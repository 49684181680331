import { gql } from '@apollo/client'

export const DELETE_API_PARTNER = gql`
  mutation deleteApiPartner($apiPartnerId: UUID!) {
    deleteApiPartner(apiPartnerId: $apiPartnerId) {
      ok
    }
  }
`

export const CREATE_API_PARTNER = gql`
  mutation createApiPartner($inputApiPartner: InputApiPartner!) {
    createApiPartner(inputApiPartner: $inputApiPartner) {
      ok
    }
  }
`

export const UPDATE_API_PARTNER = gql`
  mutation updateApiPartner($id: UUID!, $inputApiPartner: InputApiPartner!) {
    updateApiPartner(id: $id, inputApiPartner: $inputApiPartner) {
      apiPartner {
        id
        apiKey
        exportEndpoint
        importEndpoint
        documentExportEndpoint
        documentImportEndpoint
        name
        expedockDocIngestEmail
        apiPartnerType
        tmsType {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ApiPartnerTmsTypeEnum {
            value
            isFallback
          }
        }
        credential {
          id
          username
          workflow
        }
        company {
          id
          name
        }
        jobTemplates {
          edges {
            node {
              id
              name
              company {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const TEST_API_PARTNER = gql`
  mutation testApiPartner($apiPartnerId: UUID!) {
    testApiPartner(apiPartnerId: $apiPartnerId) {
      ok
    }
  }
`

export const IMPORT_E_DOCUMENTS_FROM_API_PARTNER = gql`
  mutation importEDocumentsFromApiPartner($jobId: UUID!, $dataTargetType: EDocsDataTargetType!) {
    importEDocumentsFromApiPartner(jobId: $jobId, dataTargetType: $dataTargetType) {
      ok
    }
  }
`

export const DELETE_API_EDOC = gql`
  mutation deleteApiEDoc($apiEDocumentId: UUID!) {
    deleteApiEDoc(apiEDocumentId: $apiEDocumentId) {
      ok
    }
  }
`
