import { FunctionComponent } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import Dialog from '@material-ui/core/Dialog'
import { DialogContent, DialogTitle } from '@material-ui/core'

type Props = {
  open: boolean
  title: string
}

const ProgressDialog: FunctionComponent<Props> = ({ open, title }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <LinearProgress />
      </DialogContent>
    </Dialog>
  )
}

export default ProgressDialog
