import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, RefObject, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import { HotTable } from '@handsontable/react'
import { useRef, useEffect } from 'react'

import CloseIcon from '@material-ui/icons/Close'
import theme from '@src/utils/theme'
import { useMutation } from '@apollo/client'
import { Mutation, MutationDeleteChargeVendorArgs } from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import { DELETE_CHARGE_VENDOR } from '@src/graphql/mutations/recon'
import { HANDSONTABLE_IN_MODAL_HEIGHT } from '@src/utils/app_constants'
import { ChargeCodesArray, SelectedChargeVendor } from './types'

type Props = {
  vendor: SelectedChargeVendor | undefined
  chargeCodes: ChargeCodesArray
  setOpen: (open: boolean) => void
  resetMappingState: () => void
}

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

const DeleteVendorModal: FunctionComponent<Props> = ({
  chargeCodes,
  vendor,
  setOpen,
  resetMappingState,
}: Props) => {
  const classes = useStyles()
  const [rows, setRows] = useState([] as string[][])
  const { enqueueSnackbar } = useSnackbar()
  const hotTableRef = useRef<HotTable>()
  const columns = [
    {
      key: 'description',
      name: 'Description',
    },
    {
      key: 'charge_code',
      name: 'Charge Code',
    },
  ]
  const [deleteChargeVendor] = useMutation<
    Pick<Mutation, 'deleteChargeVendor'>,
    MutationDeleteChargeVendorArgs
  >(DELETE_CHARGE_VENDOR, {
    onCompleted: () => {
      enqueueSnackbar('Charge vendor deleted', { variant: 'success' })
      resetMappingState()
      closeDialog()
    },
    onError: (error) => {
      enqueueSnackbar(`Error deleting charge vendor: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const deleteCurrentVendor = async (): Promise<void> => {
    await deleteChargeVendor({ variables: { chargeVendorId: (vendor?.id as string) || '' } })
  }

  const closeDialog = (): void => {
    setOpen(false)
  }

  useEffect(() => {
    if (chargeCodes) {
      const filteredChargeCodes = chargeCodes.filter((cc) => cc!.dateDeleted === null)
      const newRows: string[][] = filteredChargeCodes.map((chargeCode) => {
        return [chargeCode!.description, chargeCode!.code] as string[]
      })
      setRows(newRows)
    }
  }, [chargeCodes])

  return (
    <Dialog classes={{ paper: classes.dialog }} open={true} data-testid='delete-vendor-modal'>
      <Box className={classes.close}>
        <IconButton onClick={closeDialog} data-testid='close-btn'>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle>Delete Vendor</DialogTitle>
      <DialogContent>
        <Typography>
          Deleting vendor will remove all charge codes associated with vendor. Please review before
          deleting.
        </Typography>
        <Box>
          <HotTable
            id='vendor-code-table'
            ref={hotTableRef as RefObject<HotTable>}
            style={{ width: '100%', overflow: 'scroll', height: HANDSONTABLE_IN_MODAL_HEIGHT }}
            data={rows}
            readOnly={true}
            rowHeaders
            contextMenu={['row_above', 'row_below', 'remove_row']}
            colHeaders={columns.map((col) => col.name)}
            columnSorting={true}
            columns={columns}
            stretchH='all'
            autoRowSize={true}
          />
        </Box>
        <Box display='flex' justifyContent='center' p={3}>
          <Box m={3}>
            <Button onClick={closeDialog} variant='contained'>
              Cancel
            </Button>
          </Box>
          <Box m={3}>
            <Button onClick={deleteCurrentVendor} variant='contained' color='primary'>
              Delete
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteVendorModal
