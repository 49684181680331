import { FunctionComponent, useCallback } from 'react'
import { UseFieldArrayReturn } from 'react-hook-form'
import { v4 as uuid4 } from 'uuid'
import Button from '@material-ui/core/Button'

type Props = {
  append: UseFieldArrayReturn['append']
}
const AddColumnButton: FunctionComponent<Props> = ({ append }) => {
  const addColumn = useCallback(async () => {
    const newField = {
      id: uuid4(),
      name: '',
      required: false,
      type: null,
    }
    append(newField)
  }, [append])
  return (
    <Button variant='outlined' onClick={addColumn}>
      + Column
    </Button>
  )
}

export default AddColumnButton
