import { formatMaybeApolloError } from '@src/utils/errors'
import { useEffect } from 'react'
import { useSnackbar } from 'notistack'

const useSaveHotkey = (saveTable: () => Promise<void>): void => {
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent): void => {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault()
        saveTable().catch((error) => {
          enqueueSnackbar(`Error while saving: ${formatMaybeApolloError(error)}`, {
            variant: 'error',
          })
        })
      }
    }
    window.addEventListener('keydown', keydownHandler)
    return () => window.removeEventListener('keydown', keydownHandler)
  }, [saveTable, enqueueSnackbar])
}

export default useSaveHotkey
