import {
  InputField,
  InputFieldGroup,
  InputJobTemplateExportSheet,
  JobTemplateExportColumnNodeEdge,
  JobTemplateExportSheetNodeConnection,
  JobTemplateExportSheetNodeEdge,
  JobTemplateExportType,
  Maybe,
} from '@src/graphql/types'
import {
  ExportFormatRowData,
  JobTemplateExportColumn,
  JobTemplateExportSheet,
} from '@src/types/job_template_export'
import { v4 as uuid4 } from 'uuid'
import { isPresent } from 'ts-is-present'

export const formatColumnValue = (columnName: string): string => {
  return `[${columnName} value]`
}

export const createEmptyExportColumn = (): JobTemplateExportColumn => {
  return {
    field: null,
    fieldId: null,
    order: 0,
    columnName: null,
    fixedValue: null,
  }
}

const convertMetadataToExportColumns = (
  metadataFieldGroups: InputFieldGroup[],
): JobTemplateExportColumn[] => {
  return [...metadataFieldGroups]
    .sort((fieldGroupA, fieldGroupB) => fieldGroupA.name.localeCompare(fieldGroupB.name))
    .map((fieldGroup, colIdx) => ({
      field: fieldGroup.fields[0],
      fieldId: fieldGroup.fields[0].id,
      order: colIdx,
      columnName: null,
      fixedValue: null,
    }))
    .sort((columnA, columnB) => columnA.order - columnB.order)
}

const convertLineItemFieldsToExportColumns = (
  lineItemFields: InputField[],
  orderOffset = 0,
): JobTemplateExportColumn[] => {
  return [...lineItemFields]
    .sort((fieldA, fieldB) => fieldA.name.localeCompare(fieldB.name))
    .map((field, colIdx) => ({
      field,
      fieldId: field.id,
      order: colIdx + orderOffset,
      columnName: null,
      fixedValue: null,
    }))
    .sort((columnA, columnB) => columnA.order - columnB.order)
}

const generateNameRowFromSheet = (sheet: JobTemplateExportSheet): Maybe<string>[] => {
  return sheet.columns.map((column) => {
    return column.columnName || column.field?.name || null
  })
}

const generateValueRowFromSheet = (sheet: JobTemplateExportSheet): Maybe<string>[] => {
  return sheet.columns.map((column) => {
    const formattedColumnName = column.field ? formatColumnValue(column.field.name) : null
    return column.fixedValue || formattedColumnName
  })
}

const generateDropdownValuesFromSheet = (
  sheet: JobTemplateExportSheet,
  valueRow: Maybe<string>[],
): Maybe<string>[][] => {
  return sheet.columns.map((column, idx) => {
    let dropdownOptions = [] as string[]
    if (column.field) {
      let colFieldValues = column.field?.values ?? []
      // for some reason, column field values coming from the form is a json string so we parse it
      colFieldValues =
        typeof colFieldValues === 'string' ? JSON.parse(colFieldValues) : colFieldValues
      dropdownOptions = [valueRow[idx], ...colFieldValues].filter(isPresent)
    }
    return dropdownOptions
  })
}

export const generateTableDataFromExportSheet = (
  sheet: JobTemplateExportSheet,
): ExportFormatRowData => {
  const nameRow = generateNameRowFromSheet(sheet)
  const valueRow = generateValueRowFromSheet(sheet)
  const dropdownValues = generateDropdownValuesFromSheet(sheet, valueRow)
  return { nameRow, valueRow, dropdownValues }
}

const generateDefaultStandardExportSheets = (
  metadataFieldGroups: InputFieldGroup[],
  lineItemFieldGroups: InputFieldGroup[],
): JobTemplateExportSheet[] => {
  const sheets = []
  if (metadataFieldGroups.length > 0) {
    const metadataSheet = {
      id: uuid4(),
      fieldGroup: null,
      fieldGroupId: null,
      displayColumnHeader: true,
      order: 0,
      columns: convertMetadataToExportColumns(metadataFieldGroups),
    } as JobTemplateExportSheet
    metadataSheet.tableData = generateTableDataFromExportSheet(metadataSheet)
    sheets.push(metadataSheet)
  }
  if (lineItemFieldGroups.length > 0) {
    const lineItemSheets = lineItemFieldGroups
      .map((fieldGroup, sheetIdx) => {
        const lineItemSheet = {
          id: uuid4(),
          fieldGroup,
          fieldGroupId: fieldGroup.id,
          displayColumnHeader: true,
          order: sheetIdx + 1,
          columns: convertLineItemFieldsToExportColumns(fieldGroup.fields),
        } as JobTemplateExportSheet
        lineItemSheet.tableData = generateTableDataFromExportSheet(lineItemSheet)
        return lineItemSheet
      })
      .sort((sheetA, sheetB) => sheetA.order - sheetB.order)
    sheets.push(...lineItemSheets)
  }
  return sheets
}

const generateDefaultJoinedExportSheets = (
  metadataFieldGroups: InputFieldGroup[],
  lineItemFieldGroups: InputFieldGroup[],
): JobTemplateExportSheet[] => {
  const metadataCols = convertMetadataToExportColumns(metadataFieldGroups)
  if (lineItemFieldGroups.length > 0) {
    const sheets = lineItemFieldGroups.map((fieldGroup, sheetIdx) => {
      const lineItemCols = convertLineItemFieldsToExportColumns(
        fieldGroup.fields,
        metadataCols.length,
      )
      const newSheet = {
        fieldGroup,
        id: uuid4(),
        fieldGroupId: fieldGroup.id,
        displayColumnHeader: true,
        order: sheetIdx,
        columns: metadataCols.concat(lineItemCols),
      } as JobTemplateExportSheet
      newSheet.tableData = generateTableDataFromExportSheet(newSheet)
      return newSheet
    })
    return sheets
  }

  const metadataSheet = {
    id: uuid4(),
    fieldGroup: null,
    fieldGroupId: null,
    displayColumnHeader: true,
    order: 0,
    columns: metadataCols,
  }
  return [metadataSheet]
}

export const generateDefaultSheets = (
  exportType: JobTemplateExportType,
  metadataFieldGroups: InputFieldGroup[],
  lineItemFieldGroups: InputFieldGroup[],
): JobTemplateExportSheet[] => {
  switch (exportType) {
    case JobTemplateExportType.JoinMetadataLineItem:
      return generateDefaultJoinedExportSheets(metadataFieldGroups, lineItemFieldGroups)
    case JobTemplateExportType.Standard:
    case JobTemplateExportType.SplitMetadataLineItem:
    default:
      return generateDefaultStandardExportSheets(metadataFieldGroups, lineItemFieldGroups)
  }
}

export const getSheetsFromExportFormat = (
  exportFormat: JobTemplateExportSheetNodeConnection,
): JobTemplateExportSheet[] => {
  return exportFormat.edges.map((sheetEdgeNode: Maybe<JobTemplateExportSheetNodeEdge>) => {
    const sheetNode = sheetEdgeNode!.node!
    const newSheet = {
      id: sheetNode.id,
      fieldGroup: sheetNode.fieldGroup ?? null,
      fieldGroupId: sheetNode.fieldGroup?.id ?? null,
      displayColumnHeader: sheetNode.displayColumnHeader,
      order: sheetNode.order,
      columns: sheetNode.jobTemplateExportColumns!.edges.map(
        (columnEdgeNode: Maybe<JobTemplateExportColumnNodeEdge>) => {
          const columnNode = columnEdgeNode!.node!
          return {
            field: columnNode.field ?? null,
            fieldId: columnNode.field?.id ?? null,
            order: columnNode.order,
            columnName: columnNode.columnName,
            fixedValue: columnNode.fixedValue,
          }
        },
      ),
    } as JobTemplateExportSheet
    newSheet.tableData = generateTableDataFromExportSheet(newSheet)
    return newSheet
  })
}

export const sanitizeSheetsData = (
  sheets: JobTemplateExportSheet[],
): InputJobTemplateExportSheet[] => {
  const newSheets = JSON.parse(JSON.stringify(sheets)) as JobTemplateExportSheet[]
  return newSheets.map((sheet) => {
    const keysToRemove = ['id', 'fieldGroup', 'tableData']
    keysToRemove.forEach((key) => {
      delete sheet[key as keyof JobTemplateExportSheet]
    })
    sheet.columns.forEach((column) => {
      delete column.field
    })
    return sheet
  })
}

const extractFieldNameFromColumnValue = (columnValue: string): string | null => {
  const columnValueRegex = /\[(.+) value\]/g
  const regexMatchArray = columnValueRegex.exec(columnValue)
  const columnName = regexMatchArray && regexMatchArray.length > 1 ? regexMatchArray[1] : null
  return columnName
}

export const createNewSheetFromTableData = (
  sheet: JobTemplateExportSheet,
  tableData: Maybe<string>[][],
  fields: InputField[],
): JobTemplateExportSheet => {
  const fieldMap = fields.reduce(
    (acc, field) => {
      acc[field.name] = field
      return acc
    },
    {} as Record<string, InputField>,
  )
  const rowValues = tableData.pop()
  const colHeaders = tableData.pop()
  const sheetCopy = JSON.parse(JSON.stringify(sheet)) as JobTemplateExportSheet
  sheetCopy.columns = sheetCopy.columns.map((column, idx) => {
    let columnName = null
    let fixedValue = null
    column.order = idx
    if (rowValues) {
      const currentCellValue = rowValues[idx]
      if (isPresent(currentCellValue)) {
        const cellFieldName = extractFieldNameFromColumnValue(currentCellValue)
        if (cellFieldName && cellFieldName in fieldMap) {
          column.field = fieldMap[cellFieldName]
          column.fieldId = fieldMap[cellFieldName].id
        } else {
          column.field = null
          column.fieldId = null
          fixedValue = currentCellValue
        }
      } else {
        // this is from an added column so we always set the fixedValue
        column.field = null
        column.fieldId = null
        fixedValue = currentCellValue
      }
    }
    if (colHeaders) {
      const fieldName = colHeaders[idx]
      columnName = fieldName
    }
    return { ...column, columnName, fixedValue }
  })
  sheetCopy.tableData = generateTableDataFromExportSheet(sheetCopy)
  return sheetCopy
}
