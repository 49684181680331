import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { clsx } from 'clsx'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { orderBy } from 'lodash'
import theme from '@src/utils/theme'
import { COLORS } from '@src/utils/app_constants'
import { ArrivalNoticeContainerReconResultNode } from '@src/graphql/types'

enum ContainerTableFields {
  ContainerNo = 'Container No.',
  ContainerTypeCode = 'Code',
}

type ANContainerReconResultKey = keyof ArrivalNoticeContainerReconResultNode

type Props = {
  containerReconResults: ArrivalNoticeContainerReconResultNode[]
}

const useStyles = makeStyles<Theme>({
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.25, 1),
  },
  error: {
    color: theme.palette.error.main,
    background: COLORS.PALE_RED,
  },
  errorOutline: {
    boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
  },
  hovered: {
    background: COLORS.PALE_YELLOW,
    boxShadow: '0 0 0 1px yellow inset',
  },
})

const ArrivalNoticeContainerResults: FunctionComponent<Props> = ({ containerReconResults }) => {
  const classes = useStyles()
  const [highlightedDocDataRow, setHighlightedDocDataRow] = useState(null as null | number)
  const [highlightedExpectedDataRow, setHighlightedExpectedDataRow] = useState(
    null as null | number,
  )

  const documentDataExists = (reconResult: ArrivalNoticeContainerReconResultNode): boolean => {
    return Boolean(reconResult.documentContainerNo || reconResult.documentContainerTypeCode)
  }
  const expectedDataExists = (reconResult: ArrivalNoticeContainerReconResultNode): boolean => {
    return Boolean(reconResult.expectedContainerNo || reconResult.expectedContainerTypeCode)
  }

  const orderedReconResults = useMemo(() => {
    return orderBy(
      containerReconResults,
      ['success', documentDataExists, expectedDataExists],
      ['desc', 'desc', 'desc'],
    )
  }, [containerReconResults])
  const documentData = useMemo(
    () => orderedReconResults.filter(documentDataExists),
    [orderedReconResults],
  )
  const expectedData = useMemo(
    () => orderedReconResults.filter(expectedDataExists),
    [orderedReconResults],
  )

  const handleMouseLeave = useCallback(() => {
    setHighlightedDocDataRow(null)
    setHighlightedExpectedDataRow(null)
  }, [setHighlightedDocDataRow, setHighlightedExpectedDataRow])

  return (
    <>
      <Typography variant='h4' gutterBottom>
        Container Data Reconciliation
      </Typography>
      <Box display='flex' alignItems='baseline' mb={1}>
        <Box display='flex' flexDirection='column' width='100%' mr={2}>
          <Typography gutterBottom>Arrival Notice Data</Typography>
          <TableContainer>
            <Table size='small'>
              <TableBody onMouseLeave={handleMouseLeave}>
                <TableRow>
                  {Object.values(ContainerTableFields).map((col) => (
                    <TableCell
                      className={clsx(classes.cell, classes.colCell)}
                      align='center'
                      key={col}
                    >
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
                {documentData.map((reconResult, rowIdx) => {
                  const isHovered = rowIdx === highlightedDocDataRow
                  const containerNo = reconResult.documentContainerNo
                  const matchingReconResultIdx = reconResult.success
                    ? rowIdx
                    : expectedData.findIndex(
                        (reconResult) => reconResult.expectedContainerNo === containerNo,
                      )
                  const matchingReconResult = expectedData[matchingReconResultIdx]
                  const isWholeRowDisrepant = !containerNo || !matchingReconResult

                  return (
                    <TableRow
                      key={`document-${reconResult.id}`}
                      onMouseEnter={() => {
                        setHighlightedDocDataRow(rowIdx)
                        setHighlightedExpectedDataRow(matchingReconResultIdx)
                      }}
                    >
                      {Object.keys(ContainerTableFields).map((fieldKey, colIdx) => {
                        const columnKey = `document${fieldKey}` as ANContainerReconResultKey
                        const isTypeCodeDiscrepant = colIdx === 1 && !reconResult.success
                        const cellHasError = isWholeRowDisrepant || isTypeCodeDiscrepant

                        return (
                          <TableCell
                            key={`document-${fieldKey}-${colIdx}`}
                            className={clsx(classes.cell, {
                              [classes.errorOutline]: cellHasError,
                              [classes.hovered]: isHovered && !cellHasError,
                              [classes.error]: isHovered && cellHasError,
                            })}
                            component='th'
                            scope='row'
                          >
                            {reconResult[columnKey]}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display='flex' flexDirection='column' width='100%'>
          <Typography gutterBottom>Expected Data</Typography>
          <TableContainer>
            <Table size='small'>
              <TableBody onMouseLeave={handleMouseLeave}>
                <TableRow>
                  {Object.values(ContainerTableFields).map((col) => (
                    <TableCell
                      className={clsx(classes.cell, classes.colCell)}
                      align='center'
                      key={col}
                    >
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
                {expectedData.map((reconResult, rowIdx) => {
                  const isHovered = rowIdx === highlightedExpectedDataRow
                  const containerNo = reconResult.expectedContainerNo
                  const matchingReconResultIdx = documentData.findIndex(
                    (reconResult) => reconResult.documentContainerNo === containerNo,
                  )
                  const matchingReconResult = documentData[matchingReconResultIdx]
                  const isWholeRowDiscrepant = !containerNo || !matchingReconResult

                  return (
                    <TableRow
                      key={`expected-${reconResult.id}`}
                      onMouseEnter={() => {
                        setHighlightedDocDataRow(matchingReconResultIdx)
                        setHighlightedExpectedDataRow(rowIdx)
                      }}
                    >
                      {Object.keys(ContainerTableFields).map((fieldKey, colIdx) => {
                        const columnKey = `expected${fieldKey}` as ANContainerReconResultKey
                        const isTypeCodeDiscrepant = colIdx === 1 && !reconResult.success
                        const cellHasError = isWholeRowDiscrepant || isTypeCodeDiscrepant

                        return (
                          <TableCell
                            key={`expected-${fieldKey}-${colIdx}`}
                            className={clsx(classes.cell, {
                              [classes.errorOutline]: cellHasError,
                              [classes.hovered]: isHovered && !cellHasError,
                              [classes.error]: isHovered && cellHasError,
                            })}
                            component='th'
                            scope='row'
                          >
                            {reconResult[columnKey]}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  )
}

export default ArrivalNoticeContainerResults
