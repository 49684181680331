import { gql } from '@apollo/client'

export const SEARCH_COMPANIES = gql`
  query searchedCompanies($query: String!) {
    searchedForCompanies(query: $query) {
      id
      name
      jobTemplates {
        edges {
          node {
            id
            name
            dateDeleted
          }
        }
      }
    }
  }
`
