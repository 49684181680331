import {
  DocumentTableNode,
  FindShipmentReconResultNode,
  InvoiceLineItemReconResultNode,
  InvoiceReconLineItemSnapshotInterface,
  Maybe,
  ReconAttemptNode,
  ReconcileApInvoiceJob,
  ReconcileArrivalNoticeJob,
  ReconResultInterface,
  ReconResultType,
  Scalars,
  SoaReconLineItemSnapshotNode,
  SoaTotalReconResultNode,
} from '@src/graphql/types'
import { HotTableData } from '@src/utils/recon/ap_recon'
import { SoaRowFieldMapping } from '../../components/data-grid/util'
import { isFallback } from '@src/utils/enum'

import { stringifyFindModelCriteria } from '@src/utils/recon/find_model_criteria'

// Updated because we renamed some of the fields (invoiceAmount ->invoiceTotalAmount
// and expectedAmount -> expectedTotalAmount) since it is also being used
type UpdatedSoaTotalReconResultNode = {
  type: ReconResultType
  success: Scalars['Boolean']['output']
  soaTotalAmount: Scalars['Float']['output']
  expectedTotalAmount: Scalars['Float']['output']
}

export type SoaInvoiceReconResultNode = FindShipmentReconResultNode &
  InvoiceLineItemReconResultNode &
  UpdatedSoaTotalReconResultNode &
  SoaTotalReconResultNode

export type ReconJobResults = ReconcileApInvoiceJob | ReconcileArrivalNoticeJob | null

export const maybeFindShipmentIdFromReconResults = (
  reconResults?: ReconResultInterface[],
): string | null => {
  if (reconResults) {
    const reconResultWithShipmentId = reconResults.find(
      (reconResult) => reconResult.chainIoShipment?.id && reconResult.success,
    )
    if (reconResultWithShipmentId) {
      const { chainIoShipment } = reconResultWithShipmentId
      return chainIoShipment!.id
    }
  }
  return null
}

export const METADATA_DATA_TABLE_COLUMNS = ['Field Name', 'Invoice Data', 'Expected Data']
export const getSOAMetadataTable = (reconResults: SoaInvoiceReconResultNode[]): HotTableData => {
  const ERROR_COLUMN = METADATA_DATA_TABLE_COLUMNS.findIndex(
    (metadataDataTableColumn) => metadataDataTableColumn === 'Expected Data',
  )
  const invoiceTotalReconResult = reconResults.find(
    (reconResult) =>
      !isFallback(reconResult.type) &&
      reconResult.type?.value === ReconResultType.SoaTotalReconResult,
  ) as UpdatedSoaTotalReconResultNode | undefined

  return {
    data: [
      [
        'Total Amount',
        invoiceTotalReconResult?.soaTotalAmount || 0,
        invoiceTotalReconResult?.expectedTotalAmount || 0,
      ],
    ],
    errorCells: [invoiceTotalReconResult?.success ? [] : [ERROR_COLUMN]],
  }
}

/**
 * Sort recon attempts in order of the *first* (top-most in table) line item they
 * correspond to.
 *
 * Each recon attempt has line item snapshots that should map to one SOA table row,
 * *unless* there are identical SOA table rows, in which case we can't really tell (and
 * they have the same recon results anyways), so we just map to the first we see (since
 * they'll all be in the same recon attempt anyway, so ordering doesn't matter).
 *
 * @param soaRowFieldMappings SOA table rows in same order as table
 * @param reconAttempts recon attempts to order
 */
export const orderSoaReconAttempts = (
  soaRowFieldMappings: SoaRowFieldMapping[],
  reconAttempts: ReconAttemptNode[],
): ReconAttemptNode[] => {
  return reconAttempts
    .map((reconAttempt) => {
      const invoiceLineItemReconResults = reconAttempt.reconResults.filter(
        (reconResult) =>
          !isFallback(reconResult.type) &&
          reconResult.type?.value === ReconResultType.InvoiceLineItemReconResult,
      ) as InvoiceLineItemReconResultNode[]
      const lineItemSnapshots = invoiceLineItemReconResults.map(
        (reconResult: InvoiceLineItemReconResultNode): SoaReconLineItemSnapshotNode => {
          return reconResult.invoiceReconLineItemSnapshot as SoaReconLineItemSnapshotNode
        },
      )
      const matchingIndices = lineItemSnapshots.map((snapshot) =>
        soaRowFieldMappings.findIndex((mapping) =>
          Object.keys(snapshot).every((key) => {
            const retypedKey = key as keyof SoaReconLineItemSnapshotNode
            return (
              ['__typename', 'id', 'type', 'dateCreated', 'dateUpdated'].includes(key) ||
              mapping[key] === snapshot[retypedKey] ||
              (mapping[key] == null && snapshot[retypedKey] == null) ||
              (mapping[key] === '' && snapshot[retypedKey] == null) ||
              (mapping[key] == null && snapshot[retypedKey] === '')
            )
          }),
        ),
      )
      return [Math.min(...matchingIndices), reconAttempt] as [number, ReconAttemptNode]
    })
    .sort(([aIndex], [bIndex]) => aIndex - bIndex)
    .map(([, reconAttempt]) => reconAttempt)
}

/**
 * Orders objects based on the dateCreated field.
 *
 * @param items - An array of objects, each containing a dateCreated field.
 * @param order - Either 'asc' or 'desc'. Defaults to 'asc'
 * @returns A new array of objects sorted by dateCreated. Order depends on the `order` param.
 *
 * @notes
 * - This function can be used with any array of objects that have a dateCreated field.
 */
export const orderByDateCreated = <T extends { dateCreated: string }>(
  items: T[],
  order: 'asc' | 'desc' = 'asc',
): T[] => {
  return [...items].sort((a, b) => {
    const dateA = new Date(a.dateCreated)
    const dateB = new Date(b.dateCreated)
    if (order === 'asc') {
      return dateA.getTime() - dateB.getTime()
    }
    return dateB.getTime() - dateA.getTime()
  })
}

// tables are sometimes split across pages so we merge the line items of tables of the same type
export const getReconTables = (docTables: DocumentTableNode[]): DocumentTableNode[] => {
  const docTablesMapping = {} as Record<string, DocumentTableNode>
  docTables.forEach((docTable) => {
    const fieldGroupId = docTable!.fieldGroup!.id
    if (docTablesMapping[fieldGroupId]) {
      const mergedDocTableFieldGroups = docTablesMapping[
        fieldGroupId
      ].documentFieldGroups!.edges.concat(docTable.documentFieldGroups?.edges ?? [])
      docTablesMapping[fieldGroupId].documentFieldGroups!.edges = mergedDocTableFieldGroups
    } else {
      docTablesMapping[fieldGroupId] = JSON.parse(JSON.stringify(docTable))
    }
  })
  return Object.values(docTablesMapping)
}

export type SecondaryMatchingCriteria = {
  hblNumber: Maybe<string> | undefined
  mblNumber: Maybe<string> | undefined
  containerNumber: Maybe<string> | undefined
  carrierBookingNumber: Maybe<string> | undefined
  consolNumber: Maybe<string> | undefined
  orderNumber: Maybe<string> | undefined
}

export const formatInvoiceReconLineItemSnapshotToSecondaryMatchingCriteria = (
  invoiceReconLineItemSnapshot: InvoiceReconLineItemSnapshotInterface,
): SecondaryMatchingCriteria => {
  const { hblNumber, mblNumber, containerNumber, carrierBookingNumber, orderNumber, consolNumber } =
    invoiceReconLineItemSnapshot as SoaReconLineItemSnapshotNode
  return {
    hblNumber,
    mblNumber,
    containerNumber,
    carrierBookingNumber,
    orderNumber,
    consolNumber,
  }
}

export const getSecondaryKeysForGroupingLineItems = (
  secondaryMatchingCriteria: SecondaryMatchingCriteria,
): string => {
  return JSON.stringify([
    secondaryMatchingCriteria?.hblNumber ?? '',
    secondaryMatchingCriteria?.mblNumber ?? '',
    secondaryMatchingCriteria?.containerNumber ?? '',
    secondaryMatchingCriteria?.carrierBookingNumber ?? '',
    secondaryMatchingCriteria?.orderNumber ?? '',
    secondaryMatchingCriteria?.consolNumber ?? '',
  ])
}

// This function is to ensure uniqueness when displaying successful findShipmentReconResults for batch SOA recon (in cases where there are multiple recon attempts that use the exact same keys to find a shipment)
export const getUniqueFindShipmentReconResults = (
  reconAttempt: ReconAttemptNode,
): FindShipmentReconResultNode[] => {
  const successfulFindShipmentReconResults = {} as Record<string, FindShipmentReconResultNode>
  const reconResults = reconAttempt.reconResults as SoaInvoiceReconResultNode[]
  const findShipmentReconResults = reconResults.filter((reconResult) => {
    if (
      !isFallback(reconResult.type) &&
      reconResult.type?.value === ReconResultType.FindShipmentReconResult &&
      reconResult.success
    ) {
      const findShipmentReconResultKeys = stringifyFindModelCriteria(reconResult)
      successfulFindShipmentReconResults[findShipmentReconResultKeys] =
        reconResult as FindShipmentReconResultNode
    }
    return (
      !isFallback(reconResult.type) &&
      reconResult.type?.value === ReconResultType.FindShipmentReconResult
    )
  }) as FindShipmentReconResultNode[]

  const uniqueSuccessfulFindShipmentReconResults = Object.values(successfulFindShipmentReconResults)
  if (uniqueSuccessfulFindShipmentReconResults.length === 0) {
    return findShipmentReconResults
  }
  return uniqueSuccessfulFindShipmentReconResults
}
