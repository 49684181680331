import { useQuery } from '@apollo/client'
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { FunctionComponent, useState } from 'react'
import CreateJobDialog from '@src/components/CreateJobDialog'
import DividerWithText from '@src/components/divider-with-text/DividerWithText'
import { Query, TaskNode } from '@src/graphql/types'
import theme from '@src/utils/theme'
import { TASK_FOR_CREATE_JOB } from '../../graphql/queries/task'
import CreateTask from './CreateTask'
import TaskTitleReferenceIdAutocomplete from './TaskTitleReferenceIdAutocomplete'

type Props = {
  isOpen: boolean
  closePopup: () => void
  refetchJobs: () => unknown
}

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

const IdentifyTaskDialog: FunctionComponent<Props> = ({
  isOpen,
  closePopup,
  refetchJobs,
}: Props) => {
  const classes = useStyles()
  // The actual task to be used when creating a new job
  const [task, setTask] = useState(null as null | TaskNode)
  // Task selected on the search bar
  const [searchedTask, setSearchedTask] = useState(null as null | Pick<TaskNode, 'id'>)
  const [createTaskDialogOpen, setCreateTaskDialogOpen] = useState(false)
  const [createJobDialogOpen, setCreateJobDialogOpen] = useState(false)
  const { refetch: refetchTaskForCreateJob, loading: loadingTaskForCreateJob } = useQuery<
    Pick<Query, 'task'>
  >(TASK_FOR_CREATE_JOB, {
    skip: true,
  })

  const onCreateTask = (newTask: TaskNode): void => {
    setTask(newTask)
    setCreateJobDialogOpen(true)
  }

  return (
    <>
      <Dialog classes={{ paper: classes.dialog }} open={isOpen}>
        <Box className={classes.close}>
          <CloseIcon fontSize='large' onClick={closePopup} data-testid='close-btn' />
        </Box>
        <DialogTitle>Identify Existing Task</DialogTitle>
        <DialogContent>
          <Box display='flex' flexGrow={1} justifyContent='space-between'>
            <Box display='flex' flexGrow={1} flexDirection='column'>
              <Typography>Search by Task Name</Typography>
              <TaskTitleReferenceIdAutocomplete onChange={(task) => setSearchedTask(task)} />
            </Box>
            <Box display='flex' flexGrow={1} justifyContent='center' alignItems='flex-end' p={2}>
              <Button
                onClick={async () => {
                  const taskData = await refetchTaskForCreateJob({
                    id: searchedTask!.id,
                  })
                  setTask(taskData.data.task!)
                  setCreateJobDialogOpen(true)
                }}
                disabled={!searchedTask || loadingTaskForCreateJob}
                variant='contained'
                color='primary'
              >
                {loadingTaskForCreateJob ? 'Loading...' : 'Choose Task'}
              </Button>
            </Box>
          </Box>

          <DividerWithText>OR</DividerWithText>
          <Box display='flex' justifyContent='center' p={3}>
            <Button onClick={() => setCreateTaskDialogOpen(true)} variant='contained'>
              Create Task
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <CreateTask
        open={createTaskDialogOpen}
        setOpen={setCreateTaskDialogOpen}
        callback={onCreateTask}
      />
      {task && (
        <CreateJobDialog
          task={task}
          isOpen={createJobDialogOpen}
          refetchJobs={refetchJobs}
          closePopup={(): void => {
            setCreateJobDialogOpen(false)
            closePopup()
          }}
        />
      )}
    </>
  )
}

export default IdentifyTaskDialog
