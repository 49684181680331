import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  copyActiveRowBoxes,
  createRowsFromClipboard,
  createRowsFromClipboardNoSnap,
  deleteActiveFieldBoxOrLineItems,
  selectAllLineItems,
  undoLastRowFieldBoxAction,
  redoLastRowFieldBoxAction,
} from '@src/redux-features/document_editor'
import { RootState } from '@src/utils/store'
import { LogEventType } from '@src/utils/observability/LogEventType'
import { useEventLogger } from '@src/utils/observability/useEventLogger'

const useGridHotKeys = (): void => {
  const dispatch = useDispatch()
  const { logEvent } = useEventLogger()

  const gridHotkeysEnabled = useSelector(
    (state: RootState) => state.documentEditor.boxn.gridHotkeysEnabled,
  )
  const activeDocumentTableId = useSelector(
    (state: RootState) => state.documentEditor.activeDocumentTableId,
  )
  const activeFilePageId = useSelector((state: RootState) => state.documentEditor.activeFilePageId)
  const jobId = useSelector((state: RootState) => state.documentEditor.job?.id)

  const handleCopy = useCallback(
    (evt: KeyboardEvent) => {
      if ((evt.ctrlKey || evt.metaKey) && evt.key === 'c') {
        dispatch(copyActiveRowBoxes())
      }
    },
    [dispatch],
  )

  const handlePaste = useCallback(
    (evt: KeyboardEvent) => {
      if ((evt.ctrlKey || evt.metaKey) && !evt.shiftKey && evt.key === 'v') {
        dispatch(createRowsFromClipboard())
        void logEvent(LogEventType.EDIT_LINE_ITEMS_PASTE, {
          job_id: jobId,
          page_id: activeFilePageId,
        })
      }
    },
    [dispatch, jobId, activeFilePageId, logEvent],
  )

  const handlePasteNoSnap = useCallback(
    (evt: KeyboardEvent) => {
      // lowercase since some computers sense keycode change ie. onshift v -> V
      if ((evt.ctrlKey || evt.metaKey) && evt.shiftKey && evt.key.toLowerCase() === 'v') {
        dispatch(createRowsFromClipboardNoSnap())
      }
    },
    [dispatch],
  )

  const handleSelectAll = useCallback(
    (evt: KeyboardEvent) => {
      if ((evt.ctrlKey || evt.metaKey) && evt.key === 'a') {
        evt.preventDefault()
        dispatch(selectAllLineItems())
      }
    },
    [dispatch],
  )

  const handleDelete = useCallback(
    (evt: KeyboardEvent): void => {
      if (['Backspace', 'Delete'].includes(evt.key)) {
        dispatch(deleteActiveFieldBoxOrLineItems())
        void logEvent(LogEventType.EDIT_LINE_ITEMS_DELETE, {
          job_id: jobId,
          page_id: activeFilePageId,
        })
      }
    },
    [dispatch, jobId, activeFilePageId, logEvent],
  )

  const handleUndo = useCallback(
    (evt: KeyboardEvent): void => {
      if ((evt.ctrlKey || evt.metaKey) && evt.key === 'z') {
        dispatch(undoLastRowFieldBoxAction())
      }
    },
    [dispatch],
  )

  const handleRedo = useCallback(
    (evt: KeyboardEvent): void => {
      if ((evt.ctrlKey || evt.metaKey) && evt.key === 'y') {
        dispatch(redoLastRowFieldBoxAction())
      }
    },
    [dispatch],
  )

  // add key shortcuts when the table editor is displayed, otherwise remove
  useEffect(() => {
    const addListeners = (): void => {
      window.addEventListener('keydown', handleCopy)
      window.addEventListener('keydown', handleDelete)
      window.addEventListener('keydown', handlePaste)
      window.addEventListener('keydown', handlePasteNoSnap)
      window.addEventListener('keydown', handleSelectAll)
      window.addEventListener('keydown', handleUndo)
      window.addEventListener('keydown', handleRedo)
    }
    const removeListeners = (): void => {
      window.removeEventListener('keydown', handleCopy)
      window.removeEventListener('keydown', handleDelete)
      window.removeEventListener('keydown', handlePaste)
      window.removeEventListener('keydown', handlePasteNoSnap)
      window.removeEventListener('keydown', handleSelectAll)
      window.removeEventListener('keydown', handleUndo)
      window.removeEventListener('keydown', handleRedo)
    }

    if (activeDocumentTableId && gridHotkeysEnabled) {
      addListeners()
    } else {
      removeListeners()
    }
    return () => {
      removeListeners()
    }
  }, [
    gridHotkeysEnabled,
    activeDocumentTableId,
    handleCopy,
    handleDelete,
    handlePaste,
    handlePasteNoSnap,
    handleSelectAll,
    handleUndo,
    handleRedo,
  ])
}

export default useGridHotKeys
