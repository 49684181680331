import { Dispatch, SetStateAction, useState } from 'react'

export type DateRange = {
  startDate: string | null
  setStartDate: Dispatch<SetStateAction<string | null>>
  endDate: string | null
  setEndDate: Dispatch<SetStateAction<string | null>>
}

export const useDateRange = (): DateRange => {
  const [startDate, setStartDate] = useState(null as string | null)
  const [endDate, setEndDate] = useState(null as string | null)
  return { startDate, setStartDate, endDate, setEndDate }
}
