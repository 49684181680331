import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import theme from '@src/utils/theme'
import { useMutation } from '@apollo/client'
import {
  JobNodeEdge,
  TaskNode,
  Maybe,
  Mutation,
  MutationBulkUpdateJobStatusArgs,
  MutationUpdateTaskStatusArgs,
  TaskStatus,
} from '@src/graphql/types'
import { BULK_UPDATE_JOB_STATUS } from '@src/graphql/mutations/job'
import { UPDATE_TASK_STATUS } from '@src/graphql/mutations/task'
import { useSnackbar } from 'notistack'
import { JOB_STATUS } from '@src/utils/app_constants'

type Props = {
  task: Maybe<TaskNode>
  jobs: JobNodeEdge[]
  open: boolean
  status: TaskStatus
  setOpen: (open: boolean) => void
  updateCallback: () => void
}

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

const MoveJobStatusDialog: FunctionComponent<Props> = ({
  task,
  jobs,
  open,
  status,
  setOpen,
  updateCallback,
}: Props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [bulkUpdateJobStatus, { error: bulkUpdateJobStatusError }] = useMutation<
    Pick<Mutation, 'bulkUpdateJobStatus'>,
    MutationBulkUpdateJobStatusArgs
  >(BULK_UPDATE_JOB_STATUS)
  const [updateTaskStatus, { error: updateTaskStatusError }] = useMutation<
    Pick<Mutation, 'updateTaskStatus'>,
    MutationUpdateTaskStatusArgs
  >(UPDATE_TASK_STATUS)

  const closeDialog = (): void => {
    setOpen(false)
  }

  const updateJobs = async (): Promise<void> => {
    const jobIds = jobs.map((job) => job.node!.id)
    await bulkUpdateJobStatus({
      variables: { jobIds, status: JOB_STATUS[status] },
    })
    if (bulkUpdateJobStatusError) {
      enqueueSnackbar(
        `Failed to update job status. ${formatMaybeApolloError(bulkUpdateJobStatusError)}`,
        {
          variant: 'error',
        },
      )
      return
    }
    await updateTaskStatus({ variables: { taskId: task!.id, status } })
    if (updateTaskStatusError) {
      enqueueSnackbar(
        `Failed to update task status. ${formatMaybeApolloError(updateTaskStatusError)}`,
        {
          variant: 'error',
        },
      )
      return
    }

    enqueueSnackbar('Jobs updated', { variant: 'success' })
    updateCallback()
    closeDialog()
  }

  return (
    <>
      <Dialog classes={{ paper: classes.dialog }} open={open}>
        <Box className={classes.close}>
          <CloseIcon fontSize='large' onClick={closeDialog} data-testid='close-btn' />
        </Box>
        <DialogTitle>Update Jobs</DialogTitle>
        <DialogContent>
          <Typography>
            The following jobs have an invalid status. Update them to {status} before continuing?
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Job Name</TableCell>
                  <TableCell>Job Type</TableCell>
                  <TableCell>Job Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs.map((job) => (
                  <TableRow key={job.node!.id}>
                    <TableCell component='th' scope='row'>
                      {job.node!.name}
                    </TableCell>
                    <TableCell>{job.node!.jobCode}</TableCell>
                    <TableCell>{job.node!.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display='flex' justifyContent='center' p={3}>
            <Box m={3}>
              <Button onClick={closeDialog} variant='contained'>
                Cancel
              </Button>
            </Box>
            <Box m={3}>
              <Button onClick={updateJobs} variant='contained' color='primary'>
                Update
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default MoveJobStatusDialog
