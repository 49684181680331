import { FunctionComponent, useMemo } from 'react'
import { Controller, UseFieldArrayReturn } from 'react-hook-form'
import {
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  TableCell,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import theme from '@src/utils/theme'
import { makeStyles } from '@material-ui/styles'

import ControllerTextField from '@src/components/controller-text-field/ControllerTextField'
import DocumentTypesSelector from '@src/components/admin/job-template-details/DocumentTypesSelector'
import { InputFieldGroup, JobTemplateReconType } from '@src/graphql/types'
import ShipmentOpsSelector from './ShipmentOpsSelector'
import CredentialSelector from './CredentialSelector'
import { JobTemplateFormValues } from '@src/components/admin/job-template-editor/JobTemplateEditor'
import ApiPartnerSelector from './ApiPartnerSelector'
import ReconThresholdSetting from './ReconThresholdSetting'
import ExternalAssigneeSelector from '@src/components/job-viewer/ExternalAssigneeSelector'

type Props = {
  companyId: string
  removeMetadataFieldGroups: (index?: number[]) => void
  addMetadataFieldGroups: (inputFieldGroups: InputFieldGroup[]) => void
  removeLineItemTypes: (index?: number[]) => void
  addLineItemTypes: (inputFieldGroups: InputFieldGroup[]) => void
  documentTypesArrayMethods: UseFieldArrayReturn<JobTemplateFormValues, 'documentTypes'>
  reconType: JobTemplateReconType
}
const useStyles = makeStyles({
  labelCell: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    // empirically determined to fit the labels -- might want to use flexbox instead of tables
    // to automatically do this
    width: '150px',
  },
})

export const reconTypes = Object.freeze({
  [JobTemplateReconType.None]: 'None',
  [JobTemplateReconType.Ap]: 'A/P',
  [JobTemplateReconType.ArrivalNotice]: 'Arrival Notice',
  [JobTemplateReconType.Soa]: 'SOA',
  [JobTemplateReconType.BFile]: 'B-File',
})

const JobTemplateDetails: FunctionComponent<Props> = ({
  companyId,
  removeMetadataFieldGroups,
  addMetadataFieldGroups,
  removeLineItemTypes,
  addLineItemTypes,
  documentTypesArrayMethods,
  reconType,
}) => {
  const classes = useStyles()
  const jobTemplateDetails = useMemo(
    () => [
      {
        name: 'Name',
        item: (
          <ControllerTextField
            name='name'
            friendlyName='Name'
            rules={{ required: true }}
            autoFocus
          />
        ),
      },
      {
        name: 'Description',
        item: (
          <ControllerTextField
            name='description'
            friendlyName='Description'
            rules={{ required: true }}
          />
        ),
      },
      {
        name: 'SLA Time',
        item: (
          <Controller
            name='slaTime'
            rules={{
              pattern: /\d+h:\d+m/,
              required: true,
            }}
            render={({ field: { ref, ...rest }, fieldState: { invalid, error } }) => (
              <>
                <OutlinedInput
                  inputRef={ref}
                  margin='dense'
                  error={invalid}
                  {...rest}
                  fullWidth
                  endAdornment={<InputAdornment position='end'>example 1h:5m</InputAdornment>}
                />
                {error?.type === 'required' && (
                  <FormHelperText error>SLA Time is required</FormHelperText>
                )}
                {error?.type === 'pattern' && (
                  <FormHelperText error>
                    SLA Time must be of format ##h:##d (example: 2h:5m)
                  </FormHelperText>
                )}
              </>
            )}
          />
        ),
      },
      {
        name: 'Credential',
        item: <CredentialSelector />,
      },
      {
        name: 'Recon Type',
        item: (
          <Controller
            render={({ field }) => {
              return (
                <Select fullWidth defaultValue={JobTemplateReconType.None} {...field}>
                  {Object.entries(reconTypes).map(([val, label]) => (
                    <MenuItem key={`recon-type-${val}`} value={val}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )
            }}
            name='reconType'
          />
        ),
      },
      {
        name: 'Recon Thresholds',
        item: <ReconThresholdSetting />,
      },
      {
        name: 'Shipment Ops Types',
        item: <ShipmentOpsSelector />,
      },
      {
        name: 'Doc Types',
        item: (
          <DocumentTypesSelector
            companyId={companyId}
            removeMetadataFieldGroups={removeMetadataFieldGroups}
            addMetadataFieldGroups={addMetadataFieldGroups}
            removeLineItemTypes={removeLineItemTypes}
            addLineItemTypes={addLineItemTypes}
            documentTypesArrayMethods={documentTypesArrayMethods}
          />
        ),
      },
      {
        name: 'TMS',
        item: <ApiPartnerSelector />,
      },
      {
        name: 'Default External Assignee',
        item: (
          <Controller
            name='defaultExternalAssignee'
            render={({ field }) => (
              <ExternalAssigneeSelector size='small' companyId={companyId} {...field} />
            )}
          />
        ),
      },
      {
        name: 'Enabled auto-recon',
        item: (
          <Controller
            name='autoReconEnabled'
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControlLabel control={<Switch checked={field.value} {...field} />} label='' />
            )}
          />
        ),
      },
      {
        name: 'Show Subtotals row',
        item: (
          <Controller
            name='subtotalsRowEnabled'
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControlLabel control={<Switch checked={field.value} {...field} />} label='' />
            )}
          />
        ),
      },
      {
        name: 'Enable main tab',
        item: (
          <Controller
            name='mainTabEnabled'
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControlLabel control={<Switch checked={field.value} {...field} />} label='' />
            )}
          />
        ),
      },
      {
        name: 'Tick Post after fix by default',
        item: (
          <Controller
            name='autoPostEnabled'
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControlLabel control={<Switch checked={field.value} {...field} />} label='' />
            )}
          />
        ),
      },
      {
        name: 'Show Post Button',
        item: (
          <Controller
            name='showPostButton'
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControlLabel control={<Switch checked={field.value} {...field} />} label='' />
            )}
          />
        ),
      },
      {
        name: 'Require show recon result to customer',
        item: (
          <Controller
            name='requireShowReconToCustomer'
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControlLabel control={<Switch checked={field.value} {...field} />} label='' />
            )}
          />
        ),
      },
      {
        name: 'Require e-docs push/pull',
        item: (
          <Controller
            name='requireEdocsPushPull'
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControlLabel control={<Switch checked={field.value} {...field} />} label='' />
            )}
          />
        ),
      },
      {
        name: 'Require External Assignee',
        item: (
          <Controller
            name='requireExternalAssignee'
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControlLabel control={<Switch checked={field.value} {...field} />} label='' />
            )}
          />
        ),
      },
    ],
    [
      companyId,
      removeMetadataFieldGroups,
      addMetadataFieldGroups,
      removeLineItemTypes,
      addLineItemTypes,
      documentTypesArrayMethods,
      reconType,
    ],
  )
  return (
    <>
      <h2>Job Type Details</h2>
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableBody>
            {jobTemplateDetails.map((detail) => (
              <TableRow key={detail.name}>
                <TableCell className={classes.labelCell}>{detail.name}</TableCell>
                <TableCell>{detail.item}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default JobTemplateDetails
