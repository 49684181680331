import { FunctionComponent, useState } from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import theme from '@src/utils/theme'
import RoundedButton from '@src/components/RoundedButton'

type Props = {
  save: () => Promise<void>
  del: () => void
  copy?: () => void
  copyButtonText?: string
  disabled: boolean
}

const useStyles = makeStyles<Theme>({
  wrapper: {
    textAlign: 'right',
    paddingRight: theme.spacing(2),
  },
  deleteButton: {
    background: theme.palette.background.paper,
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  copyButton: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
})

const ActionButtons: FunctionComponent<Props> = ({ disabled, copyButtonText, copy, del, save }) => {
  const classes = useStyles()
  const [saving, setSaving] = useState(false)
  const disableAndSave = async (): Promise<void> => {
    setSaving(true)
    await save()
    setSaving(false)
  }
  return (
    <div className={classes.wrapper}>
      <RoundedButton
        disabled={disabled || saving}
        variant='contained'
        onClick={del}
        className={classes.deleteButton}
      >
        Delete
      </RoundedButton>
      {copy !== undefined && (
        <RoundedButton
          disabled={disabled || saving}
          variant='contained'
          onClick={copy}
          className={classes.copyButton}
        >
          {copyButtonText ?? 'Copy'}
        </RoundedButton>
      )}
      <RoundedButton
        disabled={disabled || saving}
        variant='contained'
        onClick={disableAndSave}
        color='primary'
      >
        Save
      </RoundedButton>
    </div>
  )
}

export default ActionButtons
