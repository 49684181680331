import { makeStyles, Box } from '@material-ui/core'
import { FunctionComponent } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { FilePageNode } from '@src/graphql/types'
import theme from '@src/utils/theme'
import { useZoomControlBar } from '../zoom-control-bar/useZoomControlBar'
import ZoomControlBar from '../zoom-control-bar/ZoomControlBar'

const useStyles = makeStyles({
  previewImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
})

type Props = {
  currentFilePage: FilePageNode
}

const SoaEDocFileViewer: FunctionComponent<Props> = ({ currentFilePage }) => {
  const classes = useStyles()
  const {
    panningEnabled,
    setPanningEnabled,
    scrollZoomEnabled,
    setScrollZoomEnabled,
    panOptions,
    wheelOptions,
    doubleClickOptions,
    transformWrapperOptions,
  } = useZoomControlBar()

  return (
    <>
      {currentFilePage && (
        <TransformWrapper
          pan={panOptions}
          wheel={wheelOptions}
          doubleClick={doubleClickOptions}
          options={transformWrapperOptions}
        >
          {
            // react-zoom-pan-pinch doesn't have proper typing available
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            ({ zoomIn, zoomOut, resetTransform }: any) => (
              <>
                <Box
                  display='flex'
                  justifyContent='flex-end'
                  position='sticky'
                  top={0}
                  zIndex={theme.zIndex.modal - 1}
                >
                  <ZoomControlBar
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    resetTransform={resetTransform}
                    scrollZoomEnabled={scrollZoomEnabled}
                    setScrollZoomEnabled={setScrollZoomEnabled}
                    panningEnabled={panningEnabled}
                    setPanningEnabled={setPanningEnabled}
                  />
                </Box>
                <TransformComponent>
                  <img
                    src={currentFilePage.imageUrl || ''}
                    alt={`${currentFilePage.file?.filename} page ${currentFilePage.pageNumber + 1}`}
                    className={classes.previewImage}
                  />
                </TransformComponent>
              </>
            )
          }
        </TransformWrapper>
      )}
    </>
  )
}

export default SoaEDocFileViewer
