import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import Papa from 'papaparse'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { Autocomplete, FilterOptionsState } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { InputField } from '@src/graphql/types'
import { uniq } from 'lodash'
import { useSnackbar } from 'notistack'
import ControllerErrorText from '@src/components/controller-error-text/ControllerErrorText'

type Props = {
  field: InputField
  fieldIndex: number
  fieldGroupIndex: number
  isLineItem: boolean
}

const useStyles = makeStyles({
  row: {
    width: '100%',
    cursor: 'pointer !important',
    '& div': {
      cursor: 'pointer !important',
    },
  },
  dialog: {
    width: '90vw',
  },
})

// to avoid unnecessary re-renders due to reference inequality
const emptyArray = [] as string[]

const ValuesSelector: FunctionComponent<Props> = ({
  field,
  fieldIndex,
  fieldGroupIndex,
  isLineItem,
}) => {
  const classes = useStyles()
  const { clearErrors } = useFormContext()
  const namePrefix = isLineItem ? 'lineItemTypes' : 'metadataFieldGroups'
  const {
    field: { onChange, value: values },
    fieldState: { error },
  } = useController({
    name: `${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.values`,
    defaultValue: field.values,
  })
  const { enqueueSnackbar } = useSnackbar()
  const validatorRegex: string = useWatch({
    name: `${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.validatorRegex`,
    defaultValue: field.validatorRegex,
  })
  const invalidCharsRegex: string = useWatch({
    name: `${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.invalidCharsRegex`,
    defaultValue: field.invalidCharsRegex,
  })
  const name: string = useWatch({
    name: `${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.name`,
    defaultValue: field.name,
  })

  const parsedValues = useMemo(() => (values ? (JSON.parse(values) as string[]) : []), [values])
  const [tempValues, setTempValues] = useState(() => parsedValues)
  const [inputValue, setInputValue] = useState('')
  useEffect(() => {
    setTempValues(parsedValues)
  }, [parsedValues])
  useEffect(() => {
    // clear the values validation error if we change the validation regexes
    clearErrors(`${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.values`)
  }, [validatorRegex, invalidCharsRegex, clearErrors, fieldGroupIndex, fieldIndex, namePrefix])
  const [isValuesDialogOpen, setIsValuesDialogOpen] = useState(false)
  const closeDialog = useCallback(() => {
    setTempValues(parsedValues)
    setIsValuesDialogOpen(false)
  }, [parsedValues])
  return (
    <>
      <div onClick={() => setIsValuesDialogOpen(true)} className={classes.row}>
        {parsedValues.length ? (
          parsedValues.map((value: string) => (
            <Chip data-testid='values-chip' key={value} label={value} />
          ))
        ) : (
          <span data-testid='values-no-options'>No options set. Click to add options</span>
        )}
        <ControllerErrorText friendlyName='Options' error={error} />
      </div>
      <Dialog open={isValuesDialogOpen} onClose={closeDialog} className={classes.dialog} fullWidth>
        <DialogTitle>
          <strong>{name}</strong> field options
        </DialogTitle>
        <DialogContent>
          This will overwrite the existing set of options.
          <Autocomplete
            multiple
            size='small'
            value={tempValues || emptyArray}
            inputValue={inputValue}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(_: ChangeEvent<any>, val: string[]) => {
              setTempValues(uniq(val))
            }}
            options={emptyArray}
            filterOptions={(_: string[], params: FilterOptionsState<string>) => {
              return [params.inputValue]
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onInputChange={(_: ChangeEvent<any>, newInputValue: string) => {
              const options = newInputValue.split(',')

              if (options.length > 1) {
                setTempValues(
                  uniq(
                    (tempValues || [])
                      .concat(options)
                      .map((x) => x.trim())
                      .filter((x) => x),
                  ),
                )
              } else {
                setInputValue(newInputValue)
              }
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, tagIndex) => (
                // key is part of getTagProps
                // eslint-disable-next-line react/jsx-key
                <Chip label={option} {...getTagProps({ index: tagIndex })} />
              ))
            }
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                placeholder='Enter options separated by commas (,)'
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' component='label'>
            Upload File
            <input
              type='file'
              accept='.csv,text/csv'
              onChange={async ({ target }) => {
                if (!target.files?.length) return
                const result = Papa.parse(await target.files[0].text())
                setTempValues(uniq(result.data[0] as string[]))
              }}
              hidden
            />
          </Button>
          <Box flex='1' />
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            color='primary'
            onClick={() => {
              if (!tempValues.length) {
                enqueueSnackbar('Please specify at least one option', { variant: 'error' })
                return
              }
              onChange(JSON.stringify(tempValues))
              setIsValuesDialogOpen(false)
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ValuesSelector
