import { useQuery } from '@apollo/client'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, ControllerProps, useFormContext, useWatch } from 'react-hook-form'
import { GET_CARGOWISE_FILE_TYPES } from '@src/graphql/queries/cargowise'
import { CargowiseFileTypeNode, Maybe, Query } from '@src/graphql/types'
import { DocumentTypeFormValues } from './DocumentTypeOptionsDialog'

type Props = {
  companyId: string
}

const CargowiseFileTypeSelector: FunctionComponent<Props> = ({ companyId }) => {
  const [selectedCwFileType, setSelectedCwFileType] = useState(null as null | CargowiseFileTypeNode)
  const { setValue } = useFormContext<DocumentTypeFormValues>()
  const selectedCwFileTypeId = useWatch<DocumentTypeFormValues>({
    name: 'cargowiseFileTypeId',
  }) as Maybe<string>
  const { data: cargowiseFileTypesData, loading: cargowiseFileTypesLoading } = useQuery<
    Pick<Query, 'cargowiseFileTypes'>
  >(GET_CARGOWISE_FILE_TYPES, {
    variables: {
      companyId,
    },
  })

  const cargowiseFileTypesMap = useMemo(() => {
    return Object.fromEntries(
      cargowiseFileTypesData?.cargowiseFileTypes?.map((cargowiseFileType) => [
        cargowiseFileType.id,
        cargowiseFileType,
      ]) ?? [],
    )
  }, [cargowiseFileTypesData])

  useEffect(() => {
    if (selectedCwFileTypeId) {
      const cargowiseFileType = cargowiseFileTypesMap[selectedCwFileTypeId] ?? null
      setSelectedCwFileType(cargowiseFileType)
    } else {
      setSelectedCwFileType(null)
    }
  }, [cargowiseFileTypesMap, selectedCwFileTypeId])

  const formatCwFileTypeLabel = useCallback(
    (cargowiseFileType: Maybe<CargowiseFileTypeNode>): string => {
      if (cargowiseFileType) {
        const { code } = cargowiseFileType
        return code
      }
      return ''
    },
    [],
  )

  const getOptionSelected = useCallback((option, val) => option.id === val.id, [])
  const renderInput = useCallback((params) => <TextField {...params} variant='outlined' />, [])
  const onAutocompleteChange = useCallback(
    (_: React.ChangeEvent<unknown>, newValue: Maybe<CargowiseFileTypeNode> | string): void => {
      if (typeof newValue !== 'string') {
        setValue('cargowiseFileTypeId', newValue?.id ?? null)
      }
    },
    [setValue],
  )
  const render: ControllerProps<DocumentTypeFormValues, 'cargowiseFileTypeId'>['render'] =
    useMemo(() => {
      return () => {
        return (
          <Autocomplete
            size='small'
            fullWidth={true}
            options={cargowiseFileTypesData?.cargowiseFileTypes ?? []}
            value={selectedCwFileType}
            renderOption={formatCwFileTypeLabel}
            getOptionLabel={formatCwFileTypeLabel}
            getOptionSelected={getOptionSelected}
            disabled={cargowiseFileTypesLoading}
            renderInput={renderInput}
            onChange={onAutocompleteChange}
          />
        )
      }
    }, [
      selectedCwFileType,
      formatCwFileTypeLabel,
      cargowiseFileTypesLoading,
      getOptionSelected,
      onAutocompleteChange,
      renderInput,
      cargowiseFileTypesData,
    ])
  return (
    <>
      <Controller
        render={render}
        name={'cargowiseFileTypeId'}
        defaultValue={selectedCwFileType?.id}
      />
    </>
  )
}

export default CargowiseFileTypeSelector
