import {
  TableRow,
  TableCell,
  Collapse,
  Box,
  IconButton,
  Checkbox,
  Paper,
  Button,
} from '@material-ui/core'
import { FunctionComponent, useState } from 'react'
import { FileNode, FilePageNode } from '@src/graphql/types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { getSLATimeLeft } from '@src/utils/date'
import theme from '@src/utils/theme'
import { SLA_MINUTES_TILL_JOB_ASSIGNMENT } from '@src/utils/app_constants'
import PagePreviewDialog from './PagePreviewDialog'

const useStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  filePageHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  filePageName: {
    fontWeight: theme.typography.fontWeightBold,
  },
})

type Props = {
  file: FileNode
  isSelected: boolean
  onSelectChange: (isSelected: boolean) => unknown
}

const IngestListFileRow: FunctionComponent<Props> = ({ file, isSelected, onSelectChange }) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const getFileName = (file: FileNode): string => {
    if (!file.filename) return ''
    // strip file extension if any
    const match = file.filename.match(/(.+)[.][^.]+/)
    if (!match) return file.filename
    return match[0]
  }
  const getFilePageName = (file: FileNode, page: FilePageNode): string => {
    const fileName = getFileName(file)
    return `${fileName}_page_${(page.pageNumber || 0) + 1}`
  }
  const [previewPageIndex, setPreviewPageIndex] = useState(undefined as undefined | number)
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Checkbox checked={isSelected} onChange={(_event, checked) => onSelectChange(checked)} />
        </TableCell>
        <TableCell>{getFileName(file)}</TableCell>
        <TableCell>{file.dateCreated}</TableCell>
        <TableCell>{file.company.name}</TableCell>
        <TableCell>{getSLATimeLeft(file.dateCreated, SLA_MINUTES_TILL_JOB_ASSIGNMENT)}</TableCell>
        <TableCell>{file.senderAddress}</TableCell>
        <TableCell>{file.emailSubject}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={isOpen} timeout='auto' unmountOnExit>
            {file.pages.edges
              .map((edge) => edge.node)
              .map((page, idx) => {
                return (
                  <Paper key={page.id}>
                    <Box margin={1}>
                      <div className={classes.filePageHeader}>
                        <div className={classes.filePageName}>{getFilePageName(file, page)}</div>
                        <Button onClick={() => setPreviewPageIndex(idx)}>Preview</Button>
                      </div>
                    </Box>
                  </Paper>
                )
              })}
          </Collapse>
        </TableCell>
      </TableRow>
      {previewPageIndex != null && (
        <PagePreviewDialog
          file={file}
          defaultPageIndex={previewPageIndex}
          closeDialog={() => setPreviewPageIndex(undefined)}
        />
      )}
    </>
  )
}

export default IngestListFileRow
