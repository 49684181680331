import { FunctionComponent, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { COMPANY_DATA } from '@src/graphql/queries/company'
import { EDIT_COMPANY } from '@src/graphql/mutations/company'
import { Query } from '@src/graphql/types'
import { Box, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'

import AdminBreadcrumbs from '@src/components/admin/admin-breadcrumbs/AdminBreadcrumbs'
import useRequireTeamLead from '@src/hooks/useRequireTeamLead'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'
import VendorMapping from '@src/components/admin/charge-code-vendor/VendorMapping'
import ChargeCodeMapping from '@src/components/admin/charge-code/ChargeCodeMapping'
import TaxMapping from '@src/components/admin/tax/TaxMapping'
import CompanyDetails from '@src/components/admin/admin-company/CompanyDetails'
import CustomDatabase from '@src/components/admin/custom-database/CustomDatabase'
import CompanyAdminSidebar from '@src/components/admin/admin-company/CompanyAdminSidebar'
import { CompanyAdminTab } from '@src/utils/admin/sidebar'
import CustomDatabaseType from '@src/components/admin/custom-database/CustomDatabaseType'
import JobTypesList from './job-type'
import AdminTabView from '@src/components/admin/admin-company/AdminTabView'

const useStyles = makeStyles({
  companyNamePaper: {
    zIndex: 1,
    position: 'relative',
  },
  databaseCard: {
    margin: theme.spacing(5),
  },
  databaseCardHidden: {
    display: 'none',
  },
  adminTabView: {
    paddingLeft: 300,
    paddingRight: 60,
  },
})

const CompanyAdminPage: FunctionComponent = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [refreshSearch, setRefreshSearch] = useState(false)
  const { companyId } = useParams<{ companyId: string }>()
  const { data: companyData, refetch: refetchCompany } = useQuery<Pick<Query, 'company'>>(
    COMPANY_DATA,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: companyId,
      },
    },
  )
  const [editCompany] = useMutation(EDIT_COMPANY)
  const [selectedTab, setSelectedTab] = useState(CompanyAdminTab.CompanyDetails)
  const [refreshDatabaseTypes, setRefreshDatabaseTypes] = useState(false)

  const permissionsError = useRequireTeamLead()
  if (permissionsError) {
    return permissionsError
  }
  return (
    <div>
      <Paper className={classes.companyNamePaper}>
        <AdminBreadcrumbs
          crumbs={[{ path: '/admin/company', name: 'Companies' }]}
          entityName={companyData?.company?.name || ''}
          entityType='company'
          onChangeName={async (newName) => {
            await editCompany({ variables: { id: companyId, name: newName } })
            await refetchCompany()
            enqueueSnackbar('New company name saved successfully.', { variant: 'success' })
          }}
        />
      </Paper>
      <CompanyAdminSidebar
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        companyId={companyId}
      />
      <Box className={classes.adminTabView}>
        <AdminTabView selectedTab={selectedTab} tab={CompanyAdminTab.CompanyDetails}>
          <CompanyDetails company={companyData?.company || null} />
        </AdminTabView>
        <AdminTabView selectedTab={selectedTab} tab={CompanyAdminTab.JobTypes}>
          <JobTypesList companyId={companyId} company={companyData?.company || null} />
        </AdminTabView>
        <AdminTabView selectedTab={selectedTab} tab={CompanyAdminTab.Vendors}>
          <VendorMapping companyId={companyId} company={companyData?.company || null} />
        </AdminTabView>
        <AdminTabView selectedTab={selectedTab} tab={CompanyAdminTab.ChargeCodes}>
          <ChargeCodeMapping
            companyId={companyId}
            company={companyData?.company || null}
            refreshSearch={refreshSearch}
            setRefreshSearch={setRefreshSearch}
          />
        </AdminTabView>
        <AdminTabView selectedTab={selectedTab} tab={CompanyAdminTab.Taxes}>
          <TaxMapping
            companyId={companyId}
            company={companyData?.company || null}
            refreshSearch={refreshSearch}
            setRefreshSearch={setRefreshSearch}
          />
        </AdminTabView>
        <AdminTabView selectedTab={selectedTab} tab={CompanyAdminTab.CustomDatabaseTypes}>
          <CustomDatabaseType
            companyId={companyId}
            company={companyData?.company || null}
            setRefreshDatabaseTypes={setRefreshDatabaseTypes}
          />
        </AdminTabView>
        <AdminTabView selectedTab={selectedTab} tab={CompanyAdminTab.CustomDatabases}>
          <CustomDatabase
            companyId={companyId}
            company={companyData?.company || null}
            refreshDatabaseTypes={refreshDatabaseTypes}
            setRefreshDatabaseTypes={setRefreshDatabaseTypes}
          />
        </AdminTabView>
      </Box>
    </div>
  )
}

export default CompanyAdminPage
