/*
 * This file should contain shared components and types for different recon types' history
 */

import { Box, CircularProgress, Link, Typography } from '@material-ui/core'
import {
  GetJobDataLiteQuery,
  JobTemplateReconType,
  ReconAttemptWithResultsAndMetadataFragment,
} from '@src/graphql/types'
import { type FC } from 'react'
import APReconHistory from './APReconHistory'
import SOAReconHistory from './SOAReconHistory'
import React from 'react'
import { getReconLink } from '../job-viewer/NotesOverview'

export const ReconHistoryContainer: FC = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gridGap: '3rem',
        width: '100%',
      }}
    >
      {children}
    </Box>
  )
}

type DisplayHistoryProps = {
  switchLoading: boolean
  reconHistoryLoading: boolean
  children: React.ReactNode
}

export const DisplayHistoryOrLoading: React.FC<DisplayHistoryProps> = ({
  switchLoading,
  reconHistoryLoading,
  children,
}) => {
  if (React.Children.count(children) === 0 && !reconHistoryLoading) {
    return (
      <Typography
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        No Recon History Found for this Job.
      </Typography>
    )
  }

  if (switchLoading) {
    return <></>
  }

  return <>{children}</>
}

type ReconItemSubheaderProps = {
  isSaved: boolean
  userEmail: string
  reconType: JobTemplateReconType
  reconItemId: string
}

export const ReconItemSubheader: FC<ReconItemSubheaderProps> = ({
  isSaved,
  userEmail,
  reconType,
  reconItemId,
}) => {
  return (
    <Typography
      style={{
        fontSize: '1rem',
        marginBottom: '1rem',
      }}
    >
      {userEmail}&nbsp;
      {isSaved ? 'saved reconciliation.' : `successfully reconciled ${reconType} invoice.`}
      {isSaved && (
        <span>
          {reconType === JobTemplateReconType.Soa
            ? ' Recon async batch ID: '
            : ' Recon attempt ID: '}
          <Link
            href={getReconLink(reconItemId, reconType)}
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'underline', textUnderlineOffset: '3px' }}
          >
            {reconItemId}
          </Link>
        </span>
      )}
    </Typography>
  )
}

type ReconItemHeaderProps = {
  date: Date
  loading: boolean
  isSaved: boolean
}

export const ReconItemHeader: FC<ReconItemHeaderProps> = ({ date, loading, isSaved }) => {
  return (
    <Typography
      variant='h2'
      style={{
        fontSize: '1.2rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
      }}
    >
      [{date.toDateString()} {date.toLocaleTimeString()}]&nbsp;
      <span>
        {loading && <CircularProgress size={16} />}
        {isSaved && 'Shown to Customer'}
      </span>
    </Typography>
  )
}

export type BaseReconHistoryProps = {
  job: GetJobDataLiteQuery['job']
  getSavedReconsOnly: boolean
  nextMarker: string | null
  loadMoreReconHistory: (nextMarker: string | null | undefined) => void
  handleRowClick: (reconAttempt: ReconAttemptWithResultsAndMetadataFragment) => void
  switchLoading: boolean
  setSwitchLoading: (loading: boolean) => void
}

export { APReconHistory, SOAReconHistory }
