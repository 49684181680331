import { formatMaybeApolloError } from '@src/utils/errors'
import { useMutation, useQuery } from '@apollo/client'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import {
  FIND_CONSOL_FROM_JOB,
  FIND_SHIPMENT_FROM_JOB,
  FIND_CUSTOMS_DECLARATION_FROM_JOB,
  GET_JOB_EDOCS,
} from '@src/graphql/queries/cargowise'
import { DELETE_EDOC, PULL_EDOCS_FROM_CARGOWISE } from '@src/graphql/mutations/cargowise'
import { useSnackbar } from 'notistack'
import { FunctionComponent, useContext, useState, useMemo, ReactElement, useRef } from 'react'
import {
  EDocsDataTargetType,
  EDocumentNode,
  FilePageNode,
  JobNode,
  JobTemplateReconType,
  Maybe,
  Mutation,
  MutationDeleteEDocArgs,
  MutationPullEDocsFromCwArgs,
  Query,
  QueryConsolWithMatchCriteriaArgs,
  QueryEDocumentsArgs,
  QueryCustomsDeclarationWithMatchCriteriaArgs,
  ShipmentOp,
  QueryShipmentWithMatchCriteriaArgs,
  ApiEDocumentNode,
  QueryApiEDocumentsArgs,
  MutationDeleteApiEDocArgs,
  ApiEntityType,
  MutationImportEDocumentsFromApiPartnerArgs,
} from '@src/graphql/types'
import { JOB_NOTES } from '@src/graphql/queries/note'
import theme from '@src/utils/theme'
import MsgViewerDialog from './MsgViewerDialog'
import EDocsFileSelect from './EDocsFileSelect'
import { ShipmentFormContext } from '@src/contexts/shipment_form_context'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import EDocsList from './EDocsList'
import {
  formatFindCustomsDeclarationWithMatchCriteria,
  formatFindShipmentWithMatchCriteria,
} from '@src/utils/recon/ap_recon'
import { formatFindConsolWithMatchCriteria } from '@src/utils/recon/an_recon'
import { groupBy } from 'lodash'
import BatchPullTable from '../batch-pull-table/BatchPullTable'
import CenteredCircularProgress from '../centered-circular-progress/CenteredCircularProgress'
import { RootState } from '@src/utils/store'
import { useSelector } from 'react-redux'
import { GET_API_JOB_EDOCS } from '@src/graphql/queries/api'
import { DELETE_API_EDOC, IMPORT_E_DOCUMENTS_FROM_API_PARTNER } from '@src/graphql/mutations/api'
import ApiEDocsList from './ApiEDocsList'
import { isUniversalApi } from '@src/utils/api_partner'
import ApiMsgViewerDialog from './ApiMsgViewerDialog'
import { LogEventType } from '@src/utils/observability/LogEventType'

const useStyles = makeStyles({
  button: {
    marginBottom: theme.spacing(2),
  },
  findShipmentResultsSnackbar: {
    display: 'block',
    marginRight: theme.spacing(1),
  },
})

type Props = {
  job: JobNode
  filePages: FilePageNode[]
}

const EDocsViewer: FunctionComponent<Props> = ({ job, filePages }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedEDocument, setSelectedEDocument] = useState(null as null | EDocumentNode)
  const [selectedApiEDocument, setSelectedApiEDocument] = useState(null as null | ApiEDocumentNode)
  const [isMsgViewerDialogOpen, setIsMsgViewerDialogOpen] = useState(false)
  const [isApiMsgViewerDialogOpen, setIsApiMsgViewerDialogOpen] = useState(false)
  const [pullEdocsLoading, setPullEdocsLoading] = useState(false)
  const documentEditorInitialized = useSelector(
    (state: RootState) => job.id && state.documentEditor.job?.id === job.id,
  )
  const { data: eDocumentsData, refetch: refetchEDocuments } = useQuery<
    Pick<Query, 'eDocuments'>,
    QueryEDocumentsArgs
  >(GET_JOB_EDOCS, {
    variables: { jobId: job.id },
  })
  const { data: apiEDocumentsData, refetch: refetchApiEDocuments } = useQuery<
    Pick<Query, 'apiEDocuments'>,
    QueryApiEDocumentsArgs
  >(GET_API_JOB_EDOCS, {
    variables: { jobId: job.id },
  })

  const { saveJob } = useContext(ShipmentFormContext)
  const { logEvent } = useEventLogger()
  const actionStart = useRef<Date>()

  const apiPartner = job.jobTemplate?.apiPartner ?? null
  const apiPartnerCode = apiPartner?.apiPartnerCode
  const apiPartnerName = apiPartner?.name
  const isUniversalApiMemo = useMemo(
    () => isUniversalApi(job.jobTemplate?.apiPartner),
    [job.jobTemplate?.apiPartner],
  )

  const shipmentOpsTypes = job.jobTemplate?.shipmentOpsTypes ?? []
  const reconType = job.jobTemplate?.reconType
  const isAPRecon = reconType === JobTemplateReconType.Ap
  const isSoaRecon = reconType === JobTemplateReconType.Soa
  const willPullFromShipment =
    isAPRecon ||
    shipmentOpsTypes.includes(ShipmentOp.Shipment) ||
    shipmentOpsTypes.includes(ShipmentOp.ImportDeclaration)
  const isANRecon = reconType === JobTemplateReconType.ArrivalNotice
  const willPullFromConsol =
    isAPRecon || isSoaRecon || isANRecon || shipmentOpsTypes.includes(ShipmentOp.Consol)
  const willPullFromCustomsDeclaration =
    isAPRecon || isSoaRecon || shipmentOpsTypes.includes(ShipmentOp.ImportDeclaration)
  const eDocuments = useMemo(() => {
    return eDocumentsData?.eDocuments ?? []
  }, [eDocumentsData])
  const apiEDocuments = useMemo(() => {
    return apiEDocumentsData?.apiEDocuments ?? []
  }, [apiEDocumentsData])

  const { refetch: findShipmentFromJob } = useQuery<
    Pick<Query, 'shipmentWithMatchCriteria'>,
    QueryShipmentWithMatchCriteriaArgs
  >(FIND_SHIPMENT_FROM_JOB, {
    variables: { jobId: job.id },
    fetchPolicy: 'network-only',
    skip: true,
  })

  const { refetch: findCustomsDeclarationFromJob } = useQuery<
    Pick<Query, 'customsDeclarationWithMatchCriteria'>,
    QueryCustomsDeclarationWithMatchCriteriaArgs
  >(FIND_CUSTOMS_DECLARATION_FROM_JOB, {
    variables: { jobId: job.id },
    fetchPolicy: 'network-only',
    skip: true,
  })

  const { refetch: findConsolFromJob } = useQuery<
    Pick<Query, 'consolWithMatchCriteria'>,
    QueryConsolWithMatchCriteriaArgs
  >(FIND_CONSOL_FROM_JOB, {
    variables: { jobId: job.id },
    fetchPolicy: 'network-only',
    skip: true,
  })

  const [pullEdocsFromCw] = useMutation<
    Pick<Mutation, 'pullEDocsFromCw'>,
    MutationPullEDocsFromCwArgs
  >(PULL_EDOCS_FROM_CARGOWISE, {
    refetchQueries: [{ query: JOB_NOTES, variables: { jobId: job.id } }],
  })

  const [importEDocumentsFromApiPartner] = useMutation<
    Pick<Mutation, 'importEDocumentsFromApiPartner'>,
    MutationImportEDocumentsFromApiPartnerArgs
  >(IMPORT_E_DOCUMENTS_FROM_API_PARTNER, {
    refetchQueries: [{ query: JOB_NOTES, variables: { jobId: job.id } }],
  })

  const [deleteEdoc, { loading: deleteEdocLoading }] = useMutation<
    Pick<Mutation, 'deleteEDoc'>,
    MutationDeleteEDocArgs
  >(DELETE_EDOC, {
    onCompleted: async () => {
      await refetchEDocuments()
      enqueueSnackbar('Successfully deleted eDoc', { variant: 'success' })
    },
    onError: (error) => {
      enqueueSnackbar(
        `Encountered an error while deleting eDoc: ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    },
  })

  const [deleteApiEdoc, { loading: deleteApiEdocLoading }] = useMutation<
    Pick<Mutation, 'deleteApiEDoc'>,
    MutationDeleteApiEDocArgs
  >(DELETE_API_EDOC, {
    onCompleted: async () => {
      await refetchApiEDocuments()
      enqueueSnackbar('Successfully deleted eDoc', { variant: 'success' })
    },
    onError: (error) => {
      enqueueSnackbar(
        `Encountered an error while deleting eDoc: ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    },
  })

  const pullEdocs = async (dataTargetType: EDocsDataTargetType): Promise<void> => {
    let success = false
    let errorMessage = ''
    try {
      await pullEdocsFromCw({
        variables: { jobId: job.id, dataTargetType },
      })
      await refetchEDocuments()
      enqueueSnackbar('Successfully pulled eDocs from CargoWise', { variant: 'success' })
      success = true
    } catch (error) {
      errorMessage = `Encountered an error while pulling eDocs from CargoWise: ${formatMaybeApolloError(
        error,
      )}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
    } finally {
      void logEvent(LogEventType.EDOCS_PULL, {
        success,
        job_id: job?.id,
        action_start: actionStart.current,
        action_end: new Date(),
        error_message: errorMessage,
      })
    }
  }

  const handlePullEDocs = async (dataTarget: EDocsDataTargetType): Promise<void> => {
    try {
      actionStart.current = new Date()
      setPullEdocsLoading(true)
      await saveJob()

      let foundCwDataSuccess = false
      let formattedFindCwDataResults = null as ReactElement | null
      if (dataTarget === EDocsDataTargetType.Shipment) {
        const findShipmentWithMatchCriteriaData = await findShipmentFromJob()
        const shipmentWithMatchCriteria =
          findShipmentWithMatchCriteriaData.data.shipmentWithMatchCriteria
        const chainIoShipment = shipmentWithMatchCriteria.chainIoShipment
        const shipmentNo = shipmentWithMatchCriteria.shipmentNo
        const hblNo = shipmentWithMatchCriteria.hblNo
        const consolNo = shipmentWithMatchCriteria.consolNo
        const mblNo = shipmentWithMatchCriteria.mblNo
        const carrierBookingNo = shipmentWithMatchCriteria.carrierBookingNo
        const containerNo = shipmentWithMatchCriteria.containerNo
        const orderNo = shipmentWithMatchCriteria.orderNo
        formattedFindCwDataResults = formatFindShipmentWithMatchCriteria(
          chainIoShipment,
          shipmentNo,
          hblNo,
          orderNo,
          consolNo,
          mblNo,
          carrierBookingNo,
          containerNo,
        )
        foundCwDataSuccess = !!shipmentWithMatchCriteria.chainIoShipment
      } else if (dataTarget === EDocsDataTargetType.Consol) {
        const findConsolWithMatchCriteriaData = await findConsolFromJob()
        const consolWithMatchCriteria = findConsolWithMatchCriteriaData.data.consolWithMatchCriteria
        const chainIoConsol = consolWithMatchCriteria.chainIoConsol
        const consolNo = consolWithMatchCriteria.consolNo
        const mblNo = consolWithMatchCriteria.mblNo
        const carrierBookingNo = consolWithMatchCriteria.carrierBookingNo
        const hblNo = consolWithMatchCriteria.hblNo
        const shipmentNo = consolWithMatchCriteria.shipmentNo
        const containerNo = consolWithMatchCriteria.containerNo
        const orderNo = consolWithMatchCriteria.orderNo
        formattedFindCwDataResults = formatFindConsolWithMatchCriteria(
          chainIoConsol,
          consolNo,
          mblNo,
          hblNo,
          shipmentNo,
          carrierBookingNo,
          orderNo,
          containerNo,
        )
        foundCwDataSuccess = !!consolWithMatchCriteria.chainIoConsol
      } else if (dataTarget === EDocsDataTargetType.CustomsDeclaration) {
        const findCustomsDeclarationWithMatchCriteriaData = await findCustomsDeclarationFromJob()
        const customsDeclarationWithMatchCriteria =
          findCustomsDeclarationWithMatchCriteriaData.data.customsDeclarationWithMatchCriteria
        const chainIoCustomsDeclaration =
          customsDeclarationWithMatchCriteria.chainIoCustomsDeclaration
        const referenceNo = customsDeclarationWithMatchCriteria.referenceNo
        const hblNo = customsDeclarationWithMatchCriteria.hblNo
        const consolNo = customsDeclarationWithMatchCriteria.consolNo
        const mblNo = customsDeclarationWithMatchCriteria.mblNo
        const carrierBookingNo = customsDeclarationWithMatchCriteria.carrierBookingNo
        const containerNo = customsDeclarationWithMatchCriteria.containerNo
        const orderNo = customsDeclarationWithMatchCriteria.orderNo
        formattedFindCwDataResults = formatFindCustomsDeclarationWithMatchCriteria(
          chainIoCustomsDeclaration,
          referenceNo,
          hblNo,
          orderNo,
          consolNo,
          mblNo,
          carrierBookingNo,
          containerNo,
        )
        foundCwDataSuccess = !!customsDeclarationWithMatchCriteria.chainIoCustomsDeclaration
      }

      const fetchShipmentConsolOrCdec = [
        EDocsDataTargetType.Shipment,
        EDocsDataTargetType.Consol,
        EDocsDataTargetType.CustomsDeclaration,
      ].includes(dataTarget)
      if (fetchShipmentConsolOrCdec) {
        const formattedFindShipmentResults = (): ReactElement => (
          <div className={classes.findShipmentResultsSnackbar}>{formattedFindCwDataResults}</div>
        )

        if (foundCwDataSuccess) {
          enqueueSnackbar(null, { variant: 'info', action: formattedFindShipmentResults })
        } else {
          enqueueSnackbar(null, {
            action: formattedFindShipmentResults,
            variant: 'error',
          })
          return
        }
      }

      await pullEdocs(dataTarget)
    } catch (error) {
      const errorMessage = `Encountered an error while pulling eDocs from CargoWise: ${formatMaybeApolloError(
        error,
      )}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
    } finally {
      setPullEdocsLoading(false)
    }
  }

  const pullApiEdocs = async (dataTargetType: EDocsDataTargetType): Promise<void> => {
    let success = false
    let errorMessage = ''
    try {
      await importEDocumentsFromApiPartner({
        variables: { jobId: job.id, dataTargetType },
      })
      await refetchApiEDocuments()
      enqueueSnackbar(`Successfully pulled eDocs from ${apiPartnerName}`, { variant: 'success' })
      success = true
    } catch (error) {
      errorMessage = `Encountered an error while pulling eDocs from ${apiPartnerName}: ${formatMaybeApolloError(
        error,
      )}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
    } finally {
      void logEvent(LogEventType.EDOCS_PULL, {
        success,
        job_id: job?.id,
        action_start: actionStart.current,
        action_end: new Date(),
        error_message: errorMessage,
      })
    }
  }

  const handlePullApiEDocs = async (dataTargetType: EDocsDataTargetType): Promise<void> => {
    try {
      actionStart.current = new Date()
      setPullEdocsLoading(true)
      await saveJob()
      await pullApiEdocs(dataTargetType)
    } catch (error) {
      const errorMessage = `Encountered an error while pulling eDocs from ${apiPartnerName}: ${formatMaybeApolloError(
        error,
      )}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
    } finally {
      setPullEdocsLoading(false)
    }
  }

  const groupedEdocs = useMemo(() => {
    return groupBy(eDocuments, 'dataTargetType.value')
  }, [eDocuments])

  const shipmentEdocs = groupedEdocs[EDocsDataTargetType.Shipment] ?? []
  const consolEdocs = groupedEdocs[EDocsDataTargetType.Consol] ?? []
  const APInvoiceEdocs = groupedEdocs[EDocsDataTargetType.ApInvoice] ?? []
  const customsDeclarationEdocs = groupedEdocs[EDocsDataTargetType.CustomsDeclaration] ?? []

  const groupedApiEdocs = useMemo(() => {
    const importEDocuments = apiEDocuments.filter(
      (apiEDocument) => apiEDocument?.apiImportEdocActivity?.id,
    )
    return groupBy(importEDocuments, 'apiImportEdocActivity.apiEntityType')
  }, [apiEDocuments])

  const shipmentApiEdocs = groupedApiEdocs[ApiEntityType.Shipment] ?? []
  const apInvoiceApiEdocs = groupedApiEdocs[ApiEntityType.Invoice] ?? []

  const selectEdocHandler = (eDocument: Maybe<EDocumentNode>): void => {
    setSelectedEDocument(eDocument)
    setIsMsgViewerDialogOpen(true)
  }

  const selectApiEdocHandler = (apiEDocument: Maybe<ApiEDocumentNode>): void => {
    setSelectedApiEDocument(apiEDocument)
    setIsApiMsgViewerDialogOpen(true)
  }

  const deleteEdocHandler = async (eDocumentId: string): Promise<void> => {
    await deleteEdoc({ variables: { eDocumentId } })
  }

  const deleteApiEdocHandler = async (apiEDocumentId: string): Promise<void> => {
    await deleteApiEdoc({ variables: { apiEDocumentId } })
  }

  return (
    <>
      {isUniversalApiMemo ? (
        <>
          <Box paddingBottom={1}>
            <Typography variant='h3' gutterBottom>
              {apiPartnerName} to Expedock
            </Typography>
          </Box>
          {documentEditorInitialized ? (
            <>
              {willPullFromShipment && (
                <>
                  <Typography variant='h3' gutterBottom>
                    Shipment eDocs
                  </Typography>
                  <Button
                    className={classes.button}
                    disabled={pullEdocsLoading}
                    variant='contained'
                    onClick={() => handlePullApiEDocs(EDocsDataTargetType.Shipment)}
                  >
                    Pull eDocs From Shipment
                  </Button>
                  <ApiEDocsList
                    apiEDocuments={shipmentApiEdocs}
                    selectApiEdocHandler={selectApiEdocHandler}
                    deleteApiEdocHandler={deleteApiEdocHandler}
                    deleteApiEdocLoading={deleteApiEdocLoading}
                  />
                </>
              )}
              {reconType === JobTemplateReconType.Ap && (
                <>
                  <Typography variant='h3' gutterBottom>
                    AP Invoice eDocs
                  </Typography>
                  <Button
                    className={classes.button}
                    disabled={pullEdocsLoading}
                    variant='contained'
                    onClick={() => handlePullApiEDocs(EDocsDataTargetType.ApInvoice)}
                  >
                    Pull eDocs From AP Invoice
                  </Button>
                  <ApiEDocsList
                    apiEDocuments={apInvoiceApiEdocs}
                    selectApiEdocHandler={selectApiEdocHandler}
                    deleteApiEdocHandler={deleteApiEdocHandler}
                    deleteApiEdocLoading={deleteApiEdocLoading}
                  />
                </>
              )}
            </>
          ) : (
            <CenteredCircularProgress />
          )}
        </>
      ) : (
        <>
          <Box paddingBottom={1}>
            <Typography variant='h3' gutterBottom>
              Cargowise to Expedock
            </Typography>
          </Box>
          {isSoaRecon && !documentEditorInitialized && <CenteredCircularProgress />}
          {isSoaRecon && documentEditorInitialized ? (
            <BatchPullTable
              eDocuments={eDocuments}
              jobId={job.id}
              filePages={filePages}
              refetchEDocs={refetchEDocuments}
              selectEDocHandler={selectEdocHandler}
              deleteEdocHandler={deleteEdocHandler}
              deleteEdocLoading={deleteEdocLoading}
            />
          ) : (
            <>
              {willPullFromShipment && (
                <>
                  <Typography variant='h3' gutterBottom>
                    Shipment eDocs
                  </Typography>
                  <Button
                    className={classes.button}
                    disabled={pullEdocsLoading}
                    variant='contained'
                    onClick={() => handlePullEDocs(EDocsDataTargetType.Shipment)}
                  >
                    Pull eDocs From Shipment
                  </Button>
                  <EDocsList
                    eDocuments={shipmentEdocs}
                    selectEdocHandler={selectEdocHandler}
                    deleteEdocHandler={deleteEdocHandler}
                    deleteEdocLoading={deleteEdocLoading}
                  />
                </>
              )}
              {willPullFromConsol && (
                <>
                  <Typography variant='h3' gutterBottom>
                    Consol eDocs
                  </Typography>
                  <Button
                    className={classes.button}
                    disabled={pullEdocsLoading}
                    variant='contained'
                    onClick={() => handlePullEDocs(EDocsDataTargetType.Consol)}
                  >
                    Pull eDocs From Consol
                  </Button>
                  <EDocsList
                    eDocuments={consolEdocs}
                    selectEdocHandler={selectEdocHandler}
                    deleteEdocHandler={deleteEdocHandler}
                    deleteEdocLoading={deleteEdocLoading}
                  />
                </>
              )}
              {willPullFromCustomsDeclaration && (
                <>
                  <Typography variant='h3' gutterBottom>
                    Customs Declaration eDocs
                  </Typography>
                  <Button
                    className={classes.button}
                    disabled={pullEdocsLoading}
                    variant='contained'
                    onClick={() => handlePullEDocs(EDocsDataTargetType.CustomsDeclaration)}
                  >
                    Pull eDocs From Customs Declaration
                  </Button>
                  <EDocsList
                    eDocuments={customsDeclarationEdocs}
                    selectEdocHandler={selectEdocHandler}
                    deleteEdocHandler={deleteEdocHandler}
                    deleteEdocLoading={deleteEdocLoading}
                  />
                </>
              )}
              {reconType === JobTemplateReconType.Ap && (
                <>
                  <Typography variant='h3' gutterBottom>
                    AP Invoice eDocs
                  </Typography>
                  <Button
                    className={classes.button}
                    disabled={pullEdocsLoading}
                    variant='contained'
                    onClick={() => handlePullEDocs(EDocsDataTargetType.ApInvoice)}
                  >
                    Pull eDocs From AP Invoice
                  </Button>
                  <EDocsList
                    eDocuments={APInvoiceEdocs}
                    selectEdocHandler={selectEdocHandler}
                    deleteEdocHandler={deleteEdocHandler}
                    deleteEdocLoading={deleteEdocLoading}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
      <Typography variant='h3' gutterBottom>
        Expedock to {isUniversalApiMemo ? apiPartnerName : 'Cargowise'}
      </Typography>
      {filePages.length > 0 && (
        <EDocsFileSelect job={job} filePages={filePages} isUniversalApi={isUniversalApiMemo} />
      )}
      <MsgViewerDialog
        isOpen={isMsgViewerDialogOpen}
        close={() => {
          setIsMsgViewerDialogOpen(false)
          setSelectedEDocument(null)
        }}
        eDocument={selectedEDocument}
      />
      <ApiMsgViewerDialog
        isOpen={isApiMsgViewerDialogOpen}
        close={() => {
          setIsApiMsgViewerDialogOpen(false)
          setSelectedApiEDocument(null)
        }}
        apiEDocument={selectedApiEDocument}
      />
    </>
  )
}

export default EDocsViewer
