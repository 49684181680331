import { FunctionComponent, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/styles'
import { grey } from '@material-ui/core/colors'
import { ApiPartnerNode, ApiPartnerTmsType } from '@src/graphql/types'
import { isFallback } from '@src/utils/enum'

const useStyles = makeStyles({
  helpIcon: {
    color: grey[600],
  },
  actionIcon: {
    color: grey[900],
  },
})

type Props = {
  apiPartners: ApiPartnerNode[]
  onDelete: (id: string) => Promise<void>
  onUpdate: (credential: ApiPartnerNode) => void
  onTest: (id: string) => Promise<void>
}

const ApiPartnerTable: FunctionComponent<Props> = ({ apiPartners, onDelete, onUpdate, onTest }) => {
  const classes = useStyles()
  const [isTesting, setIsTesting] = useState(false)

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Actions</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>API Key</TableCell>
            <TableCell>Auth Values</TableCell>
            <TableCell>Export Endpoint</TableCell>
            <TableCell>Import Endpoint</TableCell>
            <TableCell>Document Export Endpoint</TableCell>
            <TableCell>Document Import Endpoint</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>TMS Type</TableCell>
            <TableCell>Country Code</TableCell>
            <TableCell>Job Templates</TableCell>
            <TableCell>
              <Box display='flex' alignItems='center' flexWrap='wrap'>
                <Box paddingRight={1}>Expedock Document Ingest Email</Box>
                <Tooltip title='When Expedock receives documents for ingestion from an ApiPartner, we will send the documents to expedock_doc_ingest_email for ingestion.'>
                  <IconButton size='small' className={classes.helpIcon}>
                    <HelpOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell>Live/Test</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apiPartners.map((apiPartner) => {
            const apiPartnerTmsType = apiPartner?.tmsType
              ? isFallback(apiPartner.tmsType)
                ? (apiPartner?.tmsType?.fallbackValue as ApiPartnerTmsType)
                : (apiPartner?.tmsType?.value as ApiPartnerTmsType)
              : undefined
            return (
              <TableRow key={apiPartner.id}>
                <TableCell align='center'>
                  <Box display='flex' justifyContent='center'>
                    <IconButton
                      aria-label='edit'
                      size='small'
                      className={classes.actionIcon}
                      onClick={() => onUpdate(apiPartner)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label='delete'
                      size='small'
                      className={classes.actionIcon}
                      onClick={() => onDelete(apiPartner.id)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                    <Button
                      size='small'
                      disabled={isTesting && !apiPartner.exportEndpoint}
                      onClick={async () => {
                        setIsTesting(true)
                        try {
                          await onTest(apiPartner.id)
                        } finally {
                          setIsTesting(false)
                        }
                      }}
                    >
                      {(isTesting && <CircularProgress size={'1em'} />) || 'Test'}
                    </Button>
                  </Box>
                </TableCell>
                <TableCell>{apiPartner.company?.name || ''}</TableCell>
                <TableCell>{apiPartner.apiKey}</TableCell>
                <TableCell>
                  <pre>{JSON.stringify(JSON.parse(apiPartner?.authValues ?? '{}'), null, 4)}</pre>
                </TableCell>
                <TableCell>{apiPartner.exportEndpoint}</TableCell>
                <TableCell>{apiPartner.importEndpoint}</TableCell>
                <TableCell>{apiPartner.documentExportEndpoint}</TableCell>
                <TableCell>{apiPartner.documentImportEndpoint}</TableCell>
                <TableCell>{apiPartner.name || ''}</TableCell>
                <TableCell>{apiPartnerTmsType}</TableCell>
                <TableCell>{apiPartner.countryCode}</TableCell>
                <TableCell>
                  {apiPartner.jobTemplates?.edges
                    .map(
                      (jobTemplate) =>
                        `${jobTemplate!.node!.company!.name} - ${jobTemplate!.node!.name}`,
                    )
                    .join(', ') || ''}
                </TableCell>
                <TableCell>{apiPartner.expedockDocIngestEmail}</TableCell>
                <TableCell>{apiPartner.apiPartnerType}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ApiPartnerTable
