import { gql } from '@apollo/client'

export const USERS_BY_ROLE = gql`
  query usersByRole($role: UserRole!) {
    usersByRole(role: $role) {
      id
      email
    }
  }
`

export const LIST_USERS = gql`
  query listUsers(
    $companyId: UUID
    $email: String
    $roles: [UserRole!]
    $pageNumber: Int
    $paginate: Boolean
  ) {
    listUsers(
      companyId: $companyId
      email: $email
      roles: $roles
      pageNumber: $pageNumber
      paginate: $paginate
    ) {
      page
      limit
      total
      users {
        uuid
        auth0Id
        name
        email
        roles
        companies {
          id
          name
        }
        company {
          id
          name
          metabaseDashboardId
          hasAccessToInsights
        }
        partnerCompanies {
          id
          name
        }
        metabaseDashboardId
        salesRep
        operationsRep
        branch
        department
        salesReps
        operationsReps
        branches
        departments
        onlyViewAssignedShipments
        onlyViewDashboardCompany
        defaultDashboardsWhitelist
        lastLogin
        dateCreated
        canExportDataAsCsv
      }
    }
  }
`

export const LIST_DASHBOARD_COMPANY_USERS = gql`
  query listDashboardCompanyUsers($companyId: UUID!) {
    listDashboardCompanyUsers(companyId: $companyId) {
      id
      email
    }
  }
`

export const GET_USER_PROFILE = gql`
  query getUserProfile {
    userProfile {
      email
      roles
      company {
        id
        name
        metabaseDashboardId
        hasAccessToInsights
      }
    }
  }
`
