import { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
})

const CenteredCircularProgress: FunctionComponent = () => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <CircularProgress />
    </div>
  )
}

export default CenteredCircularProgress
