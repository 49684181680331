import { formatMaybeApolloError } from '@src/utils/errors'
/* eslint-disable @typescript-eslint/no-var-requires */
import { FunctionComponent, RefObject, useState, useMemo } from 'react'
import { useSnackbar } from 'notistack'
import { Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { HotTable } from '@handsontable/react'
import {
  ApReconAutofillKey,
  JobTemplateReconType,
  Mutation,
  MutationExportJobToCwArgs,
  ReconcileApInvoiceJob,
  MatchingCriteriaType,
  CwTargetModule,
} from '@src/graphql/types'
import { convertCwTargetModuleDisplayTextToCwTargetModule } from '@src/utils/recon/ap_recon'
import { CargowiseOpsType } from '@src/utils/cargowise/types'
import { useMutation } from '@apollo/client'
import { getLumpsumTable, getLumpsumInputTable } from '@src/utils/lumpsum'
import CenteredCircularProgress from './centered-circular-progress/CenteredCircularProgress'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { EXPORT_JOB_TO_CARGOWISE } from '@src/graphql/mutations/cargowise'
import { JOB_NOTES } from '@src/graphql/queries/note'
import { useDialog } from 'muibox'
import APInvoiceReconResultTables from './reconciliation-dialog/APInvoiceReconResultTables'
import { isEmpty } from 'lodash'
import { isCustomsDeclarationFromReconResultsHelper } from '@src/utils/recon/ap_recon'
import ReadOnlyDocumentHotTable from './read-only-document-hot-table/ReadOnlyDocumentHotTable'
import useReconData from '@src/hooks/useReconData'
import { TransportManagementSystem } from '@src/utils/observability/TransportManagementSystem'
import { LogEventType } from '@src/utils/observability/LogEventType'
import { FindShipmentResultDisplay } from '@src/utils/recon/FindShipmentResultDisplay'

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  dialogMedium: {
    maxWidth: '90vw',
  },
  dialogFullScreen: {
    maxWidth: '90%',
    width: '90%',
  },
  warningText: {
    backgroundColor: theme.palette.warning.light,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

type Props = {
  isOpen: boolean
  close: () => void
  jobId: string
  reconType: JobTemplateReconType.Ap | JobTemplateReconType.ArrivalNotice
}

const SendToCWDialog: FunctionComponent<Props> = ({ isOpen, close, jobId, reconType }) => {
  const classes = useStyles()
  const { logEvent } = useEventLogger()
  const { enqueueSnackbar } = useSnackbar()
  const dialog = useDialog()

  const {
    metadataFieldValueMap,
    reconTables,
    isReconDataLoading,
    initLumpsum,
    setInitLumpsum,
    reconJobResults,
    sendAsLumpsum,
    toggleSendAsLumpSum,
    overrideChargeDescriptionFlag,
    toggleOverrideChargeDescriptionFlag,
    reconcileCriteriaFlag,
    toggleReconcileCriteriaFlag,
    disableSendDueDateFlag,
    toggleDisableSendDueDateFlag,
    isPremiumRate,
    isValidatingChargeCodes,
    invalidDocChargeCodes,
    lumpsumHotTableRef,
    findShipmentReconResults,
  } = useReconData(jobId, reconType)
  const matchingCriteria = reconcileCriteriaFlag
    ? MatchingCriteriaType.ChargeCodeVendorInvoiceNumber
    : MatchingCriteriaType.ChargeCodeOnly
  const cwModule = (convertCwTargetModuleDisplayTextToCwTargetModule(
    metadataFieldValueMap[ApReconAutofillKey.CargowiseModule.toLowerCase()],
  ) || CwTargetModule.ForwardingShipment) as CwTargetModule

  if (!isValidatingChargeCodes && invalidDocChargeCodes.length > 0) {
    close()
  }

  const cargowiseOpsType = CargowiseOpsType.SendToCW
  const [isSending, setIsSending] = useState(false)

  const hasSuccessfulShipmentReconResult = useMemo(() => {
    return findShipmentReconResults.some((reconResult) => reconResult.success)
  }, [findShipmentReconResults])

  const isCustomsDeclarationFromReconResults = useMemo(() => {
    return isCustomsDeclarationFromReconResultsHelper(null, findShipmentReconResults)
  }, [findShipmentReconResults])

  const [exportJobToCW] = useMutation<Pick<Mutation, 'exportJobToCw'>, MutationExportJobToCwArgs>(
    EXPORT_JOB_TO_CARGOWISE,
    {
      refetchQueries: [{ query: JOB_NOTES, variables: { jobId } }],
      awaitRefetchQueries: true,
    },
  )

  const sendJobToCW = async (): Promise<void> => {
    setIsSending(true)
    const actionStart = new Date()
    let success = false
    let errorMessage = ''
    try {
      enqueueSnackbar('Uploading job to Cargowise. This may take a few minutes.', {
        variant: 'info',
      })
      const lumpsumTable = getLumpsumTable(reconTables, reconType)
      const lumpsumRows = sendAsLumpsum ? lumpsumHotTableRef.current?.hotInstance!.getData() : null
      const lumpsumColumns = Object.keys(initLumpsum)
      const lumpsumInputTable =
        lumpsumRows && lumpsumTable
          ? getLumpsumInputTable(lumpsumRows[0], lumpsumColumns, lumpsumTable)
          : null
      if (lumpsumRows) {
        const newLumpsum = Object.fromEntries(
          lumpsumColumns.map((column, idx) => [column, lumpsumRows[0][idx]]),
        )
        setInitLumpsum(newLumpsum)
      }

      await exportJobToCW({
        variables: {
          jobId,
          matchingCriteria,
          lumpsum: lumpsumInputTable,
          overrideChargeDescription: overrideChargeDescriptionFlag,
          disableSendDueDate: disableSendDueDateFlag,
        },
      })
      enqueueSnackbar('Successfully exported job to Recon System', { variant: 'success' })
      success = true
      close()
    } catch (error) {
      errorMessage = `CW Export Error: ${formatMaybeApolloError(error)}`
      await dialog.alert({
        title: 'Error during CW Export',
        message: (
          <>
            <Typography variant='body1'>
              The following error was encountered while sending to CW:
            </Typography>
            {formatMaybeApolloError(error)
              .split('\n')
              .map((log: string, idx) => {
                return (
                  <blockquote key={`${idx}`}>
                    <Typography variant='body2'>{log}</Typography>
                  </blockquote>
                )
              })}
            <Typography variant='body2'>
              Changes may have been partially applied in Cargowise. Please check on Cargowise
              whether any unintended changes have occurred.
            </Typography>
          </>
        ),
      })
    } finally {
      void logEvent(LogEventType.SHIPMENT_SEND_TO_TMS, {
        tms: TransportManagementSystem.CARGOWISE,
        job_id: jobId,
        action_start: actionStart,
        action_end: new Date(),
        send_as_lumpsum: sendAsLumpsum,
        override_charge_desc: overrideChargeDescriptionFlag,
        disable_send_due_date: disableSendDueDateFlag,
        success: success,
        error_message: errorMessage,
      })
    }
  }

  const isLoading =
    isReconDataLoading ||
    (reconType === JobTemplateReconType.Ap && !reconJobResults) ||
    (sendAsLumpsum && isEmpty(initLumpsum))

  const isSendingDisabled =
    isSending || (reconType === JobTemplateReconType.Ap && !hasSuccessfulShipmentReconResult)

  return (
    <Dialog
      classes={{
        paper: isLoading
          ? classes.dialog
          : overrideChargeDescriptionFlag
          ? classes.dialogFullScreen
          : classes.dialogMedium,
      }}
      open={isOpen}
      onBackdropClick={close}
    >
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={close} data-testid='close-btn' />
      </Box>
      <DialogTitle>
        <Box p={1}>
          <Typography variant='h6'>Review Data to Send to CW</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isCustomsDeclarationFromReconResults && (
          <Box my={2}>
            <Typography className={classes.warningText}>
              <strong>WARNING:</strong> Cargowise does not allow sending for customs declarations
              attached to shipments. If this Customs Declaration is associated to a Forwarding
              Shipment, it will not post or send properly.
            </Typography>
          </Box>
        )}
        {isLoading ? (
          <CenteredCircularProgress />
        ) : (
          <>
            {reconType === JobTemplateReconType.Ap ? (
              <>
                <Typography variant='subtitle1' gutterBottom>
                  <FindShipmentResultDisplay
                    findShipmentResults={findShipmentReconResults}
                    cargowiseOpsType={cargowiseOpsType}
                    cargowiseModule={cwModule}
                  />
                </Typography>
                <APInvoiceReconResultTables
                  reconJobResults={reconJobResults as ReconcileApInvoiceJob}
                  overrideChargeDescription={overrideChargeDescriptionFlag}
                  disableHighlights={true}
                  sendAsLumpsum={sendAsLumpsum}
                />
              </>
            ) : (
              reconTables.map((docTable) => {
                return (
                  <div key={`recon-table-${docTable.id}`}>
                    <Typography variant='subtitle1' gutterBottom>
                      {docTable.fieldGroup?.name}
                    </Typography>
                    <ReadOnlyDocumentHotTable
                      documentTable={docTable}
                      showChargeDescription={overrideChargeDescriptionFlag}
                    />
                  </div>
                )
              })
            )}
            {sendAsLumpsum && (
              <div data-testid='lumpsum-table'>
                <Typography variant='h4' gutterBottom>
                  Lumpsum Table
                </Typography>
                <HotTable
                  ref={lumpsumHotTableRef as RefObject<HotTable>}
                  style={{ width: '100%', overflow: 'hidden', height: '80px' }}
                  data={[Object.values(initLumpsum)]}
                  colHeaders={Object.keys(initLumpsum)}
                  stretchH='all'
                  autoRowSize={true}
                />
              </div>
            )}
            <Box display='flex' alignItems='center'>
              <Checkbox
                checked={sendAsLumpsum}
                onChange={toggleSendAsLumpSum}
                disabled={isPremiumRate}
                data-testid='lumpsum-checkbox'
              />
              <Typography>Send as lumpsum?</Typography>
            </Box>
            {reconType === JobTemplateReconType.Ap && (
              <>
                {cwModule !== CwTargetModule.ForwardingConsol && (
                  <Box display='flex' alignItems='center'>
                    <Checkbox
                      checked={overrideChargeDescriptionFlag}
                      onChange={toggleOverrideChargeDescriptionFlag}
                      data-testid={'override-checkbox'}
                    />
                    <Typography>Override Charge Description in CW</Typography>
                  </Box>
                )}
                <Box display='flex' alignItems='center'>
                  <Checkbox
                    checked={disableSendDueDateFlag}
                    onChange={toggleDisableSendDueDateFlag}
                    data-testid='disable-send-due-date'
                  />
                  <Typography>Do NOT send Due Date</Typography>
                </Box>
                <Box display='flex' alignItems='center'>
                  <Checkbox
                    checked={reconcileCriteriaFlag}
                    onChange={toggleReconcileCriteriaFlag}
                    data-testid={'recon-criteria-toggle'}
                  />
                  <Typography>Match by Charge Code, Vendor, and Invoice Number</Typography>
                </Box>
              </>
            )}
            <Box textAlign='right'>
              <Button
                variant='contained'
                color='primary'
                disabled={isSendingDisabled}
                onClick={sendJobToCW}
                data-testid='send-to-cw-dialog-btn'
              >
                Send to CW
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default SendToCWDialog
