// Whether the page (as a whole) is scrollable or not. If false, it's expected that at least one descendant
// has scroll instead.
import { createContext, FunctionComponent } from 'react'

type PageScrollableContextType = (pageScrollable: boolean) => void

type Props = {
  setPageScrollable: (pageScrollable: boolean) => void
}

export const PageScrollableContext = createContext((() => {}) as PageScrollableContextType)

export const PageScrollableProvider: FunctionComponent<Props> = ({
  children,
  setPageScrollable,
}) => {
  return (
    <PageScrollableContext.Provider value={setPageScrollable}>
      {children}
    </PageScrollableContext.Provider>
  )
}
