import { formatMaybeApolloError } from '@src/utils/errors'
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import { HotTable } from '@handsontable/react'
import { batch, useDispatch, useSelector } from 'react-redux'
import {
  getSaveTable,
  getHandsontableHooksAndActions,
  getSearchableRecordsValidationMap,
  getInputSearchableRecordColumns,
  SpreadsheetCellMeta,
} from '@src/utils/data-grid'
import { isChargeCodeField } from '@src/utils/searchable_record'
import {
  resetHighlightedBoxes,
  selectRowBox,
  setTableExtractionStep,
  updateHighlightedBoxes,
  updateTableLineItems,
} from '@src/redux-features/document_editor'
import {
  selectActiveMagicGrid,
  TableExtractionStep,
} from '@src/redux-features/document_editor/magic_grid'
import { selectRepeatableFieldsFromJobOrDocTable } from '@src/redux-features/document_editor/field'
import {
  selectActiveFilePage,
  selectActiveOcrData,
} from '@src/redux-features/document_editor/file_page'
import { selectActiveDocumentTable } from '@src/redux-features/document_editor/document_table'
import store, { RootState } from '@src/utils/store'
import {
  ApReconAutofillKey,
  DocumentTypeNode,
  FilePageType,
  InputSearchableRecordColumn,
  JobNode,
  Mutation,
  MutationSaveDocumentTableArgs,
  Query,
  QueryBatchSearchableRecordResultsArgs,
  QuerySearchableRecordResultsArgs,
  MutationSaveJobAndDocumentTablesArgs,
  QueryValidSearchableRecordResultsArgs,
  useChargeCodesByConfigAndVendorLazyQuery,
  useChargeCodeTaxesByApiPartnerLazyQuery,
} from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import { SAVE_DOCUMENT_TABLE } from '@src/graphql/mutations/document'
import {
  SEARCHABLE_RECORD_RESULTS,
  BATCH_SEARCHABLE_RECORD_RESULTS,
  SEARCHABLE_RECORD_RESULTS_FOR_VALIDATION,
} from '@src/graphql/queries/searchableRecord'
import { Skeleton } from '@material-ui/lab'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import createContextMenu from './ContextMenu'
import DataGridToolbar from './DataGridToolbar'
import { formatToInputDocumentTable, getColumnKeys } from '@src/utils/shipment_form'
import BulkReplaceDialog from './BulkReplaceDialog'
import { Box, makeStyles } from '@material-ui/core'
import useRerenderOnResize from '@src/components/data-grid/hooks/useRerenderOnResize'
import useRerenderOnCollapse from '@src/components/data-grid/hooks/useRerenderOnCollapse'
import {
  SUBTOTAL_ROW_HEADER,
  getSubtotalWidths,
  getSubtotalsFromRows,
  replaceCellValues,
} from '@src/components/data-grid/util'
import useBulkReplace from '@src/components/data-grid/hooks/useBulkReplace'
import useSaveHotkey from '@src/components/data-grid/hooks/useSaveHotkey'
import { buildSearchableRecordFilters } from '@src/utils/searchable_record'
import { magicGridColumnsAdapter } from '@src/redux-features/document_editor/magic_grid'
import { COLORS, HANDSONTABLE_ROW_HEIGHT } from '@src/utils/app_constants'
import {
  selectActiveJobOrDocTableLineItems,
  selectColumnHeaders,
  selectFormattedColumns,
  selectFormattedRows,
} from '@src/redux-features/document_editor/rows_columns_selectors'
import { LineItem } from '@src/utils/line_items'
import { rawLineItemsSelectors } from '../../redux-features/document_editor/line_items_table'
import { handleBeforeTableDatePaste } from '@src/utils/date'
import { selectActiveDocument } from '@src/redux-features/document_editor/document'
import { SAVE_JOB_AND_DOCUMENT_TABLES } from '@src/graphql/mutations/job'
import FastHotTable from '../fast-hot-table/FastHotTable'
import { ShipmentFormContext } from '@src/contexts/shipment_form_context'

import Handsontable from 'handsontable'
import { isEqual } from 'lodash'
import { ChargeCodeTax } from './types'
import { stateHasMainTable } from '@src/redux-features/document_editor/job_table'

const useStyles = makeStyles({
  tableWrapper: {
    overflow: 'hidden',
    flex: '1',
  },
  // using !important tag here to overwrite default borders and colors from Handsontable components
  subtotalRowHeader: {
    backgroundColor: 'yellow !important',
    borderTop: '1px solid #CCC !important',
  },
  fillerRowHeader: {
    backgroundColor: 'white !important',
    border: '0 !important',
  },
})

type Props = {
  job: JobNode
  documentType: DocumentTypeNode
  setIsUpdateChargeQuantityDialogOpen?: Dispatch<SetStateAction<boolean>>
}

/**
 * Expected flow of data:
 * User makes an edit to the handsontable -> before the change is reflected in the UI, we update our state and cancel the action (return false) -> we re-render the table using the updated state
 *
 * We must try to maintain a single source of truth.
 * That is to say we should always keep the redux state updated for every change made to the table,
 * and we should always refer to the state when performing logic (e.g. making requests to save, etc)
 * - i.e.: avoid using hotInstance.getData(), which takes data from the handsontable, and instead use getRows(), which takes data from the redux state
 *
 * Link to the design doc: https://www.notion.so/Handsontable-State-Management-Refactor-7c37307d0e3a4ae98f8ff311da075a5d
 */
const ExtractTable: FunctionComponent<Props> = ({
  job,
  documentType,
  setIsUpdateChargeQuantityDialogOpen,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const createBoxEnabled = useSelector(
    (state: RootState) => state.documentEditor.boxn.createBoxEnabled,
  )

  const hasMainTable = useSelector((state: RootState) => stateHasMainTable(state.documentEditor)!)

  const lineItems = useSelector(
    (state: RootState) => selectActiveJobOrDocTableLineItems(state.documentEditor)!,
  ) as LineItem[]

  const repeatableFields = useSelector((state: RootState) =>
    selectRepeatableFieldsFromJobOrDocTable(state.documentEditor),
  )!

  // isJobTableActive should always be false if the ExtractTable/ document table is being displayed
  const isJobTableActive = useSelector((state: RootState) => state.documentEditor.jobTableActive)

  const magicGridState = useSelector(
    (state: RootState) => selectActiveMagicGrid(state.documentEditor)!,
  )
  const activeDocument = useSelector((state: RootState) =>
    selectActiveDocument(state.documentEditor),
  )
  const activeDocumentTable = useSelector((state: RootState) =>
    selectActiveDocumentTable(state.documentEditor),
  )
  const activeFilePage = useSelector((state: RootState) =>
    selectActiveFilePage(state.documentEditor),
  )!
  const pages = useSelector((state: RootState) => state.documentEditor.pageFieldEditorState?.pages)
  const company = useSelector((state: RootState) => state.documentEditor.job?.jobTemplate?.company)
  const googleOcrData = useSelector((state: RootState) => selectActiveOcrData(state.documentEditor))
  const newGridEnabled = useSelector((state: RootState) => state.documentEditor.boxn.newGridEnabled)

  const { refetch: refetchSearchableRecords } = useQuery<
    Pick<Query, 'searchableRecordResults'>,
    QuerySearchableRecordResultsArgs
  >(SEARCHABLE_RECORD_RESULTS, {
    skip: true,
    context: {
      debounceKey: 'searchableRecordResults',
      debounceTimeout: 300,
    },
  })
  const { refetch: refetchBatchSearchableRecords } = useQuery<
    Pick<Query, 'batchSearchableRecordResults'>,
    QueryBatchSearchableRecordResultsArgs
  >(BATCH_SEARCHABLE_RECORD_RESULTS, { skip: true })
  const [saveDocumentTable] = useMutation<
    Pick<Mutation, 'saveDocumentTable'>,
    MutationSaveDocumentTableArgs
  >(SAVE_DOCUMENT_TABLE)
  const [
    fetchSearchableRecordsForValidation,
    { loading: validSearchableRecordResultsLoading, data: validSearchableRecordResultsData },
  ] = useLazyQuery<
    Pick<Query, 'validSearchableRecordResults'>,
    QueryValidSearchableRecordResultsArgs
  >(SEARCHABLE_RECORD_RESULTS_FOR_VALIDATION, {
    onError: (err) => {
      enqueueSnackbar(`Failed to validate searchable records: ${formatMaybeApolloError(err)}`, {
        variant: 'error',
      })
    },
  })

  const [saveJobAndDocumentTables] = useMutation<
    Pick<Mutation, 'saveJobAndDocumentTables'>,
    MutationSaveJobAndDocumentTablesArgs
  >(SAVE_JOB_AND_DOCUMENT_TABLES)

  const { getFormValues } = useContext(ShipmentFormContext)

  const vendorName = useMemo(() => {
    const metaFields = getFormValues?.() ?? {}
    const vendorFieldGroup = activeDocument?.documentFieldGroups?.edges
      .filter((docFieldGroup) => !docFieldGroup?.node?.fieldGroup?.repeatable)
      .map((docFieldGroup) => docFieldGroup?.node?.documentFields?.edges[0]?.node)
      .find(
        (docField) =>
          ApReconAutofillKey.Vendor.toLowerCase() === docField?.field?.autofillKey.toLowerCase(),
      )

    if (vendorFieldGroup?.field?.key) {
      return metaFields?.[vendorFieldGroup.field.key] ?? ''
    }
    return ''
  }, [activeDocument, getFormValues])

  const [chargeCodeTaxMap, setChargeCodeTaxMap] = useState<Record<string, ChargeCodeTax>>({})

  const [fetchChargeCodesByConfigAndVendor] = useChargeCodesByConfigAndVendorLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ chargeCodesByConfigAndVendor }) => {
      const taxMap: Record<string, ChargeCodeTax> = {}
      const newChargeCodeTaxMap = chargeCodesByConfigAndVendor.reduce(
        (chargeCodeTaxMapAcc, chargeCode) => {
          if (chargeCode.tax) {
            chargeCodeTaxMapAcc[chargeCode.code] = chargeCode.tax
          }
          return chargeCodeTaxMapAcc
        },
        taxMap,
      )
      setChargeCodeTaxMap(newChargeCodeTaxMap)
    },
  })

  const [fetchChargeCodesV2ByApiPartner] = useChargeCodeTaxesByApiPartnerLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ chargeCodesByApiPartner }) => {
      const taxMap: Record<string, ChargeCodeTax> = {}
      const newChargeCodeTaxMap = chargeCodesByApiPartner.reduce(
        (chargeCodeTaxMapAcc, chargeCode) => {
          if (chargeCode.tax) {
            chargeCodeTaxMapAcc[chargeCode.code] = chargeCode.tax
          }
          return chargeCodeTaxMapAcc
        },
        taxMap,
      )
      setChargeCodeTaxMap(newChargeCodeTaxMap)
    },
  })

  useEffect(() => {
    if (job?.jobTemplate?.apiPartner?.id) {
      const apiPartnerId = job.jobTemplate.apiPartner.id
      const usesChargeCodeV2 = job.jobTemplate.company?.usesChargeCodeV2 || false
      if (usesChargeCodeV2 && apiPartnerId) {
        void fetchChargeCodesV2ByApiPartner({
          variables: {
            apiPartnerId,
          },
        })
        return
      } else if (vendorName && apiPartnerId && !usesChargeCodeV2) {
        void fetchChargeCodesByConfigAndVendor({
          variables: {
            apiPartnerId,
            vendorName,
          },
        })
      }
    }
  }, [vendorName, job, fetchChargeCodesByConfigAndVendor, fetchChargeCodesV2ByApiPartner])

  const columns = useSelector((state: RootState) =>
    selectFormattedColumns(state.documentEditor, refetchSearchableRecords, enqueueSnackbar),
  )
  const colHeaders = useSelector((state: RootState) => selectColumnHeaders(state.documentEditor))
  const rows = useSelector((state: RootState) => selectFormattedRows(state.documentEditor))
  const subtotalRow = useMemo(() => getSubtotalsFromRows(rows, columns), [rows, columns])

  const currentTableExtractionStep = useSelector(
    (state: RootState) => state.documentEditor.currentTableExtractionStep,
  )

  const hotTableRef = useRef<HotTable>()
  const resizeListener = useRerenderOnResize(hotTableRef)
  useRerenderOnCollapse(hotTableRef)

  const subtotalTableRef = useRef<HotTable>()
  const subtotalResizeListener = useRerenderOnResize(subtotalTableRef)
  useRerenderOnCollapse(subtotalTableRef)

  const subtotalColWidths = useMemo(() => {
    if (hotTableRef.current) {
      return getSubtotalWidths(hotTableRef.current)
    }
    return []
  }, [hotTableRef, getSubtotalWidths, hotTableRef.current])

  const [originalNumRows, setOriginalNumRows] = useState(rows.length)
  const [currentActiveDocumentTableId, setCurrentActiveDocumentTableId] = useState(
    null as string | null,
  )
  const [lastColumnSelected, setlastColumnSelected] = useState(0)
  const dispatch = useDispatch()
  const { bulkReplaceOpen, setBulkReplaceOpen, openBulkReplace } = useBulkReplace()

  const hasLineItemsAndColumns = (rows.length > 0 && columns.length > 0) || false

  const saveCurrentTableCallback = useCallback(async (): Promise<void> => {
    const lineItems =
      rawLineItemsSelectors.selectAll(
        store.getState().documentEditor.lineItemsTableMap[activeDocumentTable!.id].lineItems,
      ) ?? []
    const inputDocumentTableToSave = formatToInputDocumentTable(
      activeDocumentTable!,
      lineItems,
      magicGridColumnsAdapter.getSelectors().selectAll(magicGridState.columns),
      true,
      getColumnKeys(columns),
    )
    try {
      await saveDocumentTable({
        variables: {
          jobId: job.id,
          documentTable: inputDocumentTableToSave,
          validateFields: false,
        },
      })
      enqueueSnackbar(`Successfully saved table`, { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Failed to save table with error ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
  }, [activeDocumentTable, columns, enqueueSnackbar, job.id, magicGridState, saveDocumentTable])

  const { saveTable } = useMemo(() => {
    return getSaveTable(
      saveCurrentTableCallback,
      hotTableRef,
      getColumnKeys(columns),
      saveJobAndDocumentTables,
      enqueueSnackbar,
    )
  }, [saveCurrentTableCallback, columns, saveJobAndDocumentTables, enqueueSnackbar])

  const contextMenu = useMemo(() => {
    return createContextMenu(
      lastColumnSelected + 1,
      repeatableFields,
      columns.map((col) => col.key),
      documentType.tableShowsPreset,
      activeFilePage.type === FilePageType.Excel,
      hasMainTable,
      dispatch,
      enqueueSnackbar,
    )
  }, [
    enqueueSnackbar,
    hasMainTable,
    lastColumnSelected,
    dispatch,
    activeFilePage.type,
    columns,
    repeatableFields,
    documentType.tableShowsPreset,
  ])

  useSaveHotkey(saveTable)

  const batchAutofillSearchableRecords = useCallback(async (): Promise<void> => {
    try {
      const descriptionColIdx = columns.findIndex((col) => col.autofillKey?.endsWith('description'))
      const searchableRecordColumnIndices = columns
        .map((col, colIdx): number => (col.searchableRecord ? colIdx : -1))
        .filter((colIdx): boolean => colIdx !== -1)
      const inputSearchableRecordColumns = searchableRecordColumnIndices.map(
        (colIdx): InputSearchableRecordColumn => {
          const col = columns[colIdx]
          return {
            searchableRecordId: col.searchableRecord!.id,
            filters: buildSearchableRecordFilters(
              col.searchableRecord,
              pages,
              company,
              job.jobTemplate?.apiPartnerId || job.jobTemplate?.apiPartner?.id,
            ),
            values: rows.map((row) => {
              if (col.autofillKey?.toLowerCase() === ApReconAutofillKey.ChargeCode.toLowerCase())
                return row[descriptionColIdx]
              return row[colIdx]
            }),
          } as InputSearchableRecordColumn
        },
      )
      const batchRecordResultData = await refetchBatchSearchableRecords({
        inputSearchableRecordColumns,
      })
      if (batchRecordResultData) {
        const results = batchRecordResultData.data.batchSearchableRecordResults
        const changes = results
          .filter((result) => !!result)
          .flatMap((result, resultIdx) => {
            const colIdx = searchableRecordColumnIndices[resultIdx]
            return rows.map((row, rowIdx) => {
              const oldVal = row[colIdx]
              const newVal = result!.values[rowIdx]
              return [rowIdx, colIdx, oldVal, newVal] as [number, number, string, string]
            })
          })
        // We set the table extraction step to idle here because we don't want to trigger the
        // this effect again when we update the table line items
        dispatch(setTableExtractionStep(TableExtractionStep.Idle))
        dispatch(
          updateTableLineItems({
            changes,
            source: 'edit',
            columns,
            googleOcrData,
            chargeCodeTaxMap,
          }),
        )
      }
    } catch (error) {
      enqueueSnackbar(
        `Failed to autofill upon extraction with error ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    }
  }, [
    columns,
    refetchBatchSearchableRecords,
    pages,
    company,
    job.jobTemplate?.apiPartnerId,
    rows,
    dispatch,
    googleOcrData,
    chargeCodeTaxMap,
    enqueueSnackbar,
  ])

  const finishSearchablerecordsBatchAutofill = useCallback(async (): Promise<void> => {
    await batchAutofillSearchableRecords()
  }, [batchAutofillSearchableRecords])

  useEffect(() => {
    if (currentTableExtractionStep === TableExtractionStep.BatchAutofilling) {
      void finishSearchablerecordsBatchAutofill()
    }
  }, [finishSearchablerecordsBatchAutofill, currentTableExtractionStep])

  const {
    beforeCopy,
    beforeColumnMove,
    beforeRemoveRow,
    beforeCreateRow,
    beforeChange,
    beforeOnCellContextMenu,
    afterLoadData,
    unhighlightRowAndFieldBoxes,
    getCellMetaForIndex,
  } = useMemo(
    () =>
      getHandsontableHooksAndActions(
        columns,
        googleOcrData,
        createBoxEnabled,
        hotTableRef,
        isJobTableActive,
        chargeCodeTaxMap,
      ),
    [columns, googleOcrData, createBoxEnabled, isJobTableActive, chargeCodeTaxMap],
  )

  useEffect(() => {
    if (activeFilePage.type === FilePageType.Excel && lineItems.length === 0) {
      // we always need at least 1 blank row for excel, otherwise we can't copy from
      // the document
      beforeCreateRow(0, 1, 'ContextMenu.below')
    }
  }, [activeFilePage, lineItems, beforeCreateRow])

  useEffect(() => {
    if (activeDocumentTable?.id !== currentActiveDocumentTableId) {
      setCurrentActiveDocumentTableId(activeDocumentTable?.id || null)
      setOriginalNumRows(rows.length)
    }
  }, [activeDocumentTable?.id, currentActiveDocumentTableId, rows.length])

  const modifyColWidth = useCallback(
    (width: number, column: number): number => {
      if (
        isChargeCodeField(repeatableFields[column]) &&
        columns[column] &&
        columns[column].handsontable
      ) {
        columns[column].handsontable.columns[1].width =
          width - columns[column].handsontable.columns[0].width
      }
      return width
    },
    [repeatableFields, columns],
  )

  const afterSelection = useCallback(
    (startRow: number, startCol: number, endRow: number, endCol: number): void => {
      if (hotTableRef.current) {
        const selectedCells = hotTableRef.current.hotInstance.getSelectedLast()
        setlastColumnSelected(selectedCells ? selectedCells[3] : 0)
      }
      if (newGridEnabled) {
        if (startRow === endRow) {
          batch(() => {
            dispatch(selectRowBox(lineItems[startRow].id))
            dispatch(resetHighlightedBoxes())
          })
        } else {
          batch(() => {
            dispatch(selectRowBox(null))
            dispatch(
              updateHighlightedBoxes({
                startRow,
                startCol,
                endRow,
                endCol,
                columnKeys: columns.map((column) => column.key),
                isJobTable: false,
              }),
            )
          })
        }
      }
    },
    [columns, dispatch, newGridEnabled],
  )

  const shouldShowSubtotals = job.jobTemplate.subtotalsRowEnabled

  const afterGetRowHeader = useCallback((row: number, TH: Element): void => {
    TH.className = classes.subtotalRowHeader
  }, [])

  const handleAfterScrollHorizontally = useCallback(
    (table: Handsontable | undefined, tableToScroll: Handsontable | undefined): void => {
      if (!table || !tableToScroll) return
      const originalTable = table
      const autoColSize = originalTable.getPlugin('autoColumnSize')
      const firstCol = autoColSize.getFirstVisibleColumn()
      tableToScroll.scrollViewportTo(undefined, firstCol, undefined, undefined)
    },
    [],
  )

  const afterContextMenuShow = useCallback((context: any): void => {
    const contextMenu = context.menu.hotMenu
    const numContextMenuOptions = contextMenu.countRows()

    const colIdx = 0
    const options: number[] = new Array(numContextMenuOptions).fill(0)
    options.forEach((_, rowIdx) => {
      const contextMenuOptionCell = contextMenu.getCell(rowIdx, colIdx)
      const optionText: string = String(contextMenuOptionCell.innerText)
        .toLowerCase()
        .replaceAll(' ', '-')
      const dataTestId = `context-menu-option-${optionText}`
      contextMenu.getCell(rowIdx, colIdx).setAttribute('data-testid', dataTestId)
    })
  }, [])

  const hooks = useMemo(
    () =>
      ({
        beforeChange,
        beforeColumnMove,
        beforeCreateRow,
        beforeRemoveRow,
        beforeCopy,
        beforeOnCellContextMenu,
        afterLoadData,
        modifyColWidth,
        afterSelection,
        afterContextMenuShow,
        afterDeselect: newGridEnabled ? unhighlightRowAndFieldBoxes : undefined,
        beforePaste: (data, coords) => handleBeforeTableDatePaste(columns, data, coords[0]),
        afterScrollHorizontally: () =>
          handleAfterScrollHorizontally(
            hotTableRef.current?.hotInstance,
            subtotalTableRef.current?.hotInstance,
          ),
      }) as Handsontable.Hooks,
    [
      afterLoadData,
      afterSelection,
      beforeChange,
      beforeColumnMove,
      beforeCopy,
      beforeCreateRow,
      beforeOnCellContextMenu,
      beforeRemoveRow,
      columns,
      modifyColWidth,
      newGridEnabled,
      unhighlightRowAndFieldBoxes,
      handleAfterScrollHorizontally,
    ],
  )

  const [searchableRecordsValidationMap, setSearchableRecordsValidationMap] = useState(
    {} as Record<number, Record<number, boolean>>,
  )
  const [inputSearchableRecordColumns, setInputSearchableRecordColumns] = useState(
    [] as InputSearchableRecordColumn[],
  )

  useEffect(() => {
    void fetchSearchableRecordsForValidation({
      variables: { searchableRecordInputs: inputSearchableRecordColumns },
    })
  }, [inputSearchableRecordColumns, fetchSearchableRecordsForValidation])

  useEffect(() => {
    /**
     * We want to update the searchableRecordsValidationMap whenever the rows change
     * but if we're going to need to query for the updated validSearchableRecordResultsData (if the input to the query changes),
     * we want to wait for that to finish first before we update the map
     */
    const inputSearchableRecordColumnsFromRows = getInputSearchableRecordColumns(
      rows,
      columns,
      pages,
      company,
      job?.jobTemplate.apiPartnerId,
    )
    if (!isEqual(inputSearchableRecordColumnsFromRows, inputSearchableRecordColumns)) {
      setInputSearchableRecordColumns(inputSearchableRecordColumnsFromRows)
    } else if (!validSearchableRecordResultsLoading && validSearchableRecordResultsData) {
      setSearchableRecordsValidationMap(
        getSearchableRecordsValidationMap(
          rows,
          columns,
          validSearchableRecordResultsData.validSearchableRecordResults,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rows,
    columns,
    pages,
    company,
    job?.jobTemplate.apiPartnerId,
    validSearchableRecordResultsData,
    validSearchableRecordResultsLoading,
  ])

  const getCellMetaAfterValidation = useCallback(
    (rowIdx: number, colIdx: number): SpreadsheetCellMeta => {
      return getCellMetaForIndex(
        rowIdx,
        colIdx,
        searchableRecordsValidationMap,
      ) as SpreadsheetCellMeta
    },
    [searchableRecordsValidationMap, getCellMetaForIndex],
  )

  const settings = useMemo(() => {
    return {
      cells: getCellMetaAfterValidation,
      comments: true,
      colHeaders,
      rowHeaders: true,
      contextMenu,
      columns,
      rowHeights: HANDSONTABLE_ROW_HEIGHT,
      // we can try to use viewportColumnRenderingOffset instead of manually calculating each row height
      // to improve performance
      // related ticket: https://expedock.atlassian.net/browse/PD-925
      viewportColumnRenderingOffset: columns.length,
      viewportRowRenderingOffset: 50,
      stretchH: 'all',
      manualColumnResize: true,
      manualColumnMove: true,
      persistentState: false,
    } as Handsontable.GridSettings
  }, [colHeaders, columns, contextMenu, getCellMetaAfterValidation])

  const subtotalCellRenderer = function (
    instance: Handsontable,
    td: HTMLElement,
    row: number,
    col: number,
    prop: string | number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    cellProperties: Handsontable.GridSettings,
  ): void {
    Handsontable.renderers.TextRenderer(instance, td, row, col, prop, value, cellProperties)
    td.style.background = COLORS.PALE_YELLOW
    td.style.borderTop = '1px solid #CCC'
    // Handsontable typing has wrong return type lol
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any as Handsontable.renderers.Base

  const subtotalHooks: Handsontable.Hooks = useMemo(
    () => ({
      afterGetRowHeader,
    }),
    [afterGetRowHeader],
  )

  const getSubtotalCellMeta = useCallback((): SpreadsheetCellMeta => {
    return {
      readOnly: true,
      renderer: subtotalCellRenderer,
    }
  }, [subtotalCellRenderer])

  const subtotalSettings: Handsontable.GridSettings = useMemo(() => {
    return {
      cells: getSubtotalCellMeta,
      columns,
      colHeaders,
      colWidths: subtotalColWidths,
      rowHeaders: [SUBTOTAL_ROW_HEADER],
      rowHeights: HANDSONTABLE_ROW_HEIGHT,
      viewportColumnRenderingOffset: columns.length,
      viewportRowRenderingOffset: 50,
      stretchH: 'all',
      persistentState: false,
    }
  }, [columns, getSubtotalCellMeta, subtotalColWidths, colHeaders])

  return (
    <>
      {resizeListener}
      {subtotalResizeListener}
      <DataGridToolbar
        job={job}
        numRows={rows.length}
        originalNumRows={originalNumRows}
        openBulkReplace={openBulkReplace}
        saveTable={saveTable}
        jobTableActive={isJobTableActive}
        setIsUpdateChargeQuantityDialogOpen={setIsUpdateChargeQuantityDialogOpen}
      />
      {(currentTableExtractionStep !== TableExtractionStep.Idle && (
        <Skeleton variant='rect' width='100%' height='100vh' />
      )) ||
        (!hasLineItemsAndColumns && (
          <h2>Please box and extract table values in each row first.</h2>
        )) || (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='stretch'
            height='100%'
            className={classes.tableWrapper}
            overflow='hidden'
            flex={1}
          >
            <Box overflow='hidden' flex={1} id='extract-table-box' data-testid='extract-table-box'>
              <FastHotTable
                hotTableRef={hotTableRef}
                data={rows}
                settings={settings}
                hooks={hooks}
              />
            </Box>
            {shouldShowSubtotals && (
              <Box height='20%' overflow='hidden' data-testid='subtotal-table-box'>
                <FastHotTable
                  hotTableRef={subtotalTableRef}
                  data={[subtotalRow]}
                  settings={subtotalSettings}
                  hooks={subtotalHooks}
                />
              </Box>
            )}
          </Box>
        )}
      {bulkReplaceOpen && (
        <BulkReplaceDialog
          open={bulkReplaceOpen}
          rows={rows}
          cols={columns}
          handleClickCancel={() => setBulkReplaceOpen(false)}
          replace={(changes: [number, number, string][]) => replaceCellValues(hotTableRef, changes)}
        />
      )}
    </>
  )
}

export default ExtractTable
