import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import {
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  makeStyles,
  Switch,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import theme from '@src/utils/theme'
import {
  useBranchesLazyQuery,
  useDepartmentsLazyQuery,
  useOperatorsLazyQuery,
  useSalesRepsLazyQuery,
  useUpdateDashboardCompanyUserIamMutation,
  useUpdateUserMutation,
} from '@src/graphql/types'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import UserRoleAutocomplete from './UserRoleAutocomplete'
import CompanyAutocomplete from './CompanyAutocomplete'
import { useSnackbar } from 'notistack'
import { AdminUserProfileNode } from '@src/graphql/types'
import { FormError, FormValues, validateFields } from './util'
import ShipmentUserDataAutocomplete from './ShipmentUserDataAutocomplete'
import DefaultDashboardsWhitelist from './default-dashboards-whitelist/DefaultDashboardsWhitelist'
import { reportRollbarError } from '@src/utils/observability/rollbar'
import { isExpedockUser } from './util'
import { UserProfileContext } from '@src/auth/UserProfileContext'
import { USER_COMPANIES } from '@src/graphql/queries/company'

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  submitButton: {
    textAlign: 'right',
  },
  formLine: {
    marginBottom: theme.spacing(1),
  },
  switch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    marginBottom: theme.spacing(1),
  },
})

type Props = {
  isOpen: boolean
  close: () => void
  user: AdminUserProfileNode
  refetchUsers: () => Promise<unknown>
  isExpedockAdmin: boolean
}

const UpdateUserDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  user,
  refetchUsers,
  isExpedockAdmin,
}) => {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const [isCreating, setIsCreating] = useState(false)
  const [formCompanyId, setFormCompanyId] = useState<string | undefined>(user.company?.id)
  const [formError, setFormError] = useState<FormError>({
    company: '',
    roles: '',
    operatorCompanies: '',
  })

  const formMethods = useForm<FormValues>({
    defaultValues: {
      company: user.company,
      operatorCompanies: isExpedockAdmin ? user.companies : [],
      metabaseDashboardId: isExpedockAdmin ? user.metabaseDashboardId : '',
    },
  })

  const { userProfile: loggedInUser } = useContext(UserProfileContext)
  const [updateUser] = useUpdateUserMutation({
    refetchQueries: user.email === loggedInUser?.email ? [{ query: USER_COMPANIES }] : [],
  })
  const [updateDashboardCompanyUserIam] = useUpdateDashboardCompanyUserIamMutation()

  const [fetchSalesRepsData, { data: salesRepsData }] = useSalesRepsLazyQuery()
  const [fetchOperatorsData, { data: operatorsData }] = useOperatorsLazyQuery()
  const [fetchBranchesData, { data: branchesData }] = useBranchesLazyQuery()
  const [fetchDepartmentsData, { data: departmentsData }] = useDepartmentsLazyQuery()

  const isGoogle = user.auth0Id.startsWith('google')

  useEffect(() => {
    if (!formCompanyId) return
    const options = { variables: { companyId: formCompanyId } }
    void fetchSalesRepsData(options)
    void fetchOperatorsData(options)
    void fetchBranchesData(options)
    void fetchDepartmentsData(options)
  }, [
    formCompanyId,
    fetchSalesRepsData,
    fetchOperatorsData,
    fetchBranchesData,
    fetchDepartmentsData,
  ])

  const onSubmit = async (data: FormValues): Promise<void> => {
    if (!validateFields(data, setFormError)) {
      // Broadcast company-related errors since the input field is hidden for non-Expedock admins.
      // WHY: This condition will be triggered only if the selected non-ExpedockAdmin user does not
      // belong to any company. It shouldn't happen but we should at least fail loudly when
      // it does unexpectedly.
      if (formError.company && !isExpedockAdmin) {
        enqueueSnackbar(formError.company, { variant: 'error' })
        reportRollbarError(formError.company)
      }
      return
    }

    setIsCreating(true)
    const inputUser = {
      email: data.email,
      name: data.name || '',
      roles: Array.isArray(data.roles) ? data.roles : [data.roles],
      companyId: data.company?.id || null,
      operatorCompanyIds: data.operatorCompanies.map((company) => company.id),
      metabaseDashboardId: data.metabaseDashboardId,
      salesReps: data.salesReps,
      operationsReps: data.operationsReps,
      branches: data.branches,
      departments: data.departments,
      onlyViewAssignedShipments: data.onlyViewAssignedShipments,
      onlyViewDashboardCompany: !data.useOperatorCompanies,
      defaultDashboardsWhitelist: [
        ...data.accounting,
        ...data.businessPerformance,
        ...data.dashboard,
        ...data.explore,
        ...data.operations,
        ...data.sales,
      ].map((dashboard) => (dashboard.type === 'builtin' ? dashboard.id : dashboard.displayName)),
    }
    try {
      await updateDashboardCompanyUserIam({
        variables: {
          inputUser: inputUser,
          companyId: user.company?.id || data.company?.id,
        },
      })
      await updateUser({
        variables: {
          auth0Id: user.auth0Id,
          inputUser: {
            ...inputUser,
          },
        },
      })
      void refetchUsers()
      close()
    } catch (e) {
      enqueueSnackbar(formatMaybeApolloError(e), { variant: 'error' })
    } finally {
      setIsCreating(false)
    }
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialog }}
      onClose={close}
      open={isOpen}
    >
      <IconButton
        aria-label='close'
        onClick={close}
        disabled={isCreating}
        className={classes.close}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle disableTypography>
        <Typography variant='h3'>Update User</Typography>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <FormControl fullWidth className={classes.formLine}>
            <Controller
              render={({ field: { value, onBlur, onChange } }) => (
                <TextField
                  variant='outlined'
                  label='Email'
                  value={value}
                  disabled
                  fullWidth
                  onBlur={onBlur}
                  onChange={(event) => onChange(event.target.value)}
                />
              )}
              name='email'
              defaultValue={user.email}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formLine}>
            <Controller
              render={({ field: { value, onBlur, onChange } }) => (
                <TextField
                  variant='outlined'
                  label='Name'
                  value={value}
                  disabled={isGoogle}
                  fullWidth
                  onBlur={onBlur}
                  onChange={(event) => onChange(event.target.value)}
                />
              )}
              name='name'
              defaultValue={user.name}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formLine}>
            <UserRoleAutocomplete
              fieldName='roles'
              friendlyName='Roles'
              formError={formError}
              defaultValue={user.roles}
              showAllRoles={isExpedockAdmin}
              multiple={isExpedockAdmin}
            />
          </FormControl>
          {isExpedockAdmin && (
            <>
              <FormControl fullWidth className={classes.formLine}>
                <CompanyAutocomplete
                  fieldName='company'
                  friendlyName='Dashboard Company'
                  formError={formError}
                  multiple={false}
                  setFormCompanyId={setFormCompanyId}
                />
              </FormControl>
              <FormControl fullWidth className={classes.formLine}>
                <CompanyAutocomplete
                  fieldName='operatorCompanies'
                  friendlyName='Operator Companies'
                  multiple={true}
                  formError={formError}
                />
              </FormControl>
              {isExpedockUser(user.email) && (
                <FormControl fullWidth className={classes.switch}>
                  <Controller
                    render={({ field: { value, onBlur, onChange } }) => (
                      <FormControlLabel
                        value={value}
                        control={
                          <Switch
                            checked={value}
                            onBlur={onBlur}
                            onChange={(_, val) => onChange(val)}
                          />
                        }
                        label='Use operator companies for To-Do Dashboard'
                        labelPlacement='start'
                      />
                    )}
                    name='useOperatorCompanies'
                    defaultValue={!user.onlyViewDashboardCompany}
                  />
                </FormControl>
              )}
              <FormControl fullWidth className={classes.formLine}>
                <Controller
                  render={({ field: { value, onBlur, onChange } }) => (
                    <TextField
                      variant='outlined'
                      label='Metabase Dashboard ID'
                      value={value}
                      fullWidth
                      onBlur={onBlur}
                      onChange={(event) => onChange(event.target.value)}
                    />
                  )}
                  name='metabaseDashboardId'
                  defaultValue={user.metabaseDashboardId}
                />
              </FormControl>
            </>
          )}
          <FormControl fullWidth className={classes.formLine}>
            <ShipmentUserDataAutocomplete
              fieldName='salesReps'
              friendlyName='Sales Rep'
              defaultValue={user.salesReps}
              options={salesRepsData?.salesReps}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formLine}>
            <ShipmentUserDataAutocomplete
              fieldName='operationsReps'
              friendlyName='Operator'
              defaultValue={user.operationsReps}
              options={operatorsData?.operators}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formLine}>
            <ShipmentUserDataAutocomplete
              fieldName='branches'
              friendlyName='Branch'
              defaultValue={user.branches}
              options={branchesData?.branches}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formLine}>
            <ShipmentUserDataAutocomplete
              fieldName='departments'
              friendlyName='Department'
              defaultValue={user.departments}
              options={departmentsData?.departments}
            />
          </FormControl>
          <FormControl fullWidth className={classes.switch}>
            <Controller
              render={({ field: { value, onBlur, onChange } }) => (
                <FormControlLabel
                  value={value}
                  control={
                    <Switch
                      checked={value}
                      onBlur={onBlur}
                      onChange={(event, val) => onChange(val)}
                    />
                  }
                  label='Can only access shipments they are assigned to'
                  labelPlacement='start'
                />
              )}
              name='onlyViewAssignedShipments'
              defaultValue={user.onlyViewAssignedShipments}
            />
          </FormControl>
          <DefaultDashboardsWhitelist
            companyId={formCompanyId}
            defaultDashboardsWhitelist={user.defaultDashboardsWhitelist}
          />
          <Button
            disabled={isCreating}
            variant='outlined'
            onClick={formMethods.handleSubmit(onSubmit)}
          >
            Update
          </Button>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateUserDialog
