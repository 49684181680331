import { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/styles'
import { TaskNode } from '@src/graphql/types'
import theme from '@src/utils/theme'
import { COLORS } from '@src/utils/app_constants'
import { getJobCountByStatus } from '@src/utils/task'
import { clsx } from 'clsx'

type Props = {
  task: TaskNode
}
const useStyles = makeStyles({
  numberCircle: {
    width: `${theme.spacing(4)}px`,
    lineHeight: `${theme.spacing(2)}px`,
    borderRadius: '15% 15% 0% 0%',
    textAlign: 'center',
    fontSize: `${theme.spacing(1.5)}px`,
    borderTop: '1px solid gray',
    borderLeft: '1px solid gray',
    borderRight: '1px solid gray',
  },
  numberCircleFilled: {
    backgroundColor: COLORS.PALE_BLUE,
  },
  markers: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
  },
})

const TaskJobMarkers: FunctionComponent<Props> = ({ task }) => {
  const classes = useStyles()
  const jobsByStatus = getJobCountByStatus(task)
  const taskStatus = task.status
  return (
    <div className={classes.markers}>
      {Object.keys(jobsByStatus).map((status) => (
        <div
          key={status}
          className={clsx(classes.numberCircle, {
            [classes.numberCircleFilled]: taskStatus === status,
          })}
        >
          {jobsByStatus[status]}
        </div>
      ))}
    </div>
  )
}

export default TaskJobMarkers
