import { makeStyles } from '@material-ui/styles'
import { FunctionComponent } from 'react'
import { ChargeCodeUploadAsyncTaskNode, ChargeCodeUploadAsyncStatus } from '@src/graphql/types'
import theme from '@src/utils/theme'
import { milliSecondsToHumanReadableString } from '@src/utils/date'
import { Box, LinearProgress, Theme, Typography } from '@material-ui/core'

type Props = {
  chargeCodeUploadAsyncTask: ChargeCodeUploadAsyncTaskNode
}

const useStyles = makeStyles<Theme>({
  progressText: {
    height: 30,
    whiteSpace: 'nowrap',
    width: '10%',
    position: 'absolute',
    color: 'white',
  },
  progressBar: {
    height: 30,
    width: '90%',
  },
  uploadTimeLeft: {
    color: theme.palette.grey[500],
  },
  errorText: {
    color: theme.palette.error.main,
  },
  progressBarWrapper: {
    width: '65%',
    margin: '0 auto',
  },
  uploadProgressText: {
    paddingBottom: theme.spacing(4),
  },
})

const ImportProgressBar: FunctionComponent<Props> = ({ chargeCodeUploadAsyncTask }) => {
  const classes = useStyles()
  const getEstimatedTimeLeft = (): string => {
    if (!chargeCodeUploadAsyncTask!.percentDone!) {
      return 'calculating...'
    }
    const elapsedTime = Date.now() - new Date(chargeCodeUploadAsyncTask!.dateCreated).getTime()
    const estimatedTimeLeft =
      (elapsedTime / chargeCodeUploadAsyncTask!.percentDone!) *
      (100 - chargeCodeUploadAsyncTask!.percentDone!)
    return milliSecondsToHumanReadableString(estimatedTimeLeft)
  }

  return (
    <div className={classes.progressBarWrapper}>
      <Typography className={classes.uploadProgressText} variant='h3'>
        Upload Progress
      </Typography>
      <Box display='flex'>
        <LinearProgress
          variant='determinate'
          value={chargeCodeUploadAsyncTask.percentDone || 0}
          className={classes.progressBar}
        />
        <Typography className={classes.progressText} variant='h5'>
          {chargeCodeUploadAsyncTask.percentDone}% complete
        </Typography>
      </Box>
      <>
        {chargeCodeUploadAsyncTask.status === ChargeCodeUploadAsyncStatus.Pending && (
          <>
            <Typography className={classes.uploadTimeLeft} variant='h5'>
              {`Estimated time left: [${getEstimatedTimeLeft()}]`}
            </Typography>
          </>
        )}
        {chargeCodeUploadAsyncTask.status === ChargeCodeUploadAsyncStatus.Done && (
          <>
            <div>
              <Typography variant='h5'>Mapping of charge codes to vendors completed</Typography>
            </div>
            <Typography className={classes.uploadTimeLeft} variant='h5'>
              You may now test the initial invoices in the Customer Set-up screen.
            </Typography>
          </>
        )}
        {chargeCodeUploadAsyncTask.status === ChargeCodeUploadAsyncStatus.Error && (
          <>
            <div>
              <Typography className={classes.errorText} variant='h5'>
                Mapping Stopped
              </Typography>
            </div>
            <Typography className={classes.uploadTimeLeft} variant='h5'>
              {chargeCodeUploadAsyncTask.errorMessage ||
                'Please get in touch with the Product or Engineering teams.'}
            </Typography>
          </>
        )}
      </>
    </div>
  )
}

export default ImportProgressBar
