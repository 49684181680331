import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useCallback, useRef, useState } from 'react'
import { JOB_OPERATORS, USER_JOB_TEMPLATES } from '@src/graphql/queries/job'
import { CREATE_EMPTY_JOB } from '@src/graphql/mutations/job'
import { useSnackbar } from 'notistack'
import {
  JobTemplateNode,
  ManualProduct,
  Mutation,
  MutationCreateEmptyJobArgs,
  Query,
  QueryUserJobTemplatesArgs,
  TaskNode,
  UserNode,
} from '@src/graphql/types'
import { useMutation, useQuery } from '@apollo/client'
import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { LogEventType } from '@src/utils/observability/LogEventType'
import { useAuth0 } from '@auth0/auth0-react'

type Props = {
  task: TaskNode
  refetchJobs: () => unknown
}

const useStyles = makeStyles({
  option: { fontSize: 14 },
})

const CreateJobRow: FunctionComponent<Props> = ({ refetchJobs, task }) => {
  const { logEvent } = useEventLogger()
  const [actionStart, setActionStart] = useState(null as Date | null)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const jobNameRef = useRef<HTMLInputElement>()
  const [isCreatingJob, setIsCreatingJob] = useState(false)
  const [manualProduct, setManualProduct] = useState(ManualProduct.Product as ManualProduct)
  const [jobName, setJobName] = useState('' as string)
  const [jobTypeId, setJobTypeId] = useState(null as null | string)
  const { loading: jobTemplatesLoading, data: jobTemplates } = useQuery<
    Pick<Query, 'userJobTemplates'>,
    QueryUserJobTemplatesArgs
  >(USER_JOB_TEMPLATES)
  const { data: operatorsData, loading: isLoadingOperators } =
    useQuery<Pick<Query, 'jobOperators'>>(JOB_OPERATORS)
  const [createEmptyJob] = useMutation<
    Pick<Mutation, 'createEmptyJob'>,
    MutationCreateEmptyJobArgs
  >(CREATE_EMPTY_JOB, {
    update: (cache) => {
      cache.modify({
        fields: {
          // Invalidate countByJobStatus field and refreshes cache
          countByJobStatus() {},
        },
      })
    },
    onCompleted: (jobData) => {
      void logEvent(LogEventType.CREATE_JOB, {
        job_id: jobData.createEmptyJob!.job.id,
        job_name: jobName,
        job_template_id: jobTypeId,
        task_id: task.id,
        company_id: task.company.id,
        action_start: actionStart,
        action_end: new Date(),
        success: true,
        error_message: '',
      })
      setActionStart(null)
    },
  })

  const { user } = useAuth0()
  const userEmail = user?.email
  const operators = (operatorsData?.jobOperators || []) as UserNode[]
  // Note: in almost all jobs, the owner is the user creating the job.
  // The only exceptions are Customs declaration (RAG) jobs.
  // See: https://insights.expedock.com/question/7299-all-job-templates-creator-owner
  // And in most cases, the QA is the user creating the job.
  // See: https://insights.expedock.com/question/7300-all-job-templates-creator-qa
  const defaultOwnerQA = operators.find((operator) => operator.email === userEmail) || null
  const [owner, setOwner] = useState(defaultOwnerQA as null | UserNode)
  const [qa, setQA] = useState(defaultOwnerQA as null | UserNode)

  const jobTypes =
    ((jobTemplates?.userJobTemplates
      ?.filter(
        (jobTemplate) => jobTemplate.company!.id === task.company!.id && !jobTemplate.dateDeleted,
      )
      .sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1)) ||
      []) as JobTemplateNode[]) || []

  const createJob = async (): Promise<void> => {
    try {
      setIsCreatingJob(true)
      // These variables are guaranteed to not be null because of validateFields
      const variables = {
        jobTemplateId: jobTypeId!,
        jobName: jobName!,
        clientReferenceNo: task.taskReferenceId!,
        ownerId: owner?.id,
        qaId: qa?.id,
        manualProduct: manualProduct.toLowerCase()!,
        dateReceived: task.dateReceived,
        message: task.message ?? '',
        taskId: task.id!,
      }
      await createEmptyJob({ variables })
      setJobName('')
      setIsCreatingJob(false)
      enqueueSnackbar(`Job Successfully Created!`, {
        variant: 'success',
        autoHideDuration: 5000,
      })
      refetchJobs()
      jobNameRef?.current?.focus()
    } catch (error) {
      setIsCreatingJob(false)
      const errorMessage = `Error while creating job: ${formatMaybeApolloError(error)}`
      enqueueSnackbar(errorMessage, { variant: 'error' })

      void logEvent(LogEventType.CREATE_JOB, {
        job_id: null,
        job_name: jobName,
        job_template_id: jobTypeId,
        task_id: task.id,
        company_id: task.company.id,
        action_start: actionStart,
        action_end: new Date(),
        success: false,
        error_message: errorMessage,
      })
      setActionStart(null)
    }
  }

  const onFieldClick = useCallback(() => {
    if (!actionStart) {
      setActionStart(new Date())
    }
  }, [actionStart])

  return (
    <TableRow>
      <TableCell />
      <TableCell />
      <TableCell>
        <TextField
          value={jobName}
          inputRef={jobNameRef}
          onChange={(e) => setJobName(e.target.value)}
          onClick={onFieldClick}
          onKeyPress={async (event): Promise<void> => {
            const isJobFormValid = jobTypeId && jobName
            if (event.key === 'Enter' && isJobFormValid) {
              await createJob()
            }
          }}
        />
      </TableCell>
      <TableCell>
        <Select
          fullWidth
          className={classes.option}
          value={jobTypeId ?? ''}
          onClick={onFieldClick}
          onChange={(e) => setJobTypeId(e.target.value as string)}
          disabled={isCreatingJob || jobTemplatesLoading}
          placeholder='Job Type'
        >
          {jobTypes.map((currJobType) => (
            <MenuItem key={currJobType?.id || ''} value={currJobType?.id || ''}>
              {currJobType?.name || ''}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        <Autocomplete
          value={owner}
          classes={{ option: classes.option, input: classes.option }}
          options={operators || []}
          getOptionLabel={(option) => option.email || ''}
          disabled={isCreatingJob || isLoadingOperators}
          onClick={onFieldClick}
          onChange={(_, newOwner) => setOwner(newOwner || null)}
          renderInput={(params) => <TextField {...params} />}
        />
      </TableCell>
      <TableCell>
        <Autocomplete
          value={qa}
          classes={{ option: classes.option, input: classes.option }}
          options={operators || []}
          getOptionLabel={(option) => option.email || ''}
          disabled={isCreatingJob || isLoadingOperators}
          onClick={onFieldClick}
          onChange={(_, newQa) => setQA(newQa || null)}
          renderInput={(params) => <TextField {...params} />}
        />
      </TableCell>
      <TableCell>
        <Select
          value={manualProduct}
          onClick={onFieldClick}
          onChange={(e) => setManualProduct(e.target.value as ManualProduct)}
        >
          {Object.values(ManualProduct).map((value) => (
            <MenuItem key={value} value={value}>
              {value[0]}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell align='center'>
        <Button
          variant='contained'
          color='primary'
          size='small'
          disabled={!jobTypeId || !jobName}
          onClick={createJob}
        >
          Create
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default CreateJobRow
