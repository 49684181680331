import { FunctionComponent } from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import theme from '@src/utils/theme'
import RoundedButton from '@src/components/RoundedButton'

type Props = {
  newVendor: () => void
}

const useStyles = makeStyles<Theme>({
  newVendorButton: {
    background: 'white',
    color: theme.palette.grey[500],
    border: `1px solid ${theme.palette.grey[500]}`,
  },
})

const NewVendorButton: FunctionComponent<Props> = ({ newVendor }) => {
  const classes = useStyles()
  return (
    <RoundedButton variant='contained' onClick={newVendor} className={classes.newVendorButton}>
      + Vendor
    </RoundedButton>
  )
}

export default NewVendorButton
