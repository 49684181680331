import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { HotTable } from '@handsontable/react'
import CenteredCircularProgress from './centered-circular-progress/CenteredCircularProgress'
import {
  ApiExportType,
  ApReconAutofillKey,
  CwTargetModule,
  InputDocumentTable,
  JobTemplateReconType,
  ReconcileApInvoiceJob,
} from '@src/graphql/types'
import { CargowiseOpsType } from '@src/utils/cargowise/types'
import APInvoiceReconResultTables from './reconciliation-dialog/APInvoiceReconResultTables'
import useReconData from '@src/hooks/useReconData'
import { isEmpty } from 'lodash'
import { getLumpsumInputTable, getLumpsumTable } from '@src/utils/lumpsum'
import { stringifyList } from '@src/utils/string'
import { FindShipmentResultDisplay } from '@src/utils/recon/FindShipmentResultDisplay'
import { convertCwTargetModuleDisplayTextToCwTargetModule } from '@src/utils/recon/ap_recon'

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  dialogLarge: {
    maxWidth: '97vw',
    maxHeight: '97vh',
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

type Props = {
  close: () => void
  jobId: string
  apiExportType: ApiExportType | null
  handleExport: (
    exportType: ApiExportType,
    lumpsum?: InputDocumentTable | null,
    overrideChargeDescriptionFlag?: boolean | null,
  ) => void
}

const SendToTMSDialog: FunctionComponent<Props> = ({
  close,
  jobId,
  apiExportType,
  handleExport,
}) => {
  const reconType = JobTemplateReconType.Ap
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const lumpsumHotTableRef = useRef<HotTable>()
  const {
    job,
    metadataFieldValueMap,
    reconTables,
    isReconDataLoading,
    initLumpsum,
    reconJobResults,
    sendAsLumpsum,
    toggleSendAsLumpSum,
    overrideChargeDescriptionFlag,
    toggleOverrideChargeDescriptionFlag,
    isValidatingChargeCodes,
    invalidDocChargeCodes,
    noJobChargeVendor,
    noJobChargeCodes,
    findShipmentReconResults,
  } = useReconData(jobId, reconType, false, false)
  const apiPartner = job?.jobTemplate.apiPartner
  const apiPartnerName = apiPartner?.name ?? 'TMS'
  const cwModule =
    convertCwTargetModuleDisplayTextToCwTargetModule(
      metadataFieldValueMap[ApReconAutofillKey.CargowiseModule.toLowerCase()],
    ) || CwTargetModule.ForwardingShipment

  const hasSuccessfulShipmentReconResult = useMemo(() => {
    return findShipmentReconResults.some((reconResult) => reconResult.success)
  }, [findShipmentReconResults])

  useEffect(() => {
    if (!isValidatingChargeCodes) {
      if (invalidDocChargeCodes.length > 0) {
        enqueueSnackbar(`Invalid document charge codes: ${stringifyList(invalidDocChargeCodes)}`, {
          variant: 'error',
        })
        close()
      } else if (noJobChargeVendor) {
        enqueueSnackbar('No charge vendor was found. Please enter a vendor.', { variant: 'error' })
        close()
      } else if (noJobChargeCodes) {
        enqueueSnackbar('No charge codes found. Please enter charge codes into table.', {
          variant: 'error',
        })
        close()
      }
    }
  }, [
    close,
    enqueueSnackbar,
    isValidatingChargeCodes,
    invalidDocChargeCodes,
    noJobChargeVendor,
    noJobChargeCodes,
  ])

  const [isSending, setIsSending] = useState(false)

  const sendJobToTMS = async (): Promise<void> => {
    setIsSending(true)
    try {
      if (!apiExportType) {
        enqueueSnackbar(`Error exporting job to ${apiPartnerName}: please specify export type.`, {
          variant: 'error',
        })
        close()
        return
      }
      enqueueSnackbar(`Exporting job to ${apiPartnerName}. This may take a few minutes.`, {
        variant: 'info',
      })

      let lumpsumInputTable = null
      if (sendAsLumpsum) {
        const lumpsumTable = getLumpsumTable(reconTables, reconType)
        const lumpsumRows = lumpsumHotTableRef.current?.hotInstance!.getData()
        const lumpsumColumns = Object.keys(initLumpsum)
        lumpsumInputTable =
          lumpsumRows && lumpsumTable
            ? getLumpsumInputTable(lumpsumRows[0], lumpsumColumns, lumpsumTable)
            : null
      }
      handleExport(apiExportType, lumpsumInputTable, overrideChargeDescriptionFlag)
      enqueueSnackbar(`Successfully exported job to ${apiPartnerName}`, { variant: 'success' })
      close()
    } catch (error) {
      enqueueSnackbar(`${apiPartnerName} Export Error: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
  }

  const isLoading = isReconDataLoading || (sendAsLumpsum && isEmpty(initLumpsum))
  const isSendingDisabled =
    isSending ||
    ((apiExportType === ApiExportType.Invoice || apiExportType === ApiExportType.PostInvoice) &&
      !hasSuccessfulShipmentReconResult)

  return (
    <Dialog
      classes={{
        paper: isLoading ? classes.dialog : classes.dialogLarge,
      }}
      open={true}
      onClose={close}
    >
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={close} data-testid='close-btn' />
      </Box>
      <DialogTitle>
        <Box p={1}>
          <Typography variant='h6'>Review Data to Send to {apiPartnerName}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CenteredCircularProgress />
        ) : (
          <>
            <Typography variant='subtitle1' gutterBottom>
              <FindShipmentResultDisplay
                findShipmentResults={findShipmentReconResults}
                cargowiseOpsType={CargowiseOpsType.SendToCW}
                cargowiseModule={cwModule as CwTargetModule}
              />
            </Typography>
            {reconJobResults?.reconResults.length && (
              <APInvoiceReconResultTables
                reconJobResults={reconJobResults as ReconcileApInvoiceJob}
                overrideChargeDescription={true}
                disableHighlights={true}
                sendAsLumpsum={sendAsLumpsum}
              />
            )}
            {sendAsLumpsum && (
              <div data-testid='lumpsum-table'>
                <Typography variant='h4' gutterBottom>
                  Lumpsum Table
                </Typography>
                <HotTable
                  ref={lumpsumHotTableRef as RefObject<HotTable>}
                  style={{ width: '100%', overflow: 'hidden', height: '80px' }}
                  data={[Object.values(initLumpsum)]}
                  colHeaders={Object.keys(initLumpsum)}
                  stretchH='all'
                  autoRowSize={true}
                />
              </div>
            )}
            <Box display='flex' alignItems='center'>
              <Checkbox
                checked={sendAsLumpsum}
                onChange={toggleSendAsLumpSum}
                data-testid='lumpsum-checkbox'
              />
              <Typography>Send as lumpsum?</Typography>
            </Box>
            {reconType === JobTemplateReconType.Ap && (
              <>
                {apiExportType !== ApiExportType.Consolidation && (
                  <Box display='flex' alignItems='center'>
                    <Checkbox
                      checked={overrideChargeDescriptionFlag}
                      onChange={toggleOverrideChargeDescriptionFlag}
                      data-testid={'override-checkbox'}
                    />
                    <Typography>Override Charge Description in {apiPartnerName}</Typography>
                  </Box>
                )}
              </>
            )}
            <Box textAlign='right'>
              <Button
                variant='contained'
                color='primary'
                disabled={isSendingDisabled}
                onClick={sendJobToTMS}
              >
                Send to {apiPartnerName}
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default SendToTMSDialog
