import { Typography } from '@material-ui/core'
import { ReactElement } from 'react'
import {
  ChainIoConsolidationNode,
  ChainIoCustomsDeclarationNode,
  ChainIoShipmentNode,
  ChargeDetail,
  CwTargetModule,
  CwTargetModuleEnum,
  FallbackValue,
  FindInvoiceReconResultNode,
  FindShipmentDuplicateReconResultNode,
  FindShipmentReconResultNode,
  InvoiceLineItemReconResultNode,
  InvoiceTotalReconResultNode,
  MatchingCriteriaType,
  Maybe,
  MetadataReconResultKey,
  MetadataReconResultNode,
  ReconAttemptNode,
  ReconResultInterface,
  ReconResultType,
  ReconThresholdRangeNode,
  Scalars,
  ShipmentStaffReconResultNode,
} from '@src/graphql/types'
import { CargowiseOpsType } from '@src/utils/cargowise/types'
import { isFallback } from '@src/utils/enum'
import { uniqBy } from 'lodash'
import Decimal from 'decimal.js'
import { FindModelCriteriaSummary } from '@src/utils/recon/FindModelCriteriaSummary'

const missingInvoiceNumberPlaceholder = '(missing invoice no)'
const MONEY_DECIMAL_PLACES = 2

export enum CwTargetModuleDisplayText {
  FORWARDING_CONSOL = 'ForwardingConsol',
  FORWARDING_SHIPMENT = 'ForwardingShipment',
  ACCOUNTING_INVOICE = 'AccountingInvoice',
  CUSTOMS_DECLARATION = 'CustomsDeclaration',
  FORWARDING_SHIPMENT_TO_CONSOL = 'ForwardingConsol',
}

// Updated because we renamed some of the fields (invoiceAmount ->invoiceTotalAmount
// and expectedAmount -> expectedTotalAmount) since it is also being used
export type UpdatedInvoiceTotalReconResultNode = {
  type: ReconResultType
  success: Scalars['Boolean']['output']
  invoiceTotalAmount: Scalars['Float']['output']
  invoiceTaxedTotalAmount: Maybe<Scalars['Float']['output']>
  expectedTotalAmount: Scalars['Float']['output']
  expectedTaxedTotalAmount: Maybe<Scalars['Float']['output']>
  isWithinThreshold: Scalars['Boolean']['output']
  usedThresholdMatching: Scalars['Boolean']['output']
  reconThresholdRange?: Maybe<ReconThresholdRangeNode>
}

export type ApInvoiceReconResultNode = FindShipmentReconResultNode &
  FindInvoiceReconResultNode &
  InvoiceLineItemReconResultNode &
  InvoiceTotalReconResultNode &
  UpdatedInvoiceTotalReconResultNode &
  ShipmentStaffReconResultNode &
  MetadataReconResultNode

// TODO: should be a component
export const formatModelFoundResults = (
  matchedShipmentReconResultNodes: FindShipmentReconResultNode[],
  cargowiseOpsType: CargowiseOpsType | null,
  cargowiseModule: string | null,
  onlyShowReferenceNumber = false,
): JSX.Element => {
  let refNumber = ''
  const matchedResultNode = matchedShipmentReconResultNodes[0]
  const isForwardingShipment =
    cargowiseModule === CwTargetModule.ForwardingShipment && matchedResultNode.chainIoShipment
  const isForwardingConsol =
    (cargowiseModule === CwTargetModule.ForwardingConsol ||
      cargowiseModule === CwTargetModule.ForwardingShipmentToConsol) &&
    matchedResultNode.chainIoConsolidation
  const isCustomsDeclaration =
    cargowiseModule === CwTargetModule.CustomsDeclaration &&
    matchedResultNode.chainIoCustomsDeclaration

  if (isForwardingShipment) {
    refNumber = matchedResultNode.chainIoShipment!.forwarderReference!.toUpperCase()
  } else if (isForwardingConsol) {
    refNumber = matchedResultNode.chainIoConsolidation!.forwarderReference!.toUpperCase()
  } else if (isCustomsDeclaration) {
    refNumber = matchedResultNode.chainIoCustomsDeclaration!.forwarderReference!.toUpperCase()
  }

  if (
    cargowiseOpsType === CargowiseOpsType.SOAReconcile ||
    cargowiseOpsType === CargowiseOpsType.SOACheckShipmentInfo
  ) {
    return (
      <>
        <Typography component={'div'} variant='body1' gutterBottom>
          <strong>{refNumber}</strong> found using:{' '}
        </Typography>
        {matchedShipmentReconResultNodes.map((matchedShipmentReconResultNode, index) => (
          <Typography
            component={'div'}
            variant='body1'
            gutterBottom
            key={index}
            data-testid='unique-find-shipment-recon-result'
          >
            - <FindModelCriteriaSummary findModelCriteriaItems={[matchedShipmentReconResultNode]} />
          </Typography>
        ))}
      </>
    )
  }
  return (
    <>
      <strong>{refNumber}</strong>
      {!onlyShowReferenceNumber && (
        <>
          {' '}
          found using{' '}
          <FindModelCriteriaSummary findModelCriteriaItems={matchedShipmentReconResultNodes} />
        </>
      )}
    </>
  )
}

export const formatFindShipmentWithMatchCriteria = (
  chainIoShipment?: Maybe<ChainIoShipmentNode>,
  shipmentNo?: Maybe<string>,
  hblNo?: Maybe<string>,
  orderNo?: Maybe<string>,
  consolNo?: Maybe<string>,
  mblNo?: Maybe<string>,
  carrierBookingNo?: Maybe<string>,
  containerNo?: Maybe<string>,
): ReactElement => {
  const foundShipment = chainIoShipment?.forwarderReference
  const matchCriteria = [shipmentNo, hblNo, orderNo, consolNo, mblNo, carrierBookingNo, containerNo]
  const matchCriteriaKeys = [
    'Shipment No.',
    'HBL No.',
    'Order No.',
    'Consol No.',
    'MBL No.',
    'Carrier Booking No.',
    'Container No.',
  ]
  const filteredMatchCriteria = matchCriteria
    .map((crit, i) => {
      if (crit) {
        return `${matchCriteriaKeys[i]} ${crit} `
      }
      return crit as string
    })
    .filter((crit) => crit)

  if (!foundShipment) {
    return (
      <Typography component='span' variant='body1'>
        No unique shipment was found using the following match criteria: {filteredMatchCriteria}
      </Typography>
    )
  }
  return (
    <Typography component='span' variant='body1'>
      Shipment <strong>{foundShipment}</strong> was found using key(s): {filteredMatchCriteria}
    </Typography>
  )
}

export const formatFindCustomsDeclarationWithMatchCriteria = (
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>,
  referenceNo?: Maybe<string>,
  hblNo?: Maybe<string>,
  orderNo?: Maybe<string>,
  consolNo?: Maybe<string>,
  mblNo?: Maybe<string>,
  carrierBookingNo?: Maybe<string>,
  containerNo?: Maybe<string>,
): ReactElement => {
  const foundCustomsDeclaration = chainIoCustomsDeclaration?.forwarderReference
  const matchCriteria = [
    referenceNo,
    hblNo,
    orderNo,
    consolNo,
    mblNo,
    carrierBookingNo,
    containerNo,
  ]
  const matchCriteriaKeys = [
    'Reference No.',
    'HBL No.',
    'Order No.',
    'Consol No.',
    'MBL No.',
    'Carrier Booking No.',
    'Container No.',
  ]
  const filteredMatchCriteria = matchCriteria
    .map((crit, i) => {
      if (crit) {
        return `${matchCriteriaKeys[i]} ${crit} `
      }
      return crit as string
    })
    .filter((crit) => crit)

  if (!foundCustomsDeclaration) {
    return (
      <Typography component='span' variant='body1'>
        No unique customs declaration was found using the following match criteria:{' '}
        {filteredMatchCriteria}
      </Typography>
    )
  }
  return (
    <Typography component='span' variant='body1'>
      Customs Declaration <strong>{foundCustomsDeclaration}</strong> was found using key(s):{' '}
      {filteredMatchCriteria}
    </Typography>
  )
}

export const formatShipmentConsolResults = (
  matchedShipmentReconResultNodes: FindShipmentReconResultNode[],
): ReactElement => {
  const consolNumbersArray =
    matchedShipmentReconResultNodes[0].chainIoShipment?.chainIoConsolidations?.edges?.map(
      (consolNodeEdge) => consolNodeEdge.node.forwarderReference,
    ) ?? []
  const consolNumbers =
    consolNumbersArray.length > 0
      ? consolNumbersArray.join(', ')
      : 'No consolidation numbers found for this shipment.'
  return (
    <Typography>
      Shipment is associated to the following consolidation/s:{' '}
      <strong data-testid='consol-numbers'>{consolNumbers}</strong>
    </Typography>
  )
}

export const maybeFindReconModuleFromReconResults = (
  reconResults: ReconResultInterface[],
): CwTargetModule | null => {
  if (reconResults) {
    const reconResultWithShipment = reconResults.find(
      (reconResult) => reconResult!.chainIoShipment?.id && reconResult!.success,
    )
    if (reconResultWithShipment) {
      return CwTargetModule.ForwardingShipment
    }
    // NOTE: shipment recon results can also have a consol! We might want to add explicit module
    // to avoid nasty bugs, but the ordering should work for this check.
    const reconResultWithConsol = reconResults.find(
      (reconResult) => reconResult!.chainIoConsolidation?.id && reconResult!.success,
    )
    if (reconResultWithConsol) {
      return CwTargetModule.ForwardingConsol
    }
    const reconResultWithCustomsDeclaration = reconResults.find(
      (reconResult) => reconResult!.chainIoCustomsDeclaration?.id && reconResult!.success,
    )
    if (reconResultWithCustomsDeclaration) {
      return CwTargetModule.CustomsDeclaration
    }
  }
  return null
}

export const formatCwTargetModule = (moduleType: CwTargetModule | null): string => {
  // CwTargetModule is inconsistent between graphql and recon, this uses the graphql version
  switch (moduleType) {
    case CwTargetModule.ForwardingConsol:
      return 'Forwarding Consol'
    case CwTargetModule.CustomsDeclaration:
      return 'Customs Declaration'
    case CwTargetModule.ForwardingShipmentToConsol:
      return 'Forwarding Consol'
    case CwTargetModule.ForwardingShipment:
      return 'Forwarding Shipment'
    default:
      return 'Unknown Cargowise Module'
  }
}

export const convertCwTargetModuleDisplayTextToCwTargetModule = (
  moduleDisplayText: string | null | undefined,
): CwTargetModule | undefined => {
  // CwTargetModule is inconsistent between graphql and recon, this uses the graphql version
  // Convert CwTargetModule display text version to the enum version
  // i.e. 'ForwardingShipment' -> 'FORWARDING_SHIPMENT'
  switch (moduleDisplayText) {
    case CwTargetModuleDisplayText.FORWARDING_CONSOL:
      return CwTargetModule.ForwardingConsol
    case CwTargetModuleDisplayText.CUSTOMS_DECLARATION:
      return CwTargetModule.CustomsDeclaration
    default:
      return CwTargetModule.ForwardingShipment
  }
}

export const convertCwModuleEnumToCwModuleDisplayText = (
  moduleType: string | null | undefined,
): string => {
  // Convert CwTargetModule enum version to the display text version
  // i.e. 'FORWARDING_SHIPMENT' -> 'ForwardingShipment'
  switch (moduleType) {
    case CwTargetModule.ForwardingConsol:
      return CwTargetModuleDisplayText.FORWARDING_CONSOL
    case CwTargetModule.CustomsDeclaration:
      return CwTargetModuleDisplayText.CUSTOMS_DECLARATION
    default:
      return CwTargetModuleDisplayText.FORWARDING_SHIPMENT
  }
}

export type HotTableData = {
  data: (number | string)[][]
  errorCells?: number[][]
  expectedErrorCells?: number[][][]
  errorOutlineCells?: number[][]
}

type LineItemTableData = {
  invoiceTableData: HotTableData
  expectedTableData: HotTableData
}

export const isErrorCell = (
  expectedErrorCells: number[][][],
  hoveredRowIdx: Maybe<number>,
  expectedRowIdx: number,
  colIdx: number,
): boolean => {
  if (hoveredRowIdx === null) return false
  return expectedErrorCells[hoveredRowIdx][expectedRowIdx]?.includes(colIdx) ?? false
}

export const RECON_METADATA_DATA_TABLE_COLUMNS = ['Field Name', 'Invoice Data', 'Expected Data']
export const CHECK_SHIPMENT_METADATA_DATA_TABLE_COLUMNS = ['Field', 'Value']
export const ADDITIONAL_REFERENCES_DATA_TABLE_COLUMNS = ['Type', 'Reference Number']
export const ADDRESSES_DATA_TABLE_COLUMNS = ['Type', 'Name', 'Code', 'Unlocode', 'Address']
export const PACKING_LINES_DATA_TABLE_COLUMNS = [
  'Packs',
  'Pack Type',
  'Container Number',
  'Weight',
  'Weight Unit',
  'Volume',
  'Volume Unit',
  'Reference Number',
  'Import Reference Number',
  'Export Reference Number',
  'Goods Description',
  'Marks and Numbers',
  'Commodity',
]
export const ORDER_NUMBER_COLLECTION_DATA_TABLE_COLUMNS = [
  'Order Number',
  'Order Number Split',
  'Buyer',
  'Supplier',
  'Order Status',
  'Order Date',
  'Order Lines',
]

export const ORDER_LINE_DATA_TABLE_COLUMNS = [
  'Order Line Number',
  'Part Number',
  'Description',
  'Container Number',
]
export const SHIPMENT_LEGS_DATA_TABLE_COLUMNS = [
  'Leg',
  'Mode',
  'Type',
  'Status',
  'Vessel',
  'Voyage',
  'Load Port',
  'Discharge Port',
  'ETD',
  'ETA',
  'Carrier',
]
export const CONTAINERS_DATA_TABLE_COLUMNS = [
  'Container Number',
  'Count',
  'Seal Number',
  'Mode',
  'Container Type',
  'Delivery Mode',
  'Sealed By',
  'Wharf Gate In',
]

export const RECON_TABLE_COLUMNS = {
  CHARGE_CODE: 'Charge Code',
  DESCRIPTION: 'Description',
  COST: 'Cost',
  CURRENCY: 'Currency',
  VENDOR: 'Vendor',
  INVOICE_NUMBER: 'Invoice No.',
  TAX_ID: 'Tax ID',
  TAX: 'Tax',
  INVOICE_DATE: 'Cost Invoice Date',
  DUE_DATE: 'Cost Due Date',
  IS_POSTED: 'Is Posted',
  OS_SELL_AMOUNT: 'OS Sell Amount',
  SELL_AMOUNT_CURRENCY: 'Sell Amount Currency',
}
export const INVOICE_DATA_TABLE_COLUMNS = [
  RECON_TABLE_COLUMNS.CHARGE_CODE,
  RECON_TABLE_COLUMNS.DESCRIPTION,
  RECON_TABLE_COLUMNS.COST,
  RECON_TABLE_COLUMNS.CURRENCY,
  RECON_TABLE_COLUMNS.VENDOR,
  RECON_TABLE_COLUMNS.INVOICE_NUMBER,
  RECON_TABLE_COLUMNS.TAX_ID,
  RECON_TABLE_COLUMNS.TAX,
  RECON_TABLE_COLUMNS.INVOICE_DATE,
  RECON_TABLE_COLUMNS.DUE_DATE,
]
export const EXPECTED_DATA_TABLE_COLUMNS = [
  RECON_TABLE_COLUMNS.CHARGE_CODE,
  RECON_TABLE_COLUMNS.DESCRIPTION,
  RECON_TABLE_COLUMNS.COST,
  RECON_TABLE_COLUMNS.CURRENCY,
  RECON_TABLE_COLUMNS.VENDOR,
  RECON_TABLE_COLUMNS.INVOICE_NUMBER,
  RECON_TABLE_COLUMNS.TAX_ID,
  RECON_TABLE_COLUMNS.TAX,
  RECON_TABLE_COLUMNS.INVOICE_DATE,
  RECON_TABLE_COLUMNS.DUE_DATE,
  RECON_TABLE_COLUMNS.IS_POSTED,
  RECON_TABLE_COLUMNS.OS_SELL_AMOUNT,
  RECON_TABLE_COLUMNS.SELL_AMOUNT_CURRENCY,
]
export const NAME_NOT_AVAILABLE = 'Name not available'
export const CODE_NOT_AVAILABLE = 'Code not available'

export const getTotalAndTaxedTotalAmount = (
  invoiceTotalReconResult: UpdatedInvoiceTotalReconResultNode | undefined,
  invoiceLineItemReconResults: InvoiceLineItemReconResultNode[],
  expected: boolean,
): number[] => {
  let sum = 0
  let taxedAmount = 0
  if (invoiceTotalReconResult) {
    if (expected) {
      return [
        invoiceTotalReconResult.expectedTotalAmount,
        invoiceTotalReconResult.expectedTaxedTotalAmount ?? 0,
      ]
    } else {
      return [
        invoiceTotalReconResult.invoiceTotalAmount,
        invoiceTotalReconResult.invoiceTaxedTotalAmount ?? 0,
      ]
    }
  } else {
    if (expected) {
      // uniquify expected results in case 2 items match to the same line so we don't
      // double count the matched line.
      const uniqMatchedLineItemResults = uniqBy(
        invoiceLineItemReconResults,
        (res) => res.chainIoConsolCostId || res.chainIoCustomsDeclarationCostId,
      )
      sum = uniqMatchedLineItemResults.reduce(
        (sum, reconResult) => sum + parseFloat(reconResult?.expectedAmount || '0'),
        0.0,
      )
      taxedAmount = uniqMatchedLineItemResults.reduce(
        (sum, reconResult) => sum + parseFloat(reconResult?.expectedTaxAmount || '0'),
        0.0,
      )
    } else {
      sum = invoiceLineItemReconResults.reduce(
        (sum, reconResult) => sum + parseFloat(reconResult?.invoiceAmount || '0'),
        0.0,
      )
      taxedAmount = invoiceLineItemReconResults.reduce(
        (sum, reconResult) => sum + parseFloat(reconResult?.invoiceTaxAmount || '0'),
        0.0,
      )
    }
  }
  // handle rounding from summing float e.g. 1.12 * 5
  sum = parseFloat(sum.toFixed(MONEY_DECIMAL_PLACES))
  const taxedSum = parseFloat((sum + taxedAmount).toFixed(MONEY_DECIMAL_PLACES))
  return [sum, taxedSum]
}

export const getAmountDeltaMaybeThreshold = (
  invoiceAmount: Maybe<number>,
  expectedAmount: Maybe<number>,
  invoiceTotalReconResult: Maybe<UpdatedInvoiceTotalReconResultNode> | undefined,
): {
  deltaValue: Maybe<Decimal>
  isWithinThreshold: boolean
  thresholdMatchTerm: Maybe<string>
  thresholdAmount: Maybe<Decimal>
} => {
  if (invoiceAmount === null && expectedAmount === null) {
    return {
      deltaValue: null,
      isWithinThreshold: true,
      thresholdMatchTerm: null,
      thresholdAmount: null,
    }
  }
  const deltaValue = Decimal.sub(invoiceAmount ?? 0, expectedAmount ?? 0).toDecimalPlaces(
    MONEY_DECIMAL_PLACES,
  )
  if (
    !invoiceTotalReconResult?.usedThresholdMatching ||
    !invoiceTotalReconResult.reconThresholdRange
  ) {
    return {
      deltaValue: deltaValue,
      isWithinThreshold: deltaValue.isZero(),
      thresholdMatchTerm: null,
      thresholdAmount: null,
    }
  }

  const isWithinThreshold = invoiceTotalReconResult.isWithinThreshold
  let thresholdMatchTerm = 'within'
  const thresholdAmount = new Decimal(
    invoiceTotalReconResult.reconThresholdRange.thresholdAmount,
  ).toDecimalPlaces(MONEY_DECIMAL_PLACES)
  if (!isWithinThreshold) {
    if (deltaValue.toNumber() > thresholdAmount.toNumber()) {
      thresholdMatchTerm = 'above'
    } else {
      thresholdMatchTerm = 'below'
    }
  }
  return {
    deltaValue: deltaValue,
    isWithinThreshold: isWithinThreshold,
    thresholdMatchTerm: thresholdMatchTerm,
    thresholdAmount: thresholdAmount,
  }
}

const formatAmountDeltaWithThreshold = (
  deltaValue: Maybe<Decimal>,
  thresholdMatchTerm: Maybe<string>,
  thresholdAmount: Maybe<Decimal>,
): string => {
  if (deltaValue === null) {
    return 'N/A'
  }
  if (!thresholdMatchTerm || !thresholdAmount) {
    return deltaValue.toString()
  }
  return `${deltaValue.toString()} (${thresholdMatchTerm} threshold of ${thresholdAmount.toString()})`
}

export const getAPMetadataTable = (reconResults: ApInvoiceReconResultNode[]): HotTableData => {
  const totalAmountFieldName = 'Total Amount'
  const totalAmountTaxedFieldName = 'Total Amount w/ Tax'
  const INVOICE_DATA_COLUMN = RECON_METADATA_DATA_TABLE_COLUMNS.findIndex(
    (associatedKeysDataTableColumn) => associatedKeysDataTableColumn === 'Invoice Data',
  )
  const EXPECTED_DATA_COLUMN = RECON_METADATA_DATA_TABLE_COLUMNS.findIndex(
    (associatedKeysDataTableColumn) => associatedKeysDataTableColumn === 'Expected Data',
  )
  const ERROR_COLUMNS = [INVOICE_DATA_COLUMN, EXPECTED_DATA_COLUMN]

  const invoiceTotalReconResult = reconResults.find(
    (reconResult) =>
      !isFallback(reconResult.type) &&
      reconResult.type?.value === ReconResultType.InvoiceTotalReconResult,
  ) as UpdatedInvoiceTotalReconResultNode | undefined

  const invoiceLineItemReconResults = reconResults.filter(
    (reconResult) =>
      !isFallback(reconResult.type) &&
      reconResult.type?.value === ReconResultType.InvoiceLineItemReconResult,
  ) as InvoiceLineItemReconResultNode[]

  const metadataReconResults = reconResults.filter(
    (reconResult) =>
      !isFallback(reconResult.type) &&
      reconResult.type?.value === ReconResultType.MetadataReconResult,
  ) as MetadataReconResultNode[]

  const [invoiceSum, invoiceTaxedSum] = getTotalAndTaxedTotalAmount(
    invoiceTotalReconResult,
    invoiceLineItemReconResults,
    false,
  )
  const [expectedSum, expectedTaxedSum] = getTotalAndTaxedTotalAmount(
    invoiceTotalReconResult,
    invoiceLineItemReconResults,
    true,
  )
  const {
    deltaValue: totalAmountDelta,
    isWithinThreshold: totalAmountMatching,
    thresholdMatchTerm,
    thresholdAmount,
  } = getAmountDeltaMaybeThreshold(invoiceSum, expectedSum, invoiceTotalReconResult)
  const formattedTotalAmountDelta = formatAmountDeltaWithThreshold(
    totalAmountDelta,
    thresholdMatchTerm,
    thresholdAmount,
  )
  const {
    deltaValue: totalAmountTaxedDelta,
    isWithinThreshold: totalAmountTaxedMatching,
    thresholdMatchTerm: taxedThresholdMatchTerm,
    thresholdAmount: taxedThresholdAmount,
  } = getAmountDeltaMaybeThreshold(invoiceTaxedSum, expectedTaxedSum, invoiceTotalReconResult)
  const formattedTotalAmountTaxedDelta = formatAmountDeltaWithThreshold(
    totalAmountTaxedDelta,
    taxedThresholdMatchTerm,
    taxedThresholdAmount,
  )

  const totalAmountSuccessMap: Record<string, boolean> = {
    [totalAmountFieldName]: totalAmountMatching,
    [totalAmountTaxedFieldName]: totalAmountTaxedMatching,
  }

  const metadataTable = [
    [totalAmountFieldName, invoiceSum, expectedSum, formattedTotalAmountDelta],
    [totalAmountTaxedFieldName, invoiceTaxedSum, expectedTaxedSum, formattedTotalAmountTaxedDelta],
    ...getMetadataTable(metadataReconResults, true),
  ]

  return {
    data: metadataTable,
    errorCells: metadataTable.map(([fieldName, invoiceValue, expectedValue]) => {
      const name = fieldName.toString()
      if (Object.keys(totalAmountSuccessMap).includes(name)) {
        return totalAmountSuccessMap[name] ? [] : [...ERROR_COLUMNS, EXPECTED_DATA_COLUMN + 1]
      }
      return isMetadataMatching(invoiceValue, expectedValue) ? [] : ERROR_COLUMNS
    }),
  }
}

const isMetadataMatching = (
  invoiceValue: string | number,
  expectedValue: string | number,
): boolean => !!(!invoiceValue || invoiceValue === expectedValue)

export const getMetadataTable = (
  metadataReconResults: MetadataReconResultNode[],
  reconResultShow = false,
): string[][] => {
  const metadataTable = [] as string[][]
  const metadataMap: { [key: string]: MetadataReconResultNode | undefined } = {}

  metadataReconResults.forEach((metadataReconResult) => {
    if (!isFallback(metadataReconResult.key)) {
      metadataMap[metadataReconResult.key.value] = metadataReconResult
    }
  })

  const opsName =
    metadataMap[MetadataReconResultKey.OpsStaffName]?.expectedValue ?? NAME_NOT_AVAILABLE
  const opsCode =
    metadataMap[MetadataReconResultKey.OpsStaffCode]?.expectedValue ?? CODE_NOT_AVAILABLE
  const salesName =
    metadataMap[MetadataReconResultKey.SalesStaffName]?.expectedValue ?? NAME_NOT_AVAILABLE
  const salesCode =
    metadataMap[MetadataReconResultKey.SalesStaffCode]?.expectedValue ?? CODE_NOT_AVAILABLE
  const branchReconResult = metadataMap[MetadataReconResultKey.Branch]
  const departmentReconResult = metadataMap[MetadataReconResultKey.Department]
  const pickupDropModeReconResult = metadataMap[MetadataReconResultKey.PickupDropMode]
  const deliveryDropModeReconResult = metadataMap[MetadataReconResultKey.DeliveryDropMode]

  if (reconResultShow) {
    if (opsName && opsCode) {
      metadataTable.push(['Ops Rep', `${opsName} (${opsCode})`, `${opsName} (${opsCode})`])
    }
    if (salesName && salesCode) {
      metadataTable.push([
        'Sales Rep',
        `${salesName} (${salesCode})`,
        `${salesName} (${salesCode})`,
      ])
    }
    metadataTable.push([
      'Branch',
      branchReconResult?.invoiceValue || '',
      branchReconResult?.expectedValue || '',
    ])
    metadataTable.push([
      'Department',
      departmentReconResult?.invoiceValue || '',
      departmentReconResult?.expectedValue || '',
    ])
    metadataTable.push([
      'Pickup Drop Mode',
      pickupDropModeReconResult?.invoiceValue || '',
      pickupDropModeReconResult?.expectedValue || '',
    ])
    metadataTable.push([
      'Delivery Drop Mode',
      deliveryDropModeReconResult?.invoiceValue || '',
      deliveryDropModeReconResult?.expectedValue || '',
    ])
  } else {
    if (opsName && opsCode) {
      metadataTable.push(['Ops Rep', `${opsName} (${opsCode})`])
    }
    if (salesName && salesCode) {
      metadataTable.push(['Sales Rep', `${salesName} (${salesCode})`])
    }
    metadataTable.push(['Branch', branchReconResult?.expectedValue || ''])
    metadataTable.push(['Department', departmentReconResult?.expectedValue || ''])
    metadataTable.push(['Pickup Drop Mode', pickupDropModeReconResult?.expectedValue || ''])
    metadataTable.push(['Delivery Drop Mode', deliveryDropModeReconResult?.expectedValue || ''])
  }

  return metadataTable
}

interface APAssociatedKeys {
  tmsId: string
  houseBill: string
  masterBill: string
  carrierBookingNumber: string
  containerNumbers: string[]
  containerNumbersString: string
  consolNumber: string
  orderNumber: string
}

const isKeyMatching = (
  invoiceKey: Maybe<string> | undefined,
  expectedKey: Maybe<string> | undefined,
): boolean => {
  return !!(!invoiceKey || !expectedKey || invoiceKey! === expectedKey!)
}

const isKeyElement = (
  invoiceKey: Maybe<string> | undefined,
  expectedKeys: Array<Maybe<string> | undefined>,
): boolean => {
  return !!(!invoiceKey || !expectedKeys?.length || expectedKeys!.indexOf(invoiceKey!) > -1)
}

const getConsolNumber = (
  chainIoModel: ChainIoShipmentNode | ChainIoCustomsDeclarationNode | ChainIoConsolidationNode,
): string => {
  switch (chainIoModel.__typename) {
    case 'ChainIOShipmentNode':
      return (
        ((chainIoModel as ChainIoShipmentNode).chainIoConsolidation
          ?.forwarderReference as string) || ''
      )
    case 'ChainIOCustomsDeclarationNode':
      return (
        ((chainIoModel as ChainIoCustomsDeclarationNode).chainIoShipment?.chainIoConsolidation
          ?.forwarderReference as string) || ''
      )
    case 'ChainIOConsolidationNode':
      return ((chainIoModel as ChainIoConsolidationNode).forwarderReference as string) || ''
    default:
      return ''
  }
}

const getOrderNumber = (
  chainIoModel: ChainIoShipmentNode | ChainIoCustomsDeclarationNode | ChainIoConsolidationNode,
): string => {
  if (
    chainIoModel.__typename === 'ChainIOShipmentNode' ||
    chainIoModel.__typename === 'ChainIOCustomsDeclarationNode'
  ) {
    return chainIoModel!.orders?.edges?.map(({ node }) => node?.orderReference).join(', ') ?? ''
  } else if (chainIoModel.__typename === 'ChainIOConsolidationNode') {
    const orderNumbers = chainIoModel?.shipments?.edges
      ?.flatMap((ship) => ship?.node?.orders?.edges)
      .flatMap((order) => order?.node?.orderReference)
    return orderNumbers.join(', ') ?? ''
  } else {
    return ''
  }
}

const getAPAssociatedKeysFromMultipleMatches = (
  multipleResult: FindShipmentReconResultNode,
): APAssociatedKeys => {
  const multipleMatches: FindShipmentDuplicateReconResultNode[] =
    multipleResult.findShipmentDuplicateReconResults.edges.map((edge) => edge.node)
  const apAssociatedKeysFromMatches: { [key: string]: string | string[] | Set<string> | number } = {
    tmsId: '',
    houseBill: '',
    masterBill: '',
    carrierBookingNumber: '',
    containerNumbers: new Set(),
    containerNumbersString: '',
    consolNumber: '',
    orderNumber: '',
  }
  const invalidValue = 1

  multipleMatches.forEach((match) => {
    const assKeysFromModel = getAPAssociatedKeysFromModel(match.chainIoShipment)
    Object.entries(assKeysFromModel).forEach(([key, value]) => {
      const keyName = key as keyof typeof apAssociatedKeysFromMatches
      if (value instanceof Array) {
        value.forEach((containerNum: string) =>
          (apAssociatedKeysFromMatches.containerNumbers as Set<string>).add(containerNum),
        )
      } else if (
        apAssociatedKeysFromMatches[keyName] &&
        apAssociatedKeysFromMatches[keyName] !== value
      ) {
        apAssociatedKeysFromMatches[keyName] = invalidValue
      } else {
        apAssociatedKeysFromMatches[keyName] = value
      }
    })
  })
  apAssociatedKeysFromMatches.containerNumbers = [
    ...(apAssociatedKeysFromMatches.containerNumbers as Set<string>),
  ]
  const validAssKeys = Object.fromEntries(
    Object.entries(apAssociatedKeysFromMatches).map(([key, val]) => [
      key,
      val === invalidValue ? '' : val,
    ]),
  ) as unknown as APAssociatedKeys

  return validAssKeys
}

const getAPAssociatedKeysFromModel = (
  chainIoModel:
    | Maybe<ChainIoShipmentNode | ChainIoCustomsDeclarationNode | ChainIoConsolidationNode>
    | undefined,
): APAssociatedKeys => {
  const apAssociatedKeysFromModel: APAssociatedKeys = {
    tmsId: '',
    houseBill: '',
    masterBill: '',
    carrierBookingNumber: '',
    containerNumbers: [],
    containerNumbersString: '',
    consolNumber: '',
    orderNumber: '',
  }
  if (chainIoModel) {
    apAssociatedKeysFromModel.tmsId = chainIoModel.tmsId ?? ''
    apAssociatedKeysFromModel.houseBill =
      (chainIoModel as ChainIoShipmentNode | ChainIoCustomsDeclarationNode).houseBill ??
      (chainIoModel as ChainIoConsolidationNode).shipments?.edges[0]?.node?.houseBill ??
      ''
    apAssociatedKeysFromModel.masterBill = chainIoModel!.masterBill ?? ''
    apAssociatedKeysFromModel.carrierBookingNumber =
      (chainIoModel as ChainIoConsolidationNode).carrierBookingRef ??
      (chainIoModel as ChainIoShipmentNode | ChainIoCustomsDeclarationNode).bookingReference ??
      ''
    apAssociatedKeysFromModel.containerNumbers =
      chainIoModel!.containers?.edges
        ?.map(({ node }) => node?.containerNumber ?? '')
        .filter((num) => num)
        .sort() || []
    apAssociatedKeysFromModel.containerNumbersString =
      apAssociatedKeysFromModel.containerNumbers?.join(', ') || ''
    apAssociatedKeysFromModel.consolNumber = getConsolNumber(chainIoModel)
    apAssociatedKeysFromModel.orderNumber = getOrderNumber(chainIoModel)
  }
  return apAssociatedKeysFromModel
}

export const getAPAssociatedKeysTable = (
  findShipmentReconResults: FindShipmentReconResultNode[],
  reconAttempt: ReconAttemptNode | null,
): HotTableData => {
  const INVOICE_DATA_COLUMN = RECON_METADATA_DATA_TABLE_COLUMNS.findIndex(
    (associatedKeysDataTableColumn) => associatedKeysDataTableColumn === 'Invoice Data',
  )
  const EXPECTED_DATA_COLUMN = RECON_METADATA_DATA_TABLE_COLUMNS.findIndex(
    (associatedKeysDataTableColumn) => associatedKeysDataTableColumn === 'Expected Data',
  )
  const ERROR_COLUMNS = [INVOICE_DATA_COLUMN, EXPECTED_DATA_COLUMN]

  const findShipmentReconResult = findShipmentReconResults.find(
    (reconResult) =>
      !isFallback(reconResult.type) &&
      reconResult.type?.value === ReconResultType.FindShipmentReconResult &&
      reconResult.success,
  ) as FindShipmentReconResultNode

  const chainIoModel =
    findShipmentReconResult?.chainIoShipment ||
    findShipmentReconResult?.chainIoConsolidation ||
    findShipmentReconResult?.chainIoCustomsDeclaration
  const multipleChainIoMatchesResult = findShipmentReconResults.find(
    (reconResult) =>
      !isFallback(reconResult.type) &&
      reconResult.type?.value === ReconResultType.FindShipmentReconResult &&
      reconResult.findShipmentDuplicateReconResults?.edges?.length,
  ) as FindShipmentReconResultNode

  if (!chainIoModel && !multipleChainIoMatchesResult) {
    return {
      data: [],
      errorCells: [],
    }
  }

  const apAssociatedKeys = chainIoModel
    ? getAPAssociatedKeysFromModel(chainIoModel)
    : getAPAssociatedKeysFromMultipleMatches(multipleChainIoMatchesResult)
  const [
    tmsId,
    houseBill,
    masterBill,
    carrierBookingNumber,
    containerNumbers,
    containerNumbersString,
    consolNumber,
    orderNumber,
  ] = Object.values(apAssociatedKeys)

  if (reconAttempt) {
    const { reconDetail } = reconAttempt
    return {
      data: [
        ['TMS ID', reconDetail?.tmsId as string, tmsId],
        ['HBL Number', reconDetail?.hblNo as string, houseBill],
        ['MBL Number', reconDetail?.mblNo as string, masterBill],
        ['Carrier Booking Number', reconDetail?.carrierBookingNo as string, carrierBookingNumber],
        ['Order Number', reconDetail?.orderNo as string, orderNumber],
        ['Container Number', reconDetail?.containerNo as string, containerNumbersString],
        ['Consol Number', reconDetail?.consolNo as string, consolNumber],
      ],
      errorCells: [
        isKeyMatching(reconDetail?.tmsId, tmsId) ? [] : ERROR_COLUMNS,
        isKeyMatching(reconDetail?.hblNo, houseBill) ? [] : ERROR_COLUMNS,
        isKeyMatching(reconDetail?.mblNo, masterBill) ? [] : ERROR_COLUMNS,
        isKeyMatching(reconDetail?.carrierBookingNo, carrierBookingNumber) ? [] : ERROR_COLUMNS,
        isKeyMatching(reconDetail?.orderNo, orderNumber) ? [] : ERROR_COLUMNS,
        isKeyElement(reconDetail?.containerNo, containerNumbers as string[]) ? [] : ERROR_COLUMNS,
        isKeyMatching(reconDetail?.consolNo, consolNumber) ? [] : ERROR_COLUMNS,
      ],
    }
  } else {
    return {
      data: [
        ['TMS ID', tmsId],
        ['HBL Number', houseBill],
        ['MBL Number', masterBill],
        ['Carrier Booking Number', carrierBookingNumber],
        ['Order Number', orderNumber],
        ['Container Number', containerNumbersString],
        ['Consol Number', consolNumber],
      ],
      errorCells: [],
    }
  }
}

const isLineItemDateMatching = (
  invoiceDate: Maybe<string> | undefined,
  expectedDate: Maybe<string> | undefined,
): boolean => {
  return (
    !!invoiceDate &&
    !!expectedDate &&
    new Date(invoiceDate).toISOString() === new Date(expectedDate).toISOString()
  )
}

const formatLineItemDate = (rawDate: string): string => {
  return new Date(rawDate).toISOString().substring(0, 10)
}

/**
 * Transforms an array of ChargeDetail objects into a matrix of string arrays for display purposes.
 *
 * @param {ChargeDetail[]} chargeDetails - The array of charge details to be transformed.
 * @param {boolean} [overrideChargeDescription=false] - Whether to override the charge description.
 * @param {boolean} [includeIsPosted=false] - Whether to include the 'isPosted' flag in the result.
 * @param {Record<string, string> | null} [chargeCodeToDescMap=null] - Optional map from charge codes to descriptions.
 * @param {Record<string, string> | null} [chargeVendorCodeToNameMap=null] - Optional map from vendor codes to vendor names.
 * @param {boolean} [includeOverseasDetails=false] - Whether overseas details (amount and current) should be included.
 * @returns {string[][]} The transformed matrix of charge details.
 */
export const transformChargeDetailsToMatrix = (
  chargeDetails: ChargeDetail[],
  overrideChargeDescription = false,
  includeIsPosted = false,
  chargeCodeToDescMap: Record<string, string> | null = null,
  chargeVendorCodeToNameMap: Record<string, string> | null = null,
  includeOverseasDetails = false,
): string[][] => {
  return chargeDetails.map((chargeDetail): string[] => {
    const chargeCodeCode = chargeDetail?.chargeCode ?? ''
    let chargeCodeDisplay = chargeCodeCode
    if (chargeCodeToDescMap != null) {
      const chargeCodeDesc = chargeCodeToDescMap?.[chargeCodeCode] ?? ''
      chargeCodeDisplay = chargeCodeDesc
        ? `${chargeCodeDesc} (${chargeCodeCode})`
        : `${chargeCodeCode} (Description unavailable)`
    }

    const vendorCode = chargeDetail?.vendor ?? ''
    let chargeVendorDisplay = vendorCode
    if (chargeVendorCodeToNameMap != null) {
      const vendorName = chargeVendorCodeToNameMap?.[vendorCode] ?? ''
      chargeVendorDisplay = vendorName
        ? `${vendorName} (${vendorCode})`
        : `${vendorCode} (Name unavailable)`
    }

    let chargeRow = [chargeCodeDisplay]
      .concat(overrideChargeDescription ? [chargeDetail?.chargeDescription ?? ''] : [])
      .concat([
        chargeDetail?.chargeCost ?? '',
        chargeDetail?.currency ?? '',
        chargeVendorDisplay,
        chargeDetail?.invoiceNumber ?? '',
        chargeDetail?.taxId ?? '',
        chargeDetail?.taxRate
          ? `${parseFloat(chargeDetail?.taxRate).toFixed(MONEY_DECIMAL_PLACES)}%`
          : '',
        chargeDetail?.invoiceDate ? formatLineItemDate(chargeDetail?.invoiceDate) : '',
        chargeDetail?.dueDate ? formatLineItemDate(chargeDetail?.dueDate) : '',
      ])

    const overseasDetails = [chargeDetail.overseasSellAmount ?? '', chargeDetail.sellCurrency ?? '']

    if (includeIsPosted) {
      const isPosted = chargeDetail.isPosted ? 'TRUE' : 'FALSE'
      chargeRow = [...chargeRow, isPosted]
    }
    if (includeOverseasDetails) {
      chargeRow = [...chargeRow, ...overseasDetails]
    }
    return chargeRow
  })
}

const getChargeVendorCodeFromInvoiceReconResult = (
  invoiceLineItemReconResult: InvoiceLineItemReconResultNode,
): string => {
  return invoiceLineItemReconResult.invoiceChargeVendor?.code || ''
}

const getInvNumberDisplayStringFromInvoiceReconResult = (
  invoiceLineItemReconResult: InvoiceLineItemReconResultNode,
): string => {
  return invoiceLineItemReconResult.invoiceNumber || missingInvoiceNumberPlaceholder
}

const sameCost = (
  invoiceLineItemReconResult: InvoiceLineItemReconResultNode,
  expectedCharge: ChargeDetail,
): boolean => {
  return invoiceLineItemReconResult.invoiceAmount === expectedCharge.chargeCost
}

const sameChargeCode = (
  invoiceLineItemReconResult: InvoiceLineItemReconResultNode,
  expectedCharge: ChargeDetail,
): boolean => {
  return invoiceLineItemReconResult.invoiceChargeCodeCode === expectedCharge.chargeCode
}

const sameVendor = (
  invoiceLineItemReconResult: InvoiceLineItemReconResultNode,
  expectedCharge: ChargeDetail,
): boolean => {
  const invoiceChargeVendor = getChargeVendorCodeFromInvoiceReconResult(invoiceLineItemReconResult)
  return invoiceChargeVendor === expectedCharge.vendor
}

const sameInvoiceNumber = (
  invoiceLineItemReconResult: InvoiceLineItemReconResultNode,
  expectedCharge: ChargeDetail,
  strict: boolean,
): boolean => {
  const invoiceNumber = getInvNumberDisplayStringFromInvoiceReconResult(invoiceLineItemReconResult)
  if (strict) {
    return invoiceNumber === expectedCharge.invoiceNumber
  } else {
    return (
      expectedCharge.invoiceNumber === invoiceNumber ||
      expectedCharge.invoiceNumber === missingInvoiceNumberPlaceholder
    )
  }
}

// gets all rows from expected charges that match the line item's matching criteria
export const getExpectedRowsBasedOnMatchingCriteria = (
  invoiceLineItemReconResults: InvoiceLineItemReconResultNode[],
  expectedCharges: ChargeDetail[],
  matchingCriteria: MatchingCriteriaType | FallbackValue | null,
): number[][] => {
  return invoiceLineItemReconResults.map((invoiceLineItemReconResult) => {
    const expectedMatches = [] as number[]
    expectedCharges.forEach((expectedCharge, idx): void => {
      if (matchingCriteria === MatchingCriteriaType.ChargeCodeOnly) {
        if (sameChargeCode(invoiceLineItemReconResult, expectedCharge)) {
          expectedMatches.push(idx)
        }
      } else if (matchingCriteria === MatchingCriteriaType.ChargeCodeVendorInvoiceNumber) {
        if (
          sameChargeCode(invoiceLineItemReconResult, expectedCharge) &&
          sameVendor(invoiceLineItemReconResult, expectedCharge) &&
          sameInvoiceNumber(invoiceLineItemReconResult, expectedCharge, false)
        ) {
          expectedMatches.push(idx)
        }
      } else if (matchingCriteria === MatchingCriteriaType.VendorInvoiceNumber) {
        if (
          sameVendor(invoiceLineItemReconResult, expectedCharge) &&
          sameInvoiceNumber(invoiceLineItemReconResult, expectedCharge, true)
        ) {
          expectedMatches.push(idx)
        }
      } else if (matchingCriteria === MatchingCriteriaType.NonStrictVendorInvoiceNumber) {
        if (
          sameVendor(invoiceLineItemReconResult, expectedCharge) &&
          sameInvoiceNumber(invoiceLineItemReconResult, expectedCharge, false)
        ) {
          expectedMatches.push(idx)
        }
      }
    })
    return expectedMatches
  })
}

// gets each invoice line item's best match (based on backend calculations)
export const getBestMatchRowsOnExpected = (
  invoiceLineItemReconResults: InvoiceLineItemReconResultNode[],
  expectedCharges: ChargeDetail[],
): number[] => {
  const expectedMatches = [] as number[]
  invoiceLineItemReconResults.forEach((invoiceLineItemReconResult) => {
    const expectedRowIdx = expectedCharges.findIndex(
      (expectedCharge: ChargeDetail) =>
        expectedCharge.chainIoSiLineId === invoiceLineItemReconResult!.chainIoSiLine?.id ||
        expectedCharge.chainIoSiLineId === invoiceLineItemReconResult!.chainIoConsolCostId ||
        expectedCharge.chainIoSiLineId ===
          invoiceLineItemReconResult!.chainIoCustomsDeclarationCostId,
    )
    expectedMatches.push(expectedRowIdx)
  })
  return expectedMatches
}

export const getAPInvoiceDataTables = (
  invoiceLineItemReconResults: InvoiceLineItemReconResultNode[],
  docCharges: ChargeDetail[],
  expectedCharges: ChargeDetail[],
  matchingCriteria: MatchingCriteriaType | FallbackValue | null,
  overrideChargeDescription = false,
  chargeCodeToDescMap: Record<string, string> | null = null,
  chargeVendorCodeToNameMap: Record<string, string> | null = null,
): LineItemTableData => {
  const invoiceDataTableColumns = overrideChargeDescription
    ? INVOICE_DATA_TABLE_COLUMNS
    : INVOICE_DATA_TABLE_COLUMNS.filter((column) => column !== RECON_TABLE_COLUMNS.DESCRIPTION)
  const expectedDataTableColumns = overrideChargeDescription
    ? EXPECTED_DATA_TABLE_COLUMNS
    : EXPECTED_DATA_TABLE_COLUMNS.filter((column) => column !== RECON_TABLE_COLUMNS.DESCRIPTION)
  const matchingRowsOnExpected = getExpectedRowsBasedOnMatchingCriteria(
    invoiceLineItemReconResults,
    expectedCharges,
    matchingCriteria,
  )
  const bestMatchRowsOnExpected = getBestMatchRowsOnExpected(
    invoiceLineItemReconResults,
    expectedCharges,
  )

  const invoiceTableColumnsWithoutTaxCols = invoiceDataTableColumns.filter(
    (invoiceDataTableColumn) =>
      !(
        invoiceDataTableColumn === RECON_TABLE_COLUMNS.TAX_ID ||
        invoiceDataTableColumn === RECON_TABLE_COLUMNS.TAX
      ),
  )
  const invoiceTableErrorCells = invoiceLineItemReconResults.map(
    (invoiceLineItemReconResult, idx): number[] => {
      const errorCells = []
      if (!invoiceLineItemReconResult.isChargeCodeSame) {
        errorCells.push(
          invoiceDataTableColumns.findIndex(
            (invoiceDataTableColumn) => invoiceDataTableColumn === RECON_TABLE_COLUMNS.CHARGE_CODE,
          ),
        )
      }
      if (!invoiceLineItemReconResult.isTotalCostEqual) {
        errorCells.push(
          invoiceDataTableColumns.findIndex(
            (invoiceDataTableColumn) => invoiceDataTableColumn === RECON_TABLE_COLUMNS.COST,
          ),
        )
      }
      if (!invoiceLineItemReconResult.isCurrencySameOrEmpty) {
        errorCells.push(
          invoiceDataTableColumns.findIndex(
            (invoiceDataTableColumn) => invoiceDataTableColumn === RECON_TABLE_COLUMNS.CURRENCY,
          ),
        )
      }
      if (!invoiceLineItemReconResult.isChargeVendorSame) {
        errorCells.push(
          invoiceDataTableColumns.findIndex(
            (invoiceDataTableColumn) => invoiceDataTableColumn === RECON_TABLE_COLUMNS.VENDOR,
          ),
        )
      }
      if (!invoiceLineItemReconResult.isInvoiceNumberCorrect) {
        errorCells.push(
          invoiceDataTableColumns.findIndex(
            (invoiceDataTableColumn) =>
              invoiceDataTableColumn === RECON_TABLE_COLUMNS.INVOICE_NUMBER,
          ),
        )
      }
      if (
        matchingRowsOnExpected[idx].length === 0 &&
        invoiceDataTableColumns.includes(RECON_TABLE_COLUMNS.DESCRIPTION)
      ) {
        errorCells.push(
          invoiceDataTableColumns.findIndex(
            (invoiceDataTableColumn) => invoiceDataTableColumn === RECON_TABLE_COLUMNS.DESCRIPTION,
          ),
        )
      }
      if (
        bestMatchRowsOnExpected[idx] < 0 ||
        !isLineItemDateMatching(
          invoiceLineItemReconResult?.invoiceDate,
          expectedCharges[bestMatchRowsOnExpected[idx]]?.invoiceDate,
        )
      ) {
        errorCells.push(
          invoiceDataTableColumns.findIndex(
            (invoiceDataTableColumn) => invoiceDataTableColumn === RECON_TABLE_COLUMNS.INVOICE_DATE,
          ),
        )
      }
      if (
        bestMatchRowsOnExpected[idx] < 0 ||
        !isLineItemDateMatching(
          invoiceLineItemReconResult?.dueDate,
          expectedCharges[bestMatchRowsOnExpected[idx]]?.dueDate,
        )
      ) {
        errorCells.push(
          invoiceDataTableColumns.findIndex(
            (invoiceDataTableColumn) => invoiceDataTableColumn === RECON_TABLE_COLUMNS.DUE_DATE,
          ),
        )
      }
      const errorTableColumns = errorCells.map((index) => invoiceDataTableColumns[index])
      if (invoiceTableColumnsWithoutTaxCols.every((value) => errorTableColumns.includes(value))) {
        errorCells.push(
          invoiceDataTableColumns.findIndex(
            (invoiceDataTableColumn) => invoiceDataTableColumn === RECON_TABLE_COLUMNS.TAX_ID,
          ),
        )
        errorCells.push(
          invoiceDataTableColumns.findIndex(
            (invoiceDataTableColumn) => invoiceDataTableColumn === RECON_TABLE_COLUMNS.TAX,
          ),
        )
      }
      return errorCells
    },
  )

  const expectedTableErrorCells = [] as number[][][]
  matchingRowsOnExpected.forEach((_, invoiceRowIdx) => {
    expectedTableErrorCells.push([] as number[][])
    expectedCharges.forEach((_, expectedRowIdx) => {
      /*
        Note:
        - For matching by ChargeCode or ChargeCodeVendorInvoiceNumber, the columns highlighted in
          the expected table should be the same columns that are highlighted in the hovered row
          in the invoice table.
        - And for matching by VendorInvoiceNumber, same as above except we also highlight both
          the charge code & the cost if the charge code don't match. See Acceptance Criteria #4
          of [PD-854](https://expedock.atlassian.net/browse/PD-854)
      */
      const expectedErrorCells = invoiceTableErrorCells[invoiceRowIdx].slice()
      if (matchingCriteria === MatchingCriteriaType.VendorInvoiceNumber) {
        const chargeCodeIdx = invoiceDataTableColumns.findIndex(
          (invoiceDataTableColumn) => invoiceDataTableColumn === RECON_TABLE_COLUMNS.CHARGE_CODE,
        )
        const costIdx = invoiceDataTableColumns.findIndex(
          (invoiceDataTableColumn) => invoiceDataTableColumn === RECON_TABLE_COLUMNS.COST,
        )
        if (
          !expectedErrorCells.includes(chargeCodeIdx) &&
          !sameChargeCode(
            invoiceLineItemReconResults[invoiceRowIdx],
            expectedCharges[expectedRowIdx],
          )
        ) {
          expectedErrorCells.push(chargeCodeIdx)
          expectedErrorCells.push(costIdx)
        }
        if (
          !expectedErrorCells.includes(costIdx) &&
          !sameCost(invoiceLineItemReconResults[invoiceRowIdx], expectedCharges[expectedRowIdx])
        ) {
          expectedErrorCells.push(costIdx)
        }
      }
      // this additional condition is for the isPosted column in the expected charges table
      // should only be highlighted red if the rest of the columns are also highlighted red
      if (expectedErrorCells.length === invoiceDataTableColumns.length) {
        const isPostedIdx = expectedDataTableColumns.findIndex(
          (expectedDataTableColumn) => expectedDataTableColumn === RECON_TABLE_COLUMNS.IS_POSTED,
        )
        expectedErrorCells.push(isPostedIdx)
      }
      expectedTableErrorCells[invoiceRowIdx].push(expectedErrorCells)
    })
  })

  const invoiceTableErrorOutlineCells = invoiceLineItemReconResults.map(
    (invoiceLineItemReconResult) =>
      invoiceLineItemReconResult.success ? [] : [...Array(invoiceDataTableColumns.length).keys()],
  )

  let expectedErrorOutlineCells = Array(expectedCharges.length).fill(null)
  invoiceTableErrorOutlineCells.forEach((invoiceTableErrorOutlineRow, invoiceRowIdx) => {
    matchingRowsOnExpected[invoiceRowIdx].forEach((expectedRowIdx) => {
      if (
        invoiceTableErrorOutlineRow.length === 0 &&
        expectedRowIdx === bestMatchRowsOnExpected[invoiceRowIdx]
      ) {
        expectedErrorOutlineCells[expectedRowIdx] = []
      } else if (expectedErrorOutlineCells[expectedRowIdx] === null) {
        expectedErrorOutlineCells[expectedRowIdx] = [
          ...Array(expectedDataTableColumns.length).keys(),
        ]
      }
    })
  })

  expectedErrorOutlineCells = expectedErrorOutlineCells.map((errorOutlineCells) =>
    errorOutlineCells === null ? [] : (errorOutlineCells as number[]),
  ) as number[][]

  return {
    invoiceTableData: {
      data: transformChargeDetailsToMatrix(
        docCharges,
        overrideChargeDescription,
        false,
        chargeCodeToDescMap,
        chargeVendorCodeToNameMap,
        false,
      ),
      errorCells: invoiceTableErrorCells,
      errorOutlineCells: invoiceTableErrorOutlineCells,
    },
    expectedTableData: {
      data: transformChargeDetailsToMatrix(
        expectedCharges,
        overrideChargeDescription,
        true,
        chargeCodeToDescMap,
        chargeVendorCodeToNameMap,
        true,
      ),
      expectedErrorCells: expectedTableErrorCells,
      errorOutlineCells: expectedErrorOutlineCells,
    },
  }
}

export const getCargowiseModuleFromReconAttempt = (
  reconAttempt: Maybe<Pick<ReconAttemptNode, 'cargowiseModule'>>,
): CwTargetModule | null => {
  return reconAttempt && isFallback(reconAttempt!.cargowiseModule!)
    ? null
    : (reconAttempt?.cargowiseModule as CwTargetModuleEnum)?.value
}

export const isCustomsDeclarationFromReconResultsHelper = (
  reconAttempt: Maybe<ReconAttemptNode>,
  findShipmentReconResults: FindShipmentReconResultNode[],
): boolean => {
  if (getCargowiseModuleFromReconAttempt(reconAttempt) === CwTargetModule.CustomsDeclaration) {
    return true
  }
  return findShipmentReconResults?.some(
    (findShipmentReconResult) =>
      findShipmentReconResult && findShipmentReconResult?.chainIoCustomsDeclaration?.id,
  )
}
