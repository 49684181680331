import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import {
  ChainIoConsolidationNode,
  ChainIoCustomsDeclarationNode,
  ChainIoShipmentNode,
  CwTargetModule,
  JobNode,
  Query,
} from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import { MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { useLazyQuery } from '@apollo/client'
import { SEARCH_SHIPMENTS } from '@src/graphql/queries/cargowise'
import SearchEntityTable from './SearchEntityTable'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { LogEventType } from '@src/utils/observability/LogEventType'

const useStyles = makeStyles({
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  searchBtn: {
    backgroundColor: theme.palette.primary.dark,
    '&:disabled': {
      backgroundColor: theme.palette.primary.light,
    },
    color: 'white',
    maxWidth: theme.spacing(10),
    marginTop: theme.spacing(2),
  },
})

type Props = {
  isOpen: boolean
  handleClose: () => void
  job: JobNode
}

const SearchShipments: FunctionComponent<Props> = ({ job, isOpen, handleClose }) => {
  const classes = useStyles()
  const { logEvent } = useEventLogger()
  const { enqueueSnackbar } = useSnackbar()
  const [module, setModule] = useState(CwTargetModule.ForwardingShipment as string)
  const [searchKey, setSearchKey] = useState('')
  const [prevSearchKey, setPrevSearchKey] = useState('')
  const [resultsFound, setResultsFound] = useState(null as boolean | null)
  const [retrievedObjects, setRetrievedObjects] = useState(
    [] as (ChainIoShipmentNode | ChainIoCustomsDeclarationNode | ChainIoConsolidationNode)[],
  )
  const [getSearchResults, { loading: searchShipmentsLoading }] = useLazyQuery<
    Pick<Query, 'searchShipments'>
  >(SEARCH_SHIPMENTS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // most recent first helps find
      const sortedShipments = [...data.searchShipments].sort(
        (a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime(),
      )
      setRetrievedObjects(sortedShipments)
      setPrevSearchKey(searchKey)
      setResultsFound(!!sortedShipments.length)
      void logEvent(LogEventType.SHIPMENT_SEARCH, {
        searchKey: searchKey,
        module: module,
        job_id: job.id,
        shipments: sortedShipments.map((s): string => s.id as string),
      })
    },
    onError: (e) => {
      enqueueSnackbar(formatMaybeApolloError(e), { variant: 'error' })
    },
  })

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose}>
      <div className={classes.close}>
        <CloseIcon fontSize='large' onClick={handleClose} data-testid='close-btn' />
      </div>
      <DialogTitle>
        <Box display='flex' alignItems='center'>
          Search For Shipments/Consols/Customs Declarations
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column'>
          <div>
            <div>
              <Typography> Module </Typography>
              <Select
                data-testid='job-type-select'
                value={module}
                onChange={(e) => setModule((e.target as HTMLInputElement).value)}
                variant='outlined'
                placeholder='Job Type'
              >
                {[
                  CwTargetModule.ForwardingShipment,
                  CwTargetModule.ForwardingConsol,
                  CwTargetModule.CustomsDeclaration,
                ].map((moduleName) => (
                  <MenuItem key={moduleName} value={moduleName} data-testid={moduleName}>
                    {moduleName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Typography> Search Key </Typography>
            <TextField
              onChange={(e) => setSearchKey(e.target.value)}
              variant='outlined'
              placeholder='Search Key'
            >
              {searchKey}
            </TextField>
          </div>
          {resultsFound === false && (
            <Typography>
              No entities were found for <strong>{prevSearchKey}</strong>
            </Typography>
          )}
          {resultsFound && retrievedObjects.length > 0 && (
            <>
              <Typography>
                The following entities were found for <strong>{prevSearchKey}</strong>:
              </Typography>
              <SearchEntityTable
                prevSearchKey={prevSearchKey}
                objects={retrievedObjects}
                objectType={retrievedObjects[0]!.__typename || ''}
              />
            </>
          )}
          <Button
            className={classes.searchBtn}
            disabled={searchShipmentsLoading}
            onClick={() =>
              getSearchResults({
                variables: {
                  cargowiseModule: module,
                  jobTemplateId: job!.jobTemplate!.id,
                  searchStr: searchKey,
                },
              })
            }
          >
            Search
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SearchShipments
