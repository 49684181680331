import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import { useSnackbar } from 'notistack'

import Title from '@src/components/Title'
import ContentWithSidebar from '@src/components/content-with-sidebar/ContentWithSidebar'
import JobViewer from '@src/components/job-viewer'
import { SidebarProvider } from '@src/components/content-with-sidebar/sidebar-context'

import { GET_JOB_DATA_LITE, JOB_OPERATORS } from '@src/graphql/queries/job'
import { SET_JOB_OWNER, MOVE_JOB_TO_IN_PROGRESS } from '@src/graphql/mutations/job'
import { JobStatus, ManualProduct, Mutation, Query, UserNode } from '@src/graphql/types'
import { redirectIfWrongStatus } from '@src/utils/job'
import { useHistory, useParams } from 'react-router-dom'
import { JobDataProvider } from '@src/contexts/job_data_context'

const TodoPage: FunctionComponent = () => {
  const { id: jobId } = useParams<{ id: string }>()
  const history = useHistory()
  const { data: jobData, loading: isLoadingJobData } = useQuery<Pick<Query, 'job'>>(
    GET_JOB_DATA_LITE,
    { variables: { id: jobId } },
  )
  const { enqueueSnackbar } = useSnackbar()
  const [ownerId, setOwnerId] = useState(null as null | string)
  const [moveJobToInProgress] = useMutation<Pick<Mutation, 'setJobOwner'>>(
    MOVE_JOB_TO_IN_PROGRESS,
    {
      update: (cache) => {
        cache.modify({
          fields: {
            // Invalidate countByJobStatus field and refreshes cache
            countByJobStatus() {},
          },
        })
      },
    },
  )
  const [setJobOwner, { error: setJobOwnerError }] =
    useMutation<Pick<Mutation, 'setJobOwner'>>(SET_JOB_OWNER)
  const { data: jobOperatorData, loading: isLoadingOperators } =
    useQuery<Pick<Query, 'jobOperators'>>(JOB_OPERATORS)

  const operators = jobOperatorData?.jobOperators || []
  const changeJobOwner = async (currOperatorId: string): Promise<void> => {
    await setJobOwner({
      variables: {
        jobId,
        ownerId: currOperatorId,
      },
    })
    if (setJobOwnerError) {
      enqueueSnackbar(`Failed to update job owner: ${formatMaybeApolloError(setJobOwnerError)}`, {
        variant: 'error',
      })
      return
    }
    setOwnerId(currOperatorId)
    enqueueSnackbar('Owner successfully updated', { variant: 'success' })
  }

  const navigateToInProgress = useCallback(async (): Promise<void> => {
    history.push(`/inprogress/${jobId}`)
  }, [history, jobId])

  const moveToInProgress = useCallback(async (): Promise<void> => {
    try {
      await moveJobToInProgress({
        variables: { jobId },
      })
    } catch (error) {
      if (setJobOwnerError) {
        enqueueSnackbar(
          `Error moving to in progress: ${formatMaybeApolloError(error)}: ${formatMaybeApolloError(
            setJobOwnerError,
          )}`,
          {
            variant: 'error',
          },
        )
        return
      }
    }
    enqueueSnackbar('Job successfully moved to in progress!', { variant: 'success' })
    await navigateToInProgress()
  }, [enqueueSnackbar, jobId, moveJobToInProgress, navigateToInProgress, setJobOwnerError])

  useEffect(() => {
    if (!isLoadingJobData) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      redirectIfWrongStatus(history, jobData!.job!)
      setOwnerId(jobData!.job!.ownerId!)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData, isLoadingJobData])

  return (
    <SidebarProvider>
      <Title title='Todo Job' />
      <ContentWithSidebar
        sidebar={
          <Box display='flex' flexDirection='column' alignItems='stretch' height='100%' p={4}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel>Job Owner</InputLabel>
              <Select
                value={ownerId || ''}
                onChange={(e) => changeJobOwner(e.target.value as string)}
                label='Job Owner'
                variant='outlined'
                disabled={isLoadingOperators}
              >
                {operators?.map((currOperator: UserNode) => (
                  <MenuItem key={currOperator.id} value={currOperator.id}>
                    {currOperator.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box display='flex' alignItems='center' flexGrow={1} p={4}>
              <Typography variant='h2' align='center'>
                {jobData?.job?.manualProduct === ManualProduct.Manual &&
                  'Move this job to "In Progress" '}
                {jobData?.job?.manualProduct === ManualProduct.Product && 'Upload a file '}
                to get started and let others know you are working on this job!
              </Typography>
            </Box>
            <Box display='flex' justifyContent='center'>
              {jobData?.job?.manualProduct === ManualProduct.Manual && (
                <Button variant='contained' color='primary' onClick={moveToInProgress}>
                  Move to In Progress
                </Button>
              )}
            </Box>
          </Box>
        }
        content={
          <JobDataProvider jobId={jobId}>
            <JobViewer
              jobId={jobId}
              onFilePageChange={useCallback(
                async (filePage) => {
                  if (filePage) {
                    await navigateToInProgress()
                  }
                },
                [navigateToInProgress],
              )}
              onLoadComplete={useCallback(
                async (hasFilePage) => {
                  if (hasFilePage && jobData?.job?.status === JobStatus.Todo) {
                    await moveToInProgress()
                  }
                },
                [jobData?.job?.status, moveToInProgress],
              )}
            />
          </JobDataProvider>
        }
      />
    </SidebarProvider>
  )
}

export default TodoPage
