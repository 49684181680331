import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  columnSelectors,
  dragGridReducer,
  initializeGrid,
  magicGridColumnsAdapter,
  magicGridRowsAdapter,
  moveGridColumnReducer,
  moveGridRowReducer,
  readDataFromNewGridReducer,
  readMagicGridReducer,
  resizeGridReducer,
  rowSelectors,
  TableExtractionStep,
} from '@src/redux-features/document_editor/magic_grid'
import {
  lineItemsAdapter,
  updateLineItemFieldDimensionReducer,
  resizeLineItemReducer,
  selectActiveLineItemsTable,
  addNewDocumentTableReducer,
  updateTableColumnsReducer,
  updateTableLineItemsReducer,
  addLineItemRowsReducer,
  addTableColumnReducer,
  removeTableColumnReducer,
  copyFromExtractedTablesReducer,
} from '@src/redux-features/document_editor/line_items_table'
import {
  insertColumn,
  insertRow,
  recalculateColumnDimensions,
  recalculateRowDimensions,
} from '@src/utils/magic_grid'
import { getLineItemFieldCoordinates, LineItem } from '@src/utils/line_items'
import { BoxDimension, GoogleOCRData } from '@src/types/ocr'
import { DocumentTableNode, FilePageNode, JobNode } from '@src/graphql/types'
import { enablePatches } from 'immer'
import { reportRollbarError } from '../../utils/observability/rollbar'
import { updateDocumentTablesReducer } from '@src/redux-features/document_editor/document_table'
import deleteFilePageFromState from './delete_file_page'
import {
  clearActiveLineItemsClipboardReducer,
  clearLineItemsClipboardReducer,
  createRowsFromClipboardNoSnapReducer,
  createRowsFromClipboardReducer,
} from './line_items_clipboard'
import { getInitialState } from './document_editor_state'
import { selectFieldCoordinates } from './field'
import { selectActiveDocumentEditorPage, selectActiveDocument } from './document'
import { updatePageFieldEditorStateReducer } from './file_page'
import {
  createFieldBoxReducer,
  deleteActiveFieldBoxOrLineItemsReducer,
  toggleRowBoxReducer,
  updateHighlightedBoxesReducer,
} from './boxn'
import {
  changeFieldBoxColumnReducer,
  dragFieldBoxReducer,
  dragRowAndFieldBoxesReducer,
  resizeFieldBoxReducer,
} from './field_box'
import {
  resetLineItemsChangesReducer,
  redoLastRowFieldBoxActionReducer,
  undoLastRowFieldBoxActionReducer,
} from './undo_redo'
import { initalizeReducer } from './initalize'
import { deleteLineItemRowsReducer, lineItemSelectors } from './line_items_table'
import { jobTableLineItemSelectors } from './job_table'
import { SpreadsheetDataColumn } from '@src/utils/data-grid'
import { ChargeCodeTax } from '@src/components/data-grid/types'

enablePatches()

const documentEditorSlice = createSlice({
  name: 'documentEditor',
  initialState: getInitialState(),
  reducers: {
    /**
     * initialize with freshly retrieved job data, overwriting all state
     */
    initialize: {
      reducer: (
        outerState,
        {
          payload: { job, activeFilePageId, enableLineItemsRowOrderPriority },
        }: PayloadAction<{
          job: JobNode
          activeFilePageId: string
          enableLineItemsRowOrderPriority: boolean
        }>,
      ) => {
        initalizeReducer(outerState, job, activeFilePageId, enableLineItemsRowOrderPriority)
      },
      prepare: (
        job: JobNode,
        activeFilePageId: string,
        enableLineItemsRowOrderPriority: boolean,
      ) => {
        return {
          payload: {
            job,
            activeFilePageId,
            enableLineItemsRowOrderPriority,
          },
        }
      },
    },
    updateDocumentTables: (
      state,
      { payload: documentTables }: PayloadAction<DocumentTableNode[]>,
    ) => {
      updateDocumentTablesReducer(state, documentTables)
    },
    setActiveFieldKey: (state, { payload: activeFieldKey }: PayloadAction<string | null>) => {
      state.activeFieldKey = activeFieldKey
    },
    setCopiedText: (state, { payload: text }: PayloadAction<string | null>) => {
      // Set text to clipboard and current field
      if (text != null) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigator.clipboard.writeText(text ?? '')
      }
      state.copiedText = text
    },
    deleteOneFieldCoordinates: (state, { payload: key }: PayloadAction<string>) => {
      delete selectFieldCoordinates(state)?.[key]
    },
    updateOneFieldCoordinates: {
      reducer: (
        state,
        { payload: { key, dimension } }: PayloadAction<{ key: string; dimension: BoxDimension }>,
      ) => {
        const fieldCoordinates = selectFieldCoordinates(state)
        if (fieldCoordinates) {
          fieldCoordinates[key] = dimension
        }
      },
      prepare: (key: string, dimension: BoxDimension) => {
        return {
          payload: {
            key,
            dimension,
          },
        }
      },
    },
    updatePageFieldEditorState: {
      reducer: (
        state,
        {
          payload: { fieldMapping, newActiveFilePageId },
        }: PayloadAction<{ fieldMapping: Record<string, string>; newActiveFilePageId?: string }>,
      ) => {
        updatePageFieldEditorStateReducer(state, fieldMapping, newActiveFilePageId)
      },
      prepare: (fieldMapping: Record<string, string>, newActiveFilePageId?: string) => ({
        payload: {
          fieldMapping,
          newActiveFilePageId,
        },
      }),
    },
    readMagicGrid: (state) => {
      readMagicGridReducer(state)
    },
    // magic grid
    /**
     * dragging is distinct from scaling because we can avoid recomputation here
     */
    dragGrid: {
      reducer: (
        state,
        { payload: { deltaX, deltaY } }: PayloadAction<{ deltaX: number; deltaY: number }>,
      ) => {
        dragGridReducer(state, deltaX, deltaY)
      },
      prepare: (deltaX: number, deltaY: number) => {
        return {
          payload: {
            deltaX,
            deltaY,
          },
        }
      },
    },
    // for setting the initial grid dimension
    setInitialGridDimension: (state, { payload: dimension }: PayloadAction<BoxDimension>) => {
      initializeGrid(state, dimension)
    },
    // for resizing an already existing grid
    resizeGrid: (state, { payload: gridDimension }: PayloadAction<BoxDimension>) => {
      resizeGridReducer(state, gridDimension)
    },
    setTableExtractionStep: (state, action: PayloadAction<TableExtractionStep>) => {
      state.currentTableExtractionStep = action.payload
    },
    moveGridColumn: {
      reducer: (state, { payload: { id, left } }: PayloadAction<{ id: string; left: number }>) => {
        moveGridColumnReducer(state, id, left)
      },
      prepare: (id: string, left: number) => {
        return {
          payload: {
            id,
            left,
          },
        }
      },
    },
    moveGridRow: {
      reducer: (state, { payload: { id, top } }: PayloadAction<{ id: string; top: number }>) => {
        moveGridRowReducer(state, id, top)
      },
      prepare: (id: string, top: number) => {
        return {
          payload: {
            id,
            top,
          },
        }
      },
    },
    setIgnoreGridRow: {
      reducer: (
        state,
        { payload: { id, ignore } }: PayloadAction<{ id: string; ignore: boolean }>,
      ) => {
        const grid = state.magicGridMap[state.activeDocumentTableId!]
        magicGridRowsAdapter.updateOne(grid.rows, {
          id,
          changes: {
            ignore,
          },
        })
      },
      prepare: (id: string, ignore: boolean) => {
        return {
          payload: {
            id,
            ignore,
          },
        }
      },
    },
    setGridColumnKey: {
      reducer: (
        state,
        { payload: { id, key } }: PayloadAction<{ id: string; key: string | null }>,
      ) => {
        const grid = state.magicGridMap[state.activeDocumentTableId!]
        magicGridColumnsAdapter.updateOne(grid.columns, {
          id,
          changes: {
            key,
          },
        })
      },
      prepare: (id: string, key: string | null) => {
        return {
          payload: {
            id,
            key,
          },
        }
      },
    },
    addGridColumn: (state, { payload: xCoord }: PayloadAction<number>) => {
      const grid = state.magicGridMap[state.activeDocumentTableId!]
      if (grid?.dimension == null) {
        reportRollbarError(
          `Called addGridColumn w/ null: grid dimension ${grid?.dimension == null}`,
        )
        return
      }
      const columns = columnSelectors.selectAll(state)!
      const newColumns = recalculateColumnDimensions(
        insertColumn(xCoord, columns, grid.dimension),
        grid.dimension,
      )
      magicGridColumnsAdapter.setAll(grid.columns, newColumns)
    },
    deleteGridColumn: (state, { payload: id }: PayloadAction<string>) => {
      const grid = state.magicGridMap[state.activeDocumentTableId!]
      if (grid?.dimension == null) {
        reportRollbarError(
          `Called deleteGridColumn w/ null: grid dimension ${grid?.dimension == null}`,
        )
        return
      }
      magicGridColumnsAdapter.removeOne(grid.columns, id)
      const columns = columnSelectors.selectAll(state)!
      const newColumns = recalculateColumnDimensions(columns, grid.dimension)
      magicGridColumnsAdapter.setAll(grid.columns, newColumns)
    },
    addGridRow: (state, { payload: yCoord }: PayloadAction<number>) => {
      const grid = state.magicGridMap[state.activeDocumentTableId!]
      if (grid?.dimension == null) {
        reportRollbarError(`Called addGridRow w/ null: grid dimension ${grid?.dimension == null}`)
        return
      }
      const rows = rowSelectors.selectAll(state)!
      const newRows = recalculateRowDimensions(
        insertRow(yCoord, rows, grid.dimension),
        grid.dimension,
      )
      magicGridRowsAdapter.setAll(grid.rows, newRows)
    },
    deleteGridRow: (state, { payload: id }: PayloadAction<string>) => {
      const grid = state.magicGridMap[state.activeDocumentTableId!]
      magicGridRowsAdapter.removeOne(grid.rows, id)
    },
    addLineItem: (state, { payload: lineItem }: PayloadAction<LineItem>) => {
      const lineItemsTable = state.lineItemsTableMap[state.activeDocumentTableId!]
      const newLineItem = {
        ...lineItem,
        documentTableId: state.activeDocumentTableId,
      } as LineItem
      lineItemsAdapter.addOne(lineItemsTable.lineItems, newLineItem)
      const documentEditorPage = selectActiveDocumentEditorPage(state)!
      Object.assign(documentEditorPage.fieldCoordinates, getLineItemFieldCoordinates([newLineItem]))
    },
    updateLineItemFieldDimension: {
      reducer: (
        state,
        {
          payload: { id, key, dimension },
        }: PayloadAction<{ id: string; key: string; dimension: BoxDimension }>,
      ) => {
        updateLineItemFieldDimensionReducer(state, id, key, dimension)
      },
      prepare: (id: string, key: string, dimension: BoxDimension) => {
        return {
          payload: {
            id,
            key,
            dimension,
          },
        }
      },
    },
    setLineItemsTableSubmitted: (state, { payload: submitted }: PayloadAction<boolean>) => {
      const lineItemsTable = state.lineItemsTableMap[state.activeDocumentTableId!]
      if (lineItemsTable) {
        lineItemsTable.submitted = submitted
      }
    },
    highlightLineItem: (state, { payload: id }: PayloadAction<string | null>) => {
      state.highlightedLineItemId = id
    },
    setCurrentUserEmail: (state, { payload: email }: PayloadAction<string | null>) => {
      state.currentUserEmail = email
    },
    addNewDocumentTable: (
      state,
      {
        payload: {
          docTable,
          prevTableId,
          newFilePage,
          newFilePageIdx,
          job,
          enableLineItemsRowOrderPriority,
        },
      }: PayloadAction<{
        docTable: DocumentTableNode
        prevTableId: string
        newFilePage: FilePageNode
        newFilePageIdx: number
        job?: JobNode | null
        enableLineItemsRowOrderPriority: boolean
      }>,
    ) => {
      if (job) {
        addNewDocumentTableReducer(
          state,
          docTable,
          prevTableId,
          newFilePage,
          newFilePageIdx,
          job,
          enableLineItemsRowOrderPriority,
        )
      }
    },
    // This is for setting the documentTable currently being viewed in the MagicGrid and LineItemsTable components.
    // This flushes all existing edits on MagicGrid and LineItemsTable to documentTable.magicGrid and lineItemsTable, respectively.
    setActiveDocumentTable: (
      state,
      { payload: activeDocumentTableId }: PayloadAction<string | null>,
    ) => {
      state.activeDocumentTableId = activeDocumentTableId
      if (!state.activeDocumentTableId) {
        state.activeFieldKey = null
      } else {
        const lineItemsTable = state.lineItemsTableMap[activeDocumentTableId!]
        lineItemsTable.editedByUser = true
      }
    },
    setTableEditMode: (state, { payload: tableEditModeEnabled }: PayloadAction<boolean>) => {
      state.tableEditModeEnabled = tableEditModeEnabled
    },
    deleteFilePage: (state, { payload: filePageId }: PayloadAction<string>) => {
      deleteFilePageFromState(state, filePageId)
    },
    setDocTableValidationErrors: (
      state,
      { payload: docTableValidationErrors }: PayloadAction<Record<string, string[]>>,
    ) => {
      state.docTableValidationErrors = docTableValidationErrors
    },
    setActiveLineItemIds: (state, { payload: lineItemIds }: PayloadAction<string[]>) => {
      state.activeLineItemIds = lineItemIds
    },
    setActiveFieldBoxId: (state, { payload: fieldBoxId }: PayloadAction<string | null>) => {
      state.boxn.activeFieldBoxId = fieldBoxId
    },
    updateLineItemBoxDimension: {
      reducer: (
        state,
        {
          payload: { lineItemId, changes },
        }: PayloadAction<{ lineItemId: string; changes: Partial<LineItem> }>,
      ) => {
        const lineItems = selectActiveLineItemsTable(state)?.lineItems
        if (lineItems) {
          lineItemsAdapter.updateOne(lineItems, { id: lineItemId, changes })
        }
      },
      prepare: (lineItemId: string, changes: Partial<LineItem>) => {
        return {
          payload: {
            lineItemId,
            changes,
          },
        }
      },
    },
    deleteActiveFieldBoxOrLineItems: (state) => {
      deleteActiveFieldBoxOrLineItemsReducer(state)
    },
    resizeLineItem: {
      reducer: (
        state,
        {
          payload: { lineItemId, dimension },
        }: PayloadAction<{ lineItemId: string; dimension: BoxDimension }>,
      ) => {
        resizeLineItemReducer(state, lineItemId, dimension)
      },
      prepare: (lineItemId: string, dimension: BoxDimension) => {
        return {
          payload: {
            lineItemId,
            dimension,
          },
        }
      },
    },
    dragRowAndFieldBoxes: {
      reducer: (
        state,
        {
          payload: { lineItemId, coords },
        }: PayloadAction<{ lineItemId: string; coords: Pick<BoxDimension, 'top' | 'left'> }>,
      ) => {
        dragRowAndFieldBoxesReducer(state, lineItemId, coords)
      },
      prepare: (lineItemId: string, coords: Pick<BoxDimension, 'top' | 'left'>) => {
        return {
          payload: {
            lineItemId,
            coords,
          },
        }
      },
    },
    dragFieldBox: {
      reducer: (
        state,
        {
          payload: { lineItemId, fieldBoxId, coords },
        }: PayloadAction<{
          lineItemId: string
          fieldBoxId: string
          coords: Pick<BoxDimension, 'top' | 'left'>
        }>,
      ) => {
        dragFieldBoxReducer(state, lineItemId, fieldBoxId, coords)
      },
      prepare: (lineItemId: string, fieldBoxId, coords: Pick<BoxDimension, 'top' | 'left'>) => {
        return {
          payload: {
            lineItemId,
            fieldBoxId,
            coords,
          },
        }
      },
    },
    resizeFieldBox: {
      reducer: (
        state,
        {
          payload: { lineItemId, fieldBoxId, dimension },
        }: PayloadAction<{
          lineItemId: string
          fieldBoxId: string
          dimension: BoxDimension
        }>,
      ) => {
        resizeFieldBoxReducer(state, lineItemId, fieldBoxId, dimension)
      },
      prepare: (lineItemId: string, fieldBoxId: string, dimension: BoxDimension) => {
        return {
          payload: {
            lineItemId,
            fieldBoxId,
            dimension,
          },
        }
      },
    },
    setNewFieldBoxId: (state, { payload: fieldBoxId }: PayloadAction<string | null>) => {
      state.boxn.newFieldBoxId = fieldBoxId
    },
    createFieldBox: (state, { payload: dimension }: PayloadAction<BoxDimension>) => {
      createFieldBoxReducer(state, dimension)
    },
    changeFieldBoxColumn: {
      reducer: (
        state,
        {
          payload: { lineItemId, fieldBoxId, columnKey },
        }: PayloadAction<{ lineItemId: string; fieldBoxId: string; columnKey: string }>,
      ) => {
        changeFieldBoxColumnReducer(state, lineItemId, fieldBoxId, columnKey)
      },
      prepare: (lineItemId: string, fieldBoxId: string, columnKey: string) => {
        return {
          payload: {
            lineItemId,
            fieldBoxId,
            columnKey,
          },
        }
      },
    },
    selectAllLineItems: (state) => {
      const lineItems = selectActiveLineItemsTable(state)?.lineItems
      if (lineItems) {
        state.activeLineItemIds = lineItemsAdapter.getSelectors().selectIds(lineItems) as string[]
      }
    },
    clearLineItemsClipboard: clearLineItemsClipboardReducer,
    copyActiveRowBoxes: (state) => {
      state.lineItemIdsClipboard = [...state.activeLineItemIds]
    },
    createRowsFromClipboard: {
      reducer: (state, { payload: lineItemIds }: PayloadAction<string[]>) => {
        createRowsFromClipboardReducer(state, lineItemIds)
      },
      prepare: (lineItemIds?: string[]) => {
        return {
          payload: lineItemIds && lineItemIds.length > 0 ? lineItemIds : [],
        }
      },
    },
    createRowsFromClipboardNoSnap: {
      reducer: (state, { payload: lineItemIds }: PayloadAction<string[]>) => {
        createRowsFromClipboardNoSnapReducer(state, lineItemIds)
      },
      prepare: (lineItemIds?: string[]) => {
        return {
          payload: lineItemIds && lineItemIds.length > 0 ? lineItemIds : [],
        }
      },
    },
    undoLastRowFieldBoxAction: (state) => {
      undoLastRowFieldBoxActionReducer(state)
    },
    redoLastRowFieldBoxAction: (state) => {
      redoLastRowFieldBoxActionReducer(state)
    },
    readDataFromNewGrid: (state) => {
      readDataFromNewGridReducer(state)
    },
    selectRowBox: (state, { payload: lineItemId }: PayloadAction<string | null>) => {
      state.activeLineItemIds = lineItemId ? [lineItemId] : []
      state.boxn.activeFieldBoxId = null
      state.boxn.highlightedFieldBoxKey = null
    },
    toggleRowBox: (state, { payload: lineItemId }: PayloadAction<string>) => {
      toggleRowBoxReducer(state, lineItemId)
    },
    updateHighlightedBoxes: (
      state,
      {
        payload: { startRow, startCol, endRow, endCol, columnKeys, isJobTable },
      }: PayloadAction<{
        startRow: number
        startCol: number
        endRow: number
        endCol: number
        columnKeys: string[]
        // TODO: prolly want to put this in state?
        isJobTable: boolean
      }>,
    ) => {
      updateHighlightedBoxesReducer(
        state,
        startRow,
        startCol,
        endRow,
        endCol,
        columnKeys,
        isJobTable,
      )
    },
    resetHighlightedBoxes: (state) => {
      state.boxn.highlightedFieldBoxKey = null
      state.boxn.highlightedRowBoxIds = []
      state.boxn.highlightedFieldBoxIds = []
    },
    setCreateBoxEnabled: (state, { payload: createBoxEnabled }: PayloadAction<boolean>) => {
      state.boxn.createBoxEnabled = createBoxEnabled
    },
    setGridHotkeysEnabled: (state, { payload: gridHotkeysEnabled }: PayloadAction<boolean>) => {
      state.boxn.gridHotkeysEnabled = gridHotkeysEnabled
    },
    setGridRowTransform: (state, { payload: transform }: PayloadAction<string>) => {
      state.boxn.gridRowTransform = transform
    },
    setNewGridEnabled: (state, { payload: newGridEnabled }: PayloadAction<boolean>) => {
      state.boxn.newGridEnabled = newGridEnabled
    },
    setIsRowBoxDraggingOrResizing: (
      state,
      { payload: toggleDragOrResize }: PayloadAction<boolean>,
    ) => {
      state.boxn.isRowBoxDraggingOrResizing = toggleDragOrResize
    },
    updateTableColumns: (
      state,
      {
        payload: { startColumnsArr, insertIdxStart, columnKeys },
      }: PayloadAction<{
        startColumnsArr: number[]
        insertIdxStart: number
        columnKeys: string[]
      }>,
    ) => {
      updateTableColumnsReducer(
        state,
        startColumnsArr,
        insertIdxStart,
        columnKeys,
        state.jobTableActive,
      )
    },
    updateTableLineItems: (
      state,
      {
        payload: { changes, source, columns, googleOcrData, chargeCodeTaxMap },
      }: PayloadAction<{
        changes: [number, string | number, string | number | null, string | number | null][]
        source: string
        columns: SpreadsheetDataColumn[]
        googleOcrData: GoogleOCRData | null
        chargeCodeTaxMap: Record<string, ChargeCodeTax> | null
      }>,
    ) => {
      updateTableLineItemsReducer(
        state,
        changes,
        source,
        columns,
        googleOcrData,
        state.jobTableActive,
        chargeCodeTaxMap,
      )
    },
    addLineItemRows: (
      state,
      {
        payload: { index, amount, columnKeys, googleOcrData },
      }: PayloadAction<{
        index: number
        amount: number
        columnKeys: string[]
        googleOcrData: GoogleOCRData | null
      }>,
    ) => {
      addLineItemRowsReducer(state, index, amount, columnKeys, googleOcrData, state.jobTableActive)
    },
    deleteLineItemRows: (
      state,
      {
        payload: { index, amount },
      }: PayloadAction<{
        index: number
        amount: number
      }>,
    ) => {
      deleteLineItemRowsReducer(state, index, amount, state.jobTableActive)
    },
    addTableColumn: (
      state,
      {
        payload: { index, columns, colKey },
      }: PayloadAction<{
        index: number
        columns: string[]
        colKey: string
      }>,
    ) => {
      addTableColumnReducer(state, columns, index, colKey, state.jobTableActive)
    },
    removeTableColumn: (
      state,
      {
        payload: { columns, colKey },
      }: PayloadAction<{
        columns: string[]
        colKey: string
      }>,
    ) => {
      removeTableColumnReducer(state, columns, colKey, state.jobTableActive)
    },
    setJobTableActive: (state, { payload: jobTableActive }: PayloadAction<boolean>) => {
      state.jobTableActive = jobTableActive
    },
    copyActiveLineItems: (
      state,
      {
        payload: { columnKeys, coords, copiedText },
      }: PayloadAction<{
        columnKeys: string[]
        coords: { startRow: number; endRow: number; startCol: number; endCol: number }[]
        copiedText: string[][]
      }>,
    ) => {
      const orderedColKeys = columnKeys.slice(coords[0]['startCol'], coords[0]['endCol'] + 1)
      const lineItems = state.jobTableActive
        ? jobTableLineItemSelectors.selectAll(state) ?? []
        : lineItemSelectors.selectAll(state) ?? []
      const documentId = state.jobTableActive ? null : selectActiveDocument(state)?.id
      if (documentId === undefined) {
        return
      }
      const copiedLineItems = lineItems.slice(coords[0]['startRow'], coords[0]['endRow'] + 1)
      state.lineItemsClipboard = {
        lineItems: copiedLineItems,
        documentId,
        orderedColKeys,
        copiedText,
      }
    },
    clearActiveLineItemsClipboard: clearActiveLineItemsClipboardReducer,
    resetLineItemsChanges: resetLineItemsChangesReducer,
    copyFromExtractedTables: (state) => {
      copyFromExtractedTablesReducer(state)
    },
    updateCurrentJob: (
      state,
      {
        payload: { job },
      }: PayloadAction<{
        job?: JobNode | null
      }>,
    ) => {
      if (job) {
        state.job = job
      }
    },
  },
})

export const {
  initialize: initializeDocumentEditor,
  setActiveFieldKey,
  setCopiedText,
  deleteOneFieldCoordinates,
  updateOneFieldCoordinates,
  updatePageFieldEditorState,
  readMagicGrid,
  dragGrid,
  setInitialGridDimension,
  resizeGrid,
  setTableExtractionStep,
  moveGridColumn,
  moveGridRow,
  setIgnoreGridRow,
  setGridColumnKey,
  addGridColumn,
  deleteGridColumn,
  addGridRow,
  deleteGridRow,
  addLineItem,
  updateLineItemBoxDimension,
  updateLineItemFieldDimension,
  highlightLineItem,
  setLineItemsTableSubmitted,
  setCurrentUserEmail,
  addNewDocumentTable,
  setActiveDocumentTable,
  setTableEditMode,
  deleteFilePage,
  setDocTableValidationErrors,
  setActiveLineItemIds,
  setActiveFieldBoxId,
  deleteActiveFieldBoxOrLineItems,
  resizeLineItem,
  dragRowAndFieldBoxes,
  dragFieldBox,
  resizeFieldBox,
  setNewFieldBoxId,
  createFieldBox,
  changeFieldBoxColumn,
  selectAllLineItems,
  clearLineItemsClipboard,
  copyActiveRowBoxes,
  createRowsFromClipboard,
  createRowsFromClipboardNoSnap,
  undoLastRowFieldBoxAction,
  redoLastRowFieldBoxAction,
  readDataFromNewGrid,
  selectRowBox,
  updateDocumentTables,
  toggleRowBox,
  updateHighlightedBoxes,
  resetHighlightedBoxes,
  setCreateBoxEnabled,
  setGridHotkeysEnabled,
  setGridRowTransform,
  setIsRowBoxDraggingOrResizing,
  setNewGridEnabled,
  updateTableColumns,
  updateTableLineItems,
  addLineItemRows,
  deleteLineItemRows,
  setJobTableActive,
  copyActiveLineItems,
  clearActiveLineItemsClipboard,
  resetLineItemsChanges,
  addTableColumn,
  removeTableColumn,
  copyFromExtractedTables,
  updateCurrentJob,
} = documentEditorSlice.actions

export default documentEditorSlice.reducer
