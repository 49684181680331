/** Used in PageChecker to avoid having to pass jobData down. */
// seems like it encompasses the same context as ShipmentFormContext -- could prolly be combined?
import { createContext, FunctionComponent, useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import {
  DocumentTypeNode,
  FilePageNode,
  JobNode,
  Maybe,
  Query,
  QueryJobArgs,
} from '@src/graphql/types'
import { GET_JOB_DATA } from '@src/graphql/queries/job'

type JobDataContextType = {
  job: Maybe<JobNode> | undefined
  jobLoading: boolean
  jobData: Pick<Query, 'job'> | undefined
  jobRefetch: () => Promise<void>
  filePages: FilePageNode[]
  documentTypes: DocumentTypeNode[]
}

export const JobDataContext = createContext<JobDataContextType>({} as JobDataContextType)

type Props = {
  jobId: string
}

export const JobDataProvider: FunctionComponent<Props> = ({ jobId, children }) => {
  const {
    loading: jobLoading,
    data: jobData,
    refetch: jobRefetch_,
  } = useQuery<Pick<Query, 'job'>, QueryJobArgs>(GET_JOB_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      id: jobId,
    },
  })
  const jobRefetch = useCallback(async () => {
    await jobRefetch_()
  }, [jobRefetch_])
  const job = jobData?.job
  const filePages = useMemo(
    () => (jobData?.job?.filePages?.edges?.map((edge) => edge?.node) || []) as FilePageNode[],
    [jobData],
  )
  const documentTypes = useMemo(() => {
    const sortedDocumentTypes =
      jobData?.job?.jobTemplate?.documentTypes?.edges?.map((edge) => edge.node) ?? []

    // sort alphabetically in ascending order
    sortedDocumentTypes.sort((a, b) => {
      if (a.name === b.name) return 0
      return a.name > b.name ? 1 : -1
    })

    return sortedDocumentTypes
  }, [jobData])

  const value = useMemo(
    () => ({
      job,
      jobLoading,
      jobData,
      jobRefetch,
      filePages,
      documentTypes,
    }),
    [job, jobLoading, jobData, jobRefetch, filePages, documentTypes],
  )

  return <JobDataContext.Provider value={value}>{children}</JobDataContext.Provider>
}
