import { FunctionComponent } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import theme from '@src/utils/theme'
import { DatabaseRecordType } from './record_type'

type Props = {
  setOpen: (open: boolean) => void
  cancelCreateNewDatabase: (cancel: boolean) => void
  databaseRecordType: DatabaseRecordType
  open: boolean
}

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

const CancelCreateDatabaseModal: FunctionComponent<Props> = ({
  setOpen,
  cancelCreateNewDatabase,
  databaseRecordType,
  open,
}: Props) => {
  const classes = useStyles()

  const closeDialog = (): void => {
    setOpen(false)
  }

  const cancelCreateAndClose = (): void => {
    cancelCreateNewDatabase(true)
    closeDialog()
  }

  return (
    <Dialog classes={{ paper: classes.dialog }} open={open}>
      <Box className={classes.close}>
        <IconButton onClick={closeDialog} data-testid='close-btn'>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle>Cancel Create New {databaseRecordType}</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to cancel creation of this {databaseRecordType.toLowerCase()}? All
          changes will be lost.
        </Typography>
        <Box display='flex' justifyContent='center' p={3}>
          <Box m={3}>
            <Button onClick={closeDialog} variant='contained'>
              Back
            </Button>
          </Box>
          <Box m={3}>
            <Button onClick={cancelCreateAndClose} variant='contained' color='primary'>
              Confirm
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CancelCreateDatabaseModal
