import { FunctionComponent } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import theme from '@src/utils/theme'

type Props = {
  setOpen: (open: boolean) => void
  confirmCreate: () => void
}

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

const CreateVendorModal: FunctionComponent<Props> = ({ setOpen, confirmCreate }: Props) => {
  const classes = useStyles()

  const closeDialog = (): void => {
    setOpen(false)
  }

  return (
    <Dialog classes={{ paper: classes.dialog }} open={true}>
      <Box className={classes.close}>
        <IconButton onClick={closeDialog} data-testid='close-btn'>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle>Create New Vendor</DialogTitle>
      <DialogContent>
        <Typography>
          Creating a new vendor will remove any edits and changes made so far on the table below, do
          you want to proceed?
        </Typography>
        <Box display='flex' justifyContent='center' p={3}>
          <Box m={3}>
            <Button onClick={closeDialog} variant='contained'>
              Cancel
            </Button>
          </Box>
          <Box m={3}>
            <Button onClick={confirmCreate} variant='contained' color='primary'>
              Discard Changes and Continue
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CreateVendorModal
