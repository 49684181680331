import produce from 'immer'
import { JobNode, JobTemplateReconType } from '@src/graphql/types'
import { DocumentEditorState, getInitialState } from './document_editor_state'
import { documentTablesAdapter, initializeDocumentTables } from './document_table'
import { getInitialJobLineItemsTableState, jobTableLineItemsAdapter } from './job_line_items_table'
import { initializeJobTable } from './job_table'
import { initializePageFieldEditorState } from './page_field_editor'
import { syncTableColumns } from './utils/line_item_actions'

export const initalizeReducer = (
  outerState: DocumentEditorState,
  job: JobNode,
  activeFilePageId: string,
  enableLineItemsRowOrderPriority: boolean,
): void => {
  // to be safe, we start from the empty initial state. However, we want to be working
  // on an Immer draft (since otherwise the inner objects won't behave as expected),
  // so we perform our changes on a new draft, and assign to the old draft.
  const newState = produce(getInitialState(), (state) => {
    state.activeFilePageId = activeFilePageId
    state.job = job
    state.pageFieldEditorState = initializePageFieldEditorState(job)
    const { documentTables, lineItemsTableMap, magicGridMap } = initializeDocumentTables(
      job,
      enableLineItemsRowOrderPriority,
    )
    documentTablesAdapter.setAll(state.documentTables, documentTables)
    state.lineItemsTableMap = lineItemsTableMap
    state.magicGridMap = magicGridMap
    state.jobTableState = produce(getInitialJobLineItemsTableState(), (jobLineItemsTable) => {
      const tableShowsPreset = documentTables.some(
        (documentTable) => documentTable.document.documentType.tableShowsPreset,
      )
      const { jobTableLineItems, columns, fieldGroup } = initializeJobTable(job, tableShowsPreset)
      jobLineItemsTable.columns = columns
      jobLineItemsTable.fieldGroup = fieldGroup ?? null
      if (jobTableLineItems?.length) {
        jobTableLineItemsAdapter.setAll(jobLineItemsTable.lineItems, jobTableLineItems)
      }
    })
    if (job.jobTemplate.reconType === JobTemplateReconType.Soa || job.jobTemplate.mainTabEnabled) {
      syncTableColumns(state, documentTables, lineItemsTableMap)
    }
  })
  Object.assign(outerState, newState)
}
