import { FunctionComponent } from 'react'
import { v4 as uuid4 } from 'uuid'
import useRequireTeamLead from '@src/hooks/useRequireTeamLead'
import JobTemplateEditor from '@src/components/admin/job-template-editor/JobTemplateEditor'
import { useParams } from 'react-router-dom'

const AdminJobType: FunctionComponent = () => {
  // eslint-disable-next-line prefer-const
  let { companyId, jobTemplateId } = useParams<{ companyId: string; jobTemplateId: string }>()
  const isNewJobTemplate = jobTemplateId === 'new'
  jobTemplateId = isNewJobTemplate ? uuid4() : jobTemplateId
  const permissionsError = useRequireTeamLead()
  if (permissionsError) {
    return permissionsError
  }
  return (
    <JobTemplateEditor
      companyId={companyId}
      isNewJobTemplate={isNewJobTemplate}
      jobTemplateId={jobTemplateId}
    />
  )
}

export default AdminJobType
