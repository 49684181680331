import { FunctionComponent, useRef, useState } from 'react'
import { JOBS_FOR_EXPORT } from '@src/graphql/queries/job'
import { useSnackbar } from 'notistack'
import { JobNode, Query } from '@src/graphql/types'
import {
  bulkExportJobsToCevaCsv,
  bulkExportJobsToExcel,
  bulkExportJobsToFieldMappingCsv,
  bulkExportJobsToLineItemCsv,
  exportJobToExcel,
} from '@src/utils/export'
import { useLazyQuery } from '@apollo/client'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import GetAppIcon from '@material-ui/icons/GetApp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { makeStyles } from '@material-ui/core'
import { POPPER_Z_INDEX } from '@src/utils/app_constants'
import { formatMaybeApolloError } from '@src/utils/errors'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

export enum ExportType {
  INVIDIUAL_EXPORT = 'Individual Export',
  BULK_EXPORT_XLSX = 'Bulk Export (XLSX)',
  BULK_EXPORT_IH_CSV = 'Bulk Export (Invoice Header CSV)',
  BULK_EXPORT_ILI_CSV = 'Bulk Export (Invoice Line Item CSV)',
  BULK_EXPORT_CEVA_CSV = 'Bulk Export (CEVA CSV)',
}

type Props = {
  jobIds: string[]
  exportItemsStatus: { [key: string]: boolean }
}

const useStyles = makeStyles({
  popper: {
    zIndex: POPPER_Z_INDEX,
  },
})

const ExportButtonGroup: FunctionComponent<Props> = ({ jobIds, exportItemsStatus }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [openButton, setOpenButton] = useState(false)
  const [exportType, setExportType] = useState(ExportType.INVIDIUAL_EXPORT)
  const anchorRef = useRef(null)
  const enableLineItemsRowOrderPriority = useFeatureIsOn('line-items-row-order-priority')

  const [queryJobsExport, { loading: queryJobsExportLoading }] = useLazyQuery<Pick<Query, 'jobs'>>(
    JOBS_FOR_EXPORT,
    {
      fetchPolicy: 'network-only',
      onCompleted: async ({ jobs }) => {
        switch (exportType) {
          case ExportType.BULK_EXPORT_XLSX:
            bulkExportJobsToExcel(jobs as JobNode[], enableLineItemsRowOrderPriority)
            break
          case ExportType.BULK_EXPORT_IH_CSV:
            await bulkExportJobsToFieldMappingCsv(jobs as JobNode[])
            break
          case ExportType.BULK_EXPORT_ILI_CSV:
            await bulkExportJobsToLineItemCsv(jobs as JobNode[])
            break
          case ExportType.BULK_EXPORT_CEVA_CSV:
            await bulkExportJobsToCevaCsv(jobs as JobNode[], enableLineItemsRowOrderPriority)
            break
          default:
            jobs.forEach((job) => exportJobToExcel(job as JobNode, enableLineItemsRowOrderPriority))
            break
        }
      },
    },
  )

  const handleToggleExportButton = (): void => {
    setOpenButton((prevOpen) => !prevOpen)
  }

  const handleExport = async (currExportType: ExportType): Promise<void> => {
    setExportType(currExportType)
    try {
      await queryJobsExport({ variables: { jobIds } })
    } catch (e) {
      enqueueSnackbar(`Failed fetching jobs for export: ${formatMaybeApolloError(e)}`, {
        variant: 'error',
      })
    }
  }

  return (
    <div>
      <Button
        variant='contained'
        color='primary'
        ref={anchorRef}
        disabled={!jobIds.length || queryJobsExportLoading}
        onClick={handleToggleExportButton}
        startIcon={<GetAppIcon />}
        endIcon={<ArrowDropDownIcon />}
      >
        Export
      </Button>
      <Popper
        open={openButton}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpenButton(false)}>
                <MenuList id='split-button-menu'>
                  {Object.values(ExportType).map((type) => (
                    <MenuItem
                      key={type}
                      disabled={!exportItemsStatus[type as ExportType]}
                      onClick={() => {
                        setOpenButton(false)
                        void handleExport(type as ExportType)
                      }}
                    >
                      {type}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default ExportButtonGroup
