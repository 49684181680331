import React, { FunctionComponent, useEffect, useState } from 'react'
import { Paper, TextField, Theme, Typography, Select, MenuItem, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import theme from '@src/utils/theme'
import { SEARCH_TAXES } from '@src/graphql/queries/recon'
import { ApiPartnerNode, TaxNode, Query, QuerySearchTaxesArgs } from '@src/graphql/types'
import { useLazyQuery } from '@apollo/client'
import { ChargeCodeTax } from './types'
type Props = {
  currChargeCode?: string
  setCurrChargeCode: (name: string) => void
  setCurrTaxId: (id: string) => void
  currTax: ChargeCodeTax | null
  currTaxId: string
  setRefreshSearch: (refresh: boolean) => void
  apiPartners: ApiPartnerNode[]
  currApiPartnerId: string | null
  setCurrApiPartnerId: (apiPartnerId: string | null) => void
  currDescription?: string
  setCurrDescription?: (description: string) => void
}

// general pixel approximations from the spec
// of the cell size. Not derived from anywhere in particular.
const metadataCellWidth = `150px`
const metadataInputWidth = '300px'

const useStyles = makeStyles<Theme>({
  descriptionCellTop: {
    color: theme.palette.grey[700],
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    borderRight: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(1),
    width: metadataCellWidth,
  },
  tableCellTop: {
    color: theme.palette.grey[700],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: metadataCellWidth,
  },
  input: {
    paddingTop: theme.spacing(2),
    width: metadataInputWidth,
  },
  table: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  cwConfigSelector: {
    padding: theme.spacing(1),
    width: metadataInputWidth,
  },
  apiPartnerSelector: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    width: metadataInputWidth,
  },
})

const ChargeCodeMetadataTable: FunctionComponent<Props> = ({
  currChargeCode,
  setCurrChargeCode,
  setCurrTaxId,
  currTax,
  currTaxId,
  setRefreshSearch,
  apiPartners,
  currApiPartnerId,
  setCurrApiPartnerId,
  currDescription,
  setCurrDescription,
}) => {
  const classes = useStyles()
  const [taxes, setTaxes] = useState([] as TaxNode[])
  const updateCode = (e: React.ChangeEvent<{ value: string }>): void => {
    const value = e.target?.value ?? ''
    setCurrChargeCode(value.toUpperCase())
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateTax = (e: React.ChangeEvent<any>): void => {
    setCurrTaxId(e.target?.value ?? '')
  }
  const changeApiPartner = (e: React.ChangeEvent<Record<string, unknown>>): void => {
    const value = e.target?.value ?? ''
    const chosenApiPartner = apiPartners.find((partner) => partner.id === value)
    setCurrApiPartnerId(chosenApiPartner!.id)
    setRefreshSearch(true)
  }
  const updateDescription = (e: React.ChangeEvent<{ value: string }>): void => {
    if (setCurrDescription) {
      setCurrDescription(e.target?.value ?? '')
    }
  }

  const [searchTaxes] = useLazyQuery<Pick<Query, 'searchTaxes'>, QuerySearchTaxesArgs>(
    SEARCH_TAXES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const taxesData = data?.searchTaxes
        setTaxes(taxesData)
      },
    },
  )

  useEffect(() => {
    void searchTaxes({
      variables: {
        apiPartnerId: currApiPartnerId,
        query: '',
      },
    })
  }, [searchTaxes, currApiPartnerId])

  return (
    <Box display='flex' justifyContent='space-between'>
      <Paper className={classes.table}>
        <table>
          <tbody>
            <tr>
              <td className={classes.descriptionCellTop}>
                <Typography>Charge Code</Typography>
              </td>
              <td className={classes.tableCellTop}>
                <TextField
                  onChange={updateCode}
                  value={currChargeCode}
                  className={classes.input}
                  id='charge-code'
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
              </td>
            </tr>
            {setCurrDescription ? (
              <tr>
                <td className={classes.descriptionCellTop}>
                  <Typography>Description</Typography>
                </td>
                <td className={classes.tableCellTop}>
                  <TextField
                    onChange={updateDescription}
                    value={currDescription}
                    className={classes.input}
                    id='charge-code-description'
                  />
                </td>
              </tr>
            ) : (
              <></>
            )}
            <tr>
              <td className={classes.descriptionCellTop}>
                <Typography>Tax</Typography>
              </td>
              <td className={classes.tableCellTop}>
                <Select
                  id='charge-code-tax'
                  value={currTaxId ?? ''}
                  onChange={updateTax}
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                  fullWidth
                >
                  {currTax && currTax.dateDeleted && (
                    <MenuItem disabled key={currTax.id} value={currTax.id}>
                      {`${currTax.taxCode} (Deleted)`}
                    </MenuItem>
                  )}
                  <MenuItem value={''}>N/A</MenuItem>
                  {taxes.map((tax) => {
                    return (
                      <MenuItem key={tax.id} value={tax.id}>
                        {tax.taxCode}
                      </MenuItem>
                    )
                  })}
                </Select>
              </td>
            </tr>
          </tbody>
        </table>
      </Paper>
      <Box pt={2} pr={4}>
        <Paper className={classes.apiPartnerSelector}>
          <Typography variant='caption'>Filter by TMS</Typography>
          <Select
            value={currApiPartnerId}
            onChange={changeApiPartner}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            data-testid='api-partner-selector'
            fullWidth
          >
            {!apiPartners?.length ? (
              <MenuItem value={''}>N/A</MenuItem>
            ) : (
              apiPartners.map((partner) => {
                return (
                  <MenuItem key={partner.id} value={partner.id}>
                    {partner.name}
                  </MenuItem>
                )
              })
            )}
          </Select>
        </Paper>
      </Box>
    </Box>
  )
}

export default ChargeCodeMetadataTable
