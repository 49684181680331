import Title from '@src/components/Title'
import JobViewerWithSidebar from '@src/components/JobViewerWithSidebar'
import ConfirmationSidebar from '@src/components/confirmation-sidebar/ConfirmationSidebar'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'

const ConfirmationPage: FunctionComponent = () => {
  const { id: jobId } = useParams<{ id: string }>()
  return (
    <>
      <Title title='Confirm Job' />
      <JobViewerWithSidebar jobId={jobId}>
        <ConfirmationSidebar jobId={jobId} />
      </JobViewerWithSidebar>
    </>
  )
}

export default ConfirmationPage
