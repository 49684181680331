import { FunctionComponent, useState } from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/styles'
import { grey } from '@material-ui/core/colors'
import { CargowiseConfigNode, CwConnectorType } from '@src/graphql/types'
import { Button, CircularProgress } from '@material-ui/core'
import theme from '@src/utils/theme'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles({
  actionIcon: {
    color: grey[900],
  },
})

type Props = {
  cargowiseConfigs: CargowiseConfigNode[]
  onDelete: (id: string) => Promise<void>
  onUpdate: (credential: CargowiseConfigNode) => void
  onTest: (id: string) => Promise<void>
}

const CWConfigsTable: FunctionComponent<Props> = ({
  cargowiseConfigs,
  onDelete,
  onUpdate,
  onTest,
}) => {
  const classes = useStyles()
  const [isTesting, setIsTesting] = useState(false)

  return (
    <TableContainer component={Paper} style={{ maxHeight: '80vh' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Actions</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Extern Api Credential</TableCell>
            <TableCell>Default Timezone</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Country Code</TableCell>
            <TableCell>Enterprise ID</TableCell>
            <TableCell>Server ID</TableCell>
            <TableCell>Outbound Password</TableCell>
            <TableCell>DB Name</TableCell>
            <TableCell>DB User</TableCell>
            <TableCell>DB Password</TableCell>
            <TableCell>Remote DB Hostname</TableCell>
            <TableCell>Remote DB Port</TableCell>
            <TableCell>DB Direct Connect?</TableCell>
            <TableCell>Poll DB</TableCell>
            <TableCell>Proxy Urls</TableCell>
            <TableCell>Connector Type</TableCell>
            <TableCell>Job Templates</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cargowiseConfigs.map((cargowiseConfig) => (
            <TableRow key={cargowiseConfig.id}>
              <TableCell align='center'>
                <Box display='flex' justifyContent='center'>
                  <IconButton
                    aria-label='edit'
                    size='small'
                    className={classes.actionIcon}
                    onClick={() => onUpdate(cargowiseConfig)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label='delete'
                    size='small'
                    className={classes.actionIcon}
                    onClick={() => onDelete(cargowiseConfig.id)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                  <Button
                    size='small'
                    disabled={isTesting}
                    onClick={async () => {
                      setIsTesting(true)
                      try {
                        await onTest(cargowiseConfig.id)
                      } finally {
                        setIsTesting(false)
                      }
                    }}
                  >
                    {(isTesting && <CircularProgress size={'1em'} />) || 'Test DB'}
                  </Button>
                </Box>
              </TableCell>
              <TableCell>
                <Link
                  href={`https://app.expedock.com/admin/company/${cargowiseConfig.company?.id}`}
                >
                  {cargowiseConfig.company?.name || ''}
                </Link>
              </TableCell>
              <TableCell>
                {cargowiseConfig.externApiCredential
                  ? `${cargowiseConfig.externApiCredential.username} - ${cargowiseConfig.externApiCredential.workflow}`
                  : ''}
              </TableCell>
              <TableCell>{cargowiseConfig.defaultTimezoneLocation?.code}</TableCell>
              <TableCell>{cargowiseConfig.code}</TableCell>
              <TableCell>{cargowiseConfig.countryCode || ''}</TableCell>
              <TableCell>{cargowiseConfig.enterpriseId}</TableCell>
              <TableCell>{cargowiseConfig.serverId}</TableCell>
              <TableCell>{cargowiseConfig.outboundPass}</TableCell>
              <TableCell>{cargowiseConfig.dbName || ''}</TableCell>
              <TableCell>{cargowiseConfig.dbUser || ''}</TableCell>
              <TableCell>{cargowiseConfig.dbPass || ''}</TableCell>
              <TableCell>{cargowiseConfig.remoteDbHostname || ''}</TableCell>
              <TableCell>{cargowiseConfig.remoteDbPort || ''}</TableCell>
              <TableCell>{cargowiseConfig.dbDirectConnect ? 'Yes' : 'No'}</TableCell>
              <TableCell>
                <Switch
                  checked={cargowiseConfig.pollDatabase}
                  disabled={cargowiseConfig.connectorType !== CwConnectorType.Db}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={() => {
                    onUpdate({
                      ...cargowiseConfig,
                      pollDatabase: !cargowiseConfig.pollDatabase,
                    })
                  }}
                />
              </TableCell>
              <TableCell>{cargowiseConfig.proxyUrls?.join(', ') || ''}</TableCell>
              <TableCell>{cargowiseConfig.connectorType || ''}</TableCell>
              <TableCell
                style={{
                  display: 'inline-flex',
                  overflowY: 'auto',
                  maxHeight: theme.spacing(20),
                  minHeight: theme.spacing(12),
                  width: '100%',
                }}
              >
                {cargowiseConfig.jobTemplates?.edges
                  .map(
                    (jobTemplate) =>
                      `${jobTemplate!.node!.company!.name} - ${jobTemplate!.node!.name}`,
                  )
                  .join(', ') || ''}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CWConfigsTable
