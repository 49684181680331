import { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/styles'

import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import { EDocumentNode, EDocsDataTargetType, Maybe } from '@src/graphql/types'

import theme from '@src/utils/theme'
import SoaEDocTableRows from './SoaEDocTableRows'
import {
  SoaConsolEDocsInfo,
  SoaCustomsDecEDocsInfo,
  SoaInvoiceEDocsInfo,
  SoaShipmentEDocsInfo,
} from './BatchPullTable'
import { Paper } from '@material-ui/core'

const useStyles = makeStyles({
  table: {
    maxHeight: '60%',
    overflowY: 'scroll',
    marginBottom: theme.spacing(1),
  },
})

type Props = {
  soaEDocs:
    | SoaInvoiceEDocsInfo[]
    | SoaShipmentEDocsInfo[]
    | SoaConsolEDocsInfo[]
    | SoaCustomsDecEDocsInfo[]
  handleSelect: (
    soaGroup:
      | SoaInvoiceEDocsInfo
      | SoaShipmentEDocsInfo
      | SoaConsolEDocsInfo
      | SoaCustomsDecEDocsInfo,
    targetType: EDocsDataTargetType,
  ) => void
  selectEDocHandler: (eDocument: Maybe<EDocumentNode>) => void
  deleteEdocHandler: (eDocumentId: string) => Promise<void>
  deleteEdocLoading: boolean
  bulkDeleteEdocsLoading: boolean
  allSelected: boolean
  selected:
    | SoaInvoiceEDocsInfo[]
    | SoaShipmentEDocsInfo[]
    | SoaConsolEDocsInfo[]
    | SoaCustomsDecEDocsInfo[]
  handleSelectAll: (targetType: EDocsDataTargetType) => void
  dataTargetType: EDocsDataTargetType
}

const SoaEDocTable: FunctionComponent<Props> = ({
  soaEDocs,
  handleSelect,
  selectEDocHandler,
  deleteEdocHandler,
  deleteEdocLoading,
  bulkDeleteEdocsLoading,
  allSelected,
  selected,
  handleSelectAll,
  dataTargetType,
}) => {
  const classes = useStyles()
  let testId = ''
  if (dataTargetType === EDocsDataTargetType.Shipment) {
    testId = 'soa-shipment-edoc-table'
  } else if (dataTargetType === EDocsDataTargetType.ApInvoice) {
    testId = 'soa-invoice-edoc-table'
  } else if (dataTargetType === EDocsDataTargetType.Consol) {
    testId = 'soa-consol-edoc-table'
  } else if (dataTargetType === EDocsDataTargetType.CustomsDeclaration) {
    testId = 'soa-customs-dec-edoc-table'
  }

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table stickyHeader size='small' data-testid={testId}>
        <TableHead>
          <TableRow>
            <TableCell>
              {dataTargetType !== EDocsDataTargetType.ApInvoice ? 'Reference No.' : 'Invoice No.'}
            </TableCell>
            <TableCell>CW File Type</TableCell>
            <TableCell>File</TableCell>
            <TableCell align='center'>Is Published</TableCell>
            <TableCell align='center'>
              <Checkbox
                checked={allSelected}
                indeterminate={soaEDocs.length > selected.length && selected.length > 0}
                onClick={() => {
                  handleSelectAll(dataTargetType)
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <SoaEDocTableRows
            soaEDocs={soaEDocs}
            handleSelect={handleSelect}
            selectEDocHandler={selectEDocHandler}
            deleteEdocHandler={deleteEdocHandler}
            deleteEdocLoading={deleteEdocLoading || bulkDeleteEdocsLoading}
            dataTargetType={dataTargetType}
          />
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SoaEDocTable
