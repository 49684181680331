import { useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

type EventAttributes = {
  userAttributes: {
    auth0Id: string | undefined | null
    email: string | undefined | null
  }
  loading: boolean
}

export const useEventAttributes = (): EventAttributes => {
  const { user, isLoading } = useAuth0()
  const userAttributes = useMemo(() => {
    // Here we store the attributes we want to identify *users* by (user-specific)
    // e.g. we can identify a user by their id, name, email, and company attributes (one user has one email, one company, etc)
    // These aren't likely to change within a session, and even if they do change,
    // we'd want all of the sessions to have the updated user attributes
    return {
      auth0Id: user?.sub,
      email: user?.email,
    }
  }, [user])
  return {
    userAttributes,
    loading: isLoading,
  }
}
