import { formatMaybeApolloError } from '@src/utils/errors'
import { useLazyQuery } from '@apollo/client'
import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import { FC, FunctionComponent, useEffect, useMemo, useState } from 'react'
import { RECON_ATTEMPTS_BY_BATCH } from '@src/graphql/queries/recon'
import {
  Query,
  ReconAsyncStatus,
  ReconAttemptNode,
  JobTemplateReconType,
  ReconResultType,
  useSaveReconAttemptsByBatchMutation,
  useReconAsyncBatchQuery,
  JobExternalStatus,
  UpdateRisrAndRmcMutationVariables,
  useUpdateRisrAndRmcMutation,
  ReconInvoiceShipmentReferenceNode,
  ReconMatchCriteriaNode,
  InputReconInfoObject,
  useJobNotesLazyQuery,
} from '@src/graphql/types'
import { COLORS, RECON_MODAL_STYLES } from '@src/utils/app_constants'
import { parseDateString } from '@src/utils/date'
import { SoaRowFieldMapping } from '@src/components/data-grid/util'
import BulkReconDialogRow from '@src/components/reconciliation-dialog/bulk-recon-dialog/BulkReconDialogRow'
import {
  SoaInvoiceReconResultNode,
  getSOAMetadataTable,
  orderSoaReconAttempts,
} from '@src/utils/recon/recon'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { HotTableData, RECON_METADATA_DATA_TABLE_COLUMNS } from '@src/utils/recon/ap_recon'
import { clsx } from 'clsx'
import theme from '@src/utils/theme'
import { isFallback } from '@src/utils/enum'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useForm, FormProvider } from 'react-hook-form'
import { ExternalAssigneeOption } from '@src/components/job-viewer/ExternalAssigneeSelector'
import { LogEventType } from '@src/utils/observability/LogEventType'
import { reportRollbarError } from '@src/utils/observability/rollbar'

const useStyles = makeStyles({
  dialog: {
    // don't block handsontable comments
    // important because z-index 1300 is inline
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zIndex: `${RECON_MODAL_STYLES.Z_INDEX} !important` as any,
  },
  dialogFullScreen: {
    maxWidth: '90%',
    width: '90%',
    height: '90%',
  },
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.75, 1),
  },
  error: {
    color: theme.palette.error.main,
    background: COLORS.PALE_RED,
    boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
  },
})

const reconAsyncBatchPollIntervalMs = 1000
const etaRefreshIntervalMs = 200

export type UpdateRISROrRMSFormSchema = {
  input: Record<
    string,
    {
      input: {
        id: string
        externalAssignee: ExternalAssigneeOption | null
        externalStatus: JobExternalStatus
      }
    }
  >
}

const transformRisrOrRmcPayload = (
  formValues: UpdateRISROrRMSFormSchema,
  jobId: string,
): UpdateRisrAndRmcMutationVariables => {
  const payload: {
    inputReconInfoObjects: InputReconInfoObject[]
    jobId: string
  } = {
    jobId,
    inputReconInfoObjects: [],
  }

  Object.values(formValues.input).forEach((item) => {
    payload.inputReconInfoObjects.push({
      id: item.input.id,
      externalStatus: item.input.externalStatus,
      externalAssigneeId: item.input.externalAssignee?.id ?? null,
    })
  })

  return payload
}

const matchStatusKeywords = ['matched', 'match', 'matched within threshold']

/*
 * Default value of external status as defined in this ticket:
 * https://expedock.atlassian.net/browse/PD-8002
 */
const determineDefaultExternalStatus = (reconAttempt: ReconAttemptNode): JobExternalStatus => {
  if (reconAttempt.matchReconInvoiceShipmentReference?.externalStatus) {
    return reconAttempt.matchReconInvoiceShipmentReference.externalStatus
  }

  if (reconAttempt.noMatchReconMatchCriteria?.externalStatus) {
    return reconAttempt.noMatchReconMatchCriteria.externalStatus
  }

  if (reconAttempt.expedockAction?.toLowerCase().includes('posted')) {
    return JobExternalStatus.Done
  }

  const errorNotes = reconAttempt.errorNotes?.toLowerCase() ?? ''
  if (matchStatusKeywords.includes(errorNotes)) {
    return JobExternalStatus.Done
  }

  return JobExternalStatus.Todo
}

const createFormInput = (
  reference: ReconInvoiceShipmentReferenceNode | ReconMatchCriteriaNode,
  defaultExternalStatus: JobExternalStatus,
  defaultExternalAssignee: ExternalAssigneeOption | null,
): UpdateRISROrRMSFormSchema['input'][string] => {
  return {
    input: {
      id: reference.id,
      // override external assignee/external status if present
      // otherwise, use default values
      externalAssignee: reference.isModified
        ? reference.externalAssignee ?? null
        : defaultExternalAssignee,
      externalStatus: reference.externalStatus ?? defaultExternalStatus,
    },
  }
}

type Props = {
  isOpen: boolean
  jobId: string
  closePopup: () => void
  goBackToSoaOptionsDialog: () => void
  reconAsyncBatchId: string
  setSelectedReconAttempt: (reconAttempt: null | ReconAttemptNode) => void
  getSoaRowFieldMappings: () => SoaRowFieldMapping[]
}

const NO_TABLE_ROWS_LOADING = 6
const ROW_HEIGHT = 80

const BulkReconDialog: FunctionComponent<Props> = ({
  isOpen,
  jobId,
  closePopup,
  goBackToSoaOptionsDialog,
  setSelectedReconAttempt,
  reconAsyncBatchId,
  getSoaRowFieldMappings,
}) => {
  const enableInvoiceReferenceExternalAssigneeAndStatus = useFeatureIsOn(
    'invoice-reference-external-assignee-and-status',
  )
  const enableReconHistory = useFeatureIsOn('recon-history')

  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [fetchReconAsyncBatch, setFetchReconAsyncBatch] = useState(true)
  const [elapsedTime, setElapsedTime] = useState(0)
  // 1000 seconds is a dummy value to display in the very short interval between
  // recon async batch load and the useEffect settings the ETA. Also, avoids division by zero.
  const [eta, setEta] = useState(1000)
  const [elapsedTimeIntervalId, setElapsedTimeIntervalId] = useState<number | null>(null)
  const formMethods = useForm<UpdateRISROrRMSFormSchema>({
    defaultValues: { input: {} },
  })
  const [getReconAttempts, { loading: reconAttemptsLoading, data: reconAttemptsData }] =
    useLazyQuery<Pick<Query, 'reconAttempts'>>(RECON_ATTEMPTS_BY_BATCH, {
      fetchPolicy: 'network-only',
      onError: (e) => {
        enqueueSnackbar(formatMaybeApolloError(e), { variant: 'error' })
        stopReconAsyncBatchPolling()
      },
      onCompleted: (data) => {
        const formDefaultValues: UpdateRISROrRMSFormSchema = { input: {} }

        data.reconAttempts.forEach((reconAttempt) => {
          if (reconAttempt.isSoaMetadataAttempt) return

          const defaultExternalStatus = determineDefaultExternalStatus(reconAttempt)
          const defaultExternalAssignee =
            reconAttempt.job?.jobTemplate.defaultExternalAssignee ?? null

          if (
            reconAttempt.matchReconInvoiceShipmentReference ||
            reconAttempt.noMatchReconMatchCriteria
          ) {
            formDefaultValues.input[reconAttempt.id] = createFormInput(
              reconAttempt.matchReconInvoiceShipmentReference ??
                // we assert as non-null because we've already checked above
                reconAttempt.noMatchReconMatchCriteria!,
              defaultExternalStatus,
              defaultExternalAssignee,
            )
          }
        })

        formMethods.reset(formDefaultValues)
      },
    })

  const [saveReconAttemptsByBatch, { loading: saveReconAttemptsLoading }] =
    useSaveReconAttemptsByBatchMutation({
      onError(e) {
        reportRollbarError(e.message)
      },
    })

  const [updateRisrsOrRmcs, { loading: updateRisrsOrRmcsLoading }] = useUpdateRisrAndRmcMutation({
    onError(e) {
      const msg =
        'Failed to update the Invoice<>Reference pair. The Expedock team has been alerted and is working on the issue.'
      enqueueSnackbar(msg, {
        variant: 'error',
      })
      reportRollbarError(e.message)
    },
  })

  const [_, { refetch: refetchJobNotes }] = useJobNotesLazyQuery({ variables: { jobId } })

  const {
    data: reconAsyncBatchData,
    startPolling: startReconAsyncBatchPolling,
    stopPolling: stopReconAsyncBatchPolling,
  } = useReconAsyncBatchQuery({
    variables: { reconAsyncBatchId },
  })

  const reconAttempts = reconAttemptsData?.reconAttempts || []
  const metadataReconAttempts = reconAttempts.filter((reconAttempt) => {
    return reconAttempt.reconResults.some(
      (reconResult) =>
        !isFallback(reconResult.type) &&
        reconResult.type?.value === ReconResultType.SoaTotalReconResult,
    )
  })
  const lineItemReconAttempts = reconAttempts.filter(({ id }) => {
    return !metadataReconAttempts.some((reconAttempt) => reconAttempt.id === id)
  })
  const soaMetadataTable = getSOAMetadataTable(
    metadataReconAttempts.flatMap((reconAttempt) => {
      return reconAttempt.reconResults
    }) as SoaInvoiceReconResultNode[],
  )
  const orderedReconAttempts: ReconAttemptNode[] = useMemo(() => {
    return orderSoaReconAttempts(getSoaRowFieldMappings(), lineItemReconAttempts)
  }, [getSoaRowFieldMappings, lineItemReconAttempts])

  const { logEvent } = useEventLogger()

  const handleSaveReconAttempts = async (): Promise<void> => {
    let success = false
    let errorMessage = ''
    try {
      await saveReconAttemptsByBatch({
        variables: { reconAsyncBatchId: reconAsyncBatchId },
      })
      enqueueSnackbar('Saved recon attempts in batch', { variant: 'success' })
      success = true
    } catch (error) {
      errorMessage = `Failed to save recon attempts: ${formatMaybeApolloError(error)}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
    } finally {
      void logEvent(LogEventType.RECONCILE_SHOW, {
        job_id: jobId,
        recon_async_batch_id: reconAsyncBatchId,
        recon_type: JobTemplateReconType.Soa,
        success: success,
        error_message: errorMessage,
      })
    }
  }

  const handleUpdateRisrsOrRmcs = async (values: UpdateRISROrRMSFormSchema): Promise<void> => {
    const payload = transformRisrOrRmcPayload(values, jobId)
    await updateRisrsOrRmcs({
      variables: payload,
    })
  }

  const handleSaveJobDetails = async (): Promise<void> => {
    await formMethods.handleSubmit(async (values) => {
      await handleUpdateRisrsOrRmcs(values)
      await refetchJobNotes()
      enqueueSnackbar('Successfully saved job details', { variant: 'success' })
      closePopup()
    })()
  }

  const handleShowCustomer = async (): Promise<void> => {
    if (!enableInvoiceReferenceExternalAssigneeAndStatus) {
      await handleSaveReconAttempts()
      await refetchJobNotes()
      closePopup()
    } else {
      /*
       * We programatically submit the form through the onClick handler instead of
       * using the onSubmit prop so that we can prevent form submit propagation, in
       * the case when this component is rendered under ShipmentActions.tsx
       */
      await formMethods.handleSubmit(async (values) => {
        await Promise.allSettled([handleUpdateRisrsOrRmcs(values), handleSaveReconAttempts()])
        await refetchJobNotes()
        closePopup()
      })()
    }
  }

  useEffect(() => {
    if (formMethods.formState.isSubmitted && !!formMethods.formState.errors) {
      enqueueSnackbar(
        'There is an error in the fields of one or more recon attempt/s. Please check if all fields are correct and filled.',
        { variant: 'error' },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMethods.formState.submitCount])

  useEffect(() => {
    const status = reconAsyncBatchData
      ? isFallback(reconAsyncBatchData.reconAsyncBatch.status)
        ? reconAsyncBatchData.reconAsyncBatch.status
        : reconAsyncBatchData.reconAsyncBatch.status.value
      : null
    if (reconAsyncBatchData && status === ReconAsyncStatus.Pending) {
      if (elapsedTimeIntervalId != null) {
        window.clearInterval(elapsedTimeIntervalId)
      }
      setElapsedTimeIntervalId(
        window.setInterval(
          () =>
            setElapsedTime(
              (new Date().getTime() -
                parseDateString(reconAsyncBatchData.reconAsyncBatch.dateCreated).getTime()) /
                1000,
            ),
          etaRefreshIntervalMs,
        ),
      )
      // buffer to account for network overhead and latency
      const minEstimateSeconds = 10
      // it takes around 30 seconds to do 240 line items
      const secondsPerRow = 30 / 240
      setEta(minEstimateSeconds + secondsPerRow * reconAsyncBatchData?.reconAsyncBatch.numTasks)
    }
    return () => {
      if (elapsedTimeIntervalId != null) {
        window.clearInterval(elapsedTimeIntervalId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reconAsyncBatchData?.reconAsyncBatch.dateCreated,
    reconAsyncBatchData?.reconAsyncBatch.numTasks,
    reconAsyncBatchData?.reconAsyncBatch.status,
  ])

  useEffect(() => {
    const status = reconAsyncBatchData?.reconAsyncBatch.status
    if (!status) {
      return
    }
    // should generally not happen because we shouldn't be able to access a recon async batch
    // that we didn't start, so the recon async batch status should always be within the current enum types
    // but just in case
    if (isFallback(status)) {
      setFetchReconAsyncBatch(false)
      stopReconAsyncBatchPolling()
      if (elapsedTimeIntervalId != null) {
        window.clearInterval(elapsedTimeIntervalId)
      }
      setElapsedTimeIntervalId(null)
      enqueueSnackbar(
        `An error occurred while reconciling: Recon Async Batch Status not supported <${status.fallbackValue}>`,
        {
          variant: 'error',
        },
      )
    } else if (status.value === ReconAsyncStatus.Done) {
      setFetchReconAsyncBatch(false)
      stopReconAsyncBatchPolling()
      if (elapsedTimeIntervalId != null) {
        window.clearInterval(elapsedTimeIntervalId)
      }
      setElapsedTimeIntervalId(null)
      void getReconAttempts({ variables: { reconAsyncBatchId } })
    } else if (status.value === ReconAsyncStatus.Error) {
      setFetchReconAsyncBatch(false)
      stopReconAsyncBatchPolling()
      if (elapsedTimeIntervalId != null) {
        window.clearInterval(elapsedTimeIntervalId)
      }
      setElapsedTimeIntervalId(null)
      enqueueSnackbar(
        `An error occurred while reconciling: ${reconAsyncBatchData?.reconAsyncBatch.errorMessage}`,
        {
          variant: 'error',
        },
      )
    } else {
      startReconAsyncBatchPolling(reconAsyncBatchPollIntervalMs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enqueueSnackbar,
    getReconAttempts,
    reconAsyncBatchData?.reconAsyncBatch.status,
    reconAsyncBatchId,
    startReconAsyncBatchPolling,
    stopReconAsyncBatchPolling,
  ])
  // use tanh(2x) to make progress bar more smooth
  const progressFraction = Math.tanh(2 * (Math.min(elapsedTime, eta) / eta))

  const allLoading =
    reconAttemptsLoading ||
    fetchReconAsyncBatch ||
    // we add the form values here to make sure the form is populated before we render it
    (enableInvoiceReferenceExternalAssigneeAndStatus &&
      Object.keys(formMethods.watch('input')).length === 0)

  return (
    <Dialog
      className={classes.dialog}
      classes={{ paper: classes.dialogFullScreen }}
      open={isOpen}
      onClose={closePopup}
    >
      <DialogTitle disableTypography>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h3'>Results</Typography>
          <IconButton
            onClick={closePopup}
            data-testid='close-button'
            aria-label='close'
            disabled={allLoading}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {enableReconHistory && (
          <Box>
            <Button
              variant='contained'
              disableElevation
              href={`/recon-history/${jobId}`}
              target='_blank'
            >
              Reconciliation History
            </Button>
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        {allLoading ? (
          <Box data-testid='loading'>
            {new Array(NO_TABLE_ROWS_LOADING).fill('').map((_, idx) => (
              <Skeleton height={ROW_HEIGHT} key={idx} />
            ))}
            {reconAsyncBatchData?.reconAsyncBatch && (
              <>
                <Box display='flex' justifyContent='space-between'>
                  <Typography>Reconciling line items...</Typography>
                  <Typography>Time Elapsed: {Math.floor(elapsedTime)}s</Typography>
                  <Typography>
                    Estimated Remaining Time:{' '}
                    {Math.max(Math.floor(eta * (1 - progressFraction)), 1)}s
                  </Typography>
                </Box>
                <LinearProgress variant='determinate' value={progressFraction * 100} />
              </>
            )}
          </Box>
        ) : (
          <FormProvider {...formMethods}>
            <form>
              <Box mb={2}>
                <Typography variant='subtitle1' gutterBottom>
                  Metadata Reconciliation:
                </Typography>
                <ReconMetadataTable soaMetadataTable={soaMetadataTable} />
              </Box>
              <TableContainer component={Paper}>
                <Table aria-label='collapsible table'>
                  <ReconTableHeader />
                  <TableBody>
                    {orderedReconAttempts.map((reconAttempt) => (
                      <BulkReconDialogRow
                        key={reconAttempt.id}
                        reconAttempt={reconAttempt}
                        onClick={() => setSelectedReconAttempt(reconAttempt)}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '1rem',
                  padding: '1rem',
                }}
              >
                <Button
                  variant='contained'
                  onClick={goBackToSoaOptionsDialog}
                  data-testid='back-button'
                >
                  Go Back
                </Button>
                {enableInvoiceReferenceExternalAssigneeAndStatus && (
                  <Button
                    variant='contained'
                    disabled={updateRisrsOrRmcsLoading}
                    onClick={handleSaveJobDetails}
                    type='button'
                  >
                    Save Job Details
                  </Button>
                )}
                <Button
                  variant='contained'
                  disabled={
                    saveReconAttemptsLoading &&
                    (enableInvoiceReferenceExternalAssigneeAndStatus
                      ? updateRisrsOrRmcsLoading
                      : false)
                  }
                  onClick={handleShowCustomer}
                  color='primary'
                  data-testid='show-customer-button'
                >
                  Show Customer
                </Button>
              </Box>
            </form>
          </FormProvider>
        )}
      </DialogContent>
    </Dialog>
  )
}

export const ReconTableHeader: FC<{ includeExternalDetails?: boolean }> = ({
  includeExternalDetails = true,
}) => {
  const enableInvoiceReferenceExternalAssigneeAndStatus = useFeatureIsOn(
    'invoice-reference-external-assignee-and-status',
  )

  return (
    <TableHead>
      <TableRow>
        <TableCell>Invoice Number</TableCell>
        <TableCell>Reference Number</TableCell>
        <TableCell>Secondary Keys</TableCell>
        <TableCell>Discrepancy Status</TableCell>
        <TableCell>Total Amount</TableCell>
        <TableCell>Expected Amount</TableCell>
        <TableCell>Delta</TableCell>
        <TableCell>Threshold</TableCell>
        <TableCell>Days Past Due</TableCell>
        {includeExternalDetails && enableInvoiceReferenceExternalAssigneeAndStatus && (
          <>
            <TableCell>External Status</TableCell>
            <TableCell>External Assignee</TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  )
}

export const ReconMetadataTable: FC<{
  soaMetadataTable: HotTableData
}> = ({ soaMetadataTable }) => {
  const classes = useStyles()

  return (
    <TableContainer>
      <Table size='small'>
        <TableBody>
          <TableRow>
            {RECON_METADATA_DATA_TABLE_COLUMNS.map((col, colIdx) => (
              <TableCell
                className={clsx(classes.cell, classes.colCell)}
                align='center'
                key={colIdx}
              >
                {col}
              </TableCell>
            ))}
          </TableRow>
          {soaMetadataTable.data.map((row, rowIdx) => (
            <TableRow key={rowIdx}>
              {row.map((data, colIdx) => (
                <TableCell
                  data-testid={rowIdx === 0 ? RECON_METADATA_DATA_TABLE_COLUMNS[colIdx] : ''}
                  className={clsx(classes.cell, {
                    [classes.error]: soaMetadataTable.errorCells![rowIdx]?.includes(colIdx),
                  })}
                  component='th'
                  scope='row'
                  key={colIdx}
                >
                  {data}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BulkReconDialog
