import Papa from 'papaparse'

/**
 * Given the text of a CSV file as a string, return an array of arrays of its cells
 */
export const parseCsvString = (val: string): Promise<string[][]> => {
  return new Promise((resolve, reject) => {
    const results = Papa.parse(val)
    if (results.errors.length > 0) {
      reject(results.errors)
    }
    return resolve(results.data as string[][])
  })
}
