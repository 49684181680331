import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useEffect, useState } from 'react'
import { useDialog } from 'muibox'
import { Breadcrumbs, Link, TextField } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { Link as RouterLink } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'

export type AdminBreadcrumb = {
  path: string
  name: string
}

type Props = {
  crumbs: AdminBreadcrumb[]
  // e.g. CEVA Logistics
  entityName: string
  // e.g. company
  entityType: string
  // if undefined, this is read-only (can't be edited)
  onChangeName: undefined | ((newEntityName: string) => Promise<void>)
}

const useStyles = makeStyles({
  breadcrumbs: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
})

const AdminBreadcrumbs: FunctionComponent<Props> = ({
  crumbs,
  entityName: initialEntityName,
  entityType,
  onChangeName,
}) => {
  const classes = useStyles()
  const [entityName, setEntityName] = useState(initialEntityName)
  const dialog = useDialog()
  const { enqueueSnackbar } = useSnackbar()
  const [isChanging, setIsChanging] = useState(false)
  useEffect(() => {
    setEntityName(initialEntityName)
  }, [initialEntityName])
  const handleClickAway = async (): Promise<void> => {
    if (entityName !== initialEntityName && !isChanging) {
      setIsChanging(true)
      try {
        await dialog.confirm({
          title: `Change ${entityType} name`,
          message: `Are you sure you want to change the ${entityType} name from ${initialEntityName} to ${entityName}?`,
          ok: {
            text: 'Change',
            color: 'primary',
          },
        })
      } catch {
        setIsChanging(false)
        setEntityName(initialEntityName)
        return
      }
      try {
        await onChangeName!(entityName)
      } catch (error) {
        enqueueSnackbar(`Error while changing name: ${formatMaybeApolloError(error)}`, {
          variant: 'error',
        })
        setEntityName(initialEntityName)
      } finally {
        setIsChanging(false)
      }
    }
  }
  return (
    <Breadcrumbs className={classes.breadcrumbs}>
      {crumbs.map(({ path, name }) => (
        <Link component={RouterLink} to={path} key={name}>
          {name}
        </Link>
      ))}
      {onChangeName ? (
        <ClickAwayListener onClickAway={handleClickAway}>
          <TextField
            value={entityName}
            variant='outlined'
            size='small'
            onChange={(e) => setEntityName(e.target.value)}
          />
        </ClickAwayListener>
      ) : (
        entityName
      )}
    </Breadcrumbs>
  )
}

export default AdminBreadcrumbs
