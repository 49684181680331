import { formatMaybeApolloError } from '@src/utils/errors'
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react'
import { Box, Paper, TextField, Theme, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/core/styles'
import theme from '@src/utils/theme'
import { useSnackbar } from 'notistack'
import { useChargeVendorsQuery } from '@src/graphql/types'
import { ChargeVendorsSearchResult, SelectedChargeVendor } from './types'

type Props = {
  vendor: SelectedChargeVendor | undefined
  setVendor: (vendor: SelectedChargeVendor) => void
  companyId: string
  refreshSearch: boolean
  setRefreshSearch: (refreshSearch: boolean) => void
  setVendorChargeCodePage: (page: number) => void
}

const useStyles = makeStyles<Theme>({
  searchBar: {
    display: 'block',
    flex: '1',
    padding: theme.spacing(2),
  },
  searchResult: {
    padding: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  maxResultsError: {
    color: theme.palette.error.main,
    padding: theme.spacing(1),
  },
})

// so ops doesn't scroll through 10k vendors
const MAX_VENDOR_ADMIN_SEARCH_RESULTS = 50

const VendorSearchBar: FunctionComponent<Props> = ({
  vendor,
  setVendor,
  companyId,
  refreshSearch,
  setRefreshSearch,
  setVendorChargeCodePage,
}) => {
  const classes = useStyles()
  const [searchResults, setSearchResults] = useState<ChargeVendorsSearchResult>([])
  const [searchValue, setSearchValue] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const searchForVendors = (e: ChangeEvent<{ value: string }>): void => {
    const query = e?.target?.value ?? ''
    setSearchValue(query)
    void search({ query, companyId })
  }

  const { refetch: search } = useChargeVendorsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      query: searchValue,
      companyId,
    },
    onCompleted: (data) => {
      if (data.chargeVendors !== null) {
        if (vendor) {
          const updatedVendor = data!.chargeVendors!.find(
            (newVendor) => newVendor.code === vendor.code,
          )
          if (updatedVendor) {
            setVendor(updatedVendor)
          }
        }
        setSearchResults(data.chargeVendors)
      }
    },
    onError: (error) => {
      enqueueSnackbar(`Error searching charge vendors: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  useEffect(() => {
    if (refreshSearch) {
      void search({ query: searchValue, companyId })
      setRefreshSearch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSearch])

  return (
    <div data-testid='vendor-search-bar'>
      <TextField
        variant='outlined'
        fullWidth
        className={classes.searchBar}
        value={searchValue}
        onChange={searchForVendors}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {searchResults.length >= MAX_VENDOR_ADMIN_SEARCH_RESULTS && (
        <Typography className={classes.maxResultsError}>
          Only showing the first {searchResults.length} results. Filter by name to show the rest.
        </Typography>
      )}
      <Box
        maxHeight={`${theme.spacing(80)}px`}
        overflow='scroll'
        data-testid='vendor-search-bar-results'
      >
        {searchResults.map((sr) => (
          <Paper
            key={sr.id}
            className={classes.searchResult}
            onClick={() => {
              setVendor(sr)
              setVendorChargeCodePage(0)
            }}
          >
            <Typography> {sr.name} </Typography>
          </Paper>
        ))}
      </Box>
    </div>
  )
}

export default VendorSearchBar
