import { ChangeEvent, FunctionComponent, useRef } from 'react'
import { ReactEditor, useSlate } from 'slate-react'
import Box from '@material-ui/core/Box'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { makeStyles } from '@material-ui/core'
import { ReactComponent as BoldIcon } from '@src/svg/bold.svg'
import { ReactComponent as ItalicIcon } from '@src/svg/italic.svg'
import { ReactComponent as UnderlineIcon } from '@src/svg/underline.svg'
import { ReactComponent as ImageIcon } from '@src/svg/image.svg'
import { ReactComponent as AttachmentIcon } from '@src/svg/attachment.svg'

import { CustomSlateEditor, SlateMark } from '@src/hooks/useSlateEditor'

const useStyles = makeStyles({
  toggleButton: {
    border: 'none',
  },
  hiddenFileInput: {
    display: 'none',
  },
})

const SlateToolbar: FunctionComponent = () => {
  const classes = useStyles()
  const editor = useSlate() as CustomSlateEditor
  const imageInputRef = useRef(null as HTMLInputElement | null)
  const fileInputRef = useRef(null as HTMLInputElement | null)

  const fileSelectHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const [file] = (event?.target?.files ?? []) as FileList
    if (file) {
      editor.uploadAndInsertFile(file)
      // file input onChange wont fire if user selects the same file
      // unless we reset the input to blank
      if (file.type.includes('image')) {
        imageInputRef.current!.value = ''
      } else {
        fileInputRef.current!.value = ''
      }
    }
  }

  return (
    <Box>
      <ToggleButtonGroup size='small'>
        <ToggleButton
          className={classes.toggleButton}
          value={SlateMark.BOLD}
          selected={editor.isMarkActive(SlateMark.BOLD)}
          onClick={() => editor.toggleMark(SlateMark.BOLD)}
        >
          <BoldIcon />
        </ToggleButton>
        <ToggleButton
          className={classes.toggleButton}
          value={SlateMark.ITALIC}
          selected={editor.isMarkActive(SlateMark.ITALIC)}
          onClick={() => editor.toggleMark(SlateMark.ITALIC)}
        >
          <ItalicIcon />
        </ToggleButton>
        <ToggleButton
          className={classes.toggleButton}
          value={SlateMark.UNDERLINED}
          selected={editor.isMarkActive(SlateMark.UNDERLINED)}
          onClick={() => editor.toggleMark(SlateMark.UNDERLINED)}
        >
          <UnderlineIcon />
        </ToggleButton>
        <ToggleButton
          className={classes.toggleButton}
          value='image'
          onClick={() => {
            ReactEditor.deselect(editor)
            imageInputRef?.current?.click()
          }}
          disabled={editor.isUploadingImage()}
        >
          <input
            accept='image/*'
            className={classes.hiddenFileInput}
            type='file'
            onChange={fileSelectHandler}
            ref={imageInputRef}
          />
          <ImageIcon />
        </ToggleButton>
        <ToggleButton
          className={classes.toggleButton}
          value='file'
          onClick={() => {
            ReactEditor.deselect(editor)
            fileInputRef?.current?.click()
          }}
          disabled={editor.isUploadingFile()}
        >
          <input
            accept='application/*,text/*,.eml'
            className={classes.hiddenFileInput}
            type='file'
            onChange={fileSelectHandler}
            ref={fileInputRef}
          />
          {/* #94A3B8 grey 400 from the Dashboard theme (updated Design System) */}
          <AttachmentIcon color='#94A3B8' />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default SlateToolbar
