import { Box, TextField, ClickAwayListener } from '@material-ui/core'
import { SwapVert } from '@material-ui/icons'
import { Autocomplete, ToggleButton } from '@material-ui/lab'
import { DocumentTypeNode, Maybe } from '@src/graphql/types'
import { type FC, useState, useMemo } from 'react'
import { Order } from '../util'
import theme from '@src/utils/theme'
import { useDeepCompareEffect } from 'react-use'

type DocumentTypeAutocompleteProps = {
  documentTypes?: Pick<DocumentTypeNode, 'id' | 'name'>[]
  documentTypeId?: Maybe<string>
  dataTestId?: string
  onChangeHandler: (id: string) => void
}

const DocumentTypeAutocomplete: FC<DocumentTypeAutocompleteProps> = ({
  documentTypes,
  documentTypeId,
  dataTestId,
  onChangeHandler,
}) => {
  /*
   * State is needed for 2 things:
   * 1. To display a default value in the autocomplete
   * 2. To display all options in the dropdown even after an option is selected
   *    (without a state, we'd only display the selected option)
   */
  const [selectedDocumentType, setSelectedDocumentType] = useState<{
    name: string
    id: string
  } | null>(null)

  const [sortOrder, setSortOrder] = useState<Order>(Order.Ascending)

  const [open, setOpen] = useState(false)
  /*
   * Hover state is necessary because without this, when autocomplete popper is
   * open and we try to click the sort button, the popper will close
   * and open again after a few milliseconds, which is not ideal UX
   */
  const [sortButtonHovered, setSortButtonHovered] = useState(false)
  const closePopper = (): void => {
    if (!sortButtonHovered) setOpen(false)
  }
  const openPopper = (): void => setOpen(true)

  /*
   * We use useDeepCompareEffect instead of a regular useEffect to
   * use deep comparison on its dependencies instead of reference equality.
   * This is done for documentTypes since it is an array, and we don't want
   * the selected document type to potentially change if the values
   * in the array does not change.
   */
  useDeepCompareEffect(() => {
    if (documentTypeId && documentTypes) {
      const selectedDocumentType = documentTypes.find((option) => option.id === documentTypeId)
      if (selectedDocumentType) {
        setSelectedDocumentType(selectedDocumentType)
      }
    }
  }, [documentTypeId, documentTypes])

  const options = useMemo(() => {
    const sortedDocumentTypes =
      documentTypes?.map((documentType) => ({
        name: documentType.name,
        id: documentType.id,
      })) ?? []
    if (sortOrder === Order.Ascending) {
      return sortedDocumentTypes
    } else {
      return sortedDocumentTypes.reverse()
    }
  }, [documentTypes, sortOrder])

  return (
    <ClickAwayListener onClickAway={closePopper}>
      <Box
        style={{
          gap: '1rem',
          width: '100%',
          display: 'flex',
        }}
      >
        <Autocomplete
          value={selectedDocumentType}
          renderInput={(params) => (
            <TextField {...params} label='Document type' variant='outlined' />
          )}
          options={options}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(_event, optionValue) => {
            setSelectedDocumentType(optionValue)
            if (optionValue) onChangeHandler(optionValue.id)
          }}
          disableClearable={selectedDocumentType !== null}
          open={open}
          onOpen={openPopper}
          onClose={closePopper}
          data-testid={dataTestId}
          style={{
            width: '100%',
            backgroundColor: 'white',
            borderRadius: '4px',
          }}
          filterOptions={(options, { inputValue }) =>
            options.filter(({ name }) =>
              name.toLowerCase().includes(inputValue.toLowerCase().trim()),
            )
          }
        />
        <ToggleButton
          value={sortOrder}
          onChange={() => {
            setSortOrder(sortOrder === Order.Ascending ? Order.Descending : Order.Ascending)
            setOpen(true)
          }}
          onMouseEnter={() => setSortButtonHovered(true)}
          onMouseLeave={() => setSortButtonHovered(false)}
          data-testid='sort-button'
          style={{
            width: '60px',
            backgroundColor: sortOrder === Order.Ascending ? 'white' : theme.palette.grey[300],
            borderRadius: '4px',
          }}
        >
          <SwapVert
            style={{
              transition: 'all 0.2s linear',
              transform: sortOrder === Order.Ascending ? 'rotate(180deg)' : 'rotate(0)',
            }}
          />
        </ToggleButton>
      </Box>
    </ClickAwayListener>
  )
}

export default DocumentTypeAutocomplete
