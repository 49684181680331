import { DatabaseRecord } from './record_type'

export type MetadataFormOption = {
  display: string
  value: string
}

export type MetadataFormField = {
  fieldName: keyof DatabaseRecord
  displayName: string
  fieldValue: string
  setFieldValue: (newValue: string) => string
  options?: MetadataFormOption[]
  disabled?: boolean
  required?: boolean
}

export const validateMetadataFormFields = (metadataFormFields: MetadataFormField[]): boolean => {
  return metadataFormFields.every((formField) => !formField.required || formField.fieldValue)
}
