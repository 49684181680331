import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState } from 'react'
import { clsx } from 'clsx'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { DialogActions, Grid, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { COLORS } from '@src/utils/app_constants'
import {
  ApInvoiceReconResultNode,
  formatCwTargetModule,
  getAPAssociatedKeysTable,
  getAPMetadataTable,
  getCargowiseModuleFromReconAttempt,
} from '@src/utils/recon/ap_recon'
import { CargowiseOpsType } from '@src/utils/cargowise/types'
import {
  CwTargetModule,
  JobTemplateReconType,
  ReconAttemptNode,
  ReconcileApInvoiceJob,
  ReconResultType,
  useEditJobMutation,
  UserNode,
} from '@src/graphql/types'
import { SAVE_AP_RECON_ATTEMPT } from '@src/graphql/mutations/recon'
import { JOB_NOTES } from '@src/graphql/queries/note'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { APInvoiceReconMetadataTable } from './APInvoiceReconMetadataTable'
import APInvoiceReconResultTables from './APInvoiceReconResultTables'
import {
  getReconResultsDataModifiedDate,
  millisecondsInMinute,
  parseDateString,
} from '@src/utils/date'
import { isFallback } from '@src/utils/enum'
import { LogEventType } from '@src/utils/observability/LogEventType'
import { GET_JOB_DATA_LITE, GET_JOB_FOR_TMS } from '@src/graphql/queries/job'
import { FindShipmentResultDisplay } from '@src/utils/recon/FindShipmentResultDisplay'
import ExternalAssigneeSelector from '@src/components/job-viewer/ExternalAssigneeSelector'

type ExternalAssigneeOption = {
  id: string
  email: string
}

type Props = {
  jobId: string
  companyId: string
  externalAssignee: UserNode | undefined | null
  reconAttemptId: string | null
  reconJobResults: ReconcileApInvoiceJob
  existingReconAttempt: ReconAttemptNode | null
  previous: () => void
  close: () => void
}

const MIN_CELL_WIDTH = '75px'

const useStyles = makeStyles<Theme>({
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.75, 1),
    minWidth: MIN_CELL_WIDTH,
  },
  wrapWord: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  error: {
    color: theme.palette.error.main,
    background: COLORS.PALE_RED,
    boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
  },
  shipmentDate: {
    color: theme.palette.text.primary,
  },
  shipmentDateError: {
    color: theme.palette.error.main,
  },
})

const APInvoiceReconResults: FunctionComponent<Props> = ({
  jobId,
  companyId,
  externalAssignee,
  reconAttemptId,
  reconJobResults,
  existingReconAttempt,
  previous,
  close,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { logEvent } = useEventLogger()
  const { reconAttempt } = reconJobResults
  const reconResults = reconJobResults.reconResults as ApInvoiceReconResultNode[]
  const [jobExternalAssignee, setJobExternalAssignee] = useState<ExternalAssigneeOption | null>(
    externalAssignee ? { id: externalAssignee.id, email: externalAssignee.email } : null,
  )
  const cargowiseOpsType = CargowiseOpsType.Reconcile

  const findShipmentReconResults = reconResults.filter(
    (reconResult) =>
      !isFallback(reconResult.type) &&
      reconResult.type?.value === ReconResultType.FindShipmentReconResult,
  )

  const shipmentDateModified = getReconResultsDataModifiedDate(
    findShipmentReconResults,
    JobTemplateReconType.Ap,
  )
  // We consider shipments that are more than 30 minutes old as outdated
  // [PD-288](https://expedock.atlassian.net/browse/PD-288)
  const isShipmentDateOutdated =
    shipmentDateModified &&
    new Date().getTime() - shipmentDateModified.getTime() > millisecondsInMinute * 30

  const [saveReconAttempt, { loading: saveReconAttemptLoading }] = useMutation(
    SAVE_AP_RECON_ATTEMPT,
    {
      refetchQueries: ['getJobDataLite', { query: JOB_NOTES, variables: { jobId } }],
    },
  )

  const [editJob] = useEditJobMutation({
    refetchQueries: [
      'getJobForTms',
      { query: GET_JOB_FOR_TMS, variables: { id: jobId } },
      'getJobDataLite',
      { query: GET_JOB_DATA_LITE, variables: { id: jobId } },
    ],
    onError: (error) => {
      enqueueSnackbar(`Failed to save job details: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
    onCompleted: () => {
      enqueueSnackbar('Successfully saved job details.', { variant: 'success' })
    },
  })

  const handleSaveReconAttempt = async (): Promise<void> => {
    let success = false
    let errorMessage = ''
    try {
      await saveReconAttempt({
        variables: { reconAttemptId: reconAttempt.id },
      })
      enqueueSnackbar('Saved recon attempt', { variant: 'success' })
      success = true
      close()
    } catch (error) {
      errorMessage = `Failed to save recon attempt: ${formatMaybeApolloError(error)}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
    } finally {
      void logEvent(LogEventType.RECONCILE_SHOW, {
        job_id: jobId,
        recon_attempt_id: reconAttemptId,
        recon_type: JobTemplateReconType.Ap,
        success: success,
        error_message: errorMessage,
      })
    }
  }

  const apMetadataTable = getAPMetadataTable(reconResults)
  const apAssociatedKeysTable = getAPAssociatedKeysTable(findShipmentReconResults, reconAttempt)
  const chainIoModelName = formatCwTargetModule(getCargowiseModuleFromReconAttempt(reconAttempt))
  return (
    <div id='recon-modal' data-testid='recon-modal'>
      <DialogTitle disableTypography>
        <Typography variant='h6'>Reconciliation Results</Typography>
      </DialogTitle>
      <DialogContent data-testid='ap-invoice-recon-result'>
        <Box mb={2}>
          <FindShipmentResultDisplay
            findShipmentResults={findShipmentReconResults}
            cargowiseOpsType={cargowiseOpsType}
            // TODO: unsafe fallback
            cargowiseModule={
              getCargowiseModuleFromReconAttempt(reconAttempt) || CwTargetModule.ForwardingShipment
            }
          />
        </Box>
        {shipmentDateModified && (
          <Box mb={2}>
            <Typography component={'span'} variant='body1'>
              {`${chainIoModelName} data last updated as of: `}
              <span
                className={clsx(classes.shipmentDate, {
                  [classes.shipmentDateError]: isShipmentDateOutdated,
                })}
              >
                {shipmentDateModified?.toString()}
              </span>
            </Typography>
          </Box>
        )}
        <Box mb={2} data-testid='associated-keys-table'>
          <APInvoiceReconMetadataTable
            title='Associated Keys'
            metadataTable={apAssociatedKeysTable}
          />
        </Box>
        <Box mb={2} data-testid='metadata-recon-table'>
          <APInvoiceReconMetadataTable
            title='Metadata Reconciliation'
            metadataTable={apMetadataTable}
          />
        </Box>
        <Typography variant='subtitle1' gutterBottom>
          Line Item Data Reconciliation:
        </Typography>
        <APInvoiceReconResultTables
          reconJobResults={reconJobResults}
          overrideChargeDescription={reconAttempt.overrideChargeDescription}
        />
        {existingReconAttempt && (
          <Box display='flex' justifyContent='center' alignItems='center'>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              bgcolor='lightgoldenrodyellow'
              p={2}
            >
              <Box p={1}>
                <ErrorOutlineIcon />
              </Box>
              <Box p={1}>
                <Typography variant='subtitle2'>
                  An existing recon attempt for this job has already been{' '}
                  {existingReconAttempt.resolutionStatus} by{' '}
                  {existingReconAttempt.userResolved?.email ?? ''} on{' '}
                  {parseDateString(
                    existingReconAttempt.dateResolved as string,
                  ).toLocaleDateString()}
                  . Are you sure you want to save another recon attempt for their approval?
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Grid item xs={6}>
            <Box display='flex' justifyContent='flex-start' p={2}>
              <Box mr={2} flex='1'>
                <ExternalAssigneeSelector
                  companyId={companyId}
                  value={jobExternalAssignee}
                  onChange={(value) => setJobExternalAssignee(value)}
                  label='External Assignee'
                />
              </Box>
              <Button
                variant='contained'
                type='submit'
                disableElevation
                onClick={async () => {
                  await editJob({
                    variables: {
                      jobId: jobId,
                      externalAssigneeId: jobExternalAssignee?.id,
                    },
                  })
                }}
                data-testid='save-job-details-btn'
              >
                Save Job Details
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display='flex' alignItems='flex-end' justifyContent='flex-end' p={2}>
              <Box mr={2}>
                <Button onClick={previous} variant='contained'>
                  Go Back
                </Button>
              </Box>
              <Button
                disabled={saveReconAttemptLoading}
                onClick={handleSaveReconAttempt}
                color='primary'
                variant='contained'
                data-testid='show-customer-aprecon'
              >
                Show Customer
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  )
}

export default APInvoiceReconResults
