import {
  Mutation,
  MutationSaveJobAndDocumentTablesArgs,
  SearchableRecordNode,
  InputDocumentTable,
  InputJobTable,
  FilePageType,
  OutputSearchableRecordColumn,
  InputSearchableRecordColumn,
  Maybe,
  FieldTypeWithFallback,
} from '@src/graphql/types'
import { v4 as uuidv4 } from 'uuid'
import { JobTableLineItem, LineItem } from './line_items'
import Handsontable from 'handsontable'
import { COLORS } from './app_constants'
import { RefObject } from 'react'
import {
  addLineItemRows,
  copyActiveLineItems,
  copyFromExtractedTables,
  deleteLineItemRows,
  resetHighlightedBoxes,
  updateTableColumns,
  updateTableLineItems,
} from '@src/redux-features/document_editor'
import store from '@src/utils/store'
import { GoogleOCRData } from '../types/ocr'
import {
  lineItemSelectors,
  rawLineItemsSelectors,
} from '../redux-features/document_editor/line_items_table'
import {
  stateHasMainTable,
  jobTableLineItemSelectors,
} from '@src/redux-features/document_editor/job_table'
import { formatToInputDocumentTable, formatToInputJobTable } from './shipment_form'
import { magicGridColumnsAdapter } from '@src/redux-features/document_editor/magic_grid'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import { FetchResult, MutationFunctionOptions } from '@apollo/client'
import { selectActiveDocumentTable } from '../redux-features/document_editor/document_table'
import { selectActiveDocument } from '@src/redux-features/document_editor/document'
import { selectRepeatableFieldKeyMap } from '../redux-features/document_editor/field'
import {
  DocumentEditorPage,
  DocumentEditorState,
} from '../redux-features/document_editor/document_editor_state'
import { isValidRecentDate } from './date'
import { FastHotTableRefValue } from '@src/components/fast-hot-table/FastHotTable'
import { CompanyNode } from '../graphql/types'
import { buildSearchableRecordFilters } from './searchable_record'
import { uniq } from 'lodash'
import { formatMaybeApolloError } from './errors'
import { ChargeCodeTax } from '@src/components/data-grid/types'

export type SpreadsheetDataColumn = {
  id?: string
  type?: string
  key: string
  autofillKey?: string
  defaultValue?: Maybe<string>
  selectOptions?: string[]
  allowInvalid?: boolean
  strict?: boolean
  fieldType?: Maybe<FieldTypeWithFallback>
  // we no longer need handsontable's built-in validation because we have our own
  // to prevent the built-in validation from running, we use different attributes
  _strict?: boolean
  _required?: boolean
  _validator?: RegExp
  name?: string
  required?: boolean
  validatorDescription?: string | null
  dateFormat?: string | null
  correctFormat?: boolean | null
  editor?: typeof Handsontable._editors.Base
  renderer?: (
    // value is any in the spec
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    instance: any,
    TD: HTMLElement,
    row: number,
    col: number,
    prop: string | number,
    // value is any in the spec
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    value: any,
    cellProperties: Handsontable.GridSettings,
  ) => void
  width?: number
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  handsontable?: any
  source?: ((query: string, process: (results: string[]) => void) => void) | string[]
  searchableRecord?: SearchableRecordNode
}

export type SpreadsheetColumnHeader = string

export type SpreadsheetDataRow = (string | null)[]

export type SpreadsheetSingleColValues = (string | null)[]

export type SpreadsheetCellMeta = {
  // handsontable actual typing I think
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  comment?: any
  readOnly?: boolean
  renderer?: string | Handsontable.renderers.Base
}

export const convertTableDataToLineItems = (
  currLineItems: LineItem[],
  gridData: (string | null)[][],
  cols: SpreadsheetDataColumn[],
  tableId: string,
  colIdxToInclude?: number[],
): LineItem[] => {
  if (!cols.length) return []
  // for each line item
  // replace value from grid data
  // if there is new value, add it
  // for new rows, append line item with null coordinates
  const newLineItems: LineItem[] = []
  // non-extensible so we copy
  const currLineItemsCopy = JSON.parse(JSON.stringify(currLineItems))
  for (const [rowIdx, gridRow] of gridData.entries()) {
    let currLineItem: LineItem = {} as LineItem
    if (rowIdx < currLineItemsCopy.length) {
      currLineItem = currLineItemsCopy[rowIdx]
    } else {
      currLineItem.id = uuidv4()
      currLineItem.fieldMapping = {}
      currLineItem.documentTableId = currLineItemsCopy[0]?.documentTableId || tableId
    }
    for (const [colIdx, cellValue] of gridRow.entries()) {
      const colKey = cols[colIdx].key
      currLineItem.fieldMapping[colKey] = {
        // we need to retain the field coordinates
        ...currLineItem.fieldMapping[colKey],
        value: cellValue || '',
      }
    }
    // make sure only columns showing in hottable table are present on line items
    const includeAllCols = !colIdxToInclude
    const colKeys = cols
      .filter((_col, colIdx) => includeAllCols || colIdxToInclude!.includes(colIdx))
      .map((col) => col.key)
    for (const key of Object.keys(currLineItem.fieldMapping)) {
      if (!colKeys.includes(key)) {
        delete currLineItem.fieldMapping[key]
      }
    }
    newLineItems.push(currLineItem)
  }
  return newLineItems
}

export const convertTableDataToJobTableLineItems = (
  currLineItems: JobTableLineItem[],
  gridData: (string | null)[][],
  cols: SpreadsheetDataColumn[],
): JobTableLineItem[] => {
  if (!cols.length) return []
  // for each line item
  // replace value from grid data
  // if there is new value, add it
  // for new rows, append line item with null coordinates
  const newLineItems: JobTableLineItem[] = []
  // non-extensible so we copy
  const currLineItemsCopy = JSON.parse(JSON.stringify(currLineItems))
  for (const [rowIdx, gridRow] of gridData.entries()) {
    let currLineItem: JobTableLineItem = {} as JobTableLineItem
    if (rowIdx < currLineItemsCopy.length) {
      currLineItem = currLineItemsCopy[rowIdx]
    } else {
      currLineItem.id = uuidv4()
      currLineItem.fieldMapping = {}
      currLineItem.boxMapping = {}
    }
    for (const [colIdx, cellValue] of gridRow.entries()) {
      const colKey = cols[colIdx].key
      currLineItem.fieldMapping[colKey] = {
        // we need to retain the field coordinates
        ...currLineItem.fieldMapping[colKey],
        value: cellValue || '',
      }
    }
    // make sure only columns showing in hottable table are present on line items
    const colKeys = cols.map((col) => col.key)
    for (const key of Object.keys(currLineItem.fieldMapping)) {
      if (!colKeys.includes(key)) {
        delete currLineItem.fieldMapping[key]
      }
    }
    newLineItems.push(currLineItem)
  }
  return newLineItems
}

export const fillerCellRenderer = function (
  instance: Handsontable,
  td: HTMLElement,
  row: number,
  col: number,
  prop: string | number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  cellProperties: Handsontable.GridSettings,
): void {
  Handsontable.renderers.TextRenderer(instance, td, row, col, prop, value, cellProperties)
  td.style.border = '0'
  // Handsontable typing has wrong return type lol
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any as Handsontable.renderers.Base

export const subtotalCellRenderer = function (
  instance: Handsontable,
  td: HTMLElement,
  row: number,
  col: number,
  prop: string | number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  cellProperties: Handsontable.GridSettings,
): void {
  Handsontable.renderers.TextRenderer(instance, td, row, col, prop, value, cellProperties)
  td.style.background = COLORS.PALE_YELLOW
  td.style.borderTop = '1px solid #CCC'
  // Handsontable typing has wrong return type lol
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any as Handsontable.renderers.Base

export const errorCellRenderer = function (
  instance: Handsontable,
  td: HTMLElement,
  row: number,
  col: number,
  prop: string | number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  cellProperties: Handsontable.GridSettings,
): void {
  Handsontable.renderers.TextRenderer(instance, td, row, col, prop, value, cellProperties)
  td.style.background = COLORS.PALE_RED
  // Handsontable typing has wrong return type lol
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any as Handsontable.renderers.Base

const isValuePartOfSource = (
  searchableRecordsValidationMap: Record<number, Record<number, boolean>>,
  cell: string,
  source: ((query: string, process: (results: string[]) => void) => void) | string[] | undefined,
  rowIdx: number,
  colIdx: number,
): boolean => {
  if (Array.isArray(source)) {
    return (source as string[]).includes(cell)
  }
  if (searchableRecordsValidationMap[colIdx]) {
    return searchableRecordsValidationMap[colIdx][rowIdx] ?? true
  }
  return true
}

export const getHandsontableHooksAndActions = (
  columns: SpreadsheetDataColumn[],
  googleOcrData: GoogleOCRData | null,
  createBoxEnabled: boolean,
  hotTableRef: RefObject<FastHotTableRefValue | undefined> | undefined,
  isJobTableActive: boolean,
  chargeCodeTaxMap: Record<string, ChargeCodeTax> | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> => {
  const serializableColumns: SpreadsheetDataColumn[] = JSON.parse(JSON.stringify(columns)).map(
    (column: SpreadsheetDataColumn) => {
      column.source = undefined
      column.handsontable = undefined
      column.editor = undefined
      column.renderer = undefined
      column._validator = undefined
      return column as SpreadsheetDataColumn
    },
  )
  const columnKeys = serializableColumns.map((column: SpreadsheetDataColumn) => column.key)
  const getActiveLineItems = (): LineItem[] | JobTableLineItem[] => {
    return isJobTableActive
      ? jobTableLineItemSelectors.selectAll(store.getState().documentEditor) ?? []
      : lineItemSelectors.selectAll(store.getState().documentEditor) ?? []
  }

  const refreshEditor = (): void => {
    if (hotTableRef?.current && !hotTableRef.current.hotInstance.isDestroyed) {
      // destroy outdated editor and refresh the editor of the current selected cell
      // without this, if we paste to a cell and press enter to edit the cell or if we edit the first cell and right click for the context menu,
      // the previous value would be displayed (not the updated value from the paste)
      // an alternative way to refresh the editor could be to re-select the cell
      hotTableRef.current.hotInstance.destroyEditor()
    }
  }

  const beforeCopy = (
    copiedText: string[][],
    coords: { startRow: number; endRow: number; startCol: number; endCol: number }[],
  ): void => {
    if (!hotTableRef || !hotTableRef.current || !coords.length) {
      return
    }
    store.dispatch(
      copyActiveLineItems({
        columnKeys,
        coords,
        copiedText,
      }),
    )
  }

  const beforeColumnMove = (startColumn: number | number[], endColumn: number): boolean => {
    // for some reason, afterColumnMove in HotTable says that startColumn is a number, but it looks like it always returns number[]
    // but just to make sure its a list:
    const startColumnsArr = [startColumn].flat()
    const insertIdxStart =
      endColumn < startColumnsArr[0] ? endColumn : endColumn - startColumnsArr.length
    store.dispatch(
      updateTableColumns({
        startColumnsArr,
        insertIdxStart,
        columnKeys,
      }),
    )
    if (hotTableRef?.current) {
      // need to programmatically set the selected columns since return false aborts the move
      const insertIdxEnd = insertIdxStart + startColumnsArr.length - 1
      hotTableRef.current!.hotInstance.selectColumns(insertIdxStart, insertIdxEnd)
    }
    return false
  }

  const beforeRemoveRow = (
    index: number,
    amount: number,
    _logicalRows: number[] | undefined,
  ): boolean => {
    store.dispatch(deleteLineItemRows({ index, amount }))
    return false
  }

  const beforeCreateRow = (index: number, amount: number, source: string | undefined): boolean => {
    const lineItemsBeforeDispatch = getActiveLineItems()
    store.dispatch(
      addLineItemRows({
        index,
        amount,
        columnKeys,
        googleOcrData,
      }),
    )
    const lineItemsAfterDispatch = getActiveLineItems()
    // in some cases, addLineItemRows could be unsuccessful (won't actually add a new row)
    // e.g. this could happen when we're trying to add a new row in which the bounding boxes would be outside of the document image
    if (lineItemsAfterDispatch.length > lineItemsBeforeDispatch.length) {
      if (source === 'ContextMenu.rowAbove') {
        if (hotTableRef?.current) {
          hotTableRef.current!.hotInstance.selectRows(index + 1)
        }
      }
    }
    return false
  }

  const beforeChange = (
    changes: [number, string | number, string | number | null, string | number | null][],
    source: string,
  ): boolean => {
    store.dispatch(
      updateTableLineItems({
        changes,
        source,
        columns: serializableColumns,
        googleOcrData,
        chargeCodeTaxMap,
      }),
    )
    return false
  }

  const beforeOnCellContextMenu = (): void => {
    refreshEditor()
  }

  const afterLoadData = (): void => {
    refreshEditor()
  }

  const unhighlightRowAndFieldBoxes = (): void => {
    // afterDeselect gets called when no cells are selected
    // i.e. selecting a different cell doesn't trigger this
    if (!createBoxEnabled) {
      store.dispatch(resetHighlightedBoxes())
    }
  }

  const copyFromExtracted = (): void => {
    store.dispatch(copyFromExtractedTables())
  }

  const getCellMetaAfterValidation = (
    rowIdx: number,
    colIdx: number,
    searchableRecordsValidationMap: Record<number, Record<number, boolean>>,
    col: SpreadsheetDataColumn | undefined,
    cell: string,
  ): SpreadsheetCellMeta => {
    if (!col) {
      return {}
    }
    const validator = col._validator as RegExp | null
    const isEmptyWrong = !cell && col._required
    const isValidationWrong = validator && cell && !validator.exec(cell)
    const isDateFormatWrong =
      col.type === 'date' && col.dateFormat && cell && !isValidRecentDate(cell, col.dateFormat)
    const isSelectionInvalid =
      col._strict &&
      cell &&
      !isValuePartOfSource(searchableRecordsValidationMap, cell, col.source, rowIdx, colIdx)
    let errorMessage = null
    if (isValidationWrong) {
      errorMessage = col.validatorDescription ?? ''
    } else if (isDateFormatWrong) {
      errorMessage = `Must be a valid date with format ${col.dateFormat}`
    } else if (isEmptyWrong) {
      errorMessage = 'This field is required'
    } else if (isSelectionInvalid) {
      errorMessage = 'Value must be selected from the dropdown'
    }
    if (
      errorMessage &&
      (isValidationWrong || isDateFormatWrong || isEmptyWrong || isSelectionInvalid)
    ) {
      return {
        comment: {
          value: errorMessage,
        },
        renderer: errorCellRenderer,
      }
    }
    return {}
  }
  /**
   * Adds comments to error cells and highlights them red
   */
  const getCellMetaForIndex = (
    rowIdx: number,
    colIdx: number,
    searchableRecordsValidationMap: Record<number, Record<number, boolean>>,
  ): SpreadsheetCellMeta => {
    const col = columns[colIdx]
    if (!hotTableRef?.current || hotTableRef.current.hotInstance.isDestroyed) {
      return {}
    }
    const cell = hotTableRef.current.hotInstance.getDataAtCell(rowIdx, colIdx) ?? ''
    return getCellMetaAfterValidation(rowIdx, colIdx, searchableRecordsValidationMap, col, cell)
  }

  return {
    beforeCopy,
    beforeColumnMove,
    beforeRemoveRow,
    beforeCreateRow,
    beforeChange,
    beforeOnCellContextMenu,
    afterLoadData,
    unhighlightRowAndFieldBoxes,
    copyFromExtracted,
    getCellMetaForIndex,
    getCellMetaAfterValidation,
  }
}

export const getInputDocumentTablesToSave = (
  docEditorState: DocumentEditorState,
  columns: string[],
): InputDocumentTable[] => {
  const inputDocumentTablesToSave = [] as InputDocumentTable[]
  docEditorState.documentTables.ids.forEach((documentTableId) => {
    const lineItems =
      rawLineItemsSelectors.selectAll(
        docEditorState.lineItemsTableMap[documentTableId].lineItems,
      ) ?? []
    const magicGridState = docEditorState.magicGridMap[documentTableId]
    const documentTable = docEditorState.documentTables.entities[documentTableId]!
    // there shouldn't be a document table to save if we're on a job w/ main table and the file page type is EXCEL
    // since there shouldn't be an Extracted Table displayed in the UI
    // adding this filter in just in case we accidentally modify the line items/ columns in the table
    if (!(stateHasMainTable(docEditorState) && documentTable.filePageType === FilePageType.Excel)) {
      inputDocumentTablesToSave.push(
        formatToInputDocumentTable(
          documentTable,
          lineItems,
          magicGridColumnsAdapter.getSelectors().selectAll(magicGridState.columns),
          true,
          columns,
        ),
      )
    }
  })
  return inputDocumentTablesToSave
}

const getInputJobTableToSave = (
  docEditorState: DocumentEditorState,
  columns: string[],
): InputJobTable => {
  const activeDocumentTable = selectActiveDocumentTable(docEditorState)
  const activeDocument = selectActiveDocument(docEditorState)
  const repeatableFieldKeyMap = selectRepeatableFieldKeyMap(docEditorState)
  const lineItems = jobTableLineItemSelectors.selectAll(store.getState().documentEditor) ?? []
  return formatToInputJobTable(
    lineItems,
    activeDocumentTable!.fieldGroup!.id,
    activeDocument!.id,
    repeatableFieldKeyMap,
    columns,
  )
}

export const getSaveTable = (
  saveCurrentTableCallback: (() => Promise<void>) | null,
  hotTableRef: RefObject<FastHotTableRefValue | undefined> | null,
  columns: string[],
  saveJobAndDocumentTables: (
    options?:
      | MutationFunctionOptions<
          Pick<Mutation, 'saveJobAndDocumentTables'>,
          MutationSaveJobAndDocumentTablesArgs
        >
      | undefined,
  ) => Promise<FetchResult<Pick<Mutation, 'saveJobAndDocumentTables'>>>,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): Record<string, () => Promise<void>> => {
  const saveAllTablesCallback = async (
    docEditorState: DocumentEditorState,
    columns: string[],
    saveJobAndDocumentTables: (
      options?:
        | MutationFunctionOptions<
            Pick<Mutation, 'saveJobAndDocumentTables'>,
            MutationSaveJobAndDocumentTablesArgs
          >
        | undefined,
    ) => Promise<FetchResult<Pick<Mutation, 'saveJobAndDocumentTables'>>>,
  ): Promise<void> => {
    /**
     * We now save all the tables all the time on SOA jobs because of the column syncing
     * https://expedock.atlassian.net/browse/PD-868
     */
    enqueueSnackbar(`Saving all tables...`, { variant: 'info' })
    try {
      await saveJobAndDocumentTables({
        variables: {
          jobId: docEditorState.job!.id,
          documentTables: getInputDocumentTablesToSave(docEditorState, columns),
          jobTable: getInputJobTableToSave(docEditorState, columns),
          validateFields: false,
        },
        context: {
          debounceKey: docEditorState.job!.id,
          debounceTimeout: 2000,
        },
      })
      enqueueSnackbar(`Successfully saved all tables`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(`Failed to save tables with error ${formatMaybeApolloError(e)}`, {
        variant: 'error',
      })
    }
  }

  const saveTableCallback = async (): Promise<void> => {
    const docEditorState = store.getState().documentEditor
    if (stateHasMainTable(docEditorState)) {
      await saveAllTablesCallback(docEditorState, columns, saveJobAndDocumentTables)
    } else if (saveCurrentTableCallback) {
      await saveCurrentTableCallback()
    }
  }
  const saveTable = async (): Promise<void> => {
    /**
     * beforeChange is only triggered after an opened cell editor is deselected or after another cell is selected
     * so in cases where the user hits the save hotkey right after typing,
     * we can manually deselect to trigger beforeChange (and to update the state)
     */
    if (hotTableRef?.current && !hotTableRef.current.hotInstance.isDestroyed) {
      const activeEditor =
        hotTableRef.current.hotInstance.getActiveEditor() /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ as
          | Record<string, any>
          | undefined
      if (activeEditor && activeEditor._opened) {
        const hotInstance = hotTableRef.current.hotInstance
        // add a hook to save line items after data is loaded onto the table
        // this is to guarantee that the state is updated before we perform the other actions
        hotInstance.addHookOnce('afterLoadData', (): void => {
          void saveTableCallback()
        })
        hotInstance.deselectCell()
      } else {
        await saveTableCallback()
      }
    }
  }
  return {
    saveTable,
  }
}

export const getSearchableRecordsValidationMap = (
  rows: SpreadsheetDataRow[],
  cols: SpreadsheetDataColumn[],
  validSearchableRecordResults: OutputSearchableRecordColumn[],
): Record<number, Record<number, boolean>> => {
  const validationMap = {} as Record<number, Record<number, boolean>>
  cols.forEach((col, colIdx) => {
    if (col.searchableRecord) {
      validationMap[colIdx] = {}
      const searchableRecordResults =
        validSearchableRecordResults.find(
          (searchableRecordColumn) =>
            searchableRecordColumn.searchableRecordId === col.searchableRecord!.id,
        )?.values ?? []
      rows.forEach((row, rowIdx) => {
        validationMap[colIdx][rowIdx] =
          !row[colIdx] || searchableRecordResults.includes(row[colIdx] ?? '')
      })
    }
  })
  return validationMap
}

export const getInputSearchableRecordColumns = (
  rows: SpreadsheetDataRow[],
  columns: SpreadsheetDataColumn[],
  pages?: Record<string, DocumentEditorPage>,
  company?: CompanyNode,
  apiPartnerId?: string | null,
): InputSearchableRecordColumn[] => {
  const columnsWithSearchableRecords = columns
    .map((col, colIdx) => ({ colIdx: colIdx, column: col }))
    .filter((col) => col.column.searchableRecord)
  const searchableRecordInput = {} as Record<string, InputSearchableRecordColumn>
  columnsWithSearchableRecords.forEach((col) => {
    const column = col.column
    const colIdx = col.colIdx
    if (!searchableRecordInput[column.searchableRecord!.id]) {
      const filters = buildSearchableRecordFilters(
        column.searchableRecord,
        pages,
        company,
        apiPartnerId,
      )
      searchableRecordInput[column!.searchableRecord!.id] = {
        searchableRecordId: column!.searchableRecord!.id,
        values: [],
        filters: filters,
      }
    }
    const queryList = rows.map((row) => row[colIdx] ?? '').filter((value) => value)
    searchableRecordInput[column!.searchableRecord!.id].values =
      searchableRecordInput[column!.searchableRecord!.id].values.concat(queryList)
  })
  return Object.keys(searchableRecordInput).map((searchableRecordId) => ({
    searchableRecordId: searchableRecordId,
    values: uniq(searchableRecordInput[searchableRecordId].values),
    filters: searchableRecordInput[searchableRecordId].filters,
  }))
}
