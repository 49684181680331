import { gql } from '@apollo/client'

export const MATCH_SOA_LINE_ITEMS_TO_CHAIN_IO_SHIPMENTS = gql`
  query matchSoaLineItemsToChainIoShipments(
    $jobId: String!
    $rowsMatchCriteria: [InputSOARowMatchCriteria!]!
  ) {
    matchSoaLineItemsToChainIoShipments(jobId: $jobId, rowsMatchCriteria: $rowsMatchCriteria) {
      rowIdx
      matchingInvoiceNumberChainIoShipmentId
    }
  }
`
