import { FunctionComponent, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/styles'
import { SpreadsheetDataColumn, SpreadsheetDataRow } from '@src/utils/data-grid'
import { Box, Checkbox, TextField, Typography } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import theme from '@src/utils/theme'
import CloseIcon from '@material-ui/icons/Close'
import { Autocomplete } from '@material-ui/lab'
import escapeStringRegexp from 'escape-string-regexp'

const TYPOGRAPHY_WIDTH = 150

const useStyles = makeStyles({
  findReplaceTitle: {
    margin: 'auto',
    width: TYPOGRAPHY_WIDTH,
    paddingBottom: theme.spacing(7),
    paddingRight: theme.spacing(2),
  },
  buttonsFooter: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: 200,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

type Props = {
  handleClickCancel: () => void
  open: boolean
  rows: SpreadsheetDataRow[]
  cols: SpreadsheetDataColumn[]
  replace: (changes: [number, number, string][]) => void
}

const BulkReplaceDialog: FunctionComponent<Props> = ({
  open,
  handleClickCancel,
  rows,
  cols,
  replace,
}) => {
  const classes = useStyles()
  const [findStr, setFindStr] = useState('')
  const [replaceStr, setReplaceStr] = useState('')
  const [selectedColNames, setSelectedColNames] = useState([] as string[])

  const handleReplace = (replaceAll: boolean): void => {
    const colNames = cols.map((col) => col.name)
    let cellChanges: [number, number, string][] = []
    for (let rowIdx = 0; rowIdx < rows.length; rowIdx += 1) {
      for (let colIdx = 0; colIdx < rows[0].length; colIdx += 1) {
        if (
          selectedColNames.includes(colNames[colIdx] as string) &&
          rows[rowIdx][colIdx]?.includes(findStr)
        ) {
          const escapedString = escapeStringRegexp(findStr)
          const newValue = rows[rowIdx][colIdx]!.replace(new RegExp(escapedString, 'g'), replaceStr)
          cellChanges = [...cellChanges, [rowIdx, colIdx, newValue]]
          if (!replaceAll) {
            replace(cellChanges)
            return
          }
        }
      }
    }
    replace(cellChanges)
  }

  const handleClickReplace = (): void => {
    handleReplace(false)
  }
  const handleClickReplaceAll = (): void => {
    handleReplace(true)
  }

  const replaceDisabled = !findStr || selectedColNames.length === 0

  const handleClickDone = handleClickCancel
  return (
    <Dialog maxWidth='sm' open={open} onClose={handleClickCancel} aria-labelledby='a'>
      <DialogTitle id='a'>Find and Replace</DialogTitle>
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={handleClickCancel} data-testid='close-btn' />
      </Box>
      <DialogContent>
        <div>
          <div>
            <Box display='inline-flex'>
              <Typography className={classes.findReplaceTitle}>Find</Typography>
              <TextField
                value={findStr}
                variant='outlined'
                size='small'
                onChange={(e) => setFindStr(e.target.value)}
              />
            </Box>
          </div>
          <div>
            <Box display='inline-flex'>
              <Typography className={classes.findReplaceTitle}>Replace with</Typography>
              <TextField
                value={replaceStr}
                variant='outlined'
                size='small'
                onChange={(e) => setReplaceStr(e.target.value)}
              />
            </Box>
          </div>
          <div>
            <Box display='inline-flex'>
              <Typography className={classes.findReplaceTitle}>Columns</Typography>
              <Autocomplete
                multiple
                className={classes.formControl}
                options={cols}
                disableCloseOnSelect
                getOptionLabel={(col) => col?.name ?? ''}
                onChange={(_evt, selectedCols: SpreadsheetDataColumn[]) => {
                  const colNames = selectedCols.map((selectedCol) => selectedCol.name)
                  setSelectedColNames(colNames as string[])
                }}
                renderOption={(col, { selected }) => (
                  <>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                      checkedIcon={<CheckBoxIcon fontSize='small' />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {col.name}
                  </>
                )}
                renderInput={(params) => <TextField {...params} variant='outlined' />}
              />
            </Box>
          </div>
        </div>
      </DialogContent>
      <Box display='inline-flex' justifyContent='space-evenly' p={2}>
        <Button
          id='replace'
          disabled={replaceDisabled}
          variant='contained'
          onClick={handleClickReplace}
        >
          Replace
        </Button>
        <Button
          id='replace-all'
          disabled={replaceDisabled}
          variant='contained'
          onClick={handleClickReplaceAll}
        >
          Replace All
        </Button>
        <Button variant='contained' color='primary' onClick={handleClickDone}>
          Done
        </Button>
      </Box>
    </Dialog>
  )
}

export default BulkReplaceDialog
