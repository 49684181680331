/* eslint-disable @typescript-eslint/no-explicit-any */
import Handsontable from 'handsontable'
const AutocompleteEditor = Handsontable.editors.AutocompleteEditor
const HandsontableEditor = Handsontable.editors.HandsontableEditor

class KeyValueAutocompleteEditor extends AutocompleteEditor {
  metadataProp = '__autocomplete__'

  getMetadata(): string | Record<string, string> | undefined {
    const md = (this as any).instance.getSourceDataAtRow(this.row)[this.metadataProp]
    return (md ? md[this.prop] : undefined) as string | undefined
  }

  setMetadata(value: string): void {
    const rowData = this.instance.getSourceDataAtRow(this.row)
    let md: any = (rowData as Record<string, unknown>)[this.metadataProp]
    if (!md) {
      md = (rowData as Record<string, unknown>)[this.metadataProp] = {}
    }
    md[this.prop] = value
  }

  open(): void {
    // eslint-disable-next-line prefer-rest-params
    AutocompleteEditor.prototype.open.apply(this, arguments as any)

    // we don't want this hook to run, it stringifes everything
    // also: no good API for this if we don't have a refernece to the the
    // original hook :(

    const bucket = (Handsontable.hooks as any).getBucket((this as any).htEditor),
      bucketList = bucket.afterRenderer

    bucketList.forEach((cb: any) => {
      ;(this as any).htEditor.removeHook('afterRenderer', cb)
    })
  }

  prepare(): void {
    // eslint-disable-next-line prefer-rest-params
    AutocompleteEditor.prototype.prepare.apply(this, arguments as any)

    // We don't support trimming list (filter),
    // sorting by relevance (make the source function sort), or trimming
    // the dropdown... yet.
    ;(this.cellProperties as any).filter = false
    ;(this.cellProperties as any).allowHtml = false
    ;(this.cellProperties as any).sortByRelevance = false
  }

  beginEditing(initialValue: string): void {
    // Set initial editor value based on the title that was last used
    const hint = this.getMetadata()
    if (hint !== undefined) {
      initialValue = (hint as Record<string, string>).code
    }

    super.beginEditing(initialValue)
  }

  updateChoicesList(choices: string[]): void {
    const newChoices = choices.map(
      (choice: string) => JSON.parse(choice) as Record<string, unknown>,
    )
    ;(this as any).choices = newChoices
    const htEditor = (this as any).htEditor
    if (htEditor && !htEditor.isDestroyed) {
      htEditor.loadData(newChoices)
    }
    ;(this as any).updateDropdownHeight()
    ;(this as any).flipDropdownIfNeeded()
    ;(this as any).focus()
  }

  findBestMatchingChoice(): number {
    const bestMatch: Record<any, any> = {}
    const choices = (this as any).choices
    let value = (this as any).getValue()
    const valueLength = value.length
    const filteringCaseSensitive = (this.cellProperties as any).filteringCaseSensitive

    if (!filteringCaseSensitive) {
      value = value.toLowerCase()
    }

    for (let i = 0, len = choices.length; i < len; i++) {
      let currentItem
      let indexOfValue
      currentItem = choices[i].code

      if (currentItem && !filteringCaseSensitive) {
        currentItem = currentItem.toLowerCase()
      }

      if (currentItem && valueLength > 0) {
        indexOfValue = currentItem.indexOf(value)
      } else {
        indexOfValue = currentItem === value ? 0 : -1
      }

      if (indexOfValue === -1) continue

      const charsLeft = currentItem.length - indexOfValue - valueLength

      if (
        typeof bestMatch.indexOfValue === 'undefined' ||
        bestMatch.indexOfValue > indexOfValue ||
        (bestMatch.indexOfValue === indexOfValue && bestMatch.charsLeft > charsLeft)
      ) {
        bestMatch.indexOfValue = indexOfValue
        bestMatch.charsLeft = charsLeft
        bestMatch.index = i
      }
    }

    return bestMatch.index as number
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  finishEditing(...args: any[]): void {
    if ((this as any).htEditor) {
      const selection = (this as any).htEditor.getSelected()

      if (selection) {
        const rowData = (this as any).htEditor.getSourceDataAtRow(selection[0]),
          value = (this as any).htEditor.getValue()

        if (rowData !== undefined && value !== undefined) {
          this.setMetadata(rowData)
        }
      }
    }

    super.finishEditing(...args)
  }

  getDropdownHeight(): number {
    // the height of the dropdown is fixed based on this, and the positioning of the dropdown
    // (whether above or below) is based on this value.
    const rowHeight = 25
    const scrollBarHeight = 16
    const numRows: number = (this as any).htEditor?.countRows() || 0
    const maxVisibleRows = 5
    // + 1 on rows for the header
    return (Math.min(numRows, maxVisibleRows) + 1) * rowHeight + scrollBarHeight
  }
}

const KeyValueAutocompleteCell = {
  editor: KeyValueAutocompleteEditor,
  renderer: (
    /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
    instance: any,
    td: HTMLElement,
    row: number,
    col: number,
    prop: string | number,
    /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
    value: any,
    cellProperties: Handsontable.GridSettings,
  ): void => {
    if (value !== undefined && value !== null) {
      const md =
        instance.getSourceDataAtRow(row)[(KeyValueAutocompleteEditor as any).prototype.metadataProp]
      if (md) {
        const hint = md[prop]
        if (hint !== undefined) {
          value = hint.code
        }
      }
    }

    Handsontable.renderers.AutocompleteRenderer(instance, td, row, col, prop, value, cellProperties)
  },
}

export default KeyValueAutocompleteCell
