import { MutableRefObject, useEffect } from 'react'
import { batch, useDispatch } from 'react-redux'
import {
  clearLineItemsClipboard,
  resetHighlightedBoxes,
  setActiveFieldBoxId,
  setActiveLineItemIds,
} from '@src/redux-features/document_editor'

const useRowBoxOutsideClicks = (
  containerRef: MutableRefObject<HTMLDivElement | null>,
  clearClipboard = true,
): void => {
  const dispatch = useDispatch()

  // handles all clicks outside the row boxes and sets the active rows to empty
  useEffect(() => {
    const containerElement = containerRef.current
    const handleMouseDown = (evt: MouseEvent): void => {
      const soaMainTable = document.getElementById('soa-main-table')
      // TODO: perhaps use XPATH. E.g.: '//*[@id="extract-table-box"]//*[contains(@class, "ht_master")]//table'
      const extractMainTable = document.getElementById('extract-table-box')

      if (
        containerElement?.contains(evt.target! as HTMLElement) &&
        !soaMainTable?.contains(evt.target! as HTMLElement) &&
        !extractMainTable?.contains(evt.target! as HTMLElement)
      ) {
        batch(() => {
          dispatch(setActiveFieldBoxId(null))
          dispatch(setActiveLineItemIds([]))
          if (clearClipboard) {
            dispatch(clearLineItemsClipboard())
          }
          dispatch(resetHighlightedBoxes())
        })
      }
    }
    containerElement?.addEventListener('mousedown', handleMouseDown)

    return () => {
      containerElement?.removeEventListener('mousedown', handleMouseDown)
    }
  }, [clearClipboard, containerRef, dispatch])
}

export default useRowBoxOutsideClicks
