import { Box, Button, Grid, Typography } from '@material-ui/core'
import { ApiPartnerInterface, CompanyNode } from '@src/graphql/types'
import { formatMaybeApolloError } from '@src/utils/errors'
import { useDialog } from 'muibox'
import { FunctionComponent, useCallback, useState } from 'react'
import CompanySelect from '../utils/CompanySelect'

type Props = {
  enabledCompanies: CompanyNode[]
  enabledApiPartners?: ApiPartnerInterface[]
  databaseName: string
  syncDatabaseData: (apiPartnerIds: string[] | null | undefined) => Promise<void>
}

const DataSyncOptions: FunctionComponent<Props> = ({
  enabledCompanies,
  enabledApiPartners,
  databaseName,
  syncDatabaseData,
}) => {
  const [selectedCompany, setSelectedCompany] = useState(null as null | CompanyNode)
  const [selectedApiPartners, setSelectedApiPartners] = useState(
    null as null | ApiPartnerInterface[],
  )
  const [isSyncing, setIsSyncing] = useState(false)

  const dialog = useDialog()

  const triggerSync = useCallback(async (): Promise<void> => {
    setIsSyncing(true)
    try {
      try {
        await syncDatabaseData(selectedApiPartners?.map((apiPartner) => apiPartner.id))
      } catch (e) {
        await dialog.alert({
          title: `Error during ${databaseName} sync`,
          message: (
            <>
              <Typography>
                The following error was encountered while syncing the database
              </Typography>
              <blockquote>{formatMaybeApolloError(e)}</blockquote>
            </>
          ),
        })
        return
      }
      await dialog.alert({
        title: `${databaseName} sync successful`,
        message: `Successfully synchronized ${databaseName} DB`,
      })
    } finally {
      setIsSyncing(false)
    }
  }, [selectedApiPartners, dialog, syncDatabaseData, databaseName])

  return (
    <Box py={1}>
      <Grid container spacing={1} alignItems='center'>
        <CompanySelect
          enabledCompanies={enabledCompanies}
          enabledApiPartners={enabledApiPartners}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          selectedApiPartners={selectedApiPartners}
          setSelectedApiPartners={setSelectedApiPartners}
          item
          xs={12}
          md={9}
        />
        <Grid item xs={12} md={3}>
          <Button
            fullWidth
            disabled={isSyncing}
            onClick={triggerSync}
            variant='outlined'
            color='primary'
          >
            {isSyncing ? 'Syncing...' : `Trigger ${databaseName} Sync`}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DataSyncOptions
