import { gql } from '@apollo/client'

export const EDIT_OWN_PROFILE = gql`
  mutation editUserProfile($name: String!, $metabaseDashboardId: String!) {
    editOwnProfile(name: $name, metabaseDashboardId: $metabaseDashboardId) {
      ok
    }
  }
`

export const CREATE_USER = gql`
  mutation createUser($inputUser: InputUser!) {
    createUser(inputUser: $inputUser)
  }
`

export const UPDATE_USER = gql`
  mutation updateUser($inputUser: InputUser!, $auth0Id: String!) {
    updateUser(inputUser: $inputUser, auth0Id: $auth0Id)
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($auth0Id: String!) {
    deleteUser(auth0Id: $auth0Id)
  }
`

export const RESET_USER_PASSWORD = gql`
  mutation resetUserPassword($auth0Id: String!) {
    resetUserPassword(auth0Id: $auth0Id)
  }
`

export const UPDATE_DASHBOARD_COMPANY_USER_IAM = gql`
  mutation updateDashboardCompanyUserIam($inputUser: InputUser!, $companyId: UUID)
  @api(name: beholder) {
    updateDashboardCompanyUserIam(inputUser: $inputUser, companyId: $companyId)
  }
`

export const BULK_UPDATE_USER_DASHBOARD_WHITELIST = gql`
  mutation bulkUpdateUserDashboardWhitelist(
    $defaultDashboardWhitelist: [String!]!
    $userEmails: [String!]!
  ) @api(name: beholder) {
    bulkUpdateUserDashboardWhitelist(
      defaultDashboardWhitelist: $defaultDashboardWhitelist
      userEmails: $userEmails
    )
  }
`
