import { JobNode } from '@src/graphql/types'
import { getFieldGroupsFromJob } from '@src/utils/job'

const standard_prefix_re = /^standard_[A-Za-z0-9]+_/
const uuid_suffix_re = /_[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
/**
 *
 * Clean field prefix like standard_po_hs_code to -> hs_code
 *
 */
const cleanJobKey = (key: string): string => {
  return key.replace(standard_prefix_re, '').replace(uuid_suffix_re, '')
}

/**
 * do a field group key <> value mapping for jobs
 * so we dont serialize the whole job on export
 */
export const formatStandardJobJSON = (
  job: JobNode,
): Record<string, string | Record<string, string>[]> => {
  const docFieldGroups = getFieldGroupsFromJob(job)
  const jsonFields = {} as Record<string, string | Record<string, string>[]>
  for (const docFieldGroup of docFieldGroups) {
    let fieldGroupKey = docFieldGroup.fieldGroup!.key
    fieldGroupKey = cleanJobKey(fieldGroupKey)
    if (!docFieldGroup.fieldGroup?.repeatable) {
      const documentField = docFieldGroup.documentFields!.edges[0]!.node!
      jsonFields[fieldGroupKey] = documentField.field!.valueExportMapping
        ? JSON.parse(documentField.field!.valueExportMapping)[documentField.value] ||
          documentField.value
        : documentField.value
    } else {
      if (!jsonFields[fieldGroupKey]) {
        jsonFields[fieldGroupKey] = []
      }
      const currLineItemMapping = {} as Record<string, string>
      const docFields = docFieldGroup.documentFields?.edges.map((edge) => edge!.node!) ?? []
      for (const docField of docFields) {
        const subFieldKey = cleanJobKey(docField?.field?.key ?? '')
        currLineItemMapping[subFieldKey] = docField.field!.valueExportMapping
          ? JSON.parse(docField.field!.valueExportMapping)[docField.value] || docField.value
          : docField.value
      }
      ;(jsonFields[fieldGroupKey] as Record<string, string>[]).push(currLineItemMapping)
    }
  }
  return jsonFields
}
