/* eslint-disable @typescript-eslint/no-var-requires */
import { FunctionComponent } from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import {
  DocumentFieldGroupNode,
  DocumentTableNode,
  InputDocumentTable,
  JobTemplateReconType,
  InputReconPremiumRate,
  MatchingCriteriaType,
} from '@src/graphql/types'
import ReconciliationReviewDefault from './ReconciliationReviewDefault'
import ReconciliationReviewPremiumRate from './ReconciliationReviewPremiumRate'
import CenteredCircularProgress from '../centered-circular-progress/CenteredCircularProgress'

type Props = {
  reconTables: DocumentTableNode[]
  documentFieldGroups: DocumentFieldGroupNode[]
  isPremiumRate: boolean
  reconcile: (
    lumpsum: InputDocumentTable | null,
    premiumRateDetails: InputReconPremiumRate | null,
    overrideChargeDescription?: boolean,
    disableSendDueDate?: boolean,
    matchingCriteria?: MatchingCriteriaType,
  ) => Promise<void>
  reconType: JobTemplateReconType.Ap | JobTemplateReconType.ArrivalNotice
  isLoading: boolean
  cargowiseModule: string
}

const ReconciliationReview: FunctionComponent<Props> = ({
  documentFieldGroups,
  isPremiumRate,
  reconTables,
  reconcile,
  reconType,
  isLoading,
  cargowiseModule,
}) => {
  return (
    <>
      <DialogTitle disableTypography>
        <Typography variant='h6'>Review Data to Reconcile Against CW</Typography>
      </DialogTitle>
      <DialogContent data-testid='reconcile-data-review'>
        {isLoading ? (
          <CenteredCircularProgress />
        ) : isPremiumRate ? (
          <ReconciliationReviewPremiumRate
            documentFieldGroups={documentFieldGroups}
            reconcile={reconcile}
            reconTables={reconTables}
          />
        ) : (
          <ReconciliationReviewDefault
            reconcile={reconcile}
            reconTables={reconTables}
            reconType={reconType}
            cargowiseModule={cargowiseModule}
          />
        )}
      </DialogContent>
    </>
  )
}

export default ReconciliationReview
