import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import theme from '@src/utils/theme'
import { useMutation } from '@apollo/client'
import { Mutation, MutationDeleteTaxArgs, TaxNode } from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import { DELETE_TAX } from '@src/graphql/mutations/recon'

type Props = {
  currTax: TaxNode
  setOpen: (open: boolean) => void
  resetMappingState: () => void
}

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

const DeleteTaxModal: FunctionComponent<Props> = ({
  currTax,
  setOpen,
  resetMappingState,
}: Props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [deleteTax] = useMutation<Pick<Mutation, 'deleteTax'>, MutationDeleteTaxArgs>(DELETE_TAX, {
    onCompleted: () => {
      enqueueSnackbar('Tax code deleted', { variant: 'success' })
      resetMappingState()
      closeDialog()
    },
    onError: (error) => {
      enqueueSnackbar(`Error deleting tax code: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const deleteCurrentTax = async (): Promise<void> => {
    await deleteTax({
      variables: { taxId: currTax.id },
    })
  }

  const closeDialog = (): void => {
    setOpen(false)
  }

  return (
    <Dialog classes={{ paper: classes.dialog }} open={true} data-testid='delete-tax-modal'>
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={closeDialog} data-testid='close-btn' />
      </Box>
      <DialogTitle>Delete {currTax?.taxCode ?? ''} from this Cargowise Configuration</DialogTitle>
      <DialogContent>
        <Typography>
          Once deleted, you will no longer be able to modify the details nor add charge codes to
          this tax entry, but the association between it and its current charge codes will remain.
        </Typography>
        <Box display='flex' justifyContent='center' p={3}>
          <Box m={3}>
            <Button onClick={closeDialog} variant='contained'>
              Cancel
            </Button>
          </Box>
          <Box m={3}>
            <Button onClick={deleteCurrentTax} variant='contained' color='primary'>
              Delete
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteTaxModal
