export const endsWithAny = (suffixes: string[], string: string): boolean => {
  return suffixes.some((suffix) => {
    return string.endsWith(suffix)
  })
}

export const cleanNonAlphaNumeric = (string: string | null): string => {
  if (string === null) {
    return ''
  }
  return string.replace(/[^a-zA-Z0-9]/g, '')
}

export const toTitleCase = (str: string): string =>
  str.replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())

// stringifyList(['a', 'b','c']) = 'a, b, and c.'
export const stringifyList = (arr: string[]): string => {
  if (arr.length <= 1) {
    return arr[0] + '.'
  }
  return arr.slice(0, arr.length - 1).join(', ') + ', and ' + arr[arr.length - 1] + '.'
}
