/*
    Component that helps render an indeterminant progress bar Dialog when uploading is in progress
 */

import {
  makeStyles,
  Dialog,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
  LinearProgress,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { FunctionComponent } from 'react'
import { EDocsDataTargetType } from '@src/graphql/types'
import theme from '@src/utils/theme'

const useStyles = makeStyles({
  progressBar: {
    height: theme.spacing(2),
  },
})

type Props = {
  dataTargetType: EDocsDataTargetType
  setUploadInProgress: (inProgress: boolean) => void
  totalNumbers: number
  totalPushed?: number
}

const UploadingProgressBar: FunctionComponent<Props> = ({
  dataTargetType,
  setUploadInProgress,
  totalNumbers,
  totalPushed,
}) => {
  const classes = useStyles()
  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h3'>Uploading Files..</Typography>
          <IconButton onClick={() => setUploadInProgress(false)} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <LinearProgress className={classes.progressBar} />
          {!!totalPushed && (
            <Typography align='left'>{`${totalPushed} / ${totalNumbers} ${
              dataTargetType === EDocsDataTargetType.Shipment ? 'shipments' : 'invoices'
            }...`}</Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default UploadingProgressBar
