/* eslint-disable @typescript-eslint/no-var-requires */
import { FunctionComponent, RefObject, useEffect, useRef, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { HotTable } from '@handsontable/react'
import { useSnackbar } from 'notistack'

import {
  AnReconAutofillKey,
  InputDocumentTable,
  DocumentTableNode,
  DocumentFieldGroupNode,
  InputReconPremiumRate,
  MatchingCriteriaType,
} from '@src/graphql/types'
import ReadOnlyDocumentHotTable from '../read-only-document-hot-table/ReadOnlyDocumentHotTable'

type Props = {
  documentFieldGroups: DocumentFieldGroupNode[]
  reconcile: (
    lumpsum: InputDocumentTable | null,
    premiumRateDetails: InputReconPremiumRate | null,
    overrideChargeDescription?: boolean,
    disableSendDueDate?: boolean,
    matchingCriteria?: MatchingCriteriaType,
  ) => Promise<void>
  reconTables: DocumentTableNode[]
}

const PREMIUM_RATE_COLUMNS_HEADERS = [
  'Premium Rate',
  'Qty',
  'Accessorial Fee',
  'Lumpsum Premium Rate',
]
// field names
const PREMIUM_RATE_COLUMNS = [
  { data: AnReconAutofillKey.PremiumRate.toLowerCase(), type: 'numeric' },
  { data: AnReconAutofillKey.PremiumRateContainerQty.toLowerCase(), type: 'numeric' },
  { data: AnReconAutofillKey.PremiumTotalAccessorialFees.toLowerCase(), type: 'numeric' },
  { data: 'lumpsum', readOnly: true, type: 'numeric' },
]

const ReconciliationReviewPremiumRate: FunctionComponent<Props> = ({
  reconcile,
  reconTables,
  documentFieldGroups,
}) => {
  const premiumRateHotTableRef = useRef<HotTable>()
  const invoiceTotalHotTableRef = useRef<HotTable>()
  const { enqueueSnackbar } = useSnackbar()
  const [reconciling, setReconciling] = useState(false)
  const [initInvoiceTotal, setInitInvoiceTotal] = useState(0.0 as number)

  const handleReconcile = async (): Promise<void> => {
    setReconciling(true)
    if (!premiumRateHotTableRef.current || !invoiceTotalHotTableRef.current) return
    const [premiumRate, qty, accessorialFee, _] =
      premiumRateHotTableRef!.current!.hotInstance.getData()[0]
    const [invoiceTotal] = invoiceTotalHotTableRef!.current!.hotInstance.getData()[0]
    setInitInvoiceTotal(invoiceTotal)
    const premiumRateDetails = {
      premiumRate: parseFloat(premiumRate),
      quantity: parseFloat(qty),
      accessorialFee: parseFloat(accessorialFee),
      invoiceTotal: parseFloat(invoiceTotal),
    }

    if (Object.values(premiumRateDetails).find((detail) => isNaN(detail)) !== undefined) {
      enqueueSnackbar(`Premium rate details must all be numbers`, { variant: 'error' })
    } else {
      await reconcile(null, premiumRateDetails as InputReconPremiumRate)
    }
    setReconciling(false)
  }

  const chargesTable = reconTables.find(
    (reconTable) =>
      reconTable.fieldGroup?.autofillKey === AnReconAutofillKey.ChargeLineItem.toLowerCase(),
  )

  useEffect(() => {
    const newInvoiceTotal =
      chargesTable?.documentFieldGroups?.edges
        .map((documentFieldGroupEdge) =>
          documentFieldGroupEdge!.node!.documentFields!.edges.reduce(
            (acc, documentFieldEdge) => {
              acc[documentFieldEdge!.node!.field!.autofillKey] = documentFieldEdge!.node!.value
              return acc
            },
            {} as Record<string, string>,
          ),
        )
        ?.reduce((sum, documentFieldGroupsMap) => {
          if (documentFieldGroupsMap) {
            const totalAmount = documentFieldGroupsMap[AnReconAutofillKey.TotalAmount.toLowerCase()]
            sum += parseFloat(totalAmount)
          }
          return sum
        }, 0.0) ?? 0.0
    setInitInvoiceTotal(newInvoiceTotal)
  }, [chargesTable])

  useEffect(() => {
    const premiumFieldKeys = PREMIUM_RATE_COLUMNS.map((col) => col.data)
    const premiumRateFieldValues = documentFieldGroups.reduce(
      (acc, documentFieldGroup) => {
        const fieldAutofillKey =
          documentFieldGroup.fieldGroup!.fields!.edges[0]!.node!.autofillKey || ''
        if (
          !documentFieldGroup.fieldGroup!.repeatable &&
          premiumFieldKeys.includes(fieldAutofillKey)
        ) {
          acc[fieldAutofillKey] = parseFloat(
            documentFieldGroup.documentFields!.edges[0]!.node!.value || '0',
          )
        }
        return acc
      },
      {} as Record<string, number>,
    )
    const premiumRateLumpsum =
      premiumRateFieldValues[AnReconAutofillKey.PremiumRate.toLowerCase()] *
        premiumRateFieldValues[AnReconAutofillKey.PremiumRateContainerQty.toLowerCase()] +
      premiumRateFieldValues[AnReconAutofillKey.PremiumTotalAccessorialFees.toLowerCase()]
    if (premiumRateHotTableRef.current) {
      premiumRateHotTableRef.current.hotInstance.loadData([
        {
          ...premiumRateFieldValues,
          lumpsum: premiumRateLumpsum,
        },
      ])
    }
  }, [documentFieldGroups])

  const updatePremiumRateLumpsum = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _: [number, string | number, any, any][],
    source: string,
  ): void => {
    if (source === 'loadData') return
    if (premiumRateHotTableRef.current) {
      const [premiumRate, qty, accessorialFee, _] =
        premiumRateHotTableRef.current.hotInstance.getData()[0]
      const lumpsum = parseFloat(premiumRate) * parseFloat(qty) + parseFloat(accessorialFee)
      premiumRateHotTableRef.current.hotInstance.loadData([
        {
          [AnReconAutofillKey.PremiumRate.toLowerCase()]: premiumRate,
          [AnReconAutofillKey.PremiumRateContainerQty.toLowerCase()]: qty,
          [AnReconAutofillKey.PremiumTotalAccessorialFees.toLowerCase()]: accessorialFee,
          lumpsum,
        },
      ])
    }
  }

  return (
    <>
      <Box mb={1}>
        <Typography variant='subtitle1' gutterBottom>
          Invoice Data - Line Items
        </Typography>
        {chargesTable && (
          <ReadOnlyDocumentHotTable documentTable={chargesTable} showChargeDescription={true} />
        )}
      </Box>
      <Typography variant='subtitle1' gutterBottom>
        Invoice Total
      </Typography>
      <HotTable
        id='invoice-total-table'
        ref={invoiceTotalHotTableRef as RefObject<HotTable>}
        style={{ width: '100%', overflow: 'hidden', height: '60px' }}
        data={[[initInvoiceTotal]]}
        rowHeaders
        colHeaders={['Invoice Total']}
        stretchH='all'
        autoRowSize={true}
      />
      <Typography variant='subtitle1' gutterBottom>
        Expected Premium Rate
      </Typography>
      <HotTable
        id='premium-rate-table'
        ref={premiumRateHotTableRef as RefObject<HotTable>}
        style={{ width: '100%', overflow: 'hidden', height: '60px' }}
        rowHeaders
        colHeaders={PREMIUM_RATE_COLUMNS_HEADERS}
        columns={PREMIUM_RATE_COLUMNS}
        stretchH='all'
        afterChange={updatePremiumRateLumpsum}
        autoRowSize={true}
      />
      <Box textAlign='center' p={2}>
        <Button
          id='recon-button'
          variant='contained'
          color='primary'
          onClick={handleReconcile}
          disabled={reconciling}
        >
          Reconcile
        </Button>
      </Box>
    </>
  )
}

export default ReconciliationReviewPremiumRate
