import { gql } from '@apollo/client'

export const EDIT_COMPANY = gql`
  mutation editCompany($editCompanyRequest: EditCompanyRequest!) {
    editCompany(editCompanyRequest: $editCompanyRequest) {
      id
    }
  }
`

export const DELETE_COMPANY = gql`
  mutation deleteCompany($companyId: String!) {
    deleteCompany(companyId: $companyId) {
      ok
    }
  }
`

export const CREATE_COMPANY = gql`
  mutation createCompany($name: String!) {
    createCompany(name: $name) {
      company {
        id
      }
    }
  }
`
