import produce from 'immer'
import { DocumentEditorState } from './document_editor_state'
import { PageFieldEditorState } from './page_field_editor'

const deleteFilePageFromState = (state: DocumentEditorState, filePageId: string): void => {
  if (state.pageFieldEditorState?.pages[filePageId]) {
    const newState = produce(state.pageFieldEditorState, (draftState: PageFieldEditorState) => {
      delete draftState!.pages[filePageId]
    })
    Object.assign(state.pageFieldEditorState, newState)
  }
}
export default deleteFilePageFromState
