import { FunctionComponent, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { JobTemplateReconType, MatchingCriteriaType } from '@src/graphql/types'
import useTMSJobData from '@src/hooks/useTMSJobData'
import useValidateChargeCodes from '@src/hooks/charge_codes/use_validate_charge_codes'
import { useSnackbar } from 'notistack'
import { isCargowiseConfig } from '@src/utils/api_partner'

const useStyles = makeStyles({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

type Props = {
  jobId: string
  overrideChargeDescription: boolean
  setOverrideChargeDescription: (flag: boolean) => void
  disableSendDueDate: boolean
  setDisableSendDueDate: (flag: boolean) => void
  reconcileMatchCriteria: MatchingCriteriaType
  toggleReconcileMatchCriteria: (matchCriteria: MatchingCriteriaType) => void
  reconAsLumpsum: boolean
  setReconAsLumpsum: (flag: boolean) => void
  reconcileSoa: () => void
  closeDialog: () => void
}

const SoaReconOptionsDialog: FunctionComponent<Props> = ({
  jobId,
  overrideChargeDescription,
  setOverrideChargeDescription,
  disableSendDueDate,
  setDisableSendDueDate,
  reconcileMatchCriteria,
  toggleReconcileMatchCriteria,
  reconAsLumpsum,
  setReconAsLumpsum,
  reconcileSoa,
  closeDialog,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const reconType = JobTemplateReconType.Soa
  const { job, jobDataLoading, documentFieldGroups, jobTable } = useTMSJobData(jobId, reconType)
  const isCargowiseConfigMemo = useMemo(
    () => isCargowiseConfig(job?.jobTemplate?.apiPartner),
    [job?.jobTemplate?.apiPartner],
  )

  const { isValidatingChargeCodes, invalidDocChargeCodes, noJobChargeVendor, noJobChargeCodes } =
    useValidateChargeCodes(
      job?.jobTemplate ?? null,
      documentFieldGroups,
      [],
      jobTable,
      reconType,
      jobDataLoading,
    )

  if (!isValidatingChargeCodes && invalidDocChargeCodes.length > 0) {
    enqueueSnackbar('Invalid charge codes found. Please correct then retry.', { variant: 'error' })
    closeDialog()
  } else if (!isValidatingChargeCodes && noJobChargeVendor) {
    enqueueSnackbar('No charge vendor was found. Please enter a vendor.', { variant: 'error' })
    closeDialog()
  } else if (!isValidatingChargeCodes && noJobChargeCodes) {
    enqueueSnackbar('No charge codes found. Please enter charge codes into table.', {
      variant: 'error',
    })
    closeDialog()
  }

  return (
    <Dialog classes={{ paper: classes.dialog }} open={true}>
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={closeDialog} data-testid='close-btn' />
      </Box>
      <DialogTitle>Review options when reconciling SOA</DialogTitle>
      <DialogContent>
        {isCargowiseConfigMemo && (
          <>
            <Box display='flex' alignItems='center'>
              <Checkbox
                checked={overrideChargeDescription}
                onChange={() => setOverrideChargeDescription(!overrideChargeDescription)}
                data-testid='override-checkbox'
              />
              <Typography>Override Charge Description in CW</Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <Checkbox
                checked={disableSendDueDate}
                onChange={() => setDisableSendDueDate(!disableSendDueDate)}
                data-testid='disable-send-due-date'
              />
              <Typography>Do NOT send Due Date</Typography>
            </Box>
          </>
        )}
        <Box display='flex' alignItems='center'>
          <Checkbox
            checked={reconAsLumpsum}
            onChange={() => setReconAsLumpsum(!reconAsLumpsum)}
            data-testid='lumpsum-checkbox'
          />
          <Typography>Reconcile as lumpsum</Typography>
        </Box>

        <Box display='flex' alignItems='center'>
          <Checkbox
            checked={reconcileMatchCriteria === MatchingCriteriaType.VendorInvoiceNumber}
            onChange={() => toggleReconcileMatchCriteria(MatchingCriteriaType.VendorInvoiceNumber)}
            data-testid='recon-criteria-toggle'
          />
          <Typography>
            Match by Vendor and Invoice Number Strictly (Missing Invoice Number <strong>NOT</strong>{' '}
            matched)
          </Typography>
        </Box>

        <Box display='flex' alignItems='center'>
          <Checkbox
            checked={reconcileMatchCriteria === MatchingCriteriaType.NonStrictVendorInvoiceNumber}
            onChange={() =>
              toggleReconcileMatchCriteria(MatchingCriteriaType.NonStrictVendorInvoiceNumber)
            }
            data-testid='recon-criteria-toggle'
          />
          <Typography>
            Match by Vendor and Invoice Number (Missing Invoice Number matched)
          </Typography>
        </Box>

        <Box display='flex' alignItems='center'>
          <Checkbox
            checked={reconcileMatchCriteria === MatchingCriteriaType.ChargeCodeVendorInvoiceNumber}
            onChange={() =>
              toggleReconcileMatchCriteria(MatchingCriteriaType.ChargeCodeVendorInvoiceNumber)
            }
            data-testid='recon-criteria-toggle'
          />
          <Typography>Match by Charge Code, Vendor, and Invoice Number</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isValidatingChargeCodes} onClick={reconcileSoa}>
          Reconcile
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SoaReconOptionsDialog
