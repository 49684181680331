import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { HotTable } from '@handsontable/react'
import { useRef, useEffect } from 'react'

import CloseIcon from '@material-ui/icons/Close'
import theme from '@src/utils/theme'
import { useMutation } from '@apollo/client'
import { Mutation, MutationDeleteChargeCodesArgs } from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import { DELETE_CHARGE_CODES } from '@src/graphql/mutations/recon'
import { ChargeCodeWithVendorArray } from './types'

type Props = {
  chargeCodes: ChargeCodeWithVendorArray
  setOpen: (open: boolean) => void
  resetMappingState: () => void
}

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

const columns = [
  {
    key: 'vendor',
    name: 'Vendor',
  },
  {
    key: 'description',
    name: 'Description',
  },
]

const DeleteChargeCodeModal: FunctionComponent<Props> = ({
  chargeCodes,
  setOpen,
  resetMappingState,
}: Props) => {
  const classes = useStyles()
  const [rows, setRows] = useState([] as string[][])
  const { enqueueSnackbar } = useSnackbar()
  const hotTableRef = useRef<HotTable>(null)
  const [deleteChargeCodes] = useMutation<
    Pick<Mutation, 'deleteChargeVendor'>,
    MutationDeleteChargeCodesArgs
  >(DELETE_CHARGE_CODES, {
    onCompleted: () => {
      enqueueSnackbar('Charge codes deleted', { variant: 'success' })
      resetMappingState()
      closeDialog()
    },
    onError: (error) => {
      enqueueSnackbar(`Error deleting charge codes: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const deleteCurrentChargeCodes = async (): Promise<void> => {
    await deleteChargeCodes({
      variables: { chargeCodeIds: chargeCodes.map((cc) => cc.id) },
    })
  }

  const closeDialog = (): void => {
    setOpen(false)
  }

  useEffect(() => {
    if (chargeCodes) {
      const newRows: string[][] = chargeCodes.map((chargeCode) => {
        return [chargeCode!.chargeVendor!.name, chargeCode.description]
      })
      setRows(newRows)
    }
  }, [chargeCodes])

  return (
    <Dialog classes={{ paper: classes.dialog }} open={true} data-testid='delete-charge-code-modal'>
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={closeDialog} data-testid='close-btn' />
      </Box>
      <DialogTitle>Delete {chargeCodes[0]?.code ?? ''} from multiple vendors</DialogTitle>
      <DialogContent>
        <Typography>The charge code will be deleted from the following vendors:</Typography>
        <HotTable
          id='vendor-code-table'
          ref={hotTableRef}
          style={{ width: '100%', overflow: 'hidden', height: '200px' }}
          data={rows}
          readOnly={true}
          rowHeaders
          contextMenu={['row_above', 'row_below', 'remove_row']}
          colHeaders={columns.map((c) => c.name)}
          columnSorting={true}
          columns={columns}
          stretchH='all'
          autoRowSize={true}
        />
        <Box display='flex' justifyContent='center' p={3}>
          <Box m={3}>
            <Button onClick={closeDialog} variant='contained'>
              Cancel
            </Button>
          </Box>
          <Box m={3}>
            <Button onClick={deleteCurrentChargeCodes} variant='contained' color='primary'>
              Delete
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteChargeCodeModal
