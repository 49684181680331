import { TaskNode, TaskStatus, JobNodeEdge, Maybe, ManualProduct } from '@src/graphql/types'
import { JOB_TASK_STATUS_MAPPING } from './app_constants'

export const getJobCountByStatus = (task: TaskNode): Record<string, number> => {
  const nodesByStatus: Record<string, number> = {}
  for (const status of Object.values(TaskStatus)) {
    nodesByStatus[status] = 0
  }
  task?.jobs?.edges.forEach((job) => {
    const jobStatus = job!.node!.status
    nodesByStatus[jobStatus] += 1
  })
  return nodesByStatus
}

/**
 * Returns jobs with statuses after the given status (i.e. prior to the given status
 * in the usual processing pipeline)
 */
export const getJobsBeforeStatus = (task: TaskNode, status: TaskStatus): JobNodeEdge[] => {
  const taskStatusList = Object.keys(JOB_TASK_STATUS_MAPPING)
  const taskStatusIdx = taskStatusList.indexOf(status)
  const jobStatusesBefore = Object.values(JOB_TASK_STATUS_MAPPING).slice(0, taskStatusIdx)
  const jobsBefore = task.jobs!.edges.filter((job: Maybe<JobNodeEdge>) =>
    jobStatusesBefore.includes(job!.node!.status),
  )
  return jobsBefore as JobNodeEdge[]
}

export const validateJobsForBulkUpdate = (jobs: JobNodeEdge[], status: TaskStatus): void => {
  const productJobs = jobs.filter((job) => job.node?.manualProduct === ManualProduct.Product)
  if (productJobs.length === jobs.length) {
    throw new Error('Cannot update status of jobs processed in the product')
  }

  const jobStatusList = Object.values(JOB_TASK_STATUS_MAPPING)
  const jobStatusIdx = jobStatusList.indexOf(JOB_TASK_STATUS_MAPPING[status]!)
  if (
    !productJobs.every(
      (productJob) => jobStatusList.indexOf(productJob.node!.status) >= jobStatusIdx,
    )
  ) {
    throw new Error(
      `Cannot update status of product jobs that are not yet in ${JOB_TASK_STATUS_MAPPING[status]}`,
    )
  }
}
