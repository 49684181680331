import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useContext, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import { Box, GridListTile, GridList } from '@material-ui/core'
import theme from '@src/utils/theme'
import { clsx } from 'clsx'
import CloseIcon from '@material-ui/icons/Close'
import {
  FilePageNode,
  FilePageNodeEdge,
  Mutation,
  MutationCopyTableFromOtherDocumentArgs,
  Maybe,
  DocumentFieldNode,
  MutationCopyTableToDocumentsArgs,
  MutationCopyRowsToOtherDocumentsArgs,
} from '@src/graphql/types'
import { useMutation } from '@apollo/client'
import {
  COPY_TABLE_FROM_OTHER_DOCUMENT,
  COPY_TABLE_TO_DOCUMENTS,
  COPY_ROWS_TO_DOCUMENTS,
} from '@src/graphql/mutations/document'
import { useSnackbar } from 'notistack'
import { ShipmentFormContext } from '@src/contexts/shipment_form_context'
import { RootState } from '@src/utils/store'
import { MAGIC_GRID_DIMENSIONS } from '@src/utils/app_constants'
import { convertLineItemsToRowSkeleton, getDocumentTableSkeleton } from '@src/utils/document'
import { lineItemSelectors } from '@src/redux-features/document_editor/line_items_table'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { LogEventType } from '@src/utils/observability/LogEventType'

const tileBorderWidth = 1
const gridTileHeight = 400
const gridTileWidth = 275

const useStyles = makeStyles({
  buttonsFooter: {
    padding: theme.spacing(2),
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  selectableTile: {
    cursor: 'pointer',
    padding: theme.spacing(1),
  },
  selectableTileSelected: {
    border: `${tileBorderWidth}px solid ${theme.palette.primary.main}`,
  },
  selectableTileUnselected: {
    // so the image doesn't resize when selected/unselected due to the border
    border: `${tileBorderWidth}px solid transparent`,
  },
  tileImage: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: MAGIC_GRID_DIMENSIONS.BASE_Z_INDEX,
  },
  tableCanvas: {
    position: 'relative',
    zIndex: MAGIC_GRID_DIMENSIONS.ROW_Z_INDEX,
    width: gridTileWidth,
    height: gridTileHeight,
  },
  imgContainer: {
    width: gridTileWidth,
    height: gridTileHeight,
  },
})

type Props = {
  handleCloseDialog: () => void
  open: boolean
  copyRows: boolean
  copyToOther: boolean
  activeDocumentTableId: string
}

const CopyGridDialog: FunctionComponent<Props> = ({
  open,
  handleCloseDialog,
  copyRows,
  copyToOther,
  activeDocumentTableId,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedPages, setSelectedPages] = useState([] as FilePageNode[])
  const [copyTableFromOtherDocument] = useMutation<
    Pick<Mutation, 'copyTableFromOtherDocument'>,
    MutationCopyTableFromOtherDocumentArgs
  >(COPY_TABLE_FROM_OTHER_DOCUMENT)
  const [copyTableToDocuments] = useMutation<
    Pick<Mutation, 'copyTableToDocuments'>,
    MutationCopyTableToDocumentsArgs
  >(COPY_TABLE_TO_DOCUMENTS)
  const [copyRowsToDocuments] = useMutation<
    Pick<Mutation, 'copyRowsToOtherDocuments'>,
    MutationCopyRowsToOtherDocumentsArgs
  >(COPY_ROWS_TO_DOCUMENTS)

  const { logEvent } = useEventLogger()
  const { saveJob, refetchJob } = useContext(ShipmentFormContext)

  const activeFilePageId = useSelector(
    (state: RootState) => state.documentEditor.activeFilePageId as string,
  )
  const job = useSelector((state: RootState) => state.documentEditor.job)
  const otherFilePages = job!.filePages!.edges.filter(
    (filePageEdge: Maybe<FilePageNodeEdge>) => filePageEdge!.node!.id !== activeFilePageId,
  )
  const activeFilePage = job!.filePages!.edges.find(
    (filePageEdge: Maybe<FilePageNodeEdge>) => filePageEdge!.node!.id === activeFilePageId,
  )!.node
  const lineItems = useSelector(
    (state: RootState) => lineItemSelectors.selectAll(state.documentEditor)!,
  )

  // TODO: Why is this in the hard to test frontend rather than the easy to test backend?
  const copyGridToOtherPages = async (): Promise<void> => {
    const toDocIds = selectedPages.map((selectedPage) => selectedPage.document!.id)
    const tables =
      activeFilePage?.document?.documentTables?.edges.map((documentTableEdge) => {
        const documentTable = documentTableEdge!.node!
        const documentTableSkeleton = getDocumentTableSkeleton(documentTable)
        const useLineItems = documentTable.id !== activeDocumentTableId
        return useLineItems
          ? documentTableSkeleton
          : { ...documentTableSkeleton, rows: convertLineItemsToRowSkeleton(lineItems) }
      }) || []

    try {
      await saveJob()
      await copyTableToDocuments({
        variables: {
          tables,
          toDocIds,
        },
      })
      handleCloseDialog()
      await refetchJob()
      enqueueSnackbar('Successfully copied document tables', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Failed to copy document tables ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
  }

  const copyGridFromOtherPage = async (): Promise<void> => {
    const fromDocId = selectedPages[0]!.document!.id
    const toDocIds = [activeFilePage!.document!.id]
    try {
      await saveJob()
      await copyTableFromOtherDocument({
        variables: {
          fromDocId,
          toDocIds,
        },
      })
      handleCloseDialog()
      await refetchJob()
      enqueueSnackbar('Successfully copied document tables', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Failed to copy document tables: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
  }

  const copyRowsToOtherPages = async (): Promise<void> => {
    let success = false
    const fromDocId = activeFilePage!.document!.id
    const allToDocIds = selectedPages.map((selectedPage) => selectedPage.document!.id)
    const docTables =
      activeFilePage?.document?.documentTables?.edges.map((documentTableEdge) => {
        const documentTable = documentTableEdge!.node!
        const documentTableSkeleton = getDocumentTableSkeleton(documentTable)
        const useLineItems = documentTable.id !== activeDocumentTableId
        return useLineItems
          ? documentTableSkeleton
          : { ...documentTableSkeleton, rows: convertLineItemsToRowSkeleton(lineItems) }
      }) || []
    const tables = docTables.filter((docTable) => docTable.id === activeDocumentTableId)
    const allDocIds = otherFilePages.map((doc) => doc.node.document!.id)
    allDocIds.unshift(fromDocId)
    try {
      await saveJob()
      handleCloseDialog()
      enqueueSnackbar(`Started copying ${allToDocIds.length} document tables`, {
        variant: 'info',
      })
      // send in batches to avoid timeout and allow checking for ones completed
      const batchSize = 5
      let idx = 0
      while (idx < allToDocIds.length - batchSize) {
        const toDocIds = allToDocIds.slice(idx, idx + batchSize)
        await copyRowsToDocuments({
          variables: {
            fromDocId,
            tables,
            toDocIds,
            allDocIds,
          },
        })
        await refetchJob()
        idx += batchSize
        enqueueSnackbar(`Successfully copied ${idx} of ${allToDocIds.length} document tables`, {
          variant: 'info',
        })
      }
      // leftovers
      const toDocIds = allToDocIds.slice(idx)
      await copyRowsToDocuments({
        variables: {
          fromDocId,
          tables,
          toDocIds,
          allDocIds,
        },
      })
      await refetchJob()
      enqueueSnackbar('Successfully copied all document tables', { variant: 'success' })
      success = true
    } catch (error) {
      enqueueSnackbar(`Failed to copy document tables ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    } finally {
      selectedPages.map(
        (selectedPage) =>
          void logEvent(LogEventType.COPY_ROWS_TO_PAGE, {
            job_id: job?.id,
            source_page_id: fromDocId,
            target_page_id: selectedPage.document!.id,
            num_created_rows: selectedPage.document!.documentFieldGroups.edges.length,
            timestamp: new Date(),
            success: success,
          }),
      )
    }
  }

  const copyGridAction = copyRows
    ? copyRowsToOtherPages
    : copyToOther
    ? copyGridToOtherPages
    : copyGridFromOtherPage
  const togglePage = (filePageEdgeNode: FilePageNodeEdge): void => {
    let newSelectedPages = [...selectedPages]
    if (copyToOther) {
      const foundPageIdx = newSelectedPages.findIndex(
        (otherfilePageEdgeNode) => otherfilePageEdgeNode.id === filePageEdgeNode.node!.id,
      )
      if (foundPageIdx !== -1) {
        newSelectedPages.splice(foundPageIdx, 1)
      } else {
        newSelectedPages.push(filePageEdgeNode.node!)
      }
    } else {
      newSelectedPages = [filePageEdgeNode.node!]
    }
    setSelectedPages(newSelectedPages)
  }

  const drawTable = (filePageId: string): void => {
    const currFilePage = job!.filePages!.edges.find(
      (filePageEdge: Maybe<FilePageNodeEdge>) => filePageEdge!.node!.id === filePageId,
    )!.node
    const img = document.getElementById(filePageId) as HTMLImageElement
    const canvas = document.getElementById(`canvas-${filePageId}`) as HTMLCanvasElement
    const ctx = canvas.getContext('2d')
    if (ctx) {
      ctx.beginPath()
      // TODO: compute table dimensions w/grid and draw
      for (const docTable of currFilePage!.document!.documentTables!.edges) {
        let tableTop = 10 ** 1000
        let tableLeft = 10 ** 1000
        let tableRight = 0
        let tableBot = 0
        for (const docFieldGroup of docTable!.node!.documentFieldGroups!.edges) {
          for (const docField of docFieldGroup!.node!.documentFields!.edges) {
            const { left, top, width, height } = docField!.node as DocumentFieldNode
            tableTop = Math.min(tableTop, top ? top * img.height : tableTop * img.height)
            tableLeft = Math.min(tableLeft, left ? left * img.width : tableLeft * img.width)
            tableRight = Math.max(tableRight, ((left as number) + (width as number)) * img.width)
            tableBot = Math.max(tableBot, ((top as number) + (height as number)) * img.height)
          }
        }
        ctx.rect(tableLeft, tableTop, tableRight - tableLeft, tableBot - tableTop)
      }
      ctx.stroke()
    }
  }
  const dialogTitle = copyToOther ? 'Copy Grid to Other Pages' : 'Copy Grid from Other Page'
  return (
    <Dialog maxWidth='sm' open={open} onClose={handleCloseDialog} aria-labelledby='a'>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={handleCloseDialog} data-testid='close-btn' />
      </Box>
      <DialogContent>
        <GridList cellHeight={gridTileHeight}>
          {otherFilePages.map((filePageEdgeNode: Maybe<FilePageNodeEdge>) => (
            <GridListTile
              key={filePageEdgeNode!.node!.imageUrl}
              onClick={() => togglePage(filePageEdgeNode!)}
              className={classes.selectableTile}
            >
              <div className={classes.imgContainer}>
                <img
                  src={filePageEdgeNode!.node!.imageUrl ?? ''}
                  id={filePageEdgeNode!.node!.id}
                  onLoad={() => drawTable(filePageEdgeNode!.node!.id)}
                  alt='file page'
                  className={clsx(classes.tileImage, {
                    [classes.selectableTileSelected]: selectedPages.includes(
                      filePageEdgeNode!.node!,
                    ),
                    [classes.selectableTileUnselected]: !selectedPages.includes(
                      filePageEdgeNode!.node!,
                    ),
                  })}
                />
                <canvas
                  id={`canvas-${filePageEdgeNode!.node!.id}`}
                  className={classes.tableCanvas}
                  width='275'
                  height='400'
                />
              </div>
            </GridListTile>
          ))}
        </GridList>
      </DialogContent>
      <Box display='inline-flex' justifyContent='space-evenly' className={classes.buttonsFooter}>
        <Button variant='contained' onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button
          disabled={selectedPages.length === 0}
          variant='contained'
          color='primary'
          onClick={copyGridAction}
        >
          Copy Grid
        </Button>
      </Box>
    </Dialog>
  )
}

export default CopyGridDialog
