import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, MutableRefObject, useState } from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import theme from '@src/utils/theme'

import ActionButtons from './ActionButtons'
import ChargeCodesTable from './ChargeCodesTable'
import TaxMetadataTable from './TaxMetadataTable'
import TaxSearchBar from './TaxSearchBar'
import { useEffect } from 'react'
import {
  CompanyNode,
  Maybe,
  TaxNode,
  Mutation,
  MutationCreateTaxArgs,
  InputTaxDetails,
  MutationEditTaxArgs,
  Query,
} from '@src/graphql/types'
import { HotTable } from '@handsontable/react'
import { useRef } from 'react'
import { useMutation, useLazyQuery, useQuery } from '@apollo/client'
import { CREATE_TAX, UPDATE_TAX } from '@src/graphql/mutations/recon'
import { ALL_CHARGE_CODES_BY_COMPANY, UNIQUE_CHARGE_CODES_BY_TAX } from '@src/graphql/queries/recon'
import DeleteTaxModal from './DeleteTaxModal'
import CreateTaxModal from './CreateTaxModal'
import CreateButtons from './CreateButtons'
import CancelCreateChargeCodeModal from './CancelCreateChargeCodeModal'

import { useSnackbar } from 'notistack'
import TaxMappingHeader from './TaxMappingHeader'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import useCompanyData from '@src/hooks/admin/useCompanyData'

type Props = {
  companyId: string
  company: Maybe<CompanyNode>
  refreshSearch: boolean
  setRefreshSearch: (toggle: boolean) => void
}

const TaxMapping: FunctionComponent<Props> = ({
  company,
  companyId,
  refreshSearch,
  setRefreshSearch,
}) => {
  const [currTax, setCurrTax] = useState(null as null | TaxNode)
  const [currTaxDetails, setCurrTaxDetails] = useState(
    {} as Record<string, string | number | null | undefined>,
  )
  const [chargeCodes, setChargeCodes] = useState([] as string[])
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [newTaxModalOpen, setNewTaxModalOpen] = useState(false)
  const [createNewTaxMode, setCreateNewTaxMode] = useState(false)
  const [cancelCreateNewTaxModalOpen, setCancelCreateNewTaxModalOpen] = useState(false)

  const { apiPartners } = useCompanyData(company)
  const [currApiPartnerId, setCurrApiPartnerId] = useState<string | null>(null)

  const [placeholderChargeCodeData] = useState([...Array(10).keys()].map(() => '') as string[])
  const { enqueueSnackbar } = useSnackbar()
  const hotTableRef = useRef<HotTable>()
  const [updateTaxDetails] = useMutation<Pick<Mutation, 'editChargeVendor'>, MutationEditTaxArgs>(
    UPDATE_TAX,
    {
      onCompleted: () => {
        setRefreshSearch(true)
        enqueueSnackbar('Tax details updated', { variant: 'success' })
      },
      onError: (error) => {
        enqueueSnackbar(`Error updating tax details: ${formatMaybeApolloError(error)}`, {
          variant: 'error',
        })
      },
    },
  )

  const [autoCompleteSuggestions, setAutoCompleteSuggestions] = useState([] as string[])
  const { loading: loadingCompanyChargeCodes } = useQuery<Pick<Query, 'allCompanyChargeCodes'>>(
    ALL_CHARGE_CODES_BY_COMPANY,
    {
      fetchPolicy: 'network-only',
      variables: {
        companyId,
        apiPartnerId: currApiPartnerId,
      },
      onCompleted: (data) => {
        const codes = data.allCompanyChargeCodes as string[]
        setAutoCompleteSuggestions(codes as string[])
      },
      skip: !currApiPartnerId,
    },
  )

  const [getUniqueChargeCodesByTax] = useLazyQuery<Pick<Query, 'uniqueChargeCodesByTax'>>(
    UNIQUE_CHARGE_CODES_BY_TAX,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const uniqueChargeCodes = data.uniqueChargeCodesByTax as string[]
        setChargeCodes(uniqueChargeCodes.length > 0 ? uniqueChargeCodes : [''])
      },
    },
  )

  const resetMappingState = (): void => {
    setCreateNewTaxMode(false)
    setCurrTax(null)
    setCurrTaxDetails({ taxCode: null, taxRate: null, taxType: null, taxSystem: null })
    setRefreshSearch(true)
  }

  const toggleCreateNewTaxMode = (val: boolean): void => {
    resetMappingState()
    setCreateNewTaxMode(val)
  }

  const setTaxFromSearchResult = (tax: TaxNode): void => {
    // don't allow code to be altered if in create mode
    if (createNewTaxMode) {
      return
    }
    setCurrTax(tax)
    void getUniqueChargeCodesByTax({
      variables: {
        taxId: tax.id,
        apiPartnerId: currApiPartnerId,
      },
    })
  }

  const [createTax] = useMutation<Pick<Mutation, 'createTax'>, MutationCreateTaxArgs>(CREATE_TAX, {
    onCompleted: () => {
      resetMappingState()
      enqueueSnackbar('Tax created', { variant: 'success' })
    },
    onError: (error) => {
      enqueueSnackbar(`Error creating tax: ${formatMaybeApolloError(error)}`, { variant: 'error' })
    },
  })

  useEffect(() => {
    setCurrTax(null)
  }, [currApiPartnerId])

  useEffect(() => {
    if (currTax) {
      const { taxCode, taxRate, taxType, taxSystem } = currTax
      setCurrTaxDetails({ taxCode, taxRate, taxType, taxSystem })
      return
    }
    setCurrTaxDetails({ taxCode: null, taxRate: null, taxType: null, taxSystem: null })
  }, [currTax])

  const isValidChargeCodes = (chargeCodes: Array<string>): boolean => {
    return chargeCodes.every((code) => {
      if (!autoCompleteSuggestions.includes(code)) {
        enqueueSnackbar(
          `Error creating tax: Charge code ${code} does not exist in this company. Create the charge code first.`,
          {
            variant: 'error',
          },
        )
        return false
      }
      return true
    })
  }

  const constructChargeCodesFromTable = (): Array<string> => {
    const tableData = hotTableRef.current?.hotInstance.getData() as string[][]
    return tableData.filter(([code]) => code).map(([code]) => code)
  }

  const createNewTax = async (): Promise<void> => {
    const chargeCodes = constructChargeCodesFromTable() as Array<string>
    if (isValidChargeCodes(chargeCodes)) {
      const taxDetails = {
        companyId,
        taxCode: currTaxDetails?.taxCode ?? '',
        taxRate: Number(currTaxDetails?.taxRate) ?? 0,
        taxType: currTaxDetails?.taxType,
        taxSystem: currTaxDetails?.taxSystem,
        apiPartnerId: currApiPartnerId,
        chargeCodes: chargeCodes,
      } as InputTaxDetails
      await createTax({
        variables: { taxDetails },
      })
    }
  }

  const saveTaxDetails = async (): Promise<void> => {
    const chargeCodes = constructChargeCodesFromTable() as Array<string>
    if (isValidChargeCodes(chargeCodes)) {
      const taxDetails = {
        companyId,
        taxCode: currTaxDetails?.taxCode ?? '',
        taxRate: Number(currTaxDetails?.taxRate) ?? 0,
        taxType: currTaxDetails?.taxType,
        taxSystem: currTaxDetails?.taxSystem,
        apiPartnerId: currApiPartnerId,
        chargeCodes,
      } as InputTaxDetails
      const variables = {
        taxDetails,
        // button that calls saveTaxDetails() is disabled if
        // !currTax
        taxId: currTax!.id,
      }
      await updateTaxDetails({
        variables,
      })
    }
  }

  return (
    <Paper data-testid='tax-mapping'>
      <TaxMappingHeader newTax={() => setNewTaxModalOpen(true)} disabled={!currApiPartnerId} />
      <Box display='flex'>
        <Box
          width='25%'
          borderRight={`1px solid ${theme.palette.grey['500']}`}
          data-testid='tax-search-bar'
        >
          <TaxSearchBar
            refreshSearch={refreshSearch}
            setRefreshSearch={setRefreshSearch}
            apiPartnerId={currApiPartnerId}
            setTax={setTaxFromSearchResult}
          />
        </Box>
        <Box width='75%' bgcolor={theme.palette.grey[200]}>
          <Box minHeight='80vh'>
            <Box paddingBottom={1} data-testid='tax-metadata'>
              <TaxMetadataTable
                currApiPartnerId={currApiPartnerId}
                setCurrApiPartnerId={setCurrApiPartnerId}
                apiPartners={apiPartners}
                currTaxDetails={currTaxDetails}
                setCurrTaxDetails={setCurrTaxDetails}
              />
            </Box>
            {createNewTaxMode || (currTax && !loadingCompanyChargeCodes) ? (
              <ChargeCodesTable
                autoCompleteSuggestions={autoCompleteSuggestions}
                chargeCodes={createNewTaxMode ? placeholderChargeCodeData : chargeCodes}
                hotTableRef={hotTableRef as MutableRefObject<HotTable>}
              />
            ) : (
              <>
                {loadingCompanyChargeCodes ? (
                  <CenteredCircularProgress />
                ) : (
                  <Typography align='center'>Select a tax code to edit details</Typography>
                )}
              </>
            )}
            {createNewTaxMode ? (
              <CreateButtons
                create={createNewTax}
                cancel={() => setCancelCreateNewTaxModalOpen(true)}
                disabled={!currApiPartnerId}
              />
            ) : (
              <ActionButtons
                disabled={!currTax}
                save={saveTaxDetails}
                del={() => setDeleteModalOpen(true)}
              />
            )}
          </Box>
        </Box>
      </Box>
      {deleteModalOpen && (
        <DeleteTaxModal
          setOpen={setDeleteModalOpen}
          currTax={currTax!}
          resetMappingState={resetMappingState}
        />
      )}
      {newTaxModalOpen && (
        <CreateTaxModal setOpen={setNewTaxModalOpen} confirmCreate={toggleCreateNewTaxMode} />
      )}
      {cancelCreateNewTaxModalOpen && (
        <CancelCreateChargeCodeModal
          setOpen={setCancelCreateNewTaxModalOpen}
          cancelCreateNewVendor={() => setCreateNewTaxMode(false)}
        />
      )}
    </Paper>
  )
}

export default TaxMapping
