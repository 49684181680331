import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Moveable, { OnDragEnd, OnResizeEnd } from 'react-moveable'
import { v4 as uuidv4 } from 'uuid'
import { makeStyles } from '@material-ui/styles'
import { isPresent } from 'ts-is-present'

import { dragFieldBox, resizeFieldBox } from '@src/redux-features/document_editor'
import { lineItemSelectors } from '@src/redux-features/document_editor/line_items_table'
import { DEFAULT_BOX_DIMENSION, FIELD_BOX_STYLES } from '@src/utils/app_constants'
import { RootState } from '@src/utils/store'
import theme from '@src/utils/theme'

const useStyles = makeStyles({
  fieldBoxMoveable: {
    zIndex: FIELD_BOX_STYLES.Z_INDEX + 1,
    '& .moveable-control': {
      width: `${theme.spacing(0.8)}px !important`,
      height: `${theme.spacing(0.8)}px !important`,
      marginTop: `-${theme.spacing(0.4)}px !important`,
      marginLeft: `-${theme.spacing(0.4)}px !important`,
      border: 'none !important',
    },
  },
})

type Props = {
  imageBoxRef: MutableRefObject<HTMLImageElement | null>
  fileViewerSize: { width: number | null; height: number | null }
}

const FieldBoxMoveable: FunctionComponent<Props> = ({ imageBoxRef, fileViewerSize }) => {
  const moveableRef = useRef(null as Moveable | null)
  const dispatch = useDispatch()
  const [imageWidth, setImageWidth] = useState(imageBoxRef.current?.width ?? 0)
  const [imageHeight, setImageHeight] = useState(imageBoxRef.current?.height ?? 0)

  const activeFieldBoxId = useSelector(
    (state: RootState) => state.documentEditor.boxn.activeFieldBoxId,
  )

  const activeFieldBoxElement = useMemo(() => {
    return document.getElementById(activeFieldBoxId ?? '')
  }, [activeFieldBoxId])

  const activeLineItemIds = useSelector(
    (state: RootState) => state.documentEditor.activeLineItemIds,
  )
  const gridRowTransform = useSelector(
    (state: RootState) => state.documentEditor.boxn.gridRowTransform,
  )
  const lineItems = useSelector(
    (state: RootState) => lineItemSelectors.selectAll(state.documentEditor) ?? [],
  )
  const activeLineItem = useMemo(() => {
    if (activeLineItemIds.length === 1) {
      return lineItems.find((item) => item.id === activeLineItemIds[0])
    }
    return null
  }, [lineItems, activeLineItemIds])
  const currentRowDimension = activeLineItem?.box ?? DEFAULT_BOX_DIMENSION

  const rowFieldBoxes = useMemo(() => {
    return Object.values(activeLineItem?.fieldMapping ?? {})
      .filter(isPresent)
      .map((fieldMapping) => {
        const id = fieldMapping?.id ?? uuidv4()
        const fieldDimension = {
          left: fieldMapping.left,
          top: fieldMapping.top,
          width: fieldMapping.width,
          height: fieldMapping.height,
        }
        return { id, dimension: fieldDimension }
      })
  }, [activeLineItem])

  const classes = useStyles()

  const onDragStart = useCallback(() => {
    if (activeFieldBoxElement) {
      activeFieldBoxElement.style.transform = 'none'
    }
  }, [activeFieldBoxElement])
  const onDrag = useCallback(
    (evt) => {
      if (activeFieldBoxElement) {
        activeFieldBoxElement.style.transform = evt.transform
      }
    },
    [activeFieldBoxElement],
  )
  const onDragEnd = useCallback(
    (evt: OnDragEnd): void => {
      const isBoxDragged = !!evt.lastEvent?.dist
      if (isBoxDragged && activeFieldBoxElement) {
        const [deltaX, deltaY] = evt.lastEvent.dist
        const newCoords = {
          left: deltaX / imageWidth,
          top: deltaY / imageHeight,
        }
        dispatch(dragFieldBox(activeLineItem!.id, activeFieldBoxId!, newCoords))
        activeFieldBoxElement.style.transform = 'none'
      }
    },
    [activeFieldBoxElement, activeFieldBoxId, activeLineItem, dispatch, imageWidth, imageHeight],
  )

  const onResize = useCallback(
    (evt) => {
      if (activeFieldBoxElement) {
        const { drag, height, width, target } = evt
        activeFieldBoxElement.style.width = `${width}px`
        activeFieldBoxElement.style.height = `${height}px`
        target.style.transform = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)`
      }
    },
    [activeFieldBoxElement],
  )
  const onResizeEnd = useCallback(
    (evt: OnResizeEnd): void => {
      if (!evt?.lastEvent?.drag || !evt?.lastEvent?.width || !evt?.lastEvent?.height) {
        return
      }
      if (activeFieldBoxElement) {
        const { drag, width, height } = evt.lastEvent
        const deltaDimension = {
          left: drag.beforeTranslate[0] / imageWidth,
          top: drag.beforeTranslate[1] / imageHeight,
          width: width / imageWidth,
          height: height / imageHeight,
        }
        dispatch(resizeFieldBox(activeLineItem!.id, activeFieldBoxId!, deltaDimension))
        activeFieldBoxElement.style.transform = 'none'
      }
    },
    [activeFieldBoxElement, activeFieldBoxId, activeLineItem, dispatch, imageWidth, imageHeight],
  )

  useEffect(() => {
    setImageWidth(imageBoxRef.current?.width || 0)
    setImageHeight(imageBoxRef.current?.height || 0)
    moveableRef?.current?.updateRect()
  }, [lineItems, fileViewerSize.width, fileViewerSize.height, imageBoxRef])

  // this allows snapping to other field boxes inside the active row
  const elementGuidelines = useMemo(() => {
    return rowFieldBoxes
      .filter((fieldBox) => fieldBox?.id !== activeFieldBoxId)
      .map((fieldBox) => document.getElementById(fieldBox.id))
      .filter(isPresent)
  }, [activeFieldBoxId, rowFieldBoxes])

  // update moveable rect when the whole row or the active field box is dragged
  useEffect(() => {
    if (activeFieldBoxElement) {
      moveableRef?.current?.updateRect()
    }
  }, [activeFieldBoxElement, gridRowTransform])

  return (
    <Moveable
      ref={moveableRef}
      target={activeFieldBoxElement}
      className={classes.fieldBoxMoveable}
      origin={false}
      bounds={{
        left: currentRowDimension.left * imageWidth,
        top: currentRowDimension.top * imageHeight,
        right: (currentRowDimension.left + currentRowDimension.width) * imageWidth,
        bottom: (currentRowDimension.top + currentRowDimension.height) * imageHeight,
      }}
      draggable
      onDragStart={onDragStart}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      snappable
      elementGuidelines={elementGuidelines}
      resizable={!!activeFieldBoxElement}
      onResize={onResize}
      onResizeEnd={onResizeEnd}
      renderDirections={['n', 'e', 'w', 's']}
    />
  )
}

export default FieldBoxMoveable
