import { makeStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { clsx } from 'clsx'
import { groupBy } from 'lodash'
import { FunctionComponent } from 'react'
import {
  FindShipmentReconResultNode,
  InvoiceLineItemReconResultNode,
  JobExternalStatus,
  ReconAttemptNode,
  ReconciliationStatus,
  ReconResultType,
} from '@src/graphql/types'
import { getDaysPastDate } from '@src/utils/date'
import theme from '@src/utils/theme'
import { parseDateString } from '../../../utils/date'
import {
  getAmountDeltaMaybeThreshold,
  getCargowiseModuleFromReconAttempt,
  getTotalAndTaxedTotalAmount,
  UpdatedInvoiceTotalReconResultNode,
} from '../../../utils/recon/ap_recon'
import { FindModelCriteriaSummary } from '@src/utils/recon/FindModelCriteriaSummary'
import { ReferenceNumberCell } from '@src/components/reconciliation-dialog/bulk-recon-dialog/ReferenceNumberCell'
import TabStyleRadioGroup from '@src/components/TabStyleRadioGroup'
import ExternalAssigneeSelector from '@src/components/job-viewer/ExternalAssigneeSelector'
import { Controller, useFormContext } from 'react-hook-form'
import { UpdateRISROrRMSFormSchema } from './BulkReconDialog'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
type Props = {
  reconAttempt: ReconAttemptNode
  onClick: () => void
}

const useStyles = makeStyles({
  errorRow: {
    cursor: 'pointer',
    fontWeight: 'bold',
    backgroundColor: 'lightpink',
    boxShadow: `${theme.spacing(1)}px 0 red inset`,
  },
  row: {
    cursor: 'pointer',
    '&:nth-child(odd)': {
      backgroundColor: 'lightgrey',
    },
  },
  error: {
    fontWeight: 'bold',
  },
  secondaryKeysCell: {
    display: 'flex',
    flexDirection: 'column',
  },
  secondaryKeysBlock: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
})

const BulkReconDialogRow: FunctionComponent<Props> = ({ reconAttempt, onClick }) => {
  const enableInvoiceReferenceExternalAssigneeAndStatus = useFeatureIsOn(
    'invoice-reference-external-assignee-and-status',
  )
  const classes = useStyles()
  const reconResultsByType = groupBy(reconAttempt.reconResults, 'type.value')
  const reconIsDiscrepant = reconAttempt.reconStatus === ReconciliationStatus.Discrepant
  const maybeInvoiceTotalReconResult = reconResultsByType[ReconResultType.InvoiceTotalReconResult]
  let invoiceTotalReconResult = null
  let expectedTotalAmount = null
  let invoiceTotalAmount = null
  if (maybeInvoiceTotalReconResult) {
    invoiceTotalReconResult =
      maybeInvoiceTotalReconResult[0] as unknown as UpdatedInvoiceTotalReconResultNode
    expectedTotalAmount = invoiceTotalReconResult.expectedTotalAmount
    invoiceTotalAmount = invoiceTotalReconResult.invoiceTotalAmount
  } else {
    const invoiceLineItemReconResults = (reconResultsByType[
      ReconResultType.InvoiceLineItemReconResult
    ] ?? []) as InvoiceLineItemReconResultNode[]
    expectedTotalAmount = getTotalAndTaxedTotalAmount(
      undefined,
      invoiceLineItemReconResults,
      true,
    )[0]
    invoiceTotalAmount = getTotalAndTaxedTotalAmount(
      undefined,
      invoiceLineItemReconResults,
      false,
    )[0]
  }
  const { deltaValue: totalAmountDelta, thresholdAmount: reconThresholdAmount } =
    getAmountDeltaMaybeThreshold(invoiceTotalAmount, expectedTotalAmount, invoiceTotalReconResult)

  const allFindShipmentReconResults = (reconResultsByType[
    ReconResultType.FindShipmentReconResult
  ] ?? []) as FindShipmentReconResultNode[]
  const successfulFindShipmentReconResult = allFindShipmentReconResults.find(
    (reconResult) => reconResult.success,
  )

  const formMethods = useFormContext<UpdateRISROrRMSFormSchema>()

  const radioGroupOptions = [
    JobExternalStatus.Todo,
    JobExternalStatus.ForExpedock,
    JobExternalStatus.Done,
  ]

  const isExternalAssigneeRequired = !!reconAttempt.job?.jobTemplate?.requireExternalAssignee

  return (
    <TableRow
      key={reconAttempt.id}
      className={reconIsDiscrepant ? classes.errorRow : classes.row}
      onClick={onClick}
      data-testid='recon-results-row'
    >
      <TableCell
        className={clsx({ [classes.error]: reconIsDiscrepant })}
        data-testid={`invoice-number-${reconAttempt.id}`}
      >
        {reconAttempt.reconDetail?.invoiceNo}
      </TableCell>
      <TableCell
        className={clsx({ [classes.error]: reconIsDiscrepant })}
        data-testid={`shipment-number-${reconAttempt.id}`}
      >
        <ReferenceNumberCell
          findShipmentResults={allFindShipmentReconResults}
          cargowiseModule={getCargowiseModuleFromReconAttempt(reconAttempt)}
        />
      </TableCell>
      <TableCell data-testid={`secondary-keys-number-${reconAttempt.id}`}>
        <div className={classes.secondaryKeysCell}>
          <FindModelCriteriaSummary
            findModelCriteriaItems={[successfulFindShipmentReconResult || reconAttempt.reconDetail]}
          />
        </div>
      </TableCell>
      <TableCell
        className={clsx({ [classes.error]: reconIsDiscrepant })}
        data-testid={`discrepant-status-${reconAttempt.id}`}
      >
        {reconIsDiscrepant ? 'Discrepant' : 'Not Discrepant'}
      </TableCell>
      <TableCell
        className={clsx({ [classes.error]: reconIsDiscrepant })}
        data-testid={`invoice-total-${reconAttempt.id}`}
      >
        {invoiceTotalAmount}
      </TableCell>
      <TableCell
        className={clsx({ [classes.error]: reconIsDiscrepant })}
        data-testid={`expected-total-${reconAttempt.id}`}
      >
        {expectedTotalAmount}
      </TableCell>
      <TableCell
        className={clsx({ [classes.error]: reconIsDiscrepant })}
        data-testid={`total-amount-delta-${reconAttempt.id}`}
      >
        {totalAmountDelta?.toString() ?? 'N/A'}
      </TableCell>
      <TableCell
        className={clsx({ [classes.error]: reconIsDiscrepant })}
        data-testid={`threshold-${reconAttempt.id}`}
      >
        {reconThresholdAmount?.toString() ?? 'N/A'}
      </TableCell>
      <TableCell
        className={clsx({ [classes.error]: reconIsDiscrepant })}
        data-testid={`days-past-date-${reconAttempt.id}`}
      >
        {getDaysPastDate(
          reconAttempt.reconDetail.dueDate
            ? parseDateString(reconAttempt.reconDetail.dueDate)
            : null,
        )}
      </TableCell>
      {/*
       * Only render the fields below if the component is inside a form.
       * This is needed because this component can be rendered outside of a form.
       */}
      {formMethods && enableInvoiceReferenceExternalAssigneeAndStatus && (
        <>
          <TableCell
            onClick={(e) => {
              // to stop the onClick function of the row from running because we do not want to be redirected
              e.stopPropagation()
            }}
            padding='none'
          >
            <Controller
              control={formMethods.control}
              name={`input.${reconAttempt.id}.input.externalStatus`}
              render={({ field }) => <TabStyleRadioGroup options={radioGroupOptions} {...field} />}
            />
          </TableCell>
          <TableCell
            onClick={(e) => {
              // to stop the onClick function of the row from running because we do not want to be redirected
              e.stopPropagation()
            }}
            width={'360px'}
          >
            <Controller
              control={formMethods.control}
              name={`input.${reconAttempt.id}.input.externalAssignee`}
              rules={{
                required: {
                  value: isExternalAssigneeRequired,
                  message: 'This job type requires an external assignee. Please select one.',
                },
              }}
              render={({ field, fieldState }) => (
                <ExternalAssigneeSelector
                  companyId={reconAttempt.job.jobTemplate.companyId}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  )
}

export default BulkReconDialogRow
