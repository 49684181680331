import { Fragment } from 'react'
import { TableRow, Typography, Checkbox, Link, IconButton, Box } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import TableCell from '@material-ui/core/TableCell'
import { FunctionComponent } from 'react'
import { EDocsDataTargetType, Maybe, EDocumentNode } from '@src/graphql/types'
import {
  SoaConsolEDocsInfo,
  SoaCustomsDecEDocsInfo,
  SoaInvoiceEDocsInfo,
  SoaShipmentEDocsInfo,
} from './BatchPullTable'

type Props = {
  soaEDocs:
    | SoaShipmentEDocsInfo[]
    | SoaInvoiceEDocsInfo[]
    | SoaConsolEDocsInfo[]
    | SoaCustomsDecEDocsInfo[]
  handleSelect: (
    soaGroup:
      | SoaShipmentEDocsInfo
      | SoaInvoiceEDocsInfo
      | SoaConsolEDocsInfo
      | SoaCustomsDecEDocsInfo,
    targetType: EDocsDataTargetType,
  ) => void
  selectEDocHandler: (eDocument: Maybe<EDocumentNode>) => void
  deleteEdocHandler: (eDocumentId: string) => Promise<void>
  deleteEdocLoading: boolean
  dataTargetType: EDocsDataTargetType
}

const SoaEDocTableRows: FunctionComponent<Props> = ({
  soaEDocs,
  handleSelect,
  selectEDocHandler,
  deleteEdocHandler,
  deleteEdocLoading,
  dataTargetType,
}) => {
  return (
    <>
      {soaEDocs.map((item, itemIdx) => {
        let referenceNumber = ''
        if ('shipmentNum' in item) {
          referenceNumber = item.shipmentNum
        } else if ('invoiceNum' in item) {
          referenceNumber = item.invoiceNum
        } else if ('consolNum' in item) {
          referenceNumber = item.consolNum
        } else if ('customsDecNum' in item) {
          referenceNumber = item.customsDecNum
        }

        return item.eDocs.length > 0 ? (
          <Fragment key={`${referenceNumber}-${itemIdx}`}>
            {item.eDocs.map((edoc, rowIdx) => {
              const cwFileType =
                (edoc?.cargowiseFileType?.description ||
                  edoc?.filePage?.document?.documentType?.cargowiseFileType?.description) ??
                ''

              return (
                <TableRow
                  key={edoc.id}
                  data-testid={`${
                    'shipmentNum' in item ? 'soa-shipment-edoc' : 'soa-invoice-edoc'
                  }-row`}
                >
                  <TableCell>{rowIdx === 0 ? referenceNumber : ''}</TableCell>
                  <TableCell>{cwFileType}</TableCell>
                  <TableCell>
                    <Box display='flex' alignItems='center'>
                      {edoc.path.toLowerCase().endsWith('.msg') ? (
                        <Link onClick={() => selectEDocHandler(edoc)}>
                          <Typography>{edoc.filename}</Typography>
                        </Link>
                      ) : (
                        <Link href={edoc.eDocUrl as string} target='_blank' rel='noreferrer'>
                          <Typography>{edoc.filename}</Typography>
                        </Link>
                      )}
                      <Box marginLeft='auto'>
                        <IconButton
                          size='small'
                          disabled={deleteEdocLoading}
                          disableFocusRipple
                          disableRipple
                          onClick={() => deleteEdocHandler(edoc.id)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align='center'>
                    <Checkbox checked={edoc.isPublished} disabled />
                  </TableCell>
                  <TableCell align='center'>
                    {rowIdx === 0 && (
                      <Checkbox
                        checked={item.checked}
                        onChange={() => handleSelect(item, dataTargetType)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </Fragment>
        ) : (
          <TableRow
            key={`${referenceNumber}-${itemIdx}`}
            data-testid={`${'shipmentNum' in item ? 'soa-shipment-edoc' : 'soa-invoice-edoc'}-row`}
          >
            <TableCell>{referenceNumber}</TableCell>
            <TableCell colSpan={3}>
              <Typography variant='body2' color='error'>
                No eDocuments found
              </Typography>
            </TableCell>
            <TableCell align='center'>
              <Checkbox
                checked={item.checked}
                onChange={() => handleSelect(item, dataTargetType)}
              />
            </TableCell>
          </TableRow>
        )
      })}
    </>
  )
}

export default SoaEDocTableRows
