import { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

import { FilePageNode, Maybe, TaskNode } from '@src/graphql/types'
import { PAGE_ASSOC_DIMENSIONS } from '@src/utils/app_constants'
import { formatDate } from '@src/utils/date'
import { formatFilePreviewName } from '@src/utils/file'

import theme from '@src/utils/theme'

import FileInfo from './FileInfo'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  fileInfoHeader: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    height: PAGE_ASSOC_DIMENSIONS.PAGE_INFO_HEADER_HEIGHT,
    padding: theme.spacing(2),
  },
  finishButton: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
})

type Props = {
  currentPage: Maybe<FilePageNode>
  selectedTask: Maybe<Pick<TaskNode, 'id'>>
  numPageProcessed: number
  totalPages: number
}

const FileInfoHeader: FunctionComponent<Props> = ({ currentPage, selectedTask }) => {
  const history = useHistory()
  const classes = useStyles()
  const pageName = currentPage
    ? formatFilePreviewName(currentPage.file!.filename, currentPage.pageNumber)
    : ''

  const openTask = async (): Promise<void> => {
    history.push({
      pathname: `/tasks/${selectedTask?.id}`,
    })
  }

  return (
    <Box className={classes.fileInfoHeader}>
      <Box display='flex' justifyContent='space-evenly' flexGrow={1}>
        <FileInfo heading='Sender Information'>
          <Typography>{currentPage?.file?.senderAddress}</Typography>
        </FileInfo>

        <FileInfo heading='Date'>
          <Typography>
            {currentPage?.dateCreated ? formatDate(currentPage?.dateCreated) : 'Unknown'}
          </Typography>
        </FileInfo>

        <FileInfo heading='Organization'>
          <Typography>{currentPage?.file?.company?.name || 'No company'}</Typography>
        </FileInfo>

        <FileInfo heading='Page Name'>
          <Typography>{pageName}</Typography>
        </FileInfo>
      </Box>

      <Box display='flex' flexDirection='column' justifyContent='center' width='300px' mx={2}>
        <Button
          className={!selectedTask ? classes.finishButton : undefined}
          variant='contained'
          color='primary'
          disabled={!selectedTask}
          onClick={openTask}
          fullWidth
        >
          Go to task
        </Button>
      </Box>
    </Box>
  )
}

export default FileInfoHeader
