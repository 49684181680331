import { gql } from '@apollo/client'

export const USER_COMPANIES = gql`
  query userCompanies {
    companies {
      id
      name
    }
  }
`

export const ALL_COMPANIES = gql`
  query allCompanies {
    allCompanies {
      id
      name
      hasAccessToInsights
      apiPartners {
        edges {
          node {
            id
            name
            companyId
            apiPartnerCode {
              ... on FallbackValue {
                fallbackValue
                isFallback
              }
              ... on ApiPartnerCodeEnum {
                value
                isFallback
              }
            }
            ... on CargowiseConfigNode {
              pollDatabase
              connectorType
            }
          }
        }
      }
    }
  }
`

export const COMPANY_JOB_TEMPLATES = gql`
  query companyJobTemplates($companyId: UUID!, $queryString: String!) {
    companyJobTemplates(companyId: $companyId, queryString: $queryString) {
      id
      name
      documentTypes {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

export const COMPANY_DATA = gql`
  query company($id: String!) {
    company(id: $id) {
      id
      name
      cargowiseConfigs {
        edges {
          node {
            id
            code
            enterpriseId
            serverId
            integrationId
          }
        }
      }
      apiPartners {
        edges {
          node {
            id
            name
          }
        }
      }
      metabaseDashboardId
      hasAccessToInsights
      shipperFacingSlug
      logo
      primaryThemeColor
      usesChargeCodeV2
      enableEdocsTab
    }
  }
`

export const SIGN_COMPANY_LOGO_URI = gql`
  query signCompanyLogoUri($companyId: UUID!) {
    signCompanyLogoUri(companyId: $companyId)
  }
`
