import { useQuery } from '@apollo/client'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { SEARCH_COMPANIES } from '@src/graphql/queries/admin'
import { FunctionComponent } from 'react'
import { CompanyNode, Query } from '@src/graphql/types'
import CompanyJobsCard from '@src/components/admin/CompanyJobsCard'
import { Box, Fab, InputBase, Paper } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { useState } from 'react'
import DeleteCompanyDialog from '@src/components/admin/DeleteCompanyDialog'
import CreateCompanyDialog from '@src/components/admin/CreateCompanyDialog'
import useRequireTeamLead from '@src/hooks/useRequireTeamLead'

const useStyles = makeStyles({
  searchRoot: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  cardsWrapper: {
    marginLeft: theme.spacing(3),
    display: 'inline-flex',
    flexWrap: 'wrap',
  },
  cardWrapper: {
    paddingRight: theme.spacing(2),
  },
  fabWrapper: {
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
})

const CompanyListAdminPage: FunctionComponent = () => {
  const classes = useStyles()
  const [query, setQuery] = useState('')
  const [companyToDelete, setCompanyToDelete] = useState(null as CompanyNode | null)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const {
    data: companies,
    loading: isLoadingCompanies,
    refetch: refetchCompanies,
  } = useQuery<Pick<Query, 'searchedForCompanies'>>(SEARCH_COMPANIES, {
    fetchPolicy: 'network-only',
    variables: {
      query,
    },
  })
  const permissionsError = useRequireTeamLead()
  if (permissionsError) {
    return permissionsError
  }
  return (
    <div>
      <Paper className={classes.searchRoot}>
        <InputBase
          value={query}
          className={classes.input}
          placeholder='Search Companies'
          inputProps={{ 'aria-label': 'search companies' }}
          onChange={(e) => setQuery(e.target.value)}
        />
        <SearchIcon />
      </Paper>
      {isLoadingCompanies ? (
        <CenteredCircularProgress />
      ) : (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          p={2}
          data-testid='company-search-results'
        >
          <div className={classes.cardsWrapper}>
            {companies?.searchedForCompanies!.map((company) => (
              <div key={company.id} className={classes.cardWrapper}>
                <CompanyJobsCard company={company} setCompanyToDelete={setCompanyToDelete} />
              </div>
            ))}
          </div>
        </Box>
      )}
      <div className={classes.fabWrapper}>
        <Fab
          color='primary'
          aria-label='add'
          variant='extended'
          onClick={() => setShowCreateDialog(true)}
        >
          + Company
        </Fab>
      </div>
      {companyToDelete && (
        <DeleteCompanyDialog
          refetchCompanies={refetchCompanies}
          setCompanyToDelete={setCompanyToDelete}
          company={companyToDelete}
        />
      )}
      {showCreateDialog && (
        <CreateCompanyDialog
          setShowCreateDialog={setShowCreateDialog}
          refetchCompanies={refetchCompanies}
        />
      )}
    </div>
  )
}

export default CompanyListAdminPage
