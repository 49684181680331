import { FunctionComponent } from 'react'
import { Box, Link, makeStyles, Paper, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { CompanyNode } from '@src/graphql/types'
import { Link as RouterLink } from 'react-router-dom'
import theme from '@src/utils/theme'

const useStyles = makeStyles({
  companyNameWrapper: {
    height: theme.spacing(5),
    backgroundColor: grey[600],
    color: 'white',
    width: '100%',
    '& a': {
      color: theme.palette.common.white,
    },
  },
  companyName: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    flexGrow: 1,
  },
  icon: {
    '&:hover': {
      cursor: 'pointer',
    },
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  card: {
    textAlign: 'left',
    userSelect: 'none',
    margin: `0 0 ${theme.spacing(1)}px 0`,
    width: 500,
    minHeight: 200,
  },
  innerJobName: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: grey[300],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

type Props = {
  company: CompanyNode
  setCompanyToDelete: (company: CompanyNode) => void
}

const CompanyJobsCard: FunctionComponent<Props> = ({ company, setCompanyToDelete }: Props) => {
  const classes = useStyles()

  return (
    <Paper elevation={3} className={classes.card}>
      <div className={classes.companyNameWrapper}>
        <Box display='inline-flex' width='100%'>
          <Typography className={classes.companyName}> {company.name} </Typography>
          <Link
            component={RouterLink}
            to={`/admin/company/${company.id}`}
            data-testid={`edit-company-${company.name}`}
          >
            <EditIcon className={classes.icon} />
          </Link>
          <DeleteIcon
            className={classes.icon}
            onClick={() => setCompanyToDelete(company)}
            data-testid={`delete-company-button-${company.name}`}
          />
        </Box>
      </div>
      <Box display='inline-flex' flexWrap='wrap'>
        {company
          .jobTemplates!.edges.filter((jobTemplate) => !jobTemplate!.node!.dateDeleted)
          .map((jobTemplate) => (
            <Typography
              title={jobTemplate!.node!.name}
              key={jobTemplate!.node!.id}
              className={classes.innerJobName}
            >
              {jobTemplate!.node!.name}
            </Typography>
          ))}
      </Box>
    </Paper>
  )
}

export default CompanyJobsCard
