import { ChangeEvent, FunctionComponent, useMemo } from 'react'
import { Controller, ControllerProps, FieldValues } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import { InputField } from '@src/graphql/types'

type Props = {
  field: InputField
  fieldGroupIndex: number
  fieldIndex: number
  isDateTime: boolean
  isLineItem: boolean
}

const DateFormatStringSelector: FunctionComponent<Props> = ({
  field,
  fieldGroupIndex,
  fieldIndex,
  isDateTime,
  isLineItem,
}) => {
  const namePrefix = isLineItem ? 'lineItemTypes' : 'metadataFieldGroups'
  const dateFormats = [
    'MM/DD/YY',
    'DD/MM/YY',
    'YY/MM/DD',
    'M/D/Y',
    'D/M/YY',
    'YY/M/D',
    'MMDDYY',
    'DDMMYY',
    'YYMMDD',
    'MM-DD-YYYY',
    'MMM-DD-YYYY',
    'DD-MMM-YYYY',
    'YYYYYY-MMM-DD',
    'MMM DD, YYYY',
    'DD MMM, YYYY',
    'YYYY, MMM DD',
    'YYYY-MM-DD',
    'YYYY/MM/DD',
    'DD/MM/YYYY',
    'MM/DD/YYYY',
  ]
  const fieldDateTimeFormats = isDateTime
    ? dateFormats.map((dateFormat) => dateFormat + '-HH:mm:ss')
    : dateFormats
  const dateFormatOptions = useMemo(() => fieldDateTimeFormats, [fieldDateTimeFormats])
  const render: ControllerProps<
    FieldValues,
    | `metadataFieldGroups.${number}.fields.${number}.dateFormatString`
    | `lineItemTypes.${number}.fields.${number}.dateFormatString`
  >['render'] = useMemo(() => {
    // we don't pass a ref to the controller since that causes react hook form to try to
    // set the input value to the raw ID on refetch
    return ({ field: { value, onBlur, onChange } }) => {
      const onDateFormatChange = (_: ChangeEvent<Record<string, string>>, val: string): void => {
        onChange(val)
      }

      return (
        <Autocomplete
          value={value}
          options={dateFormatOptions}
          onChange={onDateFormatChange}
          onBlur={onBlur}
          size='small'
          renderInput={(params) => <TextField {...params} variant='outlined' />}
        />
      )
    }
  }, [dateFormatOptions])
  return (
    <Controller
      render={render}
      name={`${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.dateFormatString`}
      defaultValue={field.dateFormatString}
    />
  )
}

export default DateFormatStringSelector
