import { HotTable } from '@handsontable/react'
import { Box, IconButton, Paper, Typography } from '@material-ui/core'
import FastHotTable from '@src/components/fast-hot-table/FastHotTable'
import { CompanyChargeCodesV2Query, useCompanyChargeCodesV2LazyQuery } from '@src/graphql/types'
import theme from '@src/utils/theme'
import { FunctionComponent, MutableRefObject, useMemo } from 'react'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import { ChargeCodeOverridesArray } from './types'
import { debounce } from 'lodash'

type Props = {
  companyId: string
  chargeCodeOverrides: ChargeCodeOverridesArray | undefined
  hotTableRef: MutableRefObject<HotTable | undefined>
  apiPartnerId?: string | null
  pageSize: number
  page: number
  setPage: (page: number) => void
}

const VendorCodeOverridesTable: FunctionComponent<Props> = ({
  companyId,
  chargeCodeOverrides,
  hotTableRef,
  apiPartnerId,
  pageSize,
  page,
  setPage,
}) => {
  const [fetchChargeCodes] = useCompanyChargeCodesV2LazyQuery({
    fetchPolicy: 'network-only',
  })

  const columns = useMemo(() => {
    return [
      {
        key: 'description',
        name: 'Description',
      },
      {
        key: 'charge_code',
        name: 'Charge Code',
        type: 'autocomplete',
        // Reference: https://handsontable.com/docs/javascript-data-grid/api/options/#source (docs for the version used in the project are not available, but this should be a good reference)
        // This allows us to do a fetch upon typing in the cell. A single fetch is not enough because the list of charge codes can go beyond the page limit.
        source: debounce(async (query: string, process: (data: unknown) => void) => {
          if (companyId) {
            const { data } = await fetchChargeCodes({
              variables: { companyId, apiPartnerId, query },
            })
            const chargeCodes = data?.companyChargeCodesV2 || []
            const codes = chargeCodes.map(
              (chargeCode: CompanyChargeCodesV2Query['companyChargeCodesV2'][0]) => chargeCode.code,
            )
            process(codes)
          }
        }, 300),
        strict: false,
      },
    ]
  }, [companyId, apiPartnerId, fetchChargeCodes])

  const rows = useMemo(() => {
    if (chargeCodeOverrides) {
      const newRows = []
      for (const override of chargeCodeOverrides) {
        if (!override.dateDeleted) {
          newRows.push([override.description, override.chargeCode?.code ?? ''])
        }
      }
      if (newRows.length == 0) {
        return [['', '']]
      } else {
        return newRows
      }
    } else {
      // Add a placeholder to allow adding new rows into the table
      return [['', '']]
    }
  }, [chargeCodeOverrides])

  return (
    <>
      <Box pl={2}>
        <Typography variant='h4'>Vendor Overrides</Typography>
      </Box>
      {apiPartnerId ? (
        <>
          <Box pl={2}>
            Page {page / pageSize + 1}
            <IconButton
              color='primary'
              aria-label='back'
              onClick={() => setPage(page - pageSize)}
              disabled={page === 0}
            >
              <ArrowBackOutlinedIcon />
            </IconButton>
            <IconButton
              color='primary'
              aria-label='forward'
              onClick={() => setPage(page + pageSize)}
              disabled={!chargeCodeOverrides || chargeCodeOverrides.length < pageSize}
            >
              <ArrowForwardOutlinedIcon />
            </IconButton>
          </Box>
          <Paper data-testid='vendor-code-table'>
            <FastHotTable
              data={rows}
              hotTableRef={hotTableRef}
              hooks={{}}
              style={{
                width: '100%',
                overflow: 'hidden',
                height: '480px',
                background: theme.palette.grey[200],
              }}
              settings={{
                rowHeaders: true,
                contextMenu: ['row_above', 'row_below', 'remove_row'],
                colHeaders: columns.map((col) => col.name),
                columnSorting: true,
                filters: true,
                autoRowSize: true,
                stretchH: 'all',
                columns,
              }}
            />
          </Paper>
        </>
      ) : (
        <Typography align='center'>Select a TMS to add and view charge code overrides.</Typography>
      )}
    </>
  )
}

export default VendorCodeOverridesTable
