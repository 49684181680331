import { FunctionComponent, useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { formatMaybeApolloError } from '@src/utils/errors'
import { useTestCredentialMutation } from '@src/graphql/types'
import { useSnackbar } from 'notistack'
type Props = {
  credentialId: string
}
const TestCredentialButton: FunctionComponent<Props> = ({ credentialId }) => {
  const [isTesting, setIsTesting] = useState(false)
  const [testCredential] = useTestCredentialMutation()
  const { enqueueSnackbar } = useSnackbar()
  const onTest = async (id: string): Promise<void> => {
    try {
      await testCredential({ variables: { credentialId: id } })
      enqueueSnackbar('eAdaptor Credential testing successful', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(
        `Encountered an error while testing eAdaptor credential: ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    }
  }

  return (
    <Button
      size='small'
      disabled={isTesting}
      variant={'outlined'}
      onClick={async () => {
        setIsTesting(true)
        try {
          await onTest(credentialId)
        } finally {
          setIsTesting(false)
        }
      }}
    >
      {(isTesting && <CircularProgress size={'1em'} />) || 'Test eAdaptor'}
    </Button>
  )
}

export default TestCredentialButton
