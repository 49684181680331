import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useContext, useMemo } from 'react'
import { clsx } from 'clsx'
import groupBy from 'lodash/groupBy'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography, Theme } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import {
  AnReconAutofillKey,
  ReconcileArrivalNoticeJob,
  ReconResultType,
  JobTemplateReconType,
  ReconAttemptNode,
  FieldNode,
} from '@src/graphql/types'
import { JobDataContext } from '@src/contexts/job_data_context'
import { SAVE_AN_RECON_ATTEMPT } from '@src/graphql/mutations/recon'
import { JOB_NOTES } from '@src/graphql/queries/note'
import {
  getDocumentTypesFieldGroups,
  getFieldGroupsNonRepeatableFields,
} from '@src/utils/shipment_form'
import {
  ArrivalNoticeReconResultNode,
  getArrivalNoticeReconResultTypography,
} from '@src/utils/recon/an_recon'
import ArrivalNoticeContainerResults from './ArrivalNoticeContainerResults'
import ArrivalNoticeRatesResults from './ArrivalNoticeRatesResults'
import ArrivalNoticeMetadataResults from './ArrivalNoticeMetadataResults'
import ArrivalNoticePremiumRatesResults from './ArrivalNoticePremiumRatesResults'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import {
  parseDateString,
  millisecondsInMinute,
  getReconResultsDataModifiedDate,
} from '@src/utils/date'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { LogEventType } from '@src/utils/observability/LogEventType'

type Props = {
  jobId: string
  reconAttemptId: string | null
  reconJobResults: ReconcileArrivalNoticeJob
  existingReconAttempt: ReconAttemptNode | null
  previous: () => void
  close: () => void
}

const useStyles = makeStyles<Theme>({
  consolDate: {
    color: theme.palette.text.primary,
  },
  consolDateError: {
    color: theme.palette.error.main,
  },
})

const ArrivalNoticeReconResults: FunctionComponent<Props> = ({
  jobId,
  reconAttemptId,
  reconJobResults,
  existingReconAttempt,
  previous,
  close,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { reconAttempt } = reconJobResults
  const reconResults = reconJobResults.reconResults as ArrivalNoticeReconResultNode[]
  const { documentTypes } = useContext(JobDataContext)
  const [saveReconAttempt, { loading: saveReconAttemptLoading }] = useMutation(
    SAVE_AN_RECON_ATTEMPT,
    {
      refetchQueries: [{ query: JOB_NOTES, variables: { jobId } }],
    },
  )

  const consolDateModified = getReconResultsDataModifiedDate(
    reconResults,
    JobTemplateReconType.ArrivalNotice,
  )
  // We consider consols that are more than 30 minutes old as outdated
  // [PD-288](https://expedock.atlassian.net/browse/PD-288)
  const isConsolDateOutdated =
    consolDateModified &&
    new Date().getTime() - consolDateModified.getTime() > millisecondsInMinute * 30

  const reconResultsByType = groupBy(reconResults, 'type.value')
  const [findConsolReconResult] = reconResultsByType[ReconResultType.FindConsolReconResult]
  const consolTypeReconResults =
    reconResultsByType[ReconResultType.ArrivalNoticeConsolTypeReconResult]
  const consolTypeReconResult = consolTypeReconResults?.[0] ?? null
  const contractReconResults =
    reconResultsByType[ReconResultType.ArrivalNoticeContractReconResult] ?? []
  const metadataReconResults =
    reconResultsByType[ReconResultType.ArrivalNoticeMetadataReconResult] ?? []
  const containerReconResults =
    reconResultsByType[ReconResultType.ArrivalNoticeContainerReconResult] ?? []
  const premiumRatesReconResults =
    reconResultsByType[ReconResultType.ArrivalNoticeLumpsumReconResult] ?? []

  const { logEvent } = useEventLogger()

  const handleSaveReconAttempt = async (): Promise<void> => {
    let success = false
    let errorMessage = ''
    try {
      await saveReconAttempt({
        variables: { reconAttemptId: reconAttempt.id },
      })
      enqueueSnackbar('Saved reconciliation attempt', { variant: 'success' })
      success = true
      close()
    } catch (error) {
      errorMessage = `Failed to save reconciliation attempt: ${formatMaybeApolloError(error)}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
    } finally {
      void logEvent(LogEventType.RECONCILE_SHOW, {
        job_id: jobId,
        recon_attempt_id: reconAttemptId,
        recon_type: JobTemplateReconType.ArrivalNotice,
        success: success,
        error_message: errorMessage,
      })
    }
  }

  const nonRepeatableFieldKeyToNameMap = useMemo(() => {
    if (documentTypes) {
      const fieldGroups = getDocumentTypesFieldGroups(documentTypes)
      const nonRepeatableFields = getFieldGroupsNonRepeatableFields(fieldGroups)
      return {
        [AnReconAutofillKey.TotalAmount.toLowerCase()]: 'Total Amount',
        ...Object.fromEntries(
          nonRepeatableFields.map((field: FieldNode) => [field.autofillKey, field.name]),
        ),
      }
    }
    return {}
  }, [documentTypes])

  return (
    <div data-testid='recon-modal'>
      <DialogTitle>Reconciliation Completed</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' mb={1}>
          {getArrivalNoticeReconResultTypography(findConsolReconResult)}
          {contractReconResults.map((reconResult) =>
            getArrivalNoticeReconResultTypography(reconResult),
          )}
        </Box>
        {consolDateModified && (
          <Box mb={2}>
            <Typography component={'span'} variant='body1'>
              Consol data last updated as of:{' '}
              <span
                className={clsx(classes.consolDate, {
                  [classes.consolDateError]: isConsolDateOutdated,
                })}
              >
                {consolDateModified?.toString()}
              </span>
            </Typography>
          </Box>
        )}
        {metadataReconResults.length > 0 && (
          <Box mb={1}>
            <ArrivalNoticeMetadataResults
              metadataReconResults={metadataReconResults}
              consolTypeReconResult={consolTypeReconResult}
              nonRepeatableFieldKeyToNameMap={nonRepeatableFieldKeyToNameMap}
            />
          </Box>
        )}
        {containerReconResults.length > 0 && (
          <ArrivalNoticeContainerResults containerReconResults={containerReconResults} />
        )}
        {premiumRatesReconResults.length > 0 ? (
          <ArrivalNoticePremiumRatesResults premiumRatesReconResult={premiumRatesReconResults[0]} />
        ) : (
          <ArrivalNoticeRatesResults
            ratesReconResults={
              reconResultsByType[ReconResultType.ArrivalNoticeChargeRateReconResult] || []
            }
          />
        )}
        {existingReconAttempt && (
          <Box display='flex' justifyContent='center' alignItems='center'>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              bgcolor='lightgoldenrodyellow'
              p={2}
            >
              <Box p={1}>
                <ErrorOutlineIcon />
              </Box>
              <Box p={1}>
                <Typography variant='subtitle2'>
                  An existing recon attempt for this job has already been{' '}
                  {existingReconAttempt.resolutionStatus} by{' '}
                  {existingReconAttempt.userResolved?.email ?? ''} on{' '}
                  {parseDateString(
                    existingReconAttempt.dateResolved as string,
                  ).toLocaleDateString()}
                  . Are you sure you want to save another recon attempt for their approval?
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Box display='flex' alignItems='center' p={2} justifyContent='center'>
          <Box mr={2}>
            <Button onClick={previous} variant='contained'>
              Go Back
            </Button>
          </Box>
          <Box>
            <Button
              disabled={saveReconAttemptLoading}
              onClick={handleSaveReconAttempt}
              color='primary'
              variant='contained'
              data-testid='show-customer-anrecon'
            >
              Show Customers
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </div>
  )
}

export default ArrivalNoticeReconResults
