/**
 * Dashboard for jobs of a particular status
 */
import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Pagination } from '@material-ui/lab'
import { v4 as uuidv4 } from 'uuid'
import { format } from 'date-fns'
import { clsx } from 'clsx'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  InputBase,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { useSnackbar } from 'notistack'

import { JOB_OVERVIEW_PAGE, JOBS_FOR_EXPORT } from '@src/graphql/queries/job'
import { COPY_JOB, DELETE_JOB, RESTORE_JOB } from '@src/graphql/mutations/job'
import {
  JobNode,
  JobStatus,
  Mutation,
  Query,
  useJobSearchParametersQuery,
} from '@src/graphql/types'
import { CSV_EXPORT_JOB_TYPES, EXCEL_EXPORT_JOB_TYPES, JOB_STATUS } from '@src/utils/app_constants'
import { getSLATimeLeft } from '@src/utils/date'
import JobOwnerSelect from '@src/components/JobOwnerSelect'
import ProofDialog from '@src/components/proof-dialog/ProofDialog'
import JobFilterMenu, { JobFilter } from '@src/components/job-filter-menu/JobFilterMenu'
import {
  bulkExportJobsToCevaCsv,
  bulkExportJobsToExcel,
  bulkExportJobsToFieldMappingCsv,
  bulkExportJobsToLineItemCsv,
} from '@src/utils/export'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import JobQASelect from '@src/components/JobQASelect'
import IdentifyTaskDialog from '@src/components/tasks/IdentifyTaskDialog'
import { useLocationQuery } from '@src/utils/route'
import { formatMaybeApolloError } from '@src/utils/errors'
import {
  QueryJobOverviewPageArgs,
  JobFilterColumn,
  JobFilterOperation,
  JobFilterSettingNode,
  InputJobFilter,
} from '../../graphql/types'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DescriptionIcon from '@material-ui/icons/Description'
import Checkbox from '@material-ui/core/Checkbox'
import SearchIcon from '@material-ui/icons/Search'
import { parseDateString } from '@src/utils/date'
import { DeployEnvironment, getDeployEnvironment } from '@src/utils/environment'
import { pick, debounce, isEqual } from 'lodash'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

const SEARCH_BAR_WIDTH = 400
const searchDebounceMs = 250

const getFormattedJobStatusFromQueryStatus = (queryStatus: string | null): string | null =>
  Object.values(JOB_STATUS).find((jobStatus) => jobStatus.toLowerCase() === queryStatus) || null

const isValidInputJobFilter = (filter: Omit<JobFilter, 'id'>): filter is Readonly<JobFilter> => {
  return !!filter.value?.length && !!filter.operation && !!filter.column
}

const useStyles = makeStyles({
  ctaButton: {
    margin: theme.spacing(2),
  },
  root: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    width: SEARCH_BAR_WIDTH,
  },
  searchPaper: {
    marginBottom: theme.spacing(3),
  },
  searchIconPrimary: {
    color: theme.palette.secondary.main,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
})
const JOBS_PER_PAGE = 20

enum BulkExportType {
  XLSX = 'XLSX',
  INVOICE_HEADER_CSV = 'Invoice Header CSV',
  INVOICE_LINE_ITEM_CSV = 'Invoice Line Item CSV',
  CEVA_CSV = 'CEVA CSV',
}

const JobsPage: FunctionComponent = () => {
  const classes = useStyles()
  const client = useApolloClient()
  const { enqueueSnackbar } = useSnackbar()
  const [bulkExportType, setBulkExportType] = useState(null as BulkExportType | null)
  const [selectedJobs, setSelectedJobs] = useState([] as JobNode[])
  const [isIdentifyTaskDialogOpen, setIsIdentifyTaskDialogOpen] = useState(false)
  const [isProofDialogOpen, setIsProofDialogOpen] = useState(false)
  const query = useLocationQuery()
  const [pageNumber, setPageNumber] = useState(1)
  const wrappedOnPageChange = (_event: unknown, value: number): void => setPageNumber(value)
  const [deleteJob, { error: deleteJobError }] = useMutation<Pick<Mutation, 'deleteJob'>>(
    DELETE_JOB,
    {
      update: (cache) => {
        cache.modify({
          fields: {
            // Invalidate countByJobStatus field and refreshes cache
            countByJobStatus() {},
          },
        })
      },
      onError: () => {
        if (deleteJobError) {
          enqueueSnackbar(`Failed to delete job: ${formatMaybeApolloError(deleteJobError)}`, {
            variant: 'error',
          })
        }
      },
    },
  )
  const queryStatus = query.get('status')
  const isInBulkExportStatus =
    queryStatus === JOB_STATUS[JobStatus.Confirmation].toLowerCase() ||
    queryStatus === JOB_STATUS[JobStatus.Qa].toLowerCase()

  const [jobQuery, setJobQuery] = useState(null as null | string)
  const [jobQueryBuffer, setJobQueryBuffer] = useState(null as null | string)
  const dateMonthAgo = new Date(new Date().setDate(new Date().getDate() - 30))
  const defaultDateCreatedJobFilter = {
    id: uuidv4(),
    column: JobFilterColumn.DateCreated,
    operation: JobFilterOperation.After,
    value: [format(dateMonthAgo, 'yyyy-MM-dd')] as string[],
  } as JobFilter
  const [jobFilters, setJobFilters] = useState(undefined as undefined | JobFilter[])
  const [jobFiltersBuffer, setJobFiltersBuffer] = useState(undefined as undefined | JobFilter[])

  useJobSearchParametersQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setJobQuery(data.jobSearchParameters.queryString)
      setJobQueryBuffer(data.jobSearchParameters.queryString)
      const newFilters = [
        ...(data.jobSearchParameters.filters as unknown as JobFilterSettingNode[]),
      ]
      let validFilters = newFilters.map((jobFilter) => ({
        ...jobFilter,
        column: jobFilter.column,
      })) as JobFilter[]
      if (getDeployEnvironment() !== DeployEnvironment.DEVELOPMENT) {
        validFilters = [...validFilters, defaultDateCreatedJobFilter]
      }
      setJobFilters(validFilters)
      setJobFiltersBuffer(validFilters)
    },
  })

  const getFilters = useCallback((allJobFilters: JobFilter[] | undefined): InputJobFilter[] => {
    if (allJobFilters === undefined) return []
    const adjustJobFilterDates = (jobFilter: InputJobFilter): InputJobFilter => {
      jobFilter.value = [parseDateString(jobFilter.value[0]).toUTCString()]
      return jobFilter
    }
    const dateFilters = [
      JobFilterColumn.DateCreated,
      JobFilterColumn.DateReceived,
      JobFilterColumn.DateCompleted,
    ]
    const validJobFilters = allJobFilters.filter(isValidInputJobFilter) as InputJobFilter[]
    const timezoneAdjustedFilters = validJobFilters.map((jobFilter) =>
      dateFilters.includes(jobFilter.column) ? adjustJobFilterDates(jobFilter) : jobFilter,
    )
    return timezoneAdjustedFilters.map((filter) =>
      pick(filter, 'id', 'value', 'operation', 'column'),
    )
  }, [])

  const {
    data: jobsData,
    loading: jobsLoading,
    refetch: refetchJobs,
  } = useQuery<Pick<Query, 'jobOverviewPage'>, QueryJobOverviewPageArgs>(JOB_OVERVIEW_PAGE, {
    fetchPolicy: 'network-only',
    variables: {
      page: pageNumber - 1,
      query: jobQuery || '',
      status: getFormattedJobStatusFromQueryStatus(queryStatus),
      filters: getFilters(jobFilters),
    },
    skip: jobFilters === undefined || jobQuery === undefined,
    onError: (error) => {
      enqueueSnackbar(`Error received while loading jobs: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const canInitiateJobsSearch =
    (jobQueryBuffer || '') !== (jobQuery || '') ||
    !isEqual(jobFilters || [], jobFiltersBuffer || [])

  const initiateJobsSearch = useMemo(() => {
    const debouncedSetJobQuery = debounce(() => {
      if (!canInitiateJobsSearch) return
      // Note: we don't just do setJobFilters(jobFiltersBuffer) here because jobFiltersBuffer
      // could be undefined. And if jobFilters is undefined, then taskPageData wouldn't get
      // refetched.
      setJobFilters(jobFiltersBuffer || [])
      setJobQuery(jobQueryBuffer || '')
    }, searchDebounceMs)
    // mui doesn't really tell us what the type should be
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return () => debouncedSetJobQuery()
  }, [canInitiateJobsSearch, jobFiltersBuffer, jobQueryBuffer])

  const enableLineItemsRowOrderPriority = useFeatureIsOn('line-items-row-order-priority')

  const [queryJobsExport] = useLazyQuery<Pick<Query, 'jobs'>>(JOBS_FOR_EXPORT, {
    fetchPolicy: 'network-only',
    onCompleted: async ({ jobs }) => {
      switch (bulkExportType) {
        case BulkExportType.XLSX:
          bulkExportJobsToExcel(jobs as JobNode[], enableLineItemsRowOrderPriority)
          break
        case BulkExportType.INVOICE_HEADER_CSV:
          await bulkExportJobsToFieldMappingCsv(jobs as JobNode[])
          break
        case BulkExportType.INVOICE_LINE_ITEM_CSV:
          await bulkExportJobsToLineItemCsv(jobs as JobNode[])
          break
        case BulkExportType.CEVA_CSV:
          await bulkExportJobsToCevaCsv(jobs as JobNode[], enableLineItemsRowOrderPriority)
          break
        default:
          break
      }
    },
  })

  const handleExport = async (currExportType: BulkExportType, jobIds: string[]): Promise<void> => {
    setBulkExportType(currExportType)
    try {
      await queryJobsExport({ variables: { jobIds } })
    } catch (e) {
      enqueueSnackbar(`Error fetching jobs for export: ${formatMaybeApolloError(e)}`, {
        variant: 'error',
      })
    }
  }

  const isExcelBulkExportable = useMemo(() => {
    const uniqueJobTemplates = [...new Set(selectedJobs.map((job) => job.jobTemplate))]
    return (
      uniqueJobTemplates.length === 1 &&
      ((
        [
          EXCEL_EXPORT_JOB_TYPES.DRAYALLIANCE,
          EXCEL_EXPORT_JOB_TYPES.SCHAYER_CUSTOMS_DECLARATION_TYPE_1,
          EXCEL_EXPORT_JOB_TYPES.SCHAYER_CUSTOMS_DECLARATION_TYPE_2,
        ] as string[]
      ).includes(uniqueJobTemplates[0]!.name || '') ||
        !!uniqueJobTemplates[0]?.jobTemplateExport?.jobTemplateExportType)
    )
  }, [selectedJobs])
  const isCsvBulkExportable = useMemo(() => {
    return (
      selectedJobs.length &&
      selectedJobs.every((job: JobNode) => {
        // we only support ascent rn for excel bulk export
        return CSV_EXPORT_JOB_TYPES.ASCENT_INVOICE === job.jobTemplate?.name
      })
    )
  }, [selectedJobs])

  const [copyJob] = useMutation<Pick<Mutation, 'copyJob'>>(COPY_JOB, {
    update: (cache) => {
      cache.modify({
        fields: {
          // Invalidate countByJobStatus field and refreshes cache
          countByJobStatus() {},
        },
      })
    },
  })

  const isCevaCsvBulkExportable = useMemo(() => {
    const selectedJobTypes = selectedJobs.map((job) => job.jobTemplate?.name)
    return (
      !!selectedJobTypes.length &&
      selectedJobTypes.every((jobType) =>
        (
          [
            CSV_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_2HR,
            CSV_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_4HR,
            CSV_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_12HR,
            CSV_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_24HR,
          ] as string[]
        ).includes(jobType || ''),
      )
    )
  }, [selectedJobs])

  const onRestoreJob = async (job: JobNode): Promise<void> => {
    await client.mutate({
      mutation: RESTORE_JOB,
      variables: { jobId: job.id },
      update: (cache) => {
        cache.modify({
          fields: {
            // Invalidate countByJobStatus field and refreshes cache
            countByJobStatus() {},
          },
        })
      },
    })
    await refetchJobs()
  }
  const getJobLink = (job: JobNode): string => {
    switch (job.status) {
      case JobStatus.Todo:
        return `/todo/${job.id}`
      case JobStatus.InProgress:
        return `/inprogress/${job.id}`
      case JobStatus.Qa:
        return `/qa/${job.id}`
      case JobStatus.Confirmation:
        return `/confirmation/${job.id}`
      case JobStatus.Done:
      default:
        return `/done/${job.id}`
    }
  }
  const onDeleteJob = async (job: JobNode): Promise<void> => {
    await deleteJob({ variables: { jobId: job.id } })
    if (jobsData && jobsData.jobOverviewPage.items.length <= 1) {
      setPageNumber(Math.max(0, pageNumber - 1))
    } else {
      await refetchJobs()
    }
  }
  const onDuplicateJob = async (job: JobNode): Promise<void> => {
    const { errors } = await copyJob({ variables: { jobId: job.id } })

    if (errors) {
      enqueueSnackbar(`Failed to duplicate job ${job.name}`, { variant: 'error' })
    } else {
      enqueueSnackbar(`Successfully duplicated job ${job.name}`, {
        variant: 'success',
      })
      await refetchJobs()
    }
  }

  return (
    <Box m={2}>
      <Button
        className={classes.ctaButton}
        variant='contained'
        color='primary'
        onClick={() => setIsIdentifyTaskDialogOpen(true)}
      >
        Create Job
      </Button>
      {isInBulkExportStatus && (
        <>
          <Button
            className={classes.ctaButton}
            variant='contained'
            color='primary'
            disabled={selectedJobs.length === 0}
            onClick={(): void => {
              setIsProofDialogOpen(true)
            }}
          >
            Move to Done
          </Button>
          <Button
            className={classes.ctaButton}
            variant='contained'
            color='primary'
            disabled={!isExcelBulkExportable}
            onClick={() => {
              const jobIds = selectedJobs.map(({ id }) => id)
              void handleExport(BulkExportType.XLSX, jobIds)
            }}
          >
            Bulk Export (XLSX)
          </Button>
          <Button
            className={classes.ctaButton}
            variant='contained'
            color='primary'
            disabled={!isCsvBulkExportable}
            onClick={() => {
              const jobIds = selectedJobs.map(({ id }) => id)
              void handleExport(BulkExportType.INVOICE_HEADER_CSV, jobIds)
            }}
          >
            Bulk Export (Invoice Header CSV)
          </Button>
          <Button
            className={classes.ctaButton}
            variant='contained'
            color='primary'
            disabled={!isCsvBulkExportable}
            onClick={() => {
              const jobIds = selectedJobs.map(({ id }) => id)
              void handleExport(BulkExportType.INVOICE_LINE_ITEM_CSV, jobIds)
            }}
          >
            Bulk Export (Invoice Line Item CSV)
          </Button>
          <Button
            className={classes.ctaButton}
            variant='contained'
            color='primary'
            disabled={!isCevaCsvBulkExportable}
            onClick={() => {
              const jobIds = selectedJobs.map(({ id }) => id)
              void handleExport(BulkExportType.CEVA_CSV, jobIds)
            }}
          >
            Bulk Export (CEVA CSV)
          </Button>
        </>
      )}
      <Box display='flex' alignItems='center'>
        <Paper className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder='Search Jobs'
            inputProps={{ 'aria-label': 'search tasks' }}
            onChange={(e) => setJobQueryBuffer(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') initiateJobsSearch()
            }}
            value={jobQueryBuffer}
            data-testid='job-search-input'
          />
          <IconButton
            onClick={initiateJobsSearch}
            data-testid='job-search-button'
            disabled={!canInitiateJobsSearch}
          >
            <SearchIcon
              className={clsx({
                [classes.searchIconPrimary]: canInitiateJobsSearch,
              })}
            />
          </IconButton>
        </Paper>
        <JobFilterMenu
          updateJobFilterBuffer={setJobFiltersBuffer}
          jobFilters={jobFiltersBuffer || []}
        />
      </Box>

      {jobsLoading || !jobsData ? (
        <CenteredCircularProgress />
      ) : (
        <>
          <Pagination
            count={Math.ceil(jobsData.jobOverviewPage.total / JOBS_PER_PAGE)}
            page={pageNumber}
            onChange={wrappedOnPageChange}
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {isInBulkExportStatus && (
                    <TableCell>
                      <Checkbox
                        checked={jobsData.jobOverviewPage.items.length === selectedJobs.length}
                        indeterminate={
                          selectedJobs.length > 0 &&
                          jobsData.jobOverviewPage.items.length < selectedJobs.length
                        }
                        onChange={(_event, checked) => {
                          if (checked) {
                            setSelectedJobs([...jobsData.jobOverviewPage.items])
                          } else {
                            setSelectedJobs([])
                          }
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell>Actions</TableCell>
                  <TableCell>Expedock Job Code</TableCell>
                  <TableCell>Customer Job ID</TableCell>
                  <TableCell>Job Type</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Job Name</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>QA</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>SLA Time Left</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsData.jobOverviewPage.items.map((job) => (
                  <TableRow key={job.id}>
                    {isInBulkExportStatus && (
                      <TableCell>
                        <Checkbox
                          checked={
                            selectedJobs.find((selectedJob) => selectedJob.id === job.id) != null
                          }
                          onChange={(_event, checked) => {
                            if (checked) {
                              setSelectedJobs([job, ...selectedJobs])
                            } else {
                              setSelectedJobs(
                                selectedJobs.filter((selectedJob) => selectedJob.id !== job.id),
                              )
                            }
                          }}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      {job.status === JobStatus.Deleted ? (
                        job.taskId == null ? (
                          <Tooltip title="This job's task was deleted, and so cannot be restored">
                            <span>
                              <IconButton size='small' disabled>
                                <RestoreFromTrashIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        ) : (
                          <IconButton size='small' onClick={() => onRestoreJob(job)}>
                            <RestoreFromTrashIcon />
                          </IconButton>
                        )
                      ) : (
                        <>
                          <IconButton href={getJobLink(job)} data-testid={`edit-${job.name}-btn`}>
                            <DescriptionIcon />
                          </IconButton>
                          <IconButton
                            size='small'
                            onClick={() => onDeleteJob(job)}
                            data-testid={`delete-${job.name}-btn`}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                          <IconButton size='small' onClick={() => onDuplicateJob(job)}>
                            <FileCopyIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                    <TableCell>{job.jobCode || 'N/A'}</TableCell>
                    <TableCell>{job.clientReferenceNo || 'N/A'}</TableCell>
                    <TableCell>{job.jobTemplate.name || 'N/A'}</TableCell>
                    <TableCell>{job.jobTemplate.company.name || 'N/A'}</TableCell>
                    <TableCell>{job.name || 'N/A'}</TableCell>
                    <TableCell>
                      <JobOwnerSelect job={job} refetchJobs={refetchJobs} />
                    </TableCell>
                    <TableCell>
                      <JobQASelect job={job} refetchJobs={refetchJobs} />
                    </TableCell>
                    <TableCell>{JOB_STATUS[job.status].toUpperCase()}</TableCell>
                    <TableCell>{getSLATimeLeft(job.dateCreated, job.slaTime)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(jobsData.jobOverviewPage.total / JOBS_PER_PAGE)}
            page={pageNumber}
            onChange={wrappedOnPageChange}
          />
        </>
      )}
      <IdentifyTaskDialog
        isOpen={isIdentifyTaskDialogOpen}
        closePopup={(): void => {
          setIsIdentifyTaskDialogOpen(false)
        }}
        refetchJobs={refetchJobs}
      />
      <ProofDialog
        isOpen={isProofDialogOpen}
        jobIds={selectedJobs.map((job: JobNode) => job.id)}
        closePopup={(): void => {
          setIsProofDialogOpen(false)
        }}
        onDoneSuccess={() => {
          // Refetch the dashboard to reflect changes
          // and clear selected jobs
          void refetchJobs()
          setSelectedJobs([])
        }}
      />
    </Box>
  )
}

export default JobsPage
