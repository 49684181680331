import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState } from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import theme from '@src/utils/theme'

import ActionButtons from './ActionButtons'
import VendorCodeTable from './VendorCodeTable'
import VendorMetadataTable from './VendorMetadataTable'
import VendorSearchBar from './VendorSearchBar'
import { useEffect } from 'react'
import {
  CompanyNode,
  InputChargeCode,
  Maybe,
  Mutation,
  MutationCreateChargeVendorWithChargeCodesArgs,
  MutationEditChargeVendorArgs,
  Query,
  QueryVendorChargeCodesArgs,
  useCreateChargeVendorAndOverridesMutation,
  useEditChargeVendorAndOverridesMutation,
  useVendorChargeCodeOverridesLazyQuery,
} from '@src/graphql/types'
import { HotTable } from '@handsontable/react'
import { useRef } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CREATE_CHARGE_VENDOR_WITH_CHARGE_CODES,
  UPDATE_CHARGE_VENDOR_DETAILS,
} from '@src/graphql/mutations/recon'
import { CHARGE_VENDOR_CODES } from '@src/graphql/queries/recon'
import DeleteVendorModal from './DeleteVendorModal'
import CopyVendorModal from './CopyVendorModal'
import CreateVendorModal from './CreateVendorModal'
import CreateButtons from './CreateButtons'
import CancelCreateVendorModal from './CancelCreateVendorModal'
import { useSnackbar } from 'notistack'
import VendorMappingHeader from './VendorMappingHeader'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import useCompanyData from '@src/hooks/admin/useCompanyData'
import VendorCodeOverridesTable from './VendorCodeOverridesTable'
import DeleteVendorOverridesModal from './DeleteVendorOverridesModal'
import { ChargeCodeOverridesArray, ChargeCodesArray, SelectedChargeVendor } from './types'
import CopyVendorOverridesModal from './CopyVendorOverridesModal'

type Props = {
  company: Maybe<CompanyNode>
  companyId: string
}

const VENDOR_CHARGE_CODE_PAGE_SIZE = 1000

// We pass in both company and companyId because company is a Maybe type and may be null
const VendorMapping: FunctionComponent<Props> = ({ company, companyId }) => {
  const [selectedVendor, setSelectedVendor] = useState<SelectedChargeVendor>()
  const [currVendorName, setCurrVendorName] = useState('')
  const [currVendorCode, setCurrVendorCode] = useState('')
  const [currVendorType, setCurrVendorType] = useState('')

  const [chargeCodes, setChargeCodes] = useState<ChargeCodesArray>([])
  const [chargeCodeOverrides, setChargeCodeOverrides] = useState<ChargeCodeOverridesArray>([])

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [copyModalOpen, setCopyModalOpen] = useState(false)
  const [newVendorModalOpen, setNewVendorModalOpen] = useState(false)
  const [createNewVendorMode, setCreateNewVendorMode] = useState(false)
  const [cancelCreateNewVendorModalOpen, setCancelCreateNewVendorModalOpen] = useState(false)

  const [refreshSearch, setRefreshSearch] = useState(false)
  const { apiPartners, usesChargeCodeV2 } = useCompanyData(company)

  const [currApiPartnerId, setCurrApiPartnerId] = useState<string | null>(null)
  const [vendorChargeCodePage, setVendorChargeCodePage] = useState(0)
  const { enqueueSnackbar } = useSnackbar()
  const hotTableRef = useRef<HotTable | undefined>()

  const [updateChargeVendorDetails] = useMutation<
    Pick<Mutation, 'editChargeVendor'>,
    MutationEditChargeVendorArgs
  >(UPDATE_CHARGE_VENDOR_DETAILS, {
    onCompleted: () => {
      enqueueSnackbar('Charge vendor details updated', { variant: 'success' })
      setRefreshSearch(true)
    },
    onError: (error) => {
      enqueueSnackbar(`Error updating details: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const [updateChargeVendorAndOverrides] = useEditChargeVendorAndOverridesMutation({
    onCompleted: () => {
      enqueueSnackbar('Charge vendor details updated', { variant: 'success' })
      setRefreshSearch(true)
    },
    onError: (error) => {
      enqueueSnackbar(`Error updating details: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const resetVendorFields = (): void => {
    setCurrVendorName('')
    setCurrVendorCode('')
    setCurrVendorType('')
    setSelectedVendor(undefined)
    setChargeCodes([])
    setChargeCodeOverrides([])
    setCurrApiPartnerId(null)
  }

  const resetMappingState = (): void => {
    setCreateNewVendorMode(false)
    resetVendorFields()
    setRefreshSearch(true)
  }

  const [createChargeVendorWithChargeCodes] = useMutation<
    Pick<Mutation, 'createChargeVendorWithChargeCodes'>,
    MutationCreateChargeVendorWithChargeCodesArgs
  >(CREATE_CHARGE_VENDOR_WITH_CHARGE_CODES, {
    onCompleted: () => {
      enqueueSnackbar('Charge vendor created', { variant: 'success' })
      resetMappingState()
    },
    onError: (error) => {
      enqueueSnackbar(`Error creating vendor: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const [createChargeVendorAndOverrides] = useCreateChargeVendorAndOverridesMutation({
    onCompleted: () => {
      enqueueSnackbar('Charge vendor created', { variant: 'success' })
      resetMappingState()
    },
    onError: (error) => {
      enqueueSnackbar(`Error creating vendor: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const [vendorChargeCodes, { loading: loadingVendorChargeCodes }] = useLazyQuery<
    Pick<Query, 'vendorChargeCodes'>,
    QueryVendorChargeCodesArgs
  >(CHARGE_VENDOR_CODES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const codes = data!.vendorChargeCodes
      setChargeCodes(codes)
    },
  })

  const [getVendorChargeCodeOverrides, { loading: loadingVendorOverrides }] =
    useVendorChargeCodeOverridesLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.vendorChargeCodeOverrides) {
          setChargeCodeOverrides(data.vendorChargeCodeOverrides)
        }
      },
    })

  useEffect(() => {
    if (selectedVendor) {
      if (usesChargeCodeV2) {
        void getVendorChargeCodeOverrides({
          variables: {
            chargeVendorId: selectedVendor!.id,
            pageLimit: VENDOR_CHARGE_CODE_PAGE_SIZE,
            pageOffset: vendorChargeCodePage,
            apiPartnerId: currApiPartnerId,
          },
        })
      } else {
        void vendorChargeCodes({
          variables: {
            chargeVendorId: selectedVendor.id,
            pageLimit: VENDOR_CHARGE_CODE_PAGE_SIZE,
            pageOffset: vendorChargeCodePage,
            apiPartnerId: currApiPartnerId,
          },
        })
      }
    }
  }, [
    selectedVendor,
    usesChargeCodeV2,
    vendorChargeCodes,
    getVendorChargeCodeOverrides,
    vendorChargeCodePage,
    currApiPartnerId,
  ])

  const constructChargeCodesFromTable = (): InputChargeCode[] => {
    const hotTable = hotTableRef?.current
    if (hotTable) {
      const tableData = hotTable.hotInstance.getData()
      const chargeCodes = tableData
        .filter(([description, code]) => description && code)
        .map(([description, code]) => ({
          code,
          description,
        }))
      return chargeCodes
    }
    return []
  }

  const saveVendorDetails = async (): Promise<void> => {
    if (usesChargeCodeV2) {
      await updateChargeVendorAndOverrides({
        variables: {
          chargeVendorId: selectedVendor?.id ?? '',
          name: currVendorName,
          code: currVendorCode,
          type: currVendorType,
          chargeCodeOverrides: constructChargeCodesFromTable(),
          apiPartnerId: currApiPartnerId,
          codesLimit: VENDOR_CHARGE_CODE_PAGE_SIZE,
          codesOffset: vendorChargeCodePage,
        },
      })
    } else {
      await updateChargeVendorDetails({
        variables: {
          chargeVendorId: selectedVendor?.id ?? '',
          name: currVendorName,
          code: currVendorCode,
          type: currVendorType,
          chargeCodes: constructChargeCodesFromTable(),
          apiPartnerId: currApiPartnerId,
          codesLimit: VENDOR_CHARGE_CODE_PAGE_SIZE,
          codesOffset: vendorChargeCodePage,
        },
      })
    }
  }

  const addNewVendorChargeCodes = (newVendorRows: string[][]): void => {
    const hotTable = hotTableRef?.current
    if (hotTable) {
      const instance = hotTable.hotInstance
      const startAtRow =
        chargeCodes.length > 0 || chargeCodeOverrides.length > 0 ? instance.countRows() : 0
      instance.populateFromArray(startAtRow, 0, newVendorRows)
    }
  }

  const createNewChargeVendor = (): void => {
    if (usesChargeCodeV2) {
      void createChargeVendorAndOverrides({
        variables: {
          companyId,
          name: currVendorName,
          code: currVendorCode,
          type: currVendorType,
          chargeCodeOverrides: constructChargeCodesFromTable(),
          apiPartnerId: currApiPartnerId || '',
        },
      })
    } else {
      void createChargeVendorWithChargeCodes({
        variables: {
          companyId,
          name: currVendorName,
          code: currVendorCode,
          type: currVendorType,
          chargeCodes: constructChargeCodesFromTable(),
          apiPartnerId: currApiPartnerId,
        },
      })
    }
  }

  const confirmCreateVendor = (): void => {
    setCreateNewVendorMode(true)
    setNewVendorModalOpen(false)
    resetVendorFields()
  }

  return (
    <Paper data-testid='vendor-mapping'>
      <VendorMappingHeader newVendor={() => setNewVendorModalOpen(true)} />
      <Box display='flex'>
        <Box borderRight={`1px solid ${theme.palette.grey[500]}`} width='25%'>
          <VendorSearchBar
            vendor={selectedVendor}
            refreshSearch={refreshSearch}
            setRefreshSearch={setRefreshSearch}
            companyId={companyId}
            setVendor={setSelectedVendor}
            setVendorChargeCodePage={setVendorChargeCodePage}
          />
        </Box>
        <Box bgcolor={theme.palette.grey[200]} width='75%'>
          <Box minHeight='80vh'>
            <Box paddingBottom={2}>
              <VendorMetadataTable
                vendor={selectedVendor}
                currVendorName={currVendorName}
                setCurrVendorName={setCurrVendorName}
                currVendorCode={currVendorCode}
                setCurrVendorCode={setCurrVendorCode}
                currVendorType={currVendorType}
                setCurrVendorType={setCurrVendorType}
                createNewVendorMode={createNewVendorMode}
                apiPartners={apiPartners}
                currApiPartnerId={currApiPartnerId}
                setCurrApiPartnerId={setCurrApiPartnerId}
              />
            </Box>
            {createNewVendorMode ||
            (selectedVendor != null && !loadingVendorChargeCodes && !loadingVendorOverrides) ? (
              <>
                {loadingVendorChargeCodes || loadingVendorOverrides ? (
                  <CenteredCircularProgress />
                ) : (
                  <>
                    {usesChargeCodeV2 ? (
                      <VendorCodeOverridesTable
                        chargeCodeOverrides={chargeCodeOverrides}
                        hotTableRef={hotTableRef}
                        companyId={companyId}
                        apiPartnerId={currApiPartnerId}
                        pageSize={VENDOR_CHARGE_CODE_PAGE_SIZE}
                        page={vendorChargeCodePage}
                        setPage={setVendorChargeCodePage}
                      />
                    ) : (
                      <VendorCodeTable
                        chargeCodes={chargeCodes}
                        hotTableRef={hotTableRef}
                        vendorChargeCodePageSize={VENDOR_CHARGE_CODE_PAGE_SIZE}
                        vendorChargeCodePage={vendorChargeCodePage}
                        setVendorChargeCodePage={setVendorChargeCodePage}
                        createNewVendorMode={createNewVendorMode}
                        currApiPartnerId={currApiPartnerId}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {loadingVendorChargeCodes ? (
                  <CenteredCircularProgress />
                ) : (
                  <Typography align='center'>Select a vendor to edit details</Typography>
                )}
              </>
            )}
            {createNewVendorMode ? (
              <CreateButtons
                create={createNewChargeVendor}
                cancel={() => setCancelCreateNewVendorModalOpen(true)}
              />
            ) : (
              <ActionButtons
                disabled={!selectedVendor}
                save={saveVendorDetails}
                copy={() => setCopyModalOpen(true)}
                del={() => setDeleteModalOpen(true)}
                companyId={companyId}
              />
            )}
          </Box>
        </Box>
      </Box>
      {deleteModalOpen && selectedVendor && usesChargeCodeV2 && (
        <DeleteVendorOverridesModal
          vendor={selectedVendor}
          setOpen={setDeleteModalOpen}
          chargeCodeOverrides={chargeCodeOverrides}
          resetMappingState={resetMappingState}
        />
      )}
      {deleteModalOpen && !usesChargeCodeV2 && (
        <DeleteVendorModal
          vendor={selectedVendor}
          setOpen={setDeleteModalOpen}
          chargeCodes={chargeCodes}
          resetMappingState={resetMappingState}
        />
      )}
      {copyModalOpen && !usesChargeCodeV2 && (
        <CopyVendorModal
          initCompany={company}
          vendor={selectedVendor}
          setOpen={setCopyModalOpen}
          chargeCodes={chargeCodes}
          addNewVendorChargeCodes={addNewVendorChargeCodes}
          currApiPartnerId={currApiPartnerId}
        />
      )}
      {copyModalOpen && usesChargeCodeV2 && (
        <CopyVendorOverridesModal
          company={company}
          vendor={selectedVendor}
          setOpen={setCopyModalOpen}
          addNewVendorChargeCodes={addNewVendorChargeCodes}
          apiPartnerId={currApiPartnerId}
        />
      )}
      {newVendorModalOpen && (
        <CreateVendorModal setOpen={setNewVendorModalOpen} confirmCreate={confirmCreateVendor} />
      )}
      {cancelCreateNewVendorModalOpen && (
        <CancelCreateVendorModal
          setOpen={setCancelCreateNewVendorModalOpen}
          cancelCreateNewVendor={() => setCreateNewVendorMode(false)}
        />
      )}
    </Paper>
  )
}

export default VendorMapping
