import { type FC } from 'react'
import {
  BaseReconHistoryProps,
  DisplayHistoryOrLoading,
  ReconHistoryContainer,
} from './ReconTypeHistory'
import { ApReconItems } from '.'
import { useApReconAttemptsHistoryQuery } from '@src/graphql/types'
import ReconHistoryTable from './ReconHistoryTable'
import LoadMoreButton from './LoadMoreButton'
import { formatMaybeApolloError } from '@src/utils/errors'
import { useSnackbar } from 'notistack'

type APReconHistoryProps = BaseReconHistoryProps & {
  reconAttempts: ApReconItems
  setReconAttempts: (reconAttempts: ApReconItems) => void
}

const APReconHistory: FC<APReconHistoryProps> = ({
  job,
  getSavedReconsOnly,
  nextMarker,
  reconAttempts,
  setReconAttempts,
  loadMoreReconHistory,
  handleRowClick,
  switchLoading,
  setSwitchLoading,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const { data: reconAttemptsHistory, loading: apReconHistoryLoading } =
    useApReconAttemptsHistoryQuery({
      variables: { jobId: job.id, getSavedOnly: getSavedReconsOnly, marker: nextMarker },
      onCompleted: (reconAttemptsHistory) => {
        const { apReconAttemptsHistory } = reconAttemptsHistory
        if (apReconAttemptsHistory.hasPrevious) {
          setReconAttempts(reconAttempts.concat(apReconAttemptsHistory.results))
        } else {
          setReconAttempts(apReconAttemptsHistory.results)
        }
        setSwitchLoading(false)
      },
      onError: (e) => {
        enqueueSnackbar(`${formatMaybeApolloError(e)}`, {
          variant: 'warning',
        })
      },
    })

  return (
    <ReconHistoryContainer>
      <DisplayHistoryOrLoading
        switchLoading={switchLoading}
        reconHistoryLoading={apReconHistoryLoading}
      >
        {reconAttempts.map((reconAttempt) => (
          <ReconHistoryTable
            key={reconAttempt.id}
            job={job}
            handleRowClick={handleRowClick}
            reconAttempts={[reconAttempt]}
            reconItem={reconAttempt}
          />
        ))}
      </DisplayHistoryOrLoading>
      <LoadMoreButton
        loading={apReconHistoryLoading}
        hasNextPage={reconAttemptsHistory?.apReconAttemptsHistory.hasNext ?? false}
        loadMoreCallback={() =>
          loadMoreReconHistory(reconAttemptsHistory?.apReconAttemptsHistory.nextMarker)
        }
      />
    </ReconHistoryContainer>
  )
}

export default APReconHistory
