import {
  checkMissingFields,
  formatMaybeApolloError,
  validateSoaTableFields,
} from '@src/utils/errors'
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FieldError, FormProvider, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { BoxingContext } from '@src/contexts/boxing_context'
import { batch, useDispatch, useSelector } from 'react-redux'
import store, { RootState } from '@src/utils/store'
import {
  deleteOneFieldCoordinates,
  setActiveFieldKey,
  setDocTableValidationErrors,
  setLineItemsTableSubmitted,
  updatePageFieldEditorState,
} from '@src/redux-features/document_editor'
import { jobTableLineItemSelectors } from '@src/redux-features/document_editor/job_table'
import { documentTableSelectors } from '@src/redux-features/document_editor/document_table'
import {
  FieldNode,
  InputDocument,
  JobNode,
  JobStatus,
  JobTemplateReconType,
  Maybe,
  Mutation,
  MutationBatchAttachDocumentToShipmentArgs,
  MutationSaveDocumentFieldsArgs,
} from '@src/graphql/types'

import { setCurrentFilePageId } from '@src/redux-features/job_editor'

import { useMutation } from '@apollo/client'
import {
  BATCH_ATTACH_DOCUMENT_TO_SHIPMENT,
  SAVE_DOCUMENT_FIELDS,
} from '@src/graphql/mutations/document'
import { COUNT_BY_JOB_STATUS } from '@src/graphql/queries/job'
import { QA_JOB } from '@src/graphql/mutations/job'
import { ShipmentFormContext } from '@src/contexts/shipment_form_context'
import { validateTables } from '@src/utils/line_items'
import {
  consolidateFieldMappings,
  createInputDocumentTables,
  formatToInputJobTable,
  getDocumentTypesFieldGroups,
  getFieldGroupsNonRepeatableFields,
  getJobDocumentTypes,
  scrollOnError,
} from '@src/utils/shipment_form'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import theme from '@src/utils/theme'
import { JOB_STATUS } from '@src/utils/app_constants'
import AssignToQADialog from '@src/components/AssignToQADialog'
import { TASK_DETAIL } from '@src/graphql/queries/task'
import ShipmentActions, { ActionSet } from './ShipmentActions'
import ShipmentFields from './ShipmentFields'
import DocumentTables from './DocumentTables'
import { useHistory } from 'react-router-dom'
import {
  selectFormattedJobTableColumns,
  selectFormattedJobTableRows,
} from '@src/redux-features/document_editor/rows_columns_selectors'

const useStyles = makeStyles({
  shipmentForm: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    padding: `0 ${theme.spacing(1)}px`,
  },
  formFields: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flexGrow: 1,
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: theme.palette.background.paper,
    zIndex: theme.zIndex.tooltip,
  },
})

type Props = {
  filePageId: string
  job: JobNode
  refetchJob: () => Promise<void>
  readOnly: boolean
  actionSet: ActionSet
  jobOtherInfo: string
}

const ShipmentForm: FunctionComponent<Props> = ({
  filePageId,
  job,
  refetchJob,
  readOnly,
  actionSet,
  jobOtherInfo,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const shouldSaveJobTable = job.jobTemplate.reconType === JobTemplateReconType.Soa
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { fieldMapRef } = useContext(BoxingContext)
  const { setUpdateDocumentMapping, setSaveJob, setValidateFields, setValidateFieldsSoa } =
    useContext(ShipmentFormContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showAssignQaToJobDialog, setShowAssignQaToJobDialog] = useState(false)
  const [qaCorrections, setQACorrections] = useState({} as Record<string, string[]>)
  const [batchAttachDocumentToShipment] = useMutation<
    Pick<Mutation, 'batchAttachDocumentToShipment'>,
    MutationBatchAttachDocumentToShipmentArgs
  >(BATCH_ATTACH_DOCUMENT_TO_SHIPMENT, {
    refetchQueries: [{ query: TASK_DETAIL, variables: { id: job.task!.id } }],
  })
  const [saveDocumentFields] = useMutation<
    Pick<Mutation, 'saveDocumentFields'>,
    MutationSaveDocumentFieldsArgs
  >(SAVE_DOCUMENT_FIELDS)
  const [qaJob, { error: qaJobError }] = useMutation<Pick<Mutation, 'qaJob'>>(QA_JOB, {
    refetchQueries: [{ query: COUNT_BY_JOB_STATUS }],
  })

  const pageFieldEditorState = useSelector(
    (state: RootState) => state.documentEditor.pageFieldEditorState,
  )
  const documentTables = useSelector((state: RootState) =>
    documentTableSelectors.selectAll(state.documentEditor),
  )
  const jobTableLineItems = useSelector((state: RootState) =>
    jobTableLineItemSelectors.selectAll(state.documentEditor),
  )
  const lineItemsTableMap = useSelector(
    (state: RootState) => state.documentEditor.lineItemsTableMap,
  )
  const magicGridMap = useSelector((state: RootState) => state.documentEditor.magicGridMap)
  const documentInitialized = useSelector(
    (state: RootState) =>
      filePageId != null && state.documentEditor.activeFilePageId === filePageId,
  )
  const jobTableColumns = useSelector(
    (state: RootState) => state.documentEditor.jobTableState?.columns ?? [],
  )
  const dispatch = useDispatch()

  const {
    documentTypes,
    documentTypeFieldGroups,
    nonRepeatableFieldKeys,
    jobRepeatableFieldGroups,
  } = useMemo(() => {
    const _documentTypes = getJobDocumentTypes(job)
    const _documentTypeFieldGroups = getDocumentTypesFieldGroups(_documentTypes)
    const _nonRepeatableFields = getFieldGroupsNonRepeatableFields(_documentTypeFieldGroups)
    const _nonRepeatableFieldKeys = _nonRepeatableFields.map((field: FieldNode) => field!.key) || []
    const _jobRepeatableFieldGroups = _documentTypeFieldGroups.filter(
      (docTypeFieldGroup) => docTypeFieldGroup.repeatable,
    )
    return {
      documentTypes: _documentTypes,
      documentTypeFieldGroups: _documentTypeFieldGroups,
      nonRepeatableFieldKeys: _nonRepeatableFieldKeys,
      jobRepeatableFieldGroups: _jobRepeatableFieldGroups,
    }
  }, [job])
  const jobInQA = actionSet === ActionSet.QA

  const formMethods = useForm({
    mode: 'all',
    defaultValues: consolidateFieldMappings(pageFieldEditorState, job),
  })
  const {
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = formMethods

  const updateDocumentMapping = useCallback(
    (newFilePageId?: string): void => {
      // Update shadow form <> document mapping of the shipment form
      const values = getValues()
      // diff values and key<>value cache to get real cache values
      const shouldUpdateEditorState =
        values != null && documentTypeFieldGroups != null && Object.keys(values).length
      if (shouldUpdateEditorState && job) {
        dispatch(updatePageFieldEditorState(values, newFilePageId))
      }
    },
    [getValues, documentTypeFieldGroups, job, dispatch],
  )
  useEffect(() => {
    setUpdateDocumentMapping(() => updateDocumentMapping)
  }, [setUpdateDocumentMapping, updateDocumentMapping])

  const { setGetFormValues } = useContext(ShipmentFormContext)
  useEffect(() => {
    setGetFormValues(() => getValues)
  }, [getValues, setGetFormValues])

  useEffect(() => {
    scrollOnError(errors)
  }, [errors])

  const focusField = useCallback(
    (fieldKey: string): void => {
      const selectedFieldRef = fieldMapRef.current[fieldKey]
      selectedFieldRef?.current?.element.current?.focus()
    },
    [fieldMapRef],
  )

  const setActiveFieldAndFocus = useCallback(
    (fieldKey: string): void => {
      dispatch(setActiveFieldKey(fieldKey))
      focusField(fieldKey)
    },
    [dispatch, focusField],
  )

  const handleQaCorrectionToggle = useCallback(
    (id: string, newQACorrections: string[]): void => {
      const newCorrectionsObj = { ...qaCorrections }
      newCorrectionsObj[id] = newQACorrections
      setQACorrections(newCorrectionsObj)
    },
    [qaCorrections],
  )

  const saveQAChanges = useCallback(async (): Promise<void> => {
    try {
      await qaJob({
        variables: {
          jobId: job.id,
          fieldsMissingInformation: Object.keys(qaCorrections).filter((field) =>
            qaCorrections[field].includes('missing'),
          ),
          fieldsWrongInformation: Object.keys(qaCorrections).filter((field) =>
            qaCorrections[field].includes('wrong'),
          ),
          fieldsTypos: Object.keys(qaCorrections).filter((field) =>
            qaCorrections[field].includes('typo'),
          ),
          otherInfo: jobOtherInfo,
        },
      })
      if (qaJobError) {
        throw qaJobError
      }
      enqueueSnackbar('QA Changes Saved', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Unable to save QA changes: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
      throw error
    }
  }, [enqueueSnackbar, job, jobOtherInfo, qaCorrections, qaJob, qaJobError])
  // this is a mild memory leak, but we're not staying on this page that long anyways
  // add any missing "ref"s
  nonRepeatableFieldKeys.forEach((fieldKey: string) => {
    if (!(fieldKey in fieldMapRef.current)) {
      // eslint-disable-next-line no-param-reassign
      fieldMapRef.current[fieldKey] = { current: null }
    }
  })

  const soaColumns = useSelector((state: RootState) =>
    selectFormattedJobTableColumns(state.documentEditor),
  )
  const soaRows = useSelector((state: RootState) =>
    selectFormattedJobTableRows(state.documentEditor),
  )
  const validateSoaTable = useCallback((): boolean => {
    const isSoaTableValid = validateSoaTableFields(soaColumns, soaRows)
    return isSoaTableValid
  }, [soaColumns, soaRows])

  const checkSoaTableMissingFields = useCallback((): string | null => {
    const missingFields = checkMissingFields(soaColumns, soaRows)
    return missingFields
  }, [soaColumns, soaRows])

  const validateAllFieldsSOA = useCallback((): Promise<void> => {
    updateDocumentMapping()
    return new Promise((resolve, reject): void => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleSubmit(
        () => {
          const missingFieldsError = checkSoaTableMissingFields()
          if (missingFieldsError !== null) {
            reject(new Error(missingFieldsError))
            return
          }
          const soaTableIsValid = validateSoaTable()
          if (!soaTableIsValid) {
            reject(
              new Error(
                `Validation failed for the Main SOA Table. Please ensure fields are valid before reconciling.`,
              ),
            )
            return
          }
          resolve()
        },
        () => {
          reject(
            new Error(
              'Not all field values in the shipment form are valid. Please revise them to resolve this issue.',
            ),
          )
        },
      )()
    })
  }, [updateDocumentMapping, handleSubmit, validateSoaTable, checkSoaTableMissingFields])

  const validateAllFields = useCallback((): Promise<void> => {
    updateDocumentMapping()
    return new Promise((resolve, reject): void => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleSubmit(
        () => {
          try {
            const tablesWithErrors = validateTables(
              Object.values(documentTables),
              jobRepeatableFieldGroups,
              lineItemsTableMap,
            )
            dispatch(setDocTableValidationErrors(tablesWithErrors))
            if (Object.keys(tablesWithErrors).length > 0) {
              reject(
                new Error(
                  'Not all line item values are valid. Please revise invalid table values to resolve this issue.',
                ),
              )
              return
            }
            resolve()
          } catch (e) {
            // validateTables can throw
            reject(e)
          }
        },
        () => {
          reject(
            new Error(
              'Not all field values in the shipment form are valid. Please revise them to resolve this issue.',
            ),
          )
        },
      )()
    })
  }, [
    dispatch,
    documentTables,
    handleSubmit,
    jobRepeatableFieldGroups,
    lineItemsTableMap,
    updateDocumentMapping,
  ])

  const getDocumentsToSave = useCallback((): InputDocument[] => {
    const { pageFieldEditorState: newPageFieldEditorState } = store.getState().documentEditor
    const pageIdMap = Object.fromEntries(
      job.filePages!.edges.map((edge) => edge!.node!).map((page) => [page.id, page]),
    )
    return Object.entries(newPageFieldEditorState!.pages)
      .filter(([pageId]) => {
        // there's a weird bug where a file page has no associated document which
        // prevents saving the job, we hotfix that by filtering null filePage.documents
        const document = pageIdMap[pageId].document
        return !!document
      })
      .map(
        ([
          pageId,
          { fieldMapping: documentFieldMapping, fieldCoordinates: documentFieldCoordinates },
        ]) => {
          const documentId = pageIdMap[pageId].document!.id
          return {
            id: documentId,
            fieldMapping: documentFieldMapping,
            fieldCoordinates: documentFieldCoordinates || {},
            documentTables: documentTables
              ? createInputDocumentTables(
                  documentTables,
                  documentId,
                  lineItemsTableMap,
                  magicGridMap,
                )
              : [],
          }
        },
      )
  }, [documentTables, job.filePages, lineItemsTableMap, magicGridMap])

  const saveNonRepeatableFields = useCallback(async (): Promise<void> => {
    updateDocumentMapping()

    const documentsToSave = getDocumentsToSave()
    await saveDocumentFields({
      variables: {
        documents: documentsToSave,
        jobId: job.id,
      },
    })
  }, [getDocumentsToSave, job.id, saveDocumentFields, updateDocumentMapping])

  const saveAllFields = useCallback(
    async (newJobStatus?: JobStatus): Promise<void> => {
      const savingStartedSnackbar = enqueueSnackbar('Saving job details...', { variant: 'info' })
      updateDocumentMapping()
      dispatch(setLineItemsTableSubmitted(true))
      const documentsToSave = getDocumentsToSave()
      const validateFields = !!newJobStatus

      const repeatableFieldKeyMap = jobRepeatableFieldGroups
        .flatMap((fieldGroup) => fieldGroup.fields!.edges!.map((fieldEdge) => fieldEdge!.node!))
        .reduce(
          (acc, field) => {
            acc[field.key] = field
            return acc
          },
          {} as Record<string, FieldNode>,
        )

      const inputJobTableToSave = shouldSaveJobTable
        ? formatToInputJobTable(
            jobTableLineItems || [],
            jobRepeatableFieldGroups[0].id,
            documentsToSave[0].id,
            repeatableFieldKeyMap,
            jobTableColumns,
          )
        : null

      await batchAttachDocumentToShipment({
        variables: {
          documents: documentsToSave,
          jobId: job.id,
          validateFields,
          newJobStatus: newJobStatus ? JOB_STATUS[newJobStatus] : null,
          jobTable: inputJobTableToSave,
        },
      })
      if (job.jobTemplate?.reconType === JobTemplateReconType.None) {
        await refetchJob()
      }
      closeSnackbar(savingStartedSnackbar)
      enqueueSnackbar('Saving job details successful!', { variant: 'success' })
    },
    [
      enqueueSnackbar,
      updateDocumentMapping,
      dispatch,
      getDocumentsToSave,
      jobRepeatableFieldGroups,
      shouldSaveJobTable,
      jobTableLineItems,
      jobTableColumns,
      batchAttachDocumentToShipment,
      job.id,
      job.jobTemplate?.reconType,
      closeSnackbar,
      refetchJob,
    ],
  )

  useEffect(() => {
    setSaveJob(() => saveAllFields)
  }, [saveAllFields, setSaveJob])

  useEffect(() => {
    setValidateFields(() => validateAllFields)
  }, [validateAllFields, setValidateFields])

  useEffect(() => {
    setValidateFieldsSoa(() => validateAllFieldsSOA)
  }, [validateAllFieldsSOA, setValidateFieldsSoa])

  const redirectToNextPage = useCallback(
    async (newJobStatus: JobStatus): Promise<void> => {
      const taskId = job?.task?.id ?? ''
      if (newJobStatus === JobStatus.Qa && !taskId) {
        // null tasks are a thing, in which redirecting to the job dash is fine
        history.push(`/jobs`)
      } else {
        history.push(`/tasks/${taskId}`)
      }
    },
    [job?.task?.id, history],
  )

  const saveAllAndRedirect = useCallback(
    async (nextJobStatus: JobStatus): Promise<void> => {
      try {
        setIsSubmitting(true)

        await saveAllFields(nextJobStatus)
        if (jobInQA) await saveQAChanges()
        await redirectToNextPage(nextJobStatus)
      } catch (error) {
        enqueueSnackbar(
          `There was an error saving your job details. ${formatMaybeApolloError(error)}`,
          {
            variant: 'error',
          },
        )
      } finally {
        setIsSubmitting(false)
      }
    },
    [enqueueSnackbar, jobInQA, redirectToNextPage, saveAllFields, saveQAChanges],
  )

  const onError = (errors: { [x: string]: FieldError | undefined }): void => {
    if (Object.values(errors).length) {
      const error = Object.values(errors)[0]
      let formattedError = formatMaybeApolloError(error)
      if (formattedError === '[object Object]') {
        formattedError = error?.message ?? 'Unknown, no error message found'
      }
      enqueueSnackbar(`Validation error: ${formattedError}`, {
        variant: 'error',
      })
    }
  }
  const submitForm = useCallback(async (): Promise<void> => {
    try {
      await validateAllFields()
      const nextJobStatus =
        job?.status === JobStatus.InProgress ? JobStatus.Qa : JobStatus.Confirmation
      if ([JobStatus.Done, JobStatus.Confirmation].includes(nextJobStatus) && job?.task?.blocked) {
        throw Error(
          `Task ${job?.task?.title} is currently blocked, please unblock it before moving this job to ${nextJobStatus}`,
        )
      }
      if (nextJobStatus === JobStatus.Qa && !job.qa?.id) {
        setShowAssignQaToJobDialog(true)
        return
      }
      await saveAllAndRedirect(nextJobStatus)
    } catch (error) {
      enqueueSnackbar(
        `There was an error saving your job details. ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    }
  }, [enqueueSnackbar, job?.qa?.id, job?.status, job?.task, saveAllAndRedirect, validateAllFields])

  const clearField = useCallback(
    (fieldKey: string): void => {
      dispatch(deleteOneFieldCoordinates(fieldKey))
      setValue(fieldKey, '')
    },
    [dispatch, setValue],
  )

  const maybeFindNextUnconfirmedFieldKey = useCallback(
    (fieldKey: string): Maybe<string> => {
      for (let offset = 1; offset < nonRepeatableFieldKeys.length; offset += 1) {
        const idx =
          (nonRepeatableFieldKeys.indexOf(fieldKey) + offset) % nonRepeatableFieldKeys.length
        if (!getValues(`${nonRepeatableFieldKeys[idx]}_confirmed`))
          return nonRepeatableFieldKeys[idx]
      }
      return null
    },
    [nonRepeatableFieldKeys, getValues],
  )

  const gotoNextUnconfirmedField = useCallback(
    (fieldKey: string): void => {
      const nextFieldKey = maybeFindNextUnconfirmedFieldKey(fieldKey)
      if (nextFieldKey) focusField(nextFieldKey)
    },
    [maybeFindNextUnconfirmedFieldKey, focusField],
  )

  const confirmField = useCallback(
    (fieldKey: string): void => {
      const confirmedFieldName = `${fieldKey}_confirmed`
      setValue(confirmedFieldName, 'true')

      gotoNextUnconfirmedField(fieldKey)
    },
    [setValue, gotoNextUnconfirmedField],
  )

  return (
    <>
      {documentInitialized || (
        <div className={classes.loadingOverlay}>
          <CenteredCircularProgress />
        </div>
      )}
      <FormProvider {...formMethods}>
        <form
          id='shipment-form'
          data-testid='shipment-form'
          onSubmit={handleSubmit(submitForm, onError)}
          className={classes.shipmentForm}
        >
          <div className={classes.formFields}>
            <ShipmentFields
              documentTypes={documentTypes}
              readOnly={readOnly}
              errors={errors}
              setActiveFieldAndFocus={setActiveFieldAndFocus}
              handleQaCorrectionToggle={handleQaCorrectionToggle}
              jobInQA={jobInQA}
              qaCorrections={qaCorrections}
            />
            <DocumentTables
              jobDocumentTables={documentTables}
              jobRepeatableFieldGroups={jobRepeatableFieldGroups}
              saveJob={saveAllFields}
              refetchJob={refetchJob}
              switchPage={async (nextFilePageId: string) => {
                await saveNonRepeatableFields()
                batch(() => {
                  updateDocumentMapping(nextFilePageId)
                  dispatch(setCurrentFilePageId(nextFilePageId))
                  dispatch(setActiveFieldKey(null))
                })
              }}
            />
          </div>

          <Box my={1}>
            <ShipmentActions
              fieldMapRef={fieldMapRef}
              isSubmitting={isSubmitting}
              clearField={clearField}
              confirmField={confirmField}
              actionSet={actionSet}
              job={job}
              validate={validateAllFields}
              validateSoa={validateAllFieldsSOA}
              save={saveAllFields}
              getValues={getValues}
            />
          </Box>
        </form>
      </FormProvider>
      <AssignToQADialog
        handleClickCancel={() => setShowAssignQaToJobDialog(false)}
        handleClickDone={async () => {
          await saveAllAndRedirect(JobStatus.Qa)
        }}
        submitting={isSubmitting}
        jobId={job.id}
        open={showAssignQaToJobDialog}
      />
    </>
  )
}

export default ShipmentForm
