import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'

import { FunctionComponent } from 'react'

type Props = {
  isOpen: boolean
  close: () => void
  onConfirm: () => void
}

const ConfirmGenerateDialog: FunctionComponent<Props> = ({ isOpen, close, onConfirm }) => {
  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle>{'Regenerate API Key?'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Regenerating this API key will revoke access to existing API partners using this key.
          Continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Continue</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmGenerateDialog
