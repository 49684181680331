import { HotTable } from '@handsontable/react'
import { Paper, Typography } from '@material-ui/core'
import FastHotTable from '@src/components/fast-hot-table/FastHotTable'
import { useChargeVendorsLazyQuery } from '@src/graphql/types'
import theme from '@src/utils/theme'
import { FunctionComponent, MutableRefObject, useMemo } from 'react'
import { ChargeCodeWithOverride } from './types'
import { debounce } from 'lodash'

type Props = {
  companyId?: string
  chargeCode: ChargeCodeWithOverride | undefined
  hotTableRef: MutableRefObject<HotTable | undefined>
}

const VendorOverrideCodesTable: FunctionComponent<Props> = ({
  companyId,
  chargeCode,
  hotTableRef,
}) => {
  const [fetchChargeVendors] = useChargeVendorsLazyQuery({
    fetchPolicy: 'network-only',
  })

  const columns = useMemo(() => {
    return [
      {
        key: 'vendor',
        name: 'Vendor',
        type: 'autocomplete',
        // Reference: https://handsontable.com/docs/javascript-data-grid/api/options/#source (docs for the version used in the project are not available, but this should be a good reference)
        // This allows us to do a fetch upon typing in the cell. A single fetch is not enough because the list of vendors can go beyond the page limit.
        source: debounce(async (query: string, process: (data: unknown) => void) => {
          if (companyId) {
            const { data } = await fetchChargeVendors({
              variables: { companyId: companyId, query },
            })
            const vendors = data?.chargeVendors?.map((chargeVendor) => chargeVendor.name) ?? []
            const uniqueVendors = [...new Set(vendors)]
            process(uniqueVendors)
          }
        }, 300),
        strict: false,
      },
      {
        key: 'description',
        name: 'Description',
      },
    ]
  }, [companyId, fetchChargeVendors])

  const rows = useMemo(() => {
    if (chargeCode) {
      const overrides = chargeCode.chargeCodeVendorOverrides?.edges || []
      const newRows = overrides
        .filter((node) => node?.node?.dateDeleted == null)
        .map((node) => [node?.node?.chargeVendor?.name || '', node?.node?.description])

      if (newRows.length == 0) {
        newRows.push(['', ''])
      }

      return newRows
    } else {
      // Add a placeholder to allow adding new rows into the table
      return [['', '']]
    }
  }, [chargeCode])

  return (
    <>
      <Typography variant='h4' style={{ marginLeft: '20px', marginTop: '20px' }}>
        Vendor Overrides
      </Typography>
      <p style={{ marginLeft: '20px' }}>
        All vendors with no override will use the default charge code description
        {chargeCode?.description ? (
          <>
            {' '}
            <b>{chargeCode.description}</b>
          </>
        ) : null}
        .
      </p>
      <Paper data-testid='vendor-codes-table'>
        <FastHotTable
          data={rows}
          hotTableRef={hotTableRef}
          hooks={{}}
          style={{
            width: '100%',
            overflow: 'hidden',
            height: '480px',
            background: theme.palette.grey[200],
          }}
          settings={{
            rowHeaders: true,
            contextMenu: ['row_above', 'row_below', 'remove_row'],
            colHeaders: columns.map((col) => col.name),
            columnSorting: true,
            filters: true,
            autoRowSize: true,
            stretchH: 'all',
            columns,
          }}
        />
      </Paper>
    </>
  )
}

export default VendorOverrideCodesTable
