import { formatMaybeApolloError } from '@src/utils/errors'
import { ChangeEvent, FunctionComponent, useState } from 'react'
import { Box, Paper, TextField, Theme, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/core/styles'
import { useLazyQuery } from '@apollo/client'
import { SEARCH_TAXES } from '@src/graphql/queries/recon'
import theme from '@src/utils/theme'
import { useSnackbar } from 'notistack'
import { TaxNode, Query, QuerySearchTaxesArgs } from '@src/graphql/types'
import { useEffect } from 'react'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'

type Props = {
  setTax: (tax: TaxNode) => void
  apiPartnerId: string | null
  refreshSearch: boolean
  setRefreshSearch: (refreshSearch: boolean) => void
}

const useStyles = makeStyles<Theme>({
  searchBar: {
    display: 'block',
    flex: '1',
    padding: theme.spacing(2),
  },
  searchResult: {
    padding: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  maxResultsError: {
    color: theme.palette.error.main,
    padding: theme.spacing(1),
  },
  placeHolderResultMessage: {
    padding: theme.spacing(2),
  },
})

// so ops doesn't scroll through 10k taxes
const MAX_TAX_ADMIN_SEARCH_RESULTS = 50

const TaxSearchBar: FunctionComponent<Props> = ({
  apiPartnerId,
  setTax,
  refreshSearch,
  setRefreshSearch,
}) => {
  const classes = useStyles()
  const [searchResults, setSearchResults] = useState([] as TaxNode[])
  const [searchValue, setSearchValue] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const [searchTaxes, { loading: searchingTaxes }] = useLazyQuery<
    Pick<Query, 'searchTaxes'>,
    QuerySearchTaxesArgs
  >(SEARCH_TAXES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSearchResults(data.searchTaxes as TaxNode[])
    },
    onError: (error) => {
      enqueueSnackbar(`Error searching taxes: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  useEffect(() => {
    if (refreshSearch || apiPartnerId) {
      void searchTaxes({
        variables: {
          apiPartnerId,
          query: searchValue,
        },
      })
    }

    setRefreshSearch(false)
  }, [refreshSearch, apiPartnerId, searchValue, searchTaxes, setRefreshSearch])

  const searchForTaxes = (e: ChangeEvent<{ value: string }>): void => {
    setSearchValue((e!.target!.value! as unknown as string) || '')
  }

  if (searchingTaxes) {
    return <CenteredCircularProgress />
  }

  return (
    <>
      <TextField
        variant='outlined'
        fullWidth
        className={classes.searchBar}
        value={searchValue}
        onChange={searchForTaxes}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {searchResults.length >= MAX_TAX_ADMIN_SEARCH_RESULTS && (
        <Typography className={classes.maxResultsError}>
          Only showing the first {searchResults.length} results. Filter by name to show the rest.
        </Typography>
      )}
      {searchResults.length == 0 && (
        <Typography align='center' className={classes.placeHolderResultMessage}>
          No taxes found matching the search criteria. Please select a cargowise config / api
          partner or try other search values
        </Typography>
      )}
      <Box maxHeight={`${theme.spacing(80)}px`} overflow='scroll'>
        {searchResults.map((sr: TaxNode) => (
          <Paper key={sr.id} className={classes.searchResult} onClick={() => setTax(sr)}>
            <Typography> {sr.taxCode} </Typography>
          </Paper>
        ))}
      </Box>
    </>
  )
}

export default TaxSearchBar
