import { FunctionComponent, MouseEvent, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Chip, IconButton, Paper, Typography } from '@material-ui/core'
import { JobStatus, Mutation, MutationStarTaskArgs, TaskNode, TaskStatus } from '@src/graphql/types'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import theme from '@src/utils/theme'
import { COLORS } from '@src/utils/app_constants'
import { grey } from '@material-ui/core/colors'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import { clsx } from 'clsx'
import { isPresent } from 'ts-is-present'
import { uniqBy } from 'lodash'
import TaskCardHeader from '@src/components/tasks/TaskCardHeader'
import TaskCardUser from '@src/components/tasks/TaskCardUser'
import { getTaskSLATimeLeft } from '@src/utils/date'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { useMutation } from '@apollo/client'
import { TOGGLE_STAR_TASK } from '@src/graphql/mutations/task'
import { useSnackbar } from 'notistack'
import TaskJobMarkers from './TaskJobMarkers'
import SingleTaskDialog from './SingleTaskDialog'
import { DeployEnvironment, getDeployEnvironment } from '@src/utils/environment'

type Props = {
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  task: TaskNode
  refetchColumns: () => void
  deleteTask: (taskId: string) => void
}

const BODY_SECTION_PADDING = theme.spacing(1)
const JOB_MARKERS_HEIGHT = 25
const CARD_PADDING = theme.spacing(1)
const DETAIL_LEFT_COL_SIZE = 3
const DETAIL_RIGHT_COL_SIZE = 9
const TASK_CARD_HEADER_COLOR = '#8C9397'
const OVERDUE_COLOR = '#9F5C58'
const JOB_TEMPLATE_PADDING = '4px'

const useStyles = makeStyles({
  titleAndSLA: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${CARD_PADDING}px`,
  },
  card: {
    textAlign: 'left',
    userSelect: 'none',
    margin: `0 0 ${theme.spacing(1)}px 0`,
  },
  cardNotDragging: {
    backgroundColor: COLORS.PURE_WHITE,
  },
  cardIsDragging: {
    backgroundColor: COLORS.ASTRONAUT,
  },
  bodySection: {
    overflow: 'hidden',
    padding: `${BODY_SECTION_PADDING}px ${CARD_PADDING}px ${BODY_SECTION_PADDING}px`,
    '& + &': {
      borderTop: `1px solid ${grey[400]}`,
    },
  },
  jobMarkers: {
    height: JOB_MARKERS_HEIGHT,
    overflow: 'hidden',
    padding: `0 ${CARD_PADDING}px`,
  },
  taskType: {
    flexGrow: 1,
  },
  inlineFlex: {
    display: 'inline-flex',
  },
  detailTitle: {
    color: grey[500],
    paddingRight: theme.spacing(1),
  },
  hr: {
    border: 'none',
    height: '1px',
    backgroundColor: grey[50],
  },
  userRow: {
    display: 'flex',
    alignItems: 'center',
    '& + &': {
      marginTop: theme.spacing(1),
    },
  },
  avatar: {
    marginRight: theme.spacing(1),
    display: 'block',
    '& > div': {
      fontSize: `${theme.typography.fontSize}px !important`,
    },
  },
  userName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    flex: '1',
  },
  taskHeaderBox: {
    padding: `${theme.spacing(1)}px`,
    borderRadius: '5px 5px 0 0',
    marginBottom: `${theme.spacing(1)}px`,
  },
  taskHeaderSlaIsNotCriticallyLow: {
    color: 'white',
    backgroundColor: TASK_CARD_HEADER_COLOR,
  },
  taskHeaderSlaIsCriticallyLow: {
    color: 'white',
    backgroundColor: OVERDUE_COLOR,
  },
  taskSlaIsNotCriticallyLow: {
    color: TASK_CARD_HEADER_COLOR,
    backgroundColor: 'white',
  },
  taskSlaIsCriticallyLow: {
    color: OVERDUE_COLOR,
  },
  taskTitle: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 3,
    wordBreak: 'break-all',
    overflow: 'hidden',
    fontSize: theme.typography.body1.fontSize,
  },
  taskSLALeftChip: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
  jobTemplateChip: {
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  refId: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  starButton: {
    padding: '0px',
    marginLeft: theme.spacing(1),
  },
  deleteButton: {
    padding: '0px',
    marginLeft: theme.spacing(1),
  },
  star: {
    fill: 'gold',
  },
})

const TaskCard: FunctionComponent<Props> = ({ deleteTask, provided, snapshot, task }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [isSingleTaskDialogOpen, setIsSingleTaskDialogOpen] = useState(false)
  const currentDeployEnv = getDeployEnvironment()
  const { owners, qas, jobTemplates } = useMemo(() => {
    const jobs = task
      .jobs!.edges.map((edge) => edge!.node!)
      .filter((job) => job.status !== JobStatus.Deleted)
    return {
      owners: uniqBy(jobs.map((job) => job.owner).filter(isPresent), (user) => user.id),
      qas: uniqBy(jobs.map((job) => job.qa).filter(isPresent), (user) => user.id),
      jobTemplates: uniqBy(
        jobs.map((job) => job.jobTemplate).filter(isPresent),
        (jobTemplate) => jobTemplate.id,
      ),
    }
  }, [task])
  const taskSlaLeft = getTaskSLATimeLeft(task)
  const [starTask] = useMutation<Pick<Mutation, 'starTask'>, MutationStarTaskArgs>(TOGGLE_STAR_TASK)

  const toggleTaskStar = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.stopPropagation()
    if (task.status === TaskStatus.Done) {
      return
    }
    const newStarStatus = !task.isStarred
    try {
      await starTask({
        variables: { taskId: task.id, starred: newStarStatus },
      })
    } catch {
      enqueueSnackbar('Could not toggle star on task', { variant: 'error' })
    }
  }

  return (
    <>
      <Paper
        elevation={3}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        onClick={() => setIsSingleTaskDialogOpen(true)}
        className={clsx(classes.card, {
          [classes.cardIsDragging]: snapshot.isDragging,
          [classes.cardNotDragging]: !snapshot.isDragging,
        })}
        data-testid={`task-card-${task.title}`}
      >
        <Box
          display='flex'
          className={clsx(classes.taskHeaderBox, {
            [classes.taskHeaderSlaIsNotCriticallyLow]: !task.isCriticallyLow,
            [classes.taskHeaderSlaIsCriticallyLow]: task.isCriticallyLow,
          })}
        >
          <TaskCardHeader task={task} />
          <Box display='flex'>
            {currentDeployEnv !== DeployEnvironment.PRODUCTION && (
              <IconButton className={classes.starButton} onClick={toggleTaskStar}>
                {task.isStarred ? <StarIcon className={classes.star} /> : <StarBorderIcon />}
              </IconButton>
            )}
            <IconButton className={classes.deleteButton} data-testid='task-card-delete-icon'>
              <DeleteIcon
                fontSize='small'
                onClick={(e) => {
                  deleteTask(task.id)
                  e.stopPropagation()
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <div className={classes.titleAndSLA}>
          <div className={classes.taskTitle}>{task.title}</div>
          {!task.blocked && (
            <Typography
              className={clsx(classes.taskSLALeftChip, {
                [classes.taskSlaIsNotCriticallyLow]: !task.isCriticallyLow,
                [classes.taskSlaIsCriticallyLow]: task.isCriticallyLow,
              })}
            >
              {taskSlaLeft}
            </Typography>
          )}
        </div>
        <Box display='flex' flexWrap='wrap' p={1}>
          {jobTemplates.map((jobTemplate) => {
            return (
              <Box
                mr={JOB_TEMPLATE_PADDING}
                mb={JOB_TEMPLATE_PADDING}
                key={`${jobTemplate.id}`}
                display='inline'
              >
                <Chip className={classes.jobTemplateChip} label={jobTemplate.name} size='small' />
              </Box>
            )
          })}
        </Box>
        <Grid container className={classes.bodySection}>
          <Grid item xs={DETAIL_LEFT_COL_SIZE}>
            <Typography variant='body2' className={classes.detailTitle}>
              Ref ID:
            </Typography>
          </Grid>
          <Grid item xs={DETAIL_RIGHT_COL_SIZE}>
            <Typography className={classes.refId} variant='body2'>
              {task.taskReferenceId}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.bodySection}>
          <Grid item xs={DETAIL_LEFT_COL_SIZE}>
            <Typography variant='body2' className={classes.detailTitle}>
              Owner:
            </Typography>
          </Grid>
          <Grid item xs={DETAIL_RIGHT_COL_SIZE}>
            {owners.map((user) => (
              <TaskCardUser user={user} key={user.id} />
            ))}
          </Grid>
        </Grid>
        <Grid container className={classes.bodySection}>
          <Grid item xs={DETAIL_LEFT_COL_SIZE}>
            <Typography variant='body2' className={classes.detailTitle}>
              QA:
            </Typography>
          </Grid>
          <Grid item xs={DETAIL_RIGHT_COL_SIZE}>
            {qas.map((user) => (
              <TaskCardUser user={user} key={user.id} />
            ))}
          </Grid>
        </Grid>
        <div className={classes.jobMarkers}>
          <TaskJobMarkers task={task} />
        </div>
      </Paper>
      {isSingleTaskDialogOpen && (
        <SingleTaskDialog
          taskId={task.id}
          isOpen={isSingleTaskDialogOpen}
          closePopup={() => {
            setIsSingleTaskDialogOpen(false)
          }}
        />
      )}
    </>
  )
}

export default TaskCard
