import { formatMaybeApolloError } from '@src/utils/errors'
import { EntityState } from '@reduxjs/toolkit'
import { ApolloQueryResult } from '@apollo/client'
import { DocumentEditorPage } from '@src/redux-features/document_editor/document_editor_state'
import { isPresent } from 'ts-is-present'
import {
  CompanyNode,
  FieldNode,
  FieldType,
  Maybe,
  Query,
  QuerySearchableRecordResultsArgs,
} from '@src/graphql/types'
import { JobTableLineItem, LineItem } from '@src/utils/line_items'
import { buildSearchableRecordFilters, displaySearchableRecord } from '@src/utils/searchable_record'
import {
  getCustomTableTypesFromRecordType,
  getCustomColumnValuesFromLineItems,
} from '@src/utils/custom_table_types'
import {
  SpreadsheetColumnHeader,
  SpreadsheetDataColumn,
  SpreadsheetDataRow,
  SpreadsheetSingleColValues,
} from '@src/utils/data-grid'
import { isFallback } from '@src/utils/enum'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import KeyValueAutocompleteCell from '@src/components/data-grid/KeyValueAutocompleteCell'
import { LineItemsTableMap } from '@src/redux-features/document_editor/line_items_table'
import { JobDocumentTable } from '@src/utils/shipment_form'

const orderRowsByCols = (
  rowsByCol: Record<string, SpreadsheetSingleColValues>,
  colKeys: string[],
): SpreadsheetDataRow[] => {
  const orderedRows: SpreadsheetDataRow[] = []
  const totalNumRows = Math.max(...Object.values(rowsByCol).map((vals) => vals.length))
  for (let rowIdx = 0; rowIdx < totalNumRows; rowIdx += 1) {
    const orderedRow = []
    for (const colKey of colKeys) {
      if (Object.keys(rowsByCol).includes(colKey)) {
        orderedRow.push(rowsByCol[colKey][rowIdx])
      } else {
        orderedRow.push(null)
      }
    }
    orderedRows.push(orderedRow)
  }
  return orderedRows
}

export const getRowsFromRepeatableFields = (
  lineItems: (LineItem | JobTableLineItem)[],
  repeatableFields: FieldNode[],
  colkeys: string[],
): SpreadsheetDataRow[] => {
  const rowsByCol = Object.fromEntries(
    Object.values(colkeys).map((colKey) => [colKey, new Array(lineItems.length).fill(null)]),
  ) as Record<string, SpreadsheetSingleColValues>
  for (const [index, lineItem] of lineItems.entries()) {
    for (const key of Object.keys(lineItem.fieldMapping)) {
      if (typeof rowsByCol[key] === 'undefined') {
        continue
      }
      const rowField = repeatableFields.find((field) => field.key === key)
      rowsByCol[key][index] = lineItem.fieldMapping[key].value || rowField?.defaultValue || null
    }
    for (const repeatableField of repeatableFields) {
      if (repeatableField.defaultValue && !rowsByCol[repeatableField.key][index]) {
        rowsByCol[repeatableField.key][index] = repeatableField.defaultValue
      }
    }
  }
  const rows: SpreadsheetDataRow[] = orderRowsByCols(rowsByCol, colkeys)
  return rows
}

const getFieldsSortedByDefaultOrder = (repeatableFields: FieldNode[]): FieldNode[] => {
  return [...repeatableFields].sort((fieldA, fieldB) => fieldA.columnOrder - fieldB.columnOrder)
}

export const reorderRepeatableFields = (
  repeatableFields: FieldNode[],
  lineItemsTableColumns: string[],
): FieldNode[] => {
  /**
   * Reorders the repeatable fields according to field column order (the default order)
   * and also according to the table columns in the state
   * which is what gets modified after column move in the handsontable
   */
  const sortedRepeatableFields = getFieldsSortedByDefaultOrder(repeatableFields)
  const visibleFields = sortedRepeatableFields.filter((field) =>
    lineItemsTableColumns.includes(field.key),
  )
  const hiddenFields = sortedRepeatableFields.filter(
    (field) => !lineItemsTableColumns.includes(field.key),
  )
  return [...visibleFields]
    .sort(
      (fieldA, fieldB) =>
        lineItemsTableColumns.indexOf(fieldA.key) - lineItemsTableColumns.indexOf(fieldB.key),
    )
    .concat(hiddenFields)
}

export const getColHeadersFromRepeatableFields = (
  repeatableFields: FieldNode[],
  lineItemsTableColumns: string[],
): SpreadsheetColumnHeader[] => {
  const reorderedFields = reorderRepeatableFields(repeatableFields, lineItemsTableColumns)
  return (
    reorderedFields
      .filter((field) => lineItemsTableColumns.includes(field.key) || field.required)
      .map((field) => field.name) ?? []
  )
}

export const getColumnsFromRepeatableFields = (
  repeatableFields: FieldNode[],
  documentTables: EntityState<JobDocumentTable> | null,
  lineItemsTableMap: Maybe<LineItemsTableMap>,
  searchableRecordsFunction:
    | ((
        variables?: Partial<QuerySearchableRecordResultsArgs> | undefined,
      ) => Promise<ApolloQueryResult<Pick<Query, 'searchableRecordResults'>>>)
    | null,
  enqueueSnackbar:
    | ((message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey)
    | null,
  lineItemsTableColumns: string[],
  pages?: Maybe<Record<string, DocumentEditorPage>>,
  company?: Maybe<CompanyNode>,
  apiPartnerId?: Maybe<string>,
): SpreadsheetDataColumn[] => {
  const cols: SpreadsheetDataColumn[] = []
  const reorderedFields = reorderRepeatableFields(repeatableFields, lineItemsTableColumns)
  for (const field of reorderedFields) {
    const {
      validatorRegex,
      allowFreeText,
      validatorDescription,
      values,
      dateFormatString,
      fieldType,
    } = field
    const name = field.name
    const key = field.key
    let col = {} as SpreadsheetDataColumn
    col.id = field.id
    col.name = name
    col.key = key
    col.autofillKey = field.autofillKey
    col.defaultValue = field.defaultValue
    col._strict = !allowFreeText
    col.fieldType = field.fieldType
    col._required = field.required
    col._validator = undefined
    col.validatorDescription = ''
    if (validatorRegex) {
      const closedPattern = ['^(', validatorRegex, ')$'].join('')
      col._validator = new RegExp(closedPattern)
      col.validatorDescription = validatorDescription ?? ''
    }
    const type = fieldType
      ? isFallback(fieldType)
        ? fieldType.fallbackValue
        : fieldType.value
      : null
    if ((type === FieldType.Date || type === FieldType.DateTime) && dateFormatString) {
      col.type = 'date'
      col.dateFormat = dateFormatString
      col.correctFormat = true
    }
    if (values) {
      col.type = 'autocomplete'
      col.source = values as string[]
    }
    const customValues = getCustomColumnValuesFromLineItems(
      field,
      documentTables,
      lineItemsTableMap,
    )
    if (documentTables && customValues) {
      col.type = 'autocomplete'
      col.source = customValues as string[]
    }
    if (field.searchableRecord) {
      col.type = 'autocomplete'
      col.searchableRecord = field.searchableRecord
      col.handsontable = {
        dataSchema: {
          value: null,
        },
        columns: [
          {
            title: field.searchableRecord.displayKey,
            data: 'value',
          },
        ],
        getValue() {
          const selection = this.getSelected()
          return (this.getSourceDataAtRow(selection[0][0])?.value as string) || ''
        },
      }
      col.source = async (query, process) => {
        const filters = buildSearchableRecordFilters(
          field.searchableRecord,
          pages,
          company,
          apiPartnerId,
        )
        // await data from mutation
        // process data?
        const recordsResultData =
          searchableRecordsFunction &&
          (await searchableRecordsFunction({
            searchableRecordId: field.searchableRecord!.id,
            queryString: query,
            filters,
          }).catch((err) => {
            enqueueSnackbar &&
              enqueueSnackbar(
                `Failed to fetch searchable record results: ${formatMaybeApolloError(err)}`,
                {
                  variant: 'error',
                },
              )
          }))
        const results = recordsResultData?.data?.searchableRecordResults?.filter(isPresent) ?? []
        const resultKeys = results.map((result) =>
          JSON.stringify({ value: displaySearchableRecord(field.searchableRecord!, result) }),
        )
        process(resultKeys)
      }
      col = getCustomTableTypesFromRecordType(
        field,
        col,
        searchableRecordsFunction,
        enqueueSnackbar,
        pages,
        company,
        apiPartnerId,
      )
      col = {
        ...col,
        ...KeyValueAutocompleteCell,
      }
    }
    cols.push(col)
  }
  return cols.filter((col) => lineItemsTableColumns.includes(col.key))
}
