import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useRef, useState } from 'react'
import { BULK_UPDATE_JOB_TASK_ID } from '@src/graphql/mutations/job'
import { useSnackbar } from 'notistack'
import { Mutation, MutationBulkUpdateJobTaskIdArgs, TaskNode } from '@src/graphql/types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import ForwardIcon from '@material-ui/icons/Forward'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useMutation } from '@apollo/client'
import CreateTask from './tasks/CreateTask'
import TaskTitleReferenceIdAutocomplete from './tasks/TaskTitleReferenceIdAutocomplete'

type Props = {
  jobIds: string[]
  task: TaskNode
  refetchJobs: () => unknown
}

const MoveToTaskButtonGroup: FunctionComponent<Props> = ({ jobIds, task, refetchJobs }) => {
  const { enqueueSnackbar } = useSnackbar()
  const anchorRef = useRef(null)
  const [openButton, setOpenButton] = useState(false)
  const [searchedTask, setSearchedTask] = useState(null as null | Pick<TaskNode, 'id' | 'title'>)
  const [createTaskDialogOpen, setCreateTaskDialogOpen] = useState(false)
  const [bulkUpdateJobTaskId] = useMutation<
    Pick<Mutation, 'bulkUpdateJobTaskId'>,
    MutationBulkUpdateJobTaskIdArgs
  >(BULK_UPDATE_JOB_TASK_ID)

  const handleBulkUpdate = async (newTask?: TaskNode): Promise<void> => {
    try {
      await bulkUpdateJobTaskId({
        variables: { jobIds, taskId: newTask ? newTask.id : searchedTask!.id },
      })
      refetchJobs()
    } catch (error) {
      enqueueSnackbar(
        `Failed to move jobs to task ${searchedTask!.title}: ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    }
  }

  const handleToggleMoveToButton = (): void => {
    setOpenButton((prevOpen) => !prevOpen)
  }

  return (
    <div>
      <Button
        variant='contained'
        color='primary'
        ref={anchorRef}
        disabled={!jobIds.length}
        onClick={handleToggleMoveToButton}
        startIcon={<ForwardIcon />}
        endIcon={<ArrowDropDownIcon />}
      >
        {!task ? 'Loading...' : 'Move To'}
      </Button>
      <Popper
        open={openButton}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpenButton(false)}>
                <Box p={2} width={300}>
                  <TaskTitleReferenceIdAutocomplete
                    companyId={task!.company!.id}
                    onChange={(task) => setSearchedTask(task)}
                  />
                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Link variant='button' onClick={() => setCreateTaskDialogOpen(true)}>
                      + Create Task
                    </Link>
                    <Button
                      variant='contained'
                      disabled={!searchedTask}
                      onClick={() => handleBulkUpdate()}
                    >
                      Move
                    </Button>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <CreateTask
        open={createTaskDialogOpen}
        setOpen={setCreateTaskDialogOpen}
        callback={handleBulkUpdate}
        defaultTask={task}
      />
    </div>
  )
}

export default MoveToTaskButtonGroup
