import { formatMaybeApolloError } from '@src/utils/errors'
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  Box,
} from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { InputFieldGroup, InputFieldGroupType, JobTemplateNode, Query } from '@src/graphql/types'
import { GET_JOB_TEMPLATE, GET_JOB_TEMPLATES } from '@src/graphql/queries/jobTemplate'
import { Autocomplete } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import { useSnackbar } from 'notistack'
import { getInputFieldGroups } from '@src/utils/admin/input_field_group'
import { useFormContext, useWatch } from 'react-hook-form'
import { sortBy } from 'lodash'
import { DocumentTypeOption, toDocumentTypeOption } from '@src/utils/admin/document_type_option'
import { v4 as uuid4 } from 'uuid'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { makeStyles } from '@material-ui/styles'

type Props = {
  companyId: string
  removeMetadataFieldGroups: () => void
  addMetadataFieldGroups: (inputFieldGroups: InputFieldGroup[]) => void
  removeLineItemTypes: () => void
  addLineItemTypes: (inputFieldGroups: InputFieldGroup[]) => void
}

const useStyles = makeStyles({
  dialog: {
    width: '50vw',
  },
})

const CopyJobFieldsButton: FunctionComponent<Props> = ({
  companyId,
  removeMetadataFieldGroups,
  addMetadataFieldGroups,
  removeLineItemTypes,
  addLineItemTypes,
}) => {
  const classes = useStyles()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { setValue } = useFormContext()
  const metadataFieldGroups = useWatch({ name: 'metadataFieldGroups' }) as InputFieldGroup[]
  const lineItemTypes = useWatch({ name: 'lineItemTypes' }) as InputFieldGroup[]
  const documentTypeOptions = useWatch({ name: 'documentTypes' }) as DocumentTypeOption[]
  const [isProcessing, setIsProcessing] = useState(false)
  const [selectedJobTemplate, setSelectedJobTemplate] = useState(null as null | JobTemplateNode)
  const [newDocumentTypeOptions, setNewDocumentTypeOptions] = useState(
    undefined as undefined | DocumentTypeOption[],
  )
  const [inputFieldGroupsToAdd, setInputFieldGroupsToAdd] = useState(
    undefined as undefined | InputFieldGroup[],
  )
  const { refetch: refetchJobTemplate } = useQuery<Pick<Query, 'jobTemplate'>>(GET_JOB_TEMPLATE, {
    fetchPolicy: 'network-only',
    skip: true,
  })
  const { enqueueSnackbar } = useSnackbar()
  const { data: jobTemplatesData } = useQuery<Pick<Query, 'jobTemplates'>>(GET_JOB_TEMPLATES)
  const doCopy = useCallback(async () => {
    if (!selectedJobTemplate) {
      enqueueSnackbar('Please select a job type to copy from first.', { variant: 'error' })
      return
    }
    setIsProcessing(true)
    try {
      const resp = await refetchJobTemplate({ id: selectedJobTemplate.id, companyId })
      const docTypeIdMap: Record<string, string> = {}
      const docTypes = sortBy(
        resp.data.jobTemplate
          .documentTypes!.edges.map((edge) => toDocumentTypeOption(edge!.node!))
          .map((option) => {
            const newId = uuid4()
            docTypeIdMap[option.id] = newId
            return {
              ...option,
              id: newId,
            }
          }),
        'name',
      )
      // we never want to make new standard doc type even from standard copies
      docTypes.forEach((dt) => (dt.isStandard = false))
      const inputFieldGroups = getInputFieldGroups(
        resp.data.jobTemplate.documentTypes!.edges.map((edge) => edge!.node!),
      ).map((inputFieldGroup) => ({
        ...inputFieldGroup,
        id: uuid4(),
        documentTypeId: docTypeIdMap[inputFieldGroup.documentTypeId],
        fields: inputFieldGroup.fields.map((field) => ({
          ...field,
          id: uuid4(),
        })),
      }))
      setInputFieldGroupsToAdd(inputFieldGroups)
      removeMetadataFieldGroups()
      removeLineItemTypes()
      setValue('documentTypes', docTypes)
      setNewDocumentTypeOptions(docTypes)
    } catch (error) {
      enqueueSnackbar(`Error while fetching fields: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
      setIsProcessing(false)
    }
  }, [
    enqueueSnackbar,
    refetchJobTemplate,
    removeLineItemTypes,
    removeMetadataFieldGroups,
    selectedJobTemplate,
    setValue,
  ])
  useEffect(() => {
    const currentDocumentTypeIds = new Set(documentTypeOptions.map(({ id }) => id))
    // need to wait for the form value update fo field groups & doc types to propagate before
    // we add new field groups
    if (
      inputFieldGroupsToAdd &&
      metadataFieldGroups.length === 0 &&
      lineItemTypes.length === 0 &&
      newDocumentTypeOptions?.every(({ id }) => currentDocumentTypeIds.has(id))
    ) {
      addMetadataFieldGroups(
        inputFieldGroupsToAdd.filter(({ type }) => type !== InputFieldGroupType.LineItem),
      )
      addLineItemTypes(
        inputFieldGroupsToAdd.filter(({ type }) => type === InputFieldGroupType.LineItem),
      )
      setIsDialogOpen(false)
      setInputFieldGroupsToAdd(undefined)
      setNewDocumentTypeOptions(undefined)
      setIsProcessing(false)
    }
  }, [
    addMetadataFieldGroups,
    documentTypeOptions,
    newDocumentTypeOptions,
    metadataFieldGroups.length,
    inputFieldGroupsToAdd,
    addLineItemTypes,
    lineItemTypes.length,
  ])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onAutocompleteChange = useCallback((_: ChangeEvent<any>, val: JobTemplateNode | null) => {
    setSelectedJobTemplate(val)
  }, [])
  return (
    <>
      <Button variant='outlined' onClick={() => setIsDialogOpen(true)}>
        Copy job fields
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>Copy fields from a job type</DialogTitle>
        <DialogContent>
          {isProcessing ? (
            <CenteredCircularProgress />
          ) : (
            <Autocomplete
              value={selectedJobTemplate}
              options={jobTemplatesData?.jobTemplates || []}
              loading={!jobTemplatesData}
              onChange={onAutocompleteChange}
              getOptionLabel={(option: JobTemplateNode) => option.name}
              renderOption={(option: JobTemplateNode) => (
                <Box display='flex' width='100%'>
                  <Box flex='1'>{option.name}</Box>
                  <Chip label={option.company!.name} />
                </Box>
              )}
              renderInput={(params) => <TextField {...params} variant='outlined' />}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button color='primary' onClick={doCopy}>
            {isProcessing ? 'Processing...' : 'Overwrite'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CopyJobFieldsButton
