import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, RefObject, useState, useCallback } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core'
import { HotTable } from '@handsontable/react'
import { useRef } from 'react'
import Handsontable from 'handsontable'

import theme from '@src/utils/theme'
import { useLazyQuery, useQuery } from '@apollo/client'
import { SEARCH_COMPANIES } from '@src/graphql/queries/admin'
import {
  ChargeCodeNodeEdge,
  ChargeVendorNode,
  CompanyNode,
  Maybe,
  Query,
  QueryChargeVendorsArgs,
  QuerySearchedForCompaniesArgs,
} from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { CHARGE_VENDORS_WITH_CODES } from '@src/graphql/queries/recon'
import { Autocomplete } from '@material-ui/lab'
import { HANDSONTABLE_IN_MODAL_HEIGHT } from '@src/utils/app_constants'
import { SpreadsheetDataColumn } from '@src/utils/data-grid'
import { cleanNonAlphaNumeric } from '@src/utils/string'
import { ChargeCodesArray, SelectedChargeVendor } from './types'

type Props = {
  initCompany: Maybe<CompanyNode>
  vendor: SelectedChargeVendor | undefined
  chargeCodes: ChargeCodesArray
  setOpen: (open: boolean) => void
  addNewVendorChargeCodes: (chargeCodeRows: string[][]) => void
  currApiPartnerId: string | null
}

const useStyles = makeStyles<Theme>({
  dialog: {
    // don't block handsontable copy menu
    // important because zindex 1300 is inline
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zIndex: '1000 !important' as any,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  option: {
    fontSize: 14,
  },
})

const columns = [
  {
    key: 'description',
    name: 'Description',
  },
  {
    key: 'new_charge_code',
    name: 'New Charge Code',
  },
  {
    key: 'current_charge_code',
    name: 'Current Charge Code',
  },
]

const CopyVendorModal: FunctionComponent<Props> = ({
  initCompany,
  chargeCodes,
  vendor,
  setOpen,
  addNewVendorChargeCodes,
  currApiPartnerId,
}: Props) => {
  const classes = useStyles()
  const [rows, setRows] = useState([] as string[][])
  const { enqueueSnackbar } = useSnackbar()
  const [companySearchResults, setCompanySearchResults] = useState([initCompany] as CompanyNode[])
  const [companySearchValue, setCompanySearchValue] = useState('')
  const [selectedCompany, setSelectedCompany] = useState(initCompany as Maybe<CompanyNode>)
  const [chargeVendorSearchResults, setChargeVendorSearchResults] = useState(
    [] as ChargeVendorNode[],
  )
  const [searchValue, setSearchValue] = useState('')
  const [selectedVendor, setSelectedVendor] = useState(null as Maybe<ChargeVendorNode>)
  const hotTableRef = useRef<HotTable>()
  // because we are using a modal, limit amount to be small
  const vendorDisplayModalLimit = 5

  const { refetch: searchVendors } = useQuery<Pick<Query, 'chargeVendors'>, QueryChargeVendorsArgs>(
    CHARGE_VENDORS_WITH_CODES,
    {
      variables: {
        query: '',
        companyId: selectedCompany?.id || '',
        limit: vendorDisplayModalLimit,
        withChargeCodes: true,
      },
      onCompleted: (data) => {
        if (data.chargeVendors) {
          setChargeVendorSearchResults(data.chargeVendors as ChargeVendorNode[])
        }
      },
      onError: (error) => {
        enqueueSnackbar(`Error searching charge codes: ${formatMaybeApolloError(error)}`)
      },
    },
  )
  const [searchCompanies] = useLazyQuery<
    Pick<Query, 'searchedForCompanies'>,
    QuerySearchedForCompaniesArgs
  >(SEARCH_COMPANIES, {
    onCompleted: (data) => {
      if (data.searchedForCompanies) {
        setCompanySearchResults(data.searchedForCompanies as CompanyNode[])
      }
    },
    onError: (error) => {
      enqueueSnackbar(`Error searching companies: ${formatMaybeApolloError(error)}`)
    },
  })

  const closeDialog = useCallback((): void => {
    setOpen(false)
  }, [setOpen])

  // derived from spec, no inherent values
  const companyDetailsWidth = 150
  const vendorDetailsWidth = 300

  useEffect(() => {
    if (chargeCodes) {
      const newRows = chargeCodes.map((chargeCode) => [chargeCode.description, chargeCode.code])
      setRows(newRows)
    }
  }, [chargeCodes])

  useEffect(() => {
    if (selectedVendor != null) {
      const newRows: string[][] = []
      selectedVendor!.chargeCodes!.edges!.forEach((chargeCodeEdge: Maybe<ChargeCodeNodeEdge>) => {
        if (
          chargeCodeEdge!.node!.dateDeleted === null &&
          chargeCodeEdge!.node!.apiPartnerId === currApiPartnerId
        ) {
          const existingCodeForDescription = chargeCodes?.find(
            (chargeCode) =>
              chargeCode!.description === chargeCodeEdge!.node!.description &&
              chargeCode!.apiPartnerId === currApiPartnerId,
          )
          newRows.push([
            chargeCodeEdge!.node!.description,
            chargeCodeEdge!.node!.code,
            existingCodeForDescription?.code || '',
          ])
        }
      })
      setRows(newRows)
    }
  }, [selectedVendor, chargeCodes, currApiPartnerId])

  const markDuplicateDescriptions = (): string[][] => {
    const newRows = [...(hotTableRef!.current!.hotInstance!.getData() as string[][])]
    newRows.forEach((row) => {
      const [chargeDescription] = row
      if (selectedVendor) {
        const foundChargeDesc = selectedVendor!.chargeCodes!.edges!.find(
          (chargeCodeEdge) =>
            cleanNonAlphaNumeric(chargeCodeEdge!.node!.description).toLowerCase() ===
            cleanNonAlphaNumeric(chargeDescription).toLowerCase(),
        )
        if (foundChargeDesc) {
          row[2] = foundChargeDesc!.node!.code
        } else {
          row[2] = ''
        }
      }
    })
    return newRows
  }

  const afterTableChange = (changes: Array<Array<string | number>>): void => {
    if (changes) {
      changes.some((change: Array<string | number>) => {
        const [_row, col, oldValue, newValue] = change
        const chargeDescColumn = 0
        if (col === chargeDescColumn) {
          if (oldValue !== newValue) {
            // if any description changed, dynamically update the charge code
            const newRows = markDuplicateDescriptions()
            setRows(newRows)
            return true
          }
        }
        return false
      })
    }
  }

  function duplicateDescRenderer(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _instance: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    td: any,
    row: number,
    _col: number,
    _prop: number,
    _value: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _cellProperties: any,
  ): void {
    /* eslint-disable prefer-rest-params */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Handsontable.renderers.TextRenderer.apply(this, arguments as any)
    const currRow = rows[row]
    const newChargeCode = currRow[1]
    const currChargeCode = currRow[2]
    if (currChargeCode && newChargeCode !== currChargeCode) {
      td.style.background = theme.palette.error.light
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(Handsontable.renderers as any).registerRenderer('duplicateDescRenderer', duplicateDescRenderer)

  const copyChargeCodesAndClose = useCallback((): void => {
    addNewVendorChargeCodes(hotTableRef?.current?.hotInstance.getData() || ([] as string[][]))
    closeDialog()
  }, [addNewVendorChargeCodes, closeDialog])

  return (
    <Dialog maxWidth='xl' className={classes.dialog} open={true}>
      <DialogTitle>Copy charge codes to {vendor?.name}</DialogTitle>
      <DialogContent>
        <Typography>Select a vendor to copy from: </Typography>
        <Box display='flex' justifyContent='space-between'>
          <Autocomplete
            classes={{ option: classes.option, input: classes.option }}
            options={companySearchResults}
            getOptionLabel={(option) =>
              typeof option !== 'string' ? (option as CompanyNode)?.name || '' : ''
            }
            value={selectedCompany || undefined}
            inputValue={companySearchValue}
            style={{ width: companyDetailsWidth }}
            disableClearable
            onChange={(_, copyFromCompany) => {
              if (typeof copyFromCompany !== 'string') {
                setSelectedCompany(copyFromCompany)
              }
            }}
            onInputChange={(_, newInput) => {
              setCompanySearchValue(newInput)
              void searchCompanies({ variables: { query: newInput } })
            }}
            renderInput={(params) => <TextField label='Company' variant='outlined' {...params} />}
          />
          <Autocomplete
            classes={{ option: classes.option, input: classes.option }}
            options={chargeVendorSearchResults}
            getOptionLabel={(option) =>
              typeof option !== 'string' ? (option as ChargeVendorNode)?.name : ''
            }
            value={selectedVendor || undefined}
            inputValue={searchValue}
            style={{ width: vendorDetailsWidth }}
            disableClearable
            onChange={(_, copyFromVendor) => {
              if (typeof copyFromVendor !== 'string') {
                setSelectedVendor(copyFromVendor)
              }
            }}
            onInputChange={(_, newInput) => {
              setSearchValue(newInput)
              void searchVendors({
                query: newInput,
                companyId: selectedCompany?.id || '',
                limit: vendorDisplayModalLimit,
                withChargeCodes: true,
              })
            }}
            renderInput={(params) => <TextField label='Vendor' variant='outlined' {...params} />}
          />
        </Box>
        <Box p={1}>
          {selectedVendor && (
            <HotTable
              id='vendor-code-table'
              ref={hotTableRef as RefObject<HotTable>}
              style={{ width: '100%', overflow: 'scroll', height: HANDSONTABLE_IN_MODAL_HEIGHT }}
              data={rows}
              rowHeaders
              afterChange={afterTableChange}
              contextMenu={['row_above', 'row_below', 'remove_row']}
              colHeaders={columns.map((column: SpreadsheetDataColumn) => column.name)}
              columnSorting={true}
              columns={columns}
              stretchH='all'
              cells={() => {
                const cellProperties: Record<string, string> = {}
                cellProperties['renderer'] = 'duplicateDescRenderer'
                return cellProperties
              }}
              autoRowSize={true}
            />
          )}
        </Box>
        <Box display='flex' justifyContent='center' p={3}>
          <Box m={3}>
            <Button onClick={closeDialog} variant='contained'>
              Cancel
            </Button>
          </Box>
          <Box m={3}>
            <Button
              onClick={copyChargeCodesAndClose}
              disabled={!selectedVendor}
              variant='contained'
              color='primary'
            >
              Copy Charge Codes
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CopyVendorModal
