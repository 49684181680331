// TODO: This & RowControls are really similar, and should prolly be unified somehow
import { FunctionComponent, MouseEvent, MutableRefObject, useState } from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { Theme } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/styles'
import { clsx } from 'clsx'

import { MAGIC_GRID_DIMENSIONS } from '@src/utils//app_constants'
import theme from '@src/utils/theme'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@src/utils/store'
import { addGridColumn, deleteGridColumn } from '@src/redux-features/document_editor'
import {
  columnSelectors,
  selectActiveMagicGrid,
} from '@src/redux-features/document_editor/magic_grid'

const useStyles = makeStyles<Theme>({
  horizontalBar: {
    position: 'absolute',
    top: -theme.spacing(2),
    width: '100%',
    height: theme.spacing(1),
    backgroundColor: theme.palette.info.dark,
    borderRadius: theme.shape.borderRadius,
    zIndex: MAGIC_GRID_DIMENSIONS.CONTROLS_Z_INDEX,
  },
  columnControl: {
    transform: 'translate(-50%, -30%)',
    padding: 0,
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  addColumnButton: {
    color: theme.palette.info.light,
  },
  rulerGuide: {
    position: 'absolute',
    width: `${MAGIC_GRID_DIMENSIONS.RULER_THICKNESS}px`,
    height: '100%',
    borderLeftWidth: 'thin',
    border: `${MAGIC_GRID_DIMENSIONS.RULER_THICKNESS}px solid ${theme.palette.info.light}`,
  },
  activeRuler: {
    border: `${MAGIC_GRID_DIMENSIONS.RULER_THICKNESS}px solid ${theme.palette.error.light}`,
  },
})

type Props = {
  imageBoxRef: MutableRefObject<HTMLImageElement | null>
  imageScale: number
}

const ColumnControls: FunctionComponent<Props> = ({ imageBoxRef, imageScale }) => {
  const dispatch = useDispatch()
  const gridDimension = useSelector(
    (state: RootState) => selectActiveMagicGrid(state.documentEditor)!.dimension!,
  )
  const columns = useSelector(
    (state: RootState) => columnSelectors.selectAll(state.documentEditor)!,
  )
  const [activeColumnId, setActiveColumnId] = useState(null as string | null)
  const [isRulerVisible, setIsRulerVisible] = useState(false)
  const [xCoord, setXCoord] = useState(0)
  const imageWidth = imageBoxRef.current?.width || 0
  const classes = useStyles()
  const relativeRulerThickness = MAGIC_GRID_DIMENSIONS.RULER_THICKNESS / imageWidth
  const relativeMarkerThreshold = MAGIC_GRID_DIMENSIONS.MARKER_THRESHOLD / imageWidth

  const handleAddColumn = (): void => {
    const adjustedXCoord = xCoord + gridDimension.left + relativeRulerThickness
    dispatch(addGridColumn(adjustedXCoord))
    setIsRulerVisible(false)
  }

  const handleRemoveColumn = (): void => {
    if (activeColumnId != null) {
      dispatch(deleteGridColumn(activeColumnId))
      setActiveColumnId(null)
    }
  }

  const getCursorPosition = (evt: MouseEvent): void => {
    const horizontalBarBox = evt.currentTarget.getBoundingClientRect() as ClientRect
    const horizontalBarBoxEndX = horizontalBarBox.left + horizontalBarBox.width
    const newXCoord = evt.clientX - horizontalBarBox.left
    const relativeXCoord = newXCoord / (imageWidth * imageScale)
    const isCursorInsideHorizontalBar =
      evt.clientX <= horizontalBarBox.left || evt.clientX >= horizontalBarBoxEndX

    // restrict the ruler inside the bounds of the horizontal bar
    if (isCursorInsideHorizontalBar) {
      setIsRulerVisible(false)
      return
    }

    // if the cursor overlaps an existing column left coordinate, set it as the active column
    const currentActiveCol = columns.find(({ dimension: { left } }) => {
      const adjustedStartXCoord = left - gridDimension.left - relativeRulerThickness
      const xmin = adjustedStartXCoord - relativeMarkerThreshold
      const xmax = adjustedStartXCoord + relativeMarkerThreshold
      return relativeXCoord >= xmin && relativeXCoord <= xmax
    })
    setActiveColumnId(currentActiveCol?.id || null)
    setXCoord(relativeXCoord)
  }

  const imageScaledXCoord = xCoord * imageWidth

  return (
    <>
      <Box
        className={classes.horizontalBar}
        onFocus={() => setIsRulerVisible(true)}
        onBlur={() => {
          setIsRulerVisible(false)
          setActiveColumnId(null)
        }}
        onMouseOver={() => setIsRulerVisible(true)}
        onMouseLeave={() => {
          setIsRulerVisible(false)
          setActiveColumnId(null)
        }}
        onMouseMove={getCursorPosition}
      >
        {activeColumnId == null && isRulerVisible && (
          <Box position='absolute' left={imageScaledXCoord}>
            <IconButton className={classes.columnControl} onClick={handleAddColumn} size='small'>
              <AddCircleIcon className={classes.addColumnButton} />
            </IconButton>
          </Box>
        )}

        {activeColumnId != null && (
          <Box position='absolute' left={imageScaledXCoord}>
            <IconButton className={classes.columnControl} onClick={handleRemoveColumn} size='small'>
              <CancelIcon color='error' />
            </IconButton>
          </Box>
        )}
      </Box>

      {isRulerVisible && (
        <Box
          className={clsx(classes.rulerGuide, {
            [classes.activeRuler]: activeColumnId != null,
          })}
          left={imageScaledXCoord}
        />
      )}
    </>
  )
}

export default ColumnControls
