import {
  ChainIoAdditionalReferenceNodeEdge,
  ChainIoAddressNode,
  ChainIoConsolidationNode,
  ChainIoContainerNodeEdge,
  ChainIoCustomFieldNodeEdge,
  ChainIoOrderNodeEdge,
  ChainIoPackingLineNodeEdge,
  ChainIoShipmentLegNodeEdge,
  ChainIoShipmentNode,
} from '@src/graphql/types'

export const MINIMUM_LOADING_TIME = 500

export type TableDisplayData = string[][]

export enum AddressType {
  LocalClient = 'Local Client',
  Shipper = 'Shipper',
  Consignee = 'Consignee',
  Supplier = 'Supplier',
  Importer = 'Importer',
  PickupTransportCompany = 'Pickup Transport Company',
  DeliveryTransportCompany = 'Delivery Transport Company',
  ImporterOfRecord = 'Importer of Record',
  ImportBroker = 'Import Broker',
  PickupFrom = 'Pickup Location',
  DeliveryTo = 'Delivery Location',
  Carrier = 'Carrier',
  Creditor = 'Creditor',
  ColoadWith = 'Coload With',
  ReceivingAgent = 'Receiving Agent',
  SendingAgent = 'Sending Agent',
  ControllingAgent = 'Controlling Agent',
  ControllingCustomer = 'Controlling Customer',
}

export const getAddressDetails = (
  addressType: AddressType,
  address: ChainIoAddressNode,
): string[] => {
  const address1 = address.address1 ? `${address.address1}, ` : ''
  const address2 = address.address2 ? `${address.address2}, ` : ''
  const city = address.city ? `${address.city}, ` : ''
  const country = address.country ? `${address.country}, ` : ''
  const state = address.state ? `${address.state}, ` : ''

  return [
    addressType.valueOf(),
    address.name || '',
    address.sourcePartyId || '',
    address.unlocode || '',
    address1 + address2 + city + country + state,
  ]
}

// TODO: Make this more dynamic so we don't have to add new fields in manually every time we add
// new things to ChainIo model (see Additional Note of https://expedock.atlassian.net/browse/PD-2558)
export const getConsolAddressesData = (consol: ChainIoConsolidationNode): TableDisplayData => {
  const addressesData: TableDisplayData = [[]]
  if (consol.carrier) {
    addressesData.push(getAddressDetails(AddressType.Carrier, consol.carrier))
  }
  if (consol.creditor) {
    addressesData.push(getAddressDetails(AddressType.Creditor, consol.creditor))
  }
  if (consol.coloadWith) {
    addressesData.push(getAddressDetails(AddressType.ColoadWith, consol.coloadWith))
  }
  if (consol.receivingAgent) {
    addressesData.push(getAddressDetails(AddressType.ReceivingAgent, consol.receivingAgent))
  }
  if (consol.sendingAgent) {
    addressesData.push(getAddressDetails(AddressType.SendingAgent, consol.sendingAgent))
  }
  return addressesData
}

// TODO: Make this more dynamic so we don't have to add new fields in manually every time we add
// new things to ChainIo model (see Additional Note of https://expedock.atlassian.net/browse/PD-2558)
export const getShipmentAddressesData = (shipment: ChainIoShipmentNode): TableDisplayData => {
  const addressesData: TableDisplayData = [[]]
  if (shipment.localClient)
    addressesData.push(getAddressDetails(AddressType.LocalClient, shipment.localClient))
  if (shipment.shipper) addressesData.push(getAddressDetails(AddressType.Shipper, shipment.shipper))
  if (shipment.consignee)
    addressesData.push(getAddressDetails(AddressType.Consignee, shipment.consignee))
  if (shipment.supplier)
    addressesData.push(getAddressDetails(AddressType.Supplier, shipment.supplier))
  if (shipment.importer)
    addressesData.push(getAddressDetails(AddressType.Importer, shipment.importer))
  if (shipment.importBroker)
    addressesData.push(getAddressDetails(AddressType.ImportBroker, shipment.importBroker))
  if (shipment.pickupTransportCompany)
    addressesData.push(
      getAddressDetails(AddressType.PickupTransportCompany, shipment.pickupTransportCompany),
    )
  if (shipment.deliveryTransportCompany)
    addressesData.push(
      getAddressDetails(AddressType.DeliveryTransportCompany, shipment.deliveryTransportCompany),
    )
  if (shipment.importerOfRecord)
    addressesData.push(getAddressDetails(AddressType.ImporterOfRecord, shipment.importerOfRecord))
  if (shipment.pickupFrom)
    addressesData.push(getAddressDetails(AddressType.PickupFrom, shipment.pickupFrom))
  if (shipment.deliveryTo)
    addressesData.push(getAddressDetails(AddressType.DeliveryTo, shipment.deliveryTo))
  if (shipment.controllingAgent)
    addressesData.push(getAddressDetails(AddressType.ControllingAgent, shipment.controllingAgent))
  if (shipment.controllingCustomer)
    addressesData.push(
      getAddressDetails(AddressType.ControllingCustomer, shipment.controllingCustomer),
    )
  return addressesData
}

export const getContainersData = (
  containerNodeEdges: ChainIoContainerNodeEdge[],
): TableDisplayData => {
  return containerNodeEdges.map((containerNodeEdge) => {
    const containerNode = containerNodeEdge.node
    return [
      containerNode.containerNumber || '',
      containerNode.containerCount?.toString() || '',
      containerNode.seal1 || '',
      containerNode.containerMode || '',
      containerNode.typeCode || '',
      containerNode.deliveryMode || '',
      containerNode.sealedBy || '',
      containerNode.wharfGateIn || '',
    ]
  })
}

export const getShipmentLegsData = (
  shipmentLegNodeEdges: ChainIoShipmentLegNodeEdge[],
): TableDisplayData => {
  return shipmentLegNodeEdges.map((shipmentLegNodeEdge) => {
    const shipmentLegNode = shipmentLegNodeEdge.node
    return [
      shipmentLegNode.legSequenceNumber.toString(),
      shipmentLegNode.transportMode || '',
      shipmentLegNode.legType || '',
      shipmentLegNode.bookingStatus || '',
      shipmentLegNode.vesselName || '',
      shipmentLegNode.voyageNumber || '',
      shipmentLegNode.ladingPort?.unlocode || '',
      shipmentLegNode.arrivalPort?.unlocode || '',
      shipmentLegNode.departureEstimated || '',
      shipmentLegNode.arrivalEstimated || '',
      shipmentLegNode.legCarrier?.name || '',
    ]
  })
}

export const getPackingLinesData = (
  packingLineNodeEdges: ChainIoPackingLineNodeEdge[],
): TableDisplayData => {
  return packingLineNodeEdges.map((packingLineNodeEdge) => {
    const packingLineNode = packingLineNodeEdge.node
    return [
      packingLineNode.packs || '',
      packingLineNode.packsUom || '',
      packingLineNode.containerNumber || '',
      packingLineNode.weight || '',
      packingLineNode.weightUom || '',
      packingLineNode.volume || '',
      packingLineNode.volumeUom || '',
      packingLineNode.referenceNumber || '',
      packingLineNode.importReferenceNumber || '',
      packingLineNode.exportReferenceNumber || '',
      packingLineNode.descriptionOfGoods || '',
      packingLineNode.marksAndNumbers || '',
      packingLineNode.commodityCode || '',
    ]
  })
}

export const getOrderNumbersData = (
  orderNumberNodeEdges: ChainIoOrderNodeEdge[],
): TableDisplayData => {
  return orderNumberNodeEdges.map((orderNumberNodeEdge) => {
    const orderNumberNode = orderNumberNodeEdge.node
    return [
      orderNumberNode.orderReference || 'N/A',
      orderNumberNode.orderNumberSplit?.toString() || 'N/A',
      orderNumberNode.orderBuyer?.sourcePartyId || 'N/A',
      orderNumberNode.orderSupplier?.sourcePartyId || 'N/A',
      orderNumberNode.orderStatus || 'N/A',
      orderNumberNode.orderDate || 'N/A',
      orderNumberNode.orderLines ? orderNumberNode.orderLines.edges.length : 'N/A',
      orderNumberNode.orderLines?.edges ?? [],
    ] as string[]
  })
}

export const getCustomFieldsData = (
  customFieldNodeEdges: ChainIoCustomFieldNodeEdge[],
): TableDisplayData => {
  return customFieldNodeEdges.map((customFieldNodeEdge) => {
    const customFieldNode = customFieldNodeEdge.node
    return [customFieldNode.key || '', customFieldNode.value || '']
  })
}

export const getAdditionalReferencesData = (
  additionalReferenceNodeEdges: ChainIoAdditionalReferenceNodeEdge[],
): TableDisplayData => {
  return additionalReferenceNodeEdges.map((additionalReferenceNodeEdge) => {
    const additionalReferenceNode = additionalReferenceNodeEdge.node
    return [
      additionalReferenceNode.referenceType || '',
      additionalReferenceNode.referenceNumber || '',
    ]
  })
}
