import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { UserRole } from '@src/graphql/types'
import { FormError } from './util'

type Props = {
  fieldName: string
  friendlyName: string
  formError?: FormError
  defaultValue?: UserRole | UserRole[]
  showAllRoles?: boolean
  multiple?: boolean
}

const DASHBOARD_ROLES = [UserRole.Client, UserRole.ClientAdmin]

const UserRoleAutocomplete: FunctionComponent<Props> = ({
  fieldName,
  friendlyName,
  formError,
  defaultValue,
  showAllRoles,
  multiple,
}) => {
  // `ClientPartner` should be removed for now as it would be reworked as per PD-4899
  const allRoles = Object.values(UserRole).filter((value) => value !== UserRole.ClientPartner)
  const preselectedRoles =
    Array.isArray(defaultValue) && !showAllRoles
      ? defaultValue.find((role) => DASHBOARD_ROLES.includes(role))
      : defaultValue
  return (
    <Controller
      render={({ field: { value, onBlur, onChange } }) => (
        <Autocomplete
          options={showAllRoles ? allRoles : DASHBOARD_ROLES}
          getOptionLabel={(option: UserRole) => option}
          onBlur={onBlur}
          multiple={multiple}
          value={value}
          onChange={(_event, newValue) => {
            if (newValue && formError) formError.roles = ''
            onChange(newValue)
          }}
          renderInput={(params) => (
            <TextField
              variant='outlined'
              {...params}
              label={friendlyName}
              error={formError ? !!formError.roles : false}
              helperText={formError ? formError.roles : ''}
            />
          )}
        />
      )}
      name={fieldName}
      defaultValue={preselectedRoles}
    />
  )
}

export default UserRoleAutocomplete
