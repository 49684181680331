import { ChangeEvent, FunctionComponent, useCallback, useState } from 'react'
import { InputField, InputFieldType, Maybe, SearchableRecordOptions } from '@src/graphql/types'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'
import { inputFieldTypeDisplayMap } from '@src/utils/admin/input_field_group'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import ControllerTextField from '@src/components/controller-text-field/ControllerTextField'
import DeleteIcon from '@material-ui/icons/Delete'
import DeleteDialog from '@src/components/admin/job-template-fields/DeleteDialog'
import FieldDetails from '@src/components/admin/job-template-fields/FieldDetails'
import ControllerErrorText from '@src/components/controller-error-text/ControllerErrorText'

type Props = {
  fieldGroupIndex: number
  fieldIndex: number
  field: InputField
  remove: (index: number) => void
  searchableRecordOptions: Maybe<SearchableRecordOptions[]> | undefined
}
const useStyles = makeStyles({
  fieldContainer: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
  labelCell: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    // empirically determined to fit the labels -- might want to use flexbox instead of tables
    // to automatically do this
    width: '150px',
  },
})
const JobTemplateLineItemColumn: FunctionComponent<Props> = ({
  fieldGroupIndex,
  fieldIndex,
  field,
  remove,
  searchableRecordOptions,
}) => {
  const classes = useStyles()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const { register } = useFormContext()
  const renderFieldTypeSelector = useCallback(
    ({ field: { value, onBlur, onChange }, fieldState: { error, invalid } }) => {
      const onAutocompleteChange = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _: ChangeEvent<any>,
        val: InputFieldType | null,
      ): void => {
        onChange(val)
      }
      return (
        <>
          <Autocomplete
            value={value}
            options={Object.values(InputFieldType)}
            onChange={onAutocompleteChange}
            onBlur={onBlur}
            size='small'
            getOptionLabel={(option: InputFieldType) => inputFieldTypeDisplayMap[option]}
            renderInput={(params) => <TextField {...params} error={invalid} variant='outlined' />}
          />
          <ControllerErrorText error={error} friendlyName='Field type' />
        </>
      )
    },
    [],
  )
  return (
    <>
      <input
        {...register(`lineItemTypes.${fieldGroupIndex}.fields.${fieldIndex}.id`)}
        defaultValue={field.id}
        type='hidden'
      />
      <Paper elevation={3} className={classes.fieldContainer}>
        <Box display='flex'>
          <Box flex='1'>
            <ControllerTextField
              name={`lineItemTypes.${fieldGroupIndex}.fields.${fieldIndex}.name`}
              friendlyName='Name'
              defaultValue={field.name}
              rules={{ required: true }}
            />
          </Box>
          <Box>
            <Controller
              render={({ field: { ref, value, ...rest } }) => (
                <>
                  <FormControlLabel
                    control={<Checkbox inputRef={ref} checked={value} {...rest} />}
                    label='Required'
                  />
                </>
              )}
              name={`lineItemTypes.${fieldGroupIndex}.fields.${fieldIndex}.required`}
              defaultValue={field.required}
            />
          </Box>
          <IconButton aria-label='delete' onClick={() => setIsDeleteDialogOpen(true)}>
            <DeleteIcon />
          </IconButton>
        </Box>
        <TableContainer component={Paper} variant='outlined' key={field.id}>
          <Table size='small'>
            <TableBody>
              <TableRow>
                <TableCell className={classes.labelCell}>Field type</TableCell>
                <TableCell>
                  <Controller
                    // we don't pass a ref to the controller since that causes react hook form to try to
                    // set the input value to the raw ID on refetch
                    render={renderFieldTypeSelector}
                    name={`lineItemTypes.${fieldGroupIndex}.fields.${fieldIndex}.type`}
                    rules={{ required: true }}
                    defaultValue={field.type}
                  />
                </TableCell>
              </TableRow>
              <FieldDetails
                field={field}
                fieldGroupIndex={fieldGroupIndex}
                fieldIndex={fieldIndex}
                isLineItem
                isLineItemField
                searchableRecordOptions={searchableRecordOptions}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {isDeleteDialogOpen && (
        <DeleteDialog
          open={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onDelete={() => remove(fieldIndex)}
          namePath={`lineItemTypes.${fieldGroupIndex}.fields.${fieldIndex}.name`}
        />
      )}
    </>
  )
}

export default JobTemplateLineItemColumn
