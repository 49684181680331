import { EntityState } from '@reduxjs/toolkit'
import { JobNode } from '@src/graphql/types'
import { JobDocumentTable } from '@src/utils/shipment_form'
import { LineItemsTableMap } from './line_items_table'
import { MagicGridMap, TableExtractionStep } from './magic_grid'
import { boxnInitialState, BoxnState } from './boxn'
import { documentTablesAdapter } from './document_table'
import { JobLineItemsTableState } from './job_line_items_table'
import { LineItemsClipboardState } from './line_items_clipboard'
import { PageFieldEditorState } from './page_field_editor'
import { UndoRedoPatches } from './undo_redo'
import { FieldCoordinates } from '@src/types/ocr'

export type DocumentEditorPage = {
  // mapping from form fields to their values. Note this includes ${key}_confirmed fields!
  // confirmed fields are fields that have been confirmed by the user after autofill
  fieldMapping: { [fieldKey: string]: string }
  // coordinates as to where the fields are on the page
  fieldCoordinates: FieldCoordinates
}

export type DocumentEditorState = {
  // Represents a job in server (we shouldn't edit this directly unless loading from server)
  job: JobNode | null
  activeFilePageId: string | null
  // copied text when you do text boxing. this has to be part of the state since
  // material-table uses controlled components
  copiedText: string | null
  activeFieldKey: string | null
  // contains page-specific state
  pageFieldEditorState: PageFieldEditorState
  // all the documentTables in a job. These are the JobDocumentTable objects on
  // each file page, while lineItemsTableMap is state about existing line items
  // in a document table.
  documentTables: EntityState<JobDocumentTable>
  lineItemsTableMap: LineItemsTableMap
  magicGridMap: MagicGridMap
  // if non-null, the document table currently being shown in line items editor/grid
  activeDocumentTableId: string | null
  tableEditModeEnabled: boolean
  docTableValidationErrors: Record<string, string[]>
  currentTableExtractionStep: TableExtractionStep
  highlightedLineItemId: string | null
  activeLineItemIds: string[]
  // TODO: seems dupe state with `lineItemsClipboard`
  lineItemIdsClipboard: string[]
  lineItemsChanges: Record<number, UndoRedoPatches>
  lineItemsCurrentVersion: number
  canUndo: boolean
  canRedo: boolean
  boxn: BoxnState
  // For job table
  jobTableState: JobLineItemsTableState | null
  jobTableActive: boolean
  lineItemsClipboard: LineItemsClipboardState
  currentUserEmail: string | null
}

export const getInitialState = (): DocumentEditorState => ({
  job: null,
  activeFilePageId: null,
  copiedText: null,
  activeFieldKey: null,
  pageFieldEditorState: null,
  documentTables: documentTablesAdapter.getInitialState(),
  lineItemsTableMap: {},
  magicGridMap: {},
  activeDocumentTableId: null,
  tableEditModeEnabled: false,
  docTableValidationErrors: {},
  currentTableExtractionStep: TableExtractionStep.Idle,
  highlightedLineItemId: null,
  activeLineItemIds: [],
  lineItemIdsClipboard: [],
  lineItemsChanges: {},
  lineItemsCurrentVersion: -1,
  canUndo: false,
  canRedo: false,
  boxn: boxnInitialState,
  jobTableState: null,
  jobTableActive: false,
  lineItemsClipboard: null,
  currentUserEmail: null,
})
