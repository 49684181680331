import { DocumentEditorPage } from '@src/redux-features/document_editor/document_editor_state'
import {
  Maybe,
  FieldNode,
  CompanyNode,
  QuerySearchableRecordResultsArgs,
  SearchableRecordModel,
  SearchableRecordNode,
  SearchableRecordResultNode,
  ShipmentOpsShipmentOrderLinesAutofillKey,
} from '@src/graphql/types'
import { endsWithAny } from '@src/utils/string'
import { camelCase } from 'lodash'

const uuid_suffix_re = /_[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/

export const buildSearchableRecordFilters = (
  searchableRecord?: Maybe<SearchableRecordNode>,
  pages?: Maybe<Record<string, DocumentEditorPage>>,
  company?: Maybe<CompanyNode>,
  apiPartnerId?: Maybe<string>,
  getFormValues?: () => Record<string, string>,
): QuerySearchableRecordResultsArgs['filters'] => {
  if (searchableRecord) {
    // TODO: remove pages and just use live form data. ie deprecate pages usage for data-grid
    // prioritize live form data over data stored in pageFieldEditor redux state
    const formValueEntries = getFormValues
      ? Object.entries(getFormValues())
      : Object.values(pages ?? {}).flatMap((pageFieldValues) =>
          Object.entries(pageFieldValues!.fieldMapping),
        )
    if (
      (searchableRecord.model === SearchableRecordModel.ChargeCode ||
        searchableRecord.model === SearchableRecordModel.ChargeCodeV2) &&
      formValueEntries.length > 0
    ) {
      // TODO: this raw string stuff is way too brittle and prone to breaking
      const suffixes = ['_creditor__code', '_creditor_code', '_vendor', '_creditor']
      const vendorField = formValueEntries.find(([fieldKey, fieldValue]) => {
        return endsWithAny(suffixes, fieldKey.replace(uuid_suffix_re, '')) && !!fieldValue
      })
      const vendor = vendorField?.[1]
      return { vendorName: vendor, companyId: company?.id, apiPartnerId }
    }
    if (
      searchableRecord.model === SearchableRecordModel.ChargeRate &&
      formValueEntries.length > 0
    ) {
      // this was previously named creditor code, so that's why the suffix is this
      const suffixes = ['_creditor__code', '_creditor_code', '_vendor', '_creditor']
      const vendorField = formValueEntries.find(([fieldKey, fieldValue]) => {
        return endsWithAny(suffixes, fieldKey.replace(uuid_suffix_re, '')) && !!fieldValue
      })
      const vendor = vendorField?.[1]
      return { vendorName: vendor, companyId: company?.id }
    }
    if (searchableRecord.model === SearchableRecordModel.ChargeVendor && company) {
      return { companyId: company.id! }
    }
    if (searchableRecord.apiUrl && company) {
      return { companyId: company.id! }
    }
  }
  return undefined
}

export const SEARCHABLE_URL_RECORD_DISPLAY = 'urlResultRecord'
export const SEARCHABLE_URL_RECORD_SEARCH = 'urlResultSearch'

export const getSearchableRecordDisplayKey = (searchableRecord: SearchableRecordNode): string => {
  return !searchableRecord.model
    ? SEARCHABLE_URL_RECORD_DISPLAY
    : camelCase(searchableRecord.displayKey)
}

export const getSearchableRecordSearchKey = (searchableRecord: SearchableRecordNode): string => {
  return !searchableRecord.model
    ? SEARCHABLE_URL_RECORD_SEARCH
    : camelCase(searchableRecord.searchKey)
}

export const displaySearchableRecord = (
  searchableRecord: SearchableRecordNode,
  result: SearchableRecordResultNode,
): string => {
  const key = getSearchableRecordDisplayKey(searchableRecord) as keyof SearchableRecordResultNode
  return result[key] || ''
}

export const getSearchableRecordSearchValue = (
  searchableRecord: SearchableRecordNode,
  result: SearchableRecordResultNode,
): string => {
  const key = getSearchableRecordSearchKey(searchableRecord) as keyof SearchableRecordResultNode
  return result[key] || ''
}

export const getSearchableRecordFromFieldKey = (
  fields: FieldNode[],
  fieldKey: string,
): Maybe<SearchableRecordNode> => {
  const field = fields.find(({ key }) => key === fieldKey) || null
  return field?.searchableRecord || null
}

export const isChargeCodeField = (field: Maybe<FieldNode>): boolean => {
  return (
    field?.searchableRecord?.model === SearchableRecordModel.ChargeCode ||
    field?.searchableRecord?.model === SearchableRecordModel.ChargeCodeV2
  )
}

export const isOrderIdentifierField = (field: Maybe<FieldNode>): boolean => {
  return (
    field?.autofillKey === ShipmentOpsShipmentOrderLinesAutofillKey.OrderIdentifier.toLowerCase()
  )
}
