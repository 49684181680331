import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import {
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import { UPDATE_TASK } from '@src/graphql/mutations/task'
import { TaskNode } from '@src/graphql/types'

type Props = {
  open: boolean
  onClose: () => unknown
  task: TaskNode
  confirm: () => unknown
}

const TaskDateConfirmedDialog: FunctionComponent<Props> = ({ open, onClose, task, confirm }) => {
  const [dateConfirmed, setDateConfirmed] = useState(undefined as string | undefined)
  const { enqueueSnackbar } = useSnackbar()
  const [updateTask] = useMutation(UPDATE_TASK)

  const handleClose = (): void => {
    enqueueSnackbar('Cannot update jobs to done without specifying date and time confirmed', {
      variant: 'error',
    })
    onClose()
  }
  const handleConfirm = async (): Promise<void> => {
    try {
      await updateTask({
        variables: {
          taskId: task.id,
          title: task.title,
          dateReceived: task.dateReceived,
          message: task.message,
          taskReferenceId: task.taskReferenceId,
          dateConfirmed: new Date(dateConfirmed!).toUTCString(),
        },
      })
      confirm()
    } catch (error) {
      enqueueSnackbar(`Error setting new date confirmed: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Task Date/Time Confirmed Missing</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please specify the task date &amp; time confirmed to update this job&rquo;s status.
        </DialogContentText>
        <TextField
          value={dateConfirmed}
          onChange={(e) => setDateConfirmed(e.target.value)}
          label='Date &amp; Time Confirmed'
          type='datetime-local'
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleConfirm} disabled={!dateConfirmed}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TaskDateConfirmedDialog
