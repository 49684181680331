import { FunctionComponent, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import theme from '@src/utils/theme'
import IconButton from '@material-ui/core/IconButton'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import SearchIcon from '@material-ui/icons/Search'
import PanToolIcon from '@material-ui/icons/PanTool'

type Props = {
  zoomIn: () => unknown
  zoomOut: () => unknown
  resetTransform: () => unknown
  scrollZoomEnabled: boolean
  setScrollZoomEnabled: (enabled: boolean) => unknown
  panningEnabled: boolean
  setPanningEnabled: (enabled: boolean) => unknown
}

const ZoomControlBar: FunctionComponent<Props> = ({
  zoomIn,
  zoomOut,
  resetTransform,
  setScrollZoomEnabled,
  scrollZoomEnabled,
  panningEnabled,
  setPanningEnabled,
}) => {
  useEffect(() => {
    const handleCtrlKeyDown = (e: KeyboardEvent): void => {
      if (e.key === 'Control' || e.key === 'Meta') {
        setScrollZoomEnabled(true)
        setPanningEnabled(true)
      }
    }
    const handleCtrlKeyUp = (e: KeyboardEvent): void => {
      if (e.key === 'Control' || e.key === 'Meta') {
        setScrollZoomEnabled(false)
        setPanningEnabled(false)
      }
    }
    const handleBlur = (): void => {
      setScrollZoomEnabled(false)
      setPanningEnabled(false)
    }

    window.addEventListener('keydown', handleCtrlKeyDown)
    window.addEventListener('keyup', handleCtrlKeyUp)
    window.addEventListener('blur', handleBlur)

    return () => {
      window.removeEventListener('keydown', handleCtrlKeyDown)
      window.removeEventListener('keyup', handleCtrlKeyUp)
      window.removeEventListener('blur', handleBlur)
    }
  }, [setScrollZoomEnabled, setPanningEnabled])

  return (
    <Box
      display='flex'
      justifyContent='flex-end'
      position='sticky'
      top={0}
      zIndex={theme.zIndex.modal - 1}
    >
      <IconButton onClick={zoomIn} title='Zoom In'>
        <ZoomInIcon />
      </IconButton>
      <IconButton onClick={zoomOut} title='Zoom Out'>
        <ZoomOutIcon />
      </IconButton>
      <IconButton onClick={resetTransform} title='Reset Zoom'>
        <ZoomOutMapIcon />
      </IconButton>
      <IconButton
        onClick={() => setScrollZoomEnabled(!scrollZoomEnabled)}
        title={scrollZoomEnabled ? 'Disable Zoom by Mouse Wheel' : 'Enable Zoom by Mouse Wheel'}
        color={scrollZoomEnabled ? 'primary' : 'default'}
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        onClick={() => setPanningEnabled(!panningEnabled)}
        title={panningEnabled ? 'Disable Panning' : 'Enable Panning'}
        color={panningEnabled ? 'primary' : 'default'}
      >
        <PanToolIcon />
      </IconButton>
    </Box>
  )
}

export default ZoomControlBar
