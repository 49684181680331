import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { debounce } from 'lodash'
import { COPY_JOB, EDIT_JOB, DELETE_JOB } from '@src/graphql/mutations/job'
import { useSnackbar } from 'notistack'
import { JobNode, Mutation, MutationEditJobArgs, TaskNode } from '@src/graphql/types'
import { useMutation } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import DescriptionIcon from '@material-ui/icons/Description'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { makeStyles } from '@material-ui/styles'
import { grey } from '@material-ui/core/colors'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import JobManualProductSelect from '@src/components/JobManualProductSelect'
import JobOwnerSelect from '@src/components/JobOwnerSelect'
import JobQASelect from '@src/components/JobQASelect'
import MoveJobStatus from '@src/components/MoveJobStatus'
import { convertJobStatusToUrlFormat } from '@src/utils/job'
import theme from '@src/utils/theme'

type Props = {
  job: JobNode
  task: TaskNode | undefined
  refetchJobs: () => unknown
  selectedJobs: JobNode[]
  handleJobSelectionChange: (newJob: JobNode, add: boolean) => void
}

const useStyles = makeStyles({
  actionIcon: {
    color: grey[900],
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
})

const JobRow: FunctionComponent<Props> = ({
  job,
  refetchJobs,
  task,
  selectedJobs,
  handleJobSelectionChange,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [deleteDialogShowing, setDeleteDialogShowing] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [deleteJob, { error: deleteJobError }] = useMutation<Pick<Mutation, 'deleteJob'>>(
    DELETE_JOB,
    {
      onError: () => {
        if (deleteJobError) {
          enqueueSnackbar(
            `Failed to delete job ${job.name}: ${formatMaybeApolloError(deleteJobError)}`,
            {
              variant: 'error',
            },
          )
        }
      },
      onCompleted: () => {
        enqueueSnackbar(`Successfully deleted job ${job.name}`, {
          variant: 'success',
        })
        refetchJobs()
      },
    },
  )

  const [copyJob, { error: copyJobError }] = useMutation<Pick<Mutation, 'copyJob'>>(COPY_JOB)
  const [editJob, { error: editJobError }] = useMutation<
    Pick<Mutation, 'editJob'>,
    MutationEditJobArgs
  >(EDIT_JOB)

  const handleViewJob = async (): Promise<void> => {
    const { id, status } = job
    const pathname = `/${convertJobStatusToUrlFormat(status)}/${id}`
    history.push({ pathname })
  }

  const handleDeleteJob = async (): Promise<void> => {
    await deleteJob({ variables: { jobId: job.id } })
    setDeleteDialogShowing(false)
  }

  const handleDuplicateJob = async (): Promise<void> => {
    await copyJob({ variables: { jobId: job.id } })
    if (copyJobError) {
      enqueueSnackbar(
        `Failed to duplicate job ${job.name}: ${formatMaybeApolloError(copyJobError)}`,
        {
          variant: 'error',
        },
      )
    } else {
      enqueueSnackbar(`Successfully duplicated job ${job.name}`, {
        variant: 'success',
      })
      refetchJobs()
    }
  }

  const changeJobName = useCallback(
    async (jobName: string): Promise<void> => {
      await editJob({ variables: { jobId: job.id, name: jobName } })
      refetchJobs()
      if (editJobError) {
        enqueueSnackbar(`Failed to change job name: ${formatMaybeApolloError(editJobError)}`, {
          variant: 'error',
        })
      }
    },
    [editJob, editJobError, enqueueSnackbar, job.id, refetchJobs],
  )

  const changeNameDebounceTime = 500
  const handleChangeJobName = useMemo(() => {
    const debouncedChangeName = debounce(changeJobName, changeNameDebounceTime)
    return (jobName: string) => debouncedChangeName(jobName)
  }, [changeJobName])

  const toggleSelect = (checked: boolean): void => {
    handleJobSelectionChange(job, checked)
  }

  return (
    <>
      <TableRow key={job.id} data-testid={`job-row-${job.name}`}>
        <TableCell>
          <Checkbox
            checked={!!selectedJobs.find((selectedJob) => selectedJob.id === job.id)}
            onChange={(_, checked) => toggleSelect(checked)}
          />
        </TableCell>
        <TableCell>
          <Box display='flex'>
            <IconButton
              aria-label='view'
              size='small'
              className={classes.actionIcon}
              onClick={handleViewJob}
              data-testid='open-job-button'
            >
              <DescriptionIcon />
            </IconButton>
            <IconButton
              aria-label='delete'
              size='small'
              className={classes.actionIcon}
              onClick={() => setDeleteDialogShowing(true)}
            >
              <DeleteOutlineIcon />
            </IconButton>
            <IconButton
              aria-label='duplicate'
              size='small'
              className={classes.actionIcon}
              onClick={handleDuplicateJob}
            >
              <FileCopyIcon />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell>
          <TextField
            defaultValue={job.name}
            onChange={(e) => handleChangeJobName(e.target.value)}
          />
        </TableCell>
        <TableCell>{job.jobTemplate?.name}</TableCell>
        <TableCell>
          <JobOwnerSelect refetchJobs={refetchJobs} job={job} />
        </TableCell>
        <TableCell>
          <JobQASelect refetchJobs={refetchJobs} job={job} />
        </TableCell>
        <TableCell>
          <JobManualProductSelect job={job} refetchJobs={refetchJobs} abbreviate />
        </TableCell>
        <TableCell align='center'>
          <MoveJobStatus refetchJobs={refetchJobs} job={job} task={task} />
        </TableCell>
      </TableRow>
      {deleteDialogShowing && (
        <Dialog open={deleteDialogShowing}>
          <DialogTitle>
            <Box p={1}>
              <Typography variant='h1'>Delete Job</Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this job?</Typography>
          </DialogContent>
          <Box display='flex' alignItems='center' justifyContent='center' mb={3}>
            <Button onClick={() => setDeleteDialogShowing(false)} variant='contained'>
              Cancel
            </Button>
            <Button
              onClick={handleDeleteJob}
              color='primary'
              variant='contained'
              data-testid='delete-job-btn'
            >
              Delete
            </Button>
          </Box>
        </Dialog>
      )}
    </>
  )
}

export default JobRow
