/**
 * Provides analytics for our app (named user events to avoid being blocked by aggressive
 * ad-blockers and the like)
 */
import { FunctionComponent } from 'react'
import useRollbar from '@src/utils/observability/rollbar/useRollbar'
import useGoogleTagManager from '@src/utils/observability/useGoogleTagManager'
import { useAmplitude } from '@src/utils/observability/useAmplitude'
import { OpenReplayProvider } from '@src/utils/observability/useOpenReplay'
import { ExpedockPostHogProvider } from '@src/utils/observability/ExpedockPostHogProvider'

const UserEvents: FunctionComponent = ({ children }) => {
  useRollbar()
  useGoogleTagManager()
  useAmplitude()
  return (
    <OpenReplayProvider>
      <ExpedockPostHogProvider>{children}</ExpedockPostHogProvider>
    </OpenReplayProvider>
  )
}

export default UserEvents
