import { gql } from '@apollo/client'

export const GET_GEO_LOCATIONS = gql`
  query geoLocations($query: String!) {
    geoLocations(query: $query) {
      id
      code
      locodeName
      timezoneName
    }
  }
`
