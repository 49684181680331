import { FunctionComponent } from 'react'
import { OutlinedInput } from '@material-ui/core'
import { Controller, RegisterOptions } from 'react-hook-form'
import ControllerErrorText from '@src/components/controller-error-text/ControllerErrorText'

type Props = {
  name: string
  friendlyName: string
  disabled?: boolean
  rules?: RegisterOptions
  defaultValue?: string | number | undefined | null
  autoFocus?: boolean
  placeHolder?: string
  type?: string
}

/**
 * Convenient wrapper around a Controller-wrapped text input field
 */
const ControllerTextField: FunctionComponent<Props> = ({
  name,
  friendlyName,
  disabled,
  rules,
  defaultValue,
  autoFocus,
  placeHolder,
  type,
}) => {
  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { ref, value, ...rest }, fieldState: { invalid, error } }) => (
        <>
          <OutlinedInput
            inputRef={ref}
            autoFocus={autoFocus || false}
            margin='dense'
            placeholder={placeHolder}
            error={invalid}
            value={value ?? ''}
            {...rest}
            fullWidth
            disabled={disabled || false}
            type={type}
          />
          <ControllerErrorText friendlyName={friendlyName} error={error} />
        </>
      )}
    />
  )
}

export default ControllerTextField
