import { CwTargetModule, FindShipmentReconResultNode } from '@src/graphql/types'
import { ReactElement } from 'react'
import { ReconResultModelReferenceDisplay } from '@src/utils/recon/ReconResultModelReferenceDisplay'

type Props = {
  findShipmentResults: FindShipmentReconResultNode[]
  cargowiseModule: CwTargetModule | null
}

export const ReferenceNumberCell = ({
  findShipmentResults,
  cargowiseModule,
}: Props): ReactElement => {
  const successfulFindShipmentReconResult = findShipmentResults.find(
    (reconResult) => reconResult.success,
  )
  if (successfulFindShipmentReconResult) {
    return <ReconResultModelReferenceDisplay result={successfulFindShipmentReconResult} />
  }
  const isForwardingShipmentToConsol = cargowiseModule === CwTargetModule.ForwardingShipmentToConsol
  // for ForwardingShipmentToConsol, we may successfully find the consol, but find multiple shipments
  // https://expedock.atlassian.net/browse/PD-6802
  const consolResult = findShipmentResults.find((reconResult) => reconResult.chainIoConsolidation)
  if (isForwardingShipmentToConsol && consolResult) {
    // since there was no successful recon result, either there were no shipments, or multiple
    const hasShipments = findShipmentResults.find(
      (result) => result.findShipmentDuplicateReconResults.edges.length,
    )
    return (
      <>
        <ReconResultModelReferenceDisplay result={consolResult} /> but{' '}
        {hasShipments ? 'multiple' : 'no'} shipments
      </>
    )
  }
  return <></>
}
