import { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/styles'

import {
  ChainIoConsolidationNode,
  ChainIoCustomsDeclarationNode,
  ChainIoShipmentNode,
} from '@src/graphql/types'

import {
  TableCell,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core'
import theme from '@src/utils/theme'
import { cleanNonAlphaNumeric } from '@src/utils/string'

type Props = {
  objects: (ChainIoShipmentNode | ChainIoCustomsDeclarationNode | ChainIoConsolidationNode)[]
  objectType: string
  prevSearchKey: string
}

const useStyles = makeStyles({
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    maxHeight: '60vh',
    overflowY: 'scroll',
    marginBottom: theme.spacing(2),
  },
  accordionRoot: {
    width: '100%',
  },
  highlightedCell: {
    backgroundColor: 'yellow',
    border: '1px solid black',
  },
  nonHighlightedCell: {
    border: '1px solid black',
  },
  tableHead: {
    backgroundColor: theme.palette.grey[400],
    position: 'sticky',
    top: '0',
  },
})

const SearchEntityTable: FunctionComponent<Props> = ({ prevSearchKey, objects, objectType }) => {
  const classes = useStyles()
  const shouldHighlightFromCellText = (cellText: string): boolean => {
    const cleanedCellText = cleanNonAlphaNumeric(cellText).toLowerCase()
    const cleanedSearchKey = cleanNonAlphaNumeric(prevSearchKey).toLowerCase()
    return cleanedCellText.includes(cleanedSearchKey)
  }
  const getTmsId = (
    object: ChainIoConsolidationNode | ChainIoShipmentNode | ChainIoCustomsDeclarationNode,
    seperator: string,
  ): string => {
    if (objectType === 'ChainIOShipmentNode') {
      return (object as ChainIoShipmentNode).tmsId || ''
    } else if (objectType === 'ChainIOConsolidationNode') {
      return (object as ChainIoConsolidationNode).tmsId || ''
    } else {
      return (object as ChainIoCustomsDeclarationNode).tmsId || ''
    }
  }
  const getReferenceNumber = (
    object: ChainIoConsolidationNode | ChainIoShipmentNode | ChainIoCustomsDeclarationNode,
    seperator: string,
  ): string => {
    if (objectType === 'ChainIOShipmentNode' || objectType === 'ChainIOCustomsDeclarationNode') {
      return (object as ChainIoShipmentNode).forwarderReference || ''
    } else {
      return (object as ChainIoConsolidationNode).shipments.edges
        .map((shipment) => shipment.node.forwarderReference)
        .join(seperator)
    }
  }
  const getHBL = (
    object: ChainIoConsolidationNode | ChainIoShipmentNode | ChainIoCustomsDeclarationNode,
    seperator: string,
  ): string => {
    if (objectType === 'ChainIOShipmentNode') {
      return (object as ChainIoShipmentNode).houseBill || ''
    } else if (objectType === 'ChainIOConsolidationNode') {
      return (object as ChainIoConsolidationNode).shipments.edges
        .map((shipment) => shipment.node.houseBill)
        .join(seperator)
    } else {
      return (object as ChainIoCustomsDeclarationNode).chainIoShipment?.houseBill || ''
    }
  }
  const getMBL = (
    object: ChainIoConsolidationNode | ChainIoShipmentNode | ChainIoCustomsDeclarationNode,
    seperator: string,
  ): string => {
    if (objectType === 'ChainIOShipmentNode') {
      return (
        (object as ChainIoShipmentNode).masterBill ||
        (object as ChainIoShipmentNode).chainIoConsolidation?.masterBill ||
        ''
      )
    } else if (objectType === 'ChainIOConsolidationNode') {
      return (
        (object as ChainIoConsolidationNode).masterBill ||
        (object as ChainIoConsolidationNode).shipments.edges
          .map((shipment) => shipment.node.masterBill || '')
          .join(seperator) ||
        ''
      )
    } else {
      return (
        (object as ChainIoCustomsDeclarationNode).chainIoShipment?.chainIoConsolidation
          ?.masterBill || ''
      )
    }
  }
  const getCarrierBookingNumber = (
    object: ChainIoConsolidationNode | ChainIoShipmentNode | ChainIoCustomsDeclarationNode,
    seperator: string,
  ): string => {
    if (objectType === 'ChainIOShipmentNode') {
      return (object as ChainIoShipmentNode).chainIoConsolidation?.carrierBookingRef || ''
    } else if (objectType === 'ChainIOConsolidationNode') {
      return (object as ChainIoConsolidationNode).carrierBookingRef || ''
    } else if (objectType === 'ChainIOCustomsDeclarationNode') {
      return (
        (object as ChainIoCustomsDeclarationNode).chainIoShipment?.chainIoConsolidation
          ?.carrierBookingRef || ''
      )
    }
    return ''
  }

  const getConsolNumber = (
    object: ChainIoConsolidationNode | ChainIoShipmentNode | ChainIoCustomsDeclarationNode,
    seperator: string,
  ): string => {
    if (objectType === 'ChainIOShipmentNode') {
      return (object as ChainIoShipmentNode).chainIoConsolidation?.forwarderReference || ''
    } else if (objectType === 'ChainIOConsolidationNode') {
      return (object as ChainIoConsolidationNode).forwarderReference || ''
    } else if (objectType === 'ChainIOCustomsDeclarationNode') {
      return (
        (object as ChainIoCustomsDeclarationNode).chainIoShipment?.chainIoConsolidation
          ?.forwarderReference || ''
      )
    }
    return ''
  }

  const getOrderReferences = (
    object: ChainIoConsolidationNode | ChainIoShipmentNode | ChainIoCustomsDeclarationNode,
    seperator: string,
  ): string => {
    if (objectType === 'ChainIOShipmentNode') {
      return (
        (object as ChainIoShipmentNode).orders.edges
          .map((order) => order.node.orderReference)
          .join(seperator) || ''
      )
    } else if (objectType === 'ChainIOConsolidationNode') {
      return (
        (object as ChainIoConsolidationNode).shipments.edges
          .map((shipment) =>
            shipment.node.orders.edges
              .flatMap((order) => order.node.orderReference)
              .join(seperator),
          )
          .join(seperator) || ''
      )
    } else if (objectType === 'ChainIOCustomsDeclarationNode') {
      return (
        (object as ChainIoCustomsDeclarationNode).chainIoShipment?.orders.edges
          .flatMap((order) => order.node.orderReference)
          .join(seperator) || ''
      )
    }
    return ''
  }

  const getContainerNumbers = (
    object: ChainIoConsolidationNode | ChainIoShipmentNode | ChainIoCustomsDeclarationNode,
    seperator: string,
  ): string => {
    if (objectType === 'ChainIOShipmentNode') {
      return (
        (object as ChainIoShipmentNode).containers.edges
          .map((container) => container.node.containerNumber)
          .join(seperator) || ''
      )
    } else if (objectType === 'ChainIOConsolidationNode') {
      return (
        (object as ChainIoConsolidationNode).containers.edges
          .map((container) => container.node.containerNumber)
          .join(seperator) || ''
      )
    } else if (objectType === 'ChainIOCustomsDeclarationNode') {
      return (
        (object as ChainIoCustomsDeclarationNode).chainIoShipment?.containers.edges
          .flatMap((container) => container.node.containerNumber)
          .join(seperator) || ''
      )
    }
    return ''
  }
  return (
    <div>
      <TableContainer component={Paper} className={classes.table}>
        <Table aria-label='collapsible table'>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {[
                'Shipment/Cdec Reference Number',
                'TMS ID',
                'HBL',
                'MBL',
                'Carrier Booking Number',
                'Consol Number',
                'Order References',
                'Container Numbers',
              ].map((key) => {
                return (
                  <TableCell className={classes.nonHighlightedCell} key={`search-shipment-${key}`}>
                    {key}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {objects.map((object) => {
              // Yes, we really need 😂. We need a seperator that won't show up in the text so we can highlight correctly
              // and we can clean for actual display.
              const seperator = '😂'
              const seperatorRegex = /😂/g
              const tmsId = getTmsId(object, seperator)
              const referenceNumber = getReferenceNumber(object, seperator)
              const hbl = getHBL(object, seperator)
              const mbl = getMBL(object, seperator)
              const carrierBookingRef = getCarrierBookingNumber(object, seperator)
              const consolNumber = getConsolNumber(object, seperator)
              const orderReferences = getOrderReferences(object, seperator)
              const containerNumbers = getContainerNumbers(object, seperator)
              const [
                displayReferenceNumber,
                displayTmsId,
                displayHbl,
                displayMbl,
                displayCarrierBookingRef,
                displayConsolNumber,
                displayOrderReferences,
                displayContainerNumbers,
              ] = [
                referenceNumber.replace(seperatorRegex, ','),
                tmsId.replace(seperatorRegex, ','),
                hbl.replace(seperatorRegex, ','),
                mbl.replace(seperatorRegex, ','),
                carrierBookingRef.replace(seperatorRegex, ','),
                consolNumber.replace(seperatorRegex, ','),
                orderReferences.replace(seperatorRegex, ','),
                containerNumbers.replace(seperatorRegex, ','),
              ]
              return (
                <TableRow key={object.id}>
                  <TableCell
                    className={
                      shouldHighlightFromCellText(referenceNumber)
                        ? classes.highlightedCell
                        : classes.nonHighlightedCell
                    }
                  >
                    {displayReferenceNumber}
                  </TableCell>
                  <TableCell
                    className={
                      shouldHighlightFromCellText(tmsId)
                        ? classes.highlightedCell
                        : classes.nonHighlightedCell
                    }
                  >
                    {displayTmsId}
                  </TableCell>
                  <TableCell
                    className={
                      shouldHighlightFromCellText(hbl)
                        ? classes.highlightedCell
                        : classes.nonHighlightedCell
                    }
                  >
                    {displayHbl}
                  </TableCell>
                  <TableCell
                    className={
                      shouldHighlightFromCellText(mbl)
                        ? classes.highlightedCell
                        : classes.nonHighlightedCell
                    }
                  >
                    {displayMbl}
                  </TableCell>
                  <TableCell
                    className={
                      shouldHighlightFromCellText(carrierBookingRef)
                        ? classes.highlightedCell
                        : classes.nonHighlightedCell
                    }
                  >
                    {displayCarrierBookingRef}
                  </TableCell>
                  <TableCell
                    className={
                      shouldHighlightFromCellText(consolNumber)
                        ? classes.highlightedCell
                        : classes.nonHighlightedCell
                    }
                  >
                    {displayConsolNumber}
                  </TableCell>
                  <TableCell
                    className={
                      shouldHighlightFromCellText(orderReferences)
                        ? classes.highlightedCell
                        : classes.nonHighlightedCell
                    }
                  >
                    {displayOrderReferences}
                  </TableCell>
                  <TableCell
                    className={
                      shouldHighlightFromCellText(containerNumbers)
                        ? classes.highlightedCell
                        : classes.nonHighlightedCell
                    }
                  >
                    {displayContainerNumbers}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default SearchEntityTable
