import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Paper, Box, Typography, Grid } from '@material-ui/core'

import {
  CompanyNode,
  CustomDatabaseNode,
  Maybe,
  Mutation,
  MutationCreateCustomDatabaseArgs,
  MutationDeleteCustomDatabaseArgs,
  MutationEditCustomDatabaseArgs,
  Query,
  QueryCustomDatabasesArgs,
  QueryCustomDatabaseTypesArgs,
} from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import useCompanyData from '@src/hooks/admin/useCompanyData'
import DatabaseHeader from './DatabaseHeader'
import theme from '@src/utils/theme'
import DatabaseTable from './DatabaseTable'
import DatabaseMetadataTable from './DatabaseMetadataTable'
import DatabaseSearchBar from './DatabaseSearchBar'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import CreateButtons from './CreateButtons'
import ActionButtons from './ActionButtons'
import DeleteDatabaseModal from './DeleteDatabaseModal'
import CreateDatabaseModal from './CreateDatabaseModal'
import CancelCreateDatabaseModal from './CancelCreateDatabaseModal'
import { MetadataFormField, validateMetadataFormFields } from './metadata_form'
import { SpreadsheetDataColumn } from '@src/utils/data-grid'
import {
  constructInputDatabase,
  constructColumnsFromDatabaseType,
  constructRowsFromCustomDatabase,
} from '@src/utils/admin/table'
import { DatabaseRecord, DatabaseRecordType } from './record_type'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  GET_CUSTOM_DATABASES,
  GET_CUSTOM_DATABASE_TYPES,
} from '@src/graphql/queries/customDatabase'
import { formatMaybeApolloError } from '@src/utils/errors'
import {
  CREATE_CUSTOM_DATABASE,
  DELETE_CUSTOM_DATABASE,
  EDIT_CUSTOM_DATABASE,
} from '@src/graphql/mutations/customDatabase'
import { makeStyles } from '@material-ui/styles'

type Props = {
  company: Maybe<CompanyNode>
  companyId: string
  refreshDatabaseTypes: boolean
  setRefreshDatabaseTypes: (toggle: boolean) => void
}

const useStyles = makeStyles({
  databaseBody: {
    position: 'relative',
  },
  databaseForm: {
    height: '650px',
    backgroundColor: theme.palette.grey[200],
  },
  buttons: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
})

const CUSTOM_DATABASE_PAGE_SIZE = 1000

// We pass in both company and companyId because company is a Maybe type and may be null
const CustomDatabase: FunctionComponent<Props> = ({
  company,
  companyId,
  refreshDatabaseTypes,
  setRefreshDatabaseTypes,
}) => {
  const classes = useStyles()
  const [selectedCustomDatabase, setSelectedCustomDatabase] = useState(
    null as null | CustomDatabaseNode,
  )
  const [currDatabaseName, setCurrDatabaseName] = useState('')
  const [currDatabaseTypeId, setCurrDatabaseTypeId] = useState('')
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [newDatabaseModalOpen, setNewDatabaseModalOpen] = useState(false)
  const [createNewDatabaseMode, setCreateNewDatabaseMode] = useState(false)
  const [cancelCreateNewDatabaseModalOpen, setCancelCreateNewDatabaseModalOpen] = useState(false)
  const { apiPartners } = useCompanyData(company)
  const [rows, setRows] = useState([] as string[][])
  const [columns, setColumns] = useState([] as SpreadsheetDataColumn[])
  const [customDatabases, setCustomDatabases] = useState([] as CustomDatabaseNode[])

  const [currApiPartnerId, setCurrApiPartnerId] = useState<string | null>(null)
  const [customDatabasePage, setCustomDatabasePage] = useState(0)
  const { enqueueSnackbar } = useSnackbar()

  const { data: databaseTypesData, refetch: refetchDatabaseTypes } = useQuery<
    Pick<Query, 'customDatabaseTypes'>,
    QueryCustomDatabaseTypesArgs
  >(GET_CUSTOM_DATABASE_TYPES, {
    fetchPolicy: 'no-cache',
    variables: {
      query: '',
    },
    onError: (error) => {
      enqueueSnackbar(`Error searching custom database types: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const [searchDatabases, { refetch: refetchDatabases, called: searchCalled }] = useLazyQuery<
    Pick<Query, 'customDatabases'>,
    QueryCustomDatabasesArgs
  >(GET_CUSTOM_DATABASES, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setCustomDatabases(data.customDatabases)
    },
    onError: (error) => {
      enqueueSnackbar(`Error searching custom databases: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const fetchDatabases = useCallback(
    (query: string): void => {
      if (currApiPartnerId) {
        void searchDatabases({ variables: { query, apiPartnerId: currApiPartnerId } })
      }
    },
    [currApiPartnerId, searchDatabases],
  )

  useEffect(() => {
    const currDatabaseType = databaseTypesData?.customDatabaseTypes.find(
      (databaseType) => databaseType.id == currDatabaseTypeId,
    )
    const cols = constructColumnsFromDatabaseType(
      currDatabaseType ?? selectedCustomDatabase?.customDatabaseType,
    )
    setColumns(cols)
  }, [selectedCustomDatabase, currDatabaseTypeId, databaseTypesData])

  useEffect(() => {
    if (createNewDatabaseMode) {
      setRows(Array.from({ length: 10 }, () => []) as string[][])
    } else if (selectedCustomDatabase) {
      const newRows = constructRowsFromCustomDatabase(selectedCustomDatabase, columns)
      setRows(newRows as string[][])
    }
  }, [selectedCustomDatabase, createNewDatabaseMode, columns])

  useEffect(() => {
    if (currApiPartnerId) {
      if (searchCalled) {
        void refetchDatabases({ apiPartnerId: currApiPartnerId })
      } else {
        void fetchDatabases('')
      }
    } else {
      setSelectedCustomDatabase(null)
    }
  }, [currApiPartnerId, refetchDatabases, searchCalled, fetchDatabases])

  useEffect(() => {
    void refetchDatabaseTypes()
    setRefreshDatabaseTypes(false)
  }, [refreshDatabaseTypes])

  const resetMappingState = (): void => {
    setCreateNewDatabaseMode(false)
    setSelectedCustomDatabase(null)
    void refetchDatabases()
  }
  const loadingCustomDatabase = false

  const [createCustomDatabase] = useMutation<
    Pick<Mutation, 'createCustomDatabase'>,
    MutationCreateCustomDatabaseArgs
  >(CREATE_CUSTOM_DATABASE, {
    onCompleted: () => {
      enqueueSnackbar('Custom database created', { variant: 'success' })
      resetMappingState()
    },
    onError: (error) => {
      enqueueSnackbar(`Error creating custom database: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const [editCustomDatabase] = useMutation<
    Pick<Mutation, 'editCustomDatabase'>,
    MutationEditCustomDatabaseArgs
  >(EDIT_CUSTOM_DATABASE, {
    onCompleted: () => {
      enqueueSnackbar('Custom database updated', { variant: 'success' })
      resetMappingState()
    },
    onError: (error) => {
      enqueueSnackbar(`Error updating details: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const [deleteCustomDatabase] = useMutation<
    Pick<Mutation, 'deleteCustomDatabase'>,
    MutationDeleteCustomDatabaseArgs
  >(DELETE_CUSTOM_DATABASE, {
    onCompleted: () => {
      enqueueSnackbar('Custom database deleted', { variant: 'success' })
      resetMappingState()
    },
    onError: (error) => {
      enqueueSnackbar(`Error deleting custom database: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const metadataFormFields = useMemo(
    () =>
      [
        {
          fieldName: 'name',
          displayName: 'Database Name',
          fieldValue: currDatabaseName,
          setFieldValue: setCurrDatabaseName,
          required: true,
        },
        {
          fieldName: 'customDatabaseTypeId',
          displayName: 'Database Type',
          fieldValue: currDatabaseTypeId,
          setFieldValue: setCurrDatabaseTypeId,
          required: true,
          options:
            databaseTypesData?.customDatabaseTypes?.map((databaseType) => {
              return {
                display: databaseType.name,
                value: databaseType.id,
              }
            }) ?? [],
          disabled: !createNewDatabaseMode,
        },
      ] as MetadataFormField[],
    [
      currDatabaseName,
      setCurrDatabaseName,
      currDatabaseTypeId,
      setCurrDatabaseTypeId,
      createNewDatabaseMode,
      databaseTypesData,
    ],
  )

  const confirmCreateCustomDatabase = (): void => {
    setCreateNewDatabaseMode(true)
    setNewDatabaseModalOpen(false)
    setSelectedCustomDatabase(null)
  }

  const createNewDatabase = (): void => {
    if (!validateMetadataFormFields(metadataFormFields)) {
      enqueueSnackbar(`Missing required fields`, {
        variant: 'error',
      })
      return
    }
    const inputCustomDatabase = constructInputDatabase(metadataFormFields, rows, columns)

    if (currApiPartnerId) {
      void createCustomDatabase({
        variables: {
          inputCustomDatabase,
          apiPartnerId: currApiPartnerId,
        },
      })
    } else {
      enqueueSnackbar('Select a TMS to create database to!', {
        variant: 'error',
      })
    }
  }

  const saveCustomDatabase = async (): Promise<void> => {
    if (!validateMetadataFormFields(metadataFormFields)) {
      enqueueSnackbar(`Missing required fields`, {
        variant: 'error',
      })
      return
    }
    const inputCustomDatabase = constructInputDatabase(metadataFormFields, rows, columns)

    await editCustomDatabase({
      variables: {
        customDatabaseId: selectedCustomDatabase!.id,
        inputCustomDatabase,
      },
    })
  }

  const confirmDeleteSelectedDatabase = async (): Promise<void> => {
    await deleteCustomDatabase({
      variables: {
        customDatabaseId: selectedCustomDatabase!.id,
      },
    })
    setDeleteModalOpen(false)
  }

  return (
    <Paper className={classes.databaseBody}>
      <DatabaseHeader
        title='Custom Databases'
        buttonText='+ Custom Database'
        onNewDatabase={() => setNewDatabaseModalOpen(true)}
      />
      <Grid container>
        <Grid item xs={12} md={3}>
          <DatabaseSearchBar
            selectedRecord={selectedCustomDatabase as any as DatabaseRecord}
            setSelectedRecord={
              setSelectedCustomDatabase as (database: DatabaseRecord | null) => void
            }
            displayRecord={(database: DatabaseRecord | null) => database?.name ?? ''}
            setActivePage={setCustomDatabasePage}
            searchResults={customDatabases as any as DatabaseRecord[]}
            fetchRecords={fetchDatabases}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Box className={classes.databaseForm}>
            <DatabaseMetadataTable
              record={selectedCustomDatabase as any as DatabaseRecord}
              metadataFormFields={metadataFormFields}
              createNewRecordMode={createNewDatabaseMode}
              apiPartners={apiPartners}
              currApiPartnerId={currApiPartnerId}
              setCurrApiPartnerId={setCurrApiPartnerId}
            />
            {createNewDatabaseMode || (selectedCustomDatabase != null && !loadingCustomDatabase) ? (
              loadingCustomDatabase ? (
                <CenteredCircularProgress />
              ) : selectedCustomDatabase || currDatabaseTypeId ? (
                <DatabaseTable
                  currApiPartnerId={currApiPartnerId}
                  recordPageSize={CUSTOM_DATABASE_PAGE_SIZE}
                  activePage={customDatabasePage}
                  setActivePage={setCustomDatabasePage}
                  createNewRecordMode={createNewDatabaseMode}
                  rows={rows}
                  columns={columns}
                />
              ) : (
                <Typography align='center'>Select a database type to start</Typography>
              )
            ) : loadingCustomDatabase ? (
              <CenteredCircularProgress />
            ) : (
              <Typography align='center'>Select a database to edit details</Typography>
            )}
            <Box className={classes.buttons}>
              {createNewDatabaseMode ? (
                <CreateButtons
                  create={createNewDatabase}
                  cancel={() => setCancelCreateNewDatabaseModalOpen(true)}
                />
              ) : (
                <ActionButtons
                  disabled={!selectedCustomDatabase}
                  save={saveCustomDatabase}
                  del={() => setDeleteModalOpen(true)}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <DeleteDatabaseModal
        rows={rows}
        columns={columns}
        databaseRecordType={DatabaseRecordType.CustomDatabase}
        setOpen={setDeleteModalOpen}
        confirmDelete={confirmDeleteSelectedDatabase}
        open={deleteModalOpen}
      />
      <CreateDatabaseModal
        databaseRecordType={DatabaseRecordType.CustomDatabase}
        setOpen={setNewDatabaseModalOpen}
        confirmCreate={confirmCreateCustomDatabase}
        open={newDatabaseModalOpen}
      />
      <CancelCreateDatabaseModal
        databaseRecordType={DatabaseRecordType.CustomDatabase}
        setOpen={setCancelCreateNewDatabaseModalOpen}
        cancelCreateNewDatabase={() => setCreateNewDatabaseMode(false)}
        open={cancelCreateNewDatabaseModalOpen}
      />
    </Paper>
  )
}

export default CustomDatabase
