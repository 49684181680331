import { Grid, GridProps, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ApiPartnerInterface, CompanyNode } from '@src/graphql/types'
import { Dispatch, FunctionComponent, SetStateAction, useMemo } from 'react'
import CompanyApiPartnersSelect from './CompanyApiPartnersSelect'

type Props = {
  enabledCompanies: CompanyNode[]
  enabledApiPartners?: ApiPartnerInterface[]
  selectedCompany: CompanyNode | null
  setSelectedCompany: Dispatch<SetStateAction<CompanyNode | null>>
  selectedApiPartners: ApiPartnerInterface[] | null
  setSelectedApiPartners?: Dispatch<SetStateAction<ApiPartnerInterface[] | null>>
  singleApiPartner?: boolean
} & GridProps

const CompanySelect: FunctionComponent<Props> = ({
  enabledCompanies,
  enabledApiPartners,
  selectedCompany,
  setSelectedCompany,
  selectedApiPartners,
  setSelectedApiPartners,
  singleApiPartner,
  ...props
}) => {
  const apiPartnerOptions = useMemo(() => {
    return (
      enabledApiPartners?.filter((apiPartner) => apiPartner.companyId === selectedCompany?.id) ?? []
    )
  }, [selectedCompany, enabledApiPartners])

  return (
    <Grid item container spacing={1} {...props}>
      <Grid item xs={12} md={5}>
        <Autocomplete
          multiple={false}
          options={enabledCompanies}
          getOptionLabel={(option: CompanyNode) => option.name!}
          value={selectedCompany}
          onChange={(_event, newValue) => {
            setSelectedCompany(newValue)
          }}
          renderInput={(params) => <TextField variant='outlined' {...params} label='Company' />}
        />
      </Grid>
      {enabledApiPartners && setSelectedApiPartners && (
        <Grid item xs={12} md={7}>
          <CompanyApiPartnersSelect
            enabledApiPartners={apiPartnerOptions}
            selectedCompany={selectedCompany}
            selectedApiPartners={selectedApiPartners}
            setSelectedApiPartners={setSelectedApiPartners}
            singleApiPartner={singleApiPartner}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default CompanySelect
