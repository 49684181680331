import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, MutableRefObject, useState } from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import theme from '@src/utils/theme'

import ActionButtons from './ActionButtons'
import VendorCodesTable from './VendorCodesTable'
import ChargeCodeMetadataTable from './ChargeCodeMetadataTable'
import ChargeCodeSearchBar from './ChargeCodeSearchBar'
import { useEffect } from 'react'
import {
  ChargeCodeNode,
  CompanyNode,
  InputVendorAndDesc,
  Maybe,
  useEditChargeCodeAndOverridesMutation,
  useCreateChargeCodesMutation,
  useCreateChargeCodeAndOverridesMutation,
  useChargeCodesWithSameCodeLazyQuery,
  useChargeCodeAndVendorOverridesLazyQuery,
  useEditChargeCodesMutation,
} from '@src/graphql/types'
import { HotTable } from '@handsontable/react'
import { useRef } from 'react'
import DeleteChargeCodeModal from './DeleteChargeCodeModal'
import AddMultipleModal from './AddMultipleModal'
import CreateChargeCodeModal from './CreateChargeCodeModal'
import CreateButtons from './CreateButtons'
import CancelCreateChargeCodeModal from './CancelCreateChargeCodeModal'

import { useSnackbar } from 'notistack'
import ChargeCodeMappingHeader from './ChargeCodeMappingHeader'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import useCompanyData from '@src/hooks/admin/useCompanyData'
import VendorOverrideCodesTable from './VendorOverrideCodesTable'
import DeleteChargeCodeAndOverridesModal from './DeleteChargeCodeAndOverridesModal'
import AddMultipleVendorOverridesModal from './AddMultipleVendorOverridesModal'
import { ChargeCodeWithOverride, ChargeCodeWithVendorArray, SelectedChargeCode } from './types'

const placeholderChargeCodeData = Object.freeze(
  Array.from({ length: 10 }, () => ({
    description: '',
    id: '',
    chargeVendor: {
      id: '',
      name: '',
      code: '',
      dateDeleted: null,
    },
  })),
) as ChargeCodeNode[]

type Props = {
  companyId: string
  company: Maybe<CompanyNode>
  refreshSearch: boolean
  setRefreshSearch: (toggle: boolean) => void
}

const ChargeCodeMapping: FunctionComponent<Props> = ({
  companyId,
  company,
  refreshSearch,
  setRefreshSearch,
}) => {
  const [selectedChargeCode, setSelectedChargeCode] = useState<SelectedChargeCode>()

  const [chargeCodesWithVendor, setChargeCodesWithVendor] = useState<ChargeCodeWithVendorArray>([])
  const [chargeCodeWithOverrides, setChargeCodeWithOverride] = useState<ChargeCodeWithOverride>()

  const [currChargeCodeCode, setCurrChargeCodeCode] = useState('')
  const [currTaxId, setCurrTaxId] = useState<string>('')
  const [currApiPartnerId, setCurrApiPartnerId] = useState<string | null>(null)
  const [currChargeDescription, setCurrChargeDescription] = useState('')
  const { apiPartners, usesChargeCodeV2 } = useCompanyData(company)

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [addMultipleModalOpen, setAddMultipleModalOpen] = useState(false)
  const [newChargeCodeModalOpen, setNewChargeCodeModalOpen] = useState(false)
  const [cancelCreateNewChargeCodeModalOpen, setCancelCreateNewChargeCodeModalOpen] =
    useState(false)
  const [createNewChargeCodeMode, setCreateNewChargeCodeMode] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const [addToMultipleVendorsDisabled, setAddToMultipleVendorsDisabled] = useState(false)

  const vendorCodesHotTableRef = useRef<HotTable | undefined>()
  const vendorOverridesHotTableRef = useRef<HotTable | undefined>()

  const [updateChargeCodeDetails] = useEditChargeCodesMutation({
    onCompleted: () => {
      setRefreshSearch(true)
      enqueueSnackbar('Charge code details updated', { variant: 'success' })
    },
    onError: (error) => {
      enqueueSnackbar(`Error updating charge code details: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })
  const [updateChargeCodeDetailsAndOverrides] = useEditChargeCodeAndOverridesMutation({
    onCompleted: () => {
      setRefreshSearch(true)
      enqueueSnackbar('Charge code details updated', { variant: 'success' })
    },
    onError: (error) => {
      enqueueSnackbar(`Error updating charge code details: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const [createChargeCodes] = useCreateChargeCodesMutation({
    onCompleted: () => {
      enqueueSnackbar('Charge codes created', { variant: 'success' })
      resetMappingState()
    },
    onError: (error) => {
      enqueueSnackbar(`Error creating charge codes: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })
  const [createChargeCodeAndOverrides] = useCreateChargeCodeAndOverridesMutation({
    onCompleted: () => {
      enqueueSnackbar('Charge codes created', { variant: 'success' })
      resetMappingState()
    },
    onError: (error) => {
      enqueueSnackbar(`Error creating charge codes: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const [getChargeCodeVendorsAndDesc, { loading: loadingVendorsAndDesc }] =
    useChargeCodesWithSameCodeLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: ({ chargeCodes }) => {
        if (chargeCodes.length > 0) {
          setChargeCodesWithVendor(chargeCodes)
        } else {
          setChargeCodesWithVendor([])
        }
      },
    })
  const [getChargeCodeVendorOverridesAndDesc, { loading: loadingVendorOverrides }] =
    useChargeCodeAndVendorOverridesLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: ({ chargeCodeAndVendorOverrides }) => {
        setChargeCodeWithOverride(chargeCodeAndVendorOverrides)

        const overrides = chargeCodeAndVendorOverrides.chargeCodeVendorOverrides?.edges || []
        const existingOverrides = overrides.filter((node) => node.node.dateDeleted == null)
        // We only allow adding overrides to multiple vendors if there are existing overrides.
        // Otherwise, vendors will use the default description assigned to the charge code.
        setAddToMultipleVendorsDisabled(existingOverrides.length == 0)
      },
    })

  const resetChargeCodeFields = (): void => {
    setSelectedChargeCode(undefined)
    setCurrTaxId('')
    setCurrChargeCodeCode('')
    setCurrChargeDescription('')
    setCurrApiPartnerId(null)
  }

  const resetMappingState = (): void => {
    setCreateNewChargeCodeMode(false)
    setChargeCodesWithVendor([])
    setChargeCodeWithOverride(undefined)
    resetChargeCodeFields()
    setRefreshSearch(true)
  }

  const confirmCreateChargeCode = (): void => {
    setCreateNewChargeCodeMode(true)
    setNewChargeCodeModalOpen(false)
    setChargeCodesWithVendor([])
    setChargeCodeWithOverride(undefined)
    resetChargeCodeFields()
  }

  const setChargeCodeFromSearchResult = (chargeCode: SelectedChargeCode): void => {
    // don't allow code to be altered if in create mode
    if (createNewChargeCodeMode) {
      return
    }
    setSelectedChargeCode(chargeCode)
  }

  useEffect(() => {
    if (selectedChargeCode) {
      setCurrChargeCodeCode(selectedChargeCode?.code ?? '')
      setCurrChargeDescription(selectedChargeCode?.description ?? '')
      setCurrTaxId(selectedChargeCode?.tax?.id ?? '')

      if (usesChargeCodeV2) {
        void getChargeCodeVendorOverridesAndDesc({
          variables: { chargeCodeId: selectedChargeCode?.id },
        })
      } else {
        void getChargeCodeVendorsAndDesc({ variables: { chargeCodeId: selectedChargeCode?.id } })
      }
    }
  }, [
    selectedChargeCode,
    getChargeCodeVendorsAndDesc,
    getChargeCodeVendorOverridesAndDesc,
    usesChargeCodeV2,
  ])

  const constructVendorsFromTable = (
    _hotTableRef: MutableRefObject<HotTable | undefined>,
  ): InputVendorAndDesc[] => {
    const hotTable = _hotTableRef?.current
    if (hotTable) {
      const tableData = hotTable.hotInstance.getData()
      const chargeVendors = tableData
        .filter(([description, code]) => description && code)
        .map(([vendorName, description]) => ({
          vendorName,
          description,
        }))
      return chargeVendors
    }
    return []
  }

  const saveVendorDetails = async (): Promise<void> => {
    const hotTableRef = usesChargeCodeV2 ? vendorOverridesHotTableRef : vendorCodesHotTableRef
    const vendorsAndDescriptions = constructVendorsFromTable(hotTableRef)

    if (usesChargeCodeV2) {
      // The new mutation allows empty overrides.
      // When a charge code has no overrides, then all vendors will use the same description from the charge_code_v2 table.
      await updateChargeCodeDetailsAndOverrides({
        variables: {
          chargeCodeId: selectedChargeCode?.id ?? '',
          code: currChargeCodeCode,
          description: currChargeDescription ?? '',
          vendorsAndDescriptions,
          taxId: currTaxId === '' ? null : currTaxId,
        },
      })
      return
    }

    if (vendorsAndDescriptions.length) {
      await updateChargeCodeDetails({
        variables: {
          chargeCodeId: selectedChargeCode?.id ?? '',
          apiPartnerId: currApiPartnerId,
          code: currChargeCodeCode,
          taxId: currTaxId === '' ? null : currTaxId,
          vendorsAndDescriptions,
        },
      })
    } else {
      enqueueSnackbar(`Please provide at least one vendor to associate this charge code with`, {
        variant: 'error',
      })
    }
  }

  const addNewVendorChargeCodes = (newVendorRows: string[][]): void => {
    const hotTable = usesChargeCodeV2
      ? vendorOverridesHotTableRef.current
      : vendorCodesHotTableRef.current
    if (hotTable) {
      const hot = hotTable.hotInstance
      hot.populateFromArray(hot.countRows(), 0, newVendorRows)
    }
  }

  const createNewChargeVendor = async (): Promise<void> => {
    if (usesChargeCodeV2) {
      await createChargeCodeAndOverrides({
        variables: {
          companyId,
          apiPartnerId: currApiPartnerId,
          taxId: currTaxId === '' ? null : currTaxId,
          code: currChargeCodeCode,
          description: currChargeDescription,
          vendorsAndDescriptions: constructVendorsFromTable(vendorOverridesHotTableRef),
        },
      })
    } else {
      await createChargeCodes({
        variables: {
          companyId,
          apiPartnerId: currApiPartnerId,
          taxId: currTaxId === '' ? null : currTaxId,
          code: currChargeCodeCode,
          vendorsAndDescriptions: constructVendorsFromTable(vendorCodesHotTableRef),
        },
      })
    }
    resetMappingState()
  }

  const createVendorTable = (): JSX.Element => {
    if (loadingVendorsAndDesc || loadingVendorOverrides) {
      return <CenteredCircularProgress />
    }
    if (createNewChargeCodeMode || chargeCodesWithVendor.length > 0 || chargeCodeWithOverrides) {
      if (usesChargeCodeV2) {
        return (
          <VendorOverrideCodesTable
            companyId={companyId}
            chargeCode={chargeCodeWithOverrides}
            hotTableRef={vendorOverridesHotTableRef}
          />
        )
      } else {
        return (
          <VendorCodesTable
            companyId={companyId}
            chargeCodes={
              createNewChargeCodeMode ? placeholderChargeCodeData : chargeCodesWithVendor
            }
            hotTableRef={vendorCodesHotTableRef}
          />
        )
      }
    }
    return <Typography align='center'>Select a charge code to edit details</Typography>
  }

  return (
    <Paper data-testid='charge-code-mapping'>
      <ChargeCodeMappingHeader newChargeCode={() => setNewChargeCodeModalOpen(true)} />
      <Box display='flex'>
        <Box
          width='25%'
          borderRight={`1px solid ${theme.palette.grey['500']}`}
          data-testid='charge-code-search-bar'
        >
          <ChargeCodeSearchBar
            chargeCode={selectedChargeCode}
            refreshSearch={refreshSearch}
            setRefreshSearch={setRefreshSearch}
            companyId={companyId}
            apiPartnerId={currApiPartnerId}
            setChargeCode={setChargeCodeFromSearchResult}
            usesChargeCodeV2={usesChargeCodeV2}
          />
        </Box>
        <Box width='75%' bgcolor={theme.palette.grey[200]}>
          <Box minHeight='80vh'>
            <Box pb={1} data-testid='charge-code-metadata'>
              <ChargeCodeMetadataTable
                currTax={selectedChargeCode?.tax ?? null}
                currTaxId={currTaxId}
                setCurrTaxId={setCurrTaxId}
                currChargeCode={currChargeCodeCode}
                setCurrChargeCode={setCurrChargeCodeCode}
                setRefreshSearch={setRefreshSearch}
                apiPartners={apiPartners}
                currApiPartnerId={currApiPartnerId}
                setCurrApiPartnerId={setCurrApiPartnerId}
                // Only apply to companies using the new charge code schema. New charge codes are by default applied to all vendors.
                // Overrides will be editable from the vendor table.
                currDescription={usesChargeCodeV2 ? currChargeDescription : ''}
                setCurrDescription={usesChargeCodeV2 ? setCurrChargeDescription : undefined}
              />
            </Box>
            {createVendorTable()}
            {createNewChargeCodeMode ? (
              <CreateButtons
                create={createNewChargeVendor}
                cancel={() => setCancelCreateNewChargeCodeModalOpen(true)}
              />
            ) : (
              <ActionButtons
                disabled={!selectedChargeCode}
                save={saveVendorDetails}
                addToMultipleVendors={() => setAddMultipleModalOpen(true)}
                del={() => setDeleteModalOpen(true)}
                companyId={companyId}
                disableAddToMultipleVendors={addToMultipleVendorsDisabled}
              />
            )}
          </Box>
        </Box>
      </Box>
      {deleteModalOpen && !usesChargeCodeV2 && (
        <DeleteChargeCodeModal
          setOpen={setDeleteModalOpen}
          chargeCodes={chargeCodesWithVendor}
          resetMappingState={resetMappingState}
        />
      )}
      {deleteModalOpen && usesChargeCodeV2 && chargeCodeWithOverrides && (
        <DeleteChargeCodeAndOverridesModal
          setOpen={setDeleteModalOpen}
          chargeCode={chargeCodeWithOverrides}
          resetMappingState={resetMappingState}
        />
      )}
      {addMultipleModalOpen && !usesChargeCodeV2 && (
        <AddMultipleModal
          companyId={companyId}
          chargeCode={selectedChargeCode}
          setOpen={setAddMultipleModalOpen}
          chargeCodes={chargeCodesWithVendor}
          addNewVendorChargeCodes={addNewVendorChargeCodes}
          currApiPartnerId={currApiPartnerId}
        />
      )}
      {addMultipleModalOpen && usesChargeCodeV2 && chargeCodeWithOverrides && (
        <AddMultipleVendorOverridesModal
          companyId={companyId}
          chargeCode={chargeCodeWithOverrides}
          setOpen={setAddMultipleModalOpen}
          addNewVendorOverrides={addNewVendorChargeCodes}
        />
      )}
      {newChargeCodeModalOpen && (
        <CreateChargeCodeModal
          setOpen={setNewChargeCodeModalOpen}
          confirmCreate={confirmCreateChargeCode}
        />
      )}
      {cancelCreateNewChargeCodeModalOpen && (
        <CancelCreateChargeCodeModal
          setOpen={setCancelCreateNewChargeCodeModalOpen}
          cancelCreateNewVendor={() => setCreateNewChargeCodeMode(false)}
        />
      )}
    </Paper>
  )
}

export default ChargeCodeMapping
