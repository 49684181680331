import { FunctionComponent, RefObject, useCallback } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { HotTable } from '@handsontable/react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { isPresent } from 'ts-is-present'

import { JobTemplateExportType } from '@src/graphql/types'
import { JobTemplateExportSheet } from '@src/types/job_template_export'
import theme from '@src/utils/theme'
import { createEmptyExportColumn } from '@src/utils/admin/exportFormat'

const exportTypes = Object.freeze({
  [JobTemplateExportType.Standard]: 'Standard',
  [JobTemplateExportType.SplitMetadataLineItem]: 'Custom: split metadata & line items',
  [JobTemplateExportType.JoinMetadataLineItem]: 'Custom: join metadata & line items',
})

const useStyles = makeStyles({
  sheetTabs: {
    display: 'flex',
  },
  exportSection: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(2),
  },
})

type Props = {
  hotTableRef: RefObject<HotTable>
  sheetIndex: number
  setSheetIndex: (idx: number) => void
  cacheSheetDataAndSwitch: (idx: number) => void
  exportType: JobTemplateExportType
  sheets: JobTemplateExportSheet[]
  updateSheets: (sheets: JobTemplateExportSheet[]) => void
  toggleColumnHeader: () => void
  setDefaultSheetsFormat: (type: JobTemplateExportType) => void
  selectNewExportType: (type: JobTemplateExportType) => void
  getSyncedSheetsFromTableData: () => JobTemplateExportSheet[]
}

const JobTemplateExportSection: FunctionComponent<Props> = ({
  hotTableRef,
  sheetIndex,
  setSheetIndex,
  exportType,
  sheets,
  updateSheets,
  toggleColumnHeader,
  setDefaultSheetsFormat,
  cacheSheetDataAndSwitch,
  selectNewExportType,
  getSyncedSheetsFromTableData,
}) => {
  const classes = useStyles()
  const currentSheet = sheets?.[sheetIndex] ?? null
  const rowHeaders = currentSheet?.displayColumnHeader ? ['Name', 'Value'] : ['Value']
  const tableReadOnly = exportType === JobTemplateExportType.Standard

  const handleDragEnd = (result: DropResult): void => {
    const { source, destination } = result
    if (result.destination === null) {
      return
    }
    if (source.index !== destination!.index) {
      const sheetsCopy = [...sheets]
      const sourceSheetCopy = JSON.parse(
        JSON.stringify(sheetsCopy[source.index]),
      ) as JobTemplateExportSheet
      sheetsCopy.splice(source.index, 1)
      sheetsCopy.splice(destination!.index, 0, sourceSheetCopy)
      updateSheets(sheetsCopy)
      setSheetIndex(destination!.index)
    }
  }

  const checkIfSingleColumnSelected = (): boolean => {
    if (hotTableRef.current) {
      const selectedRange = hotTableRef.current.hotInstance.getSelectedLast()
      if (selectedRange) {
        const [, startCol, , endCol] = selectedRange
        const isSingleColumnSelected = startCol === endCol
        return !isSingleColumnSelected
      }
    }
    return true
  }

  const contextMenu = {
    items: {
      add_column: {
        name: 'Add Column',
        disabled: checkIfSingleColumnSelected,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: (_key: any, selection: any) => {
          const { start } = selection[0]
          const sheetsCopy = getSyncedSheetsFromTableData()
          const columnsCopy = [...sheetsCopy[sheetIndex].columns]
          const newColumn = createEmptyExportColumn()
          columnsCopy.splice(start.col, 0, newColumn)
          const newSheet = { ...sheetsCopy[sheetIndex], columns: columnsCopy }
          sheetsCopy.splice(sheetIndex, 1, newSheet)
          updateSheets(sheetsCopy)
        },
      },
      remove_column: {
        name: 'Remove Column',
        disabled: checkIfSingleColumnSelected,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: (_key: any, selection: any) => {
          const { start } = selection[0]
          const sheetsCopy = getSyncedSheetsFromTableData()
          const columnsCopy = [...sheetsCopy[sheetIndex].columns]
          columnsCopy.splice(start.col, 1)
          const newSheet = { ...sheetsCopy[sheetIndex], columns: columnsCopy }
          sheetsCopy.splice(sheetIndex, 1, newSheet)
          updateSheets(sheetsCopy)
        },
      },
      toggle_column_header: {
        name: currentSheet?.displayColumnHeader ? 'Hide Name Row' : 'Show Name Row',
        callback: toggleColumnHeader,
      },
    },
  }

  const getCellMeta = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (row: number | undefined, col: number | undefined): Record<string, any> => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const cellMeta = {} as Record<string, any>
      if (currentSheet && isPresent(row) && isPresent(col)) {
        const valueRowIdx = currentSheet.displayColumnHeader ? 1 : 0
        if (valueRowIdx === row) {
          cellMeta.type = 'dropdown'
          cellMeta.source = currentSheet.tableData?.dropdownValues[col] ?? []
          cellMeta.strict = false
        }
      }
      return cellMeta
    },
    [currentSheet],
  )

  const handleColumnMove = (movedColumns: number[], target: number): boolean => {
    if (movedColumns.length === 1) {
      const [sourceIdx] = movedColumns
      const destinationIdx = sourceIdx < target ? target - 1 : target
      const sheetsCopy = getSyncedSheetsFromTableData()
      const columnsCopy = [...sheetsCopy[sheetIndex].columns]
      const [column] = columnsCopy.splice(sourceIdx, 1)
      columnsCopy.splice(destinationIdx, 0, column)
      const newSheet = { ...sheetsCopy[sheetIndex], columns: columnsCopy }
      sheetsCopy.splice(sheetIndex, 1, newSheet)
      updateSheets(sheetsCopy)
    }
    // we dont mutate handsontable's internal state so we dont mess up our ordering
    return false
  }

  return (
    <Paper className={classes.exportSection}>
      <Typography variant='h2'>Export</Typography>
      <Typography variant='subtitle2' gutterBottom>
        Format for CSV and XLSX exports
      </Typography>
      <Select
        label='Export Type'
        value={exportType}
        onChange={(evt) => {
          selectNewExportType(evt.target.value as JobTemplateExportType)
        }}
      >
        {Object.entries(exportTypes).map(([val, label]) => (
          <MenuItem key={`export-type-${val}`} value={val}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <Box display='flex' justifyContent='space-between' my={2}>
        <Typography variant='h2'>Format</Typography>
        <Button variant='outlined' onClick={() => setDefaultSheetsFormat(exportType)}>
          Default format
        </Button>
      </Box>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='export-sheets' direction='horizontal'>
          {(provided) => (
            // eslint-disable-next-line @typescript-eslint/unbound-method
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <List className={classes.sheetTabs}>
                {sheets.map((sheet, idx) => {
                  return (
                    <Draggable
                      draggableId={`draggable-export-sheet-${sheet.id}`}
                      index={idx}
                      key={sheet.id}
                    >
                      {(draggableProvided) => (
                        // eslint-disable-next-line @typescript-eslint/unbound-method
                        <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                          <ListItem
                            selected={sheetIndex === idx}
                            onClick={() => cacheSheetDataAndSwitch(idx)}
                            {...draggableProvided.dragHandleProps}
                          >
                            {sheet.fieldGroup?.name ?? 'Metadata'}
                          </ListItem>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
              </List>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Box my={2}>
        <HotTable
          id='export-section-hot'
          ref={hotTableRef}
          readOnly={tableReadOnly}
          rowHeaders={rowHeaders}
          columns={currentSheet?.tableData?.nameRow}
          cells={getCellMeta}
          colHeaders
          manualColumnMove={!tableReadOnly}
          beforeColumnMove={handleColumnMove}
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          contextMenu={tableReadOnly ? false : (contextMenu as any)}
          style={{ width: '100%', overflow: 'hidden', height: '20vh' }}
        />
      </Box>
    </Paper>
  )
}

export default JobTemplateExportSection
