import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent } from 'react'
import { DELETE_TASK } from '@src/graphql/mutations/task'
import { useSnackbar } from 'notistack'
import { Mutation, MutationDeleteTaskArgs, TaskNode } from '@src/graphql/types'
import theme from '@src/utils/theme'
import { useMutation } from '@apollo/client'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'

type Props = {
  task: TaskNode
  open: boolean
  setOpen: (open: boolean) => void
  refetchTask: () => void
}

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  deleteButton: {
    marginRight: theme.spacing(1),
  },
})

const DeleteTaskDialog: FunctionComponent<Props> = ({
  task,
  open,
  setOpen,
  refetchTask,
}: Props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [deleteTask, { error: deleteTaskError }] = useMutation<
    Pick<Mutation, 'deleteTask'>,
    MutationDeleteTaskArgs
  >(DELETE_TASK, {
    onError: () => {
      if (deleteTaskError) {
        enqueueSnackbar(`Failed to delete task: ${formatMaybeApolloError(deleteTaskError)}`, {
          variant: 'error',
        })
      }
    },
    onCompleted: () => {
      enqueueSnackbar('Jobs updated', { variant: 'success' })
      refetchTask()
      closeDialog()
    },
  })
  const jobs = task.jobs?.edges?.map((jobEdge) => jobEdge!.node!) || []

  const closeDialog = (): void => {
    setOpen(false)
  }

  const confirmDelete = async (): Promise<void> => {
    await deleteTask({
      variables: { taskId: task.id },
    })
  }

  return (
    <Dialog classes={{ paper: classes.dialog }} open={open} onClose={closeDialog}>
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={closeDialog} data-testid='close-btn' />
      </Box>
      <DialogTitle>Delete Task</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          The following jobs will be deleted. Are you sure you want to delete this task?
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Job Name</TableCell>
                <TableCell>Job Type</TableCell>
                <TableCell>Job Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs.map((job) => (
                <TableRow key={job.id}>
                  <TableCell component='th' scope='row'>
                    {job.name}
                  </TableCell>
                  <TableCell>{job?.jobTemplate?.name ?? ''}</TableCell>
                  <TableCell>{job.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display='flex' alignItems='center' justifyContent='center' p={2}>
          <Button
            className={classes.deleteButton}
            onClick={confirmDelete}
            variant='contained'
            color='primary'
            data-testid='delete-task-dialog-delete-button'
          >
            Delete
          </Button>
          <Button onClick={closeDialog} variant='contained'>
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteTaskDialog
