import { formatMaybeApolloError } from '@src/utils/errors'
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import type { InputFieldGroup, Maybe, Query } from '@src/graphql/types'
import { AutofillExtractorKey, InputFieldGroupType } from '@src/graphql/types'
import { GET_STANDARD_DOCUMENT_TYPES } from '@src/graphql/queries/document'
import DeleteIcon from '@material-ui/icons/Delete'
import { useSnackbar } from 'notistack'
import { UseFieldArrayReturn, useFormContext } from 'react-hook-form'
import { v4 as uuid4 } from 'uuid'
import { Autocomplete, AutocompleteGetTagProps, FilterOptionsState } from '@material-ui/lab'
import Chip from '@material-ui/core/Chip'
import { getBgColorFromHash } from '@src/utils/color'
import TextField from '@material-ui/core/TextField'
import { useDialog } from 'muibox'
import { AutocompleteChangeReason, createFilterOptions } from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { getInputFieldGroups } from '@src/utils/admin/input_field_group'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { Box } from '@material-ui/core'
import { DocumentTypeOption, toDocumentTypeOption } from '@src/utils/admin/document_type_option'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { sortBy } from 'lodash'
import { isPresent } from 'ts-is-present'
import { JobTemplateFormValues } from '@src/components/admin/job-template-editor/JobTemplateEditor'
import DocumentTypeOptionsDialog from './DocumentTypeOptionsDialog'

type Props = {
  companyId: string
  removeMetadataFieldGroups: (index?: number[]) => void
  addMetadataFieldGroups: (inputFieldGroups: InputFieldGroup[]) => void
  removeLineItemTypes: (index?: number[]) => void
  addLineItemTypes: (inputFieldGroups: InputFieldGroup[]) => void
  documentTypesArrayMethods: UseFieldArrayReturn<JobTemplateFormValues, 'documentTypes'>
}
const useStyles = makeStyles({
  chipText: {
    color: theme.palette.common.white,
  },
})
const filter = createFilterOptions<DocumentTypeOption>()
const DocumentTypesSelector: FunctionComponent<Props> = ({
  companyId,
  removeMetadataFieldGroups,
  addMetadataFieldGroups,
  removeLineItemTypes,
  addLineItemTypes,
  documentTypesArrayMethods,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const dialog = useDialog()
  const { getValues } = useFormContext<JobTemplateFormValues>()
  const {
    fields: selectedDocumentTypes,
    append: addSelectedDocumentTypes,
    remove: removeSelectedDocumentTypes,
    update: updateSelectedDocumentTypes,
  } = documentTypesArrayMethods
  const {
    data: documentTypesData,
    loading: documentTypesLoading,
    error: documentTypesError,
  } = useQuery<Pick<Query, 'standardDocumentTypes'>>(GET_STANDARD_DOCUMENT_TYPES, {
    variables: { companyId },
  })
  const [isProcessingChange, setIsProcessingChange] = useState(false)
  const [isDocumentTypeOptionsDialogOpen, setIsDocumentTypeOptionsDialogOpen] = useState(false)
  const [newDocumentTypes, setNewDocumentTypes] = useState([] as (string | DocumentTypeOption)[])
  const [documentTypeToEdit, setDocumentTypeToEdit] = useState(null as Maybe<DocumentTypeOption>)
  const [documentTypeToAdd, setDocumentTypeToAdd] = useState(null as Maybe<DocumentTypeOption>)

  useEffect(() => {
    if (documentTypesError) {
      enqueueSnackbar(
        `Received error while loading document types: ${formatMaybeApolloError(
          documentTypesError,
        )}`,
        {
          variant: 'error',
        },
      )
    }
  }, [documentTypesError, enqueueSnackbar])

  const allDocumentTypeOptions = useMemo(() => {
    const selectedDocumentTypeNames = new Set(
      selectedDocumentTypes.map(({ name }) => name.toLowerCase()),
    )
    return sortBy(
      selectedDocumentTypes.concat(
        documentTypesData?.standardDocumentTypes
          ?.map((type) => toDocumentTypeOption(type))
          .filter(({ name }) => !selectedDocumentTypeNames.has(name.toLowerCase())) || [],
      ),
      'name',
    )
  }, [documentTypesData?.standardDocumentTypes, selectedDocumentTypes])

  const handleDeleteDocumentTypes = useCallback(
    async (documentTypeIndices: number[]): Promise<boolean> => {
      const documentTypesToUnselect = selectedDocumentTypes.filter((_, index) =>
        documentTypeIndices.includes(index),
      )
      const { metadataFieldGroups, lineItemTypes } = getValues()
      const documentTypeIds = new Set(documentTypesToUnselect.map(({ id }) => id))
      const fieldGroupsToDelete = metadataFieldGroups
        .map((fieldGroup, index) => [fieldGroup, index] as [InputFieldGroup, number])
        .concat(
          lineItemTypes.map(
            (fieldGroup, index) => [fieldGroup, index] as [InputFieldGroup, number],
          ),
        )
        .filter(([fieldGroup]) => documentTypeIds.has(fieldGroup.documentTypeId))
      if (fieldGroupsToDelete.length) {
        try {
          await dialog.confirm({
            title: 'Delete Document Type',
            ok: {
              text: 'Delete',
              startIcon: <DeleteIcon />,
            },
            message: (
              <>
                <p>
                  Deleting the doc types{' '}
                  {documentTypesToUnselect.map(({ name }) => name).join(', ')} will delete all
                  delete all fields associated:
                </p>
                <ul>
                  {fieldGroupsToDelete.map(([fieldGroup]) => (
                    <li key={fieldGroup.id}>{fieldGroup.name}</li>
                  ))}
                </ul>
              </>
            ),
          })
          removeMetadataFieldGroups(
            fieldGroupsToDelete
              .filter(([fieldGroup]) => fieldGroup.type !== InputFieldGroupType.LineItem)
              .map(([, index]) => index),
          )
          removeLineItemTypes(
            fieldGroupsToDelete
              .filter(([fieldGroup]) => fieldGroup.type === InputFieldGroupType.LineItem)
              .map(([, index]) => index),
          )
        } catch (e) {
          return false
        }
      }
      removeSelectedDocumentTypes(documentTypeIndices)
      return true
    },
    [
      dialog,
      getValues,
      removeLineItemTypes,
      removeMetadataFieldGroups,
      removeSelectedDocumentTypes,
      selectedDocumentTypes,
    ],
  )

  const handleNewStandardDocumentTypeOptions = useCallback(
    async (
      name: string,
      newDocumentTypeOptions: DocumentTypeOption[],
      isCollapsible: boolean,
      tableShowsPreset: boolean,
      cargowiseFileTypeId: string | undefined | null,
      isEDocPublishedByDefault: boolean,
      autofillExtractorKey: AutofillExtractorKey | null,
    ): Promise<{
      documentTypeOptionsToAdd: DocumentTypeOption[]
      fieldGroupsToAdd: InputFieldGroup[]
    }> => {
      if (!newDocumentTypeOptions.length)
        return { documentTypeOptionsToAdd: [], fieldGroupsToAdd: [] }
      const newDocumentTypeIds = new Set(newDocumentTypeOptions.map(({ id }) => id))
      const newInputFieldGroups = getInputFieldGroups(
        documentTypesData!.standardDocumentTypes.filter(({ id }) => newDocumentTypeIds.has(id)),
      )
      const extractorKeyMap: Record<string, AutofillExtractorKey> = Object.fromEntries(
        documentTypesData!.standardDocumentTypes.map(({ id, autofillExtractorKey }) => [
          id,
          autofillExtractorKey || AutofillExtractorKey.Invoice,
        ]),
      )
      const { metadataFieldGroups } = getValues()
      const existingFieldGroupIds = new Set(metadataFieldGroups.map(({ id }) => id as string))
      const documentTypeIdMap: Record<string, string> = {}
      const documentTypeOptions = newDocumentTypeOptions.map(({ id }) => {
        const newId = uuid4()
        documentTypeIdMap[id] = newId
        return {
          title: name,
          name,
          id: newId,
          isStandard: false,
          collapsible: isCollapsible,
          tableShowsPreset: tableShowsPreset,
          cargowiseFileTypeId: cargowiseFileTypeId,
          isEDocPublishedByDefault,
          derivedFromId: id,
          autofillExtractorKey:
            autofillExtractorKey || extractorKeyMap[id] || AutofillExtractorKey.Invoice,
        }
      })
      const fieldGroupsToAdd = newInputFieldGroups
        .filter((fieldGroup) => !existingFieldGroupIds.has(fieldGroup.id))
        .map((inputFieldGroup) => ({
          ...inputFieldGroup,
          id: uuid4(),
          documentTypeId: documentTypeIdMap[inputFieldGroup.documentTypeId],
          fields: inputFieldGroup.fields.map((inputField) => ({
            ...inputField,
            id: uuid4(),
          })),
        }))
      return {
        fieldGroupsToAdd,
        documentTypeOptionsToAdd: documentTypeOptions,
      }
    },
    [documentTypesData, getValues],
  )

  const handleNewDocumentTypeOptions = useCallback(
    async (
      name: string,
      isStandard: boolean,
      isCollapsible: boolean,
      tableShowsPreset: boolean,
      cargowiseFileTypeId: string | undefined | null,
      isEDocPublishedByDefault: boolean,
      autofillExtractorKey: AutofillExtractorKey | null,
      newDocumentTypeOverride?: Maybe<(string | DocumentTypeOption)[]>,
    ): Promise<void> => {
      const newDocumentTypesFinal = newDocumentTypeOverride ?? newDocumentTypes
      const oldNames = new Set(
        selectedDocumentTypes.map((item: DocumentTypeOption) => item.name.toLowerCase()),
      )
      const newDocumentTypeOptions = newDocumentTypesFinal.filter(
        (item) => typeof item !== 'string' && !oldNames.has(item.name.toLowerCase()),
      ) as DocumentTypeOption[]
      if (newDocumentTypeOverride) {
        const { fieldGroupsToAdd, documentTypeOptionsToAdd } =
          await handleNewStandardDocumentTypeOptions(
            name,
            newDocumentTypeOptions,
            isCollapsible,
            tableShowsPreset,
            cargowiseFileTypeId,
            isEDocPublishedByDefault,
            autofillExtractorKey,
          )
        addSelectedDocumentTypes(documentTypeOptionsToAdd)
        if (fieldGroupsToAdd) {
          addMetadataFieldGroups(
            fieldGroupsToAdd.filter(
              (fieldGroup) => fieldGroup.type !== InputFieldGroupType.LineItem,
            ),
          )
          addLineItemTypes(
            fieldGroupsToAdd.filter(
              (fieldGroup) => fieldGroup.type === InputFieldGroupType.LineItem,
            ),
          )
        }
      } else {
        const newNonStandardDocumentTypeOptions = newDocumentTypeOptions.map(
          ({ id }) =>
            ({
              id,
              name,
              title: name,
              isStandard: isStandard,
              collapsible: isCollapsible,
              tableShowsPreset: tableShowsPreset,
              cargowiseFileTypeId: cargowiseFileTypeId,
              isEDocPublishedByDefault,
              derivedFromId: null,
              autofillExtractorKey: autofillExtractorKey || AutofillExtractorKey.Invoice,
            }) as DocumentTypeOption,
        )
        addSelectedDocumentTypes(newNonStandardDocumentTypeOptions)
      }
    },
    [
      newDocumentTypes,
      selectedDocumentTypes,
      handleNewStandardDocumentTypeOptions,
      addSelectedDocumentTypes,
      addMetadataFieldGroups,
      addLineItemTypes,
    ],
  )

  const onAutocompleteChange = useCallback(
    async (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      _: ChangeEvent<any>,
      newValue: (string | DocumentTypeOption)[],
      reason: AutocompleteChangeReason,
    ): Promise<void> => {
      setIsProcessingChange(true)
      try {
        if (reason === 'create-option' || reason === 'select-option') {
          const oldNames = new Set(
            selectedDocumentTypes.map((item: DocumentTypeOption) => item.name.toLowerCase()),
          )
          const newDocumentTypeOption = newValue.find(
            (item) => typeof item !== 'string' && !oldNames.has(item.name.toLowerCase()),
          ) as DocumentTypeOption
          if (!newDocumentTypeOption) return
          // When adding a new document type, handle standard and non-standard
          // document types separately.
          if (!newDocumentTypeOption.isStandard) {
            setNewDocumentTypes(newValue)
            setDocumentTypeToAdd(newDocumentTypeOption)
            setIsDocumentTypeOptionsDialogOpen(true)
          } else {
            setNewDocumentTypes(newValue)
            void handleNewDocumentTypeOptions(
              newDocumentTypeOption.name,
              newDocumentTypeOption.isStandard,
              newDocumentTypeOption.collapsible,
              newDocumentTypeOption.tableShowsPreset,
              newDocumentTypeOption.cargowiseFileTypeId,
              newDocumentTypeOption.isEDocPublishedByDefault,
              newDocumentTypeOption.autofillExtractorKey,
              newValue,
            )
          }
        } else if (reason === 'remove-option' || reason === 'clear') {
          const newNames = new Set(
            newValue
              .map((item) => (typeof item === 'string' ? null : item.name.toLowerCase()))
              .filter(isPresent),
          )
          const deletedDocumentTypes = selectedDocumentTypes.filter(
            (item) => !newNames.has(item.name.toLowerCase()),
          )
          const deletedDocumentTypeIndices = deletedDocumentTypes.map((docType) => {
            return selectedDocumentTypes.findIndex((item) => item.id === docType.id)
          })
          await handleDeleteDocumentTypes(deletedDocumentTypeIndices)
        }
      } finally {
        setIsProcessingChange(false)
      }
    },
    [handleDeleteDocumentTypes, handleNewDocumentTypeOptions, selectedDocumentTypes],
  )

  const handleUpdateDocumentTypeOption = useCallback(
    async (
      name: string,
      isStandard: boolean,
      isCollapsible: boolean,
      tableShowsPreset: boolean,
      cargowiseFileTypeId: Maybe<string> | undefined,
      isEDocPublishedByDefault: boolean,
      autofillExtractorKey: AutofillExtractorKey | null,
    ): Promise<void> => {
      if (!documentTypeToEdit) return
      const documentTypeToEditIndex = selectedDocumentTypes.findIndex(
        (item) => item.id === documentTypeToEdit.id,
      )
      const updatedDocumentType = {
        ...documentTypeToEdit,
        name,
        title: name,
        isStandard,
        collapsible: isCollapsible,
        tableShowsPreset,
        cargowiseFileTypeId,
        isEDocPublishedByDefault,
        autofillExtractorKey: autofillExtractorKey || AutofillExtractorKey.Invoice,
      } as DocumentTypeOption
      updateSelectedDocumentTypes(documentTypeToEditIndex, updatedDocumentType)
    },
    [documentTypeToEdit, selectedDocumentTypes, updateSelectedDocumentTypes],
  )

  const renderTags = useCallback(
    (tagValue: DocumentTypeOption[], getTagProps: AutocompleteGetTagProps): React.ReactNode =>
      tagValue.map((option, index) => (
        // key is part of getTagProps
        // eslint-disable-next-line react/jsx-key
        <Chip
          style={{ backgroundColor: getBgColorFromHash(option.title) }}
          classes={{ label: classes.chipText, deleteIcon: classes.chipText }}
          label={option.title}
          {...getTagProps({ index })}
          onClick={() => {
            setDocumentTypeToEdit(option)
            setIsDocumentTypeOptionsDialogOpen(true)
          }}
        />
      )),
    [classes.chipText],
  )
  const filterOptions = useCallback(
    (
      options: DocumentTypeOption[],
      params: FilterOptionsState<DocumentTypeOption>,
    ): DocumentTypeOption[] => {
      const filtered = filter(options, params)

      // Suggest the creation of a new value
      if (params.inputValue !== '') {
        filtered.push({
          id: uuid4(),
          name: params.inputValue,
          title: `Add "${params.inputValue}"`,
          isStandard: false,
          collapsible: false,
          tableShowsPreset: false,
          cargowiseFileTypeId: null,
          isEDocPublishedByDefault: false,
          derivedFromId: null,
          autofillExtractorKey: AutofillExtractorKey.Invoice,
        })
      }

      return filtered
    },
    [],
  )
  const getOptionLabel = useCallback((option: DocumentTypeOption) => option.title, [])
  const getOptionSelected = useCallback((option, val) => option.id === val.id, [])
  const renderInput = useCallback((params) => <TextField {...params} variant='outlined' />, [])
  return (
    <>
      <Box position='relative'>
        {(documentTypesLoading || isProcessingChange) && (
          <Box
            position='absolute'
            // have to offset the white bg a bit to cover the blue border
            // from focus on the autocomplete
            top='-1px'
            left='-1px'
            bottom='-1px'
            right='-1px'
            bgcolor={alpha(theme.palette.background.paper, 0.7)}
            zIndex={theme.zIndex.modal - 1}
          >
            <CenteredCircularProgress />
          </Box>
        )}
        <Autocomplete
          multiple
          size='small'
          value={selectedDocumentTypes}
          onChange={onAutocompleteChange}
          options={allDocumentTypeOptions}
          filterOptions={filterOptions}
          renderTags={renderTags}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          freeSolo
          renderInput={renderInput}
          data-testid='doc-types-selector'
        />
      </Box>
      <DocumentTypeOptionsDialog
        companyId={companyId}
        isOpen={isDocumentTypeOptionsDialogOpen}
        close={() => {
          setIsDocumentTypeOptionsDialogOpen(false)
        }}
        documentTypes={selectedDocumentTypes}
        handleNewDocumentTypeOptions={handleNewDocumentTypeOptions}
        documentTypeToEdit={documentTypeToEdit}
        documentTypeToAdd={documentTypeToAdd}
        setDocumentTypeToEdit={setDocumentTypeToEdit}
        setDocumentTypeToAdd={setDocumentTypeToAdd}
        handleUpdateDocumentTypeOption={handleUpdateDocumentTypeOption}
      />
    </>
  )
}
export default DocumentTypesSelector
