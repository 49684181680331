import { FunctionComponent, useEffect, useRef, useState } from 'react'
import Handsontable from 'handsontable'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[][]
  settings?: Handsontable.DefaultSettings
  style?: React.CSSProperties
  className?: string
}

/**
 * Handsontable React wrapper optimized for read-only use (expects data and settings
 * to change infrequently). Be careful that the settings object has reference equality
 * (ie, memoize it or set it as a top-level object outside of the function component).
 */
const ReadOnlyHotTable: FunctionComponent<Props> = ({ data, settings, style, className }) => {
  const hotInstance = useRef<Handsontable>()
  const hotElement = useRef<HTMLDivElement | null>(null)
  const [hotTableId] = useState(() => Math.random().toString(36))
  useEffect(() => {
    if (data && hotTableId) {
      hotInstance.current = new Handsontable(hotElement.current as HTMLDivElement, {
        data,
        ...settings,
      })
    }
    return () => {
      hotInstance.current?.destroy()
    }
  }, [hotTableId, data, settings])
  return <div style={style} className={className} id={`hot-${hotTableId}`} ref={hotElement}></div>
}

export default ReadOnlyHotTable
