import { useAuth0 } from '@auth0/auth0-react'
import { GrowthBook } from '@growthbook/growthbook'
import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { Box } from '@material-ui/core'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { DeployEnvironment, getDeployEnvironment } from '@src/utils/environment'
import { ReactNode, useEffect } from 'react'

const clientKeyMap = {
  [DeployEnvironment.DEVELOPMENT]: 'sdk-I7TAn1jQiQ7mYLQ',
  [DeployEnvironment.ENGINEERING]: 'sdk-zUKgmXSgJzdunBgV',
  [DeployEnvironment.STAGING]: 'sdk-V3t2PGnOnnlMHN9y',
  [DeployEnvironment.PRODUCTION]: 'sdk-9tuXJhj2KP54t4v',
}
const currentEnv = getDeployEnvironment()
const envClientKey = clientKeyMap[currentEnv]

const gb = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: envClientKey,
  enableDevMode: currentEnv == DeployEnvironment.DEVELOPMENT,
})
void gb.init({
  // Optional, enable streaming updates
  streaming: true,
})

/**
 * This provides usage of growthbook hooks like useFeatureIsOn and useFeatureValue
 * https://docs.growthbook.io/lib/react#feature-hooks
 *
 * You may also use growthbook react components like IfFeatureEnabled to easily hide/show features based on the feature key.
 * https://docs.growthbook.io/lib/react#feature-wrapper-components
 *
 * Note: We made our own wrapper instead of using OpenFeature (https://docs.growthbook.io/lib/react#openfeature-provider)
 * because vite had problems with compiling the growthbook's openfeature client.
 */
export function FeatureFlagsProvider(props: { children: ReactNode }): JSX.Element {
  const { user } = useAuth0()

  useEffect(() => {
    if (user) {
      void gb.setAttributes({
        email: user?.email,
      })
    }
  }, [user])

  return (
    <GrowthBookProvider growthbook={gb}>
      {gb.ready ? (
        <>{props.children}</>
      ) : (
        <Box height='100vh' width='100vw'>
          <CenteredCircularProgress />
        </Box>
      )}
    </GrowthBookProvider>
  )
}
