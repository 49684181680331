import { formatMaybeApolloError } from '@src/utils/errors'
import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { JobDataContext } from '@src/contexts/job_data_context'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { useSelector } from 'react-redux'
import { RootState } from '@src/utils/store'
import { selectActiveDocument } from '@src/redux-features/document_editor/document'
import { jobTableLineItemSelectors } from '@src/redux-features/document_editor/job_table'
import {
  addMatchedShipments,
  addTaxToLineItems,
  getLinesForSoaSend,
  transformLineItems,
  SENDING_SOA_LINE_COLUMN_KEYS,
  SendSoaTableLineItem,
  SoaLineItem,
} from '@src/utils/line_items'

import { BATCH_EXPORT_JOB_TO_CARGOWISE } from '@src/graphql/mutations/cargowise'
import {
  Maybe,
  Mutation,
  MutationBatchExportJobToCwArgs,
  Query,
  QueryFindSoaShipmentsReconResultsArgs,
  QueryTransactionAsyncBatchArgs,
  ApReconAutofillKey,
  TransactionAsyncStatus,
  TransactionAsyncTaskNodeEdge,
  CwTargetModule,
  JobTemplateReconType,
  QueryTaxIdsByApiPartnerArgs,
} from '@src/graphql/types'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import SOAInvoiceGroupDialog from '@src/components/SOAInvoiceGroupDialog'
import { TAX_IDS_BY_API_PARTNER } from '@src/graphql/queries/recon'
import { GET_TRANSACTION_ASYNC_BATCH } from '@src/graphql/queries/cargowise'
import { FIND_SOA_SHIPMENTS_RECON_RESULTS_FROM_JOB } from '@src/graphql/queries/recon'
import { validateBeforeSOASend } from '@src/utils/errors'
import { CARGOWISE_MODULE, SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING } from '@src/utils/app_constants'
import useValidateChargeCodes from '@src/hooks/charge_codes/use_validate_charge_codes'
import useTMSJobData from '@src/hooks/useTMSJobData'

const useStyles = makeStyles({
  dialogFullScreen: {
    maxWidth: '90%',
    width: '90%',
    height: '90%',
  },
  table: {
    height: '80%',
    overflowY: 'scroll',
    marginBottom: theme.spacing(2),
  },
  row: {
    cursor: 'pointer',
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  secondaryKeys: {
    borderBottom: `1px dashed ${theme.palette.grey[600]}`,
    '&:last-child': {
      borderBottom: 'none',
    },
    whiteSpace: 'pre-wrap',
  },
  closeDialog: {
    maxWidth: '100%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningText: {
    backgroundColor: theme.palette.warning.light,
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
})

export const SENDING_MODAL_COLUMN_KEYS = [
  SENDING_SOA_LINE_COLUMN_KEYS.INVOICE_NO,
  SENDING_SOA_LINE_COLUMN_KEYS.CARGOWISE_MODULE,
  SENDING_SOA_LINE_COLUMN_KEYS.SHIPMENT_NO,
  SENDING_SOA_LINE_COLUMN_KEYS.SECOND_KEYS,
  SENDING_SOA_LINE_COLUMN_KEYS.STATUS,
  SENDING_SOA_LINE_COLUMN_KEYS.NOTES,
  SENDING_SOA_LINE_COLUMN_KEYS.SELECTED,
]

// Statuses for line item sending progress to ensure consist
const SENDING_STATUS = {
  PENDING: 'Pending',
  SENDING: 'Sending',
  SENT: 'Sent',
}

//empirically takes ~1sec per task, but set under to be safe
const TRANSACTION_ASYNC_BATCH_POLL_INTERVAL = 700

type Props = {
  isOpen: boolean
  close: () => void
  jobId: string
}

const SendSOAToCWDialog: FunctionComponent<Props> = ({ isOpen, close, jobId }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const reconType = JobTemplateReconType.Soa
  const { job, jobDataLoading, documentFieldGroups, jobTable } = useTMSJobData(jobId, reconType)

  const { isValidatingChargeCodes, invalidDocChargeCodes, noJobChargeVendor, noJobChargeCodes } =
    useValidateChargeCodes(
      job?.jobTemplate ?? null,
      documentFieldGroups,
      [],
      jobTable,
      reconType,
      jobDataLoading,
    )

  if (!isValidatingChargeCodes && invalidDocChargeCodes.length > 0) {
    close()
  } else if (!isValidatingChargeCodes && noJobChargeVendor) {
    enqueueSnackbar('No charge vendor was found. Please enter a vendor.', { variant: 'error' })
    close()
  } else if (!isValidatingChargeCodes && noJobChargeCodes) {
    enqueueSnackbar('No charge codes found. Please enter charge codes into table.', {
      variant: 'error',
    })
    close()
  }
  const activeDocument = useSelector(
    (state: RootState) => selectActiveDocument(state.documentEditor)!,
  )
  const lineItems = useSelector(
    (state: RootState) => jobTableLineItemSelectors.selectAll(state.documentEditor)!,
  )
  const vendor =
    activeDocument.documentFieldGroups?.edges
      .filter((docFieldGroup) => !docFieldGroup?.node?.fieldGroup?.repeatable)
      .map((docFieldGroup) => docFieldGroup?.node?.documentFields?.edges[0]?.node)
      .find(
        (docField) =>
          ApReconAutofillKey.Vendor.toLowerCase() === docField?.field?.autofillKey.toLowerCase(),
      )?.value ?? ''
  const [isSendingSOAToCW, setIsSendingSOAToCW] = useState(false)
  const [chosenInvoiceGroup, setChosenInvoiceGroup] = useState('')
  const [soaLineItems, setSoaLineItems] = useState(getLinesForSoaSend(lineItems, activeDocument))
  const [numberSending, setNumberSending] = useState(0)
  const [totalSuccesses, setTotalSuccesses] = useState(0)
  const [transactionAsyncBatchId, setTransactionAsyncBatchId] = useState('')
  const [tasksOutOfQueue, setTasksOutOfQueue] = useState(0)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [shipmentsPerInvoice, setShipmentsPerInvoice] = useState({} as Record<string, string[]>)
  const timerRef: MutableRefObject<ReturnType<typeof setInterval> | null> = useRef(null)
  // need to convert soalineitems to input format first, to query for matching shipments
  const inputLineItems = transformLineItems(lineItems, activeDocument)

  // query and add matching shipment number, recon'd from a line's secondary keys
  const { data: findSoaShipmentsReconResultData, loading: findSoaShipmentsReconResultLoading } =
    useQuery<Pick<Query, 'findSoaShipmentsReconResults'>, QueryFindSoaShipmentsReconResultsArgs>(
      FIND_SOA_SHIPMENTS_RECON_RESULTS_FROM_JOB,
      {
        variables: { jobId, inputSoaLineItems: inputLineItems },
        fetchPolicy: 'network-only',
        onCompleted: () => {
          const soaLineItemsWithMatchedShipments = addMatchedShipments(
            soaLineItems,
            findSoaShipmentsReconResultData!.findSoaShipmentsReconResults!,
          )
          setSoaLineItems(soaLineItemsWithMatchedShipments)
          setShipmentsPerInvoice(getShipmentsPerInvoice(soaLineItemsWithMatchedShipments))
        },
      },
    )

  const startTimer = (): void => {
    timerRef.current = setInterval(() => {
      setElapsedTime((elapsedTime) => elapsedTime + 0.01)
    }, 10)
  }

  const { jobData } = useContext(JobDataContext)

  useQuery<Pick<Query, 'taxIdsByApiPartner'>, QueryTaxIdsByApiPartnerArgs>(TAX_IDS_BY_API_PARTNER, {
    skip: !jobData?.job?.jobTemplate?.apiPartner?.id,
    variables: {
      apiPartnerId: jobData?.job?.jobTemplate?.apiPartner?.id as string,
    },
    onCompleted: (taxIdsData) => {
      setSoaLineItems(addTaxToLineItems(taxIdsData.taxIdsByApiPartner, soaLineItems))
    },
  })

  const [overrideChargeDescriptionFlag, setOverrideChargeDescriptionFlag] = useState(false)
  const [disableSendDueDateFlag, setDisableSendDueDateFlag] = useState(false)
  const [sendAsLumpsumFlag, setSendAsLumpsumFlag] = useState(false)

  const [batchExportJobToCw, { loading: batchExporting }] = useMutation<
    Pick<Mutation, 'batchExportJobToCw'>,
    MutationBatchExportJobToCwArgs
  >(BATCH_EXPORT_JOB_TO_CARGOWISE, {
    onError: (error) => {
      enqueueSnackbar(`SOA Batch Send Error: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
      setSoaLineItems(
        soaLineItems.map((invoice) => {
          const updatedInvoice = invoice
          Object.values(updatedInvoice)
            .flat()
            .forEach((line) => {
              if (line[SENDING_SOA_LINE_COLUMN_KEYS.STATUS] === SENDING_STATUS.SENDING) {
                const nowTime = new Date()
                line[SENDING_SOA_LINE_COLUMN_KEYS.STATUS] = SENDING_STATUS.PENDING
                line[SENDING_SOA_LINE_COLUMN_KEYS.SELECTED] = false
                ;(line![SENDING_SOA_LINE_COLUMN_KEYS.NOTES]! as string[][]).push([
                  nowTime.toUTCString(),
                  `Error: SOA Batch Send - ${formatMaybeApolloError(error)}`,
                ])
              }
            })
          return updatedInvoice
        }),
      )
      setTasksOutOfQueue(0)
      stopTransactionAsyncBatchPolling()
      setNumberSending(0)
      setTotalSuccesses(0)
      setIsSendingSOAToCW(false)
    },
  })
  const {
    data: transactionAsyncBatchData,
    startPolling: startTransactionAsyncBatchPolling,
    stopPolling: stopTransactionAsyncBatchPolling,
  } = useQuery<Pick<Query, 'transactionAsyncBatch'>, QueryTransactionAsyncBatchArgs>(
    GET_TRANSACTION_ASYNC_BATCH,
    {
      skip: !transactionAsyncBatchId,
      variables: {
        transactionAsyncBatchId: transactionAsyncBatchId!,
      },
      fetchPolicy: 'network-only',
    },
  )

  // poll until all tasks in batch have gone through
  useEffect(() => {
    if (tasksOutOfQueue === numberSending && !batchExporting) {
      setElapsedTime(0)
      setTasksOutOfQueue(0)
      stopTransactionAsyncBatchPolling()
      setNumberSending(0)
      setTotalSuccesses(0)
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
      setIsSendingSOAToCW(false)
    } else if (isSendingSOAToCW && transactionAsyncBatchId) {
      startTransactionAsyncBatchPolling(TRANSACTION_ASYNC_BATCH_POLL_INTERVAL)
    }
  }, [
    transactionAsyncBatchData,
    startTransactionAsyncBatchPolling,
    stopTransactionAsyncBatchPolling,
    isSendingSOAToCW,
    numberSending,
    transactionAsyncBatchId,
    tasksOutOfQueue,
    batchExporting,
  ])

  const updateSoaLineItems = (
    task: Maybe<TransactionAsyncTaskNodeEdge>,
    line: SoaLineItem,
  ): void => {
    // if error reset to pending and add error log, if done set to sent and add processing log
    if (
      task?.node!.invoiceNumber === line[SENDING_SOA_LINE_COLUMN_KEYS.INVOICE_NO] &&
      task?.node!.cargowiseModule === line[SENDING_SOA_LINE_COLUMN_KEYS.CARGOWISE_MODULE]
    ) {
      const nowTime = new Date()
      const numNotes = (line[SENDING_SOA_LINE_COLUMN_KEYS.NOTES]! as string[][]).flat().length
      if (task?.node!.status === TransactionAsyncStatus.Error) {
        // conditional for error and has new error message
        const isError =
          task?.node!.errorMessage &&
          line[SENDING_SOA_LINE_COLUMN_KEYS.NOTES] &&
          !(line[SENDING_SOA_LINE_COLUMN_KEYS.NOTES]! as string[][])
            .flat()
            [numNotes - 1]?.includes(task?.node!.errorMessage)
        if (isError) {
          line[SENDING_SOA_LINE_COLUMN_KEYS.SELECTED] = false
          line[SENDING_SOA_LINE_COLUMN_KEYS.STATUS] = SENDING_STATUS.PENDING
          ;(line![SENDING_SOA_LINE_COLUMN_KEYS.NOTES]! as string[][]).push([
            nowTime.toUTCString(),
            `Error: ${task!.node!.errorMessage!}`,
          ])
        }
      } else if (task?.node!.status === TransactionAsyncStatus.Done) {
        const successMessage = `Successfully sent invoice ${
          line[SENDING_SOA_LINE_COLUMN_KEYS.INVOICE_NO]
        }`
        // conditional for done and has new processing log
        const isDoneProcessingLog =
          task?.node!.processingLog &&
          !(line[SENDING_SOA_LINE_COLUMN_KEYS.NOTES]! as string[][])
            .flat()
            [numNotes - 1].includes(successMessage)
        if (isDoneProcessingLog) {
          setTotalSuccesses((successes) => successes + 1)
          line[SENDING_SOA_LINE_COLUMN_KEYS.STATUS] = SENDING_STATUS.SENT
          ;(line![SENDING_SOA_LINE_COLUMN_KEYS.NOTES]! as string[][]).push([
            nowTime.toUTCString(),
            task!.node!.processingLog!,
          ])
          ;(line![SENDING_SOA_LINE_COLUMN_KEYS.NOTES]! as string[][]).push([
            nowTime.toUTCString(),
            successMessage,
          ])
        }
      }
    }
  }

  // update soaLineItems as transaction async tasks finish in queue
  useEffect(() => {
    setSoaLineItems((prevSoaLineItems) => {
      return prevSoaLineItems.map((invoice) => {
        const updatedInvoice = invoice
        Object.values(updatedInvoice)
          .flat()
          .forEach((line) => {
            transactionAsyncBatchData?.transactionAsyncBatch!.transactionAsyncTasks!.edges!.forEach(
              (task) => {
                return updateSoaLineItems(task, line)
              },
            )
          })
        return updatedInvoice
      })
    })
    setTasksOutOfQueue(
      transactionAsyncBatchData?.transactionAsyncBatch?.transactionAsyncTasks?.edges!
        .length as number,
    )
  }, [transactionAsyncBatchData])

  const handleCheckbox = (invoiceNo: string): void => {
    const chosenInvoice = soaLineItems.find((invoice) => Object.keys(invoice)[0] === invoiceNo)
    if (chosenInvoice!) {
      Object.values(chosenInvoice)
        .flat()
        .map((invoice) => {
          const flipSelected = !invoice[SENDING_SOA_LINE_COLUMN_KEYS.SELECTED]
          invoice[SENDING_SOA_LINE_COLUMN_KEYS.SELECTED] = flipSelected
          return invoice
        })
      setSoaLineItems(
        soaLineItems.map((invoice) =>
          invoiceNo === Object.keys(invoice)[0] ? chosenInvoice : invoice,
        ),
      )
    }
  }

  const markSelectedInvoicesSending = (): void => {
    setSoaLineItems(
      soaLineItems.map((invoice) => {
        const updatedInvoice = invoice
        Object.values(updatedInvoice)
          .flat()
          .forEach((line) => {
            const selected = line[SENDING_SOA_LINE_COLUMN_KEYS.SELECTED]
            if (selected) {
              const nowTime = new Date()
              line[SENDING_SOA_LINE_COLUMN_KEYS.STATUS] = SENDING_STATUS.SENDING
              line[SENDING_SOA_LINE_COLUMN_KEYS.SELECTED] = false
              ;(line![SENDING_SOA_LINE_COLUMN_KEYS.NOTES]! as string[][]).push([
                nowTime.toUTCString(),
                'Sending invoice to CW...',
              ])
            }
          })
        return updatedInvoice
      }),
    )
  }

  // return a set of shipments per invoice to avoid displaying duplicates
  const getShipmentsPerInvoice = (
    soaLineItemsWithMatchedShipments: Record<string, SendSoaTableLineItem[]>[],
  ): Record<string, string[]> => {
    const shipments = {} as Record<string, string[]>
    soaLineItemsWithMatchedShipments.forEach((invoice) => {
      const invoiceNum = Object.keys(invoice)[0] as string
      const invoiceShipments = [
        ...new Set(
          Object.values(invoice)
            .flat()
            .map((line) => {
              const lineShipmentNumber = line[SENDING_SOA_LINE_COLUMN_KEYS.SHIPMENT_NO]
              if (lineShipmentNumber) {
                return lineShipmentNumber
              }
              return 0
            })
            .filter((shipment) => shipment),
        ),
      ] as string[]
      shipments[invoiceNum] = invoiceShipments
    })
    return shipments
  }

  // return a set of SecondaryKeys per invoice to avoid displaying duplicates
  const getSecondaryKeysPerInvoice = (): Record<string, string[]> => {
    const secondaryKeys = {} as Record<string, string[]>
    soaLineItems.forEach((invoice) => {
      const invoiceNum = Object.keys(invoice)[0] as string
      const invoiceSecondaryKeys = [
        ...new Set(
          Object.values(invoice)
            .flat()
            .map((line) => {
              const lineSecondaryKeys = line[SENDING_SOA_LINE_COLUMN_KEYS.SECOND_KEYS]
              if (lineSecondaryKeys) {
                return (lineSecondaryKeys as string[]).join('\n')
              }
              return 0
            })
            .filter((keys) => keys),
        ),
      ] as string[]
      secondaryKeys[invoiceNum] = invoiceSecondaryKeys
    })
    return secondaryKeys
  }
  const [secondaryKeysPerInvoice, _] = useState(getSecondaryKeysPerInvoice())

  // filter by sending, dont send duplicate invoice numbers for each cw module
  const getInvoicesToSend = (invoiceCwModuleKeys: Record<string, string>[]): string[] => {
    const invoicesToSend = invoiceCwModuleKeys.map((invoiceCwModuleKey) => {
      return invoiceCwModuleKey.invoiceNum
    })
    return invoicesToSend
  }

  const getInvoiceDates = (): Record<string, string>[] => {
    const selectedLineItems = soaLineItems.filter(
      (invoice) =>
        Object.values(invoice).flat()[0][SENDING_SOA_LINE_COLUMN_KEYS.STATUS] ===
        SENDING_STATUS.SENDING,
    )
    const invoiceDates = selectedLineItems.map((invoice) => {
      const invoiceNum = Object.values(invoice).flat()[0]['Invoice Number'] as string
      const invoiceDate = Object.values(invoice).flat()[0]['Invoice Date'] as string
      const dueDate = Object.values(invoice).flat()[0]['Due Date'] as string
      const cwModule = Object.values(invoice).flat()[0]['Cargowise Module'] as string
      const documentReceivedDate = Object.values(invoice).flat()[0][
        'Document Received Date'
      ] as string
      return { invoiceNum, invoiceDate, dueDate, cwModule, documentReceivedDate }
    })
    return invoiceDates
  }

  const getInvoiceCwModuleKeys = (): Record<string, string>[] => {
    const selectedLineItems = soaLineItems.filter(
      (invoice) =>
        Object.values(invoice).flat()[0][SENDING_SOA_LINE_COLUMN_KEYS.STATUS] ===
        SENDING_STATUS.SENDING,
    )
    const invoiceCwModuleKeys = selectedLineItems.map((invoice) => {
      const invoiceNum = Object.values(invoice).flat()[0]['Invoice Number'] as string
      const cwModule = Object.values(invoice).flat()[0]['Cargowise Module'] as string
      return { invoiceNum, cwModule }
    })
    return invoiceCwModuleKeys
  }

  const sendSoaToCW = async (): Promise<void> => {
    const error = validateBeforeSOASend(soaLineItems)
    if (error) {
      enqueueSnackbar(error, { variant: 'error' })
      return
    }
    setIsSendingSOAToCW(true)
    setElapsedTime(0)
    startTimer()
    markSelectedInvoicesSending()
    const invoiceDates = getInvoiceDates()
    const invoiceCwModuleKeys = getInvoiceCwModuleKeys()
    const invoiceNumbers = getInvoicesToSend(invoiceCwModuleKeys)
    setNumberSending(invoiceNumbers.length)
    const resp = await batchExportJobToCw({
      variables: {
        jobId,
        invoiceNumbers,
        invoiceDates,
        invoiceCwModuleKeys,
        overrideChargeDescription: overrideChargeDescriptionFlag,
        disableSendDueDate: disableSendDueDateFlag,
        asLumpsum: sendAsLumpsumFlag,
      },
    })
    setTransactionAsyncBatchId(String(resp.data?.batchExportJobToCw!.transactionAsyncBatchId))
  }

  // temporary modal, might want to save data in state in future
  const [sureClose, setSureClose] = useState(false)
  const closeSure = (): void => {
    stopTransactionAsyncBatchPolling()
    setSureClose(false)
    close()
  }

  const getGroupedSoaLineItems = useCallback(
    (invoiceGroup: string, lumpsumFlag: boolean): SoaLineItem[] => {
      const groupedSoaLineItems = Object.values(
        soaLineItems.find((invoice) => Object.keys(invoice)[0] === invoiceGroup)!,
      ).flat()

      if (lumpsumFlag) {
        const lumpsumLineItem = {} as Record<
          string,
          string | string[] | string[][] | boolean | undefined
        >
        groupedSoaLineItems.forEach((lineItem) => {
          Object.entries(lineItem).forEach(([col, value]) => {
            const lumpsumColumnValue = lumpsumLineItem[col]
            if (!lumpsumColumnValue) {
              lumpsumLineItem[col] = value
            } else if (col === SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.charge_cost) {
              const lumpsumCost = parseFloat(lumpsumColumnValue as string)
              const currentLineItemCost = parseFloat(value as string)
              const newLumpsumCost = lumpsumCost + currentLineItemCost
              lumpsumLineItem[col] = newLumpsumCost.toString()
            }
          })
        })
        return [lumpsumLineItem]
      }
      return groupedSoaLineItems
    },
    [soaLineItems],
  )

  const isAnyLineItemForwardingConsol = useMemo(() => {
    const invoiceLines = soaLineItems.flatMap((invoice) => Object.values(invoice).flat())
    return invoiceLines.some((invoiceLineItem) => {
      const cwModule = invoiceLineItem[SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.cargowise_module] ?? null
      return (
        CwTargetModule[cwModule as keyof typeof CwTargetModule] === CwTargetModule.ForwardingConsol
      )
    })
  }, [soaLineItems])

  return (
    <>
      <Dialog open={isOpen} classes={{ paper: classes.dialogFullScreen }}>
        <DialogTitle disableTypography>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography variant='h3'>
              {!isSendingSOAToCW
                ? `Review data to send to CW`
                : `Successfully sent ${totalSuccesses} out of ${numberSending} invoices in batch to CW`}
            </Typography>
            <IconButton
              onClick={() => setSureClose(true)}
              aria-label='close'
              disabled={isSendingSOAToCW}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {isAnyLineItemForwardingConsol && (
            <Box my={2}>
              <Typography className={classes.warningText}>
                Line items marked as <strong>ForwardingConsol</strong> will NOT be sent. Please
                check to make sure everything is correct before sending to Cargowise.
              </Typography>
            </Box>
          )}
          <TableContainer component={Paper} className={classes.table}>
            <Table aria-label='collapsible table'>
              <TableHead>
                <TableRow>
                  {SENDING_MODAL_COLUMN_KEYS.map((key) => {
                    return <TableCell key={`sending-modal-column-${key}`}>{key}</TableCell>
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {findSoaShipmentsReconResultLoading ? (
                  <CenteredCircularProgress data-testid='loading' />
                ) : (
                  soaLineItems.map((invoice) => {
                    const invoiceNo = Object.keys(invoice)[0]
                    const invoiceLines = Object.values(invoice).flat()
                    return (
                      <TableRow
                        className={classes.row}
                        onClick={(e) => {
                          const event = e.target as HTMLInputElement
                          if (event.getAttribute('type') === 'checkbox') {
                            e.stopPropagation()
                          } else {
                            setChosenInvoiceGroup(invoiceNo)
                          }
                        }}
                        key={`send-soa-row-${invoiceNo}`}
                        data-testid='send-soa-row'
                      >
                        {SENDING_MODAL_COLUMN_KEYS.map((key, colIdx) => {
                          const colKey = `${key}-${colIdx}`
                          if (key === SENDING_SOA_LINE_COLUMN_KEYS.SELECTED) {
                            const isForwardingConsol =
                              invoiceLines[0][SENDING_SOA_LINE_COLUMN_KEYS.CARGOWISE_MODULE] ===
                              CARGOWISE_MODULE.ForwardingConsol
                            const isSelected =
                              !!invoiceLines[0][SENDING_SOA_LINE_COLUMN_KEYS.SELECTED]
                            const isPending =
                              invoiceLines[0][SENDING_SOA_LINE_COLUMN_KEYS.STATUS] ===
                              SENDING_STATUS.PENDING
                            const isSent =
                              invoiceLines[0][SENDING_SOA_LINE_COLUMN_KEYS.STATUS] ===
                              SENDING_STATUS.SENT
                            const checked = !isForwardingConsol && isSelected && isPending
                            return (
                              <TableCell key={colKey}>
                                <Checkbox
                                  checked={checked}
                                  inputProps={{ checked }}
                                  onChange={() => handleCheckbox(invoiceNo)}
                                  disabled={isForwardingConsol || isSent}
                                  data-testid={'send-soa-checkbox'}
                                />
                              </TableCell>
                            )
                          } else if (key === SENDING_SOA_LINE_COLUMN_KEYS.SHIPMENT_NO) {
                            return (
                              <TableCell key={colKey}>
                                {shipmentsPerInvoice[invoiceNo] &&
                                  shipmentsPerInvoice[invoiceNo].map((shipNo) => {
                                    return shipNo && <TableRow key={shipNo}>{shipNo}</TableRow>
                                  })}
                              </TableCell>
                            )
                          } else if (key === SENDING_SOA_LINE_COLUMN_KEYS.SECOND_KEYS) {
                            return (
                              <TableCell key={colKey}>
                                {secondaryKeysPerInvoice[invoiceNo] &&
                                  secondaryKeysPerInvoice[invoiceNo].map((secondaryKeys) => {
                                    return (
                                      secondaryKeys && (
                                        <TableRow
                                          key={`secondary-keys-${invoiceNo}-${secondaryKeys}`}
                                          className={classes.secondaryKeys}
                                        >
                                          {secondaryKeys}
                                        </TableRow>
                                      )
                                    )
                                  })}
                              </TableCell>
                            )
                          }
                          // use the invoice's first line item info, if notes, use most recent
                          return (
                            <TableCell key={colKey}>
                              {key === SENDING_SOA_LINE_COLUMN_KEYS.NOTES &&
                              (invoiceLines[0][key] as string[][]).length !== 0
                                ? (invoiceLines[0][key] as string[][])[
                                    (invoiceLines[0][key] as string[][]).length - 1
                                  ][1]
                                : invoiceLines[0][key]}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {isSendingSOAToCW && (
            <>
              <LinearProgress
                variant='determinate'
                value={tasksOutOfQueue ? (tasksOutOfQueue / numberSending) * 100 : 0}
              />
              <Typography align='left'>{elapsedTime.toFixed(2)} seconds...</Typography>
              <Typography align='right'>
                {`Sending invoice
                ${tasksOutOfQueue && tasksOutOfQueue <= numberSending ? tasksOutOfQueue + 1 : 1}
                / ${numberSending} to CargoWise`}
              </Typography>
            </>
          )}
          <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
            <Box flex={1} flexDirection='column'>
              <Box display='flex' alignItems='center'>
                <Checkbox
                  checked={overrideChargeDescriptionFlag}
                  onChange={() => setOverrideChargeDescriptionFlag(!overrideChargeDescriptionFlag)}
                  data-testid='override-checkbox'
                />
                <Typography>Override Charge Description in CW</Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                <Checkbox
                  checked={disableSendDueDateFlag}
                  onChange={() => setDisableSendDueDateFlag(!disableSendDueDateFlag)}
                  data-testid='disable-send-due-date'
                />
                <Typography>Do NOT send Due Date</Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                <Checkbox
                  checked={sendAsLumpsumFlag}
                  onChange={() => setSendAsLumpsumFlag(!sendAsLumpsumFlag)}
                  data-testid='lumpsum-checkbox'
                />
                <Typography>Send as lumpsum</Typography>
              </Box>
            </Box>
            <Box flex={2}>
              <Box display='flex' alignItems='center' justifyContent='right' p={2}>
                <Box mr={2}>
                  <Button
                    variant='contained'
                    disabled={isSendingSOAToCW}
                    onClick={() => setSureClose(true)}
                    data-testid='cancel-send-btn'
                  >
                    Go Back
                  </Button>
                </Box>
                <Button
                  variant='contained'
                  disabled={isSendingSOAToCW || isValidatingChargeCodes}
                  color={!isSendingSOAToCW ? 'primary' : undefined}
                  onClick={() => sendSoaToCW()}
                  data-testid='send-soa-btn'
                >
                  Send Selected
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {chosenInvoiceGroup && (
        <SOAInvoiceGroupDialog
          isOpen={!!chosenInvoiceGroup}
          close={() => setChosenInvoiceGroup('')}
          invoiceGroup={getGroupedSoaLineItems(chosenInvoiceGroup, sendAsLumpsumFlag)}
          vendor={vendor}
          overrideChargeDescription={overrideChargeDescriptionFlag}
        />
      )}
      {sureClose && (
        <Dialog open={sureClose} className={classes.closeDialog}>
          <DialogTitle>
            Are you sure?
            <Typography> All sending details and notes will be lost </Typography>
          </DialogTitle>
          <DialogContent>
            <Box display='flex' justifyContent='center'>
              <Box display='flex' mr={2}>
                <Button variant='contained' onClick={() => setSureClose(false)}>
                  Cancel
                </Button>
              </Box>
              <Button variant='contained' color={'secondary'} onClick={closeSure}>
                Close
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default SendSOAToCWDialog
