import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import theme from '@src/utils/theme'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_CHARGE_QUANTITY } from '@src/graphql/mutations/recon'
import { GET_DOCUMENT_TABLES } from '@src/graphql/queries/document'
import {
  MutationUpdateChargeQuantityArgs,
  Mutation,
  QueryDocumentTablesArgs,
  Query,
} from '@src/graphql/types'
import { updateDocumentTables } from '@src/redux-features/document_editor'

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  buttonGrid: {
    justifyContent: 'center',
  },
})

type Props = {
  jobId: string
  isOpen: boolean
  close: () => void
}

export const UpdateChargeQuantityDialog: FunctionComponent<Props> = ({ jobId, isOpen, close }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const [isUpdating, setIsUpdating] = useState(false)

  const [updateChargeQuantity] = useMutation<
    Pick<Mutation, 'updateChargeQuantity'>,
    MutationUpdateChargeQuantityArgs
  >(UPDATE_CHARGE_QUANTITY)
  const { refetch: refetchDocumentTables } = useQuery<
    Pick<Query, 'documentTables'>,
    QueryDocumentTablesArgs
  >(GET_DOCUMENT_TABLES, { fetchPolicy: 'network-only', skip: true })

  const update = async (): Promise<void> => {
    setIsUpdating(true)
    try {
      await updateChargeQuantity({
        variables: { jobId },
      }).then(async (): Promise<void> => {
        const updatedDocTablesQuery = await refetchDocumentTables({ jobId: jobId })
        if (updatedDocTablesQuery.data) {
          const updatedDocTables = updatedDocTablesQuery.data.documentTables
          if (updatedDocTables) {
            dispatch(updateDocumentTables(updatedDocTables))
          }
        }
        enqueueSnackbar(`Successfully updated charge quantity`, { variant: 'success' })
      })
    } catch (error) {
      enqueueSnackbar(`Failed to update charge quantity: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
    setIsUpdating(false)
  }

  return (
    <Dialog classes={{ paper: classes.dialog }} open={isOpen}>
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={close} data-testid='close-btn' />
      </Box>
      <DialogTitle>
        <Box p={1}>
          <Typography variant='h6'>
            The number of containers has changed. Would you like to update the quantity column for
            the &lsquo;Charges&rsquo; table a well?
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.buttonGrid} container alignItems='flex-end' spacing={4}>
          <Grid item>
            <Button variant='contained' onClick={close} disabled={isUpdating}>
              No
            </Button>
          </Grid>
          <Grid item>
            <Button
              color='primary'
              variant='contained'
              onClick={async (): Promise<void> => {
                await update()
                close()
              }}
              disabled={isUpdating}
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
