import { FunctionComponent, useCallback, useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { useSnackbar } from 'notistack'

import { useMutation } from '@apollo/client'
import {
  ApiExportType,
  InputDocumentTable,
  JobNode,
  JobTemplateReconType,
  Mutation,
  MutationExportJobToApiPartnerArgs,
  ShipmentOp,
} from '@src/graphql/types'
import { EXPORT_JOB_TO_API_PARTNER } from '@src/graphql/mutations/job'
import SendToTMSDialog from '@src/components/SendToTMSDialog'
import CheckShipmentInfoTmsDialog from '@src/components/CheckShipmentInfoTmsDialog'
import ProcessingLogDialog from '../ProcessingLogsDialog'

enum CWModuleToExportType {
  ForwardingShipment = ApiExportType.Shipment,
  ForwardingConsol = ApiExportType.Consolidation,
  CustomsDeclaration = ApiExportType.CustomsDeclaration,
  AccountingInvoice = ApiExportType.Invoice,
}

const ApiExportButtonLabelMap = {
  [ApiExportType.Shipment]: 'Shipment',
  [ApiExportType.Consolidation]: 'Consolidation',
  [ApiExportType.CustomsDeclaration]: 'Customs Declaration',
  [ApiExportType.Invoice]: 'Invoice',
  [ApiExportType.PostInvoice]: 'Invoice',
  [ApiExportType.BatchInvoices]: 'Batch Invoices',
  [ApiExportType.CommercialInvoice]: 'Commercial Invoice',
  [ApiExportType.GenericJson]: 'Generic JSON',
}

type Props = {
  job: JobNode
  getCwModuleFromRefMap: () => string
  saveAndValidateWrapper: (actionFn: () => Promise<void>, action: string) => Promise<void>
  saveAndValidateWrapperSoa: (actionFn: () => Promise<void>, action: string) => Promise<void>
  handleReconcile: () => Promise<void>
}

const ApiExportButtons: FunctionComponent<Props> = ({
  job,
  getCwModuleFromRefMap,
  saveAndValidateWrapper,
  saveAndValidateWrapperSoa,
  handleReconcile,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const apiPartner = job!.jobTemplate!.apiPartner!
  const shipmentOpsTypes = job!.jobTemplate!.shipmentOpsTypes || []
  const cwModule = getCwModuleFromRefMap()
  const TMSExportType = (CWModuleToExportType[
    cwModule as keyof typeof CWModuleToExportType
  ]?.toString() ?? ApiExportType.Shipment.toString()) as ApiExportType
  const [apiExportType, setApiExportType] = useState(null as ApiExportType | null)
  const [isSendToTMSDialogOpen, setIsSendToTMSDialogOpen] = useState(false)
  const [isCheckShipmentInfoDialogOpen, setIsCheckShipmentInfoDialogOpen] = useState(false)
  const [responseProcessingLogMessage, setResponseProcessingLogMessage] = useState(
    null as string | null,
  )
  const [isResponseProcessingLogsDialogOpen, setIsResponseProcessingLogsDialogOpen] =
    useState(false)

  const [exportJobToApiPartner] = useMutation<
    Pick<Mutation, 'exportJobToApiPartner'>,
    MutationExportJobToApiPartnerArgs
  >(EXPORT_JOB_TO_API_PARTNER)
  const handleExport = useCallback(
    (
      exportType: ApiExportType,
      lumpsum?: InputDocumentTable | null,
      overrideChargeDescriptionFlag?: boolean | null,
    ) => {
      const notifyMessage = `exporting ${ApiExportButtonLabelMap[exportType]} to ${apiPartner.name}`
      void saveAndValidateWrapper(async () => {
        try {
          const { data } = await exportJobToApiPartner({
            variables: {
              jobId: job!.id,
              exportType,
              lumpsum,
              overrideChargeDescription: overrideChargeDescriptionFlag ?? undefined,
            },
          })

          enqueueSnackbar(`Success ${notifyMessage}`, { variant: 'success' })
          setResponseProcessingLogMessage(data?.exportJobToApiPartner?.responseText ?? '')
        } catch (error: any) {
          setResponseProcessingLogMessage(error.message)
          throw new Error('Endpoint Returned an Error')
        } finally {
          setIsResponseProcessingLogsDialogOpen(true)
        }
      }, notifyMessage)
    },
    [saveAndValidateWrapper, job, exportJobToApiPartner, apiPartner, enqueueSnackbar],
  )

  const handleExportSoa = useCallback(
    (exportType: ApiExportType) => {
      const notifyMessage = `exporting ${ApiExportButtonLabelMap[exportType]} to ${apiPartner.name}`
      void saveAndValidateWrapperSoa(async () => {
        try {
          const { data } = await exportJobToApiPartner({
            variables: {
              jobId: job!.id,
              exportType,
            },
          })
          enqueueSnackbar(`Success ${notifyMessage}`, { variant: 'success' })
          setResponseProcessingLogMessage(data?.exportJobToApiPartner?.responseText ?? '')
        } catch (error: any) {
          setResponseProcessingLogMessage(error.message)
        } finally {
          setIsResponseProcessingLogsDialogOpen(true)
        }
      }, notifyMessage)
    },
    [saveAndValidateWrapperSoa, job, exportJobToApiPartner, apiPartner, enqueueSnackbar],
  )

  const handleShowTmsModal = useCallback(
    (exportType: ApiExportType) => {
      const notifyMessage = `saving / validating job. Please check if the fields are correct and try again.`
      void saveAndValidateWrapper(async () => {
        setApiExportType(exportType)
        setIsSendToTMSDialogOpen(true)
      }, notifyMessage)
    },
    [saveAndValidateWrapper, setApiExportType, setIsSendToTMSDialogOpen],
  )

  const handleShowCheckShipmentModal = useCallback(() => {
    const notifyMessage = `saving / validating job. Please check if the fields are correct and try again.`
    void saveAndValidateWrapper(async () => {
      setIsCheckShipmentInfoDialogOpen(true)
    }, notifyMessage)
  }, [])

  return (
    <>
      <MenuItem
        onClick={() => {
          if (job!.jobTemplate!.reconType === JobTemplateReconType.Ap) {
            handleShowTmsModal(ApiExportType.GenericJson)
          } else {
            handleExport(ApiExportType.GenericJson)
          }
        }}
      >
        Export {ApiExportButtonLabelMap[ApiExportType.GenericJson]} to {apiPartner.name}
      </MenuItem>
      {job!.jobTemplate!.reconType === JobTemplateReconType.Ap && (
        <>
          <MenuItem onClick={() => handleExport(TMSExportType)}>
            Export {ApiExportButtonLabelMap[TMSExportType]} to {apiPartner.name}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleShowTmsModal(ApiExportType.Invoice)
            }}
          >
            Export {ApiExportButtonLabelMap[ApiExportType.Invoice]} to {apiPartner.name}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleShowTmsModal(ApiExportType.PostInvoice)
            }}
          >
            Post {ApiExportButtonLabelMap[ApiExportType.PostInvoice]} to {apiPartner.name}
          </MenuItem>
          <MenuItem onClick={handleShowCheckShipmentModal}>Check Shipment Info</MenuItem>
        </>
      )}
      {job!.jobTemplate!.reconType === JobTemplateReconType.Soa && (
        <MenuItem onClick={() => handleExportSoa(ApiExportType.BatchInvoices)}>
          Export {ApiExportButtonLabelMap[ApiExportType.BatchInvoices]} to {apiPartner.name}
        </MenuItem>
      )}
      {shipmentOpsTypes.includes(ShipmentOp.Shipment) && (
        <MenuItem onClick={() => handleExport(ApiExportType.Shipment)}>
          Export {ApiExportButtonLabelMap[ApiExportType.Shipment]} to {apiPartner.name}
        </MenuItem>
      )}
      {shipmentOpsTypes.includes(ShipmentOp.Consol) && (
        <MenuItem onClick={() => handleExport(ApiExportType.Consolidation)}>
          Export {ApiExportButtonLabelMap[ApiExportType.Consolidation]} to {apiPartner.name}
        </MenuItem>
      )}
      {shipmentOpsTypes.includes(ShipmentOp.ImportDeclaration) && (
        <MenuItem onClick={() => handleExport(ApiExportType.CustomsDeclaration)}>
          Export {ApiExportButtonLabelMap[ApiExportType.CustomsDeclaration]} to {apiPartner.name}
        </MenuItem>
      )}
      {shipmentOpsTypes.includes(ShipmentOp.CommercialInvoice) && (
        <MenuItem onClick={() => handleExport(ApiExportType.CommercialInvoice)}>
          Export {ApiExportButtonLabelMap[ApiExportType.CommercialInvoice]} to {apiPartner.name}
        </MenuItem>
      )}
      {isSendToTMSDialogOpen && (
        <SendToTMSDialog
          close={() => setIsSendToTMSDialogOpen(false)}
          jobId={job.id}
          apiExportType={apiExportType}
          handleExport={handleExport}
        />
      )}
      {isCheckShipmentInfoDialogOpen && (
        <CheckShipmentInfoTmsDialog
          close={() => setIsCheckShipmentInfoDialogOpen(false)}
          jobId={job!.id}
          handleReconcile={handleReconcile}
        />
      )}
      {responseProcessingLogMessage && isResponseProcessingLogsDialogOpen && (
        <ProcessingLogDialog
          close={() => {
            setIsResponseProcessingLogsDialogOpen(false)
            setResponseProcessingLogMessage(null)
          }}
          processingLog={responseProcessingLogMessage}
        />
      )}
    </>
  )
}

export default ApiExportButtons
