import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState } from 'react'
import { Box, DialogContentText, IconButton, Theme, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

import theme from '@src/utils/theme'
import { useMutation } from '@apollo/client'
import { CompanyNode, Mutation, MutationDeleteCompanyArgs } from '@src/graphql/types'
import { DELETE_COMPANY } from '@src/graphql/mutations/company'
import { useSnackbar } from 'notistack'

type Props = {
  company: CompanyNode
  refetchCompanies: () => void
  setCompanyToDelete: (company: CompanyNode | null) => void
}

const useStyles = makeStyles<Theme>({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
})

const DeleteCompanyDialog: FunctionComponent<Props> = ({
  company,
  refetchCompanies,
  setCompanyToDelete,
}) => {
  const classes = useStyles()
  const [isDeleteing, setIsDeleting] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [deleteCompanyMutation] = useMutation<
    Pick<Mutation, 'deleteCompany'>,
    MutationDeleteCompanyArgs
  >(DELETE_COMPANY)
  const closePopup = (): void => setCompanyToDelete(null)
  const deleteCompany = async (): Promise<void> => {
    try {
      setIsDeleting(true)
      await deleteCompanyMutation({ variables: { companyId: company.id } })
      refetchCompanies()
      enqueueSnackbar('Successfully deleted company', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Failed to delete company: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    } finally {
      closePopup()
      setIsDeleting(false)
    }
  }
  return (
    <Dialog open>
      <DialogTitle disableTypography>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h3' className={classes.title}>
            Delete {company.name}
          </Typography>
          <IconButton onClick={closePopup}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' height='100%'>
          <DialogContentText>
            Do you want to delete this company? This will also delete all job types within this
            company. This action cannot be undone.
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closePopup}>Cancel</Button>
        <Button variant='contained' color='primary' disabled={isDeleteing} onClick={deleteCompany}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteCompanyDialog
