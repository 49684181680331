import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { getReconTables } from '@src/utils/recon/recon'
import { GET_JOB_FOR_TMS } from '@src/graphql/queries/job'
import {
  AnReconAutofillKey,
  DocumentFieldGroupNode,
  DocumentTableNode,
  JobNode,
  JobTemplateReconType,
  Query,
  QueryJobArgs,
  Maybe,
  JobTableNode,
} from '@src/graphql/types'

type TMSJobDataHooksType = {
  job: Maybe<JobNode>
  jobDataLoading: boolean
  reconTables: DocumentTableNode[]
  jobTable: JobTableNode | null
  documentFieldGroups: DocumentFieldGroupNode[]
  isPremiumRate: boolean
  metadataFieldValueMap: Record<string, string>
}

const useTMSJobData = (
  jobId: string,
  reconType: JobTemplateReconType | undefined | null,
): TMSJobDataHooksType => {
  const { data: jobData, loading: jobDataLoading } = useQuery<Pick<Query, 'job'>, QueryJobArgs>(
    GET_JOB_FOR_TMS,
    {
      fetchPolicy: 'no-cache',
      variables: { id: jobId },
    },
  )
  const job = jobData?.job ?? null
  const [documentFieldGroups, setDocumentFieldGroups] = useState([] as DocumentFieldGroupNode[])
  const [metadataFieldValueMap, setMetadataFieldValueMap] = useState({} as Record<string, string>)

  const reconTables = useMemo(() => {
    if (reconType !== JobTemplateReconType.Soa) {
      const documentTables =
        job?.filePages?.edges.flatMap(
          (filePageEdge) =>
            filePageEdge!.node!.document!.documentTables?.edges.map(
              (documentTableEdge) => documentTableEdge!.node!,
            ) || [],
        ) || []
      return getReconTables(documentTables)
    }
    return []
  }, [job, reconType])
  const jobTable = job && reconType === JobTemplateReconType.Soa ? job!.jobTable! : null

  useEffect(() => {
    if (job?.filePages?.edges) {
      const newDocFieldGroups =
        job?.filePages?.edges.flatMap(
          (filePageEdge) =>
            filePageEdge!.node!.document!.documentFieldGroups?.edges.map(
              (documentFieldGroupEdge) => documentFieldGroupEdge!.node!,
            ) || [],
        ) || []

      const newMetadataFieldValueMap = {} as Record<string, string>
      newDocFieldGroups.forEach((docFieldGroup) => {
        const isRepeatable = docFieldGroup?.fieldGroup?.repeatable
        if (!isRepeatable) {
          const docFieldValue = docFieldGroup?.documentFields?.edges?.[0]?.node?.value
          const autofillKey = docFieldGroup?.fieldGroup?.autofillKey
          newMetadataFieldValueMap[autofillKey] = docFieldValue
        }
      })

      setDocumentFieldGroups(newDocFieldGroups)
      setMetadataFieldValueMap(newMetadataFieldValueMap)
    }
  }, [job?.filePages?.edges])

  const isPremiumRate = useMemo(() => {
    if (reconType !== JobTemplateReconType.ArrivalNotice) {
      return false
    }
    const isPremiumRateFieldGroup = documentFieldGroups.find((documentFieldGroup) => {
      const fieldGroup = documentFieldGroup!.fieldGroup
      return (
        !fieldGroup!.repeatable &&
        fieldGroup!.fields!.edges[0]!.node!.autofillKey ===
          AnReconAutofillKey.IsPremiumRate.toLowerCase()
      )
    })
    const isPremiumRateValue =
      isPremiumRateFieldGroup?.documentFields!.edges[0]!.node!.value!.toLowerCase()
    return isPremiumRateValue === 'yes'
  }, [documentFieldGroups, reconType])

  return {
    job,
    jobDataLoading,
    jobTable,
    reconTables,
    documentFieldGroups,
    isPremiumRate,
    metadataFieldValueMap,
  }
}

export default useTMSJobData
