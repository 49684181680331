import { FunctionComponent } from 'react'
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core'
import theme from '@src/utils/theme'
import { CompanyAdminTab } from '@src/utils/admin/sidebar'
import RoundedButton from '@src/components/RoundedButton'

const useStyles = makeStyles({
  sideBar: {
    paddingTop: theme.spacing(18),
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
    position: 'fixed',
    top: 0,
    width: 240,
    zIndex: 0,
  },
  selectedTab: {
    color: 'white',
    maxWidth: 'unset',
  },
  tab: {
    maxWidth: 'unset',
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.primary.contrastText,
    },
  },
  importCsvButton: {
    background: 'white',
    color: theme.palette.grey[600],
    maxHeight: 'unset',
    border: `1px solid ${theme.palette.grey[500]}`,
  },
  csvButtonWrapper: {
    padding: theme.spacing(2),
    position: 'absolute',
    bottom: 0,
  },
})

type Props = {
  companyId: string
  selectedTab: CompanyAdminTab
  setSelectedTab: (newSelectedTab: CompanyAdminTab) => void
}

const CompanyAdminSidebar: FunctionComponent<Props> = ({
  companyId,
  selectedTab,
  setSelectedTab,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.sideBar}>
      <Tabs
        orientation='vertical'
        value={selectedTab}
        onChange={(e, v) => {
          setSelectedTab(v as CompanyAdminTab)
        }}
        TabIndicatorProps={{
          style: { width: '100%', background: theme.palette.primary.main, zIndex: -1 },
        }}
      >
        {Object.entries(CompanyAdminTab).map(([key, tab]) => (
          <Tab
            label={tab.split('_').join(' ')}
            value={tab}
            key={key}
            selected={tab == selectedTab}
            className={tab == selectedTab ? classes.selectedTab : classes.tab}
          />
        ))}
      </Tabs>
      <Box className={classes.csvButtonWrapper}>
        <RoundedButton
          fullWidth
          className={classes.importCsvButton}
          onClick={() => window!.open(`/admin/company/${companyId}/import`, '_blank')!.focus()}
        >
          + Import vendors and charges CSV
        </RoundedButton>
      </Box>
    </Box>
  )
}

export default CompanyAdminSidebar
