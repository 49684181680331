/**
 * Obtains batch recon data from active document table
 * and uses it to batch recon SOA job and call BulkReconDialog component.
 * All directly from export button group
 *
 * @props job: JobNode
 * @props documentType: DocumentTypeNode
 * @props closePopup: () => void
 * @props isOpen: boolean
 *
 */

import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { HotTable } from '@handsontable/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkMissingFields,
  formatMaybeApolloError,
  validateSoaTableFields,
} from '@src/utils/errors'
import { setActiveDocumentTable } from '@src/redux-features/document_editor'
import { selectActiveDocument } from '@src/redux-features/document_editor/document'
import { selectRepeatableFieldAutofillKeyMap } from '@src/redux-features/document_editor/field'
import { RootState } from '@src/utils/store'
import {
  MatchingCriteriaType,
  Mutation,
  MutationReconcileSoaJobArgs,
  Query,
  QuerySearchableRecordResultsArgs,
  ReconAttemptNode,
} from '@src/graphql/types'
import { useMutation, useQuery } from '@apollo/client'
import 'handsontable/dist/handsontable.full.css'
import { RECONCILE_SOA_JOB } from '@src/graphql/mutations/soa'
import BulkReconDialog from '@src/components/reconciliation-dialog/bulk-recon-dialog/BulkReconDialog'
import InvoiceLineItemReconDialog from '../reconciliation-dialog/InvoiceLineItemReconDialog'
import { useSnackbar } from 'notistack'
import useRerenderOnCollapse from '@src/components/data-grid/hooks/useRerenderOnCollapse'
import { makeSoaRowFieldMappings } from '../data-grid/util'
import {
  selectFormattedColumns,
  selectFormattedJobTableRows,
  selectJobTableColumnHeaders,
} from '@src/redux-features/document_editor/rows_columns_selectors'
import { SEARCHABLE_RECORD_RESULTS } from '@src/graphql/queries/searchableRecord'

type Props = {
  jobId: string
  closePopup: () => void
  openSoaReconOptionsDialog: () => void
  isOpen: boolean
  overrideChargeDescription: boolean
  disableSendDueDate: boolean
  reconcileMatchCriteria: MatchingCriteriaType
  reconAsLumpsum: boolean
}

const BatchReconciliationDialog: FunctionComponent<Props> = ({
  jobId,
  closePopup,
  openSoaReconOptionsDialog,
  isOpen,
  overrideChargeDescription,
  disableSendDueDate,
  reconcileMatchCriteria,
  reconAsLumpsum,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const hotTableRef = useRef<HotTable>()
  useRerenderOnCollapse(hotTableRef)
  /*
   * Reference MainTable.afterChange() for possible future implementation
   ****/

  // Get active document table using active document
  const activeDocumentTable = useSelector(
    (state: RootState) =>
      selectActiveDocument(state.documentEditor)?.documentTables?.edges[0]?.node,
  )

  // TODO: Hacky way to do this for right now,
  //       shouldn't have to change state on other components during render of this one
  useMemo(() => {
    // Set active document table from outside table view
    dispatch(setActiveDocumentTable(activeDocumentTable?.id || null))
  }, [dispatch, activeDocumentTable?.id])

  const repeatableFieldAutofillKeyMap = useSelector((state: RootState) =>
    selectRepeatableFieldAutofillKeyMap(state.documentEditor),
  )!
  const { refetch: refetchSearchableRecords } = useQuery<
    Pick<Query, 'searchableRecordResults'>,
    QuerySearchableRecordResultsArgs
  >(SEARCHABLE_RECORD_RESULTS, { skip: true })

  // Get appropriate matching column headers and row values with keys from lineItemsTableColumns and lineItems
  // TODO: strangely, selectJobTableFormattedColumns does not work here (job table null) so we use this instead
  const columns = useSelector((state: RootState) =>
    selectFormattedColumns(state.documentEditor, refetchSearchableRecords, enqueueSnackbar),
  )
  const colHeaders = useSelector((state: RootState) =>
    selectJobTableColumnHeaders(state.documentEditor),
  )
  const rows = useSelector((state: RootState) => selectFormattedJobTableRows(state.documentEditor))

  const [selectedReconAttempt, setSelectedReconAttempt] = useState(null as null | ReconAttemptNode)
  const [reconAsyncBatchId, setReconAsyncBatchId] = useState(null as null | string)

  const [reconcileSoaJob] = useMutation<
    Pick<Mutation, 'reconcileSoaJob'>,
    MutationReconcileSoaJobArgs
  >(RECONCILE_SOA_JOB)

  // Batch reconcile SOA job with current active document table data if present
  const reconcile = async (): Promise<void> => {
    const missingFieldsError = checkMissingFields(columns, rows)
    const isSoaTableValid = validateSoaTableFields(columns, rows)
    if (missingFieldsError !== null) {
      closePopup()
      enqueueSnackbar(missingFieldsError, { variant: 'error' })
    } else if (!isSoaTableValid) {
      closePopup()
      enqueueSnackbar(
        'Validation failed for the Main SOA Table. Please ensure fields are valid before reconciling.',
        { variant: 'error' },
      )
    } else if (rows!.length !== 0) {
      try {
        const resp = await reconcileSoaJob({
          variables: {
            jobId,
            overrideChargeDescription,
            disableSendDueDate,
            matchingCriteria: reconcileMatchCriteria,
            asLumpsum: reconAsLumpsum,
          },
        })
        setReconAsyncBatchId(resp.data!.reconcileSoaJob!.reconAsyncBatchId)
      } catch (error) {
        const errorMessageSoaBatchRecon = `Error batch reconciling SOA: ${formatMaybeApolloError(
          error,
        )}. Please make sure all fields and tables are filled in correctly and try again`
        enqueueSnackbar(errorMessageSoaBatchRecon, { variant: 'error' })
      }
    } else {
      closePopup()
      const noLineItemsError = `Error batch reconciling SOA: no line items found in Main SOA`
      enqueueSnackbar(noLineItemsError, { variant: 'error' })
    }
  }

  const getSoaRowFieldMappings = useCallback(
    () =>
      hotTableRef != null
        ? makeSoaRowFieldMappings(colHeaders as string[], rows, repeatableFieldAutofillKeyMap)
        : [],
    [repeatableFieldAutofillKeyMap, colHeaders, rows],
  )

  // only reconcile if BulkReconDialog is open
  useEffect(() => {
    if (isOpen) {
      void reconcile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <>
      {isOpen && reconAsyncBatchId && (
        <BulkReconDialog
          isOpen={isOpen}
          jobId={jobId}
          closePopup={closePopup}
          goBackToSoaOptionsDialog={openSoaReconOptionsDialog}
          reconAsyncBatchId={reconAsyncBatchId}
          setSelectedReconAttempt={setSelectedReconAttempt}
          getSoaRowFieldMappings={getSoaRowFieldMappings}
        />
      )}
      {selectedReconAttempt && (
        <InvoiceLineItemReconDialog
          reconAttempt={selectedReconAttempt}
          isOpen={!!selectedReconAttempt}
          closePopup={() => setSelectedReconAttempt(null)}
          overrideChargeDescription={overrideChargeDescription}
        />
      )}
    </>
  )
}

export default BatchReconciliationDialog
