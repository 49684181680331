export enum LogEventType {
  EDOCS_PULL = 'edocs_pull',
  EDOCS_PUSH = 'edocs_push',
  FILE_PAGES_VIEW = 'file_pages_view',
  FILE_PAGES_DELETE = 'file_pages_delete',
  FILE_PAGES_SELECT = 'file_pages_select',
  ACCOUNT_SIGN_IN = 'account_sign_in',
  ACCOUNT_SIGN_OUT = 'account_sign_out',
  SHIPMENT_SEND_TO_TMS = 'shipment_send_to_tms',
  SHIPMENT_POST_TO_TMS = 'shipment_post_to_tms',
  RECONCILE_ATTEMPT = 'reconcile_attempt',
  RECONCILE_SHOW = 'reconcile_show_to_customer',
  RECONCILE_ACCEPT = 'reconcile_accept',
  RECONCILE_REJECT = 'reconcile_reject',
  RECONCILE_PENDING = 'reconcile_pending',
  RECONCILE_NOTE_CREATE = 'reconcile_note_create',
  RECONCILE_NOTE_DELETE = 'reconcile_note_delete',
  PAGE_VISIT = 'page_visit',
  EDIT_METAFIELDS = 'edit_metafields',
  EDIT_LINE_ITEMS_CTA = 'edit_line_items_cta',
  EDIT_LINE_ITEMS_CELL = 'edit_line_items_cell',
  EDIT_LINE_ITEMS_BATCH_EDIT = 'edit_line_items_batch_edit',
  EDIT_LINE_ITEMS_BOXING = 'edit_line_items_boxing',
  SHIPMENT_SEARCH = 'shipment_search',
  FILE_UPLOAD = 'file_upload',
  CREATE_TASK = 'create_task',
  CREATE_JOB = 'create_job',
  CHANGE_DOCUMENT_TYPE = 'change_document_type',
  COPY_ROWS_TO_PAGE = 'copy_rows_to_page',
  EDIT_LINE_ITEMS_PASTE = 'edit_line_items_paste',
  EDIT_LINE_ITEMS_DELETE = 'edit_line_items_delete',
}
