import { Grid, GridProps, TextField } from '@material-ui/core'
import { ChangeEvent, FunctionComponent } from 'react'
import { DateRange } from './useDateRange'
import { checkDateOrder } from '@src/utils/date'

const DateRangeSelector: FunctionComponent<DateRange & GridProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  ...props
}) => {
  return (
    <Grid container {...props}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant='outlined'
          type='date'
          value={startDate}
          onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setStartDate(event.target.value)
          }}
          InputLabelProps={{ shrink: true }}
          label='Start Date'
          error={checkDateOrder(startDate, endDate)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant='outlined'
          type='date'
          value={endDate}
          onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setEndDate(event.target.value)
          }}
          InputLabelProps={{ shrink: true }}
          label='End Date'
          error={checkDateOrder(startDate, endDate)}
        />
      </Grid>
    </Grid>
  )
}

export default DateRangeSelector
