import { FunctionComponent, ReactElement } from 'react'
import { clsx } from 'clsx'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { ArrivalNoticeChargeRateReconResultNode } from '@src/graphql/types'
import {
  CHARGE_RATE_NOT_FOUND_MESSAGE,
  constructKeyFromRowColIdx,
  getChargeRateTableData,
  RateFilter,
  RateTableDataType,
} from '@src/utils/recon/an_recon'
import theme from '@src/utils/theme'
import { COLORS } from '@src/utils/app_constants'

const useStyles = makeStyles<Theme>({
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.25, 1),
  },
  error: {
    color: theme.palette.error.main,
    background: COLORS.PALE_RED,
    boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
  },
})

type Props = {
  ratesReconResults: ArrivalNoticeChargeRateReconResultNode[]
}

const ArrivalNoticeRatesResults: FunctionComponent<Props> = ({ ratesReconResults }) => {
  const classes = useStyles()
  const chargeRateTableData = getChargeRateTableData(ratesReconResults)

  const getTableCell = (rowIdx: number, colIdx: number, data: string | number): ReactElement => {
    const commentKey = constructKeyFromRowColIdx(rowIdx, colIdx)
    const comment = chargeRateTableData[RateTableDataType.COMMENTS][commentKey]
    const isRateNotFound = rowIdx === RateFilter.RATE_COST && data === CHARGE_RATE_NOT_FOUND_MESSAGE
    const isRateNotEqual =
      rowIdx === RateFilter.RATE_COST &&
      data !== chargeRateTableData[RateTableDataType.DATA][RateFilter.EXPECTED_RATE_COST][colIdx]
    const isTotalAmountNotEqual =
      rowIdx === RateFilter.AMOUNT &&
      data !== chargeRateTableData[RateTableDataType.DATA][RateFilter.EXPECTED_AMOUNT][colIdx]
    const isError =
      chargeRateTableData[RateTableDataType.ERROR_CELLS][colIdx] === rowIdx ||
      isRateNotFound ||
      isRateNotEqual ||
      isTotalAmountNotEqual

    const isColumnHeader = colIdx === 0
    const cell = (
      <TableCell
        className={clsx(classes.cell, {
          [classes.colCell]: isColumnHeader,
          [classes.error]: !isColumnHeader && isError,
        })}
        align={isColumnHeader ? 'center' : 'left'}
        component='th'
        scope='row'
        width={isColumnHeader ? '12%' : '22%'}
      >
        {data}
      </TableCell>
    )

    return comment ? (
      <Tooltip title={comment} placement='right-start' arrow>
        {cell}
      </Tooltip>
    ) : (
      cell
    )
  }

  return (
    <>
      <Typography variant='h4' gutterBottom>
        Rates Reconciliation
      </Typography>
      <Box height='20vh' overflow='scroll'>
        <TableContainer>
          <Table size='small'>
            <TableBody>
              {Object.values(chargeRateTableData[RateTableDataType.DATA]).map((row, rowIdx) => (
                <TableRow key={rowIdx}>
                  {row.map((data, colIdx) => getTableCell(rowIdx, colIdx, data))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

export default ArrivalNoticeRatesResults
