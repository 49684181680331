import { FunctionComponent } from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import theme from '@src/utils/theme'
import RoundedButton from '@src/components/RoundedButton'

type Props = {
  cancel: () => void
  create: () => void
}

const useStyles = makeStyles<Theme>({
  wrapper: {
    textAlign: 'right',
    paddingRight: theme.spacing(2),
  },
  deleteButton: {
    background: `${theme.palette.primary.contrastText}`,
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  copyButton: {
    background: `${theme.palette.primary.contrastText}`,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  saveButton: {
    border: 'none',
  },
})

const CreateButtons: FunctionComponent<Props> = ({ cancel, create }) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <RoundedButton variant='contained' onClick={cancel} className={classes.deleteButton}>
        Cancel
      </RoundedButton>
      <RoundedButton
        variant='contained'
        onClick={create}
        color='primary'
        className={classes.saveButton}
      >
        Create
      </RoundedButton>
    </div>
  )
}

export default CreateButtons
