import { ChangeEvent, FunctionComponent, useState, ReactElement } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'

import { FilePageNode, FilePageType, Maybe } from '@src/graphql/types'
import theme from '@src/utils/theme'
import FileSheetsViewer from '../job-viewer/FileSheetsViewer'

const useStyles = makeStyles({
  documentPreviewPanel: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[200],
    height: '100%',
  },
  fileImage: {
    width: '100%',
  },
  emailBodyContainer: {
    width: '100%',
  },
  message: {
    whiteSpace: 'pre-wrap',
  },
})

type Props = {
  filePage: Maybe<FilePageNode>
}

const DocumentPreviewPanel: FunctionComponent<Props> = ({ filePage }) => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState(0)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTabChange = (_evt: ChangeEvent<any>, tabIndex: number): void => {
    setCurrentTab(tabIndex)
  }

  const getCurrentTabView = (): ReactElement => {
    if (filePage?.type === FilePageType.Excel) {
      return <FileSheetsViewer filePage={filePage!} />
    } else if (filePage?.type === FilePageType.ImagePdf) {
      return (
        <img
          className={classes.fileImage}
          src={filePage?.imageUrl || undefined}
          alt={filePage?.file?.filename}
        />
      )
    } else {
      return <InsertDriveFileOutlinedIcon />
    }
  }

  return (
    <Paper className={classes.documentPreviewPanel} square>
      <AppBar position='static' color='default'>
        <Tabs
          aria-label='Document Preview Tabs'
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='primary'
          centered
        >
          <Tab label='Document' aria-label='Document' />
          <Tab label='Message' aria-label='Message' />
        </Tabs>
      </AppBar>
      <Box display='flex' justifyContent='center' flexDirection='column' flex={1} m={3}>
        {currentTab === 0 ? (
          getCurrentTabView()
        ) : (
          <Paper className={classes.emailBodyContainer} square>
            <Box m={3}>
              <Typography variant='h3' gutterBottom>
                Subject
              </Typography>
              <Typography>{filePage?.file?.emailSubject || '[No subject]'}</Typography>
            </Box>

            <Box m={3}>
              <Typography variant='h3' gutterBottom>
                Body
              </Typography>
              <Typography className={classes.message}>{filePage?.file?.message}</Typography>
            </Box>
          </Paper>
        )}
      </Box>
    </Paper>
  )
}

export default DocumentPreviewPanel
