import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import theme from '@src/utils/theme'

const RoundedButton = withStyles({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    cursor: 'pointer',
    borderRadius: `${theme.spacing(2)}px`,
    maxHeight: `${theme.spacing(6)}px`,
  },
})(Button)

export default RoundedButton
