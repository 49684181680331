// the navbar at the top of the page
import { useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { AppBar, Box, Chip, Toolbar, Typography, alpha } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MUILink from '@material-ui/core/Link'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import HomeIcon from '@material-ui/icons/Home'
import PersonIcon from '@material-ui/icons/Person'
import PublishIcon from '@material-ui/icons/Publish'
import SettingsIcon from '@material-ui/icons/Settings'
import { makeStyles } from '@material-ui/styles'
import { Auth0AccessTokenContext } from '@src/auth/Auth0AccessTokenContext'
import LogoutButton from '@src/auth/LogoutButton'
import NotificationsButton from '@src/components/notifications/NotificationsButton'
import { COUNT_BY_JOB_STATUS } from '@src/graphql/queries/job'
import { Query } from '@src/graphql/types'
import { dataLayer } from '@src/utils/observability/useGoogleTagManager'
import ExpedockFullLogo from '@src/svg/ExpedockFullLogo'
import { RECON_MODAL_STYLES } from '@src/utils/app_constants'
import { getDeployEnvironmentOverride } from '@src/utils/environment'
import theme from '@src/utils/theme'
import { clsx } from 'clsx'
import { FunctionComponent, useContext, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useLocation } from 'react-use'

const useStyles = makeStyles({
  appBar: { zIndex: RECON_MODAL_STYLES.Z_INDEX - 1 },
  navbar: {
    '& a:link': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '& a:visited': {
      color: 'inherit',
      paddingRight: theme.spacing(5),
      textDecoration: 'none',
    },
    '& a:hover': {
      color: 'inherit',
      textDecoration: 'underline',
    },
  },
  navbarPrimaryLinks: {
    display: 'flex',
    flexDirection: 'row',
    '& a:link': {
      paddingRight: theme.spacing(5),
    },
    '& a:visited': {
      paddingRight: theme.spacing(5),
    },
    alignItems: 'center',
  },
  appBarLogo: {
    display: 'flex',
    alignItems: 'center',
  },
  spacer: {
    flexGrow: 1,
  },
  navIcon: {
    marginRight: theme.spacing(1),
  },
  navDivider: {
    marginLeft: theme.spacing(1),
    backgroundColor: alpha(theme.palette.primary.contrastText, 0.5),
  },
  avatar: {
    backgroundColor: 'transparent',
    boxShadow: `0 0 0 1px ${theme.palette.primary.contrastText}`,
    marginRight: theme.spacing(1),
  },
  navLink: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    '& span': {
      borderRadius: 4,
      padding: '2px',
      marginLeft: theme.spacing(1),
      background: theme.palette.background.paper,
      color: theme.palette.primary.main,
    },
  },
  selectedNavLink: {
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    '& span': {
      borderRadius: 4,
      padding: '2px',
      marginLeft: theme.spacing(1),
      background: theme.palette.primary.main,
      color: theme.palette.background.paper,
    },
  },
  homeButton: {
    padding: theme.spacing(2),
  },
  jobTasksGroup: {
    paddingRight: theme.spacing(4),
    '& a:nth-child(1)': {
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
    },
    '& a:nth-child(2)': {
      borderLeft: '0',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
    },
  },
  activeJobTaskButton: {
    backgroundColor: theme.palette.primary.dark,
  },
  logoCutoff: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    overflow: 'hidden',
  },
  profileLink: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: `${theme.spacing(1)}px !important`,
  },
})

const NavBar: FunctionComponent = () => {
  const { user } = useAuth0()
  const accessToken = useContext(Auth0AccessTokenContext)
  const classes = useStyles()

  const currPage = useLocation().pathname || '/'

  const { data: jobsStatusData } = useQuery<Pick<Query, 'countByJobStatus'>>(COUNT_BY_JOB_STATUS, {
    skip: !currPage.includes('/jobs'),
  })
  const statusCount = jobsStatusData?.countByJobStatus

  const showTasksNavbar =
    currPage.includes('/tasks') || currPage === '/' || currPage.includes('/admin')
  const isIndividualTaskPage = currPage.includes('/tasks/')
  const headerMapping: Record<string, Record<string, string>> = Object.freeze({
    '/jobs': { name: 'All', searchString: 'all', jobStatus: 'ALL' },
    '/jobs?status=todo': { name: 'Todo', searchString: 'todo', jobStatus: 'Todo' },
    '/jobs?status=inprogress': {
      name: 'In Progress',
      searchString: 'inprogress',
      jobStatus: 'InProgress',
    },
    '/jobs?status=qa': { name: 'QA', searchString: 'qa', jobStatus: 'QA' },
    '/jobs?status=confirmation': {
      name: 'Confirmation',
      searchString: 'confirmation',
      jobStatus: 'Confirmation',
    },
    '/jobs?status=done': { name: 'Done', searchString: 'done', jobStatus: 'Done' },
    '/jobs?status=deleted': {
      name: 'Deleted',
      searchString: 'deleted',
      jobStatus: 'Deleted',
    },
  })

  dataLayer({
    user_id: user?.email,
  })

  const [adminButtonAnchorEl, setAdminButtonAnchorEl] = useState(null as Element | null)

  const deployEnvironmentOverride = getDeployEnvironmentOverride()
  const handleClearDeployEnvironmentOverride = (): void => {
    window.localStorage.removeItem('deploy_env')
    const url = new URL(window.location.toString())
    url.searchParams.delete('deploy_env')
    window.location.replace(url.toString())
  }

  const getLinkClass = (pagePath: string, pageKey: string): string => {
    // This makes the navbar header selected only if we
    // are in that step of the extract process
    let linkClass = classes.navLink
    if (currPage === pagePath) {
      linkClass = classes.selectedNavLink
    } else if (currPage.includes(pageKey)) {
      linkClass = classes.selectedNavLink
    }
    return linkClass
  }

  return (
    <AppBar className={classes.appBar} position='static' data-testid='navbar'>
      <Toolbar className={classes.navbar}>
        <div className={classes.navbarPrimaryLinks}>
          <MUILink
            className={classes.appBarLogo}
            color='inherit'
            variant='h6'
            component={RouterLink}
            to={'/'}
          >
            <div className={classes.logoCutoff}>
              <ExpedockFullLogo width={226} height={64} />
            </div>
          </MUILink>
          <div className={classes.jobTasksGroup}>
            <Button
              size={'large'}
              variant='outlined'
              color='inherit'
              className={clsx({ [classes.activeJobTaskButton]: showTasksNavbar })}
              component={RouterLink}
              to={'/'}
              data-testid='tasks-button'
            >
              Tasks
            </Button>
            <Button
              size='large'
              variant='outlined'
              color='inherit'
              component={RouterLink}
              className={clsx({ [classes.activeJobTaskButton]: !showTasksNavbar })}
              to={'/jobs'}
            >
              Jobs
            </Button>
          </div>
          {isIndividualTaskPage && (
            <Box className={classes.homeButton}>
              <IconButton component={RouterLink} to='/'>
                <HomeIcon />
              </IconButton>
            </Box>
          )}
        </div>
        <div className={classes.spacer} />
        {!showTasksNavbar && (
          <Box display='inline-flex'>
            {Object.keys(headerMapping).map((pageName: string) => (
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                key={headerMapping[pageName].name}
              >
                <MUILink
                  className={classes.appBarLogo}
                  color='inherit'
                  variant='h6'
                  component={RouterLink}
                  to={pageName}
                >
                  <Typography
                    noWrap
                    className={getLinkClass(pageName, headerMapping[pageName].searchString)}
                  >
                    {headerMapping[pageName].name}
                    {
                      // 0 is a falsy value so the count won't appear on tabs with 0 count as intended
                      statusCount && statusCount[headerMapping[pageName].jobStatus] && (
                        <Typography noWrap display='inline' component='span' variant='body2'>
                          {statusCount[headerMapping[pageName].jobStatus]}
                        </Typography>
                      )
                    }
                  </Typography>
                </MUILink>
              </div>
            ))}
          </Box>
        )}
        <div className={classes.spacer} />
        {user && accessToken && <NotificationsButton />}
        {deployEnvironmentOverride && (
          <Chip label={deployEnvironmentOverride} onDelete={handleClearDeployEnvironmentOverride} />
        )}
        {/* TODO: Maybe fix potentially broken MUILink usage because for some reason they weren't used originally */}
        <MUILink
          className={classes.profileLink}
          component={RouterLink}
          to={'/profile'}
          data-testid='profile-link'
        >
          <Avatar className={classes.avatar}>
            <PersonIcon />
          </Avatar>
          <div>{user?.email}</div>
        </MUILink>
        <Divider orientation='vertical' flexItem className={classes.navDivider} />
        <MUILink component={RouterLink} to={'/file-ingest'}>
          <Button color='inherit' title='Ingest'>
            <PublishIcon className={classes.navIcon} />
          </Button>
        </MUILink>
        <Button color='inherit' onClick={(event) => setAdminButtonAnchorEl(event.currentTarget)}>
          <SettingsIcon />
        </Button>
        <Menu
          anchorEl={adminButtonAnchorEl}
          keepMounted
          open={!!adminButtonAnchorEl}
          onClose={() => setAdminButtonAnchorEl(null)}
        >
          <MUILink component={RouterLink} to={'/admin/company/'}>
            <MenuItem>Company</MenuItem>
          </MUILink>
          <MUILink component={RouterLink} to={'/admin/cargowise/'}>
            <MenuItem>Cargowise</MenuItem>
          </MUILink>
          <MUILink component={RouterLink} to={'/admin/cargowise/outbounds'}>
            <MenuItem>Cargowise Outbounds</MenuItem>
          </MUILink>
          <MUILink component={RouterLink} to={'/admin/api-partner/'}>
            <MenuItem>API Partner</MenuItem>
          </MUILink>
          <MUILink component={RouterLink} to={'/admin/data/sync'}>
            <MenuItem>Data Sync</MenuItem>
          </MUILink>
          <MUILink component={RouterLink} to={'/admin/data/cold-start'}>
            <MenuItem>Cold Start</MenuItem>
          </MUILink>
          <MUILink component={RouterLink} to={'/admin/user/'}>
            <MenuItem>Users</MenuItem>
          </MUILink>
        </Menu>
        <LogoutButton />
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
