import { useMemo } from 'react'
import { ApiPartnerInterface, CompanyNode, Maybe } from '@src/graphql/types'

type CompanyDataHookType = {
  apiPartners: ApiPartnerInterface[]
  usesChargeCodeV2: boolean
}

const useCompanyData = (company: Maybe<CompanyNode>): CompanyDataHookType => {
  const apiPartners = useMemo(
    () => (company?.apiPartners.edges.map((edge) => edge.node) || []) as ApiPartnerInterface[],
    [company],
  )
  const usesChargeCodeV2 = company?.usesChargeCodeV2 || false

  return {
    apiPartners,
    usesChargeCodeV2,
  }
}

export default useCompanyData
