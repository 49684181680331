import { FormControlLabel, Radio, RadioGroup, RadioProps, Typography } from '@material-ui/core'
import React, { type FC } from 'react'
import theme from '@src/utils/theme'
import { blue } from '@material-ui/core/colors'
import { startCase } from 'lodash'

type Props = {
  onChange: (value: string) => void
  options: string[]
  value: string
}

const RadioWithNoIcon = (props: RadioProps): JSX.Element => {
  return (
    <Radio
      style={{ display: 'none' }}
      color='default'
      icon={<></>}
      checkedIcon={<></>}
      {...props}
    />
  )
}

const TabStyleRadioGroup: FC<Props> = ({ onChange, options, value }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    onChange(value)
  }

  return (
    <RadioGroup
      value={value}
      onChange={handleChange}
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '4px',
        border: `1px solid ${blue['600']}`,
        width: 'fit-content',
        flexWrap: 'nowrap',
      }}
    >
      {options.map((option, i, { length }) => {
        const isSelected = value === option
        const isFirst = i === 0
        const isLast = i === length - 1

        return (
          <FormControlLabel
            key={option}
            style={{
              backgroundColor: isSelected ? theme.palette.primary.main : 'white',
              color: isSelected ? 'white' : blue['600'],
              padding: '0.4rem',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              borderRadius: isFirst ? '4px 0 0 4px' : isLast ? '0 4px 4px 0' : '0',
              margin: 0,
              textTransform: 'uppercase',
              borderRight: isLast ? 'none' : '1px solid ' + blue['600'],
            }}
            value={option}
            control={<RadioWithNoIcon />}
            label={
              <Typography variant='body1' style={{ fontSize: '12px', fontWeight: 500 }}>
                {startCase(option)}
              </Typography>
            }
          />
        )
      })}
    </RadioGroup>
  )
}

export default TabStyleRadioGroup
