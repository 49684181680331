import { RefObject } from 'react'
import {
  ApReconAutofillKey,
  FieldNode,
  FieldType,
  InputSoaRowMatchCriteria,
  Maybe,
} from '@src/graphql/types'
import { camelCase } from 'lodash'
import { FastHotTableRefValue } from '@src/components/fast-hot-table/FastHotTable'
import { SpreadsheetDataColumn, SpreadsheetDataRow } from '@src/utils/data-grid'
import { HotTable } from '@handsontable/react'
import { isFallback } from '@src/utils/enum'

export const SUBTOTAL_ROW_HEADER = 'ST'

export const replaceCellValues = (
  hotTableRef: RefObject<FastHotTableRefValue | undefined>,
  changes: [number, number, string | boolean][],
  source?: string,
): void => {
  hotTableRef.current?.hotInstance?.setDataAtCell(changes, source)
}

export type SoaRowFieldMapping = { [autofillKey: string]: string }

export const makeSoaRowFieldMappings = (
  columns: string[],
  rows: (string | null)[][],
  repeatableFieldAutofillKeyMap: Record<string, FieldNode>,
): SoaRowFieldMapping[] => {
  const columnIdxMapping = columns.reduce(
    (acc, col, idx) => {
      acc[col] = idx
      return acc
    },
    {} as Record<string, number>,
  )

  return rows.map((row) => {
    return Object.fromEntries(
      Object.entries(repeatableFieldAutofillKeyMap).map(([autofillKey, field]) => {
        const columnIdx = field && columnIdxMapping[field.name]
        return [camelCase(autofillKey), columnIdx != null ? row[columnIdx] : ''] as [string, string]
      }),
    )
  })
}

export const isCivJobType = (jobTypeName: string): boolean => {
  if (
    jobTypeName.toLowerCase().includes('commercial invoice') ||
    jobTypeName.toLowerCase().includes('civ')
  )
    return true
  return false
}

/**
 * Parses a string to a float if possible, otherwise returns fallbackValue, which is undefined by default
 */
export const maybeParseFloat = (
  value: Maybe<string> | undefined,
  fallbackValue?: number,
): number | null => {
  const parsed = parseFloat(value as string)
  return !isNaN(parsed) ? parsed : fallbackValue ?? null
}

export const getSubtotalsFromRows = (
  rows: SpreadsheetDataRow[],
  columns: SpreadsheetDataColumn[],
): SpreadsheetDataRow => {
  const numericFieldTypes = [
    FieldType.Decimal.valueOf(),
    FieldType.Integer.valueOf(),
    FieldType.Price.valueOf(),
  ]
  if (!rows.length) return []

  return rows.reduce(
    (subtotalRow, currentRow) => {
      const parsedRow = currentRow.map((value) => maybeParseFloat(value))
      const parsedSubtotalRow = subtotalRow.map((value) => maybeParseFloat(value))
      columns.forEach((col, idx) => {
        const colFieldType =
          col.fieldType && !isFallback(col.fieldType)
            ? col.fieldType.value.valueOf()
            : col.fieldType && isFallback(col.fieldType)
            ? col.fieldType.fallbackValue
            : null
        if (colFieldType && numericFieldTypes.includes(colFieldType)) {
          const val = parsedRow[idx]
          const currSubtotalValue = parsedSubtotalRow[idx]
          parsedSubtotalRow[idx] = val
            ? currSubtotalValue
              ? val + currSubtotalValue
              : val
            : parsedSubtotalRow[idx]
        }
      })
      const newSubtotalRow: SpreadsheetDataRow = parsedSubtotalRow.map((value) =>
        value ? value.toFixed(2) : null,
      )
      return newSubtotalRow
    },
    rows[0].map((_) => null),
  )
}

export const getSubtotalWidths = (table: HotTable | FastHotTableRefValue): number[] => {
  const tableInstance = table.hotInstance
  const numColumns = tableInstance.countCols()
  const colWidths = []
  for (let i = 0; i < numColumns; i++) {
    colWidths.push(tableInstance.getColWidth(i))
  }
  return colWidths
}

export const isSameShippingGroup = (
  row1: InputSoaRowMatchCriteria,
  row2: InputSoaRowMatchCriteria,
): boolean => {
  const excludeKeys = ['invoiceNo', 'rowIdx']
  const rowKeys = Object.keys(row1) as (keyof typeof row1)[]
  return rowKeys.some((key) => !!row1[key] && !excludeKeys.includes(key) && row1[key] === row2[key])
}

export const getInputSoaRowMatchCriteria = (
  columns: string[],
  rows: { data: (string | null)[]; rowIdx: number }[],
  repeatableFields: FieldNode[],
): InputSoaRowMatchCriteria[] => {
  const columnIdxMapping = columns.reduce(
    (acc, col, colIdx) => {
      const repeatableField = repeatableFields.find((field) => field.name === col)
      acc[repeatableField?.autofillKey ?? ''] = colIdx
      return acc
    },
    {} as Record<string, number>,
  )

  return rows.map((row) => {
    const { data, rowIdx } = row
    return {
      rowIdx,
      invoiceNo:
        columnIdxMapping[ApReconAutofillKey.InvoiceNumber.toLowerCase()] != null
          ? data[columnIdxMapping[ApReconAutofillKey.InvoiceNumber.toLowerCase()]]
          : '',
      refNo:
        columnIdxMapping[ApReconAutofillKey.ReferenceNumber.toLowerCase()] != null
          ? data[columnIdxMapping[ApReconAutofillKey.ReferenceNumber.toLowerCase()]]
          : '',
      hblNo:
        columnIdxMapping[ApReconAutofillKey.HblNumber.toLowerCase()] != null
          ? data[columnIdxMapping[ApReconAutofillKey.HblNumber.toLowerCase()]]
          : '',
      mblNo:
        columnIdxMapping[ApReconAutofillKey.MblNumber.toLowerCase()] != null
          ? data[columnIdxMapping[ApReconAutofillKey.MblNumber.toLowerCase()]]
          : '',
      carrierBookingNo:
        columnIdxMapping[ApReconAutofillKey.CarrierBookingNumber.toLowerCase()] != null
          ? data[columnIdxMapping[ApReconAutofillKey.CarrierBookingNumber.toLowerCase()]]
          : '',
      containerNo:
        columnIdxMapping[ApReconAutofillKey.ContainerNumber.toLowerCase()] != null
          ? data[columnIdxMapping[ApReconAutofillKey.ContainerNumber.toLowerCase()]]
          : '',
      consolNo:
        columnIdxMapping[ApReconAutofillKey.ConsolNumber.toLowerCase()] != null
          ? data[columnIdxMapping[ApReconAutofillKey.ConsolNumber.toLowerCase()]]
          : '',
    }
  })
}
