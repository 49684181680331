import {
  ApReconAttemptsHistoryQuery,
  JobTemplateReconType,
  ReconAttemptNode,
  ReconAttemptWithResultsAndMetadataFragment,
  SoaReconAttemptsHistoryQuery,
  useGetJobDataLiteQuery,
} from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import { useState, type FC } from 'react'
import { APReconHistory, SOAReconHistory } from './ReconTypeHistory'
import { Box, FormControlLabel, Switch, Typography } from '@material-ui/core'
import CenteredCircularProgress from '../centered-circular-progress/CenteredCircularProgress'
import InvoiceLineItemReconDialog from '../reconciliation-dialog/InvoiceLineItemReconDialog'

type ReconHistoryProps = {
  jobId: string
}

export type ApReconItems = ApReconAttemptsHistoryQuery['apReconAttemptsHistory']['results']
export type SoaReconItems = SoaReconAttemptsHistoryQuery['soaReconAttemptsHistory']['results']

export type ApReconItemsState = {
  type: JobTemplateReconType.Ap
  items: ApReconItems
}
export type SoaReconItemsState = {
  type: JobTemplateReconType.Soa
  items: SoaReconItems
}

// Only AP and SOA are allowed, for now.
// https://expedock.atlassian.net/browse/PD-8006
export const ALLOWED_RECON_TYPES_FOR_HISTORY = [JobTemplateReconType.Ap, JobTemplateReconType.Soa]

const ReconHistory: FC<ReconHistoryProps> = ({ jobId }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [getSavedReconsOnly, setGetSavedReconsOnly] = useState(false)
  const [reconItems, setReconItems] = useState<ApReconItemsState | SoaReconItemsState>()
  const [nextMarker, setNextMarker] = useState<string | null>(null)
  const [switchLoading, setSwitchLoading] = useState(false)
  const [selectedReconAttempt, setSelectedReconAttempt] =
    useState<ReconAttemptWithResultsAndMetadataFragment>()

  const handleRowClick = (reconAttempt: ReconAttemptWithResultsAndMetadataFragment): void => {
    setSelectedReconAttempt(reconAttempt)
  }

  const handleClose = (): void => {
    setSelectedReconAttempt(undefined)
  }

  const shownOnlyToggleCallback = (checked: boolean): void => {
    setSwitchLoading(true)
    setNextMarker(null)
    setGetSavedReconsOnly(checked)
  }

  const loadMoreReconHistory = (nextMarker: string | null | undefined): void => {
    setNextMarker(nextMarker ?? null)
  }

  const { data: jobData, loading: isJobLoading } = useGetJobDataLiteQuery({
    variables: { id: jobId },
    onCompleted: (jobLiteData) => {
      if (!ALLOWED_RECON_TYPES_FOR_HISTORY.includes(jobLiteData.job.jobTemplate.reconType)) {
        enqueueSnackbar(
          `Viewing the reconciliation history of recon type ${jobLiteData.job.jobTemplate.reconType} is not yet supported.`,
          {
            variant: 'error',
          },
        )
        return
      }
      setReconItems({
        type:
          jobLiteData.job.jobTemplate.reconType === JobTemplateReconType.Ap
            ? JobTemplateReconType.Ap
            : JobTemplateReconType.Soa,
        items: [],
      })
    },
  })

  if (isJobLoading) {
    return <CenteredCircularProgress />
  }

  if (!jobData) {
    return <div>Job not found</div>
  }

  if (!ALLOWED_RECON_TYPES_FOR_HISTORY.includes(jobData.job.jobTemplate.reconType)) {
    return (
      <div>
        Viewing the reconciliation history of reconciliation type&nbsp;
        {jobData.job.jobTemplate.reconType} not supported
      </div>
    )
  }

  const renderHistory = (): JSX.Element => {
    switch (reconItems?.type) {
      case JobTemplateReconType.Ap:
        return (
          <APReconHistory
            job={jobData.job}
            getSavedReconsOnly={getSavedReconsOnly}
            nextMarker={nextMarker}
            reconAttempts={reconItems.items}
            // workaround because TS doesn't detect the type of setReconItems properly
            setReconAttempts={(newItems) =>
              setReconItems({ type: JobTemplateReconType.Ap, items: newItems })
            }
            loadMoreReconHistory={loadMoreReconHistory}
            handleRowClick={handleRowClick}
            switchLoading={switchLoading}
            setSwitchLoading={setSwitchLoading}
          />
        )
      case JobTemplateReconType.Soa:
        return (
          <SOAReconHistory
            job={jobData.job}
            getSavedReconsOnly={getSavedReconsOnly}
            nextMarker={nextMarker}
            reconAsyncBatches={reconItems.items}
            // workaround because TS doesn't detect the type of setReconItems properly
            setReconAsyncBatches={(newItems) =>
              setReconItems({ type: JobTemplateReconType.Soa, items: newItems })
            }
            loadMoreReconHistory={loadMoreReconHistory}
            handleRowClick={handleRowClick}
            switchLoading={switchLoading}
            setSwitchLoading={setSwitchLoading}
          />
        )
      default:
        return <CenteredCircularProgress />
    }
  }

  return (
    <Box
      sx={{
        marginX: 'auto',
        paddingY: '4rem',
        maxWidth: '1440px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gridGap: '1rem',
        paddingX: '1rem',
      }}
    >
      <Typography variant='h1'>{jobData.job.name} Reconciliation History</Typography>
      <FormControlLabel
        control={
          <Switch
            onChange={(e) => {
              shownOnlyToggleCallback(e.target.checked)
            }}
            checked={getSavedReconsOnly}
          />
        }
        labelPlacement='start'
        label='Display "Shown to Customer" only'
        style={{ marginLeft: 0 }}
      />
      {renderHistory()}
      {selectedReconAttempt && (
        <InvoiceLineItemReconDialog
          reconAttempt={selectedReconAttempt as ReconAttemptNode}
          isOpen={!!selectedReconAttempt}
          closePopup={handleClose}
          overrideChargeDescription={false}
          icon='exit'
        />
      )}
    </Box>
  )
}

export default ReconHistory
