import { FunctionComponent, MutableRefObject, useRef } from 'react'
import { clsx } from 'clsx'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import { makeStyles } from '@material-ui/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'

import { FilePageNode, FilePageType } from '@src/graphql/types'
import { PAGE_ASSOC_DIMENSIONS } from '@src/utils/app_constants'
import { formatFilePreviewName } from '@src/utils/file'
import theme from '@src/utils/theme'
import TaskTitleReferenceIdAutocomplete from '../tasks/TaskTitleReferenceIdAutocomplete'

const useStyles = makeStyles({
  filePagesPanel: {
    display: 'flex',
    flexDirection: 'column',
    height: PAGE_ASSOC_DIMENSIONS.PAGE_GALLERY_HEIGHT,
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  dropdownField: {
    backgroundColor: 'white',
  },
  jobsDropdown: {
    minWidth: PAGE_ASSOC_DIMENSIONS.BASE_WIDTH,
  },
  jobTemplatesDropdown: {
    marginLeft: 'auto',
    minWidth: PAGE_ASSOC_DIMENSIONS.BASE_WIDTH,
  },
  imagePreviewContainer: {
    flex: 0,
    margin: theme.spacing(2),
    outline: 'none',
    border: `${PAGE_ASSOC_DIMENSIONS.IMAGE_BORDER_THICKNESS} solid transparent`,
  },
  selectedImage: {
    border: `${PAGE_ASSOC_DIMENSIONS.IMAGE_BORDER_THICKNESS} solid ${theme.palette.common.black}`,
  },
  clickableImage: {
    '&:hover': {
      cursor: 'pointer',
      border: `${PAGE_ASSOC_DIMENSIONS.IMAGE_BORDER_THICKNESS} solid ${theme.palette.common.black}`,
    },
  },
  imageOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(128,128,128,0.3)',
  },
  imagePreview: {
    width: PAGE_ASSOC_DIMENSIONS.BASE_WIDTH,
    minHeight: PAGE_ASSOC_DIMENSIONS.BASE_MIN_HEIGHT,
    maxHeight: '100%',
  },
  imageLabel: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(2)}px`,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '40px',
    backgroundColor: alpha(theme.palette.common.black, 0.5),
    color: theme.palette.common.white,
  },
  imageMark: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: theme.typography.fontSize * 4,
  },
  iconPreview: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: PAGE_ASSOC_DIMENSIONS.BASE_WIDTH,
    height: PAGE_ASSOC_DIMENSIONS.BASE_MIN_HEIGHT,
  },
})

type PageRef = MutableRefObject<HTMLDivElement | null>

type Props = {
  filePages: FilePageNode[]
  toggleSelectPageId: (filePageId: string) => void
  setSelectedTaskId: (id: string) => Promise<void>
  selectedFilePageIds: string[]
  filePageIdsBeingAssociated: string[]
}

const FilePagesPanel: FunctionComponent<Props> = ({
  filePages,
  setSelectedTaskId,
  toggleSelectPageId,
  selectedFilePageIds,
  filePageIdsBeingAssociated,
}) => {
  const classes = useStyles()
  const pageRefs = useRef([] as PageRef[])

  const searchDebounceTime = 500

  return (
    <Paper className={classes.filePagesPanel} square>
      <AppBar position='static' color='primary'>
        <Toolbar>
          <TaskTitleReferenceIdAutocomplete
            onChange={(task) => setSelectedTaskId(task.id)}
            className={classes.jobsDropdown}
            inputClassName={classes.dropdownField}
          />
        </Toolbar>
      </AppBar>

      <Box m={2} overflow='auto'>
        <Grid container>
          {filePages.map((filePage, idx) => {
            const isPageAlreadyAssociated = filePage.jobId != null
            const isPageBeingAssociated = filePageIdsBeingAssociated.includes(filePage.id)
            const isPageSelected = selectedFilePageIds.includes(filePage.id)
            const filePageName = formatFilePreviewName(filePage.file!.filename, filePage.pageNumber)
            pageRefs.current[idx] = { current: null }

            return (
              <Grid
                ref={pageRefs.current[idx]}
                key={`${filePage.id}-thumbnail`}
                className={clsx(classes.imagePreviewContainer, {
                  [classes.selectedImage]: isPageSelected,
                  [classes.clickableImage]: !isPageAlreadyAssociated && !isPageBeingAssociated,
                })}
                onClick={() => toggleSelectPageId(filePage.id)}
                item
                xs
                tabIndex={0}
              >
                <Box position='relative' width='100%' height='100%'>
                  {(isPageAlreadyAssociated || isPageBeingAssociated) && (
                    <div className={classes.imageOverlay} />
                  )}
                  {filePage.type === FilePageType.Other && (
                    <Box className={classes.iconPreview}>
                      <InsertDriveFileOutlinedIcon />
                    </Box>
                  )}
                  {filePage.type === FilePageType.Excel && (
                    <Box className={classes.iconPreview}>
                      <TableChartOutlinedIcon fontSize='large' />
                      Excel
                    </Box>
                  )}
                  {filePage.type === FilePageType.ImagePdf && (
                    <img
                      className={classes.imagePreview}
                      src={filePage?.imageUrl || undefined}
                      alt={filePageName}
                    />
                  )}

                  {isPageAlreadyAssociated ? (
                    <CheckCircleOutlineIcon className={classes.imageMark} color='primary' />
                  ) : (
                    isPageBeingAssociated && <HourglassEmptyIcon className={classes.imageMark} />
                  )}
                  <Box className={classes.imageLabel}>
                    <Typography color='inherit' noWrap>
                      {filePageName}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Paper>
  )
}

export default FilePagesPanel
