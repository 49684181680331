import { FC } from 'react'
import {
  BaseReconHistoryProps,
  ReconHistoryContainer,
  DisplayHistoryOrLoading,
  ReconItemSubheader,
  ReconItemHeader,
} from './ReconTypeHistory'
import { Box, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import {
  useSoaReconAttemptsHistoryQuery,
  GetJobDataLiteQuery,
  SoaReconAttemptsHistoryQuery,
  ReconAttemptWithResultsAndMetadataFragment,
  useReconAttemptsQuery,
  ReconResultType,
} from '@src/graphql/types'
import { parseDateString } from '@src/utils/date'
import { isFallback } from '@src/utils/enum'
import {
  orderByDateCreated,
  getSOAMetadataTable,
  SoaInvoiceReconResultNode,
} from '@src/utils/recon/recon'
import { SoaReconItems } from '.'
import CenteredCircularProgress from '../centered-circular-progress/CenteredCircularProgress'
import { ReconMetadataTable } from '../reconciliation-dialog/bulk-recon-dialog/BulkReconDialog'
import LoadMoreButton from './LoadMoreButton'
import ReconHistoryTable from './ReconHistoryTable'
import { formatMaybeApolloError } from '@src/utils/errors'
import { useSnackbar } from 'notistack'

type SOAReconHistoryProps = BaseReconHistoryProps & {
  reconAsyncBatches: SoaReconItems
  setReconAsyncBatches: (reconAsyncBatches: SoaReconItems) => void
}

const SOAReconHistory: FC<SOAReconHistoryProps> = ({
  job,
  getSavedReconsOnly,
  nextMarker,
  reconAsyncBatches,
  setReconAsyncBatches,
  loadMoreReconHistory,
  handleRowClick,
  switchLoading,
  setSwitchLoading,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const { data: reconAsyncBatchHistory, loading: soaReconHistoryLoading } =
    useSoaReconAttemptsHistoryQuery({
      variables: { jobId: job.id, getSavedOnly: getSavedReconsOnly, marker: nextMarker },
      onCompleted: (reconAsyncBatchHistory) => {
        const { soaReconAttemptsHistory } = reconAsyncBatchHistory
        if (soaReconAttemptsHistory.hasPrevious) {
          setReconAsyncBatches(reconAsyncBatches.concat(soaReconAttemptsHistory.results))
        } else {
          setReconAsyncBatches(soaReconAttemptsHistory.results)
        }
        setSwitchLoading(false)
      },
      onError: (e) => {
        enqueueSnackbar(`${formatMaybeApolloError(e)}`, {
          variant: 'warning',
        })
      },
    })

  return (
    <ReconHistoryContainer>
      <Box
        sx={{
          display: 'grid',
          gridGap: '3rem',
          width: '100%',
        }}
      >
        <DisplayHistoryOrLoading
          switchLoading={switchLoading}
          reconHistoryLoading={soaReconHistoryLoading}
        >
          {reconAsyncBatches.map((reconAsyncBatch) => {
            return (
              <SoaAccordion
                key={reconAsyncBatch.id}
                job={job}
                reconAsyncBatch={reconAsyncBatch}
                handleRowClick={handleRowClick}
              />
            )
          })}
        </DisplayHistoryOrLoading>
      </Box>
      <LoadMoreButton
        loading={soaReconHistoryLoading}
        hasNextPage={reconAsyncBatchHistory?.soaReconAttemptsHistory.hasNext ?? false}
        loadMoreCallback={() =>
          loadMoreReconHistory(reconAsyncBatchHistory?.soaReconAttemptsHistory.nextMarker)
        }
      />
    </ReconHistoryContainer>
  )
}

export const SoaAccordion: FC<{
  job: GetJobDataLiteQuery['job']
  reconAsyncBatch: SoaReconAttemptsHistoryQuery['soaReconAttemptsHistory']['results'][number]
  handleRowClick: (reconAttempt: ReconAttemptWithResultsAndMetadataFragment) => void
}> = ({ job, reconAsyncBatch, handleRowClick }) => {
  const date = parseDateString(reconAsyncBatch.dateCreated)
  const userCreated = reconAsyncBatch.userCreated.email

  const { data: reconAttemptsData, loading } = useReconAttemptsQuery({
    variables: {
      reconAsyncBatchId: reconAsyncBatch.id,
    },
  })

  const { reconAttempts } = reconAttemptsData ?? {}

  const metadataReconAttempts =
    reconAttempts?.filter((reconAttempt) => {
      return reconAttempt.reconResults.some(
        (reconResult) =>
          !isFallback(reconResult.type) &&
          reconResult.type?.value === ReconResultType.SoaTotalReconResult,
      )
    }) ?? []

  const lineItemReconAttempts =
    reconAttempts?.filter(({ id }) => {
      return !metadataReconAttempts.some((reconAttempt) => reconAttempt.id === id)
    }) ?? []

  /*
   * We order by date created because when recon attempts are saved for SOA jobs,
   * they are ordered by their line items order. We sort them by dateCreated because
   * The first recon attempt in the line items is also the first one created.
   */
  const orderedReconAttempts = orderByDateCreated(lineItemReconAttempts, 'asc')

  const soaMetadataTable = getSOAMetadataTable(
    metadataReconAttempts?.flatMap((reconAttempt) => {
      return reconAttempt.reconResults
    }) as SoaInvoiceReconResultNode[],
  )

  const isSaved = reconAttempts?.some((attempt) => attempt.dateSaved) ?? false

  return (
    <Box
      style={{
        display: 'grid',
        gridGap: '1rem',
      }}
    >
      <Accordion
        style={{
          width: '100%',
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <ReconItemHeader date={date} loading={loading} isSaved={isSaved} />
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: 'grid',
          }}
        >
          {loading && <CenteredCircularProgress />}
          {reconAttempts && (
            <ReconHistoryTable
              handleRowClick={handleRowClick}
              reconAttempts={orderedReconAttempts}
              job={job}
              reconItem={reconAsyncBatch}
              componentAboveTable={<ReconMetadataTable soaMetadataTable={soaMetadataTable} />}
              showHeader={false}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <ReconItemSubheader
        isSaved={isSaved}
        userEmail={userCreated}
        reconType={job.jobTemplate.reconType}
        reconItemId={reconAsyncBatch.id}
      />
    </Box>
  )
}

export default SOAReconHistory
