import { formatMaybeApolloError } from '@src/utils/errors'
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core'
import { updatePageFieldEditorState } from '@src/redux-features/document_editor'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useSnackbar } from 'notistack'
import {
  exportCevaJobToCsv,
  exportJobToExcel,
  exportJobToXML,
  exportJobToFieldMappingCsv,
  exportJobToRPAJson,
  exportJobToJson,
  exportJobToLineItemCsv,
} from '@src/utils/export'
import { toTitleCase } from '@src/utils/string'
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  JobNode,
  JobTemplateReconType,
  Maybe,
  Mutation,
  MutationSendShipmentOpsToCwArgs,
  Query,
  QueryJobArgs,
  ShipmentOpsConsolDetailsAutofillKey,
  ShipmentOpsImportDecAutofillKey,
  ShipmentOpsShipmentAutofillKey,
  FieldGroupNode,
  ShipmentOp,
  CwConnectorType,
  ApReconAutofillKey,
  CargowiseConfigNode,
  MutationSendBFileToCwArgs,
} from '@src/graphql/types'
import { FetchResult, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { JOB_NAME, JOB_FOR_EXPORT } from '@src/graphql/queries/job'
import { CSV_EXPORT_JOB_TYPES } from '@src/utils/app_constants'
import ReconciliationDialog from '@src/components/reconciliation-dialog/ReconciliationDialog'
import BatchReconciliationDialog from '@src/components/reconciliation-dialog/BatchReconciliationDialog'
import SendToCWDialog from '@src/components/SendToCWDialog'
import SendSOAToCWDialog from '@src/components/SendSOAToCWDialog'
import PostToCwDialog from '@src/components/PostToCWDialog'
import { SEND_B_FILE_TO_CW, SEND_SHIPMENT_OPS_TO_CW } from '@src/graphql/mutations/cargowise'
import { FieldRefRecord } from '@src/types/shipment_form'
import { MutableRefObject } from 'react'
import { getJobDocumentTypes, getDocumentTypesFieldGroups } from '@src/utils/shipment_form'
import { RootState } from '@src/utils/store'
import ProcessingLogDialog from '../ProcessingLogsDialog'
import CheckShipmentInfoDialog from '@src/components/CheckShipmentInfoDialog'
import BatchCheckShipmentInfoDialog from '../BatchCheckShipmentInfoDialog'

import { checkApReconMissingFields } from '@src/utils/errors'
import {
  selectNonRepeatableFieldValueMap,
  selectRepeatableFieldValueMap,
} from '@src/redux-features/document_editor/field'
import SoaReconOptionsDialog from '@src/components/reconciliation-dialog/SoaReconOptionsDialog'
import useSoaReconOverrideOptions from '@src/hooks/soa/use_soa_recon_override_options'
import { CwTargetModuleDisplayText } from '@src/utils/recon/ap_recon'
import ApiExportButtons from './ApiExportButtons'
import { isCargowiseConfig, isUniversalApi } from '@src/utils/api_partner'
import { useDialog } from 'muibox'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

const useStyles = makeStyles({
  // for some reason fullWidth applies to all children of ButtonGroups as well, so we need
  // to set this back to auto
  arrowDropdown: {
    width: 'auto',
  },
})

const reconTypes = Object.freeze({
  [JobTemplateReconType.Ap]: 'Invoice',
  [JobTemplateReconType.ArrivalNotice]: 'Arrival Notice',
  [JobTemplateReconType.Soa]: 'SOA',
})

type Props = {
  jobId: string
  fieldMapRef?: MutableRefObject<FieldRefRecord>
  fullWidth?: boolean
  validateAndSaveAll?: () => Promise<unknown>
  validateAndSaveAllSoa?: () => Promise<unknown>
}

const ExportButton: FunctionComponent<Props> = ({
  fieldMapRef,
  jobId,
  fullWidth,
  validateAndSaveAll,
  validateAndSaveAllSoa,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const client = useApolloClient()
  const { enqueueSnackbar } = useSnackbar()
  const exportAnchorRef = useRef(null as HTMLDivElement | null)
  const [processingLogs, setProcessingLogs] = useState(null as string | null)
  const [isExportOpen, setIsExportOpen] = useState(false)
  const [isSendToCWDialogOpen, setIsSendToCWDialogOpen] = useState(false)
  const [isPostToCWDialogOpen, setIsPostToCWDialogOpen] = useState(false)
  const [isProcessingLogsDialogOpen, setIsProcessingLogsDialogOpen] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [isReconciliationDialogOpen, setIsReconciliationDialogOpen] = useState<boolean>(false)
  const [isSendSOAToCWDialogOpen, setIsSendSOAToCWDialogOpen] = useState(false)
  const [isCheckShipmentInfoDialogOpen, setIsCheckShipmentInfoDialogOpen] = useState(false)
  const [isBatchCheckShipmentInfoDialogOpen, setIsBatchCheckShipmentInfoDialogOpen] =
    useState(false)

  const {
    overrideChargeDescription,
    setOverrideChargeDescription,
    disableSendDueDate,
    setDisableSendDueDate,
    reconAsLumpsum,
    toggleReconMatchCriteria,
    setReconAsLumpsum,
    reconcileMatchCriteria,
    isSoaReconOptionsDialogOpen,
    closeSoaReconOptionsDialog,
    openSoaReconOptionsDialog,
    isBatchReconciliationDialogOpen,
    closeSoaReconDialog,
    openSoaReconDialog,
  } = useSoaReconOverrideOptions()
  const enableLineItemsRowOrderPriority = useFeatureIsOn('line-items-row-order-priority')

  const { data: jobData } = useQuery<Pick<Query, 'job'>, QueryJobArgs>(JOB_NAME, {
    variables: { id: jobId },
  })

  const isCargowiseConfigMemo = useMemo(
    () => isCargowiseConfig(jobData?.job?.jobTemplate?.apiPartner),
    [jobData?.job?.jobTemplate?.apiPartner],
  )

  const isUniversalApiMemo = useMemo(
    () => isUniversalApi(jobData?.job?.jobTemplate?.apiPartner),
    [jobData?.job?.jobTemplate?.apiPartner],
  )

  const nonRepeatableFieldValueMap = useSelector((state: RootState) =>
    selectNonRepeatableFieldValueMap(state.documentEditor),
  )
  const repeatableFieldValueMap = useSelector((state: RootState) =>
    selectRepeatableFieldValueMap(state.documentEditor),
  )
  const [checkApMissingFields, setCheckApMissingFields] = useState(false)
  useEffect((): void => {
    if (!checkApMissingFields) {
      return
    }
    setCheckApMissingFields(false)
    let missingFieldsError = null
    if (nonRepeatableFieldValueMap && repeatableFieldValueMap) {
      missingFieldsError = checkApReconMissingFields(
        nonRepeatableFieldValueMap,
        repeatableFieldValueMap,
      )
    }
    if (missingFieldsError !== null) {
      enqueueSnackbar(missingFieldsError, { variant: 'error' })
    } else {
      setIsReconciliationDialogOpen(true)
    }
  }, [checkApMissingFields, enqueueSnackbar, nonRepeatableFieldValueMap, repeatableFieldValueMap])

  const saveAndValidateJob = useCallback(async (): Promise<void> => {
    if (validateAndSaveAll != null) {
      await validateAndSaveAll()
    }
  }, [validateAndSaveAll])

  const saveAndValidateJobSoa = useCallback(async (): Promise<void> => {
    if (validateAndSaveAllSoa != null) {
      await validateAndSaveAllSoa()
    }
  }, [validateAndSaveAllSoa])

  const [sendShipmentOpsJob] = useMutation<
    Pick<Mutation, 'sendShipmentOpsToCw'>,
    MutationSendShipmentOpsToCwArgs
  >(SEND_SHIPMENT_OPS_TO_CW)

  const [sendBFileToCWJob] = useMutation<
    Pick<Mutation, 'sendBFileToCw'>,
    MutationSendBFileToCwArgs
  >(SEND_B_FILE_TO_CW)

  const dialog = useDialog()

  const populateReferenceNumber = (referenceNumber: string, lowercaseAutofillKey: string): void => {
    const documentTypes = getJobDocumentTypes(jobData?.job || null)
    const fieldGroups = getDocumentTypesFieldGroups(documentTypes)
    const refNumberFieldGroup = fieldGroups.find(
      (fieldGroup: FieldGroupNode) => fieldGroup.autofillKey === lowercaseAutofillKey,
    )
    if (refNumberFieldGroup) {
      const fieldKey = refNumberFieldGroup!.fields!.edges![0]!.node!.key
      dispatch(
        updatePageFieldEditorState({
          [fieldKey]: referenceNumber,
        }),
      )
      fieldMapRef!.current[fieldKey]!.current!.setValue(referenceNumber)
    }
  }

  const getCwModuleFromRefMap = (): string => {
    const documentTypes = getJobDocumentTypes(jobData?.job || null)
    const fieldGroups = getDocumentTypesFieldGroups(documentTypes)
    const refNumberFieldGroup = fieldGroups.find(
      (fieldGroup: FieldGroupNode) =>
        fieldGroup.autofillKey === ApReconAutofillKey.CargowiseModule.toLowerCase(),
    )
    if (refNumberFieldGroup && fieldMapRef) {
      const fieldKey = refNumberFieldGroup!.fields!.edges![0]!.node!.key
      return fieldMapRef!.current[fieldKey]!.current!.element.current?.value as string
    } else {
      return ''
    }
  }

  const shipmentOpsTypes = jobData?.job!.jobTemplate!.shipmentOpsTypes || []
  const shipmentOpsText = shipmentOpsTypes
    ?.map((sot: Maybe<string>) => toTitleCase((sot || '').replace(/_/g, ' ')))
    .join(' + ')

  const showWarningIfNumNotFound = (
    resp: FetchResult<
      Pick<Mutation, 'sendShipmentOpsToCw'>,
      Record<string, string>,
      Record<string, string>
    >,
  ): void => {
    const shipmentNumber = resp?.data?.sendShipmentOpsToCw?.shipmentReferenceNumber
    const consolNumber = resp?.data?.sendShipmentOpsToCw?.consolReferenceNumber
    const importDecNumber = resp?.data?.sendShipmentOpsToCw?.importDecReferenceNumber

    if (shipmentOpsTypes.includes(ShipmentOp.Shipment) && !shipmentNumber) {
      enqueueSnackbar(
        'We failed to find the shipment reference number, but the shipment might have been created. Please login to CW to check',
        { variant: 'warning' },
      )
    }
    if (shipmentOpsTypes.includes(ShipmentOp.Consol) && !consolNumber) {
      enqueueSnackbar(
        'We failed to find the consol reference number, but the consol might have been created. Please login to CW to check',
        { variant: 'warning' },
      )
    }
    if (shipmentOpsTypes.includes(ShipmentOp.ImportDeclaration) && !importDecNumber) {
      enqueueSnackbar(
        'We failed to find the import declaration reference number, but the import declaration might have been created. Please login to CW to check',
        { variant: 'warning' },
      )
    }
  }

  const populateOpsJobFields = async (
    resp: FetchResult<
      Pick<Mutation, 'sendShipmentOpsToCw'>,
      Record<string, string>,
      Record<string, string>
    >,
  ): Promise<void> => {
    const shipmentNumber = resp?.data?.sendShipmentOpsToCw?.shipmentReferenceNumber
    const consolNumber = resp?.data?.sendShipmentOpsToCw?.consolReferenceNumber
    const importDecNumber = resp?.data?.sendShipmentOpsToCw?.importDecReferenceNumber
    const processingLog = resp?.data?.sendShipmentOpsToCw?.processingLog
    if (shipmentNumber || consolNumber || importDecNumber) {
      if (shipmentNumber) {
        enqueueSnackbar(`Shipment With Reference Number Created/Modified: ${shipmentNumber}`, {
          variant: 'success',
        })
        populateReferenceNumber(
          shipmentNumber,
          ShipmentOpsShipmentAutofillKey.ReferenceNumber.toLowerCase(),
        )
      }
      if (consolNumber) {
        enqueueSnackbar(`Consol With Reference Number Created/Modified: ${consolNumber}`, {
          variant: 'success',
        })
        populateReferenceNumber(
          consolNumber,
          ShipmentOpsConsolDetailsAutofillKey.ConsolNumber.toLowerCase(),
        )
      }
      if (importDecNumber) {
        enqueueSnackbar(
          `Import Declaration With Reference Number Created/Modified: ${importDecNumber}`,
          {
            variant: 'success',
          },
        )
        populateReferenceNumber(
          importDecNumber,
          ShipmentOpsImportDecAutofillKey.ImportDecReferenceNo.toLowerCase(),
        )
      }
      if (processingLog) {
        setProcessingLogs(processingLog)
        setIsProcessingLogsDialogOpen(true)
      }
      await saveAndValidateJob()
    }
  }

  const handleShipmentOpsExport = async (): Promise<void> => {
    try {
      const resp = await sendShipmentOpsJob({
        variables: {
          jobId,
        },
      })
      showWarningIfNumNotFound(resp)
      await populateOpsJobFields(resp)
    } catch (error) {
      enqueueSnackbar(
        `Error exporting job to Cargowise: ${formatMaybeApolloError(
          error,
        )}. Please make sure all fields and tables are filled in correctly and try again`,
        { variant: 'error' },
      )
    }
  }

  const handleSendToCW = async (): Promise<void> => {
    setIsSendToCWDialogOpen(true)
  }

  const handlePostToCW = async (): Promise<void> => {
    setIsPostToCWDialogOpen(true)
  }

  const saveAndValidateWrapperSoa = async (
    actionFn: () => Promise<void>,
    action: string,
  ): Promise<void> => {
    setIsExporting(true)
    try {
      await saveAndValidateJobSoa()
    } catch (error) {
      enqueueSnackbar(`Error validating and saving job: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
      setIsExporting(false)
      return
    }
    try {
      await actionFn()
    } catch (error) {
      enqueueSnackbar(`Error ${action}: ${formatMaybeApolloError(error)}`, { variant: 'error' })
    }
    setIsExporting(false)
  }

  const saveAndValidateWrapper = async (
    actionFn: () => Promise<void>,
    action: string,
  ): Promise<void> => {
    setIsExporting(true)
    try {
      await saveAndValidateJob()
    } catch (error) {
      enqueueSnackbar(`Error validating and saving job: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
      setIsExporting(false)
      return
    }
    try {
      await actionFn()
    } catch (error) {
      enqueueSnackbar(`Error ${action}: ${formatMaybeApolloError(error)}`, { variant: 'error' })
    }
    setIsExporting(false)
  }

  const handleExport = async (
    exportFn: (job: JobNode, enableLineItemsRowOrderPriority: boolean) => unknown,
    enableLineItemsRowOrderPriority = false,
  ): Promise<void> => {
    setIsExporting(true)
    try {
      await saveAndValidateJob()
    } catch (error) {
      enqueueSnackbar(`Error validating and saving job: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
      setIsExporting(false)
      return
    }
    try {
      const { data: jobExportData } = await client.query({
        query: JOB_FOR_EXPORT,
        // otherwise, shipmentform will refresh due to the new job data, which is unnecessary
        fetchPolicy: 'no-cache',
        variables: {
          id: jobId,
        },
      })
      exportFn(jobExportData.job!, enableLineItemsRowOrderPriority)
    } catch (error) {
      enqueueSnackbar(`Error exporting job: ${formatMaybeApolloError(error)}`, { variant: 'error' })
    }
    setIsExporting(false)
  }

  const handleReconcile = async (): Promise<void> => {
    try {
      if (
        jobData?.job!.jobTemplate!.reconType === JobTemplateReconType.Ap &&
        (jobData?.job!.jobTemplate?.apiPartner as CargowiseConfigNode)?.connectorType ===
          CwConnectorType.Eadaptor
      ) {
        setCheckApMissingFields(true)
      } else {
        setIsReconciliationDialogOpen(true)
      }
    } catch (error) {
      return
    }
  }

  const batchReconSOA = async (): Promise<void> => {
    openSoaReconOptionsDialog()
  }

  const sendSOAToCW = async (): Promise<void> => {
    setIsSendSOAToCWDialogOpen(true)
  }

  const handleCheckShipmentInfo = async (): Promise<void> => {
    setIsCheckShipmentInfoDialogOpen(true)
  }

  const handleBatchCheckShipmentInfo = async (): Promise<void> => {
    setIsBatchCheckShipmentInfoDialogOpen(true)
  }

  const sendBFileToCW = async (): Promise<void> => {
    try {
      enqueueSnackbar('Uploading B-File to CW. This may take a few minutes.', { variant: 'info' })
      const { data } = await sendBFileToCWJob({
        variables: {
          jobId,
        },
      })
      const { cdecReferenceNumber, consolReferenceNumber, shipmentReferenceNumber } =
        data?.sendBFileToCw || {}
      if (consolReferenceNumber) {
        populateReferenceNumber(consolReferenceNumber, 'consol_number')
      }
      if (shipmentReferenceNumber) {
        populateReferenceNumber(shipmentReferenceNumber, 'reference_number')
        if (cdecReferenceNumber)
          populateReferenceNumber(cdecReferenceNumber, 'cdec_reference_number')
      } else if (cdecReferenceNumber) {
        populateReferenceNumber(cdecReferenceNumber, 'reference_number')
      }
      void saveAndValidateJob()
      enqueueSnackbar('B-File uploaded to CW successfully.', { variant: 'success' })
    } catch (error) {
      await dialog.alert({
        title: 'Error during CW Export',
        message: (
          <>
            <Typography variant='body1'>
              The following error was encountered while sending to CW:
            </Typography>
            {formatMaybeApolloError(error)
              .split('\n')
              .map((log: string, idx) => {
                return (
                  <blockquote key={`${idx}`}>
                    <Typography variant='body2'>{log}</Typography>
                  </blockquote>
                )
              })}
            <Typography variant='body2'>
              Changes may have been partially applied in Cargowise. Please check on Cargowise
              whether any unintended changes have occurred.
            </Typography>
          </>
        ),
      })
    }
  }

  return (
    <>
      <ButtonGroup variant='outlined' ref={exportAnchorRef} fullWidth={fullWidth} color='primary'>
        <Button
          id='save-and-export'
          onClick={() => setIsExportOpen(true)}
          data-testid='save-and-export-button'
        >
          Save All &amp; Export
        </Button>
        <Button
          className={classes.arrowDropdown}
          onClick={() => setIsExportOpen(true)}
          disabled={isExporting}
          data-testid='open-export-menu-btn'
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={isExportOpen}
        anchorEl={exportAnchorRef.current}
        transition
        disablePortal
        placement='top-end'
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center  bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setIsExportOpen(false)}>
                  <MenuList>
                    <MenuItem
                      onClick={() => handleExport(exportJobToJson)}
                      data-testid='export-job-as-json'
                    >
                      Download JSON
                    </MenuItem>
                    <MenuItem onClick={() => handleExport(exportJobToRPAJson)}>
                      Download JSON - RPA
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleExport(exportJobToExcel, enableLineItemsRowOrderPriority)
                      }
                      data-testid='export-job-as-excel'
                    >
                      Download XLSX (Excel)
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleExport(exportJobToXML)}
                      data-testid='export-job-as-xml'
                    >
                      Download XML
                    </MenuItem>
                    {isUniversalApiMemo && (
                      <ApiExportButtons
                        job={jobData!.job}
                        getCwModuleFromRefMap={getCwModuleFromRefMap}
                        saveAndValidateWrapper={saveAndValidateWrapper}
                        saveAndValidateWrapperSoa={saveAndValidateWrapperSoa}
                        handleReconcile={handleReconcile}
                      />
                    )}
                    {isCargowiseConfigMemo && (
                      <>
                        {shipmentOpsTypes?.length > 0 &&
                          jobData?.job!.jobTemplate!.reconType !== JobTemplateReconType.BFile && (
                            <MenuItem
                              onClick={() =>
                                saveAndValidateWrapper(
                                  handleShipmentOpsExport,
                                  `creating ${shipmentOpsText} on CW`,
                                )
                              }
                            >
                              Create {shipmentOpsText} on CW
                            </MenuItem>
                          )}
                        {(jobData?.job!.jobTemplate!.reconType === JobTemplateReconType.Ap ||
                          shipmentOpsTypes?.length > 0) && (
                          <MenuItem
                            onClick={async () =>
                              saveAndValidateWrapper(
                                handleCheckShipmentInfo,
                                'checking shipment info',
                              )
                            }
                            data-testid='open-check-shipment-info-btn'
                          >
                            Check Shipment Info
                          </MenuItem>
                        )}
                        {!!jobData?.job?.jobTemplate?.reconType &&
                          ![
                            JobTemplateReconType.None,
                            JobTemplateReconType.Soa,
                            JobTemplateReconType.BFile,
                          ].includes(jobData.job.jobTemplate.reconType) && (
                            <MenuItem
                              onClick={() =>
                                saveAndValidateWrapper(handleSendToCW, `${'sending to CW'}`)
                              }
                              data-testid='open-send-to-cw-btn'
                            >
                              Send to CW
                            </MenuItem>
                          )}
                        {jobData?.job!.jobTemplate!.reconType === JobTemplateReconType.Ap &&
                          getCwModuleFromRefMap() &&
                          getCwModuleFromRefMap() !==
                            CwTargetModuleDisplayText.FORWARDING_CONSOL && (
                            <MenuItem
                              onClick={() =>
                                saveAndValidateWrapper(handlePostToCW, `${'posting to CW'}`)
                              }
                              data-testid='open-post-to-cw-btn'
                            >
                              Post to CW
                            </MenuItem>
                          )}
                      </>
                    )}
                    {jobData?.job!.jobTemplate!.name === CSV_EXPORT_JOB_TYPES.ASCENT_INVOICE && (
                      <MenuItem onClick={() => handleExport(exportJobToFieldMappingCsv)}>
                        Export Invoice Header CSV
                      </MenuItem>
                    )}
                    {jobData?.job!.jobTemplate!.name === CSV_EXPORT_JOB_TYPES.ASCENT_INVOICE && (
                      <MenuItem onClick={() => handleExport(exportJobToLineItemCsv)}>
                        Export Invoice Line Item CSV
                      </MenuItem>
                    )}
                    {(
                      [
                        CSV_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_2HR,
                        CSV_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_4HR,
                        CSV_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_12HR,
                        CSV_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_24HR,
                      ] as string[]
                    ).includes(jobData?.job?.jobTemplate?.name || '') && (
                      <MenuItem
                        onClick={() =>
                          handleExport(exportCevaJobToCsv, enableLineItemsRowOrderPriority)
                        }
                      >
                        Export CEVA CSV
                      </MenuItem>
                    )}
                    {(jobData?.job!.jobTemplate!.reconType === JobTemplateReconType.Ap ||
                      jobData?.job!.jobTemplate!.reconType ===
                        JobTemplateReconType.ArrivalNotice) && (
                      <MenuItem
                        id='recon-job'
                        data-testid='open-reconcile-ap-btn'
                        onClick={() => saveAndValidateWrapper(handleReconcile, 'reconciling')}
                      >
                        Reconcile {reconTypes[jobData!.job!.jobTemplate!.reconType]}
                      </MenuItem>
                    )}
                    {/* TODO: batch recon save the main table + metadata w/out the other tables here */}
                    {jobData?.job!.jobTemplate!.reconType === JobTemplateReconType.Soa && (
                      <MenuItem
                        id='soa-batch-recon'
                        onClick={() => saveAndValidateWrapperSoa(batchReconSOA, 'reconciling')}
                      >
                        Batch Reconcile {reconTypes[jobData!.job!.jobTemplate!.reconType]}
                      </MenuItem>
                    )}
                    {isCargowiseConfigMemo && (
                      <>
                        {jobData?.job!.jobTemplate!.reconType === JobTemplateReconType.Soa && (
                          <MenuItem
                            id='soa-batch-check-shipment'
                            onClick={async () =>
                              saveAndValidateWrapperSoa(
                                handleBatchCheckShipmentInfo,
                                'checking shipment info',
                              )
                            }
                          >
                            Batch Check Shipment
                          </MenuItem>
                        )}
                        {jobData?.job!.jobTemplate!.reconType === JobTemplateReconType.Soa && (
                          <MenuItem
                            id='soa-batch-send'
                            onClick={() =>
                              saveAndValidateWrapperSoa(sendSOAToCW, 'sending SOA draft to CW')
                            }
                          >
                            Send SOA draft to CW
                          </MenuItem>
                        )}
                        {jobData?.job!.jobTemplate!.reconType === JobTemplateReconType.BFile && (
                          <MenuItem
                            id='bfile-send'
                            onClick={() =>
                              saveAndValidateWrapper(sendBFileToCW, 'sending B-File to CW')
                            }
                          >
                            Create Customs Declaration on CW
                          </MenuItem>
                        )}
                      </>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )
        }}
      </Popper>
      {isReconciliationDialogOpen && (
        <ReconciliationDialog
          isOpen={isReconciliationDialogOpen}
          close={() => setIsReconciliationDialogOpen(false)}
          jobId={jobId}
          reconType={
            jobData!.job!.jobTemplate!.reconType as
              | JobTemplateReconType.Ap
              | JobTemplateReconType.ArrivalNotice
          }
        />
      )}
      {isBatchReconciliationDialogOpen && (
        <BatchReconciliationDialog
          isOpen={isBatchReconciliationDialogOpen}
          closePopup={closeSoaReconDialog}
          openSoaReconOptionsDialog={openSoaReconOptionsDialog}
          jobId={jobData!.job!.id!}
          overrideChargeDescription={overrideChargeDescription}
          disableSendDueDate={disableSendDueDate}
          reconcileMatchCriteria={reconcileMatchCriteria}
          reconAsLumpsum={reconAsLumpsum}
        />
      )}
      {isSoaReconOptionsDialogOpen && (
        <SoaReconOptionsDialog
          jobId={jobId}
          overrideChargeDescription={overrideChargeDescription}
          setOverrideChargeDescription={setOverrideChargeDescription}
          disableSendDueDate={disableSendDueDate}
          setDisableSendDueDate={setDisableSendDueDate}
          reconcileMatchCriteria={reconcileMatchCriteria}
          toggleReconcileMatchCriteria={toggleReconMatchCriteria}
          reconAsLumpsum={reconAsLumpsum}
          setReconAsLumpsum={setReconAsLumpsum}
          reconcileSoa={openSoaReconDialog}
          closeDialog={closeSoaReconOptionsDialog}
        />
      )}
      {isSendSOAToCWDialogOpen && (
        <SendSOAToCWDialog
          isOpen={isSendSOAToCWDialogOpen}
          close={() => setIsSendSOAToCWDialogOpen(false)}
          jobId={jobData!.job!.id}
        />
      )}
      {isSendToCWDialogOpen && (
        <SendToCWDialog
          isOpen={isSendToCWDialogOpen}
          close={() => setIsSendToCWDialogOpen(false)}
          jobId={jobId}
          reconType={
            jobData!.job!.jobTemplate!.reconType as
              | JobTemplateReconType.Ap
              | JobTemplateReconType.ArrivalNotice
          }
        />
      )}
      {isPostToCWDialogOpen && (
        <PostToCwDialog
          isOpen={isPostToCWDialogOpen}
          close={() => setIsPostToCWDialogOpen(false)}
          jobId={jobId}
          reconType={
            jobData!.job!.jobTemplate!.reconType as
              | JobTemplateReconType.Ap
              | JobTemplateReconType.ArrivalNotice
          }
        />
      )}
      {isCheckShipmentInfoDialogOpen && (
        <CheckShipmentInfoDialog
          isOpen={isCheckShipmentInfoDialogOpen}
          close={() => setIsCheckShipmentInfoDialogOpen(false)}
          jobId={jobId}
          handleReconcile={handleReconcile}
        />
      )}
      {isBatchCheckShipmentInfoDialogOpen && (
        <BatchCheckShipmentInfoDialog
          isOpen={isBatchCheckShipmentInfoDialogOpen}
          close={() => setIsBatchCheckShipmentInfoDialogOpen(false)}
          jobId={jobId}
        />
      )}
      {processingLogs && isProcessingLogsDialogOpen && (
        <ProcessingLogDialog
          close={() => {
            setIsProcessingLogsDialogOpen(false)
            setProcessingLogs(null)
          }}
          processingLog={processingLogs}
        />
      )}
    </>
  )
}

export default ExportButton
