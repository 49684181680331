// TODO: copy-pasted to web/ -- should use shared library instead. Be sure to replicate changes in both places
import { Fragment, ReactElement } from 'react'
import { FindModelCriteria, getAllFindModelCriteria } from '@src/utils/recon/find_model_criteria'

type Props = {
  findModelCriteriaItems: FindModelCriteria[]
}

/**
 * For cases where we want to show an abbreviated summary of all
 * search filters used across all the find shipment results
 * (eg more space-constrained areas)
 */
export const FindModelCriteriaSummary = ({ findModelCriteriaItems }: Props): ReactElement => {
  const { referenceNo, carrierBookingNo, mblNo, hblNo, orderNo, consolNo, containerNo } =
    getAllFindModelCriteria(findModelCriteriaItems)
  const shipmentFilterElements = [
    referenceNo && (
      <>
        Reference No. <strong>{referenceNo.toUpperCase()}</strong>
      </>
    ),
    hblNo && (
      <>
        HBL No. <strong>{hblNo.toUpperCase()}</strong>
      </>
    ),
    mblNo && (
      <>
        MBL No. <strong>{mblNo.toUpperCase()}</strong>
      </>
    ),
    carrierBookingNo && (
      <>
        Carrier Booking No. <strong>{carrierBookingNo.toUpperCase()}</strong>
      </>
    ),
    orderNo && (
      <>
        Order No. <strong>{orderNo.toUpperCase()}</strong>
      </>
    ),
    consolNo && (
      <>
        Consol No. <strong>{consolNo.toUpperCase()}</strong>
      </>
    ),
    containerNo && (
      <>
        Container No. <strong>{containerNo.toUpperCase()}</strong>
      </>
    ),
  ].filter((findShipmentFilterElement) => !!findShipmentFilterElement) as JSX.Element[]

  return shipmentFilterElements.length > 0 ? (
    <>
      {shipmentFilterElements.map((el, idx) => (
        <Fragment key={idx}>{el} </Fragment>
      ))}
    </>
  ) : (
    <strong>No filters provided</strong>
  )
}
