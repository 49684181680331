import { CompanyNode, Maybe, Scalars, UserRole } from '@src/graphql/types'
import { Dispatch, SetStateAction } from 'react'
import { DashboardAutocompleteOption } from '@src/pages/admin/user/default-dashboards-whitelist/DashboardNameAutocomplete'

export type FormError = {
  company: string
  operatorCompanies: string
  roles: string
}

export type FormValues = {
  company: null | CompanyNode
  email: string
  name: string
  roles: UserRole[] | UserRole
  operatorCompanies: CompanyNode[]
  metabaseDashboardId: string | Maybe<string>
  salesReps: string[]
  operationsReps: string[]
  branches: string[]
  departments: string[]
  onlyViewAssignedShipments: Scalars['Boolean']['input']
  useOperatorCompanies: Scalars['Boolean']['input']
  emailVerified: Scalars['Boolean']['input']
  // Dashboard modules
  accounting: DashboardAutocompleteOption[]
  businessPerformance: DashboardAutocompleteOption[]
  explore: DashboardAutocompleteOption[]
  dashboard: DashboardAutocompleteOption[]
  operations: DashboardAutocompleteOption[]
  sales: DashboardAutocompleteOption[]
}

export const validateFields = (
  data: FormValues,
  setFormError: Dispatch<SetStateAction<FormError>>,
): boolean => {
  let isValid = true
  const errorMessage: FormError = { company: '', roles: '', operatorCompanies: '' }
  const hasClientRole = Array.isArray(data.roles)
    ? data.roles.includes(UserRole.Client)
    : data.roles === UserRole.Client
  const hasClientAdminRole = Array.isArray(data.roles)
    ? data.roles.includes(UserRole.ClientAdmin)
    : data.roles === UserRole.ClientAdmin
  if (hasClientRole && hasClientAdminRole) {
    errorMessage.roles = 'A user can only be assigned either CLIENT or CLIENT_ADMIN, but not both.'
    isValid = false
  }
  if (data.company && !hasClientRole && !hasClientAdminRole) {
    errorMessage.roles =
      "Please select CLIENT or CLIENT_ADMIN for the user's roles to correctly assign the user to a dashboard company."
    isValid = false
  }
  if ((hasClientRole || hasClientAdminRole) && !data.company) {
    errorMessage.company = 'Dashboard company cannot be empty for a CLIENT or CLIENT_ADMIN.'
    isValid = false
  }
  if (data.useOperatorCompanies && !data.operatorCompanies?.length) {
    errorMessage.operatorCompanies =
      'Please select at least one operator company if the switch below is enabled.'
    isValid = false
  }
  setFormError(errorMessage)
  return isValid
}

export const isExpedockUser = (email: string): boolean => {
  /*
   * Aligned with is_expedock_user function in cauldron/cauldron/users/services.py
   *
   * TODO: Workaround to derive customer from email, we assume emails with '+' aliases
   * are customers. Should be derived off roles once we figure out the DB User
   * and Auth0 out-of-sync situation.
   */
  return email.endsWith('@expedock.com') && !email.includes('+')
}
