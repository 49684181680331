import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState } from 'react'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import theme from '@src/utils/theme'
import ApiPartnerTable from '@src/components/admin/admin-api-partner/ApiPartnerTable'
import { Box, Button } from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/client'
import {
  ApiPartnerNode,
  Mutation,
  MutationCreateApiPartnerArgs,
  MutationDeleteApiPartnerArgs,
  MutationTestApiPartnerArgs,
  MutationUpdateApiPartnerArgs,
  Query,
} from '@src/graphql/types'
import { GET_ALL_API_PARTNERS } from '@src/graphql/queries/api'
import CreateUpdateApiPartnerDialog, {
  ApiPartnerFormValues,
} from '@src/components/admin/admin-api-partner/CreateUpdateApiPartnerDialog'
import {
  CREATE_API_PARTNER,
  DELETE_API_PARTNER,
  UPDATE_API_PARTNER,
  TEST_API_PARTNER,
} from '@src/graphql/mutations/api'

import { TabContext, TabPanel } from '@material-ui/lab'
const useStyles = makeStyles({
  button: {
    marginBottom: theme.spacing(2),
  },
  tabRoot: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    opacity: 1,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
})

enum TabValues {
  API_PARTNER = 'API Partner',
}

const ApiPartnerAdminPage: FunctionComponent = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { data: apiPartners, refetch: refetchApiPartners } =
    useQuery<Pick<Query, 'apiPartners'>>(GET_ALL_API_PARTNERS)
  const [openCreateUpdateApiPartnerDialog, setOpenCreateUpdateApiPartnerDialog] = useState(false)
  const [selectedApiPartner, setSelectedApiPartner] = useState(null as ApiPartnerNode | null)
  const [apiPartnerAction, setApiPartnerAction] = useState(
    () => (async () => {}) as (credential: ApiPartnerFormValues) => Promise<void>,
  )
  const [deleteApiPartner] = useMutation<
    Pick<Mutation, 'deleteApiPartner'>,
    MutationDeleteApiPartnerArgs
  >(DELETE_API_PARTNER, {
    refetchQueries: [{ query: GET_ALL_API_PARTNERS }],
    awaitRefetchQueries: true,
  })
  const [createApiPartner] = useMutation<
    Pick<Mutation, 'createApiPartner'>,
    MutationCreateApiPartnerArgs
  >(CREATE_API_PARTNER, {
    refetchQueries: [{ query: GET_ALL_API_PARTNERS }],
    awaitRefetchQueries: true,
  })
  const [updateApiPartner] = useMutation<
    Pick<Mutation, 'updateApiPartner'>,
    MutationUpdateApiPartnerArgs
  >(UPDATE_API_PARTNER, {
    refetchQueries: [{ query: GET_ALL_API_PARTNERS }],
    awaitRefetchQueries: true,
  })
  const [testApiPartner] = useMutation<
    Pick<Mutation, 'testApiPartner'>,
    MutationTestApiPartnerArgs
  >(TEST_API_PARTNER)

  const handleCreateApiPartner = async (apiPartnerForm: ApiPartnerFormValues): Promise<void> => {
    try {
      const { jobTemplates, ...rest } = apiPartnerForm
      await createApiPartner({
        variables: {
          inputApiPartner: {
            jobTemplateIds: jobTemplates?.map((jobTemplate) => jobTemplate.id) || [],
            ...rest,
            name: rest.name || 'API-Connected System',
          },
        },
      })
      enqueueSnackbar('Successfully created API Partner', { variant: 'success' })
      await refetchApiPartners()
    } catch (error) {
      enqueueSnackbar(
        `Encountered an error while creating API Partner: ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    }
  }

  const onCreateApiPartner = (): void => {
    setSelectedApiPartner(null)
    setApiPartnerAction(() => handleCreateApiPartner)
    setOpenCreateUpdateApiPartnerDialog(true)
  }

  const onUpdateApiPartner = (apiPartner: ApiPartnerNode): void => {
    const handleUpdateApiPartner = async (apiPartnerForm: ApiPartnerFormValues): Promise<void> => {
      const { jobTemplates, ...rest } = apiPartnerForm
      try {
        await updateApiPartner({
          variables: {
            id: apiPartner.id,
            inputApiPartner: {
              jobTemplateIds: jobTemplates?.map((jobTemplate) => jobTemplate.id) || [],
              ...rest,
              name: rest.name || 'API-Connected System',
            },
          },
        })
        enqueueSnackbar('Successfully updated API Partner', { variant: 'success' })
      } catch (error) {
        enqueueSnackbar(
          `Encountered an error while updating API Partner: ${formatMaybeApolloError(error)}`,
          {
            variant: 'error',
          },
        )
      }
    }
    setSelectedApiPartner(apiPartner)
    setApiPartnerAction(() => handleUpdateApiPartner)
    setOpenCreateUpdateApiPartnerDialog(true)
  }

  const onDeleteApiPartner = async (id: string): Promise<void> => {
    try {
      await deleteApiPartner({ variables: { apiPartnerId: id } })
      await refetchApiPartners()
    } catch (error) {
      enqueueSnackbar(
        `Encountered an error while deleting API Partner: ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    }
  }

  const onTestApiPartner = async (apiPartnerId: string): Promise<void> => {
    try {
      await testApiPartner({ variables: { apiPartnerId } })
      enqueueSnackbar('API Partner test successful!', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar(
        `Encountered an error while testing API Partner: ${formatMaybeApolloError(err)}`,
        {
          variant: 'error',
        },
      )
    }
  }

  return (
    <Box p={3}>
      <TabContext value={TabValues.API_PARTNER}>
        <Box pl={7}>
          <Typography variant='h3'>API Partner</Typography>
        </Box>
        <TabPanel value={TabValues.API_PARTNER}>
          <Box p={3}>
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={onCreateApiPartner}
            >
              Create API Partner
            </Button>
            <ApiPartnerTable
              apiPartners={apiPartners?.apiPartners || []}
              onDelete={onDeleteApiPartner}
              onUpdate={onUpdateApiPartner}
              onTest={onTestApiPartner}
            />
          </Box>
        </TabPanel>
      </TabContext>
      {openCreateUpdateApiPartnerDialog && (
        <CreateUpdateApiPartnerDialog
          apiPartner={selectedApiPartner}
          onSubmit={apiPartnerAction}
          isOpen={openCreateUpdateApiPartnerDialog}
          close={() => setOpenCreateUpdateApiPartnerDialog(false)}
        />
      )}
    </Box>
  )
}

export default ApiPartnerAdminPage
