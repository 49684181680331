import { FunctionComponent, useCallback } from 'react'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useQuery } from '@apollo/client'
import { IS_VALID_RECON_JOB_TEMPLATE_QUERY } from '@src/graphql/queries/jobTemplate'
import { useDialog } from 'muibox'
import { Query } from '@src/graphql/types'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { formatMaybeApolloError } from '../../../utils/errors'

type Props = {
  saveJobTemplate: () => Promise<void>
  isSaving: boolean
  jobTemplateId: string
}

const CheckValidReconButton: FunctionComponent<Props> = ({
  saveJobTemplate,
  jobTemplateId,
  isSaving,
}) => {
  const dialog = useDialog()
  const {
    loading: validityLoading,
    refetch: validityRefetch,
    error: validityError,
  } = useQuery<Pick<Query, 'isValidReconJobTemplate'>>(IS_VALID_RECON_JOB_TEMPLATE_QUERY, {
    variables: { jobTemplateId },
    fetchPolicy: 'no-cache',
    skip: true,
  })
  const checkValidity = useCallback(async (): Promise<void> => {
    try {
      await saveJobTemplate()
      const resp = await validityRefetch()
      if (validityError) throw validityError
      if (resp.data.isValidReconJobTemplate!.ok) {
        await dialog.alert({
          title: 'Valid Recon Job Type',
          message: <Typography>This is a valid recon job type.</Typography>,
        })
      } else {
        await dialog.alert({
          title: 'Invalid Recon Job Type',
          message: (
            <>
              <Typography>This is NOT a valid recon job type.</Typography>
              <ul>
                {resp.data.isValidReconJobTemplate!.errors.map((error) => (
                  <Typography key={error}>{error}</Typography>
                ))}
              </ul>
            </>
          ),
        })
      }
    } catch (e) {
      await dialog.alert({
        title: 'Invalid Recon Job Type',
        message: (
          <Typography>This is NOT a valid recon job type. {formatMaybeApolloError(e)}</Typography>
        ),
      })
    }
  }, [dialog, validityRefetch, saveJobTemplate, validityError])
  return (
    <Button onClick={checkValidity} disabled={validityLoading || isSaving} variant={'outlined'}>
      {(validityLoading && <CenteredCircularProgress />) || <>Check Valid Recon Job Type</>}
    </Button>
  )
}

export default CheckValidReconButton
