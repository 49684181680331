import { makeStyles, Box, Typography, Checkbox } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircle'
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import { clsx } from 'clsx'
import { FunctionComponent } from 'react'
import { FilePageNode, FilePageType } from '@src/graphql/types'
import theme from '@src/utils/theme'

const useStyles = makeStyles({
  imageContainer: {
    position: 'relative',
    paddingTop: '100%',
    marginRight: 0,
    marginLeft: 'auto',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[400],
  },
  image: {
    objectFit: 'contain',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    cursor: 'pointer',
    width: '100%',
    height: '100%',
  },
  excelPreview: {
    objectFit: 'contain',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    cursor: 'pointer',
    width: '100%',
    height: '100%',
  },
  selected: {
    boxShadow: `inset 0px 0px 0px ${theme.spacing(0.5)}px ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
  },
  checkbox: {
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    top: theme.spacing(0.5),
    left: theme.spacing(0.5),
    padding: 0,
  },
  titleBox: {
    bottom: 0,
    background: theme.palette.grey[800],
    position: 'absolute',
    width: '100%',
  },
})

const EDOC_THUMBNAIL_SIZE = '105px'
const EXCEL_ICON_SIZE = '60px'

type Props = {
  previewFilePage: (filePage: FilePageNode) => void
  filePages: FilePageNode[]
  selectedFilePagesId: string[]
  currentFilePage: FilePageNode
  selectFilePage: (id: string, checked: boolean) => void
}

const SoaEDocFileList: FunctionComponent<Props> = ({
  previewFilePage,
  filePages,
  selectedFilePagesId,
  currentFilePage,
  selectFilePage,
}) => {
  const classes = useStyles()

  return (
    <Box
      display='inline-flex'
      flexDirection='column'
      overflow='scroll'
      maxHeight='70%'
      height={theme.spacing(70)}
      width='100%'
    >
      {filePages.map((filePage) => (
        <Box display='inline-table' key={filePage.id} mr={2} mb={2}>
          <Box
            className={classes.imageContainer}
            width={EDOC_THUMBNAIL_SIZE}
            onClick={() => previewFilePage(filePage)}
          >
            {filePage.type === FilePageType.ImagePdf && (
              <img
                className={classes.image}
                src={filePage.imageUrl || ''}
                alt={`${filePage.file?.filename} page ${filePage.pageNumber + 1}`}
              />
            )}
            {filePage.type === FilePageType.Excel && (
              <TableChartOutlinedIcon
                className={classes.excelPreview}
                style={{ fontSize: EXCEL_ICON_SIZE }}
              />
            )}
            {filePage.type === FilePageType.Other && <InsertDriveFileOutlinedIcon />}
            {(filePage.isInCw ||
              filePage?.eDocuments?.edges?.find((eDoc) => !!eDoc!.node!.isInCw)) && (
              <Box
                alignItems='center'
                bottom={0}
                display='flex'
                height='100%'
                justifyContent='center'
                position='absolute'
                width='100%'
              >
                <CheckCircleOutlineIcon color='primary' />
              </Box>
            )}
            <Box className={classes.titleBox}>
              <Box
                color={theme.palette.common.white}
                whiteSpace='nowrap'
                textOverflow='ellipsis'
                overflow='hidden'
                padding={1}
              >
                <Typography variant='caption' color='initial'>
                  {`${filePage.file?.filename} (Page ${filePage.pageNumber + 1})`}
                </Typography>
              </Box>
            </Box>
            <Checkbox
              checked={selectedFilePagesId.includes(filePage.id)}
              className={classes.checkbox}
              size='small'
              onChange={(_, checked) => selectFilePage(filePage.id, checked)}
            />
            <Box
              className={clsx({
                [classes.selected]: filePage.id === currentFilePage?.id,
              })}
            />
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default SoaEDocFileList
