import { FunctionComponent, useMemo } from 'react'
import { clsx } from 'clsx'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { ArrivalNoticeLumpsumReconResultNode } from '@src/graphql/types'
import { Theme } from '@material-ui/core'
import theme from '@src/utils/theme'
import { COLORS } from '@src/utils/app_constants'

const PREMIUM_RATES_TABLE_COLUMNS = ['Field Name', 'Expected Premium Rate', 'Invoice Data']

type Props = {
  premiumRatesReconResult: ArrivalNoticeLumpsumReconResultNode
}

const useStyles = makeStyles<Theme>({
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.25, 1),
  },
  error: {
    color: theme.palette.error.main,
    background: COLORS.PALE_RED,
    boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
  },
  boldText: {
    fontWeight: 'bold',
  },
})

const ArrivalNoticePremiumRatesResults: FunctionComponent<Props> = ({
  premiumRatesReconResult,
}) => {
  const classes = useStyles()

  const premiumRatesTableData = useMemo(() => {
    return [
      ['Premium Rate', premiumRatesReconResult.premiumRate ?? '', '-'],
      ['Premium Container Qty', premiumRatesReconResult.premiumRateContainerQty ?? '', '-'],
      ['Accessorial Fees', premiumRatesReconResult.premiumTotalAccessorialFees ?? '', '-'],
      [
        'Total Amount',
        premiumRatesReconResult.expectedLumpsum ?? '',
        premiumRatesReconResult.documentLumpsum ?? '',
      ],
    ]
  }, [premiumRatesReconResult])

  return (
    <>
      <Typography variant='h4' gutterBottom>
        Premium Rates Reconciliation
      </Typography>
      <TableContainer>
        <Table size='small'>
          <TableBody>
            <TableRow>
              {PREMIUM_RATES_TABLE_COLUMNS.map((col, colIdx) => (
                <TableCell
                  className={clsx(classes.cell, classes.colCell)}
                  align='center'
                  width={colIdx === 0 ? '20%' : '40%'}
                  key={col}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
            {premiumRatesTableData.map((row, rowIdx) => (
              <TableRow key={rowIdx}>
                {row.map((data, colIdx) => {
                  const isFieldName = colIdx === 0
                  const isLastRow = !isFieldName && rowIdx === premiumRatesTableData.length - 1
                  return (
                    <TableCell
                      className={clsx(classes.cell, {
                        [classes.colCell]: isFieldName,
                        [classes.boldText]: isLastRow,
                        [classes.error]: isLastRow && !premiumRatesReconResult.success,
                      })}
                      component='th'
                      scope='row'
                      align={isFieldName ? 'center' : 'right'}
                      width={isFieldName ? '20%' : '40%'}
                      key={colIdx}
                    >
                      {data}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ArrivalNoticePremiumRatesResults
