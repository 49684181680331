import { formatMaybeApolloError } from '@src/utils/errors'
// the navbar at the top of the page
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { USER_COMPANIES } from '@src/graphql/queries/company'
import {
  TOGGLE_BLOCK_TASK,
  UPDATE_TASK,
  UPDATE_TASK_COMPANY,
  UPDATE_TASK_TYPE,
} from '@src/graphql/mutations/task'
import { TASK_DETAIL } from '@src/graphql/queries/task'
import { debounce } from 'lodash'
import { useSnackbar } from 'notistack'
import {
  JobStatus,
  Mutation,
  MutationBlockTaskArgs,
  MutationUpdateTaskArgs,
  MutationUpdateTaskCompanyArgs,
  MutationUpdateTaskTypeArgs,
  Query,
  TaskType,
} from '@src/graphql/types'
import { formatDateForTextField, getTaskSLATimeLeft } from '@src/utils/date'
import { useMutation, useQuery } from '@apollo/client'

import {
  SECONDS_IN_MS,
  SINGLE_TASK_VIEW_SYNC_BASE_INTERVAL,
  TASK_VIEW_DIMENSIONS,
  TASK_VIEW_SYNC_OFFSET,
} from '@src/utils/app_constants'
import theme from '@src/utils/theme'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import TaskNotes from '@src/components/task-notes'
import { OutlinedInput } from '@material-ui/core'
import randomInt from '@src/utils/random'
import BlockTaskDialog from '@src/components/BlockTaskDialog'
import UnblockTaskDialog from '@src/components/UnblockTaskDialog'

const useStyles = makeStyles({
  taskTableDetail: {
    marginBottom: theme.spacing(3),
  },
  taskNotesContainer: {
    padding: theme.spacing(2),
  },
  taskNameInput: {
    fontSize: 22,
  },
})

type Props = {
  taskId: string
}

const TaskDetail: FunctionComponent<Props> = ({ taskId }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [taskTitle, setTaskTitle] = useState('')
  const { getValues: getTaskValues, control, setValue } = useForm()
  const [timeLastSync, setTimeLastSync] = useState(null as null | Date)
  const [blockTaskDialogOpen, setBlockTaskDialogOpen] = useState(false)
  const [unblockTaskDialogOpen, setUnblockTaskDialogOpen] = useState(false)
  const {
    data: taskData,
    loading: istaskDataLoading,
    refetch: refetchTaskDetails,
  } = useQuery<Pick<Query, 'task'>>(TASK_DETAIL, {
    variables: { id: taskId },
    onCompleted: (newTaskData) => {
      setTaskTitle(newTaskData.task!.title)
    },
  })
  const { data: userCompanies, loading: userCompaniesLoading } =
    useQuery<Pick<Query, 'companies'>>(USER_COMPANIES)
  const [updateTask] = useMutation<Pick<Mutation, 'updateTask'>, MutationUpdateTaskArgs>(
    UPDATE_TASK,
  )
  const [updateTaskType, { loading: updateTaskTypeLoading, error: updateTaskTypeError }] =
    useMutation<Pick<Mutation, 'updateTaskType'>, MutationUpdateTaskTypeArgs>(UPDATE_TASK_TYPE)
  const [updateTaskCompany, { loading: updateTaskCompanyLoading, error: updateTaskCompanyError }] =
    useMutation<Pick<Mutation, 'updateTaskCompany'>, MutationUpdateTaskCompanyArgs>(
      UPDATE_TASK_COMPANY,
    )
  const [toggleBlockTask] = useMutation<Pick<Mutation, 'blockTask'>, MutationBlockTaskArgs>(
    TOGGLE_BLOCK_TASK,
  )

  const task = taskData?.task
  const jobsInConfirmationOrDone = useMemo(
    () =>
      task?.jobs?.edges
        ?.filter((job) => [JobStatus.Done, JobStatus.Confirmation].includes(job!.node!.status))
        .map((job) => job!.node!) || [],
    [task?.jobs],
  )

  const [taskType, setTaskType] = useState('')
  const [taskStatus, setTaskStatus] = useState('')
  const [companyId, setCompanyId] = useState('')

  const updateTaskValues = useCallback(async (): Promise<void> => {
    const { dateReceived, taskReferenceId, message, dateConfirmed } = getTaskValues()
    const timezoneAdjustedDateRec = new Date(dateReceived).toUTCString()
    const timezoneAdjustedDateConf = dateConfirmed
      ? new Date(dateConfirmed).toUTCString()
      : dateConfirmed
    const fieldsEmpty = !taskReferenceId || !taskTitle
    if (fieldsEmpty) {
      if (!taskReferenceId) {
        enqueueSnackbar(`Task Reference ID cannot be empty when saving task`, {
          variant: 'error',
        })
      }
      if (!taskTitle) {
        enqueueSnackbar(`Task Title cannot be empty when saving task`, {
          variant: 'error',
        })
      }
      return
    }

    try {
      await updateTask({
        variables: {
          taskId,
          dateConfirmed: timezoneAdjustedDateConf,
          dateReceived: timezoneAdjustedDateRec,
          message,
          taskReferenceId,
          title: taskTitle,
        },
      })
    } catch (error) {
      enqueueSnackbar(`Error while updating task: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, getTaskValues, taskId, taskTitle, updateTask])

  useEffect(() => {
    // Refetch recently updated/created tasks automatically
    // to keep task details relatively up to date
    const refetchData = async (): Promise<void> => {
      await refetchTaskDetails()
    }

    const timeToSyncInSeconds =
      SINGLE_TASK_VIEW_SYNC_BASE_INTERVAL +
      randomInt(TASK_VIEW_SYNC_OFFSET * 2) +
      1 -
      TASK_VIEW_SYNC_OFFSET
    setTimeout((): void => {
      refetchData()
        .then(() => setTimeLastSync(new Date()))
        .catch((error) =>
          enqueueSnackbar(
            `Failed to fetch updated task details ${error.error}. Please refresh the page`,
            {
              variant: 'error',
            },
          ),
        )
    }, timeToSyncInSeconds * SECONDS_IN_MS)
  }, [timeLastSync, refetchTaskDetails, enqueueSnackbar])

  useEffect(() => {
    if (taskTitle && taskTitle !== task?.title) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateTaskValues()
    }
  }, [task?.title, taskTitle, updateTaskValues])

  const onUpdateTaskType = async (newTaskType: string): Promise<void> => {
    await updateTaskType({ variables: { taskId, taskType: newTaskType } })
    if (updateTaskTypeError) {
      enqueueSnackbar(
        `Failed to update task type: ${formatMaybeApolloError(updateTaskTypeError)}`,
        {
          variant: 'error',
        },
      )
    }
  }

  const updateDebounceTime = 500
  const onUpdateTaskValues = useMemo(
    () => debounce(updateTaskValues, updateDebounceTime),
    [updateTaskValues],
  )

  const blockTask = useMemo(
    () => async (): Promise<void> => {
      try {
        await toggleBlockTask({
          variables: { taskId, blocked: true },
        })
        enqueueSnackbar(
          `${
            task?.title || 'Task'
          } is blocked. Please document why the task is blocked on the task notes.`,
        )
        setBlockTaskDialogOpen(false)
      } catch (error) {
        enqueueSnackbar(`Encountered error while blocking task: ${formatMaybeApolloError(error)}`, {
          variant: 'error',
        })
      }
    },
    [toggleBlockTask, taskId, task?.title, enqueueSnackbar],
  )

  const unblockTask = useMemo(
    () =>
      async (dateReceived: string): Promise<void> => {
        try {
          await toggleBlockTask({
            variables: {
              taskId,
              blocked: false,
              dateReceived: new Date(dateReceived).toUTCString(),
            },
          })
          setUnblockTaskDialogOpen(false)
        } catch (error) {
          enqueueSnackbar(
            `Encountered error while unblocking task: ${formatMaybeApolloError(error)}`,
            {
              variant: 'error',
            },
          )
        }
      },
    [toggleBlockTask, taskId, enqueueSnackbar],
  )

  const toggleBlock = useMemo(
    () =>
      async (blocked: boolean): Promise<void> => {
        if (blocked) {
          if (jobsInConfirmationOrDone.length || task?.dateConfirmed) {
            setBlockTaskDialogOpen(true)
          } else {
            await blockTask()
          }
        } else {
          setUnblockTaskDialogOpen(true)
        }
      },
    [blockTask, jobsInConfirmationOrDone, task?.dateConfirmed],
  )

  useEffect(() => {
    if (task) {
      setTaskStatus(task.status)
      setTaskType(task.taskType)
      setCompanyId(task.company!.id)
      setValue('dateReceived', formatDateForTextField(task.dateReceived!))
      setValue('dateCreated', formatDateForTextField(task.dateCreated))
    }
  }, [task, setValue])

  const taskDetails = useMemo(() => {
    const onUpdateTaskCompany = async (newCompanyId: string): Promise<void> => {
      await updateTaskCompany({ variables: { taskId, companyId: newCompanyId } })
      if (updateTaskCompanyError) {
        enqueueSnackbar(
          `Failed to update task company: ${formatMaybeApolloError(updateTaskCompanyError)}`,
          {
            variant: 'error',
          },
        )
      }
    }

    const defaultDetails = task
      ? [
          {
            name: 'Blocked',
            item: (
              <Switch checked={task.blocked} onChange={(_, newValue) => toggleBlock(newValue)} />
            ),
          },
          {
            name: 'Company',
            item: (
              <Select
                value={companyId}
                onChange={(e) => onUpdateTaskCompany(e.target.value as string)}
                disabled={userCompaniesLoading || updateTaskCompanyLoading}
                variant='outlined'
                fullWidth
              >
                {(userCompanies?.companies || []).map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            ),
          },
          {
            name: 'Task Reference',
            item: (
              <Controller
                control={control}
                name='taskReferenceId'
                render={({ field: { ref, ...rest } }) => (
                  <OutlinedInput {...rest} inputRef={ref} fullWidth margin='dense' />
                )}
                defaultValue={task.taskReferenceId}
              />
            ),
          },
          {
            name: 'Date Created',
            item: (
              <Controller
                control={control}
                name='dateCreated'
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    type='datetime-local'
                    inputRef={ref}
                    {...rest}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                )}
              />
            ),
          },
          {
            name: 'Date Received',
            item: (
              <Controller
                control={control}
                name='dateReceived'
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    type='datetime-local'
                    inputRef={ref}
                    {...rest}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            ),
          },
          {
            name: 'Date Confirmed',
            item: (
              <Controller
                control={control}
                name='dateConfirmed'
                defaultValue={
                  task.dateConfirmed ? formatDateForTextField(task.dateConfirmed) : undefined
                }
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    inputRef={ref}
                    {...rest}
                    type='datetime-local'
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            ),
          },
          {
            name: 'Status',
            item: (
              <TextField
                value={taskStatus}
                name='taskType'
                disabled
                fullWidth
                variant='outlined'
                margin='dense'
              />
            ),
          },
        ]
      : []

    return defaultDetails
  }, [
    task,
    companyId,
    userCompaniesLoading,
    updateTaskCompanyLoading,
    userCompanies?.companies,
    control,
    taskStatus,
    updateTaskCompany,
    taskId,
    updateTaskCompanyError,
    enqueueSnackbar,
    toggleBlock,
  ])

  return (
    <Box p={2}>
      {istaskDataLoading ? (
        <CenteredCircularProgress />
      ) : (
        task && (
          <div>
            <TextField
              value={taskTitle}
              onChange={(e) => setTaskTitle(e.target.value)}
              inputProps={{
                className: classes.taskNameInput,
              }}
            />
            <Box display='flex' alignItems='center' justifyContent='space-between' mb={1}>
              <Typography variant='body1'>SLA Left: {getTaskSLATimeLeft(task)}</Typography>
              <ButtonGroup disabled={updateTaskTypeLoading} disableElevation>
                {Object.values(TaskType).map((type) => (
                  <Button
                    key={type}
                    onClick={() => onUpdateTaskType(type)}
                    variant={taskType === type ? 'contained' : 'outlined'}
                  >
                    {type}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
            <form onChange={onUpdateTaskValues}>
              <TableContainer component={Paper} className={classes.taskTableDetail}>
                <Table aria-label='Task detail table' size='small'>
                  <TableBody>
                    {taskDetails.map((detail) => (
                      <TableRow key={detail.name}>
                        <TableCell
                          style={{
                            fontSize: theme.typography.body1.fontSize,
                            fontWeight: theme.typography.fontWeightMedium,
                          }}
                        >
                          {detail.name}
                        </TableCell>
                        <TableCell>{detail.item}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Controller
                control={control}
                name='message'
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    label='Task Message'
                    multiline
                    rows={TASK_VIEW_DIMENSIONS.TASK_MESSAGE_ROWS}
                    {...rest}
                    inputRef={ref}
                    variant='outlined'
                    fullWidth
                  />
                )}
                defaultValue={task.message}
              />
            </form>
            <Box my={2}>
              <Paper className={classes.taskNotesContainer} square>
                <Typography variant='h4' gutterBottom>
                  Task Notes
                </Typography>
                <TaskNotes
                  task={task}
                  taskNotes={task?.taskNotes}
                  refetchQueries={[{ query: TASK_DETAIL, variables: { id: taskId } }]}
                />
              </Paper>
            </Box>
            {blockTaskDialogOpen && (
              <BlockTaskDialog
                isOpen={blockTaskDialogOpen}
                close={() => setBlockTaskDialogOpen(false)}
                dateConfirmed={task.dateConfirmed!}
                confirm={blockTask}
                jobs={jobsInConfirmationOrDone}
              />
            )}
            {unblockTaskDialogOpen && (
              <UnblockTaskDialog
                isOpen={unblockTaskDialogOpen}
                close={() => setUnblockTaskDialogOpen(false)}
                dateReceived={task.dateReceived!}
                confirm={unblockTask}
              />
            )}
          </div>
        )
      )}
    </Box>
  )
}

export default TaskDetail
