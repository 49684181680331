import { gql } from '@apollo/client'

export const GET_STANDARD_DOCUMENT_TYPES = gql`
  query standardDocumentTypes {
    standardDocumentTypes {
      id
      name
      isStandard
      collapsible
      tableShowsPreset
      autofillExtractorKey
      isEDocPublishedByDefault
      cargowiseFileTypeId
      cargowiseFileType {
        id
        code
        description
      }
      documentTypeFieldGroups {
        edges {
          node {
            id
            fieldGroup {
              id
              key
              name
              repeatable
              autofillKey
              fields {
                edges {
                  node {
                    id
                    name
                    key
                    autofillKey
                    validatorRegex
                    validatorDescription
                    dateFormatString
                    required
                    rows
                    values
                    allowFreeText
                    columnOrder
                    defaultValue
                    fieldType {
                      ... on FallbackValue {
                        fallbackValue
                        isFallback
                      }
                      ... on FieldTypeEnum {
                        value
                        isFallback
                      }
                    }
                    partialTableAllowed
                    invalidCharsRegex
                    searchableRecord {
                      id
                      model
                      searchKey
                      displayKey
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const DOCUMENT_TABLE_FRAGMENT = gql`
  fragment documentTable on DocumentTableNode {
    id
    orderPriority
    fieldGroupId
    nextTableId
    documentId
    document {
      id
      documentType {
        id
        tableShowsPreset
      }
    }
    editedByUser
    fieldGroup {
      id
      key
      autofillKey
      name
      fields {
        edges {
          node {
            id
            key
            autofillKey
            name
            required
            validatorRegex
            validatorDescription
            defaultValue
            dateFormatString
            fieldType {
              ... on FallbackValue {
                fallbackValue
                isFallback
              }
              ... on FieldTypeEnum {
                value
                isFallback
              }
            }
            allowFreeText
            partialTableAllowed
            invalidCharsRegex
            values
            columnOrder
            searchableRecord {
              id
              searchKey
              displayKey
              model
            }
          }
        }
      }
    }
    documentTableColumns {
      edges {
        node {
          id
          fieldId
          field {
            id
            key
            name
            searchableRecord {
              id
              searchKey
              displayKey
              model
            }
          }
          index
          left
          width
          top
          height
        }
      }
    }
    documentFieldGroups {
      edges {
        node {
          id
          rowOrderPriority
          document {
            id
          }
          documentFields {
            edges {
              node {
                id
                field {
                  id
                  key
                  name
                  autofillKey
                  searchableRecord {
                    id
                    searchKey
                    displayKey
                    model
                  }
                }
                value
                confirmed
                top
                left
                width
                height
                confidenceScore
              }
            }
          }
        }
      }
    }
  }
`
export const GET_DOCUMENT_TABLES = gql`
  ${DOCUMENT_TABLE_FRAGMENT}
  query documentTables($jobId: String!, $editedByUser: Boolean) {
    documentTables(jobId: $jobId, editedByUser: $editedByUser) {
      ...documentTable
    }
  }
`

export const GET_DOCUMENT_TYPES_FIELD_GROUPS = gql`
  query documentTypesFieldGroups($ids: [UUID!]!) {
    documentTypes(ids: $ids) {
      id
      documentTypeFieldGroups {
        edges {
          node {
            id
            fieldGroup {
              id
              key
              name
              repeatable
              autofillKey
              fields {
                edges {
                  node {
                    id
                    name
                    key
                    autofillKey
                    validatorRegex
                    validatorDescription
                    dateFormatString
                    required
                    rows
                    values
                    allowFreeText
                    columnOrder
                    defaultValue
                    fieldType {
                      ... on FallbackValue {
                        fallbackValue
                        isFallback
                      }
                      ... on FieldTypeEnum {
                        value
                        isFallback
                      }
                    }
                    partialTableAllowed
                    invalidCharsRegex
                    searchableRecord {
                      id
                      searchKey
                      displayKey
                      model
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
