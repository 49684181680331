import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/styles'

import theme from '@src/utils/theme'
import Grid from '@material-ui/core/Grid'
import ExportButton from '@src/components/export-button/ExportButton'
import { useSelector } from 'react-redux'
import { selectActiveDocumentTable } from '@src/redux-features/document_editor/document_table'
import { RootState } from '@src/utils/store'
import { useSnackbar } from 'notistack'
import { UseFormGetValues } from 'react-hook-form'
import { FieldRefRecord } from '@src/types/shipment_form'
import { MutableRefObject } from 'react'
import SearchShipments from './SearchShipments'
import { JobNode, JobTemplateReconType } from '@src/graphql/types'

const useStyles = makeStyles({
  actionButton: {
    borderRadius: theme.shape.borderRadius,
  },
  fieldActions: {
    position: 'fixed',
    bottom: 0,
    // lowering this from 9999 because it would cover modals and cause e2e tests to fail
    zIndex: 1,
    left: '50%',
    display: 'flex',
    justifyContent: 'center',
    // make the whole container unclickable
    pointerEvents: 'none',
  },

  fieldButton: {
    margin: `0 ${theme.spacing(2)}px`,
    padding: theme.spacing(2),
    // make the buttons clickable
    pointerEvents: 'auto',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  clearFieldButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  confirmFieldButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  QAContainer: {
    display: 'flex',
  },
})

export enum ActionSet {
  IN_PROGRESS,
  QA,
}

type Props = {
  isSubmitting: boolean
  clearField: (activeFieldKey: string) => void
  confirmField: (activeFieldKey: string) => void
  actionSet: ActionSet
  fieldMapRef: MutableRefObject<FieldRefRecord>
  job: JobNode
  validate: () => Promise<void>
  validateSoa: () => Promise<void>
  save: () => Promise<void>
  getValues: UseFormGetValues<Record<string, string>>
}

const ShipmentActions: FunctionComponent<Props> = ({
  isSubmitting,
  clearField,
  confirmField,
  actionSet,
  job,
  validate,
  validateSoa,
  save,
  getValues,
  fieldMapRef,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const activeFieldKey =
    useSelector((state: RootState) => state.documentEditor.activeFieldKey) || null
  const [showFieldActions, setShowFieldActions] = useState(false)
  const [showSearchShipmentsDialog, setShowSearchShipmentsDialog] = useState(false)
  const hasActiveDocumentTable = useSelector(
    (state: RootState) => selectActiveDocumentTable(state.documentEditor) != null,
  )
  // only search on AP/SOA/Ops jobs
  const showSearch =
    job.jobTemplate.shipmentOpsTypes.length > 0 ||
    job.jobTemplate.reconType === JobTemplateReconType.Soa ||
    job.jobTemplate.reconType === JobTemplateReconType.Ap ||
    job.jobTemplate.reconType === JobTemplateReconType.ArrivalNotice

  useEffect(() => {
    const confirmedFieldName = activeFieldKey ? `${activeFieldKey}_confirmed` : null
    if (confirmedFieldName) {
      const isConfirmed = getValues(confirmedFieldName)
      setShowFieldActions(!isConfirmed)
    } else {
      setShowFieldActions(false)
    }
  }, [activeFieldKey, getValues])

  const validateAndSaveAll = async (): Promise<void> => {
    await validate()
    await save()
  }

  const validateAndSaveAllSoa = async (): Promise<void> => {
    await validateSoa()
    await save()
  }
  const saveJob = async (): Promise<void> => {
    try {
      await save()
      enqueueSnackbar(`Successfully saved job`, { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Error saving job: ${formatMaybeApolloError(error)}`, { variant: 'error' })
    }
  }

  document.onkeydown = async (evt): Promise<void> => {
    const e = evt || window.event
    if ((e.ctrlKey || e.metaKey) && e.key === 's' && !hasActiveDocumentTable) {
      e.preventDefault()
      await saveJob()
    }
  }

  return (
    <>
      {actionSet !== ActionSet.QA ? (
        <Grid container spacing={1} justifyContent='space-around' alignItems='center'>
          <Grid item xs={1}>
            <IconButton aria-label='Save' onClick={saveJob}>
              <SaveIcon />
            </IconButton>
          </Grid>
          <Grid item xs={5}>
            <ExportButton
              fieldMapRef={fieldMapRef}
              jobId={job?.id}
              fullWidth
              validateAndSaveAll={validateAndSaveAll}
              validateAndSaveAllSoa={validateAndSaveAllSoa}
            />
          </Grid>
          {showSearch && (
            <Grid item xs={2}>
              <Button
                color={'primary'}
                variant={'outlined'}
                fullWidth
                onClick={() => setShowSearchShipmentsDialog(true)}
              >
                Search
              </Button>
            </Grid>
          )}
          <Grid item xs={showSearch ? 4 : 6}>
            <Button
              className={classes.actionButton}
              variant='contained'
              type='submit'
              disabled={isSubmitting}
              fullWidth
              startIcon={<CheckIcon />}
              color='secondary'
              data-testid='send-to-qa-btn'
            >
              {isSubmitting ? 'Saving details...' : 'Save & Send To QA'}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1} justifyContent='space-around' alignItems='center'>
          <Grid item xs={6}>
            <ExportButton
              fieldMapRef={fieldMapRef}
              jobId={job?.id ?? ''}
              fullWidth
              validateAndSaveAll={validateAndSaveAll}
              validateAndSaveAllSoa={validateAndSaveAllSoa}
            />
          </Grid>
          <Grid item xs={showSearch ? 2 : 3}>
            <Button
              variant='outlined'
              color='primary'
              onClick={saveJob}
              fullWidth
              disabled={isSubmitting}
              disableElevation
              data-testid='save-job-btn'
            >
              Save Job
            </Button>
          </Grid>
          {showSearch && (
            <Grid item xs={2}>
              <Button
                color={'primary'}
                variant={'outlined'}
                fullWidth
                onClick={() => setShowSearchShipmentsDialog(true)}
              >
                Search
              </Button>
            </Grid>
          )}
          <Grid item xs={showSearch ? 2 : 3}>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              fullWidth
              disabled={isSubmitting}
              disableElevation
              id='confirmationBtn'
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      )}
      <Box className={classes.fieldActions} position='fixed' bottom='0' p={3}>
        {actionSet === ActionSet.IN_PROGRESS && !hasActiveDocumentTable && showFieldActions && (
          <>
            <IconButton
              className={`${classes.fieldButton} ${classes.clearFieldButton}`}
              onClick={() => clearField(activeFieldKey!)}
              data-testid='clear-field-btn'
            >
              <CloseIcon fontSize='large' />
            </IconButton>

            <IconButton
              className={`${classes.fieldButton} ${classes.confirmFieldButton}`}
              onClick={() => confirmField(activeFieldKey!)}
              data-testid='confirm-field-btn'
              data-field-key={activeFieldKey!}
            >
              <CheckIcon fontSize='large' />
            </IconButton>
          </>
        )}
      </Box>
      {showSearchShipmentsDialog && (
        <SearchShipments
          job={job}
          isOpen={true}
          handleClose={() => setShowSearchShipmentsDialog(false)}
        />
      )}
    </>
  )
}

export default ShipmentActions
