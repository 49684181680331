import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Paper, Box, Typography, Grid } from '@material-ui/core'

import {
  ApiPartnerTmsType,
  CompanyNode,
  CustomDatabaseTypeNode,
  Maybe,
  Mutation,
  MutationCreateCustomDatabaseTypeArgs,
  MutationDeleteCustomDatabaseTypeArgs,
  MutationEditCustomDatabaseTypeArgs,
  Query,
  QueryCustomDatabaseTypesArgs,
} from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import useCompanyData from '@src/hooks/admin/useCompanyData'
import DatabaseHeader from './DatabaseHeader'
import DatabaseTable from './DatabaseTable'
import DatabaseMetadataTable from './DatabaseMetadataTable'
import DatabaseSearchBar from './DatabaseSearchBar'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import CreateButtons from './CreateButtons'
import ActionButtons from './ActionButtons'
import CreateDatabaseModal from './CreateDatabaseModal'
import CancelCreateDatabaseModal from './CancelCreateDatabaseModal'
import { MetadataFormField, validateMetadataFormFields } from './metadata_form'
import DeleteDatabaseModal from './DeleteDatabaseModal'
import { DatabaseRecord, DatabaseRecordType } from './record_type'
import { SpreadsheetDataColumn } from '@src/utils/data-grid'
import { constructInputDatabaseType } from '@src/utils/admin/table'
import { useMutation, useQuery } from '@apollo/client'
import { formatMaybeApolloError } from '@src/utils/errors'
import {
  CREATE_CUSTOM_DATABASE_TYPE,
  DELETE_CUSTOM_DATABASE_TYPE,
  EDIT_CUSTOM_DATABASE_TYPE,
} from '@src/graphql/mutations/customDatabase'
import { GET_CUSTOM_DATABASE_TYPES } from '@src/graphql/queries/customDatabase'
import { generateFieldKey } from '@src/utils/admin/schema'
import theme from '@src/utils/theme'
import { makeStyles } from '@material-ui/styles'

type Props = {
  company: Maybe<CompanyNode>
  companyId: string
  setRefreshDatabaseTypes: (toggle: boolean) => void
}

const CUSTOM_DATABASE_PAGE_SIZE = 1000
const placeholderType = Object.freeze({
  name: 'New Database Type',
  columns: Array.from({ length: 5 }, () => ({
    displayName: '',
    exportKey: '',
  })),
})

const useStyles = makeStyles({
  databaseBody: {
    position: 'relative',
  },
  databaseForm: {
    height: '650px',
    backgroundColor: theme.palette.grey[200],
  },
  buttons: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
})

// We pass in both company and companyId because company is a Maybe type and may be null
const CustomDatabaseType: FunctionComponent<Props> = ({
  company,
  companyId,
  setRefreshDatabaseTypes,
}) => {
  const classes = useStyles()
  const [selectedDatabaseType, setSelectedDatabaseType] = useState(
    null as null | CustomDatabaseTypeNode,
  )
  const [currDatabaseTypeName, setCurrDatabaseTypeName] = useState('')
  const [currTmsType, setCurrTmsType] = useState(null as ApiPartnerTmsType | null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [newDatabaseTypeModalOpen, setNewDatabaseTypeModalOpen] = useState(false)
  const [createNewDatabaseTypeMode, setCreateNewDatabaseTypeMode] = useState(false)
  const [cancelCreateNewDbTypeModalOpen, setCancelCreateNewDbTypeModalOpen] = useState(false)
  const { apiPartners } = useCompanyData(company)
  const [rows, setRows] = useState([] as string[][])

  const [currApiPartnerId, setCurrApiPartnerId] = useState<string | null>(null)
  const [activePage, setActivePage] = useState(0)
  const { enqueueSnackbar } = useSnackbar()

  const columns = [
    {
      key: 'displayName',
      name: 'Column Name',
    },
    {
      key: 'exportKey',
      name: 'Export Key',
    },
  ] as SpreadsheetDataColumn[]

  useEffect(() => {
    if (selectedDatabaseType) {
      const newRows = selectedDatabaseType!.columns.edges
        .filter(({ node }) => !node.dateDeleted)
        .map(({ node }) => [node.displayName, node.exportKey])
      setRows(newRows)
    } else if (createNewDatabaseTypeMode) {
      const newRows = placeholderType.columns.map((node) => [node.displayName, node.exportKey])
      setRows(newRows)
      setCurrDatabaseTypeName(placeholderType.name)
    } else {
      resetCustomDatabaseFields()
    }
  }, [selectedDatabaseType, createNewDatabaseTypeMode, setRows])

  const resetCustomDatabaseFields = (): void => {
    setCurrDatabaseTypeName('')
    setSelectedDatabaseType(null)
    setCurrApiPartnerId(null)
  }

  const resetMappingState = (): void => {
    setCreateNewDatabaseTypeMode(false)
    resetCustomDatabaseFields()
    void refetchDatabaseTypes()
    setRefreshDatabaseTypes(true)
  }

  const [createCustomDatabaseType] = useMutation<
    Pick<Mutation, 'createCustomDatabaseType'>,
    MutationCreateCustomDatabaseTypeArgs
  >(CREATE_CUSTOM_DATABASE_TYPE, {
    onCompleted: () => {
      enqueueSnackbar('Custom database type created', { variant: 'success' })
      resetMappingState()
    },
    onError: (error) => {
      enqueueSnackbar(`Error creating custom database type: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const [editCustomDatabaseType] = useMutation<
    Pick<Mutation, 'editCustomDatabaseType'>,
    MutationEditCustomDatabaseTypeArgs
  >(EDIT_CUSTOM_DATABASE_TYPE, {
    onCompleted: () => {
      enqueueSnackbar('Custom database type updated', { variant: 'success' })
      resetMappingState()
    },
    onError: (error) => {
      enqueueSnackbar(`Error updating details: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const [deleteCustomDatabaseType] = useMutation<
    Pick<Mutation, 'deleteCustomDatabaseType'>,
    MutationDeleteCustomDatabaseTypeArgs
  >(DELETE_CUSTOM_DATABASE_TYPE, {
    onCompleted: () => {
      enqueueSnackbar('Custom database type deleted', { variant: 'success' })
      resetMappingState()
    },
    onError: (error) => {
      enqueueSnackbar(`Error deleting custom database type: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const { data, refetch: refetchDatabaseTypes } = useQuery<
    Pick<Query, 'customDatabaseTypes'>,
    QueryCustomDatabaseTypesArgs
  >(GET_CUSTOM_DATABASE_TYPES, {
    fetchPolicy: 'no-cache',
    variables: {
      query: '',
    },
    onCompleted: (data) => {
      if (data.customDatabaseTypes !== null) {
        if (selectedDatabaseType) {
          const updatedType = data!.customDatabaseTypes!.find(
            (newType: CustomDatabaseTypeNode) => newType.id === selectedDatabaseType?.id,
          )
          if (updatedType) {
            setSelectedDatabaseType(updatedType)
          }
        }
      }
    },
    onError: (error) => {
      enqueueSnackbar(`Error searching custom database types: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const loadingCustomType = false

  const metadataFormFields = useMemo(
    () =>
      [
        {
          fieldName: 'name',
          displayName: 'Database Type Name',
          fieldValue: currDatabaseTypeName,
          setFieldValue: setCurrDatabaseTypeName,
          required: true,
        },
        {
          fieldName: 'tmsType',
          displayName: 'TMS Type',
          fieldValue: currTmsType,
          setFieldValue: setCurrTmsType,
          options: Object.entries(ApiPartnerTmsType).map(([key, tmsType]) => {
            return {
              display: key,
              value: tmsType,
            }
          }),
          required: true,
        },
      ] as MetadataFormField[],
    [currDatabaseTypeName, setCurrDatabaseTypeName, currTmsType, setCurrTmsType],
  )

  const confirmCreateCustomDatabase = (): void => {
    setCreateNewDatabaseTypeMode(true)
    setNewDatabaseTypeModalOpen(false)
    resetCustomDatabaseFields()
  }

  const afterChange = (changes: [number, string | number, any, any][] | null): void => {
    if (changes) {
      const displayNameIndex = columns.findIndex((column) => column.key === 'displayName')
      const exportKeyIndex = columns.findIndex((column) => column.key === 'exportKey')

      const newRows = rows.map((row) => [...row])
      changes.forEach(([rowIndex]) => {
        if ((newRows[rowIndex][exportKeyIndex] ?? '') === '') {
          newRows[rowIndex][exportKeyIndex] = generateFieldKey(newRows[rowIndex][displayNameIndex])
        }
      })
      setRows(newRows)
    }
  }

  const createNewDatabaseType = (): void => {
    if (!validateMetadataFormFields(metadataFormFields)) {
      enqueueSnackbar(`Missing required fields`, {
        variant: 'error',
      })
      return
    }
    const inputCustomDatabaseType = constructInputDatabaseType(metadataFormFields, rows, columns)

    void createCustomDatabaseType({
      variables: {
        inputCustomDatabaseType,
      },
    })
  }

  const saveCustomDatabaseType = async (): Promise<void> => {
    if (!validateMetadataFormFields(metadataFormFields)) {
      enqueueSnackbar(`Missing required fields`, {
        variant: 'error',
      })
      return
    }
    const inputCustomDatabaseType = constructInputDatabaseType(metadataFormFields, rows, columns)

    await editCustomDatabaseType({
      variables: {
        customDatabaseTypeId: selectedDatabaseType!.id,
        inputCustomDatabaseType,
      },
    })
  }

  const confirmDeleteSelectedDatabaseType = async (): Promise<void> => {
    await deleteCustomDatabaseType({
      variables: {
        customDatabaseTypeId: selectedDatabaseType!.id,
      },
    })
    setDeleteModalOpen(false)
  }

  const fetchDatabaseTypes = (query: string): void => {
    void refetchDatabaseTypes({ query })
  }

  return (
    <Paper className={classes.databaseBody}>
      <DatabaseHeader
        title='Custom Database Types'
        buttonText='+ Custom Database Type'
        onNewDatabase={() => setNewDatabaseTypeModalOpen(true)}
      />
      <Grid container>
        <Grid item xs={12} md={3}>
          <DatabaseSearchBar
            selectedRecord={selectedDatabaseType as any as DatabaseRecord}
            setSelectedRecord={
              setSelectedDatabaseType as (databaseType: DatabaseRecord | null) => void
            }
            displayRecord={(databaseType: DatabaseRecord | null) => databaseType?.name ?? ''}
            setActivePage={setActivePage}
            searchResults={data?.customDatabaseTypes as any as DatabaseRecord[]}
            fetchRecords={fetchDatabaseTypes}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Box className={classes.databaseForm}>
            <DatabaseMetadataTable
              record={selectedDatabaseType as any as DatabaseRecord}
              metadataFormFields={metadataFormFields}
              createNewRecordMode={createNewDatabaseTypeMode}
              apiPartners={apiPartners}
            />
            {createNewDatabaseTypeMode || (selectedDatabaseType != null && !loadingCustomType) ? (
              loadingCustomType ? (
                <CenteredCircularProgress />
              ) : (
                <DatabaseTable
                  currApiPartnerId={currApiPartnerId}
                  recordPageSize={CUSTOM_DATABASE_PAGE_SIZE}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  createNewRecordMode={createNewDatabaseTypeMode}
                  rows={rows}
                  columns={columns}
                  afterChange={afterChange}
                />
              )
            ) : loadingCustomType ? (
              <CenteredCircularProgress />
            ) : (
              <Typography align='center'>Select a database type to edit details</Typography>
            )}
            <Box className={classes.buttons}>
              {createNewDatabaseTypeMode ? (
                <CreateButtons
                  create={createNewDatabaseType}
                  cancel={() => setCancelCreateNewDbTypeModalOpen(true)}
                />
              ) : (
                <ActionButtons
                  disabled={!selectedDatabaseType}
                  save={saveCustomDatabaseType}
                  del={() => setDeleteModalOpen(true)}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <DeleteDatabaseModal
        rows={rows}
        columns={columns}
        databaseRecordType={DatabaseRecordType.CustomDatabaseType}
        setOpen={setDeleteModalOpen}
        confirmDelete={confirmDeleteSelectedDatabaseType}
        open={deleteModalOpen}
      />
      <CreateDatabaseModal
        databaseRecordType={DatabaseRecordType.CustomDatabaseType}
        setOpen={setNewDatabaseTypeModalOpen}
        confirmCreate={confirmCreateCustomDatabase}
        open={newDatabaseTypeModalOpen}
      />
      <CancelCreateDatabaseModal
        databaseRecordType={DatabaseRecordType.CustomDatabaseType}
        setOpen={setCancelCreateNewDbTypeModalOpen}
        cancelCreateNewDatabase={() => setCreateNewDatabaseTypeMode(false)}
        open={cancelCreateNewDbTypeModalOpen}
      />
    </Paper>
  )
}

export default CustomDatabaseType
