import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, MenuItem, Select, TextField, Theme } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import theme from '@src/utils/theme'
import { JOB_OPERATORS, USER_JOB_TEMPLATES } from '@src/graphql/queries/job'
import { CREATE_EMPTY_JOB } from '@src/graphql/mutations/job'
import {
  Mutation,
  MutationCreateEmptyJobArgs,
  Query,
  QueryUserJobTemplatesArgs,
  TaskNode,
  UserNode,
} from '@src/graphql/types'
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { LogEventType } from '@src/utils/observability/LogEventType'

type Props = {
  task: TaskNode
  isOpen: boolean
  closePopup: () => void
  refetchJobs: () => unknown
}

const useStyles = makeStyles<Theme>({
  title: {
    // default h4 fontWeight too light
    fontWeight: theme.typography.fontWeightBold,
  },
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  taskDetails: {
    paddingTop: theme.spacing(3),
    backgroundColor: theme.palette.grey[300],
  },
  taskDetailHeader: {
    fontWeight: theme.typography.fontWeightBold,
  },
  addToTaskButton: {
    textAlign: 'right',
    paddingTop: theme.spacing(4),
  },
})

const CreateJobDialog: FunctionComponent<Props> = ({
  task,
  isOpen,
  refetchJobs,
  closePopup,
}: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { logEvent } = useEventLogger()
  const actionStart = useRef<Date>()

  const [jobTypeId, setJobTypeId] = useState(null as null | string)
  const { loading: jobTypesLoading, data: jobTypes } = useQuery<
    Pick<Query, 'userJobTemplates'>,
    QueryUserJobTemplatesArgs
  >(USER_JOB_TEMPLATES)
  const [manualProduct, setManualProduct] = useState('product')
  const [isCreatingJob, setIsCreatingJob] = useState(false)
  const [ownerId, setOwnerId] = useState(null as null | string)
  const [qaId, setQAId] = useState(null as null | string)
  const [jobName, setJobName] = useState(null as null | string)
  const { data: operatorsData, loading: isLoadingOperators } =
    useQuery<Pick<Query, 'jobOperators'>>(JOB_OPERATORS)
  const operators = operatorsData?.jobOperators as UserNode[]
  const resetForm = (): void => {
    setJobTypeId(null)
    setOwnerId(null)
    setQAId(null)
    setJobName(null)
  }

  const [createEmptyJob] = useMutation<
    Pick<Mutation, 'createEmptyJob'>,
    MutationCreateEmptyJobArgs
  >(CREATE_EMPTY_JOB, {
    update: (cache) => {
      cache.modify({
        fields: {
          // Invalidate countByJobStatus field and refreshes cache
          countByJobStatus() {},
        },
      })
    },
    onCompleted: (jobData) => {
      resetForm()
      refetchJobs()
      setIsCreatingJob(false)
      const createJobAction = (): ReactElement => (
        <Button
          onClick={async () => {
            history.push(`/todo/${jobData.createEmptyJob!.job.id}`)
          }}
        >
          Edit
        </Button>
      )
      enqueueSnackbar(`Job Successfully Created!`, {
        variant: 'success',
        autoHideDuration: 5000,
        action: createJobAction,
      })
      closePopup()

      void logEvent(LogEventType.CREATE_JOB, {
        job_id: jobData.createEmptyJob!.job.id,
        job_name: jobName,
        job_template_id: jobTypeId,
        task_id: task.id,
        company_id: task.company.id,
        action_start: actionStart.current,
        action_end: new Date(),
        success: true,
        error_message: '',
      })
    },
  })

  const onClose = (): void => {
    closePopup()
  }

  useEffect(() => {
    if (isOpen) {
      actionStart.current = new Date()
    }
  }, [isOpen])

  const validateFields = (): boolean => {
    let fieldsValid = true
    if (!jobTypeId) {
      enqueueSnackbar(`Job type cannot be blank`, { variant: 'error' })
      fieldsValid = false
    }
    if (!jobName) {
      enqueueSnackbar(`Job name cannot be blank`, { variant: 'error' })
      fieldsValid = false
    }
    return fieldsValid
  }

  const createJob = async (): Promise<void> => {
    if (!validateFields()) {
      return
    }
    try {
      setIsCreatingJob(true)
      // These variables are guaranteed to not be null because of validateFields
      const variables = {
        jobTemplateId: jobTypeId!,
        jobName: jobName!,
        clientReferenceNo: task.taskReferenceId,
        ownerId: ownerId!,
        qaId: qaId!,
        manualProduct: manualProduct,
        dateReceived: task.dateReceived,
        message: task.message ?? '',
        taskId: task.id,
      }
      await createEmptyJob({ variables })
    } catch (error) {
      setIsCreatingJob(false)
      const errorMessage = `Error while creating job: ${formatMaybeApolloError(error)}`
      enqueueSnackbar(errorMessage, { variant: 'error' })

      void logEvent(LogEventType.CREATE_JOB, {
        job_id: null,
        job_name: jobName,
        job_template_id: jobTypeId,
        task_id: task.id,
        company_id: task.company.id,
        action_start: actionStart.current,
        action_end: new Date(),
        success: false,
        error_message: errorMessage,
      })
    }
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialog }}
      onClose={onClose}
      open={isOpen}
    >
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={closePopup} data-testid='close-btn' />
      </Box>
      <Box className={classes.taskDetails}>
        <Box display='flex' justifyContent='space-around'>
          <Box p={3}>
            <Typography variant='h4'>Task Name</Typography>
            <Typography variant='subtitle1'>{task.title}</Typography>
          </Box>
          <Box p={3}>
            <Typography variant='h4'>Task Reference ID</Typography>
            <Typography variant='subtitle1'>{task.taskReferenceId}</Typography>
          </Box>
          <Box p={3}>
            <Typography variant='h4'>Company</Typography>
            <Typography variant='subtitle1'>{task.company!.name}</Typography>
          </Box>
        </Box>
      </Box>
      <DialogTitle disableTypography>
        <Typography variant='h4' className={classes.title}>
          Job Details
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography> Job Name </Typography>
            <TextField
              fullWidth
              disabled={isCreatingJob}
              onChange={(e) => setJobName(e.target.value)}
              variant='outlined'
              placeholder='Job Name'
              inputProps={{
                'data-testid': 'job-name-textfield',
              }}
            >
              {jobName}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <Typography> Operator (optional)</Typography>
            <Autocomplete
              data-testid='operator-assignment'
              options={operators || []}
              getOptionLabel={(option) => option.email || ''}
              disabled={isCreatingJob || isLoadingOperators}
              onChange={(_, newOwner) => setOwnerId(newOwner?.id || null)}
              renderInput={(params) => <TextField {...params} variant='outlined' />}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography> Job Type</Typography>
            <Select
              data-testid='job-type-select'
              fullWidth
              value={jobTypeId ?? ''}
              onChange={(e) => setJobTypeId(e.target.value as string)}
              variant='outlined'
              disabled={isCreatingJob || jobTypesLoading}
              placeholder='Job Type'
            >
              {jobTypes?.userJobTemplates
                .filter((jobType) => jobType.company.id === task.company.id)
                .map((currJobType) => (
                  <MenuItem
                    key={currJobType?.id || ''}
                    value={currJobType?.id || ''}
                    data-testid={`${currJobType?.name || ''}`}
                  >
                    {currJobType?.name || ''}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Typography> QA (optional)</Typography>
            <Autocomplete
              data-testid='qa-assignment'
              options={operators || []}
              getOptionLabel={(option) => option.email || ''}
              disabled={isCreatingJob || isLoadingOperators}
              onChange={(_, newQa) => setQAId(newQa?.id || null)}
              renderInput={(params) => <TextField {...params} variant='outlined' />}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography> Product or Manual</Typography>
            <ToggleButtonGroup
              className={classes.toggleButtonGroup}
              exclusive
              value={manualProduct}
              color='primary'
              aria-label='outlined primary button group'
            >
              <ToggleButton value='product' onClick={() => setManualProduct('product')}>
                Product
              </ToggleButton>
              <ToggleButton value='manual' onClick={() => setManualProduct('manual')}>
                Manual
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.addToTaskButton}>
              <Button
                size='large'
                color='primary'
                variant='contained'
                onClick={createJob}
                disabled={isCreatingJob}
                /*
                 * We add a testid here because MUI puts a span tag underneath
                 *  the buttons, making it hard to query the actual button.
                 *  i.e., <button><span>Upload</span></button>
                 */
                data-testid='create-job-btn'
              >
                {isCreatingJob ? 'Adding to Task...' : 'Add to Task'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default CreateJobDialog
