import { ComponentProps, FunctionComponent, useContext, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { useSnackbar } from 'notistack'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Link,
  Typography,
} from '@material-ui/core'
import { parseDateString } from '@src/utils/date'
import { getDeployEnvironment } from '@src/utils/environment'
import { ENV_DASHBOARD_URL_MAP } from '@src/utils/app_constants'
import useSlateEditor, { SlateContent } from '@src/hooks/useSlateEditor'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import { clsx } from 'clsx'
import DeleteIcon from '@material-ui/icons/Delete'
import { UserProfileContext } from '@src/auth/UserProfileContext'
import Dialog from '@material-ui/core/Dialog'
import CustomSlate from '@src/components/custom-slate'
import NoteFormAttachments from '@src/components/job-viewer/NoteFormAttachments'
import { groupBy } from 'lodash'
import { reportRollbarError } from '@src/utils/observability/rollbar'
import { formatMaybeApolloError } from '@src/utils/errors'
import { ApolloError } from '@apollo/client/errors'
import { JobReconRequestReprocessNode, RequestReprocessNode } from '@src/components/job-viewer/type'
import {
  JobNotesQuery,
  JobTemplateReconType,
  NoteSource,
  NoteType,
  useCreateNoteMutation,
  useCreateReconJobNoteMutation,
  useDeleteReconNoteMutation,
  useJobNotesQuery,
} from '@src/graphql/types'
import { isJMSActivityResponse } from '@src/components/job-viewer/util'

const MIN_NOTE_EDITOR_HEIGHT = '180px'

const useStyles = makeStyles({
  textField: {
    width: '100%',
    paddingBottom: theme.spacing(1),
  },
  noteText: {
    whiteSpace: 'pre-wrap',
  },
  highlightBox: {
    backgroundColor: '#FFE580',
    marginTop: theme.spacing(1),
  },
  blueHighlightBox: {
    backgroundColor: '#A4D8E5',
  },
  commentBox: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  reprocessDetails: {
    margin: theme.spacing(1, 0),
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    fontSize: theme.typography.body1.fontSize,
  },
  reprocessDetailsItem: {
    padding: theme.spacing(0.25, 1.5),
  },
  reprocessDetailsTitle: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  reprocessReason: {
    textTransform: 'capitalize',
  },
  inputFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: MIN_NOTE_EDITOR_HEIGHT,
    overflow: 'hidden',
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(1),
  },
})

type Note = JobNotesQuery['jobNotes'][0]

export const getReconLink = (reconId: string, reconType?: JobTemplateReconType): string => {
  const env = getDeployEnvironment()
  const isSoaJob = reconType === JobTemplateReconType.Soa
  return `${ENV_DASHBOARD_URL_MAP[env]}/${isSoaJob ? 'batch-' : ''}recon/${reconId}`
}

/**
 * Replaces recon attempt note text with link to the dashboard recon page
 */
const formatText = (noteText: string, reconType?: JobTemplateReconType): JSX.Element => {
  const reconAttemptNotePattern =
    /^(Saved reconciliation\..*: )([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})(\..*)$/
  const captureGroups = noteText.match(reconAttemptNotePattern)

  if (captureGroups) {
    return (
      <Typography>
        {captureGroups[1]}{' '}
        <Link href={getReconLink(captureGroups[2], reconType)} target='_blank' rel='noreferrer'>
          {captureGroups[2]}
        </Link>
        {captureGroups[3]}
      </Typography>
    )
  }
  return <>{noteText}</>
}

export const parseNoteContent = (noteContent: SlateContent[]): string => {
  if (noteContent.length > 0) {
    return noteContent
      .map((content) => content.children.map((child) => child.text).join(''))
      .join('')
  }
  return ''
}

type Props = {
  jobId: string
  reconType?: JobTemplateReconType
  reconAttemptId?: string | null
}

const NotesOverview: FunctionComponent<Props> = ({ jobId, reconType, reconAttemptId }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const slateEditor = useSlateEditor(undefined, false)
  const { slateValue: paragraphs, editor, resetSlateEditor, files, removeFile } = slateEditor

  const attachments = groupBy(
    files.filter((file) => file.url),
    'type',
  )
  const slateValue = [...paragraphs, ...files]

  const hasAttachmentUploading = editor.isUploadingFile() || editor.isUploadingImage()
  const hasAttachments = Object.values(attachments).some((attachment) => attachment.length > 0)
  const hasContent = paragraphs?.some(({ children, url }) => url || children[0].text)

  const handleCreateNoteError = (error: ApolloError): void => {
    reportRollbarError(`Error: ${formatMaybeApolloError(error)}. Slate Value: ${slateValue}`)
    enqueueSnackbar(
      'Something went wrong while savings notes. The Expedock team has automatically been notified.',
      { variant: 'error' },
    )
  }

  const [createDashboardNote] = useCreateReconJobNoteMutation({
    refetchQueries: ['jobNotes'],
    variables: {
      jobId,
      // TODO: make this work for recon invoice shipment pages
      // NOTE: we should only set reconAttemptId if:
      // 1. we already backfilled RISR and RMC for all jobs
      // 2. feature flag for risr and rmc creation is already enabled
      reconAttemptId: null,
      invoiceNumber: null,
      content: JSON.stringify(slateValue),
    },
    onCompleted: () => resetSlateEditor(),
    onError: handleCreateNoteError,
  })

  const [createNote] = useCreateNoteMutation({
    refetchQueries: ['jobNotes'],
    variables: {
      jobId,
      content: JSON.stringify(slateValue),
      noteType: NoteType.CommentJms,
    },
    onCompleted: () => resetSlateEditor(),
    onError: handleCreateNoteError,
  })

  const { data: notes } = useJobNotesQuery({
    fetchPolicy: 'network-only',
    variables: { jobId },
  })
  const shownNotes = notes?.jobNotes

  const scrollToMostRecentNote = (): void => {
    const element = document.getElementById('notesContainer')
    if (element) {
      element.scrollTop = element.scrollHeight
    }
  }

  useEffect(() => {
    scrollToMostRecentNote()
  }, [shownNotes])

  return (
    <div>
      <Box id='notesContainer' height='66vh' overflow='scroll' my={1} p={1} bgcolor='grey.100'>
        {shownNotes?.map((note) => <NotesItem key={note.id} note={note} reconType={reconType} />)}
      </Box>
      <Box display='flex' flexDirection='column' style={{ gap: theme.spacing(1) }}>
        <Box className={classes.inputFieldContainer}>
          <CustomSlate
            slateEditor={slateEditor}
            style={{
              flex: 1,
              padding: theme.spacing(1),
            }}
          />
          {hasAttachmentUploading && <AttachmentLoader />}
          {hasAttachments && (
            <NoteFormAttachments
              files={attachments.file}
              images={attachments.image}
              onRemove={removeFile}
            />
          )}
        </Box>
        <Box>
          <Button
            color='primary'
            variant='contained'
            onClick={() => createNote()}
            disabled={!hasContent}
            style={{ marginRight: theme.spacing(1) }}
          >
            Submit In JMS
          </Button>
          <Button
            color='primary'
            variant='contained'
            disabled={reconType !== JobTemplateReconType.Soa && !hasContent}
            onClick={() => createDashboardNote()}
          >
            Send to Customer
          </Button>
        </Box>
      </Box>
    </div>
  )
}

const AttachmentLoader: FunctionComponent = () => (
  <Box
    display='flex'
    alignItems='center'
    padding={1}
    margin={1}
    borderRadius={theme.spacing(1)}
    style={{ gap: theme.spacing(1), backgroundColor: '#F8FAFC' }}
  >
    <CircularProgress size={12} />
    <Typography
      component='span'
      style={{
        color: '#64748B',
        fontSize: '0.75rem',
        fontWeight: theme.typography.fontWeightMedium,
      }}
    >
      Uploading attachments
    </Typography>
  </Box>
)

function getNoteDate(dateCreated: string, jobActivity: Note['jobActivity']): Date {
  if (jobActivity?.__typename === 'JobReceivedNode') {
    return parseDateString(jobActivity.job.task?.dateReceived || jobActivity.job.dateCreated)
  }
  return parseDateString(dateCreated)
}

const NotesItem: FunctionComponent<{ note: Note; reconType?: JobTemplateReconType }> = ({
  note,
  reconType,
}) => {
  const { type, text: noteText, jobActivity, reconAttempt, dateCreated, user } = note

  if ([NoteType.CommentUser, NoteType.CommentJms].includes(type)) {
    return <CommentNoteItem note={note} />
  }

  const highlighted = user.isCustomer || isJMSActivityResponse(type, noteText)
  switch (jobActivity?.__typename) {
    case 'JobRequestReprocessNode':
      return (
        <RequestReprocessNoteItem
          note={note}
          reconType={reconType}
          highlighted={highlighted}
          jobActivity={jobActivity}
        />
      )
    case 'JobReconRequestReprocessNode':
      return (
        <ReconRequestReprocessNoteItem
          note={note}
          reconType={reconType}
          highlighted={highlighted}
          jobActivity={jobActivity}
        />
      )
    case 'JobReconRequestReprocessTargetNode':
      return (
        <ReconRequestReprocessNoteItem
          note={note}
          reconType={reconType}
          highlighted={highlighted}
          jobActivity={jobActivity.sourceRequestReprocess}
        />
      )
    default:
      return <ActivityNoteItem note={note} reconType={reconType} highlighted={highlighted} />
  }
}

const CommentNoteItem: FunctionComponent<{ note: Note }> = ({ note }) => {
  const classes = useStyles()

  const { userProfile } = useContext(UserProfileContext)
  const { id, type, source, content, jobActivity, dateCreated, user } = note

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const parsedContent = typeof content === 'string' ? JSON.parse(content) : content
  const slateEditor = useSlateEditor(parsedContent)

  const noteDate = getNoteDate(dateCreated, jobActivity)
  const dateDeleted = note.dateDeleted && parseDateString(note.dateDeleted)
  const label = type === NoteType.CommentUser ? 'Commented from dashboard' : 'Commented'

  const isUserComment = type === NoteType.CommentUser
  const isUserCommentFromJMS = isUserComment && source === NoteSource.Jms
  const showDeleteOption = isUserComment && !dateDeleted && userProfile?.email === user.email

  const [deleteComment] = useDeleteReconNoteMutation({
    refetchQueries: ['jobNotes'],
    variables: { reconNoteId: id },
  })

  const closeDeleteDialog = (): void => setDeleteDialogOpen(false)
  const handleDeleteConfirm = (): void => {
    void deleteComment()
    closeDeleteDialog()
  }

  return (
    <div key={id}>
      <Box
        className={clsx(classes.highlightBox, classes.commentBox, {
          [classes.blueHighlightBox]: isUserCommentFromJMS,
        })}
      >
        <Box sx={{ flex: 1 }}>
          <Typography>
            {`[${noteDate.toDateString()} ${noteDate.toLocaleTimeString()}] `}
            <strong>{user.email}</strong>
            &nbsp;{label}:
          </Typography>
          <Typography className={classes.noteText} gutterBottom>
            <CustomSlate slateEditor={slateEditor} readOnly />
            {dateDeleted && (
              <strong>
                (This note was deleted on{' '}
                {`[${dateDeleted.toDateString()} ${dateDeleted.toLocaleTimeString()}]`})
              </strong>
            )}
          </Typography>
        </Box>
        {showDeleteOption && (
          <>
            <DeleteIcon
              style={{ margin: theme.spacing(0.5), cursor: 'pointer' }}
              fontSize='small'
              onClick={() => setDeleteDialogOpen(true)}
            />
            <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
              <DialogContent style={{ display: 'flex' }}>
                Are you sure you want to delete this comment? This action cannot be undone.
              </DialogContent>
              <DialogActions>
                <Button variant='text' size='small' onClick={closeDeleteDialog}>
                  Cancel
                </Button>
                <Button variant='contained' size='small' onClick={handleDeleteConfirm}>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
    </div>
  )
}

const ActivityNoteItem: FunctionComponent<{
  note: Note
  highlighted: boolean
  reconType?: JobTemplateReconType
}> = ({ note, highlighted, reconType, children }) => {
  const classes = useStyles()

  const { type, text: noteText, jobActivity, reconAttempt, dateCreated, user } = note
  const noteDate = getNoteDate(dateCreated, jobActivity)

  const isRejectedReconNotes = type == NoteType.ActivityJms && noteText.includes('Rejected')

  const isAutoRecon =
    jobActivity?.__typename === 'JobReconciliationSavedNode' && reconAttempt?.isAutoGenerated
  const isRequestReprocess = jobActivity?.__typename === 'JobRequestReprocessNode'
  const hasAdditionalNotes =
    jobActivity && 'additionalNotes' in jobActivity && jobActivity.additionalNotes

  return (
    <div key={note.id}>
      <Box className={clsx({ [classes.highlightBox]: highlighted })}>
        <Typography>
          {`[${noteDate.toDateString()} ${noteDate.toLocaleTimeString()}] `}
          <strong>{isAutoRecon ? 'Auto-recon' : note.user.email}</strong>&nbsp;
          {formatText(note.text, reconType)}
          {!isRequestReprocess && hasAdditionalNotes && (
            <>
              &nbsp;<strong>Additional notes:</strong> {jobActivity.additionalNotes}
            </>
          )}
        </Typography>
        {children}
        {isRejectedReconNotes && (
          <Box m={2}>
            <Typography>
              <strong>Recon note:</strong> {parseNoteContent(note?.content ?? {})}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  )
}

const RequestReprocessNoteItem: FunctionComponent<
  ComponentProps<typeof ActivityNoteItem> & {
    jobActivity: RequestReprocessNode
  }
> = ({ jobActivity, ...props }) => {
  const classes = useStyles()
  return (
    <ActivityNoteItem {...props}>
      <List dense className={classes.reprocessDetails}>
        <ListItem className={clsx(classes.reprocessDetailsItem, classes.reprocessDetailsTitle)}>
          Reprocess Request
        </ListItem>
        <ListItem className={clsx(classes.reprocessDetailsItem, classes.reprocessReason)}>
          Reason:&nbsp;
          {jobActivity.reason.replaceAll('_', ' ').toLowerCase()}
        </ListItem>
        <ListItem className={classes.reprocessDetailsItem}>
          Details to correct: {jobActivity.correctedInfo}
        </ListItem>
        <ListItem className={classes.reprocessDetailsItem}>
          Additional notes: {jobActivity.additionalNotes}
        </ListItem>
        <ListItem className={classes.reprocessDetailsItem}>
          Auto post after fixing:&nbsp;
          {jobActivity.autoPostAfterFixing ? 'Yes' : 'No'}
        </ListItem>
      </List>
    </ActivityNoteItem>
  )
}

const ReconRequestReprocessNoteItem: FunctionComponent<
  ComponentProps<typeof ActivityNoteItem> & { jobActivity: JobReconRequestReprocessNode }
> = ({ jobActivity, ...props }) => {
  const classes = useStyles()

  const { reconInvoiceShipmentReference: targetRISR, reconInvoice } = jobActivity

  const sourceRISR = targetRISR?.reconAttempt?.matchReconInvoiceShipmentReference

  const fieldValueMap = JSON.parse(targetRISR?.reconAttempt?.fieldValueMapV2 || '{}')
  const invoiceNumber = reconInvoice?.invoiceNumber || fieldValueMap.invoice_number

  const { referenceNo, consolNo, hblNo, mblNo, carrierBookingNo, containerNo } =
    targetRISR?.reconAttempt?.findShipmentReconResult || {}
  const matchingCriteria = [
    referenceNo && `Shipment - ${referenceNo}`,
    consolNo && `Consol - ${consolNo}`,
    hblNo && `HBL - ${hblNo}`,
    mblNo && `MBL - ${mblNo}`,
    carrierBookingNo && `Carrier Booking - ${carrierBookingNo}`,
    containerNo && `Container - ${containerNo}`,
  ]
    .filter(Boolean)
    .join(', ')

  return (
    <ActivityNoteItem {...props}>
      <List dense className={classes.reprocessDetails}>
        <ListItem className={clsx(classes.reprocessDetailsItem, classes.reprocessDetailsTitle)}>
          Reprocess Request
        </ListItem>
        <ListItem className={classes.reprocessDetailsItem}>
          Invoice Number: {invoiceNumber || 'N/A'}
        </ListItem>
        <ListItem className={classes.reprocessDetailsItem}>
          Matching Criteria: {matchingCriteria || 'N/A'}
        </ListItem>
        <ListItem className={classes.reprocessDetailsItem}>
          Reference Number: {sourceRISR?.tmsId || 'N/A'}
        </ListItem>
        <ListItem className={clsx(classes.reprocessDetailsItem, classes.reprocessReason)}>
          Reason:&nbsp;
          {jobActivity.reason.replaceAll('_', ' ').toLowerCase()}
        </ListItem>
        <ListItem className={classes.reprocessDetailsItem}>
          Details to correct: {jobActivity.correctedInfo}
        </ListItem>
        <ListItem className={classes.reprocessDetailsItem}>
          Additional notes: {jobActivity.additionalNotes}
        </ListItem>
        <ListItem className={classes.reprocessDetailsItem}>
          Auto post after fixing:&nbsp;
          {jobActivity.autoPostAfterFixing ? 'Yes' : 'No'}
        </ListItem>
      </List>
    </ActivityNoteItem>
  )
}

export default NotesOverview
