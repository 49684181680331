/* eslint-disable @typescript-eslint/no-var-requires */
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import { DialogActions, TextField, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import CargowiseFileTypeSelector from './CargowiseFileTypeSelector'
import { DocumentTypeOption } from '@src/utils/admin/document_type_option'
import { AutofillExtractorKey, Maybe, Query, UserRole } from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import DocumentTypeAutofillExtractorKeySelector from './DocumentTypeAutofillExtractorKeySelector'
import { GET_USER_ROLES } from '@src/graphql/queries/profile'
import { useQuery } from '@apollo/client'

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  checkbox: {
    marginLeft: 'auto',
  },
  cwFileTypeAutocomplete: {
    marginLeft: 'auto',
    width: '25%',
  },
  autofillExtractorKeyAutocomplete: {
    marginLeft: 'auto',
    width: '40%',
  },
  documentTypeName: {
    marginBottom: theme.spacing(1),
  },
  documentTypeNameInput: {
    fontSize: theme.typography.h4.fontSize,
  },
})

export type DocumentTypeFormValues = {
  name: string
  isStandard: boolean
  collapsible: boolean
  tableShowsPreset: boolean
  cargowiseFileTypeId: string | null
  isEDocPublishedByDefault: boolean
  autofillExtractorKey: AutofillExtractorKey | null
}

type Props = {
  companyId: string
  isOpen: boolean
  close: () => void
  documentTypes: DocumentTypeOption[]
  handleNewDocumentTypeOptions: (
    name: string,
    isStandard: boolean,
    isCollapsible: boolean,
    tableShowsPreset: boolean,
    cwFileTypeId: string | undefined | null,
    isEDocPublishedByDefault: boolean,
    autofillExtractorKey: AutofillExtractorKey | null,
  ) => Promise<void>
  documentTypeToAdd: Maybe<DocumentTypeOption>
  setDocumentTypeToAdd: (documentType: Maybe<DocumentTypeOption>) => void
  documentTypeToEdit: Maybe<DocumentTypeOption>
  setDocumentTypeToEdit: (documentType: Maybe<DocumentTypeOption>) => void
  handleUpdateDocumentTypeOption: (
    name: string,
    isStandard: boolean,
    isCollapsible: boolean,
    tableShowsPreset: boolean,
    cwFileTypeId: string | undefined | null,
    isEDocPublishedByDefault: boolean,
    autofillExtractorKey: AutofillExtractorKey | null,
  ) => Promise<void>
}

const DocumentTypeOptionsDialog: FunctionComponent<Props> = ({
  companyId,
  isOpen,
  close,
  documentTypes,
  handleNewDocumentTypeOptions,
  documentTypeToAdd,
  setDocumentTypeToAdd,
  documentTypeToEdit,
  setDocumentTypeToEdit,
  handleUpdateDocumentTypeOption,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const { data: userProfileData } = useQuery<Pick<Query, 'userProfile'>>(GET_USER_ROLES)
  const canEditAutofillExtractorKey = useMemo(
    () =>
      !!userProfileData?.userProfile.roles.find((role) =>
        [UserRole.Engineer, UserRole.ExpedockAdmin].includes(role),
      ),
    [userProfileData],
  )

  const currentDocumentType = useMemo(
    () => documentTypeToEdit ?? documentTypeToAdd,
    [documentTypeToEdit, documentTypeToAdd],
  )

  const getDocumentTypeFormValues = (
    currentDocumentType: Maybe<DocumentTypeOption>,
  ): DocumentTypeFormValues => {
    return {
      name: currentDocumentType?.name ?? '',
      isStandard: currentDocumentType?.isStandard ?? false,
      collapsible: currentDocumentType?.collapsible ?? false,
      tableShowsPreset: currentDocumentType?.tableShowsPreset ?? false,
      cargowiseFileTypeId: currentDocumentType?.cargowiseFileTypeId ?? null,
      isEDocPublishedByDefault: currentDocumentType?.isEDocPublishedByDefault ?? false,
      autofillExtractorKey: currentDocumentType?.autofillExtractorKey ?? null,
    }
  }
  const formMethods = useForm<DocumentTypeFormValues>({
    defaultValues: getDocumentTypeFormValues(currentDocumentType),
  })
  const { handleSubmit, getValues, reset, control, setError } = formMethods

  useEffect(() => {
    // reset so the values on the form get updated according to the currentDocumentType
    reset(getDocumentTypeFormValues(currentDocumentType))
  }, [currentDocumentType, reset])

  const validateDocTypeName = useCallback(
    (trimmedName: string): Record<string, string> => {
      const errors = {} as Record<string, string>
      if (!trimmedName) {
        errors['name'] = "Document Type Name can't be blank. Please fill it in before saving."
      } else if (
        documentTypes
          .filter((docType) => docType.id !== currentDocumentType?.id)
          .map((docType) => docType.name)
          .includes(trimmedName)
      ) {
        errors['name'] =
          'Another document type already exists with the same name. Please rename before saving.'
      }
      return errors
    },
    [documentTypes, currentDocumentType],
  )

  const handleClose = useCallback((): void => {
    close()
    setDocumentTypeToAdd(null)
    setDocumentTypeToEdit(null)
  }, [close, setDocumentTypeToAdd, setDocumentTypeToEdit])

  const onSubmit = useCallback(async () => {
    const {
      name,
      isStandard,
      collapsible,
      tableShowsPreset,
      cargowiseFileTypeId,
      isEDocPublishedByDefault,
      autofillExtractorKey,
    } = getValues()
    const trimmedName = name.trim()
    const errors = validateDocTypeName(trimmedName)
    if (Object.keys(errors).length) {
      Object.entries(errors).forEach(([fieldName, errorMessage], index) => {
        setError(
          // setError only accepts the string literals, so we need to cast to any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          fieldName as unknown as any,
          { type: 'validate', message: errorMessage },
          { shouldFocus: index === 0 },
        )
      })
      enqueueSnackbar(`${Object.values(errors)[0]}`, { variant: 'error' })
      return
    }
    if (documentTypeToEdit) {
      await handleUpdateDocumentTypeOption(
        trimmedName,
        isStandard,
        collapsible,
        tableShowsPreset,
        cargowiseFileTypeId,
        isEDocPublishedByDefault,
        autofillExtractorKey,
      )
    } else {
      await handleNewDocumentTypeOptions(
        trimmedName,
        isStandard,
        collapsible,
        tableShowsPreset,
        cargowiseFileTypeId,
        isEDocPublishedByDefault,
        autofillExtractorKey,
      )
    }
    handleClose()
  }, [
    handleUpdateDocumentTypeOption,
    handleNewDocumentTypeOptions,
    validateDocTypeName,
    getValues,
    enqueueSnackbar,
    documentTypeToEdit,
    handleClose,
    setError,
  ])

  return (
    <Dialog classes={{ paper: classes.dialog }} open={isOpen}>
      <FormProvider {...formMethods}>
        <Box className={classes.close}>
          <CloseIcon fontSize='large' onClick={handleClose} data-testid='close-btn' />
        </Box>
        <DialogTitle>
          <Box p={1}>
            <Typography variant='h1'>Document Type Options</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display='flex' alignItems='center' className={classes.documentTypeName}>
            <Controller
              render={({ field: { value, onBlur, onChange }, fieldState: { error, invalid } }) => (
                <TextField
                  variant='outlined'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  InputProps={{
                    className: classes.documentTypeNameInput,
                  }}
                  label='Document Type Name'
                  error={invalid}
                  helperText={error?.message}
                />
              )}
              name='name'
              defaultValue={currentDocumentType?.name}
            />
          </Box>
          <Box display='flex' alignItems='center'>
            <Typography>Standard</Typography>
            <Tooltip
              title={
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Is Standard Document</span> - Toggling this
                  option will make this document type show up in the initial list of available “Doc
                  Types” for the other job templates.
                </Typography>
              }
            >
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
            <Controller
              render={({ field: { value, onBlur, onChange } }) => (
                <Checkbox
                  checked={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  className={classes.checkbox}
                />
              )}
              name='isStandard'
              defaultValue={currentDocumentType?.isStandard}
            />
          </Box>
          <Box display='flex' alignItems='center'>
            <Typography>Collapsible</Typography>
            <Tooltip
              title={
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Is Collapsible</span> - Toggling this option
                  will allow the operators to hide all the fields belonging to this document type in
                  an accordion.
                </Typography>
              }
            >
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
            <Controller
              render={({ field: { value, onBlur, onChange } }) => (
                <Checkbox
                  checked={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  className={classes.checkbox}
                />
              )}
              name='collapsible'
              defaultValue={currentDocumentType?.collapsible}
            />
          </Box>
          <Box display='flex' alignItems='center'>
            <Typography>EDocs published by default</Typography>
            <Tooltip
              title={
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Is EDocs published by default</span> -
                  Toggling this will set the option to publish EDocs to be true by default. The
                  option can still be overriden manually before uploading
                </Typography>
              }
            >
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
            <Controller
              render={({ field: { value, onBlur, onChange } }) => (
                <Checkbox
                  checked={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  className={classes.checkbox}
                />
              )}
              name='isEDocPublishedByDefault'
              defaultValue={currentDocumentType?.isEDocPublishedByDefault}
            />
          </Box>
          <Box display='flex' alignItems='center'>
            <Typography>Tables show preset columns</Typography>
            <Tooltip
              title={
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Table shows preset columns</span> - Toggling
                  this option will allow the operators to force the app to always display ALL
                  columns associated to the document type. The columns will be displayed in the
                  order that they are specified in this admin page. Toggling this option will also
                  disable the operator’s ability to hide or show the columns.
                </Typography>
              }
            >
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
            <Controller
              render={({ field: { value, onBlur, onChange } }) => (
                <Checkbox
                  checked={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  className={classes.checkbox}
                />
              )}
              name='tableShowsPreset'
              defaultValue={currentDocumentType?.tableShowsPreset}
            />
          </Box>
          <Box display='flex' alignItems='center'>
            <Typography>CW File Type</Typography>
            <div className={classes.cwFileTypeAutocomplete}>
              <CargowiseFileTypeSelector companyId={companyId} />
            </div>
          </Box>
          {canEditAutofillExtractorKey && (
            <Box display='flex' alignItems='center'>
              <Typography>Autofill Extractor Key</Typography>
              <div className={classes.autofillExtractorKeyAutocomplete}>
                <DocumentTypeAutofillExtractorKeySelector />
              </div>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant='contained' color='primary' onClick={handleSubmit(onSubmit)}>
            {documentTypeToEdit ? 'Update Document Type' : 'Add Document Type'}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}

export default DocumentTypeOptionsDialog
