import { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/styles'
import { grey } from '@material-ui/core/colors'
import { CredentialNode } from '@src/graphql/types'
import TestCredentialButton from '@src/components/admin/admin-cargowise/TestCredentialButton'

const useStyles = makeStyles({
  actionIcon: {
    color: grey[900],
  },
})

type Props = {
  credentials: CredentialNode[]
  onDelete: (id: string) => Promise<void>
  onUpdate: (credential: CredentialNode) => void
}

const CredentialsTable: FunctionComponent<Props> = ({ credentials, onDelete, onUpdate }) => {
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Actions</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Password</TableCell>
            <TableCell>Api Endpoint</TableCell>
            <TableCell>Workflow</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {credentials.map((credential) => (
            <TableRow key={credential.id}>
              <TableCell align='center'>
                <Box display='flex' justifyContent='center'>
                  <IconButton
                    aria-label='edit'
                    size='small'
                    className={classes.actionIcon}
                    onClick={() => onUpdate(credential)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label='delete'
                    size='small'
                    className={classes.actionIcon}
                    onClick={() => onDelete(credential.id)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                  <TestCredentialButton credentialId={credential.id} />
                </Box>
              </TableCell>
              <TableCell>{credential.username}</TableCell>
              <TableCell>[hidden]</TableCell>
              <TableCell>{credential.apiEndpoint}</TableCell>
              <TableCell>{credential.workflow}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CredentialsTable
