import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { clsx } from 'clsx'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { v4 as uuidv4 } from 'uuid'

import SelectionBox from '@src/components/selection-box'
import useCreateBox from '@src/hooks/boxn/useCreateBox'
import {
  selectRowBox,
  resetHighlightedBoxes,
  setCreateBoxEnabled,
  setIsRowBoxDraggingOrResizing,
  toggleRowBox,
  createFieldBox,
} from '@src/redux-features/document_editor'
import { selectActiveDocument } from '@src/redux-features/document_editor/document'
import { selectRepeatableFieldKeyMap } from '@src/redux-features/document_editor/field'
import { BoxDimension } from '@src/types/ocr'
import { DEFAULT_BOX_DIMENSION, LINE_THRESHOLD, ROW_BOX_STYLES } from '@src/utils/app_constants'
import {
  isJobTableLineItem,
  JobTableFieldValue,
  JobTableLineItem,
  LineItem,
} from '@src/utils/line_items'
import { RootState } from '@src/utils/store'
import FieldBox from './FieldBox'

type StyleProps = {
  dimension: BoxDimension
  imageWidth: number
  imageHeight: number
}

const useStyles = makeStyles<Theme, StyleProps>({
  rowBox: {
    position: 'absolute',
    left: (props) => props.dimension.left * props.imageWidth,
    top: (props) => props.dimension.top * props.imageHeight,
    width: (props) => props.dimension.width * props.imageWidth,
    height: (props) => props.dimension.height * props.imageHeight,
    zIndex: ROW_BOX_STYLES.Z_INDEX,
  },
  activeRow: {
    border: `1px solid ${ROW_BOX_STYLES.BORDER_COLOR}`,
  },
  inactiveRow: {
    border: `1px dotted ${ROW_BOX_STYLES.BORDER_COLOR}`,
  },
  hoveredRow: {
    border: `1px solid ${ROW_BOX_STYLES.BORDER_COLOR}`,
  },
})

type Props = {
  lineItem: LineItem | JobTableLineItem
  imageBoxRef: MutableRefObject<HTMLImageElement | null>
  setColumnAnchorEl: (anchorEl: HTMLDivElement | null) => void
  panningEnabled: boolean
}

const RowBox: FunctionComponent<Props> = ({
  lineItem,
  imageBoxRef,
  setColumnAnchorEl,
  panningEnabled,
}) => {
  const rowRef = useRef(null as HTMLDivElement | null)
  const [hovered, setHovered] = useState(false)
  const dispatch = useDispatch()

  const activeDocument = useSelector((state: RootState) =>
    selectActiveDocument(state.documentEditor),
  )
  const activeDocumentId = useMemo(() => {
    if (activeDocument) {
      return activeDocument.id
    }
  }, [activeDocument])
  const rowDimension = useMemo(() => {
    if (activeDocumentId) {
      const box = isJobTableLineItem(lineItem)
        ? lineItem.boxMapping[activeDocumentId]
        : lineItem.box
      return box || DEFAULT_BOX_DIMENSION
    } else {
      return DEFAULT_BOX_DIMENSION
    }
  }, [activeDocumentId, lineItem])
  const imageWidth = imageBoxRef.current?.width || 0
  const imageHeight = imageBoxRef.current?.height || 0
  const classes = useStyles({ dimension: rowDimension, imageWidth, imageHeight })

  const activeLineItemIds = useSelector(
    (state: RootState) => state.documentEditor.activeLineItemIds,
  )
  const highlightedRowBoxIds = useSelector(
    (state: RootState) => state.documentEditor.boxn.highlightedRowBoxIds,
  )
  const isRowBoxDraggingOrResizing = useSelector(
    (state: RootState) => state.documentEditor.boxn.isRowBoxDraggingOrResizing,
  )
  const isRowActive = useMemo(() => {
    return activeLineItemIds.includes(lineItem.id)
  }, [activeLineItemIds, lineItem.id])
  const isRowHighlighted = useMemo(() => {
    return highlightedRowBoxIds.includes(lineItem.id)
  }, [highlightedRowBoxIds, lineItem.id])

  const repeatableFieldKeyMap = useSelector((state: RootState) =>
    selectRepeatableFieldKeyMap(state.documentEditor),
  )

  const { end, boxDimension, isMouseDown, handleMouseDown } = useCreateBox(
    !isRowBoxDraggingOrResizing,
    imageBoxRef,
    rowRef,
  )

  const fieldBoxes = useMemo(() => {
    return Object.entries(lineItem.fieldMapping)
      .filter(
        ([_key, fieldMapping]) =>
          !isJobTableLineItem(lineItem) ||
          (fieldMapping as JobTableFieldValue).documentId === activeDocumentId,
      )
      .map(([key, fieldMapping]) => {
        const id = fieldMapping?.id ?? uuidv4()
        const label = repeatableFieldKeyMap?.[key]?.name ?? ''
        const fieldDimension = {
          left: fieldMapping?.left ?? 0,
          top: fieldMapping?.top ?? 0,
          width: fieldMapping?.width ?? 0,
          height: fieldMapping?.height ?? 0,
        }
        return { id, label, dimension: fieldDimension }
      })
  }, [lineItem, repeatableFieldKeyMap, activeDocumentId])

  useEffect(() => {
    if (isMouseDown) {
      return
    }
    const isWidthTooSmall = boxDimension.width <= LINE_THRESHOLD
    const isHeightTooSmall = boxDimension.height <= LINE_THRESHOLD
    const isBoxTooSmallOrMoving = isWidthTooSmall || isHeightTooSmall || isRowBoxDraggingOrResizing
    if (isBoxTooSmallOrMoving) {
      return
    }
    batch(() => {
      dispatch(createFieldBox(boxDimension))
      dispatch(setCreateBoxEnabled(false))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMouseDown, end])

  const handleClick = useCallback(
    (evt: MouseEvent) => {
      if (rowRef.current?.contains(evt.target as HTMLDivElement)) {
        batch(() => {
          if (evt.ctrlKey || evt.metaKey) {
            dispatch(toggleRowBox(lineItem.id))
          } else {
            dispatch(selectRowBox(lineItem.id))
            dispatch(setIsRowBoxDraggingOrResizing(false))
          }
          dispatch(resetHighlightedBoxes())
        })
      }
    },
    [dispatch, lineItem.id, rowRef],
  )
  useEffect(() => {
    const rowElement = rowRef.current
    rowElement?.addEventListener('mousedown', handleClick)
    return () => {
      rowElement?.removeEventListener('mousedown', handleClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnMouseEnter = (): void => {
    setHovered(true)
  }

  const handleOnMouseLeave = (): void => {
    setHovered(false)
  }

  return (
    <div>
      {fieldBoxes
        ?.filter((fieldBox) => fieldBox.dimension.width > 0 && fieldBox.dimension.height > 0)
        .map((fieldBox) => {
          return (
            <FieldBox
              key={fieldBox.id}
              lineItem={lineItem}
              fieldBox={fieldBox}
              imageBoxRef={imageBoxRef}
              setColumnAnchorEl={setColumnAnchorEl}
            />
          )
        })}
      <div
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onMouseDown={handleMouseDown}
      >
        {!panningEnabled && isRowActive && !isRowBoxDraggingOrResizing && isMouseDown && (
          <SelectionBox
            top={boxDimension.top * imageHeight}
            left={boxDimension.left * imageWidth}
            width={boxDimension.width * imageWidth}
            height={boxDimension.height * imageHeight}
          />
        )}
        <div
          id={lineItem.id}
          ref={rowRef}
          className={clsx(classes.rowBox, {
            [classes.inactiveRow]: !isRowActive,
            [classes.activeRow]: isRowActive,
            [classes.hoveredRow]: hovered || isRowHighlighted,
          })}
        />
      </div>
    </div>
  )
}

export default RowBox
