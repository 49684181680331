import { formatMaybeApolloError } from '@src/utils/errors'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import Box from '@material-ui/core/Box'
import { Skeleton } from '@material-ui/lab'
import Handsontable from 'handsontable'
import 'handsontable/dist/handsontable.full.css'
import { useSnackbar } from 'notistack'
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import useBulkReplace from '@src/components/data-grid/hooks/useBulkReplace'
import useRerenderOnCollapse from '@src/components/data-grid/hooks/useRerenderOnCollapse'
import useRerenderOnResize from '@src/components/data-grid/hooks/useRerenderOnResize'
import useSaveHotkey from '@src/components/data-grid/hooks/useSaveHotkey'
import {
  SUBTOTAL_ROW_HEADER,
  getSubtotalWidths,
  getSubtotalsFromRows,
  isSameShippingGroup,
  replaceCellValues,
  getInputSoaRowMatchCriteria,
} from '@src/components/data-grid/util'
import { ShipmentFormContext } from '@src/contexts/shipment_form_context'
import { SAVE_JOB_AND_DOCUMENT_TABLES } from '@src/graphql/mutations/job'
import { MATCH_SOA_LINE_ITEMS_TO_CHAIN_IO_SHIPMENTS } from '@src/graphql/queries/soa'
import {
  SEARCHABLE_RECORD_RESULTS,
  SEARCHABLE_RECORD_RESULTS_FOR_VALIDATION,
} from '@src/graphql/queries/searchableRecord'
import {
  ApReconAutofillKey,
  DocumentTypeNode,
  InputSearchableRecordColumn,
  JobNode,
  JobTemplateReconType,
  Mutation,
  MutationSaveJobAndDocumentTablesArgs,
  Query,
  QueryMatchSoaLineItemsToChainIoShipmentsArgs,
  QuerySearchableRecordResultsArgs,
  QueryValidSearchableRecordResultsArgs,
  useChargeCodesByConfigAndVendorQuery,
} from '@src/graphql/types'
import {
  resetHighlightedBoxes,
  selectRowBox,
  setActiveFieldKey,
  setActiveDocumentTable,
  updateHighlightedBoxes,
} from '@src/redux-features/document_editor'
import { selectActiveDocument } from '@src/redux-features/document_editor/document'
import { selectRepeatableFieldsFromJobOrDocTable } from '@src/redux-features/document_editor/field'
import { setCurrentFilePageId } from '@src/redux-features/job_editor'
import { COLORS, HANDSONTABLE_ROW_HEIGHT } from '@src/utils/app_constants'
import {
  errorCellRenderer,
  getHandsontableHooksAndActions,
  getSaveTable,
  getInputSearchableRecordColumns,
  SpreadsheetCellMeta,
} from '@src/utils/data-grid'
import { checkMissingFields, validateSoaTableFields } from '@src/utils/errors'
import { getColumnKeys, getFilePageFromDocId } from '@src/utils/shipment_form'
import { RootState } from '@src/utils/store'
import BulkReplaceDialog from './BulkReplaceDialog'
import createContextMenu from './ContextMenu'
import DataGridToolbar from './DataGridToolbar'
import { TableExtractionStep } from '@src/redux-features/document_editor/magic_grid'
import {
  selectActiveJobOrDocTableLineItems,
  selectColumnHeaders,
  selectFormattedColumns,
  selectFormattedRows,
} from '@src/redux-features/document_editor/rows_columns_selectors'
import { JobTableLineItem } from '@src/utils/line_items'
import { handleBeforeTableDatePaste } from '@src/utils/date'
import useSoaReconOverrideOptions from '@src/hooks/soa/use_soa_recon_override_options'
import BatchReconciliationDialog from '@src/components/reconciliation-dialog/BatchReconciliationDialog'
import SoaReconOptionsDialog from '@src/components/reconciliation-dialog/SoaReconOptionsDialog'
import FastHotTable, { FastHotTableRefValue } from '../fast-hot-table/FastHotTable'
import { getSearchableRecordsValidationMap } from '../../utils/data-grid'
import { isEqual } from 'lodash'
import { isCargowiseConfig } from '@src/utils/api_partner'
import { makeStyles } from '@material-ui/core'
import { ChargeCodeTax } from './types'
import { stateHasMainTable } from '@src/redux-features/document_editor/job_table'

type Props = {
  job: JobNode
  documentType: DocumentTypeNode
}

const useStyles = makeStyles({
  tableWrapper: {
    overflow: 'hidden',
    flex: '1',
  },
  // using !important tag here to overwrite default borders and colors from Handsontable components
  subtotalRowHeader: {
    backgroundColor: 'yellow !important',
    borderTop: '1px solid #CCC !important',
  },
  fillerRowHeader: {
    backgroundColor: 'white !important',
    border: '0 !important',
  },
})

/**
 * Expected flow of data:
 * User makes an edit to the handsontable -> before the change is reflected in the UI, we update our state and cancel the action (return false) -> we re-render the table using the updated state
 *
 * We must try to maintain a single source of truth.
 * That is to say we should always keep the redux state updated for every change made to the table,
 * and we should always refer to the state when performing logic (e.g. making requests to save, etc)
 * - i.e.: avoid using hotInstance.getData(), which takes data from the handsontable, and instead use getRows(), which takes data from the redux state
 *
 * Link to the design doc: https://www.notion.so/Handsontable-State-Management-Refactor-7c37307d0e3a4ae98f8ff311da075a5d
 */
const MainTable: FunctionComponent<Props> = ({ job, documentType }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const {
    overrideChargeDescription,
    setOverrideChargeDescription,
    disableSendDueDate,
    setDisableSendDueDate,
    reconAsLumpsum,
    toggleReconMatchCriteria,
    setReconAsLumpsum,
    reconcileMatchCriteria,
    isSoaReconOptionsDialogOpen,
    closeSoaReconOptionsDialog,
    openSoaReconOptionsDialog,
    isBatchReconciliationDialogOpen,
    closeSoaReconDialog,
    openSoaReconDialog,
  } = useSoaReconOverrideOptions()

  const lineItems = useSelector(
    (state: RootState) => selectActiveJobOrDocTableLineItems(state.documentEditor)!,
  ) as JobTableLineItem[]

  const hasMainTab = useSelector(
    (state: RootState) => stateHasMainTable(state.documentEditor)!,
  ) as boolean

  const repeatableFields = useSelector((state: RootState) =>
    selectRepeatableFieldsFromJobOrDocTable(state.documentEditor),
  )

  // isJobTableActive should always be true if the MainTable/ job table is being displayed
  const isJobTableActive = useSelector((state: RootState) => state.documentEditor.jobTableActive)

  const { updateDocumentMapping } = useContext(ShipmentFormContext)

  const activeDocument = useSelector((state: RootState) =>
    selectActiveDocument(state.documentEditor),
  )
  const activeDocumentId = activeDocument?.id

  const hotTableRef = useRef<FastHotTableRefValue>()
  const resizeListener = useRerenderOnResize(hotTableRef)
  useRerenderOnCollapse(hotTableRef)

  const subtotalTableRef = useRef<FastHotTableRefValue>()
  const subtotalResizeListener = useRerenderOnResize(subtotalTableRef)
  useRerenderOnCollapse(subtotalTableRef)

  const subtotalColWidths = useMemo(() => {
    if (hotTableRef.current) {
      return getSubtotalWidths(hotTableRef.current)
    }
    return []
  }, [hotTableRef, getSubtotalWidths, hotTableRef.current])

  const shouldShowSubtotals = job.jobTemplate.subtotalsRowEnabled

  const vendor =
    activeDocument?.documentFieldGroups?.edges
      .filter((docFieldGroup) => !docFieldGroup?.node?.fieldGroup?.repeatable)
      .map((docFieldGroup) => docFieldGroup?.node?.documentFields?.edges[0]?.node)
      .find(
        (docField) =>
          ApReconAutofillKey.Vendor.toLowerCase() === docField?.field?.autofillKey.toLowerCase(),
      )?.value ?? ''

  const { refetch: refetchSearchableRecords } = useQuery<
    Pick<Query, 'searchableRecordResults'>,
    QuerySearchableRecordResultsArgs
  >(SEARCHABLE_RECORD_RESULTS, {
    skip: true,
    context: {
      debounceKey: 'searchableRecordResults',
      debounceTimeout: 300,
    },
  })
  const { refetch: refetchMatchSoaLineItemsToChainIoShipments } = useQuery<
    Pick<Query, 'matchSoaLineItemsToChainIoShipments'>,
    QueryMatchSoaLineItemsToChainIoShipmentsArgs
  >(MATCH_SOA_LINE_ITEMS_TO_CHAIN_IO_SHIPMENTS, { skip: true })
  const [saveJobAndDocumentTables] = useMutation<
    Pick<Mutation, 'saveJobAndDocumentTables'>,
    MutationSaveJobAndDocumentTablesArgs
  >(SAVE_JOB_AND_DOCUMENT_TABLES)
  const [
    fetchSearchableRecordsForValidation,
    { loading: validSearchableRecordResultsLoading, data: validSearchableRecordResultsData },
  ] = useLazyQuery<
    Pick<Query, 'validSearchableRecordResults'>,
    QueryValidSearchableRecordResultsArgs
  >(SEARCHABLE_RECORD_RESULTS_FOR_VALIDATION, {
    onError: (err) => {
      enqueueSnackbar(`Failed to validate searchable records: ${formatMaybeApolloError(err)}`, {
        variant: 'error',
      })
    },
  })

  const { data: chargeCodesByConfigAndVendorData } = useChargeCodesByConfigAndVendorQuery({
    variables: {
      apiPartnerId: job?.jobTemplate?.apiPartner?.id ?? null,
      vendorName: vendor,
    },
  })
  const chargeCodeTaxMap = useMemo(() => {
    if (
      chargeCodesByConfigAndVendorData?.chargeCodesByConfigAndVendor &&
      chargeCodesByConfigAndVendorData?.chargeCodesByConfigAndVendor.length > 0
    ) {
      const taxMap: Record<string, ChargeCodeTax> = {}
      return chargeCodesByConfigAndVendorData.chargeCodesByConfigAndVendor.reduce(
        (chargeCodeTaxMapAcc, chargeCode) => {
          if (chargeCode.tax) {
            chargeCodeTaxMapAcc[chargeCode.code] = chargeCode.tax
          }
          return chargeCodeTaxMapAcc
        },
        taxMap,
      )
    }
    return null
  }, [chargeCodesByConfigAndVendorData])

  const columns = useSelector((state: RootState) =>
    selectFormattedColumns(state.documentEditor, refetchSearchableRecords, enqueueSnackbar),
  )
  const colHeaders = useSelector((state: RootState) => selectColumnHeaders(state.documentEditor))
  const rows = useSelector((state: RootState) => selectFormattedRows(state.documentEditor))
  const subtotalRow = useMemo(() => getSubtotalsFromRows(rows, columns), [rows, columns])

  const pages = useSelector((state: RootState) => state.documentEditor.pageFieldEditorState?.pages)
  const company = useSelector((state: RootState) => state.documentEditor.job?.jobTemplate?.company)
  const isCargowiseConfigMemo = useMemo(
    () => isCargowiseConfig(job.jobTemplate?.apiPartner),
    [job.jobTemplate?.apiPartner],
  )

  const [lastColumnSelected, setlastColumnSelected] = useState(0)
  const [soaLineItemShipmentMatchMapping, setSoaLineItemShipmentMatchMapping] = useState(
    [] as (string | null)[],
  )
  const dispatch = useDispatch()
  const { bulkReplaceOpen, setBulkReplaceOpen, openBulkReplace } = useBulkReplace()

  const { saveTable } = useMemo(() => {
    return getSaveTable(
      null,
      hotTableRef,
      getColumnKeys(columns),
      saveJobAndDocumentTables,
      enqueueSnackbar,
    )
  }, [columns, saveJobAndDocumentTables, enqueueSnackbar])

  useSaveHotkey(saveTable)

  const contextMenu = useMemo(() => {
    return createContextMenu(
      lastColumnSelected + 1,
      repeatableFields,
      columns.map((col) => col.key),
      documentType.tableShowsPreset,
      true,
      hasMainTab,
      dispatch,
      enqueueSnackbar,
    )
  }, [
    enqueueSnackbar,
    hasMainTab,
    lastColumnSelected,
    dispatch,
    columns,
    repeatableFields,
    documentType.tableShowsPreset,
  ])

  const currentTableExtractionStep = useSelector(
    (state: RootState) => state.documentEditor.currentTableExtractionStep,
  )

  const createBoxEnabled = useSelector(
    (state: RootState) => state.documentEditor.boxn.createBoxEnabled,
  )

  const checkSoaTableMissingFields = useCallback((): string | null => {
    return checkMissingFields(columns, rows)
  }, [columns, rows])

  const validateSoaTable = useCallback((): boolean => {
    const isSoaTableValid = validateSoaTableFields(columns, rows)
    return isSoaTableValid
  }, [columns, rows])

  useEffect(() => {
    const formattedRows = rows.map((row, rowIdx) => ({ data: row, rowIdx }))
    const criteria = getInputSoaRowMatchCriteria(colHeaders, formattedRows, repeatableFields)
    const fetchSoaLineItemShipmentMatches = async (): Promise<void> => {
      if (criteria.length) {
        const soaLineItemShipmentMatches = await refetchMatchSoaLineItemsToChainIoShipments({
          jobId: job.id,
          rowsMatchCriteria: criteria,
        })
        const newSoaLineItemShipmentMatchMapping =
          soaLineItemShipmentMatches.data?.matchSoaLineItemsToChainIoShipments?.map(
            (soaLineItemShipmentMatch) =>
              soaLineItemShipmentMatch.matchingInvoiceNumberChainIoShipmentId as string | null,
          ) || []
        setSoaLineItemShipmentMatchMapping(newSoaLineItemShipmentMatchMapping)
      } else {
        setSoaLineItemShipmentMatchMapping([])
      }
    }

    if (isCargowiseConfigMemo) {
      void fetchSoaLineItemShipmentMatches()
    }
  }, [
    job.id,
    repeatableFields,
    rows,
    colHeaders,
    refetchMatchSoaLineItemsToChainIoShipments,
    job.jobTemplate.apiPartner,
    isCargowiseConfigMemo,
  ])

  // Highlight rows with the same ChainIOShipment ID
  const afterOnCellMouseOver = useCallback(
    (_e: MouseEvent, coords: Handsontable.wot.CellCoords, _TD: Element): void => {
      const formattedRows = rows.map((row, rowIdx) => ({ data: row, rowIdx }))
      const criteria = getInputSoaRowMatchCriteria(colHeaders, formattedRows, repeatableFields)
      const containerDataHotTableRows = lineItems.length
      for (let rowIdx = 0; rowIdx < containerDataHotTableRows; rowIdx++) {
        for (let colIdx = 0; colIdx < colHeaders.length; colIdx++) {
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          const cell = hotTableRef.current!.hotInstance.getCell(rowIdx, colIdx) as unknown as any
          const cellMeta = hotTableRef.current!.hotInstance.getCellMeta(rowIdx, colIdx)
          const isInvalidCell = cellMeta?.renderer === errorCellRenderer
          if (cell && !isInvalidCell) {
            if (
              rowIdx === coords['row'] ||
              (soaLineItemShipmentMatchMapping[rowIdx] &&
                soaLineItemShipmentMatchMapping[rowIdx] ===
                  soaLineItemShipmentMatchMapping[coords['row']] &&
                isSameShippingGroup(criteria[rowIdx], criteria[coords['row']]))
            ) {
              cell.style.background = COLORS.PALE_YELLOW
              cell.style.boxShadow = '0 0 0 1px yellow inset'
            } else {
              cell.style.background = ''
              cell.style.boxShadow = ''
            }
          }
        }
      }
    },
    [lineItems, colHeaders, soaLineItemShipmentMatchMapping],
  )

  const afterSelection = useMemo(() => {
    const goToLineItemFilePage = (startRow: number, startCol: number): void => {
      const col = columns[startCol]
      const field = lineItems[startRow]?.fieldMapping[col.key]
      if (field) {
        const filePage = getFilePageFromDocId(job, field.documentId)
        const document = filePage?.document
        const documentTables = document?.documentTables?.edges
        if (filePage && documentTables?.length && activeDocumentId !== document?.id) {
          batch(() => {
            updateDocumentMapping(filePage!.id)
            dispatch(setCurrentFilePageId(filePage!.id))
            dispatch(setActiveFieldKey(null))
          })
          setTimeout(() => {
            dispatch(setActiveDocumentTable(documentTables[0]!.node!.id))
          })
        }
      }
    }
    return (startRow: number, startCol: number, endRow: number, endCol: number) => {
      goToLineItemFilePage(startRow, startCol)
      if (hotTableRef.current) {
        const selectedCells = hotTableRef.current.hotInstance.getSelectedLast()
        setlastColumnSelected(selectedCells ? selectedCells[3] : 0)
      }
      if (startRow === endRow) {
        batch(() => {
          dispatch(selectRowBox(lineItems[startRow].id))
          dispatch(resetHighlightedBoxes())
        })
      } else {
        batch(() => {
          dispatch(selectRowBox(null))
          dispatch(
            updateHighlightedBoxes({
              startRow,
              startCol,
              endRow,
              endCol,
              columnKeys: columns.map((column) => column.key),
              isJobTable: true,
            }),
          )
        })
      }
    }
  }, [activeDocumentId, columns, dispatch, job, updateDocumentMapping, lineItems])

  const {
    beforeCopy,
    beforeColumnMove,
    beforeRemoveRow,
    beforeCreateRow,
    beforeChange,
    beforeOnCellContextMenu,
    afterLoadData,
    unhighlightRowAndFieldBoxes,
    copyFromExtracted,
    getCellMetaForIndex,
  } = useMemo(
    () =>
      getHandsontableHooksAndActions(
        columns,
        null,
        createBoxEnabled,
        hotTableRef,
        isJobTableActive,
        chargeCodeTaxMap,
      ),
    [columns, createBoxEnabled, isJobTableActive, chargeCodeTaxMap],
  )

  useEffect(() => {
    if (lineItems.length === 0) {
      // we always need at least 1 blank row for SOA
      beforeCreateRow(0, 1, 'ContextMenu.below')
    }
  }, [lineItems, beforeCreateRow])

  const afterGetRowHeader = useCallback((row: number, TH: Element): void => {
    TH.className = classes.subtotalRowHeader
  }, [])

  const handleAfterScrollHorizontally = useCallback(
    (table: Handsontable | undefined, tableToScroll: Handsontable | undefined): void => {
      if (!table || !tableToScroll) return
      const originalTable = table
      const autoColSize = originalTable.getPlugin('autoColumnSize')
      const firstCol = autoColSize.getFirstVisibleColumn()
      tableToScroll.scrollViewportTo(undefined, firstCol, undefined, undefined)
    },
    [],
  )

  const hooks = useMemo(
    () =>
      ({
        beforeChange,
        beforeColumnMove,
        beforeCreateRow,
        beforeRemoveRow,
        beforeCopy,
        beforeOnCellContextMenu,
        afterOnCellMouseOver,
        afterLoadData,
        afterSelection,
        afterDeselect: unhighlightRowAndFieldBoxes,
        beforePaste: (data, coords) => handleBeforeTableDatePaste(columns, data, coords[0]),
        afterScrollHorizontally: () =>
          handleAfterScrollHorizontally(
            hotTableRef.current?.hotInstance,
            subtotalTableRef.current?.hotInstance,
          ),
      }) as Handsontable.Hooks,
    [
      afterLoadData,
      afterOnCellMouseOver,
      afterSelection,
      beforeChange,
      beforeColumnMove,
      beforeCopy,
      beforeCreateRow,
      beforeOnCellContextMenu,
      beforeRemoveRow,
      columns,
      unhighlightRowAndFieldBoxes,
      handleAfterScrollHorizontally,
    ],
  )

  const [searchableRecordsValidationMap, setSearchableRecordsValidationMap] = useState(
    {} as Record<number, Record<number, boolean>>,
  )
  const [inputSearchableRecordColumns, setInputSearchableRecordColumns] = useState(
    [] as InputSearchableRecordColumn[],
  )

  useEffect(() => {
    void fetchSearchableRecordsForValidation({
      variables: { searchableRecordInputs: inputSearchableRecordColumns },
    })
  }, [inputSearchableRecordColumns, fetchSearchableRecordsForValidation])

  useEffect(() => {
    /**
     * We want to update the searchableRecordsValidationMap whenever the rows change
     * but if we're going to need to query for the updated validSearchableRecordResultsData (if the input to the query changes),
     * we want to wait for that to finish first before we update the map
     */
    const inputSearchableRecordColumnsFromRows = getInputSearchableRecordColumns(
      rows,
      columns,
      pages,
      company,
      job?.jobTemplate.apiPartnerId,
    )
    if (!isEqual(inputSearchableRecordColumnsFromRows, inputSearchableRecordColumns)) {
      setInputSearchableRecordColumns(inputSearchableRecordColumnsFromRows)
    } else if (!validSearchableRecordResultsLoading && validSearchableRecordResultsData) {
      setSearchableRecordsValidationMap(
        getSearchableRecordsValidationMap(
          rows,
          columns,
          validSearchableRecordResultsData.validSearchableRecordResults,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rows,
    columns,
    pages,
    company,
    job?.jobTemplate.apiPartnerId,
    validSearchableRecordResultsData,
    validSearchableRecordResultsLoading,
  ])

  const getCellMetaAfterValidation = useCallback(
    (rowIdx: number, colIdx: number): SpreadsheetCellMeta => {
      return getCellMetaForIndex(
        rowIdx,
        colIdx,
        searchableRecordsValidationMap,
      ) as SpreadsheetCellMeta
    },
    [searchableRecordsValidationMap, getCellMetaForIndex],
  )

  const settings = useMemo(
    () =>
      ({
        cells: getCellMetaAfterValidation,
        comments: true,
        colHeaders,
        contextMenu,
        columns,
        rowHeaders: true,
        rowHeights: HANDSONTABLE_ROW_HEIGHT,
        // we can try to use viewportColumnRenderingOffset instead of manually calculating each row height
        // to improve performance
        // related ticket: https://expedock.atlassian.net/browse/PD-925
        viewportColumnRenderingOffset: columns.length,
        viewportRowRenderingOffset: 50,
        stretchH: 'all',
        manualColumnResize: true,
        manualColumnMove: true,
        persistentState: false,
      }) as Handsontable.GridSettings,
    [colHeaders, columns, contextMenu, getCellMetaAfterValidation],
  )

  const subtotalCellRenderer = function (
    instance: Handsontable,
    td: HTMLElement,
    row: number,
    col: number,
    prop: string | number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    cellProperties: Handsontable.GridSettings,
  ): void {
    Handsontable.renderers.TextRenderer(instance, td, row, col, prop, value, cellProperties)
    td.style.background = COLORS.PALE_YELLOW
    td.style.borderTop = '1px solid #CCC'
    // Handsontable typing has wrong return type lol
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any as Handsontable.renderers.Base

  const subtotalHooks: Handsontable.Hooks = useMemo(
    () => ({
      afterGetRowHeader,
    }),
    [afterGetRowHeader],
  )

  const getSubtotalCellMeta = useCallback((): SpreadsheetCellMeta => {
    return {
      readOnly: true,
      renderer: subtotalCellRenderer,
    }
  }, [subtotalCellRenderer])

  const subtotalSettings: Handsontable.GridSettings = useMemo(() => {
    return {
      cells: getSubtotalCellMeta,
      columns,
      colHeaders,
      colWidths: subtotalColWidths,
      rowHeaders: [SUBTOTAL_ROW_HEADER],
      rowHeights: HANDSONTABLE_ROW_HEIGHT,
      viewportColumnRenderingOffset: columns.length,
      viewportRowRenderingOffset: 50,
      stretchH: 'all',
      persistentState: false,
    }
  }, [columns, getSubtotalCellMeta, subtotalColWidths, colHeaders])

  const isSoa = job.jobTemplate.reconType === JobTemplateReconType.Soa

  return (
    <>
      {resizeListener}
      {subtotalResizeListener}
      <DataGridToolbar
        job={job}
        numRows={rows.length}
        openBulkReplace={openBulkReplace}
        checkSoaTableMissingFields={isSoa ? checkSoaTableMissingFields : undefined}
        validateSoaTable={isSoa ? validateSoaTable : undefined}
        openBulkReconDialogAndReconcile={isSoa ? openSoaReconOptionsDialog : undefined}
        saveTable={saveTable}
        jobTableActive={isJobTableActive}
        copyFromExtracted={copyFromExtracted}
      />
      {(currentTableExtractionStep !== TableExtractionStep.Idle && (
        <Skeleton variant='rect' width='100%' height='100vh' />
      )) || (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='stretch'
          height='100%'
          className={classes.tableWrapper}
          overflow='hidden'
          flex={1}
        >
          <Box overflow='hidden' flex={1} id='soa-main-table' data-testid='soa-main-table'>
            <FastHotTable hotTableRef={hotTableRef} data={rows} settings={settings} hooks={hooks} />
          </Box>
          {shouldShowSubtotals && (
            <Box height='20%' overflow='hidden'>
              <FastHotTable
                hotTableRef={subtotalTableRef}
                data={[subtotalRow]}
                settings={subtotalSettings}
                hooks={subtotalHooks}
              />
            </Box>
          )}
        </Box>
      )}
      {bulkReplaceOpen && (
        <BulkReplaceDialog
          open={bulkReplaceOpen}
          rows={rows}
          cols={columns}
          handleClickCancel={() => setBulkReplaceOpen(false)}
          replace={(changes: [number, number, string][]) => replaceCellValues(hotTableRef, changes)}
        />
      )}
      {isBatchReconciliationDialogOpen && (
        <BatchReconciliationDialog
          isOpen={isBatchReconciliationDialogOpen}
          closePopup={closeSoaReconDialog}
          openSoaReconOptionsDialog={openSoaReconOptionsDialog}
          jobId={job.id}
          overrideChargeDescription={overrideChargeDescription}
          disableSendDueDate={disableSendDueDate}
          reconcileMatchCriteria={reconcileMatchCriteria}
          reconAsLumpsum={reconAsLumpsum}
        />
      )}
      {isSoaReconOptionsDialogOpen && (
        <SoaReconOptionsDialog
          jobId={job.id}
          overrideChargeDescription={overrideChargeDescription}
          setOverrideChargeDescription={setOverrideChargeDescription}
          disableSendDueDate={disableSendDueDate}
          setDisableSendDueDate={setDisableSendDueDate}
          reconcileMatchCriteria={reconcileMatchCriteria}
          toggleReconcileMatchCriteria={toggleReconMatchCriteria}
          reconAsLumpsum={reconAsLumpsum}
          setReconAsLumpsum={setReconAsLumpsum}
          reconcileSoa={openSoaReconDialog}
          closeDialog={closeSoaReconOptionsDialog}
        />
      )}
    </>
  )
}

export default MainTable
