import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useMemo } from 'react'
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { HotTable } from '@handsontable/react'
import { useRef } from 'react'

import { useDeleteChargeCodesAndOverridesMutation } from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import ModalCloseButton from '../ModalCloseButton'
import { ChargeCodeWithOverride } from './types'

type Props = {
  chargeCode: ChargeCodeWithOverride
  setOpen: (open: boolean) => void
  resetMappingState: () => void
}

const columns = [
  {
    key: 'vendor',
    name: 'Vendor',
  },
  {
    key: 'description',
    name: 'Description',
  },
]

const DeleteChargeCodeAndOverridesModal: FunctionComponent<Props> = ({
  chargeCode,
  setOpen,
  resetMappingState,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const hotTableRef = useRef<HotTable>(null)

  const rows = useMemo(() => {
    const overrides = chargeCode.chargeCodeVendorOverrides?.edges || []
    const newRows: string[][] = []
    for (const node of overrides) {
      if (!node.node.dateDeleted) {
        newRows.push([node.node.chargeVendor.name, node.node.description])
      }
    }
    return newRows
  }, [chargeCode])

  const [deleteChargeCodes] = useDeleteChargeCodesAndOverridesMutation({
    onCompleted: () => {
      enqueueSnackbar('Charge codes deleted', { variant: 'success' })
      resetMappingState()
      setOpen(false)
    },
    onError: (error) => {
      enqueueSnackbar(`Error deleting charge codes: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  return (
    <Dialog open={true} data-testid='delete-charge-code-modal'>
      <ModalCloseButton onClose={() => setOpen(false)} />
      <DialogTitle>Delete {chargeCode.code} and related vendor overrides</DialogTitle>
      <DialogContent>
        {rows.length > 0 ? (
          <>
            <Typography>Overrides for the following vendors will be deleted:</Typography>
            <HotTable
              id='vendor-code-override-table'
              ref={hotTableRef}
              style={{ width: '100%', overflow: 'hidden', height: '200px' }}
              data={rows}
              readOnly={true}
              rowHeaders
              colHeaders={columns.map((c) => c.name)}
              columnSorting={true}
              columns={columns}
              stretchH='all'
              autoRowSize={true}
            />
          </>
        ) : (
          <Typography>
            Do you want to delete this charge code and all of its associated overrides?
          </Typography>
        )}
        <Box display='flex' justifyContent='center' p={3}>
          <Box m={3}>
            <Button onClick={() => setOpen(false)} variant='contained'>
              Cancel
            </Button>
          </Box>
          <Box m={3}>
            <Button
              onClick={() => {
                void deleteChargeCodes({
                  variables: { chargeCodeIds: [chargeCode.id] },
                })
              }}
              variant='contained'
              color='primary'
            >
              Delete
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteChargeCodeAndOverridesModal
