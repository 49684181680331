import { gql } from '@apollo/client'

export const USER_PROFILE = gql`
  query userProfile {
    userProfile {
      auth0Id
      email
      name
      metabaseDashboardId
    }
  }
`

export const GET_USER_ROLES = gql`
  query getUserRoles {
    userProfile {
      roles
    }
  }
`
