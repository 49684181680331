import { FunctionComponent } from 'react'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/styles'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import { formatDateForTextField } from '@src/utils/date'
import theme from '@src/utils/theme'
import { JobNode } from '@src/graphql/types'

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
})

type Props = {
  isOpen: boolean
  close: () => void
  confirm: () => Promise<void>
  jobs: JobNode[]
  dateConfirmed?: string
}

const BlockTaskDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  confirm,
  jobs,
  dateConfirmed,
}) => {
  const classes = useStyles()

  return (
    <Dialog classes={{ paper: classes.dialog }} open={isOpen}>
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={close} data-testid='close-btn' />
      </Box>
      <DialogTitle>
        <Box p={1}>
          <Typography variant='h1'>Mark task as blocked</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {!!jobs.length && (
          <Box mb={2}>
            <Typography gutterBottom>
              Since all jobs need to be reviewed when the task is unblocked, the status of the
              following jobs will be moved back to QA
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Job Name</TableCell>
                    <TableCell>Job Type</TableCell>
                    <TableCell>Job Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobs.map((job) => (
                    <TableRow key={job.id}>
                      <TableCell>{job.name}</TableCell>
                      <TableCell>{job.jobTemplate?.name}</TableCell>
                      <TableCell>{job.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {dateConfirmed && (
          <Box mb={2}>
            <Typography gutterBottom>Date & time confirmed will be cleared</Typography>
            <TextField
              label='Date & Time Confirmed'
              type='datetime-local'
              disabled
              defaultValue={formatDateForTextField(dateConfirmed)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        )}
      </DialogContent>
      <Box display='flex' alignItems='center' justifyContent='center' mb={3}>
        <Button className={classes.cancelButton} onClick={close} variant='contained'>
          Cancel
        </Button>
        <Button onClick={confirm} color='primary' variant='contained'>
          Accept
        </Button>
      </Box>
    </Dialog>
  )
}

export default BlockTaskDialog
