import { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Card, CardHeader, CardContent } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import theme from '@src/utils/theme'
import { AVATAR_SIZE } from '@src/components/tasks/TaskCardHeader'
import randomInt from '@src/utils/random'

const SKELETON_CHIP_HEIGHT = theme.typography.h3.fontSize

const useStyles = makeStyles({
  skeletonCard: {
    margin: `0 0 ${theme.spacing(1)}px 0`,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.grey[200],
  },
  skeletonText: {
    margin: theme.spacing(1),
  },
  skeletonChip: {
    height: SKELETON_CHIP_HEIGHT,
    borderRadius: `calc(${SKELETON_CHIP_HEIGHT} / 2)`,
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
})

// This component does not accept any props so we use this type
type Props = Record<string, never>

const TaskColumnSkeleton: FunctionComponent<Props> = () => {
  const classes = useStyles()

  return (
    <>
      {[...Array(randomInt(3) + 1)].map((_, idx) => (
        <Card className={classes.skeletonCard} elevation={0} key={`${idx}`}>
          <CardHeader
            avatar={<Skeleton variant='circle' width={AVATAR_SIZE} height={AVATAR_SIZE} />}
            title={<Skeleton variant='text' width={'75%'} />}
          />
          <CardContent style={{ paddingTop: 0 }}>
            <Skeleton variant='rect' width={'70%'} className={classes.skeletonChip} />
            <Skeleton variant='text' className={classes.skeletonText} />
            <Skeleton variant='text' className={classes.skeletonText} />
            <Skeleton variant='text' className={classes.skeletonText} />
          </CardContent>
        </Card>
      ))}
    </>
  )
}

export default TaskColumnSkeleton
