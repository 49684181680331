import { closest } from 'fastest-levenshtein'
import produce from 'immer'
import { v4 as uuidv4 } from 'uuid'
import { BoxDimension, FieldCoordinates, GoogleOCRData } from '@src/types/ocr'
import {
  boxCornersToDimension,
  boxDimensionToCorners,
  hasDimension,
  uniteBoxCorners,
} from '@src/utils/magic_grid'
import { isPresent } from 'ts-is-present'
import {
  CARGOWISE_MODULE,
  LINE_ITEM_ID_KEY_SEPARATOR,
  LINE_THRESHOLD,
  MIDPOINT_THRESHOLD,
  SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING,
  SOA_LINE_ITEM_SENDING_SECONDARY_KEY_COLUMNS,
} from '@src/utils/app_constants'
import {
  DocumentNode,
  DocumentTableNode,
  FieldGroupNode,
  FieldNode,
  FieldType,
  FindShipmentReconResultNode,
  InputSoaLineItem,
  Maybe,
  SoaLineItemExpectedCharges,
  TaxNode,
  CwTargetModule,
} from '@src/graphql/types'
import {
  lineItemsAdapter,
  sortComparerWrapper,
} from '@src/redux-features/document_editor/line_items_table'
import { JobDocumentTable } from '@src/utils/shipment_form'
import { groupBy, uniqBy } from 'lodash'
import {
  extractWordFromCoordinates,
  applyCleanForField,
  getGoogleOcrWordBoundingBoxes,
} from '@src/utils/ocr'
import { reportRollbarError } from '@src/utils/observability/rollbar'
import { LineItemsTableMap } from '@src/redux-features/document_editor/line_items_table'
import { snapRowBox, constrainFieldMappingWithinRowBox, snapFieldBox } from '@src/utils/snapping'
import { FieldMappingValue } from '@src/types/fieldmapping'
import { isValidRecentDate } from './date'
import { isFallback } from '@src/utils/enum'
import {
  CwTargetModuleDisplayText,
  maybeFindReconModuleFromReconResults,
  convertCwModuleEnumToCwModuleDisplayText,
} from '@src/utils/recon/ap_recon'

export type SoaLineItem = Record<string, string | string[] | string[][] | boolean | undefined>

export type FieldValue = {
  id?: string
  field?: Partial<FieldNode> | null
  value: string
  top?: number | null
  left?: number | null
  width?: number | null
  height?: number | null
}

export type LineItem = {
  id: string
  rowOrderPriority?: number | null
  box?: BoxDimension
  documentTableId: string
  fieldMapping: Record<string, FieldValue>
  tableData?: {
    id: number
  }
}

export type JobTableFieldValue = {
  id?: string
  value: string
  top?: number | null
  left?: number | null
  width?: number | null
  height?: number | null
  documentId: string
}

export type JobTableLineItem = {
  id: string
  // A line item can be spread across different documents, so
  // we need to different box dimensioon per document
  boxMapping: { [documentId: string]: BoxDimension }
  fieldMapping: { [fieldKey: string]: JobTableFieldValue }
}

export enum ChainIoModelName {
  CHAIN_IO_SHIPMENT = 'Shipment',
  CHAIN_IO_CONSOLIDATION = 'Consolidation',
  CHAIN_IO_CUSTOMS_DECLARATION = 'Customs Declaration',
}

export const getColumnSupersetFromAllTables = (
  documentTables: JobDocumentTable[],
  lineItemsTableMap: LineItemsTableMap,
  jobTableColumns: string[],
): string[] => {
  let allColumns = [] as string[]
  documentTables.forEach((documentTable) => {
    allColumns = allColumns.concat(lineItemsTableMap[documentTable.id].columns)
  })
  allColumns = allColumns.concat(jobTableColumns)
  return [...new Set(allColumns)]
}

export const getColumnsToDisplay = (
  documentTable: Maybe<DocumentTableNode>,
  isDocumentTableFilePageExcel: boolean,
  isSoa: boolean,
): string[] => {
  /**
   * if tableShowsPreset is True, we display all columns
   * if tableShowsPreset is False, we display all saved columns + the required columns
   *
   * if the file page type for the table is EXCEL and we're on a SOA job,
   * there would be no Extract Tables for the file page (technically no document table displayed),
   * so we should avoid modifying it and just what is saved in the db
   *
   * but if we're not on a SOA job and the file page type is EXCEL, we'll always need to display at least one column
   * since we'll always need to have at least one row on the table,
   * so if tableShowsPreset is False and there aren't any saved or required columns, we could just display all
   * related ticket: https://expedock.atlassian.net/browse/PD-391
   */
  if (!documentTable) {
    return []
  }
  const tableShowsPreset = documentTable.document.documentType.tableShowsPreset
  const docTableFields = documentTable.fieldGroup!.fields!.edges
  const requiredColumns = docTableFields
    .filter((fieldEdge) => fieldEdge!.node!.required)
    .map((fieldEdge) => fieldEdge!.node!.key)
  const savedColumns = documentTable.documentTableColumns!.edges.map(
    (column) => column!.node!.field!.key,
  )
  const lineItemsTableColumns = savedColumns.concat(
    requiredColumns.filter((requiredColumn) => !savedColumns.includes(requiredColumn)),
  )
  if (isSoa && isDocumentTableFilePageExcel) {
    return savedColumns
  }
  if (
    (tableShowsPreset && lineItemsTableColumns.length !== docTableFields.length) ||
    (isDocumentTableFilePageExcel && lineItemsTableColumns.length === 0)
  ) {
    return docTableFields.map((fieldEdge) => fieldEdge!.node!.key)
  }
  return lineItemsTableColumns
}

/**
 * Convert field groups from a list of lists to a list of objects, with each object representing
 * a row
 */
export const getLineItems = (
  documentTable: Maybe<DocumentTableNode>,
  enableLineItemsRowOrderPriority: boolean,
): LineItem[] => {
  // for doc types with no document table
  if (!documentTable) {
    return []
  }
  return documentTable
    .documentFieldGroups!.edges.map((docFieldGroupEdge) => {
      const documentFieldGroup = docFieldGroupEdge!.node!
      const boxes = documentFieldGroup
        .documentFields!.edges.map(
          (documentFieldEdge) =>
            (hasDimension(documentFieldEdge!.node!) &&
              boxDimensionToCorners(documentFieldEdge!.node)) ||
            null,
        )
        .filter(isPresent)
      return {
        id: documentFieldGroup.id,
        rowOrderPriority: documentFieldGroup.rowOrderPriority,
        fieldMapping: Object.fromEntries(
          documentFieldGroup.documentFields!.edges.map((documentFieldEdge) => [
            documentFieldEdge!.node!.field!.key,
            documentFieldEdge!.node!,
          ]),
        ),
        box: boxes.length ? boxCornersToDimension(boxes.reduce(uniteBoxCorners)) : undefined,
        documentTableId: documentTable.id,
      }
    })
    .sort(sortComparerWrapper(enableLineItemsRowOrderPriority))
}

/**
 * get a field coordinate mapping from the given line items, for TextSelectionBox display
 * purposes
 */
export const getLineItemFieldCoordinates = (
  lineItems: (LineItem | JobTableLineItem)[],
): FieldCoordinates => {
  return Object.fromEntries(
    lineItems.flatMap(({ id, fieldMapping }) =>
      Object.entries(fieldMapping)
        .filter(([, val]) => hasDimension(val))
        .map(([key, { top, left, height, width }]) => [
          `${id}${LINE_ITEM_ID_KEY_SEPARATOR}${key}`,
          { top, left, height, width } as BoxDimension,
        ]),
    ),
  )
}

const validateFieldValue = (field: FieldNode, value: string): boolean => {
  const { required, validatorRegex, allowFreeText, values, dateFormatString } = field
  const isInvalidRequiredValue = !value && required
  const isInvalidPatternMatchedValue =
    value && validatorRegex && !RegExp(`^(${validatorRegex})$`).test(value)
  const isInvalidDropdownValue =
    value && !allowFreeText && values?.length && !values.includes(value)
  const type = field.fieldType
    ? isFallback(field.fieldType)
      ? field.fieldType.fallbackValue
      : field.fieldType.value
    : null
  const isInvalidDateFormat =
    (type === FieldType.Date || type === FieldType.DateTime) &&
    dateFormatString &&
    value &&
    !isValidRecentDate(value, dateFormatString)
  return !!(
    isInvalidRequiredValue ||
    isInvalidPatternMatchedValue ||
    isInvalidDropdownValue ||
    isInvalidDateFormat
  )
}

/**
 * Validate the full set of line items of a document table given a field key map
 */
export const validateLineItems = (
  lineItemsData: LineItem[],
  fieldGroup: FieldGroupNode,
): boolean => {
  const fields = fieldGroup.fields!.edges.map((edge) => edge!.node!)
  if (uniqBy(lineItemsData, (lineItem) => lineItem.id).length !== lineItemsData.length) {
    reportRollbarError(`Duplicate line items detected: ${JSON.stringify(lineItemsData)}`)
  }
  return !fields
    .filter((field) => field.required || field.validatorRegex || !field.allowFreeText)
    .find(
      (field) =>
        !!lineItemsData.find(({ fieldMapping }) => {
          const fieldValue = fieldMapping[field.key]?.value ?? field.defaultValue ?? ''
          const isInvalidValue = validateFieldValue(field, fieldValue)
          return isInvalidValue
        }),
    )
}

const validateNotPartialableFields = (
  documentTables: JobDocumentTable[],
  lineItemsTableMap: LineItemsTableMap,
): void => {
  const filledNotPartialFields: Record<string, FieldNode> = {}
  for (const documentTable of documentTables) {
    const repeatableFieldGroup = documentTable.fieldGroup!
    const lineItems = lineItemsAdapter
      .getSelectors()
      .selectAll(lineItemsTableMap[documentTable.id].lineItems)
    for (const fieldEdge of repeatableFieldGroup.fields!.edges) {
      const field = fieldEdge!.node!
      if (!field.partialTableAllowed) {
        for (const lineItem of lineItems) {
          if (lineItem.fieldMapping[field.key]?.value?.trim()) {
            filledNotPartialFields[field.key] = field
          }
        }
      }
    }
  }
  for (const documentTable of documentTables) {
    const lineItems = lineItemsAdapter
      .getSelectors()
      .selectAll(lineItemsTableMap[documentTable.id].lineItems)
    if (uniqBy(lineItems, (lineItem) => lineItem.id).length !== lineItems.length) {
      reportRollbarError(`Duplicate line items detected: ${JSON.stringify(lineItems)}`)
    }
    for (const field of Object.values(filledNotPartialFields)) {
      for (const lineItem of lineItems) {
        if (!lineItem.fieldMapping[field.key]) {
          throw new Error(
            `At least one line item has a non-empty "${field.name}". ` +
              `Please fill in all "${field.name}" rows, or none of them.`,
          )
        }
      }
    }
  }
}

const getDocTableFieldsWithValidations = (
  jobRepeatableFieldGroups: FieldGroupNode[],
  fieldGroupId: string,
): Record<string, FieldNode> => {
  const repeatableFieldKeyMap = {} as Record<string, FieldNode>
  const docTablefieldGroup = jobRepeatableFieldGroups.find(
    (fieldGroup) => fieldGroup.id === fieldGroupId,
  )
  if (docTablefieldGroup) {
    docTablefieldGroup.fields!.edges.forEach((fieldNodeEdge) => {
      const fieldNode = fieldNodeEdge!.node!
      const { key, required, validatorRegex, allowFreeText } = fieldNode
      if (required || validatorRegex || !allowFreeText) {
        repeatableFieldKeyMap[key] = fieldNode
      }
    })
  }
  return repeatableFieldKeyMap
}

export const validateTables = (
  docTables: JobDocumentTable[],
  jobRepeatableFieldGroups: FieldGroupNode[],
  lineItemsTableMap: LineItemsTableMap,
): Record<string, string[]> => {
  const tablesWithErrors = {} as Record<string, string[]>
  validateNotPartialableFields(docTables, lineItemsTableMap)
  docTables.forEach((docTable) => {
    const fieldKeyMap = getDocTableFieldsWithValidations(
      jobRepeatableFieldGroups,
      docTable.fieldGroupId,
    )
    const fieldKeys = Object.keys(fieldKeyMap)
    const lineItemColumnsWithErrors = new Set<string>()
    const lineItems = lineItemsAdapter
      .getSelectors()
      .selectAll(lineItemsTableMap[docTable.id].lineItems)
    for (const { fieldMapping } of lineItems) {
      fieldKeys.forEach((key) => {
        const field = fieldKeyMap[key]
        const fieldValue = fieldMapping[key]?.value ?? field.defaultValue ?? ''
        const isInvalidValue = validateFieldValue(field, fieldValue)
        if (isInvalidValue) {
          lineItemColumnsWithErrors.add(field.name)
        }
      })
      if (fieldKeys.length === lineItemColumnsWithErrors.size) {
        break
      }
    }
    if (lineItemColumnsWithErrors.size > 0) {
      tablesWithErrors[docTable.id] = Array.from(lineItemColumnsWithErrors)
    }
  })
  return tablesWithErrors
}

export const readDataFromRowFieldBoxes = (
  lineItems: LineItem[],
  googleOcrData: GoogleOCRData,
  docTableRepeatableFields: FieldNode[],
): LineItem[] => {
  const fieldInvalidCharsRegexMapping = {} as Record<string, string>
  const fieldTypeMapping = {} as Record<string, FieldType>
  docTableRepeatableFields.forEach((field) => {
    if (field.invalidCharsRegex) {
      fieldInvalidCharsRegexMapping[field.key] = field.invalidCharsRegex
    }
    if (field.fieldType && !isFallback(field.fieldType)) {
      fieldTypeMapping[field.key] = field.fieldType.value
    }
  })

  const docFieldValidValuesMapping = Object.fromEntries(
    docTableRepeatableFields.map((field) => [field.key, field.values]),
  ) as Record<string, string[] | null>

  const docFieldNodesMapping = Object.fromEntries(
    docTableRepeatableFields.map((field) => [field.key, field]),
  ) as Record<string, FieldNode | null>

  return lineItems.map((lineItem) => {
    const newFieldMappings = Object.fromEntries(
      Object.entries(lineItem.fieldMapping).map(([key, fieldMapping]) => {
        const { top, left, width, height } = fieldMapping
        const start = { x: left!, y: top! }
        const end = { x: left! + width!, y: top! + height! }
        let value = extractWordFromCoordinates(googleOcrData, start, end)
        // find the closest valid value to the extracted word and make it the new value
        if (docFieldValidValuesMapping[key]?.length) {
          value = closest(value, docFieldValidValuesMapping[key] as string[])
        }
        const cleanedValue = applyCleanForField(
          value,
          fieldInvalidCharsRegexMapping[key],
          fieldTypeMapping[key],
          docFieldNodesMapping[key],
        )
        const newFieldMapping = { ...fieldMapping, value: cleanedValue }
        return [key, newFieldMapping]
      }),
    )
    return { ...lineItem, fieldMapping: newFieldMappings }
  })
}

export const checkFieldDimensionExists = (fieldBox: FieldValue): boolean => {
  return Boolean(fieldBox?.left && fieldBox?.top && fieldBox?.width && fieldBox?.height)
}

// given a list of line items, sort and compute for the bottom coord of the last item
export const getLastRowBottomCoord = (lineItems: LineItem[]): Record<string, number | string> => {
  const sortedLineItems = lineItems.sort((a, b) => (a.box?.top ?? 0) - (b.box?.top ?? 0))
  const lastItem = sortedLineItems[sortedLineItems.length - 1]
  const lastRowBottomCoord = (lastItem?.box?.top ?? 0) + (lastItem?.box?.height ?? 0)
  return { lastRowBottomCoord, lastItemId: lastItem.id }
}

const checkIfBoxesOverlap = (box1: FieldValue, box2: FieldValue): boolean => {
  const box1Right = box1.left! + box1.width!
  const box2Right = box2.left! + box2.width!
  if (box1.left! > box2Right || box1Right < box2.left!) {
    return false
  }
  const box1Bottom = box1.top! + box1.height!
  const box2Bottom = box2.top! + box2.height!
  if (box1.top! > box2Bottom || box1Bottom < box2.top!) {
    return false
  }
  return true
}

const reduceDimensionOfLargerBox = (
  largerBox: FieldValue,
  smallerBox: FieldValue,
): { reducedLargerBox: FieldValue; smallerBox: FieldValue } => {
  const largerBoxBottomRight = {
    x: largerBox.left! + largerBox.width!,
    y: largerBox.top! + largerBox.height!,
  }
  const smallerBoxBottomRight = {
    x: smallerBox.left! + smallerBox.width!,
    y: smallerBox.top! + smallerBox.height!,
  }
  const deltaWidth =
    Math.min(largerBoxBottomRight.x, smallerBoxBottomRight.x) -
    Math.max(largerBox.left!, smallerBox.left!)
  const deltaHeight =
    Math.min(largerBoxBottomRight.y, smallerBoxBottomRight.y) -
    Math.max(largerBox.top!, smallerBox.top!)
  const intersectionArea = deltaWidth * deltaHeight

  const widthDiff = Math.abs(largerBox.width! - smallerBox.width!)
  const heightDiff = Math.abs(largerBox.height! - smallerBox.height!)
  const isHeightCloseToLargeBox = heightDiff < LINE_THRESHOLD
  const isWidthCloseToLargeBox = widthDiff < LINE_THRESHOLD
  const smallerBoxArea = smallerBox.width! * smallerBox.height!
  const isCompleteOverlap = intersectionArea >= smallerBoxArea
  // we only adjust the dimensions of the larger box
  // and preserve the dimensions of the smaller one
  const reducedLargerBox = { ...largerBox }
  if (isHeightCloseToLargeBox) {
    reducedLargerBox.left! += smallerBox.left! < largerBox.left! ? deltaWidth : 0
    reducedLargerBox.width! -= deltaWidth
  } else if (isWidthCloseToLargeBox) {
    reducedLargerBox.top! += smallerBox.top! < largerBox.top! ? deltaHeight : 0
    reducedLargerBox.height! -= deltaHeight
  } else if (isCompleteOverlap) {
    reducedLargerBox.top! += deltaHeight
    reducedLargerBox.height! -= deltaHeight
    const newHeightDiff = Math.abs(reducedLargerBox.height! - smallerBox.height!)
    const isNewHeightCloseToLargeBox = newHeightDiff < LINE_THRESHOLD
    if (isNewHeightCloseToLargeBox) {
      reducedLargerBox.left! += deltaWidth
      reducedLargerBox.width! -= deltaWidth
    }
  }
  return { reducedLargerBox, smallerBox }
}

const removeIntersectedAreaFromLargerBox = (
  box1: FieldValue,
  box2: FieldValue,
): { box1: FieldValue; box2: FieldValue } => {
  const box1Area = box1.width! * box1.height!
  const box2Area = box2.width! * box2.height!
  if (box1Area > box2Area) {
    const { reducedLargerBox, smallerBox } = reduceDimensionOfLargerBox(box1, box2)
    return { box1: reducedLargerBox, box2: smallerBox }
  }
  if (box2Area > box1Area) {
    const { reducedLargerBox, smallerBox } = reduceDimensionOfLargerBox(box2, box1)
    return { box1: smallerBox, box2: reducedLargerBox }
  }
  return { box1, box2 }
}

const removeBoxOverlaps = (
  fieldMappingEntries: [string, FieldValue][],
  currentFieldMappingValue: FieldValue,
): FieldValue => {
  let newFieldMappingValue = currentFieldMappingValue
  fieldMappingEntries.forEach(([key, fieldMappingValue], idx) => {
    if (checkIfBoxesOverlap(newFieldMappingValue, fieldMappingValue)) {
      const { box1, box2 } = removeIntersectedAreaFromLargerBox(
        newFieldMappingValue,
        fieldMappingValue,
      )
      fieldMappingEntries[idx] = [key, box2]
      newFieldMappingValue = box1
    }
  })
  return newFieldMappingValue
}

const checkFieldDimensionValid = (fieldBox: FieldValue): boolean => {
  if (
    checkFieldDimensionExists(fieldBox) &&
    fieldBox.width! > MIDPOINT_THRESHOLD &&
    fieldBox.height! > MIDPOINT_THRESHOLD
  ) {
    return true
  }
  return false
}

// given a sorted list of line items, clone them but with adjusted top coordinates and empty field values
export const cloneLineItems = (
  lineItems: LineItem[],
  lastRowBottomCoord: number,
  googleOcrData: GoogleOCRData,
  fromAddTableRow?: boolean,
): (LineItem | JobTableLineItem)[] => {
  const ocrWordBoxes = getGoogleOcrWordBoundingBoxes(googleOcrData)
  let topCoordOffset = lastRowBottomCoord - (lineItems[0]?.box?.top ?? 0)
  const clonedLineItems = lineItems.map((lineItem, index) => {
    return produce(lineItem, (lineItemDraft) => {
      lineItemDraft.id = uuidv4()
      // this could be undefined if the line item's outer box has no inner boxes (after refresh)
      // e.g. create a box -> don't create field boxes (inner boxes) -> (optional) manually edit the table line items -> save -> refresh
      if (!lineItemDraft.box) {
        lineItemDraft.box = {
          top: 0,
          left: 0,
          width: 0,
          height: 0,
        }
      }
      const oldBox = { ...lineItemDraft.box! }
      lineItemDraft.box!.top += topCoordOffset
      const snappedBox = snapRowBox(lineItemDraft.box!, ocrWordBoxes)
      lineItemDraft.box = snappedBox
      // manually update the lastRowBottomCoord and topCoordOffset for the next lineItem
      // if we are directly adding multiple rows (using paste to table)
      // because we'll be cloning the same line item
      if (fromAddTableRow) {
        const newLastRowBottomCoord =
          (lineItemDraft.box?.top ?? 0) + (lineItemDraft.box?.height ?? 0)
        topCoordOffset = newLastRowBottomCoord - (lineItems[index]?.box?.top ?? 0)
      }
      const fieldMappingEntries = [] as [string, FieldValue][]
      Object.entries(lineItemDraft.fieldMapping).forEach(([key, fieldMapping]) => {
        let fieldMappingCopy = {
          ...fieldMapping,
          id: uuidv4(),
          value: '',
        }
        if (checkFieldDimensionExists(fieldMappingCopy)) {
          // TODO: scale height to snapped box height
          // scale top to within snapped box height
          const boxHeightScale = snappedBox.height / oldBox.height
          const scaledTopOffset = (fieldMapping.top! - oldBox.top) * boxHeightScale
          const scaledTop = snappedBox.top + scaledTopOffset
          const fieldHeight = Math.max(
            (fieldMapping?.height ?? 0) * boxHeightScale,
            LINE_THRESHOLD * 1.25,
          )
          fieldMappingCopy.top = scaledTop
          fieldMappingCopy.height = Math.min(fieldHeight, snappedBox.height - scaledTopOffset)
          fieldMappingCopy = snapFieldBox(
            fieldMappingCopy as FieldMappingValue,
            ocrWordBoxes,
            snappedBox,
          )
          fieldMappingCopy = constrainFieldMappingWithinRowBox(
            snappedBox,
            fieldMappingCopy as FieldMappingValue,
          )
          fieldMappingCopy = removeBoxOverlaps(
            fieldMappingEntries,
            fieldMappingCopy,
          ) as FieldMappingValue
        }
        if (checkFieldDimensionValid(fieldMappingCopy)) {
          fieldMappingEntries.push([key, fieldMappingCopy])
        }
      })
      lineItemDraft.fieldMapping = Object.fromEntries(fieldMappingEntries)
    })
  })

  // this is so it'll try to add as much rows as it can until it reaches the end of the image
  const lineItemsInsideImage = [] as (LineItem | JobTableLineItem)[]
  let clonedItemsTotalHeight = lastRowBottomCoord
  clonedLineItems.forEach((currentLineItem) => {
    clonedItemsTotalHeight += currentLineItem?.box?.height ?? 0
    if (clonedItemsTotalHeight < 1) {
      lineItemsInsideImage.push(currentLineItem)
    }
  })

  return lineItemsInsideImage
}

export const cloneLineItemsNoSnap = (
  lineItems: LineItem[],
  lastRowBottomCoord: number,
): (LineItem | JobTableLineItem)[] => {
  const clonedLineItems = lineItems.map((lineItem) => {
    return produce(lineItem, (lineItemDraft) => {
      lineItemDraft.id = uuidv4()
      const fieldMappingEntries = [] as [string, FieldValue][]
      Object.entries(lineItemDraft.fieldMapping).forEach(([key, fieldMapping]) => {
        const fieldMappingCopy = {
          ...fieldMapping,
          id: uuidv4(),
          value: '',
        }
        if (checkFieldDimensionValid(fieldMappingCopy)) {
          fieldMappingEntries.push([key, fieldMappingCopy])
        }
      })
      lineItemDraft.fieldMapping = Object.fromEntries(fieldMappingEntries)
    })
  })
  const clonedItemsTotalHeight = clonedLineItems.reduce((acc, currentLineItem) => {
    return acc + (currentLineItem?.box?.height ?? 0)
  }, 0)
  const lineItemsInsideImage = lastRowBottomCoord + clonedItemsTotalHeight < 1
  return lineItemsInsideImage ? clonedLineItems : []
}

export const isJobTableLineItem = (
  lineItem: JobTableLineItem | LineItem,
): lineItem is JobTableLineItem => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  return (lineItem as any).boxMapping !== undefined
}

export const SENDING_SOA_LINE_COLUMN_KEYS = {
  INVOICE_NO: 'Invoice Number',
  SHIPMENT_NO: 'Shipment Number',
  SECOND_KEYS: 'Secondary Keys',
  STATUS: 'Status',
  NOTES: 'Notes',
  SELECTED: 'Selected',
  CHARGE_CODE: 'Charge Code',
  COST: 'Cost',
  TAX_CODE: 'Tax Code',
  TAX_AMOUNT: 'Tax Amount',
  TOTAL: 'Total Amount',
  CARGOWISE_MODULE: 'Cargowise Module',
}

const TRANSFORM_SOA_INPUT_LINE_ITEM_KEYS: Record<string, string> = {
  'Invoice Number': 'invoiceNumber',
  'Shipment Number': 'referenceNumber',
  'Carrier Booking No.': 'carrierBookingNumber',
  'MBL No.': 'mblNumber',
  'HBL No.': 'hblNumber',
  'Container No.': 'containerNumber',
  'Consol No.': 'consolNumber',
  'Order No.': 'orderNumber',
  'Charge Code': 'chargeCode',
  Cost: 'chargeCost',
  Currency: 'currency',
  'Due Date': 'dueDate',
  'Invoice Date': 'invoiceDate',
  'Charge Qty': 'quantity',
  'Cargowise Module': 'cargowiseModule',
  'Document Received Date': 'documentReceivedDate',
}

const MATCH_SHIPMENT_KEYS = [
  'referenceNo',
  'hblNo',
  'mblNo',
  'carrierBookingNo',
  'containerNo',
  'consolNo',
  'orderNo',
]

export const BATCH_CHECK_SHIPMENT_INFO_LINE_COLUMN_KEYS = {
  INVOICE_NUMBER: 'Invoice Number',
  REFERENCE_NUMBER: 'Reference Number',
  SECOND_KEYS: 'Secondary Keys',
  CARGOWISE_MODULE: 'Cargowise Module',
}

// get autofill keys from document table field groups to get correct key names
const getDocumentTableAutofillKeys = (document: Maybe<DocumentNode>): Record<string, string> => {
  const autofillKeys = {} as Record<string, string>
  document?.documentTables?.edges[0]?.node?.fieldGroup?.fields?.edges.forEach((edge) => {
    const fieldKey = edge!.node!.key!
    const fieldAutofillKey = edge!.node!.autofillKey!
    autofillKeys[fieldKey] = fieldAutofillKey
  })
  return autofillKeys
}

export type SendSoaTableLineItem = Record<
  string,
  string | boolean | string[] | string[][] | undefined
>

// use correct key names to create line items
const getLineItemsUsingAutofillKeys = (
  jobTableLineItems: JobTableLineItem[],
  document: Maybe<DocumentNode>,
): SendSoaTableLineItem[] => {
  const autofillKeys = getDocumentTableAutofillKeys(document)
  const lineItems = jobTableLineItems.map((jobTableLine) => {
    const line = {} as SoaLineItem
    Object.entries(jobTableLine.fieldMapping).forEach(([key, value]) => {
      const columnKey = SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING[autofillKeys[key]]
      line[columnKey] = value.value
    })
    return line
  })
  return lineItems
}

// from line items for soa send, construct one fit for querying shipment matches
export const transformLineItems = (
  jobTableLineItems: JobTableLineItem[],
  document: Maybe<DocumentNode>,
): InputSoaLineItem[] => {
  const inputSoaLineItems = [] as InputSoaLineItem[]
  const lineItems = getLineItemsUsingAutofillKeys(jobTableLineItems, document)
  lineItems.forEach((line) => {
    const inputLine = {} as SoaLineItem
    Object.entries(line).forEach(([k, v]) => {
      if (TRANSFORM_SOA_INPUT_LINE_ITEM_KEYS[k]) {
        inputLine[TRANSFORM_SOA_INPUT_LINE_ITEM_KEYS[k]] = v
      }
    })
    inputSoaLineItems.push(inputLine)
  })
  return inputSoaLineItems
}

export const getUniqueChargeCodesFromSoaLineItems = (
  soaLineItems: Record<string, SendSoaTableLineItem[]>[],
): string[] => {
  const uniqueChargeCodes = new Set<string>()
  soaLineItems
    .flatMap((invoice) => Object.values(invoice))
    .flat()
    .forEach((line) =>
      uniqueChargeCodes.add(line[SENDING_SOA_LINE_COLUMN_KEYS.CHARGE_CODE] as string),
    )
  return [...uniqueChargeCodes]
}

// filter shipments for successful matches
const filterMatchedShipments = (
  shipmentMatches: Maybe<Maybe<Maybe<FindShipmentReconResultNode>[]>[]>,
): Record<string, string>[] => {
  return shipmentMatches!
    .map((shipment) => {
      // array but typescript interprets as FindShipmentReconResultNode, so recast
      const foundShipment = shipment! as unknown as FindShipmentReconResultNode[]
      const cargowiseModule = maybeFindReconModuleFromReconResults(foundShipment)
      return foundShipment
        .filter((match) => match.success)
        .map((foundMatch) => {
          const matchedShipment = {} as Record<string, string>
          if (cargowiseModule === CwTargetModule.ForwardingShipment) {
            matchedShipment['foundShipment'] = foundMatch!.chainIoShipment!.forwarderReference!
          } else if (cargowiseModule === CwTargetModule.ForwardingConsol) {
            matchedShipment['foundShipment'] = foundMatch!.chainIoConsolidation!.forwarderReference!
          } else if (cargowiseModule === CwTargetModule.CustomsDeclaration) {
            matchedShipment['foundShipment'] =
              foundMatch!.chainIoCustomsDeclaration!.forwarderReference!
          }
          matchedShipment['cargowiseModule'] =
            convertCwModuleEnumToCwModuleDisplayText(cargowiseModule)
          Object.entries(foundMatch).forEach(([key, val]) => {
            if (MATCH_SHIPMENT_KEYS.includes(key) && val) {
              matchedShipment[key] = val as string
            }
          })
          return matchedShipment
        })
    })
    .flat()
    .filter((matched) => matched)
}

// filter successful shipment matches and add to correct line item
export const addMatchedShipments = (
  lineItems: Record<string, SoaLineItem[]>[],
  shipmentMatches: Maybe<Maybe<Maybe<FindShipmentReconResultNode>[]>[]>,
): Record<string, SoaLineItem[]>[] => {
  const foundShipmentMatches = filterMatchedShipments(shipmentMatches)
  // add matched shipments to line items
  lineItems.forEach((invoice) => {
    Object.values(invoice).forEach((invoiceLines) => {
      invoiceLines.forEach((line) => {
        const lineValues = Object.values(line)
        // look through found matches, if secondary key in line item, add missing shipment number
        foundShipmentMatches.forEach((foundMatch) => {
          Object.entries(foundMatch).forEach(([matchKey, matchValue]) => {
            if (
              matchKey !== 'foundShipment' &&
              matchKey !== 'cargowiseModule' &&
              line[SENDING_SOA_LINE_COLUMN_KEYS.CARGOWISE_MODULE] === foundMatch.cargowiseModule
            ) {
              if (lineValues.includes(matchValue)) {
                line[SENDING_SOA_LINE_COLUMN_KEYS.SHIPMENT_NO] = foundMatch.foundShipment
              }
            }
          })
        })
      })
    })
  })
  return lineItems
}

// given an active Soa document, get the line items for sending
export const getLinesForSoaSend = (
  jobTableLineItems: JobTableLineItem[],
  document: Maybe<DocumentNode>,
): Record<string, SoaLineItem[]>[] => {
  const lineItems = getLineItemsUsingAutofillKeys(jobTableLineItems, document)
  return processLinesForSoaSend(lineItems || [])
}

// prep soa lines with necessary attributes for send and display
const processLinesForSoaSend = (lineItems: SoaLineItem[]): Record<string, SoaLineItem[]>[] => {
  lineItems.map((lineItem) => {
    const cwModule = lineItem[SENDING_SOA_LINE_COLUMN_KEYS.CARGOWISE_MODULE] as string
    const secondaryKeys = [] as string[]
    Object.entries(lineItem).map(([k, v]) => {
      if (SOA_LINE_ITEM_SENDING_SECONDARY_KEY_COLUMNS.includes(k) && v) {
        const value = v as string
        secondaryKeys.push(k + value + ' ')
      } else if (k === SENDING_SOA_LINE_COLUMN_KEYS.INVOICE_NO) {
        // don't clean invoice num for now, causes errors on CW end (ex. list index out of bounds)
        const cleanInvoiceNo = v //v?.toString().trim().replace('# ', '')
        lineItem[k] = cleanInvoiceNo
      }
      return 0
    })
    lineItem[SENDING_SOA_LINE_COLUMN_KEYS.SECOND_KEYS] = secondaryKeys
    lineItem[SENDING_SOA_LINE_COLUMN_KEYS.STATUS] = 'Pending'
    lineItem[SENDING_SOA_LINE_COLUMN_KEYS.NOTES] = [] as [string, string][]
    lineItem[SENDING_SOA_LINE_COLUMN_KEYS.SELECTED] = cwModule !== CARGOWISE_MODULE.ForwardingConsol
    lineItem[SENDING_SOA_LINE_COLUMN_KEYS.TAX_AMOUNT] = ''
    lineItem[SENDING_SOA_LINE_COLUMN_KEYS.TOTAL] = ''
    lineItem[SENDING_SOA_LINE_COLUMN_KEYS.CARGOWISE_MODULE] =
      cwModule || CARGOWISE_MODULE.ForwardingShipment
    return lineItem
  })
  return groupLinesByInvoiceForSoaSend(lineItems)
}

// group soa line items by invoice number <> cargowise module pair
// https://expedock.atlassian.net/browse/PD-723
const groupLinesByInvoiceForSoaSend = (
  lineItems: SoaLineItem[],
): Record<string, SoaLineItem[]>[] => {
  const groupedSoaLineItemsMap = groupBy(lineItems, (lineItem) => {
    const invoiceNumber = lineItem[SENDING_SOA_LINE_COLUMN_KEYS.INVOICE_NO] as string
    const cwModule = lineItem[SENDING_SOA_LINE_COLUMN_KEYS.CARGOWISE_MODULE] as string
    const groupedLineItemsKey = `${invoiceNumber}-${cwModule}`
    return groupedLineItemsKey
  }) as Record<string, SoaLineItem[]>
  const groupedLineItems = Object.entries(groupedSoaLineItemsMap).map(([key, value]) => ({
    [key]: value,
  }))
  return groupedLineItems
}

export const addTaxToLineItems = (
  taxes: TaxNode[],
  lineItems: Record<string, SoaLineItem[]>[],
): Record<string, SoaLineItem[]>[] => {
  const taxCodeTaxRateMap = taxes.reduce(
    (taxCodeTaxRateMap, tax) => {
      taxCodeTaxRateMap[tax.taxCode] = +tax.taxRate
      return taxCodeTaxRateMap
    },
    {} as Record<string, number>,
  )
  lineItems.map((invoice) => {
    return Object.values(invoice)
      .flat()
      .forEach((line) => {
        const taxCode = line[SENDING_SOA_LINE_COLUMN_KEYS.TAX_CODE] as string
        if (taxCode in taxCodeTaxRateMap) {
          const chargeAmount = line[SENDING_SOA_LINE_COLUMN_KEYS.COST] as string
          const taxAmount = (taxCodeTaxRateMap[taxCode] / 100) * parseInt(chargeAmount)
          line[SENDING_SOA_LINE_COLUMN_KEYS.TAX_AMOUNT] = taxAmount.toFixed(2)
          const totalAmount = taxAmount + parseInt(chargeAmount)
          line[SENDING_SOA_LINE_COLUMN_KEYS.TOTAL] = totalAmount.toFixed(2)
        }
      })
  })
  return lineItems
}

export type SoaLineItemExpectedChargesWithSecondaryKeysAndCargowiseModules =
  SoaLineItemExpectedCharges & {
    secondaryKeys: string[]
    cargowiseModules: string[]
  }
export const fillExpectedChargesWithSecondaryKeys = (
  expectedCharges: Maybe<SoaLineItemExpectedCharges[]> | undefined,
): SoaLineItemExpectedChargesWithSecondaryKeysAndCargowiseModules[] | undefined => {
  if (!expectedCharges) {
    return undefined
  }
  return expectedCharges.map((expectedCharge) => {
    return {
      ...expectedCharge,
      secondaryKeys: expectedCharge.matchCriteriaFindShipmentReconResultPairList.map(
        ({ checkShipmentInfoMatchCriteria }): string =>
          `${
            checkShipmentInfoMatchCriteria.hblNumber
              ? `HBL No. ${checkShipmentInfoMatchCriteria.hblNumber} `
              : ''
          }${
            checkShipmentInfoMatchCriteria.mblNumber
              ? `MBL No. ${checkShipmentInfoMatchCriteria.mblNumber} `
              : ''
          }${
            checkShipmentInfoMatchCriteria.carrierBookingNumber
              ? `Carrier Booking No. ${checkShipmentInfoMatchCriteria.carrierBookingNumber} `
              : ''
          }${
            checkShipmentInfoMatchCriteria.containerNumber
              ? `Container No. ${checkShipmentInfoMatchCriteria.containerNumber} `
              : ''
          }${
            checkShipmentInfoMatchCriteria.consolNumber
              ? `Consol No. ${checkShipmentInfoMatchCriteria.consolNumber} `
              : ''
          }${
            checkShipmentInfoMatchCriteria.orderNumber
              ? `Order No. ${checkShipmentInfoMatchCriteria.orderNumber} `
              : ''
          }`,
      ),
      cargowiseModules: expectedCharge.matchCriteriaFindShipmentReconResultPairList.map(
        ({ checkShipmentInfoMatchCriteria }): string =>
          `${checkShipmentInfoMatchCriteria.cargowiseModule || 'FowardingShipment'}`,
      ),
    } as SoaLineItemExpectedChargesWithSecondaryKeysAndCargowiseModules
  })
}

export const getChainIoModelName = (
  expectedCharge: SoaLineItemExpectedChargesWithSecondaryKeysAndCargowiseModules,
): string => {
  const cargowiseModule = expectedCharge.cargowiseModules[0]
  if (cargowiseModule === CwTargetModuleDisplayText.CUSTOMS_DECLARATION) {
    return ChainIoModelName.CHAIN_IO_CUSTOMS_DECLARATION
  } else if (cargowiseModule === CwTargetModuleDisplayText.FORWARDING_CONSOL) {
    return ChainIoModelName.CHAIN_IO_CONSOLIDATION
  } else {
    return ChainIoModelName.CHAIN_IO_SHIPMENT
  }
}
