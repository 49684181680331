import { produce } from 'immer'
import { JobNode, JobTemplateExportType } from '@src/graphql/types'
import {
  EXCEL_EXPORT_JOB_TYPES,
  EXPORT_FILE_NAME_JOB_TYPES,
  JSON_EXPORT_JOB_TYPES,
} from '@src/utils/app_constants'
import XLSX, { WorkBook } from 'xlsx'
import Papa from 'papaparse'
import { create } from 'xmlbuilder2'
import {
  getGroupedJobDocumentFieldGroups,
  getJobDocumentTypeFieldGroups,
  getJobFieldMappingRows,
  getJobLineItemRows,
  makeFieldMapping,
  makeLineItemsMapping,
} from '@src/utils/export/row_conversion'
import { apllFormatExcel } from '@src/utils/export/apll'
import {
  formatAmsJob,
  formatFremuraIsfFilingFilePages,
  getAddtlFremuraIsfDocTypeFieldGroups,
} from '@src/utils/export/fremura'
import { CSV_BULK_EXPORT_MAX_FILENAME_LENGTH, validateForBulkExport } from '@src/utils/export/bulk'
import {
  CEVA_COLUMN_NAME_MAPPING,
  formatCevaJob,
  formatCevaJobToRows,
  sortJobsByDateCreatedAndField,
} from '@src/utils/export/ceva'
import { formatStandardJobJSON } from '@src/utils/export/standard'
import { getFieldValueMap } from '@src/utils/job'
import { underscoreCapitalCaseText } from '@src/utils/text'
import { formatJoinMetadataLineItems, formatSplitMetadataLineItems } from './admin_export'
import { formatFieldGroups } from './job_format'
import { parseDateString } from '@src/utils/date'

export const exportToFile = (contents: string, filename: string, mimetype: string): void => {
  const dataStr = `data:${mimetype};charset=utf-8,${encodeURIComponent(contents)}`
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', `${filename}`)
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

const formatJobForJsonExport = (job: JobNode): JobNode => {
  switch (job?.jobTemplate?.name) {
    case JSON_EXPORT_JOB_TYPES.AMS_ISF_FILINGS:
    case JSON_EXPORT_JOB_TYPES.FREMURA_AMS_FILING: {
      return formatAmsJob(job)
    }
    default:
      return job
  }
}

export const exportJobToJson = (job: JobNode): void => {
  let jsonStr = ''
  let filenameWithoutExtension = ''
  // vanilla key-value mapping for standard schemas
  const mapping = formatStandardJobJSON(job)
  jsonStr = JSON.stringify(mapping, undefined, 4)
  filenameWithoutExtension = job.name
  exportToFile(jsonStr, `${filenameWithoutExtension}.json`, 'text/json')
}

export const exportJobToRPAJson = (job: JobNode): void => {
  let jsonStr = ''
  let filenameWithoutExtension = ''
  const formattedJob = formatJobForJsonExport(job)
  jsonStr = JSON.stringify(formattedJob, undefined, 4)
  filenameWithoutExtension = formattedJob.name
  exportToFile(jsonStr, `${filenameWithoutExtension}.json`, 'text/json')
}

const cleanSheetName = (sheetName: string): string => {
  let cleanedSheetName = sheetName
  // sheet name cannot contain chars \/?*[] (regex?)
  const forbiddenCharRegex = /[?*\\/[\]]/g
  cleanedSheetName = cleanedSheetName.replace(forbiddenCharRegex, '')
  // sheet name cannot exceed 31 chars
  const maxSheetLen = 31
  return cleanedSheetName.slice(0, maxSheetLen)
}

export const formatDefaultExcel = (job: JobNode, workBook: WorkBook): void => {
  const groupedDocumentFieldGroups = getGroupedJobDocumentFieldGroups(job)
  const documentTypeFieldGroups = getJobDocumentTypeFieldGroups(job)
  const fieldMapping = makeFieldMapping(groupedDocumentFieldGroups.flat())

  const fieldMappingRows = getJobFieldMappingRows(job, fieldMapping, documentTypeFieldGroups)
  XLSX.utils.book_append_sheet(workBook, XLSX.utils.aoa_to_sheet(fieldMappingRows), 'Fields')

  const repeatableFieldGroups = documentTypeFieldGroups
    .filter((documentTypeFieldGroup) => documentTypeFieldGroup.fieldGroup!.repeatable)
    .map((documentTypeFieldGroup) => documentTypeFieldGroup.fieldGroup!)
  for (const repeatableFieldGroup of repeatableFieldGroups) {
    const lineItems = groupedDocumentFieldGroups.map((documentFieldGroups) =>
      documentFieldGroups
        .filter(
          (documentFieldGroup) =>
            documentFieldGroup.fieldGroup!.repeatable &&
            documentFieldGroup.fieldGroup!.key === repeatableFieldGroup.key,
        )
        .map((documentFieldGroup) =>
          Object.fromEntries(
            documentFieldGroup.documentFields!.edges.map((documentFieldEdge) => [
              documentFieldEdge!.node!.field!.key,
              documentFieldEdge!.node!.value,
            ]),
          ),
        ),
    )
    const repeatableRows = getJobLineItemRows(
      job,
      lineItems,
      documentTypeFieldGroups,
      fieldMapping,
      repeatableFieldGroup.key,
    )
    XLSX.utils.book_append_sheet(
      workBook,
      XLSX.utils.aoa_to_sheet(repeatableRows),
      cleanSheetName(repeatableFieldGroup.name),
    )
  }
}

const formatCevaExcel = (
  job: JobNode,
  workBook: WorkBook,
  enableLineItemsRowOrderPriority = false,
): void => {
  const sheetName = 'Sheet 1'
  const formattedJob = formatCevaJob(job)
  workBook.SheetNames.push(sheetName)
  const headers = Object.values(CEVA_COLUMN_NAME_MAPPING)
  workBook.Sheets[sheetName] = XLSX.utils.aoa_to_sheet([headers])
  const rows = formatCevaJobToRows(
    formattedJob,
    CEVA_COLUMN_NAME_MAPPING,
    enableLineItemsRowOrderPriority,
  )

  XLSX.utils.sheet_add_json(workBook.Sheets[sheetName], rows, {
    header: headers,
    skipHeader: true,
    origin: 'A2',
  })
}

const formatCargowiseCiExtractionExcel = (
  job: JobNode,
  workBook: WorkBook,
  enableLineItemsRowOrderPriority = false,
): void => {
  const groupedDocumentFieldGroups = getGroupedJobDocumentFieldGroups(job)
  const metaData = makeFieldMapping(groupedDocumentFieldGroups.flat(), true, false)
  const metaDataHeaders = Object.keys(metaData)
  const metaDataWorkSheet = XLSX.utils.json_to_sheet([metaData], {
    header: metaDataHeaders,
    skipHeader: false,
  })
  XLSX.utils.book_append_sheet(workBook, metaDataWorkSheet, 'Fields')

  const lineItems = makeLineItemsMapping(
    groupedDocumentFieldGroups,
    enableLineItemsRowOrderPriority,
  )
  const lineItemsWithPoNumber = lineItems.map((lineItem) => ({
    ...lineItem,
    'PO Number': metaData['PO Number'],
  }))
  const lineItemHeaders = [
    'Product Code',
    'Description',
    'HS Code',
    'Price/Unit',
    'Export Price',
    'Weight',
    'Volume',
    'PO Number',
  ]
  const lineItemWorksheet = XLSX.utils.json_to_sheet(lineItemsWithPoNumber, {
    header: lineItemHeaders,
    skipHeader: false,
  })
  XLSX.utils.book_append_sheet(workBook, lineItemWorksheet, 'Line Items')
}

export const convertJobToExcelFormat = (
  job: JobNode,
  workBook: WorkBook,
  enableLineItemsRowOrderPriority = false,
): void => {
  switch (job?.jobTemplate?.name) {
    case EXCEL_EXPORT_JOB_TYPES.EHBL: {
      const fieldGroupsToFormat = [
        'consignee_country_ehbl',
        'consignee_state_ehbl',
        'shipper_country_ehbl',
        'shipper_state_ehbl',
      ]
      const formatter = (value: string): string => {
        return value.split(';')[0]
      }
      const formattedJob = formatFieldGroups(job, fieldGroupsToFormat, formatter)
      formatDefaultExcel(formattedJob, workBook)
      break
    }
    case EXCEL_EXPORT_JOB_TYPES.APLL_DATA_ENTRY:
    case EXCEL_EXPORT_JOB_TYPES.APLL_COMMERCIAL_INVOICE_ENTRY:
    case EXCEL_EXPORT_JOB_TYPES.APLL_PACKING_LIST_ENTRY:
    case EXCEL_EXPORT_JOB_TYPES.APLL_ORIGIN_TEXTILE_DECLARATION_ENTRY:
    case EXCEL_EXPORT_JOB_TYPES.APLL_FOOD_DETAILED_CHECKLIST_ENTRY:
    case EXCEL_EXPORT_JOB_TYPES.APLL_FOOTWEAR_CLASSIFICATION_SHEET_ENTRY:
    case EXCEL_EXPORT_JOB_TYPES.APLL_DECLARATION_FORM_ENTRY:
      apllFormatExcel(job, workBook)
      break
    case EXCEL_EXPORT_JOB_TYPES.FREMURA_ISF_FILING: {
      const addtlDocTypeFieldGroups = getAddtlFremuraIsfDocTypeFieldGroups(job)
      const formattedFilePageEdges = addtlDocTypeFieldGroups
        ? formatFremuraIsfFilingFilePages(job, addtlDocTypeFieldGroups)
        : null
      const formattedJob = produce(job, (jobDraft) => {
        const jobTemplateDocType = jobDraft?.jobTemplate?.documentTypes?.edges[0]
        const updateFilePagesAndFieldGroups =
          formattedFilePageEdges && addtlDocTypeFieldGroups && jobTemplateDocType
        if (updateFilePagesAndFieldGroups) {
          jobTemplateDocType!.node!.documentTypeFieldGroups!.edges = [
            ...jobDraft.jobTemplate!.documentTypes!.edges[0]!.node!.documentTypeFieldGroups!.edges,
            ...addtlDocTypeFieldGroups!,
          ]
          jobDraft.filePages!.edges = formattedFilePageEdges!
        }
      })
      formatDefaultExcel(formattedJob, workBook)
      break
    }
    case EXCEL_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_2HR:
    case EXCEL_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_4HR:
    case EXCEL_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_12HR:
    case EXCEL_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_24HR:
      formatCevaExcel(job, workBook, enableLineItemsRowOrderPriority)
      break
    case EXCEL_EXPORT_JOB_TYPES.CARGOWISE_CI_EXTRACTION:
      formatCargowiseCiExtractionExcel(job, workBook, enableLineItemsRowOrderPriority)
      break
    default: {
      const jobTemplateExportType = job.jobTemplate!.jobTemplateExport?.jobTemplateExportType
      switch (jobTemplateExportType) {
        case JobTemplateExportType.SplitMetadataLineItem:
        case JobTemplateExportType.Standard:
          formatSplitMetadataLineItems([job], workBook, enableLineItemsRowOrderPriority)
          break
        case JobTemplateExportType.JoinMetadataLineItem:
          formatJoinMetadataLineItems([job], workBook, enableLineItemsRowOrderPriority)
          break
        default:
          formatDefaultExcel(job, workBook)
      }
    }
  }
}

const getFilenameWithoutExtension = (job: JobNode): string => {
  switch (job?.jobTemplate?.name) {
    case EXPORT_FILE_NAME_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_2HR:
    case EXPORT_FILE_NAME_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_4HR:
    case EXPORT_FILE_NAME_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_12HR:
    case EXPORT_FILE_NAME_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_24HR: {
      const documentTypeFieldGroups = getGroupedJobDocumentFieldGroups(job).flat()
      const shipmentNumFieldGroup = documentTypeFieldGroups.find((documentFieldGroup) =>
        documentFieldGroup.fieldGroup!.key.includes('shipment_number'),
      )
      return shipmentNumFieldGroup?.documentFields?.edges[0]?.node?.value || job.name
    }
    case EXPORT_FILE_NAME_JOB_TYPES.CARGOWISE_CI_EXTRACTION: {
      const documentTypeFieldGroups = getGroupedJobDocumentFieldGroups(job).flat()
      const shipmentNumFieldGroup = documentTypeFieldGroups.find((documentFieldGroup) =>
        documentFieldGroup.fieldGroup!.key.includes('cargowise_ci_invoice_number'),
      )
      return shipmentNumFieldGroup?.documentFields?.edges[0]?.node?.value || job.name
    }
    default:
      return job.name
  }
}

export const exportJobToExcel = (job: JobNode, enableLineItemsRowOrderPriority = false): void => {
  const fileType = 'xlsx'
  const filenameWithoutExtension = getFilenameWithoutExtension(job)
  const workBook = XLSX.utils.book_new()
  workBook.Props = {
    Title: filenameWithoutExtension,
    Subject: filenameWithoutExtension,
    Author: 'Expedock',
    CreatedDate: new Date(),
  }

  convertJobToExcelFormat(job, workBook, enableLineItemsRowOrderPriority)
  XLSX.writeFile(workBook, `${filenameWithoutExtension}.${fileType}`)
}

export const buildJobXMLString = (job: JobNode): string => {
  const fieldValueMap = getFieldValueMap(job, true)
  const metaFields = [] as [string, string | Record<string, string>[]][]
  const lineItemTables = [] as [string, string | Record<string, string>[]][]
  Object.entries(fieldValueMap).forEach(([key, value]) =>
    typeof value === 'string' ? metaFields.push([key, value]) : lineItemTables.push([key, value]),
  )

  const xmlDataObj = {} as Record<
    string,
    string | Record<string, Record<string, Record<string, string>[]>[]>[]
  >

  for (const [fieldGroupKey, value] of metaFields) {
    xmlDataObj[underscoreCapitalCaseText(fieldGroupKey)] = value as string
  }

  if (lineItemTables.length > 0) {
    const xmlTableList = [] as Record<string, Record<string, string>[]>[]
    for (const [, lineItems] of lineItemTables) {
      const xmlLineItemList = [] as Record<string, string>[]
      for (const lineItem of lineItems as Record<string, string>[]) {
        const xmlLineItemObj = {} as Record<string, string>
        for (const [fieldKey, fieldValue] of Object.entries(lineItem)) {
          xmlLineItemObj[underscoreCapitalCaseText(fieldKey)] = fieldValue
        }
        xmlLineItemList.push(xmlLineItemObj)
      }
      xmlTableList.push({ Line_Item: xmlLineItemList })
    }
    xmlDataObj.Tables = [{ Table: xmlTableList }]
  }

  const xmlObj = { data: xmlDataObj }
  return create({ version: '1.0' }, xmlObj).end({ prettyPrint: true })
}

export const exportJobToXML = (job: JobNode): void => {
  const xmlStr = buildJobXMLString(job)
  const filenameWithoutExtension = getFilenameWithoutExtension(job)
  exportToFile(xmlStr, `${filenameWithoutExtension}.xml`, 'text/xml')
}

export const bulkExportJobsToExcel = (
  jobs: JobNode[],
  enableLineItemsRowOrderPriority = false,
): XLSX.WorkBook => {
  validateForBulkExport(jobs)
  const jobTemplate = jobs[0].jobTemplate!
  const sortedJobs = [...jobs].sort((jobA, jobB) => {
    return +parseDateString(jobA.dateCreated) - +parseDateString(jobB.dateCreated)
  })
  const filename = sortedJobs.map((job) => job.name).join('_')
  const workBook = XLSX.utils.book_new()
  workBook.Props = {
    Title: filename,
    Subject: filename,
    Author: 'Expedock',
    CreatedDate: new Date(),
  }

  switch (jobTemplate.jobTemplateExport?.jobTemplateExportType) {
    case JobTemplateExportType.JoinMetadataLineItem:
      formatJoinMetadataLineItems(sortedJobs, workBook, enableLineItemsRowOrderPriority)
      break
    case JobTemplateExportType.SplitMetadataLineItem:
    case JobTemplateExportType.Standard:
      formatSplitMetadataLineItems(sortedJobs, workBook, enableLineItemsRowOrderPriority)
      break
    default:
      throw new Error('There is no support for bulk exporting the given job type')
  }
  XLSX.writeFile(workBook, `${filename}.xlsx`)
  return workBook
}

export const exportJobToNetChbXML = (xmlStr: string, filename: string): void => {
  const dataStr = `data:text/xml;charset=utf-8,${encodeURIComponent(xmlStr)}`
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', `${filename}.xml`)
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

export const exportCevaJobToCsv = async (job: JobNode): Promise<void> => {
  const filenameWithoutExtension = getFilenameWithoutExtension(job)
  const headers = Object.values(CEVA_COLUMN_NAME_MAPPING)
  const formattedJob = formatCevaJob(job)
  const rows = formatCevaJobToRows(formattedJob, CEVA_COLUMN_NAME_MAPPING)
  exportToFile(
    Papa.unparse(
      {
        fields: headers,
        data: rows,
      },
      { header: true },
    ),
    `${filenameWithoutExtension}.csv`,
    'text/csv',
  )
}

export const exportJobToFieldMappingCsv = async (job: JobNode): Promise<void> => {
  const documentFieldGroups = getGroupedJobDocumentFieldGroups(job).flat()
  const documentTypeFieldGroups = getJobDocumentTypeFieldGroups(job)
  const fieldMapping = makeFieldMapping(documentFieldGroups)
  const rows = getJobFieldMappingRows(job, fieldMapping, documentTypeFieldGroups)
  exportToFile(Papa.unparse(rows, { header: false }), `${job.name}_invoice_header.csv`, 'text/csv')
}

export const exportJobToLineItemCsv = async (job: JobNode): Promise<void> => {
  const groupedDocumentFieldGroups = getGroupedJobDocumentFieldGroups(job)
  const documentTypeFieldGroups = getJobDocumentTypeFieldGroups(job)
  const fieldMapping = makeFieldMapping(groupedDocumentFieldGroups.flat())
  const groupedLineItems = groupedDocumentFieldGroups.map((documentFieldGroups) =>
    documentFieldGroups
      .filter((documentFieldGroup) => documentFieldGroup.fieldGroup!.repeatable)
      .map((documentFieldGroup) =>
        Object.fromEntries(
          documentFieldGroup.documentFields!.edges.map((documentFieldEdge) => [
            documentFieldEdge!.node!.field!.key,
            documentFieldEdge!.node!.value,
          ]),
        ),
      ),
  )
  const rows = getJobLineItemRows(
    job,
    groupedLineItems,
    documentTypeFieldGroups,
    fieldMapping,
    null,
  )
  exportToFile(Papa.unparse(rows, { header: false }), `${job.name}_line_items.csv`, 'text/csv')
}

export const bulkExportJobsToCevaCsv = async (
  jobs: JobNode[],
  enableLineItemsRowOrderPriority = false,
  downloadFile = true,
): Promise<void | { headers: string[]; rows: Record<string, string>[] }> => {
  const filenameWithoutExtension = jobs.map((job) => getFilenameWithoutExtension(job)).join('_')
  const headers = Object.values(CEVA_COLUMN_NAME_MAPPING)
  const jobsSortedByInvoiceNo = sortJobsByDateCreatedAndField(jobs, 'invoice_number')
  const rows = jobsSortedByInvoiceNo
    .map((job) => {
      const formattedJob = formatCevaJob(job)
      return formatCevaJobToRows(
        formattedJob,
        CEVA_COLUMN_NAME_MAPPING,
        enableLineItemsRowOrderPriority,
      )
    })
    .flat()

  if (downloadFile) {
    exportToFile(
      Papa.unparse({ fields: headers, data: rows }, { header: true }),
      `${filenameWithoutExtension}.csv`,
      'text/csv',
    )
  } else {
    // only useful for unit testing right now
    return { headers, rows }
  }
}

export const bulkExportJobsToFieldMappingCsv = async (jobs: JobNode[]): Promise<void> => {
  validateForBulkExport(jobs)
  const sortedJobs = [...jobs].sort((jobA, jobB) => {
    return +parseDateString(jobA.dateCreated) - +parseDateString(jobB.dateCreated)
  })
  const nameSet = new Set(sortedJobs.map((job) => job.name))
  const filename = Array.from(nameSet).join('_').substr(0, CSV_BULK_EXPORT_MAX_FILENAME_LENGTH)

  const documentTypeFieldGroups = getJobDocumentTypeFieldGroups(jobs[0])
  const rows = sortedJobs
    .map((job) => {
      const documentFieldGroups = getGroupedJobDocumentFieldGroups(job).flat()
      const fieldMapping = makeFieldMapping(documentFieldGroups)
      return getJobFieldMappingRows(job, fieldMapping, documentTypeFieldGroups)
    })
    .flat(1)
  exportToFile(
    Papa.unparse(rows, { header: false }),
    `${filename}_invoice_header_merged.csv`,
    'text/csv',
  )
}

export const bulkExportJobsToLineItemCsv = async (jobs: JobNode[]): Promise<void> => {
  // NOTE: this doesnt use the new line items row ordering logic in `makeLineItemsMapping` when the rows are constructed
  validateForBulkExport(jobs)
  const sortedJobs = [...jobs].sort((jobA, jobB) => {
    return +parseDateString(jobA.dateCreated) - +parseDateString(jobB.dateCreated)
  })
  const nameSet = new Set(sortedJobs.map((job) => job.name))
  const filename = Array.from(nameSet).join('_').substr(0, CSV_BULK_EXPORT_MAX_FILENAME_LENGTH)

  const documentTypeFieldGroups = getJobDocumentTypeFieldGroups(jobs[0])
  const rows = sortedJobs
    .map((job) => {
      const groupedDocumentFieldGroups = getGroupedJobDocumentFieldGroups(job)
      const fieldMapping = makeFieldMapping(groupedDocumentFieldGroups.flat())
      const groupedLineItems = groupedDocumentFieldGroups.map((documentFieldGroups) =>
        documentFieldGroups
          .filter((documentFieldGroup) => documentFieldGroup.fieldGroup!.repeatable)
          .map((documentFieldGroup) =>
            Object.fromEntries(
              documentFieldGroup.documentFields!.edges.map((documentFieldEdge) => [
                documentFieldEdge!.node!.field!.key,
                documentFieldEdge!.node!.value,
              ]),
            ),
          ),
      )
      return getJobLineItemRows(job, groupedLineItems, documentTypeFieldGroups, fieldMapping, null)
    })
    .flat(1)
  exportToFile(
    Papa.unparse(rows, { header: false }),
    `${filename}_line_items_merged.csv`,
    'text/csv',
  )
}
