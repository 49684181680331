import { FunctionComponent, useEffect, useState } from 'react'
import { batch, useDispatch } from 'react-redux'

import { BoxingProvider } from '@src/contexts/boxing_context'
import PageChecker from '@src/components/page-checker/PageChecker'
import { ActionSet } from '@src/components/shipment-form/ShipmentActions'
import JobViewer from '@src/components/job-viewer'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import ContentWithSidebar from '@src/components/content-with-sidebar/ContentWithSidebar'
import { ShipmentFormProvider } from '@src/contexts/shipment_form_context'
import { SidebarProvider } from '@src/components/content-with-sidebar/sidebar-context'
import { setActiveDocumentTable, setActiveFieldKey } from '@src/redux-features/document_editor'
import { clearJobEditor } from '@src/redux-features/job_editor'
import { JobDataProvider } from '@src/contexts/job_data_context'
import useGridHotKeys from '@src/hooks/boxn/useGridHotKeys'

type Props = {
  jobId: string
  actionSet: ActionSet
}

const JobEditor: FunctionComponent<Props> = ({ jobId, actionSet }) => {
  const [filePageId, setFilePageId] = useState(null as string | null)
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (isInitialLoadComplete) {
      dispatch(clearJobEditor())
    }

    return () => {
      dispatch(clearJobEditor())
    }
  }, [isInitialLoadComplete, dispatch])

  useGridHotKeys()

  return (
    <BoxingProvider>
      <ShipmentFormProvider>
        <SidebarProvider>
          <JobDataProvider jobId={jobId}>
            <ContentWithSidebar
              sidebar={
                <>
                  {!isInitialLoadComplete && <CenteredCircularProgress />}
                  {isInitialLoadComplete &&
                    (filePageId != null ? (
                      <PageChecker
                        jobId={jobId}
                        filePageId={filePageId}
                        readOnly={false}
                        actionSet={actionSet}
                      />
                    ) : (
                      <>Please upload & select a file for editing.</>
                    ))}
                </>
              }
              content={
                <JobViewer
                  jobId={jobId}
                  onFilePageChange={(filePage) => {
                    setFilePageId(filePage?.id || null)
                    batch(() => {
                      dispatch(setActiveFieldKey(null))
                      if (!filePage?.document?.documentTables?.edges?.length) {
                        dispatch(setActiveDocumentTable(null))
                      }
                    })
                  }}
                  onLoadComplete={() => setIsInitialLoadComplete(true)}
                />
              }
            />
          </JobDataProvider>
        </SidebarProvider>
      </ShipmentFormProvider>
    </BoxingProvider>
  )
}

export default JobEditor
