import { gql } from '@apollo/client'
import { FILE_FRAGMENT, FILE_PAGE_WITH_DOCUMENT } from '@src/graphql/queries/file'
import { DOCUMENT_TABLE_FRAGMENT } from '@src/graphql/queries/document'

export const COUNT_BY_JOB_STATUS = gql`
  query countByJobStatus {
    countByJobStatus
  }
`

export const GET_JOB_DATA_LITE = gql`
  query getJobDataLite($id: UUID!) {
    job(id: $id) {
      id
      name
      message
      status
      manualProduct
      ownerId
      qaId
      externalStatus
      externalAssignee {
        id
        email
      }
      jobTemplate {
        id
        name
        company {
          id
          name
        }
        apiPartner {
          id
          name
          apiPartnerCode {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ApiPartnerCodeEnum {
              value
              isFallback
            }
          }
        }
        reconType
        shipmentOpsTypes
      }
      pageAsyncBatches {
        edges {
          node {
            id
            status
          }
        }
      }
      task {
        id
        title
        message
        taskReferenceId
        status
        taskType
        dateCreated
        dateReceived
        dateConfirmed
        taskSlaTimeJobRef {
          id
          job {
            id
            dateCreated
            slaTime
          }
        }
        company {
          id
          name
        }
      }
      latestSavedReconAttemptId
    }
  }
`

export const FIELD_FRAGMENT = gql`
  fragment field on FieldNode {
    id
    autofillKey
    key
    name
    required
    validatorRegex
    validatorDescription
    dateFormatString
    defaultValue
    allowFreeText
    partialTableAllowed
    invalidCharsRegex
    values
    columnOrder
    fieldType {
      ... on FallbackValue {
        fallbackValue
        isFallback
      }
      ... on FieldTypeEnum {
        value
        isFallback
      }
    }
    searchableRecord {
      id
      searchKey
      displayKey
      model
      apiUrl
    }
  }
`

const FIELD_GROUP_FRAGMENT = gql`
  ${FIELD_FRAGMENT}
  fragment fieldGroup on FieldGroupNode {
    id
    name
    key
    autofillKey
    fields {
      edges {
        node {
          ...field
        }
      }
    }
  }
`

export const JOB_TABLE_FRAGMENT = gql`
  ${FIELD_GROUP_FRAGMENT}
  ${FIELD_FRAGMENT}
  fragment jobTable on JobTableNode {
    id
    fieldGroup {
      id
      repeatable
      ...fieldGroup
    }
    jobTableColumns {
      edges {
        node {
          id
          index
          field {
            ...field
          }
        }
      }
    }
    jobTableLineItems {
      edges {
        node {
          id
          jobTableCells {
            edges {
              node {
                id
                documentId
                top
                left
                width
                height
                value
                field {
                  id
                  key
                  autofillKey
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_JOB_DATA = gql`
  ${FIELD_GROUP_FRAGMENT}
  ${JOB_TABLE_FRAGMENT}
  ${DOCUMENT_TABLE_FRAGMENT}
  ${FILE_FRAGMENT}
  query getJobData($id: UUID!) {
    job(id: $id) {
      id
      name
      message
      status
      manualProduct
      owner {
        id
      }
      ownerId
      qa {
        id
      }
      qaId
      jobTable {
        ...jobTable
      }
      pageAsyncBatches {
        edges {
          node {
            id
            status
          }
        }
      }
      jobTemplate {
        id
        name
        reconType
        subtotalsRowEnabled
        mainTabEnabled
        company {
          id
          name
          usesChargeCodeV2
        }
        cargowiseConfig {
          id
        }
        apiPartner {
          id
          name
          apiPartnerCode {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ApiPartnerCodeEnum {
              value
              isFallback
            }
          }
        }
        shipmentOpsTypes
        documentTypes {
          edges {
            node {
              id
              name
              collapsible
              collapsibleOrderPriority
              documentTypeFieldGroups {
                edges {
                  node {
                    id
                    fieldGroup {
                      repeatable
                      ...fieldGroup
                    }
                  }
                }
              }
            }
          }
        }
      }
      task {
        id
        blocked
        title
        message
        taskReferenceId
        status
        taskType
        dateCreated
        dateReceived
        taskSlaTimeJobRef {
          id
          job {
            id
            dateCreated
            slaTime
          }
        }
        company {
          id
          name
        }
      }
      filePages {
        edges {
          node {
            id
            googleOcrData
            imageUrl
            pageNumber
            type
            isInCw
            file {
              ...FileAttributes
            }
            eDocuments {
              edges {
                node {
                  id
                  isInCw
                  dataTargetType {
                    ... on EDocsDataTargetTypeEnum {
                      value
                      isFallback
                    }
                    ... on FallbackValue {
                      fallbackValue
                      isFallback
                    }
                  }
                  cargowiseFileType {
                    id
                    code
                    description
                  }
                }
              }
            }
            document {
              id
              documentType {
                id
                tableShowsPreset
                name
                isEDocPublishedByDefault
                cargowiseFileType {
                  id
                  code
                  description
                }
                documentTypeFieldGroups {
                  edges {
                    node {
                      id
                      fieldGroup {
                        repeatable
                        ...fieldGroup
                      }
                    }
                  }
                }
              }
              documentTables {
                edges {
                  node {
                    ...documentTable
                  }
                }
              }
              documentFieldGroups {
                edges {
                  node {
                    id
                    rowOrderPriority
                    document {
                      id
                    }
                    fieldGroup {
                      id
                      repeatable
                    }
                    documentFields {
                      edges {
                        node {
                          id
                          field {
                            id
                            autofillKey
                            key
                            name
                          }
                          value
                          confirmed
                          top
                          left
                          width
                          height
                          confidenceScore
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const JOB_OVERVIEW_PAGE = gql`
  query jobOverviewPage(
    $page: Int!
    $query: String!
    $status: String
    $filters: [InputJobFilter!]
  ) {
    jobOverviewPage(page: $page, query: $query, status: $status, filters: $filters) {
      total
      items {
        id
        name
        slaTime
        dateCreated
        jobCode
        status
        clientReferenceNo
        taskId
        owner {
          email
          id
        }
        qa {
          email
          id
        }
        jobTemplate {
          id
          name
          companyId
          company {
            id
            name
          }
          jobTemplateExport {
            id
            jobTemplateExportType
          }
        }
        filePages {
          edges {
            node {
              id
              status
            }
          }
        }
      }
    }
  }
`

const JOB_FOR_EXPORT_FRAGMENT = gql`
  fragment JobForExport on JobNode {
    id
    name
    dateCreated
    status
    ownerId
    clientReferenceNo
    jobTemplate {
      id
      name
      jobTemplateExport {
        id
        jobTemplateExportType
        jobTemplateExportSheets {
          edges {
            node {
              id
              order
              displayColumnHeader
              fieldGroup {
                id
                name
              }
              jobTemplateExportColumns {
                edges {
                  node {
                    id
                    columnName
                    fixedValue
                    order
                    field {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      documentTypes {
        edges {
          node {
            id
            documentTypeFieldGroups {
              edges {
                node {
                  id
                  fieldGroup {
                    id
                    name
                    key
                    repeatable
                    fields {
                      edges {
                        node {
                          id
                          key
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      company {
        id
        name
      }
    }
    filePages {
      edges {
        node {
          ...FilePageWithDocumentAttributes
        }
      }
    }
  }
  ${FILE_PAGE_WITH_DOCUMENT}
`

export const JOB_FOR_EXPORT = gql`
  query jobForExport($id: UUID!) {
    job(id: $id) {
      ...JobForExport
    }
  }
  ${JOB_FOR_EXPORT_FRAGMENT}
`

export const USER_JOB_TEMPLATES = gql`
  query userJobTemplates {
    userJobTemplates {
      id
      name
      dateDeleted
      company {
        id
        name
      }
    }
  }
`

export const JOB_NAME = gql`
  query jobName($id: UUID!) {
    job(id: $id) {
      id
      name
      jobTemplate {
        id
        name
        reconType
        shipmentOpsTypes
        apiPartner {
          id
          name
          apiPartnerCode {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ApiPartnerCodeEnum {
              value
              isFallback
            }
          }
          ... on CargowiseConfigNode {
            connectorType
          }
        }
        cargowiseConfig {
          id
          connectorType
        }
        documentTypes {
          edges {
            node {
              id
              documentTypeFieldGroups {
                edges {
                  node {
                    id
                    fieldGroup {
                      id
                      key
                      autofillKey
                      fields {
                        edges {
                          node {
                            id
                            key
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const JOB_OPERATORS = gql`
  query jobOperators {
    jobOperators {
      id
      name
      email
    }
  }
`

export const JOBS_FOR_EXPORT = gql`
  query jobsForExport($jobIds: [String!]!) {
    jobs(jobIds: $jobIds) {
      ...JobForExport
    }
  }
  ${JOB_FOR_EXPORT_FRAGMENT}
`

export const JOB_CONFIRMATION_PAGE = gql`
  query jobConfirmationPage($id: UUID!) {
    job(id: $id) {
      id
      task {
        id
        dateConfirmed
        title
        taskReferenceId
        dateReceived
        message
      }
    }
  }
`

export const GET_JOB_FOR_TMS = gql`
  ${JOB_TABLE_FRAGMENT}
  query getJobForTms($id: UUID!) {
    job(id: $id) {
      id
      externalAssignee {
        id
        email
      }
      jobTemplate {
        id
        companyId
        apiPartnerId
        apiPartner {
          id
          name
          apiPartnerCode {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ApiPartnerCodeEnum {
              value
              isFallback
            }
          }
        }
      }
      jobTable {
        ...jobTable
      }
      filePages {
        edges {
          node {
            id
            document {
              id
              documentTables {
                edges {
                  node {
                    id
                    fieldGroup {
                      id
                      name
                      key
                      autofillKey
                      fields {
                        edges {
                          node {
                            id
                            name
                            key
                            autofillKey
                          }
                        }
                      }
                    }
                    documentFieldGroups {
                      edges {
                        node {
                          id
                          documentFields {
                            edges {
                              node {
                                id
                                field {
                                  id
                                  key
                                  autofillKey
                                  name
                                }
                                value
                                confirmed
                                confidenceScore
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              documentFieldGroups {
                edges {
                  node {
                    id
                    fieldGroup {
                      id
                      repeatable
                      name
                      autofillKey
                      fields {
                        edges {
                          node {
                            id
                            autofillKey
                          }
                        }
                      }
                    }
                    documentFields {
                      edges {
                        node {
                          id
                          field {
                            id
                            name
                            autofillKey
                          }
                          value
                          confidenceScore
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_JOB_SEARCH_PARAMETERS = gql`
  query jobSearchParameters {
    jobSearchParameters {
      queryString
      filters {
        id
        column
        operation
        value
      }
    }
  }
`
