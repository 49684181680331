import { Box, Button, Grid, Typography } from '@material-ui/core'
import {
  CargowiseConfigNode,
  InputColdStartFile,
  InputEAdaptorColdStartParams,
  useEadaptorColdStartMutation,
} from '@src/graphql/types'
import { FunctionComponent, useState } from 'react'
import EAdaptorFileCard from './EAdaptorFileCard'
import { useDialog } from 'muibox'
import { formatMaybeApolloError } from '@src/utils/errors'

type Props = {
  cargowiseConfig: CargowiseConfigNode
}

type EAdaptorReport = {
  title: string
  subtitle: string
}

const eAdaptorReports: Record<keyof InputEAdaptorColdStartParams, EAdaptorReport> = {
  shipmentProfileReports: {
    title: 'Shipment Profile',
    subtitle: 'Shipment Profile Reports',
  },
  cdecProfileReports: {
    title: 'Declaration Profile',
    subtitle: 'Declaration Profile Reports',
  },
  jobManagementReports: {
    title: 'Job Costings',
    subtitle: 'Job Management Reports',
  },
  apInvoiceReports: {
    title: 'Payables',
    subtitle: 'AP Transaction Reports',
  },
  arInvoiceReports: {
    title: 'Receivables',
    subtitle: 'AR Transaction Reports',
  },
  transportProfileReports: {
    title: 'Transport Jobs',
    subtitle: 'Transport Profile Reports',
  },
}

const EAdaptorColdStart: FunctionComponent<Props> = ({ cargowiseConfig }) => {
  const [running, setRunning] = useState(false)
  const [files, setFiles] = useState({} as Record<keyof InputEAdaptorColdStartParams, File[]>)
  const dialog = useDialog()

  const [eadaptorColdStart] = useEadaptorColdStartMutation()

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        const fileData = reader.result
        if (typeof fileData === 'string') {
          resolve(fileData.split(',')[1]) // Extract base64 data from the data URL
        } else {
          reject(new Error('Failed to read file data.'))
        }
      }
      reader.onerror = () => reject(new Error('Failed to read file data.'))
      reader.readAsDataURL(file)
    })
  }

  const encodeFiles = async (files: File[]): Promise<InputColdStartFile[]> => {
    const encodedFiles: InputColdStartFile[] = []
    for (let i = 0; i < files.length; i++) {
      const encodedFile = await fileToBase64(files[i])
      const inputFile: InputColdStartFile = {
        fileName: files[i].name,
        fileData: encodedFile,
      }
      encodedFiles.push(inputFile)
    }
    return encodedFiles
  }

  return (
    <Box>
      <Box py={2}>
        <Typography variant='h3'>eAdaptor</Typography>
        <Typography gutterBottom>
          The selected config is an eAdaptor config. This is currently experimental.
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {Object.entries(eAdaptorReports).map(([key, report]) => (
          <Grid item xs={12} md={4} key={key}>
            <EAdaptorFileCard
              title={report.title}
              subtitle={report.subtitle}
              files={files[key as keyof InputEAdaptorColdStartParams] ?? []}
              setFiles={(newFiles) => setFiles({ ...files, [key]: newFiles })}
            />
          </Grid>
        ))}
      </Grid>
      <Box py={2}>
        <Button
          disabled={running}
          onClick={async () => {
            setRunning(true)
            try {
              try {
                if (!Object.entries(files).find(([key, reportFiles]) => reportFiles.length > 0)) {
                  throw 'You must upload at least one file!'
                }
                await eadaptorColdStart({
                  variables: {
                    apiPartnerName: cargowiseConfig.name,
                    eadaptorColdStartParams: Object.fromEntries(
                      await Promise.all(
                        Object.keys(eAdaptorReports).map(async (key) => {
                          return [
                            key,
                            await encodeFiles(
                              files[key as keyof InputEAdaptorColdStartParams] ?? [],
                            ),
                          ]
                        }),
                      ),
                    ),
                  },
                })
              } catch (e) {
                await dialog.alert({
                  title: `Error during cold start`,
                  message: (
                    <>
                      <Typography>
                        The following error was encountered while running the cold start
                      </Typography>
                      <blockquote>{formatMaybeApolloError(e)}</blockquote>
                    </>
                  ),
                })
                return
              }
              await dialog.alert({
                title: `Cold start successful`,
                message: `Successfully updated data`,
              })
            } finally {
              setRunning(false)
            }
          }}
          variant='outlined'
          color='primary'
        >
          {running ? 'Running cold start...' : `Run cold start`}
        </Button>
      </Box>
    </Box>
  )
}

export default EAdaptorColdStart
