import {
  AnReconAutofillKey,
  DocumentTableNode,
  InputDocumentTable,
  JobTemplateReconType,
} from '@src/graphql/types'

export const getLumpsumInputTable = (
  lumpsumRow: (string | number)[],
  lumpsumColumns: string[],
  lumpsumTable: DocumentTableNode,
): InputDocumentTable => {
  const columnsKeyNameMapping =
    lumpsumTable?.fieldGroup!.fields!.edges?.reduce(
      (acc, fieldEdge) => {
        acc[fieldEdge!.node!.name] = fieldEdge!.node!.autofillKey
        return acc
      },
      {} as Record<string, string>,
    ) || {}
  const fieldMapping = Object.fromEntries(
    lumpsumColumns.map((columnName, idx) => {
      const columnAutofillKey = columnsKeyNameMapping[columnName]
      const columnValue = lumpsumRow[idx]
      return [columnAutofillKey, columnValue.toString()]
    }),
  )

  return {
    id: lumpsumTable.id,
    fieldGroupId: lumpsumTable.fieldGroup!.id,
    fieldGroupKey: lumpsumTable.fieldGroup!.autofillKey,
    fieldCoordinates: {},
    lineItems: [{ id: lumpsumTable.id, fieldMapping, documentTableId: lumpsumTable.id }],
    tableColumns: [],
  }
}

const LUMPSUM_OVERRIDE_COLUMNS = Object.freeze({
  [JobTemplateReconType.Ap]: {
    lumpsumAmount: 'Charge Cost',
    unitPrice: 'Unit Price',
  },
  [JobTemplateReconType.ArrivalNotice]: {
    lumpsumAmount: 'Total amount',
    unitPrice: 'Unit price',
  },
})

const getLumpsumAmount = (
  reconTable: DocumentTableNode,
  reconType: JobTemplateReconType.Ap | JobTemplateReconType.ArrivalNotice,
): number => {
  const lumpsumAmountColumn = LUMPSUM_OVERRIDE_COLUMNS[reconType].lumpsumAmount
  const lumpsumAmount = reconTable.documentFieldGroups!.edges.reduce(
    (amountAcc, documentFieldGroupEdge) => {
      const columnValueMapping = documentFieldGroupEdge!.node!.documentFields!.edges?.reduce(
        (acc, documentFieldEdge) => {
          acc[documentFieldEdge!.node!.field!.name] = documentFieldEdge!.node!.value
          return acc
        },
        {} as Record<string, string>,
      )
      amountAcc += parseFloat(columnValueMapping[lumpsumAmountColumn])
      return amountAcc
    },
    0,
  )
  return lumpsumAmount
}

export const getLumpsum = (
  reconTable: DocumentTableNode,
  reconType: JobTemplateReconType.Ap | JobTemplateReconType.ArrivalNotice,
): { lumpsumAmount: number; lumpsumRow: Record<string, string | number> } => {
  const lumpsumAmount = getLumpsumAmount(reconTable, reconType)
  const firstTableRowData =
    reconTable.documentFieldGroups?.edges?.[0]?.node?.documentFields?.edges?.reduce(
      (acc, documentFieldEdge) => {
        acc[documentFieldEdge!.node!.field!.name] = documentFieldEdge!.node!.value
        return acc
      },
      {} as Record<string, string>,
    )
  const lumpsumOverrideColumns = LUMPSUM_OVERRIDE_COLUMNS[reconType]
  const lumpsumRow = {
    ...firstTableRowData,
    Quantity: 1,
    [lumpsumOverrideColumns.lumpsumAmount]: lumpsumAmount,
    [lumpsumOverrideColumns.unitPrice]: lumpsumAmount,
  } as Record<string, string | number>
  return { lumpsumAmount, lumpsumRow }
}

export const getDefaultRowsColumns = (
  documentTable: DocumentTableNode,
): { rows: string[][]; columns: string[] } => {
  const columns =
    documentTable.fieldGroup!.fields!.edges?.map((fieldEdge) => fieldEdge!.node!.name) || []
  const rows =
    documentTable?.documentFieldGroups?.edges.map((documentFieldGroupEdge) => {
      const columnValueMapping = documentFieldGroupEdge!.node!.documentFields!.edges?.reduce(
        (acc, documentFieldEdge) => {
          acc[documentFieldEdge!.node!.field!.name] = documentFieldEdge!.node!.value
          return acc
        },
        {} as Record<string, string>,
      )
      return columns.map((column) => columnValueMapping[column] ?? '')
    }) || []

  return { rows, columns }
}

export const getLumpsumTable = (
  reconTables: DocumentTableNode[],
  reconType: JobTemplateReconType.Ap | JobTemplateReconType.ArrivalNotice,
): DocumentTableNode | null => {
  if (reconTables.length > 0) {
    if (reconType === JobTemplateReconType.ArrivalNotice) {
      return (
        reconTables.find((reconTable) => {
          return (
            reconTable!.fieldGroup!.autofillKey === AnReconAutofillKey.ChargeLineItem.toLowerCase()
          )
        }) ?? null
      )
    }
    return reconTables[0]
  }
  return null
}
