import { gql } from '@apollo/client'
import { TASK_NOTE_FRAGMENT } from '../queries/taskNote'

export const CREATE_TASK_NOTE = gql`
  ${TASK_NOTE_FRAGMENT}
  mutation createTaskNote($taskId: String!, $content: String!) {
    createTaskNote(taskId: $taskId, content: $content) {
      taskNote {
        ...taskNote
      }
    }
  }
`

export const DELETE_TASK_NOTE = gql`
  mutation deleteTaskNote($taskNoteId: String!) {
    deleteTaskNote(taskNoteId: $taskNoteId) {
      ok
    }
  }
`
