import { FunctionComponent } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'

import { DocumentTypeNode, JobNodeEdge, JobTemplateNode, Maybe, TaskNode } from '@src/graphql/types'
import { PAGE_ASSOC_DIMENSIONS } from '@src/utils/app_constants'

import theme from '@src/utils/theme'

import { Box, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const useStyles = makeStyles({
  fileInfoHeader: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    height: PAGE_ASSOC_DIMENSIONS.PAGE_INFO_HEADER_HEIGHT,
    padding: theme.spacing(2),
  },
  finishButton: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  jobTypeSelect: {
    marginRight: theme.spacing(2),
    width: '150px',
  },
  createJobButton: {
    marginRight: theme.spacing(5),
  },
  jobsDropdown: {
    width: 210,
    paddingRight: theme.spacing(3),
  },
  jobsNameDropdown: {
    marginRight: 20,
    width: 200,
  },
  centerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  insideBox: {
    display: 'flex',
    alignItems: 'center',
  },
})

type Props = {
  selectedTask: Maybe<Pick<TaskNode, 'company' | 'jobs'>>
  setIsCreateJob: (isCreateJob: boolean) => void
  selectedJob: Maybe<JobNodeEdge>
  setSelectedJob: (selectedJob: Maybe<JobNodeEdge>) => void
  setIsPageAssocDialogOpen: (isPageAssocDialogOpen: boolean) => void
  setSelectedJobTemplate: (selectedJobTemplate: Maybe<JobTemplateNode>) => void
  selectedJobTemplate: Maybe<JobTemplateNode>
  selectedDocType: Maybe<DocumentTypeNode>
  setSelectedDocType: (selectedDocType: Maybe<DocumentTypeNode>) => void
  selectedFilePageIds: string[]
  filePageIdsBeingAssociated: string[]
}

const JobSelector: FunctionComponent<Props> = ({
  setIsCreateJob,
  selectedTask,
  setIsPageAssocDialogOpen,
  selectedJob,
  setSelectedJob,
  setSelectedJobTemplate,
  selectedJobTemplate,
  selectedDocType,
  setSelectedDocType,
  selectedFilePageIds,
  filePageIdsBeingAssociated,
}) => {
  const classes = useStyles()
  const jobTemplates =
    selectedTask?.company?.jobTemplates?.edges.map((jobTemplateEdge) => jobTemplateEdge!.node!) ??
    []

  let docTypes: Maybe<DocumentTypeNode>[] = []

  if (selectedJobTemplate) {
    docTypes = selectedJobTemplate.documentTypes!.edges.map((edge) => edge!.node!)
  } else if (selectedJob) {
    docTypes = selectedJob.node!.jobTemplate!.documentTypes!.edges.map((edge) => edge!.node!)
  }

  const selectedFilePageIdsAvailable =
    selectedFilePageIds.filter((filePageId) => !filePageIdsBeingAssociated.includes(filePageId))
      .length > 0
  const addToJobEnabled =
    selectedJob?.node?.id && selectedDocType?.id && selectedFilePageIdsAvailable
  const createJobEnabled =
    selectedTask && selectedJobTemplate?.id && selectedDocType?.id && selectedFilePageIdsAvailable

  return (
    <>
      <Box display='inline-flex'>
        <Autocomplete
          className={classes.jobsDropdown}
          value={selectedJobTemplate}
          onChange={(_evt, newValue) => {
            setSelectedJobTemplate(newValue)
          }}
          options={jobTemplates as Maybe<JobTemplateNode>[]}
          renderOption={(jobTempNode: Maybe<JobTemplateNode>) => jobTempNode?.name}
          getOptionLabel={(value) => (typeof value === 'string' ? value : value?.name || '')}
          renderInput={(params) => (
            <TextField
              {...params}
              margin='normal'
              label='Job Template Name'
              variant='filled'
              size='small'
            />
          )}
        />

        <Autocomplete
          className={classes.jobsDropdown}
          value={selectedDocType}
          onChange={(_evt, newValue) => {
            setSelectedDocType(newValue)
          }}
          disabled={!selectedJobTemplate && !selectedJob}
          options={docTypes}
          renderOption={(docTypeNode: Maybe<DocumentTypeNode>) => docTypeNode?.name}
          getOptionLabel={(value) => (typeof value === 'string' ? value : value?.name || '')}
          renderInput={(params) => (
            <TextField
              {...params}
              margin='normal'
              label='Document Type'
              variant='filled'
              size='small'
            />
          )}
        />
      </Box>

      <div className={classes.centerBox}>
        <Button
          className={classes.createJobButton}
          disabled={!createJobEnabled}
          color='primary'
          variant='contained'
          onClick={() => {
            setIsCreateJob(true)
            setSelectedJob(null)
            setIsPageAssocDialogOpen(true)
          }}
        >
          Create Job
        </Button>
        <div className={classes.insideBox}>
          <Autocomplete
            className={classes.jobsNameDropdown}
            value={selectedJob}
            onChange={(_evt, newValue) => {
              setSelectedJob(newValue)
            }}
            options={selectedTask?.jobs?.edges ?? []}
            renderOption={(jobNode: Maybe<JobNodeEdge>) => jobNode?.node?.name}
            getOptionLabel={(value) =>
              typeof value === 'string' ? value : value?.node?.name || ''
            }
            renderInput={(params) => (
              <TextField
                {...params}
                margin='normal'
                label='Job Name'
                variant='filled'
                size='small'
              />
            )}
          />
          <Button
            disabled={!addToJobEnabled}
            color='primary'
            variant='contained'
            onClick={() => {
              setIsCreateJob(false)
              setIsPageAssocDialogOpen(true)
            }}
          >
            Add to Job
          </Button>
        </div>
      </div>
    </>
  )
}

export default JobSelector
