import { ApReconAutofillKey, DocumentTableNode } from '@src/graphql/types'
import { cleanStringForMatching } from './clean_strings'

export const removeChargeDescription = (reconTable: DocumentTableNode): DocumentTableNode => {
  const filteredReconTable = JSON.parse(JSON.stringify(reconTable)) as DocumentTableNode
  filteredReconTable!.documentFieldGroups!.edges!.forEach((documentFieldGroupEdge) => {
    const docFieldChargeDescIdx =
      documentFieldGroupEdge?.node?.documentFields?.edges?.findIndex(
        (documentFieldEdge) =>
          cleanStringForMatching(documentFieldEdge?.node?.field?.autofillKey, true) ===
          cleanStringForMatching(ApReconAutofillKey.ChargeDescription, true),
      ) ?? -1
    if (docFieldChargeDescIdx !== -1) {
      documentFieldGroupEdge?.node?.documentFields?.edges?.splice(docFieldChargeDescIdx, 1)
    }
  })
  const fieldChargeDescIdx =
    filteredReconTable!.fieldGroup!.fields!.edges!.findIndex(
      (fieldEdge) =>
        cleanStringForMatching(fieldEdge?.node?.autofillKey, true) ===
        cleanStringForMatching(ApReconAutofillKey.ChargeDescription, true),
    ) ?? -1
  if (fieldChargeDescIdx !== -1) {
    filteredReconTable!.fieldGroup!.fields!.edges!.splice(fieldChargeDescIdx, 1)
  }
  return filteredReconTable
}
