import { FunctionComponent, SVGProps } from 'react'

/**
 * InfoPopoverIcon Component
 *
 * This functional component represents an SVG icon typically used for informational
 * or help-related purposes. It renders a circular information symbol with an optional
 * customizable fill color.
 *
 * @param {string} fill (optional) - The fill color of the SVG icon.
 * @returns {JSX.Element} - The rendered SVG icon.
 */

type Props = {
  fill?: string
} & SVGProps<SVGAElement>

const InfoPopoverIcon: FunctionComponent<Props> = ({ fill }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill={fill}>
    <g clipPath='url(#clip0_1585_1534)'>
      <path
        d='M7 0.21875C3.25508 0.21875 0.21875 3.25618 0.21875 7C0.21875 10.746 3.25508 13.7812 7 13.7812C10.7449 13.7812 13.7812 10.746 13.7812 7C13.7812 3.25618 10.7449 0.21875 7 0.21875ZM7 3.22656C7.63427 3.22656 8.14844 3.74073 8.14844 4.375C8.14844 5.00927 7.63427 5.52344 7 5.52344C6.36573 5.52344 5.85156 5.00927 5.85156 4.375C5.85156 3.74073 6.36573 3.22656 7 3.22656ZM8.53125 10.1719C8.53125 10.3531 8.38433 10.5 8.20312 10.5H5.79688C5.61567 10.5 5.46875 10.3531 5.46875 10.1719V9.51562C5.46875 9.33442 5.61567 9.1875 5.79688 9.1875H6.125V7.4375H5.79688C5.61567 7.4375 5.46875 7.29058 5.46875 7.10938V6.45312C5.46875 6.27192 5.61567 6.125 5.79688 6.125H7.54688C7.72808 6.125 7.875 6.27192 7.875 6.45312V9.1875H8.20312C8.38433 9.1875 8.53125 9.33442 8.53125 9.51562V10.1719Z'
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id='clip0_1585_1534'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default InfoPopoverIcon
