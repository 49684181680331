import { formatMaybeApolloError } from '@src/utils/errors'
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import {
  resetLineItemsChanges,
  readDataFromNewGrid,
  setActiveDocumentTable,
  setActiveFieldKey,
  setTableExtractionStep,
  setLineItemsTableSubmitted,
  setTableEditMode,
} from '@src/redux-features/document_editor'
import {
  selectActiveDocumentTable,
  selectActiveDocumentTablePreviousId,
  selectActiveDocumentTableNextId,
} from '@src/redux-features/document_editor/document_table'
import { documentTableSelectors } from '@src/redux-features/document_editor/document_table'
import GridOffIcon from '@material-ui/icons/GridOff'
import InfoIcon from '@material-ui/icons/Info'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'
import { batch, useDispatch, useSelector } from 'react-redux'
import theme from '@src/utils/theme'
import Chip from '@material-ui/core/Chip'
import { RootState } from '@src/utils/store'
import { getFilePageDocumentTables, getPageIdFromDocTableId } from '@src/utils/shipment_form'
import { ShipmentFormContext } from '@src/contexts/shipment_form_context'
import { setCurrentFilePageId, setNextFilePageId } from '@src/redux-features/job_editor'
import { UNMERGE_DOCUMENT_TABLE } from '@src/graphql/mutations/document'
import {
  JobNode,
  Mutation,
  MutationUnmergeDocumentTableArgs,
  JobTemplateReconType,
  FilePageType,
} from '@src/graphql/types'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { EXTRACT_TABLE_SLEEP } from '@src/utils/app_constants'
import ShortcutsDialog from '@src/components/data-grid/ShortcutsDialog'
import SendSOAToCWDialog from '../SendSOAToCWDialog'
import BatchCheckShipmentInfoDialog from '../BatchCheckShipmentInfoDialog'
import { TableExtractionStep } from '@src/redux-features/document_editor/magic_grid'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { isCargowiseConfig } from '@src/utils/api_partner'
import { CallToAction } from '@src/utils/observability/CallToAction'
import { LogEventType } from '@src/utils/observability/LogEventType'

type Props = {
  job: JobNode
  numRows: number
  checkSoaTableMissingFields?: () => string | null
  validateSoaTable?: () => boolean
  openBulkReconDialogAndReconcile?: () => void
  originalNumRows?: number
  openBulkReplace: () => void
  saveTable: () => Promise<void>
  jobTableActive: boolean
  setIsUpdateChargeQuantityDialogOpen?: Dispatch<SetStateAction<boolean>>
  copyFromExtracted?: () => void
}

const useStyles = makeStyles({
  primaryActions: {
    flexGrow: 1,
    '& > button': {
      marginRight: theme.spacing(1),
    },
  },
  saveChangesBtn: {
    backgroundColor: theme.palette.success.main,
  },
  discardChangesBtn: {
    backgroundColor: theme.palette.error.main,
  },
  shortcutsBtn: {
    marginRight: theme.spacing(1),
  },
})

const DataGridToolbar: FunctionComponent<Props> = ({
  job,
  numRows,
  checkSoaTableMissingFields,
  validateSoaTable,
  openBulkReconDialogAndReconcile,
  originalNumRows,
  openBulkReplace,
  saveTable,
  jobTableActive,
  setIsUpdateChargeQuantityDialogOpen,
  copyFromExtracted,
}) => {
  const { logEvent } = useEventLogger()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const activeDocumentTable = useSelector((state: RootState) =>
    selectActiveDocumentTable(state.documentEditor),
  )
  const documentTables = useSelector((state: RootState) =>
    documentTableSelectors.selectAll(state.documentEditor),
  )
  const prevTableId = useSelector((state: RootState) =>
    selectActiveDocumentTablePreviousId(state.documentEditor),
  )
  const nextTableId = useSelector((state: RootState) =>
    selectActiveDocumentTableNextId(state.documentEditor),
  )
  const nextFilePageId = useSelector((state: RootState) => state.jobEditor.nextFilePageId)
  const { saveAndRefetchDocumentEditorJob, updateDocumentMapping, validateAndSaveAllSoa } =
    useContext(ShipmentFormContext)
  const currentTableExtractionStep = useSelector(
    (state: RootState) => state.documentEditor.currentTableExtractionStep,
  )
  const tableEditModeEnabled = useSelector(
    (state: RootState) => state.documentEditor.tableEditModeEnabled,
  )
  const [isBulkReconciling, setIsBulkReconciling] = useState(false)
  const [unmergeDocumentTable] = useMutation<
    Pick<Mutation, 'unmergeDocumentTable'>,
    MutationUnmergeDocumentTableArgs
  >(UNMERGE_DOCUMENT_TABLE)
  const [shortcutsOpen, setShortcutsOpen] = useState(false)
  const [isSendSOAToCWDialogOpen, setIsSendSOAToCWDialogOpen] = useState(false)
  const [isBatchCheckShipmentInfoDialogOpen, setIsBatchCheckShipmentInfoDialogOpen] =
    useState(false)
  const reconType = job!.jobTemplate!.reconType
  const hasNonExcelFilePage = useSelector((state: RootState) =>
    documentTableSelectors
      .selectAll(state.documentEditor)
      .some((documentTable) => documentTable.filePageType !== FilePageType.Excel),
  )
  const isCargowiseConfigMemo = useMemo(
    () => isCargowiseConfig(job.jobTemplate?.apiPartner),
    [job.jobTemplate?.apiPartner],
  )
  // table edit mode doesn't work with the spreadsheet, at least for now, and prevents editing the
  // old grid
  // TODO: get rid of tableEditModeEnabled
  useEffect(() => {
    if (tableEditModeEnabled) {
      dispatch(setTableEditMode(false))
    }
  }, [dispatch, tableEditModeEnabled])

  const goToPageAndShowTable = useCallback(
    (nextPageId: string, documentTableId: string): void => {
      batch(() => {
        updateDocumentMapping(nextPageId)
        dispatch(setCurrentFilePageId(nextPageId))
        dispatch(setActiveFieldKey(null))
      })
      // delay dispatch to let switching page finish first before
      // showing line items table
      setTimeout(() => {
        dispatch(setActiveDocumentTable(documentTableId))
      })
    },
    [dispatch, updateDocumentMapping],
  )

  const { moveToPrevTable, moveToNextTable } = useMemo(() => {
    const switchTablePage = (isNext: boolean): void => {
      let newDocumentTableId = null
      if (isNext) {
        newDocumentTableId = nextTableId
      } else {
        newDocumentTableId = prevTableId
      }
      if (newDocumentTableId) {
        dispatch(resetLineItemsChanges())
        void saveTable()
        const newPageId = getPageIdFromDocTableId(job, newDocumentTableId, documentTables)
        goToPageAndShowTable(newPageId, newDocumentTableId)
      }
      void logEvent(LogEventType.EDIT_LINE_ITEMS_CTA, {
        cta: isNext ? CallToAction.NEXT : CallToAction.PREV,
        event_time: new Date(),
        job_id: job.id,
      })
    }
    return {
      moveToPrevTable: () => switchTablePage(false),
      moveToNextTable: () => switchTablePage(true),
    }
  }, [nextTableId, prevTableId, saveTable, job, goToPageAndShowTable, documentTables, dispatch])

  const handleUnmergeTable = async (): Promise<void> => {
    try {
      await saveTable()
      const newDocumentTableId = nextTableId ?? prevTableId
      const newPageId = getPageIdFromDocTableId(job, newDocumentTableId!, documentTables)

      await unmergeDocumentTable({
        variables: {
          documentTableId: activeDocumentTable!.id,
        },
      })

      await saveAndRefetchDocumentEditorJob()
      goToPageAndShowTable(newPageId, newDocumentTableId!)

      void logEvent(LogEventType.EDIT_LINE_ITEMS_CTA, {
        cta: CallToAction.UNMERGE,
        event_time: new Date(),
        job_id: job.id,
      })
    } catch (error) {
      enqueueSnackbar(`Error unmerging table: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
  }

  const newGridEnabled = useSelector((state: RootState) => state.documentEditor.boxn.newGridEnabled)

  const extractFromOldGrid = (): void => {
    batch(() => {
      dispatch(setLineItemsTableSubmitted(false))
      dispatch(setTableExtractionStep(TableExtractionStep.Extracting))

      void logEvent(LogEventType.EDIT_LINE_ITEMS_CTA, {
        cta: CallToAction.EXTRACT,
        event_time: new Date(),
        job_id: job.id,
      })
    })
  }

  const extractFromNewGrid = (): void => {
    dispatch(setTableExtractionStep(TableExtractionStep.Extracting))
    setTimeout((): void => {
      dispatch(readDataFromNewGrid())
    }, EXTRACT_TABLE_SLEEP)

    void logEvent(LogEventType.EDIT_LINE_ITEMS_CTA, {
      cta: CallToAction.EXTRACT,
      event_time: new Date(),
      job_id: job.id,
    })
  }

  const copyFromExtractedLogged = useCallback(() => {
    if (copyFromExtracted) {
      void logEvent(LogEventType.EDIT_LINE_ITEMS_CTA, {
        cta: CallToAction.COPY_FROM_EXTRACTED,
        event_time: new Date(),
        job_id: job.id,
      })
      return copyFromExtracted()
    }
  }, [copyFromExtracted, job])

  const openBulkReplaceLogged = useCallback(() => {
    if (openBulkReplace) {
      void logEvent(LogEventType.EDIT_LINE_ITEMS_CTA, {
        cta: CallToAction.REPLACE,
        event_time: new Date(),
        job_id: job.id,
      })
      return openBulkReplace()
    }
  }, [openBulkReplace, job])

  const openSendSOAToCWDialog = async (): Promise<void> => {
    try {
      if (validateAndSaveAllSoa) await validateAndSaveAllSoa()
    } catch (err) {
      enqueueSnackbar(`Error sending draft to CW: ${formatMaybeApolloError(err)}`, {
        variant: 'error',
      })
      return
    }
    setIsSendSOAToCWDialogOpen(true)
    void logEvent(LogEventType.EDIT_LINE_ITEMS_CTA, {
      cta: CallToAction.SEND_DRAFT_TO_CW,
      event_time: new Date(),
      job_id: job.id,
    })
  }

  const openCheckShipmentsDialog = async (): Promise<void> => {
    try {
      if (validateAndSaveAllSoa) await validateAndSaveAllSoa()
    } catch (err) {
      enqueueSnackbar(`Error checking shipments: ${formatMaybeApolloError(err)}`, {
        variant: 'error',
      })
      return
    }
    setIsBatchCheckShipmentInfoDialogOpen(true)
    void logEvent(LogEventType.EDIT_LINE_ITEMS_CTA, {
      cta: CallToAction.CHECK_SHIPMENTS,
      event_time: new Date(),
      job_id: job.id,
    })
  }

  useEffect(() => {
    if (nextFilePageId) {
      void saveTable()
      const documentTables = getFilePageDocumentTables(job, nextFilePageId)
      if (documentTables) {
        const newDocumentTableId = documentTables[0].id
        goToPageAndShowTable(nextFilePageId, newDocumentTableId)
      } else {
        dispatch(setCurrentFilePageId(nextFilePageId))
        dispatch(setActiveDocumentTable(null))
      }
      dispatch(setNextFilePageId(null))
    }
  }, [dispatch, goToPageAndShowTable, job, nextFilePageId, saveTable])

  const canUpdateChargeQuantity =
    reconType === JobTemplateReconType.ArrivalNotice &&
    numRows !== originalNumRows &&
    activeDocumentTable!.fieldGroup!.name! === 'Container details'

  return (
    <>
      <Box display='flex' paddingY={1}>
        <div className={classes.primaryActions}>
          {!jobTableActive && (
            <Button
              color='primary'
              variant='contained'
              disabled={currentTableExtractionStep !== TableExtractionStep.Idle}
              onClick={newGridEnabled ? extractFromNewGrid : extractFromOldGrid}
              size='small'
              data-testid='extract-table-btn'
            >
              Extract Table
            </Button>
          )}
          {jobTableActive && copyFromExtracted && hasNonExcelFilePage && (
            <Button
              color='primary'
              variant='contained'
              onClick={copyFromExtractedLogged}
              size='small'
              data-testid='extract-table-btn'
            >
              Copy from Extracted
            </Button>
          )}
          <Button variant='contained' onClick={openBulkReplaceLogged} size='small'>
            Replace
          </Button>
          {(nextTableId || prevTableId) && !jobTableActive && (
            <Button color='primary' variant='contained' onClick={handleUnmergeTable} size='small'>
              Unmerge Table
            </Button>
          )}
          {jobTableActive &&
            openBulkReconDialogAndReconcile &&
            checkSoaTableMissingFields &&
            validateSoaTable && (
              <>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={async () => {
                    setIsBulkReconciling(true)
                    try {
                      if (validateAndSaveAllSoa) await validateAndSaveAllSoa()
                      openBulkReconDialogAndReconcile()
                    } catch (err) {
                      enqueueSnackbar(`Error batch reconciling: ${formatMaybeApolloError(err)}`, {
                        variant: 'error',
                      })
                    } finally {
                      setIsBulkReconciling(false)
                    }
                  }}
                  size='small'
                  disabled={isBulkReconciling}
                >
                  {isBulkReconciling ? 'Reconciling...' : 'Reconcile'}
                </Button>
                {isCargowiseConfigMemo && (
                  <>
                    <Button variant='contained' onClick={openSendSOAToCWDialog} size='small'>
                      Send Draft to CW
                    </Button>
                    <Button variant='contained' onClick={openCheckShipmentsDialog} size='small'>
                      Check Shipments
                    </Button>
                  </>
                )}
              </>
            )}
          {!jobTableActive && (
            <>
              <Button onClick={moveToPrevTable} disabled={!prevTableId} size='small'>
                Prev
              </Button>
              <Button onClick={moveToNextTable} disabled={!nextTableId} size='small'>
                Next
              </Button>
            </>
          )}
        </div>
        <IconButton
          className={classes.shortcutsBtn}
          aria-label='info'
          size='small'
          onClick={() => setShortcutsOpen(true)}
        >
          <InfoIcon />
        </IconButton>
        <Button
          color='secondary'
          variant='contained'
          onClick={async () => {
            await saveTable()
            if (canUpdateChargeQuantity && setIsUpdateChargeQuantityDialogOpen) {
              setIsUpdateChargeQuantityDialogOpen(true)
            }
            batch(() => {
              dispatch(setActiveDocumentTable(null))
              dispatch(setTableEditMode(false))
            })
          }}
          startIcon={<GridOffIcon />}
          size='small'
          title='Hide Table'
          data-testid='hide-line-item-table'
        >
          <Chip label={numRows} color='primary' size='small' />
        </Button>
      </Box>
      {shortcutsOpen && (
        <ShortcutsDialog open={shortcutsOpen} handleCloseDialog={() => setShortcutsOpen(false)} />
      )}
      {isSendSOAToCWDialogOpen && (
        <SendSOAToCWDialog
          isOpen={isSendSOAToCWDialogOpen}
          close={() => setIsSendSOAToCWDialogOpen(false)}
          jobId={job!.id}
        />
      )}
      {isBatchCheckShipmentInfoDialogOpen && (
        <BatchCheckShipmentInfoDialog
          isOpen={isBatchCheckShipmentInfoDialogOpen}
          close={() => setIsBatchCheckShipmentInfoDialogOpen(false)}
          jobId={job!.id}
        />
      )}
    </>
  )
}

export default DataGridToolbar
