import { JobTableLineItem } from '@src/utils/line_items'
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit'
import { FieldGroupNode, Maybe } from '@src/graphql/types'

export const jobTableLineItemsAdapter = createEntityAdapter<JobTableLineItem>()

export type JobLineItemsTableState = {
  lineItems: EntityState<JobTableLineItem>
  columns: string[]
  fieldGroup?: Maybe<FieldGroupNode>
}

export const getInitialJobLineItemsTableState = (): JobLineItemsTableState => ({
  lineItems: jobTableLineItemsAdapter.getInitialState(),
  columns: [],
  fieldGroup: null,
})
