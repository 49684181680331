import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import FileUpload from '@src/components/upload-files/FileUpload'
import {
  getPDFsFromFilesForUpload,
  groupFilesForUpload,
  pollPageAsyncBatch,
  uploadFileData,
} from '@src/utils/file'
import { ImageFileData } from '@src/utils/types'
import theme from '@src/utils/theme'
import { JobNode, JobStatus, Mutation, OriginalPdf, PageAsyncTaskStatus } from '@src/graphql/types'
import { INGEST_AUTOFILL_FILES, UPLOAD_ORIGINAL_PDFS_TO_S3 } from '@src/graphql/mutations/file'
import { useSnackbar } from 'notistack'
import UploadingEta, { UploadPhase } from '@src/components/upload-files/UploadingEta'
import { ShipmentFormContext } from '@src/contexts/shipment_form_context'
import { Auth0AccessTokenContext } from '@src/auth/Auth0AccessTokenContext'
import { JobDataContext } from '@src/contexts/job_data_context'
import { MAX_FILE_PAGES_IN_SINGLE_UPLOAD } from '@src/utils/app_constants'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { LogEventType } from '@src/utils/observability/LogEventType'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

type Props = {
  job: JobNode
  setIsOpen: (isOpen: boolean) => void
  isOpen: boolean
  isUploading?: boolean
  setIsUploading: (status: boolean) => void
}

const useStyles = makeStyles<Theme>({
  title: {
    // default h4 fontWeight too light
    fontWeight: theme.typography.fontWeightBold,
  },
  documentType: {
    width: 200,
  },
  uploadBtn: {
    // just making it extra obvious
    fontSize: theme.typography.h3.fontSize,
  },
})

const Upload: FunctionComponent<Props> = ({
  job,
  setIsOpen,
  isOpen,
  isUploading,
  setIsUploading,
}: Props) => {
  const enableOriginalPdfEdocPushing = useFeatureIsOn('original-pdf-edoc-pushing')
  const { logEvent } = useEventLogger()
  const classes = useStyles()
  const client = useApolloClient()
  const [imageFileData, setImageFileData] = useState([] as ImageFileData[])
  // https://expedock.atlassian.net/browse/PD-5551
  // imageFileData contains the rasterized files and can pass CW 10MB upload limit.
  // So store unrasterized files for upload to S3 and CW later.
  const [originalFileData, setOriginalFileData] = useState<File[]>([])
  const { jobRefetch: refetchFilePages, documentTypes } = useContext(JobDataContext)
  const [documentTypeId, setDocumentTypeId] = useState(undefined as string | undefined)
  const { enqueueSnackbar } = useSnackbar()
  const [progress, setProgress] = useState(0)
  const [valueBuffer, setValueBuffer] = useState(0)
  const [etaSeconds, setEtaSeconds] = useState(null as null | number)
  const [uploadPhase, setUploadPhase] = useState(UploadPhase.UPLOADING)
  const accessToken = useContext(Auth0AccessTokenContext)!
  const actionStart = useRef<Date>()
  const [pollGenerator, setPollGenerator] = useState(
    undefined as undefined | null | AsyncGenerator<[number, number, number | null]>,
  )
  const { saveAndRefetchDocumentEditorJob } = useContext(ShipmentFormContext)
  const [batchId, setBatchId] = useState(null as string | null)
  const [ingestAutofillFiles] = useMutation<Pick<Mutation, 'ingestAutofillFiles'>>(
    INGEST_AUTOFILL_FILES,
    {
      update: (cache) => {
        cache.modify({
          fields: {
            // Invalidate countByJobStatus field and refreshes cache
            countByJobStatus() {},
          },
        })
      },
    },
  )
  // new graphql mutation to upload original files
  const [uploadOriginalFilesToS3] = useMutation<Pick<Mutation, 'uploadOriginalPdfsToS3'>>(
    UPLOAD_ORIGINAL_PDFS_TO_S3,
  )
  useEffect(() => {
    const pendingBatches = job.pageAsyncBatches!.edges.filter(
      (edge) => edge!.node!.status === PageAsyncTaskStatus.Pending,
    )
    if (pendingBatches.length) {
      setIsOpen(true)
      setIsUploading(true)
      setUploadPhase(UploadPhase.PROCESSING)
      setBatchId(pendingBatches[0]!.node!.id)
    }
  }, [job, setIsOpen, setIsUploading])

  useEffect(() => {
    if (uploadPhase === UploadPhase.PROCESSING && pollGenerator === undefined) {
      setProgress(0)
      setValueBuffer(0)
      setEtaSeconds(null)
      setPollGenerator(null)
      let errorMessage = ''
      let success = false
      const executeProcessingPhase = async (): Promise<void> => {
        try {
          // eslint-disable-next-line no-await-in-loop
          const generator = pollPageAsyncBatch(client, batchId!)
          setPollGenerator(generator)
          for await (const [currProgress, currBuffer, eta] of generator) {
            setProgress(currProgress * 100)
            setValueBuffer(currBuffer * 100)
            setEtaSeconds(eta)
          }
          await refetchFilePages()
          enqueueSnackbar('Upload successful.', { variant: 'success' })
          setPollGenerator(undefined)
          success = true
        } catch (error) {
          errorMessage = `Encountered error while uploading: ${formatMaybeApolloError(error)}`
          enqueueSnackbar(errorMessage, {
            variant: 'error',
          })
        } finally {
          setIsOpen(false)
          setIsUploading(false)

          void logEvent(LogEventType.FILE_UPLOAD, {
            job_id: job?.id,
            action_start: actionStart.current,
            action_end: new Date(),
            file_types: imageFileData.map(({ type }) => type),
            success: success,
            error_message: errorMessage,
          })
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      executeProcessingPhase()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadPhase])

  const closeDialog = (): void => {
    setIsOpen(false)
    if (pollGenerator != null) {
      // cancel polling
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      pollGenerator.return(null)
      enqueueSnackbar('Continuing upload in the background... (Refresh to see progress bar again)')
    }
    setImageFileData([])
  }

  useEffect(() => {
    // Set the default document type to the one with the most field groups
    if (documentTypes?.length > 0 && documentTypeId == null) {
      const documentTypeWithMostFieldGroups = documentTypes?.reduce((prev, curr) =>
        prev?.fieldGroups?.edges.length > curr?.fieldGroups?.edges.length ? prev : curr,
      )
      setDocumentTypeId(documentTypeWithMostFieldGroups?.id)
    }
  }, [documentTypes, documentTypeId])

  if (documentTypes == null) {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        classes={{ paper: classes.dialog }}
        onClose={() => closeDialog()}
        open={isOpen}
        fullScreen
      >
        <CircularProgress />
      </Dialog>
    )
  }

  const uploadFiles = async (): Promise<void> => {
    if (imageFileData.length >= MAX_FILE_PAGES_IN_SINGLE_UPLOAD) {
      enqueueSnackbar(
        `You can only upload ${
          MAX_FILE_PAGES_IN_SINGLE_UPLOAD - 1
        } pages at a time. Please deselect some pages and try again`,
        {
          variant: 'error',
        },
      )
      return
    }
    actionStart.current = new Date()
    setIsUploading(true)
    setUploadPhase(UploadPhase.UPLOADING)
    setEtaSeconds(null)
    setProgress(0)
    setValueBuffer(0)
    try {
      // TODO: replace this method with one that retrieves a signed post URL from the backend.
      // Then upload to S3 to that URL from the frontend.
      // Then pass to ingestAutofillFiles to save to our File models.
      // Currently this uploads from the backend, which is inefficient and consumes our server resources.

      // new call to upload original files
      // should upload into xpd-usw-{env}-original-edocs/{date}/{jobId}/{filename}
      let uploadedOriginalFiles: OriginalPdf[] | undefined = undefined
      if (enableOriginalPdfEdocPushing) {
        const { data } = await uploadOriginalFilesToS3({
          variables: {
            jobId: job.id,
            files: await getPDFsFromFilesForUpload(originalFileData),
          },
        })
        uploadedOriginalFiles = data?.uploadOriginalPdfsToS3.files
      }
      const { progress$, viewUrls } = await uploadFileData(imageFileData, accessToken)
      progress$.subscribe((numDone) => setProgress((numDone * 100) / viewUrls.length))
      await progress$.toPromise()
      const docTypeIds = Array(imageFileData.length).fill(documentTypeId)
      const batchId_ = uuidv4()
      setBatchId(batchId_)
      await saveAndRefetchDocumentEditorJob?.()
      await ingestAutofillFiles({
        variables: {
          jobId: job.id,
          files: groupFilesForUpload(imageFileData, viewUrls, docTypeIds, uploadedOriginalFiles),
          batchId: batchId_,
        },
      })
      setUploadPhase(UploadPhase.PROCESSING)
    } catch (error) {
      enqueueSnackbar(`Encountered error while uploading: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
      setIsOpen(false)
      setIsUploading(false)
    }
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialog }}
      onClose={() => closeDialog()}
      open={isOpen}
      fullScreen
    >
      <DialogTitle disableTypography>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h3' className={classes.title}>
            Select Files
          </Typography>
          <IconButton onClick={() => closeDialog()}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        {isUploading ? (
          <UploadingEta
            progress={progress}
            valueBuffer={valueBuffer}
            uploadPhase={uploadPhase}
            etaSeconds={etaSeconds}
          />
        ) : (
          <Box display='flex' flexDirection='column' height='100%' overflow='hidden'>
            {job.status === JobStatus.Todo && (
              <Typography variant='h3' gutterBottom>
                Uploading a file will move this job to In Progress. Please make sure that the
                correct owner is assigned before uploading the file.
              </Typography>
            )}
            <Box flexGrow={1} overflow='hidden'>
              <FileUpload
                imageFileData={imageFileData}
                setImageFileData={setImageFileData}
                originalFileData={originalFileData}
                setOriginalFileData={setOriginalFileData}
                documentTypes={documentTypes}
                documentTypeId={documentTypeId}
                setDocumentTypeId={setDocumentTypeId}
              />
            </Box>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          size='large'
          color='primary'
          className={classes.uploadBtn}
          variant='contained'
          onClick={uploadFiles}
          disabled={isUploading || imageFileData.length === 0 || !documentTypeId}
          /*
           * We add a testid here because MUI puts a span tag underneath
           *  the buttons, making it hard to query the actual button.
           *  i.e., <button><span>Upload</span></button>
           */
          data-testid='upload-btn'
        >
          {isUploading ? 'Uploading...' : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Upload
