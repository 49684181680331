import { FunctionComponent } from 'react'
import { ConfigProvider as AvatarConfigProvider } from 'react-avatar'
import { CssBaseline, makeStyles } from '@material-ui/core'
import { Provider as ReduxProvider } from 'react-redux'
import store from '@src/utils/store'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import theme from '@src/utils/theme'
import { ThemeProvider } from '@material-ui/styles'
import NavBar from '@src/components/NavBar'
import Auth0ProviderWithHistory from '@src/auth/Auth0ProviderWithHistory'
import RequireAuth from '@src/auth/RequireAuth'
import ApolloProviderWithClient from '@src/graphql/ApolloProviderWithClient'
import { SnackbarProvider } from 'notistack'
import { Auth0AccessTokenProvider } from '@src/auth/Auth0AccessTokenContext'
import UserEvents from '@src/utils/observability/UserEvents'
import { HASH_BG_COLOR_PALETTE } from '@src/utils/app_constants'
import { DialogProvider } from 'muibox'
import TasksPage from '@src/pages'
import PageAssocPage from '@src/pages/page_assoc'
import ProfilePage from '@src/pages/profile/index'
import CargowiseAdminPage from '@src/pages/admin/cargowise'
import DataSyncAdminPage from './pages/admin/data/DataSyncAdminPage'
import CompanyListAdminPage from '@src/pages/admin/company'
import CompanyAdminPage from '@src/pages/admin/company/[companyId]/index'
import AdminJobType from './pages/admin/company/[companyId]/job-type/[jobTemplateId]'
import ConfirmationPage from '@src/pages/confirmation/[id]'
import DonePage from '@src/pages/done/[id]'
import FileIngestPage from '@src/pages/file-ingest'
import InProgressPage from '@src/pages/inprogress/[id]'
import JobsPage from '@src/pages/jobs'
import QAPage from '@src/pages/qa/[id]'
import TaskPage from '@src/pages/tasks/[id]'
import TodoPage from '@src/pages/todo/[id]'
import { RoutePathProvider } from '@src/contexts/route_path'
import PageScrollableWrapper from './components/page-scrollable-wrapper/PageScrollableWrapper'
import ImportPage from './pages/admin/company/[companyId]/import/index'
import ImportPageWithId from './pages/admin/company/[companyId]/import/[id]'
import CargowiseOutboundAdminPage from './pages/admin/cargowise/outbound'
import ApiDocsPage from './pages/api_docs'
import ApiPartnerAdminPage from './pages/admin/api-partner'
import UserAdminIndexPage from './pages/admin/user/index'
import ApiIngestionAttachmentPage from './pages/api_ingestion_attachment'
import ReconHistoryPage from '@src/pages/recon-history/[jobId]'
import { UserProfileProvider } from './auth/UserProfileContext'
import { FeatureFlagsProvider } from './feature-flagging/FeatureFlagsProvider'
import ColdStartPage from './pages/admin/cold-start'

const useStyles = makeStyles({
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
})

const App: FunctionComponent = () => {
  const classes = useStyles()
  return (
    <>
      <PageScrollableWrapper>
        <BrowserRouter>
          <CssBaseline />
          <Auth0ProviderWithHistory>
            <Auth0AccessTokenProvider>
              <ThemeProvider theme={theme}>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <ApolloProviderWithClient>
                    <UserProfileProvider>
                      <UserEvents>
                        <AvatarConfigProvider colors={HASH_BG_COLOR_PALETTE}>
                          <DialogProvider>
                            <ReduxProvider store={store}>
                              <RoutePathProvider>
                                <Switch>
                                  <Route exact path={'/docs/api'}>
                                    <ApiDocsPage />
                                  </Route>
                                  <RequireAuth>
                                    <FeatureFlagsProvider>
                                      <NavBar />
                                      <div className={classes.content}>
                                        <Route exact path={'/'}>
                                          <TasksPage />
                                        </Route>
                                        <Route exact path={'/page_assoc'}>
                                          <PageAssocPage />
                                        </Route>
                                        <Route exact path={'/profile'}>
                                          <ProfilePage />
                                        </Route>
                                        <Route
                                          exact
                                          path='/admin'
                                          render={() => <Redirect to='/admin/company' />}
                                        />
                                        <Route exact path={'/admin/cargowise'}>
                                          <CargowiseAdminPage />
                                        </Route>
                                        <Route exact path={'/admin/cargowise/outbounds'}>
                                          <CargowiseOutboundAdminPage />
                                        </Route>
                                        <Route exact path={'/admin/data/sync'}>
                                          <DataSyncAdminPage />
                                        </Route>
                                        <Route exact path={'/admin/data/cold-start'}>
                                          <ColdStartPage />
                                        </Route>
                                        <Route
                                          exact
                                          path='/admin/companies'
                                          render={() => <Redirect to='/admin/company' />}
                                        />
                                        <Route exact path={'/admin/company'}>
                                          <CompanyListAdminPage />
                                        </Route>
                                        <Route exact path={'/admin/company/:companyId'}>
                                          <CompanyAdminPage />
                                        </Route>
                                        <Route exact path={'/admin/company/:companyId/import'}>
                                          <ImportPage initialChargeCodeUploadTaskId={null} />
                                        </Route>
                                        <Route exact path={'/admin/company/:companyId/import/:id'}>
                                          <ImportPageWithId />
                                        </Route>
                                        <Route
                                          exact
                                          path={'/admin/company/:companyId/job-type/:jobTemplateId'}
                                        >
                                          <AdminJobType />
                                        </Route>
                                        <Route exact path={'/admin/user'}>
                                          <UserAdminIndexPage />
                                        </Route>
                                        <Route
                                          exact
                                          path='/admin/users'
                                          render={() => <Redirect to='/admin/user' />}
                                        />
                                        <Route exact path={'/admin/api-partner'}>
                                          <ApiPartnerAdminPage />
                                        </Route>
                                        <Route exact path={'/confirmation/:id'}>
                                          <ConfirmationPage />
                                        </Route>
                                        <Route exact path={'/done/:id'}>
                                          <DonePage />
                                        </Route>
                                        <Route exact path={'/file-ingest'}>
                                          <FileIngestPage />
                                        </Route>
                                        <Route exact path={'/inprogress/:id'}>
                                          <InProgressPage />
                                        </Route>
                                        <Route exact path={'/jobs'}>
                                          <JobsPage />
                                        </Route>
                                        <Route exact path={'/qa/:id'}>
                                          <QAPage />
                                        </Route>
                                        <Route exact path={'/tasks'}>
                                          <Redirect to={'/'} />
                                        </Route>
                                        <Route exact path={'/tasks/:id'}>
                                          <TaskPage />
                                        </Route>
                                        <Route exact path={'/todo/:id'}>
                                          <TodoPage />
                                        </Route>
                                        <Route exact path={'/ingestion_attachment/:id'}>
                                          <ApiIngestionAttachmentPage />
                                        </Route>
                                        <Route exact path={'/recon-history/:jobId'}>
                                          <ReconHistoryPage />
                                        </Route>
                                      </div>
                                    </FeatureFlagsProvider>
                                  </RequireAuth>
                                </Switch>
                              </RoutePathProvider>
                            </ReduxProvider>
                          </DialogProvider>
                        </AvatarConfigProvider>
                      </UserEvents>
                    </UserProfileProvider>
                  </ApolloProviderWithClient>
                </SnackbarProvider>
              </ThemeProvider>
            </Auth0AccessTokenProvider>
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </PageScrollableWrapper>
    </>
  )
}

export default App
