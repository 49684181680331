import { DocumentNode } from '@src/graphql/types'
import { DocumentEditorPage, DocumentEditorState } from './document_editor_state'
import { selectActiveFilePage } from './file_page'

export const selectActiveDocument = (state: DocumentEditorState): DocumentNode | null =>
  selectActiveFilePage(state)?.document || null

export const selectActiveDocumentEditorPage = (
  state: DocumentEditorState,
): DocumentEditorPage | null =>
  ((state.activeFilePageId &&
    state.pageFieldEditorState?.pages?.[state.activeFilePageId]) as DocumentEditorPage) || null
