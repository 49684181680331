import { FunctionComponent } from 'react'
import { UserNode } from '@src/graphql/types'
import Avatar from 'react-avatar'
import theme from '@src/utils/theme'
import { makeStyles } from '@material-ui/styles'

const AVATAR_SIZE = 24

const useStyles = makeStyles({
  userRow: {
    display: 'flex',
    alignItems: 'center',
    '& + &': {
      marginTop: theme.spacing(1),
    },
  },
  avatar: {
    marginRight: theme.spacing(1),
    display: 'block',
    '& > div': {
      fontSize: `${theme.typography.fontSize}px !important`,
    },
  },
  userName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    flex: '1',
  },
})

type Props = {
  user: UserNode
}

const TaskCardUser: FunctionComponent<Props> = ({ user: { id, name, email } }) => {
  const classes = useStyles()

  return (
    <div key={id} className={classes.userRow}>
      <Avatar name={name || email} round size={`${AVATAR_SIZE}`} className={classes.avatar} />
      <div className={classes.userName}>{name || email}</div>
    </div>
  )
}

export default TaskCardUser
