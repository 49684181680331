import { gql } from '@apollo/client'

export const GET_ALL_API_PARTNERS = gql`
  query apiPartners {
    apiPartners {
      id
      apiKey
      exportEndpoint
      importEndpoint
      documentExportEndpoint
      documentImportEndpoint
      name
      countryCode
      expedockDocIngestEmail
      apiPartnerType
      authValues
      tmsType {
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
        ... on ApiPartnerTmsTypeEnum {
          value
          isFallback
        }
      }
      credential {
        id
        username
        workflow
      }
      company {
        id
        name
      }
      jobTemplates {
        edges {
          node {
            id
            name
            company {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const GET_API_JOB_EDOCS = gql`
  query getApiEDocumentsFromJob($jobId: String!) {
    apiEDocuments(jobId: $jobId) {
      id
      s3Uri
      eDocUrl
      filename
      apiExportEdocActivity {
        id
      }
      apiImportEdocActivity {
        id
        apiEntityType
      }
    }
  }
`

export const GET_API_INGESTION_ATTACHMENT_SIGNED_URL = gql`
  query apiIngestionAttachmentSignedUrl($apiIngestionAttachmentId: UUID!) {
    apiIngestionAttachmentSignedUrl(apiIngestionAttachmentId: $apiIngestionAttachmentId)
  }
`
