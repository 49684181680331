import { formatMaybeApolloError } from '@src/utils/errors'
import { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Controller, ControllerProps, FieldValues, useWatch } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import { JobTemplateReconType, Query } from '@src/graphql/types'
import { GET_AUTOFILL_KEYS } from '@src/graphql/queries/field'
import { useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { DocumentTypeOption } from '@src/utils/admin/document_type_option'
import ControllerErrorText from '@src/components/controller-error-text/ControllerErrorText'

type Props = {
  autofillKey: string | undefined | null
  fieldGroupIndex: number
  fieldIndex?: number
  isLineItem: boolean
  isLineItemField: boolean
}

const AutofillKeySelector: FunctionComponent<Props> = ({
  autofillKey,
  fieldGroupIndex,
  fieldIndex,
  isLineItem,
  isLineItemField,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [inputValue, setInputValue] = useState('')
  const namePrefix = isLineItem ? 'lineItemTypes' : 'metadataFieldGroups'
  const documentTypeId = useWatch({
    name: `${namePrefix}.${fieldGroupIndex}.documentTypeId`,
  }) as string
  const documentTypes = useWatch({
    name: 'documentTypes',
  }) as DocumentTypeOption[]
  const reconType = useWatch({
    name: 'reconType',
  }) as JobTemplateReconType
  const autofillExtractorKey = useMemo(() => {
    return (
      (documentTypeId &&
        documentTypes.find(({ id }) => id === documentTypeId)?.autofillExtractorKey) ||
      null
    )
  }, [documentTypeId, documentTypes])
  const { data: autofillKeysData, error: autofillKeysError } = useQuery<
    Pick<Query, 'autofillKeys'>
  >(GET_AUTOFILL_KEYS, {
    variables: {
      autofillExtractorKey,
      reconType,
      documentTypeId,
    },
    skip: !documentTypeId || !documentTypes || !reconType,
  })

  useEffect(() => {
    if (autofillKeysError) {
      if (autofillKeysError.message.includes('Document type not found')) {
        enqueueSnackbar(`Please save the job template before filling up the autofill key fields.`, {
          variant: 'warning',
          preventDuplicate: true,
          autoHideDuration: 5000,
        })
      } else {
        enqueueSnackbar(
          `Received error while loading autofill keys: ${formatMaybeApolloError(
            autofillKeysError,
          )}.`,
          {
            variant: 'error',
            preventDuplicate: true,
          },
        )
      }
    }
  }, [autofillKeysError, enqueueSnackbar])

  const autofillKeyOptions = useMemo(() => {
    return (
      (!isLineItem || isLineItemField
        ? autofillKeysData?.autofillKeys.autofillKeys
        : autofillKeysData?.autofillKeys.lineItemFieldGroupAutofillKeys) || []
    ).concat([''])
  }, [autofillKeysData, isLineItem, isLineItemField])

  const filteredOptions = useMemo(() => {
    if (!inputValue) return autofillKeyOptions
    return autofillKeyOptions.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase()),
    )
  }, [inputValue, autofillKeyOptions])

  const render: ControllerProps<
    FieldValues,
    | `metadataFieldGroups.${number}.autofillKey`
    | `metadataFieldGroups.${number}.fields.${number}.autofillKey`
    | `lineItemTypes.${number}.autofillKey`
    | `lineItemTypes.${number}.fields.${number}.autofillKey`
  >['render'] = useMemo(() => {
    return ({ field: { value, onBlur, onChange }, fieldState: { error } }) => {
      const onInputChange = (
        _event: ChangeEvent<Record<string, string>>,
        val: string,
        _reason: string,
      ): void => {
        setInputValue(val)
      }

      const onValueChange = (
        _event: ChangeEvent<Record<string, string>>,
        newValue: string | null,
      ): void => {
        setInputValue(newValue || '')
        onChange(newValue || '')
      }
      return (
        <>
          <Autocomplete
            freeSolo={true}
            disabled={!documentTypeId}
            value={value}
            options={filteredOptions}
            onInputChange={onInputChange}
            onChange={onValueChange}
            onBlur={onBlur}
            size='small'
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                placeholder='Select autofill key from drop down. Only enter text if key is not found.'
              />
            )}
          />
          <ControllerErrorText error={error} friendlyName='Autofill key' />
        </>
      )
    }
  }, [filteredOptions, documentTypeId])

  return (
    <Controller
      render={render}
      name={
        fieldIndex != null
          ? `${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.autofillKey`
          : `${namePrefix}.${fieldGroupIndex}.autofillKey`
      }
      defaultValue={autofillKey || undefined}
      rules={{
        required: true,
      }}
    />
  )
}

export default AutofillKeySelector
