import { FunctionComponent, MouseEvent } from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import Box from '@material-ui/core/Box'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'

import { MAGIC_GRID_DIMENSIONS } from '@src/utils/app_constants'
import theme from '@src/utils/theme'
import { deleteGridColumn, moveGridColumn } from '@src/redux-features/document_editor'
import {
  columnSelectors,
  selectActiveMagicGrid,
} from '@src/redux-features/document_editor/magic_grid'
import { selectRepeatableFieldNameByKey } from '@src/redux-features/document_editor/field'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@src/utils/store'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles({
  columnRulerWrapper: {
    position: 'absolute',
    height: '100%',
    zIndex: MAGIC_GRID_DIMENSIONS.RULER_Z_INDEX,
  },
  columnTagWrapper: {
    position: 'absolute',
    top: -theme.spacing(7),
  },
  columnTag: {
    position: 'absolute',
    padding: `0 ${theme.spacing(0.5)}px`,
    backgroundColor: theme.palette.grey[500],
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
    transform: 'rotate(-90deg)',
    transformOrigin: 'left top',
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#020617',
    },
  },
  columnTagSelector: {
    backgroundColor: lighten(theme.palette.primary.light, 0.5),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  columnRuler: {
    cursor: 'col-resize',
    width: `${MAGIC_GRID_DIMENSIONS.RULER_THICKNESS}px`,
    height: '100%',
    borderLeftWidth: 'thin',
    border: `0 dashed ${theme.palette.primary.dark}`,
    // this is mainly used for easier dragging
    '&::before': {
      content: '""',
      position: 'absolute',
      left: -theme.spacing(0.5),
      top: 0,
      width: theme.spacing(1),
      height: '100%',
    },
    '&:hover': {
      border: `${MAGIC_GRID_DIMENSIONS.RULER_THICKNESS}px solid ${theme.palette.primary.dark}`,
    },
  },
})

type Props = {
  columnId: string
  setAnchorEl: (element: HTMLButtonElement | null) => void
  imageWidth: number
  handleDropdownToggle: (id: string) => void
}

const ColumnRuler: FunctionComponent<Props> = ({
  columnId,
  setAnchorEl,
  imageWidth,
  handleDropdownToggle,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const columnKey = useSelector(
    (state: RootState) => columnSelectors.selectById(state.documentEditor, columnId)?.key,
  ) as string
  const columnLeft = useSelector(
    (state: RootState) =>
      columnSelectors.selectById(state.documentEditor, columnId)?.dimension.left,
  ) as number
  const gridLeft = useSelector(
    (state: RootState) => selectActiveMagicGrid(state.documentEditor)!.dimension!.left,
  )
  const x = (columnLeft - gridLeft) * imageWidth

  const columnLabel = useSelector(
    (state: RootState) =>
      columnKey && selectRepeatableFieldNameByKey(state.documentEditor, columnKey),
  )

  const toggleColumnDropdown = (evt: MouseEvent<HTMLButtonElement>): void => {
    handleDropdownToggle(columnId)
    setAnchorEl(evt.currentTarget)
  }

  // compute x delta (in pixels)
  const handleColumnMove = (_evt: DraggableEvent, ui: DraggableData): void => {
    dispatch(moveGridColumn(columnId, ui.x / imageWidth + gridLeft))
  }

  return (
    <Draggable axis='x' bounds='parent' position={{ x, y: 0 }} onStop={handleColumnMove}>
      <Box className={classes.columnRulerWrapper}>
        <Box className={classes.columnTagWrapper}>
          {columnKey && (
            <Box className={classes.columnTag}>
              <Typography>{columnLabel}</Typography>
            </Box>
          )}

          <ButtonBase className={classes.columnTagSelector} onClick={toggleColumnDropdown}>
            <EditIcon fontSize='inherit' />
          </ButtonBase>
          <ButtonBase
            className={classes.columnTagSelector}
            onClick={() => dispatch(deleteGridColumn(columnId))}
          >
            <CancelIcon fontSize='inherit' />
          </ButtonBase>
        </Box>

        <div className={classes.columnRuler} />
      </Box>
    </Draggable>
  )
}

export default ColumnRuler
