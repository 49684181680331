import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'

import Button from '@material-ui/core/Button'
import CreateIcon from '@material-ui/icons/Create'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {
  Box,
  ClickAwayListener,
  Grow,
  makeStyles,
  Paper,
  Popper,
  TextField,
} from '@material-ui/core'
import theme from '@src/utils/theme'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from '@apollo/client'
import {
  JobStatus,
  ManualProduct,
  Maybe,
  Mutation,
  MutationBulkUpdateJobDetailsArgs,
  Query,
  QueryUsersByRoleArgs,
  TaskNode,
  UserNode,
  UserRole,
} from '@src/graphql/types'
import { USERS_BY_ROLE } from '@src/graphql/queries/user'
import { BULK_UPDATE_JOB_DETAILS } from '@src/graphql/mutations/job'
import { JOB_STATUS, POPPER_Z_INDEX } from '@src/utils/app_constants'
import { Autocomplete } from '@material-ui/lab'
import TaskDateConfirmedDialog from './task-date-confirmed-dialog/TaskDateConfirmedDialog'

type Props = {
  task: TaskNode | undefined
  jobIds: string[]
  statusItems: JobStatus[]
  refetchJobs: () => unknown
}

const useStyles = makeStyles({
  autoComplete: {
    minWidth: '300px',
  },
  popper: {
    zIndex: POPPER_Z_INDEX,
  },
  editButton: {
    marginRight: theme.spacing(2),
  },
  cancel: {
    color: theme.palette.grey[500],
  },
  update: {
    color: theme.palette.primary.main,
  },
})

const EditButtonGroup: FunctionComponent<Props> = ({ jobIds, statusItems, task, refetchJobs }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [openButton, setOpenButton] = useState(false)
  const anchorRef = useRef(null)
  const { data } = useQuery<Pick<Query, 'usersByRole'>, QueryUsersByRoleArgs>(USERS_BY_ROLE, {
    variables: { role: UserRole.Operator },
  })
  const [bulkUpdateJobDetails, { loading: bulkUpdateJobDetailsLoading }] = useMutation<
    Pick<Mutation, 'bulkUpdateJobDetails'>,
    MutationBulkUpdateJobDetailsArgs
  >(BULK_UPDATE_JOB_DETAILS)
  const [owner, setOwner] = useState(null as null | UserNode)
  const [qa, setQa] = useState(null as null | UserNode)
  const [manualProduct, setManualProduct] = useState(null as null | string)
  const [jobStatus, setJobStatus] = useState(null as null | JobStatus)
  const [isDateConfirmedDialogOpen, setIsDateConfirmedDialogOpen] = useState(false)

  const operators = useMemo(() => data?.usersByRole || [], [data])

  const updateJobs = async (): Promise<void> => {
    await bulkUpdateJobDetails({
      variables: {
        jobIds,
        ownerId: owner?.id,
        qaId: qa?.id,
        manualProduct: manualProduct?.toLowerCase(),
        status: jobStatus ? JOB_STATUS[jobStatus] : null,
      },
    })
    setOpenButton(false)
    refetchJobs()
  }

  const handleUpdateClick = async (): Promise<void> => {
    if (
      jobStatus &&
      [JobStatus.Done, JobStatus.Confirmation].includes(jobStatus) &&
      task!.blocked
    ) {
      enqueueSnackbar(
        `Task ${
          task!.title
        } is currently blocked, please unblock it before moving this job to ${jobStatus}`,
        {
          variant: 'error',
        },
      )
      return
    }

    if (jobStatus === JobStatus.Done && task!.dateConfirmed === null) {
      setIsDateConfirmedDialogOpen(true)
      return
    }
    await updateJobs()
  }

  useEffect(() => {
    if (!openButton) {
      setOwner(null)
      setQa(null)
      setManualProduct(null)
    }
  }, [openButton])

  return (
    <div className={classes.editButton}>
      <Button
        variant='contained'
        color='primary'
        ref={anchorRef}
        disabled={bulkUpdateJobDetailsLoading}
        onClick={() => setOpenButton(true)}
        startIcon={<CreateIcon />}
        endIcon={<ArrowDropDownIcon />}
      >
        Edit
      </Button>
      <Popper
        open={openButton}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener mouseEvent='onMouseDown' onClickAway={() => setOpenButton(false)}>
                <Box display='flex' flexDirection='column' p={1}>
                  <Box>
                    <Autocomplete
                      value={owner}
                      onChange={(_evt, newValue) => {
                        setOwner((newValue as UserNode) ?? null)
                        refetchJobs()
                      }}
                      options={operators}
                      renderOption={(userNode: Maybe<UserNode>) => userNode?.email}
                      getOptionLabel={(value) =>
                        typeof value === 'string' ? value : value?.email || ''
                      }
                      fullWidth
                      freeSolo
                      clearOnBlur
                      className={classes.autoComplete}
                      renderInput={(params) => (
                        <TextField {...params} margin='normal' label='Owner' variant='outlined' />
                      )}
                    />
                  </Box>
                  <Box>
                    <Autocomplete
                      value={qa}
                      onChange={(_evt, newValue) => {
                        setQa((newValue as UserNode) ?? null)
                        refetchJobs()
                      }}
                      options={operators}
                      renderOption={(userNode: Maybe<UserNode>) => userNode?.email}
                      getOptionLabel={(value) =>
                        typeof value === 'string' ? value : value?.email || ''
                      }
                      fullWidth
                      freeSolo
                      clearOnBlur
                      className={classes.autoComplete}
                      renderInput={(params) => (
                        <TextField {...params} margin='normal' label='QA' variant='outlined' />
                      )}
                    />
                  </Box>
                  <Box>
                    <Autocomplete
                      value={jobStatus}
                      onChange={(_evt, newValue) => {
                        setJobStatus(newValue as JobStatus | null)
                      }}
                      options={statusItems}
                      fullWidth
                      freeSolo
                      clearOnBlur
                      className={classes.autoComplete}
                      renderInput={(params) => (
                        <TextField {...params} margin='normal' label='Status' variant='outlined' />
                      )}
                    />
                  </Box>
                  <Box>
                    <Autocomplete
                      value={manualProduct}
                      onChange={(_evt, newValue) => {
                        setManualProduct(newValue ?? null)
                        refetchJobs()
                      }}
                      options={Object.values(ManualProduct)}
                      renderOption={(manualProductOption: Maybe<string>) =>
                        manualProductOption ? manualProductOption[0] : ''
                      }
                      getOptionLabel={(value) => value[0]}
                      fullWidth
                      freeSolo
                      clearOnBlur
                      className={classes.autoComplete}
                      renderInput={(params) => (
                        <TextField {...params} margin='normal' label='P/M' variant='outlined' />
                      )}
                    />
                  </Box>
                  <Box display='flex' justifyContent='flex-end'>
                    <Button className={classes.cancel} onClick={() => setOpenButton(false)}>
                      Cancel
                    </Button>
                    <Button
                      className={classes.update}
                      disabled={bulkUpdateJobDetailsLoading}
                      onClick={handleUpdateClick}
                    >
                      Update
                    </Button>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {task && (
        <TaskDateConfirmedDialog
          open={isDateConfirmedDialogOpen}
          onClose={() => setIsDateConfirmedDialogOpen(false)}
          task={task}
          confirm={updateJobs}
        />
      )}
    </div>
  )
}

export default EditButtonGroup
