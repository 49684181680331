import { Box, Tabs, Tab } from '@material-ui/core'
import {
  EXPECTED_DATA_TABLE_COLUMNS,
  HotTableData,
  CHECK_SHIPMENT_METADATA_DATA_TABLE_COLUMNS,
  ADDITIONAL_REFERENCES_DATA_TABLE_COLUMNS,
  ADDRESSES_DATA_TABLE_COLUMNS,
  PACKING_LINES_DATA_TABLE_COLUMNS,
  ORDER_NUMBER_COLLECTION_DATA_TABLE_COLUMNS,
  SHIPMENT_LEGS_DATA_TABLE_COLUMNS,
} from '@src/utils/recon/ap_recon'
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react'
import { CheckShipmentMetadataTable } from '@src/components/CheckShipmentMetadataTable'
import { FindShipmentReconResultNode, Maybe } from '@src/graphql/types'
import CheckShipmentInfoTable from './CheckShipmentInfoTable'
import {
  getAdditionalReferencesData,
  getCustomFieldsData,
  getOrderNumbersData,
  getPackingLinesData,
  getShipmentAddressesData,
  getShipmentLegsData,
  MINIMUM_LOADING_TIME,
  TableDisplayData,
} from './util'
import CenteredCircularProgress from '../centered-circular-progress/CenteredCircularProgress'

export enum CheckShipmentInfoTabs {
  AssociatedKeys = 'Associated Keys',
  Metadata = 'Metadata',
  Billing = 'Billing',
  ShipmentRouting = 'Shipment Routing',
  Packing = 'Packing',
  OrderNumberCollection = 'Order Number Collection',
  AdditionalReferences = 'Additional References',
  CustomFields = 'Custom Fields',
  Addresses = 'Addresses',
}

type Props = {
  expectedDataMatrix: TableDisplayData
  expectedMetadataTable: Maybe<TableDisplayData>
  apAssociatedKeysTable: HotTableData
  findShipmentReconResults: Maybe<FindShipmentReconResultNode[]>
}

const ForwardingShipmentDialog: FunctionComponent<Props> = ({
  expectedDataMatrix,
  expectedMetadataTable,
  apAssociatedKeysTable,
  findShipmentReconResults,
}) => {
  const [tab, setTab] = useState<CheckShipmentInfoTabs>(CheckShipmentInfoTabs.AssociatedKeys)
  const [shipmentLegs, setShipmentLegs] = useState<TableDisplayData>([[]])
  const [packingLines, setPackingLines] = useState<TableDisplayData>([[]])
  const [orderNumbers, setOrderNumbers] = useState<TableDisplayData>([[]])
  const [customFields, setCustomFields] = useState<TableDisplayData>([[]])
  const [additionalReferences, setAdditionalReferences] = useState<TableDisplayData>([[]])
  const [addresses, setAddresses] = useState<TableDisplayData>([[]])
  const [hasMinimumLoadingTimeElapsed, setHasMinimumLoadingTimeElapsed] = useState<boolean>(false)

  const handleChange = (_event: ChangeEvent<unknown>, newValue: CheckShipmentInfoTabs): void => {
    setTab(newValue)
  }

  useEffect(() => {
    findShipmentReconResults?.forEach((reconResult) => {
      const shipment = reconResult.chainIoShipment
      if (shipment) {
        setHasMinimumLoadingTimeElapsed(false)
        setShipmentLegs([...shipmentLegs].concat(getShipmentLegsData(shipment.shipmentLegs.edges)))
        setPackingLines([...packingLines].concat(getPackingLinesData(shipment.packingLines.edges)))
        setOrderNumbers([...orderNumbers].concat(getOrderNumbersData(shipment.orders.edges)))
        setCustomFields([...customFields].concat(getCustomFieldsData(shipment.customFields.edges)))
        setAdditionalReferences(
          [...additionalReferences].concat(
            getAdditionalReferencesData(shipment.additionalReferences.edges),
          ),
        )
        setAddresses([...addresses].concat(getShipmentAddressesData(shipment)))
        setTimeout(() => {
          setHasMinimumLoadingTimeElapsed(true)
        }, MINIMUM_LOADING_TIME)
      } else {
        setHasMinimumLoadingTimeElapsed(true)
      }
    })
    // adding the data arrays in the dep cause infinite loop in useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    findShipmentReconResults,
    setShipmentLegs,
    setPackingLines,
    setOrderNumbers,
    setCustomFields,
    setAdditionalReferences,
    setAddresses,
    setHasMinimumLoadingTimeElapsed,
  ])

  if (
    !hasMinimumLoadingTimeElapsed ||
    (!shipmentLegs &&
      !packingLines &&
      !orderNumbers &&
      !customFields &&
      !additionalReferences &&
      !addresses)
  ) {
    return <CenteredCircularProgress />
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant='scrollable' value={tab} onChange={handleChange}>
          {Object.values(CheckShipmentInfoTabs).map((tab) => {
            return <Tab label={tab} key={tab} value={tab} data-testid={`info-${tab}-btn`} />
          })}
        </Tabs>
      </Box>
      {tab == CheckShipmentInfoTabs.AssociatedKeys && (
        <CheckShipmentMetadataTable metadataTableData={apAssociatedKeysTable.data} />
      )}
      {tab == CheckShipmentInfoTabs.Metadata && (
        <CheckShipmentMetadataTable metadataTableData={expectedMetadataTable} />
      )}
      {tab == CheckShipmentInfoTabs.Billing && (
        <CheckShipmentInfoTable
          dataTableColumns={EXPECTED_DATA_TABLE_COLUMNS}
          expectedDataMatrix={expectedDataMatrix}
        />
      )}
      {tab == CheckShipmentInfoTabs.ShipmentRouting && (
        <CheckShipmentInfoTable
          dataTableColumns={SHIPMENT_LEGS_DATA_TABLE_COLUMNS}
          expectedDataMatrix={shipmentLegs}
        />
      )}
      {tab == CheckShipmentInfoTabs.Packing && (
        <CheckShipmentInfoTable
          dataTableColumns={PACKING_LINES_DATA_TABLE_COLUMNS}
          expectedDataMatrix={packingLines}
        />
      )}
      {tab == CheckShipmentInfoTabs.OrderNumberCollection && (
        <CheckShipmentInfoTable
          dataTableColumns={ORDER_NUMBER_COLLECTION_DATA_TABLE_COLUMNS}
          expectedDataMatrix={orderNumbers}
        />
      )}
      {tab == CheckShipmentInfoTabs.AdditionalReferences && (
        <CheckShipmentInfoTable
          dataTableColumns={ADDITIONAL_REFERENCES_DATA_TABLE_COLUMNS}
          expectedDataMatrix={additionalReferences}
        />
      )}
      {tab == CheckShipmentInfoTabs.CustomFields && (
        <CheckShipmentInfoTable
          dataTableColumns={CHECK_SHIPMENT_METADATA_DATA_TABLE_COLUMNS}
          expectedDataMatrix={customFields}
        />
      )}
      {tab == CheckShipmentInfoTabs.Addresses && (
        <CheckShipmentInfoTable
          dataTableColumns={ADDRESSES_DATA_TABLE_COLUMNS}
          expectedDataMatrix={addresses}
        />
      )}
    </>
  )
}

export default ForwardingShipmentDialog
