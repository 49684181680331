import { Checkbox, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { FunctionComponent, useEffect } from 'react'
import { ApiPartnerInterface, CompanyNode } from '@src/graphql/types'
import { toTitleCase } from '@src/utils/string'
import { isFallback } from '@src/utils/enum'

type Props = {
  enabledApiPartners: ApiPartnerInterface[]
  selectedApiPartners: ApiPartnerInterface[] | null
  setSelectedApiPartners: (apiPartners: ApiPartnerInterface[] | null) => void
  selectedCompany: CompanyNode | null
  singleApiPartner?: boolean
}

const CompanyApiPartnersSelect: FunctionComponent<Props> = ({
  enabledApiPartners,
  selectedApiPartners,
  setSelectedApiPartners,
  selectedCompany,
  singleApiPartner,
}) => {
  useEffect(() => {
    setSelectedApiPartners(singleApiPartner ? null : enabledApiPartners)
  }, [enabledApiPartners, setSelectedApiPartners, singleApiPartner])

  return (
    <Autocomplete
      multiple={!singleApiPartner}
      disabled={!selectedCompany}
      options={enabledApiPartners}
      groupBy={(option: ApiPartnerInterface) =>
        isFallback(option.apiPartnerCode)
          ? ''
          : toTitleCase(option.apiPartnerCode.value.split('_').join(' '))
      }
      value={singleApiPartner ? selectedApiPartners?.[0] : selectedApiPartners ?? []}
      onChange={(e, v) => {
        setSelectedApiPartners(
          singleApiPartner ? [v as ApiPartnerInterface] : (v as ApiPartnerInterface[]),
        )
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(option: ApiPartnerInterface) =>
        singleApiPartner
          ? [<Typography key={option.id}>{option.name}</Typography>]
          : [
              <Checkbox
                key={`check-${option.id}`}
                checked={
                  !!(
                    selectedApiPartners &&
                    selectedApiPartners?.findIndex((item) => item.id === option.id) >= 0
                  )
                }
              />,
              <Typography key={`label-${option.id}`}>{option.name}</Typography>,
            ]
      }
      renderInput={(params) => <TextField {...params} variant='outlined' label='API Partners' />}
    />
  )
}

export default CompanyApiPartnersSelect
