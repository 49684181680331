import { FunctionComponent } from 'react'
import { Box, Paper, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import theme from '@src/utils/theme'
import NewTaxButton from './NewTaxButton'

type Props = {
  newTax: () => void
  disabled: boolean
}

const CONFIG_FILTER_INPUT_WIDTH = '300px'

const useStyles = makeStyles<Theme>({
  taxTextHeader: {
    color: theme.palette.grey['500'],
    fontSize: `${theme.spacing(4)}px`,
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  cwConfigDropdown: {
    margin: theme.spacing(2),
  },
  apiPartnerDropdown: {
    margin: theme.spacing(2),
  },
  paper: {
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey['500']}`,
  },
  configFilter: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    width: CONFIG_FILTER_INPUT_WIDTH,
  },
})

const TaxMappingHeader: FunctionComponent<Props> = ({ newTax, disabled }) => {
  const classes = useStyles()

  return (
    <Paper square className={classes.paper}>
      <Box display='flex' justifyContent='space-between'>
        <Typography className={classes.taxTextHeader}>Taxes:</Typography>
        <Box pr={1}>
          <NewTaxButton newTax={newTax} disabled={disabled} />
        </Box>
      </Box>
    </Paper>
  )
}

export default TaxMappingHeader
