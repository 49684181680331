import { createTheme } from '@material-ui/core/styles'
import { COLORS } from '@src/utils/app_constants'

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.SEA,
      contrastText: COLORS.PURE_WHITE,
    },
    secondary: {
      main: COLORS.ASTRONAUT,
    },
    info: {
      main: COLORS.PALE_YELLOW,
    },
    warning: {
      main: '#FF8F00',
    },
  },
  typography: {
    fontSize: 14,
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.7rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.45rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.15rem',
      fontWeight: 500,
    },
  },
  overrides: {
    MuiFab: {
      extended: {
        borderRadius: '4px',
      },
    },
  },
})

export default theme
