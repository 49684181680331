import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { clsx } from 'clsx'

import { setActiveLineItemIds, setActiveFieldBoxId } from '@src/redux-features/document_editor'
import { BoxDimension } from '@src/types/ocr'
import { FIELD_BOX_STYLES } from '@src/utils/app_constants'
import { JobTableLineItem, LineItem } from '@src/utils/line_items'
import { RootState } from '@src/utils/store'
import theme from '@src/utils/theme'

export type FieldBoxType = {
  id: string
  label: string
  dimension: BoxDimension
}

type StyleProps = {
  dimension: BoxDimension
  imageWidth: number
  imageHeight: number
}

const useStyles = makeStyles<Theme, StyleProps>({
  fieldBox: {
    position: 'absolute',
    left: (props) => props.dimension.left * props.imageWidth,
    top: (props) => props.dimension.top * props.imageHeight,
    width: (props) => props.dimension.width * props.imageWidth,
    height: (props) => props.dimension.height * props.imageHeight,
    zIndex: FIELD_BOX_STYLES.Z_INDEX,
  },
  activeFieldBox: {
    backgroundColor: alpha(FIELD_BOX_STYLES.BOX_COLOR, 0.3),
    border: `1px solid ${FIELD_BOX_STYLES.BORDER_COLOR}`,
  },
  inactiveFieldBox: {
    backgroundColor: alpha(FIELD_BOX_STYLES.INACTIVE_BOX_COLOR, 0.2),
    border: `1px dotted ${FIELD_BOX_STYLES.BORDER_COLOR}`,
  },
  hoveredFieldBox: {
    backgroundColor: alpha(FIELD_BOX_STYLES.BOX_COLOR, 0.3),
    border: `1px solid ${FIELD_BOX_STYLES.BORDER_COLOR}`,
  },
  fieldBoxLabel: {
    position: 'absolute',
    backgroundColor: alpha(FIELD_BOX_STYLES.LABEL_COLOR, 0.5),
    color: theme.palette.common.white,
    cursor: 'pointer',
    padding: `0 ${theme.spacing(0.5)}px`,
    transform: 'translate(0%, -100%)',
    fontSize: '0.625rem',
  },
  hoveredFieldBoxLabel: {
    backgroundColor: FIELD_BOX_STYLES.LABEL_COLOR,
  },
})

type Props = {
  lineItem: LineItem | JobTableLineItem
  fieldBox: FieldBoxType
  imageBoxRef: MutableRefObject<HTMLImageElement | null>
  setColumnAnchorEl: (anchorEl: HTMLDivElement | null) => void
}

const FieldBox: FunctionComponent<Props> = ({
  lineItem,
  fieldBox,
  imageBoxRef,
  setColumnAnchorEl,
}) => {
  const fieldBoxRef = useRef(null as HTMLDivElement | null)
  const [hovered, setHovered] = useState(false)
  const dispatch = useDispatch()

  const imageWidth = imageBoxRef.current?.width || 0
  const imageHeight = imageBoxRef.current?.height || 0
  const classes = useStyles({ dimension: fieldBox.dimension, imageWidth, imageHeight })

  const gridRowTransform = useSelector(
    (state: RootState) => state.documentEditor.boxn.gridRowTransform,
  )
  const activeFieldBoxId = useSelector(
    (state: RootState) => state.documentEditor.boxn.activeFieldBoxId,
  )
  const activeLineItemIds = useSelector(
    (state: RootState) => state.documentEditor.activeLineItemIds,
  )
  const highlightedFieldBoxIds = useSelector(
    (state: RootState) => state.documentEditor.boxn.highlightedFieldBoxIds,
  )
  const isJobTableActive = useSelector((state: RootState) => state.documentEditor.jobTableActive)
  const isFieldBoxActive = fieldBox.id === activeFieldBoxId
  const isRowActive = useMemo(() => {
    return activeLineItemIds.includes(lineItem.id)
  }, [activeLineItemIds, lineItem.id])
  const isFieldBoxHighlighted = useMemo(() => {
    return highlightedFieldBoxIds.includes(fieldBox.id)
  }, [highlightedFieldBoxIds, fieldBox.id])
  const fieldBoxElement = fieldBoxRef.current
  const isFieldLabelVisible = (isRowActive && !activeFieldBoxId) || isFieldBoxActive

  const openColumnSelectionDropdown = useCallback((): void => {
    if (isJobTableActive) {
      return
    }
    setColumnAnchorEl(fieldBoxRef.current)
    dispatch(setActiveFieldBoxId(fieldBox.id))
  }, [dispatch, fieldBox.id, fieldBoxRef, isJobTableActive, setColumnAnchorEl])

  const handleOnMouseEnter = (): void => {
    setHovered(true)
  }

  const handleOnMouseLeave = (): void => {
    setHovered(false)
  }

  const handleClick = useCallback(() => {
    batch(() => {
      dispatch(setActiveLineItemIds([lineItem.id]))
      dispatch(setActiveFieldBoxId(fieldBox.id))
    })
  }, [dispatch, fieldBox, lineItem])

  const newFieldBoxId = useSelector((state: RootState) => state.documentEditor.boxn.newFieldBoxId)
  useEffect(() => {
    if (newFieldBoxId === fieldBox.id) {
      openColumnSelectionDropdown()
    }
  }, [fieldBox.id, newFieldBoxId, openColumnSelectionDropdown])

  useEffect(() => {
    if (isRowActive && fieldBoxElement) {
      fieldBoxElement.style.transform = gridRowTransform
    }
  }, [isRowActive, fieldBoxElement, gridRowTransform])

  return (
    <div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <div
        id={fieldBox.id}
        ref={fieldBoxRef}
        onClick={handleClick}
        className={clsx(classes.fieldBox, {
          [classes.inactiveFieldBox]: !isFieldBoxActive,
          [classes.activeFieldBox]: isFieldBoxActive,
          [classes.hoveredFieldBox]: hovered || isFieldBoxHighlighted,
        })}
      >
        {isFieldLabelVisible && (
          <div
            className={clsx(classes.fieldBoxLabel, {
              [classes.hoveredFieldBoxLabel]: hovered || isFieldBoxHighlighted,
            })}
            onClick={openColumnSelectionDropdown}
          >
            {fieldBox.label}
          </div>
        )}
      </div>
    </div>
  )
}

export default FieldBox
