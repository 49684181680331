import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState, useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import {
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import theme from '@src/utils/theme'
import { useMutation } from '@apollo/client'
import { Mutation } from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { RESET_USER_PASSWORD } from '@src/graphql/mutations/profile'
import { AdminUserProfileNode, MutationResetUserPasswordArgs } from '@src/graphql/types'
const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  submitButton: {
    textAlign: 'right',
  },
  formLine: {
    marginBottom: theme.spacing(1),
  },
  resetLinkBox: {
    display: 'block',
    width: '100%',
  },
})
type Props = {
  isOpen: boolean
  close: () => void
  user: AdminUserProfileNode
}

const ResetPasswordDialog: FunctionComponent<Props> = ({ isOpen, close, user }) => {
  const classes = useStyles()
  const [resetUserPassword] = useMutation<
    Pick<Mutation, 'resetUserPassword'>,
    MutationResetUserPasswordArgs
  >(RESET_USER_PASSWORD)
  const [resetLink, setResetLink] = useState(null as null | string)
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    const doReset = async (): Promise<void> => {
      try {
        const resp = await resetUserPassword({
          variables: {
            auth0Id: user.auth0Id,
          },
        })
        setResetLink(resp.data!.resetUserPassword)
      } catch (e) {
        enqueueSnackbar(formatMaybeApolloError(e), { variant: 'error' })
      }
    }
    void doReset()
  }, [enqueueSnackbar, resetUserPassword, user])
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialog }}
      onClose={close}
      open={isOpen}
    >
      <IconButton aria-label='close' onClick={close} className={classes.close}>
        <CloseIcon />
      </IconButton>
      <DialogTitle disableTypography>
        <Typography variant='h3'>Reset Password for {user.email}</Typography>
      </DialogTitle>
      <DialogContent>
        {resetLink ? (
          <>
            <p>Share this link so the end-user can reset their password</p>
            <textarea disabled className={classes.resetLinkBox}>
              {resetLink}
            </textarea>
          </>
        ) : (
          <CenteredCircularProgress />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ResetPasswordDialog
