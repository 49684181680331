import { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from 'react'
import useResizeAware from 'react-resize-aware'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Loop from '@material-ui/icons/Loop'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import RotateRightIcon from '@material-ui/icons/RotateRight'
import { makeStyles } from '@material-ui/styles'

import SelectionBox from '@src/components/selection-box'
import MagicGridContainer from '@src/components/magic-grid/MagicGridContainer'
import useBoundingBox from '@src/hooks/useBoundingBox'
import { cleanTextFromImage, extractWordFromCoordinates } from '@src/utils/ocr'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@src/utils/store'
import { setNewGridEnabled } from '@src/redux-features/document_editor'
import {
  setCopiedText,
  updateLineItemFieldDimension,
  updateOneFieldCoordinates,
} from '@src/redux-features/document_editor'
import {
  selectNonRepeatableFieldKeyMap,
  selectRepeatableFieldKeyMap,
} from '@src/redux-features/document_editor/field'
import { selectActiveDocumentTable } from '@src/redux-features/document_editor/document_table'
import { BoxingContext } from '@src/contexts/boxing_context'
import { COLORS, LINE_ITEM_ID_KEY_SEPARATOR, RECON_MODAL_STYLES } from '@src/utils/app_constants'
import {
  Query,
  FilePageNode,
  SearchableRecordNode,
  SearchableRecordResultNode,
} from '@src/graphql/types'
import { SEARCHABLE_RECORD_RESULTS } from '@src/graphql/queries/searchableRecord'
import { useQuery } from '@apollo/client'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import theme from '@src/utils/theme'
import { useZoomControlBar } from '@src/components/zoom-control-bar/useZoomControlBar'
import ZoomControlBar from '@src/components/zoom-control-bar/ZoomControlBar'
import { GoogleOCRData } from '@src/types/ocr'
import GridContainer from '@src/components/grid/GridContainer'
import ActiveFieldSelectionBox from './ActiveFieldSelectionBox'
import { getSearchableRecordFromFieldKey } from '@src/utils/searchable_record'
import { getJobNonRepeatableFields } from '@src/utils/shipment_form'
import { useSnackbar } from 'notistack'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { camelCase } from 'lodash'

const useStyles = makeStyles({
  fileImage: {
    width: '100%',
    height: 'auto',
    userDrag: 'none',
    userSelect: 'none',
  },
  // unset transform wrapper's troublesome fit-content for height and width
  transformWrapper: {
    width: 'unset !important',
    height: 'unset !important',
  },
  transformContent: {
    display: 'block !important',
    width: 'unset !important',
    height: 'unset !important',
  },
  rotateTextField: {
    backgroundColor: COLORS.PURE_WHITE,
    width: '120px',
  },
  copyButton: {
    marginRight: theme.spacing(1),
  },
  wrapperBox: {
    backgroundColor: theme.palette.grey[50],
    zIndex: RECON_MODAL_STYLES.Z_INDEX - 1,
  },
})

type Props = {
  filePage: FilePageNode
  isRotatingFilePage: boolean
  readOnly: boolean
  rotateImage: (angle: number, filePageId: string) => Promise<void>
  setCopyGridDialogOpen: (copyGridOpen: boolean) => void
  setCopyToOtherTable: (copyToOther: boolean) => void
  setCopyRowsToDocuments: (copyRowsToDocuments: boolean) => void
  showTableMenu: boolean
  activeTop: number
  hasFocusedBox: boolean
}

const FileImageViewer: FunctionComponent<Props> = ({
  filePage,
  isRotatingFilePage,
  readOnly,
  rotateImage,
  setCopyGridDialogOpen,
  setCopyToOtherTable,
  setCopyRowsToDocuments,
  showTableMenu,
  activeTop,
  hasFocusedBox,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const imageBoxRef = useRef(null as HTMLImageElement | null)
  const [autoCenterDoc, setAutoCenterDoc] = useState(false)
  const [resizeListener, fileViewerSize] = useResizeAware()

  const [rotationAngle, setRotationAngle] = useState(0)
  const [copyMenuOpen, setCopyMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isRowBoxDraggingOrResizing = useSelector(
    (state: RootState) => state.documentEditor.boxn.isRowBoxDraggingOrResizing,
  )
  const { start, end, boxDimension, isMouseDown, handleMouseDown, handleMouseUp, handleMouseMove } =
    useBoundingBox(imageBoxRef, !isRowBoxDraggingOrResizing)
  const magicGridReadOnly = useSelector(
    (state: RootState) => state.documentEditor.tableEditModeEnabled,
  )
  const hasActiveDocumentTable = useSelector(
    (state: RootState) => selectActiveDocumentTable(state.documentEditor) != null,
  )
  const googleOcrData = useMemo(
    () =>
      filePage?.googleOcrData != null
        ? (JSON.parse(filePage.googleOcrData) as GoogleOCRData)
        : undefined,
    [filePage],
  )
  const {
    panningEnabled,
    setPanningEnabled,
    scrollZoomEnabled,
    setScrollZoomEnabled,
    panOptions,
    wheelOptions,
    doubleClickOptions,
    transformWrapperOptions,
  } = useZoomControlBar()
  const { fieldMapRef } = useContext(BoxingContext)
  const activeFieldKey = useSelector(
    (state: RootState) => state.documentEditor.activeFieldKey as string,
  )
  const repeatableFieldKeyMap = useSelector((state: RootState) =>
    selectRepeatableFieldKeyMap(state.documentEditor),
  )
  const nonRepeatableFieldKeyMap = useSelector(
    (state: RootState) => selectNonRepeatableFieldKeyMap(state.documentEditor)!,
  )
  const imageWidth = imageBoxRef.current?.width || 0
  const imageHeight = imageBoxRef.current?.height || 0
  const dispatch = useDispatch()

  const newGridEnabled = useSelector((state: RootState) => state.documentEditor.boxn.newGridEnabled)

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
    setCopyMenuOpen(!copyMenuOpen)
  }

  const handleCopyTable = (copyToOther: boolean): void => {
    setCopyMenuOpen(false)
    setAnchorEl(null)
    setCopyToOtherTable(copyToOther)
    setCopyRowsToDocuments(false)
    setCopyGridDialogOpen(true)
  }

  const handleSmartCopyTable = (): void => {
    // handles copy for template matching
    setCopyMenuOpen(false)
    setAnchorEl(null)
    setCopyToOtherTable(true)
    setCopyRowsToDocuments(true)
    setCopyGridDialogOpen(true)
  }

  const job = useSelector((state: RootState) => state.documentEditor.job)
  const company = job?.jobTemplate?.company

  const nonRepeatableFields = getJobNonRepeatableFields(job)

  const [searchableRecord, setSearchableRecord] = useState<null | SearchableRecordNode>(null)
  useEffect(() => {
    if (activeFieldKey && !activeFieldKey.includes(LINE_ITEM_ID_KEY_SEPARATOR)) {
      const searchableRecord = getSearchableRecordFromFieldKey(nonRepeatableFields, activeFieldKey)
      setSearchableRecord(searchableRecord || null)
    }
  }, [job, activeFieldKey, nonRepeatableFields])

  const filters = company ? { companyId: company?.id } : {}
  const { refetch: refetchSearchableRecordResults } = useQuery<
    Pick<Query, 'searchableRecordResults'>
  >(SEARCHABLE_RECORD_RESULTS, {
    skip: true,
    variables: { searchableRecordId: '', queryString: '', filters },
  })

  // TODO: investigate whether we can put this boxing logic in a solitary child component,
  //       (prolly overlaying it over the image + via pointerEvents: none), so we can
  //       avoid re-rendering the grid constantly as we drag the box
  useEffect(() => {
    if (isMouseDown || boxDimension.width === 0 || boxDimension.height === 0 || !activeFieldKey) {
      return
    }

    dispatch(updateOneFieldCoordinates(activeFieldKey, boxDimension))
    // TODO: don't rely on this hack where we exploit the naming of the keys, and instead
    //       be more explicit on the line item ID somehow
    if (activeFieldKey.includes(LINE_ITEM_ID_KEY_SEPARATOR)) {
      const [id, key] = activeFieldKey.split(LINE_ITEM_ID_KEY_SEPARATOR)
      dispatch(updateLineItemFieldDimension(id, key, boxDimension))
    }

    const replaceFieldValue = (replacementText: string): void => {
      dispatch(setCopiedText(replacementText))
      const activeFieldElement = fieldMapRef.current[activeFieldKey]?.current
      if (activeFieldElement) {
        activeFieldElement.setValue(replacementText)
        activeFieldElement.element.current?.focus?.()
      }
      // so trigger if we box again. Can prolly just include the box coordinates
      // in the state alongside the copied text rather than doing this
      setTimeout(() => dispatch(setCopiedText(null)))
    }

    const queryClosestSearchableRecordAndReplaceFieldValue = async (
      queryString: string,
    ): Promise<void> => {
      const res = await refetchSearchableRecordResults({
        searchableRecordId: searchableRecord!.id,
        queryString: queryString,
        filters: filters,
        limit: 1,
      })
      const results = res?.data?.searchableRecordResults
      const key = camelCase(searchableRecord!.searchKey) as keyof SearchableRecordResultNode
      let newFieldValue = queryString
      if (results && results[0]) {
        newFieldValue = results[0][key] || queryString
      }
      replaceFieldValue(newFieldValue)
    }

    if (googleOcrData) {
      if (googleOcrData.error || !googleOcrData.full_text_annotation.pages) {
        const errorMessage = googleOcrData.error
          ? googleOcrData.error.message
          : `GoogleOCRData is missing pages`
        enqueueSnackbar(`${errorMessage}. You may need to delete and reupload the page`, {
          variant: `error`,
        })
        return
      }
      const copiedTextFromImage = extractWordFromCoordinates(googleOcrData, start, end)
      const cleanedTextFromImageObj = cleanTextFromImage(
        activeFieldKey,
        repeatableFieldKeyMap,
        nonRepeatableFieldKeyMap,
        copiedTextFromImage,
      )
      const cleanedText = cleanedTextFromImageObj.text
      const err = cleanedTextFromImageObj.err
      if (err) {
        enqueueSnackbar(err, { variant: `error` })
      }
      if (searchableRecord) {
        void queryClosestSearchableRecordAndReplaceFieldValue(cleanedText)
      } else {
        replaceFieldValue(cleanedText)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end])

  const [currentPositionY, setCurrentPositionY] = useState<number | null>(null)

  useEffect(() => {
    if (activeTop && hasFocusedBox && autoCenterDoc) {
      const newYPosition = -(activeTop * imageHeight) + 25
      setCurrentPositionY(newYPosition)
    }
  }, [activeTop])

  return (
    <Box position='relative'>
      {/* Re-render whenever FileViewer is resized */}
      <TransformWrapper
        pan={panOptions}
        wheel={wheelOptions}
        doubleClick={doubleClickOptions}
        // wrapperClass and contentClass are undocumented and dont have typing
        options={
          {
            ...transformWrapperOptions,
            wrapperClass: classes.transformWrapper,
            contentClass: classes.transformContent,
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          } as any
        }
      >
        {
          // react-zoom-pan-pinch doesn't have proper typing available
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          ({ zoomIn, zoomOut, resetTransform, positionX, positionY, scale, setPositionY }: any) => {
            setPositionY(currentPositionY)
            return (
              <>
                <Box
                  display='flex'
                  justifyContent='flex-end'
                  position='sticky'
                  top={0}
                  className={classes.wrapperBox}
                  zIndex={theme.zIndex.modal - 1}
                  p={1}
                >
                  {!readOnly && (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={autoCenterDoc}
                            onChange={(e) => setAutoCenterDoc(e.target.checked)}
                            name='autoCenterDoc'
                            color='primary'
                          />
                        }
                        label='Auto Pan Focused Box'
                        labelPlacement='end'
                      />
                      {showTableMenu && (
                        <>
                          <Button
                            className={classes.copyButton}
                            variant='outlined'
                            color='primary'
                            onClick={handleMenuClick}
                          >
                            Copy Grid
                          </Button>
                          <Menu
                            keepMounted
                            anchorEl={anchorEl}
                            onClose={() => {
                              setCopyMenuOpen(false)
                              setAnchorEl(null)
                            }}
                            open={copyMenuOpen}
                          >
                            <MenuItem onClick={() => handleCopyTable(false)}>
                              COPY GRID FROM OTHER PAGES
                            </MenuItem>
                            <MenuItem onClick={() => handleCopyTable(true)}>
                              COPY GRID TO OTHER PAGES
                            </MenuItem>
                            <MenuItem onClick={() => handleSmartCopyTable()}>
                              COPY GRID TO OTHER PAGES (BETA)
                            </MenuItem>
                          </Menu>
                          <Button
                            color='primary'
                            variant='outlined'
                            onClick={() => {
                              dispatch(setNewGridEnabled(!newGridEnabled))
                            }}
                          >
                            {newGridEnabled ? 'Use Old Grid' : 'Use New Grid'}
                          </Button>
                        </>
                      )}
                      <IconButton
                        disabled={isRotatingFilePage}
                        onClick={() => rotateImage(90, filePage.id)}
                      >
                        <RotateLeftIcon />
                      </IconButton>
                      <IconButton
                        disabled={isRotatingFilePage}
                        onClick={() => rotateImage(-90, filePage.id)}
                      >
                        <RotateRightIcon />
                      </IconButton>
                      <TextField
                        className={classes.rotateTextField}
                        id='standard-name'
                        label='Rotate'
                        variant='outlined'
                        value={rotationAngle}
                        onChange={(e) => setRotationAngle(Number(e.target.value))}
                        type='number'
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={() => rotateImage(rotationAngle, filePage.id)}>
                              <Loop />
                            </IconButton>
                          ),
                        }}
                      />
                    </div>
                  )}
                  <ZoomControlBar
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    resetTransform={() => {
                      resetTransform()
                      setCurrentPositionY(0)
                    }}
                    scrollZoomEnabled={scrollZoomEnabled}
                    setScrollZoomEnabled={setScrollZoomEnabled}
                    panningEnabled={panningEnabled}
                    setPanningEnabled={setPanningEnabled}
                  />
                </Box>
                <Box position='relative'>
                  {resizeListener}
                  {!isMouseDown && (!hasActiveDocumentTable || magicGridReadOnly) && (
                    <ActiveFieldSelectionBox
                      imageWidth={imageWidth * scale}
                      imageHeight={imageHeight * scale}
                      offsetX={positionX}
                      offsetY={positionY}
                    />
                  )}
                  <TransformComponent>
                    <Box
                      position='relative'
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                    >
                      {(!hasActiveDocumentTable || magicGridReadOnly) &&
                        !panningEnabled &&
                        isMouseDown && (
                          <SelectionBox
                            top={boxDimension.top * imageHeight}
                            left={boxDimension.left * imageWidth}
                            width={boxDimension.width * imageWidth}
                            height={boxDimension.height * imageHeight}
                          />
                        )}
                      {newGridEnabled ? (
                        <GridContainer
                          imageBoxRef={imageBoxRef}
                          fileViewerSize={fileViewerSize}
                          panningEnabled={panningEnabled}
                        >
                          <img
                            className={classes.fileImage}
                            ref={imageBoxRef}
                            draggable='false'
                            src={filePage.imageUrl || undefined}
                            alt={`${filePage.file?.filename}: Page ${filePage.pageNumber + 1}`}
                          />
                        </GridContainer>
                      ) : (
                        <MagicGridContainer
                          imageBoxRef={imageBoxRef}
                          fileViewerSize={fileViewerSize}
                          imageScale={scale}
                        >
                          <img
                            className={classes.fileImage}
                            ref={imageBoxRef}
                            draggable='false'
                            src={filePage.imageUrl || undefined}
                            alt={`${filePage.file?.filename}: Page ${filePage.pageNumber + 1}`}
                          />
                        </MagicGridContainer>
                      )}
                    </Box>
                  </TransformComponent>
                </Box>
              </>
            )
          }
        }
      </TransformWrapper>
    </Box>
  )
}

export default FileImageViewer
