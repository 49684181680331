import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { TASK_JOBS } from '@src/graphql/queries/task'
import { useSnackbar } from 'notistack'
import { JobNode, JobNodeEdge, JobStatus, ManualProduct } from '@src/graphql/types'
import {
  CSV_EXPORT_JOB_TYPES,
  EXCEL_EXPORT_JOB_TYPES,
  JOB_STATUS_ORDER,
  SECONDS_IN_MS,
  SINGLE_TASK_VIEW_SYNC_BASE_INTERVAL,
  TASK_VIEW_SYNC_OFFSET,
} from '@src/utils/app_constants'
import { useQuery } from '@apollo/client'
import theme from '@src/utils/theme'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import PublishIcon from '@material-ui/icons/Publish'
import { makeStyles } from '@material-ui/styles'
import ExportButtonGroup, { ExportType } from '@src/components/ExportButtonGroup'
import CreateJobDialog from '@src/components/CreateJobDialog'
import EditButtonGroup from '@src/components/EditButtonGroup'
import randomInt from '@src/utils/random'
import MoveToTaskButtonGroup from '@src/components/MoveToTaskButtonGroup'
import CreateJobRow from './CreateJobRow'
import JobRow from './JobRow'

type Props = {
  taskId: string
}

const useStyles = makeStyles({
  table: {
    zIndex: 0,
  },
  jobTable: {
    marginBottom: theme.spacing(2),
  },
  exportButton: {
    marginRight: theme.spacing(2),
  },
  ingestionButton: {
    marginBottom: theme.spacing(2),
  },
})

const TaskJobs: FunctionComponent<Props> = ({ taskId }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [timeLastSync, setTimeLastSync] = useState(null as null | Date)
  const { data: taskData, refetch: refetchTaskData } = useQuery(TASK_JOBS, {
    variables: { id: taskId },
  })
  const [createJobDialogOpen, setCreateJobDialogOpen] = useState(false)
  const [selectedJobs, setSelectedJobs] = useState([] as JobNode[])
  // TODO: what is the point of this setRefetch pattern?
  const [refetch, setRefetch] = useState(false)
  const refetchJobs = (): void => setRefetch(true)

  useEffect(() => {
    // Refetch recently updated/created tasks automatically
    // to keep task details relatively up to date
    const refetchData = async (): Promise<void> => {
      await refetchTaskData()
    }

    const timeToSyncInSeconds =
      SINGLE_TASK_VIEW_SYNC_BASE_INTERVAL +
      randomInt(TASK_VIEW_SYNC_OFFSET * 2) +
      1 -
      TASK_VIEW_SYNC_OFFSET
    setTimeout((): void => {
      refetchData()
        .then(() => setTimeLastSync(new Date()))
        .catch((error) =>
          enqueueSnackbar(
            `Failed to fetch updated task jobs ${formatMaybeApolloError(
              error,
            )}. Please refresh the page`,
            {
              variant: 'error',
            },
          ),
        )
    }, timeToSyncInSeconds * SECONDS_IN_MS)
  }, [timeLastSync, refetchTaskData, enqueueSnackbar])

  const jobData: JobNode[] =
    taskData?.task?.jobs?.edges
      ?.filter((job: JobNodeEdge) => job.node?.status !== JobStatus.Deleted)
      .map((job: JobNodeEdge) => job.node) || []

  useEffect(() => {
    const refetchData = async (): Promise<void> => {
      await refetchTaskData()
    }

    if (refetch) {
      refetchData()
        .then(() => setRefetch(false))
        .catch((error) =>
          enqueueSnackbar(
            `Failed to fetch updated jobs ${formatMaybeApolloError(
              error,
            )}. Please refresh the page`,
            {
              variant: 'error',
            },
          ),
        )
    }
  }, [enqueueSnackbar, refetch, refetchTaskData])

  const handleJobSelectionChange = (selectedJob: JobNode, add: boolean): void => {
    const newSelectedItems = add
      ? [...selectedJobs, selectedJob]
      : selectedJobs.filter((job) => job.id !== selectedJob.id)
    setSelectedJobs(newSelectedItems)
  }

  const exportItemsStatus = useMemo(() => {
    const selectedJobTypes = [...new Set(selectedJobs.map((job) => job.jobTemplate))]
    const allowAscentBulkExportCsv =
      selectedJobTypes.length === 1 &&
      selectedJobTypes[0]!.name === CSV_EXPORT_JOB_TYPES.ASCENT_INVOICE
    const allowCevaBulkExportCsv =
      !!selectedJobTypes.length &&
      selectedJobTypes.every((jobType) =>
        (
          [
            CSV_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_2HR,
            CSV_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_4HR,
            CSV_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_12HR,
            CSV_EXPORT_JOB_TYPES.CEVA_CUSTOMS_DECLARATION_24HR,
          ] as string[]
        ).includes(jobType!.name || ''),
      )
    const allowBulkExportXlsx =
      selectedJobTypes.length === 1 &&
      ((
        [
          EXCEL_EXPORT_JOB_TYPES.DRAYALLIANCE,
          EXCEL_EXPORT_JOB_TYPES.SCHAYER_CUSTOMS_DECLARATION_TYPE_1,
          EXCEL_EXPORT_JOB_TYPES.SCHAYER_CUSTOMS_DECLARATION_TYPE_2,
        ] as string[]
      ).includes(selectedJobTypes[0]!.name || '') ||
        !!selectedJobTypes[0]?.jobTemplateExport?.jobTemplateExportType)

    return {
      [ExportType.INVIDIUAL_EXPORT]: true,
      [ExportType.BULK_EXPORT_XLSX]: allowBulkExportXlsx,
      [ExportType.BULK_EXPORT_IH_CSV]: allowAscentBulkExportCsv,
      [ExportType.BULK_EXPORT_ILI_CSV]: allowAscentBulkExportCsv,
      [ExportType.BULK_EXPORT_CEVA_CSV]: allowCevaBulkExportCsv,
    }
  }, [selectedJobs])

  const jobStatusItems = useMemo(() => {
    if (selectedJobs.every((job) => job.manualProduct === ManualProduct.Manual)) {
      return JOB_STATUS_ORDER
    }

    const productSelectedJobsStatus = [
      ...new Set(
        selectedJobs
          .filter((job) => job.manualProduct === ManualProduct.Product)
          .map((job) => job.status),
      ),
    ]
    const productUnmovableJobStatus = [
      JobStatus.Deleted,
      JobStatus.Review,
      JobStatus.Export,
      JobStatus.Todo,
      JobStatus.InProgress,
    ]
    const disableBulkUpdateStatus =
      !productSelectedJobsStatus.length ||
      productUnmovableJobStatus.some((status) => productSelectedJobsStatus.includes(status))

    if (disableBulkUpdateStatus) return []
    return productSelectedJobsStatus.includes(JobStatus.Qa)
      ? [JobStatus.Confirmation, JobStatus.Done]
      : [JobStatus.Done]
  }, [selectedJobs])

  const toggleSelectAll = (): void => {
    if (selectedJobs.length === 0) {
      setSelectedJobs(jobData)
    } else {
      setSelectedJobs([])
    }
  }

  return (
    <Box py={2}>
      <Button
        className={classes.ingestionButton}
        href='/file-ingest'
        color='primary'
        variant='contained'
      >
        <PublishIcon />
        Ingestion
      </Button>
      <div className={classes.jobTable}>
        <Box display='flex' flexGrow={1}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={selectedJobs.length === jobData.length}
                      onChange={toggleSelectAll}
                      indeterminate={
                        selectedJobs.length > 0 && selectedJobs.length < jobData.length
                      }
                    />
                  </TableCell>
                  <TableCell align='center'>Actions</TableCell>
                  <TableCell>Job Name</TableCell>
                  <TableCell>Job Type</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>QA</TableCell>
                  <TableCell>P/M</TableCell>
                  <TableCell align='center'>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobData.map((job) => (
                  <JobRow
                    key={job.id}
                    job={job}
                    task={taskData?.task}
                    refetchJobs={refetchJobs}
                    selectedJobs={selectedJobs}
                    handleJobSelectionChange={handleJobSelectionChange}
                  />
                ))}
                {taskData && <CreateJobRow task={taskData!.task} refetchJobs={refetchJobs} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex'>
          <EditButtonGroup
            jobIds={selectedJobs.map((job) => job.id)}
            refetchJobs={refetchJobs}
            task={taskData?.task}
            statusItems={jobStatusItems}
          />
          <div className={classes.exportButton}>
            <ExportButtonGroup
              jobIds={selectedJobs.map((job) => job.id)}
              exportItemsStatus={exportItemsStatus}
            />
          </div>
          {taskData?.task && (
            <MoveToTaskButtonGroup
              task={taskData!.task!}
              refetchJobs={refetchJobs}
              jobIds={selectedJobs.map((job) => job.id)}
            />
          )}
        </Box>
        <Link
          onClick={() => {
            setCreateJobDialogOpen(true)
          }}
        >
          <Box display='flex' data-testid='create-job-box'>
            <AddIcon />
            <Typography variant='body1'>Create a new job</Typography>
          </Box>
        </Link>
      </Box>
      {taskData?.task && (
        <CreateJobDialog
          task={taskData.task}
          isOpen={createJobDialogOpen}
          refetchJobs={refetchJobs}
          closePopup={(): void => {
            setCreateJobDialogOpen(false)
          }}
        />
      )}
    </Box>
  )
}

export default TaskJobs
