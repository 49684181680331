export enum DeployEnvironment {
  DEVELOPMENT = 'DEVELOPMENT',
  ENGINEERING = 'ENGINEERING',
  STAGING = 'STAGING',
  PRODUCTION = 'PRODUCTION',
}

let cachedDeployEnvironment: DeployEnvironment | undefined

export const getDeployEnvironmentOverride = (): string | undefined => {
  if (typeof window === 'undefined') return 'DEVELOPMENT'
  const url = new URL(window.location.toString())
  return url.searchParams.get('deploy_env') || (localStorage.deploy_env as string | undefined)
}

export const getDeployEnvironment = (): DeployEnvironment => {
  if (cachedDeployEnvironment) {
    return cachedDeployEnvironment
  }
  if (typeof window === 'undefined') return DeployEnvironment.DEVELOPMENT
  const url = new URL(window.location.toString())
  const deployEnvironmentOverride = getDeployEnvironmentOverride()
  if (
    deployEnvironmentOverride &&
    ['DEVELOPMENT', 'ENGINEERING', 'STAGING', 'PRODUCTION'].includes(deployEnvironmentOverride)
  ) {
    return (cachedDeployEnvironment =
      DeployEnvironment[deployEnvironmentOverride as keyof typeof DeployEnvironment])
  } else if (url.hostname.includes('localhost')) {
    return (cachedDeployEnvironment = DeployEnvironment.DEVELOPMENT)
  } else if (url.hostname.includes('eng')) {
    return (cachedDeployEnvironment = DeployEnvironment.ENGINEERING)
  } else if (url.hostname.includes('staging')) {
    return (cachedDeployEnvironment = DeployEnvironment.STAGING)
  }
  return (cachedDeployEnvironment = DeployEnvironment.PRODUCTION)
}
