import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import { Box, CircularProgress, Theme } from '@material-ui/core'
import theme from '@src/utils/theme'
import { useMutation, useQuery } from '@apollo/client'
import { GET_EXISTING_RECON_ATTEMPT } from '@src/graphql/queries/recon'
import {
  RECONCILE_AP_INVOICE_JOB,
  RECONCILE_ARRIVAL_NOTICE_JOB,
} from '@src/graphql/mutations/recon'
import { useSnackbar } from 'notistack'
import { ReconJobResults, maybeFindShipmentIdFromReconResults } from '@src/utils/recon/recon'
import {
  InputDocumentTable,
  JobTemplateReconType,
  MutationReconcileApInvoiceJobArgs,
  MutationReconcileArrivalNoticeJobArgs,
  Mutation,
  ReconcileApInvoiceJob,
  ReconcileArrivalNoticeJob,
  InputReconPremiumRate,
  Query,
  QueryExistingReconAttemptArgs,
  MatchingCriteriaType,
  ApReconAutofillKey,
} from '@src/graphql/types'
import APInvoiceReconResults from './APInvoiceReconResults'
import ArrivalNoticeReconResults from './ArrivalNoticeReconResults'
import ReconciliationReview from './ReconciliationReview'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { RECON_MODAL_STYLES } from '@src/utils/app_constants'
import useValidateChargeCodes from '@src/hooks/charge_codes/use_validate_charge_codes'
import useTMSJobData from '@src/hooks/useTMSJobData'
import { stringifyList } from '@src/utils/string'
import { LogEventType } from '@src/utils/observability/LogEventType'
import ErrorCard from '@src/error-handling/ErrorCard'

enum ReconcilationStep {
  Review = 'Review',
  Completed = 'Completed',
}

const useStyles = makeStyles<Theme>({
  dialog: {
    // don't block handsontable comments
    // important because z-index 1300 is inline
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zIndex: `${RECON_MODAL_STYLES.Z_INDEX} !important` as any,
  },
  dialogMedium: {
    maxWidth: '97vw',
  },
  dialogLarge: {
    maxWidth: '97vw',
    maxHeight: '97vh',
  },
  dialogFullScreen: {
    maxWidth: '97%',
    width: '97%',
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

type Props = {
  isOpen: boolean
  close: () => void
  jobId: string
  reconType: JobTemplateReconType.Ap | JobTemplateReconType.ArrivalNotice
}

const ReconciliationDialog: FunctionComponent<Props> = ({ isOpen, close, jobId, reconType }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [reconJobResults, setReconJobResults] = useState(null as ReconJobResults)
  const [currentStep, setCurrentStep] = useState(ReconcilationStep.Review)
  const [reconAttemptId, setReconAttemptId] = useState(null as string | null)
  const { data: existingReconAttemptData } = useQuery<
    Pick<Query, 'existingReconAttempt'>,
    QueryExistingReconAttemptArgs
  >(GET_EXISTING_RECON_ATTEMPT, {
    fetchPolicy: 'network-only',
    variables: { jobId },
  })
  const existingReconAttempt = existingReconAttemptData?.existingReconAttempt ?? null
  const [reconcileApInvoiceJob] = useMutation<
    Pick<Mutation, 'reconcileApInvoiceJob'>,
    MutationReconcileApInvoiceJobArgs
  >(RECONCILE_AP_INVOICE_JOB)
  const [reconcileArrivalNoticeJob] = useMutation<
    Pick<Mutation, 'reconcileArrivalNoticeJob'>,
    MutationReconcileArrivalNoticeJobArgs
  >(RECONCILE_ARRIVAL_NOTICE_JOB)

  const { logEvent } = useEventLogger()

  const {
    job,
    jobDataLoading,
    documentFieldGroups,
    reconTables,
    isPremiumRate,
    metadataFieldValueMap,
  } = useTMSJobData(jobId, reconType)
  const cwModule = metadataFieldValueMap[ApReconAutofillKey.CargowiseModule.toLowerCase()]

  const { isValidatingChargeCodes, invalidDocChargeCodes, noJobChargeVendor, noJobChargeCodes } =
    useValidateChargeCodes(
      job?.jobTemplate ?? null,
      documentFieldGroups,
      reconTables,
      null,
      reconType,
      jobDataLoading,
    )

  const reconcile = async (
    lumpsum: InputDocumentTable | null,
    premiumRateDetails: InputReconPremiumRate | null,
    overrideChargeDescription = false,
    disableSendDueDate = false,
    matchingCriteria: MatchingCriteriaType = MatchingCriteriaType.ChargeCodeOnly,
  ): Promise<void> => {
    const actionStart = new Date()
    let success = false
    let errorMessage = ''
    let reconJobResults_ = null
    try {
      if (reconType === JobTemplateReconType.Ap) {
        const apInvoiceReconResultsData = await reconcileApInvoiceJob({
          variables: {
            jobId,
            lumpsum,
            overrideChargeDescription,
            disableSendDueDate,
            matchingCriteria,
          },
        })
        reconJobResults_ = apInvoiceReconResultsData.data!.reconcileApInvoiceJob!
      } else if (reconType === JobTemplateReconType.ArrivalNotice) {
        const arrivalNoticeReconResultsData = await reconcileArrivalNoticeJob({
          variables: { jobId, lumpsum, premiumRateDetails },
        })
        reconJobResults_ = arrivalNoticeReconResultsData.data!.reconcileArrivalNoticeJob!
      }
      setReconJobResults(reconJobResults_)
      setCurrentStep(ReconcilationStep.Completed)
      success = true
    } catch (error) {
      errorMessage = `Error reconciling job: ${formatMaybeApolloError(error)}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
      close()
    } finally {
      const reconResults = reconJobResults_?.reconResults
      const reconAttempt = reconJobResults_?.reconAttempt
      setReconAttemptId(reconAttempt ? reconAttempt?.id : null)
      void logEvent(LogEventType.RECONCILE_ATTEMPT, {
        job_id: jobId,
        action_start: actionStart,
        action_end: new Date(),
        recon_attempt_id: reconAttempt?.id,
        recon_type: reconType,
        recon_as_lumpsum: !!lumpsum,
        override_charge_desc: overrideChargeDescription,
        is_premium_rate: premiumRateDetails != null,
        shipment_id: maybeFindShipmentIdFromReconResults(reconResults),
        discrepant: reconResults ? reconResults.some((reconResult) => !reconResult.success) : true,
        success: success,
        error_message: errorMessage,
      })
    }
  }

  useEffect(() => {
    if (!isValidatingChargeCodes) {
      if (invalidDocChargeCodes.length > 0) {
        enqueueSnackbar(`Invalid document charge codes: ${stringifyList(invalidDocChargeCodes)}`, {
          variant: 'error',
        })
        close()
      } else if (noJobChargeVendor) {
        enqueueSnackbar('No charge vendor was found. Please enter a vendor.', { variant: 'error' })
        close()
      } else if (noJobChargeCodes) {
        enqueueSnackbar('No charge codes found. Please enter charge codes into table.', {
          variant: 'error',
        })
        close()
      }
    }
  }, [
    close,
    enqueueSnackbar,
    isValidatingChargeCodes,
    invalidDocChargeCodes,
    noJobChargeVendor,
    noJobChargeCodes,
  ])

  if (jobDataLoading) {
    return <CircularProgress />
  }

  return (
    <Dialog
      className={classes.dialog}
      classes={{
        paper:
          reconType === JobTemplateReconType.ArrivalNotice &&
          currentStep === ReconcilationStep.Completed
            ? classes.dialogFullScreen
            : reconType === JobTemplateReconType.Ap && currentStep === ReconcilationStep.Completed
            ? classes.dialogLarge
            : classes.dialogMedium,
      }}
      open={isOpen}
      data-testid='reconciliation-dialog'
    >
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={close} data-testid='close-btn' />
      </Box>
      {currentStep === ReconcilationStep.Review && (
        <ReconciliationReview
          documentFieldGroups={documentFieldGroups}
          isPremiumRate={isPremiumRate}
          reconTables={reconTables}
          reconcile={reconcile}
          reconType={reconType as JobTemplateReconType.Ap | JobTemplateReconType.ArrivalNotice}
          isLoading={isValidatingChargeCodes}
          cargowiseModule={cwModule}
        />
      )}
      {currentStep === ReconcilationStep.Completed && (
        <>
          {reconType === JobTemplateReconType.Ap && (
            <>
              {!jobDataLoading && !job && <ErrorCard>Job not found</ErrorCard>}
              {!jobDataLoading && job && (
                <APInvoiceReconResults
                  jobId={jobId}
                  companyId={job.jobTemplate.companyId}
                  externalAssignee={job.externalAssignee}
                  reconAttemptId={reconAttemptId}
                  reconJobResults={reconJobResults as ReconcileApInvoiceJob}
                  existingReconAttempt={existingReconAttempt}
                  previous={() => setCurrentStep(ReconcilationStep.Review)}
                  close={close}
                />
              )}
            </>
          )}
          {reconType === JobTemplateReconType.ArrivalNotice && (
            <ArrivalNoticeReconResults
              jobId={jobId}
              reconAttemptId={reconAttemptId}
              reconJobResults={reconJobResults as ReconcileArrivalNoticeJob}
              existingReconAttempt={existingReconAttempt}
              previous={() => setCurrentStep(ReconcilationStep.Review)}
              close={close}
            />
          )}
        </>
      )}
    </Dialog>
  )
}

export default ReconciliationDialog
