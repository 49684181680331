import { useEffect } from 'react'
import { DeployEnvironment, getDeployEnvironment } from '@src/utils/environment'
import { AMPLITUDE_API_KEY } from '@src/utils/observability/useAmplitude'

const environmentAuthMapping = {
  [DeployEnvironment.DEVELOPMENT]: {
    gtmId: 'GTM-P8FRRRQ',
    auth: 'hi7SnmC1fZpcs7skCfqdKg',
    preview: 'env-2',
  },
  [DeployEnvironment.ENGINEERING]: {
    gtmId: 'GTM-P8FRRRQ',
    auth: 'kgnnC9ogQ56vmgd4EQVvtA',
    preview: 'env-10',
  },
  [DeployEnvironment.STAGING]: {
    gtmId: 'GTM-P8FRRRQ',
    auth: 'cEeF8pie42WldHDq4yXRPA',
    preview: 'env-9',
  },
  [DeployEnvironment.PRODUCTION]: {
    gtmId: 'GTM-P8FRRRQ',
    auth: 'T7L5CbGkzU7EvRghGMRHAQ',
    preview: 'env-1',
  },
}

const environment = getDeployEnvironment()
const tagManagerArgs = environmentAuthMapping[environment]
const baseDataLayerVariables = {
  amplitude_api_key: AMPLITUDE_API_KEY,
  product_type: 'JMS',
}

const useGoogleTagManager = (): void => {
  const deployEnvironment = getDeployEnvironment()
  const gaEnabled =
    [
      DeployEnvironment.ENGINEERING,
      DeployEnvironment.STAGING,
      DeployEnvironment.PRODUCTION,
    ].includes(deployEnvironment) || localStorage.forceUseGoogleTagManager
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const untypedGlobal = globalThis as unknown as any
    if (gaEnabled) {
      const gtagScript = document.createElement('script')
      gtagScript.src = `https://www.googletagmanager.com/gtm.js?id=${tagManagerArgs.gtmId}&gtm_auth=${tagManagerArgs.auth}&gtm_preview=${tagManagerArgs.preview}`
      gtagScript.async = true
      document.body.appendChild(gtagScript)
      untypedGlobal.dataLayer = untypedGlobal.dataLayer || []
      untypedGlobal.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
        ...baseDataLayerVariables,
      })
    }
  }, [gaEnabled])
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
export const dataLayer = (event: any): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const untypedGlobal = globalThis as unknown as any
  untypedGlobal.dataLayer?.push({ ...event, ...baseDataLayerVariables })
}

export default useGoogleTagManager
