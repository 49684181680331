import { HotTable } from '@handsontable/react'
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useCompanyChargeVendorsAndOverridesQuery } from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import { FunctionComponent, useRef, useState } from 'react'
import { formatMaybeApolloError } from '@src/utils/errors'
import { styled } from '@material-ui/styles'
import ModalCloseButton from '../ModalCloseButton'
import { ChargeCodeVendorOverride, ChargeCodeWithOverride } from './types'

type Props = {
  chargeCode: ChargeCodeWithOverride
  companyId: string
  setOpen: (open: boolean) => void
  addNewVendorOverrides: (newChargeCodeRows: string[][]) => void
}

const dialogClass = 'AddMultipleVendorOverridesModal-dialog'
const StyledDialog = styled(Dialog)(() => ({
  [`&.${dialogClass}`]: {
    // don't block handsontable copy menu
    // important because zindex 1300 is inline
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zIndex: '1000 !important',
  },
}))

const columns = [
  {
    key: 'vendor_name',
    name: 'Vendor',
  },
  {
    key: 'description',
    name: 'Description',
  },
  {
    key: 'current_charge_code',
    name: 'Current Charge Code',
  },
]

const AddMultipleVendorOverridesModal: FunctionComponent<Props> = ({
  chargeCode,
  companyId,
  setOpen,
  addNewVendorOverrides,
}: Props) => {
  const [rows, setRows] = useState<string[][]>([])
  const { enqueueSnackbar } = useSnackbar()
  const hotTableRef = useRef<HotTable>(null)

  useCompanyChargeVendorsAndOverridesQuery({
    fetchPolicy: 'network-only',
    variables: { companyId },
    onCompleted: (data) => {
      const chargeVendorData = data.companyChargeVendors
      const newRows: string[][] = []

      const existingDescriptions: ChargeCodeVendorOverride[] = []
      const overrides = chargeCode.chargeCodeVendorOverrides.edges || []
      const descSet = new Set()
      for (const override of overrides) {
        if (!override.node.dateDeleted) {
          if (descSet.has(override.node.description)) {
            continue
          }
          descSet.add(override.node.description)
          existingDescriptions.push(override.node)
        }
      }

      for (const vendor of chargeVendorData) {
        const vendorOverrides = vendor.chargeCodeVendorOverrides?.edges || []
        for (const override of existingDescriptions) {
          const currRow: string[] = []
          currRow.push(vendor.name)
          currRow.push(override.description)

          // check if the desc already exists for an existing pairing
          const found = vendorOverrides.find((value) => {
            if (
              value.node.description == override.description &&
              value.node.chargeCodeId != chargeCode.id
            ) {
              return true
            }
            return false
          })
          if (found) {
            // In AddMultipleModal.tsx, vendor,desc pairs that already exist for a charge code will be highlighted in red.
            // For the new schema, we will only display the information and allow adding the same vendor,desc pair to another charge code.
            // One of the use cases for this is when you have separate charge codes for destination or origin charges. (i.e. future-proofing)
            currRow.push(found.node.chargeCode.code)
          } else {
            currRow.push('')
          }

          newRows.push(currRow)
        }
      }
      setRows(newRows)
    },
    onError: (error) => {
      enqueueSnackbar(`Error fetching company charge vendors: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
      setOpen(false)
    },
  })

  const copyChargeCodesAndClose = (): void => {
    if (hotTableRef?.current) {
      addNewVendorOverrides(hotTableRef.current.hotInstance.getData())
    }
    setOpen(false)
  }

  return (
    <StyledDialog maxWidth='xl' className={dialogClass} open={true}>
      <ModalCloseButton onClose={() => setOpen(false)} />
      <DialogTitle>Add {chargeCode?.code} override descriptions to multiple vendors</DialogTitle>
      <DialogContent>
        <Typography>The existing descriptions will be applied to the following vendors:</Typography>
        <Box>
          <HotTable
            id='charge-code-override-table'
            ref={hotTableRef}
            style={{ width: '100%', overflow: 'hidden', height: '400px' }}
            data={rows}
            rowHeaders
            contextMenu={['row_above', 'row_below', 'remove_row']}
            colHeaders={columns.map((col) => col.name)}
            columnSorting={true}
            columns={columns}
            stretchH='all'
            autoRowSize={true}
          />
        </Box>
        <Box display='flex' justifyContent='center' p={3}>
          <Box m={3}>
            <Button onClick={() => setOpen(false)} variant='contained'>
              Cancel
            </Button>
          </Box>
          <Box m={3}>
            <Button onClick={copyChargeCodesAndClose} variant='contained' color='primary'>
              Add Charge Code Override
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default AddMultipleVendorOverridesModal
