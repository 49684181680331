import { FunctionComponent, useCallback, useMemo } from 'react'
import { Controller, ControllerProps, useFormContext, useWatch } from 'react-hook-form'
import { DocumentTypeFormValues } from './DocumentTypeOptionsDialog'
import { AutofillExtractorKey, Maybe } from '@src/graphql/types'
import { Autocomplete } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'

const DocumentTypeAutofillExtractorKeySelector: FunctionComponent = () => {
  const { setValue } = useFormContext<DocumentTypeFormValues>()
  const autofillExtractorKey = useWatch<DocumentTypeFormValues>({
    name: 'autofillExtractorKey',
  }) as Maybe<AutofillExtractorKey>

  const renderInput = useCallback((params) => <TextField {...params} variant='outlined' />, [])
  const onAutocompleteChange = useCallback(
    (_: React.ChangeEvent<unknown>, newValue: Maybe<AutofillExtractorKey> | string): void => {
      if (newValue) {
        setValue('autofillExtractorKey', newValue as AutofillExtractorKey)
      }
    },
    [setValue],
  )
  const render: ControllerProps<DocumentTypeFormValues, 'autofillExtractorKey'>['render'] =
    useMemo(() => {
      return () => {
        return (
          <Autocomplete
            size='small'
            fullWidth={true}
            options={Object.values(AutofillExtractorKey) as AutofillExtractorKey[]}
            value={autofillExtractorKey}
            renderInput={renderInput}
            onChange={onAutocompleteChange}
          />
        )
      }
    }, [autofillExtractorKey, onAutocompleteChange, renderInput])
  return (
    <>
      <Controller
        render={render}
        name={'autofillExtractorKey'}
        defaultValue={autofillExtractorKey}
      />
    </>
  )
}

export default DocumentTypeAutofillExtractorKeySelector
