import produce, { Patch, produceWithPatches } from 'immer'
import { BoxDimension } from '@src/types/ocr'
import {
  checkFieldDimensionExists,
  isJobTableLineItem,
  JobTableLineItem,
  LineItem,
} from '@src/utils/line_items'
import { v4 as uuidv4 } from 'uuid'
import { selectActiveDocument } from './document'
import { DocumentEditorState } from './document_editor_state'
import { selectRepeatableFieldsFromJobOrDocTable } from './field'
import { jobTableLineItemSelectors } from './job_table'
import { lineItemsAdapter, lineItemSelectors } from './line_items_table'
import { computeNextStateWithUndo } from './undo_redo'
import { reportRollbarError } from '@src/utils/observability/rollbar'

/*
 * Type that represents the boxn (boxing line items) state.
 * contains information about the "active" box (the box that is selected if one is)
 * as well as hotkeys for boxn and other action information
 */
export type BoxnState = {
  activeFieldBoxId: string | null
  newFieldBoxId: string | null
  highlightedFieldBoxKey: string | null
  highlightedRowBoxIds: string[]
  highlightedFieldBoxIds: string[]
  newGridEnabled: boolean
  gridHotkeysEnabled: boolean
  createBoxEnabled: boolean
  gridRowTransform: string
  isRowBoxDraggingOrResizing: boolean
}

export const boxnInitialState: BoxnState = {
  activeFieldBoxId: null,
  newFieldBoxId: null,
  highlightedRowBoxIds: [],
  highlightedFieldBoxIds: [],
  highlightedFieldBoxKey: null,
  newGridEnabled: true,
  gridHotkeysEnabled: true,
  createBoxEnabled: false,
  gridRowTransform: 'none',
  isRowBoxDraggingOrResizing: false,
}

const updateStateLineItems = (
  state: DocumentEditorState,
  inversePatch: Patch[],
  forwardPatch: Patch[],
  nextState: DocumentEditorState,
): void => {
  computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
  state.boxn.activeFieldBoxId = null
  state.activeLineItemIds = []
}

const createNextDocumentEditorState = (
  state: DocumentEditorState,
  isFieldBoxSelected: string | boolean | null,
): [DocumentEditorState, Patch[], Patch[]] => {
  return produceWithPatches(state, (stateDraft) => {
    const lineItemsTableMapDraft = stateDraft.lineItemsTableMap
    const lineItemsDraft = lineItemsTableMapDraft[state.activeDocumentTableId!]!.lineItems
    if (isFieldBoxSelected) {
      const activeLineItem = lineItemSelectors.selectById(state, state.activeLineItemIds[0])
      const newLineItem = produce(activeLineItem, (lineItemDraft) => {
        lineItemDraft!.fieldMapping = Object.fromEntries(
          Object.entries(lineItemDraft!.fieldMapping).filter(([_key, value]) => {
            return state.boxn.activeFieldBoxId !== value.id
          }),
        )
      })
      lineItemsAdapter.updateOne(lineItemsDraft, {
        id: activeLineItem!.id,
        changes: { ...newLineItem },
      })
    } else {
      lineItemsAdapter.removeMany(lineItemsDraft, state.activeLineItemIds)
    }
  }) as [DocumentEditorState, Patch[], Patch[]]
}

export const deleteActiveFieldBoxOrLineItemsReducer = (state: DocumentEditorState): void => {
  if (state.activeLineItemIds.length > 0) {
    const isFieldBoxSelected = state.boxn.activeFieldBoxId && state.activeLineItemIds.length === 1
    const [nextState, forwardPatch, inversePatch] = createNextDocumentEditorState(
      state,
      isFieldBoxSelected,
    )
    updateStateLineItems(state, inversePatch, forwardPatch, nextState)
  }
}

export const getHighlightedLineItems = (
  lineItems: JobTableLineItem[] | LineItem[],
  startRow: number,
  endRow: number,
): (LineItem | JobTableLineItem)[] => {
  let highlightedLineItems = [] as (LineItem | JobTableLineItem)[]
  if (startRow === endRow && lineItems[startRow]) {
    highlightedLineItems = [lineItems[startRow]]
  } else {
    const start = Math.min(startRow, endRow)
    const end = Math.max(startRow, endRow)
    highlightedLineItems = lineItems.slice(start, end + 1)
  }
  return highlightedLineItems
}

export const getHighlightedFieldBoxKeys = (
  columnKeys: string[],
  startCol: number,
  endCol: number,
): string[] => {
  let highlightedFieldBoxKeys = [] as string[]
  if (startCol === endCol) {
    highlightedFieldBoxKeys = [columnKeys[startCol]]
  } else {
    const start = Math.min(startCol, endCol)
    const end = Math.max(startCol, endCol)
    highlightedFieldBoxKeys = columnKeys.slice(start, end + 1)
  }
  return highlightedFieldBoxKeys
}

export const getLineItemAndFieldBoxIds = (
  highlightedLineItems: (LineItem | JobTableLineItem)[],
  highlightedFieldBoxKeys: string[],
): string[][] => {
  const lineItemIds = [] as string[]
  const fieldBoxIds = [] as string[]
  highlightedLineItems.forEach((lineItem) => {
    lineItemIds.push(lineItem.id)
    Object.entries(lineItem.fieldMapping).forEach(([key, fieldMapping]) => {
      if (highlightedFieldBoxKeys.includes(key)) {
        fieldBoxIds.push(fieldMapping.id!)
      }
    })
  })
  return [lineItemIds, fieldBoxIds]
}

export const setCreateBoxEnabledIfValid = (
  state: DocumentEditorState,
  highlightedLineItems: (LineItem | JobTableLineItem)[],
  fieldBoxIds: string[],
): void => {
  const highlightedLineItem = highlightedLineItems[0]
  const activeDocument = selectActiveDocument(state)
  if (activeDocument == null) {
    reportRollbarError(
      `Called setCreateBoxEnabledIfValid w/ null: activeDocument ${activeDocument == null}`,
    )
    return
  }
  const box = isJobTableLineItem(highlightedLineItem)
    ? highlightedLineItem.boxMapping[activeDocument.id]
    : highlightedLineItem.box
  const isRowBoxPresent = Boolean(box)
  let isFieldBoxValid = false
  if (fieldBoxIds.length === 1) {
    const currentHighlightedFieldBox = Object.values(highlightedLineItem.fieldMapping).find(
      (fieldMapping) => {
        return fieldMapping.id === fieldBoxIds[0]
      },
    )
    isFieldBoxValid = checkFieldDimensionExists(currentHighlightedFieldBox!)
  }
  state.boxn.createBoxEnabled = isRowBoxPresent || isFieldBoxValid
}

export const updateHighlightedBoxesReducer = (
  state: DocumentEditorState,
  startRow: number,
  startCol: number,
  endRow: number,
  endCol: number,
  columnKeys: string[],
  //TODO: prolly want to put this in state?
  isJobTable: boolean,
): void => {
  if (columnKeys.length === 0) {
    return
  }
  const lineItems = isJobTable
    ? jobTableLineItemSelectors.selectAll(state)!
    : lineItemSelectors.selectAll(state)!
  const highlightedLineItems = getHighlightedLineItems(lineItems, startRow, endRow)
  const highlightedFieldBoxKeys = getHighlightedFieldBoxKeys(columnKeys, startCol, endCol)
  const [lineItemIds, fieldBoxIds] = getLineItemAndFieldBoxIds(
    highlightedLineItems,
    highlightedFieldBoxKeys,
  )

  const fieldBoxKey = highlightedFieldBoxKeys.length === 1 ? highlightedFieldBoxKeys[0] : null
  state.boxn.highlightedFieldBoxKey = fieldBoxKey
  state.boxn.highlightedRowBoxIds = lineItemIds
  state.boxn.highlightedFieldBoxIds = fieldBoxIds
  // TODO: check create box valid outside of setCreateBoxEnabledIfValid?
  if (highlightedLineItems.length === 1) {
    setCreateBoxEnabledIfValid(state, highlightedLineItems, fieldBoxIds)
  }
}

export const createFieldBoxReducer = (
  state: DocumentEditorState,
  dimension: BoxDimension,
): void => {
  if (state.activeLineItemIds.length === 1) {
    const lineItem = lineItemSelectors.selectById(state, state.activeLineItemIds[0])
    if (lineItem == null) {
      // TODO: fix race or whatever causes this?
      return
    }
    const fieldBoxKeys = Object.keys(lineItem!.fieldMapping)
    const repeatableFields = selectRepeatableFieldsFromJobOrDocTable(state)
    // these are fields not found in this line item or doesnt have a box yet
    const availableFields = repeatableFields.filter((field) => {
      return (
        !fieldBoxKeys.includes(field.key) ||
        !checkFieldDimensionExists(lineItem!.fieldMapping[field.key])
      )
    })
    const fieldBoxKey = state.boxn.highlightedFieldBoxKey || availableFields?.[0]?.key
    if (fieldBoxKey) {
      const newFieldBoxId = uuidv4()
      const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
        const lineItemsTableMapDraft = stateDraft.lineItemsTableMap
        const lineItemCopy = produce(lineItem, (lineItemDraft) => {
          const fieldBox = lineItemDraft?.fieldMapping?.[fieldBoxKey]
          lineItemDraft!.fieldMapping[fieldBoxKey] = {
            ...fieldBox,
            ...dimension,
            id: fieldBox?.id ?? newFieldBoxId,
            value: fieldBox?.value ?? '',
          }
        })
        const lineItemsDraft = lineItemsTableMapDraft[stateDraft.activeDocumentTableId!]!.lineItems
        lineItemsAdapter.updateOne(lineItemsDraft, {
          id: lineItem!.id,
          changes: { ...lineItemCopy },
        })
      })
      computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
      state.boxn.newFieldBoxId = newFieldBoxId
      state.boxn.highlightedFieldBoxKey = null
    }
  }
}

export const toggleRowBoxReducer = (state: DocumentEditorState, lineItemId: string): void => {
  state.activeLineItemIds = produce(state.activeLineItemIds, (lineItemIdsDraft) => {
    const lineItemIdIdx = lineItemIdsDraft.findIndex((id) => id === lineItemId)
    if (lineItemIdIdx > -1) {
      lineItemIdsDraft.splice(lineItemIdIdx, 1)
    } else {
      lineItemIdsDraft.push(lineItemId)
    }
  })
  state.boxn.highlightedFieldBoxKey = null
}
