import JobEditor from '@src/components/job-editor'
import { ActionSet } from '@src/components/shipment-form/ShipmentActions'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'

const InProgressPage: FunctionComponent = () => {
  const { id: jobId } = useParams<{ id: string }>()

  return <JobEditor jobId={jobId} actionSet={ActionSet.IN_PROGRESS} />
}

export default InProgressPage
