/* eslint-disable @typescript-eslint/no-explicit-any */
// ^ extremely legacy code, so can't be bothered to fix the typing
import { produce } from 'immer'
import { WritableDraft } from 'immer/dist/internal'
import { DocumentTypeFieldGroupNodeEdge, FilePageNodeEdge, JobNode } from '@src/graphql/types'
import { formatLineItems } from './job_format'

// prefix to be used for field groups duplicated as Manufacturer Summary to make key unique
const fieldKeyPrefix = 'manu_summ_'

export const getAddtlFremuraIsfDocTypeFieldGroups = (
  job: JobNode,
): DocumentTypeFieldGroupNodeEdge[] | undefined => {
  const filePageNodeEdge = job.filePages?.edges.find(
    (filePage) => filePage?.node?.document?.documentType?.name === 'Fremura ISF Filing',
  )

  // Duplicate Selling Party fields and rename to be Manufacturer Summary
  return filePageNodeEdge?.node?.document?.documentType?.documentTypeFieldGroups?.edges
    .filter(
      (docTypeFieldGroup) => docTypeFieldGroup?.node?.fieldGroup?.name?.startsWith('Selling Party'),
    )
    .map((docTypeFieldGroup) =>
      produce(docTypeFieldGroup, (docTypeFieldGroupDraft) => {
        const docTypeName = docTypeFieldGroupDraft!.node!.fieldGroup!.name.replace(
          'Selling Party',
          'Manufacturer Summary',
        )
        const docTypeKey = `${fieldKeyPrefix}${docTypeFieldGroup!.node!.fieldGroup!.key}`
        docTypeFieldGroupDraft!.node!.id = `${docTypeFieldGroup!.node!.id}_2`
        docTypeFieldGroupDraft!.node!.fieldGroup = {
          ...docTypeFieldGroupDraft!.node!.fieldGroup!,
          id: `${docTypeFieldGroup!.node!.fieldGroup!.id}_2`,
          key: docTypeKey,
          name: docTypeName,
        }
        docTypeFieldGroupDraft!.node!.fieldGroup.fields!.edges[0]!.node = {
          ...docTypeFieldGroupDraft!.node!.fieldGroup.fields!.edges[0]!.node!,
          id: `${docTypeFieldGroup!.node!.fieldGroup!.fields!.edges[0]!.node!.id}_2`,
          key: docTypeKey,
          name: docTypeName,
        }
      }),
    )
}

export const formatAmsJob = (job: JobNode): JobNode => {
  const fieldGroupsToFormat = ['container_status', 'type_code', 'description_code']
  const formatter = (value: string): string => {
    const valuesDict: {
      [key: string]: string
    } = {
      Empty: 'E',
      Loaded: 'L',
      "20'": '20G0',
      "40'": '40G0',
      "45'": 'L0G0',
      "20' HQ": '20R0',
      "40' HQ": '40R0',
      "45' HQ": 'L0R0',
      "20' SPECIAL EQUIP": '20U0',
      "40' SPECIAL EQUIP": '40U0',
      "20' CLOSED TOP": '2B',
      "40' CLOSED TOP": '4B',
      "20' OPEN TOP": '20',
      "40' OPEN TOP": '40',
      CONTAINER: 'CN',
      'REFRIGERATED CONTAINER': 'CZ',
    }
    return valuesDict[value] || value
  }
  return formatLineItems(job, fieldGroupsToFormat, formatter)
}

export const formatFremuraIsfFilingFilePages = (
  job: JobNode,
  addtlDocTypeFieldGroups: DocumentTypeFieldGroupNodeEdge[],
): FilePageNodeEdge[] | undefined =>
  job.filePages?.edges.map((filePage) =>
    !filePage?.node?.document?.documentFieldGroups?.edges ||
    !filePage.node.document.documentType?.documentTypeFieldGroups?.edges ||
    filePage.node.document.documentType.name !== 'Fremura ISF Filing'
      ? filePage
      : produce(filePage, (filePageDraft) => {
          const fieldGroupsDuplicateMap = addtlDocTypeFieldGroups.reduce(
            (map, docTypeFieldGroup) => {
              map[docTypeFieldGroup!.node!.fieldGroup!.key] = docTypeFieldGroup
              return map
            },
            {} as { [key: string]: WritableDraft<DocumentTypeFieldGroupNodeEdge> | null },
          )

          // Copy Selling Party field values for Manufacturer Summary fields
          const docFieldGroupsDuplicate = filePageDraft
            .node!.document!.documentFieldGroups!.edges.filter(
              (docFieldGroup) =>
                docFieldGroup?.node?.fieldGroup?.name?.startsWith('Selling Party') &&
                docFieldGroup.node.documentFields?.edges[0]?.node?.value,
            )
            .map((docFieldGroup) => {
              const fieldGroupKey = `${fieldKeyPrefix}${docFieldGroup!.node!.fieldGroup!.key}`
              docFieldGroup!.node!.fieldGroup = fieldGroupsDuplicateMap[fieldGroupKey]?.node
                ?.fieldGroup as unknown as any
              docFieldGroup!.node!.id = `${docFieldGroup!.node!.id}_2`
              docFieldGroup!.node!.documentFields!.edges =
                docFieldGroup!.node!.documentFields!.edges.map((docField) => {
                  docField!.node!.field = fieldGroupsDuplicateMap[fieldGroupKey]?.node?.fieldGroup
                    ?.fields?.edges[0]?.node as unknown as any
                  return docField
                })
              return docFieldGroup
            })

          filePageDraft.node!.document!.documentType!.documentTypeFieldGroups!.edges = [
            ...filePage.node!.document!.documentType!.documentTypeFieldGroups!.edges,
            ...Object.values(fieldGroupsDuplicateMap),
          ] as unknown as any

          filePageDraft.node!.document!.documentFieldGroups!.edges = [
            ...filePage.node!.document!.documentFieldGroups!.edges,
            ...docFieldGroupsDuplicate,
          ]
        }),
  )
