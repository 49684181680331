import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, ControllerProps, useFormContext, useWatch } from 'react-hook-form'
import { useQuery } from '@apollo/client'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'

import { JobTemplateFormValues } from '@src/components/admin/job-template-editor/JobTemplateEditor'
import { GET_ALL_API_PARTNERS } from '@src/graphql/queries/api'
import { GET_ALL_CW_CONFIGS } from '@src/graphql/queries/cargowise'
import { ApiPartnerInterface, Maybe, Query } from '@src/graphql/types'

const ApiPartnerSelector: FunctionComponent = () => {
  const apiPartnerId = useWatch<JobTemplateFormValues>({
    name: 'apiPartnerId',
  }) as Maybe<string>
  const { setValue } = useFormContext<JobTemplateFormValues>()
  const [selectedApiPartner, setSelectedApiPartner] = useState<ApiPartnerInterface | null>(null)

  const { data: universalApiPartnersData, loading: apiPartnersLoading } =
    useQuery<Pick<Query, 'apiPartners'>>(GET_ALL_API_PARTNERS)

  const { data: cargowiseConfigsData, loading: cargowiseConfigLoading } =
    useQuery<Pick<Query, 'cargowiseConfigs'>>(GET_ALL_CW_CONFIGS)

  const apiPartnersMap = useMemo(() => {
    return Object.fromEntries(
      [
        ...(universalApiPartnersData?.apiPartners ?? []),
        ...(cargowiseConfigsData?.cargowiseConfigs ?? []),
      ].map((apiPartner) => [apiPartner.id, apiPartner]) ?? [],
    )
  }, [universalApiPartnersData, cargowiseConfigsData])

  useEffect(() => {
    const apiPartner = apiPartnerId ? apiPartnersMap[apiPartnerId] ?? null : null
    setSelectedApiPartner(apiPartner)
  }, [apiPartnersMap, apiPartnerId])

  const formatApiPartnerLabel = useCallback((apiPartner: Maybe<ApiPartnerInterface>): string => {
    if (apiPartner) {
      if (apiPartner.company) {
        return `${apiPartner.company.name}: ${apiPartner.name}`
      }
      return apiPartner.name ?? ''
    }
    return ''
  }, [])

  const getOptionSelected = useCallback((option, val) => option.id === val.id, [])
  const onAutocompleteChange = useCallback(
    (_: ChangeEvent<unknown>, newValue: Maybe<ApiPartnerInterface> | string): void => {
      if (typeof newValue !== 'string') {
        setValue('apiPartnerId', newValue?.id ?? null)
      }
    },
    [setValue],
  )

  const render: ControllerProps<JobTemplateFormValues, 'apiPartnerId'>['render'] = useMemo(() => {
    return ({ fieldState: { error, invalid } }) => {
      return (
        <Autocomplete
          size='small'
          options={Object.values(apiPartnersMap)}
          value={selectedApiPartner}
          renderOption={formatApiPartnerLabel}
          getOptionLabel={formatApiPartnerLabel}
          getOptionSelected={getOptionSelected}
          disabled={apiPartnersLoading && cargowiseConfigLoading}
          renderInput={(params) => (
            <TextField {...params} error={invalid} helperText={error?.message} variant='outlined' />
          )}
          onChange={onAutocompleteChange}
        />
      )
    }
  }, [
    selectedApiPartner,
    formatApiPartnerLabel,
    getOptionSelected,
    onAutocompleteChange,
    apiPartnersLoading,
    cargowiseConfigLoading,
    apiPartnersMap,
  ])

  return <Controller render={render} name='apiPartnerId' defaultValue={selectedApiPartner?.id} />
}

export default ApiPartnerSelector
