import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { FieldNode } from '@src/graphql/types'
import createCachedSelector from 're-reselect'
import { JobTableNode, Maybe } from '@src/graphql/types'
import { FieldCoordinates } from '@src/types/ocr'
import { selectActiveDocumentEditorPage } from './document'
import { DocumentEditorState } from './document_editor_state'
import { documentTableSelectors, selectActiveDocumentTable } from './document_table'
import { selectJob } from './job'
import { JobLineItemsTableState } from './job_line_items_table'

export const selectFieldCoordinates = (state: DocumentEditorState): FieldCoordinates | null =>
  selectActiveDocumentEditorPage(state)?.fieldCoordinates || null

export const selectNonRepeatableFields = createDraftSafeSelector(
  selectJob,
  (job) =>
    job
      ?.jobTemplate!.documentTypes!.edges.map((edge) => edge!.node!)
      .flatMap((documentType) =>
        documentType
          .documentTypeFieldGroups!.edges.map((edge) => edge!.node!.fieldGroup!)
          .filter((fieldGroup) => !fieldGroup.repeatable)
          .map((fieldGroup) => fieldGroup.fields!.edges[0]!.node!),
      ),
)

export const selectNonRepeatableFieldKeyMap = createDraftSafeSelector(
  selectNonRepeatableFields,
  (fields) => fields && Object.fromEntries(fields.map((field) => [field.key, field])),
)

export const selectJobTable = (state: DocumentEditorState): Maybe<JobTableNode> | undefined =>
  state.job?.jobTable

export const selectJobTableState = (state: DocumentEditorState): Maybe<JobLineItemsTableState> =>
  state.jobTableState

export const selectFieldGroupFromJobOrDocTable = createDraftSafeSelector(
  selectActiveDocumentTable,
  selectJobTable,
  (state: DocumentEditorState) => state.jobTableActive,
  (activeDocumentTable, jobTable, isJobTableActive) => {
    if (isJobTableActive) {
      /*
       * a job table is only created after it is saved (no job table is created upon job creation/ initialization)
       * so while it hasn't been created yet, we can take the repeatable fields from the active document table instead
       */
      if (jobTable) {
        return jobTable?.fieldGroup
      }
      return activeDocumentTable?.fieldGroup
    }
    return activeDocumentTable?.fieldGroup
  },
)

export const selectFieldGroupFromJobTable = createDraftSafeSelector(
  selectActiveDocumentTable,
  selectJobTable,
  selectJobTableState,
  // defaults to the active document table field group if state.job.jobTable is null
  // this happens when creating a job + uploading documents for the first time
  // a jobTable is only created/saved in the db after saving the table in the frontend
  (activeDocumentTable, jobTable, jobTableState) => {
    return jobTable?.fieldGroup ?? activeDocumentTable?.fieldGroup ?? jobTableState?.fieldGroup
  },
)

export const selectRepeatableFieldsFromJobOrDocTable = createDraftSafeSelector(
  selectFieldGroupFromJobOrDocTable,
  (fieldGroup): FieldNode[] => {
    return (
      fieldGroup?.fields?.edges
        ?.map((fieldEdge) => fieldEdge!.node!)
        ?.sort((fieldA, fieldB) => {
          if (fieldA?.columnOrder || fieldB?.columnOrder) {
            return fieldA?.columnOrder - fieldB?.columnOrder
          }
          return fieldA.key.localeCompare(fieldB.key)
        }) ?? []
    )
  },
)

export const selectRepeatableFieldsFromJobTable = createDraftSafeSelector(
  selectFieldGroupFromJobTable,
  (fieldGroup): FieldNode[] => {
    return (
      fieldGroup?.fields?.edges
        ?.map((fieldEdge) => fieldEdge!.node!)
        ?.sort((fieldA, fieldB) => {
          if (fieldA?.columnOrder || fieldB?.columnOrder) {
            return fieldA?.columnOrder - fieldB?.columnOrder
          }
          return fieldA.key.localeCompare(fieldB.key)
        }) ?? []
    )
  },
)

export const selectRepeatableFieldKeyMap = createDraftSafeSelector(
  selectRepeatableFieldsFromJobOrDocTable,
  (fields) => fields && Object.fromEntries(fields.map((field) => [field.key, field])),
)

export const selectRepeatableFieldAutofillKeyMap = createDraftSafeSelector(
  selectRepeatableFieldsFromJobOrDocTable,
  (fields) => fields && Object.fromEntries(fields.map((field) => [field.autofillKey, field])),
)

// warning: this is not draft safe and thus should not be used in a reducer!
export const selectRepeatableFieldNameByKey = createCachedSelector(
  selectRepeatableFieldsFromJobOrDocTable,
  (_state: DocumentEditorState, fieldKey: string) => fieldKey,
  (fields, fieldKey): string | undefined => {
    return fields?.find(({ key }) => key === fieldKey)?.name
  },
)((_state, fieldKey) => fieldKey)

export const selectFieldKeyAutofillKeyMap = createDraftSafeSelector(
  selectNonRepeatableFields,
  (fields) => {
    if (fields) {
      return fields.reduce(
        (fieldKeyAutofillKeyMap, field) => {
          fieldKeyAutofillKeyMap[field.key] = field.autofillKey
          return fieldKeyAutofillKeyMap
        },
        {} as Record<string, string>,
      )
    }
    return null
  },
)

export const selectNonRepeatableFieldValueMap = createDraftSafeSelector(
  selectFieldKeyAutofillKeyMap,
  (state: DocumentEditorState) => state.pageFieldEditorState?.pages,
  (fieldKeyAutofillKeyMap, pages) => {
    if (!pages || Object.keys(pages).length <= 0 || !fieldKeyAutofillKeyMap) {
      return
    }
    const nonRepeatableFieldValueMap = Object.entries(pages).reduce(
      (_nonRepeatableFieldValueMap, [_, documentEditorPage]) => {
        Object.entries(documentEditorPage.fieldMapping).forEach(([fieldKey, fieldVal]) => {
          if (fieldKey in fieldKeyAutofillKeyMap) {
            const fieldAutofillKey = fieldKeyAutofillKeyMap[fieldKey]
            _nonRepeatableFieldValueMap[fieldAutofillKey] = fieldVal.trim() ?? null
          }
        })
        return _nonRepeatableFieldValueMap
      },
      {} as Record<string, string | null>,
    )
    return nonRepeatableFieldValueMap
  },
)

export const selectRepeatableFieldValueMap = (
  state: DocumentEditorState,
): Record<string, string | null>[] => {
  const repeatableFieldValueMap = [] as Record<string, string | null>[]
  const documentTables = documentTableSelectors.selectAll(state)
  documentTables.forEach((documentTable) => {
    const fieldIdAutofillKeyMap = documentTable.fieldGroup.fields.edges.reduce(
      (_fieldIdAutofillKeyMap, field) => {
        _fieldIdAutofillKeyMap[field.node.id] = field.node.autofillKey
        return _fieldIdAutofillKeyMap
      },
      {} as Record<string, string>,
    )

    const baseFieldValueMap = Object.fromEntries(
      Object.values(fieldIdAutofillKeyMap).map((autofillKey) => [autofillKey, null]),
    ) as Record<string, string | null>

    documentTable.documentFieldGroups.edges.forEach((docFieldGroup) => {
      const rowFieldValMap = docFieldGroup.node.documentFields.edges.reduce(
        (_fieldValMap, docField) => {
          const autofillKey = fieldIdAutofillKeyMap[docField.node.field.id]
          _fieldValMap[autofillKey] = docField.node.value.trim()
          return _fieldValMap
        },
        baseFieldValueMap,
      )
      repeatableFieldValueMap.push(rowFieldValMap)
    })
  })
  return repeatableFieldValueMap
}
