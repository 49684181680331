// Use this to set the title of a given page
import { FunctionComponent, useEffect } from 'react'

type TitleProps = {
  title: string
}

const Title: FunctionComponent<TitleProps> = ({ title }) => {
  useEffect(() => {
    document.title = `${title} | Expedock: Documentation, Made Easy`
  }, [title])
  return <></>
}

export default Title
