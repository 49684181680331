import { gql } from '@apollo/client'
import { TASK_NOTE_FRAGMENT } from './taskNote'

export const TASKS_UP_TO_PAGE = gql`
  query taskPages($pageUpTo: Int!, $query: String, $filters: [InputTaskFilter!]) {
    taskPages(pageUpTo: $pageUpTo, query: $query, filters: $filters) {
      items {
        id
        title
        starred
        isCriticallyLow
        isStarred
        blocked
        status
        taskType
        dateCreated
        dateReceived
        dateConfirmed
        taskReferenceId
        company {
          id
          name
        }
        taskSlaTimeJobRef {
          id
          job {
            id
            dateCreated
            slaTime
          }
        }
        jobs {
          edges {
            node {
              id
              status
              manualProduct
              name
              jobCode
              dateCompleted
              jobTemplate {
                id
                name
              }
              owner {
                id
                name
                email
              }
              qa {
                id
                name
                email
              }
            }
          }
        }
      }
      counts {
        status
        count
      }
      filteredCounts {
        status
        count
      }
    }
  }
`

export const TASKS_UP_TO_PAGE_INGEST = gql`
  query taskPagesIngest($pageUpTo: Int!, $query: String, $filters: [InputTaskFilter!]) {
    taskPagesIngest(pageUpTo: $pageUpTo, query: $query, filters: $filters) {
      items {
        id
        title
        status
        taskType
        dateCreated
        dateReceived
        dateConfirmed
        taskReferenceId
        company {
          id
          name
          jobTemplates {
            edges {
              node {
                id
                name
                documentTypes {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
        taskSlaTimeJobRef {
          id
          job {
            id
            dateCreated
            slaTime
          }
        }
        jobs {
          edges {
            node {
              id
              status
              manualProduct
              name
              jobCode
              clientReferenceNo
              jobTemplate {
                id
                name
                documentTypes {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      counts {
        status
        count
      }
      filteredCounts {
        status
        count
      }
    }
  }
`

export const TASKS_BY_TITLE_REFERENCE_ID = gql`
  query tasksByTitleReferenceId($query: String!, $companyId: UUID) {
    tasksByTitleReferenceId(query: $query, companyId: $companyId) {
      id
      title
      taskReferenceId
    }
  }
`

export const TASK_FOR_CREATE_JOB = gql`
  query taskForCreateJob($id: String!) {
    task(id: $id) {
      id
      title
      dateReceived
      taskReferenceId
      company {
        id
        name
      }
      message
    }
  }
`

export const TASK_FOR_INGEST = gql`
  query taskForIngest($id: String!) {
    task(id: $id) {
      id
      title
      dateReceived
      message
      taskReferenceId
      company {
        id
        name
        jobTemplates {
          edges {
            node {
              documentTypes {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              id
              name
            }
          }
        }
      }
      jobs {
        edges {
          node {
            id
            name
            jobTemplate {
              id
              name
              documentTypes {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const TASK_DETAIL = gql`
  ${TASK_NOTE_FRAGMENT}
  query taskDetail($id: String!) {
    task(id: $id) {
      id
      title
      taskReferenceId
      message
      starred
      isCriticallyLow
      isStarred
      blocked
      status
      taskType
      dateCreated
      dateReceived
      dateConfirmed
      jobs {
        edges {
          node {
            id
            name
            status
            jobTemplate {
              id
              name
            }
          }
        }
      }
      taskSlaTimeJobRef {
        id
        job {
          id
          dateCreated
          slaTime
        }
      }
      company {
        id
        name
      }
      taskNotes {
        edges {
          node {
            ...taskNote
          }
        }
      }
    }
  }
`

export const TASK_JOBS = gql`
  query getTaskWithJob($id: String!) {
    task(id: $id) {
      id
      title
      taskReferenceId
      message
      status
      starred
      blocked
      taskType
      dateReceived
      dateConfirmed
      company {
        id
        name
      }
      taskSlaTimeJobRef {
        id
        job {
          id
          dateCreated
          slaTime
        }
      }
      jobs {
        edges {
          node {
            id
            name
            slaTime
            dateCreated
            jobCode
            status
            clientReferenceNo
            manualProduct
            owner {
              email
              id
            }
            qa {
              email
              id
            }
            jobTemplate {
              id
              name
              companyId
              company {
                id
                name
              }
              jobTemplateExport {
                id
                jobTemplateExportType
              }
            }
            filePages {
              edges {
                node {
                  id
                  status
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_TASK_SEARCH_PARAMETERS = gql`
  query taskSearchParameters {
    taskSearchParameters {
      queryString
      filters {
        id
        column {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on TaskFilterColumnEnum {
            value
            isFallback
          }
        }
        operation
        value
      }
    }
  }
`
