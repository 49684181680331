import { gql } from '@apollo/client'

export const DELETE_FILE_PAGE = gql`
  mutation deleteFilePage($jobId: String!, $filePageId: String!) {
    deleteFilePage(jobId: $jobId, filePageId: $filePageId) {
      ok
    }
  }
`

export const DELETE_FILE_PAGES = gql`
  mutation deleteFilePages($filePageIds: [String!]!) {
    deleteFilePages(filePageIds: $filePageIds) {
      numUpdated
    }
  }
`

export const ROTATE_FILE_PAGE = gql`
  mutation rotateFilePage($angle: Float!, $filePageId: String!) {
    rotateFilePage(angle: $angle, filePageId: $filePageId) {
      filePage {
        id
        imageUrl
        dateModified
        googleOcrData
      }
    }
  }
`

export const INGEST_FILES = gql`
  mutation ingestFiles(
    $emailSubject: String
    $message: String
    $companyId: UUID!
    $files: [GrapheneInputFile!]!
    $batchId: UUID!
  ) {
    ingestFiles(
      emailSubject: $emailSubject
      message: $message
      companyId: $companyId
      files: $files
      batchId: $batchId
    ) {
      ok
    }
  }
`

export const INGEST_AUTOFILL_FILES = gql`
  mutation ingestAutofillFiles($jobId: UUID!, $files: [GrapheneInputFile!]!, $batchId: UUID!) {
    ingestAutofillFiles(jobId: $jobId, files: $files, batchId: $batchId) {
      ok
    }
  }
`

export const TIFF_TO_PNG = gql`
  mutation tiffToPng($imageBase64Data: String!) {
    tiffToPng(imageBase64Data: $imageBase64Data) {
      images
      numPages
      sizes
    }
  }
`

export const UPLOAD_ORIGINAL_PDFS_TO_S3 = gql`
  mutation uploadOriginalPdfsToS3($jobId: UUID!, $files: [OriginalInputPDF!]!) {
    uploadOriginalPdfsToS3(jobId: $jobId, files: $files) {
      files {
        filename
        s3Uri
      }
    }
  }
`
