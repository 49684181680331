import { useEffect, useMemo, useState } from 'react'
import { createEditor, Editor, Element, Transforms } from 'slate'
import { ReactEditor, withReact } from 'slate-react'
import { useSnackbar } from 'notistack'
import { useGetFileSignedUrlLazyQuery } from '@src/graphql/types'
import { formatMaybeApolloError } from '@src/utils/errors'
import { SlateAttachment } from '@src/components/job-viewer/NoteFormAttachments'

export enum SlateMark {
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINED = 'underlined',
}

// Higher order function for adding block-level functions
const withBlocks = (editor: ReactEditor): ReactEditor => {
  const isMarkActive = (mark: SlateMark): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return Editor.marks(editor)?.[mark]
  }
  editor.isMarkActive = isMarkActive

  editor.toggleMark = (mark: SlateMark): void => {
    if (isMarkActive(mark)) {
      Editor.removeMark(editor, mark)
    } else {
      Editor.addMark(editor, mark, true)
    }
  }

  return editor
}

/**
 * Higher order function for adding file-related functionalities
 * @param editor ReactEditor instance
 * @param inlineFiles flags to determine whether files will be uploaded inline or managed externally.
 * @param onFileUpload callback triggered on file upload for externally managed files.
 */
const withFile = (
  editor: ReactEditor,
  inlineFiles: boolean,
  onFileUpload?: (file: SlateAttachment) => unknown,
): CustomSlateEditor => {
  // disable the naming convention rule which doesn't allow hooks to be called
  // inside functions that are not prefixed with "use" or isn't capitalized
  /* eslint-disable react-hooks/rules-of-hooks */
  const { enqueueSnackbar } = useSnackbar()
  const [file, setFile] = useState(null as File | null)
  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const { insertData, isVoid } = editor

  editor.isVoid = (element: Element): boolean => {
    return ['image', 'file'].includes(element.type as string) || isVoid(element)
  }

  editor.uploadAndInsertFile = setFile
  editor.isUploadingImage = (): boolean => isUploadingImage
  editor.isUploadingFile = (): boolean => isUploadingFile

  editor.insertData = (data: DataTransfer): void => {
    if (data.types.includes('Files')) {
      const imageFile = data.files?.[0]
      if (imageFile) {
        setFile(imageFile)
        return
      }
    }
    insertData(data)
  }

  // convert all non-image mime types to downloadable file
  const convertMimeType = (mimeType: string): string => {
    let contentType = mimeType
    if (contentType === 'image/jpg') {
      contentType = 'image/jpeg'
    } else if (!contentType.includes('image')) {
      contentType = 'application/octet-stream'
    }
    return contentType
  }

  const uploadFileToBucket = async (signedUrl: string): Promise<Response> => {
    const contentType = convertMimeType(file!.type)
    return fetch(signedUrl, {
      method: 'PUT',
      headers: { 'Content-Type': contentType },
      body: file,
    })
  }

  const insertLoadingNode = (): void => {
    const loadingNode = {
      type: 'loading',
      children: [{ text: '' }],
    }
    Transforms.insertNodes(editor, [loadingNode])
  }

  const insertFileNode = (
    filename: string,
    url: string,
    nodeType: 'file' | 'image' = 'file',
  ): void => {
    const fileNode = {
      url,
      type: nodeType,
      caption: filename,
      children: [{ text: '' }],
    }
    // insert empty paragraph block after the file/image element to allow appending text
    const emptyNode = { type: 'paragraph', children: [{ text: '' }] }
    if (onFileUpload) onFileUpload(fileNode)
    else Transforms.insertNodes(editor, [fileNode, emptyNode])
  }

  const [getFileSignedUrl] = useGetFileSignedUrlLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: async ({ fileSignedUrl }) => {
      const nodeType = file!.type.includes('image') ? 'image' : 'file'
      try {
        if (inlineFiles) insertLoadingNode()

        await uploadFileToBucket(fileSignedUrl.putUrl)
        insertFileNode(file!.name, fileSignedUrl.viewUrl, nodeType)
      } catch (error) {
        enqueueSnackbar(`There was an error uploading the file: ${formatMaybeApolloError(error)}`, {
          variant: 'error',
        })
      } finally {
        if (inlineFiles) {
          Transforms.removeNodes(editor, {
            match: (node) => node.type === 'loading',
            mode: 'highest',
          })
        }

        setFile(null)
        if (nodeType === 'image') {
          setIsUploadingImage(false)
        } else {
          setIsUploadingFile(false)
        }
      }
    },
  })

  useEffect(() => {
    const getUrl = async (): Promise<void> => {
      if (file) {
        const contentType = convertMimeType(file.type)
        if (contentType.includes('image')) {
          setIsUploadingImage(true)
        } else {
          setIsUploadingFile(true)
        }
        try {
          await getFileSignedUrl({
            variables: {
              filename: file.name,
              contentType,
            },
          })
        } catch (e) {
          enqueueSnackbar(`Failed to get file url: ${formatMaybeApolloError(e)}`, {
            variant: 'error',
          })
        }
      }
    }
    void getUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  // FIXME: Improve the type-safety and inference of this function
  return editor as CustomSlateEditor
}

export type SlateLeaf = {
  text: string
}

export type SlateContent = {
  type: string
  url?: string
  caption?: string
  children: SlateLeaf[]
}

export type CustomSlateEditorHookType = {
  editor: CustomSlateEditor
  slateValue: SlateContent[]
  setSlateValue: (newSlateValue: SlateContent[]) => void
  resetSlateEditor: () => void
  files: SlateAttachment[]
  removeFile: (url: string) => void
}

export type CustomSlateEditor = ReactEditor & {
  isMarkActive: (mark: SlateMark) => boolean
  toggleMark: (mark: SlateMark) => void
  isUploadingImage: () => boolean
  isUploadingFile: () => boolean
  uploadAndInsertFile: (file: File) => void
}

const defaultContent = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]

/**
 * @param initialContent initial content of the editor
 * @param inlineFiles flag whether to insert files as inline. If false, uploadedfiles are stored in a separate
 *   state, and it's the user's responsibility to handle the files.
 */
const useSlateEditor = (
  initialContent?: SlateContent[],
  inlineFiles = true,
): CustomSlateEditorHookType => {
  // we force editor to be remounted due to a fast-refresh bug on dev
  // @refresh reset
  const editor = useMemo(() => withReact(createEditor()), [])
  const [slateValue, setSlateValue] = useState(initialContent || defaultContent)
  const [files, setFiles] = useState<SlateAttachment[]>([])

  const resetSlateEditor = (): void => {
    Transforms.deselect(editor)
    setSlateValue(defaultContent)
    setFiles([])
  }

  const onFileUpload = (file: SlateAttachment): void => {
    setFiles((files) => [...files, file])
  }

  const removeFile = (url: string): void => {
    setFiles(files.filter((file) => file.url !== url))
  }

  return {
    editor: withFile(withBlocks(editor), inlineFiles, inlineFiles ? undefined : onFileUpload),
    slateValue,
    setSlateValue,
    resetSlateEditor,
    files,
    removeFile,
  }
}

export default useSlateEditor
