/**
 * Aligned with Order enums in /dashboard
 *
 * Represents the sorting orders that can be applied to data sorting functions.
 * @enum {string}
 */
export enum Order {
  Ascending = 'asc',
  Descending = 'desc',
}
