import { FunctionComponent, useState } from 'react'
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  IconButton,
  Button,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import RestoreIcon from '@material-ui/icons/Restore'
import { Mutation, MutationDeleteUserArgs, UserList } from '@src/graphql/types'
import { AdminUserProfileNode } from '@src/graphql/types'
import { useDialog } from 'muibox'
import { DELETE_USER } from '@src/graphql/mutations/profile'
import { useMutation } from '@apollo/client'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import UpdateUserDialog from './UpdateUserDialog'
import ResetPasswordDialog from './ResetPasswordDialog'
import CopyDashboardAccessDialog from '@src/pages/admin/user/CopyDashboardAccessDialog'

type Props = {
  userList: UserList
  onPageChange: (page: number) => void
  pageNumber: number
  refetchUsers: () => Promise<unknown>
  isExpedockAdmin: boolean
}

const AdminUserList: FunctionComponent<Props> = ({
  userList,
  onPageChange,
  pageNumber,
  refetchUsers,
  isExpedockAdmin,
}) => {
  const numPages = Math.ceil(userList.total / userList.limit)
  const wrappedOnPageChange = (_event: unknown, value: number): void => onPageChange(value)
  const dialog = useDialog()
  const [isProcessing, setIsProcessing] = useState(false)
  const [deleteUser] = useMutation<Pick<Mutation, 'deleteUser'>, MutationDeleteUserArgs>(
    DELETE_USER,
  )
  const [userToUpdate, setUserToUpdate] = useState<AdminUserProfileNode | undefined>()
  const [userToReset, setUserToReset] = useState<AdminUserProfileNode | undefined>()
  const [userToCopy, setUserToCopy] = useState<AdminUserProfileNode | undefined>()

  const onDelete = async (user: AdminUserProfileNode): Promise<void> => {
    try {
      await dialog.confirm({
        message: `Are you sure you want to delete ${user.email}?`,
        ok: { text: 'Delete' },
      })
    } catch (e) {
      return
    }
    setIsProcessing(true)
    try {
      await deleteUser({ variables: { auth0Id: user.auth0Id } })
      await refetchUsers()
    } finally {
      setIsProcessing(false)
    }
  }
  if (isProcessing) {
    return <CenteredCircularProgress />
  }
  return (
    <>
      <Pagination count={numPages} page={pageNumber} onChange={wrappedOnPageChange} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {isExpedockAdmin && <TableCell />}
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Login Method</TableCell>
              <TableCell>
                <abbr title='The company you can access the dashboard of on dashboard.expedock.com'>
                  Dashboard Company
                </abbr>
              </TableCell>
              <TableCell>
                <abbr title='Companies you can list/make jobs for'>Operator Companies</abbr>
              </TableCell>
              <TableCell>Roles</TableCell>
              <TableCell>Metabase Dashboard ID</TableCell>
              <TableCell>Last Login</TableCell>
              <TableCell>Date Created</TableCell>
              {isExpedockAdmin && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.users.map((user) => {
              const isGoogle = user.auth0Id.startsWith('google')
              return (
                <TableRow key={user.auth0Id}>
                  {isExpedockAdmin && (
                    <TableCell>
                      <IconButton
                        aria-label='edit'
                        size='small'
                        onClick={() => setUserToUpdate(user)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label='delete' size='small' onClick={() => onDelete(user)}>
                        <DeleteOutlineIcon />
                      </IconButton>
                      <IconButton
                        aria-label='delete'
                        size='small'
                        title={`Reset Password${
                          isGoogle ? ' (only available for Password Login)' : ''
                        }`}
                        disabled={isGoogle}
                        onClick={() => setUserToReset(user)}
                      >
                        <RestoreIcon />
                      </IconButton>
                    </TableCell>
                  )}
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>
                    {user.auth0Id.startsWith('auth0') ? 'Password' : 'Google Signin'}
                  </TableCell>
                  <TableCell>{user.company ? user.company?.name : 'N/A'}</TableCell>
                  <TableCell>{user.companies.map((company) => company.name!).join(', ')}</TableCell>
                  <TableCell>{user.roles.join(', ')}</TableCell>
                  <TableCell>{user.metabaseDashboardId}</TableCell>
                  <TableCell>{user.lastLogin}</TableCell>
                  <TableCell>{user.dateCreated}</TableCell>
                  {isExpedockAdmin && (
                    <TableCell>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setUserToCopy(user)}
                      >
                        Copy Access
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={numPages} page={pageNumber} onChange={wrappedOnPageChange} />
      {userToUpdate && (
        <UpdateUserDialog
          isOpen={setUserToUpdate != null}
          close={() => setUserToUpdate(undefined)}
          refetchUsers={refetchUsers}
          user={userToUpdate}
          isExpedockAdmin={isExpedockAdmin}
        />
      )}
      {userToReset && (
        <ResetPasswordDialog
          isOpen={setUserToReset != null}
          close={() => setUserToReset(undefined)}
          user={userToReset}
        />
      )}
      {userToCopy && (
        <CopyDashboardAccessDialog
          isExpedockAdmin={isExpedockAdmin}
          user={userToCopy}
          onClose={() => {
            void refetchUsers()
            setUserToCopy(undefined)
          }}
        />
      )}
    </>
  )
}

export default AdminUserList
