import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useEffect, useState, useContext, useMemo, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'

import {
  EDocumentNode,
  Query,
  EDocsDataTargetType,
  FilePageNode,
  QueryEDocumentsArgs,
  QueryFindChainIoMatchesFromSoaLineItemsArgs,
  Mutation,
  MutationBatchPullEDocsFromCwArgs,
  Maybe,
  MutationBulkDeleteEDocsArgs,
  QueryPullEDocsBatchArgs,
} from '@src/graphql/types'
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client'

import {
  FIND_SOA_SHIPMENTS_FROM_LINE_ITEMS,
  GET_PULL_EDOCS_BATCH,
} from '@src/graphql/queries/cargowise'
import { useSelector } from 'react-redux'
import { RootState } from '@src/utils/store'
import { selectActiveDocument } from '@src/redux-features/document_editor/document'
import { jobTableLineItemSelectors } from '@src/redux-features/document_editor/job_table'
import { transformLineItems } from '@src/utils/line_items'
import theme from '@src/utils/theme'
import BatchUploadModal from '../batch-upload-modal/BatchUploadModal'
import {
  BATCH_PULL_EDOCS_FROM_CARGOWISE,
  BULK_DELETE_EDOCS,
} from '@src/graphql/mutations/cargowise'
import { JOB_NOTES } from '@src/graphql/queries/note'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { ShipmentFormContext } from '@src/contexts/shipment_form_context'
import { groupBy, uniqBy, map } from 'lodash'
import { getSoaInvoiceEDocsInfoSkeleton } from '@src/utils/edocs'
import SoaEDocTable from './SoaEDocTable'
import { LogEventType } from '@src/utils/observability/LogEventType'
import { useDialog } from 'muibox'
import { Alert, AlertTitle } from '@material-ui/lab'

export interface SoaShipmentEDocsInfo {
  chainIoShipmentId: string
  shipmentNum: string
  eDocs: EDocumentNode[]
  checked: boolean
}

export interface SoaInvoiceEDocsInfo {
  invoiceNum: string
  eDocs: EDocumentNode[]
  checked: boolean
}

export interface SoaConsolEDocsInfo {
  chainIoConsolId: string
  consolNum: string
  eDocs: EDocumentNode[]
  checked: boolean
}

export interface SoaCustomsDecEDocsInfo {
  chainIoCustomsDecId: string
  customsDecNum: string
  eDocs: EDocumentNode[]
  checked: boolean
}

const BATCH_PULL_DATA_POLLING_TIME = 2000

const useStyles = makeStyles({
  button: {
    margin: theme.spacing(2),
  },
})

type Props = {
  eDocuments: EDocumentNode[]
  jobId: string
  filePages: FilePageNode[]
  refetchEDocs: (
    variables?: Partial<QueryEDocumentsArgs> | undefined,
  ) => Promise<ApolloQueryResult<Pick<Query, 'eDocuments'>>>
  selectEDocHandler: (eDocument: Maybe<EDocumentNode>) => void
  deleteEdocHandler: (eDocumentId: string) => Promise<void>
  deleteEdocLoading: boolean
}

const BatchPullTable: FunctionComponent<Props> = ({
  eDocuments,
  jobId,
  filePages,
  refetchEDocs,
  selectEDocHandler,
  deleteEdocHandler,
  deleteEdocLoading,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { saveJob } = useContext(ShipmentFormContext)
  const { logEvent } = useEventLogger()
  const actionStart = useRef<Date>()
  const activeDocument = useSelector(
    (state: RootState) => selectActiveDocument(state.documentEditor)!,
  )
  const lineItems = useSelector(
    (state: RootState) => jobTableLineItemSelectors.selectAll(state.documentEditor)!,
  )
  const [selectedDataTargetType, setSelectedDataTargetType] = useState(EDocsDataTargetType.Shipment)
  const [isBatchPullingInProgress, setBatchPullingInProgress] = useState(false)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [allShipmentsSelected, setAllShipmentsSelected] = useState(false)
  const [allInvoicesSelected, setAllInvoicesSelected] = useState(false)
  const [allConsolsSelected, setAllConsolsSelected] = useState(false)
  const [allCustomsDecSelected, setAllCustomsDecSelected] = useState(false)
  const [soaShipmentEDocs, setSoaShipmentEDocs] = useState([] as SoaShipmentEDocsInfo[])
  const [selectedShipments, setSelectedShipments] = useState([] as SoaShipmentEDocsInfo[])
  const [soaInvoiceEDocs, setSoaInvoiceEDocs] = useState(
    getSoaInvoiceEDocsInfoSkeleton(lineItems, activeDocument) as SoaInvoiceEDocsInfo[],
  )
  const [selectedInvoices, setSelectedInvoices] = useState([] as SoaInvoiceEDocsInfo[])
  const [soaConsolEDocs, setSoaConsolEDocs] = useState([] as SoaConsolEDocsInfo[])
  const [selectedConsols, setSelectedConsols] = useState([] as SoaConsolEDocsInfo[])
  const [soaCustomsDecEDocs, setSoaCustomsDecEDocs] = useState([] as SoaCustomsDecEDocsInfo[])
  const [selectedCustomsDecs, setSelectedCustomsDecs] = useState([] as SoaCustomsDecEDocsInfo[])

  const groupedEDocs = useMemo(() => {
    return groupBy(eDocuments, 'dataTargetType.value')
  }, [eDocuments])

  const shipmentEDocs = groupedEDocs[EDocsDataTargetType.Shipment] ?? []
  const APInvoiceEDocs = groupedEDocs[EDocsDataTargetType.ApInvoice] ?? []
  const consolEDocs = groupedEDocs[EDocsDataTargetType.Consol] ?? []
  const customsDecEDocs = groupedEDocs[EDocsDataTargetType.CustomsDeclaration] ?? []

  const { data: findSoaModelsData, loading: findSoaModelsLoading } = useQuery<
    Pick<Query, 'findChainIoMatchesFromSoaLineItems'>,
    QueryFindChainIoMatchesFromSoaLineItemsArgs
  >(FIND_SOA_SHIPMENTS_FROM_LINE_ITEMS, {
    variables: { jobId, inputSoaLineItems: transformLineItems(lineItems, activeDocument) },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const foundSoaShipmentsFromLineItems =
        uniqBy(
          findSoaModelsData?.findChainIoMatchesFromSoaLineItems.chainIoShipments,
          (chainShipment) => chainShipment.id as string,
        ) || []
      const foundSoaConsolsFromLineItems =
        uniqBy(
          findSoaModelsData?.findChainIoMatchesFromSoaLineItems.chainIoConsolidations,
          (chainConsol) => chainConsol.id as string,
        ) || []
      const foundSoaCustomsDeclarationsFromLineItems =
        uniqBy(
          findSoaModelsData?.findChainIoMatchesFromSoaLineItems.chainIoCustomsDeclarations,
          (chainCustomsDec) => chainCustomsDec.id as string,
        ) || []
      if (foundSoaShipmentsFromLineItems.length) {
        setSoaShipmentEDocs(
          foundSoaShipmentsFromLineItems.map((shipment) => {
            const eDocsToAttach = shipmentEDocs.filter(
              (eDoc) => eDoc.referenceNumber === shipment.forwarderReference,
            )
            return {
              chainIoShipmentId: shipment.id,
              shipmentNum: shipment.forwarderReference,
              eDocs: eDocsToAttach || [],
              checked: true,
            }
          }) as SoaShipmentEDocsInfo[],
        )
      }
      if (foundSoaConsolsFromLineItems.length) {
        setSoaConsolEDocs(
          foundSoaConsolsFromLineItems.map((consol) => {
            const eDocsToAttach = consolEDocs.filter(
              (eDoc) => eDoc.referenceNumber === consol.forwarderReference,
            )
            return {
              chainIoConsolId: consol.id,
              consolNum: consol.forwarderReference,
              eDocs: eDocsToAttach || [],
              checked: true,
            }
          }) as SoaConsolEDocsInfo[],
        )
      }
      if (foundSoaCustomsDeclarationsFromLineItems.length) {
        setSoaCustomsDecEDocs(
          foundSoaCustomsDeclarationsFromLineItems.map((customsDec) => {
            const eDocsToAttach = customsDecEDocs.filter(
              (eDoc) => eDoc.referenceNumber === customsDec.forwarderReference,
            )
            return {
              chainIoCustomsDecId: customsDec.id,
              customsDecNum: customsDec.forwarderReference,
              eDocs: eDocsToAttach || [],
              checked: true,
            }
          }) as SoaCustomsDecEDocsInfo[],
        )
      }
      if (
        foundSoaShipmentsFromLineItems.length ||
        foundSoaConsolsFromLineItems.length ||
        foundSoaCustomsDeclarationsFromLineItems.length
      ) {
        setSoaInvoiceEDocs(getSoaInvoiceEDocsInfoSkeleton(lineItems, activeDocument))
      }
    },
    onError: (error) => {
      enqueueSnackbar(
        `Error finding ChainIO shipments/consols/customs declarations: ${formatMaybeApolloError(
          error,
        )}`,
        { variant: 'error' },
      )
    },
  })

  const [batchPullId, setBatchPullId] = useState('')

  const getTotalPulled = (): number => {
    if (selectedDataTargetType === EDocsDataTargetType.Shipment) {
      return selectedShipments.length
    } else if (selectedDataTargetType === EDocsDataTargetType.ApInvoice) {
      return selectedInvoices.length
    } else if (selectedDataTargetType === EDocsDataTargetType.Consol) {
      return selectedConsols.length
    } else if (selectedDataTargetType === EDocsDataTargetType.CustomsDeclaration) {
      return selectedCustomsDecs.length
    }
    return 0
  }
  const {
    data: getPullEDocsBatchData,
    startPolling: startGetPullEDocsBatchPolling,
    stopPolling: stopGetPullEDocsBatchPolling,
  } = useQuery<Pick<Query, 'pullEDocsBatch'>, QueryPullEDocsBatchArgs>(GET_PULL_EDOCS_BATCH, {
    skip: !batchPullId,
    variables: {
      batchPullId: batchPullId!,
      totalPulled: getTotalPulled(),
    },
    fetchPolicy: 'network-only',
  })

  const [batchPullEDocsFromCw] = useMutation<
    Pick<Mutation, 'batchPullEDocsFromCw'>,
    MutationBatchPullEDocsFromCwArgs
  >(BATCH_PULL_EDOCS_FROM_CARGOWISE, {
    refetchQueries: [{ query: JOB_NOTES, variables: { jobId: jobId } }],
    onCompleted: (data) => {
      setBatchPullId(data.batchPullEDocsFromCw?.batchPullId)
      startGetPullEDocsBatchPolling(BATCH_PULL_DATA_POLLING_TIME)
    },
  })

  const dialog = useDialog()

  useEffect(() => {
    void refetchEDocs()
    const batchPullData = getPullEDocsBatchData?.pullEDocsBatch
    if (batchPullData && batchPullData.batchPullId === batchPullId) {
      if (batchPullData.done) {
        const { successes, failures, exceptions } = batchPullData
        const success = true
        let errorMessage = ''
        stopGetPullEDocsBatchPolling()
        setBatchPullingInProgress(false)
        if (successes!.length) {
          enqueueSnackbar(`Successfully pulled CargoWise eDocs for: ${successes!.join(', ')}`, {
            variant: 'success',
          })
        }
        if (!exceptions!.length && failures!.length) {
          // Will prioritize exceptions
          errorMessage = `No CargoWise eDocs pulled for: ${failures!.join(', ')}`
          enqueueSnackbar(errorMessage, {
            variant: 'error',
          })
        }
        if (exceptions!.length) {
          errorMessage = `The server encountered an error, please contact an administrator for this issue.`
          void dialog.alert({
            title: 'Error while pulling eDocs',
            message: (
              <Alert severity='error'>
                <AlertTitle>
                  The server encountered an error, please contact an administrator for this issue.
                </AlertTitle>
                {exceptions.map((exc, idx) => (
                  <>
                    <Divider />
                    <Typography variant='caption' key={idx}>
                      {exc}
                    </Typography>
                  </>
                ))}
              </Alert>
            ),
          })
        }
        setBatchPullId('')

        void logEvent(LogEventType.EDOCS_PULL, {
          success,
          job_id: jobId,
          action_start: actionStart.current,
          action_end: new Date(),
          error_message: errorMessage,
        })
      }
    }
    // we don't want to depend on the analytics only variables
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    batchPullId,
    enqueueSnackbar,
    getPullEDocsBatchData,
    refetchEDocs,
    stopGetPullEDocsBatchPolling,
  ])

  const [bulkDeleteEDocs, { loading: bulkDeleteEDocsLoading }] = useMutation<
    Pick<Mutation, 'bulkDeleteEDocs'>,
    MutationBulkDeleteEDocsArgs
  >(BULK_DELETE_EDOCS, {
    onCompleted: async () => {
      await refetchEDocs()
      enqueueSnackbar('Successfully deleted eDocuments', { variant: 'success' })
    },
    onError: (error) => {
      enqueueSnackbar(
        `Encountered an error while deleting eDocs: ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    },
  })

  useEffect(() => {
    const selectedShipmentEDocs = soaShipmentEDocs.filter((shipment) => shipment.checked)
    setSelectedShipments(selectedShipmentEDocs)
    setAllShipmentsSelected(selectedShipmentEDocs.length === soaShipmentEDocs.length)
  }, [soaShipmentEDocs])

  useEffect(() => {
    const selectedInvoiceEDocs = soaInvoiceEDocs.filter((invoice) => invoice.checked)
    setSelectedInvoices(selectedInvoiceEDocs)
    setAllInvoicesSelected(selectedInvoiceEDocs.length === soaInvoiceEDocs.length)
  }, [soaInvoiceEDocs])

  useEffect(() => {
    const selectedConsolEDocs = soaConsolEDocs.filter((consol) => consol.checked)
    setSelectedConsols(selectedConsolEDocs)
    setAllConsolsSelected(selectedConsolEDocs.length === soaConsolEDocs.length)
  }, [soaConsolEDocs])

  useEffect(() => {
    const selectedCustomsDecEDocs = soaCustomsDecEDocs.filter((customsDec) => customsDec.checked)
    setSelectedCustomsDecs(selectedCustomsDecEDocs)
    setAllCustomsDecSelected(selectedCustomsDecEDocs.length === soaCustomsDecEDocs.length)
  }, [soaCustomsDecEDocs])

  const batchPullEDocs = async (dataTargetType: EDocsDataTargetType): Promise<void> => {
    let success = false
    let errorMessage = ''
    try {
      const selectedShipmentIds = map(selectedShipments, 'chainIoShipmentId')
      const selectedInvoiceNums = map(selectedInvoices, 'invoiceNum')
      const selectedConsolIds = map(selectedConsols, 'chainIoConsolId')
      const selectedCustomsDecIds = map(selectedCustomsDecs, 'chainIoCustomsDecId')
      await batchPullEDocsFromCw({
        variables: {
          jobId,
          dataTargetType,
          chainIoShipmentIds:
            dataTargetType === EDocsDataTargetType.Shipment ? selectedShipmentIds : null,
          invoiceNumbers:
            dataTargetType === EDocsDataTargetType.ApInvoice ? selectedInvoiceNums : null,
          chainIoConsolIds:
            dataTargetType === EDocsDataTargetType.Consol ? selectedConsolIds : null,
          chainIoCustomsDecIds:
            dataTargetType === EDocsDataTargetType.CustomsDeclaration
              ? selectedCustomsDecIds
              : null,
        },
      })

      success = true
    } catch (error) {
      errorMessage = `Encountered an error while pulling eDocs from CargoWise: ${formatMaybeApolloError(
        error,
      )}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
      setBatchPullingInProgress(false)

      void logEvent(LogEventType.EDOCS_PULL, {
        success,
        job_id: jobId,
        action_start: actionStart.current,
        action_end: new Date(),
        error_message: errorMessage,
      })
    }
  }

  useEffect(() => {
    setSoaShipmentEDocs(
      soaShipmentEDocs.map((shipment) => {
        const eDocsToAttach = shipmentEDocs.filter(
          (eDoc) => eDoc.referenceNumber === shipment.shipmentNum,
        )
        return {
          ...shipment,
          eDocs: eDocsToAttach || shipment.eDocs,
        }
      }) as SoaShipmentEDocsInfo[],
    )
    setSoaInvoiceEDocs(
      soaInvoiceEDocs.map((invoice) => {
        const eDocsToAttach = APInvoiceEDocs.filter(
          (eDoc) => eDoc.referenceNumber === invoice.invoiceNum,
        )
        return {
          ...invoice,
          eDocs: eDocsToAttach || invoice.eDocs,
        }
      }) as SoaInvoiceEDocsInfo[],
    )
    setSoaConsolEDocs(
      soaConsolEDocs.map((consol) => {
        const eDocsToAttach = consolEDocs.filter(
          (eDoc) => eDoc.referenceNumber === consol.consolNum,
        )
        return {
          ...consol,
          eDocs: eDocsToAttach || consol.eDocs,
        }
      }) as SoaConsolEDocsInfo[],
    )
    setSoaCustomsDecEDocs(
      soaCustomsDecEDocs.map((customsDec) => {
        const eDocsToAttach = customsDecEDocs.filter(
          (eDoc) => eDoc.referenceNumber === customsDec.customsDecNum,
        )
        return {
          ...customsDec,
          eDocs: eDocsToAttach || customsDec.eDocs,
        }
      }) as SoaCustomsDecEDocsInfo[],
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eDocuments])

  const handleBatchPullEDocs = async (dataTargetType: EDocsDataTargetType): Promise<void> => {
    actionStart.current = new Date()
    setSelectedDataTargetType(dataTargetType)
    try {
      setBatchPullingInProgress(true)
      await saveJob()
      if (dataTargetType === EDocsDataTargetType.Shipment) {
        if (soaShipmentEDocs.length) {
          enqueueSnackbar(
            `Pulling ${dataTargetType} eDocs from Cargowise for ${selectedShipments.length} shipments`,
            { variant: 'info' },
          )
        } else {
          throw new Error('No shipments found in CargoWise for given line items.')
        }
      } else if (dataTargetType === EDocsDataTargetType.ApInvoice) {
        if (soaInvoiceEDocs.length) {
          enqueueSnackbar(
            `Pulling ${dataTargetType} eDocs from Cargowise for ${selectedInvoices.length} invoices`,
            { variant: 'info' },
          )
        } else {
          throw new Error('No invoices found for given line items.')
        }
      } else if (dataTargetType === EDocsDataTargetType.Consol) {
        if (soaConsolEDocs.length) {
          enqueueSnackbar(
            `Pulling ${dataTargetType} eDocs from Cargowise for ${selectedConsols.length} consols`,
            { variant: 'info' },
          )
        } else {
          throw new Error('No consols found for given line items.')
        }
      } else if (dataTargetType === EDocsDataTargetType.CustomsDeclaration) {
        if (soaCustomsDecEDocs.length) {
          enqueueSnackbar(
            `Pulling ${dataTargetType} eDocs from Cargowise for ${selectedCustomsDecs.length} customs declarations`,
            { variant: 'info' },
          )
        } else {
          throw new Error('No customs declarations found for given line items.')
        }
      }

      await batchPullEDocs(dataTargetType)
    } catch (error) {
      const errorMessage = `Encountered an error while pulling eDocs from CargoWise: ${formatMaybeApolloError(
        error,
      )}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const handleDeleteSelectedEDocs = async (targetType: EDocsDataTargetType): Promise<void> => {
    let eDocsToDelete: (
      | SoaShipmentEDocsInfo
      | SoaInvoiceEDocsInfo
      | SoaConsolEDocsInfo
      | SoaCustomsDecEDocsInfo
    )[] = []
    if (targetType === EDocsDataTargetType.Shipment) {
      eDocsToDelete = soaShipmentEDocs
    } else if (targetType === EDocsDataTargetType.ApInvoice) {
      eDocsToDelete = soaInvoiceEDocs
    } else if (targetType === EDocsDataTargetType.Consol) {
      eDocsToDelete = soaConsolEDocs
    } else if (targetType === EDocsDataTargetType.CustomsDeclaration) {
      eDocsToDelete = soaCustomsDecEDocs
    }
    const eDocumentIds = eDocsToDelete
      .filter((eDocsInfo) => eDocsInfo.checked)
      .flatMap((eDocsInfo) => eDocsInfo.eDocs)
      .map((eDoc) => eDoc.id)
    setBatchPullId('')
    await bulkDeleteEDocs({ variables: { eDocumentIds } })
  }

  const handleSelect = (
    soaGroup:
      | SoaShipmentEDocsInfo
      | SoaInvoiceEDocsInfo
      | SoaConsolEDocsInfo
      | SoaCustomsDecEDocsInfo,
    targetType: EDocsDataTargetType,
  ): void => {
    if (targetType === EDocsDataTargetType.Shipment) {
      setSoaShipmentEDocs(
        soaShipmentEDocs.map((ship) =>
          ship.shipmentNum === (soaGroup as SoaShipmentEDocsInfo).shipmentNum
            ? { ...ship, checked: !soaGroup.checked }
            : ship,
        ),
      )
    } else if (targetType === EDocsDataTargetType.ApInvoice) {
      setSoaInvoiceEDocs(
        soaInvoiceEDocs.map((invoice) =>
          invoice.invoiceNum === (soaGroup as SoaInvoiceEDocsInfo).invoiceNum
            ? { ...invoice, checked: !soaGroup.checked }
            : invoice,
        ),
      )
    } else if (targetType === EDocsDataTargetType.Consol) {
      setSoaConsolEDocs(
        soaConsolEDocs.map((consol) =>
          consol.consolNum === (soaGroup as SoaConsolEDocsInfo).consolNum
            ? { ...consol, checked: !soaGroup.checked }
            : consol,
        ),
      )
    } else if (targetType === EDocsDataTargetType.CustomsDeclaration) {
      setSoaCustomsDecEDocs(
        soaCustomsDecEDocs.map((customsDec) =>
          customsDec.customsDecNum === (soaGroup as SoaCustomsDecEDocsInfo).customsDecNum
            ? { ...customsDec, checked: !soaGroup.checked }
            : customsDec,
        ),
      )
    }
  }

  const handleSelectAll = (targetType: EDocsDataTargetType): void => {
    if (targetType === EDocsDataTargetType.Shipment) {
      const newSoaShipmentEDocs = soaShipmentEDocs.map((group) => ({
        ...group,
        checked: !allShipmentsSelected,
      }))
      setSoaShipmentEDocs(newSoaShipmentEDocs)
      setAllShipmentsSelected(!allShipmentsSelected)
    } else if (targetType === EDocsDataTargetType.ApInvoice) {
      const newSoaInvoiceEDocs = soaInvoiceEDocs.map((group) => ({
        ...group,
        checked: !allInvoicesSelected,
      }))
      setSoaInvoiceEDocs(newSoaInvoiceEDocs)
      setAllInvoicesSelected(!allInvoicesSelected)
    } else if (targetType === EDocsDataTargetType.Consol) {
      const newSoaConsolEDocs = soaConsolEDocs.map((group) => ({
        ...group,
        checked: !allConsolsSelected,
      }))
      setSoaConsolEDocs(newSoaConsolEDocs)
      setAllConsolsSelected(!allConsolsSelected)
    } else if (targetType === EDocsDataTargetType.CustomsDeclaration) {
      const newSoaCustomsDecEDocs = soaCustomsDecEDocs.map((group) => ({
        ...group,
        checked: !allCustomsDecSelected,
      }))
      setSoaCustomsDecEDocs(newSoaCustomsDecEDocs)
      setAllCustomsDecSelected(!allCustomsDecSelected)
    }
  }

  const isSoaEDocsDataLoading =
    isBatchPullingInProgress || findSoaModelsLoading || bulkDeleteEDocsLoading

  const isSoaShipmentButtonsDisabled = isSoaEDocsDataLoading || !selectedShipments.length
  const isSoaInvoiceButtonsDisabled = isSoaEDocsDataLoading || !selectedInvoices.length
  const isSoaConsolButtonsDisabled = isSoaEDocsDataLoading || !selectedConsols.length
  const isSoaCustomsDecButtonsDisabled = isSoaEDocsDataLoading || !selectedCustomsDecs.length

  const getSelectedNums = (): string[] => {
    if (selectedDataTargetType === EDocsDataTargetType.Shipment) {
      return selectedShipments.map((shipment) => shipment.shipmentNum)
    } else if (selectedDataTargetType === EDocsDataTargetType.ApInvoice) {
      return selectedInvoices.map((invoice) => invoice.invoiceNum)
    } else if (selectedDataTargetType === EDocsDataTargetType.Consol) {
      return selectedConsols.map((consol) => consol.consolNum)
    } else if (selectedDataTargetType === EDocsDataTargetType.CustomsDeclaration) {
      return selectedCustomsDecs.map((customsDec) => customsDec.customsDecNum)
    }
    return []
  }

  return (
    <>
      <Typography variant='h4' gutterBottom>
        Shipment eDocs
      </Typography>
      {findSoaModelsLoading && (
        <Typography variant='caption'>
          <CircularProgress /> Finding shipments...
        </Typography>
      )}
      {soaShipmentEDocs.length > 0 ? (
        <SoaEDocTable
          soaEDocs={soaShipmentEDocs}
          handleSelect={handleSelect}
          selectEDocHandler={selectEDocHandler}
          deleteEdocHandler={deleteEdocHandler}
          deleteEdocLoading={deleteEdocLoading}
          // why the hell do we not have consistent casing for Edocs/EDocs
          bulkDeleteEdocsLoading={bulkDeleteEDocsLoading}
          allSelected={allShipmentsSelected}
          selected={selectedShipments}
          handleSelectAll={handleSelectAll}
          dataTargetType={EDocsDataTargetType.Shipment}
        />
      ) : (
        !findSoaModelsLoading && (
          <Typography color='error' data-testid={'soa-shipment-edoc-table'}>
            No shipments were found.
          </Typography>
        )
      )}
      {isBatchPullingInProgress && selectedDataTargetType === EDocsDataTargetType.Shipment && (
        <Box>
          <Box>
            <LinearProgress />
          </Box>
          <Typography variant='caption' align='left'>
            Pulling eDocuments from SOA shipments...
          </Typography>
          {getPullEDocsBatchData?.pullEDocsBatch.batchPullId === batchPullId && (
            <Typography variant='caption' align='right'>{`${
              (getPullEDocsBatchData!.pullEDocsBatch.successes.length as number) +
              (getPullEDocsBatchData!.pullEDocsBatch.failures.length as number)
            } / ${selectedShipments.length}`}</Typography>
          )}
        </Box>
      )}
      <Box>
        <Button
          className={classes.button}
          disabled={isSoaShipmentButtonsDisabled}
          variant='contained'
          onClick={() => handleBatchPullEDocs(EDocsDataTargetType.Shipment)}
          data-testid='soa-shipment-edocs-pull-btn'
        >
          Pull From Selected Shipments
        </Button>
        <Button
          className={classes.button}
          disabled={isSoaShipmentButtonsDisabled}
          variant='contained'
          onClick={() => {
            setBatchPullId('')
            setSelectedDataTargetType(EDocsDataTargetType.Shipment)
            setIsUploadModalOpen(true)
          }}
          data-testid='soa-shipment-edocs-push-btn'
        >
          Push To Selected Shipments
        </Button>
        <Button
          className={classes.button}
          variant='outlined'
          disabled={isSoaShipmentButtonsDisabled}
          onClick={() => handleDeleteSelectedEDocs(EDocsDataTargetType.Shipment)}
          data-testid='soa-shipment-edocs-clear-btn'
        >
          Clear Selected Shipments
        </Button>
      </Box>
      <Typography variant='h4' gutterBottom>
        AP Invoice eDocs
      </Typography>
      {soaInvoiceEDocs.length > 0 ? (
        <SoaEDocTable
          soaEDocs={soaInvoiceEDocs}
          handleSelect={handleSelect}
          selectEDocHandler={selectEDocHandler}
          deleteEdocHandler={deleteEdocHandler}
          deleteEdocLoading={deleteEdocLoading}
          bulkDeleteEdocsLoading={bulkDeleteEDocsLoading}
          allSelected={allInvoicesSelected}
          selected={selectedInvoices}
          handleSelectAll={handleSelectAll}
          dataTargetType={EDocsDataTargetType.ApInvoice}
        />
      ) : (
        <Typography color='error'>No invoices were found.</Typography>
      )}
      {isBatchPullingInProgress && selectedDataTargetType === EDocsDataTargetType.ApInvoice && (
        <Box>
          <Box>
            <LinearProgress />
          </Box>
          <Typography variant='caption' align='left'>
            Pulling eDocuments from SOA invoices...
          </Typography>
          {getPullEDocsBatchData?.pullEDocsBatch.batchPullId === batchPullId && (
            <Typography variant='caption' align='right'>{`${
              (getPullEDocsBatchData!.pullEDocsBatch.successes.length as number) +
              (getPullEDocsBatchData!.pullEDocsBatch.failures.length as number)
            } / ${selectedInvoices.length}`}</Typography>
          )}
        </Box>
      )}
      <Box>
        <Button
          className={classes.button}
          disabled={isSoaInvoiceButtonsDisabled}
          variant='contained'
          onClick={() => handleBatchPullEDocs(EDocsDataTargetType.ApInvoice)}
          data-testid='soa-invoice-edocs-pull-btn'
        >
          Pull From Selected Invoices
        </Button>
        <Button
          className={classes.button}
          disabled={isSoaInvoiceButtonsDisabled}
          variant='contained'
          onClick={() => {
            setBatchPullId('')
            setSelectedDataTargetType(EDocsDataTargetType.ApInvoice)
            setIsUploadModalOpen(true)
          }}
          data-testid='soa-invoice-edocs-push-btn'
        >
          Push To Selected Invoices
        </Button>
        <Button
          className={classes.button}
          variant='outlined'
          disabled={isSoaInvoiceButtonsDisabled}
          onClick={() => handleDeleteSelectedEDocs(EDocsDataTargetType.ApInvoice)}
          data-testid='soa-invoice-edocs-clear-btn'
        >
          Clear Selected Invoices
        </Button>
      </Box>

      {isUploadModalOpen && (
        <BatchUploadModal
          jobId={jobId}
          filePages={filePages}
          selectedNums={getSelectedNums()}
          isOpen={isUploadModalOpen}
          setIsOpen={setIsUploadModalOpen}
          dataTargetType={selectedDataTargetType}
        />
      )}

      <Typography variant='h4' gutterBottom>
        Consol eDocs
      </Typography>
      {findSoaModelsLoading && (
        <Typography variant='caption'>
          <CircularProgress /> Finding consols...
        </Typography>
      )}
      {soaConsolEDocs.length > 0 ? (
        <SoaEDocTable
          soaEDocs={soaConsolEDocs}
          handleSelect={handleSelect}
          selectEDocHandler={selectEDocHandler}
          deleteEdocHandler={deleteEdocHandler}
          deleteEdocLoading={deleteEdocLoading}
          // why the hell do we not have consistent casing for Edocs/EDocs
          bulkDeleteEdocsLoading={bulkDeleteEDocsLoading}
          allSelected={allConsolsSelected}
          selected={selectedConsols}
          handleSelectAll={handleSelectAll}
          dataTargetType={EDocsDataTargetType.Consol}
        />
      ) : (
        !findSoaModelsLoading && (
          <Typography color='error' data-testid={'soa-consol-edoc-table'}>
            No consols were found.
          </Typography>
        )
      )}
      {isBatchPullingInProgress && selectedDataTargetType === EDocsDataTargetType.Consol && (
        <Box>
          <Box>
            <LinearProgress />
          </Box>
          <Typography variant='caption' align='left'>
            Pulling eDocuments from SOA consols...
          </Typography>
          {getPullEDocsBatchData?.pullEDocsBatch.batchPullId === batchPullId && (
            <Typography variant='caption' align='right'>{`${
              (getPullEDocsBatchData!.pullEDocsBatch.successes.length as number) +
              (getPullEDocsBatchData!.pullEDocsBatch.failures.length as number)
            } / ${selectedConsols.length}`}</Typography>
          )}
        </Box>
      )}
      <Box>
        <Button
          className={classes.button}
          disabled={isSoaConsolButtonsDisabled}
          variant='contained'
          onClick={() => handleBatchPullEDocs(EDocsDataTargetType.Consol)}
          data-testid='soa-consol-edocs-pull-btn'
        >
          Pull From Selected Consols
        </Button>
        <Button
          className={classes.button}
          disabled={isSoaConsolButtonsDisabled}
          variant='contained'
          onClick={() => {
            setBatchPullId('')
            setSelectedDataTargetType(EDocsDataTargetType.Consol)
            setIsUploadModalOpen(true)
          }}
          data-testid='soa-consol-edocs-push-btn'
        >
          Push To Selected Consols
        </Button>
        <Button
          className={classes.button}
          variant='outlined'
          disabled={isSoaConsolButtonsDisabled}
          onClick={() => handleDeleteSelectedEDocs(EDocsDataTargetType.Consol)}
          data-testid='soa-consol-edocs-clear-btn'
        >
          Clear Selected Consols
        </Button>
      </Box>

      <Typography variant='h4' gutterBottom>
        Customs Declaration eDocs
      </Typography>
      {findSoaModelsLoading && (
        <Typography variant='caption'>
          <CircularProgress /> Finding Customs Declarations...
        </Typography>
      )}
      {soaCustomsDecEDocs.length > 0 ? (
        <SoaEDocTable
          soaEDocs={soaCustomsDecEDocs}
          handleSelect={handleSelect}
          selectEDocHandler={selectEDocHandler}
          deleteEdocHandler={deleteEdocHandler}
          deleteEdocLoading={deleteEdocLoading}
          // why the hell do we not have consistent casing for Edocs/EDocs
          bulkDeleteEdocsLoading={bulkDeleteEDocsLoading}
          allSelected={allCustomsDecSelected}
          selected={selectedCustomsDecs}
          handleSelectAll={handleSelectAll}
          dataTargetType={EDocsDataTargetType.CustomsDeclaration}
        />
      ) : (
        !findSoaModelsLoading && (
          <Typography color='error' data-testid={'soa-customs-dec-edoc-table'}>
            No customs declarations were found.
          </Typography>
        )
      )}
      {isBatchPullingInProgress &&
        selectedDataTargetType === EDocsDataTargetType.CustomsDeclaration && (
          <Box>
            <Box>
              <LinearProgress />
            </Box>
            <Typography variant='caption' align='left'>
              Pulling eDocuments from SOA customs declarations...
            </Typography>
            {getPullEDocsBatchData?.pullEDocsBatch.batchPullId === batchPullId && (
              <Typography variant='caption' align='right'>{`${
                (getPullEDocsBatchData!.pullEDocsBatch.successes.length as number) +
                (getPullEDocsBatchData!.pullEDocsBatch.failures.length as number)
              } / ${selectedCustomsDecs.length}`}</Typography>
            )}
          </Box>
        )}
      <Box>
        <Button
          className={classes.button}
          disabled={isSoaCustomsDecButtonsDisabled}
          variant='contained'
          onClick={() => handleBatchPullEDocs(EDocsDataTargetType.CustomsDeclaration)}
          data-testid='soa-customs-declaration-edocs-pull-btn'
        >
          Pull From Selected Customs Declarations
        </Button>
        <Button
          className={classes.button}
          disabled={isSoaCustomsDecButtonsDisabled}
          variant='contained'
          onClick={() => {
            setBatchPullId('')
            setSelectedDataTargetType(EDocsDataTargetType.CustomsDeclaration)
            setIsUploadModalOpen(true)
          }}
          data-testid='soa-customs-declaration-edocs-push-btn'
        >
          Push To Selected Customs Declarations
        </Button>
        <Button
          className={classes.button}
          variant='outlined'
          disabled={isSoaCustomsDecButtonsDisabled}
          onClick={() => handleDeleteSelectedEDocs(EDocsDataTargetType.CustomsDeclaration)}
          data-testid='soa-customs-declaration-edocs-clear-btn'
        >
          Clear Selected Customs Declarations
        </Button>
      </Box>
    </>
  )
}

export default BatchPullTable
