import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState, useContext, useCallback, useEffect, useRef } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core'
import theme from '@src/utils/theme'
import {
  EDocsDataTargetType,
  FilePageNode,
  InputEDocument,
  Mutation,
  Query,
  MutationBatchPushEDocsToCwArgs,
  FilePageType,
  QueryPushEDocsBatchArgs,
} from '@src/graphql/types'
import { JOB_NOTES } from '@src/graphql/queries/note'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { ShipmentFormContext } from '@src/contexts/shipment_form_context'
import { Grid } from '@material-ui/core'
import { BATCH_PUSH_EDOCS_TO_CARGOWISE } from '@src/graphql/mutations/cargowise'
import BatchPushEDocsConfirmDialog from '../batch-upload-modal/BatchPushEDocsConfirmDialog'
import SoaEDocFileViewer from '../batch-upload-modal/SoaEDocFileViewer'
import SoaEDocFileList from '../batch-upload-modal/SoaEDocFileList'
import UploadingProgressBar from '../progress-bar/UploadingProgressBar'
import FileSheetsViewer from '../job-viewer/FileSheetsViewer'
import { GET_PUSH_EDOCS_BATCH } from '@src/graphql/queries/cargowise'
import { JobDataContext } from '@src/contexts/job_data_context'
import { LogEventType } from '@src/utils/observability/LogEventType'

const BATCH_PUSH_DATA_POLLING_TIME = 2000

const useStyles = makeStyles({
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  excelContainer: {
    height: theme.spacing(80),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(100),
    borderLeft: `solid ${theme.palette.grey[300]} 1px`,
  },
})

type Props = {
  jobId: string
  filePages: FilePageNode[]
  selectedNums: string[]
  closeDialog: () => void
  dataTargetType: EDocsDataTargetType
}

const SoaEDocsFileSelect: FunctionComponent<Props> = ({
  jobId,
  filePages,
  selectedNums,
  closeDialog,
  dataTargetType,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedFilePagesId, setSelectedFilePagesId] = useState([] as string[])
  const [currentFilePage, setCurrentFilePage] = useState(filePages[0] as FilePageNode)
  const [selectedFilePagesToPush, setSelectedFilePagesToPush] = useState([] as FilePageNode[])
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [isUploadInProgress, setUploadInProgress] = useState(false)
  const { logEvent } = useEventLogger()
  const actionStart = useRef<Date>()
  const inputEDocs = useRef<InputEDocument[]>()
  const { saveJob } = useContext(ShipmentFormContext)
  const { jobRefetch: refetchFilePages } = useContext(JobDataContext)

  const closeConfirmDialog = (): void => {
    setIsConfirmDialogOpen(false)
  }

  const [batchPushId, setBatchPushId] = useState('')
  const {
    data: getPushEDocsBatchData,
    startPolling: startGetPushEDocsBatchPolling,
    stopPolling: stopGetPushEDocsBatchPolling,
  } = useQuery<Pick<Query, 'pushEDocsBatch'>, QueryPushEDocsBatchArgs>(GET_PUSH_EDOCS_BATCH, {
    skip: !batchPushId,
    variables: {
      batchPushId: batchPushId!,
      totalPushed: selectedNums.length,
    },
    fetchPolicy: 'network-only',
  })

  const [batchPushEDocsToCw] = useMutation<
    Pick<Mutation, 'batchPushEDocsToCw'>,
    MutationBatchPushEDocsToCwArgs
  >(BATCH_PUSH_EDOCS_TO_CARGOWISE, {
    refetchQueries: [{ query: JOB_NOTES, variables: { jobId } }],
    onCompleted: (data) => {
      setBatchPushId(data.batchPushEDocsToCw?.batchPushId)
      startGetPushEDocsBatchPolling(BATCH_PUSH_DATA_POLLING_TIME)
    },
  })

  useEffect(() => {
    const batchPushData = getPushEDocsBatchData?.pushEDocsBatch
    if (batchPushData && batchPushData.batchPushId === batchPushId) {
      if (batchPushData.done) {
        stopGetPushEDocsBatchPolling()
        setUploadInProgress(false)
        const successes = getPushEDocsBatchData?.pushEDocsBatch!.successes
        const failures = getPushEDocsBatchData?.pushEDocsBatch!.failures
        const success = true
        let errorMessage = ''
        if (successes!.length) {
          void refetchFilePages()
          enqueueSnackbar(`Successfully pushed CargoWise eDocs for: ${successes!.join(', ')}`, {
            variant: 'success',
          })
          closeDialog()
        }
        if (failures!.length) {
          const errorMessages = getPushEDocsBatchData?.pushEDocsBatch!.errorMessages ?? []
          enqueueSnackbar(`Failed to push CargoWise eDocs for: ${failures!.join(', ')}`, {
            variant: 'error',
          })
          errorMessages.forEach((errorMessage) => {
            enqueueSnackbar(errorMessage, { variant: 'error' })
          })

          errorMessage = `Failed to push CargoWise eDocs for: ${failures!.join(', ')}`
        }
        setBatchPushId('')

        const filePageIds = inputEDocs.current?.flatMap(
          (inputEDoc) => inputEDoc.filePageIds as string[],
        )
        void logEvent(LogEventType.EDOCS_PUSH, {
          success,
          job_id: jobId,
          page_ids: filePageIds,
          action_start: actionStart.current,
          action_end: new Date(),
          error_message: errorMessage,
        })
      }
    }
    // excluding the analytics deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeDialog,
    batchPushId,
    enqueueSnackbar,
    getPushEDocsBatchData,
    stopGetPushEDocsBatchPolling,
    refetchFilePages,
  ])

  const toggleAll = (): void => {
    let checked = false
    let newSelectedFilePagesId = []
    if (selectedFilePagesId.length === 0) {
      checked = true
      newSelectedFilePagesId = filePages.map((filePage) => filePage.id)
      setSelectedFilePagesId(newSelectedFilePagesId)
    } else {
      newSelectedFilePagesId = selectedFilePagesId
      setSelectedFilePagesId([])
    }
    void logEvent(LogEventType.FILE_PAGES_SELECT, {
      job_id: jobId,
      page_ids: newSelectedFilePagesId,
      checked: checked,
    })
  }

  const selectFilePage = (id: string, checked: boolean): void => {
    const newSelectedFilePagesId = checked
      ? [...selectedFilePagesId, id]
      : selectedFilePagesId.filter((selectedFilePageId) => selectedFilePageId !== id)
    setSelectedFilePagesId(newSelectedFilePagesId)
    void logEvent(LogEventType.FILE_PAGES_SELECT, {
      job_id: jobId,
      page_ids: [id],
      checked: checked,
    })
  }

  const previewFilePage = (filePage: FilePageNode): void => {
    if (filePage.id !== currentFilePage?.id) {
      void logEvent(LogEventType.FILE_PAGES_VIEW, {
        job_id: jobId,
        page_id: filePage.id,
      })
      setCurrentFilePage(filePage)
    }
  }

  const batchPushEDocs = useCallback(
    async (
      dataTargetType: EDocsDataTargetType,
      inputEDocuments: InputEDocument[],
    ): Promise<void> => {
      setBatchPushId('')
      setUploadInProgress(true)
      setIsConfirmDialogOpen(false)
      let success = false
      let errorMessage = ''
      inputEDocs.current = inputEDocuments

      try {
        await batchPushEDocsToCw({
          variables: {
            jobId,
            referenceNumbers: selectedNums,
            inputEDocuments,
            dataTargetType,
          },
        })

        enqueueSnackbar('Successfully pushed eDocs to CargoWise', { variant: 'success' })
        setSelectedFilePagesId([])
        success = true
      } catch (error) {
        setUploadInProgress(false)
        errorMessage = `Encountered an error while pushing eDocs to CargoWise: ${formatMaybeApolloError(
          error,
        )}`
        enqueueSnackbar(errorMessage, { variant: 'error' })

        const filePageIds = inputEDocuments.flatMap(
          (inputEDoc) => inputEDoc.filePageIds as string[],
        )
        void logEvent(LogEventType.EDOCS_PUSH, {
          job_id: jobId,
          page_ids: filePageIds,
          action_start: actionStart.current,
          action_end: new Date(),
          success: success,
          error_message: errorMessage,
        })
      }
    },
    [enqueueSnackbar, selectedNums, batchPushEDocsToCw, jobId],
  )

  const handleBatchPushEdocs = async (): Promise<void> => {
    try {
      actionStart.current = new Date()
      await saveJob()
      const filePagesToPush = filePages.filter((filePage) =>
        selectedFilePagesId.includes(filePage.id),
      )

      setSelectedFilePagesToPush(filePagesToPush)
      setIsConfirmDialogOpen(true)
    } catch (error) {
      const errorMessage = `Encountered an error while pushing eDocs to CargoWise: ${formatMaybeApolloError(
        error,
      )}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
      setIsConfirmDialogOpen(false)
    }
  }

  return (
    <>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={2}>
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            justifyContent='space-between'
            py={1}
            marginLeft={2}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onClick={toggleAll}
                  checked={selectedFilePagesId.length === filePages.length}
                  indeterminate={
                    selectedFilePagesId.length > 0 && selectedFilePagesId.length < filePages.length
                  }
                  disabled={isUploadInProgress}
                />
              }
              label='Select all'
            />
          </Box>
          <SoaEDocFileList
            filePages={filePages}
            previewFilePage={previewFilePage}
            selectedFilePagesId={selectedFilePagesId}
            currentFilePage={currentFilePage}
            selectFilePage={selectFilePage}
          />
        </Grid>
        <Grid item xs={10}>
          {currentFilePage?.type === FilePageType.Excel && (
            <Box className={classes.excelContainer}>
              <FileSheetsViewer filePage={currentFilePage} />
            </Box>
          )}
          {currentFilePage?.type === FilePageType.ImagePdf && (
            <SoaEDocFileViewer currentFilePage={currentFilePage} />
          )}
        </Grid>
      </Grid>
      {isUploadInProgress && (
        <UploadingProgressBar
          dataTargetType={dataTargetType}
          setUploadInProgress={setUploadInProgress}
          totalNumbers={selectedNums.length}
          totalPushed={
            getPushEDocsBatchData?.pushEDocsBatch.batchPushId === batchPushId
              ? (getPushEDocsBatchData!.pushEDocsBatch.successes.length as number) +
                (getPushEDocsBatchData!.pushEDocsBatch.failures.length as number)
              : undefined
          }
        />
      )}
      {isConfirmDialogOpen && !isUploadInProgress && (
        <BatchPushEDocsConfirmDialog
          dataTargetType={dataTargetType}
          closeConfirmDialog={closeConfirmDialog}
          selectedFilePagesToPush={selectedFilePagesToPush}
          selectedNums={selectedNums}
          batchPushEdocs={batchPushEDocs}
        />
      )}
      <Box
        display='flex'
        justifyContent='center'
        paddingTop={2}
        borderTop={`solid ${theme.palette.grey[300]} 1px`}
      >
        <Button
          className={classes.button}
          variant='contained'
          disabled={isUploadInProgress}
          onClick={closeDialog}
        >
          Cancel
        </Button>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          disabled={isUploadInProgress || selectedFilePagesId.length === 0 || isConfirmDialogOpen}
          onClick={handleBatchPushEdocs}
        >
          Send to CW
        </Button>
      </Box>
    </>
  )
}

export default SoaEDocsFileSelect
