import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core'
import { EXPECTED_DATA_TABLE_COLUMNS, HotTableData } from '@src/utils/recon/ap_recon'
import { FunctionComponent } from 'react'
import { CheckShipmentMetadataTable } from '@src/components/CheckShipmentMetadataTable'
import { Maybe } from '@src/graphql/types'
import { TableDisplayData } from './util'
import clsx from 'clsx'
import theme from '@src/utils/theme'

const MIN_CELL_WIDTH = '75px'

const useStyles = makeStyles<Theme>({
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.75, 1),
    minWidth: MIN_CELL_WIDTH,
  },
  wrapWord: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
})

type Props = {
  expectedDataMatrix: TableDisplayData
  expectedMetadataTable: Maybe<TableDisplayData>
  apAssociatedKeysTable: HotTableData
}

const CustomsDeclarationDialog: FunctionComponent<Props> = ({
  expectedDataMatrix,
  expectedMetadataTable,
  apAssociatedKeysTable,
}) => {
  const classes = useStyles()

  return (
    <>
      <Box mb={2}>
        <CheckShipmentMetadataTable
          title='Associated Keys'
          metadataTableData={apAssociatedKeysTable.data}
        />
      </Box>
      <Box mb={2}>
        <CheckShipmentMetadataTable title='Metadata' metadataTableData={expectedMetadataTable} />
      </Box>
      <Typography variant='h6' gutterBottom>
        Billing Tab
      </Typography>
      <Box display='flex' alignItems='baseline' mb={2}>
        <Box display='flex' flexDirection='column'>
          <TableContainer>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  {EXPECTED_DATA_TABLE_COLUMNS.map((col, colIdx) => (
                    <TableCell
                      className={clsx(classes.cell, classes.colCell)}
                      align='center'
                      key={colIdx}
                    >
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
                {expectedDataMatrix.map((row, rowIdx) => {
                  return (
                    <TableRow key={rowIdx}>
                      {row.map((data, colIdx) => {
                        return (
                          <TableCell
                            className={clsx(classes.cell, classes.wrapWord)}
                            component='th'
                            scope='row'
                            key={colIdx}
                          >
                            {data}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  )
}

export default CustomsDeclarationDialog
