import { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import theme from '@src/utils/theme'
import { SELECTION_BOX_Z_INDEX } from '@src/utils/app_constants'

type Props = {
  top: number
  left: number
  height: number
  width: number
}

type StyleProps = Props

const useStyles = makeStyles<Theme, StyleProps>({
  selectionBox: {
    position: 'absolute',
    pointerEvents: 'none',
    backgroundColor: alpha(theme.palette.primary.light, 0.2),
    border: `1px dashed ${theme.palette.grey['600']}`,
    zIndex: SELECTION_BOX_Z_INDEX,
    top: (props) => `${props.top}px`,
    left: (props) => `${props.left}px`,
    height: (props) => `${props.height}px`,
    width: (props) => `${props.width}px`,
  },
})

/**
 * Just a UI element: the dotted box when you select things or display the selected box
 */
const SelectionBox: FunctionComponent<Props> = ({ top, left, height, width }) => {
  const classes = useStyles({ top, left, width, height })

  return <Box className={classes.selectionBox} />
}

export default SelectionBox
