import { createContext, FunctionComponent, PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { LogEventType } from '@src/utils/observability/LogEventType'
import { usePostHog } from 'posthog-js/react'
import { useOpenReplay } from '@src/utils/observability/useOpenReplay'
import { useEventAttributes } from '@src/utils/observability/useEventAttributes'
import { logAmplitudeEvent } from '@src/utils/observability/useAmplitude'

export const RoutePathContext = createContext<string | undefined>(undefined)

/**
 * Wrapper to provide page URL and to log user page visits.
 */
export const RoutePathProvider: FunctionComponent<PropsWithChildren<unknown>> = ({ children }) => {
  const routePath = useLocation().pathname as string | undefined
  const posthog = usePostHog()
  const openReplay = useOpenReplay()
  const { userAttributes } = useEventAttributes()

  useEffect(() => {
    // don't want to use logEvent because we want to use posthog's native $pageview
    logAmplitudeEvent(userAttributes.email || 'null_id', LogEventType.PAGE_VISIT, {
      page: routePath,
    })
    openReplay?.event(LogEventType.PAGE_VISIT, { page: routePath })
    posthog?.capture('$pageview')
  }, [routePath, posthog])

  return <RoutePathContext.Provider value={routePath}>{children}</RoutePathContext.Provider>
}
