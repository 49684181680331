/** standard trace header name as per https://www.w3.org/TR/trace-context/ */
const traceHeaderName = 'traceparent'
let openReplaySessionToken: string | undefined

const byteToHex: string[] = []

for (let n = 0; n <= 0xff; ++n) {
  const hexOctet = n.toString(16).padStart(2, '0')
  byteToHex.push(hexOctet)
}

const uintArrayToHex = (arr: Uint8Array): string => {
  const hexOctets = [] // new Array(buff.length) is even faster (preallocates necessary array size), then use hexOctets[i] instead of .push()

  for (let i = 0; i < arr.length; ++i) hexOctets.push(byteToHex[arr[i]])

  return hexOctets.join('')
}

/** generate trace header value as per https://www.w3.org/TR/trace-context/ */
const generateTraceHeader = (): string => {
  const randomArray = new Uint8Array(24)
  crypto.getRandomValues(randomArray)
  const randomHex = uintArrayToHex(randomArray)
  const version = '00'
  // trace ID is 16 bytes
  const traceId = randomHex.substr(0, 32)
  // parent ID is 8 bytes
  const parentId = randomHex.substr(32)
  const traceFlag = '00'
  return `${version}-${traceId}-${parentId}-${traceFlag}`
}

/**
 * Modifies headers (assuming it's nonexistent or a dictionary) by appending a trace header
 */
export const tracingFetch: typeof fetch = (uri, options) => {
  const headers = (options?.headers as Record<string, string>) ?? {}
  headers[traceHeaderName] = generateTraceHeader()
  headers['X-XpdSourceApp'] = 'web'
  if (openReplaySessionToken) {
    headers['X-OpenReplay-SessionToken'] = openReplaySessionToken
  }
  return fetch(uri, { ...(options ?? {}), headers })
}

export const setOpenReplaySessionToken = (token: string): void => {
  openReplaySessionToken = token
}
