import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import { makeStyles } from '@material-ui/styles'
import humanizeDuration from 'humanize-duration'
import { FunctionComponent, useEffect, useState } from 'react'
import { PageAsyncBatchNotificationNode, PageAsyncTaskStatus } from '@src/graphql/types'
import { COLORS } from '@src/utils/app_constants'
import { formatDateForNotif } from '@src/utils/date'
import { estimateBatchSecondsLeft } from '@src/utils/file'
import theme from '@src/utils/theme'
import { useHistory } from 'react-router-dom'

type Props = {
  notification: PageAsyncBatchNotificationNode
  closeNotifications: () => void
}

const useStyles = makeStyles({
  doneIcon: { color: theme.palette.success.main },
  errorIcon: { color: theme.palette.error.main },
  progress: { alignItems: 'center', display: 'flex', justifyContent: 'flex-end' },
  unread: { backgroundColor: COLORS.LIGHT_BLUE },
})

const NotificationItem: FunctionComponent<Props> = ({ notification, closeNotifications }) => {
  const shortEnglishHumanizer = humanizeDuration.humanizer({
    language: 'shortEn',
    languages: {
      shortEn: {
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => 'd',
        h: () => 'h',
        m: () => 'm',
        s: () => 's',
        ms: () => 'ms',
      },
    },
    spacer: '',
  })
  const history = useHistory()
  const classes = useStyles()
  const [etaSeconds, setEtaSeconds] = useState(null as null | number)
  const [read, setRead] = useState(false)

  const { pageAsyncBatch } = notification
  const { job } = pageAsyncBatch!
  const [progress, setProgress] = useState(0)
  const fileNames = [
    ...new Set(
      notification.pageAsyncBatch?.pageAsyncTasks?.edges.map(
        (pageAsyncTask) => pageAsyncTask?.node?.filePage?.file?.filename,
      ),
    ),
  ].join(',')

  useEffect(() => {
    if (pageAsyncBatch?.status === PageAsyncTaskStatus.Pending) {
      const eta = estimateBatchSecondsLeft(pageAsyncBatch)
      setEtaSeconds(eta)
      const currProgress =
        pageAsyncBatch.numDone != null && pageAsyncBatch.numTasks != null
          ? pageAsyncBatch.numDone / pageAsyncBatch.numTasks
          : 0
      setProgress(currProgress * 100)
    }
  }, [pageAsyncBatch])

  // don't remove unread background until user closes the popup
  useEffect(() => {
    setRead(!!notification.dateRead)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getProgress = (): JSX.Element => {
    switch (pageAsyncBatch!.status) {
      case PageAsyncTaskStatus.Done:
        return (
          <>
            <CheckCircleIcon className={classes.doneIcon} />
            <Typography variant='caption' display='block'>
              {pageAsyncBatch?.dateCompleted
                ? formatDateForNotif(pageAsyncBatch.dateCompleted)
                : ''}
            </Typography>
          </>
        )
      case PageAsyncTaskStatus.Error:
        return (
          <>
            <ErrorIcon className={classes.errorIcon} />
            <Typography variant='caption' display='block'>
              {pageAsyncBatch?.dateCompleted
                ? formatDateForNotif(pageAsyncBatch.dateCompleted)
                : ''}
            </Typography>
          </>
        )
      default:
        return (
          <>
            <CircularProgress variant='determinate' value={progress} disableShrink />
            {etaSeconds && (
              <Typography variant='caption' display='block'>
                {`${shortEnglishHumanizer(etaSeconds * 1000, {
                  round: true,
                })} left`}
              </Typography>
            )}
          </>
        )
    }
  }

  const redirect = async (): Promise<void> => {
    if (job) {
      history.push(`/${job.status.replace('_', '').toLowerCase()}/${job.id}`)
      closeNotifications()
    } else if (pageAsyncBatch?.status === PageAsyncTaskStatus.Done) {
      const filePageIds = notification.pageAsyncBatch!.pageAsyncTasks!.edges.map(
        (pageAsyncTask) => pageAsyncTask!.node!.filePage!.id,
      )
      const params = new URLSearchParams()
      for (const id of filePageIds) {
        params.append('ids', id)
      }
      history.push({
        pathname: '/page_assoc',
        search: params.toString(),
      })
      closeNotifications()
    }
  }

  return (
    <ListItem className={!read ? classes.unread : undefined} button onClick={redirect} divider>
      {pageAsyncBatch?.pageAsyncTasks?.edges.length ? (
        <Grid container>
          <Grid item xs={8}>
            <Typography
              noWrap
              variant={
                pageAsyncBatch.status === PageAsyncTaskStatus.Pending ? 'body2' : 'subtitle2'
              }
            >
              {job
                ? job.name
                : pageAsyncBatch?.pageAsyncTasks?.edges[0]?.node?.filePage?.file?.emailSubject}
            </Typography>
            <Typography noWrap variant='caption' display='block'>
              {fileNames}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.progress}>
            {getProgress()}
          </Grid>
        </Grid>
      ) : (
        <Box display='flex' justifyContent='center' width='100%'>
          <CircularProgress />
        </Box>
      )}
    </ListItem>
  )
}

export default NotificationItem
