import { BusinessPerformanceTab, DashboardTabType, ExploreTab } from '@src/graphql/types'
import {
  AccountingTab,
  DashboardTab,
  OperationsTab,
  SalesTab,
} from '@src/pages/admin/user/default-dashboards-whitelist/constants'

export const getTabsToDisplay = (
  defaultDashboardsWhitelist: string[],
): Record<string, string[]> => {
  const tabs: Record<string, string[]> = {
    [DashboardTabType.Dashboard]: [],
    [DashboardTabType.BusinessPerformance]: [],
    [DashboardTabType.Operations]: [],
    [DashboardTabType.Accounting]: [],
    [DashboardTabType.Sales]: [],
    [DashboardTabType.Explore]: [],
  }

  defaultDashboardsWhitelist.forEach((dashboardName) => {
    if (Object.values<string>(BusinessPerformanceTab).includes(dashboardName)) {
      tabs[DashboardTabType.BusinessPerformance].push(dashboardName)
    } else if (Object.values<string>(ExploreTab).includes(dashboardName)) {
      tabs[DashboardTabType.Explore].push(dashboardName)
    } else if (Object.values<string>(OperationsTab).includes(dashboardName)) {
      tabs[DashboardTabType.Operations].push(dashboardName)
    } else if (Object.values<string>(AccountingTab).includes(dashboardName)) {
      tabs[DashboardTabType.Accounting].push(dashboardName)
    } else if (Object.values<string>(SalesTab).includes(dashboardName)) {
      tabs[DashboardTabType.Sales].push(dashboardName)
    } else if (Object.values<string>(DashboardTab).includes(dashboardName)) {
      tabs[DashboardTabType.Dashboard].push(dashboardName)
    }
  })

  return tabs
}
