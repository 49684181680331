import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import XLSX from 'xlsx'
import Box from '@material-ui/core/Box'
import { FilePageNode } from '@src/graphql/types'
import { Button, ButtonGroup } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import ReadOnlyHotTable from '../read-only-hot-table/ReadOnlyHotTable'
import CenteredCircularProgress from '../centered-circular-progress/CenteredCircularProgress'

type Props = {
  filePage: FilePageNode
}

type Sheet = {
  name: string
  data: string[][]
}

const handsontableSettings = {
  readOnly: true,
  colHeaders: true,
  rowHeaders: true,
  autoRowSize: true,
  manualColumnResize: true,
}

const FileSheetsViewer: FunctionComponent<Props> = ({ filePage }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [currentSheet, setCurrentSheet] = useState(null as Sheet | null)
  const [sheets, setSheets] = useState([] as Sheet[])

  const parseExcel = useCallback(
    (file: Blob): void => {
      const reader = new FileReader()

      reader.onload = (e) => {
        const data = e.target!.result
        let workbook: ReturnType<(typeof XLSX)['read']>
        try {
          workbook = XLSX.read(data, {
            type: 'binary',
          })
        } catch (e) {
          enqueueSnackbar(`Failed to load file: ${e}`, { variant: 'error' })
          return
        }

        const workbookSheetNames = workbook.SheetNames
        const workbookSheets = workbookSheetNames.map((sheetName) => {
          const sheetArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            header: 1,
            raw: false,
          }) as string[][]
          // `sheet_to_json` only parses upto the non-empty column of that row so
          // rows can have different length.
          // This ensures that rows have the same length by filling the row with ''
          // We do this to avoid missing columns since handsontable bases the number
          // of columns to display on the length of the first row
          const maxArrayLength = Math.max(...sheetArray.map((arr) => arr.length))
          const cleanedSheetArray = sheetArray.map((arr) => {
            const arrLength = arr.length
            return arrLength !== maxArrayLength
              ? ([...arr, ...Array(maxArrayLength - arrLength).fill('')] as string[])
              : arr
          })
          return {
            name: sheetName,
            data: cleanedSheetArray,
          }
        })
        setSheets(workbookSheets)

        if (workbookSheets.length) {
          setCurrentSheet(workbookSheets[0])
        }
      }

      reader.onerror = (ex) => {
        enqueueSnackbar(`Failed to load file: ${ex}`, { variant: 'error' })
      }

      reader.readAsBinaryString(file)
    },
    [enqueueSnackbar],
  )

  const loadFile = useCallback(
    async (fileUrl: string): Promise<void> => {
      const request = new Request(fileUrl)
      await fetch(request)
        .then((response) => response.blob())
        .then((blob) => parseExcel(blob))
    },
    [parseExcel],
  )

  useEffect(() => {
    void (async () => {
      await loadFile(filePage.imageUrl!)
    })()
  }, [filePage, loadFile])

  return (
    <>
      <div style={{ overflow: 'hidden', flex: '1' }}>
        {currentSheet?.data ? (
          <div data-testid='excel-sheet'>
            <ReadOnlyHotTable data={currentSheet.data} settings={handsontableSettings} />
          </div>
        ) : (
          <CenteredCircularProgress />
        )}
      </div>
      <Box p={2}>
        <ButtonGroup variant='outlined'>
          {sheets.map((sheet, idx) => (
            <Button
              key={`${sheet.name}-${idx}`}
              variant={currentSheet?.name === sheet.name ? 'contained' : 'outlined'}
              color='default'
              onClick={() => setCurrentSheet(sheet)}
              disableElevation
            >
              {sheet.name}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    </>
  )
}

export default FileSheetsViewer
