import { FunctionComponent, useEffect, useState } from 'react'
import { IconButton, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { clsx } from 'clsx'
import {
  getAPAssociatedKeysTable,
  getMetadataTable,
  maybeFindReconModuleFromReconResults,
  transformChargeDetailsToMatrix,
} from '@src/utils/recon/ap_recon'
import {
  ChainIoModelName,
  getChainIoModelName,
  SoaLineItemExpectedChargesWithSecondaryKeysAndCargowiseModules,
} from '@src/utils/line_items'
import { CargowiseOpsType } from '@src/utils/cargowise/types'
import { getReconResultsDataModifiedDate, millisecondsInMinute } from '@src/utils/date'
import {
  CwTargetModule,
  FindShipmentReconResultNode,
  JobTemplateReconType,
  Maybe,
} from '@src/graphql/types'
import ForwardingShipmentDialog from './check-shipment-info/ForwardingShipmentDialog'
import ForwardingConsolDialog from './check-shipment-info/ForwardingConsolDialog'
import CenteredCircularProgress from './centered-circular-progress/CenteredCircularProgress'
import { FindShipmentResultDisplay } from '@src/utils/recon/FindShipmentResultDisplay'

const MIN_CELL_WIDTH = '75px'

const useStyles = makeStyles<Theme>({
  dialogMedium: {
    maxWidth: '90vw',
    minWidth: '20vw',
    height: '50vh',
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.75, 1),
    minWidth: MIN_CELL_WIDTH,
  },
  wrapWord: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  shipmentDate: {
    color: theme.palette.text.primary,
  },
  shipmentDateError: {
    color: theme.palette.error.main,
  },
})

type Props = {
  close: () => void
  expectedCharge: SoaLineItemExpectedChargesWithSecondaryKeysAndCargowiseModules
}

const SOACheckShipmentInfoDialog: FunctionComponent<Props> = ({ close, expectedCharge }) => {
  const classes = useStyles()

  const [expectedMetadataTable, setExpectedMetadataTable] = useState<Maybe<string[][]>>(null)
  const chainIoModelName = getChainIoModelName(expectedCharge)
  const expectedDataMatrix = transformChargeDetailsToMatrix(
    expectedCharge.expectedCharges,
    true,
    true,
    null,
    null,
    true,
  )

  const successfulFindShipmentReconResult =
    (expectedCharge.matchCriteriaFindShipmentReconResultPairList.map(
      ({ successfulFindShipmentReconResult }) => successfulFindShipmentReconResult,
    ) ?? []) as FindShipmentReconResultNode[]
  const chainIoModelDateModified = successfulFindShipmentReconResult
    ? getReconResultsDataModifiedDate(successfulFindShipmentReconResult, JobTemplateReconType.Soa)
    : null
  // We consider shipments that are more than 30 minutes old as outdated
  // [PD-288](https://expedock.atlassian.net/browse/PD-288)
  const isChainIoModelDateOutdated =
    chainIoModelDateModified &&
    new Date().getTime() - chainIoModelDateModified.getTime() > millisecondsInMinute * 30

  const apAssociatedKeysTable = getAPAssociatedKeysTable(successfulFindShipmentReconResult, null)
  useEffect(() => {
    if (!expectedMetadataTable) {
      setExpectedMetadataTable(getMetadataTable(expectedCharge.metadataReconResults))
    }
  }, [expectedCharge.metadataReconResults, expectedMetadataTable])

  return (
    <Dialog classes={{ paper: classes.dialogMedium }} open={true}>
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={close} data-testid='close-btn' />
      </Box>
      <DialogTitle>
        <IconButton onClick={close} aria-label='close' data-testid='soa-invoice-group-close'>
          <ChevronLeftIcon />
        </IconButton>
        <Box>
          <FindShipmentResultDisplay
            findShipmentResults={successfulFindShipmentReconResult}
            cargowiseOpsType={CargowiseOpsType.SOACheckShipmentInfo}
            cargowiseModule={
              maybeFindReconModuleFromReconResults(successfulFindShipmentReconResult) ||
              CwTargetModule.ForwardingShipment
            }
          />
        </Box>
        <Box mb={2}>
          <Typography component={'span'} variant='body1'>
            {chainIoModelName} data last updated as of:{' '}
            <span
              className={clsx(classes.shipmentDate, {
                [classes.shipmentDateError]: isChainIoModelDateOutdated,
              })}
            >
              {chainIoModelDateModified?.toString()}
            </span>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {expectedDataMatrix ? (
          <>
            {chainIoModelName === ChainIoModelName.CHAIN_IO_SHIPMENT && (
              <ForwardingShipmentDialog
                expectedDataMatrix={expectedDataMatrix}
                expectedMetadataTable={expectedMetadataTable}
                apAssociatedKeysTable={apAssociatedKeysTable}
                findShipmentReconResults={successfulFindShipmentReconResult}
              />
            )}
            {chainIoModelName === ChainIoModelName.CHAIN_IO_CONSOLIDATION && (
              <ForwardingConsolDialog
                expectedDataMatrix={expectedDataMatrix}
                expectedMetadataTable={expectedMetadataTable}
                apAssociatedKeysTable={apAssociatedKeysTable}
                findShipmentReconResults={successfulFindShipmentReconResult}
              />
            )}
          </>
        ) : (
          <CenteredCircularProgress />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default SOACheckShipmentInfoDialog
