import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { DocumentTypeOption, toDocumentTypeOption } from '@src/utils/admin/document_type_option'
import {
  ApiPartnerCode,
  ApiPartnerCodeEnum,
  InputFieldGroup,
  InputFieldGroupType,
  InputFieldType,
  InputReconThresholdRange,
  JobTemplateReconType,
  Maybe,
  Mutation,
  MutationSaveJobTemplateExportFormatArgs,
  Query,
  UserRole,
  useUpsertJobTemplateMutation,
} from '@src/graphql/types'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { sortBy } from 'lodash'
import { getInputFieldGroups } from '@src/utils/admin/input_field_group'
import { formatDurationMsToHoursMins, parseHoursMinsToDurationMins } from '@src/utils/date'
import { useMutation, useQuery } from '@apollo/client'
import { GET_JOB_TEMPLATE } from '@src/graphql/queries/jobTemplate'
import { SAVE_JOB_TEMPLATE_EXPORT_FORMAT } from '@src/graphql/mutations/jobTemplate'
import { COMPANY_DATA } from '@src/graphql/queries/company'
import { GET_USER_ROLES } from '@src/graphql/queries/profile'
import { useSnackbar } from 'notistack'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import useExportFormat from '@src/hooks/admin/useExportFormat'
import { useLeaveFormConfirm } from '@src/hooks/useLeavePageConfirm'
import useRequireTeamLead from '@src/hooks/useRequireTeamLead'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import AdminBreadcrumbs from '@src/components/admin/admin-breadcrumbs/AdminBreadcrumbs'
import { Box, Container, Fab } from '@material-ui/core'
import JobTemplateDetails, {
  reconTypes,
} from '@src/components/admin/job-template-details/JobTemplateDetails'
import CopyJobFieldsButton from '@src/components/admin/job-template-fields/CopyJobFieldsButton'
import JobTemplateMetadataFieldGroups from '@src/components/admin/job-template-fields/JobTemplateMetadataFieldGroups'
import JobTemplateLineItemTypes from '@src/components/admin/job-template-fields/JobTemplateLineItemTypes'
import JobTemplateExportSection from '@src/components/admin/job-template-export-section/JobTemplateExportSection'
import AddFieldButton from '@src/components/admin/job-template-editor/AddFieldButton'
import SaveIcon from '@material-ui/icons/Save'
import { sanitizeSheetsData } from '@src/utils/admin/exportFormat'
import { useHistory } from 'react-router-dom'
import CheckValidReconButton from '@src/components/admin/job-template-editor/CheckValidReconButton'
import {
  ShipmentOpsTypeOption,
  toShipmentOpsTypeOption,
} from '@src/utils/admin/shipment_ops_type_option'
import { GET_SEARCHABLE_RECORD_OPTIONS } from '@src/graphql/queries/field'
import { GET_STANDARD_DOCUMENT_TYPES } from '@src/graphql/queries/document'
import { stringifyList } from '@src/utils/string'
import { isFallback } from '@src/utils/enum'
import { maybeParseFloat } from '@src/components/data-grid/util'
import {
  THRESHOLD_RANGE_ABS_MIN,
  THRESHOLD_RANGE_ABS_MAX,
  NEG_INF_STRING,
  INF_STRING,
} from '../job-template-details/ReconThresholdSetting'
import { ExternalAssigneeOption } from '@src/components/job-viewer/ExternalAssigneeSelector'

export type JobTemplateFormValues = {
  name: string
  description: string
  slaTime: string
  reconType: JobTemplateReconType
  shipmentOpsTypes: ShipmentOpsTypeOption[]
  documentTypes: DocumentTypeOption[]
  metadataFieldGroups: InputFieldGroup[]
  lineItemTypes: InputFieldGroup[]
  credentialId: Maybe<string>
  apiPartnerId: Maybe<string>
  apiPartnerCode: Maybe<ApiPartnerCodeEnum>
  defaultExternalAssignee: Maybe<ExternalAssigneeOption>
  autoReconEnabled: Maybe<boolean>
  subtotalsRowEnabled: Maybe<boolean>
  mainTabEnabled: Maybe<boolean>
  autoPostEnabled: Maybe<boolean>
  showPostButton: Maybe<boolean>
  requireShowReconToCustomer: Maybe<boolean>
  requireEdocsPushPull: Maybe<boolean>
  requireExternalAssignee: Maybe<boolean>
  inputReconThresholdRanges: InputReconThresholdRange[]
}

const METADATA_FIELD_GROUPS_PREFIX = 'metadataFieldGroups'

const useStyles = makeStyles({
  fabWrapper: {
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
})
const getJobTemplateFormDefaultValues = (
  jobTemplateData?: Pick<Query, 'jobTemplate'> | undefined,
): JobTemplateFormValues => {
  const documentTypes = jobTemplateData?.jobTemplate.documentTypes
    ? sortBy(
        jobTemplateData.jobTemplate.documentTypes.edges.map((edge) =>
          toDocumentTypeOption(edge!.node!),
        ),
        'name',
      )
    : []
  const shipmentOpsTypes = jobTemplateData?.jobTemplate.shipmentOpsTypes
    ? sortBy(
        jobTemplateData.jobTemplate.shipmentOpsTypes.map((shipmentOpsType) =>
          toShipmentOpsTypeOption(shipmentOpsType!),
        ),
        'title',
      )
    : []
  const inputFieldGroups = jobTemplateData
    ? getInputFieldGroups(
        jobTemplateData.jobTemplate.documentTypes!.edges.map((edge) => edge!.node!),
      )
    : []

  const currentReconThresholdSetting =
    jobTemplateData?.jobTemplate.reconThresholdSettings?.edges.find(
      (setting) => !!setting.node?.enabled,
    )
  const reconThresholdRanges: InputReconThresholdRange[] = currentReconThresholdSetting
    ? currentReconThresholdSetting.node?.reconThresholdRanges.edges.map((edge) => {
        const minimum = maybeParseFloat(edge?.node?.minimum) ?? 0
        const maximum = maybeParseFloat(edge?.node?.maximum) ?? 0
        const thresholdAmount = maybeParseFloat(edge?.node?.thresholdAmount) ?? 0
        return {
          minimum: minimum <= THRESHOLD_RANGE_ABS_MIN ? NEG_INF_STRING : minimum.toFixed(2),
          maximum: maximum >= THRESHOLD_RANGE_ABS_MAX ? INF_STRING : maximum.toFixed(2),
          thresholdAmount: thresholdAmount.toFixed(2),
          usePercent: edge?.node?.usePercent ?? false,
          useAbsoluteValueMatching: edge?.node?.useAbsoluteValueMatching ?? false,
        }
      })
    : []

  const defaultExternalAssignee = jobTemplateData?.jobTemplate.defaultExternalAssignee
  const defaultExternalAssigneeOption = defaultExternalAssignee
    ? { id: defaultExternalAssignee.id, email: defaultExternalAssignee.email }
    : null

  return {
    name: jobTemplateData?.jobTemplate.name || '',
    description: jobTemplateData?.jobTemplate.description || '',
    slaTime: jobTemplateData
      ? formatDurationMsToHoursMins(jobTemplateData.jobTemplate.slaTime * 1000 * 60)
      : '',
    reconType: jobTemplateData?.jobTemplate.reconType ?? JobTemplateReconType.None,
    shipmentOpsTypes,
    documentTypes,
    metadataFieldGroups: inputFieldGroups.filter(
      ({ type }) => type !== InputFieldGroupType.LineItem,
    ),
    lineItemTypes: inputFieldGroups.filter(({ type }) => type === InputFieldGroupType.LineItem),
    credentialId: jobTemplateData?.jobTemplate.credentialId ?? null,
    apiPartnerId: jobTemplateData?.jobTemplate.apiPartner?.id ?? null,
    apiPartnerCode:
      jobTemplateData?.jobTemplate.apiPartner?.apiPartnerCode &&
      !isFallback(jobTemplateData?.jobTemplate.apiPartner?.apiPartnerCode)
        ? jobTemplateData?.jobTemplate.apiPartner?.apiPartnerCode
        : null,
    defaultExternalAssignee: defaultExternalAssigneeOption,
    autoReconEnabled: jobTemplateData?.jobTemplate.autoReconEnabled ?? false,
    subtotalsRowEnabled: jobTemplateData?.jobTemplate.subtotalsRowEnabled ?? false,
    mainTabEnabled: jobTemplateData?.jobTemplate.mainTabEnabled ?? false,
    autoPostEnabled: jobTemplateData?.jobTemplate.autoPostEnabled ?? false,
    showPostButton: jobTemplateData?.jobTemplate.showPostButton ?? false,
    requireShowReconToCustomer: jobTemplateData?.jobTemplate.requireShowReconToCustomer ?? false,
    requireEdocsPushPull: jobTemplateData?.jobTemplate.requireEdocsPushPull ?? false,
    requireExternalAssignee: jobTemplateData?.jobTemplate.requireExternalAssignee ?? false,
    inputReconThresholdRanges: reconThresholdRanges,
  }
}
/**
 * Cross-field-group validation rules such as uniqueness. Returns a mapping:
 * {[reactHookForm field name]: errorMessage}
 */
const validateInputFieldGroups = (
  inputFieldGroups: InputFieldGroup[],
  namePrefix: string,
): Record<string, string> => {
  const fieldKeys = new Set<string>()
  const autofillKeys = new Set<string>()

  const errors: Record<string, string> = {}
  if (namePrefix === METADATA_FIELD_GROUPS_PREFIX && inputFieldGroups.length === 0) {
    errors[
      `${namePrefix}`
    ] = `Job template must have at least 1 metadata field. Please add one or extraction will break.`
  }
  inputFieldGroups.forEach((inputFieldGroup, fieldGroupIndex) => {
    // field group name should be only unique for that doc type, not the whole job template
    const inputFieldKey = `${inputFieldGroup.name} [${inputFieldGroup.documentTypeId}]`
    if (fieldKeys.has(inputFieldKey)) {
      errors[
        `${namePrefix}.${fieldGroupIndex}.name`
      ] = `The field group name ${inputFieldGroup.name} was repeated. Please ensure the field names are unique.`
    } else {
      fieldKeys.add(inputFieldKey)
    }
    if (inputFieldGroup.autofillKey) {
      if (autofillKeys.has(inputFieldGroup.autofillKey)) {
        errors[
          `${namePrefix}.${fieldGroupIndex}.autofillKey`
        ] = `The field group autofill key ${inputFieldGroup.autofillKey} was repeated. Please ensure the autofill keys are unique.`
      } else {
        autofillKeys.add(inputFieldGroup.autofillKey)
      }
    }

    if (inputFieldGroup.type === InputFieldGroupType.LineItem) {
      const lineItemNames = new Set<string>()
      const lineItemAutofillKeys = new Set<string>()
      inputFieldGroup.fields.forEach((inputField, fieldIndex) => {
        if (lineItemNames.has(inputField.name)) {
          errors[`${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.name`] =
            `The line items field ${inputFieldGroup.name} has a repeated column name: ` +
            `${inputField.name}. Please ensure the column names ` +
            `of ${inputFieldGroup.name} are unique`
        } else {
          lineItemNames.add(inputField.name)
        }
        if (inputField.autofillKey) {
          if (lineItemAutofillKeys.has(inputField.autofillKey)) {
            errors[
              `${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.autofillKey`
            ] = `The line items autofill key ${inputField.autofillKey} under field group ${inputFieldGroup.name} was repeated. Please ensure the autofill keys are unique.`
          } else {
            lineItemAutofillKeys.add(inputField.autofillKey)
          }
        }
      })
    }
    inputFieldGroup.fields.forEach((inputField, fieldIndex) => {
      if (inputField.type === InputFieldType.Database && !inputField.searchableRecord) {
        errors[
          `${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.searchableRecord`
        ] = `The Database sub-field of ${
          inputFieldGroup.type === InputFieldGroupType.LineItem
            ? inputField.name
            : inputFieldGroup.name
        } is blank. Please ensure all Database sub-fields are filled in.`
      }

      const parsedValues = inputField.values ? (JSON.parse(inputField.values) as string[]) : null
      if (
        (inputFieldGroup.type === InputFieldGroupType.TextWithSuggestions ||
          inputField.type === InputFieldType.TextWithSuggestions) &&
        parsedValues?.length
      ) {
        const errorDisplayName =
          inputFieldGroup.type === InputFieldGroupType.TextWithSuggestions
            ? inputFieldGroup.name
            : `${inputFieldGroup.name} - ${inputField.name}`
        parsedValues.forEach((value) => {
          if (inputField.validatorRegex) {
            if (!new RegExp(inputField.validatorRegex).exec(value)) {
              errors[
                `${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.values`
              ] = `The field ${errorDisplayName} has an option "${value}" that does not match the field's regex.`
            }
          }
          if (inputField.invalidCharsRegex) {
            if (new RegExp(`[${inputField.invalidCharsRegex}]`).exec(value)) {
              errors[
                `${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.values`
              ] = `The field ${errorDisplayName} has an option "${value}" that contains invalid characters based on the field`
            }
          }
        })
      }
    })
    // TODO recon threshold range validation
  })
  return errors
}

const validateCargowiseConfigCredentialAndApiPartner = (
  inputCredentialId?: string | null,
  inputApiPartnerId?: string | null,
  inputApiPartnerCode?: ApiPartnerCodeEnum | null,
  reconType?: JobTemplateReconType | null,
): Record<string, string> => {
  const errors: Record<string, string> = {}
  if (reconType && reconType !== JobTemplateReconType.None) {
    const reconTypesWithRequiredConfig = Object.keys(reconTypes)
      .filter((reconType) => reconType !== JobTemplateReconType.None)
      .map((reconType) => reconTypes[reconType as JobTemplateReconType])
    const reconTypesText = stringifyList(reconTypesWithRequiredConfig)
    const isUniversalApi = !!(inputApiPartnerCode?.value == ApiPartnerCode.UniversalApi)

    if (isUniversalApi) {
      return {}
    } else if (!inputApiPartnerId) {
      errors[
        'inputApiPartner'
      ] = `The Api Partner or Cargowise Config field is required for job templates with Recon Type(s): ${reconTypesText}`
    } else {
      if (!inputCredentialId) {
        errors[
          'credentialId'
        ] = `The Credential field is required for job templates with Recon Type(s): ${reconTypesText}`
      }
    }
  }
  return errors
}

type Props = {
  companyId: string
  isNewJobTemplate: boolean
  jobTemplateId: string
}

const JobTemplateEditor: FunctionComponent<Props> = ({
  companyId,
  isNewJobTemplate,
  jobTemplateId,
}) => {
  const classes = useStyles()
  const {
    loading: jobTemplateLoading,
    data: jobTemplateData,
    error: jobTemplateError,
    refetch: refetchJobTemplate,
  } = useQuery<Pick<Query, 'jobTemplate'>>(GET_JOB_TEMPLATE, {
    variables: {
      id: jobTemplateId,
      companyId,
    },
    skip: isNewJobTemplate,
  })
  const { data: companyData } = useQuery<Pick<Query, 'company'>>(COMPANY_DATA, {
    variables: {
      id: companyId,
    },
  })
  const { data: userProfileData } = useQuery<Pick<Query, 'userProfile'>>(GET_USER_ROLES)

  const { data: searchableRecords } = useQuery<Pick<Query, 'searchableRecords'>>(
    GET_SEARCHABLE_RECORD_OPTIONS,
    {
      onError: (error) => {
        enqueueSnackbar(
          `Received error while loading searchable records: ${formatMaybeApolloError(error)}`,
          {
            variant: 'error',
          },
        )
      },
    },
  )

  const isReadOnly =
    (jobTemplateData?.jobTemplate.adminDisabled &&
      !userProfileData?.userProfile.roles.find((role) => role === UserRole.Engineer)) ||
    false
  const [upsertJobTemplate] = useUpsertJobTemplateMutation({
    refetchQueries: [{ query: GET_STANDARD_DOCUMENT_TYPES, variables: { companyId } }],
  })
  const [saveJobTemplateExportFormat] = useMutation<
    Pick<Mutation, 'saveJobTemplateExportFormat'>,
    MutationSaveJobTemplateExportFormatArgs
  >(SAVE_JOB_TEMPLATE_EXPORT_FORMAT)
  const { enqueueSnackbar } = useSnackbar()
  const formMethods = useForm<JobTemplateFormValues>({
    defaultValues: getJobTemplateFormDefaultValues(),
    mode: 'onChange',
  })
  const { handleSubmit, getValues, reset, control, setError, clearErrors } = formMethods
  const metadataFieldGroupArrayMethods = useFieldArray<
    JobTemplateFormValues,
    'metadataFieldGroups'
  >({
    control,
    name: 'metadataFieldGroups',
    keyName: 'key' as 'id',
  })
  const lineItemTypeArrayMethods = useFieldArray<JobTemplateFormValues, 'lineItemTypes'>({
    control,
    name: 'lineItemTypes',
    keyName: 'key' as 'id',
  })
  const documentTypesArrayMethods = useFieldArray<JobTemplateFormValues, 'documentTypes'>({
    control,
    name: 'documentTypes',
    keyName: 'key' as 'id',
  })
  const reconType = getValues('reconType')
  const history = useHistory()

  useEffect(() => {
    if (jobTemplateData) {
      // have to reset instead of specifying default values in useForm since jobTemplateData
      // is not present on first render of form
      reset(getJobTemplateFormDefaultValues(jobTemplateData))
    }
  }, [jobTemplateData, reset])
  const [isSaving, setIsSaving] = useState(false)
  const disableLeaveConfirm = useLeaveFormConfirm(formMethods.formState)

  const {
    hotTableRef,
    sheetIndex,
    setSheetIndex,
    sheets,
    updateSheets,
    exportType,
    toggleColumnHeader,
    setDefaultSheetsFormat,
    cacheSheetDataAndSwitch,
    getSyncedSheetsFromTableData,
    selectNewExportType,
  } = useExportFormat(
    jobTemplateData?.jobTemplate?.jobTemplateExport ?? null,
    jobTemplateLoading,
    metadataFieldGroupArrayMethods.fields,
    lineItemTypeArrayMethods.fields,
  )

  const onSubmit = useCallback(async () => {
    const {
      name,
      description,
      slaTime,
      reconType,
      shipmentOpsTypes,
      documentTypes,
      metadataFieldGroups,
      lineItemTypes,
      credentialId,
      apiPartnerId,
      apiPartnerCode,
      defaultExternalAssignee,
      autoReconEnabled,
      subtotalsRowEnabled,
      mainTabEnabled,
      autoPostEnabled,
      showPostButton,
      requireShowReconToCustomer,
      requireEdocsPushPull,
      requireExternalAssignee,
      inputReconThresholdRanges,
    } = getValues()

    const errors = {
      ...validateCargowiseConfigCredentialAndApiPartner(
        credentialId,
        apiPartnerId,
        apiPartnerCode,
        reconType,
      ),
      ...validateInputFieldGroups(metadataFieldGroups, METADATA_FIELD_GROUPS_PREFIX),
      ...validateInputFieldGroups(lineItemTypes, 'lineItemTypes'),
    }

    if (Object.keys(errors).length) {
      Object.entries(errors).forEach(([fieldName, errorMessage], index) => {
        setError(
          // setError only accepts the string literals, so we need to cast to any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          fieldName as unknown as any,
          { type: 'validate', message: errorMessage },
          { shouldFocus: index === 0 },
        )
      })
      enqueueSnackbar(`${Object.values(errors)[0]}`, { variant: 'error' })
      return
    }

    setIsSaving(true)
    try {
      await upsertJobTemplate({
        variables: {
          id: jobTemplateId,
          companyId,
          name,
          description,
          slaTime: parseHoursMinsToDurationMins(slaTime),
          reconType,
          defaultExternalAssigneeId: defaultExternalAssignee?.id,
          autoReconEnabled,
          subtotalsRowEnabled,
          mainTabEnabled,
          autoPostEnabled,
          showPostButton,
          requireShowReconToCustomer,
          requireEdocsPushPull,
          requireExternalAssignee,
          shipmentOpsTypes: shipmentOpsTypes.map((shipmentOpsType) => shipmentOpsType.value),
          documentTypes: documentTypes.map(
            ({
              id,
              name,
              derivedFromId,
              isStandard,
              collapsible,
              tableShowsPreset,
              cargowiseFileTypeId,
              autofillExtractorKey,
              isEDocPublishedByDefault,
            }) => ({
              id,
              name,
              derivedFromId,
              isStandard,
              collapsible,
              tableShowsPreset,
              cargowiseFileTypeId,
              autofillExtractorKey,
              isEDocPublishedByDefault,
            }),
          ),
          createIfMissing: isNewJobTemplate,
          fieldGroups: metadataFieldGroups.concat(lineItemTypes),
          credentialId,
          apiPartnerId,
          inputReconThresholdRanges: inputReconThresholdRanges,
        },
      })
      const newSheets = getSyncedSheetsFromTableData()
      const sanitizedSheets = sanitizeSheetsData(newSheets)
      await saveJobTemplateExportFormat({
        variables: {
          jobTemplateId,
          exportType,
          jobTemplateExportSheets: sanitizedSheets,
        },
      })
      enqueueSnackbar('Saving successful', { variant: 'success' })
      if (isNewJobTemplate) {
        disableLeaveConfirm()
        history.push(`/admin/company/${companyId}/job-type/${jobTemplateId}`)
      } else {
        await refetchJobTemplate()
      }
    } catch (error) {
      enqueueSnackbar(`Error received while saving: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    } finally {
      setIsSaving(false)
    }
  }, [
    getValues,
    enqueueSnackbar,
    setError,
    upsertJobTemplate,
    jobTemplateId,
    companyId,
    isNewJobTemplate,
    getSyncedSheetsFromTableData,
    saveJobTemplateExportFormat,
    exportType,
    disableLeaveConfirm,
    history,
    refetchJobTemplate,
  ])

  const permissionsError = useRequireTeamLead()
  if (permissionsError) {
    return permissionsError
  }
  if ((jobTemplateLoading && !isNewJobTemplate) || !userProfileData) {
    return <CenteredCircularProgress />
  }
  if (jobTemplateError) {
    return <h1>Error encountered while loading job templates: {jobTemplateError.toString()}</h1>
  }
  if (
    jobTemplateData?.jobTemplate?.companyId &&
    jobTemplateData?.jobTemplate?.companyId !== companyId
  ) {
    return (
      <h1>
        Error encountered while loading job template: Company ID mismatch. Make sure you are on the
        correct company.
      </h1>
    )
  }

  return (
    <>
      <AdminBreadcrumbs
        crumbs={[
          { path: '/admin', name: 'Companies' },
          {
            path: `/admin/company/${companyId}`,
            name: companyData?.company.name || '',
          },
        ]}
        entityName={jobTemplateData?.jobTemplate.name || ''}
        entityType='job type'
        onChangeName={
          isReadOnly
            ? undefined
            : async (newName) => {
                await upsertJobTemplate({
                  variables: {
                    id: jobTemplateId,
                    name: newName,
                  },
                })
                await refetchJobTemplate()
              }
        }
      />
      <Container>
        {isReadOnly && (
          <h1>Read-only: only users with role Engineer are allowed to edit this job type</h1>
        )}
        <FormProvider {...formMethods}>
          <JobTemplateDetails
            companyId={companyId}
            removeMetadataFieldGroups={metadataFieldGroupArrayMethods.remove}
            addMetadataFieldGroups={metadataFieldGroupArrayMethods.append}
            removeLineItemTypes={lineItemTypeArrayMethods.remove}
            addLineItemTypes={lineItemTypeArrayMethods.append}
            documentTypesArrayMethods={documentTypesArrayMethods}
            reconType={reconType}
          />
          <Box display='flex' my={1}>
            <Box flex='1'>
              <h2>Field Details</h2>
            </Box>
            {isReadOnly || (
              <CheckValidReconButton
                saveJobTemplate={handleSubmit(onSubmit)}
                isSaving={isSaving}
                jobTemplateId={jobTemplateId}
              />
            )}
            <CopyJobFieldsButton
              companyId={companyId}
              removeMetadataFieldGroups={metadataFieldGroupArrayMethods.remove}
              addMetadataFieldGroups={metadataFieldGroupArrayMethods.append}
              removeLineItemTypes={lineItemTypeArrayMethods.remove}
              addLineItemTypes={lineItemTypeArrayMethods.append}
            />
          </Box>
          <JobTemplateMetadataFieldGroups
            fieldArrayMethods={metadataFieldGroupArrayMethods}
            searchableRecordOptions={searchableRecords?.searchableRecords}
          />
          <JobTemplateLineItemTypes
            fieldArrayMethods={lineItemTypeArrayMethods}
            searchableRecordOptions={searchableRecords?.searchableRecords}
          />
          {sheets.length > 0 && (
            <JobTemplateExportSection
              hotTableRef={hotTableRef}
              sheetIndex={sheetIndex}
              setSheetIndex={setSheetIndex}
              cacheSheetDataAndSwitch={cacheSheetDataAndSwitch}
              exportType={exportType}
              sheets={sheets}
              updateSheets={updateSheets}
              toggleColumnHeader={toggleColumnHeader}
              setDefaultSheetsFormat={setDefaultSheetsFormat}
              selectNewExportType={selectNewExportType}
              getSyncedSheetsFromTableData={getSyncedSheetsFromTableData}
            />
          )}
          {isReadOnly || (
            <div className={classes.fabWrapper}>
              <AddFieldButton append={metadataFieldGroupArrayMethods.append} isLineItem={false} />
              <AddFieldButton append={lineItemTypeArrayMethods.append} isLineItem />
              <Fab
                variant='extended'
                color='primary'
                onClick={async (e) => {
                  try {
                    // need to manually clear errors from validateCargowiseConfigCredentialAndApiPartner
                    clearErrors()
                    await handleSubmit(onSubmit)(e)
                  } catch (err) {
                    enqueueSnackbar(`Error received while saving: ${formatMaybeApolloError(err)}`, {
                      variant: 'error',
                    })
                  }
                }}
                disabled={isSaving}
                data-testid='save-job-type-button'
              >
                <SaveIcon className={classes.fabIcon} />
                Save
              </Fab>
            </div>
          )}
        </FormProvider>
      </Container>
    </>
  )
}

export default JobTemplateEditor
