import { createDraftSafeSelector, createEntityAdapter } from '@reduxjs/toolkit'
import produce from 'immer'
import { DocumentTableNode, FilePageType, JobNode, JobTemplateReconType } from '@src/graphql/types'
import { getLineItems, getColumnsToDisplay } from '@src/utils/line_items'
import { calculateGridDimension, getColsAndRowsInGrid } from '@src/utils/magic_grid'
import { JobDocumentTable } from '@src/utils/shipment_form'
import { LineItemsTableMap, LineItemsTableState, rawLineItemsSelectors } from './line_items_table'
import { DocumentEditorState } from './document_editor_state'
import { MagicGridMap } from './magic_grid'
import { getInitialLineItemsTableState } from './line_items_table'
import {
  getInitialMagicGridState,
  magicGridColumnsAdapter,
  magicGridRowsAdapter,
} from './magic_grid'
import { lineItemsAdapter } from '@src/redux-features/document_editor/line_items_table'
import { BoxDimension } from '@src/types/ocr'
import { MagicGridState } from '@src/redux-features/document_editor/magic_grid'

export const sortComparer = (a: JobDocumentTable, b: JobDocumentTable): number =>
  a.orderPriority - b.orderPriority

export const documentTablesAdapter = createEntityAdapter<JobDocumentTable>({ sortComparer })

export const documentTableSelectors = documentTablesAdapter.getSelectors(
  (state: DocumentEditorState) => state.documentTables,
)

export const selectActiveDocumentTable = (state: DocumentEditorState): JobDocumentTable | null =>
  (state.activeDocumentTableId &&
    documentTableSelectors.selectById(state, state.activeDocumentTableId)) ||
  null

const getPrevTableIdOfSameType = (
  activeDocumentTable: JobDocumentTable | null,
  documentTables: JobDocumentTable[],
): string | null => {
  const tableIdToPrevTableIdMap = Object.fromEntries(
    documentTables
      .map((table) => [table.nextTableId, table.id] as [string, string])
      .filter(([nextTableId]) => nextTableId != null),
  )
  let currTableId = activeDocumentTable?.id
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const prevTableId = tableIdToPrevTableIdMap[currTableId || '']
    if (!prevTableId) {
      return null
    }
    const prevDocTable = documentTables.find((docTable) => docTable!.id === prevTableId)
    if (prevDocTable!.fieldGroup!.id === activeDocumentTable!.fieldGroup!.id) {
      return prevTableId
    } else {
      currTableId = prevTableId
    }
  }
}

const getNextTableIdOfSameType = (
  activeDocumentTable: JobDocumentTable | null,
  documentTables: JobDocumentTable[],
): string | null => {
  const tableIdToNextTableIdMap = Object.fromEntries(
    documentTables
      .map((table) => [table.id, table.nextTableId] as [string, string])
      .filter(([id]) => id != null),
  )
  let currTableId = activeDocumentTable?.id
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const nextTableId = tableIdToNextTableIdMap[currTableId || '']
    if (!nextTableId) {
      return null
    }
    const nextDocTable = documentTables.find((docTable) => docTable!.id === nextTableId)
    if (nextDocTable!.fieldGroup!.id === activeDocumentTable!.fieldGroup!.id) {
      return nextTableId
    } else {
      currTableId = nextTableId
    }
  }
}

export const selectActiveDocumentTablePreviousId = createDraftSafeSelector(
  selectActiveDocumentTable,
  documentTableSelectors.selectAll,
  (activeDocumentTable, documentTables) => {
    if (!activeDocumentTable) return null
    return getPrevTableIdOfSameType(activeDocumentTable, documentTables)
  },
)

export const selectActiveDocumentTableNextId = createDraftSafeSelector(
  selectActiveDocumentTable,
  documentTableSelectors.selectAll,
  (activeDocumentTable, documentTables) => {
    if (!activeDocumentTable) return null
    return getNextTableIdOfSameType(activeDocumentTable, documentTables)
  },
)

export const selectActiveDocumentTableLinks = createDraftSafeSelector(
  selectActiveDocumentTable,
  documentTableSelectors.selectAll,
  (activeDocumentTable, documentTables) => {
    if (!activeDocumentTable) return null
    const tableIdMap = Object.fromEntries(documentTables.map((table) => [table.id, table]))
    // map from next table ID to its previous table ID
    const tableIdToPrevTableIdMap = Object.fromEntries(
      documentTables
        .map((table) => [table.nextTableId, table.id])
        .filter(([nextTableId]) => nextTableId != null),
    )
    let headId = activeDocumentTable.id
    while (tableIdToPrevTableIdMap[headId]) {
      headId = tableIdToPrevTableIdMap[headId]
    }
    const linkedTables = []
    let currId = headId as string | null | undefined
    while (currId != null) {
      const table = tableIdMap[currId]
      linkedTables.push(table)
      currId = table.nextTableId
    }
    return linkedTables
  },
)

/*
 * This is a function that takes in a job and returns all information about
 * the tables within that job - everything from information needed to init
 * magic grid, to the actual jobdocumenttable objects themselves.
 */
export const initializeDocumentTables = (
  job: JobNode,
  enableLineItemsRowOrderPriority: boolean,
): {
  documentTables: JobDocumentTable[]
  magicGridMap: MagicGridMap
  lineItemsTableMap: LineItemsTableMap
} => {
  const jobDocuments = job
    .filePages!.edges.filter((filePageEdge) => filePageEdge?.node?.document)
    .map((filePageEdge) => filePageEdge!.node!.document!)
  const magicGridMap = {} as MagicGridMap
  const lineItemsTableMap = {} as LineItemsTableMap
  const documentTables = jobDocuments.flatMap((document, pageIdx) =>
    document.documentTables!.edges.map((documentTableEdge) => {
      const jobDocumentTable = {
        ...documentTableEdge!.node!,
        filePageId: job.filePages!.edges[pageIdx]!.node!.id,
        filePageIdx: pageIdx,
        filePageType: job.filePages!.edges[pageIdx]!.node!.type,
      } as JobDocumentTable
      const lineItems = getLineItems(jobDocumentTable, enableLineItemsRowOrderPriority)
      lineItemsTableMap[jobDocumentTable.id] = produce(
        getInitialLineItemsTableState(),
        (lineItemsTable) => {
          lineItemsTable.columns = getColumnsToDisplay(
            jobDocumentTable,
            job.filePages!.edges[pageIdx]!.node!.type === FilePageType.Excel,
            job.jobTemplate.reconType === JobTemplateReconType.Soa,
          )
          lineItemsTable.editedByUser = jobDocumentTable.editedByUser || false
          if (lineItems?.length) {
            lineItemsAdapter.setAll(lineItemsTable.lineItems, lineItems)
          }
        },
      )
      const initialTableDimension = calculateGridDimension(
        jobDocumentTable.documentFieldGroups!.edges.map(
          (documentFieldGroupEdge) => documentFieldGroupEdge!.node!,
        ),
        jobDocumentTable.documentTableColumns!.edges.map(
          (documentTableColumnEdge) => documentTableColumnEdge!.node!,
        ),
      )
      magicGridMap[jobDocumentTable.id] = produce(getInitialMagicGridState(), (magicGrid) => {
        if (initialTableDimension != null) {
          magicGrid.dimension = initialTableDimension
          const { columns, rows } = getColsAndRowsInGrid(
            jobDocumentTable.documentTableColumns!.edges.map((edge) => edge!.node!) ?? [],
            rawLineItemsSelectors.selectAll(lineItemsTableMap[jobDocumentTable.id].lineItems),
            magicGrid.dimension,
          )
          magicGridColumnsAdapter.setAll(magicGrid.columns, columns)
          magicGridRowsAdapter.setAll(magicGrid.rows, rows)
        }
      })
      return jobDocumentTable
    }),
  )
  return { documentTables, magicGridMap, lineItemsTableMap }
}

const calculateInitialTableDimension = (documentTable: DocumentTableNode): BoxDimension | null => {
  const documentTableFieldGroups = documentTable.documentFieldGroups!.edges.map(
    (documentFieldGroupEdge) => documentFieldGroupEdge!.node!,
  )
  const documentTableColumns = documentTable.documentTableColumns!.edges.map(
    (documentTableColumnEdge) => documentTableColumnEdge!.node!,
  )
  return calculateGridDimension(documentTableFieldGroups, documentTableColumns)
}

const getNextMagicGridMap = (
  initialTableDimension: BoxDimension | null,
  documentTable: DocumentTableNode,
  lineItemsTableMap: LineItemsTableState,
): MagicGridState => {
  return produce(getInitialMagicGridState(), (magicGrid) => {
    if (initialTableDimension != null) {
      magicGrid.dimension = initialTableDimension
      const { columns, rows } = getColsAndRowsInGrid(
        documentTable.documentTableColumns!.edges.map((edge) => edge!.node!) ?? [],
        rawLineItemsSelectors.selectAll(lineItemsTableMap.lineItems),
        magicGrid.dimension,
      )
      magicGridColumnsAdapter.setAll(magicGrid.columns, columns)
      magicGridRowsAdapter.setAll(magicGrid.rows, rows)
    }
  })
}

export const updateDocumentTablesReducer = (
  state: DocumentEditorState,
  documentTables: DocumentTableNode[],
): void => {
  for (const documentTable of documentTables) {
    if (documentTable.id !== state.activeDocumentTableId) {
      const initialTableDimension = calculateInitialTableDimension(documentTable)
      const magicGridMap = getNextMagicGridMap(
        initialTableDimension,
        documentTable,
        state.lineItemsTableMap[documentTable.id],
      )
      state.magicGridMap[documentTable.id] = magicGridMap
      documentTablesAdapter.updateOne(state.documentTables, {
        id: documentTable.id,
        changes: { ...documentTable },
      })
    }
  }
}
