import { FunctionComponent } from 'react'
import { Box, LinearProgress, Typography } from '@material-ui/core'
import humanizeDuration from 'humanize-duration'
import theme from '@src/utils/theme'

export enum UploadPhase {
  UPLOADING,
  PROCESSING,
}

type Props = {
  progress: number
  valueBuffer?: number
  uploadPhase: UploadPhase
  etaSeconds: number | null
}

const UploadingEta: FunctionComponent<Props> = ({
  progress,
  valueBuffer,
  uploadPhase,
  etaSeconds,
}) => {
  return (
    <>
      <p>
        You can stay on this page while waiting for uploading and processing to complete, or you can
        leave this page and return after processing finishes.
      </p>
      <Typography variant='h2'>
        {uploadPhase === UploadPhase.UPLOADING
          ? 'Phase 1 of 2: Uploading...'
          : 'Phase 2 of 2: Processing...'}
      </Typography>
      <LinearProgress variant='buffer' value={progress} valueBuffer={valueBuffer ?? progress} />
      {etaSeconds != null && (
        <Box textAlign='center' fontWeight={theme.typography.fontWeightBold}>
          {humanizeDuration(etaSeconds * 1000, { round: true })} left
        </Box>
      )}
    </>
  )
}

export default UploadingEta
