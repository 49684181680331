import { FunctionComponent, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { makeStyles } from '@material-ui/styles'

import { FileNode } from '@src/graphql/types'
import { PAGE_PREVIEW_DIMENSIONS } from '@src/utils/app_constants'
import { formatFilePreviewName } from '@src/utils/file'
import theme from '@src/utils/theme'

const useStyles = makeStyles({
  divider: {
    margin: `${theme.spacing(1)}px 0`,
  },
  messageBody: {
    width: '70%',
    height: `${PAGE_PREVIEW_DIMENSIONS.MESSAGE_PANEL_HEIGHT}px`,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[300],
  },
  message: {
    whiteSpace: 'pre-wrap',
  },
  filePagePreview: {
    width: '70%',
    height: 'auto',
  },
})

type Props = {
  file: FileNode
  defaultPageIndex: number
  closeDialog: () => unknown
}

const PagePreviewDialog: FunctionComponent<Props> = ({ file, defaultPageIndex, closeDialog }) => {
  const classes = useStyles()
  const [pageIndex, setPageIndex] = useState(defaultPageIndex)

  let previewPageName = ''
  const previewPage = file.pages.edges[pageIndex]?.node
  previewPageName = formatFilePreviewName(file.filename || '', previewPage?.pageNumber || 0)

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      open={true}
      onClose={closeDialog}
      aria-labelledby={previewPageName}
    >
      <DialogTitle id={previewPageName}>{previewPageName}</DialogTitle>
      <DialogContent>
        <Box display='flex' justifyContent='center'>
          <Paper className={classes.messageBody} elevation={3}>
            {file?.emailSubject && (
              <>
                <Typography variant='h3'>{file.emailSubject}</Typography>
                <Divider className={classes.divider} />
              </>
            )}
            <Typography variant='h3' gutterBottom>
              Message Body
            </Typography>
            <Typography variant='h4' className={classes.message}>
              {file?.message || 'No message specified'}
            </Typography>
          </Paper>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Button onClick={() => setPageIndex(pageIndex - 1)} disabled={pageIndex === 0}>
            <NavigateBeforeIcon fontSize='large' />
          </Button>
          <img
            className={classes.filePagePreview}
            draggable='false'
            src={file.pages.edges[pageIndex]?.node?.imageUrl || undefined}
            alt={previewPageName}
          />
          <Button
            onClick={() => setPageIndex(pageIndex + 1)}
            disabled={pageIndex === file.pages.edges.length - 1}
          >
            <NavigateNextIcon fontSize='large' />
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default PagePreviewDialog
