import { FunctionComponent } from 'react'
import { useParams } from 'react-router'
import ImportPage from '.'

const ImportPageWithId: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>()
  return <ImportPage initialChargeCodeUploadTaskId={id} />
}

export default ImportPageWithId
