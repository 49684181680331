import {
  FallbackValue,
  ReconResultTypeEnum,
  FieldTypeEnum,
  EDocsDataTargetTypeEnum,
  TaskFilterColumnEnum,
  MatchingCriteriaTypeEnum,
  ReconAsyncStatusWithFallback,
  CwTargetModuleEnum,
  UserRoleEnum,
  MetadataReconResultKeyEnum,
  ApiPartnerCodeEnum,
  ApiPartnerTmsTypeEnum,
} from '@src/graphql/types'

export type EnumOrFallback =
  | FallbackValue
  | ReconResultTypeEnum
  | FieldTypeEnum
  | EDocsDataTargetTypeEnum
  | TaskFilterColumnEnum
  | MatchingCriteriaTypeEnum
  | ReconAsyncStatusWithFallback
  | CwTargetModuleEnum
  | UserRoleEnum
  | MetadataReconResultKeyEnum
  | ApiPartnerCodeEnum
  | ApiPartnerTmsTypeEnum

export const isFallback = (enumOrFallback: EnumOrFallback): enumOrFallback is FallbackValue => {
  return (enumOrFallback && enumOrFallback.isFallback) as boolean
}
