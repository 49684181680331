import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core'
import { SpreadsheetDataColumn } from '@src/utils/data-grid'
import theme from '@src/utils/theme'
import { FunctionComponent } from 'react'

type Props = {
  rows: string[][]
  columns: SpreadsheetDataColumn[]
}

const useStyles = makeStyles<Theme>({
  tableHead: {
    backgroundColor: theme.palette.grey[200],
  },
})

const ReadOnlyTable: FunctionComponent<Props> = ({ rows, columns }) => {
  const classes = useStyles()

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.key}>{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={`table-row-${rowIndex}`}>
                {columns.map((column, colIndex) => (
                  <TableCell key={`table-cell-${rowIndex}-${colIndex}`}>{row[colIndex]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ReadOnlyTable
