import { gql } from '@apollo/client'

export const CREATE_CUSTOM_DATABASE_TYPE = gql`
  mutation createCustomDatabaseType($inputCustomDatabaseType: InputCustomDatabaseType!) {
    createCustomDatabaseType(inputCustomDatabaseType: $inputCustomDatabaseType) {
      ok
    }
  }
`

export const EDIT_CUSTOM_DATABASE_TYPE = gql`
  mutation editCustomDatabaseType(
    $customDatabaseTypeId: UUID!
    $inputCustomDatabaseType: InputCustomDatabaseType!
  ) {
    editCustomDatabaseType(
      customDatabaseTypeId: $customDatabaseTypeId
      inputCustomDatabaseType: $inputCustomDatabaseType
    ) {
      id
    }
  }
`

export const DELETE_CUSTOM_DATABASE_TYPE = gql`
  mutation deleteCustomDatabaseType($customDatabaseTypeId: UUID!) {
    deleteCustomDatabaseType(customDatabaseTypeId: $customDatabaseTypeId) {
      ok
    }
  }
`

export const CREATE_CUSTOM_DATABASE = gql`
  mutation createCustomDatabase($inputCustomDatabase: InputCustomDatabase!, $apiPartnerId: UUID!) {
    createCustomDatabase(inputCustomDatabase: $inputCustomDatabase, apiPartnerId: $apiPartnerId) {
      ok
    }
  }
`

export const EDIT_CUSTOM_DATABASE = gql`
  mutation editCustomDatabase(
    $customDatabaseId: UUID!
    $inputCustomDatabase: InputCustomDatabase!
  ) {
    editCustomDatabase(
      customDatabaseId: $customDatabaseId
      inputCustomDatabase: $inputCustomDatabase
    ) {
      id
    }
  }
`

export const DELETE_CUSTOM_DATABASE = gql`
  mutation deleteCustomDatabase($customDatabaseId: UUID!) {
    deleteCustomDatabase(customDatabaseId: $customDatabaseId) {
      ok
    }
  }
`
