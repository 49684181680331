import { HASH_BG_COLOR_PALETTE } from '@src/utils/app_constants'

/**
 * gets a background color (assuming foreground is white) based on the given string's hash
 */
export const getBgColorFromHash = (s: string): string => {
  // low quality hash fn since adding a dep for this seems overkill
  let hash = 0
  for (let i = 0; i < s.length; i += 1) {
    hash = (17 * hash + 31 * s.codePointAt(i)! + 7) % HASH_BG_COLOR_PALETTE.length
  }
  return HASH_BG_COLOR_PALETTE[hash]
}
