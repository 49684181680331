import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { FormControlLabel, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { Autocomplete } from '@material-ui/lab'
import theme from '@src/utils/theme'
import { GET_JOB_TEMPLATES } from '@src/graphql/queries/jobTemplate'
import { CredentialNode, Query } from '@src/graphql/types'
import ControllerTextField from '@src/components/controller-text-field/ControllerTextField'
import Checkbox from '@material-ui/core/Checkbox'

export type CredentialFormValues = {
  workflow: string
  username: string
  password: string
  apiEndpoint: string
  verifySsl: boolean
  jobTemplates: PartialJobTemplateNode[]
  hostOverride: string
}

/**
 * Only taking what we need from the JobTemplateNode to display options and save
 */
export type PartialJobTemplateNode = {
  id: string
  name: string
}

type Props = {
  credential?: CredentialNode | null
  isOpen: boolean
  close: () => void
  onSubmit: (credential: CredentialFormValues) => Promise<void>
}

const HIDDEN_STRING = '[hidden]'

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  submitButton: {
    textAlign: 'right',
  },
})

const CreateUpdateCredentialDialog: FunctionComponent<Props> = ({
  credential,
  isOpen,
  close,
  onSubmit,
}) => {
  const classes = useStyles()
  const formMethods = useForm<CredentialFormValues>()
  const { handleSubmit, reset, trigger } = formMethods
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: jobTemplates, loading: jobTemplatesLoading } =
    useQuery<Pick<Query, 'jobTemplates'>>(GET_JOB_TEMPLATES)

  const jobTemplateOptions =
    jobTemplates?.jobTemplates.map((jobTemplate) => ({
      id: jobTemplate.id,
      name: jobTemplate.name,
    })) || ([] as PartialJobTemplateNode[])

  const getCredentialFormValues = (
    credential: CredentialNode | null | undefined,
  ): CredentialFormValues => {
    return {
      workflow: credential?.workflow ?? '',
      username: credential?.username ?? '',
      password: credential?.password ?? '',
      apiEndpoint: credential?.apiEndpoint ?? '',
      jobTemplates: credential?.jobTemplates?.edges.map((jobTemplate) => jobTemplate.node) ?? [],
      verifySsl: credential?.verifySsl ?? true,
      hostOverride: credential?.hostOverride ?? '',
    }
  }
  useEffect(() => {
    // reset so the values on the form get updated according to the selected credential
    reset(getCredentialFormValues(credential))
  }, [credential, reset])

  const isUpdating = !!credential

  const submitForm = async (): Promise<void> => {
    const isFormValid = await trigger()
    if (!isFormValid) {
      return
    }
    setIsSubmitting(true)
    await handleSubmit(onSubmit)()
    setIsSubmitting(false)
    reset()
    close()
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialog }}
      onClose={close}
      open={isOpen}
    >
      <IconButton
        aria-label='duplicate'
        onClick={close}
        disabled={isSubmitting}
        className={classes.close}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle disableTypography>
        <Typography variant='h3'>{`${isUpdating ? 'Update' : 'Create'} Credential`}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box pb={2}>
          <FormProvider {...formMethods}>
            <Box mb={1}>
              <Typography>Username</Typography>
              <ControllerTextField
                name='username'
                friendlyName='Username'
                defaultValue={credential?.username}
                rules={{ required: true }}
              />
            </Box>
            <Box mb={1}>
              <Typography>Password</Typography>
              <ControllerTextField
                name='password'
                friendlyName='Password'
                placeHolder={isUpdating ? HIDDEN_STRING : ''}
                rules={isUpdating ? {} : { required: true }}
              />
            </Box>
            <Box mb={1}>
              <Typography>Workflow</Typography>
              <ControllerTextField
                name='workflow'
                friendlyName='Workflow'
                defaultValue={credential?.workflow}
                rules={{ required: true }}
              />
            </Box>
            <Box mb={2}>
              <Typography>API Endpoint</Typography>
              <ControllerTextField
                name='apiEndpoint'
                friendlyName='API Endpoint'
                defaultValue={credential?.apiEndpoint}
                /* Validating against .svc as this causes 400s for ops and can easily be caught here
                   Original thread: https://expedock.slack.com/archives/CPWM5FNUQ/p1695737660406749
                */
                rules={{
                  validate: (url: string) =>
                    !url.endsWith('eadapterstreamedservice.svc') ||
                    'API Endpoint URL cannot end with eadapterstreamedservice.svc. This usually ends in /eadaptor, or will contain a middleware endpoint like hook.us1.make.com.',
                }}
              />
            </Box>
            <Box mb={2}>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
                    }
                    label={'Verify SSL (DO NOT TURN OFF UNLESS TOLD BY ENGINEERING)'}
                    labelPlacement='end'
                  />
                )}
                name={`verifySsl`}
                defaultValue={credential?.verifySsl}
              />
            </Box>
            <Box mb={2}>
              <Typography>Host Override</Typography>
              <ControllerTextField
                name='hostOverride'
                friendlyName='Host Override (ENTERPRISE ONLY -- ONLY SET IF TOLD BY ENGINEERING)'
                defaultValue={credential?.hostOverride}
              />
            </Box>
            <Box mb={2}>
              <Typography>Job Template</Typography>
              <Controller
                render={({ field: { value, onBlur, onChange } }) => {
                  const onAutocompleteChange = (
                    _: ChangeEvent<unknown>,
                    newValue: PartialJobTemplateNode[],
                  ): void => {
                    onChange(newValue)
                  }
                  return (
                    <Autocomplete
                      multiple
                      disabled={jobTemplatesLoading}
                      options={jobTemplateOptions}
                      onChange={onAutocompleteChange}
                      onBlur={onBlur}
                      value={value}
                      getOptionLabel={(option: PartialJobTemplateNode) => option.name}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => <TextField {...params} variant='standard' />}
                    />
                  )
                }}
                name='jobTemplates'
                defaultValue={
                  credential?.jobTemplates?.edges.map((jobTemplate) => ({
                    id: jobTemplate.node.id,
                    name: jobTemplate.node.name,
                  })) ?? []
                }
              />
            </Box>
            <Box className={classes.submitButton}>
              <Button
                size='large'
                color='primary'
                variant='contained'
                onClick={submitForm}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CreateUpdateCredentialDialog
