import { DeployEnvironment, getDeployEnvironment } from '@src/utils/environment'
import { getDeviceDetails } from '@src/utils/device_details'
import { init, setUserId, track } from '@amplitude/analytics-browser'
import { useEventAttributes } from '@src/utils/observability/useEventAttributes'
import { useEffect } from 'react'

const environmentAPIKeyMapping = {
  [DeployEnvironment.DEVELOPMENT]: undefined,
  [DeployEnvironment.ENGINEERING]: undefined,
  [DeployEnvironment.STAGING]: '62f3e2b5d63c6d2f3eb0ac24982c8f7b',
  [DeployEnvironment.PRODUCTION]: 'd06531d7ab00c75d6ea3aab2cf63b4ba',
}
const deviceDetails = getDeviceDetails()
const routeDir = '/web'

const environment = getDeployEnvironment()
// set this local storage key for debugging
// You can use this key for dev't: 'ca9344cea0d030a7e3d3790f3c66c5e1',
export const AMPLITUDE_API_KEY: string | undefined =
  localStorage.forceAmplitudeApiKey || environmentAPIKeyMapping[environment]

export const useAmplitude = (): void => {
  const { userAttributes, loading } = useEventAttributes()
  useEffect(() => {
    if (AMPLITUDE_API_KEY && !loading && userAttributes.email) {
      const startTracking = async (): Promise<void> => {
        init(AMPLITUDE_API_KEY, userAttributes.email!, {
          serverUrl: `${window.location.protocol}//${window.location.host}/ev-a/2/httpapi`,
        })
      }
      void startTracking()
    }
  }, [loading, userAttributes.email])
}

export const logAmplitudeEvent = (
  userId: string,
  eventType: string,
  eventProperties?: Record<string, any>,
): void => {
  if (!AMPLITUDE_API_KEY) return
  if (!eventProperties) eventProperties = { page: '' }
  eventProperties.page = `${routeDir}${eventProperties.page}`
  setUserId(userId)
  void track(eventType, {
    user_id: userId,
    event_properties: eventProperties,
    platform: deviceDetails.browserName,
    os_name: deviceDetails.osName,
    os_version: deviceDetails.osVersion,
    device_brand: deviceDetails.mobileVendor,
    device_model: deviceDetails.mobileModel,
  })
}
