import { createContext, FunctionComponent, useContext, useEffect, useMemo } from 'react'
import { ApolloError } from '@apollo/client/errors'
import { Auth0AccessTokenContext } from './Auth0AccessTokenContext'
import { GET_USER_PROFILE } from '@src/graphql/queries/user'
import { AuthProfile, Query } from '@src/graphql/types'
import { useLazyQuery } from '@apollo/client/react/hooks/useLazyQuery'

type UserProfileContextType = {
  userProfile?: AuthProfile
  loading: boolean
  error?: ApolloError
}

export const UserProfileContext = createContext<UserProfileContextType>({
  userProfile: undefined,
  loading: false,
  error: undefined,
})

export const UserProfileProvider: FunctionComponent = ({ children }) => {
  const accessToken = useContext(Auth0AccessTokenContext)
  const [getUserProfile, { data: profileData, loading: profileLoading, error: profileError }] =
    useLazyQuery<Pick<Query, 'userProfile'>>(GET_USER_PROFILE)
  useEffect(() => {
    if (accessToken) {
      void getUserProfile()
    }
  }, [accessToken, getUserProfile])
  const userProfileContextValue = useMemo(() => {
    return {
      userProfile: profileData?.userProfile,
      loading: profileLoading,
      error: profileError,
    } as UserProfileContextType
  }, [profileData, profileLoading, profileError])
  return (
    <UserProfileContext.Provider value={userProfileContextValue}>
      {children}
    </UserProfileContext.Provider>
  )
}
