import { FunctionComponent, ReactElement, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import { DialogTitle, Theme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { FilePageNode, EDocsDataTargetType, InputEDocument } from '@src/graphql/types'
import { useState } from 'react'
import EDocsPushConfirmTable from '@src/components/edocs/EDocsPushConfirmTable'
import { formatCwFileType, initializeInputEDocuments } from '@src/utils/edocs'
import { isPdfOrImage } from '@src/utils/file'

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

type Props = {
  close: () => void
  filePages: FilePageNode[]
  loading: boolean
  formattedFindCwDataResults: ReactElement | null
  selectedDataTargetType: EDocsDataTargetType
  exportToApiPartner: boolean
  disablePushToShipment: boolean
  pushEdocs: (
    dataTargetType: EDocsDataTargetType,
    inputEDocuments: InputEDocument[],
    exportToApiPartner: boolean,
  ) => Promise<void>
}

const EdocsPushConfirmDialog: FunctionComponent<Props> = ({
  close,
  filePages,
  pushEdocs,
  loading,
  formattedFindCwDataResults,
  selectedDataTargetType,
  exportToApiPartner,
  disablePushToShipment,
}) => {
  const classes = useStyles()
  const displayFindCWDataResults =
    !exportToApiPartner &&
    (selectedDataTargetType === EDocsDataTargetType.Consol ||
      selectedDataTargetType === EDocsDataTargetType.Shipment)

  const [filePagesMap, setFilePagesMap] = useState({} as Record<string, FilePageNode>)
  const [inputEDocuments, setInputEDocuments] = useState([] as InputEDocument[])

  useEffect(() => {
    const groupedFilePages = {} as Record<string, FilePageNode[]>
    const filePageMapping = {} as Record<string, FilePageNode>

    filePages.forEach((filePage) => {
      filePageMapping[filePage.id] = filePage

      let groupKey
      // If a filePage is from a PDF or an image,
      // then they should be grouped together with other
      // PDFs and images of the same document type.
      // Otherwise, they should be separate.
      if (exportToApiPartner) {
        const docTypeId = filePage!.document!.documentType!.id
        const isPdfOrImageBool = isPdfOrImage(filePage!.file.filename)
        groupKey = `${docTypeId}_${isPdfOrImageBool ? isPdfOrImageBool : filePage.id}`
      } else {
        const cwFileType = filePage!.document!.documentType!.cargowiseFileType
        if (cwFileType) {
          groupKey = formatCwFileType(cwFileType)
        }
      }

      if (groupKey) {
        const groupedFilePageIds = groupedFilePages[groupKey]
        if (!groupedFilePageIds) {
          groupedFilePages[groupKey] = []
        }
        groupedFilePages[groupKey].push(filePage)
      }
    })
    setFilePagesMap(filePageMapping)

    const eDocuments = initializeInputEDocuments(groupedFilePages)
    setInputEDocuments(eDocuments)
  }, [filePages, exportToApiPartner])

  const toggleEdocIsPublished = (idx: number): void => {
    const newInputEDocs = [...inputEDocuments]
    newInputEDocs[idx].isPublished = !inputEDocuments[idx].isPublished
    setInputEDocuments(newInputEDocs)
  }

  return (
    <Dialog
      maxWidth='md'
      classes={{ paper: classes.dialog }}
      open={true}
      disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
      onClose={close}
    >
      <Box className={classes.close}>
        <CloseIcon onClick={close} data-testid='close-btn' />
      </Box>
      <DialogTitle>
        {disablePushToShipment
          ? "e-Documents can't be uploaded"
          : 'Review e-Documents to be uploaded'}
      </DialogTitle>
      <DialogContent>
        <Box py={1}>
          {displayFindCWDataResults && (
            <Typography gutterBottom>{formattedFindCwDataResults}</Typography>
          )}

          <EDocsPushConfirmTable
            inputEDocuments={inputEDocuments}
            filePagesMap={filePagesMap}
            toggleEdocIsPublished={toggleEdocIsPublished}
            cancelHandler={close}
            pushEdocsHandler={() =>
              pushEdocs(selectedDataTargetType, inputEDocuments, exportToApiPartner)
            }
            disablePushEdocsButton={loading || disablePushToShipment}
            exportToApiPartner={exportToApiPartner}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default EdocsPushConfirmDialog
