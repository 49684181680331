import { FunctionComponent, useCallback } from 'react'
import type { UseFieldArrayReturn } from 'react-hook-form'
import JobTemplateFieldGroup from '@src/components/admin/job-template-fields/JobTemplateFieldGroup'
import { InputFieldGroup, Maybe } from '@src/graphql/types'
import { Box } from '@material-ui/core'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { makeStyles } from '@material-ui/styles'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { JobTemplateFormValues } from '@src/components/admin/job-template-editor/JobTemplateEditor'
import { SearchableRecordOptions } from '../../../graphql/types'

type Props = {
  fieldArrayMethods: UseFieldArrayReturn<JobTemplateFormValues, 'metadataFieldGroups'>
  searchableRecordOptions: Maybe<SearchableRecordOptions[]> | undefined
}

const useStyles = makeStyles({
  dragIcon: {
    cursor: 'grab',
  },
  fieldGroupDraggable: {
    display: 'flex',
  },
})

const JobTemplateMetadataFieldGroups: FunctionComponent<Props> = ({
  fieldArrayMethods,
  searchableRecordOptions,
}) => {
  const classes = useStyles()
  const { fields: fieldGroups, move } = fieldArrayMethods
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return
      }
      move(result.source.index, result.destination.index)
    },
    [move],
  )
  return (
    <>
      <h3>Metadata Fields</h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='field-groups' type='field-groups'>
          {(provided) => (
            // eslint-disable-next-line @typescript-eslint/unbound-method
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {fieldGroups.map((fieldGroup, index) => {
                return (
                  <Draggable
                    draggableId={`field-group-${fieldGroup.id}`}
                    index={index}
                    key={fieldGroup.id}
                  >
                    {(draggableProvided) => (
                      <div
                        className={classes.fieldGroupDraggable}
                        // eslint-disable-next-line @typescript-eslint/unbound-method
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                      >
                        <div {...draggableProvided.dragHandleProps}>
                          <DragIndicatorIcon className={classes.dragIcon} />
                        </div>
                        <Box flex='1'>
                          <JobTemplateFieldGroup
                            fieldGroup={fieldGroup as unknown as InputFieldGroup}
                            index={index}
                            fieldArrayMethods={fieldArrayMethods}
                            isLineItem={false}
                            searchableRecordOptions={searchableRecordOptions}
                          />
                        </Box>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

export default JobTemplateMetadataFieldGroups
