import { FunctionComponent, MouseEvent, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { USER_COMPANIES } from '@src/graphql/queries/company'
import {
  Query,
  QueryUserJobTemplatesArgs,
  TaskFilterColumn,
  TaskFilterOperation,
} from '@src/graphql/types'
import theme from '@src/utils/theme'
import { useQuery } from '@apollo/client'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import FilterListIcon from '@material-ui/icons/FilterList'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/styles'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { JOB_OPERATORS, USER_JOB_TEMPLATES } from '@src/graphql/queries/job'
import { formatDateForTextField } from '@src/utils/date'
import { Popover } from '@material-ui/core'

const taskTypeValues = ['Live', 'Test'] as const
type TaskTypeValue = (typeof taskTypeValues)[keyof typeof taskTypeValues]
const columnToNameMap = {
  [TaskFilterColumn.TaskType]: 'Live or Test',
  [TaskFilterColumn.Company]: 'Company',
  [TaskFilterColumn.DateCreated]: 'Date & Time Created',
  [TaskFilterColumn.DateReceived]: 'Date & Time Received',
  [TaskFilterColumn.DateConfirmed]: 'Date & Time Confirmed',
  [TaskFilterColumn.JobQa]: 'Job QA',
  [TaskFilterColumn.JobOwner]: 'Job Owner',
  [TaskFilterColumn.JobType]: 'Job Type',
}
const taskOperationMap = {
  [TaskFilterColumn.DateCreated]: [
    TaskFilterOperation.IsNot,
    TaskFilterOperation.Is,
    TaskFilterOperation.Before,
    TaskFilterOperation.After,
  ],
  [TaskFilterColumn.DateConfirmed]: [
    TaskFilterOperation.IsNot,
    TaskFilterOperation.Is,
    TaskFilterOperation.Before,
    TaskFilterOperation.After,
  ],
  [TaskFilterColumn.DateReceived]: [
    TaskFilterOperation.IsNot,
    TaskFilterOperation.Is,
    TaskFilterOperation.Before,
    TaskFilterOperation.After,
  ],
  [TaskFilterColumn.Company]: [TaskFilterOperation.Contains, TaskFilterOperation.DoesNotContain],
  [TaskFilterColumn.TaskType]: [TaskFilterOperation.Is, TaskFilterOperation.IsNot],
  [TaskFilterColumn.JobQa]: [TaskFilterOperation.Contains, TaskFilterOperation.DoesNotContain],
  [TaskFilterColumn.JobOwner]: [TaskFilterOperation.Contains, TaskFilterOperation.DoesNotContain],
  [TaskFilterColumn.JobType]: [TaskFilterOperation.Contains, TaskFilterOperation.DoesNotContain],
} as const

export type TaskFilter =
  | {
      id: string
      column: TaskFilterColumn.TaskType
      operation?: (typeof taskOperationMap)[TaskFilterColumn.TaskType][number]
      value?: TaskTypeValue[]
    }
  | {
      id: string
      column: TaskFilterColumn.Company
      operation?: (typeof taskOperationMap)[TaskFilterColumn.Company][number]
      value?: string[]
    }
  | {
      id: string
      column:
        | TaskFilterColumn.DateConfirmed
        | TaskFilterColumn.DateReceived
        | TaskFilterColumn.DateCreated
      operation?: (typeof taskOperationMap)[TaskFilterColumn.DateReceived][number]
      value?: string[]
    }
  | {
      id: string
      column: TaskFilterColumn.JobQa | TaskFilterColumn.JobOwner | TaskFilterColumn.JobType
      operation?: (typeof taskOperationMap)[TaskFilterColumn.JobQa][number]
      value?: string[]
    }

const useStyles = makeStyles({
  title: {
    marginBottom: theme.spacing(1),
  },
  taskTypeButton: {
    marginBottom: theme.spacing(1),
  },
  taskTypeField: {
    width: '160px',
    marginRight: theme.spacing(1),
  },
  taskSymbolField: {
    width: '150px',
    marginRight: theme.spacing(1),
  },
  taskValueField: {
    width: '200px',
    marginRight: theme.spacing(1),
  },
  taskClearButton: {},
})

type Props = {
  updateTaskFilterBuffer: (filters: TaskFilter[]) => void
  taskFilters: TaskFilter[]
}

const TaskFilterMenu: FunctionComponent<Props> = ({ taskFilters, updateTaskFilterBuffer }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null as Element | null)
  const [showAddFilter, setShowAddFilter] = useState(false)
  const { data: userCompanies, loading: userCompaniesLoading } =
    useQuery<Pick<Query, 'companies'>>(USER_COMPANIES)
  const { data: userJobTypes, loading: isJobTypesLoading } = useQuery<
    Pick<Query, 'userJobTemplates'>,
    QueryUserJobTemplatesArgs
  >(USER_JOB_TEMPLATES)
  const { data: operatorsData, loading: isLoadingOperators } =
    useQuery<Pick<Query, 'jobOperators'>>(JOB_OPERATORS)
  const companies = userCompanies?.companies || []
  const companyIds = companies.map(({ id }) => id)
  const companyIdMap = Object.fromEntries(companies.map((company) => [company.id, company]))
  const jobTypes = userJobTypes?.userJobTemplates || []
  const jobTypeIds = jobTypes.map(({ id }) => id)
  const jobTypeIdMap = Object.fromEntries(jobTypes.map((jobType) => [jobType.id, jobType]))
  const operators = operatorsData?.jobOperators || []
  const operatorIds = operators.map(({ id }) => id)
  const operatorIdMap = Object.fromEntries(operators.map((operator) => [operator.id, operator]))

  const handleClick = (e: MouseEvent): void => setAnchorEl(e.currentTarget)
  const handleClose = (): void => {
    setShowAddFilter(false)
    setAnchorEl(null)
  }

  const onUpdateTaskFilter = (id: string, updatedTaskFilter: TaskFilter): void => {
    const idx = taskFilters.findIndex((filter) => filter.id === id)
    updateTaskFilterBuffer([
      ...taskFilters.slice(0, idx),
      updatedTaskFilter,
      ...taskFilters.slice(idx + 1),
    ])
  }

  const onDeleteTaskFilter = (id: string): void => {
    updateTaskFilterBuffer(taskFilters.filter((filter) => filter.id !== id))
  }

  const onAddNewTaskFilter = (newFilter: TaskFilter): void => {
    updateTaskFilterBuffer([...taskFilters, newFilter])
    setShowAddFilter(false)
  }

  const renderTaskFilterValue = (filter: TaskFilter): JSX.Element | null => {
    switch (filter.column) {
      case TaskFilterColumn.TaskType:
        return (
          <Select
            className={classes.taskValueField}
            value={filter.value?.[0] || ''}
            onChange={(e) =>
              onUpdateTaskFilter(filter.id, {
                ...filter,
                value: [e.target.value as TaskTypeValue],
                column: TaskFilterColumn.TaskType,
              })
            }
          >
            {taskTypeValues.map((taskType) => (
              <MenuItem value={taskType} key={taskType}>
                {taskType}
              </MenuItem>
            ))}
          </Select>
        )
      case TaskFilterColumn.Company:
        return userCompaniesLoading ? (
          <CenteredCircularProgress />
        ) : (
          <Autocomplete
            className={classes.taskValueField}
            multiple
            filterSelectedOptions
            options={companyIds}
            getOptionLabel={(companyId) => companyIdMap[companyId]?.name ?? ''}
            onChange={(_, value) => onUpdateTaskFilter(filter.id, { ...filter, value })}
            getOptionSelected={(option, value) => value === option}
            value={filter.value || []}
            renderInput={(params) => (
              <TextField {...params} variant='standard' placeholder='Companies' />
            )}
          />
        )
      case TaskFilterColumn.DateCreated:
      case TaskFilterColumn.DateReceived:
      case TaskFilterColumn.DateConfirmed:
        return (
          <TextField
            className={classes.taskValueField}
            type='datetime-local'
            fullWidth
            value={filter.value?.[0] ? formatDateForTextField(filter.value?.[0]) : ''}
            onChange={(e) => {
              onUpdateTaskFilter(filter.id, { ...filter, value: [e.target.value] })
            }}
            name='dateTime'
            InputLabelProps={{
              shrink: true,
            }}
          />
        )
      case TaskFilterColumn.JobQa:
      case TaskFilterColumn.JobOwner:
        return isLoadingOperators ? (
          <CenteredCircularProgress />
        ) : (
          <Autocomplete
            className={classes.taskValueField}
            multiple
            filterSelectedOptions
            options={operatorIds}
            getOptionLabel={(operatorId) =>
              operatorIdMap[operatorId]?.name || operatorIdMap[operatorId]?.email
            }
            onChange={(_, value) => onUpdateTaskFilter(filter.id, { ...filter, value })}
            getOptionSelected={(option, value) => value === option}
            value={filter.value || []}
            renderInput={(params) => (
              <TextField {...params} variant='standard' placeholder='Operators' />
            )}
          />
        )
      case TaskFilterColumn.JobType:
        return isJobTypesLoading ? (
          <CenteredCircularProgress />
        ) : (
          <Autocomplete
            className={classes.taskValueField}
            multiple
            filterSelectedOptions
            options={jobTypeIds}
            getOptionLabel={(jobTypeId) => jobTypeIdMap[jobTypeId]?.name}
            onChange={(_, value) => onUpdateTaskFilter(filter.id, { ...filter, value })}
            getOptionSelected={(option, value) => value === option}
            value={filter.value || []}
            renderInput={(params) => (
              <TextField {...params} variant='standard' placeholder='Job Type' />
            )}
          />
        )
      default:
        return null
    }
  }

  return (
    <div>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        startIcon={<FilterListIcon />}
        size='large'
        data-testid='task-filter-menu-button'
      >
        {!!taskFilters.length && taskFilters.length}
      </Button>
      <Popover
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        <Box display='flex' flexDirection='column' p={2}>
          {(!taskFilters.length || showAddFilter) && (
            <>
              <Typography className={classes.title}>Add task filter</Typography>
              {Object.values(TaskFilterColumn).map((column) => (
                <Button
                  className={classes.taskTypeButton}
                  key={column}
                  variant='outlined'
                  onClick={() => {
                    onAddNewTaskFilter({ column, id: uuidv4() })
                  }}
                >
                  {columnToNameMap[column]}
                </Button>
              ))}
            </>
          )}
          {!!taskFilters.length && !showAddFilter && (
            <>
              <Typography className={classes.title}>Active filters</Typography>
              {taskFilters.map((filter) => (
                <Box key={filter.id} display='flex' alignItems='center'>
                  <Select
                    className={classes.taskTypeField}
                    value={filter.column}
                    onChange={(e) =>
                      onUpdateTaskFilter(filter.id, {
                        id: uuidv4(),
                        column: e.target.value as TaskFilterColumn,
                      })
                    }
                  >
                    {Object.values(TaskFilterColumn).map((column) => (
                      <MenuItem value={column} key={column}>
                        {columnToNameMap[column]}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    className={classes.taskSymbolField}
                    value={filter.operation || ''}
                    onChange={(e) =>
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onUpdateTaskFilter(filter.id, { ...filter, operation: e.target.value as any })
                    }
                  >
                    {Object.values(taskOperationMap[filter.column]).map((filterSymbol) => (
                      <MenuItem value={filterSymbol} key={filterSymbol}>
                        {filterSymbol.toLowerCase().split('_').join(' ')}
                      </MenuItem>
                    ))}
                  </Select>
                  {renderTaskFilterValue(filter)}
                  <IconButton
                    onClick={() => onDeleteTaskFilter(filter.id)}
                    className={classes.taskClearButton}
                  >
                    <ClearIcon />
                  </IconButton>
                </Box>
              ))}
              <Button size='small' startIcon={<AddIcon />} onClick={() => setShowAddFilter(true)}>
                Add filter
              </Button>
            </>
          )}
        </Box>
      </Popover>
    </div>
  )
}

export default TaskFilterMenu
