import { FunctionComponent } from 'react'
import { Box, Paper, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import theme from '@src/utils/theme'
import RoundedButton from '@src/components/RoundedButton'

type Props = {
  title: string
  buttonText: string
  onNewDatabase: () => void
}

const useStyles = makeStyles<Theme>({
  databaseTextHeader: {
    color: theme.palette.grey[500],
    fontSize: `${theme.spacing(4)}px`,
  },
  paper: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey['500']}`,
  },
  newDatabaseButton: {
    background: 'white',
    color: theme.palette.grey[500],
    border: `1px solid ${theme.palette.grey[500]}`,
    maxHeight: 'unset',
  },
})

const DatabaseHeader: FunctionComponent<Props> = ({ title, buttonText, onNewDatabase }) => {
  const classes = useStyles()

  return (
    <Paper square className={classes.paper}>
      <Box display='flex' justifyContent='space-between'>
        <Typography className={classes.databaseTextHeader}>{title}</Typography>
        <Box>
          <RoundedButton
            variant='contained'
            onClick={onNewDatabase}
            className={classes.newDatabaseButton}
          >
            {buttonText}
          </RoundedButton>
        </Box>
      </Box>
    </Paper>
  )
}

export default DatabaseHeader
