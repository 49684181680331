import { FunctionComponent } from 'react'
import { Paper, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'

const useStyles = makeStyles<Theme>({
  dialog: {
    maxWidth: '80vw',
    maxHeight: '80vh',
    scroll: 'paper',
  },
  dialogPaper: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    maxHeight: '65vh',
    overflow: 'auto',
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

type Props = {
  close: () => void
  processingLog: string
}

const ProcessingLogDialog: FunctionComponent<Props> = ({ close, processingLog }) => {
  const classes = useStyles()

  return (
    <Dialog
      fullWidth
      classes={{ paper: classes.dialog }}
      open={true}
      data-testid='processing-log-dialog'
    >
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={close} data-testid='close-btn' />
      </Box>
      <DialogTitle>
        <Box p={1}>
          <Typography variant='h3'>Response Processing Log</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Paper className={classes.dialogPaper}>
          {processingLog.split(/\n|\\n/).map((log, idx) => {
            return (
              <Typography variant='body1' key={`${idx}`}>
                {log}
              </Typography>
            )
          })}
        </Paper>
      </DialogContent>
    </Dialog>
  )
}

export default ProcessingLogDialog
