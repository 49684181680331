import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  ListItem,
  makeStyles,
} from '@material-ui/core'
import { FunctionComponent, useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { useSnackbar } from 'notistack'

import { EDocsDataTargetType, FilePageNode, InputEDocument } from '@src/graphql/types'
import theme from '@src/utils/theme'
import { formatCwFileType, initializeInputEDocuments } from '@src/utils/edocs'
import EDocsPushConfirmTable from '@src/components/edocs/EDocsPushConfirmTable'

const useStyles = makeStyles({
  dialog: {
    padding: theme.spacing(3, 1),
    height: '90%',
  },
  confirmTable: {
    marginBottom: theme.spacing(2),
    maxHeight: '30%',
    overflowY: 'scroll',
  },
  bold: {
    fontWeight: 600,
  },
  tableContent: {
    verticalAlign: 'top',
  },
})

type Props = {
  dataTargetType: EDocsDataTargetType
  closeConfirmDialog: () => void
  selectedFilePagesToPush: FilePageNode[]
  selectedNums: string[]
  batchPushEdocs: (type: EDocsDataTargetType, inputEDocuments: InputEDocument[]) => Promise<void>
}

const BatchPushEDocsConfirmDialog: FunctionComponent<Props> = ({
  dataTargetType,
  closeConfirmDialog,
  selectedFilePagesToPush,
  selectedNums,
  batchPushEdocs,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [inputEDocuments, setInputEDocuments] = useState([] as InputEDocument[])
  const [filePagesMap, setFilePagesMap] = useState({} as Record<string, FilePageNode>)

  const toggleEdocIsPublished = (idx: number): void => {
    const newInputEDocs = [...inputEDocuments]
    newInputEDocs[idx].isPublished = !inputEDocuments[idx].isPublished
    setInputEDocuments(newInputEDocs)
  }

  useEffect(() => {
    const groupedFilePages = {} as Record<string, FilePageNode[]>
    const filePageMapping = {} as Record<string, FilePageNode>
    selectedFilePagesToPush.forEach((filePage) => {
      filePageMapping[filePage.id] = filePage

      const cwFileType = filePage!.document!.documentType!.cargowiseFileType
      if (cwFileType) {
        const cwFileTypeKey = formatCwFileType(cwFileType)
        const groupedFilePageIds = groupedFilePages[cwFileTypeKey]
        if (!groupedFilePageIds) {
          groupedFilePages[cwFileTypeKey] = []
        }
        groupedFilePages[cwFileTypeKey].push(filePage)
      }
    })
    if (Object.entries(groupedFilePages).length === 0) {
      enqueueSnackbar(
        'Some of the selected file pages have no associated cargowise file type. Please try again later.',
        { variant: 'error' },
      )
      closeConfirmDialog()
      return
    }
    setFilePagesMap(filePageMapping)

    const eDocuments = initializeInputEDocuments(groupedFilePages)
    setInputEDocuments(eDocuments)
  }, [
    closeConfirmDialog,
    enqueueSnackbar,
    selectedFilePagesToPush,
    setFilePagesMap,
    setInputEDocuments,
  ])

  return (
    <Dialog open={true} fullWidth={true} maxWidth='md' classes={{ paper: classes.dialog }}>
      <DialogTitle disableTypography={true}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h3'>Confirm send e-Documents to {dataTargetType}?</Typography>
          <IconButton onClick={closeConfirmDialog} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <TableContainer className={classes.confirmTable}>
          <Table>
            <TableRow>
              <TableCell className={classes.tableContent}>
                {inputEDocuments.map((inputEDoc, idx) => {
                  const firstFilePageId = inputEDoc.filePageIds[0] as string
                  const firstFilePage = filePagesMap[firstFilePageId]
                  return <ListItem key={idx}>{firstFilePage.file!.filename}</ListItem>
                })}
              </TableCell>
              <TableCell className={classes.tableContent}>
                <Typography className={classes.bold}>TO</Typography>
              </TableCell>
              <TableCell className={classes.tableContent}>
                {selectedNums.map((number, idx) => {
                  return <ListItem key={idx}>{number}</ListItem>
                })}
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>

        <EDocsPushConfirmTable
          inputEDocuments={inputEDocuments}
          filePagesMap={filePagesMap}
          toggleEdocIsPublished={toggleEdocIsPublished}
          cancelHandler={closeConfirmDialog}
          pushEdocsHandler={() => batchPushEdocs(dataTargetType, inputEDocuments)}
          disablePushEdocsButton={false}
          exportToApiPartner={!dataTargetType}
        />
      </DialogContent>
    </Dialog>
  )
}

export default BatchPushEDocsConfirmDialog
