import React from 'react'
import { blue, deepOrange, teal } from '@material-ui/core/colors'
import { Box } from '@material-ui/core'

interface ColorCodedTextProps {
  input: string
}

const ColorCodedText: React.FC<ColorCodedTextProps> = ({ input }) => {
  const getCharColor = (char: string): string => {
    if (char === '.') {
      return blue[500]
    } else if (char === ' ') {
      return 'inherit'
    } else if (/\d/.test(char)) {
      return teal[600]
    } else if (/[a-zA-Z]/.test(char)) {
      return 'inherit'
    } else {
      return deepOrange[500]
    }
  }

  const elements: React.ReactNode[] = []
  let currentColor = 'inherit'
  let currentGroup = ''

  const createSpan = (color: string, group: string, index: number): React.ReactNode => (
    <Box component='span' key={index} sx={{ color, whiteSpace: 'pre' }}>
      {group}
    </Box>
  )

  for (let i = 0; i < input.length; i++) {
    const char = input[i]
    const color = getCharColor(char)

    if (color === currentColor) {
      currentGroup += char
      continue
    }

    if (currentGroup) {
      elements.push(createSpan(currentColor, currentGroup, elements.length))
    }

    currentColor = color
    currentGroup = char
  }

  if (currentGroup) {
    elements.push(createSpan(currentColor, currentGroup, elements.length))
  }

  return <>{elements}</>
}

export default ColorCodedText
