import { Maybe } from '@src/graphql/types'

export const cleanStringForMatching = (
  val: Maybe<string> | undefined,
  lowercase = false,
): Maybe<string> | undefined => {
  if (!val) {
    return val
  }

  const stripped_val = val.trim()
  return lowercase ? stripped_val.toLowerCase() : stripped_val
}
