import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type JobEditorState = {
  currentFilePageId: string | null
  nextFilePageId: string | null
}

export const getInitialState = (): JobEditorState => ({
  currentFilePageId: null,
  nextFilePageId: null,
})

const jobEditorSlice = createSlice({
  name: 'jobEditor',
  initialState: getInitialState(),
  reducers: {
    clear: (state) => {
      Object.assign(state, getInitialState())
    },
    setCurrentFilePageId: (state, { payload: filePageId }: PayloadAction<string | null>) => {
      state.currentFilePageId = filePageId
    },
    setNextFilePageId: (state, { payload: filePageId }: PayloadAction<string | null>) => {
      state.nextFilePageId = filePageId
    },
  },
})

export const {
  clear: clearJobEditor,
  setCurrentFilePageId,
  setNextFilePageId,
} = jobEditorSlice.actions

export default jobEditorSlice.reducer
