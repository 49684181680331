import { FunctionComponent, MouseEvent, useState } from 'react'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Popover from '@material-ui/core/Popover'
import { useMutation, useQuery } from '@apollo/client'
import { Query } from '@src/graphql/types'
import { GET_NOTIFICATIONS } from '@src/graphql/queries/notification'
import { MARK_NOTIFICATIONS_READ } from '@src/graphql/mutations/notification'
import { List, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import NotificationItem from './NotificationItem'

// poll every 30 seconds
const notificationsPollIntervalMs = 30 * 1000

const useStyles = makeStyles({
  notifList: { width: '300px', maxHeight: '400px' },
})

const NotificationsButton: FunctionComponent = () => {
  const classes = useStyles()
  const { data: notificationsData, refetch: refetchNotificationsData } = useQuery<
    Pick<Query, 'notifications'>
  >(GET_NOTIFICATIONS, {
    pollInterval: notificationsPollIntervalMs,
  })
  const [markAsRead] = useMutation(MARK_NOTIFICATIONS_READ)

  const notifications = notificationsData?.notifications
  const unreadNotifications = notifications?.filter((notification) => notification.dateRead == null)
  const [anchorEl, setAnchorEl] = useState(null as null | (EventTarget & HTMLButtonElement))

  const handleOpenNotifs = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    setAnchorEl(event.currentTarget)
    try {
      await markAsRead()
      await refetchNotificationsData()
    } catch (e) {
      // fail silently
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
  const handleCloseNotifs = (): void => setAnchorEl(null)

  return (
    <>
      <IconButton color='inherit' onClick={handleOpenNotifs}>
        <Badge badgeContent={unreadNotifications?.length} color='secondary'>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseNotifs}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List className={classes.notifList} dense disablePadding={!!notifications?.length}>
          {notifications?.length ? (
            notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                closeNotifications={handleCloseNotifs}
              />
            ))
          ) : (
            <Typography align='center'>No notifications</Typography>
          )}
        </List>
      </Popover>
    </>
  )
}

export default NotificationsButton
