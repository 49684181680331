import { FunctionComponent } from 'react'
import { Box, IconButton, Paper } from '@material-ui/core'
import theme from '@src/utils/theme'
import { SpreadsheetDataColumn } from '@src/utils/data-grid'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import FastHotTable from '../../fast-hot-table/FastHotTable'

type Props = {
  currApiPartnerId: string | null
  createNewRecordMode?: boolean
  recordPageSize?: number
  activePage?: number
  setActivePage?: (page: number) => void
  rows: string[][]
  columns: SpreadsheetDataColumn[]
  afterChange?:
    | ((changes: [number, string | number, any, any][], source: string) => void)
    | undefined
}

const DatabaseTable: FunctionComponent<Props> = ({
  currApiPartnerId,
  createNewRecordMode,
  recordPageSize,
  activePage,
  setActivePage,
  rows,
  columns,
  afterChange,
}) => {
  const paginationHeight = 48
  const databaseTableHeight = 436

  return (
    <Paper>
      {!createNewRecordMode && (
        <Box pl={2}>
          Page {activePage! / recordPageSize! + 1}
          <IconButton
            color='primary'
            aria-label='back'
            onClick={() => setActivePage!(activePage! - recordPageSize!)}
            disabled={activePage === 0}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>
          <IconButton
            color='primary'
            aria-label='forward'
            onClick={() => setActivePage!(activePage! + recordPageSize!)}
            disabled={(rows?.length || 0) < recordPageSize!}
          >
            <ArrowForwardOutlinedIcon />
          </IconButton>
        </Box>
      )}
      <FastHotTable
        data={rows}
        hooks={{ afterChange }}
        style={{
          width: '100%',
          overflow: 'hidden',
          height: createNewRecordMode
            ? `${databaseTableHeight}px`
            : `${databaseTableHeight - paginationHeight}px`,
          background: theme.palette.grey[200],
        }}
        settings={{
          rowHeaders: true,
          contextMenu: ['row_above', 'row_below', 'remove_row'],
          colHeaders: columns.map((col) => col.name),
          columnSorting: true,
          filters: true,
          autoRowSize: true,
          stretchH: 'all',
          columns,
        }}
      />
    </Paper>
  )
}

export default DatabaseTable
