import { useState, type FC, ComponentProps } from 'react'
import { Box, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import ColorCodedText from '@src/components/ColorCodedText'

/**
 Component created for this ticket: https://expedock.atlassian.net/browse/PD-6170

 It is impossible to use different colors inside the input field, so we are using
 a workaround to display the text on top of the input field with different colors.
 */

interface Props extends Omit<ComponentProps<typeof TextField>, 'value'> {
  value: string
}

const StylizedShipmentTextField: FC<Props> = ({ onBlur, onFocus, InputProps, value, ...props }) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      {!isFocused && (
        <Typography
          style={{
            fontFamily: "'Roboto Mono'",
            // to perfectly align the text over the input
            // values acquired through trial and error
            position: 'absolute',
            top: `1em`,
            paddingLeft: '0.87em',
            width: '97.5%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <ColorCodedText input={value.slice(0, 150)} />
        </Typography>
      )}
      <TextField
        {...props}
        value={value}
        onBlur={(e) => {
          onBlur && onBlur(e)
          setIsFocused(false)
        }}
        onFocus={(e) => {
          onFocus && onFocus(e)
          setIsFocused(true)
        }}
        InputProps={{
          ...InputProps,
          style: {
            caretColor: 'black',
            color: isFocused ? 'inherit' : 'transparent',
          },
        }}
        variant='outlined'
      />
    </Box>
  )
}

export default StylizedShipmentTextField
