import {
  DocumentTypeNode,
  FieldGroupNode,
  FieldType,
  InputField,
  InputFieldGroup,
  InputFieldGroupType,
  InputFieldType,
  InputSearchableRecord,
} from '@src/graphql/types'
import { omit, uniqBy } from 'lodash'
import { isFallback } from '../enum'

export const inputFieldGroupTypeDisplayMap: Record<InputFieldGroupType, string> = {
  [InputFieldGroupType.Text]: 'Text',
  [InputFieldGroupType.TextWithSuggestions]: 'Text with Suggestions',
  [InputFieldGroupType.SingleSelectDropdown]: 'Single-Select Dropdown',
  [InputFieldGroupType.Price]: 'Price',
  [InputFieldGroupType.Date]: 'Date',
  [InputFieldGroupType.DateTime]: 'Date Time',
  [InputFieldGroupType.Integer]: 'Integer',
  [InputFieldGroupType.Decimal]: 'Decimal',
  [InputFieldGroupType.LineItem]: 'Line Item',
  [InputFieldGroupType.ReferenceNumber]: 'Reference Number',
  [InputFieldGroupType.Database]: 'Database',
}

export const inputFieldTypeDisplayMap: Record<InputFieldType, string> = {
  [InputFieldType.Text]: 'Text',
  [InputFieldType.TextWithSuggestions]: 'Text with Suggestions',
  [InputFieldType.SingleSelectDropdown]: 'Single-Select Dropdown',
  [InputFieldType.Price]: 'Price',
  [InputFieldType.Date]: 'Date',
  [InputFieldType.DateTime]: 'Date Time',
  [InputFieldType.Integer]: 'Integer',
  [InputFieldType.Decimal]: 'Decimal',
  [InputFieldType.ReferenceNumber]: 'Reference Number',
  [InputFieldType.Database]: 'Database',
}

export const fieldTypeToInputFieldTypeMap: Record<FieldType, InputFieldType> = {
  [FieldType.Date]: InputFieldType.Date,
  [FieldType.DateTime]: InputFieldType.DateTime,
  [FieldType.Decimal]: InputFieldType.Decimal,
  [FieldType.Integer]: InputFieldType.Integer,
  [FieldType.Price]: InputFieldType.Price,
  [FieldType.ReferenceNumber]: InputFieldType.ReferenceNumber,
  [FieldType.Database]: InputFieldType.Database,
}
export const fieldTypeToInputFieldGroupTypeMap: Record<FieldType, InputFieldGroupType> = {
  [FieldType.Date]: InputFieldGroupType.Date,
  [FieldType.DateTime]: InputFieldGroupType.DateTime,
  [FieldType.Decimal]: InputFieldGroupType.Decimal,
  [FieldType.Integer]: InputFieldGroupType.Integer,
  [FieldType.Price]: InputFieldGroupType.Price,
  [FieldType.ReferenceNumber]: InputFieldGroupType.ReferenceNumber,
  [FieldType.Database]: InputFieldGroupType.Database,
}
export const toInputFieldGroup = (
  fieldGroup: FieldGroupNode,
  documentTypeId: string,
): InputFieldGroup => {
  const fields = fieldGroup.fields!.edges.map((edge) => edge!.node!)
  const inputFields: InputField[] = fields.map(
    ({
      id,
      name,
      required,
      validatorRegex,
      validatorDescription,
      exportKey,
      dateFormatString,
      values,
      invalidCharsRegex,
      allowFreeText,
      fieldType,
      valueExportMapping,
      autofillKey,
      searchableRecord,
    }) => {
      let type = null as InputFieldType | null
      if (values) {
        if (allowFreeText) {
          type = InputFieldType.TextWithSuggestions
        } else {
          type = InputFieldType.SingleSelectDropdown
        }
      } else if (fieldType && !isFallback(fieldType)) {
        type = fieldTypeToInputFieldTypeMap[fieldType.value]
      } else if (!fieldType) {
        type = InputFieldType.Text
      }
      let inputSearchableRecord: InputSearchableRecord | null = null
      if (searchableRecord) {
        inputSearchableRecord = {
          searchKey: searchableRecord!.searchKey,
          displayKey: searchableRecord!.displayKey,
          model: searchableRecord!.model,
          apiUrl: searchableRecord!.apiUrl,
        }
      }
      return {
        id,
        name,
        required,
        validatorRegex,
        validatorDescription,
        exportKey,
        dateFormatString,
        invalidCharsRegex,
        values: values ? JSON.stringify(values) : null,
        valueExportMapping,
        type,
        autofillKey,
        searchableRecord: inputSearchableRecord,
      }
    },
  )
  let type = null as InputFieldGroupType | null
  if (fieldGroup.repeatable) {
    type = InputFieldGroupType.LineItem
  } else {
    const field = fields[0]
    if (field.values) {
      if (field.allowFreeText) {
        type = InputFieldGroupType.TextWithSuggestions
      } else {
        type = InputFieldGroupType.SingleSelectDropdown
      }
    } else if (field.fieldType && !isFallback(field.fieldType)) {
      type = fieldTypeToInputFieldGroupTypeMap[field.fieldType.value]
    } else if (!field.fieldType) {
      type = InputFieldGroupType.Text
    }
  }

  return {
    id: fieldGroup.id,
    name: fieldGroup.name,
    exportKey: fieldGroup.exportKey,
    fields: inputFields,
    documentTypeId,
    type,
    autofillKey: fieldGroup.autofillKey,
  }
}

export const getInputFieldGroups = (documentTypes: DocumentTypeNode[]): InputFieldGroup[] => {
  return uniqBy(
    documentTypes.flatMap((documentType) => {
      const documentTypeFieldGroups = documentType.documentTypeFieldGroups!.edges.map(
        (edge) => edge!.node!,
      )
      return documentTypeFieldGroups.map((documentTypeFieldGroup) => ({
        ...toInputFieldGroup(documentTypeFieldGroup.fieldGroup!, documentType.id),
        orderPriority: documentTypeFieldGroup.orderPriority,
      }))
    }),
    (fieldGroup) => fieldGroup.id as string,
  )
    .sort((a, b) => a.orderPriority - b.orderPriority)
    .map((fieldGroup) => omit(fieldGroup, 'orderPriority'))
}
