import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import { makeStyles } from '@material-ui/styles'
import {
  CargowiseConfigNode,
  CredentialNode,
  CwConnectorType,
  Mutation,
  MutationCreateCargowiseConfigArgs,
  MutationCreateCredentialArgs,
  MutationDeleteCargowiseConfigArgs,
  MutationDeleteCredentialArgs,
  MutationTestCargowiseConfigArgs,
  MutationUpdateCargowiseConfigArgs,
  Query,
  useUpdateCredentialMutation,
} from '@src/graphql/types'
import {
  CREATE_CW_CONFIG,
  DELETE_CW_CONFIG,
  TEST_CW_CONFIG,
  UPDATE_CW_CONFIG,
} from '@src/graphql/mutations/cargowise'
import { GET_ALL_CW_CONFIGS } from '@src/graphql/queries/cargowise'
import { GET_ALL_CREDENTIALS } from '@src/graphql/queries/credential'
import theme from '@src/utils/theme'
import CredentialsTable from '@src/components/admin/admin-cargowise/CredentialsTable'
import CWConfigsTable from '@src/components/admin/admin-cargowise/CWConfigsTable'
import { CREATE_CREDENTIAL, DELETE_CREDENTIAL } from '@src/graphql/mutations/credential'
import CreateUpdateCredentialDialog, {
  CredentialFormValues,
} from '@src/components/admin/admin-cargowise/CreateUpdateCredentialDialog'
import CreateUpdateCWConfigDialog, {
  CWConfigFormValues,
} from '@src/components/admin/admin-cargowise/CreateUpdateCWConfigDialog'

const useStyles = makeStyles({
  button: {
    marginBottom: theme.spacing(2),
  },
  tabRoot: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    opacity: 1,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
})

enum TabValues {
  CREDENTIALS = 'Credentials',
  CW_CONFIG = 'Cargowise Config',
}

const CONNECTOR_TYPE_MAPPING = {
  [CwConnectorType.Eadaptor]: 'eAdaptor',
  [CwConnectorType.Db]: 'DB',
  [CwConnectorType.Api]: 'API',
}

const CargowiseAdminPage: FunctionComponent = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { data: credentials, refetch: refetchCredentials } =
    useQuery<Pick<Query, 'credentials'>>(GET_ALL_CREDENTIALS)
  const { data: cargowiseConfigs, refetch: refetchCargowiseConfigs } =
    useQuery<Pick<Query, 'cargowiseConfigs'>>(GET_ALL_CW_CONFIGS)
  const [shownTab, setShownTab] = useState(TabValues.CW_CONFIG)
  const [openCreateUpdateCredentialDialog, setOpenCreateUpdateCredentialDialog] = useState(false)
  const [selectedCredential, setSelectedCredential] = useState(null as CredentialNode | null)
  const [credentialAction, setCredentialAction] = useState(
    () => (async () => {}) as (credential: CredentialFormValues) => Promise<void>,
  )
  const [openCreateUpdateCwConfigDialog, setOpenCreateUpdateCwConfigDialog] = useState(false)
  const [selectedCwConfig, setSelectedCwConfig] = useState(null as CargowiseConfigNode | null)
  const [cwConfigAction, setCwConfigAction] = useState(
    () => (async () => {}) as (credential: CWConfigFormValues) => Promise<void>,
  )
  const [deleteCargowiseConfig] = useMutation<
    Pick<Mutation, 'deleteCargowiseConfig'>,
    MutationDeleteCargowiseConfigArgs
  >(DELETE_CW_CONFIG)
  const [deleteCredential] = useMutation<
    Pick<Mutation, 'deleteCredential'>,
    MutationDeleteCredentialArgs
  >(DELETE_CREDENTIAL)
  const [createCredential] = useMutation<
    Pick<Mutation, 'createCredential'>,
    MutationCreateCredentialArgs
  >(CREATE_CREDENTIAL)
  const [updateCredential] = useUpdateCredentialMutation({
    refetchQueries: [GET_ALL_CREDENTIALS],
  })
  const [createCwConfig] = useMutation<
    Pick<Mutation, 'createCargowiseConfig'>,
    MutationCreateCargowiseConfigArgs
  >(CREATE_CW_CONFIG, {
    refetchQueries: [{ query: GET_ALL_CW_CONFIGS }],
  })
  const [updateCwConfig] = useMutation<
    Pick<Mutation, 'updateCargowiseConfig'>,
    MutationUpdateCargowiseConfigArgs
  >(UPDATE_CW_CONFIG, {
    refetchQueries: [{ query: GET_ALL_CW_CONFIGS }],
  })
  const [testCwConfig] = useMutation<
    Pick<Mutation, 'testCargowiseConfig'>,
    MutationTestCargowiseConfigArgs
  >(TEST_CW_CONFIG)

  const handleCreateCwConfig = async (cwConfig: CWConfigFormValues): Promise<void> => {
    try {
      const { jobTemplates, connectorType, remoteDbPort, ...rest } = cwConfig
      await createCwConfig({
        variables: {
          inputCwConfig: {
            ...rest,
            remoteDbPort: remoteDbPort ? Number(remoteDbPort) : null,
            connectorType: connectorType ? CONNECTOR_TYPE_MAPPING[connectorType] : null,
            jobTemplateIds: jobTemplates?.map((jobTemplate) => jobTemplate.id) ?? [],
          },
        },
      })
      enqueueSnackbar('Successfully created CW Config', { variant: 'success' })
      await refetchCargowiseConfigs()
    } catch (error) {
      enqueueSnackbar(
        `Encountered an error while creating CW Config: ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    }
  }

  const onCreateCwConfig = (): void => {
    setSelectedCwConfig(null)
    setCwConfigAction(() => handleCreateCwConfig)
    setOpenCreateUpdateCwConfigDialog(true)
  }

  const onUpdateCwConfig = (cwConfig: CargowiseConfigNode): void => {
    const handleUpdateCwConfig = async (cwConfigForm: CWConfigFormValues): Promise<void> => {
      try {
        const { jobTemplates, connectorType, remoteDbPort, ...rest } = cwConfigForm
        await updateCwConfig({
          variables: {
            id: cwConfig.id,
            inputCwConfig: {
              ...rest,
              remoteDbPort: remoteDbPort ? Number(remoteDbPort) : null,
              connectorType: connectorType ? CONNECTOR_TYPE_MAPPING[connectorType] : null,
              jobTemplateIds: jobTemplates?.map((jobTemplate) => jobTemplate.id),
            },
          },
        })
        enqueueSnackbar('Successfully updated Cargowise Config', { variant: 'success' })
      } catch (error) {
        enqueueSnackbar(
          `Encountered an error while updating Cargowise Config: ${formatMaybeApolloError(error)}`,
          {
            variant: 'error',
          },
        )
      }
    }
    setSelectedCwConfig(cwConfig)
    setCwConfigAction(() => handleUpdateCwConfig)
    setOpenCreateUpdateCwConfigDialog(true)
  }

  const onDeleteCwConfig = async (id: string): Promise<void> => {
    try {
      await deleteCargowiseConfig({ variables: { cwConfigId: id } })
      await refetchCargowiseConfigs()
    } catch (error) {
      enqueueSnackbar(
        `Encountered an error while deleting Cargowise Config: ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    }
  }

  const onTestCwConfig = async (id: string): Promise<void> => {
    try {
      await testCwConfig({ variables: { cwConfigId: id } })
      enqueueSnackbar('Cargowise Config test successful!', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(
        `Encountered an error while testing Cargowise Config: ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    }
  }

  const handleCreateCredential = async (credential: CredentialFormValues): Promise<void> => {
    try {
      await createCredential({
        variables: {
          ...credential,
          jobTemplateIds: credential?.jobTemplates?.map((jobTemplate) => jobTemplate.id) ?? [],
        },
      })
      enqueueSnackbar('Successfully created Credential', { variant: 'success' })
      await refetchCredentials()
    } catch (error) {
      enqueueSnackbar(
        `Encountered an error while creating Credential: ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    }
  }

  const onCreateCredential = (): void => {
    setSelectedCredential(null)
    setCredentialAction(() => handleCreateCredential)
    setOpenCreateUpdateCredentialDialog(true)
  }

  const onUpdateCredential = (credential: CredentialNode): void => {
    const handleUpdateCredential = async (credentialForm: CredentialFormValues): Promise<void> => {
      try {
        await updateCredential({
          variables: {
            ...credentialForm,
            id: credential.id,
            jobTemplateIds:
              credentialForm?.jobTemplates?.map((jobTemplate) => jobTemplate.id) ?? [],
          },
        })
        enqueueSnackbar('Successfully updated Credential', { variant: 'success' })
      } catch (error) {
        enqueueSnackbar(
          `Encountered an error while updating Credential: ${formatMaybeApolloError(error)}`,
          {
            variant: 'error',
          },
        )
      }
    }
    setSelectedCredential(credential)
    setCredentialAction(() => handleUpdateCredential)
    setOpenCreateUpdateCredentialDialog(true)
  }

  const onDeleteCredential = async (id: string): Promise<void> => {
    try {
      await deleteCredential({ variables: { credentialId: id } })
      await refetchCredentials()
    } catch (error) {
      enqueueSnackbar(
        `Encountered an error while deleting Credential: ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
    }
  }

  return (
    <Box p={3}>
      <TabContext value={shownTab}>
        <TabList variant='fullWidth' onChange={(_event, newValue) => setShownTab(newValue)}>
          <Tab
            classes={{ root: classes.tabRoot, selected: classes.selected }}
            label={TabValues.CW_CONFIG}
            value={TabValues.CW_CONFIG}
          />
          <Tab
            classes={{ root: classes.tabRoot, selected: classes.selected }}
            label={TabValues.CREDENTIALS}
            value={TabValues.CREDENTIALS}
          />
        </TabList>
        <TabPanel value={TabValues.CW_CONFIG}>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={onCreateCwConfig}
          >
            Create CW Config
          </Button>
          <CWConfigsTable
            cargowiseConfigs={cargowiseConfigs?.cargowiseConfigs || []}
            onDelete={onDeleteCwConfig}
            onUpdate={onUpdateCwConfig}
            onTest={onTestCwConfig}
          />
        </TabPanel>
        <TabPanel value={TabValues.CREDENTIALS}>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={onCreateCredential}
          >
            Create Credential
          </Button>
          <CredentialsTable
            credentials={credentials?.credentials || []}
            onDelete={onDeleteCredential}
            onUpdate={onUpdateCredential}
          />
        </TabPanel>
      </TabContext>
      {openCreateUpdateCredentialDialog && (
        <CreateUpdateCredentialDialog
          credential={selectedCredential}
          onSubmit={credentialAction}
          isOpen={openCreateUpdateCredentialDialog}
          close={() => setOpenCreateUpdateCredentialDialog(false)}
        />
      )}
      {openCreateUpdateCwConfigDialog && (
        <CreateUpdateCWConfigDialog
          cwConfig={selectedCwConfig}
          credentialOptions={credentials?.credentials || []}
          onSubmit={cwConfigAction}
          isOpen={openCreateUpdateCwConfigDialog}
          close={() => setOpenCreateUpdateCwConfigDialog(false)}
        />
      )}
    </Box>
  )
}

export default CargowiseAdminPage
