import { HotTable } from '@handsontable/react'
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useDeleteChargeVendorMutation } from '@src/graphql/types'
import { formatMaybeApolloError } from '@src/utils/errors'
import { useSnackbar } from 'notistack'
import { FunctionComponent, useMemo, useRef } from 'react'
import { HANDSONTABLE_IN_MODAL_HEIGHT } from '@src/utils/app_constants'
import { ChargeCodeOverridesArray, SelectedChargeVendor } from './types'
import ModalCloseButton from '../ModalCloseButton'

type Props = {
  vendor: SelectedChargeVendor
  chargeCodeOverrides: ChargeCodeOverridesArray
  setOpen: (open: boolean) => void
  resetMappingState: () => void
}

const columns = [
  {
    key: 'description',
    name: 'Description',
  },
  {
    key: 'charge_code',
    name: 'Charge Code',
  },
]

const DeleteVendorOverridesModal: FunctionComponent<Props> = ({
  vendor,
  chargeCodeOverrides,
  setOpen,
  resetMappingState,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const hotTableRef = useRef<HotTable | null>(null)

  const rows = useMemo(() => {
    return chargeCodeOverrides.map((override) => [override.description, override.chargeCode.code])
  }, [chargeCodeOverrides])

  const [deleteChargeVendor] = useDeleteChargeVendorMutation({
    onCompleted: () => {
      enqueueSnackbar('Charge vendor deleted', { variant: 'success' })
      resetMappingState()
      setOpen(false)
    },
    onError: (error) => {
      enqueueSnackbar(`Error deleting charge vendor: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  return (
    <Dialog open={true} data-testid='delete-vendor-modal'>
      <Box>
        <ModalCloseButton onClose={() => setOpen(false)} />
      </Box>
      <DialogTitle>Delete Vendor</DialogTitle>
      <DialogContent>
        {chargeCodeOverrides.length > 0 ? (
          <>
            <Typography>
              Deleting this vendor will remove all charge code overrides associated with the vendor.
              Please review before deleting.
            </Typography>
            <Box>
              <HotTable
                id='vendor-code-table'
                ref={hotTableRef}
                style={{ width: '100%', overflow: 'scroll', height: HANDSONTABLE_IN_MODAL_HEIGHT }}
                data={rows}
                readOnly={true}
                rowHeaders
                colHeaders={columns.map((col) => col.name)}
                columnSorting={true}
                columns={columns}
                stretchH='all'
                autoRowSize={true}
              />
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Typography>
                Do you want to delete this vendor and all of its associated charge code overrides
                for all partners?
              </Typography>
            </Box>
          </>
        )}

        <Box display='flex' justifyContent='center' p={3}>
          <Box m={3}>
            <Button onClick={() => setOpen(false)} variant='contained'>
              Cancel
            </Button>
          </Box>
          <Box m={3}>
            <Button
              onClick={() => deleteChargeVendor({ variables: { chargeVendorId: vendor.id } })}
              variant='contained'
              color='primary'
            >
              Delete
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteVendorOverridesModal
