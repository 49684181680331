import { BoxDimension } from '@src/types/ocr'
import { MagicGridColumn, MagicGridRow } from '@src/types/grid'
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit'
import { DocumentEditorState } from './document_editor_state'
import { lineItemsAdapter, lineItemSelectors, selectActiveLineItemsTable } from './line_items_table'
import { selectActiveDocument, selectActiveDocumentEditorPage } from './document'
import { reportRollbarError } from '../../utils/observability/rollbar'
import {
  getColsAndRowsInGrid,
  readDataFromTable,
  recalculateColumnDimensions,
  recalculateRowDimensions,
} from '@src/utils/magic_grid'
import { filterLineItemFieldCoordinates, getDocumentTableColumns } from '@src/utils/document'
import { getNullableEntitySelectors } from './nullable_entity_selector'
import { selectActiveOcrData } from './file_page'
import { documentTableSelectors, selectActiveDocumentTable } from './document_table'
import { getLineItemFieldCoordinates, readDataFromRowFieldBoxes } from '@src/utils/line_items'
import { selectRepeatableFieldsFromJobOrDocTable } from './field'
import { uniq } from 'lodash'
import { syncTableColumns } from './utils/line_item_actions'
import { stateHasMainTable } from '@src/redux-features/document_editor/job_table'

export enum TableExtractionStep {
  Idle = 'Idle',
  Extracting = 'Extracting',
  BatchAutofilling = 'BatchAutofilling',
}

export const magicGridColumnsAdapter = createEntityAdapter<MagicGridColumn>({
  sortComparer: (a, b) => a.dimension.left - b.dimension.left,
})

export const magicGridRowsAdapter = createEntityAdapter<MagicGridRow>({
  sortComparer: (a, b) => a.dimension.top - b.dimension.top,
})

export type MagicGridState = {
  dimension: BoxDimension | null
  columns: EntityState<MagicGridColumn>
  rows: EntityState<MagicGridRow>
}

export const getInitialMagicGridState = (): MagicGridState => ({
  dimension: null,
  columns: magicGridColumnsAdapter.getInitialState(),
  rows: magicGridRowsAdapter.getInitialState(),
})

export type MagicGridMap = { [documentTableId: string]: MagicGridState }

export const selectActiveMagicGrid = (state: DocumentEditorState): MagicGridState | undefined =>
  state.activeDocumentTableId ? state.magicGridMap[state.activeDocumentTableId] : undefined

export const columnSelectors = getNullableEntitySelectors(
  magicGridColumnsAdapter,
  (state: DocumentEditorState) => selectActiveMagicGrid(state)?.columns || null,
)

export const rowSelectors = getNullableEntitySelectors(
  magicGridRowsAdapter,
  (state: DocumentEditorState) => selectActiveMagicGrid(state)?.rows || null,
)

export const initializeGrid = (state: DocumentEditorState, gridDimension: BoxDimension): void => {
  const activeDocument = selectActiveDocument(state)
  // TODO: should this be selectActiveDocumentTable?
  const activeDocumentTable = selectActiveDocument(state)
  if (activeDocument == null || activeDocumentTable == null) {
    reportRollbarError(
      `Called initializeGrid without an activeDocument ${
        activeDocument == null
      } or activeDocumentTable ${activeDocumentTable == null}`,
    )
    return
  }
  const grid = state.magicGridMap[state.activeDocumentTableId!]
  grid.dimension = gridDimension
  const { columns, rows } = getColsAndRowsInGrid(
    getDocumentTableColumns(activeDocument),
    lineItemSelectors.selectAll(state) || [],
    grid.dimension,
  )
  magicGridColumnsAdapter.setAll(grid.columns, columns)
  magicGridRowsAdapter.setAll(grid.rows, rows)
}

/*
 * Read data from the existing grid and update the adapters and the state
 * to those new values.
 */
export const readMagicGridReducer = (state: DocumentEditorState): void => {
  const googleOcrData = selectActiveOcrData(state)
  const activeDocument = selectActiveDocument(state)
  const activeDocumentTable = selectActiveDocumentTable(state)
  const activeDocumentEditorPage = selectActiveDocumentEditorPage(state)
  const grid = state.magicGridMap[state.activeDocumentTableId!]
  if (
    state.job == null ||
    grid?.dimension == null ||
    googleOcrData == null ||
    activeDocument == null ||
    activeDocumentTable == null ||
    activeDocumentEditorPage == null
  ) {
    reportRollbarError(
      `Called readMagicGrid w/ null: job ${state.job == null} grid ${
        grid?.dimension == null
      } googleOcrData ${googleOcrData == null} activeDocument ${
        activeDocument == null
      } activeDocumentTable ${activeDocumentTable == null} activeDocumentEditorPage ${
        activeDocumentEditorPage == null
      }`,
    )
    return
  }
  const { lineItemColumns, lineItems, newRows } = readDataFromTable(
    columnSelectors.selectAll(state)!,
    rowSelectors.selectAll(state)!,
    googleOcrData,
    grid.dimension,
    activeDocumentTable,
    activeDocument.documentType.tableShowsPreset,
  )
  magicGridRowsAdapter.setAll(grid.rows, newRows)
  const lineItemsTable = state.lineItemsTableMap[activeDocumentTable.id]
  lineItemsTable.columns = lineItemColumns
  if (stateHasMainTable(state)) {
    syncTableColumns(state, documentTableSelectors.selectAll(state), state.lineItemsTableMap)
  }
  lineItemsAdapter.setAll(lineItemsTable.lineItems, lineItems)
  const nonRepeatFieldCoords = filterLineItemFieldCoordinates(
    activeDocumentEditorPage.fieldCoordinates,
  )
  activeDocumentEditorPage.fieldCoordinates = {
    ...nonRepeatFieldCoords,
    ...getLineItemFieldCoordinates(lineItems),
  }
  state.currentTableExtractionStep = TableExtractionStep.BatchAutofilling
}

// TOOD: make delta x, y deltaCoords to have consistent API
export const dragGridReducer = (
  state: DocumentEditorState,
  deltaX: number,
  deltaY: number,
): void => {
  const grid = state.magicGridMap[state.activeDocumentTableId!]
  if (grid?.dimension == null) {
    reportRollbarError(`Called readMagicGrid w/ null: grid ${grid?.dimension == null}`)
    return
  }
  grid.dimension.left += deltaX
  grid.dimension.top += deltaY
  magicGridColumnsAdapter.setAll(
    grid.columns,
    columnSelectors.selectAll(state)!.map((column) => ({
      ...column,
      dimension: {
        ...column.dimension,
        left: column.dimension.left + deltaX,
        top: column.dimension.top + deltaY,
      },
    })),
  )
  magicGridRowsAdapter.setAll(
    grid.rows,
    rowSelectors.selectAll(state)!.map((row) => ({
      ...row,
      dimension: {
        ...row.dimension,
        left: row.dimension.left + deltaX,
        top: row.dimension.top + deltaY,
      },
    })),
  )
}

// read data into state for new grid
export const readDataFromNewGridReducer = (state: DocumentEditorState): void => {
  // filter out lineItem with empty fieldMapping
  const lineItems = lineItemSelectors
    .selectAll(state)!
    .filter((lineItem) => Object.keys(lineItem.fieldMapping).length !== 0)
  if (lineItems.length > 0) {
    const activeDocument = selectActiveDocument(state)
    const tableShowsPreset = activeDocument?.documentType.tableShowsPreset
    const lineItemsTable = selectActiveLineItemsTable(state)!
    const lineItemEntities = lineItemsTable.lineItems
    const googleOcrData = selectActiveOcrData(state)!
    const docTableRepeatableFields = selectRepeatableFieldsFromJobOrDocTable(state)
    const newLineItems = readDataFromRowFieldBoxes(
      lineItems,
      googleOcrData,
      docTableRepeatableFields,
    )
    lineItemsAdapter.setAll(lineItemEntities, newLineItems)
    const newKeys = new Set(newLineItems.flatMap((lineItem) => Object.keys(lineItem.fieldMapping)))
    const newColumns = uniq(
      docTableRepeatableFields
        .filter((field) => tableShowsPreset || newKeys.has(field.key) || field.required)
        .map((field) => field.key),
    )
    lineItemsTable.columns = newColumns
    if (stateHasMainTable(state)) {
      syncTableColumns(state, documentTableSelectors.selectAll(state), state.lineItemsTableMap)
    }
    const activeDocumentEditorPage = selectActiveDocumentEditorPage(state)!
    const nonRepeatFieldCoords = filterLineItemFieldCoordinates(
      activeDocumentEditorPage.fieldCoordinates,
    )
    activeDocumentEditorPage.fieldCoordinates = {
      ...nonRepeatFieldCoords,
      ...getLineItemFieldCoordinates(newLineItems),
    }
  }
  state.currentTableExtractionStep = TableExtractionStep.BatchAutofilling
}

export const resizeGridReducer = (
  state: DocumentEditorState,
  gridDimension: BoxDimension,
): void => {
  const grid = state.magicGridMap[state.activeDocumentTableId!]
  if (grid == null) {
    reportRollbarError(`Called resizeGrid w/ null: grid ${grid == null}`)
    return
  }
  grid.dimension = gridDimension
  magicGridColumnsAdapter.setAll(
    grid.columns,
    columnSelectors
      .selectAll(state)!
      .filter(
        ({ dimension: colDimension }) =>
          gridDimension.left <= colDimension.left &&
          colDimension.left < gridDimension.left + gridDimension.width,
      ),
  )
  magicGridRowsAdapter.setAll(
    grid.rows,
    rowSelectors
      .selectAll(state)!
      .filter(
        ({ dimension: rowDimension }) =>
          gridDimension.top <= rowDimension.top &&
          rowDimension.top < gridDimension.top + gridDimension.height,
      ),
  )
}

export const moveGridColumnReducer = (
  state: DocumentEditorState,
  id: string,
  left: number,
): void => {
  const grid = state.magicGridMap[state.activeDocumentTableId!]
  if (grid?.dimension == null) {
    reportRollbarError(`Called moveGridColumn w/ null: grid dimension ${grid?.dimension == null}`)
    return
  }
  const column = columnSelectors.selectById(state, id)
  if (!column) return
  magicGridColumnsAdapter.updateOne(grid.columns, {
    id,
    changes: { dimension: { ...column.dimension, left } },
  })
  const columns = columnSelectors.selectAll(state)!
  magicGridColumnsAdapter.setAll(grid.columns, recalculateColumnDimensions(columns, grid.dimension))
}

export const moveGridRowReducer = (state: DocumentEditorState, id: string, top: number): void => {
  const grid = state.magicGridMap[state.activeDocumentTableId!]
  if (grid?.dimension == null) {
    reportRollbarError(`Called moveGridRow w/ null: grid dimension ${grid?.dimension == null}`)
    return
  }
  const row = rowSelectors.selectById(state, id)
  if (!row) return
  magicGridRowsAdapter.updateOne(grid.rows, {
    id,
    changes: { dimension: { ...row.dimension, top } },
  })
  const rows = rowSelectors.selectAll(state)!
  magicGridRowsAdapter.setAll(grid.rows, recalculateRowDimensions(rows, grid.dimension))
}
