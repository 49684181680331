import { FunctionComponent, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import SelectionBox from '@src/components/selection-box'
import useCreateBox from '@src/hooks/boxn/useCreateBox'
import useRowBoxOutsideClicks from '@src/hooks/boxn/useRowBoxOutsideClicks'
import {
  addLineItem,
  setActiveLineItemIds,
  setCreateBoxEnabled,
  updateLineItemBoxDimension,
} from '@src/redux-features/document_editor'
import {
  stateHasMainTable,
  jobTableLineItemSelectors,
} from '@src/redux-features/document_editor/job_table'
import { selectActiveDocument } from '@src/redux-features/document_editor/document'
import { lineItemSelectors } from '@src/redux-features/document_editor/line_items_table'
import { LINE_THRESHOLD } from '@src/utils/app_constants'
import { RootState } from '@src/utils/store'

import FieldBoxLabelDropdown from './FieldBoxLabelDropdown'
import FieldBoxMoveable from './FieldBoxMoveable'
import RowBox from './RowBox'
import RowBoxMoveable from './RowBoxMoveable'
import { isJobTableLineItem, JobTableLineItem, LineItem } from '@src/utils/line_items'

type Props = {
  imageBoxRef: MutableRefObject<HTMLImageElement | null>
  fileViewerSize: { width: number | null; height: number | null }
  panningEnabled: boolean
}

const GridContainer: FunctionComponent<Props> = ({
  imageBoxRef,
  fileViewerSize,
  panningEnabled,
  children,
}) => {
  const displaySelectedRowOnly = useSelector((state: RootState) =>
    stateHasMainTable(state.documentEditor),
  )
  const imageContainerRef = useRef(null as HTMLDivElement | null)
  useRowBoxOutsideClicks(imageContainerRef, false)
  const [columnAnchorEl, setColumnAnchorEl] = useState(null as HTMLDivElement | null)
  const dispatch = useDispatch()

  const imageWidth = imageBoxRef.current?.width ?? 0
  const imageHeight = imageBoxRef.current?.height ?? 0
  const isJobTableActive = useSelector((state: RootState) => state.documentEditor.jobTableActive)

  const activeLineItemIds = useSelector(
    (state: RootState) => state.documentEditor.activeLineItemIds,
  )
  const lineItems = useSelector(
    (state: RootState) => lineItemSelectors.selectAll(state.documentEditor) ?? [],
  )
  const jobTableLineItems = useSelector(
    (state: RootState) => jobTableLineItemSelectors.selectAll(state.documentEditor) ?? [],
  )
  const activeLineItem = useMemo(() => {
    if (activeLineItemIds.length === 1) {
      return isJobTableActive
        ? jobTableLineItems.find((item) => item.id === activeLineItemIds[0])
        : lineItems.find((item) => item.id === activeLineItemIds[0])
    }
    return null
  }, [lineItems, jobTableLineItems, isJobTableActive, activeLineItemIds])

  const highlightedRowBoxIds = useSelector(
    (state: RootState) => state.documentEditor.boxn.highlightedRowBoxIds,
  )
  const createBoxEnabled = useSelector(
    (state: RootState) => state.documentEditor.boxn.createBoxEnabled,
  )

  const highlightedLineItem = useMemo(() => {
    if (highlightedRowBoxIds.length === 1) {
      return isJobTableActive
        ? jobTableLineItems?.find((lineItem) => lineItem.id === highlightedRowBoxIds[0]) ?? null
        : lineItems?.find((lineItem) => lineItem.id === highlightedRowBoxIds[0]) ?? null
    }
    return null
  }, [highlightedRowBoxIds, isJobTableActive, jobTableLineItems, lineItems])

  const activeDocumentId = useSelector(
    (state: RootState) => selectActiveDocument(state.documentEditor)?.id,
  )
  const activeDocumentTableId = useSelector(
    (state: RootState) => state.documentEditor.activeDocumentTableId,
  )
  const activeFieldBoxId = useSelector(
    (state: RootState) => state.documentEditor.boxn.activeFieldBoxId,
  )

  const isRowBoxDraggingOrResizing = useSelector(
    (state: RootState) => state.documentEditor.boxn.isRowBoxDraggingOrResizing,
  )
  const isRowActive = activeLineItemIds.includes(activeLineItem?.id ?? '')

  const { end, boxDimension, isMouseDown, handleMouseDown } = useCreateBox(
    !isRowBoxDraggingOrResizing,
    imageBoxRef,
  )

  useEffect(() => {
    if (isMouseDown || isJobTableActive || !activeDocumentTableId) {
      return
    }
    const isWidthTooSmall = boxDimension.width <= LINE_THRESHOLD
    const isHeightTooSmall = boxDimension.height <= LINE_THRESHOLD
    if (isWidthTooSmall || isHeightTooSmall) {
      return
    }
    const box =
      highlightedLineItem && isJobTableLineItem(highlightedLineItem)
        ? highlightedLineItem.boxMapping[activeDocumentId || '']
        : highlightedLineItem?.box
    const highlightedRowHasNoBox = Boolean(highlightedLineItem && !box)
    if (createBoxEnabled && highlightedRowHasNoBox) {
      batch(() => {
        dispatch(updateLineItemBoxDimension(highlightedLineItem!.id, { box: boxDimension }))
        dispatch(setCreateBoxEnabled(false))
      })
    } else {
      const newLineItem = {
        id: uuidv4(),
        box: boxDimension,
        fieldMapping: {},
        documentTableId: activeDocumentTableId,
      }
      dispatch(addLineItem(newLineItem))
      if (displaySelectedRowOnly) {
        dispatch(setActiveLineItemIds([newLineItem.id]))
      }
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isMouseDown, end])

  useEffect(() => {
    if (!activeFieldBoxId) {
      setColumnAnchorEl(null)
    }
  }, [activeFieldBoxId])

  const lineItemsToDisplay: (JobTableLineItem | LineItem)[] = useMemo(
    () =>
      isJobTableActive
        ? jobTableLineItems.filter((lineItem) => !!lineItem.boxMapping[activeDocumentId || ''])
        : lineItems,
    [isJobTableActive, activeDocumentId, jobTableLineItems, lineItems],
  )

  return (
    <div data-testid='grid-container'>
      {lineItemsToDisplay
        .filter(
          (lineItem) =>
            (!activeLineItemIds.length && !highlightedRowBoxIds.length) ||
            activeLineItemIds.includes(lineItem.id) ||
            highlightedRowBoxIds.includes(lineItem.id),
        )
        .map((lineItem) => {
          return (
            <RowBox
              key={`row-box-${lineItem.id}`}
              lineItem={lineItem}
              imageBoxRef={imageBoxRef}
              setColumnAnchorEl={setColumnAnchorEl}
              panningEnabled={panningEnabled}
            />
          )
        })}
      {isRowActive && (
        <RowBoxMoveable
          imageBoxRef={imageBoxRef}
          fileViewerSize={fileViewerSize}
          data-testid='moveable-row-box'
        />
      )}
      {activeFieldBoxId && (
        <FieldBoxMoveable imageBoxRef={imageBoxRef} fileViewerSize={fileViewerSize} />
      )}
      <div ref={imageContainerRef} onMouseDown={handleMouseDown}>
        {!panningEnabled && isMouseDown && (
          <SelectionBox
            top={boxDimension.top * imageHeight}
            left={boxDimension.left * imageWidth}
            width={boxDimension.width * imageWidth}
            height={boxDimension.height * imageHeight}
          />
        )}
        {children}
      </div>
      <FieldBoxLabelDropdown
        columnAnchorEl={columnAnchorEl}
        setColumnAnchorEl={setColumnAnchorEl}
      />
    </div>
  )
}

export default GridContainer
