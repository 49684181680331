import { ChangeEvent, FunctionComponent, ReactElement, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'

import { JobTemplateFormValues } from '@src/components/admin/job-template-editor/JobTemplateEditor'
import { InputReconThresholdRange } from '@src/graphql/types'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import theme from '@src/utils/theme'
import DeleteIcon from '@material-ui/icons/Delete'
import { maybeParseFloat } from '@src/components/data-grid/util'
import Decimal from 'decimal.js'
import { useSnackbar } from 'notistack'
import { DeployEnvironment, getDeployEnvironment } from '@src/utils/environment'

export const THRESHOLD_RANGE_ABS_MIN = -9_999_999_999_998
export const THRESHOLD_RANGE_ABS_MAX = 9_999_999_999_998
export const INF_STRING = Infinity.toString()
export const NEG_INF_STRING = (-Infinity).toString()

const useStyles = makeStyles({
  outlineHover: {
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    padding: '2px',
  },
})

const reconThresholdRangeHeaders = [
  'Greater Than or Equal (>=)',
  'Less Than (<)',
  'Threshold Amount',
]
// gated until PD-5787 and PD-5795 are implemented
if (getDeployEnvironment() === DeployEnvironment.DEVELOPMENT) {
  reconThresholdRangeHeaders.push('Use Percentage', 'Use Absolute Value Match')
}

const ReconThresholdSetting: FunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [inEditor, setInEditor] = useState(false)
  const currentReconThresholdRanges = (useWatch<JobTemplateFormValues>({
    name: 'inputReconThresholdRanges',
  }) ?? []) as InputReconThresholdRange[]
  const { setValue: setInputThresholdRanges } = useFormContext<JobTemplateFormValues>()

  const handleShowEditor = (): void => {
    setInEditor(!inEditor)
  }

  const handleUpdateThresholds = (thresholds: InputReconThresholdRange[]): void => {
    setInputThresholdRanges('inputReconThresholdRanges', thresholds)
    enqueueSnackbar(`Updated threshold settings, please save job template to reflect changes.`, {
      variant: 'info',
    })
  }

  return !inEditor ? (
    <Box style={{ display: 'flex', flex: 'row' }}>
      <Button variant='outlined' onClick={handleShowEditor}>
        EDIT THRESHOLDS
      </Button>
      <Button
        style={{ backgroundColor: 'red', color: 'white', marginLeft: theme.spacing(2) }}
        onClick={() => handleUpdateThresholds([])}
      >
        CLEAR THRESHOLDS
      </Button>
    </Box>
  ) : (
    <ReconThresholdEditor
      inEditor={inEditor}
      handleShowEditor={handleShowEditor}
      currentReconThresholdRanges={currentReconThresholdRanges}
      handleUpdateThresholds={handleUpdateThresholds}
    />
  )
}

export default ReconThresholdSetting

type ReconThresholdEditorProps = {
  inEditor: boolean
  handleShowEditor: () => void
  currentReconThresholdRanges: InputReconThresholdRange[]
  handleUpdateThresholds: (thresholds: InputReconThresholdRange[]) => void
}

const ReconThresholdEditor: FunctionComponent<ReconThresholdEditorProps> = ({
  inEditor,
  handleShowEditor,
  currentReconThresholdRanges,
  handleUpdateThresholds,
}) => {
  const classes = useStyles()
  const [thresholds, setThresholds] = useState<InputReconThresholdRange[]>(
    currentReconThresholdRanges,
  )

  const deleteRow = (idx: number): void => {
    const newThresholds = thresholds.filter((_, ix) => ix !== idx)
    switch (newThresholds.length) {
      case 0:
        setThresholds([])
        break
      case 1:
        setThresholds([{ ...newThresholds[0], minimum: NEG_INF_STRING, maximum: INF_STRING }])
        break
      default:
        if (idx === 0) {
          newThresholds[0] = { ...newThresholds[0], minimum: NEG_INF_STRING }
          setThresholds(newThresholds)
        } else if (idx === newThresholds.length) {
          newThresholds[newThresholds.length - 1] = {
            ...newThresholds[newThresholds.length - 1],
            maximum: INF_STRING,
          }
          setThresholds(newThresholds)
        } else {
          const prevThreshold = newThresholds[idx - 1]
          const nextThreshold = newThresholds[idx]
          newThresholds[idx - 1] = { ...prevThreshold, maximum: nextThreshold.minimum }
          setThresholds(newThresholds)
        }
    }
  }

  const addRow = (): void => {
    if (thresholds.length === 0) {
      setThresholds([
        {
          minimum: NEG_INF_STRING,
          maximum: INF_STRING,
          thresholdAmount: '0',
          usePercent: false,
          useAbsoluteValueMatching: false,
        },
      ])
      return
    }
    const newThresholds = [...thresholds]
    const lastThreshold = thresholds[thresholds.length - 1]

    if (lastThreshold.minimum === '') return

    const newRangeMin =
      lastThreshold.minimum === NEG_INF_STRING
        ? '0'
        : Decimal.add(lastThreshold.minimum, 0.01).toString()

    newThresholds[thresholds.length - 1] = {
      ...lastThreshold,
      maximum: newRangeMin,
    }
    setThresholds([
      ...newThresholds,
      {
        minimum: newRangeMin,
        maximum: INF_STRING,
        thresholdAmount: '0',
        usePercent: false,
        useAbsoluteValueMatching: false,
      },
    ])
  }

  const validateMinInput = (value: string, idx: number): boolean => {
    if (idx === 0) {
      return value === NEG_INF_STRING
    }
    const prevMin = parseFloat(thresholds[idx - 1].minimum)
    const prevMax = parseFloat(thresholds[idx - 1].maximum)
    return (
      /^-?\d+(\.\d{1,2})?$/.test(value) &&
      prevMin < parseFloat(value) &&
      prevMax === parseFloat(value)
    )
  }

  const validateThresholdAmountInput = (input_value: string): boolean => {
    const value = parseFloat(input_value)
    return !isNaN(value) && value > THRESHOLD_RANGE_ABS_MIN && value < THRESHOLD_RANGE_ABS_MAX
  }

  const EditableThresholdRow = (idx: number, threshold: InputReconThresholdRange): ReactElement => {
    const handleRowChange = (e: ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = e.target
      let newValue
      if (e.target.type === 'checkbox') {
        newValue = e.target.checked
      }
      if (e.target.type === 'number') {
        newValue = maybeParseFloat(value)
      }
      const newThresholds = [...thresholds]
      newThresholds[idx] = { ...newThresholds[idx], [name]: newValue ?? value }
      setThresholds(newThresholds)
    }

    const handleMinChange = (e: ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.target
      const newThresholds = [...thresholds]
      newThresholds[idx - 1] = { ...newThresholds[idx - 1], ['maximum']: value }
      newThresholds[idx] = { ...newThresholds[idx], ['minimum']: value }
      setThresholds(newThresholds)
    }

    return (
      <TableRow>
        <TableCell>
          <TextField
            type='text'
            name='minimum'
            value={threshold.minimum}
            onChange={handleMinChange}
            error={!validateMinInput(threshold.minimum, idx)}
            helperText={
              !validateMinInput(threshold.minimum, idx)
                ? `Enter a valid number at least .01 greater than preceding min`
                : ''
            }
            disabled={idx === 0}
          />
        </TableCell>
        <TableCell>
          <TextField type='text' name='maximum' value={threshold.maximum} disabled />
        </TableCell>
        <TableCell>
          <TextField
            type='number'
            name='thresholdAmount'
            required
            value={threshold.thresholdAmount}
            onChange={handleRowChange}
            error={!validateThresholdAmountInput(threshold.thresholdAmount)}
            helperText={
              !validateThresholdAmountInput(threshold.thresholdAmount)
                ? 'Please enter a valid number'
                : ''
            }
          />
        </TableCell>
        {getDeployEnvironment() === DeployEnvironment.DEVELOPMENT && (
          <>
            <TableCell>
              <Checkbox
                name='usePercent'
                checked={!!threshold.usePercent}
                onChange={handleRowChange}
              />
            </TableCell>
            <TableCell>
              <Checkbox
                name='useAbsoluteValueMatching'
                checked={!!threshold.useAbsoluteValueMatching}
                onChange={handleRowChange}
              />
            </TableCell>
          </>
        )}
        <TableCell>
          <Button className={classes.outlineHover} onClick={() => deleteRow(idx)}>
            <DeleteIcon />
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Dialog fullWidth={true} maxWidth='md' open={inEditor} onClose={handleShowEditor}>
      <DialogTitle>Recon Threshold Settings</DialogTitle>
      <DialogContent>
        <Box style={{ display: 'flex', flexGrow: 1, padding: theme.spacing(2) }}>
          <TableContainer>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {reconThresholdRangeHeaders.map((header) => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {thresholds.length ? (
                  thresholds.map((row, ix) => EditableThresholdRow(ix, row))
                ) : (
                  <Typography>No threshold ranges</Typography>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <Button
        style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}
        variant='contained'
        onClick={addRow}
      >
        + Add Row
      </Button>
      <Box style={{ display: 'flex', justifyContent: 'space-between', margin: theme.spacing(2) }}>
        <Button variant='outlined' onClick={handleShowEditor}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            handleUpdateThresholds(thresholds)
            handleShowEditor()
          }}
          disabled={
            !thresholds.every(
              (threshold, idx) =>
                validateMinInput(threshold.minimum, idx) &&
                validateThresholdAmountInput(threshold.thresholdAmount),
            )
          }
        >
          Apply
        </Button>
      </Box>
    </Dialog>
  )
}
