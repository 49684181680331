import { SlateContent } from '@src/hooks/useSlateEditor'
import { FunctionComponent } from 'react'
import { Box, colors, IconButton, Link, Typography } from '@material-ui/core'
import { ReactComponent as AttachmentIcon } from '@src/svg/attachment.svg'
import { ReactComponent as CloseIcon } from '@src/svg/cross.svg'
import { ReactComponent as RemoveIcon } from '@src/svg/remove-filled.svg'
import theme from '@src/utils/theme'
import { alpha } from '@material-ui/core/styles/colorManipulator'

const MAX_IMAGE_THUMBNAIL_HEIGHT = '128px'

export type SlateAttachment = {
  type: 'image' | 'file'
  url: string
} & Partial<SlateContent>

const NoteFormAttachments: FunctionComponent<{
  files?: SlateAttachment[]
  images?: SlateAttachment[]
  onRemove: (url: string) => unknown
}> = ({ files, images, onRemove }) => {
  return (
    <Box
      style={{
        display: 'flex',
        padding: theme.spacing(1),
        flexDirection: 'column',
        gap: theme.spacing(1),
      }}
      alignItems='flex-start'
    >
      <Typography
        variant='overline'
        style={{
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.grey[400],
          lineHeight: '0.75rem',
        }}
      >
        Uploaded File/s
      </Typography>
      {files?.map(({ url, caption }) => (
        <FileAttachmentItem key={url} url={url} caption={caption} onRemove={onRemove} />
      ))}
      {images?.map(({ url, caption }) => (
        <ImageAttachmentItem key={url} url={url} caption={caption} onRemove={onRemove} />
      ))}
    </Box>
  )
}

const FileAttachmentItem: FunctionComponent<{
  url: string
  caption?: string
  onRemove?: (url: string) => unknown
}> = ({ url, caption, onRemove }) => (
  <Box
    style={{ display: 'flex', flexDirection: 'row', gap: theme.spacing(0.75) }}
    key={url}
    alignItems='center'
  >
    <AttachmentIcon color={colors.teal[200]} />
    <Link
      href={url}
      style={{
        color: colors.teal[500],
        fontSize: '0.75rem',
        lineHeight: '0.75rem',
        fontWeight: theme.typography.fontWeightMedium,
      }}
    >
      {caption}
    </Link>
    {onRemove && (
      <CloseIcon
        style={{ color: theme.palette.grey[300] }}
        cursor='pointer'
        onClick={() => onRemove(url)}
      />
    )}
  </Box>
)

const ImageAttachmentItem: FunctionComponent<{
  url: string
  caption?: string
  onRemove: (url: string) => unknown
}> = ({ url, caption, onRemove }) => (
  <Box style={{ border: `4px solid ${theme.palette.grey[500]}` }} position='relative'>
    <Link href={url} target='_blank'>
      <img
        style={{ objectFit: 'contain', cursor: 'pointer', maxHeight: MAX_IMAGE_THUMBNAIL_HEIGHT }}
        src={url}
        alt={caption}
      />
    </Link>
    <Typography
      noWrap
      style={{
        fontSize: '0.75rem',
        lineHeight: '0.75rem',
        color: theme.palette.common.white,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: theme.spacing(0.75),
        backgroundColor: alpha(theme.palette.grey[800], 0.5),
      }}
    >
      {url}
    </Typography>
    <IconButton
      size='small'
      style={{ position: 'absolute', top: 0, right: 0, transform: 'translate(50%, -50%)' }}
      onClick={() => onRemove(url)}
    >
      <RemoveIcon />
    </IconButton>
  </Box>
)

export default NoteFormAttachments
