import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import {
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  makeStyles,
  Switch,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import theme from '@src/utils/theme'
import {
  CompanyNode,
  useBranchesLazyQuery,
  useCreateUserMutation,
  useDepartmentsLazyQuery,
  useOperatorsLazyQuery,
  useSalesRepsLazyQuery,
  useUpdateDashboardCompanyUserIamMutation,
} from '@src/graphql/types'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import UserRoleAutocomplete from './UserRoleAutocomplete'
import CompanyAutocomplete from './CompanyAutocomplete'
import { useSnackbar } from 'notistack'
import { FormError, FormValues, validateFields } from './util'
import ShipmentUserDataAutocomplete from './ShipmentUserDataAutocomplete'
import DefaultDashboardsWhitelist from './default-dashboards-whitelist/DefaultDashboardsWhitelist'
import { reportRollbarError } from '@src/utils/observability/rollbar'

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  submitButton: {
    textAlign: 'right',
  },
  formLine: {
    marginBottom: theme.spacing(1),
  },
  resetLinkBox: {
    display: 'block',
    width: '100%',
  },
  switch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    marginBottom: theme.spacing(1),
  },
})

type Props = {
  userCompany?: CompanyNode
  isOpen: boolean
  close: () => void
  refetchUsers: () => Promise<unknown>
  isExpedockAdmin: boolean
}

const CreateUserDialog: FunctionComponent<Props> = ({
  userCompany,
  isOpen,
  close,
  refetchUsers,
  isExpedockAdmin,
}) => {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const [isCreating, setIsCreating] = useState(false)
  const [resetLink, setResetLink] = useState(null as null | string)
  const [formCompanyId, setFormCompanyId] = useState<string | undefined>(userCompany?.id)
  const [formError, setFormError] = useState<FormError>({
    company: '',
    roles: '',
    operatorCompanies: '',
  })

  const formMethods = useForm<FormValues>({ defaultValues: { company: userCompany } })

  const [createUser] = useCreateUserMutation()
  const [updateDashboardCompanyUserIam] = useUpdateDashboardCompanyUserIamMutation()

  const [fetchSalesRepsData, { data: salesRepsData }] = useSalesRepsLazyQuery()
  const [fetchOperatorsData, { data: operatorsData }] = useOperatorsLazyQuery()
  const [fetchBranchesData, { data: branchesData }] = useBranchesLazyQuery()
  const [fetchDepartmentsData, { data: departmentsData }] = useDepartmentsLazyQuery()

  useEffect(() => {
    if (!formCompanyId) return
    const options = { variables: { companyId: formCompanyId } }
    void fetchSalesRepsData(options)
    void fetchOperatorsData(options)
    void fetchBranchesData(options)
    void fetchDepartmentsData(options)
  }, [
    formCompanyId,
    fetchSalesRepsData,
    fetchOperatorsData,
    fetchBranchesData,
    fetchDepartmentsData,
  ])

  const onSubmit = async (data: FormValues): Promise<void> => {
    if (!validateFields(data, setFormError)) {
      // Broadcast company-related errors since the input field is hidden for non-Expedock admins.
      // WHY: This condition will be triggered only if the current non-ExpedockAdmin user does not belong
      // to a company -- hence can't pass down their company to new users.
      // This shouldn't happen but we should at least fail loudly when it does unexpectedly.
      if (formError.company && !isExpedockAdmin) {
        enqueueSnackbar(formError.company, { variant: 'error' })
        reportRollbarError(formError.company)
      }
      return
    }

    setIsCreating(true)
    const inputUser = {
      email: data.email.trim(),
      name: data.name || '',
      roles: Array.isArray(data.roles) ? data.roles : [data.roles],
      companyId: data.company?.id || null,
      operatorCompanyIds: data.operatorCompanies?.map((company) => company.id) ?? [],
      metabaseDashboardId: data.metabaseDashboardId || '',
      salesReps: data.salesReps,
      operationsReps: data.operationsReps,
      branches: data.branches,
      departments: data.departments,
      onlyViewAssignedShipments: data.onlyViewAssignedShipments,
      emailVerified: data.emailVerified ?? false,
      defaultDashboardsWhitelist: [
        ...data.accounting,
        ...data.businessPerformance,
        ...data.dashboard,
        ...data.explore,
        ...data.operations,
        ...data.sales,
      ].map((dashboard) => (dashboard.type === 'builtin' ? dashboard.id : dashboard.displayName)),
    }
    try {
      const resp = await createUser({
        variables: {
          inputUser: inputUser,
        },
      })
      await updateDashboardCompanyUserIam({
        variables: {
          inputUser: inputUser,
          companyId: data.company?.id || null,
        },
      })
      void refetchUsers()
      setResetLink(resp.data!.createUser)
    } catch (e) {
      enqueueSnackbar(formatMaybeApolloError(e), { variant: 'error' })
    } finally {
      setIsCreating(false)
    }
  }

  useEffect(() => {
    if (userCompany || isExpedockAdmin) return
    enqueueSnackbar('You must belong to a company to create a new user.', { variant: 'error' })
    close()
  }, [enqueueSnackbar, userCompany, close, isExpedockAdmin])

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialog }}
      onClose={close}
      open={isOpen}
    >
      <IconButton
        aria-label='close'
        onClick={close}
        disabled={isCreating}
        className={classes.close}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle disableTypography>
        <Typography variant='h3'>Create User</Typography>
      </DialogTitle>
      <DialogContent>
        {resetLink ? (
          <>
            <p>Share this link so the end-user can reset their password</p>
            <textarea disabled className={classes.resetLinkBox}>
              {resetLink}
            </textarea>
          </>
        ) : (
          <FormProvider {...formMethods}>
            <FormControl fullWidth className={classes.formLine}>
              <Controller
                render={({ field: { value, onBlur, onChange } }) => (
                  <TextField
                    variant='outlined'
                    label='Email'
                    value={value}
                    fullWidth
                    onBlur={onBlur}
                    onChange={(event) => onChange(event.target.value)}
                  />
                )}
                name='email'
                defaultValue=''
              />
            </FormControl>
            <FormControl fullWidth className={classes.formLine}>
              <Controller
                render={({ field: { value, onBlur, onChange } }) => (
                  <TextField
                    variant='outlined'
                    label='Name'
                    value={value}
                    fullWidth
                    onBlur={onBlur}
                    onChange={(event) => onChange(event.target.value)}
                  />
                )}
                name='name'
                defaultValue=''
              />
            </FormControl>
            <FormControl fullWidth className={classes.formLine}>
              <UserRoleAutocomplete
                fieldName='roles'
                friendlyName='Roles'
                formError={formError}
                showAllRoles={isExpedockAdmin}
                multiple={isExpedockAdmin}
              />
            </FormControl>
            {isExpedockAdmin && (
              <>
                <FormControl fullWidth className={classes.formLine}>
                  <CompanyAutocomplete
                    fieldName='company'
                    friendlyName='Dashboard Company'
                    formError={formError}
                    multiple={false}
                    setFormCompanyId={setFormCompanyId}
                  />
                </FormControl>
                <FormControl fullWidth className={classes.formLine}>
                  <CompanyAutocomplete
                    fieldName='operatorCompanies'
                    friendlyName='Operator Companies'
                    multiple={true}
                  />
                </FormControl>
                <FormControl fullWidth className={classes.formLine}>
                  <Controller
                    render={({ field: { value, onBlur, onChange } }) => (
                      <TextField
                        variant='outlined'
                        label='Metabase Dashboard ID'
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={(event) => onChange(event.target.value)}
                      />
                    )}
                    name='metabaseDashboardId'
                    defaultValue=''
                  />
                </FormControl>
              </>
            )}
            <FormControl fullWidth className={classes.formLine}>
              <ShipmentUserDataAutocomplete
                fieldName='salesReps'
                friendlyName='Sales Rep'
                options={salesRepsData?.salesReps}
              />
            </FormControl>
            <FormControl fullWidth className={classes.formLine}>
              <ShipmentUserDataAutocomplete
                fieldName='operationsReps'
                friendlyName='Operator'
                options={operatorsData?.operators}
              />
            </FormControl>
            <FormControl fullWidth className={classes.formLine}>
              <ShipmentUserDataAutocomplete
                fieldName='branches'
                friendlyName='Branch'
                options={branchesData?.branches}
              />
            </FormControl>
            <FormControl fullWidth className={classes.formLine}>
              <ShipmentUserDataAutocomplete
                fieldName='departments'
                friendlyName='Department'
                options={departmentsData?.departments}
              />
            </FormControl>
            <FormControl fullWidth className={classes.switch}>
              <Controller
                render={({ field: { value, onBlur, onChange } }) => (
                  <FormControlLabel
                    value={value}
                    control={
                      <Switch
                        checked={value}
                        onBlur={onBlur}
                        onChange={(event, val) => onChange(val)}
                      />
                    }
                    label='Can only access shipments they are assigned to'
                    labelPlacement='start'
                  />
                )}
                name='onlyViewAssignedShipments'
                defaultValue={false}
              />
            </FormControl>
            <FormControl fullWidth className={classes.switch}>
              <Controller
                render={({ field: { value, onBlur, onChange } }) => (
                  <FormControlLabel
                    value={value}
                    control={
                      <Switch
                        checked={value}
                        onBlur={onBlur}
                        onChange={(_, val) => onChange(val)}
                      />
                    }
                    label='Skip sending verification email to new user'
                    labelPlacement='start'
                  />
                )}
                name='emailVerified'
                defaultValue={false}
              />
            </FormControl>
            <DefaultDashboardsWhitelist isCreate={true} companyId={formCompanyId} />
            <Button
              disabled={isCreating}
              variant='outlined'
              onClick={formMethods.handleSubmit(onSubmit)}
            >
              Create
            </Button>
          </FormProvider>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CreateUserDialog
