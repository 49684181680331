import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CompanyAdminTab } from '@src/utils/admin/sidebar'
import theme from '@src/utils/theme'
import { FunctionComponent, ReactNode } from 'react'

type Props = {
  selectedTab: CompanyAdminTab
  tab: CompanyAdminTab
  children: ReactNode
}

const useStyles = makeStyles({
  databaseCard: {
    margin: theme.spacing(5),
  },
  databaseCardHidden: {
    display: 'none',
  },
})

const AdminTabView: FunctionComponent<Props> = ({ selectedTab, tab, children }) => {
  const classes = useStyles()
  return (
    <Box className={selectedTab == tab ? classes.databaseCard : classes.databaseCardHidden}>
      {children}
    </Box>
  )
}

export default AdminTabView
