import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { CompanyNode } from '@src/graphql/types'
import { formatMaybeApolloError } from '@src/utils/errors'
import { useDialog } from 'muibox'
import { FunctionComponent, useCallback, useState } from 'react'
import { useSnackbar } from 'notistack'

type Props = {
  companies: CompanyNode[]
  redoReconcile: (companyIds: string[] | null | undefined) => Promise<void>
}

const ReReconOptions: FunctionComponent<Props> = ({ companies, redoReconcile }) => {
  const [selectedCompany, setSelectedCompany] = useState(null as null | CompanyNode)
  const [isRedoingRecon, setIsRedoingRecon] = useState(false)
  const dialog = useDialog()
  const { enqueueSnackbar } = useSnackbar()

  const triggerRedoRecon = useCallback(async (): Promise<void> => {
    setIsRedoingRecon(true)
    try {
      if (!selectedCompany) {
        enqueueSnackbar(`Please select a company and try again`, {
          variant: 'error',
        })
        return
      }
      await redoReconcile([selectedCompany.id])
      await dialog.alert({
        title: `Redo reconciliation script is now running for company: ${selectedCompany?.name}`,
        message: `Please note that recon changes may take a few minutes to reflect. You can now close this dialog.`,
      })
    } catch (e) {
      await dialog.alert({
        title: `Error redoing reconciliation for AP and SOA jobs of company: ${selectedCompany?.name}`,
        message: (
          <>
            <Typography>The following error was encountered:</Typography>
            <blockquote>{formatMaybeApolloError(e)}</blockquote>
          </>
        ),
      })
    } finally {
      setIsRedoingRecon(false)
    }
  }, [dialog, redoReconcile, selectedCompany])

  return (
    <Box py={1}>
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={12} md={4}>
          <Autocomplete
            multiple={false}
            options={companies}
            getOptionLabel={(option: CompanyNode) => option.name!}
            value={selectedCompany}
            onChange={(_event, newValue) => {
              setSelectedCompany(newValue)
            }}
            renderInput={(params) => <TextField variant='outlined' {...params} label='Company' />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            fullWidth
            disabled={isRedoingRecon}
            onClick={triggerRedoRecon}
            variant='outlined'
            color='primary'
          >
            {isRedoingRecon ? 'Redoing recon attempts...' : `Trigger redo recon`}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ReReconOptions
