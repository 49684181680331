import { DashboardAutocompleteOption } from '@src/pages/admin/user/default-dashboards-whitelist/DashboardNameAutocomplete'
import {
  AdminUserProfileNode,
  CompanyNode,
  useAllCompaniesQuery,
  useBulkUpdateUserDashboardWhitelistMutation,
  useListUsersQuery,
} from '@src/graphql/types'
import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { UserProfileContext } from '@src/auth/UserProfileContext'
import { useSnackbar } from 'notistack'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { groupBy, debounce } from 'lodash'
import { formatMaybeApolloError } from '@src/utils/errors'
import { reportRollbarError } from '@src/utils/observability/rollbar'
import Dialog from '@material-ui/core/Dialog'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core'
import DefaultDashboardsWhitelist from '@src/pages/admin/user/default-dashboards-whitelist/DefaultDashboardsWhitelist'
import { Autocomplete } from '@material-ui/lab'
import ProgressDialog from '@src/pages/admin/user/ProgressDialog'
import { clsx } from 'clsx'
import { Warning } from '@material-ui/icons'

type Props = {
  isExpedockAdmin: boolean
  user: AdminUserProfileNode
  onClose: () => void
}

type FormValues = {
  copyEntity: CopyEntity[]
  accounting: DashboardAutocompleteOption[]
  businessPerformance: DashboardAutocompleteOption[]
  explore: DashboardAutocompleteOption[]
  dashboard: DashboardAutocompleteOption[]
  operations: DashboardAutocompleteOption[]
  sales: DashboardAutocompleteOption[]
}

type CopyEntity = {
  type: 'company' | 'user'
  // For users, this is the company they belong to.
  companyId: string
  // Email for users, company id for companies.
  value: string
  label: string
}

type InvalidCopyOperation = {
  dashboard: string
  email: string
}

const mapUserToCopyEntity = (user: { email: string; companyId: string }): CopyEntity => ({
  type: 'user' as const,
  companyId: user.companyId,
  value: user.email,
  label: user.email,
})

const mapCompanyToCopyEntity = (company: Pick<CompanyNode, 'id' | 'name'>): CopyEntity => ({
  type: 'company' as const,
  companyId: company.id,
  value: company.id,
  label: `Add all users from ${company.name ?? company.id}`,
})

const getSelectedDashboards = (values: FormValues): DashboardAutocompleteOption[] => [
  ...values.accounting,
  ...values.businessPerformance,
  ...values.dashboard,
  ...values.explore,
  ...values.operations,
  ...values.sales,
]

const useStyles = makeStyles({
  invalidOperationContainer: {
    // Don't have a constant for this color? not part of our design system prolly
    backgroundColor: '#E2E8FD',
  },
  invalidOperationList: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    gap: theme.spacing(0.5),
    padding: theme.spacing(0, 1, 1, 1),
    margin: 0,
    // Limit displayed items to at most 5 users
    maxHeight: '100px',
    overflowY: 'auto',
  },
  heading: {
    fontWeight: 'bold',
  },
  flexbox: {
    gap: theme.spacing(1),
  },
})

const CopyDashboardAccessDialog: FunctionComponent<Props> = ({
  isExpedockAdmin,
  user,
  onClose,
}) => {
  const classes = useStyles()
  const { userProfile } = useContext(UserProfileContext)
  const { enqueueSnackbar } = useSnackbar()

  const formMethods = useForm<FormValues>({ defaultValues: { copyEntity: [] } })

  const [formError, setFormError] = useState<{ copyEntity?: string } | undefined>()
  const [showProgressDialog, setShowProgressDialog] = useState(false)
  const [invalidOperation, setInvalidOperation] = useState<InvalidCopyOperation[]>([])

  const [updateUsers] = useBulkUpdateUserDashboardWhitelistMutation()

  const { data: companiesData } = useAllCompaniesQuery({
    skip: !isExpedockAdmin,
  })
  const { data: usersData, refetch: fetchListUsers } = useListUsersQuery({
    variables: {
      pageNumber: 1,
      paginate: false,
    },
  })

  const companies = companiesData?.allCompanies ?? []
  const users =
    usersData?.listUsers.users.map((user) => ({
      email: user.email,
      companyId: user.company?.id ?? '',
    })) ?? []
  const usersByCompanyId = groupBy(users, 'companyId')

  const copyEntityOptions = useMemo(() => {
    if (!userProfile) return []
    const userOptions = users.map(mapUserToCopyEntity)

    if (isExpedockAdmin) {
      const companyOptions = companies
        .map(mapCompanyToCopyEntity)
        .filter(({ value }) => Object.keys(usersByCompanyId).includes(value))
      return [...companyOptions, ...userOptions]
    }

    if (!userProfile.company) return userOptions
    return [
      {
        type: 'company' as const,
        companyId: userProfile.company.id,
        value: userProfile.company.id,
        label: 'Add all users of this company',
      },
      ...userOptions,
    ]
  }, [userProfile, companies, users, usersByCompanyId])

  const handleChange = (value: CopyEntity[], onChange: (value: CopyEntity[]) => void): void => {
    const selectedValues = groupBy(value, 'type')
    if (!selectedValues.company) {
      onChange(value)
      return
    }

    // Auto-select all users from the selected company, without duplicates
    const selectedUsers = selectedValues.user ?? []
    const selectedCompany = selectedValues.company[0].value
    const selectedEmails = selectedUsers.map((user) => user.value) ?? []
    const newSelectedUsers =
      usersByCompanyId[selectedCompany]
        ?.filter((user) => !selectedEmails.includes(user.email))
        .map(mapUserToCopyEntity) ?? []
    onChange([...selectedUsers, ...newSelectedUsers])
  }

  const handleInputChange = debounce((value: string, reason: string) => {
    const searchTerm = reason === 'reset' ? '' : value.trim()
    // Auth0 can only query if our search term is atleast 3 chars or it's empty
    // https://auth0.com/docs/manage-users/user-search/user-search-query-syntax#searchable-fields
    if (searchTerm.length > 0 && searchTerm.length < 3) return
    void fetchListUsers({
      email: searchTerm,
      pageNumber: 1,
      paginate: false,
    })
  }, 300)

  const validateFields = (data: FormValues): boolean => {
    if (!data.copyEntity.length) {
      setFormError((p) => ({ ...p, copyEntity: 'Please select at least one user' }))
      return false
    }
    setFormError(undefined)
    return true
  }

  const onSubmit = async (data: FormValues): Promise<void> => {
    if (!validateFields(data)) return
    setShowProgressDialog(true)
    try {
      await updateUsers({
        variables: {
          defaultDashboardWhitelist: getSelectedDashboards(data).map((dashboard) => dashboard.id),
          userEmails: data.copyEntity.map((entity) => entity.value),
        },
      })
      onClose()
    } catch (err) {
      enqueueSnackbar(formatMaybeApolloError(err), { variant: 'error' })
      reportRollbarError(`Failed to copy dashboard access ${err}`)
    } finally {
      setShowProgressDialog(false)
    }
  }

  useEffect(() => {
    const watchForm = formMethods.watch((value) => {
      // We assert the type here because we know that all fields must be defined.
      // If they aren't, we have a bug in our code and must set a valid default value
      // for any new field added -- we never want undefined default values to keep
      // all fields `controlled`.
      const newValues = value as FormValues
      const newInvalidOperations: InvalidCopyOperation[] = []
      const selectedUsers = newValues.copyEntity.sort((a, b) => a.label.localeCompare(b.label))
      getSelectedDashboards(newValues)
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .forEach((dashboard) => {
          if (dashboard.isDefault) return
          selectedUsers.forEach((selectedUser) => {
            if (selectedUser.companyId !== user.company?.id) {
              newInvalidOperations.push({
                dashboard: dashboard.displayName,
                email: selectedUser.value,
              })
            }
          })
        })
      setInvalidOperation(newInvalidOperations)
    })
    return () => watchForm.unsubscribe()
  }, [formMethods.watch])

  return (
    <>
      <FormProvider {...formMethods}>
        <Dialog fullWidth maxWidth='md' open={!showProgressDialog} onClose={onClose}>
          <DialogTitle>
            <Typography className={classes.heading} variant='h5'>
              Copy Dashboard Access
            </Typography>
            <Typography className={classes.heading} color='error'>
              !!! WARNING: THIS WILL COMPLETELY OVERRIDE THE DASHBOARD ACCESS OF THE USERS YOU COPY
              TO !!!
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box display='flex' flexDirection='column' className={classes.flexbox}>
              <Typography variant='h6' className={classes.heading}>
                Dashboard access to copy over
              </Typography>
              <DefaultDashboardsWhitelist
                isCreate={false}
                companyId={user.company?.id}
                defaultDashboardsWhitelist={user.defaultDashboardsWhitelist}
              />
              <Typography variant='h6' className={classes.heading}>
                Users to give access to
              </Typography>
              <Controller
                name='copyEntity'
                render={({ field: { value, onBlur, onChange } }) => (
                  <Autocomplete
                    multiple
                    options={copyEntityOptions}
                    getOptionLabel={(option: CopyEntity) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={formError?.copyEntity !== undefined}
                        helperText={formError?.copyEntity}
                      />
                    )}
                    value={value}
                    onBlur={onBlur}
                    onChange={(_, newValues) => handleChange(newValues, onChange)}
                    onInputChange={(_, value, reason) => handleInputChange(value, reason)}
                  />
                )}
              />
              {invalidOperation.length > 0 && (
                <Box
                  display='flex'
                  flexDirection='column'
                  marginTop={1}
                  className={clsx(classes.invalidOperationContainer, classes.flexbox)}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    padding={1}
                    className={classes.flexbox}
                  >
                    <Warning style={{ color: theme.palette.warning.main }} />
                    <Typography className={classes.heading}>
                      Warning: the following reports won&apos;t be copied over for the following
                      users:
                    </Typography>
                  </Box>
                  <ul className={classes.invalidOperationList}>
                    {invalidOperation.map((operation) => (
                      <li key={`${operation.dashboard}_${operation.email}`}>
                        {operation.dashboard} - {operation.email}
                      </li>
                    ))}
                  </ul>
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              variant='contained'
              color='primary'
              onClick={() => formMethods.handleSubmit(onSubmit)()}
            >
              Copy & Override
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
      <ProgressDialog
        open={showProgressDialog}
        title='Please wait while we are saving your changes.'
      />
    </>
  )
}

export default CopyDashboardAccessDialog
