import { gql } from '@apollo/client'

export const UPSERT_JOB_TEMPLATE = gql`
  mutation upsertJobTemplate(
    $id: UUID!
    $name: String
    $companyId: UUID
    $description: String
    $slaTime: Int
    $reconType: JobTemplateReconType
    $shipmentOpsTypes: [ShipmentOp!]
    $documentTypes: [InputDocumentType!]
    $createIfMissing: Boolean
    $fieldGroups: [InputFieldGroup!]
    $credentialId: UUID
    $apiPartnerId: UUID
    $defaultExternalAssigneeId: UUID
    $autoReconEnabled: Boolean
    $subtotalsRowEnabled: Boolean
    $mainTabEnabled: Boolean
    $autoPostEnabled: Boolean
    $showPostButton: Boolean
    $requireShowReconToCustomer: Boolean
    $requireEdocsPushPull: Boolean
    $requireExternalAssignee: Boolean
    $inputReconThresholdRanges: [InputReconThresholdRange!]
  ) {
    upsertJobTemplate(
      id: $id
      name: $name
      companyId: $companyId
      description: $description
      slaTime: $slaTime
      reconType: $reconType
      shipmentOpsTypes: $shipmentOpsTypes
      documentTypes: $documentTypes
      createIfMissing: $createIfMissing
      fieldGroups: $fieldGroups
      credentialId: $credentialId
      apiPartnerId: $apiPartnerId
      defaultExternalAssigneeId: $defaultExternalAssigneeId
      autoReconEnabled: $autoReconEnabled
      subtotalsRowEnabled: $subtotalsRowEnabled
      mainTabEnabled: $mainTabEnabled
      autoPostEnabled: $autoPostEnabled
      showPostButton: $showPostButton
      requireShowReconToCustomer: $requireShowReconToCustomer
      requireEdocsPushPull: $requireEdocsPushPull
      requireExternalAssignee: $requireExternalAssignee
      inputReconThresholdRanges: $inputReconThresholdRanges
    ) {
      ok
    }
  }
`

export const SAVE_JOB_TEMPLATE_EXPORT_FORMAT = gql`
  mutation saveJobTemplateExportFormat(
    $jobTemplateId: UUID!
    $exportType: JobTemplateExportType!
    $jobTemplateExportSheets: [InputJobTemplateExportSheet!]!
  ) {
    saveJobTemplateExportFormat(
      jobTemplateId: $jobTemplateId
      exportType: $exportType
      jobTemplateExportSheets: $jobTemplateExportSheets
    ) {
      ok
    }
  }
`
