import { ChangeEvent, FunctionComponent, useCallback, useMemo } from 'react'
import { ShipmentOp } from '@src/graphql/types'
import { useFormContext, useWatch } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'
import { Box, TextField } from '@material-ui/core'
import { JobTemplateFormValues } from '@src/components/admin/job-template-editor/JobTemplateEditor'
import {
  ShipmentOpsTypeOption,
  toShipmentOpsTypeOption,
} from '@src/utils/admin/shipment_ops_type_option'
import { isPresent } from 'ts-is-present'
import { uniqBy } from 'lodash'

const ShipmentOpsSelector: FunctionComponent = () => {
  const selectedShipmentOpsTypes = useWatch<JobTemplateFormValues>({
    name: 'shipmentOpsTypes',
  }) as ShipmentOpsTypeOption[]
  const { setValue } = useFormContext<JobTemplateFormValues>()
  const shipmentOpsTypeOptions = useMemo((): ShipmentOpsTypeOption[] => {
    return Object.values(ShipmentOp).map((shipmentOp) => {
      return toShipmentOpsTypeOption(shipmentOp)
    })
  }, [])

  const onAutocompleteChange = useCallback(
    (_: ChangeEvent<unknown>, newValue: ShipmentOpsTypeOption[]): void => {
      const oldShipmentOpsTypeTitles = new Set(
        selectedShipmentOpsTypes.map((item: ShipmentOpsTypeOption) => item.title.toLowerCase()),
      )
      const newShipmentOpsTypeTitles = new Set(
        newValue.map((item) => item.title.toLowerCase()).filter(isPresent),
      )
      const deletedShipmentOpsTypesTitles = selectedShipmentOpsTypes
        .filter((item) => !newShipmentOpsTypeTitles.has(item.title.toLowerCase()))
        .map((item) => item.title.toLowerCase())
      const newShipmentOpsTypes = newValue.filter(
        (item) => !oldShipmentOpsTypeTitles.has(item.title.toLowerCase()),
      ) as ShipmentOpsTypeOption[]

      const newSelectedShipmentOpsTypes = uniqBy(
        selectedShipmentOpsTypes
          .filter(({ title }) => !deletedShipmentOpsTypesTitles.includes(title.toLowerCase()))
          .concat(newShipmentOpsTypes),
        (shipmentOpsTypeOption) => shipmentOpsTypeOption.title,
      )

      setValue('shipmentOpsTypes', [...newSelectedShipmentOpsTypes])
    },
    [setValue, selectedShipmentOpsTypes],
  )

  const renderInput = useCallback((params) => <TextField {...params} variant='outlined' />, [])
  return (
    <Box position='relative'>
      <Autocomplete
        multiple
        size='small'
        value={selectedShipmentOpsTypes}
        onChange={onAutocompleteChange}
        options={shipmentOpsTypeOptions}
        getOptionLabel={(option) => option.title}
        renderInput={renderInput}
      />
    </Box>
  )
}
export default ShipmentOpsSelector
