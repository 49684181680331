import { formatMaybeApolloError } from '@src/utils/errors'
import { EntityState } from '@reduxjs/toolkit'
import { JobDocumentTable } from '@src/utils/shipment_form'
import { SpreadsheetDataColumn } from './data-grid'
import {
  buildSearchableRecordFilters,
  isChargeCodeField,
  isOrderIdentifierField,
} from './searchable_record'
import { isPresent } from 'ts-is-present'
import {
  CompanyNode,
  FieldNode,
  Maybe,
  Query,
  QuerySearchableRecordResultsArgs,
  ShipmentOpsShipmentOrderNumberAutofillKey,
} from '@src/graphql/types'
import { ApolloQueryResult } from '@apollo/client'
import { DocumentEditorPage } from '@src/redux-features/document_editor/document_editor_state'
import { LineItemsTableMap } from '@src/redux-features/document_editor/line_items_table'
import { SnackbarKey, OptionsObject, SnackbarMessage } from 'notistack'

/**
  Get special autocomplete rendering for a field in handsontable, if needed. Uses HOT in HOT paradigm.
  See https://github.com/handsontable/handsontable/issues/584 for a further discussion.
  @function getCustomTableTypesFromRecordType
  @param {FieldNode} field The field to get the custom table type for
  @param {col} SpreadsheetDataColumn The column to get the custom table type for
  @param {function} searchableRecordsFunction A function that returns a list of searchable records
  @param {Maybe<Record<string, DocumentEditorPage>>} pages A dictionary of pages to build the searchable record fn
  @param {Maybe<CompanyNode>} company A company node used to build the searchable record fn
  @returns {SpreadsheetDataColumn} col the new column with custom table type
*/
export const getCustomTableTypesFromRecordType = (
  field: FieldNode,
  col: SpreadsheetDataColumn,
  searchableRecordsFunction:
    | ((
        variables?: Partial<QuerySearchableRecordResultsArgs> | undefined,
      ) => Promise<ApolloQueryResult<Pick<Query, 'searchableRecordResults'>>>)
    | null,
  enqueueSnackbar:
    | ((message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey)
    | null,
  pages?: Maybe<Record<string, DocumentEditorPage>>,
  company?: Maybe<CompanyNode>,
  apiPartnerId?: Maybe<string>,
): SpreadsheetDataColumn => {
  /*
    These column widths look decent on most PCs with horizontal displays.
    The width of the code column is fixed at 100 pixels while the description
    column expands as the user expands the width of the dropdown table.
    codeWidth and descriptionWidth should always sum up to col.width.
  */
  col.width = 300
  if (isChargeCodeField(field)) {
    const codeWidth = 100
    const descriptionWidth = col.width - codeWidth
    col.handsontable = {
      dataSchema: {
        code: null,
        description: null,
      },
      columns: [
        {
          title: 'Code',
          width: codeWidth,
          data: 'code',
        },
        {
          title: 'Description',
          width: descriptionWidth,
          data: 'description',
        },
      ],
      getValue() {
        const selection = this.getSelected()
        return (this.getSourceDataAtRow(selection[0][0])?.code as string) || ''
      },
    }
    col.source = async (query, process) => {
      const filters = buildSearchableRecordFilters(
        field.searchableRecord,
        pages,
        company,
        apiPartnerId,
      )
      const recordsResultData =
        searchableRecordsFunction &&
        (await searchableRecordsFunction({
          searchableRecordId: field.searchableRecord!.id,
          queryString: query,
          filters,
        }).catch((err) => {
          enqueueSnackbar &&
            enqueueSnackbar(
              `Failed to fetch searchable record results: ${formatMaybeApolloError(err)}`,
              {
                variant: 'error',
              },
            )
        }))
      const results = recordsResultData?.data?.searchableRecordResults?.filter(isPresent) ?? []
      process(results.map((res: Record<string, unknown>) => JSON.stringify(res)))
    }
    return col as SpreadsheetDataColumn
  }
  return col
}

const buildAutofillKeyToKeyMapFromDocTables = (
  documentTables: EntityState<JobDocumentTable>,
  repeatableFieldGroupAutofillKey: string,
): Record<string, string> => {
  return Object.fromEntries(
    Object.values(documentTables.entities)
      .filter(
        (documentTable) =>
          documentTable?.fieldGroup.autofillKey === repeatableFieldGroupAutofillKey,
      )
      .flatMap((documentTable) =>
        documentTable!.fieldGroup!.fields!.edges!.map((fieldEdge) => [
          fieldEdge!.node!.autofillKey,
          fieldEdge!.node!.key,
        ]),
      ),
  )
}

const getUniqueOrderIdentifierValues = (
  documentTables: EntityState<JobDocumentTable>,
  lineItemsTableMap: LineItemsTableMap,
): string[] => {
  const orderNumberLineItemsAutofillKey =
    ShipmentOpsShipmentOrderNumberAutofillKey.OrderNumberLineItem.toLowerCase()
  const orderNumberAutofillKeyToKeyMap = buildAutofillKeyToKeyMapFromDocTables(
    documentTables,
    orderNumberLineItemsAutofillKey,
  )

  const orderNumberFieldKey =
    orderNumberAutofillKeyToKeyMap?.[
      ShipmentOpsShipmentOrderNumberAutofillKey.OrderNumber.toLowerCase()
    ] ?? null
  const orderNumberSplitFieldKey =
    orderNumberAutofillKeyToKeyMap?.[
      ShipmentOpsShipmentOrderNumberAutofillKey.OrderNumberSplit.toLowerCase()
    ] ?? null
  const orderNumberVendorFieldKey =
    orderNumberAutofillKeyToKeyMap?.[
      ShipmentOpsShipmentOrderNumberAutofillKey.Vendor.toLowerCase()
    ] ?? null

  const orderIdentifiers = new Set<string>()
  Object.values(documentTables.entities).forEach((documentTable) => {
    if (documentTable?.fieldGroup.autofillKey === orderNumberLineItemsAutofillKey) {
      Object.values(lineItemsTableMap?.[documentTable!.id]?.lineItems?.entities ?? []).forEach(
        (lineItem) => {
          const orderNumber = lineItem?.fieldMapping?.[orderNumberFieldKey]?.value
          const orderNumberSplit = lineItem?.fieldMapping?.[orderNumberSplitFieldKey]?.value
          const orderNumberVendor = lineItem?.fieldMapping?.[orderNumberVendorFieldKey]?.value
          if (orderNumber && orderNumberSplit && orderNumberVendor) {
            const orderIdentifierDelimiter = '〰️'
            const orderIdentifier = [orderNumber, orderNumberSplit, orderNumberVendor].join(
              orderIdentifierDelimiter,
            )
            orderIdentifiers.add(orderIdentifier)
          }
        },
      )
    }
  })

  return Array.from(orderIdentifiers)
}

/**
 We can define custom dropdown values for line item fields here. An example usage is a string concatenation
 of all possible field values from another line items table.
 @function getCustomColumnValuesFromLineItems
 @param {FieldNode} field The field to get the custom values for
 @param {EntityState<JobDocumentTable> | null} documentTables document tables from redux state if needed for custom logic (e.g. real-time deriving of values)
 @param {LineItemsTableMap | null} lineItemsTableMap line items table mapping from redux state for convenient parsing of line item values
 @returns {string[] | null} array of string values. use null if we dont want to display a down chevron in the cell
 */
export const getCustomColumnValuesFromLineItems = (
  field: FieldNode,
  documentTables: EntityState<JobDocumentTable> | null,
  lineItemsTableMap: LineItemsTableMap | null,
): string[] | null => {
  if (!documentTables || !lineItemsTableMap) {
    return null
  }

  if (isOrderIdentifierField(field)) {
    const orderIdentifiers = getUniqueOrderIdentifierValues(documentTables, lineItemsTableMap)
    if (orderIdentifiers.length > 0) {
      return orderIdentifiers
    }
  }
  return null
}
