import { FunctionComponent, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useMutation, useQuery } from '@apollo/client'
import { Link, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { JOB_OPERATORS } from '@src/graphql/queries/job'
import { EDIT_JOB } from '@src/graphql/mutations/job'
import { JOB_NOTES } from '@src/graphql/queries/note'
import theme from '@src/utils/theme'
import {
  JobExternalStatus,
  JobNode,
  JobTemplateReconType,
  Mutation,
  MutationEditJobArgs,
  Query,
  UserNode,
} from '@src/graphql/types'
import NotesOverview from './NotesOverview'
import { Autocomplete } from '@material-ui/lab'
import { reportRollbarError } from '@src/utils/observability/rollbar'
import { formatMaybeApolloError } from '@src/utils/errors'
import { getDeployEnvironment } from '@src/utils/environment'
import { ENV_DASHBOARD_URL_MAP, ENV_PASSIVE_DASHBOARD_URL_MAP } from '@src/utils/app_constants'
import ExternalAssigneeSelector from '@src/components/job-viewer/ExternalAssigneeSelector'
import { ALLOWED_RECON_TYPES_FOR_HISTORY } from '../recon-history'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { alpha } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles({
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
    },
  },
  infoPanel: {
    '& dt': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '& dd': {
      margin: 0,
      overflowWrap: 'break-word',
    },
  },
})

type Props = {
  job: JobNode
  refetchJobData: (
    variables?:
      | Partial<{
          id: string
        }>
      | undefined,
  ) => Promise<unknown>
}

type ExternalAssigneeOption = {
  id: string
  email: string
}

const jobExternalStatusLabel = {
  [JobExternalStatus.ForExpedock]: 'For Expedock',
  [JobExternalStatus.Todo]: 'To do',
  [JobExternalStatus.Done]: 'Done',
}

const JobInfo: FunctionComponent<Props> = ({ job, refetchJobData }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const enableInvoiceReferenceExternalAssigneeAndStatus = useFeatureIsOn(
    'invoice-reference-external-assignee-and-status',
  )

  const disableExternalDataFields =
    enableInvoiceReferenceExternalAssigneeAndStatus &&
    job.jobTemplate.reconType === JobTemplateReconType.Soa

  const [jobName, setJobName] = useState(job.name)
  const [qaId, setQAId] = useState(job.qaId ?? null)
  const [jobExternalStatus, setJobExternalStatus] = useState<JobExternalStatus | null>(
    job.externalStatus && !disableExternalDataFields ? job.externalStatus : null,
  )
  const [jobExternalAssignee, setJobExternalAssignee] = useState<ExternalAssigneeOption | null>(
    job.externalAssignee && !disableExternalDataFields
      ? { id: job.externalAssignee.id, email: job.externalAssignee.email }
      : null,
  )

  const [editJob, { error: editJobError }] = useMutation<
    Pick<Mutation, 'editJob'>,
    MutationEditJobArgs
  >(EDIT_JOB, {
    refetchQueries: [{ query: JOB_NOTES, variables: { jobId: job.id } }],
  })

  const { data: jobOperatorData, loading: isLoadingOperators } =
    useQuery<Pick<Query, 'jobOperators'>>(JOB_OPERATORS)

  const reconJobUrl = getReconJobUrl(job)
  const operators = jobOperatorData?.jobOperators || []

  const handleSubmit = async (): Promise<void> => {
    if (!job) {
      enqueueSnackbar('Job does not exist. Engineering has been notified.', { variant: 'error' })
      reportRollbarError('Job no longer exists after trying to save job details.')
      return
    } else if (!jobExternalAssignee) {
      enqueueSnackbar('Job external assignee does not exist. Continuing to save.', {
        variant: 'warning',
      })
    }
    try {
      await editJob({
        variables: {
          jobId: job.id,
          name: jobName,
          qaId,
          externalStatus: jobExternalStatus,
          externalAssigneeId: jobExternalAssignee?.id,
        },
      })
    } catch (error) {
      enqueueSnackbar(`Failed to save job details: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
    if (editJobError) {
      enqueueSnackbar(editJobError, { variant: 'error' })
      return
    }
    await refetchJobData({ id: job.id })
    enqueueSnackbar('Successfully saved job details.', { variant: 'success' })
  }

  useEffect(() => {
    if (disableExternalDataFields) return
    setJobExternalAssignee(job?.externalAssignee ?? null)
  }, [disableExternalDataFields, job?.externalAssignee])

  useEffect(() => {
    if (disableExternalDataFields) return
    setJobExternalStatus(job.externalStatus ?? null)
  }, [disableExternalDataFields, job.externalStatus])

  const enableReconHistory = useFeatureIsOn('recon-history')
  const canViewReconHistory = ALLOWED_RECON_TYPES_FOR_HISTORY.includes(job.jobTemplate.reconType)

  return (
    <Box className={classes.dataContainer}>
      <Paper square>
        <Typography variant='h3' gutterBottom>
          Job Data
        </Typography>
        <Grid component='dl' container className={classes.infoPanel} spacing={2}>
          <Grid item xs={6}>
            <dt>Job Type</dt>
            <dd>{job.jobTemplate!.name}</dd>
          </Grid>
          <Grid item xs={6}>
            <dt>Job Status</dt>
            <dd>{job.status}</dd>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={jobName}
              onChange={(e) => setJobName(e.target.value)}
              variant='outlined'
              label='Job Name'
              inputProps={{ 'data-testid': 'job-name-input' }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel>Job QA</InputLabel>
              <Select
                value={qaId || ''}
                onChange={(e) => setQAId(e.target.value as string)}
                label='Job QA'
                disabled={isLoadingOperators}
              >
                {operators.map((currOperator: UserNode) => (
                  <MenuItem key={currOperator.id} value={currOperator.id}>
                    {currOperator.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={Object.values(JobExternalStatus)}
              value={jobExternalStatus}
              getOptionLabel={(option) => jobExternalStatusLabel[option]}
              onChange={(_, selected) => setJobExternalStatus(selected)}
              renderInput={(params) => (
                <TextField {...params} label='External Status' variant='outlined' />
              )}
              data-testid='job-external-status-autocomplete'
              disabled={disableExternalDataFields}
            />
          </Grid>
          <Grid item xs={6}>
            <ExternalAssigneeSelector
              companyId={job.jobTemplate.company.id}
              value={jobExternalAssignee}
              onChange={(value) => setJobExternalAssignee(value)}
              label='External Assignee'
              disabled={disableExternalDataFields}
            />
          </Grid>
          {disableExternalDataFields && (
            <Grid item xs={12}>
              <Typography
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: theme.spacing(1),
                  backgroundColor: alpha(theme.palette.warning.light, 0.3),
                  borderRadius: theme.spacing(0.5),
                  color: theme.palette.warning.contrastText,
                }}
              >
                ⚠️ External assignee and status fields has been moved to the &quot;Batch Reconcile
                SOA&quot; modal.
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={job.message}
              variant='outlined'
              label='Job Message'
              multiline
              rows={6}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='contained'
              type='submit'
              disableElevation
              onClick={handleSubmit}
              data-testid='save-job-details-btn'
            >
              Save Job Details
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper square>
        <Typography variant='h3' gutterBottom>
          Job Notes
        </Typography>
        {reconJobUrl && (
          <Typography variant='body2'>
            <strong>Dashboard link:</strong>&nbsp;
            <Link href={reconJobUrl} target='_blank' rel='noopener noreferrer'>
              {reconJobUrl}
            </Link>
          </Typography>
        )}

        {canViewReconHistory && enableReconHistory && (
          <Button
            variant='contained'
            disableElevation
            href={`/recon-history/${job.id}`}
            target='_blank'
          >
            View Reconciliation History
          </Button>
        )}

        <NotesOverview
          jobId={job.id}
          reconType={job.jobTemplate.reconType}
          reconAttemptId={job?.latestSavedReconAttemptId}
        />
      </Paper>
    </Box>
  )
}

const getReconJobUrl = ({
  id,
  jobTemplate,
  latestSavedReconAttemptId,
}: JobNode): string | undefined => {
  /**
   * Generates the Recon Job URL for AP jobs that have an existing recon attempt.
   */
  if (jobTemplate.reconType !== JobTemplateReconType.Ap || !latestSavedReconAttemptId) {
    return undefined
  }

  const deployEnvironment = getDeployEnvironment()
  const dashboardHostname = window.location.hostname.includes('passive')
    ? ENV_PASSIVE_DASHBOARD_URL_MAP[deployEnvironment]
    : ENV_DASHBOARD_URL_MAP[deployEnvironment]
  return `${dashboardHostname}/job/${id}`
}

export default JobInfo
