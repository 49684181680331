export enum CallToAction {
  EXTRACT = 'extract',
  COPY_FROM_EXTRACTED = 'copy_from_extracted',
  REPLACE = 'replace',
  UNMERGE = 'unmerge',
  PREV = 'prev',
  NEXT = 'next',
  SEND_DRAFT_TO_CW = 'send_draft_to_cw',
  CHECK_SHIPMENTS = 'check_shipments',
}
