import {
  Box,
  Collapse,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core'
import clsx from 'clsx'
import { FunctionComponent, ReactElement, useState } from 'react'
import theme from '@src/utils/theme'
import { TableDisplayData } from './util'
import {
  ORDER_LINE_DATA_TABLE_COLUMNS,
  ORDER_NUMBER_COLLECTION_DATA_TABLE_COLUMNS,
} from '@src/utils/recon/ap_recon'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { ChainIoOrderLineNodeEdge } from '@src/graphql/types'

const MIN_CELL_WIDTH = '75px'

const useStyles = makeStyles<Theme>({
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.75, 1),
    minWidth: MIN_CELL_WIDTH,
  },
  wrapWord: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
})

type Props = {
  dataTableColumns: string[]
  expectedDataMatrix: TableDisplayData
}

const CheckShipmentInfoTable: FunctionComponent<Props> = ({
  dataTableColumns,
  expectedDataMatrix,
}) => {
  const classes = useStyles()
  const isOrderCollectionTable =
    dataTableColumns.toString() == ORDER_NUMBER_COLLECTION_DATA_TABLE_COLUMNS.toString()

  const OrderAccordianRow: FunctionComponent<{
    row: string[]
    rowIdx: number
  }> = ({ row, rowIdx }): ReactElement => {
    const [open, setOpen] = useState(false)
    const orderLines = row[
      ORDER_NUMBER_COLLECTION_DATA_TABLE_COLUMNS.length
    ] as unknown as ChainIoOrderLineNodeEdge[]
    return (
      <>
        <TableRow
          key={rowIdx}
          onClick={orderLines.length ? () => setOpen(!open) : undefined}
          style={{ cursor: orderLines.length ? 'pointer' : undefined }}
        >
          {ORDER_NUMBER_COLLECTION_DATA_TABLE_COLUMNS.map((_, colIdx) => {
            const canExpand =
              orderLines.length && colIdx == ORDER_NUMBER_COLLECTION_DATA_TABLE_COLUMNS.length - 1
            return (
              <TableCell
                className={clsx(classes.cell, classes.wrapWord)}
                component='th'
                scope='row'
                key={colIdx}
              >
                {row[colIdx]}
                {!!canExpand && (
                  <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                )}
              </TableCell>
            )
          })}
        </TableRow>
        <TableRow>
          <TableCell
            className={classes.colCell}
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingRight: 0,
            }}
            colSpan={ORDER_NUMBER_COLLECTION_DATA_TABLE_COLUMNS.length}
          >
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Table>
                <TableHead>
                  <TableRow>
                    {ORDER_LINE_DATA_TABLE_COLUMNS.map((col, colIdx) => (
                      <TableCell
                        className={clsx(classes.cell, classes.colCell)}
                        align='center'
                        key={colIdx}
                      >
                        {col}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderLines.map((lineEdge: ChainIoOrderLineNodeEdge, rowIdx) => {
                    const lineNode = lineEdge.node
                    const lineRowData = [
                      lineNode.orderLineNumber ?? '',
                      lineNode.partNumber ?? '',
                      lineNode.description ?? '',
                      lineNode.containerNumber ?? '',
                    ]
                    return (
                      <TableRow key={rowIdx}>
                        {lineRowData.map((col, colIdx) => (
                          <TableCell
                            className={classes.cell}
                            align='center'
                            key={`${rowIdx}-${colIdx}`}
                          >
                            {col}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <Box display='flex' alignItems='baseline' mb={2}>
      <Box display='flex' flexDirection='column'>
        <TableContainer>
          <Table size='small'>
            <TableBody>
              <TableRow>
                {dataTableColumns.map((col, colIdx) => (
                  <TableCell
                    className={clsx(classes.cell, classes.colCell)}
                    align='center'
                    key={colIdx}
                  >
                    {col}
                  </TableCell>
                ))}
              </TableRow>
              {expectedDataMatrix.map((row, rowIdx) => {
                if (!row.length) return <></>
                // order collection data rows can expand if lines present
                if (isOrderCollectionTable) return <OrderAccordianRow row={row} rowIdx={rowIdx} />
                return (
                  <TableRow key={rowIdx}>
                    {row.map((data, colIdx) => {
                      return (
                        <TableCell
                          className={clsx(classes.cell, classes.wrapWord)}
                          component='th'
                          scope='row'
                          key={colIdx}
                        >
                          {data}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default CheckShipmentInfoTable
