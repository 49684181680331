import { FieldMappingValue } from '@src/types/fieldmapping'
import { BoundingBox, BoxDimension, Vertex } from '@src/types/ocr'
import { distSquared } from '@src/utils/ocr'
import { minBy } from 'lodash'
import { LINE_THRESHOLD, MIDPOINT_THRESHOLD } from './app_constants'

const getPointsInRange = (box: BoxDimension, ocrPoints: Vertex[]): Vertex[] => {
  const { top, left, width } = box
  const right = left + width
  const pointsInRange = ocrPoints.filter(
    (point) => point.x >= left && point.x <= right && point.y + MIDPOINT_THRESHOLD > top,
  )
  return pointsInRange
}

const getClosestPointByYValue = (coord: number, points: Vertex[], above: boolean): number => {
  const partitionedPoints = points.filter((point) =>
    above ? point.y < coord + MIDPOINT_THRESHOLD : point.y + MIDPOINT_THRESHOLD > coord,
  )
  const closestPointY = minBy(partitionedPoints, (point) => Math.abs(point.y - coord))?.y ?? coord
  return closestPointY
}

export const snapRowBox = (box: BoxDimension, wordBoxes: BoundingBox[]): BoxDimension => {
  const topLeftPoints = wordBoxes.map((wordBox) => wordBox.vertices[0])
  const bottomLeftPoints = wordBoxes.map((wordBox) => wordBox.vertices[3])
  const topPointsInRange = getPointsInRange(box, topLeftPoints)
  const bottomPointsInRage = getPointsInRange(box, bottomLeftPoints)
  const top = getClosestPointByYValue(box.top, topPointsInRange, false)
  const bottom = getClosestPointByYValue(top + box.height, bottomPointsInRage, true)
  box.top = top
  // we need at least a height of more than one letter, or boxing is near impossible
  box.height = Math.max(Math.abs(bottom - top), 1.25 * LINE_THRESHOLD)
  return box
}

const getTopPointsInYBoundary = (
  boundingBox: BoxDimension | undefined,
  points: Vertex[],
): Vertex[] => {
  if (boundingBox && boundingBox.top && boundingBox.height) {
    return points.filter(
      (point) =>
        point.y + MIDPOINT_THRESHOLD > boundingBox.top &&
        point.y < boundingBox.top + boundingBox.height,
    )
  }
  return points
}

const isStartWordInBoundary = (rowBox: BoxDimension | undefined, wordBox: BoundingBox): boolean => {
  if (!rowBox || !rowBox.top || !rowBox.height || !rowBox.left || !rowBox.width) return true
  const wordMidpoint: Vertex = {
    x: (wordBox.vertices[0].x + wordBox.vertices[2].x) / 2,
    y: (wordBox.vertices[0].y + wordBox.vertices[2].y) / 2,
  }
  return (
    wordMidpoint.y > rowBox.top &&
    wordMidpoint.y < rowBox.top + rowBox.height &&
    wordMidpoint.x > rowBox.left &&
    wordMidpoint.x < rowBox.left + rowBox.width
  )
}

export const snapFieldBox = (
  fieldMapping: FieldMappingValue,
  wordBoxes: BoundingBox[],
  rowBox: BoxDimension | undefined,
): FieldMappingValue => {
  const fieldMappingCopy = { ...fieldMapping }
  if (!fieldMappingCopy.left || !fieldMappingCopy.top || !fieldMappingCopy.width) {
    return fieldMappingCopy
  }

  const matchingWords = wordBoxes.filter((wordBox) => isStartWordInBoundary(rowBox, wordBox))

  const topLeft = { x: fieldMappingCopy.left, y: fieldMappingCopy.top }
  const leftMostMatchingWord = minBy(matchingWords, (wordBox) =>
    distSquared(topLeft, wordBox.vertices[0]),
  )

  const topRight = { x: fieldMappingCopy.left + fieldMappingCopy.width, y: fieldMappingCopy.top }
  const rightMostMatchignWord = minBy(matchingWords, (wordBox) =>
    distSquared(topRight, wordBox.vertices[1]),
  )

  const leftMostPoint = leftMostMatchingWord?.vertices[0] ?? topLeft
  const rightMostPoint = rightMostMatchignWord?.vertices[1] ?? topRight

  fieldMappingCopy.left = leftMostPoint.x
  fieldMappingCopy.top = leftMostPoint.y
  fieldMappingCopy.width = rightMostPoint.x - leftMostPoint.x + MIDPOINT_THRESHOLD
  return fieldMappingCopy
}

export const constrainFieldMappingWithinRowBox = (
  rowBox: BoxDimension,
  fieldMapping: FieldMappingValue,
): FieldMappingValue => {
  if (fieldMapping.left != null) {
    fieldMapping.left = Math.max(fieldMapping.left, rowBox.left)
  }
  if (fieldMapping.top != null) {
    fieldMapping.top = Math.max(fieldMapping.top, rowBox.top)
  }
  if (fieldMapping.left != null && fieldMapping.width != null) {
    const fieldMappingRight = fieldMapping.left + fieldMapping.width
    const rowBoxRight = rowBox.left + rowBox.width
    if (fieldMappingRight > rowBoxRight) {
      fieldMapping.width = rowBoxRight - fieldMapping.left
    }
  }
  if (fieldMapping.top != null && fieldMapping.height) {
    const fieldMappingBot = fieldMapping.top + fieldMapping.height
    const rowBoxBot = rowBox.top + rowBox.height
    if (fieldMappingBot > rowBoxBot) {
      fieldMapping.height = rowBoxBot - fieldMapping.top
    }
  }
  return fieldMapping
}
