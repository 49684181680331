import { FunctionComponent, MutableRefObject, useEffect } from 'react'

import MagicGrid from '@src/components/magic-grid/index'
import useBoundingBox from '@src/hooks/useBoundingBox'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import SelectionBox from '@src/components/selection-box'
import { RootState } from '@src/utils/store'
import { selectActiveMagicGrid } from '@src/redux-features/document_editor/magic_grid'
import { setInitialGridDimension } from '@src/redux-features/document_editor'
import { selectActiveDocumentTable } from '@src/redux-features/document_editor/document_table'

type Props = {
  imageBoxRef: MutableRefObject<HTMLImageElement | null>
  fileViewerSize: { width: number | null; height: number | null }
  imageScale: number
}

const MagicGridContainer: FunctionComponent<Props> = ({
  imageBoxRef,
  fileViewerSize,
  imageScale,
  children,
}) => {
  const { end, boxDimension, isMouseDown, handleMouseDown, handleMouseUp, handleMouseMove } =
    useBoundingBox(imageBoxRef)
  const hasActiveDocumentTable = useSelector(
    (state: RootState) => selectActiveDocumentTable(state.documentEditor) != null,
  )
  const gridCreated = useSelector(
    (state: RootState) => selectActiveMagicGrid(state.documentEditor)?.dimension != null,
  )
  const gridReadOnly = useSelector((state: RootState) => state.documentEditor.tableEditModeEnabled)
  const dispatch = useDispatch()
  const imageWidth = imageBoxRef.current?.width || 0
  const imageHeight = imageBoxRef.current?.height || 0

  useEffect(() => {
    if (
      !isMouseDown &&
      !gridCreated &&
      boxDimension.width &&
      boxDimension.height &&
      !gridReadOnly
    ) {
      dispatch(setInitialGridDimension(boxDimension))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end])

  return hasActiveDocumentTable ? (
    <>
      {(gridCreated && (
        <>
          <MagicGrid
            imageBoxRef={imageBoxRef}
            fileViewerSize={fileViewerSize}
            imageScale={imageScale}
          />
          {children}
        </>
      )) ||
        (!gridReadOnly && (
          <Box
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            {isMouseDown && (
              <SelectionBox
                top={boxDimension.top * imageHeight}
                left={boxDimension.left * imageWidth}
                width={boxDimension.width * imageWidth}
                height={boxDimension.height * imageHeight}
              />
            )}
            {children}
          </Box>
        )) ||
        children}
    </>
  ) : (
    <>{children}</>
  )
}

export default MagicGridContainer
