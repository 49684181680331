import { gql } from '@apollo/client'

export const FIND_SOA_SHIPMENTS_FROM_LINE_ITEMS = gql`
  query findChainIoMatchesFromSoaLineItems(
    $jobId: UUID!
    $inputSoaLineItems: [InputSOALineItem!]!
  ) {
    findChainIoMatchesFromSoaLineItems(jobId: $jobId, inputSoaLineItems: $inputSoaLineItems) {
      chainIoShipments {
        id
        forwarderReference
      }
      chainIoConsolidations {
        id
        forwarderReference
      }
      chainIoCustomsDeclarations {
        id
        forwarderReference
      }
    }
  }
`

export const GET_JOB_EDOCS = gql`
  query getEDocumentsFromJob($jobId: String!) {
    eDocuments(jobId: $jobId) {
      id
      path
      eDocUrl
      filename
      dataTargetType {
        ... on EDocsDataTargetTypeEnum {
          value
          isFallback
        }
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
      }
      referenceNumber
      isPublished
      cargowiseFileType {
        id
        code
        description
      }
      filePage {
        id
        isInCw
        document {
          id
          documentType {
            id
            cargowiseFileType {
              id
              code
              description
            }
          }
        }
      }
    }
  }
`

export const GET_ALL_CW_CONFIGS = gql`
  query cargowiseConfigs {
    cargowiseConfigs {
      id
      name
      defaultTimezoneLocationId
      defaultTimezoneLocation {
        id
        code
        locodeName
        timezoneName
      }
      code
      countryCode
      enterpriseId
      serverId
      integrationId
      outboundPass
      dbPass
      dbUser
      dbName
      dbDirectConnect
      remoteDbHostname
      remoteDbPort
      pollDatabase
      proxyUrls
      connectorType
      forceFetch
      externApiCredential {
        id
        username
        workflow
      }
      company {
        id
        name
      }
      jobTemplates {
        edges {
          node {
            id
            name
            company {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const GET_CARGOWISE_FILE_TYPES = gql`
  query cargowiseFileTypes($companyId: UUID!) {
    cargowiseFileTypes(companyId: $companyId) {
      id
      code
    }
  }
`

export const GET_TRANSACTION_ASYNC_BATCH = gql`
  query transactionAsyncBatch($transactionAsyncBatchId: String!) {
    transactionAsyncBatch(transactionAsyncBatchId: $transactionAsyncBatchId) {
      id
      jobId
      numTasks
      numDone
      status
      transactionAsyncTasks {
        edges {
          node {
            id
            status
            invoiceNumber
            errorMessage
            processingLog
            cargowiseModule
          }
        }
      }
    }
  }
`

export const GET_CHECK_SHIPMENT_INFO_ASYNC_BATCH = gql`
  query checkShipmentInfoAsyncBatch($checkShipmentInfoAsyncBatchId: UUID!) {
    checkShipmentInfoAsyncBatch(checkShipmentInfoAsyncBatchId: $checkShipmentInfoAsyncBatchId) {
      id
      jobId
      numTasks
      numDone
      status
      errorMessage
      dateCreated
    }
  }
`

export const SOA_JOB_EXPECTED_CHARGES = gql`
  query soaJobExpectedCharges($checkShipmentInfoAsyncBatchId: UUID!) {
    soaJobExpectedCharges(checkShipmentInfoAsyncBatchId: $checkShipmentInfoAsyncBatchId) {
      invoiceNumber
      shipmentNumber
      matchCriteriaFindShipmentReconResultPairList {
        checkShipmentInfoMatchCriteria {
          id
          chainIoShipment {
            id
            forwarderReference
          }
          referenceNumber
          hblNumber
          orderNumber
          consolNumber
          mblNumber
          carrierBookingNumber
          containerNumber
          cargowiseModule
          errorMessage
        }
        successfulFindShipmentReconResult {
          id
          success
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          referenceNo
          hblNo
          mblNo
          carrierBookingNo
          containerNo
          consolNo
          orderNo
          isMaximalAmongDuplicates
          chainIoShipment {
            id
            forwarderReference
            dateModified
            houseBill
            masterBill
            carrierBookingNumber
            bookingReference
            containers {
              edges {
                node {
                  id
                  containerNumber
                }
              }
            }
            chainIoConsolidations {
              edges {
                node {
                  id
                  forwarderReference
                }
              }
            }
            orders {
              edges {
                node {
                  id
                  orderReference
                  sequence
                  orderNumberSplit
                  orderStatus
                  orderDate
                  orderBuyer {
                    id
                    sourcePartyId
                  }
                  orderSupplier {
                    id
                    sourcePartyId
                  }
                  orderLines {
                    edges {
                      node {
                        id
                        orderLineNumber
                        partNumber
                        description
                        containerNumber
                      }
                    }
                  }
                }
              }
            }
            additionalReferences {
              edges {
                node {
                  id
                  referenceType
                  referenceNumber
                }
              }
            }
            customFields {
              edges {
                node {
                  id
                  key
                  value
                }
              }
            }
            shipmentLegs {
              edges {
                node {
                  id
                  legSequenceNumber
                  transportMode
                  legType
                  vesselName
                  voyageNumber
                  ladingPort {
                    id
                    unlocode
                  }
                  arrivalPort {
                    id
                    unlocode
                  }
                  departureEstimated
                  arrivalEstimated
                  legCarrier {
                    id
                    name
                  }
                  bookingStatus
                }
              }
            }
            packingLines {
              edges {
                node {
                  id
                  packs
                  packsUom
                  containerNumber
                  weight
                  weightUom
                  volume
                  volumeUom
                  referenceNumber
                  importReferenceNumber
                  exportReferenceNumber
                  descriptionOfGoods
                  marksAndNumbers
                }
              }
            }
            localClient {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            shipper {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            consignee {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            supplier {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            importer {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            pickupTransportCompany {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            deliveryTransportCompany {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            pickupFrom {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            deliveryTo {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
          }
          chainIoConsolidation {
            id
            forwarderReference
            dateModified
            masterBill
            carrierBookingRef
            consolCosts {
              edges {
                node {
                  id
                  chargeCode
                  chargeDescription
                  amount
                  currency
                  creditorCode
                  invoiceNumber
                  taxRateCode
                  taxRate
                  isPosted
                }
              }
            }
            containers {
              edges {
                node {
                  id
                  containerNumber
                  containerCount
                  seal1
                  containerMode
                  typeCode
                  deliveryMode
                  sealedBy
                }
              }
            }
            shipmentLegs {
              edges {
                node {
                  id
                  legSequenceNumber
                  transportMode
                  legType
                  vesselName
                  voyageNumber
                  ladingPort {
                    id
                    unlocode
                  }
                  arrivalPort {
                    id
                    unlocode
                  }
                  departureEstimated
                  arrivalEstimated
                  legCarrier {
                    id
                    name
                  }
                  bookingStatus
                }
              }
            }
            shipments {
              edges {
                node {
                  id
                  forwarderReference
                  houseBill
                  orders {
                    edges {
                      node {
                        id
                        orderReference
                      }
                    }
                  }
                }
              }
            }
            carrier {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            creditor {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            coloadWith {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            receivingAgent {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            sendingAgent {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
          }
          chainIoCustomsDeclaration {
            id
            forwarderReference
            dateModified
            houseBill
            masterBill
            bookingReference
            containers {
              edges {
                node {
                  id
                  containerNumber
                }
              }
            }
            chainIoShipment {
              id
              carrierBookingNumber
              forwarderReference
              chainIoConsolidations {
                edges {
                  node {
                    id
                    forwarderReference
                  }
                }
              }
            }
            orders {
              edges {
                node {
                  id
                  orderReference
                }
              }
            }
          }
          findShipmentDuplicateReconResults {
            edges {
              node {
                id
                chainIoShipment {
                  id
                  forwarderReference
                  houseBill
                  masterBill
                  bookingReference
                  isCancelled
                  tmsId
                  containers {
                    edges {
                      node {
                        id
                        containerNumber
                      }
                    }
                  }
                  chainIoConsolidation {
                    id
                    forwarderReference
                    isCancelled
                    tmsId
                  }
                  orders {
                    edges {
                      node {
                        id
                        orderReference
                      }
                    }
                  }
                }
                chainIoConsolidation {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                chainIoCustomsDeclaration {
                  id
                  isCancelled
                  forwarderReference
                  tmsId
                }
              }
            }
          }
        }
      }
      shipmentStaffReconResults {
        id
        operationsStaffName
        operationsStaffCode
        salesStaffName
        salesStaffCode
      }
      metadataReconResults {
        id
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        key {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on MetadataReconResultKeyEnum {
            value
            isFallback
          }
        }
        invoiceValue
        expectedValue
      }
      expectedCharges {
        id
        chainIoSiLineId
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
        isPosted
        overseasSellAmount
        sellCurrency
      }
    }
  }
`

export const FIND_CONSOL_FROM_JOB = gql`
  query findConsolFromJob($jobId: UUID!) {
    consolWithMatchCriteria(jobId: $jobId) {
      chainIoConsol {
        id
        forwarderReference
      }
      consolNo
      mblNo
      containerNo
      carrierBookingNo
      hblNo
      shipmentNo
      orderNo
    }
  }
`

export const FIND_SHIPMENT_FROM_JOB = gql`
  query findShipmentFromJob($jobId: UUID!) {
    shipmentWithMatchCriteria(jobId: $jobId) {
      chainIoShipment {
        id
        forwarderReference
      }
      shipmentNo
      hblNo
      consolNo
      mblNo
      carrierBookingNo
      containerNo
      orderNo
    }
  }
`

export const FIND_CUSTOMS_DECLARATION_FROM_JOB = gql`
  query findCustomsDeclarationFromJob($jobId: UUID!) {
    customsDeclarationWithMatchCriteria(jobId: $jobId) {
      chainIoCustomsDeclaration {
        id
        forwarderReference
      }
      referenceNo
      hblNo
      consolNo
      mblNo
      carrierBookingNo
      containerNo
      orderNo
    }
  }
`

export const GET_PULL_EDOCS_BATCH = gql`
  query getPullEDocsBatch($batchPullId: UUID!, $totalPulled: Int!) {
    pullEDocsBatch(batchPullId: $batchPullId, totalPulled: $totalPulled) {
      batchPullId
      done
      successes
      failures
      exceptions
    }
  }
`

export const GET_PUSH_EDOCS_BATCH = gql`
  query getPushEDocsBatch($batchPushId: UUID!, $totalPushed: Int!) {
    pushEDocsBatch(batchPushId: $batchPushId, totalPushed: $totalPushed) {
      batchPushId
      done
      successes
      failures
      errorMessages
    }
  }
`

export const GET_RECENT_CARGOWISE_OUTBOUNDS = gql`
  query getRecentCargowiseOutbounds($inputCwOutboundFilter: InputCargowiseOutboundFilter!) {
    recentCargowiseOutbounds(inputCwOutboundFilter: $inputCwOutboundFilter) {
      id
      dateCreated
      integrationId
      signedViewUrl
      processed
    }
  }
`
export const SEARCH_SHIPMENTS = gql`
  query searchShipments($cargowiseModule: String!, $jobTemplateId: UUID!, $searchStr: String!) {
    searchShipments(
      cargowiseModule: $cargowiseModule
      jobTemplateId: $jobTemplateId
      searchStr: $searchStr
    ) {
      ... on ChainIOShipmentNode {
        id
        dateCreated
        tmsId
        forwarderReference
        houseBill
        masterBill
        chainIoConsolidation {
          id
          tmsId
          forwarderReference
          carrierBookingRef
          masterBill
        }
        containers {
          edges {
            node {
              id
              containerNumber
            }
          }
        }
        orders {
          edges {
            node {
              id
              orderReference
            }
          }
        }
      }
      ... on ChainIOCustomsDeclarationNode {
        id
        dateCreated
        forwarderReference
        chainIoShipment {
          id
          tmsId
          forwarderReference
          houseBill
          masterBill
          orders {
            edges {
              node {
                id
                orderReference
              }
            }
          }
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          chainIoConsolidation {
            id
            tmsId
            forwarderReference
            carrierBookingRef
            masterBill
          }
        }
      }
      ... on ChainIOConsolidationNode {
        id
        dateCreated
        tmsId
        forwarderReference
        carrierBookingRef
        masterBill
        containers {
          edges {
            node {
              id
              containerNumber
            }
          }
        }
        shipments {
          edges {
            node {
              id
              orders {
                edges {
                  node {
                    id
                    orderReference
                  }
                }
              }
              tmsId
              forwarderReference
              houseBill
              masterBill
            }
          }
        }
      }
    }
  }
`
