import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useEffect, useState } from 'react'
import {
  Theme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import Skeleton from '@material-ui/lab/Skeleton'
import theme from '@src/utils/theme'
import { useSnackbar } from 'notistack'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  CheckShipmentInfoAsyncStatus,
  Mutation,
  MutationBatchFetchShipmentInfoFromCwArgs,
  Query,
  QueryCheckShipmentInfoAsyncBatchArgs,
} from '@src/graphql/types'
import { BATCH_FETCH_SHIPMENT_INFO_FROM_CW } from '@src/graphql/mutations/recon'
import {
  GET_CHECK_SHIPMENT_INFO_ASYNC_BATCH,
  SOA_JOB_EXPECTED_CHARGES,
} from '@src/graphql/queries/cargowise'
import { parseDateString } from '@src/utils/date'
import {
  BATCH_CHECK_SHIPMENT_INFO_LINE_COLUMN_KEYS,
  fillExpectedChargesWithSecondaryKeys,
  SoaLineItemExpectedChargesWithSecondaryKeysAndCargowiseModules,
} from '@src/utils/line_items'
import CenteredCircularProgress from './centered-circular-progress/CenteredCircularProgress'
import SOACheckShipmentInfoDialog from './SOACheckShipmentInfoDialog'
import ProcessingLogDialog from './ProcessingLogsDialog'

const useStyles = makeStyles<Theme>({
  dialogFullScreen: {
    maxWidth: '90%',
    width: '90%',
    height: '90%',
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  table: {
    height: '80%',
    overflowY: 'scroll',
    marginBottom: theme.spacing(2),
  },
  row: {
    cursor: 'pointer',
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  secondaryKeys: {
    borderBottom: `1px dashed ${theme.palette.grey[600]}`,
    '&:last-child': {
      borderBottom: 'none',
    },
    whiteSpace: 'pre-wrap',
  },
})

// time duration (in ms) between polls
const CHECK_SHIPMENT_INFO_ASYNC_BATCH_INTERVAL_MS = 1000
// number of skeleton rows presented when loading
const N_TABLE_ROWS_LOADING = 6
// height of skeleton rows when polling
const ROW_HEIGHT = 80
// 1000 seconds is a dummy value to display in the very short interval between
// check shipment info async batch load and the useEffect settings the ETA. Also, avoids division by zero.
const DUMMY_ETA = 1000
const BATCH_CHECK_SHIPMENT_INFO_COLUMN_KEYS = [
  BATCH_CHECK_SHIPMENT_INFO_LINE_COLUMN_KEYS.INVOICE_NUMBER,
  BATCH_CHECK_SHIPMENT_INFO_LINE_COLUMN_KEYS.REFERENCE_NUMBER,
  BATCH_CHECK_SHIPMENT_INFO_LINE_COLUMN_KEYS.SECOND_KEYS,
  BATCH_CHECK_SHIPMENT_INFO_LINE_COLUMN_KEYS.CARGOWISE_MODULE,
]

type Props = {
  isOpen: boolean
  close: () => void
  jobId: string
}

const BatchCheckShipmentInfoDialog: FunctionComponent<Props> = ({ isOpen, close, jobId }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [checkShipmentInfoAsyncBatchId, setCheckShipmentInfoAsyncBatchId] = useState(
    null as null | string,
  )
  const [checkShipmentInfo, setCheckShipmentInfo] = useState(true)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [eta, setEta] = useState(DUMMY_ETA)
  const [elapsedTimeIntervalId, setElapsedTimeIntervalId] = useState<number | null>(null)
  const [chosenExpectedCharge, setChosenExpectedCharge] =
    useState<SoaLineItemExpectedChargesWithSecondaryKeysAndCargowiseModules | null>(null)
  const [processingLogs, setProcessingLogs] = useState(null as string | null)

  const [batchFetchShipmentInfoFromCw] = useMutation<
    Pick<Mutation, 'batchFetchShipmentInfoFromCw'>,
    MutationBatchFetchShipmentInfoFromCwArgs
  >(BATCH_FETCH_SHIPMENT_INFO_FROM_CW, {
    onError: (error) => {
      enqueueSnackbar(`SOA Batch Check Shipment Error: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const {
    data: checkShipmentInfoAsyncBatchData,
    startPolling: startCheckShipmentInfoAsyncBatchPolling,
    stopPolling: stopCheckShipmentInfoAsyncBatchPolling,
  } = useQuery<Pick<Query, 'checkShipmentInfoAsyncBatch'>, QueryCheckShipmentInfoAsyncBatchArgs>(
    GET_CHECK_SHIPMENT_INFO_ASYNC_BATCH,
    {
      skip: !checkShipmentInfoAsyncBatchId,
      variables: {
        checkShipmentInfoAsyncBatchId: checkShipmentInfoAsyncBatchId!,
      },
    },
  )

  const [
    getSoaJobExpectedCharges,
    { loading: soaJobExpectedChargesLoading, data: soaJobExpectedChargesData },
  ] = useLazyQuery<Pick<Query, 'soaJobExpectedCharges'>>(SOA_JOB_EXPECTED_CHARGES, {
    fetchPolicy: 'network-only',
  })
  const expectedCharges = soaJobExpectedChargesData?.soaJobExpectedCharges
  const expectedChargesWithSecondaryKeys = fillExpectedChargesWithSecondaryKeys(expectedCharges)

  useEffect(() => {
    if (
      checkShipmentInfoAsyncBatchData?.checkShipmentInfoAsyncBatch.status ===
      CheckShipmentInfoAsyncStatus.Pending
    ) {
      if (elapsedTimeIntervalId != null) {
        window.clearInterval(elapsedTimeIntervalId)
      }
      const msInSecond = 1000
      const etaRefreshIntervalMs = 10
      setElapsedTimeIntervalId(
        window.setInterval(
          () =>
            setElapsedTime(
              (new Date().getTime() -
                parseDateString(
                  checkShipmentInfoAsyncBatchData?.checkShipmentInfoAsyncBatch.dateCreated,
                ).getTime()) /
                msInSecond,
            ),
          etaRefreshIntervalMs,
        ),
      )
      // buffer to account for network overhead and latency
      const minEstimateSeconds = 10
      // it takes around 30 seconds to do 240 line items
      const secondsPerRow = 30 / 240
      setEta(
        minEstimateSeconds +
          secondsPerRow * checkShipmentInfoAsyncBatchData?.checkShipmentInfoAsyncBatch.numTasks,
      )
    }
    return () => {
      if (elapsedTimeIntervalId != null) {
        window.clearInterval(elapsedTimeIntervalId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkShipmentInfoAsyncBatchData?.checkShipmentInfoAsyncBatch.dateCreated,
    checkShipmentInfoAsyncBatchData?.checkShipmentInfoAsyncBatch.numTasks,
    checkShipmentInfoAsyncBatchData?.checkShipmentInfoAsyncBatch.status,
  ])

  useEffect(() => {
    const fetchAndSetBatchShipmentInfoFromCw = async (): Promise<void> => {
      const resp = await batchFetchShipmentInfoFromCw({
        variables: { jobId },
      })
      try {
        setCheckShipmentInfoAsyncBatchId(
          resp.data!.batchFetchShipmentInfoFromCw!.checkShipmentInfoAsyncBatchId,
        )
      } catch (error) {
        close()
      }
    }
    void fetchAndSetBatchShipmentInfoFromCw()
    // no deps for this since we only want it to run on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      checkShipmentInfoAsyncBatchData?.checkShipmentInfoAsyncBatch.status ===
      CheckShipmentInfoAsyncStatus.Done
    ) {
      setCheckShipmentInfo(false)
      stopCheckShipmentInfoAsyncBatchPolling()
      if (elapsedTimeIntervalId != null) {
        window.clearInterval(elapsedTimeIntervalId)
      }
      setElapsedTimeIntervalId(null)
      void getSoaJobExpectedCharges({ variables: { checkShipmentInfoAsyncBatchId } })
    } else if (
      checkShipmentInfoAsyncBatchData?.checkShipmentInfoAsyncBatch.status ===
      CheckShipmentInfoAsyncStatus.Error
    ) {
      setCheckShipmentInfo(false)
      stopCheckShipmentInfoAsyncBatchPolling()
      if (elapsedTimeIntervalId != null) {
        window.clearInterval(elapsedTimeIntervalId)
      }
      setElapsedTimeIntervalId(null)
      enqueueSnackbar(
        `SOA Batch Check Shipment Error: ${checkShipmentInfoAsyncBatchData?.checkShipmentInfoAsyncBatch.errorMessage}`,
        {
          variant: 'error',
        },
      )
    } else {
      startCheckShipmentInfoAsyncBatchPolling(CHECK_SHIPMENT_INFO_ASYNC_BATCH_INTERVAL_MS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkShipmentInfoAsyncBatchData?.checkShipmentInfoAsyncBatch.numTasks,
    checkShipmentInfoAsyncBatchData?.checkShipmentInfoAsyncBatch.status,
    enqueueSnackbar,
    startCheckShipmentInfoAsyncBatchPolling,
    stopCheckShipmentInfoAsyncBatchPolling,
  ])

  // use tanh(2x) to make progress bar more smooth
  const progressFraction = Math.tanh(2 * (Math.min(elapsedTime, eta) / eta))
  // estimated remaining time in seconds
  const estimatedRemainingTime = Math.max(Math.floor(eta * (1 - progressFraction)), 1)
  return (
    <>
      <Dialog classes={{ paper: classes.dialogFullScreen }} open={isOpen}>
        <Box className={classes.close}>
          <CloseIcon fontSize='large' onClick={close} data-testid='close-btn' />
        </Box>
        <DialogTitle>Results</DialogTitle>
        <DialogContent>
          {checkShipmentInfo || soaJobExpectedChargesLoading ? (
            <Box data-testid='loading'>
              {new Array(N_TABLE_ROWS_LOADING).fill('').map((_, idx) => (
                <Skeleton height={ROW_HEIGHT} key={idx} />
              ))}
              {checkShipmentInfoAsyncBatchData?.checkShipmentInfoAsyncBatch && (
                <>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography>Checking shipment info for SOA line items...</Typography>
                    <Typography>Time Elapsed: {Math.floor(elapsedTime)}s</Typography>
                    <Typography>Estimated Remaining Time: {estimatedRemainingTime}s</Typography>
                  </Box>
                  <LinearProgress variant='determinate' value={progressFraction * 100} />
                </>
              )}
            </Box>
          ) : (
            <TableContainer component={Paper} className={classes.table}>
              <Table aria-label='collapsible table'>
                <TableHead>
                  <TableRow>
                    {BATCH_CHECK_SHIPMENT_INFO_COLUMN_KEYS.map((key) => {
                      return (
                        <TableCell key={`batch-check-shipment-info-column-${key}`}>{key}</TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {soaJobExpectedChargesLoading || !expectedChargesWithSecondaryKeys ? (
                    <CenteredCircularProgress data-testid='loading' />
                  ) : (
                    expectedChargesWithSecondaryKeys.map((expectedCharge) => {
                      return (
                        <TableRow
                          className={classes.row}
                          onClick={() => {
                            if (expectedCharge?.expectedCharges?.length) {
                              setChosenExpectedCharge(expectedCharge)
                            } else {
                              setProcessingLogs(
                                expectedCharge?.matchCriteriaFindShipmentReconResultPairList?.[0]
                                  ?.checkShipmentInfoMatchCriteria?.errorMessage ?? '',
                              )
                            }
                          }}
                          key={`batch-check-shipment-info-row-${expectedCharge.invoiceNumber}-${expectedCharge.shipmentNumber}`}
                          data-testid='batch-check-shipment-info-row'
                        >
                          {BATCH_CHECK_SHIPMENT_INFO_COLUMN_KEYS.map((key) => {
                            if (key === BATCH_CHECK_SHIPMENT_INFO_LINE_COLUMN_KEYS.INVOICE_NUMBER) {
                              return <TableCell>{expectedCharge.invoiceNumber}</TableCell>
                            } else if (
                              key === BATCH_CHECK_SHIPMENT_INFO_LINE_COLUMN_KEYS.REFERENCE_NUMBER
                            ) {
                              return <TableCell>{expectedCharge.shipmentNumber}</TableCell>
                            } else if (
                              key === BATCH_CHECK_SHIPMENT_INFO_LINE_COLUMN_KEYS.SECOND_KEYS
                            ) {
                              return (
                                <TableCell>
                                  {expectedCharge.secondaryKeys.map((secondaryKeyStr, idx) => {
                                    return <TableRow key={`${idx}`}>{secondaryKeyStr}</TableRow>
                                  })}
                                </TableCell>
                              )
                            } else {
                              return (
                                <TableCell>
                                  {expectedCharge.cargowiseModules.map((cargowiseModule, idx) => {
                                    return <TableRow key={`${idx}`}>{cargowiseModule}</TableRow>
                                  })}
                                </TableCell>
                              )
                            }
                          })}
                        </TableRow>
                      )
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      </Dialog>
      {chosenExpectedCharge && (
        <SOACheckShipmentInfoDialog
          close={() => setChosenExpectedCharge(null)}
          expectedCharge={chosenExpectedCharge}
        />
      )}
      {processingLogs && (
        <ProcessingLogDialog close={() => setProcessingLogs(null)} processingLog={processingLogs} />
      )}
    </>
  )
}

export default BatchCheckShipmentInfoDialog
