import { FunctionComponent, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useApolloClient, useQuery } from '@apollo/client'
import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'

import Ingest from '@src/components/upload-files/Ingest'
import { DASHBOARD_FILES } from '@src/graphql/queries/file'
import { DELETE_FILE_PAGES } from '@src/graphql/mutations/file'
import { USER_COMPANIES } from '@src/graphql/queries/company'
import { FileNode, Query, QueryFileConnectionArgs } from '@src/graphql/types'
import { PAGE_PREVIEW_DIMENSIONS } from '@src/utils/app_constants'
import theme from '@src/utils/theme'
import { FileUploadStatusProp } from '@src/utils/types'
import { useHistory } from 'react-router-dom'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import {
  Checkbox,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import IngestListFileRow from './IngestListFileRow'
import { Pagination } from '@material-ui/lab'

const useStyles = makeStyles({
  tableActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing(2)}px 0`,
    padding: theme.spacing(2),
  },
  uploadButton: {
    width: PAGE_PREVIEW_DIMENSIONS.BUTTON_WIDTH,
  },
  processButton: {
    width: PAGE_PREVIEW_DIMENSIONS.BUTTON_WIDTH,
    background: theme.palette.success.main,
  },
  deleteButton: {
    width: PAGE_PREVIEW_DIMENSIONS.BUTTON_WIDTH,
    background: theme.palette.error.main,
  },
  uploadProgress: {
    backgroundColor: 'white',
  },
  uploadProgressTxt: {
    marginLeft: theme.spacing(1),
  },
})

const FILES_PER_PAGE = 20
type FormValues = {
  searchQuery: string
}

const FileIngestPage: FunctionComponent = () => {
  const classes = useStyles()
  const history = useHistory()
  const client = useApolloClient()
  const { enqueueSnackbar } = useSnackbar()
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedFiles, setSelectedFiles] = useState([] as FileNode[])
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false)
  const [isUploadSnackbarOpen, setIsUploadSnackbarOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const { data: companiesData, loading: companiesLoading } =
    useQuery<Pick<Query, 'companies'>>(USER_COMPANIES)
  const [companyId, setCompanyId] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const wrappedOnPageChange = (_event: unknown, value: number): void => setPageNumber(value)
  const {
    data: filesData,
    loading: filesLoading,
    refetch: refetchFiles,
  } = useQuery<Pick<Query, 'fileConnection'>, QueryFileConnectionArgs>(DASHBOARD_FILES, {
    fetchPolicy: 'network-only',

    variables: {
      page: pageNumber,
      query: searchQuery,
      companyId: companyId || null,
      status: 'Pending',
    },
  })
  const fileUploadedStatusToMessage: Record<string, string> = Object.freeze({
    error: 'Failed to upload files. Try again later.',
    success: 'Files uploaded successfully!',
  })
  const formMethods = useForm<FormValues>()
  const onSearch = (data: FormValues): void => {
    setSearchQuery(data.searchQuery)
  }

  const setFileUploadStatusWrapper = (fileUploadStatus: FileUploadStatusProp): void => {
    const status = fileUploadStatus as 'error' | 'success'
    enqueueSnackbar(fileUploadedStatusToMessage[status], { variant: status })
  }

  const closePopup = (): void => {
    setIsUploadPopupOpen(false)
  }

  const closeUploadSnackbar = (): void => {
    setIsUploadSnackbarOpen(false)
  }

  const processSelectedPages = async (): Promise<void> => {
    const ids = new Set(
      selectedFiles.flatMap((file) =>
        file.pages.edges.map((edge) => edge.node).map((item) => item.id),
      ),
    )
    const params = new URLSearchParams()
    for (const id of ids) {
      params.append('ids', id)
    }
    history.push({
      pathname: '/page_assoc',
      search: params.toString(),
    })
  }

  const deleteSelectedPages = async (): Promise<void> => {
    const ids = new Set(
      selectedFiles.flatMap((file) =>
        file.pages.edges.map((edge) => edge.node).map((item) => item.id),
      ),
    )
    try {
      await client.mutate({
        mutation: DELETE_FILE_PAGES,
        variables: { filePageIds: Array.from(ids) },
      })

      await refetchFiles()
    } catch (e) {
      enqueueSnackbar(
        'An error was encountered while deleting the selected pages. Please try again.',
        { variant: 'error' },
      )
    }
  }

  return (
    <div>
      <Paper className={classes.tableActionsContainer}>
        <Grid container alignItems='flex-end' spacing={4}>
          <Grid item>
            {companiesLoading || companiesData == null ? (
              <CenteredCircularProgress />
            ) : (
              <Select
                value={companyId}
                onChange={(evt) => setCompanyId(evt.target.value as string)}
                displayEmpty
                variant='outlined'
              >
                <MenuItem value=''>
                  <em>Organization</em>
                </MenuItem>
                {companiesData?.companies.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>

          <Grid item>
            <Button
              variant='contained'
              color='primary'
              className={classes.uploadButton}
              onClick={() => setIsUploadPopupOpen(true)}
            >
              Upload
            </Button>
          </Grid>

          <Grid item>
            <Button
              onClick={processSelectedPages}
              variant='contained'
              className={classes.processButton}
              disabled={selectedFiles.length === 0}
            >
              Process
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={deleteSelectedPages}
              variant='contained'
              className={classes.deleteButton}
              disabled={selectedFiles.length === 0}
            >
              Delete
            </Button>
          </Grid>
          <Grid item>
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSearch)}>
                <Controller
                  render={({ field: { value, onBlur, onChange } }) => (
                    <TextField
                      variant='outlined'
                      label='Search Query'
                      value={value}
                      onBlur={onBlur}
                      onChange={(event) => onChange(event.target.value)}
                    />
                  )}
                  name='searchQuery'
                  defaultValue={''}
                />
                <Button onClick={formMethods.handleSubmit(onSearch)}>
                  <SearchIcon />
                </Button>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Paper>

      <Snackbar
        ContentProps={{
          classes: {
            root: classes.uploadProgress,
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isUploadSnackbarOpen}
        onClose={closeUploadSnackbar}
        action={
          <IconButton size='small' aria-label='close' color='primary' onClick={closeUploadSnackbar}>
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      >
        <Alert
          severity='info'
          onClose={closeUploadSnackbar}
          icon={
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              <CircularProgress size={theme.typography.body1.fontSize} />
            </Box>
          }
        >
          Upload in progress...
        </Alert>
      </Snackbar>
      {filesLoading || !filesData ? (
        <CenteredCircularProgress />
      ) : (
        <>
          <Pagination
            count={Math.ceil(filesData.fileConnection.total / FILES_PER_PAGE)}
            page={pageNumber}
            onChange={wrappedOnPageChange}
          />
          <TableContainer>
            <TableHead>
              <TableRow>
                <TableCell>{/* the collapse/expand column */}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={filesData.fileConnection.items.length === selectedFiles.length}
                    indeterminate={
                      selectedFiles.length > 0 &&
                      filesData.fileConnection.items.length < selectedFiles.length
                    }
                    onChange={(_event, checked) => {
                      if (checked) {
                        setSelectedFiles([...filesData.fileConnection.items])
                      } else {
                        setSelectedFiles([])
                      }
                    }}
                  />
                </TableCell>
                <TableCell>File Name</TableCell>
                <TableCell>Date Sent</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>SLA Time Left (hh:mm)</TableCell>
                <TableCell>Sender Information</TableCell>
                <TableCell>Ingestion Identifier</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filesData.fileConnection.items.map((file) => (
                <IngestListFileRow
                  key={file.id}
                  file={file}
                  isSelected={!!selectedFiles.find((selectedFile) => selectedFile.id === file.id)}
                  onSelectChange={(isSelected) => {
                    if (isSelected) {
                      setSelectedFiles([file, ...selectedFiles])
                    } else {
                      setSelectedFiles(
                        selectedFiles.filter((selectedFile) => selectedFile.id !== file.id),
                      )
                    }
                  }}
                />
              ))}
            </TableBody>
          </TableContainer>
          <Pagination
            count={Math.ceil(filesData.fileConnection.total / FILES_PER_PAGE)}
            page={pageNumber}
            onChange={wrappedOnPageChange}
          />
        </>
      )}

      {isUploadPopupOpen && (
        <Ingest
          closePopup={closePopup}
          popupStatus={isUploadPopupOpen}
          setFileUploadStatus={setFileUploadStatusWrapper}
          setIsSnackBarOpen={() => {}}
          setIsUploadSnackbarOpen={setIsUploadSnackbarOpen}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
          onUploadSuccess={() => void refetchFiles()}
          getItems={async () => {}}
        />
      )}
    </div>
  )
}

export default FileIngestPage
