import { FunctionComponent } from 'react'
import { CHECK_SHIPMENT_METADATA_DATA_TABLE_COLUMNS } from '@src/utils/recon/ap_recon'

import { clsx } from 'clsx'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import theme from '@src/utils/theme'

type Props = {
  metadataTableData: (string | number)[][] | null
  title?: string
}

const useStyles = makeStyles<Theme>({
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.75, 1),
  },
  wrapWord: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
})

export const CheckShipmentMetadataTable: FunctionComponent<Props> = ({
  metadataTableData,
  title,
}) => {
  const classes = useStyles()
  return metadataTableData === null || metadataTableData.length === 0 ? (
    <></>
  ) : (
    <>
      {title && (
        <Typography variant='h6' gutterBottom>
          {title}
        </Typography>
      )}
      <TableContainer>
        <Table size='small'>
          <TableBody>
            <TableRow>
              {CHECK_SHIPMENT_METADATA_DATA_TABLE_COLUMNS.map((col, colIdx) => (
                <TableCell
                  key={`col-${colIdx}`}
                  className={clsx(classes.cell, classes.colCell)}
                  align='center'
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
            {metadataTableData.map((row, rowIdx) => (
              <TableRow key={rowIdx}>
                {row.map((data, colIdx) => {
                  return (
                    <TableCell
                      className={clsx(classes.cell, classes.wrapWord)}
                      component='th'
                      scope='row'
                      key={`cs-${title}-${rowIdx}-${colIdx}`}
                      data-testid={colIdx === 0 ? `field-name-${row[0]}` : `field-value-${row[0]}`}
                    >
                      {data}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
