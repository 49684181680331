import { ChangeEvent, FunctionComponent, useCallback, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { Controller, UseFieldArrayReturn, useFormContext } from 'react-hook-form'
import DeleteIcon from '@material-ui/icons/Delete'
import { Autocomplete } from '@material-ui/lab'
import DocumentTypeSelector from '@src/components/admin/job-template-fields/DocumentTypeSelector'
import TextField from '@material-ui/core/TextField'
import ControllerTextField from '@src/components/controller-text-field/ControllerTextField'
import {
  InputFieldGroup,
  InputFieldGroupType,
  Maybe,
  SearchableRecordOptions,
} from '@src/graphql/types'
import { inputFieldGroupTypeDisplayMap } from '@src/utils/admin/input_field_group'
import DeleteDialog from '@src/components/admin/job-template-fields/DeleteDialog'
import JobTemplateLineItemColumns from '@src/components/admin/job-template-fields/JobTemplateLineItemColumns'
import FieldDetails from '@src/components/admin/job-template-fields/FieldDetails'
import ControllerErrorText from '@src/components/controller-error-text/ControllerErrorText'
import { JobTemplateFormValues } from '@src/components/admin/job-template-editor/JobTemplateEditor'
import AutofillKeySelector from '@src/components/admin/job-template-fields/AutofillKeySelector'

type Props = {
  fieldGroup: InputFieldGroup
  index: number
  fieldArrayMethods:
    | UseFieldArrayReturn<JobTemplateFormValues, 'lineItemTypes'>
    | UseFieldArrayReturn<JobTemplateFormValues, 'metadataFieldGroups'>
  isLineItem: boolean
  searchableRecordOptions: Maybe<SearchableRecordOptions[]> | undefined
}

const useStyles = makeStyles({
  fieldGroupContainer: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
  labelCell: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    // empirically determined to fit the labels -- might want to use flexbox instead of tables
    // to automatically do this
    width: '150px',
  },
})

const JobTemplateFieldGroup: FunctionComponent<Props> = ({
  fieldGroup,
  index,
  fieldArrayMethods: { remove },
  isLineItem,
  searchableRecordOptions,
}) => {
  const classes = useStyles()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const { register, setValue } = useFormContext()
  const namePrefix = isLineItem ? 'lineItemTypes' : 'metadataFieldGroups'
  const renderFieldTypeSelector = useCallback(
    ({ field: { value, onBlur, onChange }, fieldState: { error, invalid } }) => {
      const onAutocompleteChange = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _: ChangeEvent<any>,
        val: InputFieldGroupType | null,
      ): void => {
        onChange(val)
        setValue(`${namePrefix}.${index}.fields.0.type`, val)
      }
      return (
        <>
          <Autocomplete
            value={value}
            options={Object.values(InputFieldGroupType).filter(
              (type) => type !== InputFieldGroupType.LineItem,
            )}
            onChange={onAutocompleteChange}
            onBlur={onBlur}
            size='small'
            getOptionLabel={(option: InputFieldGroupType) => inputFieldGroupTypeDisplayMap[option]}
            renderInput={(params) => <TextField {...params} error={invalid} variant='outlined' />}
          />
          <ControllerErrorText error={error} friendlyName='Field type' />
        </>
      )
    },
    [index, namePrefix, setValue],
  )
  return (
    <>
      <input
        {...register(`${namePrefix}.${index}.id`)}
        defaultValue={fieldGroup.id}
        type='hidden'
      />
      <Paper elevation={3} className={classes.fieldGroupContainer}>
        <Box display='flex'>
          <Box flex='1'>
            <ControllerTextField
              name={`${namePrefix}.${index}.name`}
              friendlyName='Name'
              defaultValue={fieldGroup.name}
              rules={{ required: true }}
            />
          </Box>
          {!isLineItem && (
            <Controller
              render={({ field: { ref, value, ...rest } }) => (
                <>
                  <FormControlLabel
                    control={<Checkbox inputRef={ref} checked={value} {...rest} />}
                    label='Required'
                  />
                </>
              )}
              name={`${namePrefix}.${index}.fields.0.required`}
              defaultValue={fieldGroup.fields[0].required}
            />
          )}
          <IconButton aria-label='delete' onClick={() => setIsDeleteDialogOpen(true)}>
            <DeleteIcon />
          </IconButton>
        </Box>
        {!isLineItem && (
          <>
            <input
              {...register(`${namePrefix}.${index}.fields.0.name`)}
              defaultValue={fieldGroup.name}
              type='hidden'
            />
            <input
              {...register(`${namePrefix}.${index}.fields.0.type`)}
              defaultValue={fieldGroup.fields[0].type || undefined}
              type='hidden'
            />
            <input
              {...register(`${namePrefix}.${index}.fields.0.id`)}
              defaultValue={fieldGroup.fields[0].id}
              type='hidden'
            />
            <input
              {...register(`${namePrefix}.${index}.autofillKey`)}
              defaultValue={fieldGroup.fields[0].autofillKey || undefined}
              type='hidden'
            />
          </>
        )}
        <TableContainer component={Paper} variant='outlined'>
          <Table size='small'>
            <TableBody>
              <TableRow>
                <TableCell className={classes.labelCell}>Doc type</TableCell>
                <TableCell>
                  <DocumentTypeSelector
                    index={index}
                    fieldGroup={fieldGroup}
                    isLineItem={isLineItem}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.labelCell}>Field type</TableCell>
                <TableCell>
                  {isLineItem ? (
                    <>
                      Line Item
                      <input
                        {...register(`lineItemTypes.${index}.type`)}
                        defaultValue={InputFieldGroupType.LineItem}
                        type='hidden'
                      />
                    </>
                  ) : (
                    <Controller
                      // we don't pass a ref to the controller since that causes react hook form to try to
                      // set the input value to the raw ID on refetch
                      render={renderFieldTypeSelector}
                      name={`${namePrefix}.${index}.type`}
                      rules={{ required: true }}
                      defaultValue={fieldGroup.type}
                    />
                  )}
                </TableCell>
              </TableRow>
              {isLineItem && (
                <TableRow>
                  <TableCell className={classes.labelCell}>Autofill key</TableCell>
                  <TableCell>
                    <AutofillKeySelector
                      autofillKey={fieldGroup.autofillKey}
                      fieldGroupIndex={index}
                      isLineItem={isLineItem}
                      isLineItemField={false}
                    />
                  </TableCell>
                </TableRow>
              )}
              {isLineItem && (
                <TableRow>
                  <TableCell className={classes.labelCell}>Export key</TableCell>
                  <TableCell>
                    <ControllerTextField
                      name={`${namePrefix}.${index}.exportKey`}
                      friendlyName='Export key'
                      defaultValue={fieldGroup.exportKey}
                    />
                  </TableCell>
                </TableRow>
              )}
              {!isLineItem && (
                <FieldDetails
                  field={fieldGroup.fields[0]}
                  fieldGroupIndex={index}
                  fieldIndex={0}
                  isLineItem={false}
                  isLineItemField={false}
                  searchableRecordOptions={searchableRecordOptions}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isLineItem && (
          <JobTemplateLineItemColumns
            fieldGroupIndex={index}
            fieldGroupId={fieldGroup.id}
            searchableRecordOptions={searchableRecordOptions}
          />
        )}
      </Paper>
      {isDeleteDialogOpen && (
        <DeleteDialog
          open={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onDelete={() => remove(index)}
          namePath={`${namePrefix}.${index}.name`}
        />
      )}
    </>
  )
}

export default JobTemplateFieldGroup
