import { FunctionComponent, useCallback } from 'react'
import { Fab } from '@material-ui/core'
import { useWatch } from 'react-hook-form'
import { v4 as uuid4 } from 'uuid'
import { useDialog } from 'muibox'
import { InputField, InputFieldGroup, InputFieldGroupType } from '@src/graphql/types'

type Props = {
  append: (newFieldGroup: Partial<InputFieldGroup>) => void
  isLineItem: boolean
}
const AddFieldButton: FunctionComponent<Props> = ({ append, isLineItem }) => {
  const documentTypeOptions = useWatch({ name: 'documentTypes' })
  const dialog = useDialog()
  const addNewFieldGroup = useCallback(async () => {
    if (!documentTypeOptions.length) {
      await dialog.alert({ message: 'Please add a document type first before adding a field.' })
      return
    }
    const newFieldGroup: Partial<InputFieldGroup> = {
      fields: [
        {
          id: uuid4(),
          name: '',
          required: false,
          type: undefined,
        } as Partial<InputField>,
      ] as InputField[],
      documentTypeId: documentTypeOptions[0].id,
      type: isLineItem ? InputFieldGroupType.LineItem : undefined,
      id: uuid4(),
      name: '',
    }
    append(newFieldGroup)
  }, [append, dialog, documentTypeOptions, isLineItem])
  return (
    <Fab variant='extended' onClick={addNewFieldGroup}>
      {isLineItem ? '+ Line Item Type' : '+ Field'}
    </Fab>
  )
}

export default AddFieldButton
