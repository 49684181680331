import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Skeleton from '@material-ui/lab/Skeleton'
import TabPanel from '@material-ui/lab/TabPanel'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import Typography from '@material-ui/core/Typography'
import Queue from '@material-ui/icons/Queue'
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { JOB_VIEWER_DIMENSIONS } from '@src/utils/app_constants'
import { formatFilePreviewName } from '@src/utils/file'
import theme from '@src/utils/theme'
import { batch, useDispatch, useSelector } from 'react-redux'
import {
  deleteFilePage as deleteFilePageFromCache,
  setActiveDocumentTable,
  addNewDocumentTable,
  updateCurrentJob,
  initializeDocumentEditor,
  setCurrentUserEmail,
} from '@src/redux-features/document_editor'
import {
  documentTableSelectors,
  selectActiveDocumentTable,
} from '@src/redux-features/document_editor/document_table'
import { IconButton } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import Upload from '@src/components/upload-files/Upload'
import { DELETE_FILE_PAGE, ROTATE_FILE_PAGE } from '@src/graphql/mutations/file'
import { ADD_DOCUMENT_TABLE } from '@src/graphql/mutations/document'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  FilePageNode,
  FilePageType,
  JobStatus,
  Mutation,
  MutationSaveJobAndDocumentTablesArgs,
  Query,
} from '@src/graphql/types'
import { GET_JOB_DATA_LITE } from '@src/graphql/queries/job'

import { clsx } from 'clsx'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { setCurrentFilePageId, setNextFilePageId } from '@src/redux-features/job_editor'
import { redirectIfWrongStatus } from '@src/utils/job'
import { RootState } from '@src/utils/store'
import { ShipmentFormContext } from '@src/contexts/shipment_form_context'
import CopyGridDialog from '@src/components/data-grid/CopyGridDialog'
import FileImageViewer from './FileImageViewer'
import JobInfo from './JobInfo'
import TaskInfo from './TaskInfo'
import DeletePageDialog from './DeletePageDialog'
import EDocsViewer from './EDocsViewer'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import FileSheetsViewer from './FileSheetsViewer'
import FilePageThumbnail from '../file-page-thumbnail/FilePageThumbnail'
import { JobDataContext } from '@src/contexts/job_data_context'
import { getSaveTable } from '@src/utils/data-grid'
import { SAVE_JOB_AND_DOCUMENT_TABLES } from '@src/graphql/mutations/job'
import { JobTemplateReconType } from '../../graphql/types'
import { LogEventType } from '@src/utils/observability/LogEventType'
import { selectFieldCoordinates } from '@src/redux-features/document_editor/field'
import { LineItem } from '@src/utils/line_items'
import { EntityState } from '@reduxjs/toolkit'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

const useStyles = makeStyles({
  container: {
    height: '100%',
  },
  imagePreviewContainer: {
    flex: 0,
    marginLeft: theme.spacing(2),
    outline: 'none',
    border: `${JOB_VIEWER_DIMENSIONS.IMAGE_BORDER_THICKNESS} solid transparent`,
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover, &:focus': {
      border: `${JOB_VIEWER_DIMENSIONS.IMAGE_BORDER_THICKNESS} solid ${theme.palette.common.black}`,
    },
  },
  selectedImage: {
    border: `${JOB_VIEWER_DIMENSIONS.IMAGE_BORDER_THICKNESS} solid ${theme.palette.common.black}`,
  },
  removeBox: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1,
  },
  imagePreview: {
    objectFit: 'contain',
    height: `min(${JOB_VIEWER_DIMENSIONS.HEIGHT}, ${JOB_VIEWER_DIMENSIONS.MAX_HEIGHT})`,
    width: `min(${JOB_VIEWER_DIMENSIONS.WIDTH}, ${JOB_VIEWER_DIMENSIONS.MAX_WIDTH})`,
  },
  iconPreview: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: `min(${JOB_VIEWER_DIMENSIONS.WIDTH}, ${JOB_VIEWER_DIMENSIONS.MAX_WIDTH})`,
    height: `min(${JOB_VIEWER_DIMENSIONS.HEIGHT}, ${JOB_VIEWER_DIMENSIONS.MAX_HEIGHT})`,
  },
  imageLabel: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(2)}px`,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '40px',
    backgroundColor: alpha(theme.palette.common.black, 0.5),
    color: theme.palette.common.white,
  },
  uploadProgress: {
    backgroundColor: 'white',
  },
  imageSkeleton: {
    margin: 20,
    height: JOB_VIEWER_DIMENSIONS.HEIGHT,
    width: JOB_VIEWER_DIMENSIONS.WIDTH,
    maxWidth: JOB_VIEWER_DIMENSIONS.MAX_WIDTH,
    maxHeight: JOB_VIEWER_DIMENSIONS.MAX_HEIGHT,
  },
  scrollableTabPanel: {
    maxHeight: '100%',
    overflow: 'scroll',
  },
  uploadIconButton: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
  previewContainer: {
    maxWidth: '90%',
    overflowX: 'scroll',
  },
  uploadIcon: {
    transform: 'scale(1.6)',
  },
  loadingSpinner: {
    background: 'rgba(0, 0, 0, 0.5)',
    height: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
  documentTabPanel: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'auto',
    padding: 0,
  },
})

enum TabValues {
  INSTRUCTIONS = 'Instructions',
  DOCUMENT = 'Document',
  JOB_INFO = 'Job Info',
  EDOCS = 'EDocs',
  TASK_INFO = 'Task Info',
}

type Props = {
  jobId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFilePageChange?: (filePage?: FilePageNode) => any
  onLoadComplete?: (hasFilePage?: boolean) => void
}

const JobViewer: FunctionComponent<Props> = ({ jobId, onFilePageChange, onLoadComplete }) => {
  const history = useHistory()
  const client = useApolloClient()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [activeTop, setActiveTop] = useState(0)
  const [hasFocusedBox, setHasFocusedBox] = useState(false)

  const {
    job: jobToUpdate,
    jobLoading: filePagesLoading,
    jobRefetch: refetchFilePages,
    filePages,
  } = useContext(JobDataContext)
  const {
    data: jobDataLite,
    refetch: refetchJobDataLite,
    loading: isLoadingJobDataLite,
  } = useQuery<Pick<Query, 'job'>>(GET_JOB_DATA_LITE, {
    variables: { id: jobId },
  })

  const [shownTab, setShownTab] = useState(TabValues.DOCUMENT)
  const { saveAndRefetchDocumentEditorJob, updateDocumentMapping } = useContext(ShipmentFormContext)
  const [isRotatingFilePage, setIsRotatingFilePage] = useState(false)
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [copyGridDialogOpen, setCopyGridDialogOpen] = useState(false)
  const [copyToOtherTable, setCopyToOtherTable] = useState(true)
  const [copyRowsToDocuments, setCopyRowsToDocuments] = useState(false)
  const [filePageIdToDelete, setFilePageIdToDelete] = useState(null as string | null)
  const [deleteFilePage, { loading: deleteFilePageLoading, error: deleteFilePageError }] =
    useMutation(DELETE_FILE_PAGE)
  const [addDocumentTable, { error: addDocumentTableError }] = useMutation(ADD_DOCUMENT_TABLE, {
    onCompleted: refetchJobDataLite,
  })
  const currentFilePageId = useSelector((state: RootState) => state.jobEditor.currentFilePageId)
  const documentTables = useSelector((state: RootState) =>
    documentTableSelectors.selectAll(state.documentEditor),
  )
  const activeDocumentTable = useSelector((state: RootState) =>
    selectActiveDocumentTable(state.documentEditor),
  )
  const tableEditModeEnabled = useSelector(
    (state: RootState) => state.documentEditor.tableEditModeEnabled,
  )
  const hasActiveDocumentTable = activeDocumentTable !== null

  const { user } = useAuth0()
  const { logEvent } = useEventLogger()

  const currentFilePage = currentFilePageId
    ? filePages?.find((filePage) => filePage.id === currentFilePageId)
    : filePages[0]
  const dispatch = useDispatch()

  if (user && typeof user.email === 'string') {
    dispatch(setCurrentUserEmail(user.email))
  }

  useEffect(() => {
    onFilePageChange?.(currentFilePage)
  }, [currentFilePage, onFilePageChange])

  useEffect(() => {
    if (!filePagesLoading) {
      onLoadComplete?.(!!filePages.length)
    }
    // only want to react to load finish
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filePagesLoading])
  useEffect(() => {
    if (!isLoadingJobDataLite && jobDataLite) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      redirectIfWrongStatus(history, jobDataLite.job!)
    }
  }, [jobDataLite, isLoadingJobDataLite, history])

  const enableLineItemsRowOrderPriority = useFeatureIsOn('line-items-row-order-priority')

  useEffect(() => {
    // want to reinitialize with the newly updated job after an upload
    if (!isUploading && jobToUpdate && currentFilePageId) {
      dispatch(
        initializeDocumentEditor(jobToUpdate, currentFilePageId, enableLineItemsRowOrderPriority),
      )
    }
  }, [isUploading, jobToUpdate, enableLineItemsRowOrderPriority])

  const job = jobDataLite?.job
  const isSoa = job ? job.jobTemplate.reconType === JobTemplateReconType.Soa : false

  const rotateImage = async (angle: number, filePageId: string): Promise<void> => {
    setIsRotatingFilePage(true)

    await saveAndRefetchDocumentEditorJob()
    const { errors } = await client.mutate({
      mutation: ROTATE_FILE_PAGE,
      variables: { filePageId, angle },
    })

    if (errors) {
      enqueueSnackbar(`Received error while rotating file page: ${errors[1]}`, {
        variant: 'error',
      })
    }

    setIsRotatingFilePage(false)
  }

  const [saveJobAndDocumentTables] = useMutation<
    Pick<Mutation, 'saveJobAndDocumentTables'>,
    MutationSaveJobAndDocumentTablesArgs
  >(SAVE_JOB_AND_DOCUMENT_TABLES)

  const columns = useSelector((state: RootState) =>
    state.documentEditor.jobTableActive && state.documentEditor.jobTableState
      ? state.documentEditor.jobTableState!.columns
      : activeDocumentTable
      ? state.documentEditor.lineItemsTableMap[activeDocumentTable!.id].columns
      : [],
  )

  const { saveTable } = useMemo(
    () => getSaveTable(null, null, columns, saveJobAndDocumentTables, enqueueSnackbar),
    [columns, saveJobAndDocumentTables, enqueueSnackbar],
  )

  const goToPage = async (id: string): Promise<void> => {
    updateDocumentMapping(id)
    if (hasActiveDocumentTable && tableEditModeEnabled) {
      dispatch(setNextFilePageId(id))
    } else {
      dispatch(setCurrentFilePageId(id))
      if (hasActiveDocumentTable) {
        // we only do this for SOA right now because of this ticket's scope:
        // parent ticket: https://expedock.atlassian.net/browse/PD-868
        // sub task: https://expedock.atlassian.net/browse/PD-926
        if (isSoa) {
          void saveTable()
        }
        // best-effort switch to table of same type on the page we're swapping to, or failing that
        // first table, or failing THAT, back to metadata.
        const nextPageDocTables = documentTables.filter((docTable) => docTable.filePageId === id)
        const sameTypeDocTableId =
          nextPageDocTables.find(
            (docTable) => docTable!.fieldGroup!.id === activeDocumentTable!.fieldGroup!.id,
          )?.id ?? null
        if (sameTypeDocTableId) {
          dispatch(setActiveDocumentTable(sameTypeDocTableId))
        } else {
          const newFilePage = filePages?.find((filePage) => filePage.id === id)
          // something is very wrong if it's not here, but don't crash the app
          if (!newFilePage) {
            return
          }
          const createMessage = `Creating new document table for selected page`
          enqueueSnackbar(createMessage)
          const newDocTableResp = await addDocumentTable({
            variables: {
              documentId: newFilePage!.document!.id,
              fieldGroupId: activeDocumentTable!.fieldGroup!.id,
            },
          })
          if (addDocumentTableError) {
            const errorMessage = `Error creating new document table for selected page: ${formatMaybeApolloError(
              addDocumentTableError,
            )}`
            enqueueSnackbar(errorMessage, { variant: 'error' })
          } else {
            const errorMessage = `Successfully created new document table for selected page`
            enqueueSnackbar(errorMessage, { variant: 'success' })
          }
          const newDocTable = newDocTableResp!.data!.addDocumentTable!.documentTable
          const prevTableId = newDocTableResp!.data!.addDocumentTable!.prevTableId
          const newDocTableId = newDocTableResp!.data!.addDocumentTable!.documentTable!.id
          const newFilePageIdx = filePages!.findIndex((filePage) => filePage.id === id)
          dispatch(
            addNewDocumentTable({
              docTable: newDocTable,
              prevTableId,
              newFilePage,
              newFilePageIdx,
              job: jobToUpdate,
              enableLineItemsRowOrderPriority: enableLineItemsRowOrderPriority,
            }),
          )
          dispatch(updateCurrentJob({ job: jobToUpdate }))
          dispatch(setActiveDocumentTable(newDocTableId))
        }
      }
    }
  }

  const changeTab = (newTab: TabValues): void => {
    setShownTab(newTab)
  }

  const jobInReadOnlyState =
    job?.status === JobStatus.Confirmation || job?.status === JobStatus.Done

  const handleDeletePage = async (): Promise<void> => {
    const idxOfPageToBeDeleted = filePages.findIndex((page) => page.id === filePageIdToDelete)
    const idxOfNextPage = idxOfPageToBeDeleted === 0 ? 1 : idxOfPageToBeDeleted - 1
    const nextPage = filePages[idxOfNextPage]

    let success = false
    let errorMessage = ''
    try {
      await deleteFilePage({
        variables: { jobId, filePageId: filePageIdToDelete },
      })
      if (deleteFilePageError) {
        throw deleteFilePageError
      }
      batch(() => {
        dispatch(setCurrentFilePageId(nextPage?.id))
        dispatch(deleteFilePageFromCache(filePageIdToDelete!))
        dispatch(
          setActiveDocumentTable(
            hasActiveDocumentTable
              ? nextPage?.document?.documentTables?.edges[0]?.node?.id || null
              : null,
          ),
        )
      })
      success = true
      setIsDeleteDialogOpen(false)
      await refetchFilePages()
    } catch (error) {
      setIsDeleteDialogOpen(false)
      errorMessage = `Error deleting file page: ${formatMaybeApolloError(error)}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
    } finally {
      void logEvent(LogEventType.FILE_PAGES_DELETE, {
        page_id: filePageIdToDelete,
        success: success,
        errorMessage: errorMessage,
      })
    }
  }

  const activeFieldKey = useSelector((state: RootState) => state.documentEditor.activeFieldKey)
  const metadataActiveDimension = useSelector(
    (state: RootState) =>
      (activeFieldKey && selectFieldCoordinates(state.documentEditor)?.[activeFieldKey]) ||
      undefined,
  )
  const highlightedFieldBoxIds = useSelector(
    (state: RootState) => state.documentEditor.boxn.highlightedFieldBoxIds,
  )
  const lineItemActiveDimension = useSelector((state: RootState) => {
    if (highlightedFieldBoxIds && highlightedFieldBoxIds.length === 1 && activeDocumentTable) {
      const targetId = highlightedFieldBoxIds[0]

      const lineItemsTable = state.documentEditor.lineItemsTableMap[activeDocumentTable!.id]
      if (lineItemsTable) {
        const lineItems = lineItemsTable.lineItems as EntityState<LineItem>
        if (lineItems && lineItems.entities) {
          const definedEntities = Object.values(lineItems.entities).filter(Boolean) as LineItem[]

          for (const entity of definedEntities) {
            const fieldMappingValues = Object.values(entity.fieldMapping)
            for (const item of fieldMappingValues) {
              if (item.id === targetId) {
                return item.top
              }
            }
          }
        }
      }
    }
    return null
  })

  useEffect(() => {
    // Sets the activeTop to the top of the focused cell's bounding box whether it be
    // a metadata field or a line item field.
    // Additionally, sets the boolean for whether or not the focused cell is in the document.
    // Thus, change the yPosition if and only if the focused cell's bounding box is in the document.
    if (metadataActiveDimension) {
      setActiveTop(metadataActiveDimension.top)
      setHasFocusedBox(true)
    } else if (lineItemActiveDimension) {
      setActiveTop(lineItemActiveDimension)
      setHasFocusedBox(true)
    } else {
      setHasFocusedBox(false)
    }
  }, [lineItemActiveDimension, activeFieldKey])

  return (
    <div className={classes.container}>
      <Box
        height='100%'
        overflow='hidden'
        display='flex'
        flexDirection='column'
        alignItems='stretch'
      >
        <TabContext value={shownTab}>
          <TabList variant='fullWidth' onChange={(_event, newValue) => changeTab(newValue)}>
            <Tab label={TabValues.INSTRUCTIONS} value={TabValues.INSTRUCTIONS} />
            <Tab
              label={TabValues.DOCUMENT}
              value={TabValues.DOCUMENT}
              data-testid={'job-document-tab'}
            />
            <Tab
              label={TabValues.JOB_INFO}
              value={TabValues.JOB_INFO}
              data-testid={'job-info-tab'}
            />
            <Tab label={TabValues.EDOCS} value={TabValues.EDOCS} data-testid='edocs-tab' />
            <Tab
              label={TabValues.TASK_INFO}
              value={TabValues.TASK_INFO}
              data-testid='task-info-tab'
            />
          </TabList>
          <TabPanel value={TabValues.INSTRUCTIONS}>
            <Typography variant='h3'>Job Description</Typography>
            {job?.description ?? ''}
          </TabPanel>
          <TabPanel
            value={TabValues.DOCUMENT}
            className={clsx({
              [classes.documentTabPanel]: shownTab === TabValues.DOCUMENT,
            })}
          >
            <Box display='flex' m={1}>
              <Box className={classes.previewContainer}>
                {filePages && filePages.length > 0 && (
                  <Box display='flex' m={1}>
                    {filePagesLoading
                      ? (filePages ?? [1]).map((_, idx) => (
                          <Skeleton
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${idx}`}
                            variant='rect'
                            className={classes.imageSkeleton}
                          />
                        ))
                      : (filePages ?? []).map((filePage: FilePageNode) => {
                          const previewName = formatFilePreviewName(
                            filePage?.file?.filename || '',
                            filePage?.pageNumber || 0,
                          )
                          return (
                            <Box key={`${filePage.id}-${previewName}`} position='relative'>
                              {!jobInReadOnlyState && (
                                <Box className={classes.removeBox}>
                                  <IconButton
                                    disabled={deleteFilePageLoading}
                                    onClick={() => {
                                      setIsDeleteDialogOpen(true)
                                      setFilePageIdToDelete(filePage.id)
                                    }}
                                  >
                                    <RemoveCircleRoundedIcon fontSize='small' color='error' />
                                  </IconButton>
                                </Box>
                              )}
                              <Grid
                                className={clsx(classes.imagePreviewContainer, {
                                  [classes.selectedImage]: filePage.id === currentFilePage?.id,
                                })}
                                onClick={() => {
                                  if (filePage.id !== currentFilePage?.id) {
                                    void logEvent(LogEventType.FILE_PAGES_VIEW, {
                                      page_id: filePage.id,
                                    })
                                    void goToPage(filePage.id)
                                  }
                                }}
                                item
                                xs
                              >
                                <Box position='relative' width='100%' height='100%'>
                                  <Box className={classes.imagePreview}>
                                    <FilePageThumbnail
                                      type={filePage.type}
                                      file={filePage.imageUrl!}
                                      fileName={previewName}
                                    />
                                  </Box>
                                  <Box className={classes.imageLabel}>
                                    <Typography color='inherit' noWrap>
                                      {previewName}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Box>
                          )
                        })}
                  </Box>
                )}
              </Box>
              <Box className={classes.imagePreview}>
                {!jobInReadOnlyState && (
                  <IconButton
                    data-testid='upload-icon-btn'
                    className={classes.uploadIconButton}
                    onClick={() => setIsUploadPopupOpen(true)}
                    disabled={jobInReadOnlyState}
                  >
                    <Queue className={classes.uploadIcon} fontSize='large' />
                  </IconButton>
                )}
              </Box>
            </Box>
            {filePagesLoading && <CenteredCircularProgress />}
            {currentFilePage?.type === FilePageType.ImagePdf && (
              <Box position='relative'>
                {isRotatingFilePage && (
                  <div className={classes.loadingSpinner}>
                    <CenteredCircularProgress />
                  </div>
                )}
                <FileImageViewer
                  filePage={currentFilePage}
                  readOnly={jobInReadOnlyState}
                  isRotatingFilePage={isRotatingFilePage}
                  rotateImage={rotateImage}
                  setCopyGridDialogOpen={setCopyGridDialogOpen}
                  setCopyToOtherTable={setCopyToOtherTable}
                  setCopyRowsToDocuments={setCopyRowsToDocuments}
                  showTableMenu={hasActiveDocumentTable}
                  activeTop={activeTop}
                  hasFocusedBox={hasFocusedBox}
                />
              </Box>
            )}
            {currentFilePage?.type === FilePageType.Excel && (
              <FileSheetsViewer filePage={currentFilePage} />
            )}
            {currentFilePage?.type === FilePageType.Other && (
              <DescriptionOutlinedIcon className={classes.iconPreview} />
            )}
          </TabPanel>
          <TabPanel className={classes.scrollableTabPanel} value={TabValues.EDOCS}>
            {filePagesLoading || !job ? (
              <CenteredCircularProgress />
            ) : (
              <EDocsViewer job={job} filePages={filePages} />
            )}
          </TabPanel>
          <TabPanel className={classes.scrollableTabPanel} value={TabValues.JOB_INFO}>
            {(job && <JobInfo job={job} refetchJobData={refetchJobDataLite} />) || (
              <CenteredCircularProgress />
            )}
          </TabPanel>
          <TabPanel className={classes.scrollableTabPanel} value={TabValues.TASK_INFO}>
            {(job && <TaskInfo task={job?.task ?? null} />) || <CenteredCircularProgress />}
          </TabPanel>
        </TabContext>
      </Box>
      {job != null && (
        <Upload
          job={job}
          setIsOpen={setIsUploadPopupOpen}
          isOpen={isUploadPopupOpen}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
        />
      )}
      <DeletePageDialog
        isOpen={isDeleteDialogOpen}
        deleteFilePageLoading={deleteFilePageLoading}
        handleClose={() => setIsDeleteDialogOpen(false)}
        onDeleteHandler={handleDeletePage}
      />
      {copyGridDialogOpen && hasActiveDocumentTable && (
        <CopyGridDialog
          open={copyGridDialogOpen}
          copyRows={copyRowsToDocuments}
          copyToOther={copyToOtherTable}
          handleCloseDialog={() => setCopyGridDialogOpen(false)}
          activeDocumentTableId={activeDocumentTable!.id}
        />
      )}
    </div>
  )
}

export default JobViewer
