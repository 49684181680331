import {
  DocumentFieldGroupNode,
  FilePageNodeEdge,
  JobNode,
  JobStatus,
  Maybe,
} from '@src/graphql/types'
import { History } from 'history'

// Get list of fields for all documents in a job
export function getFieldGroupsFromJob(job: JobNode): DocumentFieldGroupNode[] {
  const fieldGroups = [] as DocumentFieldGroupNode[]
  const documents =
    job?.filePages?.edges?.map((filePage: Maybe<FilePageNodeEdge>) => filePage?.node?.document) ??
    []
  for (const document of documents) {
    for (const docFieldGroup of document?.documentFieldGroups?.edges || []) {
      if (docFieldGroup?.node) {
        fieldGroups.push(docFieldGroup.node)
      }
    }
  }
  return fieldGroups
}

/**
 * Given a job, get a mapping of field group keys to existing
 * field value for metafields and a list of mappings for line items.
 * Merges document fields with the same key into a single mapping.
 * @param job
 * @param splitTables Set to true to split tables by document table, otherwise all the rows of a table type are merged
 * @param useAutofillKey Set to true to use field autofill keys as keys to the mapping
 */
export const getFieldValueMap = (
  job: JobNode,
  splitTables = false,
  useAutofillKey = false,
): Record<string, string | Record<string, string>[]> => {
  const fieldValueMap: Record<string, string | Record<string, string>[]> = {}

  for (const filePageEdge of job.filePages!.edges) {
    const filePageNode = filePageEdge!.node
    for (const documentFieldGroupEdge of filePageNode!.document!.documentFieldGroups!.edges) {
      const documentFieldGroupNode = documentFieldGroupEdge!.node!
      const fieldGroupNode = documentFieldGroupNode.fieldGroup!
      const documentFieldEdges = documentFieldGroupNode.documentFields!.edges
      if (fieldGroupNode.repeatable) {
        const lineItem: Record<string, string> = {}
        for (const documentFieldEdge of documentFieldEdges) {
          const documentFieldNode = documentFieldEdge!.node!
          const key = useAutofillKey
            ? documentFieldNode.field!.autofillKey
            : documentFieldNode.field!.key
          const { value } = documentFieldNode
          if (!(key in fieldValueMap) && value !== '') {
            lineItem[key] = value
          }
        }
        const fieldGroupKey = useAutofillKey ? fieldGroupNode.autofillKey : fieldGroupNode.key
        if (splitTables) {
          const tableKey = `${fieldGroupKey}: ${documentFieldGroupNode.documentTable!.id}`
          if (tableKey in fieldValueMap) {
            const lineItems = fieldValueMap[tableKey] as Record<string, string>[]
            lineItems.push(lineItem)
          } else {
            fieldValueMap[tableKey] = [lineItem]
          }
        } else if (fieldGroupKey in fieldValueMap) {
          const lineItems = fieldValueMap[fieldGroupKey] as Record<string, string>[]
          lineItems.push(lineItem)
        } else {
          fieldValueMap[fieldGroupKey] = [lineItem]
        }
      } else {
        const documentFieldNode = documentFieldEdges[0]!.node!
        const key = useAutofillKey
          ? documentFieldNode.field!.autofillKey
          : documentFieldNode.field!.key
        const { value } = documentFieldNode
        if (!(key in fieldValueMap) && value !== '') {
          fieldValueMap[key] = value
        }
      }
    }
  }
  return fieldValueMap
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const redirectIfWrongStatus = async (_history: History, _job: JobNode): Promise<void> => {
  // temporarily disabled since this does a redirect loop in prod
}

export const convertJobStatusToUrlFormat = (status: JobStatus): string =>
  status.replace('_', '').toLowerCase()
