import { FunctionComponent, useCallback } from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'

import { MAGIC_GRID_DIMENSIONS } from '@src/utils/app_constants'
import theme from '@src/utils/theme'
import { useDispatch, useSelector } from 'react-redux'
import { moveGridRow } from '@src/redux-features/document_editor'
import { rowSelectors, selectActiveMagicGrid } from '@src/redux-features/document_editor/magic_grid'
import { RootState } from '@src/utils/store'

const useStyles = makeStyles({
  rowRulerWrapper: {
    position: 'absolute',
    width: '100%',
    zIndex: MAGIC_GRID_DIMENSIONS.RULER_Z_INDEX,
  },
  rowRuler: {
    cursor: 'row-resize',
    width: '100%',
    height: '1px',
    borderTopWidth: 'thin',
    border: `0 dashed ${theme.palette.primary.dark}`,
    // this is mainly used for easier dragging
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -theme.spacing(0.5),
      left: 0,
      width: '100%',
      height: theme.spacing(1),
    },
    '&:hover': {
      border: `${MAGIC_GRID_DIMENSIONS.RULER_THICKNESS}px solid ${theme.palette.primary.dark}`,
    },
  },
})

type Props = {
  rowId: string
  imageHeight: number
}

const RowRuler: FunctionComponent<Props> = ({ rowId, imageHeight }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  // row top is relative to image, but we want relative to grid, so we offset it and scale
  // it appropriately
  const rowTop = useSelector(
    (state: RootState) => rowSelectors.selectById(state.documentEditor, rowId)?.dimension.top,
  ) as number
  const gridTop = useSelector(
    (state: RootState) => selectActiveMagicGrid(state.documentEditor)!.dimension!.top,
  )
  const y = (rowTop - gridTop) * imageHeight

  const handleRowMove = useCallback(
    (_evt: DraggableEvent, ui: DraggableData): void => {
      dispatch(moveGridRow(rowId, ui.y / imageHeight + gridTop))
    },
    [dispatch, rowId, gridTop, imageHeight],
  )

  return (
    <Draggable axis='y' bounds='parent' position={{ x: 0, y }} onStop={handleRowMove}>
      <Box className={classes.rowRulerWrapper}>
        <div className={classes.rowRuler} />
      </Box>
    </Draggable>
  )
}

export default RowRuler
