import { formatMaybeApolloError } from '@src/utils/errors'
/* eslint-disable @typescript-eslint/no-var-requires */
import { FunctionComponent, RefObject, useMemo, useState } from 'react'
import { useSnackbar } from 'notistack'
import { Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { HotTable } from '@handsontable/react'
import {
  ApReconAutofillKey,
  CwTargetModule,
  InputDocumentTable,
  JobTemplateReconType,
  MatchingCriteriaType,
  Mutation,
  MutationExportJobToCwArgs,
} from '@src/graphql/types'
import { useMutation } from '@apollo/client'
import { getLumpsumInputTable, getLumpsumTable } from '@src/utils/lumpsum'
import CenteredCircularProgress from './centered-circular-progress/CenteredCircularProgress'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { POST_TO_CARGOWISE } from '@src/graphql/mutations/cargowise'
import { JOB_NOTES } from '@src/graphql/queries/note'
import { useDialog } from 'muibox'
import {
  convertCwTargetModuleDisplayTextToCwTargetModule,
  isCustomsDeclarationFromReconResultsHelper,
} from '@src/utils/recon/ap_recon'
import { CargowiseOpsType } from '@src/utils/cargowise/types'
import { zip } from 'lodash'
import ReadOnlyDocumentHotTable from './read-only-document-hot-table/ReadOnlyDocumentHotTable'
import useReconData from '@src/hooks/useReconData'
import { TransportManagementSystem } from '@src/utils/observability/TransportManagementSystem'
import { LogEventType } from '@src/utils/observability/LogEventType'
import { FindShipmentResultDisplay } from '@src/utils/recon/FindShipmentResultDisplay'

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  warningText: {
    backgroundColor: theme.palette.warning.light,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

type Props = {
  isOpen: boolean
  close: () => void
  jobId: string
  reconType: JobTemplateReconType.Ap | JobTemplateReconType.ArrivalNotice
}

const PostToCWDialog: FunctionComponent<Props> = ({ isOpen, close, jobId, reconType }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const dialog = useDialog()
  const { logEvent } = useEventLogger()

  const {
    metadataFieldValueMap,
    reconTables,
    isReconDataLoading,
    initLumpsum,
    setInitLumpsum,
    sendAsLumpsum,
    toggleSendAsLumpSum,
    overrideChargeDescriptionFlag,
    toggleOverrideChargeDescriptionFlag,
    reconcileCriteriaFlag,
    toggleReconcileCriteriaFlag,
    disableSendDueDateFlag,
    toggleDisableSendDueDateFlag,
    isPremiumRate,
    isValidatingChargeCodes,
    invalidDocChargeCodes,
    lumpsumHotTableRef,
    findShipmentReconResults,
  } = useReconData(jobId, reconType, true)
  const cwModule =
    convertCwTargetModuleDisplayTextToCwTargetModule(
      metadataFieldValueMap[ApReconAutofillKey.CargowiseModule.toLowerCase()],
    ) || CwTargetModule.ForwardingShipment
  const matchingCriteria = reconcileCriteriaFlag
    ? MatchingCriteriaType.ChargeCodeVendorInvoiceNumber
    : MatchingCriteriaType.ChargeCodeOnly

  if (!isValidatingChargeCodes && invalidDocChargeCodes.length > 0) {
    close()
  }

  const cargowiseOpsType = CargowiseOpsType.PostToCW
  const [isSending, setIsSending] = useState(false)

  // this will be used to determine whether or not the Post To CW button will be disabled
  const hasSuccessfulShipmentReconResult = useMemo(() => {
    return findShipmentReconResults.some((reconResult) => reconResult.success)
  }, [findShipmentReconResults])

  const [postJobToCW] = useMutation<Pick<Mutation, 'postToCw'>, MutationExportJobToCwArgs>(
    POST_TO_CARGOWISE,
    {
      refetchQueries: [{ query: JOB_NOTES, variables: { jobId } }],
      awaitRefetchQueries: true,
    },
  )

  const postToCW = async (): Promise<void> => {
    setIsSending(true)
    const actionStart = new Date()
    let success = false
    let errorMessage = ''
    try {
      enqueueSnackbar('Uploading job to Cargowise. This may take a few minutes.', {
        variant: 'info',
      })

      let lumpsumInputTable = null as InputDocumentTable | null
      const lumpsumTable = getLumpsumTable(reconTables, reconType)
      if (sendAsLumpsum && lumpsumTable) {
        const lumpsumRows = lumpsumHotTableRef.current?.hotInstance!.getData()
        if (lumpsumRows) {
          const lumpsumColumns = Object.keys(initLumpsum)
          lumpsumInputTable = getLumpsumInputTable(lumpsumRows[0], lumpsumColumns, lumpsumTable)
          const newLumpsum = Object.fromEntries(zip(lumpsumColumns, lumpsumRows[0]))
          setInitLumpsum(newLumpsum)
        }
      }

      await postJobToCW({
        variables: {
          jobId,
          lumpsum: lumpsumInputTable,
          overrideChargeDescription: overrideChargeDescriptionFlag,
          disableSendDueDate: disableSendDueDateFlag,
          matchingCriteria: matchingCriteria,
        },
      })
      enqueueSnackbar('Successfully posted line items to CW', { variant: 'success' })
      success = true
      close()
    } catch (error) {
      errorMessage = `CW Export Error: ${formatMaybeApolloError(error)}`
      await dialog.alert({
        title: 'Error during CW Export',
        message: (
          <>
            <Typography>The following error was encountered while posting to CW:</Typography>
            <blockquote>{formatMaybeApolloError(error)}</blockquote>
            <Typography>
              Changes may have been partially applied in Cargowise. Please check on Cargowise
              whether any unintended changes have occurred.
            </Typography>
          </>
        ),
      })
    } finally {
      void logEvent(LogEventType.SHIPMENT_POST_TO_TMS, {
        tms: TransportManagementSystem.CARGOWISE,
        job_id: jobId,
        action_start: actionStart,
        action_end: new Date(),
        send_as_lumpsum: sendAsLumpsum,
        override_charge_desc: overrideChargeDescriptionFlag,
        disable_send_due_date: disableSendDueDateFlag,
        success: success,
        error_message: errorMessage,
      })
    }
  }

  const isSendingDisabled =
    isSending || (reconType === JobTemplateReconType.Ap && !hasSuccessfulShipmentReconResult)

  const isCustomsDeclarationFromReconResults = isCustomsDeclarationFromReconResultsHelper(
    null,
    findShipmentReconResults,
  )

  return (
    <Dialog classes={{ paper: classes.dialog }} open={isOpen}>
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={close} data-testid='close-btn' />
      </Box>
      <DialogTitle>
        <Box p={1}>
          <Typography variant='h6'>Review Data to Post to CW</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isCustomsDeclarationFromReconResults && (
          <Box my={2}>
            <Typography className={classes.warningText}>
              <strong>WARNING:</strong> Cargowise does not allow sending for customs declarations
              attached to shipments. If this Customs Declaration is associated to a Forwarding
              Shipment, it will not post or send properly.
            </Typography>
          </Box>
        )}
        {isReconDataLoading ? (
          <CenteredCircularProgress />
        ) : (
          <>
            {reconType === JobTemplateReconType.Ap && (
              <Typography variant='subtitle1' gutterBottom>
                <FindShipmentResultDisplay
                  findShipmentResults={findShipmentReconResults}
                  cargowiseOpsType={cargowiseOpsType}
                  cargowiseModule={cwModule}
                />
              </Typography>
            )}
            {reconTables.map((docTable) => {
              return (
                <div key={`post-table-${docTable.id}`}>
                  <Typography variant='subtitle1' gutterBottom>
                    {docTable.fieldGroup?.name}
                  </Typography>
                  <ReadOnlyDocumentHotTable
                    documentTable={docTable}
                    showChargeDescription={overrideChargeDescriptionFlag}
                  />
                </div>
              )
            })}
            <Box display='flex' alignItems='center'>
              <Checkbox
                checked={sendAsLumpsum}
                onChange={toggleSendAsLumpSum}
                disabled={isPremiumRate}
                data-testid={'lumpsum-checkbox'}
              />
              <Typography>Post as lumpsum?</Typography>
            </Box>
            {sendAsLumpsum && (
              <div data-testid='lumpsum-table'>
                <HotTable
                  ref={lumpsumHotTableRef as RefObject<HotTable>}
                  style={{ width: '100%', overflow: 'hidden', height: '80px' }}
                  colHeaders={Object.keys(initLumpsum)}
                  stretchH='all'
                  autoRowSize={true}
                />
              </div>
            )}
            {reconType === JobTemplateReconType.Ap && (
              <>
                <Box display='flex' alignItems='center'>
                  <Checkbox
                    checked={overrideChargeDescriptionFlag}
                    onChange={toggleOverrideChargeDescriptionFlag}
                    data-testid='override-checkbox'
                  />
                  <Typography>Override Charge Description in CW</Typography>
                </Box>
                <Box display='flex' alignItems='center'>
                  <Checkbox
                    checked={disableSendDueDateFlag}
                    onChange={toggleDisableSendDueDateFlag}
                    data-testid='disable-send-due-date'
                  />
                  <Typography>Do NOT send Due Date</Typography>
                </Box>
                <Box display='flex' alignItems='center'>
                  <Checkbox
                    checked={reconcileCriteriaFlag}
                    onChange={toggleReconcileCriteriaFlag}
                    data-testid='recon-criteria-toggle'
                  />
                  <Typography>Match by Charge Code, Vendor, and Invoice Number</Typography>
                </Box>
              </>
            )}
            <Box textAlign='right'>
              <Button
                variant='contained'
                color='primary'
                disabled={isSendingDisabled}
                onClick={postToCW}
                data-testid='post-to-cw-dialog-btn'
              >
                Post to CW
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default PostToCWDialog
