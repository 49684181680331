import { AutofillExtractorKey, DocumentTypeNode } from '@src/graphql/types'

export type DocumentTypeOption = {
  title: string
  name: string
  id: string
  isStandard: boolean
  collapsible: boolean
  tableShowsPreset: boolean
  cargowiseFileTypeId: string | undefined | null
  derivedFromId: string | undefined | null
  autofillExtractorKey: AutofillExtractorKey
  isEDocPublishedByDefault: boolean
}

export const toDocumentTypeOption = ({
  id,
  name,
  isStandard,
  collapsible,
  tableShowsPreset,
  cargowiseFileTypeId,
  derivedFromId,
  autofillExtractorKey,
  isEDocPublishedByDefault,
}: DocumentTypeNode): DocumentTypeOption => ({
  id,
  title: name,
  name,
  isStandard,
  collapsible,
  tableShowsPreset,
  cargowiseFileTypeId,
  derivedFromId,
  autofillExtractorKey: autofillExtractorKey || AutofillExtractorKey.Invoice,
  isEDocPublishedByDefault,
})
