import { FunctionComponent, useState } from 'react'
import { clsx } from 'clsx'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { COLORS } from '@src/utils/app_constants'
import {
  EXPECTED_DATA_TABLE_COLUMNS,
  getAPInvoiceDataTables,
  getExpectedRowsBasedOnMatchingCriteria,
  INVOICE_DATA_TABLE_COLUMNS,
  isErrorCell,
  RECON_TABLE_COLUMNS,
} from '@src/utils/recon/ap_recon'
import {
  InvoiceLineItemReconResultNode,
  Query,
  ReconcileApInvoiceJob,
  ReconResultType,
} from '@src/graphql/types'
import { Theme } from '@material-ui/core'
import { isFallback } from '@src/utils/enum'
import { useQuery } from '@apollo/client'
import {
  GET_UNIQUE_CHARGE_VENDORS_BY_COMPANY,
  GET_UNIQUE_CHARGE_CODES_BY_COMPANY_PARTNER,
} from '@src/graphql/queries/recon'

type Props = {
  reconJobResults: ReconcileApInvoiceJob
  overrideChargeDescription?: boolean
  disableHighlights?: boolean
  sendAsLumpsum?: boolean
}

const MIN_CELL_WIDTH = '75px'

const useStyles = makeStyles<Theme>({
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.75, 1),
    minWidth: MIN_CELL_WIDTH,
  },
  wrapWord: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  error: {
    color: theme.palette.error.main,
    background: COLORS.PALE_RED,
    boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
  },
  errorOutline: {
    boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
  },
  hovered: {
    background: COLORS.PALE_YELLOW,
    boxShadow: '0 0 0 1px yellow inset',
  },
  disabledTable: {
    backgroundColor: theme.palette.grey[300],
  },
  disabledCell: {
    color: theme.palette.text.disabled,
  },
  nonMatching: {
    color: theme.palette.text.disabled,
  },
})

const APInvoiceReconResultTables: FunctionComponent<Props> = ({
  reconJobResults,
  overrideChargeDescription,
  disableHighlights,
  sendAsLumpsum,
}) => {
  const classes = useStyles()
  const { docCharges, expectedCharges, reconAttempt, reconResults } = reconJobResults
  const apiPartnerId = reconAttempt?.job?.jobTemplate?.apiPartnerId ?? null
  const companyId = reconAttempt?.job?.jobTemplate?.companyId ?? null
  const matchingCriteria = reconAttempt.matchingCriteria
    ? isFallback(reconAttempt.matchingCriteria)
      ? reconAttempt.matchingCriteria
      : reconAttempt.matchingCriteria.value
    : null
  const [hoveredRowIdx, setHoveredRowIdx] = useState(null as null | number)
  const invoiceLineItemReconResults = reconResults.filter(
    (reconResult) =>
      !isFallback(reconResult.type) &&
      reconResult.type?.value === ReconResultType.InvoiceLineItemReconResult,
  ) as InvoiceLineItemReconResultNode[]

  const docChargesCodes = docCharges
    .filter(
      (chargeDetail) =>
        typeof chargeDetail.chargeCode === 'string' &&
        chargeDetail.chargeCode.trim() !== '' &&
        typeof chargeDetail.vendor === 'string' &&
        chargeDetail.vendor.trim() !== '',
    )
    .map((chargeDetail) => chargeDetail.chargeCode)

  const expectedChargesCodes = expectedCharges
    .filter(
      (chargeDetail) =>
        typeof chargeDetail.chargeCode === 'string' &&
        chargeDetail.chargeCode.trim() !== '' &&
        typeof chargeDetail.vendor === 'string' &&
        chargeDetail.vendor.trim() !== '',
    )
    .map((chargeDetail) => chargeDetail.chargeCode)

  const docChargesVendors = docCharges
    .filter(
      (chargeDetail) =>
        typeof chargeDetail.chargeCode === 'string' &&
        chargeDetail.chargeCode.trim() !== '' &&
        typeof chargeDetail.vendor === 'string' &&
        chargeDetail.vendor.trim() !== '',
    )
    .map((chargeDetail) => chargeDetail.vendor)

  const expectedChargesVendors = expectedCharges
    .filter(
      (chargeDetail) =>
        typeof chargeDetail.chargeCode === 'string' &&
        chargeDetail.chargeCode.trim() !== '' &&
        typeof chargeDetail.vendor === 'string' &&
        chargeDetail.vendor.trim() !== '',
    )
    .map((chargeDetail) => chargeDetail.vendor)

  const { data: uniqueChargeCodesByCompanyPartnerData } = useQuery<
    Pick<Query, 'uniqueChargeCodesByCompanyPartner'>
  >(GET_UNIQUE_CHARGE_CODES_BY_COMPANY_PARTNER, {
    variables: {
      companyId,
      apiPartnerId,
      codes: [...docChargesCodes, ...expectedChargesCodes],
      vendorCodes: [...docChargesVendors, ...expectedChargesVendors],
    },
    skip: !apiPartnerId,
  })
  const chargeCodeToDescMap = apiPartnerId
    ? Object.fromEntries(
        uniqueChargeCodesByCompanyPartnerData?.uniqueChargeCodesByCompanyPartner?.map(
          ({ code, description }) => [code, description],
        ) ?? [],
      )
    : null

  const { data: uniqueChargeVendorsByCompanyData } = useQuery<
    Pick<Query, 'uniqueChargeVendorsByCompany'>
  >(GET_UNIQUE_CHARGE_VENDORS_BY_COMPANY, {
    variables: { companyId, codes: [...docChargesVendors, ...expectedChargesVendors] },
    skip: !apiPartnerId,
  })
  const chargeVendorCodeToNameMap = apiPartnerId
    ? Object.fromEntries(
        uniqueChargeVendorsByCompanyData?.uniqueChargeVendorsByCompany?.map(({ code, name }) => [
          code,
          name,
        ]) ?? [],
      )
    : null

  const invoiceDataTableColumns = overrideChargeDescription
    ? INVOICE_DATA_TABLE_COLUMNS
    : INVOICE_DATA_TABLE_COLUMNS.filter((column) => column !== RECON_TABLE_COLUMNS.DESCRIPTION)
  const expectedDataTableColumns = overrideChargeDescription
    ? EXPECTED_DATA_TABLE_COLUMNS
    : EXPECTED_DATA_TABLE_COLUMNS.filter((column) => column !== RECON_TABLE_COLUMNS.DESCRIPTION)

  const apInvoiceDataTables = getAPInvoiceDataTables(
    invoiceLineItemReconResults,
    docCharges,
    expectedCharges,
    matchingCriteria,
    overrideChargeDescription,
    chargeCodeToDescMap,
    chargeVendorCodeToNameMap,
  )
  const matchingRowsOnExpected = getExpectedRowsBasedOnMatchingCriteria(
    invoiceLineItemReconResults,
    expectedCharges,
    matchingCriteria,
  )
  const {
    data: documentData,
    errorCells: documentErrorCells,
    errorOutlineCells: documentErrorOutlineCells,
  } = apInvoiceDataTables.invoiceTableData
  const {
    data: expectedData,
    expectedErrorCells,
    errorOutlineCells: expectedErrorOutlineCells,
  } = apInvoiceDataTables.expectedTableData
  const nonMatchingRowsOnExpected = expectedData
    .map((_, idx) => idx)
    .filter((idx) => !matchingRowsOnExpected.flat().includes(idx))

  return (
    <Box display='flex' alignItems='baseline' mb={2} data-testid='line-item-recon-tables'>
      <Box display='flex' flexDirection='column' mr={2}>
        <Typography variant='subtitle2' gutterBottom>
          Invoice Data
        </Typography>
        <TableContainer className={clsx({ [classes.disabledTable]: sendAsLumpsum })}>
          <Table size='small'>
            <TableBody onMouseLeave={() => setHoveredRowIdx(null)}>
              <TableRow>
                {invoiceDataTableColumns.map((col, colIdx) => (
                  <TableCell
                    className={clsx(classes.cell, classes.colCell)}
                    align='center'
                    key={colIdx}
                  >
                    {col}
                  </TableCell>
                ))}
              </TableRow>
              {documentData.map((row, rowIdx) => {
                const isHovered = rowIdx === hoveredRowIdx
                return (
                  <TableRow key={rowIdx} onMouseEnter={() => setHoveredRowIdx(rowIdx)}>
                    {row.map((data, colIdx) => {
                      return (
                        <TableCell
                          className={clsx(classes.cell, classes.wrapWord, {
                            [classes.errorOutline]:
                              !disableHighlights &&
                              documentErrorOutlineCells &&
                              documentErrorOutlineCells[rowIdx]?.includes(colIdx),
                            [classes.error]:
                              !disableHighlights &&
                              isHovered &&
                              documentErrorCells![rowIdx]?.includes(colIdx),
                            [classes.hovered]:
                              !disableHighlights &&
                              isHovered &&
                              !documentErrorCells![rowIdx]?.includes(colIdx),
                            [classes.disabledCell]: sendAsLumpsum,
                          })}
                          component='th'
                          scope='row'
                          key={colIdx}
                        >
                          {data}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box display='flex' flexDirection='column'>
        <Typography variant='subtitle2' gutterBottom>
          Expected Data
        </Typography>
        <TableContainer>
          <Table size='small'>
            <TableBody>
              <TableRow>
                {expectedDataTableColumns.map((col, colIdx) => (
                  <TableCell
                    className={clsx(classes.cell, classes.colCell)}
                    align='center'
                    key={colIdx}
                  >
                    {col}
                  </TableCell>
                ))}
              </TableRow>
              {expectedData.map((row, rowIdx) => {
                const isHovered =
                  hoveredRowIdx !== null && matchingRowsOnExpected[hoveredRowIdx]?.includes(rowIdx)
                return (
                  <TableRow key={rowIdx}>
                    {row.map((data, colIdx) => {
                      return (
                        <TableCell
                          className={clsx(classes.cell, classes.wrapWord, {
                            [classes.errorOutline]:
                              !disableHighlights &&
                              expectedErrorOutlineCells &&
                              expectedErrorOutlineCells[rowIdx]?.includes(colIdx),
                            [classes.error]:
                              !disableHighlights &&
                              isHovered &&
                              isErrorCell(expectedErrorCells!, hoveredRowIdx, rowIdx, colIdx),
                            [classes.hovered]:
                              !disableHighlights &&
                              isHovered &&
                              !isErrorCell(expectedErrorCells!, hoveredRowIdx, rowIdx, colIdx),
                            [classes.nonMatching]: nonMatchingRowsOnExpected.includes(rowIdx),
                          })}
                          component='th'
                          scope='row'
                          key={colIdx}
                        >
                          {data}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default APInvoiceReconResultTables
