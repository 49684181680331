import { FunctionComponent, useEffect, useRef, useState } from 'react'
import Handsontable from 'handsontable'
import { getDefaultRowsColumns } from '@src/utils/lumpsum'
import { DocumentTableNode } from '@src/graphql/types'
import { removeChargeDescription } from '@src/utils/override_charge_description'

type Props = {
  documentTable: DocumentTableNode
  showChargeDescription?: boolean
  settings?: Handsontable.DefaultSettings
  style?: React.CSSProperties
  className?: string
}

const defaultStyle = { width: '100%', overflow: 'hidden', height: '200px' }

const defaultSettings = {
  style: { width: '100%', overflow: 'hidden', height: '200px' },
  rowHeaders: true,
  readOnly: true,
  stretchH: 'all',
  autoRowSize: true,
}

/**
 * Handsontable React wrapper optimized for read-only display of DocumentTableNode
 * (which happens pretty often). Similar to <ReadOnlyHotTable>, but does not use
 * it to avoid useMemo (not sure if that's worth it, but I don't think either
 * component will change much anyways)
 */
const ReadOnlyDocumentHotTable: FunctionComponent<Props> = ({
  documentTable,
  settings,
  style,
  className,
  showChargeDescription,
}) => {
  const hotInstance = useRef<Handsontable>()
  const hotElement = useRef<HTMLDivElement | null>(null)
  const [hotTableId] = useState(() => Math.random().toString(36))
  useEffect(() => {
    if (documentTable && hotTableId) {
      const { columns, rows } = getDefaultRowsColumns(
        showChargeDescription ? documentTable : removeChargeDescription(documentTable),
      )
      hotInstance.current = new Handsontable(hotElement.current as HTMLDivElement, {
        data: rows,
        colHeaders: columns,
        ...(settings ?? defaultSettings),
      })
    }
    return () => {
      hotInstance.current?.destroy()
    }
  }, [hotTableId, documentTable, settings, showChargeDescription])
  return (
    <div
      style={style ?? defaultStyle}
      className={className}
      id={`hot-${hotTableId}`}
      ref={hotElement}
    ></div>
  )
}

export default ReadOnlyDocumentHotTable
