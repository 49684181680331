import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useContext, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { makeStyles } from '@material-ui/styles'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import { clsx } from 'clsx'
import { useSnackbar } from 'notistack'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

import { setActiveDocumentTable, setTableEditMode } from '@src/redux-features/document_editor'
import {
  DELETE_DOCUMENT_TABLE_GROUP,
  MERGE_TABLES,
  UPDATE_DOC_TABLES_ORDER,
} from '@src/graphql/mutations/document'
import { UPSERT_DOC_TABLE_FIELD_GROUP } from '@src/graphql/mutations/document'
import {
  FieldGroupNode,
  Mutation,
  MutationDeleteDocumentTableArgs,
  MutationUpdateDocumentTablesOrderArgs,
  MutationUpsertDocumentTableFieldGroupArgs,
} from '@src/graphql/types'
import { RootState } from '@src/utils/store'
import theme from '@src/utils/theme'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import { formatDocTableErrors, groupMergedTables, JobDocumentTable } from '@src/utils/shipment_form'
import { ShipmentFormContext } from '@src/contexts/shipment_form_context'
import DeleteTableDialog from './DeleteTableDialog'
import SelectTableTypeDialog from './SelectTableTypeDialog'

const useStyles = makeStyles({
  droppableContainer: {
    width: '98%',
    padding: theme.spacing(1),
  },
  activeDroppableContainer: {
    background: theme.palette.grey[100],
  },
  draggedDocTable: {
    backgroundColor: theme.palette.info.main,
  },
  dragButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableFieldsContainer: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  errorText: {
    fontSize: theme.typography.fontSize,
  },
})

type Props = {
  jobDocumentTables: JobDocumentTable[] | null
  jobRepeatableFieldGroups: FieldGroupNode[]
  saveJob: () => Promise<void>
  refetchJob: () => Promise<void>
  switchPage: (nextFilePageId: string) => void
}

const DocumentTables: FunctionComponent<Props> = ({
  jobDocumentTables,
  jobRepeatableFieldGroups,
  saveJob,
  refetchJob,
  switchPage,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [isSelectTableTypeDialogOpen, setIsSelectTableTypeDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteTableId, setDeleteTableId] = useState(null as string | null)
  const { updateDocumentMapping } = useContext(ShipmentFormContext)

  const orderedDocumentTables = useMemo(() => {
    const mergedDocumentTables = jobDocumentTables ? groupMergedTables(jobDocumentTables) : []
    return mergedDocumentTables.sort((a, b) => a.orderPriority - b.orderPriority)
  }, [jobDocumentTables])
  const docTableValidationErrors = useSelector(
    (state: RootState) => state.documentEditor.docTableValidationErrors,
  )
  const activeFilePageId = useSelector((state: RootState) => state.documentEditor.activeFilePageId)
  const formattedDocTableErrors = useMemo(() => {
    return formatDocTableErrors(orderedDocumentTables, docTableValidationErrors)
  }, [docTableValidationErrors, orderedDocumentTables])

  // array of length orderedDocumentTables filled with false
  const [tablesChecked, setTablesChecked] = useState(orderedDocumentTables.map(() => false))
  const [mergeTableMutation] = useMutation<Pick<Mutation, 'mergeTable'>>(MERGE_TABLES)

  const [upsertTableFieldGroup] = useMutation<
    Pick<Mutation, 'upsertDocumentTableFieldGroup'>,
    MutationUpsertDocumentTableFieldGroupArgs
  >(UPSERT_DOC_TABLE_FIELD_GROUP)
  const [deleteDocumentTable] = useMutation<
    Pick<Mutation, 'deleteDocumentTablesInGroup'>,
    MutationDeleteDocumentTableArgs
  >(DELETE_DOCUMENT_TABLE_GROUP)
  const [updateDocumentTablesOrder] = useMutation<
    Pick<Mutation, 'updateDocumentTablesOrder'>,
    MutationUpdateDocumentTablesOrderArgs
  >(UPDATE_DOC_TABLES_ORDER)

  const mergeTables = async (): Promise<void> => {
    try {
      await saveJob()
      const tableIds = orderedDocumentTables
        .filter((_, idx) => tablesChecked[idx])
        .map((docTable) => docTable.id)
      updateDocumentMapping()
      await mergeTableMutation({ variables: { tableIds } })
    } catch (error) {
      enqueueSnackbar(`Failed to merge tables: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
      return
    }
    enqueueSnackbar('Successfully merged tables', { variant: 'success' })
    await refetchJob()
  }

  const handleDragEnd = async (result: DropResult): Promise<void> => {
    const { source, destination } = result

    if (result.destination === null) {
      return
    }
    if (source.index !== destination!.index) {
      const orderedTablesCopy = [...orderedDocumentTables]
      const sourceTableCopy = orderedTablesCopy[source.index]
      orderedTablesCopy.splice(source.index, 1)
      orderedTablesCopy.splice(destination!.index, 0, sourceTableCopy)
      try {
        const documentTableIds = orderedTablesCopy.map(({ id }) => id)
        await saveJob()
        await updateDocumentTablesOrder({
          variables: { documentTableIds },
        })
        await refetchJob()
      } catch (error) {
        enqueueSnackbar(`Error changing table order: ${formatMaybeApolloError(error)}`, {
          variant: 'error',
        })
      }
    }
  }

  const handleTableTypeChange = async (
    documentTableId: string,
    fieldGroupId: string,
  ): Promise<void> => {
    try {
      updateDocumentMapping()
      await saveJob()
      await upsertTableFieldGroup({ variables: { documentTableId, fieldGroupId } })
      await refetchJob()
    } catch (error) {
      enqueueSnackbar(`Error changing table type: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
  }

  const goToPageAndShowTable = (filePageId: string, documentTableId: string): void => {
    switchPage(filePageId)
    // delay dispatch to let switching page finish first before
    // showing line items table
    setTimeout(() => {
      batch(() => {
        dispatch(setActiveDocumentTable(documentTableId))
        dispatch(setTableEditMode(true))
      })
    })
  }

  const openGroupedDocumentTables = (headDocumentTable: JobDocumentTable): void => {
    const groupedDocumentTables = [headDocumentTable, ...headDocumentTable.linkedTables]
    const currentFilePageDocumentTable = groupedDocumentTables.find(
      (docTable) => docTable.filePageId === activeFilePageId,
    )

    if (currentFilePageDocumentTable) {
      goToPageAndShowTable(activeFilePageId!, currentFilePageDocumentTable.id)
    } else {
      goToPageAndShowTable(headDocumentTable.filePageId, headDocumentTable.id)
    }
  }

  const handleDeleteTable = async (): Promise<void> => {
    try {
      setIsDeleting(true)
      updateDocumentMapping()
      await saveJob()
      await deleteDocumentTable({
        variables: {
          documentTableId: deleteTableId!,
        },
      })
      await refetchJob()
      setDeleteTableId(null)
      setIsDeleteDialogOpen(false)
    } catch (error) {
      enqueueSnackbar(`Error deleting table: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    } finally {
      setIsDeleting(false)
    }
  }

  const toggleTableChecked = (index: number): void => {
    const newTablesChecked = [...tablesChecked]
    newTablesChecked[index] = !newTablesChecked[index]
    setTablesChecked(newTablesChecked)
  }

  const checkCantMerge = (): boolean => {
    const tooFewTablesSelected = tablesChecked.filter((isChecked) => isChecked).length < 2
    const selectedTables = orderedDocumentTables.filter((_, idx) => tablesChecked[idx])
    const tableTypeKeys = new Set(selectedTables.map((tab) => tab.fieldGroup!.key))
    const tablesSameType = tableTypeKeys.size === 1
    return tooFewTablesSelected || !tablesSameType
  }

  const cantMerge = checkCantMerge()

  return (
    <Box display='flex' flexDirection='column'>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='document-tables'>
          {(dropProvided, dropSnapshot) => {
            return (
              <div
                className={clsx(classes.droppableContainer, {
                  [classes.activeDroppableContainer]: dropSnapshot.isDraggingOver,
                })}
                {...dropProvided.droppableProps}
                // eslint-disable-next-line @typescript-eslint/unbound-method
                ref={dropProvided.innerRef}
              >
                {orderedDocumentTables.map((docTable, index) => {
                  const validationErrors = formattedDocTableErrors?.[docTable.id] ?? []
                  const isDocTableInvalid = validationErrors.length > 0

                  return (
                    <Draggable
                      key={`draggable-${docTable.id}`}
                      draggableId={docTable.id}
                      index={index}
                    >
                      {(dragProvided, dragSnapshot) => {
                        return (
                          // eslint-disable-next-line @typescript-eslint/unbound-method
                          <div ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
                            <Grid
                              key={`table-field-${docTable.fieldGroup!.id}-${docTable.filePageId}`}
                              className={clsx(classes.tableFieldsContainer, {
                                [classes.draggedDocTable]: dragSnapshot.isDragging,
                              })}
                              alignItems='center'
                              spacing={1}
                              container
                            >
                              <Grid item xs={1}>
                                <div
                                  className={classes.dragButton}
                                  {...dragProvided.dragHandleProps}
                                  onClick={() => switchPage(docTable.filePageId)}
                                >
                                  <DragIndicatorIcon />
                                </div>
                              </Grid>
                              <Grid item xs={5}>
                                <FormControl fullWidth error={isDocTableInvalid}>
                                  <Select
                                    value={docTable.fieldGroup!.id}
                                    onChange={(evt) =>
                                      handleTableTypeChange(docTable.id, evt.target.value as string)
                                    }
                                    fullWidth
                                    variant='outlined'
                                  >
                                    {jobRepeatableFieldGroups.map(
                                      ({ id: repeatableFieldGroupId, name }) => (
                                        <MenuItem
                                          key={`doctype-table-option-${repeatableFieldGroupId}`}
                                          value={repeatableFieldGroupId}
                                        >
                                          {name}
                                        </MenuItem>
                                      ),
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  onClick={() => openGroupedDocumentTables(docTable)}
                                  data-testid='edit-line-item-table'
                                >
                                  <EditIcon />
                                </IconButton>
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  onClick={() => {
                                    setIsDeleteDialogOpen(true)
                                    setDeleteTableId(docTable.id)
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </Grid>
                              <Grid item xs={1}>
                                <Checkbox
                                  checked={tablesChecked[index]}
                                  onClick={() => toggleTableChecked(index)}
                                />
                              </Grid>
                            </Grid>
                            {validationErrors.map((validationError) => {
                              return (
                                <Grid key={`doc-table-error-${docTable.id}`} spacing={1} container>
                                  <Grid item xs={1} />
                                  <Grid item xs={9}>
                                    <Typography className={classes.errorText} color='error'>
                                      {validationError}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )
                            })}
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {dropProvided.placeholder}
              </div>
            )
          }}
        </Droppable>
      </DragDropContext>
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          setIsSelectTableTypeDialogOpen(true)
        }}
      >
        Add New Table
      </Button>
      <Button onClick={mergeTables} disabled={cantMerge}>
        Merge tables
      </Button>
      <SelectTableTypeDialog
        isOpen={isSelectTableTypeDialogOpen}
        handleClose={() => setIsSelectTableTypeDialogOpen(false)}
        jobRepeatableFieldGroups={jobRepeatableFieldGroups}
        refetchJob={refetchJob}
      />
      <DeleteTableDialog
        isOpen={isDeleteDialogOpen}
        handleClose={() => setIsDeleteDialogOpen(false)}
        onDeleteHandler={handleDeleteTable}
        isDeleting={isDeleting}
      />
    </Box>
  )
}

export default DocumentTables
