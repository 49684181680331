import { FunctionComponent } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import theme from '@src/utils/theme'
import { SoaLineItem } from '@src/utils/line_items'

const useStyles = makeStyles({
  dialogFullScreen: {
    maxWidth: '90%',
    width: '90%',
    height: '90%',
  },
  table: {
    height: '50%',
    overflowY: 'scroll',
    margin: theme.spacing(1),
  },
  row: {
    cursor: 'pointer',
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  logs: {
    height: '30%',
    overflowY: 'scroll',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.info.main,
  },
})

const INVOICE_GROUP_COLUMN_KEYS = [
  'Shipment Number',
  'Secondary Keys',
  'Charge Code',
  'Charge Description',
  'Cost',
  'Tax Code',
  'Tax Amount',
  'Total Amount',
  'Currency',
  'Invoice Date',
  'Due Date',
]

type Props = {
  isOpen: boolean
  close: () => void
  invoiceGroup?: SoaLineItem[] | undefined
  vendor?: string | undefined
  overrideChargeDescription: boolean
}

const SOAInvoiceGroupDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  invoiceGroup,
  vendor,
  overrideChargeDescription,
}) => {
  const classes = useStyles()
  const invoiceDataColumnKeys = overrideChargeDescription
    ? INVOICE_GROUP_COLUMN_KEYS
    : INVOICE_GROUP_COLUMN_KEYS.filter((val) => val !== 'Charge Description')

  return (
    <Dialog
      open={isOpen}
      onBackdropClick={close}
      classes={{ paper: classes.dialogFullScreen }}
      data-testid='soa-invoice-group-dialog'
    >
      <DialogTitle disableTypography>
        <IconButton onClick={close} aria-label='close' data-testid='soa-invoice-group-close'>
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant='h3'>Invoice: {invoiceGroup![0]['Invoice Number']} </Typography>
        <Typography variant='h3'>Vendor: {vendor}</Typography>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} className={classes.table}>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                {invoiceDataColumnKeys.map((key) => {
                  return <TableCell key={key}>{key}</TableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceGroup!.map((invoice, rowIdx) => {
                return (
                  <TableRow key={`invoice-key-${rowIdx}`} className={classes.row}>
                    {invoiceDataColumnKeys.map((key) => {
                      if (key === 'Secondary Keys') {
                        return (
                          <TableCell key={key}>
                            {invoice[key] &&
                              (invoice[key]! as string[]).map((secondaryKey) => {
                                return <TableRow key={secondaryKey}>{secondaryKey}</TableRow>
                              })}
                          </TableCell>
                        )
                      }
                      return <TableCell key={key}>{invoice[key]}</TableCell>
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant='h6'> Processing Log </Typography>
        <Paper className={classes.logs}>
          {(invoiceGroup![0]!['Notes']! as string[]).map((log, idx) => {
            return (
              <Typography variant='body2' key={`${idx}`}>
                {log[0] + ': ' + log[1]}{' '}
              </Typography>
            )
          })}
        </Paper>
      </DialogContent>
    </Dialog>
  )
}
export default SOAInvoiceGroupDialog
