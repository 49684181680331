import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { IconButton, makeStyles } from '@material-ui/core'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import { useMutation, PureQueryOptions } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useAuth0 } from '@auth0/auth0-react'

import CustomSlate from '@src/components/custom-slate'
import useSlateEditor, { SlateContent } from '@src/hooks/useSlateEditor'
import { Mutation, MutationDeleteTaskNoteArgs, TaskNoteNode } from '@src/graphql/types'
import theme from '@src/utils/theme'
import { DELETE_TASK_NOTE } from '@src/graphql/mutations/taskNote'
import { parseDateString } from '@src/utils/date'

const useStyles = makeStyles({
  taskNoteContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  noteDate: {
    paddingRight: theme.spacing(1),
  },
  deleteButton: {
    padding: 0,
  },
})

type Props = {
  taskNote: TaskNoteNode
  isMostRecent: boolean
  refetchQueries: PureQueryOptions[]
}

const TaskNote: FunctionComponent<Props> = ({ taskNote, isMostRecent, refetchQueries }) => {
  const { user } = useAuth0()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const componentRef = useRef(null as null | HTMLElement)
  const noteDate = parseDateString(taskNote.dateCreated)
  const [isHoveringOver, setIsHoveringOver] = useState(false)
  const [deleteTaskNote, { error: deleteTaskNoteError }] = useMutation<
    Pick<Mutation, 'deleteTaskNote'>,
    MutationDeleteTaskNoteArgs
  >(DELETE_TASK_NOTE, { refetchQueries })

  const onDelete = async (): Promise<void> => {
    await deleteTaskNote({ variables: { taskNoteId: taskNote.id } })
    if (deleteTaskNoteError) {
      enqueueSnackbar(`Error deleting note: ${formatMaybeApolloError(deleteTaskNoteError)}`, {
        variant: 'error',
      })
    }
  }

  // e.g. Feb 07, 2:52:00 AM
  const formattedNoteDate = `${noteDate
    .toDateString()
    .slice(4, 10)}, ${noteDate.toLocaleTimeString()}`

  let parsedContent = [] as SlateContent[]
  try {
    parsedContent = JSON.parse(taskNote.content!) as SlateContent[]
  } catch (error) {
    enqueueSnackbar(
      `Parsing the task note content failed with error: ${formatMaybeApolloError(error)}`,
      {
        variant: 'error',
      },
    )
  }
  const slateEditor = useSlateEditor(parsedContent)

  useEffect(() => {
    if (isMostRecent) {
      componentRef?.current?.scrollIntoView({ block: 'nearest' })
    }
  }, [isMostRecent])
  const noteIsByCurrUser = user?.email === taskNote.user!.email

  return (
    <Paper
      ref={componentRef}
      key={taskNote.id}
      className={classes.taskNoteContainer}
      onMouseEnter={() => setIsHoveringOver(true)}
      onMouseLeave={() => setIsHoveringOver(false)}
    >
      <Box display='flex' justifyContent='space-between'>
        <Typography>{taskNote.user!.email} commented:</Typography>
        <Box display='inline-flex'>
          <Typography className={classes.noteDate}>{formattedNoteDate}</Typography>
          {noteIsByCurrUser && isHoveringOver && (
            <IconButton onClick={onDelete} className={classes.deleteButton}>
              <DeleteOutlinedIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <CustomSlate slateEditor={slateEditor} readOnly />
    </Paper>
  )
}

export default TaskNote
