import { DocumentTableNode, JobTableNode } from '@src/graphql/types'

export const getUniqueChargeCodesFromDocTables = (
  docTables: DocumentTableNode[],
  autofillKey: string,
): string[] => {
  const documentChargeCodes = docTables
    .flatMap((docTable) => {
      return docTable!.documentFieldGroups!.edges!.flatMap((docFieldGroupEdge) => {
        const chargeCodeDocFieldEdge = docFieldGroupEdge!.node!.documentFields!.edges!.find(
          (docFieldEdge) => {
            const docFieldAutofillKey = docFieldEdge!.node!.field!.autofillKey
            return docFieldAutofillKey === autofillKey.toLowerCase()
          },
        )
        return chargeCodeDocFieldEdge?.node?.value ?? ''
      })
    })
    .filter((chargeCodeValue) => !!chargeCodeValue)
  return [...new Set(documentChargeCodes)]
}

export const getUniqueChargeCodesFromJobTable = (
  jobTable: JobTableNode | null,
  autofillKey: string,
): string[] => {
  if (!jobTable) {
    return []
  }
  const documentChargeCodes = jobTable.jobTableLineItems.edges
    .map((jobTableLineItem) => {
      const chargeCodeCell = jobTableLineItem.node.jobTableCells.edges.find((jobTableCell) => {
        return jobTableCell.node?.field.autofillKey === autofillKey.toLowerCase()
      })?.node
      return chargeCodeCell?.value ?? ''
    })
    .filter((chargeCodeValue) => !!chargeCodeValue)
  return [...new Set(documentChargeCodes)]
}
