import { gql } from '@apollo/client'

export const GET_ALL_CREDENTIALS = gql`
  query credentials {
    credentials {
      id
      username
      apiEndpoint
      workflow
      verifySsl
      hostOverride
      jobTemplates {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`
