import { gql } from '@apollo/client'

export const JOB_NOTES = gql`
  query jobNotes($jobId: String!) {
    jobNotes(jobId: $jobId) {
      id
      dateCreated
      type
      text
      content
      source
      user {
        id
        email
        isCustomer
      }
      dateDeleted
      reconAttempt {
        id
        isAutoGenerated
      }
      jobActivity {
        ... on JobReceivedNode {
          job {
            id
            dateCreated
            task {
              id
              dateReceived
            }
          }
        }
        ... on JobRequestReprocessNode {
          reason
          correctedInfo
          autoPostAfterFixing
          additionalNotes
        }
        ... on JobExternalAssigneeChangeNode {
          additionalNotes
        }
        ... on JobPostToCWNode {
          additionalNotes
        }
        ... on JobMoveToDoneNode {
          additionalNotes
        }
        ... on JobReconApproveForPostingNode {
          additionalNotes
        }
        ... on JobReconAttemptSavedNode {
          additionalNotes
        }
        ... on JobReconCommentNode {
          additionalNotes
        }
        ... on JobReconExternalAssigneeChangeNode {
          additionalNotes
        }
        ... on JobReconExternalStatusChangeNode {
          additionalNotes
        }
        ... on JobReconMoveToDoneNode {
          additionalNotes
        }
        ... on JobReconRequestReprocessNode {
          reason
          correctedInfo
          autoPostAfterFixing
          additionalNotes
          reconInvoice {
            id
            invoiceNumber
          }
          reconInvoiceShipmentReference {
            id
            tmsId
            reconAttempt {
              id
              fieldValueMapV2
              matchReconInvoiceShipmentReference {
                id
                tmsId
              }
              findShipmentReconResult {
                id
                referenceNo
                hblNo
                mblNo
                carrierBookingNo
                containerNo
                consolNo
              }
            }
          }
        }
        ... on JobReconRequestReprocessTargetNode {
          sourceRequestReprocess {
            id
            reason
            correctedInfo
            autoPostAfterFixing
            additionalNotes
            reconInvoice {
              id
              invoiceNumber
            }
            reconInvoiceShipmentReference {
              id
              tmsId
              reconAttempt {
                id
                fieldValueMapV2
                matchReconInvoiceShipmentReference {
                  id
                  tmsId
                }
                findShipmentReconResult {
                  id
                  referenceNo
                  hblNo
                  mblNo
                  carrierBookingNo
                  containerNo
                  consolNo
                }
              }
            }
          }
        }
        ... on JobReconReceivedNode {
          additionalNotes
        }
      }
    }
  }
`
