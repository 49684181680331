import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { FunctionComponent, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { DashboardTabType, Scalars, useListCompanyDashboardsQuery } from '@src/graphql/types'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'

type Props = {
  tabType: string
  dashboardTabsToDisplayMap: Record<string, string>
  defaultValue: string[]
  defaultDashboardsWhitelistSet: Set<string>
  isCreate: boolean
  companyId?: Scalars['UUID']['input']
}

export type DashboardAutocompleteOption = {
  type: 'builtin' | 'custom'
  id: string
  displayName: string
  isDefault: boolean
}

// Sync this up with src/freight-bi/user-management/default-dashboards-whitelist/DashboardNameAutocomplete.tsx
const tabTypeToFriendlyNameMap: Record<string, string> = {
  [DashboardTabType.Dashboard]: 'Dashboard',
  [DashboardTabType.Accounting]: 'Accounting',
  [DashboardTabType.BusinessPerformance]: 'Business Performance',
  [DashboardTabType.Explore]: 'Explore',
  [DashboardTabType.Operations]: 'Operations',
  [DashboardTabType.Sales]: 'Sales',
}

// Sync this up with src/freight-bi/user-management/default-dashboards-whitelist/DashboardNameAutocomplete.tsx
const tabTypeToFieldNameMap: Record<string, string> = {
  [DashboardTabType.Dashboard]: 'dashboard',
  [DashboardTabType.Accounting]: 'accounting',
  [DashboardTabType.BusinessPerformance]: 'businessPerformance',
  [DashboardTabType.Explore]: 'explore',
  [DashboardTabType.Operations]: 'operations',
  [DashboardTabType.Sales]: 'sales',
}

// Sync this up with src/freight-bi/user-management/default-dashboards-whitelist/DashboardNameAutocomplete.tsx
const DEFAULT_CUSTOM_DASHBOARDS = new Set([
  'Agent Reports',
  'Staff Reports',
  'Carrier Reports',
  'Controlling Customer/Agent Reports',
  'Payables Overview',
  'Receivables Overview',
])

const DashboardNameAutocomplete: FunctionComponent<Props> = ({
  tabType,
  dashboardTabsToDisplayMap,
  defaultValue,
  defaultDashboardsWhitelistSet,
  isCreate,
  companyId,
}) => {
  const { data: dashboardsData, loading: dashboardDataLoading } = useListCompanyDashboardsQuery({
    variables: {
      companyId: companyId,
      tabType: tabType,
      shouldWhitelist: false,
    },
  })

  const [currentValues, options] = useMemo(() => {
    if (!dashboardsData?.listCompanyDashboards) return [null, null]

    const currentValues: DashboardAutocompleteOption[] = []
    /**
     * To allow customizing the order of dashboards, defaultValue is only used when creating a new user.
     * When updating a user, defaultDashboardsWhitelist is used to preserve the order of chips in the autocomplete
     * according to the customized order of the dashboards.
     */
    if (isCreate) {
      defaultValue.forEach((dashboard) => {
        currentValues.push({
          type: 'builtin' as const,
          id: dashboard,
          displayName: dashboardTabsToDisplayMap[dashboard] || dashboard,
          isDefault: true,
        })
      })
    } else {
      defaultDashboardsWhitelistSet.forEach((key) => {
        const customDashboard = dashboardsData.listCompanyDashboards.find(
          (dashboard) => dashboard.displayName === key,
        )
        if (customDashboard || dashboardTabsToDisplayMap[key]) {
          currentValues.push({
            type: `${customDashboard ? 'custom' : 'builtin'}` as const,
            id: customDashboard?.id ?? key,
            displayName: customDashboard?.displayName ?? dashboardTabsToDisplayMap[key],
            isDefault: customDashboard?.isDefault ?? true,
          })
        }
      })
    }

    const options: DashboardAutocompleteOption[] = Object.entries(dashboardTabsToDisplayMap).map(
      ([key, value]) => ({
        type: 'builtin' as const,
        id: key,
        displayName: value,
        isDefault: true,
      }),
    )

    dashboardsData.listCompanyDashboards.forEach(({ id, displayName, isDefault }) => {
      if (Object.keys(dashboardTabsToDisplayMap).includes(id)) return

      const dashboardOption = { type: 'custom' as const, id, displayName, isDefault }
      options.push(dashboardOption)

      if (isCreate && DEFAULT_CUSTOM_DASHBOARDS.has(displayName)) {
        currentValues.push(dashboardOption)
      }
    })

    return [currentValues, options]
  }, [
    dashboardsData?.listCompanyDashboards,
    dashboardTabsToDisplayMap,
    defaultDashboardsWhitelistSet,
    isCreate,
    defaultValue,
  ])

  const fieldName = tabTypeToFieldNameMap[tabType]
  const friendlyName = tabTypeToFriendlyNameMap[tabType]

  return (
    <>
      {(currentValues && options && (
        <Controller
          render={({ field: { value, onBlur, onChange } }) => (
            <Autocomplete
              disableClearable
              multiple={true}
              options={options}
              getOptionLabel={(option: DashboardAutocompleteOption) => option.displayName}
              getOptionSelected={(option, value) => option.id === value.id}
              onBlur={onBlur}
              value={value}
              onChange={(_event, newValue) => {
                onChange(newValue)
              }}
              renderInput={(params) => (
                <TextField variant='outlined' {...params} label={friendlyName} />
              )}
            />
          )}
          name={fieldName}
          defaultValue={currentValues}
        />
      )) || <CenteredCircularProgress />}
    </>
  )
}

export default DashboardNameAutocomplete
