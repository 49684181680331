import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { consolidateFieldMappings } from '@src/utils/shipment_form'
import { DocumentEditorState } from './document_editor_state'
import { selectJob } from './job'
import { GoogleOCRData } from '@src/types/ocr'

export const selectActiveFilePageId = (state: DocumentEditorState): string | null =>
  state.activeFilePageId

export const selectActiveFilePage = createDraftSafeSelector(
  selectJob,
  selectActiveFilePageId,
  (job, activeFilePageId) => {
    if (activeFilePageId == null) return undefined
    return (
      job
        ?.filePages!.edges.map((edge) => edge!.node!)
        .find((page) => page.id === activeFilePageId) || undefined
    )
  },
)

// get all fields changed in this form submission or page change
const getChangedFields = (
  fieldMapping: Record<string, string>,
  originalFieldMapping: Record<string, string>,
): Record<string, string> => {
  const changedFields: Record<string, string> = {}
  for (const fieldKey of Object.keys(fieldMapping)) {
    const fieldWasChangedOnPage = fieldMapping[fieldKey] !== originalFieldMapping[fieldKey]
    if (fieldWasChangedOnPage) {
      changedFields[fieldKey] = fieldMapping[fieldKey]
    }
  }
  return changedFields
}

const removeExistingFieldKeysFromOtherPages = (
  state: DocumentEditorState,
  pageFields: Record<string, string>,
  filePageId: string,
): void => {
  for (const fieldKey of Object.keys(pageFields)) {
    for (const otherPageId of Object.keys(state.pageFieldEditorState!.pages)) {
      if (otherPageId !== filePageId) {
        delete state.pageFieldEditorState!.pages[otherPageId].fieldMapping[fieldKey]
      }
    }
  }
}

/**
 * Updates the page field editor state based on the given field mapping, and changes
 * the active file page ID.
 *
 * This is the only way to change the active file page ID aside from reinitializing, since
 * we always want to update the page field editor state when changing pages
 */
export const updatePageFieldEditorStateReducer = (
  state: DocumentEditorState,
  fieldMapping: Record<string, string>,
  newActiveFilePageId?: string,
): void => {
  if (state.activeFilePageId == null || state.job == null) {
    return
  }
  const filePageId = state.activeFilePageId
  const originalFieldMapping = consolidateFieldMappings(state.pageFieldEditorState, state.job)
  const changedFields: Record<string, string> = getChangedFields(fieldMapping, originalFieldMapping)

  // assign all changed fields with existing fields on the page and
  // update page field mapping
  const pageFields: Record<string, string> = {
    ...state.pageFieldEditorState!.pages[filePageId]?.fieldMapping,
    ...changedFields,
  }

  // remove existing field keys from other pages in case of duplicates
  removeExistingFieldKeysFromOtherPages(state, pageFields, filePageId)

  state.pageFieldEditorState!.pages[filePageId] = {
    fieldMapping: pageFields,
    fieldCoordinates: state.pageFieldEditorState!.pages[filePageId]?.fieldCoordinates,
  }
  if (newActiveFilePageId) {
    state.activeFilePageId = newActiveFilePageId
  }
}

export const selectActiveOcrData = createDraftSafeSelector(
  selectActiveFilePage,
  (activeFilePage) =>
    activeFilePage?.googleOcrData != null
      ? (JSON.parse(activeFilePage.googleOcrData) as GoogleOCRData)
      : null,
)
