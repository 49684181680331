import { FunctionComponent, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { Checkbox, FormControlLabel, TextField, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { Autocomplete } from '@material-ui/lab'
import { useLazyQuery, useQuery } from '@apollo/client'
import theme from '@src/utils/theme'
import {
  CargowiseConfigNode,
  CredentialNode,
  CwConnectorType,
  GeoLocationNode,
  JobTemplateNode,
  Query,
} from '@src/graphql/types'
import { USER_COMPANIES } from '@src/graphql/queries/company'
import { GET_JOB_TEMPLATES } from '@src/graphql/queries/jobTemplate'
import ControllerTextField from '@src/components/controller-text-field/ControllerTextField'
import ControllerErrorText from '@src/components/controller-error-text/ControllerErrorText'
import { GET_GEO_LOCATIONS } from '@src/graphql/queries/geoLocations'
import InfoPopover from '../InfoPopover'

export type CWConfigFormValues = {
  code: string
  countryCode: string
  enterpriseId: string
  serverId: string
  outboundPass: string | null
  dbUser: string | null
  dbPass: string | null
  dbName: string | null
  remoteDbHostname: string | null
  remoteDbPort: number | null
  pollDatabase: boolean
  proxyUrls: string[] | null
  connectorType: CwConnectorType | null
  externApiCredentialId: string | null
  companyId: string | null
  jobTemplates: JobTemplateNode[] | null
  forceFetch: boolean
  dbDirectConnect: boolean
  defaultTimezoneLocationId: string
}

type Props = {
  cwConfig?: CargowiseConfigNode | null
  credentialOptions: CredentialNode[]
  isOpen: boolean
  close: () => void
  onSubmit: (credential: CWConfigFormValues) => Promise<void>
}

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  submitButton: {
    textAlign: 'right',
  },
})

const CreateUpdateCWConfigDialog: FunctionComponent<Props> = ({
  cwConfig,
  credentialOptions,
  isOpen,
  close,
  onSubmit,
}) => {
  const classes = useStyles()
  const formMethods = useForm<CWConfigFormValues>()
  const { handleSubmit, reset, trigger } = formMethods
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: companies, loading: companiesLoading } =
    useQuery<Pick<Query, 'companies'>>(USER_COMPANIES)
  const { data: jobTemplates, loading: jobTemplatesLoading } =
    useQuery<Pick<Query, 'jobTemplates'>>(GET_JOB_TEMPLATES)

  const [geoLocationOptions, setGeoLocationOptions] = useState<Record<string, GeoLocationNode>>(
    cwConfig?.defaultTimezoneLocation?.id
      ? { [cwConfig?.defaultTimezoneLocation?.id]: cwConfig?.defaultTimezoneLocation }
      : {},
  )
  const [fetchGeoLocations] = useLazyQuery<Pick<Query, 'geoLocations'>>(GET_GEO_LOCATIONS, {
    onCompleted: ({ geoLocations }) => {
      setGeoLocationOptions(
        Object.fromEntries(geoLocations.map((geoLocation) => [geoLocation.id, geoLocation])),
      )
    },
  })

  const [defaultTimezoneLocationInput, setdefaultTimezoneLocationInput] = useState<
    string | null | undefined
  >(null)

  const isUpdating = !!cwConfig
  const companyOptions =
    cwConfig?.company && !companies?.companies?.find((company) => company.id === cwConfig?.id)
      ? [...(companies?.companies || []), cwConfig.company]
      : companies?.companies
  const jobTemplateOptions = jobTemplates?.jobTemplates || []

  useEffect(() => {
    if (defaultTimezoneLocationInput?.length && defaultTimezoneLocationInput.length >= 3) {
      void fetchGeoLocations({ variables: { query: defaultTimezoneLocationInput } })
    }
  }, [defaultTimezoneLocationInput, fetchGeoLocations])

  const submitForm = async (): Promise<void> => {
    const isFormValid = await trigger()
    if (!isFormValid) {
      return
    }
    setIsSubmitting(true)
    await handleSubmit(onSubmit)()
    setIsSubmitting(false)
    reset()
    close()
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialog }}
      onClose={close}
      open={isOpen}
    >
      <IconButton
        aria-label='close'
        onClick={close}
        disabled={isSubmitting}
        className={classes.close}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle disableTypography>
        <Typography variant='h3'>{`${isUpdating ? 'Update' : 'Create'} CW Config`}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box pb={2}>
          <FormProvider {...formMethods}>
            <Box mb={1}>
              <Typography>Code</Typography>
              <ControllerTextField
                name='code'
                friendlyName='Code'
                defaultValue={cwConfig?.code}
                rules={{ required: true }}
              />
            </Box>
            <Box mb={1}>
              <Box display='flex' gridColumnGap={8} alignItems='center' flex={1} minWidth={0}>
                <Typography>Country Code</Typography>
                <InfoPopover iconColor={theme.palette.grey[300]} width={450}>
                  <ul>
                    <li>
                      Please enter the country code of the company of this cargowise config - e.g.
                      US, PH, etc
                      <ul>
                        <li>
                          {
                            'User Admin > Companies > Select the company for this config > Take the country code'
                          }
                        </li>
                      </ul>
                    </li>
                    <li>
                      This field only affects FreightBI and Shipper Viz, but is required for all
                      customers for best practice
                    </li>
                    <li>
                      This field determines the direction of the shipments associated with the
                      config (whether it&apos;s import, export, or others)
                    </li>
                  </ul>
                </InfoPopover>
              </Box>
              <ControllerTextField
                name='countryCode'
                friendlyName='Country Code'
                defaultValue={cwConfig?.countryCode}
                rules={{ required: true }}
              />
            </Box>
            <Box mb={1}>
              <Typography>Enterprise ID</Typography>
              <ControllerTextField
                name='enterpriseId'
                friendlyName='Enterprise ID'
                defaultValue={cwConfig?.enterpriseId}
                rules={{ required: true }}
              />
            </Box>
            <Box mb={1}>
              <Typography>Server ID</Typography>
              <ControllerTextField
                name='serverId'
                friendlyName='Server ID'
                defaultValue={cwConfig?.serverId}
                rules={{ required: true }}
              />
            </Box>
            <Box mb={1}>
              <Typography>Outbound Pass</Typography>
              <ControllerTextField
                name='outboundPass'
                friendlyName='Outbound Pass'
                defaultValue={cwConfig?.outboundPass || ''}
              />
            </Box>
            <Box mb={1}>
              <Typography>DB User</Typography>
              <ControllerTextField
                name='dbUser'
                friendlyName='DB User'
                defaultValue={cwConfig?.dbUser}
              />
            </Box>
            <Box mb={1}>
              <Typography>DB Pass</Typography>
              <ControllerTextField
                name='dbPass'
                friendlyName='DB Pass'
                defaultValue={cwConfig?.dbPass || ''}
              />
            </Box>
            <Box mb={1}>
              <Typography>DB Name</Typography>
              <ControllerTextField
                name='dbName'
                friendlyName='DB Name'
                defaultValue={cwConfig?.dbName}
              />
            </Box>
            <Box mb={1}>
              <Typography>Remote DB Host Name</Typography>
              <ControllerTextField
                name='remoteDbHostname'
                friendlyName='Remote DB Host Name'
                defaultValue={cwConfig?.remoteDbHostname}
              />
            </Box>
            <Box mb={1}>
              <Typography>Remote DB Port</Typography>
              <ControllerTextField
                name='remoteDbPort'
                friendlyName='Remote DB Port'
                defaultValue={cwConfig?.remoteDbPort}
                type='number'
                rules={{ max: 65535, min: 1024 }}
              />
              <Typography variant='body2' gutterBottom>
                Must range from 1024 to 65535
              </Typography>
            </Box>
            <Box mb={1}>
              <Controller
                render={({ field: { ref, value, ...rest } }) => (
                  <>
                    <FormControlLabel
                      control={<Checkbox inputRef={ref} checked={value} {...rest} />}
                      label='DB Direct Connect (DO NOT SET UNLESS TOLD BY ENGINEERING)'
                    />
                  </>
                )}
                name={`dbDirectConnect`}
                defaultValue={cwConfig?.dbDirectConnect}
              />
            </Box>
            <Box mb={1}>
              <Controller
                render={({ field: { ref, value, onChange, ...rest } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputRef={ref}
                        checked={value}
                        disabled={cwConfig?.connectorType !== CwConnectorType.Db}
                        onChange={(_, value) => onChange(value)}
                        {...rest}
                      />
                    }
                    label='Poll Database'
                  />
                )}
                name={`pollDatabase`}
                defaultValue={cwConfig?.pollDatabase}
              />
            </Box>
            <Box mb={2}>
              <Typography>Proxy URLS</Typography>
              <Controller
                render={({ field: { value, onBlur, onChange } }) => (
                  <Autocomplete
                    multiple
                    freeSolo
                    disabled={jobTemplatesLoading}
                    options={cwConfig?.proxyUrls || []}
                    onChange={(_, value) => onChange(value)}
                    onBlur={onBlur}
                    defaultValue={value}
                    renderInput={(params) => <TextField {...params} variant='standard' />}
                  />
                )}
                name='proxyUrls'
                defaultValue={cwConfig?.proxyUrls || []}
              />
              <Typography variant='body2' gutterBottom>
                Press enter to add a Proxy URL
              </Typography>
            </Box>
            <Box mb={2}>
              <Box display='flex' gridColumnGap={8} alignItems='center' flex={1} minWidth={0}>
                <Typography>Default Timezone </Typography>
                <InfoPopover iconColor={theme.palette.grey[300]} width={450}>
                  <ul>
                    <li>
                      Please enter the home port of the main branch of this cargowise config - e.g.
                      USNYC, PHMNL, etc
                      <ul>
                        <li>
                          {
                            'User Admin > Companies > Select the company for this config > Take the home port of the main branch'
                          }
                        </li>
                      </ul>
                    </li>
                    <li>
                      There are no fields within CW that allow setting of a time zone along with the
                      date and time. Therefore it&apos;s impossible for us to know the time zone
                      info just from fetching/parsing data from Cargowise alone. For most cases, we
                      will infer the time zone of the datetime field based on the port it is
                      associated with (e.g. shipment leg departure date &lt;&gt; shipment leg origin
                      port), but for datetime fields without associated ports (e.g. milestone
                      estimated date and milestone actual date for eadaptor), we will refer to the
                      time zone configured here on the config level.
                    </li>
                    <li>
                      This field only affects FreightBI and Shipper Viz, but it&apos;s required for
                      all configs as best practice.
                    </li>
                  </ul>
                </InfoPopover>
              </Box>
              <Controller
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <>
                    <Autocomplete
                      data-testid='default-timezone'
                      options={Object.keys(geoLocationOptions) || []}
                      getOptionLabel={(geoLocationId) => {
                        const port =
                          geoLocationOptions[geoLocationId] ?? cwConfig?.defaultTimezoneLocation
                        return port
                          ? `[${port.code}] ${port.locodeName} (${port.timezoneName})`
                          : ''
                      }}
                      onBlur={onBlur}
                      onChange={(_, port) => {
                        onChange(port)
                      }}
                      defaultValue={value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => {
                            setdefaultTimezoneLocationInput(e!.target!.value!)
                          }}
                        />
                      )}
                    />
                    <ControllerErrorText friendlyName='Default Timezone' error={error} />
                  </>
                )}
                name='defaultTimezoneLocationId'
                defaultValue={cwConfig?.defaultTimezoneLocationId}
                rules={{ required: true }}
              />
            </Box>
            <Box mb={1}>
              <Typography>Connector Type</Typography>
              <Controller
                render={({ field: { ref, value, ...rest }, fieldState: { invalid, error } }) => (
                  <>
                    <Select
                      inputRef={ref}
                      margin='dense'
                      error={invalid}
                      value={value ?? ''}
                      {...rest}
                      fullWidth
                    >
                      {Object.values(CwConnectorType).map((connectorType) => (
                        <MenuItem key={connectorType} value={connectorType}>
                          {connectorType}
                        </MenuItem>
                      ))}
                    </Select>
                    <ControllerErrorText friendlyName='Connector Type' error={error} />
                  </>
                )}
                name='connectorType'
                defaultValue={cwConfig?.connectorType}
              />
            </Box>
            <Box mb={1}>
              <Typography>Extern API Credential</Typography>
              <Controller
                render={({ field: { ref, value, ...rest }, fieldState: { invalid, error } }) => (
                  <>
                    <Select
                      inputRef={ref}
                      margin='dense'
                      error={invalid}
                      value={value ?? ''}
                      {...rest}
                      fullWidth
                    >
                      {credentialOptions.map((credential) => (
                        <MenuItem key={credential.id} value={credential.id}>
                          {`${credential.username} - ${credential.workflow}`}
                        </MenuItem>
                      ))}
                    </Select>
                    <ControllerErrorText friendlyName='Extern API Credential' error={error} />
                  </>
                )}
                name='externApiCredentialId'
                defaultValue={cwConfig?.externApiCredential?.id}
              />
            </Box>
            <Box mb={1}>
              <Controller
                render={({ field: { ref, value, ...rest } }) => (
                  <>
                    <FormControlLabel
                      control={<Checkbox inputRef={ref} checked={value} {...rest} />}
                      label='Force Fetch'
                    />
                  </>
                )}
                name={`forceFetch`}
                defaultValue={cwConfig?.forceFetch}
              />
            </Box>
            <Box mb={1}>
              <Typography>Company</Typography>
              <Controller
                render={({ field: { ref, value, ...rest }, fieldState: { invalid, error } }) => (
                  <>
                    <Select
                      disabled={companiesLoading}
                      inputRef={ref}
                      margin='dense'
                      error={invalid}
                      value={value ?? ''}
                      {...rest}
                      fullWidth
                    >
                      {companyOptions?.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ControllerErrorText friendlyName='Company' error={error} />
                  </>
                )}
                name='companyId'
                defaultValue={cwConfig?.company?.id}
              />
            </Box>
            <Box mb={2}>
              <Typography>Job Template</Typography>
              <Controller
                render={({ field: { value, onBlur, onChange } }) => (
                  <Autocomplete
                    multiple
                    disabled={jobTemplatesLoading}
                    options={jobTemplateOptions}
                    onChange={(_, value) => onChange(value)}
                    onBlur={onBlur}
                    getOptionLabel={(option: JobTemplateNode) => option.name}
                    defaultValue={value}
                    renderInput={(params) => <TextField {...params} variant='standard' />}
                  />
                )}
                name='jobTemplates'
                defaultValue={cwConfig?.jobTemplates?.edges.map(
                  (jobTemplate) => jobTemplate!.node!,
                )}
              />
            </Box>
            <Box className={classes.submitButton}>
              <Button
                size='large'
                color='primary'
                variant='contained'
                onClick={submitForm}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CreateUpdateCWConfigDialog
