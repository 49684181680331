import { BusinessPerformanceTab, ExploreTab } from '@src/graphql/types'

/**
 * TODO: Create a single source of truth for these constants if possible.
 * We're currently maintaining two copies of these constants/enums for each product.
 * We should consolidate them into a single source of truth or generate
 * them via `yarn generate`.
 */

// Sync this up with dashboard/src/freight-bi/accounting/Accounting.tsx
export enum AccountingTab {
  Overview = 'ACCOUNTING_OVERVIEW',
  Billing = 'ACCOUNTING_BILLING',
  Margins = 'ACCOUNTING_MARGINS',
}

// Sync this up with src/dashboard/DashboardWithSidebar.tsx
export enum DashboardTab {
  MainDashboard = 'MAIN_DASHBOARD',
  JobSummary = 'JOB_SUMMARY',
  ReconJobList = 'RECON_JOB_LIST',
}

// Sync this up with dashboard/src/freight-bi/operations/Operations.tsx
export enum OperationsTab {
  Shipments = 'SHIPMENTS',
  NEGATIVE_MARGIN_SHIPMENTS = 'NEGATIVE_MARGIN_SHIPMENTS',
}

// Sync this up with dashboard/src/freight-bi/sales/Sales.tsx
export enum SalesTab {
  SalesOverview = 'SALES_OVERVIEW',
}

// Sync this up with dashboard/src/freight-bi/accounting/Accounting.tsx
export const accountingTabToDisplayMap: Record<string, string> = {
  [AccountingTab.Overview]: 'Overview',
  [AccountingTab.Billing]: 'Billing',
  [AccountingTab.Margins]: 'Margins',
}

// Sync this up with dashboard/src/freight-bi/business-performance/businessPerformanceTabToDisplayMap.tsx
export const businessPerformanceTabToDisplayMap: Record<string, string> = {
  [BusinessPerformanceTab.Overview]: 'Overview',
  [BusinessPerformanceTab.AccountOverview]: 'Account Overview',
  [BusinessPerformanceTab.BranchOverview]: 'Branch Overview',
  [BusinessPerformanceTab.DepartmentOverview]: 'Department Overview',
  [BusinessPerformanceTab.Revenue]: 'Revenue',
  [BusinessPerformanceTab.Expenses]: 'Expenses',
  [BusinessPerformanceTab.Margins]: 'Margins',
  [BusinessPerformanceTab.MostProfitableRoutes]: 'Most Profitable Routes',
  [BusinessPerformanceTab.TopExpenses]: 'Top Expenses',
}

// Sync this up with src/dashboard/DashboardWithSidebar.tsx
export const dashboardTabToDisplayMap: Record<string, string> = {
  [DashboardTab.MainDashboard]: 'Dashboard',
  [DashboardTab.JobSummary]: 'Job Summary',
  [DashboardTab.ReconJobList]: 'To-Do Dashboard',
}

// Sync this up with dashboard/src/freight-bi/explore/Explore.tsx
export const exploreTabToDisplayMap: Record<string, string> = {
  [ExploreTab.ExploreShipments]: 'Explore Shipments',
  [ExploreTab.ExploreContainers]: 'Explore Containers',
  [ExploreTab.ShipmentDetails]: 'Shipment Details',
  [ExploreTab.PayableInvoices]: 'Payable Invoices',
  [ExploreTab.ReceivableInvoices]: 'Receivable Invoices',
  [ExploreTab.ExploreOrganizations]: 'Explore Organizations',
}

// Sync this up with dashboard/src/freight-bi/operations/Operations.tsx
export const operationsTabToDisplayMap: Record<string, string> = {
  [OperationsTab.Shipments]: 'Shipment Volume',
  [OperationsTab.NEGATIVE_MARGIN_SHIPMENTS]: 'Negative Profit Shipments',
}

// Sync this up with dashboard/src/freight-bi/sales/Sales.tsx
export const salesTabToDisplayMap: Record<string, string> = {
  [SalesTab.SalesOverview]: 'Overview',
}
