import { gql } from '@apollo/client'

export const GET_NOTIFICATIONS = gql`
  query notifications {
    notifications {
      id
      dateRead
      type
      pageAsyncBatch {
        id
        numDone
        numTasks
        errorMessage
        dateCompleted
        status
        type
        job {
          id
          status
          name
        }
        pageAsyncTasks {
          edges {
            node {
              id
              filePage {
                id
                pageNumber
                file {
                  id
                  filename
                  emailSubject
                }
              }
            }
          }
        }
      }
    }
  }
`
