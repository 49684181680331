import { z } from 'zod'
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** Date (isoformat) */
  Date: { input: string; output: string }
  /** Date with time (isoformat) */
  DateTime: { input: string; output: string }
  /** Decimal (fixed-point) */
  Decimal: { input: string; output: string }
  EncodedExploreContainerColumnV2: { input: any; output: any }
  EncodedExploreOrganizationColumnV2: { input: any; output: any }
  EncodedExplorePayableInvoiceColumnV2: { input: any; output: any }
  EncodedExploreReceivableInvoiceColumnV2: { input: any; output: any }
  EncodedExploreShipmentColumnV2: { input: any; output: any }
  /** The GenericScalar scalar type represents a generic GraphQL scalar value that could be: List or Object. */
  GenericScalar: { input: any; output: any }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any }
  /** GenericScalar, but serializes to a string */
  JSONString: { input: string; output: string }
  UUID: { input: string; output: string }
  /** Represents NULL values */
  Void: { input: null | undefined; output: null | undefined }
}

export enum AnReconAutofillKey {
  AcceptedDate = 'ACCEPTED_DATE',
  Branch = 'BRANCH',
  Carrier = 'CARRIER',
  ChargeCode = 'CHARGE_CODE',
  ChargeLineItem = 'CHARGE_LINE_ITEM',
  Commodity = 'COMMODITY',
  ConsolNumber = 'CONSOL_NUMBER',
  ContainerLineItem = 'CONTAINER_LINE_ITEM',
  ContainerNumber = 'CONTAINER_NUMBER',
  ContainerSize = 'CONTAINER_SIZE',
  ContainerType = 'CONTAINER_TYPE',
  Creditor = 'CREDITOR',
  Currency = 'CURRENCY',
  Department = 'DEPARTMENT',
  IsPremiumRate = 'IS_PREMIUM_RATE',
  MblNumber = 'MBL_NUMBER',
  NamedAccount = 'NAMED_ACCOUNT',
  PlaceOfDelivery = 'PLACE_OF_DELIVERY',
  PlaceOfReceipt = 'PLACE_OF_RECEIPT',
  PortOfDischarge = 'PORT_OF_DISCHARGE',
  PortOfLoading = 'PORT_OF_LOADING',
  PremiumRate = 'PREMIUM_RATE',
  PremiumRateContainerQty = 'PREMIUM_RATE_CONTAINER_QTY',
  PremiumTotalAccessorialFees = 'PREMIUM_TOTAL_ACCESSORIAL_FEES',
  Quantity = 'QUANTITY',
  ShipmentRoute = 'SHIPMENT_ROUTE',
  TotalAmount = 'TOTAL_AMOUNT',
  UnitPrice = 'UNIT_PRICE',
  Vessel = 'VESSEL',
  VoyageNumber = 'VOYAGE_NUMBER',
}

export enum ApReconAutofillKey {
  ApportionmentMethod = 'APPORTIONMENT_METHOD',
  Branch = 'BRANCH',
  CargowiseModule = 'CARGOWISE_MODULE',
  CarrierBookingNumber = 'CARRIER_BOOKING_NUMBER',
  ChargeCode = 'CHARGE_CODE',
  ChargeCost = 'CHARGE_COST',
  ChargeDescription = 'CHARGE_DESCRIPTION',
  ConsolNumber = 'CONSOL_NUMBER',
  ContainerNumber = 'CONTAINER_NUMBER',
  Currency = 'CURRENCY',
  Debtor = 'DEBTOR',
  Department = 'DEPARTMENT',
  DocumentReceivedDate = 'DOCUMENT_RECEIVED_DATE',
  DueDate = 'DUE_DATE',
  EmailSender = 'EMAIL_SENDER',
  ErrorNotes = 'ERROR_NOTES',
  ExpedockAction = 'EXPEDOCK_ACTION',
  ForwarderReference = 'FORWARDER_REFERENCE',
  HblNumber = 'HBL_NUMBER',
  InvoiceDate = 'INVOICE_DATE',
  InvoiceForwarderReference = 'INVOICE_FORWARDER_REFERENCE',
  InvoiceLineBranch = 'INVOICE_LINE_BRANCH',
  InvoiceLineDepartment = 'INVOICE_LINE_DEPARTMENT',
  InvoiceLineItem = 'INVOICE_LINE_ITEM',
  InvoiceNumber = 'INVOICE_NUMBER',
  InvoiceTmsId = 'INVOICE_TMS_ID',
  MblNumber = 'MBL_NUMBER',
  OperationsRep = 'OPERATIONS_REP',
  OrderNumber = 'ORDER_NUMBER',
  Quantity = 'QUANTITY',
  ReferenceNumber = 'REFERENCE_NUMBER',
  SalesRep = 'SALES_REP',
  SellAmount = 'SELL_AMOUNT',
  SellCurrency = 'SELL_CURRENCY',
  ShipmentTmsId = 'SHIPMENT_TMS_ID',
  TaxId = 'TAX_ID',
  TmsId = 'TMS_ID',
  UnitPrice = 'UNIT_PRICE',
  Vendor = 'VENDOR',
  VendorType = 'VENDOR_TYPE',
  VoyageNumber = 'VOYAGE_NUMBER',
}

export enum ApReconPrimaryAutofillKey {
  CarrierBookingNumber = 'CARRIER_BOOKING_NUMBER',
  ConsolNumber = 'CONSOL_NUMBER',
  ContainerNumber = 'CONTAINER_NUMBER',
  HblNumber = 'HBL_NUMBER',
  MblNumber = 'MBL_NUMBER',
  OrderNumber = 'ORDER_NUMBER',
  ReferenceNumber = 'REFERENCE_NUMBER',
}

export enum ApReconSecondaryAutofillKey {
  ChargeCode = 'CHARGE_CODE',
  ChargeCost = 'CHARGE_COST',
  ChargeDescription = 'CHARGE_DESCRIPTION',
  Currency = 'CURRENCY',
  DueDate = 'DUE_DATE',
  InvoiceDate = 'INVOICE_DATE',
  InvoiceNumber = 'INVOICE_NUMBER',
}

export enum AccountingMarginsGraph {
  Account = 'Account',
  Branch = 'Branch',
  Margin = 'Margin',
  MarginPercent = 'MarginPercent',
  PostedMargin = 'PostedMargin',
  PostedMarginPercent = 'PostedMarginPercent',
}

export enum AccountingTab {
  AccountingBilling = 'ACCOUNTING_BILLING',
  AccountingCollections = 'ACCOUNTING_COLLECTIONS',
  AccountingMargins = 'ACCOUNTING_MARGINS',
  AccountingOverview = 'ACCOUNTING_OVERVIEW',
}

export type AddDocumentTable = {
  __typename?: 'AddDocumentTable'
  documentTable: DocumentTableNode
  prevTableId?: Maybe<Scalars['String']['output']>
}

export type AdminUserProfileNode = {
  __typename?: 'AdminUserProfileNode'
  auth0Id: Scalars['String']['output']
  branch?: Maybe<Scalars['String']['output']>
  branches: Array<Scalars['String']['output']>
  canExportDataAsCsv: Scalars['Boolean']['output']
  companies: Array<CompanyNode>
  company?: Maybe<CompanyNode>
  dateCreated?: Maybe<Scalars['DateTime']['output']>
  defaultDashboardsWhitelist: Array<Scalars['String']['output']>
  department?: Maybe<Scalars['String']['output']>
  departments: Array<Scalars['String']['output']>
  email: Scalars['String']['output']
  lastLogin?: Maybe<Scalars['DateTime']['output']>
  metabaseDashboardId?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  onlyViewAssignedShipments: Scalars['Boolean']['output']
  onlyViewDashboardCompany: Scalars['Boolean']['output']
  operationsRep?: Maybe<Scalars['String']['output']>
  operationsReps: Array<Scalars['String']['output']>
  partnerCompanies: Array<CompanyNode>
  roles: Array<UserRole>
  salesRep?: Maybe<Scalars['String']['output']>
  salesReps: Array<Scalars['String']['output']>
  uuid?: Maybe<Scalars['UUID']['output']>
}

export type ApiEDocumentFilePageNode = {
  __typename?: 'ApiEDocumentFilePageNode'
  apiEDocument: ApiEDocumentNode
  apiEDocumentId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  filePage: FilePageNode
  filePageId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
}

export type ApiEDocumentFilePageNodeConnection = {
  __typename?: 'ApiEDocumentFilePageNodeConnection'
  edges: Array<ApiEDocumentFilePageNodeEdge>
}

export type ApiEDocumentFilePageNodeEdge = {
  __typename?: 'ApiEDocumentFilePageNodeEdge'
  node: ApiEDocumentFilePageNode
}

export type ApiEDocumentNode = {
  __typename?: 'ApiEDocumentNode'
  apiEDocumentFilePages: ApiEDocumentFilePageNodeConnection
  apiExportEdocActivity?: Maybe<ApiExportEDocActivityNode>
  apiExportEdocActivityId?: Maybe<Scalars['UUID']['output']>
  apiImportEdocActivity?: Maybe<ApiImportEDocActivityNode>
  apiImportEdocActivityId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  eDocUrl?: Maybe<Scalars['String']['output']>
  eDocUrlExpiry?: Maybe<Scalars['DateTime']['output']>
  filePages: FilePageNodeConnection
  filename?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  s3Uri?: Maybe<Scalars['String']['output']>
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type ApiEDocumentNodeConnection = {
  __typename?: 'ApiEDocumentNodeConnection'
  edges: Array<ApiEDocumentNodeEdge>
}

export type ApiEDocumentNodeEdge = {
  __typename?: 'ApiEDocumentNodeEdge'
  node: ApiEDocumentNode
}

export enum ApiEntityType {
  BatchInvoices = 'BatchInvoices',
  CommercialInvoice = 'CommercialInvoice',
  Consolidation = 'Consolidation',
  CustomsDeclaration = 'CustomsDeclaration',
  Invoice = 'Invoice',
  Shipment = 'Shipment',
}

export type ApiExportEDocActivityNode = {
  __typename?: 'ApiExportEDocActivityNode'
  apiEDocuments: ApiEDocumentNodeConnection
  apiResponse?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  note?: Maybe<NoteNode>
  statusCode?: Maybe<Scalars['Int']['output']>
  success: Scalars['Boolean']['output']
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type ApiExportJobActivityNode = {
  __typename?: 'ApiExportJobActivityNode'
  apiExportType: ApiExportType
  apiResponse?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  jsonDataSent: Scalars['JSONString']['output']
  note?: Maybe<NoteNode>
  statusCode?: Maybe<Scalars['Int']['output']>
  success: Scalars['Boolean']['output']
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export enum ApiExportType {
  BatchInvoices = 'BatchInvoices',
  CommercialInvoice = 'CommercialInvoice',
  Consolidation = 'Consolidation',
  CustomsDeclaration = 'CustomsDeclaration',
  GenericJson = 'GenericJson',
  Invoice = 'Invoice',
  PostInvoice = 'PostInvoice',
  Shipment = 'Shipment',
}

export type ApiImportEDocActivityNode = {
  __typename?: 'ApiImportEDocActivityNode'
  apiEDocuments: ApiEDocumentNodeConnection
  apiEntityType: ApiEntityType
  apiPartner: ApiPartnerInterface
  apiPartnerId: Scalars['UUID']['output']
  apiResponse?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  note?: Maybe<NoteNode>
  statusCode?: Maybe<Scalars['Int']['output']>
  success: Scalars['Boolean']['output']
  tmsId?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type ApiIngestionAttachmentNode = {
  __typename?: 'ApiIngestionAttachmentNode'
  apiPayload: ApiPayloadNode
  apiPayloadId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  s3Uri: Scalars['String']['output']
}

export type ApiIngestionAttachmentNodeConnection = {
  __typename?: 'ApiIngestionAttachmentNodeConnection'
  edges: Array<ApiIngestionAttachmentNodeEdge>
}

export type ApiIngestionAttachmentNodeEdge = {
  __typename?: 'ApiIngestionAttachmentNodeEdge'
  node: ApiIngestionAttachmentNode
}

export enum ApiPartnerCode {
  CargowiseConfig = 'CARGOWISE_CONFIG',
  Chainio = 'CHAINIO',
  LegacyApiPartner = 'LEGACY_API_PARTNER',
  SyncReconMapping = 'SYNC_RECON_MAPPING',
  UniversalApi = 'UNIVERSAL_API',
}

export type ApiPartnerCodeEnum = {
  __typename?: 'ApiPartnerCodeEnum'
  isFallback: Scalars['Boolean']['output']
  value: ApiPartnerCode
}

export type ApiPartnerCodeWithFallback = ApiPartnerCodeEnum | FallbackValue

export type ApiPartnerInterface = {
  apiKey: Scalars['UUID']['output']
  apiPartnerCode: ApiPartnerCodeWithFallback
  apiPartnerType: ApiPartnerType
  authValues?: Maybe<Scalars['JSONString']['output']>
  chainIoCommercialInvoices: ChainIoCommercialInvoiceNodeConnection
  chainIoConsolidations: ChainIoConsolidationNodeConnection
  chainIoCustomsDeclarations: ChainIoCustomsDeclarationNodeConnection
  chainIoServiceInvoices: ChainIoServiceInvoiceNodeConnection
  chainIoShipments: ChainIoShipmentNodeConnection
  chainIoTransports: ChainIoTransportNodeConnection
  company?: Maybe<CompanyNode>
  companyId?: Maybe<Scalars['UUID']['output']>
  countryCode?: Maybe<Scalars['String']['output']>
  credential?: Maybe<CredentialNode>
  credentialId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentExportEndpoint?: Maybe<Scalars['String']['output']>
  documentImportEndpoint?: Maybe<Scalars['String']['output']>
  expedockDocIngestEmail?: Maybe<Scalars['String']['output']>
  exportEndpoint?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  importEndpoint?: Maybe<Scalars['String']['output']>
  jobTemplates: JobTemplateNodeConnection
  name: Scalars['String']['output']
  taxes: TaxNodeConnection
  tmsType?: Maybe<ApiPartnerTmsTypeWithFallback>
  usesChargeCodeV2?: Maybe<Scalars['Boolean']['output']>
}

export type ApiPartnerInterfaceConnection = {
  __typename?: 'ApiPartnerInterfaceConnection'
  edges: Array<ApiPartnerInterfaceEdge>
}

export type ApiPartnerInterfaceEdge = {
  __typename?: 'ApiPartnerInterfaceEdge'
  node: ApiPartnerInterface
}

export type ApiPartnerNode = ApiPartnerInterface & {
  __typename?: 'ApiPartnerNode'
  apiKey: Scalars['UUID']['output']
  apiPartnerCode: ApiPartnerCodeWithFallback
  apiPartnerType: ApiPartnerType
  authValues?: Maybe<Scalars['JSONString']['output']>
  chainIoCommercialInvoices: ChainIoCommercialInvoiceNodeConnection
  chainIoConsolidations: ChainIoConsolidationNodeConnection
  chainIoCustomsDeclarations: ChainIoCustomsDeclarationNodeConnection
  chainIoServiceInvoices: ChainIoServiceInvoiceNodeConnection
  chainIoShipments: ChainIoShipmentNodeConnection
  chainIoTransports: ChainIoTransportNodeConnection
  company?: Maybe<CompanyNode>
  companyId?: Maybe<Scalars['UUID']['output']>
  countryCode?: Maybe<Scalars['String']['output']>
  credential?: Maybe<CredentialNode>
  credentialId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentExportEndpoint?: Maybe<Scalars['String']['output']>
  documentImportEndpoint?: Maybe<Scalars['String']['output']>
  expedockDocIngestEmail?: Maybe<Scalars['String']['output']>
  exportEndpoint?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  importEndpoint?: Maybe<Scalars['String']['output']>
  jobTemplates: JobTemplateNodeConnection
  name: Scalars['String']['output']
  taxes: TaxNodeConnection
  tmsType?: Maybe<ApiPartnerTmsTypeWithFallback>
  usesChargeCodeV2?: Maybe<Scalars['Boolean']['output']>
}

export enum ApiPartnerTmsType {
  Magaya = 'MAGAYA',
}

export type ApiPartnerTmsTypeEnum = {
  __typename?: 'ApiPartnerTmsTypeEnum'
  isFallback: Scalars['Boolean']['output']
  value: ApiPartnerTmsType
}

export type ApiPartnerTmsTypeWithFallback = ApiPartnerTmsTypeEnum | FallbackValue

export enum ApiPartnerType {
  Live = 'LIVE',
  Test = 'TEST',
}

export type ApiPayloadNode = {
  __typename?: 'ApiPayloadNode'
  apiIngestionAttachments: ApiIngestionAttachmentNodeConnection
  apiPartner: ApiPartnerInterface
  apiPartnerId: Scalars['UUID']['output']
  apiRequestPayloadUri?: Maybe<Scalars['String']['output']>
  apiResponsePayload: Scalars['JSONString']['output']
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoSi?: Maybe<ChainIoServiceInvoiceNode>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  statusCode: Scalars['Int']['output']
}

export type ArrivalNoticeChargeRateReconResultNode = ReconResultInterface & {
  __typename?: 'ArrivalNoticeChargeRateReconResultNode'
  amendmentNo?: Maybe<Scalars['Int']['output']>
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  chargeCodeMatchesFound?: Maybe<Scalars['Int']['output']>
  chargeContract?: Maybe<ChargeContractNode>
  chargeContractId?: Maybe<Scalars['UUID']['output']>
  chargeRate?: Maybe<ChargeRateNode>
  chargeRateId?: Maybe<Scalars['UUID']['output']>
  commodityMatchesFound?: Maybe<Scalars['Int']['output']>
  containerSizeMatchesFound?: Maybe<Scalars['Int']['output']>
  containerTypeMatchesFound?: Maybe<Scalars['Int']['output']>
  currencyMatchesFound?: Maybe<Scalars['Int']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentChargeCode?: Maybe<Scalars['String']['output']>
  documentChargeCost?: Maybe<Scalars['Decimal']['output']>
  documentCommodity?: Maybe<Scalars['String']['output']>
  documentContainerSize?: Maybe<Scalars['String']['output']>
  documentContainerType?: Maybe<Scalars['String']['output']>
  documentCurrency?: Maybe<Scalars['String']['output']>
  documentNamedAccount?: Maybe<Scalars['String']['output']>
  documentPlaceOfDelivery?: Maybe<Scalars['String']['output']>
  documentPlaceOfReceipt?: Maybe<Scalars['String']['output']>
  documentPortOfDischarge?: Maybe<Scalars['String']['output']>
  documentPortOfLoading?: Maybe<Scalars['String']['output']>
  documentQuantity?: Maybe<Scalars['Decimal']['output']>
  documentShipmentRoute?: Maybe<Scalars['String']['output']>
  documentTotalAmount?: Maybe<Scalars['Decimal']['output']>
  expectedChargeCode?: Maybe<Scalars['String']['output']>
  expectedChargeCost?: Maybe<Scalars['Decimal']['output']>
  expectedCommodity?: Maybe<Scalars['String']['output']>
  expectedContainerSize?: Maybe<Scalars['String']['output']>
  expectedContainerType?: Maybe<Scalars['String']['output']>
  expectedCurrency?: Maybe<Scalars['String']['output']>
  expectedNamedAccount?: Maybe<Scalars['String']['output']>
  expectedPlaceOfDelivery?: Maybe<Scalars['String']['output']>
  expectedPlaceOfReceipt?: Maybe<Scalars['String']['output']>
  expectedPortOfDischarge?: Maybe<Scalars['String']['output']>
  expectedPortOfLoading?: Maybe<Scalars['String']['output']>
  expectedShipmentRoute?: Maybe<Scalars['String']['output']>
  expectedTotalAmount?: Maybe<Scalars['Decimal']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  namedAccountMatchesFound?: Maybe<Scalars['Int']['output']>
  placeOfDeliveryMatchesFound?: Maybe<Scalars['Int']['output']>
  placeOfDeliverySimilarRateLocations: Array<RateLocationStringNode>
  placeOfReceiptMatchesFound?: Maybe<Scalars['Int']['output']>
  placeOfReceiptSimilarRateLocations: Array<RateLocationStringNode>
  portOfDischargeMatchesFound?: Maybe<Scalars['Int']['output']>
  portOfDischargeSimilarRateLocations: Array<RateLocationStringNode>
  portOfLoadingMatchesFound?: Maybe<Scalars['Int']['output']>
  portOfLoadingSimilarRateLocations: Array<RateLocationStringNode>
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  shipmentRouteMatchesFound?: Maybe<Scalars['Int']['output']>
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type ArrivalNoticeConsolTypeReconResultNode = ReconResultInterface & {
  __typename?: 'ArrivalNoticeConsolTypeReconResultNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentCarrierCode?: Maybe<Scalars['String']['output']>
  documentConsolType?: Maybe<Scalars['String']['output']>
  documentContainerType?: Maybe<Scalars['String']['output']>
  documentCreditorCode?: Maybe<Scalars['String']['output']>
  expectedCarrierCode?: Maybe<Scalars['String']['output']>
  expectedConsolType?: Maybe<Scalars['String']['output']>
  expectedContainerType?: Maybe<Scalars['String']['output']>
  expectedCreditorCode?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type ArrivalNoticeContainerReconResultNode = ReconResultInterface & {
  __typename?: 'ArrivalNoticeContainerReconResultNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoContainer?: Maybe<ChainIoContainerNode>
  chainIoContainerId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentCommodity?: Maybe<Scalars['String']['output']>
  documentContainerNo?: Maybe<Scalars['String']['output']>
  documentContainerTypeCode?: Maybe<Scalars['String']['output']>
  expectedContainerNo?: Maybe<Scalars['String']['output']>
  expectedContainerTypeCode?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type ArrivalNoticeContractReconResultNode = ReconResultInterface & {
  __typename?: 'ArrivalNoticeContractReconResultNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  chargeContract?: Maybe<ChargeContractNode>
  chargeContractId?: Maybe<Scalars['UUID']['output']>
  clientContractRefNo?: Maybe<Scalars['String']['output']>
  contractId?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentAcceptedDate?: Maybe<Scalars['DateTime']['output']>
  documentVendorCode?: Maybe<Scalars['String']['output']>
  effectivity?: Maybe<Scalars['DateTime']['output']>
  expiryDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type ArrivalNoticeLumpsumReconResultNode = ReconResultInterface & {
  __typename?: 'ArrivalNoticeLumpsumReconResultNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentLumpsum?: Maybe<Scalars['Decimal']['output']>
  expectedLumpsum?: Maybe<Scalars['Decimal']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  premiumRate?: Maybe<Scalars['Decimal']['output']>
  premiumRateContainerQty?: Maybe<Scalars['Int']['output']>
  premiumTotalAccessorialFees?: Maybe<Scalars['Decimal']['output']>
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type ArrivalNoticeMetadataReconResultNode = ReconResultInterface & {
  __typename?: 'ArrivalNoticeMetadataReconResultNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  chainIoShipmentLeg?: Maybe<ChainIoShipmentLegNode>
  chainIoShipmentLegId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentValue?: Maybe<Scalars['String']['output']>
  expectedValue?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  metadataKey?: Maybe<Scalars['String']['output']>
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type AssociatePagesToJob = {
  __typename?: 'AssociatePagesToJob'
  job: JobNode
}

export type AuthProfile = {
  __typename?: 'AuthProfile'
  auth0Id?: Maybe<Scalars['String']['output']>
  branch?: Maybe<Scalars['String']['output']>
  branches: Array<Scalars['String']['output']>
  canExportDataAsCsv?: Maybe<Scalars['Boolean']['output']>
  company?: Maybe<CompanyNode>
  companyId?: Maybe<Scalars['UUID']['output']>
  defaultDashboardsWhitelist?: Maybe<Array<Scalars['String']['output']>>
  department?: Maybe<Scalars['String']['output']>
  departments: Array<Scalars['String']['output']>
  email: Scalars['String']['output']
  metabaseDashboardId?: Maybe<Scalars['UUID']['output']>
  name: Scalars['String']['output']
  onlyViewAssignedShipments?: Maybe<Scalars['Boolean']['output']>
  operationsRep?: Maybe<Scalars['String']['output']>
  operationsReps: Array<Scalars['String']['output']>
  partnerCompanyIds?: Maybe<Array<Scalars['UUID']['output']>>
  roles: Array<UserRole>
  salesRep?: Maybe<Scalars['String']['output']>
  salesReps: Array<Scalars['String']['output']>
}

export type AuthProfileUpdate = {
  __typename?: 'AuthProfileUpdate'
  ok: Scalars['Boolean']['output']
}

export type AutoReconAsyncBatchNode = {
  __typename?: 'AutoReconAsyncBatchNode'
  company: CompanyNode
  companyId: Scalars['UUID']['output']
  dateCompleted?: Maybe<Scalars['DateTime']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  numDone?: Maybe<Scalars['Int']['output']>
  numTasks?: Maybe<Scalars['Int']['output']>
  reconAsyncBatches: ReconAsyncBatchNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  user?: Maybe<UserNode>
  userId?: Maybe<Scalars['UUID']['output']>
}

export type AutoReconResultNode = {
  __typename?: 'AutoReconResultNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  isDoubleModuleUsed?: Maybe<Scalars['Boolean']['output']>
  isEveryInvoiceLineCorrect?: Maybe<Scalars['Boolean']['output']>
  isEveryInvoiceLineMatchedWithAccrual?: Maybe<Scalars['Boolean']['output']>
  isEveryShipmentInInvoiceNotDiscrepant?: Maybe<Scalars['Boolean']['output']>
  isInactiveShipmentFound?: Maybe<Scalars['Boolean']['output']>
  isInvoicePosted?: Maybe<Scalars['Boolean']['output']>
  isInvoiceTotalGreaterThanExpectedAmount?: Maybe<Scalars['Boolean']['output']>
  isInvoiceTotalsMatchingUsed?: Maybe<Scalars['Boolean']['output']>
  isJobStatusClosed?: Maybe<Scalars['Boolean']['output']>
  isMatchingAccrualFound?: Maybe<Scalars['Boolean']['output']>
  isMultipleActiveShipmentsFound?: Maybe<Scalars['Boolean']['output']>
  isMultipleInactiveShipmentsFound?: Maybe<Scalars['Boolean']['output']>
  isShipmentFound?: Maybe<Scalars['Boolean']['output']>
  isThresholdMatchingUsed?: Maybe<Scalars['Boolean']['output']>
  isThresholdValueChecked?: Maybe<Scalars['Boolean']['output']>
  reconAttempt: ReconAttemptNode
  reconAttemptId: Scalars['UUID']['output']
}

export enum AutofillExtractorKey {
  AceManifest = 'ACE_MANIFEST',
  AgentInvoice = 'AGENT_INVOICE',
  AirWaybill = 'AIR_WAYBILL',
  AmsIsfSubmission = 'AMS_ISF_SUBMISSION',
  ApSoaRecon = 'AP_SOA_RECON',
  ArrivalNotice = 'ARRIVAL_NOTICE',
  BillOfLading = 'BILL_OF_LADING',
  CivPkl = 'CIV_PKL',
  CommercialInvoice = 'COMMERCIAL_INVOICE',
  Cw1ConsolShippingInstructions = 'CW1_CONSOL_SHIPPING_INSTRUCTIONS',
  DrayageInvoice = 'DRAYAGE_INVOICE',
  Edocs = 'EDOCS',
  Empty = 'EMPTY',
  FcInvoice = 'FC_INVOICE',
  Invoice = 'INVOICE',
  PackingList = 'PACKING_LIST',
  ShipmentOps = 'SHIPMENT_OPS',
  SteamshipLineInvoice = 'STEAMSHIP_LINE_INVOICE',
}

export type AutofillHyperParameterNode = {
  __typename?: 'AutofillHyperParameterNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  jobTemplate?: Maybe<JobTemplateNode>
  jobTemplateId?: Maybe<Scalars['UUID']['output']>
  name: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type AutofillHyperParameterNodeConnection = {
  __typename?: 'AutofillHyperParameterNodeConnection'
  edges: Array<AutofillHyperParameterNodeEdge>
}

export type AutofillHyperParameterNodeEdge = {
  __typename?: 'AutofillHyperParameterNodeEdge'
  node: AutofillHyperParameterNode
}

export type AutofillKeysResult = {
  __typename?: 'AutofillKeysResult'
  autofillKeys: Array<Scalars['String']['output']>
  lineItemFieldGroupAutofillKeys: Array<Scalars['String']['output']>
}

export enum AutofillMethod {
  Ensemble = 'ENSEMBLE',
  FallbackOneShot = 'FALLBACK_ONE_SHOT',
  Predictor = 'PREDICTOR',
  StraightOneShot = 'STRAIGHT_ONE_SHOT',
}

export type BarWithLineResult = {
  __typename?: 'BarWithLineResult'
  barResults: Array<HistogramDataGroup>
  linePeriodLabel: Scalars['String']['output']
  lineResults: Array<HistogramDataGroup>
  lineValueLabel: Scalars['String']['output']
  periodField: Scalars['String']['output']
  periodLabel: Scalars['String']['output']
  valueLabel: Scalars['String']['output']
}

export type BatchAttachDocumentToShipment = {
  __typename?: 'BatchAttachDocumentToShipment'
  job: JobNode
}

export type BatchExportJobToCw = {
  __typename?: 'BatchExportJobToCW'
  transactionAsyncBatchId: Scalars['UUID']['output']
}

export type BatchFetchShipmentInfoFromCw = {
  __typename?: 'BatchFetchShipmentInfoFromCW'
  checkShipmentInfoAsyncBatchId: Scalars['UUID']['output']
}

export type BatchPullEDocsFromCw = {
  __typename?: 'BatchPullEDocsFromCW'
  batchPullId: Scalars['UUID']['output']
}

export type BatchPushEDocsToCw = {
  __typename?: 'BatchPushEDocsToCW'
  batchPushId: Scalars['UUID']['output']
}

export type BenchmarkDataResult = {
  __typename?: 'BenchmarkDataResult'
  cols: Array<Scalars['String']['output']>
  rows: Array<Array<Maybe<Scalars['String']['output']>>>
}

export type BlockTask = {
  __typename?: 'BlockTask'
  task: TaskNode
}

export type BooleanFilterEquals = {
  value: Scalars['Boolean']['input']
}

export type BulkDeleteEDocs = {
  __typename?: 'BulkDeleteEDocs'
  ok: Scalars['Boolean']['output']
}

export type BulkUpdateJobDetails = {
  __typename?: 'BulkUpdateJobDetails'
  ok: Scalars['Boolean']['output']
}

export type BulkUpdateJobStatus = {
  __typename?: 'BulkUpdateJobStatus'
  ok: Scalars['Boolean']['output']
}

export type BulkUpdateJobTaskId = {
  __typename?: 'BulkUpdateJobTaskId'
  ok: Scalars['Boolean']['output']
}

export enum BusinessPerformanceAccountOverviewGraph {
  Expenses = 'Expenses',
  MarginNetProfit = 'MarginNetProfit',
  MarginPercent = 'MarginPercent',
  Revenue = 'Revenue',
  ShipmentVolume = 'ShipmentVolume',
}

export enum BusinessPerformanceBranchOverviewGraph {
  Expenses = 'Expenses',
  MarginNetProfit = 'MarginNetProfit',
  MarginPercent = 'MarginPercent',
  Milestones = 'Milestones',
  Revenue = 'Revenue',
  ShipmentVolume = 'ShipmentVolume',
  ShipmentsPerOperator = 'ShipmentsPerOperator',
}

export enum BusinessPerformanceDepartmentOverviewGraph {
  Expenses = 'Expenses',
  MarginNetProfit = 'MarginNetProfit',
  MarginPercent = 'MarginPercent',
  Revenue = 'Revenue',
  ShipmentVolume = 'ShipmentVolume',
}

export enum BusinessPerformanceExpensesGraph {
  ChargeCode = 'ChargeCode',
  Creditor = 'Creditor',
  Department = 'Department',
  TransportMode = 'TransportMode',
}

export enum BusinessPerformanceMarginGraph {
  AccountsMarginPercent = 'AccountsMarginPercent',
  MarginAccount = 'MarginAccount',
  MarginBranch = 'MarginBranch',
  MarginDepartment = 'MarginDepartment',
  MarginPercentAccount = 'MarginPercentAccount',
  MarginPercentBranch = 'MarginPercentBranch',
  MarginPercentDepartment = 'MarginPercentDepartment',
  MarginPercentTransportMode = 'MarginPercentTransportMode',
  MarginTransportMode = 'MarginTransportMode',
  ShipmentsMarginPercent = 'ShipmentsMarginPercent',
}

export enum BusinessPerformanceRevenueGraph {
  Account = 'Account',
  Branch = 'Branch',
  Department = 'Department',
  Route = 'Route',
  Shipper = 'Shipper',
  TransportMode = 'TransportMode',
}

export enum BusinessPerformanceTab {
  AccountOverview = 'ACCOUNT_OVERVIEW',
  BranchOverview = 'BRANCH_OVERVIEW',
  CustomDashboards = 'CUSTOM_DASHBOARDS',
  DepartmentOverview = 'DEPARTMENT_OVERVIEW',
  Expenses = 'EXPENSES',
  Margins = 'MARGINS',
  MostProfitableRoutes = 'MOST_PROFITABLE_ROUTES',
  Overview = 'OVERVIEW',
  Revenue = 'REVENUE',
  TopExpenses = 'TOP_EXPENSES',
}

export enum CwConnectorType {
  Api = 'API',
  Db = 'DB',
  Eadaptor = 'EADAPTOR',
}

export type CalendarHeatmapDataResult = {
  __typename?: 'CalendarHeatmapDataResult'
  days: Array<CalendarHeatmapDay>
  groupLabels: Array<Scalars['String']['output']>
}

export type CalendarHeatmapDay = {
  __typename?: 'CalendarHeatmapDay'
  data: Array<Scalars['Int']['output']>
  day: Scalars['String']['output']
}

export enum CardGraphTypes {
  BarWithLine = 'BAR_WITH_LINE',
  CalendarHeatmap = 'CALENDAR_HEATMAP',
  Explore = 'EXPLORE',
  HorizontalBar = 'HORIZONTAL_BAR',
  LineChart = 'LINE_CHART',
  PieChart = 'PIE_CHART',
  PinMap = 'PIN_MAP',
  PivotTable = 'PIVOT_TABLE',
  RegionMap = 'REGION_MAP',
  SankeyMap = 'SANKEY_MAP',
  Scatterplot = 'SCATTERPLOT',
  StackedBar = 'STACKED_BAR',
  Summary = 'SUMMARY',
  Table = 'TABLE',
  VerticalBar = 'VERTICAL_BAR',
}

export type CardGraphTypesEnum = {
  __typename?: 'CardGraphTypesEnum'
  isFallback: Scalars['Boolean']['output']
  value: CardGraphTypes
}

export type CardGraphTypesWithFallback = CardGraphTypesEnum | FallbackValue

export type CardNode = {
  __typename?: 'CardNode'
  cardFilter?: Maybe<Scalars['String']['output']>
  customExploreType?: Maybe<ExploreDataTypeWithFallback>
  dashboard: DashboardNode
  dashboardId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  dottedColumnName?: Maybe<Scalars['String']['output']>
  drilldownCta?: Maybe<DrilldownCtaWithFallback>
  drilldownCtaDisplayName?: Maybe<Scalars['String']['output']>
  graphType?: Maybe<CardGraphTypesWithFallback>
  height: Scalars['Int']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  pivotTable?: Maybe<PivotTableNode>
  queryDescription?: Maybe<Scalars['String']['output']>
  rootQuery: Scalars['String']['output']
  rootQueryLegacy?: Maybe<CustomQueryNode>
  rootQueryV2?: Maybe<Scalars['String']['output']>
  subtitle?: Maybe<Scalars['String']['output']>
  summaryUnitType?: Maybe<SummaryUnitTypeWithFallback>
  width: Scalars['Int']['output']
  xAxis?: Maybe<Scalars['String']['output']>
  xAxis2?: Maybe<Scalars['String']['output']>
  xPosition: Scalars['Int']['output']
  yAxis?: Maybe<Scalars['String']['output']>
  yAxis2?: Maybe<Scalars['String']['output']>
  yAxisTarget?: Maybe<Scalars['Float']['output']>
  yPosition: Scalars['Int']['output']
  zAxis?: Maybe<Scalars['String']['output']>
}

export type CardNodeConnection = {
  __typename?: 'CardNodeConnection'
  edges: Array<CardNodeEdge>
}

export type CardNodeEdge = {
  __typename?: 'CardNodeEdge'
  node: CardNode
}

export type CardReference = CustomCardReference | HardcodedCardReference

export type CargowiseConfigNode = ApiPartnerInterface & {
  __typename?: 'CargowiseConfigNode'
  apiKey: Scalars['UUID']['output']
  apiPartnerCode: ApiPartnerCodeWithFallback
  apiPartnerType: ApiPartnerType
  authValues?: Maybe<Scalars['JSONString']['output']>
  chainIoCommercialInvoices: ChainIoCommercialInvoiceNodeConnection
  chainIoConsolidations: ChainIoConsolidationNodeConnection
  chainIoCustomsDeclarations: ChainIoCustomsDeclarationNodeConnection
  chainIoServiceInvoices: ChainIoServiceInvoiceNodeConnection
  chainIoShipments: ChainIoShipmentNodeConnection
  chainIoTransports: ChainIoTransportNodeConnection
  code: Scalars['String']['output']
  company?: Maybe<CompanyNode>
  companyId?: Maybe<Scalars['UUID']['output']>
  connectorType?: Maybe<CwConnectorType>
  countryCode?: Maybe<Scalars['String']['output']>
  credential?: Maybe<CredentialNode>
  credentialId?: Maybe<Scalars['UUID']['output']>
  cwCompanyId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dbDirectConnect: Scalars['Boolean']['output']
  dbName?: Maybe<Scalars['String']['output']>
  dbPass?: Maybe<Scalars['String']['output']>
  dbUser?: Maybe<Scalars['String']['output']>
  defaultTimezoneLocation?: Maybe<GeoLocationNode>
  defaultTimezoneLocationId?: Maybe<Scalars['UUID']['output']>
  defaultTimezoneName: Scalars['String']['output']
  documentExportEndpoint?: Maybe<Scalars['String']['output']>
  documentImportEndpoint?: Maybe<Scalars['String']['output']>
  enterpriseId: Scalars['String']['output']
  expedockDocIngestEmail?: Maybe<Scalars['String']['output']>
  exportEndpoint?: Maybe<Scalars['String']['output']>
  externApiCredential?: Maybe<CredentialNode>
  externApiCredentialId?: Maybe<Scalars['UUID']['output']>
  forceFetch: Scalars['Boolean']['output']
  id: Scalars['UUID']['output']
  importEndpoint?: Maybe<Scalars['String']['output']>
  integrationId: Scalars['String']['output']
  jobTemplates: JobTemplateNodeConnection
  name: Scalars['String']['output']
  outboundPass?: Maybe<Scalars['String']['output']>
  pollDatabase: Scalars['Boolean']['output']
  proxyDbPort?: Maybe<Scalars['Int']['output']>
  proxyUrls?: Maybe<Array<Scalars['String']['output']>>
  remoteDbHostname?: Maybe<Scalars['String']['output']>
  remoteDbPort?: Maybe<Scalars['Int']['output']>
  serverId: Scalars['String']['output']
  taxes: TaxNodeConnection
  tmsType?: Maybe<ApiPartnerTmsTypeWithFallback>
  usesChargeCodeV2?: Maybe<Scalars['Boolean']['output']>
}

export type CargowiseConfigNodeConnection = {
  __typename?: 'CargowiseConfigNodeConnection'
  edges: Array<CargowiseConfigNodeEdge>
}

export type CargowiseConfigNodeEdge = {
  __typename?: 'CargowiseConfigNodeEdge'
  node: CargowiseConfigNode
}

export type CargowiseEnums = {
  __typename?: 'CargowiseEnums'
  cargowiseModule?: Maybe<CwTargetModule>
}

export type CargowiseFileTypeNode = {
  __typename?: 'CargowiseFileTypeNode'
  code: Scalars['String']['output']
  codeDescription: Scalars['String']['output']
  company?: Maybe<CompanyNode>
  companyId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['UUID']['output']
  jobEDocsPushes: JobEDocsPushNodeConnection
}

export type CargowiseFileTypeNodeConnection = {
  __typename?: 'CargowiseFileTypeNodeConnection'
  edges: Array<CargowiseFileTypeNodeEdge>
}

export type CargowiseFileTypeNodeEdge = {
  __typename?: 'CargowiseFileTypeNodeEdge'
  node: CargowiseFileTypeNode
}

export type CargowiseHyperlink = {
  __typename?: 'CargowiseHyperlink'
  forwarderReference: Scalars['String']['output']
  hyperlink: Scalars['String']['output']
}

export type Carrier = {
  __typename?: 'Carrier'
  code: Scalars['String']['output']
  isNvocc: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  searchRegex: Scalars['String']['output']
}

export type CashCycleCategory = {
  __typename?: 'CashCycleCategory'
  name: Scalars['String']['output']
  segments: Array<CashCycleSegment>
}

export type CashCycleDataResult = {
  __typename?: 'CashCycleDataResult'
  categories: Array<CashCycleCategory>
}

export type CashCycleSegment = {
  __typename?: 'CashCycleSegment'
  description: Scalars['String']['output']
  mean: Scalars['Decimal']['output']
  name: Scalars['String']['output']
  visible: Scalars['Boolean']['output']
}

export type ChainIoAdditionalReferenceNode = {
  __typename?: 'ChainIOAdditionalReferenceNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  chainIoTransport?: Maybe<ChainIoTransportNode>
  chainIoTransportId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  referenceNumber?: Maybe<Scalars['String']['output']>
  referenceType?: Maybe<Scalars['String']['output']>
}

export type ChainIoAdditionalReferenceNodeConnection = {
  __typename?: 'ChainIOAdditionalReferenceNodeConnection'
  edges: Array<ChainIoAdditionalReferenceNodeEdge>
}

export type ChainIoAdditionalReferenceNodeEdge = {
  __typename?: 'ChainIOAdditionalReferenceNodeEdge'
  node: ChainIoAdditionalReferenceNode
}

export type ChainIoAddressNode = {
  __typename?: 'ChainIOAddressNode'
  address1?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  addressType?: Maybe<Scalars['String']['output']>
  arrivalAt?: Maybe<ChainIoShipmentLegNode>
  arrivalCfsAddress?: Maybe<ChainIoConsolidationNode>
  arrivalContainerYard?: Maybe<ChainIoConsolidationNode>
  arrivalCtoAddress?: Maybe<ChainIoConsolidationNode>
  bookingNotificationParty?: Maybe<ChainIoShipmentNode>
  buyer?: Maybe<ChainIoShipmentNode>
  carrier?: Maybe<ChainIoConsolidationNode>
  cfsAddress?: Maybe<ChainIoShipmentNode>
  city?: Maybe<Scalars['String']['output']>
  coLoader?: Maybe<ChainIoShipmentNode>
  coloadWith?: Maybe<ChainIoConsolidationNode>
  commercialInvoiceLineManufacturer?: Maybe<ChainIoCommercialInvoiceLineNode>
  consignee?: Maybe<ChainIoShipmentNode>
  consignor?: Maybe<ChainIoShipmentNode>
  controllingAgent?: Maybe<ChainIoShipmentNode>
  controllingCustomer?: Maybe<ChainIoShipmentNode>
  country?: Maybe<Scalars['String']['output']>
  countryName?: Maybe<Scalars['String']['output']>
  creditor?: Maybe<ChainIoConsolidationNode>
  customsDecBuyer?: Maybe<ChainIoCustomsDeclarationNode>
  customsDecCarrier?: Maybe<ChainIoCustomsDeclarationNode>
  customsDecImporter?: Maybe<ChainIoCustomsDeclarationNode>
  customsDecImporterOfRecord?: Maybe<ChainIoCustomsDeclarationNode>
  customsDecManufacturer?: Maybe<ChainIoCustomsDeclarationNode>
  customsDecSeller?: Maybe<ChainIoCustomsDeclarationNode>
  customsDecShipToParty?: Maybe<ChainIoCustomsDeclarationNode>
  customsDecSoldToParty?: Maybe<ChainIoCustomsDeclarationNode>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>
  deliveryAgent?: Maybe<ChainIoShipmentNode>
  deliveryTo?: Maybe<ChainIoShipmentNode>
  deliveryTransportCompany?: Maybe<ChainIoShipmentNode>
  departureContainerYard?: Maybe<ChainIoConsolidationNode>
  departureCtoAddress?: Maybe<ChainIoConsolidationNode>
  departureFrom?: Maybe<ChainIoShipmentLegNode>
  email?: Maybe<Scalars['String']['output']>
  freightSellingParty?: Maybe<ChainIoShipmentNode>
  fullAddress?: Maybe<Scalars['String']['output']>
  fullDropOffParty?: Maybe<ChainIoShipmentNode>
  governmentAssignedId?: Maybe<Scalars['String']['output']>
  governmentAssignedIdType?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  importBroker?: Maybe<ChainIoShipmentNode>
  importer?: Maybe<ChainIoShipmentNode>
  importerOfRecord?: Maybe<ChainIoShipmentNode>
  legCarrier?: Maybe<ChainIoShipmentLegNode>
  localCartageContainerTerminalOperator?: Maybe<ChainIoShipmentNode>
  localCartageYard?: Maybe<ChainIoShipmentNode>
  localCartageYardExporter?: Maybe<ChainIoShipmentNode>
  localCartageYardImporter?: Maybe<ChainIoShipmentNode>
  localClient?: Maybe<ChainIoShipmentNode>
  mainSupplier?: Maybe<ChainIoCustomsDeclarationNode>
  name?: Maybe<Scalars['String']['output']>
  notifyParty?: Maybe<ChainIoShipmentNode>
  orderBuyer?: Maybe<ChainIoOrderNode>
  orderSupplier?: Maybe<ChainIoOrderNode>
  overseasAgent?: Maybe<ChainIoShipmentNode>
  phoneNumber?: Maybe<Scalars['String']['output']>
  pickupAgent?: Maybe<ChainIoShipmentNode>
  pickupFrom?: Maybe<ChainIoShipmentNode>
  pickupTransportCompany?: Maybe<ChainIoShipmentNode>
  postalCode?: Maybe<Scalars['String']['output']>
  receivingAgent?: Maybe<ChainIoConsolidationNode>
  sendingAgent?: Maybe<ChainIoConsolidationNode>
  shipFrom?: Maybe<ChainIoShipmentNode>
  shipTo?: Maybe<ChainIoShipmentNode>
  shipmentArrivalCfsAddress?: Maybe<ChainIoShipmentNode>
  shipmentDepartureCfsAddress?: Maybe<ChainIoShipmentNode>
  shipmentReceivingAgent?: Maybe<ChainIoShipmentNode>
  shipmentSendingAgent?: Maybe<ChainIoShipmentNode>
  shipper?: Maybe<ChainIoShipmentNode>
  sourceEntityId?: Maybe<Scalars['String']['output']>
  sourcePartyId?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  stateName?: Maybe<Scalars['String']['output']>
  supplier?: Maybe<ChainIoShipmentNode>
  targetPartyId?: Maybe<Scalars['String']['output']>
  transactionOrganizationAddress?: Maybe<ChainIoTransactionNode>
  unlocode?: Maybe<Scalars['String']['output']>
}

export type ChainIoAttachmentNode = {
  __typename?: 'ChainIOAttachmentNode'
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  content?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  fileName?: Maybe<Scalars['String']['output']>
  fileTypeCode?: Maybe<Scalars['String']['output']>
  fileTypeDescription?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  mediaType?: Maybe<Scalars['String']['output']>
  sourceSystemUid?: Maybe<Scalars['String']['output']>
}

export type ChainIoAttachmentNodeConnection = {
  __typename?: 'ChainIOAttachmentNodeConnection'
  edges: Array<ChainIoAttachmentNodeEdge>
}

export type ChainIoAttachmentNodeEdge = {
  __typename?: 'ChainIOAttachmentNodeEdge'
  node: ChainIoAttachmentNode
}

export type ChainIoCommercialInvoiceLineNode = {
  __typename?: 'ChainIOCommercialInvoiceLineNode'
  chainIoCi?: Maybe<ChainIoCommercialInvoiceNode>
  chainIoCiId?: Maybe<Scalars['UUID']['output']>
  customsValue?: Maybe<Scalars['Decimal']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dateProcessed?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  duty?: Maybe<Scalars['Decimal']['output']>
  dutyRate?: Maybe<Scalars['Decimal']['output']>
  ftaPayableMpf?: Maybe<Scalars['Decimal']['output']>
  hmf?: Maybe<Scalars['Decimal']['output']>
  id: Scalars['UUID']['output']
  lineNumber?: Maybe<Scalars['String']['output']>
  linePrice?: Maybe<Scalars['Decimal']['output']>
  manufacturer?: Maybe<ChainIoAddressNode>
  manufacturerId?: Maybe<Scalars['UUID']['output']>
  matchingKey?: Maybe<Scalars['String']['output']>
  nonFtaDuty?: Maybe<Scalars['Decimal']['output']>
  nonFtaPayableMpf?: Maybe<Scalars['Decimal']['output']>
  orderNumber?: Maybe<Scalars['String']['output']>
  payableMpf?: Maybe<Scalars['Decimal']['output']>
  productCode?: Maybe<Scalars['String']['output']>
  rawAddInfo?: Maybe<Scalars['String']['output']>
  spi?: Maybe<Scalars['String']['output']>
  supDuty?: Maybe<Scalars['Decimal']['output']>
  supDutyRate?: Maybe<Scalars['Decimal']['output']>
  supTariff?: Maybe<Scalars['String']['output']>
  systemDateCreated?: Maybe<Scalars['DateTime']['output']>
  tariff?: Maybe<Scalars['String']['output']>
  unitPrice?: Maybe<Scalars['Decimal']['output']>
}

export type ChainIoCommercialInvoiceLineNodeConnection = {
  __typename?: 'ChainIOCommercialInvoiceLineNodeConnection'
  edges: Array<ChainIoCommercialInvoiceLineNodeEdge>
}

export type ChainIoCommercialInvoiceLineNodeEdge = {
  __typename?: 'ChainIOCommercialInvoiceLineNodeEdge'
  node: ChainIoCommercialInvoiceLineNode
}

export type ChainIoCommercialInvoiceNode = {
  __typename?: 'ChainIOCommercialInvoiceNode'
  apiPartner?: Maybe<ApiPartnerInterface>
  apiPartnerId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  cleanedInvoiceNumber?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dateProcessed?: Maybe<Scalars['DateTime']['output']>
  forwarderReference?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceAmount?: Maybe<Scalars['Decimal']['output']>
  invoiceCurrency?: Maybe<Scalars['String']['output']>
  invoiceDate?: Maybe<Scalars['DateTime']['output']>
  invoiceLines: ChainIoCommercialInvoiceLineNodeConnection
  invoiceNumber?: Maybe<Scalars['String']['output']>
  systemDateCreated?: Maybe<Scalars['DateTime']['output']>
  tmsId?: Maybe<Scalars['String']['output']>
}

export type ChainIoCommercialInvoiceNodeConnection = {
  __typename?: 'ChainIOCommercialInvoiceNodeConnection'
  edges: Array<ChainIoCommercialInvoiceNodeEdge>
}

export type ChainIoCommercialInvoiceNodeEdge = {
  __typename?: 'ChainIOCommercialInvoiceNodeEdge'
  node: ChainIoCommercialInvoiceNode
}

export type ChainIoConsolCostNode = {
  __typename?: 'ChainIOConsolCostNode'
  amount?: Maybe<Scalars['Decimal']['output']>
  apportionmentMethod?: Maybe<Scalars['String']['output']>
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chargeCode?: Maybe<Scalars['String']['output']>
  chargeDescription?: Maybe<Scalars['String']['output']>
  costIsApportioned?: Maybe<Scalars['Boolean']['output']>
  costOwnerCode?: Maybe<Scalars['String']['output']>
  costOwnerName?: Maybe<Scalars['String']['output']>
  creditorCode?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dueDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['UUID']['output']
  invoiceDate?: Maybe<Scalars['DateTime']['output']>
  invoiceNumber?: Maybe<Scalars['String']['output']>
  isPosted?: Maybe<Scalars['Boolean']['output']>
  taxRate?: Maybe<Scalars['Decimal']['output']>
  taxRateCode?: Maybe<Scalars['String']['output']>
}

export type ChainIoConsolCostNodeConnection = {
  __typename?: 'ChainIOConsolCostNodeConnection'
  edges: Array<ChainIoConsolCostNodeEdge>
}

export type ChainIoConsolCostNodeEdge = {
  __typename?: 'ChainIOConsolCostNodeEdge'
  node: ChainIoConsolCostNode
}

export type ChainIoConsolidationNode = {
  __typename?: 'ChainIOConsolidationNode'
  additionalReferences: ChainIoAdditionalReferenceNodeConnection
  agentReference?: Maybe<Scalars['String']['output']>
  apiPartner?: Maybe<ApiPartnerInterface>
  apiPartnerId?: Maybe<Scalars['UUID']['output']>
  apiPayload?: Maybe<ApiPayloadNode>
  apiPayloadId?: Maybe<Scalars['UUID']['output']>
  arrivalCfsAddress?: Maybe<ChainIoAddressNode>
  arrivalCfsAddressId?: Maybe<Scalars['UUID']['output']>
  arrivalContainerYard?: Maybe<ChainIoAddressNode>
  arrivalContainerYardId?: Maybe<Scalars['UUID']['output']>
  arrivalCtoAddress?: Maybe<ChainIoAddressNode>
  arrivalCtoAddressId?: Maybe<Scalars['UUID']['output']>
  awbServiceLevel?: Maybe<Scalars['String']['output']>
  cargowiseConfig?: Maybe<CargowiseConfigNode>
  cargowiseConfigId?: Maybe<Scalars['UUID']['output']>
  carrier?: Maybe<ChainIoAddressNode>
  carrierBookingRef?: Maybe<Scalars['String']['output']>
  carrierId?: Maybe<Scalars['UUID']['output']>
  chainIoShipmentConsolidations: ChainIoShipmentConsolidationNodeConnection
  chainIoShipments: ChainIoShipmentNodeConnection
  cleanedForwarderReference?: Maybe<Scalars['String']['output']>
  coloadMasterBill?: Maybe<Scalars['String']['output']>
  coloadWith?: Maybe<ChainIoAddressNode>
  coloadWithId?: Maybe<Scalars['UUID']['output']>
  company?: Maybe<CompanyNode>
  companyId?: Maybe<Scalars['UUID']['output']>
  consolCosts: ChainIoConsolCostNodeConnection
  consolType?: Maybe<Scalars['String']['output']>
  containerMode?: Maybe<Scalars['String']['output']>
  containerizationType?: Maybe<Scalars['String']['output']>
  containers: ChainIoContainerNodeConnection
  copiedFromChainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  copyCauseRawCargowiseOutboundId?: Maybe<Scalars['UUID']['output']>
  creditor?: Maybe<ChainIoAddressNode>
  creditorId?: Maybe<Scalars['UUID']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  dateProcessed?: Maybe<Scalars['DateTime']['output']>
  departureContainerYard?: Maybe<ChainIoAddressNode>
  departureContainerYardId?: Maybe<Scalars['UUID']['output']>
  departureCtoAddress?: Maybe<ChainIoAddressNode>
  departureCtoAddressId?: Maybe<Scalars['UUID']['output']>
  eventBranchCode?: Maybe<Scalars['String']['output']>
  eventDate?: Maybe<Scalars['DateTime']['output']>
  eventDepartmentCode?: Maybe<Scalars['String']['output']>
  eventReference?: Maybe<Scalars['String']['output']>
  eventTypeCode?: Maybe<Scalars['String']['output']>
  eventUserCode?: Maybe<Scalars['String']['output']>
  eventUserName?: Maybe<Scalars['String']['output']>
  forwarderReference?: Maybe<Scalars['String']['output']>
  gitRevision?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  isCancelled?: Maybe<Scalars['Boolean']['output']>
  isHazardous?: Maybe<Scalars['Boolean']['output']>
  lastForeignPortCode?: Maybe<Scalars['String']['output']>
  lastForeignPortName?: Maybe<Scalars['String']['output']>
  masterBill?: Maybe<Scalars['String']['output']>
  milestones: ChainIoMilestoneNodeConnection
  packs?: Maybe<Scalars['Decimal']['output']>
  packsUnit?: Maybe<Scalars['String']['output']>
  paymentMode?: Maybe<Scalars['String']['output']>
  portOfDestinationCode?: Maybe<Scalars['String']['output']>
  portOfDestinationName?: Maybe<Scalars['String']['output']>
  portOfDischargeCode?: Maybe<Scalars['String']['output']>
  portOfDischargeName?: Maybe<Scalars['String']['output']>
  portOfLoadingCode?: Maybe<Scalars['String']['output']>
  portOfLoadingName?: Maybe<Scalars['String']['output']>
  portOfOriginCode?: Maybe<Scalars['String']['output']>
  portOfOriginName?: Maybe<Scalars['String']['output']>
  rawCargowiseOutbound?: Maybe<RawCargowiseOutboundNode>
  rawCargowiseOutboundId?: Maybe<Scalars['UUID']['output']>
  receivingAgent?: Maybe<ChainIoAddressNode>
  receivingAgentId?: Maybe<Scalars['UUID']['output']>
  releaseType?: Maybe<Scalars['String']['output']>
  requiresTemperatureControl?: Maybe<Scalars['Boolean']['output']>
  sendingAgent?: Maybe<ChainIoAddressNode>
  sendingAgentId?: Maybe<Scalars['UUID']['output']>
  shipmentLegs: ChainIoShipmentLegNodeConnection
  shipmentType?: Maybe<Scalars['String']['output']>
  shipments: ChainIoShipmentNodeConnection
  sourceSystemUid?: Maybe<Scalars['String']['output']>
  syncMatchCriteria?: Maybe<Scalars['JSONString']['output']>
  syncSource?: Maybe<SyncSource>
  systemDateCreated?: Maybe<Scalars['DateTime']['output']>
  systemDateEdited?: Maybe<Scalars['DateTime']['output']>
  tmsId?: Maybe<Scalars['String']['output']>
  transmissionUid?: Maybe<Scalars['String']['output']>
  transportMode?: Maybe<Scalars['String']['output']>
  triggerCount?: Maybe<Scalars['Int']['output']>
  triggerDate?: Maybe<Scalars['DateTime']['output']>
  triggerDescription?: Maybe<Scalars['String']['output']>
  triggerType?: Maybe<Scalars['String']['output']>
  volume?: Maybe<Scalars['Decimal']['output']>
  volumeUnit?: Maybe<Scalars['String']['output']>
  weight?: Maybe<Scalars['Decimal']['output']>
  weightUnit?: Maybe<Scalars['String']['output']>
}

export type ChainIoConsolidationNodeConnection = {
  __typename?: 'ChainIOConsolidationNodeConnection'
  edges: Array<ChainIoConsolidationNodeEdge>
}

export type ChainIoConsolidationNodeEdge = {
  __typename?: 'ChainIOConsolidationNodeEdge'
  node: ChainIoConsolidationNode
}

export type ChainIoContainerNode = {
  __typename?: 'ChainIOContainerNode'
  actualDeliveryDate?: Maybe<Scalars['DateTime']['output']>
  actualPickupDate?: Maybe<Scalars['DateTime']['output']>
  arrivalPickupByRail?: Maybe<Scalars['Boolean']['output']>
  arrivalPortTransportBookedDate?: Maybe<Scalars['DateTime']['output']>
  arrivalPortTransportRef?: Maybe<Scalars['String']['output']>
  arrivalSlotBookingRef?: Maybe<Scalars['String']['output']>
  arrivalSlotDate?: Maybe<Scalars['DateTime']['output']>
  carrierCutoffDate?: Maybe<Scalars['DateTime']['output']>
  cfsAvailableDate?: Maybe<Scalars['DateTime']['output']>
  cfsStorageStartDate?: Maybe<Scalars['DateTime']['output']>
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  chainIoTransport?: Maybe<ChainIoTransportNode>
  chainIoTransportId?: Maybe<Scalars['UUID']['output']>
  containerCount?: Maybe<Scalars['Int']['output']>
  containerId?: Maybe<Scalars['String']['output']>
  containerLink?: Maybe<Scalars['String']['output']>
  containerMode?: Maybe<Scalars['String']['output']>
  containerNumber?: Maybe<Scalars['String']['output']>
  ctoAvailableDate?: Maybe<Scalars['DateTime']['output']>
  ctoStorageDate?: Maybe<Scalars['DateTime']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  deliveryMode?: Maybe<Scalars['String']['output']>
  departureDeliveryByRail?: Maybe<Scalars['Boolean']['output']>
  departurePortTransportBookedDate?: Maybe<Scalars['DateTime']['output']>
  departurePortTransportRef?: Maybe<Scalars['String']['output']>
  departureSlotBookingRef?: Maybe<Scalars['String']['output']>
  departureSlotDate?: Maybe<Scalars['DateTime']['output']>
  dropModeCode?: Maybe<Scalars['String']['output']>
  emptyReadyDate?: Maybe<Scalars['DateTime']['output']>
  emptyReleasedFromContainerYardDate?: Maybe<Scalars['DateTime']['output']>
  emptyRequiredBy?: Maybe<Scalars['DateTime']['output']>
  emptyReturnReference?: Maybe<Scalars['String']['output']>
  emptyReturnedBy?: Maybe<Scalars['DateTime']['output']>
  emptyReturnedOnDate?: Maybe<Scalars['DateTime']['output']>
  estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>
  estimatedPickupDate?: Maybe<Scalars['DateTime']['output']>
  exportPortCustomsRef?: Maybe<Scalars['String']['output']>
  exportReleaseNumber?: Maybe<Scalars['String']['output']>
  fclLoadedDate?: Maybe<Scalars['DateTime']['output']>
  fclUnloadDate?: Maybe<Scalars['DateTime']['output']>
  goodsValue?: Maybe<Scalars['Decimal']['output']>
  goodsValueCurrency?: Maybe<Scalars['String']['output']>
  grossWeight?: Maybe<Scalars['Decimal']['output']>
  grossWeightUom?: Maybe<Scalars['String']['output']>
  heldForFclTransitStaging?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['UUID']['output']
  importPortCustomsRef?: Maybe<Scalars['String']['output']>
  importReleaseNumber?: Maybe<Scalars['String']['output']>
  isControlledAtmosphere?: Maybe<Scalars['Boolean']['output']>
  loadType?: Maybe<Scalars['String']['output']>
  netWeightKgs?: Maybe<Scalars['Decimal']['output']>
  netWeightLbs?: Maybe<Scalars['Decimal']['output']>
  pickupNumber?: Maybe<Scalars['String']['output']>
  seal1?: Maybe<Scalars['String']['output']>
  seal2?: Maybe<Scalars['String']['output']>
  seal3?: Maybe<Scalars['String']['output']>
  sealedBy?: Maybe<Scalars['String']['output']>
  sizeCode?: Maybe<Scalars['String']['output']>
  sourceContainerId?: Maybe<Scalars['String']['output']>
  typeCode?: Maybe<Scalars['String']['output']>
  wharfGateIn?: Maybe<Scalars['String']['output']>
  wharfGateInDate?: Maybe<Scalars['DateTime']['output']>
  wharfGateOutDate?: Maybe<Scalars['DateTime']['output']>
}

export type ChainIoContainerNodeConnection = {
  __typename?: 'ChainIOContainerNodeConnection'
  edges: Array<ChainIoContainerNodeEdge>
}

export type ChainIoContainerNodeEdge = {
  __typename?: 'ChainIOContainerNodeEdge'
  node: ChainIoContainerNode
}

export type ChainIoCustomFieldNode = {
  __typename?: 'ChainIOCustomFieldNode'
  chainIoShipment: ChainIoShipmentNode
  chainIoShipmentId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  key?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type ChainIoCustomFieldNodeConnection = {
  __typename?: 'ChainIOCustomFieldNodeConnection'
  edges: Array<ChainIoCustomFieldNodeEdge>
}

export type ChainIoCustomFieldNodeEdge = {
  __typename?: 'ChainIOCustomFieldNodeEdge'
  node: ChainIoCustomFieldNode
}

export type ChainIoCustomsDeclarationCostNode = {
  __typename?: 'ChainIOCustomsDeclarationCostNode'
  amount?: Maybe<Scalars['Decimal']['output']>
  chainIoCustomsDeclaration: ChainIoCustomsDeclarationNode
  chainIoCustomsDeclarationId: Scalars['UUID']['output']
  chargeCode?: Maybe<Scalars['String']['output']>
  chargeDescription?: Maybe<Scalars['String']['output']>
  costDisplaySequence?: Maybe<Scalars['String']['output']>
  costPostDate?: Maybe<Scalars['DateTime']['output']>
  costRecognitionDate?: Maybe<Scalars['DateTime']['output']>
  costRecognitionType?: Maybe<Scalars['String']['output']>
  creditorCode?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dueDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['UUID']['output']
  invoiceDate?: Maybe<Scalars['DateTime']['output']>
  invoiceNumber?: Maybe<Scalars['String']['output']>
  isPosted?: Maybe<Scalars['Boolean']['output']>
  sellAmount?: Maybe<Scalars['Decimal']['output']>
  sellCurrency?: Maybe<Scalars['String']['output']>
  sellDisplaySequence?: Maybe<Scalars['String']['output']>
  sellIsPosted?: Maybe<Scalars['Boolean']['output']>
  sellPostDate?: Maybe<Scalars['DateTime']['output']>
  sellRecognitionDate?: Maybe<Scalars['DateTime']['output']>
  sellRecognitionType?: Maybe<Scalars['String']['output']>
  sellTaxRateCode?: Maybe<Scalars['String']['output']>
  taxRate?: Maybe<Scalars['Decimal']['output']>
  taxRateCode?: Maybe<Scalars['String']['output']>
}

export type ChainIoCustomsDeclarationCostNodeConnection = {
  __typename?: 'ChainIOCustomsDeclarationCostNodeConnection'
  edges: Array<ChainIoCustomsDeclarationCostNodeEdge>
}

export type ChainIoCustomsDeclarationCostNodeEdge = {
  __typename?: 'ChainIOCustomsDeclarationCostNodeEdge'
  node: ChainIoCustomsDeclarationCostNode
}

export type ChainIoCustomsDeclarationNode = {
  __typename?: 'ChainIOCustomsDeclarationNode'
  additionalReferences: ChainIoAdditionalReferenceNodeConnection
  apiPartner?: Maybe<ApiPartnerInterface>
  apiPartnerId?: Maybe<Scalars['UUID']['output']>
  apiPayload?: Maybe<ApiPayloadNode>
  apiPayloadId?: Maybe<Scalars['UUID']['output']>
  applicationCode?: Maybe<Scalars['String']['output']>
  arrivalActual?: Maybe<Scalars['DateTime']['output']>
  arrivalDate?: Maybe<Scalars['DateTime']['output']>
  arrivalEstimated?: Maybe<Scalars['DateTime']['output']>
  arrivalPort?: Maybe<Scalars['String']['output']>
  bookingReference?: Maybe<Scalars['String']['output']>
  branch?: Maybe<Scalars['String']['output']>
  branchName?: Maybe<Scalars['String']['output']>
  brokerStaffCode?: Maybe<Scalars['String']['output']>
  brokerStaffName?: Maybe<Scalars['String']['output']>
  cargowiseConfig?: Maybe<CargowiseConfigNode>
  cargowiseConfigId?: Maybe<Scalars['UUID']['output']>
  carrierBookingReference?: Maybe<Scalars['String']['output']>
  carrierScac?: Maybe<Scalars['String']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  cleanedForwarderReference?: Maybe<Scalars['String']['output']>
  commercialInvoices: ChainIoCommercialInvoiceNodeConnection
  company?: Maybe<CompanyNode>
  companyId?: Maybe<Scalars['UUID']['output']>
  consignee?: Maybe<ChainIoAddressNode>
  consigneeId?: Maybe<Scalars['UUID']['output']>
  consolNumber?: Maybe<Scalars['String']['output']>
  containers: ChainIoContainerNodeConnection
  controllingAgent?: Maybe<ChainIoAddressNode>
  controllingAgentId?: Maybe<Scalars['UUID']['output']>
  controllingCustomer?: Maybe<ChainIoAddressNode>
  controllingCustomerId?: Maybe<Scalars['UUID']['output']>
  currency?: Maybe<Scalars['String']['output']>
  customsDecBuyer?: Maybe<ChainIoAddressNode>
  customsDecBuyerId?: Maybe<Scalars['UUID']['output']>
  customsDecCarrier?: Maybe<ChainIoAddressNode>
  customsDecCarrierId?: Maybe<Scalars['UUID']['output']>
  customsDecContainerMode?: Maybe<Scalars['String']['output']>
  customsDecImporter?: Maybe<ChainIoAddressNode>
  customsDecImporterId?: Maybe<Scalars['UUID']['output']>
  customsDecManufacturer?: Maybe<ChainIoAddressNode>
  customsDecManufacturerId?: Maybe<Scalars['UUID']['output']>
  customsDecSeller?: Maybe<ChainIoAddressNode>
  customsDecSellerId?: Maybe<Scalars['UUID']['output']>
  customsDecShipToParty?: Maybe<ChainIoAddressNode>
  customsDecShipToPartyId?: Maybe<Scalars['UUID']['output']>
  customsDecSoldToParty?: Maybe<ChainIoAddressNode>
  customsDecSoldToPartyId?: Maybe<Scalars['UUID']['output']>
  customsDeclarationCosts: ChainIoCustomsDeclarationCostNodeConnection
  dateClosed?: Maybe<Scalars['DateTime']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  dateProcessed?: Maybe<Scalars['DateTime']['output']>
  decEntryNum?: Maybe<Scalars['String']['output']>
  deliveryByDate?: Maybe<Scalars['DateTime']['output']>
  deliveryFromDate?: Maybe<Scalars['DateTime']['output']>
  deliveryTransportCompany?: Maybe<ChainIoAddressNode>
  deliveryTransportCompanyId?: Maybe<Scalars['UUID']['output']>
  department?: Maybe<Scalars['String']['output']>
  departmentName?: Maybe<Scalars['String']['output']>
  departureActual?: Maybe<Scalars['DateTime']['output']>
  departureEstimated?: Maybe<Scalars['DateTime']['output']>
  descriptionOfGoods?: Maybe<Scalars['String']['output']>
  destinationPort?: Maybe<Scalars['String']['output']>
  destinationState?: Maybe<Scalars['String']['output']>
  direction?: Maybe<Scalars['String']['output']>
  dischargeArrival?: Maybe<Scalars['DateTime']['output']>
  dischargePort?: Maybe<Scalars['String']['output']>
  entryArrival?: Maybe<Scalars['DateTime']['output']>
  entryDate?: Maybe<Scalars['DateTime']['output']>
  entryPort?: Maybe<Scalars['String']['output']>
  entryType?: Maybe<Scalars['String']['output']>
  estimatedEntryDate?: Maybe<Scalars['DateTime']['output']>
  eventBranchCode?: Maybe<Scalars['String']['output']>
  eventDate?: Maybe<Scalars['DateTime']['output']>
  eventDepartmentCode?: Maybe<Scalars['String']['output']>
  eventReference?: Maybe<Scalars['String']['output']>
  eventTypeCode?: Maybe<Scalars['String']['output']>
  eventUserCode?: Maybe<Scalars['String']['output']>
  eventUserName?: Maybe<Scalars['String']['output']>
  exportCountry?: Maybe<Scalars['String']['output']>
  exportDate?: Maybe<Scalars['DateTime']['output']>
  exportPort?: Maybe<Scalars['String']['output']>
  fileBranch?: Maybe<Scalars['String']['output']>
  fileBranchName?: Maybe<Scalars['String']['output']>
  filer?: Maybe<Scalars['String']['output']>
  finalDestination?: Maybe<Scalars['String']['output']>
  firmsCode?: Maybe<Scalars['String']['output']>
  firstOrigin?: Maybe<Scalars['String']['output']>
  forwarderReference?: Maybe<Scalars['String']['output']>
  gitRevision?: Maybe<Scalars['String']['output']>
  hmf?: Maybe<Scalars['Boolean']['output']>
  houseBill?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  importerOfRecord?: Maybe<ChainIoAddressNode>
  importerOfRecordId?: Maybe<Scalars['UUID']['output']>
  incoTerm?: Maybe<Scalars['String']['output']>
  isCancelled?: Maybe<Scalars['Boolean']['output']>
  issuer?: Maybe<Scalars['String']['output']>
  issuerScac?: Maybe<Scalars['String']['output']>
  itDate?: Maybe<Scalars['DateTime']['output']>
  itNumber?: Maybe<Scalars['String']['output']>
  jobStatus?: Maybe<Scalars['String']['output']>
  ladingPort?: Maybe<Scalars['String']['output']>
  loadingActual?: Maybe<Scalars['DateTime']['output']>
  loadingDate?: Maybe<Scalars['DateTime']['output']>
  loadingEstimated?: Maybe<Scalars['DateTime']['output']>
  loadingPort?: Maybe<Scalars['String']['output']>
  localClient?: Maybe<ChainIoAddressNode>
  localClientId?: Maybe<Scalars['UUID']['output']>
  mainSupplier?: Maybe<ChainIoAddressNode>
  mainSupplierId?: Maybe<Scalars['UUID']['output']>
  masterBill?: Maybe<Scalars['String']['output']>
  messageType?: Maybe<Scalars['String']['output']>
  milestones: ChainIoMilestoneNodeConnection
  oceanBill?: Maybe<Scalars['String']['output']>
  operationsStaffCode?: Maybe<Scalars['String']['output']>
  operationsStaffName?: Maybe<Scalars['String']['output']>
  orders: ChainIoOrderNodeConnection
  originCountry?: Maybe<Scalars['String']['output']>
  originPort?: Maybe<Scalars['String']['output']>
  ownerRef?: Maybe<Scalars['String']['output']>
  packs?: Maybe<Scalars['Decimal']['output']>
  packsUnit?: Maybe<Scalars['String']['output']>
  pickupByDate?: Maybe<Scalars['DateTime']['output']>
  pickupFromDate?: Maybe<Scalars['DateTime']['output']>
  pickupTransportCompany?: Maybe<ChainIoAddressNode>
  pickupTransportCompanyId?: Maybe<Scalars['UUID']['output']>
  rawCargowiseOutbound?: Maybe<RawCargowiseOutboundNode>
  rawCargowiseOutboundId?: Maybe<Scalars['UUID']['output']>
  salesStaffCode?: Maybe<Scalars['String']['output']>
  salesStaffName?: Maybe<Scalars['String']['output']>
  serviceInvoices: ChainIoServiceInvoiceNodeConnection
  serviceLevel?: Maybe<Scalars['String']['output']>
  serviceLevelDescription?: Maybe<Scalars['String']['output']>
  shipmentLegs: ChainIoShipmentLegNodeConnection
  shipper?: Maybe<ChainIoAddressNode>
  shipperId?: Maybe<Scalars['UUID']['output']>
  syncMatchCriteria?: Maybe<Scalars['JSONString']['output']>
  syncSource?: Maybe<SyncSource>
  systemDateCreated?: Maybe<Scalars['DateTime']['output']>
  systemDateEdited?: Maybe<Scalars['DateTime']['output']>
  tmsId?: Maybe<Scalars['String']['output']>
  transportMode?: Maybe<Scalars['String']['output']>
  triggerCount?: Maybe<Scalars['Int']['output']>
  triggerDate?: Maybe<Scalars['DateTime']['output']>
  triggerDescription?: Maybe<Scalars['String']['output']>
  triggerType?: Maybe<Scalars['String']['output']>
  unExportPort?: Maybe<Scalars['String']['output']>
  vesselName?: Maybe<Scalars['String']['output']>
  volume?: Maybe<Scalars['Decimal']['output']>
  volumeUnit?: Maybe<Scalars['String']['output']>
  voyageNumber?: Maybe<Scalars['String']['output']>
  weight?: Maybe<Scalars['Decimal']['output']>
  weightUnit?: Maybe<Scalars['String']['output']>
}

export type ChainIoCustomsDeclarationNodeConnection = {
  __typename?: 'ChainIOCustomsDeclarationNodeConnection'
  edges: Array<ChainIoCustomsDeclarationNodeEdge>
}

export type ChainIoCustomsDeclarationNodeEdge = {
  __typename?: 'ChainIOCustomsDeclarationNodeEdge'
  node: ChainIoCustomsDeclarationNode
}

export type ChainIoLocodeNode = {
  __typename?: 'ChainIOLocodeNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  finalDestinationPort?: Maybe<ChainIoShipmentNode>
  id: Scalars['UUID']['output']
  shipmentArrivalPort?: Maybe<ChainIoShipmentNode>
  shipmentLadingPort?: Maybe<ChainIoShipmentNode>
  shipmentLegArrivalPort?: Maybe<ChainIoShipmentLegNode>
  shipmentLegLadingPort?: Maybe<ChainIoShipmentLegNode>
  unlocode?: Maybe<Scalars['String']['output']>
}

export type ChainIoMilestoneNode = {
  __typename?: 'ChainIOMilestoneNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  chainIoTransport?: Maybe<ChainIoTransportNode>
  chainIoTransportId?: Maybe<Scalars['UUID']['output']>
  conditionReference?: Maybe<Scalars['String']['output']>
  conditionType?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  milestoneActualDate?: Maybe<Scalars['DateTime']['output']>
  milestoneActualDateUtc?: Maybe<Scalars['DateTime']['output']>
  milestoneDescription?: Maybe<Scalars['String']['output']>
  milestoneEstimatedDate?: Maybe<Scalars['DateTime']['output']>
  milestoneEstimatedDateUtc?: Maybe<Scalars['DateTime']['output']>
  milestoneEventCode?: Maybe<Scalars['String']['output']>
  milestoneSequenceNumber?: Maybe<Scalars['Int']['output']>
  referenceEntity?: Maybe<Scalars['String']['output']>
  referenceEntityTmsId?: Maybe<Scalars['String']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
}

export type ChainIoMilestoneNodeConnection = {
  __typename?: 'ChainIOMilestoneNodeConnection'
  edges: Array<ChainIoMilestoneNodeEdge>
}

export type ChainIoMilestoneNodeEdge = {
  __typename?: 'ChainIOMilestoneNodeEdge'
  node: ChainIoMilestoneNode
}

export type ChainIoOrderLineNode = {
  __typename?: 'ChainIOOrderLineNode'
  chainIoOrder: ChainIoOrderNode
  chainIoOrderId: Scalars['UUID']['output']
  containerNumber?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  orderLineNumber?: Maybe<Scalars['Int']['output']>
  partNumber?: Maybe<Scalars['String']['output']>
}

export type ChainIoOrderLineNodeConnection = {
  __typename?: 'ChainIOOrderLineNodeConnection'
  edges: Array<ChainIoOrderLineNodeEdge>
}

export type ChainIoOrderLineNodeEdge = {
  __typename?: 'ChainIOOrderLineNodeEdge'
  node: ChainIoOrderLineNode
}

export type ChainIoOrderNode = {
  __typename?: 'ChainIOOrderNode'
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  chainIoTransport?: Maybe<ChainIoTransportNode>
  chainIoTransportId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  orderBuyer?: Maybe<ChainIoAddressNode>
  orderBuyerId?: Maybe<Scalars['UUID']['output']>
  orderDate?: Maybe<Scalars['DateTime']['output']>
  orderLines: ChainIoOrderLineNodeConnection
  orderNumberSplit?: Maybe<Scalars['Int']['output']>
  orderReference?: Maybe<Scalars['String']['output']>
  orderStatus?: Maybe<Scalars['String']['output']>
  orderSupplier?: Maybe<ChainIoAddressNode>
  orderSupplierId?: Maybe<Scalars['UUID']['output']>
  sequence?: Maybe<Scalars['Int']['output']>
}

export type ChainIoOrderNodeConnection = {
  __typename?: 'ChainIOOrderNodeConnection'
  edges: Array<ChainIoOrderNodeEdge>
}

export type ChainIoOrderNodeEdge = {
  __typename?: 'ChainIOOrderNodeEdge'
  node: ChainIoOrderNode
}

export type ChainIoPackItemNode = {
  __typename?: 'ChainIOPackItemNode'
  chainIoPackageGroup?: Maybe<ChainIoPackageGroupNode>
  chainIoPackageGroupId?: Maybe<Scalars['UUID']['output']>
  chargeableWeightKgs?: Maybe<Scalars['Decimal']['output']>
  customerProductId?: Maybe<Scalars['String']['output']>
  customerSku?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  eanCode?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  poLineId?: Maybe<Scalars['String']['output']>
  poNumber?: Maybe<Scalars['String']['output']>
  priceTicketInstructions?: Maybe<Scalars['String']['output']>
  productDescription?: Maybe<Scalars['String']['output']>
  productImportHts?: Maybe<Scalars['String']['output']>
  productName?: Maybe<Scalars['String']['output']>
  sourcePackItemId?: Maybe<Scalars['String']['output']>
  sourcePoLineId?: Maybe<Scalars['String']['output']>
  totalItemQuantity?: Maybe<Scalars['Decimal']['output']>
  unitOfMeasure?: Maybe<Scalars['String']['output']>
  unitOfMeasureDescription?: Maybe<Scalars['String']['output']>
}

export type ChainIoPackItemNodeConnection = {
  __typename?: 'ChainIOPackItemNodeConnection'
  edges: Array<ChainIoPackItemNodeEdge>
}

export type ChainIoPackItemNodeEdge = {
  __typename?: 'ChainIOPackItemNodeEdge'
  node: ChainIoPackItemNode
}

export type ChainIoPackageGroupNode = {
  __typename?: 'ChainIOPackageGroupNode'
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  chargeableWeightKgs?: Maybe<Scalars['Decimal']['output']>
  commodityType?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  descriptionOfGoods?: Maybe<Scalars['String']['output']>
  finalDestinationId?: Maybe<Scalars['String']['output']>
  grossWeightKgs?: Maybe<Scalars['Decimal']['output']>
  id: Scalars['UUID']['output']
  marksAndNumbers?: Maybe<Scalars['String']['output']>
  packItems: ChainIoPackItemNodeConnection
  packageHeightCms?: Maybe<Scalars['Decimal']['output']>
  packageLengthCms?: Maybe<Scalars['Decimal']['output']>
  packageNumber?: Maybe<Scalars['String']['output']>
  packageQuantity?: Maybe<Scalars['Decimal']['output']>
  packageTypeCode?: Maybe<Scalars['String']['output']>
  packageTypeDescription?: Maybe<Scalars['String']['output']>
  packageWidthCms?: Maybe<Scalars['Decimal']['output']>
  productCode?: Maybe<Scalars['String']['output']>
  productImportHts?: Maybe<Scalars['String']['output']>
  sourceContainerId?: Maybe<Scalars['String']['output']>
  sourcePackageGroupId?: Maybe<Scalars['String']['output']>
  volumeCbms?: Maybe<Scalars['Decimal']['output']>
}

export type ChainIoPackageGroupNodeConnection = {
  __typename?: 'ChainIOPackageGroupNodeConnection'
  edges: Array<ChainIoPackageGroupNodeEdge>
}

export type ChainIoPackageGroupNodeEdge = {
  __typename?: 'ChainIOPackageGroupNodeEdge'
  node: ChainIoPackageGroupNode
}

export type ChainIoPackingLineNode = {
  __typename?: 'ChainIOPackingLineNode'
  chainIoShipment: ChainIoShipmentNode
  chainIoShipmentId: Scalars['UUID']['output']
  commodityCode?: Maybe<Scalars['String']['output']>
  containerId?: Maybe<Scalars['String']['output']>
  containerLink?: Maybe<Scalars['String']['output']>
  containerNumber?: Maybe<Scalars['String']['output']>
  countryOfOrigin?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  descriptionOfGoods?: Maybe<Scalars['String']['output']>
  exportReferenceNumber?: Maybe<Scalars['String']['output']>
  freightMode?: Maybe<Scalars['String']['output']>
  harmonisedCode?: Maybe<Scalars['String']['output']>
  height?: Maybe<Scalars['Decimal']['output']>
  heightUom?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  importReferenceNumber?: Maybe<Scalars['String']['output']>
  isControlledAtmosphere?: Maybe<Scalars['Boolean']['output']>
  itemNumber?: Maybe<Scalars['String']['output']>
  length?: Maybe<Scalars['Decimal']['output']>
  lengthUom?: Maybe<Scalars['String']['output']>
  linePrice?: Maybe<Scalars['Decimal']['output']>
  marksAndNumbers?: Maybe<Scalars['String']['output']>
  packOrder?: Maybe<Scalars['String']['output']>
  packingLineId?: Maybe<Scalars['String']['output']>
  packs?: Maybe<Scalars['Decimal']['output']>
  packsUom?: Maybe<Scalars['String']['output']>
  partNumber?: Maybe<Scalars['String']['output']>
  pieceVolume?: Maybe<Scalars['Decimal']['output']>
  pieceVolumeUom?: Maybe<Scalars['String']['output']>
  pieceWeight?: Maybe<Scalars['Decimal']['output']>
  pieceWeightUom?: Maybe<Scalars['String']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  volume?: Maybe<Scalars['Decimal']['output']>
  volumeUom?: Maybe<Scalars['String']['output']>
  weight?: Maybe<Scalars['Decimal']['output']>
  weightUom?: Maybe<Scalars['String']['output']>
  width?: Maybe<Scalars['Decimal']['output']>
  widthUom?: Maybe<Scalars['String']['output']>
}

export type ChainIoPackingLineNodeConnection = {
  __typename?: 'ChainIOPackingLineNodeConnection'
  edges: Array<ChainIoPackingLineNodeEdge>
}

export type ChainIoPackingLineNodeEdge = {
  __typename?: 'ChainIOPackingLineNodeEdge'
  node: ChainIoPackingLineNode
}

export type ChainIoPurchaseOrderLineNode = {
  __typename?: 'ChainIOPurchaseOrderLineNode'
  chainIoPo?: Maybe<ChainIoPurchaseOrderNode>
  chainIoPoId?: Maybe<Scalars['UUID']['output']>
  customerProductId?: Maybe<Scalars['String']['output']>
  customerSku?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  htsCode?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  lineId?: Maybe<Scalars['String']['output']>
  productDescription?: Maybe<Scalars['String']['output']>
  productName?: Maybe<Scalars['String']['output']>
  quantityRequested?: Maybe<Scalars['Decimal']['output']>
  skuDescription?: Maybe<Scalars['String']['output']>
  sourceSystemId?: Maybe<Scalars['String']['output']>
  unitOfMeasure?: Maybe<Scalars['String']['output']>
  unitOfMeasureDescription?: Maybe<Scalars['String']['output']>
}

export type ChainIoPurchaseOrderLineNodeConnection = {
  __typename?: 'ChainIOPurchaseOrderLineNodeConnection'
  edges: Array<ChainIoPurchaseOrderLineNodeEdge>
}

export type ChainIoPurchaseOrderLineNodeEdge = {
  __typename?: 'ChainIOPurchaseOrderLineNodeEdge'
  node: ChainIoPurchaseOrderLineNode
}

export type ChainIoPurchaseOrderNode = {
  __typename?: 'ChainIOPurchaseOrderNode'
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  customerOrderNumber?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  incoTerm?: Maybe<Scalars['String']['output']>
  incoTermNamedPlace?: Maybe<Scalars['String']['output']>
  latestDeliveryDate?: Maybe<Scalars['DateTime']['output']>
  orderDate?: Maybe<Scalars['DateTime']['output']>
  purchaseOrderLines: ChainIoPurchaseOrderLineNodeConnection
  purchaseOrdersId?: Maybe<Scalars['UUID']['output']>
  sourceSystemId?: Maybe<Scalars['String']['output']>
}

export type ChainIoPurchaseOrderNodeConnection = {
  __typename?: 'ChainIOPurchaseOrderNodeConnection'
  edges: Array<ChainIoPurchaseOrderNodeEdge>
}

export type ChainIoPurchaseOrderNodeEdge = {
  __typename?: 'ChainIOPurchaseOrderNodeEdge'
  node: ChainIoPurchaseOrderNode
}

export type ChainIoServiceInvoiceLineNode = {
  __typename?: 'ChainIOServiceInvoiceLineNode'
  accountBook?: Maybe<Scalars['String']['output']>
  branchCode?: Maybe<Scalars['String']['output']>
  chainIoSi?: Maybe<ChainIoServiceInvoiceNode>
  chainIoSiId?: Maybe<Scalars['UUID']['output']>
  chargeCode?: Maybe<Scalars['String']['output']>
  costDisplaySequence?: Maybe<Scalars['String']['output']>
  costDueDate?: Maybe<Scalars['DateTime']['output']>
  costExchangeRate?: Maybe<Scalars['Decimal']['output']>
  costFullyPaidDate?: Maybe<Scalars['DateTime']['output']>
  costInvoiceDate?: Maybe<Scalars['DateTime']['output']>
  costInvoiceNumber?: Maybe<Scalars['String']['output']>
  costIsCancelled?: Maybe<Scalars['Boolean']['output']>
  costLedger?: Maybe<Scalars['String']['output']>
  costLocalAmount?: Maybe<Scalars['Decimal']['output']>
  costOutstandingAmount?: Maybe<Scalars['String']['output']>
  costPostDate?: Maybe<Scalars['DateTime']['output']>
  costRecognitionDate?: Maybe<Scalars['DateTime']['output']>
  costRecognitionType?: Maybe<Scalars['String']['output']>
  costTransactionType?: Maybe<Scalars['String']['output']>
  creditor?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dateProcessed?: Maybe<Scalars['DateTime']['output']>
  debtor?: Maybe<Scalars['String']['output']>
  departmentCode?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  estimatedCost?: Maybe<Scalars['Decimal']['output']>
  id: Scalars['UUID']['output']
  isNewSignage?: Maybe<Scalars['Boolean']['output']>
  isPosted?: Maybe<Scalars['String']['output']>
  lineNumber?: Maybe<Scalars['String']['output']>
  quantity?: Maybe<Scalars['Decimal']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  revenueInvoiceLineNumber?: Maybe<Scalars['Decimal']['output']>
  revenueInvoiceUid?: Maybe<Scalars['String']['output']>
  sellCurrency?: Maybe<Scalars['String']['output']>
  sellDisplaySequence?: Maybe<Scalars['String']['output']>
  sellDueDate?: Maybe<Scalars['DateTime']['output']>
  sellExchangeRate?: Maybe<Scalars['Decimal']['output']>
  sellFullyPaidDate?: Maybe<Scalars['DateTime']['output']>
  sellInvoiceDate?: Maybe<Scalars['DateTime']['output']>
  sellInvoiceNumber?: Maybe<Scalars['String']['output']>
  sellInvoiceType?: Maybe<Scalars['String']['output']>
  sellIsCancelled?: Maybe<Scalars['Boolean']['output']>
  sellIsPosted?: Maybe<Scalars['String']['output']>
  sellLedger?: Maybe<Scalars['String']['output']>
  sellLocalAmount?: Maybe<Scalars['Decimal']['output']>
  sellOutstandingAmount?: Maybe<Scalars['String']['output']>
  sellPostDate?: Maybe<Scalars['DateTime']['output']>
  sellRecognitionDate?: Maybe<Scalars['DateTime']['output']>
  sellRecognitionType?: Maybe<Scalars['String']['output']>
  sellTaxAmount?: Maybe<Scalars['Decimal']['output']>
  sellTaxRate?: Maybe<Scalars['Decimal']['output']>
  sellTaxRateCode?: Maybe<Scalars['String']['output']>
  sellTotalCost?: Maybe<Scalars['Decimal']['output']>
  sellTransactionType?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  taxAmount?: Maybe<Scalars['Decimal']['output']>
  taxRate?: Maybe<Scalars['Decimal']['output']>
  taxRateCode?: Maybe<Scalars['String']['output']>
  totalCost?: Maybe<Scalars['Decimal']['output']>
  unitPrice?: Maybe<Scalars['Decimal']['output']>
}

export type ChainIoServiceInvoiceLineNodeConnection = {
  __typename?: 'ChainIOServiceInvoiceLineNodeConnection'
  edges: Array<ChainIoServiceInvoiceLineNodeEdge>
}

export type ChainIoServiceInvoiceLineNodeEdge = {
  __typename?: 'ChainIOServiceInvoiceLineNodeEdge'
  node: ChainIoServiceInvoiceLineNode
}

export type ChainIoServiceInvoiceNode = {
  __typename?: 'ChainIOServiceInvoiceNode'
  apiPartner?: Maybe<ApiPartnerInterface>
  apiPartnerId?: Maybe<Scalars['UUID']['output']>
  apiPayload?: Maybe<ApiPayloadNode>
  apiPayloadId?: Maybe<Scalars['UUID']['output']>
  billToAddress1?: Maybe<Scalars['String']['output']>
  billToAddress2?: Maybe<Scalars['String']['output']>
  billToCity?: Maybe<Scalars['String']['output']>
  billToContactEmail?: Maybe<Scalars['String']['output']>
  billToContactName?: Maybe<Scalars['String']['output']>
  billToContactPhone?: Maybe<Scalars['String']['output']>
  billToCountry?: Maybe<Scalars['String']['output']>
  billToId?: Maybe<Scalars['String']['output']>
  billToName?: Maybe<Scalars['String']['output']>
  billToPostalCode?: Maybe<Scalars['String']['output']>
  billToState?: Maybe<Scalars['String']['output']>
  billToType?: Maybe<Scalars['String']['output']>
  cargowiseConfigId?: Maybe<Scalars['UUID']['output']>
  cargowiseInvoiceReference?: Maybe<Scalars['String']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoTransport?: Maybe<ChainIoTransportNode>
  chainIoTransportId?: Maybe<Scalars['UUID']['output']>
  cleanedInvoiceNumber?: Maybe<Scalars['String']['output']>
  cleanedVendorName?: Maybe<Scalars['String']['output']>
  costInvoices?: Maybe<ChainIoShipmentNode>
  costInvoicesId?: Maybe<Scalars['UUID']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dateProcessed?: Maybe<Scalars['DateTime']['output']>
  dueDate?: Maybe<Scalars['DateTime']['output']>
  forwarderReference?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceDateYear?: Maybe<Scalars['Int']['output']>
  invoiceIdentifier?: Maybe<Scalars['String']['output']>
  invoiceLines: ChainIoServiceInvoiceLineNodeConnection
  invoiceNumber?: Maybe<Scalars['String']['output']>
  invoiceType?: Maybe<Scalars['String']['output']>
  invoiceUniqueKey?: Maybe<Scalars['String']['output']>
  issuedDate?: Maybe<Scalars['DateTime']['output']>
  paymentTermDays?: Maybe<Scalars['Decimal']['output']>
  paymentTerms?: Maybe<Scalars['String']['output']>
  remitToAddress1?: Maybe<Scalars['String']['output']>
  remitToAddress2?: Maybe<Scalars['String']['output']>
  remitToCity?: Maybe<Scalars['String']['output']>
  remitToContactEmail?: Maybe<Scalars['String']['output']>
  remitToContactName?: Maybe<Scalars['String']['output']>
  remitToContactPhone?: Maybe<Scalars['String']['output']>
  remitToCountry?: Maybe<Scalars['String']['output']>
  remitToId?: Maybe<Scalars['String']['output']>
  remitToName?: Maybe<Scalars['String']['output']>
  remitToPostalCode?: Maybe<Scalars['String']['output']>
  remitToState?: Maybe<Scalars['String']['output']>
  remitToType?: Maybe<Scalars['String']['output']>
  revenueInvoices?: Maybe<ChainIoShipmentNode>
  revenueInvoicesId?: Maybe<Scalars['UUID']['output']>
  serviceInvoiceUid?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  tmsId?: Maybe<Scalars['String']['output']>
  totalDue?: Maybe<Scalars['Decimal']['output']>
}

export type ChainIoServiceInvoiceNodeConnection = {
  __typename?: 'ChainIOServiceInvoiceNodeConnection'
  edges: Array<ChainIoServiceInvoiceNodeEdge>
}

export type ChainIoServiceInvoiceNodeEdge = {
  __typename?: 'ChainIOServiceInvoiceNodeEdge'
  node: ChainIoServiceInvoiceNode
}

export type ChainIoShipmentConsolidationNode = {
  __typename?: 'ChainIOShipmentConsolidationNode'
  chainIoConsolidation: ChainIoConsolidationNode
  chainIoConsolidationId: Scalars['UUID']['output']
  chainIoShipment: ChainIoShipmentNode
  chainIoShipmentId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
}

export type ChainIoShipmentConsolidationNodeConnection = {
  __typename?: 'ChainIOShipmentConsolidationNodeConnection'
  edges: Array<ChainIoShipmentConsolidationNodeEdge>
}

export type ChainIoShipmentConsolidationNodeEdge = {
  __typename?: 'ChainIOShipmentConsolidationNodeEdge'
  node: ChainIoShipmentConsolidationNode
}

export type ChainIoShipmentLegNode = {
  __typename?: 'ChainIOShipmentLegNode'
  aircraftType?: Maybe<Scalars['String']['output']>
  arrivalActual?: Maybe<Scalars['DateTime']['output']>
  arrivalAt?: Maybe<ChainIoAddressNode>
  arrivalAtId?: Maybe<Scalars['UUID']['output']>
  arrivalEstimated?: Maybe<Scalars['DateTime']['output']>
  arrivalPort?: Maybe<ChainIoLocodeNode>
  arrivalPortId?: Maybe<Scalars['UUID']['output']>
  bookingStatus?: Maybe<Scalars['String']['output']>
  carrierBookingRef?: Maybe<Scalars['String']['output']>
  carrierScac?: Maybe<Scalars['String']['output']>
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  chainIoTransport?: Maybe<ChainIoTransportNode>
  chainIoTransportId?: Maybe<Scalars['UUID']['output']>
  copiedFromId?: Maybe<Scalars['UUID']['output']>
  copiedFromSourceConsolId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  departureActual?: Maybe<Scalars['DateTime']['output']>
  departureEstimated?: Maybe<Scalars['DateTime']['output']>
  departureFrom?: Maybe<ChainIoAddressNode>
  departureFromId?: Maybe<Scalars['UUID']['output']>
  id: Scalars['UUID']['output']
  ladingPort?: Maybe<ChainIoLocodeNode>
  ladingPortId?: Maybe<Scalars['UUID']['output']>
  legCarrier?: Maybe<ChainIoAddressNode>
  legCarrierId?: Maybe<Scalars['UUID']['output']>
  legNotes?: Maybe<Scalars['String']['output']>
  legSequenceNumber: Scalars['Int']['output']
  legType?: Maybe<Scalars['String']['output']>
  transportMode?: Maybe<Scalars['String']['output']>
  vesselName?: Maybe<Scalars['String']['output']>
  voyageNumber?: Maybe<Scalars['String']['output']>
}

export type ChainIoShipmentLegNodeConnection = {
  __typename?: 'ChainIOShipmentLegNodeConnection'
  edges: Array<ChainIoShipmentLegNodeEdge>
}

export type ChainIoShipmentLegNodeEdge = {
  __typename?: 'ChainIOShipmentLegNodeEdge'
  node: ChainIoShipmentLegNode
}

export type ChainIoShipmentNode = {
  __typename?: 'ChainIOShipmentNode'
  actionType?: Maybe<Scalars['String']['output']>
  actualChargeable?: Maybe<Scalars['Decimal']['output']>
  actualChargeableUom?: Maybe<Scalars['String']['output']>
  additionalReferences: ChainIoAdditionalReferenceNodeConnection
  agentReference?: Maybe<Scalars['String']['output']>
  amsFiler?: Maybe<Scalars['Boolean']['output']>
  apiPartner?: Maybe<ApiPartnerInterface>
  apiPartnerId?: Maybe<Scalars['UUID']['output']>
  apiPayload?: Maybe<ApiPayloadNode>
  apiPayloadId?: Maybe<Scalars['UUID']['output']>
  arrivalCfsAddress?: Maybe<ChainIoAddressNode>
  arrivalCfsAddressId?: Maybe<Scalars['UUID']['output']>
  arrivalEstimated?: Maybe<Scalars['DateTime']['output']>
  arrivalPort?: Maybe<ChainIoLocodeNode>
  arrivalPortActual?: Maybe<Scalars['DateTime']['output']>
  arrivalPortEstimated?: Maybe<Scalars['DateTime']['output']>
  arrivalPortId?: Maybe<Scalars['UUID']['output']>
  attachments: ChainIoAttachmentNodeConnection
  awbServiceLevel?: Maybe<Scalars['String']['output']>
  billOfLadingType?: Maybe<Scalars['String']['output']>
  bookingAcceptStatus?: Maybe<Scalars['String']['output']>
  bookingAcceptStatusDescription?: Maybe<Scalars['String']['output']>
  bookingAcceptStatusReasonCode?: Maybe<Scalars['String']['output']>
  bookingConfirmationNumber?: Maybe<Scalars['String']['output']>
  bookingConfirmedActual?: Maybe<Scalars['DateTime']['output']>
  bookingNotificationParty?: Maybe<ChainIoAddressNode>
  bookingNotificationPartyId?: Maybe<Scalars['UUID']['output']>
  bookingPortalReference?: Maybe<Scalars['String']['output']>
  bookingReference?: Maybe<Scalars['String']['output']>
  bookingSubmittedActual?: Maybe<Scalars['DateTime']['output']>
  branch?: Maybe<Scalars['String']['output']>
  branchName?: Maybe<Scalars['String']['output']>
  buyer?: Maybe<ChainIoAddressNode>
  buyerId?: Maybe<Scalars['UUID']['output']>
  cargoReadyActual?: Maybe<Scalars['DateTime']['output']>
  cargoReadyEstimated?: Maybe<Scalars['DateTime']['output']>
  cargowiseConfig?: Maybe<CargowiseConfigNode>
  cargowiseConfigId?: Maybe<Scalars['UUID']['output']>
  cargowiseJobShipmentPk?: Maybe<Scalars['UUID']['output']>
  carrierBookingNumber?: Maybe<Scalars['String']['output']>
  carrierInstructions?: Maybe<Scalars['String']['output']>
  carrierPaymentTerms?: Maybe<Scalars['String']['output']>
  carrierScac?: Maybe<Scalars['String']['output']>
  cfsAddress?: Maybe<ChainIoAddressNode>
  cfsAddressId?: Maybe<Scalars['UUID']['output']>
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoConsolidations: ChainIoConsolidationNodeConnection
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoShipmentConsolidations: ChainIoShipmentConsolidationNodeConnection
  chargeableWeight?: Maybe<Scalars['Decimal']['output']>
  cleanedForwarderReference?: Maybe<Scalars['String']['output']>
  coLoadBillOfLading?: Maybe<Scalars['String']['output']>
  coLoader?: Maybe<ChainIoAddressNode>
  coLoaderId?: Maybe<Scalars['UUID']['output']>
  commercialInvoiceNumber?: Maybe<Scalars['String']['output']>
  company?: Maybe<CompanyNode>
  companyId?: Maybe<Scalars['UUID']['output']>
  consignee?: Maybe<ChainIoAddressNode>
  consigneeId?: Maybe<Scalars['UUID']['output']>
  consigneeReference?: Maybe<Scalars['String']['output']>
  consignor?: Maybe<ChainIoAddressNode>
  consignorId?: Maybe<Scalars['UUID']['output']>
  containerMode?: Maybe<Scalars['String']['output']>
  containerizationType?: Maybe<Scalars['String']['output']>
  containers: ChainIoContainerNodeConnection
  contractNumber?: Maybe<Scalars['String']['output']>
  controllingAgent?: Maybe<ChainIoAddressNode>
  controllingAgentId?: Maybe<Scalars['UUID']['output']>
  controllingCustomer?: Maybe<ChainIoAddressNode>
  controllingCustomerId?: Maybe<Scalars['UUID']['output']>
  copiedFromChainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  copyCauseRawCargowiseOutboundId?: Maybe<Scalars['UUID']['output']>
  costInvoices: ChainIoServiceInvoiceNodeConnection
  createdBy?: Maybe<ChainIoUserNode>
  createdOn?: Maybe<Scalars['DateTime']['output']>
  currency?: Maybe<Scalars['String']['output']>
  customFields: ChainIoCustomFieldNodeConnection
  customsDirection?: Maybe<Scalars['String']['output']>
  cutoffTimeBol?: Maybe<Scalars['DateTime']['output']>
  cutoffTimePhysical?: Maybe<Scalars['DateTime']['output']>
  cutoffTimeShipmentInstructions?: Maybe<Scalars['DateTime']['output']>
  cutoffTimeVgm?: Maybe<Scalars['DateTime']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  dateProcessed?: Maybe<Scalars['DateTime']['output']>
  deliveryActualDate?: Maybe<Scalars['DateTime']['output']>
  deliveryAgent?: Maybe<ChainIoAddressNode>
  deliveryAgentId?: Maybe<Scalars['UUID']['output']>
  deliveryBookingRequestedDate?: Maybe<Scalars['DateTime']['output']>
  deliveryByDate?: Maybe<Scalars['DateTime']['output']>
  deliveryDropMode?: Maybe<Scalars['String']['output']>
  deliveryEstimatedDate?: Maybe<Scalars['DateTime']['output']>
  deliveryFromDate?: Maybe<Scalars['DateTime']['output']>
  deliveryMode?: Maybe<Scalars['String']['output']>
  deliveryNoteId?: Maybe<Scalars['String']['output']>
  deliveryTo?: Maybe<ChainIoAddressNode>
  deliveryToId?: Maybe<Scalars['UUID']['output']>
  deliveryTransportCompany?: Maybe<ChainIoAddressNode>
  deliveryTransportCompanyId?: Maybe<Scalars['UUID']['output']>
  department?: Maybe<Scalars['String']['output']>
  departmentName?: Maybe<Scalars['String']['output']>
  departureActual?: Maybe<Scalars['DateTime']['output']>
  departureCfsAddress?: Maybe<ChainIoAddressNode>
  departureCfsAddressId?: Maybe<Scalars['UUID']['output']>
  departureEstimated?: Maybe<Scalars['DateTime']['output']>
  descriptionOfGoods?: Maybe<Scalars['String']['output']>
  dimensionalWeight?: Maybe<Scalars['Decimal']['output']>
  dimesionalWeight?: Maybe<Scalars['Decimal']['output']>
  direction?: Maybe<Scalars['String']['output']>
  entryDetailsCode?: Maybe<Scalars['String']['output']>
  entryDetailsDescription?: Maybe<Scalars['String']['output']>
  entryDetailsNumber?: Maybe<Scalars['String']['output']>
  environmentalPollutantIndicator?: Maybe<Scalars['Boolean']['output']>
  eventBranchCode?: Maybe<Scalars['String']['output']>
  eventDate?: Maybe<Scalars['DateTime']['output']>
  eventDepartmentCode?: Maybe<Scalars['String']['output']>
  eventReference?: Maybe<Scalars['String']['output']>
  eventTypeCode?: Maybe<Scalars['String']['output']>
  eventUserCode?: Maybe<Scalars['String']['output']>
  eventUserName?: Maybe<Scalars['String']['output']>
  exportLicenseExpiresDate?: Maybe<Scalars['DateTime']['output']>
  exportLicenseIssuedDate?: Maybe<Scalars['DateTime']['output']>
  exportLicenseNumber?: Maybe<Scalars['String']['output']>
  exporterStatementCode?: Maybe<Scalars['String']['output']>
  exporterStatementDescription?: Maybe<Scalars['String']['output']>
  finalDestinationPort?: Maybe<ChainIoLocodeNode>
  finalDestinationPortId?: Maybe<Scalars['UUID']['output']>
  forwarderControllingBranchCode?: Maybe<Scalars['String']['output']>
  forwarderControllingBranchDescription?: Maybe<Scalars['String']['output']>
  forwarderControllingCompanyCode?: Maybe<Scalars['String']['output']>
  forwarderControllingCompanyDescription?: Maybe<Scalars['String']['output']>
  forwarderControllingDepartmentCode?: Maybe<Scalars['String']['output']>
  forwarderControllingDepartmentDescription?: Maybe<Scalars['String']['output']>
  forwarderOriginBranch?: Maybe<Scalars['String']['output']>
  forwarderOriginDepartment?: Maybe<Scalars['String']['output']>
  forwarderReference?: Maybe<Scalars['String']['output']>
  forwarderScac?: Maybe<Scalars['String']['output']>
  freightPaymentTerms?: Maybe<Scalars['String']['output']>
  freightSellingParty?: Maybe<ChainIoAddressNode>
  freightSellingPartyId?: Maybe<Scalars['UUID']['output']>
  freightTariffNumber?: Maybe<Scalars['String']['output']>
  fullDropOffParty?: Maybe<ChainIoAddressNode>
  fullDropOffPartyId?: Maybe<Scalars['UUID']['output']>
  gitRevision?: Maybe<Scalars['String']['output']>
  goodsValue?: Maybe<Scalars['Decimal']['output']>
  goodsValueCurrency?: Maybe<Scalars['String']['output']>
  grossWeightKgs?: Maybe<Scalars['Decimal']['output']>
  grossWeightLbs?: Maybe<Scalars['Decimal']['output']>
  hazmatIndicator?: Maybe<Scalars['Boolean']['output']>
  hotFlag?: Maybe<Scalars['Boolean']['output']>
  houseBill?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  importBroker?: Maybe<ChainIoAddressNode>
  importBrokerId?: Maybe<Scalars['UUID']['output']>
  importer?: Maybe<ChainIoAddressNode>
  importerId?: Maybe<Scalars['UUID']['output']>
  importerOfRecord?: Maybe<ChainIoAddressNode>
  importerOfRecordId?: Maybe<Scalars['UUID']['output']>
  incoTerm?: Maybe<Scalars['String']['output']>
  inners?: Maybe<Scalars['Decimal']['output']>
  innersUom?: Maybe<Scalars['String']['output']>
  insuranceValue?: Maybe<Scalars['Decimal']['output']>
  insuranceValueCurrency?: Maybe<Scalars['String']['output']>
  interimReceipt?: Maybe<Scalars['String']['output']>
  isBooking?: Maybe<Scalars['Boolean']['output']>
  isCancelled: Scalars['Boolean']['output']
  isDirectBooking?: Maybe<Scalars['Boolean']['output']>
  isForwardRegistered?: Maybe<Scalars['Boolean']['output']>
  isHighRisk?: Maybe<Scalars['Boolean']['output']>
  isShipping?: Maybe<Scalars['Boolean']['output']>
  isSplitShipment?: Maybe<Scalars['Boolean']['output']>
  ladingPort?: Maybe<ChainIoLocodeNode>
  ladingPortId?: Maybe<Scalars['UUID']['output']>
  loadingActual?: Maybe<Scalars['DateTime']['output']>
  loadingEstimated?: Maybe<Scalars['DateTime']['output']>
  localCartageContainerTerminalOperator?: Maybe<ChainIoAddressNode>
  localCartageContainerTerminalOperatorId?: Maybe<Scalars['UUID']['output']>
  localCartageYard?: Maybe<ChainIoAddressNode>
  localCartageYardExporter?: Maybe<ChainIoAddressNode>
  localCartageYardExporterId?: Maybe<Scalars['UUID']['output']>
  localCartageYardId?: Maybe<Scalars['UUID']['output']>
  localCartageYardImporter?: Maybe<ChainIoAddressNode>
  localCartageYardImporterId?: Maybe<Scalars['UUID']['output']>
  localClient?: Maybe<ChainIoAddressNode>
  localClientId?: Maybe<Scalars['UUID']['output']>
  logisticsManagerReference?: Maybe<Scalars['String']['output']>
  marksAndNumbers?: Maybe<Scalars['String']['output']>
  masterBill?: Maybe<Scalars['String']['output']>
  milestones: ChainIoMilestoneNodeConnection
  mustDeliverByDate?: Maybe<Scalars['DateTime']['output']>
  netWeightKgs?: Maybe<Scalars['Decimal']['output']>
  netWeightLbs?: Maybe<Scalars['Decimal']['output']>
  newJobStatus?: Maybe<Scalars['String']['output']>
  notifyParty?: Maybe<ChainIoAddressNode>
  notifyPartyId?: Maybe<Scalars['UUID']['output']>
  numberOfPackages?: Maybe<Scalars['Decimal']['output']>
  numberOfPieces?: Maybe<Scalars['Decimal']['output']>
  operationsRep: Scalars['String']['output']
  operationsStaffCode?: Maybe<Scalars['String']['output']>
  operationsStaffName?: Maybe<Scalars['String']['output']>
  orders: ChainIoOrderNodeConnection
  originGateInActual?: Maybe<Scalars['DateTime']['output']>
  originGateInEstimated?: Maybe<Scalars['DateTime']['output']>
  outOfGaugeFreightIndicator?: Maybe<Scalars['Boolean']['output']>
  overseasAgent?: Maybe<ChainIoAddressNode>
  overseasAgentId?: Maybe<Scalars['UUID']['output']>
  packageGroup: ChainIoPackageGroupNodeConnection
  packingLines: ChainIoPackingLineNodeConnection
  packs?: Maybe<Scalars['Decimal']['output']>
  packsUom?: Maybe<Scalars['String']['output']>
  parentShipment?: Maybe<ChainIoShipmentNode>
  parentShipmentId?: Maybe<Scalars['UUID']['output']>
  pickupActualDate?: Maybe<Scalars['DateTime']['output']>
  pickupAgent?: Maybe<ChainIoAddressNode>
  pickupAgentId?: Maybe<Scalars['UUID']['output']>
  pickupAppointmentDate?: Maybe<Scalars['DateTime']['output']>
  pickupBookingRequestedDate?: Maybe<Scalars['DateTime']['output']>
  pickupByDate?: Maybe<Scalars['DateTime']['output']>
  pickupDropMode?: Maybe<Scalars['String']['output']>
  pickupEstimatedDate?: Maybe<Scalars['DateTime']['output']>
  pickupFrom?: Maybe<ChainIoAddressNode>
  pickupFromDate?: Maybe<Scalars['DateTime']['output']>
  pickupFromId?: Maybe<Scalars['UUID']['output']>
  pickupTransportCompany?: Maybe<ChainIoAddressNode>
  pickupTransportCompanyId?: Maybe<Scalars['UUID']['output']>
  poNumbers?: Maybe<Scalars['String']['output']>
  portOfDestinationCode?: Maybe<Scalars['String']['output']>
  portOfDestinationName?: Maybe<Scalars['String']['output']>
  portOfDischargeCode?: Maybe<Scalars['String']['output']>
  portOfDischargeName?: Maybe<Scalars['String']['output']>
  portOfLoadingCode?: Maybe<Scalars['String']['output']>
  portOfLoadingName?: Maybe<Scalars['String']['output']>
  portOfOriginCode?: Maybe<Scalars['String']['output']>
  portOfOriginName?: Maybe<Scalars['String']['output']>
  purchaseOrders: ChainIoPurchaseOrderNodeConnection
  rawCargowiseOutbound?: Maybe<RawCargowiseOutboundNode>
  rawCargowiseOutboundId?: Maybe<Scalars['UUID']['output']>
  releaseType?: Maybe<Scalars['String']['output']>
  releaseTypeCode?: Maybe<Scalars['String']['output']>
  releaseTypeDescription?: Maybe<Scalars['String']['output']>
  revenueInvoices: ChainIoServiceInvoiceNodeConnection
  salesRep: Scalars['String']['output']
  salesStaffCode?: Maybe<Scalars['String']['output']>
  salesStaffName?: Maybe<Scalars['String']['output']>
  sentDate?: Maybe<Scalars['DateTime']['output']>
  serviceLevel?: Maybe<Scalars['String']['output']>
  serviceLevelDescription?: Maybe<Scalars['String']['output']>
  shipFrom?: Maybe<ChainIoAddressNode>
  shipFromId?: Maybe<Scalars['UUID']['output']>
  shipTo?: Maybe<ChainIoAddressNode>
  shipToId?: Maybe<Scalars['UUID']['output']>
  shipWindowEnd?: Maybe<Scalars['DateTime']['output']>
  shipWindowStart?: Maybe<Scalars['DateTime']['output']>
  shipmentLegs: ChainIoShipmentLegNodeConnection
  shipmentReceivingAgent?: Maybe<ChainIoAddressNode>
  shipmentReceivingAgentId?: Maybe<Scalars['UUID']['output']>
  shipmentSendingAgent?: Maybe<ChainIoAddressNode>
  shipmentSendingAgentId?: Maybe<Scalars['UUID']['output']>
  shipmentType?: Maybe<Scalars['String']['output']>
  shipmentTypeCode?: Maybe<Scalars['String']['output']>
  shipper?: Maybe<ChainIoAddressNode>
  shipperId?: Maybe<Scalars['UUID']['output']>
  shipperReference?: Maybe<Scalars['String']['output']>
  sourceAccountUid?: Maybe<Scalars['String']['output']>
  sourceSystemUid?: Maybe<Scalars['String']['output']>
  splitOriginalBookingNumber?: Maybe<Scalars['String']['output']>
  splitReasonCode?: Maybe<Scalars['String']['output']>
  splitReasonDescription?: Maybe<Scalars['String']['output']>
  splitSequenceDescription?: Maybe<Scalars['String']['output']>
  subShipments: ChainIoShipmentNodeConnection
  supplier?: Maybe<ChainIoAddressNode>
  supplierId?: Maybe<Scalars['UUID']['output']>
  syncMatchCriteria?: Maybe<Scalars['JSONString']['output']>
  syncSource?: Maybe<SyncSource>
  systemDateClosed?: Maybe<Scalars['DateTime']['output']>
  systemDateCreated?: Maybe<Scalars['DateTime']['output']>
  systemDateEdited?: Maybe<Scalars['DateTime']['output']>
  temperatureControlledIndicator?: Maybe<Scalars['Boolean']['output']>
  tmsId?: Maybe<Scalars['String']['output']>
  transactionTypeCode?: Maybe<Scalars['String']['output']>
  transmissionUid?: Maybe<Scalars['String']['output']>
  transportMode?: Maybe<Scalars['String']['output']>
  transports: ChainIoTransportNodeConnection
  triggerCount?: Maybe<Scalars['Int']['output']>
  triggerDate?: Maybe<Scalars['DateTime']['output']>
  triggerDescription?: Maybe<Scalars['String']['output']>
  triggerType?: Maybe<Scalars['String']['output']>
  vesselName?: Maybe<Scalars['String']['output']>
  volume?: Maybe<Scalars['Decimal']['output']>
  volumeCbms?: Maybe<Scalars['Decimal']['output']>
  volumeUom?: Maybe<Scalars['String']['output']>
  voyageFlightNumber?: Maybe<Scalars['String']['output']>
  voyageNumber?: Maybe<Scalars['String']['output']>
  weight?: Maybe<Scalars['Decimal']['output']>
  weightUom?: Maybe<Scalars['String']['output']>
}

export type ChainIoShipmentNodeChainIoConsolidationNodeChainIoCustomsDeclarationNode =
  | ChainIoConsolidationNode
  | ChainIoCustomsDeclarationNode
  | ChainIoShipmentNode

export type ChainIoShipmentNodeConnection = {
  __typename?: 'ChainIOShipmentNodeConnection'
  edges: Array<ChainIoShipmentNodeEdge>
}

export type ChainIoShipmentNodeEdge = {
  __typename?: 'ChainIOShipmentNodeEdge'
  node: ChainIoShipmentNode
}

export type ChainIoTransactionLineNode = {
  __typename?: 'ChainIOTransactionLineNode'
  apiPartner: ApiPartnerInterface
  apiPartnerId: Scalars['UUID']['output']
  branchCode?: Maybe<Scalars['String']['output']>
  branchDescription?: Maybe<Scalars['String']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoTransaction: ChainIoTransactionNode
  chainIoTransactionId: Scalars['UUID']['output']
  chargeCode?: Maybe<Scalars['String']['output']>
  chargeDescription?: Maybe<Scalars['String']['output']>
  chargeExchangeRate?: Maybe<Scalars['Decimal']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dateProcessed?: Maybe<Scalars['DateTime']['output']>
  departmentCode?: Maybe<Scalars['String']['output']>
  departmentDescription?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  displaySequence?: Maybe<Scalars['Int']['output']>
  generalLedgerAccountCode?: Maybe<Scalars['String']['output']>
  generalLedgerAccountDescription?: Maybe<Scalars['String']['output']>
  gitRevision?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  localAmount?: Maybe<Scalars['Decimal']['output']>
  localAmountWithTax?: Maybe<Scalars['Decimal']['output']>
  localCurrency?: Maybe<Scalars['String']['output']>
  localTaxAmount?: Maybe<Scalars['Decimal']['output']>
  overseasAmount?: Maybe<Scalars['Decimal']['output']>
  overseasAmountWithTax?: Maybe<Scalars['Decimal']['output']>
  overseasCurrency?: Maybe<Scalars['String']['output']>
  overseasTaxAmount?: Maybe<Scalars['Decimal']['output']>
  recognitionDate?: Maybe<Scalars['DateTime']['output']>
  recognitionType?: Maybe<Scalars['String']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  syncMatchCriteria?: Maybe<Scalars['JSONString']['output']>
  syncSource?: Maybe<SyncSource>
  taxRate?: Maybe<Scalars['Decimal']['output']>
  taxRateCode?: Maybe<Scalars['String']['output']>
}

export type ChainIoTransactionLineNodeConnection = {
  __typename?: 'ChainIOTransactionLineNodeConnection'
  edges: Array<ChainIoTransactionLineNodeEdge>
}

export type ChainIoTransactionLineNodeEdge = {
  __typename?: 'ChainIOTransactionLineNodeEdge'
  node: ChainIoTransactionLineNode
}

export type ChainIoTransactionNode = {
  __typename?: 'ChainIOTransactionNode'
  apiPartner: ApiPartnerInterface
  apiPartnerId: Scalars['UUID']['output']
  branchCode?: Maybe<Scalars['String']['output']>
  branchDescription?: Maybe<Scalars['String']['output']>
  cargowiseTransactionReference?: Maybe<Scalars['String']['output']>
  chainIoTransactionLines: ChainIoTransactionLineNodeConnection
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dateProcessed?: Maybe<Scalars['DateTime']['output']>
  departmentCode?: Maybe<Scalars['String']['output']>
  departmentDescription?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['DateTime']['output']>
  exchangeRate?: Maybe<Scalars['Decimal']['output']>
  fullyPaidDate?: Maybe<Scalars['DateTime']['output']>
  gitRevision?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  isCancelled?: Maybe<Scalars['Boolean']['output']>
  ledger?: Maybe<Scalars['String']['output']>
  localAmount?: Maybe<Scalars['Decimal']['output']>
  localAmountWithTax?: Maybe<Scalars['Decimal']['output']>
  localCurrency?: Maybe<Scalars['String']['output']>
  localOutstandingAmount?: Maybe<Scalars['Decimal']['output']>
  localTaxAmount?: Maybe<Scalars['Decimal']['output']>
  organization?: Maybe<ChainIoAddressNode>
  organizationId?: Maybe<Scalars['UUID']['output']>
  originalCargowiseTransactionReference?: Maybe<Scalars['String']['output']>
  originalTransactionDate?: Maybe<Scalars['DateTime']['output']>
  originalTransactionNumber?: Maybe<Scalars['String']['output']>
  overseasAmount?: Maybe<Scalars['Decimal']['output']>
  overseasAmountWithTax?: Maybe<Scalars['Decimal']['output']>
  overseasCurrency?: Maybe<Scalars['String']['output']>
  overseasTaxAmount?: Maybe<Scalars['Decimal']['output']>
  partialTransactionKey: Scalars['String']['output']
  postDate?: Maybe<Scalars['DateTime']['output']>
  rawCargowiseOutbound?: Maybe<RawCargowiseOutboundNode>
  rawCargowiseOutboundId?: Maybe<Scalars['UUID']['output']>
  syncMatchCriteria?: Maybe<Scalars['JSONString']['output']>
  syncSource?: Maybe<SyncSource>
  transactionDate?: Maybe<Scalars['DateTime']['output']>
  transactionKey: Scalars['String']['output']
  transactionNumber?: Maybe<Scalars['String']['output']>
  transactionType?: Maybe<Scalars['String']['output']>
}

export type ChainIoTransportCostNode = {
  __typename?: 'ChainIOTransportCostNode'
  amount?: Maybe<Scalars['Decimal']['output']>
  chainIoTransport: ChainIoTransportNode
  chainIoTransportId: Scalars['UUID']['output']
  chargeCode?: Maybe<Scalars['String']['output']>
  chargeDescription?: Maybe<Scalars['String']['output']>
  costPostDate?: Maybe<Scalars['DateTime']['output']>
  costRecognitionDate?: Maybe<Scalars['DateTime']['output']>
  costRecognitionType?: Maybe<Scalars['String']['output']>
  creditorCode?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dueDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['UUID']['output']
  invoiceDate?: Maybe<Scalars['DateTime']['output']>
  invoiceNumber?: Maybe<Scalars['String']['output']>
  isPosted?: Maybe<Scalars['Boolean']['output']>
  sellAmount?: Maybe<Scalars['Decimal']['output']>
  sellCurrency?: Maybe<Scalars['String']['output']>
  sellIsPosted?: Maybe<Scalars['Boolean']['output']>
  sellPostDate?: Maybe<Scalars['DateTime']['output']>
  sellRecognitionDate?: Maybe<Scalars['DateTime']['output']>
  sellRecognitionType?: Maybe<Scalars['String']['output']>
  sellTaxRateCode?: Maybe<Scalars['String']['output']>
  taxRate?: Maybe<Scalars['Decimal']['output']>
  taxRateCode?: Maybe<Scalars['String']['output']>
}

export type ChainIoTransportCostNodeConnection = {
  __typename?: 'ChainIOTransportCostNodeConnection'
  edges: Array<ChainIoTransportCostNodeEdge>
}

export type ChainIoTransportCostNodeEdge = {
  __typename?: 'ChainIOTransportCostNodeEdge'
  node: ChainIoTransportCostNode
}

export type ChainIoTransportNode = {
  __typename?: 'ChainIOTransportNode'
  additionalReferences: ChainIoAdditionalReferenceNodeConnection
  agentReference?: Maybe<Scalars['String']['output']>
  apiPartner?: Maybe<ApiPartnerInterface>
  apiPartnerId?: Maybe<Scalars['UUID']['output']>
  arrivalDate?: Maybe<Scalars['DateTime']['output']>
  arrivalEstimated?: Maybe<Scalars['DateTime']['output']>
  arrivalPort?: Maybe<Scalars['String']['output']>
  bookingReference?: Maybe<Scalars['String']['output']>
  branch?: Maybe<Scalars['String']['output']>
  branchName?: Maybe<Scalars['String']['output']>
  brokerStaffCode?: Maybe<Scalars['String']['output']>
  brokerStaffName?: Maybe<Scalars['String']['output']>
  cargowiseConfig?: Maybe<CargowiseConfigNode>
  cargowiseConfigId?: Maybe<Scalars['UUID']['output']>
  carrierBookingReference?: Maybe<Scalars['String']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  cleanedForwarderReference?: Maybe<Scalars['String']['output']>
  company?: Maybe<CompanyNode>
  companyId?: Maybe<Scalars['UUID']['output']>
  consignee?: Maybe<ChainIoAddressNode>
  consigneeId?: Maybe<Scalars['UUID']['output']>
  consolNumber?: Maybe<Scalars['String']['output']>
  containerMode?: Maybe<Scalars['String']['output']>
  containers: ChainIoContainerNodeConnection
  controllingAgent?: Maybe<ChainIoAddressNode>
  controllingAgentId?: Maybe<Scalars['UUID']['output']>
  controllingCustomer?: Maybe<ChainIoAddressNode>
  controllingCustomerId?: Maybe<Scalars['UUID']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dateClosed?: Maybe<Scalars['DateTime']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  dateProcessed?: Maybe<Scalars['DateTime']['output']>
  deliveryByDate?: Maybe<Scalars['DateTime']['output']>
  deliveryFromDate?: Maybe<Scalars['DateTime']['output']>
  deliveryTransportCompany?: Maybe<ChainIoAddressNode>
  deliveryTransportCompanyId?: Maybe<Scalars['UUID']['output']>
  department?: Maybe<Scalars['String']['output']>
  departmentName?: Maybe<Scalars['String']['output']>
  departureEstimated?: Maybe<Scalars['DateTime']['output']>
  descriptionOfGoods?: Maybe<Scalars['String']['output']>
  destinationPort?: Maybe<Scalars['String']['output']>
  direction?: Maybe<Scalars['String']['output']>
  dischargePort?: Maybe<Scalars['String']['output']>
  entryDate?: Maybe<Scalars['DateTime']['output']>
  entryPort?: Maybe<Scalars['String']['output']>
  entryType?: Maybe<Scalars['String']['output']>
  eventBranchCode?: Maybe<Scalars['String']['output']>
  eventDate?: Maybe<Scalars['DateTime']['output']>
  eventDepartmentCode?: Maybe<Scalars['String']['output']>
  eventReference?: Maybe<Scalars['String']['output']>
  eventTypeCode?: Maybe<Scalars['String']['output']>
  eventUserCode?: Maybe<Scalars['String']['output']>
  eventUserName?: Maybe<Scalars['String']['output']>
  exportCountry?: Maybe<Scalars['String']['output']>
  fileBranch?: Maybe<Scalars['String']['output']>
  fileBranchName?: Maybe<Scalars['String']['output']>
  filer?: Maybe<Scalars['String']['output']>
  finalDestination?: Maybe<Scalars['String']['output']>
  firstOrigin?: Maybe<Scalars['String']['output']>
  forwarderReference?: Maybe<Scalars['String']['output']>
  goodsValue?: Maybe<Scalars['Decimal']['output']>
  goodsValueCurrency?: Maybe<Scalars['String']['output']>
  houseBill?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  importer?: Maybe<ChainIoAddressNode>
  importerId?: Maybe<Scalars['UUID']['output']>
  importerOfRecord?: Maybe<ChainIoAddressNode>
  importerOfRecordId?: Maybe<Scalars['UUID']['output']>
  incoTerm?: Maybe<Scalars['String']['output']>
  isCancelled: Scalars['Boolean']['output']
  jobStatus?: Maybe<Scalars['String']['output']>
  loadingDate?: Maybe<Scalars['DateTime']['output']>
  loadingPort?: Maybe<Scalars['String']['output']>
  localClient?: Maybe<ChainIoAddressNode>
  localClientId?: Maybe<Scalars['UUID']['output']>
  marksAndNumbers?: Maybe<Scalars['String']['output']>
  masterBill?: Maybe<Scalars['String']['output']>
  milestones: ChainIoMilestoneNodeConnection
  operationsStaffCode?: Maybe<Scalars['String']['output']>
  operationsStaffName?: Maybe<Scalars['String']['output']>
  orderNumber?: Maybe<Scalars['String']['output']>
  orders: ChainIoOrderNodeConnection
  originCountry?: Maybe<Scalars['String']['output']>
  originPort?: Maybe<Scalars['String']['output']>
  packs?: Maybe<Scalars['Decimal']['output']>
  packsUnit?: Maybe<Scalars['String']['output']>
  pickupByDate?: Maybe<Scalars['DateTime']['output']>
  pickupFromDate?: Maybe<Scalars['DateTime']['output']>
  pickupTransportCompany?: Maybe<ChainIoAddressNode>
  pickupTransportCompanyId?: Maybe<Scalars['UUID']['output']>
  rawCargowiseOutbound?: Maybe<RawCargowiseOutboundNode>
  rawCargowiseOutboundId?: Maybe<Scalars['UUID']['output']>
  releaseType?: Maybe<Scalars['String']['output']>
  salesStaffCode?: Maybe<Scalars['String']['output']>
  salesStaffName?: Maybe<Scalars['String']['output']>
  serviceInvoices: ChainIoServiceInvoiceNodeConnection
  serviceLevel?: Maybe<Scalars['String']['output']>
  serviceLevelDescription?: Maybe<Scalars['String']['output']>
  shipmentLegs: ChainIoShipmentLegNodeConnection
  shipper?: Maybe<ChainIoAddressNode>
  shipperId?: Maybe<Scalars['UUID']['output']>
  supplier?: Maybe<ChainIoAddressNode>
  supplierId?: Maybe<Scalars['UUID']['output']>
  systemDateClosed?: Maybe<Scalars['DateTime']['output']>
  systemDateCreated?: Maybe<Scalars['DateTime']['output']>
  tmsId?: Maybe<Scalars['String']['output']>
  transportCosts: ChainIoTransportCostNodeConnection
  transportMode?: Maybe<Scalars['String']['output']>
  transportType?: Maybe<Scalars['String']['output']>
  transportTypeDescription?: Maybe<Scalars['String']['output']>
  triggerCount?: Maybe<Scalars['Int']['output']>
  triggerDate?: Maybe<Scalars['DateTime']['output']>
  triggerDescription?: Maybe<Scalars['String']['output']>
  triggerType?: Maybe<Scalars['String']['output']>
  vesselName?: Maybe<Scalars['String']['output']>
  volume?: Maybe<Scalars['Decimal']['output']>
  volumeUnit?: Maybe<Scalars['String']['output']>
  voyageNumber?: Maybe<Scalars['String']['output']>
  weight?: Maybe<Scalars['Decimal']['output']>
  weightUnit?: Maybe<Scalars['String']['output']>
}

export type ChainIoTransportNodeConnection = {
  __typename?: 'ChainIOTransportNodeConnection'
  edges: Array<ChainIoTransportNodeEdge>
}

export type ChainIoTransportNodeEdge = {
  __typename?: 'ChainIOTransportNodeEdge'
  node: ChainIoTransportNode
}

export type ChainIoUserNode = {
  __typename?: 'ChainIOUserNode'
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  username?: Maybe<Scalars['String']['output']>
}

export type ChargeCodeNode = SearchableRecordResultNode & {
  __typename?: 'ChargeCodeNode'
  apiPartner?: Maybe<ApiPartnerInterface>
  apiPartnerId?: Maybe<Scalars['UUID']['output']>
  cargowiseConfig?: Maybe<CargowiseConfigNode>
  cargowiseConfigId?: Maybe<Scalars['UUID']['output']>
  chargeCodeUploadAsyncTask?: Maybe<ChargeCodeUploadAsyncTaskNode>
  chargeCodeUploadAsyncTaskId?: Maybe<Scalars['UUID']['output']>
  chargeVendor: ChargeVendorNode
  chargeVendorId: Scalars['UUID']['output']
  cleanedDescription: Scalars['String']['output']
  code: Scalars['String']['output']
  company: CompanyNode
  companyId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['UUID']['output']
  label: Scalars['String']['output']
  tax?: Maybe<TaxNode>
  taxId?: Maybe<Scalars['UUID']['output']>
}

export type ChargeCodeNodeConnection = {
  __typename?: 'ChargeCodeNodeConnection'
  edges: Array<ChargeCodeNodeEdge>
}

export type ChargeCodeNodeEdge = {
  __typename?: 'ChargeCodeNodeEdge'
  node: ChargeCodeNode
}

export type ChargeCodeSearchResult = SearchableRecordResultNode & {
  __typename?: 'ChargeCodeSearchResult'
  code: Scalars['String']['output']
  description: Scalars['String']['output']
  id: Scalars['UUID']['output']
}

export enum ChargeCodeUploadAsyncStatus {
  Done = 'DONE',
  Error = 'ERROR',
  Pending = 'PENDING',
}

export type ChargeCodeUploadAsyncTaskNode = {
  __typename?: 'ChargeCodeUploadAsyncTaskNode'
  company: CompanyNode
  companyId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  percentDone?: Maybe<Scalars['Int']['output']>
  status: ChargeCodeUploadAsyncStatus
  user?: Maybe<UserNode>
  userId?: Maybe<Scalars['UUID']['output']>
}

export type ChargeCodeV2Node = SearchableRecordResultNode & {
  __typename?: 'ChargeCodeV2Node'
  apiPartner?: Maybe<ApiPartnerInterface>
  apiPartnerId?: Maybe<Scalars['UUID']['output']>
  chargeCodeUploadAsyncTask?: Maybe<ChargeCodeUploadAsyncTaskNode>
  chargeCodeUploadAsyncTaskId?: Maybe<Scalars['UUID']['output']>
  chargeCodeVendorOverrides: ChargeCodeVendorOverrideNodeConnection
  cleanedDescription: Scalars['String']['output']
  code: Scalars['String']['output']
  company: CompanyNode
  companyId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['UUID']['output']
  label: Scalars['String']['output']
  tax?: Maybe<TaxNode>
  taxId?: Maybe<Scalars['UUID']['output']>
}

export type ChargeCodeV2NodeConnection = {
  __typename?: 'ChargeCodeV2NodeConnection'
  edges: Array<ChargeCodeV2NodeEdge>
}

export type ChargeCodeV2NodeEdge = {
  __typename?: 'ChargeCodeV2NodeEdge'
  node: ChargeCodeV2Node
}

export type ChargeCodeVendorOverrideNode = SearchableRecordResultNode & {
  __typename?: 'ChargeCodeVendorOverrideNode'
  chargeCode: ChargeCodeV2Node
  chargeCodeId: Scalars['UUID']['output']
  chargeVendor: ChargeVendorNode
  chargeVendorId: Scalars['UUID']['output']
  cleanedDescription: Scalars['String']['output']
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['UUID']['output']
  label: Scalars['String']['output']
}

export type ChargeCodeVendorOverrideNodeConnection = {
  __typename?: 'ChargeCodeVendorOverrideNodeConnection'
  edges: Array<ChargeCodeVendorOverrideNodeEdge>
}

export type ChargeCodeVendorOverrideNodeEdge = {
  __typename?: 'ChargeCodeVendorOverrideNodeEdge'
  node: ChargeCodeVendorOverrideNode
}

export type ChargeContractNode = {
  __typename?: 'ChargeContractNode'
  chargeRates: ChargeRateNodeConnection
  chargeVendor: ChargeVendorNode
  chargeVendorId: Scalars['UUID']['output']
  clientContractRefNo?: Maybe<Scalars['String']['output']>
  company: CompanyNode
  companyId: Scalars['UUID']['output']
  contractId: Scalars['String']['output']
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  effectivity?: Maybe<Scalars['DateTime']['output']>
  expiryDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['UUID']['output']
}

export type ChargeContractNodeConnection = {
  __typename?: 'ChargeContractNodeConnection'
  edges: Array<ChargeContractNodeEdge>
}

export type ChargeContractNodeEdge = {
  __typename?: 'ChargeContractNodeEdge'
  node: ChargeContractNode
}

export type ChargeDetail = {
  __typename?: 'ChargeDetail'
  chainIoSiLineId?: Maybe<Scalars['UUID']['output']>
  chargeCode?: Maybe<Scalars['String']['output']>
  chargeCost?: Maybe<Scalars['String']['output']>
  chargeDescription?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceDate?: Maybe<Scalars['String']['output']>
  invoiceNumber?: Maybe<Scalars['String']['output']>
  isPosted?: Maybe<Scalars['Boolean']['output']>
  overseasSellAmount?: Maybe<Scalars['String']['output']>
  sellCurrency?: Maybe<Scalars['String']['output']>
  taxId?: Maybe<Scalars['String']['output']>
  taxRate?: Maybe<Scalars['String']['output']>
  vendor?: Maybe<Scalars['String']['output']>
  vendorName?: Maybe<Scalars['String']['output']>
}

export type ChargeRateNode = SearchableRecordResultNode & {
  __typename?: 'ChargeRateNode'
  amendmentNo?: Maybe<Scalars['Int']['output']>
  chargeContract: ChargeContractNode
  chargeContractId: Scalars['UUID']['output']
  commodity?: Maybe<Scalars['String']['output']>
  company: CompanyNode
  companyId: Scalars['UUID']['output']
  containerSize?: Maybe<Scalars['String']['output']>
  containerType?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  destinationTransportMode?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  namedAccount?: Maybe<Scalars['String']['output']>
  originTransportMode?: Maybe<Scalars['String']['output']>
  placeOfDelivery?: Maybe<Scalars['String']['output']>
  placeOfReceipt?: Maybe<Scalars['String']['output']>
  portOfDischarge?: Maybe<Scalars['String']['output']>
  portOfLoading?: Maybe<Scalars['String']['output']>
  rateCategory?: Maybe<Scalars['String']['output']>
  rateCode: Scalars['String']['output']
  rateCost: Scalars['Decimal']['output']
  rateCurrency?: Maybe<Scalars['String']['output']>
  rateEffectiveDate?: Maybe<Scalars['DateTime']['output']>
  rateExpiryDate?: Maybe<Scalars['DateTime']['output']>
  rateUnit?: Maybe<Scalars['String']['output']>
  shipmentRoute?: Maybe<Scalars['String']['output']>
  transportMode?: Maybe<Scalars['String']['output']>
}

export type ChargeRateNodeConnection = {
  __typename?: 'ChargeRateNodeConnection'
  edges: Array<ChargeRateNodeEdge>
}

export type ChargeRateNodeEdge = {
  __typename?: 'ChargeRateNodeEdge'
  node: ChargeRateNode
}

export type ChargeVendorNode = SearchableRecordResultNode & {
  __typename?: 'ChargeVendorNode'
  chargeCodeUploadAsyncTask?: Maybe<ChargeCodeUploadAsyncTaskNode>
  chargeCodeUploadAsyncTaskId?: Maybe<Scalars['UUID']['output']>
  chargeCodeVendorOverrides: ChargeCodeVendorOverrideNodeConnection
  chargeCodes: ChargeCodeNodeConnection
  chargeContracts: ChargeContractNodeConnection
  cleanedName: Scalars['String']['output']
  code: Scalars['String']['output']
  company: CompanyNode
  companyId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  label: Scalars['String']['output']
  name: Scalars['String']['output']
  type?: Maybe<Scalars['String']['output']>
}

export type CheckShipmentInfoAsyncBatchNode = {
  __typename?: 'CheckShipmentInfoAsyncBatchNode'
  checkShipmentInfoMatchCriterias: CheckShipmentInfoMatchCriteriaNodeConnection
  dateCompleted?: Maybe<Scalars['DateTime']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job?: Maybe<JobNode>
  jobId?: Maybe<Scalars['UUID']['output']>
  numDone: Scalars['Int']['output']
  numTasks: Scalars['Int']['output']
  status: CheckShipmentInfoAsyncStatus
  userCreated: UserNode
  userCreatedId: Scalars['UUID']['output']
}

export enum CheckShipmentInfoAsyncStatus {
  Done = 'DONE',
  Error = 'ERROR',
  Pending = 'PENDING',
}

export type CheckShipmentInfoMatchCriteriaNode = {
  __typename?: 'CheckShipmentInfoMatchCriteriaNode'
  cargowiseModule?: Maybe<Scalars['String']['output']>
  carrierBookingNumber?: Maybe<Scalars['String']['output']>
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  checkShipmentInfoAsyncBatch: CheckShipmentInfoAsyncBatchNode
  checkShipmentInfoAsyncBatchId: Scalars['UUID']['output']
  consolNumber?: Maybe<Scalars['String']['output']>
  containerNumber?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  hblNumber?: Maybe<Scalars['String']['output']>
  host?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  jobCheckShipmentInfo?: Maybe<JobCheckShipmentInfoNode>
  mblNumber?: Maybe<Scalars['String']['output']>
  orderNumber?: Maybe<Scalars['String']['output']>
  rawCargowiseInbound?: Maybe<RawCargowiseInboundNode>
  rawCargowiseInboundId?: Maybe<Scalars['UUID']['output']>
  rawCargowiseOutbound?: Maybe<RawCargowiseOutboundNode>
  rawCargowiseOutboundId?: Maybe<Scalars['UUID']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  soaCheckShipmentInfoLineItemSnapshots: SoaCheckShipmentInfoLineItemSnapshotNodeConnection
}

export type CheckShipmentInfoMatchCriteriaNodeConnection = {
  __typename?: 'CheckShipmentInfoMatchCriteriaNodeConnection'
  edges: Array<CheckShipmentInfoMatchCriteriaNodeEdge>
}

export type CheckShipmentInfoMatchCriteriaNodeEdge = {
  __typename?: 'CheckShipmentInfoMatchCriteriaNodeEdge'
  node: CheckShipmentInfoMatchCriteriaNode
}

export type ColumnConversion = {
  __typename?: 'ColumnConversion'
  columnName: Scalars['String']['output']
  conversions: Array<StringConversion>
}

export type Company = {
  __typename?: 'Company'
  address: Scalars['String']['output']
  cargoDescription: Scalars['String']['output']
  code: Scalars['String']['output']
  name: Scalars['String']['output']
  searchRegex: Scalars['String']['output']
  shortcut: Scalars['String']['output']
}

export type CompanyNode = {
  __typename?: 'CompanyNode'
  address?: Maybe<Scalars['String']['output']>
  apiPartners: ApiPartnerInterfaceConnection
  cargowiseConfigs: CargowiseConfigNodeConnection
  cargowiseFileTypes: CargowiseFileTypeNodeConnection
  companyUsers: CompanyUserNodeConnection
  country?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  deleted: Scalars['Boolean']['output']
  documentTypes: DocumentTypeNodeConnection
  documents: DocumentNodeConnection
  email?: Maybe<Scalars['String']['output']>
  employees: UserNodeConnection
  enableAutocropImageMargins?: Maybe<Scalars['Boolean']['output']>
  enableEdocsTab?: Maybe<Scalars['Boolean']['output']>
  enableManyToManyShipmentConsolMatching?: Maybe<Scalars['Boolean']['output']>
  files: FileNodeConnection
  hasAccessToInsights?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['UUID']['output']
  jobTemplates: JobTemplateNodeConnection
  logo?: Maybe<Scalars['String']['output']>
  logoUri?: Maybe<Scalars['String']['output']>
  metabaseDashboardId?: Maybe<Scalars['String']['output']>
  metabaseMarginAttributionsDashboardId?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  primaryThemeColor?: Maybe<Scalars['String']['output']>
  publicShareMetabaseDashboardKey?: Maybe<Scalars['String']['output']>
  shipperFacingSlug: Scalars['String']['output']
  shipperFacingTenantId?: Maybe<Scalars['String']['output']>
  tasks: TaskNodeConnection
  usesChargeCodeV2?: Maybe<Scalars['Boolean']['output']>
}

export type CompanyNodeConnection = {
  __typename?: 'CompanyNodeConnection'
  edges: Array<CompanyNodeEdge>
}

export type CompanyNodeEdge = {
  __typename?: 'CompanyNodeEdge'
  node: CompanyNode
}

export type CompanyPort = {
  __typename?: 'CompanyPort'
  companyCode: Scalars['String']['output']
  freeDay: Scalars['String']['output']
  portCode: Scalars['String']['output']
}

export type CompanyUserNode = {
  __typename?: 'CompanyUserNode'
  company: CompanyNode
  companyId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type CompanyUserNodeConnection = {
  __typename?: 'CompanyUserNodeConnection'
  edges: Array<CompanyUserNodeEdge>
}

export type CompanyUserNodeEdge = {
  __typename?: 'CompanyUserNodeEdge'
  node: CompanyUserNode
}

export type ConfirmJobs = {
  __typename?: 'ConfirmJobs'
  ok: Scalars['Boolean']['output']
}

export type ConsolWithMatchCriteria = {
  __typename?: 'ConsolWithMatchCriteria'
  carrierBookingNo?: Maybe<Scalars['String']['output']>
  chainIoConsol?: Maybe<ChainIoConsolidationNode>
  consolNo?: Maybe<Scalars['String']['output']>
  containerNo?: Maybe<Scalars['String']['output']>
  hblNo?: Maybe<Scalars['String']['output']>
  mblNo?: Maybe<Scalars['String']['output']>
  orderNo?: Maybe<Scalars['String']['output']>
  shipmentNo?: Maybe<Scalars['String']['output']>
}

export type ContainerType = {
  __typename?: 'ContainerType'
  code: Scalars['String']['output']
  name: Scalars['String']['output']
  searchRegex: Scalars['String']['output']
  teus: Scalars['Float']['output']
}

export type CopyJob = {
  __typename?: 'CopyJob'
  id: Scalars['String']['output']
}

export type CopyRowsToDocumentsTemplateMatch = {
  __typename?: 'CopyRowsToDocumentsTemplateMatch'
  ok: Scalars['Boolean']['output']
}

export type CopyTableFromOtherDocument = {
  __typename?: 'CopyTableFromOtherDocument'
  ok: Scalars['Boolean']['output']
}

export type CopyTableToDocuments = {
  __typename?: 'CopyTableToDocuments'
  ok: Scalars['Boolean']['output']
}

export type CreateApiPartner = {
  __typename?: 'CreateApiPartner'
  ok: Scalars['Boolean']['output']
}

export type CreateBatchReconNote = {
  __typename?: 'CreateBatchReconNote'
  batchReconNote: NoteNode
}

export type CreateCargowiseConfig = {
  __typename?: 'CreateCargowiseConfig'
  ok: Scalars['Boolean']['output']
}

export type CreateChargeCodeAndOverrides = {
  __typename?: 'CreateChargeCodeAndOverrides'
  ok: Scalars['Boolean']['output']
}

export type CreateChargeCodes = {
  __typename?: 'CreateChargeCodes'
  ok: Scalars['Boolean']['output']
}

export type CreateChargeVendorAndOverrides = {
  __typename?: 'CreateChargeVendorAndOverrides'
  chargeVendor: ChargeVendorNode
}

export type CreateChargeVendorWithChargeCodes = {
  __typename?: 'CreateChargeVendorWithChargeCodes'
  chargeVendor: ChargeVendorNode
}

export type CreateCompany = {
  __typename?: 'CreateCompany'
  company: CompanyNode
}

export type CreateCustomDatabase = {
  __typename?: 'CreateCustomDatabase'
  ok: Scalars['Boolean']['output']
}

export type CreateCustomDatabaseType = {
  __typename?: 'CreateCustomDatabaseType'
  ok: Scalars['Boolean']['output']
}

export type CreateDocument = {
  __typename?: 'CreateDocument'
  ok: Scalars['Boolean']['output']
}

export type CreateEmptyJob = {
  __typename?: 'CreateEmptyJob'
  job: JobNode
}

export type CreateJob = {
  __typename?: 'CreateJob'
  job: JobNode
}

export type CreateNote = {
  __typename?: 'CreateNote'
  note?: Maybe<NoteNode>
}

export type CreateReconNote = {
  __typename?: 'CreateReconNote'
  reconNote: NoteNode
}

export type CreateTask = {
  __typename?: 'CreateTask'
  task: TaskNode
}

export type CreateTaskNote = {
  __typename?: 'CreateTaskNote'
  taskNote: TaskNoteNode
}

export type CreateTax = {
  __typename?: 'CreateTax'
  ok: Scalars['Boolean']['output']
}

export type CredentialNode = {
  __typename?: 'CredentialNode'
  apiEndpoint?: Maybe<Scalars['String']['output']>
  apiPartners: ApiPartnerInterfaceConnection
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  hostOverride?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  jobTemplates: JobTemplateNodeConnection
  password: Scalars['String']['output']
  username: Scalars['String']['output']
  verifySsl: Scalars['Boolean']['output']
  workflow: Scalars['String']['output']
}

export type CustomCardReference = {
  __typename?: 'CustomCardReference'
  cardId: Scalars['UUID']['output']
  dashboardReference: CustomDashboardReference
  isHardcoded: Scalars['Boolean']['output']
}

export type CustomDashboardReference = {
  __typename?: 'CustomDashboardReference'
  dashboardId: Scalars['UUID']['output']
  isHardcoded: Scalars['Boolean']['output']
  tabType: DashboardTabType
}

export type CustomDatabaseCellNode = {
  __typename?: 'CustomDatabaseCellNode'
  column: CustomDatabaseColumnNode
  customDatabaseColumnId: Scalars['UUID']['output']
  customDatabaseRowId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  row: CustomDatabaseRowNode
  value?: Maybe<Scalars['String']['output']>
}

export type CustomDatabaseCellNodeConnection = {
  __typename?: 'CustomDatabaseCellNodeConnection'
  edges: Array<CustomDatabaseCellNodeEdge>
}

export type CustomDatabaseCellNodeEdge = {
  __typename?: 'CustomDatabaseCellNodeEdge'
  node: CustomDatabaseCellNode
}

export type CustomDatabaseColumnNode = {
  __typename?: 'CustomDatabaseColumnNode'
  customDatabaseType: CustomDatabaseTypeNode
  customDatabaseTypeId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  displayName: Scalars['String']['output']
  exportKey: Scalars['String']['output']
  id: Scalars['UUID']['output']
}

export type CustomDatabaseColumnNodeConnection = {
  __typename?: 'CustomDatabaseColumnNodeConnection'
  edges: Array<CustomDatabaseColumnNodeEdge>
}

export type CustomDatabaseColumnNodeEdge = {
  __typename?: 'CustomDatabaseColumnNodeEdge'
  node: CustomDatabaseColumnNode
}

export type CustomDatabaseNode = {
  __typename?: 'CustomDatabaseNode'
  apiPartner: ApiPartnerInterface
  apiPartnerId: Scalars['UUID']['output']
  cleanedName: Scalars['String']['output']
  customDatabaseType: CustomDatabaseTypeNode
  customDatabaseTypeId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  rows: CustomDatabaseRowNodeConnection
}

export type CustomDatabaseRowNode = {
  __typename?: 'CustomDatabaseRowNode'
  cells: CustomDatabaseCellNodeConnection
  customDatabase: CustomDatabaseNode
  customDatabaseId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
}

export type CustomDatabaseRowNodeConnection = {
  __typename?: 'CustomDatabaseRowNodeConnection'
  edges: Array<CustomDatabaseRowNodeEdge>
}

export type CustomDatabaseRowNodeEdge = {
  __typename?: 'CustomDatabaseRowNodeEdge'
  node: CustomDatabaseRowNode
}

export type CustomDatabaseTypeNode = {
  __typename?: 'CustomDatabaseTypeNode'
  cleanedName: Scalars['String']['output']
  columns: CustomDatabaseColumnNodeConnection
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  tmsType?: Maybe<ApiPartnerTmsTypeWithFallback>
}

export type CustomQueryNode = {
  __typename?: 'CustomQueryNode'
  card: CardNode
  cardId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  fromClause: Scalars['String']['output']
  groupByClause?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  joinClauses: JoinClauseNodeConnection
  orderByClause?: Maybe<Scalars['String']['output']>
  parentQuery?: Maybe<CustomQueryNode>
  parentQueryId?: Maybe<Scalars['UUID']['output']>
  selectClause: Scalars['String']['output']
  subqueries: CustomQueryNodeConnection
  subqueryName?: Maybe<Scalars['String']['output']>
}

export type CustomQueryNodeConnection = {
  __typename?: 'CustomQueryNodeConnection'
  edges: Array<CustomQueryNodeEdge>
}

export type CustomQueryNodeEdge = {
  __typename?: 'CustomQueryNodeEdge'
  node: CustomQueryNode
}

export type CustomsDeclarationWithMatchCriteria = {
  __typename?: 'CustomsDeclarationWithMatchCriteria'
  carrierBookingNo?: Maybe<Scalars['String']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  consolNo?: Maybe<Scalars['String']['output']>
  containerNo?: Maybe<Scalars['String']['output']>
  hblNo?: Maybe<Scalars['String']['output']>
  mblNo?: Maybe<Scalars['String']['output']>
  orderNo?: Maybe<Scalars['String']['output']>
  referenceNo?: Maybe<Scalars['String']['output']>
}

export enum CwTargetModule {
  AccountingInvoice = 'ACCOUNTING_INVOICE',
  CustomsDeclaration = 'CUSTOMS_DECLARATION',
  ForwardingConsol = 'FORWARDING_CONSOL',
  ForwardingShipment = 'FORWARDING_SHIPMENT',
  ForwardingShipmentToConsol = 'FORWARDING_SHIPMENT_TO_CONSOL',
  LocalTransport = 'LOCAL_TRANSPORT',
}

export type CwTargetModuleEnum = {
  __typename?: 'CwTargetModuleEnum'
  isFallback: Scalars['Boolean']['output']
  value: CwTargetModule
}

export type CwTargetModuleWithFallback = CwTargetModuleEnum | FallbackValue

export type DashboardDefaultFilterNode = {
  __typename?: 'DashboardDefaultFilterNode'
  dashboard: DashboardNode
  dashboardId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  savedFilters: Scalars['String']['output']
  savedUnitSettings: Scalars['String']['output']
}

export type DashboardEdocResult = {
  __typename?: 'DashboardEdocResult'
  filename: Scalars['String']['output']
  id: Scalars['UUID']['output']
  module: Scalars['String']['output']
  s3Uri: Scalars['String']['output']
  saveDateUtc: Scalars['String']['output']
}

export type DashboardEnums = {
  __typename?: 'DashboardEnums'
  accountingMarginsGraph: AccountingMarginsGraph
  accountingTab: AccountingTab
  businessPerformanceAccountOverviewGraph: BusinessPerformanceAccountOverviewGraph
  businessPerformanceBranchOverviewGraph: BusinessPerformanceBranchOverviewGraph
  businessPerformanceDepartmentOverviewGraph: BusinessPerformanceDepartmentOverviewGraph
  businessPerformanceExpensesGraph: BusinessPerformanceExpensesGraph
  businessPerformanceMarginGraph: BusinessPerformanceMarginGraph
  businessPerformanceRevenueGraph: BusinessPerformanceRevenueGraph
  businessPerformanceTab: BusinessPerformanceTab
  dashboardFilterFieldType: DashboardFilterFieldType
  dashboardFilterOperator: DashboardFilterOperator
  dashboardTab: DashboardTab
  exploreTab: ExploreTab
  milestone: Milestone
  operationsShipmentVolumeGraph: OperationsShipmentVolumeGraph
  operationsTab: OperationsTab
  salesOverviewGraph: SalesOverviewGraph
  salesTab: SalesTab
}

export enum DashboardFilterFieldType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Number = 'NUMBER',
  NumberWithUnit = 'NUMBER_WITH_UNIT',
  Percent = 'PERCENT',
  Text = 'TEXT',
}

export enum DashboardFilterOperator {
  Between = 'BETWEEN',
  EndsWith = 'ENDS_WITH',
  Equal = 'EQUAL',
  Greater = 'GREATER',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  IsEmpty = 'IS_EMPTY',
  IsNotEmpty = 'IS_NOT_EMPTY',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  Less = 'LESS',
  LessOrEqual = 'LESS_OR_EQUAL',
  Like = 'LIKE',
  NotBetween = 'NOT_BETWEEN',
  NotEqual = 'NOT_EQUAL',
  NotLike = 'NOT_LIKE',
  Proximity = 'PROXIMITY',
  StartsWith = 'STARTS_WITH',
}

export type DashboardIamNode = {
  __typename?: 'DashboardIAMNode'
  auth0Id: Scalars['String']['output']
  companyId: Scalars['UUID']['output']
  dashboard: DashboardNode
  dashboardId: Scalars['UUID']['output']
  dashboardRole?: Maybe<DashboardRoleWithFallback>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
}

export type DashboardIamNodeConnection = {
  __typename?: 'DashboardIAMNodeConnection'
  edges: Array<DashboardIamNodeEdge>
}

export type DashboardIamNodeEdge = {
  __typename?: 'DashboardIAMNodeEdge'
  node: DashboardIamNode
}

export type DashboardInsightsUserNode = {
  __typename?: 'DashboardInsightsUserNode'
  auth0Id: Scalars['String']['output']
  branch?: Maybe<Scalars['String']['output']>
  branches: Array<Scalars['String']['output']>
  canExportDataAsCsv?: Maybe<Scalars['Boolean']['output']>
  companyId: Scalars['UUID']['output']
  defaultDashboardsWhitelist: Array<Scalars['String']['output']>
  department?: Maybe<Scalars['String']['output']>
  departments: Array<Scalars['String']['output']>
  email: Scalars['String']['output']
  metabaseDashboardId?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  onlyViewAssignedShipments?: Maybe<Scalars['Boolean']['output']>
  operationsRep?: Maybe<Scalars['String']['output']>
  operationsReps: Array<Scalars['String']['output']>
  operatorCompanies: Array<MinimalCompanyNode>
  roles: Array<UserRole>
  salesRep?: Maybe<Scalars['String']['output']>
  salesReps: Array<Scalars['String']['output']>
}

export type DashboardInsightsUserResult = {
  __typename?: 'DashboardInsightsUserResult'
  dashboardInsightsUsers: Array<DashboardInsightsUserNode>
  limit: Scalars['Int']['output']
  page: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

export type DashboardNode = {
  __typename?: 'DashboardNode'
  basicFilterFields?: Maybe<Array<Scalars['String']['output']>>
  cards: CardNodeConnection
  dashboardIams: DashboardIamNodeConnection
  dashboardTab: DashboardTabTypeWithFallback
  dashboardUserFilters: DashboardTabUserFilterNodeConnection
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  defaultFilter?: Maybe<DashboardDefaultFilterNode>
  displayName: Scalars['String']['output']
  enabled: Scalars['Boolean']['output']
  id: Scalars['UUID']['output']
  iframeOverrideUrl?: Maybe<Scalars['String']['output']>
  isDefault: Scalars['Boolean']['output']
  metabaseJwtUrl?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  publicUrl?: Maybe<Scalars['String']['output']>
  selectors: Array<Scalars['String']['output']>
}

export type DashboardReference = CustomDashboardReference | HardcodedDashboardReference

export enum DashboardRole {
  Editor = 'EDITOR',
  Owner = 'OWNER',
  Viewer = 'VIEWER',
}

export type DashboardRoleEnum = {
  __typename?: 'DashboardRoleEnum'
  isFallback: Scalars['Boolean']['output']
  value: DashboardRole
}

export type DashboardRoleWithFallback = DashboardRoleEnum | FallbackValue

export type DashboardSearchItem = {
  __typename?: 'DashboardSearchItem'
  path: Scalars['String']['output']
  tab?: Maybe<Scalars['String']['output']>
  tabType: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
}

export enum DashboardTab {
  JobSummary = 'JOB_SUMMARY',
  MainDashboard = 'MAIN_DASHBOARD',
  ReconJobList = 'RECON_JOB_LIST',
}

export enum DashboardTabType {
  Accounting = 'ACCOUNTING',
  BusinessPerformance = 'BUSINESS_PERFORMANCE',
  Dashboard = 'DASHBOARD',
  Explore = 'EXPLORE',
  Operations = 'OPERATIONS',
  Sales = 'SALES',
}

export type DashboardTabTypeEnum = {
  __typename?: 'DashboardTabTypeEnum'
  isFallback: Scalars['Boolean']['output']
  value: DashboardTabType
}

export type DashboardTabTypeWithFallback = DashboardTabTypeEnum | FallbackValue

export type DashboardTabUserFilterNode = {
  __typename?: 'DashboardTabUserFilterNode'
  auth0Id: Scalars['String']['output']
  companyId: Scalars['UUID']['output']
  dashboard?: Maybe<DashboardNode>
  dashboardId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  savedColumns?: Maybe<Scalars['String']['output']>
  savedFilters: Scalars['String']['output']
  savedOrderAscending?: Maybe<Scalars['Boolean']['output']>
  savedOrderByColumn?: Maybe<Scalars['Int']['output']>
  savedUnitSettings?: Maybe<Scalars['String']['output']>
  tabName: Scalars['String']['output']
  tabType: Scalars['String']['output']
}

export type DashboardTabUserFilterNodeConnection = {
  __typename?: 'DashboardTabUserFilterNodeConnection'
  edges: Array<DashboardTabUserFilterNodeEdge>
}

export type DashboardTabUserFilterNodeEdge = {
  __typename?: 'DashboardTabUserFilterNodeEdge'
  node: DashboardTabUserFilterNode
}

export type DashboardViewFieldMetadata = {
  __typename?: 'DashboardViewFieldMetadata'
  categories: Array<InsightsFieldCategory>
  columnDescription?: Maybe<Scalars['String']['output']>
  columnName: Scalars['String']['output']
  columnType: TableColumnType
  disabled: Scalars['Boolean']['output']
  displayName: Scalars['String']['output']
  dropdownOptions?: Maybe<Array<FilterSearchResult>>
  searchableRecordModel?: Maybe<InsightsSearchableRecordModel>
}

export type DataResult = {
  __typename?: 'DataResult'
  boxHeight?: Maybe<Scalars['String']['output']>
  cardReference?: Maybe<CardReference>
  data: SummaryDataResultLineGraphDataResultHistogramDataResultHistogramStackedDataResultHistogramGroupedDataResultDataResultPeriodsBenchmarkDataResultCashCycleDataResultRegionMapDataResultScatterplotDataResultPinMapDataResultTableDataResultSankeyMapDataResultBarWithLineResultPivotTableDataResultExploreShipmentDataResultExploreInvoiceDataResultExploreOrganizationDataResultCalendarHeatmapDataResult
  description?: Maybe<Scalars['String']['output']>
  exploreColumnDefault?: Maybe<Scalars['String']['output']>
  linkedExploreTab?: Maybe<ExploreTab>
  queryDescription?: Maybe<Scalars['String']['output']>
  queryIds: Array<Scalars['String']['output']>
  subtitle?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  type: DataType
}

export type DataResultPeriods = {
  __typename?: 'DataResultPeriods'
  current: Scalars['String']['output']
  previous?: Maybe<Scalars['String']['output']>
  unitPeriod: PeriodType
}

export enum DataType {
  BarWithLine = 'BAR_WITH_LINE',
  CalendarHeatmap = 'CALENDAR_HEATMAP',
  CashCycle = 'CASH_CYCLE',
  Explore = 'EXPLORE',
  GroupedBar = 'GROUPED_BAR',
  HorizontalBar = 'HORIZONTAL_BAR',
  LineGraph = 'LINE_GRAPH',
  MarginsByAccount = 'MARGINS_BY_ACCOUNT',
  MarginsByShipment = 'MARGINS_BY_SHIPMENT',
  MilestoneGraph = 'MILESTONE_GRAPH',
  PieChart = 'PIE_CHART',
  PinMap = 'PIN_MAP',
  PivotTable = 'PIVOT_TABLE',
  RegionMap = 'REGION_MAP',
  SankeyMap = 'SANKEY_MAP',
  Scatterplot = 'SCATTERPLOT',
  ShipmentsPerOperator = 'SHIPMENTS_PER_OPERATOR',
  StackedBar = 'STACKED_BAR',
  Summary = 'SUMMARY',
  Table = 'TABLE',
  VerticalBar = 'VERTICAL_BAR',
  VolumePerRoute = 'VOLUME_PER_ROUTE',
}

export type DateFilterBetween = {
  endDate: Scalars['Date']['input']
  startDate: Scalars['Date']['input']
  timezone: Scalars['String']['input']
}

export type DateFilterGreaterThanOrEquals = {
  startDate: Scalars['Date']['input']
  timezone: Scalars['String']['input']
}

export type DeleteApiEDoc = {
  __typename?: 'DeleteApiEDoc'
  ok: Scalars['Boolean']['output']
}

export type DeleteApiPartner = {
  __typename?: 'DeleteApiPartner'
  ok: Scalars['Boolean']['output']
}

export type DeleteBatchReconNote = {
  __typename?: 'DeleteBatchReconNote'
  ok?: Maybe<Scalars['Boolean']['output']>
}

export type DeleteCargowiseConfig = {
  __typename?: 'DeleteCargowiseConfig'
  ok: Scalars['Boolean']['output']
}

export type DeleteChargeCodes = {
  __typename?: 'DeleteChargeCodes'
  ok: Scalars['Boolean']['output']
}

export type DeleteChargeCodesAndOverrides = {
  __typename?: 'DeleteChargeCodesAndOverrides'
  ok: Scalars['Boolean']['output']
}

export type DeleteChargeVendor = {
  __typename?: 'DeleteChargeVendor'
  ok: Scalars['Boolean']['output']
}

export type DeleteCompany = {
  __typename?: 'DeleteCompany'
  ok: Scalars['Boolean']['output']
}

export type DeleteCustomDatabase = {
  __typename?: 'DeleteCustomDatabase'
  ok: Scalars['Boolean']['output']
}

export type DeleteCustomDatabaseType = {
  __typename?: 'DeleteCustomDatabaseType'
  ok: Scalars['Boolean']['output']
}

export type DeleteDocumentTable = {
  __typename?: 'DeleteDocumentTable'
  ok: Scalars['Boolean']['output']
}

export type DeleteDocumentTablesInGroup = {
  __typename?: 'DeleteDocumentTablesInGroup'
  ok: Scalars['Boolean']['output']
}

export type DeleteEDoc = {
  __typename?: 'DeleteEDoc'
  ok: Scalars['Boolean']['output']
}

export type DeleteFilePage = {
  __typename?: 'DeleteFilePage'
  ok?: Maybe<Scalars['Boolean']['output']>
}

export type DeleteFilePages = {
  __typename?: 'DeleteFilePages'
  numUpdated?: Maybe<Scalars['Int']['output']>
}

export type DeleteJob = {
  __typename?: 'DeleteJob'
  ok: Scalars['Boolean']['output']
}

export type DeleteJobTemplate = {
  __typename?: 'DeleteJobTemplate'
  ok: Scalars['Boolean']['output']
}

export type DeleteReconNote = {
  __typename?: 'DeleteReconNote'
  ok?: Maybe<Scalars['Boolean']['output']>
}

export type DeleteTask = {
  __typename?: 'DeleteTask'
  ok?: Maybe<Scalars['Boolean']['output']>
}

export type DeleteTaskNote = {
  __typename?: 'DeleteTaskNote'
  ok?: Maybe<Scalars['Boolean']['output']>
}

export type DeleteTax = {
  __typename?: 'DeleteTax'
  ok: Scalars['Boolean']['output']
}

export type DocumentFieldGroupNode = {
  __typename?: 'DocumentFieldGroupNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  document: DocumentNode
  documentFields: DocumentFieldNodeConnection
  documentId: Scalars['UUID']['output']
  documentTable?: Maybe<DocumentTableNode>
  documentTableId?: Maybe<Scalars['UUID']['output']>
  fieldGroup: FieldGroupNode
  fieldGroupId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  job?: Maybe<JobNode>
  jobId?: Maybe<Scalars['UUID']['output']>
  rowOrderPriority?: Maybe<Scalars['Int']['output']>
}

export type DocumentFieldGroupNodeConnection = {
  __typename?: 'DocumentFieldGroupNodeConnection'
  edges: Array<DocumentFieldGroupNodeEdge>
}

export type DocumentFieldGroupNodeEdge = {
  __typename?: 'DocumentFieldGroupNodeEdge'
  node: DocumentFieldGroupNode
}

export type DocumentFieldNode = {
  __typename?: 'DocumentFieldNode'
  confidenceScore?: Maybe<Scalars['Float']['output']>
  confirmed?: Maybe<Scalars['Boolean']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentFieldGroup: DocumentFieldGroupNode
  documentFieldGroupId: Scalars['UUID']['output']
  field: FieldNode
  fieldId: Scalars['UUID']['output']
  height?: Maybe<Scalars['Float']['output']>
  id: Scalars['UUID']['output']
  job?: Maybe<JobNode>
  jobId?: Maybe<Scalars['UUID']['output']>
  left?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Scalars['String']['output']>
  top?: Maybe<Scalars['Float']['output']>
  value: Scalars['String']['output']
  width?: Maybe<Scalars['Float']['output']>
}

export type DocumentFieldNodeConnection = {
  __typename?: 'DocumentFieldNodeConnection'
  edges: Array<DocumentFieldNodeEdge>
}

export type DocumentFieldNodeEdge = {
  __typename?: 'DocumentFieldNodeEdge'
  node: DocumentFieldNode
}

export type DocumentNode = {
  __typename?: 'DocumentNode'
  autofillExtractorKey?: Maybe<AutofillExtractorKey>
  autofillExtractorVersion?: Maybe<Scalars['String']['output']>
  autofillMethod?: Maybe<AutofillMethod>
  closestSimilarDocId?: Maybe<Scalars['UUID']['output']>
  closestSimilarity?: Maybe<Scalars['Int']['output']>
  company: CompanyNode
  companyId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentFieldGroups: DocumentFieldGroupNodeConnection
  documentTables: DocumentTableNodeConnection
  documentType: DocumentTypeNode
  documentTypeId: Scalars['UUID']['output']
  filePage: FilePageNode
  filePageId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  predConfidence?: Maybe<Scalars['Float']['output']>
  user?: Maybe<UserNode>
  userId?: Maybe<Scalars['UUID']['output']>
}

export type DocumentNodeConnection = {
  __typename?: 'DocumentNodeConnection'
  edges: Array<DocumentNodeEdge>
}

export type DocumentNodeEdge = {
  __typename?: 'DocumentNodeEdge'
  node: DocumentNode
}

export type DocumentTableColumnNode = {
  __typename?: 'DocumentTableColumnNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentTable: DocumentTableNode
  documentTableId: Scalars['UUID']['output']
  field: FieldNode
  fieldId: Scalars['UUID']['output']
  height: Scalars['Float']['output']
  id: Scalars['UUID']['output']
  index: Scalars['Int']['output']
  left: Scalars['Float']['output']
  top: Scalars['Float']['output']
  width: Scalars['Float']['output']
}

export type DocumentTableColumnNodeConnection = {
  __typename?: 'DocumentTableColumnNodeConnection'
  edges: Array<DocumentTableColumnNodeEdge>
}

export type DocumentTableColumnNodeEdge = {
  __typename?: 'DocumentTableColumnNodeEdge'
  node: DocumentTableColumnNode
}

export type DocumentTableNode = {
  __typename?: 'DocumentTableNode'
  autofillExtractorKey?: Maybe<AutofillExtractorKey>
  autofillExtractorVersion?: Maybe<Scalars['String']['output']>
  autofillMethod?: Maybe<AutofillMethod>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  document: DocumentNode
  documentFieldGroups: DocumentFieldGroupNodeConnection
  documentId: Scalars['UUID']['output']
  documentTableColumns: DocumentTableColumnNodeConnection
  editedByUser?: Maybe<Scalars['Boolean']['output']>
  fieldGroup: FieldGroupNode
  fieldGroupId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  nextTable?: Maybe<DocumentTableNode>
  nextTableId?: Maybe<Scalars['UUID']['output']>
  orderPriority: Scalars['Int']['output']
  predConfidence?: Maybe<Scalars['Float']['output']>
}

export type DocumentTableNodeConnection = {
  __typename?: 'DocumentTableNodeConnection'
  edges: Array<DocumentTableNodeEdge>
}

export type DocumentTableNodeEdge = {
  __typename?: 'DocumentTableNodeEdge'
  node: DocumentTableNode
}

export type DocumentTypeFieldGroupNode = {
  __typename?: 'DocumentTypeFieldGroupNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentType: DocumentTypeNode
  documentTypeId: Scalars['UUID']['output']
  fieldGroup: FieldGroupNode
  fieldGroupId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  orderPriority: Scalars['Int']['output']
}

export type DocumentTypeFieldGroupNodeConnection = {
  __typename?: 'DocumentTypeFieldGroupNodeConnection'
  edges: Array<DocumentTypeFieldGroupNodeEdge>
}

export type DocumentTypeFieldGroupNodeEdge = {
  __typename?: 'DocumentTypeFieldGroupNodeEdge'
  node: DocumentTypeFieldGroupNode
}

export type DocumentTypeNode = {
  __typename?: 'DocumentTypeNode'
  autofillExtractorKey?: Maybe<AutofillExtractorKey>
  cargowiseFileType?: Maybe<CargowiseFileTypeNode>
  cargowiseFileTypeId?: Maybe<Scalars['UUID']['output']>
  collapsible: Scalars['Boolean']['output']
  collapsibleOrderPriority: Scalars['Int']['output']
  company?: Maybe<CompanyNode>
  companyId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  derivedFromId?: Maybe<Scalars['UUID']['output']>
  documentTypeFieldGroups: DocumentTypeFieldGroupNodeConnection
  documents: DocumentNodeConnection
  fieldGroups: FieldGroupNodeConnection
  id: Scalars['UUID']['output']
  isEDocPublishedByDefault: Scalars['Boolean']['output']
  isStandard: Scalars['Boolean']['output']
  jobTemplateDocumentTypes: JobTemplateDocumentTypeNodeConnection
  jobTemplates: JobTemplateNodeConnection
  name: Scalars['String']['output']
  tableShowsPreset: Scalars['Boolean']['output']
}

export type DocumentTypeNodeConnection = {
  __typename?: 'DocumentTypeNodeConnection'
  edges: Array<DocumentTypeNodeEdge>
}

export type DocumentTypeNodeEdge = {
  __typename?: 'DocumentTypeNodeEdge'
  node: DocumentTypeNode
}

export type DownloadDashboardEDoc = {
  __typename?: 'DownloadDashboardEDoc'
  eDocUrl: Scalars['String']['output']
}

export enum DrilldownCta {
  Creditors = 'CREDITORS',
  Debtors = 'DEBTORS',
  LocalClient = 'LOCAL_CLIENT',
  PayableInvoices = 'PAYABLE_INVOICES',
  ReceivableInvoices = 'RECEIVABLE_INVOICES',
  Shipments = 'SHIPMENTS',
}

export type DrilldownCtaEnum = {
  __typename?: 'DrilldownCTAEnum'
  isFallback: Scalars['Boolean']['output']
  value: DrilldownCta
}

export type DrilldownCtaWithFallback = DrilldownCtaEnum | FallbackValue

export type DuplicateChainIoServiceInvoiceLineNode = {
  __typename?: 'DuplicateChainIOServiceInvoiceLineNode'
  chainIoConsolCost?: Maybe<ChainIoConsolCostNode>
  chainIoConsolCostId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclarationCost?: Maybe<ChainIoCustomsDeclarationCostNode>
  chainIoCustomsDeclarationCostId?: Maybe<Scalars['UUID']['output']>
  chainIoSiLine?: Maybe<ChainIoServiceInvoiceLineNode>
  chainIoSiLineId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  invoiceLineItemReconResult: InvoiceLineItemReconResultNode
  invoiceLineItemReconResultId: Scalars['UUID']['output']
}

export type DuplicateChainIoServiceInvoiceLineNodeConnection = {
  __typename?: 'DuplicateChainIOServiceInvoiceLineNodeConnection'
  edges: Array<DuplicateChainIoServiceInvoiceLineNodeEdge>
}

export type DuplicateChainIoServiceInvoiceLineNodeEdge = {
  __typename?: 'DuplicateChainIOServiceInvoiceLineNodeEdge'
  node: DuplicateChainIoServiceInvoiceLineNode
}

export enum EDocsDataTargetType {
  ApInvoice = 'AP_INVOICE',
  Consol = 'CONSOL',
  CustomsDeclaration = 'CUSTOMS_DECLARATION',
  Shipment = 'SHIPMENT',
}

export type EDocsDataTargetTypeEnum = {
  __typename?: 'EDocsDataTargetTypeEnum'
  isFallback: Scalars['Boolean']['output']
  value: EDocsDataTargetType
}

export type EDocsDataTargetTypeWithFallback = EDocsDataTargetTypeEnum | FallbackValue

export type EDocumentNode = {
  __typename?: 'EDocumentNode'
  cargowiseFileType?: Maybe<CargowiseFileTypeNode>
  cargowiseFileTypeId?: Maybe<Scalars['UUID']['output']>
  dataTargetType: EDocsDataTargetTypeWithFallback
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  eDocUrl?: Maybe<Scalars['String']['output']>
  eDocUrlExpiry?: Maybe<Scalars['DateTime']['output']>
  filePage?: Maybe<FilePageNode>
  filePageId?: Maybe<Scalars['UUID']['output']>
  filename: Scalars['String']['output']
  id: Scalars['UUID']['output']
  isInCw: Scalars['Boolean']['output']
  isPublished: Scalars['Boolean']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  path: Scalars['String']['output']
  rawCargowiseOutbound?: Maybe<RawCargowiseOutboundNode>
  rawCargowiseOutboundId?: Maybe<Scalars['UUID']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  uri?: Maybe<Scalars['String']['output']>
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type EDocumentNodeConnection = {
  __typename?: 'EDocumentNodeConnection'
  edges: Array<EDocumentNodeEdge>
}

export type EDocumentNodeEdge = {
  __typename?: 'EDocumentNodeEdge'
  node: EDocumentNode
}

export type EditChargeCodeAndOverrides = {
  __typename?: 'EditChargeCodeAndOverrides'
  id: Scalars['UUID']['output']
}

export type EditChargeCodes = {
  __typename?: 'EditChargeCodes'
  id: Scalars['UUID']['output']
}

export type EditChargeVendor = {
  __typename?: 'EditChargeVendor'
  id: Scalars['UUID']['output']
}

export type EditChargeVendorAndOverrides = {
  __typename?: 'EditChargeVendorAndOverrides'
  id: Scalars['UUID']['output']
}

export type EditCompany = {
  __typename?: 'EditCompany'
  id?: Maybe<Scalars['String']['output']>
}

export type EditCompanyRequest = {
  enableEdocsTab?: Scalars['Boolean']['input']
  hasAccessToInsights?: Scalars['Boolean']['input']
  id: Scalars['String']['input']
  imageS3Uri?: InputMaybe<Scalars['String']['input']>
  metabaseDashboardId?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  primaryThemeColor?: InputMaybe<Scalars['String']['input']>
  shipperFacingSlug?: InputMaybe<Scalars['String']['input']>
}

export type EditCustomDatabase = {
  __typename?: 'EditCustomDatabase'
  id: Scalars['UUID']['output']
}

export type EditCustomDatabaseType = {
  __typename?: 'EditCustomDatabaseType'
  id: Scalars['UUID']['output']
}

export type EditJob = {
  __typename?: 'EditJob'
  job: JobNode
}

export type EditOwnProfile = {
  __typename?: 'EditOwnProfile'
  ok?: Maybe<Scalars['Boolean']['output']>
}

export type EditTax = {
  __typename?: 'EditTax'
  id: Scalars['UUID']['output']
}

export type EmptyContainerDepot = {
  __typename?: 'EmptyContainerDepot'
  address: Scalars['String']['output']
  addressSearchRegex: Scalars['String']['output']
  code: Scalars['String']['output']
  name: Scalars['String']['output']
  nameSearchRegex: Scalars['String']['output']
}

export type EncodedFilters = {
  accountMargin?: InputMaybe<Scalars['String']['input']>
  container?: InputMaybe<Scalars['String']['input']>
  payables?: InputMaybe<Scalars['String']['input']>
  receivables?: InputMaybe<Scalars['String']['input']>
  shipment?: InputMaybe<Scalars['String']['input']>
  shipmentMargin?: InputMaybe<Scalars['String']['input']>
  unitSettings: Scalars['String']['input']
}

export type ExploreContainerColumnV2 = {
  __typename?: 'ExploreContainerColumnV2'
  categories: Array<InsightsFieldCategory>
  code: Scalars['Int']['output']
  columnName: Scalars['String']['output']
  displayConvertTimezone?: Maybe<Scalars['Boolean']['output']>
  displayDescription?: Maybe<Scalars['String']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  displayType?: Maybe<TableColumnType>
  hasColorOverride?: Maybe<Scalars['Boolean']['output']>
  hasValueOverride?: Maybe<Scalars['Boolean']['output']>
  isFrozen: Scalars['Boolean']['output']
  link?: Maybe<ExploreTableColumnLink>
  sortable?: Maybe<Scalars['Boolean']['output']>
  valueOverrideReferences?: Maybe<Array<Scalars['String']['output']>>
}

export type ExploreContainerColumnV2ExploreTableDefaultColumns = {
  __typename?: 'ExploreContainerColumnV2ExploreTableDefaultColumns'
  columns: Array<ExploreContainerColumnV2>
  orderAscending: Scalars['Boolean']['output']
  orderByColumn: ExploreContainerColumnV2
}

export type ExploreContainerDataResult = {
  __typename?: 'ExploreContainerDataResult'
  containerSummary: Scalars['JSON']['output']
  exploreContainers: Array<Scalars['JSON']['output']>
}

export enum ExploreDataType {
  Container = 'CONTAINER',
  Organization = 'ORGANIZATION',
  Payable = 'PAYABLE',
  Receivable = 'RECEIVABLE',
  Shipment = 'SHIPMENT',
}

export type ExploreDataTypeEnum = {
  __typename?: 'ExploreDataTypeEnum'
  isFallback: Scalars['Boolean']['output']
  value: ExploreDataType
}

export type ExploreDataTypeWithFallback = ExploreDataTypeEnum | FallbackValue

export type ExploreInvoiceDataResult = {
  __typename?: 'ExploreInvoiceDataResult'
  exploreInvoices: Array<Scalars['JSON']['output']>
  invoiceSummary: Scalars['JSON']['output']
  payables: Scalars['Boolean']['output']
}

export type ExploreOrganizationColumnV2 = {
  __typename?: 'ExploreOrganizationColumnV2'
  categories: Array<InsightsFieldCategory>
  code: Scalars['Int']['output']
  columnName: Scalars['String']['output']
  displayConvertTimezone?: Maybe<Scalars['Boolean']['output']>
  displayDescription?: Maybe<Scalars['String']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  displayType?: Maybe<TableColumnType>
  hasColorOverride?: Maybe<Scalars['Boolean']['output']>
  hasValueOverride?: Maybe<Scalars['Boolean']['output']>
  isFrozen: Scalars['Boolean']['output']
  link?: Maybe<ExploreTableColumnLink>
  sortable?: Maybe<Scalars['Boolean']['output']>
  valueOverrideReferences?: Maybe<Array<Scalars['String']['output']>>
}

export type ExploreOrganizationColumnV2ExploreTableDefaultColumns = {
  __typename?: 'ExploreOrganizationColumnV2ExploreTableDefaultColumns'
  columns: Array<ExploreOrganizationColumnV2>
  orderAscending: Scalars['Boolean']['output']
  orderByColumn: ExploreOrganizationColumnV2
}

export type ExploreOrganizationDataResult = {
  __typename?: 'ExploreOrganizationDataResult'
  exploreOrganizations: Array<Scalars['JSON']['output']>
  orgSummary: Scalars['JSON']['output']
}

export type ExplorePayableInvoiceColumnV2 = {
  __typename?: 'ExplorePayableInvoiceColumnV2'
  categories: Array<InsightsFieldCategory>
  code: Scalars['Int']['output']
  columnName: Scalars['String']['output']
  displayConvertTimezone?: Maybe<Scalars['Boolean']['output']>
  displayDescription?: Maybe<Scalars['String']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  displayType?: Maybe<TableColumnType>
  hasColorOverride?: Maybe<Scalars['Boolean']['output']>
  hasValueOverride?: Maybe<Scalars['Boolean']['output']>
  isFrozen: Scalars['Boolean']['output']
  link?: Maybe<ExploreTableColumnLink>
  sortable?: Maybe<Scalars['Boolean']['output']>
  valueOverrideReferences?: Maybe<Array<Scalars['String']['output']>>
}

export type ExplorePayableInvoiceColumnV2ExploreTableDefaultColumns = {
  __typename?: 'ExplorePayableInvoiceColumnV2ExploreTableDefaultColumns'
  columns: Array<ExplorePayableInvoiceColumnV2>
  orderAscending: Scalars['Boolean']['output']
  orderByColumn: ExplorePayableInvoiceColumnV2
}

export type ExploreReceivableInvoiceColumnV2 = {
  __typename?: 'ExploreReceivableInvoiceColumnV2'
  categories: Array<InsightsFieldCategory>
  code: Scalars['Int']['output']
  columnName: Scalars['String']['output']
  displayConvertTimezone?: Maybe<Scalars['Boolean']['output']>
  displayDescription?: Maybe<Scalars['String']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  displayType?: Maybe<TableColumnType>
  hasColorOverride?: Maybe<Scalars['Boolean']['output']>
  hasValueOverride?: Maybe<Scalars['Boolean']['output']>
  isFrozen: Scalars['Boolean']['output']
  link?: Maybe<ExploreTableColumnLink>
  sortable?: Maybe<Scalars['Boolean']['output']>
  valueOverrideReferences?: Maybe<Array<Scalars['String']['output']>>
}

export type ExploreReceivableInvoiceColumnV2ExploreTableDefaultColumns = {
  __typename?: 'ExploreReceivableInvoiceColumnV2ExploreTableDefaultColumns'
  columns: Array<ExploreReceivableInvoiceColumnV2>
  orderAscending: Scalars['Boolean']['output']
  orderByColumn: ExploreReceivableInvoiceColumnV2
}

export type ExploreShipmentColumnV2 = {
  __typename?: 'ExploreShipmentColumnV2'
  categories: Array<InsightsFieldCategory>
  code: Scalars['Int']['output']
  columnName: Scalars['String']['output']
  displayConvertTimezone?: Maybe<Scalars['Boolean']['output']>
  displayDescription?: Maybe<Scalars['String']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  displayType?: Maybe<TableColumnType>
  hasColorOverride?: Maybe<Scalars['Boolean']['output']>
  hasValueOverride?: Maybe<Scalars['Boolean']['output']>
  isFrozen: Scalars['Boolean']['output']
  link?: Maybe<ExploreTableColumnLink>
  sortable?: Maybe<Scalars['Boolean']['output']>
  valueOverrideReferences?: Maybe<Array<Scalars['String']['output']>>
}

export type ExploreShipmentColumnV2ExploreTableDefaultColumns = {
  __typename?: 'ExploreShipmentColumnV2ExploreTableDefaultColumns'
  columns: Array<ExploreShipmentColumnV2>
  orderAscending: Scalars['Boolean']['output']
  orderByColumn: ExploreShipmentColumnV2
}

export type ExploreShipmentDataResult = {
  __typename?: 'ExploreShipmentDataResult'
  exploreShipments: Array<Scalars['JSON']['output']>
  shipmentSummary: Scalars['JSON']['output']
}

export type ExploreShipmentsSummary = {
  __typename?: 'ExploreShipmentsSummary'
  daysPastTargetBillingDate: Array<TableColumnAggregate>
  expenses: Array<TableColumnAggregate>
  expensesTax: Array<TableColumnAggregate>
  expensesWithTax: Array<TableColumnAggregate>
  marginAbsolute: Array<TableColumnAggregate>
  marginPercentage: Array<TableColumnAggregate>
  numShipments: Scalars['Int']['output']
  revenue: Array<TableColumnAggregate>
  revenueTax: Array<TableColumnAggregate>
  revenueWithTax: Array<TableColumnAggregate>
  teus: Array<TableColumnAggregate>
  volume: Array<TableColumnAggregate>
  weight: Array<TableColumnAggregate>
}

export enum ExploreTab {
  ExploreContainers = 'EXPLORE_CONTAINERS',
  ExploreOrganizations = 'EXPLORE_ORGANIZATIONS',
  ExploreShipments = 'EXPLORE_SHIPMENTS',
  MarginAttributions = 'MARGIN_ATTRIBUTIONS',
  PayableInvoices = 'PAYABLE_INVOICES',
  ReceivableInvoices = 'RECEIVABLE_INVOICES',
  ShipmentDetails = 'SHIPMENT_DETAILS',
}

export type ExploreTableColumnLink = {
  __typename?: 'ExploreTableColumnLink'
  conversions?: Maybe<Array<ColumnConversion>>
  mergeSearchParams: Scalars['Boolean']['output']
  references?: Maybe<Array<Scalars['String']['output']>>
  searchParams?: Maybe<Array<SearchParam>>
  url: Scalars['String']['output']
}

export type ExportApReconAttemptToCw = {
  __typename?: 'ExportAPReconAttemptToCW'
  ok: Scalars['Boolean']['output']
}

export type ExportEDocumentsToApiPartner = {
  __typename?: 'ExportEDocumentsToApiPartner'
  ok: Scalars['Boolean']['output']
}

export type ExportJobToApiPartner = {
  __typename?: 'ExportJobToApiPartner'
  ok: Scalars['Boolean']['output']
  responseText: Scalars['String']['output']
}

export type ExportJobToCw = {
  __typename?: 'ExportJobToCW'
  ok: Scalars['Boolean']['output']
}

export type ExportReportStatus = {
  __typename?: 'ExportReportStatus'
  reportId: Scalars['String']['output']
  signedUrl?: Maybe<Scalars['String']['output']>
}

export type FallbackValue = {
  __typename?: 'FallbackValue'
  fallbackValue: Scalars['String']['output']
  isFallback: Scalars['Boolean']['output']
}

export type FetchChargeDetailsFromTms = {
  __typename?: 'FetchChargeDetailsFromTMS'
  expectedCharges: Array<ChargeDetail>
  findShipmentReconResults: Array<FindShipmentReconResultNode>
  metadataReconResults: Array<MetadataReconResultNode>
  shipmentStaffReconResults: Array<ShipmentStaffReconResultNode>
}

export type FetchDashboardEDocs = {
  __typename?: 'FetchDashboardEDocs'
  dashboardEDocs: Array<DashboardEdocResult>
}

export type FieldGroupNode = {
  __typename?: 'FieldGroupNode'
  autofillKey: Scalars['String']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentTables: DocumentTableNodeConnection
  exportKey?: Maybe<Scalars['String']['output']>
  fields: FieldNodeConnection
  id: Scalars['UUID']['output']
  key: Scalars['String']['output']
  name: Scalars['String']['output']
  repeatable: Scalars['Boolean']['output']
}

export type FieldGroupNodeConnection = {
  __typename?: 'FieldGroupNodeConnection'
  edges: Array<FieldGroupNodeEdge>
}

export type FieldGroupNodeEdge = {
  __typename?: 'FieldGroupNodeEdge'
  node: FieldGroupNode
}

export enum FieldIssueType {
  MissingInfo = 'MISSING_INFO',
  Typo = 'TYPO',
  WrongInfo = 'WRONG_INFO',
}

export type FieldNode = {
  __typename?: 'FieldNode'
  allowFreeText: Scalars['Boolean']['output']
  autofillKey: Scalars['String']['output']
  columnOrder: Scalars['Int']['output']
  dateCreated: Scalars['DateTime']['output']
  dateFormatString?: Maybe<Scalars['String']['output']>
  dateModified: Scalars['DateTime']['output']
  defaultValue?: Maybe<Scalars['String']['output']>
  documentFields: DocumentFieldNodeConnection
  documentTableColumns: DocumentTableColumnNodeConnection
  exportKey?: Maybe<Scalars['String']['output']>
  fieldGroup?: Maybe<FieldGroupNode>
  fieldGroupId?: Maybe<Scalars['UUID']['output']>
  fieldType?: Maybe<FieldTypeWithFallback>
  id: Scalars['UUID']['output']
  invalidCharsRegex?: Maybe<Scalars['String']['output']>
  jobQaFieldIssues: JobQaFieldIssueNodeConnection
  jobRejectionFieldIssues: JobRejectionFieldIssueNodeConnection
  key: Scalars['String']['output']
  name: Scalars['String']['output']
  partialTableAllowed: Scalars['Boolean']['output']
  required: Scalars['Boolean']['output']
  rows: Scalars['Int']['output']
  searchableRecord?: Maybe<SearchableRecordNode>
  validatorDescription?: Maybe<Scalars['String']['output']>
  validatorRegex?: Maybe<Scalars['String']['output']>
  valueExportMapping?: Maybe<Scalars['JSONString']['output']>
  values?: Maybe<Array<Scalars['String']['output']>>
}

export type FieldNodeConnection = {
  __typename?: 'FieldNodeConnection'
  edges: Array<FieldNodeEdge>
}

export type FieldNodeEdge = {
  __typename?: 'FieldNodeEdge'
  node: FieldNode
}

export enum FieldType {
  Database = 'DATABASE',
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Decimal = 'DECIMAL',
  Integer = 'INTEGER',
  Price = 'PRICE',
  ReferenceNumber = 'REFERENCE_NUMBER',
}

export type FieldTypeEnum = {
  __typename?: 'FieldTypeEnum'
  isFallback: Scalars['Boolean']['output']
  value: FieldType
}

export type FieldTypeWithFallback = FallbackValue | FieldTypeEnum

export type FileConnection = {
  __typename?: 'FileConnection'
  items: Array<FileNode>
  total: Scalars['Int']['output']
}

export type FileNode = {
  __typename?: 'FileNode'
  company: CompanyNode
  companyId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  emailSubject?: Maybe<Scalars['String']['output']>
  filename: Scalars['String']['output']
  id: Scalars['UUID']['output']
  message?: Maybe<Scalars['String']['output']>
  pages: FilePageNodeConnection
  previousStatus?: Maybe<FileStatus>
  senderAddress?: Maybe<Scalars['String']['output']>
  status: FileStatus
  unrasterizedFileUri?: Maybe<Scalars['String']['output']>
  user?: Maybe<UserNode>
  userId?: Maybe<Scalars['UUID']['output']>
}

export type FileNodeConnection = {
  __typename?: 'FileNodeConnection'
  edges: Array<FileNodeEdge>
}

export type FileNodeEdge = {
  __typename?: 'FileNodeEdge'
  node: FileNode
}

export type FilePageAutofillResultLogNode = {
  __typename?: 'FilePageAutofillResultLogNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentType?: Maybe<DocumentTypeNode>
  documentTypeId?: Maybe<Scalars['UUID']['output']>
  filePage: FilePageNode
  filePageId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  jobTemplate?: Maybe<JobTemplateNode>
  jobTemplateId?: Maybe<Scalars['UUID']['output']>
  postProcessedAutofillResultS3Uri: Scalars['String']['output']
  rawAutofillResultS3Uri: Scalars['String']['output']
}

export type FilePageAutofillResultLogNodeConnection = {
  __typename?: 'FilePageAutofillResultLogNodeConnection'
  edges: Array<FilePageAutofillResultLogNodeEdge>
}

export type FilePageAutofillResultLogNodeEdge = {
  __typename?: 'FilePageAutofillResultLogNodeEdge'
  node: FilePageAutofillResultLogNode
}

export type FilePageNode = {
  __typename?: 'FilePageNode'
  apiEDocumentFilePages: ApiEDocumentFilePageNodeConnection
  apiEDocuments: ApiEDocumentNodeConnection
  autocropHeight?: Maybe<Scalars['Int']['output']>
  autocropLeft?: Maybe<Scalars['String']['output']>
  autocropTop?: Maybe<Scalars['String']['output']>
  autocropVersion?: Maybe<Scalars['String']['output']>
  autocropWidth?: Maybe<Scalars['String']['output']>
  autofillResultLogs: FilePageAutofillResultLogNodeConnection
  dateBatchCreated: Scalars['DateTime']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  document?: Maybe<DocumentNode>
  eDocuments: EDocumentNodeConnection
  file: FileNode
  fileId: Scalars['UUID']['output']
  googleOcrData?: Maybe<Scalars['JSONString']['output']>
  googleOcrDataS3Uri?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  imageUrl?: Maybe<Scalars['String']['output']>
  imageUrlExpiry?: Maybe<Scalars['DateTime']['output']>
  imgHash?: Maybe<Array<Scalars['Boolean']['output']>>
  imgHeight?: Maybe<Scalars['Int']['output']>
  imgNormalizedHash?: Maybe<Array<Scalars['Boolean']['output']>>
  imgWidth?: Maybe<Scalars['Int']['output']>
  isInCw: Scalars['Boolean']['output']
  job?: Maybe<JobNode>
  jobId?: Maybe<Scalars['UUID']['output']>
  ocrDataS3Uri?: Maybe<Scalars['String']['output']>
  pageAsyncTasks: PageAsyncTaskNodeConnection
  pageNumber: Scalars['Int']['output']
  pageNumberInOriginalFile: Scalars['Int']['output']
  path: Scalars['String']['output']
  previousStatus?: Maybe<FilePageStatus>
  status: FilePageStatus
  type: FilePageType
  uncroppedImageUrl?: Maybe<Scalars['String']['output']>
  unrotatedGoogleOcrDataS3Uri?: Maybe<Scalars['String']['output']>
  unrotatedImageUrl?: Maybe<Scalars['String']['output']>
  uri?: Maybe<Scalars['String']['output']>
  user?: Maybe<UserNode>
  userId?: Maybe<Scalars['UUID']['output']>
}

export type FilePageNodeConnection = {
  __typename?: 'FilePageNodeConnection'
  edges: Array<FilePageNodeEdge>
}

export type FilePageNodeEdge = {
  __typename?: 'FilePageNodeEdge'
  node: FilePageNode
}

export enum FilePageStatus {
  Deleted = 'DELETED',
  Done = 'DONE',
  Export = 'EXPORT',
  Extract = 'EXTRACT',
  Pending = 'PENDING',
  Qa = 'QA',
  Review = 'REVIEW',
}

export enum FilePageType {
  Excel = 'EXCEL',
  ImagePdf = 'IMAGE_PDF',
  Other = 'OTHER',
}

export type FilePagesConnection = {
  __typename?: 'FilePagesConnection'
  items: Array<FilePageNode>
  total: Scalars['Int']['output']
}

export type FileSignedUrl = {
  __typename?: 'FileSignedUrl'
  putUrl: Scalars['String']['output']
  viewUrl: Scalars['String']['output']
}

export enum FileStatus {
  Attached = 'ATTACHED',
  Deleted = 'DELETED',
  Pending = 'PENDING',
}

export type FilterSearchResult = {
  __typename?: 'FilterSearchResult'
  code: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  label?: Maybe<Scalars['String']['output']>
  shipmentCount?: Maybe<Scalars['Int']['output']>
}

export type FilterSearchResultResponse = {
  __typename?: 'FilterSearchResultResponse'
  filterSearchResults: Array<FilterSearchResult>
  filterSearchResultsTotal: Scalars['Int']['output']
}

export enum FilterVariant {
  BooleanFilterEquals = 'BOOLEAN_FILTER_EQUALS',
  DateFilterBetween = 'DATE_FILTER_BETWEEN',
  DateFilterGreaterThanOrEquals = 'DATE_FILTER_GREATER_THAN_OR_EQUALS',
  NumericFilterBetween = 'NUMERIC_FILTER_BETWEEN',
  NumericFilterEmpty = 'NUMERIC_FILTER_EMPTY',
  NumericFilterEquals = 'NUMERIC_FILTER_EQUALS',
  NumericFilterGreaterThan = 'NUMERIC_FILTER_GREATER_THAN',
  NumericFilterGreaterThanOrEquals = 'NUMERIC_FILTER_GREATER_THAN_OR_EQUALS',
  NumericFilterLessThan = 'NUMERIC_FILTER_LESS_THAN',
  NumericFilterLessThanOrEquals = 'NUMERIC_FILTER_LESS_THAN_OR_EQUALS',
  NumericFilterNotEmpty = 'NUMERIC_FILTER_NOT_EMPTY',
  NumericFilterNotEquals = 'NUMERIC_FILTER_NOT_EQUALS',
  TextFilterIn = 'TEXT_FILTER_IN',
  TextFilterMatch = 'TEXT_FILTER_MATCH',
}

export enum FilteredContainersColumn {
  ActualDeliveryDate = 'ACTUAL_DELIVERY_DATE',
  ActualPickupDate = 'ACTUAL_PICKUP_DATE',
  CarrierBookingNumber = 'CARRIER_BOOKING_NUMBER',
  CfsStorageStartDate = 'CFS_STORAGE_START_DATE',
  ChargeableVolume = 'CHARGEABLE_VOLUME',
  ChargeableWeight = 'CHARGEABLE_WEIGHT',
  Consignee = 'CONSIGNEE',
  ConsigneeCity = 'CONSIGNEE_CITY',
  ConsigneeCode = 'CONSIGNEE_CODE',
  ConsigneeCountry = 'CONSIGNEE_COUNTRY',
  ConsigneeName = 'CONSIGNEE_NAME',
  ConsigneeState = 'CONSIGNEE_STATE',
  ConsigneeUnlocode = 'CONSIGNEE_UNLOCODE',
  ContainerCountAndType = 'CONTAINER_COUNT_AND_TYPE',
  ContainerGrossWeight = 'CONTAINER_GROSS_WEIGHT',
  ContainerMode = 'CONTAINER_MODE',
  ContainerNumberDisplay = 'CONTAINER_NUMBER_DISPLAY',
  ContainerShipmentGoodsDescription = 'CONTAINER_SHIPMENT_GOODS_DESCRIPTION',
  ContainerShipmentGoodsVolume = 'CONTAINER_SHIPMENT_GOODS_VOLUME',
  ContainerShipmentGoodsWeight = 'CONTAINER_SHIPMENT_GOODS_WEIGHT',
  ContainerTeus = 'CONTAINER_TEUS',
  CtoStorageStartDate = 'CTO_STORAGE_START_DATE',
  DeliveryActualDate = 'DELIVERY_ACTUAL_DATE',
  DeliveryAgent = 'DELIVERY_AGENT',
  DeliveryAgentCity = 'DELIVERY_AGENT_CITY',
  DeliveryAgentCode = 'DELIVERY_AGENT_CODE',
  DeliveryAgentCountry = 'DELIVERY_AGENT_COUNTRY',
  DeliveryAgentName = 'DELIVERY_AGENT_NAME',
  DeliveryAgentState = 'DELIVERY_AGENT_STATE',
  DeliveryAgentUnlocode = 'DELIVERY_AGENT_UNLOCODE',
  DeliveryByDate = 'DELIVERY_BY_DATE',
  DeliveryEstimatedDate = 'DELIVERY_ESTIMATED_DATE',
  DeliveryFromDate = 'DELIVERY_FROM_DATE',
  DeliveryTo = 'DELIVERY_TO',
  DeliveryToCity = 'DELIVERY_TO_CITY',
  DeliveryToCode = 'DELIVERY_TO_CODE',
  DeliveryToCountry = 'DELIVERY_TO_COUNTRY',
  DeliveryToName = 'DELIVERY_TO_NAME',
  DeliveryToState = 'DELIVERY_TO_STATE',
  DeliveryToUnlocode = 'DELIVERY_TO_UNLOCODE',
  DeliveryTransportCompany = 'DELIVERY_TRANSPORT_COMPANY',
  DeliveryTransportCompanyCity = 'DELIVERY_TRANSPORT_COMPANY_CITY',
  DeliveryTransportCompanyCode = 'DELIVERY_TRANSPORT_COMPANY_CODE',
  DeliveryTransportCompanyCountry = 'DELIVERY_TRANSPORT_COMPANY_COUNTRY',
  DeliveryTransportCompanyName = 'DELIVERY_TRANSPORT_COMPANY_NAME',
  DeliveryTransportCompanyState = 'DELIVERY_TRANSPORT_COMPANY_STATE',
  DeliveryTransportCompanyUnlocode = 'DELIVERY_TRANSPORT_COMPANY_UNLOCODE',
  Description = 'DESCRIPTION',
  Direction = 'DIRECTION',
  DischargePortAta = 'DISCHARGE_PORT_ATA',
  DischargePortAtd = 'DISCHARGE_PORT_ATD',
  DischargePortDisplay = 'DISCHARGE_PORT_DISPLAY',
  DischargePortEta = 'DISCHARGE_PORT_ETA',
  DischargePortEtd = 'DISCHARGE_PORT_ETD',
  EmptyRequiredByDate = 'EMPTY_REQUIRED_BY_DATE',
  EmptyReturnRequiredByDate = 'EMPTY_RETURN_REQUIRED_BY_DATE',
  EstimatedDeliveryDate = 'ESTIMATED_DELIVERY_DATE',
  EstimatedPickupDate = 'ESTIMATED_PICKUP_DATE',
  ExportReleaseNumber = 'EXPORT_RELEASE_NUMBER',
  FirstLegAtd = 'FIRST_LEG_ATD',
  FirstLegEtd = 'FIRST_LEG_ETD',
  FirstLegOriginDisplay = 'FIRST_LEG_ORIGIN_DISPLAY',
  ForwarderReference = 'FORWARDER_REFERENCE',
  GoodsValue = 'GOODS_VALUE',
  GoodsValueCurrency = 'GOODS_VALUE_CURRENCY',
  HasExceptions = 'HAS_EXCEPTIONS',
  HouseBill = 'HOUSE_BILL',
  Importer = 'IMPORTER',
  ImporterCity = 'IMPORTER_CITY',
  ImporterCode = 'IMPORTER_CODE',
  ImporterCountry = 'IMPORTER_COUNTRY',
  ImporterName = 'IMPORTER_NAME',
  ImporterState = 'IMPORTER_STATE',
  ImporterUnlocode = 'IMPORTER_UNLOCODE',
  ImportReleaseNumber = 'IMPORT_RELEASE_NUMBER',
  Incoterm = 'INCOTERM',
  IsFailedToArrive = 'IS_FAILED_TO_ARRIVE',
  IsFailedToDepart = 'IS_FAILED_TO_DEPART',
  LastLegArrivalStatus = 'LAST_LEG_ARRIVAL_STATUS',
  LastLegAta = 'LAST_LEG_ATA',
  LastLegDestinationDisplay = 'LAST_LEG_DESTINATION_DISPLAY',
  LastLegEta = 'LAST_LEG_ETA',
  LastMilestoneTracked = 'LAST_MILESTONE_TRACKED',
  LastMilestoneTrackedDate = 'LAST_MILESTONE_TRACKED_DATE',
  LocalClient = 'LOCAL_CLIENT',
  LocalClientCity = 'LOCAL_CLIENT_CITY',
  LocalClientCode = 'LOCAL_CLIENT_CODE',
  LocalClientCountry = 'LOCAL_CLIENT_COUNTRY',
  LocalClientName = 'LOCAL_CLIENT_NAME',
  LocalClientState = 'LOCAL_CLIENT_STATE',
  LocalClientUnlocode = 'LOCAL_CLIENT_UNLOCODE',
  MainLegArrivalStatus = 'MAIN_LEG_ARRIVAL_STATUS',
  MainLegCarrier = 'MAIN_LEG_CARRIER',
  MainLegCarrierCity = 'MAIN_LEG_CARRIER_CITY',
  MainLegCarrierCode = 'MAIN_LEG_CARRIER_CODE',
  MainLegCarrierCountry = 'MAIN_LEG_CARRIER_COUNTRY',
  MainLegCarrierName = 'MAIN_LEG_CARRIER_NAME',
  MainLegCarrierState = 'MAIN_LEG_CARRIER_STATE',
  MainLegCarrierUnlocode = 'MAIN_LEG_CARRIER_UNLOCODE',
  MarksAndNumbers = 'MARKS_AND_NUMBERS',
  Milestone = 'MILESTONE',
  OrderRefs = 'ORDER_REFS',
  OriginPortAta = 'ORIGIN_PORT_ATA',
  OriginPortAtd = 'ORIGIN_PORT_ATD',
  OriginPortDisplay = 'ORIGIN_PORT_DISPLAY',
  OriginPortEta = 'ORIGIN_PORT_ETA',
  OriginPortEtd = 'ORIGIN_PORT_ETD',
  Packs = 'PACKS',
  PacksUom = 'PACKS_UOM',
  PickupActualDate = 'PICKUP_ACTUAL_DATE',
  PickupByDate = 'PICKUP_BY_DATE',
  PickupEstimatedDate = 'PICKUP_ESTIMATED_DATE',
  PickupFrom = 'PICKUP_FROM',
  PickupFromCity = 'PICKUP_FROM_CITY',
  PickupFromCode = 'PICKUP_FROM_CODE',
  PickupFromCountry = 'PICKUP_FROM_COUNTRY',
  PickupFromDate = 'PICKUP_FROM_DATE',
  PickupFromName = 'PICKUP_FROM_NAME',
  PickupFromState = 'PICKUP_FROM_STATE',
  PickupFromUnlocode = 'PICKUP_FROM_UNLOCODE',
  ReceivingAgent = 'RECEIVING_AGENT',
  ReceivingAgentCity = 'RECEIVING_AGENT_CITY',
  ReceivingAgentCode = 'RECEIVING_AGENT_CODE',
  ReceivingAgentCountry = 'RECEIVING_AGENT_COUNTRY',
  ReceivingAgentName = 'RECEIVING_AGENT_NAME',
  ReceivingAgentState = 'RECEIVING_AGENT_STATE',
  ReceivingAgentUnlocode = 'RECEIVING_AGENT_UNLOCODE',
  ReleaseType = 'RELEASE_TYPE',
  RouteDisplay = 'ROUTE_DISPLAY',
  SealNumber = 'SEAL_NUMBER',
  SendingAgent = 'SENDING_AGENT',
  SendingAgentCity = 'SENDING_AGENT_CITY',
  SendingAgentCode = 'SENDING_AGENT_CODE',
  SendingAgentCountry = 'SENDING_AGENT_COUNTRY',
  SendingAgentName = 'SENDING_AGENT_NAME',
  SendingAgentState = 'SENDING_AGENT_STATE',
  SendingAgentUnlocode = 'SENDING_AGENT_UNLOCODE',
  ServiceLevel = 'SERVICE_LEVEL',
  Shipper = 'SHIPPER',
  ShipperCity = 'SHIPPER_CITY',
  ShipperCode = 'SHIPPER_CODE',
  ShipperCountry = 'SHIPPER_COUNTRY',
  ShipperName = 'SHIPPER_NAME',
  ShipperState = 'SHIPPER_STATE',
  ShipperUnlocode = 'SHIPPER_UNLOCODE',
  Supplier = 'SUPPLIER',
  SupplierCity = 'SUPPLIER_CITY',
  SupplierCode = 'SUPPLIER_CODE',
  SupplierCountry = 'SUPPLIER_COUNTRY',
  SupplierName = 'SUPPLIER_NAME',
  SupplierState = 'SUPPLIER_STATE',
  SupplierUnlocode = 'SUPPLIER_UNLOCODE',
  Teus = 'TEUS',
  TransportMode = 'TRANSPORT_MODE',
  Vessel = 'VESSEL',
  Volume = 'VOLUME',
  Voyage = 'VOYAGE',
  Weight = 'WEIGHT',
}

export type FilteredContainersResult = {
  __typename?: 'FilteredContainersResult'
  filteredContainers: Array<ShipperVizContainer>
  filteredContainersSummary: ShipperVizContainersSummary
}

export enum FilteredShipmentsColumn {
  AgentReference = 'AGENT_REFERENCE',
  ArrivalCfsAddress = 'ARRIVAL_CFS_ADDRESS',
  BillingStatus = 'BILLING_STATUS',
  CarrierBookingNumber = 'CARRIER_BOOKING_NUMBER',
  CauldronDateCreated = 'CAULDRON_DATE_CREATED',
  ChargeableVolume = 'CHARGEABLE_VOLUME',
  ChargeableWeight = 'CHARGEABLE_WEIGHT',
  Consignee = 'CONSIGNEE',
  ConsigneeCity = 'CONSIGNEE_CITY',
  ConsigneeCode = 'CONSIGNEE_CODE',
  ConsigneeCountry = 'CONSIGNEE_COUNTRY',
  ConsigneeName = 'CONSIGNEE_NAME',
  ConsigneeState = 'CONSIGNEE_STATE',
  ConsigneeUnlocode = 'CONSIGNEE_UNLOCODE',
  ConsolNumber = 'CONSOL_NUMBER',
  Container = 'CONTAINER',
  ContainerCountAndType = 'CONTAINER_COUNT_AND_TYPE',
  ContainerMode = 'CONTAINER_MODE',
  ControllingAgent = 'CONTROLLING_AGENT',
  ControllingAgentCity = 'CONTROLLING_AGENT_CITY',
  ControllingAgentCode = 'CONTROLLING_AGENT_CODE',
  ControllingAgentCountry = 'CONTROLLING_AGENT_COUNTRY',
  ControllingAgentName = 'CONTROLLING_AGENT_NAME',
  ControllingAgentState = 'CONTROLLING_AGENT_STATE',
  ControllingAgentUnlocode = 'CONTROLLING_AGENT_UNLOCODE',
  ControllingCustomer = 'CONTROLLING_CUSTOMER',
  ControllingCustomerCity = 'CONTROLLING_CUSTOMER_CITY',
  ControllingCustomerCode = 'CONTROLLING_CUSTOMER_CODE',
  ControllingCustomerCountry = 'CONTROLLING_CUSTOMER_COUNTRY',
  ControllingCustomerName = 'CONTROLLING_CUSTOMER_NAME',
  ControllingCustomerState = 'CONTROLLING_CUSTOMER_STATE',
  ControllingCustomerUnlocode = 'CONTROLLING_CUSTOMER_UNLOCODE',
  Currency = 'CURRENCY',
  DateShipmentClosed = 'DATE_SHIPMENT_CLOSED',
  DateShipmentCreated = 'DATE_SHIPMENT_CREATED',
  DeclarationDischargeDate = 'DECLARATION_DISCHARGE_DATE',
  DeclarationEntryNum = 'DECLARATION_ENTRY_NUM',
  DeclarationMessageType = 'DECLARATION_MESSAGE_TYPE',
  DeliveryActualDate = 'DELIVERY_ACTUAL_DATE',
  DeliveryAgent = 'DELIVERY_AGENT',
  DeliveryByDate = 'DELIVERY_BY_DATE',
  DeliveryEstimatedDate = 'DELIVERY_ESTIMATED_DATE',
  DeliveryFromDate = 'DELIVERY_FROM_DATE',
  DeliveryTo = 'DELIVERY_TO',
  DeliveryToCity = 'DELIVERY_TO_CITY',
  DeliveryToCode = 'DELIVERY_TO_CODE',
  DeliveryToCountry = 'DELIVERY_TO_COUNTRY',
  DeliveryToName = 'DELIVERY_TO_NAME',
  DeliveryToState = 'DELIVERY_TO_STATE',
  DeliveryToUnlocode = 'DELIVERY_TO_UNLOCODE',
  DeliveryTransportCompany = 'DELIVERY_TRANSPORT_COMPANY',
  DeliveryTransportCompanyCity = 'DELIVERY_TRANSPORT_COMPANY_CITY',
  DeliveryTransportCompanyCode = 'DELIVERY_TRANSPORT_COMPANY_CODE',
  DeliveryTransportCompanyCountry = 'DELIVERY_TRANSPORT_COMPANY_COUNTRY',
  DeliveryTransportCompanyName = 'DELIVERY_TRANSPORT_COMPANY_NAME',
  DeliveryTransportCompanyState = 'DELIVERY_TRANSPORT_COMPANY_STATE',
  DeliveryTransportCompanyUnlocode = 'DELIVERY_TRANSPORT_COMPANY_UNLOCODE',
  DepartureCfsAddress = 'DEPARTURE_CFS_ADDRESS',
  Description = 'DESCRIPTION',
  Direction = 'DIRECTION',
  DischargePortAta = 'DISCHARGE_PORT_ATA',
  DischargePortAtd = 'DISCHARGE_PORT_ATD',
  DischargePortDisplay = 'DISCHARGE_PORT_DISPLAY',
  DischargePortEta = 'DISCHARGE_PORT_ETA',
  DischargePortEtd = 'DISCHARGE_PORT_ETD',
  FirstApPosted = 'FIRST_AP_POSTED',
  FirstArPosted = 'FIRST_AR_POSTED',
  FirstLegAtd = 'FIRST_LEG_ATD',
  FirstLegEtd = 'FIRST_LEG_ETD',
  FirstLegOriginDisplay = 'FIRST_LEG_ORIGIN_DISPLAY',
  ForwarderReference = 'FORWARDER_REFERENCE',
  GoodsValue = 'GOODS_VALUE',
  GoodsValueCurrency = 'GOODS_VALUE_CURRENCY',
  HasExceptions = 'HAS_EXCEPTIONS',
  HouseBill = 'HOUSE_BILL',
  Importer = 'IMPORTER',
  ImporterCity = 'IMPORTER_CITY',
  ImporterCode = 'IMPORTER_CODE',
  ImporterCountry = 'IMPORTER_COUNTRY',
  ImporterName = 'IMPORTER_NAME',
  ImporterState = 'IMPORTER_STATE',
  ImporterUnlocode = 'IMPORTER_UNLOCODE',
  Incoterm = 'INCOTERM',
  IsFailedToArrive = 'IS_FAILED_TO_ARRIVE',
  IsFailedToDepart = 'IS_FAILED_TO_DEPART',
  LastLegArrivalStatus = 'LAST_LEG_ARRIVAL_STATUS',
  LastLegAta = 'LAST_LEG_ATA',
  LastLegDestinationDisplay = 'LAST_LEG_DESTINATION_DISPLAY',
  LastLegEta = 'LAST_LEG_ETA',
  LocalClient = 'LOCAL_CLIENT',
  LocalClientCity = 'LOCAL_CLIENT_CITY',
  LocalClientCode = 'LOCAL_CLIENT_CODE',
  LocalClientCountry = 'LOCAL_CLIENT_COUNTRY',
  LocalClientName = 'LOCAL_CLIENT_NAME',
  LocalClientState = 'LOCAL_CLIENT_STATE',
  LocalClientUnlocode = 'LOCAL_CLIENT_UNLOCODE',
  MainLegArrivalStatus = 'MAIN_LEG_ARRIVAL_STATUS',
  MainLegCarrier = 'MAIN_LEG_CARRIER',
  MainLegCarrierCity = 'MAIN_LEG_CARRIER_CITY',
  MainLegCarrierCode = 'MAIN_LEG_CARRIER_CODE',
  MainLegCarrierCountry = 'MAIN_LEG_CARRIER_COUNTRY',
  MainLegCarrierName = 'MAIN_LEG_CARRIER_NAME',
  MainLegCarrierState = 'MAIN_LEG_CARRIER_STATE',
  MainLegCarrierUnlocode = 'MAIN_LEG_CARRIER_UNLOCODE',
  MarksAndNumbers = 'MARKS_AND_NUMBERS',
  Milestone = 'MILESTONE',
  Module = 'MODULE',
  OrderRefs = 'ORDER_REFS',
  OriginPortAta = 'ORIGIN_PORT_ATA',
  OriginPortAtd = 'ORIGIN_PORT_ATD',
  OriginPortDisplay = 'ORIGIN_PORT_DISPLAY',
  OriginPortEta = 'ORIGIN_PORT_ETA',
  OriginPortEtd = 'ORIGIN_PORT_ETD',
  Packs = 'PACKS',
  PacksUom = 'PACKS_UOM',
  PickupActualDate = 'PICKUP_ACTUAL_DATE',
  PickupByDate = 'PICKUP_BY_DATE',
  PickupEstimatedDate = 'PICKUP_ESTIMATED_DATE',
  PickupFrom = 'PICKUP_FROM',
  PickupFromDate = 'PICKUP_FROM_DATE',
  PickupTransportCompany = 'PICKUP_TRANSPORT_COMPANY',
  PickupTransportCompanyCity = 'PICKUP_TRANSPORT_COMPANY_CITY',
  PickupTransportCompanyCode = 'PICKUP_TRANSPORT_COMPANY_CODE',
  PickupTransportCompanyCountry = 'PICKUP_TRANSPORT_COMPANY_COUNTRY',
  PickupTransportCompanyName = 'PICKUP_TRANSPORT_COMPANY_NAME',
  PickupTransportCompanyState = 'PICKUP_TRANSPORT_COMPANY_STATE',
  PickupTransportCompanyUnlocode = 'PICKUP_TRANSPORT_COMPANY_UNLOCODE',
  ReceivingAgent = 'RECEIVING_AGENT',
  ReceivingAgentCity = 'RECEIVING_AGENT_CITY',
  ReceivingAgentCode = 'RECEIVING_AGENT_CODE',
  ReceivingAgentCountry = 'RECEIVING_AGENT_COUNTRY',
  ReceivingAgentName = 'RECEIVING_AGENT_NAME',
  ReceivingAgentState = 'RECEIVING_AGENT_STATE',
  ReceivingAgentUnlocode = 'RECEIVING_AGENT_UNLOCODE',
  ReleaseType = 'RELEASE_TYPE',
  RouteDisplay = 'ROUTE_DISPLAY',
  SendingAgent = 'SENDING_AGENT',
  SendingAgentCity = 'SENDING_AGENT_CITY',
  SendingAgentCode = 'SENDING_AGENT_CODE',
  SendingAgentCountry = 'SENDING_AGENT_COUNTRY',
  SendingAgentName = 'SENDING_AGENT_NAME',
  SendingAgentState = 'SENDING_AGENT_STATE',
  SendingAgentUnlocode = 'SENDING_AGENT_UNLOCODE',
  ServiceLevel = 'SERVICE_LEVEL',
  ServiceLevelDescription = 'SERVICE_LEVEL_DESCRIPTION',
  ShipmentAta = 'SHIPMENT_ATA',
  ShipmentAtd = 'SHIPMENT_ATD',
  ShipmentDestination = 'SHIPMENT_DESTINATION',
  ShipmentEta = 'SHIPMENT_ETA',
  ShipmentEtd = 'SHIPMENT_ETD',
  ShipmentOrigin = 'SHIPMENT_ORIGIN',
  ShipmentStatus = 'SHIPMENT_STATUS',
  Shipper = 'SHIPPER',
  ShipperCity = 'SHIPPER_CITY',
  ShipperCode = 'SHIPPER_CODE',
  ShipperCountry = 'SHIPPER_COUNTRY',
  ShipperName = 'SHIPPER_NAME',
  ShipperState = 'SHIPPER_STATE',
  ShipperUnlocode = 'SHIPPER_UNLOCODE',
  SnowflakeDateCreated = 'SNOWFLAKE_DATE_CREATED',
  Supplier = 'SUPPLIER',
  SupplierCity = 'SUPPLIER_CITY',
  SupplierCode = 'SUPPLIER_CODE',
  SupplierCountry = 'SUPPLIER_COUNTRY',
  SupplierName = 'SUPPLIER_NAME',
  SupplierState = 'SUPPLIER_STATE',
  SupplierUnlocode = 'SUPPLIER_UNLOCODE',
  Teus = 'TEUS',
  TransportMode = 'TRANSPORT_MODE',
  TransportNumbers = 'TRANSPORT_NUMBERS',
  Vessel = 'VESSEL',
  Volume = 'VOLUME',
  Voyage = 'VOYAGE',
  Weight = 'WEIGHT',
}

export type FilteredShipmentsResult = {
  __typename?: 'FilteredShipmentsResult'
  filteredShipments: Array<ShipperVizShipment>
  filteredShipmentsSummary: ShipperVizShipmentsSummary
}

export type FindConsolReconResultNode = ReconResultInterface & {
  __typename?: 'FindConsolReconResultNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  documentConsolNo?: Maybe<Scalars['String']['output']>
  documentContainerNumbers?: Maybe<Scalars['String']['output']>
  documentMbl?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  keyNo?: Maybe<Scalars['String']['output']>
  matchedKey?: Maybe<Scalars['String']['output']>
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type FindInvoiceDuplicateReconResultNode = {
  __typename?: 'FindInvoiceDuplicateReconResultNode'
  chainIoSi: ChainIoServiceInvoiceNode
  chainIoSiId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  findInvoiceReconResult: FindInvoiceReconResultNode
  findInvoiceReconResultId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
}

export type FindInvoiceDuplicateReconResultNodeConnection = {
  __typename?: 'FindInvoiceDuplicateReconResultNodeConnection'
  edges: Array<FindInvoiceDuplicateReconResultNodeEdge>
}

export type FindInvoiceDuplicateReconResultNodeEdge = {
  __typename?: 'FindInvoiceDuplicateReconResultNodeEdge'
  node: FindInvoiceDuplicateReconResultNode
}

export type FindInvoiceReconResultFromJobNode = {
  __typename?: 'FindInvoiceReconResultFromJobNode'
  docCharges: Array<ChargeDetail>
  expectedCharges: Array<ChargeDetail>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconResults: Array<ReconResultInterface>
}

export type FindInvoiceReconResultNode = ReconResultInterface & {
  __typename?: 'FindInvoiceReconResultNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  chainIoSi?: Maybe<ChainIoServiceInvoiceNode>
  chainIoSiId?: Maybe<Scalars['UUID']['output']>
  cleanedVendorName?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  findInvoiceDuplicateReconResults: FindInvoiceDuplicateReconResultNodeConnection
  forwarderReference?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceDateYear?: Maybe<Scalars['String']['output']>
  invoiceNumber?: Maybe<Scalars['String']['output']>
  invoiceReconMatches: InvoiceReconMatchNodeConnection
  invoiceTmsId?: Maybe<Scalars['String']['output']>
  job: JobNode
  jobId: Scalars['UUID']['output']
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type FindShipmentDuplicateReconResultNode = ReconResultInterface & {
  __typename?: 'FindShipmentDuplicateReconResultNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  findShipmentReconResult: FindShipmentReconResultNode
  findShipmentReconResultId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type FindShipmentDuplicateReconResultNodeConnection = {
  __typename?: 'FindShipmentDuplicateReconResultNodeConnection'
  edges: Array<FindShipmentDuplicateReconResultNodeEdge>
}

export type FindShipmentDuplicateReconResultNodeEdge = {
  __typename?: 'FindShipmentDuplicateReconResultNodeEdge'
  node: FindShipmentDuplicateReconResultNode
}

export type FindShipmentReconResultLineItemSnapshotNode = {
  __typename?: 'FindShipmentReconResultLineItemSnapshotNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  findShipmentReconResult: FindShipmentReconResultNode
  findShipmentReconResultId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  invoiceReconLineItemSnapshot: InvoiceReconLineItemSnapshotInterface
  invoiceReconLineItemSnapshotId: Scalars['UUID']['output']
}

export type FindShipmentReconResultLineItemSnapshotNodeConnection = {
  __typename?: 'FindShipmentReconResultLineItemSnapshotNodeConnection'
  edges: Array<FindShipmentReconResultLineItemSnapshotNodeEdge>
}

export type FindShipmentReconResultLineItemSnapshotNodeEdge = {
  __typename?: 'FindShipmentReconResultLineItemSnapshotNodeEdge'
  node: FindShipmentReconResultLineItemSnapshotNode
}

export type FindShipmentReconResultNode = ReconResultInterface & {
  __typename?: 'FindShipmentReconResultNode'
  carrierBookingNo?: Maybe<Scalars['String']['output']>
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  checkShipmentInfoMatchCriteriaId?: Maybe<Scalars['UUID']['output']>
  consolNo?: Maybe<Scalars['String']['output']>
  containerNo?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  findShipmentDuplicateReconResults: FindShipmentDuplicateReconResultNodeConnection
  findShipmentReconResultLineItemSnapshots: FindShipmentReconResultLineItemSnapshotNodeConnection
  hblNo?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceReconLineItemSnapshots: InvoiceReconLineItemSnapshotInterfaceConnection
  isMaximalAmongDuplicates?: Maybe<Scalars['Boolean']['output']>
  isPrimary: Scalars['Boolean']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  mblNo?: Maybe<Scalars['String']['output']>
  orderNo?: Maybe<Scalars['String']['output']>
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  referenceNo?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type GeoLocationNode = SearchableRecordResultNode & {
  __typename?: 'GeoLocationNode'
  cleanedLabel?: Maybe<Scalars['String']['output']>
  code: Scalars['String']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  formattedAddress?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  label?: Maybe<Scalars['String']['output']>
  latitude?: Maybe<Scalars['Float']['output']>
  locodeCoordinates?: Maybe<Scalars['String']['output']>
  locodeCountry?: Maybe<Scalars['String']['output']>
  locodeDate?: Maybe<Scalars['Date']['output']>
  locodeFunction?: Maybe<Scalars['String']['output']>
  locodeIata?: Maybe<Scalars['String']['output']>
  locodeLocation?: Maybe<Scalars['String']['output']>
  locodeName?: Maybe<Scalars['String']['output']>
  locodeNameWithoutDiacritics?: Maybe<Scalars['String']['output']>
  locodePossibleNames?: Maybe<Array<Scalars['String']['output']>>
  locodeRemarks?: Maybe<Scalars['String']['output']>
  locodeStatus?: Maybe<Scalars['String']['output']>
  locodeSubdivision?: Maybe<Scalars['String']['output']>
  longitude?: Maybe<Scalars['Float']['output']>
  placeId?: Maybe<Scalars['String']['output']>
  timezoneName?: Maybe<Scalars['String']['output']>
}

export type GetDashboardEDocs = {
  __typename?: 'GetDashboardEDocs'
  dashboardEDocs: Array<DashboardEdocResult>
}

export type GrapheneImageDimensions = {
  height: Scalars['Int']['input']
  width: Scalars['Int']['input']
}

export type GrapheneInputFile = {
  filename: Scalars['String']['input']
  originalFileUri?: InputMaybe<Scalars['String']['input']>
  pages: Array<InputMaybe<GrapheneInputPage>>
  type: FilePageType
}

export type GrapheneInputPage = {
  documentTypeId?: InputMaybe<Scalars['UUID']['input']>
  pageNumber: Scalars['Int']['input']
  signedViewUrl: Scalars['String']['input']
  size?: InputMaybe<GrapheneImageDimensions>
}

export enum GroupByDateType {
  DateShipmentCreated = 'DATE_SHIPMENT_CREATED',
  DeliveryEstimatedDate = 'DELIVERY_ESTIMATED_DATE',
  EtaOnly = 'ETA_ONLY',
  EtdOnly = 'ETD_ONLY',
  EtdOrEta = 'ETD_OR_ETA',
  FirstApPosted = 'FIRST_AP_POSTED',
  FirstArPosted = 'FIRST_AR_POSTED',
  PickupEstimatedDate = 'PICKUP_ESTIMATED_DATE',
  ShipmentClosed = 'SHIPMENT_CLOSED',
}

export type HardcodedCardReference = {
  __typename?: 'HardcodedCardReference'
  dashboardReference: HardcodedDashboardReference
  graphKey: Scalars['String']['output']
  isHardcoded: Scalars['Boolean']['output']
}

export type HardcodedDashboardReference = {
  __typename?: 'HardcodedDashboardReference'
  isHardcoded: Scalars['Boolean']['output']
  tab: HardcodedTabEnum
  tabType: DashboardTabType
}

export enum HardcodedTabEnum {
  AccountingBilling = 'ACCOUNTING_BILLING',
  AccountingCollections = 'ACCOUNTING_COLLECTIONS',
  AccountingMargins = 'ACCOUNTING_MARGINS',
  AccountingOverview = 'ACCOUNTING_OVERVIEW',
  AccountOverview = 'ACCOUNT_OVERVIEW',
  BranchOverview = 'BRANCH_OVERVIEW',
  CustomDashboards = 'CUSTOM_DASHBOARDS',
  DepartmentOverview = 'DEPARTMENT_OVERVIEW',
  Expenses = 'EXPENSES',
  ExploreContainers = 'EXPLORE_CONTAINERS',
  ExploreOrganizations = 'EXPLORE_ORGANIZATIONS',
  ExploreShipments = 'EXPLORE_SHIPMENTS',
  JobSummary = 'JOB_SUMMARY',
  MainDashboard = 'MAIN_DASHBOARD',
  Margins = 'MARGINS',
  MarginAttributions = 'MARGIN_ATTRIBUTIONS',
  MostProfitableRoutes = 'MOST_PROFITABLE_ROUTES',
  NegativeMarginShipments = 'NEGATIVE_MARGIN_SHIPMENTS',
  Overview = 'OVERVIEW',
  PayableInvoices = 'PAYABLE_INVOICES',
  ReceivableInvoices = 'RECEIVABLE_INVOICES',
  ReconJobList = 'RECON_JOB_LIST',
  Revenue = 'REVENUE',
  SalesOverview = 'SALES_OVERVIEW',
  ShipmentDetails = 'SHIPMENT_DETAILS',
  ShipmentVolume = 'SHIPMENT_VOLUME',
  TopExpenses = 'TOP_EXPENSES',
}

export type HistogramDataGroup = {
  __typename?: 'HistogramDataGroup'
  group: Scalars['String']['output']
  groupLabel?: Maybe<Scalars['String']['output']>
  transportModeData?: Maybe<Array<TransportModeDatum>>
  value?: Maybe<Scalars['String']['output']>
}

export type HistogramDataResult = {
  __typename?: 'HistogramDataResult'
  linkedTab?: Maybe<DashboardReference>
  periodField: Scalars['String']['output']
  periodLabel: Scalars['String']['output']
  results: Array<HistogramDataGroup>
  valueLabel: Scalars['String']['output']
}

export type HistogramGroupedDataGroup = {
  __typename?: 'HistogramGroupedDataGroup'
  group: Scalars['String']['output']
  subgroups: Array<HistogramDataGroup>
}

export type HistogramGroupedDataResult = {
  __typename?: 'HistogramGroupedDataResult'
  groupLabel: Scalars['String']['output']
  linkedTab?: Maybe<DashboardReference>
  periodAliasLabel: Scalars['String']['output']
  periodField: Scalars['String']['output']
  periodLabel: Scalars['String']['output']
  results: Array<HistogramGroupedDataGroup>
  valueLabel: Scalars['String']['output']
}

export type HistogramStackedDataGroup = {
  __typename?: 'HistogramStackedDataGroup'
  group: Scalars['String']['output']
  subgroups: Array<HistogramDataGroup>
}

export type HistogramStackedDataResult = {
  __typename?: 'HistogramStackedDataResult'
  innerValueLabel?: Maybe<Scalars['String']['output']>
  periodField: Scalars['String']['output']
  periodLabel: Scalars['String']['output']
  results: Array<HistogramStackedDataGroup>
  valueField: Scalars['String']['output']
  valueLabel: Scalars['String']['output']
}

export type ImportEDocumentsFromApiPartner = {
  __typename?: 'ImportEDocumentsFromApiPartner'
  ok: Scalars['Boolean']['output']
}

export type IngestAutofillFiles = {
  __typename?: 'IngestAutofillFiles'
  ok: Scalars['Boolean']['output']
}

export type IngestFiles = {
  __typename?: 'IngestFiles'
  ok: Scalars['Boolean']['output']
}

export type InputApiPartner = {
  apiKey: Scalars['UUID']['input']
  apiPartnerType: ApiPartnerType
  authValues?: InputMaybe<Scalars['String']['input']>
  companyId?: InputMaybe<Scalars['UUID']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  credentialId?: InputMaybe<Scalars['UUID']['input']>
  documentExportEndpoint?: InputMaybe<Scalars['String']['input']>
  documentImportEndpoint?: InputMaybe<Scalars['String']['input']>
  expedockDocIngestEmail?: InputMaybe<Scalars['String']['input']>
  exportEndpoint?: InputMaybe<Scalars['String']['input']>
  importEndpoint?: InputMaybe<Scalars['String']['input']>
  jobTemplateIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  name: Scalars['String']['input']
  tmsType?: InputMaybe<ApiPartnerTmsType>
}

export type InputBoxDimension = {
  height?: InputMaybe<Scalars['Float']['input']>
  left?: InputMaybe<Scalars['Float']['input']>
  top?: InputMaybe<Scalars['Float']['input']>
  width?: InputMaybe<Scalars['Float']['input']>
}

export type InputCard = {
  cardFilter?: InputMaybe<Scalars['String']['input']>
  contextMenuFilters?: InputMaybe<Array<InputContextMenuFilter>>
  customExploreType?: InputMaybe<ExploreDataType>
  description?: InputMaybe<Scalars['String']['input']>
  dottedColumnName?: InputMaybe<Scalars['String']['input']>
  drilldownCta?: InputMaybe<DrilldownCta>
  drilldownCtaDisplayName?: InputMaybe<Scalars['String']['input']>
  graphType: CardGraphTypes
  height: Scalars['Int']['input']
  name: Scalars['String']['input']
  queryDescription?: InputMaybe<Scalars['String']['input']>
  rootQuery: Scalars['String']['input']
  subtitle?: InputMaybe<Scalars['String']['input']>
  summaryUnitType?: InputMaybe<SummaryUnitType>
  width: Scalars['Int']['input']
  xAxis?: InputMaybe<Scalars['String']['input']>
  xAxis2?: InputMaybe<Scalars['String']['input']>
  xPosition: Scalars['Int']['input']
  yAxis?: InputMaybe<Scalars['String']['input']>
  yAxis2?: InputMaybe<Scalars['String']['input']>
  yAxisTarget?: InputMaybe<Scalars['Float']['input']>
  yPosition: Scalars['Int']['input']
  zAxis?: InputMaybe<Scalars['String']['input']>
}

export type InputCargowiseConfig = {
  code: Scalars['String']['input']
  companyId?: InputMaybe<Scalars['UUID']['input']>
  connectorType?: InputMaybe<Scalars['String']['input']>
  countryCode: Scalars['String']['input']
  dbDirectConnect?: Scalars['Boolean']['input']
  dbName?: InputMaybe<Scalars['String']['input']>
  dbPass?: InputMaybe<Scalars['String']['input']>
  dbUser?: InputMaybe<Scalars['String']['input']>
  defaultTimezoneLocationId: Scalars['UUID']['input']
  enterpriseId: Scalars['String']['input']
  externApiCredentialId?: InputMaybe<Scalars['UUID']['input']>
  forceFetch?: Scalars['Boolean']['input']
  jobTemplateIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  outboundPass?: InputMaybe<Scalars['String']['input']>
  pollDatabase?: InputMaybe<Scalars['Boolean']['input']>
  proxyUrls?: InputMaybe<Array<Scalars['String']['input']>>
  remoteDbHostname?: InputMaybe<Scalars['String']['input']>
  remoteDbPort?: InputMaybe<Scalars['Int']['input']>
  serverId: Scalars['String']['input']
}

export type InputCargowiseOutboundFilter = {
  cargowiseConfigId?: InputMaybe<Scalars['UUID']['input']>
  cargowiseOutboundId?: InputMaybe<Scalars['String']['input']>
  dateEnd?: InputMaybe<Scalars['DateTime']['input']>
  dateStart?: InputMaybe<Scalars['DateTime']['input']>
  integrationId?: InputMaybe<Scalars['String']['input']>
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>
}

export type InputChargeCode = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  code: Scalars['String']['input']
  description: Scalars['String']['input']
}

export type InputChargeCodeCsv = {
  rows: Array<InputMaybe<InputChargeCodeCsvRow>>
}

export type InputChargeCodeCsvRow = {
  chargeCode: Scalars['String']['input']
  chargeDescription: Scalars['String']['input']
}

export type InputColdStartFile = {
  fileData: Scalars['String']['input']
  fileName: Scalars['String']['input']
}

export type InputContextMenuFilter = {
  columnName: Scalars['String']['input']
  filterByDateSelector?: Scalars['Boolean']['input']
  filterField?: InputMaybe<Scalars['String']['input']>
}

export type InputCustomDatabase = {
  customDatabaseTypeId: Scalars['UUID']['input']
  name: Scalars['String']['input']
  rows: Array<InputCustomDatabaseRow>
}

export type InputCustomDatabaseCell = {
  customDatabaseColumnId: Scalars['UUID']['input']
  value: Scalars['String']['input']
}

export type InputCustomDatabaseColumn = {
  displayName: Scalars['String']['input']
  exportKey: Scalars['String']['input']
}

export type InputCustomDatabaseRow = {
  cells: Array<InputCustomDatabaseCell>
}

export type InputCustomDatabaseType = {
  columns: Array<InputCustomDatabaseColumn>
  name: Scalars['String']['input']
  tmsType: ApiPartnerTmsType
}

export type InputDocument = {
  documentTables: Array<InputDocumentTable>
  fieldCoordinates: Scalars['GenericScalar']['input']
  fieldMapping: Scalars['GenericScalar']['input']
  id: Scalars['UUID']['input']
}

export type InputDocumentTable = {
  editedByUser?: InputMaybe<Scalars['Boolean']['input']>
  fieldCoordinates?: InputMaybe<Scalars['GenericScalar']['input']>
  fieldGroupId: Scalars['UUID']['input']
  fieldGroupKey: Scalars['String']['input']
  id: Scalars['UUID']['input']
  lineItems: Array<InputLineItem>
  tableColumns: Array<InputTableColumn>
}

export type InputDocumentTableSkeleton = {
  fieldGroupId: Scalars['UUID']['input']
  fieldGroupKey: Scalars['String']['input']
  id: Scalars['UUID']['input']
  rows: Array<InputMaybe<InputRowSkeleton>>
  tableColumns: Array<InputMaybe<InputTableColumn>>
}

export type InputDocumentType = {
  autofillExtractorKey: AutofillExtractorKey
  cargowiseFileTypeId?: InputMaybe<Scalars['UUID']['input']>
  collapsible: Scalars['Boolean']['input']
  derivedFromId?: InputMaybe<Scalars['UUID']['input']>
  id: Scalars['UUID']['input']
  isEDocPublishedByDefault?: InputMaybe<Scalars['Boolean']['input']>
  isStandard: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  tableShowsPreset: Scalars['Boolean']['input']
}

export type InputEAdaptorColdStartParams = {
  apInvoiceReports?: InputMaybe<Array<InputColdStartFile>>
  arInvoiceReports?: InputMaybe<Array<InputColdStartFile>>
  cdecProfileReports?: InputMaybe<Array<InputColdStartFile>>
  jobManagementReports?: InputMaybe<Array<InputColdStartFile>>
  shipmentProfileReports?: InputMaybe<Array<InputColdStartFile>>
  transportProfileReports?: InputMaybe<Array<InputColdStartFile>>
}

export type InputEDocument = {
  filePageIds: Array<Scalars['UUID']['input']>
  isPublished: Scalars['Boolean']['input']
}

export type InputField = {
  autofillKey?: InputMaybe<Scalars['String']['input']>
  dateFormatString?: InputMaybe<Scalars['String']['input']>
  exportKey?: InputMaybe<Scalars['String']['input']>
  id: Scalars['UUID']['input']
  invalidCharsRegex?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  required: Scalars['Boolean']['input']
  searchableRecord?: InputMaybe<InputSearchableRecord>
  type?: InputMaybe<InputFieldType>
  validatorDescription?: InputMaybe<Scalars['String']['input']>
  validatorRegex?: InputMaybe<Scalars['String']['input']>
  valueExportMapping?: InputMaybe<Scalars['JSONString']['input']>
  values?: InputMaybe<Scalars['JSONString']['input']>
}

export type InputFieldGroup = {
  autofillKey?: InputMaybe<Scalars['String']['input']>
  documentTypeId: Scalars['UUID']['input']
  exportKey?: InputMaybe<Scalars['String']['input']>
  fields: Array<InputField>
  id: Scalars['UUID']['input']
  name: Scalars['String']['input']
  type?: InputMaybe<InputFieldGroupType>
}

export enum InputFieldGroupType {
  Database = 'DATABASE',
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Decimal = 'DECIMAL',
  Integer = 'INTEGER',
  LineItem = 'LINE_ITEM',
  Price = 'PRICE',
  ReferenceNumber = 'REFERENCE_NUMBER',
  SingleSelectDropdown = 'SINGLE_SELECT_DROPDOWN',
  Text = 'TEXT',
  TextWithSuggestions = 'TEXT_WITH_SUGGESTIONS',
}

export enum InputFieldType {
  Database = 'DATABASE',
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Decimal = 'DECIMAL',
  Integer = 'INTEGER',
  Price = 'PRICE',
  ReferenceNumber = 'REFERENCE_NUMBER',
  SingleSelectDropdown = 'SINGLE_SELECT_DROPDOWN',
  Text = 'TEXT',
  TextWithSuggestions = 'TEXT_WITH_SUGGESTIONS',
}

export type InputHardcodedCardReference = {
  dashboardReference: InputHardcodedDashboardReference
  graphKey: Scalars['String']['input']
  isHardcoded?: Scalars['Boolean']['input']
}

export type InputHardcodedDashboardReference = {
  isHardcoded?: Scalars['Boolean']['input']
  tab: HardcodedTabEnum
  tabType: DashboardTabType
}

export type InputJobFilter = {
  column: JobFilterColumn
  id: Scalars['UUID']['input']
  operation: JobFilterOperation
  value: Array<Scalars['String']['input']>
}

export type InputJobTable = {
  columns: Array<Scalars['String']['input']>
  fieldGroupId: Scalars['UUID']['input']
  rows: Array<InputJobTableLineItem>
}

export type InputJobTableCell = {
  documentId: Scalars['String']['input']
  fieldId: Scalars['UUID']['input']
  height?: InputMaybe<Scalars['Float']['input']>
  left?: InputMaybe<Scalars['Float']['input']>
  top?: InputMaybe<Scalars['Float']['input']>
  value: Scalars['String']['input']
  width?: InputMaybe<Scalars['Float']['input']>
}

export type InputJobTableLineItem = {
  cells: Array<InputJobTableCell>
}

export type InputJobTemplateExportColumn = {
  columnName?: InputMaybe<Scalars['String']['input']>
  fieldId?: InputMaybe<Scalars['UUID']['input']>
  fixedValue?: InputMaybe<Scalars['String']['input']>
  order: Scalars['Int']['input']
}

export type InputJobTemplateExportSheet = {
  columns: Array<InputJobTemplateExportColumn>
  displayColumnHeader: Scalars['Boolean']['input']
  fieldGroupId?: InputMaybe<Scalars['UUID']['input']>
  order: Scalars['Int']['input']
}

export type InputLineItem = {
  box?: InputMaybe<InputBoxDimension>
  documentTableId: Scalars['UUID']['input']
  fieldMapping?: InputMaybe<Scalars['GenericScalar']['input']>
  id: Scalars['UUID']['input']
  rowOrderPriority?: InputMaybe<Scalars['Int']['input']>
}

export type InputLineItemSkeleton = {
  dimension?: InputMaybe<InputBoxDimension>
  key: Scalars['String']['input']
}

export type InputPivotRowColumn = {
  displayName?: InputMaybe<Scalars['String']['input']>
  dottedFieldName?: InputMaybe<Scalars['String']['input']>
  fieldName: Scalars['String']['input']
  isRow: Scalars['Boolean']['input']
  periodType?: InputMaybe<PeriodType>
  showTotals: Scalars['Boolean']['input']
}

export type InputPivotTable = {
  rowsColumns: Array<InputPivotRowColumn>
  values: Array<InputPivotValue>
}

export type InputPivotValue = {
  displayName?: InputMaybe<Scalars['String']['input']>
  fieldName: Scalars['String']['input']
  valueFunction: PivotValueFunction
}

export type InputReconInfoObject = {
  externalAssigneeId?: InputMaybe<Scalars['UUID']['input']>
  externalStatus?: InputMaybe<JobExternalStatus>
  id: Scalars['UUID']['input']
}

export type InputReconPremiumRate = {
  accessorialFee: Scalars['Float']['input']
  invoiceTotal: Scalars['Float']['input']
  premiumRate: Scalars['Float']['input']
  quantity: Scalars['Int']['input']
}

export type InputReconThresholdRange = {
  maximum: Scalars['Decimal']['input']
  minimum: Scalars['Decimal']['input']
  thresholdAmount: Scalars['Decimal']['input']
  useAbsoluteValueMatching?: Scalars['Boolean']['input']
  usePercent?: Scalars['Boolean']['input']
}

export type InputRowSkeleton = {
  lineItems: Array<InputMaybe<InputLineItemSkeleton>>
}

export type InputSoaLineItem = {
  cargowiseModule?: InputMaybe<Scalars['String']['input']>
  carrierBookingNumber?: InputMaybe<Scalars['String']['input']>
  chargeCode?: InputMaybe<Scalars['String']['input']>
  chargeCost?: InputMaybe<Scalars['String']['input']>
  consolNumber?: InputMaybe<Scalars['String']['input']>
  containerNumber?: InputMaybe<Scalars['String']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  documentReceivedDate?: InputMaybe<Scalars['String']['input']>
  dueDate?: InputMaybe<Scalars['String']['input']>
  hblNumber?: InputMaybe<Scalars['String']['input']>
  invoiceDate?: InputMaybe<Scalars['String']['input']>
  invoiceForwarderReference?: InputMaybe<Scalars['String']['input']>
  invoiceNumber?: InputMaybe<Scalars['String']['input']>
  invoiceTmsId?: InputMaybe<Scalars['String']['input']>
  mblNumber?: InputMaybe<Scalars['String']['input']>
  orderNumber?: InputMaybe<Scalars['String']['input']>
  quantity?: InputMaybe<Scalars['String']['input']>
  referenceNumber?: InputMaybe<Scalars['String']['input']>
}

export type InputSoaRowMatchCriteria = {
  carrierBookingNo?: InputMaybe<Scalars['String']['input']>
  consolNo?: InputMaybe<Scalars['String']['input']>
  containerNo?: InputMaybe<Scalars['String']['input']>
  hblNo?: InputMaybe<Scalars['String']['input']>
  invoiceNo?: InputMaybe<Scalars['String']['input']>
  mblNo?: InputMaybe<Scalars['String']['input']>
  orderNo?: InputMaybe<Scalars['String']['input']>
  refNo?: InputMaybe<Scalars['String']['input']>
  rowIdx: Scalars['Int']['input']
}

export type InputSearchableRecord = {
  apiUrl?: InputMaybe<Scalars['String']['input']>
  displayKey: Scalars['String']['input']
  model?: InputMaybe<Scalars['String']['input']>
  searchKey: Scalars['String']['input']
}

export type InputSearchableRecordColumn = {
  filters?: InputMaybe<InputSearchableRecordFilters>
  searchableRecordId: Scalars['UUID']['input']
  values: Array<InputMaybe<Scalars['String']['input']>>
}

export type InputSearchableRecordFilters = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  companyId?: InputMaybe<Scalars['UUID']['input']>
  vendorName?: InputMaybe<Scalars['String']['input']>
}

export type InputSoaCwModules = {
  cwModule?: InputMaybe<Scalars['String']['input']>
  invoiceNum?: InputMaybe<Scalars['String']['input']>
}

export type InputSoaDates = {
  cwModule?: InputMaybe<Scalars['String']['input']>
  documentReceivedDate?: InputMaybe<Scalars['String']['input']>
  dueDate?: InputMaybe<Scalars['String']['input']>
  invoiceDate?: InputMaybe<Scalars['String']['input']>
  invoiceNum?: InputMaybe<Scalars['String']['input']>
}

export type InputTableColumn = {
  dimension?: InputMaybe<InputBoxDimension>
  id: Scalars['UUID']['input']
  key?: InputMaybe<Scalars['String']['input']>
}

export type InputTaskFilter = {
  column: TaskFilterColumn
  id: Scalars['UUID']['input']
  operation: TaskFilterOperation
  value: Array<Scalars['String']['input']>
}

export type InputTaxDetails = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  chargeCodes: Array<Scalars['String']['input']>
  companyId: Scalars['UUID']['input']
  taxCode: Scalars['String']['input']
  taxRate: Scalars['Float']['input']
  taxSystem?: InputMaybe<Scalars['String']['input']>
  taxType?: InputMaybe<Scalars['String']['input']>
}

export type InputUser = {
  branch?: InputMaybe<Scalars['String']['input']>
  branches?: Array<Scalars['String']['input']>
  canExportDataAsCsv?: Scalars['Boolean']['input']
  companyId?: InputMaybe<Scalars['UUID']['input']>
  defaultDashboardsWhitelist: Array<Scalars['String']['input']>
  department?: InputMaybe<Scalars['String']['input']>
  departments?: Array<Scalars['String']['input']>
  email: Scalars['String']['input']
  emailVerified?: Scalars['Boolean']['input']
  inviterCompanyName?: InputMaybe<Scalars['String']['input']>
  metabaseDashboardId?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  onlyViewAssignedShipments?: Scalars['Boolean']['input']
  onlyViewDashboardCompany?: Scalars['Boolean']['input']
  operationsRep?: InputMaybe<Scalars['String']['input']>
  operationsReps?: Array<Scalars['String']['input']>
  operatorCompanyIds: Array<Scalars['UUID']['input']>
  partnerCompanyIds?: Array<Scalars['UUID']['input']>
  roles: Array<UserRole>
  salesRep?: InputMaybe<Scalars['String']['input']>
  salesReps?: Array<Scalars['String']['input']>
}

export type InputVendorAndDesc = {
  description: Scalars['String']['input']
  vendorName: Scalars['String']['input']
}

export type InputVendorCsv = {
  rows: Array<InputMaybe<InputVendorCsvRow>>
}

export type InputVendorCsvRow = {
  vendorCode: Scalars['String']['input']
  vendorName: Scalars['String']['input']
}

export type InsightsCommercialInvoiceLineNode = {
  __typename?: 'InsightsCommercialInvoiceLineNode'
  cauldronDateCreated: Scalars['DateTime']['output']
  cauldronId?: Maybe<Scalars['String']['output']>
  commercialInvoice?: Maybe<InsightsCommercialInvoiceNode>
  commercialInvoiceSnowflakeId?: Maybe<Scalars['String']['output']>
  customsDeclaration?: Maybe<InsightsShipmentNode>
  customsDeclarationSnowflakeId?: Maybe<Scalars['String']['output']>
  customsValue?: Maybe<Scalars['Float']['output']>
  description?: Maybe<Scalars['String']['output']>
  duty?: Maybe<Scalars['Float']['output']>
  dutyRate?: Maybe<Scalars['Float']['output']>
  ftaPayableMpf?: Maybe<Scalars['Float']['output']>
  hmf?: Maybe<Scalars['Float']['output']>
  lineNumber?: Maybe<Scalars['String']['output']>
  linePrice?: Maybe<Scalars['Float']['output']>
  manufacturerAddress1?: Maybe<Scalars['String']['output']>
  manufacturerAddress2?: Maybe<Scalars['String']['output']>
  manufacturerCity?: Maybe<Scalars['String']['output']>
  manufacturerCode?: Maybe<Scalars['String']['output']>
  manufacturerCountry?: Maybe<Scalars['String']['output']>
  manufacturerKey?: Maybe<Scalars['String']['output']>
  manufacturerName?: Maybe<Scalars['String']['output']>
  manufacturerState?: Maybe<Scalars['String']['output']>
  manufacturerUnlocode?: Maybe<Scalars['String']['output']>
  matchingKey?: Maybe<Scalars['String']['output']>
  nonFtaDuty?: Maybe<Scalars['Float']['output']>
  nonFtaPayableMpf?: Maybe<Scalars['Float']['output']>
  orderNumber?: Maybe<Scalars['String']['output']>
  payableMpf?: Maybe<Scalars['Float']['output']>
  productCode?: Maybe<Scalars['String']['output']>
  snowflakeDateCreated: Scalars['DateTime']['output']
  snowflakeId: Scalars['String']['output']
  spi?: Maybe<Scalars['String']['output']>
  supDuty?: Maybe<Scalars['Float']['output']>
  supDutyRate?: Maybe<Scalars['Float']['output']>
  supTariff?: Maybe<Scalars['String']['output']>
  tariff?: Maybe<Scalars['String']['output']>
  unitPrice?: Maybe<Scalars['Float']['output']>
}

export type InsightsCommercialInvoiceLineNodeConnection = {
  __typename?: 'InsightsCommercialInvoiceLineNodeConnection'
  edges: Array<InsightsCommercialInvoiceLineNodeEdge>
}

export type InsightsCommercialInvoiceLineNodeEdge = {
  __typename?: 'InsightsCommercialInvoiceLineNodeEdge'
  node: InsightsCommercialInvoiceLineNode
}

export type InsightsCommercialInvoiceNode = {
  __typename?: 'InsightsCommercialInvoiceNode'
  apiPartnerId?: Maybe<Scalars['String']['output']>
  apiPartnerName?: Maybe<Scalars['String']['output']>
  apiPartnerTmsType?: Maybe<Scalars['String']['output']>
  cauldronDateCreated: Scalars['DateTime']['output']
  cauldronId?: Maybe<Scalars['String']['output']>
  customsDeclaration?: Maybe<InsightsShipmentNode>
  customsDeclarationSnowflakeId?: Maybe<Scalars['String']['output']>
  forwarderReference?: Maybe<Scalars['String']['output']>
  invoiceAmount?: Maybe<Scalars['Float']['output']>
  invoiceCurrency?: Maybe<Scalars['String']['output']>
  invoiceDate?: Maybe<Scalars['DateTime']['output']>
  invoiceLines: InsightsCommercialInvoiceLineNodeConnection
  invoiceNumber?: Maybe<Scalars['String']['output']>
  snowflakeDateCreated: Scalars['DateTime']['output']
  snowflakeId: Scalars['String']['output']
  tmsId?: Maybe<Scalars['String']['output']>
}

export type InsightsCommercialInvoiceNodeConnection = {
  __typename?: 'InsightsCommercialInvoiceNodeConnection'
  edges: Array<InsightsCommercialInvoiceNodeEdge>
}

export type InsightsCommercialInvoiceNodeEdge = {
  __typename?: 'InsightsCommercialInvoiceNodeEdge'
  node: InsightsCommercialInvoiceNode
}

export type InsightsContainerNode = {
  __typename?: 'InsightsContainerNode'
  actualDeliveryDate?: Maybe<Scalars['DateTime']['output']>
  actualPickupDate?: Maybe<Scalars['DateTime']['output']>
  apiPartnerId?: Maybe<Scalars['String']['output']>
  arrivalPickupByRail?: Maybe<Scalars['Boolean']['output']>
  arrivalPortTransportBookedDate?: Maybe<Scalars['DateTime']['output']>
  arrivalPortTransportRef?: Maybe<Scalars['String']['output']>
  arrivalSlotBookingRef?: Maybe<Scalars['String']['output']>
  arrivalSlotDate?: Maybe<Scalars['DateTime']['output']>
  cargowiseConfigId?: Maybe<Scalars['String']['output']>
  cfsAvailableDate?: Maybe<Scalars['DateTime']['output']>
  cfsStorageStartDate?: Maybe<Scalars['DateTime']['output']>
  companyContainerKey?: Maybe<Scalars['String']['output']>
  companyId: Scalars['String']['output']
  containerCount?: Maybe<Scalars['Int']['output']>
  containerCountAndType?: Maybe<Scalars['String']['output']>
  containerEta?: Maybe<Scalars['DateTime']['output']>
  containerEtd?: Maybe<Scalars['DateTime']['output']>
  containerGrossWeight?: Maybe<Scalars['Float']['output']>
  containerMode?: Maybe<Scalars['String']['output']>
  containerNumber?: Maybe<Scalars['String']['output']>
  containerNumberDisplay?: Maybe<Scalars['String']['output']>
  containerShipmentGoodsDescription?: Maybe<Scalars['String']['output']>
  containerShipmentGoodsVolume?: Maybe<Scalars['Float']['output']>
  containerShipmentGoodsWeight?: Maybe<Scalars['Float']['output']>
  containerType?: Maybe<Scalars['String']['output']>
  ctoAvailableDate?: Maybe<Scalars['DateTime']['output']>
  ctoStorageStartDate?: Maybe<Scalars['DateTime']['output']>
  declarationId?: Maybe<Scalars['String']['output']>
  deliveryMode?: Maybe<Scalars['String']['output']>
  departureDeliveryByRail?: Maybe<Scalars['Boolean']['output']>
  departurePortTransportBookedDate?: Maybe<Scalars['DateTime']['output']>
  departurePortTransportRef?: Maybe<Scalars['String']['output']>
  departureSlotBookingRef?: Maybe<Scalars['String']['output']>
  departureSlotDate?: Maybe<Scalars['DateTime']['output']>
  emptyReadyDate?: Maybe<Scalars['DateTime']['output']>
  emptyReleasedFromContainerYardDate?: Maybe<Scalars['DateTime']['output']>
  emptyRequiredByDate?: Maybe<Scalars['DateTime']['output']>
  emptyReturnReference?: Maybe<Scalars['String']['output']>
  emptyReturnRequiredByDate?: Maybe<Scalars['DateTime']['output']>
  emptyReturnedOnDate?: Maybe<Scalars['DateTime']['output']>
  estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>
  estimatedPickupDate?: Maybe<Scalars['DateTime']['output']>
  exportPortCustomsRef?: Maybe<Scalars['String']['output']>
  exportReleaseNumber?: Maybe<Scalars['String']['output']>
  fclLoadedDate?: Maybe<Scalars['DateTime']['output']>
  fclUnloadDate?: Maybe<Scalars['DateTime']['output']>
  heldForFclTransitStaging?: Maybe<Scalars['Boolean']['output']>
  importPortCustomsRef?: Maybe<Scalars['String']['output']>
  importReleaseNumber?: Maybe<Scalars['String']['output']>
  lastMilestoneTracked?: Maybe<Scalars['String']['output']>
  lastMilestoneTrackedDate?: Maybe<Scalars['DateTime']['output']>
  originalActualDeliveryDate?: Maybe<Scalars['DateTime']['output']>
  originalActualDeliveryDateTimezone?: Maybe<Scalars['String']['output']>
  originalActualPickupDate?: Maybe<Scalars['DateTime']['output']>
  originalActualPickupDateTimezone?: Maybe<Scalars['String']['output']>
  originalArrivalPortTransportBookedDate?: Maybe<Scalars['DateTime']['output']>
  originalArrivalPortTransportBookedDateTimezone?: Maybe<Scalars['String']['output']>
  originalArrivalSlotDate?: Maybe<Scalars['DateTime']['output']>
  originalArrivalSlotDateTimezone?: Maybe<Scalars['String']['output']>
  originalCfsAvailableDate?: Maybe<Scalars['DateTime']['output']>
  originalCfsAvailableDateTimezone?: Maybe<Scalars['String']['output']>
  originalCfsStorageStartDate?: Maybe<Scalars['DateTime']['output']>
  originalCfsStorageStartDateTimezone?: Maybe<Scalars['String']['output']>
  originalContainerGrossWeight?: Maybe<Scalars['Float']['output']>
  originalContainerGrossWeightUom?: Maybe<Scalars['String']['output']>
  originalCtoAvailableDate?: Maybe<Scalars['DateTime']['output']>
  originalCtoAvailableDateTimezone?: Maybe<Scalars['String']['output']>
  originalCtoStorageStartDate?: Maybe<Scalars['DateTime']['output']>
  originalCtoStorageStartDateTimezone?: Maybe<Scalars['String']['output']>
  originalDeparturePortTransportBookedDate?: Maybe<Scalars['DateTime']['output']>
  originalDeparturePortTransportBookedDateTimezone?: Maybe<Scalars['String']['output']>
  originalDepartureSlotDate?: Maybe<Scalars['DateTime']['output']>
  originalDepartureSlotDateTimezone?: Maybe<Scalars['String']['output']>
  originalEmptyReadyDate?: Maybe<Scalars['DateTime']['output']>
  originalEmptyReadyDateTimezone?: Maybe<Scalars['String']['output']>
  originalEmptyReleasedFromContainerYardDate?: Maybe<Scalars['DateTime']['output']>
  originalEmptyReleasedFromContainerYardDateTimezone?: Maybe<Scalars['String']['output']>
  originalEmptyRequiredByDate?: Maybe<Scalars['DateTime']['output']>
  originalEmptyRequiredByDateTimezone?: Maybe<Scalars['String']['output']>
  originalEmptyReturnRequiredByDate?: Maybe<Scalars['DateTime']['output']>
  originalEmptyReturnRequiredByDateTimezone?: Maybe<Scalars['String']['output']>
  originalEmptyReturnedOnDate?: Maybe<Scalars['DateTime']['output']>
  originalEmptyReturnedOnDateTimezone?: Maybe<Scalars['String']['output']>
  originalEstimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>
  originalEstimatedDeliveryDateTimezone?: Maybe<Scalars['String']['output']>
  originalEstimatedPickupDate?: Maybe<Scalars['DateTime']['output']>
  originalEstimatedPickupDateTimezone?: Maybe<Scalars['String']['output']>
  originalFclLoadedDate?: Maybe<Scalars['DateTime']['output']>
  originalFclLoadedDateTimezone?: Maybe<Scalars['String']['output']>
  originalFclUnloadDate?: Maybe<Scalars['DateTime']['output']>
  originalFclUnloadDateTimezone?: Maybe<Scalars['String']['output']>
  originalWharfGateInDate?: Maybe<Scalars['DateTime']['output']>
  originalWharfGateInDateTimezone?: Maybe<Scalars['String']['output']>
  originalWharfGateOutDate?: Maybe<Scalars['DateTime']['output']>
  originalWharfGateOutDateTimezone?: Maybe<Scalars['String']['output']>
  sealNumber?: Maybe<Scalars['String']['output']>
  shipment: InsightsShipmentNode
  shipmentReferenceNumber?: Maybe<Scalars['String']['output']>
  shipmentSnowflakeId: Scalars['String']['output']
  snowflakeDateCreated: Scalars['DateTime']['output']
  snowflakeId: Scalars['String']['output']
  targetVolume?: Maybe<Scalars['String']['output']>
  targetWeight?: Maybe<Scalars['String']['output']>
  teus?: Maybe<Scalars['Float']['output']>
  wharfGateInDate?: Maybe<Scalars['DateTime']['output']>
  wharfGateOutDate?: Maybe<Scalars['DateTime']['output']>
}

export type InsightsContainerNodeConnection = {
  __typename?: 'InsightsContainerNodeConnection'
  edges: Array<InsightsContainerNodeEdge>
}

export type InsightsContainerNodeEdge = {
  __typename?: 'InsightsContainerNodeEdge'
  node: InsightsContainerNode
}

export enum InsightsFieldCategory {
  AccountMargin = 'ACCOUNT_MARGIN',
  Container = 'CONTAINER',
  PayableColumn = 'PAYABLE_COLUMN',
  ReceivableColumn = 'RECEIVABLE_COLUMN',
  SharedShipmentField = 'SHARED_SHIPMENT_FIELD',
  Shipment = 'SHIPMENT',
  ShipmentMargin = 'SHIPMENT_MARGIN',
}

export type InsightsInvoiceLineNode = {
  __typename?: 'InsightsInvoiceLineNode'
  apAmount?: Maybe<Scalars['Float']['output']>
  apAmountWithTax?: Maybe<Scalars['Float']['output']>
  apDateFullyPaid?: Maybe<Scalars['DateTime']['output']>
  apDueDate?: Maybe<Scalars['DateTime']['output']>
  apDueWithin?: Maybe<Scalars['String']['output']>
  apExchangeRate?: Maybe<Scalars['Float']['output']>
  apInvoiceDate?: Maybe<Scalars['DateTime']['output']>
  apInvoiceLocalOutstandingAmount?: Maybe<Scalars['Float']['output']>
  apInvoiceNumber?: Maybe<Scalars['String']['output']>
  apInvoiceOutstandingAmount?: Maybe<Scalars['Float']['output']>
  apInvoiceTotalAmount?: Maybe<Scalars['Float']['output']>
  apIsAlreadyDue?: Maybe<Scalars['Boolean']['output']>
  apIsCancelled?: Maybe<Scalars['Boolean']['output']>
  apIsPosted?: Maybe<Scalars['Boolean']['output']>
  apLedger?: Maybe<Scalars['String']['output']>
  apLocalAmount?: Maybe<Scalars['Float']['output']>
  apLocalCurrency?: Maybe<Scalars['String']['output']>
  apLocalTaxAmount?: Maybe<Scalars['Float']['output']>
  apOriginalAmount?: Maybe<Scalars['Float']['output']>
  apOriginalCurrency?: Maybe<Scalars['String']['output']>
  apPaidOnTime?: Maybe<Scalars['String']['output']>
  apPaymentStatus?: Maybe<Scalars['String']['output']>
  apPostDate?: Maybe<Scalars['DateTime']['output']>
  apRecognitionDate?: Maybe<Scalars['DateTime']['output']>
  apRecognitionType?: Maybe<Scalars['String']['output']>
  apTaxAmount?: Maybe<Scalars['Float']['output']>
  apTaxCode?: Maybe<Scalars['String']['output']>
  apTaxRate?: Maybe<Scalars['Float']['output']>
  apTimeToBill?: Maybe<Scalars['Int']['output']>
  apTimeToPay?: Maybe<Scalars['Int']['output']>
  apTransactionType?: Maybe<Scalars['String']['output']>
  apiPartnerId?: Maybe<Scalars['String']['output']>
  apiPartnerName?: Maybe<Scalars['String']['output']>
  apiPartnerTmsType?: Maybe<Scalars['String']['output']>
  arAmount?: Maybe<Scalars['Float']['output']>
  arAmountWithTax?: Maybe<Scalars['Float']['output']>
  arDateFullyPaid?: Maybe<Scalars['DateTime']['output']>
  arDueDate?: Maybe<Scalars['DateTime']['output']>
  arDueWithin?: Maybe<Scalars['String']['output']>
  arExchangeRate?: Maybe<Scalars['Float']['output']>
  arInvoiceDate?: Maybe<Scalars['DateTime']['output']>
  arInvoiceLocalOutstandingAmount?: Maybe<Scalars['Float']['output']>
  arInvoiceNumber?: Maybe<Scalars['String']['output']>
  arInvoiceOutstandingAmount?: Maybe<Scalars['Float']['output']>
  arInvoiceTotalAmount?: Maybe<Scalars['Float']['output']>
  arIsAlreadyDue?: Maybe<Scalars['Boolean']['output']>
  arIsCancelled?: Maybe<Scalars['Boolean']['output']>
  arIsPosted?: Maybe<Scalars['Boolean']['output']>
  arLedger?: Maybe<Scalars['String']['output']>
  arLocalAmount?: Maybe<Scalars['Float']['output']>
  arLocalCurrency?: Maybe<Scalars['String']['output']>
  arLocalTaxAmount?: Maybe<Scalars['Float']['output']>
  arOriginalAmount?: Maybe<Scalars['Float']['output']>
  arOriginalCurrency?: Maybe<Scalars['String']['output']>
  arPaidOnTime?: Maybe<Scalars['String']['output']>
  arPaymentStatus?: Maybe<Scalars['String']['output']>
  arPostDate?: Maybe<Scalars['DateTime']['output']>
  arRecognitionDate?: Maybe<Scalars['DateTime']['output']>
  arRecognitionType?: Maybe<Scalars['String']['output']>
  arTaxAmount?: Maybe<Scalars['Float']['output']>
  arTaxCode?: Maybe<Scalars['String']['output']>
  arTaxRate?: Maybe<Scalars['Float']['output']>
  arTimeToBill?: Maybe<Scalars['Int']['output']>
  arTimeToCollect?: Maybe<Scalars['Int']['output']>
  arTransactionType?: Maybe<Scalars['String']['output']>
  branch?: Maybe<Scalars['String']['output']>
  cargowiseConfigId?: Maybe<Scalars['String']['output']>
  cashCycleBillingTimeToBill?: Maybe<Scalars['Float']['output']>
  cashCycleBillingTimeToPay?: Maybe<Scalars['Float']['output']>
  cauldronDateCreated: Scalars['DateTime']['output']
  cauldronTableSource?: Maybe<Scalars['String']['output']>
  chargeCode?: Maybe<Scalars['String']['output']>
  companyId: Scalars['String']['output']
  companyShipmentKey?: Maybe<Scalars['String']['output']>
  creditor?: Maybe<Scalars['String']['output']>
  creditorCode?: Maybe<Scalars['String']['output']>
  creditorKey?: Maybe<Scalars['String']['output']>
  creditorName?: Maybe<Scalars['String']['output']>
  debtor?: Maybe<Scalars['String']['output']>
  debtorCode?: Maybe<Scalars['String']['output']>
  debtorKey?: Maybe<Scalars['String']['output']>
  debtorName?: Maybe<Scalars['String']['output']>
  department?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  forwarderReference?: Maybe<Scalars['String']['output']>
  integrationId?: Maybe<Scalars['String']['output']>
  invoiceId: Scalars['String']['output']
  invoiceKey?: Maybe<Scalars['String']['output']>
  invoiceLineId: Scalars['String']['output']
  invoiceTmsId?: Maybe<Scalars['String']['output']>
  isApportioned?: Maybe<Scalars['Boolean']['output']>
  isNewSignage?: Maybe<Scalars['Boolean']['output']>
  isPayable: Scalars['Boolean']['output']
  shipment?: Maybe<InsightsShipmentNode>
  shipmentSnowflakeId?: Maybe<Scalars['String']['output']>
  snowflakeDateCreated: Scalars['DateTime']['output']
  snowflakeId: Scalars['String']['output']
  tmsId?: Maybe<Scalars['String']['output']>
}

export type InsightsInvoiceLineNodeConnection = {
  __typename?: 'InsightsInvoiceLineNodeConnection'
  edges: Array<InsightsInvoiceLineNodeEdge>
}

export type InsightsInvoiceLineNodeEdge = {
  __typename?: 'InsightsInvoiceLineNodeEdge'
  node: InsightsInvoiceLineNode
}

export type InsightsMilestoneNode = {
  __typename?: 'InsightsMilestoneNode'
  apiPartnerId?: Maybe<Scalars['String']['output']>
  cargowiseConfigId?: Maybe<Scalars['String']['output']>
  companyId: Scalars['String']['output']
  milestoneActualDate?: Maybe<Scalars['DateTime']['output']>
  milestoneActualDateUtc?: Maybe<Scalars['DateTime']['output']>
  milestoneConditionReference?: Maybe<Scalars['String']['output']>
  milestoneConditionType?: Maybe<Scalars['String']['output']>
  milestoneDescription?: Maybe<Scalars['String']['output']>
  milestoneEstimatedDate?: Maybe<Scalars['DateTime']['output']>
  milestoneEstimatedDateUtc?: Maybe<Scalars['DateTime']['output']>
  milestoneEventCode?: Maybe<Scalars['String']['output']>
  milestoneReferenceEntity?: Maybe<Scalars['String']['output']>
  milestoneReferenceEntityTmsId?: Maybe<Scalars['String']['output']>
  milestoneReferenceNumber?: Maybe<Scalars['String']['output']>
  milestoneSequenceNumber: Scalars['Int']['output']
  shipment: InsightsShipmentNode
  shipmentSnowflakeId: Scalars['String']['output']
  snowflakeDateCreated: Scalars['DateTime']['output']
  snowflakeId: Scalars['String']['output']
}

export type InsightsMilestoneNodeConnection = {
  __typename?: 'InsightsMilestoneNodeConnection'
  edges: Array<InsightsMilestoneNodeEdge>
}

export type InsightsMilestoneNodeEdge = {
  __typename?: 'InsightsMilestoneNodeEdge'
  node: InsightsMilestoneNode
}

export type InsightsPackingLineNode = {
  __typename?: 'InsightsPackingLineNode'
  apiPartnerId?: Maybe<Scalars['String']['output']>
  companyContainerKey?: Maybe<Scalars['String']['output']>
  companyPackingLineKey?: Maybe<Scalars['String']['output']>
  companyShipmentKey?: Maybe<Scalars['String']['output']>
  goodsDescription?: Maybe<Scalars['String']['output']>
  originalVolume?: Maybe<Scalars['Float']['output']>
  originalVolumeUom?: Maybe<Scalars['String']['output']>
  originalWeight?: Maybe<Scalars['Float']['output']>
  originalWeightUom?: Maybe<Scalars['String']['output']>
  packOrder?: Maybe<Scalars['Int']['output']>
  packType?: Maybe<Scalars['String']['output']>
  quantity?: Maybe<Scalars['Int']['output']>
  shipment: InsightsShipmentNode
  snowflakeDateCreated: Scalars['DateTime']['output']
  snowflakeId: Scalars['String']['output']
  snowflakeShipmentId: Scalars['String']['output']
  volume?: Maybe<Scalars['Float']['output']>
  weight?: Maybe<Scalars['Float']['output']>
}

export type InsightsPackingLineNodeConnection = {
  __typename?: 'InsightsPackingLineNodeConnection'
  edges: Array<InsightsPackingLineNodeEdge>
}

export type InsightsPackingLineNodeEdge = {
  __typename?: 'InsightsPackingLineNodeEdge'
  node: InsightsPackingLineNode
}

export enum InsightsSearchableRecordModel {
  Branch = 'BRANCH',
  ChargeCode = 'CHARGE_CODE',
  Department = 'DEPARTMENT',
  Direction = 'DIRECTION',
  LocationCode = 'LOCATION_CODE',
  PacksUom = 'PACKS_UOM',
  TransportMode = 'TRANSPORT_MODE',
  VolumeUom = 'VOLUME_UOM',
  WeightUom = 'WEIGHT_UOM',
}

export type InsightsShipmentDetails = {
  __typename?: 'InsightsShipmentDetails'
  payables: Array<InsightsInvoiceLineNode>
  receivables: Array<InsightsInvoiceLineNode>
  shipment?: Maybe<InsightsShipmentNode>
  totalPayables?: Maybe<Scalars['String']['output']>
  totalPayablesPosted?: Maybe<Scalars['String']['output']>
  totalPayablesPostedWithTax?: Maybe<Scalars['String']['output']>
  totalPayablesWithTax?: Maybe<Scalars['String']['output']>
  totalReceivables?: Maybe<Scalars['String']['output']>
  totalReceivablesPosted?: Maybe<Scalars['String']['output']>
  totalReceivablesPostedWithTax?: Maybe<Scalars['String']['output']>
  totalReceivablesWithTax?: Maybe<Scalars['String']['output']>
  totalTaxPayables?: Maybe<Scalars['String']['output']>
  totalTaxPayablesPosted?: Maybe<Scalars['String']['output']>
  totalTaxReceivables?: Maybe<Scalars['String']['output']>
  totalTaxReceivablesPosted?: Maybe<Scalars['String']['output']>
}

export type InsightsShipmentLegNode = {
  __typename?: 'InsightsShipmentLegNode'
  apiPartnerId?: Maybe<Scalars['String']['output']>
  arrivalActual?: Maybe<Scalars['DateTime']['output']>
  arrivalEstimated?: Maybe<Scalars['DateTime']['output']>
  arrivalPort?: Maybe<Scalars['String']['output']>
  arrivalPortDescription?: Maybe<Scalars['String']['output']>
  arrivalPortDisplay?: Maybe<Scalars['String']['output']>
  cargowiseConfigId?: Maybe<Scalars['String']['output']>
  companyId: Scalars['String']['output']
  departureActual?: Maybe<Scalars['DateTime']['output']>
  departureEstimated?: Maybe<Scalars['DateTime']['output']>
  ladingPort?: Maybe<Scalars['String']['output']>
  ladingPortDescription?: Maybe<Scalars['String']['output']>
  ladingPortDisplay?: Maybe<Scalars['String']['output']>
  legCarrierAddress1?: Maybe<Scalars['String']['output']>
  legCarrierAddress2?: Maybe<Scalars['String']['output']>
  legCarrierCity?: Maybe<Scalars['String']['output']>
  legCarrierCode?: Maybe<Scalars['String']['output']>
  legCarrierCountry?: Maybe<Scalars['String']['output']>
  legCarrierKey?: Maybe<Scalars['String']['output']>
  legCarrierName?: Maybe<Scalars['String']['output']>
  legCarrierState?: Maybe<Scalars['String']['output']>
  legCarrierUnlocode?: Maybe<Scalars['String']['output']>
  legSequenceNumber: Scalars['Int']['output']
  legType?: Maybe<Scalars['String']['output']>
  originalArrivalActual?: Maybe<Scalars['DateTime']['output']>
  originalArrivalActualTimezone?: Maybe<Scalars['String']['output']>
  originalArrivalEstimated?: Maybe<Scalars['DateTime']['output']>
  originalArrivalEstimatedTimezone?: Maybe<Scalars['String']['output']>
  originalDepartureActual?: Maybe<Scalars['DateTime']['output']>
  originalDepartureActualTimezone?: Maybe<Scalars['String']['output']>
  originalDepartureEstimated?: Maybe<Scalars['DateTime']['output']>
  originalDepartureEstimatedTimezone?: Maybe<Scalars['String']['output']>
  shipment: InsightsShipmentNode
  shipmentSnowflakeId: Scalars['String']['output']
  snowflakeDateCreated: Scalars['DateTime']['output']
  snowflakeId: Scalars['String']['output']
  transportMode?: Maybe<Scalars['String']['output']>
  vesselName?: Maybe<Scalars['String']['output']>
  voyageNumber?: Maybe<Scalars['String']['output']>
}

export type InsightsShipmentLegNodeConnection = {
  __typename?: 'InsightsShipmentLegNodeConnection'
  edges: Array<InsightsShipmentLegNodeEdge>
}

export type InsightsShipmentLegNodeEdge = {
  __typename?: 'InsightsShipmentLegNodeEdge'
  node: InsightsShipmentLegNode
}

export type InsightsShipmentNode = {
  __typename?: 'InsightsShipmentNode'
  agentReference?: Maybe<Scalars['String']['output']>
  ancestorTmsIds?: Maybe<Array<Scalars['String']['output']>>
  apiPartnerId?: Maybe<Scalars['String']['output']>
  apiPartnerName?: Maybe<Scalars['String']['output']>
  apiPartnerTmsType?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressAddress1?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressAddress2?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressCity?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressCode?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressCountry?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressKey?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressName?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressState?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressUnlocode?: Maybe<Scalars['String']['output']>
  billingStatus?: Maybe<Scalars['String']['output']>
  branch?: Maybe<Scalars['String']['output']>
  branchDescription?: Maybe<Scalars['String']['output']>
  brokerStaff?: Maybe<Scalars['String']['output']>
  brokerStaffCode?: Maybe<Scalars['String']['output']>
  brokerStaffName?: Maybe<Scalars['String']['output']>
  cargowiseConfigId?: Maybe<Scalars['String']['output']>
  carrierBookingNumber?: Maybe<Scalars['String']['output']>
  cashCycleTransportLegMainLeg?: Maybe<Scalars['Float']['output']>
  cashCycleTransportLegOnForwarding?: Maybe<Scalars['Float']['output']>
  cashCycleTransportLegPickUp?: Maybe<Scalars['Float']['output']>
  cashCycleTransportLegPreCarriage?: Maybe<Scalars['Float']['output']>
  cauldronDateCreated: Scalars['DateTime']['output']
  cauldronId?: Maybe<Scalars['String']['output']>
  chargeableVolume?: Maybe<Scalars['Float']['output']>
  chargeableWeight?: Maybe<Scalars['Float']['output']>
  cleanedForwarderReference?: Maybe<Scalars['String']['output']>
  commercialInvoiceLines: InsightsCommercialInvoiceLineNodeConnection
  commercialInvoices: InsightsCommercialInvoiceNodeConnection
  companyId: Scalars['String']['output']
  companyName: Scalars['String']['output']
  companyShipmentKey: Scalars['String']['output']
  consigneeAddress1?: Maybe<Scalars['String']['output']>
  consigneeAddress2?: Maybe<Scalars['String']['output']>
  consigneeCity?: Maybe<Scalars['String']['output']>
  consigneeCode?: Maybe<Scalars['String']['output']>
  consigneeCountry?: Maybe<Scalars['String']['output']>
  consigneeKey?: Maybe<Scalars['String']['output']>
  consigneeName?: Maybe<Scalars['String']['output']>
  consigneeState?: Maybe<Scalars['String']['output']>
  consigneeUnlocode?: Maybe<Scalars['String']['output']>
  consolCarrierAddress1?: Maybe<Scalars['String']['output']>
  consolCarrierAddress2?: Maybe<Scalars['String']['output']>
  consolCarrierCity?: Maybe<Scalars['String']['output']>
  consolCarrierCode?: Maybe<Scalars['String']['output']>
  consolCarrierCountry?: Maybe<Scalars['String']['output']>
  consolCarrierKey?: Maybe<Scalars['String']['output']>
  consolCarrierName?: Maybe<Scalars['String']['output']>
  consolCarrierState?: Maybe<Scalars['String']['output']>
  consolCarrierUnlocode?: Maybe<Scalars['String']['output']>
  consolContainerMode?: Maybe<Scalars['String']['output']>
  consolCreditorAddress1?: Maybe<Scalars['String']['output']>
  consolCreditorAddress2?: Maybe<Scalars['String']['output']>
  consolCreditorCity?: Maybe<Scalars['String']['output']>
  consolCreditorCode?: Maybe<Scalars['String']['output']>
  consolCreditorCountry?: Maybe<Scalars['String']['output']>
  consolCreditorKey?: Maybe<Scalars['String']['output']>
  consolCreditorName?: Maybe<Scalars['String']['output']>
  consolCreditorState?: Maybe<Scalars['String']['output']>
  consolCreditorUnlocode?: Maybe<Scalars['String']['output']>
  consolFirstLoad?: Maybe<Scalars['String']['output']>
  consolLastDischarge?: Maybe<Scalars['String']['output']>
  consolNumber?: Maybe<Scalars['String']['output']>
  consolServiceLevel?: Maybe<Scalars['String']['output']>
  consolTransportMode?: Maybe<Scalars['String']['output']>
  consolType?: Maybe<Scalars['String']['output']>
  consolidationId?: Maybe<Scalars['String']['output']>
  container?: Maybe<Scalars['String']['output']>
  containerCountAndType?: Maybe<Scalars['String']['output']>
  containerMode?: Maybe<Scalars['String']['output']>
  containers: InsightsContainerNodeConnection
  controllingAgentAddress1?: Maybe<Scalars['String']['output']>
  controllingAgentAddress2?: Maybe<Scalars['String']['output']>
  controllingAgentCity?: Maybe<Scalars['String']['output']>
  controllingAgentCode?: Maybe<Scalars['String']['output']>
  controllingAgentCountry?: Maybe<Scalars['String']['output']>
  controllingAgentKey?: Maybe<Scalars['String']['output']>
  controllingAgentName?: Maybe<Scalars['String']['output']>
  controllingAgentState?: Maybe<Scalars['String']['output']>
  controllingAgentUnlocode?: Maybe<Scalars['String']['output']>
  controllingCustomerAddress1?: Maybe<Scalars['String']['output']>
  controllingCustomerAddress2?: Maybe<Scalars['String']['output']>
  controllingCustomerCity?: Maybe<Scalars['String']['output']>
  controllingCustomerCode?: Maybe<Scalars['String']['output']>
  controllingCustomerCountry?: Maybe<Scalars['String']['output']>
  controllingCustomerKey?: Maybe<Scalars['String']['output']>
  controllingCustomerName?: Maybe<Scalars['String']['output']>
  controllingCustomerState?: Maybe<Scalars['String']['output']>
  controllingCustomerUnlocode?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dateShipmentClosed?: Maybe<Scalars['DateTime']['output']>
  dateShipmentCreated?: Maybe<Scalars['DateTime']['output']>
  daysPastTargetBillingDate?: Maybe<Scalars['String']['output']>
  declarationApplicationCode?: Maybe<Scalars['String']['output']>
  declarationCarrierAddress1?: Maybe<Scalars['String']['output']>
  declarationCarrierAddress2?: Maybe<Scalars['String']['output']>
  declarationCarrierCity?: Maybe<Scalars['String']['output']>
  declarationCarrierCode?: Maybe<Scalars['String']['output']>
  declarationCarrierCountry?: Maybe<Scalars['String']['output']>
  declarationCarrierKey?: Maybe<Scalars['String']['output']>
  declarationCarrierName?: Maybe<Scalars['String']['output']>
  declarationCarrierScac?: Maybe<Scalars['String']['output']>
  declarationCarrierScacName?: Maybe<Scalars['String']['output']>
  declarationCarrierState?: Maybe<Scalars['String']['output']>
  declarationCarrierUnlocode?: Maybe<Scalars['String']['output']>
  declarationContainerMode?: Maybe<Scalars['String']['output']>
  declarationDestinationState?: Maybe<Scalars['String']['output']>
  declarationDischargeDate?: Maybe<Scalars['DateTime']['output']>
  declarationDischargePort?: Maybe<Scalars['String']['output']>
  declarationEntryDate?: Maybe<Scalars['DateTime']['output']>
  declarationEntryFiler?: Maybe<Scalars['String']['output']>
  declarationEntryNum?: Maybe<Scalars['String']['output']>
  declarationEntryType?: Maybe<Scalars['String']['output']>
  declarationEstimatedEntryDate?: Maybe<Scalars['DateTime']['output']>
  declarationExportCountry?: Maybe<Scalars['String']['output']>
  declarationExportDate?: Maybe<Scalars['DateTime']['output']>
  declarationExportPort?: Maybe<Scalars['String']['output']>
  declarationFirmsCode?: Maybe<Scalars['String']['output']>
  declarationHmf?: Maybe<Scalars['Boolean']['output']>
  declarationId?: Maybe<Scalars['String']['output']>
  declarationIssuer?: Maybe<Scalars['String']['output']>
  declarationIssuerName?: Maybe<Scalars['String']['output']>
  declarationIssuerScac?: Maybe<Scalars['String']['output']>
  declarationIssuerScacName?: Maybe<Scalars['String']['output']>
  declarationItDate?: Maybe<Scalars['DateTime']['output']>
  declarationItNumber?: Maybe<Scalars['String']['output']>
  declarationLoadingDate?: Maybe<Scalars['DateTime']['output']>
  declarationLoadingPort?: Maybe<Scalars['String']['output']>
  declarationManufacturerAddress1?: Maybe<Scalars['String']['output']>
  declarationManufacturerAddress2?: Maybe<Scalars['String']['output']>
  declarationManufacturerCity?: Maybe<Scalars['String']['output']>
  declarationManufacturerCode?: Maybe<Scalars['String']['output']>
  declarationManufacturerCountry?: Maybe<Scalars['String']['output']>
  declarationManufacturerKey?: Maybe<Scalars['String']['output']>
  declarationManufacturerName?: Maybe<Scalars['String']['output']>
  declarationManufacturerState?: Maybe<Scalars['String']['output']>
  declarationManufacturerUnlocode?: Maybe<Scalars['String']['output']>
  declarationMessageType?: Maybe<Scalars['String']['output']>
  declarationOriginCountry?: Maybe<Scalars['String']['output']>
  declarationOwnerRef?: Maybe<Scalars['String']['output']>
  declarationShipToPartyAddress1?: Maybe<Scalars['String']['output']>
  declarationShipToPartyAddress2?: Maybe<Scalars['String']['output']>
  declarationShipToPartyCity?: Maybe<Scalars['String']['output']>
  declarationShipToPartyCode?: Maybe<Scalars['String']['output']>
  declarationShipToPartyCountry?: Maybe<Scalars['String']['output']>
  declarationShipToPartyKey?: Maybe<Scalars['String']['output']>
  declarationShipToPartyName?: Maybe<Scalars['String']['output']>
  declarationShipToPartyState?: Maybe<Scalars['String']['output']>
  declarationShipToPartyUnlocode?: Maybe<Scalars['String']['output']>
  declarationSoldToPartyAddress1?: Maybe<Scalars['String']['output']>
  declarationSoldToPartyAddress2?: Maybe<Scalars['String']['output']>
  declarationSoldToPartyCity?: Maybe<Scalars['String']['output']>
  declarationSoldToPartyCode?: Maybe<Scalars['String']['output']>
  declarationSoldToPartyCountry?: Maybe<Scalars['String']['output']>
  declarationSoldToPartyKey?: Maybe<Scalars['String']['output']>
  declarationSoldToPartyName?: Maybe<Scalars['String']['output']>
  declarationSoldToPartyState?: Maybe<Scalars['String']['output']>
  declarationSoldToPartyUnlocode?: Maybe<Scalars['String']['output']>
  declarationUsDischargePort?: Maybe<Scalars['String']['output']>
  declarationUsEntryPort?: Maybe<Scalars['String']['output']>
  declarationUsExportPort?: Maybe<Scalars['String']['output']>
  declarationUsLoadingPort?: Maybe<Scalars['String']['output']>
  deliveryActualDate?: Maybe<Scalars['DateTime']['output']>
  deliveryAgentAddress1?: Maybe<Scalars['String']['output']>
  deliveryAgentAddress2?: Maybe<Scalars['String']['output']>
  deliveryAgentCity?: Maybe<Scalars['String']['output']>
  deliveryAgentCode?: Maybe<Scalars['String']['output']>
  deliveryAgentCountry?: Maybe<Scalars['String']['output']>
  deliveryAgentKey?: Maybe<Scalars['String']['output']>
  deliveryAgentName?: Maybe<Scalars['String']['output']>
  deliveryAgentState?: Maybe<Scalars['String']['output']>
  deliveryAgentUnlocode?: Maybe<Scalars['String']['output']>
  deliveryBookingRequestedDate?: Maybe<Scalars['DateTime']['output']>
  deliveryByDate?: Maybe<Scalars['DateTime']['output']>
  deliveryEstimatedDate?: Maybe<Scalars['DateTime']['output']>
  deliveryFromDate?: Maybe<Scalars['DateTime']['output']>
  deliveryToAddress1?: Maybe<Scalars['String']['output']>
  deliveryToAddress2?: Maybe<Scalars['String']['output']>
  deliveryToCity?: Maybe<Scalars['String']['output']>
  deliveryToCode?: Maybe<Scalars['String']['output']>
  deliveryToCountry?: Maybe<Scalars['String']['output']>
  deliveryToKey?: Maybe<Scalars['String']['output']>
  deliveryToName?: Maybe<Scalars['String']['output']>
  deliveryToState?: Maybe<Scalars['String']['output']>
  deliveryToUnlocode?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyAddress1?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyAddress2?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyCity?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyCode?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyCountry?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyKey?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyName?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyState?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyUnlocode?: Maybe<Scalars['String']['output']>
  department?: Maybe<Scalars['String']['output']>
  departmentDescription?: Maybe<Scalars['String']['output']>
  departureCfsAddressAddress1?: Maybe<Scalars['String']['output']>
  departureCfsAddressAddress2?: Maybe<Scalars['String']['output']>
  departureCfsAddressCity?: Maybe<Scalars['String']['output']>
  departureCfsAddressCode?: Maybe<Scalars['String']['output']>
  departureCfsAddressCountry?: Maybe<Scalars['String']['output']>
  departureCfsAddressKey?: Maybe<Scalars['String']['output']>
  departureCfsAddressName?: Maybe<Scalars['String']['output']>
  departureCfsAddressState?: Maybe<Scalars['String']['output']>
  departureCfsAddressUnlocode?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  direction?: Maybe<Scalars['String']['output']>
  dischargePort?: Maybe<Scalars['String']['output']>
  dischargePortAta?: Maybe<Scalars['DateTime']['output']>
  dischargePortAtd?: Maybe<Scalars['DateTime']['output']>
  dischargePortDescription?: Maybe<Scalars['String']['output']>
  dischargePortDisplay?: Maybe<Scalars['String']['output']>
  dischargePortEta?: Maybe<Scalars['DateTime']['output']>
  dischargePortEtd?: Maybe<Scalars['DateTime']['output']>
  exporterStatementCode?: Maybe<Scalars['String']['output']>
  exporterStatementDescription?: Maybe<Scalars['String']['output']>
  fileBranch?: Maybe<Scalars['String']['output']>
  fileBranchDescription?: Maybe<Scalars['String']['output']>
  finalDestination?: Maybe<Scalars['String']['output']>
  finalDestinationAta?: Maybe<Scalars['DateTime']['output']>
  finalDestinationDescription?: Maybe<Scalars['String']['output']>
  finalDestinationEta?: Maybe<Scalars['DateTime']['output']>
  firstApPosted?: Maybe<Scalars['DateTime']['output']>
  firstArPosted?: Maybe<Scalars['DateTime']['output']>
  firstLegAtd?: Maybe<Scalars['DateTime']['output']>
  firstLegEtd?: Maybe<Scalars['DateTime']['output']>
  firstLegOrigin?: Maybe<Scalars['String']['output']>
  firstLegOriginDescription?: Maybe<Scalars['String']['output']>
  firstLegOriginDisplay?: Maybe<Scalars['String']['output']>
  firstOrigin?: Maybe<Scalars['String']['output']>
  firstOriginAtd?: Maybe<Scalars['DateTime']['output']>
  firstOriginDescription?: Maybe<Scalars['String']['output']>
  firstOriginEtd?: Maybe<Scalars['DateTime']['output']>
  forwarderReference: Scalars['String']['output']
  goodsValue?: Maybe<Scalars['Float']['output']>
  goodsValueCurrency?: Maybe<Scalars['String']['output']>
  hasExceptions: Scalars['Boolean']['output']
  houseBill?: Maybe<Scalars['String']['output']>
  importBrokerAddress1?: Maybe<Scalars['String']['output']>
  importBrokerAddress2?: Maybe<Scalars['String']['output']>
  importBrokerCity?: Maybe<Scalars['String']['output']>
  importBrokerCode?: Maybe<Scalars['String']['output']>
  importBrokerCountry?: Maybe<Scalars['String']['output']>
  importBrokerKey?: Maybe<Scalars['String']['output']>
  importBrokerName?: Maybe<Scalars['String']['output']>
  importBrokerState?: Maybe<Scalars['String']['output']>
  importBrokerUnlocode?: Maybe<Scalars['String']['output']>
  importerAddress1?: Maybe<Scalars['String']['output']>
  importerAddress2?: Maybe<Scalars['String']['output']>
  importerCity?: Maybe<Scalars['String']['output']>
  importerCode?: Maybe<Scalars['String']['output']>
  importerCountry?: Maybe<Scalars['String']['output']>
  importerKey?: Maybe<Scalars['String']['output']>
  importerName?: Maybe<Scalars['String']['output']>
  importerOfRecordAddress1?: Maybe<Scalars['String']['output']>
  importerOfRecordAddress2?: Maybe<Scalars['String']['output']>
  importerOfRecordCity?: Maybe<Scalars['String']['output']>
  importerOfRecordCode?: Maybe<Scalars['String']['output']>
  importerOfRecordCountry?: Maybe<Scalars['String']['output']>
  importerOfRecordKey?: Maybe<Scalars['String']['output']>
  importerOfRecordName?: Maybe<Scalars['String']['output']>
  importerOfRecordState?: Maybe<Scalars['String']['output']>
  importerOfRecordUnlocode?: Maybe<Scalars['String']['output']>
  importerState?: Maybe<Scalars['String']['output']>
  importerUnlocode?: Maybe<Scalars['String']['output']>
  incoterm?: Maybe<Scalars['String']['output']>
  integrationId?: Maybe<Scalars['String']['output']>
  invoiceLines: InsightsInvoiceLineNodeConnection
  isCancelled?: Maybe<Scalars['Boolean']['output']>
  isDummy?: Maybe<Scalars['Boolean']['output']>
  isFailedToArrive: Scalars['Boolean']['output']
  isFailedToDepart: Scalars['Boolean']['output']
  isForwardRegistered?: Maybe<Scalars['Boolean']['output']>
  itNumber?: Maybe<Scalars['String']['output']>
  lastLegArrivalStatus: Scalars['String']['output']
  lastLegAta?: Maybe<Scalars['DateTime']['output']>
  lastLegDestination?: Maybe<Scalars['String']['output']>
  lastLegDestinationDescription?: Maybe<Scalars['String']['output']>
  lastLegDestinationDisplay?: Maybe<Scalars['String']['output']>
  lastLegEta?: Maybe<Scalars['DateTime']['output']>
  localClientAddress1?: Maybe<Scalars['String']['output']>
  localClientAddress2?: Maybe<Scalars['String']['output']>
  localClientCity?: Maybe<Scalars['String']['output']>
  localClientCode?: Maybe<Scalars['String']['output']>
  localClientCountry?: Maybe<Scalars['String']['output']>
  localClientKey?: Maybe<Scalars['String']['output']>
  localClientName?: Maybe<Scalars['String']['output']>
  localClientState?: Maybe<Scalars['String']['output']>
  localClientUnlocode?: Maybe<Scalars['String']['output']>
  mainLegArrivalStatus: Scalars['String']['output']
  mainLegCarrierAddress1?: Maybe<Scalars['String']['output']>
  mainLegCarrierAddress2?: Maybe<Scalars['String']['output']>
  mainLegCarrierCity?: Maybe<Scalars['String']['output']>
  mainLegCarrierCode?: Maybe<Scalars['String']['output']>
  mainLegCarrierCountry?: Maybe<Scalars['String']['output']>
  mainLegCarrierKey?: Maybe<Scalars['String']['output']>
  mainLegCarrierName?: Maybe<Scalars['String']['output']>
  mainLegCarrierState?: Maybe<Scalars['String']['output']>
  mainLegCarrierUnlocode?: Maybe<Scalars['String']['output']>
  marksAndNumbers?: Maybe<Scalars['String']['output']>
  masterBill?: Maybe<Scalars['String']['output']>
  milestone: Scalars['String']['output']
  milestones: InsightsMilestoneNodeConnection
  module?: Maybe<Scalars['String']['output']>
  operationsRepCode?: Maybe<Scalars['String']['output']>
  operationsRepName?: Maybe<Scalars['String']['output']>
  operator?: Maybe<Scalars['String']['output']>
  orderRefs?: Maybe<Scalars['String']['output']>
  originPort?: Maybe<Scalars['String']['output']>
  originPortAta?: Maybe<Scalars['DateTime']['output']>
  originPortAtd?: Maybe<Scalars['DateTime']['output']>
  originPortDescription?: Maybe<Scalars['String']['output']>
  originPortDisplay?: Maybe<Scalars['String']['output']>
  originPortEta?: Maybe<Scalars['DateTime']['output']>
  originPortEtd?: Maybe<Scalars['DateTime']['output']>
  originalChargeableVolume?: Maybe<Scalars['Float']['output']>
  originalChargeableVolumeUom?: Maybe<Scalars['String']['output']>
  originalChargeableWeight?: Maybe<Scalars['Float']['output']>
  originalChargeableWeightUom?: Maybe<Scalars['String']['output']>
  originalDeclarationDischargeDate?: Maybe<Scalars['DateTime']['output']>
  originalDeclarationDischargeDateTimezone?: Maybe<Scalars['String']['output']>
  originalDeclarationEntryDate?: Maybe<Scalars['DateTime']['output']>
  originalDeclarationEntryDateTimezone?: Maybe<Scalars['String']['output']>
  originalDeclarationEstimatedEntryDate?: Maybe<Scalars['DateTime']['output']>
  originalDeclarationEstimatedEntryDateTimezone?: Maybe<Scalars['String']['output']>
  originalDeclarationExportDate?: Maybe<Scalars['DateTime']['output']>
  originalDeclarationExportDateTimezone?: Maybe<Scalars['String']['output']>
  originalDeclarationItDate?: Maybe<Scalars['DateTime']['output']>
  originalDeclarationItDateTimezone?: Maybe<Scalars['String']['output']>
  originalDeclarationLoadingDate?: Maybe<Scalars['DateTime']['output']>
  originalDeclarationLoadingDateTimezone?: Maybe<Scalars['String']['output']>
  originalDeliveryActualDate?: Maybe<Scalars['DateTime']['output']>
  originalDeliveryActualDateTimezone?: Maybe<Scalars['String']['output']>
  originalDeliveryBookingRequestedDate?: Maybe<Scalars['DateTime']['output']>
  originalDeliveryBookingRequestedDateTimezone?: Maybe<Scalars['String']['output']>
  originalDeliveryByDate?: Maybe<Scalars['DateTime']['output']>
  originalDeliveryByDateTimezone?: Maybe<Scalars['String']['output']>
  originalDeliveryEstimatedDate?: Maybe<Scalars['DateTime']['output']>
  originalDeliveryEstimatedDateTimezone?: Maybe<Scalars['String']['output']>
  originalDeliveryFromDate?: Maybe<Scalars['DateTime']['output']>
  originalDeliveryFromDateTimezone?: Maybe<Scalars['String']['output']>
  originalDischargePortAta?: Maybe<Scalars['DateTime']['output']>
  originalDischargePortAtaTimezone?: Maybe<Scalars['String']['output']>
  originalDischargePortAtd?: Maybe<Scalars['DateTime']['output']>
  originalDischargePortAtdTimezone?: Maybe<Scalars['String']['output']>
  originalDischargePortEta?: Maybe<Scalars['DateTime']['output']>
  originalDischargePortEtaTimezone?: Maybe<Scalars['String']['output']>
  originalDischargePortEtd?: Maybe<Scalars['DateTime']['output']>
  originalDischargePortEtdTimezone?: Maybe<Scalars['String']['output']>
  originalFinalDestinationEta?: Maybe<Scalars['DateTime']['output']>
  originalFinalDestinationEtaTimezone?: Maybe<Scalars['String']['output']>
  originalFirstLegAtd?: Maybe<Scalars['DateTime']['output']>
  originalFirstLegAtdTimezone?: Maybe<Scalars['String']['output']>
  originalFirstLegEtd?: Maybe<Scalars['DateTime']['output']>
  originalFirstLegEtdTimezone?: Maybe<Scalars['String']['output']>
  originalFirstOriginEtd?: Maybe<Scalars['DateTime']['output']>
  originalFirstOriginEtdTimezone?: Maybe<Scalars['String']['output']>
  originalLastLegAta?: Maybe<Scalars['DateTime']['output']>
  originalLastLegAtaTimezone?: Maybe<Scalars['String']['output']>
  originalLastLegEta?: Maybe<Scalars['DateTime']['output']>
  originalLastLegEtaTimezone?: Maybe<Scalars['String']['output']>
  originalOriginPortAta?: Maybe<Scalars['DateTime']['output']>
  originalOriginPortAtaTimezone?: Maybe<Scalars['String']['output']>
  originalOriginPortAtd?: Maybe<Scalars['DateTime']['output']>
  originalOriginPortAtdTimezone?: Maybe<Scalars['String']['output']>
  originalOriginPortEta?: Maybe<Scalars['DateTime']['output']>
  originalOriginPortEtaTimezone?: Maybe<Scalars['String']['output']>
  originalOriginPortEtd?: Maybe<Scalars['DateTime']['output']>
  originalOriginPortEtdTimezone?: Maybe<Scalars['String']['output']>
  originalPickupActualDate?: Maybe<Scalars['DateTime']['output']>
  originalPickupActualDateTimezone?: Maybe<Scalars['String']['output']>
  originalPickupBookingRequestedDate?: Maybe<Scalars['DateTime']['output']>
  originalPickupBookingRequestedDateTimezone?: Maybe<Scalars['String']['output']>
  originalPickupByDate?: Maybe<Scalars['DateTime']['output']>
  originalPickupByDateTimezone?: Maybe<Scalars['String']['output']>
  originalPickupEstimatedDate?: Maybe<Scalars['DateTime']['output']>
  originalPickupEstimatedDateTimezone?: Maybe<Scalars['String']['output']>
  originalPickupFromDate?: Maybe<Scalars['DateTime']['output']>
  originalPickupFromDateTimezone?: Maybe<Scalars['String']['output']>
  originalShipmentEta?: Maybe<Scalars['DateTime']['output']>
  originalShipmentEtaTimezone?: Maybe<Scalars['String']['output']>
  originalShipmentEtd?: Maybe<Scalars['DateTime']['output']>
  originalShipmentEtdTimezone?: Maybe<Scalars['String']['output']>
  originalVolume?: Maybe<Scalars['Float']['output']>
  originalVolumeUom?: Maybe<Scalars['String']['output']>
  originalWeight?: Maybe<Scalars['Float']['output']>
  originalWeightUom?: Maybe<Scalars['String']['output']>
  overseasAgentAddress1?: Maybe<Scalars['String']['output']>
  overseasAgentAddress2?: Maybe<Scalars['String']['output']>
  overseasAgentCity?: Maybe<Scalars['String']['output']>
  overseasAgentCode?: Maybe<Scalars['String']['output']>
  overseasAgentCountry?: Maybe<Scalars['String']['output']>
  overseasAgentKey?: Maybe<Scalars['String']['output']>
  overseasAgentName?: Maybe<Scalars['String']['output']>
  overseasAgentState?: Maybe<Scalars['String']['output']>
  overseasAgentUnlocode?: Maybe<Scalars['String']['output']>
  packingLines: InsightsPackingLineNodeConnection
  packs?: Maybe<Scalars['Float']['output']>
  packsUom?: Maybe<Scalars['String']['output']>
  pickupActualDate?: Maybe<Scalars['DateTime']['output']>
  pickupAgentAddress1?: Maybe<Scalars['String']['output']>
  pickupAgentAddress2?: Maybe<Scalars['String']['output']>
  pickupAgentCity?: Maybe<Scalars['String']['output']>
  pickupAgentCode?: Maybe<Scalars['String']['output']>
  pickupAgentCountry?: Maybe<Scalars['String']['output']>
  pickupAgentKey?: Maybe<Scalars['String']['output']>
  pickupAgentName?: Maybe<Scalars['String']['output']>
  pickupAgentState?: Maybe<Scalars['String']['output']>
  pickupAgentUnlocode?: Maybe<Scalars['String']['output']>
  pickupBookingRequestedDate?: Maybe<Scalars['DateTime']['output']>
  pickupByDate?: Maybe<Scalars['DateTime']['output']>
  pickupEstimatedDate?: Maybe<Scalars['DateTime']['output']>
  pickupFromAddress1?: Maybe<Scalars['String']['output']>
  pickupFromAddress2?: Maybe<Scalars['String']['output']>
  pickupFromCity?: Maybe<Scalars['String']['output']>
  pickupFromCode?: Maybe<Scalars['String']['output']>
  pickupFromCountry?: Maybe<Scalars['String']['output']>
  pickupFromDate?: Maybe<Scalars['DateTime']['output']>
  pickupFromKey?: Maybe<Scalars['String']['output']>
  pickupFromName?: Maybe<Scalars['String']['output']>
  pickupFromState?: Maybe<Scalars['String']['output']>
  pickupFromUnlocode?: Maybe<Scalars['String']['output']>
  pickupTransportCompanyAddress1?: Maybe<Scalars['String']['output']>
  pickupTransportCompanyAddress2?: Maybe<Scalars['String']['output']>
  pickupTransportCompanyCity?: Maybe<Scalars['String']['output']>
  pickupTransportCompanyCode?: Maybe<Scalars['String']['output']>
  pickupTransportCompanyCountry?: Maybe<Scalars['String']['output']>
  pickupTransportCompanyKey?: Maybe<Scalars['String']['output']>
  pickupTransportCompanyName?: Maybe<Scalars['String']['output']>
  pickupTransportCompanyState?: Maybe<Scalars['String']['output']>
  pickupTransportCompanyUnlocode?: Maybe<Scalars['String']['output']>
  receivingAgentAddress1?: Maybe<Scalars['String']['output']>
  receivingAgentAddress2?: Maybe<Scalars['String']['output']>
  receivingAgentCity?: Maybe<Scalars['String']['output']>
  receivingAgentCode?: Maybe<Scalars['String']['output']>
  receivingAgentCountry?: Maybe<Scalars['String']['output']>
  receivingAgentKey?: Maybe<Scalars['String']['output']>
  receivingAgentName?: Maybe<Scalars['String']['output']>
  receivingAgentState?: Maybe<Scalars['String']['output']>
  receivingAgentUnlocode?: Maybe<Scalars['String']['output']>
  releaseType?: Maybe<Scalars['String']['output']>
  route?: Maybe<Scalars['String']['output']>
  routeDescription?: Maybe<Scalars['String']['output']>
  routeDisplay?: Maybe<Scalars['String']['output']>
  salesRep?: Maybe<Scalars['String']['output']>
  salesRepCode?: Maybe<Scalars['String']['output']>
  salesRepName?: Maybe<Scalars['String']['output']>
  sendingAgentAddress1?: Maybe<Scalars['String']['output']>
  sendingAgentAddress2?: Maybe<Scalars['String']['output']>
  sendingAgentCity?: Maybe<Scalars['String']['output']>
  sendingAgentCode?: Maybe<Scalars['String']['output']>
  sendingAgentCountry?: Maybe<Scalars['String']['output']>
  sendingAgentKey?: Maybe<Scalars['String']['output']>
  sendingAgentName?: Maybe<Scalars['String']['output']>
  sendingAgentState?: Maybe<Scalars['String']['output']>
  sendingAgentUnlocode?: Maybe<Scalars['String']['output']>
  serviceLevel?: Maybe<Scalars['String']['output']>
  serviceLevelDescription?: Maybe<Scalars['String']['output']>
  shipmentAta?: Maybe<Scalars['DateTime']['output']>
  shipmentAtd?: Maybe<Scalars['DateTime']['output']>
  shipmentDestination?: Maybe<Scalars['String']['output']>
  shipmentDestinationDescription?: Maybe<Scalars['String']['output']>
  shipmentEta?: Maybe<Scalars['DateTime']['output']>
  shipmentEtd?: Maybe<Scalars['DateTime']['output']>
  shipmentLegs: InsightsShipmentLegNodeConnection
  shipmentOrigin?: Maybe<Scalars['String']['output']>
  shipmentOriginDescription?: Maybe<Scalars['String']['output']>
  shipmentStatus?: Maybe<Scalars['String']['output']>
  shipperAddress1?: Maybe<Scalars['String']['output']>
  shipperAddress2?: Maybe<Scalars['String']['output']>
  shipperCity?: Maybe<Scalars['String']['output']>
  shipperCode?: Maybe<Scalars['String']['output']>
  shipperCountry?: Maybe<Scalars['String']['output']>
  shipperKey?: Maybe<Scalars['String']['output']>
  shipperName?: Maybe<Scalars['String']['output']>
  shipperState?: Maybe<Scalars['String']['output']>
  shipperUnlocode?: Maybe<Scalars['String']['output']>
  snowflakeDateCreated: Scalars['DateTime']['output']
  snowflakeId: Scalars['String']['output']
  supplierAddress1?: Maybe<Scalars['String']['output']>
  supplierAddress2?: Maybe<Scalars['String']['output']>
  supplierCity?: Maybe<Scalars['String']['output']>
  supplierCode?: Maybe<Scalars['String']['output']>
  supplierCountry?: Maybe<Scalars['String']['output']>
  supplierKey?: Maybe<Scalars['String']['output']>
  supplierName?: Maybe<Scalars['String']['output']>
  supplierState?: Maybe<Scalars['String']['output']>
  supplierUnlocode?: Maybe<Scalars['String']['output']>
  teus?: Maybe<Scalars['Float']['output']>
  tmsId: Scalars['String']['output']
  transportMode?: Maybe<Scalars['String']['output']>
  transportNumbers?: Maybe<Scalars['String']['output']>
  vessel?: Maybe<Scalars['String']['output']>
  volume?: Maybe<Scalars['Float']['output']>
  voyage?: Maybe<Scalars['String']['output']>
  weight?: Maybe<Scalars['Float']['output']>
}

export type InvoiceLineDetails = {
  __typename?: 'InvoiceLineDetails'
  amount?: Maybe<Scalars['Float']['output']>
  chargeCode?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  originalAmount?: Maybe<Scalars['Float']['output']>
  originalCurrency?: Maybe<Scalars['String']['output']>
}

export type InvoiceLineItemReconResultNode = ReconResultInterface & {
  __typename?: 'InvoiceLineItemReconResultNode'
  chainIoConsolCost?: Maybe<ChainIoConsolCostNode>
  chainIoConsolCostId?: Maybe<Scalars['UUID']['output']>
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationCost?: Maybe<ChainIoCustomsDeclarationCostNode>
  chainIoCustomsDeclarationCostId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  chainIoSiLine?: Maybe<ChainIoServiceInvoiceLineNode>
  chainIoSiLineId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dueDate?: Maybe<Scalars['String']['output']>
  duplicateChainIoSiLines: DuplicateChainIoServiceInvoiceLineNodeConnection
  expectedAmount?: Maybe<Scalars['Decimal']['output']>
  expectedChainIoSi?: Maybe<ChainIoServiceInvoiceNode>
  expectedChainIoSiId?: Maybe<Scalars['UUID']['output']>
  expectedChargeCode?: Maybe<ChargeCodeNode>
  expectedChargeCodeId?: Maybe<Scalars['UUID']['output']>
  expectedChargeCodeV2?: Maybe<ChargeCodeV2Node>
  expectedChargeCodeV2Id?: Maybe<Scalars['UUID']['output']>
  expectedChargeVendor?: Maybe<ChargeVendorNode>
  expectedChargeVendorId?: Maybe<Scalars['UUID']['output']>
  expectedCurrency?: Maybe<Scalars['String']['output']>
  expectedQuantity?: Maybe<Scalars['Decimal']['output']>
  expectedTaxAmount?: Maybe<Scalars['Decimal']['output']>
  expectedTaxId?: Maybe<Scalars['String']['output']>
  expectedTaxRate?: Maybe<Scalars['Decimal']['output']>
  expectedUnitPrice?: Maybe<Scalars['Decimal']['output']>
  id: Scalars['UUID']['output']
  invoiceAmount?: Maybe<Scalars['Decimal']['output']>
  invoiceChargeCode?: Maybe<ChargeCodeNode>
  invoiceChargeCodeCode?: Maybe<Scalars['String']['output']>
  invoiceChargeCodeId?: Maybe<Scalars['UUID']['output']>
  invoiceChargeCodeV2?: Maybe<ChargeCodeV2Node>
  invoiceChargeCodeV2Id?: Maybe<Scalars['UUID']['output']>
  invoiceChargeVendor?: Maybe<ChargeVendorNode>
  invoiceChargeVendorId?: Maybe<Scalars['UUID']['output']>
  invoiceChargeVendorName?: Maybe<Scalars['String']['output']>
  invoiceCurrency?: Maybe<Scalars['String']['output']>
  invoiceDate?: Maybe<Scalars['String']['output']>
  invoiceDescription?: Maybe<Scalars['String']['output']>
  invoiceNumber?: Maybe<Scalars['String']['output']>
  invoiceQuantity?: Maybe<Scalars['Decimal']['output']>
  invoiceReconLineItemSnapshot?: Maybe<InvoiceReconLineItemSnapshotInterface>
  invoiceReconLineItemSnapshotId?: Maybe<Scalars['UUID']['output']>
  invoiceTaxAmount?: Maybe<Scalars['Decimal']['output']>
  invoiceTaxId?: Maybe<Scalars['String']['output']>
  invoiceTaxRate?: Maybe<Scalars['Decimal']['output']>
  invoiceUnitPrice?: Maybe<Scalars['Decimal']['output']>
  invoiceVendorType?: Maybe<InvoiceVendorType>
  isChargeCodeSame?: Maybe<Scalars['Boolean']['output']>
  isChargeVendorSame?: Maybe<Scalars['Boolean']['output']>
  isCurrencySameOrEmpty?: Maybe<Scalars['Boolean']['output']>
  isInvoiceNumberCorrect?: Maybe<Scalars['Boolean']['output']>
  isInvoiceNumberSame?: Maybe<Scalars['Boolean']['output']>
  isTotalCostEqual?: Maybe<Scalars['Boolean']['output']>
  job: JobNode
  jobId: Scalars['UUID']['output']
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type InvoiceReconLineItemSnapshotInterface = {
  apportionmentMethod?: Maybe<Scalars['String']['output']>
  branch?: Maybe<Scalars['String']['output']>
  chargeCode?: Maybe<Scalars['String']['output']>
  chargeCost?: Maybe<Scalars['Decimal']['output']>
  chargeDescription?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  department?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceLineBranch?: Maybe<Scalars['String']['output']>
  invoiceLineDepartment?: Maybe<Scalars['String']['output']>
  job?: Maybe<JobNode>
  jobId?: Maybe<Scalars['UUID']['output']>
  quantity?: Maybe<Scalars['Decimal']['output']>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  sellAmount?: Maybe<Scalars['Decimal']['output']>
  sellCurrency?: Maybe<Scalars['String']['output']>
  taxId?: Maybe<Scalars['String']['output']>
  type: InvoiceReconLineItemType
  unitPrice?: Maybe<Scalars['Decimal']['output']>
}

export type InvoiceReconLineItemSnapshotInterfaceConnection = {
  __typename?: 'InvoiceReconLineItemSnapshotInterfaceConnection'
  edges: Array<InvoiceReconLineItemSnapshotInterfaceEdge>
}

export type InvoiceReconLineItemSnapshotInterfaceEdge = {
  __typename?: 'InvoiceReconLineItemSnapshotInterfaceEdge'
  node: InvoiceReconLineItemSnapshotInterface
}

export type InvoiceReconLineItemSnapshotNode = InvoiceReconLineItemSnapshotInterface & {
  __typename?: 'InvoiceReconLineItemSnapshotNode'
  apportionmentMethod?: Maybe<Scalars['String']['output']>
  branch?: Maybe<Scalars['String']['output']>
  chargeCode?: Maybe<Scalars['String']['output']>
  chargeCost?: Maybe<Scalars['Decimal']['output']>
  chargeDescription?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  department?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceLineBranch?: Maybe<Scalars['String']['output']>
  invoiceLineDepartment?: Maybe<Scalars['String']['output']>
  job?: Maybe<JobNode>
  jobId?: Maybe<Scalars['UUID']['output']>
  quantity?: Maybe<Scalars['Decimal']['output']>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  sellAmount?: Maybe<Scalars['Decimal']['output']>
  sellCurrency?: Maybe<Scalars['String']['output']>
  taxId?: Maybe<Scalars['String']['output']>
  type: InvoiceReconLineItemType
  unitPrice?: Maybe<Scalars['Decimal']['output']>
}

export enum InvoiceReconLineItemType {
  Ap = 'AP',
  Soa = 'SOA',
}

export type InvoiceReconMatchNode = {
  __typename?: 'InvoiceReconMatchNode'
  chainIoSi: ChainIoServiceInvoiceNode
  chainIoSiId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  findInvoiceReconResult: FindInvoiceReconResultNode
  findInvoiceReconResultId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
}

export type InvoiceReconMatchNodeConnection = {
  __typename?: 'InvoiceReconMatchNodeConnection'
  edges: Array<InvoiceReconMatchNodeEdge>
}

export type InvoiceReconMatchNodeEdge = {
  __typename?: 'InvoiceReconMatchNodeEdge'
  node: InvoiceReconMatchNode
}

export type InvoiceResult = {
  __typename?: 'InvoiceResult'
  details: Array<InvoiceLineDetails>
  relatedShipments: Array<Scalars['String']['output']>
  summary: InvoiceSummary
}

export type InvoiceSummary = {
  __typename?: 'InvoiceSummary'
  dateFullyPaid?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['String']['output']>
  integrationId?: Maybe<Scalars['String']['output']>
  invoiceKey: Scalars['String']['output']
  invoiceNumber?: Maybe<Scalars['String']['output']>
  isPayable: Scalars['Boolean']['output']
  isPosted: Scalars['Boolean']['output']
  issuedDate?: Maybe<Scalars['String']['output']>
  latestCauldronDateCreated?: Maybe<Scalars['String']['output']>
  latestSnowflakeDateCreated?: Maybe<Scalars['String']['output']>
  outstandingAmount?: Maybe<Scalars['Float']['output']>
  paymentStatus?: Maybe<Scalars['String']['output']>
  totalAmount?: Maybe<Scalars['Float']['output']>
  vendor?: Maybe<Scalars['String']['output']>
}

export type InvoiceTotalReconResultNode = ReconResultInterface & {
  __typename?: 'InvoiceTotalReconResultNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  chainIoSi?: Maybe<ChainIoServiceInvoiceNode>
  chainIoSiId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  expectedAmount: Scalars['Decimal']['output']
  expectedTaxedAmount?: Maybe<Scalars['Decimal']['output']>
  id: Scalars['UUID']['output']
  invoiceAmount: Scalars['Decimal']['output']
  invoiceTaxedAmount?: Maybe<Scalars['Decimal']['output']>
  isAmountEqual: Scalars['Boolean']['output']
  isTaxedAmountEqual: Scalars['Boolean']['output']
  isWithinThreshold: Scalars['Boolean']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  reconThresholdRange?: Maybe<ReconThresholdRangeNode>
  reconThresholdRangeId?: Maybe<Scalars['UUID']['output']>
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
  usedThresholdMatching: Scalars['Boolean']['output']
}

export enum InvoiceVendorType {
  CustomsBroker = 'CUSTOMS_BROKER',
  DestinationAgent = 'DESTINATION_AGENT',
  Drayage = 'DRAYAGE',
  OriginAgent = 'ORIGIN_AGENT',
  PortOrTerminal = 'PORT_OR_TERMINAL',
  SteamshipLine = 'STEAMSHIP_LINE',
  Warehouse = 'WAREHOUSE',
}

export type IsValidReconJobTemplateResult = {
  __typename?: 'IsValidReconJobTemplateResult'
  errors: Array<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

export type JobCheckShipmentInfoNode = {
  __typename?: 'JobCheckShipmentInfoNode'
  cargowiseModule?: Maybe<Scalars['String']['output']>
  carrierBookingNumber?: Maybe<Scalars['String']['output']>
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainioShipment?: Maybe<ChainIoShipmentNode>
  chainioShipmentId?: Maybe<Scalars['UUID']['output']>
  checkShipmentInfoMatchCriteria?: Maybe<CheckShipmentInfoMatchCriteriaNode>
  checkShipmentInfoMatchCriteriaId?: Maybe<Scalars['UUID']['output']>
  consolNumber?: Maybe<Scalars['String']['output']>
  containerNumber?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  hblNumber?: Maybe<Scalars['String']['output']>
  host?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  mblNumber?: Maybe<Scalars['String']['output']>
  note?: Maybe<NoteNode>
  orderNumber?: Maybe<Scalars['String']['output']>
  rawCargowiseInbound?: Maybe<RawCargowiseInboundNode>
  rawCargowiseInboundId?: Maybe<Scalars['UUID']['output']>
  rawCargowiseOutbound?: Maybe<RawCargowiseOutboundNode>
  rawCargowiseOutboundId?: Maybe<Scalars['UUID']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
  voyageNumber?: Maybe<Scalars['String']['output']>
}

export type JobConfirmationNode = {
  __typename?: 'JobConfirmationNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  filename: Scalars['String']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  nextStatus: JobStatus
  note?: Maybe<NoteNode>
  path: Scalars['String']['output']
  previousStatus?: Maybe<JobStatus>
  type: Scalars['String']['output']
  uri?: Maybe<Scalars['String']['output']>
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobDocumentExtractionResultLogNode = {
  __typename?: 'JobDocumentExtractionResultLogNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  postProcessedDocumentExtractionResultS3Uri: Scalars['String']['output']
  rawDocumentExtractionResultS3Uri?: Maybe<Scalars['String']['output']>
}

export type JobDocumentExtractionResultLogNodeConnection = {
  __typename?: 'JobDocumentExtractionResultLogNodeConnection'
  edges: Array<JobDocumentExtractionResultLogNodeEdge>
}

export type JobDocumentExtractionResultLogNodeEdge = {
  __typename?: 'JobDocumentExtractionResultLogNodeEdge'
  node: JobDocumentExtractionResultLogNode
}

export type JobEDocsPullEDocumentNode = {
  __typename?: 'JobEDocsPullEDocumentNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  eDocId: Scalars['UUID']['output']
  eDocument: EDocumentNode
  id: Scalars['UUID']['output']
  jobEDocsPull: JobEDocsPullNode
  jobEDocsPullId: Scalars['UUID']['output']
}

export type JobEDocsPullEDocumentNodeConnection = {
  __typename?: 'JobEDocsPullEDocumentNodeConnection'
  edges: Array<JobEDocsPullEDocumentNodeEdge>
}

export type JobEDocsPullEDocumentNodeEdge = {
  __typename?: 'JobEDocsPullEDocumentNodeEdge'
  node: JobEDocsPullEDocumentNode
}

export type JobEDocsPullNode = {
  __typename?: 'JobEDocsPullNode'
  batchPullId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  eDocuments: EDocumentNodeConnection
  errorMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobEDocsPullEDocuments: JobEDocsPullEDocumentNodeConnection
  jobId: Scalars['UUID']['output']
  module?: Maybe<Scalars['String']['output']>
  note?: Maybe<NoteNode>
  rawCargowiseInbound?: Maybe<RawCargowiseInboundNode>
  rawCargowiseInboundId?: Maybe<Scalars['UUID']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobEDocsPushEDocumentNode = {
  __typename?: 'JobEDocsPushEDocumentNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  eDocId: Scalars['UUID']['output']
  eDocument: EDocumentNode
  id: Scalars['UUID']['output']
  jobEDocsPush: JobEDocsPushNode
  jobEDocsPushId: Scalars['UUID']['output']
}

export type JobEDocsPushEDocumentNodeConnection = {
  __typename?: 'JobEDocsPushEDocumentNodeConnection'
  edges: Array<JobEDocsPushEDocumentNodeEdge>
}

export type JobEDocsPushEDocumentNodeEdge = {
  __typename?: 'JobEDocsPushEDocumentNodeEdge'
  node: JobEDocsPushEDocumentNode
}

export type JobEDocsPushNode = {
  __typename?: 'JobEDocsPushNode'
  batchPushId?: Maybe<Scalars['UUID']['output']>
  cargowiseFileType?: Maybe<CargowiseFileTypeNode>
  cargowiseFileTypeId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  eDocuments: EDocumentNodeConnection
  errorMessage?: Maybe<Scalars['String']['output']>
  host?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobEDocsPushEDocuments: JobEDocsPushEDocumentNodeConnection
  jobId: Scalars['UUID']['output']
  module?: Maybe<Scalars['String']['output']>
  note?: Maybe<NoteNode>
  rawCargowiseInbound?: Maybe<RawCargowiseInboundNode>
  rawCargowiseInboundId?: Maybe<Scalars['UUID']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobEDocsPushNodeConnection = {
  __typename?: 'JobEDocsPushNodeConnection'
  edges: Array<JobEDocsPushNodeEdge>
}

export type JobEDocsPushNodeEdge = {
  __typename?: 'JobEDocsPushNodeEdge'
  node: JobEDocsPushNode
}

export type JobEDocsPushNodeJobEDocsPullNodeJobSendToCwNodeJobPostToCwNodeJobShipmentOpsCreateNodeJobCheckShipmentInfoNodeJobReceivedNodeJobStatusChangeNodeJobConfirmationNodeJobRejectionNodeJobOwnerChangeNodeJobQaChangeNodeJobQaNodeJobExternalStatusChangeNodeJobReconciliationSavedNodeJobMoveToDoneNodeJobRequestReprocessNodeJobExternalAssigneeChangeNodeJobReconUpdateStatusNodeJobMetadataEditNodeJobLineItemEditNodeApiExportJobActivityNodeApiExportEDocActivityNodeApiImportEDocActivityNodeJobReconApproveForPostingNodeJobReconAttemptSavedNodeJobReconCommentNodeJobReconExternalAssigneeChangeNodeJobReconExternalStatusChangeNodeJobReconMoveToDoneNodeJobReconReceivedNodeJobReconRequestReprocessNodeJobReconRequestReprocessTargetNode =

    | ApiExportEDocActivityNode
    | ApiExportJobActivityNode
    | ApiImportEDocActivityNode
    | JobCheckShipmentInfoNode
    | JobConfirmationNode
    | JobEDocsPullNode
    | JobEDocsPushNode
    | JobExternalAssigneeChangeNode
    | JobExternalStatusChangeNode
    | JobLineItemEditNode
    | JobMetadataEditNode
    | JobMoveToDoneNode
    | JobOwnerChangeNode
    | JobPostToCwNode
    | JobQaChangeNode
    | JobQaNode
    | JobReceivedNode
    | JobReconApproveForPostingNode
    | JobReconAttemptSavedNode
    | JobReconCommentNode
    | JobReconExternalAssigneeChangeNode
    | JobReconExternalStatusChangeNode
    | JobReconMoveToDoneNode
    | JobReconReceivedNode
    | JobReconRequestReprocessNode
    | JobReconRequestReprocessTargetNode
    | JobReconUpdateStatusNode
    | JobReconciliationSavedNode
    | JobRejectionNode
    | JobRequestReprocessNode
    | JobSendToCwNode
    | JobShipmentOpsCreateNode
    | JobStatusChangeNode

export enum JobEditType {
  Added = 'ADDED',
  Changed = 'CHANGED',
  Removed = 'REMOVED',
}

export type JobEditTypeEnum = {
  __typename?: 'JobEditTypeEnum'
  isFallback: Scalars['Boolean']['output']
  value: JobEditType
}

export type JobEditTypeWithFallback = FallbackValue | JobEditTypeEnum

export type JobExternalAssigneeChangeNode = {
  __typename?: 'JobExternalAssigneeChangeNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  newAssignee?: Maybe<UserNode>
  newAssigneeId?: Maybe<Scalars['UUID']['output']>
  note?: Maybe<NoteNode>
  oldAssignee?: Maybe<UserNode>
  oldAssigneeId?: Maybe<Scalars['UUID']['output']>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export enum JobExternalStatus {
  Done = 'DONE',
  ForExpedock = 'FOR_EXPEDOCK',
  Todo = 'TODO',
}

export type JobExternalStatusChangeNode = {
  __typename?: 'JobExternalStatusChangeNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  newStatus?: Maybe<JobExternalStatus>
  note?: Maybe<NoteNode>
  oldStatus?: Maybe<JobExternalStatus>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export enum JobFilterColumn {
  Company = 'COMPANY',
  DateCompleted = 'DATE_COMPLETED',
  DateCreated = 'DATE_CREATED',
  DateReceived = 'DATE_RECEIVED',
  JobOwner = 'JOB_OWNER',
  JobQa = 'JOB_QA',
  JobType = 'JOB_TYPE',
  TaskType = 'TASK_TYPE',
}

export enum JobFilterOperation {
  After = 'AFTER',
  Before = 'BEFORE',
  Contains = 'CONTAINS',
  DoesNotContain = 'DOES_NOT_CONTAIN',
  Is = 'IS',
  IsNot = 'IS_NOT',
}

export type JobFilterSettingNode = {
  __typename?: 'JobFilterSettingNode'
  column: JobFilterColumn
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  operation: JobFilterOperation
  user: UserNode
  userId: Scalars['UUID']['output']
  value: Array<Scalars['String']['output']>
}

export type JobHumanInTheLoopResultLogNode = {
  __typename?: 'JobHumanInTheLoopResultLogNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  humanInTheLoopResultS3Uri: Scalars['String']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
}

export type JobHumanInTheLoopResultLogNodeConnection = {
  __typename?: 'JobHumanInTheLoopResultLogNodeConnection'
  edges: Array<JobHumanInTheLoopResultLogNodeEdge>
}

export type JobHumanInTheLoopResultLogNodeEdge = {
  __typename?: 'JobHumanInTheLoopResultLogNodeEdge'
  node: JobHumanInTheLoopResultLogNode
}

export type JobLineItemEditNode = {
  __typename?: 'JobLineItemEditNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  editType?: Maybe<JobEditTypeWithFallback>
  fieldGroupId?: Maybe<Scalars['UUID']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  jobTableId?: Maybe<Scalars['UUID']['output']>
  lineItem?: Maybe<Scalars['JSONString']['output']>
  note?: Maybe<NoteNode>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobLineItemEditNodeConnection = {
  __typename?: 'JobLineItemEditNodeConnection'
  edges: Array<JobLineItemEditNodeEdge>
}

export type JobLineItemEditNodeEdge = {
  __typename?: 'JobLineItemEditNodeEdge'
  node: JobLineItemEditNode
}

export type JobMetadataEditNode = {
  __typename?: 'JobMetadataEditNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  fields?: Maybe<Scalars['JSONString']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  note?: Maybe<NoteNode>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobMetadataEditNodeConnection = {
  __typename?: 'JobMetadataEditNodeConnection'
  edges: Array<JobMetadataEditNodeEdge>
}

export type JobMetadataEditNodeEdge = {
  __typename?: 'JobMetadataEditNodeEdge'
  node: JobMetadataEditNode
}

export type JobMoveToDoneNode = {
  __typename?: 'JobMoveToDoneNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  newStatus?: Maybe<JobExternalStatus>
  note?: Maybe<NoteNode>
  oldStatus?: Maybe<JobExternalStatus>
  reason: JobMoveToDoneReason
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export enum JobMoveToDoneReason {
  AlreadyPaid = 'ALREADY_PAID',
  AlreadyPosted = 'ALREADY_POSTED',
  Disputed = 'DISPUTED',
  DisputedAndToBeReinvoiced = 'DISPUTED_AND_TO_BE_REINVOICED',
  ForDispute = 'FOR_DISPUTE',
  Others = 'OTHERS',
}

export type JobNode = {
  __typename?: 'JobNode'
  apiEDocuments: ApiEDocumentNodeConnection
  clientReferenceNo: Scalars['String']['output']
  creator?: Maybe<UserNode>
  creatorId?: Maybe<Scalars['UUID']['output']>
  dateCompleted?: Maybe<Scalars['DateTime']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dateReceived?: Maybe<Scalars['DateTime']['output']>
  description: Scalars['String']['output']
  documentExtractionResultLogs: JobDocumentExtractionResultLogNodeConnection
  documentFieldGroups: DocumentFieldGroupNodeConnection
  eDocuments: EDocumentNodeConnection
  externalAssignee?: Maybe<UserNode>
  externalAssigneeId?: Maybe<Scalars['UUID']['output']>
  externalStatus?: Maybe<JobExternalStatus>
  filePages: FilePageNodeConnection
  humanInTheLoopResultLogs: JobHumanInTheLoopResultLogNodeConnection
  id: Scalars['UUID']['output']
  jobCode: Scalars['String']['output']
  jobLineItemEdits: JobLineItemEditNodeConnection
  jobMetadataEdits: JobMetadataEditNodeConnection
  jobTable?: Maybe<JobTableNode>
  jobTemplate: JobTemplateNode
  jobTemplateId: Scalars['UUID']['output']
  latestSavedReconAsyncBatch?: Maybe<ReconAsyncBatchNode>
  latestSavedReconAsyncBatchId?: Maybe<Scalars['UUID']['output']>
  latestSavedReconAttempt?: Maybe<ReconAttemptNode>
  latestSavedReconAttemptId?: Maybe<Scalars['UUID']['output']>
  manualProduct: ManualProduct
  message?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  notes: NoteNodeConnection
  owner?: Maybe<UserNode>
  ownerId?: Maybe<Scalars['UUID']['output']>
  pageAsyncBatches: PageAsyncBatchNodeConnection
  qa?: Maybe<UserNode>
  qaId?: Maybe<Scalars['UUID']['output']>
  rejections: JobRejectionNodeConnection
  slaTime: Scalars['Int']['output']
  status: JobStatus
  task?: Maybe<TaskNode>
  taskId?: Maybe<Scalars['UUID']['output']>
}

export type JobNodeConnection = {
  __typename?: 'JobNodeConnection'
  edges: Array<JobNodeEdge>
}

export type JobNodeEdge = {
  __typename?: 'JobNodeEdge'
  node: JobNode
}

export type JobOverviewPage = {
  __typename?: 'JobOverviewPage'
  items: Array<JobNode>
  total: Scalars['Int']['output']
}

export type JobOwnerChangeNode = {
  __typename?: 'JobOwnerChangeNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  newOwner: UserNode
  newOwnerId: Scalars['UUID']['output']
  note?: Maybe<NoteNode>
  previousOwner?: Maybe<UserNode>
  previousOwnerId?: Maybe<Scalars['UUID']['output']>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobPostToCwNode = {
  __typename?: 'JobPostToCWNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  host?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  jsonJobData?: Maybe<Scalars['JSONString']['output']>
  newChainioConsolidation?: Maybe<ChainIoConsolidationNode>
  newChainioConsolidationId?: Maybe<Scalars['UUID']['output']>
  newChainioCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  newChainioCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  newChainioShipment?: Maybe<ChainIoShipmentNode>
  newChainioShipmentId?: Maybe<Scalars['UUID']['output']>
  note?: Maybe<NoteNode>
  oldChainioConsolidation?: Maybe<ChainIoConsolidationNode>
  oldChainioConsolidationId?: Maybe<Scalars['UUID']['output']>
  oldChainioCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  oldChainioCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  oldChainioShipment?: Maybe<ChainIoShipmentNode>
  oldChainioShipmentId?: Maybe<Scalars['UUID']['output']>
  rawCargowiseInbound?: Maybe<RawCargowiseInboundNode>
  rawCargowiseInboundId?: Maybe<Scalars['UUID']['output']>
  success: Scalars['Boolean']['output']
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobQaChangeNode = {
  __typename?: 'JobQAChangeNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  newQa: UserNode
  newQaId: Scalars['UUID']['output']
  note?: Maybe<NoteNode>
  previousQa?: Maybe<UserNode>
  previousQaId?: Maybe<Scalars['UUID']['output']>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobQaFieldIssueNode = {
  __typename?: 'JobQaFieldIssueNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  field: FieldNode
  fieldId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  jobQa: JobQaNode
  jobQaId: Scalars['UUID']['output']
  type: FieldIssueType
}

export type JobQaFieldIssueNodeConnection = {
  __typename?: 'JobQaFieldIssueNodeConnection'
  edges: Array<JobQaFieldIssueNodeEdge>
}

export type JobQaFieldIssueNodeEdge = {
  __typename?: 'JobQaFieldIssueNodeEdge'
  node: JobQaFieldIssueNode
}

export type JobQaNode = {
  __typename?: 'JobQaNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  jobQaFieldIssues: JobQaFieldIssueNodeConnection
  jobQaOverallIssues: JobQaOverallIssueNodeConnection
  note?: Maybe<NoteNode>
  otherInfo: Scalars['String']['output']
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobQaOverallIssueNode = {
  __typename?: 'JobQaOverallIssueNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  jobQa: JobQaNode
  jobQaId: Scalars['UUID']['output']
  jobQaOverallIssueType: JobQaOverallIssueTypeNode
  jobQaOverallIssueTypeId: Scalars['UUID']['output']
}

export type JobQaOverallIssueNodeConnection = {
  __typename?: 'JobQaOverallIssueNodeConnection'
  edges: Array<JobQaOverallIssueNodeEdge>
}

export type JobQaOverallIssueNodeEdge = {
  __typename?: 'JobQaOverallIssueNodeEdge'
  node: JobQaOverallIssueNode
}

export type JobQaOverallIssueTypeNode = {
  __typename?: 'JobQaOverallIssueTypeNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  jobQaOverallIssues: JobQaOverallIssueNodeConnection
  name: Scalars['String']['output']
}

export type JobReceivedNode = {
  __typename?: 'JobReceivedNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  note?: Maybe<NoteNode>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobReconApproveForPostingNode = {
  __typename?: 'JobReconApproveForPostingNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  newStatus?: Maybe<JobExternalStatus>
  note?: Maybe<NoteNode>
  oldStatus?: Maybe<JobExternalStatus>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  reconInvoice?: Maybe<ReconInvoiceNode>
  reconInvoiceId?: Maybe<Scalars['UUID']['output']>
  reconInvoiceShipmentReference?: Maybe<ReconInvoiceShipmentReferenceNode>
  reconInvoiceShipmentReferenceId?: Maybe<Scalars['UUID']['output']>
  reconMatchCriteria?: Maybe<ReconMatchCriteriaNode>
  reconMatchCriteriaId?: Maybe<Scalars['UUID']['output']>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobReconAttemptSavedNode = {
  __typename?: 'JobReconAttemptSavedNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  newStatus?: Maybe<JobExternalStatus>
  note?: Maybe<NoteNode>
  oldStatus?: Maybe<JobExternalStatus>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  reconInvoice?: Maybe<ReconInvoiceNode>
  reconInvoiceId?: Maybe<Scalars['UUID']['output']>
  reconInvoiceShipmentReference?: Maybe<ReconInvoiceShipmentReferenceNode>
  reconInvoiceShipmentReferenceId?: Maybe<Scalars['UUID']['output']>
  reconMatchCriteria?: Maybe<ReconMatchCriteriaNode>
  reconMatchCriteriaId?: Maybe<Scalars['UUID']['output']>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobReconCommentNode = {
  __typename?: 'JobReconCommentNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  note?: Maybe<NoteNode>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  reconInvoice?: Maybe<ReconInvoiceNode>
  reconInvoiceId?: Maybe<Scalars['UUID']['output']>
  reconInvoiceShipmentReference?: Maybe<ReconInvoiceShipmentReferenceNode>
  reconInvoiceShipmentReferenceId?: Maybe<Scalars['UUID']['output']>
  reconMatchCriteria?: Maybe<ReconMatchCriteriaNode>
  reconMatchCriteriaId?: Maybe<Scalars['UUID']['output']>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobReconExternalAssigneeChangeNode = {
  __typename?: 'JobReconExternalAssigneeChangeNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  newAssignee?: Maybe<UserNode>
  newAssigneeId?: Maybe<Scalars['UUID']['output']>
  note?: Maybe<NoteNode>
  oldAssignee?: Maybe<UserNode>
  oldAssigneeId?: Maybe<Scalars['UUID']['output']>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  reconInvoice?: Maybe<ReconInvoiceNode>
  reconInvoiceId?: Maybe<Scalars['UUID']['output']>
  reconInvoiceShipmentReference?: Maybe<ReconInvoiceShipmentReferenceNode>
  reconInvoiceShipmentReferenceId?: Maybe<Scalars['UUID']['output']>
  reconMatchCriteria?: Maybe<ReconMatchCriteriaNode>
  reconMatchCriteriaId?: Maybe<Scalars['UUID']['output']>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobReconExternalStatusChangeNode = {
  __typename?: 'JobReconExternalStatusChangeNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  newStatus?: Maybe<JobExternalStatus>
  note?: Maybe<NoteNode>
  oldStatus?: Maybe<JobExternalStatus>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  reconInvoice?: Maybe<ReconInvoiceNode>
  reconInvoiceId?: Maybe<Scalars['UUID']['output']>
  reconInvoiceShipmentReference?: Maybe<ReconInvoiceShipmentReferenceNode>
  reconInvoiceShipmentReferenceId?: Maybe<Scalars['UUID']['output']>
  reconMatchCriteria?: Maybe<ReconMatchCriteriaNode>
  reconMatchCriteriaId?: Maybe<Scalars['UUID']['output']>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobReconMoveToDoneNode = {
  __typename?: 'JobReconMoveToDoneNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  newStatus?: Maybe<JobExternalStatus>
  note?: Maybe<NoteNode>
  oldStatus?: Maybe<JobExternalStatus>
  reason: JobMoveToDoneReason
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  reconInvoice?: Maybe<ReconInvoiceNode>
  reconInvoiceId?: Maybe<Scalars['UUID']['output']>
  reconInvoiceShipmentReference?: Maybe<ReconInvoiceShipmentReferenceNode>
  reconInvoiceShipmentReferenceId?: Maybe<Scalars['UUID']['output']>
  reconMatchCriteria?: Maybe<ReconMatchCriteriaNode>
  reconMatchCriteriaId?: Maybe<Scalars['UUID']['output']>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobReconReceivedNode = {
  __typename?: 'JobReconReceivedNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  note?: Maybe<NoteNode>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  reconInvoice?: Maybe<ReconInvoiceNode>
  reconInvoiceId?: Maybe<Scalars['UUID']['output']>
  reconInvoiceShipmentReference?: Maybe<ReconInvoiceShipmentReferenceNode>
  reconInvoiceShipmentReferenceId?: Maybe<Scalars['UUID']['output']>
  reconMatchCriteria?: Maybe<ReconMatchCriteriaNode>
  reconMatchCriteriaId?: Maybe<Scalars['UUID']['output']>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobReconRequestReprocessNode = {
  __typename?: 'JobReconRequestReprocessNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  autoPostAfterFixing: Scalars['Boolean']['output']
  correctedInfo: Scalars['String']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  newStatus?: Maybe<JobExternalStatus>
  note?: Maybe<NoteNode>
  oldStatus?: Maybe<JobExternalStatus>
  reason: JobRequestReprocessReason
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  reconInvoice?: Maybe<ReconInvoiceNode>
  reconInvoiceId?: Maybe<Scalars['UUID']['output']>
  reconInvoiceShipmentReference?: Maybe<ReconInvoiceShipmentReferenceNode>
  reconInvoiceShipmentReferenceId?: Maybe<Scalars['UUID']['output']>
  reconMatchCriteria?: Maybe<ReconMatchCriteriaNode>
  reconMatchCriteriaId?: Maybe<Scalars['UUID']['output']>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobReconRequestReprocessTargetNode = {
  __typename?: 'JobReconRequestReprocessTargetNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  note?: Maybe<NoteNode>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  reconInvoice?: Maybe<ReconInvoiceNode>
  reconInvoiceId?: Maybe<Scalars['UUID']['output']>
  reconInvoiceShipmentReference?: Maybe<ReconInvoiceShipmentReferenceNode>
  reconInvoiceShipmentReferenceId?: Maybe<Scalars['UUID']['output']>
  reconMatchCriteria?: Maybe<ReconMatchCriteriaNode>
  reconMatchCriteriaId?: Maybe<Scalars['UUID']['output']>
  sourceRequestReprocess: JobReconRequestReprocessNode
  sourceRequestReprocessId: Scalars['UUID']['output']
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobReconUpdateStatusNode = {
  __typename?: 'JobReconUpdateStatusNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  note?: Maybe<NoteNode>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  status: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobReconUpdateStatusNodeConnection = {
  __typename?: 'JobReconUpdateStatusNodeConnection'
  edges: Array<JobReconUpdateStatusNodeEdge>
}

export type JobReconUpdateStatusNodeEdge = {
  __typename?: 'JobReconUpdateStatusNodeEdge'
  node: JobReconUpdateStatusNode
}

export type JobReconciliationSavedNode = {
  __typename?: 'JobReconciliationSavedNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  newStatus?: Maybe<JobExternalStatus>
  note?: Maybe<NoteNode>
  oldStatus?: Maybe<JobExternalStatus>
  reconAttempt: ReconAttemptNode
  reconAttemptId: Scalars['UUID']['output']
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobRejectionFieldIssueNode = {
  __typename?: 'JobRejectionFieldIssueNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  field: FieldNode
  fieldId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  jobRejection: JobRejectionNode
  jobRejectionId: Scalars['UUID']['output']
  type: FieldIssueType
}

export type JobRejectionFieldIssueNodeConnection = {
  __typename?: 'JobRejectionFieldIssueNodeConnection'
  edges: Array<JobRejectionFieldIssueNodeEdge>
}

export type JobRejectionFieldIssueNodeEdge = {
  __typename?: 'JobRejectionFieldIssueNodeEdge'
  node: JobRejectionFieldIssueNode
}

export type JobRejectionNode = {
  __typename?: 'JobRejectionNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  jobRejectionFieldIssues: JobRejectionFieldIssueNodeConnection
  nextStatus: JobStatus
  note?: Maybe<NoteNode>
  previousStatus?: Maybe<JobStatus>
  reason: Scalars['String']['output']
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobRejectionNodeConnection = {
  __typename?: 'JobRejectionNodeConnection'
  edges: Array<JobRejectionNodeEdge>
}

export type JobRejectionNodeEdge = {
  __typename?: 'JobRejectionNodeEdge'
  node: JobRejectionNode
}

export type JobRequestReprocessNode = {
  __typename?: 'JobRequestReprocessNode'
  additionalNotes?: Maybe<Scalars['String']['output']>
  autoPostAfterFixing: Scalars['Boolean']['output']
  correctedInfo: Scalars['String']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  newStatus?: Maybe<JobExternalStatus>
  note?: Maybe<NoteNode>
  oldStatus?: Maybe<JobExternalStatus>
  reason: JobRequestReprocessReason
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export enum JobRequestReprocessReason {
  Debtor = 'DEBTOR',
  DeleteFieldsForAChargeCode = 'DELETE_FIELDS_FOR_A_CHARGE_CODE',
  Distribute = 'DISTRIBUTE',
  ExchangeRateAlreadyUpdated = 'EXCHANGE_RATE_ALREADY_UPDATED',
  FileNumber = 'FILE_NUMBER',
  InvoiceNumber = 'INVOICE_NUMBER',
  Others = 'OTHERS',
  PostWithinThreshold = 'POST_WITHIN_THRESHOLD',
  SpecifyAmount = 'SPECIFY_AMOUNT',
  UseCorrectShipment = 'USE_CORRECT_SHIPMENT',
  UseCorrectTerms = 'USE_CORRECT_TERMS',
  UseDifferentChargeCode = 'USE_DIFFERENT_CHARGE_CODE',
  VendorOrCreditor = 'VENDOR_OR_CREDITOR',
}

export type JobSearchParameters = {
  __typename?: 'JobSearchParameters'
  filters: Array<JobFilterSettingNode>
  queryString: Scalars['String']['output']
}

export type JobSendToCwNode = {
  __typename?: 'JobSendToCWNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  host?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  jsonJobData?: Maybe<Scalars['JSONString']['output']>
  newChainioConsolidation?: Maybe<ChainIoConsolidationNode>
  newChainioConsolidationId?: Maybe<Scalars['UUID']['output']>
  newChainioCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  newChainioCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  newChainioShipment?: Maybe<ChainIoShipmentNode>
  newChainioShipmentId?: Maybe<Scalars['UUID']['output']>
  note?: Maybe<NoteNode>
  oldChainioConsolidation?: Maybe<ChainIoConsolidationNode>
  oldChainioConsolidationId?: Maybe<Scalars['UUID']['output']>
  oldChainioCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  oldChainioCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  oldChainioShipment?: Maybe<ChainIoShipmentNode>
  oldChainioShipmentId?: Maybe<Scalars['UUID']['output']>
  rawCargowiseInbound?: Maybe<RawCargowiseInboundNode>
  rawCargowiseInboundId?: Maybe<Scalars['UUID']['output']>
  success: Scalars['Boolean']['output']
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobShipmentOpsCreateNode = {
  __typename?: 'JobShipmentOpsCreateNode'
  consolReferenceNumber?: Maybe<Scalars['String']['output']>
  consolSuccessMessage?: Maybe<Scalars['String']['output']>
  consolWarningMessage?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  host?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  importDecReferenceNumber?: Maybe<Scalars['String']['output']>
  importDecSuccessMessage?: Maybe<Scalars['String']['output']>
  importDecWarningMessage?: Maybe<Scalars['String']['output']>
  job: JobNode
  jobId: Scalars['UUID']['output']
  jsonDataSent?: Maybe<Scalars['JSONString']['output']>
  jsonProcessingLog?: Maybe<Scalars['JSONString']['output']>
  note?: Maybe<NoteNode>
  rawCargowiseInbound?: Maybe<RawCargowiseInboundNode>
  rawCargowiseInboundId?: Maybe<Scalars['UUID']['output']>
  shipmentReferenceNumber?: Maybe<Scalars['String']['output']>
  shipmentSuccessMessage?: Maybe<Scalars['String']['output']>
  shipmentWarningMessage?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export enum JobStatus {
  Confirmation = 'CONFIRMATION',
  Deleted = 'DELETED',
  Done = 'DONE',
  Export = 'EXPORT',
  InProgress = 'IN_PROGRESS',
  Qa = 'QA',
  Review = 'REVIEW',
  Todo = 'TODO',
}

export type JobStatusChangeNode = {
  __typename?: 'JobStatusChangeNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  nextStatus: JobStatus
  note?: Maybe<NoteNode>
  previousStatus?: Maybe<JobStatus>
  type: Scalars['String']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type JobTableCellNode = {
  __typename?: 'JobTableCellNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  document: DocumentNode
  documentId: Scalars['UUID']['output']
  field: FieldNode
  fieldId: Scalars['UUID']['output']
  height?: Maybe<Scalars['Float']['output']>
  id: Scalars['UUID']['output']
  jobTableLineItem: JobTableLineItemNode
  jobTableLineItemId: Scalars['UUID']['output']
  left?: Maybe<Scalars['Float']['output']>
  top?: Maybe<Scalars['Float']['output']>
  value: Scalars['String']['output']
  width?: Maybe<Scalars['Float']['output']>
}

export type JobTableCellNodeConnection = {
  __typename?: 'JobTableCellNodeConnection'
  edges: Array<JobTableCellNodeEdge>
}

export type JobTableCellNodeEdge = {
  __typename?: 'JobTableCellNodeEdge'
  node: JobTableCellNode
}

export type JobTableColumnNode = {
  __typename?: 'JobTableColumnNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  field: FieldNode
  fieldId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  index: Scalars['Int']['output']
  jobTable: JobTableNode
  jobTableId: Scalars['UUID']['output']
}

export type JobTableColumnNodeConnection = {
  __typename?: 'JobTableColumnNodeConnection'
  edges: Array<JobTableColumnNodeEdge>
}

export type JobTableColumnNodeEdge = {
  __typename?: 'JobTableColumnNodeEdge'
  node: JobTableColumnNode
}

export type JobTableLineItemNode = {
  __typename?: 'JobTableLineItemNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  jobTable: JobTableNode
  jobTableCells: JobTableCellNodeConnection
  jobTableId: Scalars['UUID']['output']
}

export type JobTableLineItemNodeConnection = {
  __typename?: 'JobTableLineItemNodeConnection'
  edges: Array<JobTableLineItemNodeEdge>
}

export type JobTableLineItemNodeEdge = {
  __typename?: 'JobTableLineItemNodeEdge'
  node: JobTableLineItemNode
}

export type JobTableNode = {
  __typename?: 'JobTableNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  fieldGroup: FieldGroupNode
  fieldGroupId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  jobTableColumns: JobTableColumnNodeConnection
  jobTableLineItems: JobTableLineItemNodeConnection
}

export type JobTemplateDocumentTypeNode = {
  __typename?: 'JobTemplateDocumentTypeNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  docTypeId: Scalars['UUID']['output']
  documentType: DocumentTypeNode
  id: Scalars['UUID']['output']
  jobTemplate: JobTemplateNode
  jobTemplateId: Scalars['UUID']['output']
}

export type JobTemplateDocumentTypeNodeConnection = {
  __typename?: 'JobTemplateDocumentTypeNodeConnection'
  edges: Array<JobTemplateDocumentTypeNodeEdge>
}

export type JobTemplateDocumentTypeNodeEdge = {
  __typename?: 'JobTemplateDocumentTypeNodeEdge'
  node: JobTemplateDocumentTypeNode
}

export type JobTemplateExportColumnNode = {
  __typename?: 'JobTemplateExportColumnNode'
  columnName?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  field?: Maybe<FieldNode>
  fieldId?: Maybe<Scalars['UUID']['output']>
  fixedValue?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  jobTemplateExportSheet: JobTemplateExportSheetNode
  jobTemplateExportSheetId: Scalars['UUID']['output']
  order: Scalars['Int']['output']
}

export type JobTemplateExportColumnNodeConnection = {
  __typename?: 'JobTemplateExportColumnNodeConnection'
  edges: Array<JobTemplateExportColumnNodeEdge>
}

export type JobTemplateExportColumnNodeEdge = {
  __typename?: 'JobTemplateExportColumnNodeEdge'
  node: JobTemplateExportColumnNode
}

export type JobTemplateExportNode = {
  __typename?: 'JobTemplateExportNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  jobTemplate: JobTemplateNode
  jobTemplateExportSheets: JobTemplateExportSheetNodeConnection
  jobTemplateExportType: JobTemplateExportType
  jobTemplateId: Scalars['UUID']['output']
}

export type JobTemplateExportSheetNode = {
  __typename?: 'JobTemplateExportSheetNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  displayColumnHeader: Scalars['Boolean']['output']
  fieldGroup?: Maybe<FieldGroupNode>
  fieldGroupId?: Maybe<Scalars['UUID']['output']>
  id: Scalars['UUID']['output']
  jobTemplateExport: JobTemplateExportNode
  jobTemplateExportColumns: JobTemplateExportColumnNodeConnection
  jobTemplateExportId: Scalars['UUID']['output']
  order: Scalars['Int']['output']
}

export type JobTemplateExportSheetNodeConnection = {
  __typename?: 'JobTemplateExportSheetNodeConnection'
  edges: Array<JobTemplateExportSheetNodeEdge>
}

export type JobTemplateExportSheetNodeEdge = {
  __typename?: 'JobTemplateExportSheetNodeEdge'
  node: JobTemplateExportSheetNode
}

export enum JobTemplateExportType {
  JoinMetadataLineItem = 'JOIN_METADATA_LINE_ITEM',
  SplitMetadataLineItem = 'SPLIT_METADATA_LINE_ITEM',
  Standard = 'STANDARD',
}

export type JobTemplateNode = {
  __typename?: 'JobTemplateNode'
  adminDisabled: Scalars['Boolean']['output']
  apiPartner?: Maybe<ApiPartnerInterface>
  apiPartnerId?: Maybe<Scalars['UUID']['output']>
  autoPostEnabled?: Maybe<Scalars['Boolean']['output']>
  autoReconEnabled?: Maybe<Scalars['Boolean']['output']>
  autoclassifyDocType: Scalars['Boolean']['output']
  autofillHyperparameters: AutofillHyperParameterNodeConnection
  cargowiseConfig?: Maybe<CargowiseConfigNode>
  cargowiseConfigId?: Maybe<Scalars['UUID']['output']>
  company: CompanyNode
  companyId: Scalars['UUID']['output']
  credentialId?: Maybe<Scalars['UUID']['output']>
  credentials?: Maybe<CredentialNode>
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  defaultExternalAssignee?: Maybe<UserNode>
  defaultExternalAssigneeId?: Maybe<Scalars['UUID']['output']>
  description: Scalars['String']['output']
  documentTypes: DocumentTypeNodeConnection
  id: Scalars['UUID']['output']
  jobTemplateDocumentTypes: JobTemplateDocumentTypeNodeConnection
  jobTemplateExport?: Maybe<JobTemplateExportNode>
  jobs: JobNodeConnection
  mainTabEnabled?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  reconThresholdSettings: ReconThresholdSettingNodeConnection
  reconType: JobTemplateReconType
  requireEdocsPushPull?: Maybe<Scalars['Boolean']['output']>
  requireExternalAssignee?: Maybe<Scalars['Boolean']['output']>
  requireShowReconToCustomer?: Maybe<Scalars['Boolean']['output']>
  shipmentOpsTypes: Array<ShipmentOp>
  showPostButton?: Maybe<Scalars['Boolean']['output']>
  slaTime: Scalars['Int']['output']
  subtotalsRowEnabled?: Maybe<Scalars['Boolean']['output']>
}

export type JobTemplateNodeConnection = {
  __typename?: 'JobTemplateNodeConnection'
  edges: Array<JobTemplateNodeEdge>
}

export type JobTemplateNodeEdge = {
  __typename?: 'JobTemplateNodeEdge'
  node: JobTemplateNode
}

export enum JobTemplateReconType {
  Ap = 'AP',
  ArrivalNotice = 'ARRIVAL_NOTICE',
  BFile = 'B_FILE',
  None = 'NONE',
  Soa = 'SOA',
}

export type JoinClauseNode = {
  __typename?: 'JoinClauseNode'
  condition: Scalars['String']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  fromClause: Scalars['String']['output']
  id: Scalars['UUID']['output']
  isOuter: Scalars['Boolean']['output']
  parentQueryId?: Maybe<Scalars['UUID']['output']>
  query?: Maybe<CustomQueryNode>
}

export type JoinClauseNodeConnection = {
  __typename?: 'JoinClauseNodeConnection'
  edges: Array<JoinClauseNodeEdge>
}

export type JoinClauseNodeEdge = {
  __typename?: 'JoinClauseNodeEdge'
  node: JoinClauseNode
}

export type LineGraphDataGroup = {
  __typename?: 'LineGraphDataGroup'
  group: Scalars['String']['output']
  points: Array<LineGraphDataPoint>
}

export type LineGraphDataPoint = {
  __typename?: 'LineGraphDataPoint'
  period: Scalars['String']['output']
  value?: Maybe<Scalars['Decimal']['output']>
}

export type LineGraphDataResult = {
  __typename?: 'LineGraphDataResult'
  columnFilterFields?: Maybe<Array<Scalars['String']['output']>>
  columnName: Scalars['String']['output']
  linkedTab?: Maybe<DashboardReference>
  otherGroups?: Maybe<Array<Scalars['String']['output']>>
  periodField: Scalars['String']['output']
  periodLabel: Scalars['String']['output']
  periodType: PeriodType
  results: Array<LineGraphDataGroup>
  valueLabel: Scalars['String']['output']
}

export enum ManualProduct {
  Manual = 'MANUAL',
  Product = 'PRODUCT',
}

export type MarkNotificationsRead = {
  __typename?: 'MarkNotificationsRead'
  ok: Scalars['Boolean']['output']
}

export type MatchCriteriaFindShipmentReconResultPair = {
  __typename?: 'MatchCriteriaFindShipmentReconResultPair'
  checkShipmentInfoMatchCriteria: CheckShipmentInfoMatchCriteriaNode
  successfulFindShipmentReconResult?: Maybe<FindShipmentReconResultNode>
}

export enum MatchingCriteriaType {
  ChargeCodeOnly = 'CHARGE_CODE_ONLY',
  ChargeCodeVendorInvoiceNumber = 'CHARGE_CODE_VENDOR_INVOICE_NUMBER',
  NonStrictVendorInvoiceNumber = 'NON_STRICT_VENDOR_INVOICE_NUMBER',
  VendorInvoiceNumber = 'VENDOR_INVOICE_NUMBER',
}

export type MatchingCriteriaTypeEnum = {
  __typename?: 'MatchingCriteriaTypeEnum'
  isFallback: Scalars['Boolean']['output']
  value: MatchingCriteriaType
}

export type MatchingCriteriaTypeWithFallback = FallbackValue | MatchingCriteriaTypeEnum

export type MeasurementUnitInput = {
  targetUnit?: InputMaybe<Scalars['String']['input']>
  type: UnitType
}

export type MeasurementUnitOutput = {
  __typename?: 'MeasurementUnitOutput'
  targetUnit?: Maybe<Scalars['String']['output']>
  type: UnitType
}

export type MergeTable = {
  __typename?: 'MergeTable'
  id: Scalars['String']['output']
}

export enum MetadataReconResultKey {
  Branch = 'BRANCH',
  DeliveryDropMode = 'DELIVERY_DROP_MODE',
  Department = 'DEPARTMENT',
  OpsStaffCode = 'OPS_STAFF_CODE',
  OpsStaffName = 'OPS_STAFF_NAME',
  PickupDropMode = 'PICKUP_DROP_MODE',
  SalesStaffCode = 'SALES_STAFF_CODE',
  SalesStaffName = 'SALES_STAFF_NAME',
}

export type MetadataReconResultKeyEnum = {
  __typename?: 'MetadataReconResultKeyEnum'
  isFallback: Scalars['Boolean']['output']
  value: MetadataReconResultKey
}

export type MetadataReconResultKeyWithFallback = FallbackValue | MetadataReconResultKeyEnum

export type MetadataReconResultNode = ReconResultInterface & {
  __typename?: 'MetadataReconResultNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  expectedValue?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceValue?: Maybe<Scalars['String']['output']>
  job: JobNode
  jobId: Scalars['UUID']['output']
  key: MetadataReconResultKeyWithFallback
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export enum Milestone {
  Arrived = 'ARRIVED',
  Awaiting = 'AWAITING',
  Closed = 'CLOSED',
  InTransit = 'IN_TRANSIT',
  Na = 'NA',
}

export type MinimalCompanyNode = {
  __typename?: 'MinimalCompanyNode'
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
}

export type MoveJobToInProgress = {
  __typename?: 'MoveJobToInProgress'
  ok: Scalars['Boolean']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  activateShipperFacingUser?: Maybe<Scalars['Void']['output']>
  addDocumentTable: AddDocumentTable
  approveJobReconForPosting: Scalars['Boolean']['output']
  approveShipperFacingPaywallUserRequest?: Maybe<Scalars['Void']['output']>
  associatePagesToJob: AssociatePagesToJob
  authProfileMetadataUpdate: AuthProfileUpdate
  batchAttachDocumentToShipment: BatchAttachDocumentToShipment
  batchExportJobToCw: BatchExportJobToCw
  batchFetchShipmentInfoFromCw: BatchFetchShipmentInfoFromCw
  batchPullEDocsFromCw: BatchPullEDocsFromCw
  batchPushEDocsToCw: BatchPushEDocsToCw
  blockTask: BlockTask
  bulkDeleteEDocs: BulkDeleteEDocs
  bulkUpdateCards: Array<CardNode>
  bulkUpdateJobDetails: BulkUpdateJobDetails
  bulkUpdateJobStatus: BulkUpdateJobStatus
  bulkUpdateJobTaskId: BulkUpdateJobTaskId
  bulkUpdateUserDashboardWhitelist: Scalars['Boolean']['output']
  confirmJobs: ConfirmJobs
  copyJob: CopyJob
  copyRowsToOtherDocuments: CopyRowsToDocumentsTemplateMatch
  copyTableFromOtherDocument: CopyTableFromOtherDocument
  copyTableToDocuments: CopyTableToDocuments
  createApiPartner: CreateApiPartner
  createBatchReconNote: CreateBatchReconNote
  createCard: CardNode
  createCargowiseConfig: CreateCargowiseConfig
  createChargeCodeAndOverrides: CreateChargeCodeAndOverrides
  createChargeCodes: CreateChargeCodes
  createChargeVendorAndOverrides: CreateChargeVendorAndOverrides
  createChargeVendorWithChargeCodes: CreateChargeVendorWithChargeCodes
  createCompany: CreateCompany
  createCredential: Scalars['Boolean']['output']
  createCustomDatabase: CreateCustomDatabase
  createCustomDatabaseType: CreateCustomDatabaseType
  createDashboard: DashboardNode
  createDocument: CreateDocument
  createDocumentPack: Scalars['String']['output']
  createDocumentPackByDoctype: Scalars['String']['output']
  createEmptyJob: CreateEmptyJob
  createForwarderClientUser: Scalars['Boolean']['output']
  createJob: CreateJob
  createNote: CreateNote
  createPaywallUserRequest?: Maybe<Scalars['Void']['output']>
  createReconJobNote?: Maybe<Scalars['Void']['output']>
  createReconNote: CreateReconNote
  createShipperFacingOrganization?: Maybe<ShipperFacingOrganizationNode>
  createTask: CreateTask
  createTaskNote: CreateTaskNote
  createTax: CreateTax
  createUser: Scalars['String']['output']
  deleteApiEDoc: DeleteApiEDoc
  deleteApiPartner: DeleteApiPartner
  deleteBatchReconNote: DeleteBatchReconNote
  deleteCard: Scalars['Boolean']['output']
  deleteCargowiseConfig: DeleteCargowiseConfig
  deleteChargeCodes: DeleteChargeCodes
  deleteChargeCodesAndOverrides: DeleteChargeCodesAndOverrides
  deleteChargeVendor: DeleteChargeVendor
  deleteCompany: DeleteCompany
  deleteCredential: Scalars['Boolean']['output']
  deleteCustomDatabase: DeleteCustomDatabase
  deleteCustomDatabaseType: DeleteCustomDatabaseType
  deleteDashboard: Scalars['Boolean']['output']
  deleteDashboardIam: Scalars['Boolean']['output']
  deleteDocumentTable: DeleteDocumentTable
  deleteDocumentTablesInGroup: DeleteDocumentTablesInGroup
  deleteEDoc: DeleteEDoc
  deleteFilePage: DeleteFilePage
  deleteFilePages: DeleteFilePages
  deleteForwarderClientUser: Scalars['Boolean']['output']
  deleteJob: DeleteJob
  deleteJobTemplate: DeleteJobTemplate
  deleteReconNote: DeleteReconNote
  deleteTask: DeleteTask
  deleteTaskNote: DeleteTaskNote
  deleteTax: DeleteTax
  deleteUser: Scalars['Boolean']['output']
  downloadDashboardEDoc: DownloadDashboardEDoc
  duplicateDashboard: DashboardNode
  eadaptorColdStart: Scalars['Boolean']['output']
  editChargeCodeAndOverrides: EditChargeCodeAndOverrides
  editChargeCodes: EditChargeCodes
  editChargeVendor: EditChargeVendor
  editChargeVendorAndOverrides: EditChargeVendorAndOverrides
  editCompany: EditCompany
  editCustomDatabase: EditCustomDatabase
  editCustomDatabaseType: EditCustomDatabaseType
  editJob: EditJob
  editOwnProfile: EditOwnProfile
  editShipperFacingOrganization?: Maybe<Scalars['Void']['output']>
  editShipperFacingUserRequest?: Maybe<Scalars['Void']['output']>
  editTax: EditTax
  exportApReconAttemptToCw: ExportApReconAttemptToCw
  exportEDocumentsToApiPartner: ExportEDocumentsToApiPartner
  exportExploreTable: ExportReportStatus
  exportJobToApiPartner: ExportJobToApiPartner
  exportJobToCw: ExportJobToCw
  exportPivotTableExcel: Scalars['String']['output']
  exportReconJobList: Scalars['String']['output']
  fetchChargeDetailsFromCw: FetchChargeDetailsFromTms
  fetchChargeDetailsFromTms: FetchChargeDetailsFromTms
  fetchDashboardEDocs: FetchDashboardEDocs
  importDashboard: DashboardNode
  importEDocumentsFromApiPartner: ImportEDocumentsFromApiPartner
  indexQueueIntoSnowflake: Scalars['Boolean']['output']
  ingestAutofillFiles: IngestAutofillFiles
  ingestFiles: IngestFiles
  jobReconRequestReprocess?: Maybe<Scalars['Void']['output']>
  jobReconRequestReprocessByInvoice?: Maybe<Scalars['Void']['output']>
  jobRequestReprocess?: Maybe<Scalars['Void']['output']>
  markNotificationsRead: MarkNotificationsRead
  mergeTable: MergeTable
  moveJobExternalStatusToDone?: Maybe<Scalars['Void']['output']>
  moveJobReconToDone: Scalars['Boolean']['output']
  moveJobToInProgress: MoveJobToInProgress
  postToCw: PostToCw
  pullEDocsFromCw: PullEDocsFromCw
  pushEDocsToCw: PushEDocsToCw
  qaJob: QaJob
  reconPostToCw: PostToCw
  reconcileApInvoiceJob: ReconcileApInvoiceJob
  reconcileArrivalNoticeJob: ReconcileArrivalNoticeJob
  reconcileSoaJob: ReconcileSoaJob
  recreateReindexMirror: RecreateReindexMirror
  redoReconcileUnmatchedReconAttempts: Scalars['Boolean']['output']
  rejectJob: RejectJob
  rejectShipperFacingPaywallUserRequest?: Maybe<Scalars['Void']['output']>
  resetShipperFacingUserPassword: ResetPasswordResponse
  resetUserPassword: Scalars['String']['output']
  resetUserReconJobListColumns: Array<ReconJobListColumn>
  restoreFilePages: RestoreFilePages
  restoreJob: RestoreJob
  reviewJob: ReviewJob
  rotateFilePage: RotateFilePage
  saveDashboardDefaultFilters?: Maybe<Scalars['Void']['output']>
  saveDocumentFields: SaveDocumentFields
  saveDocumentTable: SaveDocumentTable
  saveExploreTableDefaultColumns?: Maybe<Scalars['Void']['output']>
  saveFiltersAndUnitSettings: Scalars['Boolean']['output']
  saveJobAndDocumentTables: SaveJobAndDocumentTables
  saveJobTable: SaveJobTable
  saveJobTemplateExportFormat: SaveJobTemplateExportFormat
  saveReconAttempt: SaveReconAttempt
  saveReconAttemptsByBatch: SaveReconAttemptsByBatch
  sendBFileToCw: SendBFileToCw
  sendManualResetPasswordEmail?: Maybe<Scalars['Void']['output']>
  sendShipmentOpsToCw: SendShipmentOpsToCw
  setJobManualProduct: SetJobManualProduct
  setJobOwner: SetJobOwner
  setJobQa: SetJobQa
  shipperFacingContainerSetDefaultColumns?: Maybe<Scalars['Void']['output']>
  shipperFacingExportContainers: ExportReportStatus
  shipperFacingExportShipments: ExportReportStatus
  shipperFacingShipmentSetDefaultColumns?: Maybe<Scalars['Void']['output']>
  shipperFacingShipmentSetDefaultFilters?: Maybe<Scalars['Void']['output']>
  starTask: StarTask
  syncCargowiseDb: Scalars['Boolean']['output']
  syncCauldronDb: Scalars['Boolean']['output']
  syncSearchableRecords: Scalars['Boolean']['output']
  syncUserModelWithDashboard: Scalars['Boolean']['output']
  testApiPartner: TestApiPartner
  testCargowiseConfig: TestCargowiseConfig
  testCredential: Scalars['Boolean']['output']
  tiffToPng: TiffToPng
  unmergeDocumentTable: UnmergeDocumentTable
  updateApiPartner: UpdateApiPartner
  updateBatchReconResolutionStatusAndCreateNote: UpdateBatchReconResolutionStatusAndCreateNote
  updateCard: CardNode
  updateCargowiseConfig: UpdateCargowiseConfig
  updateChargeQuantity: UpdateChargeQuantity
  updateCredential: CredentialNode
  updateDashboardBasicFilterFields: Scalars['Boolean']['output']
  updateDashboardCompanyUserIam: Scalars['Boolean']['output']
  updateDashboardIsDefault: Scalars['Boolean']['output']
  updateDashboardName: Scalars['Boolean']['output']
  updateDashboardProperties: Scalars['Boolean']['output']
  updateDashboardSelectors: Scalars['Boolean']['output']
  updateDocumentTablesOrder: UpdateDocumentTablesOrder
  updateEmbeddedDashboard: Scalars['Boolean']['output']
  updateForwarderClientUser: Scalars['Boolean']['output']
  updateJobExternalAssignee?: Maybe<Scalars['Void']['output']>
  updateJobReconExternalAssignee: Scalars['Boolean']['output']
  updateReconAttemptStatus: UpdateReconAttemptStatus
  updateReconDashboardUserFilter: UpdateReconDashboardUserFilter
  updateRisrAndRmc: Scalars['Boolean']['output']
  updateTask: UpdateTask
  updateTaskCompany: UpdateTaskCompany
  updateTaskStatus: UpdateTaskStatus
  updateTaskType: UpdateTaskType
  updateUser: Scalars['Boolean']['output']
  updateUserReconJobListColumns?: Maybe<Scalars['Void']['output']>
  uploadOriginalPdfsToS3: UploadOriginalPdFsToS3
  upsertDashboardIamRole: Scalars['Boolean']['output']
  upsertDocumentTableFieldGroup: UpsertDocumentTableFieldGroup
  upsertJobTemplate: UpsertJobTemplate
  upsertSeperatedChargeVendorsChargeCodes: UpsertSeperatedChargeVendorsChargeCodes
}

export type MutationActivateShipperFacingUserArgs = {
  email: Scalars['String']['input']
  freightForwarderCompanyId: Scalars['UUID']['input']
}

export type MutationAddDocumentTableArgs = {
  documentId: Scalars['UUID']['input']
  fieldGroupId: Scalars['UUID']['input']
}

export type MutationApproveJobReconForPostingArgs = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>
  reconAttemptId: Scalars['UUID']['input']
}

export type MutationApproveShipperFacingPaywallUserRequestArgs = {
  email: Scalars['String']['input']
  freightForwarderCompanyId: Scalars['UUID']['input']
  name: Scalars['String']['input']
  organizationId: Scalars['UUID']['input']
}

export type MutationAssociatePagesToJobArgs = {
  batchId: Scalars['UUID']['input']
  docTypeIds: Array<InputMaybe<Scalars['UUID']['input']>>
  filePageIds: Array<InputMaybe<Scalars['UUID']['input']>>
  jobId: Scalars['UUID']['input']
}

export type MutationAuthProfileMetadataUpdateArgs = {
  apiPartnerMetadata?: InputMaybe<Array<Scalars['String']['input']>>
  configMetadata?: InputMaybe<Array<Scalars['String']['input']>>
}

export type MutationBatchAttachDocumentToShipmentArgs = {
  documents: Array<InputMaybe<InputDocument>>
  jobId: Scalars['String']['input']
  jobTable?: InputMaybe<InputJobTable>
  newJobStatus?: InputMaybe<Scalars['String']['input']>
  validateFields: Scalars['Boolean']['input']
}

export type MutationBatchExportJobToCwArgs = {
  asLumpsum?: Scalars['Boolean']['input']
  disableSendDueDate?: Scalars['Boolean']['input']
  invoiceCwModuleKeys: Array<InputSoaCwModules>
  invoiceDates: Array<InputSoaDates>
  invoiceNumbers: Array<Scalars['String']['input']>
  jobId: Scalars['String']['input']
  overrideChargeDescription?: Scalars['Boolean']['input']
}

export type MutationBatchFetchShipmentInfoFromCwArgs = {
  jobId: Scalars['UUID']['input']
}

export type MutationBatchPullEDocsFromCwArgs = {
  chainIoConsolIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  chainIoCustomsDecIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  chainIoShipmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  dataTargetType: EDocsDataTargetType
  invoiceNumbers?: InputMaybe<Array<Scalars['String']['input']>>
  jobId: Scalars['UUID']['input']
}

export type MutationBatchPushEDocsToCwArgs = {
  dataTargetType: EDocsDataTargetType
  inputEDocuments: Array<InputEDocument>
  jobId: Scalars['UUID']['input']
  referenceNumbers: Array<Scalars['String']['input']>
}

export type MutationBlockTaskArgs = {
  blocked: Scalars['Boolean']['input']
  dateReceived?: InputMaybe<Scalars['String']['input']>
  taskId: Scalars['String']['input']
}

export type MutationBulkDeleteEDocsArgs = {
  eDocumentIds: Array<InputMaybe<Scalars['UUID']['input']>>
}

export type MutationBulkUpdateCardsArgs = {
  cardIds: Array<Scalars['UUID']['input']>
  inputCards: Array<InputCard>
}

export type MutationBulkUpdateJobDetailsArgs = {
  jobIds: Array<InputMaybe<Scalars['String']['input']>>
  manualProduct?: InputMaybe<Scalars['String']['input']>
  ownerId?: InputMaybe<Scalars['String']['input']>
  qaId?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
}

export type MutationBulkUpdateJobStatusArgs = {
  jobIds: Array<InputMaybe<Scalars['String']['input']>>
  status: Scalars['String']['input']
}

export type MutationBulkUpdateJobTaskIdArgs = {
  jobIds: Array<InputMaybe<Scalars['String']['input']>>
  taskId: Scalars['String']['input']
}

export type MutationBulkUpdateUserDashboardWhitelistArgs = {
  defaultDashboardWhitelist: Array<Scalars['String']['input']>
  userEmails: Array<Scalars['String']['input']>
}

export type MutationConfirmJobsArgs = {
  filename?: Scalars['String']['input']
  jobIds: Array<Scalars['UUID']['input']>
  proofUrl?: Scalars['String']['input']
}

export type MutationCopyJobArgs = {
  jobId: Scalars['String']['input']
}

export type MutationCopyRowsToOtherDocumentsArgs = {
  allDocIds: Array<InputMaybe<Scalars['UUID']['input']>>
  fromDocId: Scalars['UUID']['input']
  tables: Array<InputMaybe<InputDocumentTableSkeleton>>
  toDocIds: Array<InputMaybe<Scalars['UUID']['input']>>
}

export type MutationCopyTableFromOtherDocumentArgs = {
  fromDocId: Scalars['UUID']['input']
  toDocIds: Array<InputMaybe<Scalars['UUID']['input']>>
}

export type MutationCopyTableToDocumentsArgs = {
  tables: Array<InputMaybe<InputDocumentTableSkeleton>>
  toDocIds: Array<InputMaybe<Scalars['UUID']['input']>>
}

export type MutationCreateApiPartnerArgs = {
  inputApiPartner: InputApiPartner
}

export type MutationCreateBatchReconNoteArgs = {
  content: Scalars['String']['input']
  jobId: Scalars['String']['input']
  noteType: Scalars['String']['input']
  reconAsyncBatchId: Scalars['String']['input']
}

export type MutationCreateCardArgs = {
  dashboardId: Scalars['UUID']['input']
  inputCard: InputCard
  inputPivotTable?: InputMaybe<InputPivotTable>
}

export type MutationCreateCargowiseConfigArgs = {
  inputCwConfig: InputCargowiseConfig
}

export type MutationCreateChargeCodeAndOverridesArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  code: Scalars['String']['input']
  companyId: Scalars['UUID']['input']
  description: Scalars['String']['input']
  taxId?: InputMaybe<Scalars['UUID']['input']>
  vendorsAndDescriptions: Array<InputVendorAndDesc>
}

export type MutationCreateChargeCodesArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  code: Scalars['String']['input']
  companyId: Scalars['UUID']['input']
  taxId?: InputMaybe<Scalars['UUID']['input']>
  vendorsAndDescriptions: Array<InputVendorAndDesc>
}

export type MutationCreateChargeVendorAndOverridesArgs = {
  apiPartnerId: Scalars['UUID']['input']
  chargeCodeOverrides: Array<InputChargeCode>
  code: Scalars['String']['input']
  companyId: Scalars['UUID']['input']
  name: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type MutationCreateChargeVendorWithChargeCodesArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  chargeCodes: Array<InputChargeCode>
  code: Scalars['String']['input']
  companyId: Scalars['UUID']['input']
  name: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type MutationCreateCompanyArgs = {
  name: Scalars['String']['input']
}

export type MutationCreateCredentialArgs = {
  apiEndpoint?: InputMaybe<Scalars['String']['input']>
  hostOverride?: InputMaybe<Scalars['String']['input']>
  jobTemplateIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  password: Scalars['String']['input']
  username: Scalars['String']['input']
  verifySsl?: Scalars['Boolean']['input']
  workflow: Scalars['String']['input']
}

export type MutationCreateCustomDatabaseArgs = {
  apiPartnerId: Scalars['UUID']['input']
  inputCustomDatabase: InputCustomDatabase
}

export type MutationCreateCustomDatabaseTypeArgs = {
  inputCustomDatabaseType: InputCustomDatabaseType
}

export type MutationCreateDashboardArgs = {
  iframeOverrideUrl?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type MutationCreateDocumentArgs = {
  batchId: Scalars['UUID']['input']
  documentTypeId: Scalars['UUID']['input']
  filePageId: Scalars['UUID']['input']
  jobId: Scalars['UUID']['input']
}

export type MutationCreateDocumentPackArgs = {
  jobId: Scalars['UUID']['input']
}

export type MutationCreateDocumentPackByDoctypeArgs = {
  jobId: Scalars['UUID']['input']
}

export type MutationCreateEmptyJobArgs = {
  clientReferenceNo: Scalars['String']['input']
  dateReceived?: InputMaybe<Scalars['String']['input']>
  jobName: Scalars['String']['input']
  jobTemplateId: Scalars['String']['input']
  manualProduct: Scalars['String']['input']
  message: Scalars['String']['input']
  ownerId?: InputMaybe<Scalars['String']['input']>
  qaId?: InputMaybe<Scalars['String']['input']>
  taskId?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateForwarderClientUserArgs = {
  inputUser: InputUser
}

export type MutationCreateJobArgs = {
  batchId: Scalars['UUID']['input']
  dateReceived?: InputMaybe<Scalars['String']['input']>
  docTypeIds: Array<InputMaybe<Scalars['UUID']['input']>>
  filePageIds: Array<InputMaybe<Scalars['UUID']['input']>>
  jobName: Scalars['String']['input']
  jobTemplateId: Scalars['UUID']['input']
  ownerId?: InputMaybe<Scalars['UUID']['input']>
  qaId?: InputMaybe<Scalars['UUID']['input']>
  taskId: Scalars['UUID']['input']
}

export type MutationCreateNoteArgs = {
  content: Scalars['String']['input']
  jobId: Scalars['String']['input']
  noteType: NoteType
}

export type MutationCreatePaywallUserRequestArgs = {
  companyName: Scalars['String']['input']
  email: Scalars['String']['input']
  feature: PaywallFeature
  freightForwarderCompanyId: Scalars['UUID']['input']
  freightForwarderCompanyName: Scalars['String']['input']
  freightForwarderCompanySlug: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type MutationCreateReconJobNoteArgs = {
  content: Scalars['String']['input']
  invoiceNumber?: InputMaybe<Scalars['String']['input']>
  jobId: Scalars['UUID']['input']
  reconAttemptId?: InputMaybe<Scalars['UUID']['input']>
  source: NoteSource
}

export type MutationCreateReconNoteArgs = {
  content: Scalars['String']['input']
  jobId: Scalars['UUID']['input']
  reconAttemptId: Scalars['UUID']['input']
  source: NoteSource
}

export type MutationCreateShipperFacingOrganizationArgs = {
  domainNames?: InputMaybe<Array<Scalars['String']['input']>>
  freightForwarderCompanyId: Scalars['UUID']['input']
  organizationCodes: Array<Scalars['String']['input']>
  organizationName: Scalars['String']['input']
}

export type MutationCreateTaskArgs = {
  blocked: Scalars['Boolean']['input']
  companyId: Scalars['String']['input']
  dateReceived: Scalars['String']['input']
  message: Scalars['String']['input']
  starred: Scalars['Boolean']['input']
  status: Scalars['String']['input']
  taskReferenceId: Scalars['String']['input']
  taskType: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type MutationCreateTaskNoteArgs = {
  content: Scalars['String']['input']
  taskId: Scalars['String']['input']
}

export type MutationCreateTaxArgs = {
  taxDetails: InputTaxDetails
}

export type MutationCreateUserArgs = {
  inputUser: InputUser
}

export type MutationDeleteApiEDocArgs = {
  apiEDocumentId: Scalars['UUID']['input']
}

export type MutationDeleteApiPartnerArgs = {
  apiPartnerId: Scalars['UUID']['input']
}

export type MutationDeleteBatchReconNoteArgs = {
  batchReconNoteId: Scalars['String']['input']
}

export type MutationDeleteCardArgs = {
  cardId: Scalars['UUID']['input']
}

export type MutationDeleteCargowiseConfigArgs = {
  cwConfigId: Scalars['UUID']['input']
}

export type MutationDeleteChargeCodesArgs = {
  chargeCodeIds: Array<InputMaybe<Scalars['UUID']['input']>>
}

export type MutationDeleteChargeCodesAndOverridesArgs = {
  chargeCodeIds: Array<Scalars['UUID']['input']>
}

export type MutationDeleteChargeVendorArgs = {
  chargeVendorId: Scalars['UUID']['input']
}

export type MutationDeleteCompanyArgs = {
  companyId: Scalars['String']['input']
}

export type MutationDeleteCredentialArgs = {
  credentialId: Scalars['UUID']['input']
}

export type MutationDeleteCustomDatabaseArgs = {
  customDatabaseId: Scalars['UUID']['input']
}

export type MutationDeleteCustomDatabaseTypeArgs = {
  customDatabaseTypeId: Scalars['UUID']['input']
}

export type MutationDeleteDashboardArgs = {
  dashboardId: Scalars['UUID']['input']
}

export type MutationDeleteDashboardIamArgs = {
  auth0Id: Scalars['String']['input']
  dashboardId: Scalars['UUID']['input']
}

export type MutationDeleteDocumentTableArgs = {
  documentTableId: Scalars['String']['input']
}

export type MutationDeleteDocumentTablesInGroupArgs = {
  documentTableId: Scalars['String']['input']
}

export type MutationDeleteEDocArgs = {
  eDocumentId: Scalars['UUID']['input']
}

export type MutationDeleteFilePageArgs = {
  filePageId: Scalars['String']['input']
  jobId: Scalars['String']['input']
}

export type MutationDeleteFilePagesArgs = {
  filePageIds: Array<InputMaybe<Scalars['String']['input']>>
}

export type MutationDeleteForwarderClientUserArgs = {
  auth0Id: Scalars['String']['input']
  companyId: Scalars['UUID']['input']
}

export type MutationDeleteJobArgs = {
  jobId: Scalars['String']['input']
}

export type MutationDeleteJobTemplateArgs = {
  jobTemplateId: Scalars['String']['input']
}

export type MutationDeleteReconNoteArgs = {
  reconNoteId: Scalars['UUID']['input']
}

export type MutationDeleteTaskArgs = {
  taskId: Scalars['String']['input']
}

export type MutationDeleteTaskNoteArgs = {
  taskNoteId: Scalars['String']['input']
}

export type MutationDeleteTaxArgs = {
  taxId: Scalars['UUID']['input']
}

export type MutationDeleteUserArgs = {
  auth0Id: Scalars['String']['input']
}

export type MutationDownloadDashboardEDocArgs = {
  id: Scalars['UUID']['input']
}

export type MutationDuplicateDashboardArgs = {
  dashboardId: Scalars['UUID']['input']
  name: Scalars['String']['input']
}

export type MutationEadaptorColdStartArgs = {
  apiPartnerName: Scalars['String']['input']
  eadaptorColdStartParams: InputEAdaptorColdStartParams
}

export type MutationEditChargeCodeAndOverridesArgs = {
  chargeCodeId: Scalars['UUID']['input']
  code: Scalars['String']['input']
  description: Scalars['String']['input']
  taxId?: InputMaybe<Scalars['UUID']['input']>
  vendorsAndDescriptions: Array<InputVendorAndDesc>
}

export type MutationEditChargeCodesArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  chargeCodeId: Scalars['UUID']['input']
  code: Scalars['String']['input']
  taxId?: InputMaybe<Scalars['UUID']['input']>
  vendorsAndDescriptions: Array<InputVendorAndDesc>
}

export type MutationEditChargeVendorArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  chargeCodes: Array<InputChargeCode>
  chargeVendorId: Scalars['UUID']['input']
  code: Scalars['String']['input']
  codesLimit?: InputMaybe<Scalars['Int']['input']>
  codesOffset?: InputMaybe<Scalars['Int']['input']>
  name: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type MutationEditChargeVendorAndOverridesArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  chargeCodeOverrides: Array<InputChargeCode>
  chargeVendorId: Scalars['UUID']['input']
  code: Scalars['String']['input']
  codesLimit?: InputMaybe<Scalars['Int']['input']>
  codesOffset?: InputMaybe<Scalars['Int']['input']>
  name: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type MutationEditCompanyArgs = {
  editCompanyRequest: EditCompanyRequest
}

export type MutationEditCustomDatabaseArgs = {
  customDatabaseId: Scalars['UUID']['input']
  inputCustomDatabase: InputCustomDatabase
}

export type MutationEditCustomDatabaseTypeArgs = {
  customDatabaseTypeId: Scalars['UUID']['input']
  inputCustomDatabaseType: InputCustomDatabaseType
}

export type MutationEditJobArgs = {
  clientReferenceNo?: InputMaybe<Scalars['String']['input']>
  dateReceived?: InputMaybe<Scalars['String']['input']>
  externalAssigneeId?: InputMaybe<Scalars['UUID']['input']>
  externalStatus?: InputMaybe<JobExternalStatus>
  jobId: Scalars['String']['input']
  jobTemplateId?: InputMaybe<Scalars['String']['input']>
  manualProduct?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  ownerId?: InputMaybe<Scalars['String']['input']>
  qaId?: InputMaybe<Scalars['UUID']['input']>
}

export type MutationEditOwnProfileArgs = {
  metabaseDashboardId: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type MutationEditShipperFacingOrganizationArgs = {
  domainNames?: InputMaybe<Array<Scalars['String']['input']>>
  freightForwarderCompanyId: Scalars['UUID']['input']
  organizationCodes: Array<Scalars['String']['input']>
  organizationName: Scalars['String']['input']
  shipperFacingOrganizationId: Scalars['UUID']['input']
}

export type MutationEditShipperFacingUserRequestArgs = {
  email: Scalars['String']['input']
  freightForwarderCompanyId: Scalars['UUID']['input']
  name: Scalars['String']['input']
  organizationId: Scalars['UUID']['input']
}

export type MutationEditTaxArgs = {
  taxDetails: InputTaxDetails
  taxId: Scalars['UUID']['input']
}

export type MutationExportApReconAttemptToCwArgs = {
  lumpsum?: InputMaybe<InputDocumentTable>
  postLineItems?: Scalars['Boolean']['input']
  reconAttemptId: Scalars['UUID']['input']
}

export type MutationExportEDocumentsToApiPartnerArgs = {
  dataTargetType: EDocsDataTargetType
  inputEDocuments: Array<InputEDocument>
  jobId: Scalars['UUID']['input']
}

export type MutationExportExploreTableArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  encodedFilters: EncodedFilters
  encodedHeaders?: InputMaybe<Scalars['String']['input']>
  exploreDataType: ExploreDataType
  filterQueries?: InputMaybe<Array<RawFilterQuery>>
  forceUseAsync?: Scalars['Boolean']['input']
  orderAscending?: InputMaybe<Scalars['Boolean']['input']>
  orderByColumn?: InputMaybe<Scalars['String']['input']>
  totalColumns: Scalars['Int']['input']
  totalDataCount: Scalars['Int']['input']
  tzString: Scalars['String']['input']
  unitSettings: UnitSetting
}

export type MutationExportJobToApiPartnerArgs = {
  exportType: ApiExportType
  jobId: Scalars['UUID']['input']
  lumpsum?: InputMaybe<InputDocumentTable>
  overrideChargeDescription?: Scalars['Boolean']['input']
}

export type MutationExportJobToCwArgs = {
  disableSendDueDate?: Scalars['Boolean']['input']
  jobId: Scalars['UUID']['input']
  lumpsum?: InputMaybe<InputDocumentTable>
  matchingCriteria?: MatchingCriteriaType
  overrideChargeDescription?: Scalars['Boolean']['input']
}

export type MutationExportPivotTableExcelArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  cardId: Scalars['UUID']['input']
  filterQuery?: InputMaybe<RawFilterQuery>
  queryVariables?: InputMaybe<Array<VariableMapping>>
  unitSettings: UnitSetting
}

export type MutationExportReconJobListArgs = {
  apiPartnerIds: Array<Scalars['UUID']['input']>
  columns: Array<Scalars['String']['input']>
  filters: Array<ReconJobListColumnColumnConditionInput>
  groupBatches?: Scalars['Boolean']['input']
  sortCol?: ReconJobListColumn
  sortDir?: SortDirection
  tab: ReconJobListTab
  timezone: Scalars['String']['input']
}

export type MutationFetchChargeDetailsFromCwArgs = {
  jobId: Scalars['UUID']['input']
}

export type MutationFetchChargeDetailsFromTmsArgs = {
  jobId: Scalars['UUID']['input']
}

export type MutationFetchDashboardEDocsArgs = {
  apiPartnerId: Scalars['UUID']['input']
  dataTargetType: EDocsDataTargetType
  tmsId: Scalars['String']['input']
}

export type MutationImportDashboardArgs = {
  dashboardJson: Scalars['String']['input']
  dashboardName?: InputMaybe<Scalars['String']['input']>
}

export type MutationImportEDocumentsFromApiPartnerArgs = {
  dataTargetType: EDocsDataTargetType
  jobId: Scalars['UUID']['input']
}

export type MutationIndexQueueIntoSnowflakeArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type MutationIngestAutofillFilesArgs = {
  batchId: Scalars['UUID']['input']
  files: Array<InputMaybe<GrapheneInputFile>>
  jobId: Scalars['UUID']['input']
}

export type MutationIngestFilesArgs = {
  batchId: Scalars['UUID']['input']
  companyId: Scalars['UUID']['input']
  emailSubject?: InputMaybe<Scalars['String']['input']>
  files: Array<InputMaybe<GrapheneInputFile>>
  message?: InputMaybe<Scalars['String']['input']>
}

export type MutationJobReconRequestReprocessArgs = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>
  autoPostAfterFixing: Scalars['Boolean']['input']
  correctedInformation: Scalars['String']['input']
  reason: JobRequestReprocessReason
  reconAttemptId: Scalars['UUID']['input']
  source: NoteSource
}

export type MutationJobReconRequestReprocessByInvoiceArgs = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>
  autoPostAfterFixing: Scalars['Boolean']['input']
  correctedInformation: Scalars['String']['input']
  reason: JobRequestReprocessReason
  reconInvoiceId: Scalars['UUID']['input']
  source: NoteSource
}

export type MutationJobRequestReprocessArgs = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>
  autoPostAfterFixing: Scalars['Boolean']['input']
  correctedInformation: Scalars['String']['input']
  jobId: Scalars['UUID']['input']
  reason: JobRequestReprocessReason
  source: NoteSource
}

export type MutationMergeTableArgs = {
  tableIds: Array<InputMaybe<Scalars['String']['input']>>
}

export type MutationMoveJobExternalStatusToDoneArgs = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>
  jobId: Scalars['UUID']['input']
  reason: JobMoveToDoneReason
  source: NoteSource
}

export type MutationMoveJobReconToDoneArgs = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>
  reason: JobMoveToDoneReason
  reconAttemptId: Scalars['UUID']['input']
  source: NoteSource
}

export type MutationMoveJobToInProgressArgs = {
  jobId: Scalars['String']['input']
}

export type MutationPostToCwArgs = {
  disableSendDueDate?: Scalars['Boolean']['input']
  jobId: Scalars['UUID']['input']
  lumpsum?: InputMaybe<InputDocumentTable>
  matchingCriteria?: MatchingCriteriaType
  overrideChargeDescription?: Scalars['Boolean']['input']
}

export type MutationPullEDocsFromCwArgs = {
  dataTargetType: EDocsDataTargetType
  jobId: Scalars['UUID']['input']
}

export type MutationPushEDocsToCwArgs = {
  dataTargetType: EDocsDataTargetType
  inputEDocuments: Array<InputEDocument>
  jobId: Scalars['UUID']['input']
  overrideReconAttemptId?: InputMaybe<Scalars['UUID']['input']>
  overrideReferenceNumber?: InputMaybe<Scalars['String']['input']>
}

export type MutationQaJobArgs = {
  fieldsMissingInformation: Array<InputMaybe<Scalars['String']['input']>>
  fieldsTypos: Array<InputMaybe<Scalars['String']['input']>>
  fieldsWrongInformation: Array<InputMaybe<Scalars['String']['input']>>
  jobId: Scalars['String']['input']
  otherInfo: Scalars['String']['input']
}

export type MutationReconPostToCwArgs = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>
  asLumpsum?: Scalars['Boolean']['input']
  disableSendDueDate?: Scalars['Boolean']['input']
  jobId: Scalars['UUID']['input']
  matchingCriteria?: MatchingCriteriaType
  overrideChargeDescription?: Scalars['Boolean']['input']
  source: NoteSource
}

export type MutationReconcileApInvoiceJobArgs = {
  disableSendDueDate?: Scalars['Boolean']['input']
  jobId: Scalars['String']['input']
  lumpsum?: InputMaybe<InputDocumentTable>
  matchingCriteria?: MatchingCriteriaType
  overrideChargeDescription?: Scalars['Boolean']['input']
}

export type MutationReconcileArrivalNoticeJobArgs = {
  jobId: Scalars['String']['input']
  lumpsum?: InputMaybe<InputDocumentTable>
  premiumRateDetails?: InputMaybe<InputReconPremiumRate>
}

export type MutationReconcileSoaJobArgs = {
  asLumpsum?: Scalars['Boolean']['input']
  disableSendDueDate?: Scalars['Boolean']['input']
  jobId: Scalars['UUID']['input']
  matchingCriteria?: InputMaybe<MatchingCriteriaType>
  overrideChargeDescription?: Scalars['Boolean']['input']
}

export type MutationRecreateReindexMirrorArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type MutationRedoReconcileUnmatchedReconAttemptsArgs = {
  companyIds?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type MutationRejectJobArgs = {
  fieldsMissingInformation: Array<InputMaybe<Scalars['String']['input']>>
  fieldsTypos: Array<InputMaybe<Scalars['String']['input']>>
  fieldsWrongInformation: Array<InputMaybe<Scalars['String']['input']>>
  issueDescription: Scalars['String']['input']
  jobId: Scalars['String']['input']
}

export type MutationRejectShipperFacingPaywallUserRequestArgs = {
  email: Scalars['String']['input']
  freightForwarderCompanyId: Scalars['UUID']['input']
}

export type MutationResetShipperFacingUserPasswordArgs = {
  token: Scalars['String']['input']
}

export type MutationResetUserPasswordArgs = {
  auth0Id: Scalars['String']['input']
}

export type MutationRestoreFilePagesArgs = {
  filePageIds: Array<InputMaybe<Scalars['String']['input']>>
}

export type MutationRestoreJobArgs = {
  jobId: Scalars['String']['input']
}

export type MutationReviewJobArgs = {
  jobId: Scalars['String']['input']
}

export type MutationRotateFilePageArgs = {
  angle: Scalars['Float']['input']
  filePageId: Scalars['String']['input']
}

export type MutationSaveDashboardDefaultFiltersArgs = {
  dashboardId: Scalars['UUID']['input']
  filterQuery: Scalars['String']['input']
  unitSettings: Scalars['String']['input']
}

export type MutationSaveDocumentFieldsArgs = {
  documents: Array<InputMaybe<InputDocument>>
  jobId: Scalars['String']['input']
}

export type MutationSaveDocumentTableArgs = {
  documentTable: InputDocumentTable
  jobId: Scalars['String']['input']
  validateFields: Scalars['Boolean']['input']
}

export type MutationSaveExploreTableDefaultColumnsArgs = {
  columns?: InputMaybe<Array<Scalars['Int']['input']>>
  orderAscending?: InputMaybe<Scalars['Boolean']['input']>
  orderByColumn?: InputMaybe<Scalars['Int']['input']>
  tabName: ExploreTab
}

export type MutationSaveFiltersAndUnitSettingsArgs = {
  filterQuery: Scalars['String']['input']
  tabName: Scalars['String']['input']
  tabType: Scalars['String']['input']
  unitSettings: Scalars['String']['input']
}

export type MutationSaveJobAndDocumentTablesArgs = {
  documentTables: Array<InputDocumentTable>
  jobId: Scalars['UUID']['input']
  jobTable: InputJobTable
  validateFields: Scalars['Boolean']['input']
}

export type MutationSaveJobTableArgs = {
  jobId: Scalars['String']['input']
  jobTable: InputJobTable
}

export type MutationSaveJobTemplateExportFormatArgs = {
  exportType: JobTemplateExportType
  jobTemplateExportSheets: Array<InputJobTemplateExportSheet>
  jobTemplateId: Scalars['UUID']['input']
}

export type MutationSaveReconAttemptArgs = {
  reconAttemptId: Scalars['String']['input']
}

export type MutationSaveReconAttemptsByBatchArgs = {
  reconAsyncBatchId: Scalars['String']['input']
}

export type MutationSendBFileToCwArgs = {
  jobId: Scalars['UUID']['input']
}

export type MutationSendManualResetPasswordEmailArgs = {
  email: Scalars['String']['input']
  freightForwarderCompanyId: Scalars['UUID']['input']
}

export type MutationSendShipmentOpsToCwArgs = {
  jobId: Scalars['UUID']['input']
}

export type MutationSetJobManualProductArgs = {
  jobId: Scalars['String']['input']
  manualProduct: Scalars['String']['input']
}

export type MutationSetJobOwnerArgs = {
  jobId: Scalars['String']['input']
  ownerId: Scalars['String']['input']
}

export type MutationSetJobQaArgs = {
  jobId: Scalars['String']['input']
  qaId: Scalars['String']['input']
}

export type MutationShipperFacingContainerSetDefaultColumnsArgs = {
  columns: Array<FilteredContainersColumn>
  orderByColumn: ShipperVizContainerColumn
  orderByDirection: ShipperVizContainerOrder
  slug: Scalars['String']['input']
}

export type MutationShipperFacingExportContainersArgs = {
  filterQueries?: InputMaybe<Array<RawFilterQuery>>
  forceUseAsync?: Scalars['Boolean']['input']
  headers?: InputMaybe<Array<FilteredContainersColumn>>
  orderByColumn?: ShipperVizContainerColumn
  orderDirection?: ShipperVizContainerOrder
  slug: Scalars['String']['input']
  totalColumns: Scalars['Int']['input']
  totalDataCount: Scalars['Int']['input']
  tzString: Scalars['String']['input']
}

export type MutationShipperFacingExportShipmentsArgs = {
  filterQueries?: InputMaybe<Array<RawFilterQuery>>
  forceUseAsync?: Scalars['Boolean']['input']
  headers?: InputMaybe<Array<FilteredShipmentsColumn>>
  orderByColumn?: ShipperVizShipmentColumn
  orderDirection?: ShipperVizShipmentOrder
  slug: Scalars['String']['input']
  totalColumns: Scalars['Int']['input']
  totalDataCount: Scalars['Int']['input']
  tzString: Scalars['String']['input']
}

export type MutationShipperFacingShipmentSetDefaultColumnsArgs = {
  columns: Array<FilteredShipmentsColumn>
  orderByColumn: ShipperVizShipmentColumn
  orderByDirection: ShipperVizShipmentOrder
  slug: Scalars['String']['input']
}

export type MutationShipperFacingShipmentSetDefaultFiltersArgs = {
  filterOrder: Array<Scalars['String']['input']>
  filterQuery?: InputMaybe<Scalars['String']['input']>
  slug: Scalars['String']['input']
  tabName: Scalars['String']['input']
}

export type MutationStarTaskArgs = {
  starred: Scalars['Boolean']['input']
  taskId: Scalars['String']['input']
}

export type MutationSyncCauldronDbArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type MutationSyncSearchableRecordsArgs = {
  companyIds?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type MutationSyncUserModelWithDashboardArgs = {
  inputUser: InputUser
}

export type MutationTestApiPartnerArgs = {
  apiPartnerId: Scalars['UUID']['input']
}

export type MutationTestCargowiseConfigArgs = {
  cwConfigId: Scalars['UUID']['input']
}

export type MutationTestCredentialArgs = {
  credentialId: Scalars['UUID']['input']
}

export type MutationTiffToPngArgs = {
  imageBase64Data: Scalars['String']['input']
}

export type MutationUnmergeDocumentTableArgs = {
  documentTableId: Scalars['String']['input']
}

export type MutationUpdateApiPartnerArgs = {
  id: Scalars['UUID']['input']
  inputApiPartner: InputApiPartner
}

export type MutationUpdateBatchReconResolutionStatusAndCreateNoteArgs = {
  batchResolutionStatus?: InputMaybe<Scalars['String']['input']>
  content: Scalars['String']['input']
  jobId: Scalars['String']['input']
  noteType: Scalars['String']['input']
  reconAsyncBatchId: Scalars['String']['input']
}

export type MutationUpdateCardArgs = {
  cardId: Scalars['UUID']['input']
  inputCard: InputCard
  inputPivotTable?: InputMaybe<InputPivotTable>
}

export type MutationUpdateCargowiseConfigArgs = {
  id: Scalars['UUID']['input']
  inputCwConfig: InputCargowiseConfig
}

export type MutationUpdateChargeQuantityArgs = {
  jobId: Scalars['String']['input']
  numContainers?: InputMaybe<Scalars['Int']['input']>
}

export type MutationUpdateCredentialArgs = {
  apiEndpoint?: InputMaybe<Scalars['String']['input']>
  hostOverride?: InputMaybe<Scalars['String']['input']>
  id: Scalars['UUID']['input']
  jobTemplateIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  password?: InputMaybe<Scalars['String']['input']>
  username: Scalars['String']['input']
  verifySsl?: Scalars['Boolean']['input']
  workflow: Scalars['String']['input']
}

export type MutationUpdateDashboardBasicFilterFieldsArgs = {
  dashboardBasicFilterFields: Array<Scalars['String']['input']>
  dashboardId: Scalars['UUID']['input']
}

export type MutationUpdateDashboardCompanyUserIamArgs = {
  companyId?: InputMaybe<Scalars['UUID']['input']>
  inputUser: InputUser
}

export type MutationUpdateDashboardIsDefaultArgs = {
  dashboardId: Scalars['UUID']['input']
  isDefault: Scalars['Boolean']['input']
}

export type MutationUpdateDashboardNameArgs = {
  dashboardId: Scalars['UUID']['input']
  displayName: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type MutationUpdateDashboardPropertiesArgs = {
  dashboardId: Scalars['UUID']['input']
  newDashboardTab: Scalars['String']['input']
}

export type MutationUpdateDashboardSelectorsArgs = {
  dashboardId: Scalars['UUID']['input']
  dashboardSelectors: Array<SummaryUnitType>
}

export type MutationUpdateDocumentTablesOrderArgs = {
  documentTableIds: Array<InputMaybe<Scalars['String']['input']>>
}

export type MutationUpdateEmbeddedDashboardArgs = {
  dashboardId: Scalars['UUID']['input']
  dashboardTab: Scalars['String']['input']
  displayName: Scalars['String']['input']
  iframeOverrideUrl: Scalars['String']['input']
  isDefault: Scalars['Boolean']['input']
  name: Scalars['String']['input']
}

export type MutationUpdateForwarderClientUserArgs = {
  auth0Id: Scalars['String']['input']
  inputUser: InputUser
  oldCompanyId: Scalars['UUID']['input']
}

export type MutationUpdateJobExternalAssigneeArgs = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>
  assigneeId: Scalars['UUID']['input']
  jobId: Scalars['UUID']['input']
  source: NoteSource
}

export type MutationUpdateJobReconExternalAssigneeArgs = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>
  assigneeId: Scalars['UUID']['input']
  reconAttemptId: Scalars['UUID']['input']
  source: NoteSource
}

export type MutationUpdateReconAttemptStatusArgs = {
  noteContent?: InputMaybe<Scalars['String']['input']>
  reconAttemptId: Scalars['String']['input']
  saveNote: Scalars['Boolean']['input']
  status: Scalars['String']['input']
}

export type MutationUpdateReconDashboardUserFilterArgs = {
  column: ReconJobListColumn
  values: Array<Scalars['String']['input']>
  variant: FilterVariant
}

export type MutationUpdateRisrAndRmcArgs = {
  inputReconInfoObjects?: InputMaybe<Array<InputReconInfoObject>>
  jobId: Scalars['UUID']['input']
}

export type MutationUpdateTaskArgs = {
  dateConfirmed?: InputMaybe<Scalars['String']['input']>
  dateReceived: Scalars['String']['input']
  message?: InputMaybe<Scalars['String']['input']>
  taskId: Scalars['String']['input']
  taskReferenceId: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type MutationUpdateTaskCompanyArgs = {
  companyId: Scalars['String']['input']
  taskId: Scalars['String']['input']
}

export type MutationUpdateTaskStatusArgs = {
  status: Scalars['String']['input']
  taskId: Scalars['String']['input']
}

export type MutationUpdateTaskTypeArgs = {
  taskId: Scalars['String']['input']
  taskType: Scalars['String']['input']
}

export type MutationUpdateUserArgs = {
  auth0Id: Scalars['String']['input']
  inputUser: InputUser
}

export type MutationUpdateUserReconJobListColumnsArgs = {
  columns: Array<ReconJobListColumn>
}

export type MutationUploadOriginalPdfsToS3Args = {
  files: Array<OriginalInputPdf>
  jobId: Scalars['UUID']['input']
}

export type MutationUpsertDashboardIamRoleArgs = {
  auth0Id: Scalars['String']['input']
  dashboardId: Scalars['UUID']['input']
  dashboardRole: DashboardRole
}

export type MutationUpsertDocumentTableFieldGroupArgs = {
  documentTableId: Scalars['String']['input']
  fieldGroupId: Scalars['String']['input']
}

export type MutationUpsertJobTemplateArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  autoPostEnabled?: InputMaybe<Scalars['Boolean']['input']>
  autoReconEnabled?: InputMaybe<Scalars['Boolean']['input']>
  companyId?: InputMaybe<Scalars['UUID']['input']>
  createIfMissing?: InputMaybe<Scalars['Boolean']['input']>
  credentialId?: InputMaybe<Scalars['UUID']['input']>
  defaultExternalAssigneeId?: InputMaybe<Scalars['UUID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  documentTypes?: InputMaybe<Array<InputDocumentType>>
  fieldGroups?: InputMaybe<Array<InputFieldGroup>>
  id: Scalars['UUID']['input']
  inputReconThresholdRanges?: InputMaybe<Array<InputReconThresholdRange>>
  mainTabEnabled?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  reconType?: InputMaybe<JobTemplateReconType>
  requireEdocsPushPull?: InputMaybe<Scalars['Boolean']['input']>
  requireExternalAssignee?: InputMaybe<Scalars['Boolean']['input']>
  requireShowReconToCustomer?: InputMaybe<Scalars['Boolean']['input']>
  shipmentOpsTypes?: InputMaybe<Array<ShipmentOp>>
  showPostButton?: InputMaybe<Scalars['Boolean']['input']>
  slaTime?: InputMaybe<Scalars['Int']['input']>
  subtotalsRowEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpsertSeperatedChargeVendorsChargeCodesArgs = {
  apiPartnerIds: Array<Scalars['UUID']['input']>
  chargeCodesSignedUrl: Scalars['String']['input']
  chargeVendorsSignedUrl: Scalars['String']['input']
  companyId: Scalars['UUID']['input']
}

export type NegativeMarginShipment = {
  __typename?: 'NegativeMarginShipment'
  containerMode?: Maybe<Scalars['String']['output']>
  dateShipmentCreated?: Maybe<Scalars['DateTime']['output']>
  expenses?: Maybe<Scalars['String']['output']>
  forwarderReference?: Maybe<Scalars['String']['output']>
  localClientName?: Maybe<Scalars['String']['output']>
  marginAbsolute?: Maybe<Scalars['String']['output']>
  marginPercentage?: Maybe<Scalars['String']['output']>
  operationsRepName?: Maybe<Scalars['String']['output']>
  revenue?: Maybe<Scalars['String']['output']>
  shipmentDestination?: Maybe<Scalars['String']['output']>
  shipmentEta?: Maybe<Scalars['DateTime']['output']>
  shipmentEtd?: Maybe<Scalars['DateTime']['output']>
  shipmentOrigin?: Maybe<Scalars['String']['output']>
  snowflakeId?: Maybe<Scalars['String']['output']>
  teus?: Maybe<Scalars['String']['output']>
  transportMode?: Maybe<Scalars['String']['output']>
  weight?: Maybe<Scalars['String']['output']>
}

export type NegativeMarginShipmentDataResult = {
  __typename?: 'NegativeMarginShipmentDataResult'
  negativeMarginShipments: Array<NegativeMarginShipment>
  shipmentSummary: ExploreShipmentsSummary
}

export type NoteCustomerApproveForPosting = {
  __typename?: 'NoteCustomerApproveForPosting'
  additionalNotes?: Maybe<Scalars['String']['output']>
  customerEmail: Scalars['String']['output']
  id: Scalars['UUID']['output']
  noteText?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
}

export type NoteCustomerChangeAssignee = {
  __typename?: 'NoteCustomerChangeAssignee'
  additionalNotes?: Maybe<Scalars['String']['output']>
  customerEmail: Scalars['String']['output']
  id: Scalars['UUID']['output']
  newAssigneeEmail?: Maybe<Scalars['String']['output']>
  noteText?: Maybe<Scalars['String']['output']>
  oldAssigneeEmail?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
}

export type NoteCustomerComment = {
  __typename?: 'NoteCustomerComment'
  body: Scalars['GenericScalar']['output']
  canDelete: Scalars['Boolean']['output']
  customerEmail: Scalars['String']['output']
  id: Scalars['UUID']['output']
  noteText?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
}

export type NoteCustomerDeletedComment = {
  __typename?: 'NoteCustomerDeletedComment'
  customerEmail: Scalars['String']['output']
  id: Scalars['UUID']['output']
  timestamp: Scalars['DateTime']['output']
}

export type NoteCustomerJobPost = {
  __typename?: 'NoteCustomerJobPost'
  additionalNotes?: Maybe<Scalars['String']['output']>
  customerEmail: Scalars['String']['output']
  id: Scalars['UUID']['output']
  timestamp: Scalars['DateTime']['output']
}

export type NoteCustomerMoveToDone = {
  __typename?: 'NoteCustomerMoveToDone'
  additionalNotes?: Maybe<Scalars['String']['output']>
  customerEmail: Scalars['String']['output']
  id: Scalars['UUID']['output']
  noteText?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
}

export type NoteCustomerRequestReprocess = {
  __typename?: 'NoteCustomerRequestReprocess'
  additionalNotes?: Maybe<Scalars['String']['output']>
  autoPostAfterFixing: Scalars['Boolean']['output']
  correctedInfo: Scalars['String']['output']
  customerEmail: Scalars['String']['output']
  id: Scalars['UUID']['output']
  noteText?: Maybe<Scalars['String']['output']>
  reason: JobRequestReprocessReason
  timestamp: Scalars['DateTime']['output']
}

export type NoteCustomerRequestReprocessTargetFromReconInvoiceShipmentReference = {
  __typename?: 'NoteCustomerRequestReprocessTargetFromReconInvoiceShipmentReference'
  cargowiseModule: CwTargetModule
  customerEmail: Scalars['String']['output']
  id: Scalars['UUID']['output']
  invoiceNumber: Scalars['String']['output']
  jobId: Scalars['String']['output']
  timestamp: Scalars['DateTime']['output']
  tmsId: Scalars['String']['output']
}

export type NoteCustomerRequestReprocessTargetFromReconMatchCriteria = {
  __typename?: 'NoteCustomerRequestReprocessTargetFromReconMatchCriteria'
  cargowiseModule: CwTargetModule
  carrierBookingNumber?: Maybe<Scalars['String']['output']>
  consolNumber?: Maybe<Scalars['String']['output']>
  containerNumber?: Maybe<Scalars['String']['output']>
  customerEmail: Scalars['String']['output']
  hblNumber?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceNumber: Scalars['String']['output']
  jobId: Scalars['String']['output']
  mblNumber?: Maybe<Scalars['String']['output']>
  orderNumber?: Maybe<Scalars['String']['output']>
  shipmentNumber?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
}

export type NoteExpedockApproveForPosting = {
  __typename?: 'NoteExpedockApproveForPosting'
  additionalNotes?: Maybe<Scalars['String']['output']>
  customerEmail: Scalars['String']['output']
  id: Scalars['UUID']['output']
  noteText?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
}

export type NoteExpedockChangeAssignee = {
  __typename?: 'NoteExpedockChangeAssignee'
  additionalNotes?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  newAssigneeEmail?: Maybe<Scalars['String']['output']>
  noteText?: Maybe<Scalars['String']['output']>
  oldAssigneeEmail?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
}

export type NoteExpedockComment = {
  __typename?: 'NoteExpedockComment'
  body: Scalars['GenericScalar']['output']
  canDelete: Scalars['Boolean']['output']
  id: Scalars['UUID']['output']
  noteText?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
}

export type NoteExpedockDeletedComment = {
  __typename?: 'NoteExpedockDeletedComment'
  id: Scalars['UUID']['output']
  timestamp: Scalars['DateTime']['output']
}

export type NoteExpedockJobPost = {
  __typename?: 'NoteExpedockJobPost'
  id: Scalars['UUID']['output']
  timestamp: Scalars['DateTime']['output']
}

export type NoteExpedockJobReceived = {
  __typename?: 'NoteExpedockJobReceived'
  id: Scalars['UUID']['output']
  timestamp: Scalars['DateTime']['output']
}

export type NoteExpedockJobReceivedNoteExpedockSaveReconAttemptNoteExpedockOtherChangeStatusNoteCustomerRequestReprocessNoteCustomerRequestReprocessTargetFromReconInvoiceShipmentReferenceNoteCustomerRequestReprocessTargetFromReconMatchCriteriaNoteCustomerMoveToDoneNoteExpedockJobPostNoteCustomerJobPostNoteExpedockChangeAssigneeNoteCustomerChangeAssigneeNoteExpedockCommentNoteCustomerCommentNoteExpedockDeletedCommentNoteCustomerDeletedCommentNoteCustomerApproveForPostingNoteExpedockApproveForPosting =

    | NoteCustomerApproveForPosting
    | NoteCustomerChangeAssignee
    | NoteCustomerComment
    | NoteCustomerDeletedComment
    | NoteCustomerJobPost
    | NoteCustomerMoveToDone
    | NoteCustomerRequestReprocess
    | NoteCustomerRequestReprocessTargetFromReconInvoiceShipmentReference
    | NoteCustomerRequestReprocessTargetFromReconMatchCriteria
    | NoteExpedockApproveForPosting
    | NoteExpedockChangeAssignee
    | NoteExpedockComment
    | NoteExpedockDeletedComment
    | NoteExpedockJobPost
    | NoteExpedockJobReceived
    | NoteExpedockOtherChangeStatus
    | NoteExpedockSaveReconAttempt

export type NoteExpedockOtherChangeStatus = {
  __typename?: 'NoteExpedockOtherChangeStatus'
  id: Scalars['UUID']['output']
  newStatus: JobExternalStatus
  noteText?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
}

export type NoteExpedockSaveReconAttempt = {
  __typename?: 'NoteExpedockSaveReconAttempt'
  id: Scalars['UUID']['output']
  invoiceFindings?: Maybe<Scalars['String']['output']>
  noteText?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
}

export type NoteNode = {
  __typename?: 'NoteNode'
  content?: Maybe<Scalars['GenericScalar']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobActivity?: Maybe<JobEDocsPushNodeJobEDocsPullNodeJobSendToCwNodeJobPostToCwNodeJobShipmentOpsCreateNodeJobCheckShipmentInfoNodeJobReceivedNodeJobStatusChangeNodeJobConfirmationNodeJobRejectionNodeJobOwnerChangeNodeJobQaChangeNodeJobQaNodeJobExternalStatusChangeNodeJobReconciliationSavedNodeJobMoveToDoneNodeJobRequestReprocessNodeJobExternalAssigneeChangeNodeJobReconUpdateStatusNodeJobMetadataEditNodeJobLineItemEditNodeApiExportJobActivityNodeApiExportEDocActivityNodeApiImportEDocActivityNodeJobReconApproveForPostingNodeJobReconAttemptSavedNodeJobReconCommentNodeJobReconExternalAssigneeChangeNodeJobReconExternalStatusChangeNodeJobReconMoveToDoneNodeJobReconReceivedNodeJobReconRequestReprocessNodeJobReconRequestReprocessTargetNode>
  jobActivityId?: Maybe<Scalars['UUID']['output']>
  jobId: Scalars['UUID']['output']
  reconAsyncBatch?: Maybe<ReconAsyncBatchNode>
  reconAsyncBatchId?: Maybe<Scalars['UUID']['output']>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  source: NoteSource
  text: Scalars['String']['output']
  type: NoteType
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type NoteNodeConnection = {
  __typename?: 'NoteNodeConnection'
  edges: Array<NoteNodeEdge>
}

export type NoteNodeEdge = {
  __typename?: 'NoteNodeEdge'
  node: NoteNode
}

export enum NoteSource {
  Dashboard = 'DASHBOARD',
  Jms = 'JMS',
}

export enum NoteType {
  ActivityCwInteraction = 'ACTIVITY_CW_INTERACTION',
  ActivityJms = 'ACTIVITY_JMS',
  Comment = 'COMMENT',
  CommentJms = 'COMMENT_JMS',
  CommentUser = 'COMMENT_USER',
}

export enum NotificationType {
  PageAsyncBatch = 'PAGE_ASYNC_BATCH',
}

export type NumericFilterBetween = {
  lowerBound: Scalars['Decimal']['input']
  upperBound: Scalars['Decimal']['input']
}

export type NumericFilterEmpty = {
  Void?: InputMaybe<Scalars['Void']['input']>
}

export type NumericFilterEquals = {
  value: Scalars['Decimal']['input']
}

export type NumericFilterGreaterThan = {
  value: Scalars['Decimal']['input']
}

export type NumericFilterGreaterThanOrEquals = {
  value: Scalars['Decimal']['input']
}

export type NumericFilterLessThan = {
  value: Scalars['Decimal']['input']
}

export type NumericFilterLessThanOrEquals = {
  value: Scalars['Decimal']['input']
}

export type NumericFilterNotEmpty = {
  Void?: InputMaybe<Scalars['Void']['input']>
}

export type NumericFilterNotEquals = {
  value: Scalars['Decimal']['input']
}

export enum OperationsShipmentVolumeGraph {
  Account = 'Account',
  Branch = 'Branch',
  DateShipmentCreated = 'DateShipmentCreated',
  Operator = 'Operator',
  Route = 'Route',
  Shipper = 'Shipper',
  TransportMode = 'TransportMode',
}

export enum OperationsTab {
  NegativeMarginShipments = 'NEGATIVE_MARGIN_SHIPMENTS',
  ShipmentVolume = 'SHIPMENT_VOLUME',
}

export type Organization = {
  __typename?: 'Organization'
  code?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type OrganizationInfo = {
  __typename?: 'OrganizationInfo'
  code?: Maybe<Scalars['String']['output']>
  dataSource: Scalars['String']['output']
  key: Scalars['String']['output']
  latestCauldronDateCreateds: Array<Array<Scalars['String']['output']>>
  latestSnowflakeDateCreateds: Array<Array<Scalars['String']['output']>>
  name?: Maybe<Scalars['String']['output']>
  types: Array<OrganizationType>
}

export enum OrganizationOverviewCard {
  PayablesAllOutstanding = 'PAYABLES_ALL_OUTSTANDING',
  PayablesByDueDate = 'PAYABLES_BY_DUE_DATE',
  PayablesHistoricalPerformance = 'PAYABLES_HISTORICAL_PERFORMANCE',
  PayablesTotalOutstanding = 'PAYABLES_TOTAL_OUTSTANDING',
  ReceivablesAllOutstanding = 'RECEIVABLES_ALL_OUTSTANDING',
  ReceivablesByDueDate = 'RECEIVABLES_BY_DUE_DATE',
  ReceivablesHistoricalPerformance = 'RECEIVABLES_HISTORICAL_PERFORMANCE',
  ReceivablesTotalOutstanding = 'RECEIVABLES_TOTAL_OUTSTANDING',
}

export type OrganizationSearchResult = {
  __typename?: 'OrganizationSearchResult'
  numOrganizations: Scalars['Int']['output']
  organizations: Array<Organization>
}

export enum OrganizationType {
  Consignee = 'CONSIGNEE',
  Creditor = 'CREDITOR',
  Debtor = 'DEBTOR',
  LocalClient = 'LOCAL_CLIENT',
  Shipper = 'SHIPPER',
}

export type OriginalInputPdf = {
  fileBytes: Scalars['String']['input']
  filename: Scalars['String']['input']
}

export type OriginalPdf = {
  __typename?: 'OriginalPDF'
  filename: Scalars['String']['output']
  s3Uri: Scalars['String']['output']
}

export type OtherSelect = {
  __typename?: 'OtherSelect'
  label: Scalars['String']['output']
  value?: Maybe<Scalars['String']['output']>
}

export type OuterPackagingUnit = {
  __typename?: 'OuterPackagingUnit'
  code: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type OutputSearchableRecordColumn = {
  __typename?: 'OutputSearchableRecordColumn'
  searchableRecordId: Scalars['UUID']['output']
  values: Array<Scalars['String']['output']>
}

export type PageAsyncBatchNode = {
  __typename?: 'PageAsyncBatchNode'
  autofillFieldGroups?: Maybe<Scalars['JSONString']['output']>
  dateCompleted?: Maybe<Scalars['DateTime']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  job?: Maybe<JobNode>
  jobId?: Maybe<Scalars['UUID']['output']>
  notification?: Maybe<PageAsyncBatchNotificationNode>
  numDone?: Maybe<Scalars['Int']['output']>
  numTasks?: Maybe<Scalars['Int']['output']>
  pageAsyncTasks: PageAsyncTaskNodeConnection
  status: PageAsyncTaskStatus
  type: PageAsyncBatchType
  user?: Maybe<UserNode>
  userId?: Maybe<Scalars['UUID']['output']>
}

export type PageAsyncBatchNodeConnection = {
  __typename?: 'PageAsyncBatchNodeConnection'
  edges: Array<PageAsyncBatchNodeEdge>
}

export type PageAsyncBatchNodeEdge = {
  __typename?: 'PageAsyncBatchNodeEdge'
  node: PageAsyncBatchNode
}

export type PageAsyncBatchNotificationNode = {
  __typename?: 'PageAsyncBatchNotificationNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dateRead?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['UUID']['output']
  pageAsyncBatch: PageAsyncBatchNode
  pageAsyncBatchId: Scalars['UUID']['output']
  type: NotificationType
  user: UserNode
  userId: Scalars['UUID']['output']
}

export enum PageAsyncBatchType {
  Autofill = 'AUTOFILL',
  Ingest = 'INGEST',
  IngestAutofill = 'INGEST_AUTOFILL',
}

export type PageAsyncTaskNode = {
  __typename?: 'PageAsyncTaskNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  filePage: FilePageNode
  filePageId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  pageAsyncBatch: PageAsyncBatchNode
  pageAsyncBatchId: Scalars['UUID']['output']
  status: PageAsyncTaskStatus
}

export type PageAsyncTaskNodeConnection = {
  __typename?: 'PageAsyncTaskNodeConnection'
  edges: Array<PageAsyncTaskNodeEdge>
}

export type PageAsyncTaskNodeEdge = {
  __typename?: 'PageAsyncTaskNodeEdge'
  node: PageAsyncTaskNode
}

export enum PageAsyncTaskStatus {
  Done = 'DONE',
  Error = 'ERROR',
  Pending = 'PENDING',
}

export type PaginatedReconJobListResults = {
  __typename?: 'PaginatedReconJobListResults'
  hasNext: Scalars['Boolean']['output']
  hasPrevious: Scalars['Boolean']['output']
  nextMarker?: Maybe<Scalars['String']['output']>
  previousMarker?: Maybe<Scalars['String']['output']>
  results: Array<ReconJobListGroupReconJobListItem>
}

export enum PaywallFeature {
  Container = 'CONTAINER',
  ContainerDetails = 'CONTAINER_DETAILS',
  CostPerSku = 'COST_PER_SKU',
  Documents = 'DOCUMENTS',
  ExceptionsManagement = 'EXCEPTIONS_MANAGEMENT',
  GetStatusUpdates = 'GET_STATUS_UPDATES',
  Invoices = 'INVOICES',
  LandingPage = 'LANDING_PAGE',
}

export type PaywallUserRequestResult = {
  __typename?: 'PaywallUserRequestResult'
  email: Scalars['String']['output']
  featureNames: Array<Scalars['String']['output']>
  name: Scalars['String']['output']
  userEnteredOrganization: Scalars['String']['output']
}

export enum PaywallUserRequestResultOrderByColumn {
  CompanyName = 'COMPANY_NAME',
  DateCreated = 'DATE_CREATED',
  Email = 'EMAIL',
  Name = 'NAME',
}

export enum PeriodType {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY',
}

export type PeriodTypeEnum = {
  __typename?: 'PeriodTypeEnum'
  isFallback: Scalars['Boolean']['output']
  value: PeriodType
}

export type PeriodTypeWithFallback = FallbackValue | PeriodTypeEnum

export type PinMapDataPoint = {
  __typename?: 'PinMapDataPoint'
  country: Scalars['String']['output']
  otherSelects?: Maybe<Array<OtherSelect>>
  value?: Maybe<Scalars['String']['output']>
}

export type PinMapDataResult = {
  __typename?: 'PinMapDataResult'
  results: Array<PinMapDataPoint>
  xAxisLabel: Scalars['String']['output']
  yAxisLabel: Scalars['String']['output']
}

export type PivotRowColumnNode = {
  __typename?: 'PivotRowColumnNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  displayName?: Maybe<Scalars['String']['output']>
  dottedFieldName?: Maybe<Scalars['String']['output']>
  fieldName: Scalars['String']['output']
  id: Scalars['UUID']['output']
  isRow: Scalars['Boolean']['output']
  periodType?: Maybe<PeriodTypeWithFallback>
  pivotTable: PivotTableNode
  pivotTableId: Scalars['UUID']['output']
  showTotals: Scalars['Boolean']['output']
}

export type PivotRowColumnNodeConnection = {
  __typename?: 'PivotRowColumnNodeConnection'
  edges: Array<PivotRowColumnNodeEdge>
}

export type PivotRowColumnNodeEdge = {
  __typename?: 'PivotRowColumnNodeEdge'
  node: PivotRowColumnNode
}

export type PivotTableColumn = {
  __typename?: 'PivotTableColumn'
  displayName?: Maybe<Scalars['String']['output']>
  dottedFieldName?: Maybe<Scalars['String']['output']>
  fieldName: Scalars['String']['output']
  groups: Array<Scalars['String']['output']>
  periodType?: Maybe<PeriodType>
  showTotals: Scalars['Boolean']['output']
}

export type PivotTableDataResult = {
  __typename?: 'PivotTableDataResult'
  data: Scalars['String']['output']
  pivotColumns: Array<PivotTableColumn>
  pivotRows: Array<PivotTableRow>
  pivotValues: Array<PivotTableValue>
}

export type PivotTableNode = {
  __typename?: 'PivotTableNode'
  card: CardNode
  cardId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  rowsColumns: PivotRowColumnNodeConnection
  values: PivotValueNodeConnection
}

export type PivotTableRow = {
  __typename?: 'PivotTableRow'
  displayName?: Maybe<Scalars['String']['output']>
  dottedFieldName?: Maybe<Scalars['String']['output']>
  fieldName: Scalars['String']['output']
  periodType?: Maybe<PeriodType>
  showTotals: Scalars['Boolean']['output']
}

export type PivotTableValue = {
  __typename?: 'PivotTableValue'
  displayName?: Maybe<Scalars['String']['output']>
  fieldName: Scalars['String']['output']
  valueFunction: PivotValueFunction
}

export enum PivotValueFunction {
  Average = 'AVERAGE',
  Count = 'COUNT',
  CountDistinct = 'COUNT_DISTINCT',
  Max = 'MAX',
  Median = 'MEDIAN',
  Min = 'MIN',
  Sum = 'SUM',
}

export type PivotValueFunctionEnum = {
  __typename?: 'PivotValueFunctionEnum'
  isFallback: Scalars['Boolean']['output']
  value: PivotValueFunction
}

export type PivotValueFunctionWithFallback = FallbackValue | PivotValueFunctionEnum

export type PivotValueNode = {
  __typename?: 'PivotValueNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  displayName?: Maybe<Scalars['String']['output']>
  fieldName: Scalars['String']['output']
  id: Scalars['UUID']['output']
  pivotTable: PivotTableNode
  pivotTableId: Scalars['UUID']['output']
  valueFunction: PivotValueFunctionWithFallback
}

export type PivotValueNodeConnection = {
  __typename?: 'PivotValueNodeConnection'
  edges: Array<PivotValueNodeEdge>
}

export type PivotValueNodeEdge = {
  __typename?: 'PivotValueNodeEdge'
  node: PivotValueNode
}

export type Port = {
  __typename?: 'Port'
  code: Scalars['String']['output']
  name: Scalars['String']['output']
  searchRegex: Scalars['String']['output']
}

export type PortCoordinatesNode = {
  __typename?: 'PortCoordinatesNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  latitude?: Maybe<Scalars['Float']['output']>
  longitude?: Maybe<Scalars['Float']['output']>
  portCode: Scalars['String']['output']
}

export type PostToCw = {
  __typename?: 'PostToCW'
  ok: Scalars['Boolean']['output']
}

export type PullEDocsBatch = {
  __typename?: 'PullEDocsBatch'
  batchPullId?: Maybe<Scalars['UUID']['output']>
  done?: Maybe<Scalars['Boolean']['output']>
  exceptions: Array<Scalars['String']['output']>
  failures: Array<Scalars['String']['output']>
  successes: Array<Scalars['String']['output']>
}

export type PullEDocsFromCw = {
  __typename?: 'PullEDocsFromCW'
  ok: Scalars['Boolean']['output']
}

export type PushEDocsBatch = {
  __typename?: 'PushEDocsBatch'
  batchPushId?: Maybe<Scalars['UUID']['output']>
  done?: Maybe<Scalars['Boolean']['output']>
  errorMessages: Array<Scalars['String']['output']>
  failures: Array<Scalars['String']['output']>
  successes: Array<Scalars['String']['output']>
}

export type PushEDocsToCw = {
  __typename?: 'PushEDocsToCW'
  ok: Scalars['Boolean']['output']
}

export type QaJob = {
  __typename?: 'QaJob'
  ok: Scalars['Boolean']['output']
}

export type Query = {
  __typename?: 'Query'
  accounting: Array<DataResult>
  allCompanies: Array<CompanyNode>
  allCompanyChargeCodes: Array<Maybe<Scalars['String']['output']>>
  apReconAttemptsHistory: ReconAttemptNodePaginatedResults
  apiEDocuments: Array<ApiEDocumentNode>
  apiIngestionAttachmentSignedUrl: Scalars['String']['output']
  apiPartners: Array<ApiPartnerNode>
  authProfile: AuthProfile
  autofillKeys: AutofillKeysResult
  batchReconNotes: Array<NoteNode>
  batchResolutionStatus: ResolutionStatus
  batchSearchableRecordResults: Array<Maybe<OutputSearchableRecordColumn>>
  branches: Array<Scalars['String']['output']>
  cargowiseConfigs: Array<CargowiseConfigNode>
  cargowiseFileTypes: Array<CargowiseFileTypeNode>
  chargeCodeAndVendorOverrides: ChargeCodeV2Node
  chargeCodeUploadAsyncTask: ChargeCodeUploadAsyncTaskNode
  chargeCodes: Array<ChargeCodeNode>
  chargeCodesByApiPartner: Array<ChargeCodeV2Node>
  chargeCodesByConfigAndVendor: Array<ChargeCodeNode>
  chargeCodesByVendorNames: Array<ChargeCodeSearchResult>
  chargeCodesOk: Scalars['Boolean']['output']
  chargeVendor: ChargeVendorNode
  chargeVendors: Array<ChargeVendorNode>
  chargeVendorsByCodes: Array<ChargeVendorNode>
  chargeVendorsOk: Scalars['Boolean']['output']
  checkShipmentInfoAsyncBatch: CheckShipmentInfoAsyncBatchNode
  companies: Array<CompanyNode>
  company: CompanyNode
  companyChargeCodes: Array<ChargeCodeNode>
  companyChargeCodesV2: Array<ChargeCodeV2Node>
  companyChargeVendors: Array<ChargeVendorNode>
  companyJobTemplates: Array<JobTemplateNode>
  consolWithMatchCriteria: ConsolWithMatchCriteria
  countByJobStatus: Scalars['GenericScalar']['output']
  credentials: Array<CredentialNode>
  currencyUnits: Array<FilterSearchResult>
  customDatabaseTypes: Array<CustomDatabaseTypeNode>
  customDatabases: Array<CustomDatabaseNode>
  customsDeclarationWithMatchCriteria: CustomsDeclarationWithMatchCriteria
  dashboardColumnSearchResults: FilterSearchResultResponse
  dashboardFieldMetadataResults: Array<DashboardViewFieldMetadata>
  dashboardFieldMetadataResultsExceptionsManagement: Array<DashboardViewFieldMetadata>
  departments: Array<Scalars['String']['output']>
  documentTables: Array<DocumentTableNode>
  documentTypes: Array<DocumentTypeNode>
  eDocuments: Array<EDocumentNode>
  existingReconAttempt?: Maybe<ReconAttemptNode>
  expenses: DataResult
  exploreContainers: ExploreContainerDataResult
  exploreContainersColumns: Array<ExploreContainerColumnV2>
  exploreContainersDefaultColumns: ExploreContainerColumnV2ExploreTableDefaultColumns
  exploreContainersV2: ExploreContainerDataResult
  exploreInvoices: ExploreInvoiceDataResult
  exploreOrganizations: ExploreOrganizationDataResult
  exploreOrganizationsColumns: Array<ExploreOrganizationColumnV2>
  exploreOrganizationsDefaultColumns: ExploreOrganizationColumnV2ExploreTableDefaultColumns
  exploreOrganizationsV2: ExploreOrganizationDataResult
  explorePayableInvoicesColumns: Array<ExplorePayableInvoiceColumnV2>
  explorePayableInvoicesDefaultColumns: ExplorePayableInvoiceColumnV2ExploreTableDefaultColumns
  explorePayableInvoicesV2: ExploreInvoiceDataResult
  exploreReceivableInvoicesColumns: Array<ExploreReceivableInvoiceColumnV2>
  exploreReceivableInvoicesDefaultColumns: ExploreReceivableInvoiceColumnV2ExploreTableDefaultColumns
  exploreReceivableInvoicesV2: ExploreInvoiceDataResult
  exploreShipments: ExploreShipmentDataResult
  exploreShipmentsColumns: Array<ExploreShipmentColumnV2>
  exploreShipmentsDefaultColumns: ExploreShipmentColumnV2ExploreTableDefaultColumns
  exploreShipmentsV2: ExploreShipmentDataResult
  exportDashboard: Scalars['String']['output']
  fetchCredentials: Array<CredentialNode>
  fileConnection: FileConnection
  filePagesConnection: FilePagesConnection
  fileSignedUrl: FileSignedUrl
  findChainIoMatchesFromSoaLineItems: SoaLineItemModelMatches
  findInvoiceReconResultFromJob: FindInvoiceReconResultFromJobNode
  findShipmentReconResults: Array<FindShipmentReconResultNode>
  findSoaShipmentsReconResults: Array<Array<FindShipmentReconResultNode>>
  freightBiExportReportStatus: ExportReportStatus
  geoLocations: Array<GeoLocationNode>
  getCustomDashboard: DashboardNode
  getDashboard: DashboardNode
  getDashboardDefaultFilters?: Maybe<SavedFiltersAndUnitSettings>
  getDashboardEDocs: GetDashboardEDocs
  getDataForCardSql?: Maybe<DataResult>
  getDataForHardcodedCardReference?: Maybe<DataResult>
  getDataResultForShipperFacingCard?: Maybe<DataResult>
  getFilteredContainers: FilteredContainersResult
  getFilteredShipments: FilteredShipmentsResult
  getNonAggData?: Maybe<Array<DataResult>>
  getPortCoordinates: Array<PortCoordinatesNode>
  getSavedFilters?: Maybe<Scalars['String']['output']>
  getSavedFiltersAndUnitSettings: Array<SavedFiltersAndUnitSettings>
  getShipperFacingCompany?: Maybe<CompanyNode>
  invoice: InvoiceResult
  isValidReconJobTemplate: IsValidReconJobTemplateResult
  job: JobNode
  jobNotes: Array<NoteNode>
  jobOperators: Array<UserNode>
  jobOverviewPage: JobOverviewPage
  jobQaOverallIssueTypes: Array<JobQaOverallIssueTypeNode>
  jobSearchParameters: JobSearchParameters
  jobTemplate: JobTemplateNode
  jobTemplates: Array<JobTemplateNode>
  jobs: Array<Maybe<JobNode>>
  listAvailableDashboards: Array<DashboardNode>
  listAvailableGraphs: Array<DashboardSearchItem>
  listCardsForDashboard: Array<CardNode>
  listCardsForExceptionsManagement: Array<CardNode>
  listCompanies: Array<CompanyNode>
  listCompanyDashboards: Array<DashboardNode>
  listDashboardCompanyUsers: Array<UserNode>
  listDashboardInsightsUsers: DashboardInsightsUserResult
  listShipperFacingOrganizations: ShipperFacingOrganizationResult
  listShipperFacingPaywallUserRequest: ShipperFacingPaywallUserRequestResults
  listShipperFacingUser: ShipperFacingUserResults
  listUserDashboards: Array<DashboardNode>
  listUserDashboardsWithIams: Array<UserDashboardNode>
  listUsers: UserList
  margins: DataResult
  matchSoaLineItemsToChainIoShipments: Array<SoaLineItemShipmentMatch>
  measurementUnits: Array<MeasurementUnitOutput>
  metabaseJwtUrl: Scalars['String']['output']
  metabasePublicShareUrl: Scalars['String']['output']
  mostProfitableRoutes: Array<DataResult>
  negativeMarginShipments: NegativeMarginShipmentDataResult
  notifications: Array<PageAsyncBatchNotificationNode>
  operators: Array<Scalars['String']['output']>
  organization?: Maybe<OrganizationInfo>
  organizationOverviewCardData: DataResult
  organizationOverviewQuery: Array<DataResult>
  organizationsSearchQuery: OrganizationSearchResult
  overview: Array<DataResult>
  pageAsyncBatch: PageAsyncBatchNode
  pullEDocsBatch: PullEDocsBatch
  pushEDocsBatch: PushEDocsBatch
  recentCargowiseOutbounds: Array<RawCargowiseOutboundNode>
  recentFields: Scalars['GenericScalar']['output']
  reconAsyncBatch: ReconAsyncBatchNode
  reconAttempt: ReconAttemptNode
  reconAttempts: Array<ReconAttemptNode>
  reconDashboardUserFilter: Array<ReconDashboardUserFilterNode>
  reconJob: ReconJob
  reconJobAttemptByInvoice: ReconJobAttemptByInvoice
  reconJobAttemptByReference: ReconJobAttemptByReference
  reconJobColumnSearch: Array<Scalars['String']['output']>
  reconJobInvoiceLines: Array<ReconJobInvoiceLine>
  reconJobList: ReconJobListItemPaginatedResults
  reconJobListGroups: PaginatedReconJobListResults
  reconJobListTabCounts: ReconJobListTabCounts
  reconJobNotes: Array<NoteExpedockJobReceivedNoteExpedockSaveReconAttemptNoteExpedockOtherChangeStatusNoteCustomerRequestReprocessNoteCustomerRequestReprocessTargetFromReconInvoiceShipmentReferenceNoteCustomerRequestReprocessTargetFromReconMatchCriteriaNoteCustomerMoveToDoneNoteExpedockJobPostNoteCustomerJobPostNoteExpedockChangeAssigneeNoteCustomerChangeAssigneeNoteExpedockCommentNoteCustomerCommentNoteExpedockDeletedCommentNoteCustomerDeletedCommentNoteCustomerApproveForPostingNoteExpedockApproveForPosting>
  reconJobResult: ReconJobResult
  reconJobShipmentDetails: Array<ReconJobShipmentDetail>
  reconJobShipmentMatched: ReconJobShipmentMatched
  reconNotes: Array<NoteNode>
  revenue: DataResult
  rpaGsheetsData: RpaGsheetsData
  salesReps: Array<Scalars['String']['output']>
  searchShipments: Array<ChainIoShipmentNodeChainIoConsolidationNodeChainIoCustomsDeclarationNode>
  searchShipperFacingOrganizationCodeChoices: SearchShipperFacingOrganizationCodeChoiceResult
  searchTaxes: Array<TaxNode>
  searchableRecordResults: Array<SearchableRecordResultNode>
  searchableRecords: Array<SearchableRecordOptions>
  searchedForCompanies: Array<CompanyNode>
  sendForgotPasswordResetEmail?: Maybe<Scalars['Void']['output']>
  shipmentDetailsByMatchCriteria: Array<Maybe<InsightsShipmentDetails>>
  shipmentWithMatchCriteria: ShipmentWithMatchCriteria
  shipperFacingContainerColumnInfos: Array<ShipperFacingContainerTableColumnInfo>
  shipperFacingContainerGetDefaultColumns: ShipperFacingContainersDefaultcolumns
  shipperFacingDashboardColumnSearchResults: FilterSearchResultResponse
  shipperFacingDashboardFieldMetadataResults: Array<DashboardViewFieldMetadata>
  shipperFacingExportReportStatus: ExportReportStatus
  shipperFacingSearchShipments: Array<InsightsShipmentNode>
  shipperFacingShipmentColumnInfos: Array<ShipperFacingShipmentTableColumnInfo>
  shipperFacingShipmentDetails?: Maybe<InsightsShipmentNode>
  shipperFacingShipmentGetDefaultColumns: ShipperFacingShipmentsDefaultcolumns
  shipperFacingShipmentGetDefaultFilters?: Maybe<ShipperFacingDashboardTabUserFilterNode>
  signCompanyLogoUri: Scalars['String']['output']
  soaJobExpectedCharges: Array<SoaLineItemExpectedCharges>
  soaReconAttemptsHistory: ReconAsyncBatchNodePaginatedResults
  standardDocumentTypes: Array<DocumentTypeNode>
  task: TaskNode
  taskNotes: Array<TaskNoteNode>
  taskPages: TaskPages
  taskPagesIngest: TaskPages
  taskSearchParameters: TaskSearchParameters
  tasksByTitleReferenceId: Array<TaskNode>
  taxIdsByApiPartner: Array<TaxNode>
  taxIdsByCargowiseConfig: Array<TaxNode>
  topExpenses: Array<DataResult>
  transactionAsyncBatch: TransactionAsyncBatchNode
  uniqueChargeCodesByCompanyPartner: Array<ChargeCodeSearchResult>
  uniqueChargeCodesByTax: Array<Scalars['String']['output']>
  uniqueChargeVendorsByCompany: Array<ChargeVendorNode>
  userDashboardId: Scalars['String']['output']
  userJobTemplates: Array<JobTemplateNode>
  userProfile: AuthProfile
  userReconJobListColumns: Array<ReconJobListColumn>
  usersByRole: Array<UserNode>
  validSearchableRecordResults: Array<OutputSearchableRecordColumn>
  validateDashboardAdmin: Scalars['Boolean']['output']
  vendorChargeCodeOverrides: Array<ChargeCodeVendorOverrideNode>
  vendorChargeCodeUrl: FileSignedUrl
  vendorChargeCodes: Array<ChargeCodeNode>
  viewOperatorCompanies: ViewOperatorCompanies
  volumeUnits: Array<FilterSearchResult>
  weightUnits: Array<FilterSearchResult>
}

export type QueryAccountingArgs = {
  accountingTabName: Scalars['String']['input']
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  unitSettings: UnitSetting
}

export type QueryAllCompanyChargeCodesArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  companyId: Scalars['UUID']['input']
}

export type QueryApReconAttemptsHistoryArgs = {
  getSavedOnly?: Scalars['Boolean']['input']
  jobId: Scalars['UUID']['input']
  marker?: InputMaybe<Scalars['String']['input']>
  pageSize?: Scalars['Int']['input']
}

export type QueryApiEDocumentsArgs = {
  jobId: Scalars['String']['input']
}

export type QueryApiIngestionAttachmentSignedUrlArgs = {
  apiIngestionAttachmentId: Scalars['UUID']['input']
}

export type QueryAutofillKeysArgs = {
  autofillExtractorKey: AutofillExtractorKey
  documentTypeId: Scalars['String']['input']
  reconType: JobTemplateReconType
}

export type QueryBatchReconNotesArgs = {
  reconAsyncBatchId: Scalars['String']['input']
}

export type QueryBatchResolutionStatusArgs = {
  reconAsyncBatchId: Scalars['String']['input']
}

export type QueryBatchSearchableRecordResultsArgs = {
  inputSearchableRecordColumns: Array<InputSearchableRecordColumn>
}

export type QueryBranchesArgs = {
  companyId: Scalars['String']['input']
}

export type QueryCargowiseFileTypesArgs = {
  companyId: Scalars['UUID']['input']
}

export type QueryChargeCodeAndVendorOverridesArgs = {
  chargeCodeId: Scalars['UUID']['input']
}

export type QueryChargeCodeUploadAsyncTaskArgs = {
  chargeCodeAsyncTaskId: Scalars['String']['input']
}

export type QueryChargeCodesArgs = {
  chargeCodeId: Scalars['UUID']['input']
}

export type QueryChargeCodesByApiPartnerArgs = {
  apiPartnerId: Scalars['UUID']['input']
}

export type QueryChargeCodesByConfigAndVendorArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  vendorName?: InputMaybe<Scalars['String']['input']>
}

export type QueryChargeCodesByVendorNamesArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  chargeVendorNames: Array<Scalars['String']['input']>
  companyId: Scalars['UUID']['input']
}

export type QueryChargeCodesOkArgs = {
  apiPartnerIds: Array<Scalars['UUID']['input']>
  chargeCodesCsv: InputChargeCodeCsv
  companyId: Scalars['UUID']['input']
}

export type QueryChargeVendorArgs = {
  chargeVendorId: Scalars['UUID']['input']
}

export type QueryChargeVendorsArgs = {
  companyId: Scalars['UUID']['input']
  limit?: Scalars['Int']['input']
  query: Scalars['String']['input']
  withChargeCodes?: Scalars['Boolean']['input']
}

export type QueryChargeVendorsByCodesArgs = {
  chargeVendorCodes: Array<Scalars['String']['input']>
  companyId: Scalars['UUID']['input']
}

export type QueryChargeVendorsOkArgs = {
  chargeVendors: InputVendorCsv
  companyId: Scalars['UUID']['input']
}

export type QueryCheckShipmentInfoAsyncBatchArgs = {
  checkShipmentInfoAsyncBatchId: Scalars['UUID']['input']
}

export type QueryCompanyArgs = {
  id: Scalars['String']['input']
}

export type QueryCompanyChargeCodesArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  companyId: Scalars['UUID']['input']
  query: Scalars['String']['input']
}

export type QueryCompanyChargeCodesV2Args = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  companyId: Scalars['UUID']['input']
  query: Scalars['String']['input']
}

export type QueryCompanyChargeVendorsArgs = {
  companyId: Scalars['UUID']['input']
}

export type QueryCompanyJobTemplatesArgs = {
  companyId: Scalars['UUID']['input']
  queryString: Scalars['String']['input']
}

export type QueryConsolWithMatchCriteriaArgs = {
  jobId: Scalars['UUID']['input']
}

export type QueryCustomDatabaseTypesArgs = {
  limit?: Scalars['Int']['input']
  query: Scalars['String']['input']
}

export type QueryCustomDatabasesArgs = {
  apiPartnerId: Scalars['UUID']['input']
  limit?: Scalars['Int']['input']
  query: Scalars['String']['input']
}

export type QueryCustomsDeclarationWithMatchCriteriaArgs = {
  jobId: Scalars['UUID']['input']
}

export type QueryDashboardColumnSearchResultsArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  searchKey?: Scalars['String']['input']
  tableColumn: Scalars['String']['input']
}

export type QueryDashboardFieldMetadataResultsArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
}

export type QueryDashboardFieldMetadataResultsExceptionsManagementArgs = {
  slug: Scalars['String']['input']
}

export type QueryDepartmentsArgs = {
  companyId: Scalars['String']['input']
}

export type QueryDocumentTablesArgs = {
  editedByUser?: InputMaybe<Scalars['Boolean']['input']>
  jobId: Scalars['String']['input']
}

export type QueryDocumentTypesArgs = {
  ids: Array<Scalars['UUID']['input']>
}

export type QueryEDocumentsArgs = {
  jobId: Scalars['String']['input']
}

export type QueryExistingReconAttemptArgs = {
  jobId: Scalars['String']['input']
}

export type QueryExpensesArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  graph: BusinessPerformanceExpensesGraph
  unitSettings: UnitSetting
}

export type QueryExploreContainersArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderByColumn?: Scalars['String']['input']
  orderDirection?: Scalars['String']['input']
  unitSettings: UnitSetting
}

export type QueryExploreContainersV2Args = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  columns?: InputMaybe<Scalars['EncodedExploreContainerColumnV2']['input']>
  filterQuery?: InputMaybe<RawFilterQuery>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderAscending?: InputMaybe<Scalars['Boolean']['input']>
  orderByColumn?: InputMaybe<Scalars['Int']['input']>
  unitSettings: UnitSetting
}

export type QueryExploreInvoicesArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
  orderByColumn?: InputMaybe<Scalars['String']['input']>
  orderDirection?: InputMaybe<Scalars['String']['input']>
  searchPayable: Scalars['Boolean']['input']
  unitSettings: UnitSetting
}

export type QueryExploreOrganizationsArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderByColumn?: Scalars['String']['input']
  orderDirection?: Scalars['String']['input']
  unitSettings: UnitSetting
}

export type QueryExploreOrganizationsV2Args = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  columns?: InputMaybe<Scalars['EncodedExploreOrganizationColumnV2']['input']>
  filterQuery?: InputMaybe<RawFilterQuery>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderAscending?: InputMaybe<Scalars['Boolean']['input']>
  orderByColumn?: InputMaybe<Scalars['Int']['input']>
  unitSettings: UnitSetting
}

export type QueryExplorePayableInvoicesV2Args = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  columns?: InputMaybe<Scalars['EncodedExplorePayableInvoiceColumnV2']['input']>
  filterQuery?: InputMaybe<RawFilterQuery>
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
  orderAscending?: InputMaybe<Scalars['Boolean']['input']>
  orderByColumn?: InputMaybe<Scalars['Int']['input']>
  unitSettings: UnitSetting
}

export type QueryExploreReceivableInvoicesV2Args = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  columns?: InputMaybe<Scalars['EncodedExploreReceivableInvoiceColumnV2']['input']>
  filterQuery?: InputMaybe<RawFilterQuery>
  limit?: Scalars['Int']['input']
  offset?: Scalars['Int']['input']
  orderAscending?: InputMaybe<Scalars['Boolean']['input']>
  orderByColumn?: InputMaybe<Scalars['Int']['input']>
  unitSettings: UnitSetting
}

export type QueryExploreShipmentsArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderByColumn?: Scalars['String']['input']
  orderDirection?: Scalars['String']['input']
  unitSettings: UnitSetting
}

export type QueryExploreShipmentsV2Args = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  columns?: InputMaybe<Scalars['EncodedExploreShipmentColumnV2']['input']>
  filterQuery?: InputMaybe<RawFilterQuery>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderAscending?: InputMaybe<Scalars['Boolean']['input']>
  orderByColumn?: InputMaybe<Scalars['Int']['input']>
  unitSettings: UnitSetting
}

export type QueryExportDashboardArgs = {
  dashboardId: Scalars['UUID']['input']
}

export type QueryFetchCredentialsArgs = {
  jobTemplateName: Scalars['String']['input']
  workflow: Scalars['String']['input']
}

export type QueryFileConnectionArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>
  page: Scalars['Int']['input']
  query: Scalars['String']['input']
  status: Scalars['String']['input']
}

export type QueryFilePagesConnectionArgs = {
  filePageIds: Array<InputMaybe<Scalars['String']['input']>>
}

export type QueryFileSignedUrlArgs = {
  contentType: Scalars['String']['input']
  filename: Scalars['String']['input']
}

export type QueryFindChainIoMatchesFromSoaLineItemsArgs = {
  inputSoaLineItems: Array<InputSoaLineItem>
  jobId: Scalars['UUID']['input']
}

export type QueryFindInvoiceReconResultFromJobArgs = {
  disableSendDueDate: Scalars['Boolean']['input']
  jobId: Scalars['UUID']['input']
  lumpsum?: InputMaybe<InputDocumentTable>
  matchingCriteria?: MatchingCriteriaType
  overrideChargeDescription: Scalars['Boolean']['input']
}

export type QueryFindShipmentReconResultsArgs = {
  jobId: Scalars['UUID']['input']
}

export type QueryFindSoaShipmentsReconResultsArgs = {
  inputSoaLineItems: Array<InputSoaLineItem>
  jobId: Scalars['UUID']['input']
}

export type QueryFreightBiExportReportStatusArgs = {
  reportId: Scalars['String']['input']
}

export type QueryGeoLocationsArgs = {
  query: Scalars['String']['input']
}

export type QueryGetCustomDashboardArgs = {
  dashboardId: Scalars['UUID']['input']
}

export type QueryGetDashboardArgs = {
  dashboardId: Scalars['UUID']['input']
}

export type QueryGetDashboardDefaultFiltersArgs = {
  dashboardId: Scalars['UUID']['input']
}

export type QueryGetDashboardEDocsArgs = {
  apiPartnerId: Scalars['UUID']['input']
  dataTargetType: EDocsDataTargetType
  tmsId: Scalars['String']['input']
}

export type QueryGetDataForCardSqlArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  cardId: Scalars['UUID']['input']
  filterQuery?: InputMaybe<RawFilterQuery>
  isNonAgg?: Scalars['Boolean']['input']
  queryVariables?: InputMaybe<Array<VariableMapping>>
  unitSettings: UnitSetting
}

export type QueryGetDataForHardcodedCardReferenceArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  hardcodedCardReference: InputHardcodedCardReference
  isNonAgg?: Scalars['Boolean']['input']
  unitSettings: UnitSetting
}

export type QueryGetDataResultForShipperFacingCardArgs = {
  cardId: Scalars['UUID']['input']
  filterQuery?: InputMaybe<Array<RawFilterQuery>>
  queryVariables?: InputMaybe<Array<VariableMapping>>
  slug: Scalars['String']['input']
}

export type QueryGetFilteredContainersArgs = {
  filterQuery?: InputMaybe<RawFilterQuery>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderByColumn?: ShipperVizContainerColumn
  orderDirection?: ShipperVizContainerOrder
  slug: Scalars['String']['input']
}

export type QueryGetFilteredShipmentsArgs = {
  filterQuery?: InputMaybe<RawFilterQuery>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderByColumn?: ShipperVizShipmentColumn
  orderDirection?: ShipperVizShipmentOrder
  slug: Scalars['String']['input']
}

export type QueryGetNonAggDataArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  dashboardSubtab: Scalars['String']['input']
  filterQuery?: InputMaybe<RawFilterQuery>
  unitSettings: UnitSetting
}

export type QueryGetPortCoordinatesArgs = {
  portCodes: Array<Scalars['String']['input']>
}

export type QueryGetSavedFiltersArgs = {
  tabName: Scalars['String']['input']
  tabType: Scalars['String']['input']
}

export type QueryGetSavedFiltersAndUnitSettingsArgs = {
  tabType: Scalars['String']['input']
}

export type QueryGetShipperFacingCompanyArgs = {
  slug: Scalars['String']['input']
}

export type QueryInvoiceArgs = {
  apiPartnerIds: Array<Scalars['UUID']['input']>
  invoiceKey: Scalars['String']['input']
  isPosted: Scalars['Boolean']['input']
  unitSettings: UnitSetting
}

export type QueryIsValidReconJobTemplateArgs = {
  jobTemplateId: Scalars['UUID']['input']
}

export type QueryJobArgs = {
  id: Scalars['UUID']['input']
}

export type QueryJobNotesArgs = {
  jobId: Scalars['String']['input']
}

export type QueryJobOverviewPageArgs = {
  filters?: InputMaybe<Array<InputJobFilter>>
  page: Scalars['Int']['input']
  query: Scalars['String']['input']
  status?: InputMaybe<Scalars['String']['input']>
}

export type QueryJobTemplateArgs = {
  companyId?: InputMaybe<Scalars['UUID']['input']>
  id: Scalars['UUID']['input']
}

export type QueryJobTemplatesArgs = {
  companyId?: InputMaybe<Scalars['UUID']['input']>
}

export type QueryJobsArgs = {
  jobIds: Array<InputMaybe<Scalars['String']['input']>>
}

export type QueryListAvailableDashboardsArgs = {
  auth0Id: Scalars['String']['input']
}

export type QueryListAvailableGraphsArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  unitSettings: UnitSetting
}

export type QueryListCardsForDashboardArgs = {
  dashboardId: Scalars['UUID']['input']
}

export type QueryListCardsForExceptionsManagementArgs = {
  slug: Scalars['String']['input']
}

export type QueryListCompaniesArgs = {
  companyIds: Array<Scalars['String']['input']>
}

export type QueryListCompanyDashboardsArgs = {
  companyId?: InputMaybe<Scalars['UUID']['input']>
  shouldWhitelist?: Scalars['Boolean']['input']
  tabType?: InputMaybe<Scalars['String']['input']>
}

export type QueryListDashboardCompanyUsersArgs = {
  companyId: Scalars['UUID']['input']
}

export type QueryListDashboardInsightsUsersArgs = {
  email?: InputMaybe<Scalars['String']['input']>
  includeMetadata?: Scalars['Boolean']['input']
  pageNumber?: Scalars['Int']['input']
  paginate?: Scalars['Boolean']['input']
}

export type QueryListShipperFacingOrganizationsArgs = {
  freightForwarderCompanyId: Scalars['UUID']['input']
  orderByColumnName?: ShipperFacingOrganizationResultOrderByColumn
  orderByIsAsc?: Scalars['Boolean']['input']
  pageNumber?: Scalars['Int']['input']
  resultPerPage?: InputMaybe<Scalars['Int']['input']>
  searchKey?: Scalars['String']['input']
}

export type QueryListShipperFacingPaywallUserRequestArgs = {
  freightForwarderCompanyId: Scalars['UUID']['input']
  matchingString?: Scalars['String']['input']
  orderByColumnName?: PaywallUserRequestResultOrderByColumn
  orderByIsAsc?: Scalars['Boolean']['input']
  pageNumber?: Scalars['Int']['input']
  resultPerPage?: Scalars['Int']['input']
}

export type QueryListShipperFacingUserArgs = {
  freightForwarderCompanyId: Scalars['UUID']['input']
  matchingString?: Scalars['String']['input']
  orderByColumnName?: ShipperFacingUserResultOrderByColumn
  orderByIsAsc?: Scalars['Boolean']['input']
  pageNumber?: Scalars['Int']['input']
  resultPerPage?: Scalars['Int']['input']
}

export type QueryListUserDashboardsWithIamsArgs = {
  auth0Id?: InputMaybe<Scalars['String']['input']>
  companyId?: InputMaybe<Scalars['UUID']['input']>
}

export type QueryListUsersArgs = {
  companyId?: InputMaybe<Scalars['UUID']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  pageNumber?: Scalars['Int']['input']
  paginate?: Scalars['Boolean']['input']
  roles?: InputMaybe<Array<UserRole>>
}

export type QueryMarginsArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  graph: BusinessPerformanceMarginGraph
  unitSettings: UnitSetting
}

export type QueryMatchSoaLineItemsToChainIoShipmentsArgs = {
  jobId: Scalars['String']['input']
  rowsMatchCriteria: Array<InputSoaRowMatchCriteria>
}

export type QueryMostProfitableRoutesArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  unitSettings: UnitSetting
}

export type QueryNegativeMarginShipmentsArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderByColumn?: Scalars['String']['input']
  orderDirection?: Scalars['String']['input']
  unitSettings: UnitSetting
}

export type QueryNotificationsArgs = {
  limit?: Scalars['Int']['input']
}

export type QueryOperatorsArgs = {
  companyId: Scalars['String']['input']
}

export type QueryOrganizationArgs = {
  apiPartnerIds: Array<Scalars['UUID']['input']>
  filterQuery: RawFilterQuery
  organizationKey: Scalars['String']['input']
}

export type QueryOrganizationOverviewCardDataArgs = {
  apiPartnerIds: Array<Scalars['UUID']['input']>
  card: OrganizationOverviewCard
  filterQuery: RawFilterQuery
  organizationKey: Scalars['String']['input']
  unitSettings: UnitSetting
}

export type QueryOrganizationOverviewQueryArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  unitSettings: UnitSetting
}

export type QueryOrganizationsSearchQueryArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  limit?: Scalars['Int']['input']
  matchString: Scalars['String']['input']
  offset: Scalars['Int']['input']
  unitSettings: UnitSetting
}

export type QueryOverviewArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  unitSettings: UnitSetting
}

export type QueryPageAsyncBatchArgs = {
  id: Scalars['UUID']['input']
}

export type QueryPullEDocsBatchArgs = {
  batchPullId: Scalars['UUID']['input']
  totalPulled: Scalars['Int']['input']
}

export type QueryPushEDocsBatchArgs = {
  batchPushId: Scalars['UUID']['input']
  totalPushed: Scalars['Int']['input']
}

export type QueryRecentCargowiseOutboundsArgs = {
  inputCwOutboundFilter: InputCargowiseOutboundFilter
}

export type QueryRecentFieldsArgs = {
  filePageId: Scalars['String']['input']
  jobId: Scalars['String']['input']
}

export type QueryReconAsyncBatchArgs = {
  reconAsyncBatchId: Scalars['String']['input']
}

export type QueryReconAttemptArgs = {
  id: Scalars['String']['input']
}

export type QueryReconAttemptsArgs = {
  reconAsyncBatchId: Scalars['String']['input']
}

export type QueryReconJobArgs = {
  cargowiseModule?: InputMaybe<CwTargetModule>
  invoiceNumber?: InputMaybe<Scalars['String']['input']>
  jobId: Scalars['UUID']['input']
  referenceNumber?: InputMaybe<Scalars['String']['input']>
}

export type QueryReconJobAttemptByInvoiceArgs = {
  invoiceNumber: Scalars['String']['input']
  jobId: Scalars['UUID']['input']
}

export type QueryReconJobAttemptByReferenceArgs = {
  cargowiseModule?: InputMaybe<CwTargetModule>
  invoiceNumber?: InputMaybe<Scalars['String']['input']>
  jobId: Scalars['UUID']['input']
  referenceNumber?: InputMaybe<Scalars['String']['input']>
}

export type QueryReconJobColumnSearchArgs = {
  apiPartnerIds: Array<Scalars['UUID']['input']>
  column: ReconJobListColumn
  query: Scalars['String']['input']
}

export type QueryReconJobInvoiceLinesArgs = {
  cargowiseModule?: InputMaybe<CwTargetModule>
  invoiceNumber?: InputMaybe<Scalars['String']['input']>
  isExpected: Scalars['Boolean']['input']
  jobId: Scalars['UUID']['input']
  referenceNumber?: InputMaybe<Scalars['String']['input']>
}

export type QueryReconJobListArgs = {
  apiPartnerIds: Array<Scalars['UUID']['input']>
  filters: Array<ReconJobListColumnColumnConditionInput>
  marker?: InputMaybe<Scalars['String']['input']>
  pageSize: Scalars['Int']['input']
  sortCol?: ReconJobListColumn
  sortDir?: SortDirection
  tab: ReconJobListTab
  timezone: Scalars['String']['input']
}

export type QueryReconJobListGroupsArgs = {
  apiPartnerIds: Array<Scalars['UUID']['input']>
  filters: Array<ReconJobListColumnColumnConditionInput>
  marker?: InputMaybe<Scalars['String']['input']>
  pageSize: Scalars['Int']['input']
  sortCol?: ReconJobListColumn
  sortDir?: SortDirection
  tab: ReconJobListTab
  timezone: Scalars['String']['input']
}

export type QueryReconJobListTabCountsArgs = {
  apiPartnerIds: Array<Scalars['UUID']['input']>
  byGroup: Scalars['Boolean']['input']
  filters: Array<ReconJobListColumnColumnConditionInput>
  timezone: Scalars['String']['input']
}

export type QueryReconJobNotesArgs = {
  invoiceNumber?: InputMaybe<Scalars['String']['input']>
  jobId: Scalars['UUID']['input']
  reconAttemptId?: InputMaybe<Scalars['UUID']['input']>
}

export type QueryReconJobResultArgs = {
  cargowiseModule?: InputMaybe<CwTargetModule>
  invoiceNumber?: InputMaybe<Scalars['String']['input']>
  isExpected: Scalars['Boolean']['input']
  jobId: Scalars['UUID']['input']
  referenceNumber?: InputMaybe<Scalars['String']['input']>
}

export type QueryReconJobShipmentDetailsArgs = {
  assigneeAuth0Id?: InputMaybe<Scalars['String']['input']>
  invoiceNumber: Scalars['String']['input']
  jobId: Scalars['UUID']['input']
}

export type QueryReconJobShipmentMatchedArgs = {
  cargowiseModule?: InputMaybe<CwTargetModule>
  invoiceNumber?: InputMaybe<Scalars['String']['input']>
  jobId: Scalars['UUID']['input']
  referenceNumber?: InputMaybe<Scalars['String']['input']>
}

export type QueryReconNotesArgs = {
  reconAttemptId: Scalars['String']['input']
}

export type QueryRevenueArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  disaggOthersGroup?: Scalars['Boolean']['input']
  filterQuery?: InputMaybe<RawFilterQuery>
  graph: BusinessPerformanceRevenueGraph
  unitSettings: UnitSetting
}

export type QuerySalesRepsArgs = {
  companyId: Scalars['String']['input']
}

export type QuerySearchShipmentsArgs = {
  cargowiseModule: Scalars['String']['input']
  jobTemplateId: Scalars['UUID']['input']
  searchStr: Scalars['String']['input']
}

export type QuerySearchShipperFacingOrganizationCodeChoicesArgs = {
  freightForwarderCompanyId: Scalars['UUID']['input']
  matchingString: Scalars['String']['input']
}

export type QuerySearchTaxesArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  query: Scalars['String']['input']
}

export type QuerySearchableRecordResultsArgs = {
  filters?: InputMaybe<InputSearchableRecordFilters>
  limit?: Scalars['Int']['input']
  queryString: Scalars['String']['input']
  searchableRecordId: Scalars['String']['input']
}

export type QuerySearchedForCompaniesArgs = {
  query: Scalars['String']['input']
}

export type QuerySendForgotPasswordResetEmailArgs = {
  companyId: Scalars['UUID']['input']
  email: Scalars['String']['input']
}

export type QueryShipmentDetailsByMatchCriteriaArgs = {
  apiPartnerIds: Array<Scalars['UUID']['input']>
  matchString: Scalars['String']['input']
  searchOnlyForwarderReference?: Scalars['Boolean']['input']
  unitSettings: UnitSetting
}

export type QueryShipmentWithMatchCriteriaArgs = {
  jobId: Scalars['UUID']['input']
}

export type QueryShipperFacingContainerGetDefaultColumnsArgs = {
  slug: Scalars['String']['input']
}

export type QueryShipperFacingDashboardColumnSearchResultsArgs = {
  searchKey?: Scalars['String']['input']
  slug: Scalars['String']['input']
  tableColumn: Scalars['String']['input']
}

export type QueryShipperFacingDashboardFieldMetadataResultsArgs = {
  slug: Scalars['String']['input']
}

export type QueryShipperFacingExportReportStatusArgs = {
  reportId: Scalars['String']['input']
  slug: Scalars['String']['input']
}

export type QueryShipperFacingSearchShipmentsArgs = {
  query: Scalars['String']['input']
  slug: Scalars['String']['input']
}

export type QueryShipperFacingShipmentDetailsArgs = {
  companyShipmentKey: Scalars['String']['input']
  slug: Scalars['String']['input']
}

export type QueryShipperFacingShipmentGetDefaultColumnsArgs = {
  slug: Scalars['String']['input']
}

export type QueryShipperFacingShipmentGetDefaultFiltersArgs = {
  slug: Scalars['String']['input']
  tabName: Scalars['String']['input']
}

export type QuerySignCompanyLogoUriArgs = {
  companyId: Scalars['UUID']['input']
}

export type QuerySoaJobExpectedChargesArgs = {
  checkShipmentInfoAsyncBatchId: Scalars['UUID']['input']
}

export type QuerySoaReconAttemptsHistoryArgs = {
  getSavedOnly?: Scalars['Boolean']['input']
  jobId: Scalars['UUID']['input']
  marker?: InputMaybe<Scalars['String']['input']>
  pageSize?: Scalars['Int']['input']
}

export type QueryTaskArgs = {
  id: Scalars['String']['input']
}

export type QueryTaskNotesArgs = {
  taskId: Scalars['String']['input']
}

export type QueryTaskPagesArgs = {
  filters?: InputMaybe<Array<InputTaskFilter>>
  pageUpTo: Scalars['Int']['input']
  query?: InputMaybe<Scalars['String']['input']>
}

export type QueryTaskPagesIngestArgs = {
  filters?: InputMaybe<Array<InputTaskFilter>>
  pageUpTo: Scalars['Int']['input']
  query?: Scalars['String']['input']
}

export type QueryTasksByTitleReferenceIdArgs = {
  companyId?: InputMaybe<Scalars['UUID']['input']>
  query: Scalars['String']['input']
}

export type QueryTaxIdsByApiPartnerArgs = {
  apiPartnerId: Scalars['UUID']['input']
}

export type QueryTaxIdsByCargowiseConfigArgs = {
  cargowiseConfigId: Scalars['UUID']['input']
}

export type QueryTopExpensesArgs = {
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']>>
  filterQuery?: InputMaybe<RawFilterQuery>
  unitSettings: UnitSetting
}

export type QueryTransactionAsyncBatchArgs = {
  transactionAsyncBatchId: Scalars['String']['input']
}

export type QueryUniqueChargeCodesByCompanyPartnerArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  codes?: InputMaybe<Array<Scalars['String']['input']>>
  companyId: Scalars['UUID']['input']
  vendorCodes?: InputMaybe<Array<Scalars['String']['input']>>
}

export type QueryUniqueChargeCodesByTaxArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  taxId: Scalars['UUID']['input']
}

export type QueryUniqueChargeVendorsByCompanyArgs = {
  codes?: InputMaybe<Array<Scalars['String']['input']>>
  companyId: Scalars['UUID']['input']
}

export type QueryUserJobTemplatesArgs = {
  companyIds?: InputMaybe<Array<Scalars['String']['input']>>
}

export type QueryUsersByRoleArgs = {
  role: UserRole
}

export type QueryValidSearchableRecordResultsArgs = {
  searchableRecordInputs: Array<InputSearchableRecordColumn>
}

export type QueryVendorChargeCodeOverridesArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  chargeVendorId: Scalars['UUID']['input']
  pageLimit?: InputMaybe<Scalars['Int']['input']>
  pageOffset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryVendorChargeCodeUrlArgs = {
  filename: Scalars['String']['input']
}

export type QueryVendorChargeCodesArgs = {
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  chargeVendorId: Scalars['UUID']['input']
  pageLimit?: InputMaybe<Scalars['Int']['input']>
  pageOffset?: InputMaybe<Scalars['Int']['input']>
}

export type RateLocationStringNode = {
  __typename?: 'RateLocationStringNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  geoLocation: GeoLocationNode
  geoLocationId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  rateLocation: Scalars['String']['output']
}

export type RawCargowiseInboundNode = {
  __typename?: 'RawCargowiseInboundNode'
  credential?: Maybe<CredentialNode>
  credentialId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  rawResponseStatus?: Maybe<Scalars['Int']['output']>
  s3Uri: Scalars['String']['output']
}

export type RawCargowiseOutboundNode = {
  __typename?: 'RawCargowiseOutboundNode'
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  integrationId?: Maybe<Scalars['String']['output']>
  processed: Scalars['Boolean']['output']
  queryParamIntegrationId?: Maybe<Scalars['String']['output']>
  rawCargowiseInbound?: Maybe<RawCargowiseInboundNode>
  rawCargowiseInboundId?: Maybe<Scalars['UUID']['output']>
  s3Uri: Scalars['String']['output']
  signedViewUrl: Scalars['String']['output']
}

export type RawFilterQuery = {
  children1?: InputMaybe<Array<RawFilterQuery>>
  id?: InputMaybe<Scalars['UUID']['input']>
  path?: InputMaybe<Array<Scalars['UUID']['input']>>
  properties?: InputMaybe<RawFilterQueryProperty>
  type: Scalars['String']['input']
}

export type RawFilterQueryProperty = {
  conjunction?: InputMaybe<Scalars['String']['input']>
  field?: InputMaybe<Scalars['String']['input']>
  fieldSrc?: InputMaybe<Scalars['String']['input']>
  isLocked?: InputMaybe<Scalars['Boolean']['input']>
  notFlag?: InputMaybe<Scalars['Boolean']['input']>
  operator?: InputMaybe<Scalars['String']['input']>
  operatorOptions?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Array<InputMaybe<RawFilterValue>>>
  valueSrc?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  valueType?: InputMaybe<Array<Scalars['String']['input']>>
}

export type RawFilterValue = {
  unit?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export type ReconAsyncBatchNode = {
  __typename?: 'ReconAsyncBatchNode'
  asLumpsum: Scalars['Boolean']['output']
  autoReconAsyncBatch?: Maybe<AutoReconAsyncBatchNode>
  autoReconAsyncBatchId?: Maybe<Scalars['UUID']['output']>
  batchResolutionStatus: ResolutionStatus
  dateCompleted?: Maybe<Scalars['DateTime']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  disableSendDueDate: Scalars['Boolean']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  isAutoGenerated: Scalars['Boolean']['output']
  job?: Maybe<JobNode>
  jobId?: Maybe<Scalars['UUID']['output']>
  matchingCriteria: MatchingCriteriaTypeWithFallback
  numDone: Scalars['Int']['output']
  numTasks: Scalars['Int']['output']
  overrideChargeDescription: Scalars['Boolean']['output']
  reconAttempts: ReconAttemptNodeConnection
  reranBy?: Maybe<ReconAsyncBatchNode>
  reranFrom?: Maybe<ReconAsyncBatchNode>
  reranFromId?: Maybe<Scalars['UUID']['output']>
  status: ReconAsyncStatusWithFallback
  userCreated: UserNode
  userCreatedId: Scalars['UUID']['output']
}

export type ReconAsyncBatchNodeConnection = {
  __typename?: 'ReconAsyncBatchNodeConnection'
  edges: Array<ReconAsyncBatchNodeEdge>
}

export type ReconAsyncBatchNodeEdge = {
  __typename?: 'ReconAsyncBatchNodeEdge'
  node: ReconAsyncBatchNode
}

export type ReconAsyncBatchNodePaginatedResults = {
  __typename?: 'ReconAsyncBatchNodePaginatedResults'
  hasNext: Scalars['Boolean']['output']
  hasPrevious: Scalars['Boolean']['output']
  nextMarker?: Maybe<Scalars['String']['output']>
  previousMarker?: Maybe<Scalars['String']['output']>
  results: Array<ReconAsyncBatchNode>
}

export enum ReconAsyncStatus {
  Done = 'DONE',
  Error = 'ERROR',
  Pending = 'PENDING',
}

export type ReconAsyncStatusEnum = {
  __typename?: 'ReconAsyncStatusEnum'
  isFallback: Scalars['Boolean']['output']
  value: ReconAsyncStatus
}

export type ReconAsyncStatusWithFallback = FallbackValue | ReconAsyncStatusEnum

export type ReconAttemptNode = {
  __typename?: 'ReconAttemptNode'
  asLumpsum: Scalars['Boolean']['output']
  autoReconAsyncBatch?: Maybe<AutoReconAsyncBatchNode>
  autoReconAsyncBatchId?: Maybe<Scalars['UUID']['output']>
  autoReconResult?: Maybe<AutoReconResultNode>
  cargowiseModule?: Maybe<CwTargetModuleWithFallback>
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  cleanedConsolNumber?: Maybe<Scalars['String']['output']>
  cleanedForwarderReference?: Maybe<Scalars['String']['output']>
  cleanedInvoiceNumber?: Maybe<Scalars['String']['output']>
  cleanedVendor?: Maybe<Scalars['String']['output']>
  currencies?: Maybe<Array<Scalars['String']['output']>>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dateResolved?: Maybe<Scalars['DateTime']['output']>
  dateSaved?: Maybe<Scalars['DateTime']['output']>
  dateUnsaved?: Maybe<Scalars['DateTime']['output']>
  disableSendDueDate: Scalars['Boolean']['output']
  docCharges: Array<ChargeDetail>
  dueDateV2?: Maybe<Scalars['Date']['output']>
  errorNotes?: Maybe<Scalars['String']['output']>
  expectedCharges: Array<ChargeDetail>
  expedockAction?: Maybe<Scalars['String']['output']>
  fieldValueMap?: Maybe<Scalars['JSONString']['output']>
  fieldValueMapV2?: Maybe<Scalars['JSONString']['output']>
  findShipmentReconResult?: Maybe<FindShipmentReconResultNode>
  id: Scalars['UUID']['output']
  invoiceChargeVendorId?: Maybe<Scalars['UUID']['output']>
  invoiceDateV2?: Maybe<Scalars['Date']['output']>
  invoiceUniqueIdentifier?: Maybe<Scalars['String']['output']>
  isAutoGenerated: Scalars['Boolean']['output']
  isEqualToPrevRecon?: Maybe<Scalars['Boolean']['output']>
  isSaved: Scalars['Boolean']['output']
  isSoaMetadataAttempt?: Maybe<Scalars['Boolean']['output']>
  job: JobNode
  jobId: Scalars['UUID']['output']
  jobReconUpdateStatuses: JobReconUpdateStatusNodeConnection
  matchReconInvoiceShipmentReference?: Maybe<ReconInvoiceShipmentReferenceNode>
  matchReconInvoiceShipmentReferenceId?: Maybe<Scalars['UUID']['output']>
  matchingCriteria: MatchingCriteriaTypeWithFallback
  maximalDuplicateConsolidations?: Maybe<Array<Scalars['UUID']['output']>>
  maximalDuplicateShipments?: Maybe<Array<Scalars['UUID']['output']>>
  noMatchReconMatchCriteria?: Maybe<ReconMatchCriteriaNode>
  noMatchReconMatchCriteriaId?: Maybe<Scalars['UUID']['output']>
  overrideChargeDescription: Scalars['Boolean']['output']
  overrideSellAmountAndCurrency: Scalars['Boolean']['output']
  partialChainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  partialChainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  reconAsyncBatch?: Maybe<ReconAsyncBatchNode>
  reconAsyncBatchId?: Maybe<Scalars['UUID']['output']>
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconDetail: ReconDetail
  reconInvoiceShipmentReference?: Maybe<ReconInvoiceShipmentReferenceNode>
  reconMatchCriteria?: Maybe<ReconMatchCriteriaNode>
  reconNotes: ReconNoteNodeConnection
  reconResults: Array<ReconResultInterface>
  reconStatus: ReconciliationStatus
  reconciliationStatus: ReconciliationStatus
  reranBy?: Maybe<ReconAttemptNode>
  reranFrom: ReconAttemptNodeConnection
  reranFromId?: Maybe<Scalars['UUID']['output']>
  resolutionStatus: ResolutionStatus
  totalChargeCost?: Maybe<Scalars['Decimal']['output']>
  totalQuantity?: Maybe<Scalars['Int']['output']>
  userCreated: UserNode
  userCreatedId: Scalars['UUID']['output']
  userResolved?: Maybe<UserNode>
  userResolvedId?: Maybe<Scalars['UUID']['output']>
  vendorCode?: Maybe<Scalars['String']['output']>
}

export type ReconAttemptNodeConnection = {
  __typename?: 'ReconAttemptNodeConnection'
  edges: Array<ReconAttemptNodeEdge>
}

export type ReconAttemptNodeEdge = {
  __typename?: 'ReconAttemptNodeEdge'
  node: ReconAttemptNode
}

export type ReconAttemptNodePaginatedResults = {
  __typename?: 'ReconAttemptNodePaginatedResults'
  hasNext: Scalars['Boolean']['output']
  hasPrevious: Scalars['Boolean']['output']
  nextMarker?: Maybe<Scalars['String']['output']>
  previousMarker?: Maybe<Scalars['String']['output']>
  results: Array<ReconAttemptNode>
}

export type ReconAttemptReconResultNode = {
  __typename?: 'ReconAttemptReconResultNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  reconAttempt: ReconAttemptNode
  reconAttemptId: Scalars['UUID']['output']
  reconResult: ReconResultInterface
  reconResultId: Scalars['UUID']['output']
}

export type ReconAttemptReconResultNodeConnection = {
  __typename?: 'ReconAttemptReconResultNodeConnection'
  edges: Array<ReconAttemptReconResultNodeEdge>
}

export type ReconAttemptReconResultNodeEdge = {
  __typename?: 'ReconAttemptReconResultNodeEdge'
  node: ReconAttemptReconResultNode
}

export type ReconDashboardUserFilterNode = {
  __typename?: 'ReconDashboardUserFilterNode'
  auth0Id: Scalars['String']['output']
  column: ReconJobListColumn
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  values: Array<Scalars['String']['output']>
  variant: FilterVariant
}

export type ReconDetail = {
  __typename?: 'ReconDetail'
  associatedEntities: Array<ChainIoShipmentNode>
  carrierBookingNo?: Maybe<Scalars['String']['output']>
  consolNo?: Maybe<Scalars['String']['output']>
  containerNo?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['String']['output']>
  hblNo?: Maybe<Scalars['String']['output']>
  invoiceNo?: Maybe<Scalars['String']['output']>
  mblNo?: Maybe<Scalars['String']['output']>
  orderNo?: Maybe<Scalars['String']['output']>
  referenceNo?: Maybe<Scalars['String']['output']>
  shipmentNo?: Maybe<Scalars['String']['output']>
  tmsId?: Maybe<Scalars['String']['output']>
  vendor?: Maybe<Scalars['String']['output']>
}

export type ReconEnums = {
  __typename?: 'ReconEnums'
  anReconAutofillKey: AnReconAutofillKey
  apReconAutofillKey: ApReconAutofillKey
  apReconPrimaryAutofillKey: ApReconPrimaryAutofillKey
  apReconSecondaryAutofillKey: ApReconSecondaryAutofillKey
  metadataReconResultKey: MetadataReconResultKey
}

export type ReconInvoiceNode = {
  __typename?: 'ReconInvoiceNode'
  apiPartner: ApiPartnerInterface
  apiPartnerId: Scalars['UUID']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  invoiceNumber: Scalars['String']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  reconInvoiceShipmentReferences: ReconInvoiceShipmentReferenceNodeConnection
  reconMatchCriterias: ReconMatchCriteriaNodeConnection
}

export type ReconInvoiceShipmentReferenceNode = {
  __typename?: 'ReconInvoiceShipmentReferenceNode'
  approvedForPosting?: Maybe<Scalars['Boolean']['output']>
  cargowiseModule: CwTargetModuleWithFallback
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  externalAssignee?: Maybe<UserNode>
  externalAssigneeId?: Maybe<Scalars['UUID']['output']>
  externalStatus?: Maybe<JobExternalStatus>
  id: Scalars['UUID']['output']
  isModified: Scalars['Boolean']['output']
  reconAttempt?: Maybe<ReconAttemptNode>
  reconInvoice: ReconInvoiceNode
  reconInvoiceId: Scalars['UUID']['output']
  reconInvoiceShipmentReference: ReconInvoiceNode
  tmsId: Scalars['String']['output']
}

export type ReconInvoiceShipmentReferenceNodeConnection = {
  __typename?: 'ReconInvoiceShipmentReferenceNodeConnection'
  edges: Array<ReconInvoiceShipmentReferenceNodeEdge>
}

export type ReconInvoiceShipmentReferenceNodeEdge = {
  __typename?: 'ReconInvoiceShipmentReferenceNodeEdge'
  node: ReconInvoiceShipmentReferenceNode
}

export type ReconJob = {
  __typename?: 'ReconJob'
  autoPostByDefault: Scalars['Boolean']['output']
  canPost?: Maybe<Scalars['Boolean']['output']>
  companyId: Scalars['UUID']['output']
  externalAssigneeId?: Maybe<Scalars['String']['output']>
  externalStatus?: Maybe<JobExternalStatus>
  id: Scalars['String']['output']
  isReconciled: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  reconType: JobTemplateReconType
  showPostButton: Scalars['Boolean']['output']
  status: JobStatus
}

export type ReconJobAttemptByInvoice = {
  __typename?: 'ReconJobAttemptByInvoice'
  dateModified?: Maybe<Scalars['DateTime']['output']>
  disableSendDueDate?: Maybe<Scalars['Boolean']['output']>
  externalStatus?: Maybe<JobExternalStatus>
  extractedDueDate?: Maybe<Scalars['String']['output']>
  extractedInvoiceDate?: Maybe<Scalars['String']['output']>
  extractedInvoiceNumber?: Maybe<Scalars['String']['output']>
  extractedVendorName?: Maybe<Scalars['String']['output']>
  matchedAsLumpsum?: Maybe<Scalars['Boolean']['output']>
  matchingCriteria?: Maybe<MatchingCriteriaType>
  overrideChargeDescription?: Maybe<Scalars['Boolean']['output']>
  reconInvoiceId?: Maybe<Scalars['UUID']['output']>
  shipmentFound: Scalars['Boolean']['output']
}

export type ReconJobAttemptByReference = {
  __typename?: 'ReconJobAttemptByReference'
  approvedForPosting?: Maybe<Scalars['Boolean']['output']>
  cargowiseModule?: Maybe<CwTargetModule>
  dateModified?: Maybe<Scalars['DateTime']['output']>
  disableSendDueDate?: Maybe<Scalars['Boolean']['output']>
  errorNotes?: Maybe<Scalars['String']['output']>
  externalAssigneeId?: Maybe<Scalars['String']['output']>
  externalStatus?: Maybe<JobExternalStatus>
  extractedConsolNumber?: Maybe<Scalars['String']['output']>
  extractedDueDate?: Maybe<Scalars['String']['output']>
  extractedHouseBillNumber?: Maybe<Scalars['String']['output']>
  extractedInvoiceDate?: Maybe<Scalars['String']['output']>
  extractedInvoiceNumber?: Maybe<Scalars['String']['output']>
  extractedMasterBillNumber?: Maybe<Scalars['String']['output']>
  extractedReferenceNumber?: Maybe<Scalars['String']['output']>
  extractedVendorName?: Maybe<Scalars['String']['output']>
  matchedAsLumpsum?: Maybe<Scalars['Boolean']['output']>
  matchingCriteria?: Maybe<MatchingCriteriaType>
  overrideChargeDescription?: Maybe<Scalars['Boolean']['output']>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  shipmentFound: Scalars['Boolean']['output']
}

export type ReconJobInvoiceLine = {
  __typename?: 'ReconJobInvoiceLine'
  chargeCode?: Maybe<Scalars['String']['output']>
  chargeDescription?: Maybe<Scalars['String']['output']>
  cost?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceNumber?: Maybe<Scalars['String']['output']>
  isChargeCodeSame?: Maybe<Scalars['Boolean']['output']>
  isChargeVendorSame?: Maybe<Scalars['Boolean']['output']>
  isCurrencySameOrEmpty?: Maybe<Scalars['Boolean']['output']>
  isDiscrepant?: Maybe<Scalars['Boolean']['output']>
  isInvoiceNumberCorrect?: Maybe<Scalars['Boolean']['output']>
  isInvoiceNumberSame?: Maybe<Scalars['Boolean']['output']>
  isMissing: Scalars['Boolean']['output']
  isPosted?: Maybe<Scalars['Boolean']['output']>
  isTotalCostEqual?: Maybe<Scalars['Boolean']['output']>
  primaryMatchKey: Scalars['String']['output']
  secondaryMatchKey?: Maybe<Scalars['String']['output']>
  taxId?: Maybe<Scalars['String']['output']>
  vendorCode?: Maybe<Scalars['String']['output']>
  vendorName?: Maybe<Scalars['String']['output']>
}

export enum ReconJobListColumn {
  CargowiseCompany = 'CARGOWISE_COMPANY',
  CargowiseModule = 'CARGOWISE_MODULE',
  ConsolCarrierBookingRef = 'CONSOL_CARRIER_BOOKING_REF',
  ConsolForwarderReference = 'CONSOL_FORWARDER_REFERENCE',
  DaysPastDue = 'DAYS_PAST_DUE',
  DaysPending = 'DAYS_PENDING',
  ExtractedCurrency = 'EXTRACTED_CURRENCY',
  ExtractedDueDate = 'EXTRACTED_DUE_DATE',
  ExtractedErrorNotes = 'EXTRACTED_ERROR_NOTES',
  ExtractedInvoiceNumber = 'EXTRACTED_INVOICE_NUMBER',
  ExtractedReferenceNumber = 'EXTRACTED_REFERENCE_NUMBER',
  ExtractedTotalAmount = 'EXTRACTED_TOTAL_AMOUNT',
  ExtractedVendorCode = 'EXTRACTED_VENDOR_CODE',
  ExtractedVendorName = 'EXTRACTED_VENDOR_NAME',
  InvoiceIsPosted = 'INVOICE_IS_POSTED',
  InvoiceTotalCost = 'INVOICE_TOTAL_COST',
  JmsAssignee = 'JMS_ASSIGNEE',
  JmsLink = 'JMS_LINK',
  JobErrorNotes = 'JOB_ERROR_NOTES',
  JobErrorNotesEqualsExtracted = 'JOB_ERROR_NOTES_EQUALS_EXTRACTED',
  JobExternalAssignee = 'JOB_EXTERNAL_ASSIGNEE',
  JobExternalAssigneeEmail = 'JOB_EXTERNAL_ASSIGNEE_EMAIL',
  JobExternalStatus = 'JOB_EXTERNAL_STATUS',
  JobId = 'JOB_ID',
  JobInternalStatus = 'JOB_INTERNAL_STATUS',
  JobName = 'JOB_NAME',
  JobType = 'JOB_TYPE',
  LastActivity = 'LAST_ACTIVITY',
  MoveToDoneDate = 'MOVE_TO_DONE_DATE',
  MoveToDoneReason = 'MOVE_TO_DONE_REASON',
  ReconAttemptApprovedForPosting = 'RECON_ATTEMPT_APPROVED_FOR_POSTING',
  ReconAttemptCount = 'RECON_ATTEMPT_COUNT',
  ReconAttemptId = 'RECON_ATTEMPT_ID',
  ReconAttemptSaved = 'RECON_ATTEMPT_SAVED',
  ReconAttemptSavedCount = 'RECON_ATTEMPT_SAVED_COUNT',
  ReprocessAutoPostAfterFix = 'REPROCESS_AUTO_POST_AFTER_FIX',
  ReprocessDateRequested = 'REPROCESS_DATE_REQUESTED',
  ReprocessLatestAdditionalNote = 'REPROCESS_LATEST_ADDITIONAL_NOTE',
  ReprocessLatestCorrectedInformation = 'REPROCESS_LATEST_CORRECTED_INFORMATION',
  ReprocessLatestDetailToCorrect = 'REPROCESS_LATEST_DETAIL_TO_CORRECT',
  ShipmentAndInvoiceNumbers = 'SHIPMENT_AND_INVOICE_NUMBERS',
  ShipmentBranch = 'SHIPMENT_BRANCH',
  ShipmentDepartment = 'SHIPMENT_DEPARTMENT',
  ShipmentForwarderReference = 'SHIPMENT_FORWARDER_REFERENCE',
  ShipmentHouseBill = 'SHIPMENT_HOUSE_BILL',
  ShipmentMasterBill = 'SHIPMENT_MASTER_BILL',
  ShipmentOperationsStaffName = 'SHIPMENT_OPERATIONS_STAFF_NAME',
  ShipmentTransportMode = 'SHIPMENT_TRANSPORT_MODE',
  TaskDateConfirmed = 'TASK_DATE_CONFIRMED',
  TaskDateReceived = 'TASK_DATE_RECEIVED',
}

export type ReconJobListColumnColumnConditionInput = {
  booleanFilterEquals?: InputMaybe<BooleanFilterEquals>
  column: ReconJobListColumn
  dateFilterBetween?: InputMaybe<DateFilterBetween>
  dateFilterGreaterThanOrEquals?: InputMaybe<DateFilterGreaterThanOrEquals>
  numericFilterBetween?: InputMaybe<NumericFilterBetween>
  numericFilterEmpty?: InputMaybe<NumericFilterEmpty>
  numericFilterEquals?: InputMaybe<NumericFilterEquals>
  numericFilterGreaterThan?: InputMaybe<NumericFilterGreaterThan>
  numericFilterGreaterThanOrEquals?: InputMaybe<NumericFilterGreaterThanOrEquals>
  numericFilterLessThan?: InputMaybe<NumericFilterLessThan>
  numericFilterLessThanOrEquals?: InputMaybe<NumericFilterLessThanOrEquals>
  numericFilterNotEmpty?: InputMaybe<NumericFilterNotEmpty>
  numericFilterNotEquals?: InputMaybe<NumericFilterNotEquals>
  textFilterIn?: InputMaybe<TextFilterIn>
  textFilterMatch?: InputMaybe<TextFilterMatch>
  variant: FilterVariant
}

export type ReconJobListGroup = {
  __typename?: 'ReconJobListGroup'
  id: Scalars['String']['output']
  invoiceNumber?: Maybe<Scalars['String']['output']>
  items: Array<ReconJobListItem>
}

export type ReconJobListGroupReconJobListItem = ReconJobListGroup | ReconJobListItem

export type ReconJobListItem = {
  __typename?: 'ReconJobListItem'
  cargowiseCompany?: Maybe<Scalars['String']['output']>
  cargowiseModule?: Maybe<CwTargetModule>
  consolCarrierBookingRef?: Maybe<Scalars['String']['output']>
  consolForwarderReference?: Maybe<Scalars['String']['output']>
  dateConfirmed?: Maybe<Scalars['DateTime']['output']>
  dateReceived?: Maybe<Scalars['DateTime']['output']>
  daysPastDue?: Maybe<Scalars['Int']['output']>
  daysPending?: Maybe<Scalars['Int']['output']>
  externalAssignee?: Maybe<Scalars['String']['output']>
  externalAssigneeEmail?: Maybe<Scalars['String']['output']>
  externalStatus?: Maybe<JobExternalStatus>
  extractedCurrency?: Maybe<Scalars['String']['output']>
  extractedDueDate?: Maybe<Scalars['Date']['output']>
  extractedErrorNotes?: Maybe<Scalars['String']['output']>
  extractedInvoiceNumber?: Maybe<Scalars['String']['output']>
  extractedReferenceNumber?: Maybe<Scalars['String']['output']>
  extractedTotalAmount?: Maybe<Scalars['Float']['output']>
  extractedVendorCode?: Maybe<Scalars['String']['output']>
  extractedVendorName?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  internalStatus?: Maybe<JobStatus>
  invoiceIsPosted?: Maybe<Scalars['Boolean']['output']>
  invoiceTotalCost?: Maybe<Scalars['Decimal']['output']>
  jmsAssignee?: Maybe<Scalars['String']['output']>
  jmsLink?: Maybe<Scalars['String']['output']>
  jobErrorNotes?: Maybe<Scalars['String']['output']>
  jobErrorNotesEqualsExtracted?: Maybe<Scalars['Boolean']['output']>
  jobId: Scalars['String']['output']
  jobType?: Maybe<Scalars['String']['output']>
  lastActivity?: Maybe<Scalars['String']['output']>
  moveToDoneDate?: Maybe<Scalars['DateTime']['output']>
  moveToDoneReason?: Maybe<JobMoveToDoneReason>
  name: Scalars['String']['output']
  reconAttemptApprovedForPosting?: Maybe<Scalars['Boolean']['output']>
  reconAttemptCount?: Maybe<Scalars['Int']['output']>
  reconAttemptId?: Maybe<Scalars['String']['output']>
  reconAttemptSaved?: Maybe<Scalars['Boolean']['output']>
  reconAttemptSavedCount?: Maybe<Scalars['Int']['output']>
  reprocessAutoPostAfterFix?: Maybe<Scalars['Boolean']['output']>
  reprocessDateRequested?: Maybe<Scalars['DateTime']['output']>
  reprocessLatestAdditionalNote?: Maybe<Scalars['String']['output']>
  reprocessLatestCorrectedInformation?: Maybe<Scalars['String']['output']>
  reprocessLatestDetailToCorrect?: Maybe<JobRequestReprocessReason>
  shipmentAndInvoiceNumbers?: Maybe<Scalars['String']['output']>
  shipmentBranch?: Maybe<Scalars['String']['output']>
  shipmentDepartment?: Maybe<Scalars['String']['output']>
  shipmentForwarderReference?: Maybe<Scalars['String']['output']>
  shipmentHouseBill?: Maybe<Scalars['String']['output']>
  shipmentMasterBill?: Maybe<Scalars['String']['output']>
  shipmentOperationsStaffName?: Maybe<Scalars['String']['output']>
  shipmentTransportMode?: Maybe<Scalars['String']['output']>
}

export type ReconJobListItemPaginatedResults = {
  __typename?: 'ReconJobListItemPaginatedResults'
  hasNext: Scalars['Boolean']['output']
  hasPrevious: Scalars['Boolean']['output']
  nextMarker?: Maybe<Scalars['String']['output']>
  previousMarker?: Maybe<Scalars['String']['output']>
  results: Array<ReconJobListItem>
}

export enum ReconJobListTab {
  All = 'ALL',
  Done = 'DONE',
  ForExpedock = 'FOR_EXPEDOCK',
  ForOthers = 'FOR_OTHERS',
  Matched = 'MATCHED',
  NoShipmentFound = 'NO_SHIPMENT_FOUND',
  Todo = 'TODO',
}

export type ReconJobListTabCounts = {
  __typename?: 'ReconJobListTabCounts'
  all: Scalars['String']['output']
  done: Scalars['String']['output']
  forExpedock: Scalars['String']['output']
  forOthers: Scalars['String']['output']
  matched: Scalars['String']['output']
  noShipmentFound: Scalars['String']['output']
  todo: Scalars['String']['output']
}

export type ReconJobResult = {
  __typename?: 'ReconJobResult'
  currency?: Maybe<Scalars['String']['output']>
  isAmountEqual?: Maybe<Scalars['Boolean']['output']>
  isTaxedAmountEqual?: Maybe<Scalars['Boolean']['output']>
  matchedAsLumpsum?: Maybe<Scalars['Boolean']['output']>
  matchingCriteria?: Maybe<MatchingCriteriaType>
  overrideChargeDescription?: Maybe<Scalars['Boolean']['output']>
  overrideDueDate?: Maybe<Scalars['Boolean']['output']>
  totalAmount?: Maybe<Scalars['Decimal']['output']>
  totalAmountWithTax?: Maybe<Scalars['Decimal']['output']>
}

export type ReconJobShipmentDetail = {
  __typename?: 'ReconJobShipmentDetail'
  approvedForPosting?: Maybe<Scalars['Boolean']['output']>
  cargowiseModule: CwTargetModule
  errorNotes?: Maybe<Scalars['String']['output']>
  externalAssignee?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  invoiceNumber: Scalars['String']['output']
  jobId: Scalars['String']['output']
  referenceNumber: Scalars['String']['output']
}

export type ReconJobShipmentMatched = {
  __typename?: 'ReconJobShipmentMatched'
  cargowiseHyperlink?: Maybe<Array<CargowiseHyperlink>>
  shipmentBranches?: Maybe<Scalars['String']['output']>
  shipmentCarrierBookingRef?: Maybe<Scalars['String']['output']>
  shipmentConsolNumber?: Maybe<Scalars['String']['output']>
  shipmentForwarderReference?: Maybe<Scalars['String']['output']>
  shipmentHouseBill?: Maybe<Scalars['String']['output']>
  shipmentMasterBill?: Maybe<Scalars['String']['output']>
  shipmentMatchFields: Array<ShipmentMatchField>
  shipmentOperationsStaffs?: Maybe<Scalars['String']['output']>
  shipmentSalesStaffs?: Maybe<Scalars['String']['output']>
}

export type ReconMatchCriteriaNode = {
  __typename?: 'ReconMatchCriteriaNode'
  approvedForPosting?: Maybe<Scalars['Boolean']['output']>
  cargowiseModule: CwTargetModuleWithFallback
  carrierBookingNumber?: Maybe<Scalars['String']['output']>
  consolNumber?: Maybe<Scalars['String']['output']>
  containerNumber?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  externalAssignee?: Maybe<UserNode>
  externalAssigneeId?: Maybe<Scalars['UUID']['output']>
  externalStatus?: Maybe<JobExternalStatus>
  hblNumber?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  isModified: Scalars['Boolean']['output']
  mblNumber?: Maybe<Scalars['String']['output']>
  orderNumber?: Maybe<Scalars['String']['output']>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconInvoice: ReconInvoiceNode
  reconInvoiceId: Scalars['UUID']['output']
  reconMatchCriteria: ReconInvoiceNode
  shipmentNumber?: Maybe<Scalars['String']['output']>
}

export type ReconMatchCriteriaNodeConnection = {
  __typename?: 'ReconMatchCriteriaNodeConnection'
  edges: Array<ReconMatchCriteriaNodeEdge>
}

export type ReconMatchCriteriaNodeEdge = {
  __typename?: 'ReconMatchCriteriaNodeEdge'
  node: ReconMatchCriteriaNode
}

export type ReconNoteNode = {
  __typename?: 'ReconNoteNode'
  content?: Maybe<Scalars['JSONString']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  reconAttempt: ReconAttemptNode
  reconAttemptId: Scalars['UUID']['output']
  user?: Maybe<UserNode>
  userId?: Maybe<Scalars['UUID']['output']>
}

export type ReconNoteNodeConnection = {
  __typename?: 'ReconNoteNodeConnection'
  edges: Array<ReconNoteNodeEdge>
}

export type ReconNoteNodeEdge = {
  __typename?: 'ReconNoteNodeEdge'
  node: ReconNoteNode
}

export type ReconResultFallbackNode = ReconResultInterface & {
  __typename?: 'ReconResultFallbackNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type ReconResultInterface = {
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export enum ReconResultType {
  ArrivalNoticeChargeRateReconResult = 'ARRIVAL_NOTICE_CHARGE_RATE_RECON_RESULT',
  ArrivalNoticeConsolTypeReconResult = 'ARRIVAL_NOTICE_CONSOL_TYPE_RECON_RESULT',
  ArrivalNoticeContainerReconResult = 'ARRIVAL_NOTICE_CONTAINER_RECON_RESULT',
  ArrivalNoticeContractReconResult = 'ARRIVAL_NOTICE_CONTRACT_RECON_RESULT',
  ArrivalNoticeLumpsumReconResult = 'ARRIVAL_NOTICE_LUMPSUM_RECON_RESULT',
  ArrivalNoticeMetadataReconResult = 'ARRIVAL_NOTICE_METADATA_RECON_RESULT',
  FindConsolReconResult = 'FIND_CONSOL_RECON_RESULT',
  FindInvoiceReconResult = 'FIND_INVOICE_RECON_RESULT',
  FindShipmentReconResult = 'FIND_SHIPMENT_RECON_RESULT',
  InvoiceLineItemReconResult = 'INVOICE_LINE_ITEM_RECON_RESULT',
  InvoiceTotalReconResult = 'INVOICE_TOTAL_RECON_RESULT',
  MetadataReconResult = 'METADATA_RECON_RESULT',
  ShipmentStaffReconResult = 'SHIPMENT_STAFF_RECON_RESULT',
  SoaTotalReconResult = 'SOA_TOTAL_RECON_RESULT',
}

export type ReconResultTypeEnum = {
  __typename?: 'ReconResultTypeEnum'
  isFallback: Scalars['Boolean']['output']
  value: ReconResultType
}

export type ReconResultTypeWithFallback = FallbackValue | ReconResultTypeEnum

export type ReconThresholdRangeNode = {
  __typename?: 'ReconThresholdRangeNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  maximum: Scalars['Decimal']['output']
  minimum: Scalars['Decimal']['output']
  reconThresholdSetting: ReconThresholdSettingNode
  reconThresholdSettingId: Scalars['UUID']['output']
  thresholdAmount: Scalars['Decimal']['output']
  useAbsoluteValueMatching: Scalars['Boolean']['output']
  usePercent: Scalars['Boolean']['output']
}

export type ReconThresholdRangeNodeConnection = {
  __typename?: 'ReconThresholdRangeNodeConnection'
  edges: Array<ReconThresholdRangeNodeEdge>
}

export type ReconThresholdRangeNodeEdge = {
  __typename?: 'ReconThresholdRangeNodeEdge'
  node: ReconThresholdRangeNode
}

export type ReconThresholdSettingNode = {
  __typename?: 'ReconThresholdSettingNode'
  dateCreated: Scalars['DateTime']['output']
  dateDisabled?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  enabled: Scalars['Boolean']['output']
  id: Scalars['UUID']['output']
  jobTemplate: JobTemplateNode
  jobTemplateId: Scalars['UUID']['output']
  reconThresholdRanges: ReconThresholdRangeNodeConnection
}

export type ReconThresholdSettingNodeConnection = {
  __typename?: 'ReconThresholdSettingNodeConnection'
  edges: Array<ReconThresholdSettingNodeEdge>
}

export type ReconThresholdSettingNodeEdge = {
  __typename?: 'ReconThresholdSettingNodeEdge'
  node: ReconThresholdSettingNode
}

export type ReconcileApInvoiceJob = {
  __typename?: 'ReconcileApInvoiceJob'
  docCharges: Array<ChargeDetail>
  expectedCharges: Array<ChargeDetail>
  reconAttempt: ReconAttemptNode
  reconResults: Array<ReconResultInterface>
}

export type ReconcileArrivalNoticeJob = {
  __typename?: 'ReconcileArrivalNoticeJob'
  reconAttempt: ReconAttemptNode
  reconResults: Array<ReconResultInterface>
}

export type ReconcileSoaJob = {
  __typename?: 'ReconcileSoaJob'
  reconAsyncBatchId: Scalars['UUID']['output']
}

export enum ReconciliationStatus {
  Discrepant = 'DISCREPANT',
  NotDiscrepant = 'NOT_DISCREPANT',
  Pending = 'PENDING',
}

export type RecreateReindexMirror = {
  __typename?: 'RecreateReindexMirror'
  reindexMirrorAsyncTaskId: Scalars['UUID']['output']
}

export type RegionMapDataPoint = {
  __typename?: 'RegionMapDataPoint'
  otherSelects?: Maybe<Array<OtherSelect>>
  region: Scalars['String']['output']
  value?: Maybe<Scalars['String']['output']>
}

export type RegionMapDataResult = {
  __typename?: 'RegionMapDataResult'
  regionLabel: Scalars['String']['output']
  results: Array<RegionMapDataPoint>
  valueLabel: Scalars['String']['output']
}

export type RejectJob = {
  __typename?: 'RejectJob'
  ok: Scalars['Boolean']['output']
}

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse'
  oobCode: Scalars['String']['output']
}

export enum ResolutionStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type RestoreFilePages = {
  __typename?: 'RestoreFilePages'
  numUpdated?: Maybe<Scalars['Int']['output']>
}

export type RestoreJob = {
  __typename?: 'RestoreJob'
  ok: Scalars['Boolean']['output']
}

export type ReviewJob = {
  __typename?: 'ReviewJob'
  ok: Scalars['Boolean']['output']
}

export type RotateFilePage = {
  __typename?: 'RotateFilePage'
  filePage?: Maybe<FilePageNode>
}

export type RpaGsheetsData = {
  __typename?: 'RpaGsheetsData'
  carrier: Array<Maybe<Carrier>>
  company: Array<Maybe<Company>>
  companyPort: Array<Maybe<CompanyPort>>
  containerType: Array<Maybe<ContainerType>>
  emptyContainerDepot: Array<Maybe<EmptyContainerDepot>>
  outerPackagingUnit: Array<Maybe<OuterPackagingUnit>>
  port: Array<Maybe<Port>>
}

export type SoaCheckShipmentInfoLineItemSnapshotNode = {
  __typename?: 'SOACheckShipmentInfoLineItemSnapshotNode'
  branch?: Maybe<Scalars['String']['output']>
  cargowiseModule?: Maybe<Scalars['String']['output']>
  carrierBookingNumber?: Maybe<Scalars['String']['output']>
  chargeCode: Scalars['String']['output']
  chargeCost: Scalars['String']['output']
  chargeDescription?: Maybe<Scalars['String']['output']>
  checkShipmentInfoMatchCriteria: CheckShipmentInfoMatchCriteriaNode
  checkShipmentInfoMatchCriteriaId: Scalars['UUID']['output']
  consolNumber?: Maybe<Scalars['String']['output']>
  containerNumber?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  debtor?: Maybe<Scalars['String']['output']>
  department?: Maybe<Scalars['String']['output']>
  documentReceivedDate?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['String']['output']>
  errorNotes?: Maybe<Scalars['String']['output']>
  expedockAction?: Maybe<Scalars['String']['output']>
  hblNumber?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceDate?: Maybe<Scalars['String']['output']>
  invoiceForwarderReference?: Maybe<Scalars['String']['output']>
  invoiceLineBranch?: Maybe<Scalars['String']['output']>
  invoiceLineDepartment?: Maybe<Scalars['String']['output']>
  invoiceNumber: Scalars['String']['output']
  invoiceTmsId?: Maybe<Scalars['String']['output']>
  mblNumber?: Maybe<Scalars['String']['output']>
  operationsRep?: Maybe<Scalars['String']['output']>
  orderNumber?: Maybe<Scalars['String']['output']>
  quantity?: Maybe<Scalars['String']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  salesRep?: Maybe<Scalars['String']['output']>
  taxId?: Maybe<Scalars['String']['output']>
  unitPrice?: Maybe<Scalars['String']['output']>
}

export type SoaCheckShipmentInfoLineItemSnapshotNodeConnection = {
  __typename?: 'SOACheckShipmentInfoLineItemSnapshotNodeConnection'
  edges: Array<SoaCheckShipmentInfoLineItemSnapshotNodeEdge>
}

export type SoaCheckShipmentInfoLineItemSnapshotNodeEdge = {
  __typename?: 'SOACheckShipmentInfoLineItemSnapshotNodeEdge'
  node: SoaCheckShipmentInfoLineItemSnapshotNode
}

export type SoaLineItemExpectedCharges = {
  __typename?: 'SOALineItemExpectedCharges'
  expectedCharges: Array<ChargeDetail>
  invoiceNumber: Scalars['String']['output']
  matchCriteriaFindShipmentReconResultPairList: Array<MatchCriteriaFindShipmentReconResultPair>
  metadataReconResults: Array<MetadataReconResultNode>
  shipmentNumber: Scalars['String']['output']
  shipmentStaffReconResults: Array<ShipmentStaffReconResultNode>
}

export type SoaLineItemShipmentMatch = {
  __typename?: 'SOALineItemShipmentMatch'
  matchingInvoiceNumberChainIoShipmentId?: Maybe<Scalars['String']['output']>
  rowIdx: Scalars['Int']['output']
}

export type SoaReconLineItemSnapshotNode = InvoiceReconLineItemSnapshotInterface & {
  __typename?: 'SOAReconLineItemSnapshotNode'
  apportionmentMethod?: Maybe<Scalars['String']['output']>
  branch?: Maybe<Scalars['String']['output']>
  cargowiseModule?: Maybe<Scalars['String']['output']>
  carrierBookingNumber?: Maybe<Scalars['String']['output']>
  chargeCode?: Maybe<Scalars['String']['output']>
  chargeCost?: Maybe<Scalars['Decimal']['output']>
  chargeDescription?: Maybe<Scalars['String']['output']>
  consolNumber?: Maybe<Scalars['String']['output']>
  containerNumber?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  debtor?: Maybe<Scalars['String']['output']>
  department?: Maybe<Scalars['String']['output']>
  documentReceivedDate?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['String']['output']>
  errorNotes?: Maybe<Scalars['String']['output']>
  expedockAction?: Maybe<Scalars['String']['output']>
  hblNumber?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceDate?: Maybe<Scalars['String']['output']>
  invoiceForwarderReference?: Maybe<Scalars['String']['output']>
  invoiceLineBranch?: Maybe<Scalars['String']['output']>
  invoiceLineDepartment?: Maybe<Scalars['String']['output']>
  invoiceNumber?: Maybe<Scalars['String']['output']>
  invoiceTmsId?: Maybe<Scalars['String']['output']>
  job?: Maybe<JobNode>
  jobId?: Maybe<Scalars['UUID']['output']>
  mblNumber?: Maybe<Scalars['String']['output']>
  operationsRep?: Maybe<Scalars['String']['output']>
  orderNumber?: Maybe<Scalars['String']['output']>
  quantity?: Maybe<Scalars['Decimal']['output']>
  reconAttempt?: Maybe<ReconAttemptNode>
  reconAttemptId?: Maybe<Scalars['UUID']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  salesRep?: Maybe<Scalars['String']['output']>
  sellAmount?: Maybe<Scalars['Decimal']['output']>
  sellCurrency?: Maybe<Scalars['String']['output']>
  taxId?: Maybe<Scalars['String']['output']>
  type: InvoiceReconLineItemType
  unitPrice?: Maybe<Scalars['Decimal']['output']>
}

export type SoaTotalReconResultNode = ReconResultInterface & {
  __typename?: 'SOATotalReconResultNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  expectedAmount: Scalars['Decimal']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  soaAmount: Scalars['Decimal']['output']
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type SoaTransactionLineItemSnapshotNode = {
  __typename?: 'SOATransactionLineItemSnapshotNode'
  branch?: Maybe<Scalars['String']['output']>
  cargowiseModule?: Maybe<Scalars['String']['output']>
  carrierBookingNumber?: Maybe<Scalars['String']['output']>
  chargeCode: Scalars['String']['output']
  chargeCost: Scalars['String']['output']
  containerNumber?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  department?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['String']['output']>
  hblNumber?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceDate?: Maybe<Scalars['String']['output']>
  invoiceForwarderReference?: Maybe<Scalars['String']['output']>
  invoiceLineBranch?: Maybe<Scalars['String']['output']>
  invoiceLineDepartment?: Maybe<Scalars['String']['output']>
  invoiceNumber: Scalars['String']['output']
  invoiceTmsId?: Maybe<Scalars['String']['output']>
  mblNumber?: Maybe<Scalars['String']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  shipmentReferenceNumber?: Maybe<Scalars['String']['output']>
  taxAmount?: Maybe<Scalars['String']['output']>
  taxCode?: Maybe<Scalars['String']['output']>
  taxRate?: Maybe<Scalars['String']['output']>
  transactionAsyncTask: TransactionAsyncTaskNode
  transactionAsyncTaskId: Scalars['UUID']['output']
}

export type SoaTransactionLineItemSnapshotNodeConnection = {
  __typename?: 'SOATransactionLineItemSnapshotNodeConnection'
  edges: Array<SoaTransactionLineItemSnapshotNodeEdge>
}

export type SoaTransactionLineItemSnapshotNodeEdge = {
  __typename?: 'SOATransactionLineItemSnapshotNodeEdge'
  node: SoaTransactionLineItemSnapshotNode
}

export enum SalesOverviewGraph {
  Expenses = 'Expenses',
  MarginNetProfit = 'MarginNetProfit',
  MarginPercent = 'MarginPercent',
  Revenue = 'Revenue',
  RevenueByPeriod = 'RevenueByPeriod',
  RevenuePeriodComparison = 'RevenuePeriodComparison',
  ShipmentVolume = 'ShipmentVolume',
  TopAccounts = 'TopAccounts',
  TopCarriers = 'TopCarriers',
  TopChargeCodes = 'TopChargeCodes',
}

export enum SalesTab {
  SalesOverview = 'SALES_OVERVIEW',
}

export type SankeyMapDataPoint = {
  __typename?: 'SankeyMapDataPoint'
  destination: Scalars['String']['output']
  origin: Scalars['String']['output']
  otherSelects?: Maybe<Array<OtherSelect>>
  value?: Maybe<Scalars['String']['output']>
}

export type SankeyMapDataResult = {
  __typename?: 'SankeyMapDataResult'
  destinationLabel: Scalars['String']['output']
  originLabel: Scalars['String']['output']
  results: Array<SankeyMapDataPoint>
  valueLabel: Scalars['String']['output']
}

export type SaveDocumentFields = {
  __typename?: 'SaveDocumentFields'
  ok: Scalars['Boolean']['output']
}

export type SaveDocumentTable = {
  __typename?: 'SaveDocumentTable'
  ok: Scalars['Boolean']['output']
}

export type SaveJobAndDocumentTables = {
  __typename?: 'SaveJobAndDocumentTables'
  ok: Scalars['Boolean']['output']
}

export type SaveJobTable = {
  __typename?: 'SaveJobTable'
  ok: Scalars['Boolean']['output']
}

export type SaveJobTemplateExportFormat = {
  __typename?: 'SaveJobTemplateExportFormat'
  ok: Scalars['Boolean']['output']
}

export type SaveReconAttempt = {
  __typename?: 'SaveReconAttempt'
  ok: Scalars['Boolean']['output']
}

export type SaveReconAttemptsByBatch = {
  __typename?: 'SaveReconAttemptsByBatch'
  ok: Scalars['Boolean']['output']
}

export type SavedFiltersAndUnitSettings = {
  __typename?: 'SavedFiltersAndUnitSettings'
  savedFilters: Scalars['String']['output']
  savedUnitSettings: Scalars['String']['output']
  source: Scalars['String']['output']
  tabName: Scalars['String']['output']
}

export type ScatterplotDataPoint = {
  __typename?: 'ScatterplotDataPoint'
  x: Scalars['String']['output']
  y: Scalars['String']['output']
}

export type ScatterplotDataResult = {
  __typename?: 'ScatterplotDataResult'
  periodType: PeriodType
  results: Array<ScatterplotDataPoint>
  xAxisLabel: Scalars['String']['output']
  yAxisLabel: Scalars['String']['output']
}

export type ScheduleDNode = SearchableRecordResultNode & {
  __typename?: 'ScheduleDNode'
  address1?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  address3?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  cleanedLabel?: Maybe<Scalars['String']['output']>
  code?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  label?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  portCarrierType?: Maybe<Scalars['String']['output']>
  portCarrierTypeDesc?: Maybe<Scalars['String']['output']>
  portOfUnlading?: Maybe<Scalars['String']['output']>
  primaryTeamLocation?: Maybe<Scalars['String']['output']>
  secondaryTeamLocation?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  zipCode?: Maybe<Scalars['String']['output']>
}

export type ScheduleKNode = SearchableRecordResultNode & {
  __typename?: 'ScheduleKNode'
  cleanedLabel?: Maybe<Scalars['String']['output']>
  code?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  label?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  validForType?: Maybe<Scalars['String']['output']>
}

export type SearchParam = {
  __typename?: 'SearchParam'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type SearchShipperFacingOrganizationCodeChoiceResult = {
  __typename?: 'SearchShipperFacingOrganizationCodeChoiceResult'
  shipperFacingOrganizationCodeChoices: Array<ShipperFacingOrganizationCodeChoice>
}

export enum SearchableRecordModel {
  ChargeCode = 'ChargeCode',
  ChargeCodeV2 = 'ChargeCodeV2',
  ChargeRate = 'ChargeRate',
  ChargeVendor = 'ChargeVendor',
  GeoLocation = 'GeoLocation',
  ScheduleD = 'ScheduleD',
  ScheduleK = 'ScheduleK',
  UsCarrier = 'USCarrier',
}

export type SearchableRecordNode = {
  __typename?: 'SearchableRecordNode'
  apiUrl?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  displayKey: Scalars['String']['output']
  field: FieldNode
  fieldId: Scalars['UUID']['output']
  id: Scalars['UUID']['output']
  model?: Maybe<SearchableRecordModel>
  searchKey: Scalars['String']['output']
}

export type SearchableRecordOptions = {
  __typename?: 'SearchableRecordOptions'
  apiUrl?: Maybe<Scalars['String']['output']>
  displayKey: Scalars['String']['output']
  model?: Maybe<Scalars['String']['output']>
  searchKey: Scalars['String']['output']
}

export type SearchableRecordResultNode = {
  id: Scalars['UUID']['output']
}

export type SearchableRecordUrlResult = SearchableRecordResultNode & {
  __typename?: 'SearchableRecordURLResult'
  id: Scalars['UUID']['output']
  urlResultRecord: Scalars['String']['output']
  urlResultSearch: Scalars['String']['output']
}

export type SendBFileToCw = {
  __typename?: 'SendBFileToCW'
  cdecReferenceNumber: Scalars['String']['output']
  consolReferenceNumber: Scalars['String']['output']
  shipmentReferenceNumber: Scalars['String']['output']
}

export type SendShipmentOpsToCw = {
  __typename?: 'SendShipmentOpsToCW'
  consolReferenceNumber: Scalars['String']['output']
  importDecReferenceNumber: Scalars['String']['output']
  processingLog: Scalars['String']['output']
  shipmentReferenceNumber: Scalars['String']['output']
}

export type SetJobManualProduct = {
  __typename?: 'SetJobManualProduct'
  job: JobNode
}

export type SetJobOwner = {
  __typename?: 'SetJobOwner'
  ok: Scalars['Boolean']['output']
}

export type SetJobQa = {
  __typename?: 'SetJobQA'
  jobId: Scalars['String']['output']
}

export type ShipmentMatchField = {
  __typename?: 'ShipmentMatchField'
  field: Scalars['String']['output']
  value: Scalars['String']['output']
}

export enum ShipmentOp {
  CommercialInvoice = 'COMMERCIAL_INVOICE',
  Consol = 'CONSOL',
  CustomsDeclaration = 'CUSTOMS_DECLARATION',
  ImportDeclaration = 'IMPORT_DECLARATION',
  Shipment = 'SHIPMENT',
}

export enum ShipmentOpsCommercialInvoiceAutofillKey {
  Branch = 'BRANCH',
  Consignee = 'CONSIGNEE',
  CountryOfOrigin = 'COUNTRY_OF_ORIGIN',
  Currency = 'CURRENCY',
  InvoiceDate = 'INVOICE_DATE',
  InvoiceNumber = 'INVOICE_NUMBER',
  Shipper = 'SHIPPER',
  TmsId = 'TMS_ID',
  TotalAmount = 'TOTAL_AMOUNT',
}

export enum ShipmentOpsConsolContainerAutofillKey {
  ContainerLineItem = 'CONTAINER_LINE_ITEM',
  ContainerNumber = 'CONTAINER_NUMBER',
  ContainerType = 'CONTAINER_TYPE',
  Count = 'COUNT',
  CtoStorageStart = 'CTO_STORAGE_START',
  DeliveryMode = 'DELIVERY_MODE',
  EmptyReqBy = 'EMPTY_REQ_BY',
  EstFullPickup = 'EST_FULL_PICKUP',
  Mode = 'MODE',
  SealedBy = 'SEALED_BY',
  SealNumber = 'SEAL_NUMBER',
  WharfGateIn = 'WHARF_GATE_IN',
}

export enum ShipmentOpsConsolDetailsAutofillKey {
  AgentRef = 'AGENT_REF',
  ArrivalCfsAddress = 'ARRIVAL_CFS_ADDRESS',
  ArrivalContainerYard = 'ARRIVAL_CONTAINER_YARD',
  ArrivalCtoAddress = 'ARRIVAL_CTO_ADDRESS',
  BookingNumber = 'BOOKING_NUMBER',
  Carrier = 'CARRIER',
  ConsolCfsAddress = 'CONSOL_CFS_ADDRESS',
  ConsolEta = 'CONSOL_ETA',
  ConsolEtd = 'CONSOL_ETD',
  ConsolNumber = 'CONSOL_NUMBER',
  ConsolReleaseType = 'CONSOL_RELEASE_TYPE',
  ConsolTransport = 'CONSOL_TRANSPORT',
  ConsolType = 'CONSOL_TYPE',
  ContainerMode = 'CONTAINER_MODE',
  ContainerYard = 'CONTAINER_YARD',
  CoLoadMbl = 'CO_LOAD_MBL',
  Creditor = 'CREDITOR',
  CtoAddress = 'CTO_ADDRESS',
  DischargePort = 'DISCHARGE_PORT',
  FirstLoad = 'FIRST_LOAD',
  LastDiscard = 'LAST_DISCARD',
  LastForeignPort = 'LAST_FOREIGN_PORT',
  LoadPort = 'LOAD_PORT',
  MblNumber = 'MBL_NUMBER',
  Payment = 'PAYMENT',
  ReceivingAgent = 'RECEIVING_AGENT',
  SendingAgent = 'SENDING_AGENT',
  ShipmentNumber = 'SHIPMENT_NUMBER',
  TmsId = 'TMS_ID',
  Vessel = 'VESSEL',
  Voyage = 'VOYAGE',
}

export enum ShipmentOpsConsolRoutingAutofillKey {
  CfsAvailable = 'CFS_AVAILABLE',
  CfsCutoff = 'CFS_CUTOFF',
  CfsReceival = 'CFS_RECEIVAL',
  CfsStorage = 'CFS_STORAGE',
  CtoAvailable = 'CTO_AVAILABLE',
  CtoCutOff = 'CTO_CUT_OFF',
  CtoReceival = 'CTO_RECEIVAL',
  CtoStorage = 'CTO_STORAGE',
  Discharge = 'DISCHARGE',
  DocsDue = 'DOCS_DUE',
  Leg = 'LEG',
  Load = 'LOAD',
  RouteCarrier = 'ROUTE_CARRIER',
  RoutingEta = 'ROUTING_ETA',
  RoutingEtd = 'ROUTING_ETD',
  RoutingLineItem = 'ROUTING_LINE_ITEM',
  RoutingMode = 'ROUTING_MODE',
  RoutingType = 'ROUTING_TYPE',
  RoutingVessel = 'ROUTING_VESSEL',
  RoutingVoyage = 'ROUTING_VOYAGE',
  Status = 'STATUS',
  VgmCutoff = 'VGM_CUTOFF',
}

export type ShipmentOpsEnums = {
  __typename?: 'ShipmentOpsEnums'
  shipmentOpsCommercialInvoiceAutofillKey: ShipmentOpsCommercialInvoiceAutofillKey
  shipmentOpsConsolContainerAutofillKey: ShipmentOpsConsolContainerAutofillKey
  shipmentOpsConsolDetailsAutofillKey: ShipmentOpsConsolDetailsAutofillKey
  shipmentOpsConsolPackingLinesAutofillKey: ShipmentOpsShipmentPackingLinesAutofillKey
  shipmentOpsConsolRoutingAutofillKey: ShipmentOpsConsolRoutingAutofillKey
  shipmentOpsImportDecAutofillKey: ShipmentOpsImportDecAutofillKey
  shipmentOpsShipmentAutofillKey: ShipmentOpsShipmentAutofillKey
  shipmentOpsShipmentOrderLinesAutofillKey: ShipmentOpsShipmentOrderLinesAutofillKey
  shipmentOpsShipmentOrderNumberAutofillKey: ShipmentOpsShipmentOrderNumberAutofillKey
}

export enum ShipmentOpsImportDecAutofillKey {
  ArrivalDate = 'ARRIVAL_DATE',
  Buyer = 'BUYER',
  DepartureDate = 'DEPARTURE_DATE',
  Destination = 'DESTINATION',
  EnabledEntSum = 'ENABLED_ENT_SUM',
  EntryPort = 'ENTRY_PORT',
  EntryType = 'ENTRY_TYPE',
  FirstArr = 'FIRST_ARR',
  Importer = 'IMPORTER',
  ImportDecBranch = 'IMPORT_DEC_BRANCH',
  ImportDecCarrier = 'IMPORT_DEC_CARRIER',
  ImportDecDepartment = 'IMPORT_DEC_DEPARTMENT',
  ImportDecDescription = 'IMPORT_DEC_DESCRIPTION',
  ImportDecDischarge = 'IMPORT_DEC_DISCHARGE',
  ImportDecEta = 'IMPORT_DEC_ETA',
  ImportDecEtd = 'IMPORT_DEC_ETD',
  ImportDecHouseBill = 'IMPORT_DEC_HOUSE_BILL',
  ImportDecPortOfOrigin = 'IMPORT_DEC_PORT_OF_ORIGIN',
  ImportDecReferenceNo = 'IMPORT_DEC_REFERENCE_NO',
  ImportDecVessel = 'IMPORT_DEC_VESSEL',
  ImportDecVoyage = 'IMPORT_DEC_VOYAGE',
  ImportDecWeight = 'IMPORT_DEC_WEIGHT',
  ImportDecWeightUom = 'IMPORT_DEC_WEIGHT_UOM',
  Issuer = 'ISSUER',
  IssuerScac = 'ISSUER_SCAC',
  Loading = 'LOADING',
  MainSupplier = 'MAIN_SUPPLIER',
  NoPackages = 'NO_PACKAGES',
  NoPackagesUom = 'NO_PACKAGES_UOM',
  OceanBill = 'OCEAN_BILL',
  OwnerRef = 'OWNER_REF',
  Purchased = 'PURCHASED',
  Seller = 'SELLER',
  ShipToParty = 'SHIP_TO_PARTY',
  SoldToParty = 'SOLD_TO_PARTY',
  TmsId = 'TMS_ID',
  TotalVolume = 'TOTAL_VOLUME',
  TotalVolumeUom = 'TOTAL_VOLUME_UOM',
}

export enum ShipmentOpsShipmentAutofillKey {
  CfsAddress = 'CFS_ADDRESS',
  Consignee = 'CONSIGNEE',
  ConsolNumber = 'CONSOL_NUMBER',
  Container = 'CONTAINER',
  CustomFieldKey = 'CUSTOM_FIELD_KEY',
  CustomFieldValue = 'CUSTOM_FIELD_VALUE',
  CustomLineItem = 'CUSTOM_LINE_ITEM',
  DeliveryTransportCompany = 'DELIVERY_TRANSPORT_COMPANY',
  DetailedGoodsDescription = 'DETAILED_GOODS_DESCRIPTION',
  Eta = 'ETA',
  Etd = 'ETD',
  GoodsDescription = 'GOODS_DESCRIPTION',
  GoodsValue = 'GOODS_VALUE',
  HblNumber = 'HBL_NUMBER',
  ImportBroker = 'IMPORT_BROKER',
  Incoterm = 'INCOTERM',
  Inners = 'INNERS',
  InnersUom = 'INNERS_UOM',
  MarksAndNumbers = 'MARKS_AND_NUMBERS',
  NotifyParty = 'NOTIFY_PARTY',
  OrderRefs = 'ORDER_REFS',
  Packs = 'PACKS',
  PacksUom = 'PACKS_UOM',
  PortOfDestination = 'PORT_OF_DESTINATION',
  PortOfOrigin = 'PORT_OF_ORIGIN',
  ReferenceNumber = 'REFERENCE_NUMBER',
  ReleaseType = 'RELEASE_TYPE',
  SalesRep = 'SALES_REP',
  ServiceLevel = 'SERVICE_LEVEL',
  ShipmentType = 'SHIPMENT_TYPE',
  Shipper = 'SHIPPER',
  TmsId = 'TMS_ID',
  Transport = 'TRANSPORT',
  Volume = 'VOLUME',
  VolumeUom = 'VOLUME_UOM',
  Weight = 'WEIGHT',
  WeightUom = 'WEIGHT_UOM',
}

export enum ShipmentOpsShipmentOrderLinesAutofillKey {
  ContainerNumber = 'CONTAINER_NUMBER',
  LineNumber = 'LINE_NUMBER',
  OrderIdentifier = 'ORDER_IDENTIFIER',
  OrderLinesLineItem = 'ORDER_LINES_LINE_ITEM',
}

export enum ShipmentOpsShipmentOrderNumberAutofillKey {
  OrderNumber = 'ORDER_NUMBER',
  OrderNumberLineItem = 'ORDER_NUMBER_LINE_ITEM',
  OrderNumberSplit = 'ORDER_NUMBER_SPLIT',
  Supplier = 'SUPPLIER',
  Vendor = 'VENDOR',
}

export enum ShipmentOpsShipmentPackingLinesAutofillKey {
  ContainerNumber = 'CONTAINER_NUMBER',
  ExportReferenceNumber = 'EXPORT_REFERENCE_NUMBER',
  GoodsDescription = 'GOODS_DESCRIPTION',
  ImportReferenceNumber = 'IMPORT_REFERENCE_NUMBER',
  MarksAndNumbers = 'MARKS_AND_NUMBERS',
  PackingLineItem = 'PACKING_LINE_ITEM',
  Packs = 'PACKS',
  PacksUom = 'PACKS_UOM',
  ReferenceNumber = 'REFERENCE_NUMBER',
  Volume = 'VOLUME',
  VolumeUom = 'VOLUME_UOM',
  Weight = 'WEIGHT',
  WeightUom = 'WEIGHT_UOM',
}

export type ShipmentStaffReconResultNode = ReconResultInterface & {
  __typename?: 'ShipmentStaffReconResultNode'
  chainIoConsolidation?: Maybe<ChainIoConsolidationNode>
  chainIoConsolidationId?: Maybe<Scalars['UUID']['output']>
  chainIoCustomsDeclaration?: Maybe<ChainIoCustomsDeclarationNode>
  chainIoCustomsDeclarationId?: Maybe<Scalars['UUID']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  chainIoShipmentId?: Maybe<Scalars['UUID']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job: JobNode
  jobId: Scalars['UUID']['output']
  operationsStaffCode?: Maybe<Scalars['String']['output']>
  operationsStaffName?: Maybe<Scalars['String']['output']>
  reconAttemptReconResults: ReconAttemptReconResultNodeConnection
  reconAttempts: ReconAttemptNodeConnection
  salesStaffCode?: Maybe<Scalars['String']['output']>
  salesStaffName?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
  type: ReconResultTypeWithFallback
}

export type ShipmentWithMatchCriteria = {
  __typename?: 'ShipmentWithMatchCriteria'
  carrierBookingNo?: Maybe<Scalars['String']['output']>
  chainIoShipment?: Maybe<ChainIoShipmentNode>
  consolNo?: Maybe<Scalars['String']['output']>
  containerNo?: Maybe<Scalars['String']['output']>
  hblNo?: Maybe<Scalars['String']['output']>
  mblNo?: Maybe<Scalars['String']['output']>
  orderNo?: Maybe<Scalars['String']['output']>
  shipmentNo?: Maybe<Scalars['String']['output']>
}

export type ShipperFacingContainerTableColumnInfo = {
  __typename?: 'ShipperFacingContainerTableColumnInfo'
  categories: Array<InsightsFieldCategory>
  column: FilteredContainersColumn
  columnId: Scalars['Int']['output']
  description?: Maybe<Scalars['String']['output']>
  displayName: Scalars['String']['output']
  isEnabled: Scalars['Boolean']['output']
  isFrozen: Scalars['Boolean']['output']
  sortableColumn: ShipperVizContainerColumn
  summable: Scalars['Boolean']['output']
  type: TableColumnType
}

export type ShipperFacingContainersDefaultcolumns = {
  __typename?: 'ShipperFacingContainersDefaultcolumns'
  columns: Array<FilteredContainersColumn>
  orderByColumn: ShipperVizContainerColumn
  orderByDirection: ShipperVizContainerOrder
}

export type ShipperFacingDashboardTabUserFilterNode = {
  __typename?: 'ShipperFacingDashboardTabUserFilterNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  savedFilterOrder: Array<Scalars['String']['output']>
  savedFilterQuery?: Maybe<Scalars['String']['output']>
  shipperFacingUser: ShipperFacingUserNode
  shipperFacingUserId: Scalars['UUID']['output']
  tabName: Scalars['String']['output']
}

export type ShipperFacingDashboardTabUserFilterNodeConnection = {
  __typename?: 'ShipperFacingDashboardTabUserFilterNodeConnection'
  edges: Array<ShipperFacingDashboardTabUserFilterNodeEdge>
}

export type ShipperFacingDashboardTabUserFilterNodeEdge = {
  __typename?: 'ShipperFacingDashboardTabUserFilterNodeEdge'
  node: ShipperFacingDashboardTabUserFilterNode
}

export type ShipperFacingOrganizationCodeChoice = {
  __typename?: 'ShipperFacingOrganizationCodeChoice'
  code: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type ShipperFacingOrganizationCodeNode = {
  __typename?: 'ShipperFacingOrganizationCodeNode'
  code: Scalars['String']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  name?: Maybe<Scalars['String']['output']>
  shipperFacingOrganization: ShipperFacingOrganizationNode
  shipperFacingOrganizationId: Scalars['UUID']['output']
}

export type ShipperFacingOrganizationCodeNodeConnection = {
  __typename?: 'ShipperFacingOrganizationCodeNodeConnection'
  edges: Array<ShipperFacingOrganizationCodeNodeEdge>
}

export type ShipperFacingOrganizationCodeNodeEdge = {
  __typename?: 'ShipperFacingOrganizationCodeNodeEdge'
  node: ShipperFacingOrganizationCodeNode
}

export type ShipperFacingOrganizationDomainNode = {
  __typename?: 'ShipperFacingOrganizationDomainNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  domain: Scalars['String']['output']
  id: Scalars['UUID']['output']
  shipperFacingOrganization: ShipperFacingOrganizationNode
  shipperFacingOrganizationId: Scalars['UUID']['output']
}

export type ShipperFacingOrganizationDomainNodeConnection = {
  __typename?: 'ShipperFacingOrganizationDomainNodeConnection'
  edges: Array<ShipperFacingOrganizationDomainNodeEdge>
}

export type ShipperFacingOrganizationDomainNodeEdge = {
  __typename?: 'ShipperFacingOrganizationDomainNodeEdge'
  node: ShipperFacingOrganizationDomainNode
}

export type ShipperFacingOrganizationNode = {
  __typename?: 'ShipperFacingOrganizationNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  freightForwarderCompanyId: Scalars['UUID']['output']
  freightForwarderCompanyName?: Maybe<Scalars['String']['output']>
  freightForwarderCompanySlug?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  shipperFacingOrganizationCodes: ShipperFacingOrganizationCodeNodeConnection
  shipperFacingOrganizationDomains: ShipperFacingOrganizationDomainNodeConnection
  shipperFacingUsers: ShipperFacingUserNodeConnection
}

export type ShipperFacingOrganizationResult = {
  __typename?: 'ShipperFacingOrganizationResult'
  limit: Scalars['Int']['output']
  page: Scalars['Int']['output']
  shipperFacingOrganizations: Array<ShipperFacingOrganizationNode>
  total: Scalars['Int']['output']
}

export enum ShipperFacingOrganizationResultOrderByColumn {
  DateCreated = 'DATE_CREATED',
  Name = 'NAME',
}

export type ShipperFacingPaywallUserRequestResults = {
  __typename?: 'ShipperFacingPaywallUserRequestResults'
  limit: Scalars['Int']['output']
  page: Scalars['Int']['output']
  paywallUserRequests: Array<PaywallUserRequestResult>
  total: Scalars['Int']['output']
}

export type ShipperFacingShipmentTableColumnInfo = {
  __typename?: 'ShipperFacingShipmentTableColumnInfo'
  categories: Array<InsightsFieldCategory>
  column: FilteredShipmentsColumn
  columnId: Scalars['Int']['output']
  description?: Maybe<Scalars['String']['output']>
  displayName: Scalars['String']['output']
  isEnabled: Scalars['Boolean']['output']
  isFrozen: Scalars['Boolean']['output']
  sortableColumn: ShipperVizShipmentColumn
  summable: Scalars['Boolean']['output']
  type: TableColumnType
}

export type ShipperFacingShipmentsDefaultcolumns = {
  __typename?: 'ShipperFacingShipmentsDefaultcolumns'
  columns: Array<FilteredShipmentsColumn>
  orderByColumn: ShipperVizShipmentColumn
  orderByDirection: ShipperVizShipmentOrder
}

export type ShipperFacingUserNode = {
  __typename?: 'ShipperFacingUserNode'
  activated: Scalars['Boolean']['output']
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  email: Scalars['String']['output']
  firebaseTenantId: Scalars['String']['output']
  firebaseUid: Scalars['String']['output']
  id: Scalars['UUID']['output']
  name: Scalars['String']['output']
  passwordResetEmailIsSent: Scalars['Boolean']['output']
  shipperFacingDashboardUserFilters: ShipperFacingDashboardTabUserFilterNodeConnection
  shipperFacingExploreContainerColumns?: Maybe<Array<FilteredContainersColumn>>
  shipperFacingExploreContainerOrderColumn?: Maybe<ShipperVizContainerColumn>
  shipperFacingExploreContainerOrderDirection?: Maybe<ShipperVizContainerOrder>
  shipperFacingExploreShipmentColumns?: Maybe<Array<FilteredShipmentsColumn>>
  shipperFacingExploreShipmentsOrderColumn?: Maybe<ShipperVizShipmentColumn>
  shipperFacingExploreShipmentsOrderDirection?: Maybe<ShipperVizShipmentOrder>
  shipperFacingOrganization: ShipperFacingOrganizationNode
  shipperFacingOrganizationId: Scalars['UUID']['output']
}

export type ShipperFacingUserNodeConnection = {
  __typename?: 'ShipperFacingUserNodeConnection'
  edges: Array<ShipperFacingUserNodeEdge>
}

export type ShipperFacingUserNodeEdge = {
  __typename?: 'ShipperFacingUserNodeEdge'
  node: ShipperFacingUserNode
}

export type ShipperFacingUserResult = {
  __typename?: 'ShipperFacingUserResult'
  activated: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  name: Scalars['String']['output']
  organizationId: Scalars['UUID']['output']
  organizationName: Scalars['String']['output']
  passwordResetEmailIsSent: Scalars['Boolean']['output']
  shipperFacingUserId: Scalars['UUID']['output']
}

export enum ShipperFacingUserResultOrderByColumn {
  Activated = 'ACTIVATED',
  DateCreated = 'DATE_CREATED',
  Email = 'EMAIL',
  Name = 'NAME',
  OrganizationName = 'ORGANIZATION_NAME',
  Status = 'STATUS',
}

export type ShipperFacingUserResults = {
  __typename?: 'ShipperFacingUserResults'
  limit: Scalars['Int']['output']
  page: Scalars['Int']['output']
  shipperFacingUserResults: Array<ShipperFacingUserResult>
  total: Scalars['Int']['output']
}

export type ShipperVizContainer = {
  __typename?: 'ShipperVizContainer'
  actualDeliveryDate?: Maybe<Scalars['DateTime']['output']>
  actualPickupDate?: Maybe<Scalars['DateTime']['output']>
  carrierBookingNumber?: Maybe<Scalars['String']['output']>
  cfsStorageStartDate?: Maybe<Scalars['DateTime']['output']>
  chargeableVolume?: Maybe<Scalars['Float']['output']>
  chargeableWeight?: Maybe<Scalars['Float']['output']>
  companyContainerKey?: Maybe<Scalars['String']['output']>
  companyShipmentKey?: Maybe<Scalars['String']['output']>
  consigneeAddress1?: Maybe<Scalars['String']['output']>
  consigneeAddress2?: Maybe<Scalars['String']['output']>
  consigneeCity?: Maybe<Scalars['String']['output']>
  consigneeCode?: Maybe<Scalars['String']['output']>
  consigneeCountry?: Maybe<Scalars['String']['output']>
  consigneeKey?: Maybe<Scalars['String']['output']>
  consigneeName?: Maybe<Scalars['String']['output']>
  consigneeState?: Maybe<Scalars['String']['output']>
  consigneeUnlocode?: Maybe<Scalars['String']['output']>
  containerCountAndType?: Maybe<Scalars['String']['output']>
  containerGrossWeight?: Maybe<Scalars['Float']['output']>
  containerMode?: Maybe<Scalars['String']['output']>
  containerNumber?: Maybe<Scalars['String']['output']>
  containerNumberDisplay?: Maybe<Scalars['String']['output']>
  containerShipmentGoodsDescription?: Maybe<Scalars['String']['output']>
  containerShipmentGoodsVolume?: Maybe<Scalars['Float']['output']>
  containerShipmentGoodsWeight?: Maybe<Scalars['Float']['output']>
  containerTeus?: Maybe<Scalars['Float']['output']>
  containerType?: Maybe<Scalars['String']['output']>
  ctoStorageStartDate?: Maybe<Scalars['DateTime']['output']>
  deliveryActualDate?: Maybe<Scalars['DateTime']['output']>
  deliveryAgentAddress1?: Maybe<Scalars['String']['output']>
  deliveryAgentAddress2?: Maybe<Scalars['String']['output']>
  deliveryAgentCity?: Maybe<Scalars['String']['output']>
  deliveryAgentCode?: Maybe<Scalars['String']['output']>
  deliveryAgentCountry?: Maybe<Scalars['String']['output']>
  deliveryAgentKey?: Maybe<Scalars['String']['output']>
  deliveryAgentName?: Maybe<Scalars['String']['output']>
  deliveryAgentState?: Maybe<Scalars['String']['output']>
  deliveryAgentUnlocode?: Maybe<Scalars['String']['output']>
  deliveryByDate?: Maybe<Scalars['DateTime']['output']>
  deliveryEstimatedDate?: Maybe<Scalars['DateTime']['output']>
  deliveryFromDate?: Maybe<Scalars['DateTime']['output']>
  deliveryMode?: Maybe<Scalars['String']['output']>
  deliveryToAddress1?: Maybe<Scalars['String']['output']>
  deliveryToAddress2?: Maybe<Scalars['String']['output']>
  deliveryToCity?: Maybe<Scalars['String']['output']>
  deliveryToCode?: Maybe<Scalars['String']['output']>
  deliveryToCountry?: Maybe<Scalars['String']['output']>
  deliveryToKey?: Maybe<Scalars['String']['output']>
  deliveryToName?: Maybe<Scalars['String']['output']>
  deliveryToState?: Maybe<Scalars['String']['output']>
  deliveryToUnlocode?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyAddress1?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyAddress2?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyCity?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyCode?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyCountry?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyKey?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyName?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyState?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyUnlocode?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  direction?: Maybe<Scalars['String']['output']>
  dischargePortAta?: Maybe<Scalars['DateTime']['output']>
  dischargePortAtd?: Maybe<Scalars['DateTime']['output']>
  dischargePortDisplay?: Maybe<Scalars['String']['output']>
  dischargePortEta?: Maybe<Scalars['DateTime']['output']>
  dischargePortEtd?: Maybe<Scalars['DateTime']['output']>
  emptyRequiredByDate?: Maybe<Scalars['DateTime']['output']>
  emptyReturnRequiredByDate?: Maybe<Scalars['DateTime']['output']>
  estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>
  estimatedPickupDate?: Maybe<Scalars['DateTime']['output']>
  exportReleaseNumber?: Maybe<Scalars['String']['output']>
  firstLegAtd?: Maybe<Scalars['DateTime']['output']>
  firstLegEtd?: Maybe<Scalars['DateTime']['output']>
  firstLegOriginDisplay?: Maybe<Scalars['String']['output']>
  forwarderReference?: Maybe<Scalars['String']['output']>
  goodsValue?: Maybe<Scalars['Float']['output']>
  goodsValueCurrency?: Maybe<Scalars['String']['output']>
  hasExceptions?: Maybe<Scalars['Boolean']['output']>
  houseBill?: Maybe<Scalars['String']['output']>
  importReleaseNumber?: Maybe<Scalars['String']['output']>
  importerAddress1?: Maybe<Scalars['String']['output']>
  importerAddress2?: Maybe<Scalars['String']['output']>
  importerCity?: Maybe<Scalars['String']['output']>
  importerCode?: Maybe<Scalars['String']['output']>
  importerCountry?: Maybe<Scalars['String']['output']>
  importerKey?: Maybe<Scalars['String']['output']>
  importerName?: Maybe<Scalars['String']['output']>
  importerState?: Maybe<Scalars['String']['output']>
  importerUnlocode?: Maybe<Scalars['String']['output']>
  incoterm?: Maybe<Scalars['String']['output']>
  isFailedToArrive?: Maybe<Scalars['Boolean']['output']>
  isFailedToDepart?: Maybe<Scalars['Boolean']['output']>
  lastLegArrivalStatus?: Maybe<Scalars['String']['output']>
  lastLegAta?: Maybe<Scalars['DateTime']['output']>
  lastLegDestinationDisplay?: Maybe<Scalars['String']['output']>
  lastLegEta?: Maybe<Scalars['DateTime']['output']>
  lastMilestoneTracked?: Maybe<Scalars['String']['output']>
  lastMilestoneTrackedDate?: Maybe<Scalars['DateTime']['output']>
  localClientAddress1?: Maybe<Scalars['String']['output']>
  localClientAddress2?: Maybe<Scalars['String']['output']>
  localClientCity?: Maybe<Scalars['String']['output']>
  localClientCode?: Maybe<Scalars['String']['output']>
  localClientCountry?: Maybe<Scalars['String']['output']>
  localClientKey?: Maybe<Scalars['String']['output']>
  localClientName?: Maybe<Scalars['String']['output']>
  localClientState?: Maybe<Scalars['String']['output']>
  localClientUnlocode?: Maybe<Scalars['String']['output']>
  mainLegArrivalStatus?: Maybe<Scalars['String']['output']>
  mainLegCarrierAddress1?: Maybe<Scalars['String']['output']>
  mainLegCarrierAddress2?: Maybe<Scalars['String']['output']>
  mainLegCarrierCity?: Maybe<Scalars['String']['output']>
  mainLegCarrierCode?: Maybe<Scalars['String']['output']>
  mainLegCarrierCountry?: Maybe<Scalars['String']['output']>
  mainLegCarrierKey?: Maybe<Scalars['String']['output']>
  mainLegCarrierName?: Maybe<Scalars['String']['output']>
  mainLegCarrierState?: Maybe<Scalars['String']['output']>
  mainLegCarrierUnlocode?: Maybe<Scalars['String']['output']>
  marksAndNumbers?: Maybe<Scalars['String']['output']>
  milestone?: Maybe<Scalars['String']['output']>
  orderRefs?: Maybe<Scalars['String']['output']>
  originPortAta?: Maybe<Scalars['DateTime']['output']>
  originPortAtd?: Maybe<Scalars['DateTime']['output']>
  originPortDisplay?: Maybe<Scalars['String']['output']>
  originPortEta?: Maybe<Scalars['DateTime']['output']>
  originPortEtd?: Maybe<Scalars['DateTime']['output']>
  packs?: Maybe<Scalars['Int']['output']>
  packsUom?: Maybe<Scalars['String']['output']>
  pickupActualDate?: Maybe<Scalars['DateTime']['output']>
  pickupByDate?: Maybe<Scalars['DateTime']['output']>
  pickupEstimatedDate?: Maybe<Scalars['DateTime']['output']>
  pickupFromAddress1?: Maybe<Scalars['String']['output']>
  pickupFromAddress2?: Maybe<Scalars['String']['output']>
  pickupFromCity?: Maybe<Scalars['String']['output']>
  pickupFromCode?: Maybe<Scalars['String']['output']>
  pickupFromCountry?: Maybe<Scalars['String']['output']>
  pickupFromDate?: Maybe<Scalars['DateTime']['output']>
  pickupFromKey?: Maybe<Scalars['String']['output']>
  pickupFromName?: Maybe<Scalars['String']['output']>
  pickupFromState?: Maybe<Scalars['String']['output']>
  pickupFromUnlocode?: Maybe<Scalars['String']['output']>
  receivingAgentAddress1?: Maybe<Scalars['String']['output']>
  receivingAgentAddress2?: Maybe<Scalars['String']['output']>
  receivingAgentCity?: Maybe<Scalars['String']['output']>
  receivingAgentCode?: Maybe<Scalars['String']['output']>
  receivingAgentCountry?: Maybe<Scalars['String']['output']>
  receivingAgentKey?: Maybe<Scalars['String']['output']>
  receivingAgentName?: Maybe<Scalars['String']['output']>
  receivingAgentState?: Maybe<Scalars['String']['output']>
  receivingAgentUnlocode?: Maybe<Scalars['String']['output']>
  releaseType?: Maybe<Scalars['String']['output']>
  routeDisplay?: Maybe<Scalars['String']['output']>
  sealNumber?: Maybe<Scalars['String']['output']>
  sendingAgentAddress1?: Maybe<Scalars['String']['output']>
  sendingAgentAddress2?: Maybe<Scalars['String']['output']>
  sendingAgentCity?: Maybe<Scalars['String']['output']>
  sendingAgentCode?: Maybe<Scalars['String']['output']>
  sendingAgentCountry?: Maybe<Scalars['String']['output']>
  sendingAgentKey?: Maybe<Scalars['String']['output']>
  sendingAgentName?: Maybe<Scalars['String']['output']>
  sendingAgentState?: Maybe<Scalars['String']['output']>
  sendingAgentUnlocode?: Maybe<Scalars['String']['output']>
  serviceLevel?: Maybe<Scalars['String']['output']>
  shipperAddress1?: Maybe<Scalars['String']['output']>
  shipperAddress2?: Maybe<Scalars['String']['output']>
  shipperCity?: Maybe<Scalars['String']['output']>
  shipperCode?: Maybe<Scalars['String']['output']>
  shipperCountry?: Maybe<Scalars['String']['output']>
  shipperKey?: Maybe<Scalars['String']['output']>
  shipperName?: Maybe<Scalars['String']['output']>
  shipperState?: Maybe<Scalars['String']['output']>
  shipperUnlocode?: Maybe<Scalars['String']['output']>
  snowflakeId?: Maybe<Scalars['String']['output']>
  supplierAddress1?: Maybe<Scalars['String']['output']>
  supplierAddress2?: Maybe<Scalars['String']['output']>
  supplierCity?: Maybe<Scalars['String']['output']>
  supplierCode?: Maybe<Scalars['String']['output']>
  supplierCountry?: Maybe<Scalars['String']['output']>
  supplierKey?: Maybe<Scalars['String']['output']>
  supplierName?: Maybe<Scalars['String']['output']>
  supplierState?: Maybe<Scalars['String']['output']>
  supplierUnlocode?: Maybe<Scalars['String']['output']>
  targetVolume?: Maybe<Scalars['Float']['output']>
  targetWeight?: Maybe<Scalars['Float']['output']>
  teus?: Maybe<Scalars['Float']['output']>
  transportMode?: Maybe<Scalars['String']['output']>
  vessel?: Maybe<Scalars['String']['output']>
  volume?: Maybe<Scalars['Float']['output']>
  voyage?: Maybe<Scalars['String']['output']>
  weight?: Maybe<Scalars['Float']['output']>
}

export enum ShipperVizContainerColumn {
  ActualDeliveryDate = 'ACTUAL_DELIVERY_DATE',
  ActualPickupDate = 'ACTUAL_PICKUP_DATE',
  CarrierBookingNumber = 'CARRIER_BOOKING_NUMBER',
  CfsStorageStartDate = 'CFS_STORAGE_START_DATE',
  ChargeableVolume = 'CHARGEABLE_VOLUME',
  ChargeableWeight = 'CHARGEABLE_WEIGHT',
  ConsigneeAddress_1 = 'CONSIGNEE_ADDRESS_1',
  ConsigneeAddress_2 = 'CONSIGNEE_ADDRESS_2',
  ConsigneeCity = 'CONSIGNEE_CITY',
  ConsigneeCode = 'CONSIGNEE_CODE',
  ConsigneeCountry = 'CONSIGNEE_COUNTRY',
  ConsigneeKey = 'CONSIGNEE_KEY',
  ConsigneeName = 'CONSIGNEE_NAME',
  ConsigneeState = 'CONSIGNEE_STATE',
  ConsigneeUnlocode = 'CONSIGNEE_UNLOCODE',
  ContainerCountAndType = 'CONTAINER_COUNT_AND_TYPE',
  ContainerGrossWeight = 'CONTAINER_GROSS_WEIGHT',
  ContainerMode = 'CONTAINER_MODE',
  ContainerNumberDisplay = 'CONTAINER_NUMBER_DISPLAY',
  ContainerShipmentGoodsDescription = 'CONTAINER_SHIPMENT_GOODS_DESCRIPTION',
  ContainerShipmentGoodsVolume = 'CONTAINER_SHIPMENT_GOODS_VOLUME',
  ContainerShipmentGoodsWeight = 'CONTAINER_SHIPMENT_GOODS_WEIGHT',
  ContainerTeus = 'CONTAINER_TEUS',
  CtoStorageStartDate = 'CTO_STORAGE_START_DATE',
  DeliveryActualDate = 'DELIVERY_ACTUAL_DATE',
  DeliveryAgentAddress_1 = 'DELIVERY_AGENT_ADDRESS_1',
  DeliveryAgentAddress_2 = 'DELIVERY_AGENT_ADDRESS_2',
  DeliveryAgentCity = 'DELIVERY_AGENT_CITY',
  DeliveryAgentCode = 'DELIVERY_AGENT_CODE',
  DeliveryAgentCountry = 'DELIVERY_AGENT_COUNTRY',
  DeliveryAgentKey = 'DELIVERY_AGENT_KEY',
  DeliveryAgentName = 'DELIVERY_AGENT_NAME',
  DeliveryAgentState = 'DELIVERY_AGENT_STATE',
  DeliveryAgentUnlocode = 'DELIVERY_AGENT_UNLOCODE',
  DeliveryByDate = 'DELIVERY_BY_DATE',
  DeliveryEstimatedDate = 'DELIVERY_ESTIMATED_DATE',
  DeliveryFromDate = 'DELIVERY_FROM_DATE',
  DeliveryToAddress_1 = 'DELIVERY_TO_ADDRESS_1',
  DeliveryToAddress_2 = 'DELIVERY_TO_ADDRESS_2',
  DeliveryToCity = 'DELIVERY_TO_CITY',
  DeliveryToCode = 'DELIVERY_TO_CODE',
  DeliveryToCountry = 'DELIVERY_TO_COUNTRY',
  DeliveryToKey = 'DELIVERY_TO_KEY',
  DeliveryToName = 'DELIVERY_TO_NAME',
  DeliveryToState = 'DELIVERY_TO_STATE',
  DeliveryToUnlocode = 'DELIVERY_TO_UNLOCODE',
  DeliveryTransportCompanyAddress_1 = 'DELIVERY_TRANSPORT_COMPANY_ADDRESS_1',
  DeliveryTransportCompanyAddress_2 = 'DELIVERY_TRANSPORT_COMPANY_ADDRESS_2',
  DeliveryTransportCompanyCity = 'DELIVERY_TRANSPORT_COMPANY_CITY',
  DeliveryTransportCompanyCode = 'DELIVERY_TRANSPORT_COMPANY_CODE',
  DeliveryTransportCompanyCountry = 'DELIVERY_TRANSPORT_COMPANY_COUNTRY',
  DeliveryTransportCompanyKey = 'DELIVERY_TRANSPORT_COMPANY_KEY',
  DeliveryTransportCompanyName = 'DELIVERY_TRANSPORT_COMPANY_NAME',
  DeliveryTransportCompanyState = 'DELIVERY_TRANSPORT_COMPANY_STATE',
  DeliveryTransportCompanyUnlocode = 'DELIVERY_TRANSPORT_COMPANY_UNLOCODE',
  Description = 'DESCRIPTION',
  Direction = 'DIRECTION',
  DischargePortAta = 'DISCHARGE_PORT_ATA',
  DischargePortAtd = 'DISCHARGE_PORT_ATD',
  DischargePortDisplay = 'DISCHARGE_PORT_DISPLAY',
  DischargePortEta = 'DISCHARGE_PORT_ETA',
  DischargePortEtd = 'DISCHARGE_PORT_ETD',
  EmptyRequiredByDate = 'EMPTY_REQUIRED_BY_DATE',
  EmptyReturnRequiredByDate = 'EMPTY_RETURN_REQUIRED_BY_DATE',
  EstimatedDeliveryDate = 'ESTIMATED_DELIVERY_DATE',
  EstimatedPickupDate = 'ESTIMATED_PICKUP_DATE',
  ExportReleaseNumber = 'EXPORT_RELEASE_NUMBER',
  FirstLegAtd = 'FIRST_LEG_ATD',
  FirstLegEtd = 'FIRST_LEG_ETD',
  FirstLegOriginDisplay = 'FIRST_LEG_ORIGIN_DISPLAY',
  ForwarderReference = 'FORWARDER_REFERENCE',
  GoodsValue = 'GOODS_VALUE',
  GoodsValueCurrency = 'GOODS_VALUE_CURRENCY',
  HasExceptions = 'HAS_EXCEPTIONS',
  HouseBill = 'HOUSE_BILL',
  ImporterAddress_1 = 'IMPORTER_ADDRESS_1',
  ImporterAddress_2 = 'IMPORTER_ADDRESS_2',
  ImporterCity = 'IMPORTER_CITY',
  ImporterCode = 'IMPORTER_CODE',
  ImporterCountry = 'IMPORTER_COUNTRY',
  ImporterKey = 'IMPORTER_KEY',
  ImporterName = 'IMPORTER_NAME',
  ImporterState = 'IMPORTER_STATE',
  ImporterUnlocode = 'IMPORTER_UNLOCODE',
  ImportReleaseNumber = 'IMPORT_RELEASE_NUMBER',
  Incoterm = 'INCOTERM',
  IsFailedToArrive = 'IS_FAILED_TO_ARRIVE',
  IsFailedToDepart = 'IS_FAILED_TO_DEPART',
  LastLegArrivalStatus = 'LAST_LEG_ARRIVAL_STATUS',
  LastLegAta = 'LAST_LEG_ATA',
  LastLegDestinationDisplay = 'LAST_LEG_DESTINATION_DISPLAY',
  LastLegEta = 'LAST_LEG_ETA',
  LastMilestoneTracked = 'LAST_MILESTONE_TRACKED',
  LastMilestoneTrackedDate = 'LAST_MILESTONE_TRACKED_DATE',
  LocalClientAddress_1 = 'LOCAL_CLIENT_ADDRESS_1',
  LocalClientAddress_2 = 'LOCAL_CLIENT_ADDRESS_2',
  LocalClientCity = 'LOCAL_CLIENT_CITY',
  LocalClientCode = 'LOCAL_CLIENT_CODE',
  LocalClientCountry = 'LOCAL_CLIENT_COUNTRY',
  LocalClientKey = 'LOCAL_CLIENT_KEY',
  LocalClientName = 'LOCAL_CLIENT_NAME',
  LocalClientState = 'LOCAL_CLIENT_STATE',
  LocalClientUnlocode = 'LOCAL_CLIENT_UNLOCODE',
  MainLegArrivalStatus = 'MAIN_LEG_ARRIVAL_STATUS',
  MainLegCarrierAddress_1 = 'MAIN_LEG_CARRIER_ADDRESS_1',
  MainLegCarrierAddress_2 = 'MAIN_LEG_CARRIER_ADDRESS_2',
  MainLegCarrierCity = 'MAIN_LEG_CARRIER_CITY',
  MainLegCarrierCode = 'MAIN_LEG_CARRIER_CODE',
  MainLegCarrierCountry = 'MAIN_LEG_CARRIER_COUNTRY',
  MainLegCarrierKey = 'MAIN_LEG_CARRIER_KEY',
  MainLegCarrierName = 'MAIN_LEG_CARRIER_NAME',
  MainLegCarrierState = 'MAIN_LEG_CARRIER_STATE',
  MainLegCarrierUnlocode = 'MAIN_LEG_CARRIER_UNLOCODE',
  MarksAndNumbers = 'MARKS_AND_NUMBERS',
  MasterBill = 'MASTER_BILL',
  Milestone = 'MILESTONE',
  OrderRefs = 'ORDER_REFS',
  OriginPortAta = 'ORIGIN_PORT_ATA',
  OriginPortAtd = 'ORIGIN_PORT_ATD',
  OriginPortDisplay = 'ORIGIN_PORT_DISPLAY',
  OriginPortEta = 'ORIGIN_PORT_ETA',
  OriginPortEtd = 'ORIGIN_PORT_ETD',
  Packs = 'PACKS',
  PacksUom = 'PACKS_UOM',
  PickupActualDate = 'PICKUP_ACTUAL_DATE',
  PickupByDate = 'PICKUP_BY_DATE',
  PickupEstimatedDate = 'PICKUP_ESTIMATED_DATE',
  PickupFromAddress_1 = 'PICKUP_FROM_ADDRESS_1',
  PickupFromAddress_2 = 'PICKUP_FROM_ADDRESS_2',
  PickupFromCity = 'PICKUP_FROM_CITY',
  PickupFromCode = 'PICKUP_FROM_CODE',
  PickupFromCountry = 'PICKUP_FROM_COUNTRY',
  PickupFromDate = 'PICKUP_FROM_DATE',
  PickupFromKey = 'PICKUP_FROM_KEY',
  PickupFromName = 'PICKUP_FROM_NAME',
  PickupFromState = 'PICKUP_FROM_STATE',
  PickupFromUnlocode = 'PICKUP_FROM_UNLOCODE',
  ReceivingAgentAddress_1 = 'RECEIVING_AGENT_ADDRESS_1',
  ReceivingAgentAddress_2 = 'RECEIVING_AGENT_ADDRESS_2',
  ReceivingAgentCity = 'RECEIVING_AGENT_CITY',
  ReceivingAgentCode = 'RECEIVING_AGENT_CODE',
  ReceivingAgentCountry = 'RECEIVING_AGENT_COUNTRY',
  ReceivingAgentKey = 'RECEIVING_AGENT_KEY',
  ReceivingAgentName = 'RECEIVING_AGENT_NAME',
  ReceivingAgentState = 'RECEIVING_AGENT_STATE',
  ReceivingAgentUnlocode = 'RECEIVING_AGENT_UNLOCODE',
  ReleaseType = 'RELEASE_TYPE',
  RouteDisplay = 'ROUTE_DISPLAY',
  SealNumber = 'SEAL_NUMBER',
  SendingAgentAddress_1 = 'SENDING_AGENT_ADDRESS_1',
  SendingAgentAddress_2 = 'SENDING_AGENT_ADDRESS_2',
  SendingAgentCity = 'SENDING_AGENT_CITY',
  SendingAgentCode = 'SENDING_AGENT_CODE',
  SendingAgentCountry = 'SENDING_AGENT_COUNTRY',
  SendingAgentKey = 'SENDING_AGENT_KEY',
  SendingAgentName = 'SENDING_AGENT_NAME',
  SendingAgentState = 'SENDING_AGENT_STATE',
  SendingAgentUnlocode = 'SENDING_AGENT_UNLOCODE',
  ServiceLevel = 'SERVICE_LEVEL',
  ShipperAddress_1 = 'SHIPPER_ADDRESS_1',
  ShipperAddress_2 = 'SHIPPER_ADDRESS_2',
  ShipperCity = 'SHIPPER_CITY',
  ShipperCode = 'SHIPPER_CODE',
  ShipperCountry = 'SHIPPER_COUNTRY',
  ShipperKey = 'SHIPPER_KEY',
  ShipperName = 'SHIPPER_NAME',
  ShipperState = 'SHIPPER_STATE',
  ShipperUnlocode = 'SHIPPER_UNLOCODE',
  SupplierAddress_1 = 'SUPPLIER_ADDRESS_1',
  SupplierAddress_2 = 'SUPPLIER_ADDRESS_2',
  SupplierCity = 'SUPPLIER_CITY',
  SupplierCode = 'SUPPLIER_CODE',
  SupplierCountry = 'SUPPLIER_COUNTRY',
  SupplierKey = 'SUPPLIER_KEY',
  SupplierName = 'SUPPLIER_NAME',
  SupplierState = 'SUPPLIER_STATE',
  SupplierUnlocode = 'SUPPLIER_UNLOCODE',
  Teus = 'TEUS',
  TransportMode = 'TRANSPORT_MODE',
  Vessel = 'VESSEL',
  Volume = 'VOLUME',
  Voyage = 'VOYAGE',
  Weight = 'WEIGHT',
}

export enum ShipperVizContainerOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ShipperVizContainersSummary = {
  __typename?: 'ShipperVizContainersSummary'
  containerShipmentGoodsVolume: Array<TableColumnAggregate>
  containerShipmentGoodsWeight: Array<TableColumnAggregate>
  numContainers: Scalars['Int']['output']
  numUniqueContainers: Scalars['Int']['output']
}

export type ShipperVizShipment = {
  __typename?: 'ShipperVizShipment'
  arrivalCfsAddressAddress1?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressAddress2?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressCity?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressCode?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressCountry?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressKey?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressName?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressState?: Maybe<Scalars['String']['output']>
  arrivalCfsAddressUnlocode?: Maybe<Scalars['String']['output']>
  carrierBookingNumber?: Maybe<Scalars['String']['output']>
  cauldronDateCreated?: Maybe<Scalars['DateTime']['output']>
  chargeableVolume?: Maybe<Scalars['Float']['output']>
  chargeableWeight?: Maybe<Scalars['Float']['output']>
  companyShipmentKey?: Maybe<Scalars['String']['output']>
  consigneeAddress1?: Maybe<Scalars['String']['output']>
  consigneeAddress2?: Maybe<Scalars['String']['output']>
  consigneeCity?: Maybe<Scalars['String']['output']>
  consigneeCode?: Maybe<Scalars['String']['output']>
  consigneeCountry?: Maybe<Scalars['String']['output']>
  consigneeKey?: Maybe<Scalars['String']['output']>
  consigneeName?: Maybe<Scalars['String']['output']>
  consigneeState?: Maybe<Scalars['String']['output']>
  consigneeUnlocode?: Maybe<Scalars['String']['output']>
  container?: Maybe<Scalars['String']['output']>
  containerCountAndType?: Maybe<Scalars['String']['output']>
  containerMode?: Maybe<Scalars['String']['output']>
  deliveryActualDate?: Maybe<Scalars['DateTime']['output']>
  deliveryAgentAddress1?: Maybe<Scalars['String']['output']>
  deliveryAgentAddress2?: Maybe<Scalars['String']['output']>
  deliveryAgentCity?: Maybe<Scalars['String']['output']>
  deliveryAgentCode?: Maybe<Scalars['String']['output']>
  deliveryAgentCountry?: Maybe<Scalars['String']['output']>
  deliveryAgentKey?: Maybe<Scalars['String']['output']>
  deliveryAgentName?: Maybe<Scalars['String']['output']>
  deliveryAgentState?: Maybe<Scalars['String']['output']>
  deliveryAgentUnlocode?: Maybe<Scalars['String']['output']>
  deliveryByDate?: Maybe<Scalars['DateTime']['output']>
  deliveryEstimatedDate?: Maybe<Scalars['DateTime']['output']>
  deliveryFromDate?: Maybe<Scalars['DateTime']['output']>
  deliveryToAddress1?: Maybe<Scalars['String']['output']>
  deliveryToAddress2?: Maybe<Scalars['String']['output']>
  deliveryToCity?: Maybe<Scalars['String']['output']>
  deliveryToCode?: Maybe<Scalars['String']['output']>
  deliveryToCountry?: Maybe<Scalars['String']['output']>
  deliveryToKey?: Maybe<Scalars['String']['output']>
  deliveryToName?: Maybe<Scalars['String']['output']>
  deliveryToState?: Maybe<Scalars['String']['output']>
  deliveryToUnlocode?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyAddress1?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyAddress2?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyCity?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyCode?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyCountry?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyKey?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyName?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyState?: Maybe<Scalars['String']['output']>
  deliveryTransportCompanyUnlocode?: Maybe<Scalars['String']['output']>
  departureCfsAddressAddress1?: Maybe<Scalars['String']['output']>
  departureCfsAddressAddress2?: Maybe<Scalars['String']['output']>
  departureCfsAddressCity?: Maybe<Scalars['String']['output']>
  departureCfsAddressCode?: Maybe<Scalars['String']['output']>
  departureCfsAddressCountry?: Maybe<Scalars['String']['output']>
  departureCfsAddressKey?: Maybe<Scalars['String']['output']>
  departureCfsAddressName?: Maybe<Scalars['String']['output']>
  departureCfsAddressState?: Maybe<Scalars['String']['output']>
  departureCfsAddressUnlocode?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  direction?: Maybe<Scalars['String']['output']>
  dischargePortAta?: Maybe<Scalars['DateTime']['output']>
  dischargePortAtd?: Maybe<Scalars['DateTime']['output']>
  dischargePortDisplay?: Maybe<Scalars['String']['output']>
  dischargePortEta?: Maybe<Scalars['DateTime']['output']>
  dischargePortEtd?: Maybe<Scalars['DateTime']['output']>
  firstLegAtd?: Maybe<Scalars['DateTime']['output']>
  firstLegEtd?: Maybe<Scalars['DateTime']['output']>
  firstLegOriginDisplay?: Maybe<Scalars['String']['output']>
  forwarderReference?: Maybe<Scalars['String']['output']>
  goodsValue?: Maybe<Scalars['Float']['output']>
  goodsValueCurrency?: Maybe<Scalars['String']['output']>
  hasExceptions?: Maybe<Scalars['Boolean']['output']>
  houseBill?: Maybe<Scalars['String']['output']>
  importerAddress1?: Maybe<Scalars['String']['output']>
  importerAddress2?: Maybe<Scalars['String']['output']>
  importerCity?: Maybe<Scalars['String']['output']>
  importerCode?: Maybe<Scalars['String']['output']>
  importerCountry?: Maybe<Scalars['String']['output']>
  importerKey?: Maybe<Scalars['String']['output']>
  importerName?: Maybe<Scalars['String']['output']>
  importerState?: Maybe<Scalars['String']['output']>
  importerUnlocode?: Maybe<Scalars['String']['output']>
  incoterm?: Maybe<Scalars['String']['output']>
  isFailedToArrive?: Maybe<Scalars['Boolean']['output']>
  isFailedToDepart?: Maybe<Scalars['Boolean']['output']>
  lastLegArrivalStatus?: Maybe<Scalars['String']['output']>
  lastLegAta?: Maybe<Scalars['DateTime']['output']>
  lastLegDestinationDisplay?: Maybe<Scalars['String']['output']>
  lastLegEta?: Maybe<Scalars['DateTime']['output']>
  localClientAddress1?: Maybe<Scalars['String']['output']>
  localClientAddress2?: Maybe<Scalars['String']['output']>
  localClientCity?: Maybe<Scalars['String']['output']>
  localClientCode?: Maybe<Scalars['String']['output']>
  localClientCountry?: Maybe<Scalars['String']['output']>
  localClientKey?: Maybe<Scalars['String']['output']>
  localClientName?: Maybe<Scalars['String']['output']>
  localClientState?: Maybe<Scalars['String']['output']>
  localClientUnlocode?: Maybe<Scalars['String']['output']>
  mainLegArrivalStatus?: Maybe<Scalars['String']['output']>
  mainLegCarrierAddress1?: Maybe<Scalars['String']['output']>
  mainLegCarrierAddress2?: Maybe<Scalars['String']['output']>
  mainLegCarrierCity?: Maybe<Scalars['String']['output']>
  mainLegCarrierCode?: Maybe<Scalars['String']['output']>
  mainLegCarrierCountry?: Maybe<Scalars['String']['output']>
  mainLegCarrierKey?: Maybe<Scalars['String']['output']>
  mainLegCarrierName?: Maybe<Scalars['String']['output']>
  mainLegCarrierState?: Maybe<Scalars['String']['output']>
  mainLegCarrierUnlocode?: Maybe<Scalars['String']['output']>
  marksAndNumbers?: Maybe<Scalars['String']['output']>
  milestone?: Maybe<Scalars['String']['output']>
  orderRefs?: Maybe<Scalars['String']['output']>
  originPortAta?: Maybe<Scalars['DateTime']['output']>
  originPortAtd?: Maybe<Scalars['DateTime']['output']>
  originPortDisplay?: Maybe<Scalars['String']['output']>
  originPortEta?: Maybe<Scalars['DateTime']['output']>
  originPortEtd?: Maybe<Scalars['DateTime']['output']>
  packs?: Maybe<Scalars['Float']['output']>
  packsUom?: Maybe<Scalars['String']['output']>
  pickupActualDate?: Maybe<Scalars['DateTime']['output']>
  pickupByDate?: Maybe<Scalars['DateTime']['output']>
  pickupEstimatedDate?: Maybe<Scalars['DateTime']['output']>
  pickupFromAddress1?: Maybe<Scalars['String']['output']>
  pickupFromAddress2?: Maybe<Scalars['String']['output']>
  pickupFromCity?: Maybe<Scalars['String']['output']>
  pickupFromCode?: Maybe<Scalars['String']['output']>
  pickupFromCountry?: Maybe<Scalars['String']['output']>
  pickupFromDate?: Maybe<Scalars['DateTime']['output']>
  pickupFromKey?: Maybe<Scalars['String']['output']>
  pickupFromName?: Maybe<Scalars['String']['output']>
  pickupFromState?: Maybe<Scalars['String']['output']>
  pickupFromUnlocode?: Maybe<Scalars['String']['output']>
  receivingAgentAddress1?: Maybe<Scalars['String']['output']>
  receivingAgentAddress2?: Maybe<Scalars['String']['output']>
  receivingAgentCity?: Maybe<Scalars['String']['output']>
  receivingAgentCode?: Maybe<Scalars['String']['output']>
  receivingAgentCountry?: Maybe<Scalars['String']['output']>
  receivingAgentKey?: Maybe<Scalars['String']['output']>
  receivingAgentName?: Maybe<Scalars['String']['output']>
  receivingAgentState?: Maybe<Scalars['String']['output']>
  receivingAgentUnlocode?: Maybe<Scalars['String']['output']>
  releaseType?: Maybe<Scalars['String']['output']>
  routeDisplay?: Maybe<Scalars['String']['output']>
  sendingAgentAddress1?: Maybe<Scalars['String']['output']>
  sendingAgentAddress2?: Maybe<Scalars['String']['output']>
  sendingAgentCity?: Maybe<Scalars['String']['output']>
  sendingAgentCode?: Maybe<Scalars['String']['output']>
  sendingAgentCountry?: Maybe<Scalars['String']['output']>
  sendingAgentKey?: Maybe<Scalars['String']['output']>
  sendingAgentName?: Maybe<Scalars['String']['output']>
  sendingAgentState?: Maybe<Scalars['String']['output']>
  sendingAgentUnlocode?: Maybe<Scalars['String']['output']>
  serviceLevel?: Maybe<Scalars['String']['output']>
  shipperAddress1?: Maybe<Scalars['String']['output']>
  shipperAddress2?: Maybe<Scalars['String']['output']>
  shipperCity?: Maybe<Scalars['String']['output']>
  shipperCode?: Maybe<Scalars['String']['output']>
  shipperCountry?: Maybe<Scalars['String']['output']>
  shipperKey?: Maybe<Scalars['String']['output']>
  shipperName?: Maybe<Scalars['String']['output']>
  shipperState?: Maybe<Scalars['String']['output']>
  shipperUnlocode?: Maybe<Scalars['String']['output']>
  snowflakeDateCreated?: Maybe<Scalars['DateTime']['output']>
  supplierAddress1?: Maybe<Scalars['String']['output']>
  supplierAddress2?: Maybe<Scalars['String']['output']>
  supplierCity?: Maybe<Scalars['String']['output']>
  supplierCode?: Maybe<Scalars['String']['output']>
  supplierCountry?: Maybe<Scalars['String']['output']>
  supplierKey?: Maybe<Scalars['String']['output']>
  supplierName?: Maybe<Scalars['String']['output']>
  supplierState?: Maybe<Scalars['String']['output']>
  supplierUnlocode?: Maybe<Scalars['String']['output']>
  teus?: Maybe<Scalars['Float']['output']>
  transportMode?: Maybe<Scalars['String']['output']>
  vessel?: Maybe<Scalars['String']['output']>
  volume?: Maybe<Scalars['Float']['output']>
  voyage?: Maybe<Scalars['String']['output']>
  weight?: Maybe<Scalars['Float']['output']>
}

export enum ShipperVizShipmentColumn {
  AgentReference = 'AGENT_REFERENCE',
  ArrivalCfsAddressName = 'ARRIVAL_CFS_ADDRESS_NAME',
  BillingStatus = 'BILLING_STATUS',
  CarrierBookingNumber = 'CARRIER_BOOKING_NUMBER',
  CauldronDateCreated = 'CAULDRON_DATE_CREATED',
  ChargeableVolume = 'CHARGEABLE_VOLUME',
  ChargeableWeight = 'CHARGEABLE_WEIGHT',
  ConsigneeCity = 'CONSIGNEE_CITY',
  ConsigneeCode = 'CONSIGNEE_CODE',
  ConsigneeCountry = 'CONSIGNEE_COUNTRY',
  ConsigneeName = 'CONSIGNEE_NAME',
  ConsigneeState = 'CONSIGNEE_STATE',
  ConsigneeUnlocode = 'CONSIGNEE_UNLOCODE',
  ConsolNumber = 'CONSOL_NUMBER',
  Container = 'CONTAINER',
  ContainerCountAndType = 'CONTAINER_COUNT_AND_TYPE',
  ContainerMode = 'CONTAINER_MODE',
  ControllingAgentCity = 'CONTROLLING_AGENT_CITY',
  ControllingAgentCode = 'CONTROLLING_AGENT_CODE',
  ControllingAgentCountry = 'CONTROLLING_AGENT_COUNTRY',
  ControllingAgentName = 'CONTROLLING_AGENT_NAME',
  ControllingAgentState = 'CONTROLLING_AGENT_STATE',
  ControllingAgentUnlocode = 'CONTROLLING_AGENT_UNLOCODE',
  ControllingCustomerCity = 'CONTROLLING_CUSTOMER_CITY',
  ControllingCustomerCode = 'CONTROLLING_CUSTOMER_CODE',
  ControllingCustomerCountry = 'CONTROLLING_CUSTOMER_COUNTRY',
  ControllingCustomerName = 'CONTROLLING_CUSTOMER_NAME',
  ControllingCustomerState = 'CONTROLLING_CUSTOMER_STATE',
  ControllingCustomerUnlocode = 'CONTROLLING_CUSTOMER_UNLOCODE',
  Currency = 'CURRENCY',
  DateShipmentClosed = 'DATE_SHIPMENT_CLOSED',
  DateShipmentCreated = 'DATE_SHIPMENT_CREATED',
  DeclarationDischargeDate = 'DECLARATION_DISCHARGE_DATE',
  DeclarationEntryNum = 'DECLARATION_ENTRY_NUM',
  DeclarationMessageType = 'DECLARATION_MESSAGE_TYPE',
  DeliveryActualDate = 'DELIVERY_ACTUAL_DATE',
  DeliveryAgentName = 'DELIVERY_AGENT_NAME',
  DeliveryByDate = 'DELIVERY_BY_DATE',
  DeliveryEstimatedDate = 'DELIVERY_ESTIMATED_DATE',
  DeliveryFromDate = 'DELIVERY_FROM_DATE',
  DeliveryToCity = 'DELIVERY_TO_CITY',
  DeliveryToCode = 'DELIVERY_TO_CODE',
  DeliveryToCountry = 'DELIVERY_TO_COUNTRY',
  DeliveryToName = 'DELIVERY_TO_NAME',
  DeliveryToState = 'DELIVERY_TO_STATE',
  DeliveryToUnlocode = 'DELIVERY_TO_UNLOCODE',
  DeliveryTransportCompanyCity = 'DELIVERY_TRANSPORT_COMPANY_CITY',
  DeliveryTransportCompanyCode = 'DELIVERY_TRANSPORT_COMPANY_CODE',
  DeliveryTransportCompanyCountry = 'DELIVERY_TRANSPORT_COMPANY_COUNTRY',
  DeliveryTransportCompanyName = 'DELIVERY_TRANSPORT_COMPANY_NAME',
  DeliveryTransportCompanyState = 'DELIVERY_TRANSPORT_COMPANY_STATE',
  DeliveryTransportCompanyUnlocode = 'DELIVERY_TRANSPORT_COMPANY_UNLOCODE',
  DepartureCfsAddressName = 'DEPARTURE_CFS_ADDRESS_NAME',
  Description = 'DESCRIPTION',
  Direction = 'DIRECTION',
  DischargePortAta = 'DISCHARGE_PORT_ATA',
  DischargePortAtd = 'DISCHARGE_PORT_ATD',
  DischargePortDisplay = 'DISCHARGE_PORT_DISPLAY',
  DischargePortEta = 'DISCHARGE_PORT_ETA',
  DischargePortEtd = 'DISCHARGE_PORT_ETD',
  FirstApPosted = 'FIRST_AP_POSTED',
  FirstArPosted = 'FIRST_AR_POSTED',
  FirstLegAtd = 'FIRST_LEG_ATD',
  FirstLegEtd = 'FIRST_LEG_ETD',
  FirstLegOriginDisplay = 'FIRST_LEG_ORIGIN_DISPLAY',
  ForwarderReference = 'FORWARDER_REFERENCE',
  GoodsValue = 'GOODS_VALUE',
  GoodsValueCurrency = 'GOODS_VALUE_CURRENCY',
  HasExceptions = 'HAS_EXCEPTIONS',
  HouseBill = 'HOUSE_BILL',
  ImporterCity = 'IMPORTER_CITY',
  ImporterCode = 'IMPORTER_CODE',
  ImporterCountry = 'IMPORTER_COUNTRY',
  ImporterName = 'IMPORTER_NAME',
  ImporterState = 'IMPORTER_STATE',
  ImporterUnlocode = 'IMPORTER_UNLOCODE',
  Incoterm = 'INCOTERM',
  IsFailedToArrive = 'IS_FAILED_TO_ARRIVE',
  IsFailedToDepart = 'IS_FAILED_TO_DEPART',
  LastLegArrivalStatus = 'LAST_LEG_ARRIVAL_STATUS',
  LastLegAta = 'LAST_LEG_ATA',
  LastLegDestinationDisplay = 'LAST_LEG_DESTINATION_DISPLAY',
  LastLegEta = 'LAST_LEG_ETA',
  LocalClientCity = 'LOCAL_CLIENT_CITY',
  LocalClientCode = 'LOCAL_CLIENT_CODE',
  LocalClientCountry = 'LOCAL_CLIENT_COUNTRY',
  LocalClientName = 'LOCAL_CLIENT_NAME',
  LocalClientState = 'LOCAL_CLIENT_STATE',
  LocalClientUnlocode = 'LOCAL_CLIENT_UNLOCODE',
  MainLegArrivalStatus = 'MAIN_LEG_ARRIVAL_STATUS',
  MainLegCarrierCity = 'MAIN_LEG_CARRIER_CITY',
  MainLegCarrierCode = 'MAIN_LEG_CARRIER_CODE',
  MainLegCarrierCountry = 'MAIN_LEG_CARRIER_COUNTRY',
  MainLegCarrierName = 'MAIN_LEG_CARRIER_NAME',
  MainLegCarrierState = 'MAIN_LEG_CARRIER_STATE',
  MainLegCarrierUnlocode = 'MAIN_LEG_CARRIER_UNLOCODE',
  MarksAndNumbers = 'MARKS_AND_NUMBERS',
  MasterBill = 'MASTER_BILL',
  Milestone = 'MILESTONE',
  Module = 'MODULE',
  OrderRefs = 'ORDER_REFS',
  OriginPortAta = 'ORIGIN_PORT_ATA',
  OriginPortAtd = 'ORIGIN_PORT_ATD',
  OriginPortDisplay = 'ORIGIN_PORT_DISPLAY',
  OriginPortEta = 'ORIGIN_PORT_ETA',
  OriginPortEtd = 'ORIGIN_PORT_ETD',
  Packs = 'PACKS',
  PacksUom = 'PACKS_UOM',
  PickupActualDate = 'PICKUP_ACTUAL_DATE',
  PickupByDate = 'PICKUP_BY_DATE',
  PickupEstimatedDate = 'PICKUP_ESTIMATED_DATE',
  PickupFromDate = 'PICKUP_FROM_DATE',
  PickupFromName = 'PICKUP_FROM_NAME',
  PickupTransportCompanyCity = 'PICKUP_TRANSPORT_COMPANY_CITY',
  PickupTransportCompanyCode = 'PICKUP_TRANSPORT_COMPANY_CODE',
  PickupTransportCompanyCountry = 'PICKUP_TRANSPORT_COMPANY_COUNTRY',
  PickupTransportCompanyName = 'PICKUP_TRANSPORT_COMPANY_NAME',
  PickupTransportCompanyState = 'PICKUP_TRANSPORT_COMPANY_STATE',
  PickupTransportCompanyUnlocode = 'PICKUP_TRANSPORT_COMPANY_UNLOCODE',
  ReceivingAgentCity = 'RECEIVING_AGENT_CITY',
  ReceivingAgentCode = 'RECEIVING_AGENT_CODE',
  ReceivingAgentCountry = 'RECEIVING_AGENT_COUNTRY',
  ReceivingAgentName = 'RECEIVING_AGENT_NAME',
  ReceivingAgentState = 'RECEIVING_AGENT_STATE',
  ReceivingAgentUnlocode = 'RECEIVING_AGENT_UNLOCODE',
  ReleaseType = 'RELEASE_TYPE',
  RouteDisplay = 'ROUTE_DISPLAY',
  SendingAgentCity = 'SENDING_AGENT_CITY',
  SendingAgentCode = 'SENDING_AGENT_CODE',
  SendingAgentCountry = 'SENDING_AGENT_COUNTRY',
  SendingAgentName = 'SENDING_AGENT_NAME',
  SendingAgentState = 'SENDING_AGENT_STATE',
  SendingAgentUnlocode = 'SENDING_AGENT_UNLOCODE',
  ServiceLevel = 'SERVICE_LEVEL',
  ServiceLevelDescription = 'SERVICE_LEVEL_DESCRIPTION',
  ShipmentAta = 'SHIPMENT_ATA',
  ShipmentAtd = 'SHIPMENT_ATD',
  ShipmentDestination = 'SHIPMENT_DESTINATION',
  ShipmentEta = 'SHIPMENT_ETA',
  ShipmentEtd = 'SHIPMENT_ETD',
  ShipmentOrigin = 'SHIPMENT_ORIGIN',
  ShipmentStatus = 'SHIPMENT_STATUS',
  ShipperCity = 'SHIPPER_CITY',
  ShipperCode = 'SHIPPER_CODE',
  ShipperCountry = 'SHIPPER_COUNTRY',
  ShipperName = 'SHIPPER_NAME',
  ShipperState = 'SHIPPER_STATE',
  ShipperUnlocode = 'SHIPPER_UNLOCODE',
  SnowflakeDateCreated = 'SNOWFLAKE_DATE_CREATED',
  SupplierCity = 'SUPPLIER_CITY',
  SupplierCode = 'SUPPLIER_CODE',
  SupplierCountry = 'SUPPLIER_COUNTRY',
  SupplierName = 'SUPPLIER_NAME',
  SupplierState = 'SUPPLIER_STATE',
  SupplierUnlocode = 'SUPPLIER_UNLOCODE',
  Teus = 'TEUS',
  TransportMode = 'TRANSPORT_MODE',
  TransportNumbers = 'TRANSPORT_NUMBERS',
  Vessel = 'VESSEL',
  Volume = 'VOLUME',
  Voyage = 'VOYAGE',
  Weight = 'WEIGHT',
}

export enum ShipperVizShipmentOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ShipperVizShipmentsSummary = {
  __typename?: 'ShipperVizShipmentsSummary'
  chargeableVolume: Array<TableColumnAggregate>
  chargeableWeight: Array<TableColumnAggregate>
  numShipments: Scalars['Int']['output']
  teus: Array<TableColumnAggregate>
  volume: Array<TableColumnAggregate>
  weight: Array<TableColumnAggregate>
}

export type SoaLineItemModelMatches = {
  __typename?: 'SoaLineItemModelMatches'
  chainIoConsolidations: Array<ChainIoConsolidationNode>
  chainIoCustomsDeclarations: Array<ChainIoCustomsDeclarationNode>
  chainIoShipments: Array<ChainIoShipmentNode>
}

export enum SortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type StarTask = {
  __typename?: 'StarTask'
  task: TaskNode
}

export type StringConversion = {
  __typename?: 'StringConversion'
  destination: Scalars['String']['output']
  source: Scalars['String']['output']
}

export type SummaryDataResult = {
  __typename?: 'SummaryDataResult'
  currentAmount?: Maybe<Scalars['Decimal']['output']>
  currentAmountAlternate?: Maybe<Scalars['Decimal']['output']>
  drilldownCta?: Maybe<DrilldownCta>
  drilldownCtaDisplayName?: Maybe<Scalars['String']['output']>
  linkedTab?: Maybe<DashboardReference>
  previousAmount?: Maybe<Scalars['Decimal']['output']>
  unitType: SummaryUnitType
}

export type SummaryDataResultLineGraphDataResultHistogramDataResultHistogramStackedDataResultHistogramGroupedDataResultDataResultPeriodsBenchmarkDataResultCashCycleDataResultRegionMapDataResultScatterplotDataResultPinMapDataResultTableDataResultSankeyMapDataResultBarWithLineResultPivotTableDataResultExploreShipmentDataResultExploreInvoiceDataResultExploreOrganizationDataResultCalendarHeatmapDataResult =

    | BarWithLineResult
    | BenchmarkDataResult
    | CalendarHeatmapDataResult
    | CashCycleDataResult
    | DataResultPeriods
    | ExploreInvoiceDataResult
    | ExploreOrganizationDataResult
    | ExploreShipmentDataResult
    | HistogramDataResult
    | HistogramGroupedDataResult
    | HistogramStackedDataResult
    | LineGraphDataResult
    | PinMapDataResult
    | PivotTableDataResult
    | RegionMapDataResult
    | SankeyMapDataResult
    | ScatterplotDataResult
    | SummaryDataResult
    | TableDataResult

export enum SummaryUnitType {
  Currency = 'CURRENCY',
  Exceptions = 'EXCEPTIONS',
  FilesPerOperator = 'FILES_PER_OPERATOR',
  GroupByDate = 'GROUP_BY_DATE',
  InvoiceVolume = 'INVOICE_VOLUME',
  Margin = 'MARGIN',
  OrgType = 'ORG_TYPE',
  Period = 'PERIOD',
  ShipmentVolume = 'SHIPMENT_VOLUME',
  Volume = 'VOLUME',
  Weight = 'WEIGHT',
}

export type SummaryUnitTypeEnum = {
  __typename?: 'SummaryUnitTypeEnum'
  isFallback: Scalars['Boolean']['output']
  value: SummaryUnitType
}

export type SummaryUnitTypeWithFallback = FallbackValue | SummaryUnitTypeEnum

export enum SyncSource {
  FetchForCheckShipmentInfo = 'FETCH_FOR_CHECK_SHIPMENT_INFO',
  FetchForColdStart = 'FETCH_FOR_COLD_START',
  FetchForCronJob = 'FETCH_FOR_CRON_JOB',
  FetchForReconciliation = 'FETCH_FOR_RECONCILIATION',
  PushedFromEadaptor = 'PUSHED_FROM_EADAPTOR',
  Uncategorized = 'UNCATEGORIZED',
}

export type TableColumnAggregate = {
  __typename?: 'TableColumnAggregate'
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export enum TableColumnType {
  Address = 'ADDRESS',
  Boolean = 'BOOLEAN',
  Currency = 'CURRENCY',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Days = 'DAYS',
  Decimal = 'DECIMAL',
  Integer = 'INTEGER',
  Link = 'LINK',
  ListOfText = 'LIST_OF_TEXT',
  Money = 'MONEY',
  Number = 'NUMBER',
  Percent = 'PERCENT',
  Text = 'TEXT',
  Time = 'TIME',
  UnconvertedVolume = 'UNCONVERTED_VOLUME',
  UnconvertedWeight = 'UNCONVERTED_WEIGHT',
  Uuid = 'UUID',
  Volume = 'VOLUME',
  Weight = 'WEIGHT',
}

export type TableDataResult = {
  __typename?: 'TableDataResult'
  columns: Array<Scalars['String']['output']>
  dataTableOptions?: Maybe<Scalars['GenericScalar']['output']>
  results: Array<TableRow>
}

export type TableRow = {
  __typename?: 'TableRow'
  data: Array<Scalars['String']['output']>
}

export enum TaskFilterColumn {
  Company = 'COMPANY',
  DateConfirmed = 'DATE_CONFIRMED',
  DateCreated = 'DATE_CREATED',
  DateReceived = 'DATE_RECEIVED',
  JobOwner = 'JOB_OWNER',
  JobQa = 'JOB_QA',
  JobType = 'JOB_TYPE',
  TaskType = 'TASK_TYPE',
}

export type TaskFilterColumnEnum = {
  __typename?: 'TaskFilterColumnEnum'
  isFallback: Scalars['Boolean']['output']
  value: TaskFilterColumn
}

export type TaskFilterColumnWithFallback = FallbackValue | TaskFilterColumnEnum

export enum TaskFilterOperation {
  After = 'AFTER',
  Before = 'BEFORE',
  Contains = 'CONTAINS',
  DoesNotContain = 'DOES_NOT_CONTAIN',
  Is = 'IS',
  IsNot = 'IS_NOT',
}

export type TaskFilterSettingNode = {
  __typename?: 'TaskFilterSettingNode'
  column: TaskFilterColumnWithFallback
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  operation: TaskFilterOperation
  user: UserNode
  userId: Scalars['UUID']['output']
  value: Array<Scalars['String']['output']>
}

export type TaskNode = {
  __typename?: 'TaskNode'
  blocked: Scalars['Boolean']['output']
  cleanedTaskReferenceId: Scalars['String']['output']
  cleanedTitle: Scalars['String']['output']
  company: CompanyNode
  companyId: Scalars['UUID']['output']
  dateConfirmed?: Maybe<Scalars['DateTime']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  dateReceived?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['UUID']['output']
  isCriticallyLow: Scalars['Boolean']['output']
  isStarred: Scalars['Boolean']['output']
  jobs: JobNodeConnection
  message?: Maybe<Scalars['String']['output']>
  starred: Scalars['Boolean']['output']
  status: TaskStatus
  taskNotes: TaskNoteNodeConnection
  taskReferenceId: Scalars['String']['output']
  taskSlaTimeJobRef?: Maybe<TaskSlaTimeJobRefNode>
  taskType: TaskType
  title: Scalars['String']['output']
}

export type TaskNodeConnection = {
  __typename?: 'TaskNodeConnection'
  edges: Array<TaskNodeEdge>
}

export type TaskNodeEdge = {
  __typename?: 'TaskNodeEdge'
  node: TaskNode
}

export type TaskNoteNode = {
  __typename?: 'TaskNoteNode'
  content?: Maybe<Scalars['JSONString']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  task: TaskNode
  taskId: Scalars['UUID']['output']
  user: UserNode
  userId: Scalars['UUID']['output']
}

export type TaskNoteNodeConnection = {
  __typename?: 'TaskNoteNodeConnection'
  edges: Array<TaskNoteNodeEdge>
}

export type TaskNoteNodeEdge = {
  __typename?: 'TaskNoteNodeEdge'
  node: TaskNoteNode
}

export type TaskPages = {
  __typename?: 'TaskPages'
  counts: Array<TaskStatusCount>
  filteredCounts: Array<TaskStatusCount>
  items: Array<TaskNode>
}

export type TaskSearchParameters = {
  __typename?: 'TaskSearchParameters'
  filters: Array<TaskFilterSettingNode>
  queryString: Scalars['String']['output']
}

export type TaskSlaTimeJobRefNode = {
  __typename?: 'TaskSlaTimeJobRefNode'
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  job?: Maybe<JobNode>
  jobId?: Maybe<Scalars['UUID']['output']>
  task: TaskNode
  taskId: Scalars['UUID']['output']
}

export enum TaskStatus {
  Confirmation = 'CONFIRMATION',
  Deleted = 'DELETED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Qa = 'QA',
  Todo = 'TODO',
}

export type TaskStatusCount = {
  __typename?: 'TaskStatusCount'
  count: Scalars['Int']['output']
  status: TaskStatus
}

export enum TaskType {
  Live = 'LIVE',
  Test = 'TEST',
}

export type TaxNode = SearchableRecordResultNode & {
  __typename?: 'TaxNode'
  apiPartner?: Maybe<ApiPartnerInterface>
  apiPartnerId?: Maybe<Scalars['UUID']['output']>
  cargowiseConfig?: Maybe<CargowiseConfigNode>
  cargowiseConfigId?: Maybe<Scalars['UUID']['output']>
  chargeCodes: ChargeCodeNodeConnection
  chargeCodesV2: ChargeCodeV2NodeConnection
  dateCreated: Scalars['DateTime']['output']
  dateDeleted?: Maybe<Scalars['DateTime']['output']>
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  taxCode: Scalars['String']['output']
  taxRate: Scalars['Decimal']['output']
  taxSystem?: Maybe<Scalars['String']['output']>
  taxType?: Maybe<Scalars['String']['output']>
}

export type TaxNodeConnection = {
  __typename?: 'TaxNodeConnection'
  edges: Array<TaxNodeEdge>
}

export type TaxNodeEdge = {
  __typename?: 'TaxNodeEdge'
  node: TaxNode
}

export type TestApiPartner = {
  __typename?: 'TestApiPartner'
  ok: Scalars['Boolean']['output']
}

export type TestCargowiseConfig = {
  __typename?: 'TestCargowiseConfig'
  ok: Scalars['Boolean']['output']
}

export type TextFilterIn = {
  exact: Scalars['Boolean']['input']
  values: Array<Scalars['String']['input']>
}

export type TextFilterMatch = {
  flag?: InputMaybe<Scalars['String']['input']>
  pattern: Scalars['String']['input']
}

export type TiffToPng = {
  __typename?: 'TiffToPng'
  images: Array<Scalars['String']['output']>
  numPages: Scalars['Int']['output']
  sizes: Scalars['GenericScalar']['output']
}

export type TransactionAsyncBatchNode = {
  __typename?: 'TransactionAsyncBatchNode'
  dateCompleted?: Maybe<Scalars['DateTime']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  jobId?: Maybe<Scalars['UUID']['output']>
  numDone: Scalars['Int']['output']
  numTasks: Scalars['Int']['output']
  status: TransactionAsyncStatus
  transactionAsyncTasks: TransactionAsyncTaskNodeConnection
  userCreated: UserNode
  userCreatedId: Scalars['UUID']['output']
}

export enum TransactionAsyncStatus {
  Done = 'DONE',
  Error = 'ERROR',
  Pending = 'PENDING',
}

export type TransactionAsyncTaskNode = {
  __typename?: 'TransactionAsyncTaskNode'
  asLumpsum: Scalars['Boolean']['output']
  cargowiseModule?: Maybe<Scalars['String']['output']>
  chargeCodeTaxCodeMapping?: Maybe<Scalars['JSONString']['output']>
  chargeCodeTaxRateMapping?: Maybe<Scalars['JSONString']['output']>
  chargeVendorCode?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  disableSendDueDate: Scalars['Boolean']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  fieldValueMap?: Maybe<Scalars['JSONString']['output']>
  forwarderReference?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  invoiceNumber?: Maybe<Scalars['String']['output']>
  invoiceTmsId?: Maybe<Scalars['String']['output']>
  overrideChargeDescription: Scalars['Boolean']['output']
  processingLog?: Maybe<Scalars['String']['output']>
  rawCargowiseInbound?: Maybe<RawCargowiseInboundNode>
  rawCargowiseInboundId?: Maybe<Scalars['UUID']['output']>
  soaTransactionLineItemSnapshots: SoaTransactionLineItemSnapshotNodeConnection
  status: TransactionAsyncStatus
  taxCodeTaxRateMapping?: Maybe<Scalars['JSONString']['output']>
  transactionAsyncBatch: TransactionAsyncBatchNode
  transactionAsyncBatchId: Scalars['UUID']['output']
}

export type TransactionAsyncTaskNodeConnection = {
  __typename?: 'TransactionAsyncTaskNodeConnection'
  edges: Array<TransactionAsyncTaskNodeEdge>
}

export type TransactionAsyncTaskNodeEdge = {
  __typename?: 'TransactionAsyncTaskNodeEdge'
  node: TransactionAsyncTaskNode
}

export type TransportModeDatum = {
  __typename?: 'TransportModeDatum'
  numShipments: Scalars['Int']['output']
  revenue: Scalars['Float']['output']
  transportMode: Scalars['String']['output']
}

export type UsCarrierNode = SearchableRecordResultNode & {
  __typename?: 'USCarrierNode'
  address?: Maybe<Scalars['String']['output']>
  airwayBillPrefix?: Maybe<Scalars['String']['output']>
  cleanedLabel?: Maybe<Scalars['String']['output']>
  code?: Maybe<Scalars['String']['output']>
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  id: Scalars['UUID']['output']
  isSystemGenerated?: Maybe<Scalars['String']['output']>
  label?: Maybe<Scalars['String']['output']>
  modeOfTransportation?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type UnitSetting = {
  currency?: Scalars['String']['input']
  groupByDate?: GroupByDateType
  orgType?: InputMaybe<MeasurementUnitInput>
  periodType?: PeriodType
  shipmentVolumeUnit?: InputMaybe<MeasurementUnitInput>
  volumeUnit?: InputMaybe<MeasurementUnitInput>
  weightUnit: MeasurementUnitInput
}

export enum UnitType {
  OrgType = 'ORG_TYPE',
  ShipmentCount = 'SHIPMENT_COUNT',
  Teus = 'TEUS',
  Volume = 'VOLUME',
  Weight = 'WEIGHT',
}

export type UnmergeDocumentTable = {
  __typename?: 'UnmergeDocumentTable'
  ok: Scalars['Boolean']['output']
}

export type UpdateApiPartner = {
  __typename?: 'UpdateApiPartner'
  apiPartner: ApiPartnerNode
}

export type UpdateBatchReconResolutionStatusAndCreateNote = {
  __typename?: 'UpdateBatchReconResolutionStatusAndCreateNote'
  note: NoteNode
  reconAsyncBatch: ReconAsyncBatchNode
}

export type UpdateCargowiseConfig = {
  __typename?: 'UpdateCargowiseConfig'
  cwConfig: CargowiseConfigNode
}

export type UpdateChargeQuantity = {
  __typename?: 'UpdateChargeQuantity'
  ok: Scalars['Boolean']['output']
}

export type UpdateDocumentTablesOrder = {
  __typename?: 'UpdateDocumentTablesOrder'
  ok: Scalars['Boolean']['output']
}

export type UpdateReconAttemptStatus = {
  __typename?: 'UpdateReconAttemptStatus'
  reconAttempt: ReconAttemptNode
}

export type UpdateReconDashboardUserFilter = {
  __typename?: 'UpdateReconDashboardUserFilter'
  ok: Scalars['Boolean']['output']
}

export type UpdateTask = {
  __typename?: 'UpdateTask'
  task: TaskNode
}

export type UpdateTaskCompany = {
  __typename?: 'UpdateTaskCompany'
  task: TaskNode
}

export type UpdateTaskStatus = {
  __typename?: 'UpdateTaskStatus'
  task: TaskNode
}

export type UpdateTaskType = {
  __typename?: 'UpdateTaskType'
  task: TaskNode
}

export type UploadOriginalPdFsToS3 = {
  __typename?: 'UploadOriginalPDFsToS3'
  files: Array<OriginalPdf>
}

export type UpsertDocumentTableFieldGroup = {
  __typename?: 'UpsertDocumentTableFieldGroup'
  ok: Scalars['Boolean']['output']
}

export type UpsertJobTemplate = {
  __typename?: 'UpsertJobTemplate'
  ok: Scalars['Boolean']['output']
}

export type UpsertSeperatedChargeVendorsChargeCodes = {
  __typename?: 'UpsertSeperatedChargeVendorsChargeCodes'
  chargeCodeUploadAsyncTaskId: Scalars['UUID']['output']
}

export type UserDashboardNode = {
  __typename?: 'UserDashboardNode'
  cards: Array<CardNode>
  dashboardIam: DashboardIamNode
  dashboardTab?: Maybe<DashboardTabType>
  displayName: Scalars['String']['output']
  enabled: Scalars['Boolean']['output']
  id: Scalars['UUID']['output']
  iframeOverrideUrl?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  publicUrl?: Maybe<Scalars['String']['output']>
}

export type UserList = {
  __typename?: 'UserList'
  limit: Scalars['Int']['output']
  page: Scalars['Int']['output']
  total: Scalars['Int']['output']
  users: Array<AdminUserProfileNode>
}

export type UserNode = {
  __typename?: 'UserNode'
  assignedJobs: JobNodeConnection
  auth0Id?: Maybe<Scalars['String']['output']>
  branch?: Maybe<Scalars['String']['output']>
  branches: Array<Scalars['String']['output']>
  companies: CompanyNodeConnection
  company?: Maybe<CompanyNode>
  companyId?: Maybe<Scalars['UUID']['output']>
  companyUsers: CompanyUserNodeConnection
  createdJobs: JobNodeConnection
  dateCreated: Scalars['DateTime']['output']
  dateModified: Scalars['DateTime']['output']
  defaultDashboardsWhitelist: Array<Scalars['String']['output']>
  department?: Maybe<Scalars['String']['output']>
  departments?: Maybe<Array<Scalars['String']['output']>>
  documents: DocumentNodeConnection
  email: Scalars['String']['output']
  filePages: FilePageNodeConnection
  files: FileNodeConnection
  id: Scalars['UUID']['output']
  isCustomer: Scalars['Boolean']['output']
  jobTemplates: JobTemplateNodeConnection
  metabaseDashboardId?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  notes: NoteNodeConnection
  onlyViewAssignedShipments: Scalars['Boolean']['output']
  onlyViewDashboardCompany: Scalars['Boolean']['output']
  operationsRep?: Maybe<Scalars['String']['output']>
  operationsReps?: Maybe<Array<Scalars['String']['output']>>
  ownedJobs: JobNodeConnection
  pageAsyncBatches: PageAsyncBatchNodeConnection
  qadJobs: JobNodeConnection
  reconNotes: ReconNoteNodeConnection
  roles: Array<UserRoleWithFallback>
  salesRep?: Maybe<Scalars['String']['output']>
  salesReps?: Maybe<Array<Scalars['String']['output']>>
}

export type UserNodeConnection = {
  __typename?: 'UserNodeConnection'
  edges: Array<UserNodeEdge>
}

export type UserNodeEdge = {
  __typename?: 'UserNodeEdge'
  node: UserNode
}

export enum UserRole {
  Client = 'CLIENT',
  ClientAdmin = 'CLIENT_ADMIN',
  ClientPartner = 'CLIENT_PARTNER',
  ClientShipperPortalAdmin = 'CLIENT_SHIPPER_PORTAL_ADMIN',
  Engineer = 'ENGINEER',
  ExpedockAdmin = 'EXPEDOCK_ADMIN',
  Internal = 'INTERNAL',
  Operator = 'OPERATOR',
  TeamLead = 'TEAM_LEAD',
}

export type UserRoleEnum = {
  __typename?: 'UserRoleEnum'
  isFallback: Scalars['Boolean']['output']
  value: UserRole
}

export type UserRoleWithFallback = FallbackValue | UserRoleEnum

export type VariableMapping = {
  key: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type ViewOperatorCompanies = {
  __typename?: 'ViewOperatorCompanies'
  onlyViewDashboardCompany: Scalars['Boolean']['output']
  operatorCompanies?: Maybe<Array<CompanyNode>>
}

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>
}>

type definedNonNullAny = {}

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny => v !== undefined && v !== null

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v))

export const AnReconAutofillKeySchema = z.nativeEnum(AnReconAutofillKey)

export const ApReconAutofillKeySchema = z.nativeEnum(ApReconAutofillKey)

export const ApReconPrimaryAutofillKeySchema = z.nativeEnum(ApReconPrimaryAutofillKey)

export const ApReconSecondaryAutofillKeySchema = z.nativeEnum(ApReconSecondaryAutofillKey)

export const AccountingMarginsGraphSchema = z.nativeEnum(AccountingMarginsGraph)

export const AccountingTabSchema = z.nativeEnum(AccountingTab)

export const ApiEntityTypeSchema = z.nativeEnum(ApiEntityType)

export const ApiExportTypeSchema = z.nativeEnum(ApiExportType)

export const ApiPartnerCodeSchema = z.nativeEnum(ApiPartnerCode)

export const ApiPartnerTmsTypeSchema = z.nativeEnum(ApiPartnerTmsType)

export const ApiPartnerTypeSchema = z.nativeEnum(ApiPartnerType)

export const AutofillExtractorKeySchema = z.nativeEnum(AutofillExtractorKey)

export const AutofillMethodSchema = z.nativeEnum(AutofillMethod)

export const BusinessPerformanceAccountOverviewGraphSchema = z.nativeEnum(
  BusinessPerformanceAccountOverviewGraph,
)

export const BusinessPerformanceBranchOverviewGraphSchema = z.nativeEnum(
  BusinessPerformanceBranchOverviewGraph,
)

export const BusinessPerformanceDepartmentOverviewGraphSchema = z.nativeEnum(
  BusinessPerformanceDepartmentOverviewGraph,
)

export const BusinessPerformanceExpensesGraphSchema = z.nativeEnum(BusinessPerformanceExpensesGraph)

export const BusinessPerformanceMarginGraphSchema = z.nativeEnum(BusinessPerformanceMarginGraph)

export const BusinessPerformanceRevenueGraphSchema = z.nativeEnum(BusinessPerformanceRevenueGraph)

export const BusinessPerformanceTabSchema = z.nativeEnum(BusinessPerformanceTab)

export const CwConnectorTypeSchema = z.nativeEnum(CwConnectorType)

export const CardGraphTypesSchema = z.nativeEnum(CardGraphTypes)

export const ChargeCodeUploadAsyncStatusSchema = z.nativeEnum(ChargeCodeUploadAsyncStatus)

export const CheckShipmentInfoAsyncStatusSchema = z.nativeEnum(CheckShipmentInfoAsyncStatus)

export const CwTargetModuleSchema = z.nativeEnum(CwTargetModule)

export const DashboardFilterFieldTypeSchema = z.nativeEnum(DashboardFilterFieldType)

export const DashboardFilterOperatorSchema = z.nativeEnum(DashboardFilterOperator)

export const DashboardRoleSchema = z.nativeEnum(DashboardRole)

export const DashboardTabSchema = z.nativeEnum(DashboardTab)

export const DashboardTabTypeSchema = z.nativeEnum(DashboardTabType)

export const DataTypeSchema = z.nativeEnum(DataType)

export const DrilldownCtaSchema = z.nativeEnum(DrilldownCta)

export const EDocsDataTargetTypeSchema = z.nativeEnum(EDocsDataTargetType)

export const ExploreDataTypeSchema = z.nativeEnum(ExploreDataType)

export const ExploreTabSchema = z.nativeEnum(ExploreTab)

export const FieldIssueTypeSchema = z.nativeEnum(FieldIssueType)

export const FieldTypeSchema = z.nativeEnum(FieldType)

export const FilePageStatusSchema = z.nativeEnum(FilePageStatus)

export const FilePageTypeSchema = z.nativeEnum(FilePageType)

export const FileStatusSchema = z.nativeEnum(FileStatus)

export const FilterVariantSchema = z.nativeEnum(FilterVariant)

export const FilteredContainersColumnSchema = z.nativeEnum(FilteredContainersColumn)

export const FilteredShipmentsColumnSchema = z.nativeEnum(FilteredShipmentsColumn)

export const GroupByDateTypeSchema = z.nativeEnum(GroupByDateType)

export const HardcodedTabEnumSchema = z.nativeEnum(HardcodedTabEnum)

export const InputFieldGroupTypeSchema = z.nativeEnum(InputFieldGroupType)

export const InputFieldTypeSchema = z.nativeEnum(InputFieldType)

export const InsightsFieldCategorySchema = z.nativeEnum(InsightsFieldCategory)

export const InsightsSearchableRecordModelSchema = z.nativeEnum(InsightsSearchableRecordModel)

export const InvoiceReconLineItemTypeSchema = z.nativeEnum(InvoiceReconLineItemType)

export const InvoiceVendorTypeSchema = z.nativeEnum(InvoiceVendorType)

export const JobEditTypeSchema = z.nativeEnum(JobEditType)

export const JobExternalStatusSchema = z.nativeEnum(JobExternalStatus)

export const JobFilterColumnSchema = z.nativeEnum(JobFilterColumn)

export const JobFilterOperationSchema = z.nativeEnum(JobFilterOperation)

export const JobMoveToDoneReasonSchema = z.nativeEnum(JobMoveToDoneReason)

export const JobRequestReprocessReasonSchema = z.nativeEnum(JobRequestReprocessReason)

export const JobStatusSchema = z.nativeEnum(JobStatus)

export const JobTemplateExportTypeSchema = z.nativeEnum(JobTemplateExportType)

export const JobTemplateReconTypeSchema = z.nativeEnum(JobTemplateReconType)

export const ManualProductSchema = z.nativeEnum(ManualProduct)

export const MatchingCriteriaTypeSchema = z.nativeEnum(MatchingCriteriaType)

export const MetadataReconResultKeySchema = z.nativeEnum(MetadataReconResultKey)

export const MilestoneSchema = z.nativeEnum(Milestone)

export const NoteSourceSchema = z.nativeEnum(NoteSource)

export const NoteTypeSchema = z.nativeEnum(NoteType)

export const NotificationTypeSchema = z.nativeEnum(NotificationType)

export const OperationsShipmentVolumeGraphSchema = z.nativeEnum(OperationsShipmentVolumeGraph)

export const OperationsTabSchema = z.nativeEnum(OperationsTab)

export const OrganizationOverviewCardSchema = z.nativeEnum(OrganizationOverviewCard)

export const OrganizationTypeSchema = z.nativeEnum(OrganizationType)

export const PageAsyncBatchTypeSchema = z.nativeEnum(PageAsyncBatchType)

export const PageAsyncTaskStatusSchema = z.nativeEnum(PageAsyncTaskStatus)

export const PaywallFeatureSchema = z.nativeEnum(PaywallFeature)

export const PaywallUserRequestResultOrderByColumnSchema = z.nativeEnum(
  PaywallUserRequestResultOrderByColumn,
)

export const PeriodTypeSchema = z.nativeEnum(PeriodType)

export const PivotValueFunctionSchema = z.nativeEnum(PivotValueFunction)

export const ReconAsyncStatusSchema = z.nativeEnum(ReconAsyncStatus)

export const ReconJobListColumnSchema = z.nativeEnum(ReconJobListColumn)

export const ReconJobListTabSchema = z.nativeEnum(ReconJobListTab)

export const ReconResultTypeSchema = z.nativeEnum(ReconResultType)

export const ReconciliationStatusSchema = z.nativeEnum(ReconciliationStatus)

export const ResolutionStatusSchema = z.nativeEnum(ResolutionStatus)

export const SalesOverviewGraphSchema = z.nativeEnum(SalesOverviewGraph)

export const SalesTabSchema = z.nativeEnum(SalesTab)

export const SearchableRecordModelSchema = z.nativeEnum(SearchableRecordModel)

export const ShipmentOpSchema = z.nativeEnum(ShipmentOp)

export const ShipmentOpsCommercialInvoiceAutofillKeySchema = z.nativeEnum(
  ShipmentOpsCommercialInvoiceAutofillKey,
)

export const ShipmentOpsConsolContainerAutofillKeySchema = z.nativeEnum(
  ShipmentOpsConsolContainerAutofillKey,
)

export const ShipmentOpsConsolDetailsAutofillKeySchema = z.nativeEnum(
  ShipmentOpsConsolDetailsAutofillKey,
)

export const ShipmentOpsConsolRoutingAutofillKeySchema = z.nativeEnum(
  ShipmentOpsConsolRoutingAutofillKey,
)

export const ShipmentOpsImportDecAutofillKeySchema = z.nativeEnum(ShipmentOpsImportDecAutofillKey)

export const ShipmentOpsShipmentAutofillKeySchema = z.nativeEnum(ShipmentOpsShipmentAutofillKey)

export const ShipmentOpsShipmentOrderLinesAutofillKeySchema = z.nativeEnum(
  ShipmentOpsShipmentOrderLinesAutofillKey,
)

export const ShipmentOpsShipmentOrderNumberAutofillKeySchema = z.nativeEnum(
  ShipmentOpsShipmentOrderNumberAutofillKey,
)

export const ShipmentOpsShipmentPackingLinesAutofillKeySchema = z.nativeEnum(
  ShipmentOpsShipmentPackingLinesAutofillKey,
)

export const ShipperFacingOrganizationResultOrderByColumnSchema = z.nativeEnum(
  ShipperFacingOrganizationResultOrderByColumn,
)

export const ShipperFacingUserResultOrderByColumnSchema = z.nativeEnum(
  ShipperFacingUserResultOrderByColumn,
)

export const ShipperVizContainerColumnSchema = z.nativeEnum(ShipperVizContainerColumn)

export const ShipperVizContainerOrderSchema = z.nativeEnum(ShipperVizContainerOrder)

export const ShipperVizShipmentColumnSchema = z.nativeEnum(ShipperVizShipmentColumn)

export const ShipperVizShipmentOrderSchema = z.nativeEnum(ShipperVizShipmentOrder)

export const SortDirectionSchema = z.nativeEnum(SortDirection)

export const SummaryUnitTypeSchema = z.nativeEnum(SummaryUnitType)

export const SyncSourceSchema = z.nativeEnum(SyncSource)

export const TableColumnTypeSchema = z.nativeEnum(TableColumnType)

export const TaskFilterColumnSchema = z.nativeEnum(TaskFilterColumn)

export const TaskFilterOperationSchema = z.nativeEnum(TaskFilterOperation)

export const TaskStatusSchema = z.nativeEnum(TaskStatus)

export const TaskTypeSchema = z.nativeEnum(TaskType)

export const TransactionAsyncStatusSchema = z.nativeEnum(TransactionAsyncStatus)

export const UnitTypeSchema = z.nativeEnum(UnitType)

export const UserRoleSchema = z.nativeEnum(UserRole)

export function BooleanFilterEqualsSchema(): z.ZodObject<Properties<BooleanFilterEquals>> {
  return z.object({
    value: z.boolean(),
  })
}

export function DateFilterBetweenSchema(): z.ZodObject<Properties<DateFilterBetween>> {
  return z.object({
    endDate: z.string(),
    startDate: z.string(),
    timezone: z.string(),
  })
}

export function DateFilterGreaterThanOrEqualsSchema(): z.ZodObject<
  Properties<DateFilterGreaterThanOrEquals>
> {
  return z.object({
    startDate: z.string(),
    timezone: z.string(),
  })
}

export function EditCompanyRequestSchema(): z.ZodObject<Properties<EditCompanyRequest>> {
  return z.object({
    enableEdocsTab: z.boolean(),
    hasAccessToInsights: z.boolean(),
    id: z.string(),
    imageS3Uri: z.string().nullish(),
    metabaseDashboardId: z.string().nullish(),
    name: z.string(),
    primaryThemeColor: z.string().nullish(),
    shipperFacingSlug: z.string().nullish(),
  })
}

export function EncodedFiltersSchema(): z.ZodObject<Properties<EncodedFilters>> {
  return z.object({
    accountMargin: z.string().nullish(),
    container: z.string().nullish(),
    payables: z.string().nullish(),
    receivables: z.string().nullish(),
    shipment: z.string().nullish(),
    shipmentMargin: z.string().nullish(),
    unitSettings: z.string(),
  })
}

export function GrapheneImageDimensionsSchema(): z.ZodObject<Properties<GrapheneImageDimensions>> {
  return z.object({
    height: z.number(),
    width: z.number(),
  })
}

export function GrapheneInputFileSchema(): z.ZodObject<Properties<GrapheneInputFile>> {
  return z.object({
    filename: z.string(),
    originalFileUri: z.string().nullish(),
    pages: z.array(z.lazy(() => GrapheneInputPageSchema().nullable())),
    type: FilePageTypeSchema,
  })
}

export function GrapheneInputPageSchema(): z.ZodObject<Properties<GrapheneInputPage>> {
  return z.object({
    documentTypeId: z.string().nullish(),
    pageNumber: z.number(),
    signedViewUrl: z.string(),
    size: GrapheneImageDimensionsSchema().nullish(),
  })
}

export function InputApiPartnerSchema(): z.ZodObject<Properties<InputApiPartner>> {
  return z.object({
    apiKey: z.string(),
    apiPartnerType: ApiPartnerTypeSchema,
    authValues: z.string().nullish(),
    companyId: z.string().nullish(),
    countryCode: z.string().nullish(),
    credentialId: z.string().nullish(),
    documentExportEndpoint: z.string().nullish(),
    documentImportEndpoint: z.string().nullish(),
    expedockDocIngestEmail: z.string().nullish(),
    exportEndpoint: z.string().nullish(),
    importEndpoint: z.string().nullish(),
    jobTemplateIds: z.array(z.string()).nullish(),
    name: z.string(),
    tmsType: ApiPartnerTmsTypeSchema.nullish(),
  })
}

export function InputBoxDimensionSchema(): z.ZodObject<Properties<InputBoxDimension>> {
  return z.object({
    height: z.number().nullish(),
    left: z.number().nullish(),
    top: z.number().nullish(),
    width: z.number().nullish(),
  })
}

export function InputCardSchema(): z.ZodObject<Properties<InputCard>> {
  return z.object({
    cardFilter: z.string().nullish(),
    contextMenuFilters: z.array(z.lazy(() => InputContextMenuFilterSchema())).nullish(),
    customExploreType: ExploreDataTypeSchema.nullish(),
    description: z.string().nullish(),
    dottedColumnName: z.string().nullish(),
    drilldownCta: DrilldownCtaSchema.nullish(),
    drilldownCtaDisplayName: z.string().nullish(),
    graphType: CardGraphTypesSchema,
    height: z.number(),
    name: z.string(),
    queryDescription: z.string().nullish(),
    rootQuery: z.string(),
    subtitle: z.string().nullish(),
    summaryUnitType: SummaryUnitTypeSchema.nullish(),
    width: z.number(),
    xAxis: z.string().nullish(),
    xAxis2: z.string().nullish(),
    xPosition: z.number(),
    yAxis: z.string().nullish(),
    yAxis2: z.string().nullish(),
    yAxisTarget: z.number().nullish(),
    yPosition: z.number(),
    zAxis: z.string().nullish(),
  })
}

export function InputCargowiseConfigSchema(): z.ZodObject<Properties<InputCargowiseConfig>> {
  return z.object({
    code: z.string(),
    companyId: z.string().nullish(),
    connectorType: z.string().nullish(),
    countryCode: z.string(),
    dbDirectConnect: z.boolean(),
    dbName: z.string().nullish(),
    dbPass: z.string().nullish(),
    dbUser: z.string().nullish(),
    defaultTimezoneLocationId: z.string(),
    enterpriseId: z.string(),
    externApiCredentialId: z.string().nullish(),
    forceFetch: z.boolean(),
    jobTemplateIds: z.array(z.string()).nullish(),
    outboundPass: z.string().nullish(),
    pollDatabase: z.boolean().nullish(),
    proxyUrls: z.array(z.string()).nullish(),
    remoteDbHostname: z.string().nullish(),
    remoteDbPort: z.number().nullish(),
    serverId: z.string(),
  })
}

export function InputCargowiseOutboundFilterSchema(): z.ZodObject<
  Properties<InputCargowiseOutboundFilter>
> {
  return z.object({
    cargowiseConfigId: z.string().nullish(),
    cargowiseOutboundId: z.string().nullish(),
    dateEnd: z.string().nullish(),
    dateStart: z.string().nullish(),
    integrationId: z.string().nullish(),
    isProcessed: z.boolean().nullish(),
  })
}

export function InputChargeCodeSchema(): z.ZodObject<Properties<InputChargeCode>> {
  return z.object({
    apiPartnerId: z.string().nullish(),
    code: z.string(),
    description: z.string(),
  })
}

export function InputChargeCodeCsvSchema(): z.ZodObject<Properties<InputChargeCodeCsv>> {
  return z.object({
    rows: z.array(z.lazy(() => InputChargeCodeCsvRowSchema().nullable())),
  })
}

export function InputChargeCodeCsvRowSchema(): z.ZodObject<Properties<InputChargeCodeCsvRow>> {
  return z.object({
    chargeCode: z.string(),
    chargeDescription: z.string(),
  })
}

export function InputColdStartFileSchema(): z.ZodObject<Properties<InputColdStartFile>> {
  return z.object({
    fileData: z.string(),
    fileName: z.string(),
  })
}

export function InputContextMenuFilterSchema(): z.ZodObject<Properties<InputContextMenuFilter>> {
  return z.object({
    columnName: z.string(),
    filterByDateSelector: z.boolean(),
    filterField: z.string().nullish(),
  })
}

export function InputCustomDatabaseSchema(): z.ZodObject<Properties<InputCustomDatabase>> {
  return z.object({
    customDatabaseTypeId: z.string(),
    name: z.string(),
    rows: z.array(z.lazy(() => InputCustomDatabaseRowSchema())),
  })
}

export function InputCustomDatabaseCellSchema(): z.ZodObject<Properties<InputCustomDatabaseCell>> {
  return z.object({
    customDatabaseColumnId: z.string(),
    value: z.string(),
  })
}

export function InputCustomDatabaseColumnSchema(): z.ZodObject<
  Properties<InputCustomDatabaseColumn>
> {
  return z.object({
    displayName: z.string(),
    exportKey: z.string(),
  })
}

export function InputCustomDatabaseRowSchema(): z.ZodObject<Properties<InputCustomDatabaseRow>> {
  return z.object({
    cells: z.array(z.lazy(() => InputCustomDatabaseCellSchema())),
  })
}

export function InputCustomDatabaseTypeSchema(): z.ZodObject<Properties<InputCustomDatabaseType>> {
  return z.object({
    columns: z.array(z.lazy(() => InputCustomDatabaseColumnSchema())),
    name: z.string(),
    tmsType: ApiPartnerTmsTypeSchema,
  })
}

export function InputDocumentSchema(): z.ZodObject<Properties<InputDocument>> {
  return z.object({
    documentTables: z.array(z.lazy(() => InputDocumentTableSchema())),
    fieldCoordinates: definedNonNullAnySchema,
    fieldMapping: definedNonNullAnySchema,
    id: z.string(),
  })
}

export function InputDocumentTableSchema(): z.ZodObject<Properties<InputDocumentTable>> {
  return z.object({
    editedByUser: z.boolean().nullish(),
    fieldCoordinates: definedNonNullAnySchema.nullish(),
    fieldGroupId: z.string(),
    fieldGroupKey: z.string(),
    id: z.string(),
    lineItems: z.array(z.lazy(() => InputLineItemSchema())),
    tableColumns: z.array(z.lazy(() => InputTableColumnSchema())),
  })
}

export function InputDocumentTableSkeletonSchema(): z.ZodObject<
  Properties<InputDocumentTableSkeleton>
> {
  return z.object({
    fieldGroupId: z.string(),
    fieldGroupKey: z.string(),
    id: z.string(),
    rows: z.array(z.lazy(() => InputRowSkeletonSchema().nullable())),
    tableColumns: z.array(z.lazy(() => InputTableColumnSchema().nullable())),
  })
}

export function InputDocumentTypeSchema(): z.ZodObject<Properties<InputDocumentType>> {
  return z.object({
    autofillExtractorKey: AutofillExtractorKeySchema,
    cargowiseFileTypeId: z.string().nullish(),
    collapsible: z.boolean(),
    derivedFromId: z.string().nullish(),
    id: z.string(),
    isEDocPublishedByDefault: z.boolean().nullish(),
    isStandard: z.boolean(),
    name: z.string(),
    tableShowsPreset: z.boolean(),
  })
}

export function InputEAdaptorColdStartParamsSchema(): z.ZodObject<
  Properties<InputEAdaptorColdStartParams>
> {
  return z.object({
    apInvoiceReports: z.array(z.lazy(() => InputColdStartFileSchema())).nullish(),
    arInvoiceReports: z.array(z.lazy(() => InputColdStartFileSchema())).nullish(),
    cdecProfileReports: z.array(z.lazy(() => InputColdStartFileSchema())).nullish(),
    jobManagementReports: z.array(z.lazy(() => InputColdStartFileSchema())).nullish(),
    shipmentProfileReports: z.array(z.lazy(() => InputColdStartFileSchema())).nullish(),
    transportProfileReports: z.array(z.lazy(() => InputColdStartFileSchema())).nullish(),
  })
}

export function InputEDocumentSchema(): z.ZodObject<Properties<InputEDocument>> {
  return z.object({
    filePageIds: z.array(z.string()),
    isPublished: z.boolean(),
  })
}

export function InputFieldSchema(): z.ZodObject<Properties<InputField>> {
  return z.object({
    autofillKey: z.string().nullish(),
    dateFormatString: z.string().nullish(),
    exportKey: z.string().nullish(),
    id: z.string(),
    invalidCharsRegex: z.string().nullish(),
    name: z.string(),
    required: z.boolean(),
    searchableRecord: z.lazy(() => InputSearchableRecordSchema().nullish()),
    type: z.lazy(() => InputFieldTypeSchema.nullish()),
    validatorDescription: z.string().nullish(),
    validatorRegex: z.string().nullish(),
    valueExportMapping: z.string().nullish(),
    values: z.string().nullish(),
  })
}

export function InputFieldGroupSchema(): z.ZodObject<Properties<InputFieldGroup>> {
  return z.object({
    autofillKey: z.string().nullish(),
    documentTypeId: z.string(),
    exportKey: z.string().nullish(),
    fields: z.array(z.lazy(() => InputFieldSchema())),
    id: z.string(),
    name: z.string(),
    type: z.lazy(() => InputFieldGroupTypeSchema.nullish()),
  })
}

export function InputHardcodedCardReferenceSchema(): z.ZodObject<
  Properties<InputHardcodedCardReference>
> {
  return z.object({
    dashboardReference: z.lazy(() => InputHardcodedDashboardReferenceSchema()),
    graphKey: z.string(),
    isHardcoded: z.boolean(),
  })
}

export function InputHardcodedDashboardReferenceSchema(): z.ZodObject<
  Properties<InputHardcodedDashboardReference>
> {
  return z.object({
    isHardcoded: z.boolean(),
    tab: HardcodedTabEnumSchema,
    tabType: DashboardTabTypeSchema,
  })
}

export function InputJobFilterSchema(): z.ZodObject<Properties<InputJobFilter>> {
  return z.object({
    column: JobFilterColumnSchema,
    id: z.string(),
    operation: JobFilterOperationSchema,
    value: z.array(z.string()),
  })
}

export function InputJobTableSchema(): z.ZodObject<Properties<InputJobTable>> {
  return z.object({
    columns: z.array(z.string()),
    fieldGroupId: z.string(),
    rows: z.array(z.lazy(() => InputJobTableLineItemSchema())),
  })
}

export function InputJobTableCellSchema(): z.ZodObject<Properties<InputJobTableCell>> {
  return z.object({
    documentId: z.string(),
    fieldId: z.string(),
    height: z.number().nullish(),
    left: z.number().nullish(),
    top: z.number().nullish(),
    value: z.string(),
    width: z.number().nullish(),
  })
}

export function InputJobTableLineItemSchema(): z.ZodObject<Properties<InputJobTableLineItem>> {
  return z.object({
    cells: z.array(z.lazy(() => InputJobTableCellSchema())),
  })
}

export function InputJobTemplateExportColumnSchema(): z.ZodObject<
  Properties<InputJobTemplateExportColumn>
> {
  return z.object({
    columnName: z.string().nullish(),
    fieldId: z.string().nullish(),
    fixedValue: z.string().nullish(),
    order: z.number(),
  })
}

export function InputJobTemplateExportSheetSchema(): z.ZodObject<
  Properties<InputJobTemplateExportSheet>
> {
  return z.object({
    columns: z.array(z.lazy(() => InputJobTemplateExportColumnSchema())),
    displayColumnHeader: z.boolean(),
    fieldGroupId: z.string().nullish(),
    order: z.number(),
  })
}

export function InputLineItemSchema(): z.ZodObject<Properties<InputLineItem>> {
  return z.object({
    box: z.lazy(() => InputBoxDimensionSchema().nullish()),
    documentTableId: z.string(),
    fieldMapping: definedNonNullAnySchema.nullish(),
    id: z.string(),
    rowOrderPriority: z.number().nullish(),
  })
}

export function InputLineItemSkeletonSchema(): z.ZodObject<Properties<InputLineItemSkeleton>> {
  return z.object({
    dimension: z.lazy(() => InputBoxDimensionSchema().nullish()),
    key: z.string(),
  })
}

export function InputPivotRowColumnSchema(): z.ZodObject<Properties<InputPivotRowColumn>> {
  return z.object({
    displayName: z.string().nullish(),
    dottedFieldName: z.string().nullish(),
    fieldName: z.string(),
    isRow: z.boolean(),
    periodType: PeriodTypeSchema.nullish(),
    showTotals: z.boolean(),
  })
}

export function InputPivotTableSchema(): z.ZodObject<Properties<InputPivotTable>> {
  return z.object({
    rowsColumns: z.array(z.lazy(() => InputPivotRowColumnSchema())),
    values: z.array(z.lazy(() => InputPivotValueSchema())),
  })
}

export function InputPivotValueSchema(): z.ZodObject<Properties<InputPivotValue>> {
  return z.object({
    displayName: z.string().nullish(),
    fieldName: z.string(),
    valueFunction: PivotValueFunctionSchema,
  })
}

export function InputReconInfoObjectSchema(): z.ZodObject<Properties<InputReconInfoObject>> {
  return z.object({
    externalAssigneeId: z.string().nullish(),
    externalStatus: JobExternalStatusSchema.nullish(),
    id: z.string(),
  })
}

export function InputReconPremiumRateSchema(): z.ZodObject<Properties<InputReconPremiumRate>> {
  return z.object({
    accessorialFee: z.number(),
    invoiceTotal: z.number(),
    premiumRate: z.number(),
    quantity: z.number(),
  })
}

export function InputReconThresholdRangeSchema(): z.ZodObject<
  Properties<InputReconThresholdRange>
> {
  return z.object({
    maximum: z.string(),
    minimum: z.string(),
    thresholdAmount: z.string(),
    useAbsoluteValueMatching: z.boolean(),
    usePercent: z.boolean(),
  })
}

export function InputRowSkeletonSchema(): z.ZodObject<Properties<InputRowSkeleton>> {
  return z.object({
    lineItems: z.array(z.lazy(() => InputLineItemSkeletonSchema().nullable())),
  })
}

export function InputSoaLineItemSchema(): z.ZodObject<Properties<InputSoaLineItem>> {
  return z.object({
    cargowiseModule: z.string().nullish(),
    carrierBookingNumber: z.string().nullish(),
    chargeCode: z.string().nullish(),
    chargeCost: z.string().nullish(),
    consolNumber: z.string().nullish(),
    containerNumber: z.string().nullish(),
    currency: z.string().nullish(),
    documentReceivedDate: z.string().nullish(),
    dueDate: z.string().nullish(),
    hblNumber: z.string().nullish(),
    invoiceDate: z.string().nullish(),
    invoiceForwarderReference: z.string().nullish(),
    invoiceNumber: z.string().nullish(),
    invoiceTmsId: z.string().nullish(),
    mblNumber: z.string().nullish(),
    orderNumber: z.string().nullish(),
    quantity: z.string().nullish(),
    referenceNumber: z.string().nullish(),
  })
}

export function InputSoaRowMatchCriteriaSchema(): z.ZodObject<
  Properties<InputSoaRowMatchCriteria>
> {
  return z.object({
    carrierBookingNo: z.string().nullish(),
    consolNo: z.string().nullish(),
    containerNo: z.string().nullish(),
    hblNo: z.string().nullish(),
    invoiceNo: z.string().nullish(),
    mblNo: z.string().nullish(),
    orderNo: z.string().nullish(),
    refNo: z.string().nullish(),
    rowIdx: z.number(),
  })
}

export function InputSearchableRecordSchema(): z.ZodObject<Properties<InputSearchableRecord>> {
  return z.object({
    apiUrl: z.string().nullish(),
    displayKey: z.string(),
    model: z.string().nullish(),
    searchKey: z.string(),
  })
}

export function InputSearchableRecordColumnSchema(): z.ZodObject<
  Properties<InputSearchableRecordColumn>
> {
  return z.object({
    filters: z.lazy(() => InputSearchableRecordFiltersSchema().nullish()),
    searchableRecordId: z.string(),
    values: z.array(z.string().nullable()),
  })
}

export function InputSearchableRecordFiltersSchema(): z.ZodObject<
  Properties<InputSearchableRecordFilters>
> {
  return z.object({
    apiPartnerId: z.string().nullish(),
    companyId: z.string().nullish(),
    vendorName: z.string().nullish(),
  })
}

export function InputSoaCwModulesSchema(): z.ZodObject<Properties<InputSoaCwModules>> {
  return z.object({
    cwModule: z.string().nullish(),
    invoiceNum: z.string().nullish(),
  })
}

export function InputSoaDatesSchema(): z.ZodObject<Properties<InputSoaDates>> {
  return z.object({
    cwModule: z.string().nullish(),
    documentReceivedDate: z.string().nullish(),
    dueDate: z.string().nullish(),
    invoiceDate: z.string().nullish(),
    invoiceNum: z.string().nullish(),
  })
}

export function InputTableColumnSchema(): z.ZodObject<Properties<InputTableColumn>> {
  return z.object({
    dimension: z.lazy(() => InputBoxDimensionSchema().nullish()),
    id: z.string(),
    key: z.string().nullish(),
  })
}

export function InputTaskFilterSchema(): z.ZodObject<Properties<InputTaskFilter>> {
  return z.object({
    column: TaskFilterColumnSchema,
    id: z.string(),
    operation: TaskFilterOperationSchema,
    value: z.array(z.string()),
  })
}

export function InputTaxDetailsSchema(): z.ZodObject<Properties<InputTaxDetails>> {
  return z.object({
    apiPartnerId: z.string().nullish(),
    chargeCodes: z.array(z.string()),
    companyId: z.string(),
    taxCode: z.string(),
    taxRate: z.number(),
    taxSystem: z.string().nullish(),
    taxType: z.string().nullish(),
  })
}

export function InputUserSchema(): z.ZodObject<Properties<InputUser>> {
  return z.object({
    branch: z.string().nullish(),
    branches: z.array(z.string()),
    canExportDataAsCsv: z.boolean(),
    companyId: z.string().nullish(),
    defaultDashboardsWhitelist: z.array(z.string()),
    department: z.string().nullish(),
    departments: z.array(z.string()),
    email: z.string(),
    emailVerified: z.boolean(),
    inviterCompanyName: z.string().nullish(),
    metabaseDashboardId: z.string().nullish(),
    name: z.string().nullish(),
    onlyViewAssignedShipments: z.boolean(),
    onlyViewDashboardCompany: z.boolean(),
    operationsRep: z.string().nullish(),
    operationsReps: z.array(z.string()),
    operatorCompanyIds: z.array(z.string()),
    partnerCompanyIds: z.array(z.string()),
    roles: z.array(UserRoleSchema),
    salesRep: z.string().nullish(),
    salesReps: z.array(z.string()),
  })
}

export function InputVendorAndDescSchema(): z.ZodObject<Properties<InputVendorAndDesc>> {
  return z.object({
    description: z.string(),
    vendorName: z.string(),
  })
}

export function InputVendorCsvSchema(): z.ZodObject<Properties<InputVendorCsv>> {
  return z.object({
    rows: z.array(z.lazy(() => InputVendorCsvRowSchema().nullable())),
  })
}

export function InputVendorCsvRowSchema(): z.ZodObject<Properties<InputVendorCsvRow>> {
  return z.object({
    vendorCode: z.string(),
    vendorName: z.string(),
  })
}

export function MeasurementUnitInputSchema(): z.ZodObject<Properties<MeasurementUnitInput>> {
  return z.object({
    targetUnit: z.string().nullish(),
    type: UnitTypeSchema,
  })
}

export function NumericFilterBetweenSchema(): z.ZodObject<Properties<NumericFilterBetween>> {
  return z.object({
    lowerBound: z.string(),
    upperBound: z.string(),
  })
}

export function NumericFilterEmptySchema(): z.ZodObject<Properties<NumericFilterEmpty>> {
  return z.object({
    Void: definedNonNullAnySchema.nullish(),
  })
}

export function NumericFilterEqualsSchema(): z.ZodObject<Properties<NumericFilterEquals>> {
  return z.object({
    value: z.string(),
  })
}

export function NumericFilterGreaterThanSchema(): z.ZodObject<
  Properties<NumericFilterGreaterThan>
> {
  return z.object({
    value: z.string(),
  })
}

export function NumericFilterGreaterThanOrEqualsSchema(): z.ZodObject<
  Properties<NumericFilterGreaterThanOrEquals>
> {
  return z.object({
    value: z.string(),
  })
}

export function NumericFilterLessThanSchema(): z.ZodObject<Properties<NumericFilterLessThan>> {
  return z.object({
    value: z.string(),
  })
}

export function NumericFilterLessThanOrEqualsSchema(): z.ZodObject<
  Properties<NumericFilterLessThanOrEquals>
> {
  return z.object({
    value: z.string(),
  })
}

export function NumericFilterNotEmptySchema(): z.ZodObject<Properties<NumericFilterNotEmpty>> {
  return z.object({
    Void: definedNonNullAnySchema.nullish(),
  })
}

export function NumericFilterNotEqualsSchema(): z.ZodObject<Properties<NumericFilterNotEquals>> {
  return z.object({
    value: z.string(),
  })
}

export function OriginalInputPdfSchema(): z.ZodObject<Properties<OriginalInputPdf>> {
  return z.object({
    fileBytes: z.string(),
    filename: z.string(),
  })
}

export function RawFilterQuerySchema(): z.ZodObject<Properties<RawFilterQuery>> {
  return z.object({
    children1: z.array(RawFilterQuerySchema()).nullish(),
    id: z.string().nullish(),
    path: z.array(z.string()).nullish(),
    properties: RawFilterQueryPropertySchema().nullish(),
    type: z.string(),
  })
}

export function RawFilterQueryPropertySchema(): z.ZodObject<Properties<RawFilterQueryProperty>> {
  return z.object({
    conjunction: z.string().nullish(),
    field: z.string().nullish(),
    fieldSrc: z.string().nullish(),
    isLocked: z.boolean().nullish(),
    notFlag: z.boolean().nullish(),
    operator: z.string().nullish(),
    operatorOptions: z.string().nullish(),
    value: z.array(RawFilterValueSchema().nullable()).nullish(),
    valueSrc: z.array(z.string().nullable()).nullish(),
    valueType: z.array(z.string()).nullish(),
  })
}

export function RawFilterValueSchema(): z.ZodObject<Properties<RawFilterValue>> {
  return z.object({
    unit: z.string().nullish(),
    value: z.string().nullish(),
  })
}

export function ReconJobListColumnColumnConditionInputSchema(): z.ZodObject<
  Properties<ReconJobListColumnColumnConditionInput>
> {
  return z.object({
    booleanFilterEquals: BooleanFilterEqualsSchema().nullish(),
    column: ReconJobListColumnSchema,
    dateFilterBetween: DateFilterBetweenSchema().nullish(),
    dateFilterGreaterThanOrEquals: DateFilterGreaterThanOrEqualsSchema().nullish(),
    numericFilterBetween: NumericFilterBetweenSchema().nullish(),
    numericFilterEmpty: NumericFilterEmptySchema().nullish(),
    numericFilterEquals: NumericFilterEqualsSchema().nullish(),
    numericFilterGreaterThan: NumericFilterGreaterThanSchema().nullish(),
    numericFilterGreaterThanOrEquals: NumericFilterGreaterThanOrEqualsSchema().nullish(),
    numericFilterLessThan: NumericFilterLessThanSchema().nullish(),
    numericFilterLessThanOrEquals: NumericFilterLessThanOrEqualsSchema().nullish(),
    numericFilterNotEmpty: NumericFilterNotEmptySchema().nullish(),
    numericFilterNotEquals: NumericFilterNotEqualsSchema().nullish(),
    textFilterIn: TextFilterInSchema().nullish(),
    textFilterMatch: TextFilterMatchSchema().nullish(),
    variant: FilterVariantSchema,
  })
}

export function TextFilterInSchema(): z.ZodObject<Properties<TextFilterIn>> {
  return z.object({
    exact: z.boolean(),
    values: z.array(z.string()),
  })
}

export function TextFilterMatchSchema(): z.ZodObject<Properties<TextFilterMatch>> {
  return z.object({
    flag: z.string().nullish(),
    pattern: z.string(),
  })
}

export function UnitSettingSchema(): z.ZodObject<Properties<UnitSetting>> {
  return z.object({
    currency: z.string(),
    groupByDate: GroupByDateTypeSchema,
    orgType: z.lazy(() => MeasurementUnitInputSchema().nullish()),
    periodType: PeriodTypeSchema,
    shipmentVolumeUnit: z.lazy(() => MeasurementUnitInputSchema().nullish()),
    volumeUnit: z.lazy(() => MeasurementUnitInputSchema().nullish()),
    weightUnit: z.lazy(() => MeasurementUnitInputSchema()),
  })
}

export function VariableMappingSchema(): z.ZodObject<Properties<VariableMapping>> {
  return z.object({
    key: z.string(),
    value: z.string(),
  })
}

export type DeleteApiPartnerMutationVariables = Exact<{
  apiPartnerId: Scalars['UUID']['input']
}>

export type DeleteApiPartnerMutation = {
  __typename?: 'Mutation'
  deleteApiPartner: { __typename?: 'DeleteApiPartner'; ok: boolean }
}

export type CreateApiPartnerMutationVariables = Exact<{
  inputApiPartner: InputApiPartner
}>

export type CreateApiPartnerMutation = {
  __typename?: 'Mutation'
  createApiPartner: { __typename?: 'CreateApiPartner'; ok: boolean }
}

export type UpdateApiPartnerMutationVariables = Exact<{
  id: Scalars['UUID']['input']
  inputApiPartner: InputApiPartner
}>

export type UpdateApiPartnerMutation = {
  __typename?: 'Mutation'
  updateApiPartner: {
    __typename?: 'UpdateApiPartner'
    apiPartner: {
      __typename?: 'ApiPartnerNode'
      id: string
      apiKey: string
      exportEndpoint?: string | null
      importEndpoint?: string | null
      documentExportEndpoint?: string | null
      documentImportEndpoint?: string | null
      name: string
      expedockDocIngestEmail?: string | null
      apiPartnerType: ApiPartnerType
      tmsType?:
        | { __typename?: 'ApiPartnerTmsTypeEnum'; value: ApiPartnerTmsType; isFallback: boolean }
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | null
      credential?: {
        __typename?: 'CredentialNode'
        id: string
        username: string
        workflow: string
      } | null
      company?: { __typename?: 'CompanyNode'; id: string; name?: string | null } | null
      jobTemplates: {
        __typename?: 'JobTemplateNodeConnection'
        edges: Array<{
          __typename?: 'JobTemplateNodeEdge'
          node: {
            __typename?: 'JobTemplateNode'
            id: string
            name: string
            company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
          }
        }>
      }
    }
  }
}

export type TestApiPartnerMutationVariables = Exact<{
  apiPartnerId: Scalars['UUID']['input']
}>

export type TestApiPartnerMutation = {
  __typename?: 'Mutation'
  testApiPartner: { __typename?: 'TestApiPartner'; ok: boolean }
}

export type ImportEDocumentsFromApiPartnerMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  dataTargetType: EDocsDataTargetType
}>

export type ImportEDocumentsFromApiPartnerMutation = {
  __typename?: 'Mutation'
  importEDocumentsFromApiPartner: { __typename?: 'ImportEDocumentsFromApiPartner'; ok: boolean }
}

export type DeleteApiEDocMutationVariables = Exact<{
  apiEDocumentId: Scalars['UUID']['input']
}>

export type DeleteApiEDocMutation = {
  __typename?: 'Mutation'
  deleteApiEDoc: { __typename?: 'DeleteApiEDoc'; ok: boolean }
}

export type ExportJobToCwMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  lumpsum?: InputMaybe<InputDocumentTable>
  overrideChargeDescription: Scalars['Boolean']['input']
  disableSendDueDate: Scalars['Boolean']['input']
  matchingCriteria: MatchingCriteriaType
}>

export type ExportJobToCwMutation = {
  __typename?: 'Mutation'
  exportJobToCw: { __typename?: 'ExportJobToCW'; ok: boolean }
}

export type BatchExportJobToCwMutationVariables = Exact<{
  jobId: Scalars['String']['input']
  invoiceNumbers: Array<Scalars['String']['input']> | Scalars['String']['input']
  invoiceDates: Array<InputSoaDates> | InputSoaDates
  invoiceCwModuleKeys: Array<InputSoaCwModules> | InputSoaCwModules
  overrideChargeDescription: Scalars['Boolean']['input']
  disableSendDueDate: Scalars['Boolean']['input']
  asLumpsum: Scalars['Boolean']['input']
}>

export type BatchExportJobToCwMutation = {
  __typename?: 'Mutation'
  batchExportJobToCw: { __typename?: 'BatchExportJobToCW'; transactionAsyncBatchId: string }
}

export type PostToCwMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  lumpsum?: InputMaybe<InputDocumentTable>
  overrideChargeDescription: Scalars['Boolean']['input']
  disableSendDueDate: Scalars['Boolean']['input']
  matchingCriteria: MatchingCriteriaType
}>

export type PostToCwMutation = {
  __typename?: 'Mutation'
  postToCw: { __typename?: 'PostToCW'; ok: boolean }
}

export type PushEDocsToCwMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  inputEDocuments: Array<InputEDocument> | InputEDocument
  dataTargetType: EDocsDataTargetType
  overrideReconAttemptId?: InputMaybe<Scalars['UUID']['input']>
}>

export type PushEDocsToCwMutation = {
  __typename?: 'Mutation'
  pushEDocsToCw: { __typename?: 'PushEDocsToCW'; ok: boolean }
}

export type BatchPushEdocsToCwMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  referenceNumbers: Array<Scalars['String']['input']> | Scalars['String']['input']
  inputEDocuments: Array<InputEDocument> | InputEDocument
  dataTargetType: EDocsDataTargetType
}>

export type BatchPushEdocsToCwMutation = {
  __typename?: 'Mutation'
  batchPushEDocsToCw: { __typename?: 'BatchPushEDocsToCW'; batchPushId: string }
}

export type PullEDocsFromCwMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  dataTargetType: EDocsDataTargetType
}>

export type PullEDocsFromCwMutation = {
  __typename?: 'Mutation'
  pullEDocsFromCw: { __typename?: 'PullEDocsFromCW'; ok: boolean }
}

export type BatchPullEDocsFromCwMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  dataTargetType: EDocsDataTargetType
  chainIoShipmentIds?: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>
  invoiceNumbers?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  chainIoConsolIds?: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>
  chainIoCustomsDecIds?: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>
}>

export type BatchPullEDocsFromCwMutation = {
  __typename?: 'Mutation'
  batchPullEDocsFromCw: { __typename?: 'BatchPullEDocsFromCW'; batchPullId: string }
}

export type DeleteEDocMutationVariables = Exact<{
  eDocumentId: Scalars['UUID']['input']
}>

export type DeleteEDocMutation = {
  __typename?: 'Mutation'
  deleteEDoc: { __typename?: 'DeleteEDoc'; ok: boolean }
}

export type BulkDeleteEdocsMutationVariables = Exact<{
  eDocumentIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
}>

export type BulkDeleteEdocsMutation = {
  __typename?: 'Mutation'
  bulkDeleteEDocs: { __typename?: 'BulkDeleteEDocs'; ok: boolean }
}

export type DeleteCargowiseConfigMutationVariables = Exact<{
  cwConfigId: Scalars['UUID']['input']
}>

export type DeleteCargowiseConfigMutation = {
  __typename?: 'Mutation'
  deleteCargowiseConfig: { __typename?: 'DeleteCargowiseConfig'; ok: boolean }
}

export type CreateCargowiseConfigMutationVariables = Exact<{
  inputCwConfig: InputCargowiseConfig
}>

export type CreateCargowiseConfigMutation = {
  __typename?: 'Mutation'
  createCargowiseConfig: { __typename?: 'CreateCargowiseConfig'; ok: boolean }
}

export type UpdateCargowiseConfigMutationVariables = Exact<{
  id: Scalars['UUID']['input']
  inputCwConfig: InputCargowiseConfig
}>

export type UpdateCargowiseConfigMutation = {
  __typename?: 'Mutation'
  updateCargowiseConfig: {
    __typename?: 'UpdateCargowiseConfig'
    cwConfig: {
      __typename?: 'CargowiseConfigNode'
      id: string
      code: string
      countryCode?: string | null
      enterpriseId: string
      serverId: string
      outboundPass?: string | null
      dbPass?: string | null
      dbUser?: string | null
      dbName?: string | null
      remoteDbHostname?: string | null
      remoteDbPort?: number | null
      pollDatabase: boolean
      proxyUrls?: Array<string> | null
      connectorType?: CwConnectorType | null
      forceFetch: boolean
      dbDirectConnect: boolean
      externApiCredential?: {
        __typename?: 'CredentialNode'
        id: string
        username: string
        workflow: string
      } | null
      company?: { __typename?: 'CompanyNode'; id: string; name?: string | null } | null
      jobTemplates: {
        __typename?: 'JobTemplateNodeConnection'
        edges: Array<{
          __typename?: 'JobTemplateNodeEdge'
          node: {
            __typename?: 'JobTemplateNode'
            id: string
            name: string
            company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
          }
        }>
      }
    }
  }
}

export type TestCargowiseConfigMutationVariables = Exact<{
  cwConfigId: Scalars['UUID']['input']
}>

export type TestCargowiseConfigMutation = {
  __typename?: 'Mutation'
  testCargowiseConfig: { __typename?: 'TestCargowiseConfig'; ok: boolean }
}

export type SendShipmentOpsToCwMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
}>

export type SendShipmentOpsToCwMutation = {
  __typename?: 'Mutation'
  sendShipmentOpsToCw: {
    __typename?: 'SendShipmentOpsToCW'
    shipmentReferenceNumber: string
    consolReferenceNumber: string
    importDecReferenceNumber: string
    processingLog: string
  }
}

export type SyncCargowiseDbMutationVariables = Exact<{ [key: string]: never }>

export type SyncCargowiseDbMutation = { __typename?: 'Mutation'; syncCargowiseDb: boolean }

export type SendBFileToCwMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
}>

export type SendBFileToCwMutation = {
  __typename?: 'Mutation'
  sendBFileToCw: {
    __typename?: 'SendBFileToCW'
    cdecReferenceNumber: string
    consolReferenceNumber: string
    shipmentReferenceNumber: string
  }
}

export type RecreateReindexMirrorMutationVariables = Exact<{
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>
}>

export type RecreateReindexMirrorMutation = {
  __typename?: 'Mutation'
  recreateReindexMirror: { __typename?: 'RecreateReindexMirror'; reindexMirrorAsyncTaskId: string }
}

export type IndexQueueIntoSnowflakeMutationVariables = Exact<{
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>
}>

export type IndexQueueIntoSnowflakeMutation = {
  __typename?: 'Mutation'
  indexQueueIntoSnowflake: boolean
}

export type SyncCauldronDbMutationVariables = Exact<{
  apiPartnerIds?: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>
}>

export type SyncCauldronDbMutation = { __typename?: 'Mutation'; syncCauldronDb: boolean }

export type SyncSearchableRecordsMutationVariables = Exact<{
  companyIds?: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>
}>

export type SyncSearchableRecordsMutation = {
  __typename?: 'Mutation'
  syncSearchableRecords: boolean
}

export type EadaptorColdStartMutationVariables = Exact<{
  apiPartnerName: Scalars['String']['input']
  eadaptorColdStartParams: InputEAdaptorColdStartParams
}>

export type EadaptorColdStartMutation = { __typename?: 'Mutation'; eadaptorColdStart: boolean }

export type EditCompanyMutationVariables = Exact<{
  editCompanyRequest: EditCompanyRequest
}>

export type EditCompanyMutation = {
  __typename?: 'Mutation'
  editCompany: { __typename?: 'EditCompany'; id?: string | null }
}

export type DeleteCompanyMutationVariables = Exact<{
  companyId: Scalars['String']['input']
}>

export type DeleteCompanyMutation = {
  __typename?: 'Mutation'
  deleteCompany: { __typename?: 'DeleteCompany'; ok: boolean }
}

export type CreateCompanyMutationVariables = Exact<{
  name: Scalars['String']['input']
}>

export type CreateCompanyMutation = {
  __typename?: 'Mutation'
  createCompany: {
    __typename?: 'CreateCompany'
    company: { __typename?: 'CompanyNode'; id: string }
  }
}

export type DeleteCredentialMutationVariables = Exact<{
  credentialId: Scalars['UUID']['input']
}>

export type DeleteCredentialMutation = { __typename?: 'Mutation'; deleteCredential: boolean }

export type CreateCredentialMutationVariables = Exact<{
  workflow: Scalars['String']['input']
  username: Scalars['String']['input']
  password: Scalars['String']['input']
  apiEndpoint?: InputMaybe<Scalars['String']['input']>
  jobTemplateIds?: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>
  verifySsl: Scalars['Boolean']['input']
  hostOverride?: InputMaybe<Scalars['String']['input']>
}>

export type CreateCredentialMutation = { __typename?: 'Mutation'; createCredential: boolean }

export type UpdateCredentialMutationVariables = Exact<{
  id: Scalars['UUID']['input']
  workflow: Scalars['String']['input']
  username: Scalars['String']['input']
  password?: InputMaybe<Scalars['String']['input']>
  apiEndpoint?: InputMaybe<Scalars['String']['input']>
  jobTemplateIds?: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>
  verifySsl: Scalars['Boolean']['input']
  hostOverride?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateCredentialMutation = {
  __typename?: 'Mutation'
  updateCredential: {
    __typename?: 'CredentialNode'
    id: string
    username: string
    apiEndpoint?: string | null
    workflow: string
    verifySsl: boolean
    jobTemplates: {
      __typename?: 'JobTemplateNodeConnection'
      edges: Array<{
        __typename?: 'JobTemplateNodeEdge'
        node: { __typename?: 'JobTemplateNode'; id: string; name: string }
      }>
    }
  }
}

export type TestCredentialMutationVariables = Exact<{
  credentialId: Scalars['UUID']['input']
}>

export type TestCredentialMutation = { __typename?: 'Mutation'; testCredential: boolean }

export type CreateCustomDatabaseTypeMutationVariables = Exact<{
  inputCustomDatabaseType: InputCustomDatabaseType
}>

export type CreateCustomDatabaseTypeMutation = {
  __typename?: 'Mutation'
  createCustomDatabaseType: { __typename?: 'CreateCustomDatabaseType'; ok: boolean }
}

export type EditCustomDatabaseTypeMutationVariables = Exact<{
  customDatabaseTypeId: Scalars['UUID']['input']
  inputCustomDatabaseType: InputCustomDatabaseType
}>

export type EditCustomDatabaseTypeMutation = {
  __typename?: 'Mutation'
  editCustomDatabaseType: { __typename?: 'EditCustomDatabaseType'; id: string }
}

export type DeleteCustomDatabaseTypeMutationVariables = Exact<{
  customDatabaseTypeId: Scalars['UUID']['input']
}>

export type DeleteCustomDatabaseTypeMutation = {
  __typename?: 'Mutation'
  deleteCustomDatabaseType: { __typename?: 'DeleteCustomDatabaseType'; ok: boolean }
}

export type CreateCustomDatabaseMutationVariables = Exact<{
  inputCustomDatabase: InputCustomDatabase
  apiPartnerId: Scalars['UUID']['input']
}>

export type CreateCustomDatabaseMutation = {
  __typename?: 'Mutation'
  createCustomDatabase: { __typename?: 'CreateCustomDatabase'; ok: boolean }
}

export type EditCustomDatabaseMutationVariables = Exact<{
  customDatabaseId: Scalars['UUID']['input']
  inputCustomDatabase: InputCustomDatabase
}>

export type EditCustomDatabaseMutation = {
  __typename?: 'Mutation'
  editCustomDatabase: { __typename?: 'EditCustomDatabase'; id: string }
}

export type DeleteCustomDatabaseMutationVariables = Exact<{
  customDatabaseId: Scalars['UUID']['input']
}>

export type DeleteCustomDatabaseMutation = {
  __typename?: 'Mutation'
  deleteCustomDatabase: { __typename?: 'DeleteCustomDatabase'; ok: boolean }
}

export type CreateDocumentMutationVariables = Exact<{
  filePageId: Scalars['UUID']['input']
  documentTypeId: Scalars['UUID']['input']
  jobId: Scalars['UUID']['input']
  batchId: Scalars['UUID']['input']
}>

export type CreateDocumentMutation = {
  __typename?: 'Mutation'
  createDocument: { __typename?: 'CreateDocument'; ok: boolean }
}

export type BatchAttachDocumentToShipmentMutationVariables = Exact<{
  documents: Array<InputDocument> | InputDocument
  jobId: Scalars['String']['input']
  validateFields: Scalars['Boolean']['input']
  newJobStatus?: InputMaybe<Scalars['String']['input']>
  jobTable?: InputMaybe<InputJobTable>
}>

export type BatchAttachDocumentToShipmentMutation = {
  __typename?: 'Mutation'
  batchAttachDocumentToShipment: {
    __typename?: 'BatchAttachDocumentToShipment'
    job: { __typename?: 'JobNode'; id: string }
  }
}

export type SaveDocumentFieldsMutationVariables = Exact<{
  jobId: Scalars['String']['input']
  documents: Array<InputDocument> | InputDocument
}>

export type SaveDocumentFieldsMutation = {
  __typename?: 'Mutation'
  saveDocumentFields: { __typename?: 'SaveDocumentFields'; ok: boolean }
}

export type SaveDocumentTableMutationVariables = Exact<{
  jobId: Scalars['String']['input']
  documentTable: InputDocumentTable
  validateFields: Scalars['Boolean']['input']
}>

export type SaveDocumentTableMutation = {
  __typename?: 'Mutation'
  saveDocumentTable: { __typename?: 'SaveDocumentTable'; ok: boolean }
}

export type UpsertDocumentTableFieldGroupMutationVariables = Exact<{
  documentTableId: Scalars['String']['input']
  fieldGroupId: Scalars['String']['input']
}>

export type UpsertDocumentTableFieldGroupMutation = {
  __typename?: 'Mutation'
  upsertDocumentTableFieldGroup: { __typename?: 'UpsertDocumentTableFieldGroup'; ok: boolean }
}

export type AddDocumentTableMutationVariables = Exact<{
  documentId: Scalars['UUID']['input']
  fieldGroupId: Scalars['UUID']['input']
}>

export type AddDocumentTableMutation = {
  __typename?: 'Mutation'
  addDocumentTable: {
    __typename?: 'AddDocumentTable'
    prevTableId?: string | null
    documentTable: {
      __typename?: 'DocumentTableNode'
      id: string
      documentId: string
      fieldGroupId: string
      orderPriority: number
      nextTableId?: string | null
      editedByUser?: boolean | null
      autofillMethod?: AutofillMethod | null
      predConfidence?: number | null
      document: {
        __typename?: 'DocumentNode'
        id: string
        documentType: { __typename?: 'DocumentTypeNode'; id: string; tableShowsPreset: boolean }
      }
      documentFieldGroups: {
        __typename?: 'DocumentFieldGroupNodeConnection'
        edges: Array<{
          __typename?: 'DocumentFieldGroupNodeEdge'
          node: {
            __typename?: 'DocumentFieldGroupNode'
            id: string
            document: { __typename?: 'DocumentNode'; id: string }
            fieldGroup: { __typename?: 'FieldGroupNode'; id: string; repeatable: boolean }
            documentFields: {
              __typename?: 'DocumentFieldNodeConnection'
              edges: Array<{
                __typename?: 'DocumentFieldNodeEdge'
                node: {
                  __typename?: 'DocumentFieldNode'
                  id: string
                  value: string
                  confirmed?: boolean | null
                  top?: number | null
                  left?: number | null
                  width?: number | null
                  height?: number | null
                  confidenceScore?: number | null
                  field: {
                    __typename?: 'FieldNode'
                    id: string
                    autofillKey: string
                    key: string
                    name: string
                  }
                }
              }>
            }
          }
        }>
      }
      documentTableColumns: {
        __typename?: 'DocumentTableColumnNodeConnection'
        edges: Array<{
          __typename?: 'DocumentTableColumnNodeEdge'
          node: {
            __typename?: 'DocumentTableColumnNode'
            id: string
            field: { __typename?: 'FieldNode'; id: string; key: string }
          }
        }>
      }
      fieldGroup: {
        __typename?: 'FieldGroupNode'
        id: string
        name: string
        key: string
        fields: {
          __typename?: 'FieldNodeConnection'
          edges: Array<{
            __typename?: 'FieldNodeEdge'
            node: {
              __typename?: 'FieldNode'
              id: string
              allowFreeText: boolean
              autofillKey: string
              columnOrder: number
              dateFormatString?: string | null
              invalidCharsRegex?: string | null
              key: string
              name: string
              partialTableAllowed: boolean
              required: boolean
              validatorDescription?: string | null
              validatorRegex?: string | null
              fieldType?:
                | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
                | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
                | null
              searchableRecord?: {
                __typename?: 'SearchableRecordNode'
                id: string
                searchKey: string
                displayKey: string
                model?: SearchableRecordModel | null
              } | null
            }
          }>
        }
      }
    }
  }
}

export type DeleteDocumentTableMutationVariables = Exact<{
  documentTableId: Scalars['String']['input']
}>

export type DeleteDocumentTableMutation = {
  __typename?: 'Mutation'
  deleteDocumentTable: { __typename?: 'DeleteDocumentTable'; ok: boolean }
}

export type DeleteDocumentTablesInGroupMutationVariables = Exact<{
  documentTableId: Scalars['String']['input']
}>

export type DeleteDocumentTablesInGroupMutation = {
  __typename?: 'Mutation'
  deleteDocumentTablesInGroup: { __typename?: 'DeleteDocumentTablesInGroup'; ok: boolean }
}

export type UnmergeDocumentTableMutationVariables = Exact<{
  documentTableId: Scalars['String']['input']
}>

export type UnmergeDocumentTableMutation = {
  __typename?: 'Mutation'
  unmergeDocumentTable: { __typename?: 'UnmergeDocumentTable'; ok: boolean }
}

export type UpdateDocumentTablesOrderMutationVariables = Exact<{
  documentTableIds: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type UpdateDocumentTablesOrderMutation = {
  __typename?: 'Mutation'
  updateDocumentTablesOrder: { __typename?: 'UpdateDocumentTablesOrder'; ok: boolean }
}

export type MergeTableMutationVariables = Exact<{
  tableIds: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type MergeTableMutation = {
  __typename?: 'Mutation'
  mergeTable: { __typename?: 'MergeTable'; id: string }
}

export type CopyTableFromOtherDocumentMutationVariables = Exact<{
  fromDocId: Scalars['UUID']['input']
  toDocIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
}>

export type CopyTableFromOtherDocumentMutation = {
  __typename?: 'Mutation'
  copyTableFromOtherDocument: { __typename?: 'CopyTableFromOtherDocument'; ok: boolean }
}

export type CopyTableToDocumentsMutationVariables = Exact<{
  tables: Array<InputDocumentTableSkeleton> | InputDocumentTableSkeleton
  toDocIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
}>

export type CopyTableToDocumentsMutation = {
  __typename?: 'Mutation'
  copyTableToDocuments: { __typename?: 'CopyTableToDocuments'; ok: boolean }
}

export type CopyRowsToDocumentsTemplateMatchMutationVariables = Exact<{
  fromDocId: Scalars['UUID']['input']
  tables: Array<InputDocumentTableSkeleton> | InputDocumentTableSkeleton
  toDocIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
  allDocIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
}>

export type CopyRowsToDocumentsTemplateMatchMutation = {
  __typename?: 'Mutation'
  copyRowsToOtherDocuments: { __typename?: 'CopyRowsToDocumentsTemplateMatch'; ok: boolean }
}

export type DeleteFilePageMutationVariables = Exact<{
  jobId: Scalars['String']['input']
  filePageId: Scalars['String']['input']
}>

export type DeleteFilePageMutation = {
  __typename?: 'Mutation'
  deleteFilePage: { __typename?: 'DeleteFilePage'; ok?: boolean | null }
}

export type DeleteFilePagesMutationVariables = Exact<{
  filePageIds: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type DeleteFilePagesMutation = {
  __typename?: 'Mutation'
  deleteFilePages: { __typename?: 'DeleteFilePages'; numUpdated?: number | null }
}

export type RotateFilePageMutationVariables = Exact<{
  angle: Scalars['Float']['input']
  filePageId: Scalars['String']['input']
}>

export type RotateFilePageMutation = {
  __typename?: 'Mutation'
  rotateFilePage: {
    __typename?: 'RotateFilePage'
    filePage?: {
      __typename?: 'FilePageNode'
      id: string
      imageUrl?: string | null
      dateModified: string
      googleOcrData?: string | null
    } | null
  }
}

export type IngestFilesMutationVariables = Exact<{
  emailSubject?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  companyId: Scalars['UUID']['input']
  files: Array<GrapheneInputFile> | GrapheneInputFile
  batchId: Scalars['UUID']['input']
}>

export type IngestFilesMutation = {
  __typename?: 'Mutation'
  ingestFiles: { __typename?: 'IngestFiles'; ok: boolean }
}

export type IngestAutofillFilesMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  files: Array<GrapheneInputFile> | GrapheneInputFile
  batchId: Scalars['UUID']['input']
}>

export type IngestAutofillFilesMutation = {
  __typename?: 'Mutation'
  ingestAutofillFiles: { __typename?: 'IngestAutofillFiles'; ok: boolean }
}

export type TiffToPngMutationVariables = Exact<{
  imageBase64Data: Scalars['String']['input']
}>

export type TiffToPngMutation = {
  __typename?: 'Mutation'
  tiffToPng: { __typename?: 'TiffToPng'; images: Array<string>; numPages: number; sizes: any }
}

export type UploadOriginalPdfsToS3MutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  files: Array<OriginalInputPdf> | OriginalInputPdf
}>

export type UploadOriginalPdfsToS3Mutation = {
  __typename?: 'Mutation'
  uploadOriginalPdfsToS3: {
    __typename?: 'UploadOriginalPDFsToS3'
    files: Array<{ __typename?: 'OriginalPDF'; filename: string; s3Uri: string }>
  }
}

export type CreateJobMutationVariables = Exact<{
  jobTemplateId: Scalars['UUID']['input']
  jobName: Scalars['String']['input']
  filePageIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
  docTypeIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
  ownerId?: InputMaybe<Scalars['UUID']['input']>
  qaId?: InputMaybe<Scalars['UUID']['input']>
  dateReceived?: InputMaybe<Scalars['String']['input']>
  batchId: Scalars['UUID']['input']
  taskId: Scalars['UUID']['input']
}>

export type CreateJobMutation = {
  __typename?: 'Mutation'
  createJob: { __typename?: 'CreateJob'; job: { __typename?: 'JobNode'; id: string } }
}

export type CreateEmptyJobMutationVariables = Exact<{
  jobTemplateId: Scalars['String']['input']
  jobName: Scalars['String']['input']
  clientReferenceNo: Scalars['String']['input']
  ownerId?: InputMaybe<Scalars['String']['input']>
  qaId?: InputMaybe<Scalars['String']['input']>
  manualProduct: Scalars['String']['input']
  dateReceived?: InputMaybe<Scalars['String']['input']>
  message: Scalars['String']['input']
  taskId?: InputMaybe<Scalars['String']['input']>
}>

export type CreateEmptyJobMutation = {
  __typename?: 'Mutation'
  createEmptyJob: { __typename?: 'CreateEmptyJob'; job: { __typename?: 'JobNode'; id: string } }
}

export type EditJobMutationVariables = Exact<{
  jobId: Scalars['String']['input']
  jobTemplateId?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  clientReferenceNo?: InputMaybe<Scalars['String']['input']>
  ownerId?: InputMaybe<Scalars['String']['input']>
  qaId?: InputMaybe<Scalars['UUID']['input']>
  manualProduct?: InputMaybe<Scalars['String']['input']>
  dateReceived?: InputMaybe<Scalars['String']['input']>
  externalStatus?: InputMaybe<JobExternalStatus>
  externalAssigneeId?: InputMaybe<Scalars['UUID']['input']>
}>

export type EditJobMutation = {
  __typename?: 'Mutation'
  editJob: { __typename?: 'EditJob'; job: { __typename?: 'JobNode'; id: string } }
}

export type AssociatePagesToJobMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  filePageIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
  docTypeIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
  batchId: Scalars['UUID']['input']
}>

export type AssociatePagesToJobMutation = {
  __typename?: 'Mutation'
  associatePagesToJob: {
    __typename?: 'AssociatePagesToJob'
    job: { __typename?: 'JobNode'; id: string }
  }
}

export type DeleteJobMutationVariables = Exact<{
  jobId: Scalars['String']['input']
}>

export type DeleteJobMutation = {
  __typename?: 'Mutation'
  deleteJob: { __typename?: 'DeleteJob'; ok: boolean }
}

export type DeleteJobTemplateMutationVariables = Exact<{
  jobTemplateId: Scalars['String']['input']
}>

export type DeleteJobTemplateMutation = {
  __typename?: 'Mutation'
  deleteJobTemplate: { __typename?: 'DeleteJobTemplate'; ok: boolean }
}

export type RestoreJobMutationVariables = Exact<{
  jobId: Scalars['String']['input']
}>

export type RestoreJobMutation = {
  __typename?: 'Mutation'
  restoreJob: { __typename?: 'RestoreJob'; ok: boolean }
}

export type CopyJobMutationVariables = Exact<{
  jobId: Scalars['String']['input']
}>

export type CopyJobMutation = {
  __typename?: 'Mutation'
  copyJob: { __typename?: 'CopyJob'; id: string }
}

export type SetJobOwnerMutationVariables = Exact<{
  jobId: Scalars['String']['input']
  ownerId: Scalars['String']['input']
}>

export type SetJobOwnerMutation = {
  __typename?: 'Mutation'
  setJobOwner: { __typename?: 'SetJobOwner'; ok: boolean }
}

export type SetJobQaMutationVariables = Exact<{
  jobId: Scalars['String']['input']
  qaId: Scalars['String']['input']
}>

export type SetJobQaMutation = {
  __typename?: 'Mutation'
  setJobQa: { __typename?: 'SetJobQA'; jobId: string }
}

export type SetJobManualProductMutationVariables = Exact<{
  jobId: Scalars['String']['input']
  manualProduct: Scalars['String']['input']
}>

export type SetJobManualProductMutation = {
  __typename?: 'Mutation'
  setJobManualProduct: {
    __typename?: 'SetJobManualProduct'
    job: { __typename?: 'JobNode'; id: string; manualProduct: ManualProduct }
  }
}

export type QaJobMutationVariables = Exact<{
  jobId: Scalars['String']['input']
  fieldsMissingInformation: Array<Scalars['String']['input']> | Scalars['String']['input']
  fieldsWrongInformation: Array<Scalars['String']['input']> | Scalars['String']['input']
  fieldsTypos: Array<Scalars['String']['input']> | Scalars['String']['input']
  otherInfo: Scalars['String']['input']
}>

export type QaJobMutation = {
  __typename?: 'Mutation'
  qaJob: { __typename?: 'QaJob'; ok: boolean }
}

export type ConfirmJobsMutationVariables = Exact<{
  jobIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
  proofUrl: Scalars['String']['input']
  filename: Scalars['String']['input']
}>

export type ConfirmJobsMutation = {
  __typename?: 'Mutation'
  confirmJobs: { __typename?: 'ConfirmJobs'; ok: boolean }
}

export type MoveJobToInProgressMutationVariables = Exact<{
  jobId: Scalars['String']['input']
}>

export type MoveJobToInProgressMutation = {
  __typename?: 'Mutation'
  moveJobToInProgress: { __typename?: 'MoveJobToInProgress'; ok: boolean }
}

export type BulkUpdateJobStatusMutationVariables = Exact<{
  jobIds: Array<Scalars['String']['input']> | Scalars['String']['input']
  status: Scalars['String']['input']
}>

export type BulkUpdateJobStatusMutation = {
  __typename?: 'Mutation'
  bulkUpdateJobStatus: { __typename?: 'BulkUpdateJobStatus'; ok: boolean }
}

export type BulkUpdateJobDetailsMutationVariables = Exact<{
  jobIds: Array<Scalars['String']['input']> | Scalars['String']['input']
  ownerId?: InputMaybe<Scalars['String']['input']>
  qaId?: InputMaybe<Scalars['String']['input']>
  manualProduct?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
}>

export type BulkUpdateJobDetailsMutation = {
  __typename?: 'Mutation'
  bulkUpdateJobDetails: { __typename?: 'BulkUpdateJobDetails'; ok: boolean }
}

export type BulkUpdateJobTaskIdMutationVariables = Exact<{
  jobIds: Array<Scalars['String']['input']> | Scalars['String']['input']
  taskId: Scalars['String']['input']
}>

export type BulkUpdateJobTaskIdMutation = {
  __typename?: 'Mutation'
  bulkUpdateJobTaskId: { __typename?: 'BulkUpdateJobTaskId'; ok: boolean }
}

export type SaveJobTableMutationVariables = Exact<{
  jobId: Scalars['String']['input']
  jobTable: InputJobTable
}>

export type SaveJobTableMutation = {
  __typename?: 'Mutation'
  saveJobTable: { __typename?: 'SaveJobTable'; ok: boolean }
}

export type SaveJobAndDocumentTablesMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  documentTables: Array<InputDocumentTable> | InputDocumentTable
  jobTable: InputJobTable
  validateFields: Scalars['Boolean']['input']
}>

export type SaveJobAndDocumentTablesMutation = {
  __typename?: 'Mutation'
  saveJobAndDocumentTables: { __typename?: 'SaveJobAndDocumentTables'; ok: boolean }
}

export type ExportJobToApiPartnerMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  exportType: ApiExportType
  lumpsum?: InputMaybe<InputDocumentTable>
  overrideChargeDescription?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ExportJobToApiPartnerMutation = {
  __typename?: 'Mutation'
  exportJobToApiPartner: { __typename?: 'ExportJobToApiPartner'; ok: boolean; responseText: string }
}

export type ExportEDocumentsToApiPartnerMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  inputEDocuments: Array<InputEDocument> | InputEDocument
  dataTargetType: EDocsDataTargetType
}>

export type ExportEDocumentsToApiPartnerMutation = {
  __typename?: 'Mutation'
  exportEDocumentsToApiPartner: { __typename?: 'ExportEDocumentsToApiPartner'; ok: boolean }
}

export type UpsertJobTemplateMutationVariables = Exact<{
  id: Scalars['UUID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  companyId?: InputMaybe<Scalars['UUID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  slaTime?: InputMaybe<Scalars['Int']['input']>
  reconType?: InputMaybe<JobTemplateReconType>
  shipmentOpsTypes?: InputMaybe<Array<ShipmentOp> | ShipmentOp>
  documentTypes?: InputMaybe<Array<InputDocumentType> | InputDocumentType>
  createIfMissing?: InputMaybe<Scalars['Boolean']['input']>
  fieldGroups?: InputMaybe<Array<InputFieldGroup> | InputFieldGroup>
  credentialId?: InputMaybe<Scalars['UUID']['input']>
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  defaultExternalAssigneeId?: InputMaybe<Scalars['UUID']['input']>
  autoReconEnabled?: InputMaybe<Scalars['Boolean']['input']>
  subtotalsRowEnabled?: InputMaybe<Scalars['Boolean']['input']>
  mainTabEnabled?: InputMaybe<Scalars['Boolean']['input']>
  autoPostEnabled?: InputMaybe<Scalars['Boolean']['input']>
  showPostButton?: InputMaybe<Scalars['Boolean']['input']>
  requireShowReconToCustomer?: InputMaybe<Scalars['Boolean']['input']>
  requireEdocsPushPull?: InputMaybe<Scalars['Boolean']['input']>
  requireExternalAssignee?: InputMaybe<Scalars['Boolean']['input']>
  inputReconThresholdRanges?: InputMaybe<Array<InputReconThresholdRange> | InputReconThresholdRange>
}>

export type UpsertJobTemplateMutation = {
  __typename?: 'Mutation'
  upsertJobTemplate: { __typename?: 'UpsertJobTemplate'; ok: boolean }
}

export type SaveJobTemplateExportFormatMutationVariables = Exact<{
  jobTemplateId: Scalars['UUID']['input']
  exportType: JobTemplateExportType
  jobTemplateExportSheets: Array<InputJobTemplateExportSheet> | InputJobTemplateExportSheet
}>

export type SaveJobTemplateExportFormatMutation = {
  __typename?: 'Mutation'
  saveJobTemplateExportFormat: { __typename?: 'SaveJobTemplateExportFormat'; ok: boolean }
}

export type CreateNoteMutationVariables = Exact<{
  jobId: Scalars['String']['input']
  content: Scalars['String']['input']
  noteType: NoteType
}>

export type CreateNoteMutation = {
  __typename?: 'Mutation'
  createNote: {
    __typename?: 'CreateNote'
    note?: {
      __typename?: 'NoteNode'
      id: string
      text: string
      dateCreated: string
      type: NoteType
      user: { __typename?: 'UserNode'; id: string; email: string }
    } | null
  }
}

export type CreateReconJobNoteMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  invoiceNumber?: InputMaybe<Scalars['String']['input']>
  reconAttemptId?: InputMaybe<Scalars['UUID']['input']>
  content: Scalars['String']['input']
}>

export type CreateReconJobNoteMutation = {
  __typename?: 'Mutation'
  createReconJobNote?: null | undefined | null
}

export type DeleteReconNoteMutationVariables = Exact<{
  reconNoteId: Scalars['UUID']['input']
}>

export type DeleteReconNoteMutation = {
  __typename?: 'Mutation'
  deleteReconNote: { __typename?: 'DeleteReconNote'; ok?: boolean | null }
}

export type MarkNotificationsReadMutationVariables = Exact<{ [key: string]: never }>

export type MarkNotificationsReadMutation = {
  __typename?: 'Mutation'
  markNotificationsRead: { __typename?: 'MarkNotificationsRead'; ok: boolean }
}

export type EditUserProfileMutationVariables = Exact<{
  name: Scalars['String']['input']
  metabaseDashboardId: Scalars['String']['input']
}>

export type EditUserProfileMutation = {
  __typename?: 'Mutation'
  editOwnProfile: { __typename?: 'EditOwnProfile'; ok?: boolean | null }
}

export type CreateUserMutationVariables = Exact<{
  inputUser: InputUser
}>

export type CreateUserMutation = { __typename?: 'Mutation'; createUser: string }

export type UpdateUserMutationVariables = Exact<{
  inputUser: InputUser
  auth0Id: Scalars['String']['input']
}>

export type UpdateUserMutation = { __typename?: 'Mutation'; updateUser: boolean }

export type DeleteUserMutationVariables = Exact<{
  auth0Id: Scalars['String']['input']
}>

export type DeleteUserMutation = { __typename?: 'Mutation'; deleteUser: boolean }

export type ResetUserPasswordMutationVariables = Exact<{
  auth0Id: Scalars['String']['input']
}>

export type ResetUserPasswordMutation = { __typename?: 'Mutation'; resetUserPassword: string }

export type UpdateDashboardCompanyUserIamMutationVariables = Exact<{
  inputUser: InputUser
  companyId?: InputMaybe<Scalars['UUID']['input']>
}>

export type UpdateDashboardCompanyUserIamMutation = {
  __typename?: 'Mutation'
  updateDashboardCompanyUserIam: boolean
}

export type BulkUpdateUserDashboardWhitelistMutationVariables = Exact<{
  defaultDashboardWhitelist: Array<Scalars['String']['input']> | Scalars['String']['input']
  userEmails: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type BulkUpdateUserDashboardWhitelistMutation = {
  __typename?: 'Mutation'
  bulkUpdateUserDashboardWhitelist: boolean
}

export type RedoReconcileUnmatchedReconAttemptsMutationVariables = Exact<{
  companyIds?: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>
}>

export type RedoReconcileUnmatchedReconAttemptsMutation = {
  __typename?: 'Mutation'
  redoReconcileUnmatchedReconAttempts: boolean
}

export type SaveReconAttemptMutationVariables = Exact<{
  reconAttemptId: Scalars['String']['input']
}>

export type SaveReconAttemptMutation = {
  __typename?: 'Mutation'
  saveReconAttempt: { __typename?: 'SaveReconAttempt'; ok: boolean }
}

export type SaveReconAttemptsByBatchMutationVariables = Exact<{
  reconAsyncBatchId: Scalars['String']['input']
}>

export type SaveReconAttemptsByBatchMutation = {
  __typename?: 'Mutation'
  saveReconAttemptsByBatch: { __typename?: 'SaveReconAttemptsByBatch'; ok: boolean }
}

export type ReconcileApInvoiceJobMutationVariables = Exact<{
  jobId: Scalars['String']['input']
  lumpsum?: InputMaybe<InputDocumentTable>
  overrideChargeDescription: Scalars['Boolean']['input']
  disableSendDueDate: Scalars['Boolean']['input']
  matchingCriteria: MatchingCriteriaType
}>

export type ReconcileApInvoiceJobMutation = {
  __typename?: 'Mutation'
  reconcileApInvoiceJob: {
    __typename?: 'ReconcileApInvoiceJob'
    docCharges: Array<{
      __typename?: 'ChargeDetail'
      id: string
      chargeCode?: string | null
      chargeDescription?: string | null
      chargeCost?: string | null
      currency?: string | null
      vendor?: string | null
      invoiceNumber?: string | null
      taxId?: string | null
      taxRate?: string | null
      invoiceDate?: string | null
      dueDate?: string | null
    }>
    expectedCharges: Array<{
      __typename?: 'ChargeDetail'
      id: string
      chainIoSiLineId?: string | null
      chargeCode?: string | null
      chargeDescription?: string | null
      chargeCost?: string | null
      currency?: string | null
      vendor?: string | null
      invoiceNumber?: string | null
      taxId?: string | null
      taxRate?: string | null
      invoiceDate?: string | null
      dueDate?: string | null
      isPosted?: boolean | null
      overseasSellAmount?: string | null
      sellCurrency?: string | null
    }>
    reconAttempt: {
      __typename?: 'ReconAttemptNode'
      id: string
      overrideChargeDescription: boolean
      job: {
        __typename?: 'JobNode'
        id: string
        jobTemplate: {
          __typename?: 'JobTemplateNode'
          id: string
          apiPartnerId?: string | null
          companyId: string
        }
      }
      reconDetail: {
        __typename?: 'ReconDetail'
        vendor?: string | null
        tmsId?: string | null
        shipmentNo?: string | null
        invoiceNo?: string | null
        hblNo?: string | null
        mblNo?: string | null
        referenceNo?: string | null
        containerNo?: string | null
        carrierBookingNo?: string | null
        consolNo?: string | null
        orderNo?: string | null
        dueDate?: string | null
      }
      matchingCriteria:
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | {
            __typename?: 'MatchingCriteriaTypeEnum'
            value: MatchingCriteriaType
            isFallback: boolean
          }
      cargowiseModule?:
        | { __typename?: 'CwTargetModuleEnum'; value: CwTargetModule; isFallback: boolean }
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | null
    }
    reconResults: Array<
      | {
          __typename?: 'ArrivalNoticeChargeRateReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeConsolTypeReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeContainerReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeContractReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeLumpsumReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeMetadataReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'FindConsolReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'FindInvoiceReconResultNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          invoiceReconMatches: {
            __typename?: 'InvoiceReconMatchNodeConnection'
            edges: Array<{
              __typename?: 'InvoiceReconMatchNodeEdge'
              node: {
                __typename?: 'InvoiceReconMatchNode'
                id: string
                chainIoSi: {
                  __typename?: 'ChainIOServiceInvoiceNode'
                  id: string
                  invoiceNumber?: string | null
                }
              }
            }>
          }
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'FindShipmentDuplicateReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'FindShipmentReconResultNode'
          referenceNo?: string | null
          hblNo?: string | null
          mblNo?: string | null
          carrierBookingNo?: string | null
          containerNo?: string | null
          orderNo?: string | null
          consolNo?: string | null
          isPrimary: boolean
          isMaximalAmongDuplicates?: boolean | null
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            forwarderReference?: string | null
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            bookingReference?: string | null
            dateModified: string
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
            chainIoConsolidations: {
              __typename?: 'ChainIOConsolidationNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOConsolidationNodeEdge'
                node: {
                  __typename?: 'ChainIOConsolidationNode'
                  id: string
                  forwarderReference?: string | null
                }
              }>
            }
            orders: {
              __typename?: 'ChainIOOrderNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOOrderNodeEdge'
                node: {
                  __typename?: 'ChainIOOrderNode'
                  id: string
                  orderReference?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            tmsId?: string | null
            masterBill?: string | null
            carrierBookingRef?: string | null
            dateModified: string
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
            shipments: {
              __typename?: 'ChainIOShipmentNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOShipmentNodeEdge'
                node: {
                  __typename?: 'ChainIOShipmentNode'
                  id: string
                  forwarderReference?: string | null
                  houseBill?: string | null
                  orders: {
                    __typename?: 'ChainIOOrderNodeConnection'
                    edges: Array<{
                      __typename?: 'ChainIOOrderNodeEdge'
                      node: {
                        __typename?: 'ChainIOOrderNode'
                        id: string
                        orderReference?: string | null
                      }
                    }>
                  }
                }
              }>
            }
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            forwarderReference?: string | null
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            bookingReference?: string | null
            dateModified: string
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
            chainIoShipment?: {
              __typename?: 'ChainIOShipmentNode'
              id: string
              carrierBookingNumber?: string | null
              forwarderReference?: string | null
              chainIoConsolidations: {
                __typename?: 'ChainIOConsolidationNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOConsolidationNodeEdge'
                  node: {
                    __typename?: 'ChainIOConsolidationNode'
                    id: string
                    forwarderReference?: string | null
                  }
                }>
              }
            } | null
            orders: {
              __typename?: 'ChainIOOrderNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOOrderNodeEdge'
                node: {
                  __typename?: 'ChainIOOrderNode'
                  id: string
                  orderReference?: string | null
                }
              }>
            }
          } | null
          findShipmentDuplicateReconResults: {
            __typename?: 'FindShipmentDuplicateReconResultNodeConnection'
            edges: Array<{
              __typename?: 'FindShipmentDuplicateReconResultNodeEdge'
              node: {
                __typename?: 'FindShipmentDuplicateReconResultNode'
                id: string
                chainIoShipment?: {
                  __typename?: 'ChainIOShipmentNode'
                  id: string
                  forwarderReference?: string | null
                  isCancelled: boolean
                  tmsId?: string | null
                } | null
                chainIoConsolidation?: {
                  __typename?: 'ChainIOConsolidationNode'
                  id: string
                  forwarderReference?: string | null
                  isCancelled?: boolean | null
                  tmsId?: string | null
                } | null
                chainIoCustomsDeclaration?: {
                  __typename?: 'ChainIOCustomsDeclarationNode'
                  id: string
                  forwarderReference?: string | null
                  isCancelled?: boolean | null
                  tmsId?: string | null
                } | null
              }
            }>
          }
        }
      | {
          __typename?: 'InvoiceLineItemReconResultNode'
          invoiceAmount?: string | null
          invoiceTaxAmount?: string | null
          expectedAmount?: string | null
          expectedTaxAmount?: string | null
          invoiceChargeCodeCode?: string | null
          invoiceNumber?: string | null
          invoiceTaxId?: string | null
          expectedTaxId?: string | null
          invoiceDate?: string | null
          dueDate?: string | null
          invoiceCurrency?: string | null
          invoiceUnitPrice?: string | null
          invoiceQuantity?: string | null
          chainIoConsolCostId?: string | null
          chainIoCustomsDeclarationCostId?: string | null
          isChargeCodeSame?: boolean | null
          isTotalCostEqual?: boolean | null
          isCurrencySameOrEmpty?: boolean | null
          isChargeVendorSame?: boolean | null
          isInvoiceNumberCorrect?: boolean | null
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          invoiceChargeCode?: {
            __typename?: 'ChargeCodeNode'
            id: string
            code: string
            chargeVendor: { __typename?: 'ChargeVendorNode'; id: string; name: string }
          } | null
          expectedChargeCode?: {
            __typename?: 'ChargeCodeNode'
            id: string
            code: string
            chargeVendor: { __typename?: 'ChargeVendorNode'; id: string; name: string }
          } | null
          invoiceChargeVendor?: { __typename?: 'ChargeVendorNode'; id: string; code: string } | null
          chainIoSiLine?: { __typename?: 'ChainIOServiceInvoiceLineNode'; id: string } | null
          duplicateChainIoSiLines: {
            __typename?: 'DuplicateChainIOServiceInvoiceLineNodeConnection'
            edges: Array<{
              __typename?: 'DuplicateChainIOServiceInvoiceLineNodeEdge'
              node: {
                __typename?: 'DuplicateChainIOServiceInvoiceLineNode'
                id: string
                chainIoSiLine?: { __typename?: 'ChainIOServiceInvoiceLineNode'; id: string } | null
              }
            }>
          }
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'InvoiceTotalReconResultNode'
          usedThresholdMatching: boolean
          isWithinThreshold: boolean
          id: string
          success: boolean
          invoiceTotalAmount: string
          invoiceTaxedTotalAmount?: string | null
          expectedTotalAmount: string
          expectedTaxedTotalAmount?: string | null
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          reconThresholdRange?: {
            __typename?: 'ReconThresholdRangeNode'
            id: string
            thresholdAmount: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'MetadataReconResultNode'
          invoiceValue?: string | null
          expectedValue?: string | null
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          key:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | {
                __typename?: 'MetadataReconResultKeyEnum'
                value: MetadataReconResultKey
                isFallback: boolean
              }
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ReconResultFallbackNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'SOATotalReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ShipmentStaffReconResultNode'
          operationsStaffName?: string | null
          operationsStaffCode?: string | null
          salesStaffName?: string | null
          salesStaffCode?: string | null
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
            tmsId?: string | null
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            dateModified: string
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            dateModified: string
          } | null
        }
    >
  }
}

export type ReconcileArrivalNoticeJobMutationVariables = Exact<{
  jobId: Scalars['String']['input']
  lumpsum?: InputMaybe<InputDocumentTable>
  premiumRateDetails?: InputMaybe<InputReconPremiumRate>
}>

export type ReconcileArrivalNoticeJobMutation = {
  __typename?: 'Mutation'
  reconcileArrivalNoticeJob: {
    __typename?: 'ReconcileArrivalNoticeJob'
    reconAttempt: {
      __typename?: 'ReconAttemptNode'
      id: string
      cargowiseModule?:
        | { __typename?: 'CwTargetModuleEnum'; value: CwTargetModule; isFallback: boolean }
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | null
    }
    reconResults: Array<
      | {
          __typename?: 'ArrivalNoticeChargeRateReconResultNode'
          documentChargeCode?: string | null
          expectedChargeCode?: string | null
          chargeCodeMatchesFound?: number | null
          documentChargeCost?: string | null
          expectedChargeCost?: string | null
          documentQuantity?: string | null
          documentTotalAmount?: string | null
          expectedTotalAmount?: string | null
          documentCurrency?: string | null
          expectedCurrency?: string | null
          currencyMatchesFound?: number | null
          documentContainerSize?: string | null
          expectedContainerSize?: string | null
          containerSizeMatchesFound?: number | null
          documentContainerType?: string | null
          expectedContainerType?: string | null
          containerTypeMatchesFound?: number | null
          documentCommodity?: string | null
          expectedCommodity?: string | null
          commodityMatchesFound?: number | null
          documentPlaceOfReceipt?: string | null
          expectedPlaceOfReceipt?: string | null
          placeOfReceiptMatchesFound?: number | null
          documentPlaceOfDelivery?: string | null
          expectedPlaceOfDelivery?: string | null
          placeOfDeliveryMatchesFound?: number | null
          documentPortOfLoading?: string | null
          expectedPortOfLoading?: string | null
          portOfLoadingMatchesFound?: number | null
          documentPortOfDischarge?: string | null
          expectedPortOfDischarge?: string | null
          portOfDischargeMatchesFound?: number | null
          documentNamedAccount?: string | null
          expectedNamedAccount?: string | null
          namedAccountMatchesFound?: number | null
          documentShipmentRoute?: string | null
          expectedShipmentRoute?: string | null
          shipmentRouteMatchesFound?: number | null
          amendmentNo?: number | null
          success: boolean
          id: string
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          portOfLoadingSimilarRateLocations: Array<{
            __typename?: 'RateLocationStringNode'
            id: string
            rateLocation: string
          }>
          portOfDischargeSimilarRateLocations: Array<{
            __typename?: 'RateLocationStringNode'
            id: string
            rateLocation: string
          }>
          placeOfReceiptSimilarRateLocations: Array<{
            __typename?: 'RateLocationStringNode'
            id: string
            rateLocation: string
          }>
          placeOfDeliverySimilarRateLocations: Array<{
            __typename?: 'RateLocationStringNode'
            id: string
            rateLocation: string
          }>
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeConsolTypeReconResultNode'
          documentCreditorCode?: string | null
          expectedCreditorCode?: string | null
          documentCarrierCode?: string | null
          expectedCarrierCode?: string | null
          documentConsolType?: string | null
          documentContainerType?: string | null
          expectedConsolType?: string | null
          expectedContainerType?: string | null
          success: boolean
          id: string
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeContainerReconResultNode'
          documentContainerNo?: string | null
          expectedContainerNo?: string | null
          documentContainerTypeCode?: string | null
          expectedContainerTypeCode?: string | null
          documentCommodity?: string | null
          success: boolean
          id: string
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeContractReconResultNode'
          contractId?: string | null
          effectivity?: string | null
          expiryDate?: string | null
          documentAcceptedDate?: string | null
          documentVendorCode?: string | null
          clientContractRefNo?: string | null
          success: boolean
          id: string
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeLumpsumReconResultNode'
          documentLumpsum?: string | null
          expectedLumpsum?: string | null
          premiumRate?: string | null
          premiumRateContainerQty?: number | null
          premiumTotalAccessorialFees?: string | null
          success: boolean
          id: string
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeMetadataReconResultNode'
          metadataKey?: string | null
          documentValue?: string | null
          expectedValue?: string | null
          success: boolean
          id: string
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'FindConsolReconResultNode'
          matchedKey?: string | null
          keyNo?: string | null
          documentConsolNo?: string | null
          documentMbl?: string | null
          documentContainerNumbers?: string | null
          success: boolean
          id: string
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'FindInvoiceReconResultNode'
          success: boolean
          id: string
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'FindShipmentDuplicateReconResultNode'
          success: boolean
          id: string
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'FindShipmentReconResultNode'
          success: boolean
          id: string
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'InvoiceLineItemReconResultNode'
          success: boolean
          id: string
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'InvoiceTotalReconResultNode'
          success: boolean
          id: string
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'MetadataReconResultNode'
          success: boolean
          id: string
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ReconResultFallbackNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'SOATotalReconResultNode'
          success: boolean
          id: string
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ShipmentStaffReconResultNode'
          success: boolean
          id: string
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
          } | null
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
    >
  }
}

export type UpdateChargeQuantityMutationVariables = Exact<{
  jobId: Scalars['String']['input']
}>

export type UpdateChargeQuantityMutation = {
  __typename?: 'Mutation'
  updateChargeQuantity: { __typename?: 'UpdateChargeQuantity'; ok: boolean }
}

export type FetchChargeDetailsFromCwMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
}>

export type FetchChargeDetailsFromCwMutation = {
  __typename?: 'Mutation'
  fetchChargeDetailsFromCw: {
    __typename?: 'FetchChargeDetailsFromTMS'
    findShipmentReconResults: Array<{
      __typename?: 'FindShipmentReconResultNode'
      id: string
      success: boolean
      referenceNo?: string | null
      hblNo?: string | null
      mblNo?: string | null
      carrierBookingNo?: string | null
      containerNo?: string | null
      orderNo?: string | null
      consolNo?: string | null
      isMaximalAmongDuplicates?: boolean | null
      type:
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
      chainIoShipment?: {
        __typename?: 'ChainIOShipmentNode'
        id: string
        forwarderReference?: string | null
        dateModified: string
        houseBill?: string | null
        masterBill?: string | null
        carrierBookingNumber?: string | null
        bookingReference?: string | null
        containers: {
          __typename?: 'ChainIOContainerNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOContainerNodeEdge'
            node: {
              __typename?: 'ChainIOContainerNode'
              id: string
              containerNumber?: string | null
            }
          }>
        }
        orders: {
          __typename?: 'ChainIOOrderNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOOrderNodeEdge'
            node: {
              __typename?: 'ChainIOOrderNode'
              id: string
              orderReference?: string | null
              sequence?: number | null
              orderNumberSplit?: number | null
              orderStatus?: string | null
              orderDate?: string | null
              orderBuyer?: {
                __typename?: 'ChainIOAddressNode'
                id: string
                sourcePartyId?: string | null
              } | null
              orderSupplier?: {
                __typename?: 'ChainIOAddressNode'
                id: string
                sourcePartyId?: string | null
              } | null
              orderLines: {
                __typename?: 'ChainIOOrderLineNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOOrderLineNodeEdge'
                  node: {
                    __typename?: 'ChainIOOrderLineNode'
                    id: string
                    orderLineNumber?: number | null
                    partNumber?: string | null
                    description?: string | null
                    containerNumber?: string | null
                  }
                }>
              }
            }
          }>
        }
        chainIoConsolidations: {
          __typename?: 'ChainIOConsolidationNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOConsolidationNodeEdge'
            node: {
              __typename?: 'ChainIOConsolidationNode'
              id: string
              forwarderReference?: string | null
            }
          }>
        }
        additionalReferences: {
          __typename?: 'ChainIOAdditionalReferenceNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOAdditionalReferenceNodeEdge'
            node: {
              __typename?: 'ChainIOAdditionalReferenceNode'
              id: string
              referenceType?: string | null
              referenceNumber?: string | null
            }
          }>
        }
        customFields: {
          __typename?: 'ChainIOCustomFieldNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOCustomFieldNodeEdge'
            node: {
              __typename?: 'ChainIOCustomFieldNode'
              id: string
              key?: string | null
              value?: string | null
            }
          }>
        }
        shipmentLegs: {
          __typename?: 'ChainIOShipmentLegNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOShipmentLegNodeEdge'
            node: {
              __typename?: 'ChainIOShipmentLegNode'
              id: string
              legSequenceNumber: number
              transportMode?: string | null
              legType?: string | null
              vesselName?: string | null
              voyageNumber?: string | null
              departureEstimated?: string | null
              arrivalEstimated?: string | null
              bookingStatus?: string | null
              ladingPort?: {
                __typename?: 'ChainIOLocodeNode'
                id: string
                unlocode?: string | null
              } | null
              arrivalPort?: {
                __typename?: 'ChainIOLocodeNode'
                id: string
                unlocode?: string | null
              } | null
              legCarrier?: {
                __typename?: 'ChainIOAddressNode'
                id: string
                name?: string | null
              } | null
            }
          }>
        }
        packingLines: {
          __typename?: 'ChainIOPackingLineNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOPackingLineNodeEdge'
            node: {
              __typename?: 'ChainIOPackingLineNode'
              id: string
              packs?: string | null
              packsUom?: string | null
              containerNumber?: string | null
              weight?: string | null
              weightUom?: string | null
              volume?: string | null
              volumeUom?: string | null
              referenceNumber?: string | null
              importReferenceNumber?: string | null
              exportReferenceNumber?: string | null
              descriptionOfGoods?: string | null
              marksAndNumbers?: string | null
              commodityCode?: string | null
            }
          }>
        }
        localClient?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        shipper?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        consignee?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        supplier?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        importer?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        pickupTransportCompany?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        deliveryTransportCompany?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        pickupFrom?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        deliveryTo?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        controllingAgent?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        controllingCustomer?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        importBroker?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
      } | null
      chainIoConsolidation?: {
        __typename?: 'ChainIOConsolidationNode'
        id: string
        forwarderReference?: string | null
        dateModified: string
        masterBill?: string | null
        carrierBookingRef?: string | null
        consolCosts: {
          __typename?: 'ChainIOConsolCostNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOConsolCostNodeEdge'
            node: {
              __typename?: 'ChainIOConsolCostNode'
              id: string
              chargeCode?: string | null
              chargeDescription?: string | null
              amount?: string | null
              currency?: string | null
              creditorCode?: string | null
              invoiceNumber?: string | null
              taxRateCode?: string | null
              taxRate?: string | null
              isPosted?: boolean | null
            }
          }>
        }
        containers: {
          __typename?: 'ChainIOContainerNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOContainerNodeEdge'
            node: {
              __typename?: 'ChainIOContainerNode'
              id: string
              containerNumber?: string | null
              containerCount?: number | null
              seal1?: string | null
              containerMode?: string | null
              typeCode?: string | null
              deliveryMode?: string | null
              sealedBy?: string | null
              wharfGateIn?: string | null
            }
          }>
        }
        shipmentLegs: {
          __typename?: 'ChainIOShipmentLegNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOShipmentLegNodeEdge'
            node: {
              __typename?: 'ChainIOShipmentLegNode'
              id: string
              legSequenceNumber: number
              transportMode?: string | null
              legType?: string | null
              vesselName?: string | null
              voyageNumber?: string | null
              departureEstimated?: string | null
              arrivalEstimated?: string | null
              bookingStatus?: string | null
              ladingPort?: {
                __typename?: 'ChainIOLocodeNode'
                id: string
                unlocode?: string | null
              } | null
              arrivalPort?: {
                __typename?: 'ChainIOLocodeNode'
                id: string
                unlocode?: string | null
              } | null
              legCarrier?: {
                __typename?: 'ChainIOAddressNode'
                id: string
                name?: string | null
              } | null
            }
          }>
        }
        shipments: {
          __typename?: 'ChainIOShipmentNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOShipmentNodeEdge'
            node: {
              __typename?: 'ChainIOShipmentNode'
              id: string
              forwarderReference?: string | null
              houseBill?: string | null
              orders: {
                __typename?: 'ChainIOOrderNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOOrderNodeEdge'
                  node: {
                    __typename?: 'ChainIOOrderNode'
                    id: string
                    orderReference?: string | null
                  }
                }>
              }
            }
          }>
        }
        carrier?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        creditor?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        coloadWith?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        receivingAgent?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        sendingAgent?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
      } | null
      chainIoCustomsDeclaration?: {
        __typename?: 'ChainIOCustomsDeclarationNode'
        id: string
        forwarderReference?: string | null
        dateModified: string
        houseBill?: string | null
        masterBill?: string | null
        bookingReference?: string | null
        containers: {
          __typename?: 'ChainIOContainerNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOContainerNodeEdge'
            node: {
              __typename?: 'ChainIOContainerNode'
              id: string
              containerNumber?: string | null
            }
          }>
        }
        chainIoShipment?: {
          __typename?: 'ChainIOShipmentNode'
          id: string
          carrierBookingNumber?: string | null
          forwarderReference?: string | null
          chainIoConsolidations: {
            __typename?: 'ChainIOConsolidationNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOConsolidationNodeEdge'
              node: {
                __typename?: 'ChainIOConsolidationNode'
                id: string
                forwarderReference?: string | null
              }
            }>
          }
        } | null
        orders: {
          __typename?: 'ChainIOOrderNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOOrderNodeEdge'
            node: { __typename?: 'ChainIOOrderNode'; id: string; orderReference?: string | null }
          }>
        }
      } | null
      findShipmentDuplicateReconResults: {
        __typename?: 'FindShipmentDuplicateReconResultNodeConnection'
        edges: Array<{
          __typename?: 'FindShipmentDuplicateReconResultNodeEdge'
          node: {
            __typename?: 'FindShipmentDuplicateReconResultNode'
            id: string
            chainIoShipment?: {
              __typename?: 'ChainIOShipmentNode'
              id: string
              forwarderReference?: string | null
              houseBill?: string | null
              masterBill?: string | null
              isCancelled: boolean
              tmsId?: string | null
              bookingReference?: string | null
              containers: {
                __typename?: 'ChainIOContainerNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOContainerNodeEdge'
                  node: {
                    __typename?: 'ChainIOContainerNode'
                    id: string
                    containerNumber?: string | null
                  }
                }>
              }
              chainIoConsolidation?: {
                __typename?: 'ChainIOConsolidationNode'
                id: string
                forwarderReference?: string | null
                isCancelled?: boolean | null
                tmsId?: string | null
              } | null
              orders: {
                __typename?: 'ChainIOOrderNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOOrderNodeEdge'
                  node: {
                    __typename?: 'ChainIOOrderNode'
                    id: string
                    orderReference?: string | null
                  }
                }>
              }
            } | null
            chainIoConsolidation?: {
              __typename?: 'ChainIOConsolidationNode'
              id: string
              forwarderReference?: string | null
              isCancelled?: boolean | null
              tmsId?: string | null
            } | null
            chainIoCustomsDeclaration?: {
              __typename?: 'ChainIOCustomsDeclarationNode'
              id: string
              forwarderReference?: string | null
              tmsId?: string | null
              isCancelled?: boolean | null
            } | null
          }
        }>
      }
    }>
    expectedCharges: Array<{
      __typename?: 'ChargeDetail'
      id: string
      chargeCode?: string | null
      chargeDescription?: string | null
      chargeCost?: string | null
      currency?: string | null
      vendor?: string | null
      invoiceNumber?: string | null
      taxId?: string | null
      taxRate?: string | null
      invoiceDate?: string | null
      dueDate?: string | null
      isPosted?: boolean | null
      overseasSellAmount?: string | null
      sellCurrency?: string | null
    }>
    shipmentStaffReconResults: Array<{
      __typename?: 'ShipmentStaffReconResultNode'
      id: string
      operationsStaffName?: string | null
      operationsStaffCode?: string | null
      salesStaffName?: string | null
      salesStaffCode?: string | null
    }>
    metadataReconResults: Array<{
      __typename?: 'MetadataReconResultNode'
      id: string
      invoiceValue?: string | null
      expectedValue?: string | null
      type:
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
      key:
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | {
            __typename?: 'MetadataReconResultKeyEnum'
            value: MetadataReconResultKey
            isFallback: boolean
          }
    }>
  }
}

export type BatchFetchShipmentInfoFromCwMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
}>

export type BatchFetchShipmentInfoFromCwMutation = {
  __typename?: 'Mutation'
  batchFetchShipmentInfoFromCw: {
    __typename?: 'BatchFetchShipmentInfoFromCW'
    checkShipmentInfoAsyncBatchId: string
  }
}

export type DeleteChargeVendorMutationVariables = Exact<{
  chargeVendorId: Scalars['UUID']['input']
}>

export type DeleteChargeVendorMutation = {
  __typename?: 'Mutation'
  deleteChargeVendor: { __typename?: 'DeleteChargeVendor'; ok: boolean }
}

export type DeleteChargeCodesMutationVariables = Exact<{
  chargeCodeIds: Array<InputMaybe<Scalars['UUID']['input']>> | InputMaybe<Scalars['UUID']['input']>
}>

export type DeleteChargeCodesMutation = {
  __typename?: 'Mutation'
  deleteChargeCodes: { __typename?: 'DeleteChargeCodes'; ok: boolean }
}

export type DeleteChargeCodesAndOverridesMutationVariables = Exact<{
  chargeCodeIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
}>

export type DeleteChargeCodesAndOverridesMutation = {
  __typename?: 'Mutation'
  deleteChargeCodesAndOverrides: { __typename?: 'DeleteChargeCodesAndOverrides'; ok: boolean }
}

export type EditChargeVendorMutationVariables = Exact<{
  chargeVendorId: Scalars['UUID']['input']
  name: Scalars['String']['input']
  code: Scalars['String']['input']
  type: Scalars['String']['input']
  chargeCodes: Array<InputChargeCode> | InputChargeCode
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  codesLimit?: InputMaybe<Scalars['Int']['input']>
  codesOffset?: InputMaybe<Scalars['Int']['input']>
}>

export type EditChargeVendorMutation = {
  __typename?: 'Mutation'
  editChargeVendor: { __typename?: 'EditChargeVendor'; id: string }
}

export type EditChargeVendorAndOverridesMutationVariables = Exact<{
  chargeVendorId: Scalars['UUID']['input']
  name: Scalars['String']['input']
  code: Scalars['String']['input']
  type: Scalars['String']['input']
  chargeCodeOverrides: Array<InputChargeCode> | InputChargeCode
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  codesLimit?: InputMaybe<Scalars['Int']['input']>
  codesOffset?: InputMaybe<Scalars['Int']['input']>
}>

export type EditChargeVendorAndOverridesMutation = {
  __typename?: 'Mutation'
  editChargeVendorAndOverrides: { __typename?: 'EditChargeVendorAndOverrides'; id: string }
}

export type EditChargeCodesMutationVariables = Exact<{
  chargeCodeId: Scalars['UUID']['input']
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  code: Scalars['String']['input']
  taxId?: InputMaybe<Scalars['UUID']['input']>
  vendorsAndDescriptions: Array<InputVendorAndDesc> | InputVendorAndDesc
}>

export type EditChargeCodesMutation = {
  __typename?: 'Mutation'
  editChargeCodes: { __typename?: 'EditChargeCodes'; id: string }
}

export type EditChargeCodeAndOverridesMutationVariables = Exact<{
  chargeCodeId: Scalars['UUID']['input']
  code: Scalars['String']['input']
  description: Scalars['String']['input']
  vendorsAndDescriptions: Array<InputVendorAndDesc> | InputVendorAndDesc
  taxId?: InputMaybe<Scalars['UUID']['input']>
}>

export type EditChargeCodeAndOverridesMutation = {
  __typename?: 'Mutation'
  editChargeCodeAndOverrides: { __typename?: 'EditChargeCodeAndOverrides'; id: string }
}

export type CreateChargeVendorWithChargeCodesMutationVariables = Exact<{
  companyId: Scalars['UUID']['input']
  name: Scalars['String']['input']
  code: Scalars['String']['input']
  type: Scalars['String']['input']
  chargeCodes: Array<InputChargeCode> | InputChargeCode
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
}>

export type CreateChargeVendorWithChargeCodesMutation = {
  __typename?: 'Mutation'
  createChargeVendorWithChargeCodes: {
    __typename?: 'CreateChargeVendorWithChargeCodes'
    chargeVendor: { __typename?: 'ChargeVendorNode'; id: string }
  }
}

export type CreateChargeVendorAndOverridesMutationVariables = Exact<{
  companyId: Scalars['UUID']['input']
  name: Scalars['String']['input']
  code: Scalars['String']['input']
  type: Scalars['String']['input']
  chargeCodeOverrides: Array<InputChargeCode> | InputChargeCode
  apiPartnerId: Scalars['UUID']['input']
}>

export type CreateChargeVendorAndOverridesMutation = {
  __typename?: 'Mutation'
  createChargeVendorAndOverrides: {
    __typename?: 'CreateChargeVendorAndOverrides'
    chargeVendor: { __typename?: 'ChargeVendorNode'; id: string }
  }
}

export type UpsertSeperatedChargeVendorsChargeCodesMutationVariables = Exact<{
  companyId: Scalars['UUID']['input']
  apiPartnerIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
  chargeVendorsSignedUrl: Scalars['String']['input']
  chargeCodesSignedUrl: Scalars['String']['input']
}>

export type UpsertSeperatedChargeVendorsChargeCodesMutation = {
  __typename?: 'Mutation'
  upsertSeperatedChargeVendorsChargeCodes: {
    __typename?: 'UpsertSeperatedChargeVendorsChargeCodes'
    chargeCodeUploadAsyncTaskId: string
  }
}

export type GetVendorChargeCodeSignedUrlQueryVariables = Exact<{
  filename: Scalars['String']['input']
}>

export type GetVendorChargeCodeSignedUrlQuery = {
  __typename?: 'Query'
  vendorChargeCodeUrl: { __typename?: 'FileSignedUrl'; putUrl: string; viewUrl: string }
}

export type CreateChargeCodesMutationVariables = Exact<{
  companyId: Scalars['UUID']['input']
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  code: Scalars['String']['input']
  taxId?: InputMaybe<Scalars['UUID']['input']>
  vendorsAndDescriptions: Array<InputVendorAndDesc> | InputVendorAndDesc
}>

export type CreateChargeCodesMutation = {
  __typename?: 'Mutation'
  createChargeCodes: { __typename?: 'CreateChargeCodes'; ok: boolean }
}

export type CreateChargeCodeAndOverridesMutationVariables = Exact<{
  companyId: Scalars['UUID']['input']
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  code: Scalars['String']['input']
  description: Scalars['String']['input']
  vendorsAndDescriptions: Array<InputVendorAndDesc> | InputVendorAndDesc
  taxId?: InputMaybe<Scalars['UUID']['input']>
}>

export type CreateChargeCodeAndOverridesMutation = {
  __typename?: 'Mutation'
  createChargeCodeAndOverrides: { __typename?: 'CreateChargeCodeAndOverrides'; ok: boolean }
}

export type CreateTaxMutationVariables = Exact<{
  taxDetails: InputTaxDetails
}>

export type CreateTaxMutation = {
  __typename?: 'Mutation'
  createTax: { __typename?: 'CreateTax'; ok: boolean }
}

export type EditTaxMutationVariables = Exact<{
  taxId: Scalars['UUID']['input']
  taxDetails: InputTaxDetails
}>

export type EditTaxMutation = {
  __typename?: 'Mutation'
  editTax: { __typename?: 'EditTax'; id: string }
}

export type DeleteTaxMutationVariables = Exact<{
  taxId: Scalars['UUID']['input']
}>

export type DeleteTaxMutation = {
  __typename?: 'Mutation'
  deleteTax: { __typename?: 'DeleteTax'; ok: boolean }
}

export type FetchChargeDetailsFromTmsMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
}>

export type FetchChargeDetailsFromTmsMutation = {
  __typename?: 'Mutation'
  fetchChargeDetailsFromTms: {
    __typename?: 'FetchChargeDetailsFromTMS'
    findShipmentReconResults: Array<{
      __typename?: 'FindShipmentReconResultNode'
      id: string
      success: boolean
      referenceNo?: string | null
      hblNo?: string | null
      mblNo?: string | null
      carrierBookingNo?: string | null
      containerNo?: string | null
      orderNo?: string | null
      consolNo?: string | null
      type:
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
      chainIoShipment?: {
        __typename?: 'ChainIOShipmentNode'
        id: string
        forwarderReference?: string | null
        dateModified: string
        houseBill?: string | null
        masterBill?: string | null
        carrierBookingNumber?: string | null
        bookingReference?: string | null
        containers: {
          __typename?: 'ChainIOContainerNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOContainerNodeEdge'
            node: {
              __typename?: 'ChainIOContainerNode'
              id: string
              containerNumber?: string | null
            }
          }>
        }
        orders: {
          __typename?: 'ChainIOOrderNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOOrderNodeEdge'
            node: {
              __typename?: 'ChainIOOrderNode'
              id: string
              orderReference?: string | null
              sequence?: number | null
            }
          }>
        }
        chainIoConsolidations: {
          __typename?: 'ChainIOConsolidationNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOConsolidationNodeEdge'
            node: {
              __typename?: 'ChainIOConsolidationNode'
              id: string
              forwarderReference?: string | null
            }
          }>
        }
        additionalReferences: {
          __typename?: 'ChainIOAdditionalReferenceNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOAdditionalReferenceNodeEdge'
            node: {
              __typename?: 'ChainIOAdditionalReferenceNode'
              id: string
              referenceType?: string | null
              referenceNumber?: string | null
            }
          }>
        }
        customFields: {
          __typename?: 'ChainIOCustomFieldNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOCustomFieldNodeEdge'
            node: {
              __typename?: 'ChainIOCustomFieldNode'
              id: string
              key?: string | null
              value?: string | null
            }
          }>
        }
        shipmentLegs: {
          __typename?: 'ChainIOShipmentLegNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOShipmentLegNodeEdge'
            node: {
              __typename?: 'ChainIOShipmentLegNode'
              id: string
              legSequenceNumber: number
              transportMode?: string | null
              legType?: string | null
              vesselName?: string | null
              voyageNumber?: string | null
              departureEstimated?: string | null
              arrivalEstimated?: string | null
              bookingStatus?: string | null
              ladingPort?: {
                __typename?: 'ChainIOLocodeNode'
                id: string
                unlocode?: string | null
              } | null
              arrivalPort?: {
                __typename?: 'ChainIOLocodeNode'
                id: string
                unlocode?: string | null
              } | null
              legCarrier?: {
                __typename?: 'ChainIOAddressNode'
                id: string
                name?: string | null
              } | null
            }
          }>
        }
        packingLines: {
          __typename?: 'ChainIOPackingLineNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOPackingLineNodeEdge'
            node: {
              __typename?: 'ChainIOPackingLineNode'
              id: string
              packs?: string | null
              packsUom?: string | null
              containerNumber?: string | null
              weight?: string | null
              weightUom?: string | null
              volume?: string | null
              volumeUom?: string | null
              referenceNumber?: string | null
              importReferenceNumber?: string | null
              exportReferenceNumber?: string | null
              descriptionOfGoods?: string | null
              marksAndNumbers?: string | null
            }
          }>
        }
        localClient?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        shipper?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        consignee?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        supplier?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        importer?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        pickupTransportCompany?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        deliveryTransportCompany?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        pickupFrom?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        deliveryTo?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
      } | null
      chainIoConsolidation?: {
        __typename?: 'ChainIOConsolidationNode'
        id: string
        forwarderReference?: string | null
        dateModified: string
        masterBill?: string | null
        carrierBookingRef?: string | null
        consolCosts: {
          __typename?: 'ChainIOConsolCostNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOConsolCostNodeEdge'
            node: {
              __typename?: 'ChainIOConsolCostNode'
              id: string
              chargeCode?: string | null
              chargeDescription?: string | null
              amount?: string | null
              currency?: string | null
              creditorCode?: string | null
              invoiceNumber?: string | null
              taxRateCode?: string | null
              taxRate?: string | null
              isPosted?: boolean | null
            }
          }>
        }
        containers: {
          __typename?: 'ChainIOContainerNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOContainerNodeEdge'
            node: {
              __typename?: 'ChainIOContainerNode'
              id: string
              containerNumber?: string | null
              containerCount?: number | null
              seal1?: string | null
              containerMode?: string | null
              typeCode?: string | null
              deliveryMode?: string | null
              sealedBy?: string | null
            }
          }>
        }
        shipmentLegs: {
          __typename?: 'ChainIOShipmentLegNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOShipmentLegNodeEdge'
            node: {
              __typename?: 'ChainIOShipmentLegNode'
              id: string
              legSequenceNumber: number
              transportMode?: string | null
              legType?: string | null
              vesselName?: string | null
              voyageNumber?: string | null
              departureEstimated?: string | null
              arrivalEstimated?: string | null
              bookingStatus?: string | null
              ladingPort?: {
                __typename?: 'ChainIOLocodeNode'
                id: string
                unlocode?: string | null
              } | null
              arrivalPort?: {
                __typename?: 'ChainIOLocodeNode'
                id: string
                unlocode?: string | null
              } | null
              legCarrier?: {
                __typename?: 'ChainIOAddressNode'
                id: string
                name?: string | null
              } | null
            }
          }>
        }
        shipments: {
          __typename?: 'ChainIOShipmentNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOShipmentNodeEdge'
            node: {
              __typename?: 'ChainIOShipmentNode'
              id: string
              forwarderReference?: string | null
              houseBill?: string | null
              orders: {
                __typename?: 'ChainIOOrderNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOOrderNodeEdge'
                  node: {
                    __typename?: 'ChainIOOrderNode'
                    id: string
                    orderReference?: string | null
                  }
                }>
              }
            }
          }>
        }
        carrier?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        creditor?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        coloadWith?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        receivingAgent?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        sendingAgent?: {
          __typename?: 'ChainIOAddressNode'
          id: string
          name?: string | null
          sourcePartyId?: string | null
          unlocode?: string | null
          address1?: string | null
          address2?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
      } | null
      chainIoCustomsDeclaration?: {
        __typename?: 'ChainIOCustomsDeclarationNode'
        id: string
        forwarderReference?: string | null
        dateModified: string
        houseBill?: string | null
        masterBill?: string | null
        bookingReference?: string | null
        containers: {
          __typename?: 'ChainIOContainerNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOContainerNodeEdge'
            node: {
              __typename?: 'ChainIOContainerNode'
              id: string
              containerNumber?: string | null
            }
          }>
        }
        chainIoShipment?: {
          __typename?: 'ChainIOShipmentNode'
          id: string
          carrierBookingNumber?: string | null
          forwarderReference?: string | null
          chainIoConsolidations: {
            __typename?: 'ChainIOConsolidationNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOConsolidationNodeEdge'
              node: {
                __typename?: 'ChainIOConsolidationNode'
                id: string
                forwarderReference?: string | null
              }
            }>
          }
        } | null
        orders: {
          __typename?: 'ChainIOOrderNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOOrderNodeEdge'
            node: { __typename?: 'ChainIOOrderNode'; id: string; orderReference?: string | null }
          }>
        }
      } | null
      findShipmentDuplicateReconResults: {
        __typename?: 'FindShipmentDuplicateReconResultNodeConnection'
        edges: Array<{
          __typename?: 'FindShipmentDuplicateReconResultNodeEdge'
          node: {
            __typename?: 'FindShipmentDuplicateReconResultNode'
            id: string
            chainIoShipment?: {
              __typename?: 'ChainIOShipmentNode'
              id: string
              forwarderReference?: string | null
              houseBill?: string | null
              isCancelled: boolean
              tmsId?: string | null
              masterBill?: string | null
              bookingReference?: string | null
              containers: {
                __typename?: 'ChainIOContainerNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOContainerNodeEdge'
                  node: {
                    __typename?: 'ChainIOContainerNode'
                    id: string
                    containerNumber?: string | null
                  }
                }>
              }
              chainIoConsolidation?: {
                __typename?: 'ChainIOConsolidationNode'
                id: string
                forwarderReference?: string | null
                isCancelled?: boolean | null
                tmsId?: string | null
              } | null
              orders: {
                __typename?: 'ChainIOOrderNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOOrderNodeEdge'
                  node: {
                    __typename?: 'ChainIOOrderNode'
                    id: string
                    orderReference?: string | null
                  }
                }>
              }
            } | null
            chainIoConsolidation?: {
              __typename?: 'ChainIOConsolidationNode'
              id: string
              isCancelled?: boolean | null
              tmsId?: string | null
              forwarderReference?: string | null
            } | null
            chainIoCustomsDeclaration?: {
              __typename?: 'ChainIOCustomsDeclarationNode'
              id: string
              isCancelled?: boolean | null
              tmsId?: string | null
              forwarderReference?: string | null
            } | null
          }
        }>
      }
    }>
    expectedCharges: Array<{
      __typename?: 'ChargeDetail'
      id: string
      chargeCode?: string | null
      chargeDescription?: string | null
      chargeCost?: string | null
      currency?: string | null
      vendor?: string | null
      invoiceNumber?: string | null
      taxId?: string | null
      taxRate?: string | null
      invoiceDate?: string | null
      dueDate?: string | null
      isPosted?: boolean | null
      overseasSellAmount?: string | null
      sellCurrency?: string | null
    }>
    shipmentStaffReconResults: Array<{
      __typename?: 'ShipmentStaffReconResultNode'
      id: string
      operationsStaffName?: string | null
      operationsStaffCode?: string | null
      salesStaffName?: string | null
      salesStaffCode?: string | null
    }>
    metadataReconResults: Array<{
      __typename?: 'MetadataReconResultNode'
      id: string
      invoiceValue?: string | null
      expectedValue?: string | null
      type:
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
      key:
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | {
            __typename?: 'MetadataReconResultKeyEnum'
            value: MetadataReconResultKey
            isFallback: boolean
          }
    }>
  }
}

export type UpdateRisrAndRmcMutationVariables = Exact<{
  inputReconInfoObjects?: InputMaybe<Array<InputReconInfoObject> | InputReconInfoObject>
  jobId: Scalars['UUID']['input']
}>

export type UpdateRisrAndRmcMutation = { __typename?: 'Mutation'; updateRisrAndRmc: boolean }

export type ReconcileSoaJobMutationVariables = Exact<{
  jobId: Scalars['UUID']['input']
  overrideChargeDescription: Scalars['Boolean']['input']
  matchingCriteria: MatchingCriteriaType
  asLumpsum: Scalars['Boolean']['input']
}>

export type ReconcileSoaJobMutation = {
  __typename?: 'Mutation'
  reconcileSoaJob: { __typename?: 'ReconcileSoaJob'; reconAsyncBatchId: string }
}

export type CreateTaskMutationVariables = Exact<{
  companyId: Scalars['String']['input']
  dateReceived: Scalars['String']['input']
  message: Scalars['String']['input']
  starred: Scalars['Boolean']['input']
  blocked: Scalars['Boolean']['input']
  status: Scalars['String']['input']
  taskReferenceId: Scalars['String']['input']
  taskType: Scalars['String']['input']
  title: Scalars['String']['input']
}>

export type CreateTaskMutation = {
  __typename?: 'Mutation'
  createTask: {
    __typename?: 'CreateTask'
    task: {
      __typename?: 'TaskNode'
      id: string
      title: string
      taskReferenceId: string
      message?: string | null
      dateReceived?: string | null
      company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
    }
  }
}

export type UpdateTaskStatusMutationVariables = Exact<{
  taskId: Scalars['String']['input']
  status: Scalars['String']['input']
}>

export type UpdateTaskStatusMutation = {
  __typename?: 'Mutation'
  updateTaskStatus: {
    __typename?: 'UpdateTaskStatus'
    task: { __typename?: 'TaskNode'; id: string; status: TaskStatus }
  }
}

export type DeleteTaskMutationVariables = Exact<{
  taskId: Scalars['String']['input']
}>

export type DeleteTaskMutation = {
  __typename?: 'Mutation'
  deleteTask: { __typename?: 'DeleteTask'; ok?: boolean | null }
}

export type UpdateTaskMutationVariables = Exact<{
  taskId: Scalars['String']['input']
  title: Scalars['String']['input']
  dateReceived: Scalars['String']['input']
  message?: InputMaybe<Scalars['String']['input']>
  taskReferenceId: Scalars['String']['input']
  dateConfirmed?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateTaskMutation = {
  __typename?: 'Mutation'
  updateTask: {
    __typename?: 'UpdateTask'
    task: {
      __typename?: 'TaskNode'
      id: string
      status: TaskStatus
      taskType: TaskType
      message?: string | null
      dateConfirmed?: string | null
      dateReceived?: string | null
      taskReferenceId: string
    }
  }
}

export type UpdateTaskTypeMutationVariables = Exact<{
  taskId: Scalars['String']['input']
  taskType: Scalars['String']['input']
}>

export type UpdateTaskTypeMutation = {
  __typename?: 'Mutation'
  updateTaskType: {
    __typename?: 'UpdateTaskType'
    task: { __typename?: 'TaskNode'; id: string; taskType: TaskType }
  }
}

export type UpdateTaskCompanyMutationVariables = Exact<{
  taskId: Scalars['String']['input']
  companyId: Scalars['String']['input']
}>

export type UpdateTaskCompanyMutation = {
  __typename?: 'Mutation'
  updateTaskCompany: {
    __typename?: 'UpdateTaskCompany'
    task: {
      __typename?: 'TaskNode'
      id: string
      company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
    }
  }
}

export type StarTaskMutationVariables = Exact<{
  taskId: Scalars['String']['input']
  starred: Scalars['Boolean']['input']
}>

export type StarTaskMutation = {
  __typename?: 'Mutation'
  starTask: { __typename?: 'StarTask'; task: { __typename?: 'TaskNode'; id: string } }
}

export type BlockTaskMutationVariables = Exact<{
  taskId: Scalars['String']['input']
  blocked: Scalars['Boolean']['input']
  dateReceived?: InputMaybe<Scalars['String']['input']>
}>

export type BlockTaskMutation = {
  __typename?: 'Mutation'
  blockTask: {
    __typename?: 'BlockTask'
    task: {
      __typename?: 'TaskNode'
      id: string
      status: TaskStatus
      blocked: boolean
      dateReceived?: string | null
      dateConfirmed?: string | null
      jobs: {
        __typename?: 'JobNodeConnection'
        edges: Array<{
          __typename?: 'JobNodeEdge'
          node: { __typename?: 'JobNode'; id: string; status: JobStatus }
        }>
      }
    }
  }
}

export type CreateTaskNoteMutationVariables = Exact<{
  taskId: Scalars['String']['input']
  content: Scalars['String']['input']
}>

export type CreateTaskNoteMutation = {
  __typename?: 'Mutation'
  createTaskNote: {
    __typename?: 'CreateTaskNote'
    taskNote: {
      __typename?: 'TaskNoteNode'
      id: string
      content?: string | null
      dateCreated: string
      user: { __typename?: 'UserNode'; id: string; email: string }
    }
  }
}

export type DeleteTaskNoteMutationVariables = Exact<{
  taskNoteId: Scalars['String']['input']
}>

export type DeleteTaskNoteMutation = {
  __typename?: 'Mutation'
  deleteTaskNote: { __typename?: 'DeleteTaskNote'; ok?: boolean | null }
}

export type SearchedCompaniesQueryVariables = Exact<{
  query: Scalars['String']['input']
}>

export type SearchedCompaniesQuery = {
  __typename?: 'Query'
  searchedForCompanies: Array<{
    __typename?: 'CompanyNode'
    id: string
    name?: string | null
    jobTemplates: {
      __typename?: 'JobTemplateNodeConnection'
      edges: Array<{
        __typename?: 'JobTemplateNodeEdge'
        node: {
          __typename?: 'JobTemplateNode'
          id: string
          name: string
          dateDeleted?: string | null
        }
      }>
    }
  }>
}

export type ApiPartnersQueryVariables = Exact<{ [key: string]: never }>

export type ApiPartnersQuery = {
  __typename?: 'Query'
  apiPartners: Array<{
    __typename?: 'ApiPartnerNode'
    id: string
    apiKey: string
    exportEndpoint?: string | null
    importEndpoint?: string | null
    documentExportEndpoint?: string | null
    documentImportEndpoint?: string | null
    name: string
    countryCode?: string | null
    expedockDocIngestEmail?: string | null
    apiPartnerType: ApiPartnerType
    authValues?: string | null
    tmsType?:
      | { __typename?: 'ApiPartnerTmsTypeEnum'; value: ApiPartnerTmsType; isFallback: boolean }
      | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
      | null
    credential?: {
      __typename?: 'CredentialNode'
      id: string
      username: string
      workflow: string
    } | null
    company?: { __typename?: 'CompanyNode'; id: string; name?: string | null } | null
    jobTemplates: {
      __typename?: 'JobTemplateNodeConnection'
      edges: Array<{
        __typename?: 'JobTemplateNodeEdge'
        node: {
          __typename?: 'JobTemplateNode'
          id: string
          name: string
          company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
        }
      }>
    }
  }>
}

export type GetApiEDocumentsFromJobQueryVariables = Exact<{
  jobId: Scalars['String']['input']
}>

export type GetApiEDocumentsFromJobQuery = {
  __typename?: 'Query'
  apiEDocuments: Array<{
    __typename?: 'ApiEDocumentNode'
    id: string
    s3Uri?: string | null
    eDocUrl?: string | null
    filename?: string | null
    apiExportEdocActivity?: { __typename?: 'ApiExportEDocActivityNode'; id: string } | null
    apiImportEdocActivity?: {
      __typename?: 'ApiImportEDocActivityNode'
      id: string
      apiEntityType: ApiEntityType
    } | null
  }>
}

export type ApiIngestionAttachmentSignedUrlQueryVariables = Exact<{
  apiIngestionAttachmentId: Scalars['UUID']['input']
}>

export type ApiIngestionAttachmentSignedUrlQuery = {
  __typename?: 'Query'
  apiIngestionAttachmentSignedUrl: string
}

export type FindChainIoMatchesFromSoaLineItemsQueryVariables = Exact<{
  jobId: Scalars['UUID']['input']
  inputSoaLineItems: Array<InputSoaLineItem> | InputSoaLineItem
}>

export type FindChainIoMatchesFromSoaLineItemsQuery = {
  __typename?: 'Query'
  findChainIoMatchesFromSoaLineItems: {
    __typename?: 'SoaLineItemModelMatches'
    chainIoShipments: Array<{
      __typename?: 'ChainIOShipmentNode'
      id: string
      forwarderReference?: string | null
    }>
    chainIoConsolidations: Array<{
      __typename?: 'ChainIOConsolidationNode'
      id: string
      forwarderReference?: string | null
    }>
    chainIoCustomsDeclarations: Array<{
      __typename?: 'ChainIOCustomsDeclarationNode'
      id: string
      forwarderReference?: string | null
    }>
  }
}

export type GetEDocumentsFromJobQueryVariables = Exact<{
  jobId: Scalars['String']['input']
}>

export type GetEDocumentsFromJobQuery = {
  __typename?: 'Query'
  eDocuments: Array<{
    __typename?: 'EDocumentNode'
    id: string
    path: string
    eDocUrl?: string | null
    filename: string
    referenceNumber?: string | null
    isPublished: boolean
    dataTargetType:
      | { __typename?: 'EDocsDataTargetTypeEnum'; value: EDocsDataTargetType; isFallback: boolean }
      | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
    cargowiseFileType?: {
      __typename?: 'CargowiseFileTypeNode'
      id: string
      code: string
      description: string
    } | null
    filePage?: {
      __typename?: 'FilePageNode'
      id: string
      isInCw: boolean
      document?: {
        __typename?: 'DocumentNode'
        id: string
        documentType: {
          __typename?: 'DocumentTypeNode'
          id: string
          cargowiseFileType?: {
            __typename?: 'CargowiseFileTypeNode'
            id: string
            code: string
            description: string
          } | null
        }
      } | null
    } | null
  }>
}

export type CargowiseConfigsQueryVariables = Exact<{ [key: string]: never }>

export type CargowiseConfigsQuery = {
  __typename?: 'Query'
  cargowiseConfigs: Array<{
    __typename?: 'CargowiseConfigNode'
    id: string
    name: string
    defaultTimezoneLocationId?: string | null
    code: string
    countryCode?: string | null
    enterpriseId: string
    serverId: string
    integrationId: string
    outboundPass?: string | null
    dbPass?: string | null
    dbUser?: string | null
    dbName?: string | null
    dbDirectConnect: boolean
    remoteDbHostname?: string | null
    remoteDbPort?: number | null
    pollDatabase: boolean
    proxyUrls?: Array<string> | null
    connectorType?: CwConnectorType | null
    forceFetch: boolean
    defaultTimezoneLocation?: {
      __typename?: 'GeoLocationNode'
      id: string
      code: string
      locodeName?: string | null
      timezoneName?: string | null
    } | null
    externApiCredential?: {
      __typename?: 'CredentialNode'
      id: string
      username: string
      workflow: string
    } | null
    company?: { __typename?: 'CompanyNode'; id: string; name?: string | null } | null
    jobTemplates: {
      __typename?: 'JobTemplateNodeConnection'
      edges: Array<{
        __typename?: 'JobTemplateNodeEdge'
        node: {
          __typename?: 'JobTemplateNode'
          id: string
          name: string
          company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
        }
      }>
    }
  }>
}

export type CargowiseFileTypesQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
}>

export type CargowiseFileTypesQuery = {
  __typename?: 'Query'
  cargowiseFileTypes: Array<{ __typename?: 'CargowiseFileTypeNode'; id: string; code: string }>
}

export type TransactionAsyncBatchQueryVariables = Exact<{
  transactionAsyncBatchId: Scalars['String']['input']
}>

export type TransactionAsyncBatchQuery = {
  __typename?: 'Query'
  transactionAsyncBatch: {
    __typename?: 'TransactionAsyncBatchNode'
    id: string
    jobId?: string | null
    numTasks: number
    numDone: number
    status: TransactionAsyncStatus
    transactionAsyncTasks: {
      __typename?: 'TransactionAsyncTaskNodeConnection'
      edges: Array<{
        __typename?: 'TransactionAsyncTaskNodeEdge'
        node: {
          __typename?: 'TransactionAsyncTaskNode'
          id: string
          status: TransactionAsyncStatus
          invoiceNumber?: string | null
          errorMessage?: string | null
          processingLog?: string | null
          cargowiseModule?: string | null
        }
      }>
    }
  }
}

export type CheckShipmentInfoAsyncBatchQueryVariables = Exact<{
  checkShipmentInfoAsyncBatchId: Scalars['UUID']['input']
}>

export type CheckShipmentInfoAsyncBatchQuery = {
  __typename?: 'Query'
  checkShipmentInfoAsyncBatch: {
    __typename?: 'CheckShipmentInfoAsyncBatchNode'
    id: string
    jobId?: string | null
    numTasks: number
    numDone: number
    status: CheckShipmentInfoAsyncStatus
    errorMessage?: string | null
    dateCreated: string
  }
}

export type SoaJobExpectedChargesQueryVariables = Exact<{
  checkShipmentInfoAsyncBatchId: Scalars['UUID']['input']
}>

export type SoaJobExpectedChargesQuery = {
  __typename?: 'Query'
  soaJobExpectedCharges: Array<{
    __typename?: 'SOALineItemExpectedCharges'
    invoiceNumber: string
    shipmentNumber: string
    matchCriteriaFindShipmentReconResultPairList: Array<{
      __typename?: 'MatchCriteriaFindShipmentReconResultPair'
      checkShipmentInfoMatchCriteria: {
        __typename?: 'CheckShipmentInfoMatchCriteriaNode'
        id: string
        referenceNumber?: string | null
        hblNumber?: string | null
        orderNumber?: string | null
        consolNumber?: string | null
        mblNumber?: string | null
        carrierBookingNumber?: string | null
        containerNumber?: string | null
        cargowiseModule?: string | null
        errorMessage?: string | null
        chainIoShipment?: {
          __typename?: 'ChainIOShipmentNode'
          id: string
          forwarderReference?: string | null
        } | null
      }
      successfulFindShipmentReconResult?: {
        __typename?: 'FindShipmentReconResultNode'
        id: string
        success: boolean
        referenceNo?: string | null
        hblNo?: string | null
        mblNo?: string | null
        carrierBookingNo?: string | null
        containerNo?: string | null
        consolNo?: string | null
        orderNo?: string | null
        isMaximalAmongDuplicates?: boolean | null
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        chainIoShipment?: {
          __typename?: 'ChainIOShipmentNode'
          id: string
          forwarderReference?: string | null
          dateModified: string
          houseBill?: string | null
          masterBill?: string | null
          carrierBookingNumber?: string | null
          bookingReference?: string | null
          containers: {
            __typename?: 'ChainIOContainerNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOContainerNodeEdge'
              node: {
                __typename?: 'ChainIOContainerNode'
                id: string
                containerNumber?: string | null
              }
            }>
          }
          chainIoConsolidations: {
            __typename?: 'ChainIOConsolidationNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOConsolidationNodeEdge'
              node: {
                __typename?: 'ChainIOConsolidationNode'
                id: string
                forwarderReference?: string | null
              }
            }>
          }
          orders: {
            __typename?: 'ChainIOOrderNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOOrderNodeEdge'
              node: {
                __typename?: 'ChainIOOrderNode'
                id: string
                orderReference?: string | null
                sequence?: number | null
                orderNumberSplit?: number | null
                orderStatus?: string | null
                orderDate?: string | null
                orderBuyer?: {
                  __typename?: 'ChainIOAddressNode'
                  id: string
                  sourcePartyId?: string | null
                } | null
                orderSupplier?: {
                  __typename?: 'ChainIOAddressNode'
                  id: string
                  sourcePartyId?: string | null
                } | null
                orderLines: {
                  __typename?: 'ChainIOOrderLineNodeConnection'
                  edges: Array<{
                    __typename?: 'ChainIOOrderLineNodeEdge'
                    node: {
                      __typename?: 'ChainIOOrderLineNode'
                      id: string
                      orderLineNumber?: number | null
                      partNumber?: string | null
                      description?: string | null
                      containerNumber?: string | null
                    }
                  }>
                }
              }
            }>
          }
          additionalReferences: {
            __typename?: 'ChainIOAdditionalReferenceNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOAdditionalReferenceNodeEdge'
              node: {
                __typename?: 'ChainIOAdditionalReferenceNode'
                id: string
                referenceType?: string | null
                referenceNumber?: string | null
              }
            }>
          }
          customFields: {
            __typename?: 'ChainIOCustomFieldNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOCustomFieldNodeEdge'
              node: {
                __typename?: 'ChainIOCustomFieldNode'
                id: string
                key?: string | null
                value?: string | null
              }
            }>
          }
          shipmentLegs: {
            __typename?: 'ChainIOShipmentLegNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOShipmentLegNodeEdge'
              node: {
                __typename?: 'ChainIOShipmentLegNode'
                id: string
                legSequenceNumber: number
                transportMode?: string | null
                legType?: string | null
                vesselName?: string | null
                voyageNumber?: string | null
                departureEstimated?: string | null
                arrivalEstimated?: string | null
                bookingStatus?: string | null
                ladingPort?: {
                  __typename?: 'ChainIOLocodeNode'
                  id: string
                  unlocode?: string | null
                } | null
                arrivalPort?: {
                  __typename?: 'ChainIOLocodeNode'
                  id: string
                  unlocode?: string | null
                } | null
                legCarrier?: {
                  __typename?: 'ChainIOAddressNode'
                  id: string
                  name?: string | null
                } | null
              }
            }>
          }
          packingLines: {
            __typename?: 'ChainIOPackingLineNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOPackingLineNodeEdge'
              node: {
                __typename?: 'ChainIOPackingLineNode'
                id: string
                packs?: string | null
                packsUom?: string | null
                containerNumber?: string | null
                weight?: string | null
                weightUom?: string | null
                volume?: string | null
                volumeUom?: string | null
                referenceNumber?: string | null
                importReferenceNumber?: string | null
                exportReferenceNumber?: string | null
                descriptionOfGoods?: string | null
                marksAndNumbers?: string | null
              }
            }>
          }
          localClient?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
          shipper?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
          consignee?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
          supplier?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
          importer?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
          pickupTransportCompany?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
          deliveryTransportCompany?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
          pickupFrom?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
          deliveryTo?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
        } | null
        chainIoConsolidation?: {
          __typename?: 'ChainIOConsolidationNode'
          id: string
          forwarderReference?: string | null
          dateModified: string
          masterBill?: string | null
          carrierBookingRef?: string | null
          consolCosts: {
            __typename?: 'ChainIOConsolCostNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOConsolCostNodeEdge'
              node: {
                __typename?: 'ChainIOConsolCostNode'
                id: string
                chargeCode?: string | null
                chargeDescription?: string | null
                amount?: string | null
                currency?: string | null
                creditorCode?: string | null
                invoiceNumber?: string | null
                taxRateCode?: string | null
                taxRate?: string | null
                isPosted?: boolean | null
              }
            }>
          }
          containers: {
            __typename?: 'ChainIOContainerNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOContainerNodeEdge'
              node: {
                __typename?: 'ChainIOContainerNode'
                id: string
                containerNumber?: string | null
                containerCount?: number | null
                seal1?: string | null
                containerMode?: string | null
                typeCode?: string | null
                deliveryMode?: string | null
                sealedBy?: string | null
              }
            }>
          }
          shipmentLegs: {
            __typename?: 'ChainIOShipmentLegNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOShipmentLegNodeEdge'
              node: {
                __typename?: 'ChainIOShipmentLegNode'
                id: string
                legSequenceNumber: number
                transportMode?: string | null
                legType?: string | null
                vesselName?: string | null
                voyageNumber?: string | null
                departureEstimated?: string | null
                arrivalEstimated?: string | null
                bookingStatus?: string | null
                ladingPort?: {
                  __typename?: 'ChainIOLocodeNode'
                  id: string
                  unlocode?: string | null
                } | null
                arrivalPort?: {
                  __typename?: 'ChainIOLocodeNode'
                  id: string
                  unlocode?: string | null
                } | null
                legCarrier?: {
                  __typename?: 'ChainIOAddressNode'
                  id: string
                  name?: string | null
                } | null
              }
            }>
          }
          shipments: {
            __typename?: 'ChainIOShipmentNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOShipmentNodeEdge'
              node: {
                __typename?: 'ChainIOShipmentNode'
                id: string
                forwarderReference?: string | null
                houseBill?: string | null
                orders: {
                  __typename?: 'ChainIOOrderNodeConnection'
                  edges: Array<{
                    __typename?: 'ChainIOOrderNodeEdge'
                    node: {
                      __typename?: 'ChainIOOrderNode'
                      id: string
                      orderReference?: string | null
                    }
                  }>
                }
              }
            }>
          }
          carrier?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
          creditor?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
          coloadWith?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
          receivingAgent?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
          sendingAgent?: {
            __typename?: 'ChainIOAddressNode'
            id: string
            name?: string | null
            sourcePartyId?: string | null
            unlocode?: string | null
            address1?: string | null
            address2?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
        } | null
        chainIoCustomsDeclaration?: {
          __typename?: 'ChainIOCustomsDeclarationNode'
          id: string
          forwarderReference?: string | null
          dateModified: string
          houseBill?: string | null
          masterBill?: string | null
          bookingReference?: string | null
          containers: {
            __typename?: 'ChainIOContainerNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOContainerNodeEdge'
              node: {
                __typename?: 'ChainIOContainerNode'
                id: string
                containerNumber?: string | null
              }
            }>
          }
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            carrierBookingNumber?: string | null
            forwarderReference?: string | null
            chainIoConsolidations: {
              __typename?: 'ChainIOConsolidationNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOConsolidationNodeEdge'
                node: {
                  __typename?: 'ChainIOConsolidationNode'
                  id: string
                  forwarderReference?: string | null
                }
              }>
            }
          } | null
          orders: {
            __typename?: 'ChainIOOrderNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOOrderNodeEdge'
              node: { __typename?: 'ChainIOOrderNode'; id: string; orderReference?: string | null }
            }>
          }
        } | null
        findShipmentDuplicateReconResults: {
          __typename?: 'FindShipmentDuplicateReconResultNodeConnection'
          edges: Array<{
            __typename?: 'FindShipmentDuplicateReconResultNodeEdge'
            node: {
              __typename?: 'FindShipmentDuplicateReconResultNode'
              id: string
              chainIoShipment?: {
                __typename?: 'ChainIOShipmentNode'
                id: string
                forwarderReference?: string | null
                houseBill?: string | null
                masterBill?: string | null
                bookingReference?: string | null
                isCancelled: boolean
                tmsId?: string | null
                containers: {
                  __typename?: 'ChainIOContainerNodeConnection'
                  edges: Array<{
                    __typename?: 'ChainIOContainerNodeEdge'
                    node: {
                      __typename?: 'ChainIOContainerNode'
                      id: string
                      containerNumber?: string | null
                    }
                  }>
                }
                chainIoConsolidation?: {
                  __typename?: 'ChainIOConsolidationNode'
                  id: string
                  forwarderReference?: string | null
                  isCancelled?: boolean | null
                  tmsId?: string | null
                } | null
                orders: {
                  __typename?: 'ChainIOOrderNodeConnection'
                  edges: Array<{
                    __typename?: 'ChainIOOrderNodeEdge'
                    node: {
                      __typename?: 'ChainIOOrderNode'
                      id: string
                      orderReference?: string | null
                    }
                  }>
                }
              } | null
              chainIoConsolidation?: {
                __typename?: 'ChainIOConsolidationNode'
                id: string
                forwarderReference?: string | null
                isCancelled?: boolean | null
                tmsId?: string | null
              } | null
              chainIoCustomsDeclaration?: {
                __typename?: 'ChainIOCustomsDeclarationNode'
                id: string
                isCancelled?: boolean | null
                forwarderReference?: string | null
                tmsId?: string | null
              } | null
            }
          }>
        }
      } | null
    }>
    shipmentStaffReconResults: Array<{
      __typename?: 'ShipmentStaffReconResultNode'
      id: string
      operationsStaffName?: string | null
      operationsStaffCode?: string | null
      salesStaffName?: string | null
      salesStaffCode?: string | null
    }>
    metadataReconResults: Array<{
      __typename?: 'MetadataReconResultNode'
      id: string
      invoiceValue?: string | null
      expectedValue?: string | null
      type:
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
      key:
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | {
            __typename?: 'MetadataReconResultKeyEnum'
            value: MetadataReconResultKey
            isFallback: boolean
          }
    }>
    expectedCharges: Array<{
      __typename?: 'ChargeDetail'
      id: string
      chainIoSiLineId?: string | null
      chargeCode?: string | null
      chargeDescription?: string | null
      chargeCost?: string | null
      currency?: string | null
      vendor?: string | null
      invoiceNumber?: string | null
      taxId?: string | null
      taxRate?: string | null
      invoiceDate?: string | null
      dueDate?: string | null
      isPosted?: boolean | null
      overseasSellAmount?: string | null
      sellCurrency?: string | null
    }>
  }>
}

export type FindConsolFromJobQueryVariables = Exact<{
  jobId: Scalars['UUID']['input']
}>

export type FindConsolFromJobQuery = {
  __typename?: 'Query'
  consolWithMatchCriteria: {
    __typename?: 'ConsolWithMatchCriteria'
    consolNo?: string | null
    mblNo?: string | null
    containerNo?: string | null
    carrierBookingNo?: string | null
    hblNo?: string | null
    shipmentNo?: string | null
    orderNo?: string | null
    chainIoConsol?: {
      __typename?: 'ChainIOConsolidationNode'
      id: string
      forwarderReference?: string | null
    } | null
  }
}

export type FindShipmentFromJobQueryVariables = Exact<{
  jobId: Scalars['UUID']['input']
}>

export type FindShipmentFromJobQuery = {
  __typename?: 'Query'
  shipmentWithMatchCriteria: {
    __typename?: 'ShipmentWithMatchCriteria'
    shipmentNo?: string | null
    hblNo?: string | null
    consolNo?: string | null
    mblNo?: string | null
    carrierBookingNo?: string | null
    containerNo?: string | null
    orderNo?: string | null
    chainIoShipment?: {
      __typename?: 'ChainIOShipmentNode'
      id: string
      forwarderReference?: string | null
    } | null
  }
}

export type FindCustomsDeclarationFromJobQueryVariables = Exact<{
  jobId: Scalars['UUID']['input']
}>

export type FindCustomsDeclarationFromJobQuery = {
  __typename?: 'Query'
  customsDeclarationWithMatchCriteria: {
    __typename?: 'CustomsDeclarationWithMatchCriteria'
    referenceNo?: string | null
    hblNo?: string | null
    consolNo?: string | null
    mblNo?: string | null
    carrierBookingNo?: string | null
    containerNo?: string | null
    orderNo?: string | null
    chainIoCustomsDeclaration?: {
      __typename?: 'ChainIOCustomsDeclarationNode'
      id: string
      forwarderReference?: string | null
    } | null
  }
}

export type GetPullEDocsBatchQueryVariables = Exact<{
  batchPullId: Scalars['UUID']['input']
  totalPulled: Scalars['Int']['input']
}>

export type GetPullEDocsBatchQuery = {
  __typename?: 'Query'
  pullEDocsBatch: {
    __typename?: 'PullEDocsBatch'
    batchPullId?: string | null
    done?: boolean | null
    successes: Array<string>
    failures: Array<string>
    exceptions: Array<string>
  }
}

export type GetPushEDocsBatchQueryVariables = Exact<{
  batchPushId: Scalars['UUID']['input']
  totalPushed: Scalars['Int']['input']
}>

export type GetPushEDocsBatchQuery = {
  __typename?: 'Query'
  pushEDocsBatch: {
    __typename?: 'PushEDocsBatch'
    batchPushId?: string | null
    done?: boolean | null
    successes: Array<string>
    failures: Array<string>
    errorMessages: Array<string>
  }
}

export type GetRecentCargowiseOutboundsQueryVariables = Exact<{
  inputCwOutboundFilter: InputCargowiseOutboundFilter
}>

export type GetRecentCargowiseOutboundsQuery = {
  __typename?: 'Query'
  recentCargowiseOutbounds: Array<{
    __typename?: 'RawCargowiseOutboundNode'
    id: string
    dateCreated: string
    integrationId?: string | null
    signedViewUrl: string
    processed: boolean
  }>
}

export type SearchShipmentsQueryVariables = Exact<{
  cargowiseModule: Scalars['String']['input']
  jobTemplateId: Scalars['UUID']['input']
  searchStr: Scalars['String']['input']
}>

export type SearchShipmentsQuery = {
  __typename?: 'Query'
  searchShipments: Array<
    | {
        __typename?: 'ChainIOConsolidationNode'
        id: string
        dateCreated: string
        tmsId?: string | null
        forwarderReference?: string | null
        carrierBookingRef?: string | null
        masterBill?: string | null
        containers: {
          __typename?: 'ChainIOContainerNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOContainerNodeEdge'
            node: {
              __typename?: 'ChainIOContainerNode'
              id: string
              containerNumber?: string | null
            }
          }>
        }
        shipments: {
          __typename?: 'ChainIOShipmentNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOShipmentNodeEdge'
            node: {
              __typename?: 'ChainIOShipmentNode'
              id: string
              tmsId?: string | null
              forwarderReference?: string | null
              houseBill?: string | null
              masterBill?: string | null
              orders: {
                __typename?: 'ChainIOOrderNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOOrderNodeEdge'
                  node: {
                    __typename?: 'ChainIOOrderNode'
                    id: string
                    orderReference?: string | null
                  }
                }>
              }
            }
          }>
        }
      }
    | {
        __typename?: 'ChainIOCustomsDeclarationNode'
        id: string
        dateCreated: string
        forwarderReference?: string | null
        chainIoShipment?: {
          __typename?: 'ChainIOShipmentNode'
          id: string
          tmsId?: string | null
          forwarderReference?: string | null
          houseBill?: string | null
          masterBill?: string | null
          orders: {
            __typename?: 'ChainIOOrderNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOOrderNodeEdge'
              node: { __typename?: 'ChainIOOrderNode'; id: string; orderReference?: string | null }
            }>
          }
          containers: {
            __typename?: 'ChainIOContainerNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOContainerNodeEdge'
              node: {
                __typename?: 'ChainIOContainerNode'
                id: string
                containerNumber?: string | null
              }
            }>
          }
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            tmsId?: string | null
            forwarderReference?: string | null
            carrierBookingRef?: string | null
            masterBill?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'ChainIOShipmentNode'
        id: string
        dateCreated: string
        tmsId?: string | null
        forwarderReference?: string | null
        houseBill?: string | null
        masterBill?: string | null
        chainIoConsolidation?: {
          __typename?: 'ChainIOConsolidationNode'
          id: string
          tmsId?: string | null
          forwarderReference?: string | null
          carrierBookingRef?: string | null
          masterBill?: string | null
        } | null
        containers: {
          __typename?: 'ChainIOContainerNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOContainerNodeEdge'
            node: {
              __typename?: 'ChainIOContainerNode'
              id: string
              containerNumber?: string | null
            }
          }>
        }
        orders: {
          __typename?: 'ChainIOOrderNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOOrderNodeEdge'
            node: { __typename?: 'ChainIOOrderNode'; id: string; orderReference?: string | null }
          }>
        }
      }
  >
}

export type UserCompaniesQueryVariables = Exact<{ [key: string]: never }>

export type UserCompaniesQuery = {
  __typename?: 'Query'
  companies: Array<{ __typename?: 'CompanyNode'; id: string; name?: string | null }>
}

export type AllCompaniesQueryVariables = Exact<{ [key: string]: never }>

export type AllCompaniesQuery = {
  __typename?: 'Query'
  allCompanies: Array<{
    __typename?: 'CompanyNode'
    id: string
    name?: string | null
    hasAccessToInsights?: boolean | null
    apiPartners: {
      __typename?: 'ApiPartnerInterfaceConnection'
      edges: Array<{
        __typename?: 'ApiPartnerInterfaceEdge'
        node:
          | {
              __typename?: 'ApiPartnerNode'
              id: string
              name: string
              companyId?: string | null
              apiPartnerCode:
                | { __typename?: 'ApiPartnerCodeEnum'; value: ApiPartnerCode; isFallback: boolean }
                | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            }
          | {
              __typename?: 'CargowiseConfigNode'
              pollDatabase: boolean
              connectorType?: CwConnectorType | null
              id: string
              name: string
              companyId?: string | null
              apiPartnerCode:
                | { __typename?: 'ApiPartnerCodeEnum'; value: ApiPartnerCode; isFallback: boolean }
                | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            }
      }>
    }
  }>
}

export type CompanyJobTemplatesQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
  queryString: Scalars['String']['input']
}>

export type CompanyJobTemplatesQuery = {
  __typename?: 'Query'
  companyJobTemplates: Array<{
    __typename?: 'JobTemplateNode'
    id: string
    name: string
    documentTypes: {
      __typename?: 'DocumentTypeNodeConnection'
      edges: Array<{
        __typename?: 'DocumentTypeNodeEdge'
        node: { __typename?: 'DocumentTypeNode'; id: string; name: string }
      }>
    }
  }>
}

export type CompanyQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type CompanyQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'CompanyNode'
    id: string
    name?: string | null
    metabaseDashboardId?: string | null
    hasAccessToInsights?: boolean | null
    shipperFacingSlug: string
    logo?: string | null
    primaryThemeColor?: string | null
    usesChargeCodeV2?: boolean | null
    enableEdocsTab?: boolean | null
    cargowiseConfigs: {
      __typename?: 'CargowiseConfigNodeConnection'
      edges: Array<{
        __typename?: 'CargowiseConfigNodeEdge'
        node: {
          __typename?: 'CargowiseConfigNode'
          id: string
          code: string
          enterpriseId: string
          serverId: string
          integrationId: string
        }
      }>
    }
    apiPartners: {
      __typename?: 'ApiPartnerInterfaceConnection'
      edges: Array<{
        __typename?: 'ApiPartnerInterfaceEdge'
        node:
          | { __typename?: 'ApiPartnerNode'; id: string; name: string }
          | { __typename?: 'CargowiseConfigNode'; id: string; name: string }
      }>
    }
  }
}

export type SignCompanyLogoUriQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
}>

export type SignCompanyLogoUriQuery = { __typename?: 'Query'; signCompanyLogoUri: string }

export type CredentialsQueryVariables = Exact<{ [key: string]: never }>

export type CredentialsQuery = {
  __typename?: 'Query'
  credentials: Array<{
    __typename?: 'CredentialNode'
    id: string
    username: string
    apiEndpoint?: string | null
    workflow: string
    verifySsl: boolean
    hostOverride?: string | null
    jobTemplates: {
      __typename?: 'JobTemplateNodeConnection'
      edges: Array<{
        __typename?: 'JobTemplateNodeEdge'
        node: { __typename?: 'JobTemplateNode'; id: string; name: string }
      }>
    }
  }>
}

export type CustomDatabaseTypesQueryVariables = Exact<{
  query: Scalars['String']['input']
}>

export type CustomDatabaseTypesQuery = {
  __typename?: 'Query'
  customDatabaseTypes: Array<{
    __typename?: 'CustomDatabaseTypeNode'
    id: string
    name: string
    tmsType?:
      | { __typename?: 'ApiPartnerTmsTypeEnum'; value: ApiPartnerTmsType; isFallback: boolean }
      | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
      | null
    columns: {
      __typename?: 'CustomDatabaseColumnNodeConnection'
      edges: Array<{
        __typename?: 'CustomDatabaseColumnNodeEdge'
        node: {
          __typename?: 'CustomDatabaseColumnNode'
          id: string
          displayName: string
          exportKey: string
          dateDeleted?: string | null
        }
      }>
    }
  }>
}

export type CustomDatabasesQueryVariables = Exact<{
  query: Scalars['String']['input']
  apiPartnerId: Scalars['UUID']['input']
}>

export type CustomDatabasesQuery = {
  __typename?: 'Query'
  customDatabases: Array<{
    __typename?: 'CustomDatabaseNode'
    id: string
    name: string
    customDatabaseTypeId: string
    customDatabaseType: {
      __typename?: 'CustomDatabaseTypeNode'
      id: string
      name: string
      tmsType?:
        | { __typename?: 'ApiPartnerTmsTypeEnum'; value: ApiPartnerTmsType; isFallback: boolean }
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | null
      columns: {
        __typename?: 'CustomDatabaseColumnNodeConnection'
        edges: Array<{
          __typename?: 'CustomDatabaseColumnNodeEdge'
          node: {
            __typename?: 'CustomDatabaseColumnNode'
            id: string
            displayName: string
            exportKey: string
            dateDeleted?: string | null
          }
        }>
      }
    }
    rows: {
      __typename?: 'CustomDatabaseRowNodeConnection'
      edges: Array<{
        __typename?: 'CustomDatabaseRowNodeEdge'
        node: {
          __typename?: 'CustomDatabaseRowNode'
          id: string
          dateDeleted?: string | null
          cells: {
            __typename?: 'CustomDatabaseCellNodeConnection'
            edges: Array<{
              __typename?: 'CustomDatabaseCellNodeEdge'
              node: {
                __typename?: 'CustomDatabaseCellNode'
                id: string
                value?: string | null
                customDatabaseColumnId: string
              }
            }>
          }
        }
      }>
    }
  }>
}

export type ListCompanyDashboardsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['UUID']['input']>
  tabType?: InputMaybe<Scalars['String']['input']>
  shouldWhitelist?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ListCompanyDashboardsQuery = {
  __typename?: 'Query'
  listCompanyDashboards: Array<{
    __typename?: 'DashboardNode'
    name: string
    displayName: string
    dateCreated: string
    dateModified: string
    id: string
    isDefault: boolean
    iframeOverrideUrl?: string | null
    publicUrl?: string | null
    selectors: Array<string>
    basicFilterFields?: Array<string> | null
    dashboardTab:
      | { __typename?: 'DashboardTabTypeEnum'; value: DashboardTabType; isFallback: boolean }
      | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
  }>
}

export type StandardDocumentTypesQueryVariables = Exact<{ [key: string]: never }>

export type StandardDocumentTypesQuery = {
  __typename?: 'Query'
  standardDocumentTypes: Array<{
    __typename?: 'DocumentTypeNode'
    id: string
    name: string
    isStandard: boolean
    collapsible: boolean
    tableShowsPreset: boolean
    autofillExtractorKey?: AutofillExtractorKey | null
    isEDocPublishedByDefault: boolean
    cargowiseFileTypeId?: string | null
    cargowiseFileType?: {
      __typename?: 'CargowiseFileTypeNode'
      id: string
      code: string
      description: string
    } | null
    documentTypeFieldGroups: {
      __typename?: 'DocumentTypeFieldGroupNodeConnection'
      edges: Array<{
        __typename?: 'DocumentTypeFieldGroupNodeEdge'
        node: {
          __typename?: 'DocumentTypeFieldGroupNode'
          id: string
          fieldGroup: {
            __typename?: 'FieldGroupNode'
            id: string
            key: string
            name: string
            repeatable: boolean
            autofillKey: string
            fields: {
              __typename?: 'FieldNodeConnection'
              edges: Array<{
                __typename?: 'FieldNodeEdge'
                node: {
                  __typename?: 'FieldNode'
                  id: string
                  name: string
                  key: string
                  autofillKey: string
                  validatorRegex?: string | null
                  validatorDescription?: string | null
                  dateFormatString?: string | null
                  required: boolean
                  rows: number
                  values?: Array<string> | null
                  allowFreeText: boolean
                  columnOrder: number
                  defaultValue?: string | null
                  partialTableAllowed: boolean
                  invalidCharsRegex?: string | null
                  fieldType?:
                    | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
                    | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
                    | null
                  searchableRecord?: {
                    __typename?: 'SearchableRecordNode'
                    id: string
                    model?: SearchableRecordModel | null
                    searchKey: string
                    displayKey: string
                  } | null
                }
              }>
            }
          }
        }
      }>
    }
  }>
}

export type DocumentTableFragment = {
  __typename?: 'DocumentTableNode'
  id: string
  orderPriority: number
  fieldGroupId: string
  nextTableId?: string | null
  documentId: string
  editedByUser?: boolean | null
  document: {
    __typename?: 'DocumentNode'
    id: string
    documentType: { __typename?: 'DocumentTypeNode'; id: string; tableShowsPreset: boolean }
  }
  fieldGroup: {
    __typename?: 'FieldGroupNode'
    id: string
    key: string
    autofillKey: string
    name: string
    fields: {
      __typename?: 'FieldNodeConnection'
      edges: Array<{
        __typename?: 'FieldNodeEdge'
        node: {
          __typename?: 'FieldNode'
          id: string
          key: string
          autofillKey: string
          name: string
          required: boolean
          validatorRegex?: string | null
          validatorDescription?: string | null
          defaultValue?: string | null
          dateFormatString?: string | null
          allowFreeText: boolean
          partialTableAllowed: boolean
          invalidCharsRegex?: string | null
          values?: Array<string> | null
          columnOrder: number
          fieldType?:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
            | null
          searchableRecord?: {
            __typename?: 'SearchableRecordNode'
            id: string
            searchKey: string
            displayKey: string
            model?: SearchableRecordModel | null
          } | null
        }
      }>
    }
  }
  documentTableColumns: {
    __typename?: 'DocumentTableColumnNodeConnection'
    edges: Array<{
      __typename?: 'DocumentTableColumnNodeEdge'
      node: {
        __typename?: 'DocumentTableColumnNode'
        id: string
        fieldId: string
        index: number
        left: number
        width: number
        top: number
        height: number
        field: {
          __typename?: 'FieldNode'
          id: string
          key: string
          name: string
          searchableRecord?: {
            __typename?: 'SearchableRecordNode'
            id: string
            searchKey: string
            displayKey: string
            model?: SearchableRecordModel | null
          } | null
        }
      }
    }>
  }
  documentFieldGroups: {
    __typename?: 'DocumentFieldGroupNodeConnection'
    edges: Array<{
      __typename?: 'DocumentFieldGroupNodeEdge'
      node: {
        __typename?: 'DocumentFieldGroupNode'
        id: string
        rowOrderPriority?: number | null
        document: { __typename?: 'DocumentNode'; id: string }
        documentFields: {
          __typename?: 'DocumentFieldNodeConnection'
          edges: Array<{
            __typename?: 'DocumentFieldNodeEdge'
            node: {
              __typename?: 'DocumentFieldNode'
              id: string
              value: string
              confirmed?: boolean | null
              top?: number | null
              left?: number | null
              width?: number | null
              height?: number | null
              confidenceScore?: number | null
              field: {
                __typename?: 'FieldNode'
                id: string
                key: string
                name: string
                autofillKey: string
                searchableRecord?: {
                  __typename?: 'SearchableRecordNode'
                  id: string
                  searchKey: string
                  displayKey: string
                  model?: SearchableRecordModel | null
                } | null
              }
            }
          }>
        }
      }
    }>
  }
}

export type DocumentTablesQueryVariables = Exact<{
  jobId: Scalars['String']['input']
  editedByUser?: InputMaybe<Scalars['Boolean']['input']>
}>

export type DocumentTablesQuery = {
  __typename?: 'Query'
  documentTables: Array<{
    __typename?: 'DocumentTableNode'
    id: string
    orderPriority: number
    fieldGroupId: string
    nextTableId?: string | null
    documentId: string
    editedByUser?: boolean | null
    document: {
      __typename?: 'DocumentNode'
      id: string
      documentType: { __typename?: 'DocumentTypeNode'; id: string; tableShowsPreset: boolean }
    }
    fieldGroup: {
      __typename?: 'FieldGroupNode'
      id: string
      key: string
      autofillKey: string
      name: string
      fields: {
        __typename?: 'FieldNodeConnection'
        edges: Array<{
          __typename?: 'FieldNodeEdge'
          node: {
            __typename?: 'FieldNode'
            id: string
            key: string
            autofillKey: string
            name: string
            required: boolean
            validatorRegex?: string | null
            validatorDescription?: string | null
            defaultValue?: string | null
            dateFormatString?: string | null
            allowFreeText: boolean
            partialTableAllowed: boolean
            invalidCharsRegex?: string | null
            values?: Array<string> | null
            columnOrder: number
            fieldType?:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
              | null
            searchableRecord?: {
              __typename?: 'SearchableRecordNode'
              id: string
              searchKey: string
              displayKey: string
              model?: SearchableRecordModel | null
            } | null
          }
        }>
      }
    }
    documentTableColumns: {
      __typename?: 'DocumentTableColumnNodeConnection'
      edges: Array<{
        __typename?: 'DocumentTableColumnNodeEdge'
        node: {
          __typename?: 'DocumentTableColumnNode'
          id: string
          fieldId: string
          index: number
          left: number
          width: number
          top: number
          height: number
          field: {
            __typename?: 'FieldNode'
            id: string
            key: string
            name: string
            searchableRecord?: {
              __typename?: 'SearchableRecordNode'
              id: string
              searchKey: string
              displayKey: string
              model?: SearchableRecordModel | null
            } | null
          }
        }
      }>
    }
    documentFieldGroups: {
      __typename?: 'DocumentFieldGroupNodeConnection'
      edges: Array<{
        __typename?: 'DocumentFieldGroupNodeEdge'
        node: {
          __typename?: 'DocumentFieldGroupNode'
          id: string
          rowOrderPriority?: number | null
          document: { __typename?: 'DocumentNode'; id: string }
          documentFields: {
            __typename?: 'DocumentFieldNodeConnection'
            edges: Array<{
              __typename?: 'DocumentFieldNodeEdge'
              node: {
                __typename?: 'DocumentFieldNode'
                id: string
                value: string
                confirmed?: boolean | null
                top?: number | null
                left?: number | null
                width?: number | null
                height?: number | null
                confidenceScore?: number | null
                field: {
                  __typename?: 'FieldNode'
                  id: string
                  key: string
                  name: string
                  autofillKey: string
                  searchableRecord?: {
                    __typename?: 'SearchableRecordNode'
                    id: string
                    searchKey: string
                    displayKey: string
                    model?: SearchableRecordModel | null
                  } | null
                }
              }
            }>
          }
        }
      }>
    }
  }>
}

export type DocumentTypesFieldGroupsQueryVariables = Exact<{
  ids: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
}>

export type DocumentTypesFieldGroupsQuery = {
  __typename?: 'Query'
  documentTypes: Array<{
    __typename?: 'DocumentTypeNode'
    id: string
    documentTypeFieldGroups: {
      __typename?: 'DocumentTypeFieldGroupNodeConnection'
      edges: Array<{
        __typename?: 'DocumentTypeFieldGroupNodeEdge'
        node: {
          __typename?: 'DocumentTypeFieldGroupNode'
          id: string
          fieldGroup: {
            __typename?: 'FieldGroupNode'
            id: string
            key: string
            name: string
            repeatable: boolean
            autofillKey: string
            fields: {
              __typename?: 'FieldNodeConnection'
              edges: Array<{
                __typename?: 'FieldNodeEdge'
                node: {
                  __typename?: 'FieldNode'
                  id: string
                  name: string
                  key: string
                  autofillKey: string
                  validatorRegex?: string | null
                  validatorDescription?: string | null
                  dateFormatString?: string | null
                  required: boolean
                  rows: number
                  values?: Array<string> | null
                  allowFreeText: boolean
                  columnOrder: number
                  defaultValue?: string | null
                  partialTableAllowed: boolean
                  invalidCharsRegex?: string | null
                  fieldType?:
                    | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
                    | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
                    | null
                  searchableRecord?: {
                    __typename?: 'SearchableRecordNode'
                    id: string
                    searchKey: string
                    displayKey: string
                    model?: SearchableRecordModel | null
                  } | null
                }
              }>
            }
          }
        }
      }>
    }
  }>
}

export type FieldGroupAttributesFragment = {
  __typename?: 'FieldGroupNode'
  id: string
  key: string
  name: string
  autofillKey: string
  repeatable: boolean
  fields: {
    __typename?: 'FieldNodeConnection'
    edges: Array<{
      __typename?: 'FieldNodeEdge'
      node: { __typename?: 'FieldNode'; id: string; name: string }
    }>
  }
}

export type AutofillKeysQueryVariables = Exact<{
  autofillExtractorKey: AutofillExtractorKey
  reconType: JobTemplateReconType
  documentTypeId: Scalars['String']['input']
}>

export type AutofillKeysQuery = {
  __typename?: 'Query'
  autofillKeys: {
    __typename?: 'AutofillKeysResult'
    autofillKeys: Array<string>
    lineItemFieldGroupAutofillKeys: Array<string>
  }
}

export type SearchableRecordsQueryVariables = Exact<{ [key: string]: never }>

export type SearchableRecordsQuery = {
  __typename?: 'Query'
  searchableRecords: Array<{
    __typename?: 'SearchableRecordOptions'
    searchKey: string
    displayKey: string
    model?: string | null
    apiUrl?: string | null
  }>
}

export type FileAttributesFragment = {
  __typename?: 'FileNode'
  id: string
  filename: string
  senderAddress?: string | null
  emailSubject?: string | null
  message?: string | null
  dateCreated: string
}

export type FilePageAttributesFragment = {
  __typename?: 'FilePageNode'
  id: string
  fileId: string
  status: FilePageStatus
  imageUrl?: string | null
  pageNumber: number
  dateCreated: string
  jobId?: string | null
  type: FilePageType
}

export type FilePageWithDocumentAttributesFragment = {
  __typename?: 'FilePageNode'
  id: string
  status: FilePageStatus
  imageUrl?: string | null
  pageNumber: number
  dateCreated: string
  fileId: string
  file: { __typename?: 'FileNode'; id: string; filename: string }
  document?: {
    __typename?: 'DocumentNode'
    id: string
    filePage: {
      __typename?: 'FilePageNode'
      id: string
      imageUrl?: string | null
      file: { __typename?: 'FileNode'; id: string; filename: string }
    }
    documentType: {
      __typename?: 'DocumentTypeNode'
      id: string
      name: string
      documentTypeFieldGroups: {
        __typename?: 'DocumentTypeFieldGroupNodeConnection'
        edges: Array<{
          __typename?: 'DocumentTypeFieldGroupNodeEdge'
          node: {
            __typename?: 'DocumentTypeFieldGroupNode'
            id: string
            fieldGroup: {
              __typename?: 'FieldGroupNode'
              id: string
              key: string
              name: string
              repeatable: boolean
              fields: {
                __typename?: 'FieldNodeConnection'
                edges: Array<{
                  __typename?: 'FieldNodeEdge'
                  node: { __typename?: 'FieldNode'; id: string; key: string; name: string }
                }>
              }
            }
          }
        }>
      }
    }
    documentFieldGroups: {
      __typename?: 'DocumentFieldGroupNodeConnection'
      edges: Array<{
        __typename?: 'DocumentFieldGroupNodeEdge'
        node: {
          __typename?: 'DocumentFieldGroupNode'
          id: string
          rowOrderPriority?: number | null
          document: { __typename?: 'DocumentNode'; id: string }
          documentFields: {
            __typename?: 'DocumentFieldNodeConnection'
            edges: Array<{
              __typename?: 'DocumentFieldNodeEdge'
              node: {
                __typename?: 'DocumentFieldNode'
                id: string
                value: string
                confidenceScore?: number | null
                top?: number | null
                left?: number | null
                width?: number | null
                height?: number | null
                field: {
                  __typename?: 'FieldNode'
                  id: string
                  name: string
                  key: string
                  valueExportMapping?: string | null
                }
              }
            }>
          }
          documentTable?: { __typename?: 'DocumentTableNode'; id: string } | null
          fieldGroup: {
            __typename?: 'FieldGroupNode'
            id: string
            key: string
            name: string
            autofillKey: string
            repeatable: boolean
            fields: {
              __typename?: 'FieldNodeConnection'
              edges: Array<{
                __typename?: 'FieldNodeEdge'
                node: { __typename?: 'FieldNode'; id: string; name: string }
              }>
            }
          }
        }
      }>
    }
  } | null
}

export type FilePagesQueryVariables = Exact<{
  filePageIds: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type FilePagesQuery = {
  __typename?: 'Query'
  filePagesConnection: {
    __typename?: 'FilePagesConnection'
    total: number
    items: Array<{
      __typename?: 'FilePageNode'
      id: string
      fileId: string
      status: FilePageStatus
      imageUrl?: string | null
      pageNumber: number
      dateCreated: string
      jobId?: string | null
      type: FilePageType
      file: {
        __typename?: 'FileNode'
        companyId: string
        id: string
        filename: string
        senderAddress?: string | null
        emailSubject?: string | null
        message?: string | null
        dateCreated: string
        company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
      }
    }>
  }
}

export type DashhboardFilesQueryVariables = Exact<{
  page: Scalars['Int']['input']
  query: Scalars['String']['input']
  companyId?: InputMaybe<Scalars['String']['input']>
  status: Scalars['String']['input']
}>

export type DashhboardFilesQuery = {
  __typename?: 'Query'
  fileConnection: {
    __typename?: 'FileConnection'
    total: number
    items: Array<{
      __typename?: 'FileNode'
      id: string
      filename: string
      senderAddress?: string | null
      emailSubject?: string | null
      message?: string | null
      dateCreated: string
      company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
      pages: {
        __typename?: 'FilePageNodeConnection'
        edges: Array<{
          __typename?: 'FilePageNodeEdge'
          node: {
            __typename?: 'FilePageNode'
            id: string
            fileId: string
            status: FilePageStatus
            imageUrl?: string | null
            pageNumber: number
            dateCreated: string
            jobId?: string | null
            type: FilePageType
          }
        }>
      }
    }>
  }
}

export type GetPageAsyncBatchQueryVariables = Exact<{
  id: Scalars['UUID']['input']
}>

export type GetPageAsyncBatchQuery = {
  __typename?: 'Query'
  pageAsyncBatch: {
    __typename?: 'PageAsyncBatchNode'
    id: string
    type: PageAsyncBatchType
    status: PageAsyncTaskStatus
    errorMessage?: string | null
    numDone?: number | null
    numTasks?: number | null
  }
}

export type GetFileSignedUrlQueryVariables = Exact<{
  filename: Scalars['String']['input']
  contentType: Scalars['String']['input']
}>

export type GetFileSignedUrlQuery = {
  __typename?: 'Query'
  fileSignedUrl: { __typename?: 'FileSignedUrl'; viewUrl: string; putUrl: string }
}

export type GeoLocationsQueryVariables = Exact<{
  query: Scalars['String']['input']
}>

export type GeoLocationsQuery = {
  __typename?: 'Query'
  geoLocations: Array<{
    __typename?: 'GeoLocationNode'
    id: string
    code: string
    locodeName?: string | null
    timezoneName?: string | null
  }>
}

export type CountByJobStatusQueryVariables = Exact<{ [key: string]: never }>

export type CountByJobStatusQuery = { __typename?: 'Query'; countByJobStatus: any }

export type GetJobDataLiteQueryVariables = Exact<{
  id: Scalars['UUID']['input']
}>

export type GetJobDataLiteQuery = {
  __typename?: 'Query'
  job: {
    __typename?: 'JobNode'
    id: string
    name: string
    message?: string | null
    status: JobStatus
    manualProduct: ManualProduct
    ownerId?: string | null
    qaId?: string | null
    externalStatus?: JobExternalStatus | null
    latestSavedReconAttemptId?: string | null
    externalAssignee?: { __typename?: 'UserNode'; id: string; email: string } | null
    jobTemplate: {
      __typename?: 'JobTemplateNode'
      id: string
      name: string
      reconType: JobTemplateReconType
      shipmentOpsTypes: Array<ShipmentOp>
      company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
      apiPartner?:
        | {
            __typename?: 'ApiPartnerNode'
            id: string
            name: string
            apiPartnerCode:
              | { __typename?: 'ApiPartnerCodeEnum'; value: ApiPartnerCode; isFallback: boolean }
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          }
        | {
            __typename?: 'CargowiseConfigNode'
            id: string
            name: string
            apiPartnerCode:
              | { __typename?: 'ApiPartnerCodeEnum'; value: ApiPartnerCode; isFallback: boolean }
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          }
        | null
    }
    pageAsyncBatches: {
      __typename?: 'PageAsyncBatchNodeConnection'
      edges: Array<{
        __typename?: 'PageAsyncBatchNodeEdge'
        node: { __typename?: 'PageAsyncBatchNode'; id: string; status: PageAsyncTaskStatus }
      }>
    }
    task?: {
      __typename?: 'TaskNode'
      id: string
      title: string
      message?: string | null
      taskReferenceId: string
      status: TaskStatus
      taskType: TaskType
      dateCreated: string
      dateReceived?: string | null
      dateConfirmed?: string | null
      taskSlaTimeJobRef?: {
        __typename?: 'TaskSlaTimeJobRefNode'
        id: string
        job?: { __typename?: 'JobNode'; id: string; dateCreated: string; slaTime: number } | null
      } | null
      company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
    } | null
  }
}

export type FieldFragment = {
  __typename?: 'FieldNode'
  id: string
  autofillKey: string
  key: string
  name: string
  required: boolean
  validatorRegex?: string | null
  validatorDescription?: string | null
  dateFormatString?: string | null
  defaultValue?: string | null
  allowFreeText: boolean
  partialTableAllowed: boolean
  invalidCharsRegex?: string | null
  values?: Array<string> | null
  columnOrder: number
  fieldType?:
    | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
    | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
    | null
  searchableRecord?: {
    __typename?: 'SearchableRecordNode'
    id: string
    searchKey: string
    displayKey: string
    model?: SearchableRecordModel | null
    apiUrl?: string | null
  } | null
}

export type FieldGroupFragment = {
  __typename?: 'FieldGroupNode'
  id: string
  name: string
  key: string
  autofillKey: string
  fields: {
    __typename?: 'FieldNodeConnection'
    edges: Array<{
      __typename?: 'FieldNodeEdge'
      node: {
        __typename?: 'FieldNode'
        id: string
        autofillKey: string
        key: string
        name: string
        required: boolean
        validatorRegex?: string | null
        validatorDescription?: string | null
        dateFormatString?: string | null
        defaultValue?: string | null
        allowFreeText: boolean
        partialTableAllowed: boolean
        invalidCharsRegex?: string | null
        values?: Array<string> | null
        columnOrder: number
        fieldType?:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
          | null
        searchableRecord?: {
          __typename?: 'SearchableRecordNode'
          id: string
          searchKey: string
          displayKey: string
          model?: SearchableRecordModel | null
          apiUrl?: string | null
        } | null
      }
    }>
  }
}

export type JobTableFragment = {
  __typename?: 'JobTableNode'
  id: string
  fieldGroup: {
    __typename?: 'FieldGroupNode'
    id: string
    repeatable: boolean
    name: string
    key: string
    autofillKey: string
    fields: {
      __typename?: 'FieldNodeConnection'
      edges: Array<{
        __typename?: 'FieldNodeEdge'
        node: {
          __typename?: 'FieldNode'
          id: string
          autofillKey: string
          key: string
          name: string
          required: boolean
          validatorRegex?: string | null
          validatorDescription?: string | null
          dateFormatString?: string | null
          defaultValue?: string | null
          allowFreeText: boolean
          partialTableAllowed: boolean
          invalidCharsRegex?: string | null
          values?: Array<string> | null
          columnOrder: number
          fieldType?:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
            | null
          searchableRecord?: {
            __typename?: 'SearchableRecordNode'
            id: string
            searchKey: string
            displayKey: string
            model?: SearchableRecordModel | null
            apiUrl?: string | null
          } | null
        }
      }>
    }
  }
  jobTableColumns: {
    __typename?: 'JobTableColumnNodeConnection'
    edges: Array<{
      __typename?: 'JobTableColumnNodeEdge'
      node: {
        __typename?: 'JobTableColumnNode'
        id: string
        index: number
        field: {
          __typename?: 'FieldNode'
          id: string
          autofillKey: string
          key: string
          name: string
          required: boolean
          validatorRegex?: string | null
          validatorDescription?: string | null
          dateFormatString?: string | null
          defaultValue?: string | null
          allowFreeText: boolean
          partialTableAllowed: boolean
          invalidCharsRegex?: string | null
          values?: Array<string> | null
          columnOrder: number
          fieldType?:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
            | null
          searchableRecord?: {
            __typename?: 'SearchableRecordNode'
            id: string
            searchKey: string
            displayKey: string
            model?: SearchableRecordModel | null
            apiUrl?: string | null
          } | null
        }
      }
    }>
  }
  jobTableLineItems: {
    __typename?: 'JobTableLineItemNodeConnection'
    edges: Array<{
      __typename?: 'JobTableLineItemNodeEdge'
      node: {
        __typename?: 'JobTableLineItemNode'
        id: string
        jobTableCells: {
          __typename?: 'JobTableCellNodeConnection'
          edges: Array<{
            __typename?: 'JobTableCellNodeEdge'
            node: {
              __typename?: 'JobTableCellNode'
              id: string
              documentId: string
              top?: number | null
              left?: number | null
              width?: number | null
              height?: number | null
              value: string
              field: { __typename?: 'FieldNode'; id: string; key: string; autofillKey: string }
            }
          }>
        }
      }
    }>
  }
}

export type GetJobDataQueryVariables = Exact<{
  id: Scalars['UUID']['input']
}>

export type GetJobDataQuery = {
  __typename?: 'Query'
  job: {
    __typename?: 'JobNode'
    id: string
    name: string
    message?: string | null
    status: JobStatus
    manualProduct: ManualProduct
    ownerId?: string | null
    qaId?: string | null
    owner?: { __typename?: 'UserNode'; id: string } | null
    qa?: { __typename?: 'UserNode'; id: string } | null
    jobTable?: {
      __typename?: 'JobTableNode'
      id: string
      fieldGroup: {
        __typename?: 'FieldGroupNode'
        id: string
        repeatable: boolean
        name: string
        key: string
        autofillKey: string
        fields: {
          __typename?: 'FieldNodeConnection'
          edges: Array<{
            __typename?: 'FieldNodeEdge'
            node: {
              __typename?: 'FieldNode'
              id: string
              autofillKey: string
              key: string
              name: string
              required: boolean
              validatorRegex?: string | null
              validatorDescription?: string | null
              dateFormatString?: string | null
              defaultValue?: string | null
              allowFreeText: boolean
              partialTableAllowed: boolean
              invalidCharsRegex?: string | null
              values?: Array<string> | null
              columnOrder: number
              fieldType?:
                | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
                | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
                | null
              searchableRecord?: {
                __typename?: 'SearchableRecordNode'
                id: string
                searchKey: string
                displayKey: string
                model?: SearchableRecordModel | null
                apiUrl?: string | null
              } | null
            }
          }>
        }
      }
      jobTableColumns: {
        __typename?: 'JobTableColumnNodeConnection'
        edges: Array<{
          __typename?: 'JobTableColumnNodeEdge'
          node: {
            __typename?: 'JobTableColumnNode'
            id: string
            index: number
            field: {
              __typename?: 'FieldNode'
              id: string
              autofillKey: string
              key: string
              name: string
              required: boolean
              validatorRegex?: string | null
              validatorDescription?: string | null
              dateFormatString?: string | null
              defaultValue?: string | null
              allowFreeText: boolean
              partialTableAllowed: boolean
              invalidCharsRegex?: string | null
              values?: Array<string> | null
              columnOrder: number
              fieldType?:
                | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
                | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
                | null
              searchableRecord?: {
                __typename?: 'SearchableRecordNode'
                id: string
                searchKey: string
                displayKey: string
                model?: SearchableRecordModel | null
                apiUrl?: string | null
              } | null
            }
          }
        }>
      }
      jobTableLineItems: {
        __typename?: 'JobTableLineItemNodeConnection'
        edges: Array<{
          __typename?: 'JobTableLineItemNodeEdge'
          node: {
            __typename?: 'JobTableLineItemNode'
            id: string
            jobTableCells: {
              __typename?: 'JobTableCellNodeConnection'
              edges: Array<{
                __typename?: 'JobTableCellNodeEdge'
                node: {
                  __typename?: 'JobTableCellNode'
                  id: string
                  documentId: string
                  top?: number | null
                  left?: number | null
                  width?: number | null
                  height?: number | null
                  value: string
                  field: { __typename?: 'FieldNode'; id: string; key: string; autofillKey: string }
                }
              }>
            }
          }
        }>
      }
    } | null
    pageAsyncBatches: {
      __typename?: 'PageAsyncBatchNodeConnection'
      edges: Array<{
        __typename?: 'PageAsyncBatchNodeEdge'
        node: { __typename?: 'PageAsyncBatchNode'; id: string; status: PageAsyncTaskStatus }
      }>
    }
    jobTemplate: {
      __typename?: 'JobTemplateNode'
      id: string
      name: string
      reconType: JobTemplateReconType
      subtotalsRowEnabled?: boolean | null
      mainTabEnabled?: boolean | null
      shipmentOpsTypes: Array<ShipmentOp>
      company: {
        __typename?: 'CompanyNode'
        id: string
        name?: string | null
        usesChargeCodeV2?: boolean | null
      }
      cargowiseConfig?: { __typename?: 'CargowiseConfigNode'; id: string } | null
      apiPartner?:
        | {
            __typename?: 'ApiPartnerNode'
            id: string
            name: string
            apiPartnerCode:
              | { __typename?: 'ApiPartnerCodeEnum'; value: ApiPartnerCode; isFallback: boolean }
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          }
        | {
            __typename?: 'CargowiseConfigNode'
            id: string
            name: string
            apiPartnerCode:
              | { __typename?: 'ApiPartnerCodeEnum'; value: ApiPartnerCode; isFallback: boolean }
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          }
        | null
      documentTypes: {
        __typename?: 'DocumentTypeNodeConnection'
        edges: Array<{
          __typename?: 'DocumentTypeNodeEdge'
          node: {
            __typename?: 'DocumentTypeNode'
            id: string
            name: string
            collapsible: boolean
            collapsibleOrderPriority: number
            documentTypeFieldGroups: {
              __typename?: 'DocumentTypeFieldGroupNodeConnection'
              edges: Array<{
                __typename?: 'DocumentTypeFieldGroupNodeEdge'
                node: {
                  __typename?: 'DocumentTypeFieldGroupNode'
                  id: string
                  fieldGroup: {
                    __typename?: 'FieldGroupNode'
                    repeatable: boolean
                    id: string
                    name: string
                    key: string
                    autofillKey: string
                    fields: {
                      __typename?: 'FieldNodeConnection'
                      edges: Array<{
                        __typename?: 'FieldNodeEdge'
                        node: {
                          __typename?: 'FieldNode'
                          id: string
                          autofillKey: string
                          key: string
                          name: string
                          required: boolean
                          validatorRegex?: string | null
                          validatorDescription?: string | null
                          dateFormatString?: string | null
                          defaultValue?: string | null
                          allowFreeText: boolean
                          partialTableAllowed: boolean
                          invalidCharsRegex?: string | null
                          values?: Array<string> | null
                          columnOrder: number
                          fieldType?:
                            | {
                                __typename?: 'FallbackValue'
                                fallbackValue: string
                                isFallback: boolean
                              }
                            | {
                                __typename?: 'FieldTypeEnum'
                                value: FieldType
                                isFallback: boolean
                              }
                            | null
                          searchableRecord?: {
                            __typename?: 'SearchableRecordNode'
                            id: string
                            searchKey: string
                            displayKey: string
                            model?: SearchableRecordModel | null
                            apiUrl?: string | null
                          } | null
                        }
                      }>
                    }
                  }
                }
              }>
            }
          }
        }>
      }
    }
    task?: {
      __typename?: 'TaskNode'
      id: string
      blocked: boolean
      title: string
      message?: string | null
      taskReferenceId: string
      status: TaskStatus
      taskType: TaskType
      dateCreated: string
      dateReceived?: string | null
      taskSlaTimeJobRef?: {
        __typename?: 'TaskSlaTimeJobRefNode'
        id: string
        job?: { __typename?: 'JobNode'; id: string; dateCreated: string; slaTime: number } | null
      } | null
      company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
    } | null
    filePages: {
      __typename?: 'FilePageNodeConnection'
      edges: Array<{
        __typename?: 'FilePageNodeEdge'
        node: {
          __typename?: 'FilePageNode'
          id: string
          googleOcrData?: string | null
          imageUrl?: string | null
          pageNumber: number
          type: FilePageType
          isInCw: boolean
          file: {
            __typename?: 'FileNode'
            id: string
            filename: string
            senderAddress?: string | null
            emailSubject?: string | null
            message?: string | null
            dateCreated: string
          }
          eDocuments: {
            __typename?: 'EDocumentNodeConnection'
            edges: Array<{
              __typename?: 'EDocumentNodeEdge'
              node: {
                __typename?: 'EDocumentNode'
                id: string
                isInCw: boolean
                dataTargetType:
                  | {
                      __typename?: 'EDocsDataTargetTypeEnum'
                      value: EDocsDataTargetType
                      isFallback: boolean
                    }
                  | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
                cargowiseFileType?: {
                  __typename?: 'CargowiseFileTypeNode'
                  id: string
                  code: string
                  description: string
                } | null
              }
            }>
          }
          document?: {
            __typename?: 'DocumentNode'
            id: string
            documentType: {
              __typename?: 'DocumentTypeNode'
              id: string
              tableShowsPreset: boolean
              name: string
              isEDocPublishedByDefault: boolean
              cargowiseFileType?: {
                __typename?: 'CargowiseFileTypeNode'
                id: string
                code: string
                description: string
              } | null
              documentTypeFieldGroups: {
                __typename?: 'DocumentTypeFieldGroupNodeConnection'
                edges: Array<{
                  __typename?: 'DocumentTypeFieldGroupNodeEdge'
                  node: {
                    __typename?: 'DocumentTypeFieldGroupNode'
                    id: string
                    fieldGroup: {
                      __typename?: 'FieldGroupNode'
                      repeatable: boolean
                      id: string
                      name: string
                      key: string
                      autofillKey: string
                      fields: {
                        __typename?: 'FieldNodeConnection'
                        edges: Array<{
                          __typename?: 'FieldNodeEdge'
                          node: {
                            __typename?: 'FieldNode'
                            id: string
                            autofillKey: string
                            key: string
                            name: string
                            required: boolean
                            validatorRegex?: string | null
                            validatorDescription?: string | null
                            dateFormatString?: string | null
                            defaultValue?: string | null
                            allowFreeText: boolean
                            partialTableAllowed: boolean
                            invalidCharsRegex?: string | null
                            values?: Array<string> | null
                            columnOrder: number
                            fieldType?:
                              | {
                                  __typename?: 'FallbackValue'
                                  fallbackValue: string
                                  isFallback: boolean
                                }
                              | {
                                  __typename?: 'FieldTypeEnum'
                                  value: FieldType
                                  isFallback: boolean
                                }
                              | null
                            searchableRecord?: {
                              __typename?: 'SearchableRecordNode'
                              id: string
                              searchKey: string
                              displayKey: string
                              model?: SearchableRecordModel | null
                              apiUrl?: string | null
                            } | null
                          }
                        }>
                      }
                    }
                  }
                }>
              }
            }
            documentTables: {
              __typename?: 'DocumentTableNodeConnection'
              edges: Array<{
                __typename?: 'DocumentTableNodeEdge'
                node: {
                  __typename?: 'DocumentTableNode'
                  id: string
                  orderPriority: number
                  fieldGroupId: string
                  nextTableId?: string | null
                  documentId: string
                  editedByUser?: boolean | null
                  document: {
                    __typename?: 'DocumentNode'
                    id: string
                    documentType: {
                      __typename?: 'DocumentTypeNode'
                      id: string
                      tableShowsPreset: boolean
                    }
                  }
                  fieldGroup: {
                    __typename?: 'FieldGroupNode'
                    id: string
                    key: string
                    autofillKey: string
                    name: string
                    fields: {
                      __typename?: 'FieldNodeConnection'
                      edges: Array<{
                        __typename?: 'FieldNodeEdge'
                        node: {
                          __typename?: 'FieldNode'
                          id: string
                          key: string
                          autofillKey: string
                          name: string
                          required: boolean
                          validatorRegex?: string | null
                          validatorDescription?: string | null
                          defaultValue?: string | null
                          dateFormatString?: string | null
                          allowFreeText: boolean
                          partialTableAllowed: boolean
                          invalidCharsRegex?: string | null
                          values?: Array<string> | null
                          columnOrder: number
                          fieldType?:
                            | {
                                __typename?: 'FallbackValue'
                                fallbackValue: string
                                isFallback: boolean
                              }
                            | {
                                __typename?: 'FieldTypeEnum'
                                value: FieldType
                                isFallback: boolean
                              }
                            | null
                          searchableRecord?: {
                            __typename?: 'SearchableRecordNode'
                            id: string
                            searchKey: string
                            displayKey: string
                            model?: SearchableRecordModel | null
                          } | null
                        }
                      }>
                    }
                  }
                  documentTableColumns: {
                    __typename?: 'DocumentTableColumnNodeConnection'
                    edges: Array<{
                      __typename?: 'DocumentTableColumnNodeEdge'
                      node: {
                        __typename?: 'DocumentTableColumnNode'
                        id: string
                        fieldId: string
                        index: number
                        left: number
                        width: number
                        top: number
                        height: number
                        field: {
                          __typename?: 'FieldNode'
                          id: string
                          key: string
                          name: string
                          searchableRecord?: {
                            __typename?: 'SearchableRecordNode'
                            id: string
                            searchKey: string
                            displayKey: string
                            model?: SearchableRecordModel | null
                          } | null
                        }
                      }
                    }>
                  }
                  documentFieldGroups: {
                    __typename?: 'DocumentFieldGroupNodeConnection'
                    edges: Array<{
                      __typename?: 'DocumentFieldGroupNodeEdge'
                      node: {
                        __typename?: 'DocumentFieldGroupNode'
                        id: string
                        rowOrderPriority?: number | null
                        document: { __typename?: 'DocumentNode'; id: string }
                        documentFields: {
                          __typename?: 'DocumentFieldNodeConnection'
                          edges: Array<{
                            __typename?: 'DocumentFieldNodeEdge'
                            node: {
                              __typename?: 'DocumentFieldNode'
                              id: string
                              value: string
                              confirmed?: boolean | null
                              top?: number | null
                              left?: number | null
                              width?: number | null
                              height?: number | null
                              confidenceScore?: number | null
                              field: {
                                __typename?: 'FieldNode'
                                id: string
                                key: string
                                name: string
                                autofillKey: string
                                searchableRecord?: {
                                  __typename?: 'SearchableRecordNode'
                                  id: string
                                  searchKey: string
                                  displayKey: string
                                  model?: SearchableRecordModel | null
                                } | null
                              }
                            }
                          }>
                        }
                      }
                    }>
                  }
                }
              }>
            }
            documentFieldGroups: {
              __typename?: 'DocumentFieldGroupNodeConnection'
              edges: Array<{
                __typename?: 'DocumentFieldGroupNodeEdge'
                node: {
                  __typename?: 'DocumentFieldGroupNode'
                  id: string
                  rowOrderPriority?: number | null
                  document: { __typename?: 'DocumentNode'; id: string }
                  fieldGroup: { __typename?: 'FieldGroupNode'; id: string; repeatable: boolean }
                  documentFields: {
                    __typename?: 'DocumentFieldNodeConnection'
                    edges: Array<{
                      __typename?: 'DocumentFieldNodeEdge'
                      node: {
                        __typename?: 'DocumentFieldNode'
                        id: string
                        value: string
                        confirmed?: boolean | null
                        top?: number | null
                        left?: number | null
                        width?: number | null
                        height?: number | null
                        confidenceScore?: number | null
                        field: {
                          __typename?: 'FieldNode'
                          id: string
                          autofillKey: string
                          key: string
                          name: string
                        }
                      }
                    }>
                  }
                }
              }>
            }
          } | null
        }
      }>
    }
  }
}

export type JobOverviewPageQueryVariables = Exact<{
  page: Scalars['Int']['input']
  query: Scalars['String']['input']
  status?: InputMaybe<Scalars['String']['input']>
  filters?: InputMaybe<Array<InputJobFilter> | InputJobFilter>
}>

export type JobOverviewPageQuery = {
  __typename?: 'Query'
  jobOverviewPage: {
    __typename?: 'JobOverviewPage'
    total: number
    items: Array<{
      __typename?: 'JobNode'
      id: string
      name: string
      slaTime: number
      dateCreated: string
      jobCode: string
      status: JobStatus
      clientReferenceNo: string
      taskId?: string | null
      owner?: { __typename?: 'UserNode'; email: string; id: string } | null
      qa?: { __typename?: 'UserNode'; email: string; id: string } | null
      jobTemplate: {
        __typename?: 'JobTemplateNode'
        id: string
        name: string
        companyId: string
        company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
        jobTemplateExport?: {
          __typename?: 'JobTemplateExportNode'
          id: string
          jobTemplateExportType: JobTemplateExportType
        } | null
      }
      filePages: {
        __typename?: 'FilePageNodeConnection'
        edges: Array<{
          __typename?: 'FilePageNodeEdge'
          node: { __typename?: 'FilePageNode'; id: string; status: FilePageStatus }
        }>
      }
    }>
  }
}

export type JobForExportFragment = {
  __typename?: 'JobNode'
  id: string
  name: string
  dateCreated: string
  status: JobStatus
  ownerId?: string | null
  clientReferenceNo: string
  jobTemplate: {
    __typename?: 'JobTemplateNode'
    id: string
    name: string
    jobTemplateExport?: {
      __typename?: 'JobTemplateExportNode'
      id: string
      jobTemplateExportType: JobTemplateExportType
      jobTemplateExportSheets: {
        __typename?: 'JobTemplateExportSheetNodeConnection'
        edges: Array<{
          __typename?: 'JobTemplateExportSheetNodeEdge'
          node: {
            __typename?: 'JobTemplateExportSheetNode'
            id: string
            order: number
            displayColumnHeader: boolean
            fieldGroup?: { __typename?: 'FieldGroupNode'; id: string; name: string } | null
            jobTemplateExportColumns: {
              __typename?: 'JobTemplateExportColumnNodeConnection'
              edges: Array<{
                __typename?: 'JobTemplateExportColumnNodeEdge'
                node: {
                  __typename?: 'JobTemplateExportColumnNode'
                  id: string
                  columnName?: string | null
                  fixedValue?: string | null
                  order: number
                  field?: { __typename?: 'FieldNode'; id: string; name: string } | null
                }
              }>
            }
          }
        }>
      }
    } | null
    documentTypes: {
      __typename?: 'DocumentTypeNodeConnection'
      edges: Array<{
        __typename?: 'DocumentTypeNodeEdge'
        node: {
          __typename?: 'DocumentTypeNode'
          id: string
          documentTypeFieldGroups: {
            __typename?: 'DocumentTypeFieldGroupNodeConnection'
            edges: Array<{
              __typename?: 'DocumentTypeFieldGroupNodeEdge'
              node: {
                __typename?: 'DocumentTypeFieldGroupNode'
                id: string
                fieldGroup: {
                  __typename?: 'FieldGroupNode'
                  id: string
                  name: string
                  key: string
                  repeatable: boolean
                  fields: {
                    __typename?: 'FieldNodeConnection'
                    edges: Array<{
                      __typename?: 'FieldNodeEdge'
                      node: { __typename?: 'FieldNode'; id: string; key: string; name: string }
                    }>
                  }
                }
              }
            }>
          }
        }
      }>
    }
    company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
  }
  filePages: {
    __typename?: 'FilePageNodeConnection'
    edges: Array<{
      __typename?: 'FilePageNodeEdge'
      node: {
        __typename?: 'FilePageNode'
        id: string
        status: FilePageStatus
        imageUrl?: string | null
        pageNumber: number
        dateCreated: string
        fileId: string
        file: { __typename?: 'FileNode'; id: string; filename: string }
        document?: {
          __typename?: 'DocumentNode'
          id: string
          filePage: {
            __typename?: 'FilePageNode'
            id: string
            imageUrl?: string | null
            file: { __typename?: 'FileNode'; id: string; filename: string }
          }
          documentType: {
            __typename?: 'DocumentTypeNode'
            id: string
            name: string
            documentTypeFieldGroups: {
              __typename?: 'DocumentTypeFieldGroupNodeConnection'
              edges: Array<{
                __typename?: 'DocumentTypeFieldGroupNodeEdge'
                node: {
                  __typename?: 'DocumentTypeFieldGroupNode'
                  id: string
                  fieldGroup: {
                    __typename?: 'FieldGroupNode'
                    id: string
                    key: string
                    name: string
                    repeatable: boolean
                    fields: {
                      __typename?: 'FieldNodeConnection'
                      edges: Array<{
                        __typename?: 'FieldNodeEdge'
                        node: { __typename?: 'FieldNode'; id: string; key: string; name: string }
                      }>
                    }
                  }
                }
              }>
            }
          }
          documentFieldGroups: {
            __typename?: 'DocumentFieldGroupNodeConnection'
            edges: Array<{
              __typename?: 'DocumentFieldGroupNodeEdge'
              node: {
                __typename?: 'DocumentFieldGroupNode'
                id: string
                rowOrderPriority?: number | null
                document: { __typename?: 'DocumentNode'; id: string }
                documentFields: {
                  __typename?: 'DocumentFieldNodeConnection'
                  edges: Array<{
                    __typename?: 'DocumentFieldNodeEdge'
                    node: {
                      __typename?: 'DocumentFieldNode'
                      id: string
                      value: string
                      confidenceScore?: number | null
                      top?: number | null
                      left?: number | null
                      width?: number | null
                      height?: number | null
                      field: {
                        __typename?: 'FieldNode'
                        id: string
                        name: string
                        key: string
                        valueExportMapping?: string | null
                      }
                    }
                  }>
                }
                documentTable?: { __typename?: 'DocumentTableNode'; id: string } | null
                fieldGroup: {
                  __typename?: 'FieldGroupNode'
                  id: string
                  key: string
                  name: string
                  autofillKey: string
                  repeatable: boolean
                  fields: {
                    __typename?: 'FieldNodeConnection'
                    edges: Array<{
                      __typename?: 'FieldNodeEdge'
                      node: { __typename?: 'FieldNode'; id: string; name: string }
                    }>
                  }
                }
              }
            }>
          }
        } | null
      }
    }>
  }
}

export type JobForExportQueryVariables = Exact<{
  id: Scalars['UUID']['input']
}>

export type JobForExportQuery = {
  __typename?: 'Query'
  job: {
    __typename?: 'JobNode'
    id: string
    name: string
    dateCreated: string
    status: JobStatus
    ownerId?: string | null
    clientReferenceNo: string
    jobTemplate: {
      __typename?: 'JobTemplateNode'
      id: string
      name: string
      jobTemplateExport?: {
        __typename?: 'JobTemplateExportNode'
        id: string
        jobTemplateExportType: JobTemplateExportType
        jobTemplateExportSheets: {
          __typename?: 'JobTemplateExportSheetNodeConnection'
          edges: Array<{
            __typename?: 'JobTemplateExportSheetNodeEdge'
            node: {
              __typename?: 'JobTemplateExportSheetNode'
              id: string
              order: number
              displayColumnHeader: boolean
              fieldGroup?: { __typename?: 'FieldGroupNode'; id: string; name: string } | null
              jobTemplateExportColumns: {
                __typename?: 'JobTemplateExportColumnNodeConnection'
                edges: Array<{
                  __typename?: 'JobTemplateExportColumnNodeEdge'
                  node: {
                    __typename?: 'JobTemplateExportColumnNode'
                    id: string
                    columnName?: string | null
                    fixedValue?: string | null
                    order: number
                    field?: { __typename?: 'FieldNode'; id: string; name: string } | null
                  }
                }>
              }
            }
          }>
        }
      } | null
      documentTypes: {
        __typename?: 'DocumentTypeNodeConnection'
        edges: Array<{
          __typename?: 'DocumentTypeNodeEdge'
          node: {
            __typename?: 'DocumentTypeNode'
            id: string
            documentTypeFieldGroups: {
              __typename?: 'DocumentTypeFieldGroupNodeConnection'
              edges: Array<{
                __typename?: 'DocumentTypeFieldGroupNodeEdge'
                node: {
                  __typename?: 'DocumentTypeFieldGroupNode'
                  id: string
                  fieldGroup: {
                    __typename?: 'FieldGroupNode'
                    id: string
                    name: string
                    key: string
                    repeatable: boolean
                    fields: {
                      __typename?: 'FieldNodeConnection'
                      edges: Array<{
                        __typename?: 'FieldNodeEdge'
                        node: { __typename?: 'FieldNode'; id: string; key: string; name: string }
                      }>
                    }
                  }
                }
              }>
            }
          }
        }>
      }
      company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
    }
    filePages: {
      __typename?: 'FilePageNodeConnection'
      edges: Array<{
        __typename?: 'FilePageNodeEdge'
        node: {
          __typename?: 'FilePageNode'
          id: string
          status: FilePageStatus
          imageUrl?: string | null
          pageNumber: number
          dateCreated: string
          fileId: string
          file: { __typename?: 'FileNode'; id: string; filename: string }
          document?: {
            __typename?: 'DocumentNode'
            id: string
            filePage: {
              __typename?: 'FilePageNode'
              id: string
              imageUrl?: string | null
              file: { __typename?: 'FileNode'; id: string; filename: string }
            }
            documentType: {
              __typename?: 'DocumentTypeNode'
              id: string
              name: string
              documentTypeFieldGroups: {
                __typename?: 'DocumentTypeFieldGroupNodeConnection'
                edges: Array<{
                  __typename?: 'DocumentTypeFieldGroupNodeEdge'
                  node: {
                    __typename?: 'DocumentTypeFieldGroupNode'
                    id: string
                    fieldGroup: {
                      __typename?: 'FieldGroupNode'
                      id: string
                      key: string
                      name: string
                      repeatable: boolean
                      fields: {
                        __typename?: 'FieldNodeConnection'
                        edges: Array<{
                          __typename?: 'FieldNodeEdge'
                          node: { __typename?: 'FieldNode'; id: string; key: string; name: string }
                        }>
                      }
                    }
                  }
                }>
              }
            }
            documentFieldGroups: {
              __typename?: 'DocumentFieldGroupNodeConnection'
              edges: Array<{
                __typename?: 'DocumentFieldGroupNodeEdge'
                node: {
                  __typename?: 'DocumentFieldGroupNode'
                  id: string
                  rowOrderPriority?: number | null
                  document: { __typename?: 'DocumentNode'; id: string }
                  documentFields: {
                    __typename?: 'DocumentFieldNodeConnection'
                    edges: Array<{
                      __typename?: 'DocumentFieldNodeEdge'
                      node: {
                        __typename?: 'DocumentFieldNode'
                        id: string
                        value: string
                        confidenceScore?: number | null
                        top?: number | null
                        left?: number | null
                        width?: number | null
                        height?: number | null
                        field: {
                          __typename?: 'FieldNode'
                          id: string
                          name: string
                          key: string
                          valueExportMapping?: string | null
                        }
                      }
                    }>
                  }
                  documentTable?: { __typename?: 'DocumentTableNode'; id: string } | null
                  fieldGroup: {
                    __typename?: 'FieldGroupNode'
                    id: string
                    key: string
                    name: string
                    autofillKey: string
                    repeatable: boolean
                    fields: {
                      __typename?: 'FieldNodeConnection'
                      edges: Array<{
                        __typename?: 'FieldNodeEdge'
                        node: { __typename?: 'FieldNode'; id: string; name: string }
                      }>
                    }
                  }
                }
              }>
            }
          } | null
        }
      }>
    }
  }
}

export type UserJobTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type UserJobTemplatesQuery = {
  __typename?: 'Query'
  userJobTemplates: Array<{
    __typename?: 'JobTemplateNode'
    id: string
    name: string
    dateDeleted?: string | null
    company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
  }>
}

export type JobNameQueryVariables = Exact<{
  id: Scalars['UUID']['input']
}>

export type JobNameQuery = {
  __typename?: 'Query'
  job: {
    __typename?: 'JobNode'
    id: string
    name: string
    jobTemplate: {
      __typename?: 'JobTemplateNode'
      id: string
      name: string
      reconType: JobTemplateReconType
      shipmentOpsTypes: Array<ShipmentOp>
      apiPartner?:
        | {
            __typename?: 'ApiPartnerNode'
            id: string
            name: string
            apiPartnerCode:
              | { __typename?: 'ApiPartnerCodeEnum'; value: ApiPartnerCode; isFallback: boolean }
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          }
        | {
            __typename?: 'CargowiseConfigNode'
            connectorType?: CwConnectorType | null
            id: string
            name: string
            apiPartnerCode:
              | { __typename?: 'ApiPartnerCodeEnum'; value: ApiPartnerCode; isFallback: boolean }
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          }
        | null
      cargowiseConfig?: {
        __typename?: 'CargowiseConfigNode'
        id: string
        connectorType?: CwConnectorType | null
      } | null
      documentTypes: {
        __typename?: 'DocumentTypeNodeConnection'
        edges: Array<{
          __typename?: 'DocumentTypeNodeEdge'
          node: {
            __typename?: 'DocumentTypeNode'
            id: string
            documentTypeFieldGroups: {
              __typename?: 'DocumentTypeFieldGroupNodeConnection'
              edges: Array<{
                __typename?: 'DocumentTypeFieldGroupNodeEdge'
                node: {
                  __typename?: 'DocumentTypeFieldGroupNode'
                  id: string
                  fieldGroup: {
                    __typename?: 'FieldGroupNode'
                    id: string
                    key: string
                    autofillKey: string
                    fields: {
                      __typename?: 'FieldNodeConnection'
                      edges: Array<{
                        __typename?: 'FieldNodeEdge'
                        node: { __typename?: 'FieldNode'; id: string; key: string }
                      }>
                    }
                  }
                }
              }>
            }
          }
        }>
      }
    }
  }
}

export type JobOperatorsQueryVariables = Exact<{ [key: string]: never }>

export type JobOperatorsQuery = {
  __typename?: 'Query'
  jobOperators: Array<{ __typename?: 'UserNode'; id: string; name?: string | null; email: string }>
}

export type JobsForExportQueryVariables = Exact<{
  jobIds: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type JobsForExportQuery = {
  __typename?: 'Query'
  jobs: Array<{
    __typename?: 'JobNode'
    id: string
    name: string
    dateCreated: string
    status: JobStatus
    ownerId?: string | null
    clientReferenceNo: string
    jobTemplate: {
      __typename?: 'JobTemplateNode'
      id: string
      name: string
      jobTemplateExport?: {
        __typename?: 'JobTemplateExportNode'
        id: string
        jobTemplateExportType: JobTemplateExportType
        jobTemplateExportSheets: {
          __typename?: 'JobTemplateExportSheetNodeConnection'
          edges: Array<{
            __typename?: 'JobTemplateExportSheetNodeEdge'
            node: {
              __typename?: 'JobTemplateExportSheetNode'
              id: string
              order: number
              displayColumnHeader: boolean
              fieldGroup?: { __typename?: 'FieldGroupNode'; id: string; name: string } | null
              jobTemplateExportColumns: {
                __typename?: 'JobTemplateExportColumnNodeConnection'
                edges: Array<{
                  __typename?: 'JobTemplateExportColumnNodeEdge'
                  node: {
                    __typename?: 'JobTemplateExportColumnNode'
                    id: string
                    columnName?: string | null
                    fixedValue?: string | null
                    order: number
                    field?: { __typename?: 'FieldNode'; id: string; name: string } | null
                  }
                }>
              }
            }
          }>
        }
      } | null
      documentTypes: {
        __typename?: 'DocumentTypeNodeConnection'
        edges: Array<{
          __typename?: 'DocumentTypeNodeEdge'
          node: {
            __typename?: 'DocumentTypeNode'
            id: string
            documentTypeFieldGroups: {
              __typename?: 'DocumentTypeFieldGroupNodeConnection'
              edges: Array<{
                __typename?: 'DocumentTypeFieldGroupNodeEdge'
                node: {
                  __typename?: 'DocumentTypeFieldGroupNode'
                  id: string
                  fieldGroup: {
                    __typename?: 'FieldGroupNode'
                    id: string
                    name: string
                    key: string
                    repeatable: boolean
                    fields: {
                      __typename?: 'FieldNodeConnection'
                      edges: Array<{
                        __typename?: 'FieldNodeEdge'
                        node: { __typename?: 'FieldNode'; id: string; key: string; name: string }
                      }>
                    }
                  }
                }
              }>
            }
          }
        }>
      }
      company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
    }
    filePages: {
      __typename?: 'FilePageNodeConnection'
      edges: Array<{
        __typename?: 'FilePageNodeEdge'
        node: {
          __typename?: 'FilePageNode'
          id: string
          status: FilePageStatus
          imageUrl?: string | null
          pageNumber: number
          dateCreated: string
          fileId: string
          file: { __typename?: 'FileNode'; id: string; filename: string }
          document?: {
            __typename?: 'DocumentNode'
            id: string
            filePage: {
              __typename?: 'FilePageNode'
              id: string
              imageUrl?: string | null
              file: { __typename?: 'FileNode'; id: string; filename: string }
            }
            documentType: {
              __typename?: 'DocumentTypeNode'
              id: string
              name: string
              documentTypeFieldGroups: {
                __typename?: 'DocumentTypeFieldGroupNodeConnection'
                edges: Array<{
                  __typename?: 'DocumentTypeFieldGroupNodeEdge'
                  node: {
                    __typename?: 'DocumentTypeFieldGroupNode'
                    id: string
                    fieldGroup: {
                      __typename?: 'FieldGroupNode'
                      id: string
                      key: string
                      name: string
                      repeatable: boolean
                      fields: {
                        __typename?: 'FieldNodeConnection'
                        edges: Array<{
                          __typename?: 'FieldNodeEdge'
                          node: { __typename?: 'FieldNode'; id: string; key: string; name: string }
                        }>
                      }
                    }
                  }
                }>
              }
            }
            documentFieldGroups: {
              __typename?: 'DocumentFieldGroupNodeConnection'
              edges: Array<{
                __typename?: 'DocumentFieldGroupNodeEdge'
                node: {
                  __typename?: 'DocumentFieldGroupNode'
                  id: string
                  rowOrderPriority?: number | null
                  document: { __typename?: 'DocumentNode'; id: string }
                  documentFields: {
                    __typename?: 'DocumentFieldNodeConnection'
                    edges: Array<{
                      __typename?: 'DocumentFieldNodeEdge'
                      node: {
                        __typename?: 'DocumentFieldNode'
                        id: string
                        value: string
                        confidenceScore?: number | null
                        top?: number | null
                        left?: number | null
                        width?: number | null
                        height?: number | null
                        field: {
                          __typename?: 'FieldNode'
                          id: string
                          name: string
                          key: string
                          valueExportMapping?: string | null
                        }
                      }
                    }>
                  }
                  documentTable?: { __typename?: 'DocumentTableNode'; id: string } | null
                  fieldGroup: {
                    __typename?: 'FieldGroupNode'
                    id: string
                    key: string
                    name: string
                    autofillKey: string
                    repeatable: boolean
                    fields: {
                      __typename?: 'FieldNodeConnection'
                      edges: Array<{
                        __typename?: 'FieldNodeEdge'
                        node: { __typename?: 'FieldNode'; id: string; name: string }
                      }>
                    }
                  }
                }
              }>
            }
          } | null
        }
      }>
    }
  } | null>
}

export type JobConfirmationPageQueryVariables = Exact<{
  id: Scalars['UUID']['input']
}>

export type JobConfirmationPageQuery = {
  __typename?: 'Query'
  job: {
    __typename?: 'JobNode'
    id: string
    task?: {
      __typename?: 'TaskNode'
      id: string
      dateConfirmed?: string | null
      title: string
      taskReferenceId: string
      dateReceived?: string | null
      message?: string | null
    } | null
  }
}

export type GetJobForTmsQueryVariables = Exact<{
  id: Scalars['UUID']['input']
}>

export type GetJobForTmsQuery = {
  __typename?: 'Query'
  job: {
    __typename?: 'JobNode'
    id: string
    externalAssignee?: { __typename?: 'UserNode'; id: string; email: string } | null
    jobTemplate: {
      __typename?: 'JobTemplateNode'
      id: string
      companyId: string
      apiPartnerId?: string | null
      apiPartner?:
        | {
            __typename?: 'ApiPartnerNode'
            id: string
            name: string
            apiPartnerCode:
              | { __typename?: 'ApiPartnerCodeEnum'; value: ApiPartnerCode; isFallback: boolean }
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          }
        | {
            __typename?: 'CargowiseConfigNode'
            id: string
            name: string
            apiPartnerCode:
              | { __typename?: 'ApiPartnerCodeEnum'; value: ApiPartnerCode; isFallback: boolean }
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          }
        | null
    }
    jobTable?: {
      __typename?: 'JobTableNode'
      id: string
      fieldGroup: {
        __typename?: 'FieldGroupNode'
        id: string
        repeatable: boolean
        name: string
        key: string
        autofillKey: string
        fields: {
          __typename?: 'FieldNodeConnection'
          edges: Array<{
            __typename?: 'FieldNodeEdge'
            node: {
              __typename?: 'FieldNode'
              id: string
              autofillKey: string
              key: string
              name: string
              required: boolean
              validatorRegex?: string | null
              validatorDescription?: string | null
              dateFormatString?: string | null
              defaultValue?: string | null
              allowFreeText: boolean
              partialTableAllowed: boolean
              invalidCharsRegex?: string | null
              values?: Array<string> | null
              columnOrder: number
              fieldType?:
                | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
                | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
                | null
              searchableRecord?: {
                __typename?: 'SearchableRecordNode'
                id: string
                searchKey: string
                displayKey: string
                model?: SearchableRecordModel | null
                apiUrl?: string | null
              } | null
            }
          }>
        }
      }
      jobTableColumns: {
        __typename?: 'JobTableColumnNodeConnection'
        edges: Array<{
          __typename?: 'JobTableColumnNodeEdge'
          node: {
            __typename?: 'JobTableColumnNode'
            id: string
            index: number
            field: {
              __typename?: 'FieldNode'
              id: string
              autofillKey: string
              key: string
              name: string
              required: boolean
              validatorRegex?: string | null
              validatorDescription?: string | null
              dateFormatString?: string | null
              defaultValue?: string | null
              allowFreeText: boolean
              partialTableAllowed: boolean
              invalidCharsRegex?: string | null
              values?: Array<string> | null
              columnOrder: number
              fieldType?:
                | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
                | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
                | null
              searchableRecord?: {
                __typename?: 'SearchableRecordNode'
                id: string
                searchKey: string
                displayKey: string
                model?: SearchableRecordModel | null
                apiUrl?: string | null
              } | null
            }
          }
        }>
      }
      jobTableLineItems: {
        __typename?: 'JobTableLineItemNodeConnection'
        edges: Array<{
          __typename?: 'JobTableLineItemNodeEdge'
          node: {
            __typename?: 'JobTableLineItemNode'
            id: string
            jobTableCells: {
              __typename?: 'JobTableCellNodeConnection'
              edges: Array<{
                __typename?: 'JobTableCellNodeEdge'
                node: {
                  __typename?: 'JobTableCellNode'
                  id: string
                  documentId: string
                  top?: number | null
                  left?: number | null
                  width?: number | null
                  height?: number | null
                  value: string
                  field: { __typename?: 'FieldNode'; id: string; key: string; autofillKey: string }
                }
              }>
            }
          }
        }>
      }
    } | null
    filePages: {
      __typename?: 'FilePageNodeConnection'
      edges: Array<{
        __typename?: 'FilePageNodeEdge'
        node: {
          __typename?: 'FilePageNode'
          id: string
          document?: {
            __typename?: 'DocumentNode'
            id: string
            documentTables: {
              __typename?: 'DocumentTableNodeConnection'
              edges: Array<{
                __typename?: 'DocumentTableNodeEdge'
                node: {
                  __typename?: 'DocumentTableNode'
                  id: string
                  fieldGroup: {
                    __typename?: 'FieldGroupNode'
                    id: string
                    name: string
                    key: string
                    autofillKey: string
                    fields: {
                      __typename?: 'FieldNodeConnection'
                      edges: Array<{
                        __typename?: 'FieldNodeEdge'
                        node: {
                          __typename?: 'FieldNode'
                          id: string
                          name: string
                          key: string
                          autofillKey: string
                        }
                      }>
                    }
                  }
                  documentFieldGroups: {
                    __typename?: 'DocumentFieldGroupNodeConnection'
                    edges: Array<{
                      __typename?: 'DocumentFieldGroupNodeEdge'
                      node: {
                        __typename?: 'DocumentFieldGroupNode'
                        id: string
                        documentFields: {
                          __typename?: 'DocumentFieldNodeConnection'
                          edges: Array<{
                            __typename?: 'DocumentFieldNodeEdge'
                            node: {
                              __typename?: 'DocumentFieldNode'
                              id: string
                              value: string
                              confirmed?: boolean | null
                              confidenceScore?: number | null
                              field: {
                                __typename?: 'FieldNode'
                                id: string
                                key: string
                                autofillKey: string
                                name: string
                              }
                            }
                          }>
                        }
                      }
                    }>
                  }
                }
              }>
            }
            documentFieldGroups: {
              __typename?: 'DocumentFieldGroupNodeConnection'
              edges: Array<{
                __typename?: 'DocumentFieldGroupNodeEdge'
                node: {
                  __typename?: 'DocumentFieldGroupNode'
                  id: string
                  fieldGroup: {
                    __typename?: 'FieldGroupNode'
                    id: string
                    repeatable: boolean
                    name: string
                    autofillKey: string
                    fields: {
                      __typename?: 'FieldNodeConnection'
                      edges: Array<{
                        __typename?: 'FieldNodeEdge'
                        node: { __typename?: 'FieldNode'; id: string; autofillKey: string }
                      }>
                    }
                  }
                  documentFields: {
                    __typename?: 'DocumentFieldNodeConnection'
                    edges: Array<{
                      __typename?: 'DocumentFieldNodeEdge'
                      node: {
                        __typename?: 'DocumentFieldNode'
                        id: string
                        value: string
                        confidenceScore?: number | null
                        field: {
                          __typename?: 'FieldNode'
                          id: string
                          name: string
                          autofillKey: string
                        }
                      }
                    }>
                  }
                }
              }>
            }
          } | null
        }
      }>
    }
  }
}

export type JobSearchParametersQueryVariables = Exact<{ [key: string]: never }>

export type JobSearchParametersQuery = {
  __typename?: 'Query'
  jobSearchParameters: {
    __typename?: 'JobSearchParameters'
    queryString: string
    filters: Array<{
      __typename?: 'JobFilterSettingNode'
      id: string
      column: JobFilterColumn
      operation: JobFilterOperation
      value: Array<string>
    }>
  }
}

export type JobTemplateQueryVariables = Exact<{
  id: Scalars['UUID']['input']
  companyId?: InputMaybe<Scalars['UUID']['input']>
}>

export type JobTemplateQuery = {
  __typename?: 'Query'
  jobTemplate: {
    __typename?: 'JobTemplateNode'
    id: string
    name: string
    description: string
    slaTime: number
    reconType: JobTemplateReconType
    shipmentOpsTypes: Array<ShipmentOp>
    adminDisabled: boolean
    credentialId?: string | null
    apiPartnerId?: string | null
    autoReconEnabled?: boolean | null
    subtotalsRowEnabled?: boolean | null
    mainTabEnabled?: boolean | null
    autoPostEnabled?: boolean | null
    showPostButton?: boolean | null
    requireShowReconToCustomer?: boolean | null
    requireEdocsPushPull?: boolean | null
    requireExternalAssignee?: boolean | null
    companyId: string
    defaultExternalAssignee?: { __typename?: 'UserNode'; id: string; email: string } | null
    apiPartner?:
      | {
          __typename?: 'ApiPartnerNode'
          id: string
          name: string
          apiPartnerCode:
            | { __typename?: 'ApiPartnerCodeEnum'; value: ApiPartnerCode; isFallback: boolean }
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        }
      | {
          __typename?: 'CargowiseConfigNode'
          id: string
          name: string
          apiPartnerCode:
            | { __typename?: 'ApiPartnerCodeEnum'; value: ApiPartnerCode; isFallback: boolean }
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        }
      | null
    company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
    jobTemplateExport?: {
      __typename?: 'JobTemplateExportNode'
      id: string
      jobTemplateExportType: JobTemplateExportType
      jobTemplateExportSheets: {
        __typename?: 'JobTemplateExportSheetNodeConnection'
        edges: Array<{
          __typename?: 'JobTemplateExportSheetNodeEdge'
          node: {
            __typename?: 'JobTemplateExportSheetNode'
            id: string
            displayColumnHeader: boolean
            order: number
            fieldGroup?: { __typename?: 'FieldGroupNode'; id: string; name: string } | null
            jobTemplateExportColumns: {
              __typename?: 'JobTemplateExportColumnNodeConnection'
              edges: Array<{
                __typename?: 'JobTemplateExportColumnNodeEdge'
                node: {
                  __typename?: 'JobTemplateExportColumnNode'
                  id: string
                  columnName?: string | null
                  fixedValue?: string | null
                  order: number
                  field?: {
                    __typename?: 'FieldNode'
                    id: string
                    name: string
                    values?: Array<string> | null
                  } | null
                }
              }>
            }
          }
        }>
      }
    } | null
    documentTypes: {
      __typename?: 'DocumentTypeNodeConnection'
      edges: Array<{
        __typename?: 'DocumentTypeNodeEdge'
        node: {
          __typename?: 'DocumentTypeNode'
          id: string
          name: string
          isStandard: boolean
          collapsible: boolean
          tableShowsPreset: boolean
          cargowiseFileTypeId?: string | null
          autofillExtractorKey?: AutofillExtractorKey | null
          derivedFromId?: string | null
          isEDocPublishedByDefault: boolean
          cargowiseFileType?: {
            __typename?: 'CargowiseFileTypeNode'
            id: string
            code: string
            description: string
          } | null
          documentTypeFieldGroups: {
            __typename?: 'DocumentTypeFieldGroupNodeConnection'
            edges: Array<{
              __typename?: 'DocumentTypeFieldGroupNodeEdge'
              node: {
                __typename?: 'DocumentTypeFieldGroupNode'
                id: string
                fieldGroup: {
                  __typename?: 'FieldGroupNode'
                  id: string
                  key: string
                  name: string
                  exportKey?: string | null
                  repeatable: boolean
                  autofillKey: string
                  fields: {
                    __typename?: 'FieldNodeConnection'
                    edges: Array<{
                      __typename?: 'FieldNodeEdge'
                      node: {
                        __typename?: 'FieldNode'
                        id: string
                        name: string
                        key: string
                        autofillKey: string
                        exportKey?: string | null
                        validatorRegex?: string | null
                        validatorDescription?: string | null
                        dateFormatString?: string | null
                        required: boolean
                        rows: number
                        values?: Array<string> | null
                        allowFreeText: boolean
                        columnOrder: number
                        defaultValue?: string | null
                        partialTableAllowed: boolean
                        invalidCharsRegex?: string | null
                        valueExportMapping?: string | null
                        fieldType?:
                          | {
                              __typename?: 'FallbackValue'
                              fallbackValue: string
                              isFallback: boolean
                            }
                          | { __typename?: 'FieldTypeEnum'; value: FieldType; isFallback: boolean }
                          | null
                        searchableRecord?: {
                          __typename?: 'SearchableRecordNode'
                          id: string
                          searchKey: string
                          displayKey: string
                          model?: SearchableRecordModel | null
                          apiUrl?: string | null
                        } | null
                      }
                    }>
                  }
                }
              }
            }>
          }
        }
      }>
    }
    reconThresholdSettings: {
      __typename?: 'ReconThresholdSettingNodeConnection'
      edges: Array<{
        __typename?: 'ReconThresholdSettingNodeEdge'
        node: {
          __typename?: 'ReconThresholdSettingNode'
          id: string
          enabled: boolean
          dateDisabled?: string | null
          reconThresholdRanges: {
            __typename?: 'ReconThresholdRangeNodeConnection'
            edges: Array<{
              __typename?: 'ReconThresholdRangeNodeEdge'
              node: {
                __typename?: 'ReconThresholdRangeNode'
                id: string
                minimum: string
                maximum: string
                thresholdAmount: string
                usePercent: boolean
                useAbsoluteValueMatching: boolean
              }
            }>
          }
        }
      }>
    }
  }
}

export type JobTemplatesQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['UUID']['input']>
}>

export type JobTemplatesQuery = {
  __typename?: 'Query'
  jobTemplates: Array<{
    __typename?: 'JobTemplateNode'
    id: string
    name: string
    company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
  }>
}

export type IsValidReconJobTemplateQueryVariables = Exact<{
  jobTemplateId: Scalars['UUID']['input']
}>

export type IsValidReconJobTemplateQuery = {
  __typename?: 'Query'
  isValidReconJobTemplate: {
    __typename?: 'IsValidReconJobTemplateResult'
    ok: boolean
    errors: Array<string>
  }
}

export type JobNotesQueryVariables = Exact<{
  jobId: Scalars['String']['input']
}>

export type JobNotesQuery = {
  __typename?: 'Query'
  jobNotes: Array<{
    __typename?: 'NoteNode'
    id: string
    dateCreated: string
    type: NoteType
    text: string
    content?: any | null
    source: NoteSource
    dateDeleted?: string | null
    user: { __typename?: 'UserNode'; id: string; email: string; isCustomer: boolean }
    reconAttempt?: { __typename?: 'ReconAttemptNode'; id: string; isAutoGenerated: boolean } | null
    jobActivity?:
      | { __typename?: 'ApiExportEDocActivityNode' }
      | { __typename?: 'ApiExportJobActivityNode' }
      | { __typename?: 'ApiImportEDocActivityNode' }
      | { __typename?: 'JobCheckShipmentInfoNode' }
      | { __typename?: 'JobConfirmationNode' }
      | { __typename?: 'JobEDocsPullNode' }
      | { __typename?: 'JobEDocsPushNode' }
      | { __typename?: 'JobExternalAssigneeChangeNode'; additionalNotes?: string | null }
      | { __typename?: 'JobExternalStatusChangeNode' }
      | { __typename?: 'JobLineItemEditNode' }
      | { __typename?: 'JobMetadataEditNode' }
      | { __typename?: 'JobMoveToDoneNode'; additionalNotes?: string | null }
      | { __typename?: 'JobOwnerChangeNode' }
      | { __typename?: 'JobPostToCWNode'; additionalNotes?: string | null }
      | { __typename?: 'JobQAChangeNode' }
      | { __typename?: 'JobQaNode' }
      | {
          __typename?: 'JobReceivedNode'
          job: {
            __typename?: 'JobNode'
            id: string
            dateCreated: string
            task?: { __typename?: 'TaskNode'; id: string; dateReceived?: string | null } | null
          }
        }
      | { __typename?: 'JobReconApproveForPostingNode'; additionalNotes?: string | null }
      | { __typename?: 'JobReconAttemptSavedNode'; additionalNotes?: string | null }
      | { __typename?: 'JobReconCommentNode'; additionalNotes?: string | null }
      | { __typename?: 'JobReconExternalAssigneeChangeNode'; additionalNotes?: string | null }
      | { __typename?: 'JobReconExternalStatusChangeNode'; additionalNotes?: string | null }
      | { __typename?: 'JobReconMoveToDoneNode'; additionalNotes?: string | null }
      | { __typename?: 'JobReconReceivedNode'; additionalNotes?: string | null }
      | {
          __typename?: 'JobReconRequestReprocessNode'
          reason: JobRequestReprocessReason
          correctedInfo: string
          autoPostAfterFixing: boolean
          additionalNotes?: string | null
          reconInvoice?: {
            __typename?: 'ReconInvoiceNode'
            id: string
            invoiceNumber: string
          } | null
          reconInvoiceShipmentReference?: {
            __typename?: 'ReconInvoiceShipmentReferenceNode'
            id: string
            tmsId: string
            reconAttempt?: {
              __typename?: 'ReconAttemptNode'
              id: string
              fieldValueMapV2?: string | null
              matchReconInvoiceShipmentReference?: {
                __typename?: 'ReconInvoiceShipmentReferenceNode'
                id: string
                tmsId: string
              } | null
              findShipmentReconResult?: {
                __typename?: 'FindShipmentReconResultNode'
                id: string
                referenceNo?: string | null
                hblNo?: string | null
                mblNo?: string | null
                carrierBookingNo?: string | null
                containerNo?: string | null
                consolNo?: string | null
              } | null
            } | null
          } | null
        }
      | {
          __typename?: 'JobReconRequestReprocessTargetNode'
          sourceRequestReprocess: {
            __typename?: 'JobReconRequestReprocessNode'
            id: string
            reason: JobRequestReprocessReason
            correctedInfo: string
            autoPostAfterFixing: boolean
            additionalNotes?: string | null
            reconInvoice?: {
              __typename?: 'ReconInvoiceNode'
              id: string
              invoiceNumber: string
            } | null
            reconInvoiceShipmentReference?: {
              __typename?: 'ReconInvoiceShipmentReferenceNode'
              id: string
              tmsId: string
              reconAttempt?: {
                __typename?: 'ReconAttemptNode'
                id: string
                fieldValueMapV2?: string | null
                matchReconInvoiceShipmentReference?: {
                  __typename?: 'ReconInvoiceShipmentReferenceNode'
                  id: string
                  tmsId: string
                } | null
                findShipmentReconResult?: {
                  __typename?: 'FindShipmentReconResultNode'
                  id: string
                  referenceNo?: string | null
                  hblNo?: string | null
                  mblNo?: string | null
                  carrierBookingNo?: string | null
                  containerNo?: string | null
                  consolNo?: string | null
                } | null
              } | null
            } | null
          }
        }
      | { __typename?: 'JobReconUpdateStatusNode' }
      | { __typename?: 'JobReconciliationSavedNode' }
      | { __typename?: 'JobRejectionNode' }
      | {
          __typename?: 'JobRequestReprocessNode'
          reason: JobRequestReprocessReason
          correctedInfo: string
          autoPostAfterFixing: boolean
          additionalNotes?: string | null
        }
      | { __typename?: 'JobSendToCWNode' }
      | { __typename?: 'JobShipmentOpsCreateNode' }
      | { __typename?: 'JobStatusChangeNode' }
      | null
  }>
}

export type NotificationsQueryVariables = Exact<{ [key: string]: never }>

export type NotificationsQuery = {
  __typename?: 'Query'
  notifications: Array<{
    __typename?: 'PageAsyncBatchNotificationNode'
    id: string
    dateRead?: string | null
    type: NotificationType
    pageAsyncBatch: {
      __typename?: 'PageAsyncBatchNode'
      id: string
      numDone?: number | null
      numTasks?: number | null
      errorMessage?: string | null
      dateCompleted?: string | null
      status: PageAsyncTaskStatus
      type: PageAsyncBatchType
      job?: { __typename?: 'JobNode'; id: string; status: JobStatus; name: string } | null
      pageAsyncTasks: {
        __typename?: 'PageAsyncTaskNodeConnection'
        edges: Array<{
          __typename?: 'PageAsyncTaskNodeEdge'
          node: {
            __typename?: 'PageAsyncTaskNode'
            id: string
            filePage: {
              __typename?: 'FilePageNode'
              id: string
              pageNumber: number
              file: {
                __typename?: 'FileNode'
                id: string
                filename: string
                emailSubject?: string | null
              }
            }
          }
        }>
      }
    }
  }>
}

export type UserProfileQueryVariables = Exact<{ [key: string]: never }>

export type UserProfileQuery = {
  __typename?: 'Query'
  userProfile: {
    __typename?: 'AuthProfile'
    auth0Id?: string | null
    email: string
    name: string
    metabaseDashboardId?: string | null
  }
}

export type GetUserRolesQueryVariables = Exact<{ [key: string]: never }>

export type GetUserRolesQuery = {
  __typename?: 'Query'
  userProfile: { __typename?: 'AuthProfile'; roles: Array<UserRole> }
}

export type ExistingReconAttemptQueryVariables = Exact<{
  jobId: Scalars['String']['input']
}>

export type ExistingReconAttemptQuery = {
  __typename?: 'Query'
  existingReconAttempt?: {
    __typename?: 'ReconAttemptNode'
    id: string
    resolutionStatus: ResolutionStatus
    dateResolved?: string | null
    userResolved?: { __typename?: 'UserNode'; id: string; email: string } | null
  } | null
}

export type ChargeVendorsQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
  query: Scalars['String']['input']
}>

export type ChargeVendorsQuery = {
  __typename?: 'Query'
  chargeVendors: Array<{
    __typename?: 'ChargeVendorNode'
    id: string
    name: string
    code: string
    type?: string | null
    dateDeleted?: string | null
  }>
}

export type ChargeVendorsWithCodesQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
  query: Scalars['String']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  withChargeCodes?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ChargeVendorsWithCodesQuery = {
  __typename?: 'Query'
  chargeVendors: Array<{
    __typename?: 'ChargeVendorNode'
    id: string
    name: string
    code: string
    type?: string | null
    dateDeleted?: string | null
    chargeCodes: {
      __typename?: 'ChargeCodeNodeConnection'
      edges: Array<{
        __typename?: 'ChargeCodeNodeEdge'
        node: {
          __typename?: 'ChargeCodeNode'
          id: string
          code: string
          description: string
          dateDeleted?: string | null
          apiPartnerId?: string | null
        }
      }>
    }
  }>
}

export type ChargeVendorsWithCodeOverridesQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
  query: Scalars['String']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type ChargeVendorsWithCodeOverridesQuery = {
  __typename?: 'Query'
  chargeVendors: Array<{
    __typename?: 'ChargeVendorNode'
    id: string
    name: string
    code: string
    type?: string | null
    dateDeleted?: string | null
    chargeCodeVendorOverrides: {
      __typename?: 'ChargeCodeVendorOverrideNodeConnection'
      edges: Array<{
        __typename?: 'ChargeCodeVendorOverrideNodeEdge'
        node: {
          __typename?: 'ChargeCodeVendorOverrideNode'
          id: string
          description: string
          dateDeleted?: string | null
          chargeCode: {
            __typename?: 'ChargeCodeV2Node'
            id: string
            code: string
            dateDeleted?: string | null
            apiPartnerId?: string | null
          }
        }
      }>
    }
  }>
}

export type CompanyChargeVendorsQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
}>

export type CompanyChargeVendorsQuery = {
  __typename?: 'Query'
  companyChargeVendors: Array<{
    __typename?: 'ChargeVendorNode'
    id: string
    name: string
    code: string
    type?: string | null
    chargeCodes: {
      __typename?: 'ChargeCodeNodeConnection'
      edges: Array<{
        __typename?: 'ChargeCodeNodeEdge'
        node: {
          __typename?: 'ChargeCodeNode'
          id: string
          code: string
          description: string
          dateDeleted?: string | null
          apiPartnerId?: string | null
        }
      }>
    }
  }>
}

export type CompanyChargeVendorsAndOverridesQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
}>

export type CompanyChargeVendorsAndOverridesQuery = {
  __typename?: 'Query'
  companyChargeVendors: Array<{
    __typename?: 'ChargeVendorNode'
    id: string
    name: string
    code: string
    type?: string | null
    chargeCodeVendorOverrides: {
      __typename?: 'ChargeCodeVendorOverrideNodeConnection'
      edges: Array<{
        __typename?: 'ChargeCodeVendorOverrideNodeEdge'
        node: {
          __typename?: 'ChargeCodeVendorOverrideNode'
          id: string
          description: string
          dateDeleted?: string | null
          chargeCodeId: string
          chargeCode: {
            __typename?: 'ChargeCodeV2Node'
            id: string
            code: string
            dateDeleted?: string | null
          }
        }
      }>
    }
  }>
}

export type ChargeVendorCodesQueryVariables = Exact<{
  chargeVendorId: Scalars['UUID']['input']
  pageLimit?: InputMaybe<Scalars['Int']['input']>
  pageOffset?: InputMaybe<Scalars['Int']['input']>
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
}>

export type ChargeVendorCodesQuery = {
  __typename?: 'Query'
  vendorChargeCodes: Array<{
    __typename?: 'ChargeCodeNode'
    id: string
    code: string
    description: string
    dateDeleted?: string | null
    apiPartnerId?: string | null
  }>
}

export type VendorChargeCodeOverridesQueryVariables = Exact<{
  chargeVendorId: Scalars['UUID']['input']
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  pageLimit?: InputMaybe<Scalars['Int']['input']>
  pageOffset?: InputMaybe<Scalars['Int']['input']>
}>

export type VendorChargeCodeOverridesQuery = {
  __typename?: 'Query'
  vendorChargeCodeOverrides: Array<{
    __typename?: 'ChargeCodeVendorOverrideNode'
    id: string
    description: string
    dateDeleted?: string | null
    chargeVendor: {
      __typename?: 'ChargeVendorNode'
      id: string
      name: string
      code: string
      dateDeleted?: string | null
    }
    chargeCode: {
      __typename?: 'ChargeCodeV2Node'
      id: string
      code: string
      description: string
      dateDeleted?: string | null
    }
  }>
}

export type ChargeVendorsOkQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
  chargeVendors: InputVendorCsv
}>

export type ChargeVendorsOkQuery = { __typename?: 'Query'; chargeVendorsOk: boolean }

export type ChargeCodesOkQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
  apiPartnerIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input']
  chargeCodesCsv: InputChargeCodeCsv
}>

export type ChargeCodesOkQuery = { __typename?: 'Query'; chargeCodesOk: boolean }

export type ChargeCodesByVendorNamesQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
  chargeVendorNames: Array<Scalars['String']['input']> | Scalars['String']['input']
  apiPartnerId: Scalars['UUID']['input']
}>

export type ChargeCodesByVendorNamesQuery = {
  __typename?: 'Query'
  chargeCodesByVendorNames: Array<{
    __typename?: 'ChargeCodeSearchResult'
    id: string
    code: string
  }>
}

export type ChargeVendorsByCodesQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
  chargeVendorCodes: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type ChargeVendorsByCodesQuery = {
  __typename?: 'Query'
  chargeVendorsByCodes: Array<{
    __typename?: 'ChargeVendorNode'
    id: string
    name: string
    code: string
    chargeCodes: {
      __typename?: 'ChargeCodeNodeConnection'
      edges: Array<{
        __typename?: 'ChargeCodeNodeEdge'
        node: {
          __typename?: 'ChargeCodeNode'
          id: string
          code: string
          description: string
          cleanedDescription: string
          dateDeleted?: string | null
        }
      }>
    }
  }>
}

export type CompanyChargeCodesQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  query: Scalars['String']['input']
}>

export type CompanyChargeCodesQuery = {
  __typename?: 'Query'
  companyChargeCodes: Array<{
    __typename?: 'ChargeCodeNode'
    id: string
    code: string
    description: string
    dateDeleted?: string | null
    tax?: {
      __typename?: 'TaxNode'
      id: string
      taxCode: string
      taxRate: string
      dateDeleted?: string | null
    } | null
  }>
}

export type CompanyChargeCodesV2QueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  query: Scalars['String']['input']
}>

export type CompanyChargeCodesV2Query = {
  __typename?: 'Query'
  companyChargeCodesV2: Array<{
    __typename?: 'ChargeCodeV2Node'
    id: string
    code: string
    description: string
    dateDeleted?: string | null
    tax?: {
      __typename?: 'TaxNode'
      id: string
      taxCode: string
      taxRate: string
      dateDeleted?: string | null
    } | null
  }>
}

export type AllCompanyChargeCodesQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
}>

export type AllCompanyChargeCodesQuery = {
  __typename?: 'Query'
  allCompanyChargeCodes: Array<string | null>
}

export type ChargeCodesByConfigAndVendorQueryVariables = Exact<{
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  vendorName?: InputMaybe<Scalars['String']['input']>
}>

export type ChargeCodesByConfigAndVendorQuery = {
  __typename?: 'Query'
  chargeCodesByConfigAndVendor: Array<{
    __typename?: 'ChargeCodeNode'
    id: string
    code: string
    description: string
    dateDeleted?: string | null
    tax?: {
      __typename?: 'TaxNode'
      id: string
      taxCode: string
      taxRate: string
      dateDeleted?: string | null
    } | null
  }>
}

export type ChargeCodeTaxesByApiPartnerQueryVariables = Exact<{
  apiPartnerId: Scalars['UUID']['input']
}>

export type ChargeCodeTaxesByApiPartnerQuery = {
  __typename?: 'Query'
  chargeCodesByApiPartner: Array<{
    __typename?: 'ChargeCodeV2Node'
    id: string
    code: string
    tax?: {
      __typename?: 'TaxNode'
      id: string
      taxCode: string
      taxRate: string
      dateDeleted?: string | null
    } | null
  }>
}

export type TaxIdsByApiPartnerQueryVariables = Exact<{
  apiPartnerId: Scalars['UUID']['input']
}>

export type TaxIdsByApiPartnerQuery = {
  __typename?: 'Query'
  taxIdsByApiPartner: Array<{
    __typename?: 'TaxNode'
    id: string
    taxCode: string
    taxRate: string
    dateDeleted?: string | null
  }>
}

export type UniqueChargeCodesByTaxQueryVariables = Exact<{
  taxId: Scalars['UUID']['input']
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
}>

export type UniqueChargeCodesByTaxQuery = {
  __typename?: 'Query'
  uniqueChargeCodesByTax: Array<string>
}

export type ChargeCodeAndVendorOverridesQueryVariables = Exact<{
  chargeCodeId: Scalars['UUID']['input']
}>

export type ChargeCodeAndVendorOverridesQuery = {
  __typename?: 'Query'
  chargeCodeAndVendorOverrides: {
    __typename?: 'ChargeCodeV2Node'
    id: string
    code: string
    description: string
    chargeCodeVendorOverrides: {
      __typename?: 'ChargeCodeVendorOverrideNodeConnection'
      edges: Array<{
        __typename?: 'ChargeCodeVendorOverrideNodeEdge'
        node: {
          __typename?: 'ChargeCodeVendorOverrideNode'
          id: string
          description: string
          dateDeleted?: string | null
          chargeVendor: {
            __typename?: 'ChargeVendorNode'
            id: string
            dateDeleted?: string | null
            name: string
            code: string
            type?: string | null
          }
        }
      }>
    }
  }
}

export type ChargeCodesWithSameCodeQueryVariables = Exact<{
  chargeCodeId: Scalars['UUID']['input']
}>

export type ChargeCodesWithSameCodeQuery = {
  __typename?: 'Query'
  chargeCodes: Array<{
    __typename?: 'ChargeCodeNode'
    id: string
    code: string
    description: string
    chargeVendor: {
      __typename?: 'ChargeVendorNode'
      id: string
      dateDeleted?: string | null
      name: string
      code: string
      type?: string | null
    }
  }>
}

export type SearchTaxesQueryVariables = Exact<{
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  query: Scalars['String']['input']
}>

export type SearchTaxesQuery = {
  __typename?: 'Query'
  searchTaxes: Array<{
    __typename?: 'TaxNode'
    id: string
    taxCode: string
    taxRate: string
    taxType?: string | null
    taxSystem?: string | null
    apiPartnerId?: string | null
    dateDeleted?: string | null
  }>
}

export type ReconAsyncBatchQueryVariables = Exact<{
  reconAsyncBatchId: Scalars['String']['input']
}>

export type ReconAsyncBatchQuery = {
  __typename?: 'Query'
  reconAsyncBatch: {
    __typename?: 'ReconAsyncBatchNode'
    id: string
    numTasks: number
    dateCreated: string
    errorMessage?: string | null
    status:
      | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
      | { __typename?: 'ReconAsyncStatusEnum'; value: ReconAsyncStatus; isFallback: boolean }
  }
}

export type ChargeCodeUploadAsyncTaskQueryVariables = Exact<{
  chargeCodeAsyncTaskId: Scalars['String']['input']
}>

export type ChargeCodeUploadAsyncTaskQuery = {
  __typename?: 'Query'
  chargeCodeUploadAsyncTask: {
    __typename?: 'ChargeCodeUploadAsyncTaskNode'
    id: string
    status: ChargeCodeUploadAsyncStatus
    percentDone?: number | null
    errorMessage?: string | null
    dateCreated: string
  }
}

export type InvoiceReconLineItemSnapshotFragment = {
  __typename?: 'InvoiceReconLineItemSnapshotNode'
  type: InvoiceReconLineItemType
  chargeCode?: string | null
  currency?: string | null
  chargeCost?: string | null
  quantity?: string | null
  chargeDescription?: string | null
  unitPrice?: string | null
  taxId?: string | null
}

export type SoaReconLineItemSnapshotFragment = {
  __typename?: 'SOAReconLineItemSnapshotNode'
  type: InvoiceReconLineItemType
  referenceNumber?: string | null
  hblNumber?: string | null
  mblNumber?: string | null
  carrierBookingNumber?: string | null
  containerNumber?: string | null
  invoiceNumber?: string | null
  orderNumber?: string | null
  consolNumber?: string | null
  invoiceDate?: string | null
  dueDate?: string | null
}

export type ReconAttemptWithResultsAndMetadataFragment = {
  __typename?: 'ReconAttemptNode'
  id: string
  isSoaMetadataAttempt?: boolean | null
  dateSaved?: string | null
  dateCreated: string
  reconStatus: ReconciliationStatus
  errorNotes?: string | null
  expedockAction?: string | null
  matchingCriteria:
    | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
    | { __typename?: 'MatchingCriteriaTypeEnum'; value: MatchingCriteriaType; isFallback: boolean }
  cargowiseModule?:
    | { __typename?: 'CwTargetModuleEnum'; value: CwTargetModule; isFallback: boolean }
    | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
    | null
  reconDetail: {
    __typename?: 'ReconDetail'
    vendor?: string | null
    tmsId?: string | null
    shipmentNo?: string | null
    invoiceNo?: string | null
    hblNo?: string | null
    mblNo?: string | null
    referenceNo?: string | null
    containerNo?: string | null
    carrierBookingNo?: string | null
    orderNo?: string | null
    consolNo?: string | null
    dueDate?: string | null
  }
  docCharges: Array<{
    __typename?: 'ChargeDetail'
    id: string
    chargeCode?: string | null
    chargeDescription?: string | null
    chargeCost?: string | null
    currency?: string | null
    vendor?: string | null
    invoiceNumber?: string | null
    taxId?: string | null
    taxRate?: string | null
    invoiceDate?: string | null
    dueDate?: string | null
  }>
  expectedCharges: Array<{
    __typename?: 'ChargeDetail'
    id: string
    chainIoSiLineId?: string | null
    chargeCode?: string | null
    chargeDescription?: string | null
    chargeCost?: string | null
    currency?: string | null
    vendor?: string | null
    invoiceNumber?: string | null
    taxId?: string | null
    taxRate?: string | null
    invoiceDate?: string | null
    dueDate?: string | null
    isPosted?: boolean | null
    overseasSellAmount?: string | null
    sellCurrency?: string | null
  }>
  reconResults: Array<
    | {
        __typename?: 'ArrivalNoticeChargeRateReconResultNode'
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'ArrivalNoticeConsolTypeReconResultNode'
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'ArrivalNoticeContainerReconResultNode'
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'ArrivalNoticeContractReconResultNode'
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'ArrivalNoticeLumpsumReconResultNode'
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'ArrivalNoticeMetadataReconResultNode'
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'FindConsolReconResultNode'
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'FindInvoiceReconResultNode'
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        invoiceReconMatches: {
          __typename?: 'InvoiceReconMatchNodeConnection'
          edges: Array<{
            __typename?: 'InvoiceReconMatchNodeEdge'
            node: {
              __typename?: 'InvoiceReconMatchNode'
              id: string
              chainIoSi: {
                __typename?: 'ChainIOServiceInvoiceNode'
                id: string
                invoiceNumber?: string | null
              }
            }
          }>
        }
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'FindShipmentDuplicateReconResultNode'
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'FindShipmentReconResultNode'
        referenceNo?: string | null
        hblNo?: string | null
        mblNo?: string | null
        orderNo?: string | null
        consolNo?: string | null
        carrierBookingNo?: string | null
        containerNo?: string | null
        isPrimary: boolean
        isMaximalAmongDuplicates?: boolean | null
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        chainIoShipment?: {
          __typename?: 'ChainIOShipmentNode'
          id: string
          forwarderReference?: string | null
          dateModified: string
          houseBill?: string | null
          masterBill?: string | null
          carrierBookingNumber?: string | null
          bookingReference?: string | null
          containers: {
            __typename?: 'ChainIOContainerNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOContainerNodeEdge'
              node: {
                __typename?: 'ChainIOContainerNode'
                id: string
                containerNumber?: string | null
              }
            }>
          }
          chainIoConsolidations: {
            __typename?: 'ChainIOConsolidationNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOConsolidationNodeEdge'
              node: {
                __typename?: 'ChainIOConsolidationNode'
                id: string
                forwarderReference?: string | null
              }
            }>
          }
          orders: {
            __typename?: 'ChainIOOrderNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOOrderNodeEdge'
              node: { __typename?: 'ChainIOOrderNode'; id: string; orderReference?: string | null }
            }>
          }
        } | null
        chainIoConsolidation?: {
          __typename?: 'ChainIOConsolidationNode'
          id: string
          forwarderReference?: string | null
          dateModified: string
          masterBill?: string | null
          carrierBookingRef?: string | null
          containers: {
            __typename?: 'ChainIOContainerNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOContainerNodeEdge'
              node: {
                __typename?: 'ChainIOContainerNode'
                id: string
                containerNumber?: string | null
              }
            }>
          }
          shipments: {
            __typename?: 'ChainIOShipmentNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOShipmentNodeEdge'
              node: {
                __typename?: 'ChainIOShipmentNode'
                id: string
                forwarderReference?: string | null
                houseBill?: string | null
                orders: {
                  __typename?: 'ChainIOOrderNodeConnection'
                  edges: Array<{
                    __typename?: 'ChainIOOrderNodeEdge'
                    node: {
                      __typename?: 'ChainIOOrderNode'
                      id: string
                      orderReference?: string | null
                    }
                  }>
                }
              }
            }>
          }
        } | null
        chainIoCustomsDeclaration?: {
          __typename?: 'ChainIOCustomsDeclarationNode'
          id: string
          forwarderReference?: string | null
          dateModified: string
          houseBill?: string | null
          masterBill?: string | null
          bookingReference?: string | null
          containers: {
            __typename?: 'ChainIOContainerNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOContainerNodeEdge'
              node: {
                __typename?: 'ChainIOContainerNode'
                id: string
                containerNumber?: string | null
              }
            }>
          }
          orders: {
            __typename?: 'ChainIOOrderNodeConnection'
            edges: Array<{
              __typename?: 'ChainIOOrderNodeEdge'
              node: { __typename?: 'ChainIOOrderNode'; id: string; orderReference?: string | null }
            }>
          }
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            carrierBookingNumber?: string | null
            forwarderReference?: string | null
            chainIoConsolidations: {
              __typename?: 'ChainIOConsolidationNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOConsolidationNodeEdge'
                node: {
                  __typename?: 'ChainIOConsolidationNode'
                  id: string
                  forwarderReference?: string | null
                }
              }>
            }
          } | null
        } | null
        findShipmentDuplicateReconResults: {
          __typename?: 'FindShipmentDuplicateReconResultNodeConnection'
          edges: Array<{
            __typename?: 'FindShipmentDuplicateReconResultNodeEdge'
            node: {
              __typename?: 'FindShipmentDuplicateReconResultNode'
              id: string
              chainIoShipment?: {
                __typename?: 'ChainIOShipmentNode'
                id: string
                forwarderReference?: string | null
                isCancelled: boolean
                tmsId?: string | null
              } | null
              chainIoConsolidation?: {
                __typename?: 'ChainIOConsolidationNode'
                id: string
                forwarderReference?: string | null
                isCancelled?: boolean | null
                tmsId?: string | null
              } | null
              chainIoCustomsDeclaration?: {
                __typename?: 'ChainIOCustomsDeclarationNode'
                id: string
                forwarderReference?: string | null
                isCancelled?: boolean | null
                tmsId?: string | null
              } | null
            }
          }>
        }
        invoiceReconLineItemSnapshots: {
          __typename?: 'InvoiceReconLineItemSnapshotInterfaceConnection'
          edges: Array<{
            __typename?: 'InvoiceReconLineItemSnapshotInterfaceEdge'
            node:
              | {
                  __typename?: 'InvoiceReconLineItemSnapshotNode'
                  id: string
                  type: InvoiceReconLineItemType
                  chargeCode?: string | null
                  currency?: string | null
                  chargeCost?: string | null
                  quantity?: string | null
                  chargeDescription?: string | null
                  unitPrice?: string | null
                  taxId?: string | null
                }
              | {
                  __typename?: 'SOAReconLineItemSnapshotNode'
                  id: string
                  type: InvoiceReconLineItemType
                  referenceNumber?: string | null
                  hblNumber?: string | null
                  mblNumber?: string | null
                  carrierBookingNumber?: string | null
                  containerNumber?: string | null
                  invoiceNumber?: string | null
                  orderNumber?: string | null
                  consolNumber?: string | null
                  invoiceDate?: string | null
                  dueDate?: string | null
                }
          }>
        }
      }
    | {
        __typename?: 'InvoiceLineItemReconResultNode'
        invoiceAmount?: string | null
        invoiceTaxAmount?: string | null
        expectedAmount?: string | null
        expectedTaxAmount?: string | null
        invoiceChargeCodeCode?: string | null
        invoiceNumber?: string | null
        invoiceDate?: string | null
        dueDate?: string | null
        invoiceTaxId?: string | null
        expectedTaxId?: string | null
        invoiceCurrency?: string | null
        invoiceUnitPrice?: string | null
        invoiceQuantity?: string | null
        chainIoConsolCostId?: string | null
        chainIoCustomsDeclarationCostId?: string | null
        isChargeCodeSame?: boolean | null
        isTotalCostEqual?: boolean | null
        isCurrencySameOrEmpty?: boolean | null
        isChargeVendorSame?: boolean | null
        isInvoiceNumberCorrect?: boolean | null
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        invoiceChargeCode?: {
          __typename?: 'ChargeCodeNode'
          id: string
          code: string
          chargeVendor: { __typename?: 'ChargeVendorNode'; id: string; name: string }
        } | null
        invoiceChargeVendor?: { __typename?: 'ChargeVendorNode'; id: string; code: string } | null
        expectedChargeCode?: {
          __typename?: 'ChargeCodeNode'
          id: string
          code: string
          chargeVendor: { __typename?: 'ChargeVendorNode'; id: string; name: string }
        } | null
        chainIoSiLine?: { __typename?: 'ChainIOServiceInvoiceLineNode'; id: string } | null
        duplicateChainIoSiLines: {
          __typename?: 'DuplicateChainIOServiceInvoiceLineNodeConnection'
          edges: Array<{
            __typename?: 'DuplicateChainIOServiceInvoiceLineNodeEdge'
            node: {
              __typename?: 'DuplicateChainIOServiceInvoiceLineNode'
              id: string
              chainIoSiLine?: { __typename?: 'ChainIOServiceInvoiceLineNode'; id: string } | null
            }
          }>
        }
        invoiceReconLineItemSnapshot?:
          | {
              __typename?: 'InvoiceReconLineItemSnapshotNode'
              id: string
              type: InvoiceReconLineItemType
              chargeCode?: string | null
              currency?: string | null
              chargeCost?: string | null
              quantity?: string | null
              chargeDescription?: string | null
              unitPrice?: string | null
              taxId?: string | null
            }
          | {
              __typename?: 'SOAReconLineItemSnapshotNode'
              id: string
              type: InvoiceReconLineItemType
              referenceNumber?: string | null
              hblNumber?: string | null
              mblNumber?: string | null
              carrierBookingNumber?: string | null
              containerNumber?: string | null
              invoiceNumber?: string | null
              orderNumber?: string | null
              consolNumber?: string | null
              invoiceDate?: string | null
              dueDate?: string | null
            }
          | null
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'InvoiceTotalReconResultNode'
        usedThresholdMatching: boolean
        isWithinThreshold: boolean
        id: string
        success: boolean
        invoiceTotalAmount: string
        invoiceTaxedTotalAmount?: string | null
        expectedTotalAmount: string
        expectedTaxedTotalAmount?: string | null
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        reconThresholdRange?: {
          __typename?: 'ReconThresholdRangeNode'
          id: string
          thresholdAmount: string
        } | null
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'MetadataReconResultNode'
        invoiceValue?: string | null
        expectedValue?: string | null
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        key:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | {
              __typename?: 'MetadataReconResultKeyEnum'
              value: MetadataReconResultKey
              isFallback: boolean
            }
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'ReconResultFallbackNode'
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'SOATotalReconResultNode'
        id: string
        success: boolean
        soaTotalAmount: string
        expectedTotalAmount: string
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
    | {
        __typename?: 'ShipmentStaffReconResultNode'
        operationsStaffName?: string | null
        operationsStaffCode?: string | null
        salesStaffName?: string | null
        salesStaffCode?: string | null
        id: string
        success: boolean
        type:
          | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
          | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
        chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
      }
  >
  matchReconInvoiceShipmentReference?: {
    __typename?: 'ReconInvoiceShipmentReferenceNode'
    id: string
    externalStatus?: JobExternalStatus | null
    isModified: boolean
    externalAssignee?: { __typename?: 'UserNode'; id: string; email: string } | null
  } | null
  noMatchReconMatchCriteria?: {
    __typename?: 'ReconMatchCriteriaNode'
    id: string
    externalStatus?: JobExternalStatus | null
    isModified: boolean
    externalAssignee?: { __typename?: 'UserNode'; id: string; email: string } | null
  } | null
}

export type ReconAttemptsQueryVariables = Exact<{
  reconAsyncBatchId: Scalars['String']['input']
}>

export type ReconAttemptsQuery = {
  __typename?: 'Query'
  reconAttempts: Array<{
    __typename?: 'ReconAttemptNode'
    id: string
    isSoaMetadataAttempt?: boolean | null
    dateSaved?: string | null
    dateCreated: string
    reconStatus: ReconciliationStatus
    errorNotes?: string | null
    expedockAction?: string | null
    job: {
      __typename?: 'JobNode'
      id: string
      jobTemplate: {
        __typename?: 'JobTemplateNode'
        id: string
        apiPartnerId?: string | null
        companyId: string
        requireExternalAssignee?: boolean | null
        defaultExternalAssignee?: { __typename?: 'UserNode'; id: string; email: string } | null
      }
    }
    matchingCriteria:
      | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
      | {
          __typename?: 'MatchingCriteriaTypeEnum'
          value: MatchingCriteriaType
          isFallback: boolean
        }
    cargowiseModule?:
      | { __typename?: 'CwTargetModuleEnum'; value: CwTargetModule; isFallback: boolean }
      | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
      | null
    reconDetail: {
      __typename?: 'ReconDetail'
      vendor?: string | null
      tmsId?: string | null
      shipmentNo?: string | null
      invoiceNo?: string | null
      hblNo?: string | null
      mblNo?: string | null
      referenceNo?: string | null
      containerNo?: string | null
      carrierBookingNo?: string | null
      orderNo?: string | null
      consolNo?: string | null
      dueDate?: string | null
    }
    docCharges: Array<{
      __typename?: 'ChargeDetail'
      id: string
      chargeCode?: string | null
      chargeDescription?: string | null
      chargeCost?: string | null
      currency?: string | null
      vendor?: string | null
      invoiceNumber?: string | null
      taxId?: string | null
      taxRate?: string | null
      invoiceDate?: string | null
      dueDate?: string | null
    }>
    expectedCharges: Array<{
      __typename?: 'ChargeDetail'
      id: string
      chainIoSiLineId?: string | null
      chargeCode?: string | null
      chargeDescription?: string | null
      chargeCost?: string | null
      currency?: string | null
      vendor?: string | null
      invoiceNumber?: string | null
      taxId?: string | null
      taxRate?: string | null
      invoiceDate?: string | null
      dueDate?: string | null
      isPosted?: boolean | null
      overseasSellAmount?: string | null
      sellCurrency?: string | null
    }>
    reconResults: Array<
      | {
          __typename?: 'ArrivalNoticeChargeRateReconResultNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'ArrivalNoticeConsolTypeReconResultNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'ArrivalNoticeContainerReconResultNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'ArrivalNoticeContractReconResultNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'ArrivalNoticeLumpsumReconResultNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'ArrivalNoticeMetadataReconResultNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'FindConsolReconResultNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'FindInvoiceReconResultNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          invoiceReconMatches: {
            __typename?: 'InvoiceReconMatchNodeConnection'
            edges: Array<{
              __typename?: 'InvoiceReconMatchNodeEdge'
              node: {
                __typename?: 'InvoiceReconMatchNode'
                id: string
                chainIoSi: {
                  __typename?: 'ChainIOServiceInvoiceNode'
                  id: string
                  invoiceNumber?: string | null
                }
              }
            }>
          }
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'FindShipmentDuplicateReconResultNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'FindShipmentReconResultNode'
          referenceNo?: string | null
          hblNo?: string | null
          mblNo?: string | null
          orderNo?: string | null
          consolNo?: string | null
          carrierBookingNo?: string | null
          containerNo?: string | null
          isPrimary: boolean
          isMaximalAmongDuplicates?: boolean | null
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
            houseBill?: string | null
            masterBill?: string | null
            carrierBookingNumber?: string | null
            bookingReference?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
            chainIoConsolidations: {
              __typename?: 'ChainIOConsolidationNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOConsolidationNodeEdge'
                node: {
                  __typename?: 'ChainIOConsolidationNode'
                  id: string
                  forwarderReference?: string | null
                }
              }>
            }
            orders: {
              __typename?: 'ChainIOOrderNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOOrderNodeEdge'
                node: {
                  __typename?: 'ChainIOOrderNode'
                  id: string
                  orderReference?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
            masterBill?: string | null
            carrierBookingRef?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
            shipments: {
              __typename?: 'ChainIOShipmentNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOShipmentNodeEdge'
                node: {
                  __typename?: 'ChainIOShipmentNode'
                  id: string
                  forwarderReference?: string | null
                  houseBill?: string | null
                  orders: {
                    __typename?: 'ChainIOOrderNodeConnection'
                    edges: Array<{
                      __typename?: 'ChainIOOrderNodeEdge'
                      node: {
                        __typename?: 'ChainIOOrderNode'
                        id: string
                        orderReference?: string | null
                      }
                    }>
                  }
                }
              }>
            }
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
            houseBill?: string | null
            masterBill?: string | null
            bookingReference?: string | null
            containers: {
              __typename?: 'ChainIOContainerNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOContainerNodeEdge'
                node: {
                  __typename?: 'ChainIOContainerNode'
                  id: string
                  containerNumber?: string | null
                }
              }>
            }
            orders: {
              __typename?: 'ChainIOOrderNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOOrderNodeEdge'
                node: {
                  __typename?: 'ChainIOOrderNode'
                  id: string
                  orderReference?: string | null
                }
              }>
            }
            chainIoShipment?: {
              __typename?: 'ChainIOShipmentNode'
              id: string
              carrierBookingNumber?: string | null
              forwarderReference?: string | null
              chainIoConsolidations: {
                __typename?: 'ChainIOConsolidationNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOConsolidationNodeEdge'
                  node: {
                    __typename?: 'ChainIOConsolidationNode'
                    id: string
                    forwarderReference?: string | null
                  }
                }>
              }
            } | null
          } | null
          findShipmentDuplicateReconResults: {
            __typename?: 'FindShipmentDuplicateReconResultNodeConnection'
            edges: Array<{
              __typename?: 'FindShipmentDuplicateReconResultNodeEdge'
              node: {
                __typename?: 'FindShipmentDuplicateReconResultNode'
                id: string
                chainIoShipment?: {
                  __typename?: 'ChainIOShipmentNode'
                  id: string
                  forwarderReference?: string | null
                  isCancelled: boolean
                  tmsId?: string | null
                } | null
                chainIoConsolidation?: {
                  __typename?: 'ChainIOConsolidationNode'
                  id: string
                  forwarderReference?: string | null
                  isCancelled?: boolean | null
                  tmsId?: string | null
                } | null
                chainIoCustomsDeclaration?: {
                  __typename?: 'ChainIOCustomsDeclarationNode'
                  id: string
                  forwarderReference?: string | null
                  isCancelled?: boolean | null
                  tmsId?: string | null
                } | null
              }
            }>
          }
          invoiceReconLineItemSnapshots: {
            __typename?: 'InvoiceReconLineItemSnapshotInterfaceConnection'
            edges: Array<{
              __typename?: 'InvoiceReconLineItemSnapshotInterfaceEdge'
              node:
                | {
                    __typename?: 'InvoiceReconLineItemSnapshotNode'
                    id: string
                    type: InvoiceReconLineItemType
                    chargeCode?: string | null
                    currency?: string | null
                    chargeCost?: string | null
                    quantity?: string | null
                    chargeDescription?: string | null
                    unitPrice?: string | null
                    taxId?: string | null
                  }
                | {
                    __typename?: 'SOAReconLineItemSnapshotNode'
                    id: string
                    type: InvoiceReconLineItemType
                    referenceNumber?: string | null
                    hblNumber?: string | null
                    mblNumber?: string | null
                    carrierBookingNumber?: string | null
                    containerNumber?: string | null
                    invoiceNumber?: string | null
                    orderNumber?: string | null
                    consolNumber?: string | null
                    invoiceDate?: string | null
                    dueDate?: string | null
                  }
            }>
          }
        }
      | {
          __typename?: 'InvoiceLineItemReconResultNode'
          invoiceAmount?: string | null
          invoiceTaxAmount?: string | null
          expectedAmount?: string | null
          expectedTaxAmount?: string | null
          invoiceChargeCodeCode?: string | null
          invoiceNumber?: string | null
          invoiceDate?: string | null
          dueDate?: string | null
          invoiceTaxId?: string | null
          expectedTaxId?: string | null
          invoiceCurrency?: string | null
          invoiceUnitPrice?: string | null
          invoiceQuantity?: string | null
          chainIoConsolCostId?: string | null
          chainIoCustomsDeclarationCostId?: string | null
          isChargeCodeSame?: boolean | null
          isTotalCostEqual?: boolean | null
          isCurrencySameOrEmpty?: boolean | null
          isChargeVendorSame?: boolean | null
          isInvoiceNumberCorrect?: boolean | null
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          invoiceChargeCode?: {
            __typename?: 'ChargeCodeNode'
            id: string
            code: string
            chargeVendor: { __typename?: 'ChargeVendorNode'; id: string; name: string }
          } | null
          invoiceChargeVendor?: { __typename?: 'ChargeVendorNode'; id: string; code: string } | null
          expectedChargeCode?: {
            __typename?: 'ChargeCodeNode'
            id: string
            code: string
            chargeVendor: { __typename?: 'ChargeVendorNode'; id: string; name: string }
          } | null
          chainIoSiLine?: { __typename?: 'ChainIOServiceInvoiceLineNode'; id: string } | null
          duplicateChainIoSiLines: {
            __typename?: 'DuplicateChainIOServiceInvoiceLineNodeConnection'
            edges: Array<{
              __typename?: 'DuplicateChainIOServiceInvoiceLineNodeEdge'
              node: {
                __typename?: 'DuplicateChainIOServiceInvoiceLineNode'
                id: string
                chainIoSiLine?: { __typename?: 'ChainIOServiceInvoiceLineNode'; id: string } | null
              }
            }>
          }
          invoiceReconLineItemSnapshot?:
            | {
                __typename?: 'InvoiceReconLineItemSnapshotNode'
                id: string
                type: InvoiceReconLineItemType
                chargeCode?: string | null
                currency?: string | null
                chargeCost?: string | null
                quantity?: string | null
                chargeDescription?: string | null
                unitPrice?: string | null
                taxId?: string | null
              }
            | {
                __typename?: 'SOAReconLineItemSnapshotNode'
                id: string
                type: InvoiceReconLineItemType
                referenceNumber?: string | null
                hblNumber?: string | null
                mblNumber?: string | null
                carrierBookingNumber?: string | null
                containerNumber?: string | null
                invoiceNumber?: string | null
                orderNumber?: string | null
                consolNumber?: string | null
                invoiceDate?: string | null
                dueDate?: string | null
              }
            | null
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'InvoiceTotalReconResultNode'
          usedThresholdMatching: boolean
          isWithinThreshold: boolean
          id: string
          success: boolean
          invoiceTotalAmount: string
          invoiceTaxedTotalAmount?: string | null
          expectedTotalAmount: string
          expectedTaxedTotalAmount?: string | null
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          reconThresholdRange?: {
            __typename?: 'ReconThresholdRangeNode'
            id: string
            thresholdAmount: string
          } | null
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'MetadataReconResultNode'
          invoiceValue?: string | null
          expectedValue?: string | null
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          key:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | {
                __typename?: 'MetadataReconResultKeyEnum'
                value: MetadataReconResultKey
                isFallback: boolean
              }
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'ReconResultFallbackNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'SOATotalReconResultNode'
          id: string
          success: boolean
          soaTotalAmount: string
          expectedTotalAmount: string
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
      | {
          __typename?: 'ShipmentStaffReconResultNode'
          operationsStaffName?: string | null
          operationsStaffCode?: string | null
          salesStaffName?: string | null
          salesStaffCode?: string | null
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
        }
    >
    matchReconInvoiceShipmentReference?: {
      __typename?: 'ReconInvoiceShipmentReferenceNode'
      id: string
      externalStatus?: JobExternalStatus | null
      isModified: boolean
      externalAssignee?: { __typename?: 'UserNode'; id: string; email: string } | null
    } | null
    noMatchReconMatchCriteria?: {
      __typename?: 'ReconMatchCriteriaNode'
      id: string
      externalStatus?: JobExternalStatus | null
      isModified: boolean
      externalAssignee?: { __typename?: 'UserNode'; id: string; email: string } | null
    } | null
  }>
}

export type FindInvoiceReconResultFromJobQueryVariables = Exact<{
  jobId: Scalars['UUID']['input']
  lumpsum?: InputMaybe<InputDocumentTable>
  overrideChargeDescription: Scalars['Boolean']['input']
  disableSendDueDate: Scalars['Boolean']['input']
  matchingCriteria: MatchingCriteriaType
}>

export type FindInvoiceReconResultFromJobQuery = {
  __typename?: 'Query'
  findInvoiceReconResultFromJob: {
    __typename?: 'FindInvoiceReconResultFromJobNode'
    docCharges: Array<{
      __typename?: 'ChargeDetail'
      id: string
      chargeCode?: string | null
      chargeDescription?: string | null
      chargeCost?: string | null
      currency?: string | null
      vendor?: string | null
      invoiceNumber?: string | null
      taxId?: string | null
      taxRate?: string | null
      invoiceDate?: string | null
      dueDate?: string | null
    }>
    expectedCharges: Array<{
      __typename?: 'ChargeDetail'
      id: string
      chainIoSiLineId?: string | null
      chargeCode?: string | null
      chargeDescription?: string | null
      chargeCost?: string | null
      currency?: string | null
      vendor?: string | null
      invoiceNumber?: string | null
      taxId?: string | null
      taxRate?: string | null
      invoiceDate?: string | null
      dueDate?: string | null
      isPosted?: boolean | null
      overseasSellAmount?: string | null
      sellCurrency?: string | null
    }>
    reconAttempt?: {
      __typename?: 'ReconAttemptNode'
      id: string
      job: {
        __typename?: 'JobNode'
        id: string
        jobTemplate: {
          __typename?: 'JobTemplateNode'
          id: string
          apiPartnerId?: string | null
          companyId: string
        }
      }
      reconDetail: {
        __typename?: 'ReconDetail'
        vendor?: string | null
        tmsId?: string | null
        shipmentNo?: string | null
        invoiceNo?: string | null
        hblNo?: string | null
        mblNo?: string | null
        referenceNo?: string | null
        containerNo?: string | null
        carrierBookingNo?: string | null
        consolNo?: string | null
        orderNo?: string | null
        dueDate?: string | null
      }
      cargowiseModule?:
        | { __typename?: 'CwTargetModuleEnum'; value: CwTargetModule; isFallback: boolean }
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | null
      matchingCriteria:
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | {
            __typename?: 'MatchingCriteriaTypeEnum'
            value: MatchingCriteriaType
            isFallback: boolean
          }
    } | null
    reconResults: Array<
      | {
          __typename?: 'ArrivalNoticeChargeRateReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeConsolTypeReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeContainerReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeContractReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeLumpsumReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ArrivalNoticeMetadataReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'FindConsolReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'FindInvoiceReconResultNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          invoiceReconMatches: {
            __typename?: 'InvoiceReconMatchNodeConnection'
            edges: Array<{
              __typename?: 'InvoiceReconMatchNodeEdge'
              node: {
                __typename?: 'InvoiceReconMatchNode'
                id: string
                chainIoSi: {
                  __typename?: 'ChainIOServiceInvoiceNode'
                  id: string
                  invoiceNumber?: string | null
                }
              }
            }>
          }
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'FindShipmentDuplicateReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'FindShipmentReconResultNode'
          referenceNo?: string | null
          hblNo?: string | null
          mblNo?: string | null
          carrierBookingNo?: string | null
          containerNo?: string | null
          consolNo?: string | null
          orderNo?: string | null
          isPrimary: boolean
          isMaximalAmongDuplicates?: boolean | null
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            forwarderReference?: string | null
            dateModified: string
            chainIoConsolidations: {
              __typename?: 'ChainIOConsolidationNodeConnection'
              edges: Array<{
                __typename?: 'ChainIOConsolidationNodeEdge'
                node: {
                  __typename?: 'ChainIOConsolidationNode'
                  id: string
                  forwarderReference?: string | null
                }
              }>
            }
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            forwarderReference?: string | null
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          } | null
          findShipmentDuplicateReconResults: {
            __typename?: 'FindShipmentDuplicateReconResultNodeConnection'
            edges: Array<{
              __typename?: 'FindShipmentDuplicateReconResultNodeEdge'
              node: {
                __typename?: 'FindShipmentDuplicateReconResultNode'
                id: string
                chainIoShipment?: {
                  __typename?: 'ChainIOShipmentNode'
                  id: string
                  forwarderReference?: string | null
                  isCancelled: boolean
                  tmsId?: string | null
                } | null
                chainIoConsolidation?: {
                  __typename?: 'ChainIOConsolidationNode'
                  id: string
                  forwarderReference?: string | null
                  isCancelled?: boolean | null
                  tmsId?: string | null
                } | null
                chainIoCustomsDeclaration?: {
                  __typename?: 'ChainIOCustomsDeclarationNode'
                  id: string
                  forwarderReference?: string | null
                  isCancelled?: boolean | null
                  tmsId?: string | null
                } | null
              }
            }>
          }
        }
      | {
          __typename?: 'InvoiceLineItemReconResultNode'
          invoiceAmount?: string | null
          invoiceTaxAmount?: string | null
          expectedAmount?: string | null
          expectedTaxAmount?: string | null
          invoiceChargeCodeCode?: string | null
          invoiceNumber?: string | null
          invoiceTaxId?: string | null
          invoiceDate?: string | null
          dueDate?: string | null
          expectedTaxId?: string | null
          invoiceCurrency?: string | null
          invoiceUnitPrice?: string | null
          invoiceQuantity?: string | null
          isChargeCodeSame?: boolean | null
          isTotalCostEqual?: boolean | null
          isCurrencySameOrEmpty?: boolean | null
          isChargeVendorSame?: boolean | null
          isInvoiceNumberCorrect?: boolean | null
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          invoiceChargeCode?: {
            __typename?: 'ChargeCodeNode'
            id: string
            code: string
            chargeVendor: { __typename?: 'ChargeVendorNode'; id: string; name: string }
          } | null
          expectedChargeCode?: {
            __typename?: 'ChargeCodeNode'
            id: string
            code: string
            chargeVendor: { __typename?: 'ChargeVendorNode'; id: string; name: string }
          } | null
          invoiceChargeVendor?: { __typename?: 'ChargeVendorNode'; id: string; code: string } | null
          chainIoSiLine?: { __typename?: 'ChainIOServiceInvoiceLineNode'; id: string } | null
          duplicateChainIoSiLines: {
            __typename?: 'DuplicateChainIOServiceInvoiceLineNodeConnection'
            edges: Array<{
              __typename?: 'DuplicateChainIOServiceInvoiceLineNodeEdge'
              node: {
                __typename?: 'DuplicateChainIOServiceInvoiceLineNode'
                id: string
                chainIoSiLine?: { __typename?: 'ChainIOServiceInvoiceLineNode'; id: string } | null
              }
            }>
          }
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'InvoiceTotalReconResultNode'
          id: string
          success: boolean
          invoiceTotalAmount: string
          invoiceTaxedTotalAmount?: string | null
          expectedTotalAmount: string
          expectedTaxedTotalAmount?: string | null
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'MetadataReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ReconResultFallbackNode'
          id: string
          success: boolean
          type:
            | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
            | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'SOATotalReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
      | {
          __typename?: 'ShipmentStaffReconResultNode'
          id: string
          success: boolean
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            dateModified: string
          } | null
        }
    >
  }
}

export type FindShipmentReconResultsQueryVariables = Exact<{
  jobId: Scalars['UUID']['input']
}>

export type FindShipmentReconResultsQuery = {
  __typename?: 'Query'
  findShipmentReconResults: Array<{
    __typename?: 'FindShipmentReconResultNode'
    id: string
    success: boolean
    referenceNo?: string | null
    hblNo?: string | null
    mblNo?: string | null
    consolNo?: string | null
    orderNo?: string | null
    carrierBookingNo?: string | null
    containerNo?: string | null
    type:
      | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
      | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
    findShipmentDuplicateReconResults: {
      __typename?: 'FindShipmentDuplicateReconResultNodeConnection'
      edges: Array<{
        __typename?: 'FindShipmentDuplicateReconResultNodeEdge'
        node: {
          __typename?: 'FindShipmentDuplicateReconResultNode'
          id: string
          chainIoShipment?: {
            __typename?: 'ChainIOShipmentNode'
            id: string
            forwarderReference?: string | null
            isCancelled: boolean
            tmsId?: string | null
          } | null
          chainIoConsolidation?: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
            isCancelled?: boolean | null
            tmsId?: string | null
          } | null
          chainIoCustomsDeclaration?: {
            __typename?: 'ChainIOCustomsDeclarationNode'
            id: string
            forwarderReference?: string | null
            isCancelled?: boolean | null
            tmsId?: string | null
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          } | null
        }
      }>
    }
    chainIoShipment?: {
      __typename?: 'ChainIOShipmentNode'
      id: string
      forwarderReference?: string | null
      chainIoConsolidations: {
        __typename?: 'ChainIOConsolidationNodeConnection'
        edges: Array<{
          __typename?: 'ChainIOConsolidationNodeEdge'
          node: {
            __typename?: 'ChainIOConsolidationNode'
            id: string
            forwarderReference?: string | null
          }
        }>
      }
    } | null
    chainIoConsolidation?: {
      __typename?: 'ChainIOConsolidationNode'
      id: string
      forwarderReference?: string | null
    } | null
    chainIoCustomsDeclaration?: {
      __typename?: 'ChainIOCustomsDeclarationNode'
      id: string
      forwarderReference?: string | null
      chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
    } | null
  }>
}

export type FindSoaShipmentsReconResultsQueryVariables = Exact<{
  jobId: Scalars['UUID']['input']
  inputSoaLineItems: Array<InputSoaLineItem> | InputSoaLineItem
}>

export type FindSoaShipmentsReconResultsQuery = {
  __typename?: 'Query'
  findSoaShipmentsReconResults: Array<
    Array<{
      __typename?: 'FindShipmentReconResultNode'
      id: string
      success: boolean
      referenceNo?: string | null
      hblNo?: string | null
      mblNo?: string | null
      consolNo?: string | null
      orderNo?: string | null
      carrierBookingNo?: string | null
      containerNo?: string | null
      type:
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
      findShipmentDuplicateReconResults: {
        __typename?: 'FindShipmentDuplicateReconResultNodeConnection'
        edges: Array<{
          __typename?: 'FindShipmentDuplicateReconResultNodeEdge'
          node: {
            __typename?: 'FindShipmentDuplicateReconResultNode'
            id: string
            chainIoShipment?: {
              __typename?: 'ChainIOShipmentNode'
              id: string
              forwarderReference?: string | null
              isCancelled: boolean
              tmsId?: string | null
            } | null
            chainIoConsolidation?: {
              __typename?: 'ChainIOConsolidationNode'
              id: string
              forwarderReference?: string | null
              isCancelled?: boolean | null
              tmsId?: string | null
            } | null
            chainIoCustomsDeclaration?: {
              __typename?: 'ChainIOCustomsDeclarationNode'
              id: string
              forwarderReference?: string | null
              isCancelled?: boolean | null
              tmsId?: string | null
            } | null
          }
        }>
      }
      chainIoShipment?: {
        __typename?: 'ChainIOShipmentNode'
        id: string
        forwarderReference?: string | null
        chainIoConsolidations: {
          __typename?: 'ChainIOConsolidationNodeConnection'
          edges: Array<{
            __typename?: 'ChainIOConsolidationNodeEdge'
            node: {
              __typename?: 'ChainIOConsolidationNode'
              id: string
              forwarderReference?: string | null
            }
          }>
        }
      } | null
      chainIoConsolidation?: {
        __typename?: 'ChainIOConsolidationNode'
        id: string
        forwarderReference?: string | null
      } | null
      chainIoCustomsDeclaration?: {
        __typename?: 'ChainIOCustomsDeclarationNode'
        id: string
        forwarderReference?: string | null
      } | null
    }>
  >
}

export type UniqueChargeVendorsByCompanyQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
  codes?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
}>

export type UniqueChargeVendorsByCompanyQuery = {
  __typename?: 'Query'
  uniqueChargeVendorsByCompany: Array<{
    __typename?: 'ChargeVendorNode'
    id: string
    name: string
    code: string
  }>
}

export type UniqueChargeCodesByCompanyPartnerQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
  apiPartnerId?: InputMaybe<Scalars['UUID']['input']>
  codes?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  vendorCodes?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
}>

export type UniqueChargeCodesByCompanyPartnerQuery = {
  __typename?: 'Query'
  uniqueChargeCodesByCompanyPartner: Array<{
    __typename?: 'ChargeCodeSearchResult'
    id: string
    code: string
    description: string
  }>
}

export type ViewOperatorCompaniesQueryVariables = Exact<{ [key: string]: never }>

export type ViewOperatorCompaniesQuery = {
  __typename?: 'Query'
  viewOperatorCompanies: {
    __typename?: 'ViewOperatorCompanies'
    onlyViewDashboardCompany: boolean
    operatorCompanies?: Array<{
      __typename?: 'CompanyNode'
      id: string
      name?: string | null
      cargowiseConfigs: {
        __typename?: 'CargowiseConfigNodeConnection'
        edges: Array<{
          __typename?: 'CargowiseConfigNodeEdge'
          node: { __typename?: 'CargowiseConfigNode'; id: string }
        }>
      }
      apiPartners: {
        __typename?: 'ApiPartnerInterfaceConnection'
        edges: Array<{
          __typename?: 'ApiPartnerInterfaceEdge'
          node:
            | { __typename?: 'ApiPartnerNode'; id: string; name: string }
            | { __typename?: 'CargowiseConfigNode'; id: string; name: string }
        }>
      }
    }> | null
  }
}

export type SoaReconAttemptsHistoryQueryVariables = Exact<{
  jobId: Scalars['UUID']['input']
  getSavedOnly?: InputMaybe<Scalars['Boolean']['input']>
  marker?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}>

export type SoaReconAttemptsHistoryQuery = {
  __typename?: 'Query'
  soaReconAttemptsHistory: {
    __typename?: 'ReconAsyncBatchNodePaginatedResults'
    nextMarker?: string | null
    previousMarker?: string | null
    hasNext: boolean
    hasPrevious: boolean
    results: Array<{
      __typename?: 'ReconAsyncBatchNode'
      id: string
      dateCreated: string
      userCreated: { __typename?: 'UserNode'; id: string; email: string }
    }>
  }
}

export type ApReconAttemptsHistoryQueryVariables = Exact<{
  jobId: Scalars['UUID']['input']
  getSavedOnly?: InputMaybe<Scalars['Boolean']['input']>
  marker?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}>

export type ApReconAttemptsHistoryQuery = {
  __typename?: 'Query'
  apReconAttemptsHistory: {
    __typename?: 'ReconAttemptNodePaginatedResults'
    nextMarker?: string | null
    previousMarker?: string | null
    hasNext: boolean
    hasPrevious: boolean
    results: Array<{
      __typename?: 'ReconAttemptNode'
      id: string
      isSoaMetadataAttempt?: boolean | null
      dateSaved?: string | null
      dateCreated: string
      reconStatus: ReconciliationStatus
      errorNotes?: string | null
      expedockAction?: string | null
      userCreated: { __typename?: 'UserNode'; id: string; email: string }
      matchingCriteria:
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | {
            __typename?: 'MatchingCriteriaTypeEnum'
            value: MatchingCriteriaType
            isFallback: boolean
          }
      cargowiseModule?:
        | { __typename?: 'CwTargetModuleEnum'; value: CwTargetModule; isFallback: boolean }
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | null
      reconDetail: {
        __typename?: 'ReconDetail'
        vendor?: string | null
        tmsId?: string | null
        shipmentNo?: string | null
        invoiceNo?: string | null
        hblNo?: string | null
        mblNo?: string | null
        referenceNo?: string | null
        containerNo?: string | null
        carrierBookingNo?: string | null
        orderNo?: string | null
        consolNo?: string | null
        dueDate?: string | null
      }
      docCharges: Array<{
        __typename?: 'ChargeDetail'
        id: string
        chargeCode?: string | null
        chargeDescription?: string | null
        chargeCost?: string | null
        currency?: string | null
        vendor?: string | null
        invoiceNumber?: string | null
        taxId?: string | null
        taxRate?: string | null
        invoiceDate?: string | null
        dueDate?: string | null
      }>
      expectedCharges: Array<{
        __typename?: 'ChargeDetail'
        id: string
        chainIoSiLineId?: string | null
        chargeCode?: string | null
        chargeDescription?: string | null
        chargeCost?: string | null
        currency?: string | null
        vendor?: string | null
        invoiceNumber?: string | null
        taxId?: string | null
        taxRate?: string | null
        invoiceDate?: string | null
        dueDate?: string | null
        isPosted?: boolean | null
        overseasSellAmount?: string | null
        sellCurrency?: string | null
      }>
      reconResults: Array<
        | {
            __typename?: 'ArrivalNoticeChargeRateReconResultNode'
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'ArrivalNoticeConsolTypeReconResultNode'
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'ArrivalNoticeContainerReconResultNode'
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'ArrivalNoticeContractReconResultNode'
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'ArrivalNoticeLumpsumReconResultNode'
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'ArrivalNoticeMetadataReconResultNode'
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'FindConsolReconResultNode'
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'FindInvoiceReconResultNode'
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            invoiceReconMatches: {
              __typename?: 'InvoiceReconMatchNodeConnection'
              edges: Array<{
                __typename?: 'InvoiceReconMatchNodeEdge'
                node: {
                  __typename?: 'InvoiceReconMatchNode'
                  id: string
                  chainIoSi: {
                    __typename?: 'ChainIOServiceInvoiceNode'
                    id: string
                    invoiceNumber?: string | null
                  }
                }
              }>
            }
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'FindShipmentDuplicateReconResultNode'
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'FindShipmentReconResultNode'
            referenceNo?: string | null
            hblNo?: string | null
            mblNo?: string | null
            orderNo?: string | null
            consolNo?: string | null
            carrierBookingNo?: string | null
            containerNo?: string | null
            isPrimary: boolean
            isMaximalAmongDuplicates?: boolean | null
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            chainIoShipment?: {
              __typename?: 'ChainIOShipmentNode'
              id: string
              forwarderReference?: string | null
              dateModified: string
              houseBill?: string | null
              masterBill?: string | null
              carrierBookingNumber?: string | null
              bookingReference?: string | null
              containers: {
                __typename?: 'ChainIOContainerNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOContainerNodeEdge'
                  node: {
                    __typename?: 'ChainIOContainerNode'
                    id: string
                    containerNumber?: string | null
                  }
                }>
              }
              chainIoConsolidations: {
                __typename?: 'ChainIOConsolidationNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOConsolidationNodeEdge'
                  node: {
                    __typename?: 'ChainIOConsolidationNode'
                    id: string
                    forwarderReference?: string | null
                  }
                }>
              }
              orders: {
                __typename?: 'ChainIOOrderNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOOrderNodeEdge'
                  node: {
                    __typename?: 'ChainIOOrderNode'
                    id: string
                    orderReference?: string | null
                  }
                }>
              }
            } | null
            chainIoConsolidation?: {
              __typename?: 'ChainIOConsolidationNode'
              id: string
              forwarderReference?: string | null
              dateModified: string
              masterBill?: string | null
              carrierBookingRef?: string | null
              containers: {
                __typename?: 'ChainIOContainerNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOContainerNodeEdge'
                  node: {
                    __typename?: 'ChainIOContainerNode'
                    id: string
                    containerNumber?: string | null
                  }
                }>
              }
              shipments: {
                __typename?: 'ChainIOShipmentNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOShipmentNodeEdge'
                  node: {
                    __typename?: 'ChainIOShipmentNode'
                    id: string
                    forwarderReference?: string | null
                    houseBill?: string | null
                    orders: {
                      __typename?: 'ChainIOOrderNodeConnection'
                      edges: Array<{
                        __typename?: 'ChainIOOrderNodeEdge'
                        node: {
                          __typename?: 'ChainIOOrderNode'
                          id: string
                          orderReference?: string | null
                        }
                      }>
                    }
                  }
                }>
              }
            } | null
            chainIoCustomsDeclaration?: {
              __typename?: 'ChainIOCustomsDeclarationNode'
              id: string
              forwarderReference?: string | null
              dateModified: string
              houseBill?: string | null
              masterBill?: string | null
              bookingReference?: string | null
              containers: {
                __typename?: 'ChainIOContainerNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOContainerNodeEdge'
                  node: {
                    __typename?: 'ChainIOContainerNode'
                    id: string
                    containerNumber?: string | null
                  }
                }>
              }
              orders: {
                __typename?: 'ChainIOOrderNodeConnection'
                edges: Array<{
                  __typename?: 'ChainIOOrderNodeEdge'
                  node: {
                    __typename?: 'ChainIOOrderNode'
                    id: string
                    orderReference?: string | null
                  }
                }>
              }
              chainIoShipment?: {
                __typename?: 'ChainIOShipmentNode'
                id: string
                carrierBookingNumber?: string | null
                forwarderReference?: string | null
                chainIoConsolidations: {
                  __typename?: 'ChainIOConsolidationNodeConnection'
                  edges: Array<{
                    __typename?: 'ChainIOConsolidationNodeEdge'
                    node: {
                      __typename?: 'ChainIOConsolidationNode'
                      id: string
                      forwarderReference?: string | null
                    }
                  }>
                }
              } | null
            } | null
            findShipmentDuplicateReconResults: {
              __typename?: 'FindShipmentDuplicateReconResultNodeConnection'
              edges: Array<{
                __typename?: 'FindShipmentDuplicateReconResultNodeEdge'
                node: {
                  __typename?: 'FindShipmentDuplicateReconResultNode'
                  id: string
                  chainIoShipment?: {
                    __typename?: 'ChainIOShipmentNode'
                    id: string
                    forwarderReference?: string | null
                    isCancelled: boolean
                    tmsId?: string | null
                  } | null
                  chainIoConsolidation?: {
                    __typename?: 'ChainIOConsolidationNode'
                    id: string
                    forwarderReference?: string | null
                    isCancelled?: boolean | null
                    tmsId?: string | null
                  } | null
                  chainIoCustomsDeclaration?: {
                    __typename?: 'ChainIOCustomsDeclarationNode'
                    id: string
                    forwarderReference?: string | null
                    isCancelled?: boolean | null
                    tmsId?: string | null
                  } | null
                }
              }>
            }
            invoiceReconLineItemSnapshots: {
              __typename?: 'InvoiceReconLineItemSnapshotInterfaceConnection'
              edges: Array<{
                __typename?: 'InvoiceReconLineItemSnapshotInterfaceEdge'
                node:
                  | {
                      __typename?: 'InvoiceReconLineItemSnapshotNode'
                      id: string
                      type: InvoiceReconLineItemType
                      chargeCode?: string | null
                      currency?: string | null
                      chargeCost?: string | null
                      quantity?: string | null
                      chargeDescription?: string | null
                      unitPrice?: string | null
                      taxId?: string | null
                    }
                  | {
                      __typename?: 'SOAReconLineItemSnapshotNode'
                      id: string
                      type: InvoiceReconLineItemType
                      referenceNumber?: string | null
                      hblNumber?: string | null
                      mblNumber?: string | null
                      carrierBookingNumber?: string | null
                      containerNumber?: string | null
                      invoiceNumber?: string | null
                      orderNumber?: string | null
                      consolNumber?: string | null
                      invoiceDate?: string | null
                      dueDate?: string | null
                    }
              }>
            }
          }
        | {
            __typename?: 'InvoiceLineItemReconResultNode'
            invoiceAmount?: string | null
            invoiceTaxAmount?: string | null
            expectedAmount?: string | null
            expectedTaxAmount?: string | null
            invoiceChargeCodeCode?: string | null
            invoiceNumber?: string | null
            invoiceDate?: string | null
            dueDate?: string | null
            invoiceTaxId?: string | null
            expectedTaxId?: string | null
            invoiceCurrency?: string | null
            invoiceUnitPrice?: string | null
            invoiceQuantity?: string | null
            chainIoConsolCostId?: string | null
            chainIoCustomsDeclarationCostId?: string | null
            isChargeCodeSame?: boolean | null
            isTotalCostEqual?: boolean | null
            isCurrencySameOrEmpty?: boolean | null
            isChargeVendorSame?: boolean | null
            isInvoiceNumberCorrect?: boolean | null
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            invoiceChargeCode?: {
              __typename?: 'ChargeCodeNode'
              id: string
              code: string
              chargeVendor: { __typename?: 'ChargeVendorNode'; id: string; name: string }
            } | null
            invoiceChargeVendor?: {
              __typename?: 'ChargeVendorNode'
              id: string
              code: string
            } | null
            expectedChargeCode?: {
              __typename?: 'ChargeCodeNode'
              id: string
              code: string
              chargeVendor: { __typename?: 'ChargeVendorNode'; id: string; name: string }
            } | null
            chainIoSiLine?: { __typename?: 'ChainIOServiceInvoiceLineNode'; id: string } | null
            duplicateChainIoSiLines: {
              __typename?: 'DuplicateChainIOServiceInvoiceLineNodeConnection'
              edges: Array<{
                __typename?: 'DuplicateChainIOServiceInvoiceLineNodeEdge'
                node: {
                  __typename?: 'DuplicateChainIOServiceInvoiceLineNode'
                  id: string
                  chainIoSiLine?: {
                    __typename?: 'ChainIOServiceInvoiceLineNode'
                    id: string
                  } | null
                }
              }>
            }
            invoiceReconLineItemSnapshot?:
              | {
                  __typename?: 'InvoiceReconLineItemSnapshotNode'
                  id: string
                  type: InvoiceReconLineItemType
                  chargeCode?: string | null
                  currency?: string | null
                  chargeCost?: string | null
                  quantity?: string | null
                  chargeDescription?: string | null
                  unitPrice?: string | null
                  taxId?: string | null
                }
              | {
                  __typename?: 'SOAReconLineItemSnapshotNode'
                  id: string
                  type: InvoiceReconLineItemType
                  referenceNumber?: string | null
                  hblNumber?: string | null
                  mblNumber?: string | null
                  carrierBookingNumber?: string | null
                  containerNumber?: string | null
                  invoiceNumber?: string | null
                  orderNumber?: string | null
                  consolNumber?: string | null
                  invoiceDate?: string | null
                  dueDate?: string | null
                }
              | null
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'InvoiceTotalReconResultNode'
            usedThresholdMatching: boolean
            isWithinThreshold: boolean
            id: string
            success: boolean
            invoiceTotalAmount: string
            invoiceTaxedTotalAmount?: string | null
            expectedTotalAmount: string
            expectedTaxedTotalAmount?: string | null
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            reconThresholdRange?: {
              __typename?: 'ReconThresholdRangeNode'
              id: string
              thresholdAmount: string
            } | null
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'MetadataReconResultNode'
            invoiceValue?: string | null
            expectedValue?: string | null
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            key:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | {
                  __typename?: 'MetadataReconResultKeyEnum'
                  value: MetadataReconResultKey
                  isFallback: boolean
                }
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'ReconResultFallbackNode'
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'SOATotalReconResultNode'
            id: string
            success: boolean
            soaTotalAmount: string
            expectedTotalAmount: string
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
        | {
            __typename?: 'ShipmentStaffReconResultNode'
            operationsStaffName?: string | null
            operationsStaffCode?: string | null
            salesStaffName?: string | null
            salesStaffCode?: string | null
            id: string
            success: boolean
            type:
              | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
              | { __typename?: 'ReconResultTypeEnum'; value: ReconResultType; isFallback: boolean }
            chainIoShipment?: { __typename?: 'ChainIOShipmentNode'; id: string } | null
          }
      >
      matchReconInvoiceShipmentReference?: {
        __typename?: 'ReconInvoiceShipmentReferenceNode'
        id: string
        externalStatus?: JobExternalStatus | null
        isModified: boolean
        externalAssignee?: { __typename?: 'UserNode'; id: string; email: string } | null
      } | null
      noMatchReconMatchCriteria?: {
        __typename?: 'ReconMatchCriteriaNode'
        id: string
        externalStatus?: JobExternalStatus | null
        isModified: boolean
        externalAssignee?: { __typename?: 'UserNode'; id: string; email: string } | null
      } | null
    }>
  }
}

export type BatchSearchableRecordResultsQueryVariables = Exact<{
  inputSearchableRecordColumns: Array<InputSearchableRecordColumn> | InputSearchableRecordColumn
}>

export type BatchSearchableRecordResultsQuery = {
  __typename?: 'Query'
  batchSearchableRecordResults: Array<{
    __typename?: 'OutputSearchableRecordColumn'
    searchableRecordId: string
    values: Array<string>
  } | null>
}

export type SearchableRecordResultsQueryVariables = Exact<{
  searchableRecordId: Scalars['String']['input']
  queryString: Scalars['String']['input']
  filters?: InputMaybe<InputSearchableRecordFilters>
}>

export type SearchableRecordResultsQuery = {
  __typename?: 'Query'
  searchableRecordResults: Array<
    | { __typename?: 'ChargeCodeNode'; code: string; description: string; id: string }
    | { __typename?: 'ChargeCodeSearchResult'; code: string; description: string; id: string }
    | { __typename?: 'ChargeCodeV2Node'; id: string }
    | { __typename?: 'ChargeCodeVendorOverrideNode'; id: string }
    | {
        __typename?: 'ChargeRateNode'
        shipmentRoute?: string | null
        namedAccount?: string | null
        commodity?: string | null
        containerSize?: string | null
        containerType?: string | null
        id: string
      }
    | { __typename?: 'ChargeVendorNode'; code: string; name: string; id: string }
    | { __typename?: 'GeoLocationNode'; label?: string | null; id: string }
    | { __typename?: 'ScheduleDNode'; label?: string | null; id: string }
    | { __typename?: 'ScheduleKNode'; label?: string | null; id: string }
    | {
        __typename?: 'SearchableRecordURLResult'
        urlResultRecord: string
        urlResultSearch: string
        id: string
      }
    | { __typename?: 'TaxNode'; id: string }
    | { __typename?: 'USCarrierNode'; label?: string | null; id: string }
  >
}

export type SearchableRecordsValidationQueryVariables = Exact<{
  searchableRecordInputs: Array<InputSearchableRecordColumn> | InputSearchableRecordColumn
}>

export type SearchableRecordsValidationQuery = {
  __typename?: 'Query'
  validSearchableRecordResults: Array<{
    __typename?: 'OutputSearchableRecordColumn'
    searchableRecordId: string
    values: Array<string>
  }>
}

export type SalesRepsQueryVariables = Exact<{
  companyId: Scalars['String']['input']
}>

export type SalesRepsQuery = { __typename?: 'Query'; salesReps: Array<string> }

export type OperatorsQueryVariables = Exact<{
  companyId: Scalars['String']['input']
}>

export type OperatorsQuery = { __typename?: 'Query'; operators: Array<string> }

export type BranchesQueryVariables = Exact<{
  companyId: Scalars['String']['input']
}>

export type BranchesQuery = { __typename?: 'Query'; branches: Array<string> }

export type DepartmentsQueryVariables = Exact<{
  companyId: Scalars['String']['input']
}>

export type DepartmentsQuery = { __typename?: 'Query'; departments: Array<string> }

export type MatchSoaLineItemsToChainIoShipmentsQueryVariables = Exact<{
  jobId: Scalars['String']['input']
  rowsMatchCriteria: Array<InputSoaRowMatchCriteria> | InputSoaRowMatchCriteria
}>

export type MatchSoaLineItemsToChainIoShipmentsQuery = {
  __typename?: 'Query'
  matchSoaLineItemsToChainIoShipments: Array<{
    __typename?: 'SOALineItemShipmentMatch'
    rowIdx: number
    matchingInvoiceNumberChainIoShipmentId?: string | null
  }>
}

export type TaskPagesQueryVariables = Exact<{
  pageUpTo: Scalars['Int']['input']
  query?: InputMaybe<Scalars['String']['input']>
  filters?: InputMaybe<Array<InputTaskFilter> | InputTaskFilter>
}>

export type TaskPagesQuery = {
  __typename?: 'Query'
  taskPages: {
    __typename?: 'TaskPages'
    items: Array<{
      __typename?: 'TaskNode'
      id: string
      title: string
      starred: boolean
      isCriticallyLow: boolean
      isStarred: boolean
      blocked: boolean
      status: TaskStatus
      taskType: TaskType
      dateCreated: string
      dateReceived?: string | null
      dateConfirmed?: string | null
      taskReferenceId: string
      company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
      taskSlaTimeJobRef?: {
        __typename?: 'TaskSlaTimeJobRefNode'
        id: string
        job?: { __typename?: 'JobNode'; id: string; dateCreated: string; slaTime: number } | null
      } | null
      jobs: {
        __typename?: 'JobNodeConnection'
        edges: Array<{
          __typename?: 'JobNodeEdge'
          node: {
            __typename?: 'JobNode'
            id: string
            status: JobStatus
            manualProduct: ManualProduct
            name: string
            jobCode: string
            dateCompleted?: string | null
            jobTemplate: { __typename?: 'JobTemplateNode'; id: string; name: string }
            owner?: {
              __typename?: 'UserNode'
              id: string
              name?: string | null
              email: string
            } | null
            qa?: { __typename?: 'UserNode'; id: string; name?: string | null; email: string } | null
          }
        }>
      }
    }>
    counts: Array<{ __typename?: 'TaskStatusCount'; status: TaskStatus; count: number }>
    filteredCounts: Array<{ __typename?: 'TaskStatusCount'; status: TaskStatus; count: number }>
  }
}

export type TaskPagesIngestQueryVariables = Exact<{
  pageUpTo: Scalars['Int']['input']
  query?: InputMaybe<Scalars['String']['input']>
  filters?: InputMaybe<Array<InputTaskFilter> | InputTaskFilter>
}>

export type TaskPagesIngestQuery = {
  __typename?: 'Query'
  taskPagesIngest: {
    __typename?: 'TaskPages'
    items: Array<{
      __typename?: 'TaskNode'
      id: string
      title: string
      status: TaskStatus
      taskType: TaskType
      dateCreated: string
      dateReceived?: string | null
      dateConfirmed?: string | null
      taskReferenceId: string
      company: {
        __typename?: 'CompanyNode'
        id: string
        name?: string | null
        jobTemplates: {
          __typename?: 'JobTemplateNodeConnection'
          edges: Array<{
            __typename?: 'JobTemplateNodeEdge'
            node: {
              __typename?: 'JobTemplateNode'
              id: string
              name: string
              documentTypes: {
                __typename?: 'DocumentTypeNodeConnection'
                edges: Array<{
                  __typename?: 'DocumentTypeNodeEdge'
                  node: { __typename?: 'DocumentTypeNode'; id: string; name: string }
                }>
              }
            }
          }>
        }
      }
      taskSlaTimeJobRef?: {
        __typename?: 'TaskSlaTimeJobRefNode'
        id: string
        job?: { __typename?: 'JobNode'; id: string; dateCreated: string; slaTime: number } | null
      } | null
      jobs: {
        __typename?: 'JobNodeConnection'
        edges: Array<{
          __typename?: 'JobNodeEdge'
          node: {
            __typename?: 'JobNode'
            id: string
            status: JobStatus
            manualProduct: ManualProduct
            name: string
            jobCode: string
            clientReferenceNo: string
            jobTemplate: {
              __typename?: 'JobTemplateNode'
              id: string
              name: string
              documentTypes: {
                __typename?: 'DocumentTypeNodeConnection'
                edges: Array<{
                  __typename?: 'DocumentTypeNodeEdge'
                  node: { __typename?: 'DocumentTypeNode'; id: string; name: string }
                }>
              }
            }
          }
        }>
      }
    }>
    counts: Array<{ __typename?: 'TaskStatusCount'; status: TaskStatus; count: number }>
    filteredCounts: Array<{ __typename?: 'TaskStatusCount'; status: TaskStatus; count: number }>
  }
}

export type TasksByTitleReferenceIdQueryVariables = Exact<{
  query: Scalars['String']['input']
  companyId?: InputMaybe<Scalars['UUID']['input']>
}>

export type TasksByTitleReferenceIdQuery = {
  __typename?: 'Query'
  tasksByTitleReferenceId: Array<{
    __typename?: 'TaskNode'
    id: string
    title: string
    taskReferenceId: string
  }>
}

export type TaskForCreateJobQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type TaskForCreateJobQuery = {
  __typename?: 'Query'
  task: {
    __typename?: 'TaskNode'
    id: string
    title: string
    dateReceived?: string | null
    taskReferenceId: string
    message?: string | null
    company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
  }
}

export type TaskForIngestQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type TaskForIngestQuery = {
  __typename?: 'Query'
  task: {
    __typename?: 'TaskNode'
    id: string
    title: string
    dateReceived?: string | null
    message?: string | null
    taskReferenceId: string
    company: {
      __typename?: 'CompanyNode'
      id: string
      name?: string | null
      jobTemplates: {
        __typename?: 'JobTemplateNodeConnection'
        edges: Array<{
          __typename?: 'JobTemplateNodeEdge'
          node: {
            __typename?: 'JobTemplateNode'
            id: string
            name: string
            documentTypes: {
              __typename?: 'DocumentTypeNodeConnection'
              edges: Array<{
                __typename?: 'DocumentTypeNodeEdge'
                node: { __typename?: 'DocumentTypeNode'; id: string; name: string }
              }>
            }
          }
        }>
      }
    }
    jobs: {
      __typename?: 'JobNodeConnection'
      edges: Array<{
        __typename?: 'JobNodeEdge'
        node: {
          __typename?: 'JobNode'
          id: string
          name: string
          jobTemplate: {
            __typename?: 'JobTemplateNode'
            id: string
            name: string
            documentTypes: {
              __typename?: 'DocumentTypeNodeConnection'
              edges: Array<{
                __typename?: 'DocumentTypeNodeEdge'
                node: { __typename?: 'DocumentTypeNode'; id: string; name: string }
              }>
            }
          }
        }
      }>
    }
  }
}

export type TaskDetailQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type TaskDetailQuery = {
  __typename?: 'Query'
  task: {
    __typename?: 'TaskNode'
    id: string
    title: string
    taskReferenceId: string
    message?: string | null
    starred: boolean
    isCriticallyLow: boolean
    isStarred: boolean
    blocked: boolean
    status: TaskStatus
    taskType: TaskType
    dateCreated: string
    dateReceived?: string | null
    dateConfirmed?: string | null
    jobs: {
      __typename?: 'JobNodeConnection'
      edges: Array<{
        __typename?: 'JobNodeEdge'
        node: {
          __typename?: 'JobNode'
          id: string
          name: string
          status: JobStatus
          jobTemplate: { __typename?: 'JobTemplateNode'; id: string; name: string }
        }
      }>
    }
    taskSlaTimeJobRef?: {
      __typename?: 'TaskSlaTimeJobRefNode'
      id: string
      job?: { __typename?: 'JobNode'; id: string; dateCreated: string; slaTime: number } | null
    } | null
    company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
    taskNotes: {
      __typename?: 'TaskNoteNodeConnection'
      edges: Array<{
        __typename?: 'TaskNoteNodeEdge'
        node: {
          __typename?: 'TaskNoteNode'
          id: string
          content?: string | null
          dateCreated: string
          user: { __typename?: 'UserNode'; id: string; email: string }
        }
      }>
    }
  }
}

export type GetTaskWithJobQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetTaskWithJobQuery = {
  __typename?: 'Query'
  task: {
    __typename?: 'TaskNode'
    id: string
    title: string
    taskReferenceId: string
    message?: string | null
    status: TaskStatus
    starred: boolean
    blocked: boolean
    taskType: TaskType
    dateReceived?: string | null
    dateConfirmed?: string | null
    company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
    taskSlaTimeJobRef?: {
      __typename?: 'TaskSlaTimeJobRefNode'
      id: string
      job?: { __typename?: 'JobNode'; id: string; dateCreated: string; slaTime: number } | null
    } | null
    jobs: {
      __typename?: 'JobNodeConnection'
      edges: Array<{
        __typename?: 'JobNodeEdge'
        node: {
          __typename?: 'JobNode'
          id: string
          name: string
          slaTime: number
          dateCreated: string
          jobCode: string
          status: JobStatus
          clientReferenceNo: string
          manualProduct: ManualProduct
          owner?: { __typename?: 'UserNode'; email: string; id: string } | null
          qa?: { __typename?: 'UserNode'; email: string; id: string } | null
          jobTemplate: {
            __typename?: 'JobTemplateNode'
            id: string
            name: string
            companyId: string
            company: { __typename?: 'CompanyNode'; id: string; name?: string | null }
            jobTemplateExport?: {
              __typename?: 'JobTemplateExportNode'
              id: string
              jobTemplateExportType: JobTemplateExportType
            } | null
          }
          filePages: {
            __typename?: 'FilePageNodeConnection'
            edges: Array<{
              __typename?: 'FilePageNodeEdge'
              node: { __typename?: 'FilePageNode'; id: string; status: FilePageStatus }
            }>
          }
        }
      }>
    }
  }
}

export type TaskSearchParametersQueryVariables = Exact<{ [key: string]: never }>

export type TaskSearchParametersQuery = {
  __typename?: 'Query'
  taskSearchParameters: {
    __typename?: 'TaskSearchParameters'
    queryString: string
    filters: Array<{
      __typename?: 'TaskFilterSettingNode'
      id: string
      operation: TaskFilterOperation
      value: Array<string>
      column:
        | { __typename?: 'FallbackValue'; fallbackValue: string; isFallback: boolean }
        | { __typename?: 'TaskFilterColumnEnum'; value: TaskFilterColumn; isFallback: boolean }
    }>
  }
}

export type TaskNoteFragment = {
  __typename?: 'TaskNoteNode'
  id: string
  content?: string | null
  dateCreated: string
  user: { __typename?: 'UserNode'; id: string; email: string }
}

export type TaskNotesQueryVariables = Exact<{
  taskId: Scalars['String']['input']
}>

export type TaskNotesQuery = {
  __typename?: 'Query'
  taskNotes: Array<{
    __typename?: 'TaskNoteNode'
    id: string
    content?: string | null
    dateCreated: string
    user: { __typename?: 'UserNode'; id: string; email: string }
  }>
}

export type UsersByRoleQueryVariables = Exact<{
  role: UserRole
}>

export type UsersByRoleQuery = {
  __typename?: 'Query'
  usersByRole: Array<{ __typename?: 'UserNode'; id: string; email: string }>
}

export type ListUsersQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['UUID']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  roles?: InputMaybe<Array<UserRole> | UserRole>
  pageNumber?: InputMaybe<Scalars['Int']['input']>
  paginate?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ListUsersQuery = {
  __typename?: 'Query'
  listUsers: {
    __typename?: 'UserList'
    page: number
    limit: number
    total: number
    users: Array<{
      __typename?: 'AdminUserProfileNode'
      uuid?: string | null
      auth0Id: string
      name: string
      email: string
      roles: Array<UserRole>
      metabaseDashboardId?: string | null
      salesRep?: string | null
      operationsRep?: string | null
      branch?: string | null
      department?: string | null
      salesReps: Array<string>
      operationsReps: Array<string>
      branches: Array<string>
      departments: Array<string>
      onlyViewAssignedShipments: boolean
      onlyViewDashboardCompany: boolean
      defaultDashboardsWhitelist: Array<string>
      lastLogin?: string | null
      dateCreated?: string | null
      canExportDataAsCsv: boolean
      companies: Array<{ __typename?: 'CompanyNode'; id: string; name?: string | null }>
      company?: {
        __typename?: 'CompanyNode'
        id: string
        name?: string | null
        metabaseDashboardId?: string | null
        hasAccessToInsights?: boolean | null
      } | null
      partnerCompanies: Array<{ __typename?: 'CompanyNode'; id: string; name?: string | null }>
    }>
  }
}

export type ListDashboardCompanyUsersQueryVariables = Exact<{
  companyId: Scalars['UUID']['input']
}>

export type ListDashboardCompanyUsersQuery = {
  __typename?: 'Query'
  listDashboardCompanyUsers: Array<{ __typename?: 'UserNode'; id: string; email: string }>
}

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetUserProfileQuery = {
  __typename?: 'Query'
  userProfile: {
    __typename?: 'AuthProfile'
    email: string
    roles: Array<UserRole>
    company?: {
      __typename?: 'CompanyNode'
      id: string
      name?: string | null
      metabaseDashboardId?: string | null
      hasAccessToInsights?: boolean | null
    } | null
  }
}

export const DocumentTableFragmentDoc = gql`
  fragment documentTable on DocumentTableNode {
    id
    orderPriority
    fieldGroupId
    nextTableId
    documentId
    document {
      id
      documentType {
        id
        tableShowsPreset
      }
    }
    editedByUser
    fieldGroup {
      id
      key
      autofillKey
      name
      fields {
        edges {
          node {
            id
            key
            autofillKey
            name
            required
            validatorRegex
            validatorDescription
            defaultValue
            dateFormatString
            fieldType {
              ... on FallbackValue {
                fallbackValue
                isFallback
              }
              ... on FieldTypeEnum {
                value
                isFallback
              }
            }
            allowFreeText
            partialTableAllowed
            invalidCharsRegex
            values
            columnOrder
            searchableRecord {
              id
              searchKey
              displayKey
              model
            }
          }
        }
      }
    }
    documentTableColumns {
      edges {
        node {
          id
          fieldId
          field {
            id
            key
            name
            searchableRecord {
              id
              searchKey
              displayKey
              model
            }
          }
          index
          left
          width
          top
          height
        }
      }
    }
    documentFieldGroups {
      edges {
        node {
          id
          rowOrderPriority
          document {
            id
          }
          documentFields {
            edges {
              node {
                id
                field {
                  id
                  key
                  name
                  autofillKey
                  searchableRecord {
                    id
                    searchKey
                    displayKey
                    model
                  }
                }
                value
                confirmed
                top
                left
                width
                height
                confidenceScore
              }
            }
          }
        }
      }
    }
  }
`
export const FileAttributesFragmentDoc = gql`
  fragment FileAttributes on FileNode {
    id
    filename
    senderAddress
    emailSubject
    message
    dateCreated
  }
`
export const FilePageAttributesFragmentDoc = gql`
  fragment FilePageAttributes on FilePageNode {
    id
    fileId
    status
    imageUrl
    pageNumber
    dateCreated
    jobId
    type
  }
`
export const FieldFragmentDoc = gql`
  fragment field on FieldNode {
    id
    autofillKey
    key
    name
    required
    validatorRegex
    validatorDescription
    dateFormatString
    defaultValue
    allowFreeText
    partialTableAllowed
    invalidCharsRegex
    values
    columnOrder
    fieldType {
      ... on FallbackValue {
        fallbackValue
        isFallback
      }
      ... on FieldTypeEnum {
        value
        isFallback
      }
    }
    searchableRecord {
      id
      searchKey
      displayKey
      model
      apiUrl
    }
  }
`
export const FieldGroupFragmentDoc = gql`
  fragment fieldGroup on FieldGroupNode {
    id
    name
    key
    autofillKey
    fields {
      edges {
        node {
          ...field
        }
      }
    }
  }
  ${FieldFragmentDoc}
`
export const JobTableFragmentDoc = gql`
  fragment jobTable on JobTableNode {
    id
    fieldGroup {
      id
      repeatable
      ...fieldGroup
    }
    jobTableColumns {
      edges {
        node {
          id
          index
          field {
            ...field
          }
        }
      }
    }
    jobTableLineItems {
      edges {
        node {
          id
          jobTableCells {
            edges {
              node {
                id
                documentId
                top
                left
                width
                height
                value
                field {
                  id
                  key
                  autofillKey
                }
              }
            }
          }
        }
      }
    }
  }
  ${FieldGroupFragmentDoc}
  ${FieldFragmentDoc}
`
export const FieldGroupAttributesFragmentDoc = gql`
  fragment FieldGroupAttributes on FieldGroupNode {
    id
    key
    name
    autofillKey
    repeatable
    fields {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
export const FilePageWithDocumentAttributesFragmentDoc = gql`
  fragment FilePageWithDocumentAttributes on FilePageNode {
    id
    status
    imageUrl
    pageNumber
    dateCreated
    fileId
    file {
      id
      filename
    }
    document {
      id
      filePage {
        id
        imageUrl
        file {
          id
          filename
        }
      }
      documentType {
        id
        name
        documentTypeFieldGroups {
          edges {
            node {
              id
              fieldGroup {
                id
                key
                name
                repeatable
                fields {
                  edges {
                    node {
                      id
                      key
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      documentFieldGroups {
        edges {
          node {
            id
            rowOrderPriority
            document {
              id
            }
            documentFields {
              edges {
                node {
                  id
                  value
                  confidenceScore
                  top
                  left
                  width
                  height
                  field {
                    id
                    name
                    key
                    valueExportMapping
                  }
                }
              }
            }
            documentTable {
              id
            }
            fieldGroup {
              ...FieldGroupAttributes
            }
          }
        }
      }
    }
  }
  ${FieldGroupAttributesFragmentDoc}
`
export const JobForExportFragmentDoc = gql`
  fragment JobForExport on JobNode {
    id
    name
    dateCreated
    status
    ownerId
    clientReferenceNo
    jobTemplate {
      id
      name
      jobTemplateExport {
        id
        jobTemplateExportType
        jobTemplateExportSheets {
          edges {
            node {
              id
              order
              displayColumnHeader
              fieldGroup {
                id
                name
              }
              jobTemplateExportColumns {
                edges {
                  node {
                    id
                    columnName
                    fixedValue
                    order
                    field {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      documentTypes {
        edges {
          node {
            id
            documentTypeFieldGroups {
              edges {
                node {
                  id
                  fieldGroup {
                    id
                    name
                    key
                    repeatable
                    fields {
                      edges {
                        node {
                          id
                          key
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      company {
        id
        name
      }
    }
    filePages {
      edges {
        node {
          ...FilePageWithDocumentAttributes
        }
      }
    }
  }
  ${FilePageWithDocumentAttributesFragmentDoc}
`
export const InvoiceReconLineItemSnapshotFragmentDoc = gql`
  fragment InvoiceReconLineItemSnapshot on InvoiceReconLineItemSnapshotNode {
    type
    chargeCode
    currency
    chargeCost
    quantity
    chargeDescription
    unitPrice
    taxId
  }
`
export const SoaReconLineItemSnapshotFragmentDoc = gql`
  fragment SOAReconLineItemSnapshot on SOAReconLineItemSnapshotNode {
    type
    referenceNumber
    hblNumber
    mblNumber
    carrierBookingNumber
    containerNumber
    invoiceNumber
    orderNumber
    consolNumber
    invoiceDate
    dueDate
  }
`
export const ReconAttemptWithResultsAndMetadataFragmentDoc = gql`
  fragment ReconAttemptWithResultsAndMetadata on ReconAttemptNode {
    id
    isSoaMetadataAttempt
    dateSaved
    dateCreated
    matchingCriteria {
      ... on FallbackValue {
        fallbackValue
        isFallback
      }
      ... on MatchingCriteriaTypeEnum {
        value
        isFallback
      }
    }
    reconStatus
    cargowiseModule {
      ... on FallbackValue {
        fallbackValue
        isFallback
      }
      ... on CwTargetModuleEnum {
        value
        isFallback
      }
    }
    reconDetail {
      vendor
      tmsId
      shipmentNo
      invoiceNo
      hblNo
      mblNo
      referenceNo
      containerNo
      carrierBookingNo
      orderNo
      consolNo
      dueDate
    }
    docCharges {
      id
      chargeCode
      chargeDescription
      chargeCost
      currency
      vendor
      invoiceNumber
      taxId
      taxRate
      invoiceDate
      dueDate
    }
    expectedCharges {
      id
      chainIoSiLineId
      chargeCode
      chargeDescription
      chargeCost
      currency
      vendor
      invoiceNumber
      taxId
      taxRate
      invoiceDate
      dueDate
      isPosted
      overseasSellAmount
      sellCurrency
    }
    reconResults {
      id
      success
      type {
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
        ... on ReconResultTypeEnum {
          value
          isFallback
        }
      }
      chainIoShipment {
        id
      }
      ... on FindShipmentReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        referenceNo
        hblNo
        mblNo
        orderNo
        consolNo
        carrierBookingNo
        containerNo
        isPrimary
        isMaximalAmongDuplicates
        chainIoShipment {
          id
          forwarderReference
          dateModified
          houseBill
          masterBill
          carrierBookingNumber
          bookingReference
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          chainIoConsolidations {
            edges {
              node {
                id
                forwarderReference
              }
            }
          }
          orders {
            edges {
              node {
                id
                orderReference
              }
            }
          }
        }
        chainIoConsolidation {
          id
          forwarderReference
          dateModified
          masterBill
          carrierBookingRef
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          shipments {
            edges {
              node {
                id
                forwarderReference
                houseBill
                orders {
                  edges {
                    node {
                      id
                      orderReference
                    }
                  }
                }
              }
            }
          }
        }
        chainIoCustomsDeclaration {
          id
          forwarderReference
          dateModified
          houseBill
          masterBill
          bookingReference
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          orders {
            edges {
              node {
                id
                orderReference
              }
            }
          }
          chainIoShipment {
            id
            carrierBookingNumber
            forwarderReference
            chainIoConsolidations {
              edges {
                node {
                  id
                  forwarderReference
                }
              }
            }
          }
        }
        findShipmentDuplicateReconResults {
          edges {
            node {
              id
              chainIoShipment {
                id
                forwarderReference
                isCancelled
                tmsId
              }
              chainIoConsolidation {
                id
                forwarderReference
                isCancelled
                tmsId
              }
              chainIoCustomsDeclaration {
                id
                forwarderReference
                isCancelled
                tmsId
              }
            }
          }
        }
        invoiceReconLineItemSnapshots {
          edges {
            node {
              id
              ... on InvoiceReconLineItemSnapshotNode {
                ...InvoiceReconLineItemSnapshot
              }
              ... on SOAReconLineItemSnapshotNode {
                ...SOAReconLineItemSnapshot
              }
            }
          }
        }
      }
      ... on ShipmentStaffReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        operationsStaffName
        operationsStaffCode
        salesStaffName
        salesStaffCode
      }
      ... on MetadataReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        key {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on MetadataReconResultKeyEnum {
            value
            isFallback
          }
        }
        invoiceValue
        expectedValue
      }
      ... on FindInvoiceReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        invoiceReconMatches {
          edges {
            node {
              id
              chainIoSi {
                id
                invoiceNumber
              }
            }
          }
        }
      }
      ... on InvoiceLineItemReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        invoiceAmount
        invoiceTaxAmount
        expectedAmount
        expectedTaxAmount
        invoiceChargeCode {
          id
          code
          chargeVendor {
            id
            name
          }
        }
        invoiceChargeCodeCode
        invoiceNumber
        invoiceDate
        dueDate
        invoiceTaxId
        expectedTaxId
        invoiceChargeVendor {
          id
          code
        }
        expectedChargeCode {
          id
          code
          chargeVendor {
            id
            name
          }
        }
        invoiceCurrency
        invoiceUnitPrice
        invoiceQuantity
        chainIoSiLine {
          id
        }
        chainIoConsolCostId
        chainIoCustomsDeclarationCostId
        duplicateChainIoSiLines {
          edges {
            node {
              id
              chainIoSiLine {
                id
              }
            }
          }
        }
        isChargeCodeSame
        isTotalCostEqual
        isCurrencySameOrEmpty
        isChargeVendorSame
        isInvoiceNumberCorrect
        invoiceReconLineItemSnapshot {
          id
          ... on InvoiceReconLineItemSnapshotNode {
            ...InvoiceReconLineItemSnapshot
          }
          ... on SOAReconLineItemSnapshotNode {
            ...SOAReconLineItemSnapshot
          }
        }
      }
      ... on InvoiceTotalReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        invoiceTotalAmount: invoiceAmount
        invoiceTaxedTotalAmount: invoiceTaxedAmount
        expectedTotalAmount: expectedAmount
        expectedTaxedTotalAmount: expectedTaxedAmount
        usedThresholdMatching
        isWithinThreshold
        reconThresholdRange {
          id
          thresholdAmount
        }
      }
      ... on SOATotalReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        soaTotalAmount: soaAmount
        expectedTotalAmount: expectedAmount
      }
      ... on ReconResultFallbackNode {
        id
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
      }
    }
    matchReconInvoiceShipmentReference {
      id
      externalAssignee {
        id
        email
      }
      externalStatus
      isModified
    }
    noMatchReconMatchCriteria {
      id
      externalAssignee {
        id
        email
      }
      externalStatus
      isModified
    }
    errorNotes
    expedockAction
  }
  ${InvoiceReconLineItemSnapshotFragmentDoc}
  ${SoaReconLineItemSnapshotFragmentDoc}
`
export const TaskNoteFragmentDoc = gql`
  fragment taskNote on TaskNoteNode {
    id
    content
    dateCreated
    user {
      id
      email
    }
  }
`
export const DeleteApiPartnerDocument = gql`
  mutation deleteApiPartner($apiPartnerId: UUID!) {
    deleteApiPartner(apiPartnerId: $apiPartnerId) {
      ok
    }
  }
`
export type DeleteApiPartnerMutationFn = Apollo.MutationFunction<
  DeleteApiPartnerMutation,
  DeleteApiPartnerMutationVariables
>

/**
 * __useDeleteApiPartnerMutation__
 *
 * To run a mutation, you first call `useDeleteApiPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiPartnerMutation, { data, loading, error }] = useDeleteApiPartnerMutation({
 *   variables: {
 *      apiPartnerId: // value for 'apiPartnerId'
 *   },
 * });
 */
export function useDeleteApiPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApiPartnerMutation,
    DeleteApiPartnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteApiPartnerMutation, DeleteApiPartnerMutationVariables>(
    DeleteApiPartnerDocument,
    options,
  )
}
export type DeleteApiPartnerMutationHookResult = ReturnType<typeof useDeleteApiPartnerMutation>
export type DeleteApiPartnerMutationResult = Apollo.MutationResult<DeleteApiPartnerMutation>
export type DeleteApiPartnerMutationOptions = Apollo.BaseMutationOptions<
  DeleteApiPartnerMutation,
  DeleteApiPartnerMutationVariables
>
export const CreateApiPartnerDocument = gql`
  mutation createApiPartner($inputApiPartner: InputApiPartner!) {
    createApiPartner(inputApiPartner: $inputApiPartner) {
      ok
    }
  }
`
export type CreateApiPartnerMutationFn = Apollo.MutationFunction<
  CreateApiPartnerMutation,
  CreateApiPartnerMutationVariables
>

/**
 * __useCreateApiPartnerMutation__
 *
 * To run a mutation, you first call `useCreateApiPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiPartnerMutation, { data, loading, error }] = useCreateApiPartnerMutation({
 *   variables: {
 *      inputApiPartner: // value for 'inputApiPartner'
 *   },
 * });
 */
export function useCreateApiPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApiPartnerMutation,
    CreateApiPartnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateApiPartnerMutation, CreateApiPartnerMutationVariables>(
    CreateApiPartnerDocument,
    options,
  )
}
export type CreateApiPartnerMutationHookResult = ReturnType<typeof useCreateApiPartnerMutation>
export type CreateApiPartnerMutationResult = Apollo.MutationResult<CreateApiPartnerMutation>
export type CreateApiPartnerMutationOptions = Apollo.BaseMutationOptions<
  CreateApiPartnerMutation,
  CreateApiPartnerMutationVariables
>
export const UpdateApiPartnerDocument = gql`
  mutation updateApiPartner($id: UUID!, $inputApiPartner: InputApiPartner!) {
    updateApiPartner(id: $id, inputApiPartner: $inputApiPartner) {
      apiPartner {
        id
        apiKey
        exportEndpoint
        importEndpoint
        documentExportEndpoint
        documentImportEndpoint
        name
        expedockDocIngestEmail
        apiPartnerType
        tmsType {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ApiPartnerTmsTypeEnum {
            value
            isFallback
          }
        }
        credential {
          id
          username
          workflow
        }
        company {
          id
          name
        }
        jobTemplates {
          edges {
            node {
              id
              name
              company {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
export type UpdateApiPartnerMutationFn = Apollo.MutationFunction<
  UpdateApiPartnerMutation,
  UpdateApiPartnerMutationVariables
>

/**
 * __useUpdateApiPartnerMutation__
 *
 * To run a mutation, you first call `useUpdateApiPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiPartnerMutation, { data, loading, error }] = useUpdateApiPartnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inputApiPartner: // value for 'inputApiPartner'
 *   },
 * });
 */
export function useUpdateApiPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApiPartnerMutation,
    UpdateApiPartnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateApiPartnerMutation, UpdateApiPartnerMutationVariables>(
    UpdateApiPartnerDocument,
    options,
  )
}
export type UpdateApiPartnerMutationHookResult = ReturnType<typeof useUpdateApiPartnerMutation>
export type UpdateApiPartnerMutationResult = Apollo.MutationResult<UpdateApiPartnerMutation>
export type UpdateApiPartnerMutationOptions = Apollo.BaseMutationOptions<
  UpdateApiPartnerMutation,
  UpdateApiPartnerMutationVariables
>
export const TestApiPartnerDocument = gql`
  mutation testApiPartner($apiPartnerId: UUID!) {
    testApiPartner(apiPartnerId: $apiPartnerId) {
      ok
    }
  }
`
export type TestApiPartnerMutationFn = Apollo.MutationFunction<
  TestApiPartnerMutation,
  TestApiPartnerMutationVariables
>

/**
 * __useTestApiPartnerMutation__
 *
 * To run a mutation, you first call `useTestApiPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestApiPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testApiPartnerMutation, { data, loading, error }] = useTestApiPartnerMutation({
 *   variables: {
 *      apiPartnerId: // value for 'apiPartnerId'
 *   },
 * });
 */
export function useTestApiPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<TestApiPartnerMutation, TestApiPartnerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TestApiPartnerMutation, TestApiPartnerMutationVariables>(
    TestApiPartnerDocument,
    options,
  )
}
export type TestApiPartnerMutationHookResult = ReturnType<typeof useTestApiPartnerMutation>
export type TestApiPartnerMutationResult = Apollo.MutationResult<TestApiPartnerMutation>
export type TestApiPartnerMutationOptions = Apollo.BaseMutationOptions<
  TestApiPartnerMutation,
  TestApiPartnerMutationVariables
>
export const ImportEDocumentsFromApiPartnerDocument = gql`
  mutation importEDocumentsFromApiPartner($jobId: UUID!, $dataTargetType: EDocsDataTargetType!) {
    importEDocumentsFromApiPartner(jobId: $jobId, dataTargetType: $dataTargetType) {
      ok
    }
  }
`
export type ImportEDocumentsFromApiPartnerMutationFn = Apollo.MutationFunction<
  ImportEDocumentsFromApiPartnerMutation,
  ImportEDocumentsFromApiPartnerMutationVariables
>

/**
 * __useImportEDocumentsFromApiPartnerMutation__
 *
 * To run a mutation, you first call `useImportEDocumentsFromApiPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportEDocumentsFromApiPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importEDocumentsFromApiPartnerMutation, { data, loading, error }] = useImportEDocumentsFromApiPartnerMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      dataTargetType: // value for 'dataTargetType'
 *   },
 * });
 */
export function useImportEDocumentsFromApiPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportEDocumentsFromApiPartnerMutation,
    ImportEDocumentsFromApiPartnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ImportEDocumentsFromApiPartnerMutation,
    ImportEDocumentsFromApiPartnerMutationVariables
  >(ImportEDocumentsFromApiPartnerDocument, options)
}
export type ImportEDocumentsFromApiPartnerMutationHookResult = ReturnType<
  typeof useImportEDocumentsFromApiPartnerMutation
>
export type ImportEDocumentsFromApiPartnerMutationResult =
  Apollo.MutationResult<ImportEDocumentsFromApiPartnerMutation>
export type ImportEDocumentsFromApiPartnerMutationOptions = Apollo.BaseMutationOptions<
  ImportEDocumentsFromApiPartnerMutation,
  ImportEDocumentsFromApiPartnerMutationVariables
>
export const DeleteApiEDocDocument = gql`
  mutation deleteApiEDoc($apiEDocumentId: UUID!) {
    deleteApiEDoc(apiEDocumentId: $apiEDocumentId) {
      ok
    }
  }
`
export type DeleteApiEDocMutationFn = Apollo.MutationFunction<
  DeleteApiEDocMutation,
  DeleteApiEDocMutationVariables
>

/**
 * __useDeleteApiEDocMutation__
 *
 * To run a mutation, you first call `useDeleteApiEDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiEDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiEDocMutation, { data, loading, error }] = useDeleteApiEDocMutation({
 *   variables: {
 *      apiEDocumentId: // value for 'apiEDocumentId'
 *   },
 * });
 */
export function useDeleteApiEDocMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteApiEDocMutation, DeleteApiEDocMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteApiEDocMutation, DeleteApiEDocMutationVariables>(
    DeleteApiEDocDocument,
    options,
  )
}
export type DeleteApiEDocMutationHookResult = ReturnType<typeof useDeleteApiEDocMutation>
export type DeleteApiEDocMutationResult = Apollo.MutationResult<DeleteApiEDocMutation>
export type DeleteApiEDocMutationOptions = Apollo.BaseMutationOptions<
  DeleteApiEDocMutation,
  DeleteApiEDocMutationVariables
>
export const ExportJobToCwDocument = gql`
  mutation exportJobToCw(
    $jobId: UUID!
    $lumpsum: InputDocumentTable
    $overrideChargeDescription: Boolean!
    $disableSendDueDate: Boolean!
    $matchingCriteria: MatchingCriteriaType!
  ) {
    exportJobToCw(
      jobId: $jobId
      lumpsum: $lumpsum
      overrideChargeDescription: $overrideChargeDescription
      disableSendDueDate: $disableSendDueDate
      matchingCriteria: $matchingCriteria
    ) {
      ok
    }
  }
`
export type ExportJobToCwMutationFn = Apollo.MutationFunction<
  ExportJobToCwMutation,
  ExportJobToCwMutationVariables
>

/**
 * __useExportJobToCwMutation__
 *
 * To run a mutation, you first call `useExportJobToCwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportJobToCwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportJobToCwMutation, { data, loading, error }] = useExportJobToCwMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      lumpsum: // value for 'lumpsum'
 *      overrideChargeDescription: // value for 'overrideChargeDescription'
 *      disableSendDueDate: // value for 'disableSendDueDate'
 *      matchingCriteria: // value for 'matchingCriteria'
 *   },
 * });
 */
export function useExportJobToCwMutation(
  baseOptions?: Apollo.MutationHookOptions<ExportJobToCwMutation, ExportJobToCwMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ExportJobToCwMutation, ExportJobToCwMutationVariables>(
    ExportJobToCwDocument,
    options,
  )
}
export type ExportJobToCwMutationHookResult = ReturnType<typeof useExportJobToCwMutation>
export type ExportJobToCwMutationResult = Apollo.MutationResult<ExportJobToCwMutation>
export type ExportJobToCwMutationOptions = Apollo.BaseMutationOptions<
  ExportJobToCwMutation,
  ExportJobToCwMutationVariables
>
export const BatchExportJobToCwDocument = gql`
  mutation batchExportJobToCw(
    $jobId: String!
    $invoiceNumbers: [String!]!
    $invoiceDates: [InputSoaDates!]!
    $invoiceCwModuleKeys: [InputSoaCwModules!]!
    $overrideChargeDescription: Boolean!
    $disableSendDueDate: Boolean!
    $asLumpsum: Boolean!
  ) {
    batchExportJobToCw(
      jobId: $jobId
      invoiceNumbers: $invoiceNumbers
      invoiceDates: $invoiceDates
      invoiceCwModuleKeys: $invoiceCwModuleKeys
      overrideChargeDescription: $overrideChargeDescription
      disableSendDueDate: $disableSendDueDate
      asLumpsum: $asLumpsum
    ) {
      transactionAsyncBatchId
    }
  }
`
export type BatchExportJobToCwMutationFn = Apollo.MutationFunction<
  BatchExportJobToCwMutation,
  BatchExportJobToCwMutationVariables
>

/**
 * __useBatchExportJobToCwMutation__
 *
 * To run a mutation, you first call `useBatchExportJobToCwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchExportJobToCwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchExportJobToCwMutation, { data, loading, error }] = useBatchExportJobToCwMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      invoiceNumbers: // value for 'invoiceNumbers'
 *      invoiceDates: // value for 'invoiceDates'
 *      invoiceCwModuleKeys: // value for 'invoiceCwModuleKeys'
 *      overrideChargeDescription: // value for 'overrideChargeDescription'
 *      disableSendDueDate: // value for 'disableSendDueDate'
 *      asLumpsum: // value for 'asLumpsum'
 *   },
 * });
 */
export function useBatchExportJobToCwMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchExportJobToCwMutation,
    BatchExportJobToCwMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BatchExportJobToCwMutation, BatchExportJobToCwMutationVariables>(
    BatchExportJobToCwDocument,
    options,
  )
}
export type BatchExportJobToCwMutationHookResult = ReturnType<typeof useBatchExportJobToCwMutation>
export type BatchExportJobToCwMutationResult = Apollo.MutationResult<BatchExportJobToCwMutation>
export type BatchExportJobToCwMutationOptions = Apollo.BaseMutationOptions<
  BatchExportJobToCwMutation,
  BatchExportJobToCwMutationVariables
>
export const PostToCwDocument = gql`
  mutation postToCw(
    $jobId: UUID!
    $lumpsum: InputDocumentTable
    $overrideChargeDescription: Boolean!
    $disableSendDueDate: Boolean!
    $matchingCriteria: MatchingCriteriaType!
  ) {
    postToCw(
      jobId: $jobId
      lumpsum: $lumpsum
      overrideChargeDescription: $overrideChargeDescription
      disableSendDueDate: $disableSendDueDate
      matchingCriteria: $matchingCriteria
    ) {
      ok
    }
  }
`
export type PostToCwMutationFn = Apollo.MutationFunction<
  PostToCwMutation,
  PostToCwMutationVariables
>

/**
 * __usePostToCwMutation__
 *
 * To run a mutation, you first call `usePostToCwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostToCwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postToCwMutation, { data, loading, error }] = usePostToCwMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      lumpsum: // value for 'lumpsum'
 *      overrideChargeDescription: // value for 'overrideChargeDescription'
 *      disableSendDueDate: // value for 'disableSendDueDate'
 *      matchingCriteria: // value for 'matchingCriteria'
 *   },
 * });
 */
export function usePostToCwMutation(
  baseOptions?: Apollo.MutationHookOptions<PostToCwMutation, PostToCwMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PostToCwMutation, PostToCwMutationVariables>(PostToCwDocument, options)
}
export type PostToCwMutationHookResult = ReturnType<typeof usePostToCwMutation>
export type PostToCwMutationResult = Apollo.MutationResult<PostToCwMutation>
export type PostToCwMutationOptions = Apollo.BaseMutationOptions<
  PostToCwMutation,
  PostToCwMutationVariables
>
export const PushEDocsToCwDocument = gql`
  mutation pushEDocsToCw(
    $jobId: UUID!
    $inputEDocuments: [InputEDocument!]!
    $dataTargetType: EDocsDataTargetType!
    $overrideReconAttemptId: UUID
  ) {
    pushEDocsToCw(
      jobId: $jobId
      inputEDocuments: $inputEDocuments
      dataTargetType: $dataTargetType
      overrideReconAttemptId: $overrideReconAttemptId
    ) {
      ok
    }
  }
`
export type PushEDocsToCwMutationFn = Apollo.MutationFunction<
  PushEDocsToCwMutation,
  PushEDocsToCwMutationVariables
>

/**
 * __usePushEDocsToCwMutation__
 *
 * To run a mutation, you first call `usePushEDocsToCwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushEDocsToCwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushEDocsToCwMutation, { data, loading, error }] = usePushEDocsToCwMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      inputEDocuments: // value for 'inputEDocuments'
 *      dataTargetType: // value for 'dataTargetType'
 *      overrideReconAttemptId: // value for 'overrideReconAttemptId'
 *   },
 * });
 */
export function usePushEDocsToCwMutation(
  baseOptions?: Apollo.MutationHookOptions<PushEDocsToCwMutation, PushEDocsToCwMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PushEDocsToCwMutation, PushEDocsToCwMutationVariables>(
    PushEDocsToCwDocument,
    options,
  )
}
export type PushEDocsToCwMutationHookResult = ReturnType<typeof usePushEDocsToCwMutation>
export type PushEDocsToCwMutationResult = Apollo.MutationResult<PushEDocsToCwMutation>
export type PushEDocsToCwMutationOptions = Apollo.BaseMutationOptions<
  PushEDocsToCwMutation,
  PushEDocsToCwMutationVariables
>
export const BatchPushEdocsToCwDocument = gql`
  mutation batchPushEdocsToCw(
    $jobId: UUID!
    $referenceNumbers: [String!]!
    $inputEDocuments: [InputEDocument!]!
    $dataTargetType: EDocsDataTargetType!
  ) {
    batchPushEDocsToCw(
      jobId: $jobId
      referenceNumbers: $referenceNumbers
      inputEDocuments: $inputEDocuments
      dataTargetType: $dataTargetType
    ) {
      batchPushId
    }
  }
`
export type BatchPushEdocsToCwMutationFn = Apollo.MutationFunction<
  BatchPushEdocsToCwMutation,
  BatchPushEdocsToCwMutationVariables
>

/**
 * __useBatchPushEdocsToCwMutation__
 *
 * To run a mutation, you first call `useBatchPushEdocsToCwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchPushEdocsToCwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchPushEdocsToCwMutation, { data, loading, error }] = useBatchPushEdocsToCwMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      referenceNumbers: // value for 'referenceNumbers'
 *      inputEDocuments: // value for 'inputEDocuments'
 *      dataTargetType: // value for 'dataTargetType'
 *   },
 * });
 */
export function useBatchPushEdocsToCwMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchPushEdocsToCwMutation,
    BatchPushEdocsToCwMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BatchPushEdocsToCwMutation, BatchPushEdocsToCwMutationVariables>(
    BatchPushEdocsToCwDocument,
    options,
  )
}
export type BatchPushEdocsToCwMutationHookResult = ReturnType<typeof useBatchPushEdocsToCwMutation>
export type BatchPushEdocsToCwMutationResult = Apollo.MutationResult<BatchPushEdocsToCwMutation>
export type BatchPushEdocsToCwMutationOptions = Apollo.BaseMutationOptions<
  BatchPushEdocsToCwMutation,
  BatchPushEdocsToCwMutationVariables
>
export const PullEDocsFromCwDocument = gql`
  mutation pullEDocsFromCw($jobId: UUID!, $dataTargetType: EDocsDataTargetType!) {
    pullEDocsFromCw(jobId: $jobId, dataTargetType: $dataTargetType) {
      ok
    }
  }
`
export type PullEDocsFromCwMutationFn = Apollo.MutationFunction<
  PullEDocsFromCwMutation,
  PullEDocsFromCwMutationVariables
>

/**
 * __usePullEDocsFromCwMutation__
 *
 * To run a mutation, you first call `usePullEDocsFromCwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePullEDocsFromCwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pullEDocsFromCwMutation, { data, loading, error }] = usePullEDocsFromCwMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      dataTargetType: // value for 'dataTargetType'
 *   },
 * });
 */
export function usePullEDocsFromCwMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PullEDocsFromCwMutation,
    PullEDocsFromCwMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PullEDocsFromCwMutation, PullEDocsFromCwMutationVariables>(
    PullEDocsFromCwDocument,
    options,
  )
}
export type PullEDocsFromCwMutationHookResult = ReturnType<typeof usePullEDocsFromCwMutation>
export type PullEDocsFromCwMutationResult = Apollo.MutationResult<PullEDocsFromCwMutation>
export type PullEDocsFromCwMutationOptions = Apollo.BaseMutationOptions<
  PullEDocsFromCwMutation,
  PullEDocsFromCwMutationVariables
>
export const BatchPullEDocsFromCwDocument = gql`
  mutation batchPullEDocsFromCw(
    $jobId: UUID!
    $dataTargetType: EDocsDataTargetType!
    $chainIoShipmentIds: [UUID!]
    $invoiceNumbers: [String!]
    $chainIoConsolIds: [UUID!]
    $chainIoCustomsDecIds: [UUID!]
  ) {
    batchPullEDocsFromCw(
      jobId: $jobId
      dataTargetType: $dataTargetType
      chainIoShipmentIds: $chainIoShipmentIds
      invoiceNumbers: $invoiceNumbers
      chainIoConsolIds: $chainIoConsolIds
      chainIoCustomsDecIds: $chainIoCustomsDecIds
    ) {
      batchPullId
    }
  }
`
export type BatchPullEDocsFromCwMutationFn = Apollo.MutationFunction<
  BatchPullEDocsFromCwMutation,
  BatchPullEDocsFromCwMutationVariables
>

/**
 * __useBatchPullEDocsFromCwMutation__
 *
 * To run a mutation, you first call `useBatchPullEDocsFromCwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchPullEDocsFromCwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchPullEDocsFromCwMutation, { data, loading, error }] = useBatchPullEDocsFromCwMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      dataTargetType: // value for 'dataTargetType'
 *      chainIoShipmentIds: // value for 'chainIoShipmentIds'
 *      invoiceNumbers: // value for 'invoiceNumbers'
 *      chainIoConsolIds: // value for 'chainIoConsolIds'
 *      chainIoCustomsDecIds: // value for 'chainIoCustomsDecIds'
 *   },
 * });
 */
export function useBatchPullEDocsFromCwMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchPullEDocsFromCwMutation,
    BatchPullEDocsFromCwMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BatchPullEDocsFromCwMutation, BatchPullEDocsFromCwMutationVariables>(
    BatchPullEDocsFromCwDocument,
    options,
  )
}
export type BatchPullEDocsFromCwMutationHookResult = ReturnType<
  typeof useBatchPullEDocsFromCwMutation
>
export type BatchPullEDocsFromCwMutationResult = Apollo.MutationResult<BatchPullEDocsFromCwMutation>
export type BatchPullEDocsFromCwMutationOptions = Apollo.BaseMutationOptions<
  BatchPullEDocsFromCwMutation,
  BatchPullEDocsFromCwMutationVariables
>
export const DeleteEDocDocument = gql`
  mutation deleteEDoc($eDocumentId: UUID!) {
    deleteEDoc(eDocumentId: $eDocumentId) {
      ok
    }
  }
`
export type DeleteEDocMutationFn = Apollo.MutationFunction<
  DeleteEDocMutation,
  DeleteEDocMutationVariables
>

/**
 * __useDeleteEDocMutation__
 *
 * To run a mutation, you first call `useDeleteEDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEDocMutation, { data, loading, error }] = useDeleteEDocMutation({
 *   variables: {
 *      eDocumentId: // value for 'eDocumentId'
 *   },
 * });
 */
export function useDeleteEDocMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEDocMutation, DeleteEDocMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteEDocMutation, DeleteEDocMutationVariables>(
    DeleteEDocDocument,
    options,
  )
}
export type DeleteEDocMutationHookResult = ReturnType<typeof useDeleteEDocMutation>
export type DeleteEDocMutationResult = Apollo.MutationResult<DeleteEDocMutation>
export type DeleteEDocMutationOptions = Apollo.BaseMutationOptions<
  DeleteEDocMutation,
  DeleteEDocMutationVariables
>
export const BulkDeleteEdocsDocument = gql`
  mutation bulkDeleteEdocs($eDocumentIds: [UUID!]!) {
    bulkDeleteEDocs(eDocumentIds: $eDocumentIds) {
      ok
    }
  }
`
export type BulkDeleteEdocsMutationFn = Apollo.MutationFunction<
  BulkDeleteEdocsMutation,
  BulkDeleteEdocsMutationVariables
>

/**
 * __useBulkDeleteEdocsMutation__
 *
 * To run a mutation, you first call `useBulkDeleteEdocsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteEdocsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteEdocsMutation, { data, loading, error }] = useBulkDeleteEdocsMutation({
 *   variables: {
 *      eDocumentIds: // value for 'eDocumentIds'
 *   },
 * });
 */
export function useBulkDeleteEdocsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkDeleteEdocsMutation,
    BulkDeleteEdocsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BulkDeleteEdocsMutation, BulkDeleteEdocsMutationVariables>(
    BulkDeleteEdocsDocument,
    options,
  )
}
export type BulkDeleteEdocsMutationHookResult = ReturnType<typeof useBulkDeleteEdocsMutation>
export type BulkDeleteEdocsMutationResult = Apollo.MutationResult<BulkDeleteEdocsMutation>
export type BulkDeleteEdocsMutationOptions = Apollo.BaseMutationOptions<
  BulkDeleteEdocsMutation,
  BulkDeleteEdocsMutationVariables
>
export const DeleteCargowiseConfigDocument = gql`
  mutation deleteCargowiseConfig($cwConfigId: UUID!) {
    deleteCargowiseConfig(cwConfigId: $cwConfigId) {
      ok
    }
  }
`
export type DeleteCargowiseConfigMutationFn = Apollo.MutationFunction<
  DeleteCargowiseConfigMutation,
  DeleteCargowiseConfigMutationVariables
>

/**
 * __useDeleteCargowiseConfigMutation__
 *
 * To run a mutation, you first call `useDeleteCargowiseConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCargowiseConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCargowiseConfigMutation, { data, loading, error }] = useDeleteCargowiseConfigMutation({
 *   variables: {
 *      cwConfigId: // value for 'cwConfigId'
 *   },
 * });
 */
export function useDeleteCargowiseConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCargowiseConfigMutation,
    DeleteCargowiseConfigMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCargowiseConfigMutation, DeleteCargowiseConfigMutationVariables>(
    DeleteCargowiseConfigDocument,
    options,
  )
}
export type DeleteCargowiseConfigMutationHookResult = ReturnType<
  typeof useDeleteCargowiseConfigMutation
>
export type DeleteCargowiseConfigMutationResult =
  Apollo.MutationResult<DeleteCargowiseConfigMutation>
export type DeleteCargowiseConfigMutationOptions = Apollo.BaseMutationOptions<
  DeleteCargowiseConfigMutation,
  DeleteCargowiseConfigMutationVariables
>
export const CreateCargowiseConfigDocument = gql`
  mutation createCargowiseConfig($inputCwConfig: InputCargowiseConfig!) {
    createCargowiseConfig(inputCwConfig: $inputCwConfig) {
      ok
    }
  }
`
export type CreateCargowiseConfigMutationFn = Apollo.MutationFunction<
  CreateCargowiseConfigMutation,
  CreateCargowiseConfigMutationVariables
>

/**
 * __useCreateCargowiseConfigMutation__
 *
 * To run a mutation, you first call `useCreateCargowiseConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCargowiseConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCargowiseConfigMutation, { data, loading, error }] = useCreateCargowiseConfigMutation({
 *   variables: {
 *      inputCwConfig: // value for 'inputCwConfig'
 *   },
 * });
 */
export function useCreateCargowiseConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCargowiseConfigMutation,
    CreateCargowiseConfigMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCargowiseConfigMutation, CreateCargowiseConfigMutationVariables>(
    CreateCargowiseConfigDocument,
    options,
  )
}
export type CreateCargowiseConfigMutationHookResult = ReturnType<
  typeof useCreateCargowiseConfigMutation
>
export type CreateCargowiseConfigMutationResult =
  Apollo.MutationResult<CreateCargowiseConfigMutation>
export type CreateCargowiseConfigMutationOptions = Apollo.BaseMutationOptions<
  CreateCargowiseConfigMutation,
  CreateCargowiseConfigMutationVariables
>
export const UpdateCargowiseConfigDocument = gql`
  mutation updateCargowiseConfig($id: UUID!, $inputCwConfig: InputCargowiseConfig!) {
    updateCargowiseConfig(id: $id, inputCwConfig: $inputCwConfig) {
      cwConfig {
        id
        code
        countryCode
        enterpriseId
        serverId
        outboundPass
        dbPass
        dbUser
        dbName
        remoteDbHostname
        remoteDbPort
        pollDatabase
        proxyUrls
        connectorType
        forceFetch
        dbDirectConnect
        externApiCredential {
          id
          username
          workflow
        }
        company {
          id
          name
        }
        jobTemplates {
          edges {
            node {
              id
              name
              company {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
export type UpdateCargowiseConfigMutationFn = Apollo.MutationFunction<
  UpdateCargowiseConfigMutation,
  UpdateCargowiseConfigMutationVariables
>

/**
 * __useUpdateCargowiseConfigMutation__
 *
 * To run a mutation, you first call `useUpdateCargowiseConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCargowiseConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCargowiseConfigMutation, { data, loading, error }] = useUpdateCargowiseConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inputCwConfig: // value for 'inputCwConfig'
 *   },
 * });
 */
export function useUpdateCargowiseConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCargowiseConfigMutation,
    UpdateCargowiseConfigMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCargowiseConfigMutation, UpdateCargowiseConfigMutationVariables>(
    UpdateCargowiseConfigDocument,
    options,
  )
}
export type UpdateCargowiseConfigMutationHookResult = ReturnType<
  typeof useUpdateCargowiseConfigMutation
>
export type UpdateCargowiseConfigMutationResult =
  Apollo.MutationResult<UpdateCargowiseConfigMutation>
export type UpdateCargowiseConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateCargowiseConfigMutation,
  UpdateCargowiseConfigMutationVariables
>
export const TestCargowiseConfigDocument = gql`
  mutation testCargowiseConfig($cwConfigId: UUID!) {
    testCargowiseConfig(cwConfigId: $cwConfigId) {
      ok
    }
  }
`
export type TestCargowiseConfigMutationFn = Apollo.MutationFunction<
  TestCargowiseConfigMutation,
  TestCargowiseConfigMutationVariables
>

/**
 * __useTestCargowiseConfigMutation__
 *
 * To run a mutation, you first call `useTestCargowiseConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestCargowiseConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testCargowiseConfigMutation, { data, loading, error }] = useTestCargowiseConfigMutation({
 *   variables: {
 *      cwConfigId: // value for 'cwConfigId'
 *   },
 * });
 */
export function useTestCargowiseConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TestCargowiseConfigMutation,
    TestCargowiseConfigMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TestCargowiseConfigMutation, TestCargowiseConfigMutationVariables>(
    TestCargowiseConfigDocument,
    options,
  )
}
export type TestCargowiseConfigMutationHookResult = ReturnType<
  typeof useTestCargowiseConfigMutation
>
export type TestCargowiseConfigMutationResult = Apollo.MutationResult<TestCargowiseConfigMutation>
export type TestCargowiseConfigMutationOptions = Apollo.BaseMutationOptions<
  TestCargowiseConfigMutation,
  TestCargowiseConfigMutationVariables
>
export const SendShipmentOpsToCwDocument = gql`
  mutation sendShipmentOpsToCw($jobId: UUID!) {
    sendShipmentOpsToCw(jobId: $jobId) {
      shipmentReferenceNumber
      consolReferenceNumber
      importDecReferenceNumber
      processingLog
    }
  }
`
export type SendShipmentOpsToCwMutationFn = Apollo.MutationFunction<
  SendShipmentOpsToCwMutation,
  SendShipmentOpsToCwMutationVariables
>

/**
 * __useSendShipmentOpsToCwMutation__
 *
 * To run a mutation, you first call `useSendShipmentOpsToCwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendShipmentOpsToCwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendShipmentOpsToCwMutation, { data, loading, error }] = useSendShipmentOpsToCwMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useSendShipmentOpsToCwMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendShipmentOpsToCwMutation,
    SendShipmentOpsToCwMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendShipmentOpsToCwMutation, SendShipmentOpsToCwMutationVariables>(
    SendShipmentOpsToCwDocument,
    options,
  )
}
export type SendShipmentOpsToCwMutationHookResult = ReturnType<
  typeof useSendShipmentOpsToCwMutation
>
export type SendShipmentOpsToCwMutationResult = Apollo.MutationResult<SendShipmentOpsToCwMutation>
export type SendShipmentOpsToCwMutationOptions = Apollo.BaseMutationOptions<
  SendShipmentOpsToCwMutation,
  SendShipmentOpsToCwMutationVariables
>
export const SyncCargowiseDbDocument = gql`
  mutation syncCargowiseDb {
    syncCargowiseDb
  }
`
export type SyncCargowiseDbMutationFn = Apollo.MutationFunction<
  SyncCargowiseDbMutation,
  SyncCargowiseDbMutationVariables
>

/**
 * __useSyncCargowiseDbMutation__
 *
 * To run a mutation, you first call `useSyncCargowiseDbMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncCargowiseDbMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncCargowiseDbMutation, { data, loading, error }] = useSyncCargowiseDbMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncCargowiseDbMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncCargowiseDbMutation,
    SyncCargowiseDbMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SyncCargowiseDbMutation, SyncCargowiseDbMutationVariables>(
    SyncCargowiseDbDocument,
    options,
  )
}
export type SyncCargowiseDbMutationHookResult = ReturnType<typeof useSyncCargowiseDbMutation>
export type SyncCargowiseDbMutationResult = Apollo.MutationResult<SyncCargowiseDbMutation>
export type SyncCargowiseDbMutationOptions = Apollo.BaseMutationOptions<
  SyncCargowiseDbMutation,
  SyncCargowiseDbMutationVariables
>
export const SendBFileToCwDocument = gql`
  mutation sendBFileToCw($jobId: UUID!) {
    sendBFileToCw(jobId: $jobId) {
      cdecReferenceNumber
      consolReferenceNumber
      shipmentReferenceNumber
    }
  }
`
export type SendBFileToCwMutationFn = Apollo.MutationFunction<
  SendBFileToCwMutation,
  SendBFileToCwMutationVariables
>

/**
 * __useSendBFileToCwMutation__
 *
 * To run a mutation, you first call `useSendBFileToCwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBFileToCwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBFileToCwMutation, { data, loading, error }] = useSendBFileToCwMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useSendBFileToCwMutation(
  baseOptions?: Apollo.MutationHookOptions<SendBFileToCwMutation, SendBFileToCwMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendBFileToCwMutation, SendBFileToCwMutationVariables>(
    SendBFileToCwDocument,
    options,
  )
}
export type SendBFileToCwMutationHookResult = ReturnType<typeof useSendBFileToCwMutation>
export type SendBFileToCwMutationResult = Apollo.MutationResult<SendBFileToCwMutation>
export type SendBFileToCwMutationOptions = Apollo.BaseMutationOptions<
  SendBFileToCwMutation,
  SendBFileToCwMutationVariables
>
export const RecreateReindexMirrorDocument = gql`
  mutation recreateReindexMirror($apiPartnerIds: [UUID!]) {
    recreateReindexMirror(apiPartnerIds: $apiPartnerIds) {
      reindexMirrorAsyncTaskId
    }
  }
`
export type RecreateReindexMirrorMutationFn = Apollo.MutationFunction<
  RecreateReindexMirrorMutation,
  RecreateReindexMirrorMutationVariables
>

/**
 * __useRecreateReindexMirrorMutation__
 *
 * To run a mutation, you first call `useRecreateReindexMirrorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecreateReindexMirrorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recreateReindexMirrorMutation, { data, loading, error }] = useRecreateReindexMirrorMutation({
 *   variables: {
 *      apiPartnerIds: // value for 'apiPartnerIds'
 *   },
 * });
 */
export function useRecreateReindexMirrorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RecreateReindexMirrorMutation,
    RecreateReindexMirrorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecreateReindexMirrorMutation, RecreateReindexMirrorMutationVariables>(
    RecreateReindexMirrorDocument,
    options,
  )
}
export type RecreateReindexMirrorMutationHookResult = ReturnType<
  typeof useRecreateReindexMirrorMutation
>
export type RecreateReindexMirrorMutationResult =
  Apollo.MutationResult<RecreateReindexMirrorMutation>
export type RecreateReindexMirrorMutationOptions = Apollo.BaseMutationOptions<
  RecreateReindexMirrorMutation,
  RecreateReindexMirrorMutationVariables
>
export const IndexQueueIntoSnowflakeDocument = gql`
  mutation indexQueueIntoSnowflake($apiPartnerIds: [UUID!]) {
    indexQueueIntoSnowflake(apiPartnerIds: $apiPartnerIds)
  }
`
export type IndexQueueIntoSnowflakeMutationFn = Apollo.MutationFunction<
  IndexQueueIntoSnowflakeMutation,
  IndexQueueIntoSnowflakeMutationVariables
>

/**
 * __useIndexQueueIntoSnowflakeMutation__
 *
 * To run a mutation, you first call `useIndexQueueIntoSnowflakeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndexQueueIntoSnowflakeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [indexQueueIntoSnowflakeMutation, { data, loading, error }] = useIndexQueueIntoSnowflakeMutation({
 *   variables: {
 *      apiPartnerIds: // value for 'apiPartnerIds'
 *   },
 * });
 */
export function useIndexQueueIntoSnowflakeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IndexQueueIntoSnowflakeMutation,
    IndexQueueIntoSnowflakeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IndexQueueIntoSnowflakeMutation,
    IndexQueueIntoSnowflakeMutationVariables
  >(IndexQueueIntoSnowflakeDocument, options)
}
export type IndexQueueIntoSnowflakeMutationHookResult = ReturnType<
  typeof useIndexQueueIntoSnowflakeMutation
>
export type IndexQueueIntoSnowflakeMutationResult =
  Apollo.MutationResult<IndexQueueIntoSnowflakeMutation>
export type IndexQueueIntoSnowflakeMutationOptions = Apollo.BaseMutationOptions<
  IndexQueueIntoSnowflakeMutation,
  IndexQueueIntoSnowflakeMutationVariables
>
export const SyncCauldronDbDocument = gql`
  mutation syncCauldronDb($apiPartnerIds: [UUID!]) {
    syncCauldronDb(apiPartnerIds: $apiPartnerIds)
  }
`
export type SyncCauldronDbMutationFn = Apollo.MutationFunction<
  SyncCauldronDbMutation,
  SyncCauldronDbMutationVariables
>

/**
 * __useSyncCauldronDbMutation__
 *
 * To run a mutation, you first call `useSyncCauldronDbMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncCauldronDbMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncCauldronDbMutation, { data, loading, error }] = useSyncCauldronDbMutation({
 *   variables: {
 *      apiPartnerIds: // value for 'apiPartnerIds'
 *   },
 * });
 */
export function useSyncCauldronDbMutation(
  baseOptions?: Apollo.MutationHookOptions<SyncCauldronDbMutation, SyncCauldronDbMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SyncCauldronDbMutation, SyncCauldronDbMutationVariables>(
    SyncCauldronDbDocument,
    options,
  )
}
export type SyncCauldronDbMutationHookResult = ReturnType<typeof useSyncCauldronDbMutation>
export type SyncCauldronDbMutationResult = Apollo.MutationResult<SyncCauldronDbMutation>
export type SyncCauldronDbMutationOptions = Apollo.BaseMutationOptions<
  SyncCauldronDbMutation,
  SyncCauldronDbMutationVariables
>
export const SyncSearchableRecordsDocument = gql`
  mutation syncSearchableRecords($companyIds: [UUID!]) {
    syncSearchableRecords(companyIds: $companyIds)
  }
`
export type SyncSearchableRecordsMutationFn = Apollo.MutationFunction<
  SyncSearchableRecordsMutation,
  SyncSearchableRecordsMutationVariables
>

/**
 * __useSyncSearchableRecordsMutation__
 *
 * To run a mutation, you first call `useSyncSearchableRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncSearchableRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncSearchableRecordsMutation, { data, loading, error }] = useSyncSearchableRecordsMutation({
 *   variables: {
 *      companyIds: // value for 'companyIds'
 *   },
 * });
 */
export function useSyncSearchableRecordsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncSearchableRecordsMutation,
    SyncSearchableRecordsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SyncSearchableRecordsMutation, SyncSearchableRecordsMutationVariables>(
    SyncSearchableRecordsDocument,
    options,
  )
}
export type SyncSearchableRecordsMutationHookResult = ReturnType<
  typeof useSyncSearchableRecordsMutation
>
export type SyncSearchableRecordsMutationResult =
  Apollo.MutationResult<SyncSearchableRecordsMutation>
export type SyncSearchableRecordsMutationOptions = Apollo.BaseMutationOptions<
  SyncSearchableRecordsMutation,
  SyncSearchableRecordsMutationVariables
>
export const EadaptorColdStartDocument = gql`
  mutation eadaptorColdStart(
    $apiPartnerName: String!
    $eadaptorColdStartParams: InputEAdaptorColdStartParams!
  ) {
    eadaptorColdStart(
      apiPartnerName: $apiPartnerName
      eadaptorColdStartParams: $eadaptorColdStartParams
    )
  }
`
export type EadaptorColdStartMutationFn = Apollo.MutationFunction<
  EadaptorColdStartMutation,
  EadaptorColdStartMutationVariables
>

/**
 * __useEadaptorColdStartMutation__
 *
 * To run a mutation, you first call `useEadaptorColdStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEadaptorColdStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eadaptorColdStartMutation, { data, loading, error }] = useEadaptorColdStartMutation({
 *   variables: {
 *      apiPartnerName: // value for 'apiPartnerName'
 *      eadaptorColdStartParams: // value for 'eadaptorColdStartParams'
 *   },
 * });
 */
export function useEadaptorColdStartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EadaptorColdStartMutation,
    EadaptorColdStartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EadaptorColdStartMutation, EadaptorColdStartMutationVariables>(
    EadaptorColdStartDocument,
    options,
  )
}
export type EadaptorColdStartMutationHookResult = ReturnType<typeof useEadaptorColdStartMutation>
export type EadaptorColdStartMutationResult = Apollo.MutationResult<EadaptorColdStartMutation>
export type EadaptorColdStartMutationOptions = Apollo.BaseMutationOptions<
  EadaptorColdStartMutation,
  EadaptorColdStartMutationVariables
>
export const EditCompanyDocument = gql`
  mutation editCompany($editCompanyRequest: EditCompanyRequest!) {
    editCompany(editCompanyRequest: $editCompanyRequest) {
      id
    }
  }
`
export type EditCompanyMutationFn = Apollo.MutationFunction<
  EditCompanyMutation,
  EditCompanyMutationVariables
>

/**
 * __useEditCompanyMutation__
 *
 * To run a mutation, you first call `useEditCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyMutation, { data, loading, error }] = useEditCompanyMutation({
 *   variables: {
 *      editCompanyRequest: // value for 'editCompanyRequest'
 *   },
 * });
 */
export function useEditCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<EditCompanyMutation, EditCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditCompanyMutation, EditCompanyMutationVariables>(
    EditCompanyDocument,
    options,
  )
}
export type EditCompanyMutationHookResult = ReturnType<typeof useEditCompanyMutation>
export type EditCompanyMutationResult = Apollo.MutationResult<EditCompanyMutation>
export type EditCompanyMutationOptions = Apollo.BaseMutationOptions<
  EditCompanyMutation,
  EditCompanyMutationVariables
>
export const DeleteCompanyDocument = gql`
  mutation deleteCompany($companyId: String!) {
    deleteCompany(companyId: $companyId) {
      ok
    }
  }
`
export type DeleteCompanyMutationFn = Apollo.MutationFunction<
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables
>

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeleteCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(
    DeleteCompanyDocument,
    options,
  )
}
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables
>
export const CreateCompanyDocument = gql`
  mutation createCompany($name: String!) {
    createCompany(name: $name) {
      company {
        id
      }
    }
  }
`
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(
    CreateCompanyDocument,
    options,
  )
}
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>
export const DeleteCredentialDocument = gql`
  mutation deleteCredential($credentialId: UUID!) {
    deleteCredential(credentialId: $credentialId)
  }
`
export type DeleteCredentialMutationFn = Apollo.MutationFunction<
  DeleteCredentialMutation,
  DeleteCredentialMutationVariables
>

/**
 * __useDeleteCredentialMutation__
 *
 * To run a mutation, you first call `useDeleteCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCredentialMutation, { data, loading, error }] = useDeleteCredentialMutation({
 *   variables: {
 *      credentialId: // value for 'credentialId'
 *   },
 * });
 */
export function useDeleteCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCredentialMutation,
    DeleteCredentialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCredentialMutation, DeleteCredentialMutationVariables>(
    DeleteCredentialDocument,
    options,
  )
}
export type DeleteCredentialMutationHookResult = ReturnType<typeof useDeleteCredentialMutation>
export type DeleteCredentialMutationResult = Apollo.MutationResult<DeleteCredentialMutation>
export type DeleteCredentialMutationOptions = Apollo.BaseMutationOptions<
  DeleteCredentialMutation,
  DeleteCredentialMutationVariables
>
export const CreateCredentialDocument = gql`
  mutation createCredential(
    $workflow: String!
    $username: String!
    $password: String!
    $apiEndpoint: String
    $jobTemplateIds: [UUID!]
    $verifySsl: Boolean!
    $hostOverride: String
  ) {
    createCredential(
      workflow: $workflow
      username: $username
      password: $password
      apiEndpoint: $apiEndpoint
      jobTemplateIds: $jobTemplateIds
      verifySsl: $verifySsl
      hostOverride: $hostOverride
    )
  }
`
export type CreateCredentialMutationFn = Apollo.MutationFunction<
  CreateCredentialMutation,
  CreateCredentialMutationVariables
>

/**
 * __useCreateCredentialMutation__
 *
 * To run a mutation, you first call `useCreateCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCredentialMutation, { data, loading, error }] = useCreateCredentialMutation({
 *   variables: {
 *      workflow: // value for 'workflow'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      apiEndpoint: // value for 'apiEndpoint'
 *      jobTemplateIds: // value for 'jobTemplateIds'
 *      verifySsl: // value for 'verifySsl'
 *      hostOverride: // value for 'hostOverride'
 *   },
 * });
 */
export function useCreateCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCredentialMutation,
    CreateCredentialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCredentialMutation, CreateCredentialMutationVariables>(
    CreateCredentialDocument,
    options,
  )
}
export type CreateCredentialMutationHookResult = ReturnType<typeof useCreateCredentialMutation>
export type CreateCredentialMutationResult = Apollo.MutationResult<CreateCredentialMutation>
export type CreateCredentialMutationOptions = Apollo.BaseMutationOptions<
  CreateCredentialMutation,
  CreateCredentialMutationVariables
>
export const UpdateCredentialDocument = gql`
  mutation updateCredential(
    $id: UUID!
    $workflow: String!
    $username: String!
    $password: String
    $apiEndpoint: String
    $jobTemplateIds: [UUID!]
    $verifySsl: Boolean!
    $hostOverride: String
  ) {
    updateCredential(
      id: $id
      workflow: $workflow
      username: $username
      password: $password
      apiEndpoint: $apiEndpoint
      jobTemplateIds: $jobTemplateIds
      verifySsl: $verifySsl
      hostOverride: $hostOverride
    ) {
      id
      username
      apiEndpoint
      workflow
      verifySsl
      jobTemplates {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`
export type UpdateCredentialMutationFn = Apollo.MutationFunction<
  UpdateCredentialMutation,
  UpdateCredentialMutationVariables
>

/**
 * __useUpdateCredentialMutation__
 *
 * To run a mutation, you first call `useUpdateCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCredentialMutation, { data, loading, error }] = useUpdateCredentialMutation({
 *   variables: {
 *      id: // value for 'id'
 *      workflow: // value for 'workflow'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      apiEndpoint: // value for 'apiEndpoint'
 *      jobTemplateIds: // value for 'jobTemplateIds'
 *      verifySsl: // value for 'verifySsl'
 *      hostOverride: // value for 'hostOverride'
 *   },
 * });
 */
export function useUpdateCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCredentialMutation,
    UpdateCredentialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCredentialMutation, UpdateCredentialMutationVariables>(
    UpdateCredentialDocument,
    options,
  )
}
export type UpdateCredentialMutationHookResult = ReturnType<typeof useUpdateCredentialMutation>
export type UpdateCredentialMutationResult = Apollo.MutationResult<UpdateCredentialMutation>
export type UpdateCredentialMutationOptions = Apollo.BaseMutationOptions<
  UpdateCredentialMutation,
  UpdateCredentialMutationVariables
>
export const TestCredentialDocument = gql`
  mutation testCredential($credentialId: UUID!) {
    testCredential(credentialId: $credentialId)
  }
`
export type TestCredentialMutationFn = Apollo.MutationFunction<
  TestCredentialMutation,
  TestCredentialMutationVariables
>

/**
 * __useTestCredentialMutation__
 *
 * To run a mutation, you first call `useTestCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testCredentialMutation, { data, loading, error }] = useTestCredentialMutation({
 *   variables: {
 *      credentialId: // value for 'credentialId'
 *   },
 * });
 */
export function useTestCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<TestCredentialMutation, TestCredentialMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TestCredentialMutation, TestCredentialMutationVariables>(
    TestCredentialDocument,
    options,
  )
}
export type TestCredentialMutationHookResult = ReturnType<typeof useTestCredentialMutation>
export type TestCredentialMutationResult = Apollo.MutationResult<TestCredentialMutation>
export type TestCredentialMutationOptions = Apollo.BaseMutationOptions<
  TestCredentialMutation,
  TestCredentialMutationVariables
>
export const CreateCustomDatabaseTypeDocument = gql`
  mutation createCustomDatabaseType($inputCustomDatabaseType: InputCustomDatabaseType!) {
    createCustomDatabaseType(inputCustomDatabaseType: $inputCustomDatabaseType) {
      ok
    }
  }
`
export type CreateCustomDatabaseTypeMutationFn = Apollo.MutationFunction<
  CreateCustomDatabaseTypeMutation,
  CreateCustomDatabaseTypeMutationVariables
>

/**
 * __useCreateCustomDatabaseTypeMutation__
 *
 * To run a mutation, you first call `useCreateCustomDatabaseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomDatabaseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomDatabaseTypeMutation, { data, loading, error }] = useCreateCustomDatabaseTypeMutation({
 *   variables: {
 *      inputCustomDatabaseType: // value for 'inputCustomDatabaseType'
 *   },
 * });
 */
export function useCreateCustomDatabaseTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomDatabaseTypeMutation,
    CreateCustomDatabaseTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCustomDatabaseTypeMutation,
    CreateCustomDatabaseTypeMutationVariables
  >(CreateCustomDatabaseTypeDocument, options)
}
export type CreateCustomDatabaseTypeMutationHookResult = ReturnType<
  typeof useCreateCustomDatabaseTypeMutation
>
export type CreateCustomDatabaseTypeMutationResult =
  Apollo.MutationResult<CreateCustomDatabaseTypeMutation>
export type CreateCustomDatabaseTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomDatabaseTypeMutation,
  CreateCustomDatabaseTypeMutationVariables
>
export const EditCustomDatabaseTypeDocument = gql`
  mutation editCustomDatabaseType(
    $customDatabaseTypeId: UUID!
    $inputCustomDatabaseType: InputCustomDatabaseType!
  ) {
    editCustomDatabaseType(
      customDatabaseTypeId: $customDatabaseTypeId
      inputCustomDatabaseType: $inputCustomDatabaseType
    ) {
      id
    }
  }
`
export type EditCustomDatabaseTypeMutationFn = Apollo.MutationFunction<
  EditCustomDatabaseTypeMutation,
  EditCustomDatabaseTypeMutationVariables
>

/**
 * __useEditCustomDatabaseTypeMutation__
 *
 * To run a mutation, you first call `useEditCustomDatabaseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomDatabaseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomDatabaseTypeMutation, { data, loading, error }] = useEditCustomDatabaseTypeMutation({
 *   variables: {
 *      customDatabaseTypeId: // value for 'customDatabaseTypeId'
 *      inputCustomDatabaseType: // value for 'inputCustomDatabaseType'
 *   },
 * });
 */
export function useEditCustomDatabaseTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditCustomDatabaseTypeMutation,
    EditCustomDatabaseTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EditCustomDatabaseTypeMutation,
    EditCustomDatabaseTypeMutationVariables
  >(EditCustomDatabaseTypeDocument, options)
}
export type EditCustomDatabaseTypeMutationHookResult = ReturnType<
  typeof useEditCustomDatabaseTypeMutation
>
export type EditCustomDatabaseTypeMutationResult =
  Apollo.MutationResult<EditCustomDatabaseTypeMutation>
export type EditCustomDatabaseTypeMutationOptions = Apollo.BaseMutationOptions<
  EditCustomDatabaseTypeMutation,
  EditCustomDatabaseTypeMutationVariables
>
export const DeleteCustomDatabaseTypeDocument = gql`
  mutation deleteCustomDatabaseType($customDatabaseTypeId: UUID!) {
    deleteCustomDatabaseType(customDatabaseTypeId: $customDatabaseTypeId) {
      ok
    }
  }
`
export type DeleteCustomDatabaseTypeMutationFn = Apollo.MutationFunction<
  DeleteCustomDatabaseTypeMutation,
  DeleteCustomDatabaseTypeMutationVariables
>

/**
 * __useDeleteCustomDatabaseTypeMutation__
 *
 * To run a mutation, you first call `useDeleteCustomDatabaseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomDatabaseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomDatabaseTypeMutation, { data, loading, error }] = useDeleteCustomDatabaseTypeMutation({
 *   variables: {
 *      customDatabaseTypeId: // value for 'customDatabaseTypeId'
 *   },
 * });
 */
export function useDeleteCustomDatabaseTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomDatabaseTypeMutation,
    DeleteCustomDatabaseTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCustomDatabaseTypeMutation,
    DeleteCustomDatabaseTypeMutationVariables
  >(DeleteCustomDatabaseTypeDocument, options)
}
export type DeleteCustomDatabaseTypeMutationHookResult = ReturnType<
  typeof useDeleteCustomDatabaseTypeMutation
>
export type DeleteCustomDatabaseTypeMutationResult =
  Apollo.MutationResult<DeleteCustomDatabaseTypeMutation>
export type DeleteCustomDatabaseTypeMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomDatabaseTypeMutation,
  DeleteCustomDatabaseTypeMutationVariables
>
export const CreateCustomDatabaseDocument = gql`
  mutation createCustomDatabase($inputCustomDatabase: InputCustomDatabase!, $apiPartnerId: UUID!) {
    createCustomDatabase(inputCustomDatabase: $inputCustomDatabase, apiPartnerId: $apiPartnerId) {
      ok
    }
  }
`
export type CreateCustomDatabaseMutationFn = Apollo.MutationFunction<
  CreateCustomDatabaseMutation,
  CreateCustomDatabaseMutationVariables
>

/**
 * __useCreateCustomDatabaseMutation__
 *
 * To run a mutation, you first call `useCreateCustomDatabaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomDatabaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomDatabaseMutation, { data, loading, error }] = useCreateCustomDatabaseMutation({
 *   variables: {
 *      inputCustomDatabase: // value for 'inputCustomDatabase'
 *      apiPartnerId: // value for 'apiPartnerId'
 *   },
 * });
 */
export function useCreateCustomDatabaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomDatabaseMutation,
    CreateCustomDatabaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCustomDatabaseMutation, CreateCustomDatabaseMutationVariables>(
    CreateCustomDatabaseDocument,
    options,
  )
}
export type CreateCustomDatabaseMutationHookResult = ReturnType<
  typeof useCreateCustomDatabaseMutation
>
export type CreateCustomDatabaseMutationResult = Apollo.MutationResult<CreateCustomDatabaseMutation>
export type CreateCustomDatabaseMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomDatabaseMutation,
  CreateCustomDatabaseMutationVariables
>
export const EditCustomDatabaseDocument = gql`
  mutation editCustomDatabase(
    $customDatabaseId: UUID!
    $inputCustomDatabase: InputCustomDatabase!
  ) {
    editCustomDatabase(
      customDatabaseId: $customDatabaseId
      inputCustomDatabase: $inputCustomDatabase
    ) {
      id
    }
  }
`
export type EditCustomDatabaseMutationFn = Apollo.MutationFunction<
  EditCustomDatabaseMutation,
  EditCustomDatabaseMutationVariables
>

/**
 * __useEditCustomDatabaseMutation__
 *
 * To run a mutation, you first call `useEditCustomDatabaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomDatabaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomDatabaseMutation, { data, loading, error }] = useEditCustomDatabaseMutation({
 *   variables: {
 *      customDatabaseId: // value for 'customDatabaseId'
 *      inputCustomDatabase: // value for 'inputCustomDatabase'
 *   },
 * });
 */
export function useEditCustomDatabaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditCustomDatabaseMutation,
    EditCustomDatabaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditCustomDatabaseMutation, EditCustomDatabaseMutationVariables>(
    EditCustomDatabaseDocument,
    options,
  )
}
export type EditCustomDatabaseMutationHookResult = ReturnType<typeof useEditCustomDatabaseMutation>
export type EditCustomDatabaseMutationResult = Apollo.MutationResult<EditCustomDatabaseMutation>
export type EditCustomDatabaseMutationOptions = Apollo.BaseMutationOptions<
  EditCustomDatabaseMutation,
  EditCustomDatabaseMutationVariables
>
export const DeleteCustomDatabaseDocument = gql`
  mutation deleteCustomDatabase($customDatabaseId: UUID!) {
    deleteCustomDatabase(customDatabaseId: $customDatabaseId) {
      ok
    }
  }
`
export type DeleteCustomDatabaseMutationFn = Apollo.MutationFunction<
  DeleteCustomDatabaseMutation,
  DeleteCustomDatabaseMutationVariables
>

/**
 * __useDeleteCustomDatabaseMutation__
 *
 * To run a mutation, you first call `useDeleteCustomDatabaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomDatabaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomDatabaseMutation, { data, loading, error }] = useDeleteCustomDatabaseMutation({
 *   variables: {
 *      customDatabaseId: // value for 'customDatabaseId'
 *   },
 * });
 */
export function useDeleteCustomDatabaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomDatabaseMutation,
    DeleteCustomDatabaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCustomDatabaseMutation, DeleteCustomDatabaseMutationVariables>(
    DeleteCustomDatabaseDocument,
    options,
  )
}
export type DeleteCustomDatabaseMutationHookResult = ReturnType<
  typeof useDeleteCustomDatabaseMutation
>
export type DeleteCustomDatabaseMutationResult = Apollo.MutationResult<DeleteCustomDatabaseMutation>
export type DeleteCustomDatabaseMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomDatabaseMutation,
  DeleteCustomDatabaseMutationVariables
>
export const CreateDocumentDocument = gql`
  mutation createDocument(
    $filePageId: UUID!
    $documentTypeId: UUID!
    $jobId: UUID!
    $batchId: UUID!
  ) {
    createDocument(
      filePageId: $filePageId
      documentTypeId: $documentTypeId
      jobId: $jobId
      batchId: $batchId
    ) {
      ok
    }
  }
`
export type CreateDocumentMutationFn = Apollo.MutationFunction<
  CreateDocumentMutation,
  CreateDocumentMutationVariables
>

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      filePageId: // value for 'filePageId'
 *      documentTypeId: // value for 'documentTypeId'
 *      jobId: // value for 'jobId'
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useCreateDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(
    CreateDocumentDocument,
    options,
  )
}
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateDocumentMutation,
  CreateDocumentMutationVariables
>
export const BatchAttachDocumentToShipmentDocument = gql`
  mutation batchAttachDocumentToShipment(
    $documents: [InputDocument!]!
    $jobId: String!
    $validateFields: Boolean!
    $newJobStatus: String
    $jobTable: InputJobTable
  ) {
    batchAttachDocumentToShipment(
      documents: $documents
      jobId: $jobId
      validateFields: $validateFields
      newJobStatus: $newJobStatus
      jobTable: $jobTable
    ) {
      job {
        id
      }
    }
  }
`
export type BatchAttachDocumentToShipmentMutationFn = Apollo.MutationFunction<
  BatchAttachDocumentToShipmentMutation,
  BatchAttachDocumentToShipmentMutationVariables
>

/**
 * __useBatchAttachDocumentToShipmentMutation__
 *
 * To run a mutation, you first call `useBatchAttachDocumentToShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchAttachDocumentToShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchAttachDocumentToShipmentMutation, { data, loading, error }] = useBatchAttachDocumentToShipmentMutation({
 *   variables: {
 *      documents: // value for 'documents'
 *      jobId: // value for 'jobId'
 *      validateFields: // value for 'validateFields'
 *      newJobStatus: // value for 'newJobStatus'
 *      jobTable: // value for 'jobTable'
 *   },
 * });
 */
export function useBatchAttachDocumentToShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchAttachDocumentToShipmentMutation,
    BatchAttachDocumentToShipmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BatchAttachDocumentToShipmentMutation,
    BatchAttachDocumentToShipmentMutationVariables
  >(BatchAttachDocumentToShipmentDocument, options)
}
export type BatchAttachDocumentToShipmentMutationHookResult = ReturnType<
  typeof useBatchAttachDocumentToShipmentMutation
>
export type BatchAttachDocumentToShipmentMutationResult =
  Apollo.MutationResult<BatchAttachDocumentToShipmentMutation>
export type BatchAttachDocumentToShipmentMutationOptions = Apollo.BaseMutationOptions<
  BatchAttachDocumentToShipmentMutation,
  BatchAttachDocumentToShipmentMutationVariables
>
export const SaveDocumentFieldsDocument = gql`
  mutation saveDocumentFields($jobId: String!, $documents: [InputDocument!]!) {
    saveDocumentFields(jobId: $jobId, documents: $documents) {
      ok
    }
  }
`
export type SaveDocumentFieldsMutationFn = Apollo.MutationFunction<
  SaveDocumentFieldsMutation,
  SaveDocumentFieldsMutationVariables
>

/**
 * __useSaveDocumentFieldsMutation__
 *
 * To run a mutation, you first call `useSaveDocumentFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDocumentFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDocumentFieldsMutation, { data, loading, error }] = useSaveDocumentFieldsMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      documents: // value for 'documents'
 *   },
 * });
 */
export function useSaveDocumentFieldsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveDocumentFieldsMutation,
    SaveDocumentFieldsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveDocumentFieldsMutation, SaveDocumentFieldsMutationVariables>(
    SaveDocumentFieldsDocument,
    options,
  )
}
export type SaveDocumentFieldsMutationHookResult = ReturnType<typeof useSaveDocumentFieldsMutation>
export type SaveDocumentFieldsMutationResult = Apollo.MutationResult<SaveDocumentFieldsMutation>
export type SaveDocumentFieldsMutationOptions = Apollo.BaseMutationOptions<
  SaveDocumentFieldsMutation,
  SaveDocumentFieldsMutationVariables
>
export const SaveDocumentTableDocument = gql`
  mutation saveDocumentTable(
    $jobId: String!
    $documentTable: InputDocumentTable!
    $validateFields: Boolean!
  ) {
    saveDocumentTable(
      jobId: $jobId
      documentTable: $documentTable
      validateFields: $validateFields
    ) {
      ok
    }
  }
`
export type SaveDocumentTableMutationFn = Apollo.MutationFunction<
  SaveDocumentTableMutation,
  SaveDocumentTableMutationVariables
>

/**
 * __useSaveDocumentTableMutation__
 *
 * To run a mutation, you first call `useSaveDocumentTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDocumentTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDocumentTableMutation, { data, loading, error }] = useSaveDocumentTableMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      documentTable: // value for 'documentTable'
 *      validateFields: // value for 'validateFields'
 *   },
 * });
 */
export function useSaveDocumentTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveDocumentTableMutation,
    SaveDocumentTableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveDocumentTableMutation, SaveDocumentTableMutationVariables>(
    SaveDocumentTableDocument,
    options,
  )
}
export type SaveDocumentTableMutationHookResult = ReturnType<typeof useSaveDocumentTableMutation>
export type SaveDocumentTableMutationResult = Apollo.MutationResult<SaveDocumentTableMutation>
export type SaveDocumentTableMutationOptions = Apollo.BaseMutationOptions<
  SaveDocumentTableMutation,
  SaveDocumentTableMutationVariables
>
export const UpsertDocumentTableFieldGroupDocument = gql`
  mutation upsertDocumentTableFieldGroup($documentTableId: String!, $fieldGroupId: String!) {
    upsertDocumentTableFieldGroup(documentTableId: $documentTableId, fieldGroupId: $fieldGroupId) {
      ok
    }
  }
`
export type UpsertDocumentTableFieldGroupMutationFn = Apollo.MutationFunction<
  UpsertDocumentTableFieldGroupMutation,
  UpsertDocumentTableFieldGroupMutationVariables
>

/**
 * __useUpsertDocumentTableFieldGroupMutation__
 *
 * To run a mutation, you first call `useUpsertDocumentTableFieldGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDocumentTableFieldGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDocumentTableFieldGroupMutation, { data, loading, error }] = useUpsertDocumentTableFieldGroupMutation({
 *   variables: {
 *      documentTableId: // value for 'documentTableId'
 *      fieldGroupId: // value for 'fieldGroupId'
 *   },
 * });
 */
export function useUpsertDocumentTableFieldGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertDocumentTableFieldGroupMutation,
    UpsertDocumentTableFieldGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertDocumentTableFieldGroupMutation,
    UpsertDocumentTableFieldGroupMutationVariables
  >(UpsertDocumentTableFieldGroupDocument, options)
}
export type UpsertDocumentTableFieldGroupMutationHookResult = ReturnType<
  typeof useUpsertDocumentTableFieldGroupMutation
>
export type UpsertDocumentTableFieldGroupMutationResult =
  Apollo.MutationResult<UpsertDocumentTableFieldGroupMutation>
export type UpsertDocumentTableFieldGroupMutationOptions = Apollo.BaseMutationOptions<
  UpsertDocumentTableFieldGroupMutation,
  UpsertDocumentTableFieldGroupMutationVariables
>
export const AddDocumentTableDocument = gql`
  mutation addDocumentTable($documentId: UUID!, $fieldGroupId: UUID!) {
    addDocumentTable(documentId: $documentId, fieldGroupId: $fieldGroupId) {
      prevTableId
      documentTable {
        id
        documentId
        fieldGroupId
        orderPriority
        nextTableId
        editedByUser
        autofillMethod
        predConfidence
        document {
          id
          documentType {
            id
            tableShowsPreset
          }
        }
        documentFieldGroups {
          edges {
            node {
              id
              document {
                id
              }
              fieldGroup {
                id
                repeatable
              }
              documentFields {
                edges {
                  node {
                    id
                    field {
                      id
                      autofillKey
                      key
                      name
                    }
                    value
                    confirmed
                    top
                    left
                    width
                    height
                    confidenceScore
                  }
                }
              }
            }
          }
        }
        documentTableColumns {
          edges {
            node {
              id
              field {
                id
                key
              }
            }
          }
        }
        fieldGroup {
          id
          name
          key
          fields {
            edges {
              node {
                id
                allowFreeText
                autofillKey
                columnOrder
                dateFormatString
                fieldType {
                  ... on FallbackValue {
                    fallbackValue
                    isFallback
                  }
                  ... on FieldTypeEnum {
                    value
                    isFallback
                  }
                }
                invalidCharsRegex
                key
                name
                partialTableAllowed
                required
                searchableRecord {
                  id
                  searchKey
                  displayKey
                  model
                }
                validatorDescription
                validatorRegex
              }
            }
          }
        }
      }
    }
  }
`
export type AddDocumentTableMutationFn = Apollo.MutationFunction<
  AddDocumentTableMutation,
  AddDocumentTableMutationVariables
>

/**
 * __useAddDocumentTableMutation__
 *
 * To run a mutation, you first call `useAddDocumentTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentTableMutation, { data, loading, error }] = useAddDocumentTableMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      fieldGroupId: // value for 'fieldGroupId'
 *   },
 * });
 */
export function useAddDocumentTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDocumentTableMutation,
    AddDocumentTableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddDocumentTableMutation, AddDocumentTableMutationVariables>(
    AddDocumentTableDocument,
    options,
  )
}
export type AddDocumentTableMutationHookResult = ReturnType<typeof useAddDocumentTableMutation>
export type AddDocumentTableMutationResult = Apollo.MutationResult<AddDocumentTableMutation>
export type AddDocumentTableMutationOptions = Apollo.BaseMutationOptions<
  AddDocumentTableMutation,
  AddDocumentTableMutationVariables
>
export const DeleteDocumentTableDocument = gql`
  mutation deleteDocumentTable($documentTableId: String!) {
    deleteDocumentTable(documentTableId: $documentTableId) {
      ok
    }
  }
`
export type DeleteDocumentTableMutationFn = Apollo.MutationFunction<
  DeleteDocumentTableMutation,
  DeleteDocumentTableMutationVariables
>

/**
 * __useDeleteDocumentTableMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentTableMutation, { data, loading, error }] = useDeleteDocumentTableMutation({
 *   variables: {
 *      documentTableId: // value for 'documentTableId'
 *   },
 * });
 */
export function useDeleteDocumentTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDocumentTableMutation,
    DeleteDocumentTableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteDocumentTableMutation, DeleteDocumentTableMutationVariables>(
    DeleteDocumentTableDocument,
    options,
  )
}
export type DeleteDocumentTableMutationHookResult = ReturnType<
  typeof useDeleteDocumentTableMutation
>
export type DeleteDocumentTableMutationResult = Apollo.MutationResult<DeleteDocumentTableMutation>
export type DeleteDocumentTableMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentTableMutation,
  DeleteDocumentTableMutationVariables
>
export const DeleteDocumentTablesInGroupDocument = gql`
  mutation deleteDocumentTablesInGroup($documentTableId: String!) {
    deleteDocumentTablesInGroup(documentTableId: $documentTableId) {
      ok
    }
  }
`
export type DeleteDocumentTablesInGroupMutationFn = Apollo.MutationFunction<
  DeleteDocumentTablesInGroupMutation,
  DeleteDocumentTablesInGroupMutationVariables
>

/**
 * __useDeleteDocumentTablesInGroupMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentTablesInGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentTablesInGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentTablesInGroupMutation, { data, loading, error }] = useDeleteDocumentTablesInGroupMutation({
 *   variables: {
 *      documentTableId: // value for 'documentTableId'
 *   },
 * });
 */
export function useDeleteDocumentTablesInGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDocumentTablesInGroupMutation,
    DeleteDocumentTablesInGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteDocumentTablesInGroupMutation,
    DeleteDocumentTablesInGroupMutationVariables
  >(DeleteDocumentTablesInGroupDocument, options)
}
export type DeleteDocumentTablesInGroupMutationHookResult = ReturnType<
  typeof useDeleteDocumentTablesInGroupMutation
>
export type DeleteDocumentTablesInGroupMutationResult =
  Apollo.MutationResult<DeleteDocumentTablesInGroupMutation>
export type DeleteDocumentTablesInGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentTablesInGroupMutation,
  DeleteDocumentTablesInGroupMutationVariables
>
export const UnmergeDocumentTableDocument = gql`
  mutation unmergeDocumentTable($documentTableId: String!) {
    unmergeDocumentTable(documentTableId: $documentTableId) {
      ok
    }
  }
`
export type UnmergeDocumentTableMutationFn = Apollo.MutationFunction<
  UnmergeDocumentTableMutation,
  UnmergeDocumentTableMutationVariables
>

/**
 * __useUnmergeDocumentTableMutation__
 *
 * To run a mutation, you first call `useUnmergeDocumentTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmergeDocumentTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmergeDocumentTableMutation, { data, loading, error }] = useUnmergeDocumentTableMutation({
 *   variables: {
 *      documentTableId: // value for 'documentTableId'
 *   },
 * });
 */
export function useUnmergeDocumentTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnmergeDocumentTableMutation,
    UnmergeDocumentTableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnmergeDocumentTableMutation, UnmergeDocumentTableMutationVariables>(
    UnmergeDocumentTableDocument,
    options,
  )
}
export type UnmergeDocumentTableMutationHookResult = ReturnType<
  typeof useUnmergeDocumentTableMutation
>
export type UnmergeDocumentTableMutationResult = Apollo.MutationResult<UnmergeDocumentTableMutation>
export type UnmergeDocumentTableMutationOptions = Apollo.BaseMutationOptions<
  UnmergeDocumentTableMutation,
  UnmergeDocumentTableMutationVariables
>
export const UpdateDocumentTablesOrderDocument = gql`
  mutation updateDocumentTablesOrder($documentTableIds: [String!]!) {
    updateDocumentTablesOrder(documentTableIds: $documentTableIds) {
      ok
    }
  }
`
export type UpdateDocumentTablesOrderMutationFn = Apollo.MutationFunction<
  UpdateDocumentTablesOrderMutation,
  UpdateDocumentTablesOrderMutationVariables
>

/**
 * __useUpdateDocumentTablesOrderMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentTablesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentTablesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentTablesOrderMutation, { data, loading, error }] = useUpdateDocumentTablesOrderMutation({
 *   variables: {
 *      documentTableIds: // value for 'documentTableIds'
 *   },
 * });
 */
export function useUpdateDocumentTablesOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDocumentTablesOrderMutation,
    UpdateDocumentTablesOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDocumentTablesOrderMutation,
    UpdateDocumentTablesOrderMutationVariables
  >(UpdateDocumentTablesOrderDocument, options)
}
export type UpdateDocumentTablesOrderMutationHookResult = ReturnType<
  typeof useUpdateDocumentTablesOrderMutation
>
export type UpdateDocumentTablesOrderMutationResult =
  Apollo.MutationResult<UpdateDocumentTablesOrderMutation>
export type UpdateDocumentTablesOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateDocumentTablesOrderMutation,
  UpdateDocumentTablesOrderMutationVariables
>
export const MergeTableDocument = gql`
  mutation MergeTable($tableIds: [String!]!) {
    mergeTable(tableIds: $tableIds) {
      id
    }
  }
`
export type MergeTableMutationFn = Apollo.MutationFunction<
  MergeTableMutation,
  MergeTableMutationVariables
>

/**
 * __useMergeTableMutation__
 *
 * To run a mutation, you first call `useMergeTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeTableMutation, { data, loading, error }] = useMergeTableMutation({
 *   variables: {
 *      tableIds: // value for 'tableIds'
 *   },
 * });
 */
export function useMergeTableMutation(
  baseOptions?: Apollo.MutationHookOptions<MergeTableMutation, MergeTableMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MergeTableMutation, MergeTableMutationVariables>(
    MergeTableDocument,
    options,
  )
}
export type MergeTableMutationHookResult = ReturnType<typeof useMergeTableMutation>
export type MergeTableMutationResult = Apollo.MutationResult<MergeTableMutation>
export type MergeTableMutationOptions = Apollo.BaseMutationOptions<
  MergeTableMutation,
  MergeTableMutationVariables
>
export const CopyTableFromOtherDocumentDocument = gql`
  mutation copyTableFromOtherDocument($fromDocId: UUID!, $toDocIds: [UUID!]!) {
    copyTableFromOtherDocument(fromDocId: $fromDocId, toDocIds: $toDocIds) {
      ok
    }
  }
`
export type CopyTableFromOtherDocumentMutationFn = Apollo.MutationFunction<
  CopyTableFromOtherDocumentMutation,
  CopyTableFromOtherDocumentMutationVariables
>

/**
 * __useCopyTableFromOtherDocumentMutation__
 *
 * To run a mutation, you first call `useCopyTableFromOtherDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTableFromOtherDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTableFromOtherDocumentMutation, { data, loading, error }] = useCopyTableFromOtherDocumentMutation({
 *   variables: {
 *      fromDocId: // value for 'fromDocId'
 *      toDocIds: // value for 'toDocIds'
 *   },
 * });
 */
export function useCopyTableFromOtherDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CopyTableFromOtherDocumentMutation,
    CopyTableFromOtherDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CopyTableFromOtherDocumentMutation,
    CopyTableFromOtherDocumentMutationVariables
  >(CopyTableFromOtherDocumentDocument, options)
}
export type CopyTableFromOtherDocumentMutationHookResult = ReturnType<
  typeof useCopyTableFromOtherDocumentMutation
>
export type CopyTableFromOtherDocumentMutationResult =
  Apollo.MutationResult<CopyTableFromOtherDocumentMutation>
export type CopyTableFromOtherDocumentMutationOptions = Apollo.BaseMutationOptions<
  CopyTableFromOtherDocumentMutation,
  CopyTableFromOtherDocumentMutationVariables
>
export const CopyTableToDocumentsDocument = gql`
  mutation CopyTableToDocuments($tables: [InputDocumentTableSkeleton!]!, $toDocIds: [UUID!]!) {
    copyTableToDocuments(tables: $tables, toDocIds: $toDocIds) {
      ok
    }
  }
`
export type CopyTableToDocumentsMutationFn = Apollo.MutationFunction<
  CopyTableToDocumentsMutation,
  CopyTableToDocumentsMutationVariables
>

/**
 * __useCopyTableToDocumentsMutation__
 *
 * To run a mutation, you first call `useCopyTableToDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTableToDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTableToDocumentsMutation, { data, loading, error }] = useCopyTableToDocumentsMutation({
 *   variables: {
 *      tables: // value for 'tables'
 *      toDocIds: // value for 'toDocIds'
 *   },
 * });
 */
export function useCopyTableToDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CopyTableToDocumentsMutation,
    CopyTableToDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CopyTableToDocumentsMutation, CopyTableToDocumentsMutationVariables>(
    CopyTableToDocumentsDocument,
    options,
  )
}
export type CopyTableToDocumentsMutationHookResult = ReturnType<
  typeof useCopyTableToDocumentsMutation
>
export type CopyTableToDocumentsMutationResult = Apollo.MutationResult<CopyTableToDocumentsMutation>
export type CopyTableToDocumentsMutationOptions = Apollo.BaseMutationOptions<
  CopyTableToDocumentsMutation,
  CopyTableToDocumentsMutationVariables
>
export const CopyRowsToDocumentsTemplateMatchDocument = gql`
  mutation CopyRowsToDocumentsTemplateMatch(
    $fromDocId: UUID!
    $tables: [InputDocumentTableSkeleton!]!
    $toDocIds: [UUID!]!
    $allDocIds: [UUID!]!
  ) {
    copyRowsToOtherDocuments(
      allDocIds: $allDocIds
      fromDocId: $fromDocId
      tables: $tables
      toDocIds: $toDocIds
    ) {
      ok
    }
  }
`
export type CopyRowsToDocumentsTemplateMatchMutationFn = Apollo.MutationFunction<
  CopyRowsToDocumentsTemplateMatchMutation,
  CopyRowsToDocumentsTemplateMatchMutationVariables
>

/**
 * __useCopyRowsToDocumentsTemplateMatchMutation__
 *
 * To run a mutation, you first call `useCopyRowsToDocumentsTemplateMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyRowsToDocumentsTemplateMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyRowsToDocumentsTemplateMatchMutation, { data, loading, error }] = useCopyRowsToDocumentsTemplateMatchMutation({
 *   variables: {
 *      fromDocId: // value for 'fromDocId'
 *      tables: // value for 'tables'
 *      toDocIds: // value for 'toDocIds'
 *      allDocIds: // value for 'allDocIds'
 *   },
 * });
 */
export function useCopyRowsToDocumentsTemplateMatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CopyRowsToDocumentsTemplateMatchMutation,
    CopyRowsToDocumentsTemplateMatchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CopyRowsToDocumentsTemplateMatchMutation,
    CopyRowsToDocumentsTemplateMatchMutationVariables
  >(CopyRowsToDocumentsTemplateMatchDocument, options)
}
export type CopyRowsToDocumentsTemplateMatchMutationHookResult = ReturnType<
  typeof useCopyRowsToDocumentsTemplateMatchMutation
>
export type CopyRowsToDocumentsTemplateMatchMutationResult =
  Apollo.MutationResult<CopyRowsToDocumentsTemplateMatchMutation>
export type CopyRowsToDocumentsTemplateMatchMutationOptions = Apollo.BaseMutationOptions<
  CopyRowsToDocumentsTemplateMatchMutation,
  CopyRowsToDocumentsTemplateMatchMutationVariables
>
export const DeleteFilePageDocument = gql`
  mutation deleteFilePage($jobId: String!, $filePageId: String!) {
    deleteFilePage(jobId: $jobId, filePageId: $filePageId) {
      ok
    }
  }
`
export type DeleteFilePageMutationFn = Apollo.MutationFunction<
  DeleteFilePageMutation,
  DeleteFilePageMutationVariables
>

/**
 * __useDeleteFilePageMutation__
 *
 * To run a mutation, you first call `useDeleteFilePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFilePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFilePageMutation, { data, loading, error }] = useDeleteFilePageMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      filePageId: // value for 'filePageId'
 *   },
 * });
 */
export function useDeleteFilePageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFilePageMutation, DeleteFilePageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteFilePageMutation, DeleteFilePageMutationVariables>(
    DeleteFilePageDocument,
    options,
  )
}
export type DeleteFilePageMutationHookResult = ReturnType<typeof useDeleteFilePageMutation>
export type DeleteFilePageMutationResult = Apollo.MutationResult<DeleteFilePageMutation>
export type DeleteFilePageMutationOptions = Apollo.BaseMutationOptions<
  DeleteFilePageMutation,
  DeleteFilePageMutationVariables
>
export const DeleteFilePagesDocument = gql`
  mutation deleteFilePages($filePageIds: [String!]!) {
    deleteFilePages(filePageIds: $filePageIds) {
      numUpdated
    }
  }
`
export type DeleteFilePagesMutationFn = Apollo.MutationFunction<
  DeleteFilePagesMutation,
  DeleteFilePagesMutationVariables
>

/**
 * __useDeleteFilePagesMutation__
 *
 * To run a mutation, you first call `useDeleteFilePagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFilePagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFilePagesMutation, { data, loading, error }] = useDeleteFilePagesMutation({
 *   variables: {
 *      filePageIds: // value for 'filePageIds'
 *   },
 * });
 */
export function useDeleteFilePagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFilePagesMutation,
    DeleteFilePagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteFilePagesMutation, DeleteFilePagesMutationVariables>(
    DeleteFilePagesDocument,
    options,
  )
}
export type DeleteFilePagesMutationHookResult = ReturnType<typeof useDeleteFilePagesMutation>
export type DeleteFilePagesMutationResult = Apollo.MutationResult<DeleteFilePagesMutation>
export type DeleteFilePagesMutationOptions = Apollo.BaseMutationOptions<
  DeleteFilePagesMutation,
  DeleteFilePagesMutationVariables
>
export const RotateFilePageDocument = gql`
  mutation rotateFilePage($angle: Float!, $filePageId: String!) {
    rotateFilePage(angle: $angle, filePageId: $filePageId) {
      filePage {
        id
        imageUrl
        dateModified
        googleOcrData
      }
    }
  }
`
export type RotateFilePageMutationFn = Apollo.MutationFunction<
  RotateFilePageMutation,
  RotateFilePageMutationVariables
>

/**
 * __useRotateFilePageMutation__
 *
 * To run a mutation, you first call `useRotateFilePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRotateFilePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rotateFilePageMutation, { data, loading, error }] = useRotateFilePageMutation({
 *   variables: {
 *      angle: // value for 'angle'
 *      filePageId: // value for 'filePageId'
 *   },
 * });
 */
export function useRotateFilePageMutation(
  baseOptions?: Apollo.MutationHookOptions<RotateFilePageMutation, RotateFilePageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RotateFilePageMutation, RotateFilePageMutationVariables>(
    RotateFilePageDocument,
    options,
  )
}
export type RotateFilePageMutationHookResult = ReturnType<typeof useRotateFilePageMutation>
export type RotateFilePageMutationResult = Apollo.MutationResult<RotateFilePageMutation>
export type RotateFilePageMutationOptions = Apollo.BaseMutationOptions<
  RotateFilePageMutation,
  RotateFilePageMutationVariables
>
export const IngestFilesDocument = gql`
  mutation ingestFiles(
    $emailSubject: String
    $message: String
    $companyId: UUID!
    $files: [GrapheneInputFile!]!
    $batchId: UUID!
  ) {
    ingestFiles(
      emailSubject: $emailSubject
      message: $message
      companyId: $companyId
      files: $files
      batchId: $batchId
    ) {
      ok
    }
  }
`
export type IngestFilesMutationFn = Apollo.MutationFunction<
  IngestFilesMutation,
  IngestFilesMutationVariables
>

/**
 * __useIngestFilesMutation__
 *
 * To run a mutation, you first call `useIngestFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIngestFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ingestFilesMutation, { data, loading, error }] = useIngestFilesMutation({
 *   variables: {
 *      emailSubject: // value for 'emailSubject'
 *      message: // value for 'message'
 *      companyId: // value for 'companyId'
 *      files: // value for 'files'
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useIngestFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<IngestFilesMutation, IngestFilesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IngestFilesMutation, IngestFilesMutationVariables>(
    IngestFilesDocument,
    options,
  )
}
export type IngestFilesMutationHookResult = ReturnType<typeof useIngestFilesMutation>
export type IngestFilesMutationResult = Apollo.MutationResult<IngestFilesMutation>
export type IngestFilesMutationOptions = Apollo.BaseMutationOptions<
  IngestFilesMutation,
  IngestFilesMutationVariables
>
export const IngestAutofillFilesDocument = gql`
  mutation ingestAutofillFiles($jobId: UUID!, $files: [GrapheneInputFile!]!, $batchId: UUID!) {
    ingestAutofillFiles(jobId: $jobId, files: $files, batchId: $batchId) {
      ok
    }
  }
`
export type IngestAutofillFilesMutationFn = Apollo.MutationFunction<
  IngestAutofillFilesMutation,
  IngestAutofillFilesMutationVariables
>

/**
 * __useIngestAutofillFilesMutation__
 *
 * To run a mutation, you first call `useIngestAutofillFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIngestAutofillFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ingestAutofillFilesMutation, { data, loading, error }] = useIngestAutofillFilesMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      files: // value for 'files'
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useIngestAutofillFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IngestAutofillFilesMutation,
    IngestAutofillFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IngestAutofillFilesMutation, IngestAutofillFilesMutationVariables>(
    IngestAutofillFilesDocument,
    options,
  )
}
export type IngestAutofillFilesMutationHookResult = ReturnType<
  typeof useIngestAutofillFilesMutation
>
export type IngestAutofillFilesMutationResult = Apollo.MutationResult<IngestAutofillFilesMutation>
export type IngestAutofillFilesMutationOptions = Apollo.BaseMutationOptions<
  IngestAutofillFilesMutation,
  IngestAutofillFilesMutationVariables
>
export const TiffToPngDocument = gql`
  mutation tiffToPng($imageBase64Data: String!) {
    tiffToPng(imageBase64Data: $imageBase64Data) {
      images
      numPages
      sizes
    }
  }
`
export type TiffToPngMutationFn = Apollo.MutationFunction<
  TiffToPngMutation,
  TiffToPngMutationVariables
>

/**
 * __useTiffToPngMutation__
 *
 * To run a mutation, you first call `useTiffToPngMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTiffToPngMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tiffToPngMutation, { data, loading, error }] = useTiffToPngMutation({
 *   variables: {
 *      imageBase64Data: // value for 'imageBase64Data'
 *   },
 * });
 */
export function useTiffToPngMutation(
  baseOptions?: Apollo.MutationHookOptions<TiffToPngMutation, TiffToPngMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TiffToPngMutation, TiffToPngMutationVariables>(
    TiffToPngDocument,
    options,
  )
}
export type TiffToPngMutationHookResult = ReturnType<typeof useTiffToPngMutation>
export type TiffToPngMutationResult = Apollo.MutationResult<TiffToPngMutation>
export type TiffToPngMutationOptions = Apollo.BaseMutationOptions<
  TiffToPngMutation,
  TiffToPngMutationVariables
>
export const UploadOriginalPdfsToS3Document = gql`
  mutation uploadOriginalPdfsToS3($jobId: UUID!, $files: [OriginalInputPDF!]!) {
    uploadOriginalPdfsToS3(jobId: $jobId, files: $files) {
      files {
        filename
        s3Uri
      }
    }
  }
`
export type UploadOriginalPdfsToS3MutationFn = Apollo.MutationFunction<
  UploadOriginalPdfsToS3Mutation,
  UploadOriginalPdfsToS3MutationVariables
>

/**
 * __useUploadOriginalPdfsToS3Mutation__
 *
 * To run a mutation, you first call `useUploadOriginalPdfsToS3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOriginalPdfsToS3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOriginalPdfsToS3Mutation, { data, loading, error }] = useUploadOriginalPdfsToS3Mutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadOriginalPdfsToS3Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadOriginalPdfsToS3Mutation,
    UploadOriginalPdfsToS3MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UploadOriginalPdfsToS3Mutation,
    UploadOriginalPdfsToS3MutationVariables
  >(UploadOriginalPdfsToS3Document, options)
}
export type UploadOriginalPdfsToS3MutationHookResult = ReturnType<
  typeof useUploadOriginalPdfsToS3Mutation
>
export type UploadOriginalPdfsToS3MutationResult =
  Apollo.MutationResult<UploadOriginalPdfsToS3Mutation>
export type UploadOriginalPdfsToS3MutationOptions = Apollo.BaseMutationOptions<
  UploadOriginalPdfsToS3Mutation,
  UploadOriginalPdfsToS3MutationVariables
>
export const CreateJobDocument = gql`
  mutation createJob(
    $jobTemplateId: UUID!
    $jobName: String!
    $filePageIds: [UUID!]!
    $docTypeIds: [UUID!]!
    $ownerId: UUID
    $qaId: UUID
    $dateReceived: String
    $batchId: UUID!
    $taskId: UUID!
  ) {
    createJob(
      jobTemplateId: $jobTemplateId
      jobName: $jobName
      filePageIds: $filePageIds
      docTypeIds: $docTypeIds
      ownerId: $ownerId
      qaId: $qaId
      dateReceived: $dateReceived
      batchId: $batchId
      taskId: $taskId
    ) {
      job {
        id
      }
    }
  }
`
export type CreateJobMutationFn = Apollo.MutationFunction<
  CreateJobMutation,
  CreateJobMutationVariables
>

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      jobTemplateId: // value for 'jobTemplateId'
 *      jobName: // value for 'jobName'
 *      filePageIds: // value for 'filePageIds'
 *      docTypeIds: // value for 'docTypeIds'
 *      ownerId: // value for 'ownerId'
 *      qaId: // value for 'qaId'
 *      dateReceived: // value for 'dateReceived'
 *      batchId: // value for 'batchId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCreateJobMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateJobMutation, CreateJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateJobMutation, CreateJobMutationVariables>(
    CreateJobDocument,
    options,
  )
}
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>
export type CreateJobMutationResult = Apollo.MutationResult<CreateJobMutation>
export type CreateJobMutationOptions = Apollo.BaseMutationOptions<
  CreateJobMutation,
  CreateJobMutationVariables
>
export const CreateEmptyJobDocument = gql`
  mutation createEmptyJob(
    $jobTemplateId: String!
    $jobName: String!
    $clientReferenceNo: String!
    $ownerId: String
    $qaId: String
    $manualProduct: String!
    $dateReceived: String
    $message: String!
    $taskId: String
  ) {
    createEmptyJob(
      jobTemplateId: $jobTemplateId
      jobName: $jobName
      clientReferenceNo: $clientReferenceNo
      ownerId: $ownerId
      qaId: $qaId
      manualProduct: $manualProduct
      dateReceived: $dateReceived
      message: $message
      taskId: $taskId
    ) {
      job {
        id
      }
    }
  }
`
export type CreateEmptyJobMutationFn = Apollo.MutationFunction<
  CreateEmptyJobMutation,
  CreateEmptyJobMutationVariables
>

/**
 * __useCreateEmptyJobMutation__
 *
 * To run a mutation, you first call `useCreateEmptyJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmptyJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmptyJobMutation, { data, loading, error }] = useCreateEmptyJobMutation({
 *   variables: {
 *      jobTemplateId: // value for 'jobTemplateId'
 *      jobName: // value for 'jobName'
 *      clientReferenceNo: // value for 'clientReferenceNo'
 *      ownerId: // value for 'ownerId'
 *      qaId: // value for 'qaId'
 *      manualProduct: // value for 'manualProduct'
 *      dateReceived: // value for 'dateReceived'
 *      message: // value for 'message'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCreateEmptyJobMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEmptyJobMutation, CreateEmptyJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateEmptyJobMutation, CreateEmptyJobMutationVariables>(
    CreateEmptyJobDocument,
    options,
  )
}
export type CreateEmptyJobMutationHookResult = ReturnType<typeof useCreateEmptyJobMutation>
export type CreateEmptyJobMutationResult = Apollo.MutationResult<CreateEmptyJobMutation>
export type CreateEmptyJobMutationOptions = Apollo.BaseMutationOptions<
  CreateEmptyJobMutation,
  CreateEmptyJobMutationVariables
>
export const EditJobDocument = gql`
  mutation editJob(
    $jobId: String!
    $jobTemplateId: String
    $name: String
    $clientReferenceNo: String
    $ownerId: String
    $qaId: UUID
    $manualProduct: String
    $dateReceived: String
    $externalStatus: JobExternalStatus
    $externalAssigneeId: UUID
  ) {
    editJob(
      jobId: $jobId
      jobTemplateId: $jobTemplateId
      name: $name
      clientReferenceNo: $clientReferenceNo
      ownerId: $ownerId
      qaId: $qaId
      manualProduct: $manualProduct
      dateReceived: $dateReceived
      externalStatus: $externalStatus
      externalAssigneeId: $externalAssigneeId
    ) {
      job {
        id
      }
    }
  }
`
export type EditJobMutationFn = Apollo.MutationFunction<EditJobMutation, EditJobMutationVariables>

/**
 * __useEditJobMutation__
 *
 * To run a mutation, you first call `useEditJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editJobMutation, { data, loading, error }] = useEditJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      jobTemplateId: // value for 'jobTemplateId'
 *      name: // value for 'name'
 *      clientReferenceNo: // value for 'clientReferenceNo'
 *      ownerId: // value for 'ownerId'
 *      qaId: // value for 'qaId'
 *      manualProduct: // value for 'manualProduct'
 *      dateReceived: // value for 'dateReceived'
 *      externalStatus: // value for 'externalStatus'
 *      externalAssigneeId: // value for 'externalAssigneeId'
 *   },
 * });
 */
export function useEditJobMutation(
  baseOptions?: Apollo.MutationHookOptions<EditJobMutation, EditJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditJobMutation, EditJobMutationVariables>(EditJobDocument, options)
}
export type EditJobMutationHookResult = ReturnType<typeof useEditJobMutation>
export type EditJobMutationResult = Apollo.MutationResult<EditJobMutation>
export type EditJobMutationOptions = Apollo.BaseMutationOptions<
  EditJobMutation,
  EditJobMutationVariables
>
export const AssociatePagesToJobDocument = gql`
  mutation associatePagesToJob(
    $jobId: UUID!
    $filePageIds: [UUID!]!
    $docTypeIds: [UUID!]!
    $batchId: UUID!
  ) {
    associatePagesToJob(
      jobId: $jobId
      filePageIds: $filePageIds
      docTypeIds: $docTypeIds
      batchId: $batchId
    ) {
      job {
        id
      }
    }
  }
`
export type AssociatePagesToJobMutationFn = Apollo.MutationFunction<
  AssociatePagesToJobMutation,
  AssociatePagesToJobMutationVariables
>

/**
 * __useAssociatePagesToJobMutation__
 *
 * To run a mutation, you first call `useAssociatePagesToJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociatePagesToJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associatePagesToJobMutation, { data, loading, error }] = useAssociatePagesToJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      filePageIds: // value for 'filePageIds'
 *      docTypeIds: // value for 'docTypeIds'
 *      batchId: // value for 'batchId'
 *   },
 * });
 */
export function useAssociatePagesToJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociatePagesToJobMutation,
    AssociatePagesToJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AssociatePagesToJobMutation, AssociatePagesToJobMutationVariables>(
    AssociatePagesToJobDocument,
    options,
  )
}
export type AssociatePagesToJobMutationHookResult = ReturnType<
  typeof useAssociatePagesToJobMutation
>
export type AssociatePagesToJobMutationResult = Apollo.MutationResult<AssociatePagesToJobMutation>
export type AssociatePagesToJobMutationOptions = Apollo.BaseMutationOptions<
  AssociatePagesToJobMutation,
  AssociatePagesToJobMutationVariables
>
export const DeleteJobDocument = gql`
  mutation deleteJob($jobId: String!) {
    deleteJob(jobId: $jobId) {
      ok
    }
  }
`
export type DeleteJobMutationFn = Apollo.MutationFunction<
  DeleteJobMutation,
  DeleteJobMutationVariables
>

/**
 * __useDeleteJobMutation__
 *
 * To run a mutation, you first call `useDeleteJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobMutation, { data, loading, error }] = useDeleteJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useDeleteJobMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteJobMutation, DeleteJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteJobMutation, DeleteJobMutationVariables>(
    DeleteJobDocument,
    options,
  )
}
export type DeleteJobMutationHookResult = ReturnType<typeof useDeleteJobMutation>
export type DeleteJobMutationResult = Apollo.MutationResult<DeleteJobMutation>
export type DeleteJobMutationOptions = Apollo.BaseMutationOptions<
  DeleteJobMutation,
  DeleteJobMutationVariables
>
export const DeleteJobTemplateDocument = gql`
  mutation deleteJobTemplate($jobTemplateId: String!) {
    deleteJobTemplate(jobTemplateId: $jobTemplateId) {
      ok
    }
  }
`
export type DeleteJobTemplateMutationFn = Apollo.MutationFunction<
  DeleteJobTemplateMutation,
  DeleteJobTemplateMutationVariables
>

/**
 * __useDeleteJobTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteJobTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobTemplateMutation, { data, loading, error }] = useDeleteJobTemplateMutation({
 *   variables: {
 *      jobTemplateId: // value for 'jobTemplateId'
 *   },
 * });
 */
export function useDeleteJobTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteJobTemplateMutation,
    DeleteJobTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteJobTemplateMutation, DeleteJobTemplateMutationVariables>(
    DeleteJobTemplateDocument,
    options,
  )
}
export type DeleteJobTemplateMutationHookResult = ReturnType<typeof useDeleteJobTemplateMutation>
export type DeleteJobTemplateMutationResult = Apollo.MutationResult<DeleteJobTemplateMutation>
export type DeleteJobTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteJobTemplateMutation,
  DeleteJobTemplateMutationVariables
>
export const RestoreJobDocument = gql`
  mutation restoreJob($jobId: String!) {
    restoreJob(jobId: $jobId) {
      ok
    }
  }
`
export type RestoreJobMutationFn = Apollo.MutationFunction<
  RestoreJobMutation,
  RestoreJobMutationVariables
>

/**
 * __useRestoreJobMutation__
 *
 * To run a mutation, you first call `useRestoreJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreJobMutation, { data, loading, error }] = useRestoreJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useRestoreJobMutation(
  baseOptions?: Apollo.MutationHookOptions<RestoreJobMutation, RestoreJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RestoreJobMutation, RestoreJobMutationVariables>(
    RestoreJobDocument,
    options,
  )
}
export type RestoreJobMutationHookResult = ReturnType<typeof useRestoreJobMutation>
export type RestoreJobMutationResult = Apollo.MutationResult<RestoreJobMutation>
export type RestoreJobMutationOptions = Apollo.BaseMutationOptions<
  RestoreJobMutation,
  RestoreJobMutationVariables
>
export const CopyJobDocument = gql`
  mutation copyJob($jobId: String!) {
    copyJob(jobId: $jobId) {
      id
    }
  }
`
export type CopyJobMutationFn = Apollo.MutationFunction<CopyJobMutation, CopyJobMutationVariables>

/**
 * __useCopyJobMutation__
 *
 * To run a mutation, you first call `useCopyJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyJobMutation, { data, loading, error }] = useCopyJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useCopyJobMutation(
  baseOptions?: Apollo.MutationHookOptions<CopyJobMutation, CopyJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CopyJobMutation, CopyJobMutationVariables>(CopyJobDocument, options)
}
export type CopyJobMutationHookResult = ReturnType<typeof useCopyJobMutation>
export type CopyJobMutationResult = Apollo.MutationResult<CopyJobMutation>
export type CopyJobMutationOptions = Apollo.BaseMutationOptions<
  CopyJobMutation,
  CopyJobMutationVariables
>
export const SetJobOwnerDocument = gql`
  mutation setJobOwner($jobId: String!, $ownerId: String!) {
    setJobOwner(jobId: $jobId, ownerId: $ownerId) {
      ok
    }
  }
`
export type SetJobOwnerMutationFn = Apollo.MutationFunction<
  SetJobOwnerMutation,
  SetJobOwnerMutationVariables
>

/**
 * __useSetJobOwnerMutation__
 *
 * To run a mutation, you first call `useSetJobOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetJobOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setJobOwnerMutation, { data, loading, error }] = useSetJobOwnerMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useSetJobOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<SetJobOwnerMutation, SetJobOwnerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetJobOwnerMutation, SetJobOwnerMutationVariables>(
    SetJobOwnerDocument,
    options,
  )
}
export type SetJobOwnerMutationHookResult = ReturnType<typeof useSetJobOwnerMutation>
export type SetJobOwnerMutationResult = Apollo.MutationResult<SetJobOwnerMutation>
export type SetJobOwnerMutationOptions = Apollo.BaseMutationOptions<
  SetJobOwnerMutation,
  SetJobOwnerMutationVariables
>
export const SetJobQaDocument = gql`
  mutation setJobQa($jobId: String!, $qaId: String!) {
    setJobQa(jobId: $jobId, qaId: $qaId) {
      jobId
    }
  }
`
export type SetJobQaMutationFn = Apollo.MutationFunction<
  SetJobQaMutation,
  SetJobQaMutationVariables
>

/**
 * __useSetJobQaMutation__
 *
 * To run a mutation, you first call `useSetJobQaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetJobQaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setJobQaMutation, { data, loading, error }] = useSetJobQaMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      qaId: // value for 'qaId'
 *   },
 * });
 */
export function useSetJobQaMutation(
  baseOptions?: Apollo.MutationHookOptions<SetJobQaMutation, SetJobQaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetJobQaMutation, SetJobQaMutationVariables>(SetJobQaDocument, options)
}
export type SetJobQaMutationHookResult = ReturnType<typeof useSetJobQaMutation>
export type SetJobQaMutationResult = Apollo.MutationResult<SetJobQaMutation>
export type SetJobQaMutationOptions = Apollo.BaseMutationOptions<
  SetJobQaMutation,
  SetJobQaMutationVariables
>
export const SetJobManualProductDocument = gql`
  mutation setJobManualProduct($jobId: String!, $manualProduct: String!) {
    setJobManualProduct(jobId: $jobId, manualProduct: $manualProduct) {
      job {
        id
        manualProduct
      }
    }
  }
`
export type SetJobManualProductMutationFn = Apollo.MutationFunction<
  SetJobManualProductMutation,
  SetJobManualProductMutationVariables
>

/**
 * __useSetJobManualProductMutation__
 *
 * To run a mutation, you first call `useSetJobManualProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetJobManualProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setJobManualProductMutation, { data, loading, error }] = useSetJobManualProductMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      manualProduct: // value for 'manualProduct'
 *   },
 * });
 */
export function useSetJobManualProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetJobManualProductMutation,
    SetJobManualProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetJobManualProductMutation, SetJobManualProductMutationVariables>(
    SetJobManualProductDocument,
    options,
  )
}
export type SetJobManualProductMutationHookResult = ReturnType<
  typeof useSetJobManualProductMutation
>
export type SetJobManualProductMutationResult = Apollo.MutationResult<SetJobManualProductMutation>
export type SetJobManualProductMutationOptions = Apollo.BaseMutationOptions<
  SetJobManualProductMutation,
  SetJobManualProductMutationVariables
>
export const QaJobDocument = gql`
  mutation qaJob(
    $jobId: String!
    $fieldsMissingInformation: [String!]!
    $fieldsWrongInformation: [String!]!
    $fieldsTypos: [String!]!
    $otherInfo: String!
  ) {
    qaJob(
      jobId: $jobId
      fieldsMissingInformation: $fieldsMissingInformation
      fieldsWrongInformation: $fieldsWrongInformation
      fieldsTypos: $fieldsTypos
      otherInfo: $otherInfo
    ) {
      ok
    }
  }
`
export type QaJobMutationFn = Apollo.MutationFunction<QaJobMutation, QaJobMutationVariables>

/**
 * __useQaJobMutation__
 *
 * To run a mutation, you first call `useQaJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQaJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qaJobMutation, { data, loading, error }] = useQaJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      fieldsMissingInformation: // value for 'fieldsMissingInformation'
 *      fieldsWrongInformation: // value for 'fieldsWrongInformation'
 *      fieldsTypos: // value for 'fieldsTypos'
 *      otherInfo: // value for 'otherInfo'
 *   },
 * });
 */
export function useQaJobMutation(
  baseOptions?: Apollo.MutationHookOptions<QaJobMutation, QaJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<QaJobMutation, QaJobMutationVariables>(QaJobDocument, options)
}
export type QaJobMutationHookResult = ReturnType<typeof useQaJobMutation>
export type QaJobMutationResult = Apollo.MutationResult<QaJobMutation>
export type QaJobMutationOptions = Apollo.BaseMutationOptions<QaJobMutation, QaJobMutationVariables>
export const ConfirmJobsDocument = gql`
  mutation confirmJobs($jobIds: [UUID!]!, $proofUrl: String!, $filename: String!) {
    confirmJobs(jobIds: $jobIds, proofUrl: $proofUrl, filename: $filename) {
      ok
    }
  }
`
export type ConfirmJobsMutationFn = Apollo.MutationFunction<
  ConfirmJobsMutation,
  ConfirmJobsMutationVariables
>

/**
 * __useConfirmJobsMutation__
 *
 * To run a mutation, you first call `useConfirmJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmJobsMutation, { data, loading, error }] = useConfirmJobsMutation({
 *   variables: {
 *      jobIds: // value for 'jobIds'
 *      proofUrl: // value for 'proofUrl'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useConfirmJobsMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmJobsMutation, ConfirmJobsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ConfirmJobsMutation, ConfirmJobsMutationVariables>(
    ConfirmJobsDocument,
    options,
  )
}
export type ConfirmJobsMutationHookResult = ReturnType<typeof useConfirmJobsMutation>
export type ConfirmJobsMutationResult = Apollo.MutationResult<ConfirmJobsMutation>
export type ConfirmJobsMutationOptions = Apollo.BaseMutationOptions<
  ConfirmJobsMutation,
  ConfirmJobsMutationVariables
>
export const MoveJobToInProgressDocument = gql`
  mutation moveJobToInProgress($jobId: String!) {
    moveJobToInProgress(jobId: $jobId) {
      ok
    }
  }
`
export type MoveJobToInProgressMutationFn = Apollo.MutationFunction<
  MoveJobToInProgressMutation,
  MoveJobToInProgressMutationVariables
>

/**
 * __useMoveJobToInProgressMutation__
 *
 * To run a mutation, you first call `useMoveJobToInProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveJobToInProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveJobToInProgressMutation, { data, loading, error }] = useMoveJobToInProgressMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useMoveJobToInProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveJobToInProgressMutation,
    MoveJobToInProgressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveJobToInProgressMutation, MoveJobToInProgressMutationVariables>(
    MoveJobToInProgressDocument,
    options,
  )
}
export type MoveJobToInProgressMutationHookResult = ReturnType<
  typeof useMoveJobToInProgressMutation
>
export type MoveJobToInProgressMutationResult = Apollo.MutationResult<MoveJobToInProgressMutation>
export type MoveJobToInProgressMutationOptions = Apollo.BaseMutationOptions<
  MoveJobToInProgressMutation,
  MoveJobToInProgressMutationVariables
>
export const BulkUpdateJobStatusDocument = gql`
  mutation bulkUpdateJobStatus($jobIds: [String!]!, $status: String!) {
    bulkUpdateJobStatus(jobIds: $jobIds, status: $status) {
      ok
    }
  }
`
export type BulkUpdateJobStatusMutationFn = Apollo.MutationFunction<
  BulkUpdateJobStatusMutation,
  BulkUpdateJobStatusMutationVariables
>

/**
 * __useBulkUpdateJobStatusMutation__
 *
 * To run a mutation, you first call `useBulkUpdateJobStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateJobStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateJobStatusMutation, { data, loading, error }] = useBulkUpdateJobStatusMutation({
 *   variables: {
 *      jobIds: // value for 'jobIds'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useBulkUpdateJobStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateJobStatusMutation,
    BulkUpdateJobStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BulkUpdateJobStatusMutation, BulkUpdateJobStatusMutationVariables>(
    BulkUpdateJobStatusDocument,
    options,
  )
}
export type BulkUpdateJobStatusMutationHookResult = ReturnType<
  typeof useBulkUpdateJobStatusMutation
>
export type BulkUpdateJobStatusMutationResult = Apollo.MutationResult<BulkUpdateJobStatusMutation>
export type BulkUpdateJobStatusMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateJobStatusMutation,
  BulkUpdateJobStatusMutationVariables
>
export const BulkUpdateJobDetailsDocument = gql`
  mutation bulkUpdateJobDetails(
    $jobIds: [String!]!
    $ownerId: String
    $qaId: String
    $manualProduct: String
    $status: String
  ) {
    bulkUpdateJobDetails(
      jobIds: $jobIds
      ownerId: $ownerId
      qaId: $qaId
      manualProduct: $manualProduct
      status: $status
    ) {
      ok
    }
  }
`
export type BulkUpdateJobDetailsMutationFn = Apollo.MutationFunction<
  BulkUpdateJobDetailsMutation,
  BulkUpdateJobDetailsMutationVariables
>

/**
 * __useBulkUpdateJobDetailsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateJobDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateJobDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateJobDetailsMutation, { data, loading, error }] = useBulkUpdateJobDetailsMutation({
 *   variables: {
 *      jobIds: // value for 'jobIds'
 *      ownerId: // value for 'ownerId'
 *      qaId: // value for 'qaId'
 *      manualProduct: // value for 'manualProduct'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useBulkUpdateJobDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateJobDetailsMutation,
    BulkUpdateJobDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BulkUpdateJobDetailsMutation, BulkUpdateJobDetailsMutationVariables>(
    BulkUpdateJobDetailsDocument,
    options,
  )
}
export type BulkUpdateJobDetailsMutationHookResult = ReturnType<
  typeof useBulkUpdateJobDetailsMutation
>
export type BulkUpdateJobDetailsMutationResult = Apollo.MutationResult<BulkUpdateJobDetailsMutation>
export type BulkUpdateJobDetailsMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateJobDetailsMutation,
  BulkUpdateJobDetailsMutationVariables
>
export const BulkUpdateJobTaskIdDocument = gql`
  mutation bulkUpdateJobTaskId($jobIds: [String!]!, $taskId: String!) {
    bulkUpdateJobTaskId(jobIds: $jobIds, taskId: $taskId) {
      ok
    }
  }
`
export type BulkUpdateJobTaskIdMutationFn = Apollo.MutationFunction<
  BulkUpdateJobTaskIdMutation,
  BulkUpdateJobTaskIdMutationVariables
>

/**
 * __useBulkUpdateJobTaskIdMutation__
 *
 * To run a mutation, you first call `useBulkUpdateJobTaskIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateJobTaskIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateJobTaskIdMutation, { data, loading, error }] = useBulkUpdateJobTaskIdMutation({
 *   variables: {
 *      jobIds: // value for 'jobIds'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useBulkUpdateJobTaskIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateJobTaskIdMutation,
    BulkUpdateJobTaskIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BulkUpdateJobTaskIdMutation, BulkUpdateJobTaskIdMutationVariables>(
    BulkUpdateJobTaskIdDocument,
    options,
  )
}
export type BulkUpdateJobTaskIdMutationHookResult = ReturnType<
  typeof useBulkUpdateJobTaskIdMutation
>
export type BulkUpdateJobTaskIdMutationResult = Apollo.MutationResult<BulkUpdateJobTaskIdMutation>
export type BulkUpdateJobTaskIdMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateJobTaskIdMutation,
  BulkUpdateJobTaskIdMutationVariables
>
export const SaveJobTableDocument = gql`
  mutation saveJobTable($jobId: String!, $jobTable: InputJobTable!) {
    saveJobTable(jobId: $jobId, jobTable: $jobTable) {
      ok
    }
  }
`
export type SaveJobTableMutationFn = Apollo.MutationFunction<
  SaveJobTableMutation,
  SaveJobTableMutationVariables
>

/**
 * __useSaveJobTableMutation__
 *
 * To run a mutation, you first call `useSaveJobTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveJobTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveJobTableMutation, { data, loading, error }] = useSaveJobTableMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      jobTable: // value for 'jobTable'
 *   },
 * });
 */
export function useSaveJobTableMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveJobTableMutation, SaveJobTableMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveJobTableMutation, SaveJobTableMutationVariables>(
    SaveJobTableDocument,
    options,
  )
}
export type SaveJobTableMutationHookResult = ReturnType<typeof useSaveJobTableMutation>
export type SaveJobTableMutationResult = Apollo.MutationResult<SaveJobTableMutation>
export type SaveJobTableMutationOptions = Apollo.BaseMutationOptions<
  SaveJobTableMutation,
  SaveJobTableMutationVariables
>
export const SaveJobAndDocumentTablesDocument = gql`
  mutation saveJobAndDocumentTables(
    $jobId: UUID!
    $documentTables: [InputDocumentTable!]!
    $jobTable: InputJobTable!
    $validateFields: Boolean!
  ) {
    saveJobAndDocumentTables(
      jobId: $jobId
      documentTables: $documentTables
      jobTable: $jobTable
      validateFields: $validateFields
    ) {
      ok
    }
  }
`
export type SaveJobAndDocumentTablesMutationFn = Apollo.MutationFunction<
  SaveJobAndDocumentTablesMutation,
  SaveJobAndDocumentTablesMutationVariables
>

/**
 * __useSaveJobAndDocumentTablesMutation__
 *
 * To run a mutation, you first call `useSaveJobAndDocumentTablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveJobAndDocumentTablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveJobAndDocumentTablesMutation, { data, loading, error }] = useSaveJobAndDocumentTablesMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      documentTables: // value for 'documentTables'
 *      jobTable: // value for 'jobTable'
 *      validateFields: // value for 'validateFields'
 *   },
 * });
 */
export function useSaveJobAndDocumentTablesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveJobAndDocumentTablesMutation,
    SaveJobAndDocumentTablesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveJobAndDocumentTablesMutation,
    SaveJobAndDocumentTablesMutationVariables
  >(SaveJobAndDocumentTablesDocument, options)
}
export type SaveJobAndDocumentTablesMutationHookResult = ReturnType<
  typeof useSaveJobAndDocumentTablesMutation
>
export type SaveJobAndDocumentTablesMutationResult =
  Apollo.MutationResult<SaveJobAndDocumentTablesMutation>
export type SaveJobAndDocumentTablesMutationOptions = Apollo.BaseMutationOptions<
  SaveJobAndDocumentTablesMutation,
  SaveJobAndDocumentTablesMutationVariables
>
export const ExportJobToApiPartnerDocument = gql`
  mutation exportJobToApiPartner(
    $jobId: UUID!
    $exportType: ApiExportType!
    $lumpsum: InputDocumentTable
    $overrideChargeDescription: Boolean
  ) {
    exportJobToApiPartner(
      jobId: $jobId
      exportType: $exportType
      lumpsum: $lumpsum
      overrideChargeDescription: $overrideChargeDescription
    ) {
      ok
      responseText
    }
  }
`
export type ExportJobToApiPartnerMutationFn = Apollo.MutationFunction<
  ExportJobToApiPartnerMutation,
  ExportJobToApiPartnerMutationVariables
>

/**
 * __useExportJobToApiPartnerMutation__
 *
 * To run a mutation, you first call `useExportJobToApiPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportJobToApiPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportJobToApiPartnerMutation, { data, loading, error }] = useExportJobToApiPartnerMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      exportType: // value for 'exportType'
 *      lumpsum: // value for 'lumpsum'
 *      overrideChargeDescription: // value for 'overrideChargeDescription'
 *   },
 * });
 */
export function useExportJobToApiPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportJobToApiPartnerMutation,
    ExportJobToApiPartnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ExportJobToApiPartnerMutation, ExportJobToApiPartnerMutationVariables>(
    ExportJobToApiPartnerDocument,
    options,
  )
}
export type ExportJobToApiPartnerMutationHookResult = ReturnType<
  typeof useExportJobToApiPartnerMutation
>
export type ExportJobToApiPartnerMutationResult =
  Apollo.MutationResult<ExportJobToApiPartnerMutation>
export type ExportJobToApiPartnerMutationOptions = Apollo.BaseMutationOptions<
  ExportJobToApiPartnerMutation,
  ExportJobToApiPartnerMutationVariables
>
export const ExportEDocumentsToApiPartnerDocument = gql`
  mutation exportEDocumentsToApiPartner(
    $jobId: UUID!
    $inputEDocuments: [InputEDocument!]!
    $dataTargetType: EDocsDataTargetType!
  ) {
    exportEDocumentsToApiPartner(
      jobId: $jobId
      inputEDocuments: $inputEDocuments
      dataTargetType: $dataTargetType
    ) {
      ok
    }
  }
`
export type ExportEDocumentsToApiPartnerMutationFn = Apollo.MutationFunction<
  ExportEDocumentsToApiPartnerMutation,
  ExportEDocumentsToApiPartnerMutationVariables
>

/**
 * __useExportEDocumentsToApiPartnerMutation__
 *
 * To run a mutation, you first call `useExportEDocumentsToApiPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportEDocumentsToApiPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportEDocumentsToApiPartnerMutation, { data, loading, error }] = useExportEDocumentsToApiPartnerMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      inputEDocuments: // value for 'inputEDocuments'
 *      dataTargetType: // value for 'dataTargetType'
 *   },
 * });
 */
export function useExportEDocumentsToApiPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportEDocumentsToApiPartnerMutation,
    ExportEDocumentsToApiPartnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ExportEDocumentsToApiPartnerMutation,
    ExportEDocumentsToApiPartnerMutationVariables
  >(ExportEDocumentsToApiPartnerDocument, options)
}
export type ExportEDocumentsToApiPartnerMutationHookResult = ReturnType<
  typeof useExportEDocumentsToApiPartnerMutation
>
export type ExportEDocumentsToApiPartnerMutationResult =
  Apollo.MutationResult<ExportEDocumentsToApiPartnerMutation>
export type ExportEDocumentsToApiPartnerMutationOptions = Apollo.BaseMutationOptions<
  ExportEDocumentsToApiPartnerMutation,
  ExportEDocumentsToApiPartnerMutationVariables
>
export const UpsertJobTemplateDocument = gql`
  mutation upsertJobTemplate(
    $id: UUID!
    $name: String
    $companyId: UUID
    $description: String
    $slaTime: Int
    $reconType: JobTemplateReconType
    $shipmentOpsTypes: [ShipmentOp!]
    $documentTypes: [InputDocumentType!]
    $createIfMissing: Boolean
    $fieldGroups: [InputFieldGroup!]
    $credentialId: UUID
    $apiPartnerId: UUID
    $defaultExternalAssigneeId: UUID
    $autoReconEnabled: Boolean
    $subtotalsRowEnabled: Boolean
    $mainTabEnabled: Boolean
    $autoPostEnabled: Boolean
    $showPostButton: Boolean
    $requireShowReconToCustomer: Boolean
    $requireEdocsPushPull: Boolean
    $requireExternalAssignee: Boolean
    $inputReconThresholdRanges: [InputReconThresholdRange!]
  ) {
    upsertJobTemplate(
      id: $id
      name: $name
      companyId: $companyId
      description: $description
      slaTime: $slaTime
      reconType: $reconType
      shipmentOpsTypes: $shipmentOpsTypes
      documentTypes: $documentTypes
      createIfMissing: $createIfMissing
      fieldGroups: $fieldGroups
      credentialId: $credentialId
      apiPartnerId: $apiPartnerId
      defaultExternalAssigneeId: $defaultExternalAssigneeId
      autoReconEnabled: $autoReconEnabled
      subtotalsRowEnabled: $subtotalsRowEnabled
      mainTabEnabled: $mainTabEnabled
      autoPostEnabled: $autoPostEnabled
      showPostButton: $showPostButton
      requireShowReconToCustomer: $requireShowReconToCustomer
      requireEdocsPushPull: $requireEdocsPushPull
      requireExternalAssignee: $requireExternalAssignee
      inputReconThresholdRanges: $inputReconThresholdRanges
    ) {
      ok
    }
  }
`
export type UpsertJobTemplateMutationFn = Apollo.MutationFunction<
  UpsertJobTemplateMutation,
  UpsertJobTemplateMutationVariables
>

/**
 * __useUpsertJobTemplateMutation__
 *
 * To run a mutation, you first call `useUpsertJobTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertJobTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertJobTemplateMutation, { data, loading, error }] = useUpsertJobTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      companyId: // value for 'companyId'
 *      description: // value for 'description'
 *      slaTime: // value for 'slaTime'
 *      reconType: // value for 'reconType'
 *      shipmentOpsTypes: // value for 'shipmentOpsTypes'
 *      documentTypes: // value for 'documentTypes'
 *      createIfMissing: // value for 'createIfMissing'
 *      fieldGroups: // value for 'fieldGroups'
 *      credentialId: // value for 'credentialId'
 *      apiPartnerId: // value for 'apiPartnerId'
 *      defaultExternalAssigneeId: // value for 'defaultExternalAssigneeId'
 *      autoReconEnabled: // value for 'autoReconEnabled'
 *      subtotalsRowEnabled: // value for 'subtotalsRowEnabled'
 *      mainTabEnabled: // value for 'mainTabEnabled'
 *      autoPostEnabled: // value for 'autoPostEnabled'
 *      showPostButton: // value for 'showPostButton'
 *      requireShowReconToCustomer: // value for 'requireShowReconToCustomer'
 *      requireEdocsPushPull: // value for 'requireEdocsPushPull'
 *      requireExternalAssignee: // value for 'requireExternalAssignee'
 *      inputReconThresholdRanges: // value for 'inputReconThresholdRanges'
 *   },
 * });
 */
export function useUpsertJobTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertJobTemplateMutation,
    UpsertJobTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertJobTemplateMutation, UpsertJobTemplateMutationVariables>(
    UpsertJobTemplateDocument,
    options,
  )
}
export type UpsertJobTemplateMutationHookResult = ReturnType<typeof useUpsertJobTemplateMutation>
export type UpsertJobTemplateMutationResult = Apollo.MutationResult<UpsertJobTemplateMutation>
export type UpsertJobTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpsertJobTemplateMutation,
  UpsertJobTemplateMutationVariables
>
export const SaveJobTemplateExportFormatDocument = gql`
  mutation saveJobTemplateExportFormat(
    $jobTemplateId: UUID!
    $exportType: JobTemplateExportType!
    $jobTemplateExportSheets: [InputJobTemplateExportSheet!]!
  ) {
    saveJobTemplateExportFormat(
      jobTemplateId: $jobTemplateId
      exportType: $exportType
      jobTemplateExportSheets: $jobTemplateExportSheets
    ) {
      ok
    }
  }
`
export type SaveJobTemplateExportFormatMutationFn = Apollo.MutationFunction<
  SaveJobTemplateExportFormatMutation,
  SaveJobTemplateExportFormatMutationVariables
>

/**
 * __useSaveJobTemplateExportFormatMutation__
 *
 * To run a mutation, you first call `useSaveJobTemplateExportFormatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveJobTemplateExportFormatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveJobTemplateExportFormatMutation, { data, loading, error }] = useSaveJobTemplateExportFormatMutation({
 *   variables: {
 *      jobTemplateId: // value for 'jobTemplateId'
 *      exportType: // value for 'exportType'
 *      jobTemplateExportSheets: // value for 'jobTemplateExportSheets'
 *   },
 * });
 */
export function useSaveJobTemplateExportFormatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveJobTemplateExportFormatMutation,
    SaveJobTemplateExportFormatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveJobTemplateExportFormatMutation,
    SaveJobTemplateExportFormatMutationVariables
  >(SaveJobTemplateExportFormatDocument, options)
}
export type SaveJobTemplateExportFormatMutationHookResult = ReturnType<
  typeof useSaveJobTemplateExportFormatMutation
>
export type SaveJobTemplateExportFormatMutationResult =
  Apollo.MutationResult<SaveJobTemplateExportFormatMutation>
export type SaveJobTemplateExportFormatMutationOptions = Apollo.BaseMutationOptions<
  SaveJobTemplateExportFormatMutation,
  SaveJobTemplateExportFormatMutationVariables
>
export const CreateNoteDocument = gql`
  mutation createNote($jobId: String!, $content: String!, $noteType: NoteType!) {
    createNote(jobId: $jobId, content: $content, noteType: $noteType) {
      note {
        id
        text
        user {
          id
          email
        }
        dateCreated
        type
      }
    }
  }
`
export type CreateNoteMutationFn = Apollo.MutationFunction<
  CreateNoteMutation,
  CreateNoteMutationVariables
>

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      content: // value for 'content'
 *      noteType: // value for 'noteType'
 *   },
 * });
 */
export function useCreateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(
    CreateNoteDocument,
    options,
  )
}
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateNoteMutation,
  CreateNoteMutationVariables
>
export const CreateReconJobNoteDocument = gql`
  mutation createReconJobNote(
    $jobId: UUID!
    $invoiceNumber: String
    $reconAttemptId: UUID
    $content: String!
  ) {
    createReconJobNote(
      jobId: $jobId
      invoiceNumber: $invoiceNumber
      reconAttemptId: $reconAttemptId
      content: $content
      source: JMS
    )
  }
`
export type CreateReconJobNoteMutationFn = Apollo.MutationFunction<
  CreateReconJobNoteMutation,
  CreateReconJobNoteMutationVariables
>

/**
 * __useCreateReconJobNoteMutation__
 *
 * To run a mutation, you first call `useCreateReconJobNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReconJobNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReconJobNoteMutation, { data, loading, error }] = useCreateReconJobNoteMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      invoiceNumber: // value for 'invoiceNumber'
 *      reconAttemptId: // value for 'reconAttemptId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateReconJobNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReconJobNoteMutation,
    CreateReconJobNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateReconJobNoteMutation, CreateReconJobNoteMutationVariables>(
    CreateReconJobNoteDocument,
    options,
  )
}
export type CreateReconJobNoteMutationHookResult = ReturnType<typeof useCreateReconJobNoteMutation>
export type CreateReconJobNoteMutationResult = Apollo.MutationResult<CreateReconJobNoteMutation>
export type CreateReconJobNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateReconJobNoteMutation,
  CreateReconJobNoteMutationVariables
>
export const DeleteReconNoteDocument = gql`
  mutation deleteReconNote($reconNoteId: UUID!) {
    deleteReconNote(reconNoteId: $reconNoteId) {
      ok
    }
  }
`
export type DeleteReconNoteMutationFn = Apollo.MutationFunction<
  DeleteReconNoteMutation,
  DeleteReconNoteMutationVariables
>

/**
 * __useDeleteReconNoteMutation__
 *
 * To run a mutation, you first call `useDeleteReconNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReconNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReconNoteMutation, { data, loading, error }] = useDeleteReconNoteMutation({
 *   variables: {
 *      reconNoteId: // value for 'reconNoteId'
 *   },
 * });
 */
export function useDeleteReconNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReconNoteMutation,
    DeleteReconNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteReconNoteMutation, DeleteReconNoteMutationVariables>(
    DeleteReconNoteDocument,
    options,
  )
}
export type DeleteReconNoteMutationHookResult = ReturnType<typeof useDeleteReconNoteMutation>
export type DeleteReconNoteMutationResult = Apollo.MutationResult<DeleteReconNoteMutation>
export type DeleteReconNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteReconNoteMutation,
  DeleteReconNoteMutationVariables
>
export const MarkNotificationsReadDocument = gql`
  mutation markNotificationsRead {
    markNotificationsRead {
      ok
    }
  }
`
export type MarkNotificationsReadMutationFn = Apollo.MutationFunction<
  MarkNotificationsReadMutation,
  MarkNotificationsReadMutationVariables
>

/**
 * __useMarkNotificationsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsReadMutation, { data, loading, error }] = useMarkNotificationsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkNotificationsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationsReadMutation,
    MarkNotificationsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>(
    MarkNotificationsReadDocument,
    options,
  )
}
export type MarkNotificationsReadMutationHookResult = ReturnType<
  typeof useMarkNotificationsReadMutation
>
export type MarkNotificationsReadMutationResult =
  Apollo.MutationResult<MarkNotificationsReadMutation>
export type MarkNotificationsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationsReadMutation,
  MarkNotificationsReadMutationVariables
>
export const EditUserProfileDocument = gql`
  mutation editUserProfile($name: String!, $metabaseDashboardId: String!) {
    editOwnProfile(name: $name, metabaseDashboardId: $metabaseDashboardId) {
      ok
    }
  }
`
export type EditUserProfileMutationFn = Apollo.MutationFunction<
  EditUserProfileMutation,
  EditUserProfileMutationVariables
>

/**
 * __useEditUserProfileMutation__
 *
 * To run a mutation, you first call `useEditUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserProfileMutation, { data, loading, error }] = useEditUserProfileMutation({
 *   variables: {
 *      name: // value for 'name'
 *      metabaseDashboardId: // value for 'metabaseDashboardId'
 *   },
 * });
 */
export function useEditUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditUserProfileMutation,
    EditUserProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditUserProfileMutation, EditUserProfileMutationVariables>(
    EditUserProfileDocument,
    options,
  )
}
export type EditUserProfileMutationHookResult = ReturnType<typeof useEditUserProfileMutation>
export type EditUserProfileMutationResult = Apollo.MutationResult<EditUserProfileMutation>
export type EditUserProfileMutationOptions = Apollo.BaseMutationOptions<
  EditUserProfileMutation,
  EditUserProfileMutationVariables
>
export const CreateUserDocument = gql`
  mutation createUser($inputUser: InputUser!) {
    createUser(inputUser: $inputUser)
  }
`
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      inputUser: // value for 'inputUser'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  )
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser($inputUser: InputUser!, $auth0Id: String!) {
    updateUser(inputUser: $inputUser, auth0Id: $auth0Id)
  }
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      inputUser: // value for 'inputUser'
 *      auth0Id: // value for 'auth0Id'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  )
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const DeleteUserDocument = gql`
  mutation deleteUser($auth0Id: String!) {
    deleteUser(auth0Id: $auth0Id)
  }
`
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      auth0Id: // value for 'auth0Id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  )
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>
export const ResetUserPasswordDocument = gql`
  mutation resetUserPassword($auth0Id: String!) {
    resetUserPassword(auth0Id: $auth0Id)
  }
`
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      auth0Id: // value for 'auth0Id'
 *   },
 * });
 */
export function useResetUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(
    ResetUserPasswordDocument,
    options,
  )
}
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>
export const UpdateDashboardCompanyUserIamDocument = gql`
  mutation updateDashboardCompanyUserIam($inputUser: InputUser!, $companyId: UUID)
  @api(name: beholder) {
    updateDashboardCompanyUserIam(inputUser: $inputUser, companyId: $companyId)
  }
`
export type UpdateDashboardCompanyUserIamMutationFn = Apollo.MutationFunction<
  UpdateDashboardCompanyUserIamMutation,
  UpdateDashboardCompanyUserIamMutationVariables
>

/**
 * __useUpdateDashboardCompanyUserIamMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardCompanyUserIamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardCompanyUserIamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardCompanyUserIamMutation, { data, loading, error }] = useUpdateDashboardCompanyUserIamMutation({
 *   variables: {
 *      inputUser: // value for 'inputUser'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUpdateDashboardCompanyUserIamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDashboardCompanyUserIamMutation,
    UpdateDashboardCompanyUserIamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDashboardCompanyUserIamMutation,
    UpdateDashboardCompanyUserIamMutationVariables
  >(UpdateDashboardCompanyUserIamDocument, options)
}
export type UpdateDashboardCompanyUserIamMutationHookResult = ReturnType<
  typeof useUpdateDashboardCompanyUserIamMutation
>
export type UpdateDashboardCompanyUserIamMutationResult =
  Apollo.MutationResult<UpdateDashboardCompanyUserIamMutation>
export type UpdateDashboardCompanyUserIamMutationOptions = Apollo.BaseMutationOptions<
  UpdateDashboardCompanyUserIamMutation,
  UpdateDashboardCompanyUserIamMutationVariables
>
export const BulkUpdateUserDashboardWhitelistDocument = gql`
  mutation bulkUpdateUserDashboardWhitelist(
    $defaultDashboardWhitelist: [String!]!
    $userEmails: [String!]!
  ) @api(name: beholder) {
    bulkUpdateUserDashboardWhitelist(
      defaultDashboardWhitelist: $defaultDashboardWhitelist
      userEmails: $userEmails
    )
  }
`
export type BulkUpdateUserDashboardWhitelistMutationFn = Apollo.MutationFunction<
  BulkUpdateUserDashboardWhitelistMutation,
  BulkUpdateUserDashboardWhitelistMutationVariables
>

/**
 * __useBulkUpdateUserDashboardWhitelistMutation__
 *
 * To run a mutation, you first call `useBulkUpdateUserDashboardWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateUserDashboardWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateUserDashboardWhitelistMutation, { data, loading, error }] = useBulkUpdateUserDashboardWhitelistMutation({
 *   variables: {
 *      defaultDashboardWhitelist: // value for 'defaultDashboardWhitelist'
 *      userEmails: // value for 'userEmails'
 *   },
 * });
 */
export function useBulkUpdateUserDashboardWhitelistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateUserDashboardWhitelistMutation,
    BulkUpdateUserDashboardWhitelistMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BulkUpdateUserDashboardWhitelistMutation,
    BulkUpdateUserDashboardWhitelistMutationVariables
  >(BulkUpdateUserDashboardWhitelistDocument, options)
}
export type BulkUpdateUserDashboardWhitelistMutationHookResult = ReturnType<
  typeof useBulkUpdateUserDashboardWhitelistMutation
>
export type BulkUpdateUserDashboardWhitelistMutationResult =
  Apollo.MutationResult<BulkUpdateUserDashboardWhitelistMutation>
export type BulkUpdateUserDashboardWhitelistMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateUserDashboardWhitelistMutation,
  BulkUpdateUserDashboardWhitelistMutationVariables
>
export const RedoReconcileUnmatchedReconAttemptsDocument = gql`
  mutation redoReconcileUnmatchedReconAttempts($companyIds: [UUID!]) {
    redoReconcileUnmatchedReconAttempts(companyIds: $companyIds)
  }
`
export type RedoReconcileUnmatchedReconAttemptsMutationFn = Apollo.MutationFunction<
  RedoReconcileUnmatchedReconAttemptsMutation,
  RedoReconcileUnmatchedReconAttemptsMutationVariables
>

/**
 * __useRedoReconcileUnmatchedReconAttemptsMutation__
 *
 * To run a mutation, you first call `useRedoReconcileUnmatchedReconAttemptsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedoReconcileUnmatchedReconAttemptsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redoReconcileUnmatchedReconAttemptsMutation, { data, loading, error }] = useRedoReconcileUnmatchedReconAttemptsMutation({
 *   variables: {
 *      companyIds: // value for 'companyIds'
 *   },
 * });
 */
export function useRedoReconcileUnmatchedReconAttemptsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RedoReconcileUnmatchedReconAttemptsMutation,
    RedoReconcileUnmatchedReconAttemptsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RedoReconcileUnmatchedReconAttemptsMutation,
    RedoReconcileUnmatchedReconAttemptsMutationVariables
  >(RedoReconcileUnmatchedReconAttemptsDocument, options)
}
export type RedoReconcileUnmatchedReconAttemptsMutationHookResult = ReturnType<
  typeof useRedoReconcileUnmatchedReconAttemptsMutation
>
export type RedoReconcileUnmatchedReconAttemptsMutationResult =
  Apollo.MutationResult<RedoReconcileUnmatchedReconAttemptsMutation>
export type RedoReconcileUnmatchedReconAttemptsMutationOptions = Apollo.BaseMutationOptions<
  RedoReconcileUnmatchedReconAttemptsMutation,
  RedoReconcileUnmatchedReconAttemptsMutationVariables
>
export const SaveReconAttemptDocument = gql`
  mutation saveReconAttempt($reconAttemptId: String!) {
    saveReconAttempt(reconAttemptId: $reconAttemptId) {
      ok
    }
  }
`
export type SaveReconAttemptMutationFn = Apollo.MutationFunction<
  SaveReconAttemptMutation,
  SaveReconAttemptMutationVariables
>

/**
 * __useSaveReconAttemptMutation__
 *
 * To run a mutation, you first call `useSaveReconAttemptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveReconAttemptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveReconAttemptMutation, { data, loading, error }] = useSaveReconAttemptMutation({
 *   variables: {
 *      reconAttemptId: // value for 'reconAttemptId'
 *   },
 * });
 */
export function useSaveReconAttemptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveReconAttemptMutation,
    SaveReconAttemptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveReconAttemptMutation, SaveReconAttemptMutationVariables>(
    SaveReconAttemptDocument,
    options,
  )
}
export type SaveReconAttemptMutationHookResult = ReturnType<typeof useSaveReconAttemptMutation>
export type SaveReconAttemptMutationResult = Apollo.MutationResult<SaveReconAttemptMutation>
export type SaveReconAttemptMutationOptions = Apollo.BaseMutationOptions<
  SaveReconAttemptMutation,
  SaveReconAttemptMutationVariables
>
export const SaveReconAttemptsByBatchDocument = gql`
  mutation saveReconAttemptsByBatch($reconAsyncBatchId: String!) {
    saveReconAttemptsByBatch(reconAsyncBatchId: $reconAsyncBatchId) {
      ok
    }
  }
`
export type SaveReconAttemptsByBatchMutationFn = Apollo.MutationFunction<
  SaveReconAttemptsByBatchMutation,
  SaveReconAttemptsByBatchMutationVariables
>

/**
 * __useSaveReconAttemptsByBatchMutation__
 *
 * To run a mutation, you first call `useSaveReconAttemptsByBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveReconAttemptsByBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveReconAttemptsByBatchMutation, { data, loading, error }] = useSaveReconAttemptsByBatchMutation({
 *   variables: {
 *      reconAsyncBatchId: // value for 'reconAsyncBatchId'
 *   },
 * });
 */
export function useSaveReconAttemptsByBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveReconAttemptsByBatchMutation,
    SaveReconAttemptsByBatchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveReconAttemptsByBatchMutation,
    SaveReconAttemptsByBatchMutationVariables
  >(SaveReconAttemptsByBatchDocument, options)
}
export type SaveReconAttemptsByBatchMutationHookResult = ReturnType<
  typeof useSaveReconAttemptsByBatchMutation
>
export type SaveReconAttemptsByBatchMutationResult =
  Apollo.MutationResult<SaveReconAttemptsByBatchMutation>
export type SaveReconAttemptsByBatchMutationOptions = Apollo.BaseMutationOptions<
  SaveReconAttemptsByBatchMutation,
  SaveReconAttemptsByBatchMutationVariables
>
export const ReconcileApInvoiceJobDocument = gql`
  mutation reconcileApInvoiceJob(
    $jobId: String!
    $lumpsum: InputDocumentTable
    $overrideChargeDescription: Boolean!
    $disableSendDueDate: Boolean!
    $matchingCriteria: MatchingCriteriaType!
  ) {
    reconcileApInvoiceJob(
      jobId: $jobId
      lumpsum: $lumpsum
      overrideChargeDescription: $overrideChargeDescription
      disableSendDueDate: $disableSendDueDate
      matchingCriteria: $matchingCriteria
    ) {
      docCharges {
        id
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
      }
      expectedCharges {
        id
        chainIoSiLineId
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
        isPosted
        overseasSellAmount
        sellCurrency
      }
      reconAttempt {
        id
        job {
          id
          jobTemplate {
            id
            apiPartnerId
            companyId
          }
        }
        reconDetail {
          vendor
          tmsId
          shipmentNo
          invoiceNo
          hblNo
          mblNo
          referenceNo
          containerNo
          carrierBookingNo
          consolNo
          orderNo
          dueDate
        }
        matchingCriteria {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on MatchingCriteriaTypeEnum {
            value
            isFallback
          }
        }
        overrideChargeDescription
        cargowiseModule {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on CwTargetModuleEnum {
            value
            isFallback
          }
        }
      }
      reconResults {
        id
        success
        chainIoShipment {
          id
          dateModified
          tmsId
          houseBill
          masterBill
          carrierBookingNumber
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
        }
        chainIoConsolidation {
          id
          dateModified
        }
        chainIoCustomsDeclaration {
          id
          dateModified
        }
        ... on FindShipmentReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          referenceNo
          hblNo
          mblNo
          carrierBookingNo
          containerNo
          orderNo
          consolNo
          isPrimary
          isMaximalAmongDuplicates
          chainIoShipment {
            id
            forwarderReference
            tmsId
            houseBill
            masterBill
            carrierBookingNumber
            bookingReference
            containers {
              edges {
                node {
                  id
                  containerNumber
                }
              }
            }
            chainIoConsolidations {
              edges {
                node {
                  id
                  forwarderReference
                }
              }
            }
            orders {
              edges {
                node {
                  id
                  orderReference
                }
              }
            }
          }
          chainIoConsolidation {
            id
            forwarderReference
            tmsId
            masterBill
            carrierBookingRef
            containers {
              edges {
                node {
                  id
                  containerNumber
                }
              }
            }
            shipments {
              edges {
                node {
                  id
                  forwarderReference
                  houseBill
                  orders {
                    edges {
                      node {
                        id
                        orderReference
                      }
                    }
                  }
                }
              }
            }
          }
          chainIoCustomsDeclaration {
            id
            forwarderReference
            tmsId
            houseBill
            masterBill
            bookingReference
            containers {
              edges {
                node {
                  id
                  containerNumber
                }
              }
            }
            chainIoShipment {
              id
              carrierBookingNumber
              forwarderReference
              chainIoConsolidations {
                edges {
                  node {
                    id
                    forwarderReference
                  }
                }
              }
            }
            orders {
              edges {
                node {
                  id
                  orderReference
                }
              }
            }
          }
          findShipmentDuplicateReconResults {
            edges {
              node {
                id
                chainIoShipment {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                chainIoConsolidation {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                chainIoCustomsDeclaration {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
              }
            }
          }
        }
        ... on FindInvoiceReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          invoiceReconMatches {
            edges {
              node {
                id
                chainIoSi {
                  id
                  invoiceNumber
                }
              }
            }
          }
        }
        ... on InvoiceLineItemReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          invoiceAmount
          invoiceTaxAmount
          expectedAmount
          expectedTaxAmount
          invoiceChargeCode {
            id
            code
            chargeVendor {
              id
              name
            }
          }
          invoiceChargeCodeCode
          expectedChargeCode {
            id
            code
            chargeVendor {
              id
              name
            }
          }
          invoiceNumber
          invoiceTaxId
          expectedTaxId
          invoiceChargeVendor {
            id
            code
          }
          invoiceDate
          dueDate
          invoiceCurrency
          invoiceUnitPrice
          invoiceQuantity
          chainIoSiLine {
            id
          }
          chainIoConsolCostId
          chainIoCustomsDeclarationCostId
          duplicateChainIoSiLines {
            edges {
              node {
                id
                chainIoSiLine {
                  id
                }
              }
            }
          }
          isChargeCodeSame
          isTotalCostEqual
          isCurrencySameOrEmpty
          isChargeVendorSame
          isInvoiceNumberCorrect
        }
        ... on InvoiceTotalReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          invoiceTotalAmount: invoiceAmount
          invoiceTaxedTotalAmount: invoiceTaxedAmount
          expectedTotalAmount: expectedAmount
          expectedTaxedTotalAmount: expectedTaxedAmount
          usedThresholdMatching
          isWithinThreshold
          reconThresholdRange {
            id
            thresholdAmount
          }
        }
        ... on ShipmentStaffReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          operationsStaffName
          operationsStaffCode
          salesStaffName
          salesStaffCode
        }
        ... on MetadataReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          key {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on MetadataReconResultKeyEnum {
              value
              isFallback
            }
          }
          invoiceValue
          expectedValue
        }
      }
    }
  }
`
export type ReconcileApInvoiceJobMutationFn = Apollo.MutationFunction<
  ReconcileApInvoiceJobMutation,
  ReconcileApInvoiceJobMutationVariables
>

/**
 * __useReconcileApInvoiceJobMutation__
 *
 * To run a mutation, you first call `useReconcileApInvoiceJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconcileApInvoiceJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconcileApInvoiceJobMutation, { data, loading, error }] = useReconcileApInvoiceJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      lumpsum: // value for 'lumpsum'
 *      overrideChargeDescription: // value for 'overrideChargeDescription'
 *      disableSendDueDate: // value for 'disableSendDueDate'
 *      matchingCriteria: // value for 'matchingCriteria'
 *   },
 * });
 */
export function useReconcileApInvoiceJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReconcileApInvoiceJobMutation,
    ReconcileApInvoiceJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReconcileApInvoiceJobMutation, ReconcileApInvoiceJobMutationVariables>(
    ReconcileApInvoiceJobDocument,
    options,
  )
}
export type ReconcileApInvoiceJobMutationHookResult = ReturnType<
  typeof useReconcileApInvoiceJobMutation
>
export type ReconcileApInvoiceJobMutationResult =
  Apollo.MutationResult<ReconcileApInvoiceJobMutation>
export type ReconcileApInvoiceJobMutationOptions = Apollo.BaseMutationOptions<
  ReconcileApInvoiceJobMutation,
  ReconcileApInvoiceJobMutationVariables
>
export const ReconcileArrivalNoticeJobDocument = gql`
  mutation reconcileArrivalNoticeJob(
    $jobId: String!
    $lumpsum: InputDocumentTable
    $premiumRateDetails: InputReconPremiumRate
  ) {
    reconcileArrivalNoticeJob(
      jobId: $jobId
      lumpsum: $lumpsum
      premiumRateDetails: $premiumRateDetails
    ) {
      reconAttempt {
        id
        cargowiseModule {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on CwTargetModuleEnum {
            value
            isFallback
          }
        }
      }
      reconResults {
        success
        chainIoConsolidation {
          id
          forwarderReference
          dateModified
        }
        chainIoShipment {
          id
          dateModified
        }
        id
        ... on FindConsolReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          matchedKey
          keyNo
          documentConsolNo
          documentMbl
          documentContainerNumbers
        }
        ... on ArrivalNoticeConsolTypeReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          documentCreditorCode
          expectedCreditorCode
          documentCarrierCode
          expectedCarrierCode
          documentConsolType
          documentContainerType
          expectedConsolType
          expectedContainerType
        }
        ... on ArrivalNoticeMetadataReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          metadataKey
          documentValue
          expectedValue
        }
        ... on ArrivalNoticeContainerReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          documentContainerNo
          expectedContainerNo
          documentContainerTypeCode
          expectedContainerTypeCode
          documentCommodity
        }
        ... on ArrivalNoticeContractReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          contractId
          effectivity
          expiryDate
          documentAcceptedDate
          documentVendorCode
          clientContractRefNo
        }
        ... on ArrivalNoticeChargeRateReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          documentChargeCode
          expectedChargeCode
          chargeCodeMatchesFound
          documentChargeCost
          expectedChargeCost
          documentQuantity
          documentTotalAmount
          expectedTotalAmount
          documentCurrency
          expectedCurrency
          currencyMatchesFound
          documentContainerSize
          expectedContainerSize
          containerSizeMatchesFound
          documentContainerType
          expectedContainerType
          containerTypeMatchesFound
          documentCommodity
          expectedCommodity
          commodityMatchesFound
          documentPlaceOfReceipt
          expectedPlaceOfReceipt
          placeOfReceiptMatchesFound
          documentPlaceOfDelivery
          expectedPlaceOfDelivery
          placeOfDeliveryMatchesFound
          documentPortOfLoading
          expectedPortOfLoading
          portOfLoadingMatchesFound
          documentPortOfDischarge
          expectedPortOfDischarge
          portOfDischargeMatchesFound
          documentNamedAccount
          expectedNamedAccount
          namedAccountMatchesFound
          documentShipmentRoute
          expectedShipmentRoute
          shipmentRouteMatchesFound
          amendmentNo
          portOfLoadingSimilarRateLocations {
            id
            rateLocation
          }
          portOfDischargeSimilarRateLocations {
            id
            rateLocation
          }
          placeOfReceiptSimilarRateLocations {
            id
            rateLocation
          }
          placeOfDeliverySimilarRateLocations {
            id
            rateLocation
          }
        }
        ... on ArrivalNoticeLumpsumReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          documentLumpsum
          expectedLumpsum
          premiumRate
          premiumRateContainerQty
          premiumTotalAccessorialFees
        }
        ... on ReconResultFallbackNode {
          id
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
        }
      }
    }
  }
`
export type ReconcileArrivalNoticeJobMutationFn = Apollo.MutationFunction<
  ReconcileArrivalNoticeJobMutation,
  ReconcileArrivalNoticeJobMutationVariables
>

/**
 * __useReconcileArrivalNoticeJobMutation__
 *
 * To run a mutation, you first call `useReconcileArrivalNoticeJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconcileArrivalNoticeJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconcileArrivalNoticeJobMutation, { data, loading, error }] = useReconcileArrivalNoticeJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      lumpsum: // value for 'lumpsum'
 *      premiumRateDetails: // value for 'premiumRateDetails'
 *   },
 * });
 */
export function useReconcileArrivalNoticeJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReconcileArrivalNoticeJobMutation,
    ReconcileArrivalNoticeJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ReconcileArrivalNoticeJobMutation,
    ReconcileArrivalNoticeJobMutationVariables
  >(ReconcileArrivalNoticeJobDocument, options)
}
export type ReconcileArrivalNoticeJobMutationHookResult = ReturnType<
  typeof useReconcileArrivalNoticeJobMutation
>
export type ReconcileArrivalNoticeJobMutationResult =
  Apollo.MutationResult<ReconcileArrivalNoticeJobMutation>
export type ReconcileArrivalNoticeJobMutationOptions = Apollo.BaseMutationOptions<
  ReconcileArrivalNoticeJobMutation,
  ReconcileArrivalNoticeJobMutationVariables
>
export const UpdateChargeQuantityDocument = gql`
  mutation updateChargeQuantity($jobId: String!) {
    updateChargeQuantity(jobId: $jobId) {
      ok
    }
  }
`
export type UpdateChargeQuantityMutationFn = Apollo.MutationFunction<
  UpdateChargeQuantityMutation,
  UpdateChargeQuantityMutationVariables
>

/**
 * __useUpdateChargeQuantityMutation__
 *
 * To run a mutation, you first call `useUpdateChargeQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChargeQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChargeQuantityMutation, { data, loading, error }] = useUpdateChargeQuantityMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useUpdateChargeQuantityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChargeQuantityMutation,
    UpdateChargeQuantityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateChargeQuantityMutation, UpdateChargeQuantityMutationVariables>(
    UpdateChargeQuantityDocument,
    options,
  )
}
export type UpdateChargeQuantityMutationHookResult = ReturnType<
  typeof useUpdateChargeQuantityMutation
>
export type UpdateChargeQuantityMutationResult = Apollo.MutationResult<UpdateChargeQuantityMutation>
export type UpdateChargeQuantityMutationOptions = Apollo.BaseMutationOptions<
  UpdateChargeQuantityMutation,
  UpdateChargeQuantityMutationVariables
>
export const FetchChargeDetailsFromCwDocument = gql`
  mutation fetchChargeDetailsFromCw($jobId: UUID!) {
    fetchChargeDetailsFromCw(jobId: $jobId) {
      findShipmentReconResults {
        id
        success
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        referenceNo
        hblNo
        mblNo
        carrierBookingNo
        containerNo
        orderNo
        consolNo
        isMaximalAmongDuplicates
        chainIoShipment {
          id
          forwarderReference
          dateModified
          houseBill
          masterBill
          carrierBookingNumber
          bookingReference
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          orders {
            edges {
              node {
                id
                orderReference
                sequence
                orderNumberSplit
                orderStatus
                orderDate
                orderBuyer {
                  id
                  sourcePartyId
                }
                orderSupplier {
                  id
                  sourcePartyId
                }
                orderLines {
                  edges {
                    node {
                      id
                      orderLineNumber
                      partNumber
                      description
                      containerNumber
                    }
                  }
                }
              }
            }
          }
          chainIoConsolidations {
            edges {
              node {
                id
                forwarderReference
              }
            }
          }
          additionalReferences {
            edges {
              node {
                id
                referenceType
                referenceNumber
              }
            }
          }
          customFields {
            edges {
              node {
                id
                key
                value
              }
            }
          }
          shipmentLegs {
            edges {
              node {
                id
                legSequenceNumber
                transportMode
                legType
                vesselName
                voyageNumber
                ladingPort {
                  id
                  unlocode
                }
                arrivalPort {
                  id
                  unlocode
                }
                departureEstimated
                arrivalEstimated
                legCarrier {
                  id
                  name
                }
                bookingStatus
              }
            }
          }
          packingLines {
            edges {
              node {
                id
                packs
                packsUom
                containerNumber
                weight
                weightUom
                volume
                volumeUom
                referenceNumber
                importReferenceNumber
                exportReferenceNumber
                descriptionOfGoods
                marksAndNumbers
                commodityCode
              }
            }
          }
          localClient {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          shipper {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          consignee {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          supplier {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          importer {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          pickupTransportCompany {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          deliveryTransportCompany {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          pickupFrom {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          deliveryTo {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          controllingAgent {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          controllingCustomer {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          importBroker {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
        }
        chainIoConsolidation {
          id
          forwarderReference
          dateModified
          masterBill
          carrierBookingRef
          consolCosts {
            edges {
              node {
                id
                chargeCode
                chargeDescription
                amount
                currency
                creditorCode
                invoiceNumber
                taxRateCode
                taxRate
                isPosted
              }
            }
          }
          containers {
            edges {
              node {
                id
                containerNumber
                containerCount
                seal1
                containerMode
                typeCode
                deliveryMode
                sealedBy
                wharfGateIn
              }
            }
          }
          shipmentLegs {
            edges {
              node {
                id
                legSequenceNumber
                transportMode
                legType
                vesselName
                voyageNumber
                ladingPort {
                  id
                  unlocode
                }
                arrivalPort {
                  id
                  unlocode
                }
                departureEstimated
                arrivalEstimated
                legCarrier {
                  id
                  name
                }
                bookingStatus
              }
            }
          }
          shipments {
            edges {
              node {
                id
                forwarderReference
                houseBill
                orders {
                  edges {
                    node {
                      id
                      orderReference
                    }
                  }
                }
              }
            }
          }
          carrier {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          creditor {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          coloadWith {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          receivingAgent {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          sendingAgent {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
        }
        chainIoCustomsDeclaration {
          id
          forwarderReference
          dateModified
          houseBill
          masterBill
          bookingReference
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          chainIoShipment {
            id
            carrierBookingNumber
            forwarderReference
            chainIoConsolidations {
              edges {
                node {
                  id
                  forwarderReference
                }
              }
            }
          }
          orders {
            edges {
              node {
                id
                orderReference
              }
            }
          }
        }
        findShipmentDuplicateReconResults {
          edges {
            node {
              id
              chainIoShipment {
                id
                forwarderReference
                houseBill
                masterBill
                isCancelled
                tmsId
                bookingReference
                containers {
                  edges {
                    node {
                      id
                      containerNumber
                    }
                  }
                }
                chainIoConsolidation {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                orders {
                  edges {
                    node {
                      id
                      orderReference
                    }
                  }
                }
              }
              chainIoConsolidation {
                id
                forwarderReference
                isCancelled
                tmsId
              }
              chainIoCustomsDeclaration {
                id
                forwarderReference
                tmsId
                isCancelled
              }
            }
          }
        }
      }
      expectedCharges {
        id
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
        isPosted
        overseasSellAmount
        sellCurrency
      }
      shipmentStaffReconResults {
        id
        operationsStaffName
        operationsStaffCode
        salesStaffName
        salesStaffCode
      }
      metadataReconResults {
        id
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        key {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on MetadataReconResultKeyEnum {
            value
            isFallback
          }
        }
        invoiceValue
        expectedValue
      }
    }
  }
`
export type FetchChargeDetailsFromCwMutationFn = Apollo.MutationFunction<
  FetchChargeDetailsFromCwMutation,
  FetchChargeDetailsFromCwMutationVariables
>

/**
 * __useFetchChargeDetailsFromCwMutation__
 *
 * To run a mutation, you first call `useFetchChargeDetailsFromCwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchChargeDetailsFromCwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchChargeDetailsFromCwMutation, { data, loading, error }] = useFetchChargeDetailsFromCwMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useFetchChargeDetailsFromCwMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FetchChargeDetailsFromCwMutation,
    FetchChargeDetailsFromCwMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FetchChargeDetailsFromCwMutation,
    FetchChargeDetailsFromCwMutationVariables
  >(FetchChargeDetailsFromCwDocument, options)
}
export type FetchChargeDetailsFromCwMutationHookResult = ReturnType<
  typeof useFetchChargeDetailsFromCwMutation
>
export type FetchChargeDetailsFromCwMutationResult =
  Apollo.MutationResult<FetchChargeDetailsFromCwMutation>
export type FetchChargeDetailsFromCwMutationOptions = Apollo.BaseMutationOptions<
  FetchChargeDetailsFromCwMutation,
  FetchChargeDetailsFromCwMutationVariables
>
export const BatchFetchShipmentInfoFromCwDocument = gql`
  mutation batchFetchShipmentInfoFromCw($jobId: UUID!) {
    batchFetchShipmentInfoFromCw(jobId: $jobId) {
      checkShipmentInfoAsyncBatchId
    }
  }
`
export type BatchFetchShipmentInfoFromCwMutationFn = Apollo.MutationFunction<
  BatchFetchShipmentInfoFromCwMutation,
  BatchFetchShipmentInfoFromCwMutationVariables
>

/**
 * __useBatchFetchShipmentInfoFromCwMutation__
 *
 * To run a mutation, you first call `useBatchFetchShipmentInfoFromCwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchFetchShipmentInfoFromCwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchFetchShipmentInfoFromCwMutation, { data, loading, error }] = useBatchFetchShipmentInfoFromCwMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useBatchFetchShipmentInfoFromCwMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchFetchShipmentInfoFromCwMutation,
    BatchFetchShipmentInfoFromCwMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BatchFetchShipmentInfoFromCwMutation,
    BatchFetchShipmentInfoFromCwMutationVariables
  >(BatchFetchShipmentInfoFromCwDocument, options)
}
export type BatchFetchShipmentInfoFromCwMutationHookResult = ReturnType<
  typeof useBatchFetchShipmentInfoFromCwMutation
>
export type BatchFetchShipmentInfoFromCwMutationResult =
  Apollo.MutationResult<BatchFetchShipmentInfoFromCwMutation>
export type BatchFetchShipmentInfoFromCwMutationOptions = Apollo.BaseMutationOptions<
  BatchFetchShipmentInfoFromCwMutation,
  BatchFetchShipmentInfoFromCwMutationVariables
>
export const DeleteChargeVendorDocument = gql`
  mutation deleteChargeVendor($chargeVendorId: UUID!) {
    deleteChargeVendor(chargeVendorId: $chargeVendorId) {
      ok
    }
  }
`
export type DeleteChargeVendorMutationFn = Apollo.MutationFunction<
  DeleteChargeVendorMutation,
  DeleteChargeVendorMutationVariables
>

/**
 * __useDeleteChargeVendorMutation__
 *
 * To run a mutation, you first call `useDeleteChargeVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChargeVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChargeVendorMutation, { data, loading, error }] = useDeleteChargeVendorMutation({
 *   variables: {
 *      chargeVendorId: // value for 'chargeVendorId'
 *   },
 * });
 */
export function useDeleteChargeVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChargeVendorMutation,
    DeleteChargeVendorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteChargeVendorMutation, DeleteChargeVendorMutationVariables>(
    DeleteChargeVendorDocument,
    options,
  )
}
export type DeleteChargeVendorMutationHookResult = ReturnType<typeof useDeleteChargeVendorMutation>
export type DeleteChargeVendorMutationResult = Apollo.MutationResult<DeleteChargeVendorMutation>
export type DeleteChargeVendorMutationOptions = Apollo.BaseMutationOptions<
  DeleteChargeVendorMutation,
  DeleteChargeVendorMutationVariables
>
export const DeleteChargeCodesDocument = gql`
  mutation deleteChargeCodes($chargeCodeIds: [UUID]!) {
    deleteChargeCodes(chargeCodeIds: $chargeCodeIds) {
      ok
    }
  }
`
export type DeleteChargeCodesMutationFn = Apollo.MutationFunction<
  DeleteChargeCodesMutation,
  DeleteChargeCodesMutationVariables
>

/**
 * __useDeleteChargeCodesMutation__
 *
 * To run a mutation, you first call `useDeleteChargeCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChargeCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChargeCodesMutation, { data, loading, error }] = useDeleteChargeCodesMutation({
 *   variables: {
 *      chargeCodeIds: // value for 'chargeCodeIds'
 *   },
 * });
 */
export function useDeleteChargeCodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChargeCodesMutation,
    DeleteChargeCodesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteChargeCodesMutation, DeleteChargeCodesMutationVariables>(
    DeleteChargeCodesDocument,
    options,
  )
}
export type DeleteChargeCodesMutationHookResult = ReturnType<typeof useDeleteChargeCodesMutation>
export type DeleteChargeCodesMutationResult = Apollo.MutationResult<DeleteChargeCodesMutation>
export type DeleteChargeCodesMutationOptions = Apollo.BaseMutationOptions<
  DeleteChargeCodesMutation,
  DeleteChargeCodesMutationVariables
>
export const DeleteChargeCodesAndOverridesDocument = gql`
  mutation deleteChargeCodesAndOverrides($chargeCodeIds: [UUID!]!) {
    deleteChargeCodesAndOverrides(chargeCodeIds: $chargeCodeIds) {
      ok
    }
  }
`
export type DeleteChargeCodesAndOverridesMutationFn = Apollo.MutationFunction<
  DeleteChargeCodesAndOverridesMutation,
  DeleteChargeCodesAndOverridesMutationVariables
>

/**
 * __useDeleteChargeCodesAndOverridesMutation__
 *
 * To run a mutation, you first call `useDeleteChargeCodesAndOverridesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChargeCodesAndOverridesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChargeCodesAndOverridesMutation, { data, loading, error }] = useDeleteChargeCodesAndOverridesMutation({
 *   variables: {
 *      chargeCodeIds: // value for 'chargeCodeIds'
 *   },
 * });
 */
export function useDeleteChargeCodesAndOverridesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChargeCodesAndOverridesMutation,
    DeleteChargeCodesAndOverridesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteChargeCodesAndOverridesMutation,
    DeleteChargeCodesAndOverridesMutationVariables
  >(DeleteChargeCodesAndOverridesDocument, options)
}
export type DeleteChargeCodesAndOverridesMutationHookResult = ReturnType<
  typeof useDeleteChargeCodesAndOverridesMutation
>
export type DeleteChargeCodesAndOverridesMutationResult =
  Apollo.MutationResult<DeleteChargeCodesAndOverridesMutation>
export type DeleteChargeCodesAndOverridesMutationOptions = Apollo.BaseMutationOptions<
  DeleteChargeCodesAndOverridesMutation,
  DeleteChargeCodesAndOverridesMutationVariables
>
export const EditChargeVendorDocument = gql`
  mutation editChargeVendor(
    $chargeVendorId: UUID!
    $name: String!
    $code: String!
    $type: String!
    $chargeCodes: [InputChargeCode!]!
    $apiPartnerId: UUID
    $codesLimit: Int
    $codesOffset: Int
  ) {
    editChargeVendor(
      chargeVendorId: $chargeVendorId
      name: $name
      code: $code
      type: $type
      chargeCodes: $chargeCodes
      apiPartnerId: $apiPartnerId
      codesLimit: $codesLimit
      codesOffset: $codesOffset
    ) {
      id
    }
  }
`
export type EditChargeVendorMutationFn = Apollo.MutationFunction<
  EditChargeVendorMutation,
  EditChargeVendorMutationVariables
>

/**
 * __useEditChargeVendorMutation__
 *
 * To run a mutation, you first call `useEditChargeVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChargeVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChargeVendorMutation, { data, loading, error }] = useEditChargeVendorMutation({
 *   variables: {
 *      chargeVendorId: // value for 'chargeVendorId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      type: // value for 'type'
 *      chargeCodes: // value for 'chargeCodes'
 *      apiPartnerId: // value for 'apiPartnerId'
 *      codesLimit: // value for 'codesLimit'
 *      codesOffset: // value for 'codesOffset'
 *   },
 * });
 */
export function useEditChargeVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditChargeVendorMutation,
    EditChargeVendorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditChargeVendorMutation, EditChargeVendorMutationVariables>(
    EditChargeVendorDocument,
    options,
  )
}
export type EditChargeVendorMutationHookResult = ReturnType<typeof useEditChargeVendorMutation>
export type EditChargeVendorMutationResult = Apollo.MutationResult<EditChargeVendorMutation>
export type EditChargeVendorMutationOptions = Apollo.BaseMutationOptions<
  EditChargeVendorMutation,
  EditChargeVendorMutationVariables
>
export const EditChargeVendorAndOverridesDocument = gql`
  mutation editChargeVendorAndOverrides(
    $chargeVendorId: UUID!
    $name: String!
    $code: String!
    $type: String!
    $chargeCodeOverrides: [InputChargeCode!]!
    $apiPartnerId: UUID
    $codesLimit: Int
    $codesOffset: Int
  ) {
    editChargeVendorAndOverrides(
      chargeVendorId: $chargeVendorId
      name: $name
      code: $code
      type: $type
      chargeCodeOverrides: $chargeCodeOverrides
      apiPartnerId: $apiPartnerId
      codesLimit: $codesLimit
      codesOffset: $codesOffset
    ) {
      id
    }
  }
`
export type EditChargeVendorAndOverridesMutationFn = Apollo.MutationFunction<
  EditChargeVendorAndOverridesMutation,
  EditChargeVendorAndOverridesMutationVariables
>

/**
 * __useEditChargeVendorAndOverridesMutation__
 *
 * To run a mutation, you first call `useEditChargeVendorAndOverridesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChargeVendorAndOverridesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChargeVendorAndOverridesMutation, { data, loading, error }] = useEditChargeVendorAndOverridesMutation({
 *   variables: {
 *      chargeVendorId: // value for 'chargeVendorId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      type: // value for 'type'
 *      chargeCodeOverrides: // value for 'chargeCodeOverrides'
 *      apiPartnerId: // value for 'apiPartnerId'
 *      codesLimit: // value for 'codesLimit'
 *      codesOffset: // value for 'codesOffset'
 *   },
 * });
 */
export function useEditChargeVendorAndOverridesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditChargeVendorAndOverridesMutation,
    EditChargeVendorAndOverridesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EditChargeVendorAndOverridesMutation,
    EditChargeVendorAndOverridesMutationVariables
  >(EditChargeVendorAndOverridesDocument, options)
}
export type EditChargeVendorAndOverridesMutationHookResult = ReturnType<
  typeof useEditChargeVendorAndOverridesMutation
>
export type EditChargeVendorAndOverridesMutationResult =
  Apollo.MutationResult<EditChargeVendorAndOverridesMutation>
export type EditChargeVendorAndOverridesMutationOptions = Apollo.BaseMutationOptions<
  EditChargeVendorAndOverridesMutation,
  EditChargeVendorAndOverridesMutationVariables
>
export const EditChargeCodesDocument = gql`
  mutation editChargeCodes(
    $chargeCodeId: UUID!
    $apiPartnerId: UUID
    $code: String!
    $taxId: UUID
    $vendorsAndDescriptions: [InputVendorAndDesc!]!
  ) {
    editChargeCodes(
      chargeCodeId: $chargeCodeId
      apiPartnerId: $apiPartnerId
      code: $code
      taxId: $taxId
      vendorsAndDescriptions: $vendorsAndDescriptions
    ) {
      id
    }
  }
`
export type EditChargeCodesMutationFn = Apollo.MutationFunction<
  EditChargeCodesMutation,
  EditChargeCodesMutationVariables
>

/**
 * __useEditChargeCodesMutation__
 *
 * To run a mutation, you first call `useEditChargeCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChargeCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChargeCodesMutation, { data, loading, error }] = useEditChargeCodesMutation({
 *   variables: {
 *      chargeCodeId: // value for 'chargeCodeId'
 *      apiPartnerId: // value for 'apiPartnerId'
 *      code: // value for 'code'
 *      taxId: // value for 'taxId'
 *      vendorsAndDescriptions: // value for 'vendorsAndDescriptions'
 *   },
 * });
 */
export function useEditChargeCodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditChargeCodesMutation,
    EditChargeCodesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditChargeCodesMutation, EditChargeCodesMutationVariables>(
    EditChargeCodesDocument,
    options,
  )
}
export type EditChargeCodesMutationHookResult = ReturnType<typeof useEditChargeCodesMutation>
export type EditChargeCodesMutationResult = Apollo.MutationResult<EditChargeCodesMutation>
export type EditChargeCodesMutationOptions = Apollo.BaseMutationOptions<
  EditChargeCodesMutation,
  EditChargeCodesMutationVariables
>
export const EditChargeCodeAndOverridesDocument = gql`
  mutation editChargeCodeAndOverrides(
    $chargeCodeId: UUID!
    $code: String!
    $description: String!
    $vendorsAndDescriptions: [InputVendorAndDesc!]!
    $taxId: UUID
  ) {
    editChargeCodeAndOverrides(
      chargeCodeId: $chargeCodeId
      code: $code
      description: $description
      vendorsAndDescriptions: $vendorsAndDescriptions
      taxId: $taxId
    ) {
      id
    }
  }
`
export type EditChargeCodeAndOverridesMutationFn = Apollo.MutationFunction<
  EditChargeCodeAndOverridesMutation,
  EditChargeCodeAndOverridesMutationVariables
>

/**
 * __useEditChargeCodeAndOverridesMutation__
 *
 * To run a mutation, you first call `useEditChargeCodeAndOverridesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChargeCodeAndOverridesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChargeCodeAndOverridesMutation, { data, loading, error }] = useEditChargeCodeAndOverridesMutation({
 *   variables: {
 *      chargeCodeId: // value for 'chargeCodeId'
 *      code: // value for 'code'
 *      description: // value for 'description'
 *      vendorsAndDescriptions: // value for 'vendorsAndDescriptions'
 *      taxId: // value for 'taxId'
 *   },
 * });
 */
export function useEditChargeCodeAndOverridesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditChargeCodeAndOverridesMutation,
    EditChargeCodeAndOverridesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EditChargeCodeAndOverridesMutation,
    EditChargeCodeAndOverridesMutationVariables
  >(EditChargeCodeAndOverridesDocument, options)
}
export type EditChargeCodeAndOverridesMutationHookResult = ReturnType<
  typeof useEditChargeCodeAndOverridesMutation
>
export type EditChargeCodeAndOverridesMutationResult =
  Apollo.MutationResult<EditChargeCodeAndOverridesMutation>
export type EditChargeCodeAndOverridesMutationOptions = Apollo.BaseMutationOptions<
  EditChargeCodeAndOverridesMutation,
  EditChargeCodeAndOverridesMutationVariables
>
export const CreateChargeVendorWithChargeCodesDocument = gql`
  mutation createChargeVendorWithChargeCodes(
    $companyId: UUID!
    $name: String!
    $code: String!
    $type: String!
    $chargeCodes: [InputChargeCode!]!
    $apiPartnerId: UUID
  ) {
    createChargeVendorWithChargeCodes(
      companyId: $companyId
      name: $name
      code: $code
      type: $type
      chargeCodes: $chargeCodes
      apiPartnerId: $apiPartnerId
    ) {
      chargeVendor {
        id
      }
    }
  }
`
export type CreateChargeVendorWithChargeCodesMutationFn = Apollo.MutationFunction<
  CreateChargeVendorWithChargeCodesMutation,
  CreateChargeVendorWithChargeCodesMutationVariables
>

/**
 * __useCreateChargeVendorWithChargeCodesMutation__
 *
 * To run a mutation, you first call `useCreateChargeVendorWithChargeCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargeVendorWithChargeCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargeVendorWithChargeCodesMutation, { data, loading, error }] = useCreateChargeVendorWithChargeCodesMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      type: // value for 'type'
 *      chargeCodes: // value for 'chargeCodes'
 *      apiPartnerId: // value for 'apiPartnerId'
 *   },
 * });
 */
export function useCreateChargeVendorWithChargeCodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChargeVendorWithChargeCodesMutation,
    CreateChargeVendorWithChargeCodesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateChargeVendorWithChargeCodesMutation,
    CreateChargeVendorWithChargeCodesMutationVariables
  >(CreateChargeVendorWithChargeCodesDocument, options)
}
export type CreateChargeVendorWithChargeCodesMutationHookResult = ReturnType<
  typeof useCreateChargeVendorWithChargeCodesMutation
>
export type CreateChargeVendorWithChargeCodesMutationResult =
  Apollo.MutationResult<CreateChargeVendorWithChargeCodesMutation>
export type CreateChargeVendorWithChargeCodesMutationOptions = Apollo.BaseMutationOptions<
  CreateChargeVendorWithChargeCodesMutation,
  CreateChargeVendorWithChargeCodesMutationVariables
>
export const CreateChargeVendorAndOverridesDocument = gql`
  mutation createChargeVendorAndOverrides(
    $companyId: UUID!
    $name: String!
    $code: String!
    $type: String!
    $chargeCodeOverrides: [InputChargeCode!]!
    $apiPartnerId: UUID!
  ) {
    createChargeVendorAndOverrides(
      companyId: $companyId
      name: $name
      code: $code
      type: $type
      chargeCodeOverrides: $chargeCodeOverrides
      apiPartnerId: $apiPartnerId
    ) {
      chargeVendor {
        id
      }
    }
  }
`
export type CreateChargeVendorAndOverridesMutationFn = Apollo.MutationFunction<
  CreateChargeVendorAndOverridesMutation,
  CreateChargeVendorAndOverridesMutationVariables
>

/**
 * __useCreateChargeVendorAndOverridesMutation__
 *
 * To run a mutation, you first call `useCreateChargeVendorAndOverridesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargeVendorAndOverridesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargeVendorAndOverridesMutation, { data, loading, error }] = useCreateChargeVendorAndOverridesMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      type: // value for 'type'
 *      chargeCodeOverrides: // value for 'chargeCodeOverrides'
 *      apiPartnerId: // value for 'apiPartnerId'
 *   },
 * });
 */
export function useCreateChargeVendorAndOverridesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChargeVendorAndOverridesMutation,
    CreateChargeVendorAndOverridesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateChargeVendorAndOverridesMutation,
    CreateChargeVendorAndOverridesMutationVariables
  >(CreateChargeVendorAndOverridesDocument, options)
}
export type CreateChargeVendorAndOverridesMutationHookResult = ReturnType<
  typeof useCreateChargeVendorAndOverridesMutation
>
export type CreateChargeVendorAndOverridesMutationResult =
  Apollo.MutationResult<CreateChargeVendorAndOverridesMutation>
export type CreateChargeVendorAndOverridesMutationOptions = Apollo.BaseMutationOptions<
  CreateChargeVendorAndOverridesMutation,
  CreateChargeVendorAndOverridesMutationVariables
>
export const UpsertSeperatedChargeVendorsChargeCodesDocument = gql`
  mutation upsertSeperatedChargeVendorsChargeCodes(
    $companyId: UUID!
    $apiPartnerIds: [UUID!]!
    $chargeVendorsSignedUrl: String!
    $chargeCodesSignedUrl: String!
  ) {
    upsertSeperatedChargeVendorsChargeCodes(
      companyId: $companyId
      apiPartnerIds: $apiPartnerIds
      chargeVendorsSignedUrl: $chargeVendorsSignedUrl
      chargeCodesSignedUrl: $chargeCodesSignedUrl
    ) {
      chargeCodeUploadAsyncTaskId
    }
  }
`
export type UpsertSeperatedChargeVendorsChargeCodesMutationFn = Apollo.MutationFunction<
  UpsertSeperatedChargeVendorsChargeCodesMutation,
  UpsertSeperatedChargeVendorsChargeCodesMutationVariables
>

/**
 * __useUpsertSeperatedChargeVendorsChargeCodesMutation__
 *
 * To run a mutation, you first call `useUpsertSeperatedChargeVendorsChargeCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSeperatedChargeVendorsChargeCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSeperatedChargeVendorsChargeCodesMutation, { data, loading, error }] = useUpsertSeperatedChargeVendorsChargeCodesMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      apiPartnerIds: // value for 'apiPartnerIds'
 *      chargeVendorsSignedUrl: // value for 'chargeVendorsSignedUrl'
 *      chargeCodesSignedUrl: // value for 'chargeCodesSignedUrl'
 *   },
 * });
 */
export function useUpsertSeperatedChargeVendorsChargeCodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertSeperatedChargeVendorsChargeCodesMutation,
    UpsertSeperatedChargeVendorsChargeCodesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertSeperatedChargeVendorsChargeCodesMutation,
    UpsertSeperatedChargeVendorsChargeCodesMutationVariables
  >(UpsertSeperatedChargeVendorsChargeCodesDocument, options)
}
export type UpsertSeperatedChargeVendorsChargeCodesMutationHookResult = ReturnType<
  typeof useUpsertSeperatedChargeVendorsChargeCodesMutation
>
export type UpsertSeperatedChargeVendorsChargeCodesMutationResult =
  Apollo.MutationResult<UpsertSeperatedChargeVendorsChargeCodesMutation>
export type UpsertSeperatedChargeVendorsChargeCodesMutationOptions = Apollo.BaseMutationOptions<
  UpsertSeperatedChargeVendorsChargeCodesMutation,
  UpsertSeperatedChargeVendorsChargeCodesMutationVariables
>
export const GetVendorChargeCodeSignedUrlDocument = gql`
  query getVendorChargeCodeSignedUrl($filename: String!) {
    vendorChargeCodeUrl(filename: $filename) {
      putUrl
      viewUrl
    }
  }
`

/**
 * __useGetVendorChargeCodeSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetVendorChargeCodeSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorChargeCodeSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorChargeCodeSignedUrlQuery({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useGetVendorChargeCodeSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVendorChargeCodeSignedUrlQuery,
    GetVendorChargeCodeSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetVendorChargeCodeSignedUrlQuery,
    GetVendorChargeCodeSignedUrlQueryVariables
  >(GetVendorChargeCodeSignedUrlDocument, options)
}
export function useGetVendorChargeCodeSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorChargeCodeSignedUrlQuery,
    GetVendorChargeCodeSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetVendorChargeCodeSignedUrlQuery,
    GetVendorChargeCodeSignedUrlQueryVariables
  >(GetVendorChargeCodeSignedUrlDocument, options)
}
export type GetVendorChargeCodeSignedUrlQueryHookResult = ReturnType<
  typeof useGetVendorChargeCodeSignedUrlQuery
>
export type GetVendorChargeCodeSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetVendorChargeCodeSignedUrlLazyQuery
>
export type GetVendorChargeCodeSignedUrlQueryResult = Apollo.QueryResult<
  GetVendorChargeCodeSignedUrlQuery,
  GetVendorChargeCodeSignedUrlQueryVariables
>
export const CreateChargeCodesDocument = gql`
  mutation createChargeCodes(
    $companyId: UUID!
    $apiPartnerId: UUID
    $code: String!
    $taxId: UUID
    $vendorsAndDescriptions: [InputVendorAndDesc!]!
  ) {
    createChargeCodes(
      companyId: $companyId
      apiPartnerId: $apiPartnerId
      code: $code
      taxId: $taxId
      vendorsAndDescriptions: $vendorsAndDescriptions
    ) {
      ok
    }
  }
`
export type CreateChargeCodesMutationFn = Apollo.MutationFunction<
  CreateChargeCodesMutation,
  CreateChargeCodesMutationVariables
>

/**
 * __useCreateChargeCodesMutation__
 *
 * To run a mutation, you first call `useCreateChargeCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargeCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargeCodesMutation, { data, loading, error }] = useCreateChargeCodesMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      apiPartnerId: // value for 'apiPartnerId'
 *      code: // value for 'code'
 *      taxId: // value for 'taxId'
 *      vendorsAndDescriptions: // value for 'vendorsAndDescriptions'
 *   },
 * });
 */
export function useCreateChargeCodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChargeCodesMutation,
    CreateChargeCodesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateChargeCodesMutation, CreateChargeCodesMutationVariables>(
    CreateChargeCodesDocument,
    options,
  )
}
export type CreateChargeCodesMutationHookResult = ReturnType<typeof useCreateChargeCodesMutation>
export type CreateChargeCodesMutationResult = Apollo.MutationResult<CreateChargeCodesMutation>
export type CreateChargeCodesMutationOptions = Apollo.BaseMutationOptions<
  CreateChargeCodesMutation,
  CreateChargeCodesMutationVariables
>
export const CreateChargeCodeAndOverridesDocument = gql`
  mutation createChargeCodeAndOverrides(
    $companyId: UUID!
    $apiPartnerId: UUID
    $code: String!
    $description: String!
    $vendorsAndDescriptions: [InputVendorAndDesc!]!
    $taxId: UUID
  ) {
    createChargeCodeAndOverrides(
      companyId: $companyId
      apiPartnerId: $apiPartnerId
      code: $code
      description: $description
      vendorsAndDescriptions: $vendorsAndDescriptions
      taxId: $taxId
    ) {
      ok
    }
  }
`
export type CreateChargeCodeAndOverridesMutationFn = Apollo.MutationFunction<
  CreateChargeCodeAndOverridesMutation,
  CreateChargeCodeAndOverridesMutationVariables
>

/**
 * __useCreateChargeCodeAndOverridesMutation__
 *
 * To run a mutation, you first call `useCreateChargeCodeAndOverridesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargeCodeAndOverridesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargeCodeAndOverridesMutation, { data, loading, error }] = useCreateChargeCodeAndOverridesMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      apiPartnerId: // value for 'apiPartnerId'
 *      code: // value for 'code'
 *      description: // value for 'description'
 *      vendorsAndDescriptions: // value for 'vendorsAndDescriptions'
 *      taxId: // value for 'taxId'
 *   },
 * });
 */
export function useCreateChargeCodeAndOverridesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChargeCodeAndOverridesMutation,
    CreateChargeCodeAndOverridesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateChargeCodeAndOverridesMutation,
    CreateChargeCodeAndOverridesMutationVariables
  >(CreateChargeCodeAndOverridesDocument, options)
}
export type CreateChargeCodeAndOverridesMutationHookResult = ReturnType<
  typeof useCreateChargeCodeAndOverridesMutation
>
export type CreateChargeCodeAndOverridesMutationResult =
  Apollo.MutationResult<CreateChargeCodeAndOverridesMutation>
export type CreateChargeCodeAndOverridesMutationOptions = Apollo.BaseMutationOptions<
  CreateChargeCodeAndOverridesMutation,
  CreateChargeCodeAndOverridesMutationVariables
>
export const CreateTaxDocument = gql`
  mutation createTax($taxDetails: InputTaxDetails!) {
    createTax(taxDetails: $taxDetails) {
      ok
    }
  }
`
export type CreateTaxMutationFn = Apollo.MutationFunction<
  CreateTaxMutation,
  CreateTaxMutationVariables
>

/**
 * __useCreateTaxMutation__
 *
 * To run a mutation, you first call `useCreateTaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaxMutation, { data, loading, error }] = useCreateTaxMutation({
 *   variables: {
 *      taxDetails: // value for 'taxDetails'
 *   },
 * });
 */
export function useCreateTaxMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaxMutation, CreateTaxMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTaxMutation, CreateTaxMutationVariables>(
    CreateTaxDocument,
    options,
  )
}
export type CreateTaxMutationHookResult = ReturnType<typeof useCreateTaxMutation>
export type CreateTaxMutationResult = Apollo.MutationResult<CreateTaxMutation>
export type CreateTaxMutationOptions = Apollo.BaseMutationOptions<
  CreateTaxMutation,
  CreateTaxMutationVariables
>
export const EditTaxDocument = gql`
  mutation editTax($taxId: UUID!, $taxDetails: InputTaxDetails!) {
    editTax(taxId: $taxId, taxDetails: $taxDetails) {
      id
    }
  }
`
export type EditTaxMutationFn = Apollo.MutationFunction<EditTaxMutation, EditTaxMutationVariables>

/**
 * __useEditTaxMutation__
 *
 * To run a mutation, you first call `useEditTaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaxMutation, { data, loading, error }] = useEditTaxMutation({
 *   variables: {
 *      taxId: // value for 'taxId'
 *      taxDetails: // value for 'taxDetails'
 *   },
 * });
 */
export function useEditTaxMutation(
  baseOptions?: Apollo.MutationHookOptions<EditTaxMutation, EditTaxMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditTaxMutation, EditTaxMutationVariables>(EditTaxDocument, options)
}
export type EditTaxMutationHookResult = ReturnType<typeof useEditTaxMutation>
export type EditTaxMutationResult = Apollo.MutationResult<EditTaxMutation>
export type EditTaxMutationOptions = Apollo.BaseMutationOptions<
  EditTaxMutation,
  EditTaxMutationVariables
>
export const DeleteTaxDocument = gql`
  mutation deleteTax($taxId: UUID!) {
    deleteTax(taxId: $taxId) {
      ok
    }
  }
`
export type DeleteTaxMutationFn = Apollo.MutationFunction<
  DeleteTaxMutation,
  DeleteTaxMutationVariables
>

/**
 * __useDeleteTaxMutation__
 *
 * To run a mutation, you first call `useDeleteTaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaxMutation, { data, loading, error }] = useDeleteTaxMutation({
 *   variables: {
 *      taxId: // value for 'taxId'
 *   },
 * });
 */
export function useDeleteTaxMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTaxMutation, DeleteTaxMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTaxMutation, DeleteTaxMutationVariables>(
    DeleteTaxDocument,
    options,
  )
}
export type DeleteTaxMutationHookResult = ReturnType<typeof useDeleteTaxMutation>
export type DeleteTaxMutationResult = Apollo.MutationResult<DeleteTaxMutation>
export type DeleteTaxMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaxMutation,
  DeleteTaxMutationVariables
>
export const FetchChargeDetailsFromTmsDocument = gql`
  mutation fetchChargeDetailsFromTms($jobId: UUID!) {
    fetchChargeDetailsFromTms(jobId: $jobId) {
      findShipmentReconResults {
        id
        success
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        referenceNo
        hblNo
        mblNo
        carrierBookingNo
        containerNo
        orderNo
        consolNo
        chainIoShipment {
          id
          forwarderReference
          dateModified
          houseBill
          masterBill
          carrierBookingNumber
          bookingReference
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          orders {
            edges {
              node {
                id
                orderReference
                sequence
              }
            }
          }
          chainIoConsolidations {
            edges {
              node {
                id
                forwarderReference
              }
            }
          }
          additionalReferences {
            edges {
              node {
                id
                referenceType
                referenceNumber
              }
            }
          }
          customFields {
            edges {
              node {
                id
                key
                value
              }
            }
          }
          shipmentLegs {
            edges {
              node {
                id
                legSequenceNumber
                transportMode
                legType
                vesselName
                voyageNumber
                ladingPort {
                  id
                  unlocode
                }
                arrivalPort {
                  id
                  unlocode
                }
                departureEstimated
                arrivalEstimated
                legCarrier {
                  id
                  name
                }
                bookingStatus
              }
            }
          }
          packingLines {
            edges {
              node {
                id
                packs
                packsUom
                containerNumber
                weight
                weightUom
                volume
                volumeUom
                referenceNumber
                importReferenceNumber
                exportReferenceNumber
                descriptionOfGoods
                marksAndNumbers
              }
            }
          }
          localClient {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          shipper {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          consignee {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          supplier {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          importer {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          pickupTransportCompany {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          deliveryTransportCompany {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          pickupFrom {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          deliveryTo {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
        }
        chainIoConsolidation {
          id
          forwarderReference
          dateModified
          masterBill
          carrierBookingRef
          consolCosts {
            edges {
              node {
                id
                chargeCode
                chargeDescription
                amount
                currency
                creditorCode
                invoiceNumber
                taxRateCode
                taxRate
                isPosted
              }
            }
          }
          containers {
            edges {
              node {
                id
                containerNumber
                containerCount
                seal1
                containerMode
                typeCode
                deliveryMode
                sealedBy
              }
            }
          }
          shipmentLegs {
            edges {
              node {
                id
                legSequenceNumber
                transportMode
                legType
                vesselName
                voyageNumber
                ladingPort {
                  id
                  unlocode
                }
                arrivalPort {
                  id
                  unlocode
                }
                departureEstimated
                arrivalEstimated
                legCarrier {
                  id
                  name
                }
                bookingStatus
              }
            }
          }
          shipments {
            edges {
              node {
                id
                forwarderReference
                houseBill
                orders {
                  edges {
                    node {
                      id
                      orderReference
                    }
                  }
                }
              }
            }
          }
          carrier {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          creditor {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          coloadWith {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          receivingAgent {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          sendingAgent {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
        }
        chainIoCustomsDeclaration {
          id
          forwarderReference
          dateModified
          houseBill
          masterBill
          bookingReference
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          chainIoShipment {
            id
            carrierBookingNumber
            forwarderReference
            chainIoConsolidations {
              edges {
                node {
                  id
                  forwarderReference
                }
              }
            }
          }
          orders {
            edges {
              node {
                id
                orderReference
              }
            }
          }
        }
        findShipmentDuplicateReconResults {
          edges {
            node {
              id
              chainIoShipment {
                id
                forwarderReference
                houseBill
                isCancelled
                tmsId
                masterBill
                bookingReference
                containers {
                  edges {
                    node {
                      id
                      containerNumber
                    }
                  }
                }
                chainIoConsolidation {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                orders {
                  edges {
                    node {
                      id
                      orderReference
                    }
                  }
                }
              }
              chainIoConsolidation {
                id
                isCancelled
                tmsId
                forwarderReference
              }
              chainIoCustomsDeclaration {
                id
                isCancelled
                tmsId
                forwarderReference
              }
            }
          }
        }
      }
      expectedCharges {
        id
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
        isPosted
        overseasSellAmount
        sellCurrency
      }
      shipmentStaffReconResults {
        id
        operationsStaffName
        operationsStaffCode
        salesStaffName
        salesStaffCode
      }
      metadataReconResults {
        id
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        key {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on MetadataReconResultKeyEnum {
            value
            isFallback
          }
        }
        invoiceValue
        expectedValue
      }
    }
  }
`
export type FetchChargeDetailsFromTmsMutationFn = Apollo.MutationFunction<
  FetchChargeDetailsFromTmsMutation,
  FetchChargeDetailsFromTmsMutationVariables
>

/**
 * __useFetchChargeDetailsFromTmsMutation__
 *
 * To run a mutation, you first call `useFetchChargeDetailsFromTmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchChargeDetailsFromTmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchChargeDetailsFromTmsMutation, { data, loading, error }] = useFetchChargeDetailsFromTmsMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useFetchChargeDetailsFromTmsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FetchChargeDetailsFromTmsMutation,
    FetchChargeDetailsFromTmsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FetchChargeDetailsFromTmsMutation,
    FetchChargeDetailsFromTmsMutationVariables
  >(FetchChargeDetailsFromTmsDocument, options)
}
export type FetchChargeDetailsFromTmsMutationHookResult = ReturnType<
  typeof useFetchChargeDetailsFromTmsMutation
>
export type FetchChargeDetailsFromTmsMutationResult =
  Apollo.MutationResult<FetchChargeDetailsFromTmsMutation>
export type FetchChargeDetailsFromTmsMutationOptions = Apollo.BaseMutationOptions<
  FetchChargeDetailsFromTmsMutation,
  FetchChargeDetailsFromTmsMutationVariables
>
export const UpdateRisrAndRmcDocument = gql`
  mutation updateRisrAndRmc($inputReconInfoObjects: [InputReconInfoObject!], $jobId: UUID!) {
    updateRisrAndRmc(inputReconInfoObjects: $inputReconInfoObjects, jobId: $jobId)
  }
`
export type UpdateRisrAndRmcMutationFn = Apollo.MutationFunction<
  UpdateRisrAndRmcMutation,
  UpdateRisrAndRmcMutationVariables
>

/**
 * __useUpdateRisrAndRmcMutation__
 *
 * To run a mutation, you first call `useUpdateRisrAndRmcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRisrAndRmcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRisrAndRmcMutation, { data, loading, error }] = useUpdateRisrAndRmcMutation({
 *   variables: {
 *      inputReconInfoObjects: // value for 'inputReconInfoObjects'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useUpdateRisrAndRmcMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRisrAndRmcMutation,
    UpdateRisrAndRmcMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateRisrAndRmcMutation, UpdateRisrAndRmcMutationVariables>(
    UpdateRisrAndRmcDocument,
    options,
  )
}
export type UpdateRisrAndRmcMutationHookResult = ReturnType<typeof useUpdateRisrAndRmcMutation>
export type UpdateRisrAndRmcMutationResult = Apollo.MutationResult<UpdateRisrAndRmcMutation>
export type UpdateRisrAndRmcMutationOptions = Apollo.BaseMutationOptions<
  UpdateRisrAndRmcMutation,
  UpdateRisrAndRmcMutationVariables
>
export const ReconcileSoaJobDocument = gql`
  mutation reconcileSoaJob(
    $jobId: UUID!
    $overrideChargeDescription: Boolean!
    $matchingCriteria: MatchingCriteriaType!
    $asLumpsum: Boolean!
  ) {
    reconcileSoaJob(
      jobId: $jobId
      overrideChargeDescription: $overrideChargeDescription
      matchingCriteria: $matchingCriteria
      asLumpsum: $asLumpsum
    ) {
      reconAsyncBatchId
    }
  }
`
export type ReconcileSoaJobMutationFn = Apollo.MutationFunction<
  ReconcileSoaJobMutation,
  ReconcileSoaJobMutationVariables
>

/**
 * __useReconcileSoaJobMutation__
 *
 * To run a mutation, you first call `useReconcileSoaJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconcileSoaJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconcileSoaJobMutation, { data, loading, error }] = useReconcileSoaJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      overrideChargeDescription: // value for 'overrideChargeDescription'
 *      matchingCriteria: // value for 'matchingCriteria'
 *      asLumpsum: // value for 'asLumpsum'
 *   },
 * });
 */
export function useReconcileSoaJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReconcileSoaJobMutation,
    ReconcileSoaJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReconcileSoaJobMutation, ReconcileSoaJobMutationVariables>(
    ReconcileSoaJobDocument,
    options,
  )
}
export type ReconcileSoaJobMutationHookResult = ReturnType<typeof useReconcileSoaJobMutation>
export type ReconcileSoaJobMutationResult = Apollo.MutationResult<ReconcileSoaJobMutation>
export type ReconcileSoaJobMutationOptions = Apollo.BaseMutationOptions<
  ReconcileSoaJobMutation,
  ReconcileSoaJobMutationVariables
>
export const CreateTaskDocument = gql`
  mutation createTask(
    $companyId: String!
    $dateReceived: String!
    $message: String!
    $starred: Boolean!
    $blocked: Boolean!
    $status: String!
    $taskReferenceId: String!
    $taskType: String!
    $title: String!
  ) {
    createTask(
      companyId: $companyId
      dateReceived: $dateReceived
      message: $message
      starred: $starred
      blocked: $blocked
      status: $status
      taskReferenceId: $taskReferenceId
      taskType: $taskType
      title: $title
    ) {
      task {
        id
        title
        taskReferenceId
        message
        dateReceived
        company {
          id
          name
        }
      }
    }
  }
`
export type CreateTaskMutationFn = Apollo.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      dateReceived: // value for 'dateReceived'
 *      message: // value for 'message'
 *      starred: // value for 'starred'
 *      blocked: // value for 'blocked'
 *      status: // value for 'status'
 *      taskReferenceId: // value for 'taskReferenceId'
 *      taskType: // value for 'taskType'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CreateTaskDocument,
    options,
  )
}
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>
export const UpdateTaskStatusDocument = gql`
  mutation UpdateTaskStatus($taskId: String!, $status: String!) {
    updateTaskStatus(taskId: $taskId, status: $status) {
      task {
        id
        status
      }
    }
  }
`
export type UpdateTaskStatusMutationFn = Apollo.MutationFunction<
  UpdateTaskStatusMutation,
  UpdateTaskStatusMutationVariables
>

/**
 * __useUpdateTaskStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskStatusMutation, { data, loading, error }] = useUpdateTaskStatusMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskStatusMutation,
    UpdateTaskStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTaskStatusMutation, UpdateTaskStatusMutationVariables>(
    UpdateTaskStatusDocument,
    options,
  )
}
export type UpdateTaskStatusMutationHookResult = ReturnType<typeof useUpdateTaskStatusMutation>
export type UpdateTaskStatusMutationResult = Apollo.MutationResult<UpdateTaskStatusMutation>
export type UpdateTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskStatusMutation,
  UpdateTaskStatusMutationVariables
>
export const DeleteTaskDocument = gql`
  mutation DeleteTask($taskId: String!) {
    deleteTask(taskId: $taskId) {
      ok
    }
  }
`
export type DeleteTaskMutationFn = Apollo.MutationFunction<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DeleteTaskDocument,
    options,
  )
}
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>
export const UpdateTaskDocument = gql`
  mutation updateTask(
    $taskId: String!
    $title: String!
    $dateReceived: String!
    $message: String
    $taskReferenceId: String!
    $dateConfirmed: String
  ) {
    updateTask(
      taskId: $taskId
      title: $title
      dateReceived: $dateReceived
      message: $message
      taskReferenceId: $taskReferenceId
      dateConfirmed: $dateConfirmed
    ) {
      task {
        id
        status
        taskType
        message
        dateConfirmed
        dateReceived
        taskReferenceId
      }
    }
  }
`
export type UpdateTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      title: // value for 'title'
 *      dateReceived: // value for 'dateReceived'
 *      message: // value for 'message'
 *      taskReferenceId: // value for 'taskReferenceId'
 *      dateConfirmed: // value for 'dateConfirmed'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UpdateTaskDocument,
    options,
  )
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>
export const UpdateTaskTypeDocument = gql`
  mutation updateTaskType($taskId: String!, $taskType: String!) {
    updateTaskType(taskId: $taskId, taskType: $taskType) {
      task {
        id
        taskType
      }
    }
  }
`
export type UpdateTaskTypeMutationFn = Apollo.MutationFunction<
  UpdateTaskTypeMutation,
  UpdateTaskTypeMutationVariables
>

/**
 * __useUpdateTaskTypeMutation__
 *
 * To run a mutation, you first call `useUpdateTaskTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskTypeMutation, { data, loading, error }] = useUpdateTaskTypeMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      taskType: // value for 'taskType'
 *   },
 * });
 */
export function useUpdateTaskTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTaskTypeMutation, UpdateTaskTypeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTaskTypeMutation, UpdateTaskTypeMutationVariables>(
    UpdateTaskTypeDocument,
    options,
  )
}
export type UpdateTaskTypeMutationHookResult = ReturnType<typeof useUpdateTaskTypeMutation>
export type UpdateTaskTypeMutationResult = Apollo.MutationResult<UpdateTaskTypeMutation>
export type UpdateTaskTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskTypeMutation,
  UpdateTaskTypeMutationVariables
>
export const UpdateTaskCompanyDocument = gql`
  mutation updateTaskCompany($taskId: String!, $companyId: String!) {
    updateTaskCompany(taskId: $taskId, companyId: $companyId) {
      task {
        id
        company {
          id
          name
        }
      }
    }
  }
`
export type UpdateTaskCompanyMutationFn = Apollo.MutationFunction<
  UpdateTaskCompanyMutation,
  UpdateTaskCompanyMutationVariables
>

/**
 * __useUpdateTaskCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateTaskCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskCompanyMutation, { data, loading, error }] = useUpdateTaskCompanyMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUpdateTaskCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskCompanyMutation,
    UpdateTaskCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTaskCompanyMutation, UpdateTaskCompanyMutationVariables>(
    UpdateTaskCompanyDocument,
    options,
  )
}
export type UpdateTaskCompanyMutationHookResult = ReturnType<typeof useUpdateTaskCompanyMutation>
export type UpdateTaskCompanyMutationResult = Apollo.MutationResult<UpdateTaskCompanyMutation>
export type UpdateTaskCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskCompanyMutation,
  UpdateTaskCompanyMutationVariables
>
export const StarTaskDocument = gql`
  mutation starTask($taskId: String!, $starred: Boolean!) {
    starTask(taskId: $taskId, starred: $starred) {
      task {
        id
      }
    }
  }
`
export type StarTaskMutationFn = Apollo.MutationFunction<
  StarTaskMutation,
  StarTaskMutationVariables
>

/**
 * __useStarTaskMutation__
 *
 * To run a mutation, you first call `useStarTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStarTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [starTaskMutation, { data, loading, error }] = useStarTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      starred: // value for 'starred'
 *   },
 * });
 */
export function useStarTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<StarTaskMutation, StarTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<StarTaskMutation, StarTaskMutationVariables>(StarTaskDocument, options)
}
export type StarTaskMutationHookResult = ReturnType<typeof useStarTaskMutation>
export type StarTaskMutationResult = Apollo.MutationResult<StarTaskMutation>
export type StarTaskMutationOptions = Apollo.BaseMutationOptions<
  StarTaskMutation,
  StarTaskMutationVariables
>
export const BlockTaskDocument = gql`
  mutation blockTask($taskId: String!, $blocked: Boolean!, $dateReceived: String) {
    blockTask(taskId: $taskId, blocked: $blocked, dateReceived: $dateReceived) {
      task {
        id
        status
        blocked
        dateReceived
        dateConfirmed
        jobs {
          edges {
            node {
              id
              status
            }
          }
        }
      }
    }
  }
`
export type BlockTaskMutationFn = Apollo.MutationFunction<
  BlockTaskMutation,
  BlockTaskMutationVariables
>

/**
 * __useBlockTaskMutation__
 *
 * To run a mutation, you first call `useBlockTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockTaskMutation, { data, loading, error }] = useBlockTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      blocked: // value for 'blocked'
 *      dateReceived: // value for 'dateReceived'
 *   },
 * });
 */
export function useBlockTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<BlockTaskMutation, BlockTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BlockTaskMutation, BlockTaskMutationVariables>(
    BlockTaskDocument,
    options,
  )
}
export type BlockTaskMutationHookResult = ReturnType<typeof useBlockTaskMutation>
export type BlockTaskMutationResult = Apollo.MutationResult<BlockTaskMutation>
export type BlockTaskMutationOptions = Apollo.BaseMutationOptions<
  BlockTaskMutation,
  BlockTaskMutationVariables
>
export const CreateTaskNoteDocument = gql`
  mutation createTaskNote($taskId: String!, $content: String!) {
    createTaskNote(taskId: $taskId, content: $content) {
      taskNote {
        ...taskNote
      }
    }
  }
  ${TaskNoteFragmentDoc}
`
export type CreateTaskNoteMutationFn = Apollo.MutationFunction<
  CreateTaskNoteMutation,
  CreateTaskNoteMutationVariables
>

/**
 * __useCreateTaskNoteMutation__
 *
 * To run a mutation, you first call `useCreateTaskNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskNoteMutation, { data, loading, error }] = useCreateTaskNoteMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateTaskNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaskNoteMutation, CreateTaskNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTaskNoteMutation, CreateTaskNoteMutationVariables>(
    CreateTaskNoteDocument,
    options,
  )
}
export type CreateTaskNoteMutationHookResult = ReturnType<typeof useCreateTaskNoteMutation>
export type CreateTaskNoteMutationResult = Apollo.MutationResult<CreateTaskNoteMutation>
export type CreateTaskNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskNoteMutation,
  CreateTaskNoteMutationVariables
>
export const DeleteTaskNoteDocument = gql`
  mutation deleteTaskNote($taskNoteId: String!) {
    deleteTaskNote(taskNoteId: $taskNoteId) {
      ok
    }
  }
`
export type DeleteTaskNoteMutationFn = Apollo.MutationFunction<
  DeleteTaskNoteMutation,
  DeleteTaskNoteMutationVariables
>

/**
 * __useDeleteTaskNoteMutation__
 *
 * To run a mutation, you first call `useDeleteTaskNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskNoteMutation, { data, loading, error }] = useDeleteTaskNoteMutation({
 *   variables: {
 *      taskNoteId: // value for 'taskNoteId'
 *   },
 * });
 */
export function useDeleteTaskNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTaskNoteMutation, DeleteTaskNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTaskNoteMutation, DeleteTaskNoteMutationVariables>(
    DeleteTaskNoteDocument,
    options,
  )
}
export type DeleteTaskNoteMutationHookResult = ReturnType<typeof useDeleteTaskNoteMutation>
export type DeleteTaskNoteMutationResult = Apollo.MutationResult<DeleteTaskNoteMutation>
export type DeleteTaskNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskNoteMutation,
  DeleteTaskNoteMutationVariables
>
export const SearchedCompaniesDocument = gql`
  query searchedCompanies($query: String!) {
    searchedForCompanies(query: $query) {
      id
      name
      jobTemplates {
        edges {
          node {
            id
            name
            dateDeleted
          }
        }
      }
    }
  }
`

/**
 * __useSearchedCompaniesQuery__
 *
 * To run a query within a React component, call `useSearchedCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchedCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchedCompaniesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchedCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<SearchedCompaniesQuery, SearchedCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchedCompaniesQuery, SearchedCompaniesQueryVariables>(
    SearchedCompaniesDocument,
    options,
  )
}
export function useSearchedCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchedCompaniesQuery,
    SearchedCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchedCompaniesQuery, SearchedCompaniesQueryVariables>(
    SearchedCompaniesDocument,
    options,
  )
}
export type SearchedCompaniesQueryHookResult = ReturnType<typeof useSearchedCompaniesQuery>
export type SearchedCompaniesLazyQueryHookResult = ReturnType<typeof useSearchedCompaniesLazyQuery>
export type SearchedCompaniesQueryResult = Apollo.QueryResult<
  SearchedCompaniesQuery,
  SearchedCompaniesQueryVariables
>
export const ApiPartnersDocument = gql`
  query apiPartners {
    apiPartners {
      id
      apiKey
      exportEndpoint
      importEndpoint
      documentExportEndpoint
      documentImportEndpoint
      name
      countryCode
      expedockDocIngestEmail
      apiPartnerType
      authValues
      tmsType {
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
        ... on ApiPartnerTmsTypeEnum {
          value
          isFallback
        }
      }
      credential {
        id
        username
        workflow
      }
      company {
        id
        name
      }
      jobTemplates {
        edges {
          node {
            id
            name
            company {
              id
              name
            }
          }
        }
      }
    }
  }
`

/**
 * __useApiPartnersQuery__
 *
 * To run a query within a React component, call `useApiPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiPartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiPartnersQuery(
  baseOptions?: Apollo.QueryHookOptions<ApiPartnersQuery, ApiPartnersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ApiPartnersQuery, ApiPartnersQueryVariables>(ApiPartnersDocument, options)
}
export function useApiPartnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApiPartnersQuery, ApiPartnersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ApiPartnersQuery, ApiPartnersQueryVariables>(
    ApiPartnersDocument,
    options,
  )
}
export type ApiPartnersQueryHookResult = ReturnType<typeof useApiPartnersQuery>
export type ApiPartnersLazyQueryHookResult = ReturnType<typeof useApiPartnersLazyQuery>
export type ApiPartnersQueryResult = Apollo.QueryResult<ApiPartnersQuery, ApiPartnersQueryVariables>
export const GetApiEDocumentsFromJobDocument = gql`
  query getApiEDocumentsFromJob($jobId: String!) {
    apiEDocuments(jobId: $jobId) {
      id
      s3Uri
      eDocUrl
      filename
      apiExportEdocActivity {
        id
      }
      apiImportEdocActivity {
        id
        apiEntityType
      }
    }
  }
`

/**
 * __useGetApiEDocumentsFromJobQuery__
 *
 * To run a query within a React component, call `useGetApiEDocumentsFromJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiEDocumentsFromJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiEDocumentsFromJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetApiEDocumentsFromJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApiEDocumentsFromJobQuery,
    GetApiEDocumentsFromJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetApiEDocumentsFromJobQuery, GetApiEDocumentsFromJobQueryVariables>(
    GetApiEDocumentsFromJobDocument,
    options,
  )
}
export function useGetApiEDocumentsFromJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApiEDocumentsFromJobQuery,
    GetApiEDocumentsFromJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetApiEDocumentsFromJobQuery, GetApiEDocumentsFromJobQueryVariables>(
    GetApiEDocumentsFromJobDocument,
    options,
  )
}
export type GetApiEDocumentsFromJobQueryHookResult = ReturnType<
  typeof useGetApiEDocumentsFromJobQuery
>
export type GetApiEDocumentsFromJobLazyQueryHookResult = ReturnType<
  typeof useGetApiEDocumentsFromJobLazyQuery
>
export type GetApiEDocumentsFromJobQueryResult = Apollo.QueryResult<
  GetApiEDocumentsFromJobQuery,
  GetApiEDocumentsFromJobQueryVariables
>
export const ApiIngestionAttachmentSignedUrlDocument = gql`
  query apiIngestionAttachmentSignedUrl($apiIngestionAttachmentId: UUID!) {
    apiIngestionAttachmentSignedUrl(apiIngestionAttachmentId: $apiIngestionAttachmentId)
  }
`

/**
 * __useApiIngestionAttachmentSignedUrlQuery__
 *
 * To run a query within a React component, call `useApiIngestionAttachmentSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiIngestionAttachmentSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiIngestionAttachmentSignedUrlQuery({
 *   variables: {
 *      apiIngestionAttachmentId: // value for 'apiIngestionAttachmentId'
 *   },
 * });
 */
export function useApiIngestionAttachmentSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiIngestionAttachmentSignedUrlQuery,
    ApiIngestionAttachmentSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ApiIngestionAttachmentSignedUrlQuery,
    ApiIngestionAttachmentSignedUrlQueryVariables
  >(ApiIngestionAttachmentSignedUrlDocument, options)
}
export function useApiIngestionAttachmentSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiIngestionAttachmentSignedUrlQuery,
    ApiIngestionAttachmentSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ApiIngestionAttachmentSignedUrlQuery,
    ApiIngestionAttachmentSignedUrlQueryVariables
  >(ApiIngestionAttachmentSignedUrlDocument, options)
}
export type ApiIngestionAttachmentSignedUrlQueryHookResult = ReturnType<
  typeof useApiIngestionAttachmentSignedUrlQuery
>
export type ApiIngestionAttachmentSignedUrlLazyQueryHookResult = ReturnType<
  typeof useApiIngestionAttachmentSignedUrlLazyQuery
>
export type ApiIngestionAttachmentSignedUrlQueryResult = Apollo.QueryResult<
  ApiIngestionAttachmentSignedUrlQuery,
  ApiIngestionAttachmentSignedUrlQueryVariables
>
export const FindChainIoMatchesFromSoaLineItemsDocument = gql`
  query findChainIoMatchesFromSoaLineItems(
    $jobId: UUID!
    $inputSoaLineItems: [InputSOALineItem!]!
  ) {
    findChainIoMatchesFromSoaLineItems(jobId: $jobId, inputSoaLineItems: $inputSoaLineItems) {
      chainIoShipments {
        id
        forwarderReference
      }
      chainIoConsolidations {
        id
        forwarderReference
      }
      chainIoCustomsDeclarations {
        id
        forwarderReference
      }
    }
  }
`

/**
 * __useFindChainIoMatchesFromSoaLineItemsQuery__
 *
 * To run a query within a React component, call `useFindChainIoMatchesFromSoaLineItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindChainIoMatchesFromSoaLineItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindChainIoMatchesFromSoaLineItemsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      inputSoaLineItems: // value for 'inputSoaLineItems'
 *   },
 * });
 */
export function useFindChainIoMatchesFromSoaLineItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindChainIoMatchesFromSoaLineItemsQuery,
    FindChainIoMatchesFromSoaLineItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindChainIoMatchesFromSoaLineItemsQuery,
    FindChainIoMatchesFromSoaLineItemsQueryVariables
  >(FindChainIoMatchesFromSoaLineItemsDocument, options)
}
export function useFindChainIoMatchesFromSoaLineItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindChainIoMatchesFromSoaLineItemsQuery,
    FindChainIoMatchesFromSoaLineItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindChainIoMatchesFromSoaLineItemsQuery,
    FindChainIoMatchesFromSoaLineItemsQueryVariables
  >(FindChainIoMatchesFromSoaLineItemsDocument, options)
}
export type FindChainIoMatchesFromSoaLineItemsQueryHookResult = ReturnType<
  typeof useFindChainIoMatchesFromSoaLineItemsQuery
>
export type FindChainIoMatchesFromSoaLineItemsLazyQueryHookResult = ReturnType<
  typeof useFindChainIoMatchesFromSoaLineItemsLazyQuery
>
export type FindChainIoMatchesFromSoaLineItemsQueryResult = Apollo.QueryResult<
  FindChainIoMatchesFromSoaLineItemsQuery,
  FindChainIoMatchesFromSoaLineItemsQueryVariables
>
export const GetEDocumentsFromJobDocument = gql`
  query getEDocumentsFromJob($jobId: String!) {
    eDocuments(jobId: $jobId) {
      id
      path
      eDocUrl
      filename
      dataTargetType {
        ... on EDocsDataTargetTypeEnum {
          value
          isFallback
        }
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
      }
      referenceNumber
      isPublished
      cargowiseFileType {
        id
        code
        description
      }
      filePage {
        id
        isInCw
        document {
          id
          documentType {
            id
            cargowiseFileType {
              id
              code
              description
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetEDocumentsFromJobQuery__
 *
 * To run a query within a React component, call `useGetEDocumentsFromJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEDocumentsFromJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEDocumentsFromJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetEDocumentsFromJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEDocumentsFromJobQuery,
    GetEDocumentsFromJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEDocumentsFromJobQuery, GetEDocumentsFromJobQueryVariables>(
    GetEDocumentsFromJobDocument,
    options,
  )
}
export function useGetEDocumentsFromJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEDocumentsFromJobQuery,
    GetEDocumentsFromJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetEDocumentsFromJobQuery, GetEDocumentsFromJobQueryVariables>(
    GetEDocumentsFromJobDocument,
    options,
  )
}
export type GetEDocumentsFromJobQueryHookResult = ReturnType<typeof useGetEDocumentsFromJobQuery>
export type GetEDocumentsFromJobLazyQueryHookResult = ReturnType<
  typeof useGetEDocumentsFromJobLazyQuery
>
export type GetEDocumentsFromJobQueryResult = Apollo.QueryResult<
  GetEDocumentsFromJobQuery,
  GetEDocumentsFromJobQueryVariables
>
export const CargowiseConfigsDocument = gql`
  query cargowiseConfigs {
    cargowiseConfigs {
      id
      name
      defaultTimezoneLocationId
      defaultTimezoneLocation {
        id
        code
        locodeName
        timezoneName
      }
      code
      countryCode
      enterpriseId
      serverId
      integrationId
      outboundPass
      dbPass
      dbUser
      dbName
      dbDirectConnect
      remoteDbHostname
      remoteDbPort
      pollDatabase
      proxyUrls
      connectorType
      forceFetch
      externApiCredential {
        id
        username
        workflow
      }
      company {
        id
        name
      }
      jobTemplates {
        edges {
          node {
            id
            name
            company {
              id
              name
            }
          }
        }
      }
    }
  }
`

/**
 * __useCargowiseConfigsQuery__
 *
 * To run a query within a React component, call `useCargowiseConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCargowiseConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCargowiseConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCargowiseConfigsQuery(
  baseOptions?: Apollo.QueryHookOptions<CargowiseConfigsQuery, CargowiseConfigsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CargowiseConfigsQuery, CargowiseConfigsQueryVariables>(
    CargowiseConfigsDocument,
    options,
  )
}
export function useCargowiseConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CargowiseConfigsQuery, CargowiseConfigsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CargowiseConfigsQuery, CargowiseConfigsQueryVariables>(
    CargowiseConfigsDocument,
    options,
  )
}
export type CargowiseConfigsQueryHookResult = ReturnType<typeof useCargowiseConfigsQuery>
export type CargowiseConfigsLazyQueryHookResult = ReturnType<typeof useCargowiseConfigsLazyQuery>
export type CargowiseConfigsQueryResult = Apollo.QueryResult<
  CargowiseConfigsQuery,
  CargowiseConfigsQueryVariables
>
export const CargowiseFileTypesDocument = gql`
  query cargowiseFileTypes($companyId: UUID!) {
    cargowiseFileTypes(companyId: $companyId) {
      id
      code
    }
  }
`

/**
 * __useCargowiseFileTypesQuery__
 *
 * To run a query within a React component, call `useCargowiseFileTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCargowiseFileTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCargowiseFileTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCargowiseFileTypesQuery(
  baseOptions: Apollo.QueryHookOptions<CargowiseFileTypesQuery, CargowiseFileTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CargowiseFileTypesQuery, CargowiseFileTypesQueryVariables>(
    CargowiseFileTypesDocument,
    options,
  )
}
export function useCargowiseFileTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CargowiseFileTypesQuery,
    CargowiseFileTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CargowiseFileTypesQuery, CargowiseFileTypesQueryVariables>(
    CargowiseFileTypesDocument,
    options,
  )
}
export type CargowiseFileTypesQueryHookResult = ReturnType<typeof useCargowiseFileTypesQuery>
export type CargowiseFileTypesLazyQueryHookResult = ReturnType<
  typeof useCargowiseFileTypesLazyQuery
>
export type CargowiseFileTypesQueryResult = Apollo.QueryResult<
  CargowiseFileTypesQuery,
  CargowiseFileTypesQueryVariables
>
export const TransactionAsyncBatchDocument = gql`
  query transactionAsyncBatch($transactionAsyncBatchId: String!) {
    transactionAsyncBatch(transactionAsyncBatchId: $transactionAsyncBatchId) {
      id
      jobId
      numTasks
      numDone
      status
      transactionAsyncTasks {
        edges {
          node {
            id
            status
            invoiceNumber
            errorMessage
            processingLog
            cargowiseModule
          }
        }
      }
    }
  }
`

/**
 * __useTransactionAsyncBatchQuery__
 *
 * To run a query within a React component, call `useTransactionAsyncBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionAsyncBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionAsyncBatchQuery({
 *   variables: {
 *      transactionAsyncBatchId: // value for 'transactionAsyncBatchId'
 *   },
 * });
 */
export function useTransactionAsyncBatchQuery(
  baseOptions: Apollo.QueryHookOptions<
    TransactionAsyncBatchQuery,
    TransactionAsyncBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TransactionAsyncBatchQuery, TransactionAsyncBatchQueryVariables>(
    TransactionAsyncBatchDocument,
    options,
  )
}
export function useTransactionAsyncBatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransactionAsyncBatchQuery,
    TransactionAsyncBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TransactionAsyncBatchQuery, TransactionAsyncBatchQueryVariables>(
    TransactionAsyncBatchDocument,
    options,
  )
}
export type TransactionAsyncBatchQueryHookResult = ReturnType<typeof useTransactionAsyncBatchQuery>
export type TransactionAsyncBatchLazyQueryHookResult = ReturnType<
  typeof useTransactionAsyncBatchLazyQuery
>
export type TransactionAsyncBatchQueryResult = Apollo.QueryResult<
  TransactionAsyncBatchQuery,
  TransactionAsyncBatchQueryVariables
>
export const CheckShipmentInfoAsyncBatchDocument = gql`
  query checkShipmentInfoAsyncBatch($checkShipmentInfoAsyncBatchId: UUID!) {
    checkShipmentInfoAsyncBatch(checkShipmentInfoAsyncBatchId: $checkShipmentInfoAsyncBatchId) {
      id
      jobId
      numTasks
      numDone
      status
      errorMessage
      dateCreated
    }
  }
`

/**
 * __useCheckShipmentInfoAsyncBatchQuery__
 *
 * To run a query within a React component, call `useCheckShipmentInfoAsyncBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckShipmentInfoAsyncBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckShipmentInfoAsyncBatchQuery({
 *   variables: {
 *      checkShipmentInfoAsyncBatchId: // value for 'checkShipmentInfoAsyncBatchId'
 *   },
 * });
 */
export function useCheckShipmentInfoAsyncBatchQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckShipmentInfoAsyncBatchQuery,
    CheckShipmentInfoAsyncBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CheckShipmentInfoAsyncBatchQuery,
    CheckShipmentInfoAsyncBatchQueryVariables
  >(CheckShipmentInfoAsyncBatchDocument, options)
}
export function useCheckShipmentInfoAsyncBatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckShipmentInfoAsyncBatchQuery,
    CheckShipmentInfoAsyncBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CheckShipmentInfoAsyncBatchQuery,
    CheckShipmentInfoAsyncBatchQueryVariables
  >(CheckShipmentInfoAsyncBatchDocument, options)
}
export type CheckShipmentInfoAsyncBatchQueryHookResult = ReturnType<
  typeof useCheckShipmentInfoAsyncBatchQuery
>
export type CheckShipmentInfoAsyncBatchLazyQueryHookResult = ReturnType<
  typeof useCheckShipmentInfoAsyncBatchLazyQuery
>
export type CheckShipmentInfoAsyncBatchQueryResult = Apollo.QueryResult<
  CheckShipmentInfoAsyncBatchQuery,
  CheckShipmentInfoAsyncBatchQueryVariables
>
export const SoaJobExpectedChargesDocument = gql`
  query soaJobExpectedCharges($checkShipmentInfoAsyncBatchId: UUID!) {
    soaJobExpectedCharges(checkShipmentInfoAsyncBatchId: $checkShipmentInfoAsyncBatchId) {
      invoiceNumber
      shipmentNumber
      matchCriteriaFindShipmentReconResultPairList {
        checkShipmentInfoMatchCriteria {
          id
          chainIoShipment {
            id
            forwarderReference
          }
          referenceNumber
          hblNumber
          orderNumber
          consolNumber
          mblNumber
          carrierBookingNumber
          containerNumber
          cargowiseModule
          errorMessage
        }
        successfulFindShipmentReconResult {
          id
          success
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          referenceNo
          hblNo
          mblNo
          carrierBookingNo
          containerNo
          consolNo
          orderNo
          isMaximalAmongDuplicates
          chainIoShipment {
            id
            forwarderReference
            dateModified
            houseBill
            masterBill
            carrierBookingNumber
            bookingReference
            containers {
              edges {
                node {
                  id
                  containerNumber
                }
              }
            }
            chainIoConsolidations {
              edges {
                node {
                  id
                  forwarderReference
                }
              }
            }
            orders {
              edges {
                node {
                  id
                  orderReference
                  sequence
                  orderNumberSplit
                  orderStatus
                  orderDate
                  orderBuyer {
                    id
                    sourcePartyId
                  }
                  orderSupplier {
                    id
                    sourcePartyId
                  }
                  orderLines {
                    edges {
                      node {
                        id
                        orderLineNumber
                        partNumber
                        description
                        containerNumber
                      }
                    }
                  }
                }
              }
            }
            additionalReferences {
              edges {
                node {
                  id
                  referenceType
                  referenceNumber
                }
              }
            }
            customFields {
              edges {
                node {
                  id
                  key
                  value
                }
              }
            }
            shipmentLegs {
              edges {
                node {
                  id
                  legSequenceNumber
                  transportMode
                  legType
                  vesselName
                  voyageNumber
                  ladingPort {
                    id
                    unlocode
                  }
                  arrivalPort {
                    id
                    unlocode
                  }
                  departureEstimated
                  arrivalEstimated
                  legCarrier {
                    id
                    name
                  }
                  bookingStatus
                }
              }
            }
            packingLines {
              edges {
                node {
                  id
                  packs
                  packsUom
                  containerNumber
                  weight
                  weightUom
                  volume
                  volumeUom
                  referenceNumber
                  importReferenceNumber
                  exportReferenceNumber
                  descriptionOfGoods
                  marksAndNumbers
                }
              }
            }
            localClient {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            shipper {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            consignee {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            supplier {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            importer {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            pickupTransportCompany {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            deliveryTransportCompany {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            pickupFrom {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            deliveryTo {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
          }
          chainIoConsolidation {
            id
            forwarderReference
            dateModified
            masterBill
            carrierBookingRef
            consolCosts {
              edges {
                node {
                  id
                  chargeCode
                  chargeDescription
                  amount
                  currency
                  creditorCode
                  invoiceNumber
                  taxRateCode
                  taxRate
                  isPosted
                }
              }
            }
            containers {
              edges {
                node {
                  id
                  containerNumber
                  containerCount
                  seal1
                  containerMode
                  typeCode
                  deliveryMode
                  sealedBy
                }
              }
            }
            shipmentLegs {
              edges {
                node {
                  id
                  legSequenceNumber
                  transportMode
                  legType
                  vesselName
                  voyageNumber
                  ladingPort {
                    id
                    unlocode
                  }
                  arrivalPort {
                    id
                    unlocode
                  }
                  departureEstimated
                  arrivalEstimated
                  legCarrier {
                    id
                    name
                  }
                  bookingStatus
                }
              }
            }
            shipments {
              edges {
                node {
                  id
                  forwarderReference
                  houseBill
                  orders {
                    edges {
                      node {
                        id
                        orderReference
                      }
                    }
                  }
                }
              }
            }
            carrier {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            creditor {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            coloadWith {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            receivingAgent {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
            sendingAgent {
              id
              name
              sourcePartyId
              unlocode
              address1
              address2
              city
              country
              state
            }
          }
          chainIoCustomsDeclaration {
            id
            forwarderReference
            dateModified
            houseBill
            masterBill
            bookingReference
            containers {
              edges {
                node {
                  id
                  containerNumber
                }
              }
            }
            chainIoShipment {
              id
              carrierBookingNumber
              forwarderReference
              chainIoConsolidations {
                edges {
                  node {
                    id
                    forwarderReference
                  }
                }
              }
            }
            orders {
              edges {
                node {
                  id
                  orderReference
                }
              }
            }
          }
          findShipmentDuplicateReconResults {
            edges {
              node {
                id
                chainIoShipment {
                  id
                  forwarderReference
                  houseBill
                  masterBill
                  bookingReference
                  isCancelled
                  tmsId
                  containers {
                    edges {
                      node {
                        id
                        containerNumber
                      }
                    }
                  }
                  chainIoConsolidation {
                    id
                    forwarderReference
                    isCancelled
                    tmsId
                  }
                  orders {
                    edges {
                      node {
                        id
                        orderReference
                      }
                    }
                  }
                }
                chainIoConsolidation {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                chainIoCustomsDeclaration {
                  id
                  isCancelled
                  forwarderReference
                  tmsId
                }
              }
            }
          }
        }
      }
      shipmentStaffReconResults {
        id
        operationsStaffName
        operationsStaffCode
        salesStaffName
        salesStaffCode
      }
      metadataReconResults {
        id
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        key {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on MetadataReconResultKeyEnum {
            value
            isFallback
          }
        }
        invoiceValue
        expectedValue
      }
      expectedCharges {
        id
        chainIoSiLineId
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
        isPosted
        overseasSellAmount
        sellCurrency
      }
    }
  }
`

/**
 * __useSoaJobExpectedChargesQuery__
 *
 * To run a query within a React component, call `useSoaJobExpectedChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoaJobExpectedChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoaJobExpectedChargesQuery({
 *   variables: {
 *      checkShipmentInfoAsyncBatchId: // value for 'checkShipmentInfoAsyncBatchId'
 *   },
 * });
 */
export function useSoaJobExpectedChargesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SoaJobExpectedChargesQuery,
    SoaJobExpectedChargesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SoaJobExpectedChargesQuery, SoaJobExpectedChargesQueryVariables>(
    SoaJobExpectedChargesDocument,
    options,
  )
}
export function useSoaJobExpectedChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SoaJobExpectedChargesQuery,
    SoaJobExpectedChargesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SoaJobExpectedChargesQuery, SoaJobExpectedChargesQueryVariables>(
    SoaJobExpectedChargesDocument,
    options,
  )
}
export type SoaJobExpectedChargesQueryHookResult = ReturnType<typeof useSoaJobExpectedChargesQuery>
export type SoaJobExpectedChargesLazyQueryHookResult = ReturnType<
  typeof useSoaJobExpectedChargesLazyQuery
>
export type SoaJobExpectedChargesQueryResult = Apollo.QueryResult<
  SoaJobExpectedChargesQuery,
  SoaJobExpectedChargesQueryVariables
>
export const FindConsolFromJobDocument = gql`
  query findConsolFromJob($jobId: UUID!) {
    consolWithMatchCriteria(jobId: $jobId) {
      chainIoConsol {
        id
        forwarderReference
      }
      consolNo
      mblNo
      containerNo
      carrierBookingNo
      hblNo
      shipmentNo
      orderNo
    }
  }
`

/**
 * __useFindConsolFromJobQuery__
 *
 * To run a query within a React component, call `useFindConsolFromJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindConsolFromJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindConsolFromJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useFindConsolFromJobQuery(
  baseOptions: Apollo.QueryHookOptions<FindConsolFromJobQuery, FindConsolFromJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindConsolFromJobQuery, FindConsolFromJobQueryVariables>(
    FindConsolFromJobDocument,
    options,
  )
}
export function useFindConsolFromJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindConsolFromJobQuery,
    FindConsolFromJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindConsolFromJobQuery, FindConsolFromJobQueryVariables>(
    FindConsolFromJobDocument,
    options,
  )
}
export type FindConsolFromJobQueryHookResult = ReturnType<typeof useFindConsolFromJobQuery>
export type FindConsolFromJobLazyQueryHookResult = ReturnType<typeof useFindConsolFromJobLazyQuery>
export type FindConsolFromJobQueryResult = Apollo.QueryResult<
  FindConsolFromJobQuery,
  FindConsolFromJobQueryVariables
>
export const FindShipmentFromJobDocument = gql`
  query findShipmentFromJob($jobId: UUID!) {
    shipmentWithMatchCriteria(jobId: $jobId) {
      chainIoShipment {
        id
        forwarderReference
      }
      shipmentNo
      hblNo
      consolNo
      mblNo
      carrierBookingNo
      containerNo
      orderNo
    }
  }
`

/**
 * __useFindShipmentFromJobQuery__
 *
 * To run a query within a React component, call `useFindShipmentFromJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShipmentFromJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShipmentFromJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useFindShipmentFromJobQuery(
  baseOptions: Apollo.QueryHookOptions<FindShipmentFromJobQuery, FindShipmentFromJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindShipmentFromJobQuery, FindShipmentFromJobQueryVariables>(
    FindShipmentFromJobDocument,
    options,
  )
}
export function useFindShipmentFromJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindShipmentFromJobQuery,
    FindShipmentFromJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindShipmentFromJobQuery, FindShipmentFromJobQueryVariables>(
    FindShipmentFromJobDocument,
    options,
  )
}
export type FindShipmentFromJobQueryHookResult = ReturnType<typeof useFindShipmentFromJobQuery>
export type FindShipmentFromJobLazyQueryHookResult = ReturnType<
  typeof useFindShipmentFromJobLazyQuery
>
export type FindShipmentFromJobQueryResult = Apollo.QueryResult<
  FindShipmentFromJobQuery,
  FindShipmentFromJobQueryVariables
>
export const FindCustomsDeclarationFromJobDocument = gql`
  query findCustomsDeclarationFromJob($jobId: UUID!) {
    customsDeclarationWithMatchCriteria(jobId: $jobId) {
      chainIoCustomsDeclaration {
        id
        forwarderReference
      }
      referenceNo
      hblNo
      consolNo
      mblNo
      carrierBookingNo
      containerNo
      orderNo
    }
  }
`

/**
 * __useFindCustomsDeclarationFromJobQuery__
 *
 * To run a query within a React component, call `useFindCustomsDeclarationFromJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCustomsDeclarationFromJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCustomsDeclarationFromJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useFindCustomsDeclarationFromJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindCustomsDeclarationFromJobQuery,
    FindCustomsDeclarationFromJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindCustomsDeclarationFromJobQuery,
    FindCustomsDeclarationFromJobQueryVariables
  >(FindCustomsDeclarationFromJobDocument, options)
}
export function useFindCustomsDeclarationFromJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCustomsDeclarationFromJobQuery,
    FindCustomsDeclarationFromJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindCustomsDeclarationFromJobQuery,
    FindCustomsDeclarationFromJobQueryVariables
  >(FindCustomsDeclarationFromJobDocument, options)
}
export type FindCustomsDeclarationFromJobQueryHookResult = ReturnType<
  typeof useFindCustomsDeclarationFromJobQuery
>
export type FindCustomsDeclarationFromJobLazyQueryHookResult = ReturnType<
  typeof useFindCustomsDeclarationFromJobLazyQuery
>
export type FindCustomsDeclarationFromJobQueryResult = Apollo.QueryResult<
  FindCustomsDeclarationFromJobQuery,
  FindCustomsDeclarationFromJobQueryVariables
>
export const GetPullEDocsBatchDocument = gql`
  query getPullEDocsBatch($batchPullId: UUID!, $totalPulled: Int!) {
    pullEDocsBatch(batchPullId: $batchPullId, totalPulled: $totalPulled) {
      batchPullId
      done
      successes
      failures
      exceptions
    }
  }
`

/**
 * __useGetPullEDocsBatchQuery__
 *
 * To run a query within a React component, call `useGetPullEDocsBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPullEDocsBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPullEDocsBatchQuery({
 *   variables: {
 *      batchPullId: // value for 'batchPullId'
 *      totalPulled: // value for 'totalPulled'
 *   },
 * });
 */
export function useGetPullEDocsBatchQuery(
  baseOptions: Apollo.QueryHookOptions<GetPullEDocsBatchQuery, GetPullEDocsBatchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPullEDocsBatchQuery, GetPullEDocsBatchQueryVariables>(
    GetPullEDocsBatchDocument,
    options,
  )
}
export function useGetPullEDocsBatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPullEDocsBatchQuery,
    GetPullEDocsBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPullEDocsBatchQuery, GetPullEDocsBatchQueryVariables>(
    GetPullEDocsBatchDocument,
    options,
  )
}
export type GetPullEDocsBatchQueryHookResult = ReturnType<typeof useGetPullEDocsBatchQuery>
export type GetPullEDocsBatchLazyQueryHookResult = ReturnType<typeof useGetPullEDocsBatchLazyQuery>
export type GetPullEDocsBatchQueryResult = Apollo.QueryResult<
  GetPullEDocsBatchQuery,
  GetPullEDocsBatchQueryVariables
>
export const GetPushEDocsBatchDocument = gql`
  query getPushEDocsBatch($batchPushId: UUID!, $totalPushed: Int!) {
    pushEDocsBatch(batchPushId: $batchPushId, totalPushed: $totalPushed) {
      batchPushId
      done
      successes
      failures
      errorMessages
    }
  }
`

/**
 * __useGetPushEDocsBatchQuery__
 *
 * To run a query within a React component, call `useGetPushEDocsBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPushEDocsBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPushEDocsBatchQuery({
 *   variables: {
 *      batchPushId: // value for 'batchPushId'
 *      totalPushed: // value for 'totalPushed'
 *   },
 * });
 */
export function useGetPushEDocsBatchQuery(
  baseOptions: Apollo.QueryHookOptions<GetPushEDocsBatchQuery, GetPushEDocsBatchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPushEDocsBatchQuery, GetPushEDocsBatchQueryVariables>(
    GetPushEDocsBatchDocument,
    options,
  )
}
export function useGetPushEDocsBatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPushEDocsBatchQuery,
    GetPushEDocsBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPushEDocsBatchQuery, GetPushEDocsBatchQueryVariables>(
    GetPushEDocsBatchDocument,
    options,
  )
}
export type GetPushEDocsBatchQueryHookResult = ReturnType<typeof useGetPushEDocsBatchQuery>
export type GetPushEDocsBatchLazyQueryHookResult = ReturnType<typeof useGetPushEDocsBatchLazyQuery>
export type GetPushEDocsBatchQueryResult = Apollo.QueryResult<
  GetPushEDocsBatchQuery,
  GetPushEDocsBatchQueryVariables
>
export const GetRecentCargowiseOutboundsDocument = gql`
  query getRecentCargowiseOutbounds($inputCwOutboundFilter: InputCargowiseOutboundFilter!) {
    recentCargowiseOutbounds(inputCwOutboundFilter: $inputCwOutboundFilter) {
      id
      dateCreated
      integrationId
      signedViewUrl
      processed
    }
  }
`

/**
 * __useGetRecentCargowiseOutboundsQuery__
 *
 * To run a query within a React component, call `useGetRecentCargowiseOutboundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentCargowiseOutboundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentCargowiseOutboundsQuery({
 *   variables: {
 *      inputCwOutboundFilter: // value for 'inputCwOutboundFilter'
 *   },
 * });
 */
export function useGetRecentCargowiseOutboundsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRecentCargowiseOutboundsQuery,
    GetRecentCargowiseOutboundsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetRecentCargowiseOutboundsQuery,
    GetRecentCargowiseOutboundsQueryVariables
  >(GetRecentCargowiseOutboundsDocument, options)
}
export function useGetRecentCargowiseOutboundsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecentCargowiseOutboundsQuery,
    GetRecentCargowiseOutboundsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetRecentCargowiseOutboundsQuery,
    GetRecentCargowiseOutboundsQueryVariables
  >(GetRecentCargowiseOutboundsDocument, options)
}
export type GetRecentCargowiseOutboundsQueryHookResult = ReturnType<
  typeof useGetRecentCargowiseOutboundsQuery
>
export type GetRecentCargowiseOutboundsLazyQueryHookResult = ReturnType<
  typeof useGetRecentCargowiseOutboundsLazyQuery
>
export type GetRecentCargowiseOutboundsQueryResult = Apollo.QueryResult<
  GetRecentCargowiseOutboundsQuery,
  GetRecentCargowiseOutboundsQueryVariables
>
export const SearchShipmentsDocument = gql`
  query searchShipments($cargowiseModule: String!, $jobTemplateId: UUID!, $searchStr: String!) {
    searchShipments(
      cargowiseModule: $cargowiseModule
      jobTemplateId: $jobTemplateId
      searchStr: $searchStr
    ) {
      ... on ChainIOShipmentNode {
        id
        dateCreated
        tmsId
        forwarderReference
        houseBill
        masterBill
        chainIoConsolidation {
          id
          tmsId
          forwarderReference
          carrierBookingRef
          masterBill
        }
        containers {
          edges {
            node {
              id
              containerNumber
            }
          }
        }
        orders {
          edges {
            node {
              id
              orderReference
            }
          }
        }
      }
      ... on ChainIOCustomsDeclarationNode {
        id
        dateCreated
        forwarderReference
        chainIoShipment {
          id
          tmsId
          forwarderReference
          houseBill
          masterBill
          orders {
            edges {
              node {
                id
                orderReference
              }
            }
          }
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          chainIoConsolidation {
            id
            tmsId
            forwarderReference
            carrierBookingRef
            masterBill
          }
        }
      }
      ... on ChainIOConsolidationNode {
        id
        dateCreated
        tmsId
        forwarderReference
        carrierBookingRef
        masterBill
        containers {
          edges {
            node {
              id
              containerNumber
            }
          }
        }
        shipments {
          edges {
            node {
              id
              orders {
                edges {
                  node {
                    id
                    orderReference
                  }
                }
              }
              tmsId
              forwarderReference
              houseBill
              masterBill
            }
          }
        }
      }
    }
  }
`

/**
 * __useSearchShipmentsQuery__
 *
 * To run a query within a React component, call `useSearchShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchShipmentsQuery({
 *   variables: {
 *      cargowiseModule: // value for 'cargowiseModule'
 *      jobTemplateId: // value for 'jobTemplateId'
 *      searchStr: // value for 'searchStr'
 *   },
 * });
 */
export function useSearchShipmentsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchShipmentsQuery, SearchShipmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchShipmentsQuery, SearchShipmentsQueryVariables>(
    SearchShipmentsDocument,
    options,
  )
}
export function useSearchShipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchShipmentsQuery, SearchShipmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchShipmentsQuery, SearchShipmentsQueryVariables>(
    SearchShipmentsDocument,
    options,
  )
}
export type SearchShipmentsQueryHookResult = ReturnType<typeof useSearchShipmentsQuery>
export type SearchShipmentsLazyQueryHookResult = ReturnType<typeof useSearchShipmentsLazyQuery>
export type SearchShipmentsQueryResult = Apollo.QueryResult<
  SearchShipmentsQuery,
  SearchShipmentsQueryVariables
>
export const UserCompaniesDocument = gql`
  query userCompanies {
    companies {
      id
      name
    }
  }
`

/**
 * __useUserCompaniesQuery__
 *
 * To run a query within a React component, call `useUserCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<UserCompaniesQuery, UserCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserCompaniesQuery, UserCompaniesQueryVariables>(
    UserCompaniesDocument,
    options,
  )
}
export function useUserCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserCompaniesQuery, UserCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserCompaniesQuery, UserCompaniesQueryVariables>(
    UserCompaniesDocument,
    options,
  )
}
export type UserCompaniesQueryHookResult = ReturnType<typeof useUserCompaniesQuery>
export type UserCompaniesLazyQueryHookResult = ReturnType<typeof useUserCompaniesLazyQuery>
export type UserCompaniesQueryResult = Apollo.QueryResult<
  UserCompaniesQuery,
  UserCompaniesQueryVariables
>
export const AllCompaniesDocument = gql`
  query allCompanies {
    allCompanies {
      id
      name
      hasAccessToInsights
      apiPartners {
        edges {
          node {
            id
            name
            companyId
            apiPartnerCode {
              ... on FallbackValue {
                fallbackValue
                isFallback
              }
              ... on ApiPartnerCodeEnum {
                value
                isFallback
              }
            }
            ... on CargowiseConfigNode {
              pollDatabase
              connectorType
            }
          }
        }
      }
    }
  }
`

/**
 * __useAllCompaniesQuery__
 *
 * To run a query within a React component, call `useAllCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllCompaniesQuery, AllCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllCompaniesQuery, AllCompaniesQueryVariables>(
    AllCompaniesDocument,
    options,
  )
}
export function useAllCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllCompaniesQuery, AllCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllCompaniesQuery, AllCompaniesQueryVariables>(
    AllCompaniesDocument,
    options,
  )
}
export type AllCompaniesQueryHookResult = ReturnType<typeof useAllCompaniesQuery>
export type AllCompaniesLazyQueryHookResult = ReturnType<typeof useAllCompaniesLazyQuery>
export type AllCompaniesQueryResult = Apollo.QueryResult<
  AllCompaniesQuery,
  AllCompaniesQueryVariables
>
export const CompanyJobTemplatesDocument = gql`
  query companyJobTemplates($companyId: UUID!, $queryString: String!) {
    companyJobTemplates(companyId: $companyId, queryString: $queryString) {
      id
      name
      documentTypes {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

/**
 * __useCompanyJobTemplatesQuery__
 *
 * To run a query within a React component, call `useCompanyJobTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyJobTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyJobTemplatesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      queryString: // value for 'queryString'
 *   },
 * });
 */
export function useCompanyJobTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyJobTemplatesQuery, CompanyJobTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyJobTemplatesQuery, CompanyJobTemplatesQueryVariables>(
    CompanyJobTemplatesDocument,
    options,
  )
}
export function useCompanyJobTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyJobTemplatesQuery,
    CompanyJobTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyJobTemplatesQuery, CompanyJobTemplatesQueryVariables>(
    CompanyJobTemplatesDocument,
    options,
  )
}
export type CompanyJobTemplatesQueryHookResult = ReturnType<typeof useCompanyJobTemplatesQuery>
export type CompanyJobTemplatesLazyQueryHookResult = ReturnType<
  typeof useCompanyJobTemplatesLazyQuery
>
export type CompanyJobTemplatesQueryResult = Apollo.QueryResult<
  CompanyJobTemplatesQuery,
  CompanyJobTemplatesQueryVariables
>
export const CompanyDocument = gql`
  query company($id: String!) {
    company(id: $id) {
      id
      name
      cargowiseConfigs {
        edges {
          node {
            id
            code
            enterpriseId
            serverId
            integrationId
          }
        }
      }
      apiPartners {
        edges {
          node {
            id
            name
          }
        }
      }
      metabaseDashboardId
      hasAccessToInsights
      shipperFacingSlug
      logo
      primaryThemeColor
      usesChargeCodeV2
      enableEdocsTab
    }
  }
`

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options)
}
export function useCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options)
}
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>
export const SignCompanyLogoUriDocument = gql`
  query signCompanyLogoUri($companyId: UUID!) {
    signCompanyLogoUri(companyId: $companyId)
  }
`

/**
 * __useSignCompanyLogoUriQuery__
 *
 * To run a query within a React component, call `useSignCompanyLogoUriQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignCompanyLogoUriQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignCompanyLogoUriQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSignCompanyLogoUriQuery(
  baseOptions: Apollo.QueryHookOptions<SignCompanyLogoUriQuery, SignCompanyLogoUriQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SignCompanyLogoUriQuery, SignCompanyLogoUriQueryVariables>(
    SignCompanyLogoUriDocument,
    options,
  )
}
export function useSignCompanyLogoUriLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SignCompanyLogoUriQuery,
    SignCompanyLogoUriQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SignCompanyLogoUriQuery, SignCompanyLogoUriQueryVariables>(
    SignCompanyLogoUriDocument,
    options,
  )
}
export type SignCompanyLogoUriQueryHookResult = ReturnType<typeof useSignCompanyLogoUriQuery>
export type SignCompanyLogoUriLazyQueryHookResult = ReturnType<
  typeof useSignCompanyLogoUriLazyQuery
>
export type SignCompanyLogoUriQueryResult = Apollo.QueryResult<
  SignCompanyLogoUriQuery,
  SignCompanyLogoUriQueryVariables
>
export const CredentialsDocument = gql`
  query credentials {
    credentials {
      id
      username
      apiEndpoint
      workflow
      verifySsl
      hostOverride
      jobTemplates {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

/**
 * __useCredentialsQuery__
 *
 * To run a query within a React component, call `useCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCredentialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCredentialsQuery(
  baseOptions?: Apollo.QueryHookOptions<CredentialsQuery, CredentialsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CredentialsQuery, CredentialsQueryVariables>(CredentialsDocument, options)
}
export function useCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CredentialsQuery, CredentialsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CredentialsQuery, CredentialsQueryVariables>(
    CredentialsDocument,
    options,
  )
}
export type CredentialsQueryHookResult = ReturnType<typeof useCredentialsQuery>
export type CredentialsLazyQueryHookResult = ReturnType<typeof useCredentialsLazyQuery>
export type CredentialsQueryResult = Apollo.QueryResult<CredentialsQuery, CredentialsQueryVariables>
export const CustomDatabaseTypesDocument = gql`
  query customDatabaseTypes($query: String!) {
    customDatabaseTypes(query: $query) {
      id
      name
      tmsType {
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
        ... on ApiPartnerTmsTypeEnum {
          value
          isFallback
        }
      }
      columns {
        edges {
          node {
            id
            displayName
            exportKey
            dateDeleted
          }
        }
      }
    }
  }
`

/**
 * __useCustomDatabaseTypesQuery__
 *
 * To run a query within a React component, call `useCustomDatabaseTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomDatabaseTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomDatabaseTypesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCustomDatabaseTypesQuery(
  baseOptions: Apollo.QueryHookOptions<CustomDatabaseTypesQuery, CustomDatabaseTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomDatabaseTypesQuery, CustomDatabaseTypesQueryVariables>(
    CustomDatabaseTypesDocument,
    options,
  )
}
export function useCustomDatabaseTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomDatabaseTypesQuery,
    CustomDatabaseTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomDatabaseTypesQuery, CustomDatabaseTypesQueryVariables>(
    CustomDatabaseTypesDocument,
    options,
  )
}
export type CustomDatabaseTypesQueryHookResult = ReturnType<typeof useCustomDatabaseTypesQuery>
export type CustomDatabaseTypesLazyQueryHookResult = ReturnType<
  typeof useCustomDatabaseTypesLazyQuery
>
export type CustomDatabaseTypesQueryResult = Apollo.QueryResult<
  CustomDatabaseTypesQuery,
  CustomDatabaseTypesQueryVariables
>
export const CustomDatabasesDocument = gql`
  query customDatabases($query: String!, $apiPartnerId: UUID!) {
    customDatabases(query: $query, apiPartnerId: $apiPartnerId) {
      id
      name
      customDatabaseTypeId
      customDatabaseType {
        id
        name
        tmsType {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ApiPartnerTmsTypeEnum {
            value
            isFallback
          }
        }
        columns {
          edges {
            node {
              id
              displayName
              exportKey
              dateDeleted
            }
          }
        }
      }
      rows {
        edges {
          node {
            id
            dateDeleted
            cells {
              edges {
                node {
                  id
                  value
                  customDatabaseColumnId
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useCustomDatabasesQuery__
 *
 * To run a query within a React component, call `useCustomDatabasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomDatabasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomDatabasesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      apiPartnerId: // value for 'apiPartnerId'
 *   },
 * });
 */
export function useCustomDatabasesQuery(
  baseOptions: Apollo.QueryHookOptions<CustomDatabasesQuery, CustomDatabasesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomDatabasesQuery, CustomDatabasesQueryVariables>(
    CustomDatabasesDocument,
    options,
  )
}
export function useCustomDatabasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomDatabasesQuery, CustomDatabasesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomDatabasesQuery, CustomDatabasesQueryVariables>(
    CustomDatabasesDocument,
    options,
  )
}
export type CustomDatabasesQueryHookResult = ReturnType<typeof useCustomDatabasesQuery>
export type CustomDatabasesLazyQueryHookResult = ReturnType<typeof useCustomDatabasesLazyQuery>
export type CustomDatabasesQueryResult = Apollo.QueryResult<
  CustomDatabasesQuery,
  CustomDatabasesQueryVariables
>
export const ListCompanyDashboardsDocument = gql`
  query listCompanyDashboards($companyId: UUID, $tabType: String, $shouldWhitelist: Boolean)
  @api(name: beholder) {
    listCompanyDashboards(
      companyId: $companyId
      tabType: $tabType
      shouldWhitelist: $shouldWhitelist
    ) {
      name
      displayName
      dateCreated
      dateModified
      id
      isDefault
      dashboardTab {
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
        ... on DashboardTabTypeEnum {
          value
          isFallback
        }
      }
      iframeOverrideUrl
      publicUrl
      selectors
      basicFilterFields
    }
  }
`

/**
 * __useListCompanyDashboardsQuery__
 *
 * To run a query within a React component, call `useListCompanyDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyDashboardsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      tabType: // value for 'tabType'
 *      shouldWhitelist: // value for 'shouldWhitelist'
 *   },
 * });
 */
export function useListCompanyDashboardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListCompanyDashboardsQuery,
    ListCompanyDashboardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListCompanyDashboardsQuery, ListCompanyDashboardsQueryVariables>(
    ListCompanyDashboardsDocument,
    options,
  )
}
export function useListCompanyDashboardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListCompanyDashboardsQuery,
    ListCompanyDashboardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListCompanyDashboardsQuery, ListCompanyDashboardsQueryVariables>(
    ListCompanyDashboardsDocument,
    options,
  )
}
export type ListCompanyDashboardsQueryHookResult = ReturnType<typeof useListCompanyDashboardsQuery>
export type ListCompanyDashboardsLazyQueryHookResult = ReturnType<
  typeof useListCompanyDashboardsLazyQuery
>
export type ListCompanyDashboardsQueryResult = Apollo.QueryResult<
  ListCompanyDashboardsQuery,
  ListCompanyDashboardsQueryVariables
>
export const StandardDocumentTypesDocument = gql`
  query standardDocumentTypes {
    standardDocumentTypes {
      id
      name
      isStandard
      collapsible
      tableShowsPreset
      autofillExtractorKey
      isEDocPublishedByDefault
      cargowiseFileTypeId
      cargowiseFileType {
        id
        code
        description
      }
      documentTypeFieldGroups {
        edges {
          node {
            id
            fieldGroup {
              id
              key
              name
              repeatable
              autofillKey
              fields {
                edges {
                  node {
                    id
                    name
                    key
                    autofillKey
                    validatorRegex
                    validatorDescription
                    dateFormatString
                    required
                    rows
                    values
                    allowFreeText
                    columnOrder
                    defaultValue
                    fieldType {
                      ... on FallbackValue {
                        fallbackValue
                        isFallback
                      }
                      ... on FieldTypeEnum {
                        value
                        isFallback
                      }
                    }
                    partialTableAllowed
                    invalidCharsRegex
                    searchableRecord {
                      id
                      model
                      searchKey
                      displayKey
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useStandardDocumentTypesQuery__
 *
 * To run a query within a React component, call `useStandardDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardDocumentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStandardDocumentTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StandardDocumentTypesQuery,
    StandardDocumentTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StandardDocumentTypesQuery, StandardDocumentTypesQueryVariables>(
    StandardDocumentTypesDocument,
    options,
  )
}
export function useStandardDocumentTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StandardDocumentTypesQuery,
    StandardDocumentTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StandardDocumentTypesQuery, StandardDocumentTypesQueryVariables>(
    StandardDocumentTypesDocument,
    options,
  )
}
export type StandardDocumentTypesQueryHookResult = ReturnType<typeof useStandardDocumentTypesQuery>
export type StandardDocumentTypesLazyQueryHookResult = ReturnType<
  typeof useStandardDocumentTypesLazyQuery
>
export type StandardDocumentTypesQueryResult = Apollo.QueryResult<
  StandardDocumentTypesQuery,
  StandardDocumentTypesQueryVariables
>
export const DocumentTablesDocument = gql`
  query documentTables($jobId: String!, $editedByUser: Boolean) {
    documentTables(jobId: $jobId, editedByUser: $editedByUser) {
      ...documentTable
    }
  }
  ${DocumentTableFragmentDoc}
`

/**
 * __useDocumentTablesQuery__
 *
 * To run a query within a React component, call `useDocumentTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTablesQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      editedByUser: // value for 'editedByUser'
 *   },
 * });
 */
export function useDocumentTablesQuery(
  baseOptions: Apollo.QueryHookOptions<DocumentTablesQuery, DocumentTablesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DocumentTablesQuery, DocumentTablesQueryVariables>(
    DocumentTablesDocument,
    options,
  )
}
export function useDocumentTablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DocumentTablesQuery, DocumentTablesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DocumentTablesQuery, DocumentTablesQueryVariables>(
    DocumentTablesDocument,
    options,
  )
}
export type DocumentTablesQueryHookResult = ReturnType<typeof useDocumentTablesQuery>
export type DocumentTablesLazyQueryHookResult = ReturnType<typeof useDocumentTablesLazyQuery>
export type DocumentTablesQueryResult = Apollo.QueryResult<
  DocumentTablesQuery,
  DocumentTablesQueryVariables
>
export const DocumentTypesFieldGroupsDocument = gql`
  query documentTypesFieldGroups($ids: [UUID!]!) {
    documentTypes(ids: $ids) {
      id
      documentTypeFieldGroups {
        edges {
          node {
            id
            fieldGroup {
              id
              key
              name
              repeatable
              autofillKey
              fields {
                edges {
                  node {
                    id
                    name
                    key
                    autofillKey
                    validatorRegex
                    validatorDescription
                    dateFormatString
                    required
                    rows
                    values
                    allowFreeText
                    columnOrder
                    defaultValue
                    fieldType {
                      ... on FallbackValue {
                        fallbackValue
                        isFallback
                      }
                      ... on FieldTypeEnum {
                        value
                        isFallback
                      }
                    }
                    partialTableAllowed
                    invalidCharsRegex
                    searchableRecord {
                      id
                      searchKey
                      displayKey
                      model
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useDocumentTypesFieldGroupsQuery__
 *
 * To run a query within a React component, call `useDocumentTypesFieldGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTypesFieldGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTypesFieldGroupsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDocumentTypesFieldGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DocumentTypesFieldGroupsQuery,
    DocumentTypesFieldGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DocumentTypesFieldGroupsQuery, DocumentTypesFieldGroupsQueryVariables>(
    DocumentTypesFieldGroupsDocument,
    options,
  )
}
export function useDocumentTypesFieldGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DocumentTypesFieldGroupsQuery,
    DocumentTypesFieldGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DocumentTypesFieldGroupsQuery, DocumentTypesFieldGroupsQueryVariables>(
    DocumentTypesFieldGroupsDocument,
    options,
  )
}
export type DocumentTypesFieldGroupsQueryHookResult = ReturnType<
  typeof useDocumentTypesFieldGroupsQuery
>
export type DocumentTypesFieldGroupsLazyQueryHookResult = ReturnType<
  typeof useDocumentTypesFieldGroupsLazyQuery
>
export type DocumentTypesFieldGroupsQueryResult = Apollo.QueryResult<
  DocumentTypesFieldGroupsQuery,
  DocumentTypesFieldGroupsQueryVariables
>
export const AutofillKeysDocument = gql`
  query autofillKeys(
    $autofillExtractorKey: AutofillExtractorKey!
    $reconType: JobTemplateReconType!
    $documentTypeId: String!
  ) {
    autofillKeys(
      autofillExtractorKey: $autofillExtractorKey
      reconType: $reconType
      documentTypeId: $documentTypeId
    ) {
      autofillKeys
      lineItemFieldGroupAutofillKeys
    }
  }
`

/**
 * __useAutofillKeysQuery__
 *
 * To run a query within a React component, call `useAutofillKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutofillKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutofillKeysQuery({
 *   variables: {
 *      autofillExtractorKey: // value for 'autofillExtractorKey'
 *      reconType: // value for 'reconType'
 *      documentTypeId: // value for 'documentTypeId'
 *   },
 * });
 */
export function useAutofillKeysQuery(
  baseOptions: Apollo.QueryHookOptions<AutofillKeysQuery, AutofillKeysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AutofillKeysQuery, AutofillKeysQueryVariables>(
    AutofillKeysDocument,
    options,
  )
}
export function useAutofillKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AutofillKeysQuery, AutofillKeysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AutofillKeysQuery, AutofillKeysQueryVariables>(
    AutofillKeysDocument,
    options,
  )
}
export type AutofillKeysQueryHookResult = ReturnType<typeof useAutofillKeysQuery>
export type AutofillKeysLazyQueryHookResult = ReturnType<typeof useAutofillKeysLazyQuery>
export type AutofillKeysQueryResult = Apollo.QueryResult<
  AutofillKeysQuery,
  AutofillKeysQueryVariables
>
export const SearchableRecordsDocument = gql`
  query searchableRecords {
    searchableRecords {
      searchKey
      displayKey
      model
      apiUrl
    }
  }
`

/**
 * __useSearchableRecordsQuery__
 *
 * To run a query within a React component, call `useSearchableRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchableRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchableRecordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchableRecordsQuery(
  baseOptions?: Apollo.QueryHookOptions<SearchableRecordsQuery, SearchableRecordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchableRecordsQuery, SearchableRecordsQueryVariables>(
    SearchableRecordsDocument,
    options,
  )
}
export function useSearchableRecordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchableRecordsQuery,
    SearchableRecordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchableRecordsQuery, SearchableRecordsQueryVariables>(
    SearchableRecordsDocument,
    options,
  )
}
export type SearchableRecordsQueryHookResult = ReturnType<typeof useSearchableRecordsQuery>
export type SearchableRecordsLazyQueryHookResult = ReturnType<typeof useSearchableRecordsLazyQuery>
export type SearchableRecordsQueryResult = Apollo.QueryResult<
  SearchableRecordsQuery,
  SearchableRecordsQueryVariables
>
export const FilePagesDocument = gql`
  query filePages($filePageIds: [String!]!) {
    filePagesConnection(filePageIds: $filePageIds) {
      items {
        ...FilePageAttributes
        file {
          ...FileAttributes
          companyId
          company {
            id
            name
          }
        }
      }
      total
    }
  }
  ${FilePageAttributesFragmentDoc}
  ${FileAttributesFragmentDoc}
`

/**
 * __useFilePagesQuery__
 *
 * To run a query within a React component, call `useFilePagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilePagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilePagesQuery({
 *   variables: {
 *      filePageIds: // value for 'filePageIds'
 *   },
 * });
 */
export function useFilePagesQuery(
  baseOptions: Apollo.QueryHookOptions<FilePagesQuery, FilePagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FilePagesQuery, FilePagesQueryVariables>(FilePagesDocument, options)
}
export function useFilePagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilePagesQuery, FilePagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FilePagesQuery, FilePagesQueryVariables>(FilePagesDocument, options)
}
export type FilePagesQueryHookResult = ReturnType<typeof useFilePagesQuery>
export type FilePagesLazyQueryHookResult = ReturnType<typeof useFilePagesLazyQuery>
export type FilePagesQueryResult = Apollo.QueryResult<FilePagesQuery, FilePagesQueryVariables>
export const DashhboardFilesDocument = gql`
  query dashhboardFiles($page: Int!, $query: String!, $companyId: String, $status: String!) {
    fileConnection(page: $page, query: $query, status: $status, companyId: $companyId) {
      items {
        ...FileAttributes
        company {
          id
          name
        }
        pages {
          edges {
            node {
              ...FilePageAttributes
            }
          }
        }
      }
      total
    }
  }
  ${FileAttributesFragmentDoc}
  ${FilePageAttributesFragmentDoc}
`

/**
 * __useDashhboardFilesQuery__
 *
 * To run a query within a React component, call `useDashhboardFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashhboardFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashhboardFilesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      query: // value for 'query'
 *      companyId: // value for 'companyId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useDashhboardFilesQuery(
  baseOptions: Apollo.QueryHookOptions<DashhboardFilesQuery, DashhboardFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DashhboardFilesQuery, DashhboardFilesQueryVariables>(
    DashhboardFilesDocument,
    options,
  )
}
export function useDashhboardFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DashhboardFilesQuery, DashhboardFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DashhboardFilesQuery, DashhboardFilesQueryVariables>(
    DashhboardFilesDocument,
    options,
  )
}
export type DashhboardFilesQueryHookResult = ReturnType<typeof useDashhboardFilesQuery>
export type DashhboardFilesLazyQueryHookResult = ReturnType<typeof useDashhboardFilesLazyQuery>
export type DashhboardFilesQueryResult = Apollo.QueryResult<
  DashhboardFilesQuery,
  DashhboardFilesQueryVariables
>
export const GetPageAsyncBatchDocument = gql`
  query getPageAsyncBatch($id: UUID!) {
    pageAsyncBatch(id: $id) {
      id
      type
      status
      errorMessage
      numDone
      numTasks
    }
  }
`

/**
 * __useGetPageAsyncBatchQuery__
 *
 * To run a query within a React component, call `useGetPageAsyncBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageAsyncBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageAsyncBatchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPageAsyncBatchQuery(
  baseOptions: Apollo.QueryHookOptions<GetPageAsyncBatchQuery, GetPageAsyncBatchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPageAsyncBatchQuery, GetPageAsyncBatchQueryVariables>(
    GetPageAsyncBatchDocument,
    options,
  )
}
export function useGetPageAsyncBatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPageAsyncBatchQuery,
    GetPageAsyncBatchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPageAsyncBatchQuery, GetPageAsyncBatchQueryVariables>(
    GetPageAsyncBatchDocument,
    options,
  )
}
export type GetPageAsyncBatchQueryHookResult = ReturnType<typeof useGetPageAsyncBatchQuery>
export type GetPageAsyncBatchLazyQueryHookResult = ReturnType<typeof useGetPageAsyncBatchLazyQuery>
export type GetPageAsyncBatchQueryResult = Apollo.QueryResult<
  GetPageAsyncBatchQuery,
  GetPageAsyncBatchQueryVariables
>
export const GetFileSignedUrlDocument = gql`
  query getFileSignedUrl($filename: String!, $contentType: String!) {
    fileSignedUrl(filename: $filename, contentType: $contentType) {
      viewUrl
      putUrl
    }
  }
`

/**
 * __useGetFileSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetFileSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileSignedUrlQuery({
 *   variables: {
 *      filename: // value for 'filename'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useGetFileSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetFileSignedUrlQuery, GetFileSignedUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFileSignedUrlQuery, GetFileSignedUrlQueryVariables>(
    GetFileSignedUrlDocument,
    options,
  )
}
export function useGetFileSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFileSignedUrlQuery, GetFileSignedUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetFileSignedUrlQuery, GetFileSignedUrlQueryVariables>(
    GetFileSignedUrlDocument,
    options,
  )
}
export type GetFileSignedUrlQueryHookResult = ReturnType<typeof useGetFileSignedUrlQuery>
export type GetFileSignedUrlLazyQueryHookResult = ReturnType<typeof useGetFileSignedUrlLazyQuery>
export type GetFileSignedUrlQueryResult = Apollo.QueryResult<
  GetFileSignedUrlQuery,
  GetFileSignedUrlQueryVariables
>
export const GeoLocationsDocument = gql`
  query geoLocations($query: String!) {
    geoLocations(query: $query) {
      id
      code
      locodeName
      timezoneName
    }
  }
`

/**
 * __useGeoLocationsQuery__
 *
 * To run a query within a React component, call `useGeoLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeoLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeoLocationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGeoLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<GeoLocationsQuery, GeoLocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GeoLocationsQuery, GeoLocationsQueryVariables>(
    GeoLocationsDocument,
    options,
  )
}
export function useGeoLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GeoLocationsQuery, GeoLocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GeoLocationsQuery, GeoLocationsQueryVariables>(
    GeoLocationsDocument,
    options,
  )
}
export type GeoLocationsQueryHookResult = ReturnType<typeof useGeoLocationsQuery>
export type GeoLocationsLazyQueryHookResult = ReturnType<typeof useGeoLocationsLazyQuery>
export type GeoLocationsQueryResult = Apollo.QueryResult<
  GeoLocationsQuery,
  GeoLocationsQueryVariables
>
export const CountByJobStatusDocument = gql`
  query countByJobStatus {
    countByJobStatus
  }
`

/**
 * __useCountByJobStatusQuery__
 *
 * To run a query within a React component, call `useCountByJobStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountByJobStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountByJobStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountByJobStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<CountByJobStatusQuery, CountByJobStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CountByJobStatusQuery, CountByJobStatusQueryVariables>(
    CountByJobStatusDocument,
    options,
  )
}
export function useCountByJobStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountByJobStatusQuery, CountByJobStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CountByJobStatusQuery, CountByJobStatusQueryVariables>(
    CountByJobStatusDocument,
    options,
  )
}
export type CountByJobStatusQueryHookResult = ReturnType<typeof useCountByJobStatusQuery>
export type CountByJobStatusLazyQueryHookResult = ReturnType<typeof useCountByJobStatusLazyQuery>
export type CountByJobStatusQueryResult = Apollo.QueryResult<
  CountByJobStatusQuery,
  CountByJobStatusQueryVariables
>
export const GetJobDataLiteDocument = gql`
  query getJobDataLite($id: UUID!) {
    job(id: $id) {
      id
      name
      message
      status
      manualProduct
      ownerId
      qaId
      externalStatus
      externalAssignee {
        id
        email
      }
      jobTemplate {
        id
        name
        company {
          id
          name
        }
        apiPartner {
          id
          name
          apiPartnerCode {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ApiPartnerCodeEnum {
              value
              isFallback
            }
          }
        }
        reconType
        shipmentOpsTypes
      }
      pageAsyncBatches {
        edges {
          node {
            id
            status
          }
        }
      }
      task {
        id
        title
        message
        taskReferenceId
        status
        taskType
        dateCreated
        dateReceived
        dateConfirmed
        taskSlaTimeJobRef {
          id
          job {
            id
            dateCreated
            slaTime
          }
        }
        company {
          id
          name
        }
      }
      latestSavedReconAttemptId
    }
  }
`

/**
 * __useGetJobDataLiteQuery__
 *
 * To run a query within a React component, call `useGetJobDataLiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobDataLiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobDataLiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobDataLiteQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobDataLiteQuery, GetJobDataLiteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobDataLiteQuery, GetJobDataLiteQueryVariables>(
    GetJobDataLiteDocument,
    options,
  )
}
export function useGetJobDataLiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobDataLiteQuery, GetJobDataLiteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobDataLiteQuery, GetJobDataLiteQueryVariables>(
    GetJobDataLiteDocument,
    options,
  )
}
export type GetJobDataLiteQueryHookResult = ReturnType<typeof useGetJobDataLiteQuery>
export type GetJobDataLiteLazyQueryHookResult = ReturnType<typeof useGetJobDataLiteLazyQuery>
export type GetJobDataLiteQueryResult = Apollo.QueryResult<
  GetJobDataLiteQuery,
  GetJobDataLiteQueryVariables
>
export const GetJobDataDocument = gql`
  query getJobData($id: UUID!) {
    job(id: $id) {
      id
      name
      message
      status
      manualProduct
      owner {
        id
      }
      ownerId
      qa {
        id
      }
      qaId
      jobTable {
        ...jobTable
      }
      pageAsyncBatches {
        edges {
          node {
            id
            status
          }
        }
      }
      jobTemplate {
        id
        name
        reconType
        subtotalsRowEnabled
        mainTabEnabled
        company {
          id
          name
          usesChargeCodeV2
        }
        cargowiseConfig {
          id
        }
        apiPartner {
          id
          name
          apiPartnerCode {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ApiPartnerCodeEnum {
              value
              isFallback
            }
          }
        }
        shipmentOpsTypes
        documentTypes {
          edges {
            node {
              id
              name
              collapsible
              collapsibleOrderPriority
              documentTypeFieldGroups {
                edges {
                  node {
                    id
                    fieldGroup {
                      repeatable
                      ...fieldGroup
                    }
                  }
                }
              }
            }
          }
        }
      }
      task {
        id
        blocked
        title
        message
        taskReferenceId
        status
        taskType
        dateCreated
        dateReceived
        taskSlaTimeJobRef {
          id
          job {
            id
            dateCreated
            slaTime
          }
        }
        company {
          id
          name
        }
      }
      filePages {
        edges {
          node {
            id
            googleOcrData
            imageUrl
            pageNumber
            type
            isInCw
            file {
              ...FileAttributes
            }
            eDocuments {
              edges {
                node {
                  id
                  isInCw
                  dataTargetType {
                    ... on EDocsDataTargetTypeEnum {
                      value
                      isFallback
                    }
                    ... on FallbackValue {
                      fallbackValue
                      isFallback
                    }
                  }
                  cargowiseFileType {
                    id
                    code
                    description
                  }
                }
              }
            }
            document {
              id
              documentType {
                id
                tableShowsPreset
                name
                isEDocPublishedByDefault
                cargowiseFileType {
                  id
                  code
                  description
                }
                documentTypeFieldGroups {
                  edges {
                    node {
                      id
                      fieldGroup {
                        repeatable
                        ...fieldGroup
                      }
                    }
                  }
                }
              }
              documentTables {
                edges {
                  node {
                    ...documentTable
                  }
                }
              }
              documentFieldGroups {
                edges {
                  node {
                    id
                    rowOrderPriority
                    document {
                      id
                    }
                    fieldGroup {
                      id
                      repeatable
                    }
                    documentFields {
                      edges {
                        node {
                          id
                          field {
                            id
                            autofillKey
                            key
                            name
                          }
                          value
                          confirmed
                          top
                          left
                          width
                          height
                          confidenceScore
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${JobTableFragmentDoc}
  ${FieldGroupFragmentDoc}
  ${FileAttributesFragmentDoc}
  ${DocumentTableFragmentDoc}
`

/**
 * __useGetJobDataQuery__
 *
 * To run a query within a React component, call `useGetJobDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobDataQuery, GetJobDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobDataQuery, GetJobDataQueryVariables>(GetJobDataDocument, options)
}
export function useGetJobDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobDataQuery, GetJobDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobDataQuery, GetJobDataQueryVariables>(GetJobDataDocument, options)
}
export type GetJobDataQueryHookResult = ReturnType<typeof useGetJobDataQuery>
export type GetJobDataLazyQueryHookResult = ReturnType<typeof useGetJobDataLazyQuery>
export type GetJobDataQueryResult = Apollo.QueryResult<GetJobDataQuery, GetJobDataQueryVariables>
export const JobOverviewPageDocument = gql`
  query jobOverviewPage(
    $page: Int!
    $query: String!
    $status: String
    $filters: [InputJobFilter!]
  ) {
    jobOverviewPage(page: $page, query: $query, status: $status, filters: $filters) {
      total
      items {
        id
        name
        slaTime
        dateCreated
        jobCode
        status
        clientReferenceNo
        taskId
        owner {
          email
          id
        }
        qa {
          email
          id
        }
        jobTemplate {
          id
          name
          companyId
          company {
            id
            name
          }
          jobTemplateExport {
            id
            jobTemplateExportType
          }
        }
        filePages {
          edges {
            node {
              id
              status
            }
          }
        }
      }
    }
  }
`

/**
 * __useJobOverviewPageQuery__
 *
 * To run a query within a React component, call `useJobOverviewPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobOverviewPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobOverviewPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      query: // value for 'query'
 *      status: // value for 'status'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useJobOverviewPageQuery(
  baseOptions: Apollo.QueryHookOptions<JobOverviewPageQuery, JobOverviewPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobOverviewPageQuery, JobOverviewPageQueryVariables>(
    JobOverviewPageDocument,
    options,
  )
}
export function useJobOverviewPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobOverviewPageQuery, JobOverviewPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobOverviewPageQuery, JobOverviewPageQueryVariables>(
    JobOverviewPageDocument,
    options,
  )
}
export type JobOverviewPageQueryHookResult = ReturnType<typeof useJobOverviewPageQuery>
export type JobOverviewPageLazyQueryHookResult = ReturnType<typeof useJobOverviewPageLazyQuery>
export type JobOverviewPageQueryResult = Apollo.QueryResult<
  JobOverviewPageQuery,
  JobOverviewPageQueryVariables
>
export const JobForExportDocument = gql`
  query jobForExport($id: UUID!) {
    job(id: $id) {
      ...JobForExport
    }
  }
  ${JobForExportFragmentDoc}
`

/**
 * __useJobForExportQuery__
 *
 * To run a query within a React component, call `useJobForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobForExportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobForExportQuery(
  baseOptions: Apollo.QueryHookOptions<JobForExportQuery, JobForExportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobForExportQuery, JobForExportQueryVariables>(
    JobForExportDocument,
    options,
  )
}
export function useJobForExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobForExportQuery, JobForExportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobForExportQuery, JobForExportQueryVariables>(
    JobForExportDocument,
    options,
  )
}
export type JobForExportQueryHookResult = ReturnType<typeof useJobForExportQuery>
export type JobForExportLazyQueryHookResult = ReturnType<typeof useJobForExportLazyQuery>
export type JobForExportQueryResult = Apollo.QueryResult<
  JobForExportQuery,
  JobForExportQueryVariables
>
export const UserJobTemplatesDocument = gql`
  query userJobTemplates {
    userJobTemplates {
      id
      name
      dateDeleted
      company {
        id
        name
      }
    }
  }
`

/**
 * __useUserJobTemplatesQuery__
 *
 * To run a query within a React component, call `useUserJobTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserJobTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserJobTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserJobTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<UserJobTemplatesQuery, UserJobTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserJobTemplatesQuery, UserJobTemplatesQueryVariables>(
    UserJobTemplatesDocument,
    options,
  )
}
export function useUserJobTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserJobTemplatesQuery, UserJobTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserJobTemplatesQuery, UserJobTemplatesQueryVariables>(
    UserJobTemplatesDocument,
    options,
  )
}
export type UserJobTemplatesQueryHookResult = ReturnType<typeof useUserJobTemplatesQuery>
export type UserJobTemplatesLazyQueryHookResult = ReturnType<typeof useUserJobTemplatesLazyQuery>
export type UserJobTemplatesQueryResult = Apollo.QueryResult<
  UserJobTemplatesQuery,
  UserJobTemplatesQueryVariables
>
export const JobNameDocument = gql`
  query jobName($id: UUID!) {
    job(id: $id) {
      id
      name
      jobTemplate {
        id
        name
        reconType
        shipmentOpsTypes
        apiPartner {
          id
          name
          apiPartnerCode {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ApiPartnerCodeEnum {
              value
              isFallback
            }
          }
          ... on CargowiseConfigNode {
            connectorType
          }
        }
        cargowiseConfig {
          id
          connectorType
        }
        documentTypes {
          edges {
            node {
              id
              documentTypeFieldGroups {
                edges {
                  node {
                    id
                    fieldGroup {
                      id
                      key
                      autofillKey
                      fields {
                        edges {
                          node {
                            id
                            key
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useJobNameQuery__
 *
 * To run a query within a React component, call `useJobNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobNameQuery(
  baseOptions: Apollo.QueryHookOptions<JobNameQuery, JobNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobNameQuery, JobNameQueryVariables>(JobNameDocument, options)
}
export function useJobNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobNameQuery, JobNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobNameQuery, JobNameQueryVariables>(JobNameDocument, options)
}
export type JobNameQueryHookResult = ReturnType<typeof useJobNameQuery>
export type JobNameLazyQueryHookResult = ReturnType<typeof useJobNameLazyQuery>
export type JobNameQueryResult = Apollo.QueryResult<JobNameQuery, JobNameQueryVariables>
export const JobOperatorsDocument = gql`
  query jobOperators {
    jobOperators {
      id
      name
      email
    }
  }
`

/**
 * __useJobOperatorsQuery__
 *
 * To run a query within a React component, call `useJobOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobOperatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobOperatorsQuery(
  baseOptions?: Apollo.QueryHookOptions<JobOperatorsQuery, JobOperatorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobOperatorsQuery, JobOperatorsQueryVariables>(
    JobOperatorsDocument,
    options,
  )
}
export function useJobOperatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobOperatorsQuery, JobOperatorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobOperatorsQuery, JobOperatorsQueryVariables>(
    JobOperatorsDocument,
    options,
  )
}
export type JobOperatorsQueryHookResult = ReturnType<typeof useJobOperatorsQuery>
export type JobOperatorsLazyQueryHookResult = ReturnType<typeof useJobOperatorsLazyQuery>
export type JobOperatorsQueryResult = Apollo.QueryResult<
  JobOperatorsQuery,
  JobOperatorsQueryVariables
>
export const JobsForExportDocument = gql`
  query jobsForExport($jobIds: [String!]!) {
    jobs(jobIds: $jobIds) {
      ...JobForExport
    }
  }
  ${JobForExportFragmentDoc}
`

/**
 * __useJobsForExportQuery__
 *
 * To run a query within a React component, call `useJobsForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsForExportQuery({
 *   variables: {
 *      jobIds: // value for 'jobIds'
 *   },
 * });
 */
export function useJobsForExportQuery(
  baseOptions: Apollo.QueryHookOptions<JobsForExportQuery, JobsForExportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobsForExportQuery, JobsForExportQueryVariables>(
    JobsForExportDocument,
    options,
  )
}
export function useJobsForExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobsForExportQuery, JobsForExportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobsForExportQuery, JobsForExportQueryVariables>(
    JobsForExportDocument,
    options,
  )
}
export type JobsForExportQueryHookResult = ReturnType<typeof useJobsForExportQuery>
export type JobsForExportLazyQueryHookResult = ReturnType<typeof useJobsForExportLazyQuery>
export type JobsForExportQueryResult = Apollo.QueryResult<
  JobsForExportQuery,
  JobsForExportQueryVariables
>
export const JobConfirmationPageDocument = gql`
  query jobConfirmationPage($id: UUID!) {
    job(id: $id) {
      id
      task {
        id
        dateConfirmed
        title
        taskReferenceId
        dateReceived
        message
      }
    }
  }
`

/**
 * __useJobConfirmationPageQuery__
 *
 * To run a query within a React component, call `useJobConfirmationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobConfirmationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobConfirmationPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobConfirmationPageQuery(
  baseOptions: Apollo.QueryHookOptions<JobConfirmationPageQuery, JobConfirmationPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobConfirmationPageQuery, JobConfirmationPageQueryVariables>(
    JobConfirmationPageDocument,
    options,
  )
}
export function useJobConfirmationPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobConfirmationPageQuery,
    JobConfirmationPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobConfirmationPageQuery, JobConfirmationPageQueryVariables>(
    JobConfirmationPageDocument,
    options,
  )
}
export type JobConfirmationPageQueryHookResult = ReturnType<typeof useJobConfirmationPageQuery>
export type JobConfirmationPageLazyQueryHookResult = ReturnType<
  typeof useJobConfirmationPageLazyQuery
>
export type JobConfirmationPageQueryResult = Apollo.QueryResult<
  JobConfirmationPageQuery,
  JobConfirmationPageQueryVariables
>
export const GetJobForTmsDocument = gql`
  query getJobForTms($id: UUID!) {
    job(id: $id) {
      id
      externalAssignee {
        id
        email
      }
      jobTemplate {
        id
        companyId
        apiPartnerId
        apiPartner {
          id
          name
          apiPartnerCode {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ApiPartnerCodeEnum {
              value
              isFallback
            }
          }
        }
      }
      jobTable {
        ...jobTable
      }
      filePages {
        edges {
          node {
            id
            document {
              id
              documentTables {
                edges {
                  node {
                    id
                    fieldGroup {
                      id
                      name
                      key
                      autofillKey
                      fields {
                        edges {
                          node {
                            id
                            name
                            key
                            autofillKey
                          }
                        }
                      }
                    }
                    documentFieldGroups {
                      edges {
                        node {
                          id
                          documentFields {
                            edges {
                              node {
                                id
                                field {
                                  id
                                  key
                                  autofillKey
                                  name
                                }
                                value
                                confirmed
                                confidenceScore
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              documentFieldGroups {
                edges {
                  node {
                    id
                    fieldGroup {
                      id
                      repeatable
                      name
                      autofillKey
                      fields {
                        edges {
                          node {
                            id
                            autofillKey
                          }
                        }
                      }
                    }
                    documentFields {
                      edges {
                        node {
                          id
                          field {
                            id
                            name
                            autofillKey
                          }
                          value
                          confidenceScore
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${JobTableFragmentDoc}
`

/**
 * __useGetJobForTmsQuery__
 *
 * To run a query within a React component, call `useGetJobForTmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobForTmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobForTmsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobForTmsQuery(
  baseOptions: Apollo.QueryHookOptions<GetJobForTmsQuery, GetJobForTmsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJobForTmsQuery, GetJobForTmsQueryVariables>(
    GetJobForTmsDocument,
    options,
  )
}
export function useGetJobForTmsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJobForTmsQuery, GetJobForTmsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJobForTmsQuery, GetJobForTmsQueryVariables>(
    GetJobForTmsDocument,
    options,
  )
}
export type GetJobForTmsQueryHookResult = ReturnType<typeof useGetJobForTmsQuery>
export type GetJobForTmsLazyQueryHookResult = ReturnType<typeof useGetJobForTmsLazyQuery>
export type GetJobForTmsQueryResult = Apollo.QueryResult<
  GetJobForTmsQuery,
  GetJobForTmsQueryVariables
>
export const JobSearchParametersDocument = gql`
  query jobSearchParameters {
    jobSearchParameters {
      queryString
      filters {
        id
        column
        operation
        value
      }
    }
  }
`

/**
 * __useJobSearchParametersQuery__
 *
 * To run a query within a React component, call `useJobSearchParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSearchParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSearchParametersQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobSearchParametersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobSearchParametersQuery,
    JobSearchParametersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobSearchParametersQuery, JobSearchParametersQueryVariables>(
    JobSearchParametersDocument,
    options,
  )
}
export function useJobSearchParametersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobSearchParametersQuery,
    JobSearchParametersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobSearchParametersQuery, JobSearchParametersQueryVariables>(
    JobSearchParametersDocument,
    options,
  )
}
export type JobSearchParametersQueryHookResult = ReturnType<typeof useJobSearchParametersQuery>
export type JobSearchParametersLazyQueryHookResult = ReturnType<
  typeof useJobSearchParametersLazyQuery
>
export type JobSearchParametersQueryResult = Apollo.QueryResult<
  JobSearchParametersQuery,
  JobSearchParametersQueryVariables
>
export const JobTemplateDocument = gql`
  query jobTemplate($id: UUID!, $companyId: UUID) {
    jobTemplate(id: $id, companyId: $companyId) {
      id
      name
      description
      slaTime
      reconType
      shipmentOpsTypes
      adminDisabled
      credentialId
      apiPartnerId
      defaultExternalAssignee {
        id
        email
      }
      autoReconEnabled
      subtotalsRowEnabled
      mainTabEnabled
      autoPostEnabled
      showPostButton
      requireShowReconToCustomer
      requireEdocsPushPull
      requireExternalAssignee
      companyId
      apiPartner {
        id
        name
        apiPartnerCode {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ApiPartnerCodeEnum {
            value
            isFallback
          }
        }
      }
      company {
        id
        name
      }
      jobTemplateExport {
        id
        jobTemplateExportType
        jobTemplateExportSheets {
          edges {
            node {
              id
              displayColumnHeader
              order
              fieldGroup {
                id
                name
              }
              jobTemplateExportColumns {
                edges {
                  node {
                    id
                    columnName
                    fixedValue
                    order
                    field {
                      id
                      name
                      values
                    }
                  }
                }
              }
            }
          }
        }
      }
      documentTypes {
        edges {
          node {
            id
            name
            isStandard
            collapsible
            tableShowsPreset
            cargowiseFileTypeId
            cargowiseFileType {
              id
              code
              description
            }
            autofillExtractorKey
            derivedFromId
            isEDocPublishedByDefault
            documentTypeFieldGroups {
              edges {
                node {
                  id
                  fieldGroup {
                    id
                    key
                    name
                    exportKey
                    repeatable
                    autofillKey
                    fields {
                      edges {
                        node {
                          id
                          name
                          key
                          autofillKey
                          exportKey
                          validatorRegex
                          validatorDescription
                          dateFormatString
                          required
                          rows
                          values
                          allowFreeText
                          columnOrder
                          defaultValue
                          fieldType {
                            ... on FallbackValue {
                              fallbackValue
                              isFallback
                            }
                            ... on FieldTypeEnum {
                              value
                              isFallback
                            }
                          }
                          partialTableAllowed
                          invalidCharsRegex
                          valueExportMapping
                          searchableRecord {
                            id
                            searchKey
                            displayKey
                            model
                            apiUrl
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      reconThresholdSettings {
        edges {
          node {
            id
            enabled
            dateDisabled
            reconThresholdRanges {
              edges {
                node {
                  id
                  minimum
                  maximum
                  thresholdAmount
                  usePercent
                  useAbsoluteValueMatching
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useJobTemplateQuery__
 *
 * To run a query within a React component, call `useJobTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useJobTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<JobTemplateQuery, JobTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobTemplateQuery, JobTemplateQueryVariables>(JobTemplateDocument, options)
}
export function useJobTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobTemplateQuery, JobTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobTemplateQuery, JobTemplateQueryVariables>(
    JobTemplateDocument,
    options,
  )
}
export type JobTemplateQueryHookResult = ReturnType<typeof useJobTemplateQuery>
export type JobTemplateLazyQueryHookResult = ReturnType<typeof useJobTemplateLazyQuery>
export type JobTemplateQueryResult = Apollo.QueryResult<JobTemplateQuery, JobTemplateQueryVariables>
export const JobTemplatesDocument = gql`
  query jobTemplates($companyId: UUID) {
    jobTemplates(companyId: $companyId) {
      id
      name
      company {
        id
        name
      }
    }
  }
`

/**
 * __useJobTemplatesQuery__
 *
 * To run a query within a React component, call `useJobTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobTemplatesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useJobTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<JobTemplatesQuery, JobTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobTemplatesQuery, JobTemplatesQueryVariables>(
    JobTemplatesDocument,
    options,
  )
}
export function useJobTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobTemplatesQuery, JobTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobTemplatesQuery, JobTemplatesQueryVariables>(
    JobTemplatesDocument,
    options,
  )
}
export type JobTemplatesQueryHookResult = ReturnType<typeof useJobTemplatesQuery>
export type JobTemplatesLazyQueryHookResult = ReturnType<typeof useJobTemplatesLazyQuery>
export type JobTemplatesQueryResult = Apollo.QueryResult<
  JobTemplatesQuery,
  JobTemplatesQueryVariables
>
export const IsValidReconJobTemplateDocument = gql`
  query isValidReconJobTemplate($jobTemplateId: UUID!) {
    isValidReconJobTemplate(jobTemplateId: $jobTemplateId) {
      ok
      errors
    }
  }
`

/**
 * __useIsValidReconJobTemplateQuery__
 *
 * To run a query within a React component, call `useIsValidReconJobTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsValidReconJobTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsValidReconJobTemplateQuery({
 *   variables: {
 *      jobTemplateId: // value for 'jobTemplateId'
 *   },
 * });
 */
export function useIsValidReconJobTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsValidReconJobTemplateQuery,
    IsValidReconJobTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IsValidReconJobTemplateQuery, IsValidReconJobTemplateQueryVariables>(
    IsValidReconJobTemplateDocument,
    options,
  )
}
export function useIsValidReconJobTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsValidReconJobTemplateQuery,
    IsValidReconJobTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IsValidReconJobTemplateQuery, IsValidReconJobTemplateQueryVariables>(
    IsValidReconJobTemplateDocument,
    options,
  )
}
export type IsValidReconJobTemplateQueryHookResult = ReturnType<
  typeof useIsValidReconJobTemplateQuery
>
export type IsValidReconJobTemplateLazyQueryHookResult = ReturnType<
  typeof useIsValidReconJobTemplateLazyQuery
>
export type IsValidReconJobTemplateQueryResult = Apollo.QueryResult<
  IsValidReconJobTemplateQuery,
  IsValidReconJobTemplateQueryVariables
>
export const JobNotesDocument = gql`
  query jobNotes($jobId: String!) {
    jobNotes(jobId: $jobId) {
      id
      dateCreated
      type
      text
      content
      source
      user {
        id
        email
        isCustomer
      }
      dateDeleted
      reconAttempt {
        id
        isAutoGenerated
      }
      jobActivity {
        ... on JobReceivedNode {
          job {
            id
            dateCreated
            task {
              id
              dateReceived
            }
          }
        }
        ... on JobRequestReprocessNode {
          reason
          correctedInfo
          autoPostAfterFixing
          additionalNotes
        }
        ... on JobExternalAssigneeChangeNode {
          additionalNotes
        }
        ... on JobPostToCWNode {
          additionalNotes
        }
        ... on JobMoveToDoneNode {
          additionalNotes
        }
        ... on JobReconApproveForPostingNode {
          additionalNotes
        }
        ... on JobReconAttemptSavedNode {
          additionalNotes
        }
        ... on JobReconCommentNode {
          additionalNotes
        }
        ... on JobReconExternalAssigneeChangeNode {
          additionalNotes
        }
        ... on JobReconExternalStatusChangeNode {
          additionalNotes
        }
        ... on JobReconMoveToDoneNode {
          additionalNotes
        }
        ... on JobReconRequestReprocessNode {
          reason
          correctedInfo
          autoPostAfterFixing
          additionalNotes
          reconInvoice {
            id
            invoiceNumber
          }
          reconInvoiceShipmentReference {
            id
            tmsId
            reconAttempt {
              id
              fieldValueMapV2
              matchReconInvoiceShipmentReference {
                id
                tmsId
              }
              findShipmentReconResult {
                id
                referenceNo
                hblNo
                mblNo
                carrierBookingNo
                containerNo
                consolNo
              }
            }
          }
        }
        ... on JobReconRequestReprocessTargetNode {
          sourceRequestReprocess {
            id
            reason
            correctedInfo
            autoPostAfterFixing
            additionalNotes
            reconInvoice {
              id
              invoiceNumber
            }
            reconInvoiceShipmentReference {
              id
              tmsId
              reconAttempt {
                id
                fieldValueMapV2
                matchReconInvoiceShipmentReference {
                  id
                  tmsId
                }
                findShipmentReconResult {
                  id
                  referenceNo
                  hblNo
                  mblNo
                  carrierBookingNo
                  containerNo
                  consolNo
                }
              }
            }
          }
        }
        ... on JobReconReceivedNode {
          additionalNotes
        }
      }
    }
  }
`

/**
 * __useJobNotesQuery__
 *
 * To run a query within a React component, call `useJobNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobNotesQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobNotesQuery(
  baseOptions: Apollo.QueryHookOptions<JobNotesQuery, JobNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JobNotesQuery, JobNotesQueryVariables>(JobNotesDocument, options)
}
export function useJobNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobNotesQuery, JobNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JobNotesQuery, JobNotesQueryVariables>(JobNotesDocument, options)
}
export type JobNotesQueryHookResult = ReturnType<typeof useJobNotesQuery>
export type JobNotesLazyQueryHookResult = ReturnType<typeof useJobNotesLazyQuery>
export type JobNotesQueryResult = Apollo.QueryResult<JobNotesQuery, JobNotesQueryVariables>
export const NotificationsDocument = gql`
  query notifications {
    notifications {
      id
      dateRead
      type
      pageAsyncBatch {
        id
        numDone
        numTasks
        errorMessage
        dateCompleted
        status
        type
        job {
          id
          status
          name
        }
        pageAsyncTasks {
          edges {
            node {
              id
              filePage {
                id
                pageNumber
                file {
                  id
                  filename
                  emailSubject
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  )
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  )
}
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>
export const UserProfileDocument = gql`
  query userProfile {
    userProfile {
      auth0Id
      email
      name
      metabaseDashboardId
    }
  }
`

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options)
}
export function useUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(
    UserProfileDocument,
    options,
  )
}
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>
export const GetUserRolesDocument = gql`
  query getUserRoles {
    userProfile {
      roles
    }
  }
`

/**
 * __useGetUserRolesQuery__
 *
 * To run a query within a React component, call `useGetUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(
    GetUserRolesDocument,
    options,
  )
}
export function useGetUserRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(
    GetUserRolesDocument,
    options,
  )
}
export type GetUserRolesQueryHookResult = ReturnType<typeof useGetUserRolesQuery>
export type GetUserRolesLazyQueryHookResult = ReturnType<typeof useGetUserRolesLazyQuery>
export type GetUserRolesQueryResult = Apollo.QueryResult<
  GetUserRolesQuery,
  GetUserRolesQueryVariables
>
export const ExistingReconAttemptDocument = gql`
  query existingReconAttempt($jobId: String!) {
    existingReconAttempt(jobId: $jobId) {
      id
      resolutionStatus
      userResolved {
        id
        email
      }
      dateResolved
    }
  }
`

/**
 * __useExistingReconAttemptQuery__
 *
 * To run a query within a React component, call `useExistingReconAttemptQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistingReconAttemptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistingReconAttemptQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useExistingReconAttemptQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExistingReconAttemptQuery,
    ExistingReconAttemptQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ExistingReconAttemptQuery, ExistingReconAttemptQueryVariables>(
    ExistingReconAttemptDocument,
    options,
  )
}
export function useExistingReconAttemptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExistingReconAttemptQuery,
    ExistingReconAttemptQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ExistingReconAttemptQuery, ExistingReconAttemptQueryVariables>(
    ExistingReconAttemptDocument,
    options,
  )
}
export type ExistingReconAttemptQueryHookResult = ReturnType<typeof useExistingReconAttemptQuery>
export type ExistingReconAttemptLazyQueryHookResult = ReturnType<
  typeof useExistingReconAttemptLazyQuery
>
export type ExistingReconAttemptQueryResult = Apollo.QueryResult<
  ExistingReconAttemptQuery,
  ExistingReconAttemptQueryVariables
>
export const ChargeVendorsDocument = gql`
  query chargeVendors($companyId: UUID!, $query: String!) {
    chargeVendors(companyId: $companyId, query: $query) {
      id
      name
      code
      type
      dateDeleted
    }
  }
`

/**
 * __useChargeVendorsQuery__
 *
 * To run a query within a React component, call `useChargeVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeVendorsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useChargeVendorsQuery(
  baseOptions: Apollo.QueryHookOptions<ChargeVendorsQuery, ChargeVendorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChargeVendorsQuery, ChargeVendorsQueryVariables>(
    ChargeVendorsDocument,
    options,
  )
}
export function useChargeVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChargeVendorsQuery, ChargeVendorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ChargeVendorsQuery, ChargeVendorsQueryVariables>(
    ChargeVendorsDocument,
    options,
  )
}
export type ChargeVendorsQueryHookResult = ReturnType<typeof useChargeVendorsQuery>
export type ChargeVendorsLazyQueryHookResult = ReturnType<typeof useChargeVendorsLazyQuery>
export type ChargeVendorsQueryResult = Apollo.QueryResult<
  ChargeVendorsQuery,
  ChargeVendorsQueryVariables
>
export const ChargeVendorsWithCodesDocument = gql`
  query chargeVendorsWithCodes(
    $companyId: UUID!
    $query: String!
    $limit: Int
    $withChargeCodes: Boolean
  ) {
    chargeVendors(
      companyId: $companyId
      query: $query
      limit: $limit
      withChargeCodes: $withChargeCodes
    ) {
      id
      name
      code
      type
      dateDeleted
      chargeCodes {
        edges {
          node {
            id
            code
            description
            dateDeleted
            apiPartnerId
          }
        }
      }
    }
  }
`

/**
 * __useChargeVendorsWithCodesQuery__
 *
 * To run a query within a React component, call `useChargeVendorsWithCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeVendorsWithCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeVendorsWithCodesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      withChargeCodes: // value for 'withChargeCodes'
 *   },
 * });
 */
export function useChargeVendorsWithCodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChargeVendorsWithCodesQuery,
    ChargeVendorsWithCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChargeVendorsWithCodesQuery, ChargeVendorsWithCodesQueryVariables>(
    ChargeVendorsWithCodesDocument,
    options,
  )
}
export function useChargeVendorsWithCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargeVendorsWithCodesQuery,
    ChargeVendorsWithCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ChargeVendorsWithCodesQuery, ChargeVendorsWithCodesQueryVariables>(
    ChargeVendorsWithCodesDocument,
    options,
  )
}
export type ChargeVendorsWithCodesQueryHookResult = ReturnType<
  typeof useChargeVendorsWithCodesQuery
>
export type ChargeVendorsWithCodesLazyQueryHookResult = ReturnType<
  typeof useChargeVendorsWithCodesLazyQuery
>
export type ChargeVendorsWithCodesQueryResult = Apollo.QueryResult<
  ChargeVendorsWithCodesQuery,
  ChargeVendorsWithCodesQueryVariables
>
export const ChargeVendorsWithCodeOverridesDocument = gql`
  query chargeVendorsWithCodeOverrides($companyId: UUID!, $query: String!, $limit: Int) {
    chargeVendors(companyId: $companyId, query: $query, limit: $limit) {
      id
      name
      code
      type
      dateDeleted
      chargeCodeVendorOverrides {
        edges {
          node {
            id
            description
            dateDeleted
            chargeCode {
              id
              code
              dateDeleted
              apiPartnerId
            }
          }
        }
      }
    }
  }
`

/**
 * __useChargeVendorsWithCodeOverridesQuery__
 *
 * To run a query within a React component, call `useChargeVendorsWithCodeOverridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeVendorsWithCodeOverridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeVendorsWithCodeOverridesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useChargeVendorsWithCodeOverridesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChargeVendorsWithCodeOverridesQuery,
    ChargeVendorsWithCodeOverridesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ChargeVendorsWithCodeOverridesQuery,
    ChargeVendorsWithCodeOverridesQueryVariables
  >(ChargeVendorsWithCodeOverridesDocument, options)
}
export function useChargeVendorsWithCodeOverridesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargeVendorsWithCodeOverridesQuery,
    ChargeVendorsWithCodeOverridesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ChargeVendorsWithCodeOverridesQuery,
    ChargeVendorsWithCodeOverridesQueryVariables
  >(ChargeVendorsWithCodeOverridesDocument, options)
}
export type ChargeVendorsWithCodeOverridesQueryHookResult = ReturnType<
  typeof useChargeVendorsWithCodeOverridesQuery
>
export type ChargeVendorsWithCodeOverridesLazyQueryHookResult = ReturnType<
  typeof useChargeVendorsWithCodeOverridesLazyQuery
>
export type ChargeVendorsWithCodeOverridesQueryResult = Apollo.QueryResult<
  ChargeVendorsWithCodeOverridesQuery,
  ChargeVendorsWithCodeOverridesQueryVariables
>
export const CompanyChargeVendorsDocument = gql`
  query companyChargeVendors($companyId: UUID!) {
    companyChargeVendors(companyId: $companyId) {
      id
      name
      code
      type
      chargeCodes {
        edges {
          node {
            id
            code
            description
            dateDeleted
            apiPartnerId
          }
        }
      }
    }
  }
`

/**
 * __useCompanyChargeVendorsQuery__
 *
 * To run a query within a React component, call `useCompanyChargeVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyChargeVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyChargeVendorsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyChargeVendorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyChargeVendorsQuery,
    CompanyChargeVendorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyChargeVendorsQuery, CompanyChargeVendorsQueryVariables>(
    CompanyChargeVendorsDocument,
    options,
  )
}
export function useCompanyChargeVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyChargeVendorsQuery,
    CompanyChargeVendorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyChargeVendorsQuery, CompanyChargeVendorsQueryVariables>(
    CompanyChargeVendorsDocument,
    options,
  )
}
export type CompanyChargeVendorsQueryHookResult = ReturnType<typeof useCompanyChargeVendorsQuery>
export type CompanyChargeVendorsLazyQueryHookResult = ReturnType<
  typeof useCompanyChargeVendorsLazyQuery
>
export type CompanyChargeVendorsQueryResult = Apollo.QueryResult<
  CompanyChargeVendorsQuery,
  CompanyChargeVendorsQueryVariables
>
export const CompanyChargeVendorsAndOverridesDocument = gql`
  query companyChargeVendorsAndOverrides($companyId: UUID!) {
    companyChargeVendors(companyId: $companyId) {
      id
      name
      code
      type
      chargeCodeVendorOverrides {
        edges {
          node {
            id
            description
            dateDeleted
            chargeCodeId
            chargeCode {
              id
              code
              dateDeleted
            }
          }
        }
      }
    }
  }
`

/**
 * __useCompanyChargeVendorsAndOverridesQuery__
 *
 * To run a query within a React component, call `useCompanyChargeVendorsAndOverridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyChargeVendorsAndOverridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyChargeVendorsAndOverridesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyChargeVendorsAndOverridesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyChargeVendorsAndOverridesQuery,
    CompanyChargeVendorsAndOverridesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompanyChargeVendorsAndOverridesQuery,
    CompanyChargeVendorsAndOverridesQueryVariables
  >(CompanyChargeVendorsAndOverridesDocument, options)
}
export function useCompanyChargeVendorsAndOverridesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyChargeVendorsAndOverridesQuery,
    CompanyChargeVendorsAndOverridesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompanyChargeVendorsAndOverridesQuery,
    CompanyChargeVendorsAndOverridesQueryVariables
  >(CompanyChargeVendorsAndOverridesDocument, options)
}
export type CompanyChargeVendorsAndOverridesQueryHookResult = ReturnType<
  typeof useCompanyChargeVendorsAndOverridesQuery
>
export type CompanyChargeVendorsAndOverridesLazyQueryHookResult = ReturnType<
  typeof useCompanyChargeVendorsAndOverridesLazyQuery
>
export type CompanyChargeVendorsAndOverridesQueryResult = Apollo.QueryResult<
  CompanyChargeVendorsAndOverridesQuery,
  CompanyChargeVendorsAndOverridesQueryVariables
>
export const ChargeVendorCodesDocument = gql`
  query chargeVendorCodes(
    $chargeVendorId: UUID!
    $pageLimit: Int
    $pageOffset: Int
    $apiPartnerId: UUID
  ) {
    vendorChargeCodes(
      chargeVendorId: $chargeVendorId
      pageLimit: $pageLimit
      pageOffset: $pageOffset
      apiPartnerId: $apiPartnerId
    ) {
      id
      code
      description
      dateDeleted
      apiPartnerId
    }
  }
`

/**
 * __useChargeVendorCodesQuery__
 *
 * To run a query within a React component, call `useChargeVendorCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeVendorCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeVendorCodesQuery({
 *   variables: {
 *      chargeVendorId: // value for 'chargeVendorId'
 *      pageLimit: // value for 'pageLimit'
 *      pageOffset: // value for 'pageOffset'
 *      apiPartnerId: // value for 'apiPartnerId'
 *   },
 * });
 */
export function useChargeVendorCodesQuery(
  baseOptions: Apollo.QueryHookOptions<ChargeVendorCodesQuery, ChargeVendorCodesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChargeVendorCodesQuery, ChargeVendorCodesQueryVariables>(
    ChargeVendorCodesDocument,
    options,
  )
}
export function useChargeVendorCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargeVendorCodesQuery,
    ChargeVendorCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ChargeVendorCodesQuery, ChargeVendorCodesQueryVariables>(
    ChargeVendorCodesDocument,
    options,
  )
}
export type ChargeVendorCodesQueryHookResult = ReturnType<typeof useChargeVendorCodesQuery>
export type ChargeVendorCodesLazyQueryHookResult = ReturnType<typeof useChargeVendorCodesLazyQuery>
export type ChargeVendorCodesQueryResult = Apollo.QueryResult<
  ChargeVendorCodesQuery,
  ChargeVendorCodesQueryVariables
>
export const VendorChargeCodeOverridesDocument = gql`
  query vendorChargeCodeOverrides(
    $chargeVendorId: UUID!
    $apiPartnerId: UUID
    $pageLimit: Int
    $pageOffset: Int
  ) {
    vendorChargeCodeOverrides(
      chargeVendorId: $chargeVendorId
      apiPartnerId: $apiPartnerId
      pageLimit: $pageLimit
      pageOffset: $pageOffset
    ) {
      id
      description
      dateDeleted
      chargeVendor {
        id
        name
        code
        dateDeleted
      }
      chargeCode {
        id
        code
        description
        dateDeleted
      }
    }
  }
`

/**
 * __useVendorChargeCodeOverridesQuery__
 *
 * To run a query within a React component, call `useVendorChargeCodeOverridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorChargeCodeOverridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorChargeCodeOverridesQuery({
 *   variables: {
 *      chargeVendorId: // value for 'chargeVendorId'
 *      apiPartnerId: // value for 'apiPartnerId'
 *      pageLimit: // value for 'pageLimit'
 *      pageOffset: // value for 'pageOffset'
 *   },
 * });
 */
export function useVendorChargeCodeOverridesQuery(
  baseOptions: Apollo.QueryHookOptions<
    VendorChargeCodeOverridesQuery,
    VendorChargeCodeOverridesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VendorChargeCodeOverridesQuery, VendorChargeCodeOverridesQueryVariables>(
    VendorChargeCodeOverridesDocument,
    options,
  )
}
export function useVendorChargeCodeOverridesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VendorChargeCodeOverridesQuery,
    VendorChargeCodeOverridesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VendorChargeCodeOverridesQuery,
    VendorChargeCodeOverridesQueryVariables
  >(VendorChargeCodeOverridesDocument, options)
}
export type VendorChargeCodeOverridesQueryHookResult = ReturnType<
  typeof useVendorChargeCodeOverridesQuery
>
export type VendorChargeCodeOverridesLazyQueryHookResult = ReturnType<
  typeof useVendorChargeCodeOverridesLazyQuery
>
export type VendorChargeCodeOverridesQueryResult = Apollo.QueryResult<
  VendorChargeCodeOverridesQuery,
  VendorChargeCodeOverridesQueryVariables
>
export const ChargeVendorsOkDocument = gql`
  query chargeVendorsOk($companyId: UUID!, $chargeVendors: InputVendorCsv!) {
    chargeVendorsOk(companyId: $companyId, chargeVendors: $chargeVendors)
  }
`

/**
 * __useChargeVendorsOkQuery__
 *
 * To run a query within a React component, call `useChargeVendorsOkQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeVendorsOkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeVendorsOkQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      chargeVendors: // value for 'chargeVendors'
 *   },
 * });
 */
export function useChargeVendorsOkQuery(
  baseOptions: Apollo.QueryHookOptions<ChargeVendorsOkQuery, ChargeVendorsOkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChargeVendorsOkQuery, ChargeVendorsOkQueryVariables>(
    ChargeVendorsOkDocument,
    options,
  )
}
export function useChargeVendorsOkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChargeVendorsOkQuery, ChargeVendorsOkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ChargeVendorsOkQuery, ChargeVendorsOkQueryVariables>(
    ChargeVendorsOkDocument,
    options,
  )
}
export type ChargeVendorsOkQueryHookResult = ReturnType<typeof useChargeVendorsOkQuery>
export type ChargeVendorsOkLazyQueryHookResult = ReturnType<typeof useChargeVendorsOkLazyQuery>
export type ChargeVendorsOkQueryResult = Apollo.QueryResult<
  ChargeVendorsOkQuery,
  ChargeVendorsOkQueryVariables
>
export const ChargeCodesOkDocument = gql`
  query chargeCodesOk(
    $companyId: UUID!
    $apiPartnerIds: [UUID!]!
    $chargeCodesCsv: InputChargeCodeCsv!
  ) {
    chargeCodesOk(
      companyId: $companyId
      apiPartnerIds: $apiPartnerIds
      chargeCodesCsv: $chargeCodesCsv
    )
  }
`

/**
 * __useChargeCodesOkQuery__
 *
 * To run a query within a React component, call `useChargeCodesOkQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeCodesOkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeCodesOkQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      apiPartnerIds: // value for 'apiPartnerIds'
 *      chargeCodesCsv: // value for 'chargeCodesCsv'
 *   },
 * });
 */
export function useChargeCodesOkQuery(
  baseOptions: Apollo.QueryHookOptions<ChargeCodesOkQuery, ChargeCodesOkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChargeCodesOkQuery, ChargeCodesOkQueryVariables>(
    ChargeCodesOkDocument,
    options,
  )
}
export function useChargeCodesOkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChargeCodesOkQuery, ChargeCodesOkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ChargeCodesOkQuery, ChargeCodesOkQueryVariables>(
    ChargeCodesOkDocument,
    options,
  )
}
export type ChargeCodesOkQueryHookResult = ReturnType<typeof useChargeCodesOkQuery>
export type ChargeCodesOkLazyQueryHookResult = ReturnType<typeof useChargeCodesOkLazyQuery>
export type ChargeCodesOkQueryResult = Apollo.QueryResult<
  ChargeCodesOkQuery,
  ChargeCodesOkQueryVariables
>
export const ChargeCodesByVendorNamesDocument = gql`
  query chargeCodesByVendorNames(
    $companyId: UUID!
    $chargeVendorNames: [String!]!
    $apiPartnerId: UUID!
  ) {
    chargeCodesByVendorNames(
      companyId: $companyId
      chargeVendorNames: $chargeVendorNames
      apiPartnerId: $apiPartnerId
    ) {
      id
      code
    }
  }
`

/**
 * __useChargeCodesByVendorNamesQuery__
 *
 * To run a query within a React component, call `useChargeCodesByVendorNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeCodesByVendorNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeCodesByVendorNamesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      chargeVendorNames: // value for 'chargeVendorNames'
 *      apiPartnerId: // value for 'apiPartnerId'
 *   },
 * });
 */
export function useChargeCodesByVendorNamesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChargeCodesByVendorNamesQuery,
    ChargeCodesByVendorNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChargeCodesByVendorNamesQuery, ChargeCodesByVendorNamesQueryVariables>(
    ChargeCodesByVendorNamesDocument,
    options,
  )
}
export function useChargeCodesByVendorNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargeCodesByVendorNamesQuery,
    ChargeCodesByVendorNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ChargeCodesByVendorNamesQuery, ChargeCodesByVendorNamesQueryVariables>(
    ChargeCodesByVendorNamesDocument,
    options,
  )
}
export type ChargeCodesByVendorNamesQueryHookResult = ReturnType<
  typeof useChargeCodesByVendorNamesQuery
>
export type ChargeCodesByVendorNamesLazyQueryHookResult = ReturnType<
  typeof useChargeCodesByVendorNamesLazyQuery
>
export type ChargeCodesByVendorNamesQueryResult = Apollo.QueryResult<
  ChargeCodesByVendorNamesQuery,
  ChargeCodesByVendorNamesQueryVariables
>
export const ChargeVendorsByCodesDocument = gql`
  query chargeVendorsByCodes($companyId: UUID!, $chargeVendorCodes: [String!]!) {
    chargeVendorsByCodes(companyId: $companyId, chargeVendorCodes: $chargeVendorCodes) {
      id
      name
      code
      chargeCodes {
        edges {
          node {
            id
            code
            description
            cleanedDescription
            dateDeleted
          }
        }
      }
    }
  }
`

/**
 * __useChargeVendorsByCodesQuery__
 *
 * To run a query within a React component, call `useChargeVendorsByCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeVendorsByCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeVendorsByCodesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      chargeVendorCodes: // value for 'chargeVendorCodes'
 *   },
 * });
 */
export function useChargeVendorsByCodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChargeVendorsByCodesQuery,
    ChargeVendorsByCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChargeVendorsByCodesQuery, ChargeVendorsByCodesQueryVariables>(
    ChargeVendorsByCodesDocument,
    options,
  )
}
export function useChargeVendorsByCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargeVendorsByCodesQuery,
    ChargeVendorsByCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ChargeVendorsByCodesQuery, ChargeVendorsByCodesQueryVariables>(
    ChargeVendorsByCodesDocument,
    options,
  )
}
export type ChargeVendorsByCodesQueryHookResult = ReturnType<typeof useChargeVendorsByCodesQuery>
export type ChargeVendorsByCodesLazyQueryHookResult = ReturnType<
  typeof useChargeVendorsByCodesLazyQuery
>
export type ChargeVendorsByCodesQueryResult = Apollo.QueryResult<
  ChargeVendorsByCodesQuery,
  ChargeVendorsByCodesQueryVariables
>
export const CompanyChargeCodesDocument = gql`
  query companyChargeCodes($companyId: UUID!, $apiPartnerId: UUID, $query: String!) {
    companyChargeCodes(companyId: $companyId, apiPartnerId: $apiPartnerId, query: $query) {
      id
      code
      tax {
        id
        taxCode
        taxRate
        dateDeleted
      }
      description
      dateDeleted
    }
  }
`

/**
 * __useCompanyChargeCodesQuery__
 *
 * To run a query within a React component, call `useCompanyChargeCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyChargeCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyChargeCodesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      apiPartnerId: // value for 'apiPartnerId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCompanyChargeCodesQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyChargeCodesQuery, CompanyChargeCodesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyChargeCodesQuery, CompanyChargeCodesQueryVariables>(
    CompanyChargeCodesDocument,
    options,
  )
}
export function useCompanyChargeCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyChargeCodesQuery,
    CompanyChargeCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyChargeCodesQuery, CompanyChargeCodesQueryVariables>(
    CompanyChargeCodesDocument,
    options,
  )
}
export type CompanyChargeCodesQueryHookResult = ReturnType<typeof useCompanyChargeCodesQuery>
export type CompanyChargeCodesLazyQueryHookResult = ReturnType<
  typeof useCompanyChargeCodesLazyQuery
>
export type CompanyChargeCodesQueryResult = Apollo.QueryResult<
  CompanyChargeCodesQuery,
  CompanyChargeCodesQueryVariables
>
export const CompanyChargeCodesV2Document = gql`
  query companyChargeCodesV2($companyId: UUID!, $apiPartnerId: UUID, $query: String!) {
    companyChargeCodesV2(companyId: $companyId, apiPartnerId: $apiPartnerId, query: $query) {
      id
      code
      tax {
        id
        taxCode
        taxRate
        dateDeleted
      }
      description
      dateDeleted
    }
  }
`

/**
 * __useCompanyChargeCodesV2Query__
 *
 * To run a query within a React component, call `useCompanyChargeCodesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useCompanyChargeCodesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyChargeCodesV2Query({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      apiPartnerId: // value for 'apiPartnerId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCompanyChargeCodesV2Query(
  baseOptions: Apollo.QueryHookOptions<
    CompanyChargeCodesV2Query,
    CompanyChargeCodesV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyChargeCodesV2Query, CompanyChargeCodesV2QueryVariables>(
    CompanyChargeCodesV2Document,
    options,
  )
}
export function useCompanyChargeCodesV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyChargeCodesV2Query,
    CompanyChargeCodesV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyChargeCodesV2Query, CompanyChargeCodesV2QueryVariables>(
    CompanyChargeCodesV2Document,
    options,
  )
}
export type CompanyChargeCodesV2QueryHookResult = ReturnType<typeof useCompanyChargeCodesV2Query>
export type CompanyChargeCodesV2LazyQueryHookResult = ReturnType<
  typeof useCompanyChargeCodesV2LazyQuery
>
export type CompanyChargeCodesV2QueryResult = Apollo.QueryResult<
  CompanyChargeCodesV2Query,
  CompanyChargeCodesV2QueryVariables
>
export const AllCompanyChargeCodesDocument = gql`
  query allCompanyChargeCodes($companyId: UUID!, $apiPartnerId: UUID) {
    allCompanyChargeCodes(companyId: $companyId, apiPartnerId: $apiPartnerId)
  }
`

/**
 * __useAllCompanyChargeCodesQuery__
 *
 * To run a query within a React component, call `useAllCompanyChargeCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompanyChargeCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompanyChargeCodesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      apiPartnerId: // value for 'apiPartnerId'
 *   },
 * });
 */
export function useAllCompanyChargeCodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllCompanyChargeCodesQuery,
    AllCompanyChargeCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllCompanyChargeCodesQuery, AllCompanyChargeCodesQueryVariables>(
    AllCompanyChargeCodesDocument,
    options,
  )
}
export function useAllCompanyChargeCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCompanyChargeCodesQuery,
    AllCompanyChargeCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllCompanyChargeCodesQuery, AllCompanyChargeCodesQueryVariables>(
    AllCompanyChargeCodesDocument,
    options,
  )
}
export type AllCompanyChargeCodesQueryHookResult = ReturnType<typeof useAllCompanyChargeCodesQuery>
export type AllCompanyChargeCodesLazyQueryHookResult = ReturnType<
  typeof useAllCompanyChargeCodesLazyQuery
>
export type AllCompanyChargeCodesQueryResult = Apollo.QueryResult<
  AllCompanyChargeCodesQuery,
  AllCompanyChargeCodesQueryVariables
>
export const ChargeCodesByConfigAndVendorDocument = gql`
  query chargeCodesByConfigAndVendor($apiPartnerId: UUID, $vendorName: String) {
    chargeCodesByConfigAndVendor(apiPartnerId: $apiPartnerId, vendorName: $vendorName) {
      id
      code
      tax {
        id
        taxCode
        taxRate
        dateDeleted
      }
      description
      dateDeleted
    }
  }
`

/**
 * __useChargeCodesByConfigAndVendorQuery__
 *
 * To run a query within a React component, call `useChargeCodesByConfigAndVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeCodesByConfigAndVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeCodesByConfigAndVendorQuery({
 *   variables: {
 *      apiPartnerId: // value for 'apiPartnerId'
 *      vendorName: // value for 'vendorName'
 *   },
 * });
 */
export function useChargeCodesByConfigAndVendorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChargeCodesByConfigAndVendorQuery,
    ChargeCodesByConfigAndVendorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ChargeCodesByConfigAndVendorQuery,
    ChargeCodesByConfigAndVendorQueryVariables
  >(ChargeCodesByConfigAndVendorDocument, options)
}
export function useChargeCodesByConfigAndVendorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargeCodesByConfigAndVendorQuery,
    ChargeCodesByConfigAndVendorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ChargeCodesByConfigAndVendorQuery,
    ChargeCodesByConfigAndVendorQueryVariables
  >(ChargeCodesByConfigAndVendorDocument, options)
}
export type ChargeCodesByConfigAndVendorQueryHookResult = ReturnType<
  typeof useChargeCodesByConfigAndVendorQuery
>
export type ChargeCodesByConfigAndVendorLazyQueryHookResult = ReturnType<
  typeof useChargeCodesByConfigAndVendorLazyQuery
>
export type ChargeCodesByConfigAndVendorQueryResult = Apollo.QueryResult<
  ChargeCodesByConfigAndVendorQuery,
  ChargeCodesByConfigAndVendorQueryVariables
>
export const ChargeCodeTaxesByApiPartnerDocument = gql`
  query chargeCodeTaxesByApiPartner($apiPartnerId: UUID!) {
    chargeCodesByApiPartner(apiPartnerId: $apiPartnerId) {
      id
      code
      tax {
        id
        taxCode
        taxRate
        dateDeleted
      }
    }
  }
`

/**
 * __useChargeCodeTaxesByApiPartnerQuery__
 *
 * To run a query within a React component, call `useChargeCodeTaxesByApiPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeCodeTaxesByApiPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeCodeTaxesByApiPartnerQuery({
 *   variables: {
 *      apiPartnerId: // value for 'apiPartnerId'
 *   },
 * });
 */
export function useChargeCodeTaxesByApiPartnerQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChargeCodeTaxesByApiPartnerQuery,
    ChargeCodeTaxesByApiPartnerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ChargeCodeTaxesByApiPartnerQuery,
    ChargeCodeTaxesByApiPartnerQueryVariables
  >(ChargeCodeTaxesByApiPartnerDocument, options)
}
export function useChargeCodeTaxesByApiPartnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargeCodeTaxesByApiPartnerQuery,
    ChargeCodeTaxesByApiPartnerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ChargeCodeTaxesByApiPartnerQuery,
    ChargeCodeTaxesByApiPartnerQueryVariables
  >(ChargeCodeTaxesByApiPartnerDocument, options)
}
export type ChargeCodeTaxesByApiPartnerQueryHookResult = ReturnType<
  typeof useChargeCodeTaxesByApiPartnerQuery
>
export type ChargeCodeTaxesByApiPartnerLazyQueryHookResult = ReturnType<
  typeof useChargeCodeTaxesByApiPartnerLazyQuery
>
export type ChargeCodeTaxesByApiPartnerQueryResult = Apollo.QueryResult<
  ChargeCodeTaxesByApiPartnerQuery,
  ChargeCodeTaxesByApiPartnerQueryVariables
>
export const TaxIdsByApiPartnerDocument = gql`
  query taxIdsByApiPartner($apiPartnerId: UUID!) {
    taxIdsByApiPartner(apiPartnerId: $apiPartnerId) {
      id
      taxCode
      taxRate
      dateDeleted
    }
  }
`

/**
 * __useTaxIdsByApiPartnerQuery__
 *
 * To run a query within a React component, call `useTaxIdsByApiPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxIdsByApiPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxIdsByApiPartnerQuery({
 *   variables: {
 *      apiPartnerId: // value for 'apiPartnerId'
 *   },
 * });
 */
export function useTaxIdsByApiPartnerQuery(
  baseOptions: Apollo.QueryHookOptions<TaxIdsByApiPartnerQuery, TaxIdsByApiPartnerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TaxIdsByApiPartnerQuery, TaxIdsByApiPartnerQueryVariables>(
    TaxIdsByApiPartnerDocument,
    options,
  )
}
export function useTaxIdsByApiPartnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaxIdsByApiPartnerQuery,
    TaxIdsByApiPartnerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TaxIdsByApiPartnerQuery, TaxIdsByApiPartnerQueryVariables>(
    TaxIdsByApiPartnerDocument,
    options,
  )
}
export type TaxIdsByApiPartnerQueryHookResult = ReturnType<typeof useTaxIdsByApiPartnerQuery>
export type TaxIdsByApiPartnerLazyQueryHookResult = ReturnType<
  typeof useTaxIdsByApiPartnerLazyQuery
>
export type TaxIdsByApiPartnerQueryResult = Apollo.QueryResult<
  TaxIdsByApiPartnerQuery,
  TaxIdsByApiPartnerQueryVariables
>
export const UniqueChargeCodesByTaxDocument = gql`
  query uniqueChargeCodesByTax($taxId: UUID!, $apiPartnerId: UUID) {
    uniqueChargeCodesByTax(taxId: $taxId, apiPartnerId: $apiPartnerId)
  }
`

/**
 * __useUniqueChargeCodesByTaxQuery__
 *
 * To run a query within a React component, call `useUniqueChargeCodesByTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniqueChargeCodesByTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniqueChargeCodesByTaxQuery({
 *   variables: {
 *      taxId: // value for 'taxId'
 *      apiPartnerId: // value for 'apiPartnerId'
 *   },
 * });
 */
export function useUniqueChargeCodesByTaxQuery(
  baseOptions: Apollo.QueryHookOptions<
    UniqueChargeCodesByTaxQuery,
    UniqueChargeCodesByTaxQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UniqueChargeCodesByTaxQuery, UniqueChargeCodesByTaxQueryVariables>(
    UniqueChargeCodesByTaxDocument,
    options,
  )
}
export function useUniqueChargeCodesByTaxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UniqueChargeCodesByTaxQuery,
    UniqueChargeCodesByTaxQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UniqueChargeCodesByTaxQuery, UniqueChargeCodesByTaxQueryVariables>(
    UniqueChargeCodesByTaxDocument,
    options,
  )
}
export type UniqueChargeCodesByTaxQueryHookResult = ReturnType<
  typeof useUniqueChargeCodesByTaxQuery
>
export type UniqueChargeCodesByTaxLazyQueryHookResult = ReturnType<
  typeof useUniqueChargeCodesByTaxLazyQuery
>
export type UniqueChargeCodesByTaxQueryResult = Apollo.QueryResult<
  UniqueChargeCodesByTaxQuery,
  UniqueChargeCodesByTaxQueryVariables
>
export const ChargeCodeAndVendorOverridesDocument = gql`
  query chargeCodeAndVendorOverrides($chargeCodeId: UUID!) {
    chargeCodeAndVendorOverrides(chargeCodeId: $chargeCodeId) {
      id
      code
      description
      chargeCodeVendorOverrides {
        edges {
          node {
            id
            description
            dateDeleted
            chargeVendor {
              id
              dateDeleted
              name
              code
              type
            }
          }
        }
      }
    }
  }
`

/**
 * __useChargeCodeAndVendorOverridesQuery__
 *
 * To run a query within a React component, call `useChargeCodeAndVendorOverridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeCodeAndVendorOverridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeCodeAndVendorOverridesQuery({
 *   variables: {
 *      chargeCodeId: // value for 'chargeCodeId'
 *   },
 * });
 */
export function useChargeCodeAndVendorOverridesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChargeCodeAndVendorOverridesQuery,
    ChargeCodeAndVendorOverridesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ChargeCodeAndVendorOverridesQuery,
    ChargeCodeAndVendorOverridesQueryVariables
  >(ChargeCodeAndVendorOverridesDocument, options)
}
export function useChargeCodeAndVendorOverridesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargeCodeAndVendorOverridesQuery,
    ChargeCodeAndVendorOverridesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ChargeCodeAndVendorOverridesQuery,
    ChargeCodeAndVendorOverridesQueryVariables
  >(ChargeCodeAndVendorOverridesDocument, options)
}
export type ChargeCodeAndVendorOverridesQueryHookResult = ReturnType<
  typeof useChargeCodeAndVendorOverridesQuery
>
export type ChargeCodeAndVendorOverridesLazyQueryHookResult = ReturnType<
  typeof useChargeCodeAndVendorOverridesLazyQuery
>
export type ChargeCodeAndVendorOverridesQueryResult = Apollo.QueryResult<
  ChargeCodeAndVendorOverridesQuery,
  ChargeCodeAndVendorOverridesQueryVariables
>
export const ChargeCodesWithSameCodeDocument = gql`
  query chargeCodesWithSameCode($chargeCodeId: UUID!) {
    chargeCodes(chargeCodeId: $chargeCodeId) {
      id
      code
      description
      chargeVendor {
        id
        dateDeleted
        name
        code
        type
      }
    }
  }
`

/**
 * __useChargeCodesWithSameCodeQuery__
 *
 * To run a query within a React component, call `useChargeCodesWithSameCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeCodesWithSameCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeCodesWithSameCodeQuery({
 *   variables: {
 *      chargeCodeId: // value for 'chargeCodeId'
 *   },
 * });
 */
export function useChargeCodesWithSameCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChargeCodesWithSameCodeQuery,
    ChargeCodesWithSameCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChargeCodesWithSameCodeQuery, ChargeCodesWithSameCodeQueryVariables>(
    ChargeCodesWithSameCodeDocument,
    options,
  )
}
export function useChargeCodesWithSameCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargeCodesWithSameCodeQuery,
    ChargeCodesWithSameCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ChargeCodesWithSameCodeQuery, ChargeCodesWithSameCodeQueryVariables>(
    ChargeCodesWithSameCodeDocument,
    options,
  )
}
export type ChargeCodesWithSameCodeQueryHookResult = ReturnType<
  typeof useChargeCodesWithSameCodeQuery
>
export type ChargeCodesWithSameCodeLazyQueryHookResult = ReturnType<
  typeof useChargeCodesWithSameCodeLazyQuery
>
export type ChargeCodesWithSameCodeQueryResult = Apollo.QueryResult<
  ChargeCodesWithSameCodeQuery,
  ChargeCodesWithSameCodeQueryVariables
>
export const SearchTaxesDocument = gql`
  query searchTaxes($apiPartnerId: UUID, $query: String!) {
    searchTaxes(apiPartnerId: $apiPartnerId, query: $query) {
      id
      taxCode
      taxRate
      taxType
      taxSystem
      apiPartnerId
      dateDeleted
    }
  }
`

/**
 * __useSearchTaxesQuery__
 *
 * To run a query within a React component, call `useSearchTaxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTaxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTaxesQuery({
 *   variables: {
 *      apiPartnerId: // value for 'apiPartnerId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchTaxesQuery(
  baseOptions: Apollo.QueryHookOptions<SearchTaxesQuery, SearchTaxesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchTaxesQuery, SearchTaxesQueryVariables>(SearchTaxesDocument, options)
}
export function useSearchTaxesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchTaxesQuery, SearchTaxesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchTaxesQuery, SearchTaxesQueryVariables>(
    SearchTaxesDocument,
    options,
  )
}
export type SearchTaxesQueryHookResult = ReturnType<typeof useSearchTaxesQuery>
export type SearchTaxesLazyQueryHookResult = ReturnType<typeof useSearchTaxesLazyQuery>
export type SearchTaxesQueryResult = Apollo.QueryResult<SearchTaxesQuery, SearchTaxesQueryVariables>
export const ReconAsyncBatchDocument = gql`
  query reconAsyncBatch($reconAsyncBatchId: String!) {
    reconAsyncBatch(reconAsyncBatchId: $reconAsyncBatchId) {
      id
      status {
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
        ... on ReconAsyncStatusEnum {
          value
          isFallback
        }
      }
      numTasks
      dateCreated
      errorMessage
    }
  }
`

/**
 * __useReconAsyncBatchQuery__
 *
 * To run a query within a React component, call `useReconAsyncBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useReconAsyncBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReconAsyncBatchQuery({
 *   variables: {
 *      reconAsyncBatchId: // value for 'reconAsyncBatchId'
 *   },
 * });
 */
export function useReconAsyncBatchQuery(
  baseOptions: Apollo.QueryHookOptions<ReconAsyncBatchQuery, ReconAsyncBatchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReconAsyncBatchQuery, ReconAsyncBatchQueryVariables>(
    ReconAsyncBatchDocument,
    options,
  )
}
export function useReconAsyncBatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReconAsyncBatchQuery, ReconAsyncBatchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReconAsyncBatchQuery, ReconAsyncBatchQueryVariables>(
    ReconAsyncBatchDocument,
    options,
  )
}
export type ReconAsyncBatchQueryHookResult = ReturnType<typeof useReconAsyncBatchQuery>
export type ReconAsyncBatchLazyQueryHookResult = ReturnType<typeof useReconAsyncBatchLazyQuery>
export type ReconAsyncBatchQueryResult = Apollo.QueryResult<
  ReconAsyncBatchQuery,
  ReconAsyncBatchQueryVariables
>
export const ChargeCodeUploadAsyncTaskDocument = gql`
  query chargeCodeUploadAsyncTask($chargeCodeAsyncTaskId: String!) {
    chargeCodeUploadAsyncTask(chargeCodeAsyncTaskId: $chargeCodeAsyncTaskId) {
      id
      status
      percentDone
      errorMessage
      dateCreated
    }
  }
`

/**
 * __useChargeCodeUploadAsyncTaskQuery__
 *
 * To run a query within a React component, call `useChargeCodeUploadAsyncTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeCodeUploadAsyncTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeCodeUploadAsyncTaskQuery({
 *   variables: {
 *      chargeCodeAsyncTaskId: // value for 'chargeCodeAsyncTaskId'
 *   },
 * });
 */
export function useChargeCodeUploadAsyncTaskQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChargeCodeUploadAsyncTaskQuery,
    ChargeCodeUploadAsyncTaskQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChargeCodeUploadAsyncTaskQuery, ChargeCodeUploadAsyncTaskQueryVariables>(
    ChargeCodeUploadAsyncTaskDocument,
    options,
  )
}
export function useChargeCodeUploadAsyncTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargeCodeUploadAsyncTaskQuery,
    ChargeCodeUploadAsyncTaskQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ChargeCodeUploadAsyncTaskQuery,
    ChargeCodeUploadAsyncTaskQueryVariables
  >(ChargeCodeUploadAsyncTaskDocument, options)
}
export type ChargeCodeUploadAsyncTaskQueryHookResult = ReturnType<
  typeof useChargeCodeUploadAsyncTaskQuery
>
export type ChargeCodeUploadAsyncTaskLazyQueryHookResult = ReturnType<
  typeof useChargeCodeUploadAsyncTaskLazyQuery
>
export type ChargeCodeUploadAsyncTaskQueryResult = Apollo.QueryResult<
  ChargeCodeUploadAsyncTaskQuery,
  ChargeCodeUploadAsyncTaskQueryVariables
>
export const ReconAttemptsDocument = gql`
  query reconAttempts($reconAsyncBatchId: String!) {
    reconAttempts(reconAsyncBatchId: $reconAsyncBatchId) {
      ...ReconAttemptWithResultsAndMetadata
      job {
        id
        jobTemplate {
          id
          apiPartnerId
          companyId
          requireExternalAssignee
          defaultExternalAssignee {
            id
            email
          }
        }
      }
    }
  }
  ${ReconAttemptWithResultsAndMetadataFragmentDoc}
`

/**
 * __useReconAttemptsQuery__
 *
 * To run a query within a React component, call `useReconAttemptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReconAttemptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReconAttemptsQuery({
 *   variables: {
 *      reconAsyncBatchId: // value for 'reconAsyncBatchId'
 *   },
 * });
 */
export function useReconAttemptsQuery(
  baseOptions: Apollo.QueryHookOptions<ReconAttemptsQuery, ReconAttemptsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReconAttemptsQuery, ReconAttemptsQueryVariables>(
    ReconAttemptsDocument,
    options,
  )
}
export function useReconAttemptsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReconAttemptsQuery, ReconAttemptsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReconAttemptsQuery, ReconAttemptsQueryVariables>(
    ReconAttemptsDocument,
    options,
  )
}
export type ReconAttemptsQueryHookResult = ReturnType<typeof useReconAttemptsQuery>
export type ReconAttemptsLazyQueryHookResult = ReturnType<typeof useReconAttemptsLazyQuery>
export type ReconAttemptsQueryResult = Apollo.QueryResult<
  ReconAttemptsQuery,
  ReconAttemptsQueryVariables
>
export const FindInvoiceReconResultFromJobDocument = gql`
  query findInvoiceReconResultFromJob(
    $jobId: UUID!
    $lumpsum: InputDocumentTable
    $overrideChargeDescription: Boolean!
    $disableSendDueDate: Boolean!
    $matchingCriteria: MatchingCriteriaType!
  ) {
    findInvoiceReconResultFromJob(
      jobId: $jobId
      lumpsum: $lumpsum
      overrideChargeDescription: $overrideChargeDescription
      disableSendDueDate: $disableSendDueDate
      matchingCriteria: $matchingCriteria
    ) {
      docCharges {
        id
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
      }
      expectedCharges {
        id
        chainIoSiLineId
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
        isPosted
        overseasSellAmount
        sellCurrency
      }
      reconAttempt {
        id
        job {
          id
          jobTemplate {
            id
            apiPartnerId
            companyId
          }
        }
        reconDetail {
          vendor
          tmsId
          shipmentNo
          invoiceNo
          hblNo
          mblNo
          referenceNo
          containerNo
          carrierBookingNo
          consolNo
          orderNo
          dueDate
        }
        cargowiseModule {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on CwTargetModuleEnum {
            value
            isFallback
          }
        }
        matchingCriteria {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on MatchingCriteriaTypeEnum {
            value
            isFallback
          }
        }
      }
      reconResults {
        id
        success
        chainIoShipment {
          id
          dateModified
        }
        ... on FindShipmentReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          referenceNo
          hblNo
          mblNo
          carrierBookingNo
          containerNo
          consolNo
          orderNo
          isPrimary
          isMaximalAmongDuplicates
          chainIoShipment {
            id
            forwarderReference
            chainIoConsolidations {
              edges {
                node {
                  id
                  forwarderReference
                }
              }
            }
          }
          chainIoConsolidation {
            id
            forwarderReference
          }
          chainIoCustomsDeclaration {
            id
            forwarderReference
            chainIoShipment {
              id
            }
          }
          findShipmentDuplicateReconResults {
            edges {
              node {
                id
                chainIoShipment {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                chainIoConsolidation {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                chainIoCustomsDeclaration {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
              }
            }
          }
        }
        ... on FindInvoiceReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          invoiceReconMatches {
            edges {
              node {
                id
                chainIoSi {
                  id
                  invoiceNumber
                }
              }
            }
          }
        }
        ... on InvoiceLineItemReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          invoiceAmount
          invoiceTaxAmount
          expectedAmount
          expectedTaxAmount
          invoiceChargeCode {
            id
            code
            chargeVendor {
              id
              name
            }
          }
          invoiceChargeCodeCode
          expectedChargeCode {
            id
            code
            chargeVendor {
              id
              name
            }
          }
          invoiceNumber
          invoiceTaxId
          invoiceDate
          dueDate
          expectedTaxId
          invoiceChargeVendor {
            id
            code
          }
          invoiceCurrency
          invoiceUnitPrice
          invoiceQuantity
          chainIoSiLine {
            id
          }
          duplicateChainIoSiLines {
            edges {
              node {
                id
                chainIoSiLine {
                  id
                }
              }
            }
          }
          isChargeCodeSame
          isTotalCostEqual
          isCurrencySameOrEmpty
          isChargeVendorSame
          isInvoiceNumberCorrect
        }
        ... on InvoiceTotalReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          invoiceTotalAmount: invoiceAmount
          invoiceTaxedTotalAmount: invoiceTaxedAmount
          expectedTotalAmount: expectedAmount
          expectedTaxedTotalAmount: expectedTaxedAmount
        }
        ... on ReconResultFallbackNode {
          id
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
        }
      }
    }
  }
`

/**
 * __useFindInvoiceReconResultFromJobQuery__
 *
 * To run a query within a React component, call `useFindInvoiceReconResultFromJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindInvoiceReconResultFromJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindInvoiceReconResultFromJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      lumpsum: // value for 'lumpsum'
 *      overrideChargeDescription: // value for 'overrideChargeDescription'
 *      disableSendDueDate: // value for 'disableSendDueDate'
 *      matchingCriteria: // value for 'matchingCriteria'
 *   },
 * });
 */
export function useFindInvoiceReconResultFromJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindInvoiceReconResultFromJobQuery,
    FindInvoiceReconResultFromJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindInvoiceReconResultFromJobQuery,
    FindInvoiceReconResultFromJobQueryVariables
  >(FindInvoiceReconResultFromJobDocument, options)
}
export function useFindInvoiceReconResultFromJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindInvoiceReconResultFromJobQuery,
    FindInvoiceReconResultFromJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindInvoiceReconResultFromJobQuery,
    FindInvoiceReconResultFromJobQueryVariables
  >(FindInvoiceReconResultFromJobDocument, options)
}
export type FindInvoiceReconResultFromJobQueryHookResult = ReturnType<
  typeof useFindInvoiceReconResultFromJobQuery
>
export type FindInvoiceReconResultFromJobLazyQueryHookResult = ReturnType<
  typeof useFindInvoiceReconResultFromJobLazyQuery
>
export type FindInvoiceReconResultFromJobQueryResult = Apollo.QueryResult<
  FindInvoiceReconResultFromJobQuery,
  FindInvoiceReconResultFromJobQueryVariables
>
export const FindShipmentReconResultsDocument = gql`
  query findShipmentReconResults($jobId: UUID!) {
    findShipmentReconResults(jobId: $jobId) {
      id
      success
      type {
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
        ... on ReconResultTypeEnum {
          value
          isFallback
        }
      }
      referenceNo
      hblNo
      mblNo
      consolNo
      orderNo
      carrierBookingNo
      containerNo
      findShipmentDuplicateReconResults {
        edges {
          node {
            id
            chainIoShipment {
              id
              forwarderReference
              isCancelled
              tmsId
            }
            chainIoConsolidation {
              id
              forwarderReference
              isCancelled
              tmsId
            }
            chainIoCustomsDeclaration {
              id
              forwarderReference
              isCancelled
              tmsId
              chainIoShipment {
                id
              }
            }
          }
        }
      }
      chainIoShipment {
        id
        forwarderReference
        chainIoConsolidations {
          edges {
            node {
              id
              forwarderReference
            }
          }
        }
      }
      chainIoConsolidation {
        id
        forwarderReference
      }
      chainIoCustomsDeclaration {
        id
        forwarderReference
        chainIoShipment {
          id
        }
      }
    }
  }
`

/**
 * __useFindShipmentReconResultsQuery__
 *
 * To run a query within a React component, call `useFindShipmentReconResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShipmentReconResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShipmentReconResultsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useFindShipmentReconResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindShipmentReconResultsQuery,
    FindShipmentReconResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindShipmentReconResultsQuery, FindShipmentReconResultsQueryVariables>(
    FindShipmentReconResultsDocument,
    options,
  )
}
export function useFindShipmentReconResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindShipmentReconResultsQuery,
    FindShipmentReconResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindShipmentReconResultsQuery, FindShipmentReconResultsQueryVariables>(
    FindShipmentReconResultsDocument,
    options,
  )
}
export type FindShipmentReconResultsQueryHookResult = ReturnType<
  typeof useFindShipmentReconResultsQuery
>
export type FindShipmentReconResultsLazyQueryHookResult = ReturnType<
  typeof useFindShipmentReconResultsLazyQuery
>
export type FindShipmentReconResultsQueryResult = Apollo.QueryResult<
  FindShipmentReconResultsQuery,
  FindShipmentReconResultsQueryVariables
>
export const FindSoaShipmentsReconResultsDocument = gql`
  query findSoaShipmentsReconResults($jobId: UUID!, $inputSoaLineItems: [InputSOALineItem!]!) {
    findSoaShipmentsReconResults(jobId: $jobId, inputSoaLineItems: $inputSoaLineItems) {
      id
      success
      type {
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
        ... on ReconResultTypeEnum {
          value
          isFallback
        }
      }
      referenceNo
      hblNo
      mblNo
      consolNo
      orderNo
      carrierBookingNo
      containerNo
      findShipmentDuplicateReconResults {
        edges {
          node {
            id
            chainIoShipment {
              id
              forwarderReference
              isCancelled
              tmsId
            }
            chainIoConsolidation {
              id
              forwarderReference
              isCancelled
              tmsId
            }
            chainIoCustomsDeclaration {
              id
              forwarderReference
              isCancelled
              tmsId
            }
          }
        }
      }
      chainIoShipment {
        id
        forwarderReference
        chainIoConsolidations {
          edges {
            node {
              id
              forwarderReference
            }
          }
        }
      }
      chainIoConsolidation {
        id
        forwarderReference
      }
      chainIoCustomsDeclaration {
        id
        forwarderReference
      }
    }
  }
`

/**
 * __useFindSoaShipmentsReconResultsQuery__
 *
 * To run a query within a React component, call `useFindSoaShipmentsReconResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSoaShipmentsReconResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSoaShipmentsReconResultsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      inputSoaLineItems: // value for 'inputSoaLineItems'
 *   },
 * });
 */
export function useFindSoaShipmentsReconResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindSoaShipmentsReconResultsQuery,
    FindSoaShipmentsReconResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindSoaShipmentsReconResultsQuery,
    FindSoaShipmentsReconResultsQueryVariables
  >(FindSoaShipmentsReconResultsDocument, options)
}
export function useFindSoaShipmentsReconResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSoaShipmentsReconResultsQuery,
    FindSoaShipmentsReconResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindSoaShipmentsReconResultsQuery,
    FindSoaShipmentsReconResultsQueryVariables
  >(FindSoaShipmentsReconResultsDocument, options)
}
export type FindSoaShipmentsReconResultsQueryHookResult = ReturnType<
  typeof useFindSoaShipmentsReconResultsQuery
>
export type FindSoaShipmentsReconResultsLazyQueryHookResult = ReturnType<
  typeof useFindSoaShipmentsReconResultsLazyQuery
>
export type FindSoaShipmentsReconResultsQueryResult = Apollo.QueryResult<
  FindSoaShipmentsReconResultsQuery,
  FindSoaShipmentsReconResultsQueryVariables
>
export const UniqueChargeVendorsByCompanyDocument = gql`
  query uniqueChargeVendorsByCompany($companyId: UUID!, $codes: [String!]) @api(name: cauldron) {
    uniqueChargeVendorsByCompany(companyId: $companyId, codes: $codes) {
      id
      name
      code
    }
  }
`

/**
 * __useUniqueChargeVendorsByCompanyQuery__
 *
 * To run a query within a React component, call `useUniqueChargeVendorsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniqueChargeVendorsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniqueChargeVendorsByCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      codes: // value for 'codes'
 *   },
 * });
 */
export function useUniqueChargeVendorsByCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    UniqueChargeVendorsByCompanyQuery,
    UniqueChargeVendorsByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UniqueChargeVendorsByCompanyQuery,
    UniqueChargeVendorsByCompanyQueryVariables
  >(UniqueChargeVendorsByCompanyDocument, options)
}
export function useUniqueChargeVendorsByCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UniqueChargeVendorsByCompanyQuery,
    UniqueChargeVendorsByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UniqueChargeVendorsByCompanyQuery,
    UniqueChargeVendorsByCompanyQueryVariables
  >(UniqueChargeVendorsByCompanyDocument, options)
}
export type UniqueChargeVendorsByCompanyQueryHookResult = ReturnType<
  typeof useUniqueChargeVendorsByCompanyQuery
>
export type UniqueChargeVendorsByCompanyLazyQueryHookResult = ReturnType<
  typeof useUniqueChargeVendorsByCompanyLazyQuery
>
export type UniqueChargeVendorsByCompanyQueryResult = Apollo.QueryResult<
  UniqueChargeVendorsByCompanyQuery,
  UniqueChargeVendorsByCompanyQueryVariables
>
export const UniqueChargeCodesByCompanyPartnerDocument = gql`
  query uniqueChargeCodesByCompanyPartner(
    $companyId: UUID!
    $apiPartnerId: UUID
    $codes: [String!]
    $vendorCodes: [String!]
  ) @api(name: cauldron) {
    uniqueChargeCodesByCompanyPartner(
      companyId: $companyId
      apiPartnerId: $apiPartnerId
      codes: $codes
      vendorCodes: $vendorCodes
    ) {
      id
      code
      description
    }
  }
`

/**
 * __useUniqueChargeCodesByCompanyPartnerQuery__
 *
 * To run a query within a React component, call `useUniqueChargeCodesByCompanyPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniqueChargeCodesByCompanyPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniqueChargeCodesByCompanyPartnerQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      apiPartnerId: // value for 'apiPartnerId'
 *      codes: // value for 'codes'
 *      vendorCodes: // value for 'vendorCodes'
 *   },
 * });
 */
export function useUniqueChargeCodesByCompanyPartnerQuery(
  baseOptions: Apollo.QueryHookOptions<
    UniqueChargeCodesByCompanyPartnerQuery,
    UniqueChargeCodesByCompanyPartnerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UniqueChargeCodesByCompanyPartnerQuery,
    UniqueChargeCodesByCompanyPartnerQueryVariables
  >(UniqueChargeCodesByCompanyPartnerDocument, options)
}
export function useUniqueChargeCodesByCompanyPartnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UniqueChargeCodesByCompanyPartnerQuery,
    UniqueChargeCodesByCompanyPartnerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UniqueChargeCodesByCompanyPartnerQuery,
    UniqueChargeCodesByCompanyPartnerQueryVariables
  >(UniqueChargeCodesByCompanyPartnerDocument, options)
}
export type UniqueChargeCodesByCompanyPartnerQueryHookResult = ReturnType<
  typeof useUniqueChargeCodesByCompanyPartnerQuery
>
export type UniqueChargeCodesByCompanyPartnerLazyQueryHookResult = ReturnType<
  typeof useUniqueChargeCodesByCompanyPartnerLazyQuery
>
export type UniqueChargeCodesByCompanyPartnerQueryResult = Apollo.QueryResult<
  UniqueChargeCodesByCompanyPartnerQuery,
  UniqueChargeCodesByCompanyPartnerQueryVariables
>
export const ViewOperatorCompaniesDocument = gql`
  query viewOperatorCompanies @api(name: cauldron) {
    viewOperatorCompanies {
      onlyViewDashboardCompany
      operatorCompanies {
        id
        name
        cargowiseConfigs {
          edges {
            node {
              id
            }
          }
        }
        apiPartners {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`

/**
 * __useViewOperatorCompaniesQuery__
 *
 * To run a query within a React component, call `useViewOperatorCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewOperatorCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewOperatorCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewOperatorCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ViewOperatorCompaniesQuery,
    ViewOperatorCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ViewOperatorCompaniesQuery, ViewOperatorCompaniesQueryVariables>(
    ViewOperatorCompaniesDocument,
    options,
  )
}
export function useViewOperatorCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewOperatorCompaniesQuery,
    ViewOperatorCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ViewOperatorCompaniesQuery, ViewOperatorCompaniesQueryVariables>(
    ViewOperatorCompaniesDocument,
    options,
  )
}
export type ViewOperatorCompaniesQueryHookResult = ReturnType<typeof useViewOperatorCompaniesQuery>
export type ViewOperatorCompaniesLazyQueryHookResult = ReturnType<
  typeof useViewOperatorCompaniesLazyQuery
>
export type ViewOperatorCompaniesQueryResult = Apollo.QueryResult<
  ViewOperatorCompaniesQuery,
  ViewOperatorCompaniesQueryVariables
>
export const SoaReconAttemptsHistoryDocument = gql`
  query soaReconAttemptsHistory(
    $jobId: UUID!
    $getSavedOnly: Boolean
    $marker: String
    $pageSize: Int
  ) {
    soaReconAttemptsHistory(
      jobId: $jobId
      getSavedOnly: $getSavedOnly
      marker: $marker
      pageSize: $pageSize
    ) {
      results {
        id
        dateCreated
        userCreated {
          id
          email
        }
      }
      nextMarker
      previousMarker
      hasNext
      hasPrevious
    }
  }
`

/**
 * __useSoaReconAttemptsHistoryQuery__
 *
 * To run a query within a React component, call `useSoaReconAttemptsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoaReconAttemptsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoaReconAttemptsHistoryQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      getSavedOnly: // value for 'getSavedOnly'
 *      marker: // value for 'marker'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useSoaReconAttemptsHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SoaReconAttemptsHistoryQuery,
    SoaReconAttemptsHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SoaReconAttemptsHistoryQuery, SoaReconAttemptsHistoryQueryVariables>(
    SoaReconAttemptsHistoryDocument,
    options,
  )
}
export function useSoaReconAttemptsHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SoaReconAttemptsHistoryQuery,
    SoaReconAttemptsHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SoaReconAttemptsHistoryQuery, SoaReconAttemptsHistoryQueryVariables>(
    SoaReconAttemptsHistoryDocument,
    options,
  )
}
export type SoaReconAttemptsHistoryQueryHookResult = ReturnType<
  typeof useSoaReconAttemptsHistoryQuery
>
export type SoaReconAttemptsHistoryLazyQueryHookResult = ReturnType<
  typeof useSoaReconAttemptsHistoryLazyQuery
>
export type SoaReconAttemptsHistoryQueryResult = Apollo.QueryResult<
  SoaReconAttemptsHistoryQuery,
  SoaReconAttemptsHistoryQueryVariables
>
export const ApReconAttemptsHistoryDocument = gql`
  query apReconAttemptsHistory(
    $jobId: UUID!
    $getSavedOnly: Boolean
    $marker: String
    $pageSize: Int
  ) {
    apReconAttemptsHistory(
      jobId: $jobId
      getSavedOnly: $getSavedOnly
      marker: $marker
      pageSize: $pageSize
    ) {
      results {
        ...ReconAttemptWithResultsAndMetadata
        userCreated {
          id
          email
        }
      }
      nextMarker
      previousMarker
      hasNext
      hasPrevious
    }
  }
  ${ReconAttemptWithResultsAndMetadataFragmentDoc}
`

/**
 * __useApReconAttemptsHistoryQuery__
 *
 * To run a query within a React component, call `useApReconAttemptsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useApReconAttemptsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApReconAttemptsHistoryQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      getSavedOnly: // value for 'getSavedOnly'
 *      marker: // value for 'marker'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useApReconAttemptsHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApReconAttemptsHistoryQuery,
    ApReconAttemptsHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ApReconAttemptsHistoryQuery, ApReconAttemptsHistoryQueryVariables>(
    ApReconAttemptsHistoryDocument,
    options,
  )
}
export function useApReconAttemptsHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApReconAttemptsHistoryQuery,
    ApReconAttemptsHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ApReconAttemptsHistoryQuery, ApReconAttemptsHistoryQueryVariables>(
    ApReconAttemptsHistoryDocument,
    options,
  )
}
export type ApReconAttemptsHistoryQueryHookResult = ReturnType<
  typeof useApReconAttemptsHistoryQuery
>
export type ApReconAttemptsHistoryLazyQueryHookResult = ReturnType<
  typeof useApReconAttemptsHistoryLazyQuery
>
export type ApReconAttemptsHistoryQueryResult = Apollo.QueryResult<
  ApReconAttemptsHistoryQuery,
  ApReconAttemptsHistoryQueryVariables
>
export const BatchSearchableRecordResultsDocument = gql`
  query batchSearchableRecordResults(
    $inputSearchableRecordColumns: [InputSearchableRecordColumn!]!
  ) {
    batchSearchableRecordResults(inputSearchableRecordColumns: $inputSearchableRecordColumns) {
      searchableRecordId
      values
    }
  }
`

/**
 * __useBatchSearchableRecordResultsQuery__
 *
 * To run a query within a React component, call `useBatchSearchableRecordResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatchSearchableRecordResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatchSearchableRecordResultsQuery({
 *   variables: {
 *      inputSearchableRecordColumns: // value for 'inputSearchableRecordColumns'
 *   },
 * });
 */
export function useBatchSearchableRecordResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BatchSearchableRecordResultsQuery,
    BatchSearchableRecordResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BatchSearchableRecordResultsQuery,
    BatchSearchableRecordResultsQueryVariables
  >(BatchSearchableRecordResultsDocument, options)
}
export function useBatchSearchableRecordResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatchSearchableRecordResultsQuery,
    BatchSearchableRecordResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BatchSearchableRecordResultsQuery,
    BatchSearchableRecordResultsQueryVariables
  >(BatchSearchableRecordResultsDocument, options)
}
export type BatchSearchableRecordResultsQueryHookResult = ReturnType<
  typeof useBatchSearchableRecordResultsQuery
>
export type BatchSearchableRecordResultsLazyQueryHookResult = ReturnType<
  typeof useBatchSearchableRecordResultsLazyQuery
>
export type BatchSearchableRecordResultsQueryResult = Apollo.QueryResult<
  BatchSearchableRecordResultsQuery,
  BatchSearchableRecordResultsQueryVariables
>
export const SearchableRecordResultsDocument = gql`
  query searchableRecordResults(
    $searchableRecordId: String!
    $queryString: String!
    $filters: InputSearchableRecordFilters
  ) {
    searchableRecordResults(
      searchableRecordId: $searchableRecordId
      queryString: $queryString
      filters: $filters
    ) {
      id
      ... on ChargeVendorNode {
        code
        name
      }
      ... on ChargeCodeNode {
        code
        description
      }
      ... on ChargeRateNode {
        shipmentRoute
        namedAccount
        commodity
        containerSize
        containerType
      }
      ... on GeoLocationNode {
        label
      }
      ... on USCarrierNode {
        label
      }
      ... on ScheduleKNode {
        label
      }
      ... on ScheduleDNode {
        label
      }
      ... on SearchableRecordURLResult {
        urlResultRecord
        urlResultSearch
      }
      ... on ChargeCodeSearchResult {
        code
        description
      }
    }
  }
`

/**
 * __useSearchableRecordResultsQuery__
 *
 * To run a query within a React component, call `useSearchableRecordResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchableRecordResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchableRecordResultsQuery({
 *   variables: {
 *      searchableRecordId: // value for 'searchableRecordId'
 *      queryString: // value for 'queryString'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSearchableRecordResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchableRecordResultsQuery,
    SearchableRecordResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchableRecordResultsQuery, SearchableRecordResultsQueryVariables>(
    SearchableRecordResultsDocument,
    options,
  )
}
export function useSearchableRecordResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchableRecordResultsQuery,
    SearchableRecordResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchableRecordResultsQuery, SearchableRecordResultsQueryVariables>(
    SearchableRecordResultsDocument,
    options,
  )
}
export type SearchableRecordResultsQueryHookResult = ReturnType<
  typeof useSearchableRecordResultsQuery
>
export type SearchableRecordResultsLazyQueryHookResult = ReturnType<
  typeof useSearchableRecordResultsLazyQuery
>
export type SearchableRecordResultsQueryResult = Apollo.QueryResult<
  SearchableRecordResultsQuery,
  SearchableRecordResultsQueryVariables
>
export const SearchableRecordsValidationDocument = gql`
  query searchableRecordsValidation($searchableRecordInputs: [InputSearchableRecordColumn!]!) {
    validSearchableRecordResults(searchableRecordInputs: $searchableRecordInputs) {
      searchableRecordId
      values
    }
  }
`

/**
 * __useSearchableRecordsValidationQuery__
 *
 * To run a query within a React component, call `useSearchableRecordsValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchableRecordsValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchableRecordsValidationQuery({
 *   variables: {
 *      searchableRecordInputs: // value for 'searchableRecordInputs'
 *   },
 * });
 */
export function useSearchableRecordsValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchableRecordsValidationQuery,
    SearchableRecordsValidationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchableRecordsValidationQuery,
    SearchableRecordsValidationQueryVariables
  >(SearchableRecordsValidationDocument, options)
}
export function useSearchableRecordsValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchableRecordsValidationQuery,
    SearchableRecordsValidationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchableRecordsValidationQuery,
    SearchableRecordsValidationQueryVariables
  >(SearchableRecordsValidationDocument, options)
}
export type SearchableRecordsValidationQueryHookResult = ReturnType<
  typeof useSearchableRecordsValidationQuery
>
export type SearchableRecordsValidationLazyQueryHookResult = ReturnType<
  typeof useSearchableRecordsValidationLazyQuery
>
export type SearchableRecordsValidationQueryResult = Apollo.QueryResult<
  SearchableRecordsValidationQuery,
  SearchableRecordsValidationQueryVariables
>
export const SalesRepsDocument = gql`
  query salesReps($companyId: String!) {
    salesReps(companyId: $companyId)
  }
`

/**
 * __useSalesRepsQuery__
 *
 * To run a query within a React component, call `useSalesRepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesRepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesRepsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSalesRepsQuery(
  baseOptions: Apollo.QueryHookOptions<SalesRepsQuery, SalesRepsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SalesRepsQuery, SalesRepsQueryVariables>(SalesRepsDocument, options)
}
export function useSalesRepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SalesRepsQuery, SalesRepsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SalesRepsQuery, SalesRepsQueryVariables>(SalesRepsDocument, options)
}
export type SalesRepsQueryHookResult = ReturnType<typeof useSalesRepsQuery>
export type SalesRepsLazyQueryHookResult = ReturnType<typeof useSalesRepsLazyQuery>
export type SalesRepsQueryResult = Apollo.QueryResult<SalesRepsQuery, SalesRepsQueryVariables>
export const OperatorsDocument = gql`
  query operators($companyId: String!) {
    operators(companyId: $companyId)
  }
`

/**
 * __useOperatorsQuery__
 *
 * To run a query within a React component, call `useOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOperatorsQuery(
  baseOptions: Apollo.QueryHookOptions<OperatorsQuery, OperatorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OperatorsQuery, OperatorsQueryVariables>(OperatorsDocument, options)
}
export function useOperatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OperatorsQuery, OperatorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OperatorsQuery, OperatorsQueryVariables>(OperatorsDocument, options)
}
export type OperatorsQueryHookResult = ReturnType<typeof useOperatorsQuery>
export type OperatorsLazyQueryHookResult = ReturnType<typeof useOperatorsLazyQuery>
export type OperatorsQueryResult = Apollo.QueryResult<OperatorsQuery, OperatorsQueryVariables>
export const BranchesDocument = gql`
  query branches($companyId: String!) {
    branches(companyId: $companyId)
  }
`

/**
 * __useBranchesQuery__
 *
 * To run a query within a React component, call `useBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useBranchesQuery(
  baseOptions: Apollo.QueryHookOptions<BranchesQuery, BranchesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BranchesQuery, BranchesQueryVariables>(BranchesDocument, options)
}
export function useBranchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BranchesQuery, BranchesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BranchesQuery, BranchesQueryVariables>(BranchesDocument, options)
}
export type BranchesQueryHookResult = ReturnType<typeof useBranchesQuery>
export type BranchesLazyQueryHookResult = ReturnType<typeof useBranchesLazyQuery>
export type BranchesQueryResult = Apollo.QueryResult<BranchesQuery, BranchesQueryVariables>
export const DepartmentsDocument = gql`
  query departments($companyId: String!) {
    departments(companyId: $companyId)
  }
`

/**
 * __useDepartmentsQuery__
 *
 * To run a query within a React component, call `useDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDepartmentsQuery(
  baseOptions: Apollo.QueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options)
}
export function useDepartmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DepartmentsQuery, DepartmentsQueryVariables>(
    DepartmentsDocument,
    options,
  )
}
export type DepartmentsQueryHookResult = ReturnType<typeof useDepartmentsQuery>
export type DepartmentsLazyQueryHookResult = ReturnType<typeof useDepartmentsLazyQuery>
export type DepartmentsQueryResult = Apollo.QueryResult<DepartmentsQuery, DepartmentsQueryVariables>
export const MatchSoaLineItemsToChainIoShipmentsDocument = gql`
  query matchSoaLineItemsToChainIoShipments(
    $jobId: String!
    $rowsMatchCriteria: [InputSOARowMatchCriteria!]!
  ) {
    matchSoaLineItemsToChainIoShipments(jobId: $jobId, rowsMatchCriteria: $rowsMatchCriteria) {
      rowIdx
      matchingInvoiceNumberChainIoShipmentId
    }
  }
`

/**
 * __useMatchSoaLineItemsToChainIoShipmentsQuery__
 *
 * To run a query within a React component, call `useMatchSoaLineItemsToChainIoShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchSoaLineItemsToChainIoShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchSoaLineItemsToChainIoShipmentsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      rowsMatchCriteria: // value for 'rowsMatchCriteria'
 *   },
 * });
 */
export function useMatchSoaLineItemsToChainIoShipmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MatchSoaLineItemsToChainIoShipmentsQuery,
    MatchSoaLineItemsToChainIoShipmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    MatchSoaLineItemsToChainIoShipmentsQuery,
    MatchSoaLineItemsToChainIoShipmentsQueryVariables
  >(MatchSoaLineItemsToChainIoShipmentsDocument, options)
}
export function useMatchSoaLineItemsToChainIoShipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MatchSoaLineItemsToChainIoShipmentsQuery,
    MatchSoaLineItemsToChainIoShipmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MatchSoaLineItemsToChainIoShipmentsQuery,
    MatchSoaLineItemsToChainIoShipmentsQueryVariables
  >(MatchSoaLineItemsToChainIoShipmentsDocument, options)
}
export type MatchSoaLineItemsToChainIoShipmentsQueryHookResult = ReturnType<
  typeof useMatchSoaLineItemsToChainIoShipmentsQuery
>
export type MatchSoaLineItemsToChainIoShipmentsLazyQueryHookResult = ReturnType<
  typeof useMatchSoaLineItemsToChainIoShipmentsLazyQuery
>
export type MatchSoaLineItemsToChainIoShipmentsQueryResult = Apollo.QueryResult<
  MatchSoaLineItemsToChainIoShipmentsQuery,
  MatchSoaLineItemsToChainIoShipmentsQueryVariables
>
export const TaskPagesDocument = gql`
  query taskPages($pageUpTo: Int!, $query: String, $filters: [InputTaskFilter!]) {
    taskPages(pageUpTo: $pageUpTo, query: $query, filters: $filters) {
      items {
        id
        title
        starred
        isCriticallyLow
        isStarred
        blocked
        status
        taskType
        dateCreated
        dateReceived
        dateConfirmed
        taskReferenceId
        company {
          id
          name
        }
        taskSlaTimeJobRef {
          id
          job {
            id
            dateCreated
            slaTime
          }
        }
        jobs {
          edges {
            node {
              id
              status
              manualProduct
              name
              jobCode
              dateCompleted
              jobTemplate {
                id
                name
              }
              owner {
                id
                name
                email
              }
              qa {
                id
                name
                email
              }
            }
          }
        }
      }
      counts {
        status
        count
      }
      filteredCounts {
        status
        count
      }
    }
  }
`

/**
 * __useTaskPagesQuery__
 *
 * To run a query within a React component, call `useTaskPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskPagesQuery({
 *   variables: {
 *      pageUpTo: // value for 'pageUpTo'
 *      query: // value for 'query'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTaskPagesQuery(
  baseOptions: Apollo.QueryHookOptions<TaskPagesQuery, TaskPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TaskPagesQuery, TaskPagesQueryVariables>(TaskPagesDocument, options)
}
export function useTaskPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskPagesQuery, TaskPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TaskPagesQuery, TaskPagesQueryVariables>(TaskPagesDocument, options)
}
export type TaskPagesQueryHookResult = ReturnType<typeof useTaskPagesQuery>
export type TaskPagesLazyQueryHookResult = ReturnType<typeof useTaskPagesLazyQuery>
export type TaskPagesQueryResult = Apollo.QueryResult<TaskPagesQuery, TaskPagesQueryVariables>
export const TaskPagesIngestDocument = gql`
  query taskPagesIngest($pageUpTo: Int!, $query: String, $filters: [InputTaskFilter!]) {
    taskPagesIngest(pageUpTo: $pageUpTo, query: $query, filters: $filters) {
      items {
        id
        title
        status
        taskType
        dateCreated
        dateReceived
        dateConfirmed
        taskReferenceId
        company {
          id
          name
          jobTemplates {
            edges {
              node {
                id
                name
                documentTypes {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
        taskSlaTimeJobRef {
          id
          job {
            id
            dateCreated
            slaTime
          }
        }
        jobs {
          edges {
            node {
              id
              status
              manualProduct
              name
              jobCode
              clientReferenceNo
              jobTemplate {
                id
                name
                documentTypes {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      counts {
        status
        count
      }
      filteredCounts {
        status
        count
      }
    }
  }
`

/**
 * __useTaskPagesIngestQuery__
 *
 * To run a query within a React component, call `useTaskPagesIngestQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskPagesIngestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskPagesIngestQuery({
 *   variables: {
 *      pageUpTo: // value for 'pageUpTo'
 *      query: // value for 'query'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTaskPagesIngestQuery(
  baseOptions: Apollo.QueryHookOptions<TaskPagesIngestQuery, TaskPagesIngestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TaskPagesIngestQuery, TaskPagesIngestQueryVariables>(
    TaskPagesIngestDocument,
    options,
  )
}
export function useTaskPagesIngestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskPagesIngestQuery, TaskPagesIngestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TaskPagesIngestQuery, TaskPagesIngestQueryVariables>(
    TaskPagesIngestDocument,
    options,
  )
}
export type TaskPagesIngestQueryHookResult = ReturnType<typeof useTaskPagesIngestQuery>
export type TaskPagesIngestLazyQueryHookResult = ReturnType<typeof useTaskPagesIngestLazyQuery>
export type TaskPagesIngestQueryResult = Apollo.QueryResult<
  TaskPagesIngestQuery,
  TaskPagesIngestQueryVariables
>
export const TasksByTitleReferenceIdDocument = gql`
  query tasksByTitleReferenceId($query: String!, $companyId: UUID) {
    tasksByTitleReferenceId(query: $query, companyId: $companyId) {
      id
      title
      taskReferenceId
    }
  }
`

/**
 * __useTasksByTitleReferenceIdQuery__
 *
 * To run a query within a React component, call `useTasksByTitleReferenceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksByTitleReferenceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksByTitleReferenceIdQuery({
 *   variables: {
 *      query: // value for 'query'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTasksByTitleReferenceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    TasksByTitleReferenceIdQuery,
    TasksByTitleReferenceIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TasksByTitleReferenceIdQuery, TasksByTitleReferenceIdQueryVariables>(
    TasksByTitleReferenceIdDocument,
    options,
  )
}
export function useTasksByTitleReferenceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TasksByTitleReferenceIdQuery,
    TasksByTitleReferenceIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TasksByTitleReferenceIdQuery, TasksByTitleReferenceIdQueryVariables>(
    TasksByTitleReferenceIdDocument,
    options,
  )
}
export type TasksByTitleReferenceIdQueryHookResult = ReturnType<
  typeof useTasksByTitleReferenceIdQuery
>
export type TasksByTitleReferenceIdLazyQueryHookResult = ReturnType<
  typeof useTasksByTitleReferenceIdLazyQuery
>
export type TasksByTitleReferenceIdQueryResult = Apollo.QueryResult<
  TasksByTitleReferenceIdQuery,
  TasksByTitleReferenceIdQueryVariables
>
export const TaskForCreateJobDocument = gql`
  query taskForCreateJob($id: String!) {
    task(id: $id) {
      id
      title
      dateReceived
      taskReferenceId
      company {
        id
        name
      }
      message
    }
  }
`

/**
 * __useTaskForCreateJobQuery__
 *
 * To run a query within a React component, call `useTaskForCreateJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskForCreateJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskForCreateJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskForCreateJobQuery(
  baseOptions: Apollo.QueryHookOptions<TaskForCreateJobQuery, TaskForCreateJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TaskForCreateJobQuery, TaskForCreateJobQueryVariables>(
    TaskForCreateJobDocument,
    options,
  )
}
export function useTaskForCreateJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskForCreateJobQuery, TaskForCreateJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TaskForCreateJobQuery, TaskForCreateJobQueryVariables>(
    TaskForCreateJobDocument,
    options,
  )
}
export type TaskForCreateJobQueryHookResult = ReturnType<typeof useTaskForCreateJobQuery>
export type TaskForCreateJobLazyQueryHookResult = ReturnType<typeof useTaskForCreateJobLazyQuery>
export type TaskForCreateJobQueryResult = Apollo.QueryResult<
  TaskForCreateJobQuery,
  TaskForCreateJobQueryVariables
>
export const TaskForIngestDocument = gql`
  query taskForIngest($id: String!) {
    task(id: $id) {
      id
      title
      dateReceived
      message
      taskReferenceId
      company {
        id
        name
        jobTemplates {
          edges {
            node {
              documentTypes {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              id
              name
            }
          }
        }
      }
      jobs {
        edges {
          node {
            id
            name
            jobTemplate {
              id
              name
              documentTypes {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useTaskForIngestQuery__
 *
 * To run a query within a React component, call `useTaskForIngestQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskForIngestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskForIngestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskForIngestQuery(
  baseOptions: Apollo.QueryHookOptions<TaskForIngestQuery, TaskForIngestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TaskForIngestQuery, TaskForIngestQueryVariables>(
    TaskForIngestDocument,
    options,
  )
}
export function useTaskForIngestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskForIngestQuery, TaskForIngestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TaskForIngestQuery, TaskForIngestQueryVariables>(
    TaskForIngestDocument,
    options,
  )
}
export type TaskForIngestQueryHookResult = ReturnType<typeof useTaskForIngestQuery>
export type TaskForIngestLazyQueryHookResult = ReturnType<typeof useTaskForIngestLazyQuery>
export type TaskForIngestQueryResult = Apollo.QueryResult<
  TaskForIngestQuery,
  TaskForIngestQueryVariables
>
export const TaskDetailDocument = gql`
  query taskDetail($id: String!) {
    task(id: $id) {
      id
      title
      taskReferenceId
      message
      starred
      isCriticallyLow
      isStarred
      blocked
      status
      taskType
      dateCreated
      dateReceived
      dateConfirmed
      jobs {
        edges {
          node {
            id
            name
            status
            jobTemplate {
              id
              name
            }
          }
        }
      }
      taskSlaTimeJobRef {
        id
        job {
          id
          dateCreated
          slaTime
        }
      }
      company {
        id
        name
      }
      taskNotes {
        edges {
          node {
            ...taskNote
          }
        }
      }
    }
  }
  ${TaskNoteFragmentDoc}
`

/**
 * __useTaskDetailQuery__
 *
 * To run a query within a React component, call `useTaskDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskDetailQuery(
  baseOptions: Apollo.QueryHookOptions<TaskDetailQuery, TaskDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TaskDetailQuery, TaskDetailQueryVariables>(TaskDetailDocument, options)
}
export function useTaskDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskDetailQuery, TaskDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TaskDetailQuery, TaskDetailQueryVariables>(TaskDetailDocument, options)
}
export type TaskDetailQueryHookResult = ReturnType<typeof useTaskDetailQuery>
export type TaskDetailLazyQueryHookResult = ReturnType<typeof useTaskDetailLazyQuery>
export type TaskDetailQueryResult = Apollo.QueryResult<TaskDetailQuery, TaskDetailQueryVariables>
export const GetTaskWithJobDocument = gql`
  query getTaskWithJob($id: String!) {
    task(id: $id) {
      id
      title
      taskReferenceId
      message
      status
      starred
      blocked
      taskType
      dateReceived
      dateConfirmed
      company {
        id
        name
      }
      taskSlaTimeJobRef {
        id
        job {
          id
          dateCreated
          slaTime
        }
      }
      jobs {
        edges {
          node {
            id
            name
            slaTime
            dateCreated
            jobCode
            status
            clientReferenceNo
            manualProduct
            owner {
              email
              id
            }
            qa {
              email
              id
            }
            jobTemplate {
              id
              name
              companyId
              company {
                id
                name
              }
              jobTemplateExport {
                id
                jobTemplateExportType
              }
            }
            filePages {
              edges {
                node {
                  id
                  status
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetTaskWithJobQuery__
 *
 * To run a query within a React component, call `useGetTaskWithJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskWithJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskWithJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskWithJobQuery(
  baseOptions: Apollo.QueryHookOptions<GetTaskWithJobQuery, GetTaskWithJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTaskWithJobQuery, GetTaskWithJobQueryVariables>(
    GetTaskWithJobDocument,
    options,
  )
}
export function useGetTaskWithJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskWithJobQuery, GetTaskWithJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTaskWithJobQuery, GetTaskWithJobQueryVariables>(
    GetTaskWithJobDocument,
    options,
  )
}
export type GetTaskWithJobQueryHookResult = ReturnType<typeof useGetTaskWithJobQuery>
export type GetTaskWithJobLazyQueryHookResult = ReturnType<typeof useGetTaskWithJobLazyQuery>
export type GetTaskWithJobQueryResult = Apollo.QueryResult<
  GetTaskWithJobQuery,
  GetTaskWithJobQueryVariables
>
export const TaskSearchParametersDocument = gql`
  query taskSearchParameters {
    taskSearchParameters {
      queryString
      filters {
        id
        column {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on TaskFilterColumnEnum {
            value
            isFallback
          }
        }
        operation
        value
      }
    }
  }
`

/**
 * __useTaskSearchParametersQuery__
 *
 * To run a query within a React component, call `useTaskSearchParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskSearchParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskSearchParametersQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskSearchParametersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TaskSearchParametersQuery,
    TaskSearchParametersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TaskSearchParametersQuery, TaskSearchParametersQueryVariables>(
    TaskSearchParametersDocument,
    options,
  )
}
export function useTaskSearchParametersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskSearchParametersQuery,
    TaskSearchParametersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TaskSearchParametersQuery, TaskSearchParametersQueryVariables>(
    TaskSearchParametersDocument,
    options,
  )
}
export type TaskSearchParametersQueryHookResult = ReturnType<typeof useTaskSearchParametersQuery>
export type TaskSearchParametersLazyQueryHookResult = ReturnType<
  typeof useTaskSearchParametersLazyQuery
>
export type TaskSearchParametersQueryResult = Apollo.QueryResult<
  TaskSearchParametersQuery,
  TaskSearchParametersQueryVariables
>
export const TaskNotesDocument = gql`
  query taskNotes($taskId: String!) {
    taskNotes(taskId: $taskId) {
      ...taskNote
    }
  }
  ${TaskNoteFragmentDoc}
`

/**
 * __useTaskNotesQuery__
 *
 * To run a query within a React component, call `useTaskNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskNotesQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskNotesQuery(
  baseOptions: Apollo.QueryHookOptions<TaskNotesQuery, TaskNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TaskNotesQuery, TaskNotesQueryVariables>(TaskNotesDocument, options)
}
export function useTaskNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskNotesQuery, TaskNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TaskNotesQuery, TaskNotesQueryVariables>(TaskNotesDocument, options)
}
export type TaskNotesQueryHookResult = ReturnType<typeof useTaskNotesQuery>
export type TaskNotesLazyQueryHookResult = ReturnType<typeof useTaskNotesLazyQuery>
export type TaskNotesQueryResult = Apollo.QueryResult<TaskNotesQuery, TaskNotesQueryVariables>
export const UsersByRoleDocument = gql`
  query usersByRole($role: UserRole!) {
    usersByRole(role: $role) {
      id
      email
    }
  }
`

/**
 * __useUsersByRoleQuery__
 *
 * To run a query within a React component, call `useUsersByRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByRoleQuery({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUsersByRoleQuery(
  baseOptions: Apollo.QueryHookOptions<UsersByRoleQuery, UsersByRoleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UsersByRoleQuery, UsersByRoleQueryVariables>(UsersByRoleDocument, options)
}
export function useUsersByRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersByRoleQuery, UsersByRoleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UsersByRoleQuery, UsersByRoleQueryVariables>(
    UsersByRoleDocument,
    options,
  )
}
export type UsersByRoleQueryHookResult = ReturnType<typeof useUsersByRoleQuery>
export type UsersByRoleLazyQueryHookResult = ReturnType<typeof useUsersByRoleLazyQuery>
export type UsersByRoleQueryResult = Apollo.QueryResult<UsersByRoleQuery, UsersByRoleQueryVariables>
export const ListUsersDocument = gql`
  query listUsers(
    $companyId: UUID
    $email: String
    $roles: [UserRole!]
    $pageNumber: Int
    $paginate: Boolean
  ) {
    listUsers(
      companyId: $companyId
      email: $email
      roles: $roles
      pageNumber: $pageNumber
      paginate: $paginate
    ) {
      page
      limit
      total
      users {
        uuid
        auth0Id
        name
        email
        roles
        companies {
          id
          name
        }
        company {
          id
          name
          metabaseDashboardId
          hasAccessToInsights
        }
        partnerCompanies {
          id
          name
        }
        metabaseDashboardId
        salesRep
        operationsRep
        branch
        department
        salesReps
        operationsReps
        branches
        departments
        onlyViewAssignedShipments
        onlyViewDashboardCompany
        defaultDashboardsWhitelist
        lastLogin
        dateCreated
        canExportDataAsCsv
      }
    }
  }
`

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      email: // value for 'email'
 *      roles: // value for 'roles'
 *      pageNumber: // value for 'pageNumber'
 *      paginate: // value for 'paginate'
 *   },
 * });
 */
export function useListUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options)
}
export function useListUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options)
}
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>
export const ListDashboardCompanyUsersDocument = gql`
  query listDashboardCompanyUsers($companyId: UUID!) {
    listDashboardCompanyUsers(companyId: $companyId) {
      id
      email
    }
  }
`

/**
 * __useListDashboardCompanyUsersQuery__
 *
 * To run a query within a React component, call `useListDashboardCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDashboardCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDashboardCompanyUsersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useListDashboardCompanyUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListDashboardCompanyUsersQuery,
    ListDashboardCompanyUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListDashboardCompanyUsersQuery, ListDashboardCompanyUsersQueryVariables>(
    ListDashboardCompanyUsersDocument,
    options,
  )
}
export function useListDashboardCompanyUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDashboardCompanyUsersQuery,
    ListDashboardCompanyUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListDashboardCompanyUsersQuery,
    ListDashboardCompanyUsersQueryVariables
  >(ListDashboardCompanyUsersDocument, options)
}
export type ListDashboardCompanyUsersQueryHookResult = ReturnType<
  typeof useListDashboardCompanyUsersQuery
>
export type ListDashboardCompanyUsersLazyQueryHookResult = ReturnType<
  typeof useListDashboardCompanyUsersLazyQuery
>
export type ListDashboardCompanyUsersQueryResult = Apollo.QueryResult<
  ListDashboardCompanyUsersQuery,
  ListDashboardCompanyUsersQueryVariables
>
export const GetUserProfileDocument = gql`
  query getUserProfile {
    userProfile {
      email
      roles
      company {
        id
        name
        metabaseDashboardId
        hasAccessToInsights
      }
    }
  }
`

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options,
  )
}
export function useGetUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options,
  )
}
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>
export type GetUserProfileQueryResult = Apollo.QueryResult<
  GetUserProfileQuery,
  GetUserProfileQueryVariables
>
