import {
  FunctionComponent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { InputField } from '@src/graphql/types'
import { makeStyles } from '@material-ui/styles'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import 'handsontable/dist/handsontable.full.css'
import { HotTable } from '@handsontable/react'
import { useController, useWatch } from 'react-hook-form'
import { parseCsvString } from '@src/utils/csv'
import { useSnackbar } from 'notistack'

type Props = {
  field: InputField
  fieldIndex: number
  fieldGroupIndex: number
  isLineItem: boolean
}

const useStyles = makeStyles({
  row: {
    width: '100%',
    cursor: 'pointer !important',
    '& div': {
      cursor: 'pointer !important',
    },
  },
  dialog: {
    width: '90vw',
    height: '90vh',
  },
})

const ValueExportMappingSelector: FunctionComponent<Props> = ({
  field,
  fieldIndex,
  fieldGroupIndex,
  isLineItem,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const namePrefix = isLineItem ? 'lineItemTypes' : 'metadataFieldGroups'
  const name: string = useWatch({
    name: `${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.name`,
    defaultValue: field.name,
  })
  const values: string = useWatch({
    name: `${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.values`,
    defaultValue: field.values,
  })
  const {
    field: { onChange, value: valueExportMapping },
  } = useController({
    name: `${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.valueExportMapping`,
    defaultValue: field.valueExportMapping,
  })
  const hotTableRef = useRef<HotTable>()
  const parsedValues = useMemo(() => (values ? (JSON.parse(values) as string[]) : null), [values])
  const parsedValueExportMapping = useMemo(
    () => (valueExportMapping ? (JSON.parse(valueExportMapping) as Record<string, string>) : null),
    [valueExportMapping],
  )
  const [tempValueExportMapping, setTempValueExportMapping] = useState(() =>
    parsedValueExportMapping ? Object.entries(parsedValueExportMapping) : null,
  )
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const setRows = useCallback((rows: string[][]) => {
    setTempValueExportMapping(rows as [string, string][])
    hotTableRef.current?.hotInstance.loadData(rows)
  }, [])
  /**
   * reset HoT rows based on valueExportMapping
   */
  const reset = useCallback(() => {
    let initialRows
    if (parsedValueExportMapping) {
      initialRows = Object.entries(parsedValueExportMapping).filter(([option]) =>
        (parsedValues || []).includes(option),
      )
      for (const value of parsedValues || []) {
        if (!parsedValueExportMapping[value]) {
          initialRows.push([value, value])
        }
      }
    } else {
      initialRows = (parsedValues || []).map((val) => [val, val])
    }
    setRows(initialRows)
  }, [parsedValueExportMapping, parsedValues, setRows])
  useEffect(() => {
    reset()
  }, [reset, valueExportMapping])
  // propagate value removal/addition to value export mapping immediately
  useEffect(() => {
    if (parsedValueExportMapping) {
      const newMapping = Object.fromEntries(
        Object.entries(parsedValueExportMapping).filter(([option]) =>
          (parsedValues || []).includes(option),
        ),
      )
      for (const value of parsedValues || []) {
        if (!newMapping[value]) {
          newMapping[value] = value
        }
      }
      onChange(JSON.stringify(newMapping))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const closeDialog = useCallback(() => {
    reset()
    setIsDialogOpen(false)
  }, [reset])
  return (
    <>
      <div
        onClick={() => setIsDialogOpen(true)}
        className={classes.row}
        data-testid='value-export-mapping-row'
      >
        {(values && valueExportMapping && Object.keys(valueExportMapping).length && (
          <span data-testid='value-export-mapping-exists'>
            Click to configure option export-value mapping.
          </span>
        )) ||
          (values && (
            <span data-testid='value-export-mapping-no-exists'>
              No option export-value mapping yet. Click to add option export-value mapping
            </span>
          )) || (
            <span data-testid='value-export-mapping-no-values'>
              Please specify options first before specifying an option export-value mapping.
            </span>
          )}
      </div>
      <Dialog open={isDialogOpen} onClose={closeDialog} className={classes.dialog} fullWidth>
        <DialogTitle>
          Configure <strong>{name}</strong> field options to export values
        </DialogTitle>
        <DialogContent>
          <HotTable
            id='value-export-mapping-hot'
            ref={hotTableRef as RefObject<HotTable>}
            data={tempValueExportMapping as string[][]}
            style={{ width: '100%', overflow: 'hidden', height: '50vh' }}
            colHeaders={['Option', 'Export Value']}
            columns={[{ type: 'text', readOnly: true }, { type: 'text' }]}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' component='label'>
            Upload File
            <input
              type='file'
              accept='.csv,text/csv'
              onChange={async ({ target }) => {
                if (!target.files?.length) return
                const parsedRows = (await parseCsvString(await target.files[0].text())).filter(
                  (row) => row.length,
                )
                if (parsedRows.some((row) => row.length !== 2)) {
                  enqueueSnackbar(
                    'Please upload a CSV with exactly two columns (option and export value)',
                    { variant: 'error' },
                  )
                  return
                }
                setRows(parsedRows.filter(([option]) => (parsedValues || []).includes(option)))
              }}
              hidden
            />
          </Button>
          <Box flex='1' />
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            color='primary'
            onClick={() => {
              onChange(
                JSON.stringify(
                  Object.fromEntries(hotTableRef.current?.hotInstance.getData() as string[][]),
                ),
              )
              setIsDialogOpen(false)
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ValueExportMappingSelector
