import { FunctionComponent, useCallback, useMemo } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/styles'
import { batch, useDispatch, useSelector } from 'react-redux'
import { isPresent } from 'ts-is-present'

import {
  changeFieldBoxColumn,
  setActiveFieldBoxId,
  setNewFieldBoxId,
} from '@src/redux-features/document_editor'
import { lineItemSelectors } from '@src/redux-features/document_editor/line_items_table'
import { jobTableLineItemSelectors } from '@src/redux-features/document_editor/job_table'
import { selectRepeatableFieldsFromJobOrDocTable } from '@src/redux-features/document_editor/field'
import { FieldNode } from '@src/graphql/types'
import { OVERLAY_Z_INDEX, FIELD_BOX_STYLES } from '@src/utils/app_constants'
import { checkFieldDimensionExists, FieldValue } from '@src/utils/line_items'
import { RootState } from '@src/utils/store'

const useStyles = makeStyles({
  columnDropdown: {
    maxHeight: FIELD_BOX_STYLES.LABEL_MAX_HEIGHT,
    zIndex: OVERLAY_Z_INDEX,
  },
})

type Props = {
  columnAnchorEl: HTMLDivElement | null
  setColumnAnchorEl: (el: HTMLDivElement | null) => void
}

const FieldBoxLabelDropdown: FunctionComponent<Props> = ({ columnAnchorEl, setColumnAnchorEl }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const isJobTableActive = useSelector((state: RootState) => state.documentEditor.jobTableActive)

  const activeLineItemIds = useSelector(
    (state: RootState) => state.documentEditor.activeLineItemIds,
  )
  const lineItems = useSelector(
    (state: RootState) => lineItemSelectors.selectAll(state.documentEditor) ?? [],
  )
  const jobTableLineItems = useSelector(
    (state: RootState) => jobTableLineItemSelectors.selectAll(state.documentEditor) ?? [],
  )

  const activeLineItem = useMemo(() => {
    if (activeLineItemIds.length === 1) {
      return isJobTableActive
        ? jobTableLineItems.find((item) => item.id === activeLineItemIds[0])
        : lineItems.find((item) => item.id === activeLineItemIds[0])
    }
    return null
  }, [isJobTableActive, lineItems, jobTableLineItems, activeLineItemIds])
  const activeFieldBoxId = useSelector(
    (state: RootState) => state.documentEditor.boxn.activeFieldBoxId,
  )

  const repeatableFields = useSelector((state: RootState) =>
    selectRepeatableFieldsFromJobOrDocTable(state.documentEditor),
  )
  const fieldBoxKeys = Object.keys(activeLineItem?.fieldMapping ?? {})

  const unassignedColumns = repeatableFields.filter((field) => {
    const isFieldBoxInvalid =
      activeLineItem && !checkFieldDimensionExists(activeLineItem.fieldMapping[field.key])
    return !fieldBoxKeys.includes(field.key) || isFieldBoxInvalid
  })
  const activeColumn = useMemo(() => {
    if (activeFieldBoxId && activeLineItem) {
      let column = null as FieldNode | null
      Object.entries(activeLineItem.fieldMapping).forEach(([key, fieldMapping]) => {
        if (fieldMapping.id === activeFieldBoxId) {
          column = repeatableFields.find((field) => field.key === key) ?? null
        }
      })
      return column
    }
    return null
  }, [activeFieldBoxId, activeLineItem, repeatableFields])

  const selectFieldBoxColumn = useCallback(
    (column: FieldNode) => {
      if (activeLineItem && activeFieldBoxId) {
        setColumnAnchorEl(null)
        batch(() => {
          dispatch(changeFieldBoxColumn(activeLineItem!.id, activeFieldBoxId!, column.key))
          dispatch(setActiveFieldBoxId(null))
          dispatch(setNewFieldBoxId(null))
        })
      }
    },
    [activeFieldBoxId, activeLineItem, dispatch, setColumnAnchorEl],
  )

  const handleClose = (): void => {
    if (activeFieldBoxId && activeLineItem) {
      const rowFieldBoxIds = Object.values(
        (activeLineItem?.fieldMapping ?? {}) as Record<string, FieldValue>,
      )
        .map((fieldMapping) => fieldMapping.id)
        .filter(isPresent)
      setColumnAnchorEl(null)
      batch(() => {
        const isFieldBoxColumnEmpty = !rowFieldBoxIds.includes(activeFieldBoxId!)
        if (isFieldBoxColumnEmpty && unassignedColumns.length > 0) {
          dispatch(
            changeFieldBoxColumn(activeLineItem.id, activeFieldBoxId, unassignedColumns[0].key),
          )
        }
        dispatch(setActiveFieldBoxId(null))
        dispatch(setNewFieldBoxId(null))
      })
    }
  }

  return (
    <Menu
      className={classes.columnDropdown}
      anchorEl={columnAnchorEl}
      open={Boolean(columnAnchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      {activeColumn && (
        <MenuItem onClick={() => selectFieldBoxColumn(activeColumn)}>{activeColumn.name}</MenuItem>
      )}
      {unassignedColumns.map((column) => {
        return (
          <MenuItem
            key={`column-dropdown-${column.id}`}
            onClick={() => selectFieldBoxColumn(column)}
          >
            {column.name}
          </MenuItem>
        )
      })}
    </Menu>
  )
}

export default FieldBoxLabelDropdown
