import { formatMaybeApolloError } from '@src/utils/errors'
import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useQuery } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/styles'

import {
  DocumentTypeNode,
  DocumentTypeNodeEdge,
  FilePageNode,
  JobNodeEdge,
  JobTemplateNode,
  Maybe,
  Query,
  QueryUsersByRoleArgs,
  TaskNode,
} from '@src/graphql/types'
import { USERS_BY_ROLE } from '@src/graphql/queries/user'
import { PAGE_ASSOC_DIMENSIONS } from '@src/utils/app_constants'
import { getDatePickerDate } from '@src/utils/date'
import { formatFilePreviewName } from '@src/utils/file'
import { UserRole } from '@src/graphql/types'

const useStyles = makeStyles({
  docTypeSelect: {
    width: PAGE_ASSOC_DIMENSIONS.DOC_TYPE_SELECT_WIDTH,
  },
})

type Props = {
  filePages: FilePageNode[] | undefined
  selectedFilePageIds: string[]
  selectedJob: Maybe<JobNodeEdge>
  selectedJobTemplate: Maybe<JobTemplateNode>
  createAndAssocPageToJob: (
    jobName: string,
    dateReceived: string,
    ownerId: string,
    qaId: string,
    docType: string,
    taskId: string,
  ) => Promise<void>
  associatePageToAnExistingJob: (docType: string) => Promise<void>
  isPageAssocDialogOpen: boolean
  setIsPageAssocDialogOpen: (toggle: boolean) => void
  filePageIdsBeingAssociated: string[]
  setFilePageIdsBeingAssociated: Dispatch<SetStateAction<string[]>>
  isCreateJob: boolean
  selectedTask: Maybe<Pick<TaskNode, 'id'>>
  selectedDocType: Maybe<DocumentTypeNode>
  setSelectedDocType: (docType: Maybe<DocumentTypeNode>) => void
}

const ConfirmPageAssocDialog: FunctionComponent<Props> = ({
  filePages,
  selectedFilePageIds,
  selectedJob,
  selectedJobTemplate,
  createAndAssocPageToJob,
  associatePageToAnExistingJob,
  isPageAssocDialogOpen,
  setIsPageAssocDialogOpen,
  setFilePageIdsBeingAssociated,
  isCreateJob,
  selectedTask,
  selectedDocType,
  setSelectedDocType,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { loading: loadingJobOwners, data: usersByRoleData } = useQuery<
    Pick<Query, 'usersByRole'>,
    QueryUsersByRoleArgs
  >(USERS_BY_ROLE, {
    variables: { role: UserRole.Operator },
  })
  const [jobName, setJobName] = useState(null as null | string)
  const [ownerId, setOwnerId] = useState(null as null | string)
  const [qaId, setQAId] = useState(null as null | string)

  const isConfirmButtonDisabled =
    (isCreateJob && jobName?.length === 0) || selectedDocType?.id === null

  const docTypes = isCreateJob
    ? selectedJobTemplate?.documentTypes
    : selectedJob?.node?.jobTemplate?.documentTypes

  const pageNames =
    filePages
      ?.filter((page) => selectedFilePageIds.includes(page.id))
      ?.map((page) => formatFilePreviewName(page.file!.filename, page.pageNumber)) ?? []

  const closeConfirmDialog = (): void => {
    if (isCreateJob) {
      setJobName(null)
    }
    setIsPageAssocDialogOpen(false)
  }

  const confirmPageAssoc = async (): Promise<void> => {
    const requiredFields = [
      [selectedDocType!.id, 'document type'],
      [jobName, 'job name'],
    ]
    for (const [fieldValue, fieldName] of requiredFields) {
      if (!fieldValue) {
        enqueueSnackbar(`Please select a ${fieldName} first.`, { variant: 'error' })
        return
      }
    }
    const errorMessage = 'There was an error creating the job. Please try again later.'
    try {
      const currentlySelectedFilePageIds = [...selectedFilePageIds]
      setFilePageIdsBeingAssociated((prevState) => [...prevState, ...currentlySelectedFilePageIds])
      closeConfirmDialog()
      if (isCreateJob) {
        const dateReceived = getDatePickerDate()
        try {
          await createAndAssocPageToJob(
            jobName!,
            dateReceived,
            ownerId!,
            qaId!,
            selectedDocType!.id,
            selectedTask!.id,
          )
          enqueueSnackbar(`Job ${jobName} created and pages associated`, { variant: 'success' })
        } catch (error) {
          enqueueSnackbar(formatMaybeApolloError(error) || errorMessage, { variant: 'error' })
        } finally {
          setFilePageIdsBeingAssociated((prevState: string[]) =>
            prevState.filter((filePageId) => !currentlySelectedFilePageIds.includes(filePageId)),
          )
        }
      } else if (selectedJob) {
        try {
          await associatePageToAnExistingJob(selectedDocType!.id)
          enqueueSnackbar(`Page associated to job ${jobName}`, { variant: 'success' })
        } catch (error) {
          enqueueSnackbar(formatMaybeApolloError(error) || errorMessage, { variant: 'error' })
        } finally {
          setFilePageIdsBeingAssociated((prevState: string[]) =>
            prevState.filter((filePageId) => !currentlySelectedFilePageIds.includes(filePageId)),
          )
        }
      }
    } catch (error) {
      enqueueSnackbar(formatMaybeApolloError(error) || errorMessage, { variant: 'error' })
    }
  }

  useEffect(() => {
    if (!isCreateJob && selectedJob) {
      setJobName(selectedJob.node!.name)
      setOwnerId(selectedJob.node!.ownerId!)
      setQAId(selectedJob.node!.qaId!)
    } else if (isCreateJob) {
      setJobName(null)
      setOwnerId(null)
      setQAId(null)
    }
  }, [selectedJob, selectedJobTemplate, isCreateJob])

  return (
    <Dialog
      open={isPageAssocDialogOpen}
      onClose={closeConfirmDialog}
      aria-labelledby='page-assoc-dialog-title'
      aria-describedby='page-assoc-dialog-description'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id='page-assoc-dialog-title'>Move to extraction</DialogTitle>

      <DialogContent>
        <DialogContentText id='page-assoc-dialog-description'>
          Moving to extraction will place these pages:
          <br />
          {pageNames.map((pageName) => (
            <Box key={`${pageName}-dialog-text`} component='span' fontWeight='fontWeightBold'>
              {pageName}
              <br />
            </Box>
          ))}
          to Extraction Dashboard under a job in the current task
        </DialogContentText>

        <Box my={3}>
          <TextField
            label={`Job Name${!isCreateJob ? ' (readonly)' : ''}`}
            variant='outlined'
            value={jobName ?? ''}
            onChange={(evt) => setJobName(evt.target.value)}
            InputProps={{ inputProps: { readOnly: !isCreateJob } }}
            fullWidth
          />
        </Box>
        <Box my={3}>
          <TextField
            label='Job Type (readonly)'
            variant='outlined'
            value={isCreateJob ? selectedJobTemplate?.name : selectedJob?.node?.jobTemplate?.name}
            fullWidth
          />
        </Box>
        <Box my={3}>
          <FormControl variant='outlined'>
            <InputLabel>Document Type</InputLabel>
            <Select
              className={classes.docTypeSelect}
              value={selectedDocType?.id ?? ''}
              onChange={(evt) =>
                setSelectedDocType(
                  docTypes?.edges?.find((dt) => dt!.node!.id === evt.target.value)?.node ?? null,
                )
              }
              label='Document Type'
            >
              {docTypes?.edges?.map((docTypeNode: Maybe<DocumentTypeNodeEdge>) => {
                return (
                  <MenuItem key={docTypeNode?.node?.id} value={docTypeNode?.node?.id}>
                    {docTypeNode?.node?.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
        {isCreateJob && (
          <>
            <Box my={3}>
              <Autocomplete
                options={usersByRoleData?.usersByRole || []}
                getOptionLabel={(option) => option.email || ''}
                className={classes.docTypeSelect}
                disabled={loadingJobOwners}
                onChange={(_, newQa) => setOwnerId(newQa?.id || null)}
                renderInput={(params) => (
                  <TextField {...params} label='Job Owner (optional)' variant='outlined' />
                )}
              />
            </Box>
            <Box my={3}>
              <Autocomplete
                options={usersByRoleData?.usersByRole || []}
                getOptionLabel={(option) => option.email || ''}
                className={classes.docTypeSelect}
                disabled={loadingJobOwners}
                onChange={(_, newQa) => setQAId(newQa?.id || null)}
                renderInput={(params) => (
                  <TextField {...params} label='Job QA (optional)' variant='outlined' />
                )}
              />
            </Box>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant='contained' color='default' onClick={closeConfirmDialog}>
          No, Go Back
        </Button>

        <Button
          variant='contained'
          color='primary'
          disabled={isConfirmButtonDisabled}
          onClick={confirmPageAssoc}
        >
          Yes, Confirm Page
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmPageAssocDialog
