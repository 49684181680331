import { formatMaybeApolloError } from '@src/utils/errors'
import { ChangeEvent, FunctionComponent, useContext, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { MenuItem, Select, TextField, Theme } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

import FileUpload from '@src/components/upload-files/FileUpload'
import { groupFilesForUpload, pollPageAsyncBatch, uploadFileData } from '@src/utils/file'
import { FileUploadStatusProp, ImageFileData } from '@src/utils/types'
import theme from '@src/utils/theme'
import Box from '@material-ui/core/Box'
import { v4 as uuidv4 } from 'uuid'
import { Mutation, Query } from '@src/graphql/types'
import { INGEST_FILES } from '@src/graphql/mutations/file'
import { useSnackbar } from 'notistack'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import UploadingEta, { UploadPhase } from '@src/components/upload-files/UploadingEta'
import { USER_COMPANIES } from '@src/graphql/queries/company'
import { Auth0AccessTokenContext } from '@src/auth/Auth0AccessTokenContext'
import { MAX_FILE_PAGES_IN_SINGLE_UPLOAD } from '@src/utils/app_constants'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { LogEventType } from '@src/utils/observability/LogEventType'

type Props = {
  closePopup: () => void
  popupStatus: boolean
  setFileUploadStatus: (status: FileUploadStatusProp) => void
  setIsSnackBarOpen: (status: boolean) => void
  setIsUploadSnackbarOpen: (status: boolean) => void
  isUploading?: boolean
  setIsUploading: (status: boolean) => void
  onUploadSuccess?: () => void
  getItems: () => Promise<void>
}

const spinnerSize = 50

const useStyles = makeStyles<Theme>({
  title: {
    // default h4 fontWeight too light
    fontWeight: theme.typography.fontWeightBold,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: `-${spinnerSize}px`,
    marginLeft: `-${spinnerSize}px`,
  },
  close: {
    position: 'absolute',
    right: '2%',
    top: '2%',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  emailSubject: {
    width: '100%',
  },
  messageBody: {
    width: '100%',
  },
  detailHeader: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
})

// Basically, the Upload component without associating the pages to a job.
const Ingest: FunctionComponent<Props> = ({
  closePopup,
  popupStatus,
  setFileUploadStatus,
  setIsSnackBarOpen,
  setIsUploadSnackbarOpen,
  isUploading,
  setIsUploading,
  onUploadSuccess,
  getItems,
}: Props) => {
  const classes = useStyles()
  const [imageFileData, setImageFileData] = useState([] as ImageFileData[])
  const [addDetails, setAddDetails] = useState(false)
  const { data: companiesData } = useQuery<Pick<Query, 'companies'>>(USER_COMPANIES)
  const companies = companiesData ? companiesData.companies! : []
  const accessToken = useContext(Auth0AccessTokenContext)!
  const { logEvent } = useEventLogger()
  const actionStart = useRef<Date>()
  const { enqueueSnackbar } = useSnackbar()
  const [companyId, setCompanyId] = useState('')
  const [message, setMessage] = useState('')
  const [emailSubject, setEmailSubject] = useState('')
  const [progress, setProgress] = useState(0)
  const [etaSeconds, setEtaSeconds] = useState(null as null | number)
  const [uploadPhase, setUploadPhase] = useState(UploadPhase.UPLOADING)
  const [ingestFiles] = useMutation<Pick<Mutation, 'ingestFiles'>>(INGEST_FILES)
  const client = useApolloClient()

  const onClose = (): void => {
    closePopup()
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const handleCompanyChange = (event: ChangeEvent<any>): void => {
    const element = event.target as HTMLInputElement
    setCompanyId(element.value)
  }

  const handleMessageChange = (event: ChangeEvent): void => {
    const element = event.target as HTMLInputElement
    setMessage(element.value)
  }

  const uploadFiles = async (): Promise<void> => {
    actionStart.current = new Date()
    if (imageFileData.length >= MAX_FILE_PAGES_IN_SINGLE_UPLOAD) {
      enqueueSnackbar(
        `You can only upload ${
          MAX_FILE_PAGES_IN_SINGLE_UPLOAD - 1
        } pages at a time. Please deselect some pages and try again`,
        {
          variant: 'error',
        },
      )
      return
    }
    setIsUploadSnackbarOpen(true)
    setIsUploading(true)
    setUploadPhase(UploadPhase.UPLOADING)
    setEtaSeconds(null)
    setProgress(0)
    let errorMessage = ''
    let success = false
    try {
      const { progress$, viewUrls } = await uploadFileData(imageFileData, accessToken)
      progress$.subscribe((numDone) => setProgress((numDone * 100) / viewUrls.length))
      await progress$.toPromise()
      setProgress(0)
      setEtaSeconds(null)
      setUploadPhase(UploadPhase.PROCESSING)
      const batchId = uuidv4()
      await ingestFiles({
        variables: {
          emailSubject,
          message,
          companyId,
          files: groupFilesForUpload(imageFileData, viewUrls),
          batchId,
        },
      })
      // eslint-disable-next-line no-await-in-loop
      for await (const [currProgress, _, eta] of pollPageAsyncBatch(client, batchId)) {
        setProgress(currProgress * 100)
        setEtaSeconds(eta)
      }
      await getItems()
      setFileUploadStatus('success')
      onUploadSuccess?.()
      success = true
    } catch (error) {
      errorMessage = `Encountered error while uploading: ${formatMaybeApolloError(error)}`
      enqueueSnackbar(errorMessage)
      setFileUploadStatus('error')
    } finally {
      closePopup()
      setIsUploadSnackbarOpen(false)
      setIsUploading(false)
      setIsSnackBarOpen(true)

      void logEvent(LogEventType.FILE_UPLOAD, {
        job_id: null,
        action_start: actionStart.current,
        action_end: new Date(),
        file_types: imageFileData.map(({ type }) => type),
        success: success,
        error_message: errorMessage,
      })
    }
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialog }}
      onClose={onClose}
      open={popupStatus}
      fullScreen
    >
      <DialogTitle disableTypography>
        <Typography variant='h4' className={classes.title}>
          {addDetails ? 'Add Details' : 'Select Files'}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {addDetails ? (
          <div>
            <div className={classes.close}>
              <CloseIcon fontSize='large' onClick={closePopup} data-testid='close-btn' />
            </div>
            <div>
              <Typography className={classes.detailHeader}> Select Organization</Typography>
              <Select
                value={companyId}
                onChange={handleCompanyChange}
                label='Company'
                variant='outlined'
              >
                {companies.map((currCompany) => (
                  <MenuItem key={currCompany.id} value={currCompany.id}>
                    {currCompany.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <Typography className={classes.detailHeader}>Ingestion Identifier</Typography>
              <TextField
                variant='outlined'
                value={emailSubject}
                onChange={(evt) => setEmailSubject(evt.target.value)}
                className={classes.emailSubject}
                placeholder='Enter ingestion identifier here'
              />
            </div>
            <div>
              <Typography className={classes.detailHeader}> Message/Notes</Typography>
              <TextField
                variant='outlined'
                multiline
                rows={8}
                value={message}
                onChange={handleMessageChange}
                className={classes.messageBody}
                placeholder='Enter any email body or notes here'
              />
            </div>
          </div>
        ) : (
          <>
            <div className={classes.close}>
              <CloseIcon fontSize='large' onClick={closePopup} data-testid='close-btn' />
            </div>
            {isUploading ? (
              <UploadingEta progress={progress} uploadPhase={uploadPhase} etaSeconds={etaSeconds} />
            ) : (
              <Box display='flex' flexDirection='column' height='100%' overflow='hidden'>
                <FileUpload imageFileData={imageFileData} setImageFileData={setImageFileData} />
              </Box>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          size='large'
          color='primary'
          onClick={addDetails ? uploadFiles : () => setAddDetails(true)}
          disabled={isUploading || imageFileData.length === 0 || (addDetails && !companyId)}
          /*
           * We add a testid here because MUI puts a span tag underneath
           *  the buttons, making it hard to query the actual button.
           *  i.e., <button><span>Upload</span></button>
           */
          data-testid='upload-btn'
        >
          {isUploading ? 'Uploading...' : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Ingest
