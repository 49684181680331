import { JobNode } from '@src/graphql/types'
import { formatFieldGroups, formatLineItems } from './job_format'
import {
  getGroupedJobDocumentFieldGroups,
  makeFieldMapping,
  makeLineItemsMapping,
} from './row_conversion'
import { parseDateString } from '@src/utils/date'

export const CEVA_COLUMN_NAME_MAPPING: Record<string, string> = Object.freeze({
  'Client Code': 'Client Code',
  'Invoice No.': 'Invoice No',
  'Supplier Part No.': 'Supplier part no',
  'Order No.': 'Order No',
  Description: 'Part Desc',
  'Invoice Qty': 'Invoice Qty',
  Amount: 'Amount',
  Origin: 'ORG',
  'Invoice Date': 'Invoice Date',
  'Shipment #': 'Shipment',
  Currency: 'Currency',
  'Unit Price': 'Unit Price',
  'Gross Weight': 'Gross Weight',
  'Net Weight': 'Net Weight',
  UOM: 'UOM',
})

const formatColumnName = (
  row: Record<string, string>,
  columnNameMapping: Record<string, string>,
): Record<string, string> =>
  Object.keys(row).reduce(
    (acc, key) => {
      // overwrite this specific key with metadata if needed
      const header = columnNameMapping[key]
      acc[header] = row[key]
      return acc
    },
    {} as Record<string, string>,
  )

export const sortJobsByDateCreatedAndField = (jobs: JobNode[], autofillKey: string): JobNode[] =>
  [...jobs].sort((a, b) => {
    if (a.dateCreated !== b.dateCreated) {
      return +parseDateString(a.dateCreated) - +parseDateString(b.dateCreated)
    }

    const fieldValueA = getGroupedJobDocumentFieldGroups(a)
      .flat()
      .find((documentFieldGroup) => documentFieldGroup.fieldGroup!.autofillKey === autofillKey)!
      .documentFields!.edges[0]!.node!.value
    const fieldValueB = getGroupedJobDocumentFieldGroups(b)
      .flat()
      .find((documentFieldGroup) => documentFieldGroup.fieldGroup!.autofillKey === autofillKey)!
      .documentFields!.edges[0]!.node!.value
    return fieldValueA > fieldValueB ? 1 : -1
  })

export const formatCevaJob = (job: JobNode): JobNode => {
  const fieldGroupsToFormat = ['ceva_sea_client_code', 'ceva_air_client_code']
  const fieldGroupFormatter = (value: string): string => {
    const valuesDict: Record<string, string> = {
      '3M AUSTRALIA': '1000103244',
      'ACER COMPUTERS': '1000008042',
      'ACRYLIC TECHNOLOGIES AUSTRALIA': '1000574511',
      'ACTIVE MEDICAL': '1000285007',
      'ADELAIDE FENCING & STEEL SUPPLIES': '1000205866',
      'ADELAIDE LEATHER & SADDLERY SUPPLIES': '1000385808',
      'AERISON PTY LTD': '1000509483',
      'AGCO AUSTRALIA LIMITED': '1000510601',
      'AKZO NOBEL PTY LTD': '1000175895',
      'ALE AUSTRALIA ( KNOWN AS ALENTERPRISE )': '1000574521',
      'ALL ABOUT CARTONS': '1000104793',
      'AMBER TECHNOLOGY LIMITED': '1000478777',
      'ANL CONT. LINE - BROKERAGE (1000176609)': 'ANLCONTBRO',
      'ANL LOGISTICS - BROKERAGE (1000442835)': 'ANLBROKER',
      'APPLE PTY LIMITED - RETAIL': '1000052724',
      'AQUAMAX AUSTRALIA PTY LTD': '1000428039',
      AQUAMONIX: '1000386575',
      'ARIES RAIL PTY LTD': '1000566744',
      'ARISTOCRAT TECHNOLOGIES AUST. PTY LTD': '1000176327',
      'ARJO AUSTRALIA PTY LTD': '1000444311',
      'ARMSTRONG PACKAGING': '1000517758',
      'ARMY & AIR FORCE EXCHANGE SERVICE': '1000494027',
      'AUD IMPORTS': '1000490217',
      'AUSTRAL FISHERIES - SEAFOOD TRADING DIVI': '1000456449',
      'AUSTRALIAN DAIRY PARK P/L': '1000457780',
      'AUSTRALIAN FLEXIBLE JOINTS': '1000353370',
      'AUSTRALIAN GOVERMENT DEPARTMENT': '1000526961',
      'AUSTRALIAN WHOLESALE OILS PTY LTD': '1000252694',
      'AVAYA AUSTRALIA PTY LTD': '1000356416',
      'BAE SYSTEMS (AUSTRALIA) LTD': '1000190579',
      'BAE SYSTEMS AUSTRALIA': '1000434340',
      'BAE SYSTEMS AUSTRALIA LIMITED': '1000103408',
      BAOBAG: '1000447343',
      'BEAM MOBILITY AUSTRALIA PTY LTD': '1000456308',
      'BEIERSDORF HEALTH CARE AUSTRALIA': '1000561476',
      'BETTER CONFECTIONERY P/L': '1000569533',
      'BIDFOOD AUSTRALIA LTD': '1000459974',
      'BRAY CONTROLS PACIFIC': '1000171625',
      'BROWN & WATSON INTERNATIONAL PL': '1000404049',
      'C&P ENTERPRISES PTY LTD T/AS 7MM': '1000233345',
      'CALTEX AUSTRALIA (PETROLEUM) PTY LTD': '1000164652',
      'CANON AUSTRALIA PTY LTD': '1000003907',
      'CANON MEDICAL SYSTEMS ANZ PTY LTD': '1000418759',
      'CANON PRODUCTION PRINTING AUSTRALIA': '1000405780',
      'CARE ESSENTIALS PTY LTD': '1000103520',
      'CATERPILLAR SARL SINGAPORE': '1000326484',
      'CELESTICA BLUE C/O MITEL NETWORKS': '1000084556',
      'CHEMXPRESS DISTRIBUTORS': '1000482294',
      'CLARKE ENERGY (AUST) PTY LTD': '1000105140',
      'CMA CGM AUSTRALIA PTY LTD': '1000561491',
      'CMA CGM FREMANTLE': '1000496882',
      'CODE 42 AUSTRALIA PTY LTD': '1000307657',
      'COLD FORGED PRODUCTS NO 1 PTY LTD': '1000003909',
      'COLOPLAST PTY. LTD': '1000428011',
      'COMBINED GROUP OF COMPANIES': '1000338459',
      'CONDO FASHION AGENCY': '1000462803',
      'CONDOR ENERGY AUSTRALIA': '1000431932',
      CONVATEC: '1000103599',
      'COSPAK (SMYC P/L)': '1000553066',
      'CUMMINS SOUTH PACIFIC PTY LTD': '1000008293',
      'DAIKIN AUSTRALIA PTY LTD': '1000511210',
      'DAMSMART (SILVER & BALLARD (AUSTRALIA))': '1000022909',
      'DECOLINE PTY LTD': '1000468512',
      'DELPHI TECHNOLOGIES': '1000009829',
      'DICKER DATA': '1000231869',
      'DOLPHIN PLASTICS': '1000007073',
      'DR OETKER FOODS AUSTRALIA PTY LTD': '1000542650',
      'DTA ROBIT': '1000447138',
      'DYNABOOK ANZ PTY LIMITED': '1000386958',
      'EASY LIVING SALES & MARK.': '1000070398',
      'EMEIS COSMETICS PTY LTD': '1000516864',
      'EMONA INSTRUMENTS PTY LTD': '1000485874',
      'EROTICGEL AUSTRALIA ***COD ACCOUNT***': '1000520724',
      'EUROTAINER SA': '1000457495',
      'EVERGREEN SYNTHETIC GRASS': '1000477323',
      'EXPERT MARKETING & PROMOTIONS LTS': '1000405879',
      EZYCHARGERS: '1000159611',
      'FELTON GRIMWADE & BOSISTO S PTY LTD': '1000564903',
      'FISHER AND PAYKEL HEALTHCARE PTY': '1000428953',
      'FORD MOTOR COMPANY': '1000150927',
      'FORD MOTOR COMPANY OF AUSTRALIA': '1000348471',
      'FOSSIL (AUSTRALIA) PTY LIMITED': '1000008121',
      'FRESENIUS KABI AUSTRALIA PTY LTD': '1000458907',
      'FURNACE ENGINEERING PTY LTD': '1000565464',
      'FYLOR PTY LTD': '1000435091',
      'GERMAIN & SONS PTY LTD': '1000459268',
      'GOBBLERS FOOD INGREDIENTS': '1000544927',
      'GRAPH-PAK PTY LTD': '1000388186',
      'HACH PACIFIC AUSTRALIA': '1000227536',
      'HANGERS AUSTRALIA': '1000280588',
      'HARD METAL INDUSTRIES': '1000448261',
      'HARLEY DAVIDSON AUSTRALIA': '1000175930',
      'HARLEY-DAVIDSON AUSTRALIA': '1000451023',
      'HARPER COLLINS PUBLISHERS AUSTRALIA': '1000475220',
      'HB COMMERCE PTY LTD': '1000441938',
      'HCG GROUP PTY LTD': '1000389541',
      'HE PARTS INTL MINING SOLUTIONS P/L': '1000020756',
      'HEALTHIER TASTIER FOODS': '1000570712',
      'HERMES AUSTRALIA PTY LTD': '1000505366',
      'HEXION AUSTRALIA PTY LTD': '1000558528',
      'HJ HEINZ CO. AUST LTD-IMPORTS': '1000011176',
      'HOBSON ENGINEERING': '1000568421',
      'HOLDEN SERVICE PARTS OPERATION HSPO': '1000108314',
      'HONEYWELL AUSTRALIA - HPS': '1000103953',
      'HONEYWELL AUSTRALIA - IMC & HFS': '1000103954',
      'HONEYWELL SAFETY PRODUCTS AUST': '1000070549',
      'HOWMET WHEEL SYSTEMS AUSTRALIA PTY LTD': '1000570698',
      'HOYA LENS PTY. LTD.': '1000103967',
      'HYGRADE WATER AUSTRALIA': '1000346555',
      'HYPERION MAGTERIALS & TECHNOLOGIES *COD*': '1000477125',
      'HYSTER-YALE ASIA PACIFIC P/L': '1000413514',
      'INDIVIOR PTY LTD': '1000295268',
      INFASECURE: '1000572314',
      'INFASTECH ENGINEERED FASTENING': '1000509798',
      'INGRAM MICRO AUSTRALIA PTY LTD': '1000041068',
      'INGRAM MICRO WAREHOUSE & DISTRIBUTION CE': '1000041579',
      'INTERNATIONAL FLAVOURS & FRAGRANCES ( AU': '1000360890',
      JAYBRO: '1000572884',
      'JINKO SOLAR AUSTRALIA HOLDINGS CO. PTY': '1000511672',
      'JLG INDUSTRIES': '1000221497',
      'JLG INDUSTRIES (AUSTRALIA)': '1000330427',
      'JOHN BERENDS IMPLEMENTS': '1000061919',
      'JOINT LOGISTICS UNIT(STH QUEENSLAND)': '1000499823',
      'JUROX PTY LTD': '1000509808',
      'KAO AUSTRALIA': '1000324388',
      'KELLOGG AUSTRALIA PTY LTD': '1000307802',
      'KISMET INTERNATIONAL': '1000104088',
      'KOGAN AUSTRALIA PTY LTD': '1000427579',
      'KONECRANES PTY LTD': '1000377568',
      'KOUNIS METAL INDUSTRIES PTY LTD': '1000140853',
      'LEADER COMPUTERS PTY LTD (NSW)': '1000527125',
      'LIBERTY MEDICAL PTY LTD': '1000206811',
      'LICENSYS PTY LTD': '1000196687',
      'LM HOME INTERIORS': '1000467032',
      'LOCKHEED MARTIN AUST.': '1000104148',
      'LOCKHEED MARTIN CORPORATION': '1000104149',
      'MAG SPECIALISED PLUMPING PRODUTS PTY LTD': '1000546852',
      'MANILDRA GROUP OF COMPANIES': '1000389937',
      'MARAND PRECISION ENGINEERING PTY LTD': '8318387',
      'MARINE ROTATIONAL': '1000482980',
      'MARKWELL PACIFIC MARKETING PTY LTD': '1000455604',
      'MASTER OF M/V YVONNE': '1000572138',
      'MB SOLUTIONS AUSTRALIA PTY LTD *COD*': '1000565496',
      'MCFARLANE MEDICAL & SCIENTIFIC PTY LTD': '1000570287',
      'MDM IMPORTS PTY LTD': '1000485158',
      'MEDI DENT DISPOSABLE INTERNATIONAL (MDDI': '1000148093',
      'MEDTRONIC AUSTRALIA PTY LTD': '1000043245',
      'MONDELEZ AUSTRALIA PTY LTD': '1000425783',
      'MORPHE PTY LTD C/- QUANTIUM SOLUTIONS': '1000426091',
      'NATURELO C/O AMAZON AUSTRALIA': '1000547460',
      'NCR AUSTRALIA PTY LTD': '1000104279',
      'NESPRESSO AUSTRALIA': '1000572139',
      'NOKIA SOLUTIONS & NETWORKS AUSTRALIA PTY': '1000428727',
      'NORMET (MINING TRUCK DIV)ASIA PACIFIC PL': '1000174472',
      'NORMET ASIA PACIFIC': '1000046243',
      'NORMET ASIA-PACIFIC': '1000333007',
      'NORSKE SKOG PAPER MILLS LTD': '1000453326',
      'NOVA PROTECTION SYSTEMS PTY LTD': '1000453788',
      'OPTIMISED NETWORK EQUIPMENT PTY LTD': '1000343015',
      'OXFORD UNIVERSITY PRESS ANZ': '1000568110',
      'OZONE ORGANICS': '1000534467',
      'PACCAR AUSTRALIA PTY LTD': '1000536281',
      'PACIFIC WEST FOODS AUSTRALIA PTY LTD': '1000377226',
      'PACIFICA TRADING PTY LTD': '1000570500',
      'PEMCO AGENCIES PT LTD****COD****': '1000572009',
      'PENTAIR FLOW TECHNOLOGIES': '1000369249',
      'PHILIPS HEALTHCARE AUSTRALIA': '1000436760',
      'PINJARRA ENGINEERING PTY LTD': '1000150858',
      'POLYFABRICS AUSTRALASIA PTY LTD(TAPEX)': '1000510580',
      'PROPAX PTY LTD': '1000104420',
      'PRYSMIAN AUSTRALIA PTY LTD': '1000148631',
      'PS IMPORTERS PTY LTD': '1000491077',
      'PYROGEN AUSTRALIA P/L *COD*': '1000376867',
      'QUAKEWRAP PTY LTD': '1000571672',
      'QUICKSTEP TECHNOLOGIES PTY LIMITED': '1000184391',
      'RAMVEK PTY LTD': '1000338757',
      'RANDOX (AUST) PTY LTD': '1000104462',
      'RD TUNA CANNER LTD': '1000571283',
      'REPLANT-IT LTD': '1000451813',
      'RESOURCE INDUSTRY SOLUTIONS***COD***': '10004969',
      'RETAIL APPAREL GROUP': '1000517560',
      'RHEEM AUSTRALIA PTY LTD': '1000424145',
      'RICHEMONT AUSTRALIA PTY LTD': '1000377594',
      'RICKY RICHARDS PTY LTD': '1000377490',
      'RING LLC': '1000463380',
      'ROBLAN PTY LIMITED *** COD ***': '1000072008',
      ROCKWEAR: '1000517569',
      'ROGER HENLEY - XLENT ENTERPRISES PTY LTD': '1000143993',
      'ROLLS-ROYCE PLC': '1000402890',
      'SAMUEL PACKAGING': '1000570817',
      'SAVANNAH BIO SYSTEMS': '1000007130',
      'SCHAEFER SYSTEMS INTERNATIONAL PTY LTD': '1000435817',
      'SEER MEDICAL': '1000565879',
      'SEVEN SEAS SEAFOODS PTY LTD': '1000450836',
      'SIGNIFY (FORMERLY PHILIPS LIGHTING AUST)': '1000428546',
      'SKF AUSTRALIA **COD**': '1000378145',
      'SOL DISTRIBUTION PTY LTD': '1000464993',
      'STEELCHIEF INDUSTRIES P/L': '1000104626',
      'STOLLS SPRAYING EQUIPMENT': '1000511339',
      'STRYKER AUSTRALIA PTY LTD COD': '1000109220',
      'SUBA ENGINEERING PTY LTD': '1000394928',
      'SUPPLY PARTNERS': '1000372830',
      'SURGICAL SPECIALTIES PTY LTD': '1000574197',
      'SYDNEY SUNNY GLASS PTY LTD': '1000571486',
      'SYNNEX AUSTRALIA': '1000048555',
      'TAGHLEEF INDUSTRIES PTY LTD': '1000432649',
      "TALLEY'S AUSTRALIA PTY LTD": '1000496927',
      'TE CONNECTIVITY AUSTRALIA PTY LTD': '1000360317',
      'TECH DATA (ANZ) PTY.LTD': '1000424985',
      'TENNANT AUSTRALIA PTY LTD': '1000043361',
      'TEREX AUSTRALIA': '1000186376',
      'TEREX AUSTRALIA GENIE AWP': '1000373192',
      'THALES AUSTRALIA': '1000104689',
      'THE BODY SHOP AUSTRALIA P/L': '2042649',
      'TOSHIBA GLOBAL COMMERCE SOLUTIONS': '1000442415',
      'TSURUMI AUSTRALIA PTY LTD': '1000488759',
      'ULTIMATUM PTY LTD': '1000514893',
      'UNIFORM SAFETY SIGNS': '1000278895',
      'UNILEVER AUSTRALIA': '1000104772',
      'VYAIRE MEDICAL PTY LTD': '1000489648',
      'WARTSILA AUSTRALIA *COD*': '1000545407',
      'WOOLWORTHS GROUP LIMITED': '1000512168',
      'WORK ARENA': '6757797',
      "WORLD'S BEST TECHNOLOGY DISTRIBUTORS": '1000487300',
      'YAKIMA AUSTRALIA PTY LTD': '1000571066',
      'ZEBRAVET QLD PTY LTD': '1000346137',
      'ZERO MASS WATER AUSTRALIA PTY': '1000515319',
      '3M NEW ZEALAND LTD': '1522135',
      'BLU-SKY UK LTD': '1000574753',
      'CONG THANH NOODLES PTY LTD': '1000303813',
      'DAB PUMPS OCEANIA PTY LTD': '1000412216',
      'DIMAC TOOLING PTY LTD': '1000339055',
      'GAMECO NEW ZEALAND LTD': '1000105250',
      'HILLS PET NUTRITION': '1000105291',
      'TRANSPORTECT NZ LTD': '6634274',
    }
    return valuesDict[value] || value
  }
  let formattedFieldGroupsJob = formatFieldGroups(job, fieldGroupsToFormat, fieldGroupFormatter)
  const commaLineItemToFormat = ['amount', 'invoice_qty']
  const replaceCommaFormatter = (value: string): string => value.replace(/,/g, '')
  formattedFieldGroupsJob = formatLineItems(
    formattedFieldGroupsJob,
    commaLineItemToFormat,
    replaceCommaFormatter,
  )
  const spaceLineItemToFormat = [
    'supplier_part_no',
    'ceva_sea_ceva_order_number',
    'ceva_air_ceva_order_number',
  ]
  const spaceFieldGroupsToFormat = [
    'ceva_sea_order_number',
    'ceva_air_order_number',
    'ceva_sea_invoice_number',
    'ceva_air_invoice_number',
  ]
  const replaceSpaceFormatter = (value: string): string => value.replace(/ /g, '')
  formattedFieldGroupsJob = formatFieldGroups(
    formattedFieldGroupsJob,
    spaceFieldGroupsToFormat,
    replaceSpaceFormatter,
  )
  formattedFieldGroupsJob = formatLineItems(
    formattedFieldGroupsJob,
    spaceLineItemToFormat,
    replaceSpaceFormatter,
  )
  return formattedFieldGroupsJob
}

export const formatCevaJobToRows = (
  job: JobNode,
  columnNameMapping: Record<string, string>,
  enableLineItemsRowOrderPriority = false,
): Record<string, string>[] => {
  const groupedDocumentFieldGroups = getGroupedJobDocumentFieldGroups(job)
  const metaData = makeFieldMapping(groupedDocumentFieldGroups.flat(), true)
  const formattedMetaData = formatColumnName(metaData, columnNameMapping)
  const lineItems = makeLineItemsMapping(
    groupedDocumentFieldGroups,
    enableLineItemsRowOrderPriority,
  )
  const rows = lineItems.map((lineItem) => ({
    ...formattedMetaData,
    ...formatColumnName(lineItem, columnNameMapping),
  }))
  return rows
}

export const _private = { formatColumnName }
