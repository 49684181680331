import { FunctionComponent, useState } from 'react'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import { RenderElementProps, useFocused, useSelected } from 'slate-react'
import { clsx } from 'clsx'

import theme from '@src/utils/theme'

const useStyles = makeStyles({
  imageElement: {
    maxHeight: theme.spacing(15),
    maxWidth: '100%',
    display: 'block',
  },
  clickable: {
    cursor: 'pointer',
  },
  selected: {
    boxShadow: `0 0 0 ${theme.spacing(0.25)}px ${theme.palette.info.light}`,
  },
  previewDialogContent: {
    maxWidth: '100%',
  },
})

const ImageElement: FunctionComponent<RenderElementProps> = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const classes = useStyles()
  const selected = useSelected()
  const focused = useFocused()
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)

  return (
    <>
      <Box {...attributes}>
        <Box width='100%' contentEditable='false'>
          <img
            className={clsx(classes.imageElement, classes.clickable, {
              [classes.selected]: selected && focused,
            })}
            src={element.url as string}
            alt={element.caption as string}
            onClick={() => setIsPreviewDialogOpen(true)}
            role='presentation'
          />
          <a href={element.url as string} target='_blank' rel='noreferrer' contentEditable='false'>
            {element.caption as string}
          </a>
        </Box>
        {children}
      </Box>

      <Dialog fullScreen open={isPreviewDialogOpen} onClose={() => setIsPreviewDialogOpen(false)}>
        <DialogTitle disableTypography>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography variant='h3'>{element.caption as string}</Typography>
            <IconButton onClick={() => setIsPreviewDialogOpen(false)} aria-label='close'>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box textAlign='center'>
            <img
              className={classes.previewDialogContent}
              src={element.url as string}
              alt={element.caption as string}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ImageElement
