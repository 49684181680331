import { FunctionComponent, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, TextField, Theme, Tooltip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { useQuery } from '@apollo/client'
import theme from '@src/utils/theme'
import { Query, ApiPartnerNode, ApiPartnerType } from '@src/graphql/types'
import { ALL_COMPANIES } from '@src/graphql/queries/company'
import ControllerTextField from '@src/components/controller-text-field/ControllerTextField'
import ControllerErrorText from '@src/components/controller-error-text/ControllerErrorText'
import BuildRoundedIcon from '@material-ui/icons/BuildRounded'
import { v4 as uuid4 } from 'uuid'
import ConfirmGenerateDialog from './ConfirmGenerateDialog'
import { GET_JOB_TEMPLATES } from '@src/graphql/queries/jobTemplate'
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { ApiPartnerTmsType } from '@src/graphql/types'
import { isFallback } from '@src/utils/enum'

type JobTemplateOption = {
  id: string
  name: string | null
}

export type ApiPartnerFormValues = {
  apiKey: string
  exportEndpoint: string | null
  importEndpoint: string | null
  documentExportEndpoint: string | null
  documentImportEndpoint: string | null
  name: string
  companyId: string | null
  countryCode: string | null
  credentialId: string | null
  jobTemplates: JobTemplateOption[] | null
  expedockDocIngestEmail: string | null
  apiPartnerType: ApiPartnerType
  authValues: string | null
}

type Props = {
  apiPartner?: ApiPartnerNode | null
  isOpen: boolean
  close: () => void
  onSubmit: (apiPartner: ApiPartnerFormValues) => Promise<void>
}

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  submitButton: {
    textAlign: 'right',
  },
})

const CreateUpdateApiPartnerDialog: FunctionComponent<Props> = ({
  apiPartner,
  isOpen,
  close,
  onSubmit,
}) => {
  const classes = useStyles()
  const [openConfirmGenerateDialog, setOpenConfirmGenerateDialog] = useState(false)
  const formMethods = useForm<ApiPartnerFormValues>()
  const { handleSubmit, reset, trigger, setValue } = formMethods
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: allCompanies, loading: allCompaniesLoading } =
    useQuery<Pick<Query, 'allCompanies'>>(ALL_COMPANIES)
  const { data: jobTemplates, loading: jobTemplatesLoading } =
    useQuery<Pick<Query, 'jobTemplates'>>(GET_JOB_TEMPLATES)

  const isUpdating = !!apiPartner
  const companyOptions =
    apiPartner?.company &&
    !allCompanies?.allCompanies?.find((company) => company.id === apiPartner?.company!.id)
      ? [...(allCompanies?.allCompanies || []), apiPartner.company]
      : allCompanies?.allCompanies
  const jobTemplateOptions =
    jobTemplates?.jobTemplates.map((jobTemplate) => ({
      id: jobTemplate.id,
      name: jobTemplate.name,
    })) || []
  const apiPartnerTmsType = apiPartner?.tmsType
    ? isFallback(apiPartner.tmsType)
      ? (apiPartner?.tmsType?.fallbackValue as ApiPartnerTmsType)
      : (apiPartner?.tmsType?.value as ApiPartnerTmsType)
    : undefined

  const submitForm = async (): Promise<void> => {
    const isFormValid = await trigger()
    if (!isFormValid) {
      return
    }
    setIsSubmitting(true)
    await handleSubmit(onSubmit)()
    setIsSubmitting(false)
    reset()
    close()
  }

  const maybeFormatJsonString = (jsonString: string): string => {
    try {
      return JSON.stringify(JSON.parse(jsonString), null, 4)
    } catch (err) {
      return jsonString
    }
  }

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        classes={{ paper: classes.dialog }}
        onClose={close}
        open={isOpen}
      >
        <IconButton
          aria-label='close'
          onClick={close}
          disabled={isSubmitting}
          className={classes.close}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle disableTypography>
          <Typography variant='h3'>{`${isUpdating ? 'Update' : 'Create'} API Partner`}</Typography>
        </DialogTitle>
        <DialogContent>
          <Box pb={2}>
            <FormProvider {...formMethods}>
              <Box mb={1}>
                <Typography>API Key</Typography>
                <Grid container>
                  <Grid item xs={11}>
                    <ControllerTextField
                      name='apiKey'
                      friendlyName='API Key'
                      defaultValue={apiPartner?.apiKey ?? uuid4()}
                      rules={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Box display='flex'>
                      <Tooltip title='Generate API Key'>
                        <IconButton
                          onClick={() => {
                            if (formMethods.getValues('apiKey')) {
                              setOpenConfirmGenerateDialog(true)
                            } else {
                              formMethods.setValue('apiKey', uuid4())
                            }
                          }}
                        >
                          <BuildRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={1}>
                <Typography>Export Endpoint</Typography>
                <ControllerTextField
                  name='exportEndpoint'
                  friendlyName='Export Endpoint'
                  defaultValue={apiPartner?.exportEndpoint}
                />
              </Box>
              <Box mb={1}>
                <Typography>Import Endpoint</Typography>
                <ControllerTextField
                  name='importEndpoint'
                  friendlyName='Import Endpoint'
                  defaultValue={apiPartner?.importEndpoint}
                />
              </Box>
              <Box mb={1}>
                <Typography>Document Export Endpoint</Typography>
                <ControllerTextField
                  name='documentExportEndpoint'
                  friendlyName='Document Export Endpoint'
                  defaultValue={apiPartner?.documentExportEndpoint}
                />
              </Box>
              <Box mb={1}>
                <Typography>Document Import Endpoint</Typography>
                <ControllerTextField
                  name='documentImportEndpoint'
                  friendlyName='Document Import Endpoint'
                  defaultValue={apiPartner?.documentImportEndpoint}
                />
              </Box>
              <Box mb={1}>
                <Typography>Name</Typography>
                <ControllerTextField
                  name='name'
                  friendlyName='Name'
                  defaultValue={apiPartner?.name}
                />
              </Box>
              <Box mb={1}>
                <Typography>Expedock Document Ingest Email</Typography>
                <ControllerTextField
                  name='expedockDocIngestEmail'
                  friendlyName='Expedock Document Ingest Email'
                  defaultValue={apiPartner?.expedockDocIngestEmail}
                  rules={{ pattern: /^\S+@\S+$/i }}
                />
              </Box>
              <Box mb={1}>
                <Typography>Auth Values</Typography>
                <Controller
                  name='authValues'
                  defaultValue={maybeFormatJsonString(apiPartner?.authValues ?? '')}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <TextField
                      placeholder='Auth Values JSON'
                      multiline
                      fullWidth
                      variant='outlined'
                      rows={5}
                      onChange={(event) => onChange(event.target.value)}
                      inputRef={ref}
                      {...rest}
                    />
                  )}
                />
              </Box>
              <Box mb={1}>
                <Controller
                  render={({ field: { ref, value, ...rest } }) => (
                    <ToggleButtonGroup
                      exclusive
                      value={value}
                      color='primary'
                      aria-label='outlined primary button group'
                      {...rest}
                    >
                      <ToggleButton
                        value={ApiPartnerType.Live}
                        onClick={() => {
                          setValue('apiPartnerType', ApiPartnerType.Live)
                        }}
                      >
                        Live
                      </ToggleButton>
                      <ToggleButton
                        value={ApiPartnerType.Test}
                        onClick={() => {
                          setValue('apiPartnerType', ApiPartnerType.Test)
                        }}
                      >
                        Test
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                  name={`apiPartnerType`}
                  defaultValue={apiPartner?.apiPartnerType || ApiPartnerType.Live}
                />
              </Box>
              <Box mb={2}>
                <Typography>TMS Type</Typography>
                <Controller
                  render={({ field: { value, onBlur, onChange } }) => (
                    <>
                      <Autocomplete
                        options={Object.values(ApiPartnerTmsType)}
                        getOptionLabel={(option: ApiPartnerTmsType) => option.toString()}
                        defaultValue={apiPartnerTmsType}
                        onChange={(_, value) => onChange(value)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </>
                  )}
                  name='tmsType'
                  defaultValue={apiPartnerTmsType}
                  rules={{ required: false }}
                />
              </Box>
              <Box mb={1}>
                <Typography>Country Code</Typography>
                <ControllerTextField
                  name='countryCode'
                  friendlyName='Country Code'
                  defaultValue={apiPartner?.countryCode}
                />
              </Box>
              <Box mb={2}>
                <Typography>Company</Typography>
                <Controller
                  render={({ field: { ref, value, ...rest }, fieldState: { invalid, error } }) => (
                    <>
                      <Select
                        disabled={allCompaniesLoading}
                        inputRef={ref}
                        margin='dense'
                        error={invalid}
                        value={value ?? ''}
                        {...rest}
                        fullWidth
                      >
                        {companyOptions?.map((company) => (
                          <MenuItem key={company.id} value={company.id}>
                            {company.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <ControllerErrorText friendlyName='Company' error={error} />
                    </>
                  )}
                  name='companyId'
                  defaultValue={apiPartner?.company?.id}
                  rules={{ required: false }}
                />
              </Box>
              <Box mb={2}>
                <Typography>Job Template</Typography>
                <Controller
                  render={({ field: { value, onBlur, onChange } }) => (
                    <Autocomplete
                      multiple
                      disabled={jobTemplatesLoading}
                      options={jobTemplateOptions}
                      onChange={(_, value) => onChange(value)}
                      onBlur={onBlur}
                      getOptionLabel={(option: JobTemplateOption) => option.name ?? ''}
                      defaultValue={value}
                      renderInput={(params) => <TextField {...params} variant='standard' />}
                    />
                  )}
                  name='jobTemplates'
                  defaultValue={apiPartner?.jobTemplates?.edges.map(
                    (jobTemplate) => jobTemplate!.node!,
                  )}
                />
              </Box>
              <Box className={classes.submitButton}>
                <Button
                  size='large'
                  color='primary'
                  variant='contained'
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Box>
            </FormProvider>
          </Box>
        </DialogContent>
      </Dialog>
      {openConfirmGenerateDialog && (
        <ConfirmGenerateDialog
          isOpen={openConfirmGenerateDialog}
          close={() => {
            setOpenConfirmGenerateDialog(false)
          }}
          onConfirm={() => {
            setValue('apiKey', uuid4())
            setOpenConfirmGenerateDialog(false)
          }}
        />
      )}
    </>
  )
}

export default CreateUpdateApiPartnerDialog
