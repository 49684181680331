import { format } from 'date-fns'
import moment from 'moment'
import {
  JobTemplateReconType,
  Maybe,
  ReconResultType,
  Scalars,
  TaskNode,
  TaskStatus,
  TaskType,
} from '@src/graphql/types'
import { TextFieldRef } from '@src/types/shipment_form'
import { ReconResultInterface } from '@src/graphql/types'
import { SpreadsheetDataColumn } from './data-grid'
import { formatFieldToDateFormatString } from './ocr'
import { isFallback } from '@src/utils/enum'

export const millisecondsInMinute = 1000 * 60

export const parseDateString = (dateString: string | Scalars['DateTime']['output']): Date => {
  /*
    Date parsing with `new Date()` is whack and very browser-dependent. Mozilla doesn't
    allow parsing with empty year, but Chrome does. However, it sets the year to 2001
    by default. Here, we replace the year with the current year if 2001 isn't in dateString.
  */
  const now = new Date()
  const date = new Date(dateString)
  if (date.getFullYear() === 2001 && !dateString.includes('2001'))
    date.setFullYear(now.getFullYear())
  return date
}

export const formatDate = (dateString: string): string => {
  const date = parseDateString(dateString)
  return `${date.toLocaleDateString()} ${date.toTimeString()}`
}

// convert date (in milliseconds) to HHh:MMm format
export const formatDurationMsToHoursMins = (durationMs: number): string => {
  const diffMins = Math.ceil(durationMs / (1000 * 60))
  const diffHours = Math.floor(diffMins / 60).toString()
  let minsRemainder = (diffMins % 60).toString()
  // zero-pad mins
  minsRemainder = `0${minsRemainder}`.slice(-2)
  return `${diffHours.padStart(2, '0')}h:${minsRemainder.padStart(2, '0')}m`
}

export const parseHoursMinsToDurationMins = (hoursMins: string): number => {
  const [hours, mins] = hoursMins.split(':')
  return parseInt(hours.replace(/[^\d]+/, ''), 10) * 60 + parseInt(mins.replace(/[^\d]+/, ''), 10)
}

// get the number of days past from current date to a given date
// return N/A if no given date
export const getDaysPastDate = (date: Date | null): string => {
  if (!date) {
    return 'N/A'
  }
  const now = new Date()

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24

  // Calculating the time difference between two dates
  const diffInTime = now.getTime() - date.getTime()

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay)

  return `${diffInDays}`
}

export const getSLATimeLeft = (
  dateString: string | null | undefined,
  SLATime: number,
  defaultValue = 'No SLA assigned',
): string => {
  if (dateString) {
    const dueDate = parseDateString(dateString)
    dueDate.setMinutes(dueDate.getMinutes() + SLATime)
    const now = new Date()
    if (now > dueDate) {
      return 'OVERDUE'
    }

    const diff = dueDate.getTime() - now.getTime()
    return formatDurationMsToHoursMins(diff)
  }

  return defaultValue
}

/**
 *
 * @param task task to select SLA job from
 * @returns String represented SLA time of shortest job in task, or completed/overdue accordingly
 */
export const getTaskSLATimeLeft = (task: Maybe<TaskNode>): string => {
  const defaultValue = 'no SLA'
  if (!task) {
    return defaultValue
  }
  const jobSLATime = task?.taskSlaTimeJobRef?.job?.slaTime
  if (task?.dateConfirmed) {
    const dueDate = parseDateString(task?.dateReceived as string)
    const confirmedDate = parseDateString(task?.dateConfirmed)
    if (jobSLATime) {
      dueDate.setMinutes(dueDate.getMinutes() + jobSLATime)
      if (confirmedDate > dueDate) {
        return 'overdue'
      }
    }
    return 'confirmed'
  }
  if (!task?.dateReceived || !jobSLATime) {
    return defaultValue
  }
  const dueDate = parseDateString(task?.dateReceived)
  dueDate.setMinutes(dueDate.getMinutes() + jobSLATime)
  const now = new Date()
  if (now > dueDate) {
    return 'overdue'
  }
  const diff = dueDate.getTime() - now.getTime()
  return formatDurationMsToHoursMins(diff)
}

/*
 * Flag live task if it's overdue, confirmed and not done, or
 * if it is not confirmed and has a visual alert of <= 2 hours left on SLA
 */
export const taskSLAIsCriticallyLow = (task: Maybe<TaskNode>): boolean => {
  const taskIsDone = task?.status === TaskStatus.Done
  const criticallyLowSLABlocked =
    !task || taskIsDone || task.taskType === TaskType.Test || task.blocked
  if (criticallyLowSLABlocked) {
    return false
  }
  const jobSLATime = task?.taskSlaTimeJobRef?.job?.slaTime
  if (!jobSLATime) {
    return false
  }
  if (task?.dateConfirmed) {
    const confDate = parseDateString(task?.dateConfirmed)
    const dueDate = parseDateString(task?.dateReceived as string)
    dueDate.setMinutes(dueDate.getMinutes() + jobSLATime)
    if (confDate <= dueDate) {
      return false
    }
    if (taskIsDone) {
      return false
    }
    return true
  }
  const dueDate = parseDateString(task?.dateReceived as string)
  dueDate.setMinutes(dueDate.getMinutes() + jobSLATime)
  const twoHoursFromNow = new Date()
  twoHoursFromNow.setHours(twoHoursFromNow.getHours() + 2)
  // if now + 2 hours >= SLA date, it's low
  // else, it's fine
  if (twoHoursFromNow >= dueDate) {
    return true
  }
  return false
}

// Get the current date (now) in a format supported by material ui's date-picker
export const getDatePickerDate = (): string => {
  const offset = new Date().getTimezoneOffset()
  const tzAdjustedDate = new Date(new Date().getTime() - offset * 60 * 1000)
  const dateString = tzAdjustedDate.toISOString()
  return dateString.slice(0, dateString.lastIndexOf(':'))
}

export const checkDateOrder = (startDate: string | null, endDate: string | null): boolean => {
  if (!(startDate && endDate)) {
    return false
  }
  const startDateObj = new Date(startDate)
  const endDateObj = new Date(endDate)
  return startDateObj.getTime() > endDateObj.getTime()
}

export const formatDateForTextField = (date: string): string => {
  return format(parseDateString(date), "yyyy-MM-dd'T'HH:mm")
}

export const formatDateForNotif = (date: string): string => {
  return format(parseDateString(date), 'yyyy/MM/dd h:mm a')
}

export const getReconResultsDataModifiedDate = (
  reconResults: ReconResultInterface[],
  reconType: JobTemplateReconType | null,
  isCheckShipmentInfo?: boolean,
): Maybe<Date> => {
  if (reconResults.length === 0) return null
  let dateModified
  let chainIoModel
  const isApOrSoa = reconType === JobTemplateReconType.Ap || reconType === JobTemplateReconType.Soa
  if (isCheckShipmentInfo || isApOrSoa) {
    chainIoModel = reconResults.find(
      (reconResult) =>
        !isFallback(reconResult.type) &&
        reconResult.type.value === ReconResultType.FindShipmentReconResult &&
        reconResult.success,
    )
    dateModified =
      chainIoModel?.chainIoConsolidation?.dateModified ||
      chainIoModel?.chainIoShipment?.dateModified ||
      chainIoModel?.chainIoCustomsDeclaration?.dateModified
  } else {
    dateModified = reconResults.find(
      (reconResult) =>
        !isFallback(reconResult.type) &&
        reconResult.type.value === ReconResultType.FindConsolReconResult &&
        reconResult.success,
    )?.chainIoConsolidation?.dateModified
  }
  if (!dateModified) return null
  return parseDateString(dateModified)
}

export const milliSecondsToHumanReadableString = (milliSeconds: number): string => {
  const ceilMs = Math.max(0, milliSeconds)
  const seconds = ceilMs / 1000
  const numyears = Math.floor(seconds / 31536000)
  const numdays = Math.floor((seconds % 31536000) / 86400)
  const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600)
  const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
  const numseconds = Math.trunc((((seconds % 31536000) % 86400) % 3600) % 60)
  let timeStr = `${numdays} days ${numhours} hours ${numminutes} minutes ${numseconds} seconds`
  if (numyears > 0) {
    timeStr = `${numyears} years ${timeStr}`
  }
  return timeStr
}

export const handleMetadataDatePaste = (
  fieldRef: TextFieldRef,
  paste: ClipboardEvent,
  dateFormat: string,
): void => {
  const pasteData = paste.clipboardData?.getData('text') as string
  const formattedPaste = formatFieldToDateFormatString(pasteData, dateFormat)
  paste.preventDefault()
  fieldRef!.current!.setValue(formattedPaste.text || pasteData)
}

export const handleBeforeTableDatePaste = (
  columns: SpreadsheetDataColumn[],
  data: string[][],
  coords: Record<string, number>,
): void => {
  const colEnd = coords.startCol + data[0].length
  const dateColFormatIdx = {} as Record<number, string>
  for (let i = coords.startCol; i < colEnd; i++) {
    if (columns[i]?.dateFormat) {
      dateColFormatIdx[i - coords.startCol] = columns[i].dateFormat as string
    }
  }
  Object.entries(dateColFormatIdx).forEach(([colIdx, dateFormat]) => {
    for (let i = 0; i < data.length; i++) {
      const row = i
      const col = colIdx as unknown as number
      const date = data[row][col]
      const formattedDate = formatFieldToDateFormatString(date, dateFormat)
      data[row][col] = formattedDate.text || date
    }
  })
}

export const isValidRecentDate = (value: string, dateFormatString?: string): boolean => {
  // Validate date fields to be valid and to be above or equal to 1900
  const date = dateFormatString ? moment(value, dateFormatString, true) : moment(value)
  return date.isValid() && date.year() >= 1900
}
