import { MouseEvent, MutableRefObject, useCallback, useState } from 'react'
import { BoxDimension, Coordinates } from '@src/types/ocr'

type BoundingBoxHookType = {
  start: Coordinates
  end: Coordinates
  boxDimension: BoxDimension
  isMouseDown: boolean
  handleMouseDown: (event: MouseEvent) => void
}

const computeCoordinates = (
  boundingRect: ClientRect,
  xCoord: number,
  yCoord: number,
): Coordinates => {
  return {
    x: (xCoord - boundingRect.left) / boundingRect.width,
    y: (yCoord - boundingRect.top) / boundingRect.height,
  }
}

const limitCoordsToImageBox = (coordsRelativeToImage: Coordinates): Coordinates => {
  const coordsCopy = { ...coordsRelativeToImage }
  if (coordsCopy.x <= 0) {
    coordsCopy.x = 0
  }
  if (coordsCopy.x >= 1) {
    coordsCopy.x = 1
  }
  if (coordsCopy.y <= 0) {
    coordsCopy.y = 0
  }
  if (coordsCopy.y >= 1) {
    coordsCopy.y = 1
  }
  return coordsCopy
}

const limitCoordsToRowBox = (
  imageRect: ClientRect,
  rowBoxRect: ClientRect,
  xCoord: number,
  yCoord: number,
): Coordinates => {
  const borderThickness = 1
  const coordsRelativeToImage = computeCoordinates(imageRect, xCoord, yCoord)
  const coordsRelativeToRowBox = computeCoordinates(rowBoxRect, xCoord, yCoord)
  const coordsCopy = { ...coordsRelativeToImage }
  if (coordsRelativeToRowBox.x <= 0) {
    coordsCopy.x = (rowBoxRect.left - imageRect.left + borderThickness) / imageRect.width
  }
  if (coordsRelativeToRowBox.x >= 1) {
    coordsCopy.x = (rowBoxRect.right - imageRect.left - borderThickness) / imageRect.width
  }
  if (coordsRelativeToRowBox.y <= 0) {
    coordsCopy.y = (rowBoxRect.top - imageRect.top + borderThickness) / imageRect.height
  }
  if (coordsRelativeToRowBox.y >= 1) {
    coordsCopy.y = (rowBoxRect.bottom - imageRect.top - borderThickness) / imageRect.height
  }
  return coordsCopy
}

const useCreateBox = (
  isEnabled = true,
  imageBoxRef: MutableRefObject<HTMLImageElement | null>,
  rowBoxRef?: MutableRefObject<HTMLElement | null>,
): BoundingBoxHookType => {
  const [start, setStart] = useState({ x: 0, y: 0 } as Coordinates)
  const [end, setEnd] = useState({ x: 0, y: 0 } as Coordinates)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const boxDimension = {
    top: Math.min(start.y, end.y),
    left: Math.min(start.x, end.x),
    width: Math.abs(start.x - end.x),
    height: Math.abs(start.y - end.y),
  }

  const handleMouseMove = useCallback(
    (evt: MouseEventInit): void => {
      const imageRect = imageBoxRef?.current?.getBoundingClientRect()
      if (imageRect && evt.clientX && evt.clientY) {
        const coordsRelativeToImage = computeCoordinates(imageRect, evt.clientX, evt.clientY)
        if (rowBoxRef?.current) {
          const rowBoxRect = rowBoxRef.current.getBoundingClientRect()
          const adjustedCoords = limitCoordsToRowBox(
            imageRect,
            rowBoxRect,
            evt.clientX,
            evt.clientY,
          )
          setEnd(adjustedCoords)
        } else {
          setEnd(limitCoordsToImageBox(coordsRelativeToImage))
        }
      }
    },
    [imageBoxRef, rowBoxRef],
  )

  const handleMouseUp = useCallback(
    (evt: MouseEventInit): void => {
      const imageRect = imageBoxRef?.current?.getBoundingClientRect()
      if (imageRect && evt.clientX && evt.clientY) {
        const coordsRelativeToImage = computeCoordinates(imageRect, evt.clientX, evt.clientY)
        if (rowBoxRef?.current) {
          const rowBoxRect = rowBoxRef.current.getBoundingClientRect()
          const adjustedCoords = limitCoordsToRowBox(
            imageRect,
            rowBoxRect,
            evt.clientX,
            evt.clientY,
          )
          setEnd(adjustedCoords)
        } else {
          setEnd(limitCoordsToImageBox(coordsRelativeToImage))
        }
        setIsMouseDown(false)
        document.removeEventListener('mousemove', handleMouseMove)
      }
    },
    [handleMouseMove, imageBoxRef, rowBoxRef],
  )

  const handleMouseDown = useCallback(
    (evt: MouseEvent): void => {
      const imageBox = imageBoxRef?.current?.getBoundingClientRect()
      if (isEnabled && imageBox) {
        const coords = computeCoordinates(imageBox, evt.clientX, evt.clientY)
        setStart(coords)
        setEnd(coords)
        setIsMouseDown(true)
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp, { once: true })
      }
    },
    [imageBoxRef, isEnabled, handleMouseMove, handleMouseUp],
  )

  return {
    start,
    end,
    boxDimension,
    isMouseDown,
    handleMouseDown,
  }
}

export default useCreateBox
