import { SoaInvoiceEDocsInfo } from '@src/components/batch-pull-table/BatchPullTable'
import {
  CargowiseFileTypeNode,
  Maybe,
  DocumentNode,
  InputSoaLineItem,
  FilePageNode,
  InputEDocument,
} from '@src/graphql/types'
import { JobTableLineItem, transformLineItems } from './line_items'

// use existing line items to create a skeleton for SOA invoice eDocs
export const getSoaInvoiceEDocsInfoSkeleton = (
  lineItems: JobTableLineItem[],
  document: Maybe<DocumentNode>,
): SoaInvoiceEDocsInfo[] => {
  const soaLineItems = transformLineItems(lineItems, document)
  const invoices = [
    ...new Set(
      soaLineItems.map((line: InputSoaLineItem): string => {
        return line.invoiceNumber || ''
      }),
    ),
  ].filter((exist) => exist)
  const getSoaInvoiceEDocsInfoSkeleton = invoices.map((invoice) => {
    return {
      invoiceNum: invoice,
      eDocs: [],
      checked: true,
    } as SoaInvoiceEDocsInfo
  })
  return getSoaInvoiceEDocsInfoSkeleton
}

export const formatCwFileType = (cwFileType: CargowiseFileTypeNode): string => {
  return `${cwFileType.code} - ${cwFileType.description}`
}

export const initializeInputEDocuments = (
  groupedFilePages: Record<string, FilePageNode[]>,
): InputEDocument[] => {
  const inputEDocuments = Object.values(groupedFilePages).map((groupedFilePages) => {
    const isPublished =
      groupedFilePages?.[0]?.document?.documentType?.isEDocPublishedByDefault ?? false
    return {
      filePageIds: groupedFilePages.map((filePage) => filePage.id),
      isPublished,
    }
  })
  return inputEDocuments
}
