import { FunctionComponent, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import { Slate, Editable, RenderElementProps, RenderLeafProps } from 'slate-react'

import { CustomSlateEditorHookType, SlateContent } from '@src/hooks/useSlateEditor'
import SlateElement from './SlateElement'
import SlateLeafElement from './SlateLeafElement'
import SlateToolbar from './SlateToolbar'

type Props = {
  slateEditor: CustomSlateEditorHookType
  readOnly?: boolean
  style?: Record<string, unknown>
}

const CustomSlate: FunctionComponent<Props> = ({ slateEditor, readOnly, style }) => {
  const { editor, slateValue, setSlateValue } = slateEditor

  const renderSlateElement = useCallback((props: RenderElementProps) => {
    return (
      <Box my={1}>
        <SlateElement {...props} />
      </Box>
    )
  }, [])

  const renderSlateLeafElement = useCallback((props: RenderLeafProps) => {
    return <SlateLeafElement {...props} />
  }, [])

  return (
    <Slate
      editor={editor}
      value={slateValue}
      onChange={(newValue) => {
        setSlateValue(newValue as SlateContent[])
      }}
    >
      {!readOnly && <SlateToolbar />}
      <Editable
        renderElement={renderSlateElement}
        renderLeaf={renderSlateLeafElement}
        readOnly={readOnly}
        style={style}
      />
    </Slate>
  )
}

export default CustomSlate
