import { FunctionComponent, useState } from 'react'
import Box from '@material-ui/core/Box'
import ButtonBase from '@material-ui/core/ButtonBase'
import { Theme } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import { makeStyles } from '@material-ui/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'

import theme from '@src/utils/theme'
import { clsx } from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@src/utils/store'
import { highlightLineItem, setIgnoreGridRow } from '@src/redux-features/document_editor'
import { rowSelectors, selectActiveMagicGrid } from '@src/redux-features/document_editor/magic_grid'

type StyleProps = {
  top: number
  left: number
  height: number
  ignore?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>({
  gridRowContainer: {
    position: 'absolute',
    top: (props) => props.top,
    left: (props) => props.left,
    width: '100%',
    height: (props) => props.height,
    display: 'flex',
    alignItems: 'center',
  },
  gridRowActionContainer: {
    position: 'absolute',
    right: '-40px',
    display: 'flex',
    alignItems: 'center',
    width: '40px',
    height: '100%',
  },
  gridRowAction: {
    width: '100%',
    height: '100%',
  },
  includeGridIcon: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.info.light, 0.3),
    '&:hover': {
      backgroundColor: alpha(theme.palette.info.light, 0.5),
    },
  },
  excludeGridIcon: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  gridRow: {
    position: 'absolute',
    backgroundColor: (props) => alpha(theme.palette.grey[100], props.ignore ? 0.5 : 0),
    width: '100%',
    height: '100%',
    '&:hover': {
      backgroundColor: alpha(theme.palette.info.light, 0.3),
    },
  },
  highlightedGridRow: {
    backgroundColor: `${alpha(theme.palette.success.main, 0.3)} !important`,
  },
})

type Props = {
  rowId: string
  imageWidth: number
  imageHeight: number
}

const GridRow: FunctionComponent<Props> = ({ rowId, imageWidth, imageHeight }) => {
  const dispatch = useDispatch()
  const row = useSelector(
    (state: RootState) => rowSelectors.selectById(state.documentEditor, rowId)!,
  )
  const { top: gridTop, left: gridLeft } = useSelector(
    (state: RootState) => selectActiveMagicGrid(state.documentEditor)!.dimension!,
  )
  const gridReadOnly = useSelector((state: RootState) => state.documentEditor.tableEditModeEnabled)
  const highlightedLineItemId = useSelector(
    (state: RootState) => state.documentEditor.highlightedLineItemId,
  )
  const top = (row.dimension.top - gridTop) * imageHeight
  const left = (row.dimension.left - gridLeft) * imageWidth
  const height = row.dimension.height * imageHeight
  const classes = useStyles({ top, left, height, ignore: row.ignore })
  const [showGridRowAction, setShowGridRowAction] = useState(false)
  const highlighted = row.lineItemId === highlightedLineItemId

  const handleGridAction = (ignore: boolean): void => {
    dispatch(setIgnoreGridRow(row.id, ignore))
    setShowGridRowAction(false)
  }

  return (
    <Box
      className={classes.gridRowContainer}
      onMouseEnter={() => {
        if (!gridReadOnly) {
          setShowGridRowAction(true)
        }
        dispatch(highlightLineItem(row?.lineItemId || null))
      }}
      onMouseLeave={() => {
        if (!gridReadOnly) {
          setShowGridRowAction(false)
        }
        dispatch(highlightLineItem(null))
      }}
    >
      <Box className={classes.gridRowActionContainer}>
        {showGridRowAction && !gridReadOnly && (
          <ButtonBase
            className={classes.gridRowAction}
            onClick={() => handleGridAction(!row.ignore)}
          >
            {row.ignore ? (
              <AddIcon className={classes.includeGridIcon} fontSize='small' />
            ) : (
              <ClearIcon className={classes.excludeGridIcon} fontSize='small' />
            )}
          </ButtonBase>
        )}
      </Box>
      <Box className={clsx(classes.gridRow, { [classes.highlightedGridRow]: highlighted })} />
    </Box>
  )
}

export default GridRow
