import { FunctionComponent, useContext, useRef, useState } from 'react'
import { Box, DialogContentText, IconButton, Theme, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

import FileUpload from '@src/components/upload-files/FileUpload'
import theme from '@src/utils/theme'
import { useSnackbar } from 'notistack'
import { uploadFileData } from '@src/utils/file'
import { CONFIRM_JOBS } from '@src/graphql/mutations/job'
import { useMutation } from '@apollo/client'
import { ConfirmJobsMutationVariables, Mutation } from '@src/graphql/types'
import { ImageFileData } from '@src/utils/types'
import { Auth0AccessTokenContext } from '@src/auth/Auth0AccessTokenContext'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { LogEventType } from '@src/utils/observability/LogEventType'

type Props = {
  isOpen: boolean
  jobIds: string[]
  closePopup: () => void
  onDoneSuccess: () => void
}

const useStyles = makeStyles<Theme>({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
})

const ProofDialog: FunctionComponent<Props> = ({ isOpen, jobIds, closePopup, onDoneSuccess }) => {
  const { logEvent } = useEventLogger()
  const actionStart = useRef<Date>()
  const [imageFileData, setImageFileData] = useState([] as ImageFileData[])
  const classes = useStyles()
  const accessToken = useContext(Auth0AccessTokenContext)!
  const [isUploading, setIsUploading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [confirmJobs] = useMutation<Pick<Mutation, 'confirmJobs'>, ConfirmJobsMutationVariables>(
    CONFIRM_JOBS,
    {
      update: (cache) => {
        cache.modify({
          fields: {
            // Invalidate countByJobStatus field and refreshes cache
            countByJobStatus() {},
          },
        })
      },
    },
  )

  const doneJobs = async (): Promise<void> => {
    actionStart.current = new Date()
    if (imageFileData.length > 1) {
      enqueueSnackbar('Please upload only a single image for proof that this export is complete', {
        variant: 'error',
      })
      return
    }
    setIsUploading(true)
    let errorMessage = ''
    let success = false
    try {
      let proofUrl = ''
      let filename = ''
      if (imageFileData.length > 0) {
        filename = imageFileData[0].filename
        const { progress$, viewUrls } = await uploadFileData(imageFileData, accessToken)
        await progress$.toPromise()
        // eslint-disable-next-line prefer-destructuring
        proofUrl = viewUrls[0]
      }
      await confirmJobs({
        variables: {
          jobIds,
          proofUrl,
          filename,
        },
      })
      onDoneSuccess()
      closePopup()
      enqueueSnackbar(`Jobs Confirmed`, { variant: 'success' })
      success = true
    } catch (err) {
      errorMessage =
        'Unable to confirm jobs. Please make sure you have uploaded a valid JPG or PNG file for proof.'
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      })
    } finally {
      setIsUploading(false)

      void logEvent(LogEventType.FILE_UPLOAD, {
        job_id: jobIds,
        action_start: actionStart.current,
        action_end: new Date(),
        file_types: imageFileData.map(({ type }) => type),
        success: success,
        error_message: errorMessage,
      })
    }
  }

  return (
    <Dialog open={isOpen} fullScreen>
      <DialogTitle disableTypography>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h3' className={classes.title}>
            Move to Done
          </Typography>
          <IconButton onClick={closePopup}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' height='100%'>
          <DialogContentText>
            Please don&rsquo;t forget to:
            <ol>
              <li>Email the client confirming that we&rsquo;ve done the job.</li>
              <li>CC the Ops team into your email!</li>
              <li>
                (Optional) Upload any screenshots (PNG or JPG) showing us confirming the job with
                the customer.
              </li>
            </ol>
          </DialogContentText>
          <Box flex={1} overflow='hidden'>
            <FileUpload imageFileData={imageFileData} setImageFileData={setImageFileData} />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closePopup}>Cancel</Button>
        <Button disabled={isUploading} onClick={doneJobs}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProofDialog
