import Title from '@src/components/Title'
import JobViewerWithSidebar from '@src/components/JobViewerWithSidebar'
import DoneSidebar from '@src/components/done-sidebar/DoneSidebar'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'

const DonePage: FunctionComponent = () => {
  const { id: jobId } = useParams<{ id: string }>()
  return (
    <>
      <Title title='Done Job' />
      <JobViewerWithSidebar jobId={jobId}>
        <DoneSidebar jobId={jobId} />
      </JobViewerWithSidebar>
    </>
  )
}

export default DonePage
