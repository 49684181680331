import { FunctionComponent, useState } from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import theme from '@src/utils/theme'
import RoundedButton from '@src/components/RoundedButton'

type Props = {
  disabled: boolean
  save: () => Promise<void>
  del: () => void
}

const useStyles = makeStyles<Theme>({
  wrapper: {
    textAlign: 'right',
    paddingRight: theme.spacing(2),
  },
  deleteButton: {
    color: theme.palette.error.main,
    background: theme.palette.error.contrastText,
  },
  copyButton: {
    background: 'white',
    color: theme.palette.primary.main,
  },
})

const ActionButtons: FunctionComponent<Props> = ({ disabled, del, save }) => {
  const classes = useStyles()
  const [saving, setSaving] = useState(false)
  const disableAndSave = async (): Promise<void> => {
    setSaving(true)
    await save()
    setSaving(false)
  }
  return (
    <div className={classes.wrapper} data-testid='action-buttons'>
      <RoundedButton
        disabled={disabled || saving}
        variant='contained'
        onClick={del}
        className={classes.deleteButton}
      >
        Delete
      </RoundedButton>
      <RoundedButton
        disabled={disabled || saving}
        variant='contained'
        onClick={disableAndSave}
        color='primary'
      >
        Save
      </RoundedButton>
    </div>
  )
}

export default ActionButtons
