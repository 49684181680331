import { gql } from '@apollo/client'

export const REDO_RECONCILE_UNMATCHED_ATTEMPTS = gql`
  mutation redoReconcileUnmatchedReconAttempts($companyIds: [UUID!]) {
    redoReconcileUnmatchedReconAttempts(companyIds: $companyIds)
  }
`

export const SAVE_AP_RECON_ATTEMPT = gql`
  mutation saveReconAttempt($reconAttemptId: String!) {
    saveReconAttempt(reconAttemptId: $reconAttemptId) {
      ok
    }
  }
`

export const SAVE_RECON_ATTEMPTS_BY_BATCH = gql`
  mutation saveReconAttemptsByBatch($reconAsyncBatchId: String!) {
    saveReconAttemptsByBatch(reconAsyncBatchId: $reconAsyncBatchId) {
      ok
    }
  }
`

export const SAVE_AN_RECON_ATTEMPT = SAVE_AP_RECON_ATTEMPT

export const RECONCILE_AP_INVOICE_JOB = gql`
  mutation reconcileApInvoiceJob(
    $jobId: String!
    $lumpsum: InputDocumentTable
    $overrideChargeDescription: Boolean!
    $disableSendDueDate: Boolean!
    $matchingCriteria: MatchingCriteriaType!
  ) {
    reconcileApInvoiceJob(
      jobId: $jobId
      lumpsum: $lumpsum
      overrideChargeDescription: $overrideChargeDescription
      disableSendDueDate: $disableSendDueDate
      matchingCriteria: $matchingCriteria
    ) {
      docCharges {
        id
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
      }
      expectedCharges {
        id
        chainIoSiLineId
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
        isPosted
        overseasSellAmount
        sellCurrency
      }
      reconAttempt {
        id
        job {
          id
          jobTemplate {
            id
            apiPartnerId
            companyId
          }
        }
        reconDetail {
          vendor
          tmsId
          shipmentNo
          invoiceNo
          hblNo
          mblNo
          referenceNo
          containerNo
          carrierBookingNo
          consolNo
          orderNo
          dueDate
        }
        matchingCriteria {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on MatchingCriteriaTypeEnum {
            value
            isFallback
          }
        }
        overrideChargeDescription
        cargowiseModule {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on CwTargetModuleEnum {
            value
            isFallback
          }
        }
      }
      reconResults {
        id
        success
        chainIoShipment {
          id
          dateModified
          tmsId
          houseBill
          masterBill
          carrierBookingNumber
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
        }
        chainIoConsolidation {
          id
          dateModified
        }
        chainIoCustomsDeclaration {
          id
          dateModified
        }
        ... on FindShipmentReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          referenceNo
          hblNo
          mblNo
          carrierBookingNo
          containerNo
          orderNo
          consolNo
          isPrimary
          isMaximalAmongDuplicates
          chainIoShipment {
            id
            forwarderReference
            tmsId
            houseBill
            masterBill
            carrierBookingNumber
            bookingReference
            containers {
              edges {
                node {
                  id
                  containerNumber
                }
              }
            }
            chainIoConsolidations {
              edges {
                node {
                  id
                  forwarderReference
                }
              }
            }
            orders {
              edges {
                node {
                  id
                  orderReference
                }
              }
            }
          }
          chainIoConsolidation {
            id
            forwarderReference
            tmsId
            masterBill
            carrierBookingRef
            containers {
              edges {
                node {
                  id
                  containerNumber
                }
              }
            }
            shipments {
              edges {
                node {
                  id
                  forwarderReference
                  houseBill
                  orders {
                    edges {
                      node {
                        id
                        orderReference
                      }
                    }
                  }
                }
              }
            }
          }
          chainIoCustomsDeclaration {
            id
            forwarderReference
            tmsId
            houseBill
            masterBill
            bookingReference
            containers {
              edges {
                node {
                  id
                  containerNumber
                }
              }
            }
            chainIoShipment {
              id
              carrierBookingNumber
              forwarderReference
              chainIoConsolidations {
                edges {
                  node {
                    id
                    forwarderReference
                  }
                }
              }
            }
            orders {
              edges {
                node {
                  id
                  orderReference
                }
              }
            }
          }
          findShipmentDuplicateReconResults {
            edges {
              node {
                id
                chainIoShipment {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                chainIoConsolidation {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                chainIoCustomsDeclaration {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
              }
            }
          }
        }
        ... on FindInvoiceReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          invoiceReconMatches {
            edges {
              node {
                id
                chainIoSi {
                  id
                  invoiceNumber
                }
              }
            }
          }
        }
        ... on InvoiceLineItemReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          invoiceAmount
          invoiceTaxAmount
          expectedAmount
          expectedTaxAmount
          invoiceChargeCode {
            id
            code
            chargeVendor {
              id
              name
            }
          }
          invoiceChargeCodeCode
          expectedChargeCode {
            id
            code
            chargeVendor {
              id
              name
            }
          }
          invoiceNumber
          invoiceTaxId
          expectedTaxId
          invoiceChargeVendor {
            id
            code
          }
          invoiceDate
          dueDate
          invoiceCurrency
          invoiceUnitPrice
          invoiceQuantity
          chainIoSiLine {
            id
          }
          chainIoConsolCostId
          chainIoCustomsDeclarationCostId
          duplicateChainIoSiLines {
            edges {
              node {
                id
                chainIoSiLine {
                  id
                }
              }
            }
          }
          isChargeCodeSame
          isTotalCostEqual
          isCurrencySameOrEmpty
          isChargeVendorSame
          isInvoiceNumberCorrect
        }
        ... on InvoiceTotalReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          invoiceTotalAmount: invoiceAmount
          invoiceTaxedTotalAmount: invoiceTaxedAmount
          expectedTotalAmount: expectedAmount
          expectedTaxedTotalAmount: expectedTaxedAmount
          usedThresholdMatching
          isWithinThreshold
          reconThresholdRange {
            id
            thresholdAmount
          }
        }
        ... on ShipmentStaffReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          operationsStaffName
          operationsStaffCode
          salesStaffName
          salesStaffCode
        }
        ... on MetadataReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          key {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on MetadataReconResultKeyEnum {
              value
              isFallback
            }
          }
          invoiceValue
          expectedValue
        }
      }
    }
  }
`

export const RECONCILE_ARRIVAL_NOTICE_JOB = gql`
  mutation reconcileArrivalNoticeJob(
    $jobId: String!
    $lumpsum: InputDocumentTable
    $premiumRateDetails: InputReconPremiumRate
  ) {
    reconcileArrivalNoticeJob(
      jobId: $jobId
      lumpsum: $lumpsum
      premiumRateDetails: $premiumRateDetails
    ) {
      reconAttempt {
        id
        cargowiseModule {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on CwTargetModuleEnum {
            value
            isFallback
          }
        }
      }
      reconResults {
        success
        chainIoConsolidation {
          id
          forwarderReference
          dateModified
        }
        chainIoShipment {
          id
          dateModified
        }
        id
        ... on FindConsolReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          matchedKey
          keyNo
          documentConsolNo
          documentMbl
          documentContainerNumbers
        }
        ... on ArrivalNoticeConsolTypeReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          documentCreditorCode
          expectedCreditorCode
          documentCarrierCode
          expectedCarrierCode
          documentConsolType
          documentContainerType
          expectedConsolType
          expectedContainerType
        }
        ... on ArrivalNoticeMetadataReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          metadataKey
          documentValue
          expectedValue
        }
        ... on ArrivalNoticeContainerReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          documentContainerNo
          expectedContainerNo
          documentContainerTypeCode
          expectedContainerTypeCode
          documentCommodity
        }
        ... on ArrivalNoticeContractReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          contractId
          effectivity
          expiryDate
          documentAcceptedDate
          documentVendorCode
          clientContractRefNo
        }
        ... on ArrivalNoticeChargeRateReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          documentChargeCode
          expectedChargeCode
          chargeCodeMatchesFound
          documentChargeCost
          expectedChargeCost
          documentQuantity
          documentTotalAmount
          expectedTotalAmount
          documentCurrency
          expectedCurrency
          currencyMatchesFound
          documentContainerSize
          expectedContainerSize
          containerSizeMatchesFound
          documentContainerType
          expectedContainerType
          containerTypeMatchesFound
          documentCommodity
          expectedCommodity
          commodityMatchesFound
          documentPlaceOfReceipt
          expectedPlaceOfReceipt
          placeOfReceiptMatchesFound
          documentPlaceOfDelivery
          expectedPlaceOfDelivery
          placeOfDeliveryMatchesFound
          documentPortOfLoading
          expectedPortOfLoading
          portOfLoadingMatchesFound
          documentPortOfDischarge
          expectedPortOfDischarge
          portOfDischargeMatchesFound
          documentNamedAccount
          expectedNamedAccount
          namedAccountMatchesFound
          documentShipmentRoute
          expectedShipmentRoute
          shipmentRouteMatchesFound
          amendmentNo
          portOfLoadingSimilarRateLocations {
            id
            rateLocation
          }
          portOfDischargeSimilarRateLocations {
            id
            rateLocation
          }
          placeOfReceiptSimilarRateLocations {
            id
            rateLocation
          }
          placeOfDeliverySimilarRateLocations {
            id
            rateLocation
          }
        }
        ... on ArrivalNoticeLumpsumReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          documentLumpsum
          expectedLumpsum
          premiumRate
          premiumRateContainerQty
          premiumTotalAccessorialFees
        }
        ... on ReconResultFallbackNode {
          id
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
        }
      }
    }
  }
`

export const UPDATE_CHARGE_QUANTITY = gql`
  mutation updateChargeQuantity($jobId: String!) {
    updateChargeQuantity(jobId: $jobId) {
      ok
    }
  }
`

export const FETCH_CHARGE_DETAILS_FROM_CW = gql`
  mutation fetchChargeDetailsFromCw($jobId: UUID!) {
    fetchChargeDetailsFromCw(jobId: $jobId) {
      findShipmentReconResults {
        id
        success
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        referenceNo
        hblNo
        mblNo
        carrierBookingNo
        containerNo
        orderNo
        consolNo
        isMaximalAmongDuplicates
        chainIoShipment {
          id
          forwarderReference
          dateModified
          houseBill
          masterBill
          carrierBookingNumber
          bookingReference
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          orders {
            edges {
              node {
                id
                orderReference
                sequence
                orderNumberSplit
                orderStatus
                orderDate
                orderBuyer {
                  id
                  sourcePartyId
                }
                orderSupplier {
                  id
                  sourcePartyId
                }
                orderLines {
                  edges {
                    node {
                      id
                      orderLineNumber
                      partNumber
                      description
                      containerNumber
                    }
                  }
                }
              }
            }
          }
          chainIoConsolidations {
            edges {
              node {
                id
                forwarderReference
              }
            }
          }
          additionalReferences {
            edges {
              node {
                id
                referenceType
                referenceNumber
              }
            }
          }
          customFields {
            edges {
              node {
                id
                key
                value
              }
            }
          }
          shipmentLegs {
            edges {
              node {
                id
                legSequenceNumber
                transportMode
                legType
                vesselName
                voyageNumber
                ladingPort {
                  id
                  unlocode
                }
                arrivalPort {
                  id
                  unlocode
                }
                departureEstimated
                arrivalEstimated
                legCarrier {
                  id
                  name
                }
                bookingStatus
              }
            }
          }
          packingLines {
            edges {
              node {
                id
                packs
                packsUom
                containerNumber
                weight
                weightUom
                volume
                volumeUom
                referenceNumber
                importReferenceNumber
                exportReferenceNumber
                descriptionOfGoods
                marksAndNumbers
                commodityCode
              }
            }
          }
          localClient {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          shipper {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          consignee {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          supplier {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          importer {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          pickupTransportCompany {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          deliveryTransportCompany {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          pickupFrom {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          deliveryTo {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          controllingAgent {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          controllingCustomer {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          importBroker {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
        }
        chainIoConsolidation {
          id
          forwarderReference
          dateModified
          masterBill
          carrierBookingRef
          consolCosts {
            edges {
              node {
                id
                chargeCode
                chargeDescription
                amount
                currency
                creditorCode
                invoiceNumber
                taxRateCode
                taxRate
                isPosted
              }
            }
          }
          containers {
            edges {
              node {
                id
                containerNumber
                containerCount
                seal1
                containerMode
                typeCode
                deliveryMode
                sealedBy
                wharfGateIn
              }
            }
          }
          shipmentLegs {
            edges {
              node {
                id
                legSequenceNumber
                transportMode
                legType
                vesselName
                voyageNumber
                ladingPort {
                  id
                  unlocode
                }
                arrivalPort {
                  id
                  unlocode
                }
                departureEstimated
                arrivalEstimated
                legCarrier {
                  id
                  name
                }
                bookingStatus
              }
            }
          }
          shipments {
            edges {
              node {
                id
                forwarderReference
                houseBill
                orders {
                  edges {
                    node {
                      id
                      orderReference
                    }
                  }
                }
              }
            }
          }
          carrier {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          creditor {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          coloadWith {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          receivingAgent {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          sendingAgent {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
        }
        chainIoCustomsDeclaration {
          id
          forwarderReference
          dateModified
          houseBill
          masterBill
          bookingReference
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          chainIoShipment {
            id
            carrierBookingNumber
            forwarderReference
            chainIoConsolidations {
              edges {
                node {
                  id
                  forwarderReference
                }
              }
            }
          }
          orders {
            edges {
              node {
                id
                orderReference
              }
            }
          }
        }
        findShipmentDuplicateReconResults {
          edges {
            node {
              id
              chainIoShipment {
                id
                forwarderReference
                houseBill
                masterBill
                isCancelled
                tmsId
                bookingReference
                containers {
                  edges {
                    node {
                      id
                      containerNumber
                    }
                  }
                }
                chainIoConsolidation {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                orders {
                  edges {
                    node {
                      id
                      orderReference
                    }
                  }
                }
              }
              chainIoConsolidation {
                id
                forwarderReference
                isCancelled
                tmsId
              }
              chainIoCustomsDeclaration {
                id
                forwarderReference
                tmsId
                isCancelled
              }
            }
          }
        }
      }
      expectedCharges {
        id
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
        isPosted
        overseasSellAmount
        sellCurrency
      }
      shipmentStaffReconResults {
        id
        operationsStaffName
        operationsStaffCode
        salesStaffName
        salesStaffCode
      }
      metadataReconResults {
        id
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        key {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on MetadataReconResultKeyEnum {
            value
            isFallback
          }
        }
        invoiceValue
        expectedValue
      }
    }
  }
`

export const BATCH_FETCH_SHIPMENT_INFO_FROM_CW = gql`
  mutation batchFetchShipmentInfoFromCw($jobId: UUID!) {
    batchFetchShipmentInfoFromCw(jobId: $jobId) {
      checkShipmentInfoAsyncBatchId
    }
  }
`

export const DELETE_CHARGE_VENDOR = gql`
  mutation deleteChargeVendor($chargeVendorId: UUID!) {
    deleteChargeVendor(chargeVendorId: $chargeVendorId) {
      ok
    }
  }
`

export const DELETE_CHARGE_CODES = gql`
  mutation deleteChargeCodes($chargeCodeIds: [UUID]!) {
    deleteChargeCodes(chargeCodeIds: $chargeCodeIds) {
      ok
    }
  }
`

export const DELETE_CHARGE_CODES_AND_OVERRIDES = gql`
  mutation deleteChargeCodesAndOverrides($chargeCodeIds: [UUID!]!) {
    deleteChargeCodesAndOverrides(chargeCodeIds: $chargeCodeIds) {
      ok
    }
  }
`

export const UPDATE_CHARGE_VENDOR_DETAILS = gql`
  mutation editChargeVendor(
    $chargeVendorId: UUID!
    $name: String!
    $code: String!
    $type: String!
    $chargeCodes: [InputChargeCode!]!
    $apiPartnerId: UUID
    $codesLimit: Int
    $codesOffset: Int
  ) {
    editChargeVendor(
      chargeVendorId: $chargeVendorId
      name: $name
      code: $code
      type: $type
      chargeCodes: $chargeCodes
      apiPartnerId: $apiPartnerId
      codesLimit: $codesLimit
      codesOffset: $codesOffset
    ) {
      id
    }
  }
`

export const UPDATE_CHARGE_VENDOR_AND_OVERRIDES = gql`
  mutation editChargeVendorAndOverrides(
    $chargeVendorId: UUID!
    $name: String!
    $code: String!
    $type: String!
    $chargeCodeOverrides: [InputChargeCode!]!
    $apiPartnerId: UUID
    $codesLimit: Int
    $codesOffset: Int
  ) {
    editChargeVendorAndOverrides(
      chargeVendorId: $chargeVendorId
      name: $name
      code: $code
      type: $type
      chargeCodeOverrides: $chargeCodeOverrides
      apiPartnerId: $apiPartnerId
      codesLimit: $codesLimit
      codesOffset: $codesOffset
    ) {
      id
    }
  }
`

export const UPDATE_CHARGE_CODE_DETAILS = gql`
  mutation editChargeCodes(
    $chargeCodeId: UUID!
    $apiPartnerId: UUID
    $code: String!
    $taxId: UUID
    $vendorsAndDescriptions: [InputVendorAndDesc!]!
  ) {
    editChargeCodes(
      chargeCodeId: $chargeCodeId
      apiPartnerId: $apiPartnerId
      code: $code
      taxId: $taxId
      vendorsAndDescriptions: $vendorsAndDescriptions
    ) {
      id
    }
  }
`

export const UPDATE_CHARGE_CODE_DETAILS_AND_OVERRIDES = gql`
  mutation editChargeCodeAndOverrides(
    $chargeCodeId: UUID!
    $code: String!
    $description: String!
    $vendorsAndDescriptions: [InputVendorAndDesc!]!
    $taxId: UUID
  ) {
    editChargeCodeAndOverrides(
      chargeCodeId: $chargeCodeId
      code: $code
      description: $description
      vendorsAndDescriptions: $vendorsAndDescriptions
      taxId: $taxId
    ) {
      id
    }
  }
`

export const CREATE_CHARGE_VENDOR_WITH_CHARGE_CODES = gql`
  mutation createChargeVendorWithChargeCodes(
    $companyId: UUID!
    $name: String!
    $code: String!
    $type: String!
    $chargeCodes: [InputChargeCode!]!
    $apiPartnerId: UUID
  ) {
    createChargeVendorWithChargeCodes(
      companyId: $companyId
      name: $name
      code: $code
      type: $type
      chargeCodes: $chargeCodes
      apiPartnerId: $apiPartnerId
    ) {
      chargeVendor {
        id
      }
    }
  }
`

export const CREATE_CHARGE_VENDOR_AND_OVERRIDES = gql`
  mutation createChargeVendorAndOverrides(
    $companyId: UUID!
    $name: String!
    $code: String!
    $type: String!
    $chargeCodeOverrides: [InputChargeCode!]!
    $apiPartnerId: UUID!
  ) {
    createChargeVendorAndOverrides(
      companyId: $companyId
      name: $name
      code: $code
      type: $type
      chargeCodeOverrides: $chargeCodeOverrides
      apiPartnerId: $apiPartnerId
    ) {
      chargeVendor {
        id
      }
    }
  }
`

export const UPSERT_SEPERATED_CHARGE_VENDORS_CHARGE_CODES = gql`
  mutation upsertSeperatedChargeVendorsChargeCodes(
    $companyId: UUID!
    $apiPartnerIds: [UUID!]!
    $chargeVendorsSignedUrl: String!
    $chargeCodesSignedUrl: String!
  ) {
    upsertSeperatedChargeVendorsChargeCodes(
      companyId: $companyId
      apiPartnerIds: $apiPartnerIds
      chargeVendorsSignedUrl: $chargeVendorsSignedUrl
      chargeCodesSignedUrl: $chargeCodesSignedUrl
    ) {
      chargeCodeUploadAsyncTaskId
    }
  }
`

export const UPLOAD_VENDOR_CODE_FILE_URL = gql`
  query getVendorChargeCodeSignedUrl($filename: String!) {
    vendorChargeCodeUrl(filename: $filename) {
      putUrl
      viewUrl
    }
  }
`

export const CREATE_CHARGE_CODES = gql`
  mutation createChargeCodes(
    $companyId: UUID!
    $apiPartnerId: UUID
    $code: String!
    $taxId: UUID
    $vendorsAndDescriptions: [InputVendorAndDesc!]!
  ) {
    createChargeCodes(
      companyId: $companyId
      apiPartnerId: $apiPartnerId
      code: $code
      taxId: $taxId
      vendorsAndDescriptions: $vendorsAndDescriptions
    ) {
      ok
    }
  }
`

export const CREATE_CHARGE_CODE_AND_OVERRIDES = gql`
  mutation createChargeCodeAndOverrides(
    $companyId: UUID!
    $apiPartnerId: UUID
    $code: String!
    $description: String!
    $vendorsAndDescriptions: [InputVendorAndDesc!]!
    $taxId: UUID
  ) {
    createChargeCodeAndOverrides(
      companyId: $companyId
      apiPartnerId: $apiPartnerId
      code: $code
      description: $description
      vendorsAndDescriptions: $vendorsAndDescriptions
      taxId: $taxId
    ) {
      ok
    }
  }
`

export const CREATE_TAX = gql`
  mutation createTax($taxDetails: InputTaxDetails!) {
    createTax(taxDetails: $taxDetails) {
      ok
    }
  }
`

export const UPDATE_TAX = gql`
  mutation editTax($taxId: UUID!, $taxDetails: InputTaxDetails!) {
    editTax(taxId: $taxId, taxDetails: $taxDetails) {
      id
    }
  }
`

export const DELETE_TAX = gql`
  mutation deleteTax($taxId: UUID!) {
    deleteTax(taxId: $taxId) {
      ok
    }
  }
`

export const FETCH_CHARGE_DETAILS_FROM_TMS = gql`
  mutation fetchChargeDetailsFromTms($jobId: UUID!) {
    fetchChargeDetailsFromTms(jobId: $jobId) {
      findShipmentReconResults {
        id
        success
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        referenceNo
        hblNo
        mblNo
        carrierBookingNo
        containerNo
        orderNo
        consolNo
        chainIoShipment {
          id
          forwarderReference
          dateModified
          houseBill
          masterBill
          carrierBookingNumber
          bookingReference
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          orders {
            edges {
              node {
                id
                orderReference
                sequence
              }
            }
          }
          chainIoConsolidations {
            edges {
              node {
                id
                forwarderReference
              }
            }
          }
          additionalReferences {
            edges {
              node {
                id
                referenceType
                referenceNumber
              }
            }
          }
          customFields {
            edges {
              node {
                id
                key
                value
              }
            }
          }
          shipmentLegs {
            edges {
              node {
                id
                legSequenceNumber
                transportMode
                legType
                vesselName
                voyageNumber
                ladingPort {
                  id
                  unlocode
                }
                arrivalPort {
                  id
                  unlocode
                }
                departureEstimated
                arrivalEstimated
                legCarrier {
                  id
                  name
                }
                bookingStatus
              }
            }
          }
          packingLines {
            edges {
              node {
                id
                packs
                packsUom
                containerNumber
                weight
                weightUom
                volume
                volumeUom
                referenceNumber
                importReferenceNumber
                exportReferenceNumber
                descriptionOfGoods
                marksAndNumbers
              }
            }
          }
          localClient {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          shipper {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          consignee {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          supplier {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          importer {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          pickupTransportCompany {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          deliveryTransportCompany {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          pickupFrom {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          deliveryTo {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
        }
        chainIoConsolidation {
          id
          forwarderReference
          dateModified
          masterBill
          carrierBookingRef
          consolCosts {
            edges {
              node {
                id
                chargeCode
                chargeDescription
                amount
                currency
                creditorCode
                invoiceNumber
                taxRateCode
                taxRate
                isPosted
              }
            }
          }
          containers {
            edges {
              node {
                id
                containerNumber
                containerCount
                seal1
                containerMode
                typeCode
                deliveryMode
                sealedBy
              }
            }
          }
          shipmentLegs {
            edges {
              node {
                id
                legSequenceNumber
                transportMode
                legType
                vesselName
                voyageNumber
                ladingPort {
                  id
                  unlocode
                }
                arrivalPort {
                  id
                  unlocode
                }
                departureEstimated
                arrivalEstimated
                legCarrier {
                  id
                  name
                }
                bookingStatus
              }
            }
          }
          shipments {
            edges {
              node {
                id
                forwarderReference
                houseBill
                orders {
                  edges {
                    node {
                      id
                      orderReference
                    }
                  }
                }
              }
            }
          }
          carrier {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          creditor {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          coloadWith {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          receivingAgent {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
          sendingAgent {
            id
            name
            sourcePartyId
            unlocode
            address1
            address2
            city
            country
            state
          }
        }
        chainIoCustomsDeclaration {
          id
          forwarderReference
          dateModified
          houseBill
          masterBill
          bookingReference
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          chainIoShipment {
            id
            carrierBookingNumber
            forwarderReference
            chainIoConsolidations {
              edges {
                node {
                  id
                  forwarderReference
                }
              }
            }
          }
          orders {
            edges {
              node {
                id
                orderReference
              }
            }
          }
        }
        findShipmentDuplicateReconResults {
          edges {
            node {
              id
              chainIoShipment {
                id
                forwarderReference
                houseBill
                isCancelled
                tmsId
                masterBill
                bookingReference
                containers {
                  edges {
                    node {
                      id
                      containerNumber
                    }
                  }
                }
                chainIoConsolidation {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                orders {
                  edges {
                    node {
                      id
                      orderReference
                    }
                  }
                }
              }
              chainIoConsolidation {
                id
                isCancelled
                tmsId
                forwarderReference
              }
              chainIoCustomsDeclaration {
                id
                isCancelled
                tmsId
                forwarderReference
              }
            }
          }
        }
      }
      expectedCharges {
        id
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
        isPosted
        overseasSellAmount
        sellCurrency
      }
      shipmentStaffReconResults {
        id
        operationsStaffName
        operationsStaffCode
        salesStaffName
        salesStaffCode
      }
      metadataReconResults {
        id
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        key {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on MetadataReconResultKeyEnum {
            value
            isFallback
          }
        }
        invoiceValue
        expectedValue
      }
    }
  }
`

export const UPDATE_RISR_AND_RMC = gql`
  mutation updateRisrAndRmc($inputReconInfoObjects: [InputReconInfoObject!], $jobId: UUID!) {
    updateRisrAndRmc(inputReconInfoObjects: $inputReconInfoObjects, jobId: $jobId)
  }
`
