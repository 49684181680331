import { FunctionComponent } from 'react'
import { Box, Link, makeStyles, Paper, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Link as RouterLink } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { JobTemplateNode } from '@src/graphql/types'
import theme from '@src/utils/theme'

const useStyles = makeStyles({
  companyNameWrapper: {
    height: theme.spacing(5),
    backgroundColor: grey[600],
    color: theme.palette.common.white,
    width: '100%',
    '& a': {
      color: theme.palette.common.white,
    },
  },
  companyName: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    flexGrow: 1,
  },
  icon: {
    '&:hover': {
      cursor: 'pointer',
    },
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  card: {
    textAlign: 'left',
    userSelect: 'none',
    margin: `0 0 ${theme.spacing(1)}px 0`,
    width: 500,
    minHeight: 200,
  },
  innerJobName: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: grey[300],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

type Props = {
  jobTemplate: JobTemplateNode
  companyId: string
  setJobTemplateToDelete: (jobTemplate: JobTemplateNode) => void
}

const JobDocTypesCard: FunctionComponent<Props> = ({
  jobTemplate,
  companyId,
  setJobTemplateToDelete,
}: Props) => {
  const classes = useStyles()

  return (
    <Paper elevation={3} className={classes.card}>
      <div className={classes.companyNameWrapper}>
        <Box display='inline-flex' width='100%'>
          <Typography className={classes.companyName}> {jobTemplate.name} </Typography>
          <Link
            component={RouterLink}
            to={`/admin/company/${companyId}/job-type/${jobTemplate.id}`}
            data-testid={`edit-job-type-${jobTemplate.name}`}
          >
            <EditIcon className={classes.icon} />
          </Link>
          <DeleteIcon
            className={classes.icon}
            onClick={() => setJobTemplateToDelete(jobTemplate)}
            data-testid={`delete-job-type-${jobTemplate.name}`}
          />
        </Box>
      </div>
      <Box display='inline-flex' flexWrap='wrap'>
        {jobTemplate.documentTypes!.edges.map((docType) => (
          <Typography
            title={docType!.node!.name}
            key={docType!.node!.id}
            className={classes.innerJobName}
          >
            {docType!.node!.name}
          </Typography>
        ))}
      </Box>
    </Paper>
  )
}

export default JobDocTypesCard
