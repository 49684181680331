/* eslint-disable @typescript-eslint/no-explicit-any */
import { addTableColumn, removeTableColumn } from '@src/redux-features/document_editor'
import { Dispatch } from 'react'
import { ApReconPrimaryAutofillKey, FieldNode } from '@src/graphql/types'
import { cleanStringForMatching } from '@src/utils/clean_strings'
import { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack'

const createContextMenu = (
  insertIdx: number,
  repeatableFields: FieldNode[],
  columns: string[],
  tableShowsPreset: boolean,
  preventDeleteAllColumns: boolean,
  isSoa: boolean,
  dispatch: Dispatch<any>,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  // Handsontable doesnt seem to have nice typing, things can take a variety of forms for the context menu
): Record<any, any> => {
  // requires a colon seperator, so we append a
  // character that shouldn't be in any column because we have to trigger by key
  const colSeperator = ':🐼'
  const showColItems = [] as Record<any, any>[]
  const hideColItems = [] as Record<any, any>
  const requiredColumns = repeatableFields
    .filter((field) => field.required)
    .map((field) => field.key)

  const cleanedAutofillPrimarySearchKeys = Object.values(ApReconPrimaryAutofillKey).map(
    (autofillKey) => cleanStringForMatching(autofillKey, true),
  )
  const searchKeyColumns = repeatableFields.filter((field) =>
    cleanedAutofillPrimarySearchKeys.includes(cleanStringForMatching(field.autofillKey, true)),
  )
  const searchKeyColumnHeaders = searchKeyColumns.map((field) => field.name)
  const searchKeyColumnKeys = searchKeyColumns.map((field) => field.key)
  const shownSearchKeyColumns = searchKeyColumnKeys.filter((colKey) => columns.includes(colKey))

  repeatableFields.forEach((repeatableField) => {
    if (!columns.includes(repeatableField.key)) {
      const item = {} as Record<any, any>
      item.key = `add_column${colSeperator}${repeatableField.name}` as string
      item.name = repeatableField.name as string
      item.callback = (_key: string): void => {
        dispatch(addTableColumn({ index: insertIdx, columns, colKey: repeatableField.key }))
      }
      showColItems.push(item)
    } else {
      const item = {} as Record<any, any>
      item.key = `remove_column${colSeperator}${repeatableField.name}`
      item.name = repeatableField.name
      item.callback = (_key: string) => {
        if (
          isSoa &&
          searchKeyColumnKeys.includes(repeatableField.key) &&
          shownSearchKeyColumns.filter((searchKey) => searchKey !== repeatableField.key).length ===
            0
        ) {
          enqueueSnackbar(
            `Cannot delete this column. At least one of the following columns must be present: ${searchKeyColumnHeaders.join(
              ', ',
            )}`,
            { variant: 'error' },
          )
          return
        }
        dispatch(removeTableColumn({ columns, colKey: repeatableField.key }))
      }
      item.disabled = requiredColumns.includes(repeatableField.key)
      hideColItems.push(item)
    }
  })

  // for file pages like excel, prevent the user from deleting all columns
  // because there needs to be line items and columns to be able to copy
  if (hideColItems.length === 1 && preventDeleteAllColumns) {
    hideColItems[0].disabled = true
  }
  const contextMenu = {
    items: {
      add_column: {
        name: 'Add Column',
        submenu: {
          items: showColItems.length === 0 ? [{ name: 'N/A', disabled: true }] : showColItems,
        },
        disabled: tableShowsPreset,
      },
      remove_column: {
        name: 'Remove Column',
        submenu: {
          items: hideColItems.length === 0 ? [{ name: 'N/A', disabled: true }] : hideColItems,
        },
        disabled: tableShowsPreset,
      },
      row_above: {},
      row_below: {},
      copy: {},
      cut: {},
      alignment: {},
      undo: {},
      redo: {},
      remove_row: {},
    },
  }

  return contextMenu
}
export default createContextMenu
