import { FunctionComponent, useRef, useState } from 'react'
import {
  ChargeVendorWithOverridesSearchResult,
  CompanySearchResult,
  SelectedChargeVendor,
  SelectedChargeVendorWithOverrides,
  SelectedCompany,
} from './types'
import { useSnackbar } from 'notistack'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core'
import ModalCloseButton from '../ModalCloseButton'
import { Autocomplete } from '@material-ui/lab'
import {
  CompanyNode,
  Maybe,
  useChargeVendorsWithCodeOverridesQuery,
  useSearchedCompaniesQuery,
} from '@src/graphql/types'
import { formatMaybeApolloError } from '@src/utils/errors'
import { HotTable } from '@handsontable/react'
import { HANDSONTABLE_IN_MODAL_HEIGHT } from '@src/utils/app_constants'
import { SpreadsheetDataColumn } from '@src/utils/data-grid'
import { styled } from '@material-ui/styles'

type Props = {
  company: Maybe<CompanyNode>
  vendor: SelectedChargeVendor | undefined
  setOpen: (open: boolean) => void
  addNewVendorChargeCodes: (chargeCodeRows: string[][]) => void
  apiPartnerId: string | null
}

const columns = [
  {
    key: 'description',
    name: 'Description',
  },
  {
    key: 'charge_code',
    name: 'Charge Code',
  },
]

const vendorDisplayModalLimit = 5

const dialogClass = 'CopyVendorOverridesModal-dialog'
const StyledDialog = styled(Dialog)(() => ({
  [`&.${dialogClass}`]: {
    // don't block handsontable copy menu
    // important because zindex 1300 is inline
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zIndex: '1000 !important',
  },
}))

const CopyVendorOverridesModal: FunctionComponent<Props> = ({
  company,
  vendor,
  setOpen,
  addNewVendorChargeCodes,
  apiPartnerId,
}: Props) => {
  const [companySearchValue, setCompanySearchValue] = useState<string>()
  const [selectedCompany, setSelectedCompany] = useState<SelectedCompany | Maybe<CompanyNode>>(
    company,
  )
  const [companySearchResults, setCompanySearchResults] = useState<CompanySearchResult>()

  const [vendorSearchValue, setVendorSearchValue] = useState<string>()
  const [selectedVendor, setSelectedVendor] = useState<SelectedChargeVendorWithOverrides>()
  const [chargeVendorSearchResults, setChargeVendorSearchResults] =
    useState<ChargeVendorWithOverridesSearchResult>()

  const [rows, setRows] = useState<string[][]>()

  const hotTableRef = useRef<HotTable | null>(null)
  const { enqueueSnackbar } = useSnackbar()

  useSearchedCompaniesQuery({
    variables: {
      query: companySearchValue ?? '',
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.searchedForCompanies) {
        setCompanySearchResults(data.searchedForCompanies)
      }
    },
    onError: (error) => {
      enqueueSnackbar(`Error searching companies: ${formatMaybeApolloError(error)}`)
    },
  })

  useChargeVendorsWithCodeOverridesQuery({
    variables: {
      query: vendorSearchValue ?? '',
      companyId: selectedCompany?.id ?? '',
      limit: vendorDisplayModalLimit,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.chargeVendors) {
        setChargeVendorSearchResults(data.chargeVendors)
      }
    },
    onError: (error) => {
      enqueueSnackbar(`Error searching charge codes: ${formatMaybeApolloError(error)}`)
    },
  })

  const copyChargeCodesAndClose = (): void => {
    if (hotTableRef?.current) {
      addNewVendorChargeCodes(hotTableRef.current.hotInstance.getData())
      setOpen(false)
    }
  }

  return (
    <StyledDialog className={dialogClass} maxWidth='xl' open={true}>
      <ModalCloseButton onClose={() => setOpen(false)} />
      <DialogTitle>Copy charge codes to {vendor?.name}</DialogTitle>
      <DialogContent>
        <Typography>Select a vendor to copy overrides from:</Typography>
        <Box display='flex' justifyContent='space-between'>
          <Autocomplete
            options={companySearchResults || []}
            getOptionLabel={(option) => option?.name ?? ''}
            renderInput={(params) => <TextField label='Company' variant='outlined' {...params} />}
            value={selectedCompany || undefined}
            onChange={(_, newValue) => {
              if (newValue && newValue.id != selectedCompany?.id) {
                setSelectedCompany(newValue)
                setVendorSearchValue('')
                setRows([])
              }
            }}
            inputValue={companySearchValue}
            onInputChange={(_, newInput) => {
              setCompanySearchValue(newInput)
            }}
            style={{ width: 150 }}
            disableClearable={true}
          />
          <Autocomplete
            options={chargeVendorSearchResults || []}
            getOptionLabel={(option) => option?.name ?? ''}
            renderInput={(params) => <TextField label='Vendor' variant='outlined' {...params} />}
            value={selectedVendor}
            onChange={(_, newValue) => {
              if (newValue) {
                setSelectedVendor(newValue)
                const newRows = []
                for (const node of newValue.chargeCodeVendorOverrides.edges) {
                  if (!node.node.dateDeleted && node.node.chargeCode.apiPartnerId == apiPartnerId) {
                    newRows.push([node.node.description, node.node.chargeCode.code])
                  }
                }
                setRows(newRows)
              }
            }}
            inputValue={vendorSearchValue ?? ''}
            onInputChange={(_, newInput) => {
              setVendorSearchValue(newInput)
            }}
            style={{ width: 300 }}
            disableClearable={true}
          />
        </Box>
        <Box p={1}>
          {selectedVendor && (
            <HotTable
              ref={hotTableRef}
              style={{ width: '100%', overflow: 'scroll', height: HANDSONTABLE_IN_MODAL_HEIGHT }}
              data={rows}
              rowHeaders
              contextMenu={['row_above', 'row_below', 'remove_row']}
              colHeaders={columns.map((column: SpreadsheetDataColumn) => column.name)}
              columnSorting={true}
              columns={columns}
              stretchH='all'
              autoRowSize={true}
            />
          )}
        </Box>
        <Box display='flex' justifyContent='center' p={3}>
          <Box m={3}>
            <Button onClick={() => setOpen(false)} variant='contained'>
              Cancel
            </Button>
          </Box>
          <Box m={3}>
            <Button
              onClick={copyChargeCodesAndClose}
              disabled={!selectedVendor || (rows && rows.length == 0)}
              variant='contained'
              color='primary'
            >
              Copy Charge Codes
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default CopyVendorOverridesModal
