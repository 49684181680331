import { FunctionComponent, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import Box from '@material-ui/core/Box'

import Title from '@src/components/Title'
import ConfirmPageAssocDialog from '@src/components/page_assoc/ConfirmPageAssocDialog'
import FileInfoHeader from '@src/components/page_assoc/FileInfoHeader'
import FilePagesPanel from '@src/components/page_assoc/FilePagesPanel'
import DocumentPreviewPanel from '@src/components/page_assoc/DocumentPreviewPanel'
import usePageAssoc from '@src/hooks/usePageAssoc'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import JobSelector from '@src/components/page_assoc/JobSelector'
import { DocumentTypeNode } from '@src/graphql/types'
import { useHistory } from 'react-router-dom'

const PageAssocPage: FunctionComponent = () => {
  const [isPageAssocDialogOpen, setIsPageAssocDialogOpen] = useState(false)
  const [isCreateJob, setIsCreateJob] = useState(false)
  const [selectedDocType, setSelectedDocType] = useState(null as null | DocumentTypeNode)
  const history = useHistory()

  const filePageIds = new URL(window.location.toString()).searchParams.getAll('ids')

  const { enqueueSnackbar } = useSnackbar()
  const {
    selectedJob,
    setSelectedJob,
    selectedJobTemplate,
    setSelectedJobTemplate,
    createAndAssocPageToJob,
    associatePageToAnExistingJob,
    filePageIdsBeingAssociated,
    setFilePageIdsBeingAssociated,
    filePages,
    toggleSelectPageId,
    selectedFilePageIds,
    selectedTask,
    setSelectedTaskId,
  } = usePageAssoc(filePageIds)

  useEffect(() => {
    setSelectedJobTemplate(null)
    setSelectedDocType(null)
    setSelectedJob(null)
  }, [selectedTask?.id, setSelectedJob, setSelectedJobTemplate])

  useEffect(() => {
    if (selectedJobTemplate) {
      const docTypeNotInJobTemplate =
        selectedJobTemplate.documentTypes!.edges.find(
          (docTypeEdge) => docTypeEdge!.node!.id === selectedDocType?.id,
        ) === undefined
      if (docTypeNotInJobTemplate) {
        setSelectedDocType(null)
      }
      const templateForDiffJob =
        selectedJob && selectedJobTemplate !== selectedJob.node!.jobTemplate!
      if (templateForDiffJob) {
        setSelectedJob(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobTemplate])

  useEffect(() => {
    if (selectedJob) {
      setSelectedJobTemplate(selectedJob.node!.jobTemplate!)
      // keep doc type as-is if its in the job we load
      const docTypeNotInJob =
        selectedJob.node!.jobTemplate!.documentTypes!.edges.find(
          (docTypeEdge) => docTypeEdge!.node!.id === selectedDocType?.id,
        ) === undefined
      if (docTypeNotInJob) {
        setSelectedDocType(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob])

  const currentPage =
    filePages?.find((page) => page.id === selectedFilePageIds[selectedFilePageIds.length - 1]) ??
    null
  const numPageProcessed = filePages?.filter((page) => page.jobId != null).length || 0

  useEffect(() => {
    const redirectToDash = async (): Promise<void> => {
      enqueueSnackbar('The pages you have specified could not be found.', { variant: 'error' })
      history.push('/')
    }

    if (filePageIds.length === 0 || filePages?.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      redirectToDash()
    }
  }, [filePages, filePageIds, enqueueSnackbar, history])
  if (filePages == null) {
    return <CenteredCircularProgress />
  }

  return (
    <Box display='flex' flexDirection='column' flexGrow={1}>
      <Title title='Page Association' />
      <FileInfoHeader
        currentPage={currentPage}
        selectedTask={selectedTask}
        numPageProcessed={numPageProcessed}
        totalPages={filePages.length}
      />
      <Box display='flex'>
        <Box flexBasis='50%' flex={1} m={2}>
          <FilePagesPanel
            filePages={filePages}
            setSelectedTaskId={setSelectedTaskId}
            toggleSelectPageId={toggleSelectPageId}
            selectedFilePageIds={selectedFilePageIds}
            filePageIdsBeingAssociated={filePageIdsBeingAssociated}
          />
          <JobSelector
            setIsCreateJob={setIsCreateJob}
            selectedJob={selectedJob}
            setSelectedJob={setSelectedJob}
            setIsPageAssocDialogOpen={setIsPageAssocDialogOpen}
            setSelectedJobTemplate={setSelectedJobTemplate}
            selectedJobTemplate={selectedJobTemplate}
            selectedTask={selectedTask}
            selectedDocType={selectedDocType}
            setSelectedDocType={setSelectedDocType}
            selectedFilePageIds={selectedFilePageIds}
            filePageIdsBeingAssociated={filePageIdsBeingAssociated}
          />
        </Box>
        <Box flexBasis='50%' flex={1} m={2}>
          <DocumentPreviewPanel filePage={currentPage} />
        </Box>
      </Box>
      <ConfirmPageAssocDialog
        filePages={filePages}
        isCreateJob={isCreateJob}
        selectedFilePageIds={selectedFilePageIds}
        selectedJob={selectedJob}
        selectedJobTemplate={selectedJobTemplate}
        createAndAssocPageToJob={createAndAssocPageToJob}
        associatePageToAnExistingJob={associatePageToAnExistingJob}
        isPageAssocDialogOpen={isPageAssocDialogOpen}
        setIsPageAssocDialogOpen={setIsPageAssocDialogOpen}
        filePageIdsBeingAssociated={filePageIdsBeingAssociated}
        setFilePageIdsBeingAssociated={setFilePageIdsBeingAssociated}
        selectedTask={selectedTask}
        selectedDocType={selectedDocType}
        setSelectedDocType={setSelectedDocType}
      />
    </Box>
  )
}

export default PageAssocPage
