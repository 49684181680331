import { ChangeEvent, FunctionComponent, useMemo } from 'react'
import { Controller, ControllerProps, FieldValues, useWatch } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import { getBgColorFromHash } from '@src/utils/color'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { InputFieldGroup } from '@src/graphql/types'
import { DocumentTypeOption } from '@src/utils/admin/document_type_option'
import { JobTemplateFormValues } from '@src/components/admin/job-template-editor/JobTemplateEditor'

type Props = {
  index: number
  fieldGroup: InputFieldGroup
  isLineItem: boolean
}
const useStyles = makeStyles({
  labelCell: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    // empirically determined to fit the labels -- might want to use flexbox instead of tables
    // to automatically do this
    width: '150px',
  },
  chipText: {
    color: theme.palette.common.white,
  },
  inputField: {
    '& input': {
      color: `${theme.palette.common.white} !important`,
    },
  },
})

const DocumentTypeSelector: FunctionComponent<Props> = ({ index, fieldGroup, isLineItem }) => {
  const classes = useStyles()
  const namePrefix = isLineItem ? 'lineItemTypes' : 'metadataFieldGroups'
  const documentTypeOptions = useWatch<JobTemplateFormValues>({
    name: 'documentTypes',
  }) as DocumentTypeOption[]
  const render: ControllerProps<
    FieldValues,
    `metadataFieldGroups.${number}.documentTypeId` | `lineItemTypes.${number}.documentTypeId`
  >['render'] = useMemo(() => {
    const documentTypeOptionNameMap = Object.fromEntries(
      documentTypeOptions.map((option) => {
        return [option.id, option.name]
      }),
    )
    // we don't pass a ref to the controller since that causes react hook form to try to
    // set the input value to the raw ID on refetch
    return ({ field: { value, onBlur, onChange } }) => {
      const onAutocompleteChange = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _: ChangeEvent<any>,
        val: string,
      ): void => {
        onChange(val)
      }
      return (
        <>
          <Autocomplete
            value={value || ''}
            inputValue={documentTypeOptionNameMap[value] ?? ''}
            options={documentTypeOptions.map((option) => option.id)}
            onChange={onAutocompleteChange}
            onBlur={onBlur}
            getOptionLabel={(option) => documentTypeOptionNameMap[option] ?? ''}
            style={{
              backgroundColor:
                value && documentTypeOptionNameMap[value]
                  ? getBgColorFromHash(documentTypeOptionNameMap[value])
                  : 'black',
            }}
            size='small'
            renderOption={(option) => (
              <Chip
                style={{
                  backgroundColor:
                    option && documentTypeOptionNameMap[option]
                      ? getBgColorFromHash(documentTypeOptionNameMap[option])
                      : 'black',
                }}
                classes={{ label: classes.chipText, deleteIcon: classes.chipText }}
                label={documentTypeOptionNameMap[option]}
              />
            )}
            renderInput={(params) => (
              <TextField {...params} variant='outlined' classes={{ root: classes.inputField }} />
            )}
          />
        </>
      )
    }
  }, [classes.chipText, classes.inputField, documentTypeOptions])
  return (
    <Controller
      render={render}
      name={`${namePrefix}.${index}.documentTypeId`}
      defaultValue={fieldGroup.documentTypeId}
    />
  )
}

export default DocumentTypeSelector
