import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/styles'
import { Box, Divider, Theme, Typography, CircularProgress } from '@material-ui/core'
import theme from '@src/utils/theme'
import MsgReader from '@kenjiuno/msgreader'
import { ApiEDocumentNode } from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import mime from 'mime-types'
import { parseDateString } from '@src/utils/date'

const useStyles = makeStyles<Theme>({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  dialog: {
    maxWidth: '70vw',
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

type Props = {
  isOpen: boolean
  close: () => void
  apiEDocument: ApiEDocumentNode | null
}

const ApiMsgViewerDialog: FunctionComponent<Props> = ({ isOpen, close, apiEDocument }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [eDocMsgReader, setEDocMsgReader] = useState(null as null | MsgReader)

  const [eDocFileData, eDocAttachments] = useMemo(() => {
    if (eDocMsgReader) {
      try {
        const fileData = eDocMsgReader.getFileData()
        const attachments = fileData.attachments!.map((_, idx) => eDocMsgReader.getAttachment(idx))
        return [fileData, attachments]
      } catch (error) {
        enqueueSnackbar(
          `Encountered an error while reading MSG for ${apiEDocument?.filename}: ${formatMaybeApolloError(
            error,
          )}`,
          {
            variant: 'error',
          },
        )
        close()
        setEDocMsgReader(null)
      }
    }
    return [null, null]
  }, [eDocMsgReader, close, apiEDocument?.filename, enqueueSnackbar])

  useEffect(() => {
    const handleMsgFile = async (eDocumentMsgFile: ApiEDocumentNode): Promise<void> => {
      try {
        const response = await fetch(eDocumentMsgFile.eDocUrl!)
        const arrayBuffer = await response.arrayBuffer()
        const msgReader = new MsgReader(arrayBuffer)

        setEDocMsgReader(msgReader)
      } catch (error) {
        enqueueSnackbar(
          `Encountered an error while reading MSG for ${
            eDocumentMsgFile.filename
          }: ${formatMaybeApolloError(error)}`,
          {
            variant: 'error',
          },
        )
        close()
        setEDocMsgReader(null)
      }
    }
    if (apiEDocument) {
      handleMsgFile(apiEDocument).catch((error) => {
        enqueueSnackbar(
          `Encountered an error while reading MSG for ${
            apiEDocument.filename
          }: ${formatMaybeApolloError(error)}`,
          {
            variant: 'error',
          },
        )
        close()
      })
    } else {
      setEDocMsgReader(null)
    }
  }, [apiEDocument, enqueueSnackbar, close])

  const parseHeaders = (headers: string | undefined): Record<string, string> => {
    const parsedHeaders = {} as Record<string, string>
    if (!headers) {
      return parsedHeaders
    }
    const headerRegex = /(.*): (.*)/g
    let match = null
    do {
      match = headerRegex.exec(headers)
      if (match) {
        const [, match_key, match_value] = match
        parsedHeaders[match_key] = match_value
      }
    } while (match)
    return parsedHeaders
  }

  const getMsgDate = (rawHeaders: string | undefined): string => {
    const headers = parseHeaders(rawHeaders)
    if (!headers.Date) {
      return '-'
    }
    return parseDateString(headers.Date).toUTCString()
  }

  return (
    <Dialog fullWidth classes={{ paper: classes.dialog }} open={isOpen}>
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={close} data-testid='close-btn' />
      </Box>
      <DialogContent>
        {!eDocMsgReader || !eDocFileData ? (
          <CircularProgress />
        ) : (
          <Box p={1}>
            <Typography className={classes.bold} variant='subtitle1'>
              From
            </Typography>
            <Typography variant='body1'>
              {eDocFileData.senderName} [{eDocFileData.senderEmail}]
            </Typography>
            <Divider />
            <Typography className={classes.bold} variant='subtitle1'>
              To
            </Typography>
            {eDocFileData.recipients
              ?.filter((recipient) => recipient.recipType === 'to')
              .map((recipient) => {
                return (
                  <Typography key={`recipient-to-${recipient.smtpAddress}`} variant='body1'>
                    {recipient?.name} [{recipient?.smtpAddress}]
                  </Typography>
                )
              })}
            <Divider />
            <Typography className={classes.bold} variant='subtitle1'>
              cc
            </Typography>
            {eDocFileData.recipients
              ?.filter((recipient) => recipient.recipType === 'cc')
              .map((recipient) => {
                return (
                  <Typography key={`recipient-cc-${recipient.smtpAddress}`} variant='body1'>
                    {recipient?.name} [{recipient?.smtpAddress}]
                  </Typography>
                )
              })}
            <Divider />
            <Typography className={classes.bold} variant='subtitle1'>
              Date
            </Typography>
            <Typography variant='body1'>{getMsgDate(eDocFileData?.headers)}</Typography>
            <Divider />
            <Typography className={classes.bold} variant='subtitle1'>
              Subject
            </Typography>
            <Typography variant='body1'>{eDocFileData?.subject}</Typography>
            <Divider />
            <Typography className={classes.bold} variant='subtitle1'>
              Attachments
            </Typography>
            {eDocAttachments?.map((file) => {
              const fileUrl = URL.createObjectURL(
                new File([file.content], file.fileName, {
                  type: mime.lookup(file.fileName) || 'application/octet-stream',
                }),
              )
              return (
                <Typography key={`attachment-${file.fileName}`} variant='body1'>
                  <a href={fileUrl} target='_blank' rel='noreferrer'>
                    {file?.fileName}
                  </a>
                </Typography>
              )
            })}
            <Divider />
            <Typography className={classes.bold} variant='subtitle1'>
              Body
            </Typography>
            <Typography variant='body2' paragraph>
              {eDocFileData?.body}
            </Typography>
            <Divider />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ApiMsgViewerDialog
