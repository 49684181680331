import { Autocomplete } from '@material-ui/lab'
import { ComponentProps } from 'react'
import { useListDashboardCompanyUsersQuery } from '@src/graphql/types'
import { TextField } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { reportRollbarError } from '@src/utils/observability/rollbar'
import { ApolloError } from '@apollo/client/errors'

export type ExternalAssigneeOption = {
  id: string
  email: string
}

type Props = {
  companyId: string
  label?: string
  value: ExternalAssigneeOption | null
  onChange: (value: ExternalAssigneeOption | null) => void
  error?: boolean
  helperText?: string
} & Pick<ComponentProps<typeof Autocomplete>, 'size' | 'disabled'>

export default function ExternalAssigneeSelector({
  companyId,
  label,
  size,
  value,
  onChange,
  error,
  helperText,
  disabled,
}: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar()

  const { data, loading } = useListDashboardCompanyUsersQuery({
    variables: { companyId },
    onError: (e: ApolloError) => {
      reportRollbarError(e)
      enqueueSnackbar('Something went wrong while retrieving dashboard company users', {
        variant: 'error',
      })
    },
  })
  const options = data?.listDashboardCompanyUsers.map(({ id, email }) => ({ id, email })) ?? []

  return (
    <Autocomplete
      size={size}
      options={options}
      getOptionLabel={(user) => user.email}
      getOptionSelected={(option, value) => option.id === value.id}
      loading={loading}
      value={value}
      onChange={(_, value) => onChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={label}
          variant='outlined'
        />
      )}
      data-testid='external-assignee-autocomplete'
      disabled={disabled}
    />
  )
}
