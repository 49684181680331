import { FunctionComponent, useState } from 'react'
import { clsx } from 'clsx'
import { makeStyles } from '@material-ui/core'
import { PageScrollableProvider } from '@src/components/page-scrollable-wrapper/page-scrollable-context'

const useStyles = makeStyles({
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  unscrollablePageWrapper: {
    position: 'relative',
    height: '100vh',
    maxWidth: '100vw',
    overflow: 'hidden',
  },
})

/**
 * Wrapper around the page to set whether the page should be scrollable at the root-level or whether
 * it's expected that a descendant has scroll (in which case height and width is fixed to the
 * viewport height and width)
 */
const PageScrollableWrapper: FunctionComponent = ({ children }) => {
  const classes = useStyles()
  const [pageScrollable, setPageScrollable] = useState(true)
  return (
    <div
      className={clsx(classes.pageWrapper, { [classes.unscrollablePageWrapper]: !pageScrollable })}
    >
      <PageScrollableProvider setPageScrollable={setPageScrollable}>
        {children}
      </PageScrollableProvider>
    </div>
  )
}

export default PageScrollableWrapper
