import { MagicGridColumn, MagicGridRow } from '@src/types/grid'
import { BoxCorners, BoxDimension, GoogleOCRData } from '@src/types/ocr'
import { extractWordFromCoordinates, applyCleanForField } from '@src/utils/ocr'
import { isPresent } from 'ts-is-present'
import { LineItem } from '@src/utils/line_items'
import { v4 as uuidv4 } from 'uuid'
import { closest } from 'fastest-levenshtein'
import { isFallback } from '@src/utils/enum'
import {
  DocumentFieldGroupNode,
  DocumentTableColumnNode,
  DocumentTableNode,
  FieldNode,
  FieldType,
} from '@src/graphql/types'

type NullableFields<T> = {
  [P in keyof T]: T[P] | null
}

export const boxDimensionToCorners = ({ left, width, top, height }: BoxDimension): BoxCorners => {
  return {
    xmin: left,
    xmax: left + width,
    ymin: top,
    ymax: top + height,
  }
}

export const boxCornersToDimension = ({ xmin, ymin, xmax, ymax }: BoxCorners): BoxDimension => {
  return {
    left: xmin,
    top: ymin,
    width: xmax - xmin,
    height: ymax - ymin,
  }
}

export const hasDimension = (
  boxDimension: Partial<NullableFields<BoxDimension>>,
): boxDimension is BoxDimension => {
  return (
    boxDimension.top != null &&
    boxDimension.left != null &&
    boxDimension.width != null &&
    boxDimension.height != null
  )
}

const isApproximatelyZeroBox = (a: BoxCorners): boolean => {
  const EPS = 1e-6
  return a.xmin < EPS && a.xmax < EPS && a.ymin < EPS && a.ymax < EPS
}

export const uniteBoxCorners = (a: BoxCorners, b: BoxCorners): BoxCorners => {
  /*
    When a line item has a field that isn't in the document
    but gets autofilled anyway (e.g. charge code), we set
    its bounding box to be the zero box {0, 0, 0, 0}. This
    causes the grid row box of the line item blow up in size.

    As a quick fix, let's just ignore zero boxes when uniting
    box corners.

    TODO: Fix the bounding boxes of table columns that aren't
    in the doc (e.g. the charge code column) in
    autofill/post_processing.py
  */
  if (isApproximatelyZeroBox(a)) return b
  else if (isApproximatelyZeroBox(b)) return a
  return {
    xmin: Math.min(a.xmin, b.xmin),
    xmax: Math.max(a.xmax, b.xmax),
    ymin: Math.min(a.ymin, b.ymin),
    ymax: Math.max(a.ymax, b.ymax),
  }
}

/**
 * check whether outer box container sinner box
 */
const boxContainsBox = (inner: BoxDimension, outer: BoxDimension): boolean => {
  const innerCorners = boxDimensionToCorners(inner)
  const outerCorners = boxDimensionToCorners(outer)

  return (
    outerCorners.xmin <= innerCorners.xmin &&
    innerCorners.xmax <= outerCorners.xmax &&
    outerCorners.ymin <= innerCorners.ymin &&
    innerCorners.ymax <= outerCorners.ymax
  )
}

export const calculateGridDimension = (
  documentFieldGroups: DocumentFieldGroupNode[],
  documentTableColumns: DocumentTableColumnNode[],
): BoxDimension | null => {
  const columnBoxes: BoxCorners[] = documentTableColumns.map((column) =>
    boxDimensionToCorners(column),
  )
  const cellBoxes: BoxCorners[] = documentFieldGroups
    .flatMap((fieldGroup) => fieldGroup.documentFields!.edges.map((edge) => edge!.node!))
    .map(
      (documentField) =>
        (hasDimension(documentField) && boxDimensionToCorners(documentField)) || null,
    )
    .filter(isPresent)
  if (!columnBoxes.length || !cellBoxes.length) {
    return null
  }
  return boxCornersToDimension(columnBoxes.concat(cellBoxes).reduce(uniteBoxCorners))
}

/**
 * Recalculate column dimensions from grid dimensions and column lefts
 */
export const recalculateColumnDimensions = (
  sortedColumns: MagicGridColumn[],
  dimension: BoxDimension,
): MagicGridColumn[] => {
  const gridEndX = dimension.left + dimension.width

  return sortedColumns
    .map((column: MagicGridColumn, idx: number) => {
      const { dimension: colDimension } = column
      const isLastItem = idx === sortedColumns.length - 1
      const xmax = isLastItem ? gridEndX : sortedColumns[idx + 1].dimension.left
      const newWidth = xmax - colDimension.left

      return {
        ...column,
        dimension: {
          height: column.dimension.height,
          left: column.dimension.left,
          top: dimension.top,
          width: newWidth,
        },
      }
    })
    .filter(({ dimension: { width } }) => width > 0)
}

/**
 * Recalculate row dimensions from grid dimensions and row tops
 */
export const recalculateRowDimensions = (
  sortedRows: MagicGridRow[],
  gridDimension: BoxDimension,
): MagicGridRow[] => {
  const gridEndY = gridDimension.top + gridDimension.height

  return sortedRows
    .map((row: MagicGridRow, idx: number) => {
      const ymax = idx !== sortedRows.length - 1 ? sortedRows[idx + 1].dimension.top : gridEndY
      const newHeight = ymax - row.dimension.top
      return {
        ...row,
        dimension: {
          width: gridDimension.width,
          left: gridDimension.left,
          height: newHeight,
          top: row.dimension.top,
        },
      }
    })
    .filter(({ dimension: { height } }) => height > 0)
}

const getAndSortColumns = (
  columns: DocumentTableColumnNode[],
  gridDimension: BoxDimension,
): MagicGridColumn[] => {
  return columns
    .map((column) => ({
      id: uuidv4(),
      key: column.field?.key || null,
      dimension: column,
    }))
    .filter(({ dimension }) => boxContainsBox(dimension, gridDimension))
    .sort((a, b) => a.dimension.left - b.dimension.left)
}

// given a sorted array of ColumnBounds, create and insert a new entry
// using given x coordinate and return a new array of ColumnBounds
export const insertColumn = (
  xCoord: number,
  columns: MagicGridColumn[],
  gridDimension: BoxDimension,
): MagicGridColumn[] => {
  if (columns.length) {
    if (columns.find((column) => column.dimension.left === xCoord) != null) {
      return columns.slice()
    }
    const newColumn = {
      id: uuidv4(),
      key: null,
      // width will be computed from its position later on
      dimension: {
        ...columns[0].dimension,
        left: xCoord,
      },
    }
    return columns.concat([newColumn]).sort((a, b) => a.dimension.left - b.dimension.left)
  }
  const newWidth = xCoord - gridDimension.left - (gridDimension.left + gridDimension.width)
  return [
    {
      id: uuidv4(),
      key: null,
      dimension: {
        top: gridDimension.top,
        height: gridDimension.height,
        left: xCoord,
        width: newWidth,
      },
    },
  ]
}

// filter row boxes that are inside the grid and sort by ascending ymin coordinate

const getAndSortRows = (lineItems: LineItem[], gridDimension: BoxDimension): MagicGridRow[] => {
  return lineItems
    .map((lineItem) => {
      const boxes = Object.values(lineItem.fieldMapping)
        .map((docField) => (hasDimension(docField) && boxDimensionToCorners(docField)) || null)
        .filter(isPresent)
      if (!boxes.length) return null
      const lineBox = boxCornersToDimension(boxes.reduce(uniteBoxCorners))
      return {
        id: uuidv4(),
        dimension: {
          top: lineBox.top,
          height: lineBox.height,
          left: gridDimension.left,
          width: gridDimension.width,
        },
        lineItemId: lineItem.id,
      }
    })
    .filter(isPresent)
    .filter(({ dimension }) => boxContainsBox(dimension, gridDimension))
    .sort((a, b) => a.dimension.top - b.dimension.top)
}

/** given a sorted array of rows, create and insert a new entry (with no associated line item)
 *  using given y coordinate and return a new array of rows
 */
export const insertRow = (
  yCoord: number,
  rows: MagicGridRow[],
  gridDimension: BoxDimension,
): MagicGridRow[] => {
  if (rows.length) {
    if (rows.find((row) => row.dimension.top === yCoord)) {
      // we can't have duplicate row tops, otherwise we'll have rows with zero height
      return rows.slice()
    }
    const newRow = {
      id: uuidv4(),
      // literally nothing matters about a row except its top coordinate. the left and width
      // are all the same, and the height is just calculated from the top of the next row / end of grid
      // during readjustment
      dimension: {
        ...rows[0].dimension,
        top: yCoord,
      },
    } as MagicGridRow
    return rows.concat([newRow]).sort((a, b) => a.dimension.top - b.dimension.top)
  }
  const newHeight = yCoord - gridDimension.top - (gridDimension.top + gridDimension.height)
  return [
    {
      id: uuidv4(),
      dimension: {
        top: yCoord,
        height: newHeight,
        left: gridDimension.left,
        width: gridDimension.width,
      },
    },
  ]
}

export const getColsAndRowsInGrid = (
  tableColumns: DocumentTableColumnNode[],
  lineItems: LineItem[],
  dimension: BoxDimension,
): { columns: MagicGridColumn[]; rows: MagicGridRow[] } => {
  const sortedColumns = getAndSortColumns(tableColumns, dimension)
  const columns = recalculateColumnDimensions(sortedColumns, dimension)
  const sortedRows = getAndSortRows(lineItems, dimension)
  const rows = recalculateRowDimensions(sortedRows, dimension)

  return { columns, rows }
}

// given extracted columnBounds and rowBounds from grid dimension,
// extract the line item columns and row values in ocr letter dict
export const readDataFromTable = (
  columns: MagicGridColumn[],
  rows: MagicGridRow[],
  googleOcrData: GoogleOCRData,
  gridDimension: BoxDimension,
  documentTable: DocumentTableNode,
  tableShowsPreset: boolean,
): { lineItemColumns: string[]; lineItems: LineItem[]; newRows: MagicGridRow[] } => {
  const lineItems = [] as LineItem[]
  const newRows = rows.map((row) => {
    // sometimes the line item row is empty
    if (!row?.ignore && Object.keys(row).length) {
      const {
        dimension: { top: rowTop, height: rowHeight },
      } = row
      const lineItem = {
        id: uuidv4(),
        box: {
          top: rowTop,
          height: rowHeight,
          left: gridDimension.left,
          width: gridDimension.width,
        },
        fieldMapping: {},
        documentTableId: documentTable.id,
      } as LineItem

      const fieldInvalidCharsRegexMapping =
        documentTable.fieldGroup?.fields?.edges.reduce(
          (acc, curr) => {
            if (curr?.node?.invalidCharsRegex) {
              acc[curr.node.key] = curr.node.invalidCharsRegex
            }
            return acc
          },
          {} as Record<string, string>,
        ) || {}

      const docFieldValidValues = Object.fromEntries(
        documentTable?.fieldGroup?.fields?.edges
          ?.map((fieldEdge) => fieldEdge!.node!)
          ?.sort((fieldA, fieldB) => {
            if (tableShowsPreset) {
              return fieldA?.columnOrder - fieldB?.columnOrder
            }
            return fieldA.key.localeCompare(fieldB.key)
          })
          ?.map((field) => [field.key, field.values]) ?? [],
      ) as Record<string, string[] | null>

      const fieldFieldTypeMapping =
        documentTable.fieldGroup?.fields?.edges.reduce(
          (acc, curr) => {
            if (curr?.node?.fieldType && !isFallback(curr?.node?.fieldType)) {
              acc[curr.node.key] = curr?.node?.fieldType.value
            }
            return acc
          },
          {} as Record<string, FieldType>,
        ) || {}

      const docFieldNodesMapping = Object.fromEntries(
        documentTable?.fieldGroup?.fields?.edges
          ?.map((fieldEdge) => fieldEdge!.node!)
          ?.map((field) => [field.key, field]) ?? [],
      ) as Record<string, FieldNode | null>

      columns.forEach(({ key, dimension }) => {
        if (key) {
          const { left: colLeft, width: colWidth } = dimension
          const start = { x: colLeft, y: rowTop }
          const end = { x: colLeft + colWidth, y: rowTop + rowHeight }
          let value = extractWordFromCoordinates(googleOcrData, start, end)
          // find the closest valid value to the extracted word and make it the new value
          if (docFieldValidValues[key]?.length) {
            value = closest(value, docFieldValidValues[key] as string[])
          }
          const cleanedValue = applyCleanForField(
            value,
            fieldInvalidCharsRegexMapping[key],
            fieldFieldTypeMapping[key],
            docFieldNodesMapping[key],
          )

          lineItem.fieldMapping[key] = {
            value: cleanedValue,
            top: rowTop,
            left: colLeft,
            width: colWidth,
            height: rowHeight,
          }
        }
      })

      // should have at least one column value for the line item to be considered valid
      if (Object.keys(lineItem).length) {
        // eslint-disable-next-line no-param-reassign
        lineItems.push(lineItem)
        return { ...row, lineItemId: lineItem.id }
      }
    }
    return { ...row }
  })

  let lineItemColumns
  if (tableShowsPreset) {
    lineItemColumns = documentTable.fieldGroup!.fields!.edges.map(
      (fieldEdge) => fieldEdge!.node!.key,
    )
  } else {
    lineItemColumns = columns.reduce((acc: string[], column: MagicGridColumn) => {
      return column?.key ? [...acc, column?.key] : acc
    }, [])
  }

  return { lineItemColumns, lineItems, newRows }
}
