import { RefObject, useContext, useEffect } from 'react'
import { SidebarContext } from '@src/components/content-with-sidebar/sidebar-context'
import { FastHotTableRefValue } from '@src/components/fast-hot-table/FastHotTable'

const useRerenderOnResize = (hotTableRef: RefObject<undefined | FastHotTableRefValue>): void => {
  const { isCollapsed } = useContext(SidebarContext)
  // need to rerender when we uncollapse, *after* the display: none is done, thus, setTimeout
  useEffect(() => {
    setTimeout(() => {
      if (hotTableRef.current && !hotTableRef.current.hotInstance.isDestroyed) {
        hotTableRef.current.hotInstance.render()
      }
    })
  }, [hotTableRef, isCollapsed])
}

export default useRerenderOnResize
