import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, RefObject, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import { HotTable } from '@handsontable/react'
import { useRef } from 'react'
import Handsontable from 'handsontable'

import CloseIcon from '@material-ui/icons/Close'
import theme from '@src/utils/theme'
import { useQuery } from '@apollo/client'
import { ChargeVendorNode, Query } from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { COMPANY_CHARGE_VENDORS } from '@src/graphql/queries/recon'
import { cleanNonAlphaNumeric } from '@src/utils/string'
import { ChargeCodeWithVendorArray, SelectedChargeCode } from './types'

type Props = {
  chargeCode: SelectedChargeCode
  chargeCodes: ChargeCodeWithVendorArray | undefined
  companyId: string
  setOpen: (open: boolean) => void
  addNewVendorChargeCodes: (newChargeCodeRows: string[][]) => void
  currApiPartnerId: string | null
}

const useStyles = makeStyles<Theme>({
  dialog: {
    // don't block handsontable copy menu
    // important because zindex 1300 is inline
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zIndex: '1000 !important' as any,
    width: '80%',
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  option: {
    fontSize: 14,
  },
})

const columns = [
  {
    key: 'vendor_name',
    name: 'Vendor',
  },
  {
    key: 'description',
    name: 'Description',
  },
  {
    key: 'current_charge_code',
    name: 'Current Charge Code',
  },
]

const AddMultipleModal: FunctionComponent<Props> = ({
  chargeCode,
  chargeCodes,
  setOpen,
  companyId,
  addNewVendorChargeCodes,
  currApiPartnerId,
}: Props) => {
  const classes = useStyles()
  const [rows, setRows] = useState([] as string[][])
  const { enqueueSnackbar } = useSnackbar()
  const [chargeVendorData, setChargeVendorData] = useState([] as ChargeVendorNode[])
  const hotTableRef = useRef<HotTable>(null)

  const closeDialog = (): void => {
    setOpen(false)
  }

  useQuery<Pick<Query, 'companyChargeVendors'>>(COMPANY_CHARGE_VENDORS, {
    variables: { companyId },
    onCompleted: (data) => {
      setChargeVendorData(data.companyChargeVendors as ChargeVendorNode[])
    },
    onError: (error) => {
      enqueueSnackbar(`Error fetching company charge vendors: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  useEffect(() => {
    if (chargeVendorData) {
      const newRows: string[][] = []
      if (!chargeCodes) {
        setRows([])
        return
      }

      const codesAndDescriptions = chargeCodes.map(({ code, description }) => ({
        code,
        description,
      }))
      const uniqueDescriptions = [...new Set(codesAndDescriptions.map((item) => item.description))]
      chargeVendorData.forEach((chargeVendor: ChargeVendorNode) => {
        uniqueDescriptions.forEach((chargeCodeDesc: string) => {
          const currRow: string[] = []
          currRow.push(chargeVendor.name)
          currRow.push(chargeCodeDesc)
          const currChargeCode = chargeVendor!.chargeCodes!.edges!.find(
            (chargeCode) =>
              chargeCode!.node!.description === chargeCodeDesc &&
              chargeCode?.node?.apiPartnerId === currApiPartnerId,
          )
          if (currChargeCode) {
            currRow.push(currChargeCode!.node!.code)
          } else {
            currRow.push('')
          }
          newRows.push(currRow)
        })
      })
      setRows(newRows)
    }
  }, [chargeVendorData, chargeCodes, currApiPartnerId])

  const markDuplicateDescriptions = (): string[][] => {
    const newRows = [...(hotTableRef!.current!.hotInstance!.getData() as string[][])]
    newRows.forEach((row) => {
      const [vendorName, chargeDescription, _currentCode] = row
      const currVendor = chargeVendorData.find(
        (vendor) =>
          cleanNonAlphaNumeric(vendor.name).toLowerCase() ===
          cleanNonAlphaNumeric(vendorName).toLowerCase(),
      )
      if (currVendor) {
        const foundChargeDesc = currVendor!.chargeCodes!.edges!.find(
          (chargeCodeEdge) =>
            cleanNonAlphaNumeric(chargeCodeEdge!.node!.description).toLowerCase() ===
              cleanNonAlphaNumeric(chargeDescription).toLowerCase() &&
            chargeCodeEdge?.node?.apiPartnerId === currApiPartnerId,
        )
        if (foundChargeDesc) {
          row[2] = foundChargeDesc!.node!.code
        } else {
          row[2] = ''
        }
      }
    })
    return newRows
  }

  const afterTableChange = (changes: Array<Array<string | number>>): void => {
    if (changes) {
      changes.some((change: Array<string | number>) => {
        const [_row, col, oldValue, newValue] = change
        const chargeDescColumn = 1
        if (col === chargeDescColumn) {
          if (oldValue !== newValue) {
            // if any description changed, dynamically update the charge code
            const newRows = markDuplicateDescriptions()
            setRows(newRows)
            return true
          }
        }
        return false
      })
    }
  }

  function duplicateDescRenderer(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _instance: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    td: any,
    row: number,
    _col: number,
    _prop: number,
    _value: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _cellProperties: any,
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _this = this as any
    /* eslint-disable prefer-rest-params */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Handsontable.renderers.TextRenderer.apply(_this, arguments as any)

    const currRow = rows[row]
    const currChargeCode = currRow[2]
    if (currChargeCode) {
      td.style.background = theme.palette.error.light
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(Handsontable.renderers as any).registerRenderer('duplicateDescRenderer', duplicateDescRenderer)

  const copyChargeCodesAndClose = (): void => {
    addNewVendorChargeCodes(hotTableRef?.current?.hotInstance.getData() as string[][])
    closeDialog()
  }

  return (
    <Dialog maxWidth='xl' className={classes.dialog} open={true}>
      <IconButton className={classes.close} onClick={closeDialog}>
        <CloseIcon />
      </IconButton>
      <DialogTitle>Add {chargeCode?.code} to multiple vendors</DialogTitle>
      <DialogContent>
        <Typography>
          The charge code will be added to the following vendors with the corresponding charge
          description:
        </Typography>
        <Box>
          {chargeVendorData && (
            <HotTable
              id='charge-code-table'
              ref={hotTableRef as RefObject<HotTable>}
              style={{ width: '100%', overflow: 'hidden', height: '400px' }}
              data={rows}
              rowHeaders
              contextMenu={['row_above', 'row_below', 'remove_row']}
              colHeaders={columns.map((col) => col.name)}
              columnSorting={true}
              columns={columns}
              afterChange={afterTableChange}
              stretchH='all'
              cells={() => {
                const cellProperties: Record<string, string> = {}
                cellProperties['renderer'] = 'duplicateDescRenderer'
                return cellProperties
              }}
              autoRowSize={true}
            />
          )}
        </Box>
        <Box display='flex' justifyContent='center' p={3}>
          <Box m={3}>
            <Button onClick={closeDialog} variant='contained'>
              Cancel
            </Button>
          </Box>
          <Box m={3}>
            <Button onClick={copyChargeCodesAndClose} variant='contained' color='primary'>
              Add Charge Code
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AddMultipleModal
