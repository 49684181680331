import { isPresent } from 'ts-is-present'
import { DocumentFieldGroupNode, DocumentTypeFieldGroupNode, JobNode } from '@src/graphql/types'
import { uniqBy } from 'lodash'
import { CSV_EXPORT_JOB_TYPES } from '@src/utils/app_constants'
import {
  boxCornersToDimension,
  boxDimensionToCorners,
  hasDimension,
  uniteBoxCorners,
} from '@src/utils/magic_grid'
import { sortComparerWrapper } from '@src/redux-features/document_editor/line_items_table'

/** returns list of the doc field groups in a job by key */
export const getJobDocumentTypeFieldGroups = (job: JobNode): DocumentTypeFieldGroupNode[] => {
  return uniqBy(
    job
      .jobTemplate!.documentTypes!.edges.flatMap(
        (documentTypeEdge) => documentTypeEdge!.node!.documentTypeFieldGroups!.edges,
      )
      .flatMap((documentTypeFieldGroupEdge) => documentTypeFieldGroupEdge!.node!),
    (documentTypeFieldGroup) => documentTypeFieldGroup.fieldGroup!.key,
  )
}
/** returns list of the doc field groups for each doc in the job */
export const getGroupedJobDocumentFieldGroups = (job: JobNode): DocumentFieldGroupNode[][] => {
  return job.filePages!.edges.map((filePageEdge) =>
    filePageEdge!.node!.document!.documentFieldGroups!.edges.map(
      (documentFieldGroupEdge) => documentFieldGroupEdge!.node!,
    ),
  )
}

const getDefaultFieldMappingRows = (
  fieldMapping: Record<string, string>,
  documentTypeFieldGroups: DocumentTypeFieldGroupNode[],
): string[][] => {
  const sortedFieldsByKey = documentTypeFieldGroups
    .filter((documentTypeFieldGroup) => !documentTypeFieldGroup.fieldGroup!.repeatable)
    .flatMap((documentTypeFieldGroup) => documentTypeFieldGroup.fieldGroup!.fields!.edges)
    .map((fieldNodeEdge) => fieldNodeEdge!.node!)
    .sort((field1, field2) => {
      const name1 = field1.name.toUpperCase()
      const name2 = field2.name.toUpperCase()
      if (name1 < name2) return -1
      if (name1 > name2) return 1
      return 0
    })
  const headers = sortedFieldsByKey.map((field) => field.name)
  const data = sortedFieldsByKey.map((field) => fieldMapping[field.key])
  return [headers, data]
}
const getAscentFieldMappingRows = (fieldMapping: Record<string, string>): string[][] => {
  const columnKeys = [
    'ascent_invoice_number',
    'ascent_transaction_related',
    'ascent_total_dollar_value',
    'ascent_currency',
  ]
  return [columnKeys.map((key) => fieldMapping[key])]
}
export const getJobFieldMappingRows = (
  job: JobNode,
  fieldMapping: Record<string, string>,
  documentTypeFieldGroups: DocumentTypeFieldGroupNode[],
): string[][] => {
  switch (job.jobTemplate!.name) {
    case CSV_EXPORT_JOB_TYPES.ASCENT_INVOICE:
      return getAscentFieldMappingRows(fieldMapping)
    default:
      return getDefaultFieldMappingRows(fieldMapping, documentTypeFieldGroups)
  }
}
const getDefaultLineItemRows = (
  lineItems: Record<string, string>[],
  documentTypeFieldGroups: DocumentTypeFieldGroupNode[],
  fieldGroupKey: string | null,
): string[][] => {
  // fields sorted by field group name then field name
  const sortedFields = documentTypeFieldGroups
    .filter(
      (documentTypeFieldGroup) =>
        documentTypeFieldGroup.fieldGroup!.repeatable &&
        (fieldGroupKey == null || documentTypeFieldGroup.fieldGroup!.key === fieldGroupKey),
    )
    .flatMap((documentTypeFieldGroup) =>
      documentTypeFieldGroup.fieldGroup!.fields!.edges.map((fieldNodeEdge) => [
        documentTypeFieldGroup.fieldGroup!,
        fieldNodeEdge!.node!,
      ]),
    )
    .sort(([fieldGroup1, field1], [fieldGroup2, field2]) => {
      const groupName1 = fieldGroup1.name.toUpperCase()
      const groupName2 = fieldGroup2.name.toUpperCase()
      if (groupName1 < groupName2) return -1
      if (groupName1 > groupName2) return 1
      const name1 = field1.name.toUpperCase()
      const name2 = field2.name.toUpperCase()
      if (name1 < name2) return -1
      if (name1 > name2) return 1
      return 0
    })
  const headers = sortedFields.map(([fieldGroup, field]) =>
    fieldGroupKey == null ? `${fieldGroup.name} - ${field.name}` : field.name,
  )
  const data = lineItems.map((lineItem) =>
    sortedFields.map(([, field]) => lineItem[field.key] || ''),
  )
  return [headers, ...data]
}

/**
 * Gets line item rows for an ascent document, attempting to combine rows from
 * different documents via their (ascent_po_number, ascent_container_number). If there
 * are any rows in any single document with the same (ascent_po_number, ascent_container_number),
 * does not attempt to do any combining.
 */
const getAscentLineItemRows = (
  groupedLineItems: Record<string, string>[][],
  fieldMapping: Record<string, string>,
): string[][] => {
  const getGroupingKey = (lineItem: Record<string, string>): string => {
    const separator = '\0'
    return `${lineItem.ascent_po_number || ''}${separator}${lineItem.ascent_container_number || ''}`
  }
  const exportKeys = [
    'ascent_invoice_number',
    'ascent_product_code',
    'ascent_invoice_qty',
    'ascent_unit_price',
    'ascent_lacey',
    'ascent_fda',
    'ascent_disclaim_reason',
    'ascent_import_declaration', // always Y
    'ascent_export_price',
    'ascent_tariff_code',
    'ascent_goods_description',
  ]
  let canGroup = true
  for (const lineItems of groupedLineItems) {
    const groupKeys = new Set<string>()
    for (const lineItem of lineItems) {
      const groupKey = getGroupingKey(lineItem)
      if (groupKeys.has(groupKey)) {
        canGroup = false
        break
      }
      groupKeys.add(groupKey)
    }
    if (!canGroup) {
      break
    }
  }
  let outputLineItems = []
  if (canGroup) {
    // fields sorted by field group name then field name
    const groupedItems: Record<string, Record<string, string>> = {}
    for (const lineItem of groupedLineItems.flat()) {
      const groupKey = getGroupingKey(lineItem)
      const nonEmptyLineItem = Object.fromEntries(
        Object.entries(lineItem).filter(([, val]) => val && !!val.trim()),
      )
      groupedItems[groupKey] = {
        ...(groupedItems[groupKey] || {}),
        ...nonEmptyLineItem,
      }
    }
    outputLineItems = Object.values(groupedItems)
  } else {
    outputLineItems = groupedLineItems.flat()
  }

  return outputLineItems.map((lineItem) =>
    exportKeys.map((key) => {
      switch (key) {
        case 'ascent_invoice_number':
          return fieldMapping[key] || ''
        case 'ascent_import_declaration':
          return 'Y'
        default:
          return lineItem[key] || ''
      }
    }),
  )
}

/**
 * @param job the job to get rows for
 * @param groupedLineItems the line items to get rows for
 * @param documentTypeFieldGroups the field groups of the job
 * @param fieldMapping field mapping of non-repeatable values
 * @param fieldGroupKey the key of the field group to get rows for. null if for all field groups
 */
export const getJobLineItemRows = (
  job: JobNode,
  groupedLineItems: Record<string, string>[][],
  documentTypeFieldGroups: DocumentTypeFieldGroupNode[],
  fieldMapping: Record<string, string>,
  fieldGroupKey: null | string,
): string[][] => {
  switch (job.jobTemplate!.name) {
    case CSV_EXPORT_JOB_TYPES.ASCENT_INVOICE:
      return getAscentLineItemRows(groupedLineItems, fieldMapping)
    default:
      return getDefaultLineItemRows(groupedLineItems.flat(), documentTypeFieldGroups, fieldGroupKey)
  }
}
export const makeFieldMapping = (
  documentFieldGroups: DocumentFieldGroupNode[],
  useName = false,
  useExportMapping = true,
): Record<string, string> => {
  return Object.fromEntries(
    documentFieldGroups
      .filter((documentFieldGroup) => !documentFieldGroup.fieldGroup!.repeatable)
      .flatMap((documentFieldGroup) => documentFieldGroup.documentFields!.edges)
      .map((edge) => edge!.node!)
      .filter((documentField) => documentField.value)
      .map((documentField) => [
        useName ? documentField.field!.name : documentField.field!.key,
        useExportMapping && documentField.field!.valueExportMapping
          ? JSON.parse(documentField.field!.valueExportMapping)[documentField.value] ||
            documentField.value
          : documentField.value,
      ]),
  )
}

export const makeLineItemsMapping = (
  groupedDocumentFieldGroups: DocumentFieldGroupNode[][],
  enableLineItemsRowOrderPriority = false,
): Record<string, string>[] => {
  const sortedDocumentFieldGroups = groupedDocumentFieldGroups
    .map((documentFieldGroups) =>
      documentFieldGroups
        .map((documentFieldGroup) => {
          const boxes = documentFieldGroup
            .documentFields!.edges.map(
              (documentFieldEdge) =>
                (hasDimension(documentFieldEdge!.node!) &&
                  boxDimensionToCorners(documentFieldEdge!.node)) ||
                null,
            )
            .filter(isPresent)

          return {
            documentFieldGroup,
            rowOrderPriority: documentFieldGroup.rowOrderPriority,
            box: boxes.length ? boxCornersToDimension(boxes.reduce(uniteBoxCorners)) : undefined,
          }
        })
        .sort(sortComparerWrapper(enableLineItemsRowOrderPriority))
        .map((documentFieldGroupWithBox) => documentFieldGroupWithBox.documentFieldGroup),
    )
    .flat()

  return sortedDocumentFieldGroups
    .filter((documentFieldGroup) => documentFieldGroup.fieldGroup!.repeatable)
    .map((documentFieldGroup) =>
      documentFieldGroup!.documentFields!.edges.reduce(
        (acc, documentFieldEdge) => {
          const docFieldValue = documentFieldEdge!.node!.value || ''
          const valueExportMapping = documentFieldEdge!.node!.field?.valueExportMapping
          if (valueExportMapping) {
            const valueExportMap = JSON.parse(valueExportMapping)
            acc[documentFieldEdge!.node!.field!.name!] = valueExportMap[docFieldValue]
          } else {
            acc[documentFieldEdge!.node!.field!.name!] = docFieldValue
          }
          return acc
        },
        {} as Record<string, string>,
      ),
    )
}

export const getFieldGroupFieldNames = (job: JobNode, fieldGroupId: string): string[] => {
  const fieldGroup = job.jobTemplate!.documentTypes?.edges.find((documentType) =>
    documentType.node.documentTypeFieldGroups.edges.some(
      (documentTypeFieldGroup) => documentTypeFieldGroup.node.fieldGroup.id === fieldGroupId,
    ),
  )

  return fieldGroup !== null && fieldGroup !== undefined
    ? fieldGroup.node.documentTypeFieldGroups.edges.flatMap(
        (documentTypeFieldGroup) => documentTypeFieldGroup.node.fieldGroup.name,
      )
    : []
}
