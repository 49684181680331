import { FilePageNodeEdge, JobNode } from '@src/graphql/types'
import XLSX, { WorkBook } from 'xlsx'

const apllGetPageFieldsAndLineItems = (filePage: FilePageNodeEdge): string[][][] => {
  const fieldList = [] as string[][]
  let areLineItemFieldNamesExtracted = false
  const lineItemFields = {} as Record<string, number>
  const lineItems = []
  const documentFieldGroups = filePage?.node?.document?.documentFieldGroups?.edges || []
  for (const documentFieldGroup of documentFieldGroups) {
    const isRepeatable = documentFieldGroup?.node?.fieldGroup?.repeatable
    const fields =
      documentFieldGroup?.node?.documentFields?.edges.map((fieldEdge) => ({
        fieldName: fieldEdge?.node?.field?.name,
        value: fieldEdge?.node?.value,
      })) || []
    if (isRepeatable) {
      // Get line item field names
      // Only get field names once per document since there's at most one repeatable field group in a document type
      if (!areLineItemFieldNamesExtracted) {
        let idx = 0
        const lineItemFieldNames =
          documentFieldGroup?.node?.fieldGroup?.fields?.edges.map(
            (fieldNode) => fieldNode?.node?.name,
          ) || []
        for (const fieldName of lineItemFieldNames) {
          if (fieldName) {
            lineItemFields[fieldName] = idx
            idx += 1
          }
        }
        areLineItemFieldNamesExtracted = true
      }

      // Get line item values and put them in a list of objects
      const lineItem = {} as Record<string, string>
      for (const field of fields) {
        const fieldName = field.fieldName || ''
        const fieldValue = field.value || ''
        lineItem[fieldName] = fieldValue
      }
      lineItems.push(lineItem)
    } else {
      const fieldName = fields[0].fieldName || ''
      const fieldValue = fields[0].value ?? ''
      fieldList.push([fieldName, fieldValue])
    }
  }

  // Convert Line Items dictionary to List of List for Sheet export
  const lineItemList = [] as string[][]

  // Add Line Item Header Row
  const lineItemHeaderRow = []
  for (const lineItemFieldName of Object.keys(lineItemFields)) {
    lineItemHeaderRow.push(lineItemFieldName)
  }
  if (lineItemHeaderRow.length > 0) {
    lineItemList.push(lineItemHeaderRow)
  }

  // Add Line Item Content Rows
  for (const lineItem of lineItems) {
    const contentRow = new Array(lineItemHeaderRow.length).fill('')
    for (const lineItemFieldName of Object.keys(lineItemFields)) {
      const lineItemIndex = lineItemFields[lineItemFieldName]
      // 0 is a falsy value
      if (lineItemIndex || lineItemIndex === 0) {
        contentRow[lineItemIndex] = lineItem[lineItemFieldName] || ''
      }
    }
    lineItemList.push(contentRow)
  }
  return [fieldList, lineItemList]
}
const apllCleanSheetName = (
  sheetName: string,
  isTable: boolean,
  sheetNumber: number | null,
): string => {
  let cleanedSheetName = sheetName
  // sheet name cannot contain chars \/?*[] (regex?)
  const forbiddenCharRegex = /[?*\\/[\]]/g
  cleanedSheetName = cleanedSheetName.replace(forbiddenCharRegex, '')
  // sheet name cannot exceed 31 chars
  let maxSheetLen = 31
  if (isTable) {
    maxSheetLen -= '-table'.length
  }
  if (sheetNumber !== null) {
    maxSheetLen -= sheetNumber.toString().length
  }
  cleanedSheetName = cleanedSheetName.slice(0, maxSheetLen)
  return cleanedSheetName
}
const apllSaveWorkSheetFromJson = (
  workBook: WorkBook,
  jsonFields: Record<string, string>[],
  sheetName: string,
  sheetNames: string[],
  sheetNameCounter: Record<string, number>,
  isTable: boolean,
): void => {
  const workSheet = XLSX.utils.json_to_sheet(jsonFields)
  // duplicate documents in one job
  if (Object.keys(sheetNameCounter).includes(sheetName)) {
    let newSheetName = sheetName
    newSheetName = apllCleanSheetName(newSheetName, isTable, sheetNameCounter[sheetName])
    if (isTable) {
      let prevSheetName = (sheetNameCounter[sheetName] - 1).toString()
      if (prevSheetName === '0') {
        prevSheetName = ''
      }
      newSheetName += prevSheetName
      newSheetName += '-table'
    } else {
      newSheetName += sheetNameCounter[sheetName]
    }
    workBook.SheetNames.push(newSheetName)
    workBook.Sheets[newSheetName] = workSheet
    if (!isTable) {
      sheetNameCounter[sheetName] += 1
    }
  } else {
    let newSheetName = sheetName
    newSheetName = apllCleanSheetName(newSheetName, isTable, null)
    if (isTable) {
      newSheetName += '-table'
    }
    sheetNames.push(newSheetName)
    workBook.SheetNames.push(newSheetName)
    workBook.Sheets[newSheetName] = workSheet
    const defaultSheetSuffixCount = 1
    sheetNameCounter[sheetName] = defaultSheetSuffixCount
  }
}
export const apllFormatExcel = (job: JobNode, workBook: WorkBook): void => {
  const sheetNames = [] as string[]
  const sheetNameCounter = {} as Record<string, number>
  for (const filePage of job?.filePages?.edges || []) {
    const sheetName = filePage?.node?.document?.documentType?.name ?? ''
    const [fieldList, lineItemList] = apllGetPageFieldsAndLineItems(filePage!)
    const jsonFields = [{}] as Record<string, string>[]
    for (const field of fieldList) {
      const fieldName = field[0]
      const fieldValue = field[1]
      jsonFields[0][fieldName] = fieldValue
    }
    apllSaveWorkSheetFromJson(workBook, jsonFields, sheetName, sheetNames, sheetNameCounter, false)
    if (lineItemList.length > 0) {
      const columnHeaders = lineItemList[0]
      const lineItemJsons = [] as Record<string, string>[]
      for (const lineItemRow of lineItemList.slice(1)) {
        const lineItemJson = {} as Record<string, string>
        for (let colIdx = 0; colIdx < lineItemRow.length; colIdx += 1) {
          const columnHeader = columnHeaders[colIdx]
          const columnValue = lineItemRow[colIdx]
          lineItemJson[columnHeader] = columnValue
        }
        lineItemJsons.push(lineItemJson)
      }
      apllSaveWorkSheetFromJson(
        workBook,
        lineItemJsons,
        sheetName,
        sheetNames,
        sheetNameCounter,
        true,
      )
    }
  }
}
