import produce from 'immer'
import { WritableDraft } from 'immer/dist/internal'
import { isEqual } from 'lodash'
import { BoxDimension } from '@src/types/ocr'
import {
  FieldValue,
  getColumnSupersetFromAllTables,
  isJobTableLineItem,
  JobTableFieldValue,
  JobTableLineItem,
  LineItem,
} from '@src/utils/line_items'
import { JobDocumentTable } from '@src/utils/shipment_form'
import { v4 as uuidv4 } from 'uuid'
import { DocumentEditorState } from '../document_editor_state'
import { LineItemsClipboardState } from '../line_items_clipboard'
import { LineItemsTableMap } from '../line_items_table'
import { updateJobAndDocumentTableColumns } from '../line_item_actions'

const newLines = /\r?\n|\r/g

export type HandsontableChanges = {
  rowIdx: number
  colIdx: number
  oldVal: string | number
  newVal: string | number
}[]

/**
 * for SOA jobs, we sync the columns of all the tables according to the superset of all the columns on all tables
 * e.g. If table 1 has [column A, column B, column C] and table 2 has [column C, column D],
 * all tables would be synced to have the columns [column A, column B, column C, column D]
 *
 * but in cases where *all* tables have no columns to display
 * (i.e. table shows preset is False, no saved columns, and no required columns as well)
 * we can display all columns instead since the Main SOA table will always needs to have at least
 * one row and at least one column
 *
 * https://expedock.atlassian.net/browse/PD-868
 */
export const syncTableColumns = (
  state: WritableDraft<DocumentEditorState>,
  documentTables: JobDocumentTable[],
  lineItemsTableMap: LineItemsTableMap,
): void => {
  // we currently assume that document tables in SOA jobs will always have the same field groups
  const defaultColumns = documentTables.length
    ? documentTables[0].fieldGroup.fields.edges.map((field) => field.node.key)
    : []
  const defaultFieldGroup = state.job?.jobTable
    ? state.job.jobTable.fieldGroup
    : documentTables.length
    ? documentTables[0].fieldGroup
    : null
  const columnSuperSet = getColumnSupersetFromAllTables(
    documentTables,
    lineItemsTableMap,
    state.jobTableState?.columns ?? [],
  )
  updateJobAndDocumentTableColumns(
    state,
    documentTables,
    columnSuperSet.length ? columnSuperSet : defaultColumns,
    defaultFieldGroup,
  )
}

export const reorderColumns = (
  startColumnsArr: number[],
  insertIdx: number,
  columns: string[],
): string[] => {
  const columnsToBeMoved = columns.filter((_, idx) => startColumnsArr.includes(idx))
  const newColumns = produce(columns, (columnsDraft) => {
    startColumnsArr.reverse().forEach((colIdx) => {
      columnsDraft.splice(colIdx, 1)
    })
    Object.assign(
      columnsDraft,
      columnsDraft.slice(0, insertIdx).concat(columnsToBeMoved, columnsDraft.slice(insertIdx)),
    )
  })
  return newColumns
}

export const addColumn = (columns: string[], insertIdx: number, colKey: string): string[] => {
  return columns.includes(colKey)
    ? columns
    : columns.slice(0, insertIdx).concat(colKey, columns.slice(insertIdx))
}

export const removeColumn = (columns: string[], colKey: string): string[] => {
  const columnIdx = columns.findIndex((column) => column === colKey)
  return columnIdx === -1
    ? columns
    : columns.slice(0, columnIdx).concat(columns.slice(columnIdx + 1))
}

export const createJobTableFieldValue = (documentId: string, value: string): JobTableFieldValue => {
  return { id: uuidv4(), value: value, documentId: documentId }
}

export const createFieldValue = (value: string): FieldValue => {
  return { id: uuidv4(), value: value }
}

/**
 * Just converts the changes into a more readable format
 */
export const formatHandsontableChanges = (
  changes: [number, number | string, string | number | null, string | number | null][],
): HandsontableChanges => {
  return changes.map((change) => {
    return {
      rowIdx: change[0] as number,
      colIdx: change[1] as number,
      oldVal: change[2] ?? '',
      newVal: change[3] ?? '',
    }
  })
}

/**
 * Maps the column keys from the source (where we copied from)
 * to the keys from the destination (where we will be pasting to)
 * in the form [string, string] since the orderedColKeys may repeat if we paste on cells that have more columns
 * than what we copied from
 * e.g. copy two columns -> paste to three columns.
 * The value of the third column should be the value of the first column copied
 */
export const getUpdatedKeyMapping = (
  orderedColKeys: string[],
  columns: string[],
  pasteStartCol: number,
  pasteEndCol: number,
): string[][] => {
  return Array.from(Array(pasteEndCol - pasteStartCol + 1))
    .map((_, colIdx) => [
      orderedColKeys[colIdx % orderedColKeys.length],
      columns[colIdx + pasteStartCol] ?? null,
    ])
    .filter((colKeyPair) => colKeyPair[1] !== null) as string[][]
}

/**
 * Maps the new keys from getUpdatedKeyMapping (where we will be pasting to)
 * to the field from the source (where we copied from)
 */
export const getUpdatedFieldMapping = (
  updatedKeyMapping: string[][],
  lineItem: LineItem | JobTableLineItem,
  clipboardDocumentId: string | null,
): Record<string, JobTableFieldValue> => {
  return Object.fromEntries(
    updatedKeyMapping.map((keyMapping) => {
      const colKey = keyMapping[0]
      const updatedColKey = keyMapping[1]
      if (!lineItem.fieldMapping[colKey]) {
        return [
          updatedColKey,
          {
            id: uuidv4(),
            value: '',
            documentId: clipboardDocumentId,
          },
        ]
      }
      if (isJobTableLineItem(lineItem)) {
        return [updatedColKey, { ...(lineItem.fieldMapping[colKey] as JobTableFieldValue) }]
      }
      return [updatedColKey, { ...lineItem.fieldMapping[colKey], documentId: clipboardDocumentId! }]
    }),
  ) as Record<string, JobTableFieldValue>
}

export const getUpdatedBoxMapping = (
  lineItem: LineItem | JobTableLineItem,
  clipboardDocumentId: string | null,
): Record<string, BoxDimension> => {
  return isJobTableLineItem(lineItem)
    ? lineItem.boxMapping
    : (Object.fromEntries([[clipboardDocumentId, lineItem.box]]) as Record<string, BoxDimension>)
}

/**
 * If row which the line item was pasted to already has an existing
 * row box dimension, we update the row box dimension to fit the new
 * fields (if needed). If there's no existing row box dimension,
 * we just set it to use the copied line item's row box dimension
 */
export const getUpdatedBoxDimension = (
  currentBox: BoxDimension | undefined,
  updatedBox: BoxDimension,
): BoxDimension => {
  if (currentBox) {
    const newTop = Math.min(updatedBox.top, currentBox.top)
    const newBottom = Math.max(
      currentBox.top + currentBox.height,
      updatedBox.top + updatedBox.height,
    )
    const newLeft = Math.min(currentBox.left, updatedBox.left)
    const newRight = Math.max(
      currentBox.left + currentBox.width,
      updatedBox.left + updatedBox.width,
    )
    return {
      top: newTop,
      left: newLeft,
      width: newRight - newLeft,
      height: newBottom - newTop,
    }
  }
  return updatedBox
}

/**
 * Inserts the new rows at the index where the user made the add row command
 */
export const getLineItemsAfterInsert = (
  insertIdx: number,
  lineItems: (LineItem | JobTableLineItem)[],
  rowsToBeInserted: (LineItem | JobTableLineItem)[],
): (LineItem | JobTableLineItem)[] => {
  return lineItems.slice(0, insertIdx).concat(rowsToBeInserted, lineItems.slice(insertIdx))
}

/**
 * Compares the text values if we were to paste the content in changes (what the handsontable would paste)
 * vs if we were to paste the content in the lineItemsClipboard
 * If the end result is the same, we prioritize pasting from the lineItemsClipboard
 * If not, it means that the lineItemsClipboard is outdated (e.g. we copied from somewhere else/ another window before pasting)
 */
export const isLineItemsClipboardOutdated = (
  lineItemsClipboard: LineItemsClipboardState,
  changes: HandsontableChanges,
): boolean => {
  if (lineItemsClipboard === null) {
    return true
  }
  const copiedRows: string[][] = lineItemsClipboard!.copiedText.map((row) =>
    row.map((val) => (val ? val.replace(newLines, '').trim() : '')),
  )
  const changesByRow: Record<number, string[]> = {}
  changes.forEach((change) => {
    const newVal = change.newVal ? change.newVal.toString().replace(newLines, '').trim() : ''
    if (!changesByRow[change.rowIdx]) {
      changesByRow[change.rowIdx] = [newVal]
    } else {
      changesByRow[change.rowIdx].push(newVal)
    }
  })

  const toPasteFromChanges = Object.values(changesByRow)
  const toPasteFromClipboard: string[][] = []

  toPasteFromChanges.forEach((row, rowIdx) => {
    toPasteFromClipboard.push([])
    row.forEach((_, valIdx) => {
      const copiedRow = copiedRows[rowIdx % copiedRows.length]
      toPasteFromClipboard[rowIdx].push(copiedRow[valIdx % copiedRow.length])
    })
  })
  return !isEqual(toPasteFromChanges, toPasteFromClipboard)
}

export const convertLineItemsToJobTableLineItems = (
  lineItems: LineItem[],
  documentId: string,
): JobTableLineItem[] => {
  const jobTableLineItems = [] as JobTableLineItem[]
  lineItems.forEach((lineItem: LineItem) => {
    let jobTableLineItem = {} as JobTableLineItem
    Object.entries(lineItem.fieldMapping).forEach(([fieldKey, _]) => {
      jobTableLineItem = {
        id: uuidv4(),
        fieldMapping: {
          ...jobTableLineItem.fieldMapping,
          [fieldKey]: {
            ...lineItem.fieldMapping[fieldKey],
            id: uuidv4(),
            documentId: documentId,
          },
        },
        boxMapping: {
          [documentId]: lineItem.box ?? {
            top: 0,
            left: 0,
            height: 0,
            width: 0,
          },
        },
      }
    })
    jobTableLineItems.push(jobTableLineItem)
  })
  return jobTableLineItems
}

export const isEmptyRow = (jobTableLineItem: JobTableLineItem): boolean => {
  if (jobTableLineItem.fieldMapping) {
    return (
      Object.values(jobTableLineItem.fieldMapping).filter((row) => row && row.value).length === 0
    )
  }
  return true
}
