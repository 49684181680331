import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState } from 'react'
import { Box, IconButton, TextField, Theme, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

import theme from '@src/utils/theme'
import { useMutation } from '@apollo/client'
import { Mutation, MutationCreateCompanyArgs } from '@src/graphql/types'
import { CREATE_COMPANY } from '@src/graphql/mutations/company'
import { useSnackbar } from 'notistack'

type Props = {
  refetchCompanies: () => void
  setShowCreateDialog: (show: boolean) => void
}

const useStyles = makeStyles<Theme>({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
})

const CreateCompanyDialog: FunctionComponent<Props> = ({
  setShowCreateDialog,
  refetchCompanies,
}) => {
  const classes = useStyles()
  const [isCreating, setIsCreating] = useState(false)
  const [name, setName] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [createCompanyMutation] = useMutation<
    Pick<Mutation, 'createCompany'>,
    MutationCreateCompanyArgs
  >(CREATE_COMPANY)
  const closePopup = (): void => setShowCreateDialog(false)
  const createCompany = async (): Promise<void> => {
    try {
      setIsCreating(true)
      await createCompanyMutation({ variables: { name } })
      refetchCompanies()
      setShowCreateDialog(false)
      enqueueSnackbar('Successfully created company', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Failed to create company: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    } finally {
      setIsCreating(false)
    }
  }
  return (
    <Dialog open>
      <DialogTitle disableTypography>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h3' className={classes.title}>
            Create New Company
          </Typography>
          <IconButton onClick={closePopup}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' height='100%'>
          <TextField
            placeholder='Company Name'
            variant='outlined'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closePopup}>Cancel</Button>
        <Button variant='contained' color='primary' disabled={isCreating} onClick={createCompany}>
          create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateCompanyDialog
