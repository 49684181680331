import { gql } from '@apollo/client'

export const GET_EXISTING_RECON_ATTEMPT = gql`
  query existingReconAttempt($jobId: String!) {
    existingReconAttempt(jobId: $jobId) {
      id
      resolutionStatus
      userResolved {
        id
        email
      }
      dateResolved
    }
  }
`

export const CHARGE_VENDORS = gql`
  query chargeVendors($companyId: UUID!, $query: String!) {
    chargeVendors(companyId: $companyId, query: $query) {
      id
      name
      code
      type
      dateDeleted
    }
  }
`

export const CHARGE_VENDORS_WITH_CODES = gql`
  query chargeVendorsWithCodes(
    $companyId: UUID!
    $query: String!
    $limit: Int
    $withChargeCodes: Boolean
  ) {
    chargeVendors(
      companyId: $companyId
      query: $query
      limit: $limit
      withChargeCodes: $withChargeCodes
    ) {
      id
      name
      code
      type
      dateDeleted
      chargeCodes {
        edges {
          node {
            id
            code
            description
            dateDeleted
            apiPartnerId
          }
        }
      }
    }
  }
`

export const CHARGE_VENDORS_WITH_CODE_OVERRIDES = gql`
  query chargeVendorsWithCodeOverrides($companyId: UUID!, $query: String!, $limit: Int) {
    chargeVendors(companyId: $companyId, query: $query, limit: $limit) {
      id
      name
      code
      type
      dateDeleted
      chargeCodeVendorOverrides {
        edges {
          node {
            id
            description
            dateDeleted
            chargeCode {
              id
              code
              dateDeleted
              apiPartnerId
            }
          }
        }
      }
    }
  }
`

export const COMPANY_CHARGE_VENDORS = gql`
  query companyChargeVendors($companyId: UUID!) {
    companyChargeVendors(companyId: $companyId) {
      id
      name
      code
      type
      chargeCodes {
        edges {
          node {
            id
            code
            description
            dateDeleted
            apiPartnerId
          }
        }
      }
    }
  }
`

export const COMPANY_CHARGE_VENDORS_AND_OVERRIDES = gql`
  query companyChargeVendorsAndOverrides($companyId: UUID!) {
    companyChargeVendors(companyId: $companyId) {
      id
      name
      code
      type
      chargeCodeVendorOverrides {
        edges {
          node {
            id
            description
            dateDeleted
            chargeCodeId
            chargeCode {
              id
              code
              dateDeleted
            }
          }
        }
      }
    }
  }
`

export const CHARGE_VENDOR_CODES = gql`
  query chargeVendorCodes(
    $chargeVendorId: UUID!
    $pageLimit: Int
    $pageOffset: Int
    $apiPartnerId: UUID
  ) {
    vendorChargeCodes(
      chargeVendorId: $chargeVendorId
      pageLimit: $pageLimit
      pageOffset: $pageOffset
      apiPartnerId: $apiPartnerId
    ) {
      id
      code
      description
      dateDeleted
      apiPartnerId
    }
  }
`

export const CHARGE_CODE_VENDOR_OVERRIDES = gql`
  query vendorChargeCodeOverrides(
    $chargeVendorId: UUID!
    $apiPartnerId: UUID
    $pageLimit: Int
    $pageOffset: Int
  ) {
    vendorChargeCodeOverrides(
      chargeVendorId: $chargeVendorId
      apiPartnerId: $apiPartnerId
      pageLimit: $pageLimit
      pageOffset: $pageOffset
    ) {
      id
      description
      dateDeleted
      chargeVendor {
        id
        name
        code
        dateDeleted
      }
      chargeCode {
        id
        code
        description
        dateDeleted
      }
    }
  }
`

export const VALIDATE_CHARGE_VENDORS = gql`
  query chargeVendorsOk($companyId: UUID!, $chargeVendors: InputVendorCsv!) {
    chargeVendorsOk(companyId: $companyId, chargeVendors: $chargeVendors)
  }
`

export const VALIDATE_CHARGE_CODES = gql`
  query chargeCodesOk(
    $companyId: UUID!
    $apiPartnerIds: [UUID!]!
    $chargeCodesCsv: InputChargeCodeCsv!
  ) {
    chargeCodesOk(
      companyId: $companyId
      apiPartnerIds: $apiPartnerIds
      chargeCodesCsv: $chargeCodesCsv
    )
  }
`

export const CHARGE_CODES_BY_VENDOR_NAMES = gql`
  query chargeCodesByVendorNames(
    $companyId: UUID!
    $chargeVendorNames: [String!]!
    $apiPartnerId: UUID!
  ) {
    chargeCodesByVendorNames(
      companyId: $companyId
      chargeVendorNames: $chargeVendorNames
      apiPartnerId: $apiPartnerId
    ) {
      id
      code
    }
  }
`

export const CHARGE_VENDORS_BY_CODES = gql`
  query chargeVendorsByCodes($companyId: UUID!, $chargeVendorCodes: [String!]!) {
    chargeVendorsByCodes(companyId: $companyId, chargeVendorCodes: $chargeVendorCodes) {
      id
      name
      code
      chargeCodes {
        edges {
          node {
            id
            code
            description
            cleanedDescription
            dateDeleted
          }
        }
      }
    }
  }
`

export const CHARGE_CODES_BY_COMPANY = gql`
  query companyChargeCodes($companyId: UUID!, $apiPartnerId: UUID, $query: String!) {
    companyChargeCodes(companyId: $companyId, apiPartnerId: $apiPartnerId, query: $query) {
      id
      code
      tax {
        id
        taxCode
        taxRate
        dateDeleted
      }
      description
      dateDeleted
    }
  }
`

export const CHARGE_CODES_V2_BY_COMPANY = gql`
  query companyChargeCodesV2($companyId: UUID!, $apiPartnerId: UUID, $query: String!) {
    companyChargeCodesV2(companyId: $companyId, apiPartnerId: $apiPartnerId, query: $query) {
      id
      code
      tax {
        id
        taxCode
        taxRate
        dateDeleted
      }
      description
      dateDeleted
    }
  }
`

export const ALL_CHARGE_CODES_BY_COMPANY = gql`
  query allCompanyChargeCodes($companyId: UUID!, $apiPartnerId: UUID) {
    allCompanyChargeCodes(companyId: $companyId, apiPartnerId: $apiPartnerId)
  }
`

export const CHARGE_CODES_BY_CONFIG_AND_VENDOR = gql`
  query chargeCodesByConfigAndVendor($apiPartnerId: UUID, $vendorName: String) {
    chargeCodesByConfigAndVendor(apiPartnerId: $apiPartnerId, vendorName: $vendorName) {
      id
      code
      tax {
        id
        taxCode
        taxRate
        dateDeleted
      }
      description
      dateDeleted
    }
  }
`

export const CHARGE_CODE_TAXES_BY_API_PARTNER = gql`
  query chargeCodeTaxesByApiPartner($apiPartnerId: UUID!) {
    chargeCodesByApiPartner(apiPartnerId: $apiPartnerId) {
      id
      code
      tax {
        id
        taxCode
        taxRate
        dateDeleted
      }
    }
  }
`

export const TAX_IDS_BY_API_PARTNER = gql`
  query taxIdsByApiPartner($apiPartnerId: UUID!) {
    taxIdsByApiPartner(apiPartnerId: $apiPartnerId) {
      id
      taxCode
      taxRate
      dateDeleted
    }
  }
`

export const UNIQUE_CHARGE_CODES_BY_TAX = gql`
  query uniqueChargeCodesByTax($taxId: UUID!, $apiPartnerId: UUID) {
    uniqueChargeCodesByTax(taxId: $taxId, apiPartnerId: $apiPartnerId)
  }
`

export const CHARGE_CODE_VENDOR_OVERRIDES_AND_DESC = gql`
  query chargeCodeAndVendorOverrides($chargeCodeId: UUID!) {
    chargeCodeAndVendorOverrides(chargeCodeId: $chargeCodeId) {
      id
      code
      description
      chargeCodeVendorOverrides {
        edges {
          node {
            id
            description
            dateDeleted
            chargeVendor {
              id
              dateDeleted
              name
              code
              type
            }
          }
        }
      }
    }
  }
`

export const CHARGE_CODE_VENDORS_AND_DESC = gql`
  query chargeCodesWithSameCode($chargeCodeId: UUID!) {
    chargeCodes(chargeCodeId: $chargeCodeId) {
      id
      code
      description
      chargeVendor {
        id
        dateDeleted
        name
        code
        type
      }
    }
  }
`

export const SEARCH_TAXES = gql`
  query searchTaxes($apiPartnerId: UUID, $query: String!) {
    searchTaxes(apiPartnerId: $apiPartnerId, query: $query) {
      id
      taxCode
      taxRate
      taxType
      taxSystem
      apiPartnerId
      dateDeleted
    }
  }
`

export const RECON_ASYNC_BATCH = gql`
  query reconAsyncBatch($reconAsyncBatchId: String!) {
    reconAsyncBatch(reconAsyncBatchId: $reconAsyncBatchId) {
      id
      status {
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
        ... on ReconAsyncStatusEnum {
          value
          isFallback
        }
      }
      numTasks
      dateCreated
      errorMessage
    }
  }
`

export const CHARGE_CODE_ASYNC_TASK = gql`
  query chargeCodeUploadAsyncTask($chargeCodeAsyncTaskId: String!) {
    chargeCodeUploadAsyncTask(chargeCodeAsyncTaskId: $chargeCodeAsyncTaskId) {
      id
      status
      percentDone
      errorMessage
      dateCreated
    }
  }
`

const INVOICE_RECON_LINE_ITEM_SNAPSHOT_FRAGMENT = gql`
  fragment InvoiceReconLineItemSnapshot on InvoiceReconLineItemSnapshotNode {
    type
    chargeCode
    currency
    chargeCost
    quantity
    chargeDescription
    unitPrice
    taxId
  }
`

const SOA_RECON_LINE_ITEM_SNAPSHOT_FRAGMENT = gql`
  fragment SOAReconLineItemSnapshot on SOAReconLineItemSnapshotNode {
    type
    referenceNumber
    hblNumber
    mblNumber
    carrierBookingNumber
    containerNumber
    invoiceNumber
    orderNumber
    consolNumber
    invoiceDate
    dueDate
  }
`

const RECON_ATTEMPT_WITH_RESULTS_AND_METADATA = gql`
  fragment ReconAttemptWithResultsAndMetadata on ReconAttemptNode {
    id
    isSoaMetadataAttempt
    dateSaved
    dateCreated
    matchingCriteria {
      ... on FallbackValue {
        fallbackValue
        isFallback
      }
      ... on MatchingCriteriaTypeEnum {
        value
        isFallback
      }
    }
    reconStatus
    cargowiseModule {
      ... on FallbackValue {
        fallbackValue
        isFallback
      }
      ... on CwTargetModuleEnum {
        value
        isFallback
      }
    }
    reconDetail {
      vendor
      tmsId
      shipmentNo
      invoiceNo
      hblNo
      mblNo
      referenceNo
      containerNo
      carrierBookingNo
      orderNo
      consolNo
      dueDate
    }
    docCharges {
      id
      chargeCode
      chargeDescription
      chargeCost
      currency
      vendor
      invoiceNumber
      taxId
      taxRate
      invoiceDate
      dueDate
    }
    expectedCharges {
      id
      chainIoSiLineId
      chargeCode
      chargeDescription
      chargeCost
      currency
      vendor
      invoiceNumber
      taxId
      taxRate
      invoiceDate
      dueDate
      isPosted
      overseasSellAmount
      sellCurrency
    }
    reconResults {
      id
      success
      type {
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
        ... on ReconResultTypeEnum {
          value
          isFallback
        }
      }
      chainIoShipment {
        id
      }
      ... on FindShipmentReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        referenceNo
        hblNo
        mblNo
        orderNo
        consolNo
        carrierBookingNo
        containerNo
        isPrimary
        isMaximalAmongDuplicates
        chainIoShipment {
          id
          forwarderReference
          dateModified
          houseBill
          masterBill
          carrierBookingNumber
          bookingReference
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          chainIoConsolidations {
            edges {
              node {
                id
                forwarderReference
              }
            }
          }
          orders {
            edges {
              node {
                id
                orderReference
              }
            }
          }
        }
        chainIoConsolidation {
          id
          forwarderReference
          dateModified
          masterBill
          carrierBookingRef
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          shipments {
            edges {
              node {
                id
                forwarderReference
                houseBill
                orders {
                  edges {
                    node {
                      id
                      orderReference
                    }
                  }
                }
              }
            }
          }
        }
        chainIoCustomsDeclaration {
          id
          forwarderReference
          dateModified
          houseBill
          masterBill
          bookingReference
          containers {
            edges {
              node {
                id
                containerNumber
              }
            }
          }
          orders {
            edges {
              node {
                id
                orderReference
              }
            }
          }
          chainIoShipment {
            id
            carrierBookingNumber
            forwarderReference
            chainIoConsolidations {
              edges {
                node {
                  id
                  forwarderReference
                }
              }
            }
          }
        }
        findShipmentDuplicateReconResults {
          edges {
            node {
              id
              chainIoShipment {
                id
                forwarderReference
                isCancelled
                tmsId
              }
              chainIoConsolidation {
                id
                forwarderReference
                isCancelled
                tmsId
              }
              chainIoCustomsDeclaration {
                id
                forwarderReference
                isCancelled
                tmsId
              }
            }
          }
        }
        invoiceReconLineItemSnapshots {
          edges {
            node {
              id
              ... on InvoiceReconLineItemSnapshotNode {
                ...InvoiceReconLineItemSnapshot
              }
              ... on SOAReconLineItemSnapshotNode {
                ...SOAReconLineItemSnapshot
              }
            }
          }
        }
      }
      ... on ShipmentStaffReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        operationsStaffName
        operationsStaffCode
        salesStaffName
        salesStaffCode
      }
      ... on MetadataReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        key {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on MetadataReconResultKeyEnum {
            value
            isFallback
          }
        }
        invoiceValue
        expectedValue
      }
      ... on FindInvoiceReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        invoiceReconMatches {
          edges {
            node {
              id
              chainIoSi {
                id
                invoiceNumber
              }
            }
          }
        }
      }
      ... on InvoiceLineItemReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        invoiceAmount
        invoiceTaxAmount
        expectedAmount
        expectedTaxAmount
        invoiceChargeCode {
          id
          code
          chargeVendor {
            id
            name
          }
        }
        invoiceChargeCodeCode
        invoiceNumber
        invoiceDate
        dueDate
        invoiceTaxId
        expectedTaxId
        invoiceChargeVendor {
          id
          code
        }
        expectedChargeCode {
          id
          code
          chargeVendor {
            id
            name
          }
        }
        invoiceCurrency
        invoiceUnitPrice
        invoiceQuantity
        chainIoSiLine {
          id
        }
        chainIoConsolCostId
        chainIoCustomsDeclarationCostId
        duplicateChainIoSiLines {
          edges {
            node {
              id
              chainIoSiLine {
                id
              }
            }
          }
        }
        isChargeCodeSame
        isTotalCostEqual
        isCurrencySameOrEmpty
        isChargeVendorSame
        isInvoiceNumberCorrect
        invoiceReconLineItemSnapshot {
          id
          ... on InvoiceReconLineItemSnapshotNode {
            ...InvoiceReconLineItemSnapshot
          }
          ... on SOAReconLineItemSnapshotNode {
            ...SOAReconLineItemSnapshot
          }
        }
      }
      ... on InvoiceTotalReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        invoiceTotalAmount: invoiceAmount
        invoiceTaxedTotalAmount: invoiceTaxedAmount
        expectedTotalAmount: expectedAmount
        expectedTaxedTotalAmount: expectedTaxedAmount
        usedThresholdMatching
        isWithinThreshold
        reconThresholdRange {
          id
          thresholdAmount
        }
      }
      ... on SOATotalReconResultNode {
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
        soaTotalAmount: soaAmount
        expectedTotalAmount: expectedAmount
      }
      ... on ReconResultFallbackNode {
        id
        type {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ReconResultTypeEnum {
            value
            isFallback
          }
        }
      }
    }
    matchReconInvoiceShipmentReference {
      id
      externalAssignee {
        id
        email
      }
      externalStatus
      isModified
    }
    noMatchReconMatchCriteria {
      id
      externalAssignee {
        id
        email
      }
      externalStatus
      isModified
    }
    errorNotes
    expedockAction
  }
`

export const RECON_ATTEMPTS_BY_BATCH = gql`
  ${INVOICE_RECON_LINE_ITEM_SNAPSHOT_FRAGMENT}
  ${SOA_RECON_LINE_ITEM_SNAPSHOT_FRAGMENT}
  ${RECON_ATTEMPT_WITH_RESULTS_AND_METADATA}
  query reconAttempts($reconAsyncBatchId: String!) {
    reconAttempts(reconAsyncBatchId: $reconAsyncBatchId) {
      ...ReconAttemptWithResultsAndMetadata
      job {
        id
        jobTemplate {
          id
          apiPartnerId
          companyId
          requireExternalAssignee
          defaultExternalAssignee {
            id
            email
          }
        }
      }
    }
  }
`

export const FIND_INVOICE_RECON_RESULT_FROM_JOB = gql`
  query findInvoiceReconResultFromJob(
    $jobId: UUID!
    $lumpsum: InputDocumentTable
    $overrideChargeDescription: Boolean!
    $disableSendDueDate: Boolean!
    $matchingCriteria: MatchingCriteriaType!
  ) {
    findInvoiceReconResultFromJob(
      jobId: $jobId
      lumpsum: $lumpsum
      overrideChargeDescription: $overrideChargeDescription
      disableSendDueDate: $disableSendDueDate
      matchingCriteria: $matchingCriteria
    ) {
      docCharges {
        id
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
      }
      expectedCharges {
        id
        chainIoSiLineId
        chargeCode
        chargeDescription
        chargeCost
        currency
        vendor
        invoiceNumber
        taxId
        taxRate
        invoiceDate
        dueDate
        isPosted
        overseasSellAmount
        sellCurrency
      }
      reconAttempt {
        id
        job {
          id
          jobTemplate {
            id
            apiPartnerId
            companyId
          }
        }
        reconDetail {
          vendor
          tmsId
          shipmentNo
          invoiceNo
          hblNo
          mblNo
          referenceNo
          containerNo
          carrierBookingNo
          consolNo
          orderNo
          dueDate
        }
        cargowiseModule {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on CwTargetModuleEnum {
            value
            isFallback
          }
        }
        matchingCriteria {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on MatchingCriteriaTypeEnum {
            value
            isFallback
          }
        }
      }
      reconResults {
        id
        success
        chainIoShipment {
          id
          dateModified
        }
        ... on FindShipmentReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          referenceNo
          hblNo
          mblNo
          carrierBookingNo
          containerNo
          consolNo
          orderNo
          isPrimary
          isMaximalAmongDuplicates
          chainIoShipment {
            id
            forwarderReference
            chainIoConsolidations {
              edges {
                node {
                  id
                  forwarderReference
                }
              }
            }
          }
          chainIoConsolidation {
            id
            forwarderReference
          }
          chainIoCustomsDeclaration {
            id
            forwarderReference
            chainIoShipment {
              id
            }
          }
          findShipmentDuplicateReconResults {
            edges {
              node {
                id
                chainIoShipment {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                chainIoConsolidation {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
                chainIoCustomsDeclaration {
                  id
                  forwarderReference
                  isCancelled
                  tmsId
                }
              }
            }
          }
        }
        ... on FindInvoiceReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          invoiceReconMatches {
            edges {
              node {
                id
                chainIoSi {
                  id
                  invoiceNumber
                }
              }
            }
          }
        }
        ... on InvoiceLineItemReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          invoiceAmount
          invoiceTaxAmount
          expectedAmount
          expectedTaxAmount
          invoiceChargeCode {
            id
            code
            chargeVendor {
              id
              name
            }
          }
          invoiceChargeCodeCode
          expectedChargeCode {
            id
            code
            chargeVendor {
              id
              name
            }
          }
          invoiceNumber
          invoiceTaxId
          invoiceDate
          dueDate
          expectedTaxId
          invoiceChargeVendor {
            id
            code
          }
          invoiceCurrency
          invoiceUnitPrice
          invoiceQuantity
          chainIoSiLine {
            id
          }
          duplicateChainIoSiLines {
            edges {
              node {
                id
                chainIoSiLine {
                  id
                }
              }
            }
          }
          isChargeCodeSame
          isTotalCostEqual
          isCurrencySameOrEmpty
          isChargeVendorSame
          isInvoiceNumberCorrect
        }
        ... on InvoiceTotalReconResultNode {
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
          invoiceTotalAmount: invoiceAmount
          invoiceTaxedTotalAmount: invoiceTaxedAmount
          expectedTotalAmount: expectedAmount
          expectedTaxedTotalAmount: expectedTaxedAmount
        }
        ... on ReconResultFallbackNode {
          id
          type {
            ... on FallbackValue {
              fallbackValue
              isFallback
            }
            ... on ReconResultTypeEnum {
              value
              isFallback
            }
          }
        }
      }
    }
  }
`

export const FIND_SHIPMENT_RECON_RESULTS_FROM_JOB = gql`
  query findShipmentReconResults($jobId: UUID!) {
    findShipmentReconResults(jobId: $jobId) {
      id
      success
      type {
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
        ... on ReconResultTypeEnum {
          value
          isFallback
        }
      }
      referenceNo
      hblNo
      mblNo
      consolNo
      orderNo
      carrierBookingNo
      containerNo
      findShipmentDuplicateReconResults {
        edges {
          node {
            id
            chainIoShipment {
              id
              forwarderReference
              isCancelled
              tmsId
            }
            chainIoConsolidation {
              id
              forwarderReference
              isCancelled
              tmsId
            }
            chainIoCustomsDeclaration {
              id
              forwarderReference
              isCancelled
              tmsId
              chainIoShipment {
                id
              }
            }
          }
        }
      }
      chainIoShipment {
        id
        forwarderReference
        chainIoConsolidations {
          edges {
            node {
              id
              forwarderReference
            }
          }
        }
      }
      chainIoConsolidation {
        id
        forwarderReference
      }
      chainIoCustomsDeclaration {
        id
        forwarderReference
        chainIoShipment {
          id
        }
      }
    }
  }
`

export const FIND_SOA_SHIPMENTS_RECON_RESULTS_FROM_JOB = gql`
  query findSoaShipmentsReconResults($jobId: UUID!, $inputSoaLineItems: [InputSOALineItem!]!) {
    findSoaShipmentsReconResults(jobId: $jobId, inputSoaLineItems: $inputSoaLineItems) {
      id
      success
      type {
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
        ... on ReconResultTypeEnum {
          value
          isFallback
        }
      }
      referenceNo
      hblNo
      mblNo
      consolNo
      orderNo
      carrierBookingNo
      containerNo
      findShipmentDuplicateReconResults {
        edges {
          node {
            id
            chainIoShipment {
              id
              forwarderReference
              isCancelled
              tmsId
            }
            chainIoConsolidation {
              id
              forwarderReference
              isCancelled
              tmsId
            }
            chainIoCustomsDeclaration {
              id
              forwarderReference
              isCancelled
              tmsId
            }
          }
        }
      }
      chainIoShipment {
        id
        forwarderReference
        chainIoConsolidations {
          edges {
            node {
              id
              forwarderReference
            }
          }
        }
      }
      chainIoConsolidation {
        id
        forwarderReference
      }
      chainIoCustomsDeclaration {
        id
        forwarderReference
      }
    }
  }
`

export const GET_UNIQUE_CHARGE_VENDORS_BY_COMPANY = gql`
  query uniqueChargeVendorsByCompany($companyId: UUID!, $codes: [String!]) @api(name: cauldron) {
    uniqueChargeVendorsByCompany(companyId: $companyId, codes: $codes) {
      id
      name
      code
    }
  }
`

export const GET_UNIQUE_CHARGE_CODES_BY_COMPANY_PARTNER = gql`
  query uniqueChargeCodesByCompanyPartner(
    $companyId: UUID!
    $apiPartnerId: UUID
    $codes: [String!]
    $vendorCodes: [String!]
  ) @api(name: cauldron) {
    uniqueChargeCodesByCompanyPartner(
      companyId: $companyId
      apiPartnerId: $apiPartnerId
      codes: $codes
      vendorCodes: $vendorCodes
    ) {
      id
      code
      description
    }
  }
`

export const GET_VIEW_OPERATOR_COMPANIES = gql`
  query viewOperatorCompanies @api(name: cauldron) {
    viewOperatorCompanies {
      onlyViewDashboardCompany
      operatorCompanies {
        id
        name
        cargowiseConfigs {
          edges {
            node {
              id
            }
          }
        }
        apiPartners {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const GET_SOA_JOB_RECON_HISTORY = gql`
  ${RECON_ATTEMPT_WITH_RESULTS_AND_METADATA}
  query soaReconAttemptsHistory(
    $jobId: UUID!
    $getSavedOnly: Boolean
    $marker: String
    $pageSize: Int
  ) {
    soaReconAttemptsHistory(
      jobId: $jobId
      getSavedOnly: $getSavedOnly
      marker: $marker
      pageSize: $pageSize
    ) {
      results {
        id
        dateCreated
        userCreated {
          id
          email
        }
      }
      nextMarker
      previousMarker
      hasNext
      hasPrevious
    }
  }
`

export const GET_AP_JOB_RECON_HISTORY = gql`
  query apReconAttemptsHistory(
    $jobId: UUID!
    $getSavedOnly: Boolean
    $marker: String
    $pageSize: Int
  ) {
    apReconAttemptsHistory(
      jobId: $jobId
      getSavedOnly: $getSavedOnly
      marker: $marker
      pageSize: $pageSize
    ) {
      results {
        ...ReconAttemptWithResultsAndMetadata
        userCreated {
          id
          email
        }
      }
      nextMarker
      previousMarker
      hasNext
      hasPrevious
    }
  }
`
