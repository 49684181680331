import { MetadataFormField } from '@src/components/admin/custom-database/metadata_form'
import {
  CustomDatabaseNode,
  CustomDatabaseTypeNode,
  InputCustomDatabase,
  InputCustomDatabaseColumn,
  InputCustomDatabaseType,
  InputCustomDatabaseRow,
  InputCustomDatabaseCell,
} from '@src/graphql/types'
import { SpreadsheetDataColumn } from '../data-grid'

export const constructRowsFromCustomDatabase = (
  customDatabase: CustomDatabaseNode,
  columns: SpreadsheetDataColumn[],
): (string | null | undefined)[][] => {
  return customDatabase.rows.edges
    ?.filter(({ node: row }) => !row.dateDeleted)
    .map(
      ({ node: customDatabaseRow }) =>
        columns.map(
          (column) =>
            customDatabaseRow.cells.edges.find(
              ({ node: cell }) => cell.customDatabaseColumnId == column.key,
            )?.node?.value,
        ) ?? [],
    )
    .filter((row) => row.length > 0)
}

export const constructColumnsFromDatabaseType = (
  customDatabaseType: CustomDatabaseTypeNode | null | undefined,
): SpreadsheetDataColumn[] => {
  return (
    customDatabaseType?.columns?.edges
      ?.filter(({ node: column }) => !column.dateDeleted)
      ?.map(({ node: column }) => {
        return {
          key: column.id,
          name: column.displayName,
        }
      }) ?? []
  )
}

export const constructInputDatabaseType = (
  metadataFormFields: MetadataFormField[],
  rows: string[][],
  columns: SpreadsheetDataColumn[],
): InputCustomDatabaseType => {
  const displayNameIndex = columns.findIndex((column) => column.key === 'displayName')
  const exportKeyIndex = columns.findIndex((column) => column.key === 'exportKey')

  const databaseTypeFields = {
    columns: rows
      .filter((row) => row[exportKeyIndex])
      .map((row) => {
        return {
          displayName: row[displayNameIndex],
          exportKey: row[exportKeyIndex],
        } as InputCustomDatabaseColumn
      }),
  } as Record<string, string | InputCustomDatabaseColumn[]>

  metadataFormFields.map((field) => {
    databaseTypeFields[field.fieldName] = field.fieldValue
  })

  return databaseTypeFields as InputCustomDatabaseType
}

export const constructInputDatabase = (
  metadataFormFields: MetadataFormField[],
  rows: string[][],
  columns: SpreadsheetDataColumn[],
): InputCustomDatabase => {
  const customDatabaseFields = {
    rows: rows
      .filter((row) => row.some((cell) => cell))
      .map((row) => {
        return {
          cells: row.map((cellValue, colIndex) => {
            return {
              value: cellValue,
              customDatabaseColumnId: columns[colIndex].key,
            } as InputCustomDatabaseCell
          }),
        } as InputCustomDatabaseRow
      }),
  } as Record<string, string | InputCustomDatabaseRow[]>

  metadataFormFields.map((field) => {
    customDatabaseFields[field.fieldName] = field.fieldValue
  })

  return customDatabaseFields as InputCustomDatabase
}
