import { FunctionComponent } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { useWatch } from 'react-hook-form'

type Props = {
  open: boolean
  onClose: () => void
  onDelete: () => void
  namePath: string
}
const DeleteDialog: FunctionComponent<Props> = ({ open, namePath, onClose, onDelete }) => {
  const name = useWatch({ name: namePath })
  return (
    <Dialog open={open}>
      <DialogTitle>Are you sure you want to delete field: &ldquo;{name}&rdquo;?</DialogTitle>
      <DialogContent>
        <Typography paragraph color='textSecondary'>
          🛑🛑🛑 STOP!!!! THIS IS EXTREMELY DANGEROUS. DO NOT EVER DO THIS BEFORE TALKING TO
          BILLING/ENGINEERING TEAMS. YOUR ACTIONS ARE NOW BEING MONITORED IF YOU PROCEED AND THERE
          WILL BE SEVERE CONSEQUENCES, INCLUDING INTERNAL INVESTIGATIONS, TERMINATION, OR FURTHER
          LEGAL ACTION FOR MALICIOUS OR IMPROPER DELETION. 🛑🛑🛑
        </Typography>
        <Typography paragraph color='textSecondary'>
          🛑🛑🛑 STOP!!!! THIS MAY CHURN THE CUSTOMER WITHOUT ALERTING BILLING/ENGINEERING.
          ENGINEERING/BILLING WILL NOW BE ALERTED AND WE WILL BE INVESTIGATING YOUR ACTIONS IF YOU
          PROCEED WITH THIS WITHOUT DISCUSSING IT WITH THEM. IF YOU DO NOT KNOW WHO TO ALERT, DO NOT
          EVER PROCEED UNTIL YOU GET APPROVAL OR YOU WILL BE ON THE HOOK FOR AN OUTAGE OR EVEN
          CUSTOMER CHURN!!! 🛑🛑🛑
        </Typography>
        <Typography paragraph color='textSecondary'>
          ⚠️⚠️⚠️ YOUR ACTIONS ARE NOW BEING MONITORED. ⚠️⚠️⚠️
        </Typography>
        <Typography paragraph color='textSecondary'>
          ARE YOU SURE you want to delete field: &ldquo;{name}&rdquo;? This action cannot be undone,
          and <strong>will affect all existing jobs for this job type.</strong>
        </Typography>
        <Typography paragraph color='textSecondary'>
          Deleting this field will cause data to be COMPLETLEY DELETED from our database and cannot
          be recovered. YOU WILL CAUSE AN OUTAGE OR EVEN CHURN IF THIS IS NOT DONE PROPERLY THAT YOU
          WILL BE RESPONSIBLE FOR. DO NOT EVER DO THIS WITHOUT CONSULTING THE BILLING AND
          ENGINEERING TEAMS.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>No</Button>
        <Button
          onClick={() => {
            onDelete()
            onClose()
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
