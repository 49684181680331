import { ChangeEvent, FunctionComponent, useState } from 'react'
import { Box, Divider, Tab, Tabs, TextField, Theme, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/core/styles'
import theme from '@src/utils/theme'
import { DatabaseRecord } from './record_type'

type Props = {
  selectedRecord: DatabaseRecord | null
  setSelectedRecord: (database: DatabaseRecord | null) => void
  displayRecord: (database: DatabaseRecord | null) => string
  setActivePage: (page: number) => void
  searchResults: DatabaseRecord[] | undefined | null
  fetchRecords: (query: string) => void
}

const useStyles = makeStyles<Theme>({
  searchBar: {
    display: 'block',
    flex: '1',
    padding: theme.spacing(2),
  },
  searchResultsList: {
    overflow: 'scroll',
    height: '560px',
    overflowX: 'hidden',
  },
  maxResultsError: {
    color: theme.palette.error.main,
    padding: theme.spacing(1),
  },
  placeHolderResultMessage: {
    padding: theme.spacing(1),
  },
  selectedSearchResult: {
    maxWidth: 'unset',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
    textTransform: 'none',
    width: '100%',
    zIndex: 0,
  },
  searchResult: {
    maxWidth: 'unset',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  selectedItem: {
    zIndex: 1,
    color: theme.palette.primary.contrastText,
    alignSelf: 'start',
    textAlign: 'left',
    marginLeft: theme.spacing(1),
  },
  item: {
    alignSelf: 'start',
    textAlign: 'left',
    marginLeft: theme.spacing(1),
    color: '#020617',
  },
})

const MAX_SEARCH_RESULTS = 50

const DatabaseSearchBar: FunctionComponent<Props> = ({
  selectedRecord,
  setSelectedRecord,
  displayRecord,
  setActivePage,
  searchResults,
  fetchRecords,
}) => {
  const classes = useStyles()
  const [searchValue, setSearchValue] = useState('')

  const searchForRecords = (e: ChangeEvent<{ value: string }>): void => {
    setSearchValue((e!.target!.value! as unknown as string) || '')
    fetchRecords(searchValue)
  }

  return (
    <Box borderRight={`1px solid ${theme.palette.grey[500]}`}>
      <TextField
        variant='outlined'
        fullWidth
        className={classes.searchBar}
        value={searchValue}
        onChange={searchForRecords}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Divider />
      <Box className={classes.searchResultsList}>
        {searchResults && searchResults.length >= MAX_SEARCH_RESULTS && (
          <Typography className={classes.maxResultsError}>
            Only showing the first {searchResults.length} results. Filter by name to show the rest.
          </Typography>
        )}
        {searchResults && searchResults.length == 0 && (
          <Typography align='center' className={classes.placeHolderResultMessage}>
            No results found matching the search criteria. Please try other search values.
          </Typography>
        )}
        <Tabs
          orientation='vertical'
          value={selectedRecord?.id ?? false}
          onChange={(e, v) => {
            setSelectedRecord(searchResults?.find((record) => record.id == v) as DatabaseRecord)
          }}
          TabIndicatorProps={{
            className: classes.selectedSearchResult,
          }}
        >
          {searchResults?.map((sr) => (
            <Tab
              label={
                <Typography
                  className={sr?.id == selectedRecord?.id ? classes.selectedItem : classes.item}
                >
                  {displayRecord(sr)}
                </Typography>
              }
              value={sr?.id}
              key={sr?.id}
              selected={sr == selectedRecord}
              className={classes.searchResult}
              onClick={() => {
                setActivePage(0)
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Divider />
    </Box>
  )
}

export default DatabaseSearchBar
