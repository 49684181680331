import { useSelector } from 'react-redux'
import { RootState } from '@src/utils/store'

import SelectionBox from '@src/components/selection-box'
import { selectFieldCoordinates } from '@src/redux-features/document_editor/field'
import ActiveFieldSelectionLabel from './ActiveFieldSelectionLabel'

type Props = {
  imageWidth: number
  imageHeight: number
  offsetX: number
  offsetY: number
}

/**
 * For displaying the box of the active field
 */
const ActiveFieldSelectionBox: React.FunctionComponent<Props> = ({
  imageWidth,
  imageHeight,
  offsetX,
  offsetY,
}) => {
  const activeFieldKey = useSelector((state: RootState) => state.documentEditor.activeFieldKey)
  const dimension = useSelector(
    (state: RootState) =>
      (activeFieldKey && selectFieldCoordinates(state.documentEditor)?.[activeFieldKey]) ||
      undefined,
  )
  const [top, left, width, height] = dimension
    ? [
        dimension.top * imageHeight + offsetY,
        dimension.left * imageWidth + offsetX,
        dimension.width * imageWidth,
        dimension.height * imageHeight,
      ]
    : [0, 0, 0, 0]
  return (
    (dimension && top + height >= 0 && left + width >= 0 && (
      <>
        <SelectionBox top={top} left={left} width={width} height={height} />
        <ActiveFieldSelectionLabel
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          offsetX={offsetX}
          offsetY={offsetY}
        />
      </>
    )) || <></>
  )
}

export default ActiveFieldSelectionBox
