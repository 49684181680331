/* eslint-disable @typescript-eslint/no-unsafe-return,no-unsafe-finally,@typescript-eslint/no-explicit-any */
// TODO: possibly upstream this to openreplay. Note this is present in both cauldron and beholder
// the any types really do accept any
import { App, Messages } from '@openreplay/tracker'

type OpenReplayGraphQLTracker = (
  operationKind: string,
  operationName: string,
  variables: any,
  result: any,
) => any
type OpenReplayGraphQLTrackerPlugin = (app: App | null) => OpenReplayGraphQLTracker

const elidedValue = '{"elided":null}'

export const openReplayGraphQLTracker = (
  sendResults: 'all' | 'none' | 'errors',
  maxResultsLength: number | undefined,
): OpenReplayGraphQLTrackerPlugin => {
  return (app: App | null): OpenReplayGraphQLTracker => {
    if (app === null) {
      return (_1, _2, _3, result) => result
    }
    return (operationKind, operationName, variables, result) => {
      try {
        let resultsToSend: string
        if (sendResults === 'all' || (sendResults === 'errors' && result?.errors?.length)) {
          resultsToSend = JSON.stringify(result)
        } else {
          resultsToSend = elidedValue
        }
        if (maxResultsLength !== undefined && resultsToSend.length > maxResultsLength) {
          resultsToSend = elidedValue
        }
        app.send(
          Messages.GraphQL(operationKind, operationName, JSON.stringify(variables), resultsToSend),
        )
      } finally {
        return result
      }
    }
  }
}
