// Context and provider for text selection and coordinate display (i.e., boxing)
import { createContext, FunctionComponent, MutableRefObject, useMemo, useRef } from 'react'
import { FieldRefRecord } from '@src/types/shipment_form'

type BoxingContextType = {
  fieldMapRef: MutableRefObject<FieldRefRecord>
}

export const BoxingContext = createContext({} as BoxingContextType)

export const BoxingProvider: FunctionComponent = ({ children }) => {
  // we have a ref of "ref's to avoid calling `useRef()` a variable amount of times
  const fieldMapRef: MutableRefObject<FieldRefRecord> = useRef({})

  const documentAssociationContextValue = useMemo(() => {
    return {
      fieldMapRef,
    }
  }, [fieldMapRef])

  return (
    <BoxingContext.Provider value={documentAssociationContextValue}>
      {children}
    </BoxingContext.Provider>
  )
}
