import { gql } from '@apollo/client'

export const BATCH_SEARCHABLE_RECORD_RESULTS = gql`
  query batchSearchableRecordResults(
    $inputSearchableRecordColumns: [InputSearchableRecordColumn!]!
  ) {
    batchSearchableRecordResults(inputSearchableRecordColumns: $inputSearchableRecordColumns) {
      searchableRecordId
      values
    }
  }
`

export const SEARCHABLE_RECORD_RESULTS = gql`
  query searchableRecordResults(
    $searchableRecordId: String!
    $queryString: String!
    $filters: InputSearchableRecordFilters
  ) {
    searchableRecordResults(
      searchableRecordId: $searchableRecordId
      queryString: $queryString
      filters: $filters
    ) {
      id
      ... on ChargeVendorNode {
        code
        name
      }
      ... on ChargeCodeNode {
        code
        description
      }
      ... on ChargeRateNode {
        shipmentRoute
        namedAccount
        commodity
        containerSize
        containerType
      }
      ... on GeoLocationNode {
        label
      }
      ... on USCarrierNode {
        label
      }
      ... on ScheduleKNode {
        label
      }
      ... on ScheduleDNode {
        label
      }
      ... on SearchableRecordURLResult {
        urlResultRecord
        urlResultSearch
      }
      ... on ChargeCodeSearchResult {
        code
        description
      }
    }
  }
`

export const SEARCHABLE_RECORD_RESULTS_FOR_VALIDATION = gql`
  query searchableRecordsValidation($searchableRecordInputs: [InputSearchableRecordColumn!]!) {
    validSearchableRecordResults(searchableRecordInputs: $searchableRecordInputs) {
      searchableRecordId
      values
    }
  }
`
