import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import Moveable, { OnDragEnd, OnResizeEnd } from 'react-moveable'
import IconButton from '@material-ui/core/IconButton'
import { Theme } from '@material-ui/core'
import OpenWithIcon from '@material-ui/icons/OpenWith'
import { makeStyles } from '@material-ui/styles'
import { isPresent } from 'ts-is-present'

import {
  dragRowAndFieldBoxes,
  resizeLineItem,
  setGridRowTransform,
  setIsRowBoxDraggingOrResizing,
} from '@src/redux-features/document_editor'
import { lineItemSelectors } from '@src/redux-features/document_editor/line_items_table'
import { DEFAULT_BOX_DIMENSION, ROW_BOX_STYLES } from '@src/utils/app_constants'
import { RootState } from '@src/utils/store'
import theme from '@src/utils/theme'
import { BoxDimension } from '@src/types/ocr'

type StyleProps = {
  dimension: BoxDimension
  imageWidth: number
  imageHeight: number
}

const useStyles = makeStyles<Theme, StyleProps>({
  dragWidget: {
    position: 'absolute',
    top: (props) => `${props.dimension.top * props.imageHeight - theme.spacing(1)}px`,
    left: (props) => `${props.dimension.left * props.imageWidth - theme.spacing(4)}px`,
    zIndex: ROW_BOX_STYLES.Z_INDEX + 1,
  },
  rowBoxMoveable: {
    zIndex: ROW_BOX_STYLES.Z_INDEX + 1,
    '& .moveable-control': {
      width: `${theme.spacing(0.8)}px !important`,
      height: `${theme.spacing(0.8)}px !important`,
      marginTop: `-${theme.spacing(0.4)}px !important`,
      marginLeft: `-${theme.spacing(0.4)}px !important`,
      border: 'none !important',
    },
  },
})

type Props = {
  imageBoxRef: MutableRefObject<HTMLImageElement | null>
  fileViewerSize: { width: number | null; height: number | null }
}

const RowBoxMoveable: FunctionComponent<Props> = ({ imageBoxRef, fileViewerSize }) => {
  const moveableRef = useRef(null as Moveable | null)
  const dragWidgetRef = useRef(null as HTMLButtonElement | null)
  const dispatch = useDispatch()
  const [imageWidth, setImageWidth] = useState(imageBoxRef.current?.width ?? 0)
  const [imageHeight, setImageHeight] = useState(imageBoxRef.current?.height ?? 0)

  const activeLineItemIds = useSelector(
    (state: RootState) => state.documentEditor.activeLineItemIds,
  )
  const lineItems = useSelector(
    (state: RootState) => lineItemSelectors.selectAll(state.documentEditor) ?? [],
  )
  const currentActiveLineItem = useMemo(() => {
    if (activeLineItemIds.length === 1) {
      return lineItems.find((item) => item.id === activeLineItemIds[0])
    }
    return null
  }, [lineItems, activeLineItemIds])

  const isRowBoxDraggingOrResizing = useSelector(
    (state: RootState) => state.documentEditor.boxn.isRowBoxDraggingOrResizing,
  )

  const rowBoxElement = useMemo(() => {
    return document.getElementById(currentActiveLineItem?.id ?? '')
  }, [currentActiveLineItem])
  const widgetElement = dragWidgetRef.current
  const currentRowDimension = currentActiveLineItem?.box ?? DEFAULT_BOX_DIMENSION
  const classes = useStyles({ dimension: currentRowDimension, imageWidth, imageHeight })

  const onDragStart = useCallback(() => {
    if (rowBoxElement && widgetElement) {
      batch(() => {
        dispatch(setIsRowBoxDraggingOrResizing(true))
        dispatch(setGridRowTransform('none'))
      })
      rowBoxElement.style.transform = 'none'
      widgetElement.style.transform = 'none'
    }
  }, [dispatch, rowBoxElement, widgetElement])
  const onDrag = useCallback(
    (evt) => {
      if (rowBoxElement && widgetElement) {
        rowBoxElement.style.transform = evt.transform
        widgetElement.style.transform = evt.transform
        dispatch(setGridRowTransform(evt.transform))
      }
    },
    [dispatch, rowBoxElement, widgetElement],
  )
  const onDragEnd = useCallback(
    (evt: OnDragEnd): void => {
      const isBoxDragged = !!evt.lastEvent?.dist
      if (isBoxDragged && rowBoxElement && widgetElement && currentActiveLineItem) {
        const [deltaX, deltaY] = evt.lastEvent.dist
        const newCoords = {
          left: deltaX / imageWidth,
          top: deltaY / imageHeight,
        }
        batch(() => {
          dispatch(dragRowAndFieldBoxes(currentActiveLineItem.id, newCoords))
          dispatch(setGridRowTransform('none'))
          dispatch(setIsRowBoxDraggingOrResizing(false))
        })
        rowBoxElement.style.transform = 'none'
        widgetElement.style.transform = 'none'
      }
    },
    [currentActiveLineItem, dispatch, imageWidth, imageHeight, rowBoxElement, widgetElement],
  )

  const onResize = useCallback(
    (evt) => {
      if (rowBoxElement && widgetElement) {
        const { drag, height, width } = evt
        rowBoxElement.style.width = `${width}px`
        rowBoxElement.style.height = `${height}px`
        const transform = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)`
        rowBoxElement.style.transform = transform
        widgetElement.style.transform = transform
        if (!isRowBoxDraggingOrResizing) {
          dispatch(setIsRowBoxDraggingOrResizing(true))
        }
      }
    },
    [rowBoxElement, dispatch, widgetElement, isRowBoxDraggingOrResizing],
  )
  const onResizeEnd = useCallback(
    (evt: OnResizeEnd): void => {
      if (!evt?.lastEvent?.drag || !evt?.lastEvent?.width || !evt?.lastEvent?.height) {
        dispatch(setIsRowBoxDraggingOrResizing(false))
        return
      }
      if (rowBoxElement && widgetElement && currentActiveLineItem) {
        const { drag, width, height } = evt.lastEvent
        const deltaDimension = {
          left: drag.beforeTranslate[0] / imageWidth,
          top: drag.beforeTranslate[1] / imageHeight,
          width: width / imageWidth,
          height: height / imageHeight,
        }
        batch(() => {
          dispatch(resizeLineItem(currentActiveLineItem.id, deltaDimension))
          dispatch(setIsRowBoxDraggingOrResizing(false))
        })
        rowBoxElement.style.transform = 'none'
        widgetElement.style.transform = 'none'
      }
    },
    [currentActiveLineItem, dispatch, imageWidth, imageHeight, rowBoxElement, widgetElement],
  )

  // this allows snapping to other rowBox elements
  const elementGuidelines = useMemo(() => {
    return lineItems
      .filter((item) => item.id !== currentActiveLineItem?.id)
      .map((item) => document.getElementById(item.id))
      .filter(isPresent)
  }, [currentActiveLineItem, lineItems])

  useEffect(() => {
    setImageWidth(imageBoxRef.current?.width ?? 0)
    setImageHeight(imageBoxRef.current?.height ?? 0)
    moveableRef?.current?.updateRect()
  }, [lineItems, fileViewerSize.width, fileViewerSize.height, imageBoxRef])

  return (
    <>
      <IconButton className={classes.dragWidget} ref={dragWidgetRef} size='small'>
        <OpenWithIcon />
      </IconButton>
      <Moveable
        ref={moveableRef}
        target={rowBoxElement}
        dragTarget={dragWidgetRef.current}
        className={classes.rowBoxMoveable}
        origin={false}
        bounds={{
          // padding for the dragWidget
          left: theme.spacing(2),
          top: 0,
          right: imageWidth,
          bottom: imageHeight,
        }}
        draggable
        onDragStart={onDragStart}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        snappable
        elementGuidelines={elementGuidelines}
        resizable
        onResize={onResize}
        onResizeEnd={onResizeEnd}
        renderDirections={['n', 'e', 'w', 's']}
      />
    </>
  )
}

export default RowBoxMoveable
