import { applyPatches, Patch, Immutable } from 'immer'
import { numberOfVersionsSupported } from './constants'
import { DocumentEditorState } from './document_editor_state'
import produce from 'immer'

export type UndoRedoPatches = {
  undo: Patch[]
  redo: Patch[]
}

export const redoLastRowFieldBoxActionReducer = (state: DocumentEditorState): void => {
  if (state.canRedo) {
    state.lineItemsCurrentVersion += 1
    const patches = state.lineItemsChanges[state.lineItemsCurrentVersion].redo as Patch[]
    applyPatches(state, patches)
    state.canUndo = true
    state.canRedo = Object.prototype.hasOwnProperty.call(
      state.lineItemsChanges,
      state.lineItemsCurrentVersion + 1,
    )
  }
}

export const undoLastRowFieldBoxActionReducer = (state: DocumentEditorState): void => {
  if (state.canUndo) {
    const patches = state.lineItemsChanges[state.lineItemsCurrentVersion].undo as Patch[]
    state.lineItemsCurrentVersion -= 1
    applyPatches(state, patches)
    state.canUndo = Object.prototype.hasOwnProperty.call(
      state.lineItemsChanges,
      state.lineItemsCurrentVersion,
    )
    state.canRedo = true
  }
}

export const computeNextStateWithUndo = (
  state: DocumentEditorState,
  inversePatch: Patch[],
  forwardPatch: Patch[],
  nextState: Immutable<DocumentEditorState>,
): void => {
  const nextStateDraft = produce(nextState, (nextStateDraft) => {
    nextStateDraft.lineItemsCurrentVersion += 1
    nextStateDraft.lineItemsChanges[nextStateDraft.lineItemsCurrentVersion] = {
      undo: inversePatch,
      redo: forwardPatch,
    }
    // TOOD: derive these from state?
    nextStateDraft.canUndo = true
    nextStateDraft.canRedo = false
    delete nextStateDraft.lineItemsChanges[nextStateDraft.lineItemsCurrentVersion + 1]
    delete nextStateDraft.lineItemsChanges[
      nextStateDraft.lineItemsCurrentVersion - numberOfVersionsSupported
    ]
  })
  Object.assign(state, nextStateDraft as DocumentEditorState)
}

/**
 * we reset undo and redo whenever the user switches tables
 */
export const resetLineItemsChangesReducer = (state: DocumentEditorState): void => {
  state.lineItemsCurrentVersion = -1
  state.lineItemsChanges = {}
  state.canUndo = false
  state.canRedo = false
}
