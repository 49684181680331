import { FunctionComponent, useCallback, useMemo } from 'react'
import {
  ApiPartnerInterface,
  Mutation,
  MutationIndexQueueIntoSnowflakeArgs,
  MutationRecreateReindexMirrorArgs,
  MutationRedoReconcileUnmatchedReconAttemptsArgs,
  MutationSyncCauldronDbArgs,
  MutationSyncSearchableRecordsArgs,
  Query,
} from '@src/graphql/types'

import { Typography, Container, Box } from '@material-ui/core'
import {
  SYNC_CAULDRON_DB,
  INDEX_QUEUE_INTO_SNOWFLAKE,
  RECREATE_REINDEX_MIRROR,
  SYNC_SEARCHABLE_RECORDS,
} from '@src/graphql/mutations/chainio'
import { useMutation, useQuery } from '@apollo/client'
import { ALL_COMPANIES } from '@src/graphql/queries/company'
import { isCargowiseConfig } from '@src/utils/api_partner'
import DataSyncOptions from './DataSyncOptions'
import { REDO_RECONCILE_UNMATCHED_ATTEMPTS } from '@src/graphql/mutations/recon'
import ReReconOptions from './ReReconOptions'
import { DeployEnvironment, getDeployEnvironment } from '@src/utils/environment'
import SyncSearchableRecordsOptions from './SyncSearchableRecordsOptions'

const DataSyncAdminPage: FunctionComponent = () => {
  const currentDeployEnv = getDeployEnvironment()
  const { data } = useQuery<Pick<Query, 'allCompanies'>>(ALL_COMPANIES)
  const [syncCauldronDb] = useMutation<
    Pick<Mutation, 'syncCauldronDb'>,
    MutationSyncCauldronDbArgs
  >(SYNC_CAULDRON_DB)
  const [syncSnowflakeDb] = useMutation<
    Pick<Mutation, 'indexQueueIntoSnowflake'>,
    MutationIndexQueueIntoSnowflakeArgs
  >(INDEX_QUEUE_INTO_SNOWFLAKE)
  const [recreateReindexMirror] = useMutation<
    Pick<Mutation, 'recreateReindexMirror'>,
    MutationRecreateReindexMirrorArgs
  >(RECREATE_REINDEX_MIRROR)
  const [syncSearchableRecords] = useMutation<
    Pick<Mutation, 'syncSearchableRecords'>,
    MutationSyncSearchableRecordsArgs
  >(SYNC_SEARCHABLE_RECORDS)
  const [redoReconcileReconAttempts] = useMutation<
    Pick<Mutation, 'redoReconcileUnmatchedReconAttempts'>,
    MutationRedoReconcileUnmatchedReconAttemptsArgs
  >(REDO_RECONCILE_UNMATCHED_ATTEMPTS)

  const hasPollingEnabled = (apiPartner: ApiPartnerInterface): boolean =>
    isCargowiseConfig(apiPartner) && apiPartner.pollDatabase

  const companies = useMemo(() => {
    return (
      data?.allCompanies.filter(
        (company) => !!company?.apiPartners.edges.map(({ node }) => node),
      ) ?? []
    )
  }, [data])

  const companiesWithPolling = useMemo(() => {
    return (
      data?.allCompanies.filter(
        (company) =>
          !!company?.apiPartners.edges
            .map(({ node }) => node)
            .filter((apiPartner) => hasPollingEnabled(apiPartner))?.length,
      ) ?? []
    )
  }, [data])

  const apiPartnersWithPolling = useMemo(() => {
    return (
      companiesWithPolling
        .flatMap((company) => company.apiPartners.edges.map(({ node }) => node))
        .filter((apiPartner) => hasPollingEnabled(apiPartner)) ?? []
    )
  }, [companiesWithPolling])

  const companiesWithInsights = useMemo(() => {
    return data?.allCompanies.filter((company) => company?.hasAccessToInsights) ?? []
  }, [data])

  const apiPartnersWithInsights = useMemo(() => {
    return (
      companiesWithInsights.flatMap((company) =>
        company.apiPartners.edges.map(({ node }) => node),
      ) ?? []
    )
  }, [companiesWithInsights])

  const syncCauldronData = useCallback(
    async (apiPartnerIds: string[] | undefined | null) => {
      await syncCauldronDb({
        variables: {
          apiPartnerIds,
        },
      })
    },
    [syncCauldronDb],
  )

  const syncSnowflakeData = useCallback(
    async (apiPartnerIds: string[] | undefined | null) => {
      await syncSnowflakeDb({
        variables: {
          apiPartnerIds,
        },
      })
    },
    [syncSnowflakeDb],
  )

  const recreateReindexMirrorSnowflake = useCallback(
    async (apiPartnerIds: string[] | undefined | null) => {
      await recreateReindexMirror({
        variables: {
          apiPartnerIds,
        },
      })
    },
    [recreateReindexMirror],
  )

  const syncSearchableRecordsSnowflake = useCallback(
    async (companyIds: string[] | undefined | null) => {
      await syncSearchableRecords({
        variables: {
          companyIds,
        },
      })
    },
    [syncSearchableRecords],
  )

  const redoReconcile = useCallback(
    async (companyIds: string[] | undefined | null) => {
      await redoReconcileReconAttempts({
        variables: {
          companyIds,
        },
      })
    },
    [redoReconcileReconAttempts],
  )

  return (
    <Container>
      <Box py={2}>
        <Box py={2}>
          <Typography variant='h2' gutterBottom>
            Data Sync
          </Typography>
          <Typography gutterBottom>
            Normally, we synchronize with Cargowise DBs (with synchronization enabled) every 15
            minutes. We also (independently) send shipments to Snowflake (for display
            dashboard.expedock.com) every 15 minutes.
          </Typography>
        </Box>
        <Box py={2}>
          <Typography variant='h3' gutterBottom>
            Company Auto re-recon
          </Typography>
          <Typography gutterBottom>
            Auto re-reconciles unmatched recon attempts by company
          </Typography>
          <ReReconOptions companies={companies} redoReconcile={redoReconcile} />
        </Box>
        <Box py={2}>
          <Typography variant='h3' gutterBottom>
            TMS Database → Expedock Database (Cauldron)
          </Typography>
          <Typography gutterBottom>
            Clicking this button forces the DB sync{' '}
            <strong>for recent changes (6 hours) only</strong>, which should take around 2 to 3
            minutes (a dialog will pop up telling you when it&rsquo;s done). Please note that this
            is an expensive operation, and to avoid clicking it in quick succession. Specifying a
            company will company will sync it only for that company. You can further specify which
            API Partners / Cargowise Configs under a company to sync.
          </Typography>
          <Typography gutterBottom>
            <strong>Warning:</strong> Leaving the company field blank will sync it for
            <strong> ALL COMPANIES</strong> so do not do that unless you know what you are doing.
          </Typography>
          <DataSyncOptions
            enabledCompanies={companiesWithPolling}
            enabledApiPartners={apiPartnersWithPolling}
            databaseName='Cauldron'
            syncDatabaseData={syncCauldronData}
          />
        </Box>
        <Box py={2}>
          <Typography variant='h3' gutterBottom>
            Expedock Database (Cauldron) → Snowflake
          </Typography>
          <Typography gutterBottom>
            Clicking this button forces the reindexing/sending to snowflake{' '}
            <strong>for recent changes (90 minutes) only</strong>, which should take around 2 to 3
            minutes (a dialog will pop up telling you when it&rsquo;s done). Please note that this
            is an expensive operation, and to avoid clicking it in quick succession.
          </Typography>
          <Typography gutterBottom>
            <strong>Warning:</strong> Leaving the company field blank will sync it for
            <strong> ALL COMPANIES</strong> so do not do that unless you know what you are doing.
          </Typography>
          <DataSyncOptions
            enabledCompanies={companiesWithInsights}
            enabledApiPartners={apiPartnersWithInsights}
            databaseName='Snowflake'
            syncDatabaseData={syncSnowflakeData}
          />
        </Box>
        {currentDeployEnv !== DeployEnvironment.PRODUCTION && (
          <Box py={2}>
            <Typography variant='h3' gutterBottom>
              Mirror DB (Cauldron) → Snowflake
            </Typography>
            <Typography gutterBottom>
              Clicking this button recreates the cauldron PostgreSQL DB mirror on Snowflake. This
              operation will only mirror those tables needed for a reindex e.g. transforming
              ChainIOShipment → InsightsShipment, ChainIOServiceInvoice + ChainIOServiceInvoiceLines
              → InsightsInvoiceLine. Depending on the environment, the mirror will be used to
              transform to the Insights Schema which will be surfaced on Freight BI and Shipper Viz.
            </Typography>
            <Typography gutterBottom>
              <strong>Warning:</strong> Running this function{' '}
              <strong>
                WILL DELETE ALL DATA FOR ALL COMPANIES AND RECREATE JUST FOR THE SELECTED COMPANIES
              </strong>{' '}
              on the Reindex Snowflake Schema.
            </Typography>
            <Typography gutterBottom>
              <strong>Warning:</strong> Leaving the company field blank will sync it for
              <strong> ALL COMPANIES</strong> so do not do that unless you know what you are doing.
            </Typography>
            <DataSyncOptions
              enabledCompanies={companiesWithInsights}
              enabledApiPartners={apiPartnersWithInsights}
              databaseName='Cauldron'
              syncDatabaseData={recreateReindexMirrorSnowflake}
            />
          </Box>
        )}
        <Box py={2}>
          <Typography variant='h3' gutterBottom>
            Synchronize Searchable Records
          </Typography>
          <Typography gutterBottom>
            Clicking this button forces the Searchable Records synchronization process. Searchable
            Records are the feature we have to allow our customer to search stuff by description
            instead of code in FreightBI. By clicking this button, Expedock customer will be able to
            search code fields in FreightBI by their most latest descriptions.
          </Typography>
          <Typography gutterBottom>
            <strong>Warning:</strong> Leaving the company field blank will sync it for
            <strong> ALL COMPANIES</strong> so do not do that unless you know what you are doing.
          </Typography>
          <SyncSearchableRecordsOptions
            companies={companies}
            syncSearchableRecords={syncSearchableRecordsSnowflake}
          />
        </Box>
      </Box>
    </Container>
  )
}

export default DataSyncAdminPage
