import { useQuery } from '@apollo/client'
import { Box, InputBase, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import JobDocTypesCard from '@src/components/admin/JobDocTypesCard'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import RoundedButton from '@src/components/RoundedButton'
import { COMPANY_JOB_TEMPLATES } from '@src/graphql/queries/company'
import { CompanyNode, JobTemplateNode, Maybe, Query } from '@src/graphql/types'
import theme from '@src/utils/theme'
import { FunctionComponent, useCallback, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import DeleteJobTypeDialog from '@src/components/admin/DeleteJobTypeDialog'
import { useHistory } from 'react-router-dom'

type Props = {
  company: Maybe<CompanyNode>
  companyId: string
}

const useStyles = makeStyles({
  searchRoot: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  cardWrapper: {
    paddingRight: theme.spacing(2),
  },
  newJobTemplateButton: {
    background: 'white',
    color: theme.palette.grey[500],
    border: `1px solid ${theme.palette.grey[500]}`,
  },
  jobTemplatesTextHeader: {
    color: theme.palette.grey['500'],
    fontSize: '25px',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
})

const JobTypesList: FunctionComponent<Props> = ({ company, companyId }) => {
  const classes = useStyles()
  const history = useHistory()
  const [jobTemplateToDelete, setJobTemplateToDelete] = useState(null as JobTemplateNode | null)
  const [query, setQuery] = useState('')

  const {
    data: jobTemplatesData,
    loading: isLoadingJobTemplates,
    refetch: refetchJobTemplates,
  } = useQuery<Pick<Query, 'companyJobTemplates'>>(COMPANY_JOB_TEMPLATES, {
    fetchPolicy: 'network-only',
    variables: {
      companyId,
      queryString: query,
    },
  })

  const redirectToJobType = useCallback((): void => {
    history.push(`/admin/company/${companyId}/job-type/new`)
  }, [companyId, history])

  return (
    <Paper>
      <Box p={1} display='flex' justifyContent='space-between'>
        <Typography className={classes.jobTemplatesTextHeader}>Job Type:</Typography>
        <Box display='flex' justifyContent='flex-end'>
          <Paper className={classes.searchRoot}>
            <InputBase
              value={query}
              className={classes.input}
              placeholder='Search Job Types'
              inputProps={{ 'aria-label': 'Search Job Types' }}
              onChange={(e) => setQuery(e.target.value)}
            />
            <SearchIcon />
          </Paper>
          <RoundedButton
            className={classes.newJobTemplateButton}
            aria-label='add'
            onClick={redirectToJobType}
          >
            + Job Type
          </RoundedButton>
        </Box>
      </Box>
      {isLoadingJobTemplates ? (
        <CenteredCircularProgress />
      ) : (
        <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
          <Box ml={3} display='inline-flex' flexWrap='wrap'>
            {jobTemplatesData!.companyJobTemplates.map((jobTemplate) => (
              <div key={jobTemplate.id} className={classes.cardWrapper}>
                <JobDocTypesCard
                  jobTemplate={jobTemplate}
                  setJobTemplateToDelete={setJobTemplateToDelete}
                  companyId={companyId}
                />
              </div>
            ))}
          </Box>
        </Box>
      )}
      {jobTemplateToDelete && (
        <DeleteJobTypeDialog
          refetchJobTemplates={refetchJobTemplates}
          setJobTemplateToDelete={setJobTemplateToDelete}
          jobTemplate={jobTemplateToDelete}
        />
      )}
    </Paper>
  )
}

export default JobTypesList
