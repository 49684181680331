import { EntityAdapter, EntityId, EntitySelectors, EntityState } from '@reduxjs/toolkit'

/**
 * Given a function that returns type R, make it return R | null
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NullableReturn<F extends (...args: any) => any> = F extends (...args: infer P) => infer R
  ? (...args: P) => R | null
  : never

/**
 * Use adapter to return entity selectors that are safe for use with selectors that might return null
 */
export function getNullableEntitySelectors<T, V>(
  adapter: EntityAdapter<T>,
  selector: (state: V) => EntityState<T> | null,
): { [Key in keyof EntitySelectors<T, V>]: NullableReturn<EntitySelectors<T, V>[Key]> } {
  const { selectIds, selectEntities, selectAll, selectTotal, selectById } = adapter.getSelectors(
    selector as (state: V) => EntityState<T>,
  )
  return {
    selectIds: (state: V) => (selector(state) != null ? selectIds(state) : null),
    selectEntities: (state: V) => (selector(state) != null ? selectEntities(state) : null),
    selectAll: (state: V) => (selector(state) != null ? selectAll(state) : null),
    selectTotal: (state: V) => (selector(state) != null ? selectTotal(state) : null),
    selectById: (state: V, id: EntityId) =>
      selector(state) != null ? selectById(state, id) : null,
  }
}
