import { useCallback, useEffect, useState } from 'react'
import { setGridHotkeysEnabled } from '@src/redux-features/document_editor'
import { useDispatch } from 'react-redux'

const useBulkReplace = (): {
  bulkReplaceOpen: boolean
  setBulkReplaceOpen: (open: boolean) => void
  openBulkReplace: () => void
} => {
  const [bulkReplaceOpen, setBulkReplaceOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    /* Set popup on ctrl + h */
    const handleCtrlKeyDown = (e: KeyboardEvent): void => {
      if (e.key === 'h' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
        setBulkReplaceOpen(true)
      }
    }

    window.addEventListener('keydown', handleCtrlKeyDown)
    return () => {
      window.removeEventListener('keydown', handleCtrlKeyDown)
    }
  }, [setBulkReplaceOpen])
  useEffect(() => {
    dispatch(setGridHotkeysEnabled(!bulkReplaceOpen))
  }, [bulkReplaceOpen, dispatch])

  const openBulkReplace = useCallback((): void => {
    setBulkReplaceOpen(true)
  }, [])

  return {
    bulkReplaceOpen,
    setBulkReplaceOpen,
    openBulkReplace,
  }
}

export default useBulkReplace
