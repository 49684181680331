import { gql } from '@apollo/client'

export const RECONCILE_SOA_JOB = gql`
  mutation reconcileSoaJob(
    $jobId: UUID!
    $overrideChargeDescription: Boolean!
    $matchingCriteria: MatchingCriteriaType!
    $asLumpsum: Boolean!
  ) {
    reconcileSoaJob(
      jobId: $jobId
      overrideChargeDescription: $overrideChargeDescription
      matchingCriteria: $matchingCriteria
      asLumpsum: $asLumpsum
    ) {
      reconAsyncBatchId
    }
  }
`
