import { JobNode, JobTemplateExportSheetNode } from '@src/graphql/types'
import XLSX, { WorkBook, WorkSheet } from 'xlsx'
import {
  getGroupedJobDocumentFieldGroups,
  makeFieldMapping,
  makeLineItemsMapping,
  getFieldGroupFieldNames,
} from './row_conversion'

interface ExportColumnField {
  columnName: string
  order: number
  fixedValue?: string | null
}

export const getExportColumnFieldMapping = (
  jobTemplateExportSheet: JobTemplateExportSheetNode,
  metaData?: Record<string, string>,
): Record<string, ExportColumnField> => {
  return jobTemplateExportSheet.jobTemplateExportColumns!.edges.reduce(
    (acc, exportColumnEdge) => {
      const fieldName = exportColumnEdge!.node!.field
        ? exportColumnEdge!.node!.field.name
        : `${exportColumnEdge!.node!.id}_${exportColumnEdge!.node!.columnName}`

      const isMetaDataField = metaData && fieldName && fieldName in metaData
      acc[fieldName] = {
        columnName: exportColumnEdge!.node!.columnName ?? fieldName,
        order: exportColumnEdge!.node!.order,
        fixedValue: isMetaDataField
          ? metaData![fieldName!]
          : exportColumnEdge!.node!.fixedValue ?? '',
      }
      return acc
    },
    {} as Record<string, ExportColumnField>,
  )
}

export const updateFieldNames = (
  fieldValues: Record<string, string>,
  exportColumnFieldMapping: Record<string, ExportColumnField>,
): Record<string, string> =>
  Object.keys(fieldValues).reduce(
    (acc, fieldName) => {
      if (exportColumnFieldMapping[fieldName]) {
        const newColumnName = exportColumnFieldMapping[fieldName].columnName
        acc[newColumnName] = fieldValues[fieldName]
      }
      return acc
    },
    {} as Record<string, string>,
  )

export const createRowWithFixedValues = (
  exportColumnFieldMapping: Record<string, ExportColumnField>,
): Record<string, string> =>
  Object.values(exportColumnFieldMapping)
    .filter((field) => field.fixedValue)
    .reduce(
      (acc, field) => {
        acc[field.columnName] = field.fixedValue!
        return acc
      },
      {} as Record<string, string>,
    )

export const filterRowWithFixedvalues = (
  rowWithFixedValues: Record<string, string>,
  fieldGroupFieldNames: string[],
): Record<string, string> =>
  Object.keys(rowWithFixedValues)
    .filter((key) => !fieldGroupFieldNames.includes(key))
    .reduce(
      (obj, key) => {
        return {
          ...obj,
          [key]: rowWithFixedValues[key],
        }
      },
      {} as Record<string, string>,
    )

export const addSheet = (
  workBook: WorkBook,
  displayColumnHeader: boolean,
  exportColumnFieldMapping: Record<string, ExportColumnField>,
  rows: Record<string, string>[],
  sheetName: string,
): WorkSheet => {
  const header = Object.values(exportColumnFieldMapping)
    .sort((exportColumnA, exportColumnB) => exportColumnA.order - exportColumnB.order)
    .map((exportColumn) => exportColumn.columnName)

  const sheetOptions = {
    header,
    skipHeader: true,
    origin: displayColumnHeader ? 'A2' : 'A1',
  }

  const worksheet = XLSX.utils.json_to_sheet(rows, sheetOptions)
  if (displayColumnHeader) {
    XLSX.utils.sheet_add_aoa(worksheet, [
      sheetOptions.header.map((columnHeader) => columnHeader.trim()),
    ])
  }
  XLSX.utils.book_append_sheet(workBook, worksheet, sheetName)
  return worksheet
}

export const formatSplitMetadataLineItems = (
  jobs: JobNode[],
  workBook: WorkBook,
  enableLineItemsRowOrderPriority = false,
): void => {
  const sortedExportSheet = [
    ...jobs[0]!.jobTemplate!.jobTemplateExport!.jobTemplateExportSheets!.edges,
  ].sort(
    (exportSheetEdgeA, exportSheetEdgeB) =>
      exportSheetEdgeA!.node!.order - exportSheetEdgeB!.node!.order,
  )

  for (const sheet of sortedExportSheet) {
    const { displayColumnHeader, fieldGroup } = sheet!.node!
    const isMetadataSheet = !fieldGroup
    const sheetName = isMetadataSheet ? 'Metadata' : fieldGroup!.name
    const exportColumnFieldMapping = getExportColumnFieldMapping(sheet!.node!)

    let rows: Record<string, string>[] = []
    for (const job of jobs) {
      const groupedDocumentFieldGroups = getGroupedJobDocumentFieldGroups(job)
      const metaData = makeFieldMapping(groupedDocumentFieldGroups.flat(), true)
      const metaDataFixedExportColumnFieldMapping = getExportColumnFieldMapping(
        sheet!.node!,
        isMetadataSheet ? undefined : metaData,
      )
      const rowWithFixedValues = createRowWithFixedValues(metaDataFixedExportColumnFieldMapping)
      if (isMetadataSheet) {
        rows = [
          ...rows,
          {
            ...rowWithFixedValues,
            ...updateFieldNames(metaData, exportColumnFieldMapping),
          },
        ]
      } else {
        const filteredDocFieldGroups = groupedDocumentFieldGroups.map((groupedDocFieldGroup) =>
          groupedDocFieldGroup.filter(
            (docFieldGroup) => docFieldGroup.fieldGroup!.id === fieldGroup!.id,
          ),
        )
        const filteredRowWithFixedValues = filterRowWithFixedvalues(
          rowWithFixedValues,
          getFieldGroupFieldNames(job, fieldGroup!.id),
        )
        const newRows = makeLineItemsMapping(
          filteredDocFieldGroups,
          enableLineItemsRowOrderPriority,
        ).map((lineItem) => ({
          ...filteredRowWithFixedValues,
          ...updateFieldNames(lineItem, exportColumnFieldMapping),
        }))
        rows = [...rows, ...newRows]
      }
    }
    addSheet(workBook, displayColumnHeader, exportColumnFieldMapping, rows, sheetName)
  }
}

export const formatJoinMetadataLineItems = (
  jobs: JobNode[],
  workBook: WorkBook,
  enableLineItemsRowOrderPriority = false,
): void => {
  const sortedExportSheet = [
    ...jobs![0].jobTemplate!.jobTemplateExport!.jobTemplateExportSheets!.edges,
  ].sort(
    (exportSheetEdgeA, exportSheetEdgeB) =>
      exportSheetEdgeA!.node!.order - exportSheetEdgeB!.node!.order,
  )

  for (const sheet of sortedExportSheet) {
    const { displayColumnHeader, fieldGroup } = sheet!.node!
    const sheetName = fieldGroup!.name
    const exportColumnFieldMapping = getExportColumnFieldMapping(sheet!.node!)
    const rowWithFixedValues = createRowWithFixedValues(exportColumnFieldMapping)
    let rows: Record<string, string>[] = []
    for (const job of jobs) {
      const groupedDocumentFieldGroups = getGroupedJobDocumentFieldGroups(job)
      const metaData = makeFieldMapping(groupedDocumentFieldGroups.flat(), true)
      const filteredDocFieldGroups = groupedDocumentFieldGroups.map((groupedDocFieldGroup) =>
        groupedDocFieldGroup.filter(
          (docFieldGroup) => docFieldGroup.fieldGroup!.id === fieldGroup!.id,
        ),
      )
      const updatedMetadata = updateFieldNames(metaData, exportColumnFieldMapping)
      const lineItems = makeLineItemsMapping(
        filteredDocFieldGroups,
        enableLineItemsRowOrderPriority,
      )
      const newRows = lineItems.length
        ? lineItems.map((lineItem) => ({
            ...rowWithFixedValues,
            ...updatedMetadata,
            ...updateFieldNames(lineItem, exportColumnFieldMapping),
          }))
        : [updatedMetadata]
      rows = [...rows, ...newRows]
    }

    addSheet(workBook, displayColumnHeader, exportColumnFieldMapping, rows, sheetName)
  }
}
