import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'

import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'

import ShipmentForm from '@src/components/shipment-form'
import { useSnackbar } from 'notistack'
import { ActionSet } from '@src/components/shipment-form/ShipmentActions'
import { FilePageType, JobTemplateReconType, Mutation, Query, UserNode } from '@src/graphql/types'
import { FormControl, MenuItem } from '@material-ui/core'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { JOB_OPERATORS } from '@src/graphql/queries/job'
import { SET_JOB_OWNER } from '@src/graphql/mutations/job'

import useRowBoxOutsideClicks from '@src/hooks/boxn/useRowBoxOutsideClicks'
import { v4 as uuid4 } from 'uuid'

import { CREATE_DOCUMENT } from '@src/graphql/mutations/document'
import { useDispatch, useSelector } from 'react-redux'
import { initializeDocumentEditor, setJobTableActive } from '@src/redux-features/document_editor'
import { selectActiveFilePage } from '@src/redux-features/document_editor/file_page'
import { selectActiveDocumentTable } from '@src/redux-features/document_editor/document_table'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'

import { RootState } from '@src/utils/store'
import { clsx } from 'clsx'
import { pollPageAsyncBatch } from '@src/utils/file'
import { ShipmentFormContext } from '@src/contexts/shipment_form_context'
import SpreadsheetDataGrid from '@src/components/data-grid/SpreadsheetDataGrid'
import { UpdateChargeQuantityDialog } from '@src/components/UpdateChargeQuantityDialog'
import { JobDataContext } from '@src/contexts/job_data_context'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { LogEventType } from '@src/utils/observability/LogEventType'
import DocumentTypeAutocomplete from '../shipment-form/DocumentTypeAutocomplete'
import { stateHasMainTable } from '@src/redux-features/document_editor/job_table'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

const useStyles = makeStyles({
  pageCheckerWrapper: {
    height: '100%',
  },
  infoPanel: {
    '& dt': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '& dd': {
      margin: 0,
      overflowWrap: 'break-word',
    },
  },
  hideShipmentForm: {
    display: 'none',
  },
  showShipmentForm: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    overflow: 'hidden',
  },
})

type Props = {
  filePageId: string
  jobId: string
  readOnly: boolean
  actionSet: ActionSet
}

const PageChecker: FunctionComponent<Props> = ({ filePageId, jobId, readOnly, actionSet }) => {
  const useMainTableSpreadsheetDataGrid = useSelector((state: RootState) =>
    stateHasMainTable(state.documentEditor),
  )

  const classes = useStyles()
  const client = useApolloClient()
  const { logEvent } = useEventLogger()
  const pageCheckerRef = useRef(null as HTMLDivElement | null)
  useRowBoxOutsideClicks(pageCheckerRef)
  const [isDocumentLoading, setIsDocumentLoading] = useState(false)
  // we keep this separate state so we don't have to discard changes on owner change
  const [ownerId, setOwnerId] = useState(null as null | string)
  const [setJobOwner, { error: setJobOwnerError }] =
    useMutation<Pick<Mutation, 'setJobOwner'>>(SET_JOB_OWNER)
  const [jobOtherInfo, setjobOtherInfo] = useState('')
  const [isUpdateChargeQuantityDialogOpen, setIsUpdateChargeQuantityDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { jobLoading, jobData, jobRefetch: refetchJob, documentTypes } = useContext(JobDataContext)
  const { setRefetchDocumentEditorJob, saveAndRefetchDocumentEditorJob } =
    useContext(ShipmentFormContext)
  const filePage = useSelector((state: RootState) => selectActiveFilePage(state.documentEditor))
  const documentTypeId = filePage?.document?.documentType?.id || null
  const documentEditorInitialized = useSelector(
    (state: RootState) => jobId && state.documentEditor.job?.id === jobId,
  )
  const [createDocument] = useMutation<Pick<Mutation, 'createDocument'>>(CREATE_DOCUMENT)
  const { data: jobOperatorData, loading: isLoadingOperators } =
    useQuery<Pick<Query, 'jobOperators'>>(JOB_OPERATORS)
  const activeDocumentTable = useSelector((state: RootState) =>
    selectActiveDocumentTable(state.documentEditor),
  )
  const dispatch = useDispatch()

  const refetchJobCallback = useCallback(async () => {
    await refetchJob()
  }, [refetchJob])

  useEffect(() => {
    setRefetchDocumentEditorJob(() => refetchJob)
  }, [dispatch, setRefetchDocumentEditorJob, refetchJob])

  const enableLineItemsRowOrderPriority = useFeatureIsOn('line-items-row-order-priority')

  useEffect(() => {
    if (filePageId && jobData) {
      dispatch(initializeDocumentEditor(jobData.job!, filePageId, enableLineItemsRowOrderPriority))
      setOwnerId(jobData.job!.owner?.id || null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, jobData?.job?.id])

  useEffect(() => {
    // for the Main Tab Spreadsheet Data Grid, if the file page type is EXCEL,
    // we only show the Main Tab Table (job table), so we set jobTableActive to true
    if (useMainTableSpreadsheetDataGrid && filePage && filePage.type === FilePageType.Excel) {
      dispatch(setJobTableActive(true))
    }
  }, [useMainTableSpreadsheetDataGrid, dispatch, filePage])

  /**
   * Return current filePage's document, if type matches. Otherwise, create a new document with
   * that type for the filePage
   */
  const maybeCreateDocumentWithType = async (newDocumentTypeId: string): Promise<void> => {
    if (!filePage || documentTypeId === newDocumentTypeId) {
      return
    }
    const batchId = uuid4()
    try {
      await saveAndRefetchDocumentEditorJob()
      await createDocument({
        variables: {
          filePageId,
          documentTypeId: newDocumentTypeId,
          batchId,
          jobId,
        },
      })
    } catch (error) {
      enqueueSnackbar(`Failed to create document: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
    // eslint-disable-next-line no-empty,@typescript-eslint/no-unused-vars
    for await (const _ of pollPageAsyncBatch(client, batchId)) {
    }
    await refetchJob()
  }

  const operators = jobOperatorData?.jobOperators || []

  const changeJobOwner = async (currOperatorId: string): Promise<void> => {
    await setJobOwner({
      variables: {
        jobId: jobData!.job!.id,
        ownerId: currOperatorId,
      },
    })
    if (setJobOwnerError) {
      enqueueSnackbar(`Failed to change job owner: ${setJobOwnerError}`, { variant: 'error' })
      return
    }
    setOwnerId(currOperatorId)
    enqueueSnackbar('Owner successfully updated', { variant: 'success' })
  }

  const isSoa = jobData?.job?.jobTemplate.reconType === JobTemplateReconType.Soa

  return (
    <div ref={pageCheckerRef} className={classes.pageCheckerWrapper}>
      {jobData && activeDocumentTable && (
        <SpreadsheetDataGrid
          job={jobData.job!}
          setIsUpdateChargeQuantityDialogOpen={
            !isSoa ? setIsUpdateChargeQuantityDialogOpen : undefined
          }
        />
      )}
      {isUpdateChargeQuantityDialogOpen && (
        <UpdateChargeQuantityDialog
          jobId={jobData!.job!.id}
          isOpen={isUpdateChargeQuantityDialogOpen}
          close={() => setIsUpdateChargeQuantityDialogOpen(false)}
        />
      )}
      <div
        className={clsx({
          [classes.hideShipmentForm]: activeDocumentTable,
          [classes.showShipmentForm]: !activeDocumentTable,
        })}
      >
        {readOnly ? (
          <TextField
            value={filePage?.document?.documentType?.name || ''}
            margin='normal'
            variant='outlined'
            InputProps={{ inputProps: { readOnly: true } }}
          />
        ) : (
          <Box display='flex' flexDirection='column' p={1}>
            <FormControl variant='outlined' fullWidth margin='normal'>
              <DocumentTypeAutocomplete
                documentTypeId={documentTypeId}
                documentTypes={documentTypes}
                onChangeHandler={async (newDocumentTypeId) => {
                  if (newDocumentTypeId === documentTypeId) return
                  setIsDocumentLoading(true)
                  try {
                    await maybeCreateDocumentWithType(newDocumentTypeId)
                  } finally {
                    setIsDocumentLoading(false)
                    void logEvent(LogEventType.CHANGE_DOCUMENT_TYPE, {
                      job_id: jobId,
                      page_id: filePageId,
                    })
                  }
                }}
                dataTestId='document-type-select'
              />
            </FormControl>
            <FormControl variant='outlined' fullWidth margin='normal'>
              <InputLabel>Job Owner</InputLabel>
              <Select
                value={ownerId || ''}
                onChange={(e) => changeJobOwner(e.target.value as string)}
                label='Job Owner'
                disabled={isLoadingOperators}
              >
                {operators?.map((currOperator: UserNode) => (
                  <MenuItem key={currOperator.id} value={currOperator.id}>
                    {currOperator.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {actionSet === ActionSet.QA && (
              <FormControl variant='outlined' fullWidth margin='normal'>
                <TextField
                  value={jobOtherInfo}
                  onChange={(e) => setjobOtherInfo(e.target.value)}
                  label='QA: Other Info'
                  variant='outlined'
                  multiline
                  rows={3}
                />
              </FormControl>
            )}
          </Box>
        )}
        {isDocumentLoading || jobLoading || !documentEditorInitialized ? (
          <Box display='flex' alignItems='center' justifyContent='center' flexGrow={1}>
            <CenteredCircularProgress />
          </Box>
        ) : (
          <ShipmentForm
            filePageId={filePageId}
            refetchJob={refetchJobCallback}
            readOnly={readOnly}
            actionSet={actionSet}
            jobOtherInfo={jobOtherInfo}
            job={jobData!.job!}
            data-testid='shipment-form'
          />
        )}
      </div>
    </div>
  )
}

export default PageChecker
