// TODO: copy-pasted to web/ -- should use shared library instead. Be sure to replicate changes in both places
import { z } from 'zod'

const FindModelCriteriaSchema = z
  .object({
    referenceNo: z.string().nullish(),
    carrierBookingNo: z.string().nullish(),
    containerNo: z.string().nullish(),
    mblNo: z.string().nullish(),
    hblNo: z.string().nullish(),
    consolNo: z.string().nullish(),
    orderNo: z.string().nullish(),
  })
  .partial()

export type FindModelCriteria = z.infer<typeof FindModelCriteriaSchema>

/**
 * Returns whether a contains b (ie the criteria in b is a subset of a)
 */
export function findModelCriteriaContains(a: FindModelCriteria, b: FindModelCriteria): boolean {
  for (const key of Object.keys(FindModelCriteriaSchema.shape)) {
    if (
      b[key as keyof FindModelCriteria] &&
      (a[key as keyof FindModelCriteria] ?? '') !== (b[key as keyof FindModelCriteria] ?? '')
    )
      return false
  }
  return true
}

export const getAllFindModelCriteria = (
  findShipmentResults: FindModelCriteria[],
): FindModelCriteria => {
  const findModelCriteria: FindModelCriteria = {}
  findShipmentResults?.forEach((reconResult) => {
    if (reconResult.referenceNo) findModelCriteria.referenceNo = reconResult.referenceNo
    if (reconResult.carrierBookingNo)
      findModelCriteria.carrierBookingNo = reconResult.carrierBookingNo
    if (reconResult.containerNo) findModelCriteria.containerNo = reconResult.containerNo
    if (reconResult.mblNo) findModelCriteria.mblNo = reconResult.mblNo
    if (reconResult.hblNo) findModelCriteria.hblNo = reconResult.hblNo
    if (reconResult.consolNo) findModelCriteria.consolNo = reconResult.consolNo
    if (reconResult.orderNo) findModelCriteria.orderNo = reconResult.orderNo
  })
  return findModelCriteria
} // for grouping successful find shipment recon results
export const stringifyFindModelCriteria = (findModelCriteria: FindModelCriteria): string => {
  return JSON.stringify([
    findModelCriteria.referenceNo ?? '',
    findModelCriteria.hblNo ?? '',
    findModelCriteria.mblNo ?? '',
    findModelCriteria.consolNo ?? '',
    findModelCriteria.containerNo ?? '',
    findModelCriteria.carrierBookingNo ?? '',
    findModelCriteria.orderNo ?? '',
  ])
}

/**
 * Within the given set of FindModelCriteria, get the maximal criteria (ie the criteria that
 * is not contained by any other criteria in the set). If there are duplicates of the maximal criteria,
 * then all duplicates are returned.
 */
export function getMaximalFindModelCriteria<T extends FindModelCriteria>(
  findModelCriteriaItems: T[],
): T[] {
  return findModelCriteriaItems.filter(
    (a) =>
      !findModelCriteriaItems.some(
        (b) =>
          findModelCriteriaContains(b, a) &&
          stringifyFindModelCriteria(a) !== stringifyFindModelCriteria(b),
      ),
  )
}
