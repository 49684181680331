// currently used to determine the formatting of "find shipment results" according to the action taken by the user
export enum CargowiseOpsType {
  Reconcile = 'RECONCILE',
  SendToCW = 'SEND_TO_CW',
  PostToCW = 'POST_TO_CW',
  PushEDocs = 'PUSH_EDOCS',
  PullEDocs = 'PULL_EDOCS',
  SOAReconcile = 'RECONCILE_SOA',
  SOACheckShipmentInfo = 'SOA_CHECK_SHIPMENT_INFO',
}

export const checkIfValidUUID = (uuid: string | null): boolean => {
  if (uuid === null) {
    return false
  }
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
  return regexExp.test(uuid)
}
