import { FunctionComponent } from 'react'
import { Box, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import theme from '@src/utils/theme'
import RoundedButton from '@src/components/RoundedButton'

type Props = {
  cancel: () => void
  create: () => void
  disabled: boolean
}

const useStyles = makeStyles<Theme>({
  cancelButton: {
    background: theme.palette.error.contrastText,
    color: theme.palette.error.light,
    border: `1px solid ${theme.palette.error.light}`,
  },
})

const CreateButtons: FunctionComponent<Props> = ({ cancel, create, disabled }) => {
  const classes = useStyles()
  return (
    <Box textAlign='right' pr={2} data-testid='create-buttons'>
      <RoundedButton variant='contained' onClick={cancel} className={classes.cancelButton}>
        Cancel
      </RoundedButton>
      <RoundedButton variant='contained' onClick={create} color='primary' disabled={disabled}>
        Create
      </RoundedButton>
    </Box>
  )
}

export default CreateButtons
