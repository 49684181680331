import { Box, Container, Typography } from '@material-ui/core'
import { FunctionComponent, useMemo, useState } from 'react'
import CompanySelect from '../utils/CompanySelect'
import {
  ApiPartnerInterface,
  CargowiseConfigNode,
  CompanyNode,
  CwConnectorType,
  useAllCompaniesQuery,
} from '@src/graphql/types'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { isCargowiseConfig } from '@src/utils/api_partner'
import EAdaptorColdStart from './EAdaptorColdStart'
import DatabaseColdStart from './DatabaseColdStart'

const ColdStartPage: FunctionComponent = () => {
  const [selectedCompany, setSelectedCompany] = useState(null as null | CompanyNode)
  const [selectedApiPartners, setSelectedApiPartners] = useState(
    null as null | ApiPartnerInterface[],
  )

  const { data: companiesData, loading } = useAllCompaniesQuery()

  const enabledCompanies = useMemo(() => {
    return companiesData?.allCompanies ?? []
  }, [companiesData])

  const cargowiseConfigs = useMemo(() => {
    return (
      enabledCompanies
        .flatMap((company) =>
          company.apiPartners.edges.map(({ node }) => node as ApiPartnerInterface),
        )
        .filter((apiPartner) => isCargowiseConfig(apiPartner)) ?? []
    )
  }, [enabledCompanies])

  const selectedCargowiseConfig = useMemo(
    () =>
      selectedApiPartners?.length === 1 ? (selectedApiPartners?.[0] as CargowiseConfigNode) : null,
    [selectedApiPartners],
  )

  if (loading) return <CenteredCircularProgress />

  return (
    <Container>
      <Box py={4}>
        <Typography variant='h2' gutterBottom>
          Cold Start
        </Typography>
        <Typography gutterBottom>
          This page is still experimental. We can perform a cold-start here for both eAdaptor and
          db-type configs.
        </Typography>
        <Box py={2}>
          <CompanySelect
            enabledCompanies={enabledCompanies as CompanyNode[]}
            enabledApiPartners={cargowiseConfigs}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            selectedApiPartners={selectedApiPartners}
            setSelectedApiPartners={setSelectedApiPartners}
            singleApiPartner={true}
          />
        </Box>
      </Box>
      {selectedCargowiseConfig?.connectorType === CwConnectorType.Eadaptor ? (
        <EAdaptorColdStart cargowiseConfig={selectedCargowiseConfig} />
      ) : selectedCargowiseConfig?.connectorType === CwConnectorType.Db ? (
        <DatabaseColdStart cargowiseConfig={selectedCargowiseConfig} />
      ) : (
        selectedCargowiseConfig && <Box>This API Partner type is currently unsupported.</Box>
      )}
    </Container>
  )
}

export default ColdStartPage
