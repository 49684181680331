import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Box, MenuItem, Paper, Select, Theme, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import theme from '@src/utils/theme'
import { useMutation, useQuery } from '@apollo/client'
import { COLORS } from '@src/utils/app_constants'
import {
  InputChargeCodeCsvRow,
  InputVendorCsvRow,
  Mutation,
  Query,
  MutationUpsertSeperatedChargeVendorsChargeCodesArgs,
  QueryChargeVendorsOkArgs,
  QueryChargeCodesOkArgs,
  ChargeCodeUploadAsyncTaskNode,
  ChargeCodeUploadAsyncStatus,
  QueryVendorChargeCodeUrlArgs,
  QueryChargeCodeUploadAsyncTaskArgs,
} from '@src/graphql/types'
import { useSnackbar } from 'notistack'
import CsvDropzone from '@src/components/dropzone/CsvDropzone'
import { parseCsvString } from '@src/utils/csv'
import { base64ToArrayBuffer, toBase64 } from '@src/utils/file'
import {
  VALIDATE_CHARGE_VENDORS,
  VALIDATE_CHARGE_CODES,
  CHARGE_CODE_ASYNC_TASK,
} from '@src/graphql/queries/recon'
import { COMPANY_DATA } from '@src/graphql/queries/company'
import {
  UPLOAD_VENDOR_CODE_FILE_URL,
  UPSERT_SEPERATED_CHARGE_VENDORS_CHARGE_CODES,
} from '@src/graphql/mutations/recon'
import { useParams } from 'react-router'
import ImportProgressBar from '@src/components/admin/import/ImportProgressBar'
import AdminBreadcrumbs from '@src/components/admin/admin-breadcrumbs/AdminBreadcrumbs'

const useStyles = makeStyles<Theme>({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(2),
  },
  addButton: {
    width: '10%',
    marginLeft: '90%',
  },
  sampleLink: {
    color: COLORS.MEDIUM_TURQUOISE,
    underline: 'none',
  },
  uploadDescription: {
    outline: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    // arbitrary, derived from spec
    width: '65%',
  },
  listItem: {
    color: theme.palette.grey[500],
  },
  companyNamePaper: {
    marginBottom: theme.spacing(1),
  },
  cargowiseConfigSelector: {
    padding: theme.spacing(2),
  },
})

type Props = {
  initialChargeCodeUploadTaskId: string | null
}

type VendorCsv = [string, string, string][]
type CodeCsv = [string, string, string][]

const ImportPage: FunctionComponent<Props> = ({ initialChargeCodeUploadTaskId }) => {
  const classes = useStyles()
  const [vendorRows, setVendorRows] = useState([] as VendorCsv)
  const [codeRows, setCodeRows] = useState([] as CodeCsv)
  const [vendorCsvFile, setVendorCsvFile] = useState(null as File | null)
  const [chargeCodeCsvFile, setChargeCodeCsvFile] = useState(null as File | null)
  const [intervalID, setIntervalID] = useState(null as null | number)
  const [chargeCodeUploadAsyncTask, setChargeCodeUploadAsyncTask] = useState(
    null as null | ChargeCodeUploadAsyncTaskNode,
  )
  const { companyId } = useParams<{ companyId: string }>()
  const [selectedApiPartnerIds, setSelectedApiPartnerIds] = useState([] as string[])
  const pollDelay = 2000
  const noConfigsAndPartnersSet = !selectedApiPartnerIds.length
  const noVendorsCodesConfigsFound =
    codeRows.length === 0 || vendorRows.length === 0 || noConfigsAndPartnersSet

  const [upsertSeperatedChargeVendorsChargeCodes] = useMutation<
    Pick<Mutation, 'upsertSeperatedChargeVendorsChargeCodes'>,
    MutationUpsertSeperatedChargeVendorsChargeCodesArgs
  >(UPSERT_SEPERATED_CHARGE_VENDORS_CHARGE_CODES)

  const { refetch: uploadVendorChargeCodeFileUrl } = useQuery<
    Pick<Query, 'vendorChargeCodeUrl'>,
    QueryVendorChargeCodeUrlArgs
  >(UPLOAD_VENDOR_CODE_FILE_URL, {
    skip: true,
  })

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { refetch: validateChargeVendors } = useQuery<
    Pick<Query, 'chargeVendorsOk'>,
    QueryChargeVendorsOkArgs
  >(VALIDATE_CHARGE_VENDORS, {
    skip: true,
  })

  const { refetch: validateChargeCodes } = useQuery<
    Pick<Query, 'chargeCodesOk'>,
    QueryChargeCodesOkArgs
  >(VALIDATE_CHARGE_CODES, {
    skip: true,
  })

  const { refetch: fetchChargeCodeAsyncTask } = useQuery<
    Pick<Query, 'chargeCodeUploadAsyncTask'>,
    QueryChargeCodeUploadAsyncTaskArgs
  >(CHARGE_CODE_ASYNC_TASK, {
    skip: true,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (initialChargeCodeUploadTaskId && !chargeCodeUploadAsyncTask) {
      void fetchChargeCodeAsyncTaskCallback(initialChargeCodeUploadTaskId)
    }
  })

  const { data: companyData } = useQuery<Pick<Query, 'company'>>(COMPANY_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      id: companyId,
    },
  })
  const apiPartners = companyData?.company.apiPartners.edges.map((edge) => edge.node)

  const validateVendorRows = async (vendorRows: string[][]): Promise<boolean> => {
    try {
      // basic check to make sure we're not swapping CSVs (jeff bombed our DB with this once)
      if (vendorRows[0][0] !== 'vendor_code') {
        throw new Error('Invalid CSV format: Make sure vendor_code is the text in the first cell')
      }
      const chargeVendors = [] as InputVendorCsvRow[]
      vendorRows.forEach(([vendorCode, vendorName], idx) => {
        if (!vendorCode) {
          throw new Error(
            `Blank vendor code at row: ${
              idx + 1
            }. Make sure the vendor code is valid and try again.`,
          )
        } else if (!vendorName) {
          throw new Error(
            `Blank vendor name at row: ${
              idx + 1
            }. Make sure the vendor name is valid and try again.`,
          )
        }
        chargeVendors.push({
          vendorCode,
          vendorName,
        })
      })
      const rowsOk = await validateChargeVendors({
        companyId,
        chargeVendors: { rows: chargeVendors },
      })
      return rowsOk.data.chargeVendorsOk as boolean
    } catch (error) {
      enqueueSnackbar(`Failed to validate vendor rows: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
      setVendorRows([])
      setVendorCsvFile(null)
      return false
    }
  }

  const parseVendorCsv = async (file: File): Promise<void> => {
    // close prior failure snackbars
    closeSnackbar()
    const newVendorRows = await parseCsvString(await file.text())
    // don't explode on empty csv
    if (newVendorRows.length === 0) {
      return
    }
    if (await validateVendorRows(newVendorRows)) {
      const headerRow = newVendorRows[0]
      if (headerRow[0] === 'vendor_code') {
        const rowsWithoutHeader = newVendorRows.slice(1)
        setVendorRows(rowsWithoutHeader as VendorCsv)
      } else {
        setVendorRows(newVendorRows as VendorCsv)
      }
    }
  }

  const validateChargeRows = async (chargeRows: string[][]): Promise<boolean> => {
    try {
      if (chargeRows[0][0] !== 'charge_code') {
        throw new Error('Invalid CSV format: Make sure charge_code is the text in the first cell')
      }
      const chargeCodesCsv = [] as InputChargeCodeCsvRow[]
      chargeRows.forEach(([chargeCode, chargeDescription], idx) => {
        if (!chargeCode) {
          throw new Error(
            `Blank charge code at row: ${
              idx + 1
            }. Make sure the charge code is valid and try again.`,
          )
        } else if (!chargeDescription) {
          throw new Error(
            `Blank charge description at row: ${
              idx + 1
            }. Make sure the charge description is valid and try again.`,
          )
        }
        chargeCodesCsv.push({
          chargeCode,
          chargeDescription,
        })
      })
      const rowsOk = await validateChargeCodes({
        companyId,
        apiPartnerIds: selectedApiPartnerIds,
        chargeCodesCsv: { rows: chargeCodesCsv },
      })
      return rowsOk.data.chargeCodesOk as boolean
    } catch (error) {
      setCodeRows([])
      setChargeCodeCsvFile(null)
      enqueueSnackbar(`Failed to validate charge rows: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
      return false
    }
  }

  const parseChargeCodeCsv = async (file: File): Promise<void> => {
    // close prior failure snackbars
    closeSnackbar()
    const newCodeRows = await parseCsvString(await file.text())
    if (newCodeRows.length === 0) {
      return
    }
    if (await validateChargeRows(newCodeRows)) {
      const headerRow = newCodeRows[0]
      if (headerRow[0] === 'charge_code') {
        const rowsWithoutHeader = newCodeRows.slice(1)
        setCodeRows(rowsWithoutHeader as CodeCsv)
      } else {
        setCodeRows(newCodeRows as CodeCsv)
      }
    }
  }

  const fetchChargeCodeAsyncTaskCallback = useCallback(
    async (chargeCodeAsyncTaskId: string): Promise<void> => {
      try {
        const asyncTaskResp = await fetchChargeCodeAsyncTask({
          chargeCodeAsyncTaskId,
        })
        setChargeCodeUploadAsyncTask(asyncTaskResp!.data!.chargeCodeUploadAsyncTask!)
      } catch (error) {
        enqueueSnackbar(
          `Failed to fetch charge code async task: ${formatMaybeApolloError(error)}`,
          {
            variant: 'error',
          },
        )
      }
    },
    [setChargeCodeUploadAsyncTask, fetchChargeCodeAsyncTask, enqueueSnackbar],
  )

  const uploadCsvFile = async (file: File): Promise<string> => {
    const signedUrl = await uploadVendorChargeCodeFileUrl({
      filename: file!.name,
    })
    const base64File = await toBase64(file!)
    // text/csv or application/vnd.ms-excel
    const mimeTypePattern = /^data:(\w+\/\w+|application\/vnd.ms-excel);base64,/
    const fileBytes = base64ToArrayBuffer(base64File.replace(mimeTypePattern, ''))
    await fetch(signedUrl!.data!.vendorChargeCodeUrl!.putUrl!, {
      method: 'PUT',
      headers: { 'Content-Type': 'text/csv' },
      body: fileBytes,
    })
    return signedUrl!.data!.vendorChargeCodeUrl!.viewUrl!
  }

  const sendCodes = async (): Promise<void> => {
    try {
      const chargeVendorsViewUrl = await uploadCsvFile(vendorCsvFile!)
      const chargeCodesViewUrl = await uploadCsvFile(chargeCodeCsvFile!)
      const asyncTaskIdResp = await upsertSeperatedChargeVendorsChargeCodes({
        variables: {
          companyId,
          apiPartnerIds: selectedApiPartnerIds,
          chargeVendorsSignedUrl: chargeVendorsViewUrl!,
          chargeCodesSignedUrl: chargeCodesViewUrl!,
        },
      })
      const chargeCodeAsyncTaskId =
        asyncTaskIdResp!.data!.upsertSeperatedChargeVendorsChargeCodes!.chargeCodeUploadAsyncTaskId!
      await fetchChargeCodeAsyncTaskCallback(chargeCodeAsyncTaskId)
      window.history.pushState(
        {},
        '',
        `/admin/company/${companyId}/import/${chargeCodeAsyncTaskId}`,
      )
    } catch (error) {
      enqueueSnackbar(`Error saving charge codes and vendors: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
  }

  const selectApiPartners = (e: React.ChangeEvent<Record<string, unknown>>): void => {
    if (typeof e.target.value === 'string') {
      setSelectedApiPartnerIds(e.target.value.split(','))
    } else {
      setSelectedApiPartnerIds(e.target.value as string[])
    }
  }

  useEffect(() => {
    return () => {
      if (intervalID) {
        window.clearInterval(intervalID)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (chargeCodeUploadAsyncTask?.status === ChargeCodeUploadAsyncStatus.Pending && !intervalID) {
      const intervalID = window.setInterval(() => {
        void fetchChargeCodeAsyncTaskCallback(chargeCodeUploadAsyncTask.id)
      }, pollDelay)
      setIntervalID(intervalID)
    } else if (
      chargeCodeUploadAsyncTask?.status !== ChargeCodeUploadAsyncStatus.Pending &&
      intervalID
    ) {
      window.clearInterval(intervalID!)
    }
  }, [chargeCodeUploadAsyncTask, intervalID, fetchChargeCodeAsyncTaskCallback])

  const uploadPending =
    !!chargeCodeUploadAsyncTask &&
    chargeCodeUploadAsyncTask.status === ChargeCodeUploadAsyncStatus.Pending

  return (
    <>
      <Paper className={classes.companyNamePaper}>
        <AdminBreadcrumbs
          crumbs={[
            { path: '/admin/company', name: 'Companies' },
            { path: `/admin/company/${companyId}`, name: `${companyData?.company?.name || ''}` },
            { path: `/admin/company/${companyId}`, name: `Import vendor and charge codes` },
          ]}
          entityName={'Import vendor and charge codes'}
          entityType=''
          onChangeName={undefined}
        />
      </Paper>
      <Box m={1} display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant='h3' className={classes.title}>
          Import Vendors and Charge Codes
        </Typography>
      </Box>
      <Box width='50%' p={4}>
        <Paper className={classes.cargowiseConfigSelector}>
          <Typography variant='h4'>Select TMS(s) to import to:</Typography>
          <Select
            id='import-charge-codes-api-partner'
            value={selectedApiPartnerIds}
            onChange={selectApiPartners}
            multiple
            inputProps={{ style: { textTransform: 'uppercase' } }}
            fullWidth
          >
            {!apiPartners?.length ? (
              <MenuItem value={''}>N/A</MenuItem>
            ) : (
              apiPartners.map((partner) => {
                return (
                  <MenuItem key={partner.id} value={partner.id}>
                    {partner.name}
                  </MenuItem>
                )
              })
            )}
          </Select>
        </Paper>
      </Box>
      {initialChargeCodeUploadTaskId && !chargeCodeUploadAsyncTask && (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Typography variant='h5' className={classes.title}>
            Loading import status...
          </Typography>
        </Box>
      )}
      {chargeCodeUploadAsyncTask && (
        <ImportProgressBar chargeCodeUploadAsyncTask={chargeCodeUploadAsyncTask} />
      )}
      <Box display='flex' flexDirection='column' height='25%' py={1}>
        <div className={classes.uploadDescription}>
          <Typography variant='h5'>Vendor Codes</Typography>
          <Typography variant='h6'>Upload Cleaned CSV:</Typography>
          <ul>
            <li>
              <Typography className={classes.listItem}>No duplicates</Typography>
            </li>
            <li>
              <Typography className={classes.listItem}>
                Important columns: Code, Description, Vendor Type, Tax ID (when applicable)
              </Typography>
            </li>
            <li>
              <a
                target='_blank'
                rel='noreferrer'
                className={classes.sampleLink}
                href={`https://docs.google.com/spreadsheets/d/1sC5gVFIOvFvSJ4I9xdrZ5_8MIGtpV4K3B7HNT5tGrHM/edit#gid=0`}
              >
                View sample file, for guidance
              </a>
            </li>
          </ul>
        </div>
        <CsvDropzone
          dropzoneTitle='Charge Vendors'
          file={vendorCsvFile}
          setFile={async (file: File | null) => {
            if (!file) {
              return
            }
            setVendorCsvFile(file)
            await parseVendorCsv(file)
          }}
        />
      </Box>
      <Box display='flex' flexDirection='column' height='25%' paddingBottom={theme.spacing(1)}>
        <div className={classes.uploadDescription}>
          <Typography variant='h5'>Charge Codes</Typography>
          <Typography variant='h6'>Upload Cleaned CSV:</Typography>
          <ul>
            <li>
              <Typography className={classes.listItem}>No duplicates</Typography>
            </li>
            <li>
              <Typography className={classes.listItem}>
                Important columns: Code, Description
              </Typography>
            </li>
            <li>
              <a
                target='_blank'
                rel='noreferrer'
                className={classes.sampleLink}
                href={`https://docs.google.com/spreadsheets/d/17MBgHid3eZYN9nbMlSPJXuvlx-R6f1gNX1ChSsyiOKc/edit#gid=0`}
              >
                View sample file, for guidance
              </a>
            </li>
          </ul>
        </div>
        <CsvDropzone
          dropzoneTitle='Charge Codes'
          file={chargeCodeCsvFile}
          setFile={async (file: File | null) => {
            if (!file) {
              return
            }
            if (noConfigsAndPartnersSet) {
              enqueueSnackbar(
                'Please select Cargowise Configs or Api Partners above before uploading charge codes',
                { variant: 'error' },
              )
              return
            }
            setChargeCodeCsvFile(file)
            await parseChargeCodeCsv(file)
          }}
        />
      </Box>
      <Button
        variant='contained'
        color='primary'
        disabled={noVendorsCodesConfigsFound || uploadPending}
        className={classes.addButton}
        onClick={sendCodes}
      >
        Add Vendors and Charge Codes
      </Button>
    </>
  )
}

export default ImportPage
