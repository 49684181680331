import { FunctionComponent } from 'react'
import {
  Paper,
  TextField,
  Theme,
  Typography,
  InputAdornment,
  Box,
  Select,
  MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import theme from '@src/utils/theme'
import { ApiPartnerInterface } from '@src/graphql/types'

type Props = {
  apiPartners: ApiPartnerInterface[]
  currApiPartnerId: string | null
  setCurrApiPartnerId: (id: string | null) => void
  currTaxDetails?: Record<string, string | number | undefined | null>
  setCurrTaxDetails: (details: Record<string, string | number | undefined | null>) => void
}

// general pixel approximations from the spec
// of the cell size. Not derived from anywhere in particular.
const metadataCellWidth = `150px`
const metadataInputWidth = '300px'

const useStyles = makeStyles<Theme>({
  descriptionCellTop: {
    color: theme.palette.grey[700],
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    borderRight: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(1),
    width: metadataCellWidth,
  },
  tableCellTop: {
    color: theme.palette.grey[700],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: metadataCellWidth,
  },
  input: {
    paddingTop: theme.spacing(2),
    width: metadataInputWidth,
  },
  table: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  apiPartnerSelector: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    width: metadataInputWidth,
  },
})

const TaxMetadataTable: FunctionComponent<Props> = ({
  apiPartners,
  currApiPartnerId,
  setCurrApiPartnerId,
  currTaxDetails,
  setCurrTaxDetails,
}) => {
  const classes = useStyles()
  const tableRowHeader = {
    taxCode: 'Tax Code',
    taxRate: 'Tax Rate',
    taxType: 'Tax Type',
    taxSystem: 'Tax System',
  } as Record<string, string>
  const updateDetails = (key: string, e: React.ChangeEvent<{ value: string }>): void => {
    e.preventDefault()
    const updatedTaxDetails = { ...currTaxDetails }
    updatedTaxDetails[key] = e.target.value.toUpperCase()
    setCurrTaxDetails(updatedTaxDetails)
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const updateCurrApiPartnerId = (e: React.ChangeEvent<any>): void => {
    setCurrApiPartnerId(e.target.value)
  }

  return (
    <Box display='flex' justifyContent='space-between'>
      <Paper className={classes.table}>
        <table>
          <tbody>
            {Object.keys(currTaxDetails!).map((key) => (
              <tr key={key}>
                <td className={classes.descriptionCellTop}>
                  <Typography>{tableRowHeader[key]}</Typography>
                </td>
                <td className={classes.tableCellTop}>
                  <TextField
                    onChange={(e) => updateDetails(key, e)}
                    value={currTaxDetails![key] ?? ''}
                    className={classes.input}
                    id={key}
                    InputProps={{
                      style: { textTransform: 'uppercase' },
                      endAdornment: (
                        <>
                          {' '}
                          {key === 'taxRate' && (
                            <InputAdornment position='end'>%</InputAdornment>
                          )}{' '}
                        </>
                      ),
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Paper>
      <Box pt={2} pr={4}>
        <Paper className={classes.apiPartnerSelector}>
          <Typography variant='caption'>Filter by TMS</Typography>
          <Select
            value={currApiPartnerId}
            onChange={updateCurrApiPartnerId}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            data-testid='api-partner-selector'
            fullWidth
          >
            {!apiPartners?.length ? (
              <MenuItem value={''}>N/A</MenuItem>
            ) : (
              apiPartners.map((partner) => {
                return (
                  <MenuItem key={partner.id} value={partner.id}>
                    {partner.name}
                  </MenuItem>
                )
              })
            )}
          </Select>
        </Paper>
      </Box>
    </Box>
  )
}

export default TaxMetadataTable
