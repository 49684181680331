import { useEffect } from 'react'
import { initializeRollbar } from '@src/utils/observability/rollbar/index'
import { getDeployEnvironment } from '@src/utils/environment'
import { useAuth0 } from '@auth0/auth0-react'

const useRollbar = (): void => {
  const deployEnv = getDeployEnvironment()
  const { user } = useAuth0()

  useEffect(() => {
    initializeRollbar(user, deployEnv)
  }, [user, deployEnv])
}

export default useRollbar
