import { BoxDimension, FieldCoordinates } from '@src/types/ocr'
import { hasDimension } from '@src/utils/magic_grid'
import {
  DocumentFieldGroupNode,
  DocumentNode,
  DocumentTableColumnNode,
  DocumentTableNode,
  InputDocumentTableSkeleton,
  InputRowSkeleton,
  InputTableColumn,
  Maybe,
} from '@src/graphql/types'
import { LINE_ITEM_ID_KEY_SEPARATOR } from './app_constants'
import { LineItem } from './line_items'

export const getNonRepeatableFieldCoordinates = (
  documentFieldGroups: DocumentFieldGroupNode[],
): FieldCoordinates => {
  return Object.fromEntries(
    documentFieldGroups.flatMap((documentFieldGroup) => {
      if (documentFieldGroup.fieldGroup!.repeatable) {
        return []
      }
      return documentFieldGroup
        .documentFields!.edges.map((edge) => edge!.node!)
        .filter(hasDimension)
        .map(
          ({ field, top, left, height, width }) =>
            [field?.key, { top, left, height, width }] as [string, BoxDimension],
        )
    }),
  )
}

export const filterLineItemFieldCoordinates = (
  fieldCoordinates: Record<string, BoxDimension>,
): FieldCoordinates => {
  const nonRepeatFieldCoords: Record<string, BoxDimension> = {}
  for (const key of Object.keys(fieldCoordinates)) {
    if (!key.includes(LINE_ITEM_ID_KEY_SEPARATOR)) {
      nonRepeatFieldCoords[key] = fieldCoordinates[key]
    }
  }
  return nonRepeatFieldCoords
}

export const getDocumentTableColumns = (
  document: Maybe<DocumentNode>,
): DocumentTableColumnNode[] => {
  return (
    document?.documentTables?.edges[0]?.node?.documentTableColumns!.edges.map(
      (edge) => edge!.node!,
    ) ?? []
  )
}

export const getDocumentTableSkeleton = (
  documentTable: DocumentTableNode,
): InputDocumentTableSkeleton => {
  const rows: InputRowSkeleton[] =
    documentTable.documentFieldGroups?.edges.map((documentFieldGroupEdge) => ({
      lineItems:
        documentFieldGroupEdge?.node?.documentFields?.edges.map(
          (documentFieldEdge) =>
            ({
              key: documentFieldEdge!.node!.field!.key,
              dimension: {
                top: documentFieldEdge!.node!.top,
                left: documentFieldEdge!.node!.left,
                width: documentFieldEdge!.node!.width,
                height: documentFieldEdge!.node!.height,
              },
            }) || [],
        ) || [],
    })) || []

  const tableColumns: InputTableColumn[] =
    documentTable.documentTableColumns?.edges.map((documentTableColumnEdge) => ({
      id: documentTableColumnEdge!.node!.field!.id,
      key: documentTableColumnEdge!.node!.field!.key,
      dimension: {
        top: documentTableColumnEdge!.node!.top,
        left: documentTableColumnEdge!.node!.left,
        width: documentTableColumnEdge!.node!.width,
        height: documentTableColumnEdge!.node!.height,
      },
    })) || []

  return {
    id: documentTable.id,
    fieldGroupKey: documentTable.fieldGroup!.key,
    fieldGroupId: documentTable.fieldGroup!.id,
    rows,
    tableColumns,
  }
}

export const convertLineItemsToRowSkeleton = (lineItems: LineItem[]): InputRowSkeleton[] =>
  lineItems.map((lineItem) => ({
    lineItems: Object.keys(lineItem.fieldMapping).map((fieldKey) => {
      const field = lineItem.fieldMapping[fieldKey]!
      return {
        key: fieldKey,
        dimension: {
          top: field.top,
          left: field.left,
          width: field.width,
          height: field.height,
        },
      }
    }),
  }))
