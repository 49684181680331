import { FunctionComponent } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import ExportButton from '@src/components/export-button/ExportButton'

const useStyles = makeStyles({
  wrapper: {
    padding: theme.spacing(2),
  },
  doneText: {
    padding: theme.spacing(3),
  },
})

type Props = {
  jobId: string
}

const DoneSidebar: FunctionComponent<Props> = ({ jobId }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.doneText}>
        This job is done and is in a read-only state.
      </Typography>
      <ExportButton jobId={jobId} />
    </div>
  )
}

export default DoneSidebar
