import { FunctionComponent } from 'react'
import {
  Paper,
  TextField,
  Theme,
  Typography,
  Select,
  MenuItem,
  Box,
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import theme from '@src/utils/theme'
import { ApiPartnerInterface, Maybe } from '@src/graphql/types'
import { useEffect } from 'react'
import { MetadataFormField } from './metadata_form'
import { DatabaseRecord } from './record_type'
import { EnumOrFallback, isFallback } from '@src/utils/enum'

type Props = {
  record?: Maybe<DatabaseRecord>
  metadataFormFields: MetadataFormField[]
  createNewRecordMode: boolean
  apiPartners: ApiPartnerInterface[]
  currApiPartnerId?: string | null
  setCurrApiPartnerId?: (apiPartnerId: string | null) => void
}

const useStyles = makeStyles<Theme>({
  fieldValue: {
    color: theme.palette.grey[700],
  },
  fieldName: {
    color: theme.palette.grey[700],
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  apiPartnerSelector: {
    padding: theme.spacing(1),
  },
})

const DatabaseMetadataTable: FunctionComponent<Props> = ({
  record,
  metadataFormFields,
  createNewRecordMode,
  apiPartners,
  currApiPartnerId,
  setCurrApiPartnerId,
}) => {
  const classes = useStyles()

  useEffect(() => {
    if (record) {
      metadataFormFields.forEach((field) => {
        if (field.fieldName in record) {
          const newFieldValue = record[field.fieldName] as unknown

          if ((newFieldValue as EnumOrFallback).isFallback !== undefined) {
            const newFieldValueEnum = newFieldValue as EnumOrFallback
            field.setFieldValue(!isFallback(newFieldValueEnum) ? newFieldValueEnum.value : '')
          } else {
            field.setFieldValue((newFieldValue as string) ?? '')
          }
        } else {
          field.setFieldValue('')
        }
      })
    } else {
      metadataFormFields.forEach((field) => {
        field.setFieldValue('')
      })
    }
  }, [record])

  const changeApiPartner = (e: React.ChangeEvent<Record<string, unknown>>): void => {
    if (setCurrApiPartnerId) {
      const chosenApiPartner = apiPartners.find((partner) => partner.id === e.target.value)
      setCurrApiPartnerId(chosenApiPartner!.id)
    }
  }

  return (
    <Box sx={{ p: 1 }}>
      <Grid container>
        <Grid item md={8} xs={12}>
          <Box sx={{ p: 1 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {metadataFormFields.map((field) => (
                    <TableRow key={field.fieldName}>
                      <TableCell className={classes.fieldName}>
                        <Typography>{field.displayName}</Typography>
                      </TableCell>
                      {field?.options === undefined ? (
                        <TableCell className={classes.fieldValue}>
                          <TextField
                            onChange={(e) => field.setFieldValue(e.target.value)}
                            value={field.fieldValue}
                            fullWidth
                            id='database-name'
                            disabled={field.disabled || (!createNewRecordMode && !record)}
                            error={
                              field.required &&
                              !field.fieldValue &&
                              !field.disabled &&
                              !!(createNewRecordMode || record)
                            }
                          />
                        </TableCell>
                      ) : (
                        <TableCell className={classes.fieldValue}>
                          <Select
                            value={field.fieldValue}
                            onChange={(e) => field.setFieldValue(e.target.value as string)}
                            displayEmpty
                            fullWidth
                            disabled={field.disabled || (!createNewRecordMode && !record)}
                            error={
                              field.required &&
                              !field.fieldValue &&
                              !field.disabled &&
                              !!(createNewRecordMode || record)
                            }
                          >
                            <MenuItem key='N/A' value=''>
                              No selected
                            </MenuItem>
                            {field.options.map((option) => {
                              return (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.display}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        {setCurrApiPartnerId && (
          <Grid item md={4} xs={12}>
            <Box sx={{ p: 1 }}>
              <Paper className={classes.apiPartnerSelector}>
                <Typography variant='caption'>
                  {createNewRecordMode ? 'Select TMS to create database to' : 'Filter by TMS'}
                </Typography>
                <Select
                  value={currApiPartnerId}
                  onChange={changeApiPartner}
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                  fullWidth
                >
                  {!apiPartners?.length ? (
                    <MenuItem value={''}>N/A</MenuItem>
                  ) : (
                    apiPartners.map((partner) => {
                      return (
                        <MenuItem key={partner.id} value={partner.id}>
                          {partner.name}
                        </MenuItem>
                      )
                    })
                  )}
                </Select>
              </Paper>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default DatabaseMetadataTable
