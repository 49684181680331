import { FunctionComponent, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import { clsx } from 'clsx'

import FileSheetsViewer from '@src/components/job-viewer/FileSheetsViewer'
import { FilePageNode, FilePageType, InputEDocument, Maybe } from '@src/graphql/types'
import theme from '@src/utils/theme'
import { formatCwFileType } from '@src/utils/edocs'
import { isPdfOrImage, replaceFileExtension } from '@src/utils/file'

const THUMBNAIL_SIZE_HEIGHT = '100px'
const THUMBNAIL_SIZE_WIDTH = '75px'
const THUMBNAIL_ICON_SIZE = '60px'

const useStyles = makeStyles({
  checkIcon: {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    left: theme.spacing(0.5),
    padding: 0,
  },
  fullViewImageContainer: {
    position: 'relative',
    paddingTop: '66%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[400],
    marginBottom: theme.spacing(2),
  },
  fullViewImage: {
    objectFit: 'contain',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  thumbnail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    objectFit: 'contain',
    position: 'relative',
    height: THUMBNAIL_SIZE_HEIGHT,
    width: THUMBNAIL_SIZE_WIDTH,
    backgroundColor: theme.palette.grey[400],
    cursor: 'pointer',
  },
  thumbnailIcon: {
    fontSize: THUMBNAIL_ICON_SIZE,
  },
  image: {
    objectFit: 'contain',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    cursor: 'pointer',
  },
  warningText: {
    backgroundColor: theme.palette.warning.light,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
  },
  selected: {
    boxShadow: `inset 0px 0px 0px ${theme.spacing(0.5)}px ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
  },
  tableHead: {
    backgroundColor: theme.palette.grey[200],
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  eDocFilename: {
    cursor: 'pointer',
  },
  excelContainer: {
    height: theme.spacing(80),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(100),
    borderLeft: `solid ${theme.palette.grey[300]} 1px`,
  },
  otherFileIcon: {
    fontSize: 100,
  },
})

type Props = {
  inputEDocuments: InputEDocument[]
  filePagesMap: Record<string, FilePageNode>
  toggleEdocIsPublished: (idx: number) => void
  cancelHandler: () => void
  pushEdocsHandler: () => Promise<void>
  disablePushEdocsButton: boolean
  exportToApiPartner: boolean
}

const EDocsPushConfirmTable: FunctionComponent<Props> = ({
  inputEDocuments,
  filePagesMap,
  toggleEdocIsPublished,
  cancelHandler,
  pushEdocsHandler,
  disablePushEdocsButton,
  exportToApiPartner,
}) => {
  const classes = useStyles()
  const [currentFilePage, setCurrentFilePage] = useState(null as Maybe<FilePageNode>)
  const [selectedInputEDocIdx, setSelectedInputEDocIdx] = useState(0)
  const [isAnyFilePageInCw, setIsAnyFilePageInCw] = useState(false)

  useEffect(() => {
    if (!exportToApiPartner) {
      const selectedEdoc = inputEDocuments[selectedInputEDocIdx]
      if (selectedEdoc) {
        const eDocFilePageIsInCw = selectedEdoc.filePageIds.some(
          (filePageId) => filePagesMap[filePageId].isInCw,
        )
        setIsAnyFilePageInCw(eDocFilePageIsInCw)

        const newSelectedFilePageId = selectedEdoc.filePageIds[0]
        setCurrentFilePage(filePagesMap[newSelectedFilePageId])
      }
    }
  }, [exportToApiPartner, filePagesMap, inputEDocuments, selectedInputEDocIdx])

  return (
    <>
      <Box mb={2}>
        <Table size='small'>
          <TableHead classes={{ root: classes.tableHead }}>
            <TableRow>
              {!exportToApiPartner && <TableCell>CW File Type</TableCell>}
              {exportToApiPartner && <TableCell>Doc Type</TableCell>}
              <TableCell>File Name</TableCell>
              <TableCell>Is Published</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inputEDocuments.map((inputEDoc, idx) => {
              const firstFilePageId = inputEDoc.filePageIds[0] as string
              const firstFilePage = filePagesMap[firstFilePageId]
              const cwFileType = firstFilePage!.document!.documentType!.cargowiseFileType!
              const docTypeName = firstFilePage!.document!.documentType!.name
              const firstFilePageFilename = firstFilePage!.file!.filename
              const isPdfOrImageBool = isPdfOrImage(firstFilePageFilename)
              const eDocName = isPdfOrImageBool
                ? replaceFileExtension(firstFilePageFilename, 'pdf')
                : firstFilePageFilename

              return (
                <TableRow key={idx} selected={selectedInputEDocIdx === idx}>
                  {!exportToApiPartner && (
                    <TableCell size='small'>{formatCwFileType(cwFileType)}</TableCell>
                  )}
                  {exportToApiPartner && <TableCell size='small'>{docTypeName}</TableCell>}
                  <TableCell size='small' width='60%'>
                    <Link
                      className={classes.eDocFilename}
                      onClick={() => setSelectedInputEDocIdx(idx)}
                    >
                      <Typography>{eDocName}</Typography>
                    </Link>
                  </TableCell>
                  <TableCell size='small'>
                    <Checkbox
                      size='small'
                      checked={inputEDoc.isPublished}
                      onChange={() => toggleEdocIsPublished(idx)}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Box>

      {isAnyFilePageInCw && !exportToApiPartner && (
        <Box my={2}>
          <Typography className={classes.warningText}>
            This document contains pages that may have been uploaded to CW. Please double check to
            make sure everything is correct before sending them to Cargowise.
          </Typography>
        </Box>
      )}
      <Box display='flex' width='100%' overflow='scroll' py={1} mb={2}>
        {inputEDocuments?.[selectedInputEDocIdx]?.filePageIds.map((filePageId) => {
          const filePage = filePagesMap[filePageId]

          return (
            <Box display='inline-table' key={filePage.id} mr={2}>
              <Box className={classes.thumbnail} onClick={() => setCurrentFilePage(filePage)}>
                {filePage.type === FilePageType.ImagePdf && (
                  <img
                    className={classes.image}
                    src={filePage.imageUrl || ''}
                    alt={`${filePage.file?.filename} page ${filePage.pageNumber + 1}`}
                    width='100%'
                    height='100%'
                  />
                )}
                {filePage.type === FilePageType.Excel && (
                  <TableChartOutlinedIcon className={classes.thumbnailIcon} />
                )}
                {filePage.type === FilePageType.Other && (
                  <InsertDriveFileOutlinedIcon className={classes.thumbnailIcon} />
                )}
                {filePage.isInCw && (
                  <CheckCircleIcon className={classes.checkIcon} fontSize='small' color='primary' />
                )}
                <Box
                  className={clsx({
                    [classes.selected]: filePage.id === currentFilePage?.id,
                  })}
                />
              </Box>
            </Box>
          )
        })}
      </Box>

      {currentFilePage?.type === FilePageType.ImagePdf && (
        <Box className={classes.fullViewImageContainer}>
          <img
            className={classes.fullViewImage}
            src={currentFilePage.imageUrl || ''}
            alt={`${currentFilePage.file?.filename} page ${currentFilePage.pageNumber + 1}`}
            width='100%'
            height='100%'
          />
        </Box>
      )}
      {currentFilePage?.type === FilePageType.Excel && (
        <Box className={classes.excelContainer}>
          <FileSheetsViewer filePage={currentFilePage} />
        </Box>
      )}
      {currentFilePage?.type === FilePageType.Other && (
        <Box
          display='flex'
          height='100%'
          width='100%'
          flex={1}
          flexDirection='column'
          alignItems='center'
          my={5}
        >
          <InsertDriveFileOutlinedIcon className={classes.otherFileIcon} />
          <Typography>File cannot be displayed</Typography>
        </Box>
      )}

      <Box display='flex' alignItems='center' justifyContent='center'>
        <Button className={classes.cancelButton} variant='contained' onClick={cancelHandler}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={pushEdocsHandler}
          disabled={disablePushEdocsButton}
          data-testid='push-edocs-confirm-btn'
        >
          Confirm
        </Button>
      </Box>
    </>
  )
}

export default EDocsPushConfirmTable
