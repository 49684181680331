import { FunctionComponent, ReactNode, useContext, useEffect, useRef } from 'react'
import useResizeAware from 'react-resize-aware'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { MAGIC_GRID_DIMENSIONS, PAGE_DIMENSIONS } from '@src/utils/app_constants'
import Moveable, { OnResize } from 'react-moveable'
import { SidebarContext } from '@src/components/content-with-sidebar/sidebar-context'
import { PageScrollableContext } from '@src/components/page-scrollable-wrapper/page-scrollable-context'

type StyleProps = {
  sidebarWidth: number | string
}

type Props = {
  sidebar: ReactNode
  content: ReactNode
}

const useStyles = makeStyles<Theme, StyleProps>({
  sidebar: {
    position: 'relative',
    // flex grow/shrink 0 so the content is the one growing,
    // flex basis at auto to adapt to the width that's set here and by the resizeable handler
    flex: '0 0 auto',
    overflowX: 'auto',
    // overflowY hidden to avoid the height and offset being misdetected by the resizeable handler
    overflowY: 'hidden',
    width: (props) => props.sidebarWidth,
  },
  sidebarInner: {
    height: '100%',
    // overflowY set to auto here so that we *do* have a vertical scrollbar when necessary
    overflowY: 'auto',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  moveable: {
    zIndex: `${MAGIC_GRID_DIMENSIONS.BASE_Z_INDEX + 1} !important` as unknown as number,
  },
})

const moveableRenderDirections: string[] = []
const moveableOnResize = ({ target: eventTarget, width }: OnResize): void => {
  eventTarget.style.width = `${width}px`
}
const getDefaultSidebarWidth = (isCollapsed: boolean): string =>
  isCollapsed ? PAGE_DIMENSIONS.SIDEBAR_COLLAPSED_WIDTH : PAGE_DIMENSIONS.SIDEBAR_WIDTH

const ContentWithSidebar: FunctionComponent<Props> = ({ sidebar, content }) => {
  const sidebarRef = useRef(null as HTMLElement | null)
  const moveableRef = useRef(null as Moveable | null)
  const [resizeListener, containerSize] = useResizeAware()
  const { isCollapsed } = useContext(SidebarContext)
  const classes = useStyles({ sidebarWidth: getDefaultSidebarWidth(isCollapsed) })
  const setPageScrollable = useContext(PageScrollableContext)

  // updates moveable border whenever sidebar is resized
  useEffect(() => {
    moveableRef?.current?.updateRect()
  }, [containerSize])

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.style.width = getDefaultSidebarWidth(isCollapsed)
    }
  }, [isCollapsed])

  useEffect(() => {
    setPageScrollable(false)
    return () => setPageScrollable(true)
  }, [setPageScrollable])

  return (
    <Box display='flex' alignItems='stretch' overflow='hidden' height='100%'>
      <Moveable
        ref={moveableRef}
        className={classes.moveable}
        target={sidebarRef.current}
        resizable
        renderDirections={moveableRenderDirections}
        edge
        origin={false}
        keepRatio={false}
        onResize={moveableOnResize}
      />
      <Paper elevation={3} ref={sidebarRef} className={classes.sidebar}>
        {resizeListener}
        <div className={classes.sidebarInner}>{sidebar}</div>
      </Paper>
      <div className={classes.content}>{content}</div>
    </Box>
  )
}

export default ContentWithSidebar
