import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useEffect, useState } from 'react'
import { BULK_UPDATE_JOB_STATUS } from '@src/graphql/mutations/job'
import { useSnackbar } from 'notistack'
import {
  JobNode,
  JobStatus,
  ManualProduct,
  Mutation,
  MutationBulkUpdateJobStatusArgs,
  TaskNode,
} from '@src/graphql/types'
import { JOB_STATUS, JOB_STATUS_ORDER } from '@src/utils/app_constants'

import { useMutation } from '@apollo/client'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import TaskDateConfirmedDialog from '@src/components/task-date-confirmed-dialog/TaskDateConfirmedDialog'
import { CircularProgress } from '@material-ui/core'

type Props = {
  job: JobNode
  task: TaskNode | undefined
  refetchJobs: () => unknown
}

const MoveJobStatus: FunctionComponent<Props> = ({ job, refetchJobs, task }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [jobStatusIndex, setJobStatusIndex] = useState(0)
  const [isDateConfirmedDialogOpen, setIsDateConfirmedDialogOpen] = useState(false)
  const jobIsManual = job.manualProduct === ManualProduct.Manual
  const showBackwardArrow = jobIsManual && job.status !== JobStatus.Todo
  const showForwardArrow =
    job.status !== JobStatus.Done &&
    (jobIsManual || (job.status !== JobStatus.Todo && job.status !== JobStatus.InProgress))

  const [bulkUpdateJobStatus, { loading: bulkUpdateJobStatusLoading }] = useMutation<
    Pick<Mutation, 'bulkUpdateJobStatus'>,
    MutationBulkUpdateJobStatusArgs
  >(BULK_UPDATE_JOB_STATUS)

  useEffect(() => {
    const statusIndex = JOB_STATUS_ORDER.findIndex((status) => status === job.status)
    setJobStatusIndex(statusIndex)
  }, [job.status])

  const onBulkUpdateJobStatus = async (
    status: JobStatus,
    validateDateConfirmed = true,
  ): Promise<void> => {
    if ([JobStatus.Done, JobStatus.Confirmation].includes(status) && task!.blocked) {
      enqueueSnackbar(
        `Task ${
          task!.title
        } is currently blocked, please unblock it before moving this job to ${status}`,
        {
          variant: 'error',
        },
      )
      return
    }
    if (status === JobStatus.Done && validateDateConfirmed && task!.dateConfirmed === null) {
      setIsDateConfirmedDialogOpen(true)
      return
    }
    try {
      await bulkUpdateJobStatus({
        variables: { jobIds: [job.id], status: JOB_STATUS[status] },
      })
      enqueueSnackbar('Job status saved', { variant: 'success' })
      refetchJobs()
    } catch (error) {
      enqueueSnackbar(`Failed to update job status: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
  }

  return (
    <Box display='flex' alignItems='center' justifyContent='center'>
      <Box visibility={showBackwardArrow && jobStatusIndex !== 0 ? 'visible' : 'hidden'}>
        <Tooltip title={JOB_STATUS_ORDER[jobStatusIndex - 1]}>
          <IconButton
            disabled={bulkUpdateJobStatusLoading}
            onClick={() => onBulkUpdateJobStatus(JOB_STATUS_ORDER[jobStatusIndex - 1])}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {job.status}
      <Box
        visibility={
          showForwardArrow && jobStatusIndex !== JOB_STATUS_ORDER.length - 1 ? 'visible' : 'hidden'
        }
      >
        <Tooltip title={JOB_STATUS_ORDER[jobStatusIndex + 1]}>
          <IconButton
            disabled={bulkUpdateJobStatusLoading || !task}
            onClick={() => onBulkUpdateJobStatus(JOB_STATUS_ORDER[jobStatusIndex + 1])}
          >
            {task ? <KeyboardArrowRightIcon /> : <CircularProgress />}
          </IconButton>
        </Tooltip>
      </Box>
      {task && (
        <TaskDateConfirmedDialog
          open={isDateConfirmedDialogOpen}
          onClose={() => setIsDateConfirmedDialogOpen(false)}
          task={task}
          confirm={() => onBulkUpdateJobStatus(JobStatus.Done, false)}
        />
      )}
    </Box>
  )
}

export default MoveJobStatus
