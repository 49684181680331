import { formatMaybeApolloError } from '@src/utils/errors'
import { useState, FunctionComponent, useCallback, ChangeEvent, useMemo, useEffect } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { useQuery } from '@apollo/client'
import { Query, TaskNode } from '@src/graphql/types'
import { TASKS_BY_TITLE_REFERENCE_ID } from '@src/graphql/queries/task'
import TextField from '@material-ui/core/TextField'
import { useSnackbar } from 'notistack'
import { debounce } from 'lodash'

type Props = {
  companyId?: string
  onChange: (selectedTask: Pick<TaskNode, 'id' | 'title' | 'taskReferenceId'>) => void
  className?: string
  inputClassName?: string
}

type AutocompleteOption =
  | {
      label: string
      disabled: true
    }
  | {
      id: string
      label: string
      title: string
      taskReferenceId: string
      disabled: false
    }

const cleanQuery = (query: string): string => query.toLowerCase().replace(/[^a-z0-9]+/, '')

/**
 * Search for tasks by title or reference ID
 */
const TaskTitleReferenceIdAutocomplete: FunctionComponent<Props> = ({
  companyId,
  onChange,
  className,
  inputClassName,
}) => {
  const { loading: tasksLoading, refetch: refetchTasks } = useQuery<
    Pick<Query, 'tasksByTitleReferenceId'>
  >(TASKS_BY_TITLE_REFERENCE_ID, { skip: true, fetchPolicy: 'no-cache' })
  const [taskOptions, setTaskOptions] = useState([] as AutocompleteOption[])
  const [selectedOption, setSelectedOption] = useState(null as null | AutocompleteOption)
  const { enqueueSnackbar } = useSnackbar()

  const loadTaskOptions = useCallback(
    async (searchQuery: string | null | undefined) => {
      const minQueryLength = 3
      const cleanedQuery = cleanQuery(searchQuery || '')
      if (cleanedQuery.length >= 1 && cleanedQuery.length < minQueryLength) {
        setTaskOptions([
          {
            label: 'Please type at least 3 alphanumeric characters',
            disabled: true,
          },
        ])
        return
      }
      try {
        const resp = await refetchTasks({ query: searchQuery ?? '', companyId: companyId })
        setTaskOptions(
          resp.data.tasksByTitleReferenceId!.map((task) => ({
            id: task.id,
            label: `${task.title} [ref: ${task.taskReferenceId}]`,
            title: task.title,
            taskReferenceId: task.taskReferenceId,
            disabled: false,
          })),
        )
      } catch (error) {
        enqueueSnackbar(
          `Error encountered while fetching task options: ${formatMaybeApolloError(error)}`,
          {
            variant: 'error',
          },
        )
      }
    },
    [companyId, enqueueSnackbar, refetchTasks],
  )
  useEffect(() => {
    void loadTaskOptions('')
  }, [loadTaskOptions])

  const handleInputChange = useMemo(
    () =>
      debounce(
        // any is literally the type they use :shrug:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (_event: React.ChangeEvent<any>, inputValue: string) => loadTaskOptions(inputValue),
        // generous debounce time to account for slow typers
        300,
      ),
    [loadTaskOptions],
  )

  const handleChange = useCallback(
    // any is literally the type they use :shrug:
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_event: ChangeEvent<any>, value: AutocompleteOption | null) => {
      setSelectedOption(value)
      if (value != null && !value.disabled) {
        onChange(value)
      }
    },
    [onChange],
  )

  const getOptionDisabled = useCallback((option: AutocompleteOption) => option.disabled, [])

  const renderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        label='Task Name'
        margin='normal'
        variant='filled'
        InputProps={{ ...params.InputProps, type: 'search' }}
        className={inputClassName}
      />
    ),
    [inputClassName],
  )

  // options are already filtered by the server
  const filterOptions = useCallback((options: AutocompleteOption[]) => options, [])

  const getOptionSelected = useCallback((option: AutocompleteOption, value: AutocompleteOption) => {
    return !option.disabled && !value.disabled && value.id === option.id
  }, [])

  return (
    <Autocomplete
      options={taskOptions}
      getOptionLabel={(option) => option.label}
      getOptionDisabled={getOptionDisabled}
      onChange={handleChange}
      onInputChange={handleInputChange}
      getOptionSelected={getOptionSelected}
      value={selectedOption}
      loading={tasksLoading}
      renderInput={renderInput}
      filterOptions={filterOptions}
      className={className}
    />
  )
}

export default TaskTitleReferenceIdAutocomplete
