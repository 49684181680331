import { ApolloQueryResult } from '@apollo/client'
import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { Query, QuerySearchableRecordResultsArgs } from '@src/graphql/types'
import { DocumentEditorState } from './document_editor_state'
import {
  selectRepeatableFieldsFromJobOrDocTable,
  selectRepeatableFieldsFromJobTable,
} from './field'
import { selectActiveLineItemsTable, lineItemSelectors } from './line_items_table'
import { jobTableLineItemSelectors } from './job_table'
import {
  getColHeadersFromRepeatableFields,
  getColumnsFromRepeatableFields,
  getRowsFromRepeatableFields,
} from './utils/rows_columns_selectors'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'

const selectActiveJobOrDocTableColumns = createDraftSafeSelector(
  (state: DocumentEditorState) => state.jobTableState,
  selectActiveLineItemsTable,
  (state: DocumentEditorState) => state.jobTableActive,
  (jobTable, docTable, isJobTableActive) => {
    if (isJobTableActive) {
      return jobTable?.columns ?? []
    }
    return docTable?.columns ?? []
  },
)

const selectJobTableColumns = createDraftSafeSelector(
  (state: DocumentEditorState) => state.jobTableState,
  (jobTable) => {
    return jobTable?.columns ?? []
  },
)

export const selectActiveJobOrDocTableLineItems = createDraftSafeSelector(
  (state: DocumentEditorState) => state,
  (state: DocumentEditorState) => state.jobTableActive,
  (state, isJobTableActive) => {
    if (isJobTableActive) {
      return jobTableLineItemSelectors.selectAll(state) ?? []
    }
    return lineItemSelectors.selectAll(state) ?? []
  },
)

export const selectJobTableLineItems = createDraftSafeSelector(
  (state: DocumentEditorState) => state,
  (state) => {
    return jobTableLineItemSelectors.selectAll(state) ?? []
  },
)

export const selectFormattedColumns = createDraftSafeSelector(
  selectRepeatableFieldsFromJobOrDocTable,
  selectActiveJobOrDocTableColumns,
  (state: DocumentEditorState) => state.documentTables,
  (state: DocumentEditorState) => state.lineItemsTableMap,
  (state: DocumentEditorState) => state.pageFieldEditorState?.pages,
  (state: DocumentEditorState) => state.job?.jobTemplate?.company,
  (
    _: DocumentEditorState,
    refetchSearchableRecords: (
      variables?: Partial<QuerySearchableRecordResultsArgs> | undefined,
    ) => Promise<ApolloQueryResult<Pick<Query, 'searchableRecordResults'>>>,
    _enqueueSnackbar: (
      message: SnackbarMessage,
      options?: OptionsObject | undefined,
    ) => SnackbarKey,
  ) => refetchSearchableRecords,
  (
    _: DocumentEditorState,
    _refetchSearchableRecords: (
      variables?: Partial<QuerySearchableRecordResultsArgs> | undefined,
    ) => Promise<ApolloQueryResult<Pick<Query, 'searchableRecordResults'>>>,
    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  ) => enqueueSnackbar,
  (state: DocumentEditorState) => state.job?.jobTemplate?.cargowiseConfig?.id,
  (state: DocumentEditorState) =>
    state.job?.jobTemplate?.apiPartnerId || state.job?.jobTemplate?.apiPartner?.id,
  (
    repeatableFields,
    lineItemsTableColumns,
    documentTables,
    lineItemsTableMap,
    pages,
    company,
    refetchSearchableRecords,
    enqueueSnackbar,
    apiPartnerId,
  ) => {
    return getColumnsFromRepeatableFields(
      repeatableFields,
      documentTables,
      lineItemsTableMap,
      refetchSearchableRecords,
      enqueueSnackbar,
      lineItemsTableColumns,
      pages,
      company,
      apiPartnerId,
    )
  },
)

//TODO: fix inconsistencies in working. This works for SOA recon in ExportButton group but not SOA recon in the table
export const selectFormattedJobTableColumns = createDraftSafeSelector(
  selectRepeatableFieldsFromJobTable,
  selectJobTableColumns,
  (state: DocumentEditorState) => state.pageFieldEditorState?.pages,
  (state: DocumentEditorState) => state.job?.jobTemplate?.company,
  (state: DocumentEditorState) => state.job?.jobTemplate?.cargowiseConfig?.id,
  (state: DocumentEditorState) =>
    state.job?.jobTemplate?.apiPartnerId || state.job?.jobTemplate?.apiPartner?.id,
  (repeatableFields, lineItemsTableColumns, pages, company, apiPartnerId) => {
    return getColumnsFromRepeatableFields(
      repeatableFields,
      null,
      null,
      null,
      null,
      lineItemsTableColumns,
      pages,
      company,
      apiPartnerId,
    )
  },
)

export const selectColumnHeaders = createDraftSafeSelector(
  selectRepeatableFieldsFromJobOrDocTable,
  selectActiveJobOrDocTableColumns,
  (repeatableFields, lineItemsTableColumns) => {
    return getColHeadersFromRepeatableFields(repeatableFields, lineItemsTableColumns)
  },
)

export const selectJobTableColumnHeaders = createDraftSafeSelector(
  selectRepeatableFieldsFromJobTable,
  selectJobTableColumns,
  (repeatableFields, lineItemsTableColumns) => {
    return getColHeadersFromRepeatableFields(repeatableFields, lineItemsTableColumns)
  },
)

export const selectFormattedRows = createDraftSafeSelector(
  selectActiveJobOrDocTableLineItems,
  selectRepeatableFieldsFromJobOrDocTable,
  selectActiveJobOrDocTableColumns,
  (lineItems, repeatableFields, columns) => {
    return getRowsFromRepeatableFields(lineItems, repeatableFields, columns)
  },
)

export const selectFormattedJobTableRows = createDraftSafeSelector(
  selectJobTableLineItems,
  selectRepeatableFieldsFromJobTable,
  selectJobTableColumns,
  (lineItems, repeatableFields, columns) => {
    return getRowsFromRepeatableFields(lineItems, repeatableFields, columns)
  },
)
