import { FunctionComponent } from 'react'
import { Box, Dialog } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core'
import theme from '@src/utils/theme'
import DialogTitle from '@material-ui/core/DialogTitle'
import { EDocsDataTargetType, FilePageNode } from '@src/graphql/types'
import SoaEDocsFileSelect from './SoaEDocsFileSelect'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles<Theme>({
  dialog: {
    padding: theme.spacing(3, 1),
    width: '100%',
  },
})

type Props = {
  jobId: string
  filePages: FilePageNode[]
  selectedNums: string[]
  setIsOpen: (isOpen: boolean) => void
  isOpen: boolean
  dataTargetType: EDocsDataTargetType
}

const BatchUploadModal: FunctionComponent<Props> = ({
  jobId,
  filePages,
  selectedNums,
  isOpen,
  setIsOpen,
  dataTargetType,
}) => {
  const closeDialog = (): void => {
    setIsOpen(false)
  }
  const classes = useStyles()

  return (
    <Dialog
      open={isOpen}
      onBackdropClick={closeDialog}
      classes={{ paper: classes.dialog }}
      maxWidth='lg'
      data-testid='soa-eDoc-upload-modal'
    >
      <DialogTitle disableTypography>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h4'>{dataTargetType}: Select Pages to Upload</Typography>
          <IconButton onClick={closeDialog}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </Box>
      </DialogTitle>
      {filePages.length && (
        <SoaEDocsFileSelect
          jobId={jobId}
          filePages={filePages}
          selectedNums={selectedNums}
          closeDialog={closeDialog}
          dataTargetType={dataTargetType}
        />
      )}
    </Dialog>
  )
}

export default BatchUploadModal
