import { gql } from '@apollo/client'

export const TASK_NOTE_FRAGMENT = gql`
  fragment taskNote on TaskNoteNode {
    id
    content
    dateCreated
    user {
      id
      email
    }
  }
`

export const TASK_NOTES = gql`
  ${TASK_NOTE_FRAGMENT}
  query taskNotes($taskId: String!) {
    taskNotes(taskId: $taskId) {
      ...taskNote
    }
  }
`
