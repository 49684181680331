import { DocumentEditorState } from './document_editor_state'
import {
  cloneLineItems,
  getLastRowBottomCoord,
  getLineItemFieldCoordinates,
  JobTableLineItem,
} from '@src/utils/line_items'
import { EntityId, EntityState } from '@reduxjs/toolkit'
import { jobTableLineItemsAdapter } from './job_line_items_table'
import { BoxDimension, GoogleOCRData } from '../../types/ocr'
import { v4 as uuidv4 } from 'uuid'
import { LineItem, JobTableFieldValue } from '../../utils/line_items'
import { lineItemsAdapter, lineItemSelectors, rawLineItemsSelectors } from './line_items_table'
import {
  createFieldValue,
  createJobTableFieldValue,
  getLineItemsAfterInsert,
  getUpdatedBoxDimension,
  getUpdatedBoxMapping,
  getUpdatedFieldMapping,
  getUpdatedKeyMapping,
  isLineItemsClipboardOutdated,
  HandsontableChanges,
  reorderColumns,
  addColumn,
  convertLineItemsToJobTableLineItems,
} from './utils/line_item_actions'
import { selectActiveDocumentEditorPage } from './document'
import { stateHasMainTable, jobTableLineItemSelectors } from './job_table'
import { LineItemsClipboardState } from './line_items_clipboard'
import { produce, current, isDraft } from 'immer'
import type { Draft } from 'immer'
import { ApReconAutofillKey, FieldGroupNode, FilePageType, Maybe } from '@src/graphql/types'
import { SpreadsheetDataColumn } from '@src/utils/data-grid'
import { JobDocumentTable } from '../../utils/shipment_form'
import { documentTableSelectors } from './document_table'
import { removeColumn, isEmptyRow } from './utils/line_item_actions'
import { ChargeCodeTax } from '@src/components/data-grid/types'

export const updateJobAndDocumentTableColumns = (
  state: DocumentEditorState,
  documentTables: JobDocumentTable[],
  columns: string[],
  fieldGroup?: Maybe<FieldGroupNode>,
): void => {
  /**
   * Should be used by SOA jobs only
   *
   * We don't update the document table's columns if the file page type is EXCEL because there shouldn't be
   * an extracted table for the file page
   */
  state.lineItemsTableMap = produce(state.lineItemsTableMap, (lineItemsTableMapDraft) => {
    documentTables.forEach((documentTable) => {
      if (documentTable.filePageType !== FilePageType.Excel) {
        lineItemsTableMapDraft[documentTable.id].columns = columns
      }
    })
  })
  state.jobTableState = produce(state.jobTableState, (jobTableStateDraft) => {
    jobTableStateDraft!.columns = columns
    if (fieldGroup) {
      jobTableStateDraft!.fieldGroup = fieldGroup
    }
  })
}

export const editLineItemCells = (
  columns: string[],
  lineItemsDraft: Draft<EntityState<JobTableLineItem>> | Draft<EntityState<LineItem>>,
  changes: HandsontableChanges,
  isJobTableActive: boolean,
  activeDocumentId: string,
): void => {
  changes.forEach((change) => {
    if (change.colIdx >= columns.length && change.colIdx < 0) {
      return
    }
    const columnKey = columns[change.colIdx as number]
    const rowId = lineItemsDraft.ids[change.rowIdx as number]
    if (lineItemsDraft.entities[rowId] && columnKey) {
      if (lineItemsDraft.entities[rowId]!.fieldMapping[columnKey]) {
        lineItemsDraft.entities[rowId]!.fieldMapping[columnKey].value = change.newVal as string
      } else {
        const field = isJobTableActive
          ? createJobTableFieldValue(activeDocumentId, change.newVal.toString())
          : createFieldValue(change.newVal.toString())
        lineItemsDraft.entities[rowId]!.fieldMapping[columnKey] = field
      }
    }
  })
}

export const postProcessLineItemCells = (
  columns: SpreadsheetDataColumn[],
  lineItemsDraft: Draft<EntityState<JobTableLineItem>> | Draft<EntityState<LineItem>>,
  changes: HandsontableChanges,
  isJobTableActive: boolean,
  activeDocumentId: string,
  chargeCodeTaxMap: Record<string, ChargeCodeTax> | null,
): void => {
  if (!chargeCodeTaxMap || Object.keys(chargeCodeTaxMap).length === 0) {
    return
  }
  changes.forEach((change) => {
    const column = columns[change.colIdx as number]
    const rowId = lineItemsDraft.ids[change.rowIdx as number]
    const taxIdColumnKey = columns.find(
      (column) => column.autofillKey === ApReconAutofillKey.TaxId.toLowerCase(),
    )?.key
    const isChargeCodeKey =
      column.autofillKey?.toLowerCase() === ApReconAutofillKey.ChargeCode.toLowerCase()
    if (taxIdColumnKey && isChargeCodeKey) {
      if (lineItemsDraft.entities[rowId] && change.newVal in chargeCodeTaxMap) {
        const matchingTaxId = chargeCodeTaxMap[change.newVal]!.taxCode
        if (lineItemsDraft.entities[rowId]!.fieldMapping[taxIdColumnKey]) {
          lineItemsDraft.entities[rowId]!.fieldMapping[taxIdColumnKey].value = matchingTaxId
        } else {
          const field = isJobTableActive
            ? createJobTableFieldValue(activeDocumentId, matchingTaxId)
            : createFieldValue(matchingTaxId)
          lineItemsDraft.entities[rowId]!.fieldMapping[taxIdColumnKey] = field
        }
      } else {
        lineItemsDraft.entities[rowId]!.fieldMapping[taxIdColumnKey] = createFieldValue('')
      }
    }
  })
}

/**
 * Copy-paste behavior:
 * https://www.notion.so/expedock/SOA-Extract-Copy-table-behavior-5f7d2c7b47524e2592e27482c30f7610
 */
export const pasteToTable = (
  columns: SpreadsheetDataColumn[],
  lineItemsDraft: Draft<EntityState<JobTableLineItem>> | Draft<EntityState<LineItem>>,
  lineItemsClipboard: LineItemsClipboardState | null,
  changes: HandsontableChanges,
  isJobTableActive: boolean,
  activeDocumentId: string,
  activeDocumentTableId: string,
  googleOcrData: GoogleOCRData | null,
  chargeCodeTaxMap: Record<string, ChargeCodeTax> | null,
): boolean => {
  const columnKeys = columns.map((column) => column.key)
  // add all missing rows before pasting/ modifying the values
  const lastRow = (changes[changes.length - 1].rowIdx as number) + 1
  if (isJobTableActive) {
    addEmptyJobTableRows(
      lineItemsDraft.ids.length as number,
      Math.max(0, lastRow - lineItemsDraft.ids.length),
      jobTableLineItemsAdapter
        .getSelectors()
        .selectAll(
          (isDraft(lineItemsDraft) ? current(lineItemsDraft) : lineItemsDraft) as Draft<
            EntityState<JobTableLineItem>
          >,
        ),
      lineItemsDraft as Draft<EntityState<JobTableLineItem>>,
      columnKeys,
      activeDocumentId,
    )
  } else {
    addEmptyOrClonedDocTableRows(
      lineItemsDraft.ids.length as number,
      Math.max(0, lastRow - lineItemsDraft.ids.length),
      rawLineItemsSelectors.selectAll(
        // selectAll should be a draft safe selector, but we get really weird
        // issues if we don't explicitly call `current()` (seems to be falling back to es5
        // backcompat code even if it shouuldn't)
        (isDraft(lineItemsDraft) ? current(lineItemsDraft) : lineItemsDraft) as Draft<
          EntityState<LineItem>
        >,
      ),
      lineItemsDraft as Draft<EntityState<LineItem>>,
      columnKeys,
      activeDocumentTableId,
      googleOcrData,
    )
  }
  const isClipboardOutdated = isLineItemsClipboardOutdated(lineItemsClipboard, changes)
  const updatedLineItems = produce(lineItemsDraft, (tableLineItem) => {
    if (lineItemsClipboard === null || isClipboardOutdated) {
      editLineItemCells(columnKeys, tableLineItem, changes, isJobTableActive, activeDocumentId)
      postProcessLineItemCells(
        columns,
        tableLineItem,
        changes,
        isJobTableActive,
        activeDocumentId,
        chargeCodeTaxMap,
      )
      return
    }

    const pasteStartCol = changes[0].colIdx
    const pasteEndCol = changes[changes.length - 1].colIdx
    const pasteStartRow = changes[0].rowIdx
    const pasteEndRow = changes[changes.length - 1].rowIdx

    const updatedKeyMapping = getUpdatedKeyMapping(
      lineItemsClipboard.orderedColKeys,
      columnKeys,
      pasteStartCol,
      pasteEndCol,
    )
    for (let rowIdx = 0; rowIdx <= pasteEndRow - pasteStartRow; rowIdx++) {
      const indexOfLineItemToPaste = rowIdx % lineItemsClipboard.lineItems.length
      const lineItem = lineItemsClipboard.lineItems[indexOfLineItemToPaste]
      const updatedFieldMapping = getUpdatedFieldMapping(
        updatedKeyMapping,
        lineItem,
        lineItemsClipboard.documentId,
      )
      const tableLineItemId = tableLineItem.ids[rowIdx + pasteStartRow]
      const updatedBoxes = getUpdatedBoxMapping(lineItem, lineItemsClipboard.documentId)
      if (tableLineItemId) {
        if (isJobTableActive) {
          updateJobTableLineItem(
            tableLineItem as Draft<EntityState<JobTableLineItem>>,
            tableLineItemId,
            updatedBoxes,
            updatedFieldMapping,
          )
        } else {
          updateDocTableLineItem(
            tableLineItem as Draft<EntityState<LineItem>>,
            tableLineItemId,
            updatedBoxes[activeDocumentId],
            updatedFieldMapping,
            activeDocumentId!,
          )
        }
        postProcessLineItemCells(
          columns,
          tableLineItem,
          changes,
          isJobTableActive,
          activeDocumentId,
          chargeCodeTaxMap,
        )
      }
    }
  })
  Object.assign(lineItemsDraft, updatedLineItems)
  return isClipboardOutdated
}

/**
 * If the line item is to be pasted on an existing row, we just update the row's fieldMapping and boxMapping
 * else, we add a new row
 *
 * For the job table (Main SOA table), it should have the ff. behavior:
 * - we always paste the entire fieldValue (including the individual field box coordinates)
 * - we update all boxMapping (the outer box coordinates), regardless of the documents they are linked to
 */
const updateJobTableLineItem = (
  tableLineItems: Draft<EntityState<JobTableLineItem>>,
  tableLineItemId: EntityId,
  updatedBoxes: Record<string, BoxDimension>,
  updatedFieldMapping: Record<string, JobTableFieldValue>,
): void => {
  if (tableLineItemId) {
    const tableLineItem = tableLineItems.entities[tableLineItemId]! as JobTableLineItem
    const { fieldMapping: currentFieldMapping, boxMapping: currentBoxMapping } = tableLineItem
    Object.entries(updatedFieldMapping).forEach(([fieldKey, fieldValue]) => {
      if (!currentFieldMapping[fieldKey]) {
        currentFieldMapping[fieldKey] = createJobTableFieldValue(
          fieldValue.documentId,
          fieldValue.value.toString(),
        )
      } else {
        currentFieldMapping[fieldKey].value = fieldValue.value
      }
      currentFieldMapping[fieldKey].top = fieldValue.top
      currentFieldMapping[fieldKey].left = fieldValue.left
      currentFieldMapping[fieldKey].height = fieldValue.height
      currentFieldMapping[fieldKey].width = fieldValue.width
      currentFieldMapping[fieldKey].documentId = fieldValue.documentId
    })
    Object.entries(updatedBoxes).forEach(([activeDocumentId, updatedBox]) => {
      if (updatedBox) {
        tableLineItem.boxMapping[activeDocumentId] = getUpdatedBoxDimension(
          currentBoxMapping[activeDocumentId],
          updatedBox,
        )
      }
    })
  }
}

/**
 * If the line item is to be pasted on an existing row, we just update the row's fieldMapping and boxMapping
 * else, we add a new row
 *
 * For the document table (Extract table), it should have the ff. behavior:
 * - we paste the entire fieldValue if exists in the same document (i.e. if we copied the field from the same doc table)
 *   else, we only paste the string value (i.e. if we copied the field from another doc table)
 * - we only update the boxMapping on the current active document
 */
export const updateDocTableLineItem = (
  lineItemsDraft: Draft<EntityState<LineItem>>,
  tableLineItemId: EntityId,
  updatedBox: BoxDimension,
  updatedFieldMapping: Record<string, JobTableFieldValue>,
  activeDocumentId: string,
): void => {
  const tableLineItem = lineItemsDraft.entities[tableLineItemId]!
  const { fieldMapping: currentFieldMapping, box: currentBoxMapping } = tableLineItem
  Object.entries(updatedFieldMapping).forEach(([fieldKey, fieldValue]) => {
    if (!currentFieldMapping[fieldKey]) {
      currentFieldMapping[fieldKey] = createFieldValue(fieldValue.value.toString())
    } else {
      currentFieldMapping[fieldKey].value = fieldValue.value
    }
    if (activeDocumentId === fieldValue.documentId) {
      currentFieldMapping[fieldKey].top = fieldValue.top
      currentFieldMapping[fieldKey].left = fieldValue.left
      currentFieldMapping[fieldKey].height = fieldValue.height
      currentFieldMapping[fieldKey].width = fieldValue.width
      if (updatedBox) {
        tableLineItem.box = getUpdatedBoxDimension(currentBoxMapping, updatedBox)
      }
    }
  })
}

export const addEmptyJobTableRows = (
  insertIdx: number,
  amount: number,
  lineItems: JobTableLineItem[],
  tableLineItems: Draft<EntityState<JobTableLineItem>>,
  columns: string[],
  activeDocumentId: string,
): void => {
  const rowsToBeInserted = Array.from(Array(amount)).map(
    (_) =>
      ({
        id: uuidv4(),
        boxMapping: {},
        fieldMapping: Object.fromEntries(
          columns.map((column) => [column, createJobTableFieldValue(activeDocumentId, '')]),
        ),
      }) as JobTableLineItem,
  )
  const rearrangedLineItems = getLineItemsAfterInsert(
    insertIdx,
    lineItems,
    rowsToBeInserted,
  ) as JobTableLineItem[]
  jobTableLineItemsAdapter.setAll(tableLineItems, rearrangedLineItems)
}

export const addEmptyOrClonedDocTableRows = (
  insertIdx: number,
  amount: number,
  lineItems: LineItem[],
  lineItemsDraft: Draft<EntityState<LineItem>>,
  columnKeys: string[],
  activeDocumentTableId: string,
  googleOcrData: GoogleOCRData | null,
): void => {
  if (lineItems.length > 0 && googleOcrData?.full_text_annotation?.pages?.length) {
    addClonedDocTableRows(insertIdx, amount, lineItems, lineItemsDraft, googleOcrData!)
  } else {
    addEmptyDocTableRows(
      insertIdx,
      amount,
      lineItems,
      lineItemsDraft,
      columnKeys,
      activeDocumentTableId!,
    )
  }
}

export const addClonedDocTableRows = (
  insertIdx: number,
  amount: number,
  lineItems: LineItem[],
  tableLineItems: Draft<EntityState<LineItem>>,
  googleOcrData: GoogleOCRData,
): void => {
  // clones the last row along with its column fields and appends it to the end of the list
  const tableLineItemsLength = tableLineItems.ids.length
  if (tableLineItemsLength > 0) {
    const { lastRowBottomCoord, lastItemId } = getLastRowBottomCoord(
      Object.values(tableLineItems.entities) as LineItem[],
    )
    const lastLineItem = tableLineItems.entities[lastItemId] as LineItem
    const rowsToBeInserted = cloneLineItems(
      Array.from(Array(amount)).map((_) => lastLineItem),
      lastRowBottomCoord as number,
      googleOcrData,
      true,
    ) as LineItem[]
    // we can directly add the new rows to the end of the table because document table rows are sorted based on their bounding boxes
    // and the bounding boxes for the the new rows are always created at the bottom of the document (as per getLastRowBottomCoord)
    const rearrangedLineItems = getLineItemsAfterInsert(
      insertIdx,
      lineItems,
      rowsToBeInserted,
    ) as LineItem[]
    lineItemsAdapter.setAll(tableLineItems, rearrangedLineItems)
  }
}

export const addEmptyDocTableRows = (
  insertIdx: number,
  amount: number,
  lineItems: LineItem[],
  tableLineItems: Draft<EntityState<LineItem>>,
  columns: string[],
  documentTableId: string,
): void => {
  const rowsToBeInserted = Array.from(Array(amount)).map(
    (_) =>
      ({
        id: uuidv4(),
        box: {},
        documentTableId: documentTableId,
        fieldMapping: Object.fromEntries(columns.map((column) => [column, createFieldValue('')])),
      }) as LineItem,
  )
  const rearrangedLineItems = getLineItemsAfterInsert(
    insertIdx,
    lineItems,
    rowsToBeInserted,
  ) as LineItem[]
  lineItemsAdapter.setAll(tableLineItems, rearrangedLineItems)
}

export const deleteFromJobTable = (
  deleteIdx: number,
  amount: number,
  tableLineItems: Draft<EntityState<JobTableLineItem>>,
): void => {
  jobTableLineItemsAdapter.removeMany(
    tableLineItems,
    tableLineItems.ids.slice(deleteIdx, deleteIdx + amount),
  )
}

export const deleteFromDocTable = (
  deleteIdx: number,
  amount: number,
  tableLineItems: Draft<EntityState<LineItem>>,
): void => {
  lineItemsAdapter.removeMany(
    tableLineItems,
    tableLineItems.ids.slice(deleteIdx, deleteIdx + amount),
  )
}

export const setDocumentEditorPageFieldCoordinates = (
  state: DocumentEditorState,
  isJobTableActive: boolean,
): void => {
  const documentEditorPage = selectActiveDocumentEditorPage(state)!
  const lineItems = isJobTableActive
    ? jobTableLineItemSelectors.selectAll(state)!
    : lineItemSelectors.selectAll(state)!
  Object.assign(documentEditorPage.fieldCoordinates, getLineItemFieldCoordinates(lineItems))
}

export const reorderTableColumns = (
  state: DocumentEditorState,
  startColumnsArr: number[],
  insertIdxStart: number,
  columnKeys: string[],
  isJobTableActive: boolean,
): void => {
  const newColumns = reorderColumns(startColumnsArr, insertIdxStart, columnKeys)
  if (stateHasMainTable(state)) {
    updateJobAndDocumentTableColumns(
      state,
      documentTableSelectors.selectAll(current(state)),
      newColumns,
    )
  } else if (isJobTableActive && state.jobTableState) {
    state.jobTableState!.columns = newColumns
  } else if (state.activeDocumentTableId) {
    const lineItemsTableMapDraft = state.lineItemsTableMap
    lineItemsTableMapDraft[state.activeDocumentTableId!].columns = newColumns
  }
}

export const addTableColumn = (
  state: DocumentEditorState,
  columns: string[],
  index: number,
  colKey: string,
  isJobTableActive: boolean,
): void => {
  const newColumns = addColumn(columns, index, colKey)
  if (stateHasMainTable(state)) {
    updateJobAndDocumentTableColumns(
      state,
      documentTableSelectors.selectAll(current(state)),
      newColumns,
    )
  } else if (isJobTableActive && state.jobTableState) {
    state.jobTableState!.columns = newColumns
  } else if (state.activeDocumentTableId) {
    const lineItemsTableMapDraft = state.lineItemsTableMap
    lineItemsTableMapDraft[state.activeDocumentTableId!].columns = newColumns
  }
}

export const removeTableColumn = (
  state: DocumentEditorState,
  columns: string[],
  colKey: string,
  isJobTableActive: boolean,
): void => {
  const newColumns = removeColumn(columns, colKey)
  if (stateHasMainTable(state)) {
    updateJobAndDocumentTableColumns(
      state,
      documentTableSelectors.selectAll(current(state)),
      newColumns,
    )
  } else if (isJobTableActive && state.jobTableState) {
    state.jobTableState.columns = newColumns
  } else if (state.activeDocumentTableId) {
    const lineItemsTableMapDraft = state.lineItemsTableMap
    lineItemsTableMapDraft[state.activeDocumentTableId].columns = newColumns
  }
}

export const copyLineItemsFromDocTables = (state: DocumentEditorState): void => {
  let jobTableLineItems = [] as JobTableLineItem[]
  state.documentTables.ids.forEach((documentTableId) => {
    const docTableLineItems = rawLineItemsSelectors.selectAll(
      current(state.lineItemsTableMap[documentTableId].lineItems),
    )
    jobTableLineItems = jobTableLineItems.concat(
      convertLineItemsToJobTableLineItems(
        docTableLineItems,
        state.documentTables.entities[documentTableId]!.documentId,
      ),
    )
  })
  jobTableLineItems = jobTableLineItems.filter((jobTableLineItem) => !isEmptyRow(jobTableLineItem))
  jobTableLineItemsAdapter.setAll(state.jobTableState!.lineItems, jobTableLineItems)
}
