import { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { Link, makeStyles } from '@material-ui/core'

import TaskNotes from '@src/components/task-notes'
import { Maybe, TaskNode } from '@src/graphql/types'
import { getTaskSLATimeLeft, parseDateString } from '@src/utils/date'
import theme from '@src/utils/theme'
import { useQuery } from '@apollo/client'
import { TASK_NOTES } from '@src/graphql/queries/taskNote'

const useStyles = makeStyles({
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
    },
  },
  infoPanel: {
    '& dt': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '& dd': {
      margin: 0,
      overflowWrap: 'break-word',
    },
  },
  messageText: {
    whiteSpace: 'pre-wrap',
  },
})

type Props = {
  task: Maybe<TaskNode>
}

const TaskInfo: FunctionComponent<Props> = ({ task }) => {
  const classes = useStyles()

  const formatDate = (dateString: string | null, defaultValue = 'N/A'): string => {
    if (dateString) {
      const dateReceived = parseDateString(dateString)
      return `${dateReceived.toDateString().slice(4)}, ${dateReceived.toLocaleTimeString()}`
    }
    return defaultValue
  }

  const { data: taskNotesData } = useQuery(TASK_NOTES, {
    variables: { taskId: task?.id },
  })

  return (
    <Box className={classes.dataContainer}>
      <Paper square>
        <Typography variant='h3' gutterBottom>
          Task Message
        </Typography>
        <Box height='15vh' overflow='scroll' my={1} p={1} bgcolor='grey.100'>
          <Typography className={classes.messageText}>{task?.message}</Typography>
        </Box>
      </Paper>

      <Paper square>
        <Typography variant='h3' gutterBottom>
          Task Data
        </Typography>
        <Grid component='dl' container className={classes.infoPanel} spacing={2}>
          <Grid item xs={6}>
            <Link href={`/tasks/${task?.id}`} data-testid='task-name-link'>
              <dt>Task Name</dt>
              <dd>{task?.title ?? 'N/A'}</dd>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <dt>Task Reference ID</dt>
            <dd>{task?.taskReferenceId ?? 'N/A'}</dd>
          </Grid>
          <Grid item xs={6}>
            <dt>Task Type</dt>
            <dd>{task?.taskType ?? 'N/A'}</dd>
          </Grid>
          <Grid item xs={6}>
            <dt>Organization</dt>
            <dd>{task?.company?.name ?? 'N/A'}</dd>
          </Grid>
          <Grid item xs={6}>
            <dt>Date Created</dt>
            <dd>{formatDate(task?.dateCreated ?? null)}</dd>
          </Grid>
          <Grid item xs={6}>
            <dt>SLA Time Left</dt>
            <dd>{getTaskSLATimeLeft(task)}</dd>
          </Grid>
          <Grid item xs={6}>
            <dt>Date Received</dt>
            <dd>{formatDate(task?.dateReceived ?? null)}</dd>
          </Grid>
          <Grid item xs={6}>
            <dt>Date Confirmed</dt>
            <dd>{formatDate(task?.dateConfirmed ?? null)}</dd>
          </Grid>
        </Grid>
      </Paper>

      <Paper square>
        <Typography variant='h3' gutterBottom>
          Task Notes
        </Typography>
        <TaskNotes
          taskNotes={taskNotesData?.taskNotes}
          task={task}
          refetchQueries={[{ query: TASK_NOTES, variables: { taskId: task?.id } }]}
        />
      </Paper>
    </Box>
  )
}

export default TaskInfo
