import { FunctionComponent } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { RenderElementProps } from 'slate-react'

import ImageElement from './ImageElement'
import FileElement from './FileElement'

const SlateElement: FunctionComponent<RenderElementProps> = (props: RenderElementProps) => {
  const { attributes, children, element } = props

  switch (element.type) {
    case 'loading':
      return (
        <div {...attributes}>
          <div contentEditable={false}>
            <CircularProgress />
          </div>
          {children}
        </div>
      )
    case 'image':
      return <ImageElement {...props} />
    case 'file':
      return <FileElement {...props} />
    default:
      return (
        <Typography gutterBottom {...attributes}>
          {children}
        </Typography>
      )
  }
}

export default SlateElement
