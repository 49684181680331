import { FunctionComponent, useCallback, useContext } from 'react'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { SidebarContext } from '@src/components/content-with-sidebar/sidebar-context'

const SidebarCollapseControl: FunctionComponent = () => {
  const { isCollapsed, setIsCollapsed } = useContext(SidebarContext)
  const collapse = useCallback(() => setIsCollapsed(true), [setIsCollapsed])
  const uncollapse = useCallback(() => setIsCollapsed(false), [setIsCollapsed])

  return isCollapsed ? (
    <IconButton onClick={uncollapse}>
      <ChevronRightIcon fontSize='large' />
    </IconButton>
  ) : (
    <IconButton onClick={collapse}>
      <ChevronLeftIcon fontSize='large' />
    </IconButton>
  )
}

export default SidebarCollapseControl
