import Rollbar, { Configuration } from 'rollbar'
import { DeployEnvironment } from '@src/utils/environment'
import { User } from '@auth0/auth0-react'
import { RequestHandler } from '@apollo/client'

let _rollbar: Rollbar | undefined

export const initializeRollbar = (
  user: User | undefined,
  deployEnvironment: DeployEnvironment | null,
): void => {
  if (!_rollbar) {
    if (
      deployEnvironment &&
      [
        DeployEnvironment.ENGINEERING,
        DeployEnvironment.STAGING,
        DeployEnvironment.PRODUCTION,
      ].includes(deployEnvironment)
    ) {
      _rollbar = new Rollbar({
        accessToken: '905aa0a359b541ef93b5730ad72e4d70',
        captureUncaught: true,
        captureUnhandledRejections: true,
        checkIgnore,
        filterTelemetry,
        payload: {
          environment: deployEnvironment.toLowerCase(),
          client: {
            javascript: {
              source_map_enabled: true,

              // -- Add this into your configuration ---
              // TODO: figure out how to make this a git sha
              code_version: '1.0',
              // ---------------------------------------

              // Optionally have Rollbar guess which frames the error was
              // thrown from when the browser does not provide line
              // and column numbers.
              guess_uncaught_frames: true,
            },
          },
          person: user?.sub
            ? {
                id: user.sub,
                email: user.email,
              }
            : undefined,
        },
      })
      setRollbarTransform(undefined)
    }
  }
  if (_rollbar && user?.sub) {
    _rollbar.configure({
      payload: {
        person: {
          id: user.sub,
          email: user.email,
        },
      },
    })
  }
}

export const reportRollbarError = (msg: string | Error): void => {
  // should be initialized by now, but let's initialize anyways just in case
  initializeRollbar(undefined, null)
  // eslint-disable-next-line no-console
  console.error(msg)
  if (_rollbar != null) {
    _rollbar.error(msg)
  }
}

// For adding additional properties to the rollbar events
export const setRollbarTransform = (rollbarTransform: Configuration['transform']): void => {
  initializeRollbar(undefined, null)
  if (_rollbar != null) {
    _rollbar.configure({
      transform: (data: any, item: any) => {
        if (!data.custom) {
          data.custom = {}
        }
        data.custom['source_app'] = 'web'
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        rollbarTransform && void rollbarTransform(data, item)
      },
    })
  }
}

/**
 * Ignore missing pages & authorization errors
 */
const checkIgnore: Configuration['checkIgnore'] = (_isUncaught, args, item) => {
  const statusCode = (args[1] as any)?.networkError?.statusCode as number | undefined
  if (statusCode && [401, 403, 404].includes(statusCode)) {
    return true
  }
  const errorMessage = (args[1] as any)?.message as string | undefined
  if (errorMessage && errorMessage.includes('Firebase: Error (auth/tenant-id-mismatch)')) {
    return true
  }
  return false
}

/**
 * Filters out GQL network events, since we have captureGraphQL that actually shows the operation name
 */
const filterTelemetry: Configuration['filterTelemetry'] = (e) => {
  // ignore graphql network events -- we have separate GQL events for that
  if (e.type === 'network' && (e.body as any)?.url?.indexOf('/graphql') !== 0) {
    return true
  }
  return false
}

/**
 * Custom GQL operations capturer so they get shown in rollbar logs
 */
export const captureGraphQL: RequestHandler = (operation, forward) => {
  if (_rollbar != null) {
    const operationDefinition = operation.query.definitions[0]
    _rollbar.captureEvent(
      {
        type: 'graphql',
        operationKind: operationDefinition.kind,
        operationName: operation.operationName,
        variables: operation.variables,
      },
      'info',
    )
  }
  return forward(operation)
}
