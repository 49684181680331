import { configureStore } from '@reduxjs/toolkit'
import documentEditorReducer from '@src/redux-features/document_editor'
import jobEditorReducer from '@src/redux-features/job_editor'

// the type for this is very long, you can tell what it is anyways
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createAppStore = () =>
  configureStore({
    reducer: {
      documentEditor: documentEditorReducer,
      jobEditor: jobEditorReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
  })

const store = createAppStore()

export default store

export type RootState = ReturnType<typeof store.getState>
