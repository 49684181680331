import { Box, Typography, Button } from '@material-ui/core'
import { FC } from 'react'
import CenteredCircularProgress from '../centered-circular-progress/CenteredCircularProgress'

type LoadMoreProps = {
  loading: boolean
  hasNextPage: boolean
  loadMoreCallback: () => void
}

export const LoadMoreButton: FC<LoadMoreProps> = ({ loading, hasNextPage, loadMoreCallback }) => {
  if (loading) {
    return (
      <Box sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <CenteredCircularProgress />
      </Box>
    )
  }

  if (!hasNextPage) {
    return (
      <Typography
        style={{
          margin: 'auto',
        }}
      >
        The entire reconciliation history for this job has been loaded.
      </Typography>
    )
  }

  return (
    <Button
      onClick={loadMoreCallback}
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
      variant='contained'
    >
      Load More
    </Button>
  )
}

export default LoadMoreButton
