import { MouseEvent, MutableRefObject, useCallback, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { BoxDimension, Coordinates } from '@src/types/ocr'
import { RootState } from '@src/utils/store'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { JobDataContext } from '@src/contexts/job_data_context'
import { LogEventType } from '@src/utils/observability/LogEventType'

type BoundingBoxHookType = {
  start: Coordinates
  end: Coordinates
  boxDimension: BoxDimension
  isMouseDown: boolean
  handleMouseDown: (event: MouseEvent) => void
  handleMouseUp: (event: MouseEvent) => void
  handleMouseMove: (event: MouseEvent) => void
}

const computeCoordinates = (
  imageBoxRef: MutableRefObject<HTMLImageElement | null>,
  x: number,
  y: number,
): Coordinates => {
  const imageBox = imageBoxRef?.current?.getBoundingClientRect() as ClientRect
  const [transformedX, transformedY] = imageBox
    ? [(x - imageBox.left) / imageBox.width, (y - imageBox.top) / imageBox.height]
    : [0, 0]
  return { x: transformedX, y: transformedY }
}

const useBoundingBox = (
  imageBoxRef: MutableRefObject<HTMLImageElement | null>,
  isEnabled = true,
): BoundingBoxHookType => {
  const [start, setStart] = useState({ x: 0, y: 0 } as Coordinates)
  const [end, setEnd] = useState({ x: 0, y: 0 } as Coordinates)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const boxDimension = {
    top: Math.min(start.y, end.y),
    left: Math.min(start.x, end.x),
    width: Math.abs(start.x - end.x),
    height: Math.abs(start.y - end.y),
  }
  const job = useSelector((state: RootState) => state.documentEditor.job)
  const currentFilePageId = useSelector((state: RootState) => state.jobEditor.currentFilePageId)
  const { filePages } = useContext(JobDataContext)
  const { logEvent } = useEventLogger()

  const handleMouseDown = useCallback(
    (event: MouseEvent): void => {
      if (isEnabled) {
        const startCoords = computeCoordinates(imageBoxRef, event.clientX, event.clientY)
        setStart(startCoords)
        setEnd(startCoords)
        setIsMouseDown(true)
      }
    },
    [imageBoxRef, isEnabled],
  )

  const handleMouseUp = useCallback(
    (event: MouseEvent): void => {
      if (isEnabled && isMouseDown) {
        const endCoords = computeCoordinates(imageBoxRef, event.clientX, event.clientY)
        setEnd(endCoords)
        setIsMouseDown(false)

        void logEvent(LogEventType.EDIT_LINE_ITEMS_BOXING, {
          job_id: job?.id,
          file_page_id: currentFilePageId ?? filePages[0]?.id,
          coordinates: {
            start: start,
            end: endCoords,
          },
          event_time: new Date(),
        })
      }
    },
    // exclude analytics dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageBoxRef, isMouseDown, isEnabled, currentFilePageId, filePages],
  )

  const handleMouseMove = useCallback(
    (event: MouseEvent): void => {
      if (isEnabled && isMouseDown) {
        const endCoords = computeCoordinates(imageBoxRef, event.clientX, event.clientY)
        setEnd(endCoords)
      }
    },
    [imageBoxRef, isMouseDown, isEnabled],
  )

  return {
    start,
    end,
    boxDimension,
    isMouseDown,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  }
}

export default useBoundingBox
