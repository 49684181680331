import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import { useMutation, PureQueryOptions } from '@apollo/client'
import { clsx } from 'clsx'
import { useSnackbar } from 'notistack'

import CustomSlate from '@src/components/custom-slate'
import useSlateEditor from '@src/hooks/useSlateEditor'
import { CREATE_TASK_NOTE } from '@src/graphql/mutations/taskNote'
import {
  Maybe,
  Mutation,
  MutationCreateTaskNoteArgs,
  TaskNode,
  TaskNoteNode,
  TaskNoteNodeConnection,
} from '@src/graphql/types'
import theme from '@src/utils/theme'
import TaskNote from './TaskNote'

const useStyles = makeStyles({
  slateContainer: {
    borderRadius: theme.shape.borderRadius,
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    '&:hover': {
      borderColor: theme.palette.text.primary,
    },
  },
  focusedSlateContainer: {
    borderColor: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  saveNoteButton: {
    marginTop: theme.spacing(1),
  },
})

type Props = {
  taskNotes: Maybe<TaskNoteNodeConnection> | undefined
  task: Maybe<TaskNode>
  refetchQueries: PureQueryOptions[]
}

const TaskNotes: FunctionComponent<Props> = ({ taskNotes, task, refetchQueries }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [isFocused, setIsFocused] = useState(false)
  const slateEditor = useSlateEditor()
  const { slateValue, resetSlateEditor } = slateEditor
  const [createTaskNote, { error: createTaskNoteError }] = useMutation<
    Pick<Mutation, 'createTaskNote'>,
    MutationCreateTaskNoteArgs
  >(CREATE_TASK_NOTE, { refetchQueries })

  const shownTaskNotes = useMemo(() => {
    return (taskNotes?.edges?.map((taskNodeEdge) => taskNodeEdge!.node) ?? []) as TaskNoteNode[]
  }, [taskNotes])

  const createNote = async (): Promise<void> => {
    await createTaskNote({
      variables: {
        taskId: task!.id,
        content: JSON.stringify(slateValue),
      },
    })

    if (createTaskNoteError) {
      enqueueSnackbar(
        `Failed to create task note: ${formatMaybeApolloError(createTaskNoteError)}`,
        {
          variant: 'error',
        },
      )
    } else {
      resetSlateEditor()
    }
  }

  return (
    <>
      <Box display='flex' height='30vh' flexDirection='column' bgcolor='grey.100' overflow='scroll'>
        {shownTaskNotes.map((taskNote, idx) => {
          const isMostRecent = idx === shownTaskNotes.length - 1
          return (
            <TaskNote
              key={taskNote.id}
              taskNote={taskNote}
              isMostRecent={isMostRecent}
              refetchQueries={refetchQueries}
            />
          )
        })}
      </Box>
      <Box my={2}>
        <Box
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          className={clsx(classes.slateContainer, { [classes.focusedSlateContainer]: isFocused })}
        >
          <CustomSlate
            slateEditor={slateEditor}
            style={{ minHeight: '10vh', padding: `0 ${theme.spacing(1)}px` }}
          />
        </Box>
        <Button
          className={classes.saveNoteButton}
          variant='contained'
          onClick={createNote}
          disabled={!task || slateValue?.[0]?.children?.[0]?.text === ''}
        >
          Submit
        </Button>
      </Box>
    </>
  )
}

export default TaskNotes
