import { FunctionComponent } from 'react'
import type { UseFieldArrayReturn } from 'react-hook-form'
import JobTemplateFieldGroup from '@src/components/admin/job-template-fields/JobTemplateFieldGroup'
import { InputFieldGroup, Maybe, SearchableRecordOptions } from '@src/graphql/types'
import { Typography } from '@material-ui/core'
import { JobTemplateFormValues } from '@src/components/admin/job-template-editor/JobTemplateEditor'

type Props = {
  fieldArrayMethods:
    | UseFieldArrayReturn<JobTemplateFormValues, 'lineItemTypes'>
    | UseFieldArrayReturn<JobTemplateFormValues, 'metadataFieldGroups'>
  searchableRecordOptions: Maybe<SearchableRecordOptions[]> | undefined
}

const JobTemplateLineItemTypes: FunctionComponent<Props> = ({
  fieldArrayMethods,
  searchableRecordOptions,
}) => {
  const { fields: fieldGroups } = fieldArrayMethods
  return (
    <>
      <Typography variant='h3'>Line Item Types</Typography>
      {fieldGroups.map((fieldGroup, index) => (
        <JobTemplateFieldGroup
          key={fieldGroup.id}
          fieldGroup={fieldGroup as unknown as InputFieldGroup}
          index={index}
          fieldArrayMethods={fieldArrayMethods}
          isLineItem
          searchableRecordOptions={searchableRecordOptions}
        />
      ))}
    </>
  )
}

export default JobTemplateLineItemTypes
