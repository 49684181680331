import { browserName, osName, osVersion, mobileVendor, mobileModel } from 'react-device-detect'

export type DeviceDetailsType = {
  browserName: string | undefined
  osName: string | undefined
  osVersion: string | undefined
  mobileVendor: string | undefined
  mobileModel: string | undefined
}

export const getDeviceDetails = (): DeviceDetailsType => {
  return {
    browserName: browserName,
    osName: osName,
    osVersion: osVersion,
    mobileVendor: mobileVendor,
    mobileModel: mobileModel,
  } as DeviceDetailsType
}
