import { gql } from '@apollo/client'
export const CREATE_JOB = gql`
  mutation createJob(
    $jobTemplateId: UUID!
    $jobName: String!
    $filePageIds: [UUID!]!
    $docTypeIds: [UUID!]!
    $ownerId: UUID
    $qaId: UUID
    $dateReceived: String
    $batchId: UUID!
    $taskId: UUID!
  ) {
    createJob(
      jobTemplateId: $jobTemplateId
      jobName: $jobName
      filePageIds: $filePageIds
      docTypeIds: $docTypeIds
      ownerId: $ownerId
      qaId: $qaId
      dateReceived: $dateReceived
      batchId: $batchId
      taskId: $taskId
    ) {
      job {
        id
      }
    }
  }
`

export const CREATE_EMPTY_JOB = gql`
  mutation createEmptyJob(
    $jobTemplateId: String!
    $jobName: String!
    $clientReferenceNo: String!
    $ownerId: String
    $qaId: String
    $manualProduct: String!
    $dateReceived: String
    $message: String!
    $taskId: String
  ) {
    createEmptyJob(
      jobTemplateId: $jobTemplateId
      jobName: $jobName
      clientReferenceNo: $clientReferenceNo
      ownerId: $ownerId
      qaId: $qaId
      manualProduct: $manualProduct
      dateReceived: $dateReceived
      message: $message
      taskId: $taskId
    ) {
      job {
        id
      }
    }
  }
`

export const EDIT_JOB = gql`
  mutation editJob(
    $jobId: String!
    $jobTemplateId: String
    $name: String
    $clientReferenceNo: String
    $ownerId: String
    $qaId: UUID
    $manualProduct: String
    $dateReceived: String
    $externalStatus: JobExternalStatus
    $externalAssigneeId: UUID
  ) {
    editJob(
      jobId: $jobId
      jobTemplateId: $jobTemplateId
      name: $name
      clientReferenceNo: $clientReferenceNo
      ownerId: $ownerId
      qaId: $qaId
      manualProduct: $manualProduct
      dateReceived: $dateReceived
      externalStatus: $externalStatus
      externalAssigneeId: $externalAssigneeId
    ) {
      job {
        id
      }
    }
  }
`

export const ASSOCIATE_PAGES_TO_JOB = gql`
  mutation associatePagesToJob(
    $jobId: UUID!
    $filePageIds: [UUID!]!
    $docTypeIds: [UUID!]!
    $batchId: UUID!
  ) {
    associatePagesToJob(
      jobId: $jobId
      filePageIds: $filePageIds
      docTypeIds: $docTypeIds
      batchId: $batchId
    ) {
      job {
        id
      }
    }
  }
`

export const DELETE_JOB = gql`
  mutation deleteJob($jobId: String!) {
    deleteJob(jobId: $jobId) {
      ok
    }
  }
`

export const DELETE_JOB_TEMPATE = gql`
  mutation deleteJobTemplate($jobTemplateId: String!) {
    deleteJobTemplate(jobTemplateId: $jobTemplateId) {
      ok
    }
  }
`

export const RESTORE_JOB = gql`
  mutation restoreJob($jobId: String!) {
    restoreJob(jobId: $jobId) {
      ok
    }
  }
`

export const COPY_JOB = gql`
  mutation copyJob($jobId: String!) {
    copyJob(jobId: $jobId) {
      id
    }
  }
`

export const SET_JOB_OWNER = gql`
  mutation setJobOwner($jobId: String!, $ownerId: String!) {
    setJobOwner(jobId: $jobId, ownerId: $ownerId) {
      ok
    }
  }
`
export const SET_JOB_QA = gql`
  mutation setJobQa($jobId: String!, $qaId: String!) {
    setJobQa(jobId: $jobId, qaId: $qaId) {
      jobId
    }
  }
`

export const SET_JOB_MANUAL_PRODUCT = gql`
  mutation setJobManualProduct($jobId: String!, $manualProduct: String!) {
    setJobManualProduct(jobId: $jobId, manualProduct: $manualProduct) {
      job {
        id
        manualProduct
      }
    }
  }
`

export const QA_JOB = gql`
  mutation qaJob(
    $jobId: String!
    $fieldsMissingInformation: [String!]!
    $fieldsWrongInformation: [String!]!
    $fieldsTypos: [String!]!
    $otherInfo: String!
  ) {
    qaJob(
      jobId: $jobId
      fieldsMissingInformation: $fieldsMissingInformation
      fieldsWrongInformation: $fieldsWrongInformation
      fieldsTypos: $fieldsTypos
      otherInfo: $otherInfo
    ) {
      ok
    }
  }
`

export const CONFIRM_JOBS = gql`
  mutation confirmJobs($jobIds: [UUID!]!, $proofUrl: String!, $filename: String!) {
    confirmJobs(jobIds: $jobIds, proofUrl: $proofUrl, filename: $filename) {
      ok
    }
  }
`

export const MOVE_JOB_TO_IN_PROGRESS = gql`
  mutation moveJobToInProgress($jobId: String!) {
    moveJobToInProgress(jobId: $jobId) {
      ok
    }
  }
`

export const BULK_UPDATE_JOB_STATUS = gql`
  mutation bulkUpdateJobStatus($jobIds: [String!]!, $status: String!) {
    bulkUpdateJobStatus(jobIds: $jobIds, status: $status) {
      ok
    }
  }
`

export const BULK_UPDATE_JOB_DETAILS = gql`
  mutation bulkUpdateJobDetails(
    $jobIds: [String!]!
    $ownerId: String
    $qaId: String
    $manualProduct: String
    $status: String
  ) {
    bulkUpdateJobDetails(
      jobIds: $jobIds
      ownerId: $ownerId
      qaId: $qaId
      manualProduct: $manualProduct
      status: $status
    ) {
      ok
    }
  }
`

export const BULK_UPDATE_JOB_TASK_ID = gql`
  mutation bulkUpdateJobTaskId($jobIds: [String!]!, $taskId: String!) {
    bulkUpdateJobTaskId(jobIds: $jobIds, taskId: $taskId) {
      ok
    }
  }
`

export const SAVE_JOB_TABLE = gql`
  mutation saveJobTable($jobId: String!, $jobTable: InputJobTable!) {
    saveJobTable(jobId: $jobId, jobTable: $jobTable) {
      ok
    }
  }
`

export const SAVE_JOB_AND_DOCUMENT_TABLES = gql`
  mutation saveJobAndDocumentTables(
    $jobId: UUID!
    $documentTables: [InputDocumentTable!]!
    $jobTable: InputJobTable!
    $validateFields: Boolean!
  ) {
    saveJobAndDocumentTables(
      jobId: $jobId
      documentTables: $documentTables
      jobTable: $jobTable
      validateFields: $validateFields
    ) {
      ok
    }
  }
`

export const EXPORT_JOB_TO_API_PARTNER = gql`
  mutation exportJobToApiPartner(
    $jobId: UUID!
    $exportType: ApiExportType!
    $lumpsum: InputDocumentTable
    $overrideChargeDescription: Boolean
  ) {
    exportJobToApiPartner(
      jobId: $jobId
      exportType: $exportType
      lumpsum: $lumpsum
      overrideChargeDescription: $overrideChargeDescription
    ) {
      ok
      responseText
    }
  }
`

export const EXPORT_E_DOCUMENTS_TO_API_PARTNER = gql`
  mutation exportEDocumentsToApiPartner(
    $jobId: UUID!
    $inputEDocuments: [InputEDocument!]!
    $dataTargetType: EDocsDataTargetType!
  ) {
    exportEDocumentsToApiPartner(
      jobId: $jobId
      inputEDocuments: $inputEDocuments
      dataTargetType: $dataTargetType
    ) {
      ok
    }
  }
`
