import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/styles'
import {
  Mutation,
  MutationCreateTaskArgs,
  Query,
  TaskNode,
  TaskStatus,
  TaskType,
} from '@src/graphql/types'
import Select from '@material-ui/core/Select'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { USER_COMPANIES } from '@src/graphql/queries/company'
import { CREATE_TASK } from '@src/graphql/mutations/task'
import { getDatePickerDate } from '@src/utils/date'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import theme from '@src/utils/theme'
import { useSnackbar } from 'notistack'
import { grey } from '@material-ui/core/colors'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { DeployEnvironment, getDeployEnvironment } from '@src/utils/environment'
import { useHistory } from 'react-router-dom'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { LogEventType } from '@src/utils/observability/LogEventType'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  refetchColumns?: () => void
  callback?: (task: TaskNode) => void
  defaultTask?: TaskNode
}
const useStyles = makeStyles({
  statusAndCompany: {
    color: grey[500],
    paddingBottom: theme.spacing(1),
    display: 'flex',
  },
  taskType: {
    flexGrow: 1,
  },
  inlineFlex: {
    display: 'inline-flex',
  },
  detailTitle: {
    color: grey[500],
    paddingRight: theme.spacing(1),
  },
  hr: {
    border: 'none',
    height: '1px',
    backgroundColor: grey[50],
  },
  column: {
    padding: theme.spacing(3),
  },
  field: {
    width: 300,
  },
  notesField: {
    width: 500,
  },
  createButton: {
    textAlign: 'right',
    paddingTop: theme.spacing(4),
  },
  star: {
    fill: 'gold',
  },
})

const CreateTask: FunctionComponent<Props> = ({
  open,
  setOpen,
  refetchColumns,
  callback,
  defaultTask,
}) => {
  const { logEvent } = useEventLogger()
  const actionStart = useRef<Date>()
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const initialDate = getDatePickerDate()
  const [dateReceived, setDateReceived] = useState(initialDate)
  const [companyId, setCompanyId] = useState(defaultTask?.company?.id || (null as null | string))
  const [isUploading, setIsUploading] = useState(false)
  const [message, setMessage] = useState(defaultTask?.message || '')
  const [starred, setStarred] = useState(defaultTask?.starred || false)
  const [blocked, setBlocked] = useState(defaultTask?.blocked || false)
  const [taskReferenceId, setTaskReferenceId] = useState(defaultTask?.taskReferenceId || '')
  const [taskType, setTaskType] = useState(defaultTask?.taskType || TaskType.Live)
  const [title, setTitle] = useState(defaultTask?.title || '')
  const currentDeployEnv = getDeployEnvironment()

  const { loading: companiesLoading, data: companies } =
    useQuery<Pick<Query, 'companies'>>(USER_COMPANIES)
  const [createTaskMutation] = useMutation<Pick<Mutation, 'createTask'>, MutationCreateTaskArgs>(
    CREATE_TASK,
    {
      onCompleted: (data) => {
        // For create job/move job to new task workflow
        if (callback) {
          callback(data!.createTask!.task!)
        }

        void logEvent(LogEventType.CREATE_TASK, {
          task_id: data!.createTask!.task!.id,
          company_id: data!.createTask!.task!.company.id,
          action_start: actionStart.current,
          action_end: new Date(),
          success: true,
          error_message: '',
        })
      },
    },
  )

  const resetForm = (): void => {
    setDateReceived(initialDate)
    setCompanyId(null)
    setMessage('')
    setTaskReferenceId('')
    setTaskType(TaskType.Live)
    setTitle('')
  }
  const validateFields = (): boolean => {
    let fieldsValid = true
    if (!taskReferenceId) {
      enqueueSnackbar(`Task Reference ID cannot be blank`, { variant: 'error' })
      fieldsValid = false
    }
    if (!companyId) {
      enqueueSnackbar(`Company cannot be blank`, { variant: 'error' })
      fieldsValid = false
    }
    if (!title) {
      enqueueSnackbar(`Title cannot be blank`, { variant: 'error' })
      fieldsValid = false
    }
    return fieldsValid
  }

  useEffect(() => {
    if (open) {
      actionStart.current = new Date()
    }
  }, [open])

  const createTask = async (): Promise<void> => {
    if (!validateFields()) {
      return
    }
    const timezoneAdjustedDate = new Date(dateReceived).toUTCString()
    setIsUploading(true)
    try {
      const createdTaskData = await createTaskMutation({
        variables: {
          companyId: companyId!,
          dateReceived: timezoneAdjustedDate,
          message,
          starred,
          blocked,
          status: TaskStatus.Todo,
          taskReferenceId,
          taskType,
          title,
        },
      })
      const createTaskAction = (): ReactElement => (
        <Button
          onClick={async () => {
            history.push(`/tasks/${createdTaskData!.data!.createTask!.task!.id!}`)
          }}
        >
          Edit
        </Button>
      )
      enqueueSnackbar(`Task created!`, {
        variant: 'success',
        autoHideDuration: 5000,
        action: createTaskAction,
      })
      if (refetchColumns) {
        refetchColumns()
      }
      setOpen(false)
      resetForm()
    } catch (error) {
      const errorMessage = `Error while creating task: ${formatMaybeApolloError(error)}`
      enqueueSnackbar(errorMessage, { variant: 'error' })

      void logEvent(LogEventType.CREATE_TASK, {
        task_id: null,
        company_id: null,
        action_start: actionStart.current,
        action_end: new Date(),
        success: false,
        error_message: errorMessage,
      })
    }
    setIsUploading(false)
  }

  return (
    <Dialog onClose={() => setOpen(false)} maxWidth='lg' open={open} aria-labelledby='a'>
      <Box display='inline-flex'>
        <DialogTitle id='task-details-title'>Task Details</DialogTitle>
        {currentDeployEnv !== DeployEnvironment.PRODUCTION && (
          <IconButton onClick={() => setStarred(!starred)}>
            {starred ? <StarIcon className={classes.star} /> : <StarBorderIcon />}
          </IconButton>
        )}
      </Box>
      <Box display='inline-flex'>
        <div className={classes.column}>
          <Typography>Blocked</Typography>
          <Switch checked={blocked} onChange={(_, newValue) => setBlocked(newValue)} />
          <Typography> Task name </Typography>
          <TextField
            inputProps={{
              'data-testid': 'task-name-textfield',
            }}
            className={classes.field}
            variant='outlined'
            onChange={(e) => setTitle(e.target.value)}
            multiline
            placeholder='Task name'
            defaultValue={defaultTask?.title}
            rows={10}
          />
          <Typography> Task Reference ID </Typography>
          <TextField
            onChange={(e) => setTaskReferenceId(e.target.value)}
            className={classes.field}
            variant='outlined'
            placeholder='Task Reference ID'
            inputProps={{
              'data-testid': 'task-reference-textfield',
            }}
            defaultValue={defaultTask?.taskReferenceId}
          />
          <Typography> Company </Typography>
          <Select
            className={classes.field}
            value={companyId ?? ''}
            onChange={(e) => setCompanyId(e.target.value as string)}
            variant='outlined'
            disabled={isUploading || companiesLoading}
            label='Organization'
            id='company-select'
          >
            {companies?.companies?.map((currCompany) => (
              <MenuItem
                key={currCompany.id}
                value={currCompany.id}
                data-testid={currCompany.name ? currCompany.name : ''}
              >
                {currCompany.name}
              </MenuItem>
            ))}
          </Select>
          <Typography> Live or test </Typography>
          <ToggleButtonGroup
            exclusive
            value={taskType}
            color='primary'
            aria-label='outlined primary button group'
          >
            <ToggleButton value={TaskType.Live} onClick={() => setTaskType(TaskType.Live)}>
              Live
            </ToggleButton>
            <ToggleButton
              value={TaskType.Test}
              onClick={() => setTaskType(TaskType.Test)}
              data-testid='toggle-test-button'
            >
              Test
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography> Date and time received </Typography>
          <TextField
            type='datetime-local'
            value={dateReceived}
            onChange={(e) => setDateReceived(e.target.value)}
            variant='outlined'
            disabled={isUploading}
            placeholder='Date and Time Received'
          />
        </div>
        <div className={classes.column}>
          <Typography> Task message (optional) </Typography>
          <TextField
            onChange={(e) => setMessage(e.target.value)}
            variant='outlined'
            defaultValue={defaultTask?.message}
            className={classes.notesField}
            multiline
            data-testid='task-message'
            rows={30}
          />
          <div className={classes.createButton}>
            <Button
              onClick={createTask}
              variant='contained'
              color='primary'
              data-testid='create-task-button'
            >
              Create Task
            </Button>
          </div>
        </div>
      </Box>
    </Dialog>
  )
}

export default CreateTask
