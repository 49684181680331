import { gql } from '@apollo/client'
import { FIELD_GROUP_FRAGMENT } from '@src/graphql/queries/field'

export const FILE_FRAGMENT = gql`
  fragment FileAttributes on FileNode {
    id
    filename
    senderAddress
    emailSubject
    message
    dateCreated
  }
`

export const FILE_PAGE_FRAGMENT = gql`
  fragment FilePageAttributes on FilePageNode {
    id
    fileId
    status
    imageUrl
    pageNumber
    dateCreated
    jobId
    type
  }
`

// commonly used fragment for pages that need to display the job's document fields and its values
export const FILE_PAGE_WITH_DOCUMENT = gql`
  fragment FilePageWithDocumentAttributes on FilePageNode {
    id
    status
    imageUrl
    pageNumber
    dateCreated
    fileId
    file {
      id
      filename
    }
    document {
      id
      filePage {
        id
        imageUrl
        file {
          id
          filename
        }
      }
      documentType {
        id
        name
        documentTypeFieldGroups {
          edges {
            node {
              id
              fieldGroup {
                id
                key
                name
                repeatable
                fields {
                  edges {
                    node {
                      id
                      key
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      documentFieldGroups {
        edges {
          node {
            id
            rowOrderPriority
            document {
              id
            }
            documentFields {
              edges {
                node {
                  id
                  value
                  confidenceScore
                  top
                  left
                  width
                  height
                  field {
                    id
                    name
                    key
                    valueExportMapping
                  }
                }
              }
            }
            documentTable {
              id
            }
            fieldGroup {
              ...FieldGroupAttributes
            }
          }
        }
      }
    }
  }
  ${FIELD_GROUP_FRAGMENT}
`

export const FILE_PAGES = gql`
  query filePages($filePageIds: [String!]!) {
    filePagesConnection(filePageIds: $filePageIds) {
      items {
        ...FilePageAttributes
        file {
          ...FileAttributes
          companyId
          company {
            id
            name
          }
        }
      }
      total
    }
  }
  ${FILE_FRAGMENT}
  ${FILE_PAGE_FRAGMENT}
`

export const DASHBOARD_FILES = gql`
  query dashhboardFiles($page: Int!, $query: String!, $companyId: String, $status: String!) {
    fileConnection(page: $page, query: $query, status: $status, companyId: $companyId) {
      items {
        ...FileAttributes
        company {
          id
          name
        }
        pages {
          edges {
            node {
              ...FilePageAttributes
            }
          }
        }
      }
      total
    }
  }
  ${FILE_FRAGMENT}
  ${FILE_PAGE_FRAGMENT}
`

export const GET_PAGE_ASYNC_BATCH = gql`
  query getPageAsyncBatch($id: UUID!) {
    pageAsyncBatch(id: $id) {
      id
      type
      status
      errorMessage
      numDone
      numTasks
    }
  }
`

export const FILE_SIGNED_URL = gql`
  query getFileSignedUrl($filename: String!, $contentType: String!) {
    fileSignedUrl(filename: $filename, contentType: $contentType) {
      viewUrl
      putUrl
    }
  }
`
