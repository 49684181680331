import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { Controller } from 'react-hook-form'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { MinimalCompanyNode, useAllCompaniesQuery } from '@src/graphql/types'
import { FormError } from './util'

type Props = {
  fieldName: string
  friendlyName: string
  formError?: FormError
  multiple?: boolean
  setFormCompanyId?: Dispatch<SetStateAction<string | undefined>>
}

const CompanyAutocomplete: FunctionComponent<Props> = ({
  fieldName,
  friendlyName,
  formError,
  setFormCompanyId,
  multiple = false,
}) => {
  const { data: companiesData, loading } = useAllCompaniesQuery()
  const options = companiesData?.allCompanies.map((company) => ({
    id: company.id,
    name: company.name ?? '',
  }))

  let error = false
  let errorText = ''
  if (formError) {
    error = multiple ? !!formError.operatorCompanies : !!formError.company
    errorText = multiple ? formError.operatorCompanies : formError.company
  }

  if (loading) return <CenteredCircularProgress />
  return (
    <Controller
      render={({ field: { value, onBlur, onChange } }) => (
        <Autocomplete
          multiple={multiple}
          options={options ?? []}
          getOptionLabel={(option: MinimalCompanyNode) => option.name!}
          getOptionSelected={(option, value) =>
            Array.isArray(value) ? value.some((v) => v.id === option.id) : value?.id === option.id
          }
          onBlur={onBlur}
          value={value}
          onChange={(_event, newValue) => {
            if (setFormCompanyId && !Array.isArray(newValue)) setFormCompanyId(newValue?.id)
            onChange(newValue)
          }}
          renderInput={(params) => (
            <TextField
              variant='outlined'
              {...params}
              label={friendlyName}
              error={error}
              helperText={errorText}
            />
          )}
        />
      )}
      name={fieldName}
    />
  )
}

export default CompanyAutocomplete
