/* eslint-disable @typescript-eslint/no-var-requires */
import { FunctionComponent, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { HotTable } from '@handsontable/react'
import {
  InputDocumentTable,
  JobTemplateReconType,
  DocumentTableNode,
  InputReconPremiumRate,
  MatchingCriteriaType,
} from '@src/graphql/types'
import { getLumpsum, getLumpsumTable, getLumpsumInputTable } from '@src/utils/lumpsum'
import { removeChargeDescription } from '@src/utils/override_charge_description'
import ReadOnlyDocumentHotTable from '../read-only-document-hot-table/ReadOnlyDocumentHotTable'
import { CwTargetModuleDisplayText } from '@src/utils/recon/ap_recon'

type Props = {
  reconcile: (
    lumpsum: InputDocumentTable | null,
    premiumRateDetails: InputReconPremiumRate | null,
    overrideChargeDescription?: boolean,
    disableSendDueDate?: boolean,
    matchingCriteria?: MatchingCriteriaType,
  ) => Promise<void>
  reconTables: DocumentTableNode[]
  reconType: JobTemplateReconType.Ap | JobTemplateReconType.ArrivalNotice
  cargowiseModule: string
}

const ReconciliationReviewDefault: FunctionComponent<Props> = ({
  reconcile,
  reconTables,
  reconType,
  cargowiseModule,
}) => {
  const lumpsumHotTableRef = useRef<HotTable>()
  const [reconcileAsLumpsum, setReconcileAsLumpsum] = useState(false)
  const [overrideChargeDescriptionFlag, setOverrideChargeDescriptionFlag] = useState(false)
  const [disableSendDueDateFlag, setDisableSendDueDateFlag] = useState(false)
  const [matchingCriteria, setMatchingCriteria] = useState(MatchingCriteriaType.ChargeCodeOnly)
  const [reconciling, setReconciling] = useState(false)
  const [initLumpsum, setInitLumpsum] = useState({} as Record<string, string | number>)

  useEffect(() => {
    const lumpsumTable = getLumpsumTable(reconTables, reconType)
    if (lumpsumTable) {
      if (!overrideChargeDescriptionFlag) {
        const filteredLumpsumTable = removeChargeDescription(lumpsumTable)
        const { lumpsumRow } = getLumpsum(filteredLumpsumTable, reconType)
        setInitLumpsum(lumpsumRow)
      } else {
        const { lumpsumRow } = getLumpsum(lumpsumTable, reconType)
        setInitLumpsum(lumpsumRow)
      }
    }
  }, [reconTables, reconType, overrideChargeDescriptionFlag])

  const handleReconcile = async (): Promise<void> => {
    setReconciling(true)
    const lumpsumTable = getLumpsumTable(reconTables, reconType)
    const lumpsumRows = reconcileAsLumpsum
      ? lumpsumHotTableRef.current?.hotInstance!.getData()
      : null
    const lumpsumColumns = Object.keys(initLumpsum)
    const lumpsumInputTable =
      lumpsumRows && lumpsumTable
        ? getLumpsumInputTable(lumpsumRows[0], lumpsumColumns, lumpsumTable)
        : null
    if (lumpsumRows) {
      const newLumpsum = Object.fromEntries(
        lumpsumColumns.map((column, idx) => [column, lumpsumRows[0][idx]]),
      )
      setInitLumpsum(newLumpsum)
    }
    try {
      await reconcile(
        lumpsumInputTable,
        null,
        overrideChargeDescriptionFlag,
        disableSendDueDateFlag,
        matchingCriteria,
      )
    } catch {
      setReconciling(false)
    }
  }

  const toggleReconcileAsLumpSum = (): void => setReconcileAsLumpsum(!reconcileAsLumpsum)
  const toggleOverrideChargeDescriptionFlag = (): void =>
    setOverrideChargeDescriptionFlag(!overrideChargeDescriptionFlag)

  const toggleMatchingCriteria = useCallback(
    (chosenMatchingCriteria: MatchingCriteriaType): void => {
      if (matchingCriteria === chosenMatchingCriteria) {
        setMatchingCriteria(MatchingCriteriaType.ChargeCodeOnly)
      } else {
        setMatchingCriteria(chosenMatchingCriteria)
      }
    },
    [matchingCriteria],
  )

  return (
    <>
      {reconTables.map((docTable) => {
        return (
          <div key={`recon-table-${docTable.id}`}>
            <Typography variant='h6' gutterBottom>
              {docTable.fieldGroup?.name}
            </Typography>
            <ReadOnlyDocumentHotTable
              documentTable={docTable}
              showChargeDescription={overrideChargeDescriptionFlag}
            />
          </div>
        )
      })}
      <Box display='flex' alignItems='center'>
        <Checkbox
          data-testid='lumpsum-toggle'
          checked={reconcileAsLumpsum}
          onChange={toggleReconcileAsLumpSum}
        />
        <Typography>Reconcile as lumpsum?</Typography>
      </Box>
      {reconcileAsLumpsum && (
        <div data-testid='lumpsum-table'>
          <HotTable
            ref={lumpsumHotTableRef as RefObject<HotTable>}
            style={{ width: '100%', overflow: 'hidden', height: '80px' }}
            data={[Object.values(initLumpsum)]}
            rowHeaders
            colHeaders={Object.keys(initLumpsum)}
            stretchH='all'
            autoRowSize={true}
          />
        </div>
      )}
      {reconType === JobTemplateReconType.Ap && (
        <>
          {cargowiseModule !== CwTargetModuleDisplayText.FORWARDING_CONSOL && (
            <Box display='flex' alignItems='center'>
              <Checkbox
                checked={overrideChargeDescriptionFlag}
                onChange={toggleOverrideChargeDescriptionFlag}
                data-testid={'override-checkbox'}
              />
              <Typography>Override Charge Description in CW</Typography>
            </Box>
          )}
          <Box display='flex' alignItems='center'>
            <Checkbox
              checked={disableSendDueDateFlag}
              onChange={() => setDisableSendDueDateFlag(!disableSendDueDateFlag)}
              data-testid='disable-send-due-date'
            />
            <Typography>Do NOT send Due Date</Typography>
          </Box>
          <Box display='flex' alignItems='center'>
            <Checkbox
              checked={matchingCriteria === MatchingCriteriaType.VendorInvoiceNumber}
              onChange={() => toggleMatchingCriteria(MatchingCriteriaType.VendorInvoiceNumber)}
              data-testid='recon-criteria-toggle'
            />
            <Typography>
              Match by Vendor and Invoice Number Strictly (Missing Invoice Number{' '}
              <strong>NOT</strong> matched)
            </Typography>
          </Box>
          <Box display='flex' alignItems='center'>
            <Checkbox
              checked={matchingCriteria === MatchingCriteriaType.NonStrictVendorInvoiceNumber}
              onChange={() =>
                toggleMatchingCriteria(MatchingCriteriaType.NonStrictVendorInvoiceNumber)
              }
              data-testid='recon-criteria-toggle'
            />
            <Typography>
              Match by Vendor and Invoice Number (Missing Invoice Number matched)
            </Typography>
          </Box>
          <Box display='flex' alignItems='center'>
            <Checkbox
              checked={matchingCriteria === MatchingCriteriaType.ChargeCodeVendorInvoiceNumber}
              onChange={() =>
                toggleMatchingCriteria(MatchingCriteriaType.ChargeCodeVendorInvoiceNumber)
              }
              data-testid='recon-criteria-toggle'
            />
            <Typography>Match by Charge Code, Vendor, and Invoice Number</Typography>
          </Box>
        </>
      )}
      <Box textAlign='center' p={2}>
        <Button
          id='recon-button'
          data-testid='recon-button'
          variant='contained'
          color='primary'
          onClick={handleReconcile}
          disabled={reconciling}
        >
          Reconcile
        </Button>
      </Box>
    </>
  )
}

export default ReconciliationReviewDefault
