import { gql } from '@apollo/client'

export const GET_JOB_TEMPLATE = gql`
  query jobTemplate($id: UUID!, $companyId: UUID) {
    jobTemplate(id: $id, companyId: $companyId) {
      id
      name
      description
      slaTime
      reconType
      shipmentOpsTypes
      adminDisabled
      credentialId
      apiPartnerId
      defaultExternalAssignee {
        id
        email
      }
      autoReconEnabled
      subtotalsRowEnabled
      mainTabEnabled
      autoPostEnabled
      showPostButton
      requireShowReconToCustomer
      requireEdocsPushPull
      requireExternalAssignee
      companyId
      apiPartner {
        id
        name
        apiPartnerCode {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ApiPartnerCodeEnum {
            value
            isFallback
          }
        }
      }
      company {
        id
        name
      }
      jobTemplateExport {
        id
        jobTemplateExportType
        jobTemplateExportSheets {
          edges {
            node {
              id
              displayColumnHeader
              order
              fieldGroup {
                id
                name
              }
              jobTemplateExportColumns {
                edges {
                  node {
                    id
                    columnName
                    fixedValue
                    order
                    field {
                      id
                      name
                      values
                    }
                  }
                }
              }
            }
          }
        }
      }
      documentTypes {
        edges {
          node {
            id
            name
            isStandard
            collapsible
            tableShowsPreset
            cargowiseFileTypeId
            cargowiseFileType {
              id
              code
              description
            }
            autofillExtractorKey
            derivedFromId
            isEDocPublishedByDefault
            documentTypeFieldGroups {
              edges {
                node {
                  id
                  fieldGroup {
                    id
                    key
                    name
                    exportKey
                    repeatable
                    autofillKey
                    fields {
                      edges {
                        node {
                          id
                          name
                          key
                          autofillKey
                          exportKey
                          validatorRegex
                          validatorDescription
                          dateFormatString
                          required
                          rows
                          values
                          allowFreeText
                          columnOrder
                          defaultValue
                          fieldType {
                            ... on FallbackValue {
                              fallbackValue
                              isFallback
                            }
                            ... on FieldTypeEnum {
                              value
                              isFallback
                            }
                          }
                          partialTableAllowed
                          invalidCharsRegex
                          valueExportMapping
                          searchableRecord {
                            id
                            searchKey
                            displayKey
                            model
                            apiUrl
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      reconThresholdSettings {
        edges {
          node {
            id
            enabled
            dateDisabled
            reconThresholdRanges {
              edges {
                node {
                  id
                  minimum
                  maximum
                  thresholdAmount
                  usePercent
                  useAbsoluteValueMatching
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_JOB_TEMPLATES = gql`
  query jobTemplates($companyId: UUID) {
    jobTemplates(companyId: $companyId) {
      id
      name
      company {
        id
        name
      }
    }
  }
`

export const IS_VALID_RECON_JOB_TEMPLATE_QUERY = gql`
  query isValidReconJobTemplate($jobTemplateId: UUID!) {
    isValidReconJobTemplate(jobTemplateId: $jobTemplateId) {
      ok
      errors
    }
  }
`
