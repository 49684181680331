import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react'
import Handsontable from 'handsontable'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { useSelector } from 'react-redux'
import { RootState } from '@src/utils/store'
import { JobDataContext } from '@src/contexts/job_data_context'
import { LogEventType } from '@src/utils/observability/LogEventType'

export type FastHotTableRefValue = {
  hotInstance: Handsontable
  hotElementRef: HTMLElement | null
}

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[][]
  hooks: Handsontable.Hooks
  settings?: Handsontable.DefaultSettings
  style?: React.CSSProperties
  className?: string
  hotTableRef?: MutableRefObject<FastHotTableRefValue | undefined>
}

/**
 * Handsontable React wrapper optimized to not reapply all settings on every tiny
 * change
 */
const FastHotTable: FunctionComponent<Props> = ({
  data,
  settings,
  style,
  className,
  hooks,
  hotTableRef,
}) => {
  const hotInstance = useRef<Handsontable>()
  const hotElement = useRef<HTMLDivElement | null>(null)
  const [hotTableId] = useState(() => Math.random().toString(36))
  const currentHooks = useRef<Handsontable.Hooks>({})

  const job = useSelector((state: RootState) => state.documentEditor.job)
  const currentFilePageId = useSelector((state: RootState) => state.jobEditor.currentFilePageId)
  const { filePages } = useContext(JobDataContext)
  const { logEvent } = useEventLogger()

  const actionStart = useRef<Date>()
  const onCellSelect = useCallback(() => {
    actionStart.current = new Date()
  }, [])

  // Get the column index based on the header text for e2e tests
  const getColumnIndexByHeader = (headerText: string, hot: Handsontable): number => {
    const headers = hot.getColHeader()
    return headers.indexOf(headerText)
  }

  // Modify the column data based on header text for e2e tests
  const attachDataTestIdToColumn = (
    headerText: string,
    dataTestId: string,
    hot: Handsontable,
  ): void => {
    // We already know that the header index is basically just
    const colIdx = getColumnIndexByHeader(headerText, hot)
    if (colIdx === -1) return

    const rowCount = hot.countRows()
    for (const rowIdx of Array(rowCount).keys()) {
      // .map is throwing an error idk why
      hot.getCell(rowIdx, colIdx)?.setAttribute('data-testid', dataTestId)
    }
  }
  useEffect(() => {
    if (hotTableId) {
      hotInstance.current = new Handsontable(hotElement.current as HTMLDivElement, {
        data,
        ...settings,
        ...hooks,
      })
      if (hotTableRef) {
        hotTableRef.current = {
          hotInstance: hotInstance.current,
          hotElementRef: hotElement.current,
        }
        attachDataTestIdToColumn('Charge Amount', 'charge-amt-cell', hotInstance.current)
        attachDataTestIdToColumn('Charge Cost', 'charge-cost-cell', hotInstance.current)
        attachDataTestIdToColumn('Unit Price', 'unit-price-cell', hotInstance.current)
        attachDataTestIdToColumn(
          'Charge Description',
          'charge-description-cell',
          hotInstance.current,
        )
      }
    }
    return () => {
      hotInstance.current?.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotTableId])
  useEffect(() => {
    if (hotInstance.current && !hotInstance.current.isDestroyed && data) {
      hotInstance.current.loadData(data)
    }
  }, [data])
  useEffect(() => {
    if (hotInstance.current && !hotInstance.current.isDestroyed && settings) {
      hotInstance.current.updateSettings(settings, false)
    }
  }, [settings])
  useEffect(() => {
    if (hotInstance.current && !hotInstance.current.isDestroyed && hooks) {
      for (const [hookName, hookFn] of Object.entries(currentHooks.current)) {
        if (hookFn) {
          hotInstance.current!.removeHook(hookName, hookFn)
        }
      }
      for (const [hookName, hookFn] of Object.entries(hooks)) {
        if (hookFn) {
          hotInstance.current!.addHook(hookName, hookFn)
        }
      }
      currentHooks.current = hooks
    }
  }, [hooks])
  useEffect(() => {
    const logEventEditCell = (changes: [number, number, string, string][]): void => {
      if (changes?.length === 1) {
        const [cellRow, cellColumn, previousValue, currentValue] = changes[0]
        void logEvent(LogEventType.EDIT_LINE_ITEMS_CELL, {
          job_id: job?.id || '',
          action_start: actionStart.current,
          action_end: new Date(),
          cell_row: cellRow,
          cell_column: cellColumn,
          previous_value: previousValue,
          current_value: currentValue,
          table_id: hotTableId,
          page_id: currentFilePageId ?? (filePages && filePages[0]?.id),
          event_time: new Date(),
        })
      } else if (changes?.length > 1) {
        const { minRow, maxRow, minCol, maxCol } = changes.reduce(
          (acc, [row, col]) => ({
            minRow: Math.min(row, acc.minRow),
            maxRow: Math.max(row, acc.maxRow),
            minCol: Math.min(col, acc.minCol),
            maxCol: Math.max(col, acc.maxCol),
          }),
          {
            minRow: Number.MAX_SAFE_INTEGER,
            maxRow: Number.MIN_SAFE_INTEGER,
            minCol: Number.MAX_SAFE_INTEGER,
            maxCol: Number.MIN_SAFE_INTEGER,
          },
        )
        void logEvent(LogEventType.EDIT_LINE_ITEMS_BATCH_EDIT, {
          job_id: job?.id || '',
          action_start: actionStart.current,
          action_end: new Date(),
          min_row: minRow,
          max_row: maxRow,
          min_col: minCol,
          max_col: maxCol,
          table_id: hotTableId,
          page_id: currentFilePageId ?? (filePages && filePages[0]?.id),
          event_time: new Date(),
        })
      }
      actionStart.current = new Date()
    }
    if (hotInstance.current && !hotInstance.current.isDestroyed) {
      hotInstance.current?.addHook('beforeChange', logEventEditCell as () => void)
      hotInstance.current?.addHook('afterSelectionEnd', onCellSelect as () => void)
    }
  }, [currentFilePageId, filePages, hotTableId, job?.id, onCellSelect])
  return <div style={style} className={className} id={`hot-${hotTableId}`} ref={hotElement}></div>
}

export default FastHotTable
