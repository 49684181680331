import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { FunctionComponent } from 'react'
import { LogEventType } from '@src/utils/observability/LogEventType'
import { useEventLogger } from '@src/utils/observability/useEventLogger'

const LogoutButton: FunctionComponent = () => {
  const { logout } = useAuth0()
  const { logEvent } = useEventLogger()
  return (
    <Button
      onClick={() => {
        void logEvent(LogEventType.ACCOUNT_SIGN_OUT)
        logout({
          returnTo: window.location.origin,
        })
      }}
      color={'inherit'}
      startIcon={<ExitToAppIcon />}
    >
      Log Out
    </Button>
  )
}

export default LogoutButton
