import { ApReconPrimaryAutofillKey, ApReconSecondaryAutofillKey } from '@src/graphql/types'
import { SpreadsheetDataColumn } from './data-grid'
import { stringifyList } from './string'
import { SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING } from './app_constants'
import { isValidRecentDate } from './date'
import { ApolloError } from '@apollo/client/errors'

// Identify missing primary and secondary fields (if any) and return corresponding error message.
export const checkMissingFields = (
  columns: SpreadsheetDataColumn[],
  rows: (string | null)[][],
): string | null => {
  let errorMessage = null
  const apReconPrimaryAutofillKeys = Object.values(ApReconPrimaryAutofillKey).map((field) =>
    field.toLowerCase(),
  )
  const apReconSecondaryAutofillKeys = Object.values(ApReconSecondaryAutofillKey).map((field) =>
    field.toLowerCase(),
  )

  for (const row of rows) {
    const presentFields = row.reduce((presentFieldsAcc, rowVal, colIdx) => {
      const autofillKey = columns[colIdx]?.autofillKey
      if (rowVal && autofillKey) {
        presentFieldsAcc.push(autofillKey)
      }
      return presentFieldsAcc
    }, [] as string[])
    const isMissingAllPrimaryFields = apReconPrimaryAutofillKeys.every(
      (field) => !presentFields.includes(field),
    )
    const missingSecondaryFields = apReconSecondaryAutofillKeys.filter(
      (field) => !presentFields.includes(field),
    )

    if (isMissingAllPrimaryFields) {
      errorMessage = `Some of the line items do not have at least one of: Reference Number, HBL Number, MBL Number, Carrier Booking Number, Consol Number, or Container Number`
      return errorMessage
    } else if (missingSecondaryFields.length > 0) {
      errorMessage = `Some of the line items are missing at least one of the following fields: ${stringifyList(
        missingSecondaryFields,
      )}`
      return errorMessage
    }
  }
  return errorMessage
}

export const checkApReconMissingFields = (
  nonRepeatableFieldValueMap: Record<string, string | null>,
  repeatableFieldValueMap: Record<string, string | null>[],
): string | null => {
  let errorMessage = null
  const presentFields = Object.keys(
    Object.fromEntries(
      Object.entries(nonRepeatableFieldValueMap).filter(([_, fieldVal]) => fieldVal),
    ),
  )
  const apReconPrimaryAutofillKeys = Object.values(ApReconPrimaryAutofillKey).map((field) =>
    field.toLowerCase(),
  )
  const apReconSecondaryAutofillKeys = Object.values(ApReconSecondaryAutofillKey).map((field) =>
    field.toLowerCase(),
  )

  const isMissingAllPrimaryFields = apReconPrimaryAutofillKeys.every(
    (field) => !presentFields.includes(field),
  )

  const missingSecondaryFields = [] as string[]

  const secondaryFieldsInLineItems = apReconSecondaryAutofillKeys.filter((field) =>
    Object.keys(nonRepeatableFieldValueMap).includes(field),
  )

  repeatableFieldValueMap.forEach((fieldValueMap) => {
    Object.entries(fieldValueMap).forEach(([field, value]) => {
      if (secondaryFieldsInLineItems.includes(field) && !value) {
        missingSecondaryFields.push(field)
      }
    })
  })

  if (isMissingAllPrimaryFields) {
    errorMessage = `Metadata must have at least one of: Reference Number, HBL Number, MBL Number, Carrier Booking No., Consol No., Container No.`
  } else if (missingSecondaryFields.length > 0) {
    errorMessage = `Some of the line items are missing at least one of the following fields: ${stringifyList(
      missingSecondaryFields,
    )}`
  }
  return errorMessage
}

export const validateBeforeSOASend = (
  lineItems: Record<
    string,
    Record<string, string | string[] | string[][] | boolean | undefined>[]
  >[],
): string | null => {
  let errorMessage = null
  const errorLines = [] as string[]
  const primaryKeys = [
    SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.invoice_number,
    SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.charge_code,
    SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.currency,
    SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.charge_cost,
    SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.invoice_date,
    SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.due_date,
  ]
  const secondaryKeys = [
    SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.reference_number,
    SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.hbl_number,
    SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.mbl_number,
    SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.carrier_booking_number,
    SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.consol_number,
  ]
  lineItems.forEach((invoice, idx) => {
    let missingSecondary = false
    let missingPrimary = false
    for (const line of Object.values(invoice).flat()) {
      // only need one secondary key
      missingSecondary = !secondaryKeys.some((field) => line[field])
      // need all primary keys
      missingPrimary = primaryKeys.some((field) => !line[field])
      if (missingPrimary || missingSecondary) {
        errorLines.push(` [${idx}] ${line[SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING.invoice_number]}`)
        break
      }
    }
  })
  if (errorLines.length) {
    errorMessage = `Error: fields missing in row(s) ${errorLines}`
  }
  return errorMessage
}

const validateFieldValue = (column: SpreadsheetDataColumn, value: string | null): boolean => {
  // Returns true if invalid
  const { required, _validator, strict, source, type, searchableRecord, dateFormat } = column
  const values = (type === 'autocomplete' && !searchableRecord ? source : undefined) as
    | string[]
    | undefined
  const isInvalidRequiredValue = !value && required
  const isInvalidPatternMatchedValue = value && _validator && !_validator.test(value)
  const isInvalidDropdownValue = value && strict && values?.length && !values.includes(value)
  const isInvalidDateFormat =
    type === 'date' && dateFormat && value && !isValidRecentDate(value, dateFormat)
  return !!(
    isInvalidRequiredValue ||
    isInvalidPatternMatchedValue ||
    isInvalidDropdownValue ||
    isInvalidDateFormat
  )
}

export const validateSoaTableFields = (
  columns: SpreadsheetDataColumn[],
  rows: (string | null)[][],
): boolean => {
  // Returns false if a field is invalid
  for (const row of rows) {
    for (const [colIdx, col] of columns.entries()) {
      const value = row[colIdx]
      const fieldIsInvalid = validateFieldValue(col, value)
      if (fieldIsInvalid) {
        return false
      }
    }
  }
  return true
}

export const formatMaybeApolloError = (e: unknown): string => {
  if (e instanceof ApolloError) {
    return e.message
  } else if (
    typeof e === 'object' &&
    e !== null &&
    'message' in e &&
    typeof e.message === 'string'
  ) {
    return e.message || 'Unknown error'
  } else {
    return `${e}`
  }
}
