// adapted from https://auth0.com/blog/complete-guide-to-react-user-authentication/
import { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { DeployEnvironment, getDeployEnvironment } from '@src/utils/environment'

const oneWeekSeconds = 3600 * 24 * 7
const environmentClientIdMap = {
  [DeployEnvironment.DEVELOPMENT]: 'gcFlD2KR8npzlQrQVx1kKTbotGVS3KuO',
  [DeployEnvironment.ENGINEERING]: '5k1CjFX44pJAJKjrTf9DLm4UP2Gk6iFu',
  [DeployEnvironment.STAGING]: 'Yi7BDmdUMcceW9LpiHQioVxhKQQhLpLn',
  [DeployEnvironment.PRODUCTION]: '0OVN4HBXBAhXYUzkcozXvODTwREt5gTN',
}

const environmentAudienceMap = {
  [DeployEnvironment.DEVELOPMENT]: 'http://localhost:3000/cauldron-api',
  [DeployEnvironment.ENGINEERING]: 'https://eng-app.expedock.com/cauldron-api',
  [DeployEnvironment.STAGING]: 'https://staging-app.expedock.com/cauldron-api',
  [DeployEnvironment.PRODUCTION]: 'https://app.expedock.com/cauldron-api',
}

const environmentDomainMap = {
  [DeployEnvironment.DEVELOPMENT]: 'expedock-dev.us.auth0.com',
  [DeployEnvironment.ENGINEERING]: 'expedock-eng.us.auth0.com',
  [DeployEnvironment.STAGING]: 'expedock-stg.us.auth0.com',
  [DeployEnvironment.PRODUCTION]: 'expedock.us.auth0.com',
}

/**
 * Wrapper around Auth0Provider to provide integration with react-router and provide parameters
 */
const Auth0ProviderWithHistory: FunctionComponent = ({ children }) => {
  const history = useHistory()

  const onRedirectCallback = (appState: AppState | undefined): void => {
    history.push(appState?.returnTo || window.location.pathname)
  }
  const environment = getDeployEnvironment()

  return (
    <Auth0Provider
      domain={environmentDomainMap[environment]}
      audience={environmentAudienceMap[environment]}
      scope={'cauldron'}
      clientId={environmentClientIdMap[environment]}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      maxAge={oneWeekSeconds}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
