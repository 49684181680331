import { FunctionComponent } from 'react'
import { Box, Paper, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import theme from '@src/utils/theme'
import NewChargeCodeButton from './NewChargeCodeButton'

type Props = {
  newChargeCode: () => void
}

const useStyles = makeStyles<Theme>({
  chargeCodeTextHeader: {
    color: theme.palette.grey['500'],
    fontSize: `${theme.spacing(4)}px`,
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey['500']}`,
  },
})

const ChargeCodeMappingHeader: FunctionComponent<Props> = ({ newChargeCode }) => {
  const classes = useStyles()

  return (
    <Paper square className={classes.paper}>
      <Box display='flex' justifyContent='space-between'>
        <Typography className={classes.chargeCodeTextHeader}> Charge Codes: </Typography>
        <Box pr={1}>
          <NewChargeCodeButton newChargeCode={newChargeCode} />
        </Box>
      </Box>
    </Paper>
  )
}

export default ChargeCodeMappingHeader
