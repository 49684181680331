import { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import GetAppIcon from '@material-ui/icons/GetApp'
import { makeStyles } from '@material-ui/styles'

import { EDocumentNode, Maybe } from '@src/graphql/types'
import theme from '@src/utils/theme'

const useStyles = makeStyles({
  button: {
    marginBottom: theme.spacing(2),
  },
  tableHead: {
    backgroundColor: theme.palette.grey[200],
  },
})

const edocsColumns = Object.freeze(['CW File Type', 'File Name', 'Published', 'Actions'])

type Props = {
  eDocuments: EDocumentNode[]
  selectEdocHandler: (eDocument: Maybe<EDocumentNode>) => void
  deleteEdocHandler: (eDocumentId: string) => Promise<void>
  deleteEdocLoading: boolean
}

const EDocsList: FunctionComponent<Props> = ({
  eDocuments,
  selectEdocHandler,
  deleteEdocHandler,
  deleteEdocLoading,
}) => {
  const classes = useStyles()

  return (
    <Box mb={2}>
      <Table size='small'>
        <TableHead classes={{ root: classes.tableHead }}>
          <TableRow>
            {edocsColumns.map((col, idx) => {
              return <TableCell key={idx}>{col}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {eDocuments.length === 0 ? (
            <TableRow>
              <TableCell colSpan={edocsColumns.length} align='center'>
                No eDocuments found
              </TableCell>
            </TableRow>
          ) : (
            <>
              {eDocuments.map((eDocument) => {
                const cwFileType =
                  (eDocument?.cargowiseFileType?.description ||
                    eDocument?.filePage?.document?.documentType?.cargowiseFileType?.description) ??
                  ''

                return (
                  <TableRow key={eDocument.id}>
                    <TableCell size='small'>{cwFileType}</TableCell>
                    <TableCell size='small' width='60%'>
                      {eDocument.path.toLowerCase().endsWith('.msg') ? (
                        <Link onClick={() => selectEdocHandler(eDocument)}>
                          <Typography>{eDocument.filename}</Typography>
                        </Link>
                      ) : (
                        <Link href={eDocument.eDocUrl as string} target='_blank' rel='noreferrer'>
                          <Typography>{eDocument.filename}</Typography>
                        </Link>
                      )}
                    </TableCell>
                    <TableCell size='small'>
                      <Checkbox checked={eDocument.isPublished} disabled />
                    </TableCell>
                    <TableCell size='small'>
                      <Box display='flex'>
                        <Link href={eDocument!.eDocUrl!} target='_blank' rel='noreferrer'>
                          <IconButton size='small' disableFocusRipple disableRipple>
                            <GetAppIcon />
                          </IconButton>
                        </Link>
                        <IconButton
                          size='small'
                          disabled={deleteEdocLoading}
                          disableFocusRipple
                          disableRipple
                          onClick={() => deleteEdocHandler(eDocument.id)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            </>
          )}
        </TableBody>
      </Table>
    </Box>
  )
}

export default EDocsList
