import { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'

const useStyles = makeStyles({
  warningIcon: {
    marginRight: theme.spacing(2),
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
})

type Props = {
  isOpen: boolean
  deleteFilePageLoading: boolean
  handleClose: () => void
  onDeleteHandler: () => void
}

const DeletePageDialog: FunctionComponent<Props> = ({
  isOpen,
  deleteFilePageLoading,
  handleClose,
  onDeleteHandler,
}) => {
  const classes = useStyles()

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Box display='flex' alignItems='center'>
          <WarningIcon className={classes.warningIcon} color='error' />
          Delete Page
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Deleting this page will also delete all associated fields and line items.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className={classes.deleteButton}
          variant='contained'
          onClick={onDeleteHandler}
          disabled={deleteFilePageLoading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeletePageDialog
