import { Box, Popover, TypographyProps, styled, useTheme } from '@material-ui/core'
import { FunctionComponent, ReactNode, useState } from 'react'
import InfoPopoverIcon from './InfoPopoverIcon'

/**
 * InfoPopover Component
 *
 * This functional component provides an information popover that displays additional information
 * when hovered on.
 * @param {Props} iconColor (optional) - The fill color of the information icon.
 * @param {ReactNode} children - The content to be displayed inside the popover.
 * @param {TypographyProps} typographyProps - Typography props to be applied to the outer Box component.
 * @returns {JSX.Element} - The rendered information popover component.
 */

type Props = {
  iconColor?: string
  width?: string | number
  children: ReactNode
} & TypographyProps

const IconContainer = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
}))

// https://mui.com/material-ui/react-popover/#mouse-over-interaction for more info on the popover

const InfoPopover: FunctionComponent<Props> = ({
  iconColor,
  children,
  width,
  ...typographyProps
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const theme = useTheme()

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handlePopoverClose = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(null)
    event.stopPropagation()
  }

  const open = Boolean(anchorEl)

  return (
    <Box {...typographyProps}>
      <IconContainer
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onClick={open ? handlePopoverClose : handlePopoverOpen}
      >
        <InfoPopoverIcon fill={iconColor} />
      </IconContainer>
      <Popover
        id='mouse-over-popover'
        open={open}
        style={{
          pointerEvents: 'none',
        }}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            position: 'absolute',
            display: 'flex',
            width: width ?? '300px',
            padding: '16px 12px',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '16px',
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 4px 8px 0px rgba(203, 213, 225, 0.50)',
            zIndex: theme.zIndex.tooltip + 1000,
          },
        }}
        disableRestoreFocus
      >
        {children}
      </Popover>
    </Box>
  )
}

export default InfoPopover
