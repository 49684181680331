import { FunctionComponent, useState, ChangeEvent } from 'react'
import { useQuery } from '@apollo/client'
import Box from '@material-ui/core/Box'
import { Query, QueryRecentCargowiseOutboundsArgs } from '@src/graphql/types'
import { checkDateOrder } from '@src/utils/date'
import { checkIfValidUUID } from '@src/utils/cargowise/types'

import { GET_ALL_CW_CONFIGS } from '@src/graphql/queries/cargowise'
import { GET_RECENT_CARGOWISE_OUTBOUNDS } from '../../../graphql/queries/cargowise'

import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import Table from '@material-ui/core/Table'
import { Grid, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import ErrorCard from '@src/error-handling/ErrorCard'

const CargowiseOutboundAdminPage: FunctionComponent = () => {
  const { data: cargowiseConfigsData, error: cargowiseConfigsError } =
    useQuery<Pick<Query, 'cargowiseConfigs'>>(GET_ALL_CW_CONFIGS)
  const [cargowiseConfigId, setCargowiseConfigId] = useState(null as string | null)
  const [integrationId, setIntegrationId] = useState(null as string | null)
  const [cargowiseOutboundId, setCargowiseOutboundId] = useState(null as string | null)
  const [startDate, setStartDate] = useState(null as string | null)
  const [endDate, setEndDate] = useState(null as string | null)
  const [isProcessed, setIsProcessed] = useState(undefined as boolean | undefined)
  const { data: recentCargowiseOutboundsData, error: recentCargowiseOutboundsError } = useQuery<
    Pick<Query, 'recentCargowiseOutbounds'>,
    QueryRecentCargowiseOutboundsArgs
  >(GET_RECENT_CARGOWISE_OUTBOUNDS, {
    variables: {
      inputCwOutboundFilter: {
        cargowiseConfigId: cargowiseConfigId || undefined,
        cargowiseOutboundId: cargowiseOutboundId || undefined,
        integrationId: integrationId || undefined,
        dateStart: startDate || undefined,
        dateEnd: endDate || undefined,
        isProcessed: isProcessed,
      },
    },
    fetchPolicy: 'network-only',
    context: {
      debounceKey: 'getRecentCargowiseOutbounds',
      debounceTimeout: 1000,
    },
  })

  if (cargowiseConfigsError) {
    return <ErrorCard>{cargowiseConfigsError.message}</ErrorCard>
  }
  if (!cargowiseConfigsData) {
    return <CenteredCircularProgress />
  }

  const cargowiseConfigMap = Object.fromEntries(
    cargowiseConfigsData.cargowiseConfigs.map((cargowiseConfig) => [
      cargowiseConfig.id,
      `${cargowiseConfig.integrationId} (${cargowiseConfig.company?.name || 'No company'})`,
    ]),
  )

  return (
    <Box p={3}>
      <h2>Recently Received Cargowise Outbound XMLs</h2>
      <p>
        Displays only the most recent <strong>100</strong> XMLs matching the given criteria
      </p>
      <p>
        Warning: Download links are only valid for 5 minutes. Only one of cargowise config or
        integration ID may be specified. Integration IDs may be in varying orders, e.g., CN0PRDMNL
        may also be shown as CN0MNLPRD (this is a Cargowise &ldquo;quirk&rdquo;).
      </p>
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={6}>
          <Autocomplete
            options={Object.keys(cargowiseConfigMap)}
            getOptionLabel={(option) => cargowiseConfigMap[option] || 'None'}
            value={cargowiseConfigId}
            disabled={!!integrationId}
            onChange={(_event, newValue) => {
              setCargowiseConfigId(newValue)
            }}
            renderInput={(params) => (
              <TextField variant={'outlined'} {...params} label='Cargowise Config' />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant='outlined'
            value={cargowiseOutboundId}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setCargowiseOutboundId(event.target.value)
            }}
            label='Cargowise Outbound ID'
            error={
              cargowiseOutboundId !== null &&
              cargowiseOutboundId !== '' &&
              !checkIfValidUUID(cargowiseOutboundId)
            }
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant='outlined'
            value={integrationId}
            disabled={!!cargowiseConfigId}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setIntegrationId(event.target.value)
            }}
            label='Integration ID (eg CN0MNLPRD)'
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant='outlined'
            type='date'
            value={startDate}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setStartDate(event.target.value)
            }}
            InputLabelProps={{ shrink: true }}
            label='Start Date'
            error={checkDateOrder(startDate, endDate)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant='outlined'
            type='date'
            value={endDate}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setEndDate(event.target.value)
            }}
            InputLabelProps={{ shrink: true }}
            label='End Date'
            error={checkDateOrder(startDate, endDate)}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            defaultValue={'All'}
            options={['Yes', 'No', 'All']}
            onChange={(_event, newValue) =>
              newValue === 'Yes'
                ? setIsProcessed(true)
                : newValue === 'All'
                ? setIsProcessed(undefined)
                : setIsProcessed(false)
            }
            renderInput={(params) => (
              <TextField variant={'outlined'} {...params} label='Processed?' />
            )}
          />
        </Grid>
      </Grid>
      {(recentCargowiseOutboundsError && (
        <ErrorCard>{recentCargowiseOutboundsError.message}</ErrorCard>
      )) ||
        (recentCargowiseOutboundsData && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Integration ID</TableCell>
                  <TableCell>Date Received</TableCell>
                  <TableCell>Processed?</TableCell>
                  <TableCell>Download Link</TableCell>
                  <TableCell>ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentCargowiseOutboundsData!.recentCargowiseOutbounds.map((cargowiseOutbound) => (
                  <TableRow key={cargowiseOutbound.id}>
                    <TableCell>{cargowiseOutbound.integrationId || 'Not Found'}</TableCell>
                    <TableCell>{cargowiseOutbound.dateCreated}</TableCell>
                    <TableCell>{cargowiseOutbound.processed ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      <a href={cargowiseOutbound.signedViewUrl}>Download</a>
                    </TableCell>
                    <TableCell>{cargowiseOutbound.id}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
    </Box>
  )
}

export default CargowiseOutboundAdminPage
