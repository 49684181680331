// Context and provider for text selection and coordinate display (i.e., boxing)
import { createContext, FunctionComponent, useCallback, useMemo, useState } from 'react'
import { UseFormGetValues } from 'react-hook-form'

type ShipmentFormContextType = {
  updateDocumentMapping: (newFilePageId?: string) => unknown
  setUpdateDocumentMapping: (fn: () => (newFilePageId?: string) => unknown) => unknown
  saveAndRefetchDocumentEditorJob: () => Promise<unknown>
  setRefetchDocumentEditorJob: (fn: () => () => Promise<unknown>) => unknown
  setSaveJob: (fn: () => () => Promise<unknown>) => unknown
  refetchJob: () => Promise<unknown>
  saveJob: () => Promise<unknown>
  setValidateFields: (fn: () => () => Promise<unknown>) => unknown
  validateFields: () => Promise<unknown>
  setValidateFieldsSoa: (fn: () => () => Promise<unknown>) => unknown
  validateFieldsSoa: () => Promise<unknown>
  validateAndSaveAll: (
    onError?: (err: Error) => void,
    afterSaveJob?: () => Promise<void>,
  ) => Promise<void>
  validateAndSaveAllSoa: (
    onError?: (err: Error) => void,
    afterSaveJob?: () => Promise<void>,
  ) => Promise<void>
  getFormValues: UseFormGetValues<Record<string, string>> | null
  setGetFormValues: (fn: () => UseFormGetValues<Record<string, string>>) => void
}

const noOp = (): void => {}
const noOpPromise = async (): Promise<void> => {}

const initialShipmentFormContext: ShipmentFormContextType = {
  updateDocumentMapping: noOp,
  setUpdateDocumentMapping: noOp,
  saveAndRefetchDocumentEditorJob: noOpPromise,
  setRefetchDocumentEditorJob: noOp,
  setSaveJob: noOp,
  refetchJob: noOpPromise,
  saveJob: noOpPromise,
  setValidateFields: noOp,
  validateFields: noOpPromise,
  setValidateFieldsSoa: noOp,
  validateFieldsSoa: noOpPromise,
  validateAndSaveAll: noOpPromise,
  validateAndSaveAllSoa: noOpPromise,
  getFormValues: null,
  setGetFormValues: noOp,
}

export const ShipmentFormContext = createContext<ShipmentFormContextType>(
  initialShipmentFormContext,
)

export const ShipmentFormProvider: FunctionComponent = ({ children }) => {
  const [updateDocumentMapping, setUpdateDocumentMapping] = useState(() => () => {})
  const [refetchDocumentEditorJob, setRefetchDocumentEditorJob] = useState<() => Promise<unknown>>(
    async () => {},
  )
  const [saveJob, setSaveJob] = useState<() => Promise<unknown>>(async () => {})
  const [validateFields, setValidateFields] = useState<() => Promise<unknown>>(async () => {})
  const [validateFieldsSoa, setValidateFieldsSoa] = useState<() => Promise<unknown>>(async () => {})
  const saveAndRefetchDocumentEditorJob = useCallback(async () => {
    await saveJob()
    await refetchDocumentEditorJob()
  }, [saveJob, refetchDocumentEditorJob])

  const validateAndSaveAll = useCallback(async (): Promise<void> => {
    await validateFields()
    await saveJob()
  }, [saveJob, validateFields])

  const validateAndSaveAllSoa = useCallback(async (): Promise<void> => {
    await validateFieldsSoa()
    await saveJob()
  }, [saveJob, validateFieldsSoa])

  const [getFormValues, setGetFormValues] = useState<UseFormGetValues<
    Record<string, string>
  > | null>(null)

  const value = useMemo(
    () => ({
      updateDocumentMapping,
      setUpdateDocumentMapping,
      setRefetchDocumentEditorJob,
      setSaveJob,
      saveJob,
      setValidateFields,
      validateFields,
      setValidateFieldsSoa,
      validateFieldsSoa,
      refetchJob: refetchDocumentEditorJob,
      saveAndRefetchDocumentEditorJob,
      validateAndSaveAll,
      validateAndSaveAllSoa,
      getFormValues,
      setGetFormValues,
    }),
    [
      saveAndRefetchDocumentEditorJob,
      updateDocumentMapping,
      refetchDocumentEditorJob,
      saveJob,
      validateFields,
      validateFieldsSoa,
      validateAndSaveAll,
      validateAndSaveAllSoa,
      getFormValues,
      setGetFormValues,
    ],
  )
  return <ShipmentFormContext.Provider value={value}>{children}</ShipmentFormContext.Provider>
}
