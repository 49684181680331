import { IconButton } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { FunctionComponent } from 'react'
import CloseIcon from '@material-ui/icons/Close'

type Props = {
  onClose: () => void
}

const stylePrefix = 'ModalCloseButton'
const classes = {
  close: `${stylePrefix}-close`,
}
const StyledIconButton = styled(IconButton)(() => ({
  [`&.${classes.close}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
}))

const ModalCloseButton: FunctionComponent<Props> = ({ onClose }) => {
  return (
    <StyledIconButton className={classes.close} onClick={onClose}>
      <CloseIcon />
    </StyledIconButton>
  )
}

export default ModalCloseButton
