import { formatMaybeApolloError } from '@src/utils/errors'
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'

import { ASSOCIATE_PAGES_TO_JOB } from '@src/graphql/mutations/job'
import { CREATE_JOB } from '@src/graphql/mutations/job'
import {
  FilePageNode,
  JobNode,
  JobNodeEdge,
  JobTemplateNode,
  Maybe,
  Mutation,
  Query,
  TaskNode,
} from '@src/graphql/types'
import { FILE_PAGES } from '@src/graphql/queries/file'
import { useSnackbar } from 'notistack'
import { v4 as uuid4 } from 'uuid'
import { pollPageAsyncBatch } from '@src/utils/file'
import { TASK_FOR_INGEST } from '@src/graphql/queries/task'

type PageAssoc = {
  filePages: FilePageNode[] | undefined
  selectedJob: Maybe<JobNodeEdge>
  setSelectedJob: (job: Maybe<JobNodeEdge>) => void
  selectedJobTemplate: Maybe<JobTemplateNode>
  setSelectedJobTemplate: (template: Maybe<JobTemplateNode>) => void
  lastAssociatedJob: Maybe<JobNode>
  createAndAssocPageToJob: (
    jobName: string,
    dateReceived: string,
    ownerId: string,
    qaId: string,
    documentTypeId: string,
    taskId: string,
  ) => Promise<void>
  filePageIdsBeingAssociated: string[]
  setFilePageIdsBeingAssociated: Dispatch<SetStateAction<string[]>>
  associatePageToAnExistingJob: (documentTypeId: string) => Promise<void>
  toggleSelectPageId: (filePageId: string) => void
  selectedFilePageIds: string[]
  selectedTask: TaskNode | null
  setSelectedTaskId: (taskId: string) => Promise<void>
}

const usePageAssoc = (filePageIds: string[]): PageAssoc => {
  const { enqueueSnackbar } = useSnackbar()
  const client = useApolloClient()
  const [selectedJob, setSelectedJob] = useState(null as Maybe<JobNodeEdge>)
  const [selectedJobTemplate, setSelectedJobTemplate] = useState(null as Maybe<JobTemplateNode>)
  const [filePageIdsBeingAssociated, setFilePageIdsBeingAssociated] = useState([] as string[])
  const [lastAssociatedJob, setLastAssociatedJob] = useState(null as Maybe<JobNode>)
  const {
    data: filePagesData,
    refetch: refetchFilePages,
    error: filePagesError,
  } = useQuery<Pick<Query, 'filePagesConnection'>>(FILE_PAGES, {
    variables: { filePageIds },
  })
  const { refetch: refetchTask } = useQuery<Pick<Query, 'task'>>(TASK_FOR_INGEST, {
    skip: true,
  })
  const filePages = useMemo(() => filePagesData?.filePagesConnection.items, [filePagesData])
  const [createJob] = useMutation<Pick<Mutation, 'createJob'>>(CREATE_JOB)
  const [associatePagesToJob] =
    useMutation<Pick<Mutation, 'associatePagesToJob'>>(ASSOCIATE_PAGES_TO_JOB)
  const [selectedTask, setSelectedTask] = useState(null as null | TaskNode)
  const setSelectedTaskId = useCallback(
    async (taskId: string) => {
      const resp = await refetchTask({ id: taskId })
      setSelectedTask(resp.data.task!)
    },
    [refetchTask],
  )
  const associatedFilePageIds = useMemo(
    () =>
      filePagesData?.filePagesConnection.items
        .filter((page) => page.jobId != null)
        .map((page) => page.id),
    [filePagesData],
  )

  const [selectedFilePageIds, setSelectedFilePageIds] = useState([] as string[])

  const toggleSelectPageId = (filePageId: string): void => {
    if (
      !selectedFilePageIds.includes(filePageId) &&
      !associatedFilePageIds?.includes(filePageId) &&
      !filePageIdsBeingAssociated.includes(filePageId)
    ) {
      setSelectedFilePageIds([...selectedFilePageIds, filePageId])
    } else {
      setSelectedFilePageIds(selectedFilePageIds.filter((id) => id !== filePageId))
    }
  }

  const createAndAssocPageToJob = async (
    jobName: string,
    dateReceived: string,
    ownerId: string,
    qaId: string,
    documentTypeId: string,
    taskId: string,
  ): Promise<void> => {
    const jobTemplateId = selectedJobTemplate?.id ?? null
    const docTypeIds = new Array(selectedFilePageIds.length).fill(documentTypeId)

    if (jobTemplateId) {
      const batchId = uuid4()
      const result = await createJob({
        variables: {
          jobTemplateId,
          jobName,
          filePageIds: selectedFilePageIds.filter(
            (filePageId) => !filePageIdsBeingAssociated.includes(filePageId),
          ),
          docTypeIds,
          ownerId,
          qaId,
          dateReceived,
          batchId,
          taskId,
        },
      })
      if (result.errors?.length) {
        enqueueSnackbar(`Received error while creating job: ${result.errors[0]}`, {
          variant: 'error',
        })
        return
      }
      try {
        // eslint-disable-next-line no-empty,@typescript-eslint/no-unused-vars
        for await (const _ of pollPageAsyncBatch(client, batchId)) {
        }
      } catch (error) {
        enqueueSnackbar(`Received error while creating job: ${formatMaybeApolloError(error)}`, {
          variant: 'error',
        })
        return
      }
      setLastAssociatedJob(result.data!.createJob!.job)
      try {
        await Promise.all([setSelectedTaskId(taskId), refetchFilePages()])
        if (filePagesError?.message) {
          throw new Error(`Received error while refetching file pages: ${filePagesError?.message}`)
        }
      } catch (error) {
        const defaultErrorMessage = 'There was an error refetching the file pages.'
        enqueueSnackbar(formatMaybeApolloError(error) ?? defaultErrorMessage, { variant: 'error' })
        return
      }
      setSelectedFilePageIds([])
    }
  }

  const associatePageToAnExistingJob = async (documentTypeId: string): Promise<void> => {
    const jobId = selectedJob?.node?.id ?? null
    const docTypeIds = new Array(selectedFilePageIds.length).fill(documentTypeId)

    if (jobId) {
      const batchId = uuid4()
      const result = await associatePagesToJob({
        variables: {
          jobId,
          filePageIds: selectedFilePageIds.filter(
            (filePageId) => !filePageIdsBeingAssociated.includes(filePageId),
          ),
          docTypeIds,
          batchId,
        },
      })
      if (result.errors?.length) {
        enqueueSnackbar(`Received error while associating pages to job: ${result.errors[1]}`, {
          variant: 'error',
        })
      }
      try {
        // eslint-disable-next-line no-empty,@typescript-eslint/no-unused-vars
        for await (const _ of pollPageAsyncBatch(client, batchId)) {
        }
      } catch (error) {
        enqueueSnackbar(
          `Received error while associating pages to job: ${formatMaybeApolloError(error)}`,
          {
            variant: 'error',
          },
        )
        return
      }
      setLastAssociatedJob(result.data!.associatePagesToJob!.job)
      try {
        await refetchFilePages()
        if (filePagesError?.message) {
          throw new Error(`Received error while refetching file pages: ${filePagesError?.message}`)
        }
      } catch (error) {
        const defaultErrorMessage = 'There was an error refetching the file pages.'
        enqueueSnackbar(formatMaybeApolloError(error) ?? defaultErrorMessage, { variant: 'error' })
        return
      }
      setSelectedFilePageIds([])
    }
  }

  return {
    filePages,
    selectedJob,
    setSelectedJob,
    selectedJobTemplate,
    setSelectedJobTemplate,
    lastAssociatedJob,
    filePageIdsBeingAssociated,
    setFilePageIdsBeingAssociated,
    createAndAssocPageToJob,
    associatePageToAnExistingJob,
    toggleSelectPageId,
    selectedFilePageIds,
    selectedTask,
    setSelectedTaskId,
  }
}

export default usePageAssoc
