import { usePostHog } from 'posthog-js/react'
import { useCallback, useContext } from 'react'
import { useEventAttributes } from '@src/utils/observability/useEventAttributes'
import { logAmplitudeEvent } from '@src/utils/observability/useAmplitude'
import { useOpenReplay } from '@src/utils/observability/useOpenReplay'
import { RoutePathContext } from '@src/contexts/route_path'

type EventLogger = {
  logEvent: (event: string, properties?: Record<string, any>) => Promise<void>
}

/**
 * Use this for logging events instead of directly calling the individual analytics services,
 * so we can swap things out if necessary or retrieve common properties
 */
export const useEventLogger = (): EventLogger => {
  // mock out completely if in tests
  if (navigator.userAgent.includes('jsdom')) {
    return { logEvent: async () => undefined }
  }
  // have to disable rules-of-hooks linter since we conditionally terminate (but on a constant, so it's safe)
  /* eslint-disable react-hooks/rules-of-hooks */
  const posthog = usePostHog()
  const openReplay = useOpenReplay()
  const { userAttributes } = useEventAttributes()
  const routePath = useContext(RoutePathContext)
  const logEvent = useCallback(
    async (event: string, properties?: Record<string, any>): Promise<void> => {
      if (!properties) {
        properties = {}
      }
      properties['page'] = routePath
      // do not capture these high volume events in posthog (expensive)
      if (
        !(
          event.startsWith('edit_line_items') ||
          event === 'edit_metafields' ||
          event === 'copy_rows_to_page'
        )
      ) {
        posthog?.capture(event, properties)
      }
      openReplay?.event(event, properties)
      logAmplitudeEvent(userAttributes.email || 'null_id', event, properties)
    },
    [posthog, routePath, userAttributes, openReplay],
  )

  return { logEvent }
}
