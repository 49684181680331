import { FunctionComponent, MutableRefObject, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import theme from '@src/utils/theme'
import { FieldRefRecord } from '@src/types/shipment_form'
import { useSelector } from 'react-redux'
import { RootState } from '@src/utils/store'
import { BoxingContext } from '@src/contexts/boxing_context'
import { SELECTION_BOX_Z_INDEX } from '@src/utils/app_constants'
import { selectFieldCoordinates } from '@src/redux-features/document_editor/field'

type Props = {
  imageWidth: number
  imageHeight: number
  offsetX: number
  offsetY: number
}

type StyleProps = {
  top: number
  left: number
  width: number
  height: number
}

const useStyles = makeStyles<Theme, StyleProps>({
  selectionTextBox: {
    position: 'absolute',
    zIndex: SELECTION_BOX_Z_INDEX,
    top: (props) => props.top,
    left: (props) => props.left,
    height: (props) => props.height,
    width: (props) => props.width,
  },
})

/**
 * The label below the active field selection box
 */
const ActiveFieldSelectionLabel: FunctionComponent<Props> = ({
  imageWidth,
  imageHeight,
  offsetX,
  offsetY,
}) => {
  // this element will (and should) only be rendered when there's an active field key and dimension
  const activeFieldKey = useSelector((state: RootState) => state.documentEditor.activeFieldKey!)
  const dimension = useSelector(
    (state: RootState) => selectFieldCoordinates(state.documentEditor)?.[activeFieldKey],
  )!
  const { fieldMapRef } = useContext(BoxingContext)
  const [top, left, width, height] = dimension
    ? [
        (dimension.top + dimension.height) * imageHeight + offsetY,
        dimension.left * imageWidth + offsetX,
        dimension.width * imageWidth,
        dimension.height * imageHeight,
      ]
    : [0, 0, 0, 0]
  const classes = useStyles({ top, left, width, height })
  /*
   *  Get label from the associated field's aria-label
   */
  const getLabel = (
    shipRef: MutableRefObject<FieldRefRecord>,
    fieldName: string | null,
  ): string => {
    if (fieldName && shipRef?.current[fieldName]) {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const currLabel = shipRef.current[fieldName]?.current as any
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return currLabel?.ariaLabel || ''
    }
    return ''
  }

  return (
    <Box className={classes.selectionTextBox}>
      <TextField
        variant='filled'
        margin='normal'
        disabled
        value={fieldMapRef?.current[activeFieldKey]?.current?.element.current?.value || ''}
        label={getLabel(fieldMapRef, activeFieldKey)}
        InputLabelProps={{
          shrink: true,
          style: {
            color: theme.palette.primary.contrastText,
          },
        }}
        inputProps={{
          style: {
            background: theme.palette.grey['600'],
            color: theme.palette.primary.contrastText,
            width: 'auto',
            fontFamily: 'Roboto Mono',
          },
        }}
      />
    </Box>
  )
}

export default ActiveFieldSelectionLabel
