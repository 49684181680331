import { FilePageNode, JobNode } from '@src/graphql/types'
import { BoxDimension } from '@src/types/ocr'
import { reportRollbarError } from '../../utils/observability/rollbar'
import { DocumentEditorPage } from './document_editor_state'

export type PageFieldEditorState = {
  pages: {
    [pageId: string]: DocumentEditorPage
  }
  jobId: string
} | null

export const initializeDocumentEditorPage = (
  filePageNode: FilePageNode,
  fieldPageMapping?: Record<string, string>,
): { page: DocumentEditorPage; filePageId: string } => {
  const page: DocumentEditorPage = {
    fieldMapping: {} as Record<string, string>,
    fieldCoordinates: {} as Record<string, BoxDimension>,
  }
  const filePageId = filePageNode!.id
  const nonRepeatableFieldGroups =
    filePageNode!.document?.documentFieldGroups!.edges.filter(
      (edge) => !edge!.node!.fieldGroup!.repeatable,
    ) || []
  for (const documentFieldGroup of nonRepeatableFieldGroups) {
    for (const documentField of documentFieldGroup!.node!.documentFields!.edges) {
      const documentFieldNode = documentField!.node
      const { value, top, left, width, height, confirmed } = documentFieldNode!
      const { key } = documentFieldNode!.field!
      page.fieldMapping[key] = value
      page.fieldMapping[`${key}_confirmed`] = confirmed ? 'true' : ''
      page.fieldCoordinates[key] = {
        height: height as number,
        left: left as number,
        top: top as number,
        width: width as number,
      }
      if (fieldPageMapping) {
        if (fieldPageMapping[key] !== undefined) {
          reportRollbarError(
            `${key} is associated with >1 pages: ${filePageId} and ${fieldPageMapping[key]}`,
          )
        }
        fieldPageMapping[key] = filePageId
      }
    }
  }
  return { page, filePageId }
}

export const initializePageFieldEditorState = (job: JobNode): PageFieldEditorState => {
  const pageFieldEditorState: PageFieldEditorState = {
    jobId: job.id,
    pages: {},
  }
  // mapping from field key to page ID
  const fieldPageMapping: Record<string, string> = {}
  for (const filePageEdge of job.filePages!.edges) {
    const { page, filePageId } = initializeDocumentEditorPage(filePageEdge.node, fieldPageMapping)
    pageFieldEditorState.pages[filePageId] = page
  }
  return pageFieldEditorState
}
