import { FunctionComponent, MutableRefObject, useMemo, useState } from 'react'
import { Paper } from '@material-ui/core'
import { HotTable } from '@handsontable/react'
import { useEffect } from 'react'
import { useChargeVendorsLazyQuery } from '@src/graphql/types'
import theme from '@src/utils/theme'
import FastHotTable from '@src/components/fast-hot-table/FastHotTable'
import { ChargeCodeWithVendorArray } from './types'
import { debounce } from 'lodash'

type Props = {
  companyId?: string
  chargeCodes: ChargeCodeWithVendorArray
  hotTableRef: MutableRefObject<HotTable | undefined>
}

const VendorCodesTable: FunctionComponent<Props> = ({ chargeCodes, companyId, hotTableRef }) => {
  const [rows, setRows] = useState([] as string[][])

  const [fetchChargeVendors] = useChargeVendorsLazyQuery({
    fetchPolicy: 'network-only',
  })

  const columns = useMemo(() => {
    return [
      {
        key: 'vendor',
        name: 'Vendor',
        type: 'autocomplete',
        // Reference: https://handsontable.com/docs/javascript-data-grid/api/options/#source (docs for the version used in the project are not available, but this should be a good reference)
        // This allows us to do a fetch upon typing in the cell. A single fetch is not enough because the list of vendors can go beyond the page limit.
        source: debounce(async (query: string, process: (data: unknown) => void) => {
          if (companyId) {
            const { data } = await fetchChargeVendors({
              variables: { companyId: companyId, query },
            })
            const vendors = data?.chargeVendors?.map((chargeVendor) => chargeVendor.name) ?? []
            const uniqueVendors = [...new Set(vendors)]
            process(uniqueVendors)
          }
        }, 300),
        strict: false,
      },
      {
        key: 'description',
        name: 'Description',
      },
    ]
  }, [companyId, fetchChargeVendors])

  useEffect(() => {
    if (chargeCodes) {
      const newRows = chargeCodes
        .filter((chargeCode) => chargeCode?.chargeVendor?.dateDeleted == null)
        .map((chargeCode) => [chargeCode?.chargeVendor?.name || '', chargeCode?.description])
      setRows(newRows)
    }
  }, [chargeCodes])

  return (
    <Paper data-testid='vendor-codes-table'>
      <FastHotTable
        data={rows}
        hotTableRef={hotTableRef}
        hooks={{}}
        style={{
          width: '100%',
          overflow: 'hidden',
          height: '600px',
          background: theme.palette.grey[200],
        }}
        settings={{
          rowHeaders: true,
          contextMenu: ['row_above', 'row_below', 'remove_row'],
          colHeaders: columns.map((col) => col.name),
          columnSorting: true,
          filters: true,
          autoRowSize: true,
          stretchH: 'all',
          columns,
        }}
      />
    </Paper>
  )
}

export default VendorCodesTable
