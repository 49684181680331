import { formatMaybeApolloError } from '@src/utils/errors'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSnackbar } from 'notistack'
import { difference } from 'lodash'
import {
  ApReconAutofillKey,
  AnReconAutofillKey,
  ChargeCodeNodeEdge,
  DocumentFieldGroupNode,
  DocumentTableNode,
  JobTemplateReconType,
  Maybe,
  JobTemplateNode,
  JobTableNode,
  useChargeCodesByVendorNamesLazyQuery,
} from '@src/graphql/types'
import {
  getUniqueChargeCodesFromDocTables,
  getUniqueChargeCodesFromJobTable,
} from '@src/utils/charge_codes'

type ValidateChargeCodesHooksType = {
  isValidatingChargeCodes: boolean
  invalidDocChargeCodes: string[]
  noJobChargeVendor?: Maybe<boolean>
  noJobChargeCodes?: Maybe<boolean>
}

const getUniqueVendorChargeCodes = (
  chargeCodesData: ChargeCodeNodeEdge[],
  apiPartnerId: Maybe<string> | undefined,
): string[] => {
  const vendorChargeCodes =
    chargeCodesData
      .filter(
        (chargeCodeEdge) =>
          chargeCodeEdge!.node!.dateDeleted === null &&
          chargeCodeEdge!.node!.apiPartnerId === apiPartnerId,
      )
      .flatMap((chargeCodeEdge) => chargeCodeEdge!.node!.code) ?? []
  return [...new Set(vendorChargeCodes)]
}

/*
 * Given a docFieldGroups and reconTables from the job data, we get the list of vendor charge codes
 * and return a list of charge codes from the job data that are not in the vendor charge codes list
 */
const useValidateChargeCodes = (
  jobTemplate: Maybe<JobTemplateNode>,
  docFieldGroups: DocumentFieldGroupNode[],
  reconTables: DocumentTableNode[],
  jobTable: JobTableNode | null,
  reconType: JobTemplateReconType,
  jobDataLoading?: boolean,
): ValidateChargeCodesHooksType => {
  const { enqueueSnackbar } = useSnackbar()
  const companyId = jobTemplate?.companyId
  const apiPartnerId = jobTemplate?.apiPartnerId || jobTemplate?.apiPartner?.id
  const [invalidDocChargeCodes, setInvalidDocChargeCodes] = useState([] as string[])
  const [isValidatingChargeCodes, setIsValidatingChargeCodes] = useState(true)
  const [noJobChargeCodes, setNoJobChargeCodes] = useState(null as Maybe<boolean>)

  const chargeCodeAutofillKey =
    reconType === JobTemplateReconType.ArrivalNotice
      ? AnReconAutofillKey.ChargeCode
      : ApReconAutofillKey.ChargeCode
  const vendorAutofillKey =
    reconType === JobTemplateReconType.ArrivalNotice
      ? AnReconAutofillKey.Creditor
      : ApReconAutofillKey.Vendor
  const chargesTableAutofillKey =
    reconType === JobTemplateReconType.ArrivalNotice
      ? AnReconAutofillKey.ChargeLineItem
      : ApReconAutofillKey.InvoiceLineItem

  const [fetchChargeCodesByVendors] = useChargeCodesByVendorNamesLazyQuery()

  const documentChargeVendor = useMemo(() => {
    if (docFieldGroups.length > 0) {
      const docFieldGroupMatched = docFieldGroups.find((docFieldGroup) => {
        const isRepeatable = docFieldGroup!.fieldGroup!.repeatable
        const fieldGroupAutofillKey = docFieldGroup!.fieldGroup!.autofillKey
        return !isRepeatable && fieldGroupAutofillKey === vendorAutofillKey.toLowerCase()
      })
      return docFieldGroupMatched?.documentFields?.edges[0]?.node?.value ?? null
    }
    return null
  }, [docFieldGroups, vendorAutofillKey])

  const documentChargeCodes = useMemo(() => {
    if (reconType === JobTemplateReconType.Soa) {
      return getUniqueChargeCodesFromJobTable(jobTable, chargeCodeAutofillKey)
    } else {
      const chargeTables = reconTables.filter(
        (reconTable) =>
          reconTable!.fieldGroup!.autofillKey === chargesTableAutofillKey.toLowerCase(),
      )
      return getUniqueChargeCodesFromDocTables(chargeTables, chargeCodeAutofillKey)
    }
  }, [reconType, jobTable, reconTables, chargeCodeAutofillKey, chargesTableAutofillKey])

  const validateChargeCodes = useCallback(
    async (docChargeVendor: string, docChargeCodes: string[]): Promise<void> => {
      if (companyId == null) {
        enqueueSnackbar(
          'Unable to validate charge codes due to missing company ID. Please wait a bit and try again. If error persists, contact engineers.',
          { variant: 'error' },
        )
        setIsValidatingChargeCodes(false)
        return
      }

      try {
        const { data } = await fetchChargeCodesByVendors({
          variables: {
            companyId,
            apiPartnerId: apiPartnerId ?? '',
            chargeVendorNames: [docChargeVendor],
          },
        })
        if (data) {
          const chargeCodesData = data.chargeCodesByVendorNames ?? []
          const uniqueVendorChargeCodes = [
            ...new Set(chargeCodesData.flatMap((chargeCode) => chargeCode.code)),
          ]
          const invalidChargeCodes = difference(docChargeCodes, uniqueVendorChargeCodes)
          setInvalidDocChargeCodes(invalidChargeCodes)
        }
      } catch (error) {
        enqueueSnackbar(`Error fetching vendor charge codes: ${formatMaybeApolloError(error)}`, {
          variant: 'error',
        })
      } finally {
        setIsValidatingChargeCodes(false)
      }
    },
    [apiPartnerId, companyId, enqueueSnackbar, fetchChargeCodesByVendors],
  )

  useEffect(() => {
    if (!jobDataLoading && docFieldGroups.length > 0 && isValidatingChargeCodes) {
      if (documentChargeVendor && documentChargeCodes.length > 0) {
        void validateChargeCodes(documentChargeVendor, documentChargeCodes)
      } else if (!documentChargeCodes.length && documentChargeVendor) {
        setIsValidatingChargeCodes(false)
        setNoJobChargeCodes(true)
      } else if (!documentChargeVendor) {
        setIsValidatingChargeCodes(false)
        setNoJobChargeCodes(true)
      }
    }
  }, [
    docFieldGroups,
    documentChargeVendor,
    documentChargeCodes,
    validateChargeCodes,
    jobDataLoading,
    isValidatingChargeCodes,
  ])

  return {
    isValidatingChargeCodes,
    invalidDocChargeCodes,
    noJobChargeVendor: !documentChargeVendor,
    noJobChargeCodes,
  }
}

export default useValidateChargeCodes
