import { FunctionComponent, useContext } from 'react'
import { DeepRequired, FieldErrorsImpl } from 'react-hook-form'
import { makeStyles } from '@material-ui/styles'

import { BoxingContext } from '@src/contexts/boxing_context'
import { DocumentTypeNode, Maybe } from '@src/graphql/types'

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import ShipmentField from './ShipmentField'
import { Accordion, AccordionSummary, Typography, AccordionDetails, Box } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import theme from '@src/utils/theme'
import {
  getDocumentTypeFieldGroups,
  getFieldGroupsNonRepeatableFields,
} from '@src/utils/shipment_form'

type Props = {
  documentTypes: Maybe<DocumentTypeNode>[]
  readOnly: boolean
  errors: FieldErrorsImpl<DeepRequired<Record<string, string>>>
  setActiveFieldAndFocus: (fieldKey: string) => void
  handleQaCorrectionToggle: (id: string, newQACorrections: string[]) => void
  jobInQA: boolean
  qaCorrections: Record<string, string[]>
}

const useStyles = makeStyles({
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionRoot: {
    width: '100%',
  },
})

const ShipmentFields: FunctionComponent<Props> = ({
  documentTypes,
  readOnly,
  errors,
  setActiveFieldAndFocus,
  handleQaCorrectionToggle,
  jobInQA,
  qaCorrections,
}) => {
  const classes = useStyles()
  const { fieldMapRef } = useContext(BoxingContext)
  const seenFields: string[] = []

  const nonCollapsibleDocumentTypes = documentTypes.filter(
    (documentType: Maybe<DocumentTypeNode>) => !documentType?.collapsible,
  )
  const collapsibleDocumentTypes = documentTypes
    .filter((documentType: Maybe<DocumentTypeNode>) => documentType?.collapsible)
    .sort((a, b) => a!.collapsibleOrderPriority - b!.collapsibleOrderPriority)

  return (
    <div>
      {collapsibleDocumentTypes.map((documentType: Maybe<DocumentTypeNode>) => {
        const fieldGroups = getDocumentTypeFieldGroups(documentType)
        const nonRepeatableFields = getFieldGroupsNonRepeatableFields(fieldGroups)
        return (
          <Box className={classes.accordionRoot} px={2} py={1} key={documentType?.id}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>{documentType?.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box display='flex' flexDirection='column' width='100%'>
                  {nonRepeatableFields.map(
                    ({
                      id,
                      name,
                      required,
                      rows,
                      key,
                      autofillKey,
                      values,
                      defaultValue,
                      validatorRegex,
                      validatorDescription,
                      dateFormatString,
                      searchableRecord,
                      allowFreeText,
                    }) => {
                      if (seenFields.includes(key)) {
                        return null
                      }
                      seenFields.push(key)
                      return (
                        <div className={classes.fieldContainer} key={id}>
                          <ShipmentField
                            label={name}
                            name={key}
                            autofillKey={autofillKey}
                            values={(values as string[]) || []}
                            defaultValue={defaultValue || ''}
                            searchableRecord={searchableRecord || undefined}
                            required={required}
                            pattern={validatorRegex || undefined}
                            patternDescription={validatorDescription || undefined}
                            dateFormatString={dateFormatString || undefined}
                            rows={rows}
                            fieldRef={fieldMapRef.current[key]}
                            // this is not type safe, but making it so is prolly not worth the effort, for now.
                            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                            errors={errors as any}
                            setActiveField={() => {
                              setActiveFieldAndFocus(key)
                            }}
                            readOnly={readOnly}
                            allowFreeText={allowFreeText}
                          />
                          {jobInQA && (
                            <div>
                              <ToggleButtonGroup
                                value={qaCorrections[id] ?? []}
                                onChange={(_, newFormats) =>
                                  handleQaCorrectionToggle(id, newFormats)
                                }
                                size='small'
                              >
                                <ToggleButton value='missing'>Missing Info</ToggleButton>
                                <ToggleButton value='wrong'>Wrong Info</ToggleButton>
                                <ToggleButton value='typo'>Typo</ToggleButton>
                              </ToggleButtonGroup>
                            </div>
                          )}
                        </div>
                      )
                    },
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        )
      })}
      {nonCollapsibleDocumentTypes.map((documentType: Maybe<DocumentTypeNode>) => {
        const fieldGroups = getDocumentTypeFieldGroups(documentType)
        const nonRepeatableFields = getFieldGroupsNonRepeatableFields(fieldGroups)
        return nonRepeatableFields.map(
          ({
            id,
            name,
            autofillKey,
            required,
            rows,
            key,
            values,
            defaultValue,
            validatorRegex,
            validatorDescription,
            dateFormatString,
            searchableRecord,
            allowFreeText,
          }) => {
            if (seenFields.includes(key)) {
              return null
            }
            seenFields.push(key)
            return (
              <div className={classes.fieldContainer} key={id}>
                <ShipmentField
                  label={name}
                  name={key}
                  autofillKey={autofillKey}
                  values={(values as string[]) || []}
                  defaultValue={defaultValue || ''}
                  searchableRecord={searchableRecord || undefined}
                  required={required}
                  pattern={validatorRegex || undefined}
                  patternDescription={validatorDescription || undefined}
                  dateFormatString={dateFormatString || undefined}
                  rows={rows}
                  fieldRef={fieldMapRef.current[key]}
                  // this is not type safe, but making it so is prolly not worth the effort, for now.
                  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                  errors={errors as any}
                  setActiveField={() => {
                    setActiveFieldAndFocus(key)
                  }}
                  readOnly={readOnly}
                  allowFreeText={allowFreeText}
                />
                {jobInQA && (
                  <div>
                    <ToggleButtonGroup
                      value={qaCorrections[id] ?? []}
                      onChange={(_, newFormats) => handleQaCorrectionToggle(id, newFormats)}
                      size='small'
                    >
                      <ToggleButton value='missing'>Missing Info</ToggleButton>
                      <ToggleButton value='wrong'>Wrong Info</ToggleButton>
                      <ToggleButton value='typo'>Typo</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                )}
              </div>
            )
          },
        )
      })}
    </div>
  )
}

export default ShipmentFields
