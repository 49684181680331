import { useCallback, useState } from 'react'
import { MatchingCriteriaType } from '@src/graphql/types'

type SoaOverrideOptionsHooksType = {
  overrideChargeDescription: boolean
  setOverrideChargeDescription: (flag: boolean) => void
  disableSendDueDate: boolean
  setDisableSendDueDate: (flag: boolean) => void
  reconAsLumpsum: boolean
  setReconAsLumpsum: (flag: boolean) => void
  reconcileMatchCriteria: MatchingCriteriaType
  toggleReconMatchCriteria: (matchCriteria: MatchingCriteriaType) => void
  isSoaReconOptionsDialogOpen: boolean
  closeSoaReconOptionsDialog: () => void
  openSoaReconOptionsDialog: () => void
  isBatchReconciliationDialogOpen: boolean
  closeSoaReconDialog: () => void
  openSoaReconDialog: () => void
}

const useSoaReconOverrideOptions = (): SoaOverrideOptionsHooksType => {
  const [isSoaReconOptionsDialogOpen, setIsSoaReconOptionsDialogOpen] = useState(false)
  const [isBatchReconciliationDialogOpen, setIsBatchReconciliationDialogOpen] = useState(false)
  const [overrideChargeDescription, setOverrideChargeDescription] = useState(false)
  const [disableSendDueDate, setDisableSendDueDate] = useState(false)
  const [reconcileMatchCriteria, setReconcileMatchCriteria] = useState(
    MatchingCriteriaType.ChargeCodeVendorInvoiceNumber,
  )
  const [reconAsLumpsum, setReconAsLumpsum] = useState(false)

  const toggleReconMatchCriteria = useCallback(
    (matchCriteria: MatchingCriteriaType) => {
      if (reconcileMatchCriteria === matchCriteria) {
        setReconcileMatchCriteria(MatchingCriteriaType.ChargeCodeOnly)
      } else {
        setReconcileMatchCriteria(matchCriteria)
      }
    },
    [reconcileMatchCriteria],
  )

  const closeSoaReconOptionsDialog = useCallback(() => {
    setIsSoaReconOptionsDialogOpen(false)
  }, [setIsSoaReconOptionsDialogOpen])

  const openSoaReconOptionsDialog = useCallback(() => {
    setIsBatchReconciliationDialogOpen(false)
    setIsSoaReconOptionsDialogOpen(true)
    setOverrideChargeDescription(false)
    setDisableSendDueDate(false)
    setReconcileMatchCriteria(MatchingCriteriaType.ChargeCodeVendorInvoiceNumber)
    setReconAsLumpsum(false)
  }, [
    setIsBatchReconciliationDialogOpen,
    setIsSoaReconOptionsDialogOpen,
    setReconAsLumpsum,
    setReconcileMatchCriteria,
    setOverrideChargeDescription,
    setDisableSendDueDate,
  ])

  const openSoaReconDialog = useCallback(() => {
    setIsBatchReconciliationDialogOpen(true)
    closeSoaReconOptionsDialog()
  }, [setIsBatchReconciliationDialogOpen, closeSoaReconOptionsDialog])

  const closeSoaReconDialog = useCallback(() => {
    setIsBatchReconciliationDialogOpen(false)
  }, [setIsBatchReconciliationDialogOpen])

  return {
    overrideChargeDescription,
    setOverrideChargeDescription,
    disableSendDueDate,
    setDisableSendDueDate,
    reconAsLumpsum,
    setReconAsLumpsum,
    reconcileMatchCriteria,
    toggleReconMatchCriteria,
    isSoaReconOptionsDialogOpen,
    closeSoaReconOptionsDialog,
    openSoaReconOptionsDialog,
    isBatchReconciliationDialogOpen,
    closeSoaReconDialog,
    openSoaReconDialog,
  }
}

export default useSoaReconOverrideOptions
