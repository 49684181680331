import { FunctionComponent, useContext, useState } from 'react'
import { Box, Button, Grid, TextField } from '@material-ui/core'
import { useListUsersQuery, UserList } from '@src/graphql/types'
import ErrorCard from '@src/error-handling/ErrorCard'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { UserRole, CompanyNode } from '@src/graphql/types'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import AdminUserList from './AdminUserList'
import UserRoleAutocomplete from './UserRoleAutocomplete'
import CompanyAutocomplete from './CompanyAutocomplete'
import CreateUserDialog from './CreateUserDialog'
import { UserProfileContext } from '@src/auth/UserProfileContext'

type FormValues = {
  company: null | CompanyNode
  email: string
  roles: UserRole[]
}

const UserAdminIndexPage: FunctionComponent = () => {
  const { userProfile } = useContext(UserProfileContext)

  const [pageNumber, setPageNumber] = useState(1)
  const [company, setCompany] = useState(null as null | CompanyNode)
  const [email, setEmail] = useState('')
  const [roles, setRoles] = useState([] as UserRole[])
  const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = useState(false)

  const formMethods = useForm<FormValues>()

  const isExpedockAdmin =
    userProfile?.roles.some((role) => role === UserRole.ExpedockAdmin) ?? false

  const {
    data: usersData,
    error: usersError,
    refetch: refetchUsers,
  } = useListUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      pageNumber,
      companyId: company?.id,
      email,
      roles,
    },
  })

  const onPageChange = (value: number): void => {
    setPageNumber(value)
  }

  const onSubmit = (data: FormValues): void => {
    setCompany(data.company)
    setEmail(data.email)
    setRoles(data.roles)
    void refetchUsers()
  }

  if (usersError) {
    return <ErrorCard>{usersError.message}</ErrorCard>
  }
  if (!usersData) {
    return <CenteredCircularProgress />
  }
  return (
    <Box p={3}>
      <h2>Users</h2>
      <FormProvider {...formMethods}>
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={4}>
            <CompanyAutocomplete fieldName='company' friendlyName='Company' multiple={false} />
          </Grid>
          <Grid item xs={2}>
            <Controller
              render={({ field: { value, onBlur, onChange } }) => (
                <TextField
                  variant='outlined'
                  label='Email'
                  value={value}
                  fullWidth
                  onBlur={onBlur}
                  onChange={(event) => onChange(event.target.value)}
                  name='email'
                />
              )}
              name='email'
              defaultValue=''
            />
          </Grid>
          <Grid item xs={4}>
            <UserRoleAutocomplete fieldName='roles' friendlyName='Roles' showAllRoles={true} />
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={formMethods.handleSubmit(onSubmit)}
              variant='outlined'
              color='primary'
              fullWidth
              size='large'
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
      {isExpedockAdmin && (
        <Button variant='outlined' onClick={() => setIsCreateUserDialogOpen(true)}>
          Create New User
        </Button>
      )}
      <AdminUserList
        userList={usersData.listUsers as UserList}
        onPageChange={onPageChange}
        pageNumber={pageNumber}
        refetchUsers={refetchUsers}
        isExpedockAdmin={isExpedockAdmin}
      />
      {isCreateUserDialogOpen && (
        <CreateUserDialog
          isOpen={isCreateUserDialogOpen}
          close={() => setIsCreateUserDialogOpen(false)}
          userCompany={userProfile?.company ?? undefined}
          refetchUsers={refetchUsers}
          isExpedockAdmin={isExpedockAdmin}
        />
      )}
    </Box>
  )
}

export default UserAdminIndexPage
