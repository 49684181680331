import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { TextField, makeStyles, Paper, Typography, Checkbox, Button, Grid } from '@material-ui/core'
import { CompanyNode, Maybe, Query, useEditCompanyMutation } from '@src/graphql/types'
import theme from '@src/utils/theme'
import RoundedButton from '@src/components/RoundedButton'
import { useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { Auth0AccessTokenContext } from '@src/auth/Auth0AccessTokenContext'
import { tracingFetch } from '@src/utils/observability/tracing'
import { SIGN_COMPANY_LOGO_URI } from '@src/graphql/queries/company'

const useStyles = makeStyles({
  companyTextHeader: {
    color: theme.palette.grey[700],
    fontSize: '25px',
    padding: theme.spacing(1),
  },
  icon: {
    '&:hover': {
      cursor: 'pointer',
    },
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  card: {
    textAlign: 'left',
    userSelect: 'none',
    margin: theme.spacing(1),
    width: '90%',
  },
  saveButtonWrapper: {
    width: '100%',
    textAlign: 'right',
    padding: theme.spacing(1),
  },
  tableCellTop: {
    color: theme.palette.grey[700],
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(1),
    width: '50%',
  },
  tableCellBot: {
    color: theme.palette.grey[700],
    borderLeft: `1px solid ${theme.palette.grey[500]}`,
    borderRight: `1px solid ${theme.palette.grey[500]}`,
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(1),
    width: '50%',
  },
  input: {
    paddingTop: theme.spacing(2),
    width: '50%',
  },
  table: {
    width: '100%',
  },
})

type Props = {
  company: Maybe<CompanyNode>
}

const CompanyDetails: FunctionComponent<Props> = ({ company }: Props) => {
  const classes = useStyles()
  const accessToken = useContext(Auth0AccessTokenContext)!
  const [name, setName] = useState(company?.name ?? '')
  const [metabaseDashboardId, setMetabaseDashboardId] = useState(company?.metabaseDashboardId ?? '')
  const [hasAccessToInsights, setHasAccessToInsights] = useState(
    company?.hasAccessToInsights ?? false,
  )
  const [shipperFacingSlug, setShipperFacingSlug] = useState(company?.shipperFacingSlug ?? '')
  const [enableEdocsTab, setEnableEdocsTab] = useState(company?.enableEdocsTab ?? false)
  const [imageUri, setImageUri] = useState('')
  const [image, setImage] = useState<Maybe<Blob>>(null)
  const [imageIsLoading, setImageIsLoading] = useState(true)
  const [primaryThemeColor, setPrimaryThemeColor] = useState(company?.primaryThemeColor ?? '')
  const { enqueueSnackbar } = useSnackbar()
  const { data: signedUri } = useQuery<Pick<Query, 'signCompanyLogoUri'>>(SIGN_COMPANY_LOGO_URI, {
    variables: { companyId: company?.id },
    skip: !company || !company.logo,
    onCompleted: (completedSignedUri) => {
      if (completedSignedUri.signCompanyLogoUri) {
        setImageUri(completedSignedUri.signCompanyLogoUri)
      } else {
        setImageIsLoading(false)
      }
    },
  })

  useEffect(() => {
    if (company) {
      setName(company!.name! as string)
      setMetabaseDashboardId(company!.metabaseDashboardId! as string)
      setHasAccessToInsights(company?.hasAccessToInsights ?? false)
      setShipperFacingSlug(company?.shipperFacingSlug ?? '')
      setEnableEdocsTab(company?.enableEdocsTab ?? false)
      setPrimaryThemeColor(company?.primaryThemeColor ?? '')
    }
  }, [company])

  const [editCompany] = useEditCompanyMutation()

  const handleSave = async (): Promise<void> => {
    try {
      const companyId = company?.id
      if (!companyId) {
        enqueueSnackbar('Company ID not found.', { variant: 'error' })
        return
      }

      let imageS3Uri = company?.logo ?? ''
      if (image) {
        const formData = new FormData()
        formData.append('companyId', String(company?.id))
        formData.append('logo', image)
        const response = await tracingFetch('/cauldron-api/company/file/upload_logo', {
          method: 'POST',
          headers: { Authorization: `Bearer ${accessToken}` },
          body: formData,
        })

        const responseBody = await response.json()
        if (response.status != 200) {
          enqueueSnackbar(`Error saving company logo: ${responseBody.message}`, {
            variant: 'error',
          })
          return
        }
        imageS3Uri = responseBody.s3_uri
      } else if (!imageUri) {
        imageS3Uri = ''
      }

      const editCompanyRequest = {
        id: companyId,
        name: name,
        metabaseDashboardId: metabaseDashboardId,
        hasAccessToInsights: hasAccessToInsights,
        // Don't pass empty string to backend if slug is not set
        shipperFacingSlug: shipperFacingSlug || undefined,
        imageS3Uri: imageS3Uri,
        primaryThemeColor: primaryThemeColor,
        enableEdocsTab: enableEdocsTab,
      }

      await editCompany({
        variables: {
          editCompanyRequest: editCompanyRequest,
        },
      })
      enqueueSnackbar('Company details saved successfully.', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Error saving company details: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
  }

  const onImageChange = (event: any): void => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0])
      setImageUri(URL.createObjectURL(event.target.files[0]))
    }
  }

  const cancelImageChange = (event: any): void => {
    setImage(null)
    setImageUri('')
  }

  return (
    <Paper>
      <Typography className={classes.companyTextHeader}> Company Details: </Typography>
      <Paper className={classes.card}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td className={classes.tableCellTop}>
                <Typography>Company Name</Typography>
              </td>
              <td className={classes.tableCellTop}>
                <TextField
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className={classes.input}
                  disabled={!company}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.tableCellBot}>
                <Typography>Metabase ID</Typography>
              </td>
              <td className={classes.tableCellBot}>
                <TextField
                  onChange={(e) => setMetabaseDashboardId(e.target.value)}
                  value={metabaseDashboardId}
                  className={classes.input}
                  disabled={!company}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.tableCellBot}>
                <Typography>Has access to new dashboard</Typography>
              </td>
              <td className={classes.tableCellBot}>
                <Checkbox
                  size='small'
                  checked={hasAccessToInsights}
                  onChange={() => setHasAccessToInsights(!hasAccessToInsights)}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.tableCellBot}>
                <Typography>Shipper-Facing Slug</Typography>
              </td>
              <td className={classes.tableCellBot}>
                <TextField
                  onChange={(e) => setShipperFacingSlug(e.target.value)}
                  value={shipperFacingSlug}
                  className={classes.input}
                  disabled={!company}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.tableCellBot}>
                <Typography>Show Edocs on Shipper Viz</Typography>
              </td>
              <td className={classes.tableCellBot}>
                <Checkbox
                  size='small'
                  checked={enableEdocsTab}
                  onChange={() => setEnableEdocsTab(!enableEdocsTab)}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.tableCellBot}>
                <Typography>Logo</Typography>
              </td>
              <td className={classes.tableCellBot}>
                {imageUri ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <img
                        src={imageUri}
                        alt='logo'
                        onLoad={() => {
                          setImageIsLoading(false)
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button variant='outlined' onClick={cancelImageChange}>
                        Delete Image
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Button variant='outlined' component='label'>
                    Upload Logo
                    <input type='file' accept='.png, .svg' onChange={onImageChange} hidden />
                  </Button>
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableCellBot}>
                <Typography>Primary Theme Color</Typography>
              </td>
              <td className={classes.tableCellBot}>
                <TextField
                  onChange={(e) => setPrimaryThemeColor(e.target.value)}
                  value={primaryThemeColor}
                  className={classes.input}
                  disabled={!company}
                  placeholder={'e.g. #008B86'}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Paper>
      <div className={classes.saveButtonWrapper}>
        <RoundedButton
          onClick={handleSave}
          disabled={!company && imageIsLoading}
          variant='contained'
          color='primary'
        >
          {' '}
          Save{' '}
        </RoundedButton>
      </div>
    </Paper>
  )
}

export default CompanyDetails
