import { getColumnsToDisplay, getLineItems, LineItem } from '@src/utils/line_items'
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit'
import { DocumentEditorState } from './document_editor_state'
import { getNullableEntitySelectors } from './nullable_entity_selector'
import produce, { produceWithPatches } from 'immer'
import { BoxDimension, GoogleOCRData } from '@src/types/ocr'
import {
  DocumentTableNode,
  FilePageNode,
  FilePageType,
  JobNode,
  JobTemplateReconType,
} from '@src/graphql/types'
import {
  getInitialMagicGridState,
  magicGridColumnsAdapter,
  magicGridRowsAdapter,
} from './magic_grid'
import { documentTablesAdapter, selectActiveDocumentTable } from './document_table'
import { JobDocumentTable } from '@src/utils/shipment_form'
import { computeNextStateWithUndo } from './undo_redo'
import { formatHandsontableChanges } from './utils/line_item_actions'
import {
  addEmptyJobTableRows,
  addEmptyOrClonedDocTableRows,
  deleteFromDocTable,
  deleteFromJobTable,
  editLineItemCells,
  pasteToTable,
  postProcessLineItemCells,
  reorderTableColumns,
  removeTableColumn,
  addTableColumn,
  setDocumentEditorPageFieldCoordinates,
  copyLineItemsFromDocTables,
} from './line_item_actions'
import { selectActiveDocument } from './document'
import { jobTableLineItemSelectors } from './job_table'
import { clearActiveLineItemsClipboardReducer } from './line_items_clipboard'
import { SpreadsheetDataColumn } from '@src/utils/data-grid'
import { selectJobTableState } from './field'
import { calculateGridDimension, getColsAndRowsInGrid } from '@src/utils/magic_grid'
import { initializeDocumentEditorPage, initializePageFieldEditorState } from './page_field_editor'
import { ChargeCodeTax } from '@src/components/data-grid/types'

const getRowOrder = (lineItem: Partial<LineItem>): number => {
  const rowOrderExists =
    lineItem.rowOrderPriority !== null || lineItem.rowOrderPriority !== undefined
  return rowOrderExists ? lineItem.rowOrderPriority! : lineItem.box?.top || Number.MAX_SAFE_INTEGER
}

// TODO: remove after https://expedock.atlassian.net/browse/PD-8328 is fully released. use sortComparer instead
export const sortComparerWrapper = (
  enableLineItemsRowOrderPriority: boolean,
): ((a: Partial<LineItem>, b: Partial<LineItem>) => number) => {
  if (enableLineItemsRowOrderPriority) {
    return sortComparer
  }
  return oldSortComparer
}

const oldSortComparer = (a: Partial<LineItem>, b: Partial<LineItem>): number =>
  (a.box?.top || Number.MAX_SAFE_INTEGER) - (b.box?.top || Number.MAX_SAFE_INTEGER)

export const sortComparer = (a: Partial<LineItem>, b: Partial<LineItem>): number =>
  getRowOrder(a) - getRowOrder(b)

export const lineItemsAdapter = createEntityAdapter<LineItem>()

export type LineItemsTableMap = { [documentTableId: string]: LineItemsTableState }

export type LineItemsTableState = {
  lineItems: EntityState<LineItem>
  columns: string[]
  lastAddedColumnKey: string | null
  editedByUser: boolean
  // we only validate if the table has been submitted at least once, ever (i.e.
  // we don't want to show lots of validation errors for freshly autofilled line items)
  submitted: boolean
}

export const getInitialLineItemsTableState = (): LineItemsTableState => ({
  lineItems: lineItemsAdapter.getInitialState(),
  columns: [],
  lastAddedColumnKey: null,
  submitted: false,
  editedByUser: false,
})

export const rawLineItemsSelectors = lineItemsAdapter.getSelectors()

export const selectActiveLineItemsTable = (
  state: DocumentEditorState,
): LineItemsTableState | undefined =>
  state.activeDocumentTableId ? state.lineItemsTableMap[state.activeDocumentTableId] : undefined

export const lineItemSelectors = getNullableEntitySelectors(
  lineItemsAdapter,
  (state: DocumentEditorState) => selectActiveLineItemsTable(state)?.lineItems || null,
)

export const resizeLineItemReducer = (
  state: DocumentEditorState,
  lineItemId: string,
  dimension: BoxDimension,
): void => {
  const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
    const lineItemsTableMapDraft = stateDraft.lineItemsTableMap
    const lineItem = lineItemSelectors.selectById(stateDraft, lineItemId)
    const newDimension = {
      left: (lineItem!.box?.left ?? 0) + dimension.left,
      top: (lineItem!.box?.top ?? 0) + dimension.top,
      width: dimension.width,
      height: dimension.height,
    }
    const lineItemsDraft = lineItemsTableMapDraft[stateDraft.activeDocumentTableId!]!.lineItems
    lineItemsAdapter.updateOne(lineItemsDraft, {
      id: lineItemId,
      changes: { box: newDimension },
    })
  })
  computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
}

export const updateLineItemFieldDimensionReducer = (
  state: DocumentEditorState,
  id: string,
  key: string,
  dimension: BoxDimension,
): void => {
  const lineItem = lineItemSelectors.selectById(state, id)
  if (!lineItem) return
  const lineItemsTable = state.lineItemsTableMap[state.activeDocumentTableId!]
  lineItemsAdapter.updateOne(lineItemsTable.lineItems, {
    id,
    changes: {
      fieldMapping: Object.fromEntries(
        Object.entries(lineItem.fieldMapping).map(([fieldKey, fieldInfo]) => {
          if (fieldKey !== key) return [fieldKey, fieldInfo]
          return [fieldKey, { ...fieldInfo, ...dimension }]
        }),
      ),
    },
  })
}

export const addNewDocumentTableReducer = (
  state: DocumentEditorState,
  docTable: DocumentTableNode,
  prevTableId: string,
  newFilePage: FilePageNode,
  newFilePageIdx: number,
  job: JobNode,
  enableLineItemsRowOrderPriority: boolean,
): void => {
  const id = docTable.id
  const newJobDocTable = {
    ...docTable,
    filePageId: newFilePage.id,
    filePageIdx: newFilePageIdx,
  } as JobDocumentTable
  // TODO: update next table id in state document tables - where next id == docTable next id
  // next id = doc table id.
  documentTablesAdapter.addOne(state.documentTables, newJobDocTable)
  documentTablesAdapter.updateOne(state.documentTables, {
    id: prevTableId,
    changes: { nextTableId: id },
  })
  const lineItems = getLineItems(newJobDocTable, enableLineItemsRowOrderPriority)
  state.lineItemsTableMap[id] = produce(getInitialLineItemsTableState(), (lineItemsTable) => {
    lineItemsTable.columns = getColumnsToDisplay(
      newJobDocTable,
      job.filePages!.edges[newFilePageIdx]!.node!.type === FilePageType.Excel,
      job.jobTemplate.reconType === JobTemplateReconType.Soa,
    )
    lineItemsTable.editedByUser = newJobDocTable.editedByUser || false
    if (lineItems?.length) {
      lineItemsAdapter.setAll(lineItemsTable.lineItems, lineItems)
    }
  })
  const initialTableDimension = calculateGridDimension(
    newJobDocTable.documentFieldGroups!.edges.map(
      (documentFieldGroupEdge) => documentFieldGroupEdge!.node!,
    ),
    newJobDocTable.documentTableColumns!.edges.map(
      (documentTableColumnEdge) => documentTableColumnEdge!.node!,
    ),
  )
  state.magicGridMap[id] = produce(getInitialMagicGridState(), (magicGrid) => {
    if (initialTableDimension != null) {
      magicGrid.dimension = initialTableDimension
      const { columns, rows } = getColsAndRowsInGrid(
        newJobDocTable.documentTableColumns!.edges.map((edge) => edge!.node!) ?? [],
        rawLineItemsSelectors.selectAll(state.lineItemsTableMap[id].lineItems),
        magicGrid.dimension,
      )
      magicGridColumnsAdapter.setAll(magicGrid.columns, columns)
      magicGridRowsAdapter.setAll(magicGrid.rows, rows)
    }
  })
  const { page, filePageId } = initializeDocumentEditorPage(newFilePage)
  if (state.pageFieldEditorState) {
    state.pageFieldEditorState.pages[filePageId] = page
  } else {
    state.pageFieldEditorState = initializePageFieldEditorState(job)
  }
}

export const updateTableColumnsReducer = (
  state: DocumentEditorState,
  startColumnsArr: number[],
  insertIdxStart: number,
  columnKeys: string[],
  isJobTableActive: boolean,
): void => {
  const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
    reorderTableColumns(stateDraft, startColumnsArr, insertIdxStart, columnKeys, isJobTableActive)
  })
  computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
}

export const updateTableLineItemsReducer = (
  state: DocumentEditorState,
  changes: [number, string | number, string | number | null, string | number | null][],
  source: string,
  columns: SpreadsheetDataColumn[],
  googleOcrData: GoogleOCRData | null,
  isJobTableActive: boolean,
  chargeCodeTaxMap: Record<string, ChargeCodeTax> | null,
): void => {
  const columnKeys = columns.map((column) => column.key)
  const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
    const lineItemsTableMapDraft = stateDraft.lineItemsTableMap
    const formattedChanges = formatHandsontableChanges(changes)
    const tableState = isJobTableActive
      ? stateDraft.jobTableState
      : stateDraft.activeDocumentTableId
      ? lineItemsTableMapDraft[stateDraft.activeDocumentTableId]
      : null
    const activeDocument = selectActiveDocument(stateDraft)
    const activeDocumentTable = selectActiveDocumentTable(stateDraft)
    if (!tableState || !activeDocument || !activeDocumentTable) {
      return
    }
    const lineItemsDraft = tableState.lineItems
    let isClipboardOutdated: boolean
    switch (source) {
      case 'CopyPaste.paste':
        isClipboardOutdated = pasteToTable(
          columns,
          lineItemsDraft,
          stateDraft.lineItemsClipboard,
          formattedChanges,
          isJobTableActive,
          activeDocument.id,
          activeDocumentTable.id,
          googleOcrData,
          chargeCodeTaxMap,
        )
        if (isClipboardOutdated) {
          clearActiveLineItemsClipboardReducer(stateDraft)
        }
        break
      // not sure if this would ever be a source from beforeChange, but just in case
      case 'loadData':
        break
      default:
        editLineItemCells(
          columnKeys,
          lineItemsDraft,
          formattedChanges,
          isJobTableActive,
          activeDocument.id,
        )
        postProcessLineItemCells(
          columns,
          lineItemsDraft,
          formattedChanges,
          isJobTableActive,
          activeDocument.id,
          chargeCodeTaxMap,
        )
        break
    }
  })
  computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
  setDocumentEditorPageFieldCoordinates(state, isJobTableActive)
}

export const addLineItemRowsReducer = (
  state: DocumentEditorState,
  index: number,
  amount: number,
  columnKeys: string[],
  googleOcrData: GoogleOCRData | null,
  isJobTableActive: boolean,
): void => {
  const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
    const activeDocument = selectActiveDocument(stateDraft)
    const activeDocumentTable = selectActiveDocumentTable(stateDraft)
    const jobTableState = selectJobTableState(stateDraft)
    if (isJobTableActive && jobTableState && activeDocument) {
      addEmptyJobTableRows(
        index,
        amount,
        jobTableLineItemSelectors.selectAll(stateDraft) ?? [],
        jobTableState.lineItems,
        columnKeys,
        activeDocument.id,
      )
    } else if (activeDocumentTable) {
      const lineItemsTableMapDraft = stateDraft.lineItemsTableMap
      addEmptyOrClonedDocTableRows(
        index,
        amount,
        lineItemSelectors.selectAll(stateDraft) ?? [],
        lineItemsTableMapDraft[activeDocumentTable.id].lineItems,
        columnKeys,
        activeDocumentTable.id,
        googleOcrData,
      )
    }
  })
  computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
  setDocumentEditorPageFieldCoordinates(state, isJobTableActive)
}

export const deleteLineItemRowsReducer = (
  state: DocumentEditorState,
  index: number,
  amount: number,
  isJobTableActive: boolean,
): void => {
  const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
    const activeDocumentTable = selectActiveDocumentTable(stateDraft)
    const jobTableState = selectJobTableState(stateDraft)
    if (isJobTableActive && jobTableState) {
      deleteFromJobTable(index, amount, jobTableState.lineItems)
    } else if (activeDocumentTable) {
      const lineItemsTableMapDraft = stateDraft.lineItemsTableMap
      deleteFromDocTable(index, amount, lineItemsTableMapDraft[activeDocumentTable.id].lineItems)
    }
  })
  computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
  setDocumentEditorPageFieldCoordinates(state, isJobTableActive)
}

export const addTableColumnReducer = (
  state: DocumentEditorState,
  columns: string[],
  index: number,
  colKey: string,
  isJobTableActive: boolean,
): void => {
  const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
    addTableColumn(stateDraft, columns, index, colKey, isJobTableActive)
  })
  computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
}

export const removeTableColumnReducer = (
  state: DocumentEditorState,
  columns: string[],
  colKey: string,
  isJobTableActive: boolean,
): void => {
  const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
    removeTableColumn(stateDraft, columns, colKey, isJobTableActive)
  })
  computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
}

export const copyFromExtractedTablesReducer = (state: DocumentEditorState): void => {
  const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
    copyLineItemsFromDocTables(stateDraft)
  })
  computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
}
