import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useCallback, useMemo, useEffect, useRef } from 'react'
import {
  FieldErrors,
  RegisterOptions,
  useController,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import StylizedShipmentTextField from './StylizedShipmentTextField'
import { makeStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'
import { Autocomplete, AutocompleteRenderInputParams, createFilterOptions } from '@material-ui/lab'

import { Query, SearchableRecordNode, SearchableRecordResultNode } from '@src/graphql/types'
import { TextFieldRef } from '@src/types/shipment_form'
import theme from '@src/utils/theme'
import { isPresent } from 'ts-is-present'
import { useQuery } from '@apollo/client'
import { SEARCHABLE_RECORD_RESULTS } from '@src/graphql/queries/searchableRecord'
import { useSelector } from 'react-redux'
import { RootState } from '@src/utils/store'
import {
  buildSearchableRecordFilters,
  displaySearchableRecord,
  getSearchableRecordDisplayKey,
  getSearchableRecordSearchValue,
} from '@src/utils/searchable_record'
import { useSnackbar } from 'notistack'
import { Box } from '@material-ui/core'
import { handleMetadataDatePaste } from '@src/utils/date'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { LogEventType } from '@src/utils/observability/LogEventType'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  fieldContainer: {
    margin: `${theme.spacing(1)}px 0`,
    width: '100%',
  },
  fieldIndicator: {
    marginBottom: theme.spacing(1),
  },
  description: {
    marginLeft: theme.spacing(1),
  },
  textField: {
    fontFamily: "'Roboto Mono'",
  },
  option: {
    fontFamily: "'Roboto Mono'",
  },
})

type Props = {
  label: string
  name: string
  autofillKey: string
  required?: boolean
  rows: number
  searchableRecord?: SearchableRecordNode
  values: string[]
  defaultValue: string
  fieldRef: TextFieldRef
  errors: FieldErrors<Record<string, string>>
  setActiveField: () => void
  readOnly: boolean
  pattern?: string
  patternDescription?: string
  allowFreeText?: boolean
  dateFormatString?: string
}

const inputLabelProps = { shrink: true }

const ShipmentField: FunctionComponent<Props> = ({
  label,
  name,
  autofillKey,
  required,
  rows,
  searchableRecord,
  values,
  defaultValue,
  fieldRef,
  errors,
  setActiveField,
  readOnly,
  pattern,
  patternDescription,
  allowFreeText,
  dateFormatString,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { register, trigger, setValue, getValues } = useFormContext()

  const pages = useSelector((state: RootState) => state.documentEditor.pageFieldEditorState?.pages)
  const company = useSelector((state: RootState) => state.documentEditor.job?.jobTemplate?.company)
  const job = useSelector((state: RootState) => state.documentEditor.job)
  const apiPartnerId = useSelector(
    (state: RootState) => state.documentEditor.job?.jobTemplate?.apiPartnerId,
  )
  const { logEvent } = useEventLogger()
  const autocompleteFilterOptions = useMemo(
    () =>
      createFilterOptions({
        stringify: (option: SearchableRecordResultNode) =>
          option == null || searchableRecord == null
            ? ''
            : getSearchableRecordSearchValue(searchableRecord, option),
      }),
    [searchableRecord],
  )

  const autocompleteRenderOption = useMemo(
    () =>
      (option: SearchableRecordResultNode): string | null | undefined =>
        option == null || searchableRecord == null
          ? ''
          : getSearchableRecordSearchValue(searchableRecord, option),
    [searchableRecord],
  )
  const isAutocompleteField = !!searchableRecord || !!values?.length
  const filters = buildSearchableRecordFilters(
    searchableRecord,
    pages,
    company,
    apiPartnerId,
    getValues,
  )
  const {
    data: searchableRecordResultsData,
    refetch: refetchSearchableRecordResults,
    loading: loadingSearchableRecordResults,
  } = useQuery<Pick<Query, 'searchableRecordResults'>>(SEARCHABLE_RECORD_RESULTS, {
    skip: !searchableRecord,
    variables: { searchableRecordId: searchableRecord?.id, queryString: defaultValue, filters },
    context: {
      debounceKey: searchableRecord?.id,
      debounceTimeout: searchableRecord?.model ? 300 : 1500, // if not model, then avoid smashing third party apis with searches
    },
  })
  // memoized for referential integrity
  const searchableRecords: SearchableRecordResultNode[] = useMemo(
    () => searchableRecordResultsData?.searchableRecordResults?.filter(isPresent) ?? [],
    // this *is* a dependency, and the linter is being weird about it
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchableRecordResultsData],
  )
  const rules = useMemo(() => {
    const _rules: RegisterOptions = { required }
    if (pattern) {
      const closedPattern = ['^(', pattern, ')$'].join('')
      _rules.pattern = {
        value: new RegExp(closedPattern),
        message: `Invalid value for ${label}. Please check description`,
      }
    }
    if (values?.length && !allowFreeText) {
      _rules.validate = (val) => {
        return (
          values.includes(val) ||
          !val ||
          `Invalid value for ${label}. Please select one of the options.`
        )
      }
    }
    if (searchableRecord && !allowFreeText) {
      _rules.validate = (val) => {
        return (
          loadingSearchableRecordResults ||
          searchableRecords
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            .map(
              (record) =>
                (record as any)[getSearchableRecordDisplayKey(searchableRecord)] as string,
            )
            .includes(val) ||
          !val ||
          `Invalid value for ${label}. Please select one of the options.`
        )
      }
    }
    return _rules
  }, [
    label,
    allowFreeText,
    pattern,
    required,
    values,
    searchableRecord,
    loadingSearchableRecordResults,
    searchableRecords,
  ])

  useEffect(() => {
    // trigger the validation every time refetchSearchableRecordResults finishes
    // to validate the searchablerecord fields with the updated options
    void trigger(name)
  }, [searchableRecords, name, loadingSearchableRecordResults, trigger])

  const {
    field: { ref: controllerRef, value, onBlur, onChange },
  } = useController({ name, rules, defaultValue })

  const previousValueRef = useRef<string>()
  const actionStart = useRef<Date>()

  const logFocus = useCallback(() => {
    previousValueRef.current = value
    actionStart.current = new Date()
    setActiveField()
  }, [setActiveField, value])

  const logBlur = useCallback(() => {
    void logEvent(LogEventType.EDIT_METAFIELDS, {
      job_id: job?.id || '',
      metafield_key: name,
      autofill_key: autofillKey,
      action_start: actionStart.current,
      action_end: new Date(),
      previous_value: previousValueRef.current,
      current_value: value,
      event_time: new Date(),
    })
    onBlur()
  }, [autofillKey, job?.id, name, value, onBlur, logEvent])

  const refWrapper = useCallback(
    (htmlInput: HTMLInputElement) => {
      if (typeof controllerRef === 'function') {
        controllerRef(htmlInput)
      } else if (controllerRef) {
        const modifiableRef = controllerRef as { current: HTMLInputElement | undefined }
        modifiableRef.current = htmlInput
      }

      if (htmlInput && dateFormatString) {
        htmlInput.onpaste = (paste) => {
          handleMetadataDatePaste(fieldRef, paste, dateFormatString)
        }
      }

      fieldRef.current = {
        element: { current: htmlInput },
        setValue: (val) => setValue(name, val),
      }
    },
    [controllerRef, dateFormatString, fieldRef, name, setValue],
  )
  const confirmedFieldName = `${name}_confirmed`
  register(confirmedFieldName, { required: true })
  const isConfirmed = useWatch({
    name: confirmedFieldName,
  })

  let errorMessage = ''
  if (errors[name]?.type === 'required') {
    errorMessage = `${label} is required`
  } else if (errors[name]?.message) {
    errorMessage = formatMaybeApolloError(errors[name]) as string
  }

  const inputProps = useMemo(
    () => ({
      inputProps: { 'aria-label': label, readOnly },
      classes: {
        input: classes.textField,
      },
    }),
    [classes.textField, label, readOnly],
  )

  const handleAutocompleteInputChange = useMemo(() => {
    const searchInputValue = async (query: string): Promise<void> => {
      if (searchableRecord && query) {
        refetchSearchableRecordResults({
          searchableRecordId: searchableRecord.id,
          queryString: query,
          filters,
        }).catch((error) => {
          enqueueSnackbar(
            `Error on fetching searchable records: ${formatMaybeApolloError(error)}`,
            {
              variant: 'error',
            },
          )
        })
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (_: React.ChangeEvent<any>, inputValue: string): Promise<void> => {
      await searchInputValue(inputValue)
      void trigger(name)
    }
  }, [name, refetchSearchableRecordResults, searchableRecord, trigger, filters, enqueueSnackbar])

  const autocompleteGetOptionLabel = useCallback(
    (option: string | SearchableRecordResultNode) => {
      if (typeof option === 'string') {
        return option
      }
      return searchableRecord == null ? '' : displaySearchableRecord(searchableRecord, option)
    },
    [searchableRecord],
  )

  const handleSearchableRecordChange = useMemo(
    () =>
      async (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _: React.ChangeEvent<any>,
        val: SearchableRecordResultNode | string | null,
      ): Promise<void> => {
        onChange(autocompleteGetOptionLabel(val || ''))
      },
    [autocompleteGetOptionLabel, onChange],
  )

  const handleAutocompleteChange = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (_: React.ChangeEvent<any>, val: string): Promise<void> => {
      onChange(val || '')
    }
  }, [onChange])

  const renderAutocompleteInput = useCallback(
    (params: AutocompleteRenderInputParams): JSX.Element => (
      <TextField
        {...params}
        label={label}
        id={name}
        data-testid={`${label}-shipment-field`}
        error={!!errors[name] || !!errors[confirmedFieldName]}
        helperText={errorMessage}
        multiline
        // we add 1 to the rows here in case there are newlines
        // that are invisible to a 1-row, which is common for our capture
        rows={rows + 1 || 2}
        inputRef={refWrapper}
        onFocus={logFocus}
        onBlur={logBlur}
        InputLabelProps={inputLabelProps}
        variant='outlined'
        InputProps={{
          ...params.InputProps,
          classes: {
            input: classes.textField,
          },
        }}
      />
    ),
    [
      label,
      name,
      errors,
      confirmedFieldName,
      errorMessage,
      rows,
      refWrapper,
      logFocus,
      logBlur,
      classes.textField,
    ],
  )
  // need to have the captured value (e.g. when boxing) in the options
  // to avoid console spam
  const fixedValuesAutocompleteOptions = useMemo(
    () => (values || []).concat(values.includes(value) ? [] : [value]),
    [values, value],
  )

  return (
    <div className={classes.root}>
      <Grid className={classes.fieldContainer} container spacing={1} alignItems='flex-end'>
        <Grid item xs={1}>
          {!isConfirmed && <ErrorIcon fontSize='inherit' className={classes.fieldIndicator} />}
        </Grid>
        <Grid item xs={10}>
          <Box width='100%'>
            {isAutocompleteField ? (
              <>
                {searchableRecord && (
                  <Autocomplete
                    value={value || ''}
                    data-testid={name}
                    options={searchableRecords}
                    freeSolo={allowFreeText}
                    selectOnFocus
                    onChange={handleSearchableRecordChange}
                    filterOptions={autocompleteFilterOptions}
                    getOptionLabel={autocompleteGetOptionLabel}
                    renderOption={autocompleteRenderOption}
                    onInputChange={handleAutocompleteInputChange}
                    renderInput={renderAutocompleteInput}
                    classes={{
                      option: classes.option,
                    }}
                    loading={loadingSearchableRecordResults}
                  />
                )}
                {!!values?.length && !searchableRecord && (
                  <Autocomplete
                    value={value || ''}
                    data-testid={name}
                    handleHomeEndKeys
                    options={fixedValuesAutocompleteOptions}
                    onChange={handleAutocompleteChange}
                    renderInput={renderAutocompleteInput}
                    onInputChange={allowFreeText ? handleAutocompleteChange : undefined}
                    freeSolo={allowFreeText}
                    autoHighlight
                    classes={{
                      option: classes.option,
                    }}
                    loading={loadingSearchableRecordResults}
                  />
                )}
              </>
            ) : (
              <StylizedShipmentTextField
                fullWidth
                label={label}
                name={name}
                error={!!errors[name] || !!errors[confirmedFieldName]}
                helperText={errorMessage}
                multiline={!!rows}
                // we add 1 to the rows here in case there are newlines
                // that are invisible to a 1-row, which is common for our capture
                rows={rows + 1 || 2}
                onBlur={logBlur}
                onChange={onChange}
                value={value}
                inputRef={refWrapper}
                onFocus={logFocus}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
              />
            )}
            <div className={classes.description}>{patternDescription || pattern}</div>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default ShipmentField
