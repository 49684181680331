import { useQuery } from '@apollo/client'
import { FunctionComponent, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Query } from '@src/graphql/types'
import { GET_API_INGESTION_ATTACHMENT_SIGNED_URL } from '@src/graphql/queries/api'
import { CircularProgress, Box, Link, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: { width: '100vw', height: '100vh' },
})

const ApiIngestionAttachmentPage: FunctionComponent = () => {
  const classes = useStyles()
  const { id: apiIngestionAttachmentId } = useParams<{ id: string }>()
  const { loading, data } = useQuery<Pick<Query, 'apiIngestionAttachmentSignedUrl'>>(
    GET_API_INGESTION_ATTACHMENT_SIGNED_URL,
    { variables: { apiIngestionAttachmentId } },
  )
  useEffect(() => {
    if (window && data?.apiIngestionAttachmentSignedUrl) {
      window.location.href = data.apiIngestionAttachmentSignedUrl
    }
  }, [data])

  return (
    <Box className={classes.root} display='flex' alignItems='center' justifyContent='center'>
      {loading || !data?.apiIngestionAttachmentSignedUrl ? (
        <CircularProgress />
      ) : (
        <Box>
          <Typography variant='h1' align='center'>
            Download hasn&apos;t started yet?
          </Typography>
          <Typography variant='body1' align='center'>
            Download manually{' '}
            <Link href={data.apiIngestionAttachmentSignedUrl} underline='always'>
              here
            </Link>{' '}
            instead.
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default ApiIngestionAttachmentPage
