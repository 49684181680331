import { FunctionComponent, MouseEvent, MutableRefObject, useState } from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { Theme } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/styles'
import { clsx } from 'clsx'

import { MAGIC_GRID_DIMENSIONS } from '@src/utils//app_constants'
import { BoxDimension } from '@src/types/ocr'
import theme from '@src/utils/theme'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@src/utils/store'
import { addGridRow, deleteGridRow } from '@src/redux-features/document_editor'
import { selectActiveMagicGrid, rowSelectors } from '@src/redux-features/document_editor/magic_grid'

const useStyles = makeStyles<Theme>({
  verticalBar: {
    position: 'absolute',
    left: -theme.spacing(2),
    height: '100%',
    width: theme.spacing(1),
    backgroundColor: theme.palette.info.dark,
    borderRadius: theme.shape.borderRadius,
    zIndex: MAGIC_GRID_DIMENSIONS.CONTROLS_Z_INDEX,
  },
  rowControl: {
    transform: 'translate(-30%, -50%)',
    padding: 0,
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  addRowButton: {
    color: theme.palette.info.light,
  },
  rulerGuide: {
    position: 'absolute',
    height: `${MAGIC_GRID_DIMENSIONS.RULER_THICKNESS}px`,
    width: '100%',
    borderTopWidth: 'thin',
    border: `${MAGIC_GRID_DIMENSIONS.RULER_THICKNESS}px solid ${theme.palette.info.light}`,
  },
  activeRuler: {
    border: `${MAGIC_GRID_DIMENSIONS.RULER_THICKNESS}px solid ${theme.palette.error.light}`,
  },
})

type Props = {
  imageBoxRef: MutableRefObject<HTMLImageElement | null>
  imageScale: number
}

const RowControls: FunctionComponent<Props> = ({ imageBoxRef, imageScale }) => {
  const dispatch = useDispatch()
  const gridDimension = useSelector(
    (state: RootState) => selectActiveMagicGrid(state.documentEditor)!.dimension,
  ) as BoxDimension
  const rows = useSelector((state: RootState) => rowSelectors.selectAll(state.documentEditor)!)
  const [activeRowId, setActiveRowId] = useState(null as string | null)
  const [isRulerVisible, setIsRulerVisible] = useState(false)
  const [yCoord, setYCoord] = useState(0)
  const imageHeight = imageBoxRef.current?.height || 0
  const classes = useStyles()
  const relativeRulerThickness = MAGIC_GRID_DIMENSIONS.RULER_THICKNESS / (imageHeight * imageScale)
  const relativeMarkerThreshold =
    MAGIC_GRID_DIMENSIONS.MARKER_THRESHOLD / (imageHeight * imageScale)

  const handleAddRow = (): void => {
    const adjustedYCoord = yCoord + gridDimension.top + relativeRulerThickness
    dispatch(addGridRow(adjustedYCoord))
    setIsRulerVisible(false)
  }

  const handleRemoveRow = (): void => {
    if (activeRowId != null) {
      dispatch(deleteGridRow(activeRowId))
      setActiveRowId(null)
    }
  }

  const getCursorPosition = (evt: MouseEvent): void => {
    const verticalBarBox = evt.currentTarget.getBoundingClientRect() as ClientRect
    const verticalBarBoxEndY = verticalBarBox.top + verticalBarBox.height
    const newYCoord = evt.clientY - verticalBarBox.top
    const relativeYCoord = newYCoord / (imageHeight * imageScale)
    const isCursorInsideVerticalBar =
      evt.clientY <= verticalBarBox.top || evt.clientY >= verticalBarBoxEndY

    // restrict the ruler inside the bounds of the vertical bar
    if (isCursorInsideVerticalBar) {
      setIsRulerVisible(false)
      return
    }

    // if the cursor overlaps an existing row top coordinate, set it as the active row
    const currentActiveRow = rows.find(({ dimension: { top } }) => {
      const adjustedStartYCoord = top - gridDimension.top - relativeRulerThickness
      const ymin = adjustedStartYCoord - relativeMarkerThreshold
      const ymax = adjustedStartYCoord + relativeMarkerThreshold
      return relativeYCoord >= ymin && relativeYCoord <= ymax
    })
    setActiveRowId(currentActiveRow?.id || null)
    setYCoord(relativeYCoord)
  }

  const imageScaledYCoord = yCoord * imageHeight

  return (
    <>
      <Box
        className={classes.verticalBar}
        onFocus={() => setIsRulerVisible(true)}
        onBlur={() => {
          setIsRulerVisible(false)
          setActiveRowId(null)
        }}
        onMouseOver={() => setIsRulerVisible(true)}
        onMouseLeave={() => {
          setIsRulerVisible(false)
          setActiveRowId(null)
        }}
        onMouseMove={getCursorPosition}
      >
        {activeRowId == null && isRulerVisible && (
          <Box position='absolute' top={imageScaledYCoord}>
            <IconButton className={classes.rowControl} onClick={handleAddRow} size='small'>
              <AddCircleIcon className={classes.addRowButton} />
            </IconButton>
          </Box>
        )}

        {activeRowId != null && (
          <Box position='absolute' top={imageScaledYCoord}>
            <IconButton className={classes.rowControl} onClick={handleRemoveRow} size='small'>
              <CancelIcon color='error' />
            </IconButton>
          </Box>
        )}
      </Box>

      {isRulerVisible && (
        <Box
          className={clsx(classes.rulerGuide, {
            [classes.activeRuler]: activeRowId != null,
          })}
          top={imageScaledYCoord}
        />
      )}
    </>
  )
}

export default RowControls
