import { FunctionComponent } from 'react'
import { FieldError } from 'react-hook-form'
import { FormHelperText } from '@material-ui/core'

type Props = {
  /** human friendly name, e.g. Document Type */
  friendlyName: string
  error: FieldError | undefined
}

const ControllerErrorText: FunctionComponent<Props> = ({ friendlyName, error }) => {
  return (
    <>
      {error?.type === 'required' && (
        <FormHelperText error>{friendlyName} is required</FormHelperText>
      )}
      {['min', 'max'].includes(error?.type || '') && (
        <FormHelperText error>{friendlyName} must be within given range</FormHelperText>
      )}
      {error?.type !== 'required' && error?.message && (
        <FormHelperText error>{error?.message}</FormHelperText>
      )}
    </>
  )
}

export default ControllerErrorText
