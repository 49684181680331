import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState, useContext, useCallback, ReactElement, useRef } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircle'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { useZoomControlBar } from '@src/components/zoom-control-bar/useZoomControlBar'
import ZoomControlBar from '@src/components/zoom-control-bar/ZoomControlBar'
import theme from '@src/utils/theme'
import {
  EDocsDataTargetType,
  FilePageNode,
  JobNode,
  JobTemplateReconType,
  Mutation,
  MutationPushEDocsToCwArgs,
  Query,
  QueryConsolWithMatchCriteriaArgs,
  ShipmentOp,
  QueryShipmentWithMatchCriteriaArgs,
  QueryCustomsDeclarationWithMatchCriteriaArgs,
  FilePageType,
  InputEDocument,
  MutationExportEDocumentsToApiPartnerArgs,
  ApiPartnerNode,
} from '@src/graphql/types'
import { JOB_NOTES } from '@src/graphql/queries/note'
import { clsx } from 'clsx'
import { PUSH_EDOCS_TO_CARGOWISE } from '@src/graphql/mutations/cargowise'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import EdocsPushConfirmDialog from './EdocsPushConfirmDialog'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import {
  FIND_CONSOL_FROM_JOB,
  FIND_SHIPMENT_FROM_JOB,
  FIND_CUSTOMS_DECLARATION_FROM_JOB,
} from '@src/graphql/queries/cargowise'
import {
  formatFindCustomsDeclarationWithMatchCriteria,
  formatFindShipmentWithMatchCriteria,
} from '@src/utils/recon/ap_recon'
import { formatFindConsolWithMatchCriteria } from '@src/utils/recon/an_recon'
import { ShipmentFormContext } from '@src/contexts/shipment_form_context'
import FileSheetsViewer from './FileSheetsViewer'
import FilePageThumbnail from '../file-page-thumbnail/FilePageThumbnail'
import { GET_JOB_DATA } from '@src/graphql/queries/job'
import { EXPORT_E_DOCUMENTS_TO_API_PARTNER } from '@src/graphql/mutations/job'
import { LogEventType } from '@src/utils/observability/LogEventType'

const THUMBNAIL_SIZE_HEIGHT = '120px'
const THUMBNAIL_SIZE_WIDTH = '85px'

const useStyles = makeStyles({
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imageContainer: {
    position: 'relative',
    paddingTop: '10%',
    height: THUMBNAIL_SIZE_HEIGHT,
    width: THUMBNAIL_SIZE_WIDTH,
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[400],
  },
  image: {
    objectFit: 'contain',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    cursor: 'pointer',
  },
  selected: {
    boxShadow: `inset 0px 0px 0px ${theme.spacing(0.5)}px ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
  },
  checkbox: {
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    top: theme.spacing(0.5),
    left: theme.spacing(0.5),
    padding: 0,
  },
  checkIcon: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  previewImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  titleBox: {
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    width: '100%',
  },
})

type Props = {
  job: JobNode
  filePages: FilePageNode[]
  isUniversalApi: boolean
}

const EDocsFileSelect: FunctionComponent<Props> = ({ job, filePages, isUniversalApi }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const {
    panningEnabled,
    setPanningEnabled,
    scrollZoomEnabled,
    setScrollZoomEnabled,
    panOptions,
    wheelOptions,
    doubleClickOptions,
    transformWrapperOptions,
  } = useZoomControlBar()
  const [selectedFilePagesId, setSelectedFilePagesId] = useState([] as string[])
  const [currentFilePage, setCurrentFilePage] = useState(filePages[0] as FilePageNode)
  const [selectedFilePagesToPush, setSelectedFilePagesToPush] = useState([] as FilePageNode[])
  const [exportToApiPartner, setExportToApiPartner] = useState(false as boolean)
  const [selectedDataTargetType, setSelectedDataTargetType] = useState(
    null as EDocsDataTargetType | null,
  )
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [isSendingToCw, setIsSendingToCw] = useState(false)
  const { logEvent } = useEventLogger()
  const actionStart = useRef<Date>()
  const [formattedFindCwDataResults, setFormattedFindCwDataResults] = useState(
    null as ReactElement | null,
  )
  const [hasSuccessfullyFoundShipment, setHasSuccessfullyFoundShipment] = useState(false)
  const [hasSuccessfullyFoundConsol, setHasSuccessfullyFoundConsol] = useState(false)
  const [hasSuccessfullyFoundCustomsDeclaration, setHasSuccessfullyFoundCustomsDeclaration] =
    useState(false)

  const shipmentOpsTypes = job.jobTemplate?.shipmentOpsTypes ?? []
  const reconType = job.jobTemplate?.reconType
  const apiPartner = job.jobTemplate?.apiPartner ?? null
  const apiPartnerName = apiPartner?.name || ''
  const isAPRecon = reconType === JobTemplateReconType.Ap
  const willPushToShipment =
    isAPRecon ||
    shipmentOpsTypes.includes(ShipmentOp.Shipment) ||
    shipmentOpsTypes.includes(ShipmentOp.ImportDeclaration)
  const isANRecon = reconType === JobTemplateReconType.ArrivalNotice
  const willPushToConsol = isAPRecon || isANRecon || shipmentOpsTypes.includes(ShipmentOp.Consol)
  const willPushToCustomsDeclaration =
    isAPRecon || shipmentOpsTypes.includes(ShipmentOp.ImportDeclaration)
  const displayFindCWDataResults =
    !exportToApiPartner &&
    (selectedDataTargetType === EDocsDataTargetType.Consol ||
      selectedDataTargetType === EDocsDataTargetType.Shipment)

  const closeConfirmDialog = (): void => {
    setIsConfirmDialogOpen(false)
  }

  const { saveJob } = useContext(ShipmentFormContext)

  const { refetch: findShipmentFromJob } = useQuery<
    Pick<Query, 'shipmentWithMatchCriteria'>,
    QueryShipmentWithMatchCriteriaArgs
  >(FIND_SHIPMENT_FROM_JOB, {
    variables: { jobId: job.id },
    fetchPolicy: 'network-only',
    skip: true,
  })

  const { refetch: findCustomsDeclarationFromJob } = useQuery<
    Pick<Query, 'customsDeclarationWithMatchCriteria'>,
    QueryCustomsDeclarationWithMatchCriteriaArgs
  >(FIND_CUSTOMS_DECLARATION_FROM_JOB, {
    variables: { jobId: job.id },
    fetchPolicy: 'network-only',
    skip: true,
  })

  const { refetch: findConsolFromJob } = useQuery<
    Pick<Query, 'consolWithMatchCriteria'>,
    QueryConsolWithMatchCriteriaArgs
  >(FIND_CONSOL_FROM_JOB, {
    variables: { jobId: job.id },
    fetchPolicy: 'network-only',
    skip: true,
  })

  const [pushEdocsToCw, { loading: pushEdocsToCwLoading }] = useMutation<
    Pick<Mutation, 'pushEDocsToCw'>,
    MutationPushEDocsToCwArgs
  >(PUSH_EDOCS_TO_CARGOWISE, {
    refetchQueries: [
      { query: JOB_NOTES, variables: { jobId: job.id } },
      { query: GET_JOB_DATA, variables: { id: job.id } },
    ],
  })

  const [exportEDocumentsToApiPartner, { loading: exportEDocumentsToApiPartnerLoading }] =
    useMutation<
      Pick<Mutation, 'exportEDocumentsToApiPartner'>,
      MutationExportEDocumentsToApiPartnerArgs
    >(EXPORT_E_DOCUMENTS_TO_API_PARTNER, {
      refetchQueries: [
        { query: JOB_NOTES, variables: { jobId: job.id } },
        { query: GET_JOB_DATA, variables: { id: job.id } },
      ],
    })

  const toggleAll = (): void => {
    let checked = false
    let newSelectedFilePagesId = []
    if (selectedFilePagesId.length === 0) {
      checked = true
      newSelectedFilePagesId = filePages.map((filePage) => filePage.id)
      setSelectedFilePagesId(newSelectedFilePagesId)
    } else {
      checked = false
      newSelectedFilePagesId = selectedFilePagesId
      setSelectedFilePagesId([])
    }
    void logEvent(LogEventType.FILE_PAGES_SELECT, {
      job_id: job?.id,
      page_ids: newSelectedFilePagesId,
      checked: checked,
    })
  }

  const selectFilePage = (id: string, checked: boolean): void => {
    const newSelectedFilePagesId = checked
      ? [...selectedFilePagesId, id]
      : selectedFilePagesId.filter((selectedFilePageId) => selectedFilePageId !== id)
    setSelectedFilePagesId(newSelectedFilePagesId)
    void logEvent(LogEventType.FILE_PAGES_SELECT, {
      job_id: job?.id,
      page_ids: [id],
      checked: checked,
    })
  }

  const pushEdocs = useCallback(
    async (
      dataTargetType: EDocsDataTargetType,
      inputEDocuments: InputEDocument[],
      exportToApiPartner: boolean,
    ): Promise<void> => {
      setIsSendingToCw(true)
      let success = false
      let errorMessage = ''
      if (exportToApiPartner) {
        try {
          await exportEDocumentsToApiPartner({
            variables: {
              jobId: job.id,
              inputEDocuments,
              dataTargetType,
            },
          })
          enqueueSnackbar(`Successfully pushed eDocs to ${apiPartnerName}`, {
            variant: 'success',
          })
          closeConfirmDialog()
          setSelectedFilePagesId([])
          success = true
        } catch (error) {
          errorMessage = `Encountered an error while exporting eDocs to ${apiPartnerName}: ${formatMaybeApolloError(
            error,
          )}`
          enqueueSnackbar(errorMessage, { variant: 'error' })
        } finally {
          const selectedFilePagesId = inputEDocuments.flatMap(
            (inputEDoc) => inputEDoc.filePageIds as string[],
          )
          void logEvent(LogEventType.EDOCS_PUSH, {
            job_id: job?.id,
            page_ids: selectedFilePagesId,
            action_start: actionStart.current,
            action_end: new Date(),
            success: success,
            error_message: errorMessage,
          })
        }
      } else {
        try {
          const fetchShipmentOrConsol = [
            EDocsDataTargetType.Shipment,
            EDocsDataTargetType.Consol,
          ].includes(dataTargetType)
          if (fetchShipmentOrConsol) {
            const formattedFindShipmentResults = (): ReactElement => (
              <Box display='block' marginRight={1}>
                {formattedFindCwDataResults}
              </Box>
            )
            enqueueSnackbar(null, { variant: 'info', action: formattedFindShipmentResults })
          }
          await pushEdocsToCw({
            variables: {
              jobId: job.id,
              dataTargetType,
              inputEDocuments,
              overrideReconAttemptId: null,
            },
          })
          enqueueSnackbar('Successfully pushed eDocs to CargoWise', { variant: 'success' })
          closeConfirmDialog()
          setSelectedFilePagesId([])
          success = true
        } catch (error) {
          errorMessage = `Encountered an error while pushing eDocs to CargoWise: ${formatMaybeApolloError(
            error,
          )}`
          enqueueSnackbar(errorMessage, { variant: 'error' })
        } finally {
          const selectedFilePagesId = inputEDocuments.flatMap(
            (inputEDoc) => inputEDoc.filePageIds as string[],
          )
          void logEvent(LogEventType.EDOCS_PUSH, {
            job_id: job?.id,
            page_ids: selectedFilePagesId,
            action_start: actionStart.current,
            action_end: new Date(),
            success: success,
            error_message: errorMessage,
          })
        }
      }

      setIsSendingToCw(false)
    },
    [
      exportEDocumentsToApiPartner,
      job.id,
      enqueueSnackbar,
      apiPartnerName,
      pushEdocsToCw,
      formattedFindCwDataResults,
      logEvent,
    ],
  )

  const handlePushEdocs = async (
    dataTargetType: EDocsDataTargetType,
    apiPartner: ApiPartnerNode | null,
  ): Promise<void> => {
    try {
      actionStart.current = new Date()
      await saveJob()
      const filePagesToPush = filePages.filter((filePage) =>
        selectedFilePagesId.includes(filePage.id),
      )
      const hasFilePageWithNoCwFileType = filePagesToPush.some(
        (filePage) => !filePage!.document!.documentType!.cargowiseFileType,
      )
      if (hasFilePageWithNoCwFileType && !apiPartner) {
        throw new Error(
          'One of the selected file pages has no CW file type. Please configure in admin and try again.',
        )
      }
      setSelectedFilePagesToPush(filePagesToPush)
      setSelectedDataTargetType(dataTargetType)
      setExportToApiPartner(!!apiPartner)
      if (apiPartner) {
        setIsConfirmDialogOpen(true)
        return
      } else if (dataTargetType === EDocsDataTargetType.Shipment) {
        const findShipmentWithMatchCriteriaData = await findShipmentFromJob()
        const shipmentWithMatchCriteria =
          findShipmentWithMatchCriteriaData.data.shipmentWithMatchCriteria
        const chainIoShipment = shipmentWithMatchCriteria.chainIoShipment
        const shipmentNo = shipmentWithMatchCriteria.shipmentNo
        const hblNo = shipmentWithMatchCriteria.hblNo
        const orderNo = shipmentWithMatchCriteria.orderNo
        const consolNo = shipmentWithMatchCriteria.consolNo
        const mblNo = shipmentWithMatchCriteria.mblNo
        const carrierBookingNo = shipmentWithMatchCriteria.carrierBookingNo
        const containerNo = shipmentWithMatchCriteria.containerNo
        setFormattedFindCwDataResults(
          formatFindShipmentWithMatchCriteria(
            chainIoShipment,
            shipmentNo,
            hblNo,
            orderNo,
            consolNo,
            mblNo,
            carrierBookingNo,
            containerNo,
          ),
        )
        setHasSuccessfullyFoundShipment(!!shipmentWithMatchCriteria.chainIoShipment)
      } else if (dataTargetType === EDocsDataTargetType.Consol) {
        const findConsolWithMatchCriteriaData = await findConsolFromJob()
        const consolWithMatchCriteria = findConsolWithMatchCriteriaData.data.consolWithMatchCriteria
        const chainIoConsol = consolWithMatchCriteria.chainIoConsol
        const consolNo = consolWithMatchCriteria.consolNo
        const mblNo = consolWithMatchCriteria.mblNo
        const hblNo = consolWithMatchCriteria.hblNo
        const containerNo = consolWithMatchCriteria.containerNo
        const orderNo = consolWithMatchCriteria.orderNo
        const shipmentNo = consolWithMatchCriteria.shipmentNo
        const carrierBookingNo = consolWithMatchCriteria.carrierBookingNo
        setFormattedFindCwDataResults(
          formatFindConsolWithMatchCriteria(
            chainIoConsol,
            consolNo,
            mblNo,
            hblNo,
            shipmentNo,
            carrierBookingNo,
            orderNo,
            containerNo,
          ),
        )
        setHasSuccessfullyFoundConsol(!!consolWithMatchCriteria.chainIoConsol)
      } else if (dataTargetType === EDocsDataTargetType.CustomsDeclaration) {
        const findCustomsDeclarationWithMatchCriteriaData = await findCustomsDeclarationFromJob()
        const customsDeclarationWithMatchCriteria =
          findCustomsDeclarationWithMatchCriteriaData.data.customsDeclarationWithMatchCriteria
        const chainIoCustomsDeclaration =
          customsDeclarationWithMatchCriteria.chainIoCustomsDeclaration
        const referenceNo = customsDeclarationWithMatchCriteria.referenceNo
        const hblNo = customsDeclarationWithMatchCriteria.hblNo
        const orderNo = customsDeclarationWithMatchCriteria.orderNo
        const consolNo = customsDeclarationWithMatchCriteria.consolNo
        const mblNo = customsDeclarationWithMatchCriteria.mblNo
        const carrierBookingNo = customsDeclarationWithMatchCriteria.carrierBookingNo
        const containerNo = customsDeclarationWithMatchCriteria.containerNo
        setFormattedFindCwDataResults(
          formatFindCustomsDeclarationWithMatchCriteria(
            chainIoCustomsDeclaration,
            referenceNo,
            hblNo,
            orderNo,
            consolNo,
            mblNo,
            carrierBookingNo,
            containerNo,
          ),
        )
        setHasSuccessfullyFoundCustomsDeclaration(
          !!customsDeclarationWithMatchCriteria.chainIoCustomsDeclaration,
        )
      }
      setIsConfirmDialogOpen(true)
    } catch (error) {
      const errorMessage = apiPartner
        ? `Encountered an error while exporting eDocs to ${apiPartnerName}: ${formatMaybeApolloError(
            error,
          )}`
        : `Encountered an error while pushing eDocs to CargoWise: ${formatMaybeApolloError(error)}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const currentFilePageDisplay = (): ReactElement => {
    if (currentFilePage.type === FilePageType.Excel) {
      return (
        <Box display='flex' height='100%' width='100%' flex={1} flexDirection='column'>
          <FileSheetsViewer filePage={currentFilePage!} />
        </Box>
      )
    } else if (currentFilePage.type === FilePageType.Other) {
      return (
        <Box display='flex' height='100%' width='100%' flex={1} flexDirection='column'>
          <Typography> File cannot be displayed </Typography>
        </Box>
      )
    }
    return (
      <TransformWrapper
        pan={panOptions}
        wheel={wheelOptions}
        doubleClick={doubleClickOptions}
        options={transformWrapperOptions}
      >
        {
          // react-zoom-pan-pinch doesn't have proper typing available
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          ({ zoomIn, zoomOut, resetTransform }: any) => (
            <>
              <Box
                display='flex'
                justifyContent='flex-end'
                position='sticky'
                top={0}
                zIndex={theme.zIndex.modal - 1}
              >
                <ZoomControlBar
                  zoomIn={zoomIn}
                  zoomOut={zoomOut}
                  resetTransform={resetTransform}
                  scrollZoomEnabled={scrollZoomEnabled}
                  setScrollZoomEnabled={setScrollZoomEnabled}
                  panningEnabled={panningEnabled}
                  setPanningEnabled={setPanningEnabled}
                />
              </Box>
              <TransformComponent>
                <img
                  src={currentFilePage.imageUrl || ''}
                  alt={`${currentFilePage.file?.filename} page ${currentFilePage.pageNumber + 1}`}
                  className={classes.previewImage}
                />
              </TransformComponent>
            </>
          )
        }
      </TransformWrapper>
    )
  }

  return (
    <>
      <Box display='flex' width='100%' justifyContent='space-between' py={1}>
        <FormControlLabel
          control={
            <Checkbox
              onClick={toggleAll}
              checked={selectedFilePagesId.length === filePages.length}
              indeterminate={
                selectedFilePagesId.length > 0 && selectedFilePagesId.length < filePages.length
              }
              disabled={isSendingToCw}
              data-testid={'edocs-select-all-btn'}
            />
          }
          label='Select all'
        />
      </Box>
      <Box display='flex' alignItems='center' overflow='scroll' flex={1} mb={2}>
        {filePages.map((filePage) => (
          <Box display='inline-table' key={filePage.id} mr={2}>
            <Box
              className={classes.imageContainer}
              onClick={() => {
                if (filePage.id !== currentFilePage?.id) {
                  void logEvent(LogEventType.FILE_PAGES_VIEW, {
                    job_id: job?.id,
                    page_id: filePage.id,
                  })
                  setCurrentFilePage(filePage)
                }
              }}
            >
              <FilePageThumbnail
                type={filePage.type}
                file={filePage.imageUrl!}
                fileName={`${filePage.file?.filename} page ${filePage.pageNumber + 1}`}
              />
              {(filePage.isInCw ||
                filePage.eDocuments.edges
                  .map((edge) => edge.node)
                  .find((eDoc) => !!eDoc!.isInCw)) && (
                <Box className={classes.checkIcon}>
                  <CheckCircleOutlineIcon color='primary' />
                </Box>
              )}
              <Box className={classes.titleBox}>
                <Box
                  color={theme.palette.common.white}
                  whiteSpace='nowrap'
                  textOverflow='ellipsis'
                  overflow='hidden'
                  padding={1}
                >
                  <Typography variant='caption' color='initial'>
                    {`${filePage.file?.filename} (Page ${filePage.pageNumber + 1})`}
                  </Typography>
                </Box>
              </Box>
              <Checkbox
                checked={selectedFilePagesId.includes(filePage.id)}
                className={classes.checkbox}
                size='small'
                onChange={(_, checked) => selectFilePage(filePage.id, checked)}
              />
              <Box
                className={clsx({
                  [classes.selected]: filePage.id === currentFilePage?.id,
                })}
              />
            </Box>
          </Box>
        ))}
      </Box>
      {!isUniversalApi ? (
        <>
          {willPushToShipment && (
            <Button
              className={classes.button}
              variant='contained'
              disabled={
                pushEdocsToCwLoading ||
                exportEDocumentsToApiPartnerLoading ||
                isSendingToCw ||
                selectedFilePagesId.length === 0
              }
              onClick={() => handlePushEdocs(EDocsDataTargetType.Shipment, null)}
              data-testid={'push-edocs-to-shipment-btn'}
            >
              Push eDocs to Shipment
            </Button>
          )}
          {willPushToConsol && (
            <Button
              className={classes.button}
              variant='contained'
              disabled={
                pushEdocsToCwLoading ||
                exportEDocumentsToApiPartnerLoading ||
                isSendingToCw ||
                selectedFilePagesId.length === 0
              }
              onClick={() => handlePushEdocs(EDocsDataTargetType.Consol, null)}
            >
              Push eDocs to Consol
            </Button>
          )}
          {willPushToCustomsDeclaration && (
            <Button
              className={classes.button}
              variant='contained'
              disabled={
                pushEdocsToCwLoading ||
                exportEDocumentsToApiPartnerLoading ||
                isSendingToCw ||
                selectedFilePagesId.length === 0
              }
              onClick={() => handlePushEdocs(EDocsDataTargetType.CustomsDeclaration, null)}
              data-testid={'push-edocs-to-cdec-btn'}
            >
              Push eDocs to Customs Declaration
            </Button>
          )}
          {isAPRecon && (
            <Button
              className={classes.button}
              variant='contained'
              disabled={
                pushEdocsToCwLoading ||
                exportEDocumentsToApiPartnerLoading ||
                isSendingToCw ||
                selectedFilePagesId.length === 0
              }
              onClick={() => handlePushEdocs(EDocsDataTargetType.ApInvoice, null)}
            >
              Push eDocs to AP Invoice
            </Button>
          )}
        </>
      ) : (
        <>
          <Button
            className={classes.button}
            variant='contained'
            disabled={
              pushEdocsToCwLoading ||
              exportEDocumentsToApiPartnerLoading ||
              isSendingToCw ||
              selectedFilePagesId.length === 0
            }
            onClick={() => handlePushEdocs(EDocsDataTargetType.Shipment, apiPartner)}
          >
            Push eDocs to Shipment: {apiPartnerName}
          </Button>
          <Button
            className={classes.button}
            variant='contained'
            disabled={
              pushEdocsToCwLoading ||
              exportEDocumentsToApiPartnerLoading ||
              isSendingToCw ||
              selectedFilePagesId.length === 0
            }
            onClick={() => handlePushEdocs(EDocsDataTargetType.ApInvoice, apiPartner)}
          >
            Push eDocs to Invoice: {apiPartnerName}
          </Button>
        </>
      )}
      {isConfirmDialogOpen && selectedDataTargetType && (
        <EdocsPushConfirmDialog
          close={closeConfirmDialog}
          filePages={selectedFilePagesToPush}
          formattedFindCwDataResults={formattedFindCwDataResults}
          selectedDataTargetType={selectedDataTargetType}
          exportToApiPartner={exportToApiPartner}
          disablePushToShipment={
            displayFindCWDataResults &&
            !hasSuccessfullyFoundShipment &&
            !hasSuccessfullyFoundConsol &&
            !hasSuccessfullyFoundCustomsDeclaration
          }
          loading={pushEdocsToCwLoading || exportEDocumentsToApiPartnerLoading}
          pushEdocs={pushEdocs}
        />
      )}
      {currentFilePage && currentFilePageDisplay()}
    </>
  )
}

export default EDocsFileSelect
