import { FormControl, makeStyles, Paper, Theme } from '@material-ui/core'
import theme from '@src/utils/theme'
import { FunctionComponent } from 'react'
import DashboardNameAutocomplete from './DashboardNameAutocomplete'
import { getTabsToDisplay } from '@src/pages/admin/user/default-dashboards-whitelist/util'
import { DashboardTabType, Scalars } from '@src/graphql/types'
import {
  accountingTabToDisplayMap,
  businessPerformanceTabToDisplayMap,
  dashboardTabToDisplayMap,
  exploreTabToDisplayMap,
  operationsTabToDisplayMap,
  salesTabToDisplayMap,
} from '@src/pages/admin/user/default-dashboards-whitelist/constants'

type Props = {
  defaultDashboardsWhitelist?: string[]
  companyId?: Scalars['UUID']['input']
  isCreate?: boolean
}

const useStyles = makeStyles<Theme>({
  formLine: {
    marginBottom: theme.spacing(1),
  },
})

const DEFAULT_DASHBOARDS_WHITELIST = [
  'MAIN_DASHBOARD',
  'OVERVIEW',
  'ACCOUNT_OVERVIEW',
  'BRANCH_OVERVIEW',
  'DEPARTMENT_OVERVIEW',
  'REVENUE',
  'EXPENSES',
  'MARGINS',
  'ACCOUNTING_OVERVIEW',
  'ACCOUNTING_BILLING',
  'ACCOUNTING_MARGINS',
  'EXPLORE_SHIPMENTS',
  'SHIPMENT_DETAILS',
  'EXPLORE_ORGANIZATIONS',
  'PAYABLE_INVOICES',
  'RECEIVABLE_INVOICES',
  'ORGANIZATION_OVERVIEW',
  'SHIPMENTS',
  'SALES_OVERVIEW',
  'Agent Reports',
  'Staff Reports',
  'Carrier Reports',
  'Controlling Customer/Agent Reports',
  'Dormant Account Reports',
  'Quarterly Business Review Reports',
  'NEGATIVE_MARGIN_SHIPMENTS',
  'Payables Overview',
  'Receivables Overview',
]

// Sync this up with src/freight-bi/user-management/default-dashboards-whitelist/DefaultDashboardsWhitelist.tsx
const tabTypeToTabDisplayMap: Record<string, Record<string, string>> = {
  [DashboardTabType.Dashboard]: dashboardTabToDisplayMap,
  [DashboardTabType.Accounting]: accountingTabToDisplayMap,
  [DashboardTabType.BusinessPerformance]: businessPerformanceTabToDisplayMap,
  [DashboardTabType.Explore]: exploreTabToDisplayMap,
  [DashboardTabType.Operations]: operationsTabToDisplayMap,
  [DashboardTabType.Sales]: salesTabToDisplayMap,
}

const DefaultDashboardsWhitelist: FunctionComponent<Props> = ({
  isCreate = false,
  companyId,
  defaultDashboardsWhitelist = [],
}) => {
  const classes = useStyles()

  const dashboardWhitelist = isCreate ? DEFAULT_DASHBOARDS_WHITELIST : defaultDashboardsWhitelist
  const tabToWhitelistedDashboardsMap = getTabsToDisplay(dashboardWhitelist)

  return (
    <Paper elevation={0}>
      {Object.entries(tabToWhitelistedDashboardsMap).map(([tabType, values]) => {
        return (
          <FormControl fullWidth className={classes.formLine} key={tabType}>
            <DashboardNameAutocomplete
              companyId={companyId}
              tabType={tabType}
              dashboardTabsToDisplayMap={tabTypeToTabDisplayMap[tabType]}
              defaultValue={values}
              defaultDashboardsWhitelistSet={new Set(defaultDashboardsWhitelist)}
              isCreate={isCreate}
            />
          </FormControl>
        )
      })}
    </Paper>
  )
}

export default DefaultDashboardsWhitelist
