import { FunctionComponent } from 'react'
import { Box, makeStyles, Paper } from '@material-ui/core'

const useStyles = makeStyles({
  errorCard: {
    width: '50vw',
    height: '50vh',
  },
})

/**
 * For full page error cards in case of a page-wide error
 */
const ErrorCard: FunctionComponent = ({ children }) => {
  const classes = useStyles()
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      flex={'1'}
    >
      <Paper className={classes.errorCard} elevation={3}>
        <Box p={3}>{children}</Box>
      </Paper>
    </Box>
  )
}

export default ErrorCard
