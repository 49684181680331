import { FunctionComponent } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import ControllerTextField from '@src/components/controller-text-field/ControllerTextField'
import { InputField, InputFieldType, Maybe, SearchableRecordOptions } from '@src/graphql/types'
import ValuesSelector from '@src/components/admin/job-template-fields/ValuesSelector'
import DateFormatStringSelector from '@src/components/admin/job-template-fields/DateFormatStringSelector'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { useWatch } from 'react-hook-form'
import ValueExportMappingSelector from '@src/components/admin/job-template-fields/ValueExportMappingSelector'
import AutofillKeySelector from './AutofillKeySelector'
import SearchableRecordSelector from './SearchableRecordSelector'

type Props = {
  field: InputField
  fieldGroupIndex: number
  fieldIndex: number
  isLineItem: boolean
  isLineItemField: boolean
  searchableRecordOptions: Maybe<SearchableRecordOptions[]> | undefined
}
const useStyles = makeStyles({
  labelCell: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    // empirically determined to fit the labels -- might want to use flexbox instead of tables
    // to automatically do this
    width: '150px',
  },
})

const FieldDetails: FunctionComponent<Props> = ({
  field,
  fieldGroupIndex,
  fieldIndex,
  isLineItem,
  isLineItemField,
  searchableRecordOptions,
}) => {
  const namePrefix = isLineItem ? 'lineItemTypes' : 'metadataFieldGroups'
  const fieldType = useWatch({
    name: `${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.type`,
    defaultValue: field.type,
  })
  const classes = useStyles()

  return (
    <>
      <TableRow>
        <TableCell className={classes.labelCell}>Autofill key</TableCell>
        <TableCell>
          <AutofillKeySelector
            autofillKey={field.autofillKey}
            fieldGroupIndex={fieldGroupIndex}
            fieldIndex={fieldIndex}
            isLineItem={isLineItem}
            isLineItemField={isLineItemField}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.labelCell}>Export key</TableCell>
        <TableCell>
          <ControllerTextField
            name={`${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.exportKey`}
            friendlyName='Export key'
            defaultValue={field.exportKey}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.labelCell}>Helper text</TableCell>
        <TableCell>
          <ControllerTextField
            name={`${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.validatorDescription`}
            friendlyName='Helper Text'
            defaultValue={field.validatorDescription}
          />
        </TableCell>
      </TableRow>
      {fieldType === InputFieldType.Database ? (
        <TableRow>
          <TableCell className={classes.labelCell}>Database</TableCell>
          <TableCell>
            <SearchableRecordSelector
              searchableRecord={field.searchableRecord}
              fieldGroupIndex={fieldGroupIndex}
              fieldIndex={fieldIndex}
              isLineItem={isLineItem}
              searchableRecordOptions={searchableRecordOptions ?? []}
            />
          </TableCell>
        </TableRow>
      ) : (
        <>
          <TableRow>
            <TableCell className={classes.labelCell}>Regex</TableCell>
            <TableCell>
              {((fieldType === InputFieldType.Text ||
                fieldType === InputFieldType.TextWithSuggestions) && (
                <ControllerTextField
                  name={`${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.validatorRegex`}
                  friendlyName='Regex'
                  defaultValue={field.validatorRegex}
                />
              )) ||
                (fieldType === InputFieldType.Decimal &&
                  'Preset: numerals followed by optional decimal point and numerals (eg 1.23 or 1)') ||
                (fieldType === InputFieldType.Integer && 'Preset: numerals (eg 12)') ||
                (field.dateFormatString && 'Must follow date format') ||
                (fieldType === InputFieldType.Date &&
                  'Preset: YYYY-MM-DD, YYYY/MM/DD, YYYY-MMM-DDD, DD-MM-YYYY, format date string, or similar') ||
                (fieldType === InputFieldType.DateTime &&
                  'Preset: YYYY-MM-DD-HH:mm:ss, YYYY/MM/DD-HH:mm:ss, YYYY-MM-DDD-HH:mm:ss, DD-MM-YYYY-HH:mm:ss, format datetime string, or similar') ||
                (fieldType === InputFieldType.Price &&
                  'Preset: currency (eg $, USD) followed by number, or number followed by currency') ||
                '-'}
            </TableCell>
          </TableRow>
          {(fieldType === InputFieldType.Date || fieldType === InputFieldType.DateTime) && (
            <TableRow>
              <TableCell className={classes.labelCell}>Date Format</TableCell>
              <TableCell>
                <DateFormatStringSelector
                  field={field}
                  fieldGroupIndex={fieldGroupIndex}
                  fieldIndex={fieldIndex}
                  isDateTime={fieldType === InputFieldType.DateTime}
                  isLineItem={isLineItem}
                />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell className={classes.labelCell}>Invalid Characters</TableCell>
            <TableCell>
              {((fieldType === InputFieldType.Text ||
                fieldType === InputFieldType.TextWithSuggestions) && (
                <ControllerTextField
                  name={`${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.invalidCharsRegex`}
                  friendlyName='Invalid characters'
                  defaultValue={field.invalidCharsRegex}
                />
              )) ||
                (fieldType === InputFieldType.Decimal && 'Preset: only allow numeral or dot (.)') ||
                (fieldType === InputFieldType.Integer && 'Preset: only allow numeral') ||
                (fieldType === InputFieldType.Date &&
                  'Preset: only allow numeral, /, -, and letters') ||
                (fieldType === InputFieldType.DateTime &&
                  'Preset: only allow numeral, /, -, :, <space> and letters') ||
                (fieldType === InputFieldType.Price &&
                  'Preset: only allow numeral, dot (.), letters, or currency symbols') ||
                '-'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.labelCell}>Options</TableCell>
            <TableCell>
              {fieldType === InputFieldType.SingleSelectDropdown ||
              fieldType === InputFieldType.TextWithSuggestions ? (
                <ValuesSelector
                  field={field}
                  fieldGroupIndex={fieldGroupIndex}
                  fieldIndex={fieldIndex}
                  isLineItem={isLineItem}
                />
              ) : (
                '—'
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.labelCell}>Export</TableCell>
            <TableCell>
              {fieldType === InputFieldType.SingleSelectDropdown ||
              fieldType === InputFieldType.TextWithSuggestions ? (
                <ValueExportMappingSelector
                  field={field}
                  fieldGroupIndex={fieldGroupIndex}
                  fieldIndex={fieldIndex}
                  isLineItem={isLineItem}
                />
              ) : (
                '—'
              )}
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  )
}

export default FieldDetails
