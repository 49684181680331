import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { clsx } from 'clsx'
import { Button, DialogActions, Grid, Theme, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { useSnackbar } from 'notistack'

import CenteredCircularProgress from './centered-circular-progress/CenteredCircularProgress'

import { useMutation, useQuery } from '@apollo/client'
import {
  ChargeDetail,
  CwTargetModule,
  FindShipmentReconResultNode,
  Maybe,
  Mutation,
  MutationFetchChargeDetailsFromCwArgs,
  Query,
  QueryJobArgs,
} from '@src/graphql/types'
import { FETCH_CHARGE_DETAILS_FROM_CW } from '@src/graphql/mutations/recon'
import {
  formatCwTargetModule,
  getAPAssociatedKeysTable,
  getMetadataTable,
  maybeFindReconModuleFromReconResults,
  transformChargeDetailsToMatrix,
} from '@src/utils/recon/ap_recon'
import { CargowiseOpsType } from '@src/utils/cargowise/types'
import ForwardingShipmentDialog from '@src/components/check-shipment-info/ForwardingShipmentDialog'
import ForwardingConsolDialog from './check-shipment-info/ForwardingConsolDialog'
import CustomsDeclarationDialog from './check-shipment-info/CustomsDeclarationDialog'
import { GET_JOB_DATA } from '@src/graphql/queries/job'
import { getReconResultsDataModifiedDate, millisecondsInMinute } from '@src/utils/date'
import { FindShipmentResultDisplay } from '@src/utils/recon/FindShipmentResultDisplay'

const MIN_CELL_WIDTH = '75px'

const useStyles = makeStyles<Theme>({
  dialogMedium: {
    maxWidth: '90vw',
    minWidth: '20vw',
    height: '80vh',
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.75, 1),
    minWidth: MIN_CELL_WIDTH,
  },
  wrapWord: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  buttonGrid: {
    justifyContent: 'flex-end',
  },
  shipmentDate: {
    color: theme.palette.text.primary,
  },
  shipmentDateError: {
    color: theme.palette.error.main,
  },
})

type Props = {
  isOpen: boolean
  close: () => void
  jobId: string
  handleReconcile: () => Promise<void>
}

const CheckShipmentInfoDialog: FunctionComponent<Props> = ({
  isOpen,
  close,
  jobId,
  handleReconcile,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [expectedCharges, setExpectedCharges] = useState<Maybe<ChargeDetail[]>>(null)
  const [expectedDataMatrix, setExpectedDataMatrix] = useState<Maybe<string[][]>>(null)
  const [expectedMetadataTable, setExpectedMetadataTable] = useState<Maybe<string[][]>>(null)
  const [findShipmentReconResults, setFindShipmentReconResults] =
    useState<Maybe<FindShipmentReconResultNode[]>>(null)

  const [fetchChargeDetailsFromCw] = useMutation<
    Pick<Mutation, 'fetchChargeDetailsFromCw'>,
    MutationFetchChargeDetailsFromCwArgs
  >(FETCH_CHARGE_DETAILS_FROM_CW)
  const { loading: jobLoading, data: jobData } = useQuery<Pick<Query, 'job'>, QueryJobArgs>(
    GET_JOB_DATA,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: jobId,
      },
    },
  )

  const getExpectedCharges = useCallback(async () => {
    try {
      const chargeDetailsData = await fetchChargeDetailsFromCw({
        variables: { jobId },
      })
      setExpectedCharges(chargeDetailsData.data!.fetchChargeDetailsFromCw!.expectedCharges!)
      setFindShipmentReconResults(
        chargeDetailsData.data!.fetchChargeDetailsFromCw!.findShipmentReconResults!,
      )
      setExpectedMetadataTable(
        getMetadataTable(chargeDetailsData.data!.fetchChargeDetailsFromCw!.metadataReconResults!),
      )
    } catch (error) {
      enqueueSnackbar(
        `Failed to fetch shipment information from Cargowise: ${formatMaybeApolloError(error)}`,
        {
          variant: 'error',
        },
      )
      close()
    }
    // missing close() from deps, as its redefined every render, making the usecallback useless
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchChargeDetailsFromCw, jobId, enqueueSnackbar])

  const apAssociatedKeysTable = getAPAssociatedKeysTable(findShipmentReconResults ?? [], null)
  const cargowiseOpsType = CargowiseOpsType.Reconcile
  const cargowiseModule = maybeFindReconModuleFromReconResults(findShipmentReconResults ?? [])
  const shipmentDateModified = getReconResultsDataModifiedDate(
    findShipmentReconResults ?? [],
    null,
    true,
  )
  const isShipmentDateOutdated =
    shipmentDateModified &&
    new Date().getTime() - shipmentDateModified.getTime() > millisecondsInMinute * 30
  const chainIoModelName = formatCwTargetModule(cargowiseModule)

  useEffect(() => {
    void getExpectedCharges()
    // adding getExpectedCharges to deps makes it query twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId])

  useEffect(() => {
    if (expectedCharges && !expectedDataMatrix) {
      setExpectedDataMatrix(
        transformChargeDetailsToMatrix(expectedCharges, true, true, null, null, true),
      )
    }
  }, [expectedCharges, expectedDataMatrix, getExpectedCharges])

  return (
    <Dialog
      classes={{ paper: classes.dialogMedium }}
      open={isOpen}
      data-testid='check-shipment-info-dialog'
    >
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={close} data-testid='close-btn' />
      </Box>
      <DialogTitle>
        {findShipmentReconResults != null && (
          <Box mb={2}>
            {/* TODO: Shouldn't the module be derivable from the input data? */}
            <FindShipmentResultDisplay
              findShipmentResults={findShipmentReconResults}
              cargowiseOpsType={cargowiseOpsType}
              cargowiseModule={cargowiseModule || CwTargetModule.ForwardingShipment}
            />
          </Box>
        )}
        {shipmentDateModified && (
          <Box mb={2}>
            <Typography component={'span'} variant='body1'>
              {`${chainIoModelName} data last updated as of: `}
              <span
                className={clsx(classes.shipmentDate, {
                  [classes.shipmentDateError]: isShipmentDateOutdated,
                })}
              >
                {shipmentDateModified?.toString()}
              </span>
            </Typography>
          </Box>
        )}
      </DialogTitle>
      <DialogContent data-testid='data-matrix'>
        {expectedDataMatrix ? (
          <>
            {cargowiseModule === CwTargetModule.ForwardingShipment && (
              <ForwardingShipmentDialog
                expectedDataMatrix={expectedDataMatrix}
                expectedMetadataTable={expectedMetadataTable}
                apAssociatedKeysTable={apAssociatedKeysTable}
                findShipmentReconResults={findShipmentReconResults}
              />
            )}
            {cargowiseModule === CwTargetModule.ForwardingConsol && (
              <ForwardingConsolDialog
                expectedDataMatrix={expectedDataMatrix}
                expectedMetadataTable={expectedMetadataTable}
                apAssociatedKeysTable={apAssociatedKeysTable}
                findShipmentReconResults={findShipmentReconResults}
              />
            )}
            {cargowiseModule === CwTargetModule.CustomsDeclaration && (
              <CustomsDeclarationDialog
                expectedDataMatrix={expectedDataMatrix}
                expectedMetadataTable={expectedMetadataTable}
                apAssociatedKeysTable={apAssociatedKeysTable}
              />
            )}
          </>
        ) : (
          <CenteredCircularProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Grid className={classes.buttonGrid} container alignItems='flex-end' spacing={2}>
          <Grid item>
            <Button variant='contained' onClick={close} disabled={!expectedDataMatrix}>
              Go Back
            </Button>
          </Grid>
          <Grid item>
            {jobLoading ? (
              <CenteredCircularProgress />
            ) : (
              <>
                {jobData?.job.jobTemplate.shipmentOpsTypes.length === 0 && (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={async (): Promise<void> => {
                      await handleReconcile()
                      close()
                    }}
                    disabled={!expectedDataMatrix}
                    data-testid='do-reconcile-btn'
                  >
                    Reconcile
                  </Button>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default CheckShipmentInfoDialog
