import produce, { produceWithPatches } from 'immer'
import { FieldMappingValue } from '@src/types/fieldmapping'
import { BoxDimension } from '@src/types/ocr'
import { DocumentEditorState } from './document_editor_state'
import { lineItemsAdapter, lineItemSelectors } from './line_items_table'
import { computeNextStateWithUndo } from './undo_redo'

export const dragRowAndFieldBoxesReducer = (
  state: DocumentEditorState,
  lineItemId: string,
  coordsDelta: Pick<BoxDimension, 'top' | 'left'>,
): void => {
  const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
    const lineItemsTableMapDraft = stateDraft.lineItemsTableMap
    const lineItem = lineItemSelectors.selectById(state, lineItemId)
    const lineItemCopy = produce(lineItem, (draftState) => {
      const { left: deltaLeft, top: deltaTop } = coordsDelta
      draftState!.box!.left += deltaLeft
      draftState!.box!.top += deltaTop
      draftState!.fieldMapping = Object.fromEntries(
        Object.entries(draftState!.fieldMapping).map(([key, fieldMapping]) => {
          const fieldMappingCopy = {
            ...fieldMapping,
            left: fieldMapping.left! + deltaLeft,
            top: fieldMapping.top! + deltaTop,
          } as FieldMappingValue
          return [key, fieldMappingCopy]
        }),
      )
    })
    const lineItemsDraft = lineItemsTableMapDraft[state.activeDocumentTableId!]!.lineItems
    lineItemsAdapter.updateOne(lineItemsDraft, {
      id: lineItemId,
      changes: { ...lineItemCopy },
    })
  })
  computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
}

export const dragFieldBoxReducer = (
  state: DocumentEditorState,
  lineItemId: string,
  fieldBoxId: string,
  coords: Pick<BoxDimension, 'top' | 'left'>,
): void => {
  const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
    const lineItemsTableMapDraft = stateDraft.lineItemsTableMap
    const lineItem = lineItemSelectors.selectById(state, lineItemId)
    const lineItemCopy = produce(lineItem, (lineItemDraft) => {
      const { left: deltaLeft, top: deltaTop } = coords
      lineItemDraft!.fieldMapping = Object.fromEntries(
        Object.entries(lineItemDraft!.fieldMapping).map(([key, fieldMapping]) => {
          if (fieldMapping?.id === fieldBoxId) {
            const fieldMappingCopy = {
              ...fieldMapping,
              left: fieldMapping.left! + deltaLeft,
              top: fieldMapping.top! + deltaTop,
            }
            return [key, fieldMappingCopy]
          }
          return [key, fieldMapping]
        }),
      )
    })
    const lineItemsDraft = lineItemsTableMapDraft[state.activeDocumentTableId!]!.lineItems
    lineItemsAdapter.updateOne(lineItemsDraft, {
      id: lineItemId,
      changes: { ...lineItemCopy },
    })
  })
  computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
}

export const changeFieldBoxColumnReducer = (
  state: DocumentEditorState,
  lineItemId: string,
  fieldBoxId: string,
  columnKey: string,
): void => {
  /***
   * For updating the fieldMapping of a line item
   * according to the changes made by the user on a field box's associated column
   * (reassigning the column keys to the field)
   *
   * If the user attempts to change the field box column to something that already exists as a key in the fieldMapping,
   * we modify both fields to swap the keys
   * i.e. the current field will have the input column key, and the field that originally had the input column key
   * will now have the current field's old column key
   */
  const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
    const lineItemsTableMapDraft = stateDraft.lineItemsTableMap
    const lineItem = lineItemSelectors.selectById(state, lineItemId)
    const prevColumnKey = Object.keys(lineItem!.fieldMapping).filter(
      (key) => lineItem!.fieldMapping[key].id === fieldBoxId,
    )[0]
    const lineItemCopy = produce(lineItem, (lineItemDraft) => {
      lineItemDraft!.fieldMapping = Object.fromEntries(
        Object.entries(lineItemDraft!.fieldMapping).map(([key, fieldMapping]) => {
          if (fieldMapping.id === fieldBoxId) {
            return [columnKey, fieldMapping]
          } // swap fieldmappings for previous column and new column
          else if (key === columnKey) {
            return [prevColumnKey, fieldMapping]
          }
          return [key, fieldMapping]
        }),
      )
    })
    const lineItemsDraft = lineItemsTableMapDraft[state.activeDocumentTableId!]!.lineItems
    lineItemsAdapter.updateOne(lineItemsDraft, {
      id: lineItemId,
      changes: { ...lineItemCopy },
    })
  })
  computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
}

export const resizeFieldBoxReducer = (
  state: DocumentEditorState,
  lineItemId: string,
  fieldBoxId: string,
  dimension: BoxDimension,
): void => {
  const lineItem = lineItemSelectors.selectById(state, lineItemId)
  const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
    const lineItemsTableMapDraft = stateDraft.lineItemsTableMap
    const lineItemCopy = produce(lineItem, (lineItemDraft) => {
      const { left: deltaLeft, top: deltaTop, width, height } = dimension
      lineItemDraft!.fieldMapping = Object.fromEntries(
        Object.entries(lineItemDraft!.fieldMapping).map(([key, fieldMapping]) => {
          if (fieldMapping?.id === fieldBoxId) {
            const fieldMappingCopy = {
              ...fieldMapping,
              left: fieldMapping.left! + deltaLeft,
              top: fieldMapping.top! + deltaTop,
              width,
              height,
            }
            return [key, fieldMappingCopy]
          }
          return [key, fieldMapping]
        }),
      )
    })
    const lineItemsDraft = lineItemsTableMapDraft[stateDraft.activeDocumentTableId!]!.lineItems
    lineItemsAdapter.updateOne(lineItemsDraft, {
      id: lineItemId,
      changes: { ...lineItemCopy },
    })
  })
  computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
}
