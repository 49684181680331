import { FunctionComponent, MouseEvent, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { USER_COMPANIES } from '@src/graphql/queries/company'
import {
  Query,
  QueryUserJobTemplatesArgs,
  JobFilterColumn,
  JobFilterOperation,
} from '@src/graphql/types'
import theme from '@src/utils/theme'
import { useQuery } from '@apollo/client'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import FilterListIcon from '@material-ui/icons/FilterList'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/styles'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { JOB_OPERATORS, USER_JOB_TEMPLATES } from '@src/graphql/queries/job'
import { formatDateForTextField } from '@src/utils/date'
import { Popover } from '@material-ui/core'

const taskTypeValues = ['Live', 'Test'] as const
type TaskTypeValue = (typeof taskTypeValues)[keyof typeof taskTypeValues]

const columnToNameMap = {
  [JobFilterColumn.TaskType]: 'Live or Test',
  [JobFilterColumn.Company]: 'Company',
  [JobFilterColumn.DateCreated]: 'Date & Time Created',
  [JobFilterColumn.DateReceived]: 'Date & Time Received',
  [JobFilterColumn.DateCompleted]: 'Date & Time Completed',
  [JobFilterColumn.JobQa]: 'Job QA',
  [JobFilterColumn.JobOwner]: 'Job Owner',
  [JobFilterColumn.JobType]: 'Job Type',
}
const columnToOperationMap = {
  [JobFilterColumn.DateCreated]: [
    JobFilterOperation.IsNot,
    JobFilterOperation.Is,
    JobFilterOperation.Before,
    JobFilterOperation.After,
  ],
  [JobFilterColumn.DateCompleted]: [
    JobFilterOperation.IsNot,
    JobFilterOperation.Is,
    JobFilterOperation.Before,
    JobFilterOperation.After,
  ],
  [JobFilterColumn.DateReceived]: [
    JobFilterOperation.IsNot,
    JobFilterOperation.Is,
    JobFilterOperation.Before,
    JobFilterOperation.After,
  ],
  [JobFilterColumn.Company]: [JobFilterOperation.Contains, JobFilterOperation.DoesNotContain],
  [JobFilterColumn.TaskType]: [JobFilterOperation.Is, JobFilterOperation.IsNot],
  [JobFilterColumn.JobQa]: [JobFilterOperation.Contains, JobFilterOperation.DoesNotContain],
  [JobFilterColumn.JobOwner]: [JobFilterOperation.Contains, JobFilterOperation.DoesNotContain],
  [JobFilterColumn.JobType]: [JobFilterOperation.Contains, JobFilterOperation.DoesNotContain],
} as const

export type JobFilter =
  | {
      id: string
      column: JobFilterColumn.TaskType
      operation?: (typeof columnToOperationMap)[JobFilterColumn.TaskType][number]
      value?: TaskTypeValue[]
    }
  | {
      id: string
      column: JobFilterColumn.Company
      operation?: (typeof columnToOperationMap)[JobFilterColumn.Company][number]
      value?: string[]
    }
  | {
      id: string
      column:
        | JobFilterColumn.DateCompleted
        | JobFilterColumn.DateReceived
        | JobFilterColumn.DateCreated
      operation?: (typeof columnToOperationMap)[JobFilterColumn.DateReceived][number]
      value?: string[]
    }
  | {
      id: string
      column: JobFilterColumn.JobQa | JobFilterColumn.JobOwner | JobFilterColumn.JobType
      operation?: (typeof columnToOperationMap)[JobFilterColumn.JobQa][number]
      value?: string[]
    }

const formatFilterColumn = (column: JobFilterColumn): string => {
  return column.toLowerCase().replace('_', '-')
}

const formatFilterOperation = (operation: JobFilterOperation): string => {
  return operation.toLowerCase().replace('_', '-')
}

const useStyles = makeStyles({
  title: {
    marginBottom: theme.spacing(1),
  },
  typeButton: {
    marginBottom: theme.spacing(1),
  },
  typeField: {
    width: '160px',
    marginRight: theme.spacing(1),
  },
  symbolField: {
    width: '150px',
    marginRight: theme.spacing(1),
  },
  valueField: {
    width: '200px',
    marginRight: theme.spacing(1),
  },
  clearButton: {},
})

type Props = {
  updateJobFilterBuffer: (filters: JobFilter[]) => void
  jobFilters: JobFilter[]
}

const JobFilterMenu: FunctionComponent<Props> = ({ jobFilters, updateJobFilterBuffer }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null as Element | null)
  const [showAddFilter, setShowAddFilter] = useState(false)
  const { data: userCompanies, loading: userCompaniesLoading } =
    useQuery<Pick<Query, 'companies'>>(USER_COMPANIES)
  const { data: userJobTypes, loading: isJobTypesLoading } = useQuery<
    Pick<Query, 'userJobTemplates'>,
    QueryUserJobTemplatesArgs
  >(USER_JOB_TEMPLATES)
  const { data: operatorsData, loading: isLoadingOperators } =
    useQuery<Pick<Query, 'jobOperators'>>(JOB_OPERATORS)
  const companies = userCompanies?.companies || []
  const companyIds = companies.map(({ id }) => id)
  const companyIdMap = Object.fromEntries(companies.map((company) => [company.id, company]))
  const jobTypes = userJobTypes?.userJobTemplates || []
  const jobTypeIds = jobTypes.map(({ id }) => id)
  const jobTypeIdMap = Object.fromEntries(jobTypes.map((jobType) => [jobType.id, jobType]))
  const operators = operatorsData?.jobOperators || []
  const operatorIds = operators.map(({ id }) => id)
  const operatorIdMap = Object.fromEntries(operators.map((operator) => [operator.id, operator]))

  const handleClick = (e: MouseEvent): void => setAnchorEl(e.currentTarget)
  const handleClose = (): void => {
    setShowAddFilter(false)
    setAnchorEl(null)
  }

  const onUpdateFilter = (id: string, updatedFilter: JobFilter): void => {
    const idx = jobFilters.findIndex((filter) => filter.id === id)
    updateJobFilterBuffer([
      ...jobFilters.slice(0, idx),
      updatedFilter,
      ...jobFilters.slice(idx + 1),
    ])
  }

  const onDeleteFilter = (id: string): void => {
    updateJobFilterBuffer(jobFilters.filter((filter) => filter.id !== id))
  }

  const onAddNewFilter = (newFilter: JobFilter): void => {
    updateJobFilterBuffer([...jobFilters, newFilter])
    setShowAddFilter(false)
  }

  const renderFilterValue = (filter: JobFilter): JSX.Element | null => {
    const dataTestId = `filter-input-${formatFilterColumn(filter.column)}`
    switch (filter.column) {
      case JobFilterColumn.TaskType:
        return (
          <Select
            className={classes.valueField}
            value={filter.value?.[0] || ''}
            onChange={(e) =>
              onUpdateFilter(filter.id, {
                ...filter,
                value: [e.target.value as TaskTypeValue],
                column: JobFilterColumn.TaskType,
              })
            }
            data-testid={dataTestId}
          >
            {taskTypeValues.map((taskType) => (
              <MenuItem value={taskType} key={taskType}>
                {taskType}
              </MenuItem>
            ))}
          </Select>
        )
      case JobFilterColumn.Company:
        return userCompaniesLoading ? (
          <CenteredCircularProgress />
        ) : (
          <Autocomplete
            className={classes.valueField}
            multiple
            filterSelectedOptions
            options={companyIds}
            getOptionLabel={(companyId) => companyIdMap[companyId]?.name ?? ''}
            onChange={(_, value) => onUpdateFilter(filter.id, { ...filter, value })}
            getOptionSelected={(option, value) => value === option}
            value={filter.value || []}
            renderInput={(params) => (
              <TextField {...params} variant='standard' placeholder='Companies' />
            )}
            data-testid={dataTestId}
          />
        )
      case JobFilterColumn.DateCreated:
      case JobFilterColumn.DateReceived:
      case JobFilterColumn.DateCompleted:
        return (
          <TextField
            className={classes.valueField}
            type='datetime-local'
            fullWidth
            value={filter.value?.[0] ? formatDateForTextField(filter.value?.[0]) : ''}
            onChange={(e) => {
              onUpdateFilter(filter.id, { ...filter, value: [e.target.value] })
            }}
            name='dateTime'
            InputLabelProps={{
              shrink: true,
            }}
            data-testid={dataTestId}
          />
        )
      case JobFilterColumn.JobQa:
      case JobFilterColumn.JobOwner:
        return isLoadingOperators ? (
          <CenteredCircularProgress />
        ) : (
          <Autocomplete
            className={classes.valueField}
            multiple
            filterSelectedOptions
            options={operatorIds}
            getOptionLabel={(operatorId) =>
              operatorIdMap[operatorId]?.name || operatorIdMap[operatorId]?.email
            }
            onChange={(_, value) => onUpdateFilter(filter.id, { ...filter, value })}
            getOptionSelected={(option, value) => value === option}
            value={filter.value || []}
            renderInput={(params) => (
              <TextField {...params} variant='standard' placeholder='Operators' />
            )}
            data-testid={dataTestId}
          />
        )
      case JobFilterColumn.JobType:
        return isJobTypesLoading ? (
          <CenteredCircularProgress />
        ) : (
          <Autocomplete
            className={classes.valueField}
            multiple
            filterSelectedOptions
            options={jobTypeIds}
            getOptionLabel={(jobTypeId) => jobTypeIdMap[jobTypeId]?.name}
            onChange={(_, value) => onUpdateFilter(filter.id, { ...filter, value })}
            getOptionSelected={(option, value) => value === option}
            value={filter.value || []}
            renderInput={(params) => (
              <TextField {...params} variant='standard' placeholder='Job Type' />
            )}
            data-testid={dataTestId}
          />
        )
      default:
        return null
    }
  }

  return (
    <div>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        startIcon={<FilterListIcon />}
        size='large'
        data-testid='job-filter-menu-button'
      >
        {!!jobFilters.length && jobFilters.length}
      </Button>
      <Popover
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        data-testid='job-filter-menu-popover'
      >
        <Box display='flex' flexDirection='column' p={2}>
          {(!jobFilters.length || showAddFilter) && (
            <>
              <Typography className={classes.title} data-testid='job-filter-menu-header'>
                Add job filter
              </Typography>
              {Object.values(JobFilterColumn).map((column) => (
                <Button
                  className={classes.typeButton}
                  key={column}
                  variant='outlined'
                  onClick={() => {
                    onAddNewFilter({ column, id: uuidv4() })
                  }}
                >
                  {columnToNameMap[column]}
                </Button>
              ))}
            </>
          )}
          {!!jobFilters.length && !showAddFilter && (
            <>
              <Typography className={classes.title} data-testid='job-filter-menu-header'>
                Active filters
              </Typography>
              {jobFilters.map((filter) => (
                <Box key={filter.id} display='flex' alignItems='center' role='row'>
                  <Select
                    className={classes.typeField}
                    value={filter.column}
                    onChange={(e) =>
                      onUpdateFilter(filter.id, {
                        id: uuidv4(),
                        column: e.target.value as JobFilterColumn,
                      })
                    }
                  >
                    {Object.values(JobFilterColumn).map((column) => (
                      <MenuItem value={column} key={column}>
                        {columnToNameMap[column]}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    className={classes.symbolField}
                    value={filter.operation || ''}
                    onChange={(e) =>
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onUpdateFilter(filter.id, { ...filter, operation: e.target.value as any })
                    }
                    data-testid={`filter-operation-${formatFilterColumn(filter.column)}`}
                  >
                    {Object.values(columnToOperationMap[filter.column]).map((filterSymbol) => (
                      <MenuItem
                        value={filterSymbol}
                        key={filterSymbol}
                        data-testid={`filter-operation-${formatFilterColumn(
                          filter.column,
                        )}-${formatFilterOperation(filterSymbol)}`}
                      >
                        {filterSymbol.toLowerCase().split('_').join(' ')}
                      </MenuItem>
                    ))}
                  </Select>
                  {renderFilterValue(filter)}
                  <IconButton
                    onClick={() => onDeleteFilter(filter.id)}
                    className={`${classes.clearButton} filter-clear-button`}
                    name={'filter-clear-button'}
                  >
                    <ClearIcon />
                  </IconButton>
                </Box>
              ))}
              <Button size='small' startIcon={<AddIcon />} onClick={() => setShowAddFilter(true)}>
                Add filter
              </Button>
            </>
          )}
        </Box>
      </Popover>
    </div>
  )
}

export default JobFilterMenu
