import { FunctionComponent } from 'react'
import { BoxingProvider } from '@src/contexts/boxing_context'
import { SidebarProvider } from '@src/components/content-with-sidebar/sidebar-context'
import ContentWithSidebar from '@src/components/content-with-sidebar/ContentWithSidebar'
import JobViewer from '@src/components/job-viewer'
import { JobDataProvider } from '@src/contexts/job_data_context'

type Props = {
  jobId: string
}

const JobViewerWithSidebar: FunctionComponent<Props> = ({ jobId, children }) => {
  return (
    <BoxingProvider>
      <SidebarProvider>
        <JobDataProvider jobId={jobId}>
          <ContentWithSidebar sidebar={children} content={<JobViewer jobId={jobId} />} />
        </JobDataProvider>
      </SidebarProvider>
    </BoxingProvider>
  )
}

export default JobViewerWithSidebar
