import { FunctionComponent } from 'react'
import { TaskNode, TaskType } from '@src/graphql/types'
import Avatar from 'react-avatar'
import theme from '@src/utils/theme'
import { makeStyles } from '@material-ui/styles'
import { Box, Chip, Typography } from '@material-ui/core'

const TITLE_HEIGHT = 25
const AVATAR_SIZE = 24
const CARD_HEADER_PADDING = theme.spacing(1)
const TASK_CARD_HEADER_COLOR = '#8C9397'

const useStyles = makeStyles({
  title: {
    height: TITLE_HEIGHT,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: `0 ${CARD_HEADER_PADDING}px`,
    color: 'white',
  },
  avatar: {
    display: 'block',
    '& > div': {
      fontSize: `${theme.typography.fontSize}px !important`,
    },
  },
  testChip: {
    backgroundColor: 'white',
    color: TASK_CARD_HEADER_COLOR,
  },
})

type Props = {
  task: TaskNode
}

const TaskCardHeader: FunctionComponent<Props> = ({ task }) => {
  const classes = useStyles()
  const taskCompanyName = task.company?.name || '?'

  return (
    <>
      <Avatar name={taskCompanyName} round size={`${AVATAR_SIZE}`} className={classes.avatar} />
      <Box flexGrow={1} className={classes.title}>
        <Typography variant='subtitle1' noWrap>
          {taskCompanyName}
        </Typography>
      </Box>
      {task.taskType === TaskType.Test && (
        <Chip className={classes.testChip} label='Test' size='small' />
      )}
    </>
  )
}

export default TaskCardHeader
export { TaskCardHeader, AVATAR_SIZE }
