import { formatMaybeApolloError } from '@src/utils/errors'
import { ChangeEvent, FunctionComponent, useState } from 'react'
import { Box, Paper, TextField, Theme, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/core/styles'
import theme from '@src/utils/theme'
import { useSnackbar } from 'notistack'
import {
  useCompanyChargeCodesLazyQuery,
  useCompanyChargeCodesV2LazyQuery,
} from '@src/graphql/types'
import { useEffect } from 'react'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import {
  ChargeCodeFromSearch,
  ChargeCodeSearchResults,
  ChargeCodeV2FromSearch,
  ChargeCodeV2SearchResults,
} from './types'

type Props = {
  chargeCode: ChargeCodeFromSearch | ChargeCodeV2FromSearch | undefined
  setChargeCode: (chargeCode: ChargeCodeFromSearch | ChargeCodeV2FromSearch) => void
  companyId: string
  usesChargeCodeV2: boolean
  apiPartnerId: string | null
  refreshSearch: boolean
  setRefreshSearch: (refreshSearch: boolean) => void
}

const useStyles = makeStyles<Theme>({
  searchBar: {
    padding: theme.spacing(2),
  },
  searchResult: {
    padding: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  maxResultsError: {
    color: theme.palette.error.main,
    padding: theme.spacing(1),
  },
  placeHolderResultMessage: {
    padding: theme.spacing(2),
  },
})

// so ops doesn't scroll through 10k charge codes
const MAX_CHARGE_CODE_ADMIN_SEARCH_RESULTS = 50

const ChargeCodeSearchBar: FunctionComponent<Props> = ({
  chargeCode,
  companyId,
  usesChargeCodeV2,
  apiPartnerId,
  setChargeCode,
  refreshSearch,
  setRefreshSearch,
}) => {
  const classes = useStyles()
  const [searchResults, setSearchResults] = useState<ChargeCodeSearchResults>([])
  const [searchResultsV2, setSearchResultsV2] = useState<ChargeCodeV2SearchResults>([])
  const [searchValue, setSearchValue] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const [search, { loading: loadingCompanyChargeCodes }] = useCompanyChargeCodesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.companyChargeCodes !== null) {
        if (chargeCode) {
          const companyChargeCodes = data.companyChargeCodes
          const newChargeCode = companyChargeCodes.find(
            (newChargeCode) => newChargeCode!.code === chargeCode!.code,
          )
          if (newChargeCode) {
            setChargeCode(newChargeCode)
          }
        }
        setSearchResults(data.companyChargeCodes)
      }
    },
    onError: (error) => {
      enqueueSnackbar(`Error searching charge codes: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const [searchV2, { loading: loadingCompanyChargeCodesV2 }] = useCompanyChargeCodesV2LazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.companyChargeCodesV2 !== null) {
        if (chargeCode) {
          const companyChargeCodes = data.companyChargeCodesV2
          const newChargeCode = companyChargeCodes.find(
            (newChargeCode) => newChargeCode!.code === chargeCode!.code,
          )
          if (newChargeCode) {
            setChargeCode(newChargeCode)
          }
        }
        setSearchResultsV2(data.companyChargeCodesV2)
      }
    },
    onError: (error) => {
      enqueueSnackbar(`Error searching charge codes: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  const searchByQuery = (query: string): void => {
    if (!apiPartnerId) {
      setSearchResults([])
      setSearchResultsV2([])
      setSearchValue('')
      return
    }
    if (usesChargeCodeV2) {
      void searchV2({
        variables: {
          companyId,
          apiPartnerId,
          query: query,
        },
      })
    } else {
      void search({
        variables: {
          companyId,
          apiPartnerId,
          query: query,
        },
      })
    }
  }

  useEffect(() => {
    if (refreshSearch) {
      searchByQuery(searchValue)
      setRefreshSearch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSearch])

  const searchForChargeCodes = (e: ChangeEvent<{ value: string }>): void => {
    const searchQuery = e?.target?.value ?? ''
    setSearchValue(searchQuery)
    searchByQuery(searchQuery)
  }

  if (loadingCompanyChargeCodes || loadingCompanyChargeCodesV2) {
    return <CenteredCircularProgress />
  }

  return (
    <div>
      <TextField
        variant='outlined'
        fullWidth
        className={classes.searchBar}
        value={searchValue}
        onChange={searchForChargeCodes}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {(searchResults.length >= MAX_CHARGE_CODE_ADMIN_SEARCH_RESULTS ||
        searchResultsV2.length >= MAX_CHARGE_CODE_ADMIN_SEARCH_RESULTS) && (
        <Typography className={classes.maxResultsError}>
          Only showing the first {searchResults.length} results. Filter by name to show the rest.
        </Typography>
      )}
      <Box maxHeight={`${theme.spacing(80)}px`} overflow='scroll'>
        {searchResults.length == 0 && searchResultsV2.length == 0 && (
          <Typography align='center' className={classes.placeHolderResultMessage}>
            No charge codes found matching the search criteria. Please select a cargowise config /
            api partner or try other search values
          </Typography>
        )}
        {searchResults.map((sr) => (
          <Paper key={sr.id} className={classes.searchResult} onClick={() => setChargeCode(sr)}>
            <Typography> {sr.code} </Typography>
          </Paper>
        ))}
        {searchResultsV2.map((sr) => (
          <Paper key={sr.id} className={classes.searchResult} onClick={() => setChargeCode(sr)}>
            <Typography> {sr.code} </Typography>
          </Paper>
        ))}
      </Box>
    </div>
  )
}

export default ChargeCodeSearchBar
