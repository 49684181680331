import { Box, Grid, Button, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { CompanyNode } from '@src/graphql/types'
import { formatMaybeApolloError } from '@src/utils/errors'
import { useDialog } from 'muibox'
import { FunctionComponent, useCallback, useState } from 'react'

type Props = {
  companies: CompanyNode[]
  syncSearchableRecords: (companyIds: string[] | null | undefined) => Promise<void>
}

const SyncSearchableRecordsOptions: FunctionComponent<Props> = ({
  companies,
  syncSearchableRecords,
}) => {
  const [selectedCompany, setSelectedCompany] = useState(null as null | CompanyNode)
  const [isSyncing, setIsSyncing] = useState(false)
  const dialog = useDialog()

  const triggerSync = useCallback(async (): Promise<void> => {
    setIsSyncing(true)
    try {
      await syncSearchableRecords(selectedCompany ? [selectedCompany.id] : [])
      await dialog.alert({
        title: `Searchable record sync successful`,
        message: `Successfully synchronized searchable records`,
      })
    } catch (e) {
      await dialog.alert({
        title: 'Error during searchable records synchronization',
        message: (
          <>
            <Typography>The following error was encountered while syncing the database</Typography>
            <blockquote>{formatMaybeApolloError(e)}</blockquote>
          </>
        ),
      })
    } finally {
      setIsSyncing(false)
    }
  }, [syncSearchableRecords, selectedCompany, dialog])

  return (
    <Box py={1}>
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={12} md={4}>
          <Autocomplete
            multiple={false}
            options={companies}
            getOptionLabel={(option: CompanyNode) => option.name!}
            value={selectedCompany}
            onChange={(_event, newValue) => {
              setSelectedCompany(newValue)
            }}
            renderInput={(params) => <TextField variant='outlined' {...params} label='Company' />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            fullWidth
            disabled={isSyncing}
            onClick={triggerSync}
            variant='outlined'
            color='primary'
          >
            {isSyncing ? 'Syncing...' : 'Trigger Synchronize Searchable Records'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SyncSearchableRecordsOptions
