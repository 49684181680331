import { capitalCase } from 'capital-case'

export const underscoreCapitalCaseText = (text: string): string => {
  return capitalCase(text).replace(/ /g, '_')
}

export const assembleSingleElementFromList = (elements: JSX.Element[]): JSX.Element => {
  // Lists Elements into a single line which is comma separated with an and
  // eg. ([<>item1</>,<>item2</>,<>item3</>]) => <><>item1</>, <>item2</>, and <>item3</></>
  return (
    <>
      {elements.length > 1 && (
        <>
          {elements.slice(0, -1).reduce((acc, element) => (
            <>
              {acc}, {element}
            </>
          ))}
          , and{' '}
        </>
      )}
      {elements.slice(-1)[0]}
    </>
  )
}
