import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useLazyQuery } from '@apollo/client'
import useValidateChargeCodes from '@src/hooks/charge_codes/use_validate_charge_codes'
import useTMSJobData from '@src/hooks/useTMSJobData'
import { FIND_INVOICE_RECON_RESULT_FROM_JOB } from '@src/graphql/queries/recon'
import {
  DocumentTableNode,
  FindShipmentReconResultNode,
  InputDocumentTable,
  JobNode,
  JobTemplateReconType,
  MatchingCriteriaType,
  Maybe,
  Query,
  QueryFindInvoiceReconResultFromJobArgs,
  QueryFindShipmentReconResultsArgs,
  ReconResultType,
} from '@src/graphql/types'
import { formatMaybeApolloError } from '@src/utils/errors'
import { getLumpsum, getLumpsumTable, getLumpsumInputTable } from '@src/utils/lumpsum'
import { removeChargeDescription } from '@src/utils/override_charge_description'
import { ReconJobResults } from '@src/utils/recon/recon'
import { HotTable } from '@handsontable/react'
import { FIND_SHIPMENT_RECON_RESULTS_FROM_JOB } from '@src/graphql/queries/recon'
import { isFallback } from '@src/utils/enum'
import { ApInvoiceReconResultNode } from '@src/utils/recon/ap_recon'

type ReconDataHooksType = {
  job: Maybe<JobNode>
  metadataFieldValueMap: Record<string, string>
  reconTables: DocumentTableNode[]
  isReconDataLoading: boolean
  initLumpsum: Record<string, string | number>
  setInitLumpsum: (lumpsum: Record<string, string | number>) => void
  reconJobResults: ReconJobResults | null
  sendAsLumpsum: boolean
  toggleSendAsLumpSum: () => void
  overrideChargeDescriptionFlag: boolean
  toggleOverrideChargeDescriptionFlag: () => void
  reconcileCriteriaFlag: boolean
  toggleReconcileCriteriaFlag: () => void
  disableSendDueDateFlag: boolean
  toggleDisableSendDueDateFlag: () => void
  isPremiumRate: boolean
  isValidatingChargeCodes: boolean
  invalidDocChargeCodes: string[]
  noJobChargeVendor?: Maybe<boolean>
  noJobChargeCodes?: Maybe<boolean>
  lumpsumHotTableRef: RefObject<HotTable>
  findShipmentReconResults: FindShipmentReconResultNode[]
}

const useReconData = (
  jobId: string,
  reconType: JobTemplateReconType.Ap | JobTemplateReconType.ArrivalNotice,
  skipInvoiceRecon = false,
  defaultOverrideChargeDescriptionFlag = false,
): ReconDataHooksType => {
  const sendAsLumpsumDefault = reconType === JobTemplateReconType.ArrivalNotice
  const [reconJobResults, setReconJobResults] = useState(null as ReconJobResults | null)
  const [initLumpsum, setInitLumpsum] = useState({} as Record<string, string | number>)
  const [findShipmentReconResults, setFindShipmentReconResults] = useState(
    [] as FindShipmentReconResultNode[],
  )
  const lumpsumHotTableRef = useRef<HotTable>(null)

  /*
   * Sending options and toggle functions
   */
  const [sendAsLumpsum, setSendAsLumpsum] = useState(sendAsLumpsumDefault)
  const toggleSendAsLumpSum = (): void => setSendAsLumpsum(!sendAsLumpsum)
  const [reconcileCriteriaFlag, setReconcileCriteriaFlag] = useState(false)
  const toggleReconcileCriteriaFlag = (): void => setReconcileCriteriaFlag(!reconcileCriteriaFlag)
  const [disableSendDueDateFlag, setDisableSendDueDateFlag] = useState(false)
  const toggleDisableSendDueDateFlag = (): void =>
    setDisableSendDueDateFlag(!disableSendDueDateFlag)
  const [overrideChargeDescriptionFlag, setOverrideChargeDescriptionFlag] = useState(
    defaultOverrideChargeDescriptionFlag,
  )
  const toggleOverrideChargeDescriptionFlag = (): void =>
    setOverrideChargeDescriptionFlag(!overrideChargeDescriptionFlag)

  const matchingCriteria = reconcileCriteriaFlag
    ? MatchingCriteriaType.ChargeCodeVendorInvoiceNumber
    : MatchingCriteriaType.ChargeCodeOnly

  const { enqueueSnackbar } = useSnackbar()
  const {
    job,
    jobDataLoading,
    documentFieldGroups,
    metadataFieldValueMap,
    reconTables,
    isPremiumRate,
  } = useTMSJobData(jobId, reconType)

  const { isValidatingChargeCodes, invalidDocChargeCodes, noJobChargeVendor, noJobChargeCodes } =
    useValidateChargeCodes(
      job?.jobTemplate ?? null,
      documentFieldGroups,
      reconTables,
      null,
      reconType,
    )

  const [findInvoiceReconResult, { loading: findInvoiceReconResultLoading }] = useLazyQuery<
    Pick<Query, 'findInvoiceReconResultFromJob'>,
    QueryFindInvoiceReconResultFromJobArgs
  >(FIND_INVOICE_RECON_RESULT_FROM_JOB, {
    fetchPolicy: 'network-only',
    onCompleted: ({ findInvoiceReconResultFromJob }) => {
      setReconJobResults(findInvoiceReconResultFromJob as ReconJobResults)
      const reconResults = findInvoiceReconResultFromJob?.reconResults as ApInvoiceReconResultNode[]
      const shipmentReconResults =
        reconResults.filter(
          (reconResult) =>
            !isFallback(reconResult.type) &&
            reconResult.type?.value === ReconResultType.FindShipmentReconResult,
        ) ?? []
      setFindShipmentReconResults(shipmentReconResults)
    },
    onError: (error) => {
      const errorMessage = `Error reconciling job: ${formatMaybeApolloError(error)}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
      close()
    },
  })

  // Sometimes we only want the findShipmentReconResults (as opposed to doing a full reconciliation)
  // because there's no need for some modals to display the AP Invoice Recon Result Tables
  const [findShipmentReconResult, { loading: findShipmentReconResultLoading }] = useLazyQuery<
    Pick<Query, 'findShipmentReconResults'>,
    QueryFindShipmentReconResultsArgs
  >(FIND_SHIPMENT_RECON_RESULTS_FROM_JOB, {
    variables: { jobId },
    fetchPolicy: 'network-only',
    onCompleted: ({ findShipmentReconResults }) => {
      setFindShipmentReconResults(findShipmentReconResults)
    },
  })

  const reconcile = useCallback(
    async (
      lumpsum: InputDocumentTable | null,
      overrideChargeDescription = false,
      disableSendDueDate = false,
    ): Promise<void> => {
      if (reconType === JobTemplateReconType.Ap) {
        if (skipInvoiceRecon) {
          void findShipmentReconResult({ variables: { jobId } })
        } else {
          void findInvoiceReconResult({
            variables: {
              jobId,
              lumpsum,
              overrideChargeDescription,
              disableSendDueDate,
              matchingCriteria,
            },
          })
        }
      } else if (reconType === JobTemplateReconType.ArrivalNotice) {
        /*
          Reconciliation before sending is not supported on Arrival Notice doc types yet
          TODOS:
            - possibly reuse the reconcile function in ReconciliationDialog.tsx
            - edit isSendingDisabled below once this gets supported
        */
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, jobId, matchingCriteria, reconType, findInvoiceReconResult],
  )

  useEffect(() => {
    if (jobDataLoading) return
    const lumpsumTable = getLumpsumTable(reconTables, reconType)
    let lumpsumRow_ = {} as Record<string, string | number>
    if (sendAsLumpsum && lumpsumTable) {
      if (!overrideChargeDescriptionFlag) {
        const filteredLumpsumTable = removeChargeDescription(lumpsumTable)
        const { lumpsumRow } = getLumpsum(filteredLumpsumTable, reconType)
        lumpsumRow_ = lumpsumRow
      } else {
        const { lumpsumRow } = getLumpsum(lumpsumTable, reconType)
        lumpsumRow_ = lumpsumRow
      }

      if (lumpsumHotTableRef.current) {
        const lumpsumValues = Object.values(lumpsumRow_)
        lumpsumHotTableRef.current.hotInstance.loadData([lumpsumValues])
      }
    }
    setInitLumpsum(lumpsumRow_)

    if (reconType === JobTemplateReconType.Ap) {
      let lumpsumInputTable = null
      if (sendAsLumpsum && lumpsumRow_ && lumpsumTable) {
        const lumpsumRowData = Object.values(lumpsumRow_)
        const lumpsumColumns = Object.keys(lumpsumRow_)
        lumpsumInputTable = getLumpsumInputTable(lumpsumRowData, lumpsumColumns, lumpsumTable)
      }

      // TODO: ideally we dont do this reconcile step and just fetch the latest invoice line items
      // to display as expected data and get the document line items from JMS to display as invoice data
      if (!sendAsLumpsum) {
        void reconcile(lumpsumInputTable, overrideChargeDescriptionFlag, disableSendDueDateFlag)
      }
    }
  }, [
    jobDataLoading,
    reconTables,
    reconType,
    overrideChargeDescriptionFlag,
    reconcile,
    sendAsLumpsum,
    disableSendDueDateFlag,
    skipInvoiceRecon,
  ])

  const isReconDataLoading =
    jobDataLoading ||
    isValidatingChargeCodes ||
    findInvoiceReconResultLoading ||
    findShipmentReconResultLoading

  return {
    job,
    metadataFieldValueMap,
    reconTables,
    isReconDataLoading,
    initLumpsum,
    setInitLumpsum,
    reconJobResults,
    sendAsLumpsum,
    toggleSendAsLumpSum,
    overrideChargeDescriptionFlag,
    toggleOverrideChargeDescriptionFlag,
    reconcileCriteriaFlag,
    toggleReconcileCriteriaFlag,
    disableSendDueDateFlag,
    toggleDisableSendDueDateFlag,
    isPremiumRate,
    isValidatingChargeCodes,
    invalidDocChargeCodes,
    noJobChargeVendor,
    noJobChargeCodes,
    lumpsumHotTableRef,
    findShipmentReconResults,
  }
}

export default useReconData
