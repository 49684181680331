import { FunctionComponent, MutableRefObject, useState } from 'react'
import { Paper } from '@material-ui/core'
import { HotTable } from '@handsontable/react'
import { useEffect } from 'react'
import theme from '@src/utils/theme'
import { SpreadsheetDataColumn } from '@src/utils/data-grid'
import FastHotTable from '@src/components/fast-hot-table/FastHotTable'

type Props = {
  chargeCodes: Array<string>
  hotTableRef: MutableRefObject<HotTable>
  autoCompleteSuggestions: Array<string>
}

const ChargeCodesTable: FunctionComponent<Props> = ({
  chargeCodes,
  hotTableRef,
  autoCompleteSuggestions,
}) => {
  const [rows, setRows] = useState([] as string[][])

  const _getColumns = (autoCompleteSuggestions: string[]): SpreadsheetDataColumn[] => {
    return [
      {
        key: 'chargeCode',
        name: 'Charge Code',
        type: 'autocomplete',
        source: autoCompleteSuggestions,
        strict: true,
      },
    ]
  }
  const [columns, setColumns] = useState(_getColumns(autoCompleteSuggestions))

  useEffect(() => {
    if (chargeCodes) {
      const newRows = chargeCodes.map((code) => [code || ''])
      setRows(newRows as string[][])
    }
  }, [chargeCodes])

  useEffect(() => {
    setColumns(_getColumns(autoCompleteSuggestions))
  }, [autoCompleteSuggestions])

  return (
    <Paper data-testid='charge-codes-table'>
      <FastHotTable
        data={rows}
        hotTableRef={hotTableRef}
        hooks={{}}
        style={{
          width: '100%',
          overflow: 'hidden',
          height: '600px',
          background: theme.palette.grey[200],
        }}
        settings={{
          rowHeaders: true,
          contextMenu: ['row_above', 'row_below', 'remove_row'],
          colHeaders: columns.map((col) => col.name),
          columnSorting: true,
          filters: true,
          autoRowSize: true,
          stretchH: 'all',
          columns,
        }}
      />
    </Paper>
  )
}

export default ChargeCodesTable
