import { ReactElement } from 'react'
import { Typography } from '@material-ui/core'
import {
  FindConsolReconResultNode,
  ArrivalNoticeConsolTypeReconResultNode,
  ArrivalNoticeMetadataReconResultNode,
  ArrivalNoticeContainerReconResultNode,
  ArrivalNoticeContractReconResultNode,
  ArrivalNoticeChargeRateReconResultNode,
  ArrivalNoticeLumpsumReconResultNode,
  AnReconAutofillKey,
  ReconResultType,
  RateLocationStringNode,
  Maybe,
  ChainIoConsolidationNode,
} from '@src/graphql/types'
import { parseDateString } from '@src/utils/date'
import { isFallback } from '@src/utils/enum'

export type ArrivalNoticeReconResultNode = FindConsolReconResultNode &
  ArrivalNoticeConsolTypeReconResultNode &
  ArrivalNoticeMetadataReconResultNode &
  ArrivalNoticeContainerReconResultNode &
  ArrivalNoticeContractReconResultNode &
  ArrivalNoticeChargeRateReconResultNode &
  ArrivalNoticeLumpsumReconResultNode

export enum RateTableDataType {
  DATA,
  COMMENTS,
  ERROR_CELLS,
}

export enum MetadataTableDataType {
  DATA,
  ERROR_ROWS,
}

type RateTableData = {
  [RateTableDataType.DATA]: Record<RateFilter, (string | number)[]>
  [RateTableDataType.COMMENTS]: Record<string, string>
  [RateTableDataType.ERROR_CELLS]: Record<number, RateFilter>
}

type MetadataTableData = {
  [MetadataTableDataType.DATA]: string[][]
  [MetadataTableDataType.ERROR_ROWS]: number[]
}

export enum RateFilter {
  PORT_OF_LOADING,
  PORT_OF_DISCHARGE,
  PLACE_OF_RECEIPT,
  PLACE_OF_DELIVERY,
  RATE_CODE,
  CONTAINER_SIZE,
  CONTAINER_TYPE,
  COMMODITY,
  NAMED_ACCOUNT,
  CURRENCY,
  ROUTE,
  AMENDMENT_NUMBER,
  RATE_COST,
  EXPECTED_RATE_COST,
  QUANTITY,
  AMOUNT,
  EXPECTED_AMOUNT,
}

export const CHARGE_RATE_NOT_FOUND_MESSAGE = 'Rate not found'

export const constructKeyFromRowColIdx = (rowIdx: number, colIdx: number): string =>
  `${rowIdx}-${colIdx}`

export const getChargeRateTableData = (
  reconResults: ArrivalNoticeChargeRateReconResultNode[],
): RateTableData =>
  reconResults.reduce((acc, reconResult, idx) => {
    if (idx === 0) {
      acc[RateTableDataType.DATA] = {} as Record<RateFilter, (string | number)[]>
      acc[RateTableDataType.COMMENTS] = {}
      acc[RateTableDataType.ERROR_CELLS] = {} as Record<number, RateFilter>
    }
    // We add 1 to accommodate field name column
    const tableColIdx = idx + 1
    const updateAcc = (
      filter: RateFilter,
      fieldName: string,
      documentValue: string | number,
      matchesFound?: number,
      similarRateLocations?: RateLocationStringNode[] | null,
    ): void => {
      acc[RateTableDataType.DATA][filter] = [
        ...(acc[RateTableDataType.DATA][filter] || [fieldName]),
        documentValue,
      ]
      if (matchesFound !== undefined) {
        if (matchesFound === 0 && acc[RateTableDataType.ERROR_CELLS][tableColIdx] === undefined) {
          acc[RateTableDataType.ERROR_CELLS][tableColIdx] = filter
        }
        const key = constructKeyFromRowColIdx(filter, tableColIdx)
        acc[RateTableDataType.COMMENTS][key] = `${matchesFound} result${
          matchesFound !== 1 ? 's' : ''
        } ${
          similarRateLocations?.length
            ? `\r\n\r\nSimilar locations: ${similarRateLocations
                .map((similarRateLocation) => similarRateLocation.rateLocation as string)
                .join('\r\n')}`
            : ''
        }`
      }
    }

    updateAcc(
      RateFilter.PORT_OF_LOADING,
      'Port of Loading',
      reconResult.documentPortOfLoading || '',
      reconResult.portOfLoadingMatchesFound || 0,
      reconResult.portOfLoadingSimilarRateLocations,
    )
    updateAcc(
      RateFilter.PORT_OF_DISCHARGE,
      'Port of Discharge',
      reconResult.documentPortOfDischarge || '',
      reconResult.portOfDischargeMatchesFound || 0,
      reconResult.portOfDischargeSimilarRateLocations,
    )
    updateAcc(
      RateFilter.PLACE_OF_RECEIPT,
      'Place of Receipt',
      reconResult.documentPlaceOfReceipt || '',
      reconResult.placeOfReceiptMatchesFound || 0,
      reconResult.placeOfReceiptSimilarRateLocations,
    )
    updateAcc(
      RateFilter.PLACE_OF_DELIVERY,
      'Place of Delivery',
      reconResult.documentPlaceOfDelivery || '',
      reconResult.placeOfDeliveryMatchesFound || 0,
      reconResult.placeOfDeliverySimilarRateLocations,
    )
    updateAcc(
      RateFilter.RATE_CODE,
      'Rate Code',
      reconResult.documentChargeCode || '',
      reconResult.chargeCodeMatchesFound || 0,
    )
    updateAcc(
      RateFilter.CONTAINER_SIZE,
      'Container Size',
      reconResult.documentContainerSize || '',
      reconResult.containerSizeMatchesFound || 0,
    )
    updateAcc(
      RateFilter.CONTAINER_TYPE,
      'Container Type',
      reconResult.documentContainerType || '',
      reconResult.containerTypeMatchesFound || 0,
    )
    updateAcc(
      RateFilter.COMMODITY,
      'Commodity',
      reconResult.documentCommodity || '',
      reconResult.commodityMatchesFound || 0,
    )
    updateAcc(
      RateFilter.NAMED_ACCOUNT,
      'Named Account',
      reconResult.documentNamedAccount || '',
      reconResult.namedAccountMatchesFound || 0,
    )
    updateAcc(
      RateFilter.CURRENCY,
      'Currency',
      reconResult.documentCurrency || '',
      reconResult.currencyMatchesFound || 0,
    )
    updateAcc(
      RateFilter.ROUTE,
      'Route',
      reconResult.documentShipmentRoute || '',
      reconResult.shipmentRouteMatchesFound || 0,
    )
    updateAcc(RateFilter.AMENDMENT_NUMBER, 'Amendment Number', reconResult.amendmentNo || '')
    updateAcc(RateFilter.RATE_COST, 'Rate Cost', reconResult.documentChargeCost || '')
    updateAcc(
      RateFilter.EXPECTED_RATE_COST,
      'Expected Rate Cost',
      reconResult.expectedChargeCost || CHARGE_RATE_NOT_FOUND_MESSAGE,
    )
    updateAcc(RateFilter.QUANTITY, 'Quantity', reconResult.documentQuantity || '')
    updateAcc(RateFilter.AMOUNT, 'Total Amount', reconResult.documentTotalAmount || '')
    updateAcc(
      RateFilter.EXPECTED_AMOUNT,
      'Expected Total Amount',
      reconResult.expectedTotalAmount || '',
    )
    return acc
  }, {} as RateTableData)

export const getMetadataTableData = (
  metadataReconResults: ArrivalNoticeMetadataReconResultNode[],
  consolTypeReconResult: ArrivalNoticeConsolTypeReconResultNode | null,
  nonRepeatableFieldKeyToNameMap: Record<string, string>,
): MetadataTableData => {
  const METADATA_FIELDS_TO_SHOW = [
    AnReconAutofillKey.Carrier,
    AnReconAutofillKey.Creditor,
    AnReconAutofillKey.ContainerType,
    AnReconAutofillKey.Vessel,
    AnReconAutofillKey.VoyageNumber,
    AnReconAutofillKey.PortOfLoading,
    AnReconAutofillKey.PortOfDischarge,
    AnReconAutofillKey.MblNumber,
  ]

  const initMetadataTableData = metadataReconResults.reduce(
    (acc, reconResult) => {
      acc[reconResult.metadataKey!.toUpperCase() as AnReconAutofillKey] = [
        nonRepeatableFieldKeyToNameMap[reconResult.metadataKey!] || '',
        reconResult.documentValue || '',
        reconResult.expectedValue || '',
        reconResult.success,
      ]
      return acc
    },
    {} as Record<AnReconAutofillKey, (string | boolean)[]>,
  )
  const metadataTableData = [
    ...Object.values(initMetadataTableData),
    ...Object.keys(nonRepeatableFieldKeyToNameMap)
      .filter((key) => {
        const anReconKey = key.toUpperCase() as AnReconAutofillKey
        return !initMetadataTableData[anReconKey] && METADATA_FIELDS_TO_SHOW.includes(anReconKey)
      })
      .map((key) => [nonRepeatableFieldKeyToNameMap[key], '', '', true]),
    [
      'Consol Type',
      consolTypeReconResult?.documentConsolType ?? '',
      consolTypeReconResult?.expectedConsolType ?? '',
      consolTypeReconResult?.success ?? true,
    ],
    [
      'Container Mode',
      consolTypeReconResult?.documentContainerType ?? '',
      consolTypeReconResult?.expectedContainerType ?? '',
      consolTypeReconResult?.success ?? true,
    ],
  ]

  return metadataTableData.reduce((acc, row, idx) => {
    if (idx === 0) {
      acc[MetadataTableDataType.DATA] = []
      acc[MetadataTableDataType.ERROR_ROWS] = []
    }

    acc[MetadataTableDataType.DATA].push(row.slice(0, 3) as string[])
    const isResultSuccess = row[3]
    if (!isResultSuccess) {
      acc[MetadataTableDataType.ERROR_ROWS].push(idx)
    }
    return acc
  }, {} as MetadataTableData)
}

export const getArrivalNoticeReconResultTypography = (
  reconResult: ArrivalNoticeReconResultNode,
): ReactElement => {
  const { success, type } = reconResult
  const refNo = reconResult?.chainIoConsolidation?.forwarderReference ?? ''
  const documentConsolNo = reconResult?.documentConsolNo ?? ''
  const documentMbl = reconResult?.documentMbl ?? ''
  const documentContainerNos = reconResult?.documentContainerNumbers ?? ''
  const typeOrFallback = isFallback(type) ? type.fallbackValue : type?.value
  if (typeOrFallback === ReconResultType.FindConsolReconResult) {
    if (success) {
      return (
        <Typography component='span' variant='body1'>
          Consolidation with Ref no. <strong>{refNo}</strong> was found using MBL no:{' '}
          <strong>{documentMbl}</strong> and Container numbers:{' '}
          <strong>{documentContainerNos}</strong>
        </Typography>
      )
    }
    const consolNumber = documentConsolNo || documentMbl || documentContainerNos
    return (
      <Typography variant='body1'>
        Consol <strong>{consolNumber}</strong> not found
      </Typography>
    )
  } else if (typeOrFallback === ReconResultType.ArrivalNoticeContractReconResult) {
    if (success) {
      const contractClientRefNo = reconResult.clientContractRefNo ?? ''
      const vendor = reconResult.documentVendorCode ?? ''
      const acceptedDate = reconResult.documentAcceptedDate
        ? parseDateString(reconResult.documentAcceptedDate).toLocaleDateString()
        : ''
      const effectivity = reconResult.effectivity
        ? parseDateString(reconResult.effectivity).toLocaleDateString()
        : ''
      const expiryDate = reconResult.expiryDate
        ? parseDateString(reconResult.expiryDate).toLocaleDateString()
        : ''
      return (
        <Typography component='span' variant='body1'>
          Contract <strong>{contractClientRefNo}</strong> for <strong>{vendor}</strong> and accepted
          date <strong>{acceptedDate}</strong> was found. Contract is effective from{' '}
          <strong>{effectivity}</strong> to <strong>{expiryDate}</strong>
        </Typography>
      )
    }
    return <Typography variant='body1'>Could not match any contract on the rate sheet</Typography>
  }
  return <></>
}

export const formatFindConsolWithMatchCriteria = (
  chainIoConsol?: Maybe<ChainIoConsolidationNode>,
  consolNo?: Maybe<string>,
  mblNo?: Maybe<string>,
  hblNo?: Maybe<string>,
  shipmentNo?: Maybe<string>,
  carrierBookingNo?: Maybe<string>,
  orderNo?: Maybe<string>,
  containerNo?: Maybe<string>,
): ReactElement => {
  if (
    !consolNo &&
    !mblNo &&
    !containerNo &&
    !hblNo &&
    !shipmentNo &&
    !carrierBookingNo &&
    !orderNo
  ) {
    return (
      <Typography component='span' variant='body1'>
        No unique consolidation was found using the following keys: Consol Number., MBL Number., HBL
        Number, Shipment Number, Carrier Booking Number, Order Number, or Container Number.
      </Typography>
    )
  }

  const refNo = chainIoConsol?.forwarderReference ?? ''
  let matchedKey = 'N/A'
  let matchedVal = ''
  if (consolNo) {
    matchedKey = 'Reference Number.'
    matchedVal = consolNo
  } else if (mblNo) {
    matchedKey = 'MBL Number.'
    matchedVal = mblNo
  } else if (containerNo) {
    matchedKey = 'Container Number.'
    matchedVal = containerNo
  } else if (hblNo) {
    matchedKey = 'HBL Number.'
    matchedVal = hblNo
  } else if (shipmentNo) {
    matchedKey = 'Shipment Number.'
    matchedVal = shipmentNo
  } else if (carrierBookingNo) {
    matchedKey = 'Carrier Booking Number.'
    matchedVal = carrierBookingNo
  } else if (orderNo) {
    matchedKey = 'Order Number.'
    matchedVal = orderNo
  }

  return (
    <Typography component='span' variant='body1'>
      Consolidation <strong>{refNo}</strong> was found using key: {matchedKey}{' '}
      <strong>{matchedVal}</strong>
    </Typography>
  )
}
