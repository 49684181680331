import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import { FieldGroupNode, Mutation, MutationAddDocumentTableArgs } from '@src/graphql/types'
import { useSelector } from 'react-redux'
import { RootState } from '@src/utils/store'
import { useMutation } from '@apollo/client'
import { ADD_DOCUMENT_TABLE } from '@src/graphql/mutations/document'
import { useSnackbar } from 'notistack'
import { selectActiveDocument } from '@src/redux-features/document_editor/document'

const useStyles = makeStyles({
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

type Props = {
  isOpen: boolean
  handleClose: () => void
  jobRepeatableFieldGroups: FieldGroupNode[]
  refetchJob: () => void
}

const SelectTableTypeDialog: FunctionComponent<Props> = ({
  isOpen,
  handleClose,
  jobRepeatableFieldGroups,
  refetchJob,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const documentId = useSelector(
    (state: RootState) => selectActiveDocument(state.documentEditor)?.id,
  )
  const [addDocumentTable, { error: addDocumentTableError }] = useMutation<
    Pick<Mutation, 'addDocumentTable'>,
    MutationAddDocumentTableArgs
  >(ADD_DOCUMENT_TABLE, {
    onCompleted: () => {
      enqueueSnackbar('New document table created', { variant: 'success' })
      refetchJob()
    },
  })

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <div className={classes.close}>
        <CloseIcon fontSize='large' onClick={handleClose} data-testid='close-btn' />
      </div>
      <DialogTitle>
        <Box display='flex' alignItems='center'>
          Select Table Type
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column'>
          {jobRepeatableFieldGroups.map((jobRepeatableFieldGroup) => {
            return (
              <Box p={1} key={jobRepeatableFieldGroup.id}>
                <Button
                  fullWidth
                  variant='contained'
                  disabled={!documentId}
                  onClick={async () => {
                    try {
                      await addDocumentTable({
                        variables: {
                          documentId: documentId!,
                          fieldGroupId: jobRepeatableFieldGroup.id,
                        },
                      })
                      if (addDocumentTableError) {
                        enqueueSnackbar(
                          `Error while adding a new table: ${formatMaybeApolloError(
                            addDocumentTableError,
                          )}`,
                          {
                            variant: 'error',
                          },
                        )
                      }
                    } catch (error) {
                      enqueueSnackbar(`Error adding table: ${formatMaybeApolloError(error)}`, {
                        variant: 'error',
                      })
                    }
                    handleClose()
                  }}
                >
                  {jobRepeatableFieldGroup.name}
                </Button>
              </Box>
            )
          })}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SelectTableTypeDialog
