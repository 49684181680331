import { RefObject, useCallback, useEffect, useState, useRef } from 'react'
import type { HotTable } from '@handsontable/react'
import {
  InputFieldGroup,
  JobTemplateExportNode,
  JobTemplateExportType,
  JobTemplateExportSheetNodeConnection,
  Maybe,
} from '@src/graphql/types'
import { JobTemplateExportSheet } from '@src/types/job_template_export'

import {
  createNewSheetFromTableData,
  generateDefaultSheets,
  generateTableDataFromExportSheet,
  getSheetsFromExportFormat,
} from '@src/utils/admin/exportFormat'

type ExportFormatHookType = {
  hotTableRef: RefObject<HotTable>
  sheetIndex: number
  setSheetIndex: (idx: number) => void
  exportType: JobTemplateExportType
  sheets: JobTemplateExportSheet[]
  updateSheets: (sheets: JobTemplateExportSheet[]) => void
  toggleColumnHeader: () => void
  setDefaultSheetsFormat: (type: JobTemplateExportType) => void
  cacheSheetDataAndSwitch: (idx: number) => void
  getSyncedSheetsFromTableData: () => JobTemplateExportSheet[]
  selectNewExportType: (type: JobTemplateExportType) => void
}

const useExportFormat = (
  jobTemplateExport: Maybe<JobTemplateExportNode>,
  jobTemplateLoading: boolean,
  metadataFieldGroups: InputFieldGroup[],
  lineItemFieldGroups: InputFieldGroup[],
): ExportFormatHookType => {
  const [sheetIndex, setSheetIndex] = useState(0)
  const [exportType, setExportType] = useState(JobTemplateExportType.Standard)
  const [sheets, setSheets] = useState([] as JobTemplateExportSheet[])
  const hotTableRef = useRef<HotTable>(null)
  const currentSheet = sheets?.[sheetIndex] ?? null

  const toggleColumnHeader = useCallback((): void => {
    const sheetsCopy = [...sheets]
    const displayColumnHeader = sheetsCopy?.[sheetIndex]?.displayColumnHeader
    const newSheet = { ...sheetsCopy[sheetIndex], displayColumnHeader: !displayColumnHeader }
    sheetsCopy.splice(sheetIndex, 1, newSheet)
    setSheets(sheetsCopy)
  }, [sheets, setSheets, sheetIndex])

  const getSyncedSheetsFromTableData = useCallback(() => {
    const sheetsCopy = [...sheets]
    const fields = [...metadataFieldGroups, ...lineItemFieldGroups].flatMap(
      (fieldGroup) => fieldGroup.fields,
    )
    if (hotTableRef.current && currentSheet && fields) {
      const tableData = hotTableRef.current.hotInstance.getData()
      const newSheet = createNewSheetFromTableData(currentSheet, tableData, fields)
      sheetsCopy.splice(sheetIndex, 1, newSheet)
    }
    return sheetsCopy
  }, [currentSheet, sheets, sheetIndex, metadataFieldGroups, lineItemFieldGroups])

  const cacheSheetDataAndSwitch = useCallback(
    (sheetIdx: number): void => {
      const newSheets = getSyncedSheetsFromTableData()
      setSheets(newSheets)
      setSheetIndex(sheetIdx)
    },
    [getSyncedSheetsFromTableData, setSheetIndex],
  )

  const setSheetFromExportFormat = (
    jobTemplateExportSheets: JobTemplateExportSheetNodeConnection,
  ): void => {
    const sheetsData = getSheetsFromExportFormat(jobTemplateExportSheets)
    setSheets(sheetsData)
  }

  const setDefaultSheetsFormat = useCallback(
    (type: JobTemplateExportType): void => {
      const sheetsData = generateDefaultSheets(type, metadataFieldGroups, lineItemFieldGroups)
      setSheets(sheetsData)
    },
    [metadataFieldGroups, lineItemFieldGroups, setSheets],
  )

  const selectNewExportType = useCallback(
    (type: JobTemplateExportType): void => {
      setExportType(type)
      if (jobTemplateExport) {
        const { jobTemplateExportType } = jobTemplateExport
        if (jobTemplateExportType && type !== jobTemplateExportType) {
          setDefaultSheetsFormat(type)
        } else {
          setSheetFromExportFormat(jobTemplateExport.jobTemplateExportSheets!)
        }
      }
    },
    [jobTemplateExport, setExportType, setDefaultSheetsFormat],
  )

  const updateSheets = useCallback(
    (newSheets: JobTemplateExportSheet[]): void => {
      const reorderedSheets = newSheets.map((sheet, sheetIdx) => {
        const columnsCopy = sheet.columns.map((col, colIdx) => ({ ...col, order: colIdx }))
        const newSheet = { ...sheet, columns: columnsCopy, order: sheetIdx }
        newSheet.tableData = generateTableDataFromExportSheet(newSheet)
        return newSheet
      })
      setSheets(reorderedSheets)
    },
    [setSheets],
  )

  useEffect(() => {
    if (!jobTemplateLoading) {
      if (jobTemplateExport) {
        setExportType(jobTemplateExport.jobTemplateExportType)
        setSheetFromExportFormat(jobTemplateExport.jobTemplateExportSheets!)
      } else {
        setDefaultSheetsFormat(JobTemplateExportType.Standard)
      }
    }
  }, [jobTemplateExport, jobTemplateLoading, setDefaultSheetsFormat])

  useEffect(() => {
    if (hotTableRef.current && currentSheet?.tableData) {
      const { nameRow, valueRow } = currentSheet.tableData
      const data = currentSheet.displayColumnHeader ? [nameRow, valueRow] : [valueRow]
      hotTableRef.current.hotInstance.loadData(data)
    }
  }, [currentSheet, sheets, sheetIndex])

  return {
    hotTableRef,
    sheetIndex,
    setSheetIndex,
    exportType,
    sheets,
    updateSheets,
    toggleColumnHeader,
    setDefaultSheetsFormat,
    cacheSheetDataAndSwitch,
    getSyncedSheetsFromTableData,
    selectNewExportType,
  }
}

export default useExportFormat
