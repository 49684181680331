import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useContext, useRef, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { JOB_CONFIRMATION_PAGE } from '@src/graphql/queries/job'
import { CONFIRM_JOBS } from '@src/graphql/mutations/job'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import theme from '@src/utils/theme'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FileUpload from '@src/components/upload-files/FileUpload'
import CancelIcon from '@material-ui/icons/Cancel'
import Chip from '@material-ui/core/Chip'
import { uploadFileData } from '@src/utils/file'
import { ConfirmJobsMutationVariables, Mutation, Query } from '@src/graphql/types'
import ExportButton from '@src/components/export-button/ExportButton'
import { ImageFileData } from '@src/utils/types'
import TaskDateConfirmedDialog from '@src/components/task-date-confirmed-dialog/TaskDateConfirmedDialog'
import { useHistory } from 'react-router-dom'
import { Auth0AccessTokenContext } from '@src/auth/Auth0AccessTokenContext'
import { useEventLogger } from '@src/utils/observability/useEventLogger'
import { LogEventType } from '@src/utils/observability/LogEventType'

type Props = {
  jobId: string
}

const useStyles = makeStyles({
  wrapper: {
    padding: theme.spacing(2),
  },
  doneButton: {
    width: '100%',
    marginTop: '20%',
  },
  fileName: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyContent: 'center',
    gridColumnGap: theme.spacing(4),
  },
  fileColumn: {
    width: '100%',
    overflowY: 'scroll',
    padding: theme.spacing(2),
  },
  fileNameContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    width: '100%',
  },
  badge: {
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    maxWidth: 100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  close: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  buttonContainer: {
    display: 'flex',
  },
  exportButtonContainer: {
    flexGrow: 1,
  },
})

const ConfirmationSidebar: FunctionComponent<Props> = ({ jobId }) => {
  const history = useHistory()
  const classes = useStyles()
  const [isProofDialogOpen, setIsProofDialogOpen] = useState(false)
  const [isDateConfirmedDialogOpen, setIsDateConfirmedDialogOpen] = useState(false)
  const [imageFileData, setImageFileData] = useState([] as ImageFileData[])
  const [isUploading, setIsUploading] = useState(false)
  const accessToken = useContext(Auth0AccessTokenContext)!
  const { logEvent } = useEventLogger()
  const actionStart = useRef<Date>()
  const { enqueueSnackbar } = useSnackbar()
  const { data: jobData, loading: jobLoading } = useQuery<Pick<Query, 'job'>>(
    JOB_CONFIRMATION_PAGE,
    {
      variables: {
        id: jobId,
      },
    },
  )
  const [confirmJobs] = useMutation<Pick<Mutation, 'confirmJobs'>, ConfirmJobsMutationVariables>(
    CONFIRM_JOBS,
    {
      update: (cache) => {
        cache.modify({
          fields: {
            // Invalidate countByJobStatus field and refreshes cache
            countByJobStatus() {},
          },
        })
      },
    },
  )

  const doneJob = async (validateDateConfirmed = true): Promise<void> => {
    actionStart.current = new Date()
    if (validateDateConfirmed && jobData!.job!.task!.dateConfirmed === null) {
      setIsDateConfirmedDialogOpen(true)
      return
    }
    if (imageFileData.length > 1) {
      enqueueSnackbar('Please upload only a single image for proof that this export is complete', {
        variant: 'error',
      })
      return
    }
    setIsUploading(true)
    let errorMessage = ''
    let success = false
    try {
      let proofUrl = ''
      let filename = ''
      if (imageFileData.length > 0) {
        filename = imageFileData[0].filename
        const { progress$, viewUrls } = await uploadFileData(imageFileData, accessToken)
        await progress$.toPromise()
        // eslint-disable-next-line prefer-destructuring
        proofUrl = viewUrls[0]
      }
      await confirmJobs({
        variables: {
          jobIds: [jobId],
          proofUrl,
          filename,
        },
      })
      enqueueSnackbar(`Job Confirmed`, { variant: 'success' })
      success = true
      history.push(`/`)
    } catch (error) {
      errorMessage = `Unable to confirm job. Please make sure you have uploaded a valid JPG or PNG file for proof: ${formatMaybeApolloError(
        error,
      )}`
      enqueueSnackbar(errorMessage, { variant: 'error' })
    } finally {
      setIsUploading(false)

      void logEvent(LogEventType.FILE_UPLOAD, {
        job_id: jobId,
        action_start: actionStart.current,
        action_end: new Date(),
        file_types: imageFileData.map(({ type }) => type),
        success: success,
        error_message: errorMessage,
      })
    }
  }

  const cancelFileUpload = (): void => {
    setImageFileData([])
    setIsProofDialogOpen(false)
  }

  return (
    <div className={classes.wrapper}>
      <p>We&rsquo;re nearly there!</p>
      <p>Please don&rsquo;t forget to:</p>
      <ol>
        <li>Email the client confirming that we&rsquo;ve done the job.</li>
        <li>CC the Ops team into your email!</li>
        <li>
          (Optional) Upload any screenshots (PNG or JPG) showing us confirming the job with the
          customer.
        </li>
      </ol>
      <div>
        <div className={classes.buttonContainer}>
          <div className={classes.exportButtonContainer}>
            <ExportButton jobId={jobId} />
          </div>
          <Button
            variant='contained'
            disabled={isUploading}
            onClick={() => setIsProofDialogOpen(true)}
          >
            Upload
          </Button>
        </div>
        {imageFileData.length > 0 && (
          <div className={classes.fileColumn}>
            <div className={classes.fileNameContainer}>
              <div className={classes.fileName}>
                <Chip label={imageFileData[0].filename} className={classes.badge} />
                <CancelIcon
                  fontSize='large'
                  className={classes.close}
                  onClick={() => setImageFileData([])}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Button
        className={classes.doneButton}
        disabled={isUploading || jobLoading}
        variant='contained'
        color='primary'
        onClick={() => doneJob()}
      >
        {jobLoading ? 'Loading...' : 'Done'}
      </Button>
      <Dialog open={isProofDialogOpen} fullScreen>
        <DialogTitle>Upload a JPG or PNG for proof of confirmation</DialogTitle>
        <DialogContent>
          <FileUpload imageFileData={imageFileData} setImageFileData={setImageFileData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelFileUpload}>Cancel</Button>
          <Button onClick={() => setIsProofDialogOpen(false)} disabled={jobLoading}>
            {jobLoading ? 'Loading...' : 'Done'}
          </Button>
        </DialogActions>
      </Dialog>
      {jobData && (
        <TaskDateConfirmedDialog
          open={isDateConfirmedDialogOpen}
          onClose={() => setIsDateConfirmedDialogOpen(false)}
          task={jobData.job!.task!}
          confirm={() => doneJob(false)}
        />
      )}
    </div>
  )
}

export default ConfirmationSidebar
