import { formatMaybeApolloError } from '@src/utils/errors'
import { useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import Title from '@src/components/Title'
import { USER_PROFILE } from '@src/graphql/queries/profile'
import { EDIT_OWN_PROFILE } from '@src/graphql/mutations/profile'
import { Mutation, Query } from '@src/graphql/types'
import theme from '@src/utils/theme'

const useStyles = makeStyles({
  userProfileForm: {
    padding: theme.spacing(3),
  },
  buttonPadding: {
    padding: theme.spacing(2),
  },
})

const fullNameMaxChars = 128

const ProfilePage: FunctionComponent = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [fullname, setFullname] = useState(null as string | null)
  const [metabaseDashboardId, setMetabaseDashboardId] = useState('')
  const [customerDemoModalOpen, setCustomerDemoModalOpen] = useState(false)

  const { data: userProfileData, loading: userProfileDataLoading } =
    useQuery<Pick<Query, 'userProfile'>>(USER_PROFILE)
  const [editOwnProfile, { error: editUserProfileError }] = useMutation<
    Pick<Mutation, 'editOwnProfile'>
  >(EDIT_OWN_PROFILE, {
    refetchQueries: [{ query: USER_PROFILE }],
  })

  useEffect(() => {
    if (userProfileData) {
      setFullname(userProfileData.userProfile.name!)
      setMetabaseDashboardId(userProfileData.userProfile.metabaseDashboardId!)
    }
  }, [userProfileData])

  const saveChanges = async (): Promise<void> => {
    try {
      await editOwnProfile({ variables: { name: fullname, metabaseDashboardId } })
      if (editUserProfileError) {
        throw editUserProfileError
      }
      enqueueSnackbar('Saving successful', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Error saving changes: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
  }

  const isFormValuesChanged =
    fullname !== userProfileData?.userProfile?.name ||
    metabaseDashboardId !== userProfileData?.userProfile?.metabaseDashboardId
  const isGoogle = userProfileData?.userProfile.auth0Id?.startsWith('google')

  return userProfileDataLoading ? (
    <CenteredCircularProgress />
  ) : (
    <>
      <Title title='Profile' />
      <Box display='flex' flexDirection='column' alignItems='center' flexGrow={1} my={4}>
        <Box width='30vw' p={2}>
          <Paper className={classes.userProfileForm}>
            <Typography variant='h2'>User Profile</Typography>

            <Box my={4}>
              {isGoogle && (
                <p>
                  Because you have signed in with Google, your name is automatically synced with the
                  name of your Google account.
                </p>
              )}
              <TextField
                fullWidth
                label='Full Name'
                disabled={isGoogle}
                value={fullname ?? ''}
                onChange={(e) => setFullname(e.target.value)}
                inputProps={{ maxLength: fullNameMaxChars }}
                variant='outlined'
              />
            </Box>

            <Box my={4}>
              <TextField
                fullWidth
                label='User Metabase Dashboard ID'
                value={metabaseDashboardId ?? ''}
                onChange={(e) => setMetabaseDashboardId(e.target.value)}
                inputProps={{ maxLength: fullNameMaxChars }}
                variant='outlined'
              />
            </Box>
            <Box paddingBottom={3}>
              <Button
                color='primary'
                variant='contained'
                onClick={saveChanges}
                disabled={!fullname || !isFormValuesChanged}
              >
                Save
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  )
}

export default ProfilePage
