import { FunctionComponent, useState } from 'react'
import { clsx } from 'clsx'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import { Box, Theme } from '@material-ui/core'
import { COLORS, RECON_MODAL_STYLES } from '@src/utils/app_constants'
import {
  CwTargetModule,
  InvoiceLineItemReconResultNode,
  JobTemplateReconType,
  Query,
  ReconAttemptNode,
  ReconResultType,
} from '@src/graphql/types'
import {
  ApInvoiceReconResultNode,
  formatCwTargetModule,
  EXPECTED_DATA_TABLE_COLUMNS,
  getAPAssociatedKeysTable,
  getAPInvoiceDataTables,
  getAPMetadataTable,
  getCargowiseModuleFromReconAttempt,
  getExpectedRowsBasedOnMatchingCriteria,
  INVOICE_DATA_TABLE_COLUMNS,
  isErrorCell,
  RECON_TABLE_COLUMNS,
} from '@src/utils/recon/ap_recon'
import { APInvoiceReconMetadataTable } from '@src/components/reconciliation-dialog/APInvoiceReconMetadataTable'
import { getUniqueFindShipmentReconResults } from '@src/utils/recon/recon'
import { CargowiseOpsType } from '@src/utils/cargowise/types'
import theme from '@src/utils/theme'
import { getReconResultsDataModifiedDate, millisecondsInMinute } from '@src/utils/date'
import { isFallback } from '@src/utils/enum'
import { useQuery } from '@apollo/client'
import {
  GET_UNIQUE_CHARGE_CODES_BY_COMPANY_PARTNER,
  GET_UNIQUE_CHARGE_VENDORS_BY_COMPANY,
} from '@src/graphql/queries/recon'
import { FindShipmentResultDisplay } from '@src/utils/recon/FindShipmentResultDisplay'

const useStyles = makeStyles<Theme>({
  dialog: {
    // don't block handsontable comments
    // important because z-index 1300 is inline
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zIndex: `${RECON_MODAL_STYLES.Z_INDEX} !important` as any,
  },
  dialogFullScreen: {
    maxWidth: '90%',
    width: '90%',
    height: '90%',
  },
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.75, 1),
  },
  wrapWord: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  error: {
    color: theme.palette.error.main,
    background: COLORS.PALE_RED,
    boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
  },
  errorOutline: {
    boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
  },
  hovered: {
    background: COLORS.PALE_YELLOW,
    boxShadow: '0 0 0 1px yellow inset',
  },
  shipmentDate: {
    color: theme.palette.text.primary,
  },
  shipmentDateError: {
    color: theme.palette.error.main,
  },
  nonMatching: {
    color: theme.palette.text.disabled,
  },
})

type Props = {
  isOpen: boolean
  closePopup: () => void
  reconAttempt: ReconAttemptNode
  overrideChargeDescription: boolean
  icon?: 'chevron' | 'exit'
}

const InvoiceLineItemReconDialog: FunctionComponent<Props> = ({
  isOpen,
  closePopup,
  reconAttempt,
  overrideChargeDescription,
  icon = 'chevron',
}) => {
  const classes = useStyles()
  const [hoveredRowIdx, setHoveredRowIdx] = useState(null as null | number)
  const apiPartnerId = reconAttempt?.job?.jobTemplate?.apiPartnerId ?? null
  const companyId = reconAttempt?.job?.jobTemplate?.companyId ?? null
  const { docCharges, expectedCharges } = reconAttempt
  const matchingCriteria = reconAttempt.matchingCriteria
    ? isFallback(reconAttempt.matchingCriteria)
      ? reconAttempt.matchingCriteria
      : reconAttempt.matchingCriteria.value
    : null
  const reconResults = reconAttempt.reconResults as ApInvoiceReconResultNode[]
  const invoiceLineItemReconResults = reconResults.filter(
    (reconResult) =>
      !isFallback(reconResult.type) &&
      reconResult.type?.value === ReconResultType.InvoiceLineItemReconResult,
  ) as InvoiceLineItemReconResultNode[]

  const uniqueFindShipmentReconResults = getUniqueFindShipmentReconResults(reconAttempt)
  const invoiceDataTableColumns = overrideChargeDescription
    ? INVOICE_DATA_TABLE_COLUMNS
    : INVOICE_DATA_TABLE_COLUMNS.filter((column) => column !== RECON_TABLE_COLUMNS.DESCRIPTION)
  const expectedDataTableColumns = overrideChargeDescription
    ? EXPECTED_DATA_TABLE_COLUMNS
    : EXPECTED_DATA_TABLE_COLUMNS.filter((column) => column !== RECON_TABLE_COLUMNS.DESCRIPTION)

  const shipmentDateModified = getReconResultsDataModifiedDate(
    uniqueFindShipmentReconResults,
    JobTemplateReconType.Soa,
  )
  // We consider shipments that are more than 30 minutes old as outdated
  // [PD-288](https://expedock.atlassian.net/browse/PD-288)
  const isShipmentDateOutdated =
    shipmentDateModified &&
    new Date().getTime() - shipmentDateModified.getTime() > millisecondsInMinute * 30

  const apAssociatedKeysTable = getAPAssociatedKeysTable(
    uniqueFindShipmentReconResults,
    reconAttempt,
  )
  const apMetadataTable = getAPMetadataTable(reconResults)

  const docChargesCodes = docCharges
    .filter(
      (chargeDetail) =>
        typeof chargeDetail.chargeCode === 'string' &&
        chargeDetail.chargeCode.trim() !== '' &&
        typeof chargeDetail.vendor === 'string' &&
        chargeDetail.vendor.trim() !== '',
    )
    .map((chargeDetail) => chargeDetail.chargeCode)

  const expectedChargesCodes = expectedCharges
    .filter(
      (chargeDetail) =>
        typeof chargeDetail.chargeCode === 'string' &&
        chargeDetail.chargeCode.trim() !== '' &&
        typeof chargeDetail.vendor === 'string' &&
        chargeDetail.vendor.trim() !== '',
    )
    .map((chargeDetail) => chargeDetail.chargeCode)

  const docChargesVendors = docCharges
    .filter(
      (chargeDetail) =>
        typeof chargeDetail.chargeCode === 'string' &&
        chargeDetail.chargeCode.trim() !== '' &&
        typeof chargeDetail.vendor === 'string' &&
        chargeDetail.vendor.trim() !== '',
    )
    .map((chargeDetail) => chargeDetail.vendor)

  const expectedChargesVendors = expectedCharges
    .filter(
      (chargeDetail) =>
        typeof chargeDetail.chargeCode === 'string' &&
        chargeDetail.chargeCode.trim() !== '' &&
        typeof chargeDetail.vendor === 'string' &&
        chargeDetail.vendor.trim() !== '',
    )
    .map((chargeDetail) => chargeDetail.vendor)

  const { data: uniqueChargeCodesByCompanyPartnerData } = useQuery<
    Pick<Query, 'uniqueChargeCodesByCompanyPartner'>
  >(GET_UNIQUE_CHARGE_CODES_BY_COMPANY_PARTNER, {
    variables: {
      companyId,
      apiPartnerId,
      codes: [...docChargesCodes, ...expectedChargesCodes],
      vendorCodes: [...docChargesVendors, ...expectedChargesVendors],
    },
    skip: !apiPartnerId,
  })
  const chargeCodeToDescMap = apiPartnerId
    ? Object.fromEntries(
        uniqueChargeCodesByCompanyPartnerData?.uniqueChargeCodesByCompanyPartner?.map(
          ({ code, description }) => [code, description],
        ) ?? [],
      )
    : null

  const { data: uniqueChargeVendorsByCompanyData } = useQuery<
    Pick<Query, 'uniqueChargeVendorsByCompany'>
  >(GET_UNIQUE_CHARGE_VENDORS_BY_COMPANY, {
    variables: { companyId, codes: [...docChargesVendors, ...expectedChargesVendors] },
    skip: !apiPartnerId,
  })

  const chargeVendorCodeToNameMap = apiPartnerId
    ? Object.fromEntries(
        uniqueChargeVendorsByCompanyData?.uniqueChargeVendorsByCompany?.map(({ code, name }) => [
          code,
          name,
        ]) ?? [],
      )
    : null

  const apInvoiceDataTables = getAPInvoiceDataTables(
    invoiceLineItemReconResults,
    docCharges,
    expectedCharges,
    matchingCriteria,
    overrideChargeDescription,
    chargeCodeToDescMap,
    chargeVendorCodeToNameMap,
  )
  const matchingRowsOnExpected = getExpectedRowsBasedOnMatchingCriteria(
    invoiceLineItemReconResults,
    expectedCharges,
    matchingCriteria,
  )
  const {
    data: documentData,
    errorCells: documentErrorCells,
    errorOutlineCells: documentErrorOutlineCells,
  } = apInvoiceDataTables.invoiceTableData
  const {
    data: expectedData,
    expectedErrorCells,
    errorOutlineCells: expectedErrorOutlineCells,
  } = apInvoiceDataTables.expectedTableData
  const nonMatchingRowsOnExpected = expectedData
    .map((_, idx) => idx)
    .filter((idx) => !matchingRowsOnExpected.flat().includes(idx))

  const chainIoModelName = formatCwTargetModule(getCargowiseModuleFromReconAttempt(reconAttempt))

  return (
    <Dialog
      className={classes.dialog}
      classes={{ paper: classes.dialogFullScreen }}
      open={isOpen}
      onClose={() => {
        if (icon === 'exit') closePopup()
      }}
    >
      <DialogTitle
        disableTypography
        style={{
          display: 'flex',
          justifyContent: icon === 'chevron' ? 'start' : 'end',
        }}
      >
        <IconButton onClick={closePopup} aria-label='exit' data-testid='close-button'>
          {icon === 'chevron' ? <ChevronLeftIcon /> : <CloseIcon />}
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Box data-testid='find-shipment-recon-results'>
            <FindShipmentResultDisplay
              findShipmentResults={uniqueFindShipmentReconResults}
              cargowiseOpsType={CargowiseOpsType.SOAReconcile}
              // TODO: unsafe fallback
              cargowiseModule={
                getCargowiseModuleFromReconAttempt(reconAttempt) ||
                CwTargetModule.ForwardingShipment
              }
            />
          </Box>
          <Box mb={2}>
            <Typography component={'span'} variant='body1'>
              {`${chainIoModelName} data last updated as of: `}
              <span
                className={clsx(classes.shipmentDate, {
                  [classes.shipmentDateError]: isShipmentDateOutdated,
                })}
              >
                {shipmentDateModified?.toString()}
              </span>
            </Typography>
          </Box>
          <Box mb={2}>
            <APInvoiceReconMetadataTable
              title='Associated Keys'
              metadataTable={apAssociatedKeysTable}
            />
          </Box>
          <Box mb={2}>
            <APInvoiceReconMetadataTable
              title='Metadata Reconciliation'
              metadataTable={apMetadataTable}
            />
          </Box>
          <Typography variant='subtitle1' gutterBottom>
            Line Item Data Reconciliation:
          </Typography>
          <Box display='flex' alignItems='baseline' mb={2}>
            <Box display='flex' flexDirection='column' mr={2} width='100%'>
              <Typography variant='subtitle2' gutterBottom>
                Invoice Data
              </Typography>
              <TableContainer>
                <Table size='small'>
                  <TableBody onMouseLeave={() => setHoveredRowIdx(null)}>
                    <TableRow>
                      {invoiceDataTableColumns.map((col, colIdx) => (
                        <TableCell
                          className={clsx(classes.cell, classes.colCell)}
                          align='center'
                          key={colIdx}
                        >
                          {col}
                        </TableCell>
                      ))}
                    </TableRow>
                    {documentData.map((row, rowIdx) => {
                      const isHovered = rowIdx === hoveredRowIdx
                      return (
                        <TableRow
                          key={rowIdx}
                          onMouseEnter={() => setHoveredRowIdx(rowIdx)}
                          data-testid={`invoice-line-item`}
                        >
                          {row.map((data, colIdx) => {
                            return (
                              <TableCell
                                data-testid={isHovered ? 'invoice-hovered' : ''}
                                className={clsx(classes.cell, classes.wrapWord, {
                                  [classes.errorOutline]:
                                    documentErrorOutlineCells &&
                                    documentErrorOutlineCells[rowIdx]?.includes(colIdx),
                                  [classes.error]:
                                    isHovered && documentErrorCells![rowIdx]?.includes(colIdx),
                                  [classes.hovered]:
                                    isHovered && !documentErrorCells![rowIdx]?.includes(colIdx),
                                })}
                                component='th'
                                scope='row'
                                key={colIdx}
                              >
                                {data}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box display='flex' flexDirection='column' width='100%'>
              <Typography variant='subtitle2' gutterBottom>
                Expected Data
              </Typography>
              <TableContainer>
                <Table size='small'>
                  <TableBody>
                    <TableRow>
                      {expectedDataTableColumns.map((col, colIdx) => (
                        <TableCell
                          className={clsx(classes.cell, classes.colCell)}
                          align='center'
                          key={colIdx}
                        >
                          {col}
                        </TableCell>
                      ))}
                    </TableRow>
                    {expectedData.map((row, rowIdx) => {
                      const isHovered =
                        hoveredRowIdx !== null &&
                        matchingRowsOnExpected[hoveredRowIdx]?.includes(rowIdx)
                      return (
                        <TableRow key={rowIdx} data-testid='expected-line-item'>
                          {row.map((data, colIdx) => {
                            return (
                              <TableCell
                                data-testid={isHovered ? `expected-hovered-${rowIdx}` : ''}
                                className={clsx(classes.cell, classes.wrapWord, {
                                  [classes.errorOutline]:
                                    expectedErrorOutlineCells &&
                                    expectedErrorOutlineCells[rowIdx]?.includes(colIdx),
                                  [classes.error]:
                                    isHovered &&
                                    isErrorCell(expectedErrorCells!, hoveredRowIdx, rowIdx, colIdx),
                                  [classes.hovered]:
                                    isHovered &&
                                    !isErrorCell(
                                      expectedErrorCells!,
                                      hoveredRowIdx,
                                      rowIdx,
                                      colIdx,
                                    ),
                                  [classes.nonMatching]: nonMatchingRowsOnExpected.includes(rowIdx),
                                })}
                                component='th'
                                scope='row'
                                key={colIdx}
                              >
                                {data}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default InvoiceLineItemReconDialog
