import { ApReconAutofillKey, JobStatus, TaskStatus } from '@src/graphql/types'
import { BoxDimension } from '@src/types/ocr'
import { DeployEnvironment } from '@src/utils/environment'

export const COLORS = Object.freeze({
  SEA: '#282a6a',
  ASTRONAUT: '#285478',
  PURE_WHITE: '#ffffff',
  LIGHT_BLUE: '#d6e8ff',
  PALE_BLUE: '#ddeefc',
  PALE_RED: '#ffcccc',
  PALE_YELLOW: '#ffffe5',
  MEDIUM_TURQUOISE: '#48d1cc',
})

export const RECON_MODAL_STYLES = Object.freeze({
  Z_INDEX: 1000,
})

export const ROW_BOX_STYLES = Object.freeze({
  Z_INDEX: 100,
  BORDER_COLOR: '#3a00f4',
  INACTIVE_BOX_COLOR: '#f2eeff',
})

export const FIELD_BOX_STYLES = Object.freeze({
  Z_INDEX: 200,
  BOX_COLOR: '#ffff00',
  BORDER_COLOR: '#3a00f4',
  INACTIVE_BOX_COLOR: '#f7ff9a',
  LABEL_COLOR: '#8e8e8e',
  LABEL_MAX_HEIGHT: '300px',
})

export const DEFAULT_BOX_DIMENSION = Object.freeze({
  left: 0,
  top: 0,
  width: 0,
  height: 0,
}) as BoxDimension

export const LINE_THRESHOLD = 0.007
export const MIDPOINT_THRESHOLD = LINE_THRESHOLD / 2

export const MAGIC_GRID_DIMENSIONS = Object.freeze({
  BASE_Z_INDEX: 100,
  ROW_Z_INDEX: 101,
  RULER_Z_INDEX: 102,
  CONTROLS_Z_INDEX: 103,
  RULER_THICKNESS: 1,
  MARKER_THRESHOLD: 1,
})

export const PAGE_ASSOC_DIMENSIONS = Object.freeze({
  BASE_WIDTH: '240px',
  BASE_MIN_HEIGHT: '320px',
  PAGE_INFO_HEADER_HEIGHT: '160px',
  PAGE_GALLERY_HEIGHT: '500px',
  IMAGE_BORDER_THICKNESS: '4px',
  DOC_TYPE_SELECT_WIDTH: '400px',
})

export const JOB_VIEWER_DIMENSIONS = Object.freeze({
  WIDTH: '135px',
  HEIGHT: '180px',
  MAX_WIDTH: '12vh',
  MAX_HEIGHT: '16vh',
  PAGE_INFO_HEADER_HEIGHT: '160px',
  PAGE_GALLERY_HEIGHT: '500px',
  IMAGE_BORDER_THICKNESS: '4px',
})

export const PAGE_PREVIEW_DIMENSIONS = Object.freeze({
  BUTTON_WIDTH: 200,
  MESSAGE_PANEL_HEIGHT: 160,
})

export const PAGE_DIMENSIONS = Object.freeze({
  SIDEBAR_WIDTH: '40vw',
  SIDEBAR_COLLAPSED_WIDTH: '5vw',
  BUTTON_WIDTH: 200,
  MESSAGE_PANEL_HEIGHT: 160,
})

export const TASK_VIEW_DIMENSIONS = Object.freeze({
  TASK_MESSAGE_ROWS: 10,
})

export const SECONDS_IN_MS = 1000

export const MASTER_TASK_VIEW_SYNC_BASE_INTERVAL = 4 * 60

export const SINGLE_TASK_VIEW_SYNC_BASE_INTERVAL = 1 * 60

// number of seconds +- the base interval
export const TASK_VIEW_SYNC_OFFSET = 30

export const SLA_MINUTES_TILL_JOB_ASSIGNMENT = 15

export const OVERLAY_Z_INDEX = 9999

export const LINE_ITEM_TABLE_Z_INDEX = 500

export const SELECTION_BOX_Z_INDEX = 400

export const POPPER_Z_INDEX = 10

export const LINE_ITEM_ID_KEY_SEPARATOR = '🙄'

export const HANDSONTABLE_IN_MODAL_HEIGHT = '200px'

export const HANDSONTABLE_IN_FULLSCREEN_MODAL_HEIGHT = '400px'

export const HANDSONTABLE_ROW_HEIGHT = 45

// Minimum time it takes (in ms) for a magic grid read so skeletons are noticeable
export const EXTRACT_TABLE_SLEEP = 10

export const AMPLITUDE_EVENTS = Object.freeze({
  AUTO_MODE_SELECTED: 'web_auto_mode_selected',
  MANUAL_MODE_SELECTED: 'web_manual_mode_selected',
  LOGOUT: 'web_log_out',
  TEXT_COPIED: 'web_text_copied',
  SUBMIT_FILES: 'web_submit_files',

  UPLOAD_CLICK: 'web_upload_clicked',
  UPLOAD_SUCCESS: 'web_upload_success',
  UPLOAD_FAIL: 'web_upload_fail',
  EXPORT_CLICK: 'web_export_clicked',

  CONFIRM_INFO_CLICK: 'web_confirm_information_click',
  CONFIRM_INFO_NUM_FILES: 'web_confirm_information_num_files',
  CONFIRM_INFO_SUCCESS: 'web_confirm_information_success',
  CONFIRM_INFO_FAIL: 'web_confirm_information_fail',

  DOCUMENT_CHECK_BOX: 'web_document_check_box',
  DOCUMENT_CHECK_BOX_COORDINATES: 'web_document_check_box_coordinates',
  DOCUMENT_CHECK_EDIT_FILLED: 'web_document_check_edit_filled_field',
  DOCUMENT_CHECK_EDIT_EMPTY: 'web_document_check_edit_empty_field',
})

export const JOB_STATUS: { [key in JobStatus]: string } = {
  [JobStatus.Todo]: 'Todo',
  [JobStatus.InProgress]: 'InProgress',
  [JobStatus.Review]: 'Review',
  [JobStatus.Export]: 'Export',
  [JobStatus.Qa]: 'QA',
  [JobStatus.Confirmation]: 'Confirmation',
  [JobStatus.Done]: 'Done',
  [JobStatus.Deleted]: 'Deleted',
}

export const JOB_TASK_STATUS_MAPPING: { [key in TaskStatus]?: JobStatus } = {
  [TaskStatus.Todo]: JobStatus.Todo,
  [TaskStatus.InProgress]: JobStatus.InProgress,
  [TaskStatus.Qa]: JobStatus.Qa,
  [TaskStatus.Confirmation]: JobStatus.Confirmation,
  [TaskStatus.Done]: JobStatus.Done,
}

export const JOB_STATUS_ORDER = [
  JobStatus.Todo,
  JobStatus.InProgress,
  JobStatus.Qa,
  JobStatus.Confirmation,
  JobStatus.Done,
]

// job types with specific file name
export const EXPORT_FILE_NAME_JOB_TYPES = Object.freeze({
  CEVA_CUSTOMS_DECLARATION_2HR: 'Customs declaration (2 hour SLA)',
  CEVA_CUSTOMS_DECLARATION_4HR: 'Customs declaration (4 hour SLA)',
  CEVA_CUSTOMS_DECLARATION_12HR: 'Customs declaration (12 hour SLA)',
  CEVA_CUSTOMS_DECLARATION_24HR: 'Customs declaration (24 hour SLA)',
  CARGOWISE_CI_EXTRACTION: 'Cargowise CI Extraction',
})

// job types that are supported for system-specific json export format
export const JSON_EXPORT_JOB_TYPES = Object.freeze({
  AMS_ISF_FILINGS: 'AMS & ISF Filings',
  FREMURA_AMS_FILING: 'Fremura AMS Filing',
})

// job types that are supported for system-specific excel export format
export const EXCEL_EXPORT_JOB_TYPES = Object.freeze({
  DRAYALLIANCE: 'Drayalliance Delivery Order',
  EHBL: 'eHBL',
  APLL_DATA_ENTRY: 'APLL Data Entry',
  APLL_COMMERCIAL_INVOICE_ENTRY: 'APLL Commercial Invoice Entry',
  APLL_PACKING_LIST_ENTRY: 'APLL Packing List Entry',
  APLL_ORIGIN_TEXTILE_DECLARATION_ENTRY: 'APLL Origin Textile Declaration Entry',
  APLL_FOOD_DETAILED_CHECKLIST_ENTRY: 'APLL Food Detailed Checklist Entry',
  APLL_FOOTWEAR_CLASSIFICATION_SHEET_ENTRY: 'APLL Footwear Classification Sheet Entry',
  APLL_DECLARATION_FORM_ENTRY: 'APLL Declaration Form Entry',
  FREMURA_ISF_FILING: 'Fremura ISF Filing',
  CEVA_CUSTOMS_DECLARATION_2HR: 'Customs declaration (2 hour SLA)',
  CEVA_CUSTOMS_DECLARATION_4HR: 'Customs declaration (4 hour SLA)',
  CEVA_CUSTOMS_DECLARATION_12HR: 'Customs declaration (12 hour SLA)',
  CEVA_CUSTOMS_DECLARATION_24HR: 'Customs declaration (24 hour SLA)',
  CARGOWISE_CI_EXTRACTION: 'Cargowise CI Extraction',
  SCHAYER_CUSTOMS_DECLARATION_TYPE_1: 'Customs Declaration Type 1',
  SCHAYER_CUSTOMS_DECLARATION_TYPE_2: 'Customs Declaration Type 2',
})

// job types that are supported for system-specific CSV export format
export const CSV_EXPORT_JOB_TYPES = Object.freeze({
  ASCENT_INVOICE: 'Ascent Invoice',
  CEVA_CUSTOMS_DECLARATION_2HR: 'Customs declaration (2 hour SLA)',
  CEVA_CUSTOMS_DECLARATION_4HR: 'Customs declaration (4 hour SLA)',
  CEVA_CUSTOMS_DECLARATION_12HR: 'Customs declaration (12 hour SLA)',
  CEVA_CUSTOMS_DECLARATION_24HR: 'Customs declaration (24 hour SLA)',
})

// palette for when the bg color is determined by the foreground text's hash
// (eg avatars, colored chips)
export const HASH_BG_COLOR_PALETTE = [
  '#1F1D1E',
  '#6A0B88',
  '#D3742F',
  '#59A9DB',
  '#AC1F32',
  '#ACAA5E',
  '#549C3B',
  '#CB63A2',
  '#436CAD',
  '#CD6F51',
  '#4A0F95',
  '#D1901F',
  '#910389',
  '#731812',
  '#6E8830',
  '#6F3813',
  '#D33029',
  '#2C3A17',
  '#818284',
]

export const MATCH_SHIPMENT_KEYS_COLUMNS = [
  ApReconAutofillKey.ReferenceNumber,
  ApReconAutofillKey.HblNumber,
  ApReconAutofillKey.MblNumber,
  ApReconAutofillKey.CarrierBookingNumber,
  ApReconAutofillKey.ContainerNumber,
  ApReconAutofillKey.ConsolNumber,
].map((autofillKey: string): string => autofillKey.toLowerCase())

// used by SOA sending modal line items to match Table Column autofill keys to modal columns
export const SOA_LINE_ITEM_AUTOFILL_KEY_MAPPING: { [key: string]: string } = Object.freeze({
  invoice_number: 'Invoice Number',
  reference_number: 'Shipment Number',
  hbl_number: 'HBL No.',
  mbl_number: 'MBL No.',
  carrier_booking_number: 'Carrier Booking No.',
  container_number: 'Container No.',
  consol_number: 'Consol No.',
  order_number: 'Order No.',
  charge_code: 'Charge Code',
  charge_description: 'Charge Description',
  currency: 'Currency',
  charge_cost: 'Cost',
  quantity: 'Charge Qty',
  invoice_date: 'Invoice Date',
  due_date: 'Due Date',
  tax_id: 'Tax Code',
  cargowise_module: 'Cargowise Module',
  document_received_date: 'Document Received Date',
})

export const SOA_LINE_ITEM_SENDING_SECONDARY_KEY_COLUMNS: string[] = [
  'HBL No.',
  'MBL No.',
  'Carrier Booking No.',
  'Container No.',
  'Consol No.',
  'Order No.',
]

export enum CARGOWISE_MODULE {
  ForwardingShipment = 'ForwardingShipment',
  ForwardingConsol = 'ForwardingConsol',
  CustomsDeclaration = 'CustomsDeclaration',
}

export const MAX_FILE_PAGES_IN_SINGLE_UPLOAD = 100

export const ENV_DASHBOARD_URL_MAP: { [key: string]: string } = Object.freeze({
  [DeployEnvironment.DEVELOPMENT]: 'http://localhost:3001',
  [DeployEnvironment.ENGINEERING]: 'https://eng-dashboard.expedock.com',
  [DeployEnvironment.STAGING]: 'https://staging-dashboard.expedock.com',
  [DeployEnvironment.PRODUCTION]: 'https://dashboard.expedock.com',
})

export const ENV_PASSIVE_DASHBOARD_URL_MAP: { [key: string]: string } = Object.freeze({
  [DeployEnvironment.DEVELOPMENT]: 'http://localhost:3001',
  [DeployEnvironment.ENGINEERING]: 'https://eng-passive-dashboard.expedock.com',
  [DeployEnvironment.STAGING]: 'https://staging-passive-dashboard.expedock.com',
  [DeployEnvironment.PRODUCTION]: 'https://passive-dashboard.expedock.com',
})
