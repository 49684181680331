import {
  FieldGroupNode,
  JobNode,
  JobTableNode,
  JobTemplateReconType,
  Maybe,
} from '@src/graphql/types'
import { BoxDimension } from '@src/types/ocr'
import { JobTableFieldValue, JobTableLineItem } from '@src/utils/line_items'
import { DocumentEditorState } from './document_editor_state'
import { jobTableLineItemsAdapter } from './job_line_items_table'
import { getNullableEntitySelectors } from './nullable_entity_selector'
import { FieldNodeEdge } from '../../graphql/types'

/**
 * Converts JobTable of a Job to JobTableLineItem[], which we store in the redux state
 * This is done because we want the granularity of operating on a list of rows and columns
 */
export const initializeJobTable = (
  job: JobNode,
  tableShowsPreset: boolean,
): {
  jobTableLineItems: JobTableLineItem[]
  columns: string[]
  fieldGroup?: Maybe<FieldGroupNode>
} => {
  const jobTableLineItems =
    job.jobTable?.jobTableLineItems?.edges.map((row) => {
      const fieldMapping = row!.node!.jobTableCells!.edges.reduce(
        (acc, cell) => {
          const { id, value, top, left, width, height, documentId, field } = cell!.node!
          acc[field!.key] = {
            id,
            value,
            top,
            left,
            width,
            height,
            documentId,
          }
          return acc as Record<string, JobTableFieldValue>
        },
        {} as Record<string, JobTableFieldValue>,
      )

      const boxMapping = row!.node!.jobTableCells!.edges.reduce(
        (acc, cell) => {
          const { top, left, width, height, documentId } = cell!.node!
          if (top && left && width && height) {
            const currentBox = acc[documentId]
            if (currentBox) {
              const newTop = Math.min(currentBox.top, top)
              const newBottom = Math.max(currentBox.top + currentBox.height, top + height)
              const newLeft = Math.min(currentBox.left, left)
              const newRight = Math.max(currentBox.left + currentBox.width, left + width)
              acc[documentId] = {
                top: newTop,
                left: newLeft,
                width: newRight - newLeft,
                height: newBottom - newTop,
              }
            } else {
              acc[documentId] = { top, left, width, height } as BoxDimension
            }
          }
          return acc as Record<string, BoxDimension>
        },
        {} as Record<string, BoxDimension>,
      )

      return { id: row!.node!.id, fieldMapping, boxMapping }
    }) || []

  const fieldGroup = job.jobTable?.fieldGroup || null
  const fieldEdges = job.jobTable?.fieldGroup.fields.edges ?? ([] as FieldNodeEdge[])
  const requiredColumnKeys = fieldEdges
    .filter((field) => field.node.required)
    .map((fieldEdge) => fieldEdge.node.key)
  const savedJobTableColumns =
    job.jobTable?.jobTableColumns?.edges.map((column) => column!.node!.field!.key) ??
    ([] as string[])
  const columns = tableShowsPreset
    ? fieldEdges.map((fieldEdge) => fieldEdge.node.key)
    : savedJobTableColumns.concat(requiredColumnKeys)
  return { jobTableLineItems, columns, fieldGroup }
}

export const jobTableLineItemSelectors = getNullableEntitySelectors(
  jobTableLineItemsAdapter,
  (state: DocumentEditorState) => state.jobTableState?.lineItems || null,
)

export const selectJobTable = (state: DocumentEditorState): Maybe<JobTableNode> | undefined =>
  state.job?.jobTable

export const stateHasMainTable = (state: DocumentEditorState): boolean =>
  state.job?.jobTemplate?.reconType === JobTemplateReconType.Soa ||
  !!state.job?.jobTemplate?.mainTabEnabled
