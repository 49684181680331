import { FunctionComponent } from 'react'
import { RenderLeafProps } from 'slate-react'

const SlateLeafElement: FunctionComponent<RenderLeafProps> = ({
  attributes,
  children,
  leaf,
}: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }
  if (leaf.italic) {
    children = <em>{children}</em>
  }
  if (leaf.underlined) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

export default SlateLeafElement
