import { FunctionComponent, useCallback } from 'react'
import { InputField, Maybe, SearchableRecordOptions } from '@src/graphql/types'
import { useFieldArray } from 'react-hook-form'
import JobTemplateLineItemColumn from '@src/components/admin/job-template-fields/JobTemplateLineItemColumn'
import { Box } from '@material-ui/core'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { makeStyles } from '@material-ui/styles'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import AddColumnButton from '@src/components/admin/job-template-fields/AddColumnButton'

type Props = {
  fieldGroupIndex: number
  fieldGroupId: string
  searchableRecordOptions: Maybe<SearchableRecordOptions[]> | undefined
}

const useStyles = makeStyles({
  dragIcon: {
    cursor: 'grab',
  },
  fieldDraggable: {
    display: 'flex',
  },
})

const JobTemplateLineItemColumns: FunctionComponent<Props> = ({
  fieldGroupIndex,
  fieldGroupId,
  searchableRecordOptions,
}) => {
  const classes = useStyles()
  // TODO: reordering, adding new fields
  const fieldArrayMethods = useFieldArray({
    name: `lineItemTypes.${fieldGroupIndex}.fields`,
  })
  const { remove, fields, append, move } = fieldArrayMethods
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return
      }
      move(result.source.index, result.destination.index)
    },
    [move],
  )
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`field-group-${fieldGroupId}`} type='fields'>
        {(provided) => (
          // eslint-disable-next-line @typescript-eslint/unbound-method
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {fields.map((field, index) => (
              <Draggable draggableId={`field-${field.id}`} index={index} key={field.id}>
                {(draggableProvided) => (
                  <div
                    key={field.id}
                    className={classes.fieldDraggable}
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                  >
                    <div {...draggableProvided.dragHandleProps}>
                      <DragIndicatorIcon className={classes.dragIcon} />
                    </div>
                    <Box flex='1'>
                      <JobTemplateLineItemColumn
                        field={field as InputField}
                        fieldIndex={index}
                        fieldGroupIndex={fieldGroupIndex}
                        remove={remove}
                        searchableRecordOptions={searchableRecordOptions}
                      />
                    </Box>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            <Box display='flex' flexDirection='row-reverse'>
              <AddColumnButton append={append} />
            </Box>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default JobTemplateLineItemColumns
