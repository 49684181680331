import { FunctionComponent } from 'react'
import { Paper, TextField, Theme, Typography, Select, MenuItem, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import theme from '@src/utils/theme'
import { ApiPartnerInterface } from '@src/graphql/types'
import { useEffect } from 'react'
import { ChangeEvent } from 'react'
import { SelectedChargeVendor } from './types'

type Props = {
  vendor: SelectedChargeVendor | undefined
  currVendorName?: string
  setCurrVendorName: (name: string) => void
  currVendorCode?: string
  setCurrVendorCode: (code: string) => void
  currVendorType?: string
  setCurrVendorType: (type: string) => void
  createNewVendorMode: boolean
  apiPartners: ApiPartnerInterface[]
  currApiPartnerId: string | null
  setCurrApiPartnerId: (apiPartnerId: string | null) => void
}

// general pixel approximations from the spec
// of the cell size. Not derived from anywhere in particular.
const metadataCellWidth = `150px`
const metadataInputWidth = '300px'

const useStyles = makeStyles<Theme>({
  descriptionCellTop: {
    color: theme.palette.grey[700],
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    borderRight: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(1),
    width: metadataCellWidth,
  },
  tableCellTop: {
    color: theme.palette.grey[700],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: metadataCellWidth,
  },
  descriptionCellBot: {
    color: theme.palette.grey[700],
    borderRight: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(1),
    width: metadataCellWidth,
  },
  tableCellBot: {
    color: theme.palette.grey[700],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: metadataCellWidth,
  },
  input: {
    paddingTop: theme.spacing(2),
    width: metadataInputWidth,
  },
  table: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  cwConfigSelector: {
    padding: theme.spacing(1),
    width: metadataInputWidth,
  },
  apiPartnerSelector: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    width: metadataInputWidth,
  },
})

const VendorMetadataTable: FunctionComponent<Props> = ({
  vendor,
  currVendorName,
  setCurrVendorName,
  currVendorCode,
  setCurrVendorCode,
  currVendorType,
  setCurrVendorType,
  createNewVendorMode,
  apiPartners,
  currApiPartnerId,
  setCurrApiPartnerId,
}) => {
  const classes = useStyles()

  useEffect(() => {
    setCurrVendorName(vendor?.name || '')
    setCurrVendorCode(vendor?.code || '')
    setCurrVendorType(vendor?.type || '')
  }, [vendor, setCurrVendorCode, setCurrVendorName, setCurrVendorType])

  const updateName = (e: ChangeEvent<{ value: string }>): void => {
    setCurrVendorName(e.target.value)
  }

  const updateCode = (e: ChangeEvent<{ value: string }>): void => {
    setCurrVendorCode(e.target.value)
  }

  const updateVendorType = (e: ChangeEvent<{ value: unknown }>): void => {
    if (typeof e.target.value == 'string') {
      setCurrVendorType(e.target.value)
    }
  }

  const changeApiPartner = (e: React.ChangeEvent<Record<string, unknown>>): void => {
    const chosenApiPartner = apiPartners.find((partner) => partner.id === e.target.value)
    setCurrApiPartnerId(chosenApiPartner!.id)
  }

  return (
    <Box display='flex' justifyContent='space-between' data-testid='vendor-metadata'>
      <Paper className={classes.table}>
        <table>
          <tbody>
            <tr>
              <td className={classes.descriptionCellTop}>
                <Typography>Vendor Name</Typography>
              </td>
              <td className={classes.tableCellTop}>
                <TextField
                  onChange={updateName}
                  value={currVendorName}
                  className={classes.input}
                  id='vendor-name'
                />
              </td>
            </tr>
            <tr>
              <td className={classes.descriptionCellTop}>
                <Typography>Vendor Code</Typography>
              </td>
              <td className={classes.tableCellBot}>
                <TextField
                  onChange={updateCode}
                  value={currVendorCode}
                  className={classes.input}
                  id='vendor-code'
                />
              </td>
            </tr>
            <tr>
              <td className={classes.descriptionCellBot}>
                <Typography>Vendor Type</Typography>
              </td>
              <td className={classes.tableCellBot}>
                <Select
                  value={currVendorType}
                  onChange={updateVendorType}
                  displayEmpty
                  fullWidth
                  id='vendor-type'
                >
                  <MenuItem value=''></MenuItem>
                  <MenuItem value='Carrier'>Carrier</MenuItem>
                  <MenuItem value='Co-loader'>Co-loader</MenuItem>
                </Select>
              </td>
            </tr>
          </tbody>
        </table>
      </Paper>
      <Box pt={2} pr={4}>
        <Paper className={classes.apiPartnerSelector}>
          <Typography variant='caption'>
            {createNewVendorMode ? 'Select TMS to create codes to' : 'Filter by TMS'}
          </Typography>
          <Select
            value={currApiPartnerId}
            onChange={changeApiPartner}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            data-testid='api-partner-selector'
            fullWidth
          >
            {!apiPartners?.length ? (
              <MenuItem value={''}>N/A</MenuItem>
            ) : (
              apiPartners.map((partner) => {
                return (
                  <MenuItem key={partner.id} value={partner.id}>
                    {partner.name}
                  </MenuItem>
                )
              })
            )}
          </Select>
        </Paper>
      </Box>
    </Box>
  )
}

export default VendorMetadataTable
