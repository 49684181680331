import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  styled,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { DeleteOutlined, NoteAdd } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { FunctionComponent } from 'react'

type Props = {
  title: string
  subtitle: string
  files: File[] | null
  setFiles: (newFiles: File[]) => void
}

const FileName = styled(Typography)(() => ({
  textOverflow: 'ellipsis',
  wordWrap: 'normal',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}))

const EAdaptorFileCard: FunctionComponent<Props> = ({ title, subtitle, files, setFiles }) => {
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Card variant='outlined'>
      <CardHeader
        action={
          <IconButton aria-label='settings' component='label'>
            <NoteAdd />
            <input
              hidden
              type='file'
              multiple
              onChange={(e) => {
                if (e.target.files) {
                  const newFiles = [...e.target.files].filter((newFile) => {
                    if (files?.find((file) => file.name === newFile.name)) {
                      enqueueSnackbar(`${newFile.name} already exists!`, { variant: 'error' })
                      return false
                    }
                    if (!newFile.name.endsWith('.xlsx')) {
                      enqueueSnackbar(`${newFile.name} is not a valid xlsx file!`, {
                        variant: 'error',
                      })
                      return false
                    }
                    return true
                  })
                  setFiles([...(files ?? []), ...newFiles])
                }
              }}
            />
          </IconButton>
        }
        title={title}
        subheader={subtitle}
      />
      <List dense>
        {files?.map((file, index) => (
          <ListItem key={`${title}-${index}`}>
            <ListItemText>
              <Tooltip title={file.name} placement='top-start'>
                <FileName>{file.name}</FileName>
              </Tooltip>
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={(e) => setFiles(files.filter((_, currIndex) => currIndex !== index))}
              >
                <DeleteOutlined color='error' />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      {!files?.length && (
        <CardContent>
          <Typography variant='body2'>No files currently selected</Typography>
        </CardContent>
      )}
    </Card>
  )
}

export default EAdaptorFileCard
