import { FunctionComponent } from 'react'
import { HotTableData, RECON_METADATA_DATA_TABLE_COLUMNS } from '@src/utils/recon/ap_recon'

import { clsx } from 'clsx'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { COLORS } from '@src/utils/app_constants'
import theme from '@src/utils/theme'

type Props = {
  title: string
  metadataTable: HotTableData | null
}

const useStyles = makeStyles<Theme>({
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.75, 1),
  },
  error: {
    color: theme.palette.error.main,
    background: COLORS.PALE_RED,
    boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
  },
})

export const APInvoiceReconMetadataTable: FunctionComponent<Props> = ({ title, metadataTable }) => {
  const classes = useStyles()
  if (metadataTable === null || metadataTable.data.length === 0) {
    return <></>
  }

  const tableHeaders = [...RECON_METADATA_DATA_TABLE_COLUMNS]
  // show Delta for Metadata Reconciliation only, and constant is used in other places
  // https://expedock.atlassian.net/browse/PD-6044
  if (title === 'Metadata Reconciliation') {
    tableHeaders.push('Delta')
  }

  return (
    <>
      <Typography variant='subtitle1' gutterBottom>
        {title}:
      </Typography>
      <TableContainer>
        <Table size='small'>
          <TableBody>
            <TableRow>
              {tableHeaders.map((col, colIdx) => (
                <TableCell
                  key={`col-${colIdx}`}
                  className={clsx(classes.cell, classes.colCell)}
                  align='center'
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
            {metadataTable.data.map((row, rowIdx) => (
              <TableRow key={rowIdx}>
                {row.map((data, colIdx) => (
                  <TableCell
                    key={`ap-${title}-${rowIdx}-${colIdx}`}
                    data-testid={`ap-${title}-${rowIdx}-${colIdx}`}
                    className={clsx(classes.cell, {
                      [classes.error]: metadataTable.errorCells![rowIdx]?.includes(colIdx),
                    })}
                    component='th'
                    scope='row'
                  >
                    {data}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
