import { FunctionComponent } from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import theme from '@src/utils/theme'
import RoundedButton from '@src/components/RoundedButton'

type Props = {
  newTax: () => void
  disabled: boolean
}

const useStyles = makeStyles<Theme>({
  newTaxButton: {
    background: 'white',
    color: theme.palette.grey[500],
    border: `1px solid ${theme.palette.grey[500]}`,
  },
})

const NewTaxButton: FunctionComponent<Props> = ({ newTax, disabled }) => {
  const classes = useStyles()
  return (
    <RoundedButton
      variant='contained'
      onClick={newTax}
      className={classes.newTaxButton}
      disabled={disabled}
    >
      + Tax
    </RoundedButton>
  )
}

export default NewTaxButton
