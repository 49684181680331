import { FunctionComponent, ReactElement } from 'react'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { COLORS } from '@src/utils/app_constants'
import theme from '@src/utils/theme'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import CloseIcon from '@material-ui/icons/Close'

const ICON_SIZE = '30px'

const useStyles = makeStyles({
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  dialog: {
    padding: `${theme.spacing(5)}px ${theme.spacing(3)}px`,
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    width: ICON_SIZE,
    height: ICON_SIZE,
    color: COLORS.PURE_WHITE,
  },
  text: {
    backgroundColor: theme.palette.primary.main,
    color: COLORS.PURE_WHITE,
    padding: '4px 8px',
  },
  shortcutName: {
    width: '130px',
  },
})

type Props = {
  handleCloseDialog: () => void
  open: boolean
}

const ShortcutsDialog: FunctionComponent<Props> = ({ open, handleCloseDialog }) => {
  const classes = useStyles()

  const sheetShortcuts: Record<string, string> = {
    Save: 'S',
    Undo: 'Z',
    'Multi-select': '',
    Copy: 'C',
    Paste: 'V',
    'Find & Replace': 'H',
  }
  const boxesShortcuts: Record<string, string | ReactElement> = {
    'Zoom in': <ArrowDownwardIcon color='inherit' />,
    'Zoom out': <ArrowUpwardIcon color='inherit' />,
    Pan: '',
    Copy: 'C',
    Paste: 'V',
  }

  return (
    <Dialog maxWidth='sm' open={open} onClose={handleCloseDialog} aria-labelledby='a'>
      <Box className={classes.close}>
        <CloseIcon onClick={handleCloseDialog} data-testid='close-btn' />
      </Box>
      <DialogContent className={classes.dialog}>
        <Grid container>
          <Box display='flex' flexDirection='column' p={2}>
            <Typography align='center' gutterBottom>
              Sheet
            </Typography>
            {Object.keys(sheetShortcuts).map((shortcut) => (
              <Box key={shortcut} display='flex' mb={1}>
                <Typography className={classes.shortcutName}>{shortcut}</Typography>
                <Box display='flex' alignItems='center'>
                  <Box className={classes.text} borderRadius={5} mr={1}>
                    Ctrl
                  </Box>
                  {sheetShortcuts[shortcut] && (
                    <Box className={classes.text} borderRadius={5}>
                      {sheetShortcuts[shortcut]}
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
          <Divider orientation='vertical' flexItem />
          <Box display='flex' flexDirection='column' p={2}>
            <Typography align='center' gutterBottom>
              Boxes
            </Typography>
            {Object.keys(boxesShortcuts).map((shortcut) => (
              <Box key={shortcut} display='flex' mb={1}>
                <Typography className={classes.shortcutName}>{shortcut}</Typography>
                <Box display='flex' alignItems='center'>
                  <Box className={classes.text} borderRadius={5} mr={1}>
                    Ctrl
                  </Box>
                  {boxesShortcuts[shortcut] && typeof boxesShortcuts[shortcut] === 'string' && (
                    <Box className={classes.text} borderRadius={5}>
                      {boxesShortcuts[shortcut]}
                    </Box>
                  )}
                  {typeof boxesShortcuts[shortcut] === 'object' && (
                    <Box
                      className={classes.icon}
                      borderRadius='50%'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      {boxesShortcuts[shortcut]}
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ShortcutsDialog
