import { Box, Typography } from '@material-ui/core'
import { CargowiseConfigNode } from '@src/graphql/types'
import { FunctionComponent } from 'react'
import DateRangeSelector from '../utils/DateRangeSelector'
import { useDateRange } from '../utils/useDateRange'

type Props = {
  cargowiseConfig: CargowiseConfigNode
}

const DatabaseColdStart: FunctionComponent<Props> = ({ cargowiseConfig }) => {
  const { startDate, setStartDate, endDate, setEndDate } = useDateRange()
  return (
    <Box>
      <Box py={2}>
        <Typography variant='h3'>DB-connected</Typography>
        <Typography gutterBottom>
          The selected config is a db-connected config. This is currently unsupported.
        </Typography>
      </Box>

      <DateRangeSelector
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        spacing={1}
      />
    </Box>
  )
}

export default DatabaseColdStart
