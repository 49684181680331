import { DeepRequired, FieldErrorsImpl } from 'react-hook-form'
import { LineItemsTableMap } from '@src/redux-features/document_editor/line_items_table'
import { MagicGridMap } from '@src/redux-features/document_editor/magic_grid'
import { DocumentEditorState } from '@src/redux-features/document_editor/document_editor_state'
import { isPresent } from 'ts-is-present'
import {
  DocumentTableNode,
  DocumentTypeFieldGroupNodeEdge,
  DocumentTypeNodeEdge,
  DocumentTypeNode,
  FieldGroupNode,
  FieldNode,
  FilePageNode,
  InputDocumentTable,
  JobNode,
  Maybe,
  InputJobTable,
  InputBoxDimension,
  InputTableColumn,
} from '@src/graphql/types'
import { lineItemsAdapter } from '@src/redux-features/document_editor/line_items_table'
import { getLineItemFieldCoordinates, JobTableLineItem, LineItem } from '@src/utils/line_items'
import { magicGridColumnsAdapter } from '@src/redux-features/document_editor/magic_grid'
import { SpreadsheetDataColumn } from './data-grid'
import { groupBy } from 'lodash'
import { MagicGridColumn } from '@src/types/grid'
import { v4 as uuid4 } from 'uuid'
import { FilePageType } from '@src/graphql/types'

export type JobDocumentTable = DocumentTableNode & {
  filePageId: string
  filePageIdx: number
  filePageType: FilePageType
  // TODO: this should be split into another type, since we don't actually populate this
  //       for the tables stored in Redux
  linkedTables: JobDocumentTable[]
}

/*
 * Get the field key -> page mapping of the shipment form. Used for assoc pages with fields
 * on the form.
 */
export const getFormDefaultPageMapping = (jobFields: JobNode): Record<string, string> => {
  const defaultPageMapping = {} as Record<string, string>
  for (const filePageNode of jobFields.filePages!.edges) {
    for (const documentFieldGroup of filePageNode!.node!.document!.documentFieldGroups!.edges) {
      for (const documentField of documentFieldGroup!.node!.documentFields!.edges) {
        const documentFieldNode = documentField!.node
        const { key } = documentFieldNode!.field!
        if (!(key in defaultPageMapping)) {
          defaultPageMapping[key] = filePageNode!.node!.id
        }
      }
    }
  }
  return defaultPageMapping
}

/*
 * Consolidate the fieldMappings of the pages in pageFieldEditorState to a unified
 * key -> value field mapping for the whole job (including ${key}_confirmed fields).
 *
 * Generates ${key}_confirmed fields for all fields not already present in the editor state.
 * TODO: the _confirmed fields thing is kinda hacky
 */
export const consolidateFieldMappings = (
  pageFieldEditorState: DocumentEditorState['pageFieldEditorState'],
  job: JobNode,
): Record<string, string> => {
  const fieldKeyToValue = {} as Record<string, string>
  for (const page of Object.values(pageFieldEditorState?.pages ?? {})) {
    const pageFieldValues = page.fieldMapping
    for (const key of Object.keys(pageFieldValues)) {
      fieldKeyToValue[key] = pageFieldValues[key]
    }
  }
  for (const documentType of job.jobTemplate!.documentTypes!.edges.map((edge) => edge!.node!)) {
    const nonRepeatableFieldGroups = documentType
      .documentTypeFieldGroups!.edges.map((edge) => edge!.node!.fieldGroup!)
      .filter((fieldGroup) => !fieldGroup.repeatable)
    for (const fieldGroup of nonRepeatableFieldGroups) {
      const field = fieldGroup.fields!.edges[0]!.node!
      if (fieldKeyToValue[field.key] === undefined) {
        fieldKeyToValue[`${field.key}_confirmed`] = 'true'
      }
    }
  }
  return fieldKeyToValue
}

// Scroll up the form on error.
export const scrollOnError = (
  errors: FieldErrorsImpl<DeepRequired<Record<string, string>>>,
): void => {
  // scroll up to error if it occurs
  if (Object.keys(errors).length > 0) {
    const confirmedErrorRefName = Object.keys(errors)[0]
    // TODO: we may get an issue with a field with a key like `date_confirmed`.
    //       we should make the suffix more like $$confirmed
    const confirmedSuffix = '_confirmed'
    let fieldId = ''
    if (confirmedErrorRefName.includes(confirmedSuffix)) {
      fieldId = confirmedErrorRefName.slice(0, confirmedErrorRefName.lastIndexOf('_confirmed'))
    } else {
      fieldId = confirmedErrorRefName
    }
    document.getElementById(fieldId)?.scrollIntoView()
  }
}

// Get document types from job
export const getJobDocumentTypes = (job: Maybe<JobNode>): Maybe<DocumentTypeNode>[] => {
  return (
    job?.jobTemplate?.documentTypes?.edges.map(
      (edge: Maybe<DocumentTypeNodeEdge>) => edge!.node!,
    ) ?? []
  )
}

// Get all field groups from document types
export const getDocumentTypesFieldGroups = (
  documentTypes: Maybe<DocumentTypeNode>[],
): FieldGroupNode[] => {
  return documentTypes
    .flatMap(
      (documentTypeNode: Maybe<DocumentTypeNode>) =>
        documentTypeNode!.documentTypeFieldGroups!.edges,
    )
    .map(
      (edge: Maybe<DocumentTypeFieldGroupNodeEdge>) => edge!.node!.fieldGroup,
    ) as FieldGroupNode[]
}

// Get all field groups from a document type
export const getDocumentTypeFieldGroups = (
  documentType: Maybe<DocumentTypeNode>,
): FieldGroupNode[] => {
  return getDocumentTypesFieldGroups([documentType])
}

// Get non repeatable fields from field groups
export const getFieldGroupsNonRepeatableFields = (fieldGroups: FieldGroupNode[]): FieldNode[] => {
  return (
    fieldGroups.flatMap((fieldGroup: FieldGroupNode) =>
      fieldGroup.repeatable ? [] : fieldGroup.fields.edges.map((fieldEdge) => fieldEdge.node) || [],
    ) || []
  )
}

// Get non repeatable fields from job
export const getJobNonRepeatableFields = (job: Maybe<JobNode>): FieldNode[] => {
  const documentTypes = getJobDocumentTypes(job)
  const fieldGroups = getDocumentTypesFieldGroups(documentTypes)
  return getFieldGroupsNonRepeatableFields(fieldGroups)
}

export const getDocumentTables = (jobFields: JobNode): DocumentTableNode[] => {
  return jobFields
    .filePages!.edges.map((filePageEdge) => filePageEdge!.node!.document!.documentTables)
    .flatMap((docTable) => docTable!.edges.map((docTableEdge) => docTableEdge!.node!))
}

export const getFilePageDocumentTables = (
  jobFields: JobNode,
  filePageId: string,
): DocumentTableNode[] => {
  return jobFields
    .filePages!.edges.filter((filePage) => filePage!.node!.id === filePageId)
    .map((filePageEdge) => filePageEdge!.node!.document!.documentTables)
    .flatMap((docTable) => docTable!.edges.map((docTableEdge) => docTableEdge!.node!))
}

export const getPageIdFromDocTableId = (
  job: JobNode,
  documentTableId: string,
  jobDocTables: JobDocumentTable[],
): string => {
  const filePage = job.filePages!.edges.find((filePageEdge) => {
    const docTableIds = filePageEdge?.node?.document?.documentTables?.edges?.map(
      (docTable) => docTable?.node?.id,
    )
    return docTableIds?.includes(documentTableId)
  })
  // fallback to find id in tables if not present in file pages (stale data)
  if (!filePage) {
    const jobDocTable = jobDocTables.find((jdt) => jdt.id === documentTableId)
    return jobDocTable!.filePageId!
  }
  return filePage!.node!.id
}

// format line item validation errors and map them to their corresponding document tables
export const formatDocTableErrors = (
  docTables: JobDocumentTable[],
  tablesWithErrors: Record<string, string[]>,
): Record<string, string[]> => {
  const docTableValidationErrors = {} as Record<string, string[]>
  const groupedTablesMap = {} as Record<string, string[]>
  docTables.forEach(({ id, linkedTables }) => {
    const linkedTableIds = linkedTables.map((linkedTable) => linkedTable.id)
    groupedTablesMap[id] = [id, ...linkedTableIds]
    docTableValidationErrors[id] = []
  })
  Object.keys(groupedTablesMap).forEach((docTableId) => {
    Object.keys(tablesWithErrors).forEach((tableIdWithError) => {
      const tableIdx = groupedTablesMap[docTableId].findIndex(
        (tableId) => tableIdWithError === tableId,
      )
      if (tableIdx >= 0) {
        const joinedColumnsText = tablesWithErrors[tableIdWithError].join(', ')
        const errorMessage = `Table ${tableIdx + 1}: columns [${joinedColumnsText}] are invalid`
        docTableValidationErrors[docTableId].push(errorMessage)
      }
    })
  })
  return docTableValidationErrors
}

/**
 * Given a list of document tables, return the head tables (in arbitrary order), with the rest of the tables in its
 * corresponding list in `linkedTables`
 */
export const groupMergedTables = (docTables: JobDocumentTable[]): JobDocumentTable[] => {
  const headTableIds = new Set<string>(docTables.map(({ id }) => id))
  for (const table of docTables) {
    if (table.nextTableId) {
      headTableIds.delete(table.nextTableId)
    }
  }
  const tableIdMap = Object.fromEntries(docTables.map((table) => [table.id, table]))
  return Array.from(headTableIds).map((headTableId) => {
    const table = tableIdMap[headTableId]
    const linkedTables = [] as JobDocumentTable[]
    let nextTableId = table.nextTableId
    while (isPresent(nextTableId)) {
      const nextTable = tableIdMap[nextTableId]
      if (nextTable) {
        linkedTables.push(nextTable)
      }
      nextTableId = nextTable?.nextTableId
    }
    return { ...table, linkedTables } as JobDocumentTable
  })
}

/**
 * Create input document tables for submission to the backend
 */
export function formatToInputDocumentTable(
  documentTable: JobDocumentTable,
  lineItems: LineItem[],
  magicGridColumns: MagicGridColumn[],
  editedByUser: boolean,
  colKeys: string[],
): InputDocumentTable {
  // columns with dimensions
  const magicGridColumnsMap = groupBy(magicGridColumns, 'key')

  const documentTableColums = colKeys.map((colKey) => {
    // default dimensions for table columns that weren't initialized upon the creation of the document table
    const defaultDimension = {
      height: 0,
      left: 0,
      top: 0,
      width: 0,
    } as InputBoxDimension

    return {
      id: uuid4(),
      key: colKey,
      dimension: magicGridColumnsMap[colKey]
        ? magicGridColumnsMap[colKey][0].dimension
        : defaultDimension,
    } as InputTableColumn
  })
  const finalLineItems = JSON.parse(JSON.stringify(lineItems))

  finalLineItems.forEach((lineItem: LineItem, idx: number) => {
    if (!lineItem.documentTableId) {
      lineItem.documentTableId = documentTable.id
    }
    lineItem.fieldMapping = Object.fromEntries(
      Object.entries(lineItem.fieldMapping).filter(([key]) => colKeys.includes(key)),
    )
    lineItem.rowOrderPriority = idx
  })
  return {
    id: documentTable.id,
    fieldGroupId: documentTable.fieldGroup!.id,
    fieldGroupKey: documentTable.fieldGroup!.key,
    fieldCoordinates: getLineItemFieldCoordinates(finalLineItems),
    lineItems: finalLineItems,
    tableColumns: documentTableColums,
    editedByUser,
  }
}

export const getColumnKeys = (columns: SpreadsheetDataColumn[]): string[] => {
  const inputTableColumns = columns.map((column) => column.key)
  return inputTableColumns
}

/**
 * Create input job table for submission to the backend
 */
export function formatToInputJobTable(
  lineItems: JobTableLineItem[],
  fieldGroupId: string,
  defaultDocumentId: string,
  fieldKeyMap: Record<string, FieldNode>,
  colKeys: string[],
): InputJobTable {
  return {
    fieldGroupId,
    columns: colKeys,
    rows: lineItems.map((lineItem) => {
      return {
        cells: colKeys.map((fieldKey) => {
          const cell = lineItem.fieldMapping[fieldKey]
          const inputCell = {
            top: cell?.top || null,
            left: cell?.left || null,
            width: cell?.width || null,
            height: cell?.height || null,
            fieldId: fieldKeyMap[fieldKey]!.id,
            value: cell?.value || fieldKeyMap[fieldKey]?.defaultValue || '',
            documentId: cell?.documentId || defaultDocumentId,
          }
          return inputCell
        }),
      }
    }),
  }
}

export function createInputDocumentTables(
  documentTables: JobDocumentTable[],
  documentId: string,
  lineItemsTableMap: LineItemsTableMap,
  magicGridMap: MagicGridMap,
): InputDocumentTable[] {
  return Object.values(documentTables)
    .filter((docTable) => docTable.documentId === documentId)
    .map((docTable) => {
      const lineItems = lineItemsAdapter
        .getSelectors()
        .selectAll(lineItemsTableMap[docTable.id].lineItems)
      const columns = lineItemsTableMap[docTable.id].columns
      return formatToInputDocumentTable(
        docTable,
        lineItems,
        magicGridColumnsAdapter.getSelectors().selectAll(magicGridMap[docTable.id].columns),
        lineItemsTableMap[docTable.id].editedByUser,
        columns,
      )
    })
}

export const getFilePageFromDocId = (
  job: JobNode,
  documentId: string,
): FilePageNode | undefined | null => {
  const filePage = job.filePages!.edges.find(
    (filePageEdge) => filePageEdge!.node!.document!.id === documentId,
  )?.node
  return filePage
}
