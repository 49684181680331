import { capitalCase } from 'capital-case'
import { ShipmentOp } from '@src/graphql/types'

export type ShipmentOpsTypeOption = {
  title: string
  value: ShipmentOp
}

export const toShipmentOpsTypeOption = (shipmentOpsType: ShipmentOp): ShipmentOpsTypeOption => {
  return {
    title: capitalCase(shipmentOpsType),
    value: shipmentOpsType,
  }
}
