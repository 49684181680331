import { produce } from 'immer'
import { DocumentFieldNodeEdge, JobNode } from '@src/graphql/types'

export const formatFieldGroups = (
  job: JobNode,
  fieldGroups: string[],
  formatter: (value: string) => string,
): JobNode =>
  produce(job, (jobDraft) => {
    if (jobDraft.filePages?.edges) {
      jobDraft.filePages.edges = jobDraft.filePages.edges.map((filePage) => {
        const filePageHasDocFieldGroups = filePage?.node?.document?.documentFieldGroups?.edges
        if (filePageHasDocFieldGroups) {
          filePage!.node!.document!.documentFieldGroups!.edges =
            filePage!.node!.document!.documentFieldGroups!.edges.map((fieldGroup) => {
              const shouldFormatFieldGroup =
                fieldGroups.includes(fieldGroup?.node?.fieldGroup?.key || '') &&
                fieldGroup?.node?.documentFields?.edges
              if (!shouldFormatFieldGroup) {
                return fieldGroup
              }
              fieldGroup!.node!.documentFields!.edges = fieldGroup!.node!.documentFields!.edges.map(
                (docField: DocumentFieldNodeEdge) => {
                  const docFieldValue = docField?.node?.value
                  if (docFieldValue) {
                    docField!.node!.value = formatter(docFieldValue)
                  }
                  return docField
                },
              )
              return fieldGroup
            })
        }
        return filePage
      })
    }
  })

export const formatLineItems = (
  job: JobNode,
  fields: string[],
  formatter: (value: string) => string,
): JobNode =>
  produce(job, (jobDraft) => {
    if (jobDraft.filePages?.edges) {
      jobDraft.filePages.edges = jobDraft.filePages.edges.map((filePage) => {
        const filePageHasDocFieldGroups = filePage?.node?.document?.documentFieldGroups?.edges
        if (filePageHasDocFieldGroups) {
          filePage!.node!.document!.documentFieldGroups!.edges =
            filePage!.node!.document!.documentFieldGroups!.edges.map((fieldGroup) => {
              const shouldFormatFieldGroup =
                fieldGroup?.node?.fieldGroup?.repeatable && fieldGroup?.node?.documentFields?.edges
              if (!shouldFormatFieldGroup) {
                return fieldGroup
              }
              fieldGroup!.node!.documentFields!.edges = fieldGroup!.node!.documentFields!.edges.map(
                (docField: DocumentFieldNodeEdge) => {
                  const formatLineItemValue =
                    docField?.node?.value && fields.includes(docField?.node?.field?.key || '')
                  if (formatLineItemValue) {
                    docField!.node!.value = formatter(docField!.node!.value)
                  }
                  return docField
                },
              )
              return fieldGroup
            })
        }
        return filePage
      })
    }
  })
