import { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import GetAppIcon from '@material-ui/icons/GetApp'
import { makeStyles } from '@material-ui/styles'

import { ApiEDocumentNode, Maybe } from '@src/graphql/types'
import theme from '@src/utils/theme'

const useStyles = makeStyles({
  button: {
    marginBottom: theme.spacing(2),
  },
  tableHead: {
    backgroundColor: theme.palette.grey[200],
  },
})

const apiEdocsColumns = Object.freeze(['File Name', 'Actions'])

type Props = {
  apiEDocuments: ApiEDocumentNode[]
  selectApiEdocHandler: (apiEDocument: Maybe<ApiEDocumentNode>) => void
  deleteApiEdocHandler: (apiEDocumentId: string) => Promise<void>
  deleteApiEdocLoading: boolean
}

const ApiEDocsList: FunctionComponent<Props> = ({
  apiEDocuments,
  selectApiEdocHandler,
  deleteApiEdocHandler,
  deleteApiEdocLoading,
}) => {
  const classes = useStyles()

  return (
    <Box mb={2}>
      <Table size='small'>
        <TableHead classes={{ root: classes.tableHead }}>
          <TableRow>
            {apiEdocsColumns.map((col, idx) => {
              return <TableCell key={idx}>{col}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {apiEDocuments.length === 0 ? (
            <TableRow>
              <TableCell colSpan={apiEdocsColumns.length} align='center'>
                No eDocuments found
              </TableCell>
            </TableRow>
          ) : (
            <>
              {apiEDocuments.map((apiEDocument) => {
                return (
                  <TableRow key={apiEDocument.id}>
                    <TableCell size='small' width='60%'>
                      {apiEDocument.filename &&
                      apiEDocument.filename.toLowerCase().endsWith('.msg') ? (
                        <Link onClick={() => selectApiEdocHandler(apiEDocument)}>
                          <Typography>{apiEDocument.filename}</Typography>
                        </Link>
                      ) : (
                        <Link
                          href={apiEDocument.eDocUrl as string}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Typography>{apiEDocument.filename}</Typography>
                        </Link>
                      )}
                    </TableCell>
                    <TableCell size='small'>
                      <Box display='flex'>
                        <Link href={apiEDocument!.eDocUrl!} target='_blank' rel='noreferrer'>
                          <IconButton size='small' disableFocusRipple disableRipple>
                            <GetAppIcon />
                          </IconButton>
                        </Link>
                        <IconButton
                          size='small'
                          disabled={deleteApiEdocLoading}
                          disableFocusRipple
                          disableRipple
                          onClick={() => deleteApiEdocHandler(apiEDocument.id)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            </>
          )}
        </TableBody>
      </Table>
    </Box>
  )
}

export default ApiEDocsList
