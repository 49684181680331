import { useCallback, useEffect, useRef } from 'react'
import { FieldValues, FormState } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

export const useLeavePageConfirm = (
  isConfirm: boolean | (() => boolean) = true,
  message = 'You have unsaved changes that will be lost. Are you sure want to leave this page?',
): (() => void) => {
  const history = useHistory()
  const unblockRef = useRef<() => void>()

  useEffect(() => {
    const shouldConfirm = typeof isConfirm === 'boolean' ? isConfirm : isConfirm()
    if (shouldConfirm) {
      // TODO: nprogress?
      unblockRef.current = history.block(message)
      return unblockRef.current
    }
  })

  return () => unblockRef.current?.()
}

export const useLeaveFormConfirm = <T extends FieldValues>(
  formState: FormState<T>,
  message = 'You have unsaved changes that will be lost. Are you sure want to leave this page?',
): (() => void) => {
  const formChangesUnsubmitted = useCallback((): boolean => {
    return formState.isDirty
  }, [formState.isDirty])

  return useLeavePageConfirm(formChangesUnsubmitted, message)
}
