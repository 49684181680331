import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from '@apollo/client'

import { JOB_OPERATORS } from '@src/graphql/queries/job'
import { SET_JOB_QA } from '@src/graphql/mutations/job'
import { Mutation, MutationSetJobQaArgs, Query, UserNode } from '@src/graphql/types'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'

const useStyles = makeStyles({
  selectQA: {
    minWidth: '100%',
  },
  spacing: {
    padding: '10%',
  },
  buttonGrid: {
    justifyContent: 'center',
  },
})

type Props = {
  handleClickCancel: () => void
  handleClickDone: () => Promise<void>
  jobId: string
  open: boolean
  submitting: boolean
}

const AssignToQADialog: FunctionComponent<Props> = ({
  handleClickCancel,
  handleClickDone,
  jobId,
  open,
  submitting,
}) => {
  const classes = useStyles()
  const [qaId, setQAId] = useState(null as null | string)
  const [setJobQA] = useMutation<Pick<Mutation, 'setJobQa'>, MutationSetJobQaArgs>(SET_JOB_QA)
  const { data: operatorsData, loading: isLoadingOperators } =
    useQuery<Pick<Query, 'jobOperators'>>(JOB_OPERATORS)
  const operators = operatorsData?.jobOperators as UserNode[]
  const { enqueueSnackbar } = useSnackbar()

  const changeJobQA = async (currOperatorId: string): Promise<void> => {
    try {
      await setJobQA({
        variables: {
          jobId,
          qaId: currOperatorId,
        },
      })
      setQAId(currOperatorId)
      enqueueSnackbar('Successfully moved job to QA', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Failed to move job to QA: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    }
  }

  return (
    <Dialog maxWidth='sm' open={open} onClose={handleClickCancel} aria-labelledby='a'>
      <DialogTitle id='a'>You are about to send this job for QA</DialogTitle>
      <DialogContent>
        <ol>
          <li>
            Please reassign this job to your team lead so they can QA the output to be sent to the
            client
          </li>
          <li>
            If this is an RPA job, please make sure that the RPA has been run and the draft has been
            saved in the client systemoperators
          </li>
        </ol>
        <div className={classes.spacing}>
          <FormControl variant='outlined' className={classes.selectQA}>
            <InputLabel>Job QA</InputLabel>
            <Select
              value={qaId || ''}
              onChange={(e) => changeJobQA(e.target.value as string)}
              label='Job QA'
              variant='outlined'
              disabled={isLoadingOperators}
            >
              {operators?.map((currOperator: UserNode) => (
                <MenuItem key={currOperator.id} value={currOperator.id}>
                  {currOperator.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Grid className={classes.buttonGrid} container alignItems='flex-end' spacing={4}>
          <Grid item>
            <Button
              color='primary'
              variant='contained'
              onClick={handleClickDone}
              disabled={submitting}
            >
              Done
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' onClick={handleClickCancel} disabled={submitting}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default AssignToQADialog
