import { createContext, FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  RequestHandler,
} from '@apollo/client'
import { Auth0AccessTokenContext } from '@src/auth/Auth0AccessTokenContext'
import DebounceLink from 'apollo-link-debounce'
import { tracingFetch } from '@src/utils/observability/tracing'
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link'
import { captureGraphQL } from '@src/utils/observability/rollbar'

const DEFAULT_DEBOUNCE_TIMEOUT_MS = 100

/**
 * Context for handing in a graphql query tracker from a child element
 */
type GraphQLTracker = {
  setRequestHandler: (requestHandler: () => RequestHandler) => unknown
}
export const GraphQLTrackerContext = createContext({
  setRequestHandler: () => undefined,
} as GraphQLTracker)

const ApolloProviderWithClient: FunctionComponent = ({ children }) => {
  const auth0AccessToken = useContext(Auth0AccessTokenContext)
  const [requestHandler, setRequestHandler] = useState(
    () => ((operation, forward) => forward(operation)) as RequestHandler,
  )
  const cache = useMemo(() => new InMemoryCache(), [])
  const httpLink = useMemo(() => {
    // we don't want to keep the cached unauthenticated queries
    void cache.reset()
    let headers = {}
    if (auth0AccessToken) {
      headers = {
        Authorization: `Bearer ${auth0AccessToken}`,
      }
    }
    return createHttpLink({
      headers,
      fetch: tracingFetch,
    })
  }, [auth0AccessToken, cache])

  const createLink = (): ApolloLink => {
    return ApolloLink.from([
      new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT_MS),
      captureGraphQL,
      requestHandler,
      new MultiAPILink({
        endpoints: {
          cauldron: '/cauldron-api',
          beholder: '/beholder-api',
        },
        defaultEndpoint: 'cauldron',
        createHttpLink: () => httpLink,
      }),
    ])
  }
  // note that we keep the client and just `resetStore()` and `setLink()`, because
  // we don't want to create a new client when we just add in graphqlTracker
  const client = useMemo(() => {
    return new ApolloClient({ cache: cache, link: createLink() })
  }, [])

  // need to resetStore to force refetch of queries
  useEffect(() => {
    void client.resetStore()
  }, [auth0AccessToken])

  useEffect(() => {
    client.setLink(createLink())
  }, [auth0AccessToken, requestHandler, client])
  const graphqlTracker = useMemo(
    () => ({
      setRequestHandler,
    }),
    [setRequestHandler],
  )
  return (
    <ApolloProvider client={client}>
      <GraphQLTrackerContext.Provider value={graphqlTracker}>
        {children}
      </GraphQLTrackerContext.Provider>
    </ApolloProvider>
  )
}

export default ApolloProviderWithClient
