/* eslint-disable @typescript-eslint/no-var-requires */
import { Dispatch, FunctionComponent, SetStateAction, useContext, useState } from 'react'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import { makeStyles } from '@material-ui/styles'
import { batch, useDispatch, useSelector } from 'react-redux'
import { SidebarContext } from '@src/components/content-with-sidebar/sidebar-context'
import { resetLineItemsChanges, setJobTableActive } from '@src/redux-features/document_editor'
import { selectActiveFilePage } from '@src/redux-features/document_editor/file_page'
import { RootState } from '@src/utils/store'
import { DocumentTypeNode, FilePageType, JobNode, JobTemplateReconType } from '@src/graphql/types'
import theme from '@src/utils/theme'
import 'handsontable/dist/handsontable.full.css'
import ExtractTable from './ExtractTable'
import MainTable from './MainTable'
import SidebarCollapseControl from '../content-with-sidebar/SidebarCollapseControl'

type Props = {
  job: JobNode
  setIsUpdateChargeQuantityDialogOpen?: Dispatch<SetStateAction<boolean>>
}

export enum MainTab {
  MAIN = 'Main SOA',
  EXTRACTED = 'Extracted',
}

const mainLabelWithoutSoa = MainTab.MAIN.split(' ')[0]

const useStyles = makeStyles({
  tabRoot: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    opacity: 1,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  tabPanel: {
    padding: 0,
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '&[hidden]': {
      flex: '0',
    },
  },
})

const SpreadsheetDataGrid: FunctionComponent<Props> = ({
  job,
  setIsUpdateChargeQuantityDialogOpen,
}) => {
  const classes = useStyles()
  const { isCollapsed } = useContext(SidebarContext)

  const isJobTableActive = useSelector((state: RootState) => state.documentEditor.jobTableActive)
  const [currentTab, setCurrentTab] = useState(isJobTableActive ? MainTab.MAIN : MainTab.EXTRACTED)
  const filePage = useSelector((state: RootState) => selectActiveFilePage(state.documentEditor))!
  const activeDocument = filePage.document!
  const dispatch = useDispatch()

  const isSoa = job.jobTemplate.reconType === JobTemplateReconType.Soa
  const showMainTabForSoa = isSoa && filePage.type !== FilePageType.Excel
  const showMainTabForNonSoa =
    !isSoa && filePage.type !== FilePageType.Excel && job.jobTemplate.mainTabEnabled
  return (
    <Box display='flex' flexDirection='column' alignItems='stretch' height='100%'>
      <Box
        display={isCollapsed ? 'none' : 'flex'}
        flexDirection={'column'}
        alignItems={'stretch'}
        flexGrow={1}
      >
        {showMainTabForSoa || showMainTabForNonSoa ? (
          <TabContext value={currentTab}>
            <TabList
              variant='fullWidth'
              value={currentTab}
              onChange={(_, val) => {
                setCurrentTab(val)
                batch(() => {
                  dispatch(setJobTableActive(val === MainTab.MAIN))
                  dispatch(resetLineItemsChanges())
                })
              }}
            >
              <Tab
                classes={{ root: classes.tabRoot, selected: classes.selected }}
                label={job.jobTemplate.reconType !== 'SOA' ? mainLabelWithoutSoa : MainTab.MAIN}
                value={MainTab.MAIN}
              />
              <Tab
                classes={{ root: classes.tabRoot, selected: classes.selected }}
                label={MainTab.EXTRACTED}
                value={MainTab.EXTRACTED}
              />
            </TabList>
            <TabPanel className={classes.tabPanel} value={MainTab.MAIN}>
              <MainTable job={job} documentType={activeDocument.documentType!} />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={MainTab.EXTRACTED}>
              <ExtractTable
                job={job}
                setIsUpdateChargeQuantityDialogOpen={setIsUpdateChargeQuantityDialogOpen}
                documentType={activeDocument.documentType!}
              />
            </TabPanel>
          </TabContext>
        ) : (
          <SingleTabTable
            job={job}
            documentType={activeDocument.documentType!}
            isSoa={isSoa}
            setIsUpdateChargeQuantityDialogOpen={setIsUpdateChargeQuantityDialogOpen}
          />
        )}
      </Box>

      <Box marginLeft='auto' marginTop='auto'>
        <SidebarCollapseControl />
      </Box>
    </Box>
  )
}

const SingleTabTable: FunctionComponent<{
  isSoa: boolean
  job: JobNode
  documentType: DocumentTypeNode
  setIsUpdateChargeQuantityDialogOpen?: Dispatch<SetStateAction<boolean>>
}> = ({ isSoa, job, documentType, setIsUpdateChargeQuantityDialogOpen }) => {
  if (isSoa) return <MainTable job={job} documentType={documentType} />
  return (
    <ExtractTable
      job={job}
      documentType={documentType}
      setIsUpdateChargeQuantityDialogOpen={setIsUpdateChargeQuantityDialogOpen}
    />
  )
}

export default SpreadsheetDataGrid
