import { FunctionComponent } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import theme from '@src/utils/theme'

import { SpreadsheetDataColumn } from '@src/utils/data-grid'
import { DatabaseRecordType } from './record_type'
import ReadOnlyTable from './ReadOnlyTable'

type Props = {
  rows: string[][]
  columns: SpreadsheetDataColumn[]
  setOpen: (open: boolean) => void
  confirmDelete: () => void
  databaseRecordType: DatabaseRecordType
  open: boolean
}

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

const DeleteDatabaseModal: FunctionComponent<Props> = ({
  rows,
  columns,
  setOpen,
  confirmDelete,
  databaseRecordType,
  open,
}: Props) => {
  const classes = useStyles()

  const closeDialog = (): void => {
    setOpen(false)
  }

  return (
    <Dialog classes={{ paper: classes.dialog }} open={open}>
      <Box className={classes.close}>
        <IconButton onClick={closeDialog} data-testid='close-btn'>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle>Delete {databaseRecordType}</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete this {databaseRecordType.toLowerCase()}? Please review
          before confirming.
        </Typography>
        <Box>
          <ReadOnlyTable rows={rows} columns={columns} />
        </Box>
        <Box display='flex' justifyContent='center' p={3}>
          <Box m={3}>
            <Button onClick={closeDialog} variant='contained'>
              Cancel
            </Button>
          </Box>
          <Box m={3}>
            <Button onClick={confirmDelete} variant='contained' color='primary'>
              Delete
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteDatabaseModal
