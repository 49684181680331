import { FunctionComponent, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import theme from '@src/utils/theme'

const uploadBoxHeight = '100px'

const useStyles = makeStyles({
  uploadBox: {
    display: 'flex',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.grey['500'],
    outline: 'none',
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    height: uploadBoxHeight,
    width: '80%',
  },
  uploadBoxDesc: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
  },
})

type Props = {
  setFiles: (files: File[]) => unknown
}

const Dropzone: FunctionComponent<Props> = ({ setFiles }: Props) => {
  const classes = useStyles()
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(acceptedFiles)
    },
    [setFiles],
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  })

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper variant='outlined' className={classes.uploadBox} {...getRootProps()}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} data-testid='dropzone-input-generic' />
        <Typography variant='body1' className={classes.uploadBoxDesc}>
          Drag files into box or click to upload.
        </Typography>
      </Paper>
    </>
  )
}

export default Dropzone
