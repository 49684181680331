import { FunctionComponent, useState } from 'react'
import TaskDetail from '@src/components/task-detail/TaskDetail'
import TaskJobs from '@src/components/task-jobs/TaskJobs'
import { Box, Button, Dialog, DialogContent, makeStyles, Tab, Theme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import HeightIcon from '@material-ui/icons/Height'
import theme from '@src/utils/theme'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'

enum TabValues {
  JOBS = 'Jobs',
  TASK_INFO = 'Task Info',
}

type Props = {
  taskId: string
  isOpen: boolean
  closePopup: () => void
}

const useStyles = makeStyles<Theme>({
  expandIcon: {
    transform: 'rotate(45deg)',
  },
  dialog: {
    maxWidth: '75vw',
  },
  tabRoot: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    opacity: 1,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
})

const SingleTaskDialog: FunctionComponent<Props> = ({ taskId, isOpen, closePopup }: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const [shownTab, setShownTab] = useState(TabValues.JOBS)

  return (
    <Dialog classes={{ paper: classes.dialog }} open={isOpen} onClose={closePopup} fullWidth>
      <div>
        <Box p={1}>
          <Button
            className={classes.openAsPageButton}
            size='small'
            href={`/tasks/${taskId}`}
            startIcon={<HeightIcon className={classes.expandIcon} color='disabled' />}
          >
            <Box color='text.disabled'>Open as page</Box>
          </Button>
        </Box>
      </div>
      <div className={classes.close}>
        <CloseIcon fontSize='large' onClick={closePopup} data-testid='close-btn' />
      </div>
      <DialogContent>
        <TabContext value={shownTab}>
          <TabList variant='fullWidth' onChange={(_event, newValue) => setShownTab(newValue)}>
            <Tab
              classes={{ root: classes.tabRoot, selected: classes.selected }}
              label='Jobs'
              value={TabValues.JOBS}
            />
            <Tab
              classes={{ root: classes.tabRoot, selected: classes.selected }}
              label='Task Info'
              value={TabValues.TASK_INFO}
            />
          </TabList>
          <TabPanel value={TabValues.JOBS}>
            <TaskJobs taskId={taskId} />
          </TabPanel>
          <TabPanel value={TabValues.TASK_INFO}>
            <TaskDetail taskId={taskId} />
          </TabPanel>
        </TabContext>
      </DialogContent>
    </Dialog>
  )
}

export default SingleTaskDialog
