import Title from '@src/components/Title'
import { ActionSet } from '@src/components/shipment-form/ShipmentActions'
import JobEditor from '@src/components/job-editor'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'

const QAPage: FunctionComponent = () => {
  const { id: jobId } = useParams<{ id: string }>()
  return (
    <>
      <Title title='QA Job' />
      <JobEditor jobId={jobId} actionSet={ActionSet.QA} />
    </>
  )
}

export default QAPage
