import React, {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Moveable, { OnDragEnd, OnResizeEnd } from 'react-moveable'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { Theme } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import ClearIcon from '@material-ui/icons/Clear'
import OpenWithIcon from '@material-ui/icons/OpenWith'
import { makeStyles } from '@material-ui/styles'
import { BoxInfo } from '@src/types/ocr'
import { EXTRACT_TABLE_SLEEP, MAGIC_GRID_DIMENSIONS } from '@src/utils/app_constants'
import theme from '@src/utils/theme'
import { useDispatch, useSelector } from 'react-redux'
import {
  dragGrid,
  readMagicGrid,
  resizeGrid,
  setGridColumnKey,
} from '@src/redux-features/document_editor'
import {
  selectActiveMagicGrid,
  TableExtractionStep,
} from '@src/redux-features/document_editor/magic_grid'
import { selectRepeatableFieldsFromJobOrDocTable } from '@src/redux-features/document_editor/field'
import { columnSelectors, rowSelectors } from '@src/redux-features/document_editor/magic_grid'
import { RootState } from '@src/utils/store'
import { Skeleton } from '@material-ui/lab'
import ColumnControls from './ColumnControls'
import ColumnRuler from './ColumnRuler'
import RowControls from './RowControls'
import RowRuler from './RowRuler'
import GridRow from './GridRow'

type StyleProps = {
  dimension: BoxInfo
  imageWidth: number
  imageHeight: number
}

const useStyles = makeStyles<Theme, StyleProps>({
  magicGrid: {
    position: 'absolute',
    backgroundColor: alpha(theme.palette.primary.light, 0.2),
    border: `1px dashed ${theme.palette.grey['600']}`,
    top: (props) => `${props.dimension.top * props.imageHeight}px`,
    left: (props) => `${props.dimension.left * props.imageWidth}px`,
    width: (props) => `${props.dimension.width * props.imageWidth}px`,
    height: (props) => `${props.dimension.height * props.imageHeight}px`,
    zIndex: MAGIC_GRID_DIMENSIONS.BASE_Z_INDEX,
  },
  moveable: {
    zIndex: `${MAGIC_GRID_DIMENSIONS.BASE_Z_INDEX + 1} !important` as unknown as number,
  },
  dragWidget: {
    position: 'absolute',
    top: (props) => `${props.dimension.top * props.imageHeight - theme.spacing(4)}px`,
    left: (props) => `${props.dimension.left * props.imageWidth - theme.spacing(4)}px`,
  },
  removeColumnMenuItem: {
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.light, 0.7),
    },
  },
  skeleton: {
    backgroundColor: alpha(theme.palette.success.main, 0.3),
    width: (props) => `${props.dimension.width * props.imageWidth}px`,
    height: (props) => `${props.dimension.height * props.imageHeight}px`,
    zIndex: 99999,
  },
})

type Props = {
  imageBoxRef: MutableRefObject<HTMLImageElement | null>
  fileViewerSize: { width: number | null; height: number | null }
  imageScale: number
}

const moveableRenderDirections = ['se']

const MagicGrid: FunctionComponent<Props> = ({ imageBoxRef, fileViewerSize, imageScale }) => {
  const dispatch = useDispatch()
  const columns = useSelector(
    (state: RootState) => columnSelectors.selectAll(state.documentEditor)!,
  )
  const columnKeys = columns.map((column) => column.key!)
  const columnIds = columns.map((column) => column.id)
  const rowIds = useSelector(
    (state: RootState) => rowSelectors.selectIds(state.documentEditor)!,
  ) as string[]

  const [isResizing, setIsResizing] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null as HTMLButtonElement | null)
  const [activeColumnId, setActiveColumnId] = useState(null as string | null)
  const activeColumnKey = useSelector(
    (state: RootState) =>
      (activeColumnId != null &&
        columnSelectors.selectById(state.documentEditor, activeColumnId)?.key) ||
      undefined,
  )
  const gridDimension = useSelector(
    (state: RootState) => selectActiveMagicGrid(state.documentEditor)!.dimension!,
  )
  const magicGridReadOnly = useSelector(
    (state: RootState) => state.documentEditor.tableEditModeEnabled,
  )
  const currentTableExtractionStep = useSelector(
    (state: RootState) => state.documentEditor.currentTableExtractionStep,
  )
  const repeatableFields = useSelector((state: RootState) =>
    selectRepeatableFieldsFromJobOrDocTable(state.documentEditor),
  )
  const moveableRef = useRef(null as Moveable | null)
  const magicGridRef = useRef(null as HTMLDivElement | null)
  const dragWidgetRef = useRef(null as HTMLButtonElement | null)
  // TODO: this probably breaks on window resize since these are refs
  const [imageWidth, setImageWidth] = useState(imageBoxRef.current?.width || 0)
  const [imageHeight, setImageHeight] = useState(imageBoxRef.current?.height || 0)
  const classes = useStyles({ dimension: gridDimension, imageWidth, imageHeight })
  const gridElement = magicGridRef.current
  const widgetElement = dragWidgetRef.current
  const gridAndWidgetExists = gridElement && widgetElement

  useEffect(() => {
    if (gridAndWidgetExists) {
      gridElement!.style.width = `${gridDimension.width * imageWidth}px`
      gridElement!.style.height = `${gridDimension.height * imageHeight}px`
    }
    moveableRef?.current?.updateRect()
  }, [gridElement, gridDimension, gridAndWidgetExists, imageWidth, imageHeight])

  // need to do this so that the grid re-renders after the grid ref is populated,
  // which then lets the Moveable to render
  const [, setInitialized] = useState(false)
  useEffect(() => {
    setTimeout(() => setInitialized(true))
  }, [])

  const endDragGridHandler = useCallback(
    (evt: OnDragEnd): void => {
      if (gridAndWidgetExists) {
        const [deltaX, deltaY] = evt.lastEvent.dist
        dispatch(dragGrid(deltaX / imageWidth, deltaY / imageHeight))
        gridElement!.style.transform = 'none'
        widgetElement!.style.transform = 'none'
        moveableRef?.current?.updateRect()
      }
    },
    [gridAndWidgetExists, dispatch, imageWidth, imageHeight, gridElement, widgetElement],
  )

  const endScaleGridHandler = useCallback(
    (evt: OnResizeEnd): void => {
      setIsResizing(false)
      if (gridAndWidgetExists) {
        const { width, height } = evt.lastEvent
        dispatch(
          resizeGrid({
            left: gridDimension.left,
            top: gridDimension.top,
            width: width / imageWidth,
            height: height / imageHeight,
          }),
        )
        gridElement!.style.transform = 'none'
      }
    },
    [
      gridAndWidgetExists,
      dispatch,
      gridDimension.left,
      gridDimension.top,
      imageWidth,
      imageHeight,
      gridElement,
    ],
  )

  const onDragStart = useCallback(() => {
    if (gridAndWidgetExists) {
      widgetElement!.style.transform = 'none'
      gridElement!.style.transform = 'none'
    }
  }, [widgetElement, gridElement, gridAndWidgetExists])
  const onDrag = useCallback(
    (evt) => {
      if (gridAndWidgetExists) {
        gridElement!.style.transform = evt.transform
        widgetElement!.style.transform = evt.transform
      }
    },
    [widgetElement, gridElement, gridAndWidgetExists],
  )
  const onResizeStart = useCallback(() => setIsResizing(true), [])
  const onResize = useCallback(
    (evt) => {
      if (gridAndWidgetExists) {
        gridElement!.style.width = `${evt.width}px`
        gridElement!.style.height = `${evt.height}px`
      }
    },

    [gridElement, gridAndWidgetExists],
  )

  const handleColumnSelect = useCallback(
    (key: string | null): void => {
      if (activeColumnId !== null) {
        dispatch(setGridColumnKey(activeColumnId, key))
        setAnchorEl(null)
      }
    },
    [dispatch, activeColumnId],
  )

  useEffect(() => {
    setImageWidth(imageBoxRef.current?.width || 0)
    setImageHeight(imageBoxRef.current?.height || 0)
    moveableRef?.current?.updateRect()

    const controlBox = moveableRef?.current?.moveable?.controlBox?.element
    if (controlBox) {
      controlBox.style.zIndex = `${MAGIC_GRID_DIMENSIONS.CONTROLS_Z_INDEX}`
    }
  }, [fileViewerSize.width, fileViewerSize.height, imageBoxRef])

  useEffect(() => {
    moveableRef?.current?.updateRect()
  }, [imageWidth, imageHeight])

  useEffect(() => {
    const readMagicGridAsync = async (): Promise<void> => {
      // Async sleep function using a timeout
      const sleep = (ms: number): Promise<void> => {
        return new Promise((resolve) => setTimeout(resolve, ms))
      }

      await sleep(EXTRACT_TABLE_SLEEP)
      dispatch(readMagicGrid())
    }
    if (currentTableExtractionStep === TableExtractionStep.Extracting) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      readMagicGridAsync()
    }
  }, [dispatch, currentTableExtractionStep])

  return (
    <>
      {magicGridReadOnly || (
        <>
          <Moveable
            className={classes.moveable}
            ref={moveableRef}
            target={magicGridRef.current}
            dragTarget={dragWidgetRef.current}
            origin={false}
            draggable={!magicGridReadOnly}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragEnd={endDragGridHandler}
            resizable={!magicGridReadOnly}
            onResizeStart={onResizeStart}
            onResize={onResize}
            onResizeEnd={endScaleGridHandler}
            renderDirections={moveableRenderDirections}
          />

          <IconButton className={classes.dragWidget} ref={dragWidgetRef} size='small'>
            <OpenWithIcon />
          </IconButton>
        </>
      )}

      <div ref={magicGridRef} className={classes.magicGrid}>
        {magicGridReadOnly || (
          <>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              keepMounted
              onClose={() => setAnchorEl(null)}
            >
              {repeatableFields
                ?.filter((repeatableField) => !columnKeys.includes(repeatableField.key))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({ key, name }) => {
                  return (
                    <MenuItem
                      key={`action-${key}`}
                      onClick={() => handleColumnSelect(activeColumnKey === key ? null : key || '')}
                    >
                      <Box
                        width='100%'
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                      >
                        <Typography>{name}</Typography>
                        {activeColumnKey === key && <ClearIcon fontSize='inherit' />}
                      </Box>
                    </MenuItem>
                  )
                })}
            </Menu>

            <ColumnControls imageBoxRef={imageBoxRef} imageScale={imageScale} />
            {!isResizing &&
              imageWidth &&
              columnIds.map((id: string) => {
                return (
                  <ColumnRuler
                    key={id}
                    columnId={id}
                    setAnchorEl={setAnchorEl}
                    imageWidth={imageWidth}
                    handleDropdownToggle={setActiveColumnId}
                  />
                )
              })}
            <RowControls imageBoxRef={imageBoxRef} imageScale={imageScale} />
          </>
        )}

        {!isResizing &&
          imageHeight &&
          imageWidth &&
          rowIds.map((id) => {
            return (
              <React.Fragment key={id}>
                {magicGridReadOnly || <RowRuler rowId={id} imageHeight={imageHeight} />}
                <GridRow rowId={id} imageWidth={imageWidth} imageHeight={imageHeight} />
              </React.Fragment>
            )
          })}
        {currentTableExtractionStep !== TableExtractionStep.Idle && (
          <Skeleton variant='rect' className={classes.skeleton} />
        )}
      </div>
    </>
  )
}

export default MagicGrid
