import { FunctionComponent } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { JobNode, Query, QueryUsersByRoleArgs, UserRole } from '@src/graphql/types'
import { USERS_BY_ROLE } from '@src/graphql/queries/user'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { SET_JOB_QA } from '@src/graphql/mutations/job'

type Props = {
  refetchJobs: () => unknown
  job: JobNode
}

const useStyles = makeStyles({
  option: { fontSize: 14 },
})

const JobQASelect: FunctionComponent<Props> = ({ refetchJobs, job }) => {
  const classes = useStyles()
  const client = useApolloClient()
  const { loading, data } = useQuery<Pick<Query, 'usersByRole'>, QueryUsersByRoleArgs>(
    USERS_BY_ROLE,
    {
      variables: { role: UserRole.Operator },
    },
  )
  const options = loading || data?.usersByRole == null ? [job.qa ?? ''] : data.usersByRole

  return (
    <Autocomplete
      classes={{ option: classes.option, input: classes.option }}
      options={options}
      value={job.qa ?? ''}
      getOptionLabel={(option) => (typeof option !== 'string' ? option?.email : '')}
      style={{ width: 150 }}
      disableClearable
      onChange={async (_, newQa) => {
        if (typeof newQa !== 'string') {
          await client.mutate({
            mutation: SET_JOB_QA,
            variables: {
              jobId: job.id,
              qaId: newQa.id,
            },
          })
          refetchJobs()
        }
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  )
}

export default JobQASelect
