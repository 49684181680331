import { createContext, FunctionComponent, useMemo, useState } from 'react'

type SidebarContextType = {
  isCollapsed: boolean
  setIsCollapsed: (isCollapsed: boolean) => void
}

export const SidebarContext = createContext({} as SidebarContextType)

export const SidebarProvider: FunctionComponent = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const sidebarContextValue = useMemo(() => {
    return {
      isCollapsed,
      setIsCollapsed,
    }
  }, [isCollapsed])

  return <SidebarContext.Provider value={sidebarContextValue}>{children}</SidebarContext.Provider>
}
