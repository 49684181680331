import { useMemo, useState } from 'react'
import { TransformWrapper } from 'react-zoom-pan-pinch'

export const useZoomControlBar = (): {
  scrollZoomEnabled: boolean
  setScrollZoomEnabled: (enabled: boolean) => unknown
  panningEnabled: boolean
  setPanningEnabled: (enabled: boolean) => unknown
  panOptions: Parameters<typeof TransformWrapper>[0]['pan']
  wheelOptions: Parameters<typeof TransformWrapper>[0]['wheel']
  doubleClickOptions: Parameters<typeof TransformWrapper>[0]['doubleClick']
  transformWrapperOptions: Parameters<typeof TransformWrapper>[0]['options']
} => {
  const [panningEnabled, setPanningEnabled] = useState(false)
  const [scrollZoomEnabled, setScrollZoomEnabled] = useState(false)
  const panOptions = useMemo(
    () => ({
      disabled: !panningEnabled,
    }),
    [panningEnabled],
  )
  const wheelOptions = useMemo(
    () => ({
      disabled: !scrollZoomEnabled,
    }),
    [scrollZoomEnabled],
  )
  const doubleClickOptions = useMemo(
    () => ({
      disabled: true,
    }),
    [],
  )
  const transformWrapperOptions = useMemo(
    () => ({
      minScale: 0.25,
      maxScale: 16,
    }),
    [],
  )
  return {
    panningEnabled,
    setPanningEnabled,
    scrollZoomEnabled,
    setScrollZoomEnabled,
    panOptions,
    wheelOptions,
    doubleClickOptions,
    transformWrapperOptions,
  }
}
