import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent, useState } from 'react'
import { Box, DialogContentText, IconButton, Theme, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

import theme from '@src/utils/theme'
import { useMutation } from '@apollo/client'
import { Mutation, MutationDeleteJobTemplateArgs, JobTemplateNode } from '@src/graphql/types'
import { DELETE_JOB_TEMPATE } from '@src/graphql/mutations/job'
import { useSnackbar } from 'notistack'

type Props = {
  jobTemplate: JobTemplateNode
  refetchJobTemplates: () => void
  setJobTemplateToDelete: (jobTemplate: JobTemplateNode | null) => void
}

const useStyles = makeStyles<Theme>({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
})

const DeleteCompanyDialog: FunctionComponent<Props> = ({
  jobTemplate,
  refetchJobTemplates,
  setJobTemplateToDelete,
}) => {
  const classes = useStyles()
  const [isDeleteing, setIsDeleting] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [deleteJobTemplateMutation] = useMutation<
    Pick<Mutation, 'deleteJobTemplate'>,
    MutationDeleteJobTemplateArgs
  >(DELETE_JOB_TEMPATE)
  const closePopup = (): void => setJobTemplateToDelete(null)
  const deleteCompany = async (): Promise<void> => {
    try {
      setIsDeleting(true)
      await deleteJobTemplateMutation({ variables: { jobTemplateId: jobTemplate.id } })
      refetchJobTemplates()
      enqueueSnackbar('Successfully deleted job template', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Failed to delete job type: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    } finally {
      closePopup()
      setIsDeleting(false)
    }
  }
  return (
    <Dialog open>
      <DialogTitle disableTypography>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h3' className={classes.title}>
            Delete {jobTemplate.name}
          </Typography>
          <IconButton onClick={closePopup}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column' height='100%'>
          <DialogContentText>
            Do you want to delete this job template? This will delete all properties and fields of
            this job type. This action cannot be undone.
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closePopup}>Cancel</Button>
        <Button variant='contained' color='primary' disabled={isDeleteing} onClick={deleteCompany}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteCompanyDialog
