import { gql } from '@apollo/client'

export const CREATE_DOCUMENT = gql`
  mutation createDocument(
    $filePageId: UUID!
    $documentTypeId: UUID!
    $jobId: UUID!
    $batchId: UUID!
  ) {
    createDocument(
      filePageId: $filePageId
      documentTypeId: $documentTypeId
      jobId: $jobId
      batchId: $batchId
    ) {
      ok
    }
  }
`

export const BATCH_ATTACH_DOCUMENT_TO_SHIPMENT = gql`
  mutation batchAttachDocumentToShipment(
    $documents: [InputDocument!]!
    $jobId: String!
    $validateFields: Boolean!
    $newJobStatus: String
    $jobTable: InputJobTable
  ) {
    batchAttachDocumentToShipment(
      documents: $documents
      jobId: $jobId
      validateFields: $validateFields
      newJobStatus: $newJobStatus
      jobTable: $jobTable
    ) {
      job {
        id
      }
    }
  }
`

export const SAVE_DOCUMENT_FIELDS = gql`
  mutation saveDocumentFields($jobId: String!, $documents: [InputDocument!]!) {
    saveDocumentFields(jobId: $jobId, documents: $documents) {
      ok
    }
  }
`

export const SAVE_DOCUMENT_TABLE = gql`
  mutation saveDocumentTable(
    $jobId: String!
    $documentTable: InputDocumentTable!
    $validateFields: Boolean!
  ) {
    saveDocumentTable(
      jobId: $jobId
      documentTable: $documentTable
      validateFields: $validateFields
    ) {
      ok
    }
  }
`

export const UPSERT_DOC_TABLE_FIELD_GROUP = gql`
  mutation upsertDocumentTableFieldGroup($documentTableId: String!, $fieldGroupId: String!) {
    upsertDocumentTableFieldGroup(documentTableId: $documentTableId, fieldGroupId: $fieldGroupId) {
      ok
    }
  }
`

export const ADD_DOCUMENT_TABLE = gql`
  mutation addDocumentTable($documentId: UUID!, $fieldGroupId: UUID!) {
    addDocumentTable(documentId: $documentId, fieldGroupId: $fieldGroupId) {
      prevTableId
      documentTable {
        id
        documentId
        fieldGroupId
        orderPriority
        nextTableId
        editedByUser
        autofillMethod
        predConfidence
        document {
          id
          documentType {
            id
            tableShowsPreset
          }
        }
        documentFieldGroups {
          edges {
            node {
              id
              document {
                id
              }
              fieldGroup {
                id
                repeatable
              }
              documentFields {
                edges {
                  node {
                    id
                    field {
                      id
                      autofillKey
                      key
                      name
                    }
                    value
                    confirmed
                    top
                    left
                    width
                    height
                    confidenceScore
                  }
                }
              }
            }
          }
        }
        documentTableColumns {
          edges {
            node {
              id
              field {
                id
                key
              }
            }
          }
        }
        fieldGroup {
          id
          name
          key
          fields {
            edges {
              node {
                id
                allowFreeText
                autofillKey
                columnOrder
                dateFormatString
                fieldType {
                  ... on FallbackValue {
                    fallbackValue
                    isFallback
                  }
                  ... on FieldTypeEnum {
                    value
                    isFallback
                  }
                }
                invalidCharsRegex
                key
                name
                partialTableAllowed
                required
                searchableRecord {
                  id
                  searchKey
                  displayKey
                  model
                }
                validatorDescription
                validatorRegex
              }
            }
          }
        }
      }
    }
  }
`

export const DELETE_DOCUMENT_TABLE = gql`
  mutation deleteDocumentTable($documentTableId: String!) {
    deleteDocumentTable(documentTableId: $documentTableId) {
      ok
    }
  }
`

export const DELETE_DOCUMENT_TABLE_GROUP = gql`
  mutation deleteDocumentTablesInGroup($documentTableId: String!) {
    deleteDocumentTablesInGroup(documentTableId: $documentTableId) {
      ok
    }
  }
`

export const UNMERGE_DOCUMENT_TABLE = gql`
  mutation unmergeDocumentTable($documentTableId: String!) {
    unmergeDocumentTable(documentTableId: $documentTableId) {
      ok
    }
  }
`

export const UPDATE_DOC_TABLES_ORDER = gql`
  mutation updateDocumentTablesOrder($documentTableIds: [String!]!) {
    updateDocumentTablesOrder(documentTableIds: $documentTableIds) {
      ok
    }
  }
`

export const MERGE_TABLES = gql`
  mutation MergeTable($tableIds: [String!]!) {
    mergeTable(tableIds: $tableIds) {
      id
    }
  }
`

export const COPY_TABLE_FROM_OTHER_DOCUMENT = gql`
  mutation copyTableFromOtherDocument($fromDocId: UUID!, $toDocIds: [UUID!]!) {
    copyTableFromOtherDocument(fromDocId: $fromDocId, toDocIds: $toDocIds) {
      ok
    }
  }
`

export const COPY_TABLE_TO_DOCUMENTS = gql`
  mutation CopyTableToDocuments($tables: [InputDocumentTableSkeleton!]!, $toDocIds: [UUID!]!) {
    copyTableToDocuments(tables: $tables, toDocIds: $toDocIds) {
      ok
    }
  }
`

export const COPY_ROWS_TO_DOCUMENTS = gql`
  mutation CopyRowsToDocumentsTemplateMatch(
    $fromDocId: UUID!
    $tables: [InputDocumentTableSkeleton!]!
    $toDocIds: [UUID!]!
    $allDocIds: [UUID!]!
  ) {
    copyRowsToOtherDocuments(
      allDocIds: $allDocIds
      fromDocId: $fromDocId
      tables: $tables
      toDocIds: $toDocIds
    ) {
      ok
    }
  }
`
