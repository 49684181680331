import { JobNode } from '@src/graphql/types'

// max length of the variable part of the csv bulk export filename,
// just to avoid hitting filename length limits
export const CSV_BULK_EXPORT_MAX_FILENAME_LENGTH = 64

/**
 * validates the list of jobs as valid for bulk export; otherwise, throws an error
 */
export const validateForBulkExport = (jobs: JobNode[]): void => {
  if (!jobs.length) {
    throw new Error('Please select at least one job to bulk export')
  }
  const jobTemplate = jobs[0].jobTemplate!
  if (!jobs.every((job) => job.jobTemplate!.id === jobTemplate.id)) {
    throw new Error('Please ensure that all jobs to bulk export are of the same type')
  }
}
