import { formatMaybeApolloError } from '@src/utils/errors'
import { FunctionComponent } from 'react'
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { format } from 'date-fns'
import { clsx } from 'clsx'
import {
  InputTaskFilter,
  JobNodeEdge,
  ManualProduct,
  Mutation,
  MutationUpdateTaskStatusArgs,
  Query,
  TaskFilterColumn,
  TaskFilterOperation,
  TaskFilterSettingNode,
  TaskFilterColumnEnum,
  TaskNode,
  TaskStatus,
} from '@src/graphql/types'
import {
  Box,
  Button,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import TaskCard from '@src/components/tasks/TaskCard'
import SearchIcon from '@material-ui/icons/Search'
import theme from '@src/utils/theme'
import CreateTask from '@src/components/tasks/CreateTask'
import TaskColumnSkeleton from '@src/components/TaskColumnSkeleton'
import { GET_TASK_SEARCH_PARAMETERS, TASKS_UP_TO_PAGE } from '@src/graphql/queries/task'
import { UPDATE_TASK_STATUS } from '@src/graphql/mutations/task'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import grey from '@material-ui/core/colors/grey'
import { getJobsBeforeStatus, validateJobsForBulkUpdate } from '@src/utils/task'
import MoveJobStatusDialog from '@src/components/tasks/MoveJobStatusDialog'
import TaskFilterMenu, { TaskFilter } from '@src/components/task-filter-menu/TaskFilterMenu'
import DeleteTaskDialog from '@src/components/tasks/DeleteTaskDialog'
import IdentifyTaskDialog from '@src/components/tasks/IdentifyTaskDialog'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { pick, isEqual, debounce } from 'lodash'
import {
  COLORS,
  MASTER_TASK_VIEW_SYNC_BASE_INTERVAL,
  SECONDS_IN_MS,
  TASK_VIEW_SYNC_OFFSET,
} from '@src/utils/app_constants'
import produce from 'immer'
import TaskDateConfirmedDialog from '@src/components/task-date-confirmed-dialog/TaskDateConfirmedDialog'
import randomInt from '@src/utils/random'
import { parseDateString } from '@src/utils/date'
import { DeployEnvironment, getDeployEnvironment } from '@src/utils/environment'

const SEARCH_BAR_WIDTH = 400
const searchDebounceMs = 250

const useStyles = makeStyles({
  root: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    width: SEARCH_BAR_WIDTH,
  },
  searchPaper: {
    marginBottom: theme.spacing(3),
  },
  searchIconPrimary: {
    color: theme.palette.secondary.main,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: theme.spacing(3),
    margin: theme.spacing(1),
  },
  colHeader: {
    display: 'flex',
    width: '80%',
  },
  colBody: {
    margin: theme.spacing(1),
  },
  colName: {
    paddingRight: `${theme.spacing(3)}px`,
  },
  center: {
    width: '100%',
    textAlign: 'center',
  },
  dragDropWrapper: {
    display: 'inline-flex',
    justifyContent: 'center',
    height: '100%',
  },
  columnHeaderBox: {
    width: '90%',
    backgroundColor: COLORS.PALE_BLUE,
    padding: `${theme.spacing(1)}px`,
    color: grey[800],
    borderRadius: `${theme.spacing(2)}px ${theme.spacing(2)}px 0px 0px`,
  },
  ctaButton: {
    marginLeft: theme.spacing(2),
  },
})

type Column = { name: string; items: TaskNode[] }
type Columns = Record<string, Column>

const getInitialColumns = (): Record<string, Column> => ({
  [TaskStatus.Todo]: {
    name: 'To Do',
    items: [],
  },
  [TaskStatus.InProgress]: {
    name: 'In Progress',
    items: [],
  },
  [TaskStatus.Qa]: {
    name: 'QA',
    items: [],
  },
  [TaskStatus.Confirmation]: {
    name: 'Confirmation',
    items: [],
  },
  [TaskStatus.Done]: {
    name: 'Done',
    items: [],
  },
})

const TasksPage: FunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [columns, setColumns] = useState(getInitialColumns)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [moveDialogOpen, setMoveDialogOpen] = useState(false)
  const [taskDateConfirmedDialogOpen, setTaskDateConfirmedDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [isIdentifyTaskDialogOpen, setIsIdentifyTaskDialogOpen] = useState(false)
  const [currTask, setCurrTask] = useState(null as null | TaskNode)
  const [jobsBelowStatus, setJobsBeforeStatus] = useState([] as JobNodeEdge[])
  const [moveCallback, setMoveCallback] = useState(() => () => {})
  const [moveStatus, setMoveStatus] = useState('')
  const [searchTrigger, setSearchTrigger] = useState(false)
  const [taskPageNum, setTaskPageNum] = useState(0)
  const [timeLastSync, setTimeLastSync] = useState(null as null | Date)
  const [colCounts, setColCounts] = useState({} as Record<string, number>)
  const colRefs = useRef({} as Record<string, MutableRefObject<HTMLDivElement | null>>)

  /*
  To prevent unnecessary (and constly) refetching of task pages:
  1. We only fetch taskPagesData after the task filters gets fetched from the server.
      More specifically, we only do this when taskFilters is not undefined anymore.
  2. We use buffers for the task query and filters. We then only update them when the
      user types in the search bar and clicks the search icon or presses ENTER.
  3. We have a default task filter (Date and Time Created) which filters out tasks
      that are older than a month old. This filter is removable & editable but does
      not persist across page loads.
  */
  const [taskQuery, setTaskQuery] = useState(null as null | string)
  const [taskQueryBuffer, setTaskQueryBuffer] = useState(null as null | string)
  const dateMonthAgo = new Date(new Date().setDate(new Date().getDate() - 30))
  const defaultDateCreatedTaskFilter = {
    id: uuidv4(),
    column: TaskFilterColumn.DateCreated,
    operation: TaskFilterOperation.After,
    value: [format(dateMonthAgo, 'yyyy-MM-dd')] as string[],
  } as TaskFilter
  const [taskFilters, setTaskFilters] = useState(undefined as undefined | TaskFilter[])
  const [taskFiltersBuffer, setTaskFiltersBuffer] = useState(undefined as undefined | TaskFilter[])

  useQuery<Pick<Query, 'taskSearchParameters'>>(GET_TASK_SEARCH_PARAMETERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTaskQuery(data.taskSearchParameters.queryString)
      setTaskQueryBuffer(data.taskSearchParameters.queryString)
      const newFilters = [
        ...(data.taskSearchParameters.filters as unknown as TaskFilterSettingNode[]),
      ]
      let validFilters = newFilters
        .filter((taskFilter) => !taskFilter.column.isFallback)
        .map((taskFilter) => ({
          ...taskFilter,
          column: (taskFilter.column as TaskFilterColumnEnum).value,
        })) as TaskFilter[]
      if (getDeployEnvironment() !== DeployEnvironment.DEVELOPMENT) {
        validFilters = [...validFilters, defaultDateCreatedTaskFilter]
      }
      setTaskFilters(validFilters)
      setTaskFiltersBuffer(validFilters)
    },
  })

  const [updateTaskStatus] = useMutation<
    Pick<Mutation, 'updateTaskStatus'>,
    MutationUpdateTaskStatusArgs
  >(UPDATE_TASK_STATUS)

  const isValidInputTaskFilter = (
    filter: Omit<TaskFilter, 'id'>,
  ): filter is Readonly<InputTaskFilter> => {
    return !!filter.value?.length && !!filter.operation && !!filter.column
  }

  const getFilters = useCallback((allTaskFilters: TaskFilter[] | undefined): InputTaskFilter[] => {
    if (allTaskFilters === undefined) return []
    const adjustTaskFilterDates = (taskFilter: InputTaskFilter): InputTaskFilter => {
      taskFilter.value = [parseDateString(taskFilter.value[0]).toUTCString()]
      return taskFilter
    }
    const dateFilters = ['DATE_CREATED', 'DATE_RECEIVED', 'DATE_CONFIRMED']
    const validTaskFilters = allTaskFilters.filter(isValidInputTaskFilter) as InputTaskFilter[]
    const timezoneAdjustedFilters = validTaskFilters.map((taskFilter) =>
      dateFilters.includes(taskFilter.column) ? adjustTaskFilterDates(taskFilter) : taskFilter,
    )
    return timezoneAdjustedFilters.map((filter) =>
      pick(filter, 'id', 'value', 'operation', 'column'),
    )
  }, [])

  const {
    data: taskPagesData,
    refetch: fetchTaskPages,
    loading: taskPagesLoading,
  } = useQuery<Pick<Query, 'taskPages'>>(TASKS_UP_TO_PAGE, {
    fetchPolicy: 'network-only',
    variables: {
      pageUpTo: taskPageNum,
      query: taskQuery,
      filters: getFilters(taskFilters),
    },
    // alternative to useLazyQuery so we can await on fetchTaskPages()
    skip: taskFilters === undefined || taskQuery === undefined,
    onError: (error) => {
      enqueueSnackbar(`Error received while loading new tasks: ${formatMaybeApolloError(error)}`, {
        variant: 'error',
      })
    },
  })

  useEffect(() => {
    // Refetch recently updated/created tasks automatically
    // to keep tasks relatively up to date
    const refetchData = async (): Promise<void> => {
      if (taskFilters === undefined || taskQuery === undefined) {
        return
      }
      await fetchTaskPages()
    }

    const timeToSyncInSeconds =
      MASTER_TASK_VIEW_SYNC_BASE_INTERVAL +
      randomInt(TASK_VIEW_SYNC_OFFSET * 2) +
      1 -
      TASK_VIEW_SYNC_OFFSET
    setTimeout((): void => {
      refetchData()
        .then(() => setTimeLastSync(new Date()))
        .catch((error) =>
          enqueueSnackbar(
            `Failed to fetch updated task cards: ${formatMaybeApolloError(
              error,
            )}. Please refresh the page`,
            {
              variant: 'error',
            },
          ),
        )
    }, timeToSyncInSeconds * SECONDS_IN_MS)
  }, [timeLastSync, fetchTaskPages, enqueueSnackbar])

  useEffect(() => {
    const setColumnsWithNewTasks = (newTasks: TaskNode[]): void => {
      const newColumns: Columns = getInitialColumns()

      newTasks.forEach((task: TaskNode) => {
        const column = newColumns[task.status]
        if (column) {
          column.items.push(task)
        }
      })

      setColumns(newColumns)
    }

    if (taskPagesData?.taskPages) {
      setColumnsWithNewTasks(taskPagesData.taskPages.items)
      setColCounts(
        Object.fromEntries(
          taskPagesData.taskPages.filteredCounts.map(({ count, status }) => [status, count]),
        ),
      )
    }
  }, [taskPagesData])

  useEffect(() => {
    const onScroll = (): void => {
      if (taskPagesLoading) {
        return
      }
      const windowBottom = window.innerHeight + window.scrollY
      let shouldLoadMore = false
      for (const [columnId, { current: columnElement }] of Object.entries(colRefs.current)) {
        if (columnElement == null) continue
        if (windowBottom >= columnElement.offsetTop + columnElement.offsetHeight) {
          const moreToLoad =
            taskPagesData &&
            (taskPagesData.taskPages!.filteredCounts.find((count) => count.status === columnId)
              ?.count || 0) > columns[columnId].items.length
          if (moreToLoad) {
            shouldLoadMore = true
          }
        }
      }
      if (shouldLoadMore) {
        const newTaskPage = taskPageNum + 1
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetchTaskPages({
          pageUpTo: newTaskPage,
          query: taskQuery,
          filters: getFilters(taskFilters),
        })
        setTaskPageNum(newTaskPage)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [
    taskPageNum,
    taskQuery,
    taskFilters,
    taskPagesData,
    columns,
    fetchTaskPages,
    getFilters,
    taskPagesLoading,
  ])

  useEffect(() => {
    // We use a trigger here because we can't figure out if we should
    // be searching from the state of the function at any given time
    // (as the actual query can be nullified if filters are applied
    // However, this effect triggers on render, so we put this here
    // to avoid double fetching
    if (searchTrigger) {
      if (taskFilters === undefined || taskQuery === undefined) {
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchTaskPages({
        query: taskQuery,
        filters: getFilters(taskFilters),
        pageUpTo: 0,
      })
      setTaskPageNum(0)
    } else {
      setSearchTrigger(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTaskPages, getFilters, taskFilters, taskQuery])

  useEffect(() => {
    const clickHandler = (event: MouseEvent): void => {
      // eslint-disable-next-line
      if (event.defaultPrevented) return
    }
    window.addEventListener('click', clickHandler)

    return () => {
      window.removeEventListener('click', clickHandler)
    }
  }, [])

  const canInitiateTaskPagesSearch =
    (taskQueryBuffer || '') !== (taskQuery || '') ||
    !isEqual(taskFilters || [], taskFiltersBuffer || [])

  const initiateTaskPagesSearch = useMemo(() => {
    const debouncedSetTaskQuery = debounce(() => {
      if (!canInitiateTaskPagesSearch) return
      // Note: we don't just do setTaskFilters(taskFiltersBuffer) here because taskFiltersBuffer
      // could be undefined. And if taskFilters is undefined, then taskPageData wouldn't get
      // refetched.
      setTaskFilters(taskFiltersBuffer || [])
      setTaskQuery(taskQueryBuffer || '')
    }, searchDebounceMs)
    // mui doesn't really tell us what the type should be
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return () => debouncedSetTaskQuery()
  }, [canInitiateTaskPagesSearch, taskFiltersBuffer, taskQueryBuffer])

  const moveTaskCard = useCallback(
    async (result: DropResult): Promise<void> => {
      const { source, destination } = result
      const orderedStatus = Object.values(TaskStatus)

      if (
        orderedStatus.indexOf(source.droppableId as TaskStatus) >
        orderedStatus.indexOf(destination?.droppableId as TaskStatus)
      ) {
        enqueueSnackbar('Cannot move tasks backwards. Please change job status individually.', {
          variant: 'error',
        })
        return
      }

      if (source.droppableId !== destination!.droppableId) {
        const newColumns = produce(columns, (draft) => {
          const sourceColumn = draft[source.droppableId]
          const destColumn = draft[destination!.droppableId]
          const [removed] = sourceColumn.items.splice(source.index, 1)
          removed.status = result.destination!.droppableId as TaskStatus
          destColumn.items.splice(destination!.index, 0, removed)
        })
        setColumns(newColumns)
        try {
          await updateTaskStatus({
            variables: {
              taskId: result.draggableId,
              status: result.destination!.droppableId,
            },
          })
        } catch (error) {
          enqueueSnackbar(`Failed to move task to column: ${formatMaybeApolloError(error)}`, {
            variant: 'error',
          })
        }
      } else {
        const newColumns = produce(columns, (draft) => {
          const column = draft[source.droppableId]
          const [removed] = column.items.splice(source.index, 1)
          column.items.splice(destination!.index, 0, removed)
        })
        setColumns(newColumns)
      }
    },
    [enqueueSnackbar, columns, updateTaskStatus],
  )

  const deleteTask = useCallback(
    (taskId: string): void => {
      const newTask = taskPagesData!.taskPages!.items.find((task) => task.id === taskId)
      setCurrTask(newTask!)
      setDeleteDialogOpen(true)
    },
    [taskPagesData, setCurrTask, setDeleteDialogOpen],
  )

  const onDragEnd = useCallback(
    async (result: DropResult): Promise<void> => {
      if (!result.destination) return
      // we want to make sure ops is moving the jobs to the right status so
      // we validate that here
      const newStatus = result.destination.droppableId as TaskStatus
      const newTask = taskPagesData!.taskPages!.items.find(
        (task) => task.id === result.draggableId,
      )!
      if ([TaskStatus.Done, TaskStatus.Confirmation].includes(newStatus) && newTask.blocked) {
        enqueueSnackbar(
          `Task ${newTask.title} is currently blocked, please unblock it before moving this task to ${newStatus}`,
          {
            variant: 'error',
          },
        )
        return
      }

      try {
        validateJobsForBulkUpdate(newTask.jobs!.edges as JobNodeEdge[], newStatus)
      } catch (error) {
        enqueueSnackbar(
          `Failed to validate jobs for bulk update: ${formatMaybeApolloError(error)}`,
          {
            variant: 'error',
          },
        )
        return
      }
      const newJobsBeforeStatus = getJobsBeforeStatus(newTask, newStatus)
      if (newJobsBeforeStatus.length > 0) {
        const manualJobsBeforeStatus = newJobsBeforeStatus.filter(
          (job) => job.node?.manualProduct === ManualProduct.Manual,
        )
        // TODO: error here
        setJobsBeforeStatus(manualJobsBeforeStatus)
        setCurrTask(newTask)
        const newMoveCallback = () => async () => {
          await moveTaskCard(result)
          await fetchTaskPages({
            pageUpTo: taskPageNum,
            query: taskQuery,
            filters: getFilters(taskFilters),
          })
          setMoveStatus('')
          setJobsBeforeStatus([])
        }
        setMoveCallback(newMoveCallback)
        setMoveStatus(newStatus)

        if (!newTask.dateConfirmed && newStatus === TaskStatus.Done) {
          setTaskDateConfirmedDialogOpen(true)
        } else {
          setMoveDialogOpen(true)
        }
      } else {
        await moveTaskCard(result)
      }
    },
    [
      taskPagesData,
      enqueueSnackbar,
      moveTaskCard,
      fetchTaskPages,
      taskPageNum,
      taskQuery,
      getFilters,
      taskFilters,
    ],
  )

  const isEmpty = (columns: Record<string, { name: string; items: Array<TaskNode> }>): boolean => {
    for (const column of Object.values(columns)) {
      if (column.items.length > 0) {
        return false
      }
    }
    return true
  }

  if (!taskPagesData) {
    return (
      <Box height='100vh' width='100%'>
        <CenteredCircularProgress />
      </Box>
    )
  }

  return (
    <div>
      <Paper className={classes.searchPaper}>
        <Box display='flex' alignItems='center' justifyContent='space-between' p={2}>
          <Box display='flex' alignItems='center'>
            <Paper className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder='Search Tasks (at least three characters)'
                inputProps={{ 'aria-label': 'search tasks (at least three characters)' }}
                onChange={(e) => setTaskQueryBuffer(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') initiateTaskPagesSearch()
                }}
                value={taskQueryBuffer}
                data-testid='task-search-bar'
              />
              <IconButton
                onClick={initiateTaskPagesSearch}
                data-testid='task-search-button'
                disabled={!canInitiateTaskPagesSearch}
              >
                <SearchIcon
                  className={clsx({
                    [classes.searchIconPrimary]: canInitiateTaskPagesSearch,
                  })}
                />
              </IconButton>
            </Paper>
            <TaskFilterMenu
              updateTaskFilterBuffer={setTaskFiltersBuffer}
              taskFilters={taskFiltersBuffer || []}
            />
          </Box>
          <Box display='inline-flex'>
            <Button
              onClick={() => setCreateDialogOpen(true)}
              variant='contained'
              color='primary'
              data-testid='create-task-open'
            >
              + Create Task
            </Button>
            <Button
              className={classes.ctaButton}
              variant='contained'
              color='primary'
              onClick={() => setIsIdentifyTaskDialogOpen(true)}
              data-testid='create-job-open'
            >
              Create Job
            </Button>
          </Box>
        </Box>
      </Paper>
      <div className={classes.center}>
        {isEmpty(columns) && !taskPagesLoading ? (
          <Typography variant='h1'> No results found! </Typography>
        ) : (
          <div className={classes.dragDropWrapper}>
            <DragDropContext onDragEnd={onDragEnd}>
              {Object.entries(columns).map(([columnId, column]) => {
                if (colRefs.current[columnId] == null) {
                  colRefs.current[columnId] = { current: null }
                }
                return (
                  <Box display='flex' flexDirection='column' alignItems='center' key={columnId}>
                    <div className={classes.columnHeaderBox}>
                      <div className={classes.colHeader}>
                        <Typography className={classes.colName}>{column.name}</Typography>
                        <Typography>{colCounts?.[columnId] || ''}</Typography>
                      </div>
                    </div>
                    <div ref={colRefs.current[columnId]} className={classes.colBody}>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(dropProvided, dropSnapshot) => {
                          return (
                            <div
                              {...dropProvided.droppableProps}
                              // eslint-disable-next-line @typescript-eslint/unbound-method
                              ref={dropProvided.innerRef}
                              // inline for conditional
                              style={{
                                background: dropSnapshot.isDraggingOver ? grey[600] : grey[50],
                                padding: 4,
                                width: 250,
                                minHeight: 500,
                              }}
                            >
                              {taskPagesLoading ? (
                                <TaskColumnSkeleton />
                              ) : (
                                column.items.map((item, index) => {
                                  if (!item) return null
                                  return (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                      {(dragProvided, dragSnapshot) => {
                                        return (
                                          <TaskCard
                                            provided={dragProvided}
                                            snapshot={dragSnapshot}
                                            refetchColumns={fetchTaskPages}
                                            deleteTask={deleteTask}
                                            task={item}
                                          />
                                        )
                                      }}
                                    </Draggable>
                                  )
                                })
                              )}
                              {dropProvided.placeholder}
                            </div>
                          )
                        }}
                      </Droppable>
                    </div>
                  </Box>
                )
              })}
            </DragDropContext>
          </div>
        )}
      </div>
      <div>
        <CreateTask
          open={createDialogOpen}
          setOpen={setCreateDialogOpen}
          refetchColumns={fetchTaskPages}
        />
        {currTask && (
          <TaskDateConfirmedDialog
            open={taskDateConfirmedDialogOpen}
            task={currTask}
            confirm={() => {
              setMoveDialogOpen(true)
              setTaskDateConfirmedDialogOpen(false)
            }}
            onClose={() => setTaskDateConfirmedDialogOpen(false)}
          />
        )}
        {moveDialogOpen && (
          <MoveJobStatusDialog
            task={currTask}
            jobs={jobsBelowStatus}
            status={moveStatus as TaskStatus}
            open={moveDialogOpen}
            setOpen={setMoveDialogOpen}
            updateCallback={moveCallback}
          />
        )}
        {currTask && (
          <DeleteTaskDialog
            task={currTask}
            open={deleteDialogOpen}
            setOpen={setDeleteDialogOpen}
            refetchTask={fetchTaskPages}
          />
        )}
        {isIdentifyTaskDialogOpen && (
          <IdentifyTaskDialog
            isOpen={isIdentifyTaskDialogOpen}
            closePopup={(): void => {
              setIsIdentifyTaskDialogOpen(false)
            }}
            refetchJobs={async () => {
              await fetchTaskPages()
            }}
          />
        )}
      </div>
    </div>
  )
}

export default TasksPage
