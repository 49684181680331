import { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { makeStyles } from '@material-ui/styles'
import { RenderElementProps, useFocused, useSelected } from 'slate-react'
import { clsx } from 'clsx'

import theme from '@src/utils/theme'

const useStyles = makeStyles({
  fileIcon: {
    marginRight: theme.spacing(0.5),
  },
  fileNode: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(0.5, 1),
    color: theme.palette.text.primary,
  },
  selected: {
    boxShadow: `0 0 0 ${theme.spacing(0.25)}px ${theme.palette.info.light}`,
  },
})

const FileElement: FunctionComponent<RenderElementProps> = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const classes = useStyles()
  const selected = useSelected()
  const focused = useFocused()

  return (
    <Box display='flex' alignItems='center' {...attributes} contentEditable='false'>
      <a
        className={clsx(classes.fileNode, { [classes.selected]: selected && focused })}
        href={element.url as string}
        target='_blank'
        rel='noreferrer'
      >
        <AttachmentIcon className={classes.fileIcon} />
        {element.caption as string}
        {children}
      </a>
    </Box>
  )
}

export default FileElement
