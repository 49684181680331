import { gql } from '@apollo/client'

export const GET_CUSTOM_DATABASE_TYPES = gql`
  query customDatabaseTypes($query: String!) {
    customDatabaseTypes(query: $query) {
      id
      name
      tmsType {
        ... on FallbackValue {
          fallbackValue
          isFallback
        }
        ... on ApiPartnerTmsTypeEnum {
          value
          isFallback
        }
      }
      columns {
        edges {
          node {
            id
            displayName
            exportKey
            dateDeleted
          }
        }
      }
    }
  }
`

export const GET_CUSTOM_DATABASES = gql`
  query customDatabases($query: String!, $apiPartnerId: UUID!) {
    customDatabases(query: $query, apiPartnerId: $apiPartnerId) {
      id
      name
      customDatabaseTypeId
      customDatabaseType {
        id
        name
        tmsType {
          ... on FallbackValue {
            fallbackValue
            isFallback
          }
          ... on ApiPartnerTmsTypeEnum {
            value
            isFallback
          }
        }
        columns {
          edges {
            node {
              id
              displayName
              exportKey
              dateDeleted
            }
          }
        }
      }
      rows {
        edges {
          node {
            id
            dateDeleted
            cells {
              edges {
                node {
                  id
                  value
                  customDatabaseColumnId
                }
              }
            }
          }
        }
      }
    }
  }
`
