import Title from '@src/components/Title'
import { type FC } from 'react'
import { useParams } from 'react-router-dom'
import ReconHistory from '@src/components/recon-history'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

const ReconHistoryPage: FC = () => {
  const { jobId } = useParams<{ jobId: string }>()
  const enableReconHistory = useFeatureIsOn('recon-history')

  if (!enableReconHistory) {
    return null
  }

  return (
    <>
      <Title title='Reconciliation History' />
      <ReconHistory jobId={jobId} />
    </>
  )
}

export default ReconHistoryPage
