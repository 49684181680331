import { FunctionComponent, MutableRefObject, useMemo, useState } from 'react'
import { Box, IconButton, Paper } from '@material-ui/core'
import { HotTable } from '@handsontable/react'
import { useEffect } from 'react'
import theme from '@src/utils/theme'
import { SpreadsheetDataColumn } from '@src/utils/data-grid'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import FastHotTable from '@src/components/fast-hot-table/FastHotTable'
import { ChargeCodesArray } from './types'

type Props = {
  chargeCodes: ChargeCodesArray | undefined
  hotTableRef: MutableRefObject<HotTable | undefined>
  currApiPartnerId: string | null
  createNewVendorMode?: boolean
  vendorChargeCodePageSize?: number
  vendorChargeCodePage?: number
  setVendorChargeCodePage?: (page: number) => void
}

const VENDOR_CODE_TABLE_HEIGHT = '600px'

const VendorCodeTable: FunctionComponent<Props> = ({
  chargeCodes,
  hotTableRef,
  currApiPartnerId,
  createNewVendorMode,
  vendorChargeCodePageSize,
  vendorChargeCodePage,
  setVendorChargeCodePage,
}) => {
  const [rows, setRows] = useState<string[][]>([])

  const getColumns = (autoCompleteSuggestions: string[]): SpreadsheetDataColumn[] => {
    return [
      {
        key: 'description',
        name: 'Description',
      },
      {
        key: 'charge_code',
        name: 'Charge Code',
        type: 'autocomplete',
        source: autoCompleteSuggestions,
        strict: false,
      },
    ]
  }

  const columns = useMemo(() => {
    if (chargeCodes) {
      const autoCompleteSuggestions = [...new Set(chargeCodes.map((node) => node.code))]
      return getColumns(autoCompleteSuggestions)
    } else {
      return getColumns([])
    }
  }, [chargeCodes])

  useEffect(() => {
    if (chargeCodes && chargeCodes.length) {
      const newRows = chargeCodes
        .map((chargeCode) => [chargeCode.description, chargeCode.code])
        .filter((row) => row.length > 0)
      setRows(newRows)
    } else {
      setRows([['', '']])
    }
  }, [chargeCodes, currApiPartnerId, createNewVendorMode])

  return (
    <Paper data-testid='vendor-code-table'>
      {!createNewVendorMode && (
        <Box pl={2}>
          Page {vendorChargeCodePage! / vendorChargeCodePageSize! + 1}
          <IconButton
            color='primary'
            aria-label='back'
            onClick={() =>
              setVendorChargeCodePage!(vendorChargeCodePage! - vendorChargeCodePageSize!)
            }
            disabled={vendorChargeCodePage === 0}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>
          <IconButton
            color='primary'
            aria-label='forward'
            onClick={() =>
              setVendorChargeCodePage!(vendorChargeCodePage! + vendorChargeCodePageSize!)
            }
            disabled={!chargeCodes || chargeCodes.length < vendorChargeCodePageSize!}
          >
            <ArrowForwardOutlinedIcon />
          </IconButton>
        </Box>
      )}
      <FastHotTable
        data={rows}
        hotTableRef={hotTableRef}
        hooks={{}}
        style={{
          width: '100%',
          overflow: 'hidden',
          height: VENDOR_CODE_TABLE_HEIGHT,
          background: theme.palette.grey[200],
        }}
        settings={{
          rowHeaders: true,
          contextMenu: ['row_above', 'row_below', 'remove_row'],
          colHeaders: columns.map((col) => col.name),
          columnSorting: true,
          filters: true,
          autoRowSize: true,
          stretchH: 'all',
          columns,
        }}
      />
    </Paper>
  )
}

export default VendorCodeTable
