import { gql } from '@apollo/client'

export const RECREATE_REINDEX_MIRROR = gql`
  mutation recreateReindexMirror($apiPartnerIds: [UUID!]) {
    recreateReindexMirror(apiPartnerIds: $apiPartnerIds) {
      reindexMirrorAsyncTaskId
    }
  }
`

export const INDEX_QUEUE_INTO_SNOWFLAKE = gql`
  mutation indexQueueIntoSnowflake($apiPartnerIds: [UUID!]) {
    indexQueueIntoSnowflake(apiPartnerIds: $apiPartnerIds)
  }
`

export const SYNC_CAULDRON_DB = gql`
  mutation syncCauldronDb($apiPartnerIds: [UUID!]) {
    syncCauldronDb(apiPartnerIds: $apiPartnerIds)
  }
`

export const SYNC_SEARCHABLE_RECORDS = gql`
  mutation syncSearchableRecords($companyIds: [UUID!]) {
    syncSearchableRecords(companyIds: $companyIds)
  }
`
