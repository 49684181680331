import { ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { Query, UserRole } from '@src/graphql/types'
import { GET_USER_ROLES } from '@src/graphql/queries/profile'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'

const useRequireTeamLead = (): ReactElement | null => {
  const { data: userProfileData } = useQuery<Pick<Query, 'userProfile'>>(GET_USER_ROLES)
  if (!userProfileData) {
    return <CenteredCircularProgress />
  }
  if (!userProfileData?.userProfile.roles.some((role) => role === UserRole.TeamLead)) {
    return <h1>You do not have the team lead role required to access this page</h1>
  }
  return null
}

export default useRequireTeamLead
