import { ReactElement } from 'react'

type Props = {
  result: {
    chainIoShipment?: {
      forwarderReference?: string | null
      tmsId?: string | null
      isCancelled?: boolean | null
    } | null
    chainIoConsolidation?: {
      forwarderReference?: string | null
      tmsId?: string | null
      isCancelled?: boolean | null
    } | null
    chainIoCustomsDeclaration?: {
      forwarderReference?: string | null
      tmsId?: string | null
      isCancelled?: boolean | null
    } | null
  }
}
/**
 * Displays the reference number for a single FindShipmentDuplicateReconResult
 */
export const ReconResultModelReferenceDisplay = ({ result }: Props): ReactElement => {
  const model =
    result.chainIoShipment || result.chainIoConsolidation || result.chainIoCustomsDeclaration
  return (
    <>
      {model?.forwarderReference || model?.tmsId}
      {model?.isCancelled && (
        <>
          {' '}
          (<em>Inactive</em>)
        </>
      )}
    </>
  )
}
