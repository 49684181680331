import {
  ApiPartnerCode,
  ApiPartnerInterface,
  ApiPartnerNode,
  CargowiseConfigNode,
} from '@src/graphql/types'
import { isFallback } from './enum'

export const isCargowiseConfig = (
  apiPartner: ApiPartnerInterface | undefined | null,
): apiPartner is CargowiseConfigNode => {
  return !!(
    apiPartner?.apiPartnerCode &&
    !isFallback(apiPartner?.apiPartnerCode) &&
    apiPartner?.apiPartnerCode.value == ApiPartnerCode.CargowiseConfig
  )
}

export const isUniversalApi = (
  apiPartner: ApiPartnerInterface | undefined | null,
): apiPartner is ApiPartnerNode => {
  return !!(
    apiPartner?.apiPartnerCode &&
    !isFallback(apiPartner?.apiPartnerCode) &&
    apiPartner?.apiPartnerCode.value == ApiPartnerCode.UniversalApi
  )
}
