import { FunctionComponent, useContext, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Typography } from '@material-ui/core'
import ErrorCard from '@src/error-handling/ErrorCard'
import { Auth0AccessTokenContext } from '@src/auth/Auth0AccessTokenContext'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import { UserProfileContext } from './UserProfileContext'
import { reportRollbarError } from '@src/utils/observability/rollbar'

/**
 * Checks if user is authed with cauldron, and if not, does that (possibly logging in to auth0 while doing that)
 */
const RequireAuth: FunctionComponent = ({ children }) => {
  const {
    isAuthenticated,
    isLoading: isAuth0Loading,
    error: auth0Error,
    loginWithRedirect,
  } = useAuth0()
  const accessToken = useContext(Auth0AccessTokenContext)
  const {
    userProfile,
    loading: profileLoading,
    error: profileError,
  } = useContext(UserProfileContext)
  // 403: bad roles; 401: no user -- either way, need to contact support
  const unauthorized =
    profileError?.message.includes('403') || profileError?.message.includes('401')
  useEffect(() => {
    const checkAuth = async (): Promise<void> => {
      if (!profileLoading && !isAuth0Loading && !isAuthenticated) {
        if (auth0Error) {
          reportRollbarError(auth0Error)
        }

        await loginWithRedirect({
          appState: {
            returnTo: window.location.pathname + window.location.search,
          },
        })
      }
    }
    void checkAuth()
  }, [isAuth0Loading, isAuthenticated, auth0Error, loginWithRedirect, profileLoading])

  const isLoading = isAuth0Loading || !isAuthenticated || !accessToken || profileLoading
  return (
    <>
      {(isLoading && (
        <Box width={'100vw'} height={'100vh'}>
          <CenteredCircularProgress />
        </Box>
      )) ||
        (profileError && (
          <ErrorCard>
            {unauthorized ? (
              <>
                <Typography variant={'h2'}>
                  You do not have the permissions required to access the Expedock processing
                  platform.
                </Typography>
                <p>Please contact your ops lead to grant you the permissions needed.</p>
              </>
            ) : (
              <>
                <Typography variant={'h2'}>
                  An unexpected error occurred while checking your permissions.
                </Typography>
                <p>
                  We&apos;re sorry for the inconvenience. The Expedock engineering team has been
                  automatically notified. Please contact your ops lead for further concerns.
                </p>
              </>
            )}
          </ErrorCard>
        )) ||
        (userProfile && children)}
    </>
  )
}

export default RequireAuth
