import { ReactElement, RefObject, useCallback, useEffect } from 'react'
import { debounce } from 'lodash'
import useResizeAware from 'react-resize-aware'
import { FastHotTableRefValue } from '@src/components/fast-hot-table/FastHotTable'

const useRerenderOnResize = (
  hotTableRef: RefObject<FastHotTableRefValue | undefined>,
): ReactElement => {
  const [resizeListener, containerSize] = useResizeAware()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRenderHotTable = useCallback(
    debounce(() => {
      if (
        hotTableRef.current?.hotElementRef &&
        hotTableRef.current.hotInstance &&
        !hotTableRef.current.hotInstance.isDestroyed
      ) {
        // set height to 0 first to force recalculation of height from scratch (otherwise
        // height will never decrease)
        hotTableRef.current.hotElementRef.style.height = '0px'
        hotTableRef.current.hotInstance?.render()
      }
    }, 200),
    [hotTableRef],
  )
  useEffect(() => {
    debouncedRenderHotTable()
  }, [containerSize, debouncedRenderHotTable])

  return resizeListener
}

export default useRerenderOnResize
