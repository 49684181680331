import { produceWithPatches } from 'immer'
import {
  cloneLineItems,
  cloneLineItemsNoSnap,
  getLastRowBottomCoord,
  LineItem,
  JobTableLineItem,
} from '@src/utils/line_items'
import { DocumentEditorState } from './document_editor_state'
import { lineItemsAdapter, lineItemSelectors } from './line_items_table'
import { selectActiveOcrData } from './file_page'
import { computeNextStateWithUndo } from './undo_redo'

// The line items currently saved in clipboard for copy-paste
// and associated metadata about cols and the doc
export type LineItemsClipboardState = {
  documentId: string | null
  lineItems: LineItem[] | JobTableLineItem[]
  orderedColKeys: string[]
  copiedText: string[][]
} | null

export const clearLineItemsClipboardReducer = (state: DocumentEditorState): void => {
  state.lineItemIdsClipboard = []
}

export const clearActiveLineItemsClipboardReducer = (state: DocumentEditorState): void => {
  state.lineItemsClipboard = null
}

export const createRowsFromClipboardReducer = (
  state: DocumentEditorState,
  lineItemIds: string[],
): void => {
  if (lineItemIds.length > 0) {
    state.lineItemIdsClipboard = lineItemIds
  }

  if (state.lineItemIdsClipboard.length > 0) {
    const allLineItems = lineItemSelectors.selectAll(state)!
    const lineItemsToClone = allLineItems.filter((lineItem) =>
      state.lineItemIdsClipboard.includes(lineItem.id),
    )
    const bottomCoord = getLastRowBottomCoord(allLineItems).lastRowBottomCoord as number
    const googleOcrData = selectActiveOcrData(state)!
    const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
      const lineItemsTableMapDraft = stateDraft.lineItemsTableMap
      const lineItemsDraft = lineItemsTableMapDraft[stateDraft.activeDocumentTableId!]!.lineItems
      const newLineItems = cloneLineItems(
        lineItemsToClone,
        bottomCoord,
        googleOcrData,
      ) as LineItem[]
      lineItemsAdapter.addMany(lineItemsDraft, newLineItems)
    })
    computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
  }
}

// Same as creating line items from clipboard but we don't "snap"
// to word boundaries, we just do a dumb copy with some downward shift
// adjustment here
export const createRowsFromClipboardNoSnapReducer = (
  state: DocumentEditorState,
  lineItemIds: string[],
): void => {
  if (lineItemIds.length > 0) {
    state.lineItemIdsClipboard = lineItemIds
  }

  if (state.lineItemIdsClipboard.length > 0) {
    const allLineItems = lineItemSelectors.selectAll(state)!
    const lineItemsToClone = allLineItems.filter((lineItem) =>
      state.lineItemIdsClipboard.includes(lineItem.id),
    )
    const bottomCoord = getLastRowBottomCoord(allLineItems).lastRowBottomCoord as number
    const [nextState, forwardPatch, inversePatch] = produceWithPatches(state, (stateDraft) => {
      const lineItemsTableMapDraft = stateDraft.lineItemsTableMap
      const newLineItems = cloneLineItemsNoSnap(lineItemsToClone, bottomCoord) as LineItem[]
      const lineItemsDraft = lineItemsTableMapDraft[stateDraft.activeDocumentTableId!]!.lineItems
      lineItemsAdapter.addMany(lineItemsDraft, newLineItems)
    })
    computeNextStateWithUndo(state, inversePatch, forwardPatch, nextState)
  }
}
