import { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import { FilePageType } from '@src/graphql/types'

const useStyles = makeStyles({
  thumbnail: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    objectFit: 'contain',
    maxWidth: '100%',
  },
  image: {
    height: '100%',
    objectFit: 'contain',
    width: '100%',
  },
  icon: {
    fontSize: 70,
  },
})

type Props = {
  type: FilePageType
  file: string
  fileName: string
}

const FilePageThumbnail: FunctionComponent<Props> = ({ type, file, fileName }: Props) => {
  const classes = useStyles()

  return (
    <Box className={classes.thumbnail}>
      {type === FilePageType.Excel && (
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          <TableChartOutlinedIcon className={classes.icon} />
          Excel
        </Box>
      )}
      {type === FilePageType.ImagePdf && (
        <img src={file} alt={fileName} className={classes.image} />
      )}
      {type === FilePageType.Other && (
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          <InsertDriveFileOutlinedIcon className={classes.icon} />
        </Box>
      )}
    </Box>
  )
}

export default FilePageThumbnail
