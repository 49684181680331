// TODO: copy-pasted to web/ -- should use shared library instead. Be sure to replicate changes in both places
import { CwTargetModule, FindShipmentReconResultNode } from '@src/graphql/types'
import { CargowiseOpsType } from '@src/utils/cargowise/types'
import { ReactElement } from 'react'
import { Typography } from '@material-ui/core'
import {
  formatCwTargetModule,
  formatModelFoundResults,
  formatShipmentConsolResults,
} from '@src/utils/recon/ap_recon'
import { FindModelCriteriaSummary } from '@src/utils/recon/FindModelCriteriaSummary'
import { ReconResultModelReferenceDisplay } from '@src/utils/recon/ReconResultModelReferenceDisplay'

type Props = {
  findShipmentResults: FindShipmentReconResultNode[]
  cargowiseOpsType: CargowiseOpsType
  cargowiseModule: CwTargetModule
}

export const FindShipmentResultDisplay = ({
  findShipmentResults,
  cargowiseOpsType,
  cargowiseModule,
}: Props): ReactElement => {
  // get the module type from the recon attempt, or fallback to results when we only have those
  const successfulFindShipmentResults =
    findShipmentResults?.filter((reconResult) => reconResult.success) ?? []
  // we only consider the primary cw module since that's what we actually reconcile the invoice data with
  const primaryCwModuleText = formatCwTargetModule(cargowiseModule)

  const isForwardingShipment = cargowiseModule === CwTargetModule.ForwardingShipment

  if (successfulFindShipmentResults.length !== 0) {
    return (
      <Typography component={'span'} variant='body1'>
        {cargowiseOpsType === CargowiseOpsType.Reconcile &&
          `${primaryCwModuleText} with reference no. `}
        {cargowiseOpsType === CargowiseOpsType.PostToCW &&
          `Posting to ${primaryCwModuleText.toLowerCase()} with reference no. `}
        {cargowiseOpsType === CargowiseOpsType.SendToCW &&
          `Sending to ${primaryCwModuleText.toLowerCase()} with reference no. `}
        {cargowiseOpsType === CargowiseOpsType.PushEDocs && 'Pushing eDocs to '}
        {cargowiseOpsType === CargowiseOpsType.PullEDocs && 'Pulling eDocs from '}
        {formatModelFoundResults(successfulFindShipmentResults, cargowiseOpsType, cargowiseModule)}
        {isForwardingShipment && formatShipmentConsolResults(successfulFindShipmentResults)}
      </Typography>
    )
  }
  const maximalFindShipmentResultsWithDuplicates = findShipmentResults.filter(
    (findShipmentResult) =>
      findShipmentResult.findShipmentDuplicateReconResults.edges.length &&
      findShipmentResult.isMaximalAmongDuplicates,
  )

  const cwModuleText = primaryCwModuleText
  const isForwardingShipmentToConsol = cargowiseModule === CwTargetModule.ForwardingShipmentToConsol
  const successfulConsolResult =
    isForwardingShipmentToConsol &&
    findShipmentResults.find((reconResult) => reconResult.chainIoConsolidation)
  const successfulConsolText = successfulConsolResult && (
    <Typography component={'div'} variant='body1' gutterBottom>
      Successfully found consol with reference no.{' '}
      <ReconResultModelReferenceDisplay result={successfulConsolResult} />, <em>but</em>:
    </Typography>
  )

  if (maximalFindShipmentResultsWithDuplicates.length) {
    // for ForwardingShipmentToConsol, it is possible to succeed on consol but fail on shipment stage --
    // in which case we want to show the consol
    return (
      <>
        {successfulConsolText}
        <Typography component={'div'} variant='body1' gutterBottom>
          Duplicate/Inactive{' '}
          {isForwardingShipmentToConsol ? 'Shipment / Consol' : primaryCwModuleText}s were found:
        </Typography>
        {maximalFindShipmentResultsWithDuplicates.map((findShipmentResult) => (
          <Typography component={'div'} variant='body1' gutterBottom key={findShipmentResult.id}>
            -{' '}
            <strong>
              {findShipmentResult.findShipmentDuplicateReconResults.edges
                .map((edge) => edge.node)
                .map((duplicateReconResult, i) => [
                  i > 0 && ', ',
                  <ReconResultModelReferenceDisplay
                    key={duplicateReconResult.id}
                    result={duplicateReconResult}
                  />,
                ])}
            </strong>{' '}
            using <FindModelCriteriaSummary findModelCriteriaItems={[findShipmentResult]} />
          </Typography>
        ))}
      </>
    )
  }

  return (
    <>
      {successfulConsolText}
      <Typography component={'span'} variant='body1'>
        No {successfulConsolText ? 'shipment' : cwModuleText} was found using the following keys:{' '}
        <FindModelCriteriaSummary findModelCriteriaItems={findShipmentResults} />
      </Typography>
    </>
  )
}
