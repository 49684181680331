/**
 * This is a copy from dashboard. Please make any changes here as well as
 * in dashboard/src/freight-bi/user-management/ShipmentUserDataAutocomplete.tsx
 */
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'

type Props = {
  fieldName: string
  friendlyName: string
  defaultValue?: string[] | null
  options?: string[]
}

const ShipmentUserDataAutocomplete: FunctionComponent<Props> = ({
  fieldName,
  friendlyName,
  defaultValue,
  options = [],
}) => {
  return (
    <Controller
      render={({ field: { value, onBlur, onChange } }) => (
        <Autocomplete
          options={options}
          multiple={true}
          getOptionLabel={(option: string) => option || ''}
          onBlur={onBlur}
          value={value}
          onChange={(_event, newValue) => onChange(newValue)}
          renderInput={(params) => (
            <TextField variant='outlined' {...params} label={friendlyName} />
          )}
        />
      )}
      name={fieldName}
      defaultValue={defaultValue}
    />
  )
}

export default ShipmentUserDataAutocomplete
