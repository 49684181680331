import { ChangeEvent, FunctionComponent, useMemo, useCallback } from 'react'
import { Controller, ControllerProps, FieldValues, useWatch } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import { InputSearchableRecord, SearchableRecordOptions } from '@src/graphql/types'
import { SearchableRecordNode, Maybe } from '../../../graphql/types'

type Props = {
  searchableRecord: Maybe<InputSearchableRecord> | undefined
  fieldGroupIndex: number
  fieldIndex: number
  isLineItem: boolean
  searchableRecordOptions: SearchableRecordOptions[]
}

const SearchableRecordSelector: FunctionComponent<Props> = ({
  searchableRecord,
  fieldGroupIndex,
  fieldIndex,
  isLineItem,
  searchableRecordOptions,
}) => {
  const namePrefix = isLineItem ? 'lineItemTypes' : 'metadataFieldGroups'
  const documentTypeId = useWatch({
    name: `${namePrefix}.${fieldGroupIndex}.documentTypeId`,
  }) as string

  const searchableRecordsOptionsTrimmed = useMemo(
    () =>
      searchableRecordOptions?.map((record: Maybe<SearchableRecordOptions>) =>
        record
          ? {
              searchKey: record.searchKey,
              model: record.model,
              displayKey: record.displayKey,
              apiUrl: record.apiUrl,
            }
          : {},
      ) ?? [],
    [searchableRecordOptions],
  )

  const formatSearchableRecordsOptions = useCallback(
    (searchableRecordsOption: SearchableRecordNode) => {
      return `${searchableRecordsOption.model ?? searchableRecordsOption.apiUrl} (${
        searchableRecordsOption.searchKey
      }:${searchableRecordsOption.displayKey})`
    },
    [],
  )

  const render: ControllerProps<
    FieldValues,
    | `metadataFieldGroups.${number}.fields.${number}.searchableRecord`
    | `lineItemTypes.${number}.fields.${number}.searchableRecord`
  >['render'] = useMemo(() => {
    return ({ field: { value, onBlur, onChange }, fieldState: { error, invalid } }) => {
      const onAutocompleteChange = (_: ChangeEvent<Record<string, string>>, val: string): void => {
        onChange(val)
      }

      return (
        <Autocomplete
          disabled={!documentTypeId}
          value={value}
          options={searchableRecordsOptionsTrimmed}
          getOptionLabel={(searchableRecordsOptionsTrimmed) =>
            formatSearchableRecordsOptions(searchableRecordsOptionsTrimmed) as string
          }
          getOptionSelected={(searchableRecordsOptionsTrimmed) =>
            formatSearchableRecordsOptions(searchableRecordsOptionsTrimmed) ===
            formatSearchableRecordsOptions(value)
          }
          onChange={onAutocompleteChange}
          onBlur={onBlur}
          size='small'
          renderInput={(params) => (
            <TextField {...params} error={invalid} helperText={error?.message} variant='outlined' />
          )}
        />
      )
    }
  }, [documentTypeId, searchableRecordsOptionsTrimmed, formatSearchableRecordsOptions])
  return (
    <Controller
      render={render}
      name={`${namePrefix}.${fieldGroupIndex}.fields.${fieldIndex}.searchableRecord`}
      defaultValue={searchableRecord || undefined}
    />
  )
}

export default SearchableRecordSelector
