import { Context, FunctionComponent, useEffect, useState } from 'react'
import { DeployEnvironment, getDeployEnvironment } from '@src/utils/environment'
import posthog from 'posthog-js'
import { PostHogContext } from 'posthog-js/react'
import { useEventAttributes } from '@src/utils/observability/useEventAttributes'

const shouldSendData =
  [DeployEnvironment.PRODUCTION].includes(getDeployEnvironment()) || localStorage.forceUsePostHog

// until we decide to pay for posthog (or decide to host multiple posthog servers), we're stuck
// with only a single posthog project
const projectKey = 'phc_q7OXE0msZg6LD2m8SF0l7jQRFHbr9R4BSwCMUjjP0hh'
const applicationIdMap = {
  [DeployEnvironment.DEVELOPMENT]: projectKey,
  [DeployEnvironment.ENGINEERING]: projectKey,
  [DeployEnvironment.STAGING]: projectKey,
  [DeployEnvironment.PRODUCTION]: projectKey,
}

// gets the type of the value of a React.Context
// cf https://stackoverflow.com/questions/44851268/typescript-how-to-extract-the-generic-parameter-from-a-type
type ContextValueType<ContextType> = ContextType extends Context<infer T> ? T : never

/**
 * We use a custom posthog provider to make our environment/localStorage-based tracking behavior
 * easier.
 */
export const ExpedockPostHogProvider: FunctionComponent = ({ children }) => {
  const { userAttributes, loading } = useEventAttributes()
  const [postHog_, setPostHog_] = useState({} as ContextValueType<typeof PostHogContext>)
  useEffect(() => {
    if (shouldSendData && !loading && userAttributes.email) {
      const startTracking = async (): Promise<void> => {
        posthog.init(applicationIdMap[getDeployEnvironment()], {
          api_host: `${window.location.protocol}//${window.location.host}/ev-phog`,
          secure_cookie: getDeployEnvironment() !== DeployEnvironment.DEVELOPMENT,
          autocapture: false,
          rageclick: false,
          session_recording: {
            maskAllInputs: false,
          },
          loaded: (posthog) => {
            posthog.identify(userAttributes.email!, userAttributes)
          },
        })
        setPostHog_({ client: posthog })
      }
      void startTracking()
    }
    // really don't want to re-init every change of session attributes
  }, [loading, userAttributes])
  useEffect(() => {
    postHog_.client?.identify(userAttributes.email!, userAttributes)
  }, [userAttributes, postHog_])
  return <PostHogContext.Provider value={postHog_}> {children} </PostHogContext.Provider>
}
