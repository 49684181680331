import {
  ApReconAttemptsHistoryQuery,
  GetJobDataLiteQuery,
  ReconAttemptNode,
  ReconAttemptsQuery,
  ReconAttemptWithResultsAndMetadataFragment,
} from '@src/graphql/types'
import { type FC } from 'react'
import { ApReconItems, SoaReconItems } from '.'
import { parseDateString } from '@src/utils/date'
import { ReconTableHeader } from '../reconciliation-dialog/bulk-recon-dialog/BulkReconDialog'
import { Box, TableContainer, Paper, Table, TableBody } from '@material-ui/core'
import ReconAttemptRow from '../reconciliation-dialog/bulk-recon-dialog/BulkReconDialogRow'
import { ReconItemHeader, ReconItemSubheader } from './ReconTypeHistory'

type ReconHistoryTableProps = (
  | {
      reconItem: SoaReconItems[number]
      reconAttempts: ReconAttemptsQuery['reconAttempts']
    }
  | {
      reconItem: ApReconItems[number]
      reconAttempts: ApReconAttemptsHistoryQuery['apReconAttemptsHistory']['results'][number][]
    }
) & {
  job: GetJobDataLiteQuery['job']
  handleRowClick: (reconAttempt: ReconAttemptWithResultsAndMetadataFragment) => void
  showHeader?: boolean
  componentAboveTable?: JSX.Element
}

const ReconHistoryTable: FC<ReconHistoryTableProps> = ({
  reconItem,
  reconAttempts,
  handleRowClick,
  job,
  showHeader = true,
  componentAboveTable,
}) => {
  const date = parseDateString(reconItem.dateCreated)
  const userCreated = reconItem.userCreated.email

  const isSaved = reconAttempts.some((attempt) => attempt.dateSaved)

  return (
    <Box key={reconItem.id} sx={{ width: '100%' }}>
      {showHeader && (
        <>
          <ReconItemHeader date={date} loading={false} isSaved={isSaved} />
          <ReconItemSubheader
            isSaved={isSaved}
            userEmail={userCreated}
            reconType={job.jobTemplate.reconType}
            reconItemId={reconItem.id}
          />
        </>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gridGap: '1rem' }}>
        {componentAboveTable}
        <TableContainer component={Paper}>
          <Table>
            <ReconTableHeader includeExternalDetails={false} />
            <TableBody>
              {reconAttempts.map((reconAttempt) => (
                <ReconAttemptRow
                  key={reconAttempt.id}
                  reconAttempt={reconAttempt as ReconAttemptNode}
                  onClick={() => handleRowClick(reconAttempt)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default ReconHistoryTable
