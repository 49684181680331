import { FunctionComponent } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

import { PAGE_ASSOC_DIMENSIONS } from '@src/utils/app_constants'
import theme from '@src/utils/theme'

const useStyles = makeStyles({
  fileInfo: {
    width: PAGE_ASSOC_DIMENSIONS.BASE_WIDTH,
    overflow: 'auto',
    padding: theme.spacing(2),
  },
})

type Props = {
  heading: string
}

const FileInfo: FunctionComponent<Props> = ({ heading, children }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.fileInfo} square>
      <Typography variant='h6' gutterBottom>
        {heading}
      </Typography>
      {children}
    </Paper>
  )
}

export default FileInfo
