import { FunctionComponent, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { makeStyles, Button, Box } from '@material-ui/core'
import { COLORS } from '@src/utils/app_constants'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import theme from '@src/utils/theme'
import { clsx } from 'clsx'

// arbitrary, derived from spec
const uploadBoxHeight = '300px'

const useStyles = makeStyles({
  uploadBox: {
    display: 'flex',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.grey[100],
    outline: 'none',
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    margin: '0 auto',
    height: uploadBoxHeight,
    // arbitrary, derived from spec
    width: '65%',
  },
  uploadBoxNoFilePresent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadBoxFilePresent: {
    justifyContent: 'left',
    alignItems: 'left',
  },
  uploadBoxDesc: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  dateUploaded: {
    color: theme.palette.grey[600],
  },
  dropzoneTitle: {
    paddingBottom: theme.spacing(2),
  },
  fileUploadedName: {
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    textDecoration: 'underline',
  },
  updateButton: {
    color: COLORS.MEDIUM_TURQUOISE,
  },
})

type Props = {
  file: File | null
  setFile: (file: File | null) => unknown
  dropzoneTitle: string
}

const CsvDropzone: FunctionComponent<Props> = ({ dropzoneTitle, setFile, file }: Props) => {
  const classes = useStyles()
  const [dateUploaded, setDateUploaded] = useState(null as null | Date)
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        // only accept 1 file for this specific dropzone type
        // to avoid stupid failure modes with ops
        const newFile = acceptedFiles[0]
        setFile(newFile)
        setDateUploaded(new Date())
      } else {
        setFile(null)
        setDateUploaded(null)
      }
    },
    [setFile],
  )
  const removeFiles = (): void => {
    setFile(null)
    setDateUploaded(null)
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.csv, text/csv',
  })

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper
        variant='outlined'
        className={clsx(classes.uploadBox, {
          [classes.uploadBoxNoFilePresent]: !file,
          [classes.uploadBoxFilePresent]: !!file,
        })}
        {...getRootProps()}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} data-testid='dropzone-input' />
        <Typography variant='body1' className={classes.uploadBoxDesc}>
          {!file ? (
            'Drag CSV files or click to upload.'
          ) : (
            <Box justifyContent='left' pl={4}>
              <Typography variant='h6' className={classes.dropzoneTitle}>
                {dropzoneTitle}
              </Typography>
              <Box display='flex'>
                <Typography className={classes.fileUploadedName}>{file?.name || ''}</Typography>
                <Button className={classes.updateButton} onClick={removeFiles}>
                  Update
                </Button>
              </Box>
              <Typography className={classes.dateUploaded}>{`Uploaded ${
                dateUploaded || 'Pending'
              }`}</Typography>
            </Box>
          )}
        </Typography>
      </Paper>
    </>
  )
}

export default CsvDropzone
