import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import Dropzone from '@src/components/dropzone'
import { useApolloClient } from '@apollo/client'
import { getImageFileData } from '@src/utils/file'
import { ImageFileData } from '@src/utils/types'
import {
  Checkbox,
  alpha,
  FormControl,
  FormControlLabel,
  GridList,
  GridListTile,
  GridListTileBar,
  Paper,
} from '@material-ui/core'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import theme from '@src/utils/theme'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { clsx } from 'clsx'
import { useZoomControlBar } from '@src/components/zoom-control-bar/useZoomControlBar'
import ZoomControlBar from '@src/components/zoom-control-bar/ZoomControlBar'
import { DocumentTypeNode, FilePageType } from '@src/graphql/types'
import Button from '@material-ui/core/Button'
import CenteredCircularProgress from '@src/components/centered-circular-progress/CenteredCircularProgress'
import FilePageThumbnail from '../file-page-thumbnail/FilePageThumbnail'
import DocumentTypeAutocomplete from '../shipment-form/DocumentTypeAutocomplete'

const gridTileHeight = 300
const leftPanelWidth = 450
const tileBorderWidth = 1

const useStyles = makeStyles({
  leftPanel: {
    width: leftPanelWidth,
    height: '100%',
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
  },
  documentTypeSelect: {
    background: theme.palette.background.paper,
    minWidth: '520px',
    margin: theme.spacing(1),
  },
  selectableTile: {
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  },
  selectableTileSelected: {
    border: `${tileBorderWidth}px solid ${theme.palette.primary.main}`,
  },
  selectableTileUnselected: {
    // so the image doesn't resize when selected/unselected due to the border
    border: `${tileBorderWidth}px solid transparent`,
  },
  tileImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  rightPanel: {
    flex: '1 1',
    height: '100%',
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  selectedImageTile: {
    cursor: 'pointer',
    margin: theme.spacing(1),
    position: 'relative',
  },
  selectedImageTileBar: {
    position: 'absolute',
    color: theme.palette.common.white,
    bottom: 0,
    left: 0,
    right: 0,
    background: alpha(theme.palette.common.black, 0.5),
    padding: theme.spacing(1),
  },
  previewFile: {
    width: '100%',
  },
  selectedImageTilePreviewed: {
    border: `${tileBorderWidth}px solid ${theme.palette.primary.main}`,
  },
  selectedImageTileUnpreviewed: {
    border: `${tileBorderWidth}px solid transparent`,
  },
  previewImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
})

enum LeftPanelState {
  Upload,
  SelectPages,
}

type Props = {
  imageFileData: ImageFileData[]
  setImageFileData: Dispatch<SetStateAction<ImageFileData[]>>
  // to save original files from upload
  // optional because this component is used in ConfirmationSidebar, ProofDialog, and Ingest
  originalFileData?: File[]
  setOriginalFileData?: Dispatch<SetStateAction<File[]>>
  documentTypes?: DocumentTypeNode[]
  documentTypeId?: string | undefined
  setDocumentTypeId?: (id: string) => unknown
}

const FileUpload: FunctionComponent<Props> = ({
  imageFileData,
  setImageFileData,
  originalFileData,
  setOriginalFileData,
  documentTypes,
  setDocumentTypeId,
  documentTypeId,
}: Props) => {
  const classes = useStyles()
  const client = useApolloClient()
  const [isProcessingImages, setIsProcessingImages] = useState(false)
  const [selectablePages, setSelectablePages] = useState([] as ImageFileData[])
  const [selectedIndices, setSelectedIndices] = useState(new Set<number>())
  const [leftPanelState, setLeftPanelState] = useState(LeftPanelState.Upload)
  const [previewedPage, setPreviewedPage] = useState(null as ImageFileData | null)
  const {
    panningEnabled,
    setPanningEnabled,
    scrollZoomEnabled,
    setScrollZoomEnabled,
    panOptions,
    wheelOptions,
    doubleClickOptions,
    transformWrapperOptions,
  } = useZoomControlBar()

  const togglePage = (index: number): void => {
    const page = selectablePages[index]
    if (selectedIndices.has(index)) {
      selectedIndices.delete(index)
      setSelectedIndices(selectedIndices)
      if (previewedPage === page) {
        // set previewed page to previous selected page, or next if non-existent
        let foundPreviewable = false
        for (let i = index - 1; i >= 0 && !foundPreviewable; i -= 1) {
          if (selectedIndices.has(i)) {
            setPreviewedPage(selectablePages[i])
            foundPreviewable = true
          }
        }
        for (let i = index + 1; i < selectablePages.length && !foundPreviewable; i += 1) {
          if (selectedIndices.has(i)) {
            setPreviewedPage(selectablePages[i])
            foundPreviewable = true
          }
        }
        if (!foundPreviewable) {
          setPreviewedPage(null)
        }
      }
    } else {
      selectedIndices.add(index)
      setSelectedIndices(selectedIndices)
      setPreviewedPage(page)
    }
    setImageFileData(selectablePages.filter((_, idx) => selectedIndices.has(idx)))
  }

  const toggleAll = (): void => {
    if (selectedIndices.size === 0) {
      setSelectedIndices(new Set(selectablePages.map((_, index) => index)))
      setImageFileData(selectablePages)
    } else {
      setSelectedIndices(new Set())
      setImageFileData([])
    }
  }

  return (
    <Box display='flex' width='100%' height='100%' p={1}>
      <Paper className={classes.leftPanel}>
        <AppBar position='static' color='primary'>
          <Toolbar>
            <h2>File pages</h2>
          </Toolbar>
        </AppBar>
        {leftPanelState === LeftPanelState.Upload && isProcessingImages && (
          <CenteredCircularProgress />
        )}
        {leftPanelState === LeftPanelState.Upload && !isProcessingImages && (
          <Box justifyContent='center' alignItems='center' display='flex' width='100%' flex='1'>
            <Dropzone
              setFiles={async (files: File[]) => {
                // Now that we have the original data,
                // we can save this for upload
                if (setOriginalFileData) {
                  if (originalFileData && originalFileData.length > 0) {
                    // this appends new files in case we upload, hit Add More Pages, and upload again
                    setOriginalFileData([...originalFileData, ...files])
                  } else {
                    // this just overwrites the entire thing
                    setOriginalFileData(files)
                  }
                }
                setIsProcessingImages(true)
                try {
                  const newImageFileData = await getImageFileData(client, files)
                  const newSelectablePages = selectablePages.concat(newImageFileData)
                  setSelectablePages(newSelectablePages)
                  if (newSelectablePages.length === 1) {
                    setSelectedIndices(new Set([0]))
                    setImageFileData(newSelectablePages)
                    setPreviewedPage(newSelectablePages[0])
                  }
                  setLeftPanelState(LeftPanelState.SelectPages)
                } finally {
                  setIsProcessingImages(false)
                }
              }}
            />
          </Box>
        )}
        {leftPanelState === LeftPanelState.SelectPages && (
          <Box display='flex' flexDirection='column' width='100%' flex='1' overflow='hidden'>
            <Box display='flex' width='100%' justifyContent='space-between' px={3} py={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid='file-upload-select-all'
                    checked={selectedIndices.size > 0}
                    onClick={toggleAll}
                    indeterminate={
                      selectedIndices.size > 0 && selectedIndices.size < selectablePages.length
                    }
                  />
                }
                label='Select all'
              />
              <Button onClick={() => setLeftPanelState(LeftPanelState.Upload)} variant='outlined'>
                Add more pages
              </Button>
            </Box>
            <GridList cellHeight={gridTileHeight} cols={2}>
              {selectablePages.map(({ filename, pageNumber, file, type }, idx) => (
                <GridListTile key={`${filename}-${pageNumber}`} onClick={() => togglePage(idx)}>
                  <Box
                    className={clsx(classes.selectableTile, {
                      [classes.selectableTileSelected]: imageFileData.includes(
                        selectablePages[idx],
                      ),
                      [classes.selectableTileUnselected]: !imageFileData.includes(
                        selectablePages[idx],
                      ),
                    })}
                  >
                    <FilePageThumbnail type={type} file={file} fileName={filename} />
                  </Box>
                  <GridListTileBar title={filename} />
                </GridListTile>
              ))}
            </GridList>
          </Box>
        )}
      </Paper>
      <Paper className={classes.rightPanel}>
        <AppBar position='static' color='primary'>
          <Toolbar>
            <h2>Chosen pages</h2>
            {documentTypes && setDocumentTypeId && (
              <>
                <Box flex={1} />
                <FormControl
                  className={classes.documentTypeSelect}
                  variant='outlined'
                  style={{
                    backgroundColor: 'transparent',
                  }}
                >
                  <DocumentTypeAutocomplete
                    documentTypes={documentTypes}
                    documentTypeId={documentTypeId}
                    onChangeHandler={setDocumentTypeId}
                    dataTestId='document-type-select-upload'
                  />
                </FormControl>
              </>
            )}
          </Toolbar>
        </AppBar>
        <Box display='flex' flex='1' overflow='hidden'>
          {(!imageFileData.length && (
            <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
              <h2>Select pages</h2>
            </Box>
          )) || (
            <>
              <Box
                width='200px'
                display='flex'
                overflow='hidden scroll'
                height='100%'
                flexDirection='column'
                flex='0 0 auto'
              >
                {imageFileData.map(({ filename, pageNumber, file, type }, idx) => (
                  <Paper
                    className={classes.selectedImageTile}
                    onClick={() => setPreviewedPage(imageFileData[idx])}
                    key={`${filename}-${pageNumber}`}
                  >
                    <Box
                      height={gridTileHeight}
                      className={clsx({
                        [classes.selectedImageTilePreviewed]: previewedPage === imageFileData[idx],
                        [classes.selectedImageTileUnpreviewed]:
                          previewedPage !== imageFileData[idx],
                      })}
                    >
                      <FilePageThumbnail type={type} file={file} fileName={filename} />
                    </Box>
                    <div className={classes.selectedImageTileBar}>{filename}</div>
                  </Paper>
                ))}
              </Box>
              <Box flexGrow='1' overflow='hidden scroll' height='100%' position='relative'>
                {!previewedPage ? (
                  <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
                    <h2>Select page to preview</h2>
                  </Box>
                ) : previewedPage.type !== FilePageType.ImagePdf ? (
                  <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
                    <h2>No preview available</h2>
                  </Box>
                ) : (
                  <TransformWrapper
                    pan={panOptions}
                    wheel={wheelOptions}
                    doubleClick={doubleClickOptions}
                    options={transformWrapperOptions}
                  >
                    {
                      // react-zoom-pan-pinch doesn't have proper typing available
                      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                      ({ zoomIn, zoomOut, resetTransform }: any) => (
                        <>
                          <Box
                            display='flex'
                            justifyContent='flex-end'
                            position='sticky'
                            top={0}
                            zIndex={theme.zIndex.modal - 1}
                          >
                            <ZoomControlBar
                              zoomIn={zoomIn}
                              zoomOut={zoomOut}
                              resetTransform={resetTransform}
                              scrollZoomEnabled={scrollZoomEnabled}
                              setScrollZoomEnabled={setScrollZoomEnabled}
                              panningEnabled={panningEnabled}
                              setPanningEnabled={setPanningEnabled}
                            />
                          </Box>
                          <TransformComponent>
                            <img
                              src={previewedPage!.file}
                              alt={previewedPage!.filename}
                              className={classes.previewImage}
                            />
                          </TransformComponent>
                        </>
                      )
                    }
                  </TransformWrapper>
                )}
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

export default FileUpload
