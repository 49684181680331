import { FunctionComponent } from 'react'
import { clsx } from 'clsx'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import {
  ArrivalNoticeConsolTypeReconResultNode,
  ArrivalNoticeMetadataReconResultNode,
} from '@src/graphql/types'
import { getMetadataTableData, MetadataTableDataType } from '@src/utils/recon/an_recon'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import theme from '@src/utils/theme'
import { COLORS } from '@src/utils/app_constants'

const useStyles = makeStyles<Theme>({
  colCell: {
    backgroundColor: theme.palette.grey[100],
  },
  cell: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(0.25, 1),
  },
  error: {
    color: theme.palette.error.main,
    background: COLORS.PALE_RED,
    boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
  },
})

const METADATA_DATA_TABLE_COLUMNS = ['Field Name', 'Invoice Data', 'Expected Data']

type Props = {
  metadataReconResults: ArrivalNoticeMetadataReconResultNode[]
  consolTypeReconResult: ArrivalNoticeConsolTypeReconResultNode | null
  nonRepeatableFieldKeyToNameMap: Record<string, string>
}

const ArrivalNoticeMetadataResults: FunctionComponent<Props> = ({
  metadataReconResults,
  consolTypeReconResult,
  nonRepeatableFieldKeyToNameMap,
}) => {
  const classes = useStyles()
  const metadataTableData = getMetadataTableData(
    metadataReconResults,
    consolTypeReconResult,
    nonRepeatableFieldKeyToNameMap,
  )
  return (
    <>
      <Typography variant='h4' gutterBottom>
        Metadata Reconciliation
      </Typography>
      <TableContainer>
        <Table size='small'>
          <TableBody>
            <TableRow>
              <TableCell className={clsx(classes.cell, classes.colCell)} width='3%' />
              {METADATA_DATA_TABLE_COLUMNS.map((col) => (
                <TableCell className={clsx(classes.cell, classes.colCell)} align='center' key={col}>
                  {col}
                </TableCell>
              ))}
            </TableRow>
            {Object.values(metadataTableData[MetadataTableDataType.DATA]).map((row, rowIdx) => (
              <TableRow key={rowIdx}>
                <TableCell
                  className={clsx(classes.cell, classes.colCell)}
                  width='3%'
                  align='center'
                >
                  {rowIdx}
                </TableCell>
                {row.map((data, idx) => (
                  <TableCell
                    className={clsx(classes.cell, {
                      [classes.error]:
                        metadataTableData[MetadataTableDataType.ERROR_ROWS].includes(rowIdx),
                    })}
                    component='th'
                    scope='row'
                    key={idx}
                  >
                    {data}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ArrivalNoticeMetadataResults
