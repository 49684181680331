import { FunctionComponent } from 'react'
import { useApolloClient } from '@apollo/client'
import { JobNode, ManualProduct } from '@src/graphql/types'
import Select from '@material-ui/core/Select'
import { MenuItem } from '@material-ui/core'
import { SET_JOB_MANUAL_PRODUCT } from '@src/graphql/mutations/job'

type Props = {
  abbreviate?: boolean
  job: JobNode
  refetchJobs: () => unknown
}

const JobManualProductSelect: FunctionComponent<Props> = ({ abbreviate, job, refetchJobs }) => {
  const client = useApolloClient()

  return (
    <Select
      value={job.manualProduct}
      onChange={async (event) => {
        const newManualProduct = event.target.value as ManualProduct
        await client.mutate({
          mutation: SET_JOB_MANUAL_PRODUCT,
          variables: {
            jobId: job.id,
            manualProduct: newManualProduct,
          },
        })
        refetchJobs()
      }}
    >
      {Object.values(ManualProduct).map((value) => (
        <MenuItem key={value} value={value}>
          {abbreviate ? value[0] : value}
        </MenuItem>
      ))}
    </Select>
  )
}

export default JobManualProductSelect
