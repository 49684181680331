import { gql } from '@apollo/client'

export const CREATE_TASK = gql`
  mutation createTask(
    $companyId: String!
    $dateReceived: String!
    $message: String!
    $starred: Boolean!
    $blocked: Boolean!
    $status: String!
    $taskReferenceId: String!
    $taskType: String!
    $title: String!
  ) {
    createTask(
      companyId: $companyId
      dateReceived: $dateReceived
      message: $message
      starred: $starred
      blocked: $blocked
      status: $status
      taskReferenceId: $taskReferenceId
      taskType: $taskType
      title: $title
    ) {
      task {
        id
        title
        taskReferenceId
        message
        dateReceived
        company {
          id
          name
        }
      }
    }
  }
`

export const UPDATE_TASK_STATUS = gql`
  mutation UpdateTaskStatus($taskId: String!, $status: String!) {
    updateTaskStatus(taskId: $taskId, status: $status) {
      task {
        id
        status
      }
    }
  }
`

export const DELETE_TASK = gql`
  mutation DeleteTask($taskId: String!) {
    deleteTask(taskId: $taskId) {
      ok
    }
  }
`

export const UPDATE_TASK = gql`
  mutation updateTask(
    $taskId: String!
    $title: String!
    $dateReceived: String!
    $message: String
    $taskReferenceId: String!
    $dateConfirmed: String
  ) {
    updateTask(
      taskId: $taskId
      title: $title
      dateReceived: $dateReceived
      message: $message
      taskReferenceId: $taskReferenceId
      dateConfirmed: $dateConfirmed
    ) {
      task {
        id
        status
        taskType
        message
        dateConfirmed
        dateReceived
        taskReferenceId
      }
    }
  }
`

export const UPDATE_TASK_TYPE = gql`
  mutation updateTaskType($taskId: String!, $taskType: String!) {
    updateTaskType(taskId: $taskId, taskType: $taskType) {
      task {
        id
        taskType
      }
    }
  }
`

export const UPDATE_TASK_COMPANY = gql`
  mutation updateTaskCompany($taskId: String!, $companyId: String!) {
    updateTaskCompany(taskId: $taskId, companyId: $companyId) {
      task {
        id
        company {
          id
          name
        }
      }
    }
  }
`

export const TOGGLE_STAR_TASK = gql`
  mutation starTask($taskId: String!, $starred: Boolean!) {
    starTask(taskId: $taskId, starred: $starred) {
      task {
        id
      }
    }
  }
`

export const TOGGLE_BLOCK_TASK = gql`
  mutation blockTask($taskId: String!, $blocked: Boolean!, $dateReceived: String) {
    blockTask(taskId: $taskId, blocked: $blocked, dateReceived: $dateReceived) {
      task {
        id
        status
        blocked
        dateReceived
        dateConfirmed
        jobs {
          edges {
            node {
              id
              status
            }
          }
        }
      }
    }
  }
`
