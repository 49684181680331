import { FunctionComponent } from 'react'
import TaskDetail from '@src/components/task-detail/TaskDetail'
import TaskJobs from '@src/components/task-jobs/TaskJobs'
import Grid from '@material-ui/core/Grid'
import { useParams } from 'react-router-dom'

const TaskPage: FunctionComponent = () => {
  const { id: taskId } = useParams<{ id: string }>()

  return (
    <Grid container>
      <Grid item xs={5}>
        <TaskDetail taskId={taskId} />
      </Grid>
      <Grid item xs={7}>
        <TaskJobs taskId={taskId} />
      </Grid>
    </Grid>
  )
}

export default TaskPage
