import { gql } from '@apollo/client'

export const DELETE_CREDENTIAL = gql`
  mutation deleteCredential($credentialId: UUID!) {
    deleteCredential(credentialId: $credentialId)
  }
`

export const CREATE_CREDENTIAL = gql`
  mutation createCredential(
    $workflow: String!
    $username: String!
    $password: String!
    $apiEndpoint: String
    $jobTemplateIds: [UUID!]
    $verifySsl: Boolean!
    $hostOverride: String
  ) {
    createCredential(
      workflow: $workflow
      username: $username
      password: $password
      apiEndpoint: $apiEndpoint
      jobTemplateIds: $jobTemplateIds
      verifySsl: $verifySsl
      hostOverride: $hostOverride
    )
  }
`

export const UPDATE_CREDENTIAL = gql`
  mutation updateCredential(
    $id: UUID!
    $workflow: String!
    $username: String!
    $password: String
    $apiEndpoint: String
    $jobTemplateIds: [UUID!]
    $verifySsl: Boolean!
    $hostOverride: String
  ) {
    updateCredential(
      id: $id
      workflow: $workflow
      username: $username
      password: $password
      apiEndpoint: $apiEndpoint
      jobTemplateIds: $jobTemplateIds
      verifySsl: $verifySsl
      hostOverride: $hostOverride
    ) {
      id
      username
      apiEndpoint
      workflow
      verifySsl
      jobTemplates {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

export const TEST_CREDENTIAL = gql`
  mutation testCredential($credentialId: UUID!) {
    testCredential(credentialId: $credentialId)
  }
`
