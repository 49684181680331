import { createContext, FunctionComponent, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export const Auth0AccessTokenContext = createContext<string | undefined>(undefined)

export const Auth0AccessTokenProvider: FunctionComponent = ({ children }) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState(undefined as undefined | string)
  const userEmail = user?.email
  useEffect(() => {
    if (isAuthenticated) {
      const signIn = async (): Promise<void> => {
        const token = await getAccessTokenSilently()
        setAccessToken(token)
      }
      void signIn()
    } else {
      setAccessToken(undefined)
    }
  }, [isAuthenticated, getAccessTokenSilently, userEmail])
  return (
    <Auth0AccessTokenContext.Provider value={accessToken}>
      {children}
    </Auth0AccessTokenContext.Provider>
  )
}
