import { gql } from '@apollo/client'

export const EXPORT_JOB_TO_CARGOWISE = gql`
  mutation exportJobToCw(
    $jobId: UUID!
    $lumpsum: InputDocumentTable
    $overrideChargeDescription: Boolean!
    $disableSendDueDate: Boolean!
    $matchingCriteria: MatchingCriteriaType!
  ) {
    exportJobToCw(
      jobId: $jobId
      lumpsum: $lumpsum
      overrideChargeDescription: $overrideChargeDescription
      disableSendDueDate: $disableSendDueDate
      matchingCriteria: $matchingCriteria
    ) {
      ok
    }
  }
`

export const BATCH_EXPORT_JOB_TO_CARGOWISE = gql`
  mutation batchExportJobToCw(
    $jobId: String!
    $invoiceNumbers: [String!]!
    $invoiceDates: [InputSoaDates!]!
    $invoiceCwModuleKeys: [InputSoaCwModules!]!
    $overrideChargeDescription: Boolean!
    $disableSendDueDate: Boolean!
    $asLumpsum: Boolean!
  ) {
    batchExportJobToCw(
      jobId: $jobId
      invoiceNumbers: $invoiceNumbers
      invoiceDates: $invoiceDates
      invoiceCwModuleKeys: $invoiceCwModuleKeys
      overrideChargeDescription: $overrideChargeDescription
      disableSendDueDate: $disableSendDueDate
      asLumpsum: $asLumpsum
    ) {
      transactionAsyncBatchId
    }
  }
`

export const POST_TO_CARGOWISE = gql`
  mutation postToCw(
    $jobId: UUID!
    $lumpsum: InputDocumentTable
    $overrideChargeDescription: Boolean!
    $disableSendDueDate: Boolean!
    $matchingCriteria: MatchingCriteriaType!
  ) {
    postToCw(
      jobId: $jobId
      lumpsum: $lumpsum
      overrideChargeDescription: $overrideChargeDescription
      disableSendDueDate: $disableSendDueDate
      matchingCriteria: $matchingCriteria
    ) {
      ok
    }
  }
`

export const PUSH_EDOCS_TO_CARGOWISE = gql`
  mutation pushEDocsToCw(
    $jobId: UUID!
    $inputEDocuments: [InputEDocument!]!
    $dataTargetType: EDocsDataTargetType!
    $overrideReconAttemptId: UUID
  ) {
    pushEDocsToCw(
      jobId: $jobId
      inputEDocuments: $inputEDocuments
      dataTargetType: $dataTargetType
      overrideReconAttemptId: $overrideReconAttemptId
    ) {
      ok
    }
  }
`
export const BATCH_PUSH_EDOCS_TO_CARGOWISE = gql`
  mutation batchPushEdocsToCw(
    $jobId: UUID!
    $referenceNumbers: [String!]!
    $inputEDocuments: [InputEDocument!]!
    $dataTargetType: EDocsDataTargetType!
  ) {
    batchPushEDocsToCw(
      jobId: $jobId
      referenceNumbers: $referenceNumbers
      inputEDocuments: $inputEDocuments
      dataTargetType: $dataTargetType
    ) {
      batchPushId
    }
  }
`

export const PULL_EDOCS_FROM_CARGOWISE = gql`
  mutation pullEDocsFromCw($jobId: UUID!, $dataTargetType: EDocsDataTargetType!) {
    pullEDocsFromCw(jobId: $jobId, dataTargetType: $dataTargetType) {
      ok
    }
  }
`
export const BATCH_PULL_EDOCS_FROM_CARGOWISE = gql`
  mutation batchPullEDocsFromCw(
    $jobId: UUID!
    $dataTargetType: EDocsDataTargetType!
    $chainIoShipmentIds: [UUID!]
    $invoiceNumbers: [String!]
    $chainIoConsolIds: [UUID!]
    $chainIoCustomsDecIds: [UUID!]
  ) {
    batchPullEDocsFromCw(
      jobId: $jobId
      dataTargetType: $dataTargetType
      chainIoShipmentIds: $chainIoShipmentIds
      invoiceNumbers: $invoiceNumbers
      chainIoConsolIds: $chainIoConsolIds
      chainIoCustomsDecIds: $chainIoCustomsDecIds
    ) {
      batchPullId
    }
  }
`

export const DELETE_EDOC = gql`
  mutation deleteEDoc($eDocumentId: UUID!) {
    deleteEDoc(eDocumentId: $eDocumentId) {
      ok
    }
  }
`

export const BULK_DELETE_EDOCS = gql`
  mutation bulkDeleteEdocs($eDocumentIds: [UUID!]!) {
    bulkDeleteEDocs(eDocumentIds: $eDocumentIds) {
      ok
    }
  }
`

export const DELETE_CW_CONFIG = gql`
  mutation deleteCargowiseConfig($cwConfigId: UUID!) {
    deleteCargowiseConfig(cwConfigId: $cwConfigId) {
      ok
    }
  }
`

export const CREATE_CW_CONFIG = gql`
  mutation createCargowiseConfig($inputCwConfig: InputCargowiseConfig!) {
    createCargowiseConfig(inputCwConfig: $inputCwConfig) {
      ok
    }
  }
`

export const UPDATE_CW_CONFIG = gql`
  mutation updateCargowiseConfig($id: UUID!, $inputCwConfig: InputCargowiseConfig!) {
    updateCargowiseConfig(id: $id, inputCwConfig: $inputCwConfig) {
      cwConfig {
        id
        code
        countryCode
        enterpriseId
        serverId
        outboundPass
        dbPass
        dbUser
        dbName
        remoteDbHostname
        remoteDbPort
        pollDatabase
        proxyUrls
        connectorType
        forceFetch
        dbDirectConnect
        externApiCredential {
          id
          username
          workflow
        }
        company {
          id
          name
        }
        jobTemplates {
          edges {
            node {
              id
              name
              company {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const TEST_CW_CONFIG = gql`
  mutation testCargowiseConfig($cwConfigId: UUID!) {
    testCargowiseConfig(cwConfigId: $cwConfigId) {
      ok
    }
  }
`

export const SEND_SHIPMENT_OPS_TO_CW = gql`
  mutation sendShipmentOpsToCw($jobId: UUID!) {
    sendShipmentOpsToCw(jobId: $jobId) {
      shipmentReferenceNumber
      consolReferenceNumber
      importDecReferenceNumber
      processingLog
    }
  }
`

export const SYNC_CARGOWISE_DB = gql`
  mutation syncCargowiseDb {
    syncCargowiseDb
  }
`

export const SEND_B_FILE_TO_CW = gql`
  mutation sendBFileToCw($jobId: UUID!) {
    sendBFileToCw(jobId: $jobId) {
      cdecReferenceNumber
      consolReferenceNumber
      shipmentReferenceNumber
    }
  }
`
