import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, ControllerProps, useFormContext, useWatch } from 'react-hook-form'
import { useQuery } from '@apollo/client'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'

import { JobTemplateFormValues } from '@src/components/admin/job-template-editor/JobTemplateEditor'
import { GET_ALL_CREDENTIALS } from '@src/graphql/queries/credential'
import { CredentialNode, Maybe, Query } from '@src/graphql/types'

const CredentialSelector: FunctionComponent = () => {
  const credentialId = useWatch<JobTemplateFormValues>({
    name: 'credentialId',
  }) as Maybe<string>
  const { setValue } = useFormContext<JobTemplateFormValues>()
  const [selectedCredential, setSelectedCredential] = useState<CredentialNode | null>(null)

  const { data: credentialsData, loading: credentialsLoading } =
    useQuery<Pick<Query, 'credentials'>>(GET_ALL_CREDENTIALS)

  const credentialsMap = useMemo(() => {
    return Object.fromEntries(
      credentialsData?.credentials?.map((credential) => [credential.id, credential]) ?? [],
    )
  }, [credentialsData])

  useEffect(() => {
    const credential = credentialId ? credentialsMap[credentialId] ?? null : null
    setSelectedCredential(credential)
  }, [credentialsMap, credentialId])

  const formatCredentialLabel = useCallback((credential: Maybe<CredentialNode>): string => {
    if (credential) {
      const { workflow, username } = credential
      return `${workflow}: ${username}`
    }
    return ''
  }, [])

  const getOptionSelected = useCallback((option, val) => option.id === val.id, [])
  const renderInput = useCallback((params) => <TextField {...params} variant='outlined' />, [])
  const onAutocompleteChange = useCallback(
    (_: ChangeEvent<unknown>, newValue: Maybe<CredentialNode> | string): void => {
      if (typeof newValue !== 'string') {
        setValue('credentialId', newValue?.id ?? null)
      }
    },
    [setValue],
  )

  const render: ControllerProps<JobTemplateFormValues, 'credentialId'>['render'] = useMemo(() => {
    return ({ fieldState: { error, invalid } }) => {
      return (
        <Autocomplete
          size='small'
          options={Object.values(credentialsMap)}
          value={selectedCredential}
          renderOption={formatCredentialLabel}
          getOptionLabel={formatCredentialLabel}
          getOptionSelected={getOptionSelected}
          disabled={credentialsLoading}
          renderInput={(params) => (
            <TextField {...params} error={invalid} helperText={error?.message} variant='outlined' />
          )}
          onChange={onAutocompleteChange}
        />
      )
    }
  }, [
    selectedCredential,
    formatCredentialLabel,
    getOptionSelected,
    onAutocompleteChange,
    credentialsLoading,
    credentialsMap,
  ])
  return (
    <>
      <Controller render={render} name={'credentialId'} defaultValue={selectedCredential?.id} />
    </>
  )
}

export default CredentialSelector
