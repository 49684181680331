import { createContext, FunctionComponent, useContext, useEffect, useState } from 'react'
import { DeployEnvironment, getDeployEnvironment } from '@src/utils/environment'
import Tracker from '@openreplay/tracker'
import { snakeCase } from 'change-case'
import { GraphQLTrackerContext } from '@src/graphql/ApolloProviderWithClient'
import { emptyAttrPlaceholder } from '@src/utils/observability/formatting'
import { useEventAttributes } from '@src/utils/observability/useEventAttributes'
import { openReplayGraphQLTracker } from '@src/utils/observability/openReplayGraphQLTracker'
import { setRollbarTransform } from '@src/utils/observability/rollbar'
import { setOpenReplaySessionToken } from '@src/utils/observability/tracing'

// send in stg by default too to make debugging QA easier
const shouldSendData =
  [DeployEnvironment.STAGING, DeployEnvironment.PRODUCTION].includes(getDeployEnvironment()) ||
  localStorage.forceUseOpenReplay === 'true'
const shouldSendResponses = sessionStorage.sendOpenReplayResponses === 'true'
const maxResponseLength = shouldSendResponses ? 10 * 1024 * 1024 * 1024 : 100 * 1024 * 1024

const applicationIdMap = {
  [DeployEnvironment.DEVELOPMENT]: 'bN5kEGqaqOhQCLWyBeDU',
  [DeployEnvironment.ENGINEERING]: 'rYiMdU6WBLoxP0RIXhag',
  [DeployEnvironment.STAGING]: 'K65rpjoNaFXqeq77BVu2',
  [DeployEnvironment.PRODUCTION]: '64BKEyCdHQhCpTEmXF8g',
}

const OpenReplayContext = createContext(undefined as Tracker | undefined)
export const OpenReplayProvider: FunctionComponent = ({ children }) => {
  const [tracker, setTracker] = useState(undefined as Tracker | undefined)
  const { userAttributes, loading } = useEventAttributes()
  const { setRequestHandler } = useContext(GraphQLTrackerContext)
  useEffect(() => {
    if (shouldSendData && !tracker) {
      const startTracking = async (): Promise<void> => {
        const tracker_ = new Tracker({
          projectKey: applicationIdMap[getDeployEnvironment()],
          ingestPoint: `${window.location.protocol}//${window.location.host}/ev-or/ingest`,
          __DISABLE_SECURE_MODE: getDeployEnvironment() === DeployEnvironment.DEVELOPMENT,
          network: {
            failuresOnly: true,
            sessionTokenHeader: false,
            ignoreHeaders: true,
            capturePayload: false,
            captureInIframes: false,
          },
          obscureInputEmails: false,
          obscureTextEmails: false,
          captureIFrames: false,
        })
        setTracker(tracker_)
        const recordGraphQL = tracker_.use(
          openReplayGraphQLTracker((shouldSendResponses && 'all') || 'errors', maxResponseLength),
        )
        setRequestHandler(() => (operation, forward) => {
          return forward(operation).map((result) => {
            const operationDefinition = operation.query.definitions[0]
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return recordGraphQL(
              operationDefinition.kind === 'OperationDefinition'
                ? operationDefinition.operation
                : 'unknown?',
              operation.operationName,
              operation.variables,
              result,
            )
          })
        })
        await tracker_.start()
        const sessionToken = tracker_.getSessionToken()
        setRollbarTransform((data: any, item: any) => {
          if (!data.custom) {
            data.custom = {}
          }
          data.custom['openReplaySessionToken'] = sessionToken
          if (item?.err) {
            tracker_.handleError(item.err as Error)
          } else {
            tracker_.issue((item?.message as string) || String(item), data)
          }
        })
        setOpenReplaySessionToken(sessionToken!)
      }
      void startTracking()
    }
  }, [])
  useEffect(() => {
    if (!loading && tracker && userAttributes.email) {
      tracker.setUserID(userAttributes.email)
      for (const [name, value] of Object.entries(userAttributes)) {
        if (name == 'email' || name == 'auth0Id') continue
        tracker.setMetadata(snakeCase(name), String(value || emptyAttrPlaceholder))
      }
    }
  }, [tracker, userAttributes, loading])
  return <OpenReplayContext.Provider value={tracker}>{children}</OpenReplayContext.Provider>
}

export const useOpenReplay = (): Tracker | undefined => {
  return useContext(OpenReplayContext)
}
