import { gql } from '@apollo/client'

// ideally atomic but we dont have a FieldGroup w/o fields query now so its ok
export const FIELD_GROUP_FRAGMENT = gql`
  fragment FieldGroupAttributes on FieldGroupNode {
    id
    key
    name
    autofillKey
    repeatable
    fields {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const GET_AUTOFILL_KEYS = gql`
  query autofillKeys(
    $autofillExtractorKey: AutofillExtractorKey!
    $reconType: JobTemplateReconType!
    $documentTypeId: String!
  ) {
    autofillKeys(
      autofillExtractorKey: $autofillExtractorKey
      reconType: $reconType
      documentTypeId: $documentTypeId
    ) {
      autofillKeys
      lineItemFieldGroupAutofillKeys
    }
  }
`

export const GET_SEARCHABLE_RECORD_OPTIONS = gql`
  query searchableRecords {
    searchableRecords {
      searchKey
      displayKey
      model
      apiUrl
    }
  }
`
