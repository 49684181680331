import { FunctionComponent, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/styles'
import { Box, TextField, Theme, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { formatDateForTextField } from '@src/utils/date'
import theme from '@src/utils/theme'

const useStyles = makeStyles<Theme>({
  dialog: {
    width: theme.breakpoints.values.md,
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
    },
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
})

type Props = {
  isOpen: boolean
  close: () => void
  confirm: (dateReceived: string) => Promise<void>
  dateReceived?: string
}

const UnblockTaskDialog: FunctionComponent<Props> = ({ isOpen, close, confirm, dateReceived }) => {
  const [newDateReceived, setNewDateReceived] = useState(null as null | string)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const handleConfirm = async (): Promise<void> => {
    if (!newDateReceived) {
      enqueueSnackbar('Please specify a date time received', {
        variant: 'error',
      })
      return
    }
    await confirm(newDateReceived)
  }

  return (
    <Dialog classes={{ paper: classes.dialog }} open={isOpen}>
      <Box className={classes.close}>
        <CloseIcon fontSize='large' onClick={close} data-testid='close-btn' />
      </Box>
      <DialogTitle>
        <Box p={1}>
          <Typography variant='h1'>Update date & time received</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography gutterBottom>Specify when the task got unblocked</Typography>
          <TextField
            label='Date & Time Received'
            type='datetime-local'
            onChange={(e) => {
              setNewDateReceived(e.target.value)
            }}
            defaultValue={dateReceived ? formatDateForTextField(dateReceived) : null}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </DialogContent>
      <Box display='flex' alignItems='center' justifyContent='center' mb={3}>
        <Button className={classes.cancelButton} onClick={close} variant='contained'>
          Cancel
        </Button>
        <Button onClick={handleConfirm} color='primary' variant='contained'>
          Accept
        </Button>
      </Box>
    </Dialog>
  )
}

export default UnblockTaskDialog
